import { useSetAtom } from "jotai";
import { useEffect } from "react";

import {
  IntelligenceDashboardQuery,
  IntelligenceDashboardType,
} from "@/generated-models";

import { ComparativeDashboardCard } from "./ComparativeDashboardCard";
import { ComparativeDashboardHeader } from "./ComparativeDashboardHeader";
import { ComparativeDashboardContent } from "./Content/ComparativeDashboardContent";
import { compContentTabAtom } from "./Content/ComparativeDashboardContentTabs";
import { ComparativeTabType } from "./constant";
import { getComparativeType } from "./utils";

export interface ComparativeDashboardProps {
  dashboard: NonNullable<IntelligenceDashboardQuery["intelligenceDashboard"]>;
}

const DEFAULT_TYPE: Record<
  | IntelligenceDashboardType.Presence
  | IntelligenceDashboardType.Idle
  | IntelligenceDashboardType.Count,
  ComparativeTabType
> = {
  [IntelligenceDashboardType.Presence]: ComparativeTabType.PRESENCE,
  [IntelligenceDashboardType.Idle]: ComparativeTabType.IDLE_PERCENTAGE,
  [IntelligenceDashboardType.Count]: ComparativeTabType.COUNT_IN_OUT,
};

function useInitializeContentTab(
  dashboard: ComparativeDashboardProps["dashboard"]
) {
  const type = getComparativeType(dashboard) || IntelligenceDashboardType.Idle;
  const setContentTab = useSetAtom(compContentTabAtom);

  useEffect(() => {
    setContentTab(
      DEFAULT_TYPE[
        type as
          | IntelligenceDashboardType.Presence
          | IntelligenceDashboardType.Idle
          | IntelligenceDashboardType.Count
      ]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export function ComparativeDashboard({ dashboard }: ComparativeDashboardProps) {
  useInitializeContentTab(dashboard);

  return (
    <ComparativeDashboardCard>
      <ComparativeDashboardHeader dashboard={dashboard} />
      <ComparativeDashboardContent dashboard={dashboard} />
    </ComparativeDashboardCard>
  );
}
