import Dialog from "@mui/material/Dialog";
import { ReactNode } from "react";

import LockLandscapeOnFullscreen from "@/components/LockLandscapeOnFullscreen";

export function IntelligenceFullScreenView({
  children,
}: {
  children?: ReactNode;
}) {
  return (
    <Dialog fullScreen open>
      <LockLandscapeOnFullscreen>{children}</LockLandscapeOnFullscreen>
    </Dialog>
  );
}
