import {
  Switch,
  SwitchProps,
  Typography,
  Box,
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
  useTheme,
  FormControlLabel,
} from "@mui/material";
import clsx from "clsx";
import React from "react";

import { useLocalStorage } from "@/util/useLocalStorage";

export type SpotSwitchProps = Omit<SwitchProps, "color"> & {
  color?: string;
  label?: string;
  className?: string;
};

export function SpotSwitch({ className, ...props }: SpotSwitchProps) {
  const { checked } = props;
  const { color, label, ...switchProps } = props;
  const mainTheme = useTheme();
  const theme = createTheme({
    palette: {
      primary: {
        main: color || mainTheme.palette.primary.main,
      },
    },
  });
  return (
    <FormControlLabel
      className={clsx("ml-[11px]", className)}
      control={
        <Box
          marginRight={label ? 1 : undefined}
          display="flex"
          alignItems="center"
        >
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <Switch {...{ size: "small", ...switchProps }} color="primary" />
            </ThemeProvider>
          </StyledEngineProvider>
          <Typography
            variant="caption"
            style={{
              marginTop: 1,
              fontSize: 8,
              width: 16,
              fontWeight: checked ? "bold" : "inherit",
              color: checked ? props.color : "inherit",
            }}
          >
            {checked ? "ON" : "OFF"}
          </Typography>
        </Box>
      }
      label={
        label && (
          <Typography variant="subtitle2" className="relative">
            {label}
            {color && (
              <div
                className="absolute -bottom-0.5 h-1 w-10"
                style={{ backgroundColor: color }}
              />
            )}
          </Typography>
        )
      }
    />
  );
}

export function WiredSpotSwitch({
  localStorageKey,
  initialChecked = false,
  ...props
}: {
  localStorageKey: string;
  initialChecked?: boolean;
} & SpotSwitchProps) {
  const [checked, setChecked] = useLocalStorage(
    localStorageKey,
    initialChecked
  );
  return (
    <SpotSwitch
      {...props}
      checked={checked}
      onChange={(e) => setChecked(e.target.checked)}
    />
  );
}
