/* eslint-disable no-useless-escape */
import { Monaco } from "@monaco-editor/react";

import {
  PATTERN_PROMPT_INPUT,
  PATTERN_VARIABLE,
  SPOT_ASSIST_LANG,
} from "./constant";
import { THEME_RULE_TOKEN } from "./editorTheme";

export function registerAssistantTokenProvider(monaco: Monaco) {
  return monaco.languages.setMonarchTokensProvider(SPOT_ASSIST_LANG, {
    tokenizer: {
      root: [
        [PATTERN_VARIABLE, THEME_RULE_TOKEN.VARIABLE],
        [PATTERN_PROMPT_INPUT, THEME_RULE_TOKEN.PROMPT_INPUT],
        [/\/\/.*/g, "comment"],
        [
          /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-:]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-:]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/,
          THEME_RULE_TOKEN.HYPERLINK,
        ],
      ],
    },
  });
}
