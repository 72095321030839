import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Button } from "@mui/material";
import clsx from "clsx";
import { useCallback } from "react";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

export enum SortType {
  default = 0,
  ascending = 1,
  descending = -1,
}

// create a custom parameter with a default value
export const SortParams = withDefault(NumberParam, -1);
export const SortFieldParams = withDefault(StringParam, "time");

export function LicensePlateSummarySortButton({ label }: { label: string }) {
  const [query, setQuery] = useQueryParams({
    sort: SortParams,
    field: SortFieldParams,
  });

  const { sort, field } = query;
  const sorted = !!sort && field === label;

  const onMouseClick = useCallback(() => {
    if (!sort || sort > 0 || field !== label) {
      setQuery({
        sort: SortType.descending,
        field: label,
      });
    } else if (sort < 0) {
      setQuery({
        sort: SortType.ascending,
        field: label,
      });
    }
  }, [sort, label, field, setQuery]);

  function SortIcon() {
    return (
      <>
        {sort < 0 && sorted && <ArrowDownwardIcon className="w-4 h-4" />}
        {sort > 0 && sorted && <ArrowUpwardIcon className="w-4 h-4" />}
      </>
    );
  }

  return (
    <Button
      color={sorted ? "primary" : "inherit"}
      disableRipple
      endIcon={<SortIcon />}
      classes={{
        endIcon: "ml-0",
      }}
      className={clsx(
        "mt-[3.5px] p-0 text-[14px] leading-[16.41px] min-w-[unset] font-normal capitalize border-b-2 border-solid border-transparent rounded-none",
        {
          "font-bold !border-primary": sorted,
        }
      )}
      size="small"
      onClick={onMouseClick}
    >
      {label}
    </Button>
  );
}
