import { Typography } from "@mui/material";
import { useIntervalEffect } from "@react-hookz/web";
import clsx from "clsx";
import React, { useState } from "react";

export const IntLoadingIndicator = React.memo(function ({
  labels = ["Calculating and Loading AI Results", "Still working..."],
  intervalTime = 10000,
  className,
}: {
  labels?: string[];
  intervalTime?: number;
  className?: string;
}) {
  const [currentLabelIndex, setCurrentLabelIndex] = useState(0);

  useIntervalEffect(
    () =>
      setCurrentLabelIndex((prevIndex) =>
        prevIndex === labels.length - 1 ? 0 : prevIndex + 1
      ),
    intervalTime
  );

  return (
    <div className={clsx("flex-center flex-col", className)}>
      <div className="w-[262px] grid grid-cols-12 gap-0.5">
        {Array.from({ length: 36 }).map((_, index) => (
          <div
            key={index}
            className="w-5 h-5 border-[5px] border-[#d9ebfb] rounded-full flex-center relative"
          >
            <div
              className="absolute w-4 h-4 bg-primary rounded-full opacity-0 animate-full-pulse"
              style={{
                animationDelay: `${getRandomInt(0, 1000)}ms`,
                animationDuration: `${getRandomInt(1000, 4000)}ms`,
              }}
            />
          </div>
        ))}
      </div>
      <Typography className="mt-3 text-base font-bold text-primary">
        {labels[currentLabelIndex]}
      </Typography>
    </div>
  );
});

function getRandomInt(min: number, max: number) {
  return Math.floor(Math.random() * (max - min) + min);
}
