import { Button, Modal, Paper, Typography } from "@mui/material";
import { DataGrid, GridRowSelectionModel } from "@mui/x-data-grid";
import { Form, Formik } from "formik";
import gql from "graphql-tag";
import { useState } from "react";

import { AI } from "@/icons/AI";

import { Loading } from "@/components/Loading";
import { DefaultDialog, useDialog } from "@/components/shared/Dialog";

import {
  ApplianceAiMode,
  LifecycleStates,
  useDeviceListQuery,
  useLocationWithAppliancesQuery,
  useMoveCamerasMutation,
} from "@/generated-models";

interface RebalanceModalProps {
  locationId: number;
  opened: boolean;
  close: () => void;
}

export function RebalanceCamerasModal({
  locationId,
  opened,
  close,
}: RebalanceModalProps) {
  const [moveCameras] = useMoveCamerasMutation();
  const [rowSelectionModel, setRowSelectionModel] = useState<
    GridRowSelectionModel
  >([]);
  const { open, ...dialogProps } = useDialog();
  const {
    data: devData,
    loading: devicesLoading,
    refetch,
  } = useDeviceListQuery({
    variables: { locationId },
    skip: !opened,
  });
  const {
    data: applianceData,
    loading: locationLoading,
  } = useLocationWithAppliancesQuery({
    variables: { id: locationId },
    skip: !opened,
  });

  if (!opened) {
    return null;
  }

  const devices = devData?.location?.devices;
  const cameras =
    devices?.flatMap((d) =>
      d.cameras.map((c) => ({ ...c, devId: d.id, ip: d.ip, nvr: d.isNvr }))
    ) ?? [];
  const appliances =
    applianceData?.location?.appliances.map((a) => ({
      id: a.id,
      serial: a.serialNumber,
      capacity: a.capacity,
      aiEnabled: a.deviceAiMode === ApplianceAiMode.Nvidia,
      cameras:
        cameras
          ?.filter((c) => c.appliance.id === a.id)
          .map((c) => ({
            id: c.id,
            name: c.name,
            ip: devices?.find((d) => d.id === c.devId)?.ip ?? "",
            nvr: c.nvr,
            state: c.lifecycleState,
            aiEnabled: c.aiEnabled,
          })) ?? [],
    })) ?? [];

  return (
    <Modal
      className="flex-center"
      disableEnforceFocus
      open={opened}
      onClose={() => {
        setRowSelectionModel([]);
        close();
      }}
    >
      <Paper>
        <Formik
          initialValues={{
            appliance: String(appliances[0]?.id),
            cameras: [] as string[],
          }}
          onSubmit={async (values) => {
            const confirmed = await open();
            if (!confirmed) return;

            await moveCameras({
              variables: {
                input: [
                  {
                    targetApplianceId: Number(values.appliance),
                    cameraIds: values.cameras.map(Number),
                  },
                ],
              },
            });
            await refetch();
          }}
        >
          {({ isSubmitting, values, setValues, handleChange }) => {
            if (devicesLoading || locationLoading) {
              return <Loading />;
            }

            const camRows = appliances.flatMap((appliance) => {
              if (appliance.id === Number(values.appliance)) {
                return [];
              }
              return appliance.cameras.map((cam) => {
                return {
                  id: cam.id,
                  cam: cam.name,
                  ip: cam.ip,
                  nvr: cam.nvr,
                  appliance: appliance.serial,
                  aiEnabled: cam.aiEnabled,
                };
              });
            });

            const targetAppliance = appliances.find(
              (a) => a.id === Number(values.appliance)
            );

            const enabled =
              targetAppliance?.cameras.filter(
                (c) => c.state === LifecycleStates.Enabled
              ).length ?? 0;

            const counts = appliances.map((a) => {
              let newCount = a.cameras.length;
              if (a.id === Number(values.appliance)) {
                newCount += values.cameras.length;
              } else {
                newCount = values.cameras.reduce((previous, c) => {
                  return a.cameras.find((l) => l.id === Number(c))
                    ? previous - 1
                    : previous;
                }, newCount);
              }
              return {
                serial: a.serial,
                aiEnabled: a.aiEnabled,
                old: a.cameras.length,
                new: newCount,
                capacity: a.capacity,
              };
            });

            return (
              <Form className="p-4">
                <Typography variant="h1">Rebalance Cameras</Typography>
                <div className="py-4">
                  {counts.map((c) => (
                    <Typography>
                      {c.serial}: {c.old} -&gt; {c.new} [capacity: {c.capacity}]{" "}
                      {c.aiEnabled && <AI className="inline" />}
                    </Typography>
                  ))}
                </div>
                <div>
                  <label htmlFor="appliance">Target Appliance</label>
                  <select
                    onChange={(e) => {
                      setValues({ ...values, cameras: [] });
                      setRowSelectionModel([]);
                      handleChange(e);
                    }}
                    name="appliance"
                    className="ml-4 pl-4"
                  >
                    {appliances.map((appliance) => (
                      <option key={appliance.id} value={appliance.id}>
                        {appliance.serial}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="h-[400px] w-[725px] py-4">
                  <DataGrid
                    checkboxSelection={true}
                    columns={[
                      {
                        field: "aiEnabled",
                        headerName: "AI",
                        align: "center",
                        width: 85,
                        renderCell: (params) => params.value && <AI />,
                      },
                      { field: "cam", headerName: "Camera Name", width: 175 },
                      { field: "ip", headerName: "IP", width: 130 },
                      {
                        field: "appliance",
                        headerName: "Current Appliance",
                        width: 185,
                      },
                      { field: "nvr", headerName: "NVR", width: 100 },
                    ]}
                    rows={camRows}
                    rowHeight={25}
                    initialState={{
                      pagination: { paginationModel: { pageSize: 64 } },
                    }}
                    pageSizeOptions={[16, 32, 64]}
                    onRowSelectionModelChange={(s) => {
                      setValues({ ...values, cameras: s as string[] });
                      setRowSelectionModel(s);
                    }}
                    rowSelectionModel={rowSelectionModel}
                  />
                </div>
                {targetAppliance && (
                  <>
                    <p className="pb-2">
                      Moving <strong>{rowSelectionModel.length}</strong>{" "}
                      camera(s) to '<strong>{targetAppliance.serial}</strong>'.
                      Currently <strong>{enabled}</strong> active.
                      {enabled + rowSelectionModel.length >
                        targetAppliance.capacity && (
                        <Typography color="error">
                          WARNING: this will exceed the capacity of{" "}
                          {targetAppliance.capacity}
                        </Typography>
                      )}
                    </p>

                    <Button
                      color="primary"
                      variant="outlined"
                      type="submit"
                      disabled={isSubmitting || rowSelectionModel.length < 1}
                    >
                      Rebalance
                    </Button>
                  </>
                )}
              </Form>
            );
          }}
        </Formik>
        <DefaultDialog
          title="Are you sure you want to rebalance?"
          confirmText="Move Cameras"
          content="Make sure the cameras are reachable by the target appliance."
          confirmColor="primary"
          {...dialogProps}
        />
      </Paper>
    </Modal>
  );
}

gql`
  mutation moveCameras($input: [MoveCamerasInput!]!) {
    moveCameras(input: $input)
  }
`;
