import { TextFieldProps } from "@mui/material";
import { TextField as FormikTextField } from "formik-mui";

import { ConnectionValidation as Validation } from "@/generated-models";

export function getValidationErrors(validation: Validation) {
  switch (validation) {
    case Validation.IpInvalid:
      return { ip: "Cannot reach IP" };
    case Validation.PortInvalid:
      return { port: "Port invalid" };
    case Validation.RtspPathInvalid:
      return { path: "Path invalid" };
    case Validation.CredentialsInvalid:
      return {
        username: "Credentials invalid",
        password: "Credentials invalid",
      };
    case Validation.MaxAttemptsReached:
      return {
        username: "Maximum connection attempts reached",
        password: "Maximum connection attempts reached",
      };
  }
  return undefined;
}

export const defaultTextProps: TextFieldProps & {
  component: typeof FormikTextField;
} = {
  disabled: true,
  fullWidth: true,
  size: "small",
  InputProps: { style: { fontSize: 14, fontWeight: "bold" } },
  InputLabelProps: { shrink: true, style: { fontSize: 15 } },
  // inputProps: { style: { padding: "0 0 4px" } },
  FormHelperTextProps: { style: { fontSize: 10, marginTop: 0 } },
  component: FormikTextField,
};

export function getDeviceName(
  isNvr: boolean,
  camName: string | undefined,
  isCameraVendor: boolean
) {
  if (isNvr) return "NVR";
  if (camName) return camName;
  if (isCameraVendor) return "Camera device";
  return "Other device";
}
