import _ from "lodash";
import { useState, useMemo } from "react";
import useResizeObserver from "use-resize-observer";

const useDebouncedResizeObserver = (wait: number) => {
  const [size, setSize] = useState<{ width?: number; height?: number }>({});
  const tAF = (sz: any) => requestAnimationFrame(() => setSize(sz));
  const onResize = useMemo(() => _.throttle(tAF, wait, { leading: true }), [
    wait,
  ]);
  const { ref } = useResizeObserver({
    onResize,
  });

  return { ref, ...size };
};

export default useDebouncedResizeObserver;
