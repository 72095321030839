import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Typography,
} from "@mui/material";
import gql from "graphql-tag";
import { ReactNode, useState } from "react";

import { useMe } from "@/components/Auth";

import { useAcceptPeopleSearchTermsMutation } from "@/generated-models";

function ListItem({
  content,
  children,
}: {
  content: string;
  children?: ReactNode;
}) {
  return (
    <li className="text-sm leading-normal">
      {content}
      {children}
    </li>
  );
}

function NestedList({ children }: { children?: ReactNode }) {
  return <ol className="list-[lower-alpha] pl-8">{children}</ol>;
}

export function PeopleSearchEulaModal({
  opened,
  cancel,
  confirm,
  alternate,
  ...dialogProps
}: {
  opened: boolean;
  cancel: () => void;
  confirm?: () => void;
  alternate?: () => void;
} & Omit<DialogProps, "open" | "onClose" | "content">) {
  const [viewedBottom, setViewedBottom] = useState(false);
  const me = useMe();

  const handleScroll = (e: any) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop - 5 <= e.target.clientHeight;
    if (!viewedBottom) {
      setViewedBottom(bottom);
    }
  };

  const [acceptSearchTerms] = useAcceptPeopleSearchTermsMutation({
    update: (cache) => {
      cache.modify({
        id: "ROOT_QUERY",
        fields: {
          me(existing) {
            return {
              ...existing,
              termsPeopleSearchAccepted: true,
            };
          },
        },
      });
    },
    optimisticResponse: {
      __typename: "Mutation",
      acceptPeopleSearchTerms: {
        __typename: "User",
        id: Number(me?.id),
        termsPeopleSearchAccepted: true,
      },
    },
  });

  return (
    <Dialog
      open={opened}
      onClose={cancel}
      onClick={(e) => {
        e.stopPropagation();
      }}
      {...dialogProps}
      classes={{
        paper: "rounded-lg max-w-[460px]",
      }}
    >
      <DialogTitle className="flex justify-between items-center p-5">
        <Typography className="text-2xl leading-[22px] font-bold">
          To use people search you agree to the following terms
        </Typography>
      </DialogTitle>
      <DialogContent
        className="py-0 px-5 h-[600px] overflow-scroll"
        onScroll={handleScroll}
      >
        <Typography className="text-[11px]">Dated 10/16/2023</Typography>
        <Typography className="text-sm leading-normal pt-[18px]">
          The Spot AI Dashboard offers a people search tool that requires the
          use of facial recognition technology and biometric data (“People
          Search Tool”). Due to numerous state and local laws governing the use,
          storage and processing of biometric data, you must carefully review
          and understand the following terms that govern the People Search Tool
          prior to your use. In using the People Search Tool, you acknowledge
          and agree that:
        </Typography>
        <ol>
          <ListItem content="1. you will not use the People Search Tool, including turning on the People Search Tool in the following states:">
            <NestedList>
              <li>California</li>
              <li>Colorado</li>
              <li>Florida</li>
              <li>Illinois</li>
              <li>Texas;</li>
            </NestedList>
          </ListItem>

          <ListItem content="2. you will not use the People Search Tool, including turning on the People Search Tool in the city of Portland, Oregon;" />
          <ListItem content="3. certain cities have banned government use of facial recognition technology and you will not use the People Search Tool if the ban in those cities applies to your situation;" />
          <ListItem content="4. you will only use the People Search Tool for the following limited purposes:">
            <NestedList>
              <li>
                detecting, preventing, protecting against, or responding to a
                security incident, identity theft, fraud, harassment, malicious
                or deceptive activity, or any illegal activity
              </li>
              <li>
                investigating, reporting, or prosecuting a person responsible
                for an action described in section (4)(a)
              </li>
              <li>preserving the integrity or security of systems</li>
              <li>
                in the State of Utah only, for the additional limited purpose of
                investigating, reporting, or prosecuting a person responsible
                for harming or threatening the integrity or security of systems;
              </li>
            </NestedList>
          </ListItem>
          <ListItem content="5. we reserve the right to restrict, suspend or disable your use of the People Search Tool if we believe you are violating these terms or the Spot Terms of Use;" />
          <ListItem content="6. your use of the People Search Tool outside of the geographical scope in sections 1 and 2 and outside the scope of use in sections 3 and 4 are material violations of the Spot AI Terms of Use and may result in suspension or termination of the services including the People Search Tool;" />
          <ListItem content="7. your use of the People Search Tool:">
            <NestedList>
              <li>
                is reasonably necessary and proportionate to the purposes listed
                in section 4 above
              </li>
              <li>
                is adequate, relevant, and limited to what is necessary in
                relation to the specific purposes listed in section 4
              </li>
              <li>
                includes your consideration of the nature and purpose of the
                collection, use, or retention of the biometric data collected,
                used, or retained;
              </li>
            </NestedList>
          </ListItem>
          <ListItem content="8. the biometric data must be subject to reasonable administrative, technical, and physical measures to protect the confidentiality, integrity, and accessibility of the biometric data and to reduce reasonably foreseeable risks of harm to consumers relating to the collection, use, or retention of personal data;" />
          <ListItem content="9. the requirements set forth in these terms are not intended to, and shall not, be construed as legal advice, and you should seek the advice of counsel, as necessary, to understand, interpret and comply with the various state and local laws that may be applicable to your specific situation; and" />
          <ListItem content="10. the laws governing the use of facial recognition technology and biometric data are continuing to evolve and we reserve the right to update these terms accordingly, in which case you will be notified of such updated terms via a new click-through agreement." />
        </ol>
        <Typography className="text-sm leading-normal">
          BY CLICKING THE “ACCEPT” BUTTON OR CHECK BOX PRESENTED WITH THESE
          TERMS, (A) YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE
          TO ABIDE BY THE TERMS; AND (B) YOU HAVE RECEIVED PERMISSION FROM AN
          AUTHORIZED REPRESENTATIVE OF YOUR EMPLOYER TO USE THE PEOPLE SEARCH
          TOOL IN ACCORDANCE WITH THESE TERMS. IF YOU DO NOT AGREE TO THESE
          TERMS OR YOUR EMPLOYER DOES NOT GIVE YOU PERMISSION, YOU MUST NOT USE
          THE PEOPLE SEARCH TOOL.
        </Typography>
      </DialogContent>
      <DialogActions className="p-4">
        <Button
          color="primary"
          variant="contained"
          className="font-normal shadow-none rounded-md w-[108px] text-sm leading-normal text-primary bg-[#DAEEFF] border border-solid border-[#007Ce4]/[.17]"
          onClick={cancel}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="font-bold shadow-none rounded-md text-sm leading-normal w-[178px] ml-1"
          disabled={!viewedBottom}
          onClick={() => {
            acceptSearchTerms();
            confirm?.();
          }}
        >
          Agree and Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export const ACCEPT_SEARCH_TERMS = gql`
  mutation acceptPeopleSearchTerms {
    acceptPeopleSearchTerms {
      id
      termsPeopleSearchAccepted
    }
  }
`;
