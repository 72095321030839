import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Typography,
  InputLabel,
  Button,
  ButtonGroup,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputBaseProps,
  TextField,
  CircularProgress,
} from "@mui/material";
import clsx from "clsx";
import { Formik, Form, useField } from "formik";
import { keyBy } from "lodash/fp";
import { ReactNode, useMemo, useState } from "react";
import * as yup from "yup";

import { useBreakpoints } from "@/util/useBreakpoints";

import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { useDialog } from "@/components/shared/Dialog";

import {
  IntegrationSource,
  useIntegrationQuery,
  useSetupIntegrationMutation,
} from "@/generated-models";

import { CFG_FAILED_MSG, CFG_SUCCESS_MSG } from "../constant";
import { INTEGRATION_SETUP_STATE_QUERY } from "../hooks";
import { IntegrationsDeviceTagsButton } from "./IntegrationsDeviceTagsButton";
import IntegrationsDeviceUptimeDialog, {
  Uptime,
} from "./IntegrationsDeviceUptimeDialog";
import IntegrationsLinkCamerasDialog, {
  IntegrationsLinkCamerasList,
} from "./IntegrationsLinkCamerasDialog";

interface IntegrationsSpotAIVendorInputsFormProps {
  id: number;
  schema?: any;
  setupState?: any;
  className?: string;
  onConnectionResult?: (success: boolean) => void;
  editMode?: boolean;
  initialState?: any;
  callback?: () => void;
  onBack?: () => void;
}

const validationSchema = yup.array().of(
  yup.object().shape({
    name: yup.string().min(5).required("Required"),
    slotIdx: yup.number(),
    normallyClosed: yup.boolean(),
    onLabel: yup.string().default("On"),
    offLabel: yup.string().default("Off"),
    uptime: yup
      .object()
      .shape({
        startTime: yup.string(),
        endTime: yup.string(),
        daysOfWeek: yup.array(yup.number()),
      })
      .notRequired(),
  })
);

interface InputProps {
  name: string;
  slotId: string;
  slotIdx: number;
  normallyClosed: boolean;
  onLabel?: string;
  offLabel?: string;
  uptime?: Uptime;
}

interface InputValues {
  macAddress: string;
  inputs: InputProps[];
}

interface InputSlotAccordionProps {
  slot: number;
  editMode?: boolean;
  expanded: boolean;
  source?: IntegrationSource;
  onCreate: (slot: number) => Promise<void>;
  onExpandToggle: (expanded: boolean) => void;
  inputData?: InputProps;
}

function getDefaultSlotName(slot: number, output?: boolean) {
  return `Digital ${output ? "Output" : "Input"} ${slot}`;
}

function getSlotId(slot: number, output?: boolean) {
  return `d${output ? "o" : "i"}${slot + 1}`;
}

function SlotInputField({
  label,
  field,
  placeholder,
  value,
  onChange,
}: {
  label: ReactNode;
  field: string;
  placeholder?: string;
  value?: string;
  onChange: InputBaseProps["onChange"];
}) {
  return (
    <div className="w-full">
      <InputLabel shrink htmlFor={field}>
        {label}
      </InputLabel>

      <TextField
        size="small"
        variant="outlined"
        className="-my-1"
        InputProps={{
          classes: { root: "rounded-lg" },
        }}
        fullWidth
        id={field}
        name={field}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
}

function InputSlotAccordionSummary({
  cameraLinkCount,
  onLabel,
  offLabel,
}: {
  cameraLinkCount: number;
  onLabel?: string;
  offLabel?: string;
}) {
  const { fitsDesktop } = useBreakpoints();
  const noCustomLabels = !onLabel && !offLabel;

  function eventLabel(field: string, value?: string) {
    return (
      <>
        <strong>
          {field}
          {value ? ":" : ""}
        </strong>{" "}
        {value ? value : ""}
      </>
    );
  }

  return (
    <div className="flex md:flex-row flex-col gap-1 md:gap-3 text-[#757575]">
      {noCustomLabels ? (
        <>
          <Typography className="text-sm leading-[16.41px]">
            <strong>On & Off</strong>
          </Typography>
          {fitsDesktop && <Divider className="h-4" orientation="vertical" />}
        </>
      ) : (
        <>
          <Typography className="text-sm leading-[16.41px]">
            {eventLabel("On", onLabel)}
          </Typography>
          {fitsDesktop && <Divider className="h-4" orientation="vertical" />}
          <Typography className="text-sm leading-[16.41px]">
            {eventLabel("Off", offLabel)}
          </Typography>
          {fitsDesktop && <Divider className="h-4" orientation="vertical" />}
        </>
      )}
      <Typography
        className={clsx("text-sm leading-[16.41px]", {
          "text-[#FF490F]": cameraLinkCount === 0,
        })}
      >
        <strong>{cameraLinkCount}</strong> Cameras Linked
      </Typography>
    </div>
  );
}

function InputSlotAccordion({
  slot,
  source,
  editMode,
  expanded,
  onExpandToggle,
  onCreate,
}: InputSlotAccordionProps) {
  const [{ value }, , { setValue }] = useField<InputProps[]>("inputs");
  const [loading, setLoading] = useState(false);

  const { open, opened, cancel } = useDialog();
  const data = value.find((i) => i.slotIdx === slot);
  const cameraLinkCount = source?.cameras.length || 0;
  const { fitsDesktop } = useBreakpoints();

  function handleChange(change: Partial<InputProps>) {
    const slotValueIdx = value.findIndex((i) => i.slotIdx === slot);

    const newValues = [...value];
    newValues.splice(slotValueIdx, 1, {
      ...value[slotValueIdx],
      slotIdx: slot,
      slotId: getSlotId(slot),
      ...change,
    });
    setValue(newValues);
  }

  return (
    <>
      {source && (
        <IntegrationsLinkCamerasDialog
          open={opened}
          onClose={cancel}
          source={source}
        />
      )}
      <Accordion
        classes={{ root: "before:hidden" }}
        expanded={expanded}
        className="shadow-none border border-solid border-[#ECECEC] bg-[#F4F4F4] rounded-lg max-w-[700px]"
        onChange={async (_, expanded) => {
          if (expanded && !source) {
            setLoading(true);
            await onCreate(slot);
            setLoading(false);
          }
          onExpandToggle(expanded);
        }}
      >
        <AccordionSummary
          expandIcon={source ? <ExpandMoreIcon /> : <AddIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          classes={{
            root: "min-h-[39px]",
            content: "my-1",
            expandIconWrapper: clsx(
              "py-1",
              source && !fitsDesktop && "self-start"
            ),
          }}
        >
          <div className="flex justify-start items-center gap-4">
            {!!data && (
              <div
                className={clsx("rounded-[43px] w-[5px] md:h-8 h-full", {
                  "bg-[#FF480F]": cameraLinkCount === 0,
                  "bg-[#2CB626]": cameraLinkCount > 0,
                })}
              />
            )}

            <div className="flex flex-col gap-0.5">
              <Typography className="text-lg leading-[21px]">
                Digital Input {slot}{" "}
                {data?.name && data?.name !== getDefaultSlotName(slot) && (
                  <>
                    {fitsDesktop && (
                      <>
                        : <strong>{data.name}</strong>
                      </>
                    )}
                    {!fitsDesktop && (
                      <div>
                        <strong>{data.name}</strong>
                      </div>
                    )}
                  </>
                )}
              </Typography>
              {data && (
                <InputSlotAccordionSummary
                  cameraLinkCount={cameraLinkCount}
                  onLabel={data?.onLabel}
                  offLabel={data?.offLabel}
                />
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails
          classes={{
            root:
              "px-4 pt-4 bg-[#FBFBFB] border-t border-solid border-[#E0E0E0]",
          }}
        >
          {loading && (
            <div className="flex items-center justify-center p-14 h-[400px]">
              <CircularProgress />
            </div>
          )}
          {!loading && (
            <div className="flex sm:flex-row flex-col gap-5 w-full">
              <div className="flex-1 flex flex-col gap-4">
                <SlotInputField
                  field="name"
                  label="Input Name"
                  value={data?.name}
                  onChange={(e) => {
                    handleChange({
                      name: e?.target.value,
                    });
                  }}
                />

                <FormControl>
                  <FormLabel
                    id="demo-row-radio-buttons-group-label"
                    className="text-xs leading-[14px] text-[#757575]"
                  >
                    Input Type
                  </FormLabel>
                  <RadioGroup
                    row
                    className="-mt-[6px]"
                    name="input-normally-open"
                    value={!!data?.normallyClosed}
                    onChange={(e) => {
                      handleChange({
                        normallyClosed: e?.target.value === "true",
                      });
                    }}
                  >
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      classes={{ label: "text-sm leading-4" }}
                      label="Normally Open"
                    />
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      classes={{ label: "text-sm leading-4" }}
                      label="Normally Closed"
                    />
                  </RadioGroup>
                </FormControl>

                <SlotInputField
                  field="onLabel"
                  label={
                    <span>
                      <strong>On</strong> Event Name
                    </span>
                  }
                  value={data?.onLabel}
                  onChange={(e) => {
                    handleChange({
                      onLabel: e?.target.value,
                    });
                  }}
                />

                <SlotInputField
                  field="offLabel"
                  label={
                    <span>
                      <strong>Off</strong> Event Name
                    </span>
                  }
                  value={data?.offLabel}
                  onChange={(e) => {
                    handleChange({
                      offLabel: e?.target.value,
                    });
                  }}
                />

                <div className="w-full">
                  <InputLabel shrink htmlFor="uptime">
                    I/O Uptime
                  </InputLabel>
                  {source && (
                    <IntegrationsDeviceUptimeDialog
                      title="I/O Uptime"
                      source={source}
                      uptime={data?.uptime}
                      onSubmit={(uptime) => {
                        handleChange({ uptime });
                      }}
                    />
                  )}
                </div>

                {source && (
                  <div>
                    <InputLabel shrink htmlFor="Tags">
                      Tags
                    </InputLabel>
                    <div className="-mt-2">
                      <IntegrationsDeviceTagsButton
                        className="h-8"
                        integrationId={source?.id}
                        currentTags={source?.tags}
                        siteName={source?.standardMeta?.name}
                        disableIconButton
                      />
                    </div>
                  </div>
                )}
              </div>
              {fitsDesktop && (
                <Divider className="h-[400px] w-2" orientation="vertical" />
              )}
              <div className="flex-1 flex flex-col gap-2">
                <Typography className="text-xs leading-[14px] opacity-70">
                  Linked Cameras
                </Typography>
                <Divider orientation="horizontal" />
                {cameraLinkCount === 0 && (
                  <Typography className="text-sm leading-[16.41px]">
                    Add cameras to this Input to sync your I/O board events with
                    camera footage.
                  </Typography>
                )}
                {source && <IntegrationsLinkCamerasList source={source} />}
                <div>
                  <Button
                    color="primary"
                    className="font-bold py-0 -ml-2"
                    onClick={() => {
                      open();
                    }}
                  >
                    + Link Cameras
                  </Button>
                </div>
              </div>
            </div>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export function IntegrationsSpotAIVendorInputsForm({
  id,
  setupState,
  className,
  onConnectionResult = () => {},
  callback,
  onBack,
  editMode,
}: IntegrationsSpotAIVendorInputsFormProps) {
  const [accordionExpanded, setAccordionExpanded] = useState<
    Record<number, boolean>
  >({});
  const { pushSnackbar } = useFeedback();

  const { data } = useIntegrationQuery({
    variables: {
      input: { id },
    },
  });

  const sources = useMemo(
    () => keyBy("standardMeta.slotIdx", data?.integration?.sources || []),
    [data?.integration?.sources]
  );

  const [setupIntegration, { loading }] = useSetupIntegrationMutation({
    refetchQueries: [
      { query: INTEGRATION_SETUP_STATE_QUERY, variables: { input: { id } } },
      "integration",
    ],
    onCompleted: () => {
      pushSnackbar(CFG_SUCCESS_MSG, FeedbackType.Success);
      onConnectionResult(true);
      if (callback) {
        callback();
      }
    },
    onError: (e) => {
      pushSnackbar(e.message || CFG_FAILED_MSG, FeedbackType.Error);
      onConnectionResult(false);
    },
  });

  const [addSlot] = useSetupIntegrationMutation({
    refetchQueries: [
      { query: INTEGRATION_SETUP_STATE_QUERY, variables: { input: { id } } },
      "integration",
    ],
    onError: (e) => {
      pushSnackbar(e.message || CFG_FAILED_MSG, FeedbackType.Error);
    },
  });

  const handleSubmit = async (values: InputValues) => {
    await setupIntegration({
      variables: {
        input: {
          integrationId: id,
          setupState: values,
        },
      },
    });
  };

  const initialValues: InputValues = {
    macAddress: setupState?.macAddress ?? "",
    inputs: setupState?.inputs
      ? setupState?.inputs.map((input: InputProps) => ({
          slotId: input.slotId,
          name: input.name,
          slotIdx: input.slotIdx,
          normallyClosed: input.normallyClosed || false,
          onLabel: input.onLabel,
          offLabel: input.offLabel,
          uptime: input.uptime,
        }))
      : [],
  };

  return (
    <div className={className}>
      <Formik
        onSubmit={(values) => handleSubmit(values)}
        validationSchema={validationSchema}
        initialValues={initialValues}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div className="spotSlotForm">
              <div className="flex flex-col gap-4">
                {[...Array(6)].map((_, idx) => {
                  const expanded = !!accordionExpanded[idx];
                  return (
                    <InputSlotAccordion
                      key={idx}
                      slot={idx}
                      editMode={editMode}
                      source={sources[idx] as IntegrationSource}
                      expanded={!!accordionExpanded[idx]}
                      onExpandToggle={() => {
                        setAccordionExpanded((val) => ({
                          ...val,
                          [idx]: !expanded,
                        }));
                      }}
                      onCreate={async (slot: number) => {
                        const insertedInput = {
                          slotId: getSlotId(slot),
                          slotIdx: slot,
                          name: getDefaultSlotName(slot),
                          normallyClosed: false,
                        };

                        setFieldValue("inputs", [
                          ...values.inputs,
                          insertedInput,
                        ]);
                        await addSlot({
                          variables: {
                            input: {
                              integrationId: id,
                              setupState: {
                                macAddress: setupState.macAddress,
                                inputs: [insertedInput],
                              },
                            },
                          },
                        });
                      }}
                    />
                  );
                })}
              </div>
            </div>
            <ButtonGroup className="mt-7 flex gap-2">
              {!editMode && (
                <Button
                  variant="contained"
                  className="shadow-none bg-[#DAEEFF] text-[#007CE4] text-sm leading-6 font-normal h-9 rounded-[6px]"
                  onClick={onBack}
                >
                  Back
                </Button>
              )}
              <Button
                type="submit"
                className="shadow-none font-normal text-sm leading-6 rounded-lg h-9 px-10 w-auto"
                variant="contained"
                color="primary"
                disabled={values.inputs.length === 0 || loading}
              >
                {editMode ? "Save" : "Save & Finish"}
              </Button>
            </ButtonGroup>
          </Form>
        )}
      </Formik>
    </div>
  );
}
