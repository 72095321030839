import ExpandIcon from "@mui/icons-material/ExpandMore";
import ListIcon from "@mui/icons-material/List";
import { Tab, Tabs } from "@mui/material";
import { useWindowSize } from "@react-hookz/web";
import clsx from "clsx";
import React, { PropsWithChildren, useEffect, useState } from "react";

import { MappingTool } from "@/components/MappingTool/Mapping";
import { MapIcon } from "@/components/Player/PlayerIcons";
import {
  DesktopGroupSelect,
  MobileCameraNavigation,
} from "@/components/View/ViewNavigation";
import {
  NavigationTab,
  useActiveCamIds,
  useActiveGroupAndCams,
} from "@/components/View/sharedViewHooks";

import { refetchOnMountPolicy } from "@/apolloClient";
import { useCamDrawerBaseQuery, useGroupsQuery } from "@/generated-models";

import { ErrorMessage } from "../ErrorMessage";
import { Loading } from "../Loading";

/**
 * Designed to replace the <MobileCameraDrawer /> component. Self-contained; requests
 * all data it needs.
 *
 * Note that this component is up for redesign, but it seems that the design itself
 * is still in progress as of sept 9 2022.
 * https://www.figma.com/file/nLL4E18oXJ9kKJjYMadV0M/Mobile-App?node-id=2409%3A41300
 */
export function WiredMobileCameraDrawer() {
  const [expanded, setExpanded] = useState(false);

  return (
    <div
      className={clsx(
        "flex flex-col transition-position absolute inset-x-0 bottom-0 z-40",
        {
          "pointer-events-none": !expanded,
        }
      )}
      style={{
        top: expanded ? 0 : `calc(100% - ${drawerButtonHeight}px)`,
      }}
    >
      {/* Drawer handle */}
      <CurvedCamDrawerButton expanded={expanded}>
        <button
          className="bg-transparent text-white flex-center gap-3 font-medium pointer-events-auto"
          onClick={() => setExpanded((value) => !value)}
        >
          <ExpandIcon
            className={clsx("transition-transform", {
              "rotate-180": !expanded,
            })}
          />
          Cameras
        </button>
      </CurvedCamDrawerButton>
      <div className="w-full flex-shrink grow overflow-y-auto bg-white">
        {expanded && (
          <WiredMobileCameraDrawerContent setExpanded={setExpanded} />
        )}
      </div>
    </div>
  );
}
function WiredMobileCameraDrawerContent({
  setExpanded,
}: {
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { data: groupsData, error: groupsError } = useGroupsQuery();
  const { data: camerasData, error: camerasError } = useCamDrawerBaseQuery(
    refetchOnMountPolicy
  );
  const activeCamIds = useActiveCamIds();
  const { activeGroup, setActiveGroup } = useActiveGroupAndCams(
    groupsData?.groups,
    camerasData?.cameras
  );
  const [navTab, setNavTab] = useState(NavigationTab.Cameras);

  // Delay rendering hidden content until after first render
  // (React 18 concurrent renderer might be a better solution than this)
  const [renderContent, setRenderContent] = useState(false);
  useEffect(() => {
    setTimeout(() => setRenderContent(true), 50);
  }, [setRenderContent]);

  const error = camerasError || groupsError;
  if (error) {
    return <ErrorMessage title="Error" description={error.message} />;
  }

  if (!camerasData || !groupsData) {
    return (
      <Loading grow className="h-full">
        Fetching Cameras
      </Loading>
    );
  }

  if (!renderContent) return null;

  return (
    <>
      {/* List/maps tabs */}
      <Tabs
        value={navTab}
        onChange={(_, value: NavigationTab) => setNavTab(value)}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        className="border-b border-[#E0E0E0]"
      >
        <Tab
          label={
            <>
              <ListIcon /> List
            </>
          }
          value={NavigationTab.Cameras}
          classes={{ root: "flex-row gap-2 font-normal" }}
        />
        <Tab
          label={
            <>
              <MapIcon /> Maps
            </>
          }
          value={NavigationTab.Maps}
          classes={{ root: "flex-row gap-2 font-normal" }}
        />
      </Tabs>
      {/* Camera filters */}
      <div className="px-2 pt-3 pb-1">
        <DesktopGroupSelect
          groups={groupsData.groups}
          activeGroupId={activeGroup?.id}
          setGroupId={setActiveGroup}
        />
      </div>
      {/* Cameras */}
      {navTab === NavigationTab.Cameras && (
        <MobileCameraNavigation
          cameras={camerasData.cameras}
          groups={groupsData.groups}
          activeGroup={activeGroup}
          activeCamIds={activeCamIds}
          onSelect={() => setExpanded(false)}
        />
      )}
      {/* Maps */}
      {navTab === NavigationTab.Maps && (
        <MappingTool
          activeTag={activeGroup}
          isFullWidth={false}
          setFullWidth={() => {}}
          setMobileViewMode={setNavTab}
        />
      )}
    </>
  );
}

const drawerButtonHeight = 48;
function CurvedCamDrawerButton({
  expanded,
  children,
  className,
}: PropsWithChildren<{ expanded?: boolean; className?: string }>) {
  const { width } = useWindowSize();
  const labelWidth = 180;
  const labelStart = width - labelWidth;
  const expandedY = drawerButtonHeight - 7;
  return (
    <div className={clsx("relative", className)}>
      <svg
        className={clsx(
          !expanded ? "drop-shadow-[0_0_6px_rgba(0,0,0,0.4)]" : ""
        )}
        viewBox={`0 0 ${width} ${drawerButtonHeight}`}
      >
        <path
          className="transition-all"
          fill="#007CE4"
          d={
            expanded
              ? // Do the fancy path transformation to allow transitioning between the two states.
                // Expanded: https://svg-path-visualizer.netlify.app/#M0%200C32%200%2051%200%2083%200H180V48H0V0
                `
    M0 0
    H${labelStart}
    C${labelStart + 32} 0 ${labelStart + 51} 0 ${labelStart + 83} 0
    H${width}
    V${drawerButtonHeight}
    H0
    V0
          `
              : // Collapsed: https://svg-path-visualizer.netlify.app/#M0%2041C32%2041%2051%200%2083%200H180V48H0V41
                `
    M0 ${expandedY}
    H${labelStart}
    C${labelStart + 32} ${expandedY} ${labelStart + 51} 0 ${labelStart + 83} 0
    H${width}
    V${drawerButtonHeight}
    H0
    V${expandedY}
                `
          }
        />
      </svg>
      {/* </div> */}
      <div className="absolute right-4 bottom-0 flex items-center h-full">
        {children}
      </div>
    </div>
  );
}
