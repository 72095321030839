import { Button, Typography } from "@mui/material";
import { useRef, useState, useEffect, memo } from "react";
import { BooleanParam, useQueryParam } from "use-query-params";

import { Fisheye } from "@/icons/Fisheye";

import { useBreakpoints } from "@/util/useBreakpoints";

import { LockRow } from "@/pages/Settings/LocationSettings/Camera/DeviceRow";

import CameraProxyPopover from "@/components/Camera/CameraProxyPopover";
import EditableText from "@/components/EditableText";
import { DeviceAuthenticationForm } from "@/components/Genius/Forms/AuthenticationForm";
import { useUpdateCamera } from "@/components/Genius/Forms/Camera/cameraHooks";

import {
  ConnectionValidation as Validation,
  DeviceDetailsQuery,
} from "@/generated-models";

import BaseModal from "../Modal/BaseModal";
import FisheyeChannelsList from "./Forms/Fisheye/FisheyeChannelsList";
import { DeleteDeviceButton, SecondaryFormState } from "./GeniusPopup";

function FisheyeModalComponent({
  device,
  onClose,
  lockRow,
}: {
  device: DeviceDetailsQuery["deviceScan"];
  onClose: () => void;
  lockRow?: LockRow;
}) {
  const { fitsDesktop } = useBreakpoints();
  const { update: updateCamera } = useUpdateCamera();
  const camera = device.cameras[0];
  const [configure, setConfigure] = useQueryParam("configure", BooleanParam);
  const deviceIsAuthenticated = device.status === Validation.Ok;
  const deviceHasCameras = !!device.cameras.length;

  const [expandAuthForm, setExpandAuthForm] = useState(!deviceIsAuthenticated);
  const [secondaryFormState, setSecondaryFormState] = useState(
    deviceIsAuthenticated || deviceHasCameras
      ? SecondaryFormState.Expanded
      : SecondaryFormState.Hidden
  );

  // Need to un-hide the secondary form if we were just recently authenticated for this device.
  useEffect(() => {
    if (
      secondaryFormState === SecondaryFormState.Hidden &&
      deviceIsAuthenticated
    ) {
      secondaryOnSetExpanded(true);
    }
  }, [deviceIsAuthenticated, secondaryFormState]);

  function secondaryOnSetExpanded(expanded: boolean) {
    if (expanded) {
      setExpandAuthForm(false);
      setSecondaryFormState(SecondaryFormState.Expanded);
    } else {
      setSecondaryFormState(SecondaryFormState.Contracted);
    }
  }

  const proxyButtonRef = useRef<HTMLButtonElement | null>(null);
  const [proxyPopoverOpen, setProxyPopoverOpen] = useState<boolean>(false);

  useEffect(() => {
    if (configure) {
      setTimeout(() => {
        setProxyPopoverOpen(true);
      }, 1);
    }
  }, [configure]);

  return (
    <BaseModal
      classes={{
        root: "max-w-4xl",
      }}
      onClose={onClose}
      header={
        <>
          <div className="flex items-center gap-2">
            <div className="flex-center flex-col">
              <Fisheye dark className="text-[20px]" />
            </div>
            <EditableText
              TypographyProps={{
                style: { fontSize: 18, fontWeight: "bold", lineHeight: 1 },
              }}
              initialValue="Fisheye Camera"
              disabled
              onSubmit={(value) => {
                if (camera) {
                  updateCamera(camera.id, undefined, value);
                }
              }}
            />
            {!deviceHasCameras && device.isManual && (
              <DeleteDeviceButton device={device} close={onClose} />
            )}
          </div>
          <div className="flex items-center gap-3">
            <Typography variant="body2">
              IP: <strong style={{ opacity: 0.8 }}>{device.ip}</strong>
            </Typography>
            <div className="w-[1px] h-4 bg-black bg-opacity-10" />
            <Typography variant="body2">
              Mac: <strong style={{ opacity: 0.8 }}>{device.mac}</strong>{" "}
              {device.vendor.toLowerCase() !== "unknown" &&
                `(${device.vendor.split(" ")[0]})`}
            </Typography>
            {fitsDesktop && (
              <Button
                ref={proxyButtonRef}
                size="small"
                color="primary"
                className="h-5"
                onClick={(e) => {
                  setProxyPopoverOpen(true);
                }}
              >
                Native Fisheye Camera Config
              </Button>
            )}
            <CameraProxyPopover
              open={proxyPopoverOpen}
              anchorEl={proxyButtonRef.current}
              onClose={() => {
                setProxyPopoverOpen(false);
                setConfigure(false);
              }}
              deviceId={device.id}
              deviceMac={device.mac}
              localTarget={device.ip}
              homeAppliance={device.cameras[0]?.appliance}
              reachableAppliances={device.reachableAppliances}
            />
          </div>
        </>
      }
    >
      <div className="flex">
        <div className="p-4 overflow-hidden">
          <DeviceAuthenticationForm
            lockRow={lockRow}
            expanded={expandAuthForm}
            device={device}
            expansionLocked={secondaryFormState === SecondaryFormState.Hidden}
            onSetExpanded={(expanded: boolean) => {
              setExpandAuthForm(expanded);
            }}
            onClose={onClose}
          />
          {secondaryFormState !== SecondaryFormState.Hidden && (
            <FisheyeChannelsList
              onSetExpanded={secondaryOnSetExpanded}
              expanded={secondaryFormState === SecondaryFormState.Expanded}
              device={device}
              onOpenNativeProxy={() => setProxyPopoverOpen(true)}
            />
          )}
        </div>
      </div>
    </BaseModal>
  );
}

export const FisheyeModal = memo(FisheyeModalComponent);
