import { Button } from "@mui/material";
import clsx from "clsx";
import { utcToZonedTime } from "date-fns-tz";
import {
  differenceInDays,
  differenceInHours,
  subDays,
  addMinutes,
} from "date-fns/fp";

import { DoubleArrow } from "@/icons/DoubleArrow";

import { useTimeRangeParams } from "@/pages/Maintain/hooks";

import { OptionsCard } from "@/components/Player/OptionsCard";
import { DateTimePickerUncontrolled } from "@/components/shared/DateTimePickerUncontrolled";

import { CameraDetailByIdQuery } from "@/generated-models";

type QuickTimelineRangeSelector = "Month" | "Week" | "Day" | "Custom";

interface TimeRangePickerSegmentProps {
  autoOk?: boolean;
  initialDate: Date;
  minDate: Date;
  maxDate: Date;
  onChange: (value: Date | null) => void;
}

function TimeRangeButton({
  className,
  selected,
  range,
  onClick,
}: {
  className?: string;
  selected: QuickTimelineRangeSelector;
  range: QuickTimelineRangeSelector;
  onClick: () => void;
}) {
  const active = range === selected;
  return (
    <Button
      className={clsx("px-0 min-w-[45px] max-w-[45px] text-xs", className, {
        "font-bold": active,
        "font-normal opacity-40 pb-3": !active,
      })}
      onClick={onClick}
    >
      <div className="flex flex-col items-center gap-y-1">
        <div>{range}</div>
        {active && (
          <div className="w-4 border-b-2 border-[#353D48] border-solid rounded-md" />
        )}
      </div>
    </Button>
  );
}

function getInitialTimelineGranularity(
  from: Date,
  to: Date,
  now: Date
): QuickTimelineRangeSelector {
  const daysDifference = differenceInDays(from)(to);
  if (differenceInHours(to)(now) === 0) {
    // is the date set to today
    switch (daysDifference) {
      case 1: {
        return "Day";
      }
      case 7: {
        return "Week";
      }
      case 30: {
        return "Month";
      }
      default: {
        return "Custom";
      }
    }
  }
  return "Custom";
}

function TimeRangePickerSegment({
  autoOk,
  initialDate,
  minDate,
  maxDate,
  onChange,
}: TimeRangePickerSegmentProps) {
  return (
    <div className="flex flex-row justify-between items-center cursor-pointer px-1">
      <DateTimePickerUncontrolled
        key={`start${Date.now()}`}
        initialDate={initialDate}
        onChange={onChange}
        minDateTime={minDate}
        maxDateTime={maxDate}
        inputProps={{
          className: "cursor-pointer pb-[6px]",
        }}
        disableMaskedInput
        inputFormat="eee M/d, hh:mm aaaaa'm'"
      />
    </div>
  );
}

export function MaintainDetailsTimeRangePicker({
  camera,
  now,
  timezone,
}: {
  camera: CameraDetailByIdQuery["camera"];
  now: Date;
  timezone: string;
}) {
  const firstSegment = camera.firstSegmentTime
    ? new Date(camera.firstSegmentTime)
    : null;
  const {
    fromQuery,
    toQuery,
    setTimeRangeParams,
    setDatePicker,
  } = useTimeRangeParams(now);
  const timelineGranularity: QuickTimelineRangeSelector = getInitialTimelineGranularity(
    fromQuery,
    toQuery,
    now
  );

  return (
    <div className="flex sm:flex-row flex-col justify-between items-center mb-5 gap-y-4">
      <OptionsCard
        className="min-w-[240px] max-w-[450px] m-0"
        title={"Time Range"}
        showIcon={false}
      >
        <div className="flex flex-col sm:flex-row sm:justify-around justify-center ml-3 flex-1 items-center w-full h-full">
          <TimeRangePickerSegment
            autoOk
            minDate={
              firstSegment &&
              firstSegment.getTime() > subDays(30)(now).getTime()
                ? firstSegment
                : subDays(30)(now)
            }
            maxDate={utcToZonedTime(toQuery, timezone)}
            initialDate={fromQuery}
            onChange={(value) =>
              value &&
              setTimeRangeParams({
                start: value.toISOString(),
              })
            }
          />
          <DoubleArrow className="mx-1" />
          <div className="flex flex-row justify-between items-center cursor-pointer px-1">
            <TimeRangePickerSegment
              minDate={utcToZonedTime(addMinutes(1)(fromQuery), timezone)}
              maxDate={now}
              initialDate={toQuery}
              onChange={(value) =>
                value &&
                setTimeRangeParams({
                  end: value.toISOString(),
                })
              }
            />
          </div>
        </div>
      </OptionsCard>
      <div>
        <TimeRangeButton
          className="mr-2"
          selected={timelineGranularity}
          range="Month"
          onClick={() => {
            setDatePicker(subDays(30)(now), now);
          }}
        />
        <TimeRangeButton
          selected={timelineGranularity}
          range="Week"
          onClick={() => {
            setDatePicker(subDays(7)(now), now);
          }}
        />
        <TimeRangeButton
          selected={timelineGranularity}
          range="Day"
          onClick={() => {
            setDatePicker(subDays(1)(now), now);
          }}
        />
      </div>
    </div>
  );
}
