import {
  Checkbox,
  FormControl,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";

import { useBreakpoints } from "@/util/useBreakpoints";

import { useMaintainDashboard, useUpdateMaintainDashboard } from "../hooks";

const NONE = "<<<NONE>>>";

export function MaintainMetricsSelectMenu() {
  const { data, loading } = useMaintainDashboard();
  const { fitsDesktop } = useBreakpoints();
  const [updating, setUpdating] = useState(false);
  const update = useUpdateMaintainDashboard([]);

  const values = useMemo(
    () => data?.maintainDashboard?.settings.metrics || [],
    [data?.maintainDashboard?.settings.metrics]
  );

  const menuItems = useMemo(() => {
    return (
      data?.maintainDashboard?.metrics.map((m) => {
        return (
          <MenuItem
            key={m.field}
            value={m.field}
            disabled={updating}
            onClick={async () => {
              setUpdating(true);
              await update(null, {
                metrics: values.includes(m.field)
                  ? values.filter((h) => h !== m.field)
                  : [...values, m.field],
              });
              setUpdating(false);
            }}
          >
            <ListItemIcon>
              <Checkbox checked={values.includes(m.field)} />
            </ListItemIcon>
            <ListItemText primary={m.label} secondary={m.description} />
          </MenuItem>
        );
      }) || []
    );
  }, [data?.maintainDashboard?.metrics, update, updating, values]);

  if (!data?.maintainDashboard?.id) return <></>;

  return (
    <FormControl
      size="small"
      style={{ minWidth: 130, backgroundColor: "white" }}
      fullWidth={!fitsDesktop}
    >
      <Select
        multiple
        variant="outlined"
        value={values.length === 0 ? [NONE] : values}
        placeholder="Metrics"
        className="rounded-lg py-1"
        disabled={loading || updating}
        renderValue={() => {
          return (
            <div className="flex items-center justify-start gap-2">
              <Typography>Metrics</Typography>
              {values.length !== 0 && (
                <div className="bg-[#DDEFFF] px-2 py-1 rounded-lg h-[22px] min-w-[22px] flex items-center justify-center">
                  <Typography className="text-primary text-xs leading-[14px]">
                    {(values || []).length} /{" "}
                    {(data?.maintainDashboard?.metrics || []).length}
                  </Typography>
                </div>
              )}
            </div>
          );
        }}
        inputProps={{ name: "metrics", id: "metrics" }}
      >
        {menuItems}
      </Select>
    </FormControl>
  );
}
