import { CircularProgress } from "@mui/material";
import { useAtomValue } from "jotai";

import { AssistantMessage } from "@/components/Assistant/AssistantMessage";

import { conversationAtom, conversationLoadingAtom } from "./hooks";

export function AssistantConversationHistory() {
  const conversationHistory = useAtomValue(conversationAtom);
  const conversationLoading = useAtomValue(conversationLoadingAtom);

  return (
    <div className="flex flex-col gap-3 w-full">
      {conversationHistory.map(({ isSender, content, ts }) => (
        <AssistantMessage
          key={`${content}-${ts}`}
          sender={isSender}
          content={content}
        />
      ))}
      {conversationLoading && (
        <div className="flex items-center w-full justify-center mt-4">
          <CircularProgress className="text-white" />
        </div>
      )}
    </div>
  );
}
