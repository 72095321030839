// import { SearchOutlined } from "@mui/icons-material";
// import { Divider, IconButton } from "@mui/material";
import clsx from "clsx";
import { utcToZonedTime } from "date-fns-tz";
import { format } from "date-fns/fp";
// import { uniq } from "lodash/fp";
import { useMemo } from "react";

// import { StringParam, useQueryParam } from "use-query-params";
import { FilterDateField } from "@/components/Filter/Fields/FilterDateField";
import { FilterBar } from "@/components/Filter/FilterBar";
import { FilterBarControls } from "@/components/Filter/FilterBarControls";

// import { SearchBox } from "@/components/SearchBox";
import { IntegrationEventData } from "../../hooks";
import { useIntegrationContext } from "../../useIntegrationsContext";
import { IntegrationsDetailsSummaryBar } from "../IntegrationsDetailsSummaryBar";

interface IntegrationDetailsEventSummaryBarProps {
  condensed?: boolean;
  loading?: boolean;
  className?: string;
  events: IntegrationEventData[];
}

export function IntegrationDetailsEventSummaryBar({
  condensed,
  className,
  loading,
  events,
}: IntegrationDetailsEventSummaryBarProps) {
  // const [showSearch, setShowSearch] = useState(false);
  // const [searchInputParam, setSearchInputParam] = useQueryParam(
  //   "search",
  //   StringParam
  // );
  const { eventCount } = useIntegrationContext();

  const timezone = events[0]?.cameras[0]?.location?.timezone;

  // const tags = useMemo(
  //   () =>
  //     uniq(events?.flatMap((e) => e.tags)).map((t) => ({
  //       label: t,
  //       value: t,
  //     })),
  //   [events]
  // );

  const filters = useMemo(
    () => [
      {
        placeholder: "Dates & Times",
        filterName: "datetime",
        label: "Date & Time",
        timezone,
        style: condensed ? { minWidth: 130 } : undefined,
        getValue: (value: string | null) => {
          return (
            value
              ?.split("|")
              .map((vi) =>
                format("MMM d, yyyy p")(utcToZonedTime(new Date(vi), timezone))
              )
              .join(" - ") || ""
          );
        },
        options: [],
        date: true,
      },
      // Hiding filters
      // ...(condensed
      //   ? []
      //   : [
      //       {
      //         placeholder: "Locations",
      //         filterName: "locations",
      //         label: "Locations",
      //         style: condensed ? { minWidth: 130 } : undefined,
      //         options: uniqBy(
      //           "value",
      //           events?.map((d) => ({
      //             label: d.sourceStandardMeta.siteName,
      //             value: String(d.sourceStandardMeta.siteName),
      //           })) || []
      //         ),
      //       },
      //       {
      //         placeholder: "Tags",
      //         filterName: "tags",
      //         style: condensed ? { minWidth: 130 } : undefined,
      //         label: "Tags",
      //         options: tags,
      //       },
      //     ]),
    ],
    [condensed, timezone]
  );

  return (
    <div className={clsx("flex flex-col justify-center", className)}>
      <IntegrationsDetailsSummaryBar
        className={clsx({
          "flex-col-reverse !items-start mb-4": condensed,
        })}
        count={eventCount}
        small={condensed}
        loading={loading}
        label="Result"
      >
        <div
          className={clsx("flex items-center gap-3", {
            "justify-between w-full mb-2": condensed,
          })}
        >
          <FilterBarControls
            className={clsx("hidden md:flex", condensed && "-ml-[14px]")}
            label="Filter:"
            filters={filters}
            components={{
              datetime: FilterDateField,
            }}
          />
          {/* Hiding filters */}
          {/* {!condensed && (
            <Divider
              className="h-[25px] md:block hidden"
              orientation="vertical"
            />
          )}
          {showSearch ? (
            <SearchBox
              className="min-w-[250px] w-full py-1"
              input={searchInputParam || ""}
              setInput={setSearchInputParam}
            />
          ) : (
            <IconButton
              onClick={() => {
                setShowSearch(true);
              }}
              className="bg-[#DDEFFF]"
              color="primary"
            >
              <SearchOutlined />
            </IconButton>
          )} */}
        </div>
      </IntegrationsDetailsSummaryBar>
      {!loading && (
        <FilterBar
          className={clsx("rounded-lg mt-5", {
            "!my-2": condensed,
          })}
          filters={filters}
        />
      )}
    </div>
  );
}
