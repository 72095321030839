import { SvgIconComponent } from "@mui/icons-material";
import VehicleIcon from "@mui/icons-material/DirectionsCar";
import PeopleIcon from "@mui/icons-material/People";
import { isBefore } from "date-fns/fp";

import { ReactComponent as ForkliftIcon } from "@/icons/forklift.svg";

import { Camera, IntelligenceDashboardType } from "@/generated-models";

import { intelligentFiltersConfig } from "../Search/intelligence/intelligence";
import {
  IntelligenceFeatureConfig,
  IntelligenceFeatureType,
} from "./constants";

type ObjectType = keyof typeof intelligentFiltersConfig;

const objectTypesKeys = Object.keys(intelligentFiltersConfig) as ObjectType[];

export function footageIsAvailable(
  footageStartDate: string | number,
  camera?: Pick<Camera, "firstSegmentTime">
) {
  const firstSegmentTime = camera?.firstSegmentTime;
  return (
    !!firstSegmentTime &&
    !isBefore(new Date(firstSegmentTime), new Date(footageStartDate))
  );
}

export function getIntObjectFilterConfig(
  objectTypes: string[] | string | null | undefined,
  type: IntelligenceDashboardType
) {
  const objectTypeList = Array.isArray(objectTypes)
    ? objectTypes
    : [objectTypes];

  const objectType = objectTypesKeys.find(
    (objectType) =>
      objectTypeList.includes(objectType) ||
      objectTypeList?.toString() ===
        intelligentFiltersConfig[objectType].objectIds.toString()
  );

  if (objectType) {
    return intelligentFiltersConfig[objectType];
  }
  if (type === IntelligenceDashboardType.Idle) {
    return intelligentFiltersConfig.vehicle;
  }

  if (type === IntelligenceDashboardType.Presence) {
    return intelligentFiltersConfig.people;
  }
  return intelligentFiltersConfig.vehicle;
}

export function getObjectTypeIcon(
  objectTypes: string[] | string | null | undefined,
  dashType: IntelligenceDashboardType
) {
  const type = getIntObjectFilterConfig(objectTypes, dashType).id;

  let TypeIcon = PeopleIcon;

  if (type === "forklift") {
    TypeIcon = ForkliftIcon as SvgIconComponent;
  } else if (type === "vehicle") {
    TypeIcon = VehicleIcon;
  }

  return TypeIcon;
}

export function getObjectLabel(
  objectTypes: string[] | string | null = [],
  dashType: IntelligenceDashboardType,
  multi?: boolean
) {
  const type = getIntObjectFilterConfig(objectTypes, dashType).id;

  switch (type) {
    case "vehicle":
      return `vehicle${multi ? "s" : ""}`;
    case "forklift":
      return `forklift${multi ? "s" : ""}`;
    default:
      return "people";
  }
}

// Maps a dashboard type to the corresponding feature type.
export function getDashboardFeatureType(
  type: IntelligenceDashboardType,
  objectTypes?: string[] | string | null
) {
  const entityType = getIntObjectFilterConfig(objectTypes, type).id;
  const isVehicleType = entityType === "vehicle";

  if (type === IntelligenceDashboardType.Compound) {
    return IntelligenceFeatureType.ComparativeInsights;
  }

  if (entityType === "forklift") {
    switch (type) {
      case IntelligenceDashboardType.Count:
        return IntelligenceFeatureType.ForkliftCounting;
      case IntelligenceDashboardType.Presence:
        return IntelligenceFeatureType.ForkliftPresence;
      default:
        return IntelligenceFeatureType.ForkliftIdleTime;
    }
  }

  switch (type) {
    case IntelligenceDashboardType.InterestList:
      return IntelligenceFeatureType.LicensePlateInterestLists;
    case IntelligenceDashboardType.Count:
      return isVehicleType
        ? IntelligenceFeatureType.VehicleCounting
        : IntelligenceFeatureType.PeopleCounting;
    case IntelligenceDashboardType.Presence:
      return isVehicleType
        ? IntelligenceFeatureType.VehiclePresence
        : IntelligenceFeatureType.PeoplePresence;
    case IntelligenceDashboardType.Report:
      return IntelligenceFeatureType.LicensePlateReports;
    default:
      return isVehicleType
        ? IntelligenceFeatureType.VehicleIdleTime
        : IntelligenceFeatureType.PeopleIdleTime;
  }
}

// Obtains the feature configuration for the given dashboard type and data.
export function getDashboardFeatureConfig(
  type: IntelligenceDashboardType,
  objectTypes?: string[] | string | null
) {
  return IntelligenceFeatureConfig[getDashboardFeatureType(type, objectTypes)];
}
