import { Box, CircularProgress, Typography } from "@mui/material";
import gql from "graphql-tag";
import { PropsWithChildren } from "react";

import KioskLoginWelcome from "@/pages/VideoWall/Kiosk/KioskLoginWelcome";
import { useKioskAuth } from "@/pages/VideoWall/Kiosk/auth";

import {
  KioskVideoWallFragmentDoc,
  WallCamerasFragmentDoc,
} from "@/generated-models";

export default function KioskAuth({ children }: PropsWithChildren<{}>) {
  const { userCode, currentKiosk } = useKioskAuth();

  if (currentKiosk) return <>{children}</>;

  if (userCode) {
    return <KioskLoginWelcome userCode={userCode} />;
  }

  return (
    <div className="w-full h-full flex-center flex-col">
      <CircularProgress />
      <Box m={1} />
      <Typography variant="body1">
        Kiosk is offline. Attempting to reconnect...
      </Typography>
    </div>
  );
}

gql`
  fragment KioskVideoWall on VideoWall {
    id
    name
    cameras {
      ... on Camera {
        ...WallCameras
      }
      ... on NotAllowed {
        id
        noAccess
      }
    }
    config {
      columns
      rows
      sizes
    }
  }
  ${WallCamerasFragmentDoc}
`;

gql`
  query kioskInfo {
    kioskInfo {
      id
      name
      code
      config {
        webRtcEnabled
      }
      videoWall {
        ... on VideoWall {
          ...KioskVideoWall
        }

        ... on RotatingVideoWall {
          id
          name
          pageDuration
          videoWalls {
            ...KioskVideoWall
          }
        }
      }
    }
  }
  ${KioskVideoWallFragmentDoc}
`;
