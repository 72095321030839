import { useFlags } from "launchdarkly-react-client-sdk";

import { AuditLog } from "../AuditLog";
import { AuditLogsPage } from "./AuditLogsPage";

export function AuditLogRouter() {
  const { auditLogs } = useFlags();

  return auditLogs ? <AuditLogsPage /> : <AuditLog />;
}
