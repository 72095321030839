import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  AutocompleteRenderInputParams,
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  Alert,
  ToggleButton,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { Autocomplete, ToggleButtonGroup } from "formik-mui";
import { omit } from "lodash/fp";
import { useState } from "react";
import { makeStyles } from "tss-react/mui";

import { Circle } from "@/icons/Circle";

import { isPTZSupported } from "@/util/isPTZSupported";

import { CamLifecycleButtons } from "@/pages/Settings/LocationSettings/Camera/CamLifecycleButtons";
import { LockRow } from "@/pages/Settings/LocationSettings/Camera/DeviceRow";

import { useMe } from "@/components/Auth";
import {
  defaultTextProps,
  getValidationErrors,
} from "@/components/Genius/Forms/utils";
import { GeniusDiscoveredLogo } from "@/components/Genius/GeniusDiscoveredLogo";
import { GeniusLogo } from "@/components/Genius/GeniusLogo";
import { useGenius } from "@/components/Genius/GeniusProvider";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";

import {
  ConnectionValidation,
  ConnectionValidation as Validation,
  DeviceDetailsQuery,
  DeviceListDocument,
  LifecycleStates,
  LocationCapacityDocument,
  useAddManualDeviceScanMutation,
  useUpdateDeviceMutation,
} from "@/generated-models";

export const BACKG_GREY = "#f5f5f5";
// const BACKG_RED = "#f0acac";

const useStyles = makeStyles()((theme) => ({
  formContainer: {
    padding: theme.spacing(0, 1),
  },

  formValid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#62b309",
    fontSize: 14,
    fontWeight: "bold",
    "& > svg": { margin: "0 2px 0 8px", fontSize: 18 },
  },

  icon: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 4px 0 6px",
    fontSize: 8,
    fontWeight: "bold",
    lineHeight: 0.6,
    "& svg": { fontSize: 15 },
  },

  headerButton: {
    padding: theme.spacing(0.5),
    borderRadius: 5,
    display: "flex",
    alignItems: "stretch",
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      background: "rgba(0,0,0,0.05)",
    },
  },
  buttonGroup: {
    color: "#007CE4",
    border: "1px solid #007CE4",
  },
  selectedButtonGroup: {
    color: "#007CE4 !important" as any,
    fontWeight: "bold !important" as any,
    background: "#DBEFFF !important" as any,
  },
}));

interface AuthenticationFormProps {
  device: DeviceDetailsQuery["deviceScan"];
  expanded: boolean;
  onSetExpanded?: (expanded: boolean) => void;
  lockRow?: LockRow;
  expansionLocked?: boolean;
  onClose: () => void;
}

export function DeviceAuthenticationForm({
  device,
  expanded,
  onSetExpanded = () => {},
  lockRow,
  expansionLocked = false,
  onClose,
}: AuthenticationFormProps) {
  const {
    id,
    ip,
    port,
    path,
    username,
    password,
    onvifUsername,
    onvifPassword,
    isNvr,
    vendor,
    isFisheye,
  } = device;
  const { classes } = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [advancedOpen, setAdvancedOpen] = useState(false);
  const { pushSnackbar } = useFeedback();
  const [geniusScan] = useGenius();
  const [updateDevice] = useUpdateDeviceMutation({
    onError: (error) => {
      console.error(error);
      pushSnackbar(
        "Something went wrong, please try again...",
        FeedbackType.Error
      );
    },
  });

  const cameras = device.cameras;

  const disableFields =
    !isNvr &&
    cameras.length > 0 &&
    cameras[0]?.lifecycleState !== LifecycleStates.Enabled;

  const conErrors = getValidationErrors(device.status);
  const channelsCount = isNvr ? device.channels.length : 0;
  const valid = device.status === Validation.Ok;

  const me = useMe();
  const showPtz = me?.organization.flags.ptz && isPTZSupported(vendor);

  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        ip,
        port: port.toString(),
        username,
        password,
        ptzEnabled: !!onvifUsername && !!onvifPassword,
        onvifUsername: onvifUsername ?? "",
        onvifPassword: onvifPassword ?? "",
        path: path || "/",
        isNvr,
        channels: channelsCount,
      }}
      initialTouched={{
        ip: !!conErrors?.ip,
        port: !!conErrors?.port,
        username: !!conErrors?.username,
        password: !!conErrors?.password,
        path: !!conErrors?.path,
      }}
      initialErrors={conErrors}
      onSubmit={async (values, { setErrors, resetForm }) => {
        // Trim any whitespaces added by mistake
        const creds = {
          ip: values.ip.trim(),
          port: parseInt(values.port.trim(), 10),
          username: values.username.trim(),
          password: values.password.trim(),
          onvifUsername: !showPtz
            ? onvifUsername
            : values.ptzEnabled && !!values.onvifUsername
            ? values.onvifUsername.trim()
            : null,
          onvifPassword: !showPtz
            ? onvifPassword
            : values.ptzEnabled && !!values.onvifPassword
            ? values.onvifPassword.trim()
            : null,
          path: values.path.trim() || "/",
          // We always want isNvr: true value for fisheye cams.
          isNvr: isFisheye || values.isNvr,
          channels:
            values.channels > channelsCount ? values.channels : undefined,
        };

        const input = {
          id,
          ...omit("ip", creds),
        };
        return updateDevice({ variables: { input } })
          .then(({ data }) => {
            if (data) {
              if (data.updateDevice.__typename === "StreamValidationError") {
                const error = data.updateDevice;
                throw new Error(`Error ${error.code}: ${error.message}`);
              }
              resetForm();
              if (lockRow) lockRow(data.updateDevice.mac);
            }
          })
          .then(() => geniusScan())
          .catch((e) => {
            pushSnackbar(e.message, FeedbackType.Error);
            console.error(e);
          });
      }}
    >
      {({ isSubmitting, dirty, touched, errors, values, setFieldValue }) => (
        <Box display="flex" flexDirection="column" width="100%">
          <ButtonBase
            disabled={expansionLocked}
            className={classes.headerButton}
            onClick={() => onSetExpanded(!expanded)}
          >
            <Box
              mr={0.75}
              display="flex"
              flexDirection="row"
              alignItems={expanded ? "flex-start" : "center"}
            >
              {valid ? (
                <CheckCircleIcon style={{ fontSize: 18, color: "#62b309" }} />
              ) : (
                <Circle style={{ fontSize: 18, opacity: 0.1 }} />
              )}
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="center"
            >
              <Typography variant="h6" style={{ lineHeight: 1, marginTop: 1 }}>
                Connection
              </Typography>
              {expanded && (
                <Typography
                  variant="caption"
                  style={{ lineHeight: 1.4, textAlign: "left" }}
                >
                  Configure {isFisheye ? "Fisheye" : isNvr ? "NVR" : "camera"}{" "}
                  username and password
                </Typography>
              )}
            </Box>
            {!expanded && (
              <Tooltip title="Device was automatically authenticated via Spot Genius">
                <Box
                  ml={2}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent={expanded ? "flex-end" : "center"}
                >
                  {device.geniusAuthConfigured && <GeniusLogo width="70" />}
                </Box>
              </Tooltip>
            )}
            <Box flexGrow={1}></Box>
            <Button
              component="div"
              size="small"
              color="primary"
              style={{
                opacity: expanded ? 0 : 1,
              }}
              variant="outlined"
              onClick={() => onSetExpanded(!expanded)}
            >
              Edit
            </Button>
          </ButtonBase>
          <Collapse in={expanded}>
            <Form className={classes.formContainer}>
              <Box m={2} />
              <Grid container spacing={2}>
                {!!errors.ip && (
                  <Grid item xs={12}>
                    <Alert color="error">{values.ip} Unreachable</Alert>
                  </Grid>
                )}
                {cameras.length === 0 && !isFisheye && (
                  <Grid item xs={Boolean(values.isNvr) ? 8 : 12}>
                    <Typography
                      style={{ fontSize: 11, color: "rgba(0, 0, 0, 0.54)" }}
                    >
                      Video Source
                    </Typography>
                    <Field
                      onChange={(_: object, value: any) => {
                        setFieldValue("isNvr", value);
                      }}
                      size="small"
                      exclusive
                      component={ToggleButtonGroup}
                      name="isNvr"
                      type="checkbox"
                      disabled={disableFields || isSubmitting}
                      style={{
                        fontSize: 14,
                        fontWeight: "bold",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <ToggleButton
                        value={false}
                        classes={{ selected: classes.selectedButtonGroup }}
                        className={classes.buttonGroup}
                        style={{ flexGrow: 1 }}
                      >
                        Camera
                      </ToggleButton>
                      <ToggleButton
                        value={true}
                        classes={{ selected: classes.selectedButtonGroup }}
                        className={classes.buttonGroup}
                        style={{ flexGrow: 1 }}
                      >
                        NVR
                      </ToggleButton>
                    </Field>
                  </Grid>
                )}
                {values.isNvr && (
                  <>
                    <Grid
                      item
                      style={{ width: 70 }}
                      xs={cameras.length === 0 ? 4 : 12}
                    >
                      <Typography
                        style={{ fontSize: 11, color: "rgba(0, 0, 0, 0.54)" }}
                      >
                        {isFisheye ? "Fisheye" : "NVR"} Channels
                      </Typography>
                      <Field
                        variant="outlined"
                        name="channels"
                        {...defaultTextProps}
                        disabled={disableFields || isSubmitting}
                        type="number"
                        validate={(input: number) => {
                          if (!Number.isInteger(input) || input < 0)
                            return "Invalid";
                          if (input < channelsCount)
                            return "Cannot be decreased";
                        }}
                      />
                    </Grid>
                    {isFisheye && (
                      <Grid xs={8} />
                    )}
                  </>
                )}
                <Grid item xs={6}>
                  <Field
                    name="username"
                    label="Username"
                    {...defaultTextProps}
                    disabled={disableFields || isSubmitting}
                    InputProps={{
                      ...defaultTextProps.InputProps,
                      style: {
                        ...defaultTextProps.InputProps?.style,
                        paddingLeft: 0,
                      },
                      startAdornment: device.geniusUsername ===
                        values.username && (
                        <Tooltip title="Discovered by Spot Genius">
                          <InputAdornment
                            position="start"
                            style={{ marginLeft: 6, marginRight: 4 }}
                          >
                            <GeniusDiscoveredLogo height="15" width="20" />
                          </InputAdornment>
                        </Tooltip>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name="password"
                    label="Password"
                    {...defaultTextProps}
                    disabled={disableFields || isSubmitting}
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      ...defaultTextProps.InputProps,
                      style: {
                        ...defaultTextProps.InputProps?.style,
                        paddingLeft: 0,
                      },
                      startAdornment: device.geniusPassword ===
                        values.password && (
                        <Tooltip title="Discovered by Spot Genius">
                          <InputAdornment
                            position="start"
                            style={{ marginLeft: 6, marginRight: 4 }}
                          >
                            <GeniusDiscoveredLogo height="15" width="20" />
                          </InputAdornment>
                        </Tooltip>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            aria-label="toggle password visibility"
                            onClick={() =>
                              setShowPassword((currentState) => !currentState)
                            }
                          >
                            {showPassword ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    size="small"
                    onClick={() => setAdvancedOpen(!advancedOpen)}
                    style={{ marginLeft: -4 }} // Nudge it to the left
                  >
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <Typography
                        variant="body1"
                        style={{ opacity: 0.6, fontSize: 14 }}
                      >
                        Advanced Settings
                      </Typography>
                      <Box m={0.25} />
                      <KeyboardArrowUpIcon
                        style={{
                          opacity: 0.6,
                          transform:
                            advancedOpen || !!errors.port || !!errors.path
                              ? undefined
                              : "rotate(180deg)",
                          transition: "all 0.2s ease-in-out",
                        }}
                      />
                    </Box>
                  </Button>
                  <Box m={1} />

                  <Collapse in={advancedOpen || !!errors.port || !!errors.path}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Field
                          name="port"
                          component={Autocomplete}
                          options={["554", "8554", "8555", "10554", "2554"]}
                          freeSolo
                          forcePopupIcon
                          disableClearable
                          disabled={disableFields || isSubmitting}
                          fullWidth
                          size="small"
                          renderInput={(
                            params: AutocompleteRenderInputParams
                          ) => (
                            <TextField
                              {...params}
                              onChange={(e) => {
                                setFieldValue("port", e.target.value);
                              }}
                              InputProps={{
                                ...params.InputProps,
                                ...defaultTextProps.InputProps,
                                startAdornment: device.geniusPorts &&
                                  device.geniusPorts.indexOf(
                                    Number(values.port)
                                  ) > -1 && (
                                    <Tooltip title="Discovered by Spot Genius">
                                      <InputAdornment
                                        position="start"
                                        style={{ marginRight: 0 }}
                                      >
                                        <GeniusDiscoveredLogo
                                          height="15"
                                          width="20"
                                        />
                                      </InputAdornment>
                                    </Tooltip>
                                  ),
                              }}
                              InputLabelProps={{
                                ...params.InputLabelProps,
                                ...defaultTextProps.InputLabelProps,
                              }}
                              inputProps={{
                                // style: { padding: "0 0 3px" },
                                ...params.inputProps,
                              }}
                              FormHelperTextProps={
                                defaultTextProps.FormHelperTextProps
                              }
                              error={touched["port"] && !!errors["port"]}
                              helperText={errors["port"]}
                              label="RTSP Port"
                            />
                          )}
                          validate={(value: string) =>
                            Number(value.trim()) ? undefined : "Port invalid"
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name="path"
                          label="Test Path"
                          {...defaultTextProps}
                          disabled={disableFields || isSubmitting}
                          helperText={
                            "Any RTSP path on the device to test authentication."
                          }
                        />
                      </Grid>
                      {showPtz && (
                        <PTZCredentials
                          enabled={values.ptzEnabled}
                          inputsDisabled={disableFields || isSubmitting}
                        />
                      )}
                    </Grid>
                  </Collapse>
                </Grid>
                {!valid || dirty || Object.values(touched).some((v) => v) ? (
                  <div className="w-full flex justify-between flex-center">
                    {!isNvr && cameras[0]?.lifecycleState && (
                      <CamLifecycleButtons
                        {...cameras[0]}
                        hideActivateButton
                        resetForm={() => {
                          // setSelectedStreamId(channel?.streams[0]?.id);
                          console.log("what should I be doing here?");
                        }}
                        onClose={onClose}
                      />
                    )}
                    <Button
                      color="primary"
                      variant="outlined"
                      type="submit"
                      disabled={isSubmitting}
                      style={{ fontSize: 12, fontWeight: 400 }}
                    >
                      {isFisheye ? "Update Fisheye" : isNvr
                        ? "Update NVR"
                        : `${valid ? "Re-" : ""}Authenticate`}
                      {isSubmitting && (
                        <>
                          <Box mx={1} />
                          <CircularProgress size="20px" />
                        </>
                      )}
                    </Button>
                  </div>
                ) : (
                  <div className="flex-center w-full font-bold text-[#62b309]">
                    <CheckCircleIcon />
                    <div>Valid Connection</div>
                  </div>
                )}
              </Grid>
            </Form>
          </Collapse>
        </Box>
      )}
    </Formik>
  );
}

interface ManualAuthenticationFormProps {
  onClose?: () => void;
  locationId: number;
}
export function ManualDeviceAuthenticationForm({
  onClose = () => {},
  locationId,
}: ManualAuthenticationFormProps) {
  const { classes } = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [advancedOpen, setAdvancedOpen] = useState(false);
  const { pushSnackbar } = useFeedback();
  const [addDevice] = useAddManualDeviceScanMutation();
  const [geniusScan] = useGenius();

  const me = useMe();
  const showPtz = me?.organization.flags.ptz;

  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        ip: "",
        port: "554",
        username: "admin",
        password: "",
        ptzEnabled: false,
        onvifUsername: "",
        onvifPassword: "",
        path: "/",
        isNvr: false,
        channels: 0,
      }}
      onSubmit={async (values, { setErrors, resetForm }) => {
        // Trim any whitespaces added by mistake
        const creds = {
          ip: values.ip.trim(),
          port: Number(values.port),
          username: values.username.trim(),
          password: values.password.trim(),
          onvifUsername:
            showPtz && values.ptzEnabled && !!values.onvifUsername
              ? values.onvifUsername.trim()
              : null,
          onvifPassword:
            showPtz && values.ptzEnabled && !!values.onvifPassword
              ? values.onvifPassword.trim()
              : null,
          path: values.path.trim() || "/",
          isNvr: values.isNvr,
          channels: values.channels > 0 ? values.channels : undefined,
        };

        // Add manual camera
        return addDevice({
          variables: {
            input: {
              ...omit("isNvr", creds),
              locationId,
              failOnValidationError: true,
            },
          },
          refetchQueries: [
            { query: LocationCapacityDocument, variables: { locationId } },
            {
              query: DeviceListDocument,
              variables: { locationId: locationId },
            },
          ],
          update(_, { data }) {
            if (!data) return;

            onClose();
            geniusScan();
          },
        }).catch((error: Error) => {
          const message = error.message;
          if (message.includes(Validation.IpInvalid)) {
            setErrors({ ip: "Cannot reach IP" });
            return;
          } else if (message.includes("already exist")) {
            setErrors({ ip: "Already discovered" });
            return;
          }

          if (message.includes("VALIDATION_ERROR")) {
            const validationError = getValidationErrors(
              message.replace("VALIDATION_ERROR: ", "") as ConnectionValidation
            );
            if (!!validationError) {
              setErrors(validationError);
              return;
            }
          }
          console.error(error);
          pushSnackbar(
            "Something went wrong, failed to connect to device...",
            FeedbackType.Error
          );
        });
      }}
    >
      {({ isSubmitting, dirty, touched, errors, values, setFieldValue }) => (
        <Box display="flex" flexDirection="column" width="100%">
          <div className="flex">
            <Box
              mr={0.75}
              display="flex"
              flexDirection="row"
              alignItems="flex-start"
            >
              <Circle style={{ fontSize: 18, opacity: 0.1 }} />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="center"
            >
              <Typography variant="h6" style={{ lineHeight: 1, marginTop: 1 }}>
                Connection
              </Typography>
              <Typography
                variant="caption"
                style={{ lineHeight: 1.4, textAlign: "left" }}
              >
                Configure camera username and password
              </Typography>
            </Box>
          </div>
          <Form className={classes.formContainer}>
            <Box m={2} />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field
                  name="ip"
                  label="IP Address"
                  {...defaultTextProps}
                  disabled={isSubmitting}
                  validate={(value: string) =>
                    value.trim() ? undefined : "IP invalid"
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="username"
                  label="Username"
                  {...defaultTextProps}
                  disabled={isSubmitting}
                  InputProps={{
                    ...defaultTextProps.InputProps,
                    style: {
                      ...defaultTextProps.InputProps?.style,
                      paddingLeft: 0,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="password"
                  label="Password"
                  {...defaultTextProps}
                  disabled={isSubmitting}
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    ...defaultTextProps.InputProps,
                    style: {
                      ...defaultTextProps.InputProps?.style,
                      paddingLeft: 0,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setShowPassword((currentState) => !currentState)
                          }
                        >
                          {showPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  size="small"
                  onClick={() => setAdvancedOpen(!advancedOpen)}
                  style={{ marginLeft: -4 }} // Nudge it to the left
                >
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Typography
                      variant="body1"
                      style={{ opacity: 0.6, fontSize: 14 }}
                    >
                      Advanced Settings
                    </Typography>
                    <Box m={0.25} />
                    <KeyboardArrowUpIcon
                      style={{
                        opacity: 0.6,
                        transform:
                          advancedOpen || !!errors.port || !!errors.path
                            ? undefined
                            : "rotate(180deg)",
                        transition: "all 0.2s ease-in-out",
                      }}
                    />
                  </Box>
                </Button>
                <Box m={1} />

                <Collapse in={advancedOpen || !!errors.port || !!errors.path}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field
                        name="port"
                        component={Autocomplete}
                        options={["554", "8554", "8555", "10554", "2554"]}
                        freeSolo
                        forcePopupIcon
                        disableClearable
                        disabled={isSubmitting}
                        fullWidth
                        size="small"
                        renderInput={(
                          params: AutocompleteRenderInputParams
                        ) => (
                          <TextField
                            {...params}
                            onChange={(e) => {
                              setFieldValue("port", e.target.value);
                            }}
                            InputProps={{
                              ...params.InputProps,
                              ...defaultTextProps.InputProps,
                            }}
                            InputLabelProps={{
                              ...params.InputLabelProps,
                              ...defaultTextProps.InputLabelProps,
                            }}
                            inputProps={{
                              // style: { padding: "0 0 3px" },
                              ...params.inputProps,
                            }}
                            FormHelperTextProps={
                              defaultTextProps.FormHelperTextProps
                            }
                            error={touched["port"] && !!errors["port"]}
                            helperText={errors["port"]}
                            label="RTSP Port"
                          />
                        )}
                        validate={(value: string) =>
                          Number(value.trim()) ? undefined : "Port invalid"
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name="path"
                        label="Test Path"
                        {...defaultTextProps}
                        disabled={isSubmitting}
                        helperText={
                          "Any RTSP path on the device to test authentication."
                        }
                      />
                    </Grid>
                    {showPtz && (
                      <PTZCredentials
                        enabled={values.ptzEnabled}
                        inputsDisabled={isSubmitting}
                      />
                    )}
                  </Grid>
                </Collapse>
              </Grid>

              <Grid item className={classes.formValid} xs={12}>
                {!dirty || Object.values(touched).some((v) => v) ? (
                  <Button
                    color="primary"
                    variant="outlined"
                    type="submit"
                    disabled={isSubmitting}
                    fullWidth
                    style={{ fontSize: 12, fontWeight: 400 }}
                  >
                    Authenticate
                    {isSubmitting && (
                      <>
                        <Box mx={1} />
                        <CircularProgress size="20px" />
                      </>
                    )}
                  </Button>
                ) : (
                  <>
                    <CheckCircleIcon />
                    <div>Valid Connection</div>
                  </>
                )}
              </Grid>
            </Grid>
          </Form>
        </Box>
      )}
    </Formik>
  );
}

function PTZCredentials({
  enabled,
  inputsDisabled,
}: {
  enabled: boolean;
  inputsDisabled: boolean;
}) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <Grid item xs={12}>
        <label className="text-sm opacity-60">
          <Field
            type="checkbox"
            name="ptzEnabled"
            className="mr-2"
            disabled={inputsDisabled}
          />
          Enable PTZ
        </label>
      </Grid>
      {enabled && (
        <>
          <Grid item xs={6}>
            <Field
              name="onvifUsername"
              label="ONVIF Username"
              {...defaultTextProps}
              disabled={inputsDisabled}
              InputProps={{
                ...defaultTextProps.InputProps,
                style: {
                  ...defaultTextProps.InputProps?.style,
                  paddingLeft: 0,
                },
              }}
              validate={(value: string | null) =>
                value && value.trim() ? undefined : "Username invalid"
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              name="onvifPassword"
              label="ONVIF Password"
              {...defaultTextProps}
              disabled={inputsDisabled}
              type={showPassword ? "text" : "password"}
              InputProps={{
                ...defaultTextProps.InputProps,
                style: {
                  ...defaultTextProps.InputProps?.style,
                  paddingLeft: 0,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowPassword((currentState) => !currentState)
                      }
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              validate={(value: string | null) =>
                value && value.trim() ? undefined : "Password invalid"
              }
            />
          </Grid>
        </>
      )}
    </>
  );
}
