import {
  Box,
  Button,
  Link,
  Popover,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Switch,
  Typography,
  Alert,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { makeStyles } from "tss-react/mui";

import { CopyTextButton } from "@/components/CopyTextButton";
import { PasswordToggleButton } from "@/components/PasswordToggleButton";
import { ZendeskArticle, ZendeskLink } from "@/components/Zendesk/ZendeskLink";

import { LocationWithAppliancesQuery } from "@/generated-models";
import { usePrefixOrgSlug } from "@/hooks/useOrgRouteBase";

const useStyles = makeStyles()((theme) => ({
  appliance: {
    padding: "24px 16px",
    marginBottom: 4,
    backgroundColor: "white",
  },
  status: {
    display: "flex",
    alignItems: "center",
    "& > span": {
      marginLeft: 8,
    },
    "&:first-of-type": {
      marginBottom: 4,
    },
  },
  serialNumber: { fontSize: 24, fontWeight: "bold", lineHeight: 1 },
  popoverPaper: {
    display: "flex",
    flexDirection: "column",
    width: 400,
    borderRadius: 12,
  },
}));

interface TroubleshootPopoverProps {
  open: boolean;
  onClose: () => void;
  anchorEl?: null | Element | ((element: Element) => Element);
  appliance: Required<
    NonNullable<Required<LocationWithAppliancesQuery>["location"]>
  >["appliances"][0];
}

function returnOverflowResultForUnit8(numberOfInt: number) {
  //to prevent Overflow here, First get Unit8 in String(2), Then transfer back to String(10)
  return parseInt(numberOfInt.toString(2).slice(-8), 2);
}

function generateFallbackIPs(applianceSerial: string, connNum: number) {
  if (applianceSerial) {
    var SumOfASCIInumber = 0;
    for (var charactor of applianceSerial) {
      SumOfASCIInumber += Number(charactor.charCodeAt(0));
    }
    if (connNum > 0) {
      connNum -= 1;
    }
    //For Octet0, it never exceed unit8. 6374/255=24.99, So we do not need take consideration of Uint8.
    var octet0 = Math.floor(
      (((SumOfASCIInumber * 9) % 6374) / 255 + 200 + 25 * connNum) % 255
    );
    //Octet1 was Limited to Uint8
    var octet1 = Math.floor(
      returnOverflowResultForUnit8((SumOfASCIInumber * 9) % 6374) % 255
    );
    return [octet0, octet1];
  } else {
    return ["No Serial Number", "No Serial Number"];
  }
}

export default function TroubleshootPopover({
  open,
  onClose,
  anchorEl,
  appliance,
}: TroubleshootPopoverProps) {
  const { classes } = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [isRemote, setIsRemote] = useState(appliance.health.online);
  useEffect(() => {
    if (!open) {
      setShowPassword(false);
    }
  }, [open]);
  const prefixOrgSlug = usePrefixOrgSlug();

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onClose={onClose}
      classes={{ paper: classes.popoverPaper }}
    >
      <Box
        style={{ boxSizing: "border-box" }}
        width="100%"
        px={3}
        mt={2}
        mb={1}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h6">
          Troubleshooting Tools
          <ZendeskLink article={ZendeskArticle.CONFIGURE_STATIC_IP} />
        </Typography>
        <Box flexGrow={1} />
        <Typography
          style={{
            opacity: isRemote ? 0.5 : 1,
            fontWeight: isRemote ? "normal" : "bold",
            transition: "all 0.1s ease-in-out",
          }}
          variant="body2"
        >
          Local
        </Typography>
        <Box m={0.5}>
          <Switch
            size="small"
            color="default"
            checked={isRemote}
            onChange={(e) => setIsRemote(e.target.checked)}
          />
        </Box>
        <Typography
          style={{
            opacity: isRemote ? 1 : 0.5,
            fontWeight: isRemote ? "bold" : "normal",
            transition: "all 0.1s ease-in-out",
          }}
          variant="body2"
        >
          Remote
        </Typography>
      </Box>
      {isRemote ? (
        <Box
          mb={2}
          alignItems="center"
          justifyContent="center"
          display="flex"
          flexDirection="column"
        >
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              window.open(
                prefixOrgSlug(
                  `/localproxy/appliance/${appliance.serialNumber}`
                ),
                appliance.serialNumber,
                "directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes"
              );
            }}
          >
            Connect to Remote Appliance
          </Button>

          <Typography
            variant={"body1"}
            style={{
              marginTop: 10,
              marginLeft: 15,
              marginRight: 15,
              fontSize: 12,
              textAlign: "center",
            }}
          >
            *A remote connection may not always be possible in network
            environments. Use a local connection if remote access fails.
          </Typography>
        </Box>
      ) : (
        <Box>
          <Box px={2} mb={2}>
            <Alert severity="info">
              <strong>If internet is on a second network</strong>, you need to
              use the second NIC provided. <br />
              Refer to the{" "}
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.notion.so/spotai/Installing-Spot-ef366697b5e9444991429cca9d3f191b"
              >
                onboarding guide
              </Link>{" "}
              or{" "}
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href="mailto:help@spotai.co"
              >
                contact us
              </Link>
              .
            </Alert>
          </Box>
          <Stepper orientation="vertical" style={{ paddingTop: 0 }}>
            <Step active>
              <StepLabel>Connect</StepLabel>
              <StepContent>
                <Typography variant={"body1"} style={{ fontSize: 15 }}>
                  Connect to the same LAN as your appliance
                </Typography>
              </StepContent>
            </Step>
            <Step active>
              <StepLabel>Navigate</StepLabel>
              <StepContent>
                <Typography variant={"body1"} style={{ fontSize: 15 }}>
                  Navigate to{" "}
                  <Link
                    href={`https://${appliance.serialNumber}.local/www`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://{appliance.serialNumber}.local/www
                  </Link>
                </Typography>

                <Typography variant={"body1"} style={{ fontSize: 15 }}>
                  If the device is unavailable, try fallback static IP
                  addresses.
                </Typography>
                <ul className="space-y-2 mt-2">
                  <li>
                    <span>Wire Connection 1</span>
                    <ul className="border border-gray-300 bg-gray-50 border-solid rounded-lg p-2">
                      <li>
                        <Link
                          href={`https://10.175.${
                            generateFallbackIPs(appliance.serialNumber, 1)[0]
                          }.${
                            generateFallbackIPs(appliance.serialNumber, 1)[1]
                          }/www`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          https://10.175.
                          {generateFallbackIPs(appliance.serialNumber, 1)[0]}.
                          {generateFallbackIPs(appliance.serialNumber, 1)[1]}
                          /www
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={`https://192.168.${
                            generateFallbackIPs(appliance.serialNumber, 1)[0]
                          }.${
                            generateFallbackIPs(appliance.serialNumber, 1)[1]
                          }/www`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          https://192.168.
                          {generateFallbackIPs(appliance.serialNumber, 1)[0]}.
                          {generateFallbackIPs(appliance.serialNumber, 1)[1]}
                          /www
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    Wire Connection 2
                    <ul className="border border-gray-300 bg-gray-50 border-solid rounded-lg p-2">
                      <li>
                        <Link
                          href={`https://10.175.${
                            generateFallbackIPs(appliance.serialNumber, 2)[0]
                          }.${
                            generateFallbackIPs(appliance.serialNumber, 2)[1]
                          }/www`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          https://10.175.
                          {generateFallbackIPs(appliance.serialNumber, 2)[0]}.
                          {generateFallbackIPs(appliance.serialNumber, 2)[1]}
                          /www
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={`https://192.168.${
                            generateFallbackIPs(appliance.serialNumber, 2)[0]
                          }.${
                            generateFallbackIPs(appliance.serialNumber, 2)[1]
                          }/www`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          https://192.168.
                          {generateFallbackIPs(appliance.serialNumber, 2)[0]}.
                          {generateFallbackIPs(appliance.serialNumber, 2)[1]}
                          /www
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </StepContent>
            </Step>
            <Step active>
              <StepLabel>Authenticate</StepLabel>
              <StepContent>
                <Typography variant={"body1"} className="text-[15px] mb-4">
                  Enter the following password when prompted
                </Typography>
                <div className="bg-black text-white rounded-xl flex mx-8 px-4 py-2 items-center">
                  <Typography
                    variant={"body1"}
                    style={{ fontFamily: "monospace" }}
                    className="w-full"
                  >
                    {showPassword ? appliance.localPassword : "************"}
                  </Typography>
                  <PasswordToggleButton
                    showPassword={showPassword}
                    setShowPassword={setShowPassword}
                    fontSize={18}
                    className="text-white"
                  />
                  <CopyTextButton
                    text={appliance.localPassword}
                    fontSize={18}
                    style={{ color: "white" }}
                  />
                </div>
              </StepContent>
            </Step>
          </Stepper>
        </Box>
      )}
    </Popover>
  );
}
