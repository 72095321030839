import CopyIcon from "@mui/icons-material/FileCopyOutlined";
import { IconButton, IconButtonProps, Tooltip } from "@mui/material";
import * as clipboard from "clipboard-polyfill/build/clipboard-polyfill.promise";
import { useState } from "react";

export function CopyTextButton({
  text,
  fontSize,
  ...buttonProps
}: { text: string; fontSize: number } & Pick<
  IconButtonProps,
  "size" | "className" | "style"
>) {
  const [copied, setCopied] = useState(false);
  return (
    <Tooltip
      title={copied ? "Copied!" : "Copy to clipboard"}
      onClose={() => setCopied(false)}
      classes={{ popper: "pointer-events-none" }}
    >
      <IconButton
        size="small"
        {...buttonProps}
        onClick={async () => {
          await clipboard.writeText(text);
          setCopied(true);
        }}
      >
        <CopyIcon style={{ fontSize: fontSize }} />
      </IconButton>
    </Tooltip>
  );
}
