import Editor, { Monaco, OnChange, OnMount } from "@monaco-editor/react";
import { Button, CircularProgress, Typography } from "@mui/material";
import clsx from "clsx";
import { editor } from "monaco-editor";
import { useEffect, useRef, useState } from "react";
import { useLocalStorage } from "react-use";

import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";

import {
  useApplianceConfigSchemaQuery,
  useUpdateApplianceConfigurationMutation,
} from "@/generated-models";

import {
  useSelectedAppliance,
  useApplianceConfig,
  useApplianceConfigHistory,
} from "./hooks";
import { registerApplianceConfigExtensions } from "./utils";

interface MaintainConfigEditorInputProps {
  onChange?: OnChange;
  onMount?: OnMount;
  value?: string;
  containerClassName?: string;
  className?: string;
}

export function MaintainConfigEditorInput({
  containerClassName,
  ...props
}: MaintainConfigEditorInputProps) {
  const [dark] = useLocalStorage("maintainEditDark", false);
  const [dirty, setDirty] = useState(false);
  const items = useApplianceConfigHistory();
  const editorRef = useRef<editor.IStandaloneCodeEditor>();
  const monacoRef = useRef<Monaco>();
  const { pushSnackbar } = useFeedback();

  const lastSave = items[0]?.date;
  const lastDelivered = items[0]?.deliveryDate;

  const [
    updateConfiguration,
    { loading: isUpdating },
  ] = useUpdateApplianceConfigurationMutation({
    refetchQueries: ["applianceConfig", "applianceConfigsMetadata"],
    onError: (error) => {
      pushSnackbar(error.message, FeedbackType.Error);
    },
  });

  const { selectedAppliance } = useSelectedAppliance();
  const {
    data: { config, ...editorProps },
    loading: loadingApplianceConfig,
  } = useApplianceConfig();

  const {
    data: schema,
    loading: loadingMetadata,
  } = useApplianceConfigSchemaQuery();

  const loading = loadingMetadata || loadingApplianceConfig;

  function onWindowResize() {
    editorRef.current?.layout();
  }

  useEffect(() => {
    window.addEventListener("resize", onWindowResize);
    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, []);

  return (
    <div className={clsx("w-full h-[calc(100%-120px)]", containerClassName)}>
      {loading && (
        <div className="flex items-center justify-center w-full h-full">
          <CircularProgress className="text-white" />
        </div>
      )}
      {!loading && (
        <>
          <Editor
            {...props}
            {...editorProps}
            width="100%"
            height="100%"
            defaultValue="{}"
            defaultLanguage="json"
            theme={dark ? "vs-dark" : "light"}
            options={{
              fontSize: 12,
              scrollBeyondLastLine: true,
            }}
            onChange={(value) => {
              setDirty(value !== editorProps.value);
            }}
            onMount={(editor, monaco) => {
              editorRef.current = editor;
              monacoRef.current = monaco;
              registerApplianceConfigExtensions(
                monaco,
                schema?.applianceConfigSchema?.schema
              );
            }}
          />
          <div className="flex items-center justify-between mt-2 gap-2 mx-4">
            <div>
              {lastSave && (
                <Typography className="text-[10px] mr-2 opacity-60 font-mono">
                  Last Saved: {lastSave}
                </Typography>
              )}
              {lastDelivered && (
                <Typography className="text-[10px] mr-2 opacity-60 font-mono">
                  Last Delivered: {lastDelivered}
                </Typography>
              )}
            </div>
            <div className="flex items-center gap-2">
              <Button
                variant="contained"
                className="shadow-none bg-[#DAEEFF] text-[#007CE4] font-normal rounded-[6px] disabled:bg-[#EEEEEE] disabled:text-[#9C9C9C]"
                disabled={!dirty}
                onClick={() => {
                  editorRef?.current?.setValue(editorProps.value);
                }}
              >
                Reset
              </Button>
              <Button
                variant="contained"
                color="primary"
                className="font-normal rounded-[6px] shadow-none"
                disabled={isUpdating || !dirty}
                onClick={() => {
                  const newValue = editorRef.current?.getValue() || "{}";
                  try {
                    JSON.parse(newValue);
                    updateConfiguration({
                      variables: {
                        input: {
                          serialNumber: selectedAppliance?.serialNumber || "",
                          data: editorRef.current?.getValue() || "{}",
                          createdAt: config?.createdAt,
                          configType: "iotCore",
                        },
                      },
                    });
                    setDirty(false);
                  } catch (e) {
                    pushSnackbar(
                      "Malformed JSON, please ensure the configuration is JSON compliant.",
                      FeedbackType.Error
                    );
                  }
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
