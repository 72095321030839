import {
  Box,
  Checkbox,
  CircularProgress,
  ClickAwayListener,
  Fade,
  FormControlLabel,
  Paper,
  Popper,
  PopperProps,
} from "@mui/material";
import formatDistanceToNow from "date-fns/esm/formatDistanceToNow";
import gql from "graphql-tag";
import { useSetAtom } from "jotai";
import { makeStyles } from "tss-react/mui";

import { GeniusLogo } from "@/components/Genius/GeniusLogo";
import {
  hlsDebugState,
  useHlsDebugState,
} from "@/components/Player/HlsDebugOverlay";

import { isProductionEnv } from "@/environment";
import {
  GetGeniusDiagnosticsQuery,
  useGetGeniusDiagnosticsQuery,
} from "@/generated-models";

const useStyles = makeStyles()((theme) => ({
  paper: {
    borderRadius: 8,
    minHeight: 200,
    minWidth: 350,
    pointerEvents: "auto",
  },
  header: {
    padding: theme.spacing(1.5),
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0 2px 4px 0 rgba(137, 137, 137, 0.5)",
    zIndex: 5,
    position: "relative",
  },
  body: {
    zIndex: 4,
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexGrow: 1,
    width: "100%",
  },
}));

interface SpotGeniusDiagnosticProps {
  open: boolean;
  setOpen?: (open: boolean) => void;
  cameraId: number;
  anchorEl: PopperProps["anchorEl"];
  PopperProps?: Omit<PopperProps, "children" | "open">;
}

export default function GeniusDiagnosticsPopup(
  props: SpotGeniusDiagnosticProps
) {
  const { classes } = useStyles();
  const { open, cameraId, anchorEl, setOpen = () => {} } = props;
  const { data, loading, error } = useGetGeniusDiagnosticsQuery({
    variables: {
      cameraId,
    },
  });

  return (
    <Popper
      placement="left"
      style={{ zIndex: 1300 }} // somehow this number works to kick it above everything like toolbars but under the autocompletes sorry
      disablePortal={false}
      open={open}
      anchorEl={anchorEl}
      transition
      {...props.PopperProps}
    >
      {({ TransitionProps }) => (
        <ClickAwayListener
          mouseEvent={"onMouseDown"}
          onClickAway={() => {
            if (setOpen) setOpen(false);
          }}
        >
          <Fade {...TransitionProps} timeout={200}>
            <Paper
              elevation={9}
              className={classes.paper}
              onMouseEnter={() => setOpen(true)}
              onMouseLeave={() => setOpen(false)}
            >
              <Box className={classes.header}>
                <GeniusLogo />
              </Box>
              <Box className={classes.body}>
                {loading ? (
                  <CircularProgress />
                ) : error ? (
                  <>Cannot load diagnostics</>
                ) : data ? (
                  <GeniusDiagnosticBody
                    onClose={() => setOpen(false)}
                    data={data}
                  />
                ) : (
                  <>Cannot load diagnostics</>
                )}
              </Box>
            </Paper>
          </Fade>
        </ClickAwayListener>
      )}
    </Popper>
  );
}

interface SpotGeniusDiagnosticBodyProps {
  data: GetGeniusDiagnosticsQuery;
  onClose?: () => void;
}

export function GeniusDiagnosticBody(props: SpotGeniusDiagnosticBodyProps) {
  const hlsDebug = useHlsDebugState();
  const setHlsDebug = useSetAtom(hlsDebugState);

  return (
    <Box width="100%" flexGrow={1} style={{ overflow: "hidden" }}>
      <ul className="pt-2 pl-2 list-disc list-inside">
        <li>
          Last appliance heartbeat:{" "}
          {formatDistanceToNow(
            Date.parse(props.data.camera.appliance.health.lastSeen!),
            { addSuffix: true }
          )}
        </li>
        <li>
          Last camera heartbeat:{" "}
          {formatDistanceToNow(Date.parse(props.data.camera.heartbeat!), {
            addSuffix: true,
          })}
        </li>
        <li>
          <a
            href={`https://monitoring.spotai.co/location/${
              props.data.camera.appliance.location.id
            }${isProductionEnv ? "" : "?staging=1"}`}
            target="_blank"
            rel="noreferrer noopener"
          >
            Open Monitoring
          </a>
        </li>
        <li>
          <a
            href={`https://app.datadoghq.com/logs?query=host%3A${props.data.camera.appliance.serialNumber}&cols=host%2Cservice%2C%40cam&index=main`}
            target="_blank"
            rel="noreferrer noopener"
          >
            Open Datadog Logs
          </a>
        </li>
      </ul>

      <FormControlLabel
        control={
          <Checkbox
            className="ml-1"
            checked={hlsDebug}
            onChange={() => {
              setHlsDebug(!hlsDebug);
            }}
            color="primary"
            size="small"
          />
        }
        label="HLS.js Debugging"
      />
    </Box>
  );
}

export const GENIUS_DIAGNOSTIC_QUERY = gql`
  query getGeniusDiagnostics($cameraId: Int!) {
    camera(id: $cameraId) {
      id
      heartbeat
      appliance {
        id
        serialNumber
        location {
          id
        }
        health {
          lastSeen
        }
      }
    }
  }
`;
