import { ReactComponent as Back10Icon_ } from "@/icons/back-10.svg";
import { ReactComponent as CaseSmallIcon_ } from "@/icons/case-small.svg";
import { ReactComponent as CloseFullscreenIcon_ } from "@/icons/close-fullscreen.svg";
import { ReactComponent as CloseIcon_ } from "@/icons/close.svg";
import { createIconComponent } from "@/icons/createIconComponent";
import { ReactComponent as DownloadIcon_ } from "@/icons/download.svg";
import { ReactComponent as ExpandIcon_ } from "@/icons/expand.svg";
import { ReactComponent as Forward10Icon_ } from "@/icons/forward-10.svg";
import { ReactComponent as FullscreenIcon_ } from "@/icons/fullscreen.svg";
import { ReactComponent as MapIcon_ } from "@/icons/map.svg";
import { ReactComponent as MinimizeIcon_ } from "@/icons/minimize.svg";
// import { ReactComponent as HdIcon_ } from "@/icons/hd.svg";
import { ReactComponent as PauseIcon_ } from "@/icons/pause.svg";
import { ReactComponent as PlayIcon_ } from "@/icons/play.svg";
import { ReactComponent as RemoveIcon_ } from "@/icons/remove.svg";
import { ReactComponent as ReplayIcon_ } from "@/icons/replay.svg";
import { ReactComponent as SettingsIcon_ } from "@/icons/settings.svg";
import { ReactComponent as ShareSaveIcon_ } from "@/icons/share-save.svg";
import { ReactComponent as ShareIcon_ } from "@/icons/share.svg";
import { ReactComponent as ZoomInIcon_ } from "@/icons/zoom-in.svg";
import { ReactComponent as ZoomOutIcon_ } from "@/icons/zoom-out.svg";

export const Back10Icon = createIconComponent(Back10Icon_);
export const CaseSmallIcon = createIconComponent(CaseSmallIcon_);
export const CloseIcon = createIconComponent(CloseIcon_);
export const CloseFullscreenIcon = createIconComponent(CloseFullscreenIcon_);
export const DownloadIcon = createIconComponent(DownloadIcon_);
export const Forward10Icon = createIconComponent(Forward10Icon_);
export const FullscreenIcon = createIconComponent(FullscreenIcon_);
// export const HdIcon = createIconComponent(HdIcon_);
export const MapIcon = createIconComponent(MapIcon_);
export const ExpandIcon = createIconComponent(ExpandIcon_);
export const MinimizeIcon = createIconComponent(MinimizeIcon_);
export const PauseIcon = createIconComponent(PauseIcon_);
export const PlayIcon = createIconComponent(PlayIcon_);
export const RemoveIcon = createIconComponent(RemoveIcon_);
export const ReplayIcon = createIconComponent(ReplayIcon_);
export const SettingsIcon = createIconComponent(SettingsIcon_);
export const ShareIcon = createIconComponent(ShareIcon_);
export const ShareSaveIcon = createIconComponent(ShareSaveIcon_);
export const ZoomInIcon = createIconComponent(ZoomInIcon_);
export const ZoomOutIcon = createIconComponent(ZoomOutIcon_);
