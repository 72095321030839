import CreateIcon from "@mui/icons-material/Create";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  Box,
  Button,
  ButtonBase,
  Grid,
  Popper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import mapboxgl from "mapbox-gl";
import React, { useEffect, useRef, useState } from "react";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import { makeStyles, withStyles } from "tss-react/mui";

import { Loading } from "@/components/Loading";
import { CameraIcon } from "@/components/MappingTool/CameraIcon";
import { SearchBox } from "@/components/SearchBox";

import {
  CamerasByLocationLayoutIdQuery,
  LifecycleStates,
  useCamerasByLocationIdQuery,
  useCamerasByLocationLayoutIdQuery,
} from "@/generated-models";
import { useCustomScrollbarStyles } from "@/layout/theme";

const useStyles = makeStyles<{ scale: number }>()((theme, { scale }) => ({
  assignCamButton: {
    background: "#FFF",
    "&:hover": {
      backgroundColor: "#FFF",
    },
    minWidth: "30px",
    filter: "drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));",
    border: "1px solid #CF00F1",
  },
  assignCamButtonText: {
    fontWeight: "bold",
    fontSize: "12px",
    color: "#CF00F1",
  },
  assignCamButtonTextNoCam: {
    fontStyle: "italic",
    opacity: 0.5,
  },
  holder: {
    borderRadius: "4px 4px 0px 0px",
    minWidth: "350px",
    boxShadow: "0px 0px 10.061px rgba(0, 0, 0, 0.5);",
    background: "#FFF",
  },
  heading: {
    backgroundColor: "#353D48",
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  headingText: {
    color: "#FFF",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
  },
  searchBox: {
    background: "rgb(220,220,220,0.5)",
    padding: theme.spacing(1),
  },
  searchBoxMessage: {
    fontSize: "10px",
    color: "#353d48",
    opacity: "0.5",
    lineHeight: "12px",
    fontStyle: "italic",
  },
  listWrapper: {
    maxWidth: "100%",
    maxHeight: "300px",
    overflow: "auto",
    background: "rgb(220,220,220,0.5)",
  },
  cameraListContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    background: "#FFF",
  },
  cameraList: {
    //background: "#FFF",
    //padding: theme.spacing(1),
  },
  cameraListTypeHeading: {
    fontSize: "12px",
    lineHeight: "14px",
    fontWeight: "bold",
    marginBottom: theme.spacing(0.5),
  },
  divider: {
    height: "1px",
    background: "#E3E3E3",
  },
  cameraItem: {
    textAlign: "justify",
  },
  cameraThumbnail: {
    height: "30px",
    width: "30px",
    border: "0.7px solid #DDDDDD",
    borderRadius: "0.7px",
    display: "inherit",
  },
  cameraName: {
    fontSize: "14px",
    fontWeight: "bold",
  },
  locationName: {
    fontSize: "12px",
    opacity: 0.5,
  },
  scaled: {
    transform: `scale(${scale})`,
  },
  noMoreCamerasText: {
    fontSize: "11px",
    display: "flex",
    textAlign: "center",
    opacity: "0.5",
    color: "#353D48",
    fontStyle: "italic",
  },
  cameraStill: {
    border: "2px solid #CF00F1",
    borderRadius: "4px",
    filter: "drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25))",
    maxHeight: "100%",
  },
  cameraTab: {
    fontSize: "12px",
    background: "#FFF",
  },
}));

export type LayoutSelectedCamera = Omit<
  CamerasByLocationLayoutIdQuery["locationLayout"]["cameras"][0]["camera"],
  "location"
>;

export interface NewLayoutCamera {
  cameraId: number;
  layoutId: number;
  angle: number;
  position: {
    x: number;
    y: number;
  };
  camera: LayoutSelectedCamera;
}

interface LayoutCameraProps {
  locationId: number;
  layoutId: number;
  camera?: LayoutSelectedCamera | null;
  onUpdate?: (
    updateCallback: (
      old: Omit<NewLayoutCamera, "position">
    ) => Omit<NewLayoutCamera, "position"> & {
      translation?: {
        x: number;
        y: number;
      };
    }
  ) => void;
  onAdd?: (
    value: Omit<NewLayoutCamera, "position"> & {
      translation?: {
        x: number;
        y: number;
      };
    }
  ) => void;
  onDelete: () => void;
  initialAngle?: number;
  offset: {
    top: number;
    left: number;
  };
  disableDrag?: boolean;
  mapMarker?: mapboxgl.Marker | null;
  scale?: number;
}

export function AddCamera({
  locationId,
  layoutId,
  camera = null,
  onUpdate,
  onAdd,
  initialAngle = 0,
  offset,
  onDelete,
  disableDrag = false,
  mapMarker = null,
  scale = 1,
}: LayoutCameraProps) {
  const { classes } = useStyles({ scale });
  const { classes: scrollbarClasses } = useCustomScrollbarStyles();

  const [angle, setAngle] = useState(initialAngle);
  const [dragging, setDragging] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [
    selectedCamera,
    setSelectedCamera,
  ] = useState<LayoutSelectedCamera | null>(camera ? camera : null);
  const [draggedDistance, setDraggedDistance] = useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 });
  const [iconElement, setIconElement] = useState<HTMLDivElement | null>(null);
  const [popperContainer, setPopperContainer] = useState<HTMLDivElement | null>(
    null
  );

  const [tab, setTab] = useState(0);

  const searchInput = searchString || "";
  const splitSearchInput = searchInput
    .split(" ")
    .map((str) => str.toLowerCase());

  function filterCams(cam: LayoutSelectedCamera) {
    const searchText = cam.name.toLowerCase();
    return splitSearchInput.every((search) => searchText.includes(search));
  }

  const [selectingCamera, setSelectingCamera] = useState(!camera);
  const loadingInitialState = useRef(true);
  const {
    loading: locationCamsLoading,
    data: locationCams,
    // error: locationCamsError,
  } = useCamerasByLocationIdQuery({ variables: { id: locationId } });

  const {
    loading: layoutCamsLoading,
    data: layoutCams,
    // error: layoutCamsError,
  } = useCamerasByLocationLayoutIdQuery({
    variables: {
      id: layoutId,
    },
  });

  const handleCameraSelect = (camera: LayoutSelectedCamera) => {
    setSelectedCamera(camera);
    setSelectingCamera(false);

    onUpdate?.((old) => ({
      ...old,
      camera,
      cameraId: camera.id,
    }));

    onAdd?.({
      cameraId: camera.id,
      layoutId,
      angle,
      camera,
      translation: draggedDistance,
    });
  };
  const handleDragStop = (_: DraggableEvent, data: DraggableData) => {
    setDragging(false);
    // update drag distance only if the draggable div has moved
    if (data.x === 0 && data.y === 0) return;
    onUpdate?.((old) => ({
      ...old,
      translation: data,
    }));
  };

  useEffect(() => {
    setDraggedDistance({ x: 0, y: 0 });
  }, [offset?.left, offset?.top]);

  useEffect(() => {
    loadingInitialState.current = false;
    mapMarker?.on("dragend", () => {
      onUpdate?.((old) => ({
        ...old,
      }));
    });
    // eslint-disable-next-line
  }, []);

  if (!layoutCams || !locationCams) return <Loading>Loading Cameras</Loading>;

  const assignedIds = layoutCams.locationLayout.cameras.map((c) => c.camera.id);

  const unassignedCams = locationCams.location?.cameras.filter((c) => {
    return (
      assignedIds.indexOf(c.id) === -1 &&
      c.lifecycleState === LifecycleStates.Enabled
    );
  });

  return (
    <>
      <Draggable
        disabled={disableDrag}
        cancel={".cancelClass, .holder"}
        onStart={() => setDragging(true)}
        onStop={(e, d) => handleDragStop(e, d)}
        position={draggedDistance}
        onDrag={(e, d) => {
          setDraggedDistance(d);
        }}
      >
        {locationCamsLoading || layoutCamsLoading ? (
          <Loading />
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              left: `${offset.left}%`,
              top: `${offset.top}%`,
              zIndex: 1,
            }}
            ref={(node) => {
              if (!node) return;
              setIconElement(node);
            }}
          >
            <div
              className={classes.scaled}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              ref={(node) => {
                if (!node) return;
                setPopperContainer(node);
              }}
            >
              {iconElement && (
                <CameraIcon
                  angle={angle}
                  setAngle={(angle) => {
                    setAngle(angle);
                    onUpdate?.((old) => ({
                      ...old,
                      angle,
                    }));
                  }}
                  element={iconElement}
                  mapMarker={mapMarker}
                />
              )}
            </div>
          </div>
        )}
      </Draggable>

      {popperContainer && (
        <Popper
          placement={"right-start"}
          style={{
            height: "100px",
            minWidth: "250px",
            marginTop: "-60px",
            marginLeft: "60px",
          }}
          className={classes.scaled}
          open={!!popperContainer}
          anchorEl={{
            getBoundingClientRect() {
              // popper can use a fake bounding box
              // To calculate the position of the grid besides the icon,
              // If the position (top) of the icon is close to bottom (view port height - top) < information box height
              // move the bounding box up.
              const iconBounds = popperContainer?.getBoundingClientRect();
              const viewPort = document.body.getBoundingClientRect();
              const cameraInfoHeight =
                document.querySelector(".measureClass")?.getBoundingClientRect()
                  .height ?? 0;

              return {
                y:
                  viewPort.height - iconBounds.top < cameraInfoHeight / 2
                    ? viewPort.height - cameraInfoHeight / 2
                    : iconBounds.top,
                top:
                  viewPort.height - iconBounds.top < cameraInfoHeight / 2
                    ? viewPort.height - cameraInfoHeight / 2
                    : iconBounds.top,
                bottom: iconBounds.bottom,
                x: iconBounds.left,
                left: iconBounds.left,
                right: iconBounds.right,
                height: 500,
                width: 250,
              } as ClientRect;
            },
            contextElement: popperContainer,
          }}
          container={popperContainer}
          transition
          modifiers={[
            {
              name: "flip",
              enabled: true,
            },
          ]}
        >
          <Grid
            container
            spacing={1}
            direction="column"
            className={clsx("cancelClass", "measureClass")}
          >
            <Grid item style={{ display: "flex" }}>
              <Button
                variant="outlined"
                className={classes.assignCamButton}
                size="small"
                disableRipple
                disableFocusRipple
                onClick={() => {
                  setSelectingCamera(true);
                }}
              >
                <Typography
                  // className={classes.assignCamButtonText}
                  className={clsx(classes.assignCamButtonText, {
                    [classes.assignCamButtonTextNoCam]: !selectedCamera,
                  })}
                >
                  {selectedCamera?.name ?? "Assign Camera"}
                </Typography>
                <Box mx={1} />
                <CreateIcon fontSize={"small"} style={{ color: "#CF00F1" }} />
              </Button>
              <Box mx={1} />
              <Button
                className={classes.assignCamButton}
                variant="outlined"
                size="small"
                disableRipple
                disableFocusRipple
                onClick={onDelete}
                style={{ color: "#CF00F1" }}
              >
                <DeleteForeverIcon />
              </Button>
            </Grid>
            <Grid item>
              {!dragging && (
                <>
                  {selectingCamera ? (
                    <div className={clsx(classes.holder)}>
                      <Grid container direction={"column"}>
                        <Grid item className={classes.heading}>
                          <Typography
                            variant={"h5"}
                            className={classes.headingText}
                          >
                            {" "}
                            Assign Camera
                          </Typography>
                        </Grid>
                        <Grid item className={classes.searchBox}>
                          <SearchBox
                            input={searchString}
                            setInput={setSearchString}
                            placeholder="Search Cameras"
                            fullWidth
                          />
                          <Box m={1} />
                          <Typography
                            align="center"
                            className={classes.searchBoxMessage}
                          >
                            {" "}
                            Select a camera to assign it
                          </Typography>
                        </Grid>
                        <div className={classes.listWrapper}>
                          <Grid
                            container
                            item
                            className={clsx(
                              classes.cameraList,
                              scrollbarClasses.scrollbarContainer
                            )}
                            direction="column"
                          >
                            <CameraSelectionTabs
                              centered
                              value={tab}
                              indicatorColor="primary"
                              onChange={(_, newTab) => setTab(newTab)}
                            >
                              <CameraTab
                                label={`Unassigned Cameras (${unassignedCams?.length})`}
                              />
                              <CameraTab
                                label={`Assigned Cameras (${assignedIds?.length})`}
                              />
                            </CameraSelectionTabs>

                            {tab === 0 ? (
                              <Grid
                                xs={12}
                                item
                                container
                                direction="column"
                                className={classes.cameraListContainer}
                              >
                                <Box mt={1} />
                                {unassignedCams && (
                                  <>
                                    {unassignedCams.length === 0 ? (
                                      <Grid
                                        item
                                        xs={6}
                                        style={{ alignSelf: "center" }}
                                      >
                                        <Box p={2}>
                                          <Typography
                                            className={
                                              classes.noMoreCamerasText
                                            }
                                          >
                                            All cameras for this location have
                                            been assigned to this layout
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    ) : (
                                      <>
                                        {unassignedCams
                                          .filter(filterCams)
                                          .map((c) => {
                                            return (
                                              <Box mb={1} key={c.id}>
                                                <ButtonBase
                                                  focusRipple
                                                  className={classes.cameraItem}
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    handleCameraSelect(c);
                                                  }}
                                                >
                                                  <Grid item container>
                                                    <Grid item>
                                                      <img
                                                        alt={"Camera Thumbnail"}
                                                        className={
                                                          classes.cameraThumbnail
                                                        }
                                                        src={c.still}
                                                      />
                                                    </Grid>
                                                    <Box mx={1} />
                                                    <Grid item>
                                                      <div
                                                        className={
                                                          classes.cameraName
                                                        }
                                                      >
                                                        {c.name}
                                                      </div>
                                                      <div
                                                        className={
                                                          classes.locationName
                                                        }
                                                      >
                                                        {
                                                          locationCams?.location
                                                            ?.name
                                                        }
                                                      </div>
                                                    </Grid>
                                                  </Grid>
                                                </ButtonBase>
                                              </Box>
                                            );
                                          })}
                                      </>
                                    )}
                                  </>
                                )}
                              </Grid>
                            ) : (
                              <Grid
                                xs={12}
                                item
                                container
                                direction="column"
                                className={classes.cameraListContainer}
                              >
                                <Box mt={1} />
                                {layoutCams.locationLayout.cameras &&
                                  layoutCams.locationLayout.cameras
                                    .filter((c) =>
                                      splitSearchInput.every((search) =>
                                        c.camera.name
                                          .toLowerCase()
                                          .includes(search)
                                      )
                                    )
                                    .map((c) => {
                                      return (
                                        <Box mb={1} key={c.id}>
                                          <Grid
                                            item
                                            container
                                            alignItems={"center"}
                                          >
                                            <Grid item>
                                              <img
                                                alt={"Camera Thumbnail"}
                                                className={
                                                  classes.cameraThumbnail
                                                }
                                                src={c.camera.still}
                                              />
                                            </Grid>
                                            <Box mx={1} />
                                            <Grid item>
                                              <div
                                                className={classes.cameraName}
                                              >
                                                {c.camera.name}
                                              </div>
                                              <div
                                                className={classes.locationName}
                                              >
                                                {locationCams?.location?.name}
                                              </div>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                      );
                                    })}
                              </Grid>
                            )}
                          </Grid>
                        </div>
                      </Grid>
                    </div>
                  ) : (
                    <div
                      style={{
                        height: "136px",
                        filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
                      }}
                    >
                      <img
                        className={classes.cameraStill}
                        src={selectedCamera?.still}
                        alt={"Camera Still"}
                      />
                    </div>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Popper>
      )}
    </>
  );
}

const CameraTab = withStyles(Tab, (theme) => ({
  root: {
    fontSize: "12px",
    minHeight: "30px",
  },
  selected: {
    background: "#fff !important",
    borderRadius: "4px 4px 0px 0px",
  },
}));

const CameraSelectionTabs = withStyles(Tabs, (theme) => ({
  root: {
    minHeight: "30px",
  },
  indicator: {
    background: "none",
  },
}));
