import { Button, Divider, Paper, Popper, Typography } from "@mui/material";
import clsx from "clsx";
import { addDays, format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { format as fpformat } from "date-fns/fp";
import { flow, groupBy } from "lodash/fp";
import { useMemo, useState } from "react";
import { StringParam, useQueryParam } from "use-query-params";

import { useBreakpoints } from "@/util/useBreakpoints";

import { StillImage } from "@/components/Still/StillImage";

import { GetLprSearchQuery, useStillsQueryQuery } from "@/generated-models";

import { footageIsAvailable } from "../../utils";
import { useLprSearch } from "../hooks";

function SummaryPopover({
  data,
  onClick,
  onExit,
}: {
  data: GetLprSearchQuery["lprSearch"][number] & { id: string };
  onClick: () => void;
  onExit: () => void;
}) {
  const { data: result, loading } = useStillsQueryQuery({
    variables: {
      start: data.startTime,
      end: data.endTime,
      id: data.camera.id,
      limit: 1,
    },
    skip: !data.camera.id,
  });

  const hasFootage = footageIsAvailable(data.startTime, data.camera);

  const still = result?.stills[0]?.src;

  const startTimeFormatted = useMemo(
    () => [
      fpformat("MMM d, yyyy")(
        utcToZonedTime(new Date(data.startTime), data.camera.location.timezone)
      ),
      fpformat("p")(
        utcToZonedTime(new Date(data.startTime), data.camera.location.timezone)
      ),
    ],
    [data]
  );

  return (
    <Paper
      onClick={() => {
        if (hasFootage) {
          onClick();
        }
      }}
      className={clsx("py-2 px-3 flex gap-2 items-center", {
        "cursor-pointer": hasFootage,
      })}
      onMouseLeave={onExit}
    >
      {!loading ? (
        <StillImage
          className="h-[86px] w-[155px] object-cover"
          src={still || "/no-still.svg"}
          alt=""
        />
      ) : (
        <div className="h-[86px] w-[155px]" />
      )}
      <div className="flex flex-col justify-center items-between gap-[6px]">
        <div>
          {startTimeFormatted[0]} <strong>{startTimeFormatted[1]}</strong>
          <Divider className="mt-1" />
        </div>
        <div className="w-[140px]">
          <Typography className="text-base leading-4 truncate">
            {data.camera.name}
          </Typography>
          <Typography className="text-base leading-4 opacity-[0.66] truncate">
            {data.camera.location.name}
          </Typography>
        </div>
        <div className="bg-transparent text-left text-xs leading-[14px] text-[#757575] font-bold">
          {hasFootage ? (
            "Click to view clip"
          ) : (
            <span>
              Footage unavailable,
              <br /> storage period exceeded
            </span>
          )}
        </div>
      </div>
    </Paper>
  );
}

function useLprSearchChartData() {
  const query = useLprSearch(true);

  const data = useMemo(() => {
    const monthName = (item: GetLprSearchQuery["lprSearch"][number]) => {
      const converted = utcToZonedTime(
        new Date(item.startTime),
        item.camera.location.timezone
      );
      return new Date(
        converted.getFullYear(),
        converted.getMonth(),
        converted.getDate()
      ).getTime();
    };

    return flow(groupBy(monthName))(
      query.data.sort((a, b) => a.startTime.localeCompare(b.startTime))
    );
  }, [query.data]) as {
    [key: number]: (GetLprSearchQuery["lprSearch"][number] & { id: string })[];
  };

  return { ...query, data };
}

export function LicensePlateSummaryChart() {
  const [row, setRow] = useState<
    (GetLprSearchQuery["lprSearch"][number] & { id: string }) | null
  >(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setViewing] = useQueryParam("viewing", StringParam);
  const [hideLprSummaryChart, setHideLprSummaryChart] = useState(false);
  const { fitsDesktop, fitsTablet } = useBreakpoints();
  const [anchorEl, setAnchorEl] = useState(null);
  const { data } = useLprSearchChartData();
  const timezone = data?.[0]?.[0]?.camera?.location?.timezone;
  const ticks = useMemo(() => {
    const items = Object.keys(data).map((d) => parseInt(d));
    const result = [];
    let i = items[0];
    while (i <= items[items.length - 1]) {
      result.push(i);
      i = addDays(i, 1).getTime();
    }
    return result;
  }, [data]);

  const shouldAlternateLabels = () => {
    if (ticks.length > 25) {
      return true;
    }
    if (!fitsTablet && ticks.length > 8) {
      return true;
    }
    if (!fitsDesktop && ticks.length > 14) {
      return true;
    }

    return false;
  };

  return (
    <div className="p-3 sm:py-5 sm:px-7 bg-white border border-solid border-[#E0E0E0] rounded-lg">
      <div className="flex items-center justify-between w-full">
        <Typography className="text-base leading-[18.75px] font-medium">
          {fitsTablet && "License Plate Recognitions Per Day"}
          {!fitsTablet && "License Plates Per Day"}
        </Typography>
        <Button
          onClick={() => {
            setHideLprSummaryChart(!hideLprSummaryChart);
          }}
          className="leading-[16.41px]"
          color="primary"
        >
          {hideLprSummaryChart ? "Show" : "Hide"} Graph
        </Button>
      </div>
      <div
        className={clsx(
          "flex flex-row gap-1  border-b border-solid border-black/10 my-8 px-3",
          {
            hidden: hideLprSummaryChart,
          }
        )}
        onMouseLeave={() => {
          setAnchorEl(null);
          setRow(null);
        }}
      >
        {ticks.map((tick, idx) => {
          const rows = data[tick] || [];
          return (
            <div
              key={`${tick}-${idx}`}
              className="flex flex-col-reverse gap-1 relative flex-1 items-center"
            >
              {(shouldAlternateLabels()
                ? idx % (fitsTablet ? 2 : ticks.length > 10 ? 5 : 3) === 0
                : true) && (
                <div className="absolute text-xs leading-[16.41px] text-center bottom-[-32px] text-[#757575] whitespace-nowrap flex justify-center w-full">
                  {format(utcToZonedTime(tick, timezone), "MMM d")}
                </div>
              )}
              {rows.map((r, idx) => {
                const hasFootage = footageIsAvailable(r.startTime, r.camera);

                return (
                  <div
                    key={r.id}
                    onClick={() => {
                      if (!hasFootage) {
                        return;
                      }

                      setViewing(r.id);
                      setAnchorEl(null);
                      setRow(null);
                    }}
                    onMouseEnter={(e) => {
                      setAnchorEl(e.target as any);
                      setRow(r);
                    }}
                    className={clsx("bg-primary", {
                      "cursor-pointer": hasFootage,
                      "w-1/4": ticks.length < 5,
                      "w-full": ticks.length >= 5,
                    })}
                    style={{ height: 10 }}
                  />
                );
              })}
            </div>
          );
        })}
        <Popper anchorEl={anchorEl} open={!!anchorEl} placement="right">
          {row && (
            <SummaryPopover
              data={row}
              onClick={() => {
                setViewing(row.id);
                setAnchorEl(null);
                setRow(null);
              }}
              onExit={() => {
                setAnchorEl(null);
                setRow(null);
              }}
            />
          )}
        </Popper>
      </div>
    </div>
  );
}
