import { Button, OutlinedInput } from "@mui/material";
import clsx from "clsx";
import { useField } from "formik";
import { useAtom } from "jotai";
import startCase from "lodash/startCase";
import { useEffect, useState } from "react";

import { InterestListItemInput, InterestListType } from "@/generated-models";

import {
  baseInputProps,
  InterestListNoteInput,
  InterestListTextInputLabel,
} from ".";
import { interestListConfig } from "../constants";
import { bulkItemAtom } from "../hooks";

function AddButton({
  onClick,
  disabled,
  hidden,
}: {
  onClick: () => void;
  disabled?: boolean;
  hidden: boolean;
}) {
  return (
    <Button
      variant="contained"
      color="primary"
      onClick={onClick}
      disabled={disabled}
      className={clsx(
        "shadow-none text-xs leading-[22px] font-normal rounded-md ml-auto",
        {
          hidden,
        }
      )}
    >
      Add
    </Button>
  );
}

export function InterestListDialogAddSingleItemInput({
  type,
}: {
  type: InterestListType;
}) {
  const config = interestListConfig[type];
  const [addCustomNote, setAddCustomNote] = useState(false);

  const [, { value }, { setValue }] = useField<InterestListItemInput[]>(
    "items"
  );

  const [item, setItem] = useAtom(bulkItemAtom);

  useEffect(() => {
    setItem(value[0] || { name: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <InterestListTextInputLabel
        labelClassname="font-light text-xs !mb-1"
        label={startCase(config.resourceName)}
        htmlFor="name"
      />
      <div className="flex items-center justify-between gap-2">
        <OutlinedInput
          value={item?.name}
          size="small"
          classes={{ root: "rounded-lg" }}
          placeholder={`Enter ${startCase(config.resourceName)}`}
          onChange={(e) => {
            const newItem = { ...item, name: e.target.value };
            setItem(newItem);
            setValue([newItem]);
          }}
          {...baseInputProps}
          inputProps={{
            className: clsx(
              baseInputProps.inputProps.className,
              "font-mono tracking-[1.4px] uppercase"
            ),
          }}
        />
      </div>

      <button
        className={clsx(
          "bg-transparent text-primary text-sm leading-[16.41px] mt-3",
          {
            hidden: addCustomNote,
          }
        )}
        type="button"
        onClick={() => {
          setAddCustomNote((v) => !v);
        }}
      >
        + Add Note (optional)
      </button>

      {addCustomNote && (
        <InterestListNoteInput
          notes={item?.notes || ""}
          onClear={() => {
            const newItem = { ...item, name: item?.name || "", notes: null };
            setAddCustomNote(false);
            setItem(newItem);
            setValue([newItem]);
          }}
          onChange={(e) => {
            const newItem = { ...item, name: item?.name || "", notes: e };
            setItem(newItem);
            setValue([newItem]);
          }}
        />
      )}
    </div>
  );
}

export function InterestListDialogSingleItemInput({
  type,
  onChange,
}: {
  type: InterestListType;
  onChange?: (name: string, notes: string) => void;
}) {
  const config = interestListConfig[type];
  const [addCustomNote, setAddCustomNote] = useState(false);

  const [item, setItem] = useState("");
  const [notes, setNotes] = useState("");

  const [, { value }, { setValue }] = useField<InterestListItemInput[]>(
    "items"
  );

  const onAddItem = () => {
    const itemExits = value.findIndex((i) => i.name === item);
    const newValues = [...value];
    const newItem = {
      name: item.toLowerCase(),
      notes,
      source: null,
    };

    if (itemExits >= 0) {
      newValues.splice(itemExits, 1, newItem);
      setValue(newValues);
    } else {
      setValue([...newValues, newItem]);
    }

    setItem("");
    setNotes("");

    setAddCustomNote(false);
  };

  return (
    <div>
      <InterestListTextInputLabel
        labelClassname="!mb-1"
        label={config.featureName}
        htmlFor="name"
      />
      <InterestListTextInputLabel
        labelClassname="font-light text-xs !mb-1"
        label="Manually Add"
        htmlFor="name"
      />
      <div className="flex items-center justify-between gap-2">
        <OutlinedInput
          value={item}
          size="small"
          classes={{ root: "rounded-lg" }}
          placeholder={`Enter ${startCase(config.resourceName)}`}
          onChange={(e) => {
            const newItem = e.target.value;
            setItem(newItem);

            if (onChange) {
              onChange(newItem, notes);
            }
          }}
          {...baseInputProps}
          inputProps={{
            className: clsx(
              baseInputProps.inputProps.className,
              "font-mono tracking-[1.4px] uppercase"
            ),
          }}
        />

        <AddButton
          onClick={onAddItem}
          disabled={!item}
          hidden={addCustomNote}
        />
      </div>

      <button
        className={clsx(
          "bg-transparent text-primary text-sm leading-[16.41px] mt-3",
          {
            hidden: addCustomNote,
          }
        )}
        type="button"
        onClick={() => {
          setAddCustomNote((v) => !v);
        }}
      >
        + Add Note (optional)
      </button>

      {addCustomNote && (
        <InterestListNoteInput
          notes={notes}
          onClear={() => {
            setAddCustomNote(false);
            setNotes("");
            if (onChange) {
              onChange(item, "");
            }
          }}
          onChange={(e) => {
            setNotes(e);
            if (onChange) {
              onChange(item, e);
            }
          }}
        />
      )}

      <div className="flex mt-2">
        <AddButton
          onClick={onAddItem}
          disabled={!item}
          hidden={!addCustomNote}
        />
      </div>
    </div>
  );
}
