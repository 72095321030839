import { MutationHookOptions } from "@apollo/client";
import gql from "graphql-tag";
import { useState } from "react";

import {
  CameraSettingsFeedback,
  Exact,
  UpdateCameraSettingsInput,
  UpdateCameraSettingsMutation,
  useUpdateCameraSettingsMutation,
  ValidateUpdateCameraSettingsMutation,
  useValidateUpdateCameraSettingsMutation,
} from "@/generated-models";

import { CameraSettingsFeedbackDialog } from "./CameraSettingsFeedbackDialog";

type UpdateCameraMutationOptions = MutationHookOptions<
  UpdateCameraSettingsMutation,
  Exact<{
    ids: number | number[];
    input: UpdateCameraSettingsInput;
  }>
>;

type ValidateUpdateCameraMutationOptions = MutationHookOptions<
  ValidateUpdateCameraSettingsMutation,
  Exact<{
    ids: number | number[];
    input: UpdateCameraSettingsInput;
  }>
>;

export function useUpdateCameraSettings(
  baseOptions?: UpdateCameraMutationOptions
) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [messages, setMessages] = useState<string[]>([]);

  function onCompleted(feedback: CameraSettingsFeedback) {
    const { messages = [], success } = feedback;

    if (!success) {
      setMessages(messages || []);
      setDialogOpen(true);
    }
  }

  function FeedbackDialog() {
    return (
      <CameraSettingsFeedbackDialog
        messages={messages}
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
      />
    );
  }

  return {
    FeedbackDialog,
    mutation: useUpdateCameraSettingsMutation({
      ...(baseOptions as UpdateCameraMutationOptions),
      onCompleted({ updateCameraSettings }) {
        onCompleted(updateCameraSettings);
      },
    }),
  };
}

export function useValidateUpdateCameraSettings(
  baseOptions?: ValidateUpdateCameraMutationOptions
) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [messages, setMessages] = useState<string[]>([]);

  function onCompleted(feedback: CameraSettingsFeedback) {
    const { messages = [], success } = feedback;

    if (!success) {
      setMessages(messages || []);
      setDialogOpen(true);
    }
  }

  function FeedbackDialog() {
    return (
      <CameraSettingsFeedbackDialog
        messages={messages}
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
      />
    );
  }

  return {
    FeedbackDialog,
    mutation: useValidateUpdateCameraSettingsMutation({
      ...baseOptions,
      onCompleted({ validateUpdateCameraSettings }) {
        onCompleted(validateUpdateCameraSettings);
      },
    }),
  };
}

gql`
  query cameraSettings {
    cameras {
      id
      settings {
        lprEnabled
        modelForkliftEnabled
      }
    }
  }
`;

gql`
  mutation updateCameraSettings(
    $ids: [Int!]!
    $input: UpdateCameraSettingsInput!
  ) {
    updateCameraSettings(ids: $ids, input: $input) {
      messages
      success
    }
  }
`;

gql`
  mutation validateUpdateCameraSettings(
    $ids: [Int!]!
    $input: UpdateCameraSettingsInput!
  ) {
    validateUpdateCameraSettings(ids: $ids, input: $input) {
      messages
      success
    }
  }
`;
