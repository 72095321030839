import gql from "graphql-tag";
import { atom } from "jotai";
import { keyBy } from "lodash/fp";
import { useMemo } from "react";
import { NumberParam, StringParam, useQueryParam } from "use-query-params";

import {
  PromptItem,
  PromptListType,
  usePromptListQuery,
  usePromptListsAllQuery,
  usePromptOrgsQuery,
} from "@/generated-models";

export enum FormMode {
  create = "create",
  edit = "edit",
}

export const promptListContentAtom = atom("");
promptListContentAtom.debugLabel = "promptListContentAtom";

export function useCurrentPromptListQuery() {
  const [selectedPrompt] = useSelectedPrompt();

  return usePromptListQuery({
    variables: {
      id: selectedPrompt!,
    },
    skip: !selectedPrompt,
  });
}

export function useAllPromptLists() {
  const { data: items, ...query } = usePromptListsAllQuery();
  const data = useMemo(() => {
    return [...(items?.promptListsAll || [])].sort((a, b) =>
      a.type === b.type
        ? 0
        : a.type === PromptListType.Global
        ? -1
        : 1 || a.name.localeCompare(b.name)
    );
  }, [items?.promptListsAll]);

  const promptsMap = keyBy("id", data);

  return {
    ...query,
    promptsMap,
    data,
  };
}

export function usePromptOrgs() {
  const { data, ...query } = usePromptOrgsQuery();
  const orgs = useMemo(
    () =>
      [...(data?.organizations || [])].sort((a, b) =>
        a.name.localeCompare(b.name)
      ),
    [data?.organizations]
  );

  const orgsPromptListCount = useMemo(() => {
    const result: Record<number, number> = {};

    orgs.forEach((o) => {
      const p = o.promptListId || -1;
      if (result[p]) {
        result[p] = result[p] + 1;
      } else {
        result[p] = 1;
      }
    });

    return result;
  }, [orgs]);

  const orgsMap = keyBy("id", orgs);

  return {
    ...query,
    data: orgs,
    orgsMap,
    orgsPromptListCount,
  };
}

export function useParsePromptList() {
  return {
    convert: (rows: PromptItem[]) => {
      const result = [...rows]
        .sort((a, b) => a.label.localeCompare(b.label))
        .map((r) => `${r.label}`);
      return result.join("\n");
    },
    parseIgnoreList: (value: string) =>
      value.split(/\r\n|\r|\n/).filter(Boolean),
    parse: (value: string, items?: PromptItem[], override?: number) => {
      const promptItemsMap = keyBy("label", items || []);
      return value
        .split(/\r\n|\r|\n/)
        .filter(Boolean)
        .map((label) => ({
          label: label.trim(),
          multiplier: !!override
            ? Math.max(0, Math.min(override, 100))
            : promptItemsMap[label.trim()]?.multiplier,
        }));
    },
  };
}

export function usePromptMode() {
  return useQueryParam("prompt-mode", StringParam);
}

export function useSelectedPrompt() {
  return useQueryParam("selected-prompt", NumberParam);
}

gql`
  mutation updateOrgPromptList($input: UpdateOrgPromptListInput!) {
    updateOrgPromptList(input: $input) {
      message
    }
  }
`;

gql`
  query promptOrgs {
    organizations {
      id
      name
      promptListId
    }
  }
`;

gql`
  query promptList($id: Int!) {
    promptList(id: $id) {
      id
      name
      type
      organizationId
      ignoreList
      prompts {
        label
        multiplier
      }
    }
  }
`;

gql`
  query promptListsAll {
    promptListsAll {
      id
      name
      type
      organizationId
      ignoreList
    }
  }
`;
