import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHotkeys } from "react-hotkeys-hook";

import { Position } from "@/util/mouseEvents";
import { useBreakpoints } from "@/util/useBreakpoints";

export function CreateAnnotationContextProvider({
  children,
}: PropsWithChildren<{}>) {
  return (
    <CreateAnnotationContext.Provider value={useCreateAnnotationContextValue()}>
      <AnnotationHotkeys />
      {children}
    </CreateAnnotationContext.Provider>
  );
}

function AnnotationHotkeys() {
  const { setPlacingAnnotation } = useAnnotationContext();
  useHotkeys(
    "escape",
    () => {
      setPlacingAnnotation(false);
    },
    {
      enableOnTags: ["INPUT", "TEXTAREA"],
    }
  );
  return null;
}

export const CreateAnnotationContext = createContext<
  ReturnType<typeof useCreateAnnotationContextValue>
>({
  placingAnnotation: false,
  setPlacingAnnotation: () => {},
  placedAnnotationPosition: null,
  setPlacedAnnotationPosition: () => {},
});

export function useCreateAnnotationContextValue() {
  const { fitsTablet } = useBreakpoints();
  const [placingAnnotation, setPlacingAnnotation] = useState(false);
  const [
    placedAnnotationPosition,
    setPlacedAnnotationPosition,
  ] = useState<Position | null>(null);

  // On mobile; immediately place the annotation label in the center
  useEffect(() => {
    if (fitsTablet || !placingAnnotation || placedAnnotationPosition) return;

    setPlacedAnnotationPosition({ x: 0.5, y: 0.5 });
  }, [
    fitsTablet,
    placingAnnotation,
    placedAnnotationPosition,
    setPlacedAnnotationPosition,
  ]);

  return useMemo(
    () => ({
      placingAnnotation,
      setPlacingAnnotation: (value: boolean) => {
        if (value === false) setPlacedAnnotationPosition(null);
        setPlacingAnnotation(value);
      },
      placedAnnotationPosition,
      setPlacedAnnotationPosition,
    }),
    [placingAnnotation, placedAnnotationPosition]
  );
}

export function useAnnotationContext() {
  return useContext(CreateAnnotationContext);
}
