import UploadIcon from "@mui/icons-material/CloudUpload";
import {
  Button,
  CircularProgress,
  Divider,
  Link as MaterialLink,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import CropperJS from "cropperjs";
import "cropperjs/dist/cropper.css";
import gql from "graphql-tag";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useCallback, useState } from "react";
import Cropper from "react-cropper";
import { useDropzone } from "react-dropzone";
import { Link } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { useBreakpoints } from "@/util/useBreakpoints";
import { useDocumentTitle } from "@/util/useDocumentTitle";

import { useMe } from "@/components/Auth";
import { FeedbackDialog } from "@/components/FeedbackDialog";
import { OrgPromptListSelect } from "@/components/InternalTools/AI/OrgPromptListSelect";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { ZendeskArticle, ZendeskLink } from "@/components/Zendesk/ZendeskLink";
import { DefaultDialog, useDialog } from "@/components/shared/Dialog";

import {
  AdditionalAiClass,
  Crop,
  OrganizationCameraFlagResult,
  OrgType,
  Role,
  useHasAiLicenseQuery,
  useResetOrgLogoMutation,
  useUpdateAllowSupportEnabledMutation,
  useUpdateCloudSyncEnabledMutation,
  useUpdateGeniusEnabledMutation,
  useUpdateIndustryMutation,
  useUpdateOrganizationCameraFlagMutation,
  useUpdateOrganizationFlagsMutation,
  useUpdateOrgPromptListMutation,
  useUploadOrgLogoMutation,
} from "@/generated-models";
import { usePrefixOrgSlug } from "@/hooks/useOrgRouteBase";
import { usePermissions } from "@/hooks/usePermissions";
import { MobileHeader } from "@/layout/MobileHeader";

import { PeopleSearchEulaModal } from "../Search/PeopleSearchEulaModal";
import { OrganizationIndustryDropdown } from "./OrganizationIndustryDropdown";

const webRTCStreamingDesc =
  "WebRTC unlocks low latency live streaming. If viewing on remote network, please ensure the proper outbound ports and IPs are allowed through your firewall.";

const useStyles = makeStyles()((theme) => ({
  settingRowLeftCell: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 120,
    flexShrink: 0,
    whiteSpace: "nowrap",
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  switchLabel: {
    fontSize: 12,
    fontWeight: "bold",
  },
}));

export function OrganizationSettings() {
  useDocumentTitle("Organization Settings");
  const {
    cameraBulkCfg,
    universalSearch,
    liveWebRtc: liveWebRTCAvailability,
  } = useFlags();
  const { fitsDesktop } = useBreakpoints();
  const { classes } = useStyles();
  const me = useMe();
  const isSuccess = me && me.role >= Role.Success;
  const isSupport = me && me.role >= Role.Support;
  const isLiveWebRTC = me?.organization.flags.liveWebRTC ?? true;
  const prefixOrgSlug = usePrefixOrgSlug();
  const { pushSnackbar } = useFeedback();

  const [
    update,
    { loading: updatingPromptList },
  ] = useUpdateOrgPromptListMutation({
    refetchQueries: ["me"],
    onCompleted: () =>
      pushSnackbar("Saved additional object list", FeedbackType.Success),
    onError: () =>
      pushSnackbar(
        "We were unable to update the additional object list.",
        FeedbackType.Error
      ),
  });

  const [
    updateIndustry,
    { loading: updatingIndustry },
  ] = useUpdateIndustryMutation({
    onCompleted: () => pushSnackbar("Saved industry", FeedbackType.Success),
    onError: () =>
      pushSnackbar(
        "We were unable to update the industry.",
        FeedbackType.Error
      ),
  });

  const [
    updateCloudSyncEnabled,
    { loading: updatingCloudSyncEnabled },
  ] = useUpdateCloudSyncEnabledMutation({
    onCompleted: (data) =>
      pushSnackbar(
        `Saved Clip Backup ${
          data.updateOrganization.cloudSyncEnabled ? "enabled" : "disabled"
        }`,
        FeedbackType.Success
      ),
    onError: () =>
      pushSnackbar(
        "Something went wrong, unable to update Saved Clip Backup setting",
        FeedbackType.Error
      ),
  });
  const [
    updateAllowSupportEnabled,
    { loading: updatingAllowSupportEnabled },
  ] = useUpdateAllowSupportEnabledMutation({
    onCompleted: (data) =>
      pushSnackbar(
        `Spot Technical Support ${
          data.updateOrganization.allowSupport ? "enabled" : "disabled"
        }`,
        FeedbackType.Success
      ),
    onError: () =>
      pushSnackbar(
        "Something went wrong, unable to update Spot Technical Support setting",
        FeedbackType.Error
      ),
  });
  const [
    updateGeniusEnabled,
    { loading: updatingGeniusEnabled },
  ] = useUpdateGeniusEnabledMutation({
    onCompleted: (data) =>
      pushSnackbar(
        `Genius Autodiscovery ${
          data.updateOrganization.geniusEnabled ? "enabled" : "disabled"
        }`,
        FeedbackType.Success
      ),
    onError: () =>
      pushSnackbar(
        "Something went wrong, unable to update Genius Autodiscovery setting",
        FeedbackType.Error
      ),
  });
  const [
    updateOrganizationFlags,
    { loading: updatingFlags },
  ] = useUpdateOrganizationFlagsMutation();
  const { open, ...dialogProps } = useDialog();

  return (
    <>
      <MobileHeader label="Settings" />
      {fitsDesktop && (
        <div className="flex justify-between items-center p-4">
          <Typography variant="h1">Settings</Typography>
          <div>Customize your Spot experience.</div>
        </div>
      )}

      <div className="flex flex-col">
        <div className="px-6 py-3 bg-[#F4F4F4] shadow-divider text-[#757575] text-base font-medium">
          Organization Settings
        </div>
        <LogoUpload />
        {isSuccess && universalSearch && (
          <>
            <Divider className="mx-4" />
            <div className="flex flex-col md:flex-row items-start md:items-center gap-4 md:gap-10 pl-12 pr-4 py-6">
              <div>
                <Typography variant="h6" component="h2">
                  Industry
                </Typography>
                <Typography className="text-sm leading-normal max-w-[400px]">
                  Select an industry to optimize your dashboard experience and
                  help guide intelligence throughout the product.
                </Typography>
              </div>
              <OrganizationIndustryDropdown
                value={me.organization?.industry}
                disabled={updatingIndustry}
                onChange={(newIndustry) => {
                  updateIndustry({
                    variables: { industry: newIndustry },
                    optimisticResponse: {
                      __typename: "Mutation",
                      updateOrganization: {
                        ...me!.organization,
                        industry: newIndustry,
                      },
                    },
                  });
                }}
                className="w-full md:w-[262px]"
              />
            </div>
          </>
        )}
        {isSuccess && universalSearch && (
          <>
            <Divider className="mx-4" />
            <div className="flex flex-col md:flex-row items-start md:items-center gap-4 md:gap-10 pl-12 pr-4 py-6">
              <div>
                <Typography variant="h6" component="h2">
                  AI Copilot Additional Objects
                </Typography>
                <Typography className="text-sm leading-normal max-w-[400px]">
                  Select a list to optimize your Copilot experience and help
                  guide intelligence throughout the product.
                </Typography>
              </div>
              <OrgPromptListSelect
                value={me.organization?.promptListId}
                disabled={updatingPromptList}
                onChange={(newIndustry) => {
                  update({
                    variables: {
                      input: {
                        id: newIndustry,
                        organizationId: me.organization.id,
                      },
                    },
                  });
                }}
                className="w-full md:w-[262px]"
              />
            </div>
          </>
        )}
        <Divider className="mx-4" />
        <div className="flex items-center">
          <div className={classes.settingRowLeftCell}>
            <Switch
              disabled={updatingGeniusEnabled}
              color="primary"
              checked={me?.organization.geniusEnabled}
              onChange={async (event) => {
                const enabled = event.target.checked;
                updateGeniusEnabled({
                  variables: { enabled },
                  optimisticResponse: {
                    __typename: "Mutation",
                    updateOrganization: {
                      ...me!.organization,
                      geniusEnabled: enabled,
                    },
                  },
                });
              }}
            />
            <Typography
              variant="caption"
              color={
                me?.organization.geniusEnabled ? "primary" : "textSecondary"
              }
              className={classes.switchLabel}
            >
              {me?.organization.geniusEnabled ? "ON" : "OFF"}
            </Typography>
          </div>
          <div className="px-4 py-6">
            <Typography variant="h6" component="h2">
              Genius Autodiscovery
            </Typography>
            <Typography>
              Enable Genius autodiscovery for all locations. Requires Spot
              firmware version 2.3.0 or higher.
            </Typography>
          </div>
        </div>
        <Divider className="mx-4" />
        <div className="flex items-center">
          <div className={classes.settingRowLeftCell}>
            <Switch
              disabled={updatingAllowSupportEnabled}
              color="primary"
              checked={me?.organization.allowSupport}
              onChange={(event) => {
                const enabled = event.target.checked;
                updateAllowSupportEnabled({
                  variables: { enabled },
                  optimisticResponse: {
                    __typename: "Mutation",
                    updateOrganization: {
                      ...me!.organization,
                      allowSupport: enabled,
                    },
                  },
                });
              }}
            />
            <Typography
              variant="caption"
              color={
                me?.organization.allowSupport ? "primary" : "textSecondary"
              }
              className={classes.switchLabel}
            >
              {me?.organization.allowSupport ? "ON" : "OFF"}
            </Typography>
          </div>
          <div className="px-4 py-6">
            <Typography variant="h6" component="h2">
              Spot Technical Support
            </Typography>
            <Typography>
              Take advantage of Spot Technical Support! This allows the Spot
              Technical Support team to securely access to your dashboard to
              provide assistance and solve any technical issues.
            </Typography>
          </div>
        </div>
        {isSuccess && !cameraBulkCfg && (
          <>
            <Divider className="mx-4" />
            <div className="flex items-center">
              <div className={classes.settingRowLeftCell}>
                <Select
                  size="small"
                  variant="outlined"
                  disabled={updatingFlags}
                  value={
                    me?.organization.flags.additionalAiFeature ?? "__NONE__"
                  }
                  onChange={async (event) => {
                    const resolvedValue =
                      event.target.value === "__NONE__"
                        ? null
                        : (event.target.value as AdditionalAiClass);
                    const { errors } = await updateOrganizationFlags({
                      variables: {
                        flags: { additionalAiFeature: resolvedValue },
                      },
                      optimisticResponse: {
                        __typename: "Mutation",
                        updateOrganizationFlags: {
                          ...me!.organization,
                          flags: {
                            ...me!.organization.flags,
                            additionalAiFeature: resolvedValue,
                          },
                        },
                      },
                    });
                    if (errors?.length) {
                      pushSnackbar(
                        "Something went wrong, unable to update additional AI functionality settings",
                        FeedbackType.Error
                      );
                    } else {
                      pushSnackbar(
                        `Updated additional AI functionality to ${
                          resolvedValue ?? "none"
                        }!`,
                        FeedbackType.Success
                      );
                    }
                  }}
                >
                  {Object.entries(AdditionalAiClass).map(([label, aiClass]) => (
                    <MenuItem value={aiClass}>{label}</MenuItem>
                  ))}
                  <MenuItem value="__NONE__">
                    <em>None</em>
                  </MenuItem>
                </Select>
              </div>
              <div className="px-4 py-6">
                <Typography variant="h6" component="h2">
                  Additional AI Functionality
                </Typography>
                <Typography>Enable additional AI functionality.</Typography>
              </div>
            </div>
          </>
        )}
        <div className="px-6 py-3 bg-[#F4F4F4] text-[#757575] text-base font-medium">
          Video Settings
        </div>
        <div className="flex items-center">
          <div className={classes.settingRowLeftCell}>
            <Switch
              disabled={updatingCloudSyncEnabled}
              color="primary"
              checked={me?.organization.cloudSyncEnabled}
              onChange={async (event) => {
                const enabled = event.target.checked;
                if (!enabled && !(await open())) {
                  return;
                }
                updateCloudSyncEnabled({
                  variables: { enabled },
                  optimisticResponse: {
                    __typename: "Mutation",
                    updateOrganization: {
                      ...me!.organization,
                      cloudSyncEnabled: enabled,
                    },
                  },
                });
              }}
            />
            <Typography
              variant="caption"
              color={
                me?.organization.cloudSyncEnabled ? "primary" : "textSecondary"
              }
              className={classes.switchLabel}
            >
              {me?.organization.cloudSyncEnabled ? "ON" : "OFF"}
            </Typography>
          </div>
          <div className="px-4 py-6">
            <Typography variant="h6" component="h2">
              Saved Clip Backup
            </Typography>
            <Typography>
              Automatically backup your saved clips in the cloud.
            </Typography>
          </div>
        </div>
        <Divider className="mx-4" />
        <div className="flex items-center">
          <div className={classes.settingRowLeftCell}>
            <Switch
              disabled
              color="primary"
              checked={me?.organization.audioControlEnabled}
            />
            <Typography
              variant="caption"
              color={
                me?.organization.audioControlEnabled
                  ? "primary"
                  : "textSecondary"
              }
              className={classes.switchLabel}
            >
              {me?.organization.audioControlEnabled ? "ON" : "OFF"}
            </Typography>
          </div>
          <div className="px-4 py-6">
            <Typography variant="h6" component="h2">
              Audio Controls
            </Typography>
            <Typography>
              Audio controls have been moved to the{" "}
              <Link to={prefixOrgSlug("/maintain")} className="text-primary">
                maintain page
              </Link>
              . You can now toggle audio on a per camera level or select all and
              toggle audio for all cameras.
            </Typography>
          </div>
        </div>
        <Divider className="mx-4" />
        <div className="flex items-center">
          <div className={classes.settingRowLeftCell}>
            <Switch
              disabled={updatingFlags}
              color="primary"
              checked={Boolean(me?.organization.flags.webRTC)}
              onChange={async (event) => {
                const enabled = event.target.checked;
                const { errors } = await updateOrganizationFlags({
                  variables: {
                    flags: {
                      webRTC: enabled,
                    },
                  },
                  optimisticResponse: {
                    __typename: "Mutation",
                    updateOrganizationFlags: {
                      ...me!.organization,
                      flags: {
                        ...me!.organization.flags,
                        webRTC: enabled,
                      },
                    },
                  },
                });
                if (errors?.length) {
                  pushSnackbar(
                    "Something went wrong, unable to enable WebRTC",
                    FeedbackType.Error
                  );
                } else {
                  pushSnackbar(
                    `WebRTC ${enabled ? "enabled" : "disabled"}!`,
                    FeedbackType.Success
                  );
                }
              }}
            />
            <Typography
              variant="caption"
              color={
                me?.organization.flags.webRTC ? "primary" : "textSecondary"
              }
              className={classes.switchLabel}
            >
              {me?.organization.flags.webRTC ? "ON" : "OFF"}
            </Typography>
          </div>
          <div className="px-4 py-6">
            <Typography variant="h6" component="h2">
              Video Walls WebRTC - Low latency live streaming
            </Typography>
            <Typography>{webRTCStreamingDesc}</Typography>
          </div>
        </div>

        {liveWebRTCAvailability && isSupport && (
          <>
            <Divider className="mx-4" />

            <div className="flex items-center">
              <div className={classes.settingRowLeftCell}>
                <Switch
                  disabled={updatingFlags}
                  color="primary"
                  checked={isLiveWebRTC}
                  onChange={async (event) => {
                    const enabled = event.target.checked;
                    const { errors } = await updateOrganizationFlags({
                      variables: {
                        flags: {
                          liveWebRTC: enabled,
                        },
                      },
                      optimisticResponse: {
                        __typename: "Mutation",
                        updateOrganizationFlags: {
                          ...me!.organization,
                          flags: {
                            ...me!.organization.flags,
                            liveWebRTC: enabled,
                          },
                        },
                      },
                    });
                    if (errors?.length) {
                      pushSnackbar(
                        "Something went wrong, unable to enable WebRTC",
                        FeedbackType.Error
                      );
                    } else {
                      pushSnackbar(
                        `WebRTC ${enabled ? "enabled" : "disabled"}!`,
                        FeedbackType.Success
                      );
                    }
                  }}
                />
                <Typography
                  variant="caption"
                  color={isLiveWebRTC ? "primary" : "textSecondary"}
                  className={classes.switchLabel}
                >
                  {isLiveWebRTC ? "ON" : "OFF"}
                </Typography>
              </div>
              <div className="px-4 py-6">
                <Typography variant="h6" component="h2">
                  Live WebRTC - Low latency live streaming
                </Typography>
                <Typography>{webRTCStreamingDesc}</Typography>
              </div>
            </div>
          </>
        )}
        <Divider className="mx-4" />
        <div className="flex items-center">
          <div className={classes.settingRowLeftCell}>
            <Switch
              disabled={updatingFlags}
              color="primary"
              checked={Boolean(me?.organization.flags.ptz)}
              onChange={async (event) => {
                const enabled = event.target.checked;
                const { errors } = await updateOrganizationFlags({
                  variables: { flags: { ptz: enabled } },
                  optimisticResponse: {
                    __typename: "Mutation",
                    updateOrganizationFlags: {
                      ...me!.organization,
                      flags: {
                        ...me!.organization.flags,
                        ptz: enabled,
                      },
                    },
                  },
                });
                if (errors?.length) {
                  pushSnackbar(
                    "Something went wrong, unable to enable PTZ",
                    FeedbackType.Error
                  );
                } else {
                  pushSnackbar(
                    `PTZ ${enabled ? "enabled" : "disabled"}!`,
                    FeedbackType.Success
                  );
                }
              }}
            />
            <Typography
              variant="caption"
              color={me?.organization.flags.ptz ? "primary" : "textSecondary"}
              className={classes.switchLabel}
            >
              {me?.organization.flags.ptz ? "ON" : "OFF"}
            </Typography>
          </div>
          <div className="px-4 py-6">
            <Typography variant="h6" component="h2">
              PTZ - Pan/Tilt/Zoom Cameras
              <ZendeskLink article={ZendeskArticle.PTZ_SUPPORT} />
            </Typography>
            <Typography>
              Allows you to add PTZ cameras and use controls directly from our
              dashboard. See KB article.
            </Typography>
          </div>
        </div>
        <AiSettings />
      </div>

      <DefaultDialog
        {...dialogProps}
        content="Turning off Cloud Sync will result in all synced footage to be removed
          from the cloud. Turning Cloud Sync back on will resync the footage to
          the cloud, provided that the appliance that holds the footage is
          properly connected to the internet and turned on."
        title="Disable Cloud Sync"
        confirmText="Disable"
      />
    </>
  );
}

function LogoUpload() {
  const { classes } = useStyles();
  const me = useMe();
  const { fitsTablet } = useBreakpoints();
  const { pushSnackbar } = useFeedback();
  const [uploadOrgLogo, { loading: uploading }] = useUploadOrgLogoMutation();
  const [resetOrgLogo] = useResetOrgLogoMutation();
  const [selectedFile, setSelectedFile] = useState<{
    file: File;
    preview: string;
  } | null>(null);
  const [crop, setCrop] = useState<Crop | null>(null);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
  } = useDropzone({
    multiple: false,
    onDrop: (acceptedFiles, fileRejections) => {
      if (acceptedFiles.length === 0 && fileRejections[0]) {
        pushSnackbar(fileRejections[0].errors[0].message, FeedbackType.Error);
        return;
      }
      // Do something with the files
      setSelectedFile({
        file: acceptedFiles[0],
        preview: URL.createObjectURL(acceptedFiles[0]),
      });
      setCrop(null);
    },
    accept: { "image/*": [] },
    noClick: Boolean(selectedFile),
  });
  const { open: openDialog, ...dialogProps } = useDialog();

  const { ref, ...rootProps } = getRootProps();
  const croppingEnabled =
    selectedFile &&
    fitsTablet &&
    croppableMimeTypes.includes(selectedFile.file.type);

  return (
    <>
      <div
        ref={ref}
        className="flex items-center"
        {...rootProps}
        style={{
          transition: "background 400ms",
          background: isDragActive
            ? isDragAccept
              ? "rgb(8 197 0 / 0.1)"
              : "rgb(219 98 98 / 0.1)"
            : undefined,
        }}
      >
        <input {...getInputProps()} />
        <div
          style={{ pointerEvents: "none", opacity: 0.5 }}
          className={classes.settingRowLeftCell}
        >
          <UploadIcon />
        </div>

        {!selectedFile ? (
          <div
            className={clsx(
              "flex justify-between grow gap-4 px-4 py-6 pointer-events-none",
              { "flex-col items-start": !fitsTablet },
              { "items-center": fitsTablet }
            )}
          >
            <div>
              <Typography variant="h6" component="h2">
                Set organization logo
              </Typography>
              <Typography>Click to select file or drop a file here</Typography>
            </div>
            {me?.organization.logo && (
              <Button
                variant="contained"
                style={{ pointerEvents: "initial" }}
                onClick={async (e) => {
                  e.stopPropagation();
                  const confirmed = await openDialog();
                  if (confirmed) resetOrgLogo();
                }}
              >
                Discard
              </Button>
            )}
          </div>
        ) : (
          <div className="flex flex-col gap-4 justify-between items-start px-4 py-6">
            <div>
              <Typography variant="h6" component="h2">
                {croppingEnabled ? "Crop and confirm" : "Preview"}
              </Typography>
              <Typography>file: {selectedFile.file.name}</Typography>
            </div>
            <div
              className="p-1 rounded border border-[#c1c1c1]"
              onClick={(e) => e.stopPropagation()}
            >
              {fitsTablet &&
              croppableMimeTypes.includes(selectedFile.file.type) ? (
                <ImageCropper
                  image={selectedFile.preview}
                  onCropChange={setCrop}
                />
              ) : (
                <img
                  alt="Logo Preview"
                  className="block"
                  src={selectedFile.preview}
                  height="37"
                />
              )}
            </div>
            {!croppableMimeTypes.includes(selectedFile.file.type) && (
              <Typography variant="caption" className="block">
                Resizing for {selectedFile.file.type} is not supported
              </Typography>
            )}

            <div className="flex gap-2">
              <Button
                variant="contained"
                color="primary"
                disabled={uploading}
                onClick={async (event) => {
                  event.stopPropagation();

                  const { errors } = await uploadOrgLogo({
                    variables: {
                      file: selectedFile.file,
                      crop,
                    },
                  }).catch((e) => ({ errors: [e] }));

                  if (errors?.length) {
                    pushSnackbar(
                      "Something went wrong when uploading the logo, please try again later",
                      FeedbackType.Error
                    );
                  } else {
                    pushSnackbar(`Logo updated!`, FeedbackType.Success);
                    setSelectedFile(null);
                  }
                }}
                endIcon={uploading && <CircularProgress size={14} />}
              >
                Confirm
              </Button>
              <Button
                disabled={uploading}
                onClick={(event) => {
                  event.stopPropagation();
                  setSelectedFile(null);
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        )}
      </div>
      <DefaultDialog
        {...dialogProps}
        content="Are you sure you want to discard the organization logo? This cannot be reverted, however, you can still upload a new logo afterwards."
        title="Discard organization logo"
        confirmText="Discard"
      />
    </>
  );
}

function AiSettings() {
  const { attributeSearchFacialRecOrgSettings } = useFlags();
  const { classes } = useStyles();
  const me = useMe();
  const hasPermission = usePermissions();
  const { open: peopleSearchOpen, ...peopleSearchDialogProps } = useDialog();
  const [result, setResult] = useState<OrganizationCameraFlagResult | null>(
    null
  );
  const { data: licenseQuery } = useHasAiLicenseQuery();
  const { pushSnackbar } = useFeedback();
  const [
    updateAiFlag,
    { loading: updatingAiFlag },
  ] = useUpdateOrganizationCameraFlagMutation({
    update: (cache, { data }) => {
      if (!data || !me) return;
      const { flag, enabled } = data.updateOrganizationCameraFlag;
      cache.modify({
        id: cache.identify(me.organization),
        fields: {
          flags(currentFlags) {
            return {
              ...currentFlags,
              [flag]: enabled,
              // If we're disabling aiAttributeSearch, we also need to disable aiFaceRecognition
              ...(flag === "aiAttributeSearch" && !enabled
                ? { aiFaceRecognition: enabled }
                : undefined),
            };
          },
        },
      });
    },
  });

  if (!attributeSearchFacialRecOrgSettings || !me) return null;
  const disableFaceRec =
    !hasPermission("people_search_manage") || // only user with permission can enable face rec
    !me.organization.flags.aiAttributeSearch || // attribute search prereq for face rec
    me.organization.type === OrgType.Private || // health care orgs can't use face rec
    !licenseQuery?.hasAiLicense; // no appliances with pro/ai license

  return (
    <>
      <PeopleSearchEulaModal {...peopleSearchDialogProps} />
      {result && (
        <FeedbackDialog
          open={true}
          onClose={() => setResult(null)}
          title={`Successfully enabled ${
            result.flag === "aiAttributeSearch" ? "Attribute" : "People"
          } Search`}
          description={`We have enabled ${
            result.flag === "aiAttributeSearch" ? "attribute" : "people"
          } search for all ${result.updatedCameraCount} out of ${
            result.totalCameraCount
          } cameras!`}
          messages={result.messages}
        />
      )}
      <Divider className="mx-4" />
      <div className="flex items-center">
        <div className={classes.settingRowLeftCell}>
          <Switch
            disabled={
              !hasPermission("attribute_search_manage") || updatingAiFlag
            }
            color="primary"
            checked={Boolean(me.organization.flags.aiAttributeSearch)}
            onChange={(event) => {
              const enabled = event.target.checked;
              const variables = { flag: "aiAttributeSearch", enabled };
              return updateAiFlag({
                variables,
                optimisticResponse: {
                  __typename: "Mutation",
                  updateOrganizationCameraFlag: {
                    __typename: "OrganizationCameraFlagResult",
                    ...variables,
                    updatedCameraCount: 0,
                    totalCameraCount: 0,
                    messages: [],
                  },
                },
                onError: () =>
                  pushSnackbar(
                    "Failed to update Attribute Search setting",
                    FeedbackType.Error
                  ),
                onCompleted: (data) => {
                  if (enabled && data) {
                    setResult(data.updateOrganizationCameraFlag);
                  } else {
                    pushSnackbar(
                      "Attribute search disabled!",
                      FeedbackType.Success
                    );
                  }
                },
              });
            }}
          />
          <Typography
            variant="caption"
            color={
              me.organization.flags.aiAttributeSearch
                ? "primary"
                : "textSecondary"
            }
            className={classes.switchLabel}
          >
            {me.organization.flags.aiAttributeSearch ? "ON" : "OFF"}
          </Typography>
        </div>
        <div className="px-4 py-6">
          <Typography variant="h6" component="h2">
            People and Vehicle Attribute Search
          </Typography>
          <Typography>
            Allows you to filter people and vehicles on additional attributes.
            Feature requires additional bandwidth consumption.{" "}
            <MaterialLink
              href="https://help.spot.ai/hc/en-us/articles/19121608742413-Attribute-Search-and-People-Search-with-Faces"
              target="_blank"
              rel="noopener noreferrer"
              underline="hover"
            >
              See KB article.
            </MaterialLink>
          </Typography>
        </div>
      </div>
      <div
        className={clsx("flex items-center pl-8", {
          "opacity-50": disableFaceRec,
        })}
      >
        <div className={classes.settingRowLeftCell}>
          <Switch
            disabled={updatingAiFlag || disableFaceRec}
            color="primary"
            checked={Boolean(me.organization.flags.aiFaceRecognition)}
            onChange={async (event) => {
              const enabled = event.target.checked;

              if (enabled && !me.termsPeopleSearchAccepted) {
                const confirmed = await peopleSearchOpen();
                if (!confirmed) return;
              }

              const variables = { flag: "aiFaceRecognition", enabled };
              await updateAiFlag({
                variables,
                optimisticResponse: {
                  __typename: "Mutation",
                  updateOrganizationCameraFlag: {
                    __typename: "OrganizationCameraFlagResult",
                    ...variables,
                    updatedCameraCount: 0,
                    totalCameraCount: 0,
                    messages: [],
                  },
                },
                onError: () =>
                  pushSnackbar(
                    "Failed to update People Search setting",
                    FeedbackType.Error
                  ),
                onCompleted: (data) => {
                  if (enabled && data) {
                    setResult(data.updateOrganizationCameraFlag);
                  } else {
                    pushSnackbar(
                      "People search disabled!",
                      FeedbackType.Success
                    );
                  }
                },
              });
            }}
          />
          <Typography
            variant="caption"
            color={
              me.organization.flags.aiFaceRecognition
                ? "primary"
                : "textSecondary"
            }
            className={classes.switchLabel}
          >
            {me.organization.flags.aiFaceRecognition ? "ON" : "OFF"}
          </Typography>
        </div>
        <div className="px-4 pb-6 pt-0">
          <Typography variant="h6" component="h2">
            People Search
          </Typography>
          <Typography>
            Feature requires attribute search to be enabled. Allows you to
            search for people by face. Feature may be restricted by local
            government.{" "}
            <MaterialLink
              href="https://help.spot.ai/hc/en-us/articles/19121608742413-Attribute-Search-and-People-Search-with-Faces"
              target="_blank"
              rel="noopener noreferrer"
              underline="hover"
            >
              See KB article.
            </MaterialLink>
          </Typography>
        </div>
      </div>
    </>
  );
}

gql`
  mutation updateIndustry($industry: OrganizationIndustry!) {
    updateOrganization(industry: $industry) {
      id
      industry
    }
  }
`;

gql`
  mutation updateGeniusEnabled($enabled: Boolean!) {
    updateOrganization(geniusEnabled: $enabled) {
      id
      geniusEnabled
    }
  }
`;

gql`
  mutation updateAllowSupportEnabled($enabled: Boolean!) {
    updateOrganization(allowSupport: $enabled) {
      id
      allowSupport
    }
  }
`;

gql`
  mutation updateCloudSyncEnabled($enabled: Boolean!) {
    updateOrganization(cloudSyncEnabled: $enabled) {
      id
      cloudSyncEnabled
    }
  }
`;

gql`
  mutation uploadOrgLogo($file: Upload!, $crop: Crop) {
    uploadOrgLogo(file: $file, crop: $crop) {
      id
      logo
    }
  }
`;

gql`
  mutation resetOrgLogo {
    resetOrgLogo {
      id
      logo
    }
  }
`;

gql`
  mutation updateOrganizationFlags($flags: OrganizationFlagsUpdate!) {
    updateOrganizationFlags(flags: $flags) {
      id
      flags {
        webRTC
        ptz
        liveWebRTC
        additionalAiFeature
      }
    }
  }
`;

gql`
  mutation updateOrganizationCameraFlag($flag: String!, $enabled: Boolean!) {
    updateOrganizationCameraFlag(flag: $flag, enabled: $enabled) {
      flag
      enabled
      updatedCameraCount
      totalCameraCount
      messages
    }
  }
`;

const croppableMimeTypes = ["image/jpeg", "image/png"];

function ImageCropper({
  image,
  onCropChange,
}: {
  image: string;
  onCropChange: (crop: Crop | null) => void;
}) {
  const wrapper = useCallback(
    (element: HTMLDivElement | null) => {
      if (element !== null) {
        element.addEventListener("cropend", (event: any) => {
          const cropper: CropperJS = event.target.cropper;
          const cropData = cropper.getData(true);
          const imageData = cropper.getImageData();
          if (
            cropData.width === imageData.width &&
            cropData.height === imageData.height
          ) {
            // noop crop
            onCropChange(null);
          } else {
            onCropChange({
              x: cropData.x,
              y: cropData.y,
              width: cropData.width,
              height: cropData.height,
            });
          }
        });
      }
    },
    [onCropChange]
  );

  return (
    <div style={{ width: 400 }} ref={wrapper}>
      <Cropper
        style={{ maxHeight: 200, width: "100%" }}
        src={image}
        viewMode={1}
        guides={true}
        minCropBoxHeight={10}
        minCropBoxWidth={10}
        background={true}
        responsive={true}
        autoCropArea={1}
        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
      />
    </div>
  );
}
