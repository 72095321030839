import { useRemoteParticipants } from "@livekit/components-react";
import { Participant } from "livekit-client";
import React, { ReactElement } from "react";

import getParticipant from "@/components/Player/WebRTCPlayer/utils/getParticipant";

const ApplianceParticipantVideo = ({
  children,
}: {
  children: (p: Participant) => ReactElement;
}) => {
  const participants = useRemoteParticipants();
  const applianceParticipant = getApplianceParticipant(participants);
  if (!applianceParticipant) {
    return null;
  }
  return <>{children(applianceParticipant)}</>;
};

function getApplianceParticipant(participants: Participant[]) {
  return getParticipant(participants, "SPOT_SFU");
}

export default ApplianceParticipantVideo;
