import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  Container,
  Divider,
  Link as MaterialLink,
  Paper,
  Switch,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import gql from "graphql-tag";
import { sortBy } from "lodash/fp";
import { NumberParam, useQueryParam } from "use-query-params";

import { formatTimeString, getShorthandDayRange } from "@/util/date";
import { pluralize } from "@/util/pluralize";
import { useBreakpoints } from "@/util/useBreakpoints";
import { useDocumentTitle } from "@/util/useDocumentTitle";

import { InternalAlertUserSelect } from "@/pages/Alerts/InternalAlertUserSelect";
import { alertTypesConfig } from "@/pages/Alerts/constants";

import { useMe } from "@/components/Auth";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { QueryParamLink } from "@/components/shared/QueryParamLink";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  AlertSource,
  GetAlertsQuery,
  Role,
  useGetAlertsQuery,
  useUpdateAlertActiveMutation,
} from "@/generated-models";
import { BackButton } from "@/layout/BackButton";
import { ContentWrapper } from "@/layout/ContentWrapper";

import { useAlertRoutes } from "./utils";

export function AlertList() {
  useDocumentTitle("Alerts");
  const alertRoutes = useAlertRoutes();
  const feedback = useFeedback();
  const { fitsDesktop } = useBreakpoints();
  const [orgUserId] = useQueryParam("ouid", NumberParam);
  const me = useMe();
  const { loading, data, error } = useGetAlertsQuery({
    ...refetchOnMountPolicy,
    variables: {
      orgUserId: orgUserId ?? undefined,
    },
  });

  const [updateAlert] = useUpdateAlertActiveMutation({
    onError: () =>
      feedback.pushSnackbar(
        "Updating alert failed, please try again",
        FeedbackType.Error
      ),
  });

  if (error) return <ErrorMessage title="Error" description={error.message} />;

  return (
    <ContentWrapper>
      <Container maxWidth="md" className="p-0">
        <Paper elevation={fitsDesktop ? 4 : 0}>
          <div className="flex flex-col sm:flex-row gap-y-4 items-center py-3 pr-4 pl-4 sm:pl-2">
            <div className="flex flex-col flex-1 pb-1">
              <div className="flex items-center">
                <BackButton withQueryParams to={alertRoutes.ALERTS_BASE} />
                <Typography variant="h1" className="text-2xl font-bold">
                  Configure Alerts
                </Typography>
              </div>
              <Typography
                color="textSecondary"
                variant="body2"
                className="text-sm pl-[42px] max-w-[500px] leading-[16px]"
              >
                We will watch your cameras while you are away for motion events
                and cameras going down. Create a new alert to start getting
                notifications.
              </Typography>
            </div>
            <Button
              variant="contained"
              color="primary"
              component={QueryParamLink}
              to={alertRoutes.ALERTS_CREATE}
              className="float-right min-w-[160px] w-full sm:w-[unset] mr-0 sm:mr-4"
            >
              Create a New Alert
            </Button>
          </div>

          <div className="shadow-divider">
            <InternalAlertUserSelect />
            {loading && !data?.alerts && (
              <div className="py-12">
                <Loading>Loading alerts...</Loading>
              </div>
            )}
            {data?.alerts &&
              sortBy((alert) => alert.name.toLowerCase(), data.alerts).map(
                (alert, i) => {
                  const { id, active } = alert;
                  return (
                    <div
                      className="flex justify-between items-center px-8 py-6"
                      style={{
                        backgroundColor: i % 2 === 0 ? "initial" : "#f7f7f7",
                      }}
                      key={id}
                    >
                      <div>
                        <AlertRow
                          alert={alert}
                          orgUserId={
                            orgUserId && me && me?.role > Role.Success
                              ? orgUserId
                              : undefined
                          }
                        />
                      </div>
                      <div className="flex items-center">
                        <span
                          className={clsx("text-[#a0a0a0] text-2xs", {
                            "opacity-0": active,
                          })}
                        >
                          OFF
                        </span>
                        <Switch
                          checked={active}
                          onChange={() =>
                            updateAlert({
                              variables: { id, updates: { active: !active } },
                              optimisticResponse: {
                                __typename: "Mutation",
                                updateAlert: {
                                  __typename: "Alert",
                                  id,
                                  active: !active,
                                },
                              },
                            })
                          }
                          color="primary"
                          size="small"
                        />
                        <span
                          className={clsx("text-primary text-2xs font-bold", {
                            "opacity-0": !active,
                          })}
                        >
                          ON
                        </span>
                      </div>
                    </div>
                  );
                }
              )}
          </div>
        </Paper>
      </Container>
    </ContentWrapper>
  );
}

export function AlertRow({
  alert,
  orgUserId,
}: {
  alert: Pick<
    GetAlertsQuery["alerts"][number],
    | "id"
    | "name"
    | "type"
    | "source"
    | "cameras"
    | "integrationSources"
    | "startTime"
    | "endTime"
    | "daysOfWeek"
    | "customText"
  >;
  orgUserId?: number;
}) {
  const { fitsTablet } = useBreakpoints();
  const alertRoutes = useAlertRoutes();

  return (
    <>
      <MaterialLink
        to={`${alertRoutes.ALERTS_CONFIGURE}/${alert.id}`}
        params={orgUserId ? { ouid: orgUserId } : undefined}
        component={QueryParamLink}
        variant="h4"
        className="flex gap-2"
        underline="hover"
      >
        {alert.name} <EditIcon fontSize="small" />
      </MaterialLink>
      <div className="flex items-center flex-wrap whitespace-nowrap mt-2">
        <div>{alertTypesConfig[alert.type].typeName}</div>
        <Divider orientation="vertical" className="h-3 mx-2" />
        {alert.source === AlertSource.Camera && (
          <div>
            <span className="font-bold">{alert.cameras.length}</span>{" "}
            {pluralize({ 1: "Camera", multi: "Cameras" }, alert.cameras.length)}
          </div>
        )}
        {alert.source === AlertSource.Integration && alert.integrationSources && (
          <div>
            <span className="font-bold">{alert.integrationSources.length}</span>{" "}
            {pluralize(
              { 1: "Device", multi: "Devices" },
              alert.integrationSources.length
            )}
          </div>
        )}
        {fitsTablet && (
          <>
            {alert.customText && (
              <>
                <Divider orientation="vertical" className="h-3 mx-2" />
                <span>Custom Info</span>
              </>
            )}
            <Divider orientation="vertical" className="h-3 mx-2" />
            <div>
              <span>{getShorthandDayRange(alert.daysOfWeek)},</span>{" "}
              <span>{`${formatTimeString(alert.startTime)} - ${formatTimeString(
                alert.endTime
              )}`}</span>
            </div>
          </>
        )}
      </div>
    </>
  );
}

gql`
  query getAlerts($orgUserId: Int) {
    alerts(orgUserId: $orgUserId) {
      id
      name
      active
      type
      source
      cameras {
        id
      }
      integrationSources {
        id
      }
      startTime
      endTime
      daysOfWeek
      customText
    }
  }
`;

gql`
  mutation updateAlertActive($id: Int!, $updates: UpdateAlertInput!) {
    updateAlert(id: $id, updates: $updates) {
      id
      active
    }
  }
`;
