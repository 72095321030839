import Add from "@mui/icons-material/Add";
import { Button, CircularProgress, Paper } from "@mui/material";
import clsx from "clsx";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Link, Route, Routes, useLocation } from "react-router-dom";

import { ReactComponent as MaintainAppliancesIcon } from "@/icons/maintain_appliances.svg";
import { ReactComponent as MaintainCamerasIcon } from "@/icons/maintain_cameras.svg";
import { ReactComponent as MaintainMetricsIcon } from "@/icons/maintain_metrics.svg";

import { useBreakpoints } from "@/util/useBreakpoints";
import { useDocumentTitle } from "@/util/useDocumentTitle";

import { MaintainApplianceDatagrid } from "@/pages/Maintain/Datagrid/MaintainApplianceDatagrid";
import { MaintainCameraDatagrid } from "@/pages/Maintain/Datagrid/MaintainCameraDatagrid";
import { useMaintainDeviceStatistics } from "@/pages/Maintain/hooks";
import { useMaintainRoutes } from "@/pages/Maintain/utils";

import { useMe } from "@/components/Auth";

import { Role } from "@/generated-models";

import { MaintainApplianceConfigEditor } from "./Details/Editor/MaintainApplianceConfigEditor";
import { MaintainMetricsPage } from "./Metrics/MaintainMetricsPage";
import { MaintainTabType } from "./constants";

interface MaintainTabProps {
  route: string;
  label: string;
  Icon: React.FC<{ className?: string; color?: string }>;
}

const tabs: {
  route: string;
  label: string;
  Component: React.ComponentType;
  Icon: React.FC<{ className?: string; color?: string }>;
}[] = [
  {
    route: MaintainTabType.cameras,
    label: "Cameras",
    Component: MaintainCameraDatagrid,
    Icon: MaintainCamerasIcon,
  },
  {
    route: MaintainTabType.appliances,
    label: "Appliances",
    Component: MaintainApplianceDatagrid,
    Icon: MaintainAppliancesIcon,
  },
  {
    route: MaintainTabType.metrics,
    label: "Metrics",
    Component: MaintainMetricsPage,
    Icon: MaintainMetricsIcon,
  },
];

function StatusIndicator({
  inactive,
  className,
  amount,
  label,
}: {
  inactive?: boolean;
  className?: string;
  amount: number;
  label: string;
}) {
  return (
    <div>
      <span className={clsx(!inactive && className, "font-bold")}>
        {amount}
      </span>{" "}
      {label}
    </div>
  );
}

function StatusIndicators({
  className,
  type,
  inactive,
}: {
  className?: string;
  type: MaintainTabType;
  inactive: boolean;
}) {
  const { cameras, appliances } = useMaintainDeviceStatistics();

  function getStatistics() {
    if (type === MaintainTabType.cameras) return cameras;
    if (type === MaintainTabType.appliances) return appliances;
    return { online: -1, offline: -1, outOfSpec: -1 };
  }

  const { online, offline, outOfSpec = -1 } = getStatistics();

  return (
    <div
      className={clsx("text-sm font-light flex items-center", className, {
        "text-[#757575]": inactive,
        "text-primary": !inactive,
        "!invisible": type === MaintainTabType.metrics,
      })}
    >
      <StatusIndicator
        className="text-[#18D710]"
        inactive={inactive}
        amount={online}
        label="online"
      />
      <div className="mx-2 border-r border-solid border-black opacity-20 h-2" />
      <StatusIndicator
        className="text-[#F44336]"
        inactive={inactive}
        amount={offline}
        label="offline"
      />
      {type === MaintainTabType.cameras && (
        <>
          <div className="mx-2 border-r border-solid border-black opacity-20 h-2" />
          <StatusIndicator
            className="text-[#FF9800]"
            inactive={inactive}
            amount={outOfSpec}
            label="out of spec."
          />
        </>
      )}
    </div>
  );
}

function MaintainTab({ route, label, Icon }: MaintainTabProps) {
  useDocumentTitle("Maintain");
  const location = useLocation();
  const inactive = !location.pathname.includes(route);
  const { cameras, appliances, loading } = useMaintainDeviceStatistics();
  const isCameras = route === MaintainTabType.cameras;
  const count = isCameras ? cameras.total : appliances.total;
  const maintainRoutes = useMaintainRoutes();
  const params = new URLSearchParams(location.search);
  params.delete("page");
  params.delete("page-size");
  const { fitsExtraLarge, fitsDesktop } = useBreakpoints();

  const fontColor = inactive
    ? "text-[#757575] hover:text-primary"
    : "text-primary";
  return (
    <Link
      to={{
        pathname: `${maintainRoutes.MAINTAIN_BASE}/${route}`,
        search: `?${params.toString()}`,
      }}
    >
      <button
        className={clsx(
          "w-full min-h-[80px] rounded-t-none sm:rounded-t-md rounded-b-none shadow-top px-4 flex flex-col justify-center gap-2 py-2",
          inactive
            ? "opacity-60 hover:bg-primary/10 cursor-pointer"
            : "bg-white cursor-default",
          fontColor,
          fitsExtraLarge ? "min-w-[400px]" : ""
        )}
      >
        <div className="flex gap-x-5 justify-start items-center">
          <Icon className="text-inherit" color="secondary" />
          <div className="flex flex-col items-start">
            <div
              className={clsx(
                "font-light text-[28px] leading-[32px] text-inherit"
              )}
            >
              {route === MaintainTabType.metrics ? (
                label
              ) : (
                <>
                  {loading ? (
                    <div className="flex gap-x-3 items-center">
                      <CircularProgress size={20} />
                      {label}
                    </div>
                  ) : (
                    `${count} ${label}`
                  )}
                </>
              )}
            </div>
            <StatusIndicators
              className={clsx(loading && "invisible")}
              type={route as MaintainTabType}
              inactive={inactive}
            />
          </div>
        </div>
        {route === MaintainTabType.cameras && !fitsDesktop && (
          <div className="w-full">
            <GetQuoteButton stretch />
          </div>
        )}
      </button>
    </Link>
  );
}

export function MaintainDevices() {
  const me = useMe();
  const { iotCoreDisabled, maintainBandwidth } = useFlags();
  const resolvedTabs = tabs.filter((t) => {
    return t.route !== MaintainTabType.metrics || maintainBandwidth;
  });

  const { fitsLarge } = useBreakpoints();

  const isSupport = me && me.role >= Role.Support;

  const tabsElement = (
    <div className="flex flex-col md:flex-row gap-x-3 overflow-hidden bg-transparent">
      {resolvedTabs.map((tab) => (
        <MaintainTab
          key={tab.route}
          Icon={tab.Icon}
          label={tab.label}
          route={tab.route}
        />
      ))}
      {me && fitsLarge && (
        <>
          <div className="grow" />
          <GetQuoteButton />
        </>
      )}
    </div>
  );

  return (
    <>
      <Routes>
        {isSupport && iotCoreDisabled && (
          <Route
            path="appliances/editor"
            element={<MaintainApplianceConfigEditor />}
          />
        )}
        {resolvedTabs.map((tab) => (
          <Route
            key={tab.route}
            path={tab.route}
            element={
              <>
                {tabsElement}
                <Paper className="rounded-tl-none rounded-b-2xl shadow-[0_3px_5px_rgba(0,0,0,0.15)] bg-transparent">
                  <tab.Component />
                </Paper>
              </>
            }
          />
        ))}
      </Routes>
    </>
  );
}

function GetQuoteButton({ stretch }: { stretch?: boolean }) {
  const me = useMe();
  const { getQuoteButton } = useFlags();
  if (!me || !getQuoteButton) return null;
  return (
    <Button
      startIcon={<Add />}
      className={clsx(
        "self-center whitespace-nowrap bg-[#daeeff] text-primary font-normal rounded-lg px-4",
        {
          "w-full": stretch,
        }
      )}
      target="_blank"
      href={`https://order.spot.ai/?orgId=${me.organization.id}`}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      Add More Cameras
    </Button>
  );
}
