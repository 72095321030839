import { QueryResult } from "@apollo/client";
import {
  DeleteForever,
  KeyboardArrowLeft,
  Videocam,
} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Backdrop,
  Button,
  ButtonGroup,
  Divider,
  Fade,
  IconButton,
  MenuItem,
  Modal,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { subDays } from "date-fns/fp";
import { Field, Form, Formik, useField, useFormikContext } from "formik";
import gql from "graphql-tag";
import { useFlags } from "launchdarkly-react-client-sdk";
import { capitalize, range } from "lodash/fp";
import { useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { BooleanParam, useQueryParam } from "use-query-params";

import { IntelligenceDashboardDesatPlaceholder } from "@/icons/intelligenceIcons";

import {
  formatIsoDate,
  formatIsoTimeMinutes,
  parseIsoTimeMinutes,
} from "@/util/date";
import { required } from "@/util/form";
import { formatDurationSecs } from "@/util/formatDurationSecs";
import { pluralize } from "@/util/pluralize";
import { useBreakpoints } from "@/util/useBreakpoints";

import {
  SelectInput,
  TextInput,
  SelfCheckboxSet,
  TextAreaInput,
  FormErrorMessage,
} from "@/pages/Intelligence/FormInputs";
import { Range } from "@/pages/Intelligence/IntelligenceDashboardView";
import {
  MobilePresenceDashboard,
  PresenceDashboard,
} from "@/pages/Intelligence/Presence/PresenceDashboardDataLoader";
import {
  CountDirection,
  IntelligenceInsightType,
} from "@/pages/Intelligence/constants";

import { CopilotBanner } from "@/components/Ai/Copilot/CopilotOverlay/CopilotLabelButton/CopilotBanner";
import { CustomDurationInput } from "@/components/DurationSelect/CustomDurationInput";
import { ErrorMessage } from "@/components/ErrorMessage";
import { InterestListSelectFormItem } from "@/components/InterestList/InterestListSelectFormItem";
import { Loading } from "@/components/Loading";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { TimeRangeSelector } from "@/components/TimeRange/TimeRangeSelector";
import { ZoneSelect } from "@/components/Zones/ZoneSelect";
import { DefaultDialog, useDialog } from "@/components/shared/Dialog";
import { CameraImage } from "@/components/shared/LazyImage";

import {
  AlertType,
  CameraFeeds,
  Exact,
  Focus,
  IntDashCamsQuery,
  IntelligenceDashboardInput,
  IntelligenceDashboardType,
  useAlertQuery,
  useCreateIntelligenceDashboardMutation,
  useDeleteIntelligenceDashboardMutation,
  useIntDashCamsQuery,
  useIntDashboardCamQuery,
  useIntelligenceDashboardPreviewQuery,
  useIntelligenceDashboardQuery,
  useLatestCameraStillQuery,
  usePresenceDashboardPreviewQuery,
  useUpdateIntelligenceDashboardMutation,
} from "@/generated-models";
import { usePrefixOrgSlug } from "@/hooks/useOrgRouteBase";

import { AlertModal, AlertOperation } from "../Alerts/AlertModal";
import { CreateEditLine } from "../Search/ZoneMotion/ZoneModals";
import {
  idToFilterConfigMap,
  intelligentFiltersConfig,
} from "../Search/intelligence/intelligence";
import { CountDashboard } from "./Count/CountDashboard";
import { ComparativeForm } from "./FormInputs/ComparativeForm";
import { IdleDashboard } from "./Idle/IdleDashboard";
import { IntLoadingIndicator } from "./IntLoadingIndicator";
import { IntelligenceDashboardCamDrawer } from "./IntelligenceDashboardCamDrawer";
import {
  getDashboardFeatureConfig,
  getDashboardFeatureType,
  getObjectLabel,
} from "./utils";

const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

enum FormMode {
  create,
  edit,
}

const LPR_DASHBOARDS = [
  IntelligenceDashboardType.Report,
  IntelligenceDashboardType.InterestList,
];

export function IntelligenceDashboardForm() {
  const { fitsDesktop } = useBreakpoints();
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type")?.toLowerCase();
  const camsParams = searchParams.get("cams");
  const [showDescription, setShowDescription] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const prefixOrgSlug = usePrefixOrgSlug();
  const flags = useFlags();
  const allowCustomObjects = flags["intelligenceDashboardCustomObjects"];
  const { pushSnackbar } = useFeedback();

  const [createIntelligenceDashboard] = useCreateIntelligenceDashboardMutation({
    onCompleted: (res) =>
      // Push /intelligence/new to /intelligence/<id>
      navigate(
        prefixOrgSlug(
          `/intelligence/${String(res.createIntelligenceDashboard.id)}`
        )
      ),
    onError: () =>
      pushSnackbar(
        "Failed to create intelligence dashboard, please try again",
        FeedbackType.Error
      ),
  });
  const [updateIntelligenceDashboard] = useUpdateIntelligenceDashboardMutation({
    onCompleted: () =>
      // Push from /intelligence/edit/<id> to /intelligence/<id>
      navigate(prefixOrgSlug(`/intelligence/${editingId}`)),
    onError: () =>
      pushSnackbar(
        "Failed to update intelligence dashboard, please try again",
        FeedbackType.Error
      ),
  });
  const cameraIdParams = camsParams
    ? camsParams.split(",").map((c) => Number(c))
    : [];
  const zoneIdParam = searchParams.get("zoneId")
    ? Number(searchParams.get("zoneId"))
    : null;
  const mode = params.id ? FormMode.edit : FormMode.create;
  const editingId = Number(params.id);
  const typeParam = params.type && (params.type as IntelligenceDashboardType);
  const dashboardType =
    typeParam && Object.values(IntelligenceDashboardType).includes(typeParam)
      ? typeParam
      : IntelligenceDashboardType.Idle;

  const { data, error } = useIntelligenceDashboardQuery({
    variables: { id: editingId },
    skip: mode === FormMode.create,
  });

  const initialValues = data?.intelligenceDashboard && {
    name: data.intelligenceDashboard.name,
    cameraIds: data.intelligenceDashboard.cameras.map(({ id }) => id),
    zoneId: data.intelligenceDashboard.zone?.id,
    thresholdSeconds: String(data.intelligenceDashboard.thresholdSeconds),
    daysOfWeek: data.intelligenceDashboard.daysOfWeek.map(String),
    alerts: data.intelligenceDashboard.alerts?.map((a) => a.id),
    interestLists: data.intelligenceDashboard.interestLists.map((l) => l.id),
    description: data.intelligenceDashboard.description,
    timeRange: [
      data.intelligenceDashboard.startTime,
      data.intelligenceDashboard.endTime,
    ].map(parseIsoTimeMinutes) as [number, number],
    referenceDashIds: data.intelligenceDashboard?.referenceDashboards?.map(
      (r) => r.id
    ),
    type: data.intelligenceDashboard.type,
    subtype: data.intelligenceDashboard.subtype,
    entityCount: data.intelligenceDashboard.entityCount,
    objectTypes: !!data.intelligenceDashboard.objectTypes?.length
      ? idToFilterConfigMap[data.intelligenceDashboard.objectTypes[0]].id
      : ((type || "default") as "people" | "vehicle" | "default"),
  };
  const camerasQuery = useIntDashCamsQuery();

  function getDefaultSubtype() {
    if (dashboardType === IntelligenceDashboardType.Count) {
      return CountDirection.InAndOut;
    } else if (dashboardType === IntelligenceDashboardType.Compound) {
      return IntelligenceDashboardType.Idle;
    }

    return "";
  }

  if (error) {
    return (
      <ErrorMessage
        title="Oops!"
        description="Failed to load intelligence dashboard. Please try again."
      />
    );
  }
  if (mode === FormMode.edit && !initialValues) {
    return <Loading className="py-20" />;
  }

  return (
    <div className="max-w-7xl w-full mx-auto md:px-6">
      <Paper elevation={4} className="rounded-none md:rounded-lg md:mt-8">
        <Formik
          initialValues={
            initialValues ?? {
              name: "",
              cameraIds: cameraIdParams ?? ([] as number[]), // Because type=radio cannot do proper numbers
              zoneId: zoneIdParam ?? (null as null | number),
              thresholdSeconds: 60,
              alerts: [],
              interestLists: [],
              description: null,
              daysOfWeek: range(0, 7).map(String), // Because type=checkbox cannot do proper numbers
              timeRange: [0, 1440] as [number, number],
              type: dashboardType,
              subtype: getDefaultSubtype(),
              entityCount: "1",
              objectTypes: (type ?? "default") as
                | "people"
                | "vehicle"
                | "default",
            }
          }
          validateOnMount={true}
          isInitialValid={false}
          validate={(values) => {
            if (values.daysOfWeek.length === 0) {
              return {
                daysOfWeek: "It is required to select at least one day",
              };
            }
          }}
          onSubmit={async (
            { timeRange, daysOfWeek, zoneId, objectTypes, ...values },
            { setErrors }
          ) => {
            const input = {
              ...values,
              thresholdSeconds: Number(values.thresholdSeconds),
              startTime: formatIsoTimeMinutes(timeRange[0]),
              endTime: formatIsoTimeMinutes(timeRange[1]),
              daysOfWeek: daysOfWeek.map(Number),
              zoneId: zoneId ?? null,
              entityCount: Number(values.entityCount),
              type: values.type as IntelligenceDashboardType,
              objectTypes: !!objectTypes
                ? objectTypes === "default"
                  ? null
                  : intelligentFiltersConfig[objectTypes].objectIds
                : null,
            };

            if (
              LPR_DASHBOARDS.includes(values.type) &&
              values.cameraIds.length === 0
            ) {
              setErrors({ cameraIds: "Required" });
              return;
            }

            if (
              values.type === IntelligenceDashboardType.InterestList &&
              values.interestLists.length === 0
            ) {
              setErrors({ interestLists: "Required" });
              return;
            }

            if (mode === FormMode.create) {
              await createIntelligenceDashboard({ variables: { input } });
            } else {
              await updateIntelligenceDashboard({
                variables: { id: editingId, input },
              });
            }
          }}
        >
          {({ values, setFieldValue, isValid }) => (
            <Form>
              <div className="top-0 sticky md:relative -mb-2.5 z-10">
                <div
                  className={clsx(
                    `w-full flex items-center py-0 md:px-6 md:py-5 p-[10px] rounded-t-lg max-md:bg-white max-md:text-inherit`,

                    getDashboardFeatureConfig(values.type, values.objectTypes)
                      .background.colorTertiary,

                    getDashboardFeatureConfig(values.type, values.objectTypes)
                      .foreground.colorPrimary
                  )}
                >
                  <IconButton
                    className="text-inherit"
                    component={Link}
                    to={prefixOrgSlug(
                      mode === FormMode.create
                        ? "/intelligence"
                        : `/intelligence/${editingId}`
                    )}
                    size="small"
                  >
                    <KeyboardArrowLeft fontSize="large" />
                  </IconButton>
                  <Typography
                    className="grow text-center md:text-start max-md:text-[16px]
                    max-md:leading-5"
                    variant="h1"
                  >
                    {mode === FormMode.create ? "Create" : "Edit"}{" "}
                    {
                      getDashboardFeatureConfig(values.type, values.objectTypes)
                        .label.displayName
                    }{" "}
                    Dashboard
                  </Typography>
                  <IconButton
                    className={clsx(
                      "invisible pointer-events-none text-inherit"
                      // "md:pointer-events-auto md:visible"
                    )}
                    component={Link}
                    to={
                      mode === FormMode.create
                        ? "/intelligence"
                        : `/intelligence/${editingId}`
                    }
                    size="small"
                  >
                    <KeyboardArrowLeft fontSize="large" />
                  </IconButton>
                  {values.type === IntelligenceDashboardType.Compound && (
                    <IntelligenceDeleteButton />
                  )}
                </div>
                <div
                  className="h-2.5"
                  style={{
                    background:
                      "linear-gradient(180deg, #C4C4C4 0%, rgba(196, 196, 196, 0) 100%)",
                  }}
                ></div>
              </div>

              {values.type !== IntelligenceDashboardType.Compound ? (
                <>
                  <div className="bg-[#F2F2F2] grid grid-cols-1 md:grid-cols-[minmax(400px,3fr)_auto_4fr] p-4 gap-4">
                    <CopilotBanner className="bg-white col-span-full" />

                    <div className="flex flex-col gap-5 order-3 md:order-1">
                      <Field
                        component={TextInput}
                        name="name"
                        label="Dashboard Name"
                        validate={required}
                      />

                      {!showDescription && (
                        <div>
                          <button
                            type="button"
                            onClick={() => {
                              setShowDescription(true);
                            }}
                            className="text-primary bg-transparent font-bold text-base leading-[18.75px]"
                          >
                            + Add Description (optional)
                          </button>
                        </div>
                      )}

                      {showDescription && (
                        <Field
                          component={TextAreaInput}
                          rows={4}
                          multiline
                          name="description"
                          label="Description (optional)"
                        />
                      )}

                      {values.type === IntelligenceDashboardType.Idle && (
                        <ThresholdSecondsSelect />
                      )}

                      {values.type === IntelligenceDashboardType.Presence && (
                        <EntityCountSelect />
                      )}

                      <IntelligenceCameraSelect camerasQuery={camerasQuery} />

                      {values.type ===
                        IntelligenceDashboardType.InterestList && (
                        <InterestListSelectFormItem />
                      )}

                      {values.type === IntelligenceDashboardType.Count && (
                        <IntelligenceCountControls />
                      )}

                      <SelfCheckboxSet
                        name="daysOfWeek"
                        label="Days Tracked"
                        options={weekDays.map((day, i) => ({
                          value: String(i),
                          label: day,
                        }))}
                      />

                      <TimeRangeSelector
                        btnClassName={clsx({ "p-0": !fitsDesktop })}
                        labelClassName={clsx({
                          "pt-2": !fitsDesktop,
                        })}
                        initialValue={values.timeRange}
                        onChange={(value) => setFieldValue("timeRange", value)}
                      />

                      {values.type ===
                        IntelligenceDashboardType.InterestList && (
                        <IntelligenceAlertsInput />
                      )}

                      {allowCustomObjects && <ObjectsSelect />}
                    </div>
                    {(values.cameraIds.length > 0 ||
                      getDashboardFeatureConfig(values.type, values.objectTypes)
                        .illustration.form) && (
                      <>
                        <span className="hidden md:block h-full order-2">
                          <Divider orientation="vertical" />
                        </span>
                        {values.cameraIds.length === 0 && (
                          <span className="md:hidden w-full order-2">
                            <Divider orientation="horizontal" />
                          </span>
                        )}

                        <div
                          className={clsx("order-1 md:order-3 md:mt-6", {
                            "max-md:-mx-4 max-md:-mt-4":
                              values.cameraIds.length > 0,
                          })}
                        >
                          {values.cameraIds.length > 0 ? (
                            <CameraImage
                              className="w-full"
                              alt="camera stil"
                              cameraId={Number(
                                camerasQuery.data?.cameras.find(({ id }) =>
                                  values.cameraIds.includes(id)
                                )!.id
                              )}
                            />
                          ) : (
                            <>
                              <img
                                className="bg-[#E9E9E9] w-full"
                                src={
                                  getDashboardFeatureConfig(
                                    values.type,
                                    values.objectTypes
                                  ).illustration.form
                                }
                                alt={
                                  getDashboardFeatureConfig(
                                    values.type,
                                    values.objectTypes
                                  ).label.description
                                }
                              />
                              {
                                getDashboardFeatureConfig(
                                  values.type,
                                  values.objectTypes
                                ).label.formDescription
                              }
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>

                  <IntelligenceActions />

                  {!LPR_DASHBOARDS.includes(values.type) && (
                    <>
                      <div
                        className="h-2.5 opacity-[.45] mb-6"
                        style={{
                          background:
                            "linear-gradient(180deg, #C4C4C4 0%, rgba(196, 196, 196, 0) 100%)",
                        }}
                      ></div>
                      <div className="px-0 md:px-12 pb-6">
                        {values.cameraIds.length > 0 &&
                        (values.type !== IntelligenceDashboardType.Count ||
                          !!values.zoneId) ? (
                          <>
                            <Typography
                              variant="h4"
                              className="text-[16px] leading-[18.75px] md:text-xl text-center"
                            >
                              Dashboard Preview
                            </Typography>
                            <div className={clsx(!fitsDesktop && "px-3")}>
                              <IntelligenceDashboardPreview
                                cameraIds={values.cameraIds}
                                startTime={formatIsoTimeMinutes(
                                  values.timeRange[0]
                                )}
                                interestLists={values.interestLists}
                                endTime={formatIsoTimeMinutes(
                                  values.timeRange[1]
                                )}
                                thresholdSeconds={Number(
                                  values.thresholdSeconds
                                )}
                                zoneId={values.zoneId || null}
                                daysOfWeek={values.daysOfWeek.map(Number)}
                                type={values.type as IntelligenceDashboardType}
                                subtype={values.subtype || undefined}
                                entityCount={Number(values.entityCount)}
                                objectTypes={
                                  values.objectTypes === "default"
                                    ? null
                                    : intelligentFiltersConfig[
                                        values.objectTypes
                                      ].objectIds
                                }
                              />
                            </div>
                          </>
                        ) : (
                          <div className="flex-center flex-col py-6 pb-12 md:py-20">
                            <IntelligenceDashboardDesatPlaceholder
                              className={clsx(
                                !fitsDesktop && "w-[128px] h-[85px]"
                              )}
                            />
                            <div className="h-4" />
                            <Typography variant="h4">
                              Dashboard Preview
                            </Typography>
                            <Typography className="text-[#757575] text-center md:w-[unset] w-[195px] text-sm leading-4 mt-1">
                              {values.type ===
                                IntelligenceDashboardType.Count &&
                                "Please create a tile, select a camera, and specify a count line to preview"}
                              {values.type !==
                                IntelligenceDashboardType.Count &&
                                "Please create a tile and select a camera to preview"}
                            </Typography>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </>
              ) : (
                <ComparativeForm />
              )}
            </Form>
          )}
        </Formik>
      </Paper>
    </div>
  );
}

function IntelligenceCountControls() {
  const [, { value: objectTypes }] = useField<string | null>({
    name: "objectTypes",
  });

  const [, { value }] = useField<number[]>({
    name: "cameraIds",
    validate: (value: any) => required(value),
  });

  const { data } = useIntDashboardCamQuery({
    variables: { ids: value },
    skip: value.length === 0,
  });

  return (
    <div className="flex gap-4 items-center">
      <div className="flex flex-col gap-2 w-full">
        <Typography variant="body2" className="font-medium text-[#666666]">
          {capitalize(objectTypes || "")} Count Direction
        </Typography>
        <div className="flex items-center gap-3">
          <ButtonGroup
            className="whitespace-nowrap"
            variant="outlined"
            color="primary"
            size="large"
          >
            <ButtonGroupItem label="In" type={CountDirection.In} />
            <ButtonGroupItem label="Out" type={CountDirection.Out} />
            <ButtonGroupItem label="In & Out" type={CountDirection.InAndOut} />
          </ButtonGroup>

          <Divider orientation="vertical" variant="middle" flexItem />
          <IntelligenceZoneCountLineSelect camera={data?.cameras[0]} />
        </div>
      </div>
    </div>
  );
}

export type IntDashCam = IntDashCamsQuery["cameras"][number];
type LprSelectProps = {
  cameras: IntDashCam[];
  selectedCameraIds: number[];
  openCameraDrawer: () => void;
  setSelectedCameraIds: (cameraIds: number[]) => void;
};

function MultiCamList({
  cameras,
  setSelectedCameraIds,
}: {
  cameras: IntDashCam[];
  setSelectedCameraIds: (cameraIds: number[]) => void;
}) {
  return (
    <div className="grid grid-cols-1 divide-y divide-[#D3D3D3] bg-white mb-2 rounded-lg border border-[#AAAAAA]">
      {cameras.map((camera) => (
        <div className="mx-3">
          <div className="truncate my-2 grid grid-cols-[1fr_auto] grid-rows-[auto_auto]">
            <span className="text-xs text-[#757575] font-normal col-span-2">
              {camera.location.name}
            </span>
            <span className="truncate flex items-center -ml-0.5">
              <Videocam className="text-[#BDBDBD]" />
              <span className="font-bold text-base">{camera.name}</span>
            </span>
            <button
              type="button"
              style={{ all: "unset" }}
              onClick={() =>
                setSelectedCameraIds(
                  cameras.map(({ id }) => id).filter((id) => id !== camera.id)
                )
              }
            >
              <DeleteForever className="text-[#757575] cursor-pointer" />
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}

function LprSelect({
  cameras,
  selectedCameraIds,
  openCameraDrawer,
  setSelectedCameraIds,
}: LprSelectProps) {
  const selectedCameras = cameras.filter(({ id }) =>
    selectedCameraIds.includes(id)
  );
  return (
    <>
      {selectedCameras.length > 0 && (
        <MultiCamList
          cameras={selectedCameras}
          setSelectedCameraIds={setSelectedCameraIds}
        />
      )}
      <div className="flex gap-4">
        <Button
          className="whitespace-nowrap shadow-none rounded-lg font-normal"
          color="primary"
          variant="contained"
          onClick={openCameraDrawer}
          size="large"
        >
          Select LPR Cameras
        </Button>
        <Button
          className="whitespace-nowrap"
          color="primary"
          size="large"
          onClick={() => setSelectedCameraIds(cameras.map(({ id }) => id))}
        >
          Select all LPR Cameras
        </Button>
      </div>
    </>
  );
}

function SingleCameraSelect({
  cameras,
  selectedCameraIds,
  openCameraDrawer,
}: {
  cameras: IntDashCam[];
  selectedCameraIds: number[];
  openCameraDrawer: () => void;
}) {
  const selectedCamera = cameras.find((camera) =>
    selectedCameraIds.includes(camera.id)
  );
  return selectedCamera ? (
    <div className="grid grid-cols-1 bg-white rounded-lg border border-[#AAAAAA]">
      <div className="mx-3">
        <div className="my-2 grid grid-cols-[1fr_auto] grid-rows-[auto_auto]">
          <span className="truncate text-xs text-[#757575] font-normal col-start-1 col-end-2 row-start-1 row-end-2">
            {selectedCamera.location.name}
          </span>
          <span className="truncate flex items-center -ml-0.5 col-start-1 col-end-2 row-start-2 row-end-3">
            <Videocam className="text-[#BDBDBD]" />
            <span className="font-bold text-base">{selectedCamera.name}</span>
          </span>
          <Button
            className="col-start-2 col-end-3 row-start-1 row-end-3"
            color="primary"
            variant="outlined"
            onClick={openCameraDrawer}
            size="large"
          >
            Change Camera
          </Button>
        </div>
      </div>
    </div>
  ) : (
    <div className="flex gap-4">
      <Button
        color="primary"
        variant="contained"
        onClick={openCameraDrawer}
        size="large"
      >
        Select Camera
      </Button>
    </div>
  );
}

function useIsSaveDisabled() {
  const { isValid } = useFormikContext();
  const [, { value: type }] = useField<IntelligenceDashboardType>("type");
  const lineZone = useLineZone();

  const noLineZone = type === IntelligenceDashboardType.Count && !lineZone;

  return {
    message: noLineZone ? "Please select a counting line" : "",
    disabled: !isValid || noLineZone,
  };
}

function IntelligenceActions() {
  const params = useParams();
  const mode = params.id ? FormMode.edit : FormMode.create;

  const { message, disabled } = useIsSaveDisabled();

  return (
    <div
      className={clsx(
        "bg-[#FBFBFB] px-4 md:px-7 py-4 grid grid-cols-[1fr_auto] sm:grid-cols-[auto_auto] gap-4 justify-between items-center"
      )}
    >
      <Tooltip title={message}>
        <span>
          <Button
            variant="contained"
            color="primary"
            disabled={disabled}
            type="submit"
          >
            {mode === FormMode.create ? "Create" : "Save"} Dashboard
          </Button>
        </span>
      </Tooltip>
      <IntelligenceDeleteButton />
    </div>
  );
}

function IntelligenceCameraSelect({
  camerasQuery,
}: {
  camerasQuery: QueryResult<
    IntDashCamsQuery,
    Exact<{
      [key: string]: never;
    }>
  >;
}) {
  const { cameraBulkCfg } = useFlags();
  const [cameraDrawerOpen, setCameraDrawerOpen] = useState(false);
  const [, { value: objectTypes }] = useField<string | null>({
    name: "objectTypes",
  });
  const [, { value: type }] = useField<IntelligenceDashboardType>("type");
  const [, { value: cameraIds }, { setTouched, setValue }] = useField<number[]>(
    {
      name: "cameraIds",
      validate: (value: any) => required(value),
    }
  );

  const { data, loading } = camerasQuery;

  const isForklift = objectTypes === IntelligenceInsightType.Forklift;

  const openCameraDrawer = () => setCameraDrawerOpen(true);
  const closeCameraDrawer = () => {
    setCameraDrawerOpen(false);
    setTouched(true);
  };

  const cameras =
    data?.cameras.filter(({ settings }) => {
      if (isForklift && cameraBulkCfg) {
        return settings.modelForkliftEnabled;
      }
      return !LPR_DASHBOARDS.includes(type) || settings.lprEnabled;
    }) ?? [];

  return (
    <>
      <div className="w-full">
        <label className="block font-medium mb-2 text-[#666666]">Camera</label>
        {loading && (
          <LoadingButton
            startIcon={<Videocam />}
            size="large"
            loading={loading}
            loadingPosition="start"
          >
            Fetching Cameras
          </LoadingButton>
        )}
        {cameras.length > 0 &&
          (LPR_DASHBOARDS.includes(type) ? (
            <LprSelect
              cameras={cameras}
              selectedCameraIds={cameraIds}
              setSelectedCameraIds={setValue}
              openCameraDrawer={openCameraDrawer}
            />
          ) : (
            <SingleCameraSelect
              cameras={cameras}
              selectedCameraIds={cameraIds}
              openCameraDrawer={openCameraDrawer}
            />
          ))}
        <IntelligenceDashboardCamDrawer
          open={cameraDrawerOpen}
          onOpen={openCameraDrawer}
          onClose={closeCameraDrawer}
          cameras={cameras}
          selectedCameraIds={cameraIds}
          setSelectedCameraIds={setValue}
          multiple={LPR_DASHBOARDS.includes(type)}
        />
        <FormErrorMessage name="cameraIds" />
      </div>
      {[
        IntelligenceDashboardType.Idle,
        IntelligenceDashboardType.Presence,
      ].includes(type) &&
        cameras.some(({ id }) => cameraIds.includes(id)) && (
          <IntelligenceZoneSelect
            camera={cameras.find(({ id }) => cameraIds.includes(id))!}
          />
        )}
    </>
  );
}

function IntelligenceAlertsInput() {
  const [, { value }, { setValue }] = useField<number[] | null>("alerts");
  const [alertModalOpen, setAlertModalOpen] = useState(false);
  const alertId = value && value?.[0] ? value[0] : null;

  const { data } = useAlertQuery({
    variables: {
      id: Number(alertId),
    },
    fetchPolicy: "network-only",
    skip: !alertId,
  });
  const closeAlertModal = () => {
    setAlertModalOpen(false);
  };

  return (
    <div className="w-full">
      <label className="block font-medium mb-2 text-[#666666]">
        Alerts (optional)
      </label>
      <button
        className="text-primary font-bold text-base leading-[19px]"
        type="button"
        onClick={() => {
          setAlertModalOpen(true);
        }}
      >
        {data ? `${data.alert.name}` : "+ Create Alert"}
        {data && <EditIcon className="w-4 h-4 ml-0.5 -mt-0.5" />}
      </button>
      <AlertModal
        open={alertModalOpen}
        closeModal={closeAlertModal}
        alertId={alertId}
        onCompleted={(alertId) => {
          setValue([alertId]);
        }}
        operation={alertId ? AlertOperation.edit : AlertOperation.create}
        defaultAlertType={AlertType.VehicleInterestList}
      />
    </div>
  );
}

function useLineZone() {
  const [, { value: type }] = useField<IntelligenceDashboardType>("type");

  const [, { value }] = useField<number[]>({
    name: "cameraIds",
    validate: (value: any) => required(value),
  });

  const { data } = useIntDashboardCamQuery({
    variables: { ids: value },
    skip: value.length === 0,
  });

  const isRequired = type === IntelligenceDashboardType.Count;

  const [, { value: zoneId }] = useField<number>({
    name: "zoneId",
    validate: (value: any) => (isRequired ? required(value) : undefined),
  });

  return data?.cameras[0]?.focusZones.filter((zone) => zone.id === zoneId)?.[0];
}

function IntelligenceZoneCountLineSelect({
  camera,
}: {
  camera?: {
    id: number;
    still: string;
    focusZones: Focus[];
    feeds?: Pick<CameraFeeds, "tunnel" | "local"> | null;
  };
}) {
  const [open, setOpen] = useState(false);
  const [, { error }, { setValue: setZoneId }] = useField<number>({
    name: "zoneId",
    validate: (value: any) => required(value),
  });

  const [, { value: objectTypes }] = useField<string | null>({
    name: "objectTypes",
  });

  const lineZone = useLineZone();

  return (
    <>
      <Button
        className="bg-[#F6FBFF] text-primary disabled:opacity-50 whitespace-nowrap border-primary"
        color={error ? "error" : "primary"}
        variant="outlined"
        onClick={() => setOpen(true)}
        disabled={camera === undefined}
        size="large"
      >
        {Boolean(lineZone) ? "Edit" : "Add"} Count Line
      </Button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 350 }}
        className="flex-center"
      >
        <Fade in={open}>
          <Paper className="w-full sm:h-[unset] h-full sm:w-[1200px]">
            {camera !== undefined && (
              <CreateEditLine
                cameraId={camera.id}
                zone={lineZone}
                snapshotSource={camera.still}
                feeds={camera.feeds || undefined}
                close={() => setOpen(false)}
                setZoneId={setZoneId}
                entity={objectTypes}
              />
            )}
          </Paper>
        </Fade>
      </Modal>
    </>
  );
}

function IntelligenceZoneSelect({ camera }: { camera: IntDashCam }) {
  const [, { value }, { setValue }] = useField<number | null>("zoneId");
  const { data } = useLatestCameraStillQuery({ variables: { id: camera.id } });
  if (!data) return null;
  return (
    <div className="w-full">
      <label className="block font-medium mb-2 text-[#666666]">Camera</label>
      <ZoneSelect
        className="flex-1 text-center"
        cameraId={camera.id}
        zones={camera.focusZones}
        snapshotSource={data.camera.still}
        feeds={camera.feeds ?? undefined}
        zoneId={value}
        setZoneId={setValue}
      />
    </div>
  );
}

function ButtonGroupItem({
  label,
  type,
}: {
  label: string;
  type: CountDirection;
}) {
  const [, { value }, { setValue }] = useField<CountDirection>("subtype");
  const selected = value === type;
  return (
    <Button
      key={`${label}-${selected}`}
      className={clsx({
        "bg-[#F6FBFF] text-primary": !selected,
      })}
      variant={selected ? "contained" : "outlined"}
      onClick={() => setValue(type)}
      style={{
        color: "red !important",
      }}
    >
      {label}
    </Button>
  );
}

const IDLE_TIME_THERSHOLDS = [30, 60, 120, 300, 600];

export function ThresholdSecondsSelect() {
  const [, { value }, { setValue }] = useField<number>("thresholdSeconds");
  const [customValue, setCustomValue] = useState<number>(0);

  return (
    <div className="flex gap-4 items-end">
      <Field
        component={SelectInput}
        name="thresholdSeconds"
        validate={required("Please provide a type")}
        label="Idle Time Threshold"
      >
        {IDLE_TIME_THERSHOLDS.map((duration) => (
          <MenuItem key={duration} value={duration}>
            {formatDurationSecs(duration, { hideZeroes: true, long: true })}
          </MenuItem>
        ))}
        <MenuItem value={customValue}>Custom</MenuItem>
      </Field>

      {Number(value) === customValue && (
        <CustomDurationInput
          onChange={({ sec, min, hour }) => {
            const duration = sec + min * 60 + hour * 3600;
            if (!IDLE_TIME_THERSHOLDS.includes(duration)) {
              setCustomValue(duration);
            }
            setValue(duration);
          }}
        />
      )}
    </div>
  );
}

function ObjectsSelect() {
  return (
    <Field
      component={SelectInput}
      name="objectTypes"
      validate={required("Please provide a type")}
      label="Custom Object Type (Internal Only)"
    >
      <MenuItem value={"people"}>People</MenuItem>
      <MenuItem value={"vehicle"}>Vehicles</MenuItem>
      <MenuItem value={"default"}>Default (Original)</MenuItem>
    </Field>
  );
}

export function EntityCountSelect() {
  const [, { value: objectTypes }] = useField<string>("objectTypes");
  const [, { value: dashType }] = useField<IntelligenceDashboardType>("type");

  function entityLabel(count: number) {
    return pluralize(
      {
        1: capitalize(getObjectLabel(objectTypes, dashType)),
        multi: capitalize(getObjectLabel(objectTypes, dashType, true)),
      },
      count
    );
  }

  return (
    <Field
      component={SelectInput}
      name="entityCount"
      validate={required("Please provide a type")}
      label="Presence Threshold"
    >
      {range(1, 4).map((count) => (
        <MenuItem key={count} value={count}>
          {count}+ {entityLabel(count)}
        </MenuItem>
      ))}
    </Field>
  );
}

const today = new Date();

function IdleDashboardPreview({
  input,
}: {
  input: IntelligenceDashboardInput;
}) {
  const feature = getDashboardFeatureType(input.type, input.objectTypes);
  const isoDate = formatIsoDate(today);
  const { data, loading, error } = useIntelligenceDashboardPreviewQuery({
    variables: { input: input, startDate: isoDate, endDate: isoDate },
  });

  if (error) {
    return <ErrorMessage title="Oops!" description="Failed to load preview." />;
  }
  if (!data?.intelligenceDashboardPreview.metrics.results || loading)
    return <IntLoadingIndicator className="py-20" />;

  return (
    <IdleDashboard
      feature={feature}
      objectTypes={input.objectTypes}
      cameraId={input.cameraIds[0]}
      metrics={data.intelligenceDashboardPreview.metrics.results}
      timezone={data.intelligenceDashboardPreview.cameras[0].location.timezone}
      thresholdSeconds={input.thresholdSeconds}
      zoneId={input.zoneId}
    />
  );
}

function PresenceDashboardPreview({
  input,
}: {
  input: IntelligenceDashboardInput;
}) {
  const { fitsDesktop } = useBreakpoints();
  const [fullscreen] = useQueryParam("fullscreen", BooleanParam);
  const isoDate = formatIsoDate(today);
  const { data, loading, error } = usePresenceDashboardPreviewQuery({
    variables: { input: input, startDate: isoDate, endDate: isoDate },
  });

  if (error) {
    return <ErrorMessage title="Oops!" description="Failed to load preview." />;
  }
  if (!data?.intelligenceDashboardPreview.presence.results || loading)
    return <IntLoadingIndicator className="py-20" />;

  if (!fitsDesktop && fullscreen) {
    return (
      <MobilePresenceDashboard
        cameraId={input.cameraIds[0]}
        objectTypes={input.objectTypes}
        metrics={data.intelligenceDashboardPreview.presence.results}
        timezone={
          data.intelligenceDashboardPreview.cameras[0].location.timezone
        }
        feature={getDashboardFeatureType(input.type, input.objectTypes)}
      />
    );
  }

  return (
    <PresenceDashboard
      entityCount={input.entityCount}
      objectTypes={input.objectTypes}
      cameraId={input.cameraIds[0]}
      metrics={data.intelligenceDashboardPreview.presence.results}
      timezone={data.intelligenceDashboardPreview.cameras[0].location.timezone}
      startDate={subDays(1, new Date())}
      endDate={subDays(1, new Date())}
      range={Range.Day}
      feature={getDashboardFeatureType(input.type, input.objectTypes)}
    />
  );
}

function CountDashboardPreview({
  input,
}: {
  input: IntelligenceDashboardInput;
}) {
  const isoDate = formatIsoDate(today);
  const { data, loading, error } = useIntelligenceDashboardPreviewQuery({
    variables: {
      input: input,
      startDate: isoDate,
      endDate: isoDate,
      usePaths: true,
    },
    fetchPolicy: "cache-and-network",
  });

  if (error) {
    return <ErrorMessage title="Oops!" description="Failed to load preview." />;
  }
  if (!data?.intelligenceDashboardPreview.metrics.results || loading)
    return <IntLoadingIndicator className="py-20" />;

  return (
    <CountDashboard
      cameraId={input.cameraIds[0]}
      metrics={data.intelligenceDashboardPreview.metrics.results}
      timezone={data.intelligenceDashboardPreview.cameras[0].location.timezone}
      subtype={input.subtype as CountDirection}
      feature={getDashboardFeatureType(input.type, input.objectTypes)}
      objectTypes={input.objectTypes}
    />
  );
}

function IntelligenceDashboardPreview({
  cameraIds,
  startTime,
  endTime,
  thresholdSeconds,
  interestLists,
  zoneId,
  daysOfWeek,
  type,
  entityCount,
  subtype,
  objectTypes,
}: {
  cameraIds: number[];
  daysOfWeek: number[];
  startTime: string;
  interestLists: number[];
  endTime: string;
  entityCount: number;
  thresholdSeconds: number;
  type: IntelligenceDashboardType;
  zoneId?: number | null;
  subtype?: string;
  objectTypes?: string[] | string | null;
}) {
  const input = {
    name: "Dashboard Preview",
    thresholdSeconds: Number(thresholdSeconds),
    zoneId: zoneId,
    interestLists: interestLists,
    cameraIds: cameraIds,
    startTime: startTime,
    endTime: endTime,
    daysOfWeek: daysOfWeek.map(Number),
    type: type as IntelligenceDashboardType,
    entityCount: Number(entityCount),
    objectTypes: objectTypes as string[],
    subtype,
  };

  return (
    <>
      {type === IntelligenceDashboardType.Idle && (
        <IdleDashboardPreview input={input} />
      )}
      {type === IntelligenceDashboardType.Presence && (
        <PresenceDashboardPreview input={input} />
      )}
      {type === IntelligenceDashboardType.Count && (
        <CountDashboardPreview input={input} />
      )}
    </>
  );
}

function IntelligenceDeleteButton() {
  const params = useParams();
  const navigate = useNavigate();
  const prefixOrgSlug = usePrefixOrgSlug();
  const { open: openDeleteDialog, ...deleteDialogProps } = useDialog();
  const { pushSnackbar } = useFeedback();

  const mode = params.id ? FormMode.edit : FormMode.create;
  const editingId = Number(params.id);

  const [
    deleteIntelligenceDashboard,
    { loading: deleting },
  ] = useDeleteIntelligenceDashboardMutation({
    onCompleted: () =>
      // Replace /intelligence/edit/<id> with /intelligence
      navigate(prefixOrgSlug("/intelligence"), { replace: true }),
    onError: () =>
      pushSnackbar(
        "Failed to delete intelligence dashboard, please try again",
        FeedbackType.Error
      ),
  });

  return (
    <>
      <IconButton
        color="primary"
        className=""
        disabled={deleting}
        onClick={async () => {
          if (mode === FormMode.create) {
            navigate(prefixOrgSlug("/intelligence"), {
              replace: true,
            });
          } else {
            const deleteConfirmed = await openDeleteDialog();
            // Make sure the user confirms deletion
            if (!deleteConfirmed) return;
            deleteIntelligenceDashboard({
              variables: { id: editingId },
              update(cache) {
                cache.evict({
                  id: `IntelligenceDashboard:${editingId}`,
                });
              },
            });
          }
        }}
        name="delete-dashboard"
      >
        <DeleteIcon />
      </IconButton>
      {mode === FormMode.edit && (
        <DefaultDialog
          title="Delete Intelligence Dashboard"
          confirmText="Delete"
          content="Are you sure you want to delete this dashboard?"
          {...deleteDialogProps}
        />
      )}
    </>
  );
}

gql`
  query intDashboardCam($ids: [Int!]!) {
    cameras(ids: $ids) {
      id
      name
      still
      feeds {
        tunnel
        local
      }

      focusZones {
        id
        name
        shape {
          x
          y
        }
      }

      location {
        id
        name
      }

      settings {
        lprEnabled
      }
    }
  }
`;

const intDashFrag = gql`
  fragment IntelligenceDashboardFragment on IntelligenceDashboard {
    id
    name
    thresholdSeconds
    daysOfWeek
    startTime
    endTime
    type
    subtype
    entityCount
    description
    objectTypes
    alerts {
      id
      name
    }
    interestLists {
      id
      type
      name
      items {
        name
        source
        notes
      }
    }
    cameras {
      id
      name
      still
      location {
        id
        name
        timezone
      }
    }
    zone {
      id
      name
      shape {
        x
        y
      }
    }
  }
`;

gql`
  query intelligenceDashboard($id: Int!) {
    intelligenceDashboard(id: $id) {
      ...IntelligenceDashboardFragment

      referenceDashboards {
        id
        name
        type
        cameras {
          id
          name
          still
          location {
            id
            name
            timezone
          }
        }
      }
    }
  }
  ${intDashFrag}
`;

gql`
  query intelligenceDashboardPreview(
    $input: IntelligenceDashboardInput!
    $startDate: Date!
    $endDate: Date!
    $usePaths: Boolean
  ) {
    intelligenceDashboardPreview(input: $input) {
      id
      cameras {
        id
        location {
          id
          timezone
        }
      }
      metrics(
        startDate: $startDate
        endDate: $endDate
        input: { bucketSizeSeconds: 3600, usePaths: $usePaths }
      ) {
        results {
          totalCount
          idleCount
          averageIdleTimeSeconds
          averageNonzeroIdleTimeSeconds
          maxIdleTimeSeconds
          entities {
            objectId
            firstMs
            lastMs
            idleSum
            queueNum
            crossedLeft
            crossedRight
          }
          leftCount
          rightCount
          bucketCounts {
            bucketMs
            totalCount
            idleCount
            leftCount
            rightCount
          }
        }
      }
    }
  }
`;

gql`
  query presenceDashboardPreview(
    $input: IntelligenceDashboardInput!
    $startDate: Date!
    $endDate: Date!
  ) {
    intelligenceDashboardPreview(input: $input) {
      id
      cameras {
        id
        location {
          id
          timezone
        }
      }
      presence(
        input: {
          bucketSizeSeconds: 3600
          startDate: $startDate
          endDate: $endDate
        }
      ) {
        results {
          totalMsecPresent
          totalMsec
          longestPresence {
            startMs
            endMs
          }
          longestAbsence {
            startMs
            endMs
          }
          presenceBuckets {
            bucketMs
            bucketEndMs
            presenceSum
            intervals {
              startMs
              endMs
            }
          }
        }
      }
    }
  }
`;

gql`
  mutation createIntelligenceDashboard($input: IntelligenceDashboardInput!) {
    createIntelligenceDashboard(input: $input) {
      ...IntelligenceDashboardFragment
    }
  }
  ${intDashFrag}
`;

gql`
  mutation updateIntelligenceDashboard(
    $id: Int!
    $input: IntelligenceDashboardInput!
  ) {
    updateIntelligenceDashboard(id: $id, input: $input) {
      ...IntelligenceDashboardFragment
    }
  }
  ${intDashFrag}
`;

gql`
  mutation deleteIntelligenceDashboard($id: Int!) {
    deleteIntelligenceDashboard(id: $id) {
      message
    }
  }
`;
