import { Container } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Navigate, Route, Routes } from "react-router-dom";

import { useBreakpoints } from "@/util/useBreakpoints";

import { usePermissions } from "@/hooks/usePermissions";
import { ContentWrapper } from "@/layout/ContentWrapper";

import { IntegrationsCreatePage } from "../IntegrationsOld/IntegrationsCreate/IntegrationsCreatePage";
import { IntegrationsDetailsDeviceOverview } from "../IntegrationsOld/IntegrationsDetails/IntegrationsDetailsDeviceOverview/IntegrationsDetailsDeviceOverview";
import { IntegrationsDetailsPage } from "../IntegrationsOld/IntegrationsDetails/IntegrationsDetailsPage";
import { IntegrationsEditForm } from "../IntegrationsOld/IntegrationsEdit/IntegrationsEditForm";
import { IntegrationProvider } from "../IntegrationsOld/useIntegrationsContext";
import { IntegrationCreatePage } from "./Connect/Create/IntegrationCreatePage";
import { IntegrationDetailsDeviceEventPage } from "./Connect/Details/DeviceEvent/IntegrationDetailsDeviceEventPage";
import { IntegrationDetailsPage as IntegrationConnectDetailsPage } from "./Connect/Details/IntegrationDetailsPage";
import { IntegrationEditPage } from "./Connect/Edit/IntegrationEditPage";
import { IntegrationsOverviewPage } from "./IntegrationsOverview/IntegrationsOverviewPage";

export function IntegrationsPage() {
  const { fitsDesktop } = useBreakpoints();
  const { spotConnect } = useFlags();
  const hasPermission = usePermissions();

  return (
    <IntegrationProvider>
      <div
        style={{
          background: "url(/integrations/integrations-mist.svg)",
          backgroundSize: "100%",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="min-h-[calc(100vh-70px)]">
          <ContentWrapper>
            <Container
              maxWidth="xl"
              style={fitsDesktop ? undefined : { padding: 0 }}
            >
              <Routes>
                <Route index element={<Navigate to="overview" replace />} />
                <Route path="overview" element={<IntegrationsOverviewPage />} />
                <Route
                  path={"connect/spot/new"}
                  element={
                    spotConnect ? (
                      hasPermission(
                        "integrations_manage",
                        <IntegrationCreatePage />
                      )
                    ) : (
                      <Navigate to="/integrations/overview" replace={true} />
                    )
                  }
                />
                <Route
                  path={"connect/spot/:integrationId"}
                  element={
                    spotConnect ? (
                      <IntegrationConnectDetailsPage />
                    ) : (
                      <Navigate to="/integrations/overview" replace={true} />
                    )
                  }
                />
                <Route
                  path={"connect/spot/:integrationId/edit"}
                  element={
                    spotConnect ? (
                      hasPermission(
                        "integrations_manage",
                        <IntegrationEditPage />
                      )
                    ) : (
                      <Navigate to="/integrations/overview" replace={true} />
                    )
                  }
                />
                <Route
                  path={"connect/spot/:integrationId/device/:deviceId"}
                  element={
                    spotConnect ? (
                      <IntegrationDetailsDeviceEventPage />
                    ) : (
                      <Navigate to="/integrations/overview" replace={true} />
                    )
                  }
                />
                <Route
                  path={":typeId/:vendorKey/new"}
                  element={hasPermission(
                    "integrations_manage",
                    <IntegrationsCreatePage />
                  )}
                />
                <Route
                  path={":typeId/:vendorKey/new/:integrationId"}
                  element={hasPermission(
                    "integrations_manage",
                    <IntegrationsCreatePage />
                  )}
                />
                <Route
                  path={":typeId/:vendorKey/:integrationId"}
                  element={<IntegrationsDetailsPage />}
                />
                <Route
                  path={":typeId/:vendorKey/:integrationId/device/:sourceId"}
                  element={<IntegrationsDetailsDeviceOverview />}
                />
                <Route
                  path={":typeId/:vendorKey/:integrationId/edit"}
                  element={hasPermission(
                    "integrations_manage",
                    <IntegrationsEditForm />
                  )}
                />
              </Routes>
            </Container>
          </ContentWrapper>
        </div>
      </div>
    </IntegrationProvider>
  );
}
