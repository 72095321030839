import { LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";

export function VideoWallRotationProgress({
  page,
  pageDuration,
}: {
  page: number;
  pageDuration: number;
}) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const start = Date.now();
    const timer = setInterval(() => {
      // 10 is because of 1000 (ms) / 100 (%)
      setProgress((Date.now() - start) / pageDuration / 10);
    }, 50);
    return () => {
      clearInterval(timer);
      setProgress(0);
    };
  }, [page, pageDuration]);

  return <LinearProgress variant="determinate" value={progress} />;
}
