import CheckIcon from "@mui/icons-material/Check";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Alert, Button, Typography } from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import clsx from "clsx";
import { useField } from "formik";
import gql from "graphql-tag";
import { groupBy } from "lodash/fp";
import { useMemo, useState } from "react";

import { useBreakpoints } from "@/util/useBreakpoints";

import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";

import { refetchOnMountPolicy } from "@/apolloClient";
import { useAlertIntegrationSourcesQuery } from "@/generated-models";

export function AlertIntegrationDevices() {
  const { fitsTablet } = useBreakpoints();
  const [expandedId, setExpandedId] = useState<string | null>(null);
  const [
    ,
    { value: selectedSourceIds = [], error: inputError },
    { setValue: setSelectedSourceIds },
  ] = useField<number[] | undefined>({
    name: "integrationSourceIds",
    validate: (input: number[]) =>
      !input?.length ? "Please select a device" : undefined,
  });
  const [, { value: integrationId }] = useField<string>("integrationId");
  const { data, error } = useAlertIntegrationSourcesQuery({
    ...refetchOnMountPolicy,
    variables: { input: { id: Number(integrationId) } },
  });
  const integrationSources = useMemo(() => {
    if (!data) return undefined;

    // Filter integration sources with no cameras
    const filteredSources = data.integrationSources.filter(
      (source) => source.cameras.length > 0
    );

    // Map integration sources to better format
    const mappedSources = filteredSources.map(
      ({ id, standardMeta, cameras }) => ({
        id,
        location:
          (standardMeta?.siteName as string) ??
          cameras[0].location.name ??
          "Unknown",
        deviceName: (standardMeta?.name as string) ?? "Unknown",
        thumbnail: cameras[0].still,
      })
    );

    // Group integration sources by location
    const groupedSources = Object.entries(
      groupBy("location", mappedSources)
    ).map(([key, value]) => ({
      id: key,
      title: key,
      devices: value,
    }));

    return { groupedSources, filteredSources };
  }, [data]);

  if (error) {
    return (
      <ErrorMessage
        title="Failed to load devices."
        description={error?.message}
        dense
      />
    );
  }

  if (!integrationSources) {
    return <Loading />;
  }

  const { groupedSources, filteredSources } = integrationSources;
  if (!groupedSources.length) {
    return (
      <ErrorMessage
        title="No integration devices found."
        description="To create an alert for this integration it needs to have at least one device connected with at least one linked camera."
        dense
      />
    );
  }

  return (
    <>
      <div className="flex justify-between items-center">
        <Typography className="font-light text-xl">
          {filteredSources.length} Devices
        </Typography>
        <Button
          color="primary"
          className="font-normal text-base -mr-2"
          onClick={() =>
            setSelectedSourceIds(filteredSources.map((source) => source.id))
          }
          disabled={selectedSourceIds.length === filteredSources.length}
        >
          Select All
        </Button>
      </div>
      {selectedSourceIds.length > 0 && (
        <div className="flex justify-between items-center mt-2 px-3 py-1.5 bg-primary text-white rounded-lg">
          <Typography className="font-bold">
            {selectedSourceIds.length} Selected
          </Typography>
          <Button
            className="font-normal -mr-2"
            onClick={() => setSelectedSourceIds([])}
          >
            Clear All
          </Button>
        </div>
      )}
      {inputError && (
        <Alert severity="error" className="mt-2">
          {inputError}
        </Alert>
      )}
      <div className="mt-4">
        {groupedSources.map((data, index) => {
          const selectedCount = data.devices.filter((device) =>
            selectedSourceIds.includes(device.id)
          ).length;

          return (
            <MuiAccordion
              key={data.id}
              elevation={0}
              disableGutters
              expanded={expandedId ? expandedId === data.id : index === 0}
              onChange={(_, expanded) =>
                setExpandedId(expanded ? data.id : null)
              }
              sx={(theme) => ({
                "&:before": { display: "none" },
                marginBottom: theme.spacing(2),
              })}
            >
              <MuiAccordionSummary
                aria-controls={`panel${data.id}d-content`}
                id={`panel${data.id}d-header`}
                expandIcon={<ExpandMoreIcon className="text-text" />}
                classes={{ root: "bg-gray-f4 rounded-lg" }}
              >
                <Typography className="md:text-lg">
                  <strong>{data.title}</strong>
                  {` - ${data.devices.length} Devices`}
                  {fitsTablet &&
                    selectedCount > 0 &&
                    ` / ${selectedCount} Selected`}
                </Typography>
              </MuiAccordionSummary>
              <MuiAccordionDetails
                classes={{
                  root:
                    "pt-3 pb-0 px-0 grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4",
                }}
              >
                {data.devices.map((device, index) => {
                  const isChecked = selectedSourceIds.includes(device.id);

                  return (
                    <label
                      key={device.id}
                      className={clsx(
                        "text-primary border border-[#DCDCDC] p-4 rounded-lg flex items-start gap-x-3 relative cursor-pointer",
                        { "bg-[#F6FBFF]": isChecked }
                      )}
                    >
                      <input
                        type="checkbox"
                        className="peer sr-only"
                        name={`device-${device.id}`}
                        id={`device-${device.id}`}
                        checked={isChecked}
                        onChange={() => {
                          if (isChecked) {
                            setSelectedSourceIds(
                              selectedSourceIds.filter((id) => id !== device.id)
                            );
                          } else {
                            setSelectedSourceIds([
                              ...selectedSourceIds,
                              device.id,
                            ]);
                          }
                        }}
                      />
                      <div className="w-5 h-5 border-2 border-[#999] peer-checked:bg-primary peer-checked:border-primary flex rounded shrink-0">
                        <CheckIcon className="text-white w-full h-full items-center justify-center align-middle" />
                      </div>
                      <img
                        className="shrink w-[97px] rounded-lg overflow-hidden hidden sm:block"
                        src={device.thumbnail}
                        alt={device.deviceName}
                      />
                      <div className="overflow-hidden">
                        <p className="text-xs sm:text-sm truncate">
                          {device.location}
                        </p>
                        <p className="text-base sm:text-lg font-bold truncate">
                          {device.deviceName}
                        </p>
                      </div>
                    </label>
                  );
                })}
              </MuiAccordionDetails>
            </MuiAccordion>
          );
        })}
      </div>
    </>
  );
}

gql`
  query alertIntegrationSources($input: IntegrationSourcesInput) {
    integrationSources(input: $input) {
      id
      standardMeta

      cameras {
        id
        still
        location {
          id
          name
        }
      }
    }
  }
`;
