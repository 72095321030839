import { ReactNode } from "react";

import { ReactComponent as CasesIcon } from "@/icons/CasesIcon_filled.svg";

import { useBreakpoints } from "@/util/useBreakpoints";

interface CaseEmptyStateProps {
  createCaseButton: ReactNode;
  headerContent: string[];
}

export const CasesEmptyState = ({
  createCaseButton,
  headerContent,
}: CaseEmptyStateProps) => {
  const { fitsTablet } = useBreakpoints();
  return (
    <div className="md:flex items-center justify-center gap-[50px]">
      <figure className="relative w-full md:w-auto h-60 md:h-auto mb-9 md:mb-0 ">
        {fitsTablet && (
          <img
            className="w-full h-full md:w-auto relative z-10"
            src="/CasesEmptyStateMain.svg"
            alt=""
          />
        )}
        {!fitsTablet && (
          <img
            className="w-full relative z-10 sm:mt-0 -mt-12"
            src="/CasesEmptyStateMainMobile.svg"
            alt=""
          />
        )}
        <img
          width="1149"
          height="664"
          className="w-full h-full absolute bottom-0 right-0 z-1 max-w-none"
          src="/CasesEmptyStateIconRectangles.svg"
          alt=""
        />
        <img
          width="1306"
          height="982"
          className="hidden md:block absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-0 w-[1306px] h-[982px] max-w-none"
          src="/CasesEmptyStateIconGradient.svg"
          alt=""
        />
      </figure>
      <div className="w-3/4 sm:w-[60%] md:w-auto max-w-sm md:max-w-none mx-auto md:mx-0 relative z-20">
        <h1 className="text-2xl md:text-[44px] text-text font-bold flex items-center mb-3 gap-2">
          <CasesIcon />
          <span className="leading-4">Cases</span>
        </h1>
        <h2 className="text-[40px] md:text-[64px] leading-[0.96969697em] text-primary font-light">
          Investigate.
          <br />
          Collaborate.
          <br />
          <strong>Resolve.</strong>
        </h2>
        <ul className="text-sm md:text-lg mt-5 list-disc pl-4">
          {headerContent.map((listText, index) => (
            <li
              key={`listItem-${index}`}
              className="text-primary mt-3 md:mt-0 first-of-type:mt-0"
            >
              <span className="text-text">{listText}</span>
            </li>
          ))}
        </ul>
        <div className="mt-7">{createCaseButton}</div>
      </div>
    </div>
  );
};
