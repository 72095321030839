import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { Select, TextField } from "formik-mui";
import gql from "graphql-tag";
import { uniq } from "lodash/fp";
import { makeStyles } from "tss-react/mui";

import { authProviderDisplayNames } from "@/pages/Settings/UserManagement/UserSettingsUtils";

import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";

import {
  SsoProvider,
  useAddSsoConnectionMutation,
  useGetSsoConnectionsQuery,
  usePage_UsersQuery,
  useRemoveSsoConnectionMutation,
} from "@/generated-models";

const useStyles = makeStyles()(() => ({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 16,
    backgroundColor: "#F0F0F0",
    borderRadius: "4px 4px 0 0",
  },
  row: { flexWrap: "nowrap", padding: "12px 0 12px 16px" },
}));

function required(message: string) {
  return (input: string) => (!input ? message : undefined);
}

export function AddSsoConnectionModal({ close }: { close: () => void }) {
  const { classes } = useStyles();
  const { pushSnackbar } = useFeedback();
  const { data: usersData } = usePage_UsersQuery();
  const { data, error } = useGetSsoConnectionsQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const [removeSso, { loading: removing }] = useRemoveSsoConnectionMutation({
    refetchQueries: ["getSsoConnections"],
    awaitRefetchQueries: true,
    onError: () =>
      pushSnackbar("Failed deleting SSO connection", FeedbackType.Error),
  });
  const [addSso] = useAddSsoConnectionMutation({
    refetchQueries: ["getSsoConnections", "getUsers"],
    awaitRefetchQueries: true,
    onError: () =>
      pushSnackbar(
        "Unable to create SSO connection. Please contact help@spotai.co",
        FeedbackType.Error
      ),
  });

  if (error) {
    return (
      <ErrorMessage
        title="Oops"
        description="Something went wrong. Please refresh or try again later."
      />
    );
  }

  if (!data || !usersData) {
    return (
      <Box py={5}>
        <Loading />
      </Box>
    );
  }
  const userDomains = uniq(
    usersData.users.map((u) => u.profile.email.split("@")[1].toLowerCase())
  );

  return (
    <>
      <div className={classes.header}>
        <Typography variant="h2">Setup Single Sign-On</Typography>
        <IconButton size="small" onClick={close}>
          <CloseIcon />
        </IconButton>
      </div>

      {data.ssoConnections.map((row) => {
        const isUsed = userDomains.includes(row.domain.toLowerCase());
        return (
          <Grid
            container
            alignItems="center"
            key={row.id}
            className={classes.row}
            style={{ borderBottom: "1px solid #e3e3e3" }}
          >
            <Grid item xs={5}>
              {row.domain}
            </Grid>
            <Grid item xs={5}>
              {authProviderDisplayNames[row.provider]}
            </Grid>
            <Grid container item xs={2} justifyContent="center">
              <Tooltip
                title={
                  isUsed
                    ? "Cannot be deleted, since users depend on it"
                    : "Delete SSO domain"
                }
              >
                <span>
                  <IconButton
                    size="small"
                    onClick={() => removeSso({ variables: { id: row.id } })}
                    disabled={removing || isUsed}
                  >
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        );
      })}

      <Formik
        initialValues={{ domain: "", provider: "" as SsoProvider }}
        onSubmit={(values, { resetForm }) => {
          const input = {
            domain: values.domain.trim(),
            provider: values.provider,
          };
          if (!input.provider || !input.domain) return;
          return addSso({ variables: { input } }).then(
            ({ errors }) => !errors && resetForm()
          );
        }}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <Grid container style={{ padding: "12px 0 12px 16px" }}>
              <Grid item sm={5} xs={6} style={{ paddingRight: 8 }}>
                <Field
                  component={TextField}
                  name="domain"
                  fullWidth
                  placeholder="example.com"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">@</InputAdornment>
                    ),
                  }}
                  InputLabelProps={{ shrink: true }}
                  label="SSO domain"
                  validate={(value: string) => {
                    if (!value) return "Please provide a domain";
                    if (
                      !/^[a-zA-Z0-9-.]{1,61}\.[a-zA-Z]{2,}$/.test(value.trim())
                    ) {
                      return "Domain is not valid";
                    }
                  }}
                />
              </Grid>
              <Grid item sm={5} xs={6}>
                <FormControl fullWidth>
                  <InputLabel
                    className="mt-2 -ml-3"
                    shrink
                    htmlFor="provider-select"
                  >
                    SSO provider
                  </InputLabel>
                  <Field
                    component={Select}
                    name="provider"
                    id="provider-select"
                    validate={required("Please select a provider")}
                    displayEmpty
                    formControl={{ variant: "standard" }}
                    inputLabel={{ variant: "standard" }}
                  >
                    <MenuItem value="">
                      <em>Choose your provider</em>
                    </MenuItem>
                    <MenuItem value={SsoProvider.Google}>
                      {authProviderDisplayNames[SsoProvider.Google]}
                    </MenuItem>
                    <MenuItem value={SsoProvider.Microsoft}>
                      {authProviderDisplayNames[SsoProvider.Microsoft]}
                    </MenuItem>
                  </Field>
                </FormControl>
              </Grid>
              <Grid
                container
                item
                sm={2}
                xs={12}
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={isSubmitting || !isValid}
                  style={{ marginTop: 8 }}
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}

export const GET_SSO_CONNECTIONS = gql`
  query getSsoConnections {
    ssoConnections {
      id
      provider
      domain
    }
  }
`;

export const ADD_SSO_CONNECTION = gql`
  mutation addSsoConnection($input: SsoConnectionInput!) {
    addSsoConnection(input: $input) {
      id
      provider
      domain
    }
  }
`;
export const REMOVE_SSO_CONNECTION = gql`
  mutation removeSsoConnection($id: Int!) {
    removeSsoConnection(id: $id) {
      message
    }
  }
`;
