import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import gql from "graphql-tag";
import { flow, groupBy } from "lodash/fp";
import { useMemo } from "react";
import {
  ArrayParam,
  StringParam,
  useQueryParam,
  useQueryParams,
} from "use-query-params";

import { useSearchRangeParams } from "@/pages/Search/searchHooks";

import { GetLprSearchQuery, useGetLprSearchQuery } from "@/generated-models";

import {
  SortFieldParams,
  SortParams,
} from "./LicensePlateSummaryBar/LicensePlateSummarySortButton";
import { MINIMUM_QUERY_LENGTH } from "./constants";
import { getUniqueLprId } from "./utils";

function sortLprData(
  collection: GetLprSearchQuery["lprSearch"],
  field: string,
  direction: number
) {
  const sortFieldGetter = (item: GetLprSearchQuery["lprSearch"][number]) => {
    if (field === "location") {
      return item.camera.location.name;
    } else if (field === "camera") {
      return item.camera.name;
    }
    return item.startTime;
  };
  const resolvedDirection = field === "time" ? direction : -1 * direction;
  collection.sort(
    (a, b) =>
      sortFieldGetter(a).localeCompare(sortFieldGetter(b)) * resolvedDirection
  );
}

export function useLprSelected() {
  const [viewing] = useQueryParam("viewing", StringParam);
  const { data, loading, error } = useLprSearch();
  const selected = useMemo(() => data?.find((d) => d.id === viewing), [
    data,
    viewing,
  ]);

  return { data: selected, loading, error };
}

export function useLprSearch(filter?: boolean) {
  const { rangeStart, rangeEnd } = useSearchRangeParams();
  const [locationsFilter] = useQueryParam("locations", ArrayParam);
  const [camerasFilter] = useQueryParam("cameras", ArrayParam);
  const [searchFilter] = useQueryParam("search", StringParam);
  const [sortParams] = useQueryParams({
    sort: SortParams,
    field: SortFieldParams,
  });

  const disabled = !searchFilter;
  const query = useGetLprSearchQuery({
    variables: {
      input: {
        plates: searchFilter ? [searchFilter] : null,
        startTime: rangeStart?.toISOString(),
        endTime: rangeEnd?.toISOString(),
      },
    },
    skip: disabled || searchFilter.length < MINIMUM_QUERY_LENGTH,
  });

  const data = useMemo(() => {
    const collection =
      query.data?.lprSearch?.map((d) => ({
        id: getUniqueLprId(d),
        ...d,
      })) || [];

    if (sortParams.sort) {
      sortLprData(collection, sortParams.field, sortParams.sort);
    }

    if (filter) {
      return collection
        .filter(
          (d) =>
            !locationsFilter ||
            locationsFilter.includes(String(d.camera.location.id))
        )
        .filter(
          (d) => !camerasFilter || camerasFilter.includes(String(d.camera.id))
        );
    }

    return collection;
  }, [
    camerasFilter,
    filter,
    locationsFilter,
    query.data?.lprSearch,
    sortParams.field,
    sortParams.sort,
  ]);

  return { ...query, data, disabled };
}

interface GroupLprResult {
  value: number | string;
  label: string;
  results: (GetLprSearchQuery["lprSearch"][number] & { id: string })[];
}

// Based on current sorting, group results by the sorting category.
export function useGroupedLprSearch(groupByDate?: boolean) {
  const map = require("lodash/fp/map").convert({ cap: false });
  const query = useLprSearch(true);
  const [sortParams] = useQueryParams({
    field: SortFieldParams,
  });

  const data = useMemo(() => {
    if (groupByDate || sortParams.field === "time") {
      const monthName = (item: GetLprSearchQuery["lprSearch"][number]) => {
        const converted = utcToZonedTime(
          new Date(item.startTime),
          item.camera.location.timezone
        );
        return new Date(
          converted.getFullYear(),
          converted.getMonth()
        ).getTime();
      };

      return flow(
        groupBy(monthName),
        map((results: GetLprSearchQuery["lprSearch"], time: number): any => ({
          value: Number(time),
          label: format(new Date(Number(time)), "MMMM YYY"),
          results,
        }))
      )(query.data);
    }

    return flow(
      groupBy(
        sortParams.field === "location" ? "camera.location.name" : "camera.name"
      ),
      map((results: GetLprSearchQuery["lprSearch"], label: string): any => ({
        value: label,
        label: label,
        results,
      }))
    )(query.data);
  }, [groupByDate, map, query.data, sortParams.field]) as GroupLprResult[];

  return { ...query, data };
}

export const GET_LPR_SEARCH = gql`
  query getLprSearch($input: LprSearchInput!) {
    lprSearch(input: $input) {
      vehicleId
      startTime
      endTime
      vehicleAttributes {
        plate
        make
        model
        colorId
        typeId
      }
      camera {
        id
        name
        firstSegmentTime
        health {
          cameraOnline
          applianceOnline
        }
        location {
          id
          name
          timezone
        }
        settings {
          audioControlEnabled
        }
      }
    }
  }
`;
