import FilterListIcon from "@mui/icons-material/FilterList";
import SearchOutlined from "@mui/icons-material/SearchOutlined";
import {
  Container,
  IconButton,
  Pagination,
  PaginationItem,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import gql from "graphql-tag";
import { useFlags } from "launchdarkly-react-client-sdk";
import { uniqBy } from "lodash/fp";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { usePagination } from "react-use-pagination";
import { ArrayParam, StringParam, useQueryParam } from "use-query-params";

import { formatIsoDate } from "@/util/date";
import { useAIModelEnabled } from "@/util/featureToggles";
import { pluralize } from "@/util/pluralize";
import { useBreakpoints } from "@/util/useBreakpoints";
import { useDocumentTitle } from "@/util/useDocumentTitle";

import { IntelligenceFeatureConfig } from "@/pages/Intelligence/constants";

import { ErrorMessage } from "@/components/ErrorMessage";
import { MobileFilterActionBar } from "@/components/Filter/Mobile/MobileFilterActionBar";
import { MobileFilterModal } from "@/components/Filter/Mobile/MobileFilterModal";
import { MobileFilterSearchBar } from "@/components/Filter/Mobile/MobileFilterSearchBar";
import { MobileSearchModal } from "@/components/Filter/Mobile/MobileSearchModal";
import { IntelligenceFilter } from "@/components/Insights/DashboardFilter";
import { Loading } from "@/components/Loading";
import { SearchBox } from "@/components/SearchBox";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  AdditionalAiClass,
  IntelligenceDashboardContentDocument,
  IntelligenceDashboardType,
  IntelligencePresenceDashboardContentDocument,
  Page_IntelligenceQuery,
  useApplianceLprEnabledQuery,
  useHasAiLicenseQuery,
  usePage_IntelligenceQuery,
} from "@/generated-models";

import { ComparativeToolbar } from "./FormInputs/ComparativeForm/ComparativeToolbar";
import { IntelligenceFeatureHeader } from "./Header/IntelligenceFeatureHeader";
import { IntelligenceCreatePromptModal } from "./IntelligenceCreatePromptModal";
import { IntelligenceDashboardCard } from "./IntelligenceDashboardCard";
import { IntelligenceEmptyState } from "./IntelligenceEmptyState";
import { useLprEnabled } from "./hooks";
import { getDashboardFeatureType } from "./utils";

const DASHBOARDS_PER_PAGE = 12;

type QueriedDashboards = Page_IntelligenceQuery["intelligenceDashboards"];

export function Intelligence({ asForm }: { asForm?: boolean }) {
  useDocumentTitle("Intelligence");
  const { loading: lprEnabledLoading } = useApplianceLprEnabledQuery();
  const {
    data: licenseQuery,
    loading: licenseQueryLoading,
  } = useHasAiLicenseQuery();
  const { data, loading, error, client } = usePage_IntelligenceQuery({
    ...refetchOnMountPolicy,
  });
  const lprReportEnabled = useLprEnabled();
  const hasAiLicense = licenseQuery?.hasAiLicense;
  const headerRef = useRef<HTMLDivElement | null>(null);
  const [mobileSearch, setMobileSearch] = useState(false);
  const [mobileFilter, setMobileFilter] = useState(false);
  const [mobileModal, setMobileModal] = useState(false);
  const [metricsFilter] = useQueryParam("metrics", ArrayParam);
  const [locationsFilter] = useQueryParam("locations", ArrayParam);
  const [camerasFilter] = useQueryParam("cameras", ArrayParam);
  const [searchFilter, setSearchFilter] = useQueryParam("search", StringParam);
  const { lprInterests, comparativeInt } = useFlags();

  const navigate = useNavigate();
  const emptyFilters =
    (asForm || !metricsFilter) && !locationsFilter && !camerasFilter;
  const forkliftEnabled = useAIModelEnabled(AdditionalAiClass.Forklift);
  const { fitsDesktop, fitsTablet } = useBreakpoints();

  const filterCategoryOptions = useMemo(() => {
    const result = [
      IntelligenceFeatureConfig.VehicleCounting,
      IntelligenceFeatureConfig.VehicleIdleTime,
      IntelligenceFeatureConfig.VehiclePresence,

      IntelligenceFeatureConfig.PeopleCounting,
      IntelligenceFeatureConfig.PeopleIdleTime,
      IntelligenceFeatureConfig.PeoplePresence,
    ];

    if (lprReportEnabled) {
      result.push(IntelligenceFeatureConfig.LicensePlateReports);
    }

    if (comparativeInt) {
      result.push(IntelligenceFeatureConfig.ComparativeInsights);
    }

    if (lprInterests) {
      result.push(IntelligenceFeatureConfig.LicensePlateInterestLists);
    }

    if (forkliftEnabled) {
      result.push(
        ...[
          IntelligenceFeatureConfig.ForkliftCounting,
          IntelligenceFeatureConfig.ForkliftIdleTime,
          IntelligenceFeatureConfig.ForkliftPresence,
        ]
      );
    }

    return result.sort((a, b) =>
      a.label.displayName.localeCompare(b.label.displayName)
    );
  }, [comparativeInt, forkliftEnabled, lprInterests, lprReportEnabled]);

  const filterCategories = [
    ...(asForm
      ? []
      : [
          {
            name: "Type",
            filterName: "metrics",
            options: filterCategoryOptions.map((e) => ({
              label: e.label.displayName,
              value: e.type,
            })),
          },
        ]),
    {
      name: "Locations",
      filterName: "locations",
      options: uniqBy(
        "value",
        data?.intelligenceDashboards
          .map((d) =>
            d.cameras.map((cam) => ({
              label: cam.location.name,
              value: String(cam.location.id),
            }))
          )
          .filter((x) => x)
          .flat() || []
      ),
    },
    ...(asForm
      ? []
      : [
          {
            name: "Cameras",
            filterName: "cameras",
            options: uniqBy(
              "value",
              data?.intelligenceDashboards
                .map((d) =>
                  d.cameras.map((cam) => ({
                    label: cam.name,
                    value: String(cam.id),
                  }))
                )
                .filter((x) => x)
                .flat() || []
            ),
          },
        ]),
  ];

  useEffect(() => {
    if (!hasAiLicense && !licenseQueryLoading) {
      navigate("..");
    }
  }, [hasAiLicense, licenseQueryLoading, navigate]);

  const filteredDashboards = data?.intelligenceDashboards
    .filter(
      (d) =>
        !metricsFilter ||
        metricsFilter.includes(getDashboardFeatureType(d.type, d.objectTypes))
    )
    .filter(
      (d) =>
        !locationsFilter ||
        d.cameras.some((cam) =>
          locationsFilter.includes(String(cam.location.id))
        )
    )
    .filter(
      (d) =>
        !camerasFilter ||
        d.cameras.some((cam) => camerasFilter.includes(String(cam.id)))
    )
    .filter(
      (d) => comparativeInt || d.type !== IntelligenceDashboardType.Compound
    )
    .filter(
      (d) =>
        !searchFilter ||
        [
          d.name,
          ...(d.cameras.map((cam) => [cam.name, cam.location.name]).flat() ??
            []),
        ]
          .join(" ")
          .toLowerCase()
          .includes(searchFilter.toLowerCase())
    );

  const {
    currentPage,
    totalPages,
    setPage,
    startIndex,
    endIndex,
  } = usePagination({
    totalItems: filteredDashboards?.length ?? 0,
    initialPageSize: DASHBOARDS_PER_PAGE,
  });

  const dashboards = filteredDashboards?.slice(startIndex, endIndex + 1);

  if (loading || licenseQueryLoading || lprEnabledLoading) return <Loading />;

  return (
    <div
      className={clsx("h-full overflow-auto", {
        "md:pt-5 pb-16": !asForm,
        relative: asForm && !fitsTablet,
      })}
      style={
        fitsDesktop && !asForm
          ? {
              background:
                "linear-gradient(180deg, rgba(255,255, 255,0) 0%, rgba(255, 255, 255, 1) 40%)," +
                "linear-gradient(91.55deg, rgba(235, 245, 255, 1) 1.07%, rgba(249, 247, 255, 1) 98.2%)",
            }
          : {}
      }
    >
      {!asForm && (
        <>
          {data?.intelligenceDashboards.length === 0 ? (
            <IntelligenceEmptyState />
          ) : (
            <IntelligenceFeatureHeader />
          )}
          <IntelligenceCreatePromptModal />
        </>
      )}
      {asForm && <ComparativeToolbar />}
      {!fitsDesktop && (data?.intelligenceDashboards?.length ?? 0) > 0 && (
        <>
          <MobileFilterModal
            count={dashboards?.length || 0}
            open={mobileFilter}
            subject="Dashboards"
            onBack={() => {
              setMobileFilter(false);
            }}
            categories={filterCategories}
          />
          <MobileSearchModal
            open={mobileModal}
            subject="Dashboard"
            total={data?.intelligenceDashboards?.length ?? 0}
            onBack={() => {
              setMobileModal(false);
              if (searchFilter) {
                headerRef?.current?.scrollIntoView();
              }
            }}
          >
            <div className="grid md:grid-cols-1 gap-6 p-4">
              {dashboards?.map((dashboard) => (
                <IntelligenceDashboardCard
                  minimal
                  key={dashboard.id}
                  dashboard={dashboard}
                  selectable={asForm}
                />
              ))}
            </div>
          </MobileSearchModal>
          <div
            ref={headerRef}
            className="flex justify-between items-center px-4 mt-6 mb-2 min-h-[40px] scroll-mt-2"
          >
            <Typography variant="h2" className={clsx(asForm && "invisible")}>
              {pluralize(
                {
                  multi: <>{filteredDashboards?.length} Insights</>,
                  1: <>{filteredDashboards?.length} Insight</>,
                  0: <>No Insights</>,
                },
                filteredDashboards?.length ?? 0
              )}
            </Typography>
            <div className="flex items-center gap-x-4">
              {!mobileSearch && !searchFilter && (
                <IconButton
                  className="bg-[#DDEFFF]"
                  color="primary"
                  onClick={() => {
                    setMobileModal(true);
                  }}
                >
                  <SearchOutlined />
                </IconButton>
              )}
              {emptyFilters && (
                <IconButton
                  className="bg-[#DDEFFF]"
                  color="primary"
                  onClick={() => {
                    setMobileFilter(true);
                  }}
                >
                  <FilterListIcon />
                </IconButton>
              )}
            </div>
          </div>
          {!mobileModal && searchFilter && (
            <MobileFilterSearchBar
              onClick={() => setMobileModal(true)}
              onHide={() => setMobileSearch(false)}
            />
          )}
          <MobileFilterActionBar
            dynamic
            categories={filterCategories}
            onFilterClick={() => {
              setMobileFilter(true);
            }}
          />
        </>
      )}

      {fitsDesktop &&
        data?.intelligenceDashboards &&
        data.intelligenceDashboards.length > 0 && (
          <Container
            maxWidth="xl"
            className="flex gap-6 mt-6 items-center md:flex-row flex-col flex-wrap"
          >
            {!asForm && (
              <div className="flex-auto">
                <Typography variant="h2">
                  {pluralize(
                    {
                      multi: <>{filteredDashboards?.length} Insights</>,
                      1: <>{filteredDashboards?.length} Insight</>,
                      0: <>No Insights</>,
                    },
                    filteredDashboards?.length ?? 0
                  )}
                </Typography>
              </div>
            )}
            <div
              className={clsx("flex gap-6 items-center md:flex-row flex-col", {
                "justify-between w-full -ml-6": asForm,
              })}
            >
              {filterCategories.map((category) => (
                <IntelligenceFilter
                  key={category.filterName}
                  displayName={category.name}
                  filterName={category.filterName}
                  options={category.options}
                />
              ))}

              <div className="ml-auto w-full sm:w-[unset]">
                <SearchBox
                  className="min-w-[250px]"
                  input={searchFilter || ""}
                  setInput={setSearchFilter}
                />
              </div>
            </div>
          </Container>
        )}

      <Container
        maxWidth="xl"
        className={clsx(
          "flex flex-col mt-6 md:mt-8 gap-6 lg:gap-8 items-center",
          {
            "p-1": asForm,
            "min-h-[1000px]":
              !fitsDesktop && data && data?.intelligenceDashboards.length > 0,
          }
        )}
      >
        {!data &&
          (error ? (
            <ErrorMessage
              title="Oops!"
              description="Failed to load intelligence dashboards. Please try again."
            />
          ) : (
            <Loading className="my-10" />
          ))}

        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-8">
          {dashboards?.map((dashboard) => (
            <IntelligenceDashboardCard
              key={dashboard.id}
              dashboard={dashboard}
              selectable={asForm}
            />
          ))}
        </div>
        {filteredDashboards &&
          filteredDashboards?.length > DASHBOARDS_PER_PAGE && (
            <Pagination
              count={totalPages}
              page={currentPage + 1}
              onChange={(__, value) => setPage(value - 1)}
              renderItem={(item) => (
                <PaginationItem
                  {...item}
                  onMouseOver={() => {
                    const pageDashboards = dashboardsFromPage(
                      filteredDashboards,
                      item.page!
                    );
                    const allVariables = getAllQueries(pageDashboards);
                    allVariables.map((query) => client.query(query));
                  }}
                />
              )}
            />
          )}
      </Container>
    </div>
  );
}

function getQuery(id: number, dashboardType: IntelligenceDashboardType) {
  const today = new Date();
  const todayIso = formatIsoDate(today);
  const commonVariables = {
    id,
    startDate: todayIso,
    endDate: todayIso,
  };

  if (dashboardType === IntelligenceDashboardType.Count) {
    return {
      query: IntelligenceDashboardContentDocument,
      variables: {
        ...commonVariables,
        bucketSizeSeconds: 3600,
        usePaths: true,
      },
    };
  }
  if (dashboardType === IntelligenceDashboardType.Idle) {
    return {
      query: IntelligenceDashboardContentDocument,
      variables: { ...commonVariables, bucketSizeSeconds: 3600 },
    };
  }
  return {
    query: IntelligencePresenceDashboardContentDocument,
    variables: { ...commonVariables, bucketSizeSeconds: 86400 },
  };
}

function dashboardsFromPage(allDashboards: QueriedDashboards, page: number) {
  const totalDashboards = allDashboards.length;
  const start = (page - 1) * DASHBOARDS_PER_PAGE;
  const end = Math.min(start + DASHBOARDS_PER_PAGE, totalDashboards + 1);
  return allDashboards.slice(start, end);
}

function getAllQueries(dashboards: QueriedDashboards) {
  return dashboards
    .filter(({ type }) =>
      [
        IntelligenceDashboardType.Count,
        IntelligenceDashboardType.Idle,
        IntelligenceDashboardType.Presence,
      ].includes(type)
    )
    .map((dashboard) => getQuery(dashboard.id, dashboard.type));
}

gql`
  query page_intelligence {
    intelligenceDashboards {
      id
      name
      thresholdSeconds
      daysOfWeek
      startTime
      endTime
      type
      entityCount
      objectTypes
      subtype
      cameras {
        id
        name
        location {
          id
          name
          timezone
        }
      }
    }
  }
`;
