import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Popover from "@mui/material/Popover";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { useState } from "react";

export const IntegrationNestedChild = ({
  row,
  path,
}: {
  row: any;
  path: string;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#f4f4f4",
      color: "#353d48b3",
    },
  }));

  return (
    <>
      <button className="bg-transparent" onClick={handleClick}>
        <MoreHorizIcon className="text-primary" />
      </button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="px-2 pb-2">
          <p className="capitalize font-bold py-4">{path}</p>
          {Array.isArray(row) ? (
            typeof row[0] === "string" ? (
              <ul>
                {row.map((rowValue, rowIdx) => (
                  <li key={`list-${rowIdx}-${rowValue}`}>{rowValue}</li>
                ))}
              </ul>
            ) : (
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {Object.entries(row[0]).map(([key]) => (
                        <StyledTableCell>{key}</StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.map((item, index) => (
                      <TableRow
                        key={`path-row-${index}`}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        {Object.entries(item).map(([_, value]) => (
                          <TableCell component="th" scope="row">
                            {value !== undefined ? renderCellValue(value) : "-"}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )
          ) : (
            <ul>
              {Object.entries(row).map(([key, value]) => (
                <li>
                  <strong className="capitalize">{key}</strong>:{" "}
                  {value as string}
                </li>
              ))}
            </ul>
          )}
        </div>
      </Popover>
    </>
  );
};

export function renderCellValue(value: any) {
  if (typeof value === "boolean") {
    return value ? "true" : "false";
  } else if (typeof value === "string" || typeof value === "number") {
    return value;
  }
}
