import { ArrowDropDown } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  MenuItem,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { Field, useField } from "formik";
import { Select, TextField } from "formik-mui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { lowerCase } from "lodash";
import { useUpdateEffect } from "react-use";

import {
  aiAlertTypes,
  alertDetectionCountOperator,
  alertTypesConfig,
  useAlertThresholdOptions,
} from "@/pages/Alerts/constants";

import { DurationSelectFormik } from "@/components/DurationSelect/DurationSelect";
import { ErrorMessage } from "@/components/ErrorMessage";
import HelpPopover from "@/components/HelpPopover";
import { Loading } from "@/components/Loading";

import {
  AlertDetectionCountOperator,
  AlertFeature,
  AlertType,
  useEligibleAlertFeaturesQuery,
  useGetAlertsQuery,
} from "@/generated-models";

import { AlertFormBlock } from "./AlertFormBlock";
import { required } from "./AlertFormBlocks";
import { FormikConfidenceSlider } from "./ConfidenceSlider";
import { alertFormBlockStyles } from "./alertStyles";
import { useFilterEnabledAlerts } from "./utils";

export function AlertNameType({
  initialName,
  disableType,
}: {
  initialName?: string;
  disableType?: boolean;
}) {
  const [{ value: type }] = useField<AlertType>("type");
  const [, , { setValue }] = useField("thresholdSeconds");

  const {
    data: existingAlertsData,
    error: existingAlertsError,
  } = useGetAlertsQuery();
  const {
    data: eligibleAlertFeaturesData,
    error: eligibleAlertFeaturesError,
  } = useEligibleAlertFeaturesQuery();

  const { alertCamOneMin } = useFlags();
  const filterEnabledAlerts = useFilterEnabledAlerts();
  const alertThresholdOptions = useAlertThresholdOptions();

  // Update default threshold if the alert type changes.
  useUpdateEffect(() => {
    const newOptions = alertThresholdOptions[type];

    if (newOptions) {
      setValue(
        newOptions.options[
          type === AlertType.CameraOffline && alertCamOneMin ? 1 : 0
        ]
      );
    }
  }, [type]);

  if (existingAlertsError || eligibleAlertFeaturesError) {
    return (
      <ErrorMessage
        title="Sorry about that"
        description="Something went wrong here, please try again later."
      />
    );
  }

  if (!existingAlertsData || !eligibleAlertFeaturesData) {
    return <Loading />;
  }

  const activeType = eligibleAlertFeaturesData.eligibleAlertFeatures.find(
    (alertType) => alertType.type === type
  );

  const alertNames = existingAlertsData
    ? existingAlertsData.alerts
        .filter((alert) => alert.name !== initialName)
        .map((alert) => alert.name.toLowerCase())
    : [];

  const alertOptions = eligibleAlertFeaturesData.eligibleAlertFeatures
    .filter(({ type }) => filterEnabledAlerts(type))
    .sort((a, b) =>
      alertTypesConfig[a.type].typeName.localeCompare(
        alertTypesConfig[b.type].typeName
      )
    )
    .map(({ type }) => (
      <MenuItem key={type} value={type}>
        {alertTypesConfig[type].typeName}
      </MenuItem>
    ));

  return (
    <AlertFormBlock>
      <FormControl className={alertFormBlockStyles.formItem}>
        <label className="text-xs text-text/70" htmlFor="name">
          Alert Name
        </label>
        <Field
          component={TextField}
          name="name"
          validate={(input: string) => {
            if (!input) return "Please provide a name";
            if (alertNames.includes(input.toLowerCase()))
              return "Name is already used";
          }}
          variant="outlined"
          size="small"
        />
      </FormControl>

      <FormControl className={alertFormBlockStyles.formItem}>
        <label className="text-xs text-text/70" htmlFor="type">
          Alert Type
        </label>
        <Field
          disabled={disableType}
          component={Select}
          name="type"
          labelId="alert-type-select-label"
          validate={required("Please provide a type")}
          formControl={{ variant: "standard" }}
          variant="outlined"
          size="small"
          onChange={(event: any) => {
            // Update default threshold if the alert type changes.
            const newType = event.target.value as AlertType;
            const newOptions = alertThresholdOptions[newType];

            if (newOptions) {
              setValue(newOptions.options[0]);
            }
          }}
        >
          {alertOptions}
        </Field>
      </FormControl>

      {aiAlertTypes.has(type) && <AiConfidenceSlider />}

      {activeType?.features.includes(AlertFeature.Counting) && (
        <AlertCountField />
      )}

      {activeType?.features.includes(AlertFeature.Threshold) && (
        <AlertThresholdField />
      )}
    </AlertFormBlock>
  );
}

function AiConfidenceSlider() {
  return (
    <div>
      <Accordion
        elevation={0}
        disableGutters
        classes={{
          expanded: "min-h-auto",
          root: "px-0",
        }}
      >
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          classes={{
            root: "justify-start px-0",
            content: "flex-grow-0 text-primary font-medium",
            expandIconWrapper: "text-primary",
          }}
        >
          Advanced AI Confidence Settings
        </AccordionSummary>
        <AccordionDetails className="flex flex-wrap sm:flex-nowrap justify-center gap-4 p-3 bg-[#FBFBFB] border border-[#EAEAEA] rounded-md max-w-[740px]">
          <div className="max-w-[240px] p-2 self-center text-center sm:text-left">
            <Typography variant="h4">AI Confidence Level</Typography>
            <Typography variant="body2" className="mt-1">
              This slider allows you to adjust the level of certainty of AI
              detections.
            </Typography>
          </div>
          <div className="sm:min-w-[500px] border bg-white rounded-md">
            <Field component={FormikConfidenceSlider} name="confidence" />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

function AlertCountField() {
  const [{ value: type }] = useField<AlertType>("type");
  const [{ value: operator }] = useField<AlertDetectionCountOperator>(
    "operator"
  );
  return (
    <div className="mt-5">
      <Typography variant="h4" className="text-base">
        Number of {alertTypesConfig[type].typeName}
      </Typography>
      <Typography variant="body2" className="mt-1">
        {`How many ${lowerCase(
          alertTypesConfig[type].typeName
        )} should be present before we generate an alert`}
      </Typography>

      <FormControl className="flex-row space-x-6 mt-1">
        <Field
          component={Select}
          name="operator"
          validate={required("Please provide an operator")}
          className="min-w-[220px]"
          formControl={{ variant: "standard" }}
        >
          {Object.entries(alertDetectionCountOperator).map(([key, name]) => (
            <MenuItem key={key} value={key}>
              {name}
            </MenuItem>
          ))}
        </Field>

        {operator !== AlertDetectionCountOperator.Any && (
          <Field
            component={TextField}
            name="detectionCount"
            label={`Number of ${alertTypesConfig[type].typeName}`}
            validate={(input: string) => {
              const val = Number(input);
              if (!input || isNaN(val) || !Number.isInteger(val)) {
                return "Please input a valid number";
              }

              if (val <= 0) {
                return "Please specify a number greater than 0";
              }

              if (
                operator === AlertDetectionCountOperator.LessThan &&
                val <= 1
              ) {
                return "Please specify a number greater than 1";
              }
            }}
          />
        )}
      </FormControl>
      {operator !== AlertDetectionCountOperator.Any && (
        <div className="flex gap-x-1 items-center mt-2">
          <p className="text-sm">Only available for the Pro license</p>
          <HelpPopover title="Pro License Feature">
            <p>{`All licenses can trigger ${alertTypesConfig[
              type
            ].typeName.toLowerCase()} detection alerts on any number of ${alertTypesConfig[
              type
            ].typeName.toLowerCase()}. To unlock alerts for other detection thresholds ("equal to", "more than" or "fewer than"), a Pro license is required.`}</p>
          </HelpPopover>
        </div>
      )}
    </div>
  );
}

function AlertThresholdField() {
  const [{ value: type }] = useField<AlertType>("type");
  const alertThresholdOptions = useAlertThresholdOptions();

  const copy = alertTypesConfig[type].threshold ?? {
    title: "Event Trigger Threshold",
    description:
      "How long should events be triggered and active before we generate an alert.",
  };

  const thresholdOptions = alertThresholdOptions[type];
  if (!thresholdOptions) return null;

  return (
    <div className="mt-5">
      <Typography variant="h4" className="text-base">
        {copy.title}
      </Typography>
      <div>{copy.description}</div>
      <Field
        name="thresholdSeconds"
        component={DurationSelectFormik}
        options={thresholdOptions.options}
        allowCustom={thresholdOptions.custom}
        variant="underlined"
        classes={{
          button: clsx(
            alertFormBlockStyles.formItem,
            "justify-between pr-1 text-base pb-0.5"
          ),
          popover: alertFormBlockStyles.formItem,
        }}
      />
    </div>
  );
}
