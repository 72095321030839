import { Button, CircularProgress } from "@mui/material";
import { useFormContext } from "react-hook-form";

import { QueryParamLink } from "@/components/shared/QueryParamLink";

export function UserManagementFormButtons({
  confirmText,
}: {
  confirmText?: string;
}) {
  const {
    formState: { isSubmitting, errors, isDirty },
  } = useFormContext();
  // For some reason isValid is sometimes false, even after changing memberIds
  const customIsValid = Object.keys(errors).length === 0 && isDirty;
  return (
    <div className="flex gap-2 justify-end sticky -bottom-2 py-2 bg-blue-light border-t border-blue-medium -mx-2 -mt-1.5 px-2">
      <Button
        variant="outlined"
        color="primary"
        component={QueryParamLink}
        to="./.."
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        color="primary"
        type="submit"
        disabled={!customIsValid || isSubmitting}
      >
        {confirmText ?? "Save"}
        {isSubmitting && (
          <CircularProgress color="inherit" size={16} className=" ml-2" />
        )}
      </Button>
    </div>
  );
}
