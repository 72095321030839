import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CalendarIcon from "@mui/icons-material/Event";
import LocationIcon from "@mui/icons-material/LocationOn";
import {
  Backdrop,
  ClickAwayListener,
  MenuItem,
  Modal,
  Popper,
  Select,
  Slide,
} from "@mui/material";
import clsx from "clsx";
import { startOfMinute, subDays } from "date-fns/fp";
import { sortBy } from "lodash/fp";
import { useMemo, useRef, useState } from "react";

import { CameraIcon } from "@/icons/Icons";
import { ReactComponent as GroupIcon } from "@/icons/icon_group.svg";
import { ReactComponent as LocationGroupIcon } from "@/icons/icon_group_location.svg";

import { useBreakpoints } from "@/util/useBreakpoints";

import { CamDrawer } from "@/components/CamDrawer/CamDrawerBase";
import { CamWrapperProps } from "@/components/CamDrawer/CamDrawerList";
import {
  DateTimeRangePopper,
  MobileDateTimeRangePopper,
} from "@/components/DateTimePicker/DateTimeRangePicker";
import {
  useActiveCamIds,
  useActiveGroupAndCams,
  useActiveGroupId,
  useSetActiveCamIds,
} from "@/components/View/sharedViewHooks";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  CamDrawerBaseQuery,
  useCamDrawerBaseQuery,
  useGroupsQuery,
} from "@/generated-models";

import { useRangeParam, useRangeParamWithoutDefaults } from "../../searchHooks";
import {
  getMenuItemProps,
  getPrimarySelectStyles,
  getSelectProps,
} from "./AttributeFilters";

export function RangeInput() {
  const { fitsDesktop } = useBreakpoints();
  const { setRangeParam } = useRangeParam();
  const { rangeStart, rangeEnd } = useRangeParamWithoutDefaults();
  const mainContainerRef = useRef<HTMLButtonElement>(null);
  const isActive = !!rangeStart || !!rangeEnd;

  const [popperOpen, setPopperOpen] = useState(false);
  const close = () => setPopperOpen(false);

  const nowDate = startOfMinute(new Date());
  const timeRangePopperProps = {
    startValue: rangeStart ?? subDays(1, nowDate),
    endValue: rangeEnd ?? nowDate,
    onChange: (start: Date, end: Date) => {
      close();
      setRangeParam({ start, end });
    },
    close,
    reset: () => setRangeParam(null),
    minDate: new Date(2020, 1, 1),
    maxDate: nowDate,
  };

  return (
    <ClickAwayListener onClickAway={close}>
      <div className="w-full">
        <button
          ref={mainContainerRef}
          className={clsx(
            "flex items-center h-10 w-full pl-2 rounded-md text-sm text-text border",
            isActive
              ? "bg-blue-medium border-[#FEFFFF] font-bold shadow-[0_0_4px_#007CE4]"
              : "bg-[#E9F5FF] border-[#E9F5FF]"
          )}
          onClick={() => setPopperOpen((open) => !open)}
        >
          <CalendarIcon className="mr-1" />
          <span className="truncate">
            {isActive ? "Date & Time Selected" : "All Dates & Times"}
          </span>
          <ArrowDropDownIcon
            className={clsx("ml-auto", { "rotate-180": popperOpen })}
          />
        </button>
        {fitsDesktop ? (
          <Popper
            open={popperOpen}
            anchorEl={mainContainerRef.current}
            className="z-30"
          >
            <DateTimeRangePopper {...timeRangePopperProps} />
          </Popper>
        ) : (
          <Modal open={popperOpen} onClose={close}>
            <Slide direction="up" in={popperOpen} mountOnEnter unmountOnExit>
              <div className="absolute bottom-0 w-full">
                <MobileDateTimeRangePopper {...timeRangePopperProps} />
              </div>
            </Slide>
          </Modal>
        )}
      </div>
    </ClickAwayListener>
  );
}

export function GroupSelect() {
  const { data } = useGroupsQuery(refetchOnMountPolicy);
  const groups = data?.groups;
  const [activeGroupId, setActiveGroup] = useActiveGroupId();
  const activeGroup =
    activeGroupId && groups
      ? groups.find((g) => g.id === activeGroupId)
      : undefined;

  const isActive = !!activeGroup;
  const label = "All Groups & Locations";

  return (
    <Select
      fullWidth
      {...getSelectProps({
        value: activeGroup ? String(activeGroup.id) : "",
        setValue: (value) => setActiveGroup(value ? Number(value) : undefined),
        label,
        activeLabel: "Group/Location Selected",
        Icon: LocationIcon,
        sx: (theme) => getPrimarySelectStyles({ isActive, theme }),
      })}
    >
      <MenuItem {...getMenuItemProps({ value: "", selected: !isActive })}>
        {label}
      </MenuItem>
      {sortBy([(g) => g.name.toLowerCase()], groups).map((g) => (
        <MenuItem
          {...getMenuItemProps({
            value: String(g.id),
            selected: g.id === activeGroupId,
          })}
        >
          <div className="flex items-center">
            {g.isLocationGroup ? <LocationGroupIcon /> : <GroupIcon />}
            <div className="ml-3 truncate">{g.name}</div>
          </div>
        </MenuItem>
      ))}
    </Select>
  );
}

export function CamerasSelect() {
  const { data: groupsData } = useGroupsQuery(refetchOnMountPolicy);
  const { data: camerasData } = useCamDrawerBaseQuery(refetchOnMountPolicy);
  const cameras = camerasData?.cameras;
  const { groupCams } = useActiveGroupAndCams(groupsData?.groups, cameras);
  const activeCamIds = useActiveCamIds();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const isActive = activeCamIds.length > 0;

  return (
    <>
      <button
        className={clsx(
          "flex items-center h-10 w-full pl-2 rounded-md text-sm text-text border",
          isActive
            ? "bg-blue-medium border-[#FEFFFF] font-bold shadow-[0_0_4px_#007CE4]"
            : "bg-[#E9F5FF] border-[#E9F5FF]"
        )}
        onClick={() => setDrawerOpen((open) => !open)}
      >
        <CameraIcon className="mr-1" />
        <span className="truncate">
          {isActive
            ? `${activeCamIds.length} Camera${
                activeCamIds.length > 1 ? "s" : ""
              } Selected`
            : "All Cameras"}
        </span>
        <ArrowDropDownIcon
          className={clsx("ml-auto", { "rotate-180": drawerOpen })}
        />
      </button>
      {drawerOpen && (
        <CameraDrawer
          activeCamIds={activeCamIds}
          cameras={groupCams ?? cameras ?? []}
          open={drawerOpen}
          onOpen={() => setDrawerOpen(true)}
          onClose={() => setDrawerOpen(false)}
        />
      )}
    </>
  );
}

function CameraDrawer({
  activeCamIds,
  cameras,
  open,
  onOpen,
  onClose,
}: {
  activeCamIds: number[];
  cameras: CamDrawerBaseQuery["cameras"];
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
}) {
  const setActiveCamIds = useSetActiveCamIds();
  const statefulCameras = useMemo(() => {
    return cameras.map((c) => {
      const selected = activeCamIds.includes(c.id);
      return { ...c, selected };
    });
  }, [cameras, activeCamIds]);

  return (
    <>
      <Backdrop open={open} onClick={onClose} className="z-1" />
      <CamDrawer open={open} onOpen={onOpen} onClose={onClose}>
        <CamDrawer.Filters>
          <CamDrawer.SearchBox placeholder="Search Cameras" fullWidth />
          <CamDrawer.ListControls
            cameras={statefulCameras}
            selectIds={(ids) =>
              setActiveCamIds((cur) => [...(cur ?? []), ...ids])
            }
            deselectIds={(ids) =>
              setActiveCamIds((cur) => cur?.filter((id) => !ids.includes(id)))
            }
          />
          <CamDrawer.SelectedOnlyFilter />
        </CamDrawer.Filters>
        <CamDrawer.List cameras={statefulCameras} CamWrapper={CamWrapper} />
      </CamDrawer>
    </>
  );
}

function CamWrapper({ cam, children }: CamWrapperProps) {
  const setValue = useSetActiveCamIds();

  return (
    <label>
      <input
        type="checkbox"
        data-testid={`cam-${cam.id}`}
        checked={cam.selected}
        onChange={(event) => {
          if (event.target.checked) {
            setValue((cur) => [...(cur ?? []), cam.id]);
          } else {
            setValue((cur) => cur?.filter((id) => id !== cam.id));
          }
        }}
        className="absolute opacity-0 w-0 h-0"
      />
      {children}
    </label>
  );
}
