import CircleIcon from "@mui/icons-material/Circle";
import { IconButton, Typography } from "@mui/material";
import clsx from "clsx";

import { backgrounds } from "./background";

interface AssistantPageFooterProps {
  selectedBackground: number;
  onBackgroundChange: (newBackground: number) => void;
  className?: string;
}

export function AssistantPageFooter({
  selectedBackground,
  onBackgroundChange,
  className,
}: AssistantPageFooterProps) {
  return (
    <div
      className={clsx(
        "w-full flex justify-center items-center gap-2",
        className
      )}
    >
      <Typography className="text-[#A6C8E1] text-sm leading-[27px] opacity-40">
        Change Background
      </Typography>
      <div className="-mt-0.5 flex gap-1 items-center">
        {backgrounds.map((_, idx) => (
          <IconButton
            onClick={() => {
              onBackgroundChange(idx);
            }}
            className="p-0 "
            size="small"
            key={idx}
          >
            <CircleIcon
              className={clsx("w-[10px] h-[10px] text-[#D9D9D9]", {
                "opacity-20": idx !== selectedBackground,
              })}
            />
          </IconButton>
        ))}
      </div>
    </div>
  );
}
