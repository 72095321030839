import { useSetAtom } from "jotai";
import { useState } from "react";

import { InterestListType } from "@/generated-models";

import { InterestListDialog } from "./InterestListDialog";
import { InterestListFormMode } from "./constants";
import {
  currentListItemAtom,
  currentNoteNameAtom,
  useResetInterestListState,
} from "./hooks";

interface EditInterestListButtonProps {
  className?: string;
  noteName?: string;
  listId?: number;
  type: InterestListType;
}

export function EditInterestListButton({
  className,
  noteName,
  listId,
  type,
}: EditInterestListButtonProps) {
  const [open, setOpen] = useState(false);

  const setCurrentListItem = useSetAtom(currentListItemAtom);
  const setCurrentNoteName = useSetAtom(currentNoteNameAtom);

  const resetInterestListState = useResetInterestListState();

  return (
    <div className={className}>
      <button
        type="button"
        className="text-primary bg-transparent text-sm leading-[16.41px]"
        onClick={() => {
          if (noteName) {
            setCurrentNoteName(noteName);
          }
          setCurrentListItem(listId || null);
          setOpen(true);
        }}
      >
        Edit
      </button>
      <InterestListDialog
        open={open}
        defaultMode={InterestListFormMode.edit}
        primaryActionLabel={"Save"}
        type={type}
        onClose={() => {
          setOpen(false);
          resetInterestListState();
        }}
        onApplyLists={() => {
          setOpen(false);
          resetInterestListState();
        }}
      />
    </div>
  );
}
