import clsx from "clsx";
import { useNavigate } from "react-router-dom";

import { useHasFilters } from "../../hooks";
import { IntegrationEventDatagrid } from "./IntegrationEventDatagrid";
import { IntegrationEventTabSummaryBar } from "./IntegrationEventTabSummaryBar";

export function IntegrationEventContent({
  condensed,
}: {
  condensed?: boolean;
}) {
  const navigate = useNavigate();
  const hasFilters = useHasFilters();

  return (
    <div
      className={clsx("flex flex-col", {
        "gap-6": !hasFilters,
      })}
    >
      <IntegrationEventTabSummaryBar condensed={condensed} />
      <IntegrationEventDatagrid
        onRowClick={(_, options) => {
          navigate({
            ...(condensed ? {} : options),
            search: `${options.search}&tab=events`,
          });
        }}
      />
    </div>
  );
}
