import { Button, Divider, Typography } from "@mui/material";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";

import {
  DeviceStreamSettings,
  StreamSpecItem,
  useIsInSpecStream,
} from "@/util/validation/stream";

import { MaintainCamera } from "@/pages/Maintain/hooks";
import { useMaintainRoutes } from "@/pages/Maintain/utils";

import { Statistic } from "@/components/Statistic";

import {
  Camera,
  CameraStatus,
  DefaultCameraSettings,
} from "@/generated-models";
import { usePermissions } from "@/hooks/usePermissions";

function NavigateButton({ label, id }: { label?: string; id?: number }) {
  const navigate = useNavigate();
  const maintainRoutes = useMaintainRoutes();
  return (
    <div className="flex justify-end">
      <Button
        size="small"
        variant="outlined"
        color="primary"
        classes={{ outlined: "px-6" }}
        onClick={() => {
          navigate(`${maintainRoutes.MAINTAIN_BASE}/cameras/${id}?configure=1`);
        }}
      >
        {label || "Configure Camera"}
      </Button>
    </div>
  );
}

function SettingSpecBreakdownItem({ item }: { item: StreamSpecItem }) {
  const { label, value, recommended } = item;
  return (
    <div className="flex flex-col gap-y-1">
      <div className="flex items-center gap-x-2">
        <div className="flex min-w-[100px] items-center justify-center">
          <Statistic error>{value || "Unknown"}</Statistic>
        </div>
        <div className="opacity-40">{`Current ${label}`}</div>
      </div>
      <div className="flex items-center gap-x-2">
        <div className="flex min-w-[100px] items-center justify-center">
          <Statistic flag>{recommended}</Statistic>
        </div>
        <div className="mt-[6px]">
          <span className="font-bold">Recommended</span> {label}
        </div>
      </div>
    </div>
  );
}

interface CameraSettingsRecommenderProps {
  camera: Pick<Camera, "id" | "status"> & {
    stream: DeviceStreamSettings;
    appliance: { defaultCameraSettings: DefaultCameraSettings };
  };
}

export function CameraSettingsRecommender({
  camera,
}: CameraSettingsRecommenderProps) {
  const { stream, appliance, id, status } = camera;
  const { defaultCameraSettings } = appliance;
  const isOffline = status === CameraStatus.Offline;
  const hasPermission = usePermissions();

  const { errors } = useIsInSpecStream(
    stream,
    defaultCameraSettings,
    camera as MaintainCamera
  );

  if (errors.length === 0 && !isOffline) return null;

  return (
    <>
      <div className="flex flex-col gap-y-4 my-3">
        {isOffline && (
          <>
            <Typography variant="body1" className="text-sm">
              Camera unreachable, check connectivity or reboot device.
            </Typography>
            {errors.length > 0 && <Divider />}
          </>
        )}
        {errors.map((e, idx) => (
          <Fragment key={e.label}>
            <Typography variant="body1" className="text-sm">
              Please adjust the camera{" "}
              <span className="font-bold">{e.label?.toLowerCase()}</span> for
              better results
            </Typography>
            <SettingSpecBreakdownItem item={e} />
            {idx + 1 < errors.length && <Divider />}
          </Fragment>
        ))}
      </div>
      {hasPermission("devices_manage") && <NavigateButton id={id} />}
    </>
  );
}
