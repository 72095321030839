import { IconButton, Paper, Popper, Typography } from "@mui/material";
import clsx from "clsx";
import { Fragment, useCallback, useRef, useState } from "react";
import { makeStyles } from "tss-react/mui";

import { formatVideoTime } from "@/util/date";
import { insertBreaklines } from "@/util/insertBreaklines";
import { useBreakpoints } from "@/util/useBreakpoints";

import { AnnotationLabel } from "@/pages/Cases/AnnotationLabel";
import { getColorForLabel } from "@/pages/Cases/CaseComments";
import {
  applyCommentPartMapper,
  insertMentions,
} from "@/pages/Cases/commentDecorations";

import { CaseContentType } from "@/components/Player/AddToCaseDialog";
import { QueryParamLink } from "@/components/shared/QueryParamLink";

import { ClipType } from "./CaseMedia";

type Annotation = ClipType["annotations"][number];

const useAnnotationStyles = makeStyles()((theme) => ({
  annotation: {
    border: "1px solid #DCDCDC",
    borderRadius: 2,
    width: 36,
    height: 36,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: 0,
    marginBottom: 2,
    padding: 0,
  },
  popper: {
    backgroundColor: "rgba(0, 0, 0, .8)",
    border: "1px solid #676767",
    color: "white",
    maxWidth: 400,
    padding: theme.spacing(1),
  },
}));

function getMentions(annotation: Annotation) {
  if (annotation.__typename === "CaseClipAnnotation") {
    return annotation.mentions;
  }
  return undefined;
}

export function AnnotationButton({
  itemId,
  type,
  annotation: { label, content, time },
  annotation,
  classes: classOverrides,
}: {
  itemId: number;
  type: CaseContentType;
  annotation: Annotation;
  classes?: {
    annotation?: string;
  };
}) {
  const { fitsTablet } = useBreakpoints();
  const { classes } = useAnnotationStyles();
  const annotationRef = useRef<SVGElement>(null);
  const [popperOpen, setPopperOpen] = useState(false);
  const openPopper = useCallback(() => setPopperOpen(true), []);
  const closePopper = useCallback(() => setPopperOpen(false), []);

  return (
    <IconButton
      component={QueryParamLink}
      params={
        type === CaseContentType.clip
          ? {
              clip: itemId,
              startTime: Math.floor(time),
            }
          : { screenshot: itemId }
      }
      removalParams={["screenshot", "clip", "startTime"]}
      state={{
        time: Date.now(),
      }}
      className={clsx(classes.annotation, classOverrides?.annotation)}
      onMouseEnter={openPopper}
      onFocus={openPopper}
      onMouseLeave={closePopper}
      onBlur={closePopper}
      size="large"
    >
      <AnnotationLabel
        ref={annotationRef}
        variant="contained"
        color={
          // Starts at "yellow" for A, moves towards red for B, C ...etc
          getColorForLabel(label)
        }
        width={18}
      >
        {label}
      </AnnotationLabel>
      {fitsTablet && (
        <Popper
          anchorEl={annotationRef.current}
          open={popperOpen}
          placement="bottom"
        >
          <Paper className={classes.popper}>
            <Typography>
              {[content || ""]
                .flatMap(applyCommentPartMapper(insertBreaklines))
                .flatMap(
                  applyCommentPartMapper(
                    insertMentions(getMentions(annotation))
                  )
                )
                .map((content, i) => (
                  <Fragment key={i}>{content}</Fragment>
                ))}
            </Typography>
            {type === CaseContentType.clip && (
              <Typography variant="body2" style={{ color: "#ccc" }}>
                {formatVideoTime(Math.floor(time * 1000))}
              </Typography>
            )}
          </Paper>
        </Popper>
      )}
    </IconButton>
  );
}
