import FileIcon from "@mui/icons-material/FilePresent";
import LinkIcon from "@mui/icons-material/Link";
import {
  IconButton,
  Link as MaterialLink,
  Modal,
  Typography,
} from "@mui/material";
import { useCallback, useState } from "react";

import {
  isMobileApp,
  mobileFeatureFlags,
} from "@/components/Mobile/mobileEnvironment";
import { CloseIcon } from "@/components/Player/PlayerIcons";

import { CaseAttachmentType } from "@/generated-models";

import { AddAttachmentButton, Attachment } from "./AddAttachmentButton";

const imageMatcher = /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i;

export function CaseAttachments({
  caseId,
  attachments,
  manageFilesPermission,
}: {
  caseId: number;
  attachments: Attachment[];
  manageFilesPermission: boolean;
}) {
  const [focusedImage, setFocusedImage] = useState<Attachment | null>(null);
  const closeModal = useCallback(() => setFocusedImage(null), [
    setFocusedImage,
  ]);

  return (
    <>
      <Typography className="mt-4 font-bold">External Links & Files</Typography>
      {attachments.length > 0 && <div className="h-2" />}
      <div className="flex flex-col gap-1 w-full">
        {attachments.map((attachment) => (
          <div
            key={attachment.id}
            className="flex items-center border-b border-solid border-[#C4DFF7] pb-1"
          >
            {attachment.type === CaseAttachmentType.Link ? (
              <LinkIcon color="primary" fontSize="small" />
            ) : (
              <FileIcon color="primary" fontSize="small" />
            )}
            {isMobileApp && !mobileFeatureFlags.externalLinks ? (
              attachment.url && imageMatcher.test(attachment.label) ? (
                <MaterialLink
                  underline="hover"
                  className="ml-1 font-medium truncate"
                  target="_blank"
                  rel="noopener noreferrer"
                  component="button"
                  onClick={() => setFocusedImage(attachment)}
                >
                  {attachment.label}
                </MaterialLink>
              ) : (
                <span className="ml-1 font-medium truncate">
                  {attachment.label}
                </span>
              )
            ) : (
              <MaterialLink
                underline="hover"
                className="ml-1 font-medium truncate"
                href={attachment.url ?? undefined}
                target="_blank"
                rel="noopener noreferrer"
              >
                {attachment.label}
              </MaterialLink>
            )}
          </div>
        ))}
      </div>
      <Modal
        className="flex-center"
        disablePortal={false}
        open={focusedImage?.url != null}
        onClose={closeModal}
        BackdropProps={{
          className: "bg-black bg-opacity-90 h-full",
        }}
      >
        <>
          <IconButton
            onClick={closeModal}
            size="large"
            className="text-white absolute top-0 right-0 z-10"
          >
            <CloseIcon />
          </IconButton>
          {focusedImage?.url && (
            <img src={focusedImage.url} alt={focusedImage.label} />
          )}
        </>
      </Modal>
      {manageFilesPermission ? (
        <AddAttachmentButton caseId={caseId} attachments={attachments} />
      ) : (
        attachments.length === 0 && (
          <Typography className="opacity-50 italic" variant="body2">
            none
          </Typography>
        )
      )}
    </>
  );
}
