import { useSetAtom } from "jotai";
import { useEffect } from "react";

import { ErrorMessage } from "@/components/ErrorMessage";

import { keepAliveAtom } from "./useKeepAlive";

export const KioskError = ({ description }: { description: string }) => {
  const setSendKeepAlive = useSetAtom(keepAliveAtom);
  useEffect(() => setSendKeepAlive(false), [setSendKeepAlive]);

  return <ErrorMessage title="Oops.." description={description} />;
};
