import { useMemo } from "react";

import { useLocalStorage } from "@/util/useLocalStorage";
import { getCameraSpecs } from "@/util/validation/camera";

import { MaintainCamera } from "@/pages/Maintain/hooks";

import {
  DefaultCameraSettings,
  DeviceStream,
  Maybe,
  StreamMetadata,
  StreamSettings,
} from "@/generated-models";

const VALID_CODECS = ["h264", "h265"];

export const ENCODINGS: { [key: string]: string } = {
  h264: "H.264",
  h265: "H.265",
};

export interface StreamSpecItem {
  label: string;
  value: string;
  error?: boolean;
  recommended?: string | number;
}

export interface StreamSpecResult {
  specs: StreamSpecItem[];
  errors: StreamSpecItem[];
  errorLabels: string;
  errorFields: string;
  errorRecs: string;
}

// TODO bitrate rate control values.
// Given the metadata for a stream, return the stream spec validation result.
export function getStreamMetadataSpecs(
  stream: DeviceStreamSettings,
  recommendedCameraSettings?: DefaultCameraSettings
): StreamSpecItem[] {
  if (!stream?.metadata) return [];

  const { codec, height, width, fps } = getStreamSettings(stream);

  const isValidRes = !!width && !!height && width !== -1 && height !== -1;
  // Assuming the lower dimension is the 'height', to account for rotated screens.
  const computedRes = height > width ? width : height;

  const {
    fps: recFps = 0,
    resolution: recRes = 0,
    encoding: recEncoding = [],
    rateControl: recRateControl = [],
    bitrate: recBitrate = 0,
  } = recommendedCameraSettings || {};

  return [
    {
      label: "Resolution",
      value: isValidRes ? `${computedRes}p` : "",
      error: recommendedCameraSettings && computedRes > recRes,
      recommended: `${recRes}p`,
    },
    {
      label: "Framerate",
      value: `${fps} fps`,
      error: recommendedCameraSettings && fps > recFps,
      recommended: `${recFps} fps`,
    },
    {
      label: "Bitrate",
      value: "???",
      recommended: `< ${recBitrate / 1024} mbps`,
    },
    {
      label: "Encoding",
      value: ENCODINGS[codec] || codec,
      error:
        recommendedCameraSettings &&
        VALID_CODECS.includes(codec) &&
        !recEncoding.includes(codec),
      recommended: recEncoding.map((r) => ENCODINGS[r] || codec).join(", "),
    },
    {
      label: "Rate Control",
      value: "???",
      recommended: recRateControl.join(", "),
    },
  ];
}

// TODO account for enterprise vs business specs.
// TODO bitrate & rate control are mocked for now until we can retrieve from the backend.
// Given a stream, return validation data that indicates if the stream
// is in spec.
export const useIsInSpecStream = (
  stream: DeviceStreamSettings,
  recommendedCameraSettings?: DefaultCameraSettings,
  cam?: MaintainCamera
): StreamSpecResult => {
  return useMemo(() => {
    const streamSpecs = getStreamMetadataSpecs(
      stream,
      recommendedCameraSettings
    );
    let specs = [...streamSpecs];
    if (cam) {
      const camSpecs = getCameraSpecs(cam);
      specs = [...streamSpecs, ...camSpecs];
    }
    const errors = specs.filter((item) => item.error);
    const errorFields = errors
      .map((item) => item.value || "Unknown")
      .join(" & ");
    const errorRecs = errors.map((item) => item.recommended).join(", ");
    const errorLabels = errors
      .map((item) => item.label?.toLowerCase())
      .join(", ");

    return {
      specs,
      errors,
      errorLabels,
      errorFields,
      errorRecs,
    };
  }, [stream, recommendedCameraSettings, cam]);
};

// Determines if the specified stream has a valid codec.
// This is also takes into account any codec validation
// overrides that may have been specified.
export const useIsValidStream = (stream?: DeviceStream): string | undefined => {
  const [disableCodecValidation] = useLocalStorage(
    "disableCodecValidation",
    ""
  );
  const disableCodecValidationItems = useMemo(
    () => disableCodecValidation.split(","),
    [disableCodecValidation]
  );

  if (!stream) {
    return undefined;
  }

  const settings = getStreamSettings(stream);

  if (
    settings.isValid ||
    (settings.codec &&
      disableCodecValidation &&
      disableCodecValidationItems.includes(settings.codec))
  ) {
    return undefined;
  }

  return settings.codec && !VALID_CODECS.includes(settings.codec)
    ? `Encoding '${settings.codec}' is not supported. Required: ${
        disableCodecValidation
          ? disableCodecValidation
          : VALID_CODECS.join(", ")
      }`
    : "Path invalid";
};

export interface DeviceStreamSettings {
  settings?: Maybe<Pick<StreamSettings, "video">>;
  metadata?: Maybe<
    Pick<StreamMetadata, "codec" | "fps" | "height" | "width" | "isValid">
  >;
}

export function getStreamSettings(stream: DeviceStreamSettings) {
  const { metadata, settings } = stream;

  const codec = settings?.video?.codec ?? metadata?.codec ?? "";
  const fps = settings?.video?.fps ?? metadata?.fps ?? 0;
  const height = settings?.video?.height ?? metadata?.height ?? 0;
  const width = settings?.video?.width ?? metadata?.width ?? 0;
  const isValid = settings ? VALID_CODECS.includes(codec) : !!metadata?.isValid;

  return { isValid, codec, height, width, fps };
}
