import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Popper, Button, Typography } from "@mui/material";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { addMinutes, subDays } from "date-fns/fp";
import { useState } from "react";

import { useBreakpoints } from "@/util/useBreakpoints";

import { useSearchRangeParams } from "@/pages/Search/searchHooks";

import { DateTimeRangePopper } from "@/components/DateTimePicker/DateTimeRangePicker";

import { FilterFieldProps } from "../constant";

export const DatePicker = ({
  open,
  anchorEl,
  setAnchorEl,
  timezone,
  defaultStartRange,
  minDate,
  maxDate,
}: {
  open: boolean;
  anchorEl: null | HTMLButtonElement;
  setAnchorEl: (el: null | HTMLButtonElement) => void;
  defaultStartRange?: number;
  timezone?: string;
  minDate?: Date;
  maxDate?: Date;
}) => {
  const { rangeStart, rangeEnd, setRangeParam } = useSearchRangeParams(
    defaultStartRange
  );

  const tz = timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
  const startValue = utcToZonedTime(rangeStart, tz);
  const endValue = utcToZonedTime(rangeEnd, tz);

  const close = () => setAnchorEl(null);
  const id = open ? "datePicker" : undefined;

  const timeRangePopperProps = {
    startValue,
    endValue,
    onChange: (start: Date, end: Date) => {
      close();
      setRangeParam({
        start: zonedTimeToUtc(start, tz),
        end: zonedTimeToUtc(end, tz),
      });
    },
    close,
    reset: () => setRangeParam(null),
    minDate: minDate ?? utcToZonedTime(subDays(90, new Date()), tz),
    maxDate: maxDate ?? utcToZonedTime(addMinutes(60, new Date()), tz),
  };

  return (
    <Popper
      id={id}
      open={open}
      anchorEl={anchorEl}
      placement="left-start"
      className="z-[1401]"
      aria-labelledby="date-range-picker"
      aria-describedby="date-range-picker"
    >
      <DateTimeRangePopper {...timeRangePopperProps} />
    </Popper>
  );
};

export function FilterAccordionDateField<T>({
  placeholder,
  timezone,
  defaultStartRange,
  minDate,
  maxDate,
}: FilterFieldProps<T> & {
  defaultStartRange?: number;
  timezone?: string;
  minDate?: Date;
  maxDate?: Date;
}) {
  const { fitsDesktop } = useBreakpoints();
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? "datePicker" : undefined;

  return (
    <>
      <Button
        className="bg-[#D1EBFF] rounded-lg text-left items-start justify-between pl-4 pr-5 py-3 w-full"
        endIcon={<ArrowDropDownIcon className="text-[#353D48]" />}
        aria-describedby={id}
        onClick={handleClick}
      >
        <Typography className="text-base font-bold text-primary">
          {placeholder}
        </Typography>
      </Button>
      {fitsDesktop && (
        <DatePicker
          open={open}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          timezone={timezone}
          defaultStartRange={defaultStartRange}
          minDate={minDate}
          maxDate={maxDate}
        />
      )}
    </>
  );
}
