import semverGte from "semver/functions/gte";
import semverValid from "semver/functions/valid";

import { iosError, iosLog } from "@/util/iosMessages";

/**
 * legacy cookie to indicate that we're running inside of a native app wrapper
 * @deprecated use mobileEnvironment instead
 */
export function detectMobileWrapper() {
  return document.cookie.toLowerCase().includes("spotai.is.mobile.app=true");
}

// Example UA substring: SpotAndroid:1.2.3 or SpotIOS:1.2.3
const nativeAppEnvironmentParser = /Spot(Android|IOS):(\d+\.\d+\.\d+)/;
const userAgent = navigator.userAgent;
const [, platform, version] = nativeAppEnvironmentParser.exec(userAgent) ?? [];
const nativeAppModelParser = /model:([a-zA-Z0-9-,]+)/;
const [, model] = nativeAppModelParser.exec(userAgent) ?? [];
export const mobileEnvironment = platform
  ? {
      platform: platform as "Android" | "IOS",
      version,
      model,
    }
  : null;

function safeSemver(version: string, comparator = semverGte) {
  if (!mobileEnvironment?.version || !semverValid(version)) {
    return false;
  }
  return comparator(mobileEnvironment.version, version);
}
export const mobileFeatureFlags = {
  externalLinks: safeSemver("2.1.0"),
};

/**
 * Stand in until the native apps support setting the custom UA string.
 * @deprecated use mobileEnvironment instead
 */
export const inferredNativeAppPlatform = /iphone|ipod|ipad/i.test(userAgent)
  ? "IOS"
  : /android/i.test(userAgent)
  ? "Android"
  : null;

// Custom iOS Debugging. Can't get iOS debugging to show us console logs, so we're using this instead.
if (userAgent.includes("ios:debug")) {
  const originalLog = console.log;
  console.log = (...args: any[]) => {
    originalLog("ios debug:", ...args);
    iosLog(...args);
  };
  const originalError = console.error;
  console.error = (...args: any[]) => {
    originalError("ios debug:", ...args);
    iosError(...args);
  };
  console.log("iOS debug mode enabled");
}

export const isMobileApp = Boolean(detectMobileWrapper() || mobileEnvironment);
