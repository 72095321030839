import { IconButton } from "@mui/material";
import { useRef, useState } from "react";

import { PawIcon } from "@/icons/Paw";

import GeniusHealth from "@/components/Player/GeniusHealth/GeniusHealth";
import { PlayerTooltip } from "@/components/Player/PlayerTooltip";

interface HealthButtonProps {
  cameraId: number;
  disabled?: boolean;
}
export function HealthButton({ cameraId, disabled }: HealthButtonProps) {
  const [openDiagnostics, setOpenDiagnostics] = useState(false);
  const buttonRef = useRef<HTMLDivElement | null>(null);
  return (
    <PlayerTooltip title="">
      <div
        ref={buttonRef}
        className="md:bg-black rounded-md hover:md:bg-gray-900"
      >
        {/**
         * Wrapper div is required to ensure the Tooltip works even
         * when the button is disabled, as disabled buttons don't emit events
         **/}
        <IconButton
          disabled={disabled}
          className="flex-center"
          style={{ color: "white" }}
          onMouseEnter={() => setOpenDiagnostics(true)}
          onMouseLeave={() => setOpenDiagnostics(false)}
          size="large"
        >
          <PawIcon
            style={{
              width: 25,
              height: 25,
              color: "white",
              opacity: disabled ? 0.5 : undefined,
            }}
          />
        </IconButton>
        <GeniusHealth
          open={openDiagnostics}
          anchorEl={buttonRef.current}
          cameraId={cameraId}
          setOpen={(open) => setOpenDiagnostics(open)}
        />
      </div>
    </PlayerTooltip>
  );
}
