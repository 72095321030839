import mapboxgl from "mapbox-gl";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

import { AddCamera } from "@/components/MappingTool/AddCamera";
import { Layout } from "@/components/MappingTool/Mapping";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";

import { useAddLayoutCamerasMutation } from "@/generated-models";

export function AddCameraMarker({
  map,
  layout,
  setNewCam,
  setEditCam,
}: {
  map: mapboxgl.Map;
  layout: Layout;
  setNewCam: React.Dispatch<boolean>;
  setEditCam: React.Dispatch<number | null>;
}) {
  const { pushSnackbar } = useFeedback();
  const [addLayoutCameras] = useAddLayoutCamerasMutation();
  const [marker, setMarker] = useState<mapboxgl.Marker | null>(null);
  const [element, setElement] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!element) return;

    const newMarker = new mapboxgl.Marker({
      element,
      draggable: true,
    })
      .setLngLat(map.getCenter())
      .addTo(map);
    setMarker(newMarker);
    // eslint-disable-next-line
  }, [element]);

  useEffect(() => {
    // enable map drag pan when camera editing is finished
    return () => {
      map.dragPan.enable();
      map.scrollZoom.enable();
    };
    // eslint-disable-next-line
  }, []);

  return ReactDOM.createPortal(
    <div
      style={{ zIndex: 1 }}
      ref={(node) => {
        if (!map || !node) return;
        setElement(node);
        // disable map panning when editing camera
        map.dragPan.disable();
        map.scrollZoom.disable();
      }}
    >
      {marker && (
        <AddCamera
          locationId={layout.locationId}
          layoutId={layout.id}
          offset={{ top: 0, left: 0 }}
          mapMarker={marker}
          onDelete={() => {
            setNewCam(false);
          }}
          onAdd={async (args) => {
            if (!marker) return;
            const [x, y] = marker.getLngLat().toArray();
            const camPosition = { x, y };
            setNewCam(false);
            setEditCam(-1);
            const { errors } = await addLayoutCameras({
              variables: {
                input: [
                  {
                    layoutId: args.layoutId,
                    cameraId: args.cameraId,
                    angle: args.angle,
                    position: camPosition,
                  },
                ],
              },
              optimisticResponse: {
                __typename: "Mutation",
                addLayoutCameras: [
                  {
                    angle: args.angle,
                    cameraId: args.cameraId,
                    id: -1,
                    layoutId: args.layoutId,
                    position: {
                      ...camPosition,
                      __typename: "Point",
                    },
                    camera: args.camera,
                    __typename: "LayoutCamera",
                  },
                ],
              },
              update: (cache, { data }) => {
                if (!data) return;
                setEditCam(data?.addLayoutCameras[0].id);
                cache.modify({
                  id: `LocationLayout:${args.layoutId}`,
                  fields: {
                    cameras(existingCameras) {
                      return [
                        ...existingCameras,
                        ...data.addLayoutCameras.map((d) => ({
                          __ref: `LayoutCamera:${d.id}`,
                        })),
                      ];
                    },
                  },
                });
              },
            }).catch((e) => ({ errors: [e] }));
            if (errors?.length) {
              pushSnackbar(
                "Failed to Add Camera. Please try again",
                FeedbackType.Error
              );
            }
          }}
        />
      )}
    </div>,
    map.getCanvasContainer()
  );
}
