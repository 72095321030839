import clsx from "clsx";
import React, { useLayoutEffect, useRef, useState } from "react";

const textClass = "p-0 text-sm font-sans whitespace-pre";
const inputClass =
  "text-right bg-transparent rounded-none border-none w-full outline-none";

const basePlaceholder = "00h 00m 00s";
type CustomDuration = {
  hour: number;
  min: number;
  sec: number;
};
export function CustomDurationInput({
  onChange,
  initial,
}: {
  onChange: (value: CustomDuration) => void;
  initial?: { start: Date; end: Date };
}) {
  const [input, setInput] = useState("");
  const [width, setWidth] = useState(1);
  const measureRef = useRef<HTMLSpanElement>(null);
  const runAfterUpdate = useRunAfterUpdate();
  useLayoutEffect(() => {
    if (measureRef.current) {
      setWidth(measureRef.current.offsetWidth);
    }
  }, [input]);
  return (
    <label
      data-cy="custom-duration"
      className="inline-flex border-0 border-b border-red border-opacity-30 border-solid cursor-text"
    >
      <span
        ref={measureRef}
        className={clsx(
          textClass,
          "absolute top-full opacity-0 pointer-events-0"
        )}
      >
        {input || "s"}
      </span>
      <div className="shrink grow pointer-events-none opacity-50">
        <div className={clsx(textClass, inputClass, "text-inherit shrink-0")}>
          {basePlaceholder.slice(0, -(input.length || 1))}
        </div>
      </div>
      <div
        className="shrink-0"
        style={{
          width,
        }}
      >
        <input
          className={clsx(textClass, inputClass, "shrink-0 text-inherit", {
            "text-opacity-50": !input,
          })}
          autoFocus
          value={input || "s"}
          maxLength={11}
          onFocus={(e) => {
            const inputEl = e.target;
            if (!inputEl.value) {
              inputEl.selectionStart = inputEl.selectionEnd = 0;
            }
            if (inputEl.selectionStart === inputEl.value.length) {
              inputEl.selectionStart = inputEl.selectionEnd =
                inputEl.value.length - 2;
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              e.stopPropagation();
              const inputEl = e.target as HTMLInputElement;
              const rawText = inputEl.value;

              const { sec, min, hour } = parseDurationText(rawText);
              onChange({
                sec: Number(sec),
                min: Number(min),
                hour: Number(hour),
              });
            }
          }}
          onChange={(e) => {
            e.stopPropagation();
            e.preventDefault();
            const inputEl = e.target;
            const caret = e.target.selectionStart;
            const rawText = inputEl.value;

            const { sec, min, hour } = parseDurationText(rawText);
            const conc = [
              hour && hour + "h",
              min && min + "m",
              sec && sec + "s",
            ]
              .filter(Boolean)
              .join(" ");
            const normalizedBefore = normalizeDurationText(input);
            const normalizedAfter = normalizeDurationText(conc);
            const normalizedTextDiff =
              normalizedAfter.length - normalizedBefore.length;

            const textDiff =
              normalizedTextDiff === 0 ? 0 : conc.length - rawText.length;
            const newCaret = Math.max(
              0,
              Math.min((caret ?? 0) + textDiff, conc.length - 1)
            );
            if (conc === input) {
              requestAnimationFrame(() => {
                inputEl.selectionStart = newCaret;
                inputEl.selectionEnd = newCaret;
              });
            } else {
              setInput(conc);
              runAfterUpdate(() => {
                inputEl.selectionStart = newCaret;
                inputEl.selectionEnd = newCaret;
              });
            }
          }}
          onBlur={(e) => {
            const inputEl = e.target;
            const rawText = inputEl.value;

            const { sec, min, hour } = parseDurationText(rawText);
            const conc = [
              hour && hour + "h",
              min && ceilStringNumber(min, 59) + "m",
              sec && ceilStringNumber(sec, 59) + "s",
            ]
              .filter(Boolean)
              .join(" ");
            setInput(conc);
            onChange({
              sec: Number(sec),
              min: Number(min),
              hour: Number(hour),
            });
          }}
        />
      </div>
    </label>
  );
}

function ceilStringNumber(value: string, ceil: number) {
  if (Number(value) > ceil) return ceil;
  return value;
}

function normalizeDurationText(text: string) {
  return text.replaceAll(/[^0-9]/g, "");
}
function parseDurationText(text: string) {
  const normalized = normalizeDurationText(text);
  const sec = normalized.slice(-2);
  const min = normalized.slice(-4, -2);
  const hour = normalized.slice(0, -4);
  return { sec, min, hour };
}

function useRunAfterUpdate() {
  const afterPaintRef = useRef<(() => void) | null>(null);

  useLayoutEffect(() => {
    if (afterPaintRef.current) {
      afterPaintRef.current();
      afterPaintRef.current = null;
    }
  });

  return (cb: () => void) => (afterPaintRef.current = cb);
}
