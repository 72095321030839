interface EmptyStateProps {
  title?: string;
  description?: string;
}

export function EmptyState({
  title = "No Results Found",
  description,
}: EmptyStateProps) {
  return (
    <div className="flex flex-col items-center gap-y-1 my-14 mx-auto text-base text-center">
      {/* Paw size can be configured by setting the width */}
      <div className="w-12 aspect-[0.82/1] relative">
        <div className="absolute top-0 right-[17%] w-5/12 aspect-square border border-text border-dashed rounded-full" />
        <div className="absolute top-[24%] left-0 w-6/12 aspect-square border border-text border-dashed rounded-full" />
        <div className="absolute top-[29%] right-0 w-3/12 aspect-square border border-text border-dashed rounded-full" />
        <div className="absolute bottom-0 right-[3%] w-7/12 aspect-square border border-text border-dashed rounded-full" />
      </div>
      <div className="text-[22px] italic font-bold">{title}</div>
      {description && <div className="text-sm">{description}</div>}
    </div>
  );
}
