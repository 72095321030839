import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  ClickAwayListener,
  Container,
  Divider,
  Grid,
  IconButton,
  Link as MaterialLink,
  Popper,
  Tooltip,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import gql from "graphql-tag";
import { orderBy } from "lodash/fp";
import { useRef, useState } from "react";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import { useUpdateEffect } from "react-use";
import { makeStyles } from "tss-react/mui";
import { StringParam, useQueryParam } from "use-query-params";

import { ReactComponent as GroupIcon } from "@/icons/icon_group.svg";
import { ReactComponent as LocationGroupIcon } from "@/icons/icon_group_location.svg";

import { pluralize } from "@/util/pluralize";
import { useBreakpoints } from "@/util/useBreakpoints";
import { useDocumentTitle } from "@/util/useDocumentTitle";
import { usePagination } from "@/util/usePagination";

import { Group } from "@/pages/Settings/TagsSettings/Tag";

import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { SearchBox } from "@/components/SearchBox";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { ZendeskArticle, ZendeskLink } from "@/components/Zendesk/ZendeskLink";
import { DefaultDialog, useDialog } from "@/components/shared/Dialog";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  Page_GroupsDocument,
  useCreateGroupMutation,
  useDeleteGroupMutation,
  usePage_GroupsQuery,
} from "@/generated-models";
import { usePermissions } from "@/hooks/usePermissions";
import { theme } from "@/layout/theme";

export function GroupSettings() {
  useDocumentTitle("Group Settings");
  return (
    <Routes>
      <Route index element={<Groups />} />
      <Route path=":id/*" element={<Group />} />
    </Routes>
  );
}

const useStyles = makeStyles()((theme) => ({
  popper: {
    maxWidth: 450,
    padding: theme.spacing(2),
    margin: `${theme.spacing(1)} 0`,
    [theme.breakpoints.down("md")]: {
      width: "90vw",
    },
  },
  groupCard: {
    position: "relative",
    background: "#fff",
    textDecoration: "none",
    borderRadius: 4,
    border: "1px solid #E6E6E6",
    boxShadow: "0px 4px 4px rgba(0,0,0,0.05)",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden", // For the rounded corners
    "& svg": {
      "& path": {
        fill: "currentColor",
      },
    },
  },
  groupCardOwner: {
    background: "rgba(196,196,196,0.1)",
    padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
    display: "flex",
    flexGrow: 1,
  },
  groupEntityItem: {
    padding: `${theme.spacing(1)} 0`,
    display: "flex",
    alignItems: "center",
  },
  groupOwnerAvatar: {
    fontSize: 11,
    width: 24,
    height: 24,
    marginLeft: theme.spacing(1),
  },
  locationTag: {
    position: "absolute",
    background: "#FFFFFF",
    border: "1px solid #DFDFDF",
    boxSizing: "border-box",
    borderRadius: "2px",
    top: theme.spacing(0.5),
    width: "30%",
    right: theme.spacing(3),
    marginLeft: "auto",
    marginRight: "auto",
  },
  locationGroupHeading: {
    background: theme.palette.secondary.dark,
    color: "#fff",
  },
  nonLocationGroupHeading: {
    color: "#000",
  },
  GroupIcons: {
    color: "#C4C4C4",
    "& .locationGroupPointerPath": {
      fill: "#007CE4",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function Groups() {
  const { classes: styles } = useStyles();
  const { pushSnackbar } = useFeedback();
  const { fitsTablet } = useBreakpoints();
  const hasPermission = usePermissions();
  const anchorEl = useRef(null as null | HTMLDivElement);
  const navigate = useNavigate();
  const [creatingGroup, setCreatingGroup] = useState(false);
  const [createGroup] = useCreateGroupMutation({
    onCompleted: (data) => {
      navigate(`${data.createTag.id}/cameras?new=1`);
    },
    onError: () =>
      pushSnackbar(
        "Failed to create Group, please try again later",
        FeedbackType.Error
      ),
  });

  const [searchInputParam, setSearchInput] = useQueryParam(
    "search",
    StringParam
  );
  const searchInput = searchInputParam ?? "";

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ padding: theme.spacing(2) }}
      >
        {fitsTablet && (
          <Typography variant="h1">
            Camera Groups
            <ZendeskLink article={ZendeskArticle.CAMERA_GROUPS} />
          </Typography>
        )}
        <Grid item>
          <SearchBox
            input={searchInput}
            setInput={(value: string) => setSearchInput(value || undefined)}
            placeholder="Search Groups"
            fullWidth={false}
          />
          {hasPermission("devices_manage") && (
            <Tooltip title="Create a New Group">
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  setCreatingGroup(true);
                }}
                style={{ marginLeft: theme.spacing(4) }}
              >
                {fitsTablet ? "Create a New Group" : <AddIcon />}
              </Button>
            </Tooltip>
          )}
        </Grid>
      </Grid>

      <Grid
        item
        style={{
          padding: 18,
          backgroundColor: "#f3f3f3",
          boxShadow: "inset 0px 10px 9px -10px rgba(0,0,0,0.4)",
        }}
        ref={anchorEl}
      >
        Groups make it easy to organize your locations and cameras. Groups also
        provide access control, so if you have a user that needs access to a
        particular camera or cameras, add that user to the camera group. You can
        edit, remove, groups as required.
      </Grid>

      <GroupsList searchInput={searchInput} />

      {anchorEl.current && (
        <Backdrop open={creatingGroup} className={styles.backdrop}>
          <Popper
            id="create-group-popper"
            open={creatingGroup}
            anchorEl={anchorEl.current}
            style={{ zIndex: theme.zIndex.drawer + 1 }}
          >
            <ClickAwayListener onClickAway={() => setCreatingGroup(false)}>
              <Paper className={styles.popper} elevation={4}>
                <Formik
                  initialValues={{ name: "", description: "" }}
                  onSubmit={(values) =>
                    createGroup({
                      variables: {
                        value: {
                          name: values.name,
                          description: values.description,
                        },
                      },
                    })
                  }
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <Grid container alignItems="center">
                        <Typography variant="h3"> Create Group</Typography>
                        <Box flexGrow={1} />
                        <IconButton
                          size="small"
                          onClick={() => setCreatingGroup(false)}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Grid>
                      <Divider style={{ margin: `8px 0` }} />
                      <Grid container>
                        <Field
                          name="name"
                          component={TextField}
                          label="Title"
                          variant="outlined"
                          autoFocus
                          required
                          style={{
                            width: "100%",
                            marginTop: theme.spacing(2),
                          }}
                        />
                        <Field
                          multiline
                          name="description"
                          component={TextField}
                          label="Description"
                          variant="outlined"
                          required
                          rows={3}
                          style={{
                            width: "100%",
                            marginTop: theme.spacing(2),
                          }}
                        />
                      </Grid>
                      <Box marginTop={2} />
                      <Grid container justifyContent="flex-end">
                        <Button
                          disabled={isSubmitting}
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Create
                          {isSubmitting && (
                            <>
                              <Box m={1} />
                              <CircularProgress size={20} />
                            </>
                          )}
                        </Button>
                      </Grid>
                    </Form>
                  )}
                </Formik>
                <Divider style={{ margin: `8px 0` }} />
              </Paper>
            </ClickAwayListener>
          </Popper>
        </Backdrop>
      )}
    </>
  );
}

const pageSize = 30;

function GroupsList({ searchInput }: { searchInput: string }) {
  const { classes: styles } = useStyles();
  const { pushSnackbar } = useFeedback();
  const hasPermission = usePermissions();
  const [deleteGroup] = useDeleteGroupMutation({
    refetchQueries: [{ query: Page_GroupsDocument }],
    awaitRefetchQueries: true,
    onCompleted: () => {
      pushSnackbar(`Group deleted successfully`, FeedbackType.Success);
    },
    onError: () =>
      pushSnackbar(
        "Failed to delete Group, please try again later",
        FeedbackType.Error
      ),
  });
  const { data, error } = usePage_GroupsQuery(refetchOnMountPolicy);
  const { open, ...dialogProps } = useDialog();
  const { pageCount, reset, visibilitySensor } = usePagination(1);
  useUpdateEffect(() => reset(), [searchInput]);

  if (error) {
    return (
      <ErrorMessage
        title="Failed to load groups"
        description="Please try again later or contact support."
      />
    );
  }

  if (!data) {
    return (
      <div style={{ padding: "48px 0", margin: "0 auto" }}>
        <Loading>Fetching Groups...</Loading>
      </div>
    );
  }

  const splitSearchInput = searchInput.split(" ").map((x) => x.toLowerCase());

  function filterGroup({ name }: { name: string }) {
    const lowerCaseName = name.toLowerCase();
    return splitSearchInput.every((search) => lowerCaseName.includes(search));
  }

  const groups = orderBy(["name"], ["asc"], data.groups.filter(filterGroup));

  return (
    <>
      <Container maxWidth="lg">
        <Box p={2}>
          <Grid container spacing={2}>
            {groups.slice(0, pageCount * pageSize).map((group) => (
              <Grid
                key={group.id}
                item
                xs={12}
                md={6}
                style={{
                  position: "relative",
                  marginTop: theme.spacing(1),
                }}
              >
                <Paper className={styles.groupCard} elevation={2}>
                  <Box p={1} className={styles.nonLocationGroupHeading}>
                    <Grid container>
                      <Grid
                        item
                        style={{
                          paddingLeft: theme.spacing(1),
                          display: "inline-flex",
                        }}
                      >
                        <Box mr={1} className={styles.GroupIcons}>
                          {group.locations.length > 0 ? (
                            <LocationGroupIcon />
                          ) : (
                            <GroupIcon />
                          )}
                        </Box>
                        <Typography variant="h4" style={{ fontSize: "14px" }}>
                          {group.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className={styles.groupCardOwner}>
                    <Box m={0.5} />
                    <Typography variant="body2">
                      {group.description.length > 120
                        ? `${group.description.slice(0, 117)}...`
                        : group.description}
                    </Typography>
                  </Box>
                  <Grid container style={{ justifyContent: "space-between" }}>
                    <Grid item>
                      <div
                        style={{
                          background: "white",
                          display: "flex",
                          paddingLeft: theme.spacing(2),
                        }}
                      >
                        {hasPermission(
                          "devices_access",
                          <MaterialLink
                            component={Link}
                            to={`${group.id}/cameras`}
                            underline="hover"
                          >
                            <GroupEntityItem
                              singular="Camera"
                              plural="Cameras"
                              count={
                                group.locations.length > 0
                                  ? group.locations[0].cameras.length
                                  : group.cameras.length
                              }
                            />
                          </MaterialLink>
                        )}
                        {hasPermission(
                          "users_manage",
                          <>
                            <span style={{ paddingTop: theme.spacing(1) }}>
                              {","}
                              &nbsp;
                            </span>
                            <MaterialLink
                              component={Link}
                              to={`${group.id}/users`}
                              underline="hover"
                            >
                              <GroupEntityItem
                                singular="User"
                                plural="Users"
                                count={group.users.length}
                              />
                            </MaterialLink>
                          </>,
                          null
                        )}
                      </div>
                    </Grid>
                    {hasPermission("devices_manage") && (
                      <Grid item style={{ display: "flex" }}>
                        <IconButton
                          style={{ opacity: 0.5 }}
                          size="small"
                          component={Link}
                          to={`${group.id}/cameras`}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                        <IconButton
                          style={{ opacity: 0.5 }}
                          size="small"
                          onClick={async () => {
                            const confirmed = await open();
                            if (!confirmed) return;
                            await deleteGroup({
                              variables: { id: group.id },
                            });
                          }}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                </Paper>
                {group.locations.length > 0 && (
                  <Grid item className={styles.locationTag}>
                    <Tooltip
                      title="This group was auto generated from a location"
                      placement="top"
                    >
                      <Box
                        p={0.5}
                        style={{
                          fontSize: "9px",
                          fontWeight: "bold",
                          display: "flex",
                          alignItems: "center",
                          cursor: "default",
                        }}
                      >
                        <LocationOnIcon fontSize="inherit" color="primary" />
                        <Box
                          ml={0.5}
                          style={{ color: "#353D48", opacity: 0.4 }}
                        >
                          LOCATION GROUP
                        </Box>
                      </Box>
                    </Tooltip>
                  </Grid>
                )}
              </Grid>
            ))}
            {groups.length > pageSize && visibilitySensor}
          </Grid>
        </Box>
      </Container>

      <DefaultDialog
        {...getGroupDeleteDialogContent()}
        confirmColor="primary"
        content={
          "Deleting the group will permanently remove access for all the users to the cameras in the group"
        }
        {...dialogProps}
      />
    </>
  );
}

function getGroupDeleteDialogContent() {
  return {
    title: `Are you sure you want to permanently delete this Group?`,
    confirmText: `Confirm Delete`,
  };
}

function GroupEntityItem({
  singular,
  plural,
  count,
}: {
  singular: string;
  plural: string;
  count: number;
}) {
  const { classes } = useStyles();
  return (
    <>
      <Box className={classes.groupEntityItem}>
        <strong>{count}</strong>
        &nbsp;
        {pluralize(
          {
            1: singular,
            multi: plural,
          },
          count
        )}
      </Box>
    </>
  );
}

gql`
  mutation createGroup($value: TagInput!) {
    createTag(value: $value) {
      id
    }
  }
`;

gql`
  query page_groups {
    groups: tags {
      id
      name
      description
      cameras {
        id
      }
      # Would be nice to get rid of "locations" and use "isLocationGroup" instead,
      # but that would require API changes on "cameras" field (to include location cameras as well).
      locations {
        id
        cameras {
          id
        }
      }
      users {
        id
      }
    }
  }
`;

gql`
  mutation deleteGroup($id: Int!) {
    deleteTag(id: $id) {
      id
    }
  }
`;
