import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";
import { GlobalStyles } from "tss-react";

import { useBreakpoints } from "@/util/useBreakpoints";
import { useScript } from "@/util/useScript";

export type ZendeskCommand = (
  command: string,
  type: string,
  args?: any
) => void;

declare global {
  interface Window {
    zE?: {
      identify: (...args: any[]) => void;
      activate: () => void;
      reset: () => void;
      setHelpCenterSuggestions: (params: {
        search?: string;
        labels?: string[];
      }) => void;
    };
  }
}

export function Zendesk({ children }: { children: React.ReactNode }) {
  const { fitsDesktop } = useBreakpoints();
  const { intercomSupport } = useFlags();

  useScript(
    "https://static.zdassets.com/ekr/snippet.js?key=e452598d-c0d6-4aa8-8dfd-b233cd1cf771",
    false,
    "ze-snippet"
  );

  return (
    <>
      {children}
      <GlobalStyles
        styles={{
          /* Make sure the support button doesn't overlay over modals/popups etc */
          "iframe#launcher": {
            zIndex: "10 !important",
            display: `${
              fitsDesktop && !intercomSupport ? "block" : "none"
            } !important`,
          },
        }}
      />
    </>
  );
}

export function executeZendeskCmd(type: string, cmd: string, args?: any) {
  const zendesk = window.zE;

  if (zendesk) {
    ((zendesk as unknown) as ZendeskCommand)(type, cmd, args);
  }
}
