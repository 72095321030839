import { createTheme } from "@mui/material";
import React from "react";
import { FullScreen } from "react-full-screen";

import { useBreakpoints } from "@/util/useBreakpoints";

import { useVideoWallContext } from "@/pages/VideoWall/useVideoWallContext";

import { darkFocusThemeOptions, DarkTheme } from "@/layout/theme";

export const fullscreenWallTheme = createTheme({
  ...darkFocusThemeOptions,
  palette: {
    ...darkFocusThemeOptions.palette,
    primary: {
      main: "#ccc",
    },
  },
});

export function VideoWallLayout({
  header,
  body,
  tray,
}: {
  header: React.ReactNode;
  body: React.ReactNode;
  tray?: React.ReactNode;
}) {
  const { fullscreenHandle, fullscreenActive } = useVideoWallContext();
  const { fitsTablet } = useBreakpoints();

  /**
   * Schematic layout
   *
   * ------------------------------
   *        header        |       |
   * ---------------------|       |
   *                      |       |
   *                      |       |
   *                      |       |
   *                      | tray  |
   *         body         |       |
   *                      |       |
   *                      |       |
   *                      |       |
   *                      |       |
   * ------------------------------
   */
  return (
    <DarkTheme enabled={fullscreenActive} theme={fullscreenWallTheme}>
      <div className="flex h-full w-full overflow-auto">
        <div className="grow relative">
          <FullScreen handle={fullscreenHandle}>
            {header}
            {body}
            {!fullscreenActive && !fitsTablet && (
              <div className="opacity-50 text-center py-4">
                View on desktop to edit.
              </div>
            )}
          </FullScreen>
        </div>
        {tray}
      </div>
    </DarkTheme>
  );
}
