import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { useMemo } from "react";
import { ControllerRenderProps, useController } from "react-hook-form";

import { filterFalsy } from "@/util/filterFalsy";

import { SpotSwitch, SpotSwitchProps } from "@/components/Styled/SpotSwitch";

import { RolePermission } from "@/generated-models";
import { SPOT_GREEN } from "@/layout/theme";

import { RoleFormFields } from "./RoleDetails";

export function PermissionsDisplay({
  permissions,
  editable = false,
}: {
  permissions: Omit<RolePermission, "__typename">[];
  editable?: boolean;
}) {
  const mappedPermissionGroups = useMemo(() => {
    // Find all permissions that have no explicit group defined and bucket them under "others"
    const others: PermissionGroup = {
      label: "Others",
      // find permissions that don't have a group
      permissions: permissions
        .filter((permission) =>
          permissionGroups.every((group) => {
            // Top level permission
            if (group.id === permission.id) return false;

            return group.permissions.every((nestedPermission) => {
              if (typeof nestedPermission === "string") {
                // Nested permission
                return nestedPermission !== permission.id;
              } else {
                // Radio group
                return nestedPermission.permissions.every(
                  (radio) => radio !== permission.id
                );
              }
            });
          })
        )
        .map((permission) => permission.id),
    };
    others.defaultOnWhenEnabling = [
      typeof others.permissions[0] === "string"
        ? others.permissions[0]
        : others.permissions[0]?.permissions[0],
    ];

    return [...permissionGroups, others.permissions.length > 0 ? others : null]
      .filter(filterFalsy)
      .filter((group) =>
        // Filter out group for which the main group ID is not present in the list
        // of permissions returned from the server
        permissions.some(
          (fetchedPermission) => !group.id || fetchedPermission.id === group.id
        )
      )
      .map((group) => ({
        ...group,
        name: group.label,
        type: group.id ? "permission" : "virtual",
        initiallyEnabled: permissions.some(
          (permission) =>
            // The group is initially enabled if...
            permission.enabled &&
            // The group itself is explicitly enabled
            (group.id
              ? group.id === permission.id
              : // One of its dependencies is enabled
                group.permissions.includes(permission.id))
        ),
        permissions: group.permissions
          .filter(
            (p) =>
              // Filter out permissions for which the ID is not present in the list
              // of permissions returned from the server
              typeof p !== "string" || permissions.some((f) => f.id === p)
          )
          .map((permission) => {
            if (typeof permission === "string") {
              const fromServer = permissions.find((p) => p.id === permission);
              return {
                type: "permission" as const,
                id: permission,
                name: fromServer?.name ?? permission,
                initiallyEnabled: fromServer?.enabled ?? false,
              };
            } else {
              const filteredRadioPermissions = permission.permissions.filter(
                (p) =>
                  // Filter out permissions for which the ID is not present in the list
                  // of permissions returned from the server
                  permissions.some((f) => f.id === p)
              );
              if (filteredRadioPermissions.length === 0) return null;
              return {
                type: "radio" as const,
                name: permission.label,
                permissions: filteredRadioPermissions.map((permissionId) => ({
                  id: permissionId,
                  name: permissionId, // default, will be overwritten by spread below
                  ...permissions.find((p) => p.id === permissionId),
                  initiallyEnabled: true,
                })),
              };
            }
          })
          .filter(filterFalsy),
      }));
  }, [permissions]);

  let field: ControllerRenderProps<RoleFormFields, "permissionIds"> | undefined;
  try {
    // pretty nasty hack, useController will throw if not rendered within a <FormProvider>
    // but I couldn't find a better way around this.
    const controller = useController<RoleFormFields, "permissionIds">({
      name: "permissionIds",
    });
    field = controller?.field;
  } catch {}
  // Undefined if not rendered within a <FormProvider>
  const enabledPermissionIds = field?.value;

  const handlePermissionChange = (permission: { id: string }) => (
    _: any,
    checked: boolean
  ) => {
    // not editable
    if (!field) return;

    // Apply dependencies
    const dependenciesFulfilled =
      permissionDependencies[permission.id]?.(enabledPermissionIds ?? []) ??
      true;
    if (!dependenciesFulfilled) {
      return;
    }

    // Apply new value
    let newPermissionIds = enabledPermissionIds ?? [];
    if (checked) {
      newPermissionIds.push(permission.id);
    } else {
      newPermissionIds = newPermissionIds.filter((id) => id !== permission.id);
    }

    // Deduplicate
    newPermissionIds = [...new Set(newPermissionIds)];

    // Apply global dependencies - e.g. disabling other permissions due to
    // disabling a permission that is required by them
    newPermissionIds = applyPermissionDependencies(newPermissionIds);

    // Update form
    field.onChange(newPermissionIds);
  };

  return (
    <div className="flex flex-col gap-3">
      {mappedPermissionGroups.map((group) => {
        const groupChecked = Boolean(
          // The group is checked if...
          enabledPermissionIds
            ? // ...one of its dependencies is enabled
              group.permissions.some(
                (p) =>
                  (p.id && enabledPermissionIds!.includes(p.id)) ??
                  p.permissions?.some((r) =>
                    enabledPermissionIds?.includes(r.id)
                  )
              ) ||
                // ...the group itself represents a permission and that permission is enabled
                (group.id && enabledPermissionIds.includes(group.id))
            : // enabledPermissionIds === undefined; this is read-only mode outside of a Form
              group.initiallyEnabled
        );
        const expandable = group.permissions.length > 0;
        return (
          <Accordion
            disableGutters
            elevation={0}
            sx={{ "&:before": { display: "none" } }}
            classes={{
              root:
                "rounded-lg border border-solid border-blue-medium bg-blue-light",
              expanded: "bg-white",
            }}
            defaultExpanded={!editable ? groupChecked : undefined}
            expanded={editable ? groupChecked : undefined}
            onChange={
              editable
                ? (_, expanded) => {
                    // not editable
                    if (!field) return;

                    let newValue = field.value.slice(0);
                    if (expanded) {
                      // Enable group if it represents a permission
                      if (group.id) newValue.push(group.id);

                      if (group.defaultOnWhenEnabling) {
                        newValue.push(...group.defaultOnWhenEnabling);
                      }
                    } else {
                      const groupPermissionIds = [
                        group.id,
                        ...group.permissions.flatMap((p) =>
                          p.type === "permission"
                            ? p.id
                            : p.permissions.map((r) => r.id)
                        ),
                      ];
                      newValue = newValue.filter(
                        (permissionId) =>
                          !groupPermissionIds.includes(permissionId)
                      );
                    }
                    field.onChange([...new Set(newValue)]);
                  }
                : undefined
            }
            key={group.label}
          >
            <AccordionSummary
              classes={{
                root: "px-3 min-h-none flex",
                content: "my-0",
              }}
            >
              <Typography className="font-bold grow">{group.label}</Typography>

              <PermissionSwitch editable={editable} checked={groupChecked} />
            </AccordionSummary>
            {expandable && (
              <AccordionDetails
                classes={{
                  root: "px-3 py-0 pb-3 flex flex-col gap-3",
                }}
              >
                <Divider className="border-[#000] border-opacity-10" />
                {group.permissions.map((permission) =>
                  permission.type === "permission" ? (
                    <PermissionDisplay
                      editable={editable}
                      disabled={
                        !(
                          permissionDependencies[permission.id]?.(
                            enabledPermissionIds ?? []
                          ) ?? true
                        )
                      }
                      key={permission.id}
                      label={permission.name}
                      checked={
                        enabledPermissionIds
                          ? enabledPermissionIds.includes(permission.id)
                          : permission.initiallyEnabled
                      }
                      onChange={
                        editable
                          ? handlePermissionChange(permission)
                          : undefined
                      }
                    />
                  ) : (
                    <PermissionRadioDisplay
                      checked={
                        enabledPermissionIds
                          ? enabledPermissionIds.find((enabledPermissionId) =>
                              permission.permissions.some(
                                (candidate) =>
                                  candidate.id === enabledPermissionId
                              )
                            )
                          : // If not rendered within a <FormProvider>, we're in read-only mode
                            permission.permissions.find((p) => p.enabled)?.id
                      }
                      onChange={
                        editable
                          ? (checked) => {
                              // not editable
                              if (!field) return;

                              let newValue = field.value
                                .slice(0)
                                .filter(
                                  (p) =>
                                    !permission.permissions.some(
                                      (candidate) => candidate.id === p
                                    )
                                );

                              if (checked) newValue.push(checked);

                              field.onChange([...new Set(newValue)]);
                            }
                          : undefined
                      }
                      editable={editable}
                      key={permission.name}
                      group={permission}
                    />
                  )
                )}
              </AccordionDetails>
            )}
          </Accordion>
        );
      })}
    </div>
  );
}

/**
 * Maps a permission ID to a function that returns whether the permission is enabled.
 */
const permissionDependencies: Record<
  string,
  (permissionIds: string[]) => boolean
> = {
  "walls.access": (permissionIds) => {
    return permissionIds.includes("video.live.view");
  },
  "walls.default.editor": (permissionIds) => {
    return permissionIds.includes("video.live.view");
  },
  "walls.default.viewer": (permissionIds) => {
    return permissionIds.includes("video.live.view");
  },
  "people.search.access": (permissionIds) => {
    return permissionIds.includes("video.vod.access");
  },
  "people.assignment.manage": (permissionIds) => {
    return (
      permissionIds.includes("video.vod.access") &&
      permissionIds.includes("people.search.access")
    );
  },
  "video.share": (permissionIds) => {
    return (
      permissionIds.includes("video.live.access") ||
      permissionIds.includes("video.vod.access")
    );
  },
  "attribute.search.manage": (permissionIds) => {
    return permissionIds.includes("devices.manage");
  },
  "people.search.manage": (permissionIds) => {
    return (
      permissionIds.includes("devices.manage") &&
      permissionIds.includes("attribute.search.manage")
    );
  },
};

/**
 * A group of interdependent permissions.
 * The top level can either be an actual permission, or a virtual one. Either way, if the top level
 * is unchecked, all other permissions in the group are unchecked and not exposed.
 */
interface PermissionGroup {
  /**
   * If no id is provided, the top level section will be a virtual permission; only serving
   * to expose the other permissions in the group when checked.
   */
  id?: string;
  label: string;
  permissions: (string | PermissionRadioGroup)[];
  defaultOnWhenEnabling?: string[];
}

/**
 * A group of permissions that should function as a radio button group.
 */
interface PermissionRadioGroup {
  label: string;
  permissions: string[];
}
/**
 * The purpose of the following structure is to statically define groups/dependencies/order of permissions.
 *
 * The server will be the source of truth for:
 * - permission IDs
 * - permission names
 * - permission descriptions
 * - permission initial enabled values
 */
const permissionGroups: PermissionGroup[] = [
  {
    label: "Video",
    permissions: [
      "video.live.access",
      "video.vod.access",
      "people.search.access",
      "people.assignment.manage",
      "video.share",
      "video.smart.search",
      "audio.access",
    ],
    defaultOnWhenEnabling: ["video.live.access", "video.vod.access"],
  },
  {
    id: "users.access",
    label: "Users",
    permissions: ["users.manage"],
  },
  {
    id: "devices.access",
    label: "Devices",
    permissions: [
      "devices.manage",
      "audio.manage",
      "attribute.search.manage",
      "people.search.manage",
    ],
  },
  {
    id: "cases.access",
    label: "Cases",
    permissions: [
      {
        label: "Access All Cases",
        permissions: [
          "cases.default.viewer",
          "cases.default.commenter",
          "cases.default.editor",
        ],
      },
    ],
  },
  {
    id: "walls.access",
    label: "Walls",
    permissions: [
      {
        label: "Access All Walls",
        permissions: ["walls.default.viewer", "walls.default.editor"],
      },
    ],
  },
  {
    id: "alerts.access",
    label: "Alerts",
    permissions: [
      {
        label: "Access All Alerts",
        permissions: ["alerts.default.viewer", "alerts.default.editor"],
      },
    ],
  },
  {
    id: "intelligence.access",
    label: "Intelligence",
    permissions: [
      {
        label: "Access All Dashboards",
        permissions: [
          "intelligence.default.viewer",
          "intelligence.default.editor",
        ],
      },
    ],
  },
  {
    id: "integrations.access",
    label: "Integrations",
    permissions: [
      // Commented out until we implement granular permissions for integrations.
      // {
      //   label: "Access All Integrations",
      //   permissions: [
      //     "integrations.default.viewer",
      //     "integrations.default.editor",
      //   ],
      // },
      "integrations.manage",
    ],
  },
  {
    label: "Organization",
    permissions: [
      "organization.api",
      "organization.audit_log",
      "organization.compliance",
      "organization.settings",
    ],
    defaultOnWhenEnabling: ["organization.settings"],
  },
];

function applyPermissionDependencies(permissionIds: string[]) {
  return permissionIds.filter(
    (id) => permissionDependencies[id]?.(permissionIds) ?? true
  );
}

function PermissionDisplay({
  label,
  disabled,
  checked,
  editable = false,
  onChange,
}: {
  label: string;
  disabled: boolean;
  checked: boolean;
  editable?: boolean;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
}) {
  return (
    <div
      className={clsx("flex grow", {
        "opacity-50": editable && disabled,
      })}
    >
      <Typography
        variant="body2"
        className={clsx("grow", checked ? "" : "opacity-50")}
      >
        {label}
      </Typography>
      <PermissionSwitch
        editable={editable}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
      />
    </div>
  );
}

function PermissionSwitch({
  checked,
  editable,
  ...props
}: SpotSwitchProps & { checked: boolean; editable: boolean }) {
  return editable ? (
    <SpotSwitch
      color={SPOT_GREEN}
      className="!mx-0"
      checked={checked}
      {...props}
    />
  ) : (
    <Typography
      variant="body2"
      className={clsx(
        "text-right leading-[normal]",
        checked ? "text-spotGreen font-bold" : "opacity-30"
      )}
    >
      {checked ? "On" : "Off"}
    </Typography>
  );
}

interface MappedRadioPermission {
  type: "radio";
  name: string;
  permissions: {
    initiallyEnabled: boolean;
    description?: string | undefined;
    enabled?: boolean | undefined;
    id: string;
    name: string;
  }[];
}
function PermissionRadioDisplay({
  checked,
  editable,
  group,
  onChange,
}: {
  checked?: string;
  editable: boolean;
  group: MappedRadioPermission;
  onChange?: (checked?: string) => void;
}) {
  const handleChange =
    onChange &&
    ((_: any, checked: string | boolean) => {
      onChange(
        checked === true ? group.permissions[0].id : checked || undefined
      );
    });
  return (
    <Accordion
      disableGutters
      elevation={0}
      sx={{ "&:before": { display: "none" } }}
      classes={{
        root: "mx-0 px-0",
        // expanded: "bg-white",
      }}
      onChange={handleChange}
      expanded={Boolean(checked)}
    >
      <AccordionSummary
        classes={{
          root: "px-0 mx-0 flex justify-stretch",
        }}
      >
        <PermissionDisplay
          label={group.name}
          checked={!!checked}
          disabled={false}
          editable={editable}
          onChange={handleChange}
        />
      </AccordionSummary>
      <AccordionDetails className="pl-8 py-0">
        {editable ? (
          <RadioGroup
            // Radio group value must be non-nullish to remain in controlled mode
            value={checked || group.permissions[0].id}
            name={group.name}
            onChange={handleChange}
          >
            {group.permissions.map((permission) => (
              <FormControlLabel
                classes={{
                  label: "text-sm",
                  root: clsx({ "opacity-60": permission.id !== checked }),
                }}
                value={permission.id}
                control={<Radio size="small" className="p-1" />}
                label={permission.name}
                key={permission.id}
              />
            ))}
          </RadioGroup>
        ) : (
          group.permissions.find((p) => p.id === checked)?.name
        )}
      </AccordionDetails>
    </Accordion>
  );
}
