import React, { PropsWithChildren, useContext, useMemo } from "react";
import {
  FullScreenHandle,
  FullScreen as FullScreenWrapper,
  useFullScreenHandle as useFullScreenHandle_,
} from "react-full-screen";

const FullScreenContext = React.createContext({
  active: false,
  enter: async () => {},
  exit: async () => {},
  node: { current: null },
  browserSupportsFullscreen: true,
} as FullScreenHandle & {
  browserSupportsFullscreen: boolean;
});

export function useFullScreenHandle() {
  return useContext(FullScreenContext);
}

export function useExitIfFullscreen() {
  const { active, exit } = useFullScreenHandle();
  return async () => {
    if (active) exit();
  };
}

export function FullScreen({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) {
  const fullscreenHandle = useFullScreenHandle_();
  const extendedHandle = useMemo(
    () => ({
      ...fullscreenHandle,
      browserSupportsFullscreen:
        typeof document.body.requestFullscreen === "function",
    }),
    [fullscreenHandle]
  );
  return (
    <FullScreenWrapper handle={fullscreenHandle} className={className}>
      <FullScreenContext.Provider value={extendedHandle}>
        {children}
      </FullScreenContext.Provider>
    </FullScreenWrapper>
  );
}

export function IfFullscreen({ children }: PropsWithChildren<{}>) {
  const { active } = useFullScreenHandle();
  if (!active) return null;
  return <>{children}</>;
}

export function IfNotFullscreen({ children }: PropsWithChildren<{}>) {
  const { active } = useFullScreenHandle();
  if (active) return null;
  return <>{children}</>;
}
