import { Tooltip, Typography } from "@mui/material";

import { useMe } from "@/components/Auth";

import { Role } from "@/generated-models";

interface CameraSupportDetailsProps {
  camera?: { id: number; appliance: { serialNumber: string } } | null;
}

const CameraSupportDetails = ({
  camera,
}: CameraSupportDetailsProps): JSX.Element => {
  const me = useMe();
  const isSupport = me && me.role >= Role.Success;

  if (isSupport && camera) {
    return (
      <Tooltip
        title="Camera ID and appliance serial number"
        placement="bottom-start"
      >
        <Typography className="text-2xs text-gray-71" variant="caption">
          {`ID: ${camera.id}, SN: ${camera.appliance.serialNumber}`}
        </Typography>
      </Tooltip>
    );
  }
  return <></>;
};

export default CameraSupportDetails;
