import clsx from "clsx";
import { motion } from "framer-motion";

import {
  useCopilotEnabled,
  useUniversalSearchByImage,
} from "../copilotQueryHooks";
import { CopilotMode, useCopilotContext } from "../useCopilotContext";

export function CopilotAddLabelButton() {
  const { setMode } = useCopilotContext();
  const { copilotEnabled } = useCopilotEnabled();
  const { loading } = useUniversalSearchByImage();

  const disabled = loading || !copilotEnabled;

  return (
    <motion.button
      disabled={disabled}
      className={clsx("relative bg-transparent group", {
        "opacity-40": disabled,
      })}
      animate="animate"
      whileHover="hover"
      onClick={() => {
        setMode(CopilotMode.addobject);
      }}
      variants={{
        hover: {
          color: "#ffffff",
          translateY: -4,
          transition: { duration: 0.2 },
        },
      }}
      transition={{
        duration: 0.8,
      }}
      style={{
        color: "#925EFF",
      }}
    >
      <>
        <svg
          width="23"
          height="16"
          viewBox="0 0 23 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.357143"
            y="0.357143"
            width="17.8571"
            height="9.28571"
            rx="4.64286"
            fill="#100920"
            stroke="#4C076C"
            strokeWidth="0.714286"
          />
          <circle cx="16" cy="9" r="5" fill="#100920" />
          <g clipPath="url(#clip0_2849_32584)">
            <path
              d="M15.5 2.25C12.05 2.25 9.25 5.05 9.25 8.5C9.25 11.95 12.05 14.75 15.5 14.75C18.95 14.75 21.75 11.95 21.75 8.5C21.75 5.05 18.95 2.25 15.5 2.25ZM18.625 9.125H16.125V11.625H14.875V9.125H12.375V7.875H14.875V5.375H16.125V7.875H18.625V9.125Z"
              fill="currentColor"
            />
          </g>
          <path
            d="M4.1625 6.9125L6.07083 5L4.1625 3.0875L4.75 2.5L7.25 5L4.75 7.5L4.1625 6.9125Z"
            fill="white"
          />
          <defs>
            <clipPath id="clip0_2849_32584">
              <rect
                width="15"
                height="15"
                fill="white"
                transform="translate(8 1)"
              />
            </clipPath>
          </defs>
        </svg>
        <motion.span
          className="absolute text-xs text-white w-[80px] -left-6 top-[18px] opacity-0 font-barlow"
          variants={{
            hover: {
              opacity: 1,
            },
          }}
        >
          Add Label
        </motion.span>
      </>
    </motion.button>
  );
}
