import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  AutocompleteProps,
  Checkbox,
  ChipProps,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { useField } from "formik";
import { keyBy, sortBy } from "lodash/fp";
import { useFormContext } from "react-hook-form";
import { makeStyles } from "tss-react/mui";

import { Chip } from "@/components/Chip";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles()(() => ({
  chipRoot: { borderRadius: 6, backgroundColor: "#fff" },
  deleteIcon: { width: 14, height: 14, margin: "0 5px 0 -2px" },
  popupIndicatorOpen: { transform: "none" },
  popupOption: {
    "&[aria-selected='true']": { backgroundColor: "initial" },
    borderBottom: "1px solid rgba(0, 0, 0, 0.04)",
  },
  checkboxLabel: { fontWeight: "bold" },
  endAdornment: { top: "calc(50% - 20px)" },
  underline: {
    "&:after": { right: 52 },
    "&:before": { right: 52 },
  },
}));

type FormikGroupSelectProps = Omit<GroupSelectProps, "methods" | "value"> & {
  name: string;
};
// const emptyState = { id: -1, name: "No groups selected", disabled: true };
const manyState = { id: 0, name: "Many groups selected", disabled: true };
const allState = { id: 0, name: "All groups selected", disabled: true };

export function FormikGroupSelect(props: FormikGroupSelectProps) {
  const [, meta, helpers] = useField<string[]>(props.name);
  return <GroupSelectBase {...props} methods={helpers} value={meta.value} />;
}

type HookFormGroupSelectProps = Omit<GroupSelectProps, "methods" | "value"> & {
  name: string;
};
export function HookFormGroupSelect(props: HookFormGroupSelectProps) {
  const { watch, ...methods } = useFormContext();
  const value = watch(props.name) as string[];

  return (
    <GroupSelectBase
      {...props}
      methods={{
        setValue: (value) =>
          methods.setValue(props.name, value, {
            shouldDirty: true,
            shouldTouch: true,
          }),
      }}
      value={value}
    />
  );
}

interface GroupSelectOption {
  id: number;
  name: string;
  disabled?: boolean;
}
interface GroupSelectProps {
  options: GroupSelectOption[];
  autocompleteProps?: Partial<
    AutocompleteProps<GroupSelectOption, true, true, false>
  >;
  variant?: "standard" | "filled" | "outlined";
  methods: {
    setValue: (value: string[]) => void;
  };
  value: string[];
  chipProps?: Partial<ChipProps>;
  textFieldProps?: Partial<TextFieldProps>;
}
function GroupSelectBase(props: GroupSelectProps) {
  const { classes } = useStyles();
  const optionsMap = keyBy("id", props.options);
  const value = props.value.map(
    (id) => optionsMap[id] ?? { id, name: `Unknown group (${id})` }
  );
  // if (value.length === 0) value.push(emptyState);
  const allSelected = value.length === props.options.length;
  const sortedOptions = sortBy("name", props.options);

  const { InputProps, ...textFieldProps } = props.textFieldProps ?? {};

  return (
    <Autocomplete
      multiple
      id="groups-select"
      value={value}
      onChange={(_, newValue) =>
        props.methods.setValue(
          newValue.filter((v) => v.id > 0).map((v) => v.id.toString())
        )
      }
      options={sortedOptions}
      disableCloseOnSelect
      disableClearable
      getOptionLabel={(option) => option.name}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            color="primary"
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </li>
      )}
      // PaperComponent={(paperProps) => {
      //   return (
      //     <Paper {...paperProps}>
      //       <FormControlLabel
      //         classes={{ label: classes.checkboxLabel }}
      //         control={
      //           <Checkbox
      //             color="primary"
      //             icon={icon}
      //             checkedIcon={checkedIcon}
      //             style={{ margin: "0 8px 0 27px" }}
      //             checked={allSelected}
      //             onChange={(event) =>
      //               helpers.setValue(
      //                 event.target.checked
      //                   ? props.options.map((v) => v.id.toString())
      //                   : []
      //               )
      //             }
      //           />
      //         }
      //         label="Camera Groups"
      //       />

      //       <Divider />
      //       {paperProps.children}
      //     </Paper>
      //   );
      // }}
      ListboxProps={{ style: { padding: 0 } }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={props.variant}
          label="Groups & Locations"
          InputLabelProps={{ ...params.InputLabelProps, shrink: true }}
          InputProps={{
            ...params.InputProps,
            style: { paddingRight: 52 },
            ...((props.variant === "standard"
              ? {
                  disableUnderline: false,
                  classes: { underline: classes.underline },
                }
              : {}) as any),
            ...InputProps,
          }}
          helperText={
            props.value.length === 0
              ? // "This user doesn’t have access to any groups or locations. Use the add button to give them access."
                "No groups or locations selected"
              : undefined
          }
          {...textFieldProps}
        />
      )}
      renderTags={(value, getTagProps) => {
        const tags = allSelected
          ? [allState]
          : value.length > 20
          ? [manyState]
          : value;
        return tags.map((option, index) => {
          const tagProps = getTagProps({ index });
          return (
            <Chip
              label={option.name}
              {...tagProps}
              // variant="outlined"
              deleteIcon={<CloseIcon />}
              // classes={{
              //   root: classes.chipRoot,
              //   deleteIcon: classes.deleteIcon,
              // }}
              onDelete={option.disabled ? undefined : tagProps.onDelete}
              {...props.chipProps}
            />
          );
        });
      }}
      openText="Add groups"
      popupIcon={
        <Chip
          label="Add"
          variant="outlined"
          color="primary"
          clickable
          classes={{ root: classes.chipRoot }}
        />
      }
      classes={{
        popupIndicatorOpen: classes.popupIndicatorOpen,
        option: classes.popupOption,
        endAdornment: classes.endAdornment,
        ...props.autocompleteProps?.classes,
      }}
      {...props.autocompleteProps}
    />
  );
}
