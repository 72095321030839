import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function Circle(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fill="currentColor"
        d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
      />
    </SvgIcon>
  );
}
