import Chip, { ChipProps } from "@mui/material/Chip";

const variants = {
  online: "bg-[#CBFFA6] text-[#247100] font-medium capitalize text-sm",
  offline: "bg-[#FFD9D7] text-[#930000] font-medium capitalize text-sm",
};

interface StatusChipProps extends ChipProps {
  status: keyof typeof variants;
}

export function StatusChip({ status, ...props }: StatusChipProps) {
  return (
    <Chip
      label={status}
      size="small"
      classes={{ root: variants[status] }}
      {...props}
    />
  );
}
