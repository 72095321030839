import { Divider, Typography } from "@mui/material";
import clsx from "clsx";

import { useBreakpoints } from "@/util/useBreakpoints";

export function IntelligenceHeader({
  className,
  subtitleClassName,
}: {
  className?: string;
  subtitleClassName?: string;
}) {
  const { fitsTablet } = useBreakpoints();

  return (
    <>
      <div
        className={clsx(
          "flex flex-row lg:justify-start justify-around items-center gap-0 -mt-4 py-2 sm:py-0 sm:!mt-[-50px]",
          className
        )}
      >
        <img
          src="/intelligenceIllustration.svg"
          className="max-h-[140px] sm:max-h-[250px] sm:max-w-fit"
          alt="Intelligence Illustration"
        />
        <div className="sm:px-0 sm:mt-0 pr-1">
          <img
            src="/intelligenceLogoText.svg"
            className="sm:min-w-[268px]"
            alt="Intelligence Logo"
          />
          <Typography
            className={clsx(
              "mt-2 font-light text-primary text-[22px] leading-[25.78px] sm:leading-[38.25px] sm:text-[32px]",
              subtitleClassName
            )}
          >
            Turn video into
            {!fitsTablet && <br />} meaningful insights.
          </Typography>
        </div>
        <div className="ml-10 hidden lg:flex">
          <Divider orientation="vertical" variant="middle" className="h-auto" />
          <Typography className="font-base text-normal leading-5 ml-10">
            Make smart decisions based on video <br /> data, improve operational
            efficiency, <br /> and optimize your business.
          </Typography>
        </div>
      </div>
    </>
  );
}
