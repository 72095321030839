import { Typography } from "@mui/material";
import clsx from "clsx";
import { format, utcToZonedTime } from "date-fns-tz";
import React from "react";

import { useBreakpoints } from "@/util/useBreakpoints";

import { useWallclockTime } from "@/components/Player/PlayerBase";

interface WallclockTimerProps {
  durationText?: string;
  timezone: string;
}

export function WallclockTimer(props: WallclockTimerProps) {
  const wallclockTime = useWallclockTime();
  if (!wallclockTime) return null;
  return <WallclockStatic {...props} date={wallclockTime} />;
}

export function WallclockStatic({
  date,
  durationText,
  timezone,
}: WallclockTimerProps & { date: Date }) {
  const { fitsTablet, fitsDesktop } = useBreakpoints();
  const dateFormat = fitsTablet ? "PPP" : "LLLL d, yyyy";
  const zonedTime = utcToZonedTime(date, timezone);

  return (
    <div
      className={clsx("flex gap-x-2 gap-y-1 text-white", {
        "flex-col": !fitsDesktop,
      })}
    >
      <Typography
        variant="body2"
        className="text-sm opacity-70 leading-none whitespace-nowrap"
      >
        {format(zonedTime, dateFormat, { timeZone: timezone })}
      </Typography>

      <div className="flex items-end gap-2">
        <Typography
          variant="body2"
          className="text-xl font-bold leading-4 whitespace-nowrap"
        >
          {format(zonedTime, "pp")}
          {fitsTablet && durationText && ` (${durationText} clip)`}
        </Typography>
        <Typography
          variant="body2"
          className="text-sm opacity-70 leading-none whitespace-nowrap"
        >
          {format(zonedTime, "z", { timeZone: timezone })}
        </Typography>
      </div>
    </div>
  );
}
