import * as turf from "@turf/turf";
import clsx from "clsx";
import { forceCollide, forceSimulation, forceX, forceY } from "d3-force";
import { motion } from "framer-motion";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useMemo, useState } from "react";

import { ErrorMessage } from "@/components/ErrorMessage";
import { usePlayingIntent } from "@/components/Player/PlayerBase";
import { useFirstActiveCamId } from "@/components/View/sharedViewHooks";

import { AnnotationLabelState } from "@/generated-models";

import {
  LibraryAddStep,
  useLibraryParams,
} from "../CopilotPlayerSection/CopilotLibraryPopover/copilotLibraryHooks";
import { COPILOT_COLORS } from "../constant";
import {
  useCombinedIgnoreList,
  useCopilotEnabled,
  useHasAdvancedAi,
  useUniversalSearchByImage,
  useUniversalSearchLaunchHandler,
} from "../copilotQueryHooks";
import { useCopilotContext } from "../useCopilotContext";
import { formatSubjectLabel } from "../utils";
import { CopilotLabelButton } from "./CopilotLabelButton";
import { CopilotLabelProvider } from "./CopilotLabelButton/useCopilotLabelContext";
import { OBJECT_UPSERT_MODES } from "./CopilotUpsertObjectOverlay";
import { CopilotWorkflowInfoTray } from "./CopilotWorkflowInfoTray";

const STAGGER_DELAY = 0.05;

function useBuildLabelItems() {
  const { copilotIoUThreshold } = useFlags();
  const { data } = useUniversalSearchByImage();

  return useMemo(() => {
    const items =
      data.map((item, index) => {
        const points: [number, number][] = [];
        const xPoints: number[] = [];
        const yPoints: number[] = [];

        item.shape.forEach((d: { x: number; y: number }) => {
          xPoints.push(d.x);
          yPoints.push(d.y);
          points.push([d.x, d.y]);
        });

        // Need to close the polygon for calculations.
        points.push(points[0]);

        return {
          ...item,
          label: formatSubjectLabel(item.title),
          color: COPILOT_COLORS[index % COPILOT_COLORS.length],
          points,
          x: (Math.min(...xPoints) + Math.max(...xPoints)) / 2,
          y: (Math.min(...yPoints) + Math.max(...yPoints)) / 2,
        };
      }) || [];

    const pinned = items.filter(
      (i) => i.labelState === AnnotationLabelState.Pinned
    );

    // Suppress any labels that are overlapping with a pinned label.
    try {
      pinned.forEach((p) => {
        const pinnedPoly = turf.polygon([p.points]);
        const pinnedArea = turf.area(pinnedPoly);

        items.forEach((i) => {
          if (i.labelState === AnnotationLabelState.Pinned) return 0;

          const itemPoly = turf.polygon([i.points]);
          const intersection = turf.intersect(pinnedPoly, itemPoly);

          if (intersection) {
            const itemArea = turf.area(itemPoly);
            const intersectionArea = turf.area(intersection);
            const unionArea = pinnedArea + itemArea - intersectionArea;
            const iou = intersectionArea / unionArea;

            if (iou > copilotIoUThreshold) {
              i.labelState = AnnotationLabelState.Suppressed;
            }
          }
        });
      });
    } catch {
      // opt out of interseciton detection if failure
    }

    forceSimulation(items)
      .force("x", forceX().strength(0.0002))
      .force("y", forceY().strength(0.0002))
      .force(
        "collide",
        forceCollide()
          .radius(() => 5)
          .iterations(5)
      )
      .stop()
      .tick(300);

    return items.map((r) => ({
      ...r,
      middleX: r.x,
      middleY: r.y,
    }));
  }, [data, copilotIoUThreshold]);
}

export function CopilotBaseOverlay() {
  const { mode } = useCopilotContext();
  const [libraryParams] = useLibraryParams();
  const { copilotEnabled } = useCopilotEnabled();
  const [currentZone, setCurrentZone] = useState<number | null>();
  const cameraId = useFirstActiveCamId();

  const hasAdvancedAi = useHasAdvancedAi(cameraId);

  const ignoreList = useCombinedIgnoreList();
  const isPlaying = usePlayingIntent();

  useUniversalSearchLaunchHandler();
  const { loading, error } = useUniversalSearchByImage();

  const items = useBuildLabelItems();

  if (isPlaying || !copilotEnabled || !hasAdvancedAi) return null;

  return (
    <>
      {error && (
        <div className="w-full h-full flex-center">
          <div className="bg-white bg-opacity-60 p-12 rounded-3xl border-1 border-white/80">
            <ErrorMessage dense title="Oops!" description={error.toString()} />
          </div>
        </div>
      )}
      {!loading && !error && items.length > 0 && (
        <motion.div
          className={clsx("w-full h-full bg-black bg-opacity-40", {
            hidden:
              libraryParams.addStep === LibraryAddStep.inputvisual ||
              OBJECT_UPSERT_MODES.includes(mode),
          })}
          id="copilot-overlay"
          key="overlay"
        >
          <svg
            className="absolute w-full h-full"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            {items.map(({ label, id, color, shape }) => {
              const isFoundObject =
                libraryParams.addStep === LibraryAddStep.found &&
                label.toLowerCase() === libraryParams.object?.toLowerCase();

              const isZoneVisible = [currentZone].includes(id);
              return (
                <motion.polygon
                  key={`volume-${id}`}
                  points={shape
                    .map((p: { x: number; y: number }) => `${p.x}, ${p.y}`)
                    .join(" ")}
                  stroke={color}
                  strokeWidth={2}
                  vectorEffect="non-scaling-stroke"
                  fill={color}
                  fillOpacity={0.5}
                  className={clsx(
                    "transition-opacity ease-out duration-300 opacity-0",
                    {
                      "opacity-0": currentZone !== id,
                      "opacity-100": isFoundObject || isZoneVisible,
                      "animate-pulse": isFoundObject,
                      hidden: ignoreList.includes(label.toLowerCase()),
                    }
                  )}
                />
              );
            })}
          </svg>
          <motion.div
            initial="hidden"
            animate="show"
            variants={{
              hidden: { opacity: 0 },
              show: {
                opacity: 1,
                transition: {
                  staggerChildren: STAGGER_DELAY,
                },
              },
            }}
          >
            <CopilotWorkflowInfoTray />
            {items.map(({ id, ...item }, idx) => (
              <motion.div
                className={clsx({
                  hidden:
                    ignoreList.includes(item.label.toLowerCase()) ||
                    item.labelState === AnnotationLabelState.Suppressed,
                })}
                key={`subject-${id}`}
                variants={{
                  hidden: { opacity: 0 },
                  show: { opacity: 1 },
                }}
              >
                <CopilotLabelProvider
                  id={id}
                  label={item.label}
                  labelState={item.labelState}
                  color={item.color}
                  shape={item.shape}
                >
                  <CopilotLabelButton
                    animationDelay={STAGGER_DELAY * 1000 * idx}
                    {...item}
                    onRevealZone={() => {
                      setCurrentZone(id);
                    }}
                    onHideZone={() => {
                      setCurrentZone(null);
                    }}
                  />
                </CopilotLabelProvider>
              </motion.div>
            ))}
          </motion.div>
        </motion.div>
      )}
    </>
  );
}
