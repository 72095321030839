import AddIcon from "@mui/icons-material/Add";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SearchIcon from "@mui/icons-material/Search";
import { Button, Divider, IconButton, Paper, Typography } from "@mui/material";
import clsx from "clsx";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { NumberParam, useQueryParam } from "use-query-params";

import { ReactComponent as CaseIcon } from "@/icons/case.svg";

import { useBreakpoints } from "@/util/useBreakpoints";

import { ClipNavigation } from "@/pages/Search/ClipNavigation/ClipNavigation";
import { ZoneSwitch } from "@/pages/Search/ZoneMotion/ZoneSwitch";
import { ReactComponent as EmptyStateIcon } from "@/pages/Search/search-empty-state.svg";
import {
  Clip,
  useCameraPreviews,
  useVodParam,
} from "@/pages/Search/searchHooks";

import { CopilotPlayerSection } from "@/components/Ai/Copilot/CopilotPlayerSection/CopilotPlayerSection";
import { useIsCopilotCompatible } from "@/components/Ai/Copilot/copilotQueryHooks";
import { FullScreen, IfFullscreen } from "@/components/FullScreen";
import { WallClock as MobileWallClock } from "@/components/Live/WallClock";
import {
  AddToCaseDialog,
  CaseContentType,
} from "@/components/Player/AddToCaseDialog";
import { VodPlayer } from "@/components/Player/MainPlayer/MainPlayer";
import {
  HdIndicator,
  LocalIndicator,
} from "@/components/Player/PlayerMetadata";
import QuickEditCameraName from "@/components/QuickEditCameraName";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { PlayerMetadataLayout, WallClock } from "@/components/View/View";
import { useFocusedCam } from "@/components/View/sharedViewHooks";
import { QueryParamLink } from "@/components/shared/QueryParamLink";

import {
  Page_SearchQuery,
  useAddClipToCaseMutation,
  useGetCaseQuery,
} from "@/generated-models";
import { usePermissions } from "@/hooks/usePermissions";

import { usePrefixOrgSlug } from "../../hooks/useOrgRouteBase";
import { CopilotProvider } from "../Ai/Copilot/useCopilotContext";

export function SearchPlayerSection({
  activeCams,
}: {
  activeCams: Page_SearchQuery["cameras"];
}) {
  const hasPermission = usePermissions();
  const { fitsDesktop, fitsTablet } = useBreakpoints();
  const [dialogOpen, setDialogOpen] = useState(false);
  const copilotCompatible = useIsCopilotCompatible();
  const { vodParam, vodStart, vodEnd, vodPosition, setVod } = useVodParam();
  const vodActive = !!vodParam;
  const navigate = useNavigate();
  const focusedCam = useFocusedCam(activeCams);
  const previews = useCameraPreviews(focusedCam.id);
  const { pushSnackbar } = useFeedback();
  const [addClipToCase] = useAddClipToCaseMutation({
    onError: () =>
      pushSnackbar(
        "We were unable to save this clip. Please try again later.",
        FeedbackType.Error
      ),
  });
  const [caseId] = useQueryParam("case", NumberParam);
  const { data } = useGetCaseQuery({
    variables: {
      id: caseId || -1,
    },
    skip: !caseId,
    // Background info on nextFetchPolicy (buried in an issue):
    // https://github.com/apollographql/apollo-client/issues/6760#issuecomment-668188727
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const prefixOrgSlug = usePrefixOrgSlug();

  function getClipNavigationHandler(
    determineNavigation: typeof determineNextClip
  ) {
    if (!previews) return undefined;
    const clipAfterNavigation = determineNavigation(
      previews,
      vodStart.toISOString()
    );
    if (!clipAfterNavigation) return undefined;
    return () => setVod(clipAfterNavigation);
  }

  const playNextClip = vodActive
    ? getClipNavigationHandler(determineNextClip)
    : undefined;
  const playPreviousClip = vodActive
    ? getClipNavigationHandler(determinePreviousClip)
    : undefined;

  return (
    <PlayerBackground>
      <CopilotProvider>
        <div>
          {fitsDesktop && (
            <div className="flex items-center gap-1 p-3 justify-between">
              {activeCams.length === 1 ? (
                <>
                  <QuickEditCameraName
                    cameraId={activeCams[0].id}
                    camName={activeCams[0].name}
                  />
                  {activeCams[0].location.tag ? (
                    <QueryParamLink
                      params={{ g: activeCams[0].location.tag.id }}
                      className="truncate text-primary"
                    >
                      {activeCams[0].location.name}
                    </QueryParamLink>
                  ) : (
                    activeCams[0].location.name
                  )}
                  {fitsDesktop && vodActive && (
                    <>
                      <div className="grow" />
                      <HdIndicator />
                      <LocalIndicator />
                    </>
                  )}
                </>
              ) : (
                <>
                  <SearchIcon className="" />
                  <Typography component="h2" className="font-bold md:text-xl">
                    Multi-Camera Search
                  </Typography>
                  <Button
                    variant="outlined"
                    color="primary"
                    component={QueryParamLink}
                    removalParams={["cams"]}
                    className="ml-auto"
                  >
                    Remove All Cameras
                  </Button>
                </>
              )}
            </div>
          )}

          <AddToCaseDialog
            type={CaseContentType.clip}
            open={dialogOpen}
            setOpen={setDialogOpen}
            onSave={async (caseId: number, redirectToCase: boolean) => {
              await addClipToCase({
                variables: {
                  caseId,
                  value: {
                    cameraId: activeCams[0].id,
                    startTime: vodStart.toISOString(),
                    endTime: vodEnd.toISOString(),
                  },
                },
                onCompleted: (data) => {
                  if (redirectToCase && data.addClipToCase) {
                    navigate(
                      prefixOrgSlug(
                        `/cases/${caseId}?clip=${data.addClipToCase.id}`
                      )
                    );
                  } else {
                    setDialogOpen(false);
                    pushSnackbar(
                      "Successfully saved clip to case",
                      FeedbackType.Success
                    );
                  }
                },
              });
            }}
          />

          {data?.case && (
            <div className="px-2 pt-2 bg-primary rounded-t-lg flex justify-between gap-4 items-center">
              <div className="flex gap-1 items-center">
                <IconButton
                  size="small"
                  color="primary"
                  aria-label="Back to cases"
                  to={prefixOrgSlug(`/cases/${caseId}`)}
                  component={Link}
                >
                  <ChevronLeftIcon className="text-white" />
                </IconButton>
                <Link to={prefixOrgSlug(`/cases/${caseId}`)}>
                  <h3 className="text-white flex justify-between gap-2 items-center font-bold text-base mr-4 shrink">
                    {fitsTablet && (
                      <CaseIcon className="text-white w-[16px] h-auto shrink-0" />
                    )}
                    <span>{data?.case?.title}</span>
                  </h3>
                </Link>
                <Button
                  className="text-white border border-white whitespace-nowrap shrink-0"
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={() => setDialogOpen(true)}
                >
                  {fitsTablet ? "Save Clip to Case" : "Save to Case"}
                </Button>
              </div>
              <IconButton
                size="small"
                color="primary"
                component={QueryParamLink}
                removalParams={["case"]}
              >
                <HighlightOffIcon className="text-white opacity-50" />
              </IconButton>
            </div>
          )}

          <FullScreen className="flex grow flex-col">
            {vodActive ? (
              <VodPlayer
                cameras={activeCams}
                playNextClip={playNextClip}
                playPreviousClip={playPreviousClip}
                startTime={vodStart.toISOString()}
                endTime={vodEnd.toISOString()}
                startPosition={vodPosition}
                overrideMachineProvider={false}
              />
            ) : (
              <div className="flex-center flex-col px-4 h-72 md:h-96 bg-black text-white">
                <EmptyStateIcon className="mb-4" />
                <span className="text-lg md:text-xl text-center max-w-xs">
                  {fitsDesktop
                    ? "Use the timeline to view a clip or select a clip segment below"
                    : "Select a clip segment below"}
                </span>
              </div>
            )}

            {fitsDesktop && (
              <>
                <div
                  className={clsx(
                    "text-white py-[7px] px-4 flex items-center flex-wrap gap-5 bg-black bg-opacity-90",
                    {
                      "rounded-b": !copilotCompatible,
                    }
                  )}
                >
                  {activeCams.length === 1 && (
                    <IfFullscreen>
                      <Typography component="h2" variant="h4">
                        {activeCams[0].name}
                      </Typography>

                      <div className="flex-center gap-1">
                        <HdIndicator />
                        <LocalIndicator />
                      </div>
                    </IfFullscreen>
                  )}

                  <PlayerMetadataLayout
                    wallClock={
                      <>
                        <WallClock
                          timezone={focusedCam.location.timezone}
                          className="sm:!flex-row-reverse sm:items-center !gap-x-4"
                          classes={{
                            date: "!text-[13px] opacity-50 font-normal",
                            time: "text-[#BFBFBF] !text-base",
                            timeContainer: "items-center !gap-x-1",
                            ampm: "text-white opacity-30 !text-[9px]",
                          }}
                        />
                        <Divider
                          orientation="vertical"
                          className="border-[#3E3E3E] h-[10px]"
                        />
                      </>
                    }
                    clipNavigation={
                      <>
                        <ClipNavigation
                          condensed
                          playNextClip={playNextClip}
                          playPreviousClip={playPreviousClip}
                        />
                        <Divider
                          orientation="vertical"
                          className="border-[#3E3E3E] h-[10px]"
                        />
                      </>
                    }
                    zoneSwitch={
                      activeCams.length === 1 && (
                        <ZoneSwitch cameraId={focusedCam.id} showHelp />
                      )
                    }
                    liveButtonOrIndicator={
                      hasPermission("video_live_access") &&
                      activeCams.length === 1 && (
                        <Button
                          variant="contained"
                          color="primary"
                          component={QueryParamLink}
                          size="small"
                          to={prefixOrgSlug(`/live`)}
                          removalParams={[
                            "vod",
                            "range",
                            "zones",
                            "m",
                            "subjects",
                          ]}
                          className="text-white shrink-0 text-[13px] leading-[15px] px-[14px] ml-auto"
                        >
                          Go Live
                        </Button>
                      )
                    }
                  />
                </div>
                {copilotCompatible && <CopilotPlayerSection />}
              </>
            )}

            {!fitsDesktop && (
              <>
                <div className="py-3 px-4 flex flex-col gap-3">
                  <div className="text-center">
                    {activeCams.length === 1 ? (
                      <>
                        <div className="text-xl font-bold">
                          {activeCams[0].name}
                        </div>
                        <div className="text-sm opacity-50">
                          {activeCams[0].location.name}
                        </div>
                      </>
                    ) : (
                      <Typography
                        component="h2"
                        className="font-bold md:text-xl"
                      >
                        Multi-Camera Search
                      </Typography>
                    )}
                  </div>
                  <Divider />
                  <div className="flex items-center">
                    <MobileWallClock timezone={focusedCam.location.timezone} />
                    <div className="grow" />
                    <QueryParamLink
                      to={prefixOrgSlug(`/live`)}
                      removalParams={["vod", "range", "zones", "m", "subjects"]}
                      className="flex gap-1 items-center bg-primary bg-opacity-[0.14] text-primary no-underline py-2 pr-3 pl-2 rounded text-md font-normal whitespace-nowrap border border-[#007CE414]"
                    >
                      Go To Live View
                    </QueryParamLink>
                  </div>
                  <Divider />
                  <div className="[@media(min-width:580px)]:flex items-center">
                    <div className="[@media(max-width:580px)]:mb-2 [@media(max-width:380px)]:w-full">
                      <div className="text-sm text-gray-75 h-[25px]">
                        Clip Length:
                      </div>
                      <ClipNavigation
                        playNextClip={playNextClip}
                        playPreviousClip={playPreviousClip}
                        lightMode
                        mobileDesign
                      />
                    </div>
                    <div className="grow" />
                    {activeCams.length === 1 && (
                      <ZoneSwitch
                        cameraId={focusedCam.id}
                        showHelp
                        mobileDesign
                      />
                    )}
                  </div>
                </div>
              </>
            )}
          </FullScreen>
        </div>
      </CopilotProvider>
    </PlayerBackground>
  );
}

function PlayerBackground({ children }: { children: any }) {
  const { fitsDesktop } = useBreakpoints();
  const copilotCompatible = useIsCopilotCompatible();

  if (!fitsDesktop) {
    return <div className="mb-6">{children}</div>;
  }

  return (
    <Paper
      elevation={6}
      className={clsx("mb-6", {
        "!rounded-b-2xl": copilotCompatible,
      })}
    >
      {children}
    </Paper>
  );
}

function determineNextClip(
  previews: Clip[],
  currentStartTime: string
): Clip | null {
  return previews.reduce((result: Clip | null, candidate) => {
    if (candidate.start <= currentStartTime) return result;
    if (result === null || candidate.start < result.start) return candidate;
    return result;
  }, null);
}

function determinePreviousClip(
  previews: Clip[],
  currentStartTime: string
): Clip | null {
  return previews.reduce((result: Clip | null, candidate) => {
    if (candidate.start >= currentStartTime) return result;
    if (result === null || candidate.start > result.start) return candidate;
    return result;
  }, null);
}
