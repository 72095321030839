import { Route, Routes } from "react-router-dom";

import { AlertCreate } from "@/pages/Alerts/AlertCreate";
import { AlertEdit } from "@/pages/Alerts/AlertEdit";
import { AlertList } from "@/pages/Alerts/AlertList";
import { AlertNotifications } from "@/pages/Alerts/AlertNotifications";

export const AlertsPage = () => {
  return (
    <Routes>
      <Route index element={<AlertNotifications />} />
      <Route path="configure">
        <Route index element={<AlertList />} />
        <Route path="create" element={<AlertCreate />} />
        <Route path=":id" element={<AlertEdit />} />
      </Route>
    </Routes>
  );
};
