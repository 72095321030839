// import { SearchOutlined } from "@mui/icons-material";
// import { Divider, IconButton } from "@mui/material";
import { uniq, uniqBy } from "lodash/fp";
import { useMemo } from "react";

// import { StringParam, useQueryParam } from "use-query-params";
// import { useBreakpoints } from "@/util/useBreakpoints";
import { FilterBar } from "@/components/Filter/FilterBar";

// import { FilterBarControls } from "@/components/Filter/FilterBarControls";
// import { SearchBox } from "@/components/SearchBox";
import {
  IntegrationSourcesQuery,
  IntegrationTypeKey,
} from "@/generated-models";

import { useCurrentTypeId } from "../../hooks";
import { IntegrationsDetailsSummaryBar } from "../IntegrationsDetailsSummaryBar";

interface IntegrationDetailsDeviceSummaryBarProps {
  loading?: boolean;
  sources: IntegrationSourcesQuery["integrationSources"];
}

export function IntegrationDetailsDeviceSummaryBar({
  loading,
  sources,
}: IntegrationDetailsDeviceSummaryBarProps) {
  const currentType = useCurrentTypeId();

  const isIoBoard = currentType === IntegrationTypeKey.IoBoard;

  // const { fitsDesktop } = useBreakpoints();
  // const [showSearch, setShowSearch] = useState(false);
  // const [searchInputParam, setSearchInputParam] = useQueryParam(
  //   "search",
  //   StringParam
  // );

  const tags = useMemo(
    () =>
      uniq(sources?.flatMap((d) => d.tags)).map((t) => ({
        label: t,
        value: t,
      })),
    [sources]
  );

  const filters = useMemo(
    () => [
      {
        placeholder: "Locations",
        filterName: "locations",
        label: "Locations",
        options: uniqBy(
          "value",
          sources?.map((d) => ({
            label: d.standardMeta.siteName,
            value: String(d.standardMeta.siteName),
          })) || []
        ),
      },
      {
        placeholder: "Tags",
        filterName: "tags",
        label: "Tags",
        options: tags,
      },
    ],
    [sources, tags]
  );

  return (
    <div className="flex flex-col justify-center">
      <IntegrationsDetailsSummaryBar
        loading={loading}
        count={sources.length || 0}
        label={isIoBoard ? "Input" : "Device"}
      >
        {/* Hiding filters on integrations */}
        {/* <div className="flex items-center gap-3">
          {fitsDesktop && (
            <FilterBarControls label="Filter:" filters={filters} />
          )}
          <Divider
            className="h-[25px] hidden md:block"
            orientation="vertical"
          />
          {showSearch ? (
            <SearchBox
              className="min-w-[250px] w-full py-1"
              input={searchInputParam || ""}
              setInput={setSearchInputParam}
            />
          ) : (
            <IconButton
              onClick={() => {
                setShowSearch(true);
              }}
              className="bg-[#DDEFFF]"
              color="primary"
            >
              <SearchOutlined />
            </IconButton>
          )}
        </div> */}
      </IntegrationsDetailsSummaryBar>
      {!loading && <FilterBar className="rounded-lg mt-5" filters={filters} />}
    </div>
  );
}
