import { FormControl, InputLabel, OutlinedInput } from "@mui/material";
import { useState } from "react";

interface IntegrationCreateFormNameStepProps {
  onChange: (value: string) => void;
  nextStep: () => Promise<void>;
  disabled: boolean;
  integrationName: string;
}

export default function IntegrationCreateFormNameStep({
  onChange,
  nextStep,
  disabled,
  integrationName,
}: IntegrationCreateFormNameStepProps) {
  const [value, setValue] = useState(integrationName);
  return (
    <div>
      <FormControl>
        <InputLabel
          shrink
          classes={{
            shrink: "-ml-[14px] -mt-1",
          }}
          className="text-[#757575] text-xs leading-[14px] capitalize"
          htmlFor="integration-name-label"
        >
          Name
        </InputLabel>
        <OutlinedInput
          size="small"
          placeholder="Enter a name"
          label="Name"
          required
          notched={false}
          className="rounded-lg"
          value={value}
          onChange={(e) => {
            const newValue = e.target.value;
            setValue(newValue);
            onChange(newValue);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter" && !disabled) {
              nextStep();
            }
          }}
        />
      </FormControl>
    </div>
  );
}
