import gql from "graphql-tag";
import { NumberParam, useQueryParam } from "use-query-params";

import { concat } from "@/util/apolloCache";

import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { useFirstActiveCamId } from "@/components/View/sharedViewHooks";

import {
  AnnotationLabelState,
  CreateAnnotationLabelInput,
  EditAnnotationLabelInput,
  useAiAnnotationsQuery,
  useCreateAiAnnotationLabelMutation,
  useEditAiAnnotionLabelMutation,
} from "@/generated-models";

export const CONFIRMED_LABEL_STATES = [
  AnnotationLabelState.Confirmed,
  AnnotationLabelState.Pinned,
];

export function useAiAnnotations() {
  const cameraId = useFirstActiveCamId();
  return useAiAnnotationsQuery({
    variables: {
      cameraId,
      labelStates: [
        AnnotationLabelState.Confirmed,
        AnnotationLabelState.Pinned,
      ],
    },
  });
}

export function useCurrentAnnotation() {
  const query = useAiAnnotations();
  const [annotation] = useQueryParam("annotation", NumberParam);

  return {
    ...query,
    data: query.data?.aiAnnotations.find((a) => a.id === annotation),
  };
}

export function useCreateAnnotationLabel(annotationId: number) {
  const { pushSnackbar } = useFeedback();
  const [createLabel, { loading }] = useCreateAiAnnotationLabelMutation({
    update: (cache, { data }) => {
      if (!data) return;
      cache.modify({
        id: `AnnotationLabelItem:${data.createAiAnnotationLabel.id}`,
        fields: {
          id: () => data.createAiAnnotationLabel.id,
          labelState: () => data.createAiAnnotationLabel.labelState,
          shape: () => data.createAiAnnotationLabel.shape || [],
          title: () => data.createAiAnnotationLabel.title || "",
        },
      });
      cache.modify({
        id: `UniversalSearchResult:${annotationId}`,
        fields: {
          items: concat({
            __ref: `AnnotationLabelItem:${data.createAiAnnotationLabel.id}`,
          }),
        },
      });
    },
    onError: (e) => {
      console.error(e);
      pushSnackbar(
        "Unable to create the specified label, please try again later",
        FeedbackType.Error
      );
    },
  });

  return {
    loading,
    create: (input: Omit<CreateAnnotationLabelInput, "id">) => {
      return createLabel({
        variables: {
          input: { ...input, id: annotationId },
        },
      });
    },
  };
}

export function useEditAnnotationLabel() {
  const { pushSnackbar } = useFeedback();
  const [editLabel, { loading }] = useEditAiAnnotionLabelMutation({
    update: (cache, { data }) => {
      if (!data) return;
      cache.modify({
        id: `AnnotationLabelItem:${data.editAiAnnotionLabel.id}`,
        fields: {
          labelState: () => data.editAiAnnotionLabel.labelState,
          shape: () => data.editAiAnnotionLabel.shape || [],
          title: () => data.editAiAnnotionLabel.title || "",
        },
      });
    },
    onError: (e) => {
      console.error(e);
      pushSnackbar(
        "Unable to update the specified label, please try again later",
        FeedbackType.Error
      );
    },
  });

  return {
    loading,
    edit: (id: number, input: EditAnnotationLabelInput) => {
      return editLabel({
        variables: {
          id,
          input,
        },
      });
    },
  };
}

gql`
  query aiAnnotations($cameraId: Int!, $labelStates: [AnnotationLabelState!]) {
    aiAnnotations(cameraId: $cameraId, labelStates: $labelStates) {
      id
      cameraId
      timestamp
      url
    }
  }
`;

gql`
  fragment AnnotationLabelItemFragment on AnnotationLabelItem {
    id
    shape
    title
    labelState
  }
`;

gql`
  mutation createAiAnnotationLabel($input: CreateAnnotationLabelInput!) {
    createAiAnnotationLabel(input: $input) {
      ...AnnotationLabelItemFragment
    }
  }
`;

gql`
  mutation editAiAnnotionLabel($id: Int!, $input: EditAnnotationLabelInput!) {
    editAiAnnotionLabel(id: $id, input: $input) {
      ...AnnotationLabelItemFragment
    }
  }
`;

gql`
  mutation deleteAiAnnotationLabel($id: Int!) {
    deleteAiAnnotationLabel(id: $id) {
      message
    }
  }
`;
