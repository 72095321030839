import { useParams } from "react-router-dom";

import { useMe } from "@/components/Auth";

export function useDetailsRouteInfo() {
  const me = useMe();
  const { id } = useParams<{ id: string }>();
  if (id === undefined) {
    // Should never happen
    throw new Error("Details route must be given an id");
  }
  const formMode = id === "new" ? "create" : "edit";
  if (id === "new") {
    return { id: null, formMode: "create" } as const;
  }
  if (id === "me" && me?.orgUserId) {
    return { id: me.orgUserId, formMode: "edit" } as const;
  }
  return { id: Number(id), formMode } as const;
}
