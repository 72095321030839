import { utcToZonedTime } from "date-fns-tz";
import { capitalize } from "lodash";

import { dateTimeFormatSecs } from "@/util/date";
import { formatDurationSecs } from "@/util/formatDurationSecs";

import {
  Camera,
  IntelligenceDashboardContentQuery,
  IntelligenceDashboardType,
} from "@/generated-models";

import { getObjectLabel } from "../../utils";

export type IdleExportContent = IntelligenceDashboardContentQuery["intelligenceDashboard"];

export function idleCsvFormatter(
  data: NonNullable<IdleExportContent>,
  cameras: Pick<Camera, "id">[],
  timezone: string,
  thresholdString: string,
  objectTypes: string[] | null | undefined
) {
  const {
    averageIdleTimeSeconds,
    averageNonzeroIdleTimeSeconds,
    bucketCounts,
    idleCount,
    maxIdleTimeSeconds,
    totalCount,
    entities,
  } = data.metrics.results!;

  const idlePercent = totalCount
    ? `${totalCount && Math.round((idleCount / totalCount) * 1000) / 10}%`
    : "";

  const objectLabel = capitalize(
    getObjectLabel(objectTypes, IntelligenceDashboardType.Idle)
  );

  const objectLabelPlural = capitalize(
    getObjectLabel(objectTypes, IntelligenceDashboardType.Idle, true)
  );

  const idleCounts = bucketCounts.map(
    ({ bucketMs, idleCount, totalCount }) =>
      `${utcToZonedTime(
        new Date(bucketMs),
        timezone
      )},${idleCount},${totalCount}`
  );

  const entitiesList = [...entities]
    .sort((a, b) => b.idleSum - a.idleSum)
    .map(({ queueNum, idleSum, firstMs, lastMs }) =>
      [
        queueNum,
        formatDurationSecs(idleSum as number),
        `"${dateTimeFormatSecs.format(utcToZonedTime(firstMs, timezone))}"`,
        `"${dateTimeFormatSecs.format(utcToZonedTime(lastMs, timezone))}"`,
        `${window.location.origin}/search?cams=${cameras[0].id}&vod=${new Date(
          firstMs
        ).toISOString()}|${new Date(lastMs).toISOString()}&subjects=${(
          objectTypes || []
        ).join("_")}`,
      ].join(",")
    );

  return [
    `Total ${objectLabelPlural},${totalCount}`,
    `${objectLabelPlural} Idle ${thresholdString},${idleCount}`,
    `Percent Idle ${thresholdString},${idlePercent}`,
    `Average Idle Time,${formatDurationSecs(averageIdleTimeSeconds)}`,
    `Longest Idle Time,${formatDurationSecs(maxIdleTimeSeconds)}`,
    averageNonzeroIdleTimeSeconds !== null
      ? `Average Stationary Idle Time,${formatDurationSecs(
          averageNonzeroIdleTimeSeconds || 0
        )}`
      : "",
    "",
    `Time,Idle ${objectLabelPlural},Total ${objectLabelPlural}`,
    ...idleCounts,
    "",
    `${objectLabel} Queue,Idle Time Length,Entered,Exited,Video`,
    ...entitiesList,
  ];
}
