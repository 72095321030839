import { Hidden, Typography } from "@mui/material";
import { useField } from "formik";

import { formatIsoTimeMinutes } from "@/util/date";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  IntelligenceDashboardType,
  usePage_IntelligenceQuery,
} from "@/generated-models";

import {
  ComparativeDashboardChart,
  ComparativePresenceChart,
} from "../../ComparativeDashboard/Content/Chart/ComparativeDashboardChart";
import { ComparativeDashboardChartFetcher } from "../../ComparativeDashboard/Content/Chart/ComparativeDashboardChartFetcher";
import { ComparativeDashboardContentChips } from "../../ComparativeDashboard/Content/ComparativeDashboardContentChips";
import { ComparativeDashboardContentTabs } from "../../ComparativeDashboard/Content/ComparativeDashboardContentTabs";
import { ComparativeDashboardDatagrid } from "../../ComparativeDashboard/Content/ComparativeDashboardDatagrid";

export function ComparativePreview() {
  const [, { value }] = useField<number[]>({
    name: "referenceDashIds",
  });

  const [, { value: subtype }] = useField<string>({
    name: "subtype",
  });

  const [, { value: entityCount }] = useField<number>("entityCount");
  const [, { value: thresholdSeconds }] = useField<number>("thresholdSeconds");
  const [, { value: objectTypes }] = useField<string[]>("objectTypes");

  const [, { value: daysOfWeek }] = useField<string[]>({
    name: "daysOfWeek",
  });

  const [, { value: timeRange }] = useField<[number, number]>({
    name: "timeRange",
  });

  const { data } = usePage_IntelligenceQuery({
    ...refetchOnMountPolicy,
  });

  const isPresence = subtype === IntelligenceDashboardType.Presence;
  const refDashes =
    data?.intelligenceDashboards?.filter((d) => value.includes(d.id)) || [];

  const baseProps = {
    type: subtype as IntelligenceDashboardType,
    objectTypes,
    thresholdSeconds: thresholdSeconds,
    referenceDashboards: refDashes,
  };

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-4">
        <Typography className="text-2xl leading-6 font-light text-black">
          Insight Preview
        </Typography>
        <ComparativeDashboardContentTabs
          type={baseProps.type}
          objectTypes={baseProps.objectTypes}
          thresholdSeconds={baseProps.thresholdSeconds}
        />
      </div>
      {isPresence ? (
        <ComparativePresenceChart referenceDashboards={refDashes} />
      ) : (
        <ComparativeDashboardChart referenceDashboards={refDashes} />
      )}
      <ComparativeDashboardChartFetcher
        comparativeId="preview"
        dashboards={refDashes.map((v) => ({
          id: v.id,
          thresholdSeconds,
          entityCount,
          daysOfWeek: daysOfWeek.map((d) => Number(d)),
          startTime: formatIsoTimeMinutes(timeRange[0]),
          endTime: formatIsoTimeMinutes(timeRange[1]),
          timezone: v.cameras[0]?.location?.timezone || "",
        }))}
        type={subtype as IntelligenceDashboardType}
      />
      <ComparativeDashboardContentChips {...baseProps} />
      <Hidden smDown>
        <ComparativeDashboardDatagrid {...baseProps} />
      </Hidden>
    </div>
  );
}
