import gql from "graphql-tag";
import { useInterval } from "react-use";

import { usePlayingState } from "@/components/Player/playerMachine";

import { useAuditLiveViewActivityMutation } from "@/generated-models";

/**
 * @deprecated only used for legacy cameraActivity
 */
export function LiveViewTracker({ cameraId }: { cameraId: number }) {
  const playingState = usePlayingState();
  const [audit] = useAuditLiveViewActivityMutation({ variables: { cameraId } });

  useInterval(
    () => audit().catch(console.error),
    playingState === "playing" ? 10000 : null
  );
  return null;
}

gql`
  mutation auditLiveViewActivity($cameraId: Int!) {
    auditLiveViewActivity(cameraId: $cameraId)
  }
`;
