import { Point } from "./getSectorsForPolygon";

function getMidpoint(line: Point[]): Point {
  const [{ x: x1, y: y1 }, { x: x2, y: y2 }] = line;

  return { x: (x2 + x1) / 2, y: (y2 + y1) / 2 };
}

function getSlopeOfLine(line: Point[]) {
  const [{ x: x1, y: y1 }, { x: x2, y: y2 }] = line;

  return (y2 - y1) / (x2 - x1);
}

// Find y using line equation
function getYgivenX(x: number, startingPoint: Point, slope: number): number {
  const b = startingPoint.y - slope * startingPoint.x;
  return slope * x + b;
}

function getPointsOnLineCertainDistanceAway(
  startingPoint: Point,
  slope: number,
  distance: number
): { plus: Point; minus: Point } {
  const { x: x0 } = startingPoint;

  // Finds point on line a certain distance away from point https://tinyurl.com/ycyzdvew
  const plusX = x0 + distance / Math.sqrt(1 + slope ** 2);
  const plusY = getYgivenX(plusX, startingPoint, slope);

  const minusX = x0 - distance / Math.sqrt(1 + slope ** 2);
  const minusY = getYgivenX(minusX, startingPoint, slope);

  return {
    plus: { x: plusX, y: plusY },
    minus: { x: minusX, y: minusY },
  };
}

// Get In and Out Circle Points based on line
export function getCirclePoints(line: Point[]) {
  const circleDistance = 50;
  const arrowDistance = circleDistance / 3;
  const midpoint = getMidpoint(line);
  const lineSlope = getSlopeOfLine(line);
  const lineVector = getLineVector(line);
  const signOfSlope = Math.sign(lineSlope); // returns +1 if slope is positive, -1 if slope is negative.

  // Special case if line is horizontal place circles above/below midpoint
  if (lineSlope === 0) {
    return {
      in: { ...midpoint, y: midpoint.y - circleDistance * lineVector },
      out: { ...midpoint, y: midpoint.y + circleDistance * lineVector },
      inarrow: { ...midpoint, y: midpoint.y - arrowDistance * lineVector },
      outarrow: { ...midpoint, y: midpoint.y + arrowDistance * lineVector },
    };
  } else if (lineSlope === Infinity) {
    // Special case if line is horizontal place circles left/right midpoint
    return {
      in: { ...midpoint, x: midpoint.x - circleDistance * lineVector },
      out: { ...midpoint, x: midpoint.x + circleDistance * lineVector },
      inarrow: { ...midpoint, x: midpoint.x - arrowDistance * lineVector },
      outarrow: { ...midpoint, x: midpoint.x + arrowDistance * lineVector },
    };
  } else {
    // Find points circle distance away from midpoint on perpendicular line equation
    const slope = -1 / lineSlope;
    const circlePoints = getPointsOnLineCertainDistanceAway(
      midpoint,
      slope,
      circleDistance
    );

    const arrowPoints = getPointsOnLineCertainDistanceAway(
      midpoint,
      slope,
      arrowDistance
    );

    if (signOfSlope * lineVector > 0) {
      return {
        in: circlePoints.minus,
        out: circlePoints.plus,
        inarrow: arrowPoints.minus,
        outarrow: arrowPoints.plus,
      };
    }
    return {
      in: circlePoints.plus,
      out: circlePoints.minus,
      inarrow: arrowPoints.plus,
      outarrow: arrowPoints.minus,
    };
  }
}

// Bounds detection based on svg bounds
export function isLineOutOfBounds(
  shape: Point[],
  measurements: DOMRectReadOnly
): Boolean {
  return shape.some(
    (p) =>
      p.x > measurements.width ||
      p.y > measurements.height ||
      p.x < 0 ||
      p.y < 0
  );
}

export function getTransformedShape(
  line: Point[],
  measurements: DOMRectReadOnly
) {
  return line.map((point) => ({
    x: (measurements.width / 100) * point.x,
    y: (measurements.height / 100) * point.y,
  }));
}

export function getNormalizedShape(
  line: Point[],
  measurements: DOMRectReadOnly
) {
  return line.map((point) => ({
    x: (100 / measurements.width) * point.x,
    y: (100 / measurements.height) * point.y,
  }));
}

export function getCursorPoint(
  evt: React.MouseEvent<SVGSVGElement, MouseEvent>
) {
  const pt = evt.currentTarget.createSVGPoint();
  pt.x = evt.clientX;
  pt.y = evt.clientY;
  const cursorPoint = pt.matrixTransform(
    evt.currentTarget.getScreenCTM()?.inverse()
  );
  return { x: cursorPoint.x, y: cursorPoint.y };
}

export function getTouchPoint(evt: React.TouchEvent<SVGSVGElement>) {
  const touch = evt.touches[0];
  const pt = evt.currentTarget.createSVGPoint();
  pt.x = touch.clientX;
  pt.y = touch.clientY;
  const touchPoint = pt.matrixTransform(
    evt.currentTarget.getScreenCTM()?.inverse()
  );
  return { x: touchPoint.x, y: touchPoint.y };
}

export function swapPoints([first, second]: Point[]): Point[] {
  return [second, first];
}

// Line direction: Left to right = 1, right to left = -1
function getLineVector(line: Point[]): number {
  const [{ x: x1, y: y1 }, { x: x2, y: y2 }] = line;
  const vector = { x: x2 - x1, y: y2 - y1 };

  if (vector.x > 0 || (vector.x === 0 && vector.y < 0)) {
    return 1;
  }

  if (vector.x < 0 || (vector.x === 0 && vector.y > 0)) {
    return -1;
  }

  return 0;
}
