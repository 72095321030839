import { Settings } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Typography from "@mui/material/Typography";
import { useState, memo } from "react";

import { useMe } from "@/components/Auth";
import { IOSSwitch } from "@/components/IOSSwitch";

import { useSetFlagsMutation } from "@/generated-models";

export const AlertsSettingsEdit = memo(function AlertsSettingsEdit() {
  const me = useMe();
  const [setFlags, { loading: settingFlags }] = useSetFlagsMutation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const flags = me?.profile?.flags;

  const [showRealTimeAlerts, setShowRealTimeAlerts] = useState(
    Boolean(flags?.realTimeAlerts)
  );
  const [showAudioAlerts, setShowAudioAlerts] = useState(
    Boolean(flags?.audioAlerts)
  );
  /* TODO: Finish this. This will show admins customer only alerts and should be visible to non-admin users. */
  // const [
  //   showCustomerGeneratedAlerts,
  //   setShowCustomerGeneratedAlerts,
  // ] = useState(Boolean(flags?.customerAlertsOnly));

  if (!me) return null;

  return (
    <div className="flex items-center justify-center">
      <Button
        id="alert-settings-menu-button"
        aria-controls={open ? "alert-settings-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        startIcon={<Settings color="primary" />}
        color="primary"
        onClick={handleClick}
      >
        Settings
      </Button>
      <Menu
        id="alert-settings-menu"
        aria-labelledby="alert-settings-menu-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className="flex flex-col p-4 gap-3">
          <div className="flex justify-between">
            <Typography variant="h4">Alert settings </Typography>
            <CloseIcon onClick={handleClose} className="w-4 cursor-pointer" />
          </div>
          <div>
            <IOSSwitch
              disabled={settingFlags}
              label="Real-time Alerts"
              checked={showRealTimeAlerts}
              onChange={(event) => {
                setShowRealTimeAlerts(event.target.checked);
                setFlags({
                  variables: {
                    flags: {
                      realTimeAlerts: event.target.checked,
                    },
                  },
                });
              }}
            />
            <Typography className="leading-5">
              Displays in-app, real-time alert messages.
            </Typography>
          </div>
          <div>
            <IOSSwitch
              disabled={settingFlags}
              label="Audio Alerts"
              checked={showAudioAlerts}
              onChange={(event) => {
                const showAudioAlertsValue = event.target.checked;
                setShowAudioAlerts(showAudioAlertsValue);
                setFlags({
                  variables: {
                    flags: {
                      audioAlerts: showAudioAlertsValue,
                    },
                  },
                });
                // If audio alerts are toggled
                // we need to toggle real time alerts too.
                if (showAudioAlertsValue) {
                  setShowRealTimeAlerts(true);
                  setFlags({
                    variables: {
                      flags: {
                        realTimeAlerts: true,
                      },
                    },
                  });
                }
              }}
            />
            <Typography className="leading-5">
              Plays audio alert with real-time alert notification.
            </Typography>
          </div>
          {/* TODO: Finish this. This will show admins customer only alerts and should be visible to non-admin users. */}
          {/* <Divider className="bg-[#DBDBDB]" /> */}
          {/* <div>
            <IOSSwitch
              disabled={settingFlags}
              label="Customer Alerts Only"
              checked={showCustomerGeneratedAlerts}
              onChange={(event) => {
                setShowCustomerGeneratedAlerts(event.target.checked);
                setFlags({
                  variables: {
                    flags: {
                      customerAlertsOnly: event.target.checked,
                    },
                  },
                });
              }}
            />
            <Typography className="leading-5">
              Display customer generated alerts only. Spot AI Support alerts are
              hidden.
            </Typography>
          </div> */}
        </div>
      </Menu>
    </div>
  );
});
