import { inspect } from "@xstate/inspect";
import { useInterpret, useSelector } from "@xstate/react";
import { createContext, PropsWithChildren, useContext } from "react";
import {
  ActionObject,
  assign,
  interpret,
  Machine,
  MachineConfig,
  send,
  Subscribable,
} from "xstate";

import { isLocalEnv } from "@/environment";

if (localStorage.xstateDev === "true") {
  inspect({
    iframe: false,
    url: "https://statecharts.io/inspect",
  });
}

type MachineContext = typeof initialContext;
interface MachineSchema {
  states: {
    selectingStream: SelectingStreamSchema;
    playback: {
      states: {
        stillOverlay: StillOverlaySchema;
        playingState: PlayingStateSchema;
      };
    };
  };
}
type MachineEvent =
  | SourcesChangedEvent
  | StillReceivedEvent
  | SetVideoDimensionsEvent
  | SetVisualQualityEvent
  | {
      type: PlayerMachineEvent;
    };

const initialContext = {
  source: null as null | undefined | string,
  sources: {
    local: "",
    tunnel: "",
    webRTC: null as null | string,
  },
  videoDimensions: {
    width: 16,
    height: 9,
  },
  visualQuality: 0, // ~= height of video in px
  stillTimestamp: null as null | Date,
};
export enum PlayerMachineEvent {
  SOURCES_CHANGED = "SOURCES_CHANGED",
  SOURCE_CHANGED = "SOURCE_CHANGED",

  ALL_SOURCES_FAILED = "ALL_SOURCES_FAILED",
  LOCAL_SOURCE_REACHABLE = "LOCAL_SOURCE_REACHABLE",
  TUNNEL_SOURCE_REACHABLE = "TUNNEL_SOURCE_REACHABLE",
  WEBRTC_SOURCE_REACHABLE = "WEBRTC_SOURCE_REACHABLE",

  STILL_RECEIVED = "STILL_RECEIVED",
  PAUSED = "PAUSED",
  IDLE = "IDLE",
  COMPLETE = "COMPLETE",
  PLAYING = "PLAYING",
  BUFFERING = "BUFFERING",
  STALLED = "STALLED",

  MEDIA_ERROR = "MEDIA_ERROR",

  // RTC_CONNECTED = "RTC_CONNECTED",
  // RTC_DISCONNECTED = "RTC_DISCONNECTED",

  SET_VIDEO_DIMENSIONS = "SET_VIDEO_DIMENSIONS",
  SET_VISUAL_QUALITY = "SET_VISUAL_QUALITY",

  // TOGGLE_ZOOMING = "TOGGLE_ZOOMING",
}

interface PlayingStateSchema {
  states: {
    playing: {};
    paused: {};
    idle: {};
    complete: {};
    buffering: {};
    stalled: {};
    error: {};
  };
}

export type PlayingStates = keyof PlayingStateSchema["states"];

const playingState: MachineConfig<
  MachineContext,
  PlayingStateSchema,
  MachineEvent
> = {
  initial: "idle",
  states: {
    playing: {
      on: {
        PAUSED: "paused",
        BUFFERING: "buffering",
        IDLE: "idle",
        COMPLETE: "complete",
        MEDIA_ERROR: "error",
      },
    },
    paused: {
      on: {
        BUFFERING: "buffering",
        IDLE: "idle",
        PLAYING: "playing",
        COMPLETE: "complete",
      },
    },
    idle: {
      on: {
        PAUSED: "paused",
        BUFFERING: "buffering",
        PLAYING: "playing",
        COMPLETE: "complete",
      },
    },
    complete: {
      on: {
        PLAYING: "playing",
        BUFFERING: "buffering",
      },
    },
    buffering: {
      on: {
        PAUSED: "paused",
        IDLE: "idle",
        PLAYING: "playing",
        STALLED: "stalled",
      },
      after: {
        2000: { target: "stalled" },
      },
    },
    stalled: {
      on: {
        PAUSED: "paused",
        IDLE: "idle",
        PLAYING: "playing",
      },
      entry: send(PlayerMachineEvent.STALLED),
    },
    error: {
      on: {
        PAUSED: "paused",
        IDLE: "idle",
        PLAYING: "playing",
      },
    },
  },
};

interface StillOverlaySchema {
  states: {
    hidden: {};
    shown: {};
  };
}
interface StillReceivedEvent {
  type: PlayerMachineEvent.STILL_RECEIVED;
  stillTimestamp: Date;
}
const stillOverlay: MachineConfig<
  MachineContext,
  StillOverlaySchema,
  MachineEvent
> = {
  initial: "shown",
  on: {
    STILL_RECEIVED: {
      actions: assign({
        stillTimestamp: (_, event: StillReceivedEvent) => event.stillTimestamp,
      }),
    },
  },
  states: {
    hidden: {
      on: { SOURCES_CHANGED: "shown" },
    },
    shown: {
      on: { PLAYING: "hidden" },
    },
  },
};

// Video Quality
interface SetVideoDimensionsEvent {
  type: PlayerMachineEvent.SET_VIDEO_DIMENSIONS;
  dimensions: { width: number; height: number };
}

export function useVideoDimensions() {
  return usePlayerSelector((state) => state.context.videoDimensions);
}

interface SetVisualQualityEvent {
  type: PlayerMachineEvent.SET_VISUAL_QUALITY;
  value: number;
}
export function useVisualQuality() {
  return usePlayerSelector((state) => state.context.visualQuality);
}
export function useIsHd() {
  return useVisualQuality() >= 720;
}

interface SelectingStreamSchema {
  states: {
    loading: {};
    genericError: {};
    local: {};
    tunnel: {};
    webRTC: {};
  };
}
interface SourcesChangedEvent {
  type: PlayerMachineEvent.SOURCES_CHANGED;
  sources: {
    tunnel: string;
    local?: string | null;
    webRTC?: string | null;
  };
}
const selectingStream: MachineConfig<
  MachineContext,
  SelectingStreamSchema,
  MachineEvent
> = {
  initial: "loading" as const,
  on: {
    SOURCES_CHANGED: {
      target: "selectingStream",
      cond: (_, event: any) =>
        Boolean(
          event.sources &&
            event.sources.hasOwnProperty("tunnel") &&
            event.sources.hasOwnProperty("local")
        ),
      actions: assign<MachineContext, SourcesChangedEvent>({
        source: () => null,
        sources: (_, event) => event.sources as any,
      }) as ActionObject<MachineContext, MachineEvent>,
    },
  },
  states: {
    loading: {
      entry: send(PlayerMachineEvent.SOURCE_CHANGED),
      on: {
        LOCAL_SOURCE_REACHABLE: "local",
        TUNNEL_SOURCE_REACHABLE: "tunnel",
        WEBRTC_SOURCE_REACHABLE: "webRTC",
        ALL_SOURCES_FAILED: "genericError",
      },
    },
    genericError: {
      on: {
        LOCAL_SOURCE_REACHABLE: "local",
        TUNNEL_SOURCE_REACHABLE: "tunnel",
        WEBRTC_SOURCE_REACHABLE: "webRTC",
      },
    },
    // notFoundError: {
    // todo
    // },
    local: {
      type: "final",
      entry: [
        send(PlayerMachineEvent.SOURCE_CHANGED),
        assign({
          source: (context) => context.sources.local,
        }),
      ],
    },
    tunnel: {
      entry: [
        send(PlayerMachineEvent.SOURCE_CHANGED),
        assign({
          source: (context) => context.sources.tunnel,
        }),
      ],
      on: {
        LOCAL_SOURCE_REACHABLE: {
          target: "local",
          actions: assign({
            source: (context) => context.sources.local,
          }),
        },
      },
    },
    webRTC: {
      entry: [
        send(PlayerMachineEvent.SOURCE_CHANGED),
        assign({
          source: (context) => context.sources.webRTC,
        }),
      ],
    },
  },
};

export const playerMachine = Machine<
  MachineContext,
  MachineSchema,
  MachineEvent
>({
  id: "player",
  initial: "selectingStream",
  type: "parallel",
  context: initialContext,
  on: {
    SET_VIDEO_DIMENSIONS: {
      actions: assign({
        videoDimensions: (_, event: SetVideoDimensionsEvent) =>
          event.dimensions,
      }),
    },
    SET_VISUAL_QUALITY: {
      actions: assign({
        visualQuality: (_, event: SetVisualQualityEvent) => event.value,
      }),
    },
  },
  states: {
    selectingStream,
    playback: {
      type: "parallel",
      states: {
        playingState,
        stillOverlay,
      },
      on: {
        SOURCE_CHANGED: {
          target: "playback",
          cond: (context) => !context.source,
        },
      },
    },
  },
});

// Should never be used, except for type inference and checking that a parent
// provider exists with a machine _other_ than this default one.
const defaultMachine = interpret(playerMachine);
const PlayerMachineContext = createContext(defaultMachine);

export type PlayerMachineProviderProps = PropsWithChildren<{
  override?: boolean;
}>;
export function PlayerMachineProvider({
  override,
  ...props
}: PlayerMachineProviderProps) {
  const ancestorStore = useContext(PlayerMachineContext);
  if (override === false && ancestorStore !== defaultMachine) {
    return <>{props.children}</>;
  }
  return <PlayerMachineProviderInner {...props} />;
}

function PlayerMachineProviderInner({ children }: PropsWithChildren<{}>) {
  const service = useInterpret(playerMachine, {
    devTools: localStorage.xstateDev === "true",
  });
  return (
    <PlayerMachineContext.Provider value={service}>
      {children}
    </PlayerMachineContext.Provider>
  );
}

export function usePlayerService() {
  const context = useContext(PlayerMachineContext);
  if (context === defaultMachine) {
    if (isLocalEnv) {
      throw new Error("No player machine context found");
    } else {
      console.warn("No player machine context found");
    }
  }
  return context;
}

export function usePlayerSelector<
  T,
  TActor = typeof service,
  TEmitted = TActor extends Subscribable<infer Emitted> ? Emitted : never
>(selector: (emitted: TEmitted) => T) {
  const service = usePlayerService();
  return useSelector(service, selector);
}

export function useClipCompleted() {
  return usePlayerSelector((s) => s.matches("playback.playingState.complete"));
}

export function useSources() {
  return usePlayerSelector((state) => state.context.sources);
}

/**
 * Allows passing available sources to check against. We have a race condition
 * where a video player has sources on initial render, but the SOURCES_CHANGED
 * event can only be dispatched after the player has been mounted.
 *
 * Passing essentially a whitelist of sources allows us to prevent passing a
 * stale source.
 *
 * If no whitelist is passed, we'll just use the current source.
 */
export function useSource(sources?: {
  tunnel: string;
  local?: string | null;
  webRTC?: string | null;
}) {
  const sourceFromMachine = usePlayerSelector((state) => state.context.source);

  if (!sources) return sourceFromMachine;
  // Stale source, should be fixed after the `SOURCES_CHANGED` event
  // has been emitted and processed by the player machine.
  if (!Object.values(sources).includes(sourceFromMachine ?? null)) return null;

  return sourceFromMachine;
}

export function usePlaylistReachable() {
  return usePlayerSelector(
    (state) => !state.matches("selectingStream.genericError")
  );
}

export function usePlayingState() {
  return usePlayerSelector(
    (state) => (state.value as any)?.playback?.playingState as PlayingStates
  );
}

export function useStillOverlayShown(states = ["playback.stillOverlay.shown"]) {
  return usePlayerSelector((state) => states.some((s) => state.matches(s)));
}

export function useMatchesPlayingState(s: PlayingStates) {
  return usePlayerSelector((state) =>
    state.matches({
      playback: { playingState: s },
    })
  );
}

export function useStillTimestamp() {
  return usePlayerSelector((state) => state.context.stillTimestamp);
}
