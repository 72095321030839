import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import Check from "@mui/icons-material/Check";
import {
  adaptV4Theme,
  createTheme,
  IconButton,
  Input,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { StaticDatePicker } from "@mui/x-date-pickers";
import clsx from "clsx";
import { addHours, addMinutes, format, startOfDay } from "date-fns/fp";
import { useCombobox } from "downshift";
import { FieldProps } from "formik";
import { padCharsEnd } from "lodash/fp";
import { useEffect, useMemo, useRef } from "react";
import { makeStyles } from "tss-react/mui";

import { useBreakpoints } from "@/util/useBreakpoints";

import { theme } from "@/layout/theme";

export const datePickerTheme = createTheme(
  adaptV4Theme({
    overrides: {
      MuiInputBase: {
        root: {
          color: "white",
          height: "100%",
          width: 185,
          padding: "0 12px",
          "&:focus-within": {
            outline: `2px solid ${theme.palette.secondary.main}`,
          },
          fontWeight: 500,
        },
      },
      MuiInput: {
        underline: {
          "&:after, &:before": {
            display: "none",
          },
        },
      },
    },
  })
);

const MENU_ITEM_HEIGHT = 36;

const useStyles = makeStyles()(() => ({
  timePickerMenu: {
    maxHeight: MENU_ITEM_HEIGHT * 7,
    overflow: "auto",
    zIndex: 10,

    "&::-webkit-scrollbar": {
      background: "transparent",
      height: 8,
      width: 8,
    },
    "&::-webkit-scrollbar-thumb": {
      border: "none",
      boxShadow: "none",
      background: "#dadce0",
      borderRadius: 8,
      minHeight: 40,
    },
  },
  timePickerInput: {
    "& input::selection": {
      background: "#e45400",
    },
  },
  expandTimePickerButton: {
    transition: "transform 400ms",
  },
}));

export interface DateTimePickerProps {
  minDate: Date;
  maxDate: Date;
  value: Date;
  onChange: (value: Date) => void;
  invalid?: boolean;
}

export function DateTimePicker({
  minDate,
  maxDate,
  value,
  onChange,
  invalid,
}: DateTimePickerProps) {
  // eslint-disable-next-line
  const timeOptions = useMemo(() => generateTimeOptions(value), [
    // eslint-disable-next-line
    value.getTime(),
  ]);

  return (
    <div className="flex flex-col items-center">
      <div
        className={`border ${
          invalid ? "border-red-600" : "border-gray-300"
        } border-solid rounded p-0.5 mb-3`}
      >
        <StaticDatePicker
          showToolbar={false}
          orientation="landscape"
          displayStaticWrapperAs="desktop"
          minDate={minDate}
          maxDate={maxDate}
          inputFormat="eee, LLL do Y"
          value={value}
          onChange={(date) => {
            if (!date) return;
            const cappedDate = new Date(
              Math.min(
                maxDate.getTime(),
                Math.max(minDate.getTime(), date.getTime())
              )
            );
            onChange(cappedDate);
          }}
          renderInput={(props) => (
            <TextField
              {...(props as TextFieldProps)}
              variant="outlined"
              size="small"
            />
          )}
        />
      </div>
      <TimePicker
        options={timeOptions}
        value={value}
        onChange={onChange}
        minDate={minDate}
        maxDate={maxDate}
      />
    </div>
  );
}

const formatTime = format("p");

const timeIntervalMinutes = 30;
export function generateTimeOptions(currentValue: Date) {
  const start = startOfDay(currentValue);

  const numberOfOptions = (24 * 60) / timeIntervalMinutes;
  return Array.from({ length: numberOfOptions }).map((_, i) => {
    const candidate = addMinutes(timeIntervalMinutes * i, start);
    if (candidate.getTime() === currentValue.getTime()) return currentValue;
    return candidate;
  });
}

interface TimePickerFormikProps
  extends Omit<FieldProps<Date>, "options">,
    Omit<TimePickerProps, "value" | "onChange"> {}

/**
 * Formik-compatible wrapper of our <TimePicker>
 */
export function TimePickerFormik({
  field: { onChange: _onChange, onBlur: fieldOnBlur, ...field },
  form: { setFieldValue, setFieldTouched },
  ...props
}: TimePickerFormikProps) {
  return (
    <TimePicker
      {...props}
      onChange={(date) => {
        // Do not switch this order, otherwise you might cause a race condition
        // See https://github.com/formium/formik/issues/2083#issuecomment-884831583
        setFieldTouched(field.name, true, false);
        setFieldValue(field.name, date, true);
      }}
      value={field.value}
    />
  );
}

interface TimePickerProps {
  options?: Date[];
  value: Date;
  onChange: (date: Date) => void;
  minDate?: Date;
  maxDate?: Date;
  variant?: "contained" | "outlined";
}

export function TimePicker({
  options,
  value,
  onChange,
  minDate,
  maxDate,
  variant = "contained",
}: TimePickerProps) {
  const { fitsDesktop } = useBreakpoints();
  const { classes } = useStyles();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const timeOptions = useMemo(() => options ?? generateTimeOptions(value), [
    options,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    startOfDay(value).getTime(),
  ]);

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    getInputProps,
    getComboboxProps,
    setInputValue,
    closeMenu,
    openMenu,
    setHighlightedIndex,
  } = useCombobox({
    initialSelectedItem: value,
    defaultSelectedItem: value,
    items: timeOptions,
    itemToString: formatTime,
    onSelectedItemChange: ({ selectedItem }) => {
      selectedItem && onChange(selectedItem);
    },
    onInputValueChange: ({ inputValue }) => {
      if (!inputValue) return;
      const parsedTime = parseTime(inputValue, value);

      const matchedOptionIndex =
        parsedTime &&
        timeOptions.findIndex(
          (option) => option.getTime() === parsedTime.getTime()
        );

      setHighlightedIndex(matchedOptionIndex ?? -1);
    },
    onIsOpenChange: ({ isOpen, inputValue }) => {
      if (!isOpen) {
        const parsedTime = inputValue ? parseTime(inputValue) : null;
        if (parsedTime === null) {
          const formattedTime = formatTime(value);
          // Reset input value back to the time value
          // when invalid input has been provided and
          // the menu is closed.
          setInputValue(formattedTime);
        }
      }
    },
  });
  // Ensure the input value is synced with the actual date value being tracked
  useEffect(() => {
    setInputValue(formatTime(value));
    // eslint-disable-next-line
  }, [value.getTime()]);

  const anchorRef = useRef<HTMLDivElement>(null);

  return (
    <div
      className={clsx("rounded py-0.5 pr-2.5 pl-5 relative", {
        "bg-primary": variant === "contained",
        "border border-solid border-primary": variant === "outlined",
      })}
    >
      <div
        {...getComboboxProps({
          ref: anchorRef,
        })}
        className={clsx("flex", {
          "text-white": variant === "contained",
          "text-primary": variant === "outlined",
        })}
      >
        <Input
          className={clsx(
            "font-bold w-[70px]",
            {
              "text-white": variant === "contained",
              "text-primary": variant === "outlined",
            },
            classes.timePickerInput
          )}
          classes={{
            disabled: clsx({
              "text-white text-fill-white": variant === "contained",
              "text-primary text-fill-primary": variant === "outlined",
            }),
          }}
          disableUnderline
          disabled={!fitsDesktop}
          {...getInputProps({
            refKey: "inputRef",
            onKeyDown: (event) => {
              if (event.key === "Enter" && highlightedIndex === -1) {
                // Handle fuzzy input that doesn't match one of the preset options
                const inputValue = (event.target as HTMLInputElement).value;
                const parsedTime = parseTime(inputValue, value);
                if (parsedTime) {
                  onChange(parsedTime);
                  closeMenu();
                }
              }
            },
            onClick: () => openMenu(),
          })}
          onFocus={(event) => {
            // Detect whether the focus was triggered by the user manually focussing
            // the input field vs downshift focussing the input after selecting an
            // item.
            if (!event.relatedTarget) {
              event.target.select();
              openMenu();
            }
          }}
        />
        <IconButton
          color="inherit"
          size="small"
          className={classes.expandTimePickerButton}
          style={{ transform: `rotate(${isOpen ? 180 : 0}deg)` }}
          {...getToggleButtonProps()}
        >
          <ArrowDropDown color="inherit" />
        </IconButton>
        <Popper
          open
          anchorEl={anchorRef.current}
          className="z-50"
          disablePortal
        >
          <Paper className={clsx({ hidden: !isOpen })}>
            <MenuList
              {...getMenuProps({ className: classes.timePickerMenu })}
              onClose={closeMenu}
            >
              {timeOptions.map((item, index) => (
                <MenuItem
                  key={item.getTime()}
                  {...getItemProps({
                    index,
                    item,
                    style: {
                      paddingLeft: 8,
                      backgroundColor:
                        highlightedIndex === index ? "#F1F1F1" : "white",
                    },

                    disabled:
                      (minDate && item.getTime() < minDate.getTime()) ||
                      (maxDate && item.getTime() > maxDate.getTime()),
                  })}
                >
                  <Check
                    color="primary"
                    fontSize="small"
                    style={{
                      opacity: item.getTime() === value.getTime() ? 1 : 0,
                      marginRight: 8,
                    }}
                  />
                  {formatTime(item)}
                </MenuItem>
              ))}
            </MenuList>
          </Paper>
        </Popper>
      </div>
    </div>
  );
}

const padMinutes = padCharsEnd("0", 2);
export function parseTime(fuzzyInput: string, referenceDate = new Date()) {
  const sanitizedInput = fuzzyInput.replace(/\s*/g, "").toLowerCase();
  const matches = /((\d{1,2}):)?(\d{1,2})\s*(am?|pm?)?/.exec(sanitizedInput);
  if (!matches) return null;

  const [, , hourMatch, minuteMatch, suffixMatch = "pm"] = matches;

  if (!minuteMatch) return null;

  let hour = 0;
  let minute = 0;
  if (!hourMatch) {
    hour = Number(minuteMatch);
  } else {
    hour = Number(hourMatch);
    minute = Number(padMinutes(minuteMatch));
  }

  if (hour > 24) return null;
  if (minute > 60) return null;

  if (hour <= 12) {
    // Handles 12 am
    if (["a", "am"].includes(suffixMatch)) {
      hour %= 12;
    }

    if (["p", "pm"].includes(suffixMatch) && hour !== 0) {
      hour += 12;
    }
  }

  return addHours(hour, addMinutes(minute, startOfDay(referenceDate)));
}
