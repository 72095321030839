import { MutationFunctionOptions } from "@apollo/client";
import QueueIcon from "@mui/icons-material/Queue";
import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import clsx from "clsx";
import { ReactNode, useState } from "react";
import { useDropzone } from "react-dropzone";

import EditableText from "@/components/EditableText";
import FileUploader from "@/components/FileUploader/FileUploader";
import { useAddCamera } from "@/components/Genius/Forms/Camera/cameraHooks";
import { useGenius } from "@/components/Genius/GeniusProvider";
import BaseModal from "@/components/Modal/BaseModal";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { determineDefaultStream } from "@/components/StreamSelectionDropdown/StreamSelectionDropdownMenu";

import {
  AddManualDeviceScanInput,
  AddManualDeviceScanMutation,
  DeviceListDocument,
  Exact,
  LocationCapacityDocument,
  useAddManualDeviceScanMutation,
} from "@/generated-models";

const IPV4_REGEX = /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/;
const PORT_REGEX = /^\d+(\.\d{1,2})?$/;

const headers = ["ip", "username", "password", "port", "path"];

type ManualDeviceRow = Pick<
  AddManualDeviceScanInput,
  "ip" | "username" | "password" | "port" | "path"
>;

const validators: {
  [key: string]: (value: string) => boolean;
} = {
  ip: (value: string) => IPV4_REGEX.test(value),
  port: (value: string) => PORT_REGEX.test(value),
};

const valueFormatter: {
  [key: string]: (value?: string) => string;
} = {
  port: (value?: string) => value || "554",
  path: (value?: string) => value || "/",
};

function csvToArray(str: string, delimiter = ",") {
  const rows = str.split(/\r\n|\r|\n/).filter(Boolean);
  const errors: ReactNode[] = [];

  const arr = rows.map(function (row, rowIdx) {
    const values: string[] = row.split(delimiter);

    const el = headers.reduce<ManualDeviceRow>((object, header: any, index) => {
      const validator = validators[header];
      const formatter = valueFormatter[header];
      const formattedValue = formatter
        ? formatter(values[index])
        : values[index];

      if (validator && !validator(formattedValue)) {
        errors.push(
          <div>
            {`(Row: ${index + 1}): Not a valid ${header}: `}
            <strong className="bg-black/10 rounded-md px-1 py-0.5">
              {formattedValue}
            </strong>
          </div>
        );
      }

      return { ...object, [header]: formattedValue };
    }, {} as ManualDeviceRow);
    return { id: `${el.ip}-${rowIdx}`, ...el };
  });

  return { errors, data: arr };
}

function ManualDeviceCredentialTable({ rows }: { rows: ManualDeviceRow[] }) {
  return (
    <DataGrid
      classes={{
        root: "rounded-md",
        cell: "h-[58px]",
      }}
      columnHeaderHeight={40}
      rowHeight={24}
      initialState={{ pagination: { paginationModel: { pageSize: 16 } } }}
      pageSizeOptions={[16]}
      autoHeight
      columns={[
        {
          field: "ip",
          headerName: "IP",
          flex: 2,
        },
        {
          field: "username",
          headerName: "Username",
          flex: 1,
        },
        {
          field: "password",
          headerName: "Password",
          valueFormatter: () => "*****",
          width: 100,
        },
        {
          field: "port",
          headerName: "Port",
          width: 100,
        },
        {
          field: "path",
          headerName: "Path",
          flex: 3,
        },
      ]}
      rows={rows || []}
    ></DataGrid>
  );
}

export default function ManualDevicePopup({
  onClose,
  locationId,
}: {
  onClose: () => void;
  locationId: number;
}) {
  const { pushSnackbar } = useFeedback();
  const [data, setData] = useState<ManualDeviceRow[]>([]);
  const [errors, setErrors] = useState<ReactNode[]>([]);
  const [importErrors, setImportErrors] = useState<ReactNode[]>([]);

  const [uploading, setUploading] = useState(false);
  const hasData = data.length > 0;
  const hasErrors = errors.length > 0;
  const isValid = hasData && !hasErrors;
  const [geniusScan] = useGenius();
  const [mutate, { loading }] = useAddManualDeviceScanMutation({
    onError: (error, options) => {
      const input = options?.variables?.input;
      setImportErrors([
        ...importErrors,
        <div className="bg-black/10 rounded-md px-1 py-0.5 flex flex-col gap-1">
          <strong>{`[${input?.ip}] Path: '${input?.path}'`}</strong>
          <div>{error?.message}</div>
        </div>,
      ]);
    },
  });
  const { addNewCamera: addCamera, loading: addCameraLoading } = useAddCamera(
    locationId
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    disabled: uploading,
    accept: {
      "text/csv": [".csv"],
    },
    maxSize: 10e6,
    onDropAccepted: async (files) => {
      setUploading(true);
      const reader = new FileReader();
      reader.readAsText(files[0]);
      reader.onload = (event) => {
        const result = csvToArray(`${event?.target?.result}`);
        setData(result.data);
        setErrors(result.errors);
      };
      setUploading(false);
    },
    onDropRejected: (rejections) => {
      if (rejections[0]) {
        pushSnackbar(rejections[0].errors[0].message, FeedbackType.Error);
      }
    },
  });

  return (
    <BaseModal
      onClose={onClose}
      classes={{ root: "sm:w-[600px] md:w-[800px]" }}
      header={
        <div className="flex items-center gap-2">
          <div className="flex-center flex-col">
            <QueueIcon />
          </div>
          <EditableText
            TypographyProps={{
              style: { fontSize: 18, fontWeight: "bold", lineHeight: 1 },
            }}
            initialValue="Bulk Import"
            disabled
            onSubmit={() => {}}
          />
        </div>
      }
    >
      <div className="flex">
        <div className="p-4 overflow-hidden w-full">
          <div className="bg-[#f8f8f8] rounded-md px-3 py-2 text-[#717171] sm:text-sm text-xs">
            CSV file should include the following fields:{" "}
            <strong>{headers.join(", ")}</strong>
          </div>
          <FileUploader
            loading={uploading || loading || addCameraLoading}
            state={{
              getRootProps,
              getInputProps,
              isDragActive,
            }}
          />
          {isValid && <ManualDeviceCredentialTable rows={data} />}
          {importErrors.length > 0 && (
            <>
              <Alert
                classes={{
                  message: "w-full",
                }}
                severity="error"
              >
                <div className="font-bold pb-1">
                  There were errors importing the devices:
                </div>
                <div className="h-[200px] overflow-y-auto space-y-1">
                  {importErrors.map((err) => (
                    <div>{err}</div>
                  ))}
                </div>
              </Alert>
            </>
          )}
          {hasErrors && (
            <>
              <Alert
                classes={{
                  message: "w-full",
                }}
                severity="error"
              >
                <div className="font-bold pb-1">
                  There were issues processing the bulk device import:
                </div>
                <div className="h-[200px] overflow-y-auto space-y-1">
                  {errors.map((err) => (
                    <div>{err}</div>
                  ))}
                </div>
              </Alert>
            </>
          )}
        </div>
      </div>
      <div
        className={clsx("px-4 pb-4 flex justify-end gap-x-2", {
          hidden: !hasData && !hasErrors,
        })}
      >
        <Button onClick={onClose}>Cancel</Button>
        {!hasErrors && (
          <LoadingButton
            loading={loading || addCameraLoading}
            color="primary"
            variant="contained"
            onClick={async () => {
              const ops: MutationFunctionOptions<
                AddManualDeviceScanMutation,
                Exact<{
                  input: AddManualDeviceScanInput;
                }>
              >[] = data.map((d, idx) => {
                const lastOp = data.length - 1 === idx;
                return {
                  variables: {
                    input: {
                      ip: d.ip.trim(),
                      port: Number(d.port),
                      username: d.username.trim(),
                      password: d.password.trim(),
                      path: d.path.trim(),
                      locationId,
                      failOnValidationError: true,
                    },
                  },
                  update(_, { data }) {
                    if (!data) return;
                    const device = data?.addManualDeviceScan;
                    const connectedStream = device.channels[0]?.streams.find(
                      (stream) => stream.camera
                    );
                    const recommendedCameraSettings =
                      connectedStream?.camera?.appliance?.defaultCameraSettings;
                    const autoSelectedStream =
                      device.channels[0] &&
                      determineDefaultStream(
                        device.channels[0].streams,
                        recommendedCameraSettings
                      );

                    if (autoSelectedStream) {
                      addCamera(
                        autoSelectedStream.id,
                        "Camera device",
                        device.vendor,
                        true
                      );
                    }

                    if (lastOp) {
                      onClose();
                      geniusScan();
                    }
                  },
                  ...(lastOp && {
                    refetchQueries: [
                      {
                        query: LocationCapacityDocument,
                        variables: { locationId },
                      },
                      {
                        query: DeviceListDocument,
                        variables: { locationId: locationId },
                      },
                    ],
                  }),
                };
              });

              for (const op of ops) {
                await mutate(op);
              }
            }}
          >
            Import
          </LoadingButton>
        )}
      </div>
    </BaseModal>
  );
}
