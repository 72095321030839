import CheckIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { Tooltip, Theme } from "@mui/material";
import React from "react";
import { makeStyles } from "tss-react/mui";

import { CameraStatus } from "@/generated-models";

const statusColors = (theme: Theme) => ({
  online: theme.palette.success.main,
  offline: theme.palette.error.main,
  inactive: "grey",
});

const statusIcons = {
  online: CheckIcon,
  offline: ErrorIcon,
  inactive: ErrorIcon,
};

const useStyles = makeStyles<{ props: ConnectionStatusProps }>()(
  (theme, { props }) => ({
    connection: {
      fill: statusColors(theme)[props.status],
    },
  })
);

type Status = "online" | "offline" | "inactive";

interface ConnectionStatusProps {
  status: Status;
}

export function ConnectionStatus(props: ConnectionStatusProps) {
  const { status } = props;
  const { classes } = useStyles({ props });

  const Icon = statusIcons[status];
  return (
    <Tooltip title={status}>
      <Icon className={classes.connection} />
    </Tooltip>
  );
}

export function LiveStatusIndicator({ status }: { status: CameraStatus }) {
  return (
    <span
      style={{
        display: "inline-block",
        width: 8,
        height: 8,
        marginRight: 4,
        borderRadius: "50%",
        ...(status === "online"
          ? {
              border: "2px solid #67c21b",
              boxShadow: `0px 0px 5px #9FFC28`,
            }
          : {
              backgroundColor: "#b0b0b0",
            }),
      }}
    />
  );
}
