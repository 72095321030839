import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import gql from "graphql-tag";
import { useState } from "react";
import { makeStyles } from "tss-react/mui";

import { useBreakpoints } from "@/util/useBreakpoints";

import { useUpdateCameraNameMutation } from "@/generated-models";
import { usePermissions } from "@/hooks/usePermissions";

const useStyles = makeStyles()(() => ({
  textField: {
    "&:before": {
      borderColor: "rgba(0, 0, 0, 0.5)",
    },
  },
}));

interface QuickEditCameraNameProps {
  cameraId: number;
  camName: string;
}

export default function QuickEditCameraName({
  cameraId,
  camName,
}: QuickEditCameraNameProps) {
  const { fitsTablet } = useBreakpoints();
  const { classes } = useStyles();
  const hasPermission = usePermissions();
  const [updateCameraName] = useUpdateCameraNameMutation();
  const [editing, setEditing] = useState(false);

  if (!fitsTablet) {
    return (
      <Typography component="h2" className="font-bold md:text-xl shrink-0">
        {camName}
      </Typography>
    );
  }

  return editing ? (
    <Formik
      initialValues={{
        name: camName,
      }}
      onSubmit={(values) => {
        if (values.name !== camName) {
          updateCameraName({
            variables: { id: cameraId, name: values.name },
            optimisticResponse: {
              __typename: "Mutation",
              updateCameraName: {
                __typename: "Camera",
                id: cameraId,
                name: values.name,
              },
            },
          });
        }
        setEditing(false);
      }}
    >
      <Form className="inline-flex items-center m-0 p-0 ml-2">
        <Field
          name="name"
          component={TextField}
          size="small"
          InputProps={{ className: classes.textField }}
        />
        <Tooltip title="Save">
          <IconButton type="submit" size="small">
            <CheckIcon />
          </IconButton>
        </Tooltip>
      </Form>
    </Formik>
  ) : (
    <div className="flex gap-2 items-center shrink-0">
      <Typography component="h2" className="font-bold md:text-xl">
        {camName}
      </Typography>
      {hasPermission("devices_manage") && (
        <Tooltip title="Edit camera name">
          <IconButton onClick={() => setEditing(true)} size="small">
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
}

export const UPDATE_CAMERA = gql`
  mutation updateCameraName($id: Int!, $name: String!) {
    updateCameraName(id: $id, name: $name) {
      id
      name
    }
  }
`;
