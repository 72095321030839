import { IconProps } from "@/icons/IconProps";

export function SpotConnect({ style, className, onClick }: IconProps) {
  return (
    <svg
      style={style}
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <g clipPath="url(#clip0_2369_2742)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.87665 15.5158L9.40525 15.9872C7.5828 17.8096 7.5828 20.7644 9.40525 22.5868C11.2277 24.4093 14.1825 24.4093 16.0049 22.5868L16.4763 22.1154L9.87665 15.5158ZM7.99103 24.001C10.5945 26.6045 14.8156 26.6045 17.4191 24.001L18.3944 23.0257C18.8972 22.523 18.8972 21.7079 18.3944 21.2051L10.7869 13.5976C10.2842 13.0949 9.4691 13.0949 8.96635 13.5976L7.99103 14.573C5.38754 17.1764 5.38754 21.3975 7.99103 24.001Z"
          fill="#353D48"
        />
        <path
          d="M5.67379 27.7356C5.28225 28.1272 4.64744 28.1272 4.2559 27.7356C3.86435 27.3441 3.86435 26.7093 4.2559 26.3177L8.25883 22.3148L9.67672 23.7327L5.67379 27.7356Z"
          fill="#353D48"
        />
        <path
          d="M13.2907 13.9845C13.7472 13.528 14.4872 13.528 14.9437 13.9845C15.4001 14.4409 15.4001 15.181 14.9437 15.6375L12.9418 17.6394L11.2888 15.9864L13.2907 13.9845Z"
          fill="#353D48"
        />
        <path
          d="M16.3639 17.0514C16.8188 16.5965 17.5562 16.5965 18.0111 17.0514C18.4659 17.5062 18.4659 18.2437 18.0111 18.6985L16.0062 20.7034L14.3591 19.0562L16.3639 17.0514Z"
          fill="#353D48"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.1234 16.484L22.5948 16.0126C24.4172 14.1901 24.4172 11.2354 22.5948 9.41293C20.7723 7.59048 17.8175 7.59048 15.9951 9.41293L15.5237 9.88433L22.1234 16.484ZM24.009 7.99871C21.4055 5.39522 17.1844 5.39522 14.5809 7.99871L13.6056 8.97403C13.1028 9.47678 13.1028 10.2919 13.6056 10.7946L21.2131 18.4021C21.7158 18.9049 22.5309 18.9049 23.0336 18.4021L24.009 17.4268C26.6125 14.8233 26.6125 10.6022 24.009 7.99871Z"
          fill="#353D48"
        />
        <path
          d="M26.3233 4.26632C26.7148 3.87478 27.3496 3.87478 27.7412 4.26632C28.1327 4.65786 28.1327 5.29268 27.7412 5.68422L23.7402 9.6852L22.3223 8.2673L26.3233 4.26632Z"
          fill="#353D48"
        />
      </g>
      <defs>
        <clipPath id="clip0_2369_2742">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
