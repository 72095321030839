import { useMemo } from "react";

import { useMe } from "@/components/Auth";
import { NoAccess } from "@/components/NoAccess/NoAccess";

import { MeQuery } from "@/generated-models";

export type OrgPermissionIds = keyof Omit<
  MeQuery["me"]["organization"]["permissions"],
  "__typename"
>;

/**
 * Returns a function that can be used to check if the user has the given permissions.
 */
export function usePermissions() {
  const me = useMe();
  const permissions = me?.organization.permissions;

  return useMemo(() => {
    /**
     * When only passing permissions - returns a boolean indicating whether the user has
     * all the permissions
     * @param permissionsToCheck
     */
    function hasPermission(
      permissionsToCheck: OrgPermissionIds | OrgPermissionIds[]
    ): boolean;
    /**
     * When passing a "withAccess" node - returns that node if the user has all the
     * permissions, otherwise returns the noAccess node (defaults to <NoAccess />)
     * @param permissionsToCheck
     * @param withAccess
     * @param noAccess defaults to <NoAccess />
     */
    function hasPermission(
      permissionsToCheck: OrgPermissionIds | OrgPermissionIds[],
      withAccess: React.ReactNode,
      noAccess?: React.ReactNode
    ): React.ReactNode;
    function hasPermission(
      permissionsToCheck: OrgPermissionIds | OrgPermissionIds[],
      withAccess?: React.ReactNode,
      noAccess: React.ReactNode = <NoAccess />
    ): Boolean | React.ReactNode {
      if (!me || !permissions) return false;

      const permissionsArray = Array.isArray(permissionsToCheck)
        ? permissionsToCheck
        : [permissionsToCheck];

      const hasAllPermissions = permissionsArray.every(
        (permission) => permissions[permission]
      );

      if (!withAccess) return hasAllPermissions;

      if (hasAllPermissions) {
        return withAccess;
      } else {
        return noAccess;
      }
    }
    return hasPermission;
  }, [me, permissions]);
}
