import { MaintainDetailsConfigConnectionTile } from "@/pages/Maintain/Details/Config/MaintainDetailsConfigConnectionTile";
import { MaintainDetailsConfigDetailsTile } from "@/pages/Maintain/Details/Config/MaintainDetailsConfigDetailsTile";
import { MaintainDetailsConfigFisheye } from "@/pages/Maintain/Details/Config/MaintainDetailsConfigFisheye";
import { MaintainDetailsConfigNvrTile } from "@/pages/Maintain/Details/Config/MaintainDetailsConfigNvrTile";

import { LocationCapacityGuard } from "@/components/Genius/LocationCapacityGuard";

import { CameraDetailByIdQuery } from "@/generated-models";
import { usePermissions } from "@/hooks/usePermissions";

export function MaintainDetailsConfig({
  camera,
}: {
  camera: CameraDetailByIdQuery["camera"];
}) {
  const hasPermissions = usePermissions();
  const isNvr = camera?.device?.isNvr;
  const isFisheye = camera?.device?.isFisheye;

  let configComponent;
  if (isFisheye) {
    configComponent = <MaintainDetailsConfigFisheye camera={camera} />;
  } else if (isNvr) {
    configComponent = <MaintainDetailsConfigNvrTile camera={camera} />;
  } else {
    configComponent = <MaintainDetailsConfigConnectionTile camera={camera} />;
  }

  return (
    <div>
      <div className="flex flex-col gap-y-3">
        {hasPermissions("devices_manage") && (
          <div className="grid grid-cols-1 gap-3">
            <LocationCapacityGuard locationId={camera.location.id}>
              {configComponent}
            </LocationCapacityGuard>
          </div>
        )}
        <MaintainDetailsConfigDetailsTile camera={camera} />
      </div>
    </div>
  );
}
