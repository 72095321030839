import { createIconComponent } from "@/icons/createIconComponent";

import { ReactComponent as _AgeIcon } from "../icons/age.svg";
import { ReactComponent as _GenderIcon } from "../icons/gender.svg";
import { ReactComponent as _MotionIcon } from "../icons/motion.svg";
import { ReactComponent as _VehicleColorIcon } from "../icons/vehicle-color.svg";
import { ReactComponent as _VehicleMakeIcon } from "../icons/vehicle-make.svg";
import { ReactComponent as _VehicleTypeIcon } from "../icons/vehicle-type.svg";
import { ReactComponent as _VehiclesIcon } from "../icons/vehicles.svg";

export const AgeIcon = createIconComponent(_AgeIcon);
export const GenderIcon = createIconComponent(_GenderIcon);
export const MotionIcon = createIconComponent(_MotionIcon);
export const VehicleColorIcon = createIconComponent(_VehicleColorIcon);
export const VehicleMakeIcon = createIconComponent(_VehicleMakeIcon);
export const VehicleTypeIcon = createIconComponent(_VehicleTypeIcon);
export const VehiclesIcon = createIconComponent(_VehiclesIcon);
