type Truthy<T> = T extends false | "" | 0 | null | undefined ? never : T;

export function filterFalsy<T>(value: T): value is Truthy<T> {
  return Boolean(value);
}

type NotNullish<T> = T extends null | undefined ? never : T;
export function filterNullish<T>(value: T): value is NotNullish<T> {
  return value != null;
}
