import { Search } from "@mui/icons-material";
import { Hidden, IconButton, Typography } from "@mui/material";
import clsx from "clsx";
import { useFlags } from "launchdarkly-react-client-sdk";
import uniq from "lodash/uniq";
import { useState } from "react";
import { StringParam, useQueryParam } from "use-query-params";

import { useBreakpoints } from "@/util/useBreakpoints";

import { SearchBox } from "@/components/SearchBox";

import { IntelligenceDashboardQuery } from "@/generated-models";

import { LicensePlateReportDetailsMobileBar } from "./LicensePlateReportDetailsMobileBar";
import { useLprReportGrouped, useLprResolvedVisits } from "./hooks";

interface LicensePlateReportDetailsBarStatisticProps {
  className?: string;
  label: string;
  value?: number;
}

function LicensePlateReportDetailsBarStatistic({
  className,
  label,
  value,
}: LicensePlateReportDetailsBarStatisticProps) {
  return (
    <Typography className={clsx(className, "text-base leading-[19px]")}>
      <strong>{value?.toLocaleString()}</strong> {label}
    </Typography>
  );
}

export function LicensePlateReportDetailsBar({
  dashboard,
}: {
  dashboard: IntelligenceDashboardQuery["intelligenceDashboard"];
}) {
  const { lprMmct } = useFlags();
  const [showSearch, setShowSearch] = useState(false);
  const [searchFilter, setSearchFilter] = useQueryParam("search", StringParam);
  const { fitsDesktop } = useBreakpoints();
  const { data } = useLprReportGrouped();
  const plates = data?.lprReport || [];
  const total = plates.reduce((n, { visits }) => n + visits, 0);

  const resolvedVisits = useLprResolvedVisits();
  const makes = uniq(plates.map((d) => d.make));
  const models = uniq(plates.map((d) => d.model));
  const colors = uniq(plates.map((d) => d.colorId));
  const types = uniq(plates.map((d) => d.typeId));

  const maxVisits = Math.max(...Object.values(resolvedVisits));

  const vehicleTypeFields = lprMmct ? (
    <>
      <LicensePlateReportDetailsBarStatistic
        className="sm:px-3"
        label={makes.length > 1 ? "Makes" : "Make"}
        value={makes.length}
      />
      <LicensePlateReportDetailsBarStatistic
        className="pl-2 sm:px-3"
        label={models.length > 1 ? "Models" : "Model"}
        value={models.length}
      />
      <LicensePlateReportDetailsBarStatistic
        className="pl-2 sm:px-3"
        label={colors.length > 1 ? "Colors" : "Color"}
        value={colors.length}
      />
      <LicensePlateReportDetailsBarStatistic
        className="pl-2 sm:px-3"
        label={types.length > 1 ? "Types" : "Type"}
        value={types.length}
      />
    </>
  ) : (
    <></>
  );

  return (
    <div className="flex flex-col gap-3 justify-center px-0 sm:px-6 pt-2 sm:pt-6 pb-3 sm:pb-5">
      <Hidden smUp>
        <LicensePlateReportDetailsMobileBar dashboard={dashboard} />
      </Hidden>

      <div className="flex items-center justify-between">
        <div className="flex flex-col sm:flex-row justify-start gap-y-2 sm:divide-x divide-[#91D8D4] text-[#00A69C] px-4 sm:px-0">
          <LicensePlateReportDetailsBarStatistic
            className="sm:pr-3"
            label="Unique License plates"
            value={Object.keys(resolvedVisits).length}
          />
          <LicensePlateReportDetailsBarStatistic
            className="sm:px-3"
            label="Most visits by a single plate"
            value={maxVisits >= 0 ? maxVisits : 0}
          />
          <LicensePlateReportDetailsBarStatistic
            className="sm:px-3"
            label="Number of vehicles total"
            value={total || 0}
          />
          {fitsDesktop ? (
            vehicleTypeFields
          ) : (
            <div className="flex gap-2 divide-x divide-[#91D8D4]">
              {vehicleTypeFields}
            </div>
          )}
        </div>
        <Hidden smDown>
          <div className="flex justify-between items-center px-4 sm:px-0">
            {showSearch ? (
              <SearchBox
                input={searchFilter || ""}
                setInput={setSearchFilter}
                placeholder="Search Results"
              />
            ) : (
              <IconButton
                onClick={() => setShowSearch(true)}
                size="small"
                className="text-text"
              >
                <Search />
              </IconButton>
            )}
          </div>
        </Hidden>
      </div>
    </div>
  );
}
