import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Button } from "@mui/material";
import React from "react";
import { makeStyles } from "tss-react/mui";

import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { DefaultDialog, useDialog } from "@/components/shared/Dialog";

import { useDeleteLocationLayoutMutation } from "@/generated-models";

const useStyles = makeStyles()((theme) => ({
  deleteLayoutButton: {
    background: "#FFF",
    "&:hover": {
      backgroundColor: "#fff",
    },
    minWidth: "30px",
    position: "absolute",
    left: theme.spacing(1),
    top: theme.spacing(1),
    zIndex: 1,
  },
}));
interface DeleteLayoutProps {
  id: number;
}
export function DeleteLayoutButton({ id }: DeleteLayoutProps) {
  const { classes } = useStyles();
  const { pushSnackbar } = useFeedback();
  const { open, ...dialogProps } = useDialog();
  const [deleteLocationLayout] = useDeleteLocationLayoutMutation();

  return (
    <>
      <Button
        color={"primary"}
        variant={"outlined"}
        size="small"
        disableRipple
        disableFocusRipple
        className={classes.deleteLayoutButton}
        onClick={async () => {
          const confirmed = await open();
          if (!confirmed) return;
          const { errors } = await deleteLocationLayout({
            variables: {
              id,
            },
            optimisticResponse: {
              __typename: "Mutation",
              deleteLocationLayout: id,
            },
            update: (cache, data) => {
              cache.evict({
                id: `LocationLayout:${id}`,
              });
            },
          }).catch((e) => ({ errors: [e] }));
          if (errors?.length) {
            pushSnackbar(
              "Something went wrong. Please try again",
              FeedbackType.Error
            );
          } else {
            pushSnackbar("Layout deleted successfully", FeedbackType.Success);
          }
        }}
      >
        <DeleteForeverIcon />
      </Button>

      <DefaultDialog
        title="Delete layout"
        content="Are you sure you want to delete this Layout?
          Deleting the layout will delete the map and all the cameras placed on it"
        confirmColor="primary"
        {...dialogProps}
      />
    </>
  );
}
