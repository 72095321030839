import { useMemo } from "react";
import { ArrayParam, useQueryParams } from "use-query-params";

export function buildQueryParamResult<T>(
  filters: { filterName: string }[],
  value: T
) {
  return filters.reduce<Record<string, T>>(function (result, filter) {
    result[filter.filterName] = value;
    return result;
  }, {});
}

export function useActiveFilter(filters: { filterName: string }[]) {
  const params = useMemo(() => {
    return buildQueryParamResult(filters, ArrayParam);
  }, [filters]);
  const [query, setQuery] = useQueryParams(params);
  const clearFilters = (callback?: () => void) => {
    const res = filters.reduce<Record<string, undefined>>(function (
      result,
      filter
    ) {
      result[filter.filterName] = undefined;
      return result;
    },
    {});
    setQuery(res);
    if (callback) {
      callback();
    }
  };
  return {
    clearFilters,
    hasActiveFilter: !Object.values(query).every((el) => !el),
  };
}
