import { ApolloError } from "@apollo/client";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import FilterListIcon from "@mui/icons-material/FilterList";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { IconButton, Popover } from "@mui/material";
import clsx from "clsx";
import { addMinutes, format, max, startOfHour } from "date-fns/fp";
import gql from "graphql-tag";
import { useFlags } from "launchdarkly-react-client-sdk";
import { groupBy, orderBy, sortBy, uniqBy } from "lodash/fp";
import { Fragment, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useUpdateEffect } from "react-use";

import { ClothingLowerIcon, ClothingUpperIcon } from "@/icons/Icons";

import { filterNullish } from "@/util/filterFalsy";
import { pluralize } from "@/util/pluralize";
import { usePagination } from "@/util/usePagination";

import { IntLoadingIndicator } from "@/pages/Intelligence/IntLoadingIndicator";

import { ErrorMessage } from "@/components/ErrorMessage";
import {
  DataGridContextualPlayer,
  IVodContextualPlayer,
  useDataGridContextPlayer,
} from "@/components/VideoWall/ContextualVideoPlayer";
import {
  useActiveCamIds,
  useActiveGroupId,
  useSetActiveCamIds,
} from "@/components/View/sharedViewHooks";
import { SearchParamLink } from "@/components/shared/QueryParamLink";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  ClothingColor,
  Gender,
  GetDetectedSubjectTracksQuery,
  useGetDetectedSubjectBaseQuery,
  useGetDetectedSubjectTracksQuery,
} from "@/generated-models";
import { usePermissions } from "@/hooks/usePermissions";

import {
  useArraySearchFilter,
  useRangeParamWithoutDefaults,
} from "../searchHooks";
import {
  ActivePeopleFiltersBar,
  FilterChip,
} from "./components/ActiveFiltersBar";
import {
  ageBucketMap,
  attributeColorsMap,
  useGenderParam,
} from "./components/AttributeFilters";
import { EditableSubjectName } from "./components/EditableSubjectName";
import { EmptyState } from "./components/EmptyState";
import { PeopleFilterBar } from "./components/PeopleFilterBar";
import { RemoveSubjectButton } from "./components/RemoveSubjectButton";
import { SortBar } from "./components/SortBar";
import { SubjectFeedbackButtons } from "./components/SubjectFeedbackButtons";

const formatDate = format("MMMM d, ");
const formatDateYear = format("MMMM d, y ");
const formatDayDateYear = format(" - EEEE, MMM d, y");
const formatTime = format("h:mmaaa");

const sortParamName = "clipSort";
const sortOptions = [
  { label: "Time", prefix: "time", defaultOrder: "desc" as const },
  { label: "Location", prefix: "loc", defaultOrder: "asc" as const },
  { label: "Camera", prefix: "cam", defaultOrder: "asc" as const },
];
const defaultSort = `${sortOptions[0].prefix}_${sortOptions[0].defaultOrder}`;

export function PeopleSearchDetail() {
  const { genderAgeAttributeSearch } = useFlags();
  const hasPermission = usePermissions();
  const canManageAssignment = hasPermission("people_assignment_manage");
  const params = useParams<{ id: string }>();
  const { rangeStart, rangeEnd } = useRangeParamWithoutDefaults();
  const [activeGroupId, setActiveGroup] = useActiveGroupId();
  const activeCamIds = useActiveCamIds();
  const setActiveCamIds = useSetActiveCamIds();
  const clothingUpper = useArraySearchFilter("clothingUpper");
  const clothingLower = useArraySearchFilter("clothingLower");
  const [gender] = useGenderParam();
  const age = useArraySearchFilter("age");

  const { data: baseData, error: baseError } = useGetDetectedSubjectBaseQuery({
    ...refetchOnMountPolicy,
    variables: { id: Number(params.id) },
  });

  const {
    data: tracksData,
    error: tracksError,
  } = useGetDetectedSubjectTracksQuery({
    ...refetchOnMountPolicy,
    variables: {
      id: Number(params.id),
      input: {
        objectType: "0",
        startTime: rangeStart?.toISOString(),
        endTime: rangeEnd?.toISOString(),
        groupIds: activeGroupId ? [activeGroupId] : undefined,
        cameraIds: activeCamIds.length ? activeCamIds : undefined,
        attributes: {
          clothingUpper: clothingUpper as ClothingColor[] | undefined,
          clothingLower: clothingLower as ClothingColor[] | undefined,
          gender: gender as Gender | null | undefined,
          age: age?.map((x) => ageBucketMap[x]),
        },
      },
    },
  });

  const stats = useMemo(() => {
    if (!tracksData) return null;
    const { tracks } = tracksData.detectedSubject;
    if (!tracks.length) return null;

    // 80% of the tracks with the highest ageGenderScore
    // The goal is to get rid of outliers
    const bestScoreTracks = orderBy(
      (t) => t.attributes.ageGenderScore,
      "desc",
      tracks
    ).slice(0, Math.ceil(tracks.length * 0.8));

    const ages = bestScoreTracks
      .map((t) => t.attributes.age)
      .filter(filterNullish);
    const minAge = ages.length ? Math.min(...ages) : null;
    const maxAge = ages.length ? Math.max(...ages) : null;
    const genders = bestScoreTracks
      .map((t) => t.attributes.gender)
      .filter(filterNullish);
    const femaleCount = genders.filter((g) => g === Gender.Female).length;
    const uniqueCameras = uniqBy(
      (camera) => camera.id,
      tracks.map((track) => track.camera)
    );
    const uniqueLocations = uniqBy(
      (location) => location.id,
      uniqueCameras.map((camera) => camera.location)
    );

    return {
      age:
        minAge && maxAge
          ? minAge === maxAge
            ? String(minAge)
            : `${minAge}-${maxAge}`
          : null,
      gender: genders.length
        ? femaleCount >= genders.length / 2
          ? Gender.Female
          : Gender.Male
        : null,
      firstAppearance: Math.min(
        ...tracks.map((t) => t.entered).filter(filterNullish)
      ),
      lastAppearance: Math.max(
        ...tracks.map((t) => t.exited).filter(filterNullish)
      ),
      locations: sortBy((l) => l.name, uniqueLocations),
      cameras: sortBy((c) => c.name, uniqueCameras),
    };
  }, [tracksData]);

  if (baseError) {
    return (
      <ErrorMessage
        title="Failed to load person"
        description={baseError.message}
      />
    );
  }

  if (!baseData) {
    return <IntLoadingIndicator className="my-40 mx-auto" />;
  }
  const subject = baseData.detectedSubject;
  const tracksCount = tracksData?.detectedSubject.tracks.length;

  return (
    <div className="flex grow gap-x-8 md:gap-x-12 p-3 md:p-5">
      <div className="flex flex-col shrink-0 gap-y-3 w-56 md:w-64">
        <div className="flex items-center gap-x-4 -ml-3 my-1">
          <SearchParamLink to="..">
            <IconButton color="primary" className="p-0">
              <ChevronLeftIcon fontSize="large" />
            </IconButton>
          </SearchParamLink>
          <h1 className="text-primary text-2xl font-light">Person Details</h1>
        </div>
        <div className="paper p-1">
          <img
            src={subject.bestTrack?.imageSrc ?? ""}
            alt={subject.label ?? "Unknown subject"}
            className="w-full rounded-t"
          />
          {canManageAssignment && (
            <div className="flex items-center gap-1 mt-1">
              <div className="flex-1" />
              <EditableSubjectName subject={subject} />
              <div className="flex-1 flex justify-end shrink-0">
                <RemoveSubjectButton subjectId={subject.id} />
              </div>
            </div>
          )}
        </div>
        {genderAgeAttributeSearch && (stats?.gender || stats?.age) && (
          <div className="text-xs">
            <div>Person Attributes</div>
            <div className="flex flex-col gap-y-1 p-3 mt-2 bg-white rounded-md">
              {stats.gender && (
                <>
                  <div>
                    <span className="mr-1 text-black text-opacity-40">
                      Gender Appearance:
                    </span>
                    {stats.gender}
                  </div>
                  <hr />
                </>
              )}
              {stats.age && (
                <div>
                  <span className="mr-1 text-black text-opacity-40">Age:</span>
                  {stats.age}
                </div>
              )}
            </div>
          </div>
        )}
        {stats && (
          <div className="text-xs">
            <div>{tracksCount} Appearances</div>
            <div className="flex flex-col gap-y-1 p-3 mt-2 bg-white rounded-md">
              {stats.firstAppearance && (
                <>
                  <div className="truncate">
                    <span className="mr-1 text-black text-opacity-40">
                      First:
                    </span>
                    {formatDateYear(stats.firstAppearance)}
                    <strong>{formatTime(stats.firstAppearance)}</strong>
                  </div>
                  <hr />
                </>
              )}
              {stats.lastAppearance && (
                <>
                  <div className="truncate">
                    <span className="mr-1 text-black text-opacity-40">
                      Last:
                    </span>
                    {formatDateYear(stats.lastAppearance)}
                    <strong>{formatTime(stats.lastAppearance)}</strong>
                  </div>
                  <hr />
                </>
              )}
              <div className="flex">
                <span className="mr-1 text-black text-opacity-40">
                  Locations:
                </span>
                <span className="text-primary">
                  {stats.locations.map(({ id, name, tag }, index) => (
                    <Fragment key={id}>
                      <span
                        className="cursor-pointer hover:underline"
                        onClick={() => setActiveGroup(tag?.id)}
                      >
                        {name}
                      </span>
                      {index < stats.locations.length - 1 ? ", " : ""}
                    </Fragment>
                  ))}
                </span>
              </div>
              <hr />
              <div className="flex">
                <span className="mr-1 text-black text-opacity-40">
                  Cameras:
                </span>
                <span className="text-primary">
                  {stats.cameras.map(({ id, name }, index) => (
                    <Fragment key={id}>
                      <span
                        className="cursor-pointer hover:underline"
                        onClick={() => setActiveCamIds([id])}
                      >
                        {name}
                      </span>
                      {index < stats.cameras.length - 1 ? ", " : ""}
                    </Fragment>
                  ))}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="grow">
        <div className="flex items-center flex-wrap gap-y-1 gap-x-6 mb-4">
          <div className="text-2xl font-bold">
            {tracksCount !== undefined ? (
              <>
                This person appears in {tracksCount} video
                {pluralize({ 1: " clip", multi: " clips" }, tracksCount)}
              </>
            ) : (
              "This person appears in these clips"
            )}
          </div>
          {canManageAssignment && (
            <div className="px-2 py-1 border border-blue-medium rounded text-sm bg-white">
              <strong>Tip:</strong>
              <em className="ml-1.5">Get better results by rating.</em>
            </div>
          )}
          <div className="flex items-center gap-x-6 ml-auto">
            <SortBar
              param={sortParamName}
              options={sortOptions}
              defaultValue={defaultSort}
            />
            <FilterMenu />
          </div>
        </div>
        <ActivePeopleFiltersBar />
        <ResultsList
          subject={tracksData?.detectedSubject}
          error={tracksError}
        />
      </div>
    </div>
  );
}

function FilterMenu() {
  const [filterAnchor, setFilterAnchor] = useState<HTMLButtonElement | null>(
    null
  );

  return (
    <>
      <button
        className={clsx(
          "flex items-center gap-x-2.5 px-4 py-1 rounded text-sm border hover:bg-opacity-80 transition-colors",
          Boolean(filterAnchor)
            ? "bg-primary text-white border-primary"
            : "bg-blue-medium text-primary border-[#b5dbfa]"
        )}
        onClick={(e) => setFilterAnchor((v) => (v ? null : e.currentTarget))}
      >
        <FilterListIcon />
        Filters
      </button>
      <Popover
        open={Boolean(filterAnchor)}
        anchorEl={filterAnchor}
        onClose={() => setFilterAnchor(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          className:
            "bg-transparent rounded-2xl shadow-[0_17px_28px_rgba(0,0,0,0.13)] mt-px",
        }}
        elevation={0}
        className="z-20"
      >
        <PeopleFilterBar />
      </Popover>
    </>
  );
}

interface ResultsListProps {
  subject?: GetDetectedSubjectTracksQuery["detectedSubject"];
  error?: ApolloError;
}

const groupPageSize = 5;

function ResultsList({ subject, error }: ResultsListProps) {
  const [search] = useSearchParams();
  const sortParam = search.get(sortParamName) || defaultSort;

  const groupedTracks = useMemo(() => {
    if (!subject) return null;
    const [sort, rawOrder] = sortParam.split("_");
    const order = rawOrder === "desc" ? "desc" : "asc";

    // Order tracks by time
    const sortedTracks = orderBy(
      (t) => t.entered,
      sort === "loc" || sort === "cam" ? "desc" : order,
      subject.tracks
    );

    // Group tracks
    const grouped = Object.entries(
      groupBy((t) => {
        switch (sort) {
          case "loc":
            return t.camera.location.name;
          case "cam":
            return t.camera.name;
          default:
            return startOfHour(t.entered).toISOString();
        }
      }, sortedTracks)
    ).map(([key, value]) => ({ header: key, tracks: value }));

    // Order groups
    return orderBy((g) => g.header, order, grouped);
  }, [subject, sortParam]);

  const {
    open: playerOpen,
    handleOpen: handlePlayerOpen,
    handleClose: handlePlayerClose,
    playerProps,
  } = useDataGridContextPlayer();

  const { pageCount, reset, visibilitySensor } = usePagination(1);
  useUpdateEffect(() => reset(), [sortParam]);

  if (error) {
    return (
      <ErrorMessage title="Failed to load clips" description={error.message} />
    );
  }

  if (!subject || !groupedTracks) {
    return <IntLoadingIndicator className="my-40 mx-auto" />;
  }

  return (
    <>
      {groupedTracks
        .slice(0, pageCount * groupPageSize)
        .map(({ header, tracks }) => (
          <div key={header} className="mb-5 mt-3">
            <div className="text-base font-bold mb-2">
              {!sortParam.startsWith("loc") && !sortParam.startsWith("cam") ? (
                <>
                  {formatTime(new Date(header))}
                  <span className="font-normal">
                    {formatDayDateYear(new Date(header))}
                  </span>
                </>
              ) : (
                header
              )}
            </div>
            <TracksList
              tracks={tracks}
              subjectId={subject.id}
              onClick={handlePlayerOpen}
            />
          </div>
        ))}
      {groupedTracks.length === 0 && (
        <EmptyState description="Change your search filters to find clips" />
      )}
      {groupedTracks.length > groupPageSize && visibilitySensor}
      <DataGridContextualPlayer
        open={playerOpen}
        handleClose={handlePlayerClose}
        playerProps={playerProps}
      />
    </>
  );
}

interface TracksListProps {
  tracks: GetDetectedSubjectTracksQuery["detectedSubject"]["tracks"];
  onClick: (newPlayerProps: IVodContextualPlayer) => void;
  subjectId: number;
}

const clipPageSize = 20;

function TracksList({ tracks, onClick, subjectId }: TracksListProps) {
  const hasPermission = usePermissions();
  const { pageCount, visibilitySensor } = usePagination(1);

  return (
    <div className="flex flex-wrap gap-x-6 gap-y-5">
      {tracks.slice(0, pageCount * clipPageSize).map((item) => {
        const date = new Date(item.entered);
        const endDate = new Date(item.exited);
        const clothingUpper = item.attributes.clothingUpper?.[0];
        const clothingLower = item.attributes.clothingLower?.[0];
        const upperColor = clothingUpper && attributeColorsMap[clothingUpper];
        const lowerColor = clothingLower && attributeColorsMap[clothingLower];

        return (
          <div
            key={item.id}
            className="paper w-40 p-1 cursor-pointer group hover:scale-105 hover:shadow-[0_3px_14px_rgba(0,0,0,0.45)] transition-[transform,box-shadow]"
            onClick={() =>
              onClick({
                camera: item.camera,
                startTime: date.toISOString(),
                endTime: max([addMinutes(1, date), endDate]).toISOString(),
              })
            }
          >
            <div className="relative">
              <img
                src={item.imageSrc || ""}
                alt={"Subject thumbnail"}
                className="w-full h-44 rounded-t object-cover object-center"
              />
              <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-16 h-16 flex-center bg-black bg-opacity-50 text-white rounded-full pointer-events-none opacity-0 group-hover:opacity-100 transition-opacity">
                <PlayArrowIcon className="w-12 h-12" />
              </div>
              {hasPermission("people_assignment_manage") && (
                <SubjectFeedbackButtons track={item} subjectId={subjectId} />
              )}
            </div>
            <div className="px-0.5 text-xs">
              <div className="mt-1.5 text-sm truncate">
                {formatDate(date)}
                <strong>{formatTime(date)}</strong>
              </div>
              <hr className="my-0.5" />
              <div className="truncate">{item.camera.location.name}</div>
              <div className="font-bold truncate">{item.camera.name}</div>
              <hr className="mt-1 mb-1.5" />
              {upperColor && (
                <FilterChip
                  className="w-full pointer-events-none bg-transparent border-none h-5"
                  label={`${upperColor.name} Shirt`}
                  color={upperColor.color}
                  Icon={ClothingUpperIcon}
                />
              )}
              <div className="my-px" />
              {lowerColor && (
                <FilterChip
                  className="w-full pointer-events-none bg-transparent border-none h-5"
                  label={`${lowerColor.name} Pants`}
                  color={lowerColor.color}
                  Icon={ClothingLowerIcon}
                />
              )}
            </div>
          </div>
        );
      })}
      {tracks.length > clipPageSize && visibilitySensor}
    </div>
  );
}

gql`
  query getDetectedSubjectBase($id: Int!) {
    detectedSubject(id: $id) {
      id
      label
      bestTrack {
        imageSrc
      }
    }
  }
`;

gql`
  query getDetectedSubjectTracks($id: Int!, $input: DetectedSubjectsInput!) {
    detectedSubject(id: $id) {
      id
      tracks(input: $input) {
        id
        entered
        exited
        imageSrc
        confirmed
        attributes {
          age
          gender
          ageGenderScore
          clothingUpper
          clothingLower
        }
        camera {
          id
          name
          location {
            id
            name
            timezone
            tag {
              id
            }
          }
          health {
            cameraOnline
            applianceOnline
          }
          settings {
            audioControlEnabled
          }
        }
      }
    }
  }
`;
