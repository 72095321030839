import clsx from "clsx";
import React, { PropsWithChildren } from "react";

import { usePlayerControlsShown } from "@/components/Player/playerControlsMachine";

export function MobileCenterButtons({ children }: PropsWithChildren<{}>) {
  const shown = usePlayerControlsShown();
  return (
    <div
      className={clsx(
        "text-white absolute w-full h-full flex-center flex-nowrap transition-opacity",
        {
          "opacity-0": !shown,
        }
      )}
    >
      {children}
    </div>
  );
}
