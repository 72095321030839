import DownloadIcon from "@mui/icons-material/Download";
import { Button } from "@mui/material";

import { CaseQueryVariables, useDownloads } from "@/components/Downloads";

export function DownloadCaseButton(props: CaseQueryVariables) {
  const { downloadCase } = useDownloads();
  return (
    <Button
      color="primary"
      variant="contained"
      startIcon={<DownloadIcon />}
      onClick={() => downloadCase(props)}
    >
      Download
    </Button>
  );
}
