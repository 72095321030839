import clsx from "clsx";
import { differenceInDays, format } from "date-fns/fp";
import { useMemo } from "react";

import { useTimeRangeParams } from "@/pages/Maintain/hooks";

import { CamfootageMetadataQuery } from "@/generated-models";

function getConnectionLabelClassname(connectionPercentage: number) {
  interface connectionLevelInfo {
    value: number;
    className: string;
  }
  const sortedSteps: connectionLevelInfo[] = [
    {
      value: 0,
      className: "bg-[#FFD9D7] text-[#930000]",
    },
    {
      value: 70,
      className: "bg-[#FFD79B] text-[#935802]",
    },
    {
      value: 95,
      className: "bg-[#CBFFA6] text-[#247100]",
    },
  ];
  let i = 0;
  while (
    i < sortedSteps.length &&
    connectionPercentage >= sortedSteps[i]?.value
  ) {
    i++;
  }
  return sortedSteps[i - 1]?.className;
}

export function MaintainDetialsConnectionSummary({
  now,
  footageMetadata,
}: {
  now: Date;
  footageMetadata: CamfootageMetadataQuery;
}) {
  const missingSegments = footageMetadata?.camera.missingSegments;
  const footageBounds = footageMetadata?.camera.footageBounds;
  const { fromQuery, toQuery } = useTimeRangeParams(now);

  const percConnectedTime = useMemo(() => {
    // Find total disconnection time between the selected time period
    const totalDisconnectionTimeMilSec =
      (missingSegments
        ? missingSegments.reduce((acc: number, currentMissingSegment) => {
            if (
              +currentMissingSegment.start + currentMissingSegment.duration >
                fromQuery.getTime() &&
              +currentMissingSegment.start < toQuery.getTime()
            ) {
              acc +=
                Math.min(
                  +currentMissingSegment.start + currentMissingSegment.duration,
                  toQuery.getTime()
                ) -
                fromQuery.getTime() -
                Math.max(+currentMissingSegment.start - fromQuery.getTime(), 0);
            }
            return acc;
          }, 0)
        : 0) +
      (footageBounds
        ? Math.max(
            toQuery.getTime() - new Date(footageBounds.end).getTime(),
            0
          ) +
          Math.max(
            new Date(footageBounds.start).getTime() - fromQuery.getTime(),
            0
          )
        : 0);

    return Math.max(
      0,
      Math.round(
        (1 -
          totalDisconnectionTimeMilSec /
            (toQuery.getTime() - fromQuery.getTime())) *
          100
      )
    );
  }, [footageBounds, fromQuery, missingSegments, toQuery]);

  const difference = differenceInDays(fromQuery)(toQuery);

  return (
    <div
      className={clsx(
        "rounded text-center p-1 mt-8",
        getConnectionLabelClassname(percConnectedTime)
      )}
    >
      <span className="text-[17.7px]">
        Camera connected {percConnectedTime}% of the time
        {difference === 0 && ` on ${format("do MMM")(fromQuery)}.`}
        {difference === 1 && " over the last day."}
        {difference > 1 &&
          ` over the last ${differenceInDays(fromQuery)(toQuery)} days.`}
      </span>
    </div>
  );
}
