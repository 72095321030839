import { atom, useAtomValue } from "jotai";
import { atomFamily } from "jotai/utils";
import { Participant } from "livekit-client";

export const livekitApplianceParticipantState = atomFamily((url: string) => {
  const applianceAtom = atom(null as Participant | null);
  applianceAtom.debugLabel = `applianceParticipantAtom:${url}`;
  return applianceAtom;
});

export function useLivekitApplianceParticipant(url: string) {
  return useAtomValue(livekitApplianceParticipantState(url));
}
