import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Avatar, Hidden, Typography, Button, Tooltip } from "@mui/material";
import clsx from "clsx";
import { format as formatD } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { capitalize } from "lodash";
import React, { ReactNode, useMemo, useState } from "react";
import { Chart } from "react-charts";
import { BooleanParam, StringParam, useQueryParam } from "use-query-params";

import { PersonIcon } from "@/icons/Person";
import { PersonAbsentIcon } from "@/icons/PersonAbsent";
import { VehicleIcon } from "@/icons/Vehicle";
import { VehicleAbsentIcon } from "@/icons/VehicleAbsent";
import { ReactComponent as Forklift } from "@/icons/forklift.svg";
import { ReactComponent as ForkliftAbsent } from "@/icons/forklift_absent_int.svg";
import { ReactComponent as ExpandIcon } from "@/icons/icon-expand.svg";
import { ReactComponent as MinimizeIcon } from "@/icons/icon-min.svg";

import { timeFormat } from "@/util/date";
import { filterNullish } from "@/util/filterFalsy";
import { pluralize } from "@/util/pluralize";

import {
  DashboardDatePicker,
  Range,
} from "@/pages/Intelligence/IntelligenceDashboardView";

import {
  IdleBucket,
  IdleMetrics,
  IntelligenceDashboardContentQuery,
  IntelligenceDashboardType,
} from "@/generated-models";

import {
  primaryAxis,
  secondaryAxesNonStacked,
  useReactChartStyles,
} from "../ChartConfig";
import { IntLoadingIndicator } from "../IntLoadingIndicator";
import { IntelligenceFullScreenView } from "../IntelligenceFullScreenView";
import {
  CountDirection,
  EntityColors,
  EntityLabel,
  EntityType,
  IntelligenceFeatureType,
} from "../constants";
import { useIntelligenceDateRange } from "../hooks";
import { getIntObjectFilterConfig } from "../utils";
import {
  ContextualStartTimeParam,
  CountDashboardChartContextualView,
} from "./CountDashboardChartContextualView";
import CountEntitiesDatagrid from "./CountEntitiesDatagrid";

export type IdleBuckets = Pick<
  IdleBucket,
  "bucket" | "totalCount" | "idleCount"
>[];

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export type IdleDashboardMetrics = Optional<
  Pick<
    IdleMetrics,
    | "bqJobId"
    | "totalCount"
    | "idleCount"
    | "averageIdleTimeSeconds"
    | "averageNonzeroIdleTimeSeconds"
    | "maxIdleTimeSeconds"
    | "entities"
    | "bucketCounts"
    | "leftCount"
    | "rightCount"
  >,
  "bqJobId"
>;

export function MobileCountDashboard({
  cameraId,
  dashboard,
  timezone,
  feature,
  subtype,
}: {
  cameraId: number;
  intDashId?: number;
  dashboard?: IntelligenceDashboardContentQuery["intelligenceDashboard"];
  timezone: string;
  startDate?: Date;
  endDate?: Date;
  feature: IntelligenceFeatureType;
  subtype: CountDirection;
}) {
  const entity = EntityLabel[feature];

  const { range } = useIntelligenceDateRange();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setFullscreen] = useQueryParam("fullscreen", BooleanParam);

  return (
    <IntelligenceFullScreenView>
      <div className="flex items-center justify-between mt-4 mx-4">
        <div className="text-lg leading-[20px] font-bold">
          {capitalize(entity)} Counts
        </div>
        <DashboardDatePicker cameraId={cameraId} />
        <Button
          startIcon={<MinimizeIcon className="fill-current text-primary" />}
          color="primary"
          onClick={() => setFullscreen(false)}
        >
          Minimize
        </Button>
      </div>
      {!dashboard && <IntLoadingIndicator className="py-10 md:py-20" />}
      {dashboard && (
        <div className="px-5 py-6">
          <CountDashboardChart
            id={dashboard?.id}
            cameraId={cameraId}
            buckets={dashboard?.metrics.results!.bucketCounts}
            timezone={timezone}
            primaryTicks={range === Range.Week ? 7 : undefined}
            entity={entity}
            subtype={subtype}
          />
          <CountDashboardLegend entity={entity} subtype={subtype} />
        </div>
      )}
    </IntelligenceFullScreenView>
  );
}

export function CountDashboardLegend({
  className,
  labelClassName,
  entity,
  subtype,
}: {
  className?: string;
  labelClassName?: string;
  entity: string;
  subtype: CountDirection;
}) {
  return (
    <div
      className={clsx(
        "flex justify-center items-center md:px-0 px-3 mt-6 md:mt-10 mb-2 md:mb-3",
        className
      )}
    >
      {subtype !== CountDirection.Out && (
        <div className="flex items-center">
          <div
            className={clsx("md:h-6 md:w-6 h-[14px] w-[14px] md:rounded mr-2", {
              "bg-[#9947FF]": entity === "people",
              "bg-[#37D3F5]": entity === "vehicle",
              "bg-[#FFB55F]": entity === "forklift",
            })}
          />
          <Typography className={clsx("text-xs md:text-sm", labelClassName)}>
            {capitalize(entity)} In
          </Typography>
        </div>
      )}
      {subtype !== CountDirection.In && (
        <div className="flex items-center ml-2 sm:ml-8">
          <div
            className={clsx("md:h-6 md:w-6 h-[14px] w-[14px] md:rounded mr-2", {
              "bg-[#4A00A9]": entity === "people",
              "bg-[#00AFD6]": entity === "vehicle",
              "bg-[#e07901]": entity === "forklift",
            })}
          />
          <Typography className={clsx("text-xs md:text-sm", labelClassName)}>
            {capitalize(entity)} Out
          </Typography>
        </div>
      )}
    </div>
  );
}

export function CountDashboard({
  id,
  cameraId,
  objectTypes,
  metrics,
  timezone,
  datePicker,
  feature,
  subtype,
}: {
  id?: number;
  cameraId: number;
  objectTypes?: string[] | string | null;
  metrics: IdleDashboardMetrics;
  timezone: string;
  datePicker?: ReactNode;
  feature: IntelligenceFeatureType;
  subtype: CountDirection;
}) {
  const [rangeParam] = useQueryParam("range", StringParam);
  const range = rangeParam ?? Range.Day;
  const entity = EntityLabel[feature];
  const [fullscreen = false, setFullscreen] = useQueryParam(
    "fullscreen",
    BooleanParam
  );

  let TypePresentIcon: React.ElementType = PersonIcon;
  let TypeAbsentIcon: React.ElementType = PersonAbsentIcon;

  if (feature === IntelligenceFeatureType.ForkliftCounting) {
    TypePresentIcon = Forklift;
    TypeAbsentIcon = ForkliftAbsent;
  } else if (feature === IntelligenceFeatureType.VehicleCounting) {
    TypePresentIcon = VehicleIcon;
    TypeAbsentIcon = VehicleAbsentIcon;
  }

  // Filter entities that don't match the subtype (count direction)
  const entities = useMemo(() => {
    return metrics.entities.filter((e) => {
      if (subtype === CountDirection.In) return e.crossedLeft;
      if (subtype === CountDirection.Out) return e.crossedRight;
      return true;
    });
  }, [metrics.entities, subtype]);

  const first = useMemo(() => {
    return [...entities].sort((a, b) => a.firstMs - b.firstMs)[0];
  }, [entities]);

  const last = useMemo(() => {
    return [...entities].sort((a, b) => b.lastMs - a.lastMs)[0];
  }, [entities]);

  const [mostIn, fewestIn] = useMemo(() => {
    const buckets = [...metrics.bucketCounts].sort(
      (a, b) => (b.leftCount ?? 0) - (a.leftCount ?? 0)
    );
    return [buckets[0], buckets[buckets.length - 1]];
  }, [metrics]);

  const [mostOut, fewestOut] = useMemo(() => {
    const buckets = [...metrics.bucketCounts].sort(
      (a, b) => (b.rightCount ?? 0) - (a.rightCount ?? 0)
    );
    return [buckets[0], buckets[buckets.length - 1]];
  }, [metrics]);

  const label = (count: number) =>
    pluralize(
      {
        1: entity === "people" ? "person" : entity,
        multi: entity === "people" ? "people" : `${entity}s`,
      },
      count
    );

  const legend =
    subtype === CountDirection.InAndOut ? (
      <>
        <div className="flex items-center md:ml-auto">
          <div
            className={clsx("md:h-6 md:w-6 h-[14px] w-[14px] md:rounded mr-2", {
              "bg-[#9947FF]": entity === "people",
              "bg-[#37D3F5]": entity === "vehicle",
              "bg-[#FFB55F]": entity === "forklift",
            })}
          />
          <Typography className="text-xs md:text-sm">
            {/*Plural value for entity*/}
            {capitalize(label(2))} In
          </Typography>
        </div>
        <div className="flex items-center ml-8">
          <div
            className={clsx("md:h-6 md:w-6 h-[14px] w-[14px] md:rounded mr-2", {
              "bg-[#4A00A9]": entity === "people",
              "bg-[#00AFD6]": entity === "vehicle",
              "bg-[#e07901]": entity === "forklift",
            })}
          />
          <Typography className="text-xs md:text-sm">
            {capitalize(label(2))} Out
          </Typography>
        </div>
      </>
    ) : null;

  return (
    <>
      <div
        className={clsx("gap-2 md:gap-4 mt-4 md:mt-6 grid grid-cols-2", {
          "sm:grid-cols-3": subtype !== CountDirection.InAndOut,
          "sm:grid-cols-4": subtype === CountDirection.InAndOut,
        })}
      >
        {subtype !== CountDirection.Out && (
          <MetricBlock
            entity={entity}
            metric={
              <>
                {metrics.leftCount}{" "}
                {
                  <span className="text-base">
                    {label(metrics.leftCount ?? 0)}
                  </span>
                }
              </>
            }
            tooltipTitle={`Total count of ${label(
              2
            )} crossing the line going in`}
            label={
              <>
                <strong>{capitalize(label(2))} In</strong>
              </>
            }
            icon={
              <ArrowUpwardIcon className="text-[#353D48] md:text-[#C6C6C6] mb-[2px] h-5 md:p-0 p-0.5" />
            }
          />
        )}
        {subtype !== CountDirection.In && (
          <MetricBlock
            entity={entity}
            secondary
            metric={
              <>
                {metrics.rightCount}{" "}
                {<span className="text-base">{label(2)}</span>}
              </>
            }
            tooltipTitle={`Total count of ${label(
              2
            )} crossing the line going out`}
            label={
              <>
                <strong>
                  {capitalize(label(metrics.rightCount ?? 0))} Out
                </strong>
              </>
            }
            icon={
              <ArrowDownwardIcon className="text-[#353D48] md:text-[#C6C6C6] mb-[2px] h-5 md:p-0 p-0.5" />
            }
          />
        )}
        {range === Range.Day ? (
          <>
            <MetricBlock
              entity={entity}
              secondary={subtype === CountDirection.Out}
              metric={
                first
                  ? timeFormat.format(utcToZonedTime(first.firstMs, timezone))
                  : "N/A"
              }
              tooltipTitle={`The first time a ${label(
                1
              )} was in the camera view`}
              label={<strong>First {capitalize(label(1))}</strong>}
              icon={
                <TypePresentIcon className="text-[#353D48] md:text-[#C6C6C6] h-5 md:p-0 p-0.5" />
              }
            />
            <MetricBlock
              entity={entity}
              secondary={subtype !== CountDirection.In}
              metric={
                last
                  ? timeFormat.format(utcToZonedTime(last.lastMs, timezone))
                  : "N/A"
              }
              tooltipTitle={`The last time a ${label(
                1
              )} was in the camera view`}
              label={
                <>
                  <strong>Last {capitalize(label(1))}</strong>
                </>
              }
              icon={
                <TypeAbsentIcon className="text-[#353D48] md:text-[#C6C6C6] h-5 md:p-0 p-0.5" />
              }
            />
          </>
        ) : (
          <>
            {subtype !== CountDirection.Out && (
              <MetricBlock
                entity={entity}
                metric={
                  mostIn
                    ? `${mostIn.leftCount ?? 0} on ${formatD(
                        utcToZonedTime(mostIn.bucketMs, timezone),
                        "M/d"
                      )}`
                    : "N/A"
                }
                tooltipTitle={`Count and the date that recorded the most  ${label(
                  mostIn.leftCount ?? 0
                )} going in`}
                label={
                  <strong>
                    Most {capitalize(label(metrics.leftCount ?? 0))} In
                  </strong>
                }
                icon={
                  <TypePresentIcon className="text-[#353D48] md:text-[#C6C6C6] h-5 md:p-0 p-0.5" />
                }
              />
            )}
            {subtype !== CountDirection.In && (
              <MetricBlock
                entity={entity}
                secondary
                metric={
                  mostOut
                    ? `${mostOut.rightCount ?? 0} on ${formatD(
                        utcToZonedTime(mostOut.bucketMs, timezone),
                        "M/d"
                      )}`
                    : "N/A"
                }
                tooltipTitle={`Count and the date that recorded the most  ${label(
                  mostIn.leftCount ?? 0
                )} going out`}
                label={
                  <>
                    <strong>Most {capitalize(entity)} Out</strong>
                  </>
                }
                icon={
                  <TypeAbsentIcon className="text-[#353D48] md:text-[#C6C6C6] h-5 md:p-0 p-0.5" />
                }
              />
            )}
            {subtype !== CountDirection.InAndOut && (
              <MetricBlock
                entity={entity}
                secondary={subtype === CountDirection.Out}
                metric={
                  subtype === CountDirection.In
                    ? fewestIn
                      ? `${fewestIn.leftCount ?? 0} on ${formatD(
                          utcToZonedTime(fewestIn.bucketMs, timezone),
                          "M/d"
                        )}`
                      : "N/A"
                    : fewestOut
                    ? `${fewestOut.rightCount ?? 0} on ${formatD(
                        utcToZonedTime(fewestOut.bucketMs, timezone),
                        "M/d"
                      )}`
                    : "N/A"
                }
                tooltipTitle={`Count and the date that recorded the most  ${label(
                  mostIn.leftCount ?? 0
                )} going ${subtype === CountDirection.In ? "in" : "out"}`}
                label={
                  <>
                    <strong>{`Fewest ${capitalize(entity)} ${
                      subtype === CountDirection.In ? "In" : "Out"
                    }`}</strong>
                  </>
                }
                icon={
                  <TypeAbsentIcon className="text-[#353D48] md:text-[#C6C6C6] h-5 md:p-0 p-0.5" />
                }
              />
            )}
          </>
        )}
      </div>
      <div className="flex items-center md:px-0 px-3 mt-6 md:mt-10 mb-2 md:mb-3">
        <Typography className="text-sm font-medium">
          {capitalize(entity)} Counting
        </Typography>
        <Hidden mdDown>{legend}</Hidden>
        <Hidden mdUp>
          <div className="grow"></div>
          {metrics.bucketCounts.length > 0 && (
            <Button
              startIcon={<ExpandIcon className="fill-current text-primary" />}
              color="primary"
              onClick={() => setFullscreen(true)}
            >
              Expand
            </Button>
          )}
        </Hidden>
      </div>
      {metrics.bucketCounts.length > 0 ? (
        <div className="md:px-0 px-3">
          <CountDashboardChart
            id={id}
            cameraId={cameraId}
            buckets={metrics.bucketCounts}
            timezone={timezone}
            primaryTicks={range === Range.Week ? 7 : undefined}
            entity={entity}
            subtype={subtype}
          />
        </div>
      ) : (
        <div className="h-40 md:h-52 flex-center">
          <div>No available data</div>
        </div>
      )}
      <Hidden mdUp>
        <div className="flex-center pt-3">{legend}</div>
      </Hidden>

      <div className="mt-8">
        <CountEntitiesDatagrid
          objectIds={
            getIntObjectFilterConfig(
              objectTypes,
              IntelligenceDashboardType.Count
            ).objectIds
          }
          entities={entities}
          cameraId={cameraId}
          timezone={timezone}
          subtype={subtype}
          range={range}
        />
      </div>
      <Hidden mdUp>
        {fullscreen && (
          <IntelligenceFullScreenView>
            <div className="flex items-center justify-between mt-4 mx-4">
              <div className="text-lg leading-[20px] font-bold">
                Activity Map
              </div>
              {datePicker}
              <Button
                startIcon={
                  <MinimizeIcon className="fill-current text-primary" />
                }
                color="primary"
                onClick={() => setFullscreen(false)}
              >
                Minimize
              </Button>
            </div>
            <div className="px-5 py-6">
              <CountDashboardChart
                id={id}
                cameraId={cameraId}
                buckets={metrics.bucketCounts}
                timezone={timezone}
                primaryTicks={range === Range.Week ? 7 : undefined}
                entity={entity}
                subtype={subtype}
              />
              <div className="flex justify-center items-center md:px-0 px-3 mt-6 md:mt-10 mb-2 md:mb-3">
                {legend}
              </div>
            </div>
          </IntelligenceFullScreenView>
        )}
      </Hidden>
    </>
  );
}

export function MetricBlock({
  metric,
  label,
  icon,
  className,
  secondary,
  tooltipTitle = "",
  entity,
}: {
  metric: React.ReactNode;
  label: React.ReactNode;
  icon?: React.ReactNode;
  className?: string;
  secondary?: boolean;
  tooltipTitle?: string;
  entity: EntityType;
}) {
  return (
    <Tooltip title={tooltipTitle}>
      <div
        className={clsx(
          "rounded-lg px-2 py-3 md:p-4 min-h-[88px] md:min-h-[unset] h-full shadow-paper flex-grow flex flex-col justify-between",
          className
        )}
      >
        <div className="flex flex-row justify-bewteen mb-1 md:mb-6">
          <div className="text-sm md:text-xl flex-grow leading-[0.875rem] md:leading-none">
            {label}
          </div>
          {icon && (
            <Avatar className="md:w-8 md:h-8 w-5 h-5 border-solid rounded-full border-gray-200 border md:border-4 bg-transparent text-gray-400 flex justify-center items-center">
              {icon}
            </Avatar>
          )}
        </div>
        <div
          className={clsx("text-2xl md:text-[38px] leading-none font-bold", {
            "text-[#4A00A9]": secondary && entity === "people",
            "text-[#9947FF]": !secondary && entity === "people",
            "text-[#00AFD6]": secondary && entity === "vehicle",
            "text-[#37D3F5]": !secondary && entity === "vehicle",
            "text-[#e07901]": secondary && entity === "forklift",
            "text-[#FFB55F]": !secondary && entity === "forklift",
          })}
        >
          {metric}
        </div>
      </div>
    </Tooltip>
  );
}

function CountDashboardChart({
  id,
  cameraId,
  buckets,
  timezone,
  primaryTicks,
  entity,
  subtype,
}: {
  id?: number;
  cameraId: number;
  buckets: IdleMetrics["bucketCounts"];
  timezone: string;
  primaryTicks?: number;
  entity: EntityType;
  subtype: CountDirection;
}) {
  const [contextualViewOpen, setContextualViewOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setStartTime] = useQueryParam(
    "contextualStartTime",
    ContextualStartTimeParam
  );
  const { classes } = useReactChartStyles();
  const chartData = useMemo(() => {
    const mapped = buckets.map((b) => ({
      date: utcToZonedTime(new Date(b.bucketMs), timezone),
      leftCount: b.leftCount || 0,
      rightCount: b.rightCount || 0,
    }));
    return [
      subtype !== CountDirection.Out
        ? {
            label: `${capitalize(entity)} In`,
            data: mapped.map((b) => ({
              primary: b.date,
              secondary: b.leftCount,
            })),
          }
        : null,
      subtype !== CountDirection.In
        ? {
            label: `${capitalize(entity)} Out`,
            data: mapped.map((b) => ({
              primary: b.date,
              secondary: b.rightCount,
            })),
          }
        : null,
    ].filter(filterNullish);
  }, [buckets, entity, timezone, subtype]);

  const defaultColors = EntityColors[entity];

  return (
    <div className={clsx("h-40 sm:h-60 md:h-80", classes.hideChartLines)}>
      {id && (
        <CountDashboardChartContextualView
          id={id}
          defaultColors={defaultColors}
          cameraId={cameraId}
          timezone={timezone}
          primaryTicks={primaryTicks}
          entity={entity}
          subtype={subtype}
          open={contextualViewOpen}
          onToggle={() => setContextualViewOpen(!contextualViewOpen)}
        />
      )}
      <Chart
        options={{
          onClickDatum: (datum, event) => {
            if (datum?.primaryValue) {
              setStartTime(
                zonedTimeToUtc(datum.primaryValue, timezone).toISOString()
              );
              setContextualViewOpen(true);
            }
          },
          data: chartData,
          primaryAxis: primaryAxis(primaryTicks, { innerSeriesBandPadding: 0 }),
          secondaryAxes: secondaryAxesNonStacked,
          primaryCursor: { showLabel: false },
          secondaryCursor: { showLabel: false },
          defaultColors:
            subtype === CountDirection.Out
              ? defaultColors.reverse()
              : defaultColors,
        }}
      />
    </div>
  );
}
