import AddIcon from "@mui/icons-material/AddCircle";
import AdminIcon from "@mui/icons-material/Stars";
import {
  Button,
  Dialog,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { formatDistance } from "date-fns";
import gql from "graphql-tag";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUpdateEffect } from "react-use";
import { makeStyles } from "tss-react/mui";
import { StringParam, useQueryParam } from "use-query-params";

import { AddMultipleIcon, SsoIcon } from "@/icons/Icons";

import { useBreakpoints } from "@/util/useBreakpoints";
import { useDocumentTitle } from "@/util/useDocumentTitle";
import { usePagination } from "@/util/usePagination";
import { ConnectedTableSortLabel, useTableSort } from "@/util/useTableSort";

import { AddBulkModal } from "@/pages/Settings/UserManagement/AddBulkModal";
import { AddSsoConnectionModal } from "@/pages/Settings/UserManagement/SsoConnections";
import { authProviderDisplayNames } from "@/pages/Settings/UserManagement/UserSettingsUtils";

import { isDemoUser, useMe } from "@/components/Auth";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { QueryParamLink } from "@/components/shared/QueryParamLink";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  Page_UsersQuery,
  Page_UsersQueryResult,
  Role,
  useGroupsQuery,
  useMeInUsersListQuery,
  usePage_UsersQuery,
  UserForOrgFragmentDoc,
} from "@/generated-models";
import { usePermissions } from "@/hooks/usePermissions";

import { UserManagementMobileSearchBox } from "./UserManagementMobileSearchBox";

const useStyles = makeStyles()((theme) => ({
  tableHead: {
    "& > th": {
      padding: "12px 16px",
      borderBottom: "1px solid rgba(200, 200, 200, 1)",
    },
  },
  userSummaryMode: {
    "& > td": { border: "initial", padding: 16 },
    "&:nth-of-type(odd)": { backgroundColor: "#F6FBFF" },
  },
  userSummaryAdmin: {
    cursor: "pointer",
    [theme.breakpoints.up("md")]: {
      "&:hover": { backgroundColor: "#DDEFFF" },
    },
  },
  providerInfo: { fontWeight: 400, fontSize: 12, opacity: 0.5 },
  roleCell: {
    display: "flex",
    alignItems: "center",
    textTransform: "capitalize",
    fontSize: 12,
    whiteSpace: "nowrap",
    "& svg": { marginLeft: 4, fontSize: 11 },
  },
}));

export function UserSettings() {
  useDocumentTitle("Users");
  const user = useMe();
  const isDemo = !!user && isDemoUser(user); // only relevant for demo orgs
  const { fitsDesktop } = useBreakpoints();
  const [addingBulk, setAddingBulk] = useState(false);
  const [ssoModalOpen, setSsoModal] = useState(false);
  const hasPermission = usePermissions();

  if (!user) {
    return (
      <Typography>Unable to load current user. Are you logged in?</Typography>
    );
  }
  const hasUsersManagePermission = hasPermission("users_manage");

  return (
    <>
      <UserManagementMobileSearchBox />

      {(isDemo || hasUsersManagePermission) && (
        <div className="bg-[#DDEFFF] py-1 flex items-center justify-center md:justify-end gap-1 md:shadow-inner">
          <Button
            startIcon={<AddIcon className="text-[18px]" />}
            color="primary"
            disabled={isDemo}
            className="text-xs font-normal"
            classes={{ startIcon: "mr-1 " }}
            component={QueryParamLink}
            to="new"
          >
            {fitsDesktop ? "New User" : "User"}
          </Button>
          <Divider orientation="vertical" className="h-2.5 self-center" />
          <Button
            startIcon={<AddMultipleIcon className="text-[22px]" />}
            color="primary"
            disabled={addingBulk || isDemo}
            onClick={() => setAddingBulk(true)}
            className="text-xs font-normal"
            classes={{ startIcon: "mr-1 " }}
          >
            Multiple Users
          </Button>
          <Divider orientation="vertical" className="h-2.5 self-center" />
          <Button
            startIcon={<SsoIcon className="text-[22px]" />}
            color="primary"
            onClick={() => setSsoModal(true)}
            className="text-xs font-normal"
            classes={{ startIcon: "mr-1 " }}
          >
            {fitsDesktop ? "Setup Single Sign-On" : "Single Sign-On"}
          </Button>
        </div>
      )}

      <UserList />

      <Dialog
        PaperProps={{ sx: { maxWidth: "700px" } }}
        open={addingBulk}
        closeAfterTransition
      >
        <AddBulkModal close={() => setAddingBulk(false)} />
      </Dialog>
      <Dialog
        open={ssoModalOpen}
        onClose={() => setSsoModal(false)}
        closeAfterTransition
      >
        <AddSsoConnectionModal close={() => setSsoModal(false)} />
      </Dialog>
    </>
  );
}

const pageSize = 30;

/**
 * Fetches the list of users. If the user has the `users_access` permission,
 * then all users are fetched. Otherwise, only the current user is fetched.
 */
function useUsersList(): {
  usersList?: NonNullable<Page_UsersQueryResult["data"]>["users"];
  error?: Error;
} {
  const hasPermission = usePermissions();
  const hasUsersAccessPermission = hasPermission("users_access");
  const { data: meInUserList, error: meError } = useMeInUsersListQuery({
    skip: hasUsersAccessPermission,
  });
  const { data: usersListData, error: usersListError } = usePage_UsersQuery({
    ...refetchOnMountPolicy,
    skip: !hasUsersAccessPermission,
  });

  if (hasUsersAccessPermission)
    return { usersList: usersListData?.users, error: usersListError };

  return {
    usersList: meInUserList?.me ? [meInUserList.me] : undefined,
    error: meError,
  };
}

function UserList() {
  const { classes } = useStyles();
  const me = useMe();
  const hasPermission = usePermissions();
  const hasEditUserPermission = hasPermission("users_manage");
  const { fitsDesktop, fitsTablet } = useBreakpoints();
  const { usersList, error } = useUsersList();
  const { data: groupData } = useGroupsQuery(refetchOnMountPolicy);
  const [searchInputParam] = useQueryParam("search", StringParam);
  const searchQuery = searchInputParam?.toLowerCase();
  const { pageCount, reset, visibilitySensor } = usePagination(1);
  const navigate = useNavigate();
  const { "*": splat } = useParams();
  const rawUserId = splat?.split("/")[0];
  const userId =
    (rawUserId === "me" ? me?.orgUserId : Number(rawUserId)) || null; // coerce empty string to null
  useUpdateEffect(() => reset(), [searchQuery]);

  const filteredUsersBySearch = !searchQuery
    ? usersList
    : usersList?.filter((u) =>
        [u.profile.email, u.profile.name].some((field) =>
          field.toLowerCase().includes(searchQuery)
        )
      );

  const flattenedUsers = filteredUsersBySearch?.map((u) => ({
    ...u,
    ...u.profile,
    id: u.id,
  }));

  const { sortedRows: sortedUsers, ...sortLabelParams } = useTableSort(
    flattenedUsers,
    "name"
  );

  if (error) {
    return (
      <ErrorMessage
        title="Oops"
        description="We are having trouble loading the users. Please refresh or try again later."
      />
    );
  }
  if (!sortedUsers || !groupData) {
    return (
      <div style={{ padding: "48px 0" }}>
        <Loading>Loading users...</Loading>
      </div>
    );
  }

  return (
    <Table className="shadow-inner md:shadow-none">
      <TableHead>
        <TableRow className={classes.tableHead}>
          <TableCell>
            {!fitsTablet && <span className="font-normal">Sort by: </span>}
            <ConnectedTableSortLabel
              name="name"
              {...sortLabelParams}
              classes={{ active: "text-primary" }}
            >
              Name
            </ConnectedTableSortLabel>
          </TableCell>
          {fitsTablet && (
            <TableCell>
              <ConnectedTableSortLabel name="email" {...sortLabelParams}>
                Email
              </ConnectedTableSortLabel>
            </TableCell>
          )}
          <TableCell>
            <ConnectedTableSortLabel name="role" {...sortLabelParams}>
              Role
            </ConnectedTableSortLabel>
          </TableCell>
          {fitsDesktop && (
            <TableCell>
              <ConnectedTableSortLabel name="lastLoginAt" {...sortLabelParams}>
                Last Sign-on
              </ConnectedTableSortLabel>
            </TableCell>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedUsers.slice(0, pageCount * pageSize).map((user) => {
          const isMe = me?.id === user.id;
          const selected = userId === user.orgUserId;
          const isEditable =
            (hasEditUserPermission || isMe) &&
            // internal roles are only editable from Spot AI HQ
            (user.platformRole < Role.Spot || me?.organization.id === 1);
          return (
            <TableRow
              key={user.id}
              className={clsx(
                "transition-colors transation-transform border-b border-solid border-[#ececec]",
                classes.userSummaryMode,
                {
                  [classes.userSummaryAdmin]: isEditable,
                  "bg-blue-medium": selected,
                }
              )}
              onClick={
                isEditable
                  ? () =>
                      navigate(
                        (selected // toggle detail panel
                          ? `./` // /users
                          : `${user.orgUserId}`) + // /users/123
                          window.location.search,

                        // todo: Should we history.replace() here?
                        {
                          replace: userId !== null && userId !== user.id,
                        }
                      )
                  : undefined
              }
              // onClick={isEditable ?  : undefined}
              data-cy="user-row"
            >
              <UserRowSummary
                profile={user.profile}
                rolev2={user.rolev2}
                authProvider={user.authProvider}
                lastLoginAt={user.lastLoginAt}
              />
            </TableRow>
          );
        })}
        <TableRow>
          <TableCell colSpan={5}>
            {sortedUsers.length > pageSize && visibilitySensor}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

type User = Page_UsersQuery["users"][0];

const UserRowSummary = React.memo(function UserRowSummary({
  profile: {
    name,
    email,
    // platformRole
  },
  rolev2,
  authProvider,
  lastLoginAt,
}: Pick<User, "profile" | "rolev2" | "authProvider" | "lastLoginAt">) {
  const { classes } = useStyles();
  const { fitsDesktop, fitsTablet } = useBreakpoints();

  const provider = (
    <div className={classes.providerInfo}>
      {authProviderDisplayNames[authProvider]}
    </div>
  );

  return (
    <>
      <TableCell style={{ fontWeight: "bold" }}>
        {name}
        {!fitsTablet && provider}
      </TableCell>
      {fitsTablet && (
        <TableCell>
          {email}
          {provider}
        </TableCell>
      )}
      <TableCell>
        <div className={classes.roleCell}>
          <Tooltip title={rolev2?.name}>
            <span className="truncate max-w-[100px]">
              {rolev2?.name ?? <em className="opacity-50">No role selected</em>}
              {rolev2?.builtIn && <AdminIcon />}
            </span>
          </Tooltip>
        </div>
      </TableCell>
      {fitsDesktop && (
        <TableCell
          style={{
            fontSize: 12,
            opacity: lastLoginAt ? 1 : 0.5,
            fontStyle: lastLoginAt ? "initial" : "italic",
          }}
        >
          {lastLoginAt
            ? formatDistance(new Date(lastLoginAt), new Date(), {
                addSuffix: true,
              })
            : "Never signed in"}
        </TableCell>
      )}
    </>
  );
});

gql`
  query meInUsersList {
    me {
      ...UserForOrg
    }
  }
  ${UserForOrgFragmentDoc}
`;

gql`
  fragment UserForOrg on User {
    id
    orgUserId
    profile {
      id
      name
      email
      phone
      # platformRole only available after deploying api
    }
    platformRole: role # TODO: replace with profile.platformRole
    allLocations
    rolev2 {
      id
      name
      builtIn

      permissions {
        id
        name
        description
        enabled
      }
    }
    authProvider
    lastLoginAt
    tags {
      id
      name
    }
  }
`;

gql`
  query userDetails($orgUserId: Int!) {
    user(orgUserId: $orgUserId) {
      ...UserForOrg
    }
  }
  ${UserForOrgFragmentDoc}
`;

gql`
  query meDetails {
    user: me {
      ...UserForOrg
    }
  }
  ${UserForOrgFragmentDoc}
`;

gql`
  mutation addUser($input: AddUserInput!) {
    addUser(input: $input) {
      ...UserForOrg
    }
  }
  ${UserForOrgFragmentDoc}
`;

gql`
  query page_users {
    users {
      ...UserForOrg
    }
  }
  ${UserForOrgFragmentDoc}
`;

gql`
  mutation removeUser($id: Int, $orgUserId: Int) {
    removeUser(id: $id, orgUserId: $orgUserId)
  }
`;

gql`
  mutation updateUser($id: Int, $orgUserId: Int, $updates: UserInput!) {
    updateUser(id: $id, orgUserId: $orgUserId, updates: $updates) {
      ...UserForOrg
    }
  }
  ${UserForOrgFragmentDoc}
`;

gql`
  mutation updateSelf($updates: UpdateSelfInput!) {
    updateSelf(updates: $updates) {
      ...UserForOrg
    }
  }
  ${UserForOrgFragmentDoc}
`;

gql`
  mutation resendUserEmail($id: Int!) {
    resendUserEmail(id: $id) {
      message
    }
  }
`;
