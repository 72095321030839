import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import FileIcon from "@mui/icons-material/FilePresent";
import LinkIcon from "@mui/icons-material/Link";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  Link,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import gql from "graphql-tag";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";

import FileUploader from "@/components/FileUploader/FileUploader";
import {
  isMobileApp,
  mobileFeatureFlags,
} from "@/components/Mobile/mobileEnvironment";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";

import {
  CaseAttachment,
  CaseAttachmentBaseFragmentDoc,
  CaseAttachmentType,
  SharedCaseAttachment,
  useAddCaseAttachmentsMutation,
  useRemoveCaseAttachmentMutation,
} from "@/generated-models";

export type Attachment = CaseAttachment | SharedCaseAttachment;

interface AttachmentButtonProps {
  caseId: number;
  attachments: Attachment[];
  className?: string;
}

export function AddAttachmentButton({
  className,
  ...props
}: AttachmentButtonProps) {
  const [opened, setOpened] = useState(false);
  return (
    <>
      <Button
        color="primary"
        className={clsx("text-md font-normal", className)}
        onClick={() => setOpened(true)}
      >
        + Add/edit a link or file
      </Button>
      {opened && (
        <AddAttachmentModal {...props} close={() => setOpened(false)} />
      )}
    </>
  );
}

function AddAttachmentModal({
  caseId,
  attachments,
  close,
}: AttachmentButtonProps & {
  close: () => void;
}) {
  const { pushSnackbar } = useFeedback();
  const [addAttachments] = useAddCaseAttachmentsMutation({
    update(cache, { data }) {
      if (data) {
        cache.modify({
          id: `Case:${caseId}`,
          fields: {
            attachments(existing = []) {
              const newAttachments = data.addCaseAttachments.map((attachment) =>
                cache.writeFragment({
                  data: attachment,
                  fragment: CaseAttachmentBaseFragmentDoc,
                })
              );
              return [...existing, ...newAttachments];
            },
          },
        });
      }
    },
    onError: () =>
      pushSnackbar(
        "Failed to upload attachment(s), please try again",
        FeedbackType.Error
      ),
  });
  const [
    removeAttachment,
    { loading: removing },
  ] = useRemoveCaseAttachmentMutation({
    update(cache, { data }) {
      if (data?.removeCaseAttachment.deleted) {
        cache.evict({ id: `CaseAttachment:${data.removeCaseAttachment.id}` });
      }
    },
    onCompleted: () =>
      pushSnackbar(
        "Successfully removed attachment from case",
        FeedbackType.Success
      ),
    onError: () =>
      pushSnackbar(
        "Failed to remove attachment, please try again",
        FeedbackType.Error
      ),
  });

  const [uploading, setUploading] = useState(false);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    disabled: uploading,
    // accept: {
    //   "application/pdf": [],
    //   "image/*": [],
    //   "text/*": [],
    // },
    maxSize: 10e6, // 10mb
    onDropAccepted: async (files) => {
      setUploading(true);
      await addAttachments({ variables: { caseId, files } });
      setUploading(false);
    },
    onDropRejected: (rejections) => {
      if (rejections[0]) {
        pushSnackbar(rejections[0].errors[0].message, FeedbackType.Error);
      }
    },
  });

  return (
    <Dialog open={true} onClose={close}>
      <DialogTitle className="flex justify-between items-center py-2 bg-[#F4F4F4]">
        <Typography variant="h3" component="span">
          Add Link or File
        </Typography>
        <IconButton onClick={close}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="p-5">
        <Formik
          validateOnBlur
          initialValues={{ url: "" }}
          onSubmit={(values, { resetForm, setSubmitting }) => {
            const url = values.url.trim();
            if (url) {
              return addAttachments({
                variables: { caseId, links: [url] },
                onCompleted: () => resetForm(),
              });
            }
            setSubmitting(false);
          }}
        >
          {({ isSubmitting, isValid, values }) => (
            <Form>
              <Field
                name="url"
                component={TextField}
                label="Link URL"
                placeholder="https://www.example.com"
                variant="outlined"
                fullWidth
                validate={(input: string) => {
                  if (!input.trim()) return undefined;
                  try {
                    new URL(input);
                  } catch {
                    return "Invalid URL";
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting || !isValid || !values.url}
                      >
                        Add Link
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </Form>
          )}
        </Formik>
        {!(isMobileApp && (window as any).Android) && (
          <FileUploader
            loading={uploading}
            state={{
              getRootProps,
              getInputProps,
              isDragActive,
            }}
          />
        )}
        {attachments.length > 0 && (
          <Typography variant="h4" className="mt-7 mb-2 text-base font-medium">
            Your Links and Files
          </Typography>
        )}
        {attachments.map((item) => (
          <React.Fragment key={item.id}>
            <Divider className="my-1" />
            <div className="flex items-center">
              {item.type === CaseAttachmentType.Link ? (
                <LinkIcon />
              ) : (
                <FileIcon />
              )}
              {isMobileApp && !mobileFeatureFlags.externalLinks ? (
                <span className="ml-1 font-medium truncate">{item.label}</span>
              ) : (
                <Link
                  underline="hover"
                  className="ml-1 font-medium truncate"
                  href={item.url ?? undefined}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.label}
                </Link>
              )}
              <IconButton
                size="small"
                className="ml-auto text-[#BDBDBD]"
                disabled={removing}
                onClick={() =>
                  removeAttachment({
                    variables: { caseId, attachmentId: item.id },
                  })
                }
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </div>
          </React.Fragment>
        ))}
      </DialogContent>
    </Dialog>
  );
}

gql`
  mutation addCaseAttachments(
    $caseId: Int!
    $files: [Upload!]
    $links: [String!]
  ) {
    addCaseAttachments(caseId: $caseId, files: $files, links: $links) {
      id
      type
      label
      url
    }
  }
`;

gql`
  mutation removeCaseAttachment($caseId: Int!, $attachmentId: Int!) {
    removeCaseAttachment(caseId: $caseId, attachmentId: $attachmentId) {
      id
      deleted
    }
  }
`;
