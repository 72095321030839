import React from "react";

export interface Position {
  x: number;
  y: number;
}

export function subtractPosition(base: Position, subtract: Position | null) {
  if (!subtract) return base;

  return {
    x: base.x - subtract.x,
    y: base.y - subtract.y,
  };
}
export function addPosition(base: Position, add: Position | null) {
  if (!add) return base;

  return {
    x: base.x + add.x,
    y: base.y + add.y,
  };
}

type KeysMatching<T, V> = {
  [K in keyof T]: T[K] extends V ? K : never;
}[keyof T];
type TouchEvents = KeysMatching<HTMLElementEventMap, TouchEvent>;
type MouseEvents = KeysMatching<HTMLElementEventMap, MouseEvent>;

function isTouchEvent(e: MouseEvent | TouchEvent): e is TouchEvent {
  return Boolean((e as any).touches);
}

export function addPointerListener(
  mouseEvent: MouseEvents,
  pointerEvent: TouchEvents,
  target: HTMLElement | Window,
  handler: (position: Position, button?: number) => void
) {
  function positionHandler(e: MouseEvent | TouchEvent) {
    e.preventDefault();
    // e.stopPropagation();

    const event = isTouchEvent(e) ? e.touches[0] : e;
    handler(
      {
        x: event?.clientX,
        y: event?.clientY,
      },
      isTouchEvent(e) ? undefined : e.button
    );
  }
  (target as HTMLElement).addEventListener(mouseEvent, positionHandler, {
    passive: false,
  });
  (target as HTMLElement).addEventListener(pointerEvent, positionHandler, {
    passive: false,
  });

  return () => {
    (target as HTMLElement).removeEventListener(mouseEvent, positionHandler);
    (target as HTMLElement).removeEventListener(pointerEvent, positionHandler);
  };
}

function isPosition(
  candidate: React.MouseEvent | MouseEvent | React.Touch | Touch | Position
): candidate is Position {
  return candidate.hasOwnProperty("x") && candidate.hasOwnProperty("y");
}
/**
 * Returns the x/y coords in fractions relative to e.currentTarget
 */
export function getRelativeMousePosition(
  e: React.MouseEvent | MouseEvent,
  target?: HTMLElement
): Position;
/**
 * Returns the x/y coords in fractions relative to the target
 */
export function getRelativeMousePosition(
  e: React.Touch | Touch,
  target: HTMLElement
): Position;
export function getRelativeMousePosition(
  e: Position,
  target: HTMLElement
): Position;
export function getRelativeMousePosition(
  e: React.MouseEvent | MouseEvent | React.Touch | Touch | Position,
  target?: HTMLElement
) {
  const position = isPosition(e) ? e : { x: e.clientX, y: e.clientY };
  const rect = (
    target ?? ((e as React.MouseEvent).currentTarget as HTMLElement)
  ).getBoundingClientRect();
  const x = position.x - rect.left; // x position within the element.
  const y = position.y - rect.top; // y position within the element.
  return { x: x / rect.width, y: y / rect.height };
}

export function getRelativeTouchPosition(event: React.TouchEvent) {
  const node = event.currentTarget as HTMLElement;
  const position = {
    x: event.nativeEvent.targetTouches[0].clientX,
    y: event.nativeEvent.targetTouches[0].clientY,
  };

  const rect = node.getBoundingClientRect();
  const x = position.x - rect.left;
  const y = position.y - rect.top;

  return {
    x: x / rect.width,
    y: y / rect.height,
  };
}
