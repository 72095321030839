import { useAtomValue } from "jotai";
import { capitalize } from "lodash";

import { formatDurationSecs } from "@/util/formatDurationSecs";

import { IntelligenceDashboardType } from "@/generated-models";

import { getObjectLabel } from "../utils";
import { compContentTabAtom } from "./Content/ComparativeDashboardContentTabs";
import { ComparativeTabType } from "./constant";

export function useComparativeDescriptions(
  type: IntelligenceDashboardType,
  thresholdSeconds: number,
  objectTypes?: string[] | null | undefined
): [string] | [string, string] {
  const tab = useAtomValue(compContentTabAtom);
  const label = capitalize(getObjectLabel(objectTypes, type, true));

  const duration = formatDurationSecs(thresholdSeconds, {
    hideZeroes: true,
    long: true,
  });

  switch (tab) {
    case ComparativeTabType.IDLE_PERCENTAGE:
      return [`% ${label} Idle >${duration}`];
    case ComparativeTabType.IDLE_COUNT:
      return [`Total ${label}`, `${label} Idle >${duration}`];
    case ComparativeTabType.COUNT_IN_OUT:
      return [`${label} In`, `${label} Out`];
    case ComparativeTabType.COUNT_IN:
      return [`${label} In`];
    case ComparativeTabType.COUNT_OUT:
      return [`${label} Out`];
    default:
      return [`${label} Present`, `${label} Absent`];
  }
}
