import { format } from "date-fns/fp";
import { EChartsOption, LineSeriesOption } from "echarts";

export const lineTimeseriesConfig = {
  type: "line",
  symbolSize: 0,
  dimensions: ["timestamp", "value"],
} as Partial<LineSeriesOption>;

const gridConfig = {
  top: "4%",
  left: "2%",
  right: "2%",
  bottom: "10%",
  containLabel: true,
};

const legendConfig = {
  itemGap: 18,
  itemWidth: 20,
  icon: "circle",
  top: "bottom",
};

export const getToolboxConfig = (imageName: string) => ({
  top: "bottom",
  show: true,
  showTitle: false,
  feature: {
    saveAsImage: {
      name: imageName,
    },
  },
});

export const getBaseOptions = (imageName: string) => {
  return {
    responsive: true,
    toolbox: getToolboxConfig(imageName),
    legend: legendConfig,
    grid: gridConfig,
  };
};

export const getBaseBrushOptions = (
  imageName: string,
  valueFormatter?: (value: unknown) => string
) => {
  return {
    responsive: true,
    grid: {
      show: true,
      top: "4%",
      left: 4,
      right: 4,
      bottom: "28%",
      containLabel: true,
    },
    legend: undefined,
    toolbox: {
      top: "bottom",
      show: true,
      showTitle: true,
      right: -6,
      feature: {
        dataZoom: {
          xAxisIndex: 0,
          yAxisIndex: false,
        },
        saveAsImage: {
          name: imageName,
        },
      },
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        label: {
          formatter: ({ value }: { value: any }) => {
            return format("MMM, d, hh:mm a")(new Date(value));
          },
        },
      },
      valueFormatter,
    },
    xAxis: {
      type: "time",
      boundaryGap: ["5%", "5%"],
      minorSplitLine: {
        show: true,
      },
    },
    dataZoom: [
      {
        type: "slider",
        xAxisIndex: 0,
        start: 0,
        end: 100,
        height: 20,
        bottom: 50,
      },
    ],
    yAxis: {
      type: "value",
      boundaryGap: ["20%", "20%"],
      min: 0,
      scale: true,
      axisLabel: {},
    },
  } as EChartsOption;
};
