import clsx from "clsx";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useCallback, useState } from "react";

import { bestMatch } from "@/util/bestMatch";
import {
  getRelativeMousePosition,
  getRelativeTouchPosition,
} from "@/util/mouseEvents";
import { useBreakpoints } from "@/util/useBreakpoints";
import { useThrottle } from "@/util/useThrottle";

import { ScrubImages } from "@/components/Camera/ExpandingSnippetPreview";
import {
  useMultiCamVodTime,
  useMultiPlayerControls,
} from "@/components/Player/PlayerBase";

import {
  PlayerControlsEvent,
  usePlayerControlsService,
} from "./playerControlsMachine";

export function ProgressBar({
  stills,
}: {
  stills?: { timestamp: number; src: string }[];
}) {
  const { fitsDesktop } = useBreakpoints();
  const time = useMultiCamVodTime();
  const { seek } = useMultiPlayerControls();
  const { scrubbingMobileVodPlayer } = useFlags();
  const { send } = usePlayerControlsService();

  const showControls = useCallback(() => {
    send(PlayerControlsEvent.SHOW_CONTROLS);
  }, [send]);
  const throttledShow = useThrottle(showControls, 2000);

  const slide = useCallback(
    (event: React.PointerEvent | React.TouchEvent | any) => {
      if (!time?.duration) return;
      const p =
        event.type === "touchmove"
          ? getRelativeTouchPosition(event)
          : getRelativeMousePosition(event);
      seek(time.duration * p.x);
    },
    [time?.duration, seek]
  );

  const handlePointerDown = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      // if (context.type !== "vod") return;
      (event.currentTarget as HTMLDivElement).addEventListener(
        "mousemove",
        slide
      );
      slide(event);
    },
    [slide]
  );

  const handlePointerUp = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      (event.currentTarget as HTMLDivElement).removeEventListener(
        "mousemove",
        slide
      );
    },
    [slide]
  );

  const [hoverTime, setHoverTime] = useState(null as number | null);
  const bestStillMatch =
    stills && typeof hoverTime === "number"
      ? bestMatch(
          stills,
          (a, b) =>
            Math.abs(a.timestamp - hoverTime) -
            Math.abs(b.timestamp - hoverTime)
        )
      : undefined;

  if (!time) return null;

  return (
    <div
      id="mainProgressBar"
      className="relative box-content w-full pt-2 transition-size h-2 md:h-1 md:hover:h-2"
      onMouseMove={
        fitsDesktop
          ? (e) => {
              const mousePosition = getRelativeMousePosition(
                e,
                e.currentTarget as HTMLDivElement
              );
              setHoverTime(time.duration * mousePosition.x);
            }
          : undefined
      }
      onMouseLeave={fitsDesktop ? () => setHoverTime(null) : undefined}
      onTouchMove={
        scrubbingMobileVodPlayer && !fitsDesktop
          ? (event) => {
              event.stopPropagation();
              const p = getRelativeTouchPosition(event);
              setHoverTime(time.duration * p.x);
            }
          : undefined
      }
      onTouchEnd={
        scrubbingMobileVodPlayer && !fitsDesktop
          ? () => setHoverTime(null)
          : undefined
      }
    >
      {(scrubbingMobileVodPlayer || fitsDesktop) && stills && (
        <div
          className={clsx(
            "absolute transform -translate-x-1/2 bottom-full pointer-events-none w-56",
            {
              hidden: hoverTime == null,
            }
          )}
          style={{ left: ((hoverTime ?? 0) / time.duration) * 100 + "%" }}
        >
          <ScrubImages
            stills={stills}
            hoveringStill={bestStillMatch}
            imgClassName="w-full rounded border border-white border-solid"
          />
        </div>
      )}
      <div
        className="bg-white bg-opacity-30 w-full h-full relative"
        onMouseDown={handlePointerDown}
        onMouseUp={handlePointerUp}
        onTouchMove={(event) => {
          slide(event);
          throttledShow();
        }}
      >
        <div className="absolute bottom-full h-4 w-full opacity-30 bg-gradient-to-b to-black from-transparent">
          &nbsp;
        </div>

        <div
          className="absolute left-0 h-full bg-white bg-opacity-50 transition-size"
          style={{ width: time.bufferPercent + "%" }}
        />

        <div
          className="absolute left-0 h-full bg-secondary"
          style={{ width: (time.position / time.duration) * 100 + "%" }}
        >
          {scrubbingMobileVodPlayer && !fitsDesktop && (
            <div className="absolute right-0 rounded-full bg-secondary w-5 h-5 top-1/2 -translate-y-1/2" />
          )}
        </div>
      </div>
    </div>
  );
}
