import { CircularProgress, Divider } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import { useController } from "react-hook-form";

import { useIntegrationDeviceCamQuery } from "@/generated-models";

import { CustomDeviceCamerasDrawer } from "./CustomDeviceCamerasDrawer";

interface IntegrationsLinkCamerasDialogProps {
  open?: boolean;
  path: string;
  name: string;
  onClose: () => void;
}

function ConnectCustomDeviceCamerasDialogCameraList({
  path,
  labelClassName,
  onUnlink,
}: {
  path: string;
  labelClassName?: string;
  onUnlink?: () => void;
}) {
  const {
    field: { value: cameraIds, onChange: updateCameraIds },
  } = useController({
    name: `${path}.cameraIds`,
  });

  const { data, loading } = useIntegrationDeviceCamQuery({
    variables: { ids: cameraIds },
    skip: cameraIds.length === 0,
  });

  const hasCamerasConfigured = cameraIds.length > 0;

  return (
    <>
      {hasCamerasConfigured && loading && (
        <div className="flex items-center justify-center p-4">
          <CircularProgress size={30} />
        </div>
      )}
      {hasCamerasConfigured && (
        <>
          {data?.cameras?.map((camera) => (
            <div
              key={camera.id}
              className="flex flex-nowrap items-start gap-2.5 pb-2 border-b border-solid border-[#DFDFDF] w-full"
            >
              <img
                src={camera.still}
                className="h-[58px] flex-grow-0 flex-shrink rounded-[1px] border border-solid border-[#C1C1C1]"
                alt=""
              />
              <div className="max-w-[15rem] md:max-w-[unset] w-full">
                <div className="flex items-start justify-between gap-1 w-full">
                  <Typography
                    variant="caption"
                    component="p"
                    className={clsx(
                      "truncate text-sm leading-4 max-w-[180px]",
                      labelClassName
                    )}
                  >
                    {camera.location.name}
                  </Typography>
                  <button
                    type="button"
                    onClick={() => {
                      updateCameraIds(
                        cameraIds.filter((c: number) => c !== camera.id)
                      );
                      onUnlink?.();
                    }}
                    className="bg-transparent py-0 text-sm leading-4 text-primary"
                  >
                    Unlink
                  </button>
                </div>
                <Typography className="truncate text-[11px] leading-[13px] opacity-50 mt-[1px]">
                  {camera.name}
                </Typography>
                <div className="flex items-center capitalize text-xs -ml-0.5">
                  <span
                    className={clsx(
                      "w-2 h-2 mx-1 rounded-[50%] border-2 border-solid",
                      {
                        "bg-[#67c21b]/50 border-[#67c21b]":
                          camera.status === "online",
                        "bg-[#b0b0b0]/50 border-[#b0b0b0]":
                          camera.status !== "online",
                      }
                    )}
                  />
                  {camera.status}
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
}

export default function ConnectCustomDeviceCamerasDialog({
  open = false,
  name,
  path,
  onClose,
}: IntegrationsLinkCamerasDialogProps) {
  const {
    field: { value: cameraIds },
  } = useController({
    name: `${path}.cameraIds`,
  });

  return (
    <Dialog
      classes={{ paper: "max-w-sm rounded-xl" }}
      onClose={onClose}
      hideBackdrop
      disableEnforceFocus
      open={open}
    >
      <div className="max-w-md p-5 flex flex-col gap-2">
        <CustomDeviceCamerasDrawer
          open
          path={path}
          onOpen={() => {}}
          onClose={async () => {
            onClose();
          }}
        />
        <Typography className="text-lg leading-[21px] font-bold mb-[6px]">
          {name}
        </Typography>
        <Divider />
        {cameraIds.length === 0 && (
          <>
            <Typography className="text-lg leading-[21px] text-black opacity-50 italic text-center">
              Add cameras from the camera drawer on the right.
            </Typography>
            <Divider />
          </>
        )}
        {cameraIds.length > 0 && (
          <ConnectCustomDeviceCamerasDialogCameraList path={path} />
        )}
        <div className="flex gap-[10px] items-center justify-end">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="shadow-none text-xs leading-[22px] font-normal rounded-md"
            onClick={() => {
              onClose();
            }}
          >
            Close
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
