import { debounce } from "lodash/fp";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";

import gtag, { install } from "@/util/gtag";

import { isDemoUser, useMe } from "@/components/Auth";

import { debugMode } from "@/environment";
import { OrgType } from "@/generated-models";

const measurementId = "G-ZZCRDT9L38";

/**
 * These are the IDs of Spot Demo orgs in the production DB. They will
 * be flagged as _internal_ traffic. We don't have to account for staging
 * and dev envs, since they'll be flagged as _internal_ anyway.
 */
const internalOrgIds = new Set([
  1, // Spot AI HQ
  59, // Spot AI HD Test
  60, // Spot AI Demo
]);

export function Analytics() {
  const me = useMe();

  useEffect(() => {
    if (!me) return;

    const isDemo = isDemoUser({
      role: me.role,
      organization: {
        type: me.organization.type,
      },
    });

    let analyticsEmail = me.email;
    const localEmail = localStorage.getItem("demoEmail");
    if (isDemo && localEmail) {
      analyticsEmail = localEmail;
    }

    // Allows filtering traffic
    // docs: https://support.google.com/analytics/answer/10108813
    const trafficType =
      analyticsEmail.endsWith("@spotai.co") ||
      internalOrgIds.has(me.organization.id)
        ? "internal"
        : me.organization.type === OrgType.Demo
        ? "demo"
        : "external";

    gtag("config", measurementId, {
      user_id: analyticsEmail,
      send_page_view: true,
      traffic_type: trafficType,
      ...(debugMode ? { debug_mode: true } : undefined),
    });

    gtag("set", {
      user_id: analyticsEmail,
      user_properties: {
        email: analyticsEmail,
        orgName: me.organization.name,
        orgId: me.organization.id,
        traffic_type: trafficType,
      },
    });

    // Install after setting initial config
    install(measurementId);
  }, [me]);

  return null;
}

/**
 * Used for shared clips/streams
 */
export function AnonymousAnalytics() {
  useEffect(() => {
    gtag("set" as any, "user_properties", {
      orgName: "Anonymous",
      orgId: "-1",
      email: "Anonymous",
    });
    install(measurementId);
  }, []);
  return <Outlet />;
}

export function trackClipShare() {
  gtag("event", "Shared_a_Clip", {
    category: "Sharing",
  });
}

export function trackLivestreamShare() {
  gtag("event", "Shared_a_Livestream", {
    category: "Sharing",
  });
}

export function trackClipDownload() {
  gtag("event", "Downloaded_a_Clip", {
    category: "Downloads",
  });
}

export function trackPlaybackSpeedSet(speed: number) {
  if (speed === 1) return;

  gtag("event", "Set_Playback_Speed", {
    category: "Playback",
    value: speed,
  });
}

export function trackMotionFilterEnabled() {
  gtag("event", "Enabled_Motion_Filter", {
    category: "Motion",
  });
}

export enum AnalyticsViewType {
  live = "Live",
  vod = "VOD",
}
export function trackView(type: AnalyticsViewType, shared?: boolean) {
  gtag("event", shared ? `Viewed_Shared_${type}` : `Viewed_${type}`, {
    category: shared ? "Shared_View" : "View",
  });
}

export const trackAudioSet = debounce(1000, function trackAudioSet(
  level: number
) {
  gtag("event", "Volume_changed", {
    category: "Audio",
    value: level,
  });
});

export function trackQualitySelected(hd: boolean) {
  gtag("event", hd ? "Playing_HD" : "Playing_SD", {
    category: "Quality",
  });
}

export function trackDownloadedStills() {
  gtag("event", "Downloaded_Stills", {
    category: "Stills",
  });
}
