import MenuIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import clsx from "clsx";
import React from "react";
import { NavLink } from "react-router-dom";

import { CasesIcon, LiveIcon, WallIcon } from "@/icons/Icons";

import { OrgPermissionIds, usePermissions } from "@/hooks/usePermissions";

import { usePrefixOrgSlug } from "../hooks/useOrgRouteBase";

export function MobileNav() {
  const prefixOrgSlug = usePrefixOrgSlug();
  const hasAccess = usePermissions();
  const navLinks: {
    to: string;
    label: string;
    icon: React.ReactNode;
    accessGuard?: OrgPermissionIds;
  }[] = [
    {
      to: "/live",
      label: "Live",
      icon: <LiveIcon />,
      accessGuard: "video_live_access",
    },
    {
      to: "/search",
      label: "Search",
      icon: <SearchIcon />,
      accessGuard: "video_vod_access",
    },
    {
      to: "/wall",
      label: "Walls",
      icon: <WallIcon />,
      accessGuard: "walls_access",
    },
    {
      to: "/cases",
      label: "Cases",
      icon: <CasesIcon />,
      accessGuard: "cases_access",
    },
    { to: "/menu", label: "More", icon: <MenuIcon /> },
  ];

  return (
    <header className="w-full grow-0 shrink-0 bg-white">
      <nav className="flex justify-center shadow-top text-[#72777f] text-sm px-2.5 gap-x-2.5 py-[5px]">
        {navLinks
          .filter(({ accessGuard }) => !accessGuard || hasAccess(accessGuard))
          .map(({ to, label, icon, accessGuard }, index) => (
            <NavLink
              key={index}
              className={({ isActive }) =>
                clsx("flex-center flex-col h-16 w-1/5", {
                  "text-primary bg-primary bg-opacity-[0.104] rounded-lg": isActive,
                })
              }
              to={prefixOrgSlug(to)}
            >
              {icon}
              {label}
            </NavLink>
          ))}
      </nav>
    </header>
  );
}
