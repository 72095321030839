import { zonedTimeToUtc } from "date-fns-tz";
import { addSeconds, isEqual, startOfDay } from "date-fns/fp";
import { Provider } from "jotai";
import { useMemo } from "react";

import { formatIsoDate } from "@/util/date";
import { useBreakpoints } from "@/util/useBreakpoints";

import { PlayerBase } from "@/components/Player/PlayerBase";
import { StreamSources } from "@/components/Player/StreamSources";
import { VideoOverlay } from "@/components/Player/VideoOverlay";
import { VideoPlayer } from "@/components/Player/VideoPlayer";

import { SearchPoint, usePrecenseHeatmapQuery } from "@/generated-models";

import { useIntelligenceTimeframeText } from "../hooks";
import IntelligenceHeatmapOverlay, {
  colorValuesInt,
} from "./IntelligenceHeatmapOverlay";

export function IntelligenceHeatmap({
  cameraId,
  intDashId,
  timezone,
  zone,
  startDate,
  endDate,
  showTooltip,
  date,
  colorValues,
  tooltipText,
}: {
  cameraId: number;
  intDashId: number;
  timezone: string;
  zone?: SearchPoint[];
  startDate: Date;
  endDate: Date;
  showTooltip: boolean;
  date?: string;
  colorValues?: colorValuesInt;
  tooltipText?: string;
}) {
  const timeframeText = useIntelligenceTimeframeText();
  const { fitsDesktop } = useBreakpoints();
  const { zonedNoon, endTime } = useMemo(() => {
    const today = startOfDay(new Date());
    today.setHours(0, 0, 0, 0);

    const noonStartDay = startOfDay(startDate);
    noonStartDay.setHours(12, 0, 0, 0);

    if (isEqual(startDate, today)) {
      noonStartDay.setDate(noonStartDay.getDate() - 1);
    }

    const zonedNoon = zonedTimeToUtc(noonStartDay, timezone);
    const endTime = addSeconds(10, zonedNoon);

    return {
      zonedNoon: zonedNoon.toISOString(),
      endTime: endTime.toISOString(),
    };
  }, [startDate, timezone]);

  const { data } = usePrecenseHeatmapQuery({
    variables: {
      cameraId,
      startTime: zonedNoon,
      endTime: endTime,
    },
  });

  const feeds = useMemo(() => {
    if (fitsDesktop) return data?.vod.feeds;
    const result: StreamSources = { tunnel: "" };
    Object.entries(data?.vod.feeds || []).forEach(([key, value]) => {
      // Need to forward stream in order to render thumbnail on mobile devices.
      // https://muffinman.io/blog/hack-for-ios-safari-to-display-html-video-thumbnail/
      result[key as keyof StreamSources] = `${value}#t=0.001`;
    });

    return result;
  }, [data, fitsDesktop]);

  return data?.vod?.feeds ? (
    <div className="w-full">
      <PlayerBase>
        <VideoOverlay enablePointerEvents={true}>
          <IntelligenceHeatmapOverlay
            intDashId={intDashId}
            startDate={formatIsoDate(startDate)} // TODO: Might need to check this
            endDate={formatIsoDate(endDate)}
            showTooltip={showTooltip}
            date={date}
            colorValues={colorValues}
            tooltipText={tooltipText}
            timeframeText={timeframeText}
          />
          {zone && (
            <svg
              className="absolute w-full h-full pointer-events-none"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <polygon
                points={zone.map((p) => `${p.x}, ${p.y}`).join(" ")}
                stroke="#ef00b6"
                strokeWidth={4}
                vectorEffect="non-scaling-stroke"
                fill="#ef00b6"
                fillOpacity={0}
              />
            </svg>
          )}
        </VideoOverlay>
        <Provider>
          <div className="pointer-events-none">
            <VideoPlayer sources={feeds} autoPlay={false} forceMuted />
          </div>
        </Provider>
      </PlayerBase>
    </div>
  ) : null;
}
