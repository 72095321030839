import { Checkbox, FormControlLabel } from "@mui/material";
import { useAtom } from "jotai";
import { useMemo } from "react";

import { StatefulDrawerCam } from "@/components/CamDrawer/CamDrawerBase";
import { selectedOnlyFilterAtom } from "@/components/CamDrawer/CamDrawerSelectedOnlyFilter";
import { useCamDrawerCams } from "@/components/CamDrawer/useCamDrawerCams";

export function CamDrawerListControls({
  cameras,
  selectIds,
  deselectIds,
}: {
  selectIds: (ids: number[]) => void;
  deselectIds: (ids: number[]) => void;
  cameras: StatefulDrawerCam[];
}) {
  const [selectedOnly] = useAtom(selectedOnlyFilterAtom);
  const { cams } = useCamDrawerCams(cameras);
  const enabledCams = useMemo(() => cams.filter((c) => !c.disabled), [cams]);
  const allSelected = enabledCams.every((c) => c.selected);

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={allSelected}
          disabled={selectedOnly || cameras.length === 0}
          onChange={() => {
            if (allSelected) {
              // Deselect all listed cameras
              const selectedCams = cams.filter((c) => c.selected);
              deselectIds(selectedCams.map((c) => c.id));
            } else {
              // Select all listed and not disabled cameras
              const selectableCams = enabledCams.filter((c) => !c.selected);
              selectIds(selectableCams.map((c) => c.id));
            }
          }}
          color="primary"
          size="small"
        />
      }
      label="Select/Deselect All Cameras"
    />
  );
}
