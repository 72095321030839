import { Typography } from "@mui/material";
import clsx from "clsx";
import { motion } from "framer-motion";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { CopilotIcon } from "@/icons/CopilotIcon";

import { useIntDashboardCamQuery } from "@/generated-models";

import { useIsCopilotCompatible } from "../../copilotQueryHooks";

export function CopilotBanner({ className }: { className?: string }) {
  const copilotCompatible = useIsCopilotCompatible();
  const [searchParams] = useSearchParams();

  const camsParams = searchParams.get("cams")
    ? Number(searchParams.get("cams"))
    : null;

  const zoneIdParam = searchParams.get("zoneId")
    ? Number(searchParams.get("zoneId"))
    : null;

  const { data } = useIntDashboardCamQuery({
    variables: { ids: camsParams! },
    skip: !camsParams,
  });

  const { zone, cam } = useMemo(() => {
    const cam = data?.cameras?.[0];
    return {
      cam,
      zone: cam?.focusZones.find((z) => z.id === zoneIdParam),
    };
  }, [data?.cameras, zoneIdParam]);

  if (!cam || !zone || !copilotCompatible) return <></>;

  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      transition={{
        duration: 0.5,
      }}
      className={clsx(
        "rounded-lg bg-[#F2F2F2] px-4 py-[10px] gap-4 flex items-center justify-start",
        className
      )}
    >
      <CopilotIcon />
      <Typography className="text-base leading-normal mt-[1px]">
        Automatically selected Camera <strong>{cam.name}</strong> and Zone{" "}
        <strong>{zone.name}</strong>.
      </Typography>
    </motion.div>
  );
}
