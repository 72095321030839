import { IconProps } from "@/icons/IconProps";

export function PersonIcon({ style, className, onClick }: IconProps) {
  return (
    <svg
      style={style}
      className={className}
      onClick={onClick}
      width="12"
      height="11"
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.6">
        <path
          d="M8.71582 3.35643C8.71582 4.90864 7.43891 6.16696 5.86377 6.16696C4.28862 6.16696 3.01172 4.90864 3.01172 3.35643C3.01172 1.80422 4.28862 0.545898 5.86377 0.545898C7.43891 0.545898 8.71582 1.80422 8.71582 3.35643Z"
          fill="#353D48"
        />
        <path
          d="M11.6832 10.4385C11.6832 8.32752 9.07835 6.61621 5.86506 6.61621C2.65176 6.61621 0.046875 8.32752 0.046875 10.4385H11.6832Z"
          fill="#353D48"
        />
      </g>
    </svg>
  );
}
