import Hls, { Events, FragBufferedData } from "hls.js";
import { atom as jAtom, useAtomValue } from "jotai";
import { ReactNode, useEffect, useState, useCallback } from "react";

import { VideoOverlay } from "@/components/Player/VideoOverlay";

import { ReactECharts } from "../Chart";
import { getBaseOptions, lineTimeseriesConfig } from "../Chart/utils";

interface BitrateData {
  time: number;
  duration: number;
  level: number;
  bitrate: number;
}

export const hlsDebugState = jAtom(false);
hlsDebugState.debugLabel = "hlsDebug";

export function useHlsDebugState() {
  return useAtomValue(hlsDebugState);
}

function timeRangesToString(r: any) {
  let log = "";
  for (let i = 0; i < r?.length || 0; i++) {
    log += `[${r.start(i).toFixed(3)},${r.end(i).toFixed(3)}] `;
  }
  return log;
}

export function DebugStatistic({
  label,
  children,
}: {
  label: string;
  children?: ReactNode;
}) {
  return (
    <div className="text-white text-[7px] sm:text-2xs">
      {`${label}: `}
      {children}
    </div>
  );
}

export function HlsDebugOverlay({
  hls,
  playerElement,
}: {
  hls: Hls | null;
  playerElement: HTMLVideoElement | null;
}) {
  const [currentBitrate, setCurrentBitrate] = useState<BitrateData[]>([]);

  const updateBitrate = (data: FragBufferedData, curr: BitrateData[]) => {
    const newCurr = [...curr];
    if (newCurr.length > 50) {
      newCurr.shift();
    }

    return [
      ...newCurr,
      {
        time: Date.now(),
        duration: data.stats.buffering.end - data.stats.loading.first,
        level: data.frag.level,
        bitrate: Math.round(
          (8 * data.stats.total) /
            (data.stats.buffering.end - data.stats.loading.start)
        ),
      },
    ];
  };

  const handleFragBuffered = useCallback(
    (_: Events.FRAG_BUFFERED, data: FragBufferedData) => {
      if (data.frag.level === hls?.currentLevel) {
        setCurrentBitrate((curr) => updateBitrate(data, curr));
      }
    },
    [hls?.currentLevel]
  );

  useEffect(() => {
    if (hls) {
      hls?.on(Hls.Events.FRAG_BUFFERED, handleFragBuffered);
    }

    return () => {
      hls?.off(Hls.Events.FRAG_BUFFERED, handleFragBuffered);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hls]);

  const report: {
    [key: string]: ReactNode;
  } = {
    "Bandwidth Estimate": `${((hls?.bandwidthEstimate || 0) / 1000000).toFixed(
      3
    )} Mbps`,
    "Max Latency": hls?.maxLatency,
    "Target Latency": hls?.targetLatency?.toFixed(3),
    Latency: hls?.latency?.toFixed(3),
    Drift: `${hls?.drift?.toFixed(3)} (edge advance rate)`,
    "Current Level": hls?.currentLevel,
    "Live Sync Position": hls?.liveSyncPosition?.toFixed(3),
    Duration: playerElement?.duration?.toFixed(3),
    Buffered: timeRangesToString(playerElement?.buffered),
    Seekable: timeRangesToString(playerElement?.seekable),
    Played: timeRangesToString(playerElement?.played),
    "Dropped Frames": playerElement?.getVideoPlaybackQuality()
      ?.droppedVideoFrames,
    "Corrupted Frames": playerElement?.getVideoPlaybackQuality()
      ?.corruptedVideoFrames,
  };

  return (
    <VideoOverlay className="overflow-auto overflow-y-clip">
      <div className="bg-black bg-opacity-60 p-6 flex-row md:flex-col">
        <div className="grid grid-cols-2 gap-0.5">
          {Object.keys(report).map((item) => (
            <DebugStatistic key={item} label={item}>
              {report[item]}
            </DebugStatistic>
          ))}
        </div>
        <div className="w-full h-52">
          <div className="text-[7px] sm:text-2xs text-white mt-2">
            Level: {hls?.currentLevel}
          </div>
          <div className="text-[7px] sm:text-2xs text-white mt-2">Bitrate</div>

          <ReactECharts
            style={{ height: 200 }}
            option={{
              ...{
                ...getBaseOptions("camera-appliance-uptime"),
                legend: { show: false },
              },
              xAxis: {
                type: "time",
                boundaryGap: false,
                axisLabel: {
                  rotate: -45,
                },
              },
              yAxis: {
                type: "value",
              },
              series: [
                {
                  ...lineTimeseriesConfig,
                  name: "Bitrate",
                  data: currentBitrate.map((x) => [
                    new Date(x.time),
                    x.bitrate,
                  ]),
                  type: "line",
                  symbolSize: 0,
                  dimensions: ["timestamp", "value"],
                  lineStyle: { color: "#67e8f9" },
                },
              ],
            }}
          />
        </div>
      </div>
    </VideoOverlay>
  );
}
