import { Button, Fade, Modal, OutlinedInput } from "@mui/material";
import clsx from "clsx";
import { ReactNode, useState } from "react";

import { useSendCopilotFeedback } from "../../copilotQueryHooks";

export function CopilotFeedbackModal({
  buttonClassName,
  placeholder,
  children,
  prefix,
}: {
  buttonClassName?: string;
  placeholder?: string;
  children: ReactNode;
  prefix?: string;
}) {
  const { send } = useSendCopilotFeedback();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");

  function toggleModal() {
    setOpen((o) => !o);
  }

  async function onSubmit() {
    const message = `[Feedback]: ${value}`;
    toggleModal();
    await send(prefix ? `[${prefix}] ${message}` : message);
    setValue("");
  }

  return (
    <>
      <button
        type="button"
        className={clsx("pointer-events-auto", buttonClassName)}
        onClick={(e) => {
          e.stopPropagation();
          toggleModal();
        }}
      >
        {children}
      </button>
      <Modal
        open={open}
        onClose={toggleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={open}>
          <div
            className="flex flex-col justify-center items-center w-full h-full"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="p-5 rounded-2xl bg-black bg-opacity-50 flex items-center gap-2 w-full max-w-[665px]">
              <OutlinedInput
                size="small"
                value={value}
                placeholder={placeholder}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
                onKeyPress={async (e) => {
                  if (e.key === "Enter") {
                    onSubmit();
                  }
                }}
                className="bg-black border border-solid border-[#2B2B2B] text-white rounded w-full"
              />
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={onSubmit}
              >
                Submit
              </Button>
              <Button
                className="text-white/[.40]"
                size="small"
                variant="outlined"
                onClick={() => {
                  toggleModal();
                  setValue("");
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
