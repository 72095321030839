import { useFlags } from "launchdarkly-react-client-sdk";

import { ClothingLowerIcon, ClothingUpperIcon } from "@/icons/Icons";

import {
  useArraySearchFilter,
  useSetArraySearchFilter,
} from "../../searchHooks";
import {
  PrimaryAgeSelect,
  PrimaryGenderSelect,
  PrimaryMultipleColorSelect,
} from "./AttributeFilters";
import { CamerasSelect, GroupSelect, RangeInput } from "./SearchFilters";

export function PeopleFilterBar() {
  return (
    <div className="flex flex-col shrink-0 gap-y-4 w-60 md:w-[17rem] p-2.5 bg-blue-medium text-sm rounded-2xl shadow-[0_17px_28px_rgba(0,0,0,0.13)]">
      <div className="flex flex-col items-center gap-2 p-2 bg-white rounded-md">
        <div className="font-bold">Search Filters</div>
        <RangeInput />
        <GroupSelect />
        <CamerasSelect />
      </div>
      <div className="flex flex-col items-center gap-2 p-2 bg-white rounded-md">
        <PeopleAttributesFilters />
      </div>
    </div>
  );
}

function PeopleAttributesFilters() {
  const { genderAgeAttributeSearch } = useFlags();
  const upperColor = useArraySearchFilter("clothingUpper");
  const setUpperColor = useSetArraySearchFilter("clothingUpper");
  const lowerColor = useArraySearchFilter("clothingLower");
  const setLowerColor = useSetArraySearchFilter("clothingLower");

  return (
    <>
      <div className="font-bold">People Attributes</div>
      <PrimaryMultipleColorSelect
        selected={upperColor}
        setSelected={setUpperColor}
        label="Upper Clothing Color"
        Icon={ClothingUpperIcon}
      />
      <PrimaryMultipleColorSelect
        selected={lowerColor}
        setSelected={setLowerColor}
        label="Lower Clothing Color"
        Icon={ClothingLowerIcon}
      />
      {genderAgeAttributeSearch && (
        <>
          <PrimaryGenderSelect />
          <PrimaryAgeSelect />
        </>
      )}
    </>
  );
}
