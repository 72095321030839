import DeleteIcon from "@mui/icons-material/DeleteForever";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import gql from "graphql-tag";
import { useNavigate } from "react-router-dom";

import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { DefaultDialog, useDialog } from "@/components/shared/Dialog";

import { useRemoveDetectedSubjectMutation } from "@/generated-models";

export function RemoveSubjectButton({ subjectId }: { subjectId: number }) {
  const navigate = useNavigate();
  const { pushSnackbar } = useFeedback();
  const { open: openDialog, ...dialogProps } = useDialog();
  const [remove, { loading }] = useRemoveDetectedSubjectMutation({
    variables: { id: subjectId },
    update: (cache, { data }) => {
      if (!data) return;
      cache.evict({ id: `DetectedSubject:${subjectId}` });
    },
    onCompleted: () => {
      navigate("..", { replace: true });
      pushSnackbar("Successfully deleted subject", FeedbackType.Success);
    },
    onError: () =>
      pushSnackbar("Combining people failed...", FeedbackType.Error),
  });

  return (
    <>
      <Tooltip title="Delete">
        <IconButton
          onClick={async () => {
            const confirmed = await openDialog();
            if (confirmed) remove();
          }}
          size="small"
          className="text-primary text-opacity-40"
          disabled={loading}
        >
          {loading ? <CircularProgress size={20} /> : <DeleteIcon />}
        </IconButton>
      </Tooltip>
      <DefaultDialog
        title="Delete Subject"
        content="Are you sure you want to delete this subject? This action is irreversible."
        confirmColor="primary"
        confirmText="Delete"
        {...dialogProps}
      />
    </>
  );
}

gql`
  mutation removeDetectedSubject($id: Int!) {
    removeDetectedSubject(id: $id) {
      message
    }
  }
`;
