import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Button, ButtonBase, Collapse, Typography } from "@mui/material";
import naturalSort from "javascript-natural-sort";
import { makeStyles } from "tss-react/mui";

import { Circle } from "@/icons/Circle";

import {
  DeviceStatusV2,
  getDeviceStatusV2,
} from "@/pages/Settings/LocationSettings/Camera/CameraSetupTable";

import NvrChannelForm from "@/components/Genius/Forms/Nvr/NvrChannelForm";

import {
  ConnectionValidation as Validation,
  DeviceDetailsQuery,
} from "@/generated-models";

const useStyles = makeStyles()((theme) => ({
  headerButton: {
    padding: theme.spacing(0.5),
    borderRadius: 5,
    display: "flex",
    alignItems: "flex-start",
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      background: "rgba(0,0,0,0.05)",
    },
  },
}));

interface NvrChannelsListProps {
  device: DeviceDetailsQuery["deviceScan"];
  expanded: boolean;
  onSetExpanded?: (expanded: boolean) => void;
  onOpenNativeProxy?: () => void;
}

export default function NvrChannelsList({
  expanded,
  onSetExpanded = () => {},
  device,
  onOpenNativeProxy = () => {},
}: NvrChannelsListProps) {
  const { classes } = useStyles();
  const status = getDeviceStatusV2(device);
  // If device validation failed, only show channels with activated camera
  const channels =
    device.status === Validation.Ok
      ? device.channels
      : device.channels.filter((c) => c.streams.some((s) => !!s.camera));
  const unconfiguredChannelsCount = channels.length - device.cameras.length;
  const isFisheye = device.isFisheye;
  const deviceName = isFisheye ? "Fisheye" : "NVR";

  return (
    <Box display="flex" flexDirection="column">
      <ButtonBase
        className={classes.headerButton}
        onClick={() => onSetExpanded(!expanded)}
      >
        <Box mr={0.75}>
          {status !== DeviceStatusV2.NvrNoActivatedCameras ? (
            <CheckCircleIcon style={{ fontSize: 18, color: "#62b309" }} />
          ) : (
            <Circle style={{ fontSize: 18, opacity: 0.1 }} />
          )}
        </Box>
        <Box flexGrow={1} display="flex" flexDirection="row">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
          >
            <Typography variant="h6" style={{ lineHeight: 1, marginTop: 1 }}>
              {deviceName} Channel Configuration
            </Typography>
            {expanded && (
              <Typography variant="caption" style={{ lineHeight: 1.4 }}>
                Configure your {deviceName} Channels below
              </Typography>
            )}
          </Box>
          <Box m={2} />
          {!!device.cameras.length && (
            <Typography
              variant="body1"
              style={{
                alignSelf: "center",
                fontSize: 14,
                fontWeight: 500,
                color: "#08C500",
              }}
            >
              {device.cameras.length} Configured
            </Typography>
          )}
          {!!device.cameras.length && !!unconfiguredChannelsCount && (
            <Box
              width="1px"
              height="23px"
              mx={1.5}
              style={{ background: "rgba(0,0,0,0.3)", alignSelf: "center" }}
            />
          )}
          {!!unconfiguredChannelsCount && (
            <Typography
              variant="body1"
              style={{
                alignSelf: "center",
                fontSize: 14,
                fontWeight: 500,
                color: "#737373",
              }}
            >
              {unconfiguredChannelsCount} Unconfigured
            </Typography>
          )}
        </Box>

        <Button
          component="div"
          size="small"
          color="primary"
          style={{ opacity: expanded ? 0 : 1 }}
          variant="outlined"
          onClick={() => onSetExpanded(!expanded)}
        >
          Edit
        </Button>
      </ButtonBase>
      <Collapse in={expanded}>
        <Box
          display="flex"
          flexDirection="column"
          style={{
            maxHeight: "60vh",
            overflowY: "scroll",
          }}
        >
          {channels
            ?.slice() // Need to copy array for sort
            .sort((a, b) => {
              const aHasCam = a.streams.some((s) => s.camera);
              const bHasCam = b.streams.some((s) => s.camera);
              if (aHasCam !== bHasCam) {
                // one of them has a cam, make sure the one without a cam is sorted on top
                return aHasCam ? 1 : -1;
              }
              // They either both have a cam, or neither has a cam. Apply a natural sort
              // on their paths
              return naturalSort(a.streams[0]?.path, b.streams[0]?.path);
            })
            .map((channel, idx) => (
              <Box
                key={channel.id}
                m={1}
                style={{ borderRadius: 4, border: "1px solid #A3A3A3" }}
              >
                <NvrChannelForm
                  onOpenNativeProxy={onOpenNativeProxy}
                  key={`channel-${channel.id}`}
                  deviceId={device.id}
                  defaultName={`${deviceName} Channel ${channel.channelId + 1}`}
                  channel={channel}
                  vendor={device.vendor}
                />
              </Box>
            ))}
        </Box>
      </Collapse>
    </Box>
  );
}
