import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import FilterIcon from "@mui/icons-material/FilterList";
import DownloadIcon from "@mui/icons-material/GetApp";
import CameraIcon from "@mui/icons-material/Videocam";
import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Chip,
  CircularProgress,
  ClickAwayListener,
  Divider,
  FormControl,
  Grid,
  Grow,
  Hidden,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popover,
  Popper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  TextFieldProps,
  Theme,
  Tooltip,
  TooltipProps,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import clsx from "clsx";
import {
  differenceInSeconds,
  format,
  isBefore,
  startOfDay,
  startOfYear,
  subDays,
} from "date-fns/fp";
import gql from "graphql-tag";
import { mapValues, pick } from "lodash/fp";
import { stringify } from "query-string";
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { makeStyles } from "tss-react/mui";
import {
  DateParam,
  DelimitedArrayParam,
  DelimitedNumericArrayParam,
  encodeQueryParams,
  NumberParam,
  StringParam,
  useQueryParams,
} from "use-query-params";

import { formatIsoDate } from "@/util/date";
import { downloadExternalFile } from "@/util/file";
import { filterFalsy, filterNullish } from "@/util/filterFalsy";
import { useBreakpoints } from "@/util/useBreakpoints";
import { useDocumentTitle } from "@/util/useDocumentTitle";

import {
  AuditLogActivitySelect,
  AuditLogCameraSelect,
  AuditLogGroupSelect,
  AuditLogUserSelect,
} from "@/pages/Settings/AuditLogs/AuditLogFilters";

import { useMe } from "@/components/Auth";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { SearchBox } from "@/components/SearchBox";
import { ZendeskArticle, ZendeskLink } from "@/components/Zendesk/ZendeskLink";
import { QueryParamLink } from "@/components/shared/QueryParamLink";
import { TextView } from "@/components/shared/TextView";

import { apiHost } from "@/environment";
import {
  ActivityTypes,
  GetUserActivityQuery,
  useAuditLogFilterOptionsQuery,
  useCameraFirstSegmentTimeQuery,
  useGetUserActivityPaginationQuery,
  useGetUserActivityQuery,
  UserActivityPdfDownloadQueryVariables,
  useUserActivityPdfDownloadQuery,
} from "@/generated-models";

type Data = GetUserActivityQuery["userActivity"]["results"][number];
type MappedData = ReturnType<typeof mapData>;

function mapData({
  id,
  timestamp,
  startTime,
  endTime,
  user,
  activity,
  camera,
  cameraId,
  location,
  link,
  metadata,
}: Data) {
  return {
    id,
    timestamp,
    startTime,
    endTime,
    duration: differenceInSeconds(new Date(startTime), new Date(endTime)),
    user,
    activity,
    camera,
    cameraId,
    location,
    link,
    metadata,
  };
}

type Order = "asc" | "desc";

const today = startOfDay(new Date());
const startOfThisYear = startOfYear(today);
// const thirtyDaysAgo = subDays(30, today);

/**
 * Custom "recuder-like" variation of `useQueryParams`. Allows atomic changes to
 * all query params, so they can change consistently within a single rerender.
 */
function usePaginationParams() {
  const [params, setParams] = useQueryParams({
    // pagination
    lastSeenId: NumberParam,
    page: NumberParam,
    pageSize: NumberParam,
    // filtering
    search: StringParam,
    start: DateParam,
    end: DateParam,
    groups: DelimitedNumericArrayParam,
    cameras: DelimitedNumericArrayParam,
    users: DelimitedNumericArrayParam,
    activities: DelimitedArrayParam,
    // sorting
    sort: StringParam,
  });

  const filterParams = [
    "start",
    "end",
    "groups",
    "cameras",
    "users",
    "activities",
  ] as const;
  type FilterParams = typeof filterParams[number];

  const actions = useMemo(
    () => ({
      setLastSeenId: (value?: number) =>
        setParams(
          (p) => ({
            ...p,
            lastSeenId: value,
          }),
          "replace"
        ),
      setPage: (value?: number) =>
        setParams(
          (p) => ({
            ...p,
            page: clearDefault(value, 0),
          }),
          "replace"
        ),
      setPageSize: (value?: number) =>
        setParams(
          (p) => ({
            ...p,
            pageSize: clearDefault(value, 25),
            page: undefined,
          }),
          "replace"
        ),
      setSearch: (value?: string) =>
        setParams(
          (p) => ({
            ...p,
            search: clearDefault(value, ""),
            page: undefined,
            lastSeenId: undefined,
          }),
          "replace"
        ),
      setFilters: (value: Partial<Pick<typeof params, FilterParams>>) =>
        setParams(
          (p) => ({
            ...p,
            ...value,
            ...(value.start && {
              start: clearDefault(value.start, allDatesStart),
            }),
            ...(value.groups && { groups: clearEmptyArray(value.groups) }),
            ...(value.cameras && { cameras: clearEmptyArray(value.cameras) }),
            ...(value.users && { users: clearEmptyArray(value.users) }),
            ...(value.activities && {
              activities: clearEmptyArray(value.activities),
            }),
            page: undefined,
            lastSeenId: undefined,
          }),
          "replace"
        ),
      resetFilters: () =>
        setParams(
          { ...mapValues(() => undefined), start: startOfThisYear },
          "replace"
        ),
      clearFilters: (keys: FilterParams[]) => {
        setParams((p) => {
          const result = {
            ...p,
            page: undefined,
            lastSeenId: undefined,
          };
          keys.forEach((k) => (result[k] = undefined));
          return result;
        }, "replace");
      },
      toggleSort: (column?: string) =>
        setParams(
          (p) => ({
            ...p,
            // Toggle sort
            sort: clearDefault(
              column && p.sort === column ? `-${column}` : column,
              "-timestamp"
            ),
            page: undefined,
            // resetting lastSeenId messes with cache when quickly toggling along one sorting dimension.
            // Leaving the old lastSeenId in place will result in the "new entries available" message
            // to show up, which should be fine.
            // lastSeenId: undefined,
          }),
          "replace"
        ),
    }),
    [setParams]
  );

  const parsedParams = {
    ...params,
    groups: params.groups?.filter(filterNullish),
    cameras: params.cameras?.filter(filterNullish),
    users: params.users?.filter(filterNullish),
    activities: params.activities?.filter(filterNullish) as ActivityTypes[],
  };

  return {
    ...parsedParams,

    ...actions,

    // Derived
    anyFilterSet: filterParams.some((param) => Boolean(params[param])),
    downloadQuery: encodeQueryParams(
      {
        search: StringParam,
        startDate: DateParam,
        endDate: DateParam,
        groups: DelimitedNumericArrayParam,
        cameras: DelimitedNumericArrayParam,
        users: DelimitedNumericArrayParam,
        activities: DelimitedArrayParam,
      },
      {
        ...pick(["search", "groups", "cameras", "users", "activities"], params),
        startDate: params.start,
        endDate: params.end,
      }
    ),
    parsedParams,
  };
}

/**
 * Replaces the past value with `undefined` if the value equals the defautl value.
 * Helps to keep the query params clean.
 */
function clearDefault<T>(value: T, defaultValue: T) {
  if (value === defaultValue) return undefined;
  return value;
}

function clearEmptyArray(value?: any[] | null) {
  if (value?.length === 0) return undefined;
  return value;
}

export function AuditLog() {
  useDocumentTitle("Audit log");
  const me = useMe();
  const {
    search,
    setSearch,
    downloadQuery,
    parsedParams,
    start,
    end,
    setFilters,
  } = usePaginationParams();

  // Set default start date to start of this year
  useEffect(() => {
    if (start || end) return;
    setFilters({
      start: startOfThisYear,
    });
    // eslint-disable-next-line
  }, []);

  const [
    pdfQuery,
    setPdfQuery,
  ] = useState<UserActivityPdfDownloadQueryVariables | null>(null);
  const { data: pdfData } = useUserActivityPdfDownloadQuery({
    skip: !pdfQuery,
    variables: pdfQuery!,
    pollInterval: 3000,
    notifyOnNetworkStatusChange: true, // Hack to get onCompleted to fire on every poll
    onCompleted: (data) => {
      if (data?.userActivityPdfDownload) {
        downloadExternalFile(data.userActivityPdfDownload);
        setPdfQuery(null);
      }
    },
  });
  const pdfLoading = Boolean(!pdfData?.userActivityPdfDownload && pdfQuery);

  const { classes } = useStyles();
  const { fitsTablet } = useBreakpoints();
  // const downloadOptions = () => {
  const timezone =
    Intl?.DateTimeFormat?.()?.resolvedOptions()?.timeZone ?? "UTC";
  const q = stringify({
    ...downloadQuery,
    timezone,
  });
  const downloadOptions = [
    {
      label: "Export CSV",
      onClick: () =>
        downloadExternalFile(
          `${apiHost}/downloads/${me?.organization.id}/audit-log.csv?${q}`
        ),
    },
    {
      label: "Export PDF",
      onClick: () => {
        if (!parsedParams.lastSeenId) return;
        const { lastSeenId, start, end, ...params } = parsedParams;
        setPdfQuery({
          ...params,
          lastSeenId: lastSeenId!,
          timezone,
          startDate: start?.toISOString(),
          endDate: end?.toISOString(),
        });
      },
    },
  ];

  return (
    <>
      <Grid container alignItems="center" style={{ padding: 16 }}>
        <Typography variant="h1">
          Audit log
          <ZendeskLink article={ZendeskArticle.AUDIT_LOGS} />
        </Typography>
        <Box flexGrow={1} m={1} />
        {fitsTablet && (
          <>
            <SearchBox
              input={search ?? ""}
              setInput={(input) => setSearch(input ?? undefined)}
            />
            <Box m={1} />
          </>
        )}
        <ExportButton
          options={downloadOptions}
          {...(pdfLoading
            ? {
                disabled: true,
                children: "Exporting PDF",
                endIcon: <CircularProgress size="14px" />,
                tooltip: "This shouldn't take longer than five minutes",
              }
            : {})}
        />

        {!fitsTablet && (
          <Box mt={1} width="100%">
            <SearchBox
              fullWidth
              input={search ?? ""}
              setInput={(input) => setSearch(input ?? undefined)}
            />
          </Box>
        )}
      </Grid>
      <Toolbar />
      <div className={classes.wrapper}>
        <EnhancedTable />
      </div>
    </>
  );
}

function ExportButton({
  options,
  children,
  tooltip,
  ...props
}: {
  options: { label: string; onClick: () => void }[];
  tooltip?: string;
} & ButtonProps) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  return (
    <>
      <ConditionalTooltip shown={Boolean(tooltip)} title={tooltip ?? ""}>
        <span>
          <Button
            color="primary"
            ref={anchorRef}
            variant="contained"
            endIcon={<ArrowDropDownIcon />}
            startIcon={<DownloadIcon />}
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              setOpen((prevOpen) => !prevOpen);
            }}
            {...props}
          >
            {children ?? "Export"}
          </Button>
        </span>
      </ConditionalTooltip>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        // disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <MenuList id="split-button-menu">
                  {options.map((option, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        option.onClick();
                        setOpen(false);
                      }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

function ConditionalTooltip({
  shown,
  ...props
}: { shown?: boolean } & TooltipProps) {
  if (!shown) return <>{props.children}</>;
  return <Tooltip {...props} />;
}

function determineIntersection<T>(setA: T[], setB: T[]) {
  return setA.some((a) => setB.some((b) => a === b));
}

const useFilterStyles = makeStyles()((theme) => ({
  root: {
    boxShadow: "rgba(0, 0, 0, 0.4) 0px 10px 9px -10px inset",
    background: "#c4c4c433",
    padding: "13px 26px",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
    },
  },
  paper: {
    maxWidth: 760,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0",
      minHeight: "100vh",
      "&": {
        /* mobile viewport bug fix */
        minHeight: "-webkit-fill-available",
      },
      maxHeight: "none",
      maxWidth: "none",
      borderRadius: "0",

      top: "0 !important",
      left: "0 !important",
    },
  },
  mainButton: {
    background: "white",
    flexShrink: 0,
  },
  actionButton: {
    fontWeight: "normal",
    letterSpacing: 2,
  },
  cameraFilterOption: {
    display: "flex",
    alignItems: "center",
  },
  cameraFilterPreview: {
    width: 24,
    height: 24,
    borderRadius: 1,
    objectFit: "cover",
    objectPosition: "center",
  },
  twoGrid: {
    display: "grid",
    gap: "16px",
    gridTemplateColumns: "1fr",
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "1fr 1fr",
    },
  },
  threeGrid: {
    gap: "16px",
    display: "grid",
    gridTemplateColumns: "1fr",
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "1fr 1fr 1fr",
    },
  },
}));
function useFiltersState() {
  const {
    start,
    end,
    groups,
    cameras,
    users,
    activities,
  } = usePaginationParams();
  const [state, setState] = useState({
    start: start as Date | null | undefined,
    end,
    groups: [] as number[] | null | undefined,
    cameras: [] as number[] | null | undefined,
    users: [] as number[] | null | undefined,
    activities: [] as string[] | null | undefined,
  });
  const actions = useMemo(
    () => ({
      setDateRange: (start?: Date, end?: Date) =>
        setState((p) => ({
          ...p,
          start: clearDefault(start, startOfDay(new Date("2000-01-01"))),
          end,
        })),
      setDate: (field: "start" | "end", date: Date | undefined) =>
        setState((p) => {
          const update = {
            ...p,
            [field]: date,
          };
          if (
            update.start &&
            update.end &&
            isBefore(update.start, update.end)
          ) {
            const fieldToClear = field === "start" ? "end" : "start";
            update[fieldToClear] = date;
          }

          return update;
        }),
      setState: (updates: {
        groups?: number[];
        cameras?: number[];
        users?: number[];
        activities?: string[];
      }) => {
        setState((p) => ({
          ...p,
          ...updates,
        }));
      },
      setGroups: (
        groups?: number[],
        camFilterInfo?: { id: number; tags: { id: number }[] }[]
      ) =>
        setState((p) => ({
          ...p,
          groups,
          cameras: groups
            ? p.cameras?.filter((camId) => {
                if (!groups) return true;
                const filterInfo = camFilterInfo?.find((i) => i.id === camId);
                if (!filterInfo) return false; // Hmm, is this right?
                return determineIntersection(
                  groups,
                  filterInfo.tags.map((t) => t.id)
                );
              })
            : p.cameras,
        })),
    }),
    [setState]
  );

  const dependencies = [
    start,
    end,
    groups?.join(),
    cameras?.join(),
    users?.join(),
    activities?.join(),
    setState,
  ];
  const reset = useCallback(() => {
    setState({ start, end, groups, cameras, users, activities });
    // eslint-disable-next-line
  }, dependencies);

  // We want this state to stay in sync with the query params
  useEffect(() => {
    setState({ start, end, groups, cameras, users, activities });
    // eslint-disable-next-line
  }, dependencies);

  return { state, actions, reset };
}
function Toolbar() {
  const { classes } = useFilterStyles();
  const { fitsTablet } = useBreakpoints();
  const { data } = useAuditLogFilterOptionsQuery();
  // const [open, setOpen] = useState(false);
  const [open, toggleOpen] = useReducer((open) => !open, false);
  const buttonRef = useRef<HTMLButtonElement>();
  const filters = useFiltersState();
  const {
    start,
    end,
    groups,
    cameras,
    users,
    activities,
    setFilters,
    resetFilters,
    clearFilters,
    anyFilterSet,
  } = usePaginationParams();

  const activityOptions = Object.entries(activityMap).map(([id, label]) => ({
    id: id as ActivityTypes,
    name: label,
  }));

  return (
    <Grid
      container
      className={classes.root}
      wrap={fitsTablet ? "nowrap" : "wrap"}
    >
      <Button
        ref={buttonRef as any}
        className={classes.mainButton}
        variant="outlined"
        startIcon={<FilterIcon />}
        onClick={toggleOpen}
        color="primary"
      >
        Filters
      </Button>
      <Box m={1} />
      <DesktopBox display="flex" flexWrap="wrap">
        {(start || end) && (
          <FilterSummaryChip
            label={getDateRangeLabel({ start, end })}
            onDelete={() => clearFilters(["start", "end"])}
          />
        )}
        {data?.tags && groups && (
          <FilterSummaryChipGroup
            ids={groups}
            data={data.tags}
            renderGroupedLabel={(count) => `${count} Groups`}
            onDelete={(id) =>
              setFilters({
                groups: groups.filter((gId) => id !== gId),
              })
            }
            onDeleteAll={() => clearFilters(["groups"])}
          />
        )}
        {data?.cameras && cameras && (
          <FilterSummaryChipGroup
            ids={cameras}
            data={data.cameras}
            renderGroupedLabel={(count) => `${count} Cameras`}
            onDelete={(id) =>
              setFilters({
                cameras: cameras.filter((cId) => id !== cId),
              })
            }
            onDeleteAll={() => clearFilters(["cameras"])}
          />
        )}
        {data?.users && users && (
          <FilterSummaryChipGroup
            ids={users}
            data={data.users}
            renderGroupedLabel={(count) => `${count} Users`}
            onDelete={(id) =>
              setFilters({
                users: users.filter((cId) => id !== cId),
              })
            }
            onDeleteAll={() => clearFilters(["users"])}
          />
        )}
        {activities && (
          <FilterSummaryChipGroup
            ids={activities}
            data={activityOptions}
            renderGroupedLabel={(count) => `${count} Activities`}
            onDelete={(id) =>
              setFilters({
                activities: activities.filter((cId) => id !== cId),
              })
            }
            onDeleteAll={() => clearFilters(["activities"])}
          />
        )}
      </DesktopBox>
      <Box m={1} flexGrow={1} />
      {anyFilterSet && (
        <Button
          color="primary"
          onClick={resetFilters}
          style={{
            whiteSpace: "nowrap",
            flexShrink: 0,
          }}
        >
          Clear Filters
        </Button>
      )}
      <Popover
        {...(fitsTablet && {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          anchorEl: buttonRef.current,
        })}
        open={open}
        classes={{
          paper: classes.paper,
        }}
        BackdropProps={{
          style: {
            background: "rgba(0, 0, 0, .2)",
          },
        }}
      >
        <Box p="20px">
          {!data ? (
            <Loading>Loading Filters</Loading>
          ) : (
            <>
              <Hidden smUp>
                <Typography variant="h4">Filter log by</Typography>
                <Box m={1} />
              </Hidden>
              <Box className={classes.threeGrid}>
                <FilterControl label="Date">
                  <DatePresetSelector {...filters} />
                </FilterControl>
                <FilterControl label="From">
                  <DateFieldSelector
                    field="start"
                    fallback={allDatesStart}
                    {...filters}
                  />
                </FilterControl>
                <FilterControl label="To">
                  <DateFieldSelector field="end" {...filters} />
                </FilterControl>
              </Box>
              <Box m={2} />
              <Box className={classes.twoGrid}>
                <FilterControl label="Groups & Locations">
                  <AuditLogGroupSelect
                    value={filters.state.groups ?? []}
                    onChange={(groups) =>
                      filters.actions.setGroups(groups, data.cameras)
                    }
                    options={data.tags}
                  />
                </FilterControl>
                <FilterControl label="Cameras">
                  <AuditLogCameraSelect<typeof data.cameras[number]>
                    value={filters.state.cameras ?? []}
                    onChange={(cameras) =>
                      filters.actions.setState({ cameras })
                    }
                    options={
                      filters.state.groups?.length
                        ? data.cameras.filter((camera) =>
                            filters.state.groups?.some((groupId) =>
                              camera.tags.some((group) => group.id === groupId)
                            )
                          )
                        : data.cameras
                    }
                    renderOption={(option) => (
                      <Box className={classes.cameraFilterOption}>
                        <img
                          className={classes.cameraFilterPreview}
                          src={option.still.replace(
                            "/no-still.svg",
                            "/no-still-audit-log.svg"
                          )}
                          onError={(ev) =>
                            ((ev.target as any).src = "/no-still-audit-log.svg")
                          }
                          alt=""
                        />
                        <Box m={0.5} />
                        <Typography variant="body2">
                          {option.name}
                          <br />
                          {option.location.name}
                        </Typography>
                      </Box>
                    )}
                  />
                </FilterControl>
              </Box>
              <Box m={2} />
              <Box className={classes.twoGrid}>
                <FilterControl label="Users">
                  <AuditLogUserSelect
                    value={filters.state.users ?? []}
                    onChange={(users) => filters.actions.setState({ users })}
                    options={data.users}
                  />
                </FilterControl>
                <FilterControl label="Activity">
                  <AuditLogActivitySelect
                    value={(filters.state.activities ?? []) as ActivityTypes[]}
                    onChange={(activities) =>
                      filters.actions.setState({ activities })
                    }
                    options={activityOptions}
                  />
                </FilterControl>
              </Box>
            </>
          )}
        </Box>
        <Divider />
        <Grid container style={{ padding: 12 }}>
          <Button
            color="primary"
            size="small"
            className={classes.actionButton}
            onClick={() => {
              resetFilters();
              toggleOpen();
            }}
          >
            RESET
          </Button>
          <Box flexGrow={1} />
          <Button
            color="primary"
            size="small"
            className={classes.actionButton}
            onClick={() => {
              filters.reset();
              toggleOpen();
            }}
          >
            CANCEL
          </Button>
          <Button
            color="primary"
            size="small"
            className={classes.actionButton}
            style={{ fontWeight: "bold" }}
            onClick={() => {
              setFilters(filters.state);
              toggleOpen();
            }}
          >
            APPLY
          </Button>
        </Grid>
      </Popover>
    </Grid>
  );
}

function DesktopBox({ children, ...props }: BoxProps) {
  const { fitsTablet } = useBreakpoints();

  if (fitsTablet) {
    return <Box {...props}>{children}</Box>;
  }
  return <>{children}</>;
}

const useFilterSummaryChipStyles = makeStyles()((theme) => ({
  chipRoot: {
    backgroundColor: "transparent",
    border: "none",
    marginRight: 8,
  },
  label: {
    paddingRight: 4,
    paddingLeft: 0,
  },
  deleteIcon: {
    width: 14,
    height: 14,
    margin: "0 5px 0 -2px",
  },
}));
function FilterSummaryChip({
  label,
  onDelete,
}: {
  label: string;
  onDelete: () => void;
}) {
  const { classes } = useFilterSummaryChipStyles();
  return (
    <Chip
      label={label}
      variant="outlined"
      deleteIcon={<CloseIcon />}
      classes={{
        root: classes.chipRoot,
        label: classes.label,
        deleteIcon: classes.deleteIcon,
      }}
      onDelete={onDelete}
    />
  );
}

function FilterSummaryChipGroup({
  ids,
  data,
  onDelete,
  onDeleteAll,
  renderGroupedLabel,
}: {
  ids: (number | string)[];
  data: { id: number | string; name: string }[];
  onDelete: (id: number | string) => void;
  onDeleteAll: () => void;
  renderGroupedLabel: (count: number) => string;
}) {
  const { fitsTablet } = useBreakpoints();
  const chips = ids
    .map((id) => {
      const matchingEntity = data.find((candidate) => candidate.id === id);
      return (
        matchingEntity && (
          <FilterSummaryChip
            key={id}
            label={matchingEntity.name}
            onDelete={() => onDelete(id)}
          />
        )
      );
    })
    .filter(filterFalsy);
  const chipLimit = fitsTablet ? 2 : 1;
  return (
    <>
      {chips.length > chipLimit ? (
        <FilterSummaryChip
          label={renderGroupedLabel(chips.length)}
          onDelete={() => onDeleteAll()}
        />
      ) : (
        chips
      )}
    </>
  );
}

function FilterControl({
  label,
  children,
}: PropsWithChildren<{ label: string }>) {
  return (
    <Grid container direction="column">
      <Typography style={{ fontWeight: "bold" }}>{label}</Typography>
      {children}
    </Grid>
  );
}

const allDatesStart = startOfDay(new Date("2000-01-01T00:00:00"));
const dateRangePresets: {
  label: string;
  range?: { start?: Date; end?: Date };
}[] = [
  {
    label: "All dates",
    range: { start: allDatesStart },
  },
  {
    label: "Today",
    range: { start: today },
  },
  {
    label: "Yesterday",
    range: { start: subDays(1, today) },
  },
  {
    label: "Last 7 days",
    range: { start: subDays(7, today) },
  },
  {
    label: "Last 30 days",
    range: { start: subDays(30, today) },
  },
  {
    label: "This Year",
    range: { start: startOfThisYear },
  },
];
function getDateRangeLabel({
  start,
  end,
}: {
  start?: Date | null;
  end?: Date | null;
}) {
  const preset = dateRangePresets.find(
    (p) =>
      p.range?.start?.getTime() === (start ?? allDatesStart).getTime() &&
      p.range?.end?.getTime() === end?.getTime()
  );
  const f = format("PP");
  return preset?.label ?? `${f(start ?? allDatesStart)} - ${f(end ?? today)}`;
}
function DatePresetSelector({
  state: { start, end },
  actions: { setDateRange },
}: ReturnType<typeof useFiltersState>) {
  const presetIndex = dateRangePresets.findIndex(
    (p) =>
      p.range?.start?.getTime() === (start ?? allDatesStart).getTime() &&
      p.range?.end?.getTime() === end?.getTime()
  );
  return (
    <FormControl variant="outlined" size="small" style={{ minWidth: 220 }}>
      <Select
        variant="outlined"
        MenuProps={{
          disablePortal: true,
        }}
        value={presetIndex === -1 ? dateRangePresets.length : presetIndex}
        onChange={(e) => {
          const range = dateRangePresets[e.target.value as number]?.range;
          if (!range) return; // Custom
          setDateRange(range.start, range.end);
        }}
      >
        {dateRangePresets.map((p, i) => (
          <MenuItem key={p.label} value={i}>
            {p.label}
          </MenuItem>
        ))}
        {presetIndex === -1 && (
          <MenuItem value={dateRangePresets.length}>Custom</MenuItem>
        )}
      </Select>
    </FormControl>
  );
}
function DateFieldSelector({
  field,
  fallback,
  state: filtersState,
  actions: { setDate },
}: {
  field: "start" | "end";
  fallback?: Date;
} & ReturnType<typeof useFiltersState>) {
  const [open, setOpen] = useState(false);

  return (
    <DatePicker
      open={open}
      onClose={() => setOpen(false)}
      InputProps={{
        placeholder: "",
        className: "pointer-events-none",
      }}
      renderInput={(props) => (
        <TextField
          {...(props as TextFieldProps)}
          InputProps={{
            className: "pointer-events-none",
          }}
          onClick={(e) => {
            e.preventDefault();
            setOpen(!open);
          }}
          variant="outlined"
          size="small"
        />
      )}
      inputFormat="PP"
      value={filtersState[field] ?? fallback}
      onChange={(date) => setDate(field, date ?? undefined)}
      maxDate={today}
    />
  );
}

interface HeadCell {
  id?: keyof MappedData;
  disablePadding?: boolean;
  label: string;
}

function useHeadCells(): HeadCell[] {
  const { fitsTablet } = useBreakpoints();
  return ([
    { id: "timestamp", label: "User Timestamp" },
    fitsTablet && { id: "camera" as const, label: "Location / Camera" },
    { id: "user", label: "User" },
    { id: "activity", label: "Activity" },
  ] as const).filter(filterFalsy);
}

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof MappedData
  ) => void;
  order: Order;
  orderBy: string;
}

function EnhancedTableHead({
  order,
  orderBy,
  onRequestSort,
}: EnhancedTableProps) {
  const { classes } = useStyles();
  const createSortHandler = (property: keyof MappedData) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };
  const headCells = useHeadCells();

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {headCells.map((headCell) => {
          const active = orderBy === headCell.id;
          return (
            <TableCell
              key={headCell.id}
              align="left"
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={active ? order : false}
            >
              {headCell.id ? (
                <TableSortLabel
                  active={active}
                  direction={active ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                  style={{ fontWeight: active ? "bold" : undefined }}
                >
                  {headCell.label}
                  {active ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              ) : (
                headCell.label
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles<void, "detailMode">()(
  (theme: Theme, _props, classes) => ({
    wrapper: {
      borderTop: "1px solid rgba(224, 224, 224, 1)",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },

    table: {
      fontSize: 16,
      overflowX: "hidden",
    },
    tableHead: {
      "& th": {
        whiteSpace: "nowrap",
      },
    },
    tableRow: {
      cursor: "pointer",
      transition: "background 200ms, transform 300ms",
      "& > td": { border: "initial", padding: 16, fontSize: 16 },
      "&:nth-of-type(odd)": { backgroundColor: "#F6FBFF" },

      [`&:not(.${classes.detailMode}):hover`]: { backgroundColor: "#DDEFFF" },
    },

    detailMode: {
      cursor: "default",
      [theme.breakpoints.up("sm")]: {
        transform: "scale(1.03, 1.03)",
      },
    },
    detailWrapper: {
      position: "relative",
      display: "grid",
      gridTemplateColumns: "1fr",
      columnGap: "48px",
      rowGap: "16px",
      padding: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(4),
        gridTemplateColumns: "1fr 1fr",
      },
    },
    detailTextView: {
      borderBottom: "1px solid #aaa",
      flexGrow: 1,
    },
  })
);

const activityMap: Record<ActivityTypes, string> = {
  [ActivityTypes.LiveView]: "Live View",
  [ActivityTypes.VodView]: "VOD View",
  [ActivityTypes.SaveClip]: "Save Clip",
  [ActivityTypes.DownloadClip]: "Download Clip",
  [ActivityTypes.ShareClip]: "Share Clip",
  [ActivityTypes.Snapshot]: "Download Snapshot",
  [ActivityTypes.EnableCameraSharing]: "Enable Camera Share",
  [ActivityTypes.DisableCameraSharing]: "Disable Camera Share",
};

function EnhancedTable() {
  const { classes } = useStyles();
  const { fitsTablet } = useBreakpoints();
  const [detailLogId, setDetailLogId] = useState(0);
  const disableDetail = () => setDetailLogId(0);
  const {
    lastSeenId,
    setLastSeenId,
    page,
    setPage,
    pageSize,
    setPageSize,
    search: searchInputParam,
    start,
    end,
    groups,
    cameras,
    users,
    activities,
    sort,
    toggleSort,
    resetFilters,
  } = usePaginationParams();
  const order = !sort ? "desc" : sort?.startsWith("-") ? "desc" : "asc";
  const orderBy = !sort
    ? "timestamp"
    : (sort?.replace("-", "") as keyof MappedData);

  const filterParams = {
    search: searchInputParam,
    startDate: start ? formatIsoDate(start) : undefined,
    endDate: end ? formatIsoDate(end) : undefined,
    groups,
    cameras,
    users,
    activities: activities as ActivityTypes[],
  };
  const {
    data: paginationData,
    loading: paginationDataLoading,
    error: paginationError,
  } = useGetUserActivityPaginationQuery({
    variables: filterParams,
  });
  useEffect(() => {
    if (lastSeenId || !paginationData?.userActivity.lastId) return;

    setLastSeenId(paginationData.userActivity.lastId);
  }, [paginationData?.userActivity.lastId, lastSeenId, setLastSeenId]);

  const { data, loading: resultsLoading, error } = useGetUserActivityQuery({
    variables: {
      lastSeenId,
      page,
      pageSize,
      sort: sort,
      ...filterParams,
    },
    skip: !lastSeenId,
  });
  const headCells = useHeadCells();

  if (error || paginationError) {
    return (
      <ErrorMessage title="Oops!" description="Failed to load audit log." />
    );
  }

  let content: React.ReactNode;
  if (resultsLoading || paginationDataLoading) {
    content = (
      <TableRow className={classes.tableRow}>
        <TableCell colSpan={headCells.length}>
          <div className="py-16">
            <Loading>Fetching Audit Logs</Loading>
          </div>
        </TableCell>
      </TableRow>
    );
  } else if (!data?.userActivity) {
    content = (
      <TableRow className={classes.tableRow}>
        <TableCell colSpan={headCells.length}>
          <ErrorMessage
            title="No results found"
            description={
              <>
                <Typography>Try adjusting or clearing your filters.</Typography>
                <Button
                  color="primary"
                  style={{
                    fontSize: 16,
                    fontWeight: "normal",
                    marginLeft: -8,
                  }}
                  onClick={resetFilters}
                >
                  Clear filters
                </Button>
              </>
            }
          />
        </TableCell>
      </TableRow>
    );
  } else {
    const rows = data.userActivity.results.map(mapData);
    const emptyRows = (pageSize ?? 25) - rows.length;
    content = (
      <>
        {data.userActivity.lastId &&
          lastSeenId &&
          data.userActivity.lastId > lastSeenId && (
            <TableRow className={classes.tableRow}>
              <TableCell colSpan={headCells.length}>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Typography>New audit logs available</Typography>
                  <Box m={2} />
                  <Button
                    component={QueryParamLink}
                    params={{ lastSeenId: data.userActivity.lastId }}
                    color="inherit"
                  >
                    Show new results
                  </Button>
                </Box>
              </TableCell>
            </TableRow>
          )}
        {rows.map((row) => {
          const detailMode = row.id === detailLogId;
          return (
            <TableRow
              key={row.id}
              className={clsx(classes.tableRow, {
                [classes.detailMode]: detailMode,
              })}
              onClick={() => setDetailLogId(row.id)}
            >
              {detailMode ? (
                <TableCell colSpan={headCells.length} style={{ padding: 0 }}>
                  <ClickAwayListener
                    mouseEvent={fitsTablet ? "onMouseUp" : false} // Disable on touch devices
                    touchEvent={fitsTablet ? undefined : false}
                    onClickAway={() => disableDetail()}
                  >
                    <Paper
                      elevation={6}
                      square
                      className={classes.detailWrapper}
                    >
                      <Hidden smUp>
                        <Tooltip title="close">
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              disableDetail();
                            }}
                            className="absolute right-0 top-0"
                            size="large"
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      </Hidden>
                      <TextView
                        className={classes.detailTextView}
                        label="User Timestamp"
                      >
                        <strong>
                          {formatActivityDateTime(new Date(row.timestamp))}
                        </strong>
                      </TextView>
                      <div
                        className={clsx({
                          "flex items-end justify-between": !!row.link,
                        })}
                      >
                        <TextView
                          className={classes.detailTextView}
                          label="Video Start/End Time"
                        >
                          <VodLink
                            cameraId={row.cameraId}
                            startTime={row.startTime}
                            endTime={row.endTime}
                            activity={row.activity}
                          >
                            <strong>
                              {formatActivityDateTime(new Date(row.startTime))}{" "}
                              - {formatActivityTime(new Date(row.endTime))}
                            </strong>
                          </VodLink>
                        </TextView>

                        {row.link && (
                          <Button
                            color="primary"
                            variant="outlined"
                            size="small"
                            className="font-bold min-w-[44px] ml-2"
                            component="a"
                            href={row.link}
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            View
                          </Button>
                        )}
                      </div>

                      <TextView
                        className={classes.detailTextView}
                        label="Location"
                      >
                        <strong>{row.location}</strong>
                      </TextView>
                      <TextView
                        className={classes.detailTextView}
                        label="Camera"
                      >
                        <strong>{row.camera}</strong>
                      </TextView>
                      <TextView className={classes.detailTextView} label="User">
                        <strong>{row.user}</strong>
                      </TextView>
                      <div className="flex">
                        <TextView
                          className={clsx(classes.detailTextView, "shrink-0")}
                          label="Activity"
                        >
                          <strong>{activityMap[row.activity]}</strong>
                        </TextView>
                        {row.metadata.sharedWith && (
                          <TextView
                            className={clsx(
                              classes.detailTextView,
                              "overflow-x-auto max-w-[300px] ml-4"
                            )}
                            label="Shared With"
                          >
                            <strong>{row.metadata.sharedWith}</strong>
                          </TextView>
                        )}
                      </div>
                    </Paper>
                  </ClickAwayListener>
                </TableCell>
              ) : (
                <>
                  <TableCell>
                    <Typography>
                      {fitsTablet ? (
                        formatActivityDateTime(new Date(row.timestamp))
                      ) : (
                        <>
                          {formatActivityDate(new Date(row.timestamp))}
                          <br />
                          {formatActivityTime(new Date(row.timestamp))}
                        </>
                      )}
                    </Typography>
                  </TableCell>
                  {fitsTablet && (
                    <TableCell>
                      <Typography>{row.location}</Typography>
                      <Typography
                        variant="body2"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <CameraIcon
                          style={{
                            opacity: 0.5,
                            fontSize: "1em",
                          }}
                        />
                        <Box m={0.25} />
                        {row.camera}
                      </Typography>
                    </TableCell>
                  )}
                  <TableCell style={{ whiteSpace: "nowrap" }}>
                    {row.user}
                  </TableCell>
                  <TableCell style={{ whiteSpace: "nowrap" }}>
                    {activityMap[row.activity] ?? row.activity}
                  </TableCell>
                </>
              )}
            </TableRow>
          );
        })}
        {emptyRows > 0 && (
          <TableRow style={{ height: 76 * emptyRows }}>
            <TableCell colSpan={headCells.length} />
          </TableRow>
        )}
      </>
    );
  }

  return (
    <>
      {/* <EnhancedTableToolbar /> */}
      <Table size="small" className={classes.table}>
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={(_, property) => toggleSort(property)}
        />
        <TableBody>{content}</TableBody>
      </Table>
      {paginationData && (
        <TablePagination
          rowsPerPageOptions={[25, 50, 100, 250, 500, 1000]}
          component="div"
          count={paginationData.userActivity.paginationInfo.totalCount ?? 0}
          rowsPerPage={pageSize ?? 25}
          page={page ?? 0}
          onPageChange={(_, newPage) => setPage(newPage)}
          onRowsPerPageChange={(event) =>
            setPageSize(Number(event.target.value))
          }
        />
      )}
    </>
  );
}

const footageViewActivities = new Set([
  ActivityTypes.VodView,
  ActivityTypes.LiveView,
  ActivityTypes.DownloadClip,
  ActivityTypes.SaveClip,
]);
function VodLink({
  cameraId,
  startTime,
  endTime,
  activity,
  children,
}: PropsWithChildren<{
  cameraId: number;
  startTime: string;
  endTime: string;
  activity: ActivityTypes;
}>) {
  const { data } = useCameraFirstSegmentTimeQuery({
    variables: { id: cameraId },
  });

  if (
    !footageViewActivities.has(activity) ||
    !data?.camera.firstSegmentTime ||
    isBefore(new Date(data.camera.firstSegmentTime), new Date(startTime))
  ) {
    return <>{children}</>;
  }

  return (
    <QueryParamLink
      to="../../search"
      params={{ cams: cameraId, vod: `${startTime}|${endTime}` }}
      mergeCurrentParams={false}
    >
      {children}
    </QueryParamLink>
  );
}

gql`
  query cameraFirstSegmentTime($id: Int!) {
    camera(id: $id) {
      id
      firstSegmentTime
    }
  }
`;

const formatActivityTime = format("p");
const formatActivityDate = format("MMMM d, yyyy");
const formatActivityDateTime = format("MMMM d, yyyy, p");

gql`
  query getUserActivity(
    $startDate: Date
    $endDate: Date
    $search: String
    $groups: [Int!]
    $cameras: [Int!]
    $users: [Int!]
    $activities: [ActivityTypes!]
    $sort: String
    $lastSeenId: Int
    $page: Int
    $pageSize: Int
  ) {
    userActivity(
      startDate: $startDate
      endDate: $endDate
      search: $search
      groups: $groups
      cameras: $cameras
      users: $users
      activities: $activities
      sort: $sort
      lastSeenId: $lastSeenId
      page: $page
      pageSize: $pageSize
    ) {
      results {
        id
        activity
        timestamp
        startTime
        endTime
        user
        camera
        cameraId
        location
        link
        metadata {
          sharedWith
        }
      }
      lastId
    }
  }
`;
gql`
  query getUserActivityPagination(
    $startDate: Date
    $endDate: Date
    $search: String
    $groups: [Int!]
    $cameras: [Int!]
    $users: [Int!]
    $activities: [ActivityTypes!]
    $lastSeenId: Int
  ) {
    userActivity(
      startDate: $startDate
      endDate: $endDate
      search: $search
      groups: $groups
      cameras: $cameras
      users: $users
      activities: $activities
      lastSeenId: $lastSeenId
    ) {
      lastId
      paginationInfo {
        totalCount
      }
    }
  }
`;

gql`
  query auditLogFilterOptions {
    tags {
      id
      name
    }
    cameras {
      id
      name
      still
      location {
        id
        name
      }
      tags {
        id
      }
    }
    users {
      id
      name
      email
    }
  }
`;

gql`
  query userActivityPdfDownload(
    $lastSeenId: Int!
    $timezone: String!
    $startDate: DateTime
    $endDate: DateTime
    $search: String
    $groups: [Int!]
    $cameras: [Int!]
    $users: [Int!]
    $activities: [ActivityTypes!]
  ) {
    userActivityPdfDownload(
      lastSeenId: $lastSeenId
      timezone: $timezone
      startDate: $startDate
      endDate: $endDate
      search: $search
      groups: $groups
      cameras: $cameras
      users: $users
      activities: $activities
    )
  }
`;
