import { QueryResult } from "@apollo/client";
import { Close, DeleteForever, Edit, LocationOn } from "@mui/icons-material";
import Check from "@mui/icons-material/Check";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  ChipProps,
  Divider,
  Grid,
  IconButton,
  Link as MaterialLink,
  Paper,
  Popper,
  Tooltip,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { format } from "date-fns/fp";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { DatePicker as FormikDatePicker } from "formik-mui-x-date-pickers";
import gql from "graphql-tag";
import { groupBy, identity, mapValues, orderBy } from "lodash/fp";
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHotkeys } from "react-hotkeys-hook";
import {
  Link,
  Route,
  Routes,
  useLocation,
  useMatch,
  useParams,
} from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import { NumberParam, useQueryParam } from "use-query-params";

import { ReactComponent as CaseIcon } from "@/icons/case.svg";

import { formatIsoDate, parseIsoDate } from "@/util/date";
import { pluralize } from "@/util/pluralize";
import { useBreakpoints } from "@/util/useBreakpoints";
import { useDocumentTitle } from "@/util/useDocumentTitle";
import { useSlugMatch } from "@/util/useSlugMatch";

import { ManageCaseCollaboratorsButton } from "@/pages/Cases/AddCaseCollaboratorButton";
import { AddCaseTagsButton } from "@/pages/Cases/AddCaseTagButton";
import { AnnotationButton } from "@/pages/Cases/AnnotationButton";
import { AnnotationLabel } from "@/pages/Cases/AnnotationLabel";
import { CaseClipPlayer } from "@/pages/Cases/CaseClipPlayer";
import { CaseComments } from "@/pages/Cases/CaseComments";
import { CaseMedia } from "@/pages/Cases/CaseMedia";
import {
  CASE_COLLABORATOR_FRAGMENT,
  CASE_FRAGMENT,
} from "@/pages/Cases/caseFragments";

import { isDemoUser, useMe } from "@/components/Auth";
import { Chip } from "@/components/Chip";
import { CaseQueryVariables, downloadSupport } from "@/components/Downloads";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { CaseContentType } from "@/components/Player/AddToCaseDialog";
import { usePlayerControls } from "@/components/Player/PlayerBase";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { createStyledTabs } from "@/components/StyledTabs/StyledTabs";
import { DefaultDialog, useDialog } from "@/components/shared/Dialog";
import { QueryParamLink } from "@/components/shared/QueryParamLink";

import {
  Case as CaseType,
  GetCaseQuery,
  GetSharedCaseQuery,
  ResolutionStatus,
  useDeleteCaseMutation,
  useGetCaseQuery,
  useSubsequentAnnotationLabelQuery,
  useUpdateCaseMutation,
  useUpdateCaseStatusMutation,
  useUpdateCaseTagsMutation,
} from "@/generated-models";
import { useOrgSlugNavigate, usePrefixOrgSlug } from "@/hooks/useOrgRouteBase";
import { useCustomScrollbarStyles } from "@/layout/theme";

import { CaseAttachments } from "./CaseAttachments";
import { CaseCollaboratorChip } from "./CaseCollaboratorChip";
import { CaseDescription } from "./CaseDescription";
import { CaseScreenshotViewer } from "./CaseScreenshotViewer";
import {
  CreateAnnotationContextProvider,
  useAnnotationContext,
} from "./CreateAnnotationContextProvider";
import { DownloadCaseButton } from "./DownloadCaseButton";
import { ShareCaseButton } from "./ShareCaseButton";

const useStyles = makeStyles()((theme) => ({
  annotationsOverflowContainer: {
    display: "flex",
    overflow: "auto",
    gap: 2,

    "&::-webkit-scrollbar": {
      background: "transparent",
      height: 4,
      width: 8,
    },
    "&::-webkit-scrollbar-thumb": {
      border: "none",
      boxShadow: "none",
      background: "rgba(0, 0, 0, .3)",
      borderRadius: 8,
      minHeight: 40,
    },
  },
  lightShadowContainer: {
    boxShadow: `
      0px 3px 3px -2px rgb(0 0 0 / 0%),
      0px 3px 4px 0px rgb(0 0 0 / 9%),
      0px 1px 8px 0px rgba(0,0,0,0.12)`,
    position: "relative",
  },
  mobileNavTab: {
    color: "inherit",
    flexGrow: 1,
    padding: theme.spacing(1),
  },
}));

export function Case() {
  return (
    <CreateAnnotationContextProvider>
      <SpotUserCaseInner />
    </CreateAnnotationContextProvider>
  );
}

function SpotUserCaseInner() {
  useDocumentTitle("Cases");
  const { id } = useParams();
  const caseQuery = useGetCaseQuery({
    variables: { id: Number(id) },
    skip: !id,
    fetchPolicy: "cache-and-network",
  });

  return <CaseInner caseQuery={caseQuery} anonymous={false} />;
}

function SpotUserAnnotationButton({ caseId }: { caseId: number }) {
  // Determine the next annotation label: "A", "B", "C", ...etc
  const {
    data: subsequentAnnotationLabelData,
  } = useSubsequentAnnotationLabelQuery({ variables: { caseId } });
  const { placingAnnotation, setPlacingAnnotation } = useAnnotationContext();

  return placingAnnotation ? (
    <Paper
      elevation={0}
      className="flex items-center px-4 whitespace-nowrap bg-black text-white"
    >
      <Typography className="text-sm mr-8">Placing Annotation</Typography>
      <Button color="primary" onClick={() => setPlacingAnnotation(false)}>
        Cancel
      </Button>
    </Paper>
  ) : (
    <Button
      variant="contained"
      color="primary"
      className="shrink-0"
      onClick={() => setPlacingAnnotation(true)}
      startIcon={
        <AnnotationLabel>
          {subsequentAnnotationLabelData?.subsequentAnnotationLabel ?? "A"}
        </AnnotationLabel>
      }
    >
      Add Annotation
    </Button>
  );
}

type CaseQuery = GetCaseQuery | GetSharedCaseQuery;
type Annotation = NonNullable<CaseQuery["case"]>["annotations"][number];

export function CaseInner({
  caseQuery,
  anonymous,
}: {
  caseQuery:
    | QueryResult<GetCaseQuery, any>
    | QueryResult<GetSharedCaseQuery, any>;
  anonymous: boolean;
}) {
  const { classes } = useStyles();
  const { fitsDesktop } = useBreakpoints();
  const me = useMe();
  const [clipId] = useQueryParam("clip", NumberParam);
  const [screenshotId] = useQueryParam("screenshot", NumberParam);
  const [startTime] = useQueryParam("startTime", NumberParam);
  const prefixOrgSlug = usePrefixOrgSlug();

  // locationState.time functions as a nasty hack to trigger this useEffect any time an
  // annotationLabel is clicked, even if the clipId and startTime of that annotation
  // are already set in the URL Query params
  const location = useLocation();
  const locationState: any = location.state;
  const { seek, play, pause } = usePlayerControls();
  const { placingAnnotation } = useAnnotationContext();
  useEffect(() => {
    if (placingAnnotation) {
      pause();
    } else {
      play();
    }
  }, [placingAnnotation, play, pause]);

  // Seek if the startTime is changed
  useEffect(() => {
    if (!startTime) return;

    // Seek
    seek(startTime);
  }, [startTime, locationState?.time, seek]);

  const { data, error } = caseQuery;

  if (error) {
    return (
      <ErrorMessage
        title="Unable to load case"
        description="We're having trouble loading the case, please try again later"
      />
    );
  }

  if (!data?.case) {
    return <Loading grow>Fetching Case</Loading>;
  }

  // isDemo and viewOnly are only relevant for demo orgs
  const isDemo = !!me && isDemoUser(me);
  let viewOnly = false;
  if (isDemo) {
    if (
      data.case.__typename === "SharedCase" &&
      data.case.createdBy?.name !== me?.name
    ) {
      viewOnly = true;
    }
    if (data.case.__typename === "Case" && data.case.createdBy?.id !== me?.id) {
      viewOnly = true;
    }
  }

  const annotationButton = data.case.permissions.comment ? (
    <SpotUserAnnotationButton caseId={data.case.id} />
  ) : null;

  const Layout = fitsDesktop ? DesktopLayout : MobileLayout;
  return (
    <Layout
      anonymous={anonymous}
      header={<CaseHeader caseInfo={data.case} anonymous={anonymous} />}
      player={
        clipId ? (
          <CaseClipPlayer
            key={clipId}
            clipId={clipId}
            anonymous={anonymous}
            caseCommentPermission={data.case.permissions.comment}
          />
        ) : screenshotId ? (
          <CaseScreenshotViewer
            caseId={data.case.id}
            screenshotId={screenshotId}
            anonymous={anonymous}
          />
        ) : null
      }
      noClips={
        data.case.clips.length === 0 && data.case.screenshots.length === 0 ? (
          <div className="max-w-lg p-8 md:p-16">
            {data.case.permissions.manage_media ? (
              <>
                <Typography>
                  <strong>Case created.</strong> The next step is to add video
                  clips to your investigation. They will show up here as you add
                  them to this case.
                </Typography>
                <Button
                  component={Link}
                  to={prefixOrgSlug(`/search?case=${data.case.id}`)}
                  variant="contained"
                  color="primary"
                  size="large"
                  className="md:text-lg md:py-5 md:px-14 mt-4"
                >
                  Search and add clips
                </Button>
              </>
            ) : (
              <>
                <Typography>
                  <strong>Case created.</strong> The next step is to add video
                  clips to your investigation.
                </Typography>
                <div className="h-2" />
                <Typography>
                  However, you don't seem to have permission to add clips. Ask a
                  clip editor to add clips, or grant yourself the{" "}
                  <em>case editor</em> role to allow you to add clips.
                </Typography>
              </>
            )}
          </div>
        ) : null
      }
      annotationShortcut={
        data.case.annotations.length > 0 && (
          <div className="py-1 px-2 bg-[#f4f4f4] flex justify-between gap-4">
            <div className={classes.annotationsOverflowContainer}>
              {(data.case.annotations as Annotation[])
                .filter(
                  (a) => a.content !== null && (a.clip || a.caseScreenshotId)
                )
                .map((annotation) => (
                  <AnnotationButton
                    key={annotation.id}
                    itemId={annotation.clip?.id ?? annotation.caseScreenshotId!}
                    type={
                      annotation.clip
                        ? CaseContentType.clip
                        : CaseContentType.screenshot
                    }
                    annotation={annotation}
                    classes={{
                      annotation: "bg-[#ededed] border border-[#dcdcdc]",
                    }}
                  />
                ))}
            </div>
            {!fitsDesktop && (clipId || screenshotId) && annotationButton}
          </div>
        )
      }
      annotationButton={(clipId || screenshotId) && annotationButton}
      media={
        <CaseMedia
          id={data.case.id}
          className={clsx("transition-opacity", {
            "opacity-50 pointer-events-none": placingAnnotation,
          })}
          anonymous={anonymous}
        />
      }
      metadata={
        <CaseMetadata
          caseInfo={data.case}
          viewOnly={viewOnly}
          anonymous={anonymous}
        />
      }
      comments={
        <CaseComments
          caseId={data.case.id}
          viewOnly={viewOnly}
          anonymous={anonymous}
        />
      }
      placingAnnotation={placingAnnotation}
    />
  );
}

interface CaseLayoutProps {
  header: ReactNode;
  player: ReactNode | null;
  noClips: ReactNode | null;
  metadata: ReactNode;
  comments: ReactNode;
  media: ReactNode;
  annotationButton: ReactNode;
  placingAnnotation: boolean;
  annotationShortcut: ReactNode;
  anonymous: boolean;
}

function DesktopLayout({
  header,
  player,
  noClips,
  metadata,
  comments,
  media,
  annotationButton,
  placingAnnotation,
  annotationShortcut,
  anonymous,
}: CaseLayoutProps) {
  const { classes: scrollbarClasses } = useCustomScrollbarStyles();
  const mainContentRef = useRef<HTMLDivElement>(null);
  const [clipId] = useQueryParam("clip", NumberParam);
  const [startTime] = useQueryParam("startTime", NumberParam);

  // Scroll to top when picking a clip or changing the startTime
  // which basically happens when clicking an annotation or timestamp
  // in a comment.
  useEffect(() => {
    if (!mainContentRef.current) return;
    mainContentRef.current.scroll({ behavior: "smooth", top: 0 });
  }, [clipId, startTime]);

  return (
    <div className="flex h-full overflow-auto">
      <div
        className={clsx(
          "flex flex-col grow shrink-0 overflow-auto max-w-[324px]",
          scrollbarClasses.scrollbarContainer
        )}
        ref={mainContentRef}
      >
        {header}
        {metadata}
      </div>
      <div
        className={clsx(
          "flex flex-col grow pb-10 overflow-auto bg-white border-x border-[#d8d8d8] border-solid",
          scrollbarClasses.scrollbarContainer
        )}
        ref={mainContentRef}
      >
        {noClips ?? (
          <>
            {player ? (
              <div className="shrink-0">{player}</div>
            ) : (
              <div className="flex-center flex-col bg-[#1B1B1B] min-h-[320px]">
                <img
                  src="/logo-light-main.svg"
                  alt="Spot AI"
                  className="h-14 mb-4"
                />
                <Typography
                  variant="subtitle1"
                  className="text-white font-medium"
                >
                  Select an annotation or clip to start viewing
                </Typography>
              </div>
            )}
            {annotationShortcut}
            {!anonymous && player && annotationButton && (
              <div className="flex items-center gap-4 py-2 px-4 bg-[#efefef]">
                {annotationButton}
                <Typography variant="body2">
                  Click to create an annotation on the video. Use annotations to
                  mark points of interest. <AnnotationsInfoPopper />
                </Typography>
              </div>
            )}
            {comments}
          </>
        )}
      </div>
      <div
        className={clsx(
          "flex flex-col shrink-0 pb-10 max-w-[324px] overflow-auto transition-opacity",
          scrollbarClasses.scrollbarContainer,
          { "opacity-50 pointer-events-none": placingAnnotation }
        )}
      >
        {media}
      </div>
    </div>
  );
}

const { NavTabs, NavTab } = createStyledTabs({
  tab: {
    root: {
      fontSize: 14,
    },
  },
});

function MobileLayout({
  header,
  player,
  noClips,
  metadata,
  comments,
  media,
  annotationShortcut,
  annotationButton,
  anonymous,
}: CaseLayoutProps) {
  const { classes } = useStyles();
  // const { classes:scrollbarClasses } = useCustomScrollbarStyles();
  const [clipId] = useQueryParam("clip", NumberParam);
  const [startTime] = useQueryParam("startTime", NumberParam);

  // Scroll to top when picking a clip or changing the startTime
  // which basically happens when clicking an annotation or timestamp
  // in a comment.
  useEffect(() => {
    window.scroll({ behavior: "smooth", top: 0 });
  }, [clipId, startTime]);

  const tabMatch = useSlugMatch("cases/:id/:tab");
  const sharedTabMatch = useMatch("/s/case/:token/:tab");

  const tabLocation =
    tabMatch?.params.tab ?? sharedTabMatch?.params.tab ?? "comments";

  return (
    <div>
      {header}
      {noClips ??
        (player ? (
          <div className="shrink-0">{player}</div>
        ) : (
          <div className="flex-center flex-col bg-[#1B1B1B] h-[135px]">
            <img
              src="/logo-light-main.svg"
              alt="Spot AI"
              className="h-10 mb-1"
            />
            <Typography
              variant="subtitle1"
              component={QueryParamLink}
              to="clips"
              className="p-2 text-white font-medium text-sm"
            >
              Select an annotation or clip to start viewing
            </Typography>
          </div>
        ))}
      {annotationShortcut}
      <NavTabs value={tabLocation}>
        <NavTab
          label="Overview"
          component={QueryParamLink}
          className={classes.mobileNavTab}
          to="overview"
          value="overview"
        />
        <NavTab
          label="Media"
          component={QueryParamLink}
          className={classes.mobileNavTab}
          to="media"
          value="media"
        />
        <NavTab
          label="Annotations & Comments"
          component={QueryParamLink}
          className={classes.mobileNavTab}
          to="."
          value="comments"
        />
      </NavTabs>
      <div className="shadow-divider w-full h-3 mt-2" />
      <Routes>
        <Route index element={comments} />
        <Route path="overview" element={metadata} />
        <Route path="media" element={media} />
      </Routes>
    </div>
  );
}

function splitOutMostPrevalent(values: string[]) {
  // orderBy([1, 0], Object.entries(...)) means first sort by the values, then by keys.
  // in this case, the values are the occurrence counts for each unique value
  // keys are the actual unique values themselves
  let tally = orderBy(
    [1, 0],
    ["desc", "asc"],
    Object.entries(
      mapValues((v: string[]) => v.length, groupBy(identity, values))
    )
  ).map((entry) => entry[0]);

  return [tally.shift(), tally] as const;
}

function CaseHeader({
  caseInfo,
  anonymous,
}: {
  caseInfo: NonNullable<(GetCaseQuery | GetSharedCaseQuery)["case"]>;
  anonymous: boolean;
}) {
  // Determine which location is the most prevelant one amongst all clips.
  // This location name will be fully displayed, the rest will by collapsed
  // under a `+ x others` tooltip
  const [mostPrevalentLocation, remainingLocations] = splitOutMostPrevalent(
    [...caseInfo.clips, ...caseInfo.screenshots].map(
      ({ camera }) => camera.location.name
    )
  );
  const { fitsTablet } = useBreakpoints();

  return (
    <div className="flex shrink-0 items-center justify-start py-5 pl-1 pr-3 bg-primary text-white min-w-0">
      {!anonymous ? (
        <IconButton component={Link} to="../cases" className="text-white">
          <ChevronLeftIcon fontSize={fitsTablet ? "medium" : "large"} />
        </IconButton>
      ) : (
        <div className="w-6" />
      )}

      <div className="flex-center gap-3 min-w-0">
        <CaseIcon className="w-[36px] h-auto shrink-0" />

        <div className="min-w-0">
          <EditableCaseField
            initialValue={caseInfo.title}
            name="title"
            caseId={caseInfo.id}
            className="flex items-center gap-2 text-white"
            editable={caseInfo.permissions.edit_info}
          >
            {({ actions, editing }) =>
              editing ? (
                <>
                  <Field
                    name="title"
                    component={TextField}
                    InputProps={{
                      classes: { root: "text-inherit" },
                      disableUnderline: true,
                    }}
                    autoFocus
                    onFocus={(event: any) => {
                      event.target.select();
                    }}
                  />
                  <div className="h-[1em] flex-center">{actions}</div>
                </>
              ) : (
                <>
                  <Typography
                    component="h1"
                    className="font-bold text-lg truncate"
                  >
                    {caseInfo.title}
                  </Typography>
                  <div className="h-[1em] flex-center">{actions}</div>
                </>
              )
            }
          </EditableCaseField>
          {mostPrevalentLocation && (
            <>
              <Typography className="truncate" variant="body2">
                <LocationOn fontSize="small" />
                <div className="inline-block w-1" />
                {mostPrevalentLocation}
              </Typography>
              {remainingLocations.length > 0 && (
                <Tooltip
                  title={
                    <ul>
                      {remainingLocations.map((l) => (
                        <li key={l}>{l}</li>
                      ))}
                    </ul>
                  }
                >
                  <Typography
                    variant="caption"
                    className="text-[#fffc] cursor-default ml-6"
                  >
                    + {remainingLocations.length}{" "}
                    {pluralize(
                      { 1: "other", multi: "others" },
                      remainingLocations.length
                    )}
                  </Typography>
                </Tooltip>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

const useAnnotationsInfoPopperStyles = makeStyles()((theme) => ({
  button: {
    fontFamily: "inherit",
    fontSize: "inherit",
    padding: 0,
    margin: 0,
    lineHeight: "inherit",
    verticalAlign: "initial",

    "&:hover": {
      cursor: "pointer",
    },
  },
  popper: {
    maxWidth: 440,
    padding: theme.spacing(3),
    position: "relative",
    zIndex: 10,
  },
}));

function AnnotationsInfoPopper() {
  const [popperOpen, setPopperOpen] = useState(false);
  const anchor = useRef<HTMLButtonElement | null>(null);
  const { classes } = useAnnotationsInfoPopperStyles();
  const closePopper = useCallback(() => setPopperOpen(false), [setPopperOpen]);
  return (
    <>
      <MaterialLink
        className={classes.button}
        onClick={(event: any) => {
          event.stopPropagation();
          event.preventDefault();
          setPopperOpen((open) => !open);
        }}
        ref={anchor}
        component="button"
        underline="hover"
      >
        Learn more.
      </MaterialLink>
      <Popper
        id="annotations-info-popper"
        open={popperOpen}
        anchorEl={anchor.current}
        style={{ zIndex: 100 }}
      >
        <Paper className={classes.popper} elevation={4}>
          <Grid container direction="column">
            <Grid container justifyContent="space-between" alignItems="center">
              <Typography variant="h3">Annotations</Typography>
              <IconButton onClick={closePopper} size="small">
                <CloseIcon />
              </IconButton>
            </Grid>
            <Box m={0.5} />
            <Divider />
            <Box m={0.5} />
            <Typography>
              Use annotations to describe events you discover on the video. Use
              them to quickly share an event or object found. They provide a
              shortcut to that spot in time and area of the video.
            </Typography>
            <Box m={0.5} />
            <Button
              variant="contained"
              color="primary"
              onClick={closePopper}
              style={{ minWidth: 120, alignSelf: "flex-end" }}
            >
              Ok
            </Button>
          </Grid>
        </Paper>
      </Popper>
    </>
  );
}

function CaseMetadata({
  caseInfo,
  viewOnly,
  anonymous,
}: {
  caseInfo:
    | NonNullable<GetCaseQuery["case"]>
    | NonNullable<GetSharedCaseQuery["case"]>;
  viewOnly: boolean;
  anonymous: boolean;
}) {
  const me = useMe();
  const { pushSnackbar } = useFeedback();
  const [deleteCase] = useDeleteCaseMutation({
    variables: { id: caseInfo.id },
  });
  const [updateCaseStatus] = useUpdateCaseStatusMutation({
    refetchQueries: ["caseWithComments"],
    onError: () =>
      pushSnackbar("Failed to save case, please try again", FeedbackType.Error),
  });
  const [updateCaseTags] = useUpdateCaseTagsMutation({
    onError: () =>
      pushSnackbar(
        "Failed to delete tag, please try again",
        FeedbackType.Error
      ),
  });
  const { open: openDeleteCaseDialog, ...dialogProps } = useDialog();
  const navigate = useOrgSlugNavigate();
  const caseSharing = caseInfo.permissions.share;
  const [dialogState, setDialogState] = useState<
    "addCollaborator" | "shareCase" | null
  >(null);

  function deleteCaseTag(tag: string) {
    const input = {
      id: caseInfo.id,
      tags: caseInfo.tags.filter((t) => t !== tag),
    };
    updateCaseTags({
      variables: input,
      optimisticResponse: {
        __typename: "Mutation",
        updateCase: {
          __typename: "Case",
          ...input,
        },
      },
    });
  }

  const params = useParams();
  const downloadParams: CaseQueryVariables | null = useMemo(() => {
    if (params.id) {
      return { caseId: Number(params.id) };
    }
    if (params.token) {
      return { shareToken: params.token! };
    }
    return null;
  }, [params]);

  const caseDownloadsEnabled = Boolean(
    downloadSupport &&
      downloadParams &&
      me?.organization.cloudSyncEnabled &&
      caseInfo.permissions.download
  );

  return (
    <div
      data-cy="case-info"
      className="flex flex-col grow items-start w-full p-7 bg-[#E8F4FF]"
    >
      <EditableCaseField
        initialValue={caseInfo.description}
        name="description"
        caseId={caseInfo.id}
        className="flex flex-col w-full"
        editable={caseInfo.permissions.edit_info}
      >
        {({ actions, editing }) => (
          <>
            <Typography className="font-bold flex items-center gap-2">
              Description {actions}
            </Typography>
            {editing ? (
              <>
                <Field
                  name="description"
                  component={TextField}
                  multiline
                  rows={4}
                  fullWidth
                  autoFocus
                />
              </>
            ) : caseInfo.description.trim() ? (
              <CaseDescription description={caseInfo.description} />
            ) : (
              <Typography className="opacity-50">
                Enter description here
              </Typography>
            )}
          </>
        )}
      </EditableCaseField>

      <div className="h-4" />

      <EditableCaseField
        initialValue={caseInfo.incidentDate ?? new Date()}
        name="incidentDate"
        caseId={caseInfo.id}
        className="flex flex-col w-full"
        serializeValue={formatIsoDate}
        editable={caseInfo.permissions.edit_info}
      >
        {({ actions, editing }) => (
          <>
            <Typography className="font-bold flex items-center gap-2">
              Incident date {actions}
            </Typography>
            {editing ? (
              <>
                <Field
                  name="incidentDate"
                  autoOk
                  inputFormat="MM/dd/yyyy"
                  disableFuture
                  disableToolbar
                  textField={{
                    // label: "Incident Date",
                    variant: "outlined",
                    fullWidth: true,
                  }}
                  component={FormikDatePicker}
                />
              </>
            ) : caseInfo.incidentDate ? (
              <Typography>
                {format("MMMM d, y", parseIsoDate(caseInfo.incidentDate))}
              </Typography>
            ) : (
              <Typography className="opacity-50 italic" variant="body2">
                {caseInfo.permissions.edit_info
                  ? "Enter an incident date here"
                  : "none set"}
              </Typography>
            )}
          </>
        )}
      </EditableCaseField>

      <Typography className="mt-4 font-bold">Tags</Typography>
      {caseInfo.tags.length > 0 && <div className="h-2" />}
      <div className="flex flex-wrap gap-1">
        {caseInfo.tags
          .slice()
          .sort()
          .map((tag, i) => (
            <Chip
              key={i}
              label={tag}
              onDelete={
                !anonymous && caseInfo.permissions.edit_info
                  ? () => deleteCaseTag(tag)
                  : undefined
              }
              {...chipProps}
            />
          ))}
        {caseInfo.permissions.edit_info ? (
          <AddCaseTagsButton
            className="h-8"
            caseId={caseInfo.id}
            currentTags={caseInfo.tags}
          />
        ) : (
          caseInfo.tags.length === 0 && (
            <Typography className="opacity-50 italic" variant="body2">
              none
            </Typography>
          )
        )}
      </div>

      <CaseAttachments
        caseId={caseInfo.id}
        attachments={caseInfo.attachments}
        manageFilesPermission={caseInfo.permissions.manage_files}
      />

      {!anonymous && caseInfo.__typename === "Case" && (
        <>
          <Typography className="mt-4 font-bold">Collaborators</Typography>
          <div className="h-2" />
          <div className="flex flex-col gap-1 w-full items-stretch">
            {caseInfo.caseCollaborators.map((collaborator) => (
              <CaseCollaboratorChip
                key={collaborator.id}
                caseId={caseInfo.id}
                collaborator={collaborator}
                collaboratorManagamentDisabled={
                  // no manage_collaborator permission
                  !caseInfo.permissions.manage_collaborators ||
                  // the collaborator is the current user
                  collaborator.orgUserId === me?.orgUserId ||
                  // view only
                  viewOnly
                }
              />
            ))}
          </div>
          {caseInfo.permissions.manage_collaborators && !viewOnly && (
            <>
              <div className="h-2" />
              <ManageCaseCollaboratorsButton
                caseId={caseInfo.id}
                initialCollaborators={caseInfo.caseCollaborators}
                open={() => setDialogState("addCollaborator")}
                close={() => setDialogState(null)}
                opened={dialogState === "addCollaborator"}
              />
            </>
          )}
        </>
      )}

      <div className="m-2" />

      <Typography className="font-bold">Resolution Status</Typography>
      <div className="h-1" />
      <Paper variant="outlined" className="flex items-center p-1 rounded-[9px]">
        <ResolutionStatusSwitch
          status={caseInfo.resolutionStatus}
          onChange={(resolutionStatus) => {
            updateCaseStatus({
              variables: {
                id: caseInfo.id,
                resolutionStatus,
              },
              optimisticResponse: {
                __typename: "Mutation",
                updateCaseStatus: {
                  ...(caseInfo as CaseType),
                  resolutionStatus,
                },
              },
            });
          }}
          disabled={!caseInfo.permissions.change_status}
        />
      </Paper>

      {(caseSharing || caseDownloadsEnabled) && (
        <>
          <div className="m-2" />
          <Typography>
            <strong>Actions</strong>
          </Typography>
          <div className="h-1" />
          <div className="flex gap-3">
            {caseSharing && (
              <ShareCaseButton
                caseId={caseInfo.id}
                open={() => setDialogState("shareCase")}
                close={() => setDialogState(null)}
                openCollaborators={() => setDialogState("addCollaborator")}
                opened={dialogState === "shareCase"}
              />
            )}
            {caseDownloadsEnabled && (
              <DownloadCaseButton {...downloadParams!} />
            )}
          </div>
        </>
      )}

      <div className="grow m-2" />
      {!anonymous && caseInfo.permissions.delete && (
        <Button
          className="items-start"
          color="primary"
          startIcon={<DeleteForever />}
          onClick={async () => {
            const caseDeletionConfirmed = await openDeleteCaseDialog();
            if (!caseDeletionConfirmed) return;

            deleteCase()
              .then(() => {
                pushSnackbar("Case deleted", FeedbackType.Info);
                navigate("/cases", { replace: true });
              })
              .catch((error) => {
                pushSnackbar(
                  `Problem deleting case: ${error.message}. Please try again later.`,
                  FeedbackType.Error
                );
              });
          }}
        >
          Delete Case
        </Button>
      )}
      <DefaultDialog
        title="Delete Case"
        content="Are you sure you want to delete this case along with all its content? This action is irreversible."
        confirmColor="primary"
        confirmText="Delete Case"
        {...dialogProps}
      />
    </div>
  );
}

function EditableCaseField<T>({
  children,
  initialValue,
  name,
  caseId,
  serializeValue = identity,
  className,
  editable,
}: {
  children: (props: { actions: ReactNode; editing: boolean }) => ReactNode;
  initialValue: T;
  name: string;
  caseId: number;
  serializeValue?: (value: T) => any;
  className?: string;
  editable: boolean;
}) {
  const { pushSnackbar } = useFeedback();
  const [editing, setEditing] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const [updateCase] = useUpdateCaseMutation({
    onError: () =>
      pushSnackbar("Failed to save case, please try again", FeedbackType.Error),
  });
  useHotkeys(
    "escape",
    () => {
      setEditing(false);
    },
    {
      enableOnTags: ["INPUT", "TEXTAREA"],
      // Only capture escape presses from within the form parent
      filter: (event) =>
        formRef.current?.contains(event.target as any) ?? false,
    }
  );
  useHotkeys(
    "ctrl+enter",
    () => {
      const submitButton:
        | HTMLButtonElement
        | null
        | undefined = formRef.current?.querySelector("button[type=submit]");
      submitButton?.click();
    },
    {
      enableOnTags: ["INPUT", "TEXTAREA"],
      // Only capture escape presses from within the form parent
      filter: (event) =>
        formRef.current?.contains(event.target as any) ?? false,
    }
  );
  return (
    <Formik
      // Should reset form state when switching `editing` state
      key={Number(editing)}
      initialValues={{
        [name]: initialValue,
      }}
      onSubmit={async (values) => {
        const update = { [name]: serializeValue(values[name]) };
        await updateCase({
          variables: { id: caseId, update },
        });
        setEditing(false);
      }}
    >
      {({ isSubmitting }) => (
        <Form
          ref={formRef}
          className={className}
          onClick={() => {
            if (!editing && editable) setEditing(true);
          }}
        >
          {children({
            actions: !editable ? null : editing ? (
              <div className="flex">
                <Tooltip title="Save (ctrl/cmd+enter)">
                  <IconButton
                    disabled={isSubmitting}
                    className="opacity-50 hover:opacity-100 transition-opacity"
                    color="inherit"
                    size="small"
                    type="submit"
                  >
                    <Check fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Cancel (esc)">
                  <IconButton
                    disabled={isSubmitting}
                    className="opacity-50 hover:opacity-100 transition-opacity"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setEditing(false);
                    }}
                  >
                    <Close fontSize="small" />
                  </IconButton>
                </Tooltip>
              </div>
            ) : (
              <Tooltip title="Edit">
                <IconButton
                  disabled={isSubmitting}
                  className="opacity-50 hover:opacity-100 transition-opacity"
                  color="inherit"
                  size="small"
                  onClick={() => setEditing(true)}
                >
                  <Edit fontSize="small" />
                </IconButton>
              </Tooltip>
            ),
            editing,
          })}
        </Form>
      )}
    </Formik>
  );
}

const resolutionLabels: Record<
  ResolutionStatus,
  {
    label: string;
    color: "primary" | "secondary";
  }
> = {
  [ResolutionStatus.Open]: {
    label: "Open",
    color: "primary",
  },
  // [ResolutionStatus.InProgress]: "In-Progress",
  // [ResolutionStatus.Resolved]: "Resolved",
  [ResolutionStatus.Closed]: {
    label: "Closed",
    color: "secondary",
  },
};

function ResolutionStatusSwitch({
  status: activeStatus,
  onChange,
  disabled = false,
}: {
  status: ResolutionStatus;
  onChange?: (status: ResolutionStatus) => void;
  disabled?: boolean;
}) {
  return (
    <>
      {Object.entries(resolutionLabels).map(([status, { label, color }]) => {
        const active = status === activeStatus;
        return (
          <Button
            key={status}
            color={active ? color : "inherit"}
            variant={active ? "contained" : "text"}
            size="small"
            className={clsx(
              "min-w-[100px] rounded-[7px] text-base",
              active ? "font-bold" : "font-normal text-text text-opacity-60"
            )}
            disabled={disabled}
            onClick={
              !active && onChange
                ? () => onChange(status as ResolutionStatus)
                : undefined
            }
          >
            {label}
          </Button>
        );
      })}
    </>
  );
}

export const chipProps: ChipProps = {
  deleteIcon: <CloseIcon />,
} as const;

gql`
  mutation updateCase($id: Int!, $update: CaseUpdate!) {
    updateCase(id: $id, update: $update) {
      ...CaseBase
    }
  }
  ${CASE_FRAGMENT}
`;

gql`
  mutation deleteCase($id: Int!) {
    deleteCase(id: $id) {
      id
      deleted
    }
  }
`;

gql`
  mutation updateCaseStatus($id: Int!, $resolutionStatus: ResolutionStatus!) {
    updateCaseStatus(id: $id, resolutionStatus: $resolutionStatus) {
      ...CaseBase
    }
  }
  ${CASE_FRAGMENT}
`;

gql`
  query getCase($id: Int!) {
    case(id: $id) {
      ...CaseBase
    }
  }
  ${CASE_FRAGMENT}
`;

gql`
  query usersForCase {
    users {
      id
      orgUserId
      name
      email
    }
  }
`;

gql`
  mutation removeCaseCollaborator($caseId: Int!, $orgUserId: Int!) {
    removeCaseCollaborator(caseId: $caseId, orgUserId: $orgUserId) {
      id
      deleted
    }
  }
`;

gql`
  mutation updateCaseCollaboratorRole(
    $caseCollaboratorId: Int!
    $caseRole: CaseRole!
  ) {
    updateCaseCollaboratorRole(
      caseCollaboratorId: $caseCollaboratorId
      caseRole: $caseRole
    ) {
      ...CaseCollaboratorBase
    }
  }
  ${CASE_COLLABORATOR_FRAGMENT}
`;
