import { CSSObject } from "@emotion/react";
import LeftIcon from "@mui/icons-material/ChevronLeft";
import CalendarIcon from "@mui/icons-material/Event";
import PersonIcon from "@mui/icons-material/Person";
import LocationIcon from "@mui/icons-material/Place";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Link as MaterialLink,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { format, formatDistanceStrict, isBefore } from "date-fns/fp";
import gql from "graphql-tag";
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Link, useParams } from "react-router-dom";
import { GlobalStyles } from "tss-react";
import { makeStyles } from "tss-react/mui";
import { BooleanParam, useQueryParams } from "use-query-params";

import {
  isDocumentInFullScreenMode,
  useGlobalFullscreenState,
} from "@/util/fullscreen";
import { insertBreaklines } from "@/util/insertBreaklines";
import { useBreakpoints } from "@/util/useBreakpoints";

import { useMe } from "@/components/Auth";
import { RelativeDatePicker } from "@/components/DateTimePicker/RelativeDatePicker";
import { executeZendeskCmd } from "@/components/Scripts/Zendesk";
import { Logo } from "@/components/shared/Logo";

import { useSharingOrganizationQuery } from "@/generated-models";
import { AuthenticatedHeader, GuestHeader } from "@/layout/Header";
import { Layout } from "@/layout/Layout";

const useStyles = makeStyles<{ isFullscreen: boolean }>()(
  (theme, { isFullscreen }) => ({
    appBar: {
      display: "flex",
      flexDirection: "row",
      background: "white",
      color: theme.palette.text.primary,
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    shareLabel: {
      opacity: 0.4,
      letterSpacing: 6,
      fontSize: 12,
      marginLeft: theme.spacing(3),
    },
    appBarSpacer: theme.mixins.toolbar as CSSObject,
    container: {
      marginBottom: isFullscreen ? 0 : 50,
      padding: 0,
    },
    poweredBy: {
      background: "#f4f4f4",
      fontSize: 8,
      color: "#b5b5b5",
    },
    wrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      height: "100%",
      width: "100%",
      background: "#f8f8f8",
      minHeight: "100vh",
    },
    grid: {
      width: "100%",
      display: "grid",
      gridTemplateAreas: `
      ". player ."
      ". playerMetadata ."
      ". body ."
      ". promo . "
    `,
      gridTemplateColumns: `auto minmax(auto, ${theme.breakpoints.values.md}px) auto`,
      marginBottom: 40,
    },
    paper: {
      gridArea: "body",
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0,
      padding: "20px 30px",

      [theme.breakpoints.down("md")]: {
        borderRadius: 0,
      },
    },
    sharedClipInfoItem: {
      display: "flex",
      alignItems: "center",
      margin: "0 14px",

      [theme.breakpoints.down("sm")]: {
        margin: "6px 0",
      },
    },
  })
);

export function GuestLayout({
  player,
  title,
  expiry,
  onExpiryChange,
  onDisable,
  // onDelete,
  description,
  onDescriptionChange,
  locationName,
  createdAt,
  createdBy,
}: {
  player: React.ReactNode;
  title: React.ReactNode;
  expiry: string | null;
  onExpiryChange?: (value: Date | null) => void;
  onDisable?: () => void;
  // onDelete?: () => void;
  description: string;
  onDescriptionChange?: (value: string) => void;
  locationName: string;
  createdAt?: string;
  createdBy?: string;
}) {
  const [isFullscreen, setIsFullscreen] = useGlobalFullscreenState(
    "isFullscreen"
  );
  const [layoutParams] = useQueryParams({
    hideLayout: BooleanParam,
  });
  const { fitsTablet, fitsDesktop } = useBreakpoints();
  const { classes } = useStyles({ isFullscreen });
  const { token } = useParams();
  const { data } = useSharingOrganizationQuery({
    variables: { shareToken: token! },
    skip: !token,
  });

  useEffect(() => {
    executeZendeskCmd("webWidget", layoutParams?.hideLayout ? "hide" : "show");
  }, [layoutParams?.hideLayout]);

  document.addEventListener("fullscreenchange", () => {
    setIsFullscreen(isDocumentInFullScreenMode());
  });

  const me = useMe();
  const HeaderComponent = me ? AuthenticatedHeader : GuestHeader;

  if (layoutParams.hideLayout) {
    return (
      <div className="h-full bg-black flex flex-col justify-center">
        <GlobalStyles
          styles={{
            "iframe#launcher": {
              display: `none !important`,
            },
          }}
        />
        {player}
      </div>
    );
  }

  return (
    <Layout
      header={
        <HeaderComponent
          customLogoSrc={data?.sharingOrganization.logo ?? undefined}
        />
      }
    >
      <article className={classes.wrapper}>
        {/* <GuestHeader location={"Todo St."} createdAt={new Date(vod.createdAt)} /> */}
        <div className="p-4 w-full relative">
          <IconButton
            component={Link}
            to={
              data?.sharingOrganization
                ? `/o/${data.sharingOrganization.slug}/shares`
                : "/shares"
            }
            size="small"
            className="-ml-2 text-primary relative z-1"
          >
            <LeftIcon fontSize="large" />
          </IconButton>
          <Typography
            className="text-center block absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 z-0"
            variant="h1"
          >
            Shares
          </Typography>
        </div>
        <Box className={classes.grid}>
          <GridCellBackground color="#141414" />
          {player}
          <GridCellBackground color="#141414" />
          <Paper className={classes.paper} elevation={fitsDesktop ? 4 : 0}>
            <Grid container direction={fitsTablet ? "row" : "column"}>
              <Typography variant="h2">{title}</Typography>
              {onExpiryChange ? (
                <>
                  <Box flexGrow={1} m={1} />
                  <RelativeDatePicker
                    label="Expiration"
                    value={expiry ? new Date(expiry) : null}
                    onChange={onExpiryChange}
                    disableImmediately={onDisable}
                    FormControlProps={{ fullWidth: false, size: "small" }}
                    style={{ minWidth: 250 }}
                  />
                </>
              ) : (
                expiry && (
                  <>
                    <Box flexGrow={1} />
                    {isBefore(new Date(), new Date(expiry)) ? (
                      <Typography color="error">Expired</Typography>
                    ) : (
                      <Typography>
                        Expires in{" "}
                        {formatDistanceStrict(new Date(), new Date(expiry))}
                      </Typography>
                    )}
                  </>
                )
              )}
            </Grid>
            {description && (
              <>
                <Box m={1} />
                <Typography
                  style={{
                    fontSize: ".9em",
                    opacity: 0.4,
                  }}
                >
                  Description
                </Typography>
                <Paper
                  elevation={4}
                  style={{
                    padding: 10,
                  }}
                >
                  {onDescriptionChange ? (
                    <DescriptionField
                      initialValue={description}
                      onChange={onDescriptionChange}
                    />
                  ) : (
                    <Typography style={{ width: "100%" }}>
                      {insertBreaklines(description)}
                    </Typography>
                  )}
                </Paper>
              </>
            )}
            <Divider style={{ margin: "24px 0" }} />
            <Grid
              container
              direction={fitsTablet ? "row" : "column"}
              justifyContent="center"
            >
              <Box className={classes.sharedClipInfoItem}>
                <LocationIcon fontSize="small" />
                <Box m={0.5} />
                {locationName}
              </Box>
              {createdAt && (
                <Box className={classes.sharedClipInfoItem}>
                  <CalendarIcon fontSize="small" />
                  <Box m={0.5} />
                  {formatCreatedAt(new Date(createdAt))}
                </Box>
              )}
              {createdBy && (
                <Box className={classes.sharedClipInfoItem}>
                  <PersonIcon fontSize="small" />
                  <Box m={0.5} />
                  {createdBy}
                </Box>
              )}
            </Grid>
          </Paper>
          <GuestFooter />
        </Box>
      </article>
    </Layout>
  );
}

export function GuestErrorLayout({ children }: PropsWithChildren<{}>) {
  const me = useMe();
  const HeaderComponent = me ? AuthenticatedHeader : GuestHeader;
  return (
    <Layout header={<HeaderComponent />}>
      <Box
        style={{
          background: "black",
        }}
      >
        {children}
      </Box>
      <GuestFooter />
    </Layout>
  );
}

export function OutageErrorLayout({ children }: PropsWithChildren<{}>) {
  return (
    <Layout header={<GuestHeader />}>
      <Box>{children}</Box>
      <GuestFooter />
    </Layout>
  );
}

export function SharedLinkError({
  title,
  description,
}: {
  title: React.ReactNode;
  description?: React.ReactNode;
}) {
  return (
    <Box
      style={{
        color: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: 40,
      }}
    >
      <img src="/expired-icon.svg" alt="" />
      <Typography variant="h2">{title}</Typography>
      {description && (
        <Box m={2} maxWidth={320}>
          <Typography
            variant="h3"
            style={{
              color: "#AAA",
              fontSize: 16,
              fontWeight: "normal",
              textAlign: "center",
            }}
          >
            {description}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export function GuestFooter() {
  return (
    <Box
      style={{ gridArea: "promo", color: "#353d48" }}
      display="flex"
      alignItems="center"
      flexDirection="column"
      mt={4}
      px={2}
    >
      <Logo className="h-5" />
      <Box m={1} />
      <Typography
        variant="h4"
        style={{
          fontSize: 16,
        }}
      >
        One dashboard for all your cameras.
      </Typography>
      <Typography
        style={{
          maxWidth: 450,
          textAlign: "center",
          fontSize: 14,
          opacity: 0.7,
        }}
      >
        <strong>See, search</strong> and <strong>share</strong> video from your
        existing security cameras on a modern web dashboard that takes 10
        minutes to set up.
      </Typography>
      <Box m={0.5} />
      <MaterialLink
        href="https://www.spot.ai"
        target="_blank"
        style={{ fontSize: 14, fontWeight: "bold" }}
        underline="hover"
      >
        LEARN MORE
      </MaterialLink>
    </Box>
  );
}

function DescriptionField({
  initialValue,
  onChange,
}: {
  initialValue: string;
  onChange: (value: string) => void;
}) {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(initialValue);

  function submit() {
    onChange(value);
    setEditing(false);
  }
  const cancel = useCallback(() => {
    setValue(initialValue);
    setEditing(false);
  }, [setValue, setEditing, initialValue]);

  return (
    <Grid container direction="column" alignItems="flex-end">
      {editing ? (
        <>
          <TextField
            multiline
            value={value}
            fullWidth
            autoFocus
            variant="outlined"
            onChange={(event) => setValue(event.target.value)}
            onKeyUp={(e) => {
              if (e.keyCode === 13 && (e.metaKey || e.ctrlKey)) {
                e.preventDefault();
                e.stopPropagation();
                submit();
              }
            }}
          />

          <Box>
            <Button color="primary" size="small" onClick={cancel}>
              CANCEL
            </Button>
            <Button color="primary" size="small" onClick={submit}>
              SAVE
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Typography style={{ width: "100%" }}>
            {insertBreaklines(value)}
          </Typography>

          <Button
            color="primary"
            size="small"
            style={{
              fontWeight: 400,
              letterSpacing: 2,
              alignSelf: "flex-end",
            }}
            onClick={() => setEditing(true)}
          >
            EDIT
          </Button>
        </>
      )}
    </Grid>
  );
}

const formatCreatedAt = format("MMM dd, yyyy");

function GridCellBackground({ color }: { color: string }) {
  return <div style={{ backgroundColor: color }} />;
}

gql`
  query sharingOrganization($shareToken: String!) {
    sharingOrganization(shareToken: $shareToken) {
      id
      name
      slug
      logo
    }
  }
`;
