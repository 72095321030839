import { DeleteForever } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { camelCase } from "lodash";
import { useMemo, useState } from "react";
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";

import { IntegrationCreateFormFields } from "../../Create/IntegrationCreateForm";
import { SchemaActionButton } from "../../SchemaActionButton";
import {
  BASE_CONNECT_INPUT_PROPS,
  DEFAULT_CONNECT_EVENT,
  DEFAULT_CONNECT_EVENT_ROW,
  FormMode,
} from "../../constant";
import {
  IntegrationSchemaType,
  NestedIntegrationSchemaTypes,
  getSchemaPropertyDisplayName,
} from "./utils";

interface EventFormCardProps {
  idx: number;
  mode?: FormMode;
  className?: string;
  onDelete: () => void;
}

interface EventSchemaFormRowProps {
  path: string;
  disabled?: boolean;
  onDelete: () => void;
}

function useSchemaPropertyOptions(nested?: boolean) {
  return useMemo(() => {
    return Object.entries(IntegrationSchemaType)
      .filter((f) => !nested || !NestedIntegrationSchemaTypes.includes(f[1]))
      .sort((a, b) => {
        // Sort nested fields as the last items.
        if (NestedIntegrationSchemaTypes.includes(a[1])) {
          return 1;
        } else if (NestedIntegrationSchemaTypes.includes(b[1])) {
          return -1;
        }
        return getSchemaPropertyDisplayName(a[1]).localeCompare(
          getSchemaPropertyDisplayName(b[1])
        );
      })
      .map(([label, value]) => ({
        label: getSchemaPropertyDisplayName(label),
        value,
      }));
  }, [nested]);
}

function EventSchemaTypeSelect({
  disabled,
  parentPath,
}: {
  disabled?: boolean;
  parentPath: string;
}) {
  const { control } = useFormContext();
  const options = useSchemaPropertyOptions(parentPath.includes("children"));

  const { append, remove } = useFieldArray({
    control,
    name: `${parentPath}.children`,
  });

  return (
    <FormControl size="small" className="w-full">
      <InputLabel variant="outlined" className="-my-3 -mx-3" shrink>
        Schema Type
      </InputLabel>

      <Controller
        name={`${parentPath}.type`}
        render={({ field }) => (
          <Select
            disabled={disabled}
            variant="outlined"
            defaultValue={IntegrationSchemaType.string}
            input={
              <OutlinedInput
                notched={false}
                className="bg-white h-[45px]"
                label="string"
              />
            }
            value={field.value}
            renderValue={(v) => {
              return options.find((o) => o.value === v)?.label || v;
            }}
            onBlur={field.onBlur}
            onChange={(e) => {
              const nested = NestedIntegrationSchemaTypes.includes(
                e.target.value
              );
              const isCurrentlyNested = NestedIntegrationSchemaTypes.includes(
                field.value
              );

              field.onChange(e.target.value);
              if (nested) {
                if (!isCurrentlyNested) {
                  append({ ...DEFAULT_CONNECT_EVENT_ROW });
                }
              } else {
                remove();
              }
            }}
            className="rounded-lg"
          >
            {options.map((option) => {
              const selected = field.value === option.value;
              return (
                <MenuItem
                  className={clsx("py-2", {
                    "text-white bg-primary": selected,
                  })}
                  value={option.value}
                  key={option.value}
                >
                  <ListItemIcon
                    className={clsx({
                      invisible: !selected,
                    })}
                  >
                    <CheckIcon className="text-white" />
                  </ListItemIcon>
                  <ListItemText primary={option.label} />
                </MenuItem>
              );
            })}
          </Select>
        )}
      />
    </FormControl>
  );
}

function EventSchemaFormRow({
  path,
  disabled,
  onDelete,
}: EventSchemaFormRowProps) {
  const { setValue } = useFormContext();
  const [isDuplicate, setIsDuplicate] = useState(true);
  const fields = useWatch({ name: `${path}.children` });
  const type = useWatch({ name: `${path}.type` });
  const nested = NestedIntegrationSchemaTypes.includes(type);

  const handleIntegrationTitleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target;
    const generatedKey = camelCase(value);
    setValue(`${path}.name`, generatedKey);
  };

  const handleIntegrationKeyChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (isDuplicate) {
      setIsDuplicate(false);
    }
    const { value } = e.target;
    const generatedKey = camelCase(value);
    setValue(`${path}.name`, generatedKey);
  };

  return (
    <div className="flex flex-col gap-y-4 mt-4">
      <div className="w-full flex gap-3 items-center">
        <Controller
          name={`${path}.description`}
          render={({ field }) => (
            <TextField
              disabled={disabled}
              className="w-full"
              placeholder="Schema Title"
              label="Schema Title"
              {...BASE_CONNECT_INPUT_PROPS}
              {...field}
              onChange={(e) => {
                if (isDuplicate) {
                  handleIntegrationTitleChange(e);
                }
                field.onChange(e);
              }}
            />
          )}
        />
        {!disabled && (
          <IconButton onClick={onDelete} className="mt-[6px]" size="small">
            <ClearIcon className="w-6 h-6" />
          </IconButton>
        )}
      </div>
      <div className="flex flex-col md:flex-row gap-y-10 items-start gap-3 justify-between mt-4 relative">
        <Controller
          name={`${path}.name`}
          rules={{
            required: {
              value: true,
              message: "Please provide a name",
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              disabled={disabled}
              error={!!error}
              helperText={error?.message}
              className="grow"
              classes={{
                root: "w-full",
              }}
              placeholder="Schema Key"
              label="Schema Key"
              {...BASE_CONNECT_INPUT_PROPS}
              {...field}
              onChange={(e) => {
                handleIntegrationKeyChange(e);
                field.onChange(e);
              }}
            />
          )}
        />
        <div className="flex flex-wrap sm:flex-nowrap sm:justify-between w-full gap-4">
          <div className="basis-full sm:basis-auto w-full">
            <EventSchemaTypeSelect disabled={disabled} parentPath={path} />
          </div>
          <Controller
            name={`${path}.required`}
            render={({ field }) => (
              <FormControlLabel
                disabled={disabled}
                label="Required"
                className="text-[#757575] pl-0.5 mt-0.5"
                control={<Checkbox />}
                checked={field.value}
                {...field}
              />
            )}
          />
        </div>
      </div>
      {nested && (
        <>
          <div className="flex flex-col gap-3 px-10 pt-4 mb-4 relative">
            {fields?.map((f: unknown, idx: number) => (
              <EventSchemaFormRow
                disabled={disabled}
                key={idx}
                path={`${path}.children.${idx}`}
                onDelete={() => {
                  setValue(
                    `${path}.children`,
                    fields.filter((f: unknown, fIdx: number) => fIdx !== idx)
                  );
                }}
              />
            ))}
          </div>
          {!disabled && (
            <div className="px-10">
              <SchemaActionButton
                onClick={() => {
                  setValue(`${path}.children`, [
                    ...fields,
                    { ...DEFAULT_CONNECT_EVENT_ROW },
                  ]);
                }}
              >
                + Add Schema to{" "}
                {type === IntegrationSchemaType.array_of_objects
                  ? "Array"
                  : "Object"}
              </SchemaActionButton>
            </div>
          )}
        </>
      )}
    </div>
  );
}

const durationOptions = () => {
  const minutes = Array.from({ length: 10 }, (v, i) => {
    const minute = i + 1;
    return {
      label: `${minute} ${minute === 1 ? "minute" : "minutes"}`,
      value: minute * 60000,
    };
  });
  const seconds = Array.from({ length: 3 }, (v, i) => {
    const second = (i + 1) * 15;
    return {
      label: `${second} ${second === 1 ? "second" : "seconds"}`,
      value: second * 1000,
    };
  });

  return [...seconds, ...minutes];
};

function calculateSecondsLabel(seconds: number) {
  switch (true) {
    case seconds < 60:
      return `${seconds} seconds`;

    default:
      return `${seconds / 60} minutes`;
  }
}

const bufferOptions = Array.from({ length: 8 }, (v, i) => {
  const seconds = (i + 1) * 15;
  return {
    label: `${calculateSecondsLabel(seconds)}`,
    value: seconds * 1000,
  };
});

interface OptionsType {
  label: string;
  value: number;
}

const timeSelectOptions = ({
  options,
  value,
}: {
  options: OptionsType[];
  value: any;
}) =>
  options.map((option) => {
    const selected = value === option;
    return (
      <MenuItem
        className={clsx("py-2", {
          "text-white bg-primary": selected,
        })}
        value={option.value}
        key={option.value}
      >
        <ListItemIcon
          className={clsx({
            invisible: !selected,
          })}
        >
          <CheckIcon className="text-white" />
        </ListItemIcon>
        <ListItemText primary={option.label} />
      </MenuItem>
    );
  });

function millisToMinutesAndSeconds(millis: number) {
  const seconds = ((millis % 60000) / 1000).toFixed(0);

  switch (true) {
    case millis < 60000:
      return `${seconds} seconds`;

    default:
      const minutes = Math.floor(millis / 60000);
      return `${minutes}:${Number(seconds) < 10 ? "0" : ""}${Number(
        seconds
      )} minutes`;
  }
}

export function EventFormCard({
  idx,
  mode,
  className,
  onDelete,
}: EventFormCardProps) {
  const path = `events.${idx}`;
  const editing = mode === FormMode.Edit;

  const { control, formState, resetField } = useFormContext<
    IntegrationCreateFormFields
  >();

  const { fields, append, remove } = useFieldArray({
    control,
    name: `events.${idx}.properties`,
  });

  const renderValue = ({
    value,
    options,
  }: {
    value: number;
    options: OptionsType[];
  }) =>
    options.find((o) => o.value === value)?.label ||
    millisToMinutesAndSeconds(value);

  return (
    <div
      className={clsx("flex flex-col bg-[#FBFBFB] max-w-[750px]", className)}
    >
      <div className="rounded-lg rounded-b-none border border-solid border-[#E0E0E0] bg-[#F4F4F4] border-b-[#D9D9D9] py-3 px-4 flex flex-col gap-2">
        <Typography className="text-base font-bold">Event Name</Typography>
        <div className="flex items-start justify-between gap-4">
          <Controller
            name={`${path}.name`}
            rules={{
              required: {
                value: true,
                message: "Please provide a name",
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                error={!!error}
                helperText={error?.message}
                className="grow"
                placeholder="Event Name"
                {...BASE_CONNECT_INPUT_PROPS}
                {...field}
              />
            )}
          />
          {editing && formState.isDirty && (
            <EditFormActions
              onCancel={() => {
                resetField(`events.${idx}.name`);
              }}
            />
          )}
          <Button
            onClick={onDelete}
            className="text-[#757575] font-normal mt-1"
          >
            <DeleteForever className="pr-1" />
            <span className="sr-only sm:not-sr-only">Delete Event</span>
          </Button>
        </div>
      </div>
      <div className="rounded-lg rounded-t-none border border-t-0 border-solid border-[#E0E0E0] p-4 flex flex-col gap-4">
        <Typography className="text-base font-bold">
          Event Clip Duration
        </Typography>
        <div className="flex flex-col sm:flex-row gap-y-10 mt-4 gap-4 w-full">
          <FormControl className="w-full">
            <InputLabel variant="outlined" className="-my-3 -mx-3" shrink>
              Default Event Clip Duration
            </InputLabel>
            <Controller
              name={`${path}.duration`}
              render={({ field }) => (
                <Select
                  variant="outlined"
                  input={
                    <OutlinedInput
                      notched={false}
                      className="bg-white h-[45px]"
                      label="string"
                    />
                  }
                  value={field.value}
                  renderValue={(data) =>
                    renderValue({
                      value: data,
                      options: durationOptions(),
                    })
                  }
                  onBlur={field.onBlur}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                  }}
                  className="rounded-lg"
                >
                  {timeSelectOptions({
                    value: field.value,
                    options: durationOptions(),
                  })}
                </Select>
              )}
            />
          </FormControl>
          <FormControl className="w-full">
            <InputLabel variant="outlined" className="-my-3 -mx-3" shrink>
              Default Buffer Time Before & After Event Trigger
            </InputLabel>
            <Controller
              name={`${path}.buffer`}
              render={({ field }) => (
                <Select
                  variant="outlined"
                  defaultValue={4}
                  input={
                    <OutlinedInput
                      notched={false}
                      className="bg-white h-[45px]"
                      label="string"
                    />
                  }
                  value={field.value}
                  renderValue={(data) =>
                    renderValue({
                      value: data,
                      options: bufferOptions,
                    })
                  }
                  onBlur={field.onBlur}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                  }}
                  className="rounded-lg"
                >
                  {timeSelectOptions({
                    value: field.value,
                    options: bufferOptions,
                  })}
                </Select>
              )}
            />
          </FormControl>
        </div>

        <Typography className="text-base font-bold">Event Schema</Typography>
        {fields.map((s, idx) => {
          return (
            <EventSchemaFormRow
              disabled={editing}
              key={s.id}
              path={`${path}.properties.${idx}`}
              onDelete={() => {
                remove(idx);
              }}
            />
          );
        })}

        {!editing && (
          <div>
            <SchemaActionButton
              onClick={() => {
                append({ ...DEFAULT_CONNECT_EVENT_ROW });
              }}
            >
              + Add Schema
            </SchemaActionButton>
          </div>
        )}
      </div>
    </div>
  );
}

export function CustomEventFormItem() {
  const { control } = useFormContext<IntegrationCreateFormFields>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "events",
  });

  return (
    <div>
      <div className="flex flex-col gap-6">
        {fields.map((f: { id: string }, idx) => (
          <EventFormCard
            idx={idx}
            key={f.id}
            onDelete={() => {
              remove(idx);
            }}
          />
        ))}
      </div>
      <div className="mt-6">
        <SchemaActionButton
          large
          onClick={() => {
            append({ ...DEFAULT_CONNECT_EVENT });
          }}
        >
          + Add New Event
        </SchemaActionButton>
      </div>
    </div>
  );
}

export function EditFormActions({ onCancel }: { onCancel: () => void }) {
  const { formState, trigger } = useFormContext();
  const { isSubmitting } = formState;

  return (
    <div className="flex items-center gap-2 mt-2">
      <Button
        size="small"
        variant="contained"
        className="shadow-none text-xs leading-[22px] font-normal bg-[#ddefff] text-[#007CE4] w-[70px]"
        disabled={isSubmitting}
        onClick={onCancel}
      >
        Cancel
      </Button>
      <Button
        size="small"
        type="submit"
        variant="contained"
        color="primary"
        className="shadow-none text-xs leading-[22px] font-normal"
        disabled={isSubmitting}
        onClick={() => {
          trigger();
        }}
      >
        Save
      </Button>
    </div>
  );
}
