import { IconProps } from "@/icons/IconProps";

export function LPRIcon({ style, className, onClick }: IconProps) {
  return (
    <svg
      style={style}
      className={className}
      onClick={onClick}
      width="28"
      height="28"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9396 6.08008H3.06055C2.88796 6.08008 2.74805 6.21999 2.74805 6.39258V13.6077C2.74805 13.7803 2.88796 13.9202 3.06055 13.9202H16.9396C17.1122 13.9202 17.2521 13.7803 17.2521 13.6077V6.39258C17.2521 6.21999 17.1122 6.08008 16.9396 6.08008ZM3.06055 5.14258C2.37019 5.14258 1.81055 5.70222 1.81055 6.39258V13.6077C1.81055 14.2981 2.37019 14.8577 3.06055 14.8577H16.9396C17.63 14.8577 18.1896 14.2981 18.1896 13.6077V6.39258C18.1896 5.70222 17.63 5.14258 16.9396 5.14258H3.06055Z"
        fill="currentColor"
      />
      <path
        d="M7.46751 11.2654V11.8647H5.59022V11.2654H7.46751ZM5.83865 8.13574V11.8647H5.07031V8.13574H5.83865Z"
        fill="currentColor"
      />
      <path
        d="M9.72734 10.5355H8.77717V9.9362H9.72734C9.87418 9.9362 9.99369 9.9123 10.0859 9.86449C10.1781 9.81498 10.2455 9.74668 10.2882 9.6596C10.3309 9.57253 10.3523 9.47435 10.3523 9.36508C10.3523 9.25409 10.3309 9.1508 10.2882 9.05518C10.2455 8.95957 10.1781 8.88273 10.0859 8.82468C9.99369 8.76663 9.87418 8.7376 9.72734 8.7376H9.04352V11.8647H8.27519V8.13574H9.72734C10.0193 8.13574 10.2694 8.18867 10.4777 8.29453C10.6878 8.39868 10.8483 8.54296 10.9592 8.72736C11.0702 8.91176 11.1257 9.12262 11.1257 9.35995C11.1257 9.6007 11.0702 9.809 10.9592 9.98487C10.8483 10.1607 10.6878 10.2965 10.4777 10.3921C10.2694 10.4877 10.0193 10.5355 9.72734 10.5355Z"
        fill="currentColor"
      />
      <path
        d="M12.023 8.13574H13.4137C13.6988 8.13574 13.9439 8.17843 14.1487 8.2638C14.3553 8.34917 14.5141 8.47552 14.6251 8.64284C14.7361 8.81017 14.7916 9.01591 14.7916 9.26007C14.7916 9.45984 14.7574 9.63143 14.6891 9.77485C14.6225 9.91657 14.5278 10.0352 14.4049 10.1308C14.2836 10.2248 14.1411 10.2999 13.9771 10.3562L13.7338 10.4843H12.525L12.5199 9.88498H13.4188C13.5537 9.88498 13.6655 9.86108 13.7543 9.81327C13.8431 9.76546 13.9097 9.69888 13.9541 9.6135C14.0002 9.52813 14.0232 9.4291 14.0232 9.31642C14.0232 9.1969 14.0011 9.0936 13.9567 9.00652C13.9123 8.91944 13.8448 8.85285 13.7543 8.80675C13.6638 8.76065 13.5503 8.7376 13.4137 8.7376H12.7914V11.8647H12.023V8.13574ZM14.1078 11.8647L13.2575 10.2026L14.0693 10.1974L14.9299 11.8289V11.8647H14.1078Z"
        fill="currentColor"
      />
    </svg>
  );
}
