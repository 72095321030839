import { DateRange, Edit, LocationOn, Videocam } from "@mui/icons-material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Button, Divider } from "@mui/material";
import clsx from "clsx";
import { utcToZonedTime } from "date-fns-tz";
import { format } from "date-fns/fp";
import { uniqBy, uniq } from "lodash/fp";
import { Fragment, ReactNode, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { formatTimeString, getShorthandDayRange } from "@/util/date";
import { filterNullish } from "@/util/filterFalsy";
import { useBreakpoints } from "@/util/useBreakpoints";

import { useSearchRangeParams } from "@/pages/Search/searchHooks";

import { FilterDateField } from "@/components/Filter/Fields/FilterDateField";
import { FilterBar } from "@/components/Filter/FilterBar";
import { FilterBarControls } from "@/components/Filter/FilterBarControls";
import { useTransparentFilterFieldStyles } from "@/components/Filter/constant";
import { useActiveFilter } from "@/components/Filter/utils";
import { InterestListDetailsButton } from "@/components/InterestList/InterestListDetailsButton";
import { QueryParamLink } from "@/components/shared/QueryParamLink";

import {
  IntelligenceDashboardQuery,
  InterestListType,
} from "@/generated-models";
import { usePrefixOrgSlug } from "@/hooks/useOrgRouteBase";

import { IntelligenceDashboardViewExportButton } from "../../Export/IntelligenceDashboardViewExportButton";
import { DEFAULT_TIME_RANGE, useLprReportGrouped } from "./hooks";

interface LicensePlateReportFilterBarProps {
  dashboard: IntelligenceDashboardQuery["intelligenceDashboard"];
}

function LicensePlateSettingInfoItem({
  Icon,
  value,
}: {
  Icon: ReactNode;
  value: string;
}) {
  return (
    <span className="text-[#757575] flex items-center gap-1">
      {Icon}
      <span>{value}</span>
    </span>
  );
}

function LicensePlateReportFilterBarSettings({
  className,
  dashboard,
}: LicensePlateReportFilterBarProps & { className?: string }) {
  const navigate = useNavigate();
  const { rangeStart, rangeEnd } = useSearchRangeParams(DEFAULT_TIME_RANGE);
  const { id, cameras, startTime, endTime, daysOfWeek } = dashboard!;
  const { fitsDesktop } = useBreakpoints();
  const { loading } = useLprReportGrouped();

  return (
    <div className={clsx("flex items-center justify-between", className)}>
      <div className="flex sm:flex-row flex-col items-start sm:items-center gap-2 sm:gap-5">
        <LicensePlateSettingInfoItem
          Icon={<LocationOn />}
          value={cameras.map((cam) => cam.location.name).join(",")}
        />
        <LicensePlateSettingInfoItem
          Icon={<Videocam />}
          value={cameras.map((cam) => cam.name).join(",")}
        />
        <LicensePlateSettingInfoItem
          Icon={<DateRange />}
          value={`${getShorthandDayRange(daysOfWeek)}, ${formatTimeString(
            startTime
          )} - ${formatTimeString(endTime)}`}
        />
        <div className="-ml-2 hidden sm:flex items-center gap-2">
          <Divider orientation="vertical" className="h-3" />
          <Button
            size="small"
            startIcon={<Edit />}
            color="primary"
            onClick={() => {
              navigate(`../edit/${id}`);
            }}
          >
            Edit
          </Button>
        </div>
      </div>
      {!fitsDesktop && (
        <div className="flex flex-col justify-between gap-3">
          <Button
            className="bg-white"
            variant="outlined"
            color="primary"
            onClick={() => {
              navigate(`../edit/${id}`);
            }}
          >
            Edit
          </Button>
          <IntelligenceDashboardViewExportButton
            variant="outlined"
            dashboard={dashboard!}
            disabled={loading}
            startDate={rangeStart}
            endDate={rangeEnd}
          />
        </div>
      )}
    </div>
  );
}

export function LicensePlateInterestListReportFilterBar({
  dashboard,
}: LicensePlateReportFilterBarProps) {
  const { classes } = useTransparentFilterFieldStyles();
  const { fitsDesktop } = useBreakpoints();
  const prefixOrgSlug = usePrefixOrgSlug();
  const { data: groupedReport, loading } = useLprReportGrouped();

  const lprCameras = useMemo(
    () =>
      (groupedReport?.lprReport || [])
        .flatMap((d) => d.cameras)
        .filter(filterNullish),
    [groupedReport]
  );

  const sortedLocations = useMemo(() => {
    return uniq(lprCameras.map((cam) => cam.location).flat()).sort((a, b) =>
      a.name.localeCompare(b.name)
    );
  }, [lprCameras]);

  const filters = useMemo(() => {
    const timezone = lprCameras[0]?.location?.timezone || "";
    return [
      {
        placeholder: "Dates & Times",
        filterName: "datetime",
        label: "Date & Time",
        classes,
        timezone,
        style: {},
        getValue: (value: string | null) => {
          return (
            value
              ?.split("|")
              .map((vi) =>
                format("MMM d, yyyy p")(utcToZonedTime(new Date(vi), timezone))
              )
              .join(" - ") || ""
          );
        },
        options: [],
        date: true,
      },
      {
        placeholder: "Locations & Groups",
        label: "Locations & Groups",
        classes,
        style: {},
        filterName: "locations",
        options: uniqBy(
          "value",
          sortedLocations?.map((d) => ({
            label: d.name,
            value: String(d.id),
          })) || []
        ),
      },
      {
        placeholder: "Cameras",
        filterName: "cameras",
        label: "Cameras",
        classes,
        style: {},
        options: uniqBy(
          "value",
          lprCameras?.map((d) => ({
            label: d.name,
            value: String(d.id),
            secondaryLabel: d.location.name,
          })) || []
        ),
      },
    ];
  }, [classes, lprCameras, sortedLocations]);

  const { hasActiveFilter } = useActiveFilter(filters);

  return (
    <div
      className={clsx(
        "flex flex-col justify-center-center pt-4 bg-[#F2F2F2] gap-3",
        {
          "pb-4": !hasActiveFilter || !fitsDesktop,
        }
      )}
    >
      <LicensePlateReportFilterBarSettings
        className="px-4 sm:px-6"
        dashboard={dashboard}
      />
      <div className="px-4 sm:px-6 flex flex-col sm:flex-row gap-2 items-center">
        {dashboard?.interestLists?.map((list, idx) => (
          <Fragment key={list.id}>
            <InterestListDetailsButton
              type={InterestListType.Lpr}
              list={list}
            />
            {dashboard.interestLists.length > idx + 1 && (
              <>
                <Divider
                  className="h-4 sm:block hidden"
                  orientation="vertical"
                />
                <Divider
                  className="w-full block sm:hidden"
                  orientation="horizontal"
                />
              </>
            )}
          </Fragment>
        ))}
        {dashboard?.alerts?.map((alert) => (
          <Fragment key={alert.id}>
            <>
              <Divider className="h-4 sm:block hidden" orientation="vertical" />
              <Divider
                className="w-full block sm:hidden"
                orientation="horizontal"
              />
            </>
            <Button
              component={QueryParamLink}
              to={prefixOrgSlug(`/alerts`)}
              params={{
                search: alert.name,
              }}
              startIcon={<NotificationsIcon />}
              color="primary"
              size="small"
            >
              <span className="font-bold">{alert.name} Alert</span>
            </Button>
          </Fragment>
        ))}
      </div>
      <Divider className="sm:block hidden mx-4 sm:mx-6" />
      <div className="sm:block hidden">
        <FilterBarControls
          className="pl-4 pr-6"
          filters={filters}
          components={{
            datetime: FilterDateField,
          }}
        />
        {!loading && <FilterBar filters={filters} />}
      </div>
    </div>
  );
}
