import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Typography } from "@mui/material";
import { useState } from "react";

import SchemaForm from "@/components/SchemaForm/SchemaForm";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";

import { useSetupIntegrationMutation } from "@/generated-models";

import { CFG_FAILED_MSG, CFG_SUCCESS_MSG } from "../constant";
import { INTEGRATION_SETUP_STATE_QUERY } from "../hooks";

interface IntegrationsVendorFormProps {
  id: number;
  schema?: any;
  setupState?: any;
  className?: string;
  onConnectionResult?: (success: boolean) => void;
  initialState?: any;
  callback?: () => void;
}

export function IntegrationsVendorForm({
  id,
  schema = {},
  setupState,
  className,
  onConnectionResult = () => {},
  initialState,
  callback,
}: IntegrationsVendorFormProps) {
  const [connected, setConnected] = useState(false);
  const [formData, setFormData] = useState({});
  const { pushSnackbar } = useFeedback();

  const [setupIntegration, { loading }] = useSetupIntegrationMutation({
    refetchQueries: [
      { query: INTEGRATION_SETUP_STATE_QUERY, variables: { input: { id } } },
      "integration",
    ],
    onCompleted: () => {
      pushSnackbar(CFG_SUCCESS_MSG, FeedbackType.Success);
      setConnected(true);
      onConnectionResult(true);
      if (callback) {
        callback();
      }
    },
    onError: (e) => {
      pushSnackbar(e.message || CFG_FAILED_MSG, FeedbackType.Error);
      setConnected(false);
      onConnectionResult(false);
    },
  });

  return (
    <div className={className}>
      <div className="max-w-xl">
        <SchemaForm
          id="integration-setup-form"
          formData={setupState || formData}
          schema={schema}
          showErrorList={false}
          uiSchema={{
            "ui:disabled": loading,

            "ui:submitButtonOptions": {
              props: {
                disabled: loading,
                className:
                  "shadow-none text-base leading-[26px] font-normal min-w-[112px] rounded-lg",
              },
              norender: false,
              submitText: loading ? "Validating..." : "Validate Connection",
            },
            devices: {
              "ui:label": false,
              items: {
                "ui:title": " ",
              },
            },
            email: {
              "ui:description": " ",
            },
            "msg set": { "ui:widget": "hidden" },
            "msg unset": { "ui:widget": "hidden" },
            password: {
              "ui:widget": "password",
              "ui:description": " ",
            },
            twoFactorOneTimePassword: {
              "ui:title": "2FA Code (optional)",
            },
          }}
          onSubmit={(event) => {
            const formData = event.formData;

            setupIntegration({
              variables: {
                input: {
                  integrationId: id,
                  setupState: formData,
                },
              },
            });

            setFormData({
              devices: formData,
            });
          }}
        />
      </div>
      {connected && (
        <Typography className="text-[#18D710] flex items-center justify-start gap-[6px] mt-[18px] text-lg leading-[22px]">
          <CheckCircleIcon />
          Connection Successful
        </Typography>
      )}
    </div>
  );
}
