import * as MapboxGeocoding from "@mapbox/mapbox-sdk/services/geocoding";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import React, { useRef, useEffect, useState } from "react";

export const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1IjoiYWtzaGF0Z29lbCIsImEiOiJja3E5b2gyYmswNm1jMnBzMXA3YjFnMmFxIn0.4cmbbAbd4bGHGicB-8oVvg";
const geoEncodingService = MapboxGeocoding.default({
  accessToken: MAPBOX_ACCESS_TOKEN,
});
mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;
export interface MapProps {
  address: string;
  setCoordinates: React.Dispatch<{ x: number; y: number }>;
}

export function MapboxMap({ address, setCoordinates }: MapProps) {
  const mapContainerRef = useRef(null as null | HTMLDivElement);
  const [map, setMap] = useState<mapboxgl.Map>();

  useEffect(() => {
    if (!map || !address) return;
    geoEncodingService
      .forwardGeocode({
        query: address,
        autocomplete: false,
        limit: 1,
        mode: "mapbox.places",
      })
      .send()
      .then((response) => {
        if (
          response &&
          response.body &&
          response.body.features &&
          response.body.features.length
        ) {
          const feature = response.body.features[0];
          setCoordinates({
            x: feature.center[0],
            y: feature.center[1],
          });
          map.setCenter(feature.center);
          new mapboxgl.Marker().setLngLat(feature.center).addTo(map);
          map.setZoom(15);
        }
      });
  }, [address, map, setCoordinates]);

  // Initialize map when component mounts
  useEffect(() => {
    if (!mapContainerRef.current) return;
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v11",
    });
    setMap(map);
    return () => map.remove();
  }, [mapContainerRef]);

  return (
    <>
      <div
        //className="map-container"
        ref={mapContainerRef}
        style={{
          height: address ? "200px" : 0,
          width: "100%",
        }}
      />
    </>
  );
}

export default MapboxMap;
