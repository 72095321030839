export interface BlockLevel {
  /** The width in pixels of a rendered block */
  blockWidth: number;
  macroBlockSize?: number;
  macroBlockLabelFormat?: string;
  /** The number of seconds within a block */
  blockSize: number;
  markerSize: number;
  smallMarkerSize: number;
  blockLabelFormat: string;
}

export const levels: BlockLevel[] = [
  // Level 0
  {
    blockWidth: 200,
    macroBlockSize: 60 * 60 * 24, // 1 day
    macroBlockLabelFormat: "MMMM d",
    blockSize: 60, // 1 min
    markerSize: 30,
    smallMarkerSize: 10,
    blockLabelFormat: "p",
  },
  // Level 1
  {
    blockWidth: 200,
    macroBlockSize: 60 * 60 * 24, // 1 day
    macroBlockLabelFormat: "MMMM d",
    blockSize: 2 * 60, // 2 min
    markerSize: 60,
    smallMarkerSize: 20,
    blockLabelFormat: "p",
  },
  // Level 2
  {
    blockWidth: 200,
    macroBlockSize: 60 * 60 * 24, // 1 day
    macroBlockLabelFormat: "MMMM d",
    blockSize: 5 * 60, // 5 min
    markerSize: 0,
    smallMarkerSize: 60,
    blockLabelFormat: "p",
  },
  // Level 3
  {
    blockWidth: 200,
    macroBlockSize: 60 * 60 * 24, // 1 day
    macroBlockLabelFormat: "MMMM d",
    blockSize: 10 * 60, // 10 min
    markerSize: 5 * 60,
    smallMarkerSize: 60,
    blockLabelFormat: "p",
  },
  // Level 4
  {
    blockWidth: 200,
    macroBlockSize: 60 * 60 * 24, // 1 day
    macroBlockLabelFormat: "MMMM d",
    blockSize: 20 * 60, // 20 min
    markerSize: 10 * 60,
    smallMarkerSize: 2 * 60,
    blockLabelFormat: "p",
  },
  // Level 5
  {
    blockWidth: 200,
    macroBlockSize: 60 * 60 * 24, // 1 day
    macroBlockLabelFormat: "MMMM d",
    blockSize: 30 * 60, // 30 min
    markerSize: 10 * 60,
    smallMarkerSize: 2 * 60,
    blockLabelFormat: "p",
  },
  // Level 6
  {
    blockWidth: 200,
    macroBlockSize: 60 * 60 * 24, // 1 day
    macroBlockLabelFormat: "MMMM d",
    blockSize: 60 * 60, // 1 hour
    markerSize: 30 * 60,
    smallMarkerSize: 10 * 60,
    blockLabelFormat: "p",
  },
  // Level 7
  {
    blockWidth: 200,
    macroBlockSize: 60 * 60 * 24, // 1 day
    macroBlockLabelFormat: "MMMM d",
    blockSize: 60 * 60 * 2, // 2 hrs
    markerSize: 60 * 60,
    smallMarkerSize: 10 * 60,
    blockLabelFormat: "p",
  },
  // Level 8
  {
    blockWidth: 200,
    macroBlockSize: 60 * 60 * 24, // 1 day
    macroBlockLabelFormat: "MMMM d",
    blockSize: 60 * 60 * 3, // 3 hrs
    markerSize: 60 * 60,
    smallMarkerSize: 15 * 60,
    blockLabelFormat: "p",
  },
  // Level 9
  {
    blockWidth: 200,
    macroBlockSize: 60 * 60 * 24, // 1 day
    macroBlockLabelFormat: "MMMM d",
    blockSize: 60 * 60 * 6, // 6 hrs
    markerSize: 60 * 60 * 3,
    smallMarkerSize: 60 * 60,
    blockLabelFormat: "p",
  },
  // Level 10
  {
    blockWidth: 200,
    macroBlockSize: 60 * 60 * 24, // 1 day
    macroBlockLabelFormat: "MMMM d",
    blockSize: 60 * 60 * 12, // 12 hrs
    markerSize: 60 * 60 * 6,
    smallMarkerSize: 60 * 60 * 1.5,
    blockLabelFormat: "p",
  },
  // Level 11
  {
    blockWidth: 200,
    // macroBlockSize: 60 * 60 * 24, // 1 day
    // macroBlockLabelFormat: 'MMMM',
    blockSize: 60 * 60 * 24, // 1 day
    markerSize: 60 * 60 * 12,
    smallMarkerSize: 60 * 60 * 3,
    blockLabelFormat: "MMM d",
  },
  // Level 12
  {
    blockWidth: 200,
    // macroBlockSize: 60 * 60 * 24, // 1 day
    // macroBlockLabelFormat: 'MMMM',
    blockSize: 60 * 60 * 24 * 2, // 2 days
    markerSize: 60 * 60 * 24,
    smallMarkerSize: 60 * 60 * 6,
    blockLabelFormat: "MMM d",
  },
  // Level 13
  {
    blockWidth: 200,
    // macroBlockSize: 60 * 60 * 24, // 1 day
    // macroBlockLabelFormat: 'MMMM',
    blockSize: 60 * 60 * 24 * 4, // 4 days
    markerSize: 60 * 60 * 24 * 2,
    smallMarkerSize: 60 * 60 * 12,
    blockLabelFormat: "MMM d",
  },
  // Level 14
  {
    blockWidth: 200,
    // macroBlockSize: 60 * 60 * 24, // 1 day
    // macroBlockLabelFormat: 'MMMM',
    blockSize: 60 * 60 * 24 * 8, // 8 days
    markerSize: 60 * 60 * 24 * 4,
    smallMarkerSize: 60 * 60 * 24,
    blockLabelFormat: "MMM d",
  },
];
