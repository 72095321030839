import { Button, Container, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

import { useDocumentTitle } from "@/util/useDocumentTitle";

import { useSettingsRoutes } from "@/pages/Settings/SettingsRoutes";

import { ContentWrapper } from "@/layout/ContentWrapper";

export function WelcomePage() {
  useDocumentTitle("Welcome");
  const settingsRoutes = useSettingsRoutes();

  return (
    <ContentWrapper>
      <Container
        maxWidth="sm"
        className="flex flex-col justify-center text-lg text-center"
      >
        <img src="/paw.svg" alt="Spot AI" className="h-36" />
        <div className="my-2" />
        <Typography variant="h1" className="text-5xl font-light text-primary">
          Welcome to Spot
        </Typography>
        <div className="my-2" />
        <Typography>
          <span style={{ fontWeight: "bold" }}>Let’s get you set up.</span> In
          order to use Spot, you first have to create a location, add and
          connect a spot appliance, then add cameras and NVRs. That’s it.
        </Typography>
        <div className="my-2" />
        <Typography>
          If you would rather have someone else set this up, you can invite them
          to set up Spot for you.
        </Typography>
        <div className="my-4" />
        <div>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={settingsRoutes.users}
            className="w-40 mr-2"
          >
            Invite User
          </Button>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={`${settingsRoutes.locations}/create`}
          >
            Setup a New Location
          </Button>
        </div>
      </Container>
    </ContentWrapper>
  );
}
