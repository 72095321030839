import { Button, CircularProgress, Typography } from "@mui/material";
import clsx from "clsx";
import { useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { BooleanParam, useQueryParam } from "use-query-params";

import { ReactComponent as IntegrationsLogo } from "@/icons/integrations.svg";

import { useBreakpoints } from "@/util/useBreakpoints";

import { ErrorMessage } from "@/components/ErrorMessage";

import {
  IntegrationVendor,
  IntegrationVendorKey,
  useIntegrationVendorsQuery,
} from "@/generated-models";
import { usePrefixOrgSlug } from "@/hooks/useOrgRouteBase";
import { MobileHeader } from "@/layout/MobileHeader";

import IntegrationsCard from "../Core/IntegrationsCard";
import IntegrationsDeleteButton from "../Core/IntegrationsDeleteButton";
import { useCurrentIntegrationId, useCurrentTypeId } from "../hooks";
import { getLabels } from "../labels";
import IntegrationsCreateForm from "./IntegrationsCreateForm";

interface IntegrationsCreatePagePromptProps {
  vendor: IntegrationVendor;
}

function useVendorMetadata() {
  const { vendorKey } = useParams<{
    vendorKey: IntegrationVendorKey;
  }>();
  const { data, loading, error } = useIntegrationVendorsQuery();

  const vendorMetadata = useMemo(
    () =>
      data?.integrationVendors?.find(
        (vendor) => vendor.key?.toLowerCase() === vendorKey?.toLowerCase()
      ),
    [data?.integrationVendors, vendorKey]
  );

  return { data: vendorMetadata, loading, error };
}

function IntegrationsCreatePageHeader() {
  const id = useCurrentIntegrationId();
  const type = useCurrentTypeId();

  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center justify-start gap-2 -ml-2">
        <IntegrationsLogo className="h-5" />
        <Typography className="text-2xl leading-[28px] font-bold">
          {getLabels(type).createLabel}
        </Typography>
      </div>
      {id ? (
        <IntegrationsDeleteButton id={id} />
      ) : (
        <Button
          className="leading-[16px] text-[#757575]"
          component={Link}
          to=".."
        >
          Cancel
        </Button>
      )}
    </div>
  );
}

function IntegrationsCreatePagePrompt({
  vendor,
}: IntegrationsCreatePagePromptProps) {
  const { name, key, logoSrc, instructions } = vendor;

  const isIOBoard = key === IntegrationVendorKey.Spot;
  const isIntercom = key === IntegrationVendorKey.SpotIntercom;
  return (
    <div className="flex md:flex-row flex-col items-start justify-between md:gap-0 gap-6">
      <div className="flex justify-start items-center gap-7">
        {isIOBoard && (
          <img
            className="max-h-[92px]"
            alt={name}
            src="/integrations/io-board-setup.svg"
          />
        )}
        <div className="flex flex-col gap-2">
          <Typography className="text-lg leading-[21px] font-bold">
            Welcome.
          </Typography>
          <Typography className="text-base leading-[19px] max-w-lg">
            {instructions}
          </Typography>
        </div>
      </div>
      {!isIOBoard && !isIntercom && (
        <img className="mt-1 max-h-10" alt={name} src={logoSrc} />
      )}
    </div>
  );
}

export function IntegrationsCreatePage() {
  const { data, loading, error } = useVendorMetadata();
  const { fitsDesktop } = useBreakpoints();
  const prefixOrgSlug = usePrefixOrgSlug();
  const type = useCurrentTypeId();
  const [discoveredDevices, setDiscoveredDevices] = useQueryParam(
    "discovered-devices",
    BooleanParam
  );

  if (error) {
    return <ErrorMessage title="Error" description={error.toString()} />;
  }

  if (!loading && !data) {
    return (
      <ErrorMessage
        title="Error"
        description="Could not find integration vendor metadata. If this issue persists, please contact support."
      />
    );
  }

  return (
    <>
      <MobileHeader
        to={prefixOrgSlug("/integrations")}
        onBack={
          discoveredDevices
            ? () => {
                setDiscoveredDevices(false);
              }
            : null
        }
        label={
          discoveredDevices ? "Discovered Devices" : getLabels(type).createLabel
        }
      />
      <IntegrationsCard
        className={clsx({
          "-my-8": !fitsDesktop,
        })}
      >
        {fitsDesktop && <IntegrationsCreatePageHeader />}
        <div className="-mx-8">
          {fitsDesktop && (
            <div
              style={{
                background:
                  "linear-gradient(180deg, rgba(0, 0, 0, 0.373989) 0%, rgba(255, 255, 255, 0.0001) 98.13%)",
              }}
              className="opacity-40 w-full h-2 mt-6"
            />
          )}
          {loading && (
            <div className="flex items-center justify-center w-full h-full py-12">
              <CircularProgress size={30} />
            </div>
          )}
          {!loading && data && (
            <div className="p-5 md:p-8">
              {!discoveredDevices && (
                <IntegrationsCreatePagePrompt vendor={data} />
              )}
              <IntegrationsCreateForm vendor={data} />
            </div>
          )}
        </div>
      </IntegrationsCard>
    </>
  );
}
