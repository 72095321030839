import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Checkbox,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { atom, useAtom } from "jotai";
import { filter } from "lodash";
import { useEffect } from "react";
import { ArrayParam, useQueryParam } from "use-query-params";

import { FilterFieldOption } from "../../../components/Filter/constant";

interface FilterAccordionProps {
  placeholder: string;
  filterName: string;
  label: string;
  options: FilterFieldOption[];
  expandedFilter: string | null;
  setExpandedFilter: (expandedFilter: string | null) => void;
}

export const auditLogActionsAtom = atom<(string | null)[] | null>([]);

export const FilterAccordion = ({
  placeholder,
  options,
  filterName,
  expandedFilter,
  setExpandedFilter,
}: FilterAccordionProps) => {
  const [filterVal, setFilterVal] = useQueryParam(filterName, ArrayParam);
  const filteredParsed = filterVal ?? [];
  const [, setAuditLogActions] = useAtom(auditLogActionsAtom);

  useEffect(() => {
    if (filterVal) {
      setAuditLogActions(filterVal);
    }
  }, [filterVal, setAuditLogActions]);

  return (
    <Accordion
      elevation={0}
      disableGutters
      expanded={expandedFilter === filterName}
      classes={{
        expanded: "min-h-auto",
        root: "px-0 bg-[#F4FAFF]",
      }}
      onChange={() => {
        setExpandedFilter(expandedFilter === filterName ? null : filterName);
      }}
      sx={{
        "&:before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ArrowDropDownIcon className="text-[#353D48]" />}
        classes={{
          root: "bg-[#D1EBFF] rounded-lg",
          content: "leading-6 font-bold !border-0 my-1.5",
          expandIconWrapper: "text-medium text-lg !border-0",
        }}
      >
        <Typography className="text-base font-bold text-primary capitalize">
          {placeholder}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className="border border-[#DBEFFF] rounded-lg bg-white mt-1">
        <div className=" w-full flex flex-col">
          {options
            .filter((metric) => metric.label !== "")
            .map((metric) => (
              <MenuItem
                key={`${metric.label}-${metric.secondaryLabel}-${metric.value}`}
                value={`${metric.value}`}
                classes={{
                  root: "whitespace-normal",
                }}
                onClick={() => {
                  if (filteredParsed.includes(`${metric.value}`)) {
                    setFilterVal((val) =>
                      filter(val, (v) => v !== `${metric.value}`)
                    );
                  } else {
                    setFilterVal((val) => [...(val ?? []), `${metric.value}`]);
                  }
                }}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={filteredParsed.includes(`${metric.value}`)}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText
                  className="capitalize"
                  data-cy={`filter-dd-item-${filterName}`}
                  primary={metric.label}
                  secondary={metric.secondaryLabel}
                />
              </MenuItem>
            ))}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
