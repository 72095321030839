import { Container } from "@mui/material";
import clsx from "clsx";
import { useRef } from "react";
import { Navigate } from "react-router-dom";

import { useGlobalFullscreenState } from "@/util/fullscreen";
import { useBreakpoints } from "@/util/useBreakpoints";

import { Live } from "@/pages/Live/Live";
import { LiveMobile, useLiveMobile } from "@/pages/Live/LiveMobile";

import { ErrorMessage } from "@/components/ErrorMessage";
import { ViewDrawer } from "@/components/SpotDrawer";
import { WiredMobileCameraDrawer } from "@/components/View/MobileCameraDrawer";

import { refetchOnMountPolicy } from "@/apolloClient";
import { useGroupsQuery } from "@/generated-models";
import { usePrefixOrgSlug } from "@/hooks/useOrgRouteBase";
import { usePermissions } from "@/hooks/usePermissions";
import { useCustomScrollbarStyles } from "@/layout/theme";

import { PlayerIdsProvider } from "../Player/PlayerBase";
import { CaseInvestigationBanner } from "./CaseInvestigationBanner";
import { useActiveCamIds } from "./sharedViewHooks";

export function ViewBase() {
  const hasPermission = usePermissions();
  const prefixOrgSlug = usePrefixOrgSlug();
  const { fitsSmallTablet } = useBreakpoints();
  const { classes: scrollbarClasses } = useCustomScrollbarStyles();
  const liveMobile = useLiveMobile();
  const [isFullscreen] = useGlobalFullscreenState("isFullscreen");
  const activeCamIds = useActiveCamIds();
  const { data: groupsData } = useGroupsQuery(refetchOnMountPolicy);
  const mainContainerRef = useRef<HTMLDivElement>(null);

  if (liveMobile) {
    return <LiveMobile />;
  }

  if (groupsData?.groups.length === 0) {
    if (hasPermission("devices_manage")) {
      return <Navigate to={prefixOrgSlug("/welcome")} replace />;
    }
    return (
      <ErrorMessage
        title="No access"
        description="Please contact your admin, as your admin hasn't assigned any camera locations to you"
      />
    );
  }

  return (
    <PlayerIdsProvider cameraIds={activeCamIds}>
      <CaseInvestigationBanner />
      <div
        className={clsx(
          "flex",
          fitsSmallTablet ? "h-[calc(100%-70px)]" : "h-full", // Correct for desktop header
          { relative: !fitsSmallTablet }
        )}
      >
        <div
          className={clsx(
            "w-full overflow-y-auto overflow-x-hidden",
            scrollbarClasses.scrollbarContainer
          )}
          ref={mainContainerRef}
        >
          <Container maxWidth="lg" className="p-0 md:px-6 relative">
            <Live activeCamIds={activeCamIds} />
          </Container>
        </div>
        {fitsSmallTablet && !isFullscreen && (
          <ViewDrawer activeCamIds={activeCamIds} />
        )}
        {!fitsSmallTablet && <WiredMobileCameraDrawer />}
      </div>
    </PlayerIdsProvider>
  );
}
