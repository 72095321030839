import { isEmpty, values } from "lodash/fp";
import {
  ArrayParam,
  DelimitedNumericArrayParam,
  StringParam,
  useQueryParams,
} from "use-query-params";

export const isNotDeleted = (r: { isDeleted?: boolean }) => !r.isDeleted;

export function useFilterQueryParams() {
  return useQueryParams({
    integrationEventTypeIds: DelimitedNumericArrayParam,
    integrationDeviceIds: DelimitedNumericArrayParam,
    cameraIds: ArrayParam,
    datetime: StringParam,
    search: StringParam,
  });
}

export function useHasFilters() {
  const [params] = useFilterQueryParams();
  const { search, ...filters } = params;
  return !values(filters).every(isEmpty);
}
