import { Button, Typography } from "@mui/material";
import clsx from "clsx";
import { useEffect, useState } from "react";
import Draggable from "react-draggable";

import DrawZone from "@/components/Zones/DrawZone";

import { useEditAnnotationLabel } from "../copilotAnnotationQueryHooks";
import { useCopilotEnabled } from "../copilotQueryHooks";
import { CopilotMode, useCopilotContext } from "../useCopilotContext";
import { CreateLabelForm } from "./CopilotLabelButton/Forms/CreateLabelForm";

export const OBJECT_UPSERT_MODES = [
  CopilotMode.addobject,
  CopilotMode.editobjectboundary,
];

export function CopilotUpsertObjectOverlay() {
  const { copilotEnabled } = useCopilotEnabled();
  const { edit } = useEditAnnotationLabel();
  const {
    editingLabelId,
    setEditingLabelId,
    editingShape,
    setEditingShape,
    mode,
    setMode,
  } = useCopilotContext();
  const [editing, setEditing] = useState(true);
  const [creating, setCreating] = useState(false);
  const visible = OBJECT_UPSERT_MODES.includes(mode) && copilotEnabled;

  const disabled = editing;

  useEffect(() => {
    if (mode === CopilotMode.default) {
      setCreating(false);
      setEditing(true);
      setEditingShape([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  if (visible) {
    return (
      <>
        {creating && <CreateLabelForm />}
        <DrawZone
          shape={editingShape}
          setShape={setEditingShape}
          editing={editing}
          setEditing={setEditing}
        />
        <div className="absolute w-full h-full flex items-end justify-center">
          <Draggable bounds="parent">
            <div className="bg-[#151515] rounded-lg p-1 flex items-center h-[38px] max-w-[600px] mb-[10px] gap-1 z-10 cursor-move">
              <div className="w-[3px] h-[26px] rounded-[19px] opacity-30 bg-[#D9D9D9]" />
              <Typography className="text-white font-normal text-[13px] leading-[15.6px] text-center px-[10px]">
                Click to draw a boundary around the object you want to add.
              </Typography>
              <Button
                variant="outlined"
                className="h-[30px] w-[60px] rounded border-[#282828] text-[#575757] font-normal"
                onClick={() => {
                  setEditingShape([]);
                  setEditingLabelId(null);
                  setMode(CopilotMode.default);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                className="h-[30px] w-[60px] rounded border-[#282828] text-[#878787] bg-[#343434] font-normal"
                onClick={() => {
                  setEditingShape([]);
                }}
              >
                Clear
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={clsx(
                  "h-[30px] w-[60px] rounded font-normal bg-[#535353]",
                  {
                    "text-white/50": editing,
                  }
                )}
                disabled={disabled}
                onClick={() => {
                  if (mode === CopilotMode.editobjectboundary) {
                    edit(editingLabelId!, { shape: editingShape });
                    setEditingShape([]);
                    setMode(CopilotMode.default);
                  } else {
                    setCreating(true);
                  }
                }}
                style={{
                  background: disabled
                    ? undefined
                    : "linear-gradient(135deg, #A900E4 -3.16%, #007CE4 117.76%)",
                }}
              >
                Save
              </Button>
            </div>
          </Draggable>
        </div>
      </>
    );
  }

  return <></>;
}
