import { Box, StyledEngineProvider, ThemeProvider } from "@mui/material";
import { useKeyboardEvent } from "@react-hookz/web";
import clsx from "clsx";
import { useState } from "react";
import { CSSInterpolation, GlobalStyles } from "tss-react";
import { makeStyles } from "tss-react/mui";

import { Spotcast } from "@/icons/Spotcast";

import { filterNullish } from "@/util/filterFalsy";
import { useDocumentTitle } from "@/util/useDocumentTitle";

import KioskInfo from "@/pages/VideoWall/Kiosk/KioskInfo";
import KioskNoWallWelcome from "@/pages/VideoWall/Kiosk/KioskNoWallWelcome";

import { VideoWallCamTile } from "@/components/VideoWall/VideoWallCamTile";
import VideoWallGrid from "@/components/VideoWall/VideoWallGrid";
import { VideoWallRotationProgress } from "@/components/VideoWall/VideoWallRotationProgress";
import { GridContainer } from "@/components/shared/Grid";

import { useKioskInfoQuery } from "@/generated-models";
import { useCustomScrollbarStyles } from "@/layout/theme";

import { forcedQualityByCameraCount } from "../constants";
import { useVideoWallRotation } from "../useVideoWallRotation";
import { KioskError } from "./KioskError";

const useStyles = makeStyles()((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    display: "grid",
    boxSizing: "border-box",
    // height: "100%",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "1fr auto",
    [theme.breakpoints.down("md")]: {
      paddingLeft: 8,
      paddingRight: 8,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  topBar: {
    maxHeight: 75,
    backgroundColor: theme.palette.background.default,
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",

    "& :not(:first-child):not(:last-child)": {
      justifySelf: "center",
    },
    alignItems: "center",
    gap: "4px",

    [theme.breakpoints.up("sm")]: {
      padding: 16,
    },

    padding: 8,
  },
  wallGrid: {
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "4px",
  },
}));

function KisokNetworkSettings() {
  return (
    <iframe
      title="Network Settings"
      src="https://127.0.0.1/www"
      style={{
        height: "90vh",
        width: "90vw",
      }}
    />
  );
}

export default function Kiosk() {
  useDocumentTitle("Kiosk Video Wall");
  const [showNetworkSettings, setShowNetworkSettings] = useState(false);
  const { classes, theme } = useStyles();
  const { data, error } = useKioskInfoQuery();
  const kioskInfo = data?.kioskInfo;
  const { classes: scrollbarClasses } = useCustomScrollbarStyles();
  const { page, preloadingPage } = useVideoWallRotation(
    (kioskInfo?.videoWall?.__typename === "RotatingVideoWall"
      ? kioskInfo.videoWall.videoWalls.length
      : 0) ?? 0,
    (kioskInfo?.videoWall as any)?.pageDuration ?? 90,
    kioskInfo?.config.webRtcEnabled ?? false
  );

  useKeyboardEvent(
    true,
    (ev) => {
      if (ev.key === "Escape") {
        setShowNetworkSettings((value) => !value);
      }
    },
    [],
    { eventOptions: { passive: true } }
  );

  if (error) {
    return (
      <KioskError description="Could not get Kiosk info, or Kiosk is not authenticated" />
    );
  }
  if (!kioskInfo) return null; // loading

  const { videoWall: wall } = kioskInfo;

  if (!wall) {
    // show directions on how to cast things to walls
    return <KioskNoWallWelcome />;
  }

  if (showNetworkSettings) {
    return <KisokNetworkSettings />;
  }

  const wallPage =
    wall.__typename === "VideoWall"
      ? wall
      : wall.videoWalls[Math.min(page, wall.videoWalls.length - 1)];

  if (!wallPage) {
    // This shouldn't happen, but it seems like it does (https://spotaico.atlassian.net/browse/SEE-738)
    // There might be a off-by-one error, but I can't find it in `useVideoWallRotation`. There's even
    // an extra fail-safe with `Math.min(page, wall.videoWalls.length - 1)`.
    return (
      <KioskError description="Something went wrong, please try to refresh the page." />
    );
  }

  const preloadingWallPage =
    wall.__typename === "RotatingVideoWall" && preloadingPage != null
      ? wall.videoWalls[Math.min(preloadingPage, wall.videoWalls.length - 1)]
      : null;

  const cameraCount = wallPage.cameras.filter(filterNullish).length;
  const maxAutoLevel = forcedQualityByCameraCount(cameraCount);

  return (
    <Box
      className={clsx(classes.container, scrollbarClasses.scrollbarContainer)}
      position="relative"
    >
      <GlobalStyles
        styles={{
          "html, body, #root": {
            height: "100%",
            background: "#000",
          },
          header: ({
            [theme.breakpoints.up("sm")]: {
              position: "sticky !important", // TODO: Extremely hacky, need to have a better solution for spacing with the drawer than just setting header static
              top: 0,
            },
          } as unknown) as CSSInterpolation,
        }}
      />
      {preloadingWallPage && (
        <VideoWallGrid
          config={preloadingWallPage.config as any} // Could not cast this to VideoWallConfig, so casting as any to silence warning
          fullscreen={true}
          autoLayout={false}
          filterableCamIndexes={preloadingWallPage.cameras
            .map((cam, index) =>
              Boolean(cam && cam.__typename !== "NotAllowed") ? null : index
            )
            .filter(filterNullish)}
          preload
          key={preloadingWallPage.id}
        >
          {preloadingWallPage.cameras.map((cam, i) => (
            <VideoWallCamTile
              cam={cam}
              key={`${cam?.id}-${i}`}
              maxAutoLevel="360p" // Preload with lowest quality
              kiosk={true}
              isWebRtc={kioskInfo.config.webRtcEnabled}
            />
          ))}
        </VideoWallGrid>
      )}
      <VideoWallGrid
        key={wallPage.id}
        config={wallPage.config as any} // Could not cast this to VideoWallConfig, so casting as any to silence warning
        fullscreen={true}
        autoLayout={cameraCount === 1} // Use auto layout when wall only has a single camera
        filterableCamIndexes={wallPage.cameras
          .map((cam, index) =>
            Boolean(cam && cam.__typename !== "NotAllowed") ? null : index
          )
          .filter(filterNullish)}
      >
        {wallPage.cameras.map((cam, i) => (
          <VideoWallCamTile
            cam={cam}
            key={`${cam?.id}-${i}`}
            maxAutoLevel={maxAutoLevel}
            kiosk={true}
            isWebRtc={kioskInfo.config.webRtcEnabled}
          />
        ))}
      </VideoWallGrid>

      {wall.__typename === "RotatingVideoWall" && (
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <VideoWallRotationProgress
              page={page}
              pageDuration={wall.pageDuration}
            />
          </ThemeProvider>
        </StyledEngineProvider>
      )}
      <GridContainer className={classes.topBar}>
        <KioskInfo
          pageName={
            wall.__typename === "RotatingVideoWall" ? wallPage.name : undefined
          }
        />
        <Spotcast
          style={{ height: "100%", width: "100%", objectFit: "contain" }}
        />
        <Box
          display="flex"
          justifyContent="flex-end"
          width="100%"
          height="100%"
        >
          {/* <WallclockTimer
            timezone={Intl.DateTimeFormat().resolvedOptions().timeZone}
          /> */}
        </Box>
      </GridContainer>
    </Box>
  );
}
