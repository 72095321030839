import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { IconButton, Popover, Typography } from "@mui/material";
import clsx from "clsx";
import { ReactNode } from "react";

interface CameraProxyPopoverProps {
  type: string;
  open: boolean;
  onClose: () => void;
  anchorEl?: null | Element | ((element: Element) => Element);
  offline?: boolean;
  children?: ReactNode;
}

export default function MaintainDeviceStatusPopover({
  type,
  open,
  onClose,
  anchorEl,
  offline,
  children,
}: CameraProxyPopoverProps) {
  const title = !offline ? "Out of Spec" : "Unreachable";
  const warning = !offline;

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onClose={onClose}
      classes={{ paper: "flex flex-col rounded w-[400px]" }}
    >
      <div className="flex flex-col gap-y-3">
        <div className="w-full box-border flex items-center justify-between bg-[#f4f4f4] p-4">
          {warning && (
            <ReportProblemOutlinedIcon
              className={clsx("mr-2 text-[#FF9800]")}
            />
          )}
          {!warning && <InfoIcon className={clsx("mr-2 text-[#cc0000]")} />}
          <Typography variant="h4" className="m-0 font-medium">
            {`${type} ${title}`}
          </Typography>
          <div className="grow" />
          <IconButton size="small" onClick={onClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
        <div className="px-4 pb-4">{children}</div>
      </div>
    </Popover>
  );
}
