import { Autocomplete, createFilterOptions, TextField } from "@mui/material";
import gql from "graphql-tag";
import { orderBy } from "lodash/fp";
import { Dispatch, useState } from "react";

import { filterNullish } from "@/util/filterFalsy";

import { Chip } from "@/components/Chip";

import {
  UsersForRoleMembershipQuery,
  useUsersForCaseQuery,
} from "@/generated-models";

// import { CaseCollaborator, CaseCollaboratorChip } from "./CaseCollaboratorChip";

gql`
  query usersForRoleMembership {
    users {
      id
      orgUserId
      name
      email
      rolev2 {
        id
        name
      }
    }
  }
`;

type MemberOption = Omit<
  UsersForRoleMembershipQuery["users"][number],
  "__typename"
>;

export function RoleMemberSelect({
  setMemberIds,
  initialMemberIds,
  label,
  filterOptions = () => true,
}: {
  filterOptions?: (orgUserId: number) => boolean;
  setMemberIds: Dispatch<number[]>;
  initialMemberIds: number[];
  label?: string;
}) {
  // const me = useMe();
  const { data } = useUsersForCaseQuery();

  const memberIdSet = new Set(initialMemberIds);
  const options = (
    orderBy(
      (m) => m.name.toLowerCase(),
      "asc",
      // Filter out selected users from the options
      data?.users.filter((option) => !memberIdSet.has(option.orgUserId))
    ) ?? []
  ).filter((m) => filterOptions(m.orgUserId));

  const [memberIds, setValue] = useState([] as number[]);
  const value = memberIds
    .map((memberId) => data?.users.find((o) => o.orgUserId === memberId))
    .sort((a, b) => (a && b ? a.name.localeCompare(b.name) : 0))
    .filter(filterNullish);

  return (
    <Autocomplete
      multiple
      id="tags-outlined"
      options={options}
      fullWidth
      getOptionLabel={(option) => option.name}
      filterOptions={filterMemberOptions}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.name}
          <span className="inline-block ml-2 text-xs opacity-50">
            {option.email}
          </span>
        </li>
      )}
      renderTags={(values, getTagProps) => {
        return values.map((value, index) => {
          const props = getTagProps({ index });

          return (
            <Chip
              {...props}
              label={value.name}
              className="m-0"
              classes={{
                deleteIcon: "!mr-1 ml-0.5",
              }}
            />
          );
        });
      }}
      onChange={(_, value) => {
        const newValue = value.map((v) => v.orgUserId);
        setValue(newValue);
        setMemberIds(initialMemberIds.concat(newValue));
      }}
      classes={{
        inputRoot: "gap-2",
      }}
      disableClearable
      value={value}
      filterSelectedOptions
      // onChange={(
      //   // event: React.SyntheticEvent<Element, Event> | { source: string },
      //   _,
      //   value,
      //   reason
      // ) => {
      //   if (
      //     // prevent removing options by backspacing the search input
      //     reason !== "removeOption"
      //     // ...but allow deletion of option through the role select
      //     // || ("source" in event && event.source === "chip")
      //   ) {
      //     // setMemberIds(value);
      //   }
      // }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          placeholder="Search by name"
        />
      )}
    />
  );
}

const filterMemberOptions = createFilterOptions<MemberOption>({
  stringify(option) {
    return [option.name, option.email].join(" ");
  },
});
