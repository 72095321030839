import { Business, CloudOutlined } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import clsx from "clsx";
import React, { PropsWithChildren } from "react";

import { useIsHd, usePlayerSelector } from "@/components/Player/playerMachine";

export function PlayerMetadata({
  children,
  className,
  disabled = false,
}: PropsWithChildren<{ className?: string; disabled?: boolean }>) {
  return (
    <div
      className={clsx(
        "flex items-center px-4 min-h-[40px] bg-[#353535] text-white transition-opacity",
        className,
        { "opacity-70 pointer-events-none": disabled }
      )}
    >
      {children}
    </div>
  );
}

export function HdIndicator({ darkIcon = false }: { darkIcon?: boolean }) {
  if (!useIsHd()) return null;

  return (
    <div
      className={clsx(" font-bold py-0.5 px-1 rounded-sm text-xs", {
        "dark:text-white bg-gray-e0 dark:bg-gray-44": !darkIcon,
        "text-white bg-gray-44": darkIcon,
      })}
    >
      HD
    </div>
  );
}

export function LocalIndicator({
  variant,
  darkIcon = false,
}: {
  variant?: "outline" | "solid";
  darkIcon?: boolean;
}) {
  const isLocal = usePlayerSelector((state) =>
    state.matches("selectingStream.local")
  );

  return (
    <Tooltip title={`Spot is streaming ${isLocal ? "locally" : "remotely"}`}>
      <div
        className={clsx(
          "font-medium py-0.5 px-1 rounded-sm text-2xs inline-flex items-center",
          {
            "border border-[#FFFFFF4D]": variant === "outline",
            "bg-gray-e0 dark:bg-gray-71": variant !== "outline" && !darkIcon,
            "text-white bg-gray-44": variant !== "outline" && darkIcon,
          }
        )}
      >
        {isLocal ? (
          <>
            <Business style={{ fontSize: 13 }} />
            <div className="w-1" />
            LOCAL
          </>
        ) : (
          <>
            <CloudOutlined style={{ fontSize: 13 }} />
            <div className="w-1" />
            REMOTE
          </>
        )}
      </div>
    </Tooltip>
  );
}
