import clsx from "clsx";

import { ReactComponent as CameraIcon } from "@/icons/camera.svg";

export function DeviceIcon({
  className,
  isNvr,
}: {
  className?: string;
  isNvr?: boolean;
}) {
  return (
    <div
      data-cy="device-icon"
      className={clsx(
        "flex flex-col justify-center items-center text-[8px] font-bold leading-[0.6]",
        className,
        {
          "mt-[-6px]": isNvr,
        }
      )}
    >
      <CameraIcon />
      {isNvr && (
        <div data-cy="nvr-icon" className="-mt-0.5">
          NVR
        </div>
      )}
    </div>
  );
}
