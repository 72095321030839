import { Container, Hidden, Paper, Typography, useTheme } from "@mui/material";
import clsx from "clsx";
import { utcToZonedTime } from "date-fns-tz";
import { format } from "date-fns/fp";
import gql from "graphql-tag";
import { useFlags } from "launchdarkly-react-client-sdk";
import { identity, sortBy } from "lodash/fp";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";

import { AnalyticsViewType, trackView } from "@/util/analytics";
import { filterNullish } from "@/util/filterFalsy";
import { useGlobalFullscreenState } from "@/util/fullscreen";
import { hasAdvancedAiLicense } from "@/util/license";
import { useBreakpoints } from "@/util/useBreakpoints";
import { useDocumentTitle } from "@/util/useDocumentTitle";

import { ClipSegmentsSection } from "@/pages/Search/ClipSegmentsSection";
import { SearchFilterSection } from "@/pages/Search/SearchFilterSection";
import { SearchHeader, SearchTabs } from "@/pages/Search/SearchTabs";
import { useRangeParam, useVodParam } from "@/pages/Search/searchHooks";

import { useCopilotEnabled } from "@/components/Ai/Copilot/copilotQueryHooks";
import { useMe } from "@/components/Auth";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { MainPlayerMobileHeader } from "@/components/Player/MainPlayer/MainPlayerMobileHeader";
import { PlayerIdsProvider } from "@/components/Player/PlayerBase";
import { PlayerMachineProvider } from "@/components/Player/playerMachine";
import { SearchPlayerSection } from "@/components/Search/SearchPlayerSection";
import { ViewDrawer } from "@/components/SpotDrawer";
import { SpotSwitch } from "@/components/Styled/SpotSwitch";
import { CaseInvestigationBanner } from "@/components/View/CaseInvestigationBanner";
import { WiredMobileCameraDrawer } from "@/components/View/MobileCameraDrawer";
import { WiredCanvasTimeline } from "@/components/View/WiredCanvasTimeline";
import {
  useActiveCamIds,
  useFocusedCam,
} from "@/components/View/sharedViewHooks";
import { SearchParamLink } from "@/components/shared/QueryParamLink";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  Camera,
  CameraActivity,
  Location,
  Page_SearchQuery,
  useGroupsQuery,
  usePage_SearchQuery,
  useRecentlyViewedVodsQuery,
  useRecentVodsQuery,
  Vod,
} from "@/generated-models";
import { usePrefixOrgSlug } from "@/hooks/useOrgRouteBase";
import { usePermissions } from "@/hooks/usePermissions";
import { useCustomScrollbarStyles } from "@/layout/theme";

import { PeopleSearchEulaModal } from "./PeopleSearchEulaModal";
import { PeopleSearch } from "./SubjectSearch/PeopleSearch";
import { PeopleSearchDetail } from "./SubjectSearch/PeopleSearchDetail";

export function Search() {
  useDocumentTitle("Search");
  const me = useMe();
  const hasPermission = usePermissions();
  const navigate = useNavigate();
  const hasViewVodPermission = hasPermission("video_vod_access");
  const prefixOrgSlug = usePrefixOrgSlug();
  const { data: groupsData } = useGroupsQuery(refetchOnMountPolicy);

  // User does not have permission to view this page
  if (!hasViewVodPermission) {
    return (
      <ErrorMessage
        title="You do not have access to video search"
        description="Your system admin has restricted your ability to search video feeds, please contact them for additional access permissions."
      />
    );
  }

  // Redirect to welcome page if no locations have been setup
  if (groupsData?.groups.length === 0) {
    if (hasPermission("devices_manage")) {
      return <Navigate to={prefixOrgSlug("/welcome")} replace />;
    }
    return (
      <ErrorMessage
        title="No access"
        description="Please contact your admin, as your admin hasn't assigned any camera locations to you"
      />
    );
  }

  return (
    <Routes>
      <Route index element={<VodSearchBase />} />
      {me?.termsPeopleSearchAccepted ? (
        <Route path="people">
          <Route
            index
            element={hasPermission("people_search_access", <PeopleSearch />)}
          />
          <Route
            path=":id"
            element={hasPermission(
              "people_search_access",
              <PeopleSearchDetail />
            )}
          />
        </Route>
      ) : (
        <Route
          path="people/*"
          element={hasPermission(
            "people_search_access",
            <PeopleSearchEulaModal
              opened
              cancel={() => {
                navigate(prefixOrgSlug("/search"));
              }}
            />
          )}
        />
      )}
      <Route path="vehicles">
        <Route index element={<div>WIP</div>} />
        <Route path=":id" element={<div>WIP</div>} />
      </Route>
    </Routes>
  );
}

function VodSearchBase() {
  const { fitsSmallTablet, fitsDesktop } = useBreakpoints();
  const { classes: scrollbarClasses } = useCustomScrollbarStyles();
  const [isFullscreen] = useGlobalFullscreenState("isFullscreen");
  const activeCamIds = useActiveCamIds();
  const mainContainerRef = useRef<HTMLDivElement>(null);

  return (
    <PlayerIdsProvider cameraIds={activeCamIds}>
      <CaseInvestigationBanner />
      <div
        className={clsx(
          "flex",
          fitsSmallTablet ? "h-[calc(100%-70px)]" : "h-full", // Correct for desktop header
          { relative: !fitsSmallTablet }
        )}
      >
        <div
          className={clsx(
            "w-full overflow-y-auto overflow-x-hidden",
            scrollbarClasses.scrollbarContainer
          )}
          ref={mainContainerRef}
        >
          <Container maxWidth="lg" className="p-0 md:px-5 relative">
            {(fitsDesktop || !activeCamIds.length) && (
              <SearchTabs
                fallback={
                  <SearchHeader label="Cameras" className="text-4xl shrink-0" />
                }
              >
                {activeCamIds.length ? (
                  <SearchParamLink
                    className="text-primary font-medium ml-auto"
                    to="."
                    keepParams={["g"]}
                  >
                    Recent Searches
                  </SearchParamLink>
                ) : (
                  <Typography className="flex-auto basis-80 max-w-md text-sm md:text-base leading-5 md:leading-5">
                    <strong>Search your video footage.</strong> Choose a recent
                    search or select a camera from the list or map in the
                    {fitsDesktop ? " sidebar on the right" : " tray below"}.
                  </Typography>
                )}
              </SearchTabs>
            )}
            <VodSearch
              activeCamIds={activeCamIds.slice(0, 4)} // Only show max 4 cameras on the search page
              mainContainerRef={mainContainerRef}
            />
          </Container>
        </div>
        {fitsSmallTablet && !isFullscreen && (
          <ViewDrawer activeCamIds={activeCamIds} />
        )}
        {!fitsSmallTablet && <WiredMobileCameraDrawer />}
      </div>
    </PlayerIdsProvider>
  );
}

interface SearchProps {
  activeCamIds: number[];
  mainContainerRef: React.RefObject<HTMLDivElement>;
}

function VodSearch({ activeCamIds, mainContainerRef }: SearchProps) {
  const me = useMe();
  const hasPermission = usePermissions();
  const noCamerasSelected = activeCamIds.length === 0;
  const { data, previousData, error } = usePage_SearchQuery({
    variables: { ids: sortBy(identity, activeCamIds) },
    ...refetchOnMountPolicy,
    skip: noCamerasSelected,
  });

  // Make sure the cameras are in the same order as the activeCamIds and show cameras from the previous query if they are still active
  const activeCams = useMemo(() => {
    const cameras = data?.cameras ?? previousData?.cameras;
    if (!cameras) return undefined;
    return activeCamIds
      .map((id) => cameras.find((cam) => cam.id === id))
      .filter(filterNullish);
  }, [activeCamIds, data, previousData]);

  if (error) {
    return (
      <ErrorMessage
        title="Error"
        description="Failed loading the camera(s). Please try again later or contact support."
      />
    );
  }

  if (noCamerasSelected) {
    // Show recent searches if no cameras are selected
    return (
      <>
        <RecentSearches />
        {me?.organization.flags.aiFaceRecognition &&
          hasPermission("people_search_access") && (
            <Hidden mdDown>
              <div className="inline-block px-1.5 py-1 ml-2.5 text-2xs italic font-bold text-white bg-spotGreen rounded-t">
                NEW FEATURE!
              </div>
              <div className="flex items-stretch max-w-xl gap-3 p-2.5 rounded-lg bg-white shadow-[0_8px_15px_rgba(0,0,0,0.07)] border border-[#E3E3E3BF]">
                <div className="flex-1 text-sm leading-4">
                  <strong>You can now search for people!</strong> Find people
                  and search by attribute, like the clothing, age, and more.
                </div>
                <SearchParamLink
                  className="flex-1 flex-center text-lg font-bold text-white spot-gradient rounded-lg"
                  to="people"
                  keepParams={["g"]}
                >
                  Try People Search
                </SearchParamLink>
              </div>
            </Hidden>
          )}
      </>
    );
  } else if (!activeCams || (activeCams.length === 0 && !data)) {
    // activeCams is undefined or empty while data is still loading
    return <Loading className="my-40" />;
  } else if (activeCams.length === 0) {
    // User is trying to access a camera without having access to it, because the data has loaded and there are no active cameras
    return (
      <ErrorMessage
        title="You do not have access to this camera"
        description="Your system admin has restricted your ability to access this camera, please contact them for additional access permissions."
      />
    );
  }

  return (
    <PlayerMachineProvider>
      <Hidden mdUp>
        <MainPlayerMobileHeader />
      </Hidden>
      <SearchCameras
        activeCams={activeCams}
        mainContainerRef={mainContainerRef}
      />
    </PlayerMachineProvider>
  );
}

interface SearchCamerasProps {
  activeCams: Page_SearchQuery["cameras"];
  mainContainerRef: React.RefObject<HTMLDivElement>;
}

function SearchCameras({ activeCams, mainContainerRef }: SearchCamerasProps) {
  const theme = useTheme();
  const { fitsDesktop } = useBreakpoints();
  const { rangeStart, rangeEnd } = useRangeParam();
  const { vodStart, vodEnd, setVod, isDefaultVod } = useVodParam();
  const { copilotEnabled, setCopilotEnabled } = useCopilotEnabled();
  const focusedCam = useFocusedCam(activeCams);

  const cameraIdsJoin = activeCams.map((c) => c.id).join("");
  useEffect(() => {
    if (cameraIdsJoin) trackView(AnalyticsViewType.vod);
  }, [cameraIdsJoin, vodStart, vodEnd]);

  // Scroll down towards search features when no vod is selected
  useEffect(() => {
    if (isDefaultVod) {
      mainContainerRef.current?.scroll({
        behavior: "smooth",
        top: 500,
      });
    }
  }, [isDefaultVod, mainContainerRef]);

  const [showMissingFootage, setShowMissingFootage] = useState(
    localStorage.enableMissingFootage === "true"
  );

  return (
    <>
      <SearchPlayerSection activeCams={activeCams} />

      <Paper
        elevation={fitsDesktop ? 6 : 0}
        className={clsx("relative my-6", {
          "rounded-xl": fitsDesktop,
        })}
      >
        <>
          <SearchFilterSection
            intelligenceFilters={
              focusedCam.appliance.licensesSupported ||
              activeCams.every((c) => c.aiEnabled)
            }
            activeCameraIds={activeCams.map((c) => c.id)}
            firstSegmentTime={focusedCam.firstSegmentTime} // TODO: how to handle?
            timezone={focusedCam.location.timezone}
            attributesEnabled={focusedCam.settings.attributesEnabled ?? false}
            hasAdvancedAi={hasAdvancedAiLicense(focusedCam.appliance.license)}
          />
          <div
            className="w-full relative z-10"
            style={{
              // TODO: If someone has a better way of doing this, let me know
              // Probably edo and demian could help: basically I want this shadow to show above the
              // canvas timeline, but once I start setting z indices, becasue the image component
              // of the scrubber is is a child of the timeline, it starts going behind. So I want a sibling z index to be
              // lower than the time range session, but also have the children thumbnails above. this is the easiest way I could
              // think of doing this without adding relative z indices everywhere.
              height: 6,
              marginBottom: -6,
              background:
                "linear-gradient(180deg, #E3E3E3 -183.33%, rgba(196, 196, 196, 0) 100%)",
            }}
          />
        </>

        {fitsDesktop && (
          <WiredCanvasTimeline
            showMissingFootage={showMissingFootage}
            timezone={focusedCam.location.timezone}
            focusedCameraId={focusedCam.id}
            activeWindow={
              vodStart && vodEnd
                ? { lower: vodStart.getTime(), upper: vodEnd.getTime() }
                : undefined
            }
            bounds={{
              lower: rangeStart.getTime(),
              upper: rangeEnd.getTime(),
            }}
            style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.03)" }}
          />
        )}
        {fitsDesktop && localStorage.enableMissingFootage === "true" && (
          <div className="flex p-2 py-1 justify-end">
            <SpotSwitch
              label="Show missing footage"
              checked={showMissingFootage}
              color={theme.palette.error.main}
              onChange={(event) => setShowMissingFootage(event.target.checked)}
            />
          </div>
        )}
        <ClipSegmentsSection
          activeCams={activeCams}
          selectVod={(start: string, end: string, progress?: number) => {
            if (copilotEnabled) {
              setCopilotEnabled(false);
            }
            let position: number | undefined = undefined;
            // If progress <= 0.1 then we start at the beginning of the vod
            if (progress && progress > 0.1) {
              // Calculate vod position based on progress
              const duration =
                new Date(end).getTime() - new Date(start).getTime();
              position = Math.floor((duration * progress) / 1000);
            }
            setVod({ start, end, position });

            // Scroll to top
            mainContainerRef.current?.scroll({
              behavior: "smooth",
              top: 0,
            });
          }}
        />
      </Paper>
    </>
  );
}

const formatSearchDate = format("MMM d, h:mmaaa");
const recentSearchesTileSizes = [2, 2];

function RecentSearches() {
  const { auditLogs } = useFlags();
  return auditLogs ? <NewRecentSearches /> : <OldRecentSearches />;
}

function OldRecentSearches() {
  const { data, error } = useRecentlyViewedVodsQuery({
    fetchPolicy: "cache-and-network",
  });
  if (error || !data?.recentlyViewedVods.length) return null;

  return <RecentSearchesInternal vods={data.recentlyViewedVods} />;
}

function NewRecentSearches() {
  const { data, error } = useRecentVodsQuery({
    fetchPolicy: "cache-and-network",
  });
  if (error || !data?.recentVods.length) return null;

  return <RecentSearchesInternal vods={data.recentVods} />;
}

function RecentSearchesInternal({
  vods,
}: {
  vods: ((
    | Pick<CameraActivity, "id" | "still" | "startTime" | "endTime">
    | Pick<Vod, "id" | "still" | "startTime" | "endTime">
  ) & {
    camera: Pick<Camera, "id" | "name"> & {
      location: Pick<Location, "timezone">;
    };
  })[];
}) {
  const { fitsDesktop } = useBreakpoints();
  const recentVods = vods.slice(0, fitsDesktop ? 9 : 10);

  return (
    <div className="mt-7 mb-3 px-2 md:px-0.5 w-full">
      <h2 className="text-lg font-bold leading-none">Recent Searches</h2>
      <hr className="border-text border-opacity-30 my-2 md:my-3" />
      <div className="grid grid-cols-1 md:grid-cols-5 gap-2 md:gap-y-3 md:gap-x-4">
        {recentVods.map((item, index) => {
          const timezone = item.camera.location.timezone;
          return (
            <SearchParamLink
              params={{
                cams: item.camera.id,
                vod: `${item.startTime}|${item.endTime}`,
              }}
              key={item.id}
              style={
                index < recentSearchesTileSizes.length && fitsDesktop
                  ? {
                      gridColumn: `span ${recentSearchesTileSizes[index]}`,
                      gridRow: `span ${recentSearchesTileSizes[index]}`,
                    }
                  : undefined
              }
            >
              <div className="relative overflow-hidden rounded aspect-video">
                <img
                  className="w-full h-full object-cover"
                  src={item.still || "/no-still.svg"}
                  alt=""
                />
                <div className="absolute left-1 top-1 right-1 flex overflow-hidden rounded">
                  <div className="p-1 rounded bg-gray-900 bg-opacity-70 text-white font-bold text-2xs sm:text-xs leading-tight whitespace-nowrap">
                    <div>{item.camera.name}</div>
                    <div>
                      {formatSearchDate(
                        utcToZonedTime(item.startTime, timezone)
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </SearchParamLink>
          );
        })}
      </div>
    </div>
  );
}

gql`
  query page_search($ids: [Int!]!) {
    cameras(ids: $ids) {
      id
      name
      firstSegmentTime
      aiEnabled
      health {
        cameraOnline
        applianceOnline
      }
      location {
        id
        name
        timezone
        tag {
          id
        }
      }
      appliance {
        id
        licensesSupported
        license {
          configuration {
            features {
              id
            }
          }
        }
      }
      device {
        id
      }
      settings {
        audioControlEnabled
        attributesEnabled
        faceRecognitionEnabled
      }
    }
  }
`;

gql`
  query recentlyViewedVods {
    recentlyViewedVods {
      id
      startTime
      endTime
      still
      camera {
        id
        name
        location {
          id
          timezone
        }
      }
    }
  }
`;

gql`
  query recentVods {
    recentVods {
      id
      startTime
      endTime
      still
      camera {
        id
        name
        location {
          id
          timezone
        }
      }
    }
  }
`;
