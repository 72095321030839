import { Button } from "@mui/material";
import clsx from "clsx";
import OAuth2Login from "react-simple-oauth2-login";

import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";

import { apiHost, isProductionEnv, isStagingEnv } from "@/environment";
import { useSetupIntegrationMutation } from "@/generated-models";

import { CFG_FAILED_MSG, CFG_SUCCESS_MSG } from "../constant";
import {
  INTEGRATION_SETUP_STATE_QUERY,
  useCurrentIntegrationId,
} from "../hooks";

// TODO: This client id resolution should default to REACT_APP_BRIVO_CLIENT_ID
// and should fallback to the values based on environment.
const brivoClientId = isProductionEnv
  ? "c0757439-9b8c-4a1f-9e9d-569f732d42dd"
  : isStagingEnv
  ? "bac05fd2-01b3-4a4a-9ba3-e9b8275ce813"
  : process.env.REACT_APP_BRIVO_CLIENT_ID;

export const IntegrationBrivoVendorForm = ({
  callback,
  authorizationUrl,
  editMode,
}: {
  authorizationUrl: string;
  callback: () => void;
  editMode?: boolean;
}) => {
  const { pushSnackbar } = useFeedback();
  const integrationId = useCurrentIntegrationId();
  const redirectUri = `${window.location.origin}/integration/auth/callback`;
  const [setupIntegration, { loading }] = useSetupIntegrationMutation({
    refetchQueries: [
      {
        query: INTEGRATION_SETUP_STATE_QUERY,
        variables: { input: { id: integrationId } },
      },
      "integration",
    ],
    onCompleted: () => {
      callback();
      pushSnackbar(CFG_SUCCESS_MSG, FeedbackType.Success);
    },
    onError: (e) => {
      pushSnackbar(e.message || CFG_FAILED_MSG, FeedbackType.Error);
    },
  });

  const onSuccess = async ({ code }: { code: string }) => {
    const res = await fetch(`${apiHost}/integration/auth/callback`, {
      method: "POST",
      body: JSON.stringify({ authorizationCode: code, integrationId }),
      headers: {
        "content-type": "application/json",
      },
    });

    const resJson = await res.json();

    if (!resJson.success) {
      pushSnackbar(CFG_FAILED_MSG, FeedbackType.Error);
    }

    if (resJson.success) {
      setupIntegration({
        variables: {
          input: {
            integrationId,
            setupState: {},
          },
        },
      });
    }
  };

  const onFailure = (err: any) => {
    pushSnackbar(CFG_FAILED_MSG, FeedbackType.Error);
    console.error(err);
  };

  if (editMode) {
    return <div />;
  }

  return (
    <OAuth2Login
      authorizationUrl={authorizationUrl}
      clientId={brivoClientId ?? ""}
      redirectUri={redirectUri}
      responseType="code"
      buttonText="Login"
      onSuccess={(data: any) => onSuccess(data)}
      onFailure={(err) => onFailure(err)}
      render={({ onClick, className }) => (
        <Button
          variant="contained"
          color="primary"
          className={clsx([
            className,
            "shadow-none text-xs leading-[22px] font-normal px-8",
          ])}
          onClick={onClick}
          disabled={loading}
        >
          Authenticate
        </Button>
      )}
    />
  );
};
