import { ApolloError } from "@apollo/client";
import { CircularProgress, Typography } from "@mui/material";
import gql from "graphql-tag";
import { useEffect } from "react";
import { AppPortal } from "svix-react";
import "svix-react/style.css";

import { useDocumentTitle } from "@/util/useDocumentTitle";

import { ErrorMessage } from "@/components/ErrorMessage";

import {
  useWebhookAppRegisterMutation,
  useWebhookPortalLoginMutation,
} from "@/generated-models";

const APP_NOT_FOUND_ERROR = "APP_NOT_FOUND";

export function Webhooks() {
  useDocumentTitle("Webhooks");

  const [
    webhookLogin,
    { data, loading, error },
  ] = useWebhookPortalLoginMutation({
    onError: (e) => {
      if (isAppNotFoundError(e)) {
        webhookAppRegister();
      }
    },
  });

  const [
    webhookAppRegister,
    { loading: registerLoading, error: registerError },
  ] = useWebhookAppRegisterMutation({
    onCompleted: () => {
      webhookLogin();
    },
  });

  function isAppNotFoundError(error?: ApolloError) {
    return !!error?.graphQLErrors.find(
      (e) => e.extensions.code === APP_NOT_FOUND_ERROR
    );
  }

  useEffect(() => {
    webhookLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const url = data?.appPortalLogin?.url;

  if (registerError || (error && !isAppNotFoundError(error))) {
    return (
      <ErrorMessage
        title="Error"
        description={
          <Typography>
            Something went wrong while trying to load the webhook data...
            <br />
            Please try again later.
          </Typography>
        }
      />
    );
  }

  return (
    <div>
      <div className="flex justify-between items-center flex-wrap gap-y-1 gap-x-8 p-4 bg-white ">
        <Typography variant="h1">Webhooks</Typography>
        <div>Receive realtime, automated messages when events occur</div>
      </div>
      <div className="flex flex-col p-4 shadow-divider h-[600px]">
        {(loading || registerLoading) && (
          <div className="flex items-center justify-center h-full">
            <CircularProgress size={50} />
          </div>
        )}
        {url && <AppPortal url={url} />}
      </div>
    </div>
  );
}

export const WEBHOOK_PORTAL_LOGIN = gql`
  mutation webhookPortalLogin {
    appPortalLogin {
      url
      token
    }
  }
`;

export const WEBHOOK_APP_REGISTER = gql`
  mutation webhookAppRegister {
    appRegister {
      uid
    }
  }
`;
