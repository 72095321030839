import {
  DateRange,
  SvgIconComponent,
  Timer,
  Videocam,
} from "@mui/icons-material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Typography } from "@mui/material";
import clsx from "clsx";
import { capitalize } from "lodash";
import { Link } from "react-router-dom";

import { formatTimeString, getShorthandDayRange } from "@/util/date";
import { formatDurationSecs } from "@/util/formatDurationSecs";

import { IntelligenceDashboardType } from "@/generated-models";
import { usePrefixOrgSlug } from "@/hooks/useOrgRouteBase";

import { IntelligenceDashboardComparativeViewExportButton } from "../Export/IntelligenceDashboardViewExportButton";
import { getObjectLabel, getObjectTypeIcon } from "../utils";
import { ComparativeDashboardProps } from "./ComparativeDashboard";
import { ComparativeLocationIndicator } from "./Core/ComparativeLocationIndicator";
import { getComparativeCameras, getComparativeType } from "./utils";

function ComparativeBackButton() {
  const prefixOrgSlug = usePrefixOrgSlug();

  return (
    <IconButton
      edge="start"
      component={Link}
      to={prefixOrgSlug("/intelligence")}
      className="text-text"
      size="large"
    >
      <ArrowBackIosIcon />
    </IconButton>
  );
}

function ComparativeHeaderLabels({ name }: { name: string }) {
  return (
    <div className="flex flex-col gap-0.5 items-center">
      <Typography className="text-lg leading-[21px]">
        Comparative Insight
      </Typography>
      <Typography className="font-bold text-2xl leading-7">{name}</Typography>
    </div>
  );
}

export function ComparativeDashboardSummaryStat({
  Icon,
  iconClass,
  value,
}: {
  Icon: SvgIconComponent;
  iconClass?: string;
  value: string;
}) {
  return (
    <div className="flex items-center gap-1">
      <Icon
        className={clsx("mr-0.5 text-[#757575]", iconClass)}
        fontSize="small"
      />
      <Typography className="text-sm leading-4 truncate text-[#757575] tracking-[0.15px]">
        {value}
      </Typography>
    </div>
  );
}

function ComparativeDashboardSummaryHeader({
  dashboard,
}: ComparativeDashboardProps) {
  const cameras = getComparativeCameras(dashboard);
  const type =
    getComparativeType(dashboard) || IntelligenceDashboardType.Presence;

  const TypeIcon = getObjectTypeIcon(dashboard.objectTypes, type);

  return (
    <div className="bg-[#F2F2F2] p-6 flex flex-col items-center gap-4">
      <div className="flex flex-col md:flex-row items-center justify-center gap-1 md:w-[unset] w-full flex-wrap">
        {cameras.map((c, idx) => (
          <ComparativeLocationIndicator
            position={idx}
            key={`${c.id}-${idx}`}
            location={c.location.name}
          />
        ))}
      </div>
      <div className="flex items-center gap-3 flex-wrap justify-center">
        <ComparativeDashboardSummaryStat
          Icon={Videocam}
          value={`${cameras.length} Cameras`}
        />
        {type === IntelligenceDashboardType.Idle && (
          <ComparativeDashboardSummaryStat
            Icon={Timer}
            value={`${
              dashboard.objectTypes
                ? capitalize(getObjectLabel(dashboard.objectTypes, type))
                : "Vehicle"
            } Idle >${formatDurationSecs(dashboard.thresholdSeconds, {
              hideZeroes: true,
              long: true,
            })}`}
          />
        )}
        {type === IntelligenceDashboardType.Presence && (
          <ComparativeDashboardSummaryStat
            Icon={TypeIcon}
            value={`Presence ${dashboard.entityCount}+ ${capitalize(
              getObjectLabel(
                dashboard.objectTypes,
                type,
                dashboard.entityCount > 1
              )
            )}`}
          />
        )}
        <ComparativeDashboardSummaryStat
          Icon={DateRange}
          value={`${getShorthandDayRange(
            dashboard?.daysOfWeek
          )}, ${formatTimeString(dashboard.startTime)} - ${formatTimeString(
            dashboard.endTime
          )}`}
        />
        <Link
          to={`../edit/${dashboard.id}`}
          className="text-primary font-medium text-sm leading-[22px] no-underline"
        >
          <EditIcon className="w-4 h-4 ml-0.5 -mt-0.5" /> Edit
        </Link>
      </div>
    </div>
  );
}

export function ComparativeDashboardHeader({
  dashboard,
}: ComparativeDashboardProps) {
  return (
    <div>
      <div className="px-6 py-4 flex items-center justify-between">
        <ComparativeBackButton />
        <ComparativeHeaderLabels name={dashboard?.name || ""} />
        <IntelligenceDashboardComparativeViewExportButton
          dashboard={dashboard}
        />
      </div>
      <ComparativeDashboardSummaryHeader dashboard={dashboard} />
    </div>
  );
}
