import { ChevronRight } from "@mui/icons-material";
import clsx from "clsx";
import { motion } from "framer-motion";
import { ReactNode, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";

import { useAIModelEnabled } from "@/util/featureToggles";
import { useSlugMatch } from "@/util/useSlugMatch";

import { IntelligenceFeatureType } from "@/pages/Intelligence/constants";

import { useExitIfFullscreen } from "@/components/FullScreen";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { useFirstActiveCamId } from "@/components/View/sharedViewHooks";
import { DefaultDialog, useDialog } from "@/components/shared/Dialog";

import { AdditionalAiClass, AlertType } from "@/generated-models";
import { usePrefixOrgSlug } from "@/hooks/useOrgRouteBase";

import { intelligentFiltersConfig } from "../../../../../../pages/Search/intelligence/intelligence";
import { useSetSearchSubjects } from "../../../../../../pages/Search/searchHooks";
import { useCopilotNavigate } from "../../../CopilotPlayerSection/copilotPlayerSectionHooks";
import { SubjectType } from "../../../constant";
import { useCreateAutoZone } from "../../../copilotQueryHooks";
import {
  CopilotAlertIcon,
  CopilotInsightIcon,
  CopilotSearchIcon,
  CopilotZoneIcon,
} from "../CopilotMenuIcons";
import { useCopilotInsightQueryParam } from "../copilotLabelButtonHooks";
import { useCopilotLabelContext } from "../useCopilotLabelContext";
import { redirectCopilot } from "../utils";
import { LabelInsightMenuItems, MenuMapping } from "./InsightLabelMenuItems";

interface LabelMenuItemProps {
  className?: string;
  disabled?: boolean;
  focused?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  children: ReactNode;
}

export type BaseMenuItemProps = {
  id: number;
  cameraId: number;
  onCreateAutoZone: (approximateLine?: boolean) => Promise<number | null>;
};

function scrollToSearchList() {
  const section = document.querySelector("#csearch-result-list");
  section?.scrollIntoView({ behavior: "smooth", block: "start" });
}

function useNavigateToSearch() {
  const isLive = useSlugMatch("live");
  const navigate = useNavigate();
  const location = useLocation();

  return (cb: () => void, objectIds?: string[], zones?: string) => {
    if (isLive) {
      const params = new URLSearchParams(location.search);
      if (zones) params.set("zones", zones);
      if (objectIds) params.set("subjects", objectIds.join("_"));

      navigate(
        `${location.pathname.replace("/live", "/search")}?${params.toString()}`
      );
    } else {
      cb();
    }
  };
}

export function LabelMenuItem({
  disabled,
  className,
  onClick,
  focused,
  children,
}: LabelMenuItemProps) {
  return (
    <div className="py-[7px]">
      <motion.div
        className={clsx(
          "relative py-[10px] text-left text-sm leading-normal text-white/[0.5] hover:font-bold cursor-pointer flex items-center justify-start gap-2 font-barlow rounded pl-2 pr-1",
          className,
          {
            "animate-ripple": focused,
            "opacity-40 pointer-events-none": disabled,
          }
        )}
        onClick={onClick}
        animate={focused ? "focused" : "animate"}
        style={{
          background: "rgba(0,0,0,0)",
        }}
        variants={{
          focused: {
            border: "1px solid #6F6F6F",
            background: "#4D4D4D",
          },
        }}
        transition={{
          duration: 0.2,
          ease: "easeInOut",
        }}
        whileHover={{
          color: "#FFFFFF",
          transition: { duration: 0.2 },
        }}
      >
        {children}
      </motion.div>
    </div>
  );
}

const AlertTypeMapping: Record<string, AlertType> = {
  [SubjectType.vehicle]: AlertType.Vehicle,
  [SubjectType.person]: AlertType.People,
  [SubjectType.forklift]: AlertType.Forklift,
};

function CreateAlertSubjectMenuItem({
  label,
  cameraId,
  onCreateAutoZone,
}: BaseMenuItemProps & {
  label: string;
}) {
  const title = "Create Alert";
  const prefixOrgSlug = usePrefixOrgSlug();
  const { open, ...dialogProps } = useDialog();
  const exitIfFullscreen = useExitIfFullscreen();

  async function onNavigateCreateAlert() {
    exitIfFullscreen();
    const confirmed = await open();
    if (!confirmed) return;

    const id = await onCreateAutoZone();
    const url = new URL(
      `${window.location.origin}${prefixOrgSlug("/alerts/configure/create")}`
    );

    url.searchParams.append(
      "type",
      AlertTypeMapping[label] || AlertType.Motion
    );

    redirectCopilot(url, cameraId, id);
  }

  return (
    <>
      <LabelMenuItem onClick={onNavigateCreateAlert}>
        <CopilotAlertIcon />
        {title}
      </LabelMenuItem>
      <DefaultDialog
        title={title}
        content={
          <span>
            Continuing will open a new tab to the create alert page. A zone for{" "}
            <strong>{label}</strong> will automatically be created and used for
            this alert. Continue?
          </span>
        }
        confirmColor="primary"
        confirmText="Create Alert"
        {...dialogProps}
      />
    </>
  );
}

function CreateInsightTypeSubjectMenuItem({
  type,
}: BaseMenuItemProps & {
  type: keyof typeof intelligentFiltersConfig;
}) {
  const [navigate] = useCopilotNavigate();
  const [, setInsightMenu] = useCopilotInsightQueryParam();
  const config = intelligentFiltersConfig[type];
  const title = `Create ${config.title} Insight`;

  const item = useMemo(() => {
    if (!navigate.insightFeature) return null;

    return Object.keys(MenuMapping).find((type) => {
      if (
        (MenuMapping[
          type as "people" | "vehicle" | "forklift"
        ] as IntelligenceFeatureType[]).includes(
          navigate.insightFeature as IntelligenceFeatureType
        )
      ) {
        return true;
      }

      return false;
    });
  }, [navigate.insightFeature]);

  return (
    <LabelMenuItem
      focused={(item as SubjectType) === type}
      onClick={(e) => {
        setInsightMenu(type);
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <CopilotInsightIcon />
      {title}
      <ChevronRight className="ml-auto" />
    </LabelMenuItem>
  );
}

function CreateAutoZoneMenuItem({
  cameraId,
  onCreateAutoZone,
}: BaseMenuItemProps) {
  const [, setSearchZone] = useQueryParam("zones", StringParam);

  async function onCreateZone() {
    const id = await onCreateAutoZone();
    setSearchZone(`${cameraId}-${id}`);
  }
  return (
    <LabelMenuItem onClick={onCreateZone}>
      <CopilotZoneIcon />
      Automatically Create Zone
    </LabelMenuItem>
  );
}

function SearchSubjectSubButton({
  id,
  type,
  cameraId,
  onCreateAutoZone,
  children,
  className,
}: BaseMenuItemProps & {
  type: keyof typeof intelligentFiltersConfig;
  children: ReactNode;
  className?: string;
}) {
  const [navigate] = useCopilotNavigate();
  const [, setSearchZone] = useQueryParam("zones", StringParam);
  const setSubjects = useSetSearchSubjects();
  const config = intelligentFiltersConfig[type];
  const navigateToSearch = useNavigateToSearch();
  const focused = navigate.searchFeature === type;

  async function onCreateZone() {
    const id = await onCreateAutoZone();
    const zone = `${cameraId}-${id}`;
    scrollToSearchList();
    navigateToSearch(
      () => {
        setSearchZone(zone);
        setSubjects(config.objectIds);
      },
      config.objectIds,
      zone
    );
  }

  return (
    <div
      onClick={onCreateZone}
      className={clsx(
        "rounded bg-[#303133] border border-solid text-white border-[#79787838] px-2 py-1 text-[13px] leading-normal font-normal hover:font-bold relative",
        className,
        { "animate-ripple z-20": focused }
      )}
      style={
        focused
          ? {
              border: "1px solid #6F6F6F",
              background: "#4D4D4D",
            }
          : {}
      }
    >
      {children}
    </div>
  );
}

function SearchBySubjectInZoneMenuItem(props: BaseMenuItemProps) {
  const forkliftEnabled = useAIModelEnabled(AdditionalAiClass.Forklift, [
    props.cameraId,
  ]);

  return (
    <LabelMenuItem>
      <CopilotSearchIcon />
      <div className="flex items-center gap-0.5">
        <SearchSubjectSubButton className="w-[57px]" {...props} type="motion">
          Motion
        </SearchSubjectSubButton>
        <SearchSubjectSubButton className="w-[57px]" {...props} type="people">
          People
        </SearchSubjectSubButton>
        <SearchSubjectSubButton className="w-[68px]" {...props} type="vehicle">
          Vehicles
        </SearchSubjectSubButton>
        {forkliftEnabled && (
          <SearchSubjectSubButton
            className="w-[60px]"
            {...props}
            type="forklift"
          >
            Forklift
          </SearchSubjectSubButton>
        )}
      </div>
    </LabelMenuItem>
  );
}

export function BaseLabelMenuItems() {
  const cameraId = useFirstActiveCamId();
  const { pushSnackbar } = useFeedback();
  const [insightMenu] = useCopilotInsightQueryParam();
  const {
    id,
    label,
    shape,
    createdAutoZone,
    setCreatedAutoZone,
  } = useCopilotLabelContext();
  const { create } = useCreateAutoZone(cameraId);

  const forkliftEnabled = useAIModelEnabled(AdditionalAiClass.Forklift, [
    cameraId,
  ]);

  async function onCreateAutoZone(approximateLine?: boolean) {
    if (!createdAutoZone) {
      const result = await create(
        label,
        shape,
        label as SubjectType,
        approximateLine
      );
      const id = result.data?.addFocus.id || null;
      setCreatedAutoZone(id);
      if (id) {
        pushSnackbar(
          "Automatic zone successfully created",
          FeedbackType.Success
        );
      }
      return id;
    }
    return createdAutoZone;
  }

  const baseProps = {
    id,
    onCreateAutoZone,
    cameraId,
  };

  return (
    <>
      <LabelInsightMenuItems label={label} {...baseProps} />
      {!insightMenu && (
        <>
          <CreateAutoZoneMenuItem key="auto-zone" {...baseProps} />
          <CreateAlertSubjectMenuItem
            {...baseProps}
            label={label}
            key="alert"
          />
          <SearchBySubjectInZoneMenuItem key="search" {...baseProps} />
          <CreateInsightTypeSubjectMenuItem
            key="insight-people"
            type="people"
            {...baseProps}
          />
          <CreateInsightTypeSubjectMenuItem
            key="insight-vehicle"
            type="vehicle"
            {...baseProps}
          />
          {forkliftEnabled && (
            <CreateInsightTypeSubjectMenuItem
              key="insight-forklift"
              type="forklift"
              {...baseProps}
            />
          )}
        </>
      )}
    </>
  );
}
