import { useSlugMatch } from "@/util/useSlugMatch";

export function useVideoWallParams() {
  const rotatingWall = useSlugMatch("wall/r/:id");
  const singleWall = useSlugMatch("wall/:id");

  return {
    rotatingWallId: rotatingWall && Number(rotatingWall.params.id),
    singleWallId: rotatingWall
      ? null
      : singleWall && Number(singleWall.params.id),
  };
}
