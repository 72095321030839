// import { SearchOutlined } from "@mui/icons-material";
// import FilterListIcon from "@mui/icons-material/FilterList";
// import { IconButton } from "@mui/material";
import clsx from "clsx";
import { utcToZonedTime } from "date-fns-tz";
import { format } from "date-fns/fp";
import { uniqBy } from "lodash/fp";
import { useMemo } from "react";

// import { StringParam, useQueryParam } from "use-query-params";
import { FilterDateField } from "@/components/Filter/Fields/FilterDateField";
import { FilterBar } from "@/components/Filter/FilterBar";
import { FilterBarControls } from "@/components/Filter/FilterBarControls";

// import { SearchBox } from "@/components/SearchBox";
import {
  useCurrentDeviceId,
  useCurrentIntegrationDevices,
} from "../../hooks/deviceHooks";
import { useCurrentIntegrationEventTypes } from "../../hooks/eventHooks";

interface IntegrationDetailsEventSummaryBarProps {
  condensed?: boolean;
  loading?: boolean;
  className?: string;
}

export function IntegrationEventTabSummaryBar({
  condensed,
  className,
  loading,
}: IntegrationDetailsEventSummaryBarProps) {
  const deviceId = useCurrentDeviceId();
  const { types } = useCurrentIntegrationEventTypes();
  const { devices } = useCurrentIntegrationDevices();
  // const [showSearch, setShowSearch] = useState(false);
  // const [searchInputParam, setSearchInputParam] = useQueryParam(
  //   "search",
  //   StringParam
  // );

  const cameras = useMemo(() => {
    return uniqBy(
      "id",
      devices.flatMap((d) => d.cameras)
    );
  }, [devices]);

  const timezone = devices[0]?.cameras?.[0]?.location?.timezone || "";

  const filters = useMemo(
    () => [
      {
        placeholder: "Dates & Times",
        filterName: "datetime",
        label: "Date & Time",
        timezone,
        style: condensed ? { minWidth: 130 } : undefined,
        getValue: (value: string | null) => {
          return (
            value
              ?.split("|")
              .map((vi) =>
                format("MMM d, yyyy p")(utcToZonedTime(new Date(vi), timezone))
              )
              .join(" - ") || ""
          );
        },
        options: [],
        date: true,
      },
      {
        placeholder: "Types",
        filterName: "integrationEventTypeIds",
        style: condensed ? { minWidth: 130 } : undefined,
        label: "Types",
        options: types.map((c) => ({
          label: c.name,
          value: c.id,
        })),
      },
      ...(deviceId
        ? []
        : [
            {
              placeholder: "Devices",
              filterName: "integrationDeviceIds",
              style: condensed ? { minWidth: 130 } : undefined,
              label: "Devices",
              options: devices.map((c) => ({
                label: c.name,
                value: c.id,
              })),
            },
          ]),
      {
        placeholder: "Cameras",
        filterName: "cameraIds",
        style: condensed ? { minWidth: 130 } : undefined,
        label: "Cameras",
        options: cameras.map((c) => ({
          label: c.name,
          value: c.id,
        })),
      },
    ],
    [cameras, condensed, deviceId, devices, timezone, types]
  );

  return (
    <div className={clsx("flex flex-col justify-center", className)}>
      <div className="flex items-center justify-between">
        {!condensed && <div />}
        <div
          className={clsx("flex items-center justify-between gap-3", {
            "justify-center items-center w-full": condensed,
          })}
        >
          {/* NOTE: Hiding search until BigQuery solution is implemented */}
          {/* {showSearch ? (
            <IconButton
              onClick={() => {
                setShowSearch(false);
              }}
              className="bg-[#DDEFFF]"
              color="primary"
            >
              <FilterListIcon />
            </IconButton>
          ) : (
            <FilterBarControls
              className={clsx("hidden md:flex", condensed && "-ml-[14px]")}
              label="Filter:"
              filters={filters}
              components={{
                datetime: FilterDateField,
              }}
            />
          )} */}

          {/* {showSearch ? (
            <SearchBox
              className="w-1/2 py-1"
              input={searchInputParam || ""}
              setInput={setSearchInputParam}
            />
          ) : (
            <IconButton
              onClick={() => {
                setShowSearch(true);
              }}
              className={clsx("bg-[#DDEFFF]", condensed && "ml-auto")}
              color="primary"
            >
              <SearchOutlined />
            </IconButton>
          )} */}
          <FilterBarControls
            className={clsx("hidden md:flex", condensed && "-ml-[14px]")}
            label="Filter:"
            filters={filters}
            components={{
              datetime: FilterDateField,
            }}
          />
        </div>
      </div>
      {!loading && (
        <FilterBar
          className={clsx("rounded-lg my-2", {
            "!my-2": condensed,
          })}
          filters={filters}
        />
      )}
    </div>
  );
}
