import { Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import { SpotConnectIcon } from "@/icons/SpotConnectIcon";

import { usePermissions } from "@/hooks/usePermissions";

export function IntegrationCustomVendorCard() {
  const hasPermission = usePermissions();
  const hasManagePermission = hasPermission("integrations_manage");

  return (
    <div className="md:col-span-3 sm:col-span-2 col-span-1 rounded-2xl bg-white flex justify-start items-center shadow-[0_19px_22px_rgba(0,42,82,0.1)] border border-solid border-[#9AD1FF]">
      <div className="p-6 flex md:flex-row flex-col justify-between items-start md:items-center gap-4 md:gap-3 w-full h-full">
        <div className="flex flex-col md:flex-row md:items-center justify-start gap-7">
          <div className="flex items-center justify-start gap-3">
            <SpotConnectIcon />
            <Typography className="text-lg leading-[21px] font-bold min-w-[150px] text-primary">
              Spot Connect
            </Typography>
          </div>
          <Typography className="text-sm leading-[16px] max-w-[680px]">
            Build a custom integration with Spot Connect. Seamlessly merge your
            on-premise or cloud-based systems with Spot AI to find and
            investigate related video footage.
          </Typography>
        </div>
        {hasManagePermission && (
          <div className="flex flex-col items-center justify-center h-full md:min-w-[75px]">
            <Button
              variant="contained"
              color="primary"
              className="shadow-none rounded-lg md:w-[unset] w-full md:py-[6px] py-[9px]"
              component={Link}
              to="../connect/spot/new"
            >
              Set Up
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
