import CachedRoundedIcon from "@mui/icons-material/CachedRounded";
import { Button } from "@mui/material";
import { useAtomValue } from "jotai";

import {
  conversationAtom,
  conversationLoadingAtom,
  useAskSpot,
} from "../hooks";

export function AssistantPromptRegenerateButton() {
  const { askSpot } = useAskSpot(true);

  const conversation = useAtomValue(conversationAtom);
  const conversationLoading = useAtomValue(conversationLoadingAtom);

  if (conversation.length === 0) return <></>;

  return (
    <div>
      <Button
        startIcon={<CachedRoundedIcon />}
        disabled={conversationLoading}
        variant="contained"
        size="small"
        className="shadow-none bg-[#252E35] rounded text-[#A6C8E1] -mt-6 disabled:opacity-40"
        onClick={askSpot}
      >
        Regenerate Response
      </Button>
    </div>
  );
}
