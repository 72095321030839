import Editor, { Monaco, OnChange, OnMount } from "@monaco-editor/react";
import { CircularProgress } from "@mui/material";
import clsx from "clsx";
import gql from "graphql-tag";
import { useAtomCallback } from "jotai/utils";
import { IDisposable } from "monaco-editor";
import { useCallback, useEffect, useRef } from "react";

import { conversationLocationAtom } from "@/pages/Assistant/hooks";

import { refetchOnMountPolicy } from "@/apolloClient";
import { usePage_AssistantQuery } from "@/generated-models";

import {
  EDITOR_BASE_OPTIONS,
  SPOT_ASSIST_LANG,
  SPOT_ASSIST_THEME,
} from "./utils/editor/constant";
import { registerAssistantCompletionProvider } from "./utils/editor/editorCompletion";
import { registerAssistantLanguage } from "./utils/editor/editorLanguage";
import { registerAssistantTheme } from "./utils/editor/editorTheme";
import { registerAssistantTokenProvider } from "./utils/editor/editorTokens";

interface AssistantEditorInputProps {
  onChange?: OnChange;
  onMount?: OnMount;
  value?: string;
  containerClassName?: string;
  className?: string;
}

export function AssistantEditorInput({
  containerClassName,
  ...props
}: AssistantEditorInputProps) {
  const disposables = useRef<IDisposable[]>([]);
  const { data, loading } = usePage_AssistantQuery(refetchOnMountPolicy);

  const getLocation = useAtomCallback(
    useCallback((get) => {
      return get(conversationLocationAtom);
    }, [])
  );

  function handleEditorWillMount(monaco: Monaco) {
    disposables.current.push(
      ...[
        registerAssistantLanguage(monaco),
        registerAssistantTokenProvider(monaco),
        registerAssistantCompletionProvider(
          monaco,
          data?.cameras || [],
          data?.appliances || [],
          getLocation
        ),
      ]
    );
    registerAssistantTheme(monaco);
  }

  useEffect(() => {
    const currentDisposables = disposables.current;
    return () => {
      currentDisposables.forEach((d) => {
        d.dispose();
      });
    };
  }, []);

  return (
    <div className={clsx("w-full", containerClassName)}>
      {loading && (
        <div className="flex items-center justify-center w-full h-full">
          <CircularProgress className="text-white" />
        </div>
      )}
      {!loading && (
        <Editor
          {...props}
          width="100%"
          height="100%"
          defaultValue=""
          defaultLanguage={SPOT_ASSIST_LANG}
          theme={SPOT_ASSIST_THEME}
          beforeMount={handleEditorWillMount}
          options={EDITOR_BASE_OPTIONS}
        />
      )}
    </div>
  );
}

gql`
  query page_assistant {
    appliances {
      id
      serialNumber
      version
      health {
        online
      }
      location {
        id
        name
      }
    }
    cameras {
      id
      name
      status
      vendor
      still
      health {
        cameraOnline
        applianceOnline
      }
      location {
        id
        name
        timezone
      }
      tags {
        id
        name
      }
      settings {
        audioControlEnabled
      }
    }
  }
`;
