import {
  Box,
  Button,
  CardMedia,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { Select, TextField } from "formik-mui";
import gql from "graphql-tag";
import React, { LegacyRef } from "react";
import { useNavigate } from "react-router-dom";
import { useMeasure } from "react-use";
import { makeStyles } from "tss-react/mui";

import { states } from "@/util/constants";
import { filterFalsy } from "@/util/filterFalsy";

import { gmapStyles } from "@/pages/Settings/LocationSettings/LocationSettings";

import { TextFieldNoErrorMessage } from "@/components/Formik/FormikTextField";
import { Loading } from "@/components/Loading";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { TimezonePicker } from "@/components/TimezonePicker";

import {
  useCreateLocationMutation,
  useGetLocationQuery,
  useUpdateLocationMutation,
} from "@/generated-models";
import { usePermissions } from "@/hooks/usePermissions";

const useStyles = makeStyles()((theme) => ({
  locationMap: {
    width: "100%",
    height: "100%",
    backgroundColor: "#f5f5f5",
    backgroundSize: "initial",
    border: "1px solid #ddd",
  },
  locationNameField: {
    fontWeight: "bold",
  },
  notesContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "24px 16px 16px",
    backgroundColor: "#f8f8f8",
  },
  notesTitle: {
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
    "& svg": { fontSize: 16, marginRight: 4 },
  },
}));

export function LocationCreateEdit({
  locationId,
}: {
  setup?: boolean;
  locationId?: number;
}) {
  const { classes } = useStyles();
  const hasPermission = usePermissions();
  const { pushSnackbar } = useFeedback();
  const [ref, dimensions] = useMeasure();

  const canEdit = hasPermission("devices_manage");

  const { data } = useGetLocationQuery({
    variables: {
      id: locationId!,
    },
    skip: !locationId,
    fetchPolicy: "cache-only",
  });
  const [createLocation] = useCreateLocationMutation({
    onError: () =>
      pushSnackbar(
        "Failed to create location, please try again...",
        FeedbackType.Error
      ),
  });
  const [updateLocation] = useUpdateLocationMutation({
    onError: () =>
      pushSnackbar(
        "Failed to update location, please try again...",
        FeedbackType.Error
      ),
  });
  const navigate = useNavigate();

  const locationLookup = data?.location;

  // Loading
  if (locationId && !locationLookup) {
    return (
      <div style={{ padding: "48px 0", margin: "0 auto" }}>
        <Loading />
      </div>
    );
  }

  const location = locationLookup || {
    name: "",
    address: "",
    city: "",
    timezone: "",
    state: "",
    supportName: "",
    zipcode: "",
  };

  const centerParams = [location.address, location.city, location.state];

  // Prepare for google maps
  const searchParams =
    location.address &&
    location.city &&
    new URLSearchParams({
      center: centerParams.filter(filterFalsy).join(","),
      zoom: "12",
      size: dimensions
        ? `${Math.floor(dimensions.width)}x${Math.floor(dimensions.height)}`
        : `434x378`,
      key: "AIzaSyAiJY_JuGeRkueiaEq4deZAsppGrkZSNLo",
      markers: `color:0x007ce4|${location.address},${location.city}`,
    });
  if (searchParams) {
    gmapStyles.forEach((style) => {
      searchParams.append("style", style);
    });
  }

  return (
    <Formik
      validateOnBlur={true}
      validateOnChange={false}
      enableReinitialize
      initialValues={{
        name: location.name,
        timezone: location.timezone,
        address: location.address ?? "",
        state: location.state ?? "",
        city: location.city ?? "",
        supportName: location.supportName ?? "",
        zipcode: location.zipcode ?? "",
      }}
      onSubmit={async (values, { setSubmitting }) => {
        // Saving here should work like an upsert, depending on whether the locationId
        // is present in the URL. If so we should expect the user to have gone back one
        // step in the flow, and now wants to update the location that has already been
        // created.
        const upsertLocation = locationId
          ? () =>
              updateLocation({
                variables: {
                  input: { id: locationId, ...values },
                },
              }).then((x) => x.data?.updateLocation.id)
          : () =>
              createLocation({
                variables: { input: { ...values, isSetup: false } }, // Temporarily adding isSetup = true to prevent weird setup confusion in the future
              }).then((x) => x.data?.createLocation.id);

        let id: number | undefined;
        try {
          id = await upsertLocation();
        } catch (e) {
          setSubmitting(false);
          throw e;
        }

        setSubmitting(false);
        if (!id) throw new Error("Unable to save location");
        if (!locationId) navigate(`../${id}/appliances`);
      }}
    >
      {({ isSubmitting, errors, dirty }) => (
        <Form style={{ padding: 16 }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={7}
              style={{ overflow: "hidden", alignSelf: "stretch" }}
            >
              <div
                ref={ref as LegacyRef<HTMLDivElement> | undefined}
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "#F4F4F4",
                }}
              >
                {searchParams ? (
                  dimensions ? (
                    <CardMedia
                      className={classes.locationMap}
                      image={`https://maps.googleapis.com/maps/api/staticmap?${searchParams.toString()}`}
                    />
                  ) : (
                    <CircularProgress />
                  )
                ) : (
                  <Typography variant="body1" style={{ opacity: 0.5 }}>
                    Enter a name and location details
                  </Typography>
                )}
              </div>
            </Grid>
            <Grid item xs={5}>
              <Field
                required
                name="name"
                style={{
                  fontWeight: "bold",
                  fontSize: 18,
                }}
                disabled={!canEdit}
                label="Location Name"
                placeholder="NewCo Corporate Headquarters"
                component={TextFieldNoErrorMessage}
                InputProps={{
                  classes: {
                    input: classes.locationNameField,
                  },
                }}
                fullWidth
                validate={(value: string) => {
                  if (!value) return "Required Field";
                }}
              />
              <Box m={1} />
              <Field
                name="address"
                label="Address"
                placeholder="123 Jones Street"
                disabled={!canEdit}
                component={TextField}
                fullWidth
              />
              <Box m={1} />
              <Field
                name="city"
                label="City"
                placeholder="New York"
                disabled={!canEdit}
                component={TextField}
                fullWidth
              />
              <Box m={1} />
              <FormControl fullWidth>
                <Field
                  label="State"
                  name="state"
                  component={Select}
                  disabled={!canEdit}
                  formControl={{ variant: "standard" }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {states.map((state) => (
                    <MenuItem
                      value={state.abbreviation}
                      key={state.abbreviation}
                    >
                      {state.name}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>
              <Box m={1} />
              <Field
                name="zipcode"
                label="Zipcode"
                placeholder="12345"
                disabled={!canEdit}
                component={TextField}
                fullWidth
              />
              <Box m={1} />
              <FormControl required fullWidth error={!!errors.timezone}>
                <Field
                  label="Timezone"
                  name="timezone"
                  component={TimezonePicker}
                  disabled={!canEdit}
                  validate={(value: string) => {
                    if (!value) return "Required Field";
                  }}
                  formControl={{ variant: "standard" }}
                />
              </FormControl>
              <Box m={1} />
              <Field
                name="supportName"
                label="Location Contact Name"
                placeholder=""
                component={TextField}
                disabled={!canEdit}
                fullWidth
              />
              <Box m={2} />
              {canEdit && (
                <div style={{ display: "flex" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting || !dirty}
                    style={{ marginLeft: "auto" }}
                  >
                    {`Save${locationId ? "" : " and Continue"}`}
                  </Button>
                </div>
              )}
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export const LOCATION_BASE = gql`
  fragment LocationBase on Location {
    id
    name
    timezone
    address
    zipcode
    state
    city
    notes
    isSetup
    supportName
  }
`;

export const CREATE_LOCATION = gql`
  mutation createLocation($input: CreateLocationInput!) {
    createLocation(input: $input) {
      ...LocationBase
    }
  }
  ${LOCATION_BASE}
`;

export const UPDATE_LOCATION = gql`
  mutation updateLocation($input: UpdateLocationInput!) {
    updateLocation(input: $input) {
      ...LocationBase
    }
  }
  ${LOCATION_BASE}
`;

export const GET_LOCATION = gql`
  query getLocation($id: Int!) {
    location(id: $id) {
      ...LocationBase
    }
  }
  ${LOCATION_BASE}
`;
