import { useState } from "react";
import { useInterval } from "react-use";

const viewActivityMaxDuration = 8 * 60 * 60_000;

export const useViewActivityTimer = <T>(
  enabled: boolean,
  record: (start: Date) => Promise<T>
) => {
  const [start, setStart] = useState(new Date());

  useInterval(
    () => {
      // if the view has exceeded the max duration, start over
      const now = new Date();
      const over = now.getTime() - viewActivityMaxDuration > start.getTime();
      if (over) {
        // update for future intervals
        setStart(now);
      }

      record(over ? now : start).catch(console.error);
    },
    enabled ? 10000 : null
  );
  return null;
};
