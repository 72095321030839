import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CheckIcon from "@mui/icons-material/Check";
import { Button, Divider, Modal, Slide, Typography } from "@mui/material";
import {
  DataGridProps,
  GridColumnVisibilityModel,
  GridSortItem,
  GridSortModel,
} from "@mui/x-data-grid";
import clsx from "clsx";
import { useMemo, useState } from "react";

interface MobileDataGridHeaderProps {
  className?: string;
  sortModel: GridSortModel;
  setSortModel: (model: GridSortModel) => void;
  columns: DataGridProps["columns"];
  visibilityModel?: GridColumnVisibilityModel;
}

function getColumnIsVisible(
  field: string,
  visibilityModel?: GridColumnVisibilityModel
) {
  if (!visibilityModel || !(field in visibilityModel)) {
    return true;
  }

  return visibilityModel[field];
}

export function MobileDataGridHeader({
  className,
  sortModel,
  setSortModel,
  columns,
  visibilityModel,
}: MobileDataGridHeaderProps) {
  const [touched, setTouched] = useState(false);
  const [tempSortModel, setSortTempModel] = useState<GridSortModel>(
    sortModel || []
  );
  const [popperOpen, setPopperOpen] = useState(false);
  const close = () => setPopperOpen(false);

  const sortedColumn = sortModel[0];
  const SortIcon =
    sortedColumn?.sort === "asc" ? ArrowUpwardIcon : ArrowDownwardIcon;

  const sortedColumnData = useMemo(() => {
    if (!sortedColumn) return null;
    return columns.find((c) => c.field === sortedColumn.field);
  }, [columns, sortedColumn]);

  const tempSortedColumn = tempSortModel[0];
  const TempSortIcon =
    tempSortedColumn?.sort === "asc" ? ArrowUpwardIcon : ArrowDownwardIcon;

  function getSortDirection(item: GridSortItem) {
    return !item || item?.sort === "asc" ? "desc" : "asc";
  }

  const sortableColumns = useMemo(
    () =>
      columns.filter(
        (c) =>
          c.sortable !== false && getColumnIsVisible(c.field, visibilityModel)
      ),
    [columns, visibilityModel]
  );

  return (
    <div
      className={clsx(
        "md:hidden flex justify-between items-center bg-[#F6F6F6] px-4 py-[10px] rounded-t-lg",
        className
      )}
    >
      <button
        disabled={!sortedColumn}
        type="button"
        className="bg-transparent text-base leading-[18.75px] text-text"
        onClick={() => {
          if (sortedColumn) {
            setSortModel([
              {
                field: sortedColumn.field,
                sort: getSortDirection(sortedColumn),
              },
            ]);
          }
        }}
      >
        Sort by:{" "}
        {sortedColumn && sortedColumnData && (
          <span className="font-bold text-primary">
            {sortedColumnData.headerName || ""}
            <SortIcon className="text-lg ml-0.5 -mt-0.5" />
          </span>
        )}
      </button>
      {sortableColumns.length > 1 && (
        <>
          <Button
            className="font-normal text-base leading-[18.75px]"
            color="primary"
            onClick={() => {
              setPopperOpen(true);
            }}
          >
            Change Metric
          </Button>
          <Modal open={popperOpen} onClose={close}>
            <Slide direction="up" in={popperOpen} mountOnEnter unmountOnExit>
              <div className="absolute bottom-0 w-full">
                <div className="bg-white rounded-t">
                  <div className="p-4">
                    <div className="flex flex-col gap-4">
                      <Typography className="text-base leading-[18.75px] font-bold">
                        Sort By
                      </Typography>
                      {sortableColumns.map((c) => {
                        const isCurrentMetric =
                          tempSortedColumn?.field === c.field;
                        return (
                          <div key={c.field}>
                            {isCurrentMetric && (
                              <CheckIcon className="text-lg mr-1 text-primary -mt-0.5" />
                            )}
                            <button
                              onClick={() => {
                                setSortTempModel([
                                  {
                                    field: c.field,
                                    sort: isCurrentMetric
                                      ? getSortDirection(tempSortedColumn)
                                      : "desc",
                                  },
                                ]);
                                setTouched(true);
                              }}
                              className={clsx(
                                "text-base leading-[18.75px] bg-transparent",
                                {
                                  "text-primary font-bold": isCurrentMetric,
                                }
                              )}
                            >
                              {c.headerName ?? c.field}
                              {isCurrentMetric && (
                                <TempSortIcon className="text-lg ml-0.5 -mt-0.5" />
                              )}
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <Divider />
                  <div className="flex items-center px-3 py-2">
                    <Button
                      color="primary"
                      onClick={() => {
                        setSortTempModel(sortModel);
                      }}
                      className="tracking-button font-normal"
                    >
                      RESET
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => {
                        setSortTempModel(sortModel);
                        setTouched(false);
                        close();
                      }}
                      className="tracking-button font-normal ml-auto mr-2"
                    >
                      CANCEL
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => {
                        setSortModel(tempSortModel);
                        setTouched(false);
                        close();
                      }}
                      disabled={!touched}
                      className="tracking-button font-bold"
                    >
                      APPLY
                    </Button>
                  </div>
                </div>
              </div>
            </Slide>
          </Modal>
        </>
      )}
    </div>
  );
}
