import { atom, useAtom, useSetAtom } from "jotai";

import { useLocalStorage } from "@/util/useLocalStorage";

import {
  usePlayerControls,
  useZoomState,
} from "@/components/Player/PlayerBase";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { useFirstActiveCamId } from "@/components/View/sharedViewHooks";
import { Point } from "@/components/Zones/getSectorsForPolygon";

import {
  PromptListType,
  PromptListsQuery,
  useCreateOrgPromptListMutation,
  useDeletePromptListItemMutation,
  usePromptListsQuery,
  useUpdatePromptListMutation,
} from "@/generated-models";

import { usePromptLists } from "../../copilotQueryHooks";
import { captureCustomObjectFrame } from "../../utils";

export enum LibraryMode {
  adding = "adding",
  default = "default",
  details = "details",
  help = "help",
}

export enum LibraryAddStep {
  input = "input",
  found = "found",
  missing = "missing",
  inputvisual = "inputvisual",
  ivconfirmation = "ivconfirmation",
  success = "success",
}

type LibraryState = {
  addStep: LibraryAddStep;
  details: number;
  found: boolean;
  object: string;
  open: boolean;
  retry: boolean;
  mode: LibraryMode;
};

export const aiCopilotLibraryParams = atom<Partial<LibraryState>>({
  addStep: LibraryAddStep.input,
  mode: LibraryMode.default,
});
aiCopilotLibraryParams.debugLabel = "aiCopilotLibraryParams";

type CopilotVisualObjectData = {
  editing: boolean;
  shape: Point[];
  objectImage?: string;
  scaledShape: Point[];
};

export const aiCopilotVisualObjectData = atom<CopilotVisualObjectData>({
  editing: true,
  shape: [],
  scaledShape: [],
});
aiCopilotVisualObjectData.debugLabel = "aiCopilotVisualObjectData";

export function useUpdateCopilotVisualObjectData() {
  const update = useSetAtom(aiCopilotVisualObjectData);
  return (data: Partial<CopilotVisualObjectData>) => {
    update((d) => ({ ...d, ...data }));
  };
}

export const aiCopilotHiddenObjects = atom<string[]>([]);
aiCopilotHiddenObjects.debugLabel = "aiCopilotHiddenObjects";

export const aiCopilotCustomObjectImage = atom<string>("");
aiCopilotCustomObjectImage.debugLabel = "aiCopilotCustomObjectImage";

export const aiCopilotCustomObjectShape = atom<Point[]>([]);
aiCopilotCustomObjectShape.debugLabel = "aiCopilotCustomObjectShape";

export const aiCopilotCustomShape = atom<Point[]>([]);
aiCopilotCustomShape.debugLabel = "aiCopilotCustomShape";

export const aiCopilotCustomShapeEditing = atom(true);
aiCopilotCustomShapeEditing.debugLabel = "aiCopilotCustomShapeEditing";

export function useLibraryParams() {
  const [libraryParams, setLibraryParams] = useAtom(aiCopilotLibraryParams);

  return [
    {
      ...libraryParams,
      addStep: libraryParams.addStep ?? LibraryAddStep.input,
      mode: libraryParams.mode ?? LibraryMode.default,
    },
    (value: Partial<LibraryState>) =>
      setLibraryParams((s) => ({ ...s, ...value })),
  ] as [typeof libraryParams, typeof setLibraryParams];
}

export function useCloseLibraryPopover() {
  const [, setLibraryParams] = useLibraryParams();

  return () => {
    setLibraryParams({ open: undefined });
    setTimeout(() => {
      setLibraryParams({
        addStep: undefined,
        retry: undefined,
        details: undefined,
        found: undefined,
        mode: undefined,
        object: undefined,
      });
    }, 500);
  };
}

export function useGenerateVisualObject() {
  const [debugCopilotStill] = useLocalStorage("debugCopilotStill", false);
  const [shape] = useAtom(aiCopilotCustomShape);
  const [libraryParams] = useLibraryParams();
  const setCustomObjectImage = useSetAtom(aiCopilotCustomObjectImage);
  const setCustomObjectShape = useSetAtom(aiCopilotCustomObjectShape);
  const playerId = useFirstActiveCamId().toString();
  const zoomState = useZoomState(playerId);
  const { getPlayerElement } = usePlayerControls(playerId);

  return async () => {
    const playerElement = getPlayerElement();

    if (!playerElement) return null;

    const filename = `${playerId}-${libraryParams.object}-${Date.now()}.jpg`;

    const dataURL = captureCustomObjectFrame(
      playerElement,
      filename,
      zoomState,
      !debugCopilotStill
    );

    setCustomObjectShape(shape);
    setCustomObjectImage(dataURL);

    await Promise.resolve();
  };
}

export function usePromptListDetails() {
  const [libraryParams] = useLibraryParams();
  const query = usePromptLists();

  return {
    ...query,
    data: query.data.find((d) => d.id === libraryParams.details),
  };
}

export function useOrgPromptList(
  onCompleted?: (data?: PromptListsQuery["promptLists"][number]) => void
) {
  const query = usePromptListsQuery({
    onCompleted: (data) => {
      onCompleted?.(
        data?.promptLists?.find((d) => d.type === PromptListType.Organization)
      );
    },
  });

  return {
    ...query,
    data: query.data?.promptLists?.find(
      (d) => d.type === PromptListType.Organization
    ),
  };
}

export function useInitializeOrgList() {
  const [createPromptList] = useCreateOrgPromptListMutation({
    refetchQueries: ["promptListsAll"],
  });

  return useOrgPromptList((list) => {
    if (!list) {
      createPromptList();
    }
  });
}

export function useUpdatePromptLabel() {
  const { pushSnackbar } = useFeedback();

  const [updateList, { loading }] = useUpdatePromptListMutation({
    refetchQueries: ["promptLists"],
    onError: () => {
      pushSnackbar(
        "Unable to toggle object visibility please contact support if this persists.",
        FeedbackType.Error
      );
    },
  });

  const [deleteItem, { loading: deleting }] = useDeletePromptListItemMutation({
    refetchQueries: ["promptLists"],
    onError: () => {
      pushSnackbar(
        "Unable to remove the custom object please contact support if this persists.",
        FeedbackType.Error
      );
    },
  });

  return {
    loading: loading || deleting,
    remove: (listId: number, label: string) => {
      deleteItem({
        variables: {
          input: {
            id: listId,
            label,
          },
        },
      });
    },
    reset: (listId: number, itemsToRemove: string[], ignoreList: string[]) => {
      updateList({
        variables: {
          input: {
            id: listId,
            ignoreList: ignoreList.filter((l) => !itemsToRemove.includes(l)),
          },
        },
      });
    },
    toggle: (listId: number, label: string, ignoreList: string[]) => {
      const newList = [...(ignoreList || [])];

      if (ignoreList.includes(label)) {
        //noop
      } else {
        newList.push(label);
      }

      updateList({
        variables: {
          input: {
            id: listId,
            ignoreList: ignoreList.includes(label)
              ? ignoreList.filter((l) => l !== label)
              : [...ignoreList, label],
          },
        },
      });
    },
  };
}
