import { Typography, Paper } from "@mui/material";
import clsx from "clsx";
import React, { Ref, ReactNode } from "react";
import { makeStyles } from "tss-react/mui";

import { PawIcon } from "@/icons/Paw";

const useStyles = makeStyles()((theme) => ({
  flex: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  container: {
    minHeight: 35,
    borderRadius: 4,
    boxShadow: "0 2px 7px 0 rgba(0, 0, 0, 0.09)",
    minWidth: 70,
    padding: 3,
    boxSizing: "border-box",
    margin: 4,
    flexGrow: 1,
  },
  title: {
    marginLeft: 9,
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: -0.34,
    color: "#353d48",
    width: "fit-content",
  },
  innerContainer: {
    height: "100%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
  },
  paw: {
    display: "block",
    height: "100%",
    width: 20,
    marginLeft: 8,
    boxSizing: "border-box",
  },
}));

type OptionsCardProps = {
  showIcon?: boolean;
  title?: string;
  style?: React.CSSProperties;
  className?: string;
  children?: ReactNode;
};

export const OptionsCard = React.forwardRef(
  (props: OptionsCardProps, ref: Ref<HTMLDivElement>) => {
    const { showIcon = true, title = "", style, className } = props;
    const { classes: styles } = useStyles();

    return (
      <Paper
        ref={ref}
        className={clsx(styles.flex, styles.container, className)}
        style={style}
      >
        {showIcon && <PawIcon className={styles.paw} />}
        <div>
          <Typography noWrap={true} className={styles.title}>
            {title}
          </Typography>
        </div>
        <div className={clsx(styles.flex, styles.innerContainer)}>
          {props.children}
        </div>
      </Paper>
    );
  }
);
