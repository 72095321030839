import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import HelpIcon from "@mui/icons-material/Help";
import ClearIcon from "@mui/icons-material/HighlightOff";
import { Typography, Tooltip, Button, OutlinedInput } from "@mui/material";
import clsx from "clsx";
import { useField } from "formik";
import { TextField, TextFieldProps } from "formik-mui";
import { uniq } from "lodash";
import { flow, keyBy, values } from "lodash/fp";
import { FunctionComponent } from "react";
import { useDropzone } from "react-dropzone";

import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";

import { InterestListItemInput } from "@/generated-models";

import FileUploader from "../../FileUploader/FileUploader";

export const baseInputClassName =
  "px-4 py-3 bg-white text-base leading-[18.75px] font-bold border border-[#AAAAAA] h-auto";

export const baseInputProps = {
  hiddenLabel: true,
  variant: "outlined" as "outlined" | "standard" | "filled" | undefined,
  inputProps: {
    className: baseInputClassName,
  },
  InputProps: {
    classes: { root: "rounded-lg" },
  },
  sx: {
    input: {
      "&::placeholder": {
        fontWeight: 300,
      },
    },
  },
  className: "w-full",
};

function csvToData(str: string, source: string) {
  const rows = str.split(/\r\n|\r|\n/).filter(Boolean);

  return rows.map((r) => {
    const [name, notes] = r.split(",");
    return {
      name: name?.toLowerCase() || "",
      notes: notes.trim() || null,
      source,
    };
  });
}

export function InterestListNoteInput({
  className,
  notes,
  onClear,
  onChange,
  rows = 4,
}: {
  className?: string;
  notes: string;
  onClear: () => void;
  onChange: (value: string) => void;
  rows?: number;
}) {
  return (
    <div className={clsx("relative mt-2", className)}>
      <button
        type="button"
        className="bg-transparent absolute opacity-40 top-0 right-0 mt-1 mr-1 z-10"
        onClick={() => {
          onClear();
        }}
      >
        <ClearIcon className="w-5 h-5" />
      </button>
      <OutlinedInput
        multiline
        rows={rows}
        value={notes}
        classes={{ root: "rounded-lg p-1" }}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        {...baseInputProps}
        inputProps={{
          className: clsx(baseInputProps.inputProps.className, "!font-normal"),
        }}
      />
    </div>
  );
}

export function InterestListTextInputLabel({
  label,
  htmlFor,
  labelClassname,
}: {
  label: string;
  htmlFor?: string;
  labelClassname?: string;
}) {
  return (
    <label
      className={clsx("block font-bold mb-2 text-[#666666]", labelClassname)}
      htmlFor={htmlFor}
    >
      {label}
    </label>
  );
}

export function InterestListTextInput({
  label,
  Component,
  ...props
}: TextFieldProps & {
  Component: FunctionComponent<TextFieldProps>;
}) {
  return (
    <TextField
      {...baseInputProps}
      {...props}
      id={props.id ?? props.field.name}
    />
  );
}

const lastUniqBy = (field: string) => flow(keyBy(field), values);

export function InterestListUploadCSVInput() {
  const { pushSnackbar } = useFeedback();
  const [, { value }, { setValue }] = useField<InterestListItemInput[]>(
    "items"
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    disabled: false,
    accept: {
      "text/csv": [".csv"],
    },
    maxSize: 10e6, // 10mb
    onDropAccepted: async (files) => {
      const reader = new FileReader();
      reader.readAsText(files[0]);
      reader.onload = (event) => {
        const result = csvToData(`${event?.target?.result}`, files[0].name);
        setValue(lastUniqBy("name")([...value, ...result]));
      };
    },
    onDropRejected: (rejections) => {
      if (rejections[0]) {
        pushSnackbar(rejections[0].errors[0].message, FeedbackType.Error);
      }
    },
  });

  const importedFiles = uniq(value.map((v) => v.source)).filter(Boolean);

  return (
    <div className="flex flex-col mt-6">
      <Typography className="text-xs text-[#666666] -mb-2">
        Upload CSV
        <Tooltip
          classes={{ tooltip: "bg-[#333333]" }}
          title={
            <img
              alt="CSV Instructions"
              className="object-cover rounded-[4px]"
              src="/tooltip-interest-list-format.svg"
            />
          }
        >
          <HelpIcon className="ml-0.5 -mt-0.5 w-4 h-4 text-[#BDBDBD]" />
        </Tooltip>
      </Typography>
      <FileUploader
        prompt="Select a CSV file to upload"
        loading={false}
        state={{
          getRootProps,
          getInputProps,
          isDragActive,
        }}
      />
      <div>
        {importedFiles.map((i) => (
          <div key={i} className="flex justify-start items-center gap-4">
            <Typography className="font-bold text-sm leading-[16.41px]">
              {i}
            </Typography>
            <Button
              startIcon={<DeleteForeverIcon className="w-4 h-4" />}
              size="small"
              color="primary"
              className="py-0"
              onClick={() => {
                setValue([...value].filter((v) => v.source !== i));
              }}
            >
              Remove from list
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
}
