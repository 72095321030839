import { format as formatWithTimeZone, utcToZonedTime } from "date-fns-tz";
import { format } from "date-fns/fp";

type IntegrationFormEventPropertyField = {
  name: string;
  type: IntegrationSchemaType;
  required?: boolean;
  description?: string;
};

type IntegrationFormEventChildren = {
  children?: IntegrationFormEventPropertyField[];
};

type IntegrationFormEventField = {
  name: string;
  buffer?: number | null;
  duration?: number | null;
  properties: (IntegrationFormEventPropertyField &
    IntegrationFormEventChildren)[];
};

enum IntegrationSchemaType {
  "string" = "string",
  "boolean" = "boolean",
  "integer" = "integer",
  "float" = "number",
  "datetime" = "date-time",
  "object" = "object",
  "array_of_objects" = "array",
}

const NestedIntegrationSchemaTypes = [
  IntegrationSchemaType.object,
  IntegrationSchemaType.array_of_objects,
];

function getSchemaPropertyNestedField(type: IntegrationSchemaType) {
  return type === IntegrationSchemaType.array_of_objects
    ? "items"
    : "properties";
}

function getSchemaPropertyDisplayName(property: string) {
  return property.split("_").join(" ").toLowerCase();
}

function getEventDateTime(value: string, timezone?: string) {
  const zonedTime = timezone
    ? utcToZonedTime(new Date(value), timezone)
    : undefined;
  const time =
    timezone && zonedTime
      ? formatWithTimeZone(zonedTime, "hh:mmaaa", {
          timeZone: timezone,
        })
      : format("hh:mmaaa", value);

  const date =
    timezone && zonedTime
      ? formatWithTimeZone(zonedTime, "MMM dd, yyyy", {
          timeZone: timezone,
        })
      : format("MMM dd, yyyy", value);
  return { date, time };
}

export {
  getEventDateTime,
  getSchemaPropertyDisplayName,
  getSchemaPropertyNestedField,
  IntegrationSchemaType,
  NestedIntegrationSchemaTypes,
};

export type {
  IntegrationFormEventChildren,
  IntegrationFormEventField,
  IntegrationFormEventPropertyField,
};
