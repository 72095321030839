import { IconProps } from "@/icons/IconProps";

export function VehicleIcon({ style, className, onClick }: IconProps) {
  return (
    <svg
      style={style}
      className={className}
      onClick={onClick}
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.6"
        d="M11.3529 1.23408C11.2155 0.831804 10.8309 0.545441 10.3776 0.545441H2.82201C2.36868 0.545441 1.9909 0.831804 1.84666 1.23408L0.417969 5.31817V10.7727C0.417969 11.1477 0.72706 11.4545 1.10484 11.4545H1.79171C2.16948 11.4545 2.47857 11.1477 2.47857 10.7727V10.0909H10.721V10.7727C10.721 11.1477 11.0301 11.4545 11.4079 11.4545H12.0947C12.4725 11.4545 12.7816 11.1477 12.7816 10.7727V5.31817L11.3529 1.23408ZM2.82201 8.04544C2.25191 8.04544 1.79171 7.58862 1.79171 7.02271C1.79171 6.4568 2.25191 5.99999 2.82201 5.99999C3.39211 5.99999 3.85231 6.4568 3.85231 7.02271C3.85231 7.58862 3.39211 8.04544 2.82201 8.04544ZM10.3776 8.04544C9.80746 8.04544 9.34726 7.58862 9.34726 7.02271C9.34726 6.4568 9.80746 5.99999 10.3776 5.99999C10.9477 5.99999 11.4079 6.4568 11.4079 7.02271C11.4079 7.58862 10.9477 8.04544 10.3776 8.04544ZM1.79171 4.63635L2.82201 1.56817H10.3776L11.4079 4.63635H1.79171Z"
        fill="#353D48"
      />
    </svg>
  );
}
