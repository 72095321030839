import { addMilliseconds } from "date-fns/fp";
import Hls, { HlsConfig } from "hls.js";

// const testPlaylist = `#EXTM3U
// #EXT-X-VERSION:3
// #EXT-X-TARGETDURATION:2
// #EXT-X-MEDIA-SEQUENCE:823029
// #EXTINF:2.000000,
// #EXT-X-PROGRAM-DATE-TIME:2021-07-26T15:06:48.759+0000
// 2021-07-26T15-06-18Z_2000000.ts?auth=-pLY0GyZQ7zgAVznYx6jeg&exp=1627396867&w=640&b=230400
// #EXTINF:2.000000;
// #EXT-X-PROGRAM-DATE-TIME:2021-07-26T15:06:50.759+0000
// 2021-07-26T15-06-20Z_2000000.ts?auth=-pLY0GyZQ7zgAVznYx6jeg&exp=1627396867&w=640&b=230400
// #EXTINF:2.010000
// #EXT-X-PROGRAM-DATE-TIME:2021-07-26T15:06:52.759+0000
// 2021-07-26T15-06-22Z_2010000.ts?auth=-pLY0GyZQ7zgAVznYx6jeg&exp=1627396867&w=640&b=230400
// #EXTINF:2.000000,
// #EXT-X-PROGRAM-DATE-TIME:2021-07-26T15:06:54.769+0000
// 2021-07-26T15-06-24Z_2000000.ts?auth=-pLY0GyZQ7zgAVznYx6jeg&exp=1627396867&w=640&b=230400
// #EXTINF:1.800000,
// #EXT-X-PROGRAM-DATE-TIME:2021-07-26T15:06:57.769+0000
// 2021-07-26T15-06-26Z_1800000.ts?auth=-pLY0GyZQ7zgAVznYx6jeg&exp=1627396867&w=640&b=230400`;
// console.log(addDiscontTagsToPlaylist(testPlaylist));

const discontTag = "#EXT-X-DISCONTINUITY\n";
function addDiscontTagsToPlaylistWhereNeeded(playlist: string) {
  const lines = playlist.split("\n");
  let previousEndDate: Date | null = null;
  let newPlaylist = "";

  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];
    if (line.includes("#EXT-X-PROGRAM-DATE-TIME:")) {
      const dateTime = new Date(line.slice(line.indexOf(":") + 1));

      // Add discontinuity tag before first segment and if dateTime is invalid
      if (!previousEndDate || !dateTime.getTime()) {
        newPlaylist += discontTag;
      }
      // Check if dateTime matches the end of the previous dateTime
      else if (previousEndDate.getTime() !== dateTime.getTime()) {
        newPlaylist += discontTag;
      }

      // Update previousEndDate
      const extInf = lines[i - 1];
      if (dateTime.getTime() && extInf && extInf.includes("#EXTINF:")) {
        const duration = Number(extInf.replace(/[^\d.]/g, ""));
        previousEndDate = addMilliseconds(
          Math.round(duration * 1000),
          dateTime
        );
      }
    }
    // Add line back into new playlist
    newPlaylist += line + "\n";
  }

  return newPlaylist;
}

function addDiscontTagsToPlaylist(playlist: string) {
  const lines = playlist.split("\n");
  let newPlaylist = "";

  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];
    const previousLine = lines[i - 1];
    // Add discontinuity tag before every segment if not already there
    if (
      line.includes("#EXT-X-PROGRAM-DATE-TIME:") &&
      previousLine !== discontTag.trim()
    ) {
      newPlaylist += discontTag;
    }
    // Add line back into new playlist
    newPlaylist += line + "\n";
  }

  return newPlaylist;
}

const debugPlLoader = localStorage.getItem("debugPlaylistLoader");
export class livePlaylistLoader extends Hls.DefaultConfig.loader {
  constructor(config: HlsConfig) {
    super(config);
    const load = this.load.bind(this);
    this.load = function (context, config, callbacks) {
      if ((context as any).type === "level") {
        const onSuccess = callbacks.onSuccess;
        callbacks.onSuccess = (response, stats, context, networkDetails) => {
          if (
            typeof response.data === "string" &&
            !localStorage.disableContinuity
          ) {
            response.data = addDiscontTagsToPlaylistWhereNeeded(response.data);
          }
          if (!!debugPlLoader) console.log(response.data);
          onSuccess(response, stats, context, networkDetails);
        };
      }
      load(context, config, callbacks);
    };
  }
}

export class vodPlaylistLoader extends Hls.DefaultConfig.loader {
  constructor(config: HlsConfig) {
    super(config);
    const load = this.load.bind(this);
    this.load = function (context, config, callbacks) {
      if ((context as any).type === "level") {
        const onSuccess = callbacks.onSuccess;
        callbacks.onSuccess = (response, stats, context, networkDetails) => {
          if (typeof response.data === "string") {
            response.data = addDiscontTagsToPlaylist(response.data);
          }
          if (!!debugPlLoader) console.log(response.data);
          onSuccess(response, stats, context, networkDetails);
        };
      }
      load(context, config, callbacks);
    };
  }
}
