// import { usePage_MaintainmetricsQuery } from "@/generated-models";
import { BooleanParam, useQueryParam } from "use-query-params";

import { ErrorMessage } from "@/components/ErrorMessage";

import { MaintainDashboardType } from "@/generated-models";

import { MetricAddResourceModal } from "./Core/MetricAddResource/MetricAddResourceModal";
import { MaintainMetricsChartsContainer } from "./MaintainMetricsChart/MaintainMetricsChartsContainer";
import { MaintainMetricsToolbar } from "./MaintainMetricsToolbar/MaintainMetricsToolbar";
import { useMaintainDashboard, useMaintainMetricsDashboardType } from "./hooks";

export function MaintainMetricsPage() {
  const [open, setOpen] = useQueryParam("add-resource-modal", BooleanParam);
  const [type] = useMaintainMetricsDashboardType();
  const { error } = useMaintainDashboard();

  // Disable camera dashboards for now.
  if (type === MaintainDashboardType.Camera) return <></>;

  return (
    <div className="p-2 sm:p-5 flex flex-col gap-2">
      <MaintainMetricsToolbar />
      <MaintainMetricsChartsContainer />
      {error && (
        <ErrorMessage dense title={"Error"} description={error.toString()} />
      )}
      <MetricAddResourceModal
        open={!!open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </div>
  );
}
