import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { useState } from "react";

import { pluralize } from "@/util/pluralize";

import { IntegrationDevices } from "../../hooks/deviceHooks";
import { IntegrationDeviceCard } from "./IntegrationDeviceCard";

interface IntegrationDeviceAccordionProps {
  location: string;
  devices: IntegrationDevices;
}

function getDeviceCountString(count: number) {
  return pluralize(
    {
      multi: `${count} Devices`,
      1: `${count} Device`,
    },
    count
  );
}

export function IntegrationDetailsDeviceGrid({
  devices,
}: {
  devices: IntegrationDevices;
}) {
  return (
    <div className="grid gap-2 grid-cols-1 sm:grid-cols-2 md:grid-cols-[repeat(auto-fill,minmax(220px,_1fr))]">
      {devices
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((device) => (
          <IntegrationDeviceCard device={device as any} key={device.id} />
        ))}
    </div>
  );
}

export function IntegrationDeviceAccordion({
  location,
  devices,
}: IntegrationDeviceAccordionProps) {
  const [expanded, setExpanded] = useState(true);
  return (
    <Accordion
      elevation={0}
      disableGutters
      expanded={expanded}
      onChange={(_, expanded) => {
        setExpanded(expanded);
      }}
      classes={{
        expanded: "min-h-auto",
        root: "px-0",
      }}
      sx={{
        "&:before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        onClick={(e) => {
          e.stopPropagation();
        }}
        expandIcon={<ExpandMoreIcon className="text-[#353D48]" />}
        classes={{
          root: "bg-[#F4F4F4] rounded-lg",
          content: "leading-6 font-bold !border-0 my-[10px]",
          expandIconWrapper: "text-medium text-lg leading-6 !border-0",
        }}
      >
        <div className="flex items-center justify-start gap-[10px] w-full">
          <Typography className="text-lg leading-[21.09px]">
            <strong>{location}</strong> - {getDeviceCountString(devices.length)}
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails
        classes={{
          root: "py-4 md:py-2 px-0",
        }}
      >
        <IntegrationDetailsDeviceGrid devices={devices} />
      </AccordionDetails>
    </Accordion>
  );
}
