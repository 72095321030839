import ClearIcon from "@mui/icons-material/Clear";
import { Button, Chip, Hidden, Typography } from "@mui/material";
import clsx from "clsx";
import { Fragment, useMemo } from "react";
import { ArrayParam, useQueryParams } from "use-query-params";

import { useBreakpoints } from "@/util/useBreakpoints";

import { FilterFieldProps } from "./constant";
import { useActiveFilter, buildQueryParamResult } from "./utils";

function FilterChipGroup<T>({ filters }: { filters: FilterFieldProps<T>[] }) {
  const { fitsDesktop } = useBreakpoints();
  const params = useMemo(() => {
    return buildQueryParamResult(filters, ArrayParam);
  }, [filters]);
  const [query, setQuery] = useQueryParams(params);

  const chips = Object.entries(query).map(([key, value]) => {
    const filter = filters.find((f) => f.filterName === key);
    if (!value || !filter) return <Fragment key={key} />;
    const items = value.map((v) => (
      <Chip
        classes={{
          outlinedPrimary: "font-medium my-1 truncate",
          deleteIconOutlinedColorPrimary: "w-4 h-4",
        }}
        size={fitsDesktop ? "medium" : "small"}
        deleteIcon={<ClearIcon />}
        variant="outlined"
        color="primary"
        key={`${key}-${v}`}
        label={
          filter.getValue
            ? filter.getValue((v as unknown) as T)
            : filter.options.find((o) => `${o.value}` === v)?.label ?? v
        }
        onDelete={() => {
          const filters = [...(value || [])];
          filters.splice(filters.indexOf(v), 1);
          setQuery({ ...query, [key]: filters });
        }}
      />
    ));

    if (!fitsDesktop) return items;

    return (
      <div key={key} className="flex items-center gap-2">
        <Typography className="text-xs leading-[14px] text-black opacity-50">
          {filter.label}:
        </Typography>
        {items}
      </div>
    );
  });

  return <>{chips}</>;
}

export function FilterBar<T>({
  filters,
  className,
}: {
  className?: string;
  filters: FilterFieldProps<T>[];
}) {
  const { clearFilters, hasActiveFilter } = useActiveFilter(filters);

  return (
    <>
      {hasActiveFilter && (
        <div
          className={clsx(
            className,
            "bg-white px-5 border-t border-[#007CE42B]"
          )}
        >
          <div className="flex items-center gap-x-4">
            <div className="flex items-center gap-x-4 flex-wrap">
              <Typography className="opacity-50 text-black font-medium text-sm leading-4 shrink-0">
                Active Filters
              </Typography>
              <Hidden mdDown>
                <div className="flex flex-wrap items-start gap-x-1">
                  <FilterChipGroup filters={filters} />
                </div>
              </Hidden>
            </div>
            <Button
              onClick={() => clearFilters()}
              size="small"
              color="primary"
              className="leading-4 font-normal ml-auto text-sm -mt-1"
            >
              Clear All
            </Button>
          </div>
          <Hidden mdUp>
            <div className="flex flex-wrap items-center gap-x-1">
              <FilterChipGroup filters={filters} />
            </div>
          </Hidden>
        </div>
      )}
    </>
  );
}
