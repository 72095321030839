import { BugReport } from "@mui/icons-material";
import BuildIcon from "@mui/icons-material/Build";
import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import RedeemIcon from "@mui/icons-material/Redeem";
import {
  Badge,
  Box,
  Button,
  ClickAwayListener,
  Divider,
  IconButton,
  Popover,
  Popper,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import gql from "graphql-tag";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useEffect, useRef, useState } from "react";
import { Link, LinkProps, useLocation, useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { ReactComponent as EllipsesIcon } from "@/icons/ellipses.svg";
import { ReactComponent as IntelligenceIcon } from "@/icons/nav_ai.svg";
import { ReactComponent as AlertsIcon } from "@/icons/nav_alerts.svg";
import { ReactComponent as MaintainIcon } from "@/icons/nav_maintain.svg";
import { ReactComponent as SettingsIcon } from "@/icons/nav_settings_line.svg";
import { ReactComponent as SharesIcon } from "@/icons/nav_shares.svg";

import { useBeamer } from "@/util/useBeamer";
import { useBreakpoints } from "@/util/useBreakpoints";
import { useSlugMatch } from "@/util/useSlugMatch";

import { useSettingsRoutes } from "@/pages/Settings/SettingsRoutes";

import { useLogout, useMe } from "@/components/Auth";
import {
  isMobileApp,
  mobileFeatureFlags,
} from "@/components/Mobile/mobileEnvironment";
import { createStyledTabs } from "@/components/StyledTabs/StyledTabs";
import { UserContextDisplay } from "@/components/User/UserContextDisplay";

import {
  Role,
  useGetNewNotificationsCountQuery,
  useHasAiLicenseQuery,
} from "@/generated-models";
import { usePrefixOrgSlug } from "@/hooks/useOrgRouteBase";
import { usePermissions } from "@/hooks/usePermissions";

const useStyles = makeStyles()({
  smallButton: {
    fontWeight: "normal",
    fontSize: 12,
    opacity: 0.7,
    paddingTop: 0,
    paddingBottom: 0,
    minWidth: "initial",
  },
});

export function DesktopMenu() {
  const prefixOrgSlug = usePrefixOrgSlug();
  const logout = useLogout();
  const { classes } = useStyles();
  const me = useMe();
  const hasPermission = usePermissions();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const { fitsLarge } = useBreakpoints();
  const helpButtonRef = useRef<HTMLButtonElement | null>(null);
  const { data } = useGetNewNotificationsCountQuery({
    pollInterval: 60000, // 60 seconds
  });
  const { data: licenseQuery } = useHasAiLicenseQuery();
  const notificationCount = data?.notificationsCount ?? 0;
  const { navigationUpdates, integrations, intercomSupport } = useFlags();
  const hasIntelligence =
    licenseQuery?.hasAiLicense || localStorage.intDashboards;

  const settingsRoutes = useSettingsRoutes();

  // Takes the first part of the location path, e.g:
  //   /configure/user-management => configure
  const firstPathPart = location.pathname
    .replace(/\/o\/[^/]*/, "")
    .split("/")
    .filter(Boolean)[0];

  function handleClick() {
    setIsOpen(true);
  }

  function handleClose() {
    setIsOpen(false);
  }

  // Ordered as: Live, Search, Walls, Cases, Intelligence, Alerts, Integrations, Shares, Maintain, Configure
  const validPaths = [
    "live",
    "search",
    "wall",
    "cases",
    "intelligence",
    "alerts",
    "integrations",
    "shares",
    "maintain",
    "configure",
  ];
  return me ? (
    <>
      <NavTabs
        value={validPaths.includes(firstPathPart) ? firstPathPart : false}
        classes={{
          root: "w-full pl-8",
          indicator: `text-primary`,
        }}
      >
        {hasPermission("video_live_access") && (
          <NavTab
            className={clsx(navigationUpdates && "text-base lg:text-lg")}
            value="live"
            label="Live"
            component={Link}
            to={prefixOrgSlug("/live")}
          />
        )}
        {hasPermission("video_vod_access") && (
          <NavTab
            className={clsx(navigationUpdates && "text-base lg:text-lg")}
            value="search"
            label="Search"
            component={Link}
            to={prefixOrgSlug("/search")}
          />
        )}
        {hasPermission("walls_access") && (
          <NavTab
            className={clsx(navigationUpdates && "text-base lg:text-lg")}
            value="wall"
            label="Walls"
            component={Link}
            to={prefixOrgSlug("/wall")}
          />
        )}

        {hasPermission("cases_access") && (
          <NavTab
            value="cases"
            label="Cases"
            component={Link}
            to={prefixOrgSlug("/cases")}
          />
        )}

        {hasPermission("intelligence_access") && (
          <NavTab
            className={clsx(
              !hasIntelligence && "hidden",
              navigationUpdates && "text-base lg:text-lg"
            )}
            value="intelligence"
            label="Intelligence"
            component={Link}
            to={prefixOrgSlug("/intelligence")}
            icon={
              navigationUpdates && !fitsLarge ? <IntelligenceIcon /> : undefined
            }
          />
        )}

        {hasPermission("alerts_access") && (
          <NavTab
            className={clsx(navigationUpdates && "text-base lg:text-lg")}
            value="alerts"
            label="Alerts"
            component={BadgeLink}
            to={prefixOrgSlug("/alerts")}
            notificationCount={notificationCount}
            icon={navigationUpdates && !fitsLarge ? <AlertsIcon /> : undefined}
          />
        )}

        {integrations && hasPermission("integrations_access") && (
          <NavTab
            className={clsx(navigationUpdates && "text-base lg:text-lg")}
            value="integrations"
            label="Integrations"
            component={Link}
            to={prefixOrgSlug("/integrations")}
          />
        )}

        {hasPermission("video_share") && (
          <NavTab
            className={clsx(navigationUpdates && "text-base lg:text-lg")}
            value="shares"
            label="Shares"
            component={Link}
            to={prefixOrgSlug("/shares")}
            icon={navigationUpdates && !fitsLarge ? <SharesIcon /> : undefined}
          />
        )}

        {hasPermission("devices_access") && (
          <NavTab
            className={clsx(navigationUpdates && "text-base lg:text-lg")}
            value="maintain"
            label="Maintain"
            component={Link}
            tooltip="hello"
            to={prefixOrgSlug("/maintain/cameras")}
            icon={
              navigationUpdates && !fitsLarge ? <MaintainIcon /> : undefined
            }
          />
        )}

        <NavTab
          className={clsx(navigationUpdates && "text-base lg:text-lg")}
          value="configure"
          label="Configure"
          component={Link}
          to={settingsRoutes.configure}
          icon={navigationUpdates && !fitsLarge ? <SettingsIcon /> : undefined}
        />
      </NavTabs>
      <div className="grow" />
      <div className="flex flex-col grow items-end">
        <Box mx={1.5} display="flex" paddingTop={1} alignItems="center">
          {navigationUpdates && (
            <>
              <Button
                ref={helpButtonRef}
                className={`mr-2 ${classes.smallButton}`}
                size="small"
                onClick={handleClick}
              >
                Help
              </Button>
              <Popover
                open={isOpen}
                onClose={handleClose}
                anchorEl={helpButtonRef.current}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <div>
                  <a
                    className="text-base block p-4 hover:bg-[#DDEFFF]"
                    href={
                      intercomSupport
                        ? "https://helpcenter.spot.ai/en"
                        : "https://spotai.zendesk.com/hc/en-us"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <strong className="text-primary">Knowledge Base</strong>
                    <br />
                    Help articles & how-to guides
                  </a>
                  <Divider />
                  <Link
                    className="text-base block p-4 hover:bg-[#DDEFFF]"
                    to={prefixOrgSlug("/configure/tutorials")}
                    onClick={handleClose}
                  >
                    <strong className="text-primary">Video Tutorials</strong>
                    <br />
                    Video walkthroughs of features
                  </Link>
                </div>
              </Popover>
              <Divider orientation="vertical" className="h-2.5" />
            </>
          )}
          {(!isMobileApp || mobileFeatureFlags.externalLinks) && (
            <>
              <BeamerButton />
              <Divider orientation="vertical" className="h-2.5" />
            </>
          )}
          {me.role >= Role.Success && (
            <>
              <div>
                <IconButton component={Link} to={prefixOrgSlug("/internal")}>
                  <BuildIcon style={{ fontSize: 18 }} />
                </IconButton>
              </div>
              <Divider orientation="vertical" className="h-2.5" />
            </>
          )}
          {me.role >= Role.Support && (
            <>
              <div>
                <IconButton component={Link} to={prefixOrgSlug("/debug")}>
                  <BugReport />
                </IconButton>
              </div>
              <Divider orientation="vertical" className="h-2.5" />
            </>
          )}
          <Button
            className={clsx(classes.smallButton, "whitespace-nowrap ml-2")}
            onClick={(e) => {
              e.stopPropagation();
              setAnchorEl(anchorEl ? null : (e.currentTarget as any));
            }}
          >
            {me.name ?? me.email}
            <ArrowDownIcon
              fontSize="small"
              style={{
                transition: "transform 200ms",
                transform: `rotate(${Boolean(anchorEl) ? 180 : 0}deg)`,
              }}
            />
          </Button>
        </Box>
        <Popper
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          placement="bottom"
          style={{ zIndex: 1201 }}
        >
          <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
            {/* <div> is needed to make it work, because otherwise: "Invalid prop `children` supplied to `ClickAwayListener`." */}
            <div>
              <UserContextDisplay />
            </div>
          </ClickAwayListener>
        </Popper>
        {/* <AiFetchHeader /> */}
      </div>
    </>
  ) : (
    <>
      <Button onClick={() => logout()}>Log out</Button>
      <Box m={1} />
    </>
  );
}

// eslint-disable-next-line
function AiFetchHeader() {
  const navigate = useNavigate();
  const prefixOrgSlug = usePrefixOrgSlug();
  const isAssistant = useSlugMatch("assistant");

  const [expanded, setExpanded] = useState(false);
  const [startAnimation, setStartAnimation] = useState(false);

  const { spotAssist } = useFlags();

  useEffect(() => {
    if (expanded) {
      setTimeout(() => {
        setStartAnimation(true);
      }, 1000);
    }
  }, [expanded]);

  if (!spotAssist || isAssistant) return <></>;
  return (
    <div className="absolute top-[70px]">
      <div className="flex items-center justify-start h-[32px]">
        <button
          type="button"
          onClick={() => {
            navigate(prefixOrgSlug("/assistant"));
            setExpanded((value) => !value);
            setStartAnimation(false);
          }}
          className={clsx(
            "bg-[#2B353D] text-[#A6C8E1] p-2 transition-[width] duration-400 h-full overflow-clip whitespace-nowrap",
            {
              "opacity-0 w-0": !expanded,
              "opacity-100 w-[372px]": expanded,
            }
          )}
        >
          <Typography className="text-sm leading-[16.41px]">
            Try{" "}
            <span className="text-white">
              Spot AI <strong>Fetch</strong>
            </span>{" "}
            - the new ChatGPT powered chat assistant.
          </Typography>
        </button>
        <button
          type="button"
          className={clsx(
            " text-white text-sm leading-[16.41px] p-2 transition ease-in-out delay-150 duration-300",
            {
              "bg-primary hover:bg-[#C026A7] min-w-[118px]": expanded,
              "bg-[#2B353D] hover:bg-primary": !expanded,
            }
          )}
          onClick={() => {
            setExpanded((value) => !value);
            setStartAnimation(false);
          }}
        >
          {expanded && (
            <>
              {!startAnimation && (
                <div className="h-4 flex items-center justify-center animate-pulse">
                  <EllipsesIcon />
                </div>
              )}
              {startAnimation && (
                <span className="inline-flex overflow-x-hidden animate-type group-hover:animate-type-reverse whitespace-nowrap text-brand-accent will-change-transform">
                  <strong>Ask me anything.</strong>
                </span>
              )}
            </>
          )}

          {!expanded && (
            <span>
              Spot <strong>AI Fetch</strong>
            </span>
          )}
        </button>
      </div>
    </div>
  );
}

const BadgeLink = React.forwardRef<
  HTMLAnchorElement,
  LinkProps & { notificationCount: number }
>(({ notificationCount, children, ...linkProps }, ref) => {
  return (
    <Link {...linkProps} ref={ref}>
      <Badge badgeContent={notificationCount} color="error">
        {children}
      </Badge>
    </Link>
  );
});

function BeamerButton() {
  useBeamer();

  return (
    <IconButton size="large">
      <div className="beamerTrigger flex items-center">
        <RedeemIcon className="text-2xl" />
      </div>
    </IconButton>
  );
}

gql`
  query hasAiLicense {
    hasAiLicense
  }
`;

gql`
  query getNewNotificationsCount {
    notificationsCount(isSeen: false)
  }
`;

const { NavTabs, NavTab } = createStyledTabs({
  tab: {
    selected: {
      fontWeight: "bold",
    },
  },
});
