import { Button, Modal, Link } from "@mui/material";
import { memo } from "react";

const zendeskArticleLink =
  "https://help.spot.ai/hc/en-us/articles/4407101358989-Transform-Any-Screen-into-a-Video-Wall-with-SpotCast";

function VideoWallConnectionNotSupportedOverlayComponent() {
  return (
    <>
      <Modal
        open
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="flex flex-col justify-center items-center w-full h-full">
          <div className="p-4 rounded-xl flex items-start gap-4 flex-col w-full max-w-[415px] bg-[#383838]">
            <div className="text-white">
              <div className="text-xl font-bold">
                Physical Connection Not Supported
              </div>
              16+ camera video walls are not supported when the appliance is
              physically connected to a display. Please use Spotcast to view
              this wall on a display.
            </div>
            <Button
              size="small"
              variant="contained"
              color="primary"
              component={Link}
              href={zendeskArticleLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              View Spotcast Guide
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export const VideoWallConnectionNotSupportedOverlay = memo(
  VideoWallConnectionNotSupportedOverlayComponent
);
