import { EChartsOption } from "echarts";

import { getBaseBrushOptions } from "@/components/Chart/utils";

export function getBooleanChartOptions(
  label: string,
  onLabel = "",
  offLabel = ""
) {
  return {
    ...getBaseBrushOptions(
      `metrics-${label}`.toLowerCase(),
      (value: unknown) => {
        return !!value ? onLabel : offLabel;
      }
    ),
    yAxis: {
      type: "value",
      boundaryGap: ["20%", "20%"],
      min: 0,
      scale: true,
      axisLabel: {
        formatter: (value: number) => {
          if (value === 1) {
            return onLabel;
          } else if (value === 0) {
            return offLabel;
          }
          return "";
        },
      },
    } as EChartsOption["yAxis"],
  };
}

export function getChartOptions(label: string, unit?: string | null) {
  return {
    ...getBaseBrushOptions(
      `metrics-${label}`.toLowerCase(),
      (value: unknown) => {
        return `${(value as number).toFixed(2)}${unit ? ` ${unit}` : ""}`;
      }
    ),
  };
}
