import { Grid, Hidden } from "@mui/material";

export function NotAllowedCell() {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      wrap="nowrap"
      style={{
        height: "100%",
        backgroundColor: "#cdcdcd",
        borderRadius: 2,
        padding: 16,
        textAlign: "center",
      }}
    >
      <div style={{ fontSize: 16, fontWeight: "bold" }}>Camera Unavailable</div>
      <Hidden smDown>
        <div>Please ask your adminstrator for camera access.</div>
      </Hidden>
    </Grid>
  );
}
