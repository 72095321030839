import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@mui/material";

// A simple login page for manually authenticating into auth0.
// This allows for Cypress to manually handle the login flow in a way
// that is usable with it's multi domain origin workflow.
// Normally we redirect if the user is not authenticated, which
// Cypress is not able to properly interact with the auth0 login
// form in this particular case.
export function LoginPage() {
  const { loginWithRedirect } = useAuth0();

  return (
    <div className="flex items-center justify-center w-screen h-screen bg-white">
      <Button
        data-cy="login-btn"
        variant="outlined"
        color="primary"
        onClick={() => loginWithRedirect()}
      >
        Login
      </Button>
    </div>
  );
}
