import * as yup from "yup";

import { AuthProvider, SsoProvider } from "@/generated-models";

const emailSchema = yup
  .string()
  .email('":email" is not a valid email address')
  .required();

export function required(message: string) {
  return (input: string) => (!input ? message : undefined);
}

export function validateEmail(emails: string[]) {
  const lowerCaseEmails = emails.map((email) => email.toLowerCase());
  return async (rawInput: string) => {
    if (lowerCaseEmails.includes(rawInput.toLowerCase())) {
      return "This email address has already been added";
    }
    if (!rawInput) return "Please provide an email address";
    const invalidEmail = await emailSchema
      .validate(rawInput)
      .then(() => false)
      .catch(() => true);

    if (invalidEmail) return "Not a valid email address";
  };
}

export const authProviderDisplayNames: Record<
  AuthProvider | SsoProvider,
  string
> = {
  auth0: "User / Password",
  microsoft: "Microsoft 365",
  google: "Google",
};

export const emailLabels: Record<AuthProvider | SsoProvider, string> = {
  [AuthProvider.Google]: "Google email",
  [AuthProvider.Microsoft]: "Microsoft 365 email",
  [AuthProvider.Auth0]: "Email",
};

export const getEmailLabelByEmail = (
  email: string,
  ssoConnections?: { domain: string; provider: SsoProvider }[]
) => {
  const domain = email.split("@")[1];
  if (domain && ssoConnections) {
    const matchingSso = ssoConnections.find(
      (s) => s.domain.toLowerCase() === domain.toLowerCase()
    );
    if (matchingSso) return emailLabels[matchingSso.provider];
  }
  return "Email";
};