import {
  DataGrid as MuiDataGrid,
  DataGridProps,
  GridSortModel,
  useGridApiRef,
} from "@mui/x-data-grid";
import clsx from "clsx";
import { FormikHelpers, FormikProps, FormikValues } from "formik";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { NumberParam, useQueryParam } from "use-query-params";

import { useBreakpoints } from "@/util/useBreakpoints";

import { DataGridEditDrawer } from "./DataGridEditDrawer/DataGridEditDrawer";
import { DataGridSelectionBar } from "./DataGridSelectionBar";
import { MobileDataGridHeader } from "./MobileDataGridHeader";
import { useDataGridSelection, useDataGridSelectionCheckbox } from "./hooks";

export interface BaseDatagridRowSelectionProps {
  rowLabel?: string;
  renderEditDrawer?: (
    props: BaseDatagridProps,
    formikProps: FormikProps<FormikValues>
  ) => ReactNode;
  editDrawerSelectionDetails?: ReactNode;
  editDrawerInitialValues?: FormikValues;
  editDrawerOnSubmit?: (
    ids: number[],
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>
  ) => void | Promise<any>;
}

export interface BaseDatagridProps extends DataGridProps {
  rowSelectionProps?: BaseDatagridRowSelectionProps | null;
  baseRows?: DataGridProps["rows"];
  mobileHeaderClassName?: string;
  disableMobileHeader?: boolean;
}

function DataGridEditDrawerContainer(props: BaseDatagridProps) {
  const {
    rowLabel,
    renderEditDrawer,
    editDrawerInitialValues,
    editDrawerOnSubmit,
  } = props.rowSelectionProps || {};

  const canRenderEditDrawer =
    renderEditDrawer &&
    rowLabel &&
    editDrawerInitialValues &&
    editDrawerOnSubmit;

  return canRenderEditDrawer ? (
    <DataGridEditDrawer
      rowLabel={rowLabel}
      initialValues={editDrawerInitialValues}
      onSubmit={editDrawerOnSubmit}
      {...props}
    >
      {(formikProps) => {
        return renderEditDrawer(props, formikProps);
      }}
    </DataGridEditDrawer>
  ) : (
    <></>
  );
}

export function useQueryParamPagination() {
  const [page, setPage] = useQueryParam("page", NumberParam);
  const [pageSize = 100, setPageSize] = useQueryParam("page-size", NumberParam);

  return {
    paginationModel: {
      page: page || 0,
      pageSize: pageSize || 100,
    },
    onPaginationModelChange: (result: { page: number; pageSize: number }) => {
      setPage(result.page, "replaceIn");
      setPageSize(result.pageSize, "replaceIn");
    },
  };
}

export function DataGrid(props: BaseDatagridProps) {
  const {
    className,
    classes,
    components,
    initialState,
    columns,
    rowSelectionProps,
    mobileHeaderClassName,
    baseRows,
    disableMobileHeader = true,
    ...rest
  } = props;

  const rowSelection = !!rowSelectionProps;
  const rowLabel = rowSelectionProps?.rowLabel;

  const [sortModel, setSortModel] = useState<GridSortModel>(
    initialState?.sorting?.sortModel || []
  );

  const { selection, setTotal } = useDataGridSelection();
  const { fitsDesktop } = useBreakpoints();
  const reponsiveClasses = fitsDesktop
    ? {}
    : disableMobileHeader && {
        columnHeaders: "bg-[#f4f4f4] hidden",
        virtualScroller: "!mt-0",
      };

  const apiRef = useGridApiRef();
  const setSortModelCallback = useCallback(
    (model: GridSortModel) => {
      setSortModel(model);
      apiRef.current.setSortModel(model);
    },
    [apiRef]
  );

  const selectionColumn = useDataGridSelectionCheckbox(
    rest.rows,
    rest.getRowId,
    rowLabel
  );
  const showSelectionBar = rowSelection && rowLabel;

  const resolvedRows = baseRows || props.rows;

  useEffect(() => {
    setTotal(props.rows.length);
  }, [props.rows.length, setTotal]);

  return (
    <div>
      {disableMobileHeader && (
        <MobileDataGridHeader
          columns={columns}
          className={mobileHeaderClassName}
          sortModel={sortModel}
          setSortModel={setSortModelCallback}
          visibilityModel={props.columnVisibilityModel}
        />
      )}
      {showSelectionBar && <DataGridSelectionBar rowLabel={rowLabel} />}
      <MuiDataGrid
        apiRef={apiRef}
        className={clsx(
          "rounded-none",
          { "md:rounded-lg": !showSelectionBar || selection?.length === 0 },
          className
        )}
        classes={{
          footerContainer: "justify-center",
          cell: "h-[58px]",
          columnHeaders: "bg-[#f4f4f4]",
          columnHeader: "text-text/70",
          "columnHeader--sorted": "!text-black font-bold",
          sortIcon: "!text-black font-bold",
          ...reponsiveClasses,
          ...classes,
        }}
        components={{
          ColumnResizeIcon: () => null,
          ColumnsPanel: () => null,
          ...components,
        }}
        initialState={{
          pagination: { paginationModel: { pageSize: 100 } },
          ...initialState,
        }}
        sortingOrder={["asc", "desc"]}
        pageSizeOptions={[10, 25, 50, 100]}
        autoHeight
        disableRowSelectionOnClick
        disableColumnMenu
        columns={[...(rowSelection ? [selectionColumn] : []), ...columns]}
        {...rest}
      />
      <DataGridEditDrawerContainer {...props} rows={resolvedRows} />
    </div>
  );
}
