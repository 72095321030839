import React, { SVGProps } from "react";

export function SpotcastLight(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 76 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 1H2C0.9 1 0 1.9 0 3V15C0 16.1 0.9 17 2 17H7V19H15V17H20C21.1 17 21.99 16.1 21.99 15L22 3C22 1.9 21.1 1 20 1ZM20 15H2V3H20V15Z"
        fill="#007CE4"
      />
      <path d="M8 12.5V5L15 9L8 12.5Z" fill="#007CE4" />
      <path
        d="M29.2637 11.2256C29.2637 10.8838 29.1338 10.6195 28.874 10.4326C28.6188 10.2412 28.1699 10.0771 27.5273 9.94043C26.8893 9.80371 26.3812 9.63965 26.0029 9.44824C25.6292 9.25684 25.3512 9.02897 25.1689 8.76465C24.9912 8.50033 24.9023 8.18587 24.9023 7.82129C24.9023 7.21517 25.1576 6.70247 25.668 6.2832C26.1829 5.86393 26.8392 5.6543 27.6367 5.6543C28.4753 5.6543 29.1543 5.87077 29.6738 6.30371C30.1979 6.73665 30.46 7.29036 30.46 7.96484H29.1885C29.1885 7.61849 29.0404 7.31999 28.7441 7.06934C28.4525 6.81868 28.0833 6.69336 27.6367 6.69336C27.1764 6.69336 26.8164 6.79362 26.5566 6.99414C26.2969 7.19466 26.167 7.45671 26.167 7.78027C26.167 8.08561 26.2878 8.31576 26.5293 8.4707C26.7708 8.62565 27.2061 8.77376 27.835 8.91504C28.4684 9.05631 28.9811 9.22494 29.373 9.4209C29.765 9.61686 30.0544 9.85384 30.2412 10.1318C30.4326 10.4053 30.5283 10.7402 30.5283 11.1367C30.5283 11.7975 30.264 12.3285 29.7354 12.7295C29.2067 13.126 28.5208 13.3242 27.6777 13.3242C27.0853 13.3242 26.5612 13.2194 26.1055 13.0098C25.6497 12.8001 25.292 12.5085 25.0322 12.1348C24.777 11.7565 24.6494 11.3486 24.6494 10.9111H25.9141C25.9368 11.335 26.1055 11.6722 26.4199 11.9229C26.7389 12.1689 27.1582 12.292 27.6777 12.292C28.1562 12.292 28.5391 12.1963 28.8262 12.0049C29.1178 11.8089 29.2637 11.5492 29.2637 11.2256Z"
        fill="#007CE4"
      />
      <path
        d="M38.0738 9.57129C38.0738 10.6969 37.8163 11.6038 37.3014 12.292C36.7864 12.9801 36.0891 13.3242 35.2096 13.3242C34.3118 13.3242 33.6054 13.0394 33.0904 12.4697V16.0312H31.8258V5.79102H32.9811L33.0426 6.61133C33.5576 5.97331 34.273 5.6543 35.1891 5.6543C36.0777 5.6543 36.7796 5.98926 37.2945 6.65918C37.8141 7.3291 38.0738 8.26107 38.0738 9.45508V9.57129ZM36.8092 9.42773C36.8092 8.59375 36.6314 7.93522 36.276 7.45215C35.9205 6.96908 35.4329 6.72754 34.8131 6.72754C34.0475 6.72754 33.4732 7.06706 33.0904 7.74609V11.2803C33.4687 11.9548 34.0475 12.292 34.8268 12.292C35.4329 12.292 35.9137 12.0527 36.2691 11.5742C36.6292 11.0911 36.8092 10.3757 36.8092 9.42773Z"
        fill="#007CE4"
      />
      <path
        d="M39.0021 9.4209C39.0021 8.69629 39.1434 8.0446 39.426 7.46582C39.7131 6.88704 40.1096 6.44043 40.6154 6.12598C41.1258 5.81152 41.7069 5.6543 42.3586 5.6543C43.3658 5.6543 44.1792 6.00293 44.799 6.7002C45.4234 7.39746 45.7355 8.32487 45.7355 9.48242V9.57129C45.7355 10.2913 45.5965 10.9385 45.3186 11.5127C45.0451 12.0824 44.6509 12.5267 44.1359 12.8457C43.6255 13.1647 43.0376 13.3242 42.3723 13.3242C41.3697 13.3242 40.5562 12.9756 39.9318 12.2783C39.312 11.5811 39.0021 10.6582 39.0021 9.50977V9.4209ZM40.2736 9.57129C40.2736 10.3916 40.4628 11.0501 40.841 11.5469C41.2238 12.0436 41.7342 12.292 42.3723 12.292C43.0148 12.292 43.5253 12.0413 43.9035 11.54C44.2818 11.0342 44.4709 10.3278 44.4709 9.4209C44.4709 8.6097 44.2772 7.95345 43.8898 7.45215C43.507 6.94629 42.9966 6.69336 42.3586 6.69336C41.7342 6.69336 41.2307 6.94173 40.8479 7.43848C40.465 7.93522 40.2736 8.64616 40.2736 9.57129Z"
        fill="#007CE4"
      />
      <path
        d="M48.6873 4V5.79102H50.0682V6.76855H48.6873V11.3555C48.6873 11.6517 48.7488 11.875 48.8719 12.0254C48.9949 12.1712 49.2046 12.2441 49.5008 12.2441C49.6466 12.2441 49.8471 12.2168 50.1023 12.1621V13.1875C49.7697 13.2786 49.4461 13.3242 49.1316 13.3242C48.5665 13.3242 48.1404 13.1533 47.8533 12.8115C47.5662 12.4697 47.4227 11.9844 47.4227 11.3555V6.76855H46.076V5.79102H47.4227V4H48.6873Z"
        fill="#007CE4"
      />
      <path
        d="M54.3838 11.7588C54.7484 11.7588 55.0446 11.6585 55.2725 11.458C55.5003 11.2575 55.6188 10.9909 55.6279 10.6582H57.4805C57.4759 11.1595 57.3392 11.6198 57.0703 12.0391C56.8014 12.4538 56.4323 12.7773 55.9629 13.0098C55.498 13.2376 54.9831 13.3516 54.418 13.3516C53.3607 13.3516 52.5267 13.0166 51.916 12.3467C51.3053 11.6722 51 10.7425 51 9.55762V9.42773C51 8.28841 51.3031 7.37923 51.9092 6.7002C52.5153 6.02116 53.347 5.68164 54.4043 5.68164C55.3294 5.68164 56.07 5.94596 56.626 6.47461C57.1865 6.9987 57.4714 7.69824 57.4805 8.57324H55.6279C55.6188 8.19043 55.5003 7.88053 55.2725 7.64355C55.0446 7.40202 54.7438 7.28125 54.3701 7.28125C53.9098 7.28125 53.5612 7.44987 53.3242 7.78711C53.0918 8.11979 52.9756 8.66211 52.9756 9.41406V9.61914C52.9756 10.3802 53.0918 10.9271 53.3242 11.2598C53.5566 11.5924 53.9098 11.7588 54.3838 11.7588Z"
        fill="#007CE4"
      />
      <path
        d="M62.5445 13.2148C62.4534 13.0371 62.3873 12.8161 62.3463 12.5518C61.8678 13.085 61.2457 13.3516 60.4801 13.3516C59.7555 13.3516 59.1539 13.1419 58.6754 12.7227C58.2014 12.3034 57.9645 11.7747 57.9645 11.1367C57.9645 10.3529 58.2538 9.7513 58.8326 9.33203C59.4159 8.91276 60.2568 8.70085 61.3551 8.69629H62.2643V8.27246C62.2643 7.93066 62.1754 7.65723 61.9977 7.45215C61.8245 7.24707 61.5488 7.14453 61.1705 7.14453C60.8378 7.14453 60.5758 7.22428 60.3844 7.38379C60.1975 7.54329 60.1041 7.76204 60.1041 8.04004H58.1285C58.1285 7.61165 58.2607 7.21517 58.525 6.85059C58.7893 6.486 59.163 6.20117 59.6461 5.99609C60.1292 5.78646 60.6715 5.68164 61.273 5.68164C62.1845 5.68164 62.9068 5.91178 63.44 6.37207C63.9778 6.8278 64.2467 7.47038 64.2467 8.2998V11.5059C64.2512 12.2077 64.3492 12.7386 64.5406 13.0986V13.2148H62.5445ZM60.9107 11.8408C61.2024 11.8408 61.4713 11.777 61.7174 11.6494C61.9635 11.5173 62.1458 11.3418 62.2643 11.123V9.85156H61.526C60.537 9.85156 60.0107 10.1934 59.9469 10.877L59.94 10.9932C59.94 11.2393 60.0266 11.4421 60.1998 11.6016C60.373 11.7611 60.61 11.8408 60.9107 11.8408Z"
        fill="#007CE4"
      />
      <path
        d="M69.4201 11.1709C69.4201 10.9294 69.2993 10.7402 69.0578 10.6035C68.8208 10.4622 68.438 10.3369 67.9094 10.2275C66.1503 9.8584 65.2707 9.111 65.2707 7.98535C65.2707 7.3291 65.5419 6.78223 66.0842 6.34473C66.6311 5.90267 67.3443 5.68164 68.2238 5.68164C69.1626 5.68164 69.9123 5.90267 70.4729 6.34473C71.038 6.78678 71.3205 7.361 71.3205 8.06738H69.3449C69.3449 7.78483 69.2538 7.55241 69.0715 7.37012C68.8892 7.18327 68.6044 7.08984 68.217 7.08984C67.8843 7.08984 67.6268 7.16504 67.4445 7.31543C67.2622 7.46582 67.1711 7.65723 67.1711 7.88965C67.1711 8.1084 67.2736 8.28613 67.4787 8.42285C67.6883 8.55501 68.0393 8.67122 68.5314 8.77148C69.0236 8.86719 69.4383 8.97656 69.7756 9.09961C70.8192 9.48242 71.341 10.1455 71.341 11.0889C71.341 11.7633 71.0516 12.3102 70.4729 12.7295C69.8941 13.1442 69.1467 13.3516 68.2307 13.3516C67.6109 13.3516 67.0594 13.2422 66.5764 13.0234C66.0979 12.8001 65.7219 12.4971 65.4484 12.1143C65.175 11.7269 65.0383 11.3099 65.0383 10.8633H66.9113C66.9296 11.2142 67.0594 11.4831 67.301 11.6699C67.5425 11.8568 67.8661 11.9502 68.2717 11.9502C68.6499 11.9502 68.9348 11.8796 69.1262 11.7383C69.3221 11.5924 69.4201 11.4033 69.4201 11.1709Z"
        fill="#007CE4"
      />
      <path
        d="M74.6346 4V5.81836H75.8992V7.26758H74.6346V10.959C74.6346 11.2324 74.687 11.4284 74.7918 11.5469C74.8966 11.6654 75.0971 11.7246 75.3934 11.7246C75.6121 11.7246 75.8058 11.7087 75.9744 11.6768V13.1738C75.587 13.2923 75.1883 13.3516 74.7781 13.3516C73.3927 13.3516 72.6863 12.652 72.659 11.2529V7.26758H71.5789V5.81836H72.659V4H74.6346Z"
        fill="#007CE4"
      />
    </svg>
  );
}
export function Spotcast(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 76 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 1H2C0.9 1 0 1.9 0 3V15C0 16.1 0.9 17 2 17H7V19H15V17H20C21.1 17 21.99 16.1 21.99 15L22 3C22 1.9 21.1 1 20 1ZM20 15H2V3H20V15Z"
        fill="#007CE4"
      />
      <path d="M8 12.5V5L15 9L8 12.5Z" fill="#007CE4" />
      <path
        d="M29.2637 11.2256C29.2637 10.8838 29.1338 10.6195 28.874 10.4326C28.6188 10.2412 28.1699 10.0771 27.5273 9.94043C26.8893 9.80371 26.3812 9.63965 26.0029 9.44824C25.6292 9.25684 25.3512 9.02897 25.1689 8.76465C24.9912 8.50033 24.9023 8.18587 24.9023 7.82129C24.9023 7.21517 25.1576 6.70247 25.668 6.2832C26.1829 5.86393 26.8392 5.6543 27.6367 5.6543C28.4753 5.6543 29.1543 5.87077 29.6738 6.30371C30.1979 6.73665 30.46 7.29036 30.46 7.96484H29.1885C29.1885 7.61849 29.0404 7.31999 28.7441 7.06934C28.4525 6.81868 28.0833 6.69336 27.6367 6.69336C27.1764 6.69336 26.8164 6.79362 26.5566 6.99414C26.2969 7.19466 26.167 7.45671 26.167 7.78027C26.167 8.08561 26.2878 8.31576 26.5293 8.4707C26.7708 8.62565 27.2061 8.77376 27.835 8.91504C28.4684 9.05631 28.9811 9.22494 29.373 9.4209C29.765 9.61686 30.0544 9.85384 30.2412 10.1318C30.4326 10.4053 30.5283 10.7402 30.5283 11.1367C30.5283 11.7975 30.264 12.3285 29.7354 12.7295C29.2067 13.126 28.5208 13.3242 27.6777 13.3242C27.0853 13.3242 26.5612 13.2194 26.1055 13.0098C25.6497 12.8001 25.292 12.5085 25.0322 12.1348C24.777 11.7565 24.6494 11.3486 24.6494 10.9111H25.9141C25.9368 11.335 26.1055 11.6722 26.4199 11.9229C26.7389 12.1689 27.1582 12.292 27.6777 12.292C28.1562 12.292 28.5391 12.1963 28.8262 12.0049C29.1178 11.8089 29.2637 11.5492 29.2637 11.2256Z"
        fill="#007CE4"
      />
      <path
        d="M38.0738 9.57129C38.0738 10.6969 37.8163 11.6038 37.3014 12.292C36.7864 12.9801 36.0891 13.3242 35.2096 13.3242C34.3118 13.3242 33.6054 13.0394 33.0904 12.4697V16.0312H31.8258V5.79102H32.9811L33.0426 6.61133C33.5576 5.97331 34.273 5.6543 35.1891 5.6543C36.0777 5.6543 36.7796 5.98926 37.2945 6.65918C37.8141 7.3291 38.0738 8.26107 38.0738 9.45508V9.57129ZM36.8092 9.42773C36.8092 8.59375 36.6314 7.93522 36.276 7.45215C35.9205 6.96908 35.4329 6.72754 34.8131 6.72754C34.0475 6.72754 33.4732 7.06706 33.0904 7.74609V11.2803C33.4687 11.9548 34.0475 12.292 34.8268 12.292C35.4329 12.292 35.9137 12.0527 36.2691 11.5742C36.6292 11.0911 36.8092 10.3757 36.8092 9.42773Z"
        fill="#007CE4"
      />
      <path
        d="M39.0021 9.4209C39.0021 8.69629 39.1434 8.0446 39.426 7.46582C39.7131 6.88704 40.1096 6.44043 40.6154 6.12598C41.1258 5.81152 41.7069 5.6543 42.3586 5.6543C43.3658 5.6543 44.1792 6.00293 44.799 6.7002C45.4234 7.39746 45.7355 8.32487 45.7355 9.48242V9.57129C45.7355 10.2913 45.5965 10.9385 45.3186 11.5127C45.0451 12.0824 44.6509 12.5267 44.1359 12.8457C43.6255 13.1647 43.0376 13.3242 42.3723 13.3242C41.3697 13.3242 40.5562 12.9756 39.9318 12.2783C39.312 11.5811 39.0021 10.6582 39.0021 9.50977V9.4209ZM40.2736 9.57129C40.2736 10.3916 40.4628 11.0501 40.841 11.5469C41.2238 12.0436 41.7342 12.292 42.3723 12.292C43.0148 12.292 43.5253 12.0413 43.9035 11.54C44.2818 11.0342 44.4709 10.3278 44.4709 9.4209C44.4709 8.6097 44.2772 7.95345 43.8898 7.45215C43.507 6.94629 42.9966 6.69336 42.3586 6.69336C41.7342 6.69336 41.2307 6.94173 40.8479 7.43848C40.465 7.93522 40.2736 8.64616 40.2736 9.57129Z"
        fill="#007CE4"
      />
      <path
        d="M48.6873 4V5.79102H50.0682V6.76855H48.6873V11.3555C48.6873 11.6517 48.7488 11.875 48.8719 12.0254C48.9949 12.1712 49.2046 12.2441 49.5008 12.2441C49.6466 12.2441 49.8471 12.2168 50.1023 12.1621V13.1875C49.7697 13.2786 49.4461 13.3242 49.1316 13.3242C48.5665 13.3242 48.1404 13.1533 47.8533 12.8115C47.5662 12.4697 47.4227 11.9844 47.4227 11.3555V6.76855H46.076V5.79102H47.4227V4H48.6873Z"
        fill="#007CE4"
      />
      <path
        d="M54.3838 11.7588C54.7484 11.7588 55.0446 11.6585 55.2725 11.458C55.5003 11.2575 55.6188 10.9909 55.6279 10.6582H57.4805C57.4759 11.1595 57.3392 11.6198 57.0703 12.0391C56.8014 12.4538 56.4323 12.7773 55.9629 13.0098C55.498 13.2376 54.9831 13.3516 54.418 13.3516C53.3607 13.3516 52.5267 13.0166 51.916 12.3467C51.3053 11.6722 51 10.7425 51 9.55762V9.42773C51 8.28841 51.3031 7.37923 51.9092 6.7002C52.5153 6.02116 53.347 5.68164 54.4043 5.68164C55.3294 5.68164 56.07 5.94596 56.626 6.47461C57.1865 6.9987 57.4714 7.69824 57.4805 8.57324H55.6279C55.6188 8.19043 55.5003 7.88053 55.2725 7.64355C55.0446 7.40202 54.7438 7.28125 54.3701 7.28125C53.9098 7.28125 53.5612 7.44987 53.3242 7.78711C53.0918 8.11979 52.9756 8.66211 52.9756 9.41406V9.61914C52.9756 10.3802 53.0918 10.9271 53.3242 11.2598C53.5566 11.5924 53.9098 11.7588 54.3838 11.7588Z"
        fill="white"
      />
      <path
        d="M62.5445 13.2148C62.4534 13.0371 62.3873 12.8161 62.3463 12.5518C61.8678 13.085 61.2457 13.3516 60.4801 13.3516C59.7555 13.3516 59.1539 13.1419 58.6754 12.7227C58.2014 12.3034 57.9645 11.7747 57.9645 11.1367C57.9645 10.3529 58.2538 9.7513 58.8326 9.33203C59.4159 8.91276 60.2568 8.70085 61.3551 8.69629H62.2643V8.27246C62.2643 7.93066 62.1754 7.65723 61.9977 7.45215C61.8245 7.24707 61.5488 7.14453 61.1705 7.14453C60.8378 7.14453 60.5758 7.22428 60.3844 7.38379C60.1975 7.54329 60.1041 7.76204 60.1041 8.04004H58.1285C58.1285 7.61165 58.2607 7.21517 58.525 6.85059C58.7893 6.486 59.163 6.20117 59.6461 5.99609C60.1292 5.78646 60.6715 5.68164 61.273 5.68164C62.1845 5.68164 62.9068 5.91178 63.44 6.37207C63.9778 6.8278 64.2467 7.47038 64.2467 8.2998V11.5059C64.2512 12.2077 64.3492 12.7386 64.5406 13.0986V13.2148H62.5445ZM60.9107 11.8408C61.2024 11.8408 61.4713 11.777 61.7174 11.6494C61.9635 11.5173 62.1458 11.3418 62.2643 11.123V9.85156H61.526C60.537 9.85156 60.0107 10.1934 59.9469 10.877L59.94 10.9932C59.94 11.2393 60.0266 11.4421 60.1998 11.6016C60.373 11.7611 60.61 11.8408 60.9107 11.8408Z"
        fill="white"
      />
      <path
        d="M69.4201 11.1709C69.4201 10.9294 69.2993 10.7402 69.0578 10.6035C68.8208 10.4622 68.438 10.3369 67.9094 10.2275C66.1503 9.8584 65.2707 9.111 65.2707 7.98535C65.2707 7.3291 65.5419 6.78223 66.0842 6.34473C66.6311 5.90267 67.3443 5.68164 68.2238 5.68164C69.1626 5.68164 69.9123 5.90267 70.4729 6.34473C71.038 6.78678 71.3205 7.361 71.3205 8.06738H69.3449C69.3449 7.78483 69.2538 7.55241 69.0715 7.37012C68.8892 7.18327 68.6044 7.08984 68.217 7.08984C67.8843 7.08984 67.6268 7.16504 67.4445 7.31543C67.2622 7.46582 67.1711 7.65723 67.1711 7.88965C67.1711 8.1084 67.2736 8.28613 67.4787 8.42285C67.6883 8.55501 68.0393 8.67122 68.5314 8.77148C69.0236 8.86719 69.4383 8.97656 69.7756 9.09961C70.8192 9.48242 71.341 10.1455 71.341 11.0889C71.341 11.7633 71.0516 12.3102 70.4729 12.7295C69.8941 13.1442 69.1467 13.3516 68.2307 13.3516C67.6109 13.3516 67.0594 13.2422 66.5764 13.0234C66.0979 12.8001 65.7219 12.4971 65.4484 12.1143C65.175 11.7269 65.0383 11.3099 65.0383 10.8633H66.9113C66.9296 11.2142 67.0594 11.4831 67.301 11.6699C67.5425 11.8568 67.8661 11.9502 68.2717 11.9502C68.6499 11.9502 68.9348 11.8796 69.1262 11.7383C69.3221 11.5924 69.4201 11.4033 69.4201 11.1709Z"
        fill="white"
      />
      <path
        d="M74.6346 4V5.81836H75.8992V7.26758H74.6346V10.959C74.6346 11.2324 74.687 11.4284 74.7918 11.5469C74.8966 11.6654 75.0971 11.7246 75.3934 11.7246C75.6121 11.7246 75.8058 11.7087 75.9744 11.6768V13.1738C75.587 13.2923 75.1883 13.3516 74.7781 13.3516C73.3927 13.3516 72.6863 12.652 72.659 11.2529V7.26758H71.5789V5.81836H72.659V4H74.6346Z"
        fill="white"
      />
    </svg>
  );
}
