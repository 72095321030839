import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { IconButton } from "@mui/material";
import clsx from "clsx";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useMemo } from "react";

import { IntercomArticle, IntercomLink } from "../Intercom/IntercomLink";

type ZendeskSuggestionType = string | string[];

export const ZendeskArticle: {
  [key: string]: ZendeskSuggestionType;
} = {
  ADDING_CAMERA: "Adding a new camera",
  ADDING_NVR: "Adding an Existing NVR",
  ADDING_USERS: "Adding Users",
  AUDIT_LOGS: "Audit Logs",
  CAMERA_GROUPS: "Camera groups",
  CAMERA_SETTINGS:
    "Changing Camera Resolutions, Rotation, and Audio and Video Settings",
  CASES: "Cases",
  CONFIGURE_STATIC_IP: "Configure static ip",
  CREATE_ALERT: "How to create alerts",
  INSTALL_APPLIANCE: "Installing Appliance",
  MAPS: "Maps",
  OFFLINE_APPLIANCE: "Troubleshooting Appliance going offline",
  OFFLINE_CAMERA: "Troubleshooting Cameras going offline",
  PTZ_SUPPORT: "PTZ Support",
  ROTATING_VIDEO_WALL: "Rotating Video Walls",
  SEGMENT_SCRUB: "How to use Segment Scrub",
  SHARE_VIDEO: "How to download, share, and save",
  SPOTCAST: "Spotcast",
  TIMELAPSE: "Timelapse and Clips on Timeline",
  VIDEO_TROUBLESHOOT: "Video is taking longer than expected to load",
  VIDEO_WALL_CUSTOMIZE: "Customize your video wall",
  ZONE_BASED_MOTION_FILTER: "How to use Zone-Based motion",
};

interface ZendeskLinkProps {
  color?:
    | "inherit"
    | "default"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning"
    | undefined;
  className?: string;
  article: ZendeskSuggestionType;
}

export function ZendeskLink({ color, className, article }: ZendeskLinkProps) {
  const { intercomSupport } = useFlags();
  const suggestionType = useMemo(
    () => (Array.isArray(article) ? "labels" : "search"),
    [article]
  );

  // TODO refactor and remove ZendeskLink once we fully roll out Intercom.
  if (intercomSupport) {
    const articleKey = Object.keys(ZendeskArticle).find((a) => {
      return (
        ZendeskArticle[a] === (Array.isArray(article) ? article[0] : article)
      );
    });

    return (
      <IntercomLink
        color={color}
        className={className}
        article={IntercomArticle[articleKey!]}
      />
    );
  }

  return (
    <IconButton
      className={clsx(className, "-mt-0.5", {
        "opacity-50 hover:opacity-100": !!color,
      })}
      color={color}
      size="small"
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
        const zendesk = window.zE;
        if (zendesk) {
          zendesk.activate();
          zendesk.setHelpCenterSuggestions({
            [suggestionType]: article,
          });
        }
      }}
    >
      <HelpOutlineIcon fontSize="small" />
    </IconButton>
  );
}
