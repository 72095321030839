import { Box, Button, Typography } from "@mui/material";
import clsx from "clsx";
import React from "react";
import { makeStyles } from "tss-react/mui";

import { Spotcast, SpotcastLight } from "@/icons/Spotcast";

import KioskInfo from "@/pages/VideoWall/Kiosk/KioskInfo";

const useStyles = makeStyles()((theme) => ({
  baseText: {
    color: "rgba(255,255,255,0.5)",
    fontSize: 18,
  },
}));

export default function KioskNoWallWelcome() {
  const { classes } = useStyles();

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      flexDirection="column"
      p={5}
    >
      <Box />
      <Box display="flex" alignItems="center" flexDirection="column">
        <Spotcast
          style={{
            width: "clamp(400px, 30vw, 1000px)",
            minHeight: 100,
          }}
        />
        <Box m={2} />
        <Typography
          variant="h5"
          component="h5"
          className={clsx(classes.baseText)}
        >
          Select a video wall from the spot dashboard and press{" "}
          <Button size="large">
            <SpotcastLight width="80" />
          </Button>
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        width="100%"
        alignItems="center"
      >
        <KioskInfo />
      </Box>
    </Box>
  );
}
