import { Chip, Typography } from "@mui/material";
import { GridColDef, useGridApiRef } from "@mui/x-data-grid";
import clsx from "clsx";
import { format as formatWithTimeZone, utcToZonedTime } from "date-fns-tz";
import { addSeconds, format, subSeconds } from "date-fns/fp";
import { capitalize, intersection } from "lodash/fp";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { ArrayParam, StringParam, useQueryParam } from "use-query-params";

import { pluralize } from "@/util/pluralize";
import { useBreakpoints } from "@/util/useBreakpoints";

import { useSearchRangeParams } from "@/pages/Search/searchHooks";

import { DataGrid } from "@/components/DataGrid/DataGrid";

import { IntegrationSource, IntegrationVendorKey } from "@/generated-models";

import { chipProps } from "../constant";
import {
  GridIntegrationEvent,
  useCurrentVendor,
  useIntegrationEvents,
} from "../hooks";
import { useIntegrationContext } from "../useIntegrationsContext";
import { IntegrationsEventPopover } from "./IntegrationsEventStills";

interface IntegrationsEventDatagridProps {
  sourceId?: number;
  onRowClick?: (
    source: IntegrationSource,
    redirectData: { pathname: string; search: string }
  ) => void;
}

export function getEventDatagridId({
  id,
  ts,
  externalId,
}: {
  id: number;
  ts: number;
  externalId: string;
}) {
  return `${id}-${ts}-${externalId}`;
}

export function IntegrationsEventDatagrid({
  sourceId,
  onRowClick,
}: IntegrationsEventDatagridProps) {
  const { rangeStart, rangeEnd } = useSearchRangeParams();
  const [searchInputParam] = useQueryParam("search", StringParam);
  const [tagsFilter] = useQueryParam("tags", ArrayParam);
  const [locationsFilter] = useQueryParam("locations", ArrayParam);
  const [datetimeInputParam] = useQueryParam("datetime", StringParam);
  const [params] = useSearchParams();
  const apiRef = useGridApiRef();
  const vendor = useCurrentVendor();
  const { setEventCount } = useIntegrationContext();
  const { data, loading: eventsLoading } = useIntegrationEvents(undefined, {
    startTime: rangeStart.toISOString(),
    endTime: rangeEnd.toISOString(),
    query: {},
  });
  const { fitsDesktop } = useBreakpoints();
  const isHalo = vendor?.key === IntegrationVendorKey.Halo;
  const isSpot = vendor?.key === IntegrationVendorKey.Spot;

  const currentSelectedId = params.get("id");

  const getEventCellColorClass = (
    isAnomalous?: boolean,
    isGood?: boolean
  ): string => {
    if (!isAnomalous && !isGood) {
      return "";
    }

    if (isGood) {
      return "text-[#2CB626]";
    }

    return "text-[#ff490f]";
  };

  const events = useMemo(() => {
    const searchQuery = searchInputParam?.toLowerCase();
    const eventValues = data
      .filter((d) => {
        if (!!sourceId && d.id !== sourceId) {
          return false;
        }

        return (
          !searchQuery ||
          d.sourceStandardMeta.name?.toLowerCase()?.includes(searchQuery) ||
          d.sourceStandardMeta.siteName?.toLowerCase()?.includes(searchQuery) ||
          d.standardMeta.entryStatus?.toLowerCase()?.includes(searchQuery) ||
          d.tags.join("_")?.toLowerCase()?.includes(searchQuery)
        );
      })
      .filter(
        (s) =>
          !locationsFilter ||
          locationsFilter.includes(String(s.sourceStandardMeta.siteName))
      )
      .filter(
        (s) => !tagsFilter || intersection(tagsFilter, s.tags).length > 0
      );

    setEventCount(eventValues.length);

    return eventValues;
  }, [
    data,
    locationsFilter,
    searchInputParam,
    sourceId,
    tagsFilter,
    setEventCount,
  ]);

  const loading = eventsLoading;

  return (
    <>
      <DataGrid
        apiRef={apiRef}
        getRowId={(data) => getEventDatagridId(data)}
        rowHeight={fitsDesktop ? 48 : 110}
        rows={events}
        loading={loading}
        classes={{
          cell: "outline-none",
        }}
        initialState={{
          pagination: { paginationModel: { pageSize: 100 } },
          sorting: { sortModel: [{ field: "ts", sort: "desc" }] },
        }}
        columnVisibilityModel={{
          accessPoint: fitsDesktop && !sourceId,
          location: fitsDesktop && !sourceId && !isSpot,
          tags: fitsDesktop && (!sourceId || isSpot),
          eventType: fitsDesktop,
          personId: fitsDesktop,
          // TODO: Remove this and create a proper way to display columns based on integration
          // Quickfix for demo
          personName: fitsDesktop && !isHalo && !isSpot,
          linkedCameras: fitsDesktop && !sourceId && isSpot,
        }}
        getRowClassName={(params) => {
          if (params.id === currentSelectedId) {
            return "bg-primary text-white";
          }
          return "cursor-pointer";
        }}
        onRowClick={({ row }) => {
          const id = getEventDatagridId(row);
          const eventTimestamp = new Date(row.ts);
          const { start, end } = getClipDateRange(vendor?.key, eventTimestamp);

          if (onRowClick && id !== currentSelectedId) {
            onRowClick(row, {
              pathname: `./device/${row.id}`,
              search: `vod=${start.toISOString()}|${end.toISOString()}&id=${id}&hideMultiCamRemove=1&hideMultiCamZone=1${
                datetimeInputParam ? `&datetime=${datetimeInputParam}` : ""
              }`,
            });
          }
        }}
        columns={
          [
            {
              field: "ts",
              headerName: "Time",
              minWidth: 150,
              flex: 1,
              cellClassName: (params) => {
                return getEventCellColorClass(
                  params.row.standardMeta.isAnomalous
                );
              },
              renderCell: ({ row, value }) => {
                const location = row.cameras[0]?.location;
                const timezone = location?.timezone as string | undefined;
                const zonedTime = timezone
                  ? utcToZonedTime(new Date(value), timezone)
                  : undefined;
                const time =
                  timezone && zonedTime
                    ? formatWithTimeZone(zonedTime, "hh:mmaaa", {
                        timeZone: timezone,
                      })
                    : format("hh:mmaaa", value);
                const date =
                  timezone && zonedTime
                    ? formatWithTimeZone(zonedTime, "MMM dd, yyyy", {
                        timeZone: timezone,
                      })
                    : format("MMM dd, yyyy", value);

                if (!fitsDesktop) {
                  const entryStatus = row.standardMeta?.entryStatus;
                  const firstRow = [
                    row.sourceStandardMeta.name,
                    row.sourceStandardMeta.siteName,
                    isSpot ? null : location?.name,
                  ].filter(Boolean);
                  const secondRow = [
                    entryStatus && capitalize(entryStatus.toLowerCase()),
                    row.standardMeta?.event,
                    row.standardMeta?.personName,
                  ].filter(Boolean);
                  return (
                    <div className="flex flex-col gap-2 items-start justify-center">
                      <div>
                        <span>
                          <strong>
                            {date}, {time}
                          </strong>
                        </span>
                      </div>
                      <Typography className="text-sm leading-[16.41px]">
                        {firstRow.join(" | ")}
                      </Typography>
                      {!isSpot && (
                        <Typography className="text-sm leading-[16.41px]">
                          {secondRow.join(" | ")}
                        </Typography>
                      )}
                      {isSpot && (
                        <Typography
                          className={clsx(
                            "text-sm leading-[16.41px]",
                            getEventCellColorClass(
                              row.standardMeta?.normallyClosed
                                ? row.standardMeta?.value
                                : !row.standardMeta?.value,
                              row.standardMeta?.normallyClosed
                                ? !row.standardMeta?.value
                                : row.standardMeta?.value
                            )
                          )}
                        >
                          {row.standardMeta?.eventLabel}
                        </Typography>
                      )}
                    </div>
                  );
                }

                if (sourceId) {
                  return (
                    <span>
                      {date}
                      <br />
                      <strong>{time}</strong>
                    </span>
                  );
                }

                return (
                  <span>
                    {date} <strong>{time}</strong>
                  </span>
                );
              },
            },
            {
              field: "accessPoint",
              headerName: getDeviceHeaderName(vendor?.key),
              flex: 1,
              cellClassName: (params) => {
                return getEventCellColorClass(
                  params.row.standardMeta.isAnomalous
                );
              },
              valueGetter: ({ row }) => {
                return row.sourceStandardMeta.name;
              },
            },
            {
              field: "location",
              headerName: "Location",

              flex: 1,
              cellClassName: (params) => {
                return getEventCellColorClass(
                  params.row.standardMeta.isAnomalous
                );
              },
              valueGetter: ({ row }) => {
                return (
                  row.sourceStandardMeta.siteName ??
                  row.cameras[0]?.location.name
                );
              },
            },
            {
              field: "eventType",
              headerName: "Event Type",
              flex: 1,
              cellClassName: (params) => {
                if (isSpot) {
                  const inverted = params.row.standardMeta.normallyClosed;
                  const value = params.row.standardMeta.value;
                  return getEventCellColorClass(
                    inverted ? value : !value,
                    inverted ? !value : value
                  );
                }
                return getEventCellColorClass(
                  params.row.standardMeta.isAnomalous
                );
              },
              valueGetter: ({ row }) => {
                if (isSpot) {
                  return row.standardMeta.eventLabel;
                }
                if (!row.standardMeta.formattedEntryStatus) {
                  return row.standardMeta.event;
                }
                return row.standardMeta.formattedEntryStatus;
              },
            },
            {
              field: "tags",
              headerName: "Tags",
              flex: 1,
              renderCell: ({ row }) => {
                return (
                  <div className="flex items-center gap-1">
                    {row.tags.map((tag) => (
                      <Chip key={tag} label={tag} {...chipProps} />
                    ))}
                  </div>
                );
              },
            },
            {
              field: "linkedCameras",
              headerName: "Cameras",
              flex: 1,
              valueGetter: ({ row }) => {
                const cameraCount = row.cameras.length;
                return pluralize(
                  {
                    1: `1 Linked Camera`,
                    multi: `${cameraCount} Linked Cameras`,
                  },
                  cameraCount
                );
              },
            },
            {
              field: "personName",
              headerName: "User",
              flex: 1,
              cellClassName: (params) => {
                return getEventCellColorClass(
                  params.row.standardMeta.isAnomalous
                );
              },
              renderCell: ({ row }) => {
                const personName =
                  row.standardMeta.personName ?? "Unknown Person";
                if (sourceId) {
                  const [firstName, lastName] = personName.split(" ");
                  return (
                    <span>
                      {firstName}
                      <br />
                      {lastName}
                    </span>
                  );
                }

                return <span>{personName}</span>;
              },
            },
          ] as GridColDef<GridIntegrationEvent>[]
        }
      />
      {fitsDesktop && !sourceId && <IntegrationsEventPopover apiRef={apiRef} />}
    </>
  );
}

function getDeviceHeaderName(vendorKey?: IntegrationVendorKey) {
  switch (vendorKey) {
    case IntegrationVendorKey.Spot:
      return "Input";
    case IntegrationVendorKey.Halo:
      return "Sensor";
    default:
      return "Access Point";
  }
}

export function getClipDateRange(
  integrationVendorKey: IntegrationVendorKey | undefined,
  eventTimestamp: Date
): { start: Date; end: Date } {
  let secondsPrecedingEvent: number;
  let secondsFollowingEvent: number;

  switch (integrationVendorKey) {
    case IntegrationVendorKey.Halo: {
      secondsPrecedingEvent = 60;
      secondsFollowingEvent = 60;
      break;
    }
    case IntegrationVendorKey.AvigilonAlta: {
      secondsPrecedingEvent = 5;
      secondsFollowingEvent = 15;
      break;
    }
    case IntegrationVendorKey.Brivo: {
      secondsPrecedingEvent = 5;
      secondsFollowingEvent = 15;
      break;
    }
    default: {
      secondsPrecedingEvent = 60;
      secondsFollowingEvent = 60;
      break;
    }
  }
  const start = subSeconds(secondsPrecedingEvent, eventTimestamp);
  const end = addSeconds(secondsFollowingEvent, eventTimestamp);
  return { start, end };
}
