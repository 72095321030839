import { Skeleton } from "@mui/material";
import { useLocalStorageValue } from "@react-hookz/web";
import { useCallback, useMemo } from "react";
import { WidthProvider, Responsive } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import { ReactComponent as ChartEmptyStateIcon } from "@/icons/chart_empty_state.svg";

import {
  MaintainMetricsConfig,
  Page_Maintain_DashboardQuery,
} from "@/generated-models";

import { MetricAddResourceButton } from "../Core/MetricAddResource/MetricAddResourceButton";
import { useMaintainDashboard } from "../hooks";
import { MaintainMetricsChart } from "./MaintainMetricsChart";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

function getLayoutItem(idx: number) {
  return {
    w: 4,
    h: 4,
    x: idx % 2 === 0 ? 0 : 4,
    y: 0,
    minW: 4,
    minH: 4,
  };
}

function MetricsChartEmptyState() {
  return (
    <div className="flex items-center justify-center py-6 px-14 mt-6 relative">
      <MetricAddResourceButton className="absolute" />
      <ChartEmptyStateIcon />
    </div>
  );
}

function MaintainMetricsChartsContainerLayout({
  id,
  metrics,
}: {
  id: number;
  metrics?: MaintainMetricsConfig[];
}) {
  const [layout, setLayout] = useLocalStorageValue<ReactGridLayout.Layouts>(
    `maintain-layout-${id}`,
    {}
  );
  const handleLayoutChange = useCallback(
    (_: ReactGridLayout.Layout[], layouts: ReactGridLayout.Layouts) => {
      window.dispatchEvent(new Event("resize"));
      setLayout(layouts);
    },
    [setLayout]
  );

  return (
    <ResponsiveReactGridLayout
      cols={{ lg: 8, md: 8, sm: 4, xs: 4, xxs: 4 }}
      rowHeight={80}
      layouts={layout}
      onLayoutChange={handleLayoutChange}
    >
      {metrics?.map((m, idx) => (
        <div
          key={m.field}
          data-grid={getLayoutItem(idx)}
          className="border border-solid border-[#EDEDED] rounded-lg h-full bg-white"
        >
          <MaintainMetricsChart metric={m} />
        </div>
      ))}
    </ResponsiveReactGridLayout>
  );
}

function isEmptyChart(
  dashboard?: Page_Maintain_DashboardQuery["maintainDashboard"]
) {
  if (!dashboard) return false;
  return (
    dashboard.appliances.length === 0 &&
    dashboard.cameras.length === 0 &&
    dashboard.locations.length === 0
  );
}

export function MaintainMetricsChartsContainer() {
  const { data, loading } = useMaintainDashboard();
  const dashboard = data?.maintainDashboard;

  const metrics = useMemo(() => {
    return dashboard?.metrics.filter((f) =>
      dashboard?.settings.metrics.includes(f.field)
    );
  }, [dashboard?.metrics, dashboard?.settings.metrics]);

  if (loading) {
    return (
      <Skeleton
        variant="rectangular"
        className="py-6 px-14 mt-10 rounded-md h-[153px]"
      />
    );
  }

  if (!dashboard?.id || isEmptyChart(dashboard)) {
    return <MetricsChartEmptyState />;
  }

  return (
    <MaintainMetricsChartsContainerLayout id={dashboard.id} metrics={metrics} />
  );
}
