import { Typography } from "@mui/material";
import clsx from "clsx";
import { motion } from "framer-motion";
import { MutableRefObject, useRef } from "react";
import { useDraggable } from "react-use-draggable-scroll";

import { IntelligenceFeatureType } from "@/pages/Intelligence/constants";

import {
  TutorialType,
  getDefaultCopilotNavigateParams,
  useCopilotNavigate,
} from "./copilotPlayerSectionHooks";

interface CopilotSectionCalloutProps {
  tagline: string;
  description: string;
  selected?: boolean;
  onClick: () => void;
}

export function CopilotSectionCallout({
  tagline,
  description,
  selected,
  ...props
}: CopilotSectionCalloutProps) {
  return (
    <motion.button
      type="button"
      className={clsx(
        "rounded-[56px] px-[11px] h-9 py-1 bg-black/50 border border-solid border-[#4C076C]/50 hover:border-[#B029Ef]/50 hover:bg-[#291B46]/50 flex items-center justify-center min-w-[230px] transition duration-300 ease-in-out",
        {
          "shadow-[0_0_9px_0_rgb(0,0,0)] bg-[#291B46] border-[#B029Ef]": selected,
        }
      )}
      initial="initial"
      animate="animate"
      variants={{
        initial: {
          opacity: 0,
        },
        animate: {
          opacity: 1,
        },
      }}
      transition={{ duration: 0.5 }}
      {...props}
    >
      <Typography className="text-[13px] leading-[13px] text-[#E6B0FF] text-center">
        <strong className="text-white">{tagline}</strong>{" "}
        {description ? "-" : ""} {description}
      </Typography>
    </motion.button>
  );
}

export function CopilotSectionCalloutLayout() {
  const ref = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  const { events } = useDraggable(ref);
  const [navigate, setNavigate] = useCopilotNavigate();

  const peopleSelected =
    navigate.tutorialSubject === TutorialType.person &&
    navigate.searchFeature === "people";

  const vehicleSelected =
    navigate.tutorialSubject === TutorialType.vehicle &&
    navigate.insightFeature === IntelligenceFeatureType.VehicleIdleTime;

  const customObjectSelected = navigate.tutorialSubject === TutorialType.object;

  function onChange(selected: boolean, newState: Record<string, string>) {
    setNavigate({
      ...getDefaultCopilotNavigateParams(),
      ...(selected ? {} : newState),
    });
  }

  return (
    <div
      className="flex items-center overflow-x-scroll no-scrollbar gap-1 px-8 w-full"
      ref={ref}
      {...events}
    >
      <motion.div
        className="text-[13px] font-barlow leading-normal text-[#E6B0FF] w-[72px] whitespace-nowrap mr-2"
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
      >
        Things to try
      </motion.div>

      <CopilotSectionCallout
        key="people"
        tagline="Rapidly review incidents"
        description="search footage for people"
        selected={peopleSelected}
        onClick={() => {
          onChange(peopleSelected, {
            searchFeature: "people",
            tutorialSubject: TutorialType.person,
          });
        }}
      />

      <CopilotSectionCallout
        key="vehicle"
        tagline="Improve efficiency"
        description="with vehicle count, idle time insights"
        selected={vehicleSelected}
        onClick={() => {
          onChange(vehicleSelected, {
            insightFeature: IntelligenceFeatureType.VehicleIdleTime,
            tutorialSubject: TutorialType.vehicle,
          });
        }}
      />

      <CopilotSectionCallout
        key="custom-object"
        tagline="Coach AI Copilot"
        description="give feedback on what you want AI Copilot to understand"
        selected={customObjectSelected}
        onClick={() => {
          onChange(customObjectSelected, {
            tutorialSubject: TutorialType.object,
          });
        }}
      />
    </div>
  );
}
