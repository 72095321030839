import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Typography,
  InputLabel,
  InputBase,
  styled,
  Button,
  ButtonGroup,
} from "@mui/material";
import clsx from "clsx";
import { Formik, Form } from "formik";
import { useState } from "react";
import * as yup from "yup";

import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";

import {
  useIntegrationSetupStateLazyQuery,
  useSetupIntegrationMutation,
} from "@/generated-models";

import { CFG_FAILED_MSG, CFG_SUCCESS_MSG, IOBoardStatus } from "../constant";
import { INTEGRATION_SETUP_STATE_QUERY } from "../hooks";

interface IntegrationsVendorFormProps {
  id: number;
  schema?: any;
  className?: string;
  onConnectionResult?: (success: boolean) => void;
  editMode?: boolean;
  setupState?: any;
  initialState?: any;
  callback?: () => void;
  onBack?: () => void;
}

interface VendorFormValues {
  macAddress: string;
}

const macAddressRegex = /^([0-9A-Fa-f]{2}[:-]?){5}([0-9A-Fa-f]{2})$/;

const StyledInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 8,
    position: "relative",
    backgroundColor: "white",
    border: "1px solid rgba(53, 61, 72, 0.36)",
    fontSize: 14,
    width: "100%",
    padding: "12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
  },
}));

const validationSchema = yup.array().of(
  yup.object().shape({
    macAddress: yup
      .string()
      .required("Required")
      .matches(macAddressRegex, "Invalid MAC address"),
  })
);

export function IntegrationsSpotAIVendorForm({
  id,
  className,
  onConnectionResult = () => {},
  setupState,
  initialState,
  callback,
  onBack,
  editMode,
}: IntegrationsVendorFormProps) {
  const [boardState, setBoardState] = useState<IOBoardStatus | null>(null);

  const { pushSnackbar } = useFeedback();

  const [
    getSetupStateOnDemand,
    { loading: fetchingSetupState },
  ] = useIntegrationSetupStateLazyQuery({
    fetchPolicy: "network-only",
  });

  const [setupIntegration, { loading }] = useSetupIntegrationMutation({
    refetchQueries: [
      { query: INTEGRATION_SETUP_STATE_QUERY, variables: { input: { id } } },
      "integration",
    ],
    onCompleted: async (blah) => {
      const resultingSetupState = await getSetupStateOnDemand({
        variables: {
          input: {
            id,
          },
        },
      });

      const boardStatus =
        resultingSetupState.data?.integration?.setupState.boardStatus?.status;

      if (boardStatus === IOBoardStatus.Connected) {
        pushSnackbar(CFG_SUCCESS_MSG, FeedbackType.Success);
      } else {
        pushSnackbar(CFG_FAILED_MSG, FeedbackType.Error);
      }
      onConnectionResult(true);
      setBoardState(boardStatus);
    },
    onError: (e) => {
      pushSnackbar(e.message || CFG_FAILED_MSG, FeedbackType.Error);
      setBoardState(null);
      onConnectionResult(false);
    },
  });

  const handleSubmit = (values: any) => {
    if (!macAddressRegex.test(values.macAddress)) {
      return pushSnackbar(
        `Invalid Mac Address. Must match pattern "^([0-9A-Fa-f]{2}[:-]?){5}([0-9A-Fa-f]{2})$"`,
        FeedbackType.Error
      );
    }

    setBoardState(null);
    setupIntegration({
      variables: {
        input: {
          integrationId: id,
          setupState: {
            macAddress: values.macAddress,
            inputs: [],
          },
        },
      },
    });
  };

  const initialValues: VendorFormValues = {
    macAddress: setupState?.macAddress ?? "",
  };

  return (
    <div className={className}>
      <Formik
        onSubmit={(values) => handleSubmit(values)}
        validationSchema={validationSchema}
        initialValues={initialValues}
      >
        {({ values, setValues }) => (
          <Form>
            <div className="spotIntegrationSetup">
              <div className="max-w-xl">
                <div className="flex flex-col gap-3 items-start">
                  <div className="w-full">
                    <InputLabel shrink htmlFor="mac">
                      MAC Address
                    </InputLabel>
                    <StyledInput
                      fullWidth
                      id="mac"
                      name="mac"
                      value={values.macAddress}
                      onChange={(e) => {
                        setValues({
                          macAddress: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <Button
                    type="submit"
                    className="shadow-none font-normal text-base rounded-lg h-11 px-[70px] w-auto"
                    variant="contained"
                    color="primary"
                    disabled={
                      fetchingSetupState ||
                      loading ||
                      !values.macAddress ||
                      values.macAddress.length < 12
                    }
                  >
                    {fetchingSetupState
                      ? "Validating..."
                      : "Validate Connection"}
                  </Button>
                </div>
              </div>
            </div>
            <ButtonGroup className="mt-7 flex gap-2">
              {!editMode && (
                <Button
                  variant="contained"
                  className="shadow-none bg-[#DAEEFF] text-[#007CE4] h-9 text-sm leading-6 font-normal rounded-[6px]"
                  onClick={onBack}
                >
                  Back
                </Button>
              )}
              <Button
                onClick={() => {
                  if (callback) {
                    callback();
                  }
                }}
                className="shadow-none font-normal text-sm leading-6 rounded-lg h-9 px-10 w-auto"
                variant="contained"
                color="primary"
                disabled={
                  boardState !== IOBoardStatus.Connected ||
                  values.macAddress.length === 0 ||
                  loading ||
                  (editMode && setupState.macAddress === values.macAddress)
                }
              >
                {editMode ? "Save" : "Next"}
              </Button>
            </ButtonGroup>
          </Form>
        )}
      </Formik>
      <ValidationResultIndicator boardState={boardState} />
    </div>
  );
}

function ValidationResultIndicator({
  boardState,
}: {
  boardState: IOBoardStatus | null;
}) {
  if (!boardState) return <></>;

  let message;
  let icon;

  switch (boardState) {
    case IOBoardStatus.Connected:
      icon = <CheckCircleIcon />;
      message = "Connection Successful";
      break;
    case IOBoardStatus.Disconnected:
      icon = <CheckCircleIcon />;
      message = "Device Disconnected";
      break;
    default:
      icon = <HelpOutlineIcon />;
      message = "Device Not Found";
  }

  return (
    <Typography
      className={clsx(
        "flex items-center justify-start gap-[6px] mt-[18px] text-lg leading-[22px]",
        {
          "text-[#18D710]": boardState === IOBoardStatus.Connected,
          "text-[#FF490F]": boardState !== IOBoardStatus.Connected,
        }
      )}
    >
      {icon}
      {message}
    </Typography>
  );
}
