import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
  capitalize,
} from "@mui/material";
import { Form, Formik } from "formik";

import {
  useCreateDashboardIfNeeded,
  useMaintainDashboard,
  useMaintainMetricsDashboardType,
  useUpdateMaintainDashboard,
} from "../../hooks";
import { MetricAddResourceDatagrid } from "./MetricAddResourceDatagrid";

export function MetricAddResourceModal({ open, onClose }: any) {
  const [type] = useMaintainMetricsDashboardType();
  const createDashboardIfNeeded = useCreateDashboardIfNeeded();
  const update = useUpdateMaintainDashboard();

  const { data } = useMaintainDashboard();

  return (
    <Dialog
      hideBackdrop
      open={open}
      onClose={onClose}
      classes={{
        paper: "rounded-lg max-w-2xl",
      }}
    >
      <Formik
        enableReinitialize
        initialValues={{
          resourceIds: data?.maintainDashboard?.resourceIds || [],
        }}
        onSubmit={async ({ resourceIds }, helpers) => {
          const dashboard = await createDashboardIfNeeded();
          await update(
            resourceIds,
            null,
            dashboard?.data?.createMaintainDashboard.id
          );
          onClose();
          helpers.resetForm();
        }}
      >
        {({ dirty, isSubmitting, resetForm }) => (
          <Form>
            <DialogTitle className="flex justify-between items-center p-4">
              <Typography className="text-lg leading-5 font-bold">
                Update {capitalize(type)}s
              </Typography>
              <IconButton
                size="small"
                onClick={onClose}
                className="text-text text-base leading-5 font-normal"
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent className="py-0 px-0">
              <MetricAddResourceDatagrid />
            </DialogContent>
            <DialogActions className="p-4">
              <Button
                disabled={!dirty || isSubmitting}
                color="primary"
                className="font-bold"
                onClick={() => {
                  resetForm();
                }}
              >
                Reset
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="font-bold shadow-none rounded-md"
                disabled={!dirty || isSubmitting}
              >
                Save Selected {capitalize(type)}s
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
