import { ReactComponent as Forklift } from "@/icons/forklift.svg";
import { ReactComponent as Person } from "@/icons/peoplefilter.svg";

import { pluralize } from "@/util/pluralize";

import { MotionIcon, VehiclesIcon } from "../icons/SearchIcons";

export type IntelligentFilterConfiguration = typeof intelligentFiltersConfig[keyof typeof intelligentFiltersConfig];

export const intelligentFiltersConfig = {
  motion: {
    id: "motion" as const,
    title: "Motion",
    pluralize: (count: number) =>
      pluralize(
        {
          1: `motion event`,
          multi: `motion events`,
        },
        count
      ),
    icon: <MotionIcon />,
    color: (opacity: number = 1) => `rgba(152, 0, 223, ${opacity})`,
    objectIds: ["m", "legacyMotion"],
    objectConfidenceThreshold: [0.1, 0.1],
  },
  people: {
    id: "people" as const,
    title: "People",
    pluralize: (count: number) =>
      pluralize(
        {
          1: `person`,
          multi: `people`,
        },
        count
      ),
    icon: <Person />,
    color: (opacity: number = 1) => `rgba(101, 0, 228, ${opacity})`,
    objectIds: ["0"],
    objectConfidenceThreshold: [0.1],
  },
  vehicle: {
    id: "vehicle" as const,
    title: "Vehicles",
    pluralize: (count: number) =>
      pluralize(
        {
          1: `vehicle`,
          multi: `vehicles`,
        },
        count
      ),
    icon: <VehiclesIcon />,
    color: (opacity: number = 1) => `rgba(0, 175, 214, ${opacity})`,
    objectIds: ["2", "5", "7"],
    objectConfidenceThreshold: [0, 0.5, 0.5],
  },
  forklift: {
    id: "forklift" as const,
    title: "Forklift",
    pluralize: (count: number) =>
      pluralize(
        {
          1: `forklift`,
          multi: `forklifts`,
        },
        count
      ),
    icon: <Forklift />,
    color: (opacity: number = 1) => `rgba(224, 121, 1, ${opacity})`,
    objectIds: ["1"],
    objectConfidenceThreshold: [0, 0.5, 0.5],
  },
};

export const idToFilterConfigMap = Object.fromEntries(
  Object.entries(intelligentFiltersConfig).flatMap(([_, config]) =>
    config.objectIds.map((id) => [id, config])
  )
);
