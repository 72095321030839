import MuiAlert from "@mui/material/Alert";
import React, { useState } from "react";

import { useMe } from "@/components/Auth";

import { OrgType } from "@/generated-models";

export function PHIWarning({ style }: { style?: React.CSSProperties }) {
  const me = useMe();
  const [open, setOpen] = useState(true);
  if (open && me?.organization.type === OrgType.Private) {
    return (
      <MuiAlert
        severity="info"
        onClose={() => setOpen(false)}
        style={{ padding: "2px 16px", ...style }}
      >
        Health Care Facilities - Please do not input any patient information
      </MuiAlert>
    );
  }
  return null;
}
