import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, TextField, Tooltip } from "@mui/material";
import gql from "graphql-tag";
import { useState } from "react";

import {
  DetectedSubject,
  useUpdateDetectedSubjectMutation,
} from "@/generated-models";

interface EditableSubjectNameProps {
  subject: Pick<DetectedSubject, "id" | "label">;
}

export function EditableSubjectName({ subject }: EditableSubjectNameProps) {
  const [editing, setEditing] = useState(false);
  const [newLabel, setNewLabel] = useState(subject.label);
  const [update] = useUpdateDetectedSubjectMutation({
    variables: { id: subject.id, input: { label: newLabel || null } },
    optimisticResponse: {
      __typename: "Mutation",
      updateDetectedSubject: {
        __typename: "DetectedSubject",
        id: subject.id,
        label: newLabel || null,
      },
    },
  });
  const save = () => {
    if (newLabel !== subject.label) update();
    setEditing(false);
  };

  return (
    <div className="flex-center gap-1 text-sm font-bold">
      {editing ? (
        <TextField
          size="small"
          fullWidth
          autoFocus
          value={newLabel}
          onChange={(event) => setNewLabel(event.target.value)}
          onKeyDown={(event) => {
            if (event.key === "Enter") save();
          }}
        />
      ) : (
        subject.label ?? `Person ${subject.id}`
      )}
      {editing ? (
        <Tooltip title="Save">
          <IconButton onClick={save} size="small">
            <CheckIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Edit">
          <IconButton onClick={() => setEditing(true)} size="small">
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
}

gql`
  mutation updateDetectedSubject(
    $id: Int!
    $input: UpdateDetectedSubjectInput!
  ) {
    updateDetectedSubject(id: $id, input: $input) {
      id
      label
    }
  }
`;
