import { atom, useAtomValue } from "jotai";
import { atomFamily } from "jotai/utils";
import { Room } from "livekit-client";

export const livekitRoomState = atomFamily((url: string) => {
  const roomAtom = atom(null as Room | null);
  roomAtom.debugLabel = `roomAtom:${url}`;
  return roomAtom;
});

export function useLivekitRoom(url: string) {
  return useAtomValue(livekitRoomState(url));
}
