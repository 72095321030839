import { CircularProgress } from "@mui/material";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  IntelligenceDashboardType,
  useIntelligencePresenceDashboardContentQuery,
} from "@/generated-models";

import { IntervalGraph } from "../IntervalGraph";
import {
  EntityColors,
  EntityLabel,
  IntelligenceFeatureType,
} from "../constants";
import { getObjectLabel, getObjectTypeIcon } from "../utils";
import { PresencePercentChart } from "./PresenceDashboardDataLoader";

export function PresenceCardPreview({
  id,
  entityCount,
  timezone,
  chartDate,
  feature,
  objectTypes = [],
}: {
  id: number;
  objectTypes?: string[] | string | null;
  entityCount: number;
  timezone: string;
  chartDate: string;
  feature: IntelligenceFeatureType;
}) {
  const {
    data: metrics,
    error,
    loading,
  } = useIntelligencePresenceDashboardContentQuery({
    ...refetchOnMountPolicy,
    variables: {
      id,
      startDate: chartDate,
      endDate: chartDate,
      bucketSizeSeconds: 86400,
    },
  });

  const entity = EntityLabel[feature];

  if (error) {
    return (
      <div className="h-40 md:h-52 flex-center">
        <div>No available data</div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full w-full">
        <CircularProgress />
      </div>
    );
  }

  const typeString = getObjectLabel(
    objectTypes,
    IntelligenceDashboardType.Presence
  );
  const TypeIcon = getObjectTypeIcon(
    objectTypes,
    IntelligenceDashboardType.Presence
  );

  return !!metrics?.intelligenceDashboard?.presence.results!.presenceBuckets
    .length ? (
    <div className="flex flex-col gap-2">
      <div>
        <PresencePercentChart
          objectTypes={objectTypes}
          entityCount={entityCount}
          label={typeString}
          feature={feature}
          variant="small"
          totalMsec={metrics.intelligenceDashboard.presence.results.totalMsec}
          totalMsecPresent={
            metrics.intelligenceDashboard.presence.results.totalMsecPresent
          }
          TypeIcon={TypeIcon}
        />
      </div>
      <IntervalGraph
        intervalBarStyle={{ background: EntityColors[entity][1] }}
        variant="small"
        intervals={
          metrics?.intelligenceDashboard?.presence.results.presenceBuckets[0]
            .intervals
        }
        graphStartMs={
          metrics?.intelligenceDashboard?.presence.results.presenceBuckets[0]
            .bucketMs
        }
        graphEndMs={
          metrics?.intelligenceDashboard?.presence.results.presenceBuckets[0]
            .bucketEndMs
        }
        timezone={timezone}
      />
    </div>
  ) : (
    <div className="h-40 md:h-52 flex-center">
      <div>No available data</div>
    </div>
  );
}
