import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { usePrevious } from "react-use";

import { BufferingInfo } from "./BufferingInfo";
import {
  defaultPlayerId,
  qualityLevelsState,
  useIsBuffering,
  usePlayerControls,
} from "./PlayerBase";
import { useSetPlayerSetting } from "./playerControlsMachine";
import { useVisualQuality } from "./playerMachine";

interface HighestQualityOverlayProps {
  playerId?: string;
  onFinish: (qualityWarning?: string | null) => void;
}

// An overlay that can be utilized to force the player into
// it's highest quality level. Structured as a component in order
// to easily handle cleanup logic (to revert back to auto level).
export function HighestQualityOverlay({
  playerId = defaultPlayerId,
  onFinish,
}: HighestQualityOverlayProps) {
  const [qualityWarning, setQualityWarning] = useState<string | null>(null);

  const visualQuality = useVisualQuality();
  const qualityLevels = useAtomValue(qualityLevelsState);
  const setPlayerSetting = useSetPlayerSetting();
  const buffering = useIsBuffering(playerId);
  const prevBuffering = usePrevious(buffering) || false;
  const highestQualityLevel = getHighestQualityLevel(qualityLevels);
  const { forceQuality, getPlayerElement } = usePlayerControls(playerId);

  const player = getPlayerElement();
  const containerEl = player?.parentElement;

  useEffect(() => {
    // Set highest quality level
    if (!highestQualityLevel) {
      onFinish(visualQuality < 720 ? `${visualQuality}p` : null);
      return;
    }
    setPlayerSetting("quality", `${highestQualityLevel.height}p`);

    if (highestQualityLevel.height < 720) {
      setQualityWarning(`${highestQualityLevel.height}p`);
    }

    // Make sure the player will zoom with highest resolution
    if (!visualQuality || visualQuality !== highestQualityLevel.height) {
      forceQuality(`${highestQualityLevel.height}p`, () => {});
    } else {
      onFinish();
    }

    // toggle the currentTime to force the new resolution
    if (player) {
      const old = player.currentTime;
      const adjust = old >= 1 ? 1 : -1;
      player.currentTime = old - adjust;
      player.currentTime = old;
    }

    return () => {
      setPlayerSetting("quality", "auto");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Mark quality change as complete once buffering is done.
  useEffect(() => {
    if (
      prevBuffering !== buffering &&
      !buffering &&
      visualQuality === highestQualityLevel?.height
    ) {
      onFinish(qualityWarning);
    }
  }, [
    buffering,
    highestQualityLevel?.height,
    onFinish,
    prevBuffering,
    qualityWarning,
    visualQuality,
  ]);

  return (
    <>
      {buffering && containerEl && (
        <BufferingInfo
          title="Buffering highest resolution for snapshot"
          element={containerEl}
        />
      )}
    </>
  );
}

/** Returns index of player quality level with highest bitrate */
function getHighestQualityLevel(
  levels: { bitrate?: number; label: string; width: number; height: number }[]
) {
  let result: typeof levels[number] | undefined;
  levels.forEach((l) => {
    if (!l.bitrate) return;
    if (result && result.bitrate! >= l.bitrate) return;
    result = l;
  });
  return result;
}
