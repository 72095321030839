import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  AutocompleteRenderInputParams,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { Autocomplete, TextField as FormikTextField } from "formik-mui";
import gql from "graphql-tag";
import { omit } from "lodash/fp";
import { useState } from "react";

import { isPTZSupported } from "@/util/isPTZSupported";

import { useMe } from "@/components/Auth";
import { ErrorMessage } from "@/components/ErrorMessage";
import { getValidationErrors } from "@/components/Genius/Forms/utils";
import { useGenius } from "@/components/Genius/GeniusProvider";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";

import {
  ConnectionValidation,
  LifecycleStates,
  useCameraCredentialsByIdQuery,
  useUpdateDeviceMutation,
} from "@/generated-models";
import { usePermissions } from "@/hooks/usePermissions";
import { ContentWrapper } from "@/layout/ContentWrapper";

export const defaultTextProps: TextFieldProps & {
  component: typeof FormikTextField;
} = {
  disabled: true,
  fullWidth: true,
  size: "small",
  InputProps: {
    classes: {
      root: "font-normal",
    },
  },
  InputLabelProps: {
    shrink: true,
    className: "text-sm",
  },
  FormHelperTextProps: {
    className: "mt-0 text-[10px]",
  },
  component: FormikTextField,
};

interface MaintainConnectionAuthFormProps {
  cameraId: number;
}

export function MaintainConnectionAuthForm({
  cameraId,
}: MaintainConnectionAuthFormProps) {
  const { loading, data, error } = useCameraCredentialsByIdQuery({
    variables: { cameraId },
  });

  const camera = data?.camera;
  const device = camera?.device;

  const [showPassword, setShowPassword] = useState(false);
  const { pushSnackbar } = useFeedback();
  const [geniusScan] = useGenius();
  const me = useMe();
  const hasPermission = usePermissions();

  const [updateDevice] = useUpdateDeviceMutation({
    onError: (error) => {
      console.error(error);
      pushSnackbar(
        "Something went wrong, please try again...",
        FeedbackType.Error
      );
    },
  });

  if (error) {
    return (
      <ContentWrapper>
        <ErrorMessage title="Error" description={error?.message} />
      </ContentWrapper>
    );
  }

  if (!device) {
    if (!loading) {
      return (
        <ErrorMessage
          title="Unable to fetch camera data"
          description="Please try again later"
        />
      );
    }
    return <CircularProgress />;
  }

  const {
    id,
    ip,
    port,
    path,
    username,
    password,
    onvifUsername,
    onvifPassword,
    isNvr,
    vendor,
  } = device;

  const disableFields =
    !hasPermission("devices_manage") ||
    (!isNvr && camera?.lifecycleState !== LifecycleStates.Enabled);

  const channelsCount = isNvr ? device.channels.length : 0;
  const valid = device.status === ConnectionValidation.Ok;
  const conErrors = getValidationErrors(device.status);

  const showPtz = me?.organization.flags.ptz && isPTZSupported(vendor);

  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        ip,
        port: port.toString(),
        username,
        password,
        ptzEnabled: !!onvifUsername && !!onvifPassword,
        onvifUsername: onvifUsername ?? "",
        onvifPassword: onvifPassword ?? "",
        path: path || "/",
        isNvr: device.isNvr,
        channels: channelsCount,
      }}
      initialTouched={{
        ip: !!conErrors?.ip,
        port: !!conErrors?.port,
        username: !!conErrors?.username,
        password: !!conErrors?.password,
        path: !!conErrors?.path,
      }}
      initialErrors={conErrors}
      onSubmit={async (values, { setErrors, resetForm }) => {
        // Trim any whitespaces added by mistake
        const creds = {
          ip: values.ip.trim(),
          port: parseInt(values.port.trim(), 10),
          username: values.username.trim(),
          password: values.password.trim(),
          path: values.path.trim() || "/",
          isNvr: Boolean(values.isNvr),
          channels:
            values.channels > channelsCount ? values.channels : undefined,
          onvifUsername: !showPtz
            ? onvifUsername
            : values.ptzEnabled && !!values.onvifUsername
            ? values.onvifUsername.trim()
            : null,
          onvifPassword: !showPtz
            ? onvifPassword
            : values.ptzEnabled && !!values.onvifPassword
            ? values.onvifPassword.trim()
            : null,
        };

        const input = {
          id,
          ...omit("ip", creds),
        };
        return updateDevice({ variables: { input } })
          .then(({ data }) => {
            if (data) {
              if (data.updateDevice.__typename === "StreamValidationError") {
                const error = data.updateDevice;
                throw new Error(`Error ${error.code}: ${error.message}`);
              }
              resetForm();
            }
          })
          .then(() => geniusScan())
          .catch((e) => {
            pushSnackbar(e.message, FeedbackType.Error);
            console.error(e);
          });
      }}
    >
      {({ isSubmitting, dirty, touched, errors, values, setFieldValue }) => (
        <Form>
          <div className="grid grid-cols-1 sm:grid-cols-4 gap-4 mt-3">
            <Field
              name="username"
              label="Username"
              className="sm:col-span-2"
              {...defaultTextProps}
              disabled={disableFields || isSubmitting}
            />
            <Field
              name="password"
              label="Password"
              className="sm:col-span-2"
              {...defaultTextProps}
              disabled={disableFields || isSubmitting}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      className="text-[#353D48]"
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowPassword((currentState) => !currentState)
                      }
                    >
                      {showPassword ? (
                        <VisibilityIcon className="w-4 h-4" />
                      ) : (
                        <VisibilityOffIcon className="w-4 h-4" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Field
              name="port"
              className="sm:col-span-1"
              component={Autocomplete}
              options={["554", "8554", "8555", "10554", "2554"]}
              freeSolo
              forcePopupIcon
              disableClearable
              disabled={disableFields || isSubmitting}
              fullWidth
              size="small"
              renderInput={(params: AutocompleteRenderInputParams) => (
                <TextField
                  {...params}
                  onChange={(e) => {
                    setFieldValue("port", e.target.value);
                  }}
                  InputProps={{
                    ...params.InputProps,
                    ...defaultTextProps.InputProps,
                  }}
                  InputLabelProps={{
                    ...params.InputLabelProps,
                    ...defaultTextProps.InputLabelProps,
                  }}
                  inputProps={{
                    ...params.inputProps,
                  }}
                  FormHelperTextProps={defaultTextProps.FormHelperTextProps}
                  error={touched["port"] && !!errors["port"]}
                  helperText={errors["port"]}
                  label="RTSP Port"
                />
              )}
              validate={(value: string) =>
                Number(value.trim()) ? undefined : "Port invalid"
              }
            />
            <Field
              name="path"
              label="Test Path"
              className="sm:col-span-3"
              {...defaultTextProps}
              disabled={disableFields || isSubmitting}
              helperText={"Any RTSP path on the device to test authentication."}
            />
          </div>
          {showPtz && (
            <PTZCredentials
              enabled={values.ptzEnabled}
              inputsDisabled={disableFields || isSubmitting}
            />
          )}
          <div className="mt-2">
            {!valid || dirty || Object.values(touched).some((v) => v) ? (
              <div className="w-full flex justify-between">
                <Button
                  fullWidth
                  color="primary"
                  variant="outlined"
                  type="submit"
                  disabled={isSubmitting}
                  style={{ fontSize: 12, fontWeight: 400 }}
                >
                  {device.isNvr
                    ? "Update NVR"
                    : `${valid ? "Re-" : ""}Authenticate`}
                  {isSubmitting && (
                    <>
                      <CircularProgress className="mx-1" size="20px" />
                    </>
                  )}
                </Button>
              </div>
            ) : (
              <div className="flex-center w-full font-bold text-[#62b309]">
                <CheckCircleIcon />
                <div>Valid Connection</div>
              </div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
}

function PTZCredentials({
  enabled,
  inputsDisabled,
}: {
  enabled: boolean;
  inputsDisabled: boolean;
}) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <div className="mt-3">
        <label className="text-sm opacity-60">
          <Field
            type="checkbox"
            name="ptzEnabled"
            className="mr-2"
            disabled={inputsDisabled}
          />
          Enable PTZ
        </label>
      </div>
      {enabled && (
        <div className="flex sm:flex-row flex-col gap-3 items-center my-4">
          <Field
            name="onvifUsername"
            label="ONVIF Username"
            {...defaultTextProps}
            disabled={inputsDisabled}
            validate={(value: string | null) =>
              value && value.trim() ? undefined : "Username invalid"
            }
          />
          <Field
            name="onvifPassword"
            label="ONVIF Password"
            {...defaultTextProps}
            disabled={inputsDisabled}
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    className="text-[#353D48]"
                    aria-label="toggle password visibility"
                    onClick={() =>
                      setShowPassword((currentState) => !currentState)
                    }
                  >
                    {showPassword ? (
                      <VisibilityIcon className="w-4 h-4" />
                    ) : (
                      <VisibilityOffIcon className="w-4 h-4" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            validate={(value: string | null) =>
              value && value.trim() ? undefined : "Password invalid"
            }
          />
        </div>
      )}
    </>
  );
}

gql`
  query cameraCredentialsById($cameraId: Int!) {
    camera(id: $cameraId) {
      id
      lifecycleState
      device {
        id
        ip
        port
        path
        username
        password
        onvifUsername
        onvifPassword
        isNvr
        status
        vendor
        channels(filterChannelsWithCamera: false) {
          id
          channelId
        }
      }
    }
  }
`;
