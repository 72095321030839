// TODO show current settings when ready.
import CheckIcon from "@mui/icons-material/Check";
import { CircularProgress, Divider, Link, Typography } from "@mui/material";
import gql from "graphql-tag";
import { Fragment, useMemo } from "react";

import { useIsInSpecStream } from "@/util/validation/stream";

import { Statistic } from "@/components/Statistic";

import {
  DeviceStream,
  useDefaultCameraSettingsQuery,
  useGetDeviceChannelsQuery,
} from "@/generated-models";

// TODO show current value when all data is ready.
function StreamSetting({
  currentValue,
  valid,
  label,
  value,
}: {
  currentValue?: string;
  valid?: boolean;
  label: string;
  value?: string | number;
}) {
  return (
    <div className="flex flex-col items-center justify-center">
      {!valid && label !== "Bitrate" && label !== "Rate Control" && (
        <Statistic className="absolute top-[60px]" error flag inverted>
          {currentValue}
        </Statistic>
      )}
      {valid && label !== "Bitrate" && label !== "Rate Control" && (
        <CheckIcon className="absolute top-[60px] text-[#18D710] animate-scale-up-center" />
      )}
      <div className="flex flex-col text-left gap-y-0.5 animate-scale-up-center">
        <Typography className="text-sm leading-3">{label}</Typography>
        <Typography className="font-bold text-base leading-[16px]">
          {value}
        </Typography>
      </div>
    </div>
  );
}

export function DeviceSettingsInfo({
  applianceSerial,
  deviceId = 0,
}: {
  applianceSerial: string;
  deviceId?: number;
}) {
  const { data, loading } = useDefaultCameraSettingsQuery({
    variables: {
      serialNumber: applianceSerial,
    },
  });
  const {
    data: deviceScanData,
    loading: channelsLoading,
  } = useGetDeviceChannelsQuery({
    variables: {
      deviceId: deviceId || 0,
    },
  });

  const isLoading = loading || channelsLoading;
  const settings = data?.applianceFromSerial?.defaultCameraSettings;
  const connectedStream = useMemo(
    () =>
      deviceScanData?.deviceScan?.channels[0]?.streams.find(
        (stream) => stream.camera
      ),
    [deviceScanData]
  );

  const { specs } = useIsInSpecStream(
    connectedStream as DeviceStream,
    settings
  );

  return (
    <>
      <div className="flex flex-col items-end pr-2 gap-y-2">
        <div className="flex flex-col text-sm leading-[14px] mb-1 text-right">
          <div className="font-bold">Current</div>
          <div>Settings</div>
        </div>
        <div className="flex flex-col text-sm leading-[14px] my-1 text-right">
          <div className="font-bold">Recommended</div>
          <div>Settings</div>
        </div>
      </div>
      {isLoading && <CircularProgress className="mx-10" size={40} />}
      {!isLoading && (
        <div className="flex bg-[#F4F4F4] py-2 px-3 rounded-md mt-10">
          {specs.map((s, idx) => {
            return (
              <Fragment key={s.label}>
                <StreamSetting
                  label={s.label}
                  value={s.recommended}
                  valid={!s.error}
                  currentValue={s.value}
                />
                {idx + 1 < specs.length && (
                  <Divider
                    className="mx-4 bg-black/10"
                    orientation="vertical"
                    flexItem
                  />
                )}
              </Fragment>
            );
          })}
        </div>
      )}
      <Typography className="text-base ml-3 mt-10">
        <Link color="primary" underline="always" href="mailto:help@spotai.co">
          Need Help?
        </Link>
      </Typography>
    </>
  );
}

gql`
  query defaultCameraSettings($serialNumber: String!) {
    applianceFromSerial(serialNumber: $serialNumber) {
      id
      defaultCameraSettings {
        fps
        resolution
        bitrate
        encoding
        rateControl
      }
    }
  }
`;

gql`
  query getDeviceChannels($deviceId: Int!) {
    deviceScan(id: $deviceId) {
      id
      channels(filterChannelsWithCamera: false) {
        id
        channelId
        streams {
          id
          path
          channelId
          camera {
            id
            lifecycleState
            status
          }
          settings {
            video {
              codec
              height
              width
              fps
            }
            audio {
              codec
            }
          }
          metadata {
            id
            width
            height
            codec
            fps
            isValid
          }
        }
      }
    }
  }
`;
