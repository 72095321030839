import { useAtom } from "jotai";

import DrawZone from "@/components/Zones/DrawZone";

import {
  LibraryAddStep,
  aiCopilotCustomShape,
  aiCopilotCustomShapeEditing,
  useLibraryParams,
} from "../CopilotPlayerSection/CopilotLibraryPopover/copilotLibraryHooks";

export function CopilotAddVisualLabelOverlay() {
  const [shape, setShape] = useAtom(aiCopilotCustomShape);
  const [editing, setEditing] = useAtom(aiCopilotCustomShapeEditing);
  const [libraryParams] = useLibraryParams();

  if (libraryParams.addStep === LibraryAddStep.inputvisual) {
    return (
      <DrawZone
        shape={shape}
        setShape={setShape}
        editing={editing}
        setEditing={setEditing}
        animated
        style={{
          color: "#fff",
          fillOpacity: 0.3,
        }}
        shapeProps={{
          strokeWidth: "2",
          strokeDasharray: "10",
          strokeDashoffset: "1",
        }}
      />
    );
  }

  return <></>;
}
