import { Button, CircularProgress } from "@mui/material";
import clsx from "clsx";
import { createPortal } from "react-dom";

export function BufferingInfo({
  title,
  element,
  cancel,
}: {
  title: string;
  element: HTMLElement;
  cancel?: () => void;
}) {
  return createPortal(
    <div className="flex-center absolute m-auto w-full h-full top-0 z-10">
      <div
        className={clsx(
          "flex justify-between flex-col p-1 w-36 rounded bg-white text-xs font-bold text-center",
          {
            "h-32": !!cancel,
            "h-20": !cancel,
          }
        )}
      >
        <div className="p-1">
          <CircularProgress size={30} />
        </div>
        <div>{title}</div>
        {cancel && (
          <Button
            size="small"
            color="primary"
            onClick={cancel}
            style={{ fontSize: 12 }}
          >
            CANCEL
          </Button>
        )}
      </div>
    </div>,
    element
  );
}
