import { Button, Typography } from "@mui/material";
import { useState } from "react";

import { insertBreaklines } from "@/util/insertBreaklines";

const MAX_VISIBLE_LENGTH = 200;

export function CaseDescription({
  description,
  className,
}: {
  description: string;
  className?: string;
}) {
  const shouldTruncate = description.length >= MAX_VISIBLE_LENGTH;

  const [truncated, setTruncated] = useState(shouldTruncate);

  const displayText =
    shouldTruncate && truncated
      ? `${description.substring(0, MAX_VISIBLE_LENGTH)}...`
      : description;

  return (
    <>
      <Typography className={className}>
        {insertBreaklines(displayText)}
      </Typography>
      <div>
        {shouldTruncate && (
          <Button
            className="mt-1"
            color="primary"
            variant="outlined"
            size="small"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setTruncated((t) => !t);
            }}
          >
            {`View ${truncated ? "More" : "Less"}`}
          </Button>
        )}
      </div>
    </>
  );
}
