import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { CircularProgress, IconButton, Typography } from "@mui/material";
import clsx from "clsx";
import gql from "graphql-tag";
import { useEffect, useRef, useState } from "react";

import { StreamActivationActions } from "@/components/StreamSelectionDropdown/StreamActivationActions";
import { StreamInfoTile } from "@/components/StreamSelectionDropdown/StreamInfoTile";
import {
  determineDefaultStream,
  StreamSelectionDropdownMenu,
} from "@/components/StreamSelectionDropdown/StreamSelectionDropdownMenu";

import { useDeviceScanByIdQuery } from "@/generated-models";
import { usePermissions } from "@/hooks/usePermissions";

import {
  DEFAULT_CAMERA_SETTINGS_FRAGMENT,
  DEVICE_STREAM_METADATA_FRAGMENT,
  DEVICE_STREAM_SETTINGS_FRAGMENT,
} from "../Genius/fragments";

interface StreamSelectionDropdownProps {
  deviceName: string;
  deviceVendor: string;
  deviceId: number;
  channelId: number;
  aiEnabled: boolean;
  onClose?: () => void;
  disabled?: boolean;
  onOpenNativeProxy?: () => void;
}

export function StreamSelectionDropdown({
  deviceId,
  channelId,
  deviceName,
  deviceVendor,
  aiEnabled,
  onClose = () => {},
  disabled = false,
  onOpenNativeProxy = () => {},
}: StreamSelectionDropdownProps) {
  const [open, setOpen] = useState(false);
  const selectElementRef = useRef<HTMLDivElement | null>(null);
  const hasPermission = usePermissions();
  const isAdmin = hasPermission("devices_manage");

  const { data, loading, error, refetch } = useDeviceScanByIdQuery({
    variables: { deviceId },
  });

  const channel =
    data?.deviceScan?.channels.find((c) => c.channelId === channelId) ||
    data?.deviceScan?.channels[0];

  const [selectedStreamId, setSelectedStreamId] = useState<number | undefined>(
    undefined
  );

  useEffect(() => {
    const currentStream =
      channel?.streams.find((stream) => stream.camera) || channel?.streams[0];
    setSelectedStreamId(currentStream?.id);
  }, [channel?.streams]);

  if (loading) {
    return (
      <div className="flex justify-center items-center">
        <CircularProgress />
      </div>
    );
  }

  const selectedStream = channel?.streams.find(
    (stream) => stream.id === selectedStreamId
  );
  const connectedStream = channel?.streams.find((stream) => stream.camera);
  const recommendedCameraSettings =
    connectedStream?.camera?.appliance?.defaultCameraSettings;

  const recommendedStream =
    channel?.streams &&
    determineDefaultStream(channel?.streams, recommendedCameraSettings);

  return (
    <>
      <div className="flex flex-col">
        <StreamInfoTile
          ref={selectElementRef}
          stream={selectedStream}
          recommendedCameraSettings={recommendedCameraSettings}
          recommended={
            selectedStream && selectedStream.path === recommendedStream?.path
          }
          onOpenNativeProxy={onOpenNativeProxy}
          showErrors
        >
          {error && (
            <div className="flex justify-center items-center">
              <div className="text-[#F44336]">
                Could not fetch stream data. Please try again.
              </div>
            </div>
          )}
          {!error && (
            <div className="flex justify-between items-center mb-1">
              <Typography className="font-bold leading-[18.75px]">
                Stream Selection
              </Typography>
              <IconButton
                disabled={disabled}
                size="small"
                className={clsx(
                  "text-[#353D48] transition duration-[400ms] p-0 w-[18px] h-[18px]",
                  {
                    "rotate-180": open,
                    "rotate-0": !open,
                    "opacity-40": disabled,
                  }
                )}
                onClick={() => setOpen((value) => !value)}
              >
                <ArrowDropDownIcon />
              </IconButton>
            </div>
          )}
        </StreamInfoTile>
        {isAdmin && (
          <StreamActivationActions
            selectedStreamId={selectedStreamId}
            channel={channel}
            deviceName={deviceName}
            deviceVendor={deviceVendor}
            aiEnabled={aiEnabled}
            onChange={async (newValue) => {
              if (newValue) {
                setSelectedStreamId(newValue);
                await refetch({ deviceId });
              }
            }}
            onClose={onClose}
            disabledActivateCamera={disabled}
          />
        )}
      </div>
      <StreamSelectionDropdownMenu
        open={open}
        disabled={disabled}
        anchorEl={selectElementRef.current}
        onClose={() => setOpen(false)}
        channel={channel}
        recommendedCameraSettings={recommendedCameraSettings}
        deviceId={deviceId}
        selectedStreamId={selectedStreamId}
        onOpenNativeProxy={onOpenNativeProxy}
        onChange={async (streamId) => {
          setSelectedStreamId(streamId);
          await refetch({ deviceId });
          setOpen(false);
        }}
      />
    </>
  );
}

gql`
  query deviceScanById($deviceId: Int!) {
    deviceScan(id: $deviceId) {
      id
      channels(filterChannelsWithCamera: false) {
        id
        channelId
        still
        streams {
          id
          path
          channelId
          camera {
            id
            name
            lifecycleState
            status
            aiEnabled
            stream {
              id
              settings {
                ...DeviceStreamSettings
              }
            }
            appliance {
              id
              defaultCameraSettings {
                ...DefaultCameraSettings
              }
            }
          }
          settings {
            ...DeviceStreamSettings
          }
          metadata {
            ...DeviceStreamMetadata
          }
        }
      }
    }
  }
  ${DEFAULT_CAMERA_SETTINGS_FRAGMENT}
  ${DEVICE_STREAM_SETTINGS_FRAGMENT}
  ${DEVICE_STREAM_METADATA_FRAGMENT}
`;
