import gql from "graphql-tag";
import { makeStyles } from "tss-react/mui";

import {
  Page_VideoWallsQuery,
  VideoWallFragmentFragmentDoc,
  WallCamerasFragmentDoc,
} from "@/generated-models";

/**
 * <cameraCount>: [columns, rows]
 */
export const configs = [
  // 4 tiles
  {
    columns: 2,
    rows: 2,
    sizes: [1, 1, 1, 1],
  },
  {
    columns: 3,
    rows: 4,
    sizes: [3, 1, 1, 1],
  },
  {
    columns: 4,
    rows: 3,
    sizes: [3, 1, 1, 1],
  },

  // 6 tiles
  {
    columns: 3,
    rows: 2,
    sizes: [1, 1, 1, 1, 1, 1],
  },
  {
    columns: 3,
    rows: 3,
    sizes: [2, 1, 1, 1, 1, 1],
  },
  {
    columns: 3,
    rows: 3,
    sizes: [1, 2, 1, 1, 1, 1],
  },
  {
    columns: 3,
    rows: 4,
    sizes: [2, 1, 1, 2, 1, 1],
  },
  {
    columns: 3,
    rows: 4,
    sizes: [2, 1, 1, 1, 2, 1],
  },

  // 9 tiles
  {
    columns: 3,
    rows: 3,
    sizes: [1, 1, 1, 1, 1, 1, 1, 1, 1],
  },
  {
    columns: 3,
    rows: 4,
    sizes: [2, 1, 1, 1, 1, 1, 1, 1, 1],
  },
  {
    columns: 3,
    rows: 5,
    sizes: [2, 1, 1, 2, 1, 1, 1, 1, 1],
  },
  {
    columns: 4,
    rows: 3,
    sizes: [2, 1, 1, 1, 1, 1, 1, 1, 1],
  },

  // 16 tiles
  {
    columns: 4,
    rows: 4,
    sizes: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
  },

  {
    columns: 5,
    rows: 5,
    sizes: [
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
    ],
  },

  // 36 tiles
  {
    columns: 6,
    rows: 6,
    sizes: [
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
    ],
  },
] as const;

export type WallConfig = typeof configs[number];
export type VideoWallFromQuery = Page_VideoWallsQuery["videoWalls"][number];
export type WallCamera =
  | { __typename: "Camera"; id: number; name: string }
  | { __typename: "NotAllowed"; id: number; noAccess: boolean };

export const useWallStyles = makeStyles()((theme) => ({
  wallSelectInput: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    flex: 1,

    "&:hover fieldset": {
      opacity: 1,
    },

    "& svg": {
      color: "inherit",
    },
  },
  wallGrid: {
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "4px",
  },
  initialMessage: {
    paddingTop: theme.spacing(6),
  },
  initialMessageHeader: {
    fontSize: "3rem",
    fontWeight: 300,
  },
  wallNameInput: {
    minWidth: 300,
    "&:hover fieldset": {
      opacity: 1,
    },
    "& label": {
      color: theme.palette.primary.main,
    },
  },
  wallNameInputOutline: {
    opacity: 0.5,
    borderColor: `${theme.palette.primary.main} !important`,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  streamDetails: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    width: "initial",
    padding: theme.spacing(1),
  },
  dropHover: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    border: "4px solid #007ce4",
    borderRadius: 2,
    zIndex: 1,
  },
  slotText: {
    color: "white",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  pageChip: {
    textTransform: "none",
    outline: "none !important",
    border: "1px solid #E3E3E3",

    "&:not(.active)": {
      color: "#353D48",
      backgroundColor: "#F3F3F3C9",
      fontWeight: "normal",
    },
    "&.active": {
      backgroundColor: "#007ce4",
      borderColor: "#007ce4",
    },
  },
  fullScreenPageChip: {
    border: "1px solid #E3E3E34D",
    "&:not(.active)": {
      backgroundColor: "#060606C9",
      color: "#fff",
    },
    "&.active": {
      color: "#fff",
    },
  },
  editRotatingWallContainer: {
    border: "1px solid #dbdbdb",
    borderRadius: 4,
    width: 500,
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  editRotatingWallList: {
    background: "#f8f8f8",
    padding: 0,
    marginTop: 8,
    overflow: "auto",
  },
  wallDraggable: {
    cursor: "move",
    borderBottom: "1px solid #dbdbdb77",
    marginTop: -1,
    borderTop: "1px solid #dbdbdb77",
    background: "white",

    "&:active": {
      cursor: "grabbing",
    },
  },
  pageDurationTextField: {
    borderRadius: 0,
    background: "white",

    "& input": {
      width: 40,
      padding: "4px 4px",
      textAlign: "center",
    },
  },
  editingWallPreview: {},
}));

export function forcedQualityByCameraCount(count: number) {
  if (count > 6) return "360p";
  if (count > 4) return "480p";
  if (count > 1) return "720p";
  return undefined;
}

gql`
  fragment WallCameras on Camera {
    id
    name
    status
    feeds {
      tunnel
      local
      webRTC
    }
    location {
      id
      name
      timezone
    }
    health {
      cameraOnline
      applianceOnline
    }
    settings {
      audioControlEnabled
    }
  }
`;

gql`
  fragment VideoWallFragment on VideoWall {
    id
    name
    cameras {
      ... on Camera {
        ...WallCameras
      }
      ... on NotAllowed {
        id
        noAccess
      }
    }
    config {
      columns
      rows
      sizes
    }
  }
  ${WallCamerasFragmentDoc}
`;

gql`
  query page_videoWalls {
    videoWalls {
      ...VideoWallFragment
    }
    rotatingVideoWalls {
      id
      name
      pageDuration
      videoWalls {
        ...VideoWallFragment
      }
    }
  }
  ${VideoWallFragmentFragmentDoc}
`;

export function validateName(names: string[]) {
  const lowerCaseNames = names.map((name) => name.toLowerCase());
  return (rawInput: string) => {
    if (lowerCaseNames.includes(rawInput.toLowerCase())) {
      return `This name has already been used`;
    }
    if (!rawInput.trim()) return "Please provide a name";
  };
}
