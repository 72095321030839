import SpeedIcon from "@mui/icons-material/SlowMotionVideo";
import { Box, Grid, Typography } from "@mui/material";
import React from "react";

import { usePlaybackRate } from "@/components/Player/PlayerBase";

import { PlayerTooltip } from "./PlayerTooltip";

export function PlaybackRateDisplay() {
  const playbackRate = usePlaybackRate();
  if (playbackRate === 1) return null;

  return (
    <PlayerTooltip title={`Playing at ${playbackRate.toFixed(1)}x speed`}>
      <Grid
        container
        alignItems="center"
        style={{ width: "initial", opacity: 0.5 }}
        wrap="nowrap"
      >
        <Box m={1} />
        <SpeedIcon fontSize="small" />
        <Box m="2px" />
        <Typography variant="caption">{playbackRate.toFixed(1)}x</Typography>
      </Grid>
    </PlayerTooltip>
  );
}
