import Hls, { Events, LevelSwitchingData } from "hls.js";
import mux from "mux-embed";
import { useEffect, useState } from "react";

import { MuxMetadata } from "@/util/useMuxMetadata";

import { useMe } from "@/components/Auth";
import { StreamSources } from "@/components/Player/StreamSources";

import { isProductionEnv, isStagingEnv } from "@/environment";

/**
 * Mux monitoring is enabled by default for production and staging environments.
 * Can be overridden by specifying the REACT_APP_MUX_KEY environment variable.
 */
const muxKey =
  process.env.REACT_APP_MUX_KEY ??
  (isProductionEnv
    ? "ij1d35ptebrufj0c6t5j060on"
    : isStagingEnv
    ? "6rnr7huf3sko9kktrfim4p8ee"
    : undefined);

interface MuxMonitorProps {
  playerElement: HTMLVideoElement;
  hls: Hls | null;
  source: string;
  sources?: StreamSources | null;
  data?: MuxMetadata;
}
export function MuxMonitor({ sources, ...props }: MuxMonitorProps) {
  const me = useMe();
  const [playerInitTime, setPlayerInitTime] = useState<number | null>(null);

  // Ensure playerInitTime is captured as soon as sources are available
  useEffect(() => {
    if (!sources) setPlayerInitTime(null);
    else setPlayerInitTime(Date.now());
    // eslint-disable-next-line
  }, [sources?.local, sources?.tunnel]);

  useEffect(() => {
    if (props.data?.loading || playerInitTime == null) return;
    return initMuxMonitoring({ ...props, playerInitTime, user: me });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data?.loading, playerInitTime]);

  return null;
}

type SamplingStates = Record<number, number>; // { <sampling rate>: <count> }
function resolveMuxSampling(sampling: number) {
  if (sampling >= 1) return true;
  let samplingState: SamplingStates;
  try {
    samplingState = JSON.parse(localStorage.muxSampling);
  } catch (e) {
    // Sampling state is invalid, reset it
    samplingState = { [sampling]: 1 };
    localStorage.muxSampling = JSON.stringify(samplingState);
    return false;
  }
  if (sampling in samplingState) {
    const threshold = Math.round(1 / sampling);
    if (samplingState[sampling] >= threshold) {
      samplingState[sampling] = 0;
    } else {
      samplingState[sampling]++;
    }

    localStorage.muxSampling = JSON.stringify(samplingState);
    return samplingState[sampling] === 0; // Threshold passed if count reset to 0
  } else {
    samplingState[sampling] = 1; // initial run for this sampling rate
    localStorage.muxSampling = JSON.stringify(samplingState);
    return false;
  }
}

function initMuxMonitoring({
  playerInitTime,
  playerElement,
  hls,
  source,
  data = {
    experimentName: "<not set>",
    sampling: 0.1,
    playerName: "<not set>",
  },
  user,
}: Omit<MuxMonitorProps, "sources"> & {
  playerInitTime: number;
  user?: { id: number; organization: { id: number } } | null;
}) {
  const { pathname, search } = window.location;
  const isKiosk = pathname.includes("kiosk");
  const isKioskKeepAlive = search.includes("kioskKeepAlive");
  const muxSampling = resolveMuxSampling(data.sampling);
  const muxEnabled = !!muxKey && !isKioskKeepAlive && muxSampling;

  const hlsSrc = source?.includes("m3u8");
  if (hlsSrc && !hls && Hls.isSupported()) return; // HLS.js pending instantiation

  function onLevelSwitched(
    _: Events.LEVEL_SWITCHING,
    data: LevelSwitchingData
  ) {
    const { bitrate, width, height } = data;
    mux.emit(playerElement, "renditionchange", {
      video_source_bitrate: bitrate,
      video_source_height: height,
      video_source_width: width,
    });
  }
  if (muxEnabled) {
    if (hls) {
      hls.on(Hls.Events.LEVEL_SWITCHING, onLevelSwitched);
    }

    mux.monitor(playerElement, {
      debug: false,
      ...(hls && {
        // pass in the 'hls' instance and the 'Hls' constructor
        hlsjs: hls,
        Hls,
      }),
      data: {
        env_key: muxKey,

        custom_1: data.applianceVersion,
        experiment_name: data.experimentName,

        player_init_time: playerInitTime,
        player_name: data.locationName,
        player_software_version: Hls.version,
        player_source_width: data.width,
        player_source_height: data.height,
        player_version: hlsSrc ? "HLS" : "MP4",

        sub_property_id: data.locationId,

        video_encoding_variant: data.codec,
        video_id: data.cameraId,
        video_cdn: data.applianceSerial ?? /sn\w+-\w+/.exec(source)?.[0],
        video_series: user?.organization?.id,
        video_stream_type: data.playerName,
        video_title: isKiosk
          ? "Kiosk"
          : `${data.cameraName} [${data.locationName}]`,
        viewer_user_id: user?.id,
      },
    });
  }

  return () => {
    if (muxEnabled) {
      if (hls) {
        hls?.off(Hls.Events.LEVEL_SWITCHING, onLevelSwitched);
      }
      mux.destroyMonitor(playerElement);
    }
  };
}
