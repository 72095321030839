import { CircularProgress, Divider } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import { Form, Formik, useField } from "formik";
import gql from "graphql-tag";
import { BooleanParam, useQueryParam } from "use-query-params";

import { required } from "@/util/form";
import { useBreakpoints } from "@/util/useBreakpoints";

import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";

import {
  IntegrationSourcesQuery,
  useIntegrationDeviceCamQuery,
  useUpdateIntegrationSourceMutation,
} from "@/generated-models";

import { IntegrationsLinkCamerasDrawer } from "./IntegrationsLinkCamerasDrawer";

interface IntegrationsLinkCamerasFormProps {
  source?: IntegrationSourcesQuery["integrationSources"][number];
  promptSuccess?: boolean;
}

interface IntegrationsLinkCamerasDialogProps {
  source?: IntegrationSourcesQuery["integrationSources"][number];
  open?: boolean;
  promptSuccess?: boolean;
  onClose: () => void;
}
const DEV_EDIT_OK = "Device successfully updated.";
const DEV_EDIT_FAIL = "Failed to update the device, please try again";

export function IntegrationsLinkCamerasDialogCameraList({
  labelClassName,
  onUnlink,
}: {
  labelClassName?: string;
  onUnlink?: () => void;
}) {
  const [, { value: cameraIds }, { setValue }] = useField<number[]>({
    name: "cameraIds",
    validate: (value: any) => required(value),
  });
  const { data, loading } = useIntegrationDeviceCamQuery({
    variables: { ids: cameraIds },
    skip: cameraIds.length === 0,
  });

  const hasCamerasConfigured = cameraIds.length > 0;

  return (
    <>
      {hasCamerasConfigured && loading && (
        <div className="flex items-center justify-center p-4">
          <CircularProgress size={30} />
        </div>
      )}
      {hasCamerasConfigured && (
        <>
          {data?.cameras?.map((camera) => (
            <div
              key={camera.id}
              className="flex flex-nowrap items-start gap-2.5 pb-2 border-b border-solid border-[#DFDFDF] w-full"
            >
              <img
                src={camera.still}
                className="h-[58px] flex-grow-0 flex-shrink rounded-[1px] border border-solid border-[#C1C1C1]"
                alt=""
              />
              <div className="max-w-[15rem] md:max-w-[unset] w-full">
                <div className="flex items-start justify-between gap-1 w-full">
                  <Typography
                    variant="caption"
                    component="p"
                    className={clsx(
                      "truncate text-sm leading-4 max-w-[180px]",
                      labelClassName
                    )}
                  >
                    {camera.location.name}
                  </Typography>
                  <button
                    type="button"
                    onClick={() => {
                      setValue(cameraIds.filter((c) => c !== camera.id));
                      onUnlink?.();
                    }}
                    className="bg-transparent py-0 text-sm leading-4 text-primary"
                  >
                    Unlink
                  </button>
                </div>
                <Typography className="truncate text-[11px] leading-[13px] opacity-50 mt-[1px]">
                  {camera.name}
                </Typography>
                <div className="flex items-center capitalize text-xs -ml-0.5">
                  <span
                    className={clsx(
                      "w-2 h-2 mx-1 rounded-[50%] border-2 border-solid",
                      {
                        "bg-[#67c21b]/50 border-[#67c21b]":
                          camera.status === "online",
                        "bg-[#b0b0b0]/50 border-[#b0b0b0]":
                          camera.status !== "online",
                      }
                    )}
                  />
                  {camera.status}
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
}

export function IntegrationsLinkCamerasDialogSuccessContent({
  className,
}: {
  className?: string;
}) {
  return (
    <div className={clsx("flex flex-col gap-2", className)}>
      <Typography className="text-[36px] leading-[42px] font-bold">
        🎉 Success!
      </Typography>
      <Typography className="text-sm leading-4">
        Device successfully set up.
      </Typography>
      <Typography className="text-sm leading-4 md:font-bold">
        You can now select another device to link cameras to or advance to the
        next step
      </Typography>
    </div>
  );
}

export function IntegrationsLinkCamerasList({
  source,
  promptSuccess,
}: IntegrationsLinkCamerasFormProps) {
  const [finished, setFinished] = useQueryParam("finished", BooleanParam);
  const { pushSnackbar } = useFeedback();
  const { cameras = [] } = source || { cameras: [] };

  const initialCamerasId = cameras?.map((c) => c.id) || [];
  const [updateIntegration] = useUpdateIntegrationSourceMutation({
    refetchQueries: ["integrationSources"],
    onCompleted: () => {
      if (promptSuccess) {
        setFinished(true);
      } else {
        pushSnackbar(DEV_EDIT_OK, FeedbackType.Success);
      }
    },
    onError: () => pushSnackbar(DEV_EDIT_FAIL, FeedbackType.Error),
  });

  return (
    <Formik
      enableReinitialize
      validateOnBlur
      initialValues={{ cameraIds: initialCamerasId }}
      onSubmit={({ cameraIds }) => {
        return updateIntegration({
          variables: { input: { id: source?.id!, cameraIds } },
        });
      }}
    >
      {({ values, submitForm }) => (
        <Form className="max-w-md flex flex-col gap-2">
          {values.cameraIds.length > 0 && !finished && (
            <IntegrationsLinkCamerasDialogCameraList
              labelClassName="!max-w-[154px]"
              onUnlink={() => {
                submitForm();
              }}
            />
          )}
        </Form>
      )}
    </Formik>
  );
}

export default function IntegrationsLinkCamerasDialog({
  source,
  open = false,
  promptSuccess,
  onClose,
}: IntegrationsLinkCamerasDialogProps) {
  const [finished, setFinished] = useQueryParam("finished", BooleanParam);
  const { fitsDesktop } = useBreakpoints();
  const { pushSnackbar } = useFeedback();
  const { standardMeta, cameras = [] } = source || {};
  const { name } = standardMeta || {};

  const initialCamerasId = cameras?.map((c) => c.id) || [];

  function onDialogClose() {
    if (fitsDesktop) {
      setFinished(false);
    }
    onClose();
  }

  const [updateIntegration] = useUpdateIntegrationSourceMutation({
    refetchQueries: ["integrationSources"],
    onCompleted: () => {
      if (promptSuccess) {
        setFinished(true);
      } else {
        onDialogClose();
        pushSnackbar(DEV_EDIT_OK, FeedbackType.Success);
      }
    },
    onError: () => pushSnackbar(DEV_EDIT_FAIL, FeedbackType.Error),
  });

  return (
    <Dialog
      classes={{ paper: "max-w-sm rounded-xl" }}
      onClose={onDialogClose}
      hideBackdrop
      disableEnforceFocus
      open={open}
    >
      <Formik
        validateOnBlur
        initialValues={{ cameraIds: initialCamerasId }}
        onSubmit={({ cameraIds }) => {
          return updateIntegration({
            variables: { input: { id: source?.id!, cameraIds } },
          });
        }}
      >
        {({ isSubmitting, isValid, dirty, resetForm, values, submitForm }) => (
          <Form className="max-w-md p-5 flex flex-col gap-2">
            <IntegrationsLinkCamerasDrawer
              open
              onOpen={() => {}}
              onClose={async () => {
                if (!fitsDesktop) {
                  await submitForm();
                }
                resetForm();
                onDialogClose();
              }}
            />
            <Typography className="text-lg leading-[21px] font-bold mb-[6px]">
              {name}
            </Typography>
            <Divider />
            {finished && <IntegrationsLinkCamerasDialogSuccessContent />}
            {values.cameraIds.length === 0 && (
              <>
                <Typography className="text-lg leading-[21px] text-black opacity-50 italic text-center">
                  Add cameras from the camera drawer on the right.
                </Typography>
                <Divider />
              </>
            )}
            {values.cameraIds.length > 0 && !finished && (
              <IntegrationsLinkCamerasDialogCameraList />
            )}
            <div className="flex gap-[10px] items-center justify-end">
              <Button
                variant="contained"
                color={finished ? "primary" : undefined}
                className={clsx(
                  "shadow-none text-xs leading-[22px] font-normal rounded-md",
                  {
                    "bg-[#DAEEFF] text-primary": !finished,
                  }
                )}
                onClick={() => {
                  resetForm();
                  onDialogClose();
                }}
                disabled={isSubmitting}
              >
                {finished ? "Ok" : "Cancel"}
              </Button>
              {!finished && (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="shadow-none text-xs leading-[22px] font-normal rounded-md"
                  disabled={isSubmitting || !isValid || !dirty}
                >
                  Save
                </Button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

gql`
  query integrationDeviceCam($ids: [Int!]!) {
    cameras(ids: $ids) {
      id
      name
      still
      status

      location {
        id
        name
      }
    }
  }
`;
