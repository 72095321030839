import { Button, ButtonProps, Typography } from "@mui/material";
import clsx from "clsx";
import { capitalize } from "lodash";

import { pluralize } from "@/util/pluralize";

import { useDataGridSelection } from "./hooks";

interface DataGridSelectionBarProps {
  rowLabel: string;
}

function DataGridSelectionButton({ className, ...props }: ButtonProps) {
  return (
    <Button
      className={clsx("text-white leading-5 disabled:opacity-50", className)}
      {...props}
    />
  );
}

export function DataGridSelectionBar({ rowLabel }: DataGridSelectionBarProps) {
  const { selectAll, deselectAll, locked, count } = useDataGridSelection();

  const hasSelection = count > 0;
  const displayLabel = pluralize(
    {
      1: capitalize(rowLabel),
      multi: `${capitalize(rowLabel)}s`,
    },
    count
  );

  if (!hasSelection) return <></>;

  return (
    <div className="bg-primary py-1 px-3 animate-fade-in-bottom">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-1">
          <div className="font-bold text-white leading-5 mr-4">
            Manage {displayLabel}
          </div>
          <Typography className="text-white text-sm leading-4">
            {count} {displayLabel} Selected
          </Typography>
        </div>
        <div className="flex items-center gap-1">
          <DataGridSelectionButton
            disabled={!!locked}
            className="font-normal"
            onClick={deselectAll}
          >
            Deselect All
          </DataGridSelectionButton>
          <DataGridSelectionButton
            disabled={!!locked}
            className="font-normal"
            onClick={selectAll}
          >
            Select All
          </DataGridSelectionButton>
        </div>
      </div>
    </div>
  );
}
