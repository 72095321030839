import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Typography } from "@mui/material";
import { DataGridProps } from "@mui/x-data-grid";
import clsx from "clsx";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { useAtomValue } from "jotai";
import { capitalize } from "lodash";
import { ReactNode, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";

import { formatDurationSecs } from "@/util/formatDurationSecs";
import { useBreakpoints } from "@/util/useBreakpoints";

import { DataGrid } from "@/components/DataGrid/DataGrid";
import { MobileColumnsRenderer } from "@/components/DataGrid/MobileColumnsRenderer";
import { LazyImage } from "@/components/shared/LazyImage";

import { IntelligenceDashboardType } from "@/generated-models";

import { getObjectLabel } from "../../utils";
import { COMPARATIVE_COLORS, ComparativeTabType } from "../constant";
import { ComparativeDashboardDataProps } from "./Chart/ComparativeDashboardChart";
import {
  refContentDashboardLastUpdated,
  refContentDashboardsAtom,
  refPresenceDashboardsAtom,
} from "./Chart/ComparativeDashboardChartFetcher";
import { compContentTabAtom } from "./ComparativeDashboardContentTabs";

function ComparativeDatagridHeader({ lines }: { lines: string[] }) {
  return (
    <div className="flex gap-1 md:gap-0 md:flex-col leading-4">
      {lines.map((l) => (
        <div key={l}>{l}</div>
      ))}
    </div>
  );
}

function ComparativeDatagridStat({
  label,
  value,
  multiRow,
}: {
  label?: string;
  value: ReactNode;
  multiRow?: boolean;
}) {
  const { fitsDesktop } = useBreakpoints();
  return (
    <div
      className={clsx(
        "flex gap-1",
        multiRow ? "md:flex-col" : "items-center",
        !fitsDesktop && "flex-row-reverse justify-end items-center"
      )}
    >
      <Typography className="text-sm md:text-lg font-bold leading-4 sm:leading-[21px]">
        {value}
      </Typography>
      {label && (
        <Typography
          className={clsx("text-sm leading-4 sm:text-base sm:leading-[18px]", {
            hidden: !fitsDesktop,
          })}
        >
          {label}
        </Typography>
      )}
    </div>
  );
}

function useComparativeDatagridData(
  referenceDashboards: ComparativeDashboardDataProps["referenceDashboards"],
  type: IntelligenceDashboardType,
  thresholdSeconds: number,
  objectTypes?: string[] | null | undefined
) {
  const tab = useAtomValue(compContentTabAtom);
  const lastUpdated = useAtomValue(refContentDashboardLastUpdated);
  const refContent = useAtomValue(refContentDashboardsAtom);
  const refPresence = useAtomValue(refPresenceDashboardsAtom);

  return useMemo(() => {
    const rows: any[] = [];
    const colDefs: DataGridProps["columns"] = [];

    const objectLabelPlural = capitalize(
      getObjectLabel(objectTypes, type, true)
    );

    referenceDashboards?.forEach((rd, idx) => {
      const { id } = rd;
      const contentData =
        refContent[id]?.data?.intelligenceDashboard?.metrics?.results;
      const presenceData =
        refPresence[id]?.data?.intelligenceDashboard?.presence?.results;

      if (type === IntelligenceDashboardType.Presence && presenceData) {
        rows.push({
          ...presenceData,
          position: idx,
          referenceDashboard: rd,
        });
      } else if (type !== IntelligenceDashboardType.Presence && contentData) {
        rows.push({
          ...contentData,
          position: idx,
          referenceDashboard: rd,
        });
      }
    });

    switch (tab) {
      case ComparativeTabType.IDLE_PERCENTAGE:
      case ComparativeTabType.IDLE_COUNT:
        const idleThreshold = formatDurationSecs(thresholdSeconds, {
          hideZeroes: true,
          long: true,
        });

        colDefs.push({
          flex: 1,
          field: "totalCount",
          renderHeader: () => (
            <ComparativeDatagridHeader lines={[`Total ${objectLabelPlural}`]} />
          ),
          renderCell: ({ row: { totalCount } }) => (
            <ComparativeDatagridStat
              label={objectLabelPlural.toLowerCase()}
              value={<strong>{totalCount}</strong>}
            />
          ),
        });
        colDefs.push({
          flex: 1,
          field: "percentIdle",
          renderHeader: () => (
            <ComparativeDatagridHeader
              lines={[
                `Percent ${objectLabelPlural}`,
                `Idle > ${idleThreshold}`,
              ]}
            />
          ),
          renderCell: ({ row: { idleCount, totalCount } }) => (
            <ComparativeDatagridStat
              label={`idle >${idleThreshold}`}
              value={
                <strong>{`${
                  totalCount && Math.round((idleCount / totalCount) * 1000) / 10
                }%`}</strong>
              }
            />
          ),
        });
        colDefs.push({
          flex: 1,
          field: "averageIdle",
          headerName: `Average Idle Time >${idleThreshold}`,
          renderHeader: () => (
            <ComparativeDatagridHeader
              lines={["Average Idle", `Time > ${idleThreshold}`]}
            />
          ),
          renderCell: ({ row: { averageIdleTimeSeconds } }) => (
            <ComparativeDatagridStat
              value={
                <strong>{formatDurationSecs(averageIdleTimeSeconds)}</strong>
              }
            />
          ),
        });
        colDefs.push({
          flex: 1,
          field: "averageStationary",
          renderHeader: () => (
            <ComparativeDatagridHeader
              lines={["Average Stationary", `Time All ${objectLabelPlural}`]}
            />
          ),
          renderCell: ({ row: { averageNonzeroIdleTimeSeconds } }) => (
            <ComparativeDatagridStat
              value={
                <strong>
                  {formatDurationSecs(averageNonzeroIdleTimeSeconds)}
                </strong>
              }
            />
          ),
        });
        break;
      case ComparativeTabType.COUNT_IN_OUT:
      case ComparativeTabType.COUNT_IN:
      case ComparativeTabType.COUNT_OUT:
        colDefs.push({
          flex: 1,
          field: "in",
          renderHeader: () => (
            <ComparativeDatagridHeader
              lines={[`Total ${objectLabelPlural} In`]}
            />
          ),

          renderCell: ({ row: { leftCount } }) => (
            <ComparativeDatagridStat
              multiRow
              label={`${objectLabelPlural.toLowerCase()} in`}
              value={<strong>{leftCount}</strong>}
            />
          ),
        });
        colDefs.push({
          flex: 1,
          field: "out",
          renderHeader: () => (
            <ComparativeDatagridHeader
              lines={[`Total ${objectLabelPlural} Out`]}
            />
          ),

          renderCell: ({ row: { rightCount } }) => (
            <ComparativeDatagridStat
              multiRow
              label={`${objectLabelPlural.toLowerCase()} out`}
              value={<strong>{rightCount}</strong>}
            />
          ),
        });
        colDefs.push({
          flex: 1,
          field: "mostIn",
          renderHeader: () => (
            <ComparativeDatagridHeader
              lines={[`Most ${objectLabelPlural} In`]}
            />
          ),

          valueGetter: ({ row: { bucketCounts } }) => {
            return [...bucketCounts].sort(
              (a, b) => (b.leftCount ?? 0) - (a.leftCount ?? 0)
            )[0];
          },

          renderCell: ({ value, row: { referenceDashboard } }) => {
            const tz = referenceDashboard?.cameras[0]?.location?.timezone;
            return (
              <ComparativeDatagridStat
                multiRow
                label={
                  value
                    ? `${objectLabelPlural.toLowerCase()} in on ${format(
                        utcToZonedTime(value.bucketMs, tz),
                        "M/d"
                      )}`
                    : ""
                }
                value={<strong>{value ? value?.leftCount ?? 0 : "N/A"}</strong>}
              />
            );
          },
        });
        colDefs.push({
          flex: 1,
          field: "mostOut",
          renderHeader: () => (
            <ComparativeDatagridHeader
              lines={[`Most ${objectLabelPlural} Out`]}
            />
          ),

          valueGetter: ({ row: { bucketCounts } }) => {
            return [...bucketCounts].sort(
              (a, b) => (b.rightCount ?? 0) - (a.rightCount ?? 0)
            )[0];
          },

          renderCell: ({ value, row: { referenceDashboard } }) => {
            const tz = referenceDashboard?.cameras[0]?.location?.timezone;
            return (
              <ComparativeDatagridStat
                multiRow
                label={
                  value
                    ? `${objectLabelPlural.toLowerCase()} out on ${format(
                        utcToZonedTime(value.bucketMs, tz),
                        "M/d"
                      )}`
                    : ""
                }
                value={
                  <strong>{value ? value?.rightCount ?? 0 : "N/A"}</strong>
                }
              />
            );
          },
        });
        break;
      default:
        colDefs.push({
          flex: 1,
          field: "percentPresent",
          renderHeader: () => (
            <ComparativeDatagridHeader
              lines={["Percent Time", `${objectLabelPlural} Present`]}
            />
          ),
          valueGetter: ({ row: { totalMsecPresent, totalMsec } }) => {
            return ((totalMsecPresent / totalMsec) * 100).toFixed(2);
          },
          renderCell: ({ value }) => (
            <ComparativeDatagridStat
              multiRow
              label="present"
              value={<strong>{value}%</strong>}
            />
          ),
        });
        colDefs.push({
          flex: 1,
          field: "totalTime",
          renderHeader: () => (
            <ComparativeDatagridHeader lines={["Total Time Present"]} />
          ),
          valueGetter: ({ row: { totalMsecPresent } }) => {
            return Math.round(totalMsecPresent / 1000);
          },
          renderCell: ({ value }) => (
            <ComparativeDatagridStat
              multiRow
              label="total presence"
              value={<strong>{formatDurationSecs(value)}</strong>}
            />
          ),
        });
        colDefs.push({
          flex: 1,
          field: "longestPresence",
          renderHeader: () => (
            <ComparativeDatagridHeader lines={["Longest Presence"]} />
          ),
          valueGetter: ({ row: { longestPresence } }) => {
            return longestPresence
              ? Math.round(
                  (new Date(longestPresence.endMs).getTime() -
                    new Date(longestPresence.startMs).getTime()) /
                    1000
                )
              : -1;
          },
          renderCell: ({ value }) =>
            value < 0 ? (
              ""
            ) : (
              <ComparativeDatagridStat
                multiRow
                label="present"
                value={<strong>{formatDurationSecs(value)}</strong>}
              />
            ),
        });
        colDefs.push({
          flex: 1,
          field: "longestAbsence",
          renderHeader: () => (
            <ComparativeDatagridHeader lines={["Longest Absence"]} />
          ),
          valueGetter: ({ row: { longestAbsence } }) => {
            return longestAbsence
              ? Math.round(
                  (new Date(longestAbsence.endMs).getTime() -
                    new Date(longestAbsence.startMs).getTime()) /
                    1000
                )
              : -1;
          },
          renderCell: ({ value }) =>
            value < 0 ? (
              ""
            ) : (
              <ComparativeDatagridStat
                multiRow
                label="absent"
                value={<strong>{formatDurationSecs(value)}</strong>}
              />
            ),
        });
    }

    return { rows, colDefs };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastUpdated, tab]);
}

export function ComparativeDashboardDatagrid({
  referenceDashboards,
  type,
  thresholdSeconds,
  objectTypes,
}: {
  referenceDashboards: ComparativeDashboardDataProps["referenceDashboards"];
  type: IntelligenceDashboardType;
  thresholdSeconds: number;
  objectTypes?: string[] | null | undefined;
}) {
  const location = useLocation();
  const { fitsDesktop } = useBreakpoints();
  const { rows, colDefs } = useComparativeDatagridData(
    referenceDashboards,
    type,
    thresholdSeconds,
    objectTypes
  );

  return (
    <DataGrid
      columnVisibilityModel={{
        totalCount: fitsDesktop,
        percentIdle: fitsDesktop,
        averageIdle: fitsDesktop,
        averageStationary: fitsDesktop,
        in: fitsDesktop,
        out: fitsDesktop,
        mostIn: fitsDesktop,
        mostOut: fitsDesktop,
        percentPresent: fitsDesktop,
        totalTime: fitsDesktop,
        longestPresence: fitsDesktop,
        longestAbsence: fitsDesktop,
      }}
      classes={{ row: "cursor-pointer" }}
      getRowId={({ referenceDashboard }) => referenceDashboard?.id}
      rowHeight={fitsDesktop ? 78 : 160}
      hideFooter
      initialState={{
        sorting: { sortModel: [{ field: "id", sort: "desc" }] },
      }}
      columns={[
        {
          field: "id",
          headerName: "Location & Camera",
          valueGetter: ({ row: { position, ...rest } }) => {
            return position;
          },
          cellClassName: "pl-0",
          flex: 2,
          renderCell: ({ row, ...rest }) => {
            const { referenceDashboard, position } = row;
            const camera = referenceDashboard?.cameras[0];
            const cell = (
              <div className="flex items-center gap-2 p-1">
                <div
                  className="h-[60px] w-[6px] rounded-[9px]"
                  style={{ background: COMPARATIVE_COLORS[position][1] }}
                />
                {camera?.still ? (
                  <img
                    className="w-[100px] h-14 rounded-md"
                    src={camera?.still}
                    alt="camera stil"
                  />
                ) : (
                  <LazyImage
                    cameraId={camera.id}
                    className="w-[100px] h-14 rounded-md"
                  />
                )}

                <div className="flex flex-col gap-1">
                  <Typography className="text-sm leading-4 truncate max-w-[200px]">
                    <strong>{camera?.location?.name}</strong>
                    <br />
                    {camera?.name}
                  </Typography>
                  <Link
                    to={`../${referenceDashboard.id}`}
                    state={{ from: location.pathname }}
                    className="flex gap-1 text-primary items-center"
                  >
                    <OpenInNewIcon className="w-4 h-4" />
                    <Typography className="text-sm leading-4">
                      View Insight
                    </Typography>
                  </Link>
                </div>
              </div>
            );

            if (fitsDesktop) return cell;

            return (
              <MobileColumnsRenderer
                params={{ row, ...rest }}
                colDefs={colDefs}
              >
                {cell}
              </MobileColumnsRenderer>
            );
          },
        },
        ...colDefs,
      ]}
      rows={rows}
    />
  );
}
