import { formatIsoDate, formatIsoDateTime } from "@/util/date";
import { formatDurationSecs } from "@/util/formatDurationSecs";

import { IntelligencePresenceDashboardContentQuery } from "@/generated-models";

import { Range } from "../../IntelligenceDashboardView";

export type PresenceExportContent = IntelligencePresenceDashboardContentQuery["intelligenceDashboard"];

export function presenceCsvFormatter(
  data: NonNullable<PresenceExportContent>,
  range?: string
) {
  const {
    longestAbsence: lAbsense,
    longestPresence: lPresence,
    totalMsec,
    totalMsecPresent,
  } = data.presence.results!;
  const percentOfTimePresent = ((totalMsecPresent / totalMsec) * 100).toFixed(
    2
  );

  const totalTimePresent = formatDurationSecs(
    Math.round(totalMsecPresent / 1000)
  );

  const longestPresence = lPresence
    ? formatDurationSecs(
        Math.round(
          (new Date(lPresence.endMs).getTime() -
            new Date(lPresence.startMs).getTime()) /
            1000
        )
      )
    : "";

  const longestAbsence = lAbsense
    ? formatDurationSecs(
        Math.round(
          (new Date(lAbsense.endMs).getTime() -
            new Date(lAbsense.startMs).getTime()) /
            1000
        )
      )
    : "";

  let detectedPresence;

  if (range === Range.Day) {
    detectedPresence = data.presence.results!.presenceBuckets[0]?.intervals.map(
      ({ startMs, endMs }) =>
        `${formatIsoDateTime(new Date(startMs))},${formatIsoDateTime(
          new Date(endMs)
        )}`
    );
  } else {
    detectedPresence = data.presence.results!.presenceBuckets.map(
      ({ presenceSum, bucketMs, bucketEndMs }) =>
        `${formatIsoDate(new Date(bucketMs))},${
          (presenceSum / (bucketEndMs - bucketMs)) * 100
        }%`
    );
  }

  return [
    `Percent of Time Present,${percentOfTimePresent}%`,
    `Total Time Present,${totalTimePresent}`,
    `Longest Presence,${longestPresence}`,
    `Longest Absence,${longestAbsence}`,
    "",
    range === Range.Day
      ? "Presence Start,Presence End"
      : "Day, Presence Percentage",
    ...(detectedPresence || []),
  ];
}
