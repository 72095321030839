/* eslint-disable no-template-curly-in-string */
import { Monaco } from "@monaco-editor/react";
import { IRange } from "monaco-editor";

export const getPromptSuggestions = (monaco: Monaco, range: IRange) => {
  function generateSuggestion(
    category: string,
    label: string,
    insertText: string,
    documentation: string
  ) {
    return {
      label: `[Prompt][${category}] ${label}`,
      kind: monaco.languages.CompletionItemKind.Enum,
      insertTextRules:
        monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      insertText,
      documentation,
      range,
    };
  }

  return [
    generateSuggestion(
      "Cameras",
      "Get Offline Camera Count",
      "How many of my cameras are offline",
      "How many of my cameras are offline."
    ),
    generateSuggestion(
      "Cameras",
      "Get Camera IP Address",
      "What is the IP address of the camera, [[CAMERA]]?",
      "Get the IP address of the specified camera"
    ),
    generateSuggestion(
      "Cameras",
      "Get earliest Camera recorded time",
      "What was the earliest recorded time of the camera, [[CAMERA]]?",
      "Find the earliest recorded time for a specified camera"
    ),
    generateSuggestion(
      "Support",
      "Search Support Center",
      "Search support center for ${1:((topic))}",
      "Search our support center knowledge base."
    ),
    generateSuggestion(
      "Support",
      "Search Support Tickets",
      "Search for tickets related to ${1:((topic))}",
      "Search our for specific support tickets for your organization."
    ),
  ];
};
