import { CircularProgress } from "@mui/material";
import { groupBy, intersection } from "lodash/fp";
import { useMemo } from "react";
import { ArrayParam, StringParam, useQueryParam } from "use-query-params";

import { ErrorMessage } from "@/components/ErrorMessage";

import {
  IntegrationTypeKey,
  useIntegrationSourcesQuery,
} from "@/generated-models";

import { useCurrentIntegrationId, useCurrentTypeId } from "../../hooks";
import IntegrationDetailsDeviceAccordion, {
  IntegrationDetailsDeviceGrid,
} from "./IntegrationDetailsDeviceAccordion";
import { IntegrationDetailsDeviceSummaryBar } from "./IntegrationDetailsDeviceSummaryBar";

export function IntegrationDetailsDevicesPage() {
  const id = useCurrentIntegrationId();
  const typeId = useCurrentTypeId();

  const [tagsFilter] = useQueryParam("tags", ArrayParam);
  const [locationsFilter] = useQueryParam("locations", ArrayParam);
  const [searchInputParam] = useQueryParam("search", StringParam);

  const { data, loading, error } = useIntegrationSourcesQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        id,
      },
    },
    skip: !id,
  });

  const sources = useMemo(() => {
    const searchQuery = searchInputParam?.toLowerCase();
    let result = data?.integrationSources || [];

    if (searchQuery) {
      result = result.filter((r) => {
        return (
          r.standardMeta.siteName.toLowerCase().includes(searchQuery) ||
          r.standardMeta.name.toLowerCase().includes(searchQuery) ||
          r.standardMeta.id.toLowerCase().includes(searchQuery) ||
          r.tags.join("_").toLowerCase().includes(searchQuery)
        );
      });
    }

    return result
      .filter(
        (s) =>
          !locationsFilter ||
          locationsFilter.includes(String(s.standardMeta.siteName))
      )
      .filter(
        (s) => !tagsFilter || intersection(tagsFilter, s.tags).length > 0
      );
  }, [data?.integrationSources, locationsFilter, searchInputParam, tagsFilter]);

  const groupedSources = useMemo(() => {
    return groupBy((source) => {
      if (!source.standardMeta.siteName) {
        return source.cameras[0]?.location.name ?? "Unknown";
      }

      return source.standardMeta.siteName;
    }, sources);
  }, [sources]);

  if (error) {
    return <ErrorMessage title="Error" description={error.toString()} />;
  }

  return (
    <div className="flex flex-col gap-3 md:gap-6">
      <IntegrationDetailsDeviceSummaryBar
        loading={loading}
        sources={data?.integrationSources || []}
      />
      {loading && (
        <div className="w-full flex items-center justify-center p-8">
          <CircularProgress size={30} />
        </div>
      )}
      {!loading &&
        (typeId === IntegrationTypeKey.IoBoard ? (
          <IntegrationDetailsDeviceGrid sources={sources} />
        ) : (
          Object.entries(groupedSources).map(([location, sources]) => {
            return (
              <IntegrationDetailsDeviceAccordion
                key={location}
                location={location}
                sources={sources}
              />
            );
          })
        ))}
    </div>
  );
}
