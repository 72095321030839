import { useFlags } from "launchdarkly-react-client-sdk";
import { useMemo } from "react";

import { useAIModelEnabled } from "@/util/featureToggles";

import { AdditionalAiClass, AlertType } from "@/generated-models";
import { usePrefixOrgSlug } from "@/hooks/useOrgRouteBase";

import { useLprEnabled } from "../Intelligence/hooks";

export function useFilterEnabledAlerts() {
  const { lprInterests } = useFlags();
  const lprUi = useLprEnabled();
  const forkliftEnabled = useAIModelEnabled(AdditionalAiClass.Forklift);

  const forkliftAlertTypes = [
    AlertType.Forklift,
    AlertType.ForkliftAbsent,
    AlertType.ForkliftIdle,
  ];

  const isForkliftEnabled = (key: AlertType) =>
    !forkliftAlertTypes.includes(key) ||
    (forkliftAlertTypes.includes(key) && forkliftEnabled);

  const isVehicleInterestListEnabled = (key: AlertType) =>
    key !== AlertType.VehicleInterestList ||
    (key === AlertType.VehicleInterestList && lprInterests && lprUi);

  return (key: AlertType) =>
    isForkliftEnabled(key) && isVehicleInterestListEnabled(key);
}

const ALERTS_PATH = "/alerts";
const ALERTS_CONFIGURE_PATH = `${ALERTS_PATH}/configure`;
const ALERTS_CREATE_PATH = `${ALERTS_CONFIGURE_PATH}/create`;

export function useAlertRoutes() {
  const prefixOrgSlug = usePrefixOrgSlug();
  return useMemo(
    () => ({
      ALERTS_BASE: prefixOrgSlug(ALERTS_PATH),
      ALERTS_CONFIGURE: prefixOrgSlug(ALERTS_CONFIGURE_PATH),
      ALERTS_CREATE: prefixOrgSlug(ALERTS_CREATE_PATH),
    }),
    [prefixOrgSlug]
  );
}
