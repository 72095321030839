import { ChevronLeft } from "@mui/icons-material";
import { Divider, Typography } from "@mui/material";
import clsx from "clsx";
import { motion } from "framer-motion";
import { Fragment } from "react";

import {
  CountingFeatureTypes,
  IntelligenceFeatureConfig,
  IntelligenceFeatureType,
} from "@/pages/Intelligence/constants";

import { useExitIfFullscreen } from "@/components/FullScreen";
import { DefaultDialog, useDialog } from "@/components/shared/Dialog";

import { usePrefixOrgSlug } from "@/hooks/useOrgRouteBase";

import { useCopilotNavigate } from "../../../CopilotPlayerSection/copilotPlayerSectionHooks";
import { useCopilotInsightQueryParam } from "../copilotLabelButtonHooks";
import { redirectCopilot } from "../utils";
import { BaseMenuItemProps } from "./BaseLabelMenuItems";

interface CopilotLabelInsightMenuItemProps extends BaseMenuItemProps {
  className?: string;
  label: string;
  featureType: IntelligenceFeatureType;
}

function LabelInsightMenuItem({
  label,
  featureType,
  cameraId,
  className,
  onCreateAutoZone,
}: CopilotLabelInsightMenuItemProps) {
  const [navigate] = useCopilotNavigate();
  const [, setInsightMenu] = useCopilotInsightQueryParam();
  const prefixOrgSlug = usePrefixOrgSlug();
  const config = IntelligenceFeatureConfig[featureType];
  const { open, ...dialogProps } = useDialog();
  const focused = navigate.insightFeature === featureType;
  const exitIfFullscreen = useExitIfFullscreen();
  const isCounting = CountingFeatureTypes.includes(featureType);

  async function onNavigateCreateInsight() {
    exitIfFullscreen();
    const confirmed = await open();
    setInsightMenu(undefined);
    if (!confirmed) return;
    const config = IntelligenceFeatureConfig[featureType];
    const id = await onCreateAutoZone(isCounting);

    const newUrl = prefixOrgSlug(`/intelligence/${config.action.linkNew}`);
    const url = new URL(`${window.location.origin}${newUrl}`);

    redirectCopilot(url, cameraId, id);
  }

  return (
    <motion.div
      className={clsx(
        "relative group flex flex-col items-start gap-[13px] py-[13px] cursor-pointer px-2 rounded-lg",
        className,
        { "animate-ripple": focused }
      )}
      onClick={onNavigateCreateInsight}
      style={
        focused
          ? {
              border: "1px solid #6F6F6F",
              background: "#4D4D4D",
            }
          : {}
      }
    >
      <Typography className="text-white text-base font-bold leading-normal">
        {config.label.displayName}
      </Typography>
      <div className="flex items-center justify-between gap-[12px]">
        <img
          alt={`${config.label.displayName} illustration`}
          width={50}
          height={50}
          className="object-cover rounded-[45px] group-hover:scale-[1.15] transition ease-in-out delay-150"
          src={config.illustration.copilot}
        />
        <Typography className="text-[13px] leading-normal opacity-50 w-[150px] text-left group-hover:opacity-100 transition ease-in-out delay-150">
          {config.label.descriptionCondensed}
        </Typography>
      </div>
      <DefaultDialog
        title={config.label.displayName}
        content={
          <span>
            Continuing will open a new tab to the create insight page. A{" "}
            {isCounting ? "suggested line through" : "zone for"}{" "}
            <strong>{label}</strong> will automatically be created and used for
            this insight. Continue?
          </span>
        }
        confirmColor="primary"
        confirmText="Create Insight"
        {...dialogProps}
      />
    </motion.div>
  );
}

export const MenuMapping = {
  motion: [],
  people: [
    IntelligenceFeatureType.PeopleCounting,
    IntelligenceFeatureType.PeopleIdleTime,
    IntelligenceFeatureType.PeoplePresence,
  ],
  vehicle: [
    IntelligenceFeatureType.VehicleCounting,
    IntelligenceFeatureType.VehicleIdleTime,
    IntelligenceFeatureType.VehiclePresence,
  ],
  forklift: [
    IntelligenceFeatureType.ForkliftCounting,
    IntelligenceFeatureType.ForkliftIdleTime,
    IntelligenceFeatureType.ForkliftPresence,
  ],
};

export function LabelInsightMenuItems(
  props: {
    label: string;
  } & BaseMenuItemProps
) {
  const [insightMenu, setInsightMenu] = useCopilotInsightQueryParam();
  const hidden = !insightMenu;
  return (
    <motion.div
      className={clsx("pb-2", {
        hidden,
      })}
      animate={hidden ? "initial" : "animate"}
      transition={{ type: "spring", stiffness: 100, mass: 0.75 }}
      variants={{
        initial: { opacity: 0, translateX: -10 },
        animate: { opacity: 1, translateX: 0 },
      }}
    >
      <div
        className="flex items-center justify-start gap-1 pt-2 group transition ease-in-out delay-150 hover:-translate-x-2 cursor-pointer"
        onClick={(e) => {
          setInsightMenu(undefined);
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <ChevronLeft className="text-white" />
        <Typography className="text-[13px] text-[#b8b8b8fc] group-hover:text-white leading-normal">
          Back
        </Typography>
      </div>
      {Object.entries(MenuMapping).map(([type, items]) => {
        return items.map((i, idx) => {
          const baseClassName = type !== insightMenu ? "hidden" : undefined;
          return (
            <Fragment key={`${type}-${idx}-${i}`}>
              <LabelInsightMenuItem
                className={baseClassName}
                {...props}
                featureType={i}
              />
              {idx + 1 < items.length && (
                <Divider
                  orientation="horizontal"
                  className={clsx(
                    "w-full h-[1px] border-[#2C2C2C]",
                    baseClassName
                  )}
                />
              )}
            </Fragment>
          );
        });
      })}
    </motion.div>
  );
}
