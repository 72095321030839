import { Skeleton, Typography } from "@mui/material";
import clsx from "clsx";
import { ReactNode } from "react";

interface IntegrationsDetailsSummaryBarProps {
  small?: boolean;
  loading?: boolean;
  count: number;
  label: string;
  className?: string;
  children?: ReactNode;
}

export function IntegrationsDetailsSummaryBar({
  small,
  loading,
  count,
  label,
  className,
  children,
}: IntegrationsDetailsSummaryBarProps) {
  return (
    <div className={clsx("flex justify-between items-center", className)}>
      <Typography
        className={clsx("font-light", {
          "text-base leading-[19px]": small,
          "text-lg leading-[21px] md:text-2xl md:leading-[28px]": !small,
        })}
      >
        {loading ? (
          <Skeleton className="w-44 h-10" variant="text" />
        ) : (
          `${count} ${label}${count !== 1 ? "s" : ""}`
        )}
      </Typography>
      {children || <div />}
    </div>
  );
}
