import { Checkbox, CircularProgress, FormControlLabel } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useField } from "formik";
import { uniq } from "lodash/fp";
import { useMemo } from "react";
import { ArrayParam, StringParam, useQueryParam } from "use-query-params";

import { required } from "@/util/form";

import { FilterBar } from "@/components/Filter/FilterBar";
import { FilterBarControls } from "@/components/Filter/FilterBarControls";
import { FilterSearch } from "@/components/Filter/FilterSearch";
import { FilterFieldProps } from "@/components/Filter/constant";
import { StatusChip } from "@/components/StatusChip";
import { LazyImage } from "@/components/shared/LazyImage";

import {
  CameraStatus,
  MaintainDashboardType,
  usePage_Maintain_MetricsQuery,
} from "@/generated-models";

import { useMaintainMetricsDashboardType } from "../../hooks";

function SelectionCheckboxColumn({ id, label }: { id: number; label: string }) {
  const [, { value }, { setValue }] = useField<number[]>({
    name: "resourceIds",
    validate: (value: any) => required(value),
  });

  return (
    <FormControlLabel
      classes={{
        label: "font-bold",
      }}
      control={
        <Checkbox
          checked={value.includes(id)}
          onChange={(e, checked) => {
            if (checked) {
              setValue([...value, id]);
            } else {
              setValue(value.filter((v) => v !== id));
            }
          }}
          name={label}
        />
      }
      label={label}
    />
  );
}

function useResourceModalDataGridProps() {
  const [type] = useMaintainMetricsDashboardType();
  const { data, loading, error } = usePage_Maintain_MetricsQuery();
  const [searchFilter] = useQueryParam("search", StringParam);
  const [locationsFilter] = useQueryParam("locations", ArrayParam);
  const [statusFilter] = useQueryParam("status", ArrayParam);

  const counts = useMemo(() => {
    const result: Record<string, number> = {};
    data?.cameras?.forEach((c) => {
      const serial = c.appliance.serialNumber;
      result[serial] = (result[serial] || 0) + 1;
    });

    return result;
  }, [data?.cameras]);

  const locations = useMemo(() => {
    return uniq(data?.cameras.map((cam) => cam.location).flat());
  }, [data?.cameras]);

  return useMemo(() => {
    let columns: GridColDef[];
    let rows;
    let filters: FilterFieldProps<unknown>[];

    function filterFn(curr: string, filterValue?: string | null) {
      return (
        !filterValue || curr.toLowerCase().includes(filterValue.toLowerCase())
      );
    }

    function filterFnArr(
      curr: string | number,
      filterValue?: (string | number | null)[] | null | undefined
    ) {
      return !filterValue || filterValue.includes(curr);
    }

    switch (type as MaintainDashboardType) {
      case MaintainDashboardType.Location:
        columns = [
          {
            field: "name",
            headerName: "Location",
            flex: 1,
            renderCell: ({ row }) => {
              return <SelectionCheckboxColumn id={row.id} label={row.name} />;
            },
          },
        ];
        rows = (locations || []).filter((r) => filterFn(r.name, searchFilter));
        filters = [];

        break;
      case MaintainDashboardType.Appliance:
        columns = [
          {
            field: "serialNumber",
            headerName: "Appliance",
            flex: 1,
            renderCell: ({ row }) => {
              return (
                <SelectionCheckboxColumn id={row.id} label={row.serialNumber} />
              );
            },
          },
          {
            field: "location.name",
            headerName: "Location",
            valueGetter: ({ row }) => row.location.name,
            flex: 1,
          },
          {
            field: "cameras",
            headerName: "Cameras",
            width: 230,
            valueGetter: ({ row }) => counts[row.serialNumber] ?? 0,
            valueFormatter: ({ value }) => `${value} Cameras`,
          },
          {
            field: "status",
            headerName: "Status",
            type: "string",
            width: 115,
            renderCell: ({ row }) => {
              const status = row.health.online
                ? CameraStatus.Online
                : CameraStatus.Offline;
              return <StatusChip status={status} />;
            },
          },
        ];
        rows = (data?.appliances || [])
          .filter((r) => filterFn(r.serialNumber, searchFilter))
          .filter((r) => filterFnArr(String(r.location.id), locationsFilter))
          .filter((r) =>
            filterFnArr(r.health.online ? "online" : "offline", statusFilter)
          );
        filters = [
          {
            placeholder: "Locations",
            label: "Locations",
            style: {},
            filterName: "locations",
            options:
              locations?.map((d) => ({
                label: d.name,
                value: String(d.id),
              })) || [],
          },
          {
            placeholder: "Status",
            label: "Status",
            style: {},
            filterName: "status",
            options: [
              {
                label: "Online",
                value: "online",
              },
              {
                label: "Offline",
                value: "offline",
              },
            ],
          },
        ];
        break;
      default:
        columns = [
          {
            field: "id",
            headerName: "Preview",
            sortable: false,
            headerClassName: "invisible",
            cellClassName: "pl-0",
            renderCell: ({ row }) => {
              return (
                <LazyImage cameraId={row.id} className="h-[57px] w-[100px]" />
              );
            },
          },
          {
            field: "name",
            headerName: "Camera Name",
            flex: 1,
            renderCell: ({ row }) => {
              return <SelectionCheckboxColumn id={row.id} label={row.name} />;
            },
          },
          {
            field: "location.name",
            headerName: "Location",
            valueGetter: ({ row }) => row.location.name,
            flex: 1,
          },
          {
            field: "status",
            headerName: "Status",
            type: "string",
            width: 115,
            sortComparator: (c1: string, c2: string) => c2.localeCompare(c1),
            renderCell: ({ row }) => {
              return <StatusChip status={row.status} />;
            },
          },
        ];
        rows = (data?.cameras || [])
          .filter((r) => filterFn(r.name, searchFilter))
          .filter((r) => filterFnArr(String(r.location.id), locationsFilter))
          .filter((r) => filterFnArr(r.status, statusFilter));
        filters = [
          {
            placeholder: "Locations",
            label: "Locations",
            style: {},
            filterName: "locations",
            options:
              locations?.map((d) => ({
                label: d.name,
                value: String(d.id),
              })) || [],
          },
          {
            placeholder: "Status",
            label: "Status",
            style: {},
            filterName: "status",
            options: [
              {
                label: "Online",
                value: "online",
              },
              {
                label: "Offline",
                value: "offline",
              },
            ],
          },
        ];
    }

    return {
      filters,
      loading,
      error,
      columns,
      rows,
      classes: {
        footerContainer: "justify-center",
        cell: "h-[58px]",
        columnHeaders: "bg-[#f4f4f4]",
        columnHeader: "text-text/70",
        "columnHeader--sorted": "!text-black font-bold",
        sortIcon: "!text-black font-bold",
      },
    };
  }, [
    counts,
    data?.appliances,
    data?.cameras,
    error,
    loading,
    locations,
    searchFilter,
    statusFilter,
    locationsFilter,
    type,
  ]);
}

export function MetricAddResourceDatagrid() {
  const {
    filters,
    error,
    loading,
    ...dataGridProps
  } = useResourceModalDataGridProps();

  return (
    <div>
      {loading && (
        <div className="flex items-center justify-center p-24">
          <CircularProgress size={40} />
        </div>
      )}
      {!loading && (
        <>
          <div className="pl-0 p-4">
            <div className="flex items-center justify-between gap-2">
              {filters.length > 0 ? (
                <FilterBarControls filters={filters} />
              ) : (
                <div />
              )}
              <FilterSearch />
            </div>
            <FilterBar className="py-4" filters={filters} />
          </div>
          <DataGrid
            className="rounded-none"
            columnHeaderHeight={40}
            disableRowSelectionOnClick
            disableColumnMenu
            initialState={{
              pagination: { paginationModel: { pageSize: 100 } },
              sorting: {
                sortModel: [{ field: "name", sort: "desc" }],
              },
            }}
            {...dataGridProps}
            classes={{
              main: "h-[50vh]",
            }}
          />
        </>
      )}
    </div>
  );
}
