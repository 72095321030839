import { Button, Divider, Paper, Typography } from "@mui/material";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

export function MaintainDetailTile({
  title,
  path,
  children,
  action,
}: {
  title: string;
  path?: string;
  children: ReactNode;
  action?: ReactNode;
}) {
  return (
    <Paper elevation={1} className="px-5 py-3">
      <div className="flex flex-col sm:flex-row justify-between items-center">
        <Typography variant="body1" className="text-lg font-bold">
          {title}
        </Typography>
        {path && (
          <Button
            color="primary"
            size="small"
            className="text-base font-bold"
            to={path}
            component={Link}
          >
            View All
          </Button>
        )}
        {action}
      </div>
      <Divider className="mt-1 mb-3" />
      {children}
    </Paper>
  );
}
