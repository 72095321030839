import { gql } from "@apollo/client";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CreateIcon from "@mui/icons-material/Create";
import RemoveIcon from "@mui/icons-material/Remove";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Fade,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Popper,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { parse, stringify } from "query-string";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { makeStyles } from "tss-react/mui";
import {
  DelimitedNumericArrayParam,
  NumberParam,
  useQueryParam,
} from "use-query-params";
import useResizeObserver from "use-resize-observer/polyfilled";

import { useBreakpoints } from "@/util/useBreakpoints";

import { ErrorMessage } from "@/components/ErrorMessage";
import { AddCamera } from "@/components/MappingTool/AddCamera";
import { AddCameraMarker } from "@/components/MappingTool/AddCameraMarker";
import { AddFloorImage } from "@/components/MappingTool/AddLayout";
import { AddedCameraIcon } from "@/components/MappingTool/AddedCameraIcon";
import { AddedCameraMarker } from "@/components/MappingTool/AddedCameraMarker";
import { Point, transFormedPoints } from "@/components/MappingTool/CameraIcon";
import { DeleteLayoutButton } from "@/components/MappingTool/DeleteLayout";
import { EditCameraMarker } from "@/components/MappingTool/EditCameraMarker";
import { MAPBOX_ACCESS_TOKEN } from "@/components/MappingTool/MapboxMap";
import { PlayerBase } from "@/components/Player/PlayerBase";
import { VideoPlayer } from "@/components/Player/VideoPlayer";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { SelectorGroup } from "@/components/View/ViewNavigation";
import { NavigationTab } from "@/components/View/sharedViewHooks";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  CamerasByLocationLayoutIdQuery,
  LocationLayout,
  LocationLayoutType,
  useAddLayoutCamerasMutation,
  useCamerasByLocationLayoutIdQuery,
  useDeleteLayoutCamMutation,
  useLocationLayoutsByTagIdQuery,
  useUpdateLayoutCameraMutation,
  useUpdateLocationLayoutMutation,
} from "@/generated-models";
import { usePermissions } from "@/hooks/usePermissions";
import { theme } from "@/layout/theme";

import { usePrefixOrgSlug } from "../../hooks/useOrgRouteBase";

const useStyles = makeStyles()((theme) => ({
  editModeBanner: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
    background: "#004D8D",
  },
  editModeBannerMessage: {
    fontWeight: "bold",
    marginLeft: theme.spacing(2),
    color: "#FFF",
  },
  mappingToolViewHeader: {
    backgroundColor: "#FFF",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  editModeContainer: {
    borderBottom: "1px solid #007CE4",
  },
  viewModeContainer: {
    borderBottom: "1px solid #C4C4C4",
    borderTop: "1px solid #C4C4C4",
  },
  openFullWidthContainer: {
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    zIndex: 1,
  },
  layoutContainer: {
    height: "100%",
    justifyContent: "center",
    display: "flex",
    overflow: "hidden",
    position: "relative",
    alignSelf: "center",
  },
  layoutImageContainer: {
    position: "relative",
    cursor: "pointer",
    alignSelf: "center",
  },
  layoutImage: {
    height: "100%",
    objectFit: "contain",
  },
  zoomButtonGroup: {
    background: "#FFF",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
    borderRadius: "10px",
  },
  popperContainer: {
    background: "none",
    boxShadow: "0px 0px 0px 0px",
  },
  assignCamButton: {
    background: "#FFF",
    "&:hover": {
      backgroundColor: "#FFF",
    },
    minWidth: "30px",
    boxShadow: "0px 0px 10.061px rgba(0, 0, 0, 0.5);",
  },
  assignedCamButton: {
    background: "#FFF",
    border: "2px solid #50ADFB",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: "#FFF",
      border: "2px solid #50ADFB",
      borderRadius: "4px",
    },
    boxShadow: "0px 0px 10.061px rgba(0, 0, 0, 0.5);",
  },
  assignCamButtonText: {
    fontWeight: "bold",
    fontStyle: "italic",
    fontSize: "12px",
  },
  assignedCamButtonText: {
    fontWeight: "bold",
    fontSize: "12px",
    color: "#50ADFB",
  },
  deleteLayoutButton: {
    background: "#FFF",
    "&:hover": {
      backgroundColor: "#fff",
    },
    minWidth: "30px",
    position: "absolute",
    left: theme.spacing(1),
    zIndex: 1,
  },
  selectionMessage: {
    alignSelf: "center",
    fontSize: "20px",
    padding: theme.spacing(1),
    textAlign: "center",
    width: "100%",
  },
  viewModeCamera: {
    display: "flex",
    position: "absolute",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
    height: "0px",
    width: "0px",
  },
  tagList: {
    padding: 0,
    width: "100%",
  },
  mapStyleChangeContainer: {
    minWidth: "100px",
    minHeight: "67px",
    display: "flex",
    alignItems: "flex-end",
    position: "absolute",
    left: "2%",
    bottom: "10%",
    border: "2px solid #FFFFFF",
    filter: "drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25))",
    borderRadius: "10px",
    cursor: "pointer",
  },
  mapStyleChangeText: {
    fontWeight: "bold",
    fontSize: "16px",
    textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    marginLeft: "8px",
    color: "#FFF",
  },
  cameraPlayer: {
    marginTop: theme.spacing(1),
    height: "136px",
    width: "auto",
    border: "2px solid #50ADFB",
    borderRadius: "4px",
    filter: "drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25))",
  },
  zoomButtonsContainers: {
    position: "absolute",
    zIndex: 1,
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  cameraHoverPopper: {
    borderRadius: 5,
  },
}));
const ZOOM_STEP = 0.1;
const MIN_STYLE_BUTTON_DIMENSIONS = {
  width: 100,
  height: 67,
};
const MAP_STYLES = {
  street: "streets-v11",
  satellite: "satellite-streets-v11",
};

interface MappingToolProps {
  children?: React.ReactNode;
  activeTag?: SelectorGroup;
  isFullWidth: boolean;
  setFullWidth: React.Dispatch<boolean>;
  setMobileViewMode?: React.Dispatch<NavigationTab>;
}
export interface HoveredCam {
  element: HTMLElement;
  cam: CamerasByLocationLayoutIdQuery["locationLayout"]["cameras"][0];
}
interface ViewportRectangle {
  topLeft: Point;
  topRight: Point;
  bottomLeft: Point;
  bottomRight: Point;
}
export type MODE = "VIEW" | "EDIT";

// default position offset while placing a new add camera icon
const DEFAULT_ADD_CAM_OFFSET = {
  top: 40,
  left: 50,
};
export type Layout = Omit<LocationLayout, "location" | "cameras">;

export function MappingTool({
  children,
  activeTag,
  isFullWidth,
  setFullWidth,
  setMobileViewMode,
}: MappingToolProps) {
  const location = useLocation();
  const { classes } = useStyles();
  const { pushSnackbar } = useFeedback();
  const { fitsDesktop } = useBreakpoints();

  const [layoutParam, setLayoutParam] = useQueryParam("layout", NumberParam);
  const [, setCams] = useQueryParam("cams", DelimitedNumericArrayParam);
  const setCam = useCallback(
    (value: number | undefined) => {
      if (!value) setCams(null);
      else setCams([value]);
      // else setCams((current) => [...(current ?? []), value]);
    },
    [setCams]
  );
  const [zoomLevel, setZoomLevel] = useState(1);
  const [newCam, setNewCam] = useState(false);
  const [editCam, setEditCam] = useState<number | null>(null);
  const [hoveredCam, setHoveredCam] = useState<HoveredCam | null>(null);
  const [mode, setMode] = useState<MODE>("VIEW");
  const [imageContainer, setImageContainer] = useState<HTMLDivElement | null>(
    null
  );
  const [imageElement, setImageElement] = useState<HTMLImageElement | null>(
    null
  );

  const [map, setMap] = useState<mapboxgl.Map | null>(null);
  const [mapContainer, setMapContainer] = useState<HTMLDivElement | null>(null);
  const [mapStyle, setMapStyle] = useState(MAP_STYLES.street);

  const { width: mapWidth = 1, height: mapHeight = 1 } = useResizeObserver<
    HTMLDivElement
  >({
    ref: mapContainer,
  });

  const { data, error: layoutsError } = useLocationLayoutsByTagIdQuery({
    ...refetchOnMountPolicy,
    skip: Boolean(!activeTag || !activeTag.id),
    variables: { id: (activeTag && activeTag.id)! },
  });
  const layouts = data?.tag?.layouts;

  const layout =
    layoutParam === -1 // Layout Param -1 is used to detect new layout creation
      ? undefined
      : layouts?.find((l) => l.id === layoutParam) || layouts?.[0];

  const {
    loading: camsLoading,
    data: cams,
  } = useCamerasByLocationLayoutIdQuery({
    ...refetchOnMountPolicy,
    skip: !layout,
    variables: { id: layout?.id! },
  });

  const getScaleDownFactor = useMemo(() => {
    return 1 / zoomLevel;
  }, [zoomLevel]);

  /** Returns a bounding box type response of the original (non transformed) layout image**/
  const originalImageCoordinates = useMemo(() => {
    if (!imageElement || !imageElement.x || !imageElement.y || !layout) {
      return {
        x: 0,
        y: 0,
        width: 1,
        height: 1,
      };
    }
    const angle = layout.angle ?? 0;
    const { x, y, width, height } = imageElement.getBoundingClientRect();
    const center: Point = {
      x: x + width / 2,
      y: y + height / 2,
    };
    const rotatedImage: ViewportRectangle = {
      topLeft: { x, y },
      topRight: { x: x + width, y },
      bottomLeft: { x, y: y + height },
      bottomRight: { x: x + width, y: y + height },
    };

    const originalImage: ViewportRectangle = {
      topLeft: transFormedPoints(center, rotatedImage.topLeft, angle * -1),
      topRight: transFormedPoints(center, rotatedImage.topRight, angle * -1),
      bottomLeft: transFormedPoints(
        center,
        rotatedImage.bottomLeft,
        angle * -1
      ),
      bottomRight: transFormedPoints(
        center,
        rotatedImage.bottomRight,
        angle * -1
      ),
    };

    return (Object.values(originalImage) as Point[]).reduce(
      (acc: { x: number; y: number; width: number; height: number }, point) => {
        const x = Math.min(acc.x, point.x);
        const y = Math.min(acc.y, point.y);
        const width = acc.x !== point.x ? Math.abs(point.x - acc.x) : acc.width;
        const height =
          acc.y !== point.y ? Math.abs(point.y - acc.y) : acc.height;
        return {
          x,
          y,
          width,
          height,
        };
      },
      {
        x: Number.POSITIVE_INFINITY,
        y: Number.POSITIVE_INFINITY,
        width: 0,
        height: 0,
      }
    );
    // eslint-disable-next-line
  }, [imageElement, imageElement?.x, imageElement?.y, layout]);

  useEffect(() => {
    if (layoutParam === -1) {
      setMode("EDIT");
    }
    setNewCam(false);
    setEditCam(null);
    setImageElement(null);
    setZoomLevel(1);
  }, [activeTag?.id, layoutParam]);
  useEffect(() => {
    if (
      !mapHeight ||
      !mapWidth ||
      !mapContainer ||
      layout?.type !== LocationLayoutType.Address
    ) {
      setMap(null);
      return;
    }

    if (map) {
      map.setStyle(`mapbox://styles/mapbox/${mapStyle}`);
      map.getCanvasContainer().style.width = `${mapWidth}px`;
      map.getCanvasContainer().style.height = `${mapHeight}px`;
      map.setCenter([layout.coordinates.x, layout.coordinates.y]);
      map.setZoom(layout.zoom ?? 17);
      map.resize();
      return;
    }

    const newMap = new mapboxgl.Map({
      accessToken: MAPBOX_ACCESS_TOKEN,
      container: mapContainer,
      style: `mapbox://styles/mapbox/${mapStyle}`,
      center: [layout.coordinates.x, layout.coordinates.y],
      zoom: layout.zoom ?? 17,
      interactive: true,
      trackResize: true,
      doubleClickZoom: false,
      dragPan: true,
      keyboard: false,
      pitchWithRotate: false,
      preserveDrawingBuffer: true,
      scrollZoom: true,
      attributionControl: false,
    });

    newMap.on("click", (d) => {
      if (
        //Event target is not an Element
        (d.originalEvent.target as Element).parentNode ===
        newMap.getCanvasContainer()
      ) {
        setEditCam(null);
      }
    });
    newMap.addControl(new mapboxgl.AttributionControl(), "bottom-left");
    newMap.addControl(new mapboxgl.NavigationControl({ showCompass: false }));
    setMap(newMap);
    // eslint-disable-next-line
  }, [mapContainer, layout?.id, mapHeight, mapWidth, mapStyle]);
  useEffect(() => {
    if (isFullWidth) return;
    if (mode === "EDIT") setMode("VIEW");
  }, [isFullWidth, mode]);

  const [updateLocationLayout] = useUpdateLocationLayoutMutation();
  const [addLayoutCameras] = useAddLayoutCamerasMutation();
  const [updateLayoutCamera] = useUpdateLayoutCameraMutation();
  const [deleteLayoutCamera] = useDeleteLayoutCamMutation();

  const hasPermission = usePermissions();
  const hasEditAccess = hasPermission("devices_manage");

  const prefixOrgSlug = usePrefixOrgSlug();

  function handleZoom(value: number) {
    if (value >= 0) {
      setZoomLevel(Math.min(2, zoomLevel + value));
    } else {
      setZoomLevel(Math.max(0.5, zoomLevel + value));
    }
  }

  /** Gets the relative position of any point from the original coordinates of the layout image **/
  function getRelativePosition(
    position: { x: number; y: number },
    translation = { x: 0, y: 0 }
  ) {
    return {
      x: Number(
        (translation.x / originalImageCoordinates.width + position.x).toFixed(3)
      ),
      y: Number(
        (translation.y / originalImageCoordinates.height + position.y).toFixed(
          3
        )
      ),
    };
  }

  const hoveredPath = stringify({
    ...parse(location.search),
    cams: hoveredCam?.cam.camera.id,
  });

  return (
    <>
      <div
        className={`flex flex-col ${
          fitsDesktop ? "h-[calc(100vh-70px)]" : "h-[calc(100%-105px)]"
        }`}
      >
        {mode === "EDIT" && (
          <div className={classes.editModeBanner}>
            <Typography className={classes.editModeBannerMessage}>
              Edit Map
            </Typography>
          </div>
        )}

        {fitsDesktop && (
          <Box
            className={clsx(classes.mappingToolViewHeader, {
              [classes.editModeContainer]: mode === "EDIT",
              [classes.viewModeContainer]: mode === "VIEW",
            })}
          >
            <Grid container justifyContent={"space-between"}>
              <Grid container item xs={isFullWidth ? 7 : 12} spacing={1}>
                <Grid item xs={isFullWidth ? 5 : 12}>
                  {children}
                </Grid>
                {layouts && layouts.length > 0 && (
                  <>
                    <Grid item xs={isFullWidth ? 4 : 12}>
                      <MapLayoutSelect
                        layout={layout}
                        layouts={layouts}
                        setLayoutParam={(id?: number) => setLayoutParam(id)}
                        resetImageElement={() => setImageElement(null)}
                      />
                    </Grid>

                    {mode === "VIEW" && isFullWidth && hasEditAccess && (
                      <Grid item xs={3} style={{ display: "flex" }}>
                        <Button
                          size={"medium"}
                          variant="outlined"
                          color="primary"
                          onClick={() => setMode("EDIT")}
                        >
                          <CreateIcon
                            fontSize="small"
                            style={{ marginRight: theme.spacing(0.5) }}
                          />
                          Edit
                        </Button>
                      </Grid>
                    )}
                  </>
                )}
              </Grid>

              {layouts && layouts.length > 0 && mode === "EDIT" && isFullWidth && (
                <Grid
                  container
                  item
                  xs={5}
                  spacing={1}
                  alignContent="center"
                  justifyContent={"flex-end"}
                >
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      disabled={!layouts || !hasEditAccess}
                      onClick={() => setLayoutParam(-1)}
                    >
                      + Add New Layout
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        setNewCam(true);
                        setEditCam(null);
                      }}
                      disabled={!layout || !hasEditAccess}
                    >
                      + Add Camera
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant={"contained"}
                      color="primary"
                      onClick={async () => {
                        if (!layout) return;

                        if (
                          map &&
                          layout.type === LocationLayoutType.Address &&
                          (layout.zoom !== map.getZoom() ||
                            layout.coordinates.x !== map.getCenter().lng ||
                            layout.coordinates.y !== map.getCenter().lat)
                        ) {
                          const { lng, lat } = map.getCenter();
                          const coordinates = { x: lng, y: lat };
                          const { errors } = await updateLocationLayout({
                            variables: {
                              input: {
                                id: layout.id,
                                coordinates,
                                zoom: map.getZoom(),
                              },
                            },
                            optimisticResponse: {
                              __typename: "Mutation",
                              updateLocationLayout: {
                                ...layout,
                                zoom: map.getZoom(),
                                coordinates: {
                                  __typename: "Point",
                                  ...coordinates,
                                },
                              },
                            },
                          }).catch((e) => ({ errors: [e] }));

                          if (errors?.length) {
                            pushSnackbar(
                              "Unable to save changes. Please try again",
                              FeedbackType.Error
                            );
                          } else {
                            pushSnackbar("Layout saved", FeedbackType.Success);
                          }
                        }

                        setNewCam(false);
                        setEditCam(null);
                        setMode("VIEW");
                      }}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Box>
        )}
        {!fitsDesktop && layouts && layouts.length > 0 && (
          <div className="px-2 py-1">
            <MapLayoutSelect
              layout={layout}
              layouts={layouts}
              setLayoutParam={(id?: number) => setLayoutParam(id)}
              resetImageElement={() => setImageElement(null)}
            />
          </div>
        )}

        <div className="p-4 bg-[#e5e5e5] grow">
          <div className="flex relative p-1 bg-white border border-[#B0B0B0] rounded h-full">
            {!activeTag ? (
              <div className="flex-center flex-col">
                <img src="/map_view.svg" alt="Map" />
                <Typography variant="h3">Select a Group or Location</Typography>
                <Typography className="text-base text-center">
                  To view or add new maps, please select a camera group or
                  location from above.
                  {!fitsDesktop &&
                    "Use Spot on a desktop to create or edit your maps."}
                </Typography>
              </div>
            ) : (
              <>
                {layoutsError && (
                  <ErrorMessage
                    title={"Unable to load layouts"}
                    description={layoutsError?.message}
                  />
                )}

                {layout ? (
                  <>
                    {!isFullWidth && !!layouts?.length && fitsDesktop && (
                      <Box m={2} className={classes.openFullWidthContainer}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => setFullWidth(true)}
                        >
                          Open Full Map
                        </Button>
                      </Box>
                    )}

                    {layout.type === LocationLayoutType.Image && (
                      <TransformWrapper
                        limitToBounds={false}
                        disabled={false}
                        maxScale={2}
                        minScale={0.5}
                        wheel={{
                          step: 0.025,
                          disabled: fitsDesktop,
                        }}
                        panning={{
                          excluded: [
                            "svg",
                            "path",
                            "g",
                            "mask",
                            "circle",
                            "input",
                          ],
                        }}
                        doubleClick={{ disabled: true }}
                        pinch={{ disabled: fitsDesktop }}
                      >
                        {({
                          centerView,
                          zoomIn,
                          zoomOut,
                          resetTransform,
                          ...rest
                        }) => (
                          <>
                            <div
                              className={classes.layoutContainer}
                              onClick={(e) => {
                                if (
                                  editCam &&
                                  (e.target === e.currentTarget ||
                                    e.target === imageContainer)
                                ) {
                                  setEditCam(null);
                                  //setNewCam(false);
                                }
                              }}
                            >
                              {mode === "EDIT" && (
                                <DeleteLayoutButton id={layout?.id} />
                              )}

                              {fitsDesktop && (
                                <Box className={classes.zoomButtonsContainers}>
                                  <ButtonGroup
                                    orientation="vertical"
                                    className={classes.zoomButtonGroup}
                                  >
                                    <Tooltip title={"Zoom In"}>
                                      <Button variant={"text"}>
                                        <AddIcon
                                          onClick={() => handleZoom(ZOOM_STEP)}
                                        />
                                      </Button>
                                    </Tooltip>
                                    <Tooltip title={"Reset Zoom"}>
                                      <Button variant={"text"}>
                                        <RotateLeftIcon
                                          onClick={() => {
                                            centerView();
                                            setZoomLevel(1);
                                          }}
                                        />
                                      </Button>
                                    </Tooltip>
                                    <Tooltip title={"Zoom Out"}>
                                      <Button variant={"text"}>
                                        <RemoveIcon
                                          onClick={() =>
                                            handleZoom(-1 * ZOOM_STEP)
                                          }
                                        />
                                      </Button>
                                    </Tooltip>
                                  </ButtonGroup>
                                </Box>
                              )}

                              <TransformComponent
                                wrapperClass={classes.layoutContainer}
                                wrapperStyle={{
                                  overflow: "visible",
                                }}
                              >
                                <div
                                  className={classes.layoutImageContainer}
                                  style={{
                                    transform: `scale(${zoomLevel})`,
                                  }}
                                  ref={(node) => {
                                    if (!node) return;
                                    setImageContainer(node);
                                  }}
                                >
                                  <img
                                    alt="Layout"
                                    src={layout.imagePath ?? ""}
                                    className={classes.layoutImage}
                                    ref={(node) => {
                                      if (node) {
                                        node.onload = () => {
                                          setImageElement(node);
                                        };
                                      }
                                    }}
                                    style={{
                                      filter: `grayscale(${
                                        layout.grayscale ? 1 : 0
                                      })`,
                                      transform: `rotate(${layout.angle}deg)`,

                                      // hack to show larger horizontal images
                                      maxWidth: `${
                                        (layout.angle ?? 0) > 0 ? "75vh" : ""
                                      }`,
                                    }}
                                  />

                                  {imageElement && newCam && (
                                    <AddCamera
                                      scale={getScaleDownFactor}
                                      locationId={layout.locationId}
                                      layoutId={layout.id}
                                      offset={DEFAULT_ADD_CAM_OFFSET}
                                      onDelete={() => setNewCam(false)}
                                      onAdd={async (args) => {
                                        const camPosition = getRelativePosition(
                                          {
                                            x:
                                              DEFAULT_ADD_CAM_OFFSET.left / 100,
                                            y: DEFAULT_ADD_CAM_OFFSET.top / 100,
                                          },
                                          args.translation
                                        );
                                        if (!camPosition) return;

                                        setNewCam(false);
                                        setEditCam(-1);
                                        const {
                                          errors,
                                        } = await addLayoutCameras({
                                          variables: {
                                            input: [
                                              {
                                                layoutId: args.layoutId,
                                                cameraId: args.cameraId,
                                                angle: args.angle,
                                                position: camPosition,
                                              },
                                            ],
                                          },
                                          optimisticResponse: {
                                            __typename: "Mutation",
                                            addLayoutCameras: [
                                              {
                                                angle: args.angle,
                                                cameraId: args.cameraId,
                                                id: -1,
                                                layoutId: args.layoutId,
                                                position: {
                                                  ...camPosition,
                                                  __typename: "Point",
                                                },
                                                camera: args.camera,
                                                __typename: "LayoutCamera",
                                              },
                                            ],
                                          },
                                          update: (cache, { data }) => {
                                            if (!data) return;
                                            setEditCam(
                                              data?.addLayoutCameras[0].id
                                            );
                                            cache.modify({
                                              id: `LocationLayout:${args.layoutId}`,
                                              fields: {
                                                cameras(existingCameras) {
                                                  return [
                                                    ...existingCameras,
                                                    ...data.addLayoutCameras.map(
                                                      (d) => ({
                                                        __ref: `LayoutCamera:${d.id}`,
                                                      })
                                                    ),
                                                  ];
                                                },
                                              },
                                            });
                                          },
                                        }).catch((e) => ({ errors: [e] }));

                                        if (errors?.length) {
                                          pushSnackbar(
                                            "Failed to Add Camera. Please try again",
                                            FeedbackType.Error
                                          );
                                        }
                                      }}
                                    />
                                  )}

                                  {!cams && (
                                    <>
                                      {camsLoading && (
                                        <>
                                          <CircularProgress /> Loading Cameras
                                        </>
                                      )}
                                    </>
                                  )}

                                  {imageElement &&
                                    cams?.locationLayout.cameras.map((cam) => {
                                      return editCam === cam.id ? (
                                        <AddCamera
                                          key={cam.id}
                                          scale={getScaleDownFactor}
                                          locationId={layout.locationId}
                                          layoutId={layout.id}
                                          initialAngle={cam.angle}
                                          camera={cam.camera}
                                          offset={{
                                            top: cam.position.y * 100,
                                            left: cam.position.x * 100,
                                          }}
                                          onUpdate={async (updateCallback) => {
                                            const args = updateCallback(cam);
                                            const camPosition = args.translation
                                              ? getRelativePosition(
                                                  cam.position,
                                                  args.translation
                                                )
                                              : {
                                                  x: cam.position.x,
                                                  y: cam.position.y,
                                                };

                                            const {
                                              errors,
                                            } = await updateLayoutCamera({
                                              variables: {
                                                id: cam.id,
                                                input: {
                                                  layoutId: args.layoutId,
                                                  cameraId: args.cameraId,
                                                  angle: args.angle,
                                                  position: camPosition,
                                                },
                                              },
                                              optimisticResponse: {
                                                __typename: "Mutation",
                                                updateLayoutCamera: {
                                                  angle: args.angle,
                                                  cameraId: args.cameraId,
                                                  id: cam.id,
                                                  layoutId: args.layoutId,
                                                  position: {
                                                    ...camPosition,
                                                    __typename: "Point",
                                                  },
                                                  camera: cam.camera,
                                                  __typename: "LayoutCamera",
                                                },
                                              },
                                            }).catch((e) => ({ errors: [e] }));

                                            if (errors?.length) {
                                              pushSnackbar(
                                                "Something went wrong. Please try again",
                                                FeedbackType.Error
                                              );
                                            }
                                          }}
                                          onDelete={async () => {
                                            const {
                                              errors,
                                            } = await deleteLayoutCamera({
                                              variables: {
                                                id: cam.id,
                                              },
                                              optimisticResponse: {
                                                __typename: "Mutation",
                                                deleteLayoutCamera: cam.id,
                                              },
                                              update: (cache, data) => {
                                                cache.evict({
                                                  id: `LayoutCamera:${cam.id}`,
                                                });
                                                try {
                                                  cache.gc();
                                                } catch (e) {
                                                  console.log(e);
                                                }
                                              },
                                            }).catch((e) => ({ errors: [e] }));

                                            if (errors?.length) {
                                              pushSnackbar(
                                                "Something went wrong. Please try again",
                                                FeedbackType.Error
                                              );
                                            }
                                          }}
                                        />
                                      ) : (
                                        <div
                                          key={`layoutcam-${cam.id}`}
                                          className={classes.viewModeCamera}
                                          style={{
                                            top: `${cam.position.y * 100}%`,
                                            left: `${cam.position.x * 100}%`,
                                            transform: `scale(${getScaleDownFactor})`,
                                          }}
                                        >
                                          <AddedCameraIcon
                                            angle={cam.angle}
                                            online={
                                              cam.camera.health.cameraOnline
                                            }
                                            showStatus={mode === "VIEW"}
                                            onMainCamHovered={
                                              fitsDesktop
                                                ? (element) =>
                                                    mode === "VIEW" &&
                                                    setHoveredCam({
                                                      element,
                                                      cam,
                                                    })
                                                : undefined
                                            }
                                            onMainCamLeft={
                                              fitsDesktop
                                                ? () =>
                                                    hoveredCam &&
                                                    setHoveredCam(null)
                                                : undefined
                                            }
                                            onMainCamClicked={() => {
                                              if (mode === "VIEW") {
                                                setCam(cam.camera.id);
                                                setFullWidth(false);
                                                if (setMobileViewMode) {
                                                  setMobileViewMode(
                                                    NavigationTab.Cameras
                                                  );
                                                }
                                              } else {
                                                setNewCam(false);
                                                setEditCam(cam.id);
                                              }
                                            }}
                                          />
                                        </div>
                                      );
                                    })}
                                </div>
                              </TransformComponent>
                            </div>
                          </>
                        )}
                      </TransformWrapper>
                    )}

                    {layout.type === LocationLayoutType.Address && (
                      <>
                        <div
                          className="w-full h-full"
                          ref={(node) => {
                            if (!node) return;
                            setMapContainer(node);
                          }}
                        />

                        {mode === "EDIT" && (
                          <DeleteLayoutButton id={layout?.id} />
                        )}

                        {map && (
                          <>
                            {newCam && (
                              <AddCameraMarker
                                map={map}
                                layout={layout}
                                setNewCam={setNewCam}
                                setEditCam={setEditCam}
                              />
                            )}
                            {cams?.locationLayout.cameras.map((cam) => {
                              if (editCam === cam.id) {
                                return (
                                  <EditCameraMarker
                                    map={map}
                                    cam={cam}
                                    layout={layout}
                                  />
                                );
                              }

                              return (
                                <AddedCameraMarker
                                  key={cam.id}
                                  cam={cam}
                                  map={map}
                                  setEditCam={setEditCam}
                                  setCam={setCam}
                                  setHoveredCam={setHoveredCam}
                                  setFullWidth={setFullWidth}
                                  mode={mode}
                                  setMobileViewMode={setMobileViewMode}
                                />
                              );
                            })}

                            <div
                              className={classes.mapStyleChangeContainer}
                              style={{
                                width: `${(mapWidth / 10).toFixed()}px`,
                                height: `${(mapWidth / 15).toFixed()}px`,
                                background: `url("https://api.mapbox.com/styles/v1/mapbox/${
                                  mapStyle === MAP_STYLES.street
                                    ? MAP_STYLES.satellite
                                    : MAP_STYLES.street
                                }/static/pin-s+bd1414(${layout.coordinates.x},${
                                  layout.coordinates.y
                                })/${layout.coordinates.x},${
                                  layout.coordinates.y
                                },15,0/${Math.max(
                                  mapWidth / 10,
                                  MIN_STYLE_BUTTON_DIMENSIONS.width
                                ).toFixed()}x${Math.max(
                                  mapWidth / 10,
                                  MIN_STYLE_BUTTON_DIMENSIONS.height
                                ).toFixed()}?access_token=${MAPBOX_ACCESS_TOKEN}")`,
                              }}
                              onClick={() => {
                                setMapStyle(
                                  mapStyle === MAP_STYLES.street
                                    ? MAP_STYLES.satellite
                                    : MAP_STYLES.street
                                );
                              }}
                            >
                              <Typography
                                className={classes.mapStyleChangeText}
                              >
                                {mapStyle === MAP_STYLES.street
                                  ? "Satellite"
                                  : "Street"}
                              </Typography>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {activeTag.isLocationGroup ? (
                      <AddFloorImage
                        tag={activeTag}
                        showIntro={!layouts?.length}
                        setLayoutParam={setLayoutParam}
                        setFullWidth={setFullWidth}
                        isFullWidth={isFullWidth}
                      />
                    ) : (
                      <div className="flex-center flex-col p-2 m-auto">
                        <img src="/map_view.svg" alt="Map" />
                        <Typography variant="h3">Select a Location</Typography>
                        <Typography className="text-base text-center mt-2">
                          Layouts can only be created for a camera group that is
                          tagged to a Location. Please select a location from
                          the drop down to add layout.
                        </Typography>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      {hoveredCam && (
        <Popper
          className={classes.cameraHoverPopper}
          open={!!hoveredCam}
          anchorEl={hoveredCam?.element}
          transition
          modifiers={[
            {
              name: "flip",
              enabled: true,
            },
            {
              name: "preventOverflow",
              options: {
                boundary: hoveredCam?.element,
              },
            },
          ]}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps}>
              <div
                style={{ width: 300 }}
                onMouseLeave={(e) => {
                  setHoveredCam(null);
                }}
                onMouseEnter={(e) => {
                  setHoveredCam(hoveredCam);
                }}
              >
                <Paper className={classes.popperContainer}>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.assignedCamButton}
                    size="small"
                    disableRipple
                    disableFocusRipple
                  >
                    <Link to={prefixOrgSlug(`/live?${hoveredPath}`)}>
                      <Typography className={classes.assignedCamButtonText}>
                        {hoveredCam?.cam.camera.name}
                      </Typography>
                    </Link>
                  </Button>
                  <div className={classes.cameraPlayer}>
                    <PlayerBase>
                      <VideoPlayer
                        sources={hoveredCam?.cam.camera.feeds}
                        showingLivestream={true}
                        muxConfig={{
                          cameraId: hoveredCam?.cam.camera.id,
                          playerName: "Mapping Tool",
                        }}
                      />
                    </PlayerBase>
                  </div>
                </Paper>
              </div>
            </Fade>
          )}
        </Popper>
      )}
    </>
  );
}

interface MapLayoutSelectProps {
  layout?: { id: number; name: string };
  layouts: { id: number; name: string }[];
  setLayoutParam: (id?: number) => void;
  resetImageElement: () => void;
}

function MapLayoutSelect({
  layout,
  layouts,
  setLayoutParam,
  resetImageElement,
}: MapLayoutSelectProps) {
  return (
    <FormControl variant="standard" size="small" fullWidth>
      <InputLabel shrink htmlFor="layout">
        Layout
      </InputLabel>
      <Select
        label="Layout"
        value={layout?.id || "__NONE__"}
        onChange={(e) => {
          const newValue = e.target.value;
          if (newValue === "__NEW__") {
            setLayoutParam(-1);
          } else if (typeof newValue === "number") {
            const layout = layouts.find(
              (l) => l.id.toString() === newValue.toString()
            );
            setLayoutParam(layout?.id);
            resetImageElement();
          }
        }}
        inputProps={{ name: "layout", id: "layout" }}
        renderValue={() => layout?.name || "Select a layout"}
      >
        <MenuItem value="__NONE__" disabled>
          Select a layout
        </MenuItem>
        {layouts.map((view) => (
          <MenuItem
            value={view.id}
            key={view.id}
            style={{
              borderBottom: "1px solid rgb(163,163,163, 0.1)",
            }}
          >
            <Grid container>
              <Grid item xs={2}>
                {layout?.id === view.id && <CheckIcon color="primary" />}
              </Grid>
              <Grid item>{view.name}</Grid>
            </Grid>
          </MenuItem>
        ))}

        <MenuItem value="__NEW__">
          <Grid container>
            <Grid item xs={2}>
              <AddIcon color="disabled" />
            </Grid>
            <Grid item className="opacity-60">
              Add New Layout
            </Grid>
          </Grid>
        </MenuItem>
      </Select>
    </FormControl>
  );
}

gql`
  mutation addLocationLayout($input: addLocationLayoutInput!) {
    addLocationLayout(input: $input) {
      id
      locationId
      name
      angle
      grayscale
      type
      address
      zoom
      coordinates {
        x
        y
      }
    }
  }
`;

gql`
  mutation updateLocationLayout($input: updateLocationLayoutInput!) {
    updateLocationLayout(input: $input) {
      id
      name
      angle
      grayscale
      type
      address
      zoom
      coordinates {
        x
        y
      }
    }
  }
`;

gql`
  query locationLayoutsByTagId($id: Int!) {
    tag(id: $id) {
      id
      layouts {
        id
        locationId
        name
        type
        imagePath
        address
        angle
        grayscale
        zoom
        coordinates {
          x
          y
        }
      }
    }
  }
`;

gql`
  mutation addLayoutCameras($input: [addLayoutCameraInput!]!) {
    addLayoutCameras(input: $input) {
      id
      layoutId
      cameraId
      angle
      position {
        x
        y
      }
      camera {
        id
        name
        still
        health {
          cameraOnline
          applianceOnline
        }
        feeds {
          tunnel
          local
          webRTC
        }
      }
    }
  }
`;

gql`
  mutation updateLayoutCamera($id: Int!, $input: addLayoutCameraInput!) {
    updateLayoutCamera(id: $id, input: $input) {
      id
      layoutId
      cameraId
      angle
      position {
        x
        y
      }
      camera {
        id
        name
        still
        health {
          cameraOnline
          applianceOnline
        }
        feeds {
          tunnel
          local
          webRTC
        }
      }
    }
  }
`;

gql`
  mutation deleteLayoutCam($id: Int!) {
    deleteLayoutCamera(id: $id)
  }
`;

gql`
  query camerasByLocationId($id: Int!) {
    location(id: $id) {
      id
      name
      cameras {
        id
        name
        still
        lifecycleState
        health {
          cameraOnline
          applianceOnline
        }
        feeds {
          tunnel
          local
          webRTC
        }
      }
    }
  }
`;

gql`
  query camerasByLocationLayoutId($id: Int!) {
    locationLayout(id: $id) {
      id
      cameras {
        id
        cameraId
        layoutId
        angle
        position {
          x
          y
        }
        camera {
          id
          name
          still
          location {
            id
          }
          health {
            cameraOnline
            applianceOnline
          }
          feeds {
            tunnel
            local
            webRTC
          }
        }
      }
    }
  }
`;

gql`
  mutation deleteLocationLayout($id: Int!) {
    deleteLocationLayout(id: $id)
  }
`;
