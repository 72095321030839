import {
  Checkbox,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography,
} from "@mui/material";
import { useRef, useState } from "react";
import { makeStyles } from "tss-react/mui";

import { CloseIcon } from "@/components/Player/PlayerIcons";

import {
  useCurrentIntegrationEventTypes,
  useHiddenSchemaAttributes,
} from "../../hooks/eventHooks";

const useStyles = makeStyles()((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    width: 310,
    pointerEvents: "auto",
  },
}));

export default function IntegrationEventCustomColumnPopover() {
  const { classes } = useStyles();
  const { attributeFields } = useCurrentIntegrationEventTypes();
  const [open, setOpen] = useState(false);
  const [hidden, setHidden] = useHiddenSchemaAttributes();
  const helpRef = useRef<SVGSVGElement | null>(null);

  function onToggle(attribute: string) {
    const itemExits = hidden.findIndex((i) => i === attribute);
    const newValues = [...hidden];
    if (hidden.includes(attribute)) {
      newValues.splice(itemExits, 1);
      setHidden(newValues);
    } else {
      setHidden([...hidden, attribute]);
    }
  }

  return (
    <>
      <button
        type="button"
        onClick={() => {
          setOpen((o) => !o);
        }}
      >
        <svg
          ref={helpRef}
          type="button"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.5775 2.50098H3.33329C2.41413 2.50098 1.66663 3.24848 1.66663 4.16764V15.8343C1.66663 16.7535 2.41413 17.501 3.33329 17.501H16.5775C17.4966 17.501 18.2441 16.7535 18.2441 15.8343V4.16681C18.2432 3.72513 18.0673 3.3018 17.7549 2.98957C17.4426 2.67733 17.0191 2.50164 16.5775 2.50098ZM6.66663 15.8343H3.33329V6.66681H6.66663V15.8343ZM11.6666 15.8343H8.33329V6.66681H11.6666V15.8343ZM13.3333 15.8343V6.66681H16.5775L16.5783 15.8343H13.3333Z"
            fill="#757575"
          />
        </svg>
      </button>
      <Popover
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={helpRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={() => setOpen(false)}
        disableRestoreFocus
        // PaperProps={
        //   stayOn
        //     ? {
        //         onMouseEnter: () => setHelpOpen(true),
        //         onMouseLeave: () => setHelpOpen(false),
        //       }
        //     : undefined
        // }
      >
        <div>
          <div className="p-4 flex items-center justify-between">
            <Typography className="font-bold text-lg leading-5">
              Customize Columns
            </Typography>
            <IconButton
              className="p-0"
              size="small"
              onClick={() => {
                setOpen((o) => !o);
              }}
            >
              <CloseIcon className="w-5 h-5" />
            </IconButton>
          </div>
          <Divider />
          <List>
            {attributeFields.map((field) => {
              const { key } = field;
              const onToggleItem = () => {
                onToggle(key);
              };
              return (
                <ListItemButton
                  onClick={onToggleItem}
                  className="py-[7px]"
                  key={key}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={!hidden.includes(key)}
                      tabIndex={-1}
                      disableRipple
                      onChange={onToggleItem}
                    />
                  </ListItemIcon>
                  <ListItemText primary={generateAttributeName(field)} />
                </ListItemButton>
              );
            })}
          </List>
        </div>
      </Popover>
    </>
  );
}

export function generateAttributeName(attribute: {
  key: string;
  description?: string | null;
}) {
  const { key, description } = attribute;

  switch (true) {
    case Boolean(description):
      return description;

    case key.includes("[]"):
      return <span className="capitalize">{key.replace(".[].", " ")}</span>;

    case key.includes("."):
      return <span className="capitalize">{key.replace(".", " ")}</span>;

    default:
      return <span className="capitalize">{key}</span>;
  }
}
