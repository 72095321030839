import {
  Container as DragContainer,
  Draggable,
} from "@edorivai/react-smooth-dnd";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  OutlinedInput,
  Tooltip,
  Typography,
} from "@mui/material";
import arrayMove from "array-move";
import clsx from "clsx";
import { Field, Formik } from "formik";
import { TextField } from "formik-mui";
import { useEffect, useMemo, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useUpdateEffect } from "react-use";

import { debounceAccumulate } from "@/util/debounceAccumulate";

import { useWallStyles, validateName } from "@/pages/VideoWall/constants";
import { useVideoWallContext } from "@/pages/VideoWall/useVideoWallContext";

import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { WallPreview } from "@/components/VideoWall/WallPreview";
import { DefaultDialog, useDialog } from "@/components/shared/Dialog";

import {
  Page_VideoWallsDocument,
  Page_VideoWallsQuery,
  useDeleteRotatingWallMutation,
  usePage_VideoWallsQuery,
  useUpdateRotatingWallMutation,
} from "@/generated-models";
import { ERROR, useCustomScrollbarStyles } from "@/layout/theme";

export function RotatingVideoWallEdit() {
  const { classes } = useWallStyles();
  const { classes: scrollbarClasses } = useCustomScrollbarStyles();

  const params = useParams<{ id: string }>();
  const id = Number(params.id);

  const { data } = usePage_VideoWallsQuery();
  const wall = data?.rotatingVideoWalls.find((wall) => wall.id === id);
  const [
    deleteWall,
    { loading: deletingWall },
  ] = useDeleteRotatingWallMutation();
  const [updateWall] = useUpdateRotatingWallMutation();

  const {
    fullscreenHandle,
    editingState: [, setEditing],
  } = useVideoWallContext();

  const { open: openDeleteWallDialog, ...deleteWallDialogProps } = useDialog();
  const { pushSnackbar } = useFeedback();
  const navigate = useNavigate();

  const [, setLastSaved] = useState<Date | null>(null);
  const [, setDebouncedUpdating] = useState(false);
  const save = useMemo(
    () =>
      debounceAccumulate(
        async function save(
          updates: {
            name?: string;
            wallIds?: number[];
            pageDuration?: number;
          }[]
        ) {
          setDebouncedUpdating(false);
          if (!wall) return;

          const { wallIds, ...restUpdate } = updates.reduceRight(
            (result, value) => {
              result.wallIds = result.wallIds ?? value.wallIds;
              result.name = result.name ?? value.name;
              return result;
            }
          );

          const update = {
            ...restUpdate,
            wallIds,
          };

          setLastSaved(new Date());

          await updateWall({
            variables: {
              id,
              update,
            },
            update: (store, { data }) => {
              if (!data) throw new Error("Failed to update wall");
              const res = store.readQuery<Page_VideoWallsQuery>({
                query: Page_VideoWallsDocument,
              })!;
              if (!res) return;
              const { videoWalls, rotatingVideoWalls } = res;
              store.writeQuery<Page_VideoWallsQuery>({
                query: Page_VideoWallsDocument,
                data: {
                  __typename: "Query",
                  rotatingVideoWalls: rotatingVideoWalls.map((w) => {
                    if (w.id === data.updateRotatingVideoWall.id)
                      return data.updateRotatingVideoWall;
                    return w;
                  }),
                  videoWalls,
                },
              });
            },
          });
        },
        1000,
        () => setDebouncedUpdating(true)
      ),
    [wall, updateWall, id]
  );

  const [editingWallIds, setEditingWallIds] = useState(null as null | number[]);

  useEffect(() => {
    if (!wall || editingWallIds) return;

    setEditingWallIds(wall.videoWalls.map((v) => v.id));
  }, [wall, editingWallIds]);

  useUpdateEffect(() => {
    if (!editingWallIds) return;

    save({ wallIds: editingWallIds });
    // eslint-disable-next-line
  }, [editingWallIds?.join("")]);

  const [pageDuration, setPageDuration] = useState(wall?.pageDuration ?? 90);

  // error and loading state should be handled in parent <VideoWallPage />
  if (!data) return null;

  if (!wall) {
    // Wall doesn't exist anymore
    localStorage.removeItem("lastViewedWallId");
    return <Navigate to="wall" replace />;
  }

  const wallNames = [...data.videoWalls, ...data.rotatingVideoWalls].map(
    (wall) => wall.name
  );
  return (
    <Box className={classes.editRotatingWallContainer}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Typography style={{ padding: 16 }} variant="h3">
          Edit Rotating Wall Set
        </Typography>

        <IconButton onClick={() => setEditing(false)} size="large">
          <CloseIcon />
        </IconButton>
      </Box>
      <Formik
        initialValues={{
          name: wall.name,
        }}
        onSubmit={() => {}}
        render={() => (
          <>
            <Box px={2}>
              <ButtonGroup fullWidth color="primary">
                <Field
                  name="name"
                  label="Video Wall Name"
                  variant="outlined"
                  size="small"
                  color="primary"
                  validate={validateName(
                    wallNames.filter((name) => name !== wall.name)
                  )}
                  onKeyUp={(e: any) => {
                    save({ name: e.target.value });
                  }}
                  classes={{
                    root: classes.wallNameInput,
                  }}
                  InputProps={{
                    classes: {
                      root: "rounded-r-none",
                      notchedOutline: classes.wallNameInputOutline,
                    },
                  }}
                  component={TextField}
                />
                <Button
                  className="border-l-0 rounded-l-none"
                  color="primary"
                  variant="outlined"
                  disabled={deletingWall}
                  style={{ width: "initial" }}
                  onClick={async () => {
                    const deleteWallConfirmed = await openDeleteWallDialog();
                    if (!deleteWallConfirmed) return;

                    fullscreenHandle.exit();

                    await deleteWall({
                      variables: { id: wall.id },
                      update: (store, { data: deleteWallResponse }) => {
                        if (!deleteWallResponse)
                          throw new Error(
                            "Unable to delete rotating wall #" + wall.id
                          );

                        setEditing(false);
                        localStorage.removeItem("lastViewedWallId");

                        const res = store.readQuery<Page_VideoWallsQuery>({
                          query: Page_VideoWallsDocument,
                        })!;
                        if (!res) return;
                        const {
                          videoWalls,
                          rotatingVideoWalls,
                          __typename,
                        } = res;
                        store.writeQuery({
                          query: Page_VideoWallsDocument,
                          data: {
                            __typename,
                            videoWalls,
                            rotatingVideoWalls: rotatingVideoWalls.filter(
                              (w) => w.id !== wall.id
                            ),
                          },
                        });
                      },
                    });

                    navigate("../wall", { replace: true });

                    pushSnackbar(
                      "Video Wall deleted successfully",
                      FeedbackType.Success
                    );
                  }}
                >
                  <DeleteForeverIcon />
                </Button>
              </ButtonGroup>
              <DefaultDialog
                title="Delete Wall"
                content={`Are you sure you want to delete "${
                  wall!.name
                }"? This action cannot be undone.`}
                disablePortal
                {...deleteWallDialogProps}
              />
            </Box>
            <Box px={2} py={1}>
              <Typography variant="caption">Selected</Typography>
            </Box>
          </>
        )}
      />
      <Box position="relative">
        <VerticalInsetShadow orientation="top" />
      </Box>
      <List
        component="div"
        className={clsx(
          classes.editRotatingWallList,
          scrollbarClasses.scrollbarContainer
        )}
      >
        <DragContainer
          behaviour="contain"
          lockAxis="y"
          onDrop={({ removedIndex, addedIndex }) => {
            if (removedIndex == null || addedIndex == null) {
              // TODO: what does this mean?
              return;
            }
            setEditingWallIds((items) =>
              arrayMove(items ?? [], removedIndex, addedIndex)
            );
          }}
        >
          {editingWallIds?.map((id) => (
            <Draggable key={id}>
              <ListItem
                component="div"
                style={{ display: "flex" }}
                classes={{
                  root: classes.wallDraggable,
                }}
              >
                <ListItemIcon
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <DragIndicatorIcon />
                  <Box m={1} />
                  <WallPreview ids={[id]} />
                  <Box m={1} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    data.videoWalls.find((v) => v.id === id)?.name ?? "uh oh"
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton
                    onClick={() =>
                      setEditingWallIds((wallIds) =>
                        wallIds!.filter((candidateId) => id !== candidateId)
                      )
                    }
                    size="large"
                  >
                    <RemoveCircleIcon style={{ opacity: 0.5 }} />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </Draggable>
          ))}
        </DragContainer>
        <Box px={2} py={1}>
          <Typography variant="caption">Available</Typography>
        </Box>
        {data.videoWalls
          .filter((v) => !editingWallIds?.includes(v.id))
          .map((v) => (
            <ListItem key={v.id} component="div">
              <ListItemIcon>
                <Box m={2.5} />
                <WallPreview ids={[v.id]} />
                <Box m={1} />
              </ListItemIcon>
              <ListItemText primary={v.name} />
              <ListItemSecondaryAction>
                <IconButton
                  color="primary"
                  onClick={() => {
                    setEditingWallIds((wallIds) => [...(wallIds ?? []), v.id]);
                  }}
                  size="large"
                >
                  <AddCircleIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
      </List>

      <Box position="relative">
        <VerticalInsetShadow orientation="bottom" />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={2}
      >
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Box className="flex-center">
            <OutlinedInput
              value={pageDuration}
              type="number"
              classes={{ root: classes.pageDurationTextField }}
              inputProps={{
                min: 10,
              }}
              onChange={(e) => {
                const value = Number(e.target.value);

                setPageDuration(value);

                if (value >= 10) {
                  save({ pageDuration: value });
                }
              }}
              error={pageDuration < 10}
            />
            <Box m={1} />
            Seconds duration on each wall
          </Box>
          {pageDuration < 10 && (
            <Typography variant="caption" style={{ color: ERROR }}>
              Page rotating requires a minimum value of 10 seconds
            </Typography>
          )}
        </Box>

        <Tooltip
          title="Finish Editing"
          style={{
            flexShrink: 0,
          }}
        >
          <Button
            onClick={() => setEditing(false)}
            variant="contained"
            color="primary"
            size="medium"
          >
            Done
          </Button>
        </Tooltip>
      </Box>
    </Box>
  );
}

function VerticalInsetShadow({
  orientation,
}: {
  orientation: "top" | "bottom";
}) {
  return (
    <div
      style={{
        background: `linear-gradient(${
          orientation === "top" ? 180 : 0
        }deg, rgba(0, 0, 0, .2) 0%, transparent 100%)`,
        width: "100%",
        height: 8,
        position: "absolute",
        ...(orientation === "top" ? { top: "100%" } : { bottom: "100%" }),
      }}
    >
      &nbsp;
    </div>
  );
}
