export const COMPARATIVE_COLORS: [string, string, string][] = [
  ["#C5EAFF", "#1FAEFF", "#0072B1"],
  ["#F8D0FF", "#E01FFF", "#9C00B6"],
  ["#C3FFC9", "#60FF70", "#00AE12"],
  ["#FFFCA0", "#F6ED00", "#C0B900"],
  ["#FFC9C9", "#FF1F1F", "#AE0000"],
  ["#FFDA93", "#FFA800", "#946100"],
];

export enum ComparativeTabType {
  IDLE_PERCENTAGE = 0,
  IDLE_COUNT = 1,
  COUNT_IN_OUT = 2,
  COUNT_IN = 3,
  COUNT_OUT = 4,
  PRESENCE = 5,
}
