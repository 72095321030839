import { IntegrationTypeKey } from "@/generated-models";

type IntegrationLabels = {
  prefix: string;
  createLabel: string;
  device: string;
  devices: string;
  events: string;
};

const shared = {
  events: "Events",
};

export function getLabels(type: IntegrationTypeKey): IntegrationLabels {
  switch (type) {
    case IntegrationTypeKey.IoBoard:
      return {
        prefix: "I/O",
        createLabel: "Setup I/O Board",
        device: "Input",
        devices: "Inputs",
        ...shared,
      };
    default:
      return {
        prefix: "",
        createLabel: "Create Integration",
        device: "Device",
        devices: "Devices",
        ...shared,
      };
  }
}
