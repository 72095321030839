import { Typography } from "@mui/material";
import lowerFirst from "lodash/lowerFirst";

import { useBreakpoints } from "@/util/useBreakpoints";

import HelpPopover from "@/components/HelpPopover";

import { DeviceScanByIdQuery } from "@/generated-models";

import { CAMERA_STREAM_SETTINGS_MOBILE_TEXT } from "./constants";

interface StreamSelectHelpProps {
  streams: NonNullable<
    DeviceScanByIdQuery["deviceScan"]
  >["channels"][number]["streams"][number][];
  onMenuClose?: () => void;
  onOpenNativeProxy?: () => void;
}

export function StreamSelectionHelp({
  streams,
  onMenuClose = () => {},
  onOpenNativeProxy = () => {},
}: StreamSelectHelpProps) {
  const { fitsDesktop } = useBreakpoints();

  return (
    <HelpPopover title="Stream Selection Help" stayOn>
      <Typography variant="body1" style={{ fontSize: 14, marginTop: 4 }}>
        Your camera may have multiple streams with different resolutions or
        other characteristics.
        <br />
        <br />
        Spot has found{" "}
        {streams?.length === 1 ? (
          <>
            1 stream for selection.{" "}
            <strong>
              Test an alternate path below if stream is unexpected.
            </strong>
          </>
        ) : (
          <>
            {streams?.length ?? 0} streams for selection.{" "}
            <strong>
              Select a stream to use or test an alternate path below.
            </strong>
          </>
        )}
        <br />
        <br />
        If streams are still unexpected,{" "}
        {fitsDesktop ? (
          <>
            reconfigure your device's settings by{" "}
            <span
              className="text-primary cursor-pointer underline pointer-events-auto"
              onClick={() => {
                onMenuClose();
                onOpenNativeProxy();
              }}
            >
              accessing the Native Device Config.
            </span>
          </>
        ) : (
          lowerFirst(CAMERA_STREAM_SETTINGS_MOBILE_TEXT)
        )}
      </Typography>
    </HelpPopover>
  );
}
