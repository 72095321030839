import CircleIcon from "@mui/icons-material/Circle";
import { Button, Skeleton, Typography } from "@mui/material";
import clsx from "clsx";
import { uniq } from "lodash/fp";
import { useMemo } from "react";
import { Link } from "react-router-dom";

import { pluralize } from "@/util/pluralize";

import { ErrorMessage } from "@/components/ErrorMessage";

import {
  IntegrationVendorKey,
  Page_IntegrationsQuery,
  useIntegrationSourcesQuery,
} from "@/generated-models";
import { usePrefixOrgSlug } from "@/hooks/useOrgRouteBase";

import { useIntegrationSetupState } from "../../IntegrationsOld/hooks";

interface IntegrationActiveVendorCardProps {
  integration: Page_IntegrationsQuery["integrations"][number];
}

interface IntegrationActiveVendorCardContentProps {
  integration: Page_IntegrationsQuery["integrations"][number];
}

interface VendorLabel {
  singular: string;
  plural: string;
}

function IntegrationConfiguredTypeContentSummary({
  tagsCount,
  sourcesCount,
  cameraCount,
  vendorKey,
}: {
  tagsCount: number;
  sourcesCount: number;
  cameraCount: number;
  vendorKey: IntegrationVendorKey;
}) {
  const tagsLabel = pluralize(
    {
      1: "Tag",
      multi: "Tags",
    },
    tagsCount
  );

  const isSpot = vendorKey === IntegrationVendorKey.Spot;
  const vendorLabels: { [key in IntegrationVendorKey]?: VendorLabel } = {
    [IntegrationVendorKey.Halo]: {
      singular: "HALO Device",
      plural: "HALO Devices",
    },
    [IntegrationVendorKey.Spot]: {
      singular: "Access Control Point",
      plural: "Access Control Points",
    },
    [IntegrationVendorKey.SpotIntercom]: {
      singular: "Intercom Device",
      plural: "Intercom Devices",
    },
  };

  // Fallback to default labels if vendorKey is not defined in vendorLabels
  const { singular, plural } = vendorLabels[vendorKey] || {
    singular: "Access Point",
    plural: "Access Points",
  };

  const accessPointsLabel = pluralize(
    {
      1: singular,
      multi: plural,
    },
    sourcesCount
  );

  const sensorLabel = pluralize(
    {
      1: "Input",
      multi: "Inputs",
    },
    sourcesCount
  );

  const camerasLabel = pluralize(
    {
      1: "Camera",
      multi: "Cameras",
    },
    cameraCount
  );

  if (isSpot) {
    return (
      <>
        <strong>{sourcesCount}</strong> {sensorLabel}
        {", "}
        <strong>{cameraCount}</strong> {camerasLabel}
      </>
    );
  }

  return (
    <>
      <strong>{tagsCount} </strong>
      {tagsLabel}
      {", "}
      <strong>{sourcesCount}</strong> {accessPointsLabel}
      {", "}
      <strong>{cameraCount}</strong> {camerasLabel}
    </>
  );
}

function IntegrationConfiguredTypeContent({
  integration,
}: IntegrationActiveVendorCardContentProps) {
  const { id, vendor } = integration;
  const { online, loading } = useIntegrationSetupState(id);
  const { type } = vendor;
  const prefixOrgSlug = usePrefixOrgSlug();

  const { data, error } = useIntegrationSourcesQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        id,
      },
    },
  });

  const sourcesList = useMemo(() => data?.integrationSources || [], [
    data?.integrationSources,
  ]);
  const tags = useMemo(() => uniq(sourcesList.flatMap((s) => s.tags)), [
    sourcesList,
  ]);

  const cameraCount = useMemo(() => {
    return sourcesList.flatMap((s) => s.cameras).length;
  }, [sourcesList]);

  if (error) {
    return <ErrorMessage title="Error" description={error.toString()} />;
  }

  return (
    <>
      <>
        <Typography className="text-base leading-[19px]">
          {loading ? (
            <Skeleton variant="text" className="w-[250px] lg:w-[350px]" />
          ) : (
            <IntegrationConfiguredTypeContentSummary
              tagsCount={tags.length}
              sourcesCount={sourcesList.length}
              cameraCount={cameraCount}
              vendorKey={vendor.key}
            />
          )}
        </Typography>
        <div className="flex items-center justify-start gap-1 mt-1 mb-4">
          {!loading && (
            <>
              <CircleIcon
                className={clsx("w-[10px] h-[10px] -mt-0.5", {
                  "text-[#B8EF72]": online,
                  "text-[#db6262]": !online,
                })}
              />
              <Typography className="text-sm leading-[19px] tracking-[0.15px]">
                {online ? "Online & Active" : "Offline"}
              </Typography>
            </>
          )}
          {loading && (
            <>
              <Skeleton variant="circular" className="w-[10px] h-[10px]" />
              <Skeleton variant="text" className="w-16 h-[18px] mb-[1px]" />
            </>
          )}
        </div>
      </>
      <div className="flex items-center justify-between">
        <Button
          variant="contained"
          color="primary"
          className="shadow-none rounded w-[87px]"
          component={Link}
          to={prefixOrgSlug(
            `/integrations/${type?.key.toLowerCase()}/${vendor?.key.toLowerCase()}/${id}`
          )}
        >
          View
        </Button>
      </div>
    </>
  );
}

export function IntegrationActiveVendorCard({
  integration,
}: IntegrationActiveVendorCardProps) {
  const { vendor, name } = integration;
  const { logoSrc } = vendor;
  return (
    <div className="rounded-2xl bg-white flex flex-col items-center shadow-[0_19px_22px_rgba(0,42,82,0.1)] p-8">
      <div className="flex flex-col gap-3 w-full">
        <div className="flex items-center justify-between">
          <Typography className="text-primary text-lg leading-[21px] font-bold">
            {name || `${vendor.name} Integration`}
          </Typography>
          <div>
            <img
              className="h-6"
              alt={name}
              src={logoSrc?.replace("integrations/", "integrations/icon-")}
            />
          </div>
        </div>
        <div>
          <IntegrationConfiguredTypeContent integration={integration} />
        </div>
      </div>
    </div>
  );
}
