import clsx from "clsx";
import { decodeString, encodeString, useQueryParam } from "use-query-params";

export enum LayoutSize {
  Small = "small",
  Medium = "medium",
  Large = "large",
}

export function LayoutSelect() {
  return (
    <div className="flex items-center gap-2">
      <div className="text-xs opacity-50">Layout:</div>
      <LayoutSizeButton size={LayoutSize.Large} />
      <LayoutSizeButton size={LayoutSize.Medium} />
      <LayoutSizeButton size={LayoutSize.Small} />
    </div>
  );
}

const layoutIconLength: Record<LayoutSize, number> = {
  [LayoutSize.Large]: 6,
  [LayoutSize.Medium]: 12,
  [LayoutSize.Small]: 20,
};

function LayoutSizeButton({ size }: { size: LayoutSize }) {
  const [param, setParam] = useLayoutParam();
  const layout = param ?? LayoutSize.Medium;

  return (
    <button
      className={clsx(
        "grid h-4 w-[1.375rem] gap-px [&>div]:disabled:bg-primary [&>div]:enabled:bg-[#D9D9D9]",
        {
          "grid-cols-3": size === LayoutSize.Large,
          "grid-cols-4": size === LayoutSize.Medium,
          "grid-cols-5": size === LayoutSize.Small,
        }
      )}
      onClick={() => setParam(size === LayoutSize.Medium ? undefined : size)}
      disabled={layout === size}
    >
      {Array.from({ length: layoutIconLength[size] }).map((_, i) => (
        <div key={i} className="w-full h-full" />
      ))}
    </button>
  );
}

const LayoutSizeParam = {
  encode: (value: LayoutSize | undefined) => encodeString(value),
  decode: (value: string | (string | null)[] | null | undefined) =>
    decodeString(value) as LayoutSize | null | undefined,
};

export function useLayoutParam() {
  return useQueryParam("layout", LayoutSizeParam);
}
