import DeleteIcon from "@mui/icons-material/DeleteForever";
import WarningIcon from "@mui/icons-material/ErrorOutline";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import { Button, CircularProgress } from "@mui/material";
import gql from "graphql-tag";
import React, { useState } from "react";

import { useLocationCapacity } from "@/components/Genius/LocationCapacityGuard";
import { MaxCapacityTooltip } from "@/components/Genius/MaxCapacityTooltip";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { DefaultDialog, useDialog } from "@/components/shared/Dialog";

import {
  DeviceListDocument,
  LifecycleStates,
  LocationCapacityDocument,
  useUpdateCamLifecycleStateMutation,
} from "@/generated-models";

export function CamLifecycleButtons({
  id,
  name,
  lifecycleState,
  aiEnabled,
  resetForm,
  buttonStyle,
  hideActivateButton = false,
  onClose = () => {},
}: {
  id: number;
  name: string;
  lifecycleState: LifecycleStates;
  aiEnabled: boolean;
  resetForm: () => void;
  buttonStyle?: React.CSSProperties;
  hideActivateButton?: boolean;
  onClose?: () => void;
}) {
  const { pushSnackbar } = useFeedback();
  const [dialogState, setDialogState] = useState(LifecycleStates.Enabled);
  const [
    updateCamLifecycleState,
    { loading },
  ] = useUpdateCamLifecycleStateMutation({
    onError: (error) => pushSnackbar(error.message, FeedbackType.Error),
    onCompleted: () =>
      pushSnackbar(
        `Successfully ${
          dialogState === LifecycleStates.Deleting ? "deleted" : "updated"
        } the camera`,
        FeedbackType.Success
      ),
  });
  const {
    locationId,
    maxCapacityReached,
    maxAICapacityReached,
    guardCapacityAndShowModal,
  } = useLocationCapacity();

  const { open, ...dialogProps } = useDialog();

  const isActive = lifecycleState === LifecycleStates.Enabled;

  return (
    <>
      {lifecycleState !== LifecycleStates.Deleting && (
        <Button
          color="primary"
          disabled={loading}
          startIcon={isActive ? <VideocamOffIcon /> : <DeleteIcon />}
          onClick={async () => {
            const nextState = isActive
              ? LifecycleStates.Disabled
              : LifecycleStates.Deleting;
            setDialogState(nextState);
            const confirmed = await open();
            if (!confirmed) return;
            // Reset form in case form is dirty
            resetForm();
            updateCamLifecycleState({
              variables: { id, lifecycleState: nextState },
              refetchQueries: [
                { query: LocationCapacityDocument, variables: { locationId } },
                {
                  query: DeviceListDocument,
                  variables: { locationId: locationId },
                },
              ],
            });
            onClose();
          }}
        >
          {isActive ? "Deactivate" : "Delete"}
        </Button>
      )}

      {lifecycleState === LifecycleStates.Disabled && !hideActivateButton && (
        <>
          <div className="grow" />
          <MaxCapacityTooltip
            maxCapacityReached={maxCapacityReached}
            maxAICapacityReached={aiEnabled ? maxAICapacityReached : undefined}
          >
            <Button
              color="primary"
              variant="contained"
              disabled={loading}
              style={{
                fontSize: 12,
                ...buttonStyle,
              }}
              onClick={async () => {
                if (guardCapacityAndShowModal(aiEnabled)) return;

                setDialogState(LifecycleStates.Enabled);
                const confirmed = await open();
                if (!confirmed) return;
                updateCamLifecycleState({
                  variables: { id, lifecycleState: LifecycleStates.Enabled },
                  refetchQueries: [
                    {
                      query: LocationCapacityDocument,
                      variables: { locationId },
                    },
                  ],
                });
                onClose();
              }}
            >
              Activate Camera
              {maxCapacityReached ? (
                <>
                  <div className="w-1" />
                  <WarningIcon className="text-base" />
                </>
              ) : (
                loading && (
                  <>
                    <div className="w-1" />
                    <CircularProgress size="20px" />
                  </>
                )
              )}
            </Button>
          </MaxCapacityTooltip>
        </>
      )}

      <DefaultDialog
        {...getDialogContent(name, dialogState)}
        {...dialogProps}
      />
    </>
  );
}

function getDialogContent(name: string, state: LifecycleStates) {
  switch (state) {
    case LifecycleStates.Deleting: {
      return {
        title: `Are you sure you want to delete ${name}?`,
        confirmText: "Delete Camera",
        content:
          "Removing the camera will also delete all the camera footage stored on the appliance. This action cannot be undone.",
        verifyText: "DELETE",
      };
    }
    case LifecycleStates.Enabled: {
      return {
        title: `Are you sure you want to activate ${name}?`,
        confirmText: "Activate Camera",
        content:
          "Activating the camera will cause it to start recording LIVE footage.",
      };
    }
    case LifecycleStates.Disabled:
    default: {
      return {
        title: `Are you sure you want to deactivate ${name}?`,
        confirmText: "Deactivate Camera",
        content:
          "Deactivating the camera will cause it to stop recording LIVE footage. You will still be able to watch previously recorded footage.",
      };
    }
  }
}

export const UPDATE_CAMERA_LIFECYCLE_STATE = gql`
  mutation updateCamLifecycleState(
    $id: Int!
    $lifecycleState: LifecycleStates!
  ) {
    updateLifecycleState(id: $id, lifecycleState: $lifecycleState) {
      id
      lifecycleState
    }
  }
`;
