import { isValid } from "date-fns/fp";
import Hls, {
  ErrorData,
  ErrorTypes,
  FragChangedData,
  Fragment,
  InitPTSFoundData,
  Level,
  LevelLoadedData,
} from "hls.js";
import { useAtom, useSetAtom } from "jotai";
import { inRange } from "lodash";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import {
  MuxConfiguration,
  MuxMetadata,
  useMuxMetadata,
} from "@/util/useMuxMetadata";
import { MuxMonitor } from "@/util/useMuxMonitoring";

import {
  HlsDebugOverlay,
  useHlsDebugState,
} from "@/components/Player/HlsDebugOverlay";
import {
  defaultPlayerId,
  playerControlsState,
  playingIntentState,
  qualityLevelsState,
  readyState,
  useMultiCamVodTimeCallback,
  useMultiPlayerBuffering,
  usePlaybackRate,
  vodTimeState,
  wallclockPtsState,
  wallclockTimeState,
} from "@/components/Player/PlayerBase";
import {
  livePlaylistLoader,
  vodPlaylistLoader,
} from "@/components/Player/PlaylistLoader";
import { StreamSources } from "@/components/Player/StreamSources";
import { useQuality } from "@/components/Player/playerControlsMachine";
import {
  PlayerMachineEvent,
  usePlayerService,
  usePlaylistReachable,
  useSource,
  useSources,
} from "@/components/Player/playerMachine";

import { CameraFeeds } from "@/generated-models";

function timeRangesEnd(r: TimeRanges) {
  return r.length && r.end(r.length - 1);
}

const MATCH_PTS_THRESHOLD = 2;

interface BaseVideoPlayerProps {
  playerId?: string;
  sources?: StreamSources | null;
  showingLivestream?: boolean;
  autoPlay?: boolean;
  poster?: string;
  currentTime?: number;
  wallclockStartTime?: Date;
  forceMuted?: boolean;
  forceAutoQuality?: boolean;
  forceHighestQuality?: boolean;
  maxAutoLevel?: string;
  muxMetadata?: MuxMetadata;
}

export function BaseVideoPlayer({
  playerId = defaultPlayerId,
  sources,
  showingLivestream,
  autoPlay = true,
  poster,
  currentTime = 0,
  wallclockStartTime,
  forceMuted,
  forceAutoQuality = false,
  forceHighestQuality = false,
  maxAutoLevel,
  muxMetadata,
}: BaseVideoPlayerProps) {
  const forceNativeHLS = localStorage.getItem("forceNativeHLS") === "true";
  const playbackRate = usePlaybackRate();
  const correctedPlaybackRate = showingLivestream ? 1 : playbackRate;

  const { send } = usePlayerService();
  const setVodTime = useSetAtom(vodTimeState(playerId));
  const getTime = useMultiCamVodTimeCallback();
  const hlsDebug = useHlsDebugState();

  const setWallclockTime = useSetAtom(wallclockTimeState(playerId));
  const setWallclockPts = useSetAtom(wallclockPtsState(playerId));

  usePlaylistsReachable(useSources());

  const source = useSource(sources ?? undefined);
  const playlistReachable = usePlaylistReachable();

  const hlsSrc = source?.includes("m3u8");

  // If Media Source is supported, use HLS.js to play video
  // Fallback to using a regular video player if HLS is supported by default in the user's browser
  const useHlsJs = Hls.isSupported() && hlsSrc && !forceNativeHLS;
  const _selectedQuality = useQuality();
  const selectedQuality = forceAutoQuality ? "auto" : _selectedQuality;

  useEffect(() => {
    if (!sources) return;
    send({
      type: PlayerMachineEvent.SOURCES_CHANGED,
      sources,
    });
    // eslint-disable-next-line
  }, [sources?.tunnel, sources?.local]);

  // continuation is used by the timeline, making sure
  // playback position is retained after the changing the clip duration
  const [continuation, setContinuation] = useState(0);
  const [playerElement, setPlayerElement] = useState<HTMLVideoElement | null>(
    null
  );
  const playerRef = useCallback(
    (node: HTMLVideoElement | null) => setPlayerElement(node),
    [setPlayerElement]
  );

  const [hls, setHls] = useState<Hls | null>(null);

  const setPlayerControls = useSetAtom(playerControlsState(playerId));
  const [playerReadyState, setReadyState] = useAtom(readyState(playerId));
  const multiPlayerBuffering = useMultiPlayerBuffering();
  const [playingIntent, setPlayingIntent] = useAtom(playingIntentState);

  useEffect(() => {
    setPlayingIntent(autoPlay);
  }, [autoPlay, setPlayingIntent, sources?.tunnel]);

  useEffect(() => {
    if (playerElement) {
      playerElement.currentTime = currentTime;
    }
  }, [currentTime, playerElement]);

  useEffect(() => {
    if (!playerElement) return;
    if (!playingIntent) {
      if (!playerElement.paused) {
        playerElement.pause();
      }
    } else {
      // https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
      playerElement
        .play()
        .catch((e) => !!localStorage.debugHls && console.log(e));
    }

    // Pause video playback if we detect buffering. This allows us to properly track
    // rebuffering events in Mux.

    if (multiPlayerBuffering) {
      // ios safari doesn't support playbackRate set to 0. So, this will prevent multi player from working on iOS
      // https://developer.apple.com/documentation/webkitjs/htmlmediaelement/1629746-playbackrate
      playerElement.playbackRate = 0;
    } else if (!playerElement.playbackRate) {
      playerElement.playbackRate = correctedPlaybackRate;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiPlayerBuffering, playerElement, playerReadyState, playingIntent]);

  const setQualityLevels = useSetAtom(qualityLevelsState);

  const clockStartTime = wallclockStartTime?.getTime();
  useEffect(() => {
    if (!playerElement) return;
    const player = playerElement;

    // Track start time offset locally here, so we don't have tie this useEffect()
    // call to that piece of react state.
    let startTimeOffset = 0;
    let currentFragment: Fragment | null = null;
    const initPtsMap = new Map<Fragment, InitPTSFoundData>();
    let currentInitPts: InitPTSFoundData | null = null;

    let seekBuffer = { position: 0, buffer: 0 };
    setPlayerControls((current) => ({
      ...current,
      getPlayerElement: () => playerElement!,
      seek: (
        position: number | ((prevPosition: number) => number),
        addStartTimeOffset = false
      ) => {
        const currentPosition = player.currentTime;
        let p = currentPosition;
        if (typeof position === "function") {
          // The seekBuffer acts to buffer the seek position deltas _while_ JW player
          // is still in the process of applying a previous `seek()`, and hasn't updated
          // its internal `position` yet. This happens when quickly tapping the
          // back/forward 10 sec buttons.
          if (seekBuffer.position === currentPosition) {
            const bufferedPosition = seekBuffer.position + seekBuffer.buffer;
            const newPosition = position(bufferedPosition);
            const delta = newPosition - bufferedPosition;
            seekBuffer.buffer += delta;
            p = newPosition;
          } else {
            const d = position(p) - p;
            seekBuffer = { position: p, buffer: d };
            p += d;
          }
        } else {
          p = position;
        }

        function applySeek() {
          if (!player.duration) return;

          const newPosition = Math.min(
            player.duration,
            Math.max(
              0,
              p + (addStartTimeOffset ? startTimeOffset ?? 0 : 0) / 1000
            )
          );
          player.currentTime = newPosition;

          // Immediately apply seek time - otherwise the progressbar would only update on the next
          // tick, which will feel like a slight delay.
          setVodTime((current) => ({
            ...current!,
            position: newPosition,
          }));
        }

        // Wait for a player duration to be available
        if (player.duration) {
          applySeek();
        } else {
          player.addEventListener("durationchange", applySeek, { once: true });
        }
      },
      setContinuation: (c) => {
        setContinuation(c + (startTimeOffset ?? 0) / 1000);
      },
      setFullscreen: (value) => {
        if (value) {
          const anyPlayer = player as any;
          if (player.requestFullscreen) {
            player.requestFullscreen();
          } else if (anyPlayer.mozRequestFullScreen) {
            // Mozilla current API
            anyPlayer.mozRequestFullScreen();
          } else if (anyPlayer.webkitRequestFullScreen) {
            // Webkit current API
            anyPlayer.webkitRequestFullScreen();
          } else if (anyPlayer.webkitEnterFullscreen) {
            // This is the IOS Mobile edge case
            anyPlayer.webkitEnterFullscreen();
          }
        } else {
          const anyDocument = document as any;
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (anyDocument.webkitExitFullscreen) {
            anyDocument.webkitExitFullscreen();
          } else if (anyDocument.mozCancelFullScreen) {
            anyDocument.mozCancelFullScreen();
          } else if (anyDocument.msExitFullscreen) {
            anyDocument.msExitFullscreen();
          }
        }
      },
      setVolume: (v) => {
        player.muted = v === 0;
        player.volume = v / 500;
      },
      forceQuality: (quality, callback) => {
        if (!hls) return callback();

        const newLevelIndex = hls.levels.findIndex(
          (l) => l.height + "p" === quality
        );
        if (newLevelIndex !== -1) {
          hls.currentLevel = newLevelIndex;
        }

        callback();
      },
      play: () => {
        if (player.currentTime === player.duration) {
          setVodTime((time) => time && { ...time, position: 0 });
        }
        player.play().catch((e) => !!localStorage.debugHls && console.log(e));
      },
      pause: player.pause.bind(player),
    }));

    function handlePlaying() {
      send(PlayerMachineEvent.PLAYING);
      setContinuation(0);
    }

    player.addEventListener("playing", handlePlaying);

    function handlePause() {
      send(PlayerMachineEvent.PAUSED);
    }

    player.addEventListener("pause", handlePause);

    function handleEnded() {
      send(PlayerMachineEvent.COMPLETE);
    }

    player.addEventListener("ended", handleEnded);

    function handleError() {}

    player.addEventListener("error", handleError);

    function handleLoadedMetadata() {
      setVodTime({
        position: player.currentTime,
        duration: player.duration,
        bufferPercent: 0,
      });
      setReadyState(player.readyState);
      if (player.videoHeight) {
        send({
          type: PlayerMachineEvent.SET_VISUAL_QUALITY,
          value: player.videoHeight,
        });

        if (!hls) {
          setQualityLevels([
            {
              label: `Auto`,
              width: player.videoWidth,
              height: player.videoHeight,
              bitrate: -1,
            },
          ]);
        }
      }
    }

    player.addEventListener("loadedmetadata", handleLoadedMetadata);

    function updateReadyState() {
      setReadyState(player.readyState);
      // Is this redundant?
      if (player.readyState < 3) send(PlayerMachineEvent.BUFFERING);
    }

    function updateCanPlayState() {
      updateReadyState();
      send({
        type: PlayerMachineEvent.SET_VIDEO_DIMENSIONS,
        dimensions: { width: player.videoWidth, height: player.videoHeight },
      });
    }

    player.addEventListener("waiting", updateReadyState);
    player.addEventListener("progress", updateReadyState);
    player.addEventListener("canplay", updateCanPlayState);
    player.addEventListener("canplaythrough", updateReadyState);
    player.addEventListener("loadstart", updateReadyState);

    function handleTimeUpdate(this: HTMLVideoElement) {
      const playerElement = this;
      // I think we don't need this, but leaving it here in case we run into issues
      // updateReadyState();

      // if (hls) {
      //   console.log({
      //     latency: hls.latency,
      //     targetLatency: hls.targetLatency,
      //     maxLatency: hls.maxLatency,
      //     liveSyncPosition: hls.liveSyncPosition,
      //     drift: hls.drift,
      //     // maxAutoLevel: hls.maxAutoLevel,
      //     // autoLevelCapping: hls.autoLevelCapping,
      //     // capLevelToPlayerSize: hls.capLevelToPlayerSize,
      //   });
      //   // console.log(hls.bandwidthEstimate / 1000000);
      // }

      // Update video progress
      setVodTime((current) => ({
        ...current,
        bufferPercent:
          (timeRangesEnd(playerElement.buffered) / playerElement.duration) *
          100,
        duration: playerElement.duration,
        position: playerElement.currentTime,
      }));

      // Set wallclock time
      if (currentFragment?.programDateTime) {
        setWallclockTime(
          new Date(
            currentFragment.programDateTime +
              Math.round(
                (playerElement.currentTime - currentFragment.start) * 1000
              )
          )
        );
        if (currentInitPts) {
          setWallclockPts(
            currentInitPts.initPTS +
              playerElement.currentTime * currentInitPts.timescale
          );
        }
      } else if (clockStartTime) {
        setWallclockTime(
          new Date(
            clockStartTime + Math.round(playerElement.currentTime * 1000)
          )
        );
      } else if ("getStartDate" in player) {
        const iosTime = getTrackStartTimeIOS(player);
        if (iosTime) {
          // Because iOS plays HLS natively, the FRAG_CHANGED event is never fired and currentFrag is always undefined.
          // Instead iOS provides a getStartDate() function that let's us get the first fragment init time
          // https://developer.apple.com/documentation/webkitjs/htmlmediaelement/1634352-getstartdate
          setWallclockTime(
            new Date(
              iosTime.getTime() + Math.round(playerElement.currentTime * 1000)
            )
          );
        }
      }
    }

    player.addEventListener("timeupdate", handleTimeUpdate);

    function handleHlsError(_: any, data: ErrorData) {
      console.log("HLS error:", data);

      switch (data.type) {
        case ErrorTypes.MEDIA_ERROR:
          break;
      }
    }

    hls?.on(Hls.Events.ERROR, handleHlsError);

    function handleHlsLevelLoaded(_: any, data: LevelLoadedData) {
      const details = data.details as any;
      const offsetFromManifest: number | undefined = details?.startTimeOffset;
      if (offsetFromManifest != null) {
        startTimeOffset = offsetFromManifest * 1000;
      }
    }

    hls?.on(Hls.Events.LEVEL_LOADED, handleHlsLevelLoaded);

    function handleHlsFragChanged(_: any, data: FragChangedData) {
      currentFragment = data.frag;
      currentInitPts = initPtsMap.get(data.frag) ?? null;

      if (!currentInitPts) {
        for (let [key, value] of initPtsMap.entries()) {
          const targetPts = data.frag.appendedPTS || 0;
          if (
            inRange(
              key.appendedPTS || 0,
              targetPts - MATCH_PTS_THRESHOLD,
              targetPts + MATCH_PTS_THRESHOLD
            )
          ) {
            currentInitPts = value;
            return;
          }
        }
      }
    }

    hls?.on(Hls.Events.FRAG_CHANGED, handleHlsFragChanged);

    function handleInitialPts(_: any, data: InitPTSFoundData) {
      initPtsMap.set(data.frag, data);
      // console.log({ currentInitPts })
    }

    hls?.on(Hls.Events.INIT_PTS_FOUND, handleInitialPts);

    return () => {
      player.removeEventListener("playing", handlePlaying);
      player.removeEventListener("pause", handlePause);
      player.removeEventListener("ended", handleEnded);
      player.removeEventListener("error", handleError);
      player.removeEventListener("loadedmetadata", handleLoadedMetadata);
      player.removeEventListener("waiting", updateReadyState);
      player.removeEventListener("progress", updateReadyState);
      player.removeEventListener("canplay", updateCanPlayState);
      player.removeEventListener("canplaythrough", updateReadyState);
      player.removeEventListener("timeupdate", handleTimeUpdate);

      hls?.off(Hls.Events.ERROR, handleHlsError);
      hls?.off(Hls.Events.LEVEL_LOADED, handleHlsLevelLoaded);
      hls?.off(Hls.Events.FRAG_CHANGED, handleHlsFragChanged);
      hls?.off(Hls.Events.INIT_PTS_FOUND, handleInitialPts);

      setVodTime(null);
    };
  }, [
    send,
    setPlayerControls,
    setVodTime,
    playlistReachable,
    setContinuation,
    setWallclockTime,
    setWallclockPts,
    playerElement,
    hls,
    setQualityLevels,
    clockStartTime,
    setReadyState,
  ]);

  // Code below is for iOS edge case where player will pause after closing fullscreen
  useEffect(() => {
    if (!playerElement) return;

    function handlePause(this: HTMLVideoElement) {
      const isFullscreen = (this as any).webkitDisplayingFullscreen as boolean;
      if (!isFullscreen && playingIntent && this.readyState > 2) {
        this.play().catch(() => console.warn("Playing video failed"));
      }
    }

    playerElement.addEventListener("pause", handlePause);
    return () => {
      playerElement.removeEventListener("pause", handlePause);
    };
  }, [playingIntent, playerElement]);

  useEffect(() => {
    if (!playerElement) return;
    playerElement.playbackRate = correctedPlaybackRate;
  }, [correctedPlaybackRate, playerElement]);

  const hlsConfig = useMemo(async () => {
    const time = (await getTime())();
    return {
      ...defaultHlsConfig,
      debug: localStorage.debugHls === "true",
      startPosition: continuation || time?.position || -1,
      capLevelToPlayerSize: !maxAutoLevel, // This will overwrite autoLevelCapping if true
      // startLevel: localStorage.hlsStartLevel
      //   ? Number(localStorage.hlsStartLevel)
      //   : -1,
      pLoader: showingLivestream ? livePlaylistLoader : vodPlaylistLoader,
    } as any;
    // Continuation hack: only update the startPosition when the source changes, not
    // when the continuation changes, because that would not allow us to reset the
    // continuation after it has been applied.
    // eslint-disable-next-line
  }, [source]);

  useEffect(() => {
    let hls: Hls;

    async function _initPlayer() {
      if (!hlsSrc || !source || !playerElement) return;
      hls = new Hls({ enableWorker: false, ...(await hlsConfig) });

      hls.attachMedia(playerElement);

      hls.on(Hls.Events.MEDIA_ATTACHED, () => {
        hls.loadSource(source);

        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          playerElement
            .play()
            .catch((e) => !!localStorage.debugHls && console.log(e));
          playerElement.playbackRate = correctedPlaybackRate;
        });
      });

      hls.on(Hls.Events.ERROR, function (_, data) {
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              hls.startLoad();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              hls.recoverMediaError();
              break;
            default:
              _initPlayer();
              break;
          }
        }
      });

      hls.on(Hls.Events.MANIFEST_PARSED, (_, data) => {
        const mapQualityLevels = (l: Level) => ({
          label: l.height + "p",
          width: l.width,
          height: l.height,
          bitrate: l.bitrate,
        });

        if (forceHighestQuality) {
          // Set current level to highest (original) quality
          const highest = Math.max(...data.levels.map((l) => l.height));
          hls.currentLevel = data.levels.findIndex((l) => l.height === highest);

          // Set quality option for the menu
          setQualityLevels(
            data.levels
              .filter((l) => l.height === highest)
              .map(mapQualityLevels)
          );
        } else {
          // Set current level to selected quality in menu
          hls.currentLevel = data.levels.findIndex(
            (l) => l.height + "p" === selectedQuality
          );

          // Cap auto level if needed
          if (maxAutoLevel) {
            hls.autoLevelCapping = data.levels.findIndex(
              (l) => l.height + "p" === maxAutoLevel
            );
          }

          // Set quality option for the menu
          setQualityLevels(data.levels.map(mapQualityLevels));
        }
      });

      // newHls.on(Hls.Events.LEVEL_LOADED, console.log);
      // newHls.on(Hls.Events.LEVEL_LOADING, console.log);
      // newHls.on(Hls.Events.LEVEL_UPDATED, console.log);
      // hls.on(Hls.Events.LEVEL_SWITCHING, (_, data) => {
      //   localStorage.hlsStartLevel = data.level;
      // });
      hls.on(Hls.Events.LEVEL_SWITCHED, (_, data) => {
        // localStorage.hlsStartLevel = data.level;

        const level = hls.levels[data.level];
        if (!level) return; // woops, not supposed to happen

        send({
          type: PlayerMachineEvent.SET_VISUAL_QUALITY,
          value: level.height,
        });
      });

      setHls(hls);
    }

    // Check for Media Source support
    if (useHlsJs) {
      _initPlayer();
    } else {
      setHls(null);
    }

    return () => {
      if (hls != null) {
        // We're required to clear the hls instance to ensure we only start a single mux tracker per hls instance.
        // If we don't, there will be one effect run where mux is already started because the old hls instance
        // is still around. By setting the state to null when destroying the hls instance, we ensure that mux
        // tracking is only initialized once the new hls instance has been set up.
        hls.destroy();
        setHls(null);
      } else {
        // On Native HLS for iOS the player keeps requesting old camera segments because it is still being referenced
        // somewhere. This is a hack to make sure the player is paused and the source set to null which prevents
        // the player from making any requests
        playerElement?.pause();
        playerElement?.setAttribute("src", "");
      }
    };
    // eslint-disable-next-line
  }, [
    hlsConfig,
    playerElement,
    source,
    hlsSrc,
    setQualityLevels,
    send,
    setHls,
    setPlayingIntent,
  ]);

  const hlsLevelsLength = hls?.levels.length;
  useEffect(() => {
    if (!hls || !hlsLevelsLength || forceHighestQuality) return;

    hls.nextLevel = hls.levels.findIndex(
      (l) => l.height + "p" === selectedQuality
    );
  }, [selectedQuality, hls, hlsLevelsLength, forceHighestQuality]);

  useEffect(() => {
    if (!hls || !hlsLevelsLength) return;

    if (!maxAutoLevel) {
      hls.autoLevelCapping = -1;
    } else {
      hls.autoLevelCapping = hls.levels.findIndex(
        (l) => l.height + "p" === maxAutoLevel
      );
    }
  }, [maxAutoLevel, hls, hlsLevelsLength]);

  if (!playlistReachable || !source) {
    return null;
  }

  const hlsPlayerProps = {
    "data-cy": "video-player",
    muted: forceMuted,
    ref: playerRef,
    playsInline: true,
    poster,
    className: "bg-black w-full h-full",
    // auth is handled through a query param, no need to send cookies.
    // crossOrigin is required to prevent CORS issues when creating
    // a snapshot from the video.
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/video#attr-crossorigin
    crossOrigin: "anonymous" as const,
  };

  let muxMonitor: ReactNode | null = null;
  if (playerElement && source && (!useHlsJs || hls)) {
    muxMonitor = (
      <MuxMonitor
        playerElement={playerElement}
        source={source}
        sources={sources}
        hls={hls}
        data={muxMetadata}
      />
    );
  }

  const videoProps = {
    src: source,
    autoPlay,
  };
  return (
    <>
      {hlsDebug && useHlsJs && (
        <HlsDebugOverlay hls={hls} playerElement={playerElement} />
      )}
      <video {...hlsPlayerProps} {...(!useHlsJs ? videoProps : {})} />
      {muxMonitor}
    </>
  );
}

export type VideoPlayerProps = Omit<BaseVideoPlayerProps, "muxMetadata"> & {
  muxConfig?: MuxConfiguration;
  kiosk?: boolean;
};

export function VideoPlayer(props: VideoPlayerProps) {
  const metadata = useMuxMetadata(props.muxConfig);

  return <BaseVideoPlayer {...props} muxMetadata={metadata} />;
}

const defaultHlsConfig = {
  // Caps the quality when the player is small
  // capLevelToPlayerSize: true, // Switching this of for now to see if this improves auto mode
  // Makes sure the player starts in "auto" quality mode
  startLevel: -1,
  lowLatencyMode: false,
};

function usePlaylistsReachable(
  sources?: Omit<CameraFeeds, "__typename"> | null
) {
  const { send } = usePlayerService();
  const { local, tunnel } = sources ?? {};
  useEffect(() => {
    // Reset states on new sources
    if (!tunnel) return;
    if (!tunnel.includes(".m3u8")) {
      // ONLY CHECKING HLS PLAYLISTS FOR POC
      send(PlayerMachineEvent.TUNNEL_SOURCE_REACHABLE);
      return;
    }
    let timerId: number;
    let cancelled = false;
    let attempts = {} as Record<string, "loading" | "failed" | "succeeded">;

    async function attemptFetch({
      url,
      reachableEvent,
      retryLoop = false,
      timeout,
    }: {
      url: string;
      reachableEvent:
        | PlayerMachineEvent.LOCAL_SOURCE_REACHABLE
        | PlayerMachineEvent.TUNNEL_SOURCE_REACHABLE;
      retryLoop?: boolean;
      timeout?: number;
    }) {
      try {
        attempts[url] = "loading";

        // Fetch timeout
        let controller: AbortController | undefined = undefined;
        if (timeout) {
          controller = new AbortController();
          setTimeout(() => controller!.abort(), timeout);
        }

        const res = await fetch(url, { signal: controller?.signal });
        if (cancelled) return;
        if (res.status !== 200) {
          throw new Error("Unable to load source");
        }
        attempts[url] = "succeeded";
        send(reachableEvent);
      } catch (error) {
        if (cancelled) return;
        attempts[url] = "failed";
        if (Object.values(attempts).every((status) => status === "failed")) {
          send(PlayerMachineEvent.ALL_SOURCES_FAILED);
        }
        if (retryLoop) {
          // Retry in 5 sec
          timerId = window.setTimeout(
            () =>
              attemptFetch({ url, reachableEvent, retryLoop: true, timeout }),
            5000
          );
        }
      }
    }

    // Check if not empty string
    if (local)
      attemptFetch({
        url: local,
        reachableEvent: PlayerMachineEvent.LOCAL_SOURCE_REACHABLE,
        timeout: 1500,
      });
    attemptFetch({
      url: tunnel,
      reachableEvent: PlayerMachineEvent.TUNNEL_SOURCE_REACHABLE,
      retryLoop: true,
    });

    return () => {
      cancelled = true;
      if (timerId) window.clearTimeout(timerId);
    };
  }, [tunnel, local, send]);
}

function getTrackStartTimeIOS(player: HTMLVideoElement) {
  const res = (player as any).getStartDate() as Date;
  if (isValid(res)) return res;
  return null;
}
