import { memoize } from "lodash/fp";
import { useMemo } from "react";

import { MaintainCamera } from "@/pages/Maintain/hooks";

import { usePrefixOrgSlug } from "@/hooks/useOrgRouteBase";

const MAINTAIN_PATH = "/maintain";

export function useMaintainRoutes() {
  const prefixOrgSlug = usePrefixOrgSlug();
  return useMemo(
    () => ({
      MAINTAIN_BASE: prefixOrgSlug(MAINTAIN_PATH),
    }),
    [prefixOrgSlug]
  );
}

type ExpandedCamera = MaintainCamera & {
  streamCounter?: number;
};

export type ExpandedCameras = ExpandedCamera[];

export const expandCamerasForStreams = memoize(function (
  cameras: ExpandedCameras
): ExpandedCameras {
  const expandedCameras: ExpandedCameras = [];

  if (cameras) {
    // Create a shallow copy of the cameras and modify it
    [...cameras].forEach((camera) => {
      if (camera.device.isFisheye) {
        const targetStreamId = camera.stream.id;
        camera.device.channels.forEach((channel, channelIndex) => {
          channel.streams.forEach((stream) => {
            if (stream.id === targetStreamId) {
              camera.streamCounter = channelIndex + 1;
              expandedCameras.push(camera);
            }
          });
        });
      } else {
        expandedCameras.push(camera);
      }
    });
  }

  // Group cameras by IP address
  const groups: Record<string, typeof expandedCameras> = {};
  expandedCameras.forEach((camera) => {
    const ip = camera.device.ip;
    if (!groups[ip]) {
      groups[ip] = [];
    }
    groups[ip].push(camera);
  });

  // Sort each group based on streamCounter and flatten
  const sortedExpandedCameras: ExpandedCameras = [];
  for (const ip in groups) {
    groups[ip].sort((a, b) => (a.streamCounter || 0) - (b.streamCounter || 0));
    sortedExpandedCameras.push(...groups[ip]);
  }

  return sortedExpandedCameras;
});
