import CheckIcon from "@mui/icons-material/Check";
import HeightIcon from "@mui/icons-material/Height";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  ClickAwayListener,
  Hidden,
  Modal,
  Popper,
  Slide,
} from "@mui/material";
import clsx from "clsx";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { addMinutes, format, subDays } from "date-fns/fp";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useRef, useState } from "react";

import { IntelligenceLogoV2 } from "@/icons/intelligenceIcons";

import { useAIModelEnabled } from "@/util/featureToggles";
import { useBreakpoints } from "@/util/useBreakpoints";

import { intelligentFiltersConfig } from "@/pages/Search/intelligence/intelligence";
import {
  useHideBoundingBoxes,
  useRangeParam,
  useSearchSubjects,
  useSetSearchSubjects,
} from "@/pages/Search/searchHooks";

import {
  DateTimeRangePopper,
  MobileDateTimeRangePopper,
} from "@/components/DateTimePicker/DateTimeRangePicker";
import { ZendeskArticle, ZendeskLink } from "@/components/Zendesk/ZendeskLink";

import { AdditionalAiClass } from "@/generated-models";

import {
  useResetPeopleAttributeFilters,
  useResetVehicleAttributeFilters,
} from "./SubjectSearch/components/ActiveFiltersBar";
import {
  PeopleAttributeFilters,
  VehicleAttributeFilters,
} from "./SubjectSearch/components/AttributeFilters";

interface SearchFilterSectionProps
  extends Omit<SearchRangeInputProps, "mainContainerRef">,
    SubjectFilterButtonsProps {
  attributesEnabled: boolean;
  hasAdvancedAi: boolean;
}

export function SearchFilterSection(props: SearchFilterSectionProps) {
  const {
    firstSegmentTime,
    timezone,
    intelligenceFilters,
    activeCameraIds,
    attributesEnabled,
    hasAdvancedAi,
  } = props;
  const { fitsLarge } = useBreakpoints();
  const mainContainerRef = useRef<HTMLDivElement>(null);
  const { peopleAttributeSearch, vehicleAttributeSearch } = useFlags();

  return (
    <div
      className="overflow-hidden rounded-t-xl rounded-b-none"
      style={{ minHeight: "4rem" }}
      ref={mainContainerRef}
    >
      <div className={clsx("w-full flex", { "flex-wrap": !fitsLarge })}>
        <SearchRangeInput
          firstSegmentTime={firstSegmentTime}
          timezone={timezone}
          mainContainerRef={mainContainerRef}
        />
        <SubjectFilterButtons
          intelligenceFilters={intelligenceFilters}
          activeCameraIds={activeCameraIds}
        />
      </div>
      {peopleAttributeSearch && attributesEnabled && (
        <PeopleAttributeFilters hasAdvancedAi={hasAdvancedAi} />
      )}
      {vehicleAttributeSearch && attributesEnabled && (
        <VehicleAttributeFilters hasAdvancedAi={hasAdvancedAi} />
      )}
    </div>
  );
}

const formatDate = format("E MMM d, y");
const formatTime = format("h:mm");
const formatAmPm = format("a");

const renderTime = (date: Date, label?: string) => (
  <div className="text-primary leading-tight">
    {label && <div className="text-xs opacity-80 text-primary">{label}</div>}
    <div>{formatDate(date)}</div>
    <div className="font-bold text-lg leading-tight">
      {formatTime(date)}
      <span className="text-2xs">{formatAmPm(date)}</span>
    </div>
  </div>
);

interface SearchRangeInputProps {
  firstSegmentTime?: string | null;
  timezone: string;
  mainContainerRef: React.RefObject<HTMLDivElement>;
}

function SearchRangeInput({
  firstSegmentTime,
  timezone,
  mainContainerRef,
}: SearchRangeInputProps) {
  const { fitsDesktop, fitsTablet } = useBreakpoints();
  const { rangeStart, rangeEnd, setRangeParam } = useRangeParam();

  const [popperOpen, setPopperOpen] = useState(false);
  const close = () => setPopperOpen(false);

  const startValue = utcToZonedTime(rangeStart, timezone);
  const endValue = utcToZonedTime(rangeEnd, timezone);

  const timeRangePopperProps = {
    startValue,
    endValue,
    onChange: (start: Date, end: Date) => {
      close();
      setRangeParam({
        start: zonedTimeToUtc(start, timezone),
        end: zonedTimeToUtc(end, timezone),
      });
    },
    close,
    reset: () => setRangeParam(null),
    minDate: utcToZonedTime(
      firstSegmentTime ? firstSegmentTime : subDays(30, new Date()),
      timezone
    ),
    maxDate: utcToZonedTime(addMinutes(60, new Date()), timezone),
  };

  return (
    <ClickAwayListener onClickAway={close}>
      <div className="grow">
        <div
          className="flex items-center py-3 md:py-4 px-3 cursor-pointer bg-primary bg-opacity-20 md:hover:bg-opacity-30"
          onClick={() => setPopperOpen((open) => !open)}
        >
          <SearchIcon className="text-4xl text-primary" />
          <ZendeskLink color="primary" article={ZendeskArticle.TIMELAPSE} />
          <div className="flex items-center mx-auto">
            {renderTime(startValue, fitsTablet ? "Start" : undefined)}
            <HeightIcon className="rotate-90 mx-3 text-4xl text-primary opacity-50" />
            {renderTime(endValue, fitsTablet ? "End" : undefined)}
          </div>
        </div>
        {fitsDesktop ? (
          <Popper
            open={popperOpen}
            anchorEl={mainContainerRef.current}
            className="z-30"
          >
            <DateTimeRangePopper {...timeRangePopperProps} />
          </Popper>
        ) : (
          <Modal open={popperOpen} onClose={close}>
            <Slide direction="up" in={popperOpen} mountOnEnter unmountOnExit>
              <div className="absolute bottom-0 w-full">
                <MobileDateTimeRangePopper {...timeRangePopperProps} />
              </div>
            </Slide>
          </Modal>
        )}
      </div>
    </ClickAwayListener>
  );
}

interface SubjectFilterButtonsProps {
  intelligenceFilters?: boolean;
  activeCameraIds?: number[];
}

function SubjectFilterButtons({
  intelligenceFilters,
  activeCameraIds,
}: SubjectFilterButtonsProps) {
  const forkliftEnabled = useAIModelEnabled(
    AdditionalAiClass.Forklift,
    activeCameraIds
  );
  const subjects = useSearchSubjects();
  const setSubjects = useSetSearchSubjects();
  const resetPeopleAttributeFilters = useResetPeopleAttributeFilters();
  const resetVehicleAttributeFilters = useResetVehicleAttributeFilters();

  return (
    <div className="flex flex-col p-2 gap-2 grow">
      <div className="flex items-center justify-between">
        <IntelligenceLogoV2 />
        <BoundingBoxButton />
      </div>
      <div className="[@media(min-width:380px)]:flex items-center justify-center gap-1 md:gap-2 grow flex-wrap sm:flex-nowrap w-full">
        <div className="inline-flex items-center sm:justify-center gap-1 md:gap-2 w-full">
          {[
            intelligentFiltersConfig.motion,
            intelligentFiltersConfig.people,
            intelligentFiltersConfig.vehicle,
            ...(forkliftEnabled ? [intelligentFiltersConfig.forklift] : []),
          ]
            .slice(0, intelligenceFilters ? (forkliftEnabled ? 4 : 3) : 1) // Takes only motion if intelligence is not enabled but bigquery search is forced
            .map(({ id, title, icon, objectIds, color }) => {
              const selected =
                subjects !== undefined &&
                objectIds.every((v) => subjects.includes(v));

              return (
                <Button
                  key={id}
                  className="rounded-md h-10 transition-colors text-center min-w-[75px] sm:min-w-[110px] font-normal flex gap-1 w-full"
                  style={{
                    background: selected ? color(1) : color(0.05),
                    color: selected ? "white" : color(1),
                    padding: "0 12px",
                    fontSize: "12px",
                  }}
                  size="small"
                  startIcon={selected && <CheckIcon />}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (selected) {
                      setSubjects((prev) =>
                        prev?.filter((x) => !objectIds.includes(x))
                      );
                      if (id === "people") resetPeopleAttributeFilters();
                      else if (id === "vehicle") resetVehicleAttributeFilters();
                    } else {
                      setSubjects((prev) => (prev ?? []).concat(objectIds));
                    }
                  }}
                >
                  {title}
                  <Hidden smDown>
                    <div className={clsx(!selected && "opacity-30")}>
                      {icon}
                    </div>
                  </Hidden>
                </Button>
              );
            })}
        </div>
      </div>
    </div>
  );
}

function BoundingBoxButton() {
  const [hiddenBB, setHiddenBB] = useHideBoundingBoxes();

  return (
    <div className="relative">
      <Button
        size="small"
        variant="outlined"
        color="primary"
        className={clsx(
          "text-[10px] leading-normal font-normal rounded-[6px] w-[117px] h-[24px] border-transparent px-2",
          {
            "bg-[#E1F2FF]": !hiddenBB,
          }
        )}
        onClick={() => {
          setHiddenBB((h) => !h);
        }}
      >
        {!hiddenBB && <CheckIcon className="w-[10px] h-[16px] mr-0.5" />}
        All Bounding Boxes
      </Button>

      <svg
        width="117"
        height="24"
        viewBox="0 0 117 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx("absolute -mt-6 pointer-events-none", {
          "opacity-40": hiddenBB,
        })}
      >
        <path
          d="M0 4.5C0 3.90905 0.116396 3.32389 0.342542 2.77792C0.568688 2.23196 0.900156 1.73588 1.31802 1.31802C1.73588 0.900156 2.23196 0.568688 2.77792 0.342542C3.32389 0.116396 3.90905 -2.58312e-08 4.5 0L4.5 1.16963C4.06265 1.16963 3.62958 1.25578 3.22552 1.42314C2.82146 1.59051 2.45433 1.83582 2.14507 2.14507C1.83582 2.45433 1.59051 2.82146 1.42314 3.22552C1.25578 3.62958 1.16963 4.06265 1.16963 4.5H0Z"
          fill="#007CE4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.5 0.500009L111.5 0.5L111.5 0L5.5 9.26678e-06L5.5 0.500009Z"
          fill="#007CE4"
        />
        <path
          d="M117 4.5C117 3.90905 116.884 3.32389 116.657 2.77792C116.431 2.23196 116.1 1.73588 115.682 1.31802C115.264 0.900156 114.768 0.568688 114.222 0.342542C113.676 0.116396 113.091 -2.58312e-08 112.5 0L112.5 1.16963C112.937 1.16963 113.37 1.25578 113.774 1.42314C114.179 1.59051 114.546 1.83582 114.855 2.14507C115.164 2.45433 115.409 2.82146 115.577 3.22552C115.744 3.62958 115.83 4.06265 115.83 4.5H117Z"
          fill="#007CE4"
        />
        <rect y="5.5" width="0.5" height="13" fill="#007CE4" />
        <rect x="116.5" y="5.5" width="0.5" height="13" fill="#007CE4" />
        <path
          d="M0 19.5C0 20.0909 0.116396 20.6761 0.342542 21.2221C0.568688 21.768 0.900156 22.2641 1.31802 22.682C1.73588 23.0998 2.23196 23.4313 2.77792 23.6575C3.32389 23.8836 3.90905 24 4.5 24L4.5 22.8304C4.06265 22.8304 3.62958 22.7442 3.22552 22.5769C2.82146 22.4095 2.45433 22.1642 2.14507 21.8549C1.83582 21.5457 1.59051 21.1785 1.42314 20.7745C1.25578 20.3704 1.16963 19.9374 1.16963 19.5H0Z"
          fill="#007CE4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.5 23.5L111.5 23.5L111.5 24L5.5 24L5.5 23.5Z"
          fill="#007CE4"
        />
        <path
          d="M117 19.5C117 20.0909 116.884 20.6761 116.657 21.2221C116.431 21.768 116.1 22.2641 115.682 22.682C115.264 23.0998 114.768 23.4313 114.222 23.6575C113.676 23.8836 113.091 24 112.5 24L112.5 22.8304C112.937 22.8304 113.37 22.7442 113.774 22.5769C114.179 22.4095 114.546 22.1642 114.855 21.8549C115.164 21.5457 115.409 21.1785 115.577 20.7745C115.744 20.3704 115.83 19.9374 115.83 19.5H117Z"
          fill="#007CE4"
        />
      </svg>
    </div>
  );
}
