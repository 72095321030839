import CloseIcon from "@mui/icons-material/Close";
import CarIcon from "@mui/icons-material/DirectionsCar";
import CalendarIcon from "@mui/icons-material/Event";
import LocationIcon from "@mui/icons-material/LocationOn";
import { Button, Chip, Divider } from "@mui/material";
import { format } from "date-fns/fp";
import { keyBy } from "lodash/fp";
import { useMemo } from "react";
import {
  DelimitedArrayParam,
  StringParam,
  useQueryParams,
} from "use-query-params";

import {
  CameraIcon,
  ClothingLowerIcon,
  ClothingUpperIcon,
} from "@/icons/Icons";

import {
  useArraySearchFilter,
  useRangeParam,
  useRangeParamWithoutDefaults,
  useSetArraySearchFilter,
} from "@/pages/Search/searchHooks";

import {
  useActiveCamIds,
  useActiveGroupId,
  useSetActiveCamIds,
} from "@/components/View/sharedViewHooks";

import { useCamDrawerBaseQuery, useGroupsQuery } from "@/generated-models";

import { AgeIcon, GenderIcon } from "../../icons/SearchIcons";
import {
  attributeAgesMap,
  attributeColorsMap,
  useGenderParam,
  vehicleMakesMap,
  vehicleTypesMap,
} from "./AttributeFilters";

const formatRange = format("M/d h:mmaaa");

export function ActivePeopleFiltersBar() {
  const { rangeStart, rangeEnd } = useRangeParamWithoutDefaults();
  const { setRangeParam } = useRangeParam();
  const [activeGroupId, setActiveGroup] = useActiveGroupId();
  const activeCamIds = useActiveCamIds();
  const setActiveCamIds = useSetActiveCamIds();
  const upperColor = useArraySearchFilter("clothingUpper");
  const setUpperColor = useSetArraySearchFilter("clothingUpper");
  const lowerColor = useArraySearchFilter("clothingLower");
  const setLowerColor = useSetArraySearchFilter("clothingLower");
  const [gender, setGender] = useGenderParam();
  const age = useArraySearchFilter("age");
  const setAge = useSetArraySearchFilter("age");

  const { data: groupsData } = useGroupsQuery();
  const groups = groupsData?.groups;
  const groupsMap = useMemo(() => keyBy("id", groups), [groups]);
  const activeGroup = activeGroupId ? groupsMap[activeGroupId] : undefined;
  const { data: camerasData } = useCamDrawerBaseQuery();
  const cameras = camerasData?.cameras;
  const camerasMap = useMemo(() => keyBy("id", cameras), [cameras]);
  const activeCams = activeCamIds.map((id) => camerasMap[id]).filter(Boolean);

  const hasSearchFilters = Boolean(
    (rangeStart && rangeEnd) || activeGroup || activeCams.length
  );
  const hasAttributeFilters = Boolean(
    upperColor || lowerColor || gender || age
  );
  if (!hasSearchFilters && !hasAttributeFilters) return null;

  return (
    <div className="flex flex-wrap items-center gap-1.5 mt-3 px-4 py-2 bg-white rounded">
      <div className="text-xs mr-1.5">Active Filters:</div>
      {rangeStart && rangeEnd && (
        <FilterChip
          label={`${formatRange(rangeStart)} - ${formatRange(rangeEnd)}`}
          onDelete={() => setRangeParam(null)}
          Icon={CalendarIcon}
        />
      )}
      {activeGroup && (
        <FilterChip
          label={activeGroup.name}
          onDelete={() => setActiveGroup(undefined)}
          Icon={LocationIcon}
        />
      )}
      {activeCams.map((cam) => (
        <FilterChip
          key={cam.id}
          label={cam.name}
          onDelete={() =>
            setActiveCamIds((cur) => cur?.filter((id) => id !== cam.id))
          }
          Icon={CameraIcon}
        />
      ))}
      {hasSearchFilters && hasAttributeFilters && (
        <Divider orientation="vertical" className="h-2 mx-3 border-[#DADADA]" />
      )}
      <ActivePeopleAttributeFilters
        upperColor={upperColor}
        setUpperColor={setUpperColor}
        lowerColor={lowerColor}
        setLowerColor={setLowerColor}
        gender={gender}
        setGender={setGender}
        age={age}
        setAge={setAge}
      />
      <ClearAllButton
        onClick={() => {
          setRangeParam(null);
          setActiveGroup(undefined);
          setActiveCamIds(undefined);
          setUpperColor(undefined);
          setLowerColor(undefined);
          setGender(undefined);
          setAge(undefined);
        }}
      />
    </div>
  );
}

export function ActiveAttributeFilters() {
  const upperColor = useArraySearchFilter("clothingUpper");
  const setUpperColor = useSetArraySearchFilter("clothingUpper");
  const lowerColor = useArraySearchFilter("clothingLower");
  const setLowerColor = useSetArraySearchFilter("clothingLower");
  const [gender, setGender] = useGenderParam();
  const age = useArraySearchFilter("age");
  const setAge = useSetArraySearchFilter("age");
  const vehicleColor = useArraySearchFilter("vehicleColor");
  const setVehicleColor = useSetArraySearchFilter("vehicleColor");
  const vehicleType = useArraySearchFilter("vehicleType");
  const setVehicleType = useSetArraySearchFilter("vehicleType");
  const vehicleMake = useArraySearchFilter("vehicleMake");
  const setVehicleMake = useSetArraySearchFilter("vehicleMake");

  const hasAttributeFilters = Boolean(
    upperColor ||
      lowerColor ||
      gender ||
      age ||
      vehicleColor ||
      vehicleType ||
      vehicleMake
  );
  if (!hasAttributeFilters) return null;

  return (
    <div className="flex flex-wrap items-center gap-1 mb-2">
      <ActivePeopleAttributeFilters
        upperColor={upperColor}
        setUpperColor={setUpperColor}
        lowerColor={lowerColor}
        setLowerColor={setLowerColor}
        gender={gender}
        setGender={setGender}
        age={age}
        setAge={setAge}
      />
      <ActiveVehicleAttributeFilters
        color={vehicleColor}
        setColor={setVehicleColor}
        type={vehicleType}
        setType={setVehicleType}
        make={vehicleMake}
        setMake={setVehicleMake}
      />
      <ClearAllButton
        onClick={() => {
          setUpperColor(undefined);
          setLowerColor(undefined);
          setGender(undefined);
          setAge(undefined);
          setVehicleColor(undefined);
          setVehicleType(undefined);
          setVehicleMake(undefined);
        }}
      />
    </div>
  );
}

function ActivePeopleAttributeFilters({
  upperColor,
  setUpperColor,
  lowerColor,
  setLowerColor,
  gender,
  setGender,
  age,
  setAge,
}: {
  upperColor?: string[];
  setUpperColor: ReturnType<typeof useSetArraySearchFilter>;
  lowerColor?: string[];
  setLowerColor: ReturnType<typeof useSetArraySearchFilter>;
  gender?: string | null;
  setGender: ReturnType<typeof useGenderParam>[1];
  age?: string[];
  setAge: ReturnType<typeof useSetArraySearchFilter>;
}) {
  return (
    <>
      {upperColor?.map((value) => {
        const item = attributeColorsMap[value];
        return (
          <FilterChip
            key={value}
            label={`${item.name} Upper Clothing`}
            color={item.color}
            onDelete={() => {
              setUpperColor((current) => current?.filter((c) => c !== value));
            }}
            Icon={ClothingUpperIcon}
          />
        );
      })}
      {lowerColor?.map((value) => {
        const item = attributeColorsMap[value];
        return (
          <FilterChip
            key={value}
            label={`${item.name} Lower Clothing`}
            color={item.color}
            onDelete={() => {
              setLowerColor((current) => current?.filter((c) => c !== value));
            }}
            Icon={ClothingLowerIcon}
          />
        );
      })}
      {gender && (
        <FilterChip
          label={gender}
          color="#6500E4"
          onDelete={() => setGender(undefined)}
          Icon={GenderIcon}
        />
      )}
      {age?.map((value) => {
        const item = attributeAgesMap[value];
        return (
          <FilterChip
            key={value}
            label={item.name}
            color="#6500E4"
            onDelete={() => {
              setAge((current) => current?.filter((c) => c !== value));
            }}
            Icon={AgeIcon}
          />
        );
      })}
    </>
  );
}

function ActiveVehicleAttributeFilters({
  type,
  setType,
  make,
  setMake,
  color,
  setColor,
}: {
  type?: string[];
  setType: ReturnType<typeof useSetArraySearchFilter>;
  make?: string[];
  setMake: ReturnType<typeof useSetArraySearchFilter>;
  color?: string[];
  setColor: ReturnType<typeof useSetArraySearchFilter>;
}) {
  return (
    <>
      {color?.map((value) => {
        const item = attributeColorsMap[value];
        return (
          <FilterChip
            key={value}
            label={item.name}
            color={item.color}
            onDelete={() => {
              setColor((current) => current?.filter((c) => c !== value));
            }}
            Icon={CarIcon}
          />
        );
      })}
      {type?.map((value) => {
        const item = vehicleTypesMap[value];
        return (
          <FilterChip
            key={value}
            label={item.name}
            color="#00AFD6"
            onDelete={() => {
              setType((current) => current?.filter((c) => c !== value));
            }}
            Icon={CarIcon}
          />
        );
      })}
      {make?.map((value) => {
        const item = vehicleMakesMap[value];
        return (
          <FilterChip
            key={value}
            label={item.name}
            color="#00AFD6"
            onDelete={() => {
              setMake((current) => current?.filter((c) => c !== value));
            }}
            Icon={CarIcon}
          />
        );
      })}
    </>
  );
}

export function FilterChip({
  label,
  onDelete,
  Icon,
  color,
  className,
}: {
  label: string;
  onDelete?: () => void;
  Icon: React.ElementType;
  color?: string;
  className?: string;
}) {
  return (
    <Chip
      label={label}
      icon={
        <Icon
          style={
            // Add outline for white color icons
            color === "#FFFFFF"
              ? { stroke: "#D6D6D6", strokeWidth: "1px" }
              : undefined
          }
        />
      }
      onDelete={onDelete}
      deleteIcon={<CloseIcon />}
      variant="outlined"
      size="small"
      sx={(theme) => ({
        fontSize: "12px",
        backgroundColor: "#f6fbff",
        borderColor: "#ACD5F7",
        padding: "0 2px",
        justifyContent: "flex-start",
        '&[role="button"]': { cursor: "default" },
        ".MuiChip-icon": {
          color: color ?? theme.palette.text.primary,
          width: "18px",
          height: "18px",
        },
        ".MuiChip-deleteIcon": { fontSize: "12px", color: "#ACD5F7" },
      })}
      className={className}
    />
  );
}

function ClearAllButton({ onClick }: { onClick: () => void }) {
  return (
    <Button
      color="primary"
      size="small"
      onClick={onClick}
      className="text-xs font-normal"
      sx={{
        ".MuiButton-startIcon": { marginRight: "2px" },
      }}
    >
      Clear All
    </Button>
  );
}

export function useResetPeopleAttributeFilters() {
  const [, setParams] = useQueryParams({
    clothingUpper: DelimitedArrayParam,
    clothingLower: DelimitedArrayParam,
    age: DelimitedArrayParam,
    gender: StringParam,
  });

  return () => {
    setParams({
      clothingUpper: undefined,
      clothingLower: undefined,
      age: undefined,
      gender: undefined,
    });
  };
}

export function useResetVehicleAttributeFilters() {
  const [, setParams] = useQueryParams({
    vehicleColor: DelimitedArrayParam,
    vehicleType: DelimitedArrayParam,
    vehicleMake: DelimitedArrayParam,
  });

  return () => {
    setParams({
      vehicleColor: undefined,
      vehicleType: undefined,
      vehicleMake: undefined,
    });
  };
}
