import { addSeconds, subSeconds } from "date-fns/fp";
import { get } from "lodash/fp";

import { AlertType, Vod } from "@/generated-models";

import { intelligentFiltersConfig } from "../Search/intelligence/intelligence";

export function createClipLink(
  cameraId: number,
  vod: Pick<Vod, "startTime" | "endTime">,
  alertType: AlertType
) {
  const startDate = new Date(vod.startTime);

  const paramsObj = {
    cams: String(cameraId),
    vod: vod.startTime + "|" + vod.endTime,
    start: subSeconds(120, startDate).toISOString(),
    end: addSeconds(120, startDate).toISOString(),
    subjects: get([alertType, "objectIds"], intelligentFiltersConfig)?.join(
      "_"
    ),
  };

  const params = new URLSearchParams(paramsObj);

  return `/search?${params.toString()}`;
}
