import { useField } from "formik";
import { useMemo } from "react";

import { formatIsoTimeMinutes, parseIsoTimeMinutes } from "@/util/date";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  Page_IntelligenceQuery,
  usePage_IntelligenceQuery,
} from "@/generated-models";

type QueriedDash = Page_IntelligenceQuery["intelligenceDashboards"][number];

export function getComparativeSettingsId(
  dashboard: Pick<
    QueriedDash,
    "startTime" | "endTime" | "daysOfWeek" | "thresholdSeconds" | "entityCount"
  >
) {
  return `${dashboard.startTime}|${dashboard.endTime}|${
    dashboard.thresholdSeconds
  }|${dashboard.entityCount}|${dashboard.endTime}${dashboard.daysOfWeek.join(
    "_"
  )}`;
}

export function useCurrentSettingsId() {
  const [, { value: daysOfWeek }] = useField<string[]>({
    name: "daysOfWeek",
  });

  const [, { value: timeRange }] = useField<[number, number]>({
    name: "timeRange",
  });

  const [, { value: thresholdSeconds }] = useField<number>({
    name: "thresholdSeconds",
  });

  const [, { value: entityCount }] = useField<number>({
    name: "entityCount",
  });

  return getComparativeSettingsId({
    startTime: formatIsoTimeMinutes(timeRange[0]),
    endTime: formatIsoTimeMinutes(timeRange[1]),
    daysOfWeek: daysOfWeek.map(Number),
    thresholdSeconds,
    entityCount,
  });
}

export function useReferenceDashboards() {
  const [, { value }] = useField<number[]>({
    name: "referenceDashIds",
  });

  const { data } = usePage_IntelligenceQuery({
    ...refetchOnMountPolicy,
  });

  return useMemo(
    () =>
      data?.intelligenceDashboards.filter((i) =>
        (value || []).includes(i.id)
      ) || [],
    [data?.intelligenceDashboards, value]
  );
}

export function useReferenceTypeInfo() {
  const dashboard = useReferenceDashboards()[0];

  return dashboard
    ? {
        type: dashboard.type,
        objectTypes: dashboard.objectTypes,
        subtype: dashboard.subtype,
      }
    : null;
}

export function useComparativeSettings() {
  const [, { value: daysOfWeek }, { setValue: setDaysOfWeek }] = useField<
    string[]
  >({
    name: "daysOfWeek",
  });

  const [, { value: timeRange }, { setValue: setTimeRange }] = useField<
    [number, number]
  >({
    name: "timeRange",
  });

  const [, { value: entityCount }, { setValue: setEntityCount }] = useField<
    number
  >({
    name: "entityCount",
  });

  const [
    ,
    { value: thresholdSeconds },
    { setValue: setThresholdSeconds },
  ] = useField<number>({
    name: "thresholdSeconds",
  });

  const data = {
    startTime: formatIsoTimeMinutes(timeRange[0]),
    endTime: formatIsoTimeMinutes(timeRange[1]),
    daysOfWeek: daysOfWeek.map(Number),
    thresholdSeconds,
    entityCount,
  };

  const update = (
    daysOfWeek: number[],
    startTime: string,
    endTime: string,
    thresholdSeconds: number,
    entityCount: number
  ) => {
    setThresholdSeconds(thresholdSeconds);
    setEntityCount(entityCount);
    setDaysOfWeek(daysOfWeek.map(String));
    setTimeRange([
      parseIsoTimeMinutes(startTime),
      parseIsoTimeMinutes(endTime),
    ]);
  };

  return { update, data };
}
