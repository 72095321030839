import clsx from "clsx";
import { PropsWithChildren } from "react";

import { ErrorMessage } from "../ErrorMessage";
import {
  detectMobileWrapper,
  inferredNativeAppPlatform,
  mobileEnvironment,
} from "./mobileEnvironment";

const storeUrls = {
  Android: "https://play.google.com/store/apps/details?id=ai.spot.dashboard",
  IOS: "https://apps.apple.com/us/app/spot-ai-dashboard/id1609067120",
};

export function RequiredUpdateSplash({ children }: PropsWithChildren<{}>) {
  // Intentionally using the deprecated `detectMobileWrapper` here to distinguish
  // between the old (initial) version of the mobile app and newer versions.
  if (!detectMobileWrapper() || mobileEnvironment) {
    return <>{children}</>;
  }

  const storeUrl =
    inferredNativeAppPlatform && storeUrls[inferredNativeAppPlatform];

  return (
    <ErrorMessage
      title="Update Required"
      description={
        <>
          Please{" "}
          <MaybeAnchor
            href={storeUrl}
            activeClassName="text-primary font-bold underline"
          >
            update the app
          </MaybeAnchor>{" "}
          to continue.
        </>
      }
    />
  );
}

function MaybeAnchor({
  href,
  activeClassName,
  className,
  ...rest
}: PropsWithChildren<{
  href?: string | null;
  activeClassName?: string;
  className?: string;
}>) {
  if (!href) return <span className={className} {...rest} />;
  return (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a href={href} className={clsx(className, activeClassName)} {...rest} />
  );
}
