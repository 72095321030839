import { useEffect, useState } from "react";

interface ScriptStatus {
  loaded: boolean;
  error: boolean;
}

interface CacheItem {
  loaded: boolean;
  error: boolean;
  script: HTMLScriptElement;
  listeners: ((status: ScriptStatus) => void)[];
}

const cachedScripts: Record<string, CacheItem> = {};
export function useScript(src: string, skip = false, id?: string) {
  // Keeping track of script loaded and error state
  const cached = cachedScripts[src];
  const [state, setState] = useState(
    cached
      ? { loaded: cached.loaded, error: cached.error }
      : { loaded: false, error: false }
  );

  useEffect(() => {
    if (skip) return;

    const cached = cachedScripts[src];
    // If cachedScripts array already includes src that means another instance ...
    // ... of this hook already loaded this script, so no need to load again.
    if (cached) {
      setState({
        loaded: cached.loaded,
        error: cached.error,
      });

      if (!cached.loaded) {
        cached.listeners.push((status) => setState(status));
      }
    } else {
      // Create script
      const script = document.createElement("script");
      if (id) script.id = id;
      script.src = src;
      script.async = true;

      const newCacheItem: CacheItem = {
        loaded: false,
        error: false,
        script,
        listeners: [],
      };

      cachedScripts[src] = newCacheItem;

      let unmounted = false;

      const notifyListeners = (status: ScriptStatus) => {
        newCacheItem.listeners.forEach((l) => l(status));
        newCacheItem.listeners.length = 0;
      };

      const onScriptLoad = () => {
        newCacheItem.loaded = true;
        newCacheItem.error = false;

        const status = {
          loaded: true,
          error: false,
        };
        notifyListeners(status);

        if (unmounted) return;
        setState(status);
      };

      const onScriptError = () => {
        newCacheItem.loaded = true;
        newCacheItem.error = true;

        const status = {
          loaded: true,
          error: true,
        };
        notifyListeners(status);

        if (unmounted) return;
        setState(status);
      };

      // Script event listener callbacks for load and error
      script.addEventListener("load", onScriptLoad);
      script.addEventListener("error", onScriptError);

      // Add script to document body
      document.body.appendChild(script);

      // Remove event listeners on cleanup
      return function cleanup() {
        unmounted = true;
      };
    }
  }, [src, skip, id]); // Only re-run effect if script src changes

  return [state.loaded, state.error];
}
