import { ListItem, ListItemIcon, ListItemProps } from "@mui/material";
import React, { ReactNode } from "react";

import { PlayerTooltip } from "./PlayerTooltip";

export function DisabledShareTrayItem({
  icon,
  label,
  tooltipTitle,
  ...props
}: ListItemProps<"li"> & {
  icon: ReactNode;
  label: string;
  tooltipTitle: string;
}) {
  return (
    <PlayerTooltip title={tooltipTitle} PopperProps={undefined}>
      <ListItem
        disabled
        className="text-white text-left py-1 px-3 w-full focus:bg-white focus:bg-opacity-20 md:hover:bg-white md:hover:bg-opacity-20 h-10"
        {...(props as any)}
      >
        <ListItemIcon className="text-inherit min-w-0 mr-2">
          {icon}
        </ListItemIcon>
        <strong>{label}</strong>
      </ListItem>
    </PlayerTooltip>
  );
}
