import {
  ApolloClient,
  ApolloProvider as Provider,
  from,
  InMemoryCache,
  WatchQueryFetchPolicy,
} from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import { offsetLimitPagination } from "@apollo/client/utilities";
import { createUploadLink } from "apollo-upload-client";
import { memoize } from "lodash/fp";
import React from "react";
import { useMatch } from "react-router-dom";

import { isProductionEnv } from "@/environment";

import { apiHost } from "./environment";
import generatedPossibleTypes from "./possibleTypes";

const { possibleTypes } = generatedPossibleTypes;

const customFetch = async (uri: RequestInfo, options?: RequestInit) => {
  const response = await fetch(uri, options);
  if (response.status >= 500) {
    return Promise.reject(response.status);
  }
  return response;
};

function getUploadLink(orgSlug?: string) {
  return createUploadLink({
    uri: `${apiHost}/graphql`,
    credentials: "include",
    fetch: customFetch,
    headers: orgSlug ? { "spot-org-slug": orgSlug } : undefined,
  });
}

const getApolloClient = memoize(
  (orgSlug?: string) =>
    new ApolloClient({
      connectToDevTools: !isProductionEnv,
      name: "dashboard",
      cache: new InMemoryCache({
        possibleTypes,
        typePolicies: {
          Query: {
            fields: {
              feed: offsetLimitPagination(),
              universalSearchByImage: {
                keyArgs: ["input", ["cameraId", "time", "promptOverride"]],
              },
            },
          },
          CameraSettings: {
            merge: true,
          },
          RolePermission: {
            // Do not normalize role permissions. Role permissions across roles
            // share the same ids, so normalizing them would cause them to
            // overwrite each other.
            keyFields: false,
          },
        },
      }),
      /**
       * The order in which links are added is important
       * Refer - https://www.apollographql.com/docs/react/api/link/introduction
       */
      link: from([
        // This only works for Network errors and not for graphql errors
        new RetryLink({
          delay: {
            initial: 1000,
            max: Infinity,
            jitter: true,
          },
          attempts: {
            max: 5,
            retryIf: (error, _operation) => {
              return !!error;
            },
          },
        }),
        getUploadLink(orgSlug),
      ]),
    })
);

export function ApolloProvider({ children }: { children: React.ReactNode }) {
  const match = useMatch("/o/:orgSlug/*");
  const client = getApolloClient(match?.params.orgSlug);

  return <Provider client={client}>{children}</Provider>;
}

// Background info on nextFetchPolicy (buried in an issue):
// https://github.com/apollographql/apollo-client/issues/6760#issuecomment-668188727
// The goal of this policy is to refetch the query on mount, but not on every render or when cache changes.
export const refetchOnMountPolicy: {
  fetchPolicy: WatchQueryFetchPolicy;
  nextFetchPolicy: WatchQueryFetchPolicy;
} = {
  fetchPolicy: "cache-and-network",
  nextFetchPolicy: "cache-first",
};
