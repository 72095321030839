import { useCallback, useEffect, useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";

export function usePagination(
  initialPageCount = 4,
  incrementCount = initialPageCount
) {
  const [pageCount, setPageCount] = useState(initialPageCount);
  const increment = useCallback(
    (incrementSize = 1) =>
      setPageCount((currentCount) => currentCount + incrementSize),
    [setPageCount]
  );
  const reset = useCallback((count = initialPageCount) => setPageCount(count), [
    setPageCount,
    initialPageCount,
  ]);

  // Visibility Sensor
  const { ref, inView } = useInView();
  useEffect(() => {
    if (inView) increment(incrementCount);
  }, [inView, increment, incrementCount]);
  const visibilitySensor = useMemo(() => <div ref={ref}>&nbsp;</div>, [ref]);

  return {
    pageCount,
    increment,
    reset,
    visibilitySensor,
  };
}
