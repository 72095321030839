import { DeleteForever, HighlightOff } from "@mui/icons-material";
import Mail from "@mui/icons-material/Mail";
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import gql from "graphql-tag";
import { sortBy } from "lodash/fp";
import { PropsWithChildren, useState } from "react";
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { useMe } from "@/components/Auth";
import { Chip } from "@/components/Chip";
import { ResponsiveBasicDrawer } from "@/components/Drawer/BasicDrawer";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { DisabledTooltip } from "@/components/shared/CustomTooltips";
import { DefaultDialog, useDialog } from "@/components/shared/Dialog";
import { QueryParamLink } from "@/components/shared/QueryParamLink";

import {
  AuthProvider,
  Role,
  useAddUserMutation,
  useGroupsQuery,
  useMeDetailsQuery,
  UserDetailsQuery,
  useRemoveUserMutation,
  useResendUserEmailMutation,
  UserForOrgFragmentDoc,
  useRoleOptionsQuery,
  useUpdateSelfMutation,
  useUpdateUserMutation,
  useUserDetailsQuery,
  useUsersWithEmailQuery,
} from "@/generated-models";
import { usePermissions } from "@/hooks/usePermissions";

import { HookFormGroupSelect } from "./GroupSelect";
import { CustomPrompt } from "./NavigationPrompt";
import { PermissionsDisplay } from "./Permissions";
import { SavedFeedback, SavedFeedbackStatus } from "./SavedFeedback";
import { TruncateList } from "./TruncateList";
import { UserManagementField } from "./UserManagementField";
import { UserManagementFormButtons } from "./UserManagementFormButtons";
import { validateEmail } from "./UserSettingsUtils";
import { useDetailsRouteInfo } from "./useDetailsRouteInfo";

function useUserDetails() {
  const me = useMe();
  const { id, formMode } = useDetailsRouteInfo();
  const editingSelf = id === me?.orgUserId;
  const userDetailQuery = useUserDetailsQuery({
    variables: {
      orgUserId: Number(id),
    },
    skip: formMode === "create" || !me || editingSelf,
  });

  const meQuery = useMeDetailsQuery({
    skip: !editingSelf,
  });

  return { editingSelf, ...(editingSelf ? meQuery : userDetailQuery) };
}

export function UserDetails() {
  const { formMode } = useDetailsRouteInfo();
  const { data, error, editingSelf } = useUserDetails();
  const { pushSnackbar } = useFeedback();
  const [resendUserEmail, { loading: resending }] = useResendUserEmailMutation({
    onCompleted: () =>
      pushSnackbar("Successfully resent login info", FeedbackType.Success),
    onError: () =>
      pushSnackbar("Failed to resend login info", FeedbackType.Error),
  });

  const validateEmailFunc = useValidateEmail(formMode !== "create");

  let contents = <Loading grow>Loading user...</Loading>;

  if (error) {
    contents = (
      <ErrorMessage
        narrow
        title="Unable to load user"
        description={error.message}
      />
    );
  } else if (formMode === "create" || data) {
    contents = (
      <UserForm
        key={data?.user.id}
        defaultValues={
          formMode === "create"
            ? {
                name: "",
                email: "",
                phone: "",
                roleId: undefined,
                allLocations: false,
                tagIds: [],
              }
            : {
                name: data!.user.profile.name,
                phone: data!.user.profile.phone,
                roleId: data!.user.rolev2?.id,
                allLocations: data!.user.allLocations,
                tagIds: data!.user.tags.map((t) => String(t.id)),
              }
        }
      >
        <div className="bg-white border border-solid border-blue-medium rounded-lg px-[9px] py-[16px]">
          <UserManagementField
            label="Name"
            value={data?.user.profile.name}
            editSelfField
            editValue={
              <div className="flex">
                <Controller
                  name="name"
                  rules={{
                    required: {
                      value: true,
                      message: "Please provide a name",
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      error={!!error}
                      helperText={error?.message}
                      className="grow"
                      {...field}
                    />
                  )}
                />
                <DeleteUserButton />
              </div>
            }
            classes={{
              value: "font-bold",
            }}
          />
          <Divider className="mt-[16px] mb-[7px] border-[#f1f1f1]" />
          <UserManagementField
            label="Email"
            value={data?.user.profile.email}
            editValue={
              formMode === "create" ? (
                <Controller
                  name="email"
                  rules={{
                    required: {
                      value: true,
                      message: "Please provide an email",
                    },

                    pattern: {
                      // yes yes, not spec compliant, but good enough and it's simple
                      value: /\S+@\S+\.\S+/,
                      message: "Invalid email address",
                    },

                    validate: validateEmailFunc,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={Boolean(error)}
                      helperText={error?.message}
                    />
                  )}
                />
              ) : undefined
            }
          />

          {formMode !== "create" && !editingSelf && (
            <Button
              color="primary"
              onClick={() =>
                resendUserEmail({
                  variables: {
                    id: data!.user.id,
                  },
                })
              }
              startIcon={<Mail className="text-[14px]" />}
              className="font-normal"
              classes={{
                startIcon: "mr-1",
              }}
              size="small"
              disabled={resending}
            >
              Resend email invite
            </Button>
          )}
          <Divider className="mt-[16px] mb-[7px] border-[#f1f1f1]" />
          <UserManagementField
            label="Phone"
            editSelfField
            value={
              data?.user.profile.phone || (
                <Typography variant="caption" className="opacity-40 font-light">
                  not specified
                </Typography>
              )
            }
            editValue={
              <Controller
                name="phone"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    type="tel"
                    {...field}
                    error={!!error}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+1</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            }
          />
          <Divider className="mt-[16px] mb-[7px] border-[#f1f1f1]" />
          <RoleField initialRoleName={data?.user.rolev2?.name} />
          <Divider className="mt-[16px] mb-[7px] border-[#f1f1f1]" />
          <UserManagementField
            label="Groups & Locations"
            value={
              data && (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={data?.user.allLocations}
                        disabled
                        size="small"
                      />
                    }
                    label="All locations"
                  />
                  <UserGroupDisplay
                    groups={sortBy(
                      (g) => g.name.toLocaleLowerCase(),
                      data.user.tags
                    )}
                  />
                </>
              )
            }
            editValue={<UserLocationSelect />}
          />
          <Divider className="mt-[16px] mb-[7px] border-[#f1f1f1]" />
          <UserManagementField
            label="Permissions"
            value={<WiredPermissionsDisplay defaultRole={data?.user.rolev2} />}
          />
          <Typography
            variant="body2"
            className="italic px-[14px] mt-5 leading-[normal]"
          >
            If you need help, ask your administrator to change your role or
            permissions.
          </Typography>
        </div>
      </UserForm>
    );
  }

  return (
    <ResponsiveBasicDrawer
      title={formMode === "create" ? "Create User" : "User Details"}
      closeButton={
        <IconButton className="-mr-2" component={QueryParamLink} to="./..">
          <HighlightOff />
        </IconButton>
      }
    >
      {contents}
    </ResponsiveBasicDrawer>
  );
}

gql`
  query usersWithEmail {
    users {
      id
      email
    }
  }
`;

function useValidateEmail(skip: boolean) {
  const { data } = useUsersWithEmailQuery({
    skip,
  });
  if (!data) return () => undefined;
  const emails = data.users.map((u) => u.email);
  return validateEmail(emails);
}

function UserGroupDisplay({
  groups,
}: {
  groups: { id: number; name: string }[];
}) {
  return (
    <TruncateList>
      {groups.map((t) => (
        <Chip
          classes={{
            root: "max-w-[106px]",
          }}
          label={t.name}
          key={t.id}
        />
      ))}
    </TruncateList>
  );
}

function UserLocationSelect() {
  const { watch } = useFormContext<UserFormFields>();
  const allLocations = watch("allLocations");
  const { data: groupsData } = useGroupsQuery();
  const roleId = watch("roleId");
  const { data: roleData } = useRoleOptionsQuery({
    fetchPolicy: "cache-only", // dangerous?
  });
  const role = roleData?.roles.find((r) => r.id === roleId);
  return (
    <>
      <div className="flex">
        <Controller
          name="allLocations"
          render={({ field }) => (
            <DisabledTooltip
              disabled={role?.allLocations}
              title={`The "${role?.name}" role has all locations enabled. Pick a different role to select specific locations for this user.`}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    {...field}
                    checked={field.value || role?.allLocations}
                    disabled={role?.allLocations}
                  />
                }
                label="All locations"
              />
            </DisabledTooltip>
          )}
        />
      </div>
      {groupsData &&
        (allLocations || role?.allLocations ? (
          <UserGroupDisplay
            groups={sortBy(
              (g) => g.name.toLocaleLowerCase(),
              groupsData.groups.filter((g) => g.isLocationGroup)
            )}
          />
        ) : (
          <HookFormGroupSelect
            name="tagIds"
            options={groupsData.groups}
            autocompleteProps={{
              popupIcon: undefined,
              classes: {
                root: "mt-2",
              },
            }}
            chipProps={{
              classes: {
                root: "max-w-[140px] !m-0",
              },
            }}
            textFieldProps={{
              label: undefined,
              InputProps: { style: { paddingRight: undefined, gap: 8 } },
            }}
          />
        ))}
    </>
  );
}

function WiredPermissionsDisplay({
  defaultRole,
}: {
  defaultRole: UserDetailsQuery["user"]["rolev2"];
}) {
  const formContext = useFormContext<UserFormFields>();
  const roleId = formContext?.watch("roleId") ?? -1;
  const hasPermission = usePermissions();
  const hasUsersManagePermission = hasPermission("users_manage");
  const { data, error } = useRoleOptionsQuery({
    skip: !hasUsersManagePermission,
  });
  if (error)
    return (
      <ErrorMessage
        narrow
        title="Failed to load permissions"
        description={error.message}
      />
    );
  const role = data?.roles.find((r) => r.id === roleId) ?? defaultRole;
  if (!role) return null;

  return <PermissionsDisplay key={roleId} permissions={role.permissions} />;
}

/**
 * We need the profileId, because that's what the apollo cache uses
 * @returns
 */
function DeleteUserButton() {
  const { id } = useDetailsRouteInfo();
  const orgUserId = Number(id);
  const { pushSnackbar } = useFeedback();
  const [deleteUser] = useRemoveUserMutation({
    variables: { orgUserId },
    update: (cache, { data }) => {
      if (data) {
        cache.evict({
          id: `User:${data.removeUser}`,
        });
      }
    },
  });
  const { open, ...dialogProps } = useDialog();
  const navigate = useNavigate();
  return (
    <>
      <IconButton
        color="primary"
        size="small"
        className="shrink-0"
        onClick={async () => {
          const confirmed = await open();
          if (!confirmed) return;
          const { errors } = await deleteUser();
          if (errors?.length) {
            pushSnackbar(
              "Something went wrong. Please try again",
              FeedbackType.Error
            );
          } else {
            pushSnackbar("User deleted successfully", FeedbackType.Success);
            navigate("./..", { replace: true });
          }
        }}
      >
        <DeleteForever />
      </IconButton>
      <DefaultDialog
        title="Delete user"
        content="Are you sure you want to delete this user? This action is irreversible."
        confirmColor="primary"
        {...dialogProps}
      />
    </>
  );
}

interface UserFormFields {
  name: string;
  email?: string;
  phone: string;
  roleId?: number;
  allLocations: boolean;
  tagIds: string[];
}

function UserForm({
  children,
  defaultValues,
}: PropsWithChildren<{
  defaultValues: UserFormFields;
}>) {
  const { id, formMode } = useDetailsRouteInfo();

  const [updateUser] = useUpdateUserMutation();
  const [updateSelf] = useUpdateSelfMutation();
  const [addUser] = useAddUserMutation();
  const me = useMe();
  const permissions = me?.organization.permissions;
  const methods = useForm({
    defaultValues,
    mode: "onChange",
  });
  const {
    formState: { isDirty, isSubmitting },
  } = methods;
  const [
    savedFeedbackStatus,
    setSavedFeedbackStatus,
  ] = useState<SavedFeedbackStatus | null>(null);
  const navigate = useNavigate();

  const editingSelf = id === me?.orgUserId;
  if (!permissions?.users_manage && !editingSelf) {
    return <>{children}</>;
  }
  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(async ({ email, roleId, ...values }) => {
          try {
            if (formMode === "create") {
              const { data, errors } = await addUser({
                variables: {
                  input: {
                    ...values,
                    roleId: roleId!,
                    email: email!,
                    tagIds: values.tagIds.map(Number),
                  },
                },
                optimisticResponse: {
                  __typename: "Mutation",
                  addUser: {
                    __typename: "User",
                    id: -1,
                    orgUserId: -1,
                    ...values,
                    // TODO: remove this field once customRoles flag is removed and
                    // everything is migrated to custom roles
                    profile: {
                      __typename: "Profile",
                      id: -1,
                      name: values.name,
                      email: email!,
                      phone: values.phone,
                    },
                    platformRole: Role.User, // TODO: replace with profile.platformRole
                    authProvider: AuthProvider.Auth0,
                    lastLoginAt: null,
                    tags: [],
                  },
                },
                update(cache, { data }) {
                  if (data) {
                    cache.modify({
                      id: "ROOT_QUERY",
                      fields: {
                        users(existing = []) {
                          const newUser = cache.writeFragment({
                            data: data.addUser,
                            fragment: UserForOrgFragmentDoc,
                            fragmentName: "UserForOrg",
                          });
                          return [...existing, newUser];
                        },
                      },
                    });
                  }
                },
              });
              if (errors?.length) {
                throw errors[0];
              } else if (data) {
                navigate(`./../${data.addUser.orgUserId}`);
              }
            } else {
              const { errors } = await (editingSelf
                ? updateSelf({
                    variables: {
                      updates: {
                        name: values.name,
                        phone: values.phone,
                      },
                    },
                  })
                : updateUser({
                    variables: {
                      orgUserId: Number(id),
                      updates: {
                        ...values,
                        tagIds: values.tagIds.map(Number),
                        roleId,
                      },
                    },
                  }));
              if (errors?.length) throw errors[0];
            }
            methods.reset({ ...values, roleId, email });
            setSavedFeedbackStatus({ success: true });
          } catch (error: any) {
            setSavedFeedbackStatus({ success: false, error });
          }
        })}
      >
        {children}
        {isDirty && (
          <>
            <UserManagementFormButtons
              confirmText={formMode === "create" ? "Create User" : "Save"}
            />
            <CustomPrompt
              when={isDirty && !isSubmitting}
              message="You'll lose your changes, you sure?"
            />
          </>
        )}
        {!isDirty && (
          <SavedFeedback
            confirmationText={
              formMode === "create" ? "User Created." : "Changes Saved."
            }
            status={savedFeedbackStatus}
            onClose={() => setSavedFeedbackStatus(null)}
          />
        )}
        <div className="h-[50px]" />
      </form>
    </FormProvider>
  );
}

gql`
  query roleOptions {
    roles {
      id
      name
      allLocations
      permissions {
        id
        name
        description
        enabled
      }
    }
  }
`;

function RoleField({ initialRoleName }: { initialRoleName?: string }) {
  const hasPermission = usePermissions();
  const hasUsersManagePermission = hasPermission("users_manage");
  const { data } = useRoleOptionsQuery({
    skip: !hasUsersManagePermission,
  });
  return (
    <UserManagementField
      label="Role"
      value={initialRoleName}
      editValue={
        hasUsersManagePermission ? (
          <Controller
            name="roleId"
            rules={{ required: true }}
            render={({ field }) => (
              <Select {...field}>
                {data?.roles.map((role) => (
                  <MenuItem value={role.id} key={role.id}>
                    {role.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        ) : null
      }
    />
  );
}
