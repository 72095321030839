import clsx from "clsx";
import React, { PropsWithChildren } from "react";

import { usePlayerControlsShown } from "@/components/Player/playerControlsMachine";

import { usePlayerMobileMode } from "./PlayerControls";

export function TopRightTray({
  children,
  disabled,
}: PropsWithChildren<{ disabled?: boolean }>) {
  const shown = usePlayerControlsShown();
  const playerMobileMode = usePlayerMobileMode();
  return (
    <div
      className={clsx(
        "absolute grid grid-cols-1 top-0 right-0 transition-opacity place-items-center z-10",
        !playerMobileMode ? "gap-4 p-4" : "p-2",
        {
          "opacity-70": disabled,
          "opacity-0": !shown,
        }
      )}
    >
      {children}
    </div>
  );
}
