import { useTheme } from "@mui/material";
import clsx from "clsx";
import React, {
  useCallback,
  useState,
  useMemo,
  CSSProperties,
  forwardRef,
} from "react";
import { makeStyles } from "tss-react/mui";

import { useUuid } from "@/util/useUuid";

const useStyles = makeStyles()((theme) => ({
  root: {
    fontFamily: theme.typography.fontFamily,
    userSelect: "none",
  },
}));

interface AnnotationLabelProps {
  children: string;
  variant?: "flat" | "contained";
  color?: string;
  textColor?: string;
  width?: number;
  height?: number;
  style?: CSSProperties;
  className?: string;
}
export const AnnotationLabel = forwardRef<SVGElement, AnnotationLabelProps>(
  function AnnotationLabel(
    {
      children,
      variant = "flat",
      color = "currentColor",
      textColor,
      width = 18,
      height,
      style,
      className,
    },
    ref
  ) {
    const { classes } = useStyles();
    const textId = useUuid();
    const theme = useTheme();

    const [contrastTextColor, setContrastTextColor] = useState("white");

    const rootElementRefCallback = useCallback(
      (element: SVGElement | null) => {
        if (!element) return;
        if (ref) {
          if (typeof ref === "function") {
            ref(element);
          } else {
            ref.current = element;
          }
        }

        const styles = window.getComputedStyle(element);
        const computedColor = styles.fill;
        const [r, g, b] = computedColor
          .slice(4, -1)
          .split(",")
          .map((x) => Number(x.trim()));

        // https://stackoverflow.com/a/11868398
        const yiq = (r * 299 + g * 587 + b * 114) / 1000;

        setContrastTextColor(yiq >= 128 ? theme.palette.text.primary : "white");
      },

      // eslint-disable-next-line
      [color]
    );

    const fontSize = useMemo(
      () => [12, 10, 8][Math.min(2, children.length - 1)],
      [children]
    );

    const text = (
      <text
        x="50%"
        y="60%"
        fill={variant === "flat" ? "#000" : textColor ?? contrastTextColor}
        fontSize={`${fontSize}px`}
        textAnchor="middle"
      >
        {children}
      </text>
    );
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        ref={rootElementRefCallback}
        width={width}
        height={height}
        viewBox="0 0 18 20"
        fill={color}
        className={clsx(classes.root, className)}
        style={{
          ...(style ?? {}),
          ...(variant === "contained"
            ? {
                filter: "drop-shadow(0 2px 1px rgba(0, 0, 0, .25))",
                fontWeight: "bold",
              }
            : {}),
        }}
      >
        <path
          d="M18 0H0v13l9 7 9-7z"
          stroke="white"
          mask={variant === "flat" ? `url(#${textId})` : undefined}
        />
        {variant === "flat" ? (
          <mask id={textId}>
            <rect width="100%" height="100%" fill="#fff" x="0" y="0" />
            {text}
          </mask>
        ) : (
          text
        )}
      </svg>
    );
  }
);

const CHAR_CODE_OFFSET = "A".charCodeAt(0);
/**
 * Returns an annotation label for a given number:
 *  0 => A
 *  1 => B
 *  ...
 *  25 => Z
 *  26 => AA
 *  27 => AB
 *  ...
 *  700 => ZY
 *  701 => ZZ
 *  702 => AAA
 */
export function labelFromNumber(num: number): string {
  let rest = num % 26;
  let remaining = Math.floor(num / 26);
  if (remaining > 0) {
    return labelFromNumber(remaining - 1) + getChar(rest);
  }
  return getChar(rest);
}

function getChar(num: number) {
  return String.fromCharCode(num + CHAR_CODE_OFFSET);
}
