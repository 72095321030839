import InfoIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Button,
  Card,
  CardMedia,
  Divider,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import gql from "graphql-tag";
import { useFlags } from "launchdarkly-react-client-sdk";
import { sortBy } from "lodash/fp";
import { useState } from "react";
import { Link, Route, Routes } from "react-router-dom";
import { useUpdateEffect } from "react-use";
import { makeStyles, withStyles } from "tss-react/mui";

import { filterFalsy } from "@/util/filterFalsy";
import { pluralize } from "@/util/pluralize";
import { useBreakpoints } from "@/util/useBreakpoints";
import { useDocumentTitle } from "@/util/useDocumentTitle";
import { usePagination } from "@/util/usePagination";

import { SetupFlow } from "@/pages/Settings/LocationSettings/SetupFlow";

import { isDemoUser, useMe } from "@/components/Auth";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { SearchBox } from "@/components/SearchBox";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { timezoneLabelMap } from "@/components/TimezonePicker";
import { DefaultDialog, useDialog } from "@/components/shared/Dialog";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  useDeleteLocationMutation,
  usePage_LocationsQuery,
} from "@/generated-models";
import { usePermissions } from "@/hooks/usePermissions";
import { MobileHeader } from "@/layout/MobileHeader";

export function LocationSettings() {
  return (
    <Routes>
      <Route index element={<Locations />} />
      {<Route path="*" element={<SetupFlow />} />}
    </Routes>
  );
}

function Locations() {
  useDocumentTitle("Locations");
  const { fitsDesktop } = useBreakpoints();
  const user = useMe();
  const hasPermission = usePermissions();
  const isDemo = !!user && isDemoUser(user); // only relevant for demo orgs
  //Edit location page permission same as admin
  const canEditLocation = Boolean(hasPermission("devices_manage"));
  const [searchInput, setSearchInput] = useState("");

  return (
    <>
      <MobileHeader label="Locations" />

      {fitsDesktop && (
        <div className="flex justify-between items-center p-4">
          <Typography variant="h1">Locations</Typography>
          <SearchBox
            input={searchInput}
            setInput={setSearchInput}
            style={{ minWidth: 250, margin: "0 16px 0 auto" }}
          />
          {(canEditLocation || isDemo) && (
            <Button
              to="create"
              color="primary"
              variant="contained"
              component={Link}
              disabled={isDemo}
            >
              Create a New Location
            </Button>
          )}
        </div>
      )}
      <div className="shadow-divider">
        <LocationList
          canEditLocation={canEditLocation && fitsDesktop}
          searchInput={searchInput}
        />
      </div>
    </>
  );
}

const useStyles = makeStyles()((theme) => ({
  grid: {
    padding: "8px 4px 4px",
  },
  locationMap: {
    width: "100%",
    height: 169,
    backgroundColor: "#f5f5f5",
    backgroundSize: "initial",
    border: "1px solid #d5d5d5",
  },
  cameraOnline: {
    color: "#62b309",
  },
  cameraOffline: {
    color: "rgba(0, 0, 0, 0.38)",
  },
}));

const pageSize = 20;

interface LocationListProps {
  canEditLocation: boolean;
  searchInput: string;
}

function LocationList({ canEditLocation, searchInput }: LocationListProps) {
  const { classes } = useStyles();
  const { locationCounts } = useFlags();
  const { data, error } = usePage_LocationsQuery(refetchOnMountPolicy);
  const { pageCount, reset, visibilitySensor } = usePagination(1);
  useUpdateEffect(() => reset(), [searchInput]);
  const feedback = useFeedback();

  const [deleteLocation] = useDeleteLocationMutation({
    refetchQueries: ["getLocations"],
    onCompleted: () =>
      feedback.pushSnackbar(
        "Location deleted successfully",
        FeedbackType.Success
      ),
    onError: (error) =>
      feedback.pushSnackbar(
        error.graphQLErrors[0]?.message ||
          "Deleting location failed, please try again",
        FeedbackType.Error
      ),
  });

  const [locationName, setLocationName] = useState<string | null>(null);
  const { open, ...dialogProps } = useDialog();

  if (error) {
    return (
      <ErrorMessage
        title="Oops"
        description="We are having trouble loading the locations. Please refresh or try again later."
      />
    );
  }

  // Loading
  if (!data?.locations) {
    return (
      <div style={{ padding: "48px 0", margin: "0 auto" }}>
        <Loading>Loading locations...</Loading>
      </div>
    );
  }

  // No locations yet
  if (data.locations.length === 0) {
    return (
      <ErrorMessage
        title="No locations found"
        description={
          canEditLocation
            ? "Please create your first location"
            : "Please contact your admin, as your admin hasn't assigned any camera locations to you"
        }
      />
    );
  }

  // Filter locations based on search query
  const searchQuery = searchInput.toLowerCase();
  const locations = !searchQuery
    ? data.locations
    : data.locations.filter((l) =>
        [
          l.name,
          l.address,
          l.city,
          l.state,
          l.zipcode,
          timezoneLabelMap[l.timezone],
        ].some((field) => field?.toLowerCase().includes(searchQuery))
      );

  // No search results
  if (locations.length === 0) {
    return (
      <ErrorMessage
        title="No search results"
        description="Try another search query"
      />
    );
  }

  const numberOfLocationsWithOfflineAppliances = locations.filter((loc) => {
    return loc.appliances.some((appliance) => !appliance.health.online);
  }).length;

  const locationsText = pluralize(
    { 1: "location", multi: "locations" },
    numberOfLocationsWithOfflineAppliances
  );

  return (
    <>
      {locationCounts && (
        <Grid
          item
          className="shadow-divider"
          style={{
            padding: 18,
            backgroundColor: "#f3f3f3",
          }}
        >
          <Grid item xs={4}>
            <Typography variant="subtitle1">
              <span style={{ fontWeight: "bold" }}>{locations.length}</span>{" "}
              {pluralize(
                { 1: "location", multi: "locations" },
                locations.length
              )}
              .{" "}
              <span style={{ fontWeight: "bold" }}>
                {numberOfLocationsWithOfflineAppliances}
              </span>{" "}
              {locationsText} with offline appliances.
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid container className={classes.grid}>
        {sortBy("name", locations)
          .slice(0, pageCount * pageSize)
          .map((loc) => {
            const centerParams = [loc.address, loc.city, loc.state];
            const onlineCamCount = loc.cameras.filter(
              (c) => c.status === "online"
            ).length;
            const searchParams = new URLSearchParams({
              center: centerParams.filter(filterFalsy).join(","),
              zoom: "14",
              size: "360x167",
              key: "AIzaSyAiJY_JuGeRkueiaEq4deZAsppGrkZSNLo",
              markers: `color:0x007ce4|${loc.address},${loc.city}`,
            });
            gmapStyles.forEach((style) => {
              searchParams.append("style", style);
            });
            return (
              <Grid item xs={12} md={6} key={loc.id} style={{ padding: 10 }}>
                <Card elevation={4}>
                  <div style={{ padding: 8 }}>
                    {loc.address && loc.city ? (
                      <CardMedia
                        className={classes.locationMap}
                        image={`https://maps.googleapis.com/maps/api/staticmap?${searchParams.toString()}`}
                      />
                    ) : (
                      <CardMedia
                        className={classes.locationMap}
                        image="/blank-location.svg"
                        style={{
                          display: "flex",
                          alignItems: "flex-end",
                          backgroundSize: "cover",
                        }}
                      >
                        <Typography
                          color="textSecondary"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: 8,
                            textDecoration: "none",
                          }}
                        >
                          <InfoIcon style={{ marginRight: 8 }} />
                          Please add address
                        </Typography>
                      </CardMedia>
                    )}
                  </div>
                  <div style={{ padding: "0 14px" }}>
                    <Typography variant="h6" component="h2">
                      {loc.name}
                    </Typography>
                    <div>{loc.address || <>&nbsp;</>}</div>
                    <div>
                      {[
                        loc.city,
                        [loc.state, loc.zipcode].filter(Boolean).join(" "),
                      ]
                        .filter(Boolean)
                        .join(", ") || <>&nbsp;</>}
                    </div>
                    <Box m={0.5} />
                    <div>
                      {`Timezone: ${
                        timezoneLabelMap[loc.timezone] || loc.timezone
                      }`}
                    </div>
                    <div>{loc.cameras.length} Cameras Total</div>
                    <div
                      className={
                        onlineCamCount > 0
                          ? classes.cameraOnline
                          : classes.cameraOffline
                      }
                    >
                      {onlineCamCount} Cameras Online
                    </div>
                    <Box m={1} />
                    {<Divider />}
                  </div>
                  {
                    <div style={{ display: "flex" }}>
                      <Button
                        color="primary"
                        component={Link}
                        to={String(loc.id)}
                        style={{ fontWeight: "bold" }}
                      >
                        {canEditLocation ? "Edit" : "View"}
                      </Button>
                      {canEditLocation && (
                        <BiggerTooltip
                          title={
                            loc.cameras.length > 0
                              ? "Locations with cameras cannot be deleted"
                              : ""
                          }
                        >
                          <span style={{ marginLeft: "auto" }}>
                            <Button
                              color="primary"
                              onClick={async () => {
                                setLocationName(loc.name);
                                const confirmed = await open();
                                if (!confirmed) return;
                                await deleteLocation({
                                  variables: { id: loc.id },
                                });
                              }}
                              disabled={loc.cameras.length > 0}
                              style={{ fontWeight: 400 }}
                            >
                              Delete
                            </Button>
                          </span>
                        </BiggerTooltip>
                      )}
                    </div>
                  }
                </Card>
              </Grid>
            );
          })}
        {locations.length > pageSize && visibilitySensor}
        <DefaultDialog
          title={`Are you sure you want to delete ${locationName}?`}
          confirmText="Delete Location"
          content="This action cannot be undone."
          verifyText="DELETE"
          {...dialogProps}
        />
      </Grid>
    </>
  );
}

const BiggerTooltip = withStyles(Tooltip, () => ({
  tooltip: {
    fontSize: 14,
  },
}));

gql`
  query page_locations {
    locations {
      id
      name
      timezone
      address
      zipcode
      state
      city

      cameras(lifecycleStates: enabled) {
        id
        status
      }

      appliances {
        id
        health {
          online
        }
      }
    }
  }
`;

gql`
  mutation deleteLocation($id: Int!) {
    deleteLocation(id: $id) {
      id
    }
  }
`;

export const gmapStyles = [
  {
    element: "geometry",
    color: "0xf5f5f5",
  },
  {
    element: "labels.icon",
    visibility: "off",
  },
  {
    element: "labels.text.fill",
    color: "0x616161",
  },
  {
    element: "labels.text.stroke",
    color: "0xf5f5f5",
  },
  {
    feature: "administrative.land_parcel",
    element: "labels.text.fill",
    color: "0xbdbdbd",
  },
  {
    feature: "poi",
    element: "geometry",
    color: "0xeeeeee",
  },
  {
    feature: "poi",
    element: "labels.text.fill",
    color: "0x757575",
  },
  {
    feature: "poi.park",
    element: "geometry",
    color: "0xe5e5e5",
  },
  {
    feature: "poi.park",
    element: "labels.text.fill",
    color: "0x9e9e9e",
  },
  {
    feature: "road",
    element: "geometry",
    color: "0xffffff",
  },
  {
    feature: "road.arterial",
    element: "labels.text.fill",
    color: "0x757575",
  },
  {
    feature: "road.highway",
    element: "geometry",
    color: "0xdadada",
  },
  {
    feature: "road.highway",
    element: "labels.text.fill",
    color: "0x616161",
  },
  {
    feature: "road.local",
    element: "labels.text.fill",
    color: "0x9e9e9e",
  },
  {
    feature: "transit.line",
    element: "geometry",
    color: "0xe5e5e5",
  },
  {
    feature: "transit.station",
    element: "geometry",
    color: "0xeeeeee",
  },
  {
    feature: "water",
    element: "geometry",
    color: "0xc9c9c9",
  },
  {
    feature: "water",
    element: "labels.text.fill",
    color: "0x9e9e9e",
  },
].map(({ feature, element, ...styles }) =>
  [
    feature && `feature:${feature}`,
    element && `element:${element}`,
    styles &&
      Object.entries(styles)
        .filter(([, value]) => Boolean(value))
        .map(([key, value]) => `${key}:${value}`),
  ].join("|")
);
