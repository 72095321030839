import { createContext, Dispatch, SetStateAction, useContext } from "react";
import { FullScreenHandle } from "react-full-screen";

type StateTuple<S> = [S, Dispatch<SetStateAction<S>>];

export const VideoWallContext = createContext({
  fullscreenHandle: {
    active: false,
    enter: async () => {},
    exit: async () => {},
    node: { current: null },
  } as FullScreenHandle,
  iosActiveState: initialState(false),
  autoLayoutState: initialState(true),
  originalConfigurationState: initialState(false),
  editingState: initialState(false),
  debugMode: false,
  fullscreenActive: false,
});

export function useVideoWallContext() {
  return useContext(VideoWallContext);
}

function initialState<S>(initial: S) {
  return [initial, () => {}] as StateTuple<S>;
}
