import { SvgIconProps, Typography } from "@mui/material";
import clsx from "clsx";
import { FunctionComponent } from "react";
import { StringParam, useQueryParam, withDefault } from "use-query-params";

import { useBreakpoints } from "@/util/useBreakpoints";

export enum TabType {
  DEVICES = "devices",
  EVENTS = "events",
}

export const TabParam = withDefault(StringParam, TabType.DEVICES);

interface IntegrationDetailsTabProps {
  tab: string;
  label: string;
  Icon: FunctionComponent<SvgIconProps<"svg", {}>>;
}

export function IntegrationDetailsTab({
  tab,
  label,
  Icon,
}: IntegrationDetailsTabProps) {
  const { fitsDesktop } = useBreakpoints();
  const [activeTab, setActiveTab] = useQueryParam("tab", TabParam);

  const active = tab === activeTab;
  return (
    <button
      disabled={active}
      onClick={() => {
        setActiveTab(tab);
      }}
      type="button"
      className={clsx(
        "md:rounded-t-2xl bg-transparent md:bg-white flex items-center justify-start border-b-2 border-solid md:border-b-0",
        {
          "md:py-4 md:px-7 gap-[7px] md:gap-[10px] border-primary": active,
          "md:py-4 md:px-5 text-primary md:bg-opacity-50 gap-[7px] border-transparent": !active,
        }
      )}
    >
      <Icon style={{ fontSize: fitsDesktop ? (active ? 28 : 19) : 24 }} />
      <Typography
        className={clsx({
          "text-lg leading-[21px] md:text-2xl md:leading-[28px] font-bold": active,
          "text-lg leading-[21px] md:text-lg md:leading-[21px]": !active,
        })}
      >
        {label}
      </Typography>
    </button>
  );
}
