import { Dispatch, SetStateAction, useState, useEffect } from "react";

/**
 * Stolen from https://github.com/dance2die/react-use-localstorage, however, their implementation
 * uses useEffect to update local storage, which means accessing `localStorage[key]` will be behind
 * updates to the state managed by this hook. This resulted in an authentication bug for us where
 * a request was fired because the state-managed value was updated, but the graphql auth header
 * reads from localStorage which wasn't updated yet.
 *
 * https://github.com/dance2die/react-use-localstorage/issues/29
 */
export function useLocalStorage<T = any>(
  key: string,
  initialValue?: T
): [T, Dispatch<SetStateAction<T>>] {
  const state = useState<T>(() => {
    const value =
      localStorage.getItem(key) || JSON.stringify(initialValue ?? null);
    localStorage.setItem(key, value);
    return JSON.parse(value);
  });

  const stateValue = state[0];
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(stateValue ?? null));
  }, [key, stateValue]);
  return state;
}
