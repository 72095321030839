import { CircularProgress, IconButton } from "@mui/material";
import clsx from "clsx";
import React, { useState } from "react";

import { trackClipDownload } from "@/util/analytics";
import { downloadExternalFile } from "@/util/file";

import { downloadSupport, useDownloads } from "@/components/Downloads";
import { DownloadIcon } from "@/components/Player/PlayerIcons";
import { PlayerTooltip } from "@/components/Player/PlayerTooltip";
import { ShareTrayItem } from "@/components/Player/ShareTrayItem";

import { ClipDownloadQueryVariables } from "@/generated-models";

export const DownloadButton = React.memo(function DownloadButton({
  cameraId,
  startTime,
  endTime,
  disabled,
  label,
}: ClipDownloadQueryVariables & { disabled?: boolean; label?: string }) {
  const { downloadClip } = useDownloads();

  if (!downloadSupport) return null;

  return (
    <ShareTrayItem
      disabled={disabled}
      onClick={() => {
        trackClipDownload();
        downloadClip({ cameraId, startTime, endTime });
      }}
      aria-label="download"
      icon={<DownloadIcon />}
      label={label ?? "Download"}
    />
  );
});

interface SharedButtonProps {
  downloadLink?: string;
  startDownload: () => void;
}

export const SharedDownloadButton = React.memo(function SharedButton({
  downloadLink,
  startDownload,
}: SharedButtonProps) {
  const [started, setStarted] = useState(false);
  const inProgress = !downloadLink && started;

  return (
    <PlayerTooltip title="Download">
      {/**
       * Wrapper div is required to ensure the Tooltip works even
       * when the button is disabled, as disabled buttons don't emit events
       **/}
      <div className="md:bg-black rounded-md hover:md:bg-gray-900">
        <IconButton
          className={clsx("text-white flex-center", {
            "opacity-20": inProgress,
          })}
          disabled={inProgress}
          onClick={() => {
            trackClipDownload();
            if (downloadLink) {
              downloadExternalFile(downloadLink);
            } else {
              startDownload();
              setStarted(true);
            }
          }}
          size="large"
        >
          {inProgress ? <CircularProgress size={23} /> : <DownloadIcon />}
        </IconButton>
      </div>
    </PlayerTooltip>
  );
});
