import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Button, FormControlLabel, Switch, Typography } from "@mui/material";
import ace from "brace";
import "brace/mode/json";
import "brace/theme/github";
import gql from "graphql-tag";
import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";

import { DeviceIcon } from "@/pages/Maintain/DeviceIcon";
import { MaintainStatusChip } from "@/pages/Maintain/MaintainStatusChip";

import { useMe } from "@/components/Auth";
import { useUpdateCameraSettings } from "@/components/Camera/hooks";
import CameraSupportDetails from "@/components/CameraSupportDetails";
import EditableText from "@/components/EditableText";
import { useUpdateCamera } from "@/components/Genius/Forms/Camera/cameraHooks";
import { LazyImage } from "@/components/shared/LazyImage";

import {
  CameraDetailByIdDocument,
  CameraDetailByIdQuery,
  CameraSettingsType,
  Role,
  useCameraCustomArgsByIdQuery,
  useSyncCamerasToApplianceMutation,
  useUpdateCameraCustomArgsMutation,
} from "@/generated-models";
import { usePermissions } from "@/hooks/usePermissions";

import { usePrefixOrgSlug } from "../../../hooks/useOrgRouteBase";

// TODO Use when we move towards separate detail tabs
// interface MaintainDetailsTabProp
//   extends DetailedHTMLProps<
//     ButtonHTMLAttributes<HTMLButtonElement>,
//     HTMLButtonElement
//   > {
//   label: string;
//   active?: boolean | null;
// }
//
// function MaintainDetailsTab({
//   label,
//   active,
//   ...props
// }: MaintainDetailsTabProp) {
//   return (
//     <button
//       className={clsx("bg-white w-full h-9 rounded-t", {
//         "opacity-[0.44] hover:opacity-100": !active,
//         "cursor-default": active,
//       })}
//       disabled={active || false}
//       {...props}
//     >
//       <Typography
//         variant="body1"
//         classes={{
//           body1: clsx({
//             "text-primary font-bold": active,
//             "font-medium": !active,
//           }),
//         }}
//       >
//         {label}
//       </Typography>
//     </button>
//   );
// }

const TCP_ARG =
  "-rtsp_transport tcp -y -i '{uri}' -c:a copy -c:v copy -f mpegts -";
interface JsonEditorRef {
  jsonEditor: {
    set: (val: any) => void; // here we define `that set method`
  };
}

export function MaintainDetailsHeader({
  camera,
}: {
  camera: CameraDetailByIdQuery["camera"];
}) {
  const hasPermissions = usePermissions();
  const { id, name, location, health, device } = camera;
  const [showEditor, setShowEditor] = useState(false);
  const [editorDirty, setEditorDirty] = useState(false);
  const [editorValue, setEditorValue] = useState<{ nvpFrontendArgs?: string }>(
    {}
  );
  const editorRef = useRef<JsonEditorRef>();
  const me = useMe();
  const hasPermission = usePermissions();
  const { update: updateCamera, loading } = useUpdateCamera();
  const [updateCameraCustomArgs] = useUpdateCameraCustomArgsMutation();
  const { cameraBulkCfg } = useFlags();
  const isSupport = me && me.role >= Role.Support;
  const isSuccess = me && me.role >= Role.Success;
  const [lprEnabledSwitch, setLprEnabledSwitch] = useState(
    !!camera.settings.lprEnabled
  );
  const [syncCameras] = useSyncCamerasToApplianceMutation({
    variables: { locationId: camera.location.id },
  });

  useCameraCustomArgsByIdQuery({
    variables: { cameraId: id },
    skip: !isSupport,
    onCompleted: (data) => {
      setEditorValue(data?.camera?.customArgs || {});
    },
  });

  const {
    mutation: [updateCameraSettings],
  } = useUpdateCameraSettings({
    refetchQueries: [
      { query: CameraDetailByIdDocument, variables: { cameraId: camera.id } },
    ],
  });

  const prefixOrgSlug = usePrefixOrgSlug();

  return (
    <div className="flex flex-col sm:flex-row w-full justify-between sm:h-[360px] shadow-divider">
      <div className="flex-1 sm:h-[360px] h-full relative w-full">
        {!health?.cameraOnline && (
          <div className="w-full h-full absolute">
            <ErrorOutlineIcon className="text-white w-24 h-24 sm:w-[165px] sm:h-[165px] absolute opacity-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
          </div>
        )}
        {showEditor ? (
          <Editor
            ref={editorRef}
            value={editorValue}
            onChange={(value: any) => {
              setEditorValue(value);
              setEditorDirty(true);
            }}
            ace={ace}
            theme="ace/theme/github"
            htmlElementProps={{ style: { height: "100%" } }}
            mode="code"
          />
        ) : (
          <LazyImage
            cameraId={Number(id)}
            className="w-full sm:h-[360px] h-full bg-cover"
            containerClassName="flex-1"
          />
        )}
      </div>
      <div className="w-full sm:w-[365px] md:w-[465px] bg-[#007CE4]/10 h-full bg-gradient-to-t from-black/5 via-transparent">
        <div className="smp:y-0 py-6 flex flex-col justify-center pl-11 gap-y-1 h-full">
          <div>
            <MaintainStatusChip camera={camera} />
          </div>
          <Typography className="mt-1" variant="body1">
            {location?.name}
          </Typography>
          <div className="flex gap-x-2 items-center mb-1">
            <DeviceIcon isNvr={device.isNvr} />
            <EditableText
              disabled={!hasPermissions("devices_manage")}
              TypographyProps={{
                style: { fontSize: 24, fontWeight: "bold", lineHeight: "32px" },
              }}
              initialValue={name}
              onSubmit={(value) => {
                updateCamera(camera.id, undefined, value);
              }}
            />
          </div>
          <div className="space-x-1">
            {hasPermission("video_live_access") && (
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to={prefixOrgSlug(`/live?cams=${id}`)}
              >
                View Live Camera
              </Button>
            )}
            {isSupport && (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setShowEditor(!showEditor)}
              >
                Video Pipeline Args
              </Button>
            )}
          </div>
          <CameraSupportDetails camera={camera} />
          {isSupport && showEditor && (
            <div className="flex gap-x-1">
              <Button
                variant="contained"
                color="primary"
                disabled={!editorDirty}
                onClick={() => {
                  updateCameraCustomArgs({
                    variables: { id: id, input: editorValue },
                  });
                  setShowEditor(false);
                }}
              >
                Save Custom Args
              </Button>
              {isSupport && !editorValue?.nvpFrontendArgs && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setEditorValue({
                      ...editorValue,
                      nvpFrontendArgs: TCP_ARG,
                    });
                    setEditorDirty(true);
                    editorRef?.current?.jsonEditor?.set({
                      ...editorValue,
                      nvpFrontendArgs: TCP_ARG,
                    });
                  }}
                >
                  Inject TCP Args
                </Button>
              )}
            </div>
          )}
          {isSuccess && !cameraBulkCfg && (
            <FormControlLabel
              disabled={loading}
              control={
                <Switch
                  checked={lprEnabledSwitch}
                  onChange={async (e) => {
                    const checked = e.currentTarget.checked;
                    await updateCameraSettings({
                      variables: {
                        ids: [camera.id],
                        input: {
                          settings: [
                            {
                              type: CameraSettingsType.AiLpr,
                              enabled: checked,
                            },
                          ],
                        },
                      },
                    });
                    await syncCameras();
                    setLprEnabledSwitch(checked);
                  }}
                />
              }
              label="Enable Camera LPR (Requires appliance LPR key)"
            />
          )}
        </div>
        {/** Detail Category Tabs, uncomment when needed for future versions.  */}
        {/* {isAdmin && (
          <div className="flex justify-around -mt-9 gap-x-3 px-2 sm:py-0 pt-12">
            <MaintainDetailsTab
              label="Analytics"
              active={!configure}
              onClick={() => setConfigure(false)}
            />
            <MaintainDetailsTab
              label="Configure"
              active={configure}
              onClick={() => setConfigure(true)}
            />
          </div>
        )} */}
      </div>
    </div>
  );
}

gql`
  query cameraCustomArgsById($cameraId: Int!) {
    camera(id: $cameraId) {
      id
      customArgs
    }
  }
`;

gql`
  mutation UpdateCameraCustomArgs($id: Int!, $input: JSON) {
    updateCameraCustomArgs(id: $id, input: $input) {
      id
    }
  }
`;
