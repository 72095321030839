import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment } from "@mui/material";
import { StringParam, useQueryParam } from "use-query-params";

import { useBreakpoints } from "@/util/useBreakpoints";

import { SearchBox } from "@/components/SearchBox";

export function UserManagementMobileSearchBox() {
  const { fitsDesktop } = useBreakpoints();
  const [searchInputParam, _setSearchInputParam] = useQueryParam(
    "search",
    StringParam
  );
  const setSearchInputParam = (value: string) =>
    _setSearchInputParam(value || undefined);

  if (fitsDesktop) return null;
  return (
    <SearchBox
      InputProps={{
        disableUnderline: true,
        classes: {
          root: "text-primary",
        },
      }}
      className="bg-white text-primary"
      input={searchInputParam || ""}
      setInput={setSearchInputParam}
      style={{ padding: 12, width: "100%" }}
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      }
    />
  );
}
