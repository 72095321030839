import { ChevronLeft, Visibility, VisibilityOff } from "@mui/icons-material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { motion } from "framer-motion";
import { intersection } from "lodash";
import { useMemo } from "react";

import { pluralize } from "@/util/pluralize";

import { PromptListType } from "@/generated-models";
import { useCustomScrollbarStyles } from "@/layout/theme";

import { useRefetchUniversalSearch } from "../../../copilotQueryHooks";
import { CopilotPopoverHeader } from "../Core/CopilotPopoverHeader";
import {
  LibraryMode,
  useCloseLibraryPopover,
  useLibraryParams,
  useOrgPromptList,
  usePromptListDetails,
  useUpdatePromptLabel,
} from "../copilotLibraryHooks";

function DetailsBackButton() {
  const [, setLibraryParams] = useLibraryParams();

  return (
    <IconButton
      className="bg-[#2D2D2D] w-[22px] h-[22px]"
      size="small"
      onClick={() => {
        setLibraryParams({ mode: LibraryMode.default, details: undefined });
      }}
    >
      <ChevronLeft className="text-white" />
    </IconButton>
  );
}

function PromptItemButton({
  label,
  disabled,
  visible,
  onClick,
  onRemove,
}: {
  label: string;
  disabled: boolean;
  visible?: boolean;
  onClick: () => void;
  onRemove: (() => void) | null;
}) {
  const refetch = useRefetchUniversalSearch();
  const Icon = visible ? Visibility : VisibilityOff;

  return (
    <ListItemButton
      className="group px-2 py-0.5 hover:bg-[#2D2D2D]"
      disabled={disabled}
      onClick={onClick}
    >
      <ListItemText
        className="text-white my-0 text-[13px] leading-[20px] w-full"
        primary={label}
      />
      <ListItemIcon
        className={clsx("text-white group-hover:opacity-100 min-w-min", {
          "opacity-50": visible,
          "opacity-20": !visible,
        })}
      >
        <Icon className="w-4 h-4" />
      </ListItemIcon>
      {onRemove && (
        <ListItemButton
          classes={{ root: "pr-0" }}
          onClick={async (e) => {
            e.stopPropagation();
            e.preventDefault();
            await onRemove();
            await refetch();
          }}
          className="text-white opacity-50 group-hover:opacity-100 min-w-min"
        >
          <HighlightOffIcon className="w-4 h-4" />
        </ListItemButton>
      )}
    </ListItemButton>
  );
}

export function CopilotLibraryDetailsContent() {
  const { data } = usePromptListDetails();
  const { data: orgList } = useOrgPromptList();

  const prompts = useMemo(() => data?.prompts || [], [data?.prompts]);

  const ignoreListForCurrentList = useMemo(() => {
    return intersection(
      prompts.map((p) => p.label),
      orgList?.ignoreList || []
    );
  }, [orgList?.ignoreList, prompts]);

  const isCustom = data?.type === PromptListType.Organization;
  const handleClose = useCloseLibraryPopover();

  const count = prompts.length;
  const hiddenCount = ignoreListForCurrentList.length;

  const label = `${count} ${data?.name} ${count > 1 ? "Objects" : " Object"}`;

  const { loading, reset, remove, toggle } = useUpdatePromptLabel();
  const scrollbarClasses = useCustomScrollbarStyles();

  return (
    <>
      <CopilotPopoverHeader
        title={label}
        icon={<DetailsBackButton />}
        onClose={handleClose}
      />
      <motion.div
        key="lib-details-list-content"
        transition={{ type: "spring", stiffness: 100, mass: 0.75 }}
        initial={{ opacity: 0, translateX: -10 }}
        animate={{ opacity: 1, translateX: 0 }}
        className="py-0 overflow-scroll"
        style={{
          maxHeight: 600,
        }}
      >
        <Typography className="text-[13px] leading-normal italic text-[#B8B8B8]/50 mb-3">
          Change the visibility of the library objects for your optimal
          searching experience.
        </Typography>
        <div className="flex flex-col">
          <List
            className={clsx(
              "py-0 h-[338px] overflow-y-scroll white-scrollbar",
              scrollbarClasses.classes.darkScrollbarContainer
            )}
          >
            {prompts.map((p, idx) => (
              <PromptItemButton
                key={`${p.label}-${idx}`}
                label={p.label}
                visible={!ignoreListForCurrentList.includes(p.label)}
                disabled={loading}
                onRemove={
                  isCustom
                    ? () => {
                        remove(orgList?.id!, p.label);
                      }
                    : null
                }
                onClick={() => {
                  toggle(orgList?.id!, p.label, orgList?.ignoreList || []);
                }}
              />
            ))}
          </List>
          <div
            className={clsx(
              "text-white flex items-center justify-center gap-2 text-[11px] leading-[12.89px] mt-2",
              {
                invisible: !hiddenCount,
              }
            )}
          >
            <div className="opacity-50 italic">
              {hiddenCount}{" "}
              {pluralize(
                {
                  1: "Object",
                  multi: "Objects",
                },
                hiddenCount
              )}{" "}
              hidden
            </div>
            <Divider orientation="vertical" className="border-white/40 h-2" />
            <button
              type="button"
              onClick={() => {
                reset(
                  orgList?.id!,
                  ignoreListForCurrentList,
                  orgList?.ignoreList || []
                );
              }}
              className="bg-transparent text-primary"
            >
              Reset
            </button>
          </div>
        </div>
      </motion.div>
    </>
  );
}
