import { Box, Typography } from "@mui/material";
import React, { ReactNode } from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  title: {
    display: "inline-block",
  },
}));

interface WalkthroughHeaderProps {
  title: string;
  description?: ReactNode;
}

export default function WalkthroughHeader({
  title,
  description,
}: WalkthroughHeaderProps) {
  const { classes } = useStyles();
  return (
    <Box display="flex" flexDirection="column" mt={3} ml={3} mr={3}>
      <Typography className={classes.title} variant="h1">
        {title}
      </Typography>
      {description && (
        <Typography
          variant="body1"
          style={{ display: "inline-block", color: "#353D48" }}
        >
          {description}
        </Typography>
      )}
    </Box>
  );
}
