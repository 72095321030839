export function redirectCopilot(
  url: URL,
  cameraId: number,
  zoneId: number | null
) {
  url.searchParams.append("cams", `${cameraId}`);
  url.searchParams.append("zoneId", `${zoneId}`);

  window.open(url.toString(), "_blank");
}

export function calculatePillWidth(label: string) {
  return label.length * 10 + 52;
}

export function calculatePosition(label: string, x: number, y: number) {
  return {
    left: `calc(${x}% - ${calculatePillWidth(label)}px)`,
    top: `${y}%`,
  };
}
