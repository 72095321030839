import { DisabledTooltip } from "../shared/CustomTooltips";

export const MaxCapacityTooltip = ({
  maxCapacityReached,
  maxAICapacityReached,
  children,
}: {
  maxCapacityReached: boolean;
  maxAICapacityReached?: boolean;
  children: React.ReactElement<any, any>;
}) => {
  const title = !!maxAICapacityReached
    ? "Max AI Capacity Reached"
    : "Max Capacity Reached";
  return (
    <DisabledTooltip
      disabled={maxCapacityReached || !!maxAICapacityReached}
      title={title}
    >
      {children}
    </DisabledTooltip>
  );
};
