import { ChipProps } from "@mui/material";

export const chipProps: ChipProps = {
  variant: "outlined",
  color: "primary",
  classes: {
    root: "min-w-0 bg-[#F6FBFF] border-[#B6D8F5] h-[27px] cursor-default",
    label: "font-normal text-md",
    deleteIcon: "w-3 h-3 mr-2.5",
  },
} as const;

export enum IOBoardStatus {
  Connected = "connect",
  Disconnected = "disconnect",
  Unknown = "unknown",
}

export const CFG_FAILED_MSG =
  "Failed to validate the integration, please try again";
export const CFG_SUCCESS_MSG = "Connection successfully validated.";
