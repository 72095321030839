import { ContentCopy } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import * as clipboard from "clipboard-polyfill/build/clipboard-polyfill.promise";
import clsx from "clsx";
import { format } from "date-fns/fp";
import { useState } from "react";

import { ErrorMessage } from "@/components/ErrorMessage";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { SpotSwitch } from "@/components/Styled/SpotSwitch";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  ApigeeActivationAction,
  ApigeeStatus,
  DevApiAccessTokenExpirationLength,
  DeveloperApiAccessToken,
  DeveloperApiAccessTokenMetadata,
  useCreateDevApiAccessTokenMutation,
  useCreateDevApiCredentialMutation,
  useDevApiAccessTokenMetadataQuery,
  useDevApiCredentialQuery,
  useDevApiSecretLazyQuery,
  useInvalidateDevApiAccessTokenMutation,
  useRegenerateDevApiCredentialMutation,
  useUpdateDevApiCredentialMutation,
} from "@/generated-models";

interface ApiFieldProps {
  label: string;
  value: string;
  loading?: boolean;
  secretValue?: string;
  error?: string;
  onReveal?: () => void;
  onHide?: () => void;
}

function ApiField({
  label,
  value,
  loading,
  secretValue,
  error,
  onReveal,
  onHide,
}: ApiFieldProps) {
  const { pushSnackbar } = useFeedback();

  const secret = !!onReveal;
  const hidden = secret && !secretValue;

  return (
    <div className="flex flex-col gap-[6px] items-start">
      <label>{label}</label>
      <div
        className={clsx(
          "rounded border border-solid border-[#DFDFDF] bg-[#F8F8F8] px-[11px] py-[6px] flex justify-start items-center gap-[9px]",
          { "text-error": !!error }
        )}
      >
        <Typography
          style={{
            lineBreak: "anywhere",
          }}
          className={clsx(
            "text-xs leading-[15px] sm:text-sm sm:leading-[18px] break-words font-mono",
            hidden && "blur-sm"
          )}
        >
          {secretValue || value}
        </Typography>
        {secret && (
          <button
            className="bg-white px-[10px] py-1 border border-solid border-[#DADADA] rounded text-[11px] leading-3 text-primary w-[55px]"
            type="button"
            disabled={loading}
            onClick={hidden ? onReveal : onHide}
          >
            {hidden ? "Reveal" : "Hide"}
          </button>
        )}
        <Divider className="h-6" orientation="vertical" />
        <>
          {loading && <CircularProgress className="mx-1" size={16} />}
          {!loading && (
            <IconButton
              className="p-1"
              color="primary"
              size="small"
              onClick={async () => {
                await clipboard.writeText(secretValue || value);
                pushSnackbar(`${label} Copied!`, FeedbackType.Info);
              }}
            >
              <ContentCopy className="w-4 h-4" />
            </IconButton>
          )}
        </>
      </div>
      {error && <Typography className="text-error text-xs">{error}</Typography>}
    </div>
  );
}

export function DeveloperApiKeys() {
  const { pushSnackbar } = useFeedback();
  const { data, loading, error } = useDevApiCredentialQuery();

  const creds = data?.devApiCredential;
  const configured = !!creds;
  const enabled = creds?.status === ApigeeStatus.Approved;
  const [update, { loading: updating }] = useUpdateDevApiCredentialMutation({
    update: (cache, { data }) => {
      cache.modify({
        id: "ROOT_QUERY",
        fields: {
          devApiCredential() {
            return data?.updateDevApiCredential || null;
          },
        },
      });
    },
    onError: (e) => {
      pushSnackbar(e.message, FeedbackType.Error);
    },
  });

  if (error)
    return (
      <div className="w-full">
        <ErrorMessage title="Error" description={error.message} />
      </div>
    );

  return (
    <div className="flex flex-col gap-[26px] w-full">
      <div className="flex sm:flex-row flex-col items-start sm:items-center justify-between">
        <Typography className="text-lg font-bold leading-[21px]">
          API Keys
        </Typography>
        {configured && (
          <div className="sm:ml-0 -ml-4 mt-2 sm:mt-0">
            <SpotSwitch
              label={`API Key is ${enabled ? "" : "in"}active`}
              checked={enabled}
              disabled={updating}
              onChange={() => {
                update({
                  variables: {
                    input: {
                      action: enabled
                        ? ApigeeActivationAction.Revoke
                        : ApigeeActivationAction.Approve,
                    },
                  },
                });
              }}
            />
          </div>
        )}
      </div>
      {loading && (
        <div className="flex items-center justify-center p-[10px]">
          <CircularProgress size={24} />
        </div>
      )}
      {!loading && (
        <>
          {configured && (
            <DevelopeApiKeysConfiguredState
              clientId={creds.clientId}
              email={creds.developerEmail}
            />
          )}
          {!configured && <DevelopeApiKeysEmptyState />}
        </>
      )}
      {configured && <AccessTokenSection />}
    </div>
  );
}

function DevelopeApiKeysEmptyState() {
  const { pushSnackbar } = useFeedback();
  const [developerEmail, setDeveloperEmail] = useState("");
  const [create, { loading }] = useCreateDevApiCredentialMutation({
    update: (cache, { data }) => {
      cache.modify({
        id: "ROOT_QUERY",
        fields: {
          devApiCredential() {
            return data?.createDevApiCredential || null;
          },
        },
      });
    },
    onError: (e) => {
      pushSnackbar(e.message, FeedbackType.Error);
    },
  });
  return (
    <div className="flex flex-col sm:flex-row items-center justify-start gap-y-4 gap-x-2">
      {loading && (
        <div className="flex items-center justify-center w-full pt-[15px]">
          <CircularProgress size={30} />
        </div>
      )}
      {!loading && (
        <>
          <OutlinedInput
            inputProps={{ className: "py-[11px]" }}
            className="flex-1 rounded-lg w-full sm:w-[unset] sm:max-w-[400px]"
            placeholder="Developer Email"
            value={developerEmail}
            disabled={loading}
            onChange={(e) => {
              setDeveloperEmail(e.target.value);
            }}
          />
          <Button
            className="flex-1 text-lg leading-[21px] font-bold shadow-none px-[22px] py-[12px] w-full sm:w-[unset]"
            variant="contained"
            color="primary"
            disabled={developerEmail.length < 3 || loading}
            onClick={() => {
              const validEmail = /\S+@\S+\.\S+/.test(developerEmail);
              if (!validEmail) {
                pushSnackbar("Invalid email address.", FeedbackType.Error);
                return;
              }
              create({
                variables: {
                  input: {
                    developerEmail,
                  },
                },
              });
            }}
          >
            Create API Key
          </Button>
        </>
      )}
    </div>
  );
}

function DevelopeApiKeysConfiguredState({
  clientId,
  email,
}: {
  clientId: string;
  email: string;
}) {
  const { pushSnackbar } = useFeedback();
  const clientSecret = "*".repeat(64);
  const [getSecret, { loading }] = useDevApiSecretLazyQuery({
    fetchPolicy: "network-only",
  });
  const [secret, setSecret] = useState("");

  const [
    regen,
    { loading: regenerating },
  ] = useRegenerateDevApiCredentialMutation({
    update: (cache, { data }) => {
      cache.modify({
        id: "ROOT_QUERY",
        fields: {
          devApiCredential() {
            return data?.regenerateDevApiCredential || null;
          },
        },
      });
    },
    onCompleted: () => {
      setSecret("");
    },
    onError: (e) => {
      pushSnackbar(e.message, FeedbackType.Error);
    },
  });

  return (
    <>
      <div className="flex flex-col gap-[14px]">
        <ApiField label="Developer Email" value={email} />
        <ApiField label="clientId" value={clientId} />
        <ApiField
          loading={loading}
          label="clientSecret"
          value={clientSecret}
          secretValue={secret}
          onReveal={async () => {
            const secret = await getSecret();
            const result = secret?.data?.devApiSecret?.clientSecret;

            if (!result) {
              pushSnackbar(
                "Could not fetch clientSecret, please contact support if this persists.",
                FeedbackType.Error
              );
            } else {
              setSecret(result);
            }
          }}
          onHide={() => {
            setSecret("");
          }}
        />
      </div>
      <div>
        <Button
          onClick={() => {
            regen();
          }}
          disabled={regenerating}
          color="primary"
          variant="outlined"
          className="font-normal w-full sm:w-[unset]"
        >
          {regenerating ? "Regenerating..." : "Regenerate Key"}
        </Button>
      </div>
    </>
  );
}

function AccessTokenSection() {
  const { pushSnackbar } = useFeedback();
  const { data, loading, error } = useDevApiAccessTokenMetadataQuery(
    refetchOnMountPolicy
  );
  const [createData, setCreateData] = useState<DeveloperApiAccessToken | null>(
    null
  );
  const [create, { loading: creating }] = useCreateDevApiAccessTokenMutation({
    onError: (e) => {
      pushSnackbar(e.message, FeedbackType.Error);
    },
    onCompleted: (data) => {
      setCreateData(data.createDevApiAccessToken);
    },
  });

  if (error) {
    return (
      <div className="w-full">
        <ErrorMessage
          title="Failed to fetch access token"
          description={error?.message}
          dense
        />
      </div>
    );
  }

  if (loading) {
    return (
      <div className="flex-center p-3">
        <CircularProgress size={24} />
      </div>
    );
  }

  const configuredData = createData ?? data?.devApiAccessTokenMetadata;

  return (
    <div className="flex flex-col gap-6 w-full">
      <Typography className="text-lg font-bold">Access Token</Typography>
      {configuredData ? (
        <AccessTokenConfiguredState
          data={configuredData}
          create={create}
          creating={creating}
        />
      ) : (
        <AccessTokenEmptyState create={create} creating={creating} />
      )}
    </div>
  );
}

function AccessTokenEmptyState({
  create,
  creating,
}: {
  create: ReturnType<typeof useCreateDevApiAccessTokenMutation>[0];
  creating: boolean;
}) {
  const [tokenLifetime, setTokenLifetime] = useState(
    DevApiAccessTokenExpirationLength.OneMonth
  );

  return (
    <div className="flex flex-col gap-4">
      <div className="text-xs">
        Valid for 30 days. Afterwards, the token must be regenerated in order
        for continued access to the API.
      </div>
      <div className="flex flex-col sm:flex-row items-center gap-2">
        <FormControl className="flex-1 w-full sm:w-[unset] sm:max-w-[400px] hidden">
          <InputLabel id="select-label">Token Expiration</InputLabel>
          <Select
            labelId="select-label"
            id="demo-simple-select"
            value={tokenLifetime}
            label="Token Expiration"
            variant="outlined"
            inputProps={{ className: "py-[11px]" }}
            readOnly
            disabled={creating}
            onChange={(event) => {
              setTokenLifetime(
                event.target.value as DevApiAccessTokenExpirationLength
              );
            }}
          >
            <MenuItem value={DevApiAccessTokenExpirationLength.OneMonth}>
              1 month
            </MenuItem>
            <MenuItem value={DevApiAccessTokenExpirationLength.ThreeMonths}>
              3 months
            </MenuItem>
            <MenuItem value={DevApiAccessTokenExpirationLength.SixMonths}>
              6 months
            </MenuItem>
          </Select>
        </FormControl>
        <Button
          className="flex-1 text-lg leading-[21px] font-bold shadow-none px-[22px] py-3 w-full sm:w-[unset]"
          variant="contained"
          color="primary"
          disabled={creating}
          onClick={() => {
            create({
              variables: {
                input: {
                  tokenLifetime,
                },
              },
            });
          }}
        >
          {creating ? "Creating..." : "Create Access Token"}
        </Button>
      </div>
    </div>
  );
}

const formatDateTime = format("Pp");

function AccessTokenConfiguredState({
  data,
  create,
  creating,
}: {
  data: DeveloperApiAccessTokenMetadata | DeveloperApiAccessToken;
  create: ReturnType<typeof useCreateDevApiAccessTokenMutation>[0];
  creating: boolean;
}) {
  const [tokenHidden, setTokenHidden] = useState(true);
  const { pushSnackbar } = useFeedback();
  const [invalidate, { loading }] = useInvalidateDevApiAccessTokenMutation({
    update: (cache) => {
      cache.evict({ id: cache.identify(data) });
    },
    onError: (e) => {
      pushSnackbar(e.message, FeedbackType.Error);
    },
  });

  async function regenerate() {
    await invalidate();
    await create({
      variables: {
        input: {
          tokenLifetime: DevApiAccessTokenExpirationLength.OneMonth,
        },
      },
    });
  }

  const resolvedLoading = loading || creating;

  return (
    <>
      <div className="flex flex-col gap-3">
        {data.__typename === "DeveloperApiAccessTokenMetadata" ? (
          <ApiField
            label="Created"
            value={formatDateTime(new Date(data.created))}
          />
        ) : (
          <>
            <div className="text-xs">
              Access token can only be viewed once. Please copy it to a safe
              place.
            </div>
            <ApiField
              label="Token"
              value={data.accessToken}
              secretValue={tokenHidden ? undefined : data.accessToken}
              onReveal={() => setTokenHidden(false)}
              onHide={() => setTokenHidden(true)}
            />
          </>
        )}
        <ApiField
          label="Expires"
          value={formatDateTime(new Date(data.expires))}
          error={
            data.__typename === "DeveloperApiAccessTokenMetadata" &&
            data.isExpired
              ? "Token has expired"
              : undefined
          }
        />
      </div>
      <div>
        <Button
          onClick={() => regenerate()}
          disabled={resolvedLoading}
          color="primary"
          variant="outlined"
          className="font-normal w-full sm:w-[unset]"
        >
          {resolvedLoading ? "Regenerating..." : "Regenerate Access Token"}
        </Button>
      </div>
    </>
  );
}
