import { Logger } from "@datadog/browser-logs";
import { useRoomContext } from "@livekit/components-react";
import { ConnectionQuality } from "livekit-client";
import { useEffect } from "react";

import {
  PlayerMachineEvent,
  usePlayerService,
} from "@/components/Player/playerMachine";

const useSourceStatus = (metricsLogger: Logger) => {
  const { send } = usePlayerService();
  const room = useRoomContext();

  useEffect(() => {
    const run = async () => {
      if (room) {
        const statsReport = await room.engine.subscriber?.pc.getStats();

        // Check if the connection is established over local (host) candidates
        if (statsReport) {
          const stats = [...statsReport.values()];
          const selectedCandidatePair = stats.filter(
            (s) =>
              s.type === "candidate-pair" &&
              // Chrome does not return the `selected` property
              s.nominated &&
              s.state === "succeeded"
          )[0];

          if (selectedCandidatePair) {
            const localCandidate = statsReport.get(
              selectedCandidatePair.localCandidateId
            );
            const remoteCandidate = statsReport.get(
              selectedCandidatePair.localCandidateId
            );
            const local =
              isPrivateIP(localCandidate.ip || localCandidate.address) &&
              isPrivateIP(remoteCandidate.ip || remoteCandidate.address);

            if (local) {
              send(PlayerMachineEvent.LOCAL_SOURCE_REACHABLE);
            } else {
              send(PlayerMachineEvent.TUNNEL_SOURCE_REACHABLE);
            }
          }
        }
      }
    };
    run();
  }, [room, send]);

  useEffect(() => {
    room.on("reconnecting", () => {
      metricsLogger.log("reconnectAttempt", {
        timeStamp: Date.now(),
      });

      // If reconnection is not possible within 15 secs, disconnect and try a new connection
      setTimeout(() => {
        room.disconnect();
      }, 15000);
    });
    room.on("connectionQualityChanged", (quality, participant) => {
      if (quality === ConnectionQuality.Poor) {
        metricsLogger.log("connection Quality", {
          timeStamp: Date.now(),
          quality: quality,
        });
      }
    });
    room.on("reconnected", () => {
      metricsLogger.log("reconnectSuccess", {
        timeStamp: Date.now(),
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useSourceStatus;

function isPrivateIP(ip: string) {
  const parts = ip.split(".").map((part) => parseInt(part, 10));
  return (
    parts[0] === 10 ||
    (parts[0] === 172 && parts[1] >= 16 && parts[1] <= 31) ||
    (parts[0] === 192 && parts[1] === 168)
  );
}
