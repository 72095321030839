import clsx from "clsx";
import { atom as jAtom, useAtom } from "jotai";
import { StringParam, useQueryParam } from "use-query-params";

import { LicensePlateDetails } from "./LicensePlateDetails/LicensePlateDetails";
import { LicensePlateHeader } from "./LicensePlateHeader";
import { LicensePlateSearchBar } from "./LicensePlateSearchBar/LicensePlateSearchBar";
import { LicensePlateSearchResultGrid } from "./LicensePlateSearchResultGrid";
import { LicensePlateSummaryBar } from "./LicensePlateSummaryBar/LicensePlateSummaryBar";
import { MINIMUM_QUERY_LENGTH } from "./constants";
import { useLprSearch } from "./hooks";

export const multiSelectionState = jAtom<string[] | null>(null);
multiSelectionState.debugLabel = "multiSelectionState";
export function useMultiSelectionState() {
  return useAtom(multiSelectionState);
}

export function LicensePlatePage() {
  const baseProps = { className: "mx-4 md:mx-16 lg:mx-32" };
  const [viewing] = useQueryParam("viewing", StringParam);
  const [searchFilter] = useQueryParam("search", StringParam);
  const validSearch = !!searchFilter
    ? searchFilter.length >= MINIMUM_QUERY_LENGTH
    : false;
  const { disabled } = useLprSearch();

  return (
    <div className="h-full overflow-y-scroll">
      <div
        className={clsx("flex flex-col pt-1 pb-6 md:py-6", {
          "gap-8 md:gap-20": !validSearch,
          "gap-4 md:gap-10": validSearch,
        })}
      >
        <LicensePlateHeader {...baseProps} condensed={validSearch} />
        <LicensePlateSearchBar {...baseProps} />
        {!disabled && validSearch && (
          <div className="flex flex-col gap-5">
            <LicensePlateSummaryBar {...baseProps} />
            {!viewing && (
              <div {...baseProps}>
                <LicensePlateSearchResultGrid />
              </div>
            )}
            {viewing && <LicensePlateDetails />}
          </div>
        )}
      </div>
    </div>
  );
}
