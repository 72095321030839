import Editor, { Monaco, OnChange, OnMount } from "@monaco-editor/react";
import { CircularProgress } from "@mui/material";
import clsx from "clsx";
import { IDisposable, editor } from "monaco-editor";
import { useEffect, useRef, useState } from "react";

import { refetchOnMountPolicy } from "@/apolloClient";
import { usePage_AssistantQuery } from "@/generated-models";

import {
  DataGridContextualPlayer,
  useDataGridContextPlayer,
} from "../VideoWall/ContextualVideoPlayer";
import {
  EDITOR_BASE_OPTIONS,
  SPOT_ASSIST_LANG,
  SPOT_ASSIST_THEME,
} from "./utils/editor/constant";
import { registerAssistantLanguage } from "./utils/editor/editorLanguage";
import { registerAssistantTheme } from "./utils/editor/editorTheme";
import { getEditorVodMatch } from "./utils/utils";

interface AssistantEditorReadOnlyInputProps {
  onChange?: OnChange;
  onMount?: OnMount;
  value?: string;
  isSender?: boolean;
  containerClassName?: string;
  className?: string;
}

const DEFAULT_MIN_HEIGHT = 27;

export function AssistantEditorReadOnlyInput({
  containerClassName,
  isSender,
  onMount,
  ...props
}: AssistantEditorReadOnlyInputProps) {
  const { data, loading } = usePage_AssistantQuery(refetchOnMountPolicy);
  const disposables = useRef<IDisposable[]>([]);
  const [height, setHeight] = useState(DEFAULT_MIN_HEIGHT);

  const {
    open,
    handleOpen,
    handleClose,
    playerProps,
  } = useDataGridContextPlayer();

  const baseStyle = {
    height,
  };

  function handleEditorWillMount(monaco: Monaco) {
    disposables.current.push(...[registerAssistantLanguage(monaco)]);

    registerAssistantTheme(monaco);
  }

  function handleEditorDidMount(
    editor: editor.IStandaloneCodeEditor,
    monaco: Monaco
  ) {
    if (onMount) {
      onMount(editor, monaco);
    }

    editor.onDidContentSizeChange((event) => {
      setHeight(event.contentHeight);
    });

    editor.onMouseDown(({ target }) => {
      const vodMatch = getEditorVodMatch(
        editor,
        target.position,
        data?.cameras
      );

      if (vodMatch) {
        handleOpen(vodMatch);
      }
    });
  }

  useEffect(() => {
    const currentDisposables = disposables.current;
    return () => {
      currentDisposables.forEach((d) => {
        d.dispose();
      });
    };
  }, []);

  return (
    <div style={baseStyle} className={clsx("w-full", containerClassName)}>
      <DataGridContextualPlayer
        open={open}
        handleClose={handleClose}
        playerProps={playerProps}
      />

      {loading && (
        <div className="flex items-center justify-center w-full h-full">
          <CircularProgress className="text-white" />
        </div>
      )}
      {!loading && (
        <Editor
          {...props}
          width="100%"
          height="100%"
          defaultValue=""
          className="!text-red-500"
          defaultLanguage={SPOT_ASSIST_LANG}
          theme={SPOT_ASSIST_THEME}
          beforeMount={handleEditorWillMount}
          onMount={handleEditorDidMount}
          options={{
            ...EDITOR_BASE_OPTIONS,
            readOnly: true,
            wordWrap: "on",
            wrappingStrategy: "advanced",
          }}
        />
      )}
    </div>
  );
}
