import { Button, Typography } from "@mui/material";
import clsx from "clsx";
import { ReactNode, useState } from "react";

export function TruncateList({
  children,
  limit = 5,
  classes,
}: {
  children: ReactNode[];
  limit?: number;
  classes?: {
    root?: string;
  };
}) {
  const [truncated, setTruncated] = useState(true);
  return (
    <div className={clsx("flex flex-wrap gap-2 mt-2", classes?.root)}>
      {truncated && children.length > limit ? (
        <>
          {children.slice(0, limit)}
          <Button
            color="primary"
            size="small"
            onClick={() => setTruncated(false)}
          >
            View All ({children.length})
          </Button>
        </>
      ) : (
        children
      )}

      {children.length === 0 && (
        <Typography variant="caption" className="opacity-40 font-light">
          none selected
        </Typography>
      )}
    </div>
  );
}
