import { GraphQLError } from "graphql";
import gql from "graphql-tag";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";

import {
  CreateIntegrationV2Input,
  IntegrationDeviceCreateInput,
  useCreateIntegrationDevicesMutation,
  useCreateIntegrationEventTypesMutation,
  useCreateIntegrationV2Mutation,
  useDeleteIntegrationV2Mutation,
  useIntegrationTypesV2Query,
  useIntegrationV2Query,
  useUpdateIntegrationV2Mutation,
} from "@/generated-models";
import { usePrefixOrgSlug } from "@/hooks/useOrgRouteBase";

import { isNotDeleted } from ".";
import { generateJsonSchema } from "../Form/Event/schemaUtils";
import { IntegrationFormEventField } from "../Form/Event/utils";
import { PLACEHOLDER_ID } from "../constant";

type CreateConnectIntegrationPayload = {
  events: IntegrationFormEventField[];
  devices: IntegrationDeviceCreateInput[];
  integrationInput: CreateIntegrationV2Input;
};

export function useCurrentIntegrationId() {
  const { integrationId: tempIntId } = useParams();
  return Number(tempIntId || null);
}

export function useCurrentIntegration() {
  const id = useCurrentIntegrationId();

  const result = useIntegrationV2Query({
    variables: {
      input: {
        id,
      },
    },
    skip: !id,
  });

  return {
    integration: result.data?.integrationV2,
    ...result,
  };
}

export function useIntegrationTypes() {
  const { data, ...rest } = useIntegrationTypesV2Query();

  const items = useMemo(() => {
    return data?.integrationTypesV2.filter(isNotDeleted);
  }, [data?.integrationTypesV2]);

  return {
    data: items || [],
    ...rest,
  };
}

// Handles the process of creating an integration
// 1. Create the integration
// 2. If specified, create the devices for the integration
// 3. Create any event types for the integration
export function useCreateIntegration() {
  const navigate = useNavigate();
  const prefixOrgSlug = usePrefixOrgSlug();
  const { pushSnackbar } = useFeedback();

  const [
    createIntegration,
    { loading: loadingCreateIntegration },
  ] = useCreateIntegrationV2Mutation({
    onError: () =>
      pushSnackbar(
        "Unable to create the integration, please try again.",
        FeedbackType.Error
      ),
  });

  const [
    createDevices,
    { loading: loadingCreateDevices },
  ] = useCreateIntegrationDevicesMutation({
    onError: (error) =>
      pushSnackbar(
        "Unable to create the integration device data, please try again.",
        FeedbackType.Error
      ),
  });

  const [
    createEvents,
    { loading: loadingCreateEvent },
  ] = useCreateIntegrationEventTypesMutation({
    onError: (error) =>
      pushSnackbar(
        "Unable to create the integration event data, please try again.",
        FeedbackType.Error
      ),
  });

  const [
    deleteIntegration,
    { loading: loadingDeleteIntegration },
  ] = useDeleteIntegrationV2Mutation();

  const loading =
    loadingCreateIntegration ||
    loadingCreateDevices ||
    loadingCreateEvent ||
    loadingDeleteIntegration;

  return {
    loading,
    create: async ({
      devices,
      events,
      integrationInput,
    }: CreateConnectIntegrationPayload) => {
      let integrationId = PLACEHOLDER_ID;
      let resultErrors: GraphQLError[] = [];

      try {
        const { data } = await createIntegration({
          variables: { input: integrationInput },
        });

        if (data?.createIntegrationV2?.id) {
          integrationId = data?.createIntegrationV2?.id;
        } else {
          throw new Error("Failed to create integration");
        }

        if (devices.length > 0) {
          const { errors } = await createDevices({
            variables: {
              input: {
                integrationId,
                devices,
              },
            },
          });

          if (errors) {
            resultErrors.push(...errors);
          }
        }

        if (events.length > 0) {
          const { errors } = await createEvents({
            variables: {
              input: {
                integrationId,
                integrationEventTypes: events.map((e) => ({
                  name: e.name,
                  schema: generateJsonSchema(e.properties),
                  buffer: e.buffer ?? undefined,
                  duration: e.duration ?? undefined,
                })),
              },
            },
          });

          if (errors) {
            resultErrors.push(...errors);
          }
        }

        if (resultErrors.length > 0) {
          throw new Error("Failed to create integration resources");
        }

        navigate(prefixOrgSlug(`/integrations/connect/spot/${integrationId}`));
      } catch (e) {
        if (integrationId >= 0) {
          await deleteIntegration({ variables: { id: integrationId } });
        }

        pushSnackbar(
          "Unable to create the integration, please try again.",
          FeedbackType.Error
        );
      }
    },
  };
}

export function useEditIntegration() {
  const id = useCurrentIntegrationId();
  const { pushSnackbar } = useFeedback();

  const [editIntegration, { loading }] = useUpdateIntegrationV2Mutation({
    onCompleted: () =>
      pushSnackbar("Integration successfully updated!", FeedbackType.Success),
    onError: () =>
      pushSnackbar(
        "Unable to edit the integration, please try again.",
        FeedbackType.Error
      ),
  });

  return {
    loading,
    edit: async (name: string, onCompleted?: () => void) => {
      editIntegration({
        variables: {
          input: {
            id,
            name,
          },
        },
        onCompleted,
      });
    },
  };
}

gql`
  fragment IntegrationTypeV2Fragment on IntegrationTypeV2 {
    id
    name
    label
    isDeleted
    defaultSchema
  }
`;

gql`
  fragment IntegrationV2Fragment on IntegrationV2 {
    id
    name
    organizationId
    isDeleted

    integrationType {
      ...IntegrationTypeV2Fragment
    }
  }
`;

gql`
  mutation createIntegrationV2($input: CreateIntegrationV2Input!) {
    createIntegrationV2(input: $input) {
      ...IntegrationV2Fragment
    }
  }
`;

gql`
  mutation updateIntegrationV2($input: UpdateIntegrationV2Input!) {
    updateIntegrationV2(input: $input) {
      ...IntegrationV2Fragment
    }
  }
`;

gql`
  query integrationTypesV2 {
    integrationTypesV2 {
      ...IntegrationTypeV2Fragment
    }
  }
`;

gql`
  query page_integrationsV2 {
    integrationsV2 {
      ...IntegrationV2Fragment
    }
  }
`;

gql`
  query integrationV2($input: IntegrationV2Input!) {
    integrationV2(input: $input) {
      ...IntegrationV2Fragment
    }
  }
`;
