import { useNavigate } from "react-router-dom";

import { ErrorMessage } from "@/components/ErrorMessage";

import { IntegrationsEventDatagrid } from "../../Core/IntegrationsEventDatagrid";
import { IntegrationEventData, useIntegrationEvents } from "../../hooks";
import { IntegrationDetailsEventSummaryBar } from "./IntegrationDetailsEventSummaryBar";

export function IntegrationDetailsEventPage() {
  const navigate = useNavigate();
  const { data, loading, error } = useIntegrationEvents();

  if (error) {
    return <ErrorMessage title="Error" description={error.toString()} />;
  }

  return (
    <div className="flex flex-col gap-2 md:gap-6">
      <IntegrationDetailsEventSummaryBar
        loading={loading}
        events={data as IntegrationEventData[]}
      />
      <IntegrationsEventDatagrid
        onRowClick={(_, options) => {
          navigate({
            ...options,
            search: `${options.search}&tab=events`,
          });
        }}
      />
    </div>
  );
}
