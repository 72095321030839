import React from "react";

import { IconProps } from "@/icons/IconProps";

export function DoubleArrow({ style, className, onClick }: IconProps) {
  return (
    <svg
      style={style}
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="7"
      viewBox="0 0 24 7"
      preserveAspectRatio="none"
    >
      <path
        fill="#D6D6D6"
        d="M18.864.5l5 3-5 3V3.852L5.25 3.845V6.5l-5-3 5-3v2.345l13.614.007V.5z"
      />
    </svg>
  );
}
