import { Skeleton, Typography } from "@mui/material";

import { ErrorMessage } from "@/components/ErrorMessage";

import {
  InterestListType,
  usePage_InterestListsQuery,
} from "@/generated-models";

import { interestListConfig } from "../constants";
import { InterestListDetailsForm } from "./InterestListDetailsForm";
import { InterestListDialogAddSingleItemInput } from "./InterestListDialogSingleItemInput";
import { InterestListSelectionMenu } from "./InterestListSelectionMenu";

export function InterestListAddItemForm({ type }: { type: InterestListType }) {
  const { data, error } = usePage_InterestListsQuery();
  const config = interestListConfig[type];

  if (error) {
    return (
      <ErrorMessage
        title="Unable to load lists"
        description="We're having trouble loading lists, please try again later"
      />
    );
  }

  if (!data?.interestLists) {
    return (
      <div className="flex flex-col gap-3">
        <Skeleton className="rounded-lg" variant="rectangular" height={44} />
        <Skeleton className="rounded-lg" variant="rectangular" height={44} />
        <Skeleton className="rounded-lg" variant="rectangular" height={44} />
      </div>
    );
  }

  if (data.interestLists.length === 0) {
    return <InterestListDetailsForm type={type} />;
  }

  return (
    <>
      <Typography className="text-sm leading-[16.41px] pb-4">
        {config.addToListPrompt}
      </Typography>
      <InterestListDialogAddSingleItemInput type={type} />
      <InterestListSelectionMenu prompt="" type={type} />
    </>
  );
}
