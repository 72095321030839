import gql from "graphql-tag";
import { atom, useAtom, useSetAtom } from "jotai";
import { atomWithStorage, useAtomCallback } from "jotai/utils";
import { useCallback } from "react";

import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";

import { useAskSpotLazyQuery } from "@/generated-models";

import { questionAtom } from "./AssistantPrompt/AssistantPromptInput";

type AssistantMessageData = {
  id?: number;
  isSender: boolean;
  content: string;
  ts: number;
};

export const conversationLocationAtom = atomWithStorage<number | null>(
  "conversationLocationId",
  null
);
export const conversationAtom = atom<AssistantMessageData[]>([]);
export const conversationLoadingAtom = atom(false);

// Need to be accessible via monaco callback context.
function useAskSpotState() {
  return useAtomCallback(
    useCallback((get) => {
      const isLoading = get(conversationLoadingAtom);
      const question = get(questionAtom);
      const locationId = get(conversationLocationAtom);
      const conversation = get(conversationAtom);

      return {
        isDisabled: isLoading || question.length === 0,
        locationId,
        question,
        conversation,
        lastQuestion: conversation
          .slice()
          .reverse()
          .find((c) => c.isSender),
        lastResponse: conversation
          .slice()
          .reverse()
          .find((c) => !c.isSender),
      };
    }, [])
  );
}

export function useAskSpot(regenerate?: boolean) {
  const { pushSnackbar } = useFeedback();
  const [conversation, setConversation] = useAtom(conversationAtom);
  const setQuestion = useSetAtom(questionAtom);
  const setConversationLoading = useSetAtom(conversationLoadingAtom);
  const getAskSpotState = useAskSpotState();

  const [askSpot, query] = useAskSpotLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setConversationLoading(false);
      setConversation([
        ...conversation,
        {
          isSender: false,
          id: data?.askSpot?.id,
          content: data?.askSpot?.response?.content || "",
          ts: Date.now(),
        },
      ]);
      setQuestion("");
    },
    onError: () => {
      setConversationLoading(false);
      pushSnackbar(
        "Unable to receive a response from SpotFetch.",
        FeedbackType.Error
      );
      setConversation([
        ...conversation,
        {
          isSender: false,
          content:
            "I'm sorry, I'm unable to process the requested information.",
          ts: Date.now(),
        },
      ]);
    },
  });

  return {
    askSpot: async () => {
      const {
        conversation: conversationData,
        isDisabled,
        locationId,
        lastQuestion,
        lastResponse,
        question,
      } = await getAskSpotState();
      if (isDisabled) return;

      if (locationId) {
        setConversation([
          ...conversationData,
          {
            isSender: true,
            content:
              regenerate && lastQuestion ? lastQuestion.content : question,
            ts: Date.now(),
          },
        ]);

        setConversationLoading(true);
        await askSpot({
          variables: {
            chatId: regenerate ? null : lastResponse?.id,
            locationId,
            question,
          },
        });
      } else {
        pushSnackbar("Please set a location", FeedbackType.Warning);
      }
    },
    query,
  };
}

gql`
  query askSpot($question: String!, $locationId: Int!, $chatId: Int) {
    askSpot(question: $question, locationId: $locationId, chatId: $chatId) {
      id
      question {
        messageType
        content
      }
      response {
        messageType
        content
      }
    }
  }
`;
