import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
  Autocomplete,
} from "@mui/material";
import { createFilterOptions } from "@mui/material/useAutocomplete";
import clsx from "clsx";
import gql from "graphql-tag";
import { uniq } from "lodash/fp";
import React, { Dispatch, useState } from "react";

import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  useGetCaseTagsQuery,
  useUpdateCaseTagsMutation,
} from "@/generated-models";

interface TagsButtonProps {
  caseId: number;
  currentTags: string[];
  className?: string;
}

export function AddCaseTagsButton({ className, ...props }: TagsButtonProps) {
  const [opened, setOpened] = useState(false);
  return (
    <>
      <Button
        color="primary"
        className={clsx("font-normal", className)}
        onClick={() => setOpened(true)}
      >
        + Add a tag
      </Button>
      {opened && <AddCaseTagsModal {...props} close={() => setOpened(false)} />}
    </>
  );
}

function AddCaseTagsModal({
  caseId,
  currentTags,
  close,
}: TagsButtonProps & {
  close: () => void;
}) {
  const { data } = useGetCaseTagsQuery(refetchOnMountPolicy);
  const { pushSnackbar } = useFeedback();
  const [updateTags] = useUpdateCaseTagsMutation({
    onError: () =>
      pushSnackbar("Failed to save tags, please try again", FeedbackType.Error),
  });
  const [selectedTags, setSelectedTags] = useState(currentTags);

  return (
    <Dialog
      classes={{
        paper: "rounded-lg",
      }}
      open={true}
      onClose={close}
    >
      <DialogTitle className="flex justify-between items-center py-2 bg-[#F4F4F4] mb-4">
        <Typography variant="h3">Add Tags</Typography>
        <IconButton onClick={close} size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText className="text-text mb-4">
          Tags help you organize your cases into the categories that make sense
          to you.
        </DialogContentText>
        <CaseTagSelect
          label="Tags"
          tags={data?.cases ? data.cases.flatMap((c) => c.tags) : []}
          selectedTags={selectedTags}
          setSelectedTags={(tags) => {
            setSelectedTags(tags);
            updateTags({ variables: { id: caseId, tags } });
          }}
          freeSolo
        />
      </DialogContent>
    </Dialog>
  );
}

type AutocompleteItem = {
  label: React.ReactNode;
  value: string;
};
const filterAutocomplete = createFilterOptions<AutocompleteItem>();
function mapTagValues(tags: string[]): AutocompleteItem[] {
  return tags.map((t) => ({ label: t, value: t }));
}

export function CaseTagSelect({
  tags,
  selectedTags,
  setSelectedTags,
  label,
  placeholder = "Search tags",
  freeSolo = false,
  limitTags = -1,
  size,
  className,
}: {
  tags: string[];
  selectedTags: string[];
  setSelectedTags: Dispatch<string[]>;
  label?: string;
  placeholder?: string;
  freeSolo?: boolean;
  limitTags?: number;
  size?: "small" | "medium";
  className?: string;
}) {
  return (
    <Autocomplete
      multiple
      forcePopupIcon
      freeSolo={freeSolo}
      fullWidth
      size={size}
      className={className}
      options={mapTagValues(uniq([...tags, ...selectedTags]).sort())}
      value={mapTagValues(selectedTags)}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      onChange={(_, value) =>
        setSelectedTags(
          uniq(value.map((v) => (typeof v === "string" ? v : v.value).trim()))
        )
      }
      disableCloseOnSelect
      limitTags={limitTags}
      getLimitTagsText={
        limitTags === 0 ? (more) => `${more} Selected` : undefined
      }
      noOptionsText="No tags found"
      ChipProps={{
        variant: "outlined",
        color: "primary",
        deleteIcon: <CloseIcon />,
        classes: { root: "font-medium bg-[#F6FBFF]", deleteIcon: "w-5 h-5" },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Tags"
          variant="outlined"
          placeholder={placeholder}
        />
      )}
      getOptionLabel={(option) => (option as { value: string }).value}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            checked={selected}
            color="primary"
            className="mr-2"
            icon={option.label !== option.value ? <AddIcon /> : undefined}
          />
          {option.label}
        </li>
      )}
      filterOptions={(options, params) => {
        const filtered = filterAutocomplete(options, params);

        // Suggest the creation of a new value
        const value = params.inputValue;
        if (freeSolo && value && filtered.every((v) => v.value !== value)) {
          filtered.unshift({
            value,
            label: (
              <>
                {value}
                <Button
                  variant="contained"
                  color="primary"
                  className="text-sm ml-auto"
                >
                  Create Tag
                </Button>
              </>
            ),
          });
        }

        return filtered;
      }}
    />
  );
}

gql`
  mutation updateCaseTags($id: Int!, $tags: [String!]!) {
    updateCase(id: $id, update: { tags: $tags }) {
      id
      tags
    }
  }
`;

gql`
  query getCaseTags {
    cases {
      id
      tags
    }
  }
`;
