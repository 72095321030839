import { ReactNode, useEffect } from "react";
import { isIOS, isAndroid } from "react-device-detect";
import { BooleanParam, useQueryParam } from "use-query-params";

import { iosLockLandscape, iosUnlockLandscape } from "@/util/iosMessages";

const LockLandscapeOnFullscreen = ({ children }: { children: ReactNode }) => {
  const [fullScreen] = useQueryParam("fullscreen", BooleanParam);

  useEffect(() => {
    if (fullScreen) {
      if (isIOS) {
        iosLockLandscape();
      } else if (isAndroid) {
        // TODO: Handle landscape orientation lock for android
      }
    }

    return () => {
      if (isIOS) {
        iosUnlockLandscape();
      } else if (isAndroid) {
        // TODO: Handle landscape orientation unlock for android
      }
    };
  }, [fullScreen]);

  return <>{children}</>;
};

export default LockLandscapeOnFullscreen;
