import { IntelligenceDashboardType } from "@/generated-models";

import { ComparativeDashboardProps } from "../ComparativeDashboard";
import { getComparativeType } from "../utils";
import {
  ComparativeDashboardChart,
  ComparativePresenceChart,
} from "./Chart/ComparativeDashboardChart";
import { ComparativeDashboardChartFetcher } from "./Chart/ComparativeDashboardChartFetcher";
import { ComparativeDashboardContentChips } from "./ComparativeDashboardContentChips";
import { ComparativeDashboardContentTabs } from "./ComparativeDashboardContentTabs";
import { ComparativeDashboardContentToolbar } from "./ComparativeDashboardContentToolbar";
import { ComparativeDashboardDatagrid } from "./ComparativeDashboardDatagrid";

export function ComparativeDashboardContent({
  dashboard,
}: ComparativeDashboardProps) {
  const type = getComparativeType(dashboard);
  const refDashes = dashboard?.referenceDashboards || [];
  const isPresence = type === IntelligenceDashboardType.Presence;
  const baseProps = {
    type,
    objectTypes: dashboard.objectTypes,
    thresholdSeconds: dashboard.thresholdSeconds,
    referenceDashboards: refDashes,
  };

  const previewProps = {
    thresholdSeconds: dashboard.thresholdSeconds,
    entityCount: dashboard.entityCount,
    daysOfWeek: dashboard.daysOfWeek,
    startTime: dashboard.startTime,
    endTime: dashboard.endTime,
  };

  return (
    <div>
      <ComparativeDashboardContentToolbar dashboard={dashboard} />
      <div className="p-6 flex flex-col gap-6">
        <ComparativeDashboardContentTabs
          type={type}
          objectTypes={dashboard.objectTypes}
          thresholdSeconds={dashboard.thresholdSeconds}
        />
        {isPresence ? (
          <ComparativePresenceChart referenceDashboards={refDashes} />
        ) : (
          <ComparativeDashboardChart referenceDashboards={refDashes} />
        )}
        <ComparativeDashboardChartFetcher
          comparativeId={`${dashboard.id}`}
          dashboards={refDashes.map((rd) => ({
            ...rd,
            ...previewProps,
            timezone: rd.cameras[0]?.location?.timezone || "",
          }))}
          type={type}
        />
        <ComparativeDashboardContentChips {...baseProps} />
        <ComparativeDashboardDatagrid {...baseProps} />
      </div>
    </div>
  );
}
