import { Typography } from "@mui/material";
import { utcToZonedTime, format as tzFormat } from "date-fns-tz";

import { useWallclockTime } from "@/components/Player/PlayerBase";

export function WallClock({ timezone = "UTC" }: { timezone: string }) {
  const wallclockTime = useWallclockTime() ?? new Date();
  const zonedTime = utcToZonedTime(wallclockTime, timezone);
  return (
    <div className="flex flex-col">
      <div className="flex gap-x-1 items-baseline">
        <Typography className="font-bold text-lg dark:text-[#f9f9f9]">
          {tzFormat(zonedTime, "h:mm:ss a", { timeZone: timezone })}
        </Typography>
        <Typography className="text-xs">
          ({tzFormat(zonedTime, "z", { timeZone: timezone })})
        </Typography>
      </div>
      <Typography className="text-sm sm:text-base leading-none">
        {tzFormat(zonedTime, "PP", { timeZone: timezone })}
      </Typography>
    </div>
  );
}
