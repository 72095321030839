import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { CameraDetailByIdQuery, CameraStatus } from "@/generated-models";

export function MaintainDetailsStatusBanner({
  camera,
}: {
  camera: CameraDetailByIdQuery["camera"];
}) {
  const offline = camera.status === CameraStatus.Offline;

  return offline ? (
    <div className="flex items-center gap-x-2">
      <InfoOutlinedIcon className="text-[#E30000]" />
      <div className="bg-[#FFD9D7] rounded-3xl py-[7px] px-5 text-[#930000] text-base leading-[18.75px]">
        <span className="font-bold">
          This camera is currently unreachable and offline.
        </span>{" "}
        Check the internet and local network connections.{" "}
      </div>
    </div>
  ) : (
    <></>
  );
}
