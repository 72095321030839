import SearchIcon from "@mui/icons-material/Search";
import { IconButton } from "@mui/material";
import { useState } from "react";
import { StringParam, useQueryParam } from "use-query-params";

import { SearchBox } from "../SearchBox";

export function FilterSearch() {
  const [showInput, setShowInput] = useState(false);
  const [searchFilter, setSearchFilter] = useQueryParam("search", StringParam);

  const shouldShowInput = showInput || !!searchFilter;

  return (
    <div className="flex items-center justify-between gap-2">
      {shouldShowInput && (
        <SearchBox
          className="w-full"
          input={searchFilter || ""}
          setInput={setSearchFilter}
        />
      )}
      <IconButton
        size="small"
        className="bg-[#DDEFFF] disabled:opacity-40"
        disabled={showInput && !!searchFilter}
        onClick={(e) => {
          e.stopPropagation();
          setShowInput((value) => !value);
        }}
        aria-label="clear search"
      >
        <div className="mx-0.5">
          <SearchIcon color="primary" />
        </div>
      </IconButton>
    </div>
  );
}
