import { TableSortLabel } from "@mui/material";
import clsx from "clsx";
import naturalSort from "javascript-natural-sort";
import React, { useState } from "react";

(naturalSort as any).insensitive = true;

export function useTableSort<T>(
  rows: T[] | undefined | null,
  defaultSortField: keyof T,
  defaultOrder: 1 | -1 = 1
) {
  type SortField = keyof T;
  const [sortParam, setSortParam] = useState<string | null>(null);
  const [sortField, sortOrder]: [SortField, 1 | -1] =
    sortParam !== null
      ? sortParam.startsWith("-")
        ? [sortParam.slice(1) as SortField, -1]
        : [sortParam as SortField, 1]
      : [defaultSortField, defaultOrder];

  const sortedRows =
    rows &&
    rows
      .slice(0)
      .sort((a, b) => naturalSort(a[sortField], b[sortField]) * sortOrder);

  return { setSortParam, sortField, sortOrder, sortedRows };
}

export function ConnectedTableSortLabel({
  sortField,
  sortOrder,
  name,
  setSortParam,
  children,
  classes,
}: {
  sortField: string;
  sortOrder: 1 | -1;
  name: string;
  setSortParam: React.Dispatch<string | null>;
  children: React.ReactNode;
  classes?: {
    active?: string;
  };
}) {
  const materialSortOrder = sortOrder === 1 ? "asc" : "desc";
  const active = sortField === name;
  return (
    <TableSortLabel
      active={active}
      direction={active ? materialSortOrder : "asc"}
      onClick={() =>
        setSortParam(!active || sortOrder === -1 ? name : `-${name}`)
      }
      classes={{
        root: active ? clsx("font-bold", classes?.active) : "font-normal",
        icon: active ? clsx("font-bold", classes?.active) : "font-normal",
      }}
    >
      {children}
    </TableSortLabel>
  );
}
