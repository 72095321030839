import { Hidden, Typography } from "@mui/material";
import clsx from "clsx";
import React, { ReactNode } from "react";
import { BooleanParam, useQueryParams } from "use-query-params";

import { MuxConfiguration } from "@/util/useMuxMetadata";

import { downloadSupport } from "@/components/Downloads";
import { FullScreen } from "@/components/FullScreen";
import { Back10Button } from "@/components/Player/Back10Button";
import { BufferingIndicator } from "@/components/Player/BufferingIndicator";
import { CompletedOverlay } from "@/components/Player/CompletedOverlay";
import { Forward10Button } from "@/components/Player/Forward10Button";
import { FullscreenButton } from "@/components/Player/FullscreenButton";
import { KeyboardShortcuts } from "@/components/Player/KeyboardShortcuts";
import { MobileLandscapeFullscreen } from "@/components/Player/MobileLandscapeFullscreen";
import { MobileOverlay } from "@/components/Player/MobileOverlay";
import { PlayPauseButton } from "@/components/Player/PlayPauseButton";
import { PlayPauseReplayButton } from "@/components/Player/PlayPauseReplayButton";
import { PlaybackRateDisplay } from "@/components/Player/PlaybackRateDisplay";
import { PlayerAspect, PlayerBase } from "@/components/Player/PlayerBase";
import { usePlayerMobileMode } from "@/components/Player/PlayerControls";
import { PlayerControls } from "@/components/Player/PlayerControls.1";
import { PlayerMetadata } from "@/components/Player/PlayerMetadata";
import {
  PlaybackRateSetting,
  PlayerSettingsButton,
  QualitySetting,
} from "@/components/Player/PlayerSettingsButton";
import { ProgressBar } from "@/components/Player/ProgressBar";
import { ProgressDisplay } from "@/components/Player/ProgressDisplay";
import { ReplayButton } from "@/components/Player/ReplayButton";
import { ShareTrayButton } from "@/components/Player/ShareTrayButton";
import { SnapshotButton } from "@/components/Player/SnapshotButton";
import { LatestCameraStillOverlay } from "@/components/Player/Status/ConnectivityDisplay";
import { StreamSources } from "@/components/Player/StreamSources";
import { TopRightTray } from "@/components/Player/TopRightTray";
import { VideoPlayer } from "@/components/Player/VideoPlayer";
import { VolumeButton } from "@/components/Player/VolumeButton";
import { DigitalPtzButton } from "@/components/Player/ZoomButton";
import { PlayerControlsEvent } from "@/components/Player/playerControlsMachine";
import { WallclockTimer } from "@/components/View/WallclockTimer";

import { ShareMarketingButton } from "./ShareMarketingButton";

interface GuestLivePlayerProps {
  cameraName: string;
  cameraId: number;
  locationName: string;
  timezone: string;
  sources: StreamSources;
  audioControlEnabled: boolean;
  className?: string;
}

export function GuestLivePlayer({
  cameraName,
  cameraId,
  locationName,
  timezone,
  sources,
  audioControlEnabled,
  className,
}: GuestLivePlayerProps) {
  const [layoutParams] = useQueryParams({
    marketingShareButton: BooleanParam,
  });
  const playerMobileMode = usePlayerMobileMode();
  return (
    <PlayerBase>
      <FullScreen className={clsx(className, "relative flex-center")}>
        <PlayerAspect maxHeight="90vh">
          <VideoPlayer
            sources={sources}
            showingLivestream
            muxConfig={{
              cameraId,
              cameraName,
              locationName,
              playerName: "Guest Live",
            }}
          />
        </PlayerAspect>
        <KeyboardShortcuts />
        <LatestCameraStillOverlay cameraId={cameraId} />
        <MobileOverlay>
          <Back10Button fontSize={36} disabled />
          <PlayPauseButton fontSize={60} />
          <Forward10Button fontSize={36} disabled />
        </MobileOverlay>

        <BufferingIndicator />

        <TopRightTray>
          {layoutParams.marketingShareButton && (
            <ShareTrayButton>
              <ShareMarketingButton />
            </ShareTrayButton>
          )}

          <SnapshotButton cameraName={cameraName} timezone={timezone} />
        </TopRightTray>

        <PlayerControls>
          <div
            style={{
              background: "linear-gradient(transparent 0%, black 140%)",
            }}
            className="py-0.5 flex-center w-full"
          >
            {!playerMobileMode && (
              <>
                <Back10Button disabled />
                <PlayPauseButton />
                <Forward10Button disabled />
              </>
            )}

            <div className="flex grow items-center justify-end">
              <DigitalPtzButton disabled="Not available in live mode" />
              <VolumeButton enabled={audioControlEnabled} />
              <PlayerSettingsButton
                initialOpenEvent={PlayerControlsEvent.OPEN_QUALITY_SETTING}
              >
                <QualitySetting />
              </PlayerSettingsButton>
              <FullscreenButton />
            </div>
          </div>
        </PlayerControls>
      </FullScreen>

      <PlayerMetadata>
        <div className="m-2" />
        <Typography
          style={{
            marginLeft: 5,
            opacity: 0.5,
            display: "inline",
          }}
        >
          Camera:
        </Typography>
        <div className="m-1" />
        <span style={{ fontSize: 16, fontWeight: "bold" }}>{cameraName}</span>

        <Hidden lgDown>
          <div className="grow" />

          <WallclockTimer timezone={timezone} />
        </Hidden>
      </PlayerMetadata>
    </PlayerBase>
  );
}

interface GuestVodPlayerProps extends Omit<GuestLivePlayerProps, "cameraId"> {
  downloadButton: ReactNode;
  muxConfig?: MuxConfiguration;
}
export function GuestVodPlayer({
  cameraName,
  locationName,
  timezone,
  sources,
  downloadButton,
  audioControlEnabled,
  className,
  muxConfig,
}: GuestVodPlayerProps) {
  const [layoutParams] = useQueryParams({
    marketingShareButton: BooleanParam,
  });
  const playerMobileMode = usePlayerMobileMode();

  return (
    <FullScreen>
      <PlayerBase className={clsx(className, "relative")}>
        <PlayerAspect maxHeight="90vh">
          <VideoPlayer
            sources={sources}
            muxConfig={{
              cameraName,
              locationName,
              playerName: "Guest VOD",
              ...muxConfig,
            }}
          />
        </PlayerAspect>
        <KeyboardShortcuts />
        <BufferingIndicator />

        <CompletedOverlay>
          <ReplayButton fontSize={60} />
        </CompletedOverlay>
        <MobileOverlay>
          <Back10Button fontSize={36} />
          <PlayPauseReplayButton fontSize={60} />
          <Forward10Button fontSize={36} />
        </MobileOverlay>

        <MobileLandscapeFullscreen />

        <TopRightTray>
          {downloadSupport && downloadButton}
          {layoutParams.marketingShareButton && (
            <ShareTrayButton>
              <ShareMarketingButton title="Share Video Clip" />
            </ShareTrayButton>
          )}

          <SnapshotButton cameraName={cameraName} timezone={timezone} />
        </TopRightTray>

        <PlayerControls>
          {playerMobileMode && (
            <div className="flex w-full justify-end z-10 relative">
              <DigitalPtzButton />
              <VolumeButton enabled={audioControlEnabled} />
              <PlayerSettingsButton>
                <PlaybackRateSetting />
                <QualitySetting />
              </PlayerSettingsButton>
              <FullscreenButton />
            </div>
          )}

          <ProgressBar />

          {!playerMobileMode && (
            <div
              style={{
                background:
                  "linear-gradient(180deg, rgba(0, 0, 0, 0.68) 0%, #000000 90%)",
              }}
              className="py-0.5 flex-center w-full"
            >
              <Back10Button />
              <PlayPauseReplayButton />
              <Forward10Button />

              <div className="mx-2">
                <ProgressDisplay />
              </div>
              <PlaybackRateDisplay />

              <div className="flex grow items-center justify-end">
                <DigitalPtzButton />
                <VolumeButton enabled={audioControlEnabled} />
                <PlayerSettingsButton>
                  <PlaybackRateSetting />
                  <QualitySetting />
                </PlayerSettingsButton>
                <FullscreenButton />
              </div>
            </div>
          )}
        </PlayerControls>
      </PlayerBase>
    </FullScreen>
  );
}
