import { ClickAwayListener, Portal } from "@mui/material";
import clsx from "clsx";
import { motion } from "framer-motion";
import { useRef, useState } from "react";
import Draggable from "react-draggable";

import { CopilotBookLaunchIcon } from "@/icons/CopilotIcon";

import { usePlayerControls } from "@/components/Player/PlayerBase";
import { useFirstActiveCamId } from "@/components/View/sharedViewHooks";

import { useUniversalSearchByImage } from "../../copilotQueryHooks";
import { CopilotMode, useCopilotContext } from "../../useCopilotContext";
import { CopilotLibraryAddContent } from "./Content/CopilotLibraryAddContent/CopilotLibraryAddContent";
import { CopilotLibraryDetailsContent } from "./Content/CopilotLibraryDetailsContent";
import { CopilotLibraryHelpContent } from "./Content/CopilotLibraryHelpContent";
import { CopilotLibraryListContent } from "./Content/CopilotLibraryListContent";
import {
  LibraryMode,
  useCloseLibraryPopover,
  useInitializeOrgList,
  useLibraryParams,
} from "./copilotLibraryHooks";

function LibraryPopoverContent() {
  const [libraryParams] = useLibraryParams();

  switch (libraryParams.mode) {
    case LibraryMode.adding:
      return <CopilotLibraryAddContent />;
    case LibraryMode.details:
      return <CopilotLibraryDetailsContent />;
    case LibraryMode.help:
      return <CopilotLibraryHelpContent />;
    default:
      return <CopilotLibraryListContent />;
  }
}

export function CopilotLibraryPopover() {
  const [libraryParams, setLibraryParams] = useLibraryParams();
  const anchorEl = useRef<HTMLButtonElement | null>(null);
  const playerId = useFirstActiveCamId().toString();
  const { getPlayerElement } = usePlayerControls(playerId);
  const { setMode } = useCopilotContext();
  const { loading } = useInitializeOrgList();
  const { loading: searchLoading } = useUniversalSearchByImage();
  const [position, setPosition] = useState<{
    top: number | string;
    left: number | string;
  }>({ top: 0, left: 0 });
  const handleClose = useCloseLibraryPopover();
  const disabled = loading || searchLoading;

  const handleToggle = async () => {
    if (libraryParams.open) {
      handleClose();
    } else {
      setMode(CopilotMode.default);
      const boundingRect = getPlayerElement()?.getBoundingClientRect();

      if (boundingRect) {
        setPosition({
          top: (boundingRect.bottom / 3) * 2,
          left: boundingRect.right - 300,
        });
      } else {
        setPosition({
          top: "50%",
          left: "50%",
        });
      }

      setLibraryParams({ open: true });
    }
  };

  const isAdding = libraryParams.mode === LibraryMode.adding;
  const isDetails = libraryParams.mode === LibraryMode.details;

  function getMarginTop() {
    if (isDetails) return -150;
    if (isAdding) return 135;
    return 0;
  }

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <motion.button
          disabled={loading || searchLoading}
          className={clsx("relative bg-transparent mt-[7px]", {
            "opacity-40": disabled,
          })}
          ref={anchorEl}
          onClick={handleToggle}
          animate="animate"
          whileHover="hover"
          variants={{
            hover: {
              color: "#ffffff",
              translateY: -4,
              transition: { duration: 0.2 },
            },
          }}
          transition={{
            duration: 0.8,
          }}
          style={{
            color: "#925EFF",
          }}
        >
          <div>
            <CopilotBookLaunchIcon />
            <motion.span
              className="absolute text-xs text-white w-[80px] -left-5 top-[35px] opacity-0 font-barlow"
              variants={{
                hover: {
                  opacity: 1,
                },
              }}
            >
              Object Library
            </motion.span>
          </div>
        </motion.button>

        {libraryParams.open && (
          <Portal>
            <Draggable>
              <div
                className="bg-black/[.38] rounded-lg absolute z-20 cursor-move"
                style={{
                  ...position,
                  marginTop: getMarginTop(),
                }}
              >
                <div
                  className={clsx(
                    "m-[10px] p-[10px] bg-[#181818] rounded flex flex-col gap-[13px] max-h-[500px]",
                    {
                      "w-[240px]": !isAdding,
                      "w-[340px]": isAdding,
                    }
                  )}
                >
                  <LibraryPopoverContent />
                </div>
              </div>
            </Draggable>
          </Portal>
        )}
      </div>
    </ClickAwayListener>
  );
}
