import { Avatar as MaterialAvatar, AvatarProps } from "@mui/material";
import clsx from "clsx";
import React from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  root: {
    color: theme.palette.primary.main,
    backgroundColor: "#f4f4f4",
    border: "1px solid #d8d8d8",
    fontSize: 16,
    fontWeight: "bold",
  },
}));

export function Avatar({
  user,
  className,
  ...avatarProps
}: { user: { name: string; email?: string } } & AvatarProps) {
  const { classes } = useStyles();
  return (
    <MaterialAvatar className={clsx(classes.root, className)} {...avatarProps}>
      {(user.name || user.email || "")
        .split(" ")
        .map((name) => name[0])
        .filter(Boolean)
        .filter((char) => RegExp(/\w/).test(char))
        .slice(0, 2)
        .join("")
        .toUpperCase()}
    </MaterialAvatar>
  );
}
