import LeftIcon from "@mui/icons-material/ChevronLeft";
import { IconButton, Typography } from "@mui/material";
import clsx from "clsx";
import { Link } from "react-router-dom";

import { useBreakpoints } from "@/util/useBreakpoints";

import { useSearchRangeParams } from "@/pages/Search/searchHooks";

import { IntelligenceDashboardQuery } from "@/generated-models";
import { usePrefixOrgSlug } from "@/hooks/useOrgRouteBase";
import { MobileHeader } from "@/layout/MobileHeader";

import { IntelligenceDashboardViewExportButton } from "../../Export/IntelligenceDashboardViewExportButton";
import { isInterestListDashboard } from "../utils";
import { DEFAULT_TIME_RANGE, useLprReportGrouped } from "./hooks";

interface LicensePlateReportPageHeaderProps {
  dashboard: IntelligenceDashboardQuery["intelligenceDashboard"];
}

export function LicensePlateReportPageHeader({
  dashboard,
}: LicensePlateReportPageHeaderProps) {
  const prefixOrgSlug = usePrefixOrgSlug();
  const { rangeStart, rangeEnd } = useSearchRangeParams(DEFAULT_TIME_RANGE);
  const { fitsDesktop } = useBreakpoints();
  const { loading } = useLprReportGrouped();
  const description = dashboard?.description;

  const isInterestList = isInterestListDashboard(dashboard);

  if (!fitsDesktop) {
    return (
      <MobileHeader
        to={prefixOrgSlug("/intelligence")}
        label={dashboard?.name || ""}
      />
    );
  }

  return (
    <div className="flex items-center px-6">
      <IconButton
        component={Link}
        to=".."
        size="small"
        className="mr-2 text-text"
      >
        <LeftIcon fontSize="large" />
      </IconButton>
      <div className="flex flex-col gap-1">
        {isInterestList && (
          <Typography className="text-sm leading-[18.75px] text-[#00A69C] mt-4">
            License Plates of Interest
          </Typography>
        )}
        <Typography
          className={clsx("text-[24px] leading-[28.13px] font-bold", {
            "mt-5": !isInterestList,
            "mb-5": !description,
          })}
        >
          {dashboard?.name}
        </Typography>
        {description && (
          <Typography className="text-sm leading-[16.41px] text-[#757575] mb-4">
            {description}
          </Typography>
        )}
      </div>
      <div className="ml-auto">
        <IntelligenceDashboardViewExportButton
          dashboard={dashboard!}
          disabled={loading}
          startDate={rangeStart}
          endDate={rangeEnd}
        />
      </div>
    </div>
  );
}
