import { Divider, Typography } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Link, Navigate, Route, Routes, useParams } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";

import { useBreakpoints } from "@/util/useBreakpoints";

import { GrowingSearchBox } from "@/components/SearchBox";
import {
  createMobileTabs,
  createStyledTabs,
} from "@/components/StyledTabs/StyledTabs";

import { usePermissions } from "@/hooks/usePermissions";
import { MobileHeader } from "@/layout/MobileHeader";

import { RoleSettings } from "./RoleSettings";
import { UserSettings } from "./UserSettings";

const { NavTabs, NavTab } = createStyledTabs({
  tab: {
    root: { fontSize: 24, padding: "16px 24px" },
    selected: { fontWeight: "bold !important" },
  },
});

const { MobileTabs, MobileTab } = createMobileTabs();

export function UserManagement() {
  const { fitsDesktop } = useBreakpoints();
  const { customRoles } = useFlags();
  const { "*": splat } = useParams();
  const hasPermission = usePermissions();
  const hasUsersManagePermission = hasPermission("users_manage");
  const hasUsersAccessPermission = hasPermission("users_access");
  const tab = splat?.split("/")[0];
  const [searchInputParam, _setSearchInputParam] = useQueryParam(
    "search",
    StringParam
  );
  const setSearchInputParam = (value: string | null) =>
    _setSearchInputParam(value || undefined);
  return (
    <div>
      {/* <pre>{JSON.stringify({ flags }, null, 2)}</pre> */}
      {fitsDesktop ? (
        <div className="flex-center">
          {customRoles ? (
            <NavTabs
              value={tab}
              classes={{
                indicator: `text-primary`,
              }}
            >
              <NavTab component={Link} value="users" to="users" label="Users" />
              {hasUsersManagePermission && (
                <Divider
                  orientation="vertical"
                  className="h-5 self-center bg-[#e6e6e6]"
                />
              )}
              {/* <NavTab> cannot be nested in fragment, will break current tab detection */}
              {hasUsersManagePermission && (
                <NavTab
                  component={Link}
                  value="roles"
                  to="roles"
                  label="Roles"
                />
              )}
            </NavTabs>
          ) : (
            <Typography variant="h1" className="ml-4">
              Users
            </Typography>
          )}
          <div className="flex-grow" />
          <GrowingSearchBox
            key={tab}
            input={searchInputParam ?? null}
            setInput={setSearchInputParam}
            classes={{
              root: "self-center mr-4 py-3",
              iconButton: "transition-colors bg-primary bg-opacity-20",
              iconButtonActive: "!bg-opacity-0",
            }}
          />
        </div>
      ) : (
        <div className="bg-[#e7e7e7] flex flex-col gap-2">
          <MobileHeader
            label="User Management"
            classes={{
              root: "!bg-transparent",
            }}
            disableShadow
            sticky={false}
          />
          {customRoles && (
            <MobileTabs value={tab}>
              <MobileTab
                component={Link}
                value="users"
                to="users"
                label="Users"
                className="ml-3"
              />
              {hasUsersManagePermission && (
                <MobileTab
                  component={Link}
                  value="roles"
                  to="roles"
                  label="Roles"
                />
              )}
            </MobileTabs>
          )}
        </div>
      )}
      <Routes>
        <Route
          index
          element={
            <Navigate
              to={hasUsersAccessPermission ? "users" : "users/me"}
              replace
            />
          }
        />
        <Route path="users/*" element={<UserSettings />} />
        {hasUsersManagePermission && (
          <Route path="roles/*" element={<RoleSettings />} />
        )}
      </Routes>
    </div>
  );
}
