import { Typography } from "@mui/material";
import { useMemo } from "react";

import { pluralize } from "@/util/pluralize";

import { useMe } from "@/components/Auth";
import { Loading } from "@/components/Loading";

import { useFeatureLicenseSeatsQuery } from "@/generated-models";

import { AppleTVLicenseTable } from "./AppleTVLicenseTable";

export function AppleTVSettings() {
  const me = useMe();
  const { data, loading } = useFeatureLicenseSeatsQuery();

  const licenses = useMemo(() => {
    return [...(data?.featureLicenseSeats || [])]
      .sort((a, b) => a.createdAt.localeCompare(b.createdAt))
      .filter((a) => a.organizationId === me?.organization.id)
      .map((r, idx) => ({
        ...r,
        idx,
      }));
  }, [data, me?.organization.id]);

  return (
    <div>
      <div className="flex justify-between items-center flex-wrap gap-y-1 gap-x-8 py-5 px-7 bg-white ">
        <Typography variant="h1">Apple TV</Typography>
      </div>
      {loading && (
        <div className="h-[400px] flex items-center justify-center">
          <Loading>Fetching Data...</Loading>
        </div>
      )}
      {!loading && (
        <div className="flex flex-col p-6 shadow-divider sm:h-[400px]">
          <div className="h-full flex flex-col sm:flex-row items-center justify-center gap-4">
            <div className="flex flex-col items-center gap-8">
              <img
                width={175}
                height={175}
                alt="Apple TV"
                src="/apple_tv_logo.png"
              />
              <div className="flex flex-col gap-2">
                <Typography className="text-2xl font-bold leading-[26px] text-center max-w-[300px]">
                  Watch your Spot AI video walls on your Apple TV.
                </Typography>
                <Typography className="text-sm leading-[26px] text-center">
                  You have <strong>{licenses.length}</strong>{" "}
                  {pluralize(
                    {
                      1: "License",
                      multi: "Licenses",
                    },
                    licenses.length
                  )}
                </Typography>
              </div>
            </div>
            <AppleTVLicenseTable data={licenses} />
          </div>
        </div>
      )}
    </div>
  );
}
