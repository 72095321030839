import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Button, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import clsx from "clsx";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { BooleanParam, useQueryParam } from "use-query-params";

import { useBreakpoints } from "@/util/useBreakpoints";

import { DataGrid } from "@/components/DataGrid/DataGrid";
import { ErrorMessage } from "@/components/ErrorMessage";
import { useDialog } from "@/components/shared/Dialog";

import {
  IntegrationVendorKey,
  IntegrationSourcesQuery,
  useIntegrationSourcesQuery,
} from "@/generated-models";

import { useCurrentVendor, useIntegrationTypeIcon } from "../hooks";
import { IntegrationsDeviceTagsButton } from "./IntegrationsDeviceTagsButton";
import IntegrationsLinkCamerasDialog from "./IntegrationsLinkCamerasDialog";

type IntegrationSource = IntegrationSourcesQuery["integrationSources"][number];

export default function IntegrationsDeviceDatagrid({
  mode,
  hideWhenEmpty = false,
}: {
  mode: "create" | "edit";
  hideWhenEmpty?: boolean;
}) {
  const [, setFinished] = useQueryParam("finished", BooleanParam);
  const [source, setSource] = useState<IntegrationSource | null>(null);
  const { fitsDesktop } = useBreakpoints();
  const { integrationId } = useParams();
  const { open, opened, cancel } = useDialog();

  const vendor = useCurrentVendor();
  const { data, loading, error } = useIntegrationSourcesQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        id: Number(integrationId),
      },
    },
    skip: !integrationId,
  });

  const TypeIcon = useIntegrationTypeIcon();

  if (error) {
    return <ErrorMessage title="Error" description={error.toString()} />;
  }

  if (
    hideWhenEmpty &&
    data?.integrationSources &&
    !data?.integrationSources.length
  ) {
    return null;
  }

  const isHalo = vendor?.key === IntegrationVendorKey.Halo;

  return (
    <div className="md:mt-4">
      {source && (
        <IntegrationsLinkCamerasDialog
          open={opened}
          onClose={cancel}
          source={source}
          promptSuccess={mode === "create"}
        />
      )}
      <DataGrid
        getRowId={({ id }) => id}
        rowHeight={fitsDesktop ? 58 : 130}
        rows={data?.integrationSources ?? []}
        loading={loading}
        mobileHeaderClassName="!hidden"
        columnVisibilityModel={{
          linked: fitsDesktop,
          externalId: fitsDesktop,
          siteName: fitsDesktop,
          cameras: fitsDesktop,
          tags: fitsDesktop,
        }}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
          sorting: { sortModel: [{ field: "name", sort: "desc" }] },
        }}
        columns={
          [
            {
              field: "name",
              headerName: isHalo ? "Sensor Name" : "Device Name",
              minWidth: 150,
              flex: 1,
              valueGetter: ({ row }) => {
                return row.standardMeta.name;
              },
              renderCell: ({ row, value }) => {
                const { id, cameras, tags, standardMeta } = row;
                const linked = cameras.length > 0;

                const nameIndicator = (
                  <span className="flex items-center justify-start">
                    <TypeIcon />
                    {value}
                  </span>
                );

                if (!fitsDesktop) {
                  return (
                    <div className="flex-col gap-1 items-start justify-center">
                      <div className="flex gap-2 items-center">
                        <CheckCircleIcon
                          className={clsx({
                            "text-primary": linked,
                            "text-[#E0E0E0]": !linked,
                          })}
                          fontSize="small"
                        />
                        {nameIndicator}
                      </div>
                      <div className="flex gap-6 items-center">
                        <Typography>{standardMeta.name}</Typography>
                        <Typography>{standardMeta.siteName}</Typography>
                      </div>
                      <div>
                        <Button
                          color="primary"
                          className="-ml-2 font-bold py-0"
                          onClick={() => {
                            setFinished(false);
                            setSource(row);
                            open();
                          }}
                        >
                          {cameras.length > 0
                            ? `${cameras.length} Linked Cameras`
                            : "Link Cameras"}
                        </Button>
                      </div>
                      <IntegrationsDeviceTagsButton
                        className="h-8"
                        integrationId={id}
                        currentTags={tags}
                        siteName={standardMeta.siteName}
                      />
                    </div>
                  );
                }

                return nameIndicator;
              },
            },
            {
              field: "linked",
              headerName: "Linked",
              sortable: false,
              width: 75,
              headerAlign: "center",
              cellClassName: "flex items-center justify-center",
              valueGetter: ({ row }) => {
                return row.cameras.length;
              },
              renderCell: ({ row }) => {
                const { cameras } = row;
                const linked = cameras.length > 0;
                return (
                  <CheckCircleIcon
                    className={clsx({
                      "text-primary": linked,
                      "text-[#E0E0E0]": !linked,
                    })}
                    fontSize="small"
                  />
                );
              },
            },
            {
              field: "externalId",
              headerName: isHalo ? "MAC Address" : "Device ID",
              flex: 1,
            },
            {
              field: "siteName",
              headerName: isHalo ? "Sensor Location" : "Device Location",
              flex: 1,
              valueGetter: ({ row }) => {
                return (
                  row.standardMeta.siteName ?? row.cameras[0]?.location.name
                );
              },
            },
            {
              field: "cameras",
              headerName: "Linked Cameras",
              sortable: false,
              minWidth: 150,
              renderCell: ({ row }) => {
                const { cameras } = row;
                return (
                  <Button
                    color="primary"
                    className="font-bold"
                    onClick={() => {
                      setSource(row);
                      open();
                    }}
                  >
                    {cameras.length > 0
                      ? `${cameras.length} Linked Cameras`
                      : "Link Cameras"}
                  </Button>
                );
              },
            },
            {
              field: "tags",
              headerName: "Tags",
              sortable: false,
              flex: 1,
              renderCell: ({ row }) => {
                const { id, tags, standardMeta } = row;
                return (
                  <IntegrationsDeviceTagsButton
                    className="h-8"
                    integrationId={id}
                    currentTags={tags}
                    siteName={standardMeta.siteName}
                  />
                );
              },
            },
          ] as GridColDef<IntegrationSource>[]
        }
      />
    </div>
  );
}
