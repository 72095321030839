import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ComputerIcon from "@mui/icons-material/Computer";
import ListIcon from "@mui/icons-material/List";
import {
  Backdrop,
  Drawer,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import clsx from "clsx";
import React, { useCallback, useState } from "react";
import { makeStyles, withStyles } from "tss-react/mui";

import { useLocalStorage } from "@/util/useLocalStorage";

import { MappingTool } from "@/components/MappingTool/Mapping";
import { MapIcon } from "@/components/Player/PlayerIcons";
import {
  CameraNavigation,
  DesktopGroupSelect,
} from "@/components/View/ViewNavigation";
import {
  NavigationTab,
  useActiveGroupAndCams,
  useNavigationTabs,
} from "@/components/View/sharedViewHooks";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  useCamDrawerBaseQuery,
  useDebugCamerasQuery,
  useGroupsQuery,
} from "@/generated-models";

import AiDebugTools from "./Ai/AiDebugTools";
import ThroughputPane from "./Ai/ThroughputPane";
import { Loading } from "./Loading";

const drawerHandleWidth = 57;

const useStyles = makeStyles<{ width: number }>()((theme, { width }) => ({
  drawer: {
    flexShrink: 0,
    flexDirection: "row",
    borderLeft: "initial",
    boxShadow: "0 0 10px 0 rgba(0,0,0,0.4)",
    display: "flex",
    overflow: "hidden",
  },
  drawerPaper: {
    flexDirection: "row",
    borderLeft: "initial",
  },
  drawerFullScreen: {
    width: "100%",
    maxWidth: "100%",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerOpen: {
    width,
    maxWidth: "100%",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: drawerHandleWidth,
  },
  drawerHandle: {
    overflow: "hidden",
    padding: 0,
    border: "none",
    outline: "none",
    flexShrink: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: drawerHandleWidth,
    background: "linear-gradient(180deg, #007CE4 35.85%, #8000E4 100%)",
    color: "#fff",
    cursor: "pointer",
    height: "100%",

    "& svg": {
      fontSize: 44,
    },
  },

  viewButtonLabel: {
    fontSize: "10px",
    color: "#fff",
    fontWeight: "bold",
  },
}));

interface SpotDrawerProps {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  width?: number;
  children: React.ReactNode;
  invisible?: boolean;
  mappingChildren?: React.ReactNode;
  isFullWidth?: boolean;
  enableFullWidth?: () => void;
  disableFullWidth?: () => void;
}

export function UnmanagedSpotDrawer({
  initialOpen = false,
  ...drawerProps
}: { initialOpen?: boolean } & Pick<SpotDrawerProps, "width" | "children">) {
  const [isOpen, setIsOpen] = useState(initialOpen);
  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  return (
    <SpotDrawer isOpen={isOpen} open={open} close={close} {...drawerProps} />
  );
}

type ViewMode = "list" | "map";

function SpotDrawer({
  isOpen,
  open,
  close,
  width = 400,
  children,
  mappingChildren = null,
  isFullWidth = false,
  enableFullWidth,
  disableFullWidth,
}: SpotDrawerProps) {
  const { classes } = useStyles({ width });
  const [viewMode, setViewMode] = useState<ViewMode | null>("list");

  const changeViewMode = (
    event: React.MouseEvent<HTMLElement>,
    newMode: ViewMode
  ) => {
    if (newMode) {
      // this is null when an option is reclicked
      setViewMode(newMode);

      if (newMode === "list" && isFullWidth) {
        disableFullWidth && disableFullWidth();
      }
    }
  };

  return (
    <>
      <Paper
        square
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: isOpen && !isFullWidth,
          [classes.drawerFullScreen]: isFullWidth,
          [classes.drawerClose]: !isOpen,
        })}
      >
        <div className="relative z-1">
          <div className="absolute left-0 top-0 w-full">
            <ToggleButtonGroup
              orientation="vertical"
              value={viewMode}
              exclusive
              onChange={changeViewMode}
              className="flex items-start"
            >
              <DrawerButton value="list" aria-label="list">
                <ListIcon className="text-white" />
                <div className={classes.viewButtonLabel}>Cameras</div>
              </DrawerButton>
              {mappingChildren && (
                <DrawerButton value="map" aria-label="map">
                  <MapIcon className="text-white" />
                  <div className={classes.viewButtonLabel}>Maps</div>
                </DrawerButton>
              )}
            </ToggleButtonGroup>
          </div>
          <Tooltip title={isOpen ? "Close" : "Open"} placement="left">
            <button
              className={classes.drawerHandle}
              onClick={() =>
                isOpen
                  ? isFullWidth
                    ? disableFullWidth && disableFullWidth()
                    : close()
                  : open()
              }
            >
              {isOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </button>
          </Tooltip>
        </div>
        {viewMode === "list" && children}
        {viewMode === "map" && mappingChildren}
      </Paper>
    </>
  );
}

interface ViewDrawerProps {
  activeCamIds: number[];
}

/* Custom drawer for view pages */
export function ViewDrawer({ activeCamIds }: ViewDrawerProps) {
  const drawerWidth = 460;
  const { classes } = useStyles({ width: drawerWidth });
  const [isOpen, setOpen] = useState(true);
  const [isFullWidth, setFullWidth] = useState(false);
  const { data: camerasData } = useCamDrawerBaseQuery(refetchOnMountPolicy);
  const { data: groupsData } = useGroupsQuery();
  const { activeGroup, setActiveGroup, groupCams } = useActiveGroupAndCams(
    groupsData?.groups,
    camerasData?.cameras
  );
  const { navTab, setNavTab } = useNavigationTabs();
  const [showAiDebug] = useLocalStorage("showAiDebug", false);
  const { data: debugData } = useDebugCamerasQuery({
    variables: { ids: activeCamIds },
    ...refetchOnMountPolicy,
    skip: !showAiDebug || activeCamIds.length === 0,
  });

  return (
    <>
      <Paper
        square
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: isOpen && !isFullWidth,
          [classes.drawerFullScreen]: isFullWidth,
          [classes.drawerClose]: !isOpen,
        })}
      >
        <div className="relative z-1">
          <div className="absolute left-0 top-0 w-full">
            <ToggleButtonGroup
              orientation="vertical"
              value={navTab}
              exclusive
              onChange={(_, newTab: NavigationTab | null) => {
                if (newTab !== null) {
                  // this is null when an option is reclicked
                  setNavTab(newTab);
                  if (newTab !== NavigationTab.Maps && isFullWidth) {
                    setFullWidth(false);
                  }
                }
              }}
              className="flex items-start"
            >
              <DrawerButton value={NavigationTab.Cameras} aria-label="cameras">
                <ListIcon className="text-white" />
                <div className={classes.viewButtonLabel}>Cameras</div>
              </DrawerButton>
              <DrawerButton value={NavigationTab.Maps} aria-label="map">
                <MapIcon className="text-white" />
                <div className={classes.viewButtonLabel}>Maps</div>
              </DrawerButton>
              {showAiDebug && (
                <DrawerButton value={NavigationTab.Ai} aria-label="ai">
                  <ComputerIcon className="text-white" />
                  <div className={classes.viewButtonLabel}>AI</div>
                </DrawerButton>
              )}
              {showAiDebug && (
                <DrawerButton
                  value={NavigationTab.Insights}
                  aria-label="insights"
                >
                  <ComputerIcon className="text-white" />
                  <div className={classes.viewButtonLabel}>Insights</div>
                </DrawerButton>
              )}
            </ToggleButtonGroup>
          </div>
          <Tooltip title={isOpen ? "Close" : "Open"} placement="left">
            <button
              className={classes.drawerHandle}
              onClick={() =>
                isOpen
                  ? isFullWidth
                    ? setFullWidth(false)
                    : setOpen(false)
                  : setOpen(true)
              }
            >
              {isOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </button>
          </Tooltip>
        </div>
        {!camerasData || !groupsData ? (
          <Loading grow />
        ) : (
          /*
          min-w-0 to prevent flex overflow
          https://stackoverflow.com/a/66689926/971091
          */
          <div data-cy="drawer" className="min-w-0 grow">
            {navTab === NavigationTab.Cameras && (
              <CameraNavigation
                cameras={camerasData.cameras}
                groupCams={groupCams}
                activeCamIds={activeCamIds}
              >
                <DesktopGroupSelect
                  activeGroupId={activeGroup?.id}
                  setGroupId={setActiveGroup}
                  groups={groupsData.groups}
                />
                <div className="mt-3" />
              </CameraNavigation>
            )}
            {navTab === NavigationTab.Maps && (
              <MappingTool
                activeTag={activeGroup}
                isFullWidth={isFullWidth}
                setFullWidth={setFullWidth}
              >
                <DesktopGroupSelect
                  activeGroupId={activeGroup?.id}
                  setGroupId={setActiveGroup}
                  groups={groupsData.groups}
                />
              </MappingTool>
            )}

            {navTab === NavigationTab.Ai && debugData?.cameras.length && (
              <AiDebugTools activeCameras={debugData.cameras} />
            )}

            {navTab === NavigationTab.Insights && debugData?.cameras.length && (
              <ThroughputPane activeCameras={debugData.cameras} />
            )}
          </div>
        )}
      </Paper>
    </>
  );
}

/**
 * This component basically only exists for the alert settings - where users select cams to apply
 * an alert to. Didn't want to create too big of a refactor.
 */
export function SpotDrawerFixed({
  isOpen,
  open,
  close,
  width = 400,
  children,
  invisible = false,
}: SpotDrawerProps) {
  const { classes } = useStyles({ width });

  return (
    <>
      <Backdrop
        open={isOpen}
        onClick={close}
        style={{ zIndex: 1 }}
        invisible={invisible}
      />
      <Drawer
        variant="permanent"
        anchor="right"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: isOpen,
          [classes.drawerClose]: !isOpen,
        })}
        classes={{
          paper: clsx(classes.drawerPaper, {
            [classes.drawerOpen]: isOpen,
            [classes.drawerClose]: !isOpen,
          }),
        }}
      >
        <Tooltip title={isOpen ? "Close" : "Open"} placement="left">
          <button
            type="button"
            style={{
              height: "100%",
            }}
            className={classes.drawerHandle}
            onClick={() => (isOpen ? close() : open())}
          >
            {isOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </button>
        </Tooltip>
        {children}
      </Drawer>
    </>
  );
}

export const DrawerButton = withStyles(ToggleButton, (theme) => ({
  root: {
    display: "block",
    padding: 0,
    lineHeight: "12px",
    color: "#fff",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    borderRadius: "0px",
    border: "0px",
    width: "100%",
    "&:hover, &:focus": {
      color: "#fff",
      backgroundColor: "#004D8D",
    },
  },
}));
