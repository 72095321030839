import { min } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import {
  differenceInHours,
  differenceInDays,
  endOfMonth,
  endOfWeek,
  startOfMonth,
  startOfWeek,
} from "date-fns/fp";
import { atom, useSetAtom } from "jotai";
import { useEffect } from "react";

import { formatIsoDate } from "@/util/date";

import { useIntelligenceDateRange } from "@/pages/Intelligence/hooks";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  IntelligenceDashboard,
  IntelligenceDashboardContentQuery,
  IntelligenceDashboardType,
  IntelligencePresenceDashboardContentQuery,
  JobStatus,
  useIntelligenceDashboardContentQuery,
  useIntelligencePresenceDashboardContentQuery,
} from "@/generated-models";

import { Range } from "../../../IntelligenceDashboardView";

// We need to dynamically fetch different metrics based on the compound dashboard.
// Ideally we can leverage lazy queries to accomplish this.
// Due to the additional polling aspect of our intelligence queries,
// it's a little more straightforward to just render a component containing the
// query hook.

type ContentTimeseries = {
  date: Date;
  idlePercentage: number;
  idle: number;
  total: number;
  leftCount: number;
  rightCount: number;
};

type PresenceContentTimeseries = {
  date: Date;
  presencePerc: number;
};

function isDailyRange(range: string) {
  return range === Range.Week || range === Range.Month;
}

export const refContentDashboardLastUpdated = atom(0);

export const refDashboardsLoadingAtom = atom<Record<number, boolean>>({});

export const refContentDashboardsAtom = atom<
  Record<
    number,
    {
      data: IntelligenceDashboardContentQuery;
      timeseries: {
        date: Date;
        idlePercentage: number;
        idle: number;
        total: number;
        leftCount: number;
        rightCount: number;
      }[];
    }
  >
>({});

export const refPresenceDashboardsAtom = atom<
  Record<
    number,
    {
      data: IntelligencePresenceDashboardContentQuery;
      timeseries: PresenceContentTimeseries[];
    }
  >
>({});

type FetcherInputOverrideProps = Pick<
  IntelligenceDashboard,
  | "id"
  | "daysOfWeek"
  | "startTime"
  | "endTime"
  | "thresholdSeconds"
  | "entityCount"
> & { timezone: string };

const currentDate = new Date();

export function ComparativeDashboardChartPresenceFetcher({
  id,
  daysOfWeek,
  startTime,
  endTime,
  entityCount,
  comparativeId,
  timezone,
}: FetcherInputOverrideProps & { comparativeId: string }) {
  const setLoading = useSetAtom(refDashboardsLoadingAtom);
  const setLastUpdated = useSetAtom(refContentDashboardLastUpdated);
  const setRefDashboard = useSetAtom(refPresenceDashboardsAtom);
  const { date, range } = useIntelligenceDateRange();

  const startDate =
    range === Range.Month
      ? startOfMonth(date)
      : range === Range.Week
      ? startOfWeek(date)
      : date;
  const endDate =
    range === Range.Month
      ? min([endOfMonth(date), currentDate])
      : range === Range.Week
      ? min([endOfWeek(date), currentDate])
      : date;
  const {
    networkStatus,
    stopPolling,
  } = useIntelligencePresenceDashboardContentQuery({
    ...refetchOnMountPolicy,
    variables: {
      id,
      startDate: formatIsoDate(startDate),
      endDate: formatIsoDate(endDate),
      bucketSizeSeconds: 86400,
      polling: true,
      daysOfWeek,
      startTime,
      endTime,
      entityCount,
      compoundId: comparativeId,
    },
    pollInterval: 1000,
    onCompleted(returnedData) {
      const presence = returnedData?.intelligenceDashboard?.presence;
      if (presence?.job.status && presence?.job.status === JobStatus.Done) {
        const timeseries: PresenceContentTimeseries[] = [];

        const sortedBuckets = [
          ...(presence?.results?.presenceBuckets || []),
        ].sort((a, b) => a.bucketMs - b.bucketMs);

        sortedBuckets.forEach((b, idx) => {
          const nextBucket = sortedBuckets[idx + 1];
          timeseries.push({
            date: utcToZonedTime(new Date(b.bucketMs), timezone),
            presencePerc: Math.round(
              (b.presenceSum / (b.bucketEndMs - b.bucketMs)) * 100
            ),
          });

          // Fill in gaps for uniform timeseries data.
          if (nextBucket) {
            const gap = Math.abs(
              differenceInDays(
                new Date(nextBucket.bucketMs - 86400000),
                new Date(b.bucketMs)
              )
            );

            if (gap > 1) {
              for (let i = 0; i < gap; i++) {
                timeseries.push({
                  date: utcToZonedTime(
                    new Date(b.bucketMs + 86400000 * (i + 1)),
                    timezone
                  ),
                  presencePerc: 0,
                });
              }
            }
          }
        });

        setRefDashboard((curr) => ({
          ...curr,
          [id]: {
            data: returnedData,
            timeseries,
          },
        }));
        setLoading((curr) => ({
          ...curr,
          [id]: false,
        }));
        setLastUpdated(Date.now());
        stopPolling();
      }
    },
    onError() {
      stopPolling();
    },
  });

  useEffect(() => {
    if (networkStatus < 7) {
      setLoading((curr) => ({
        ...curr,
        [id]: true,
      }));
    }
  }, [id, networkStatus, setLoading]);

  useEffect(() => {
    return () => {
      setRefDashboard((curr) => {
        const result = { ...curr };
        delete result[id];
        return result;
      });
      setLoading((curr) => ({
        ...curr,
        [id]: false,
      }));
      setLastUpdated(Date.now());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
}

export function ComparativeDashboardChartContentFetcher({
  id,
  type,
  daysOfWeek,
  startTime,
  endTime,
  thresholdSeconds,
  comparativeId,
  timezone,
}: FetcherInputOverrideProps & {
  comparativeId: string;
  type: IntelligenceDashboardType;
}) {
  const setLoading = useSetAtom(refDashboardsLoadingAtom);
  const setLastUpdated = useSetAtom(refContentDashboardLastUpdated);
  const setRefDashboard = useSetAtom(refContentDashboardsAtom);
  const { range, startDate, endDate } = useIntelligenceDateRange();

  const { networkStatus, stopPolling } = useIntelligenceDashboardContentQuery({
    ...refetchOnMountPolicy,
    variables: {
      id,
      startDate: formatIsoDate(startDate),
      endDate: formatIsoDate(endDate),
      bucketSizeSeconds: range === Range.Day ? 3600 : 86400,
      usePaths: type === IntelligenceDashboardType.Count,
      polling: true,
      daysOfWeek,
      startTime,
      endTime,
      thresholdSeconds,
      compoundId: comparativeId,
    },
    pollInterval: 1000,
    onCompleted(returnedData) {
      const metrics = returnedData?.intelligenceDashboard?.metrics;
      const step = isDailyRange(range) ? 86400000 : 3600000;
      const diffFn = isDailyRange(range) ? differenceInDays : differenceInHours;

      if (metrics?.job.status && metrics?.job.status === JobStatus.Done) {
        const timeseries: ContentTimeseries[] = [];

        const sortedBuckets = [...(metrics?.results?.bucketCounts || [])].sort(
          (a, b) => a.bucketMs - b.bucketMs
        );
        sortedBuckets.forEach((b, idx) => {
          const nextBucket = sortedBuckets[idx + 1];
          timeseries.push({
            date: utcToZonedTime(new Date(b.bucketMs), timezone),
            idlePercentage: Math.ceil(
              (b.idleCount / (b.totalCount || 1)) * 100
            ),
            idle: b.idleCount,
            total: b.totalCount - b.idleCount,
            leftCount: b.leftCount || 0,
            rightCount: b.rightCount || 0,
          });

          // Fill in gaps for uniform timeseries data.
          if (nextBucket) {
            const gap = Math.abs(
              diffFn(new Date(nextBucket.bucketMs - step), new Date(b.bucketMs))
            );

            if (gap > 1) {
              for (let i = 0; i < gap; i++) {
                timeseries.push({
                  date: utcToZonedTime(
                    new Date(b.bucketMs + step * (i + 1)),
                    timezone
                  ),
                  idlePercentage: 0,
                  idle: 0,
                  total: 0,
                  leftCount: 0,
                  rightCount: 0,
                });
              }
            }
          }
        });

        setRefDashboard((curr) => ({
          ...curr,
          [id]: { data: returnedData, timeseries },
        }));
        setLoading((curr) => ({
          ...curr,
          [id]: false,
        }));
        setLastUpdated(Date.now());
        stopPolling();
      }
    },
    onError() {
      stopPolling();
    },
  });

  useEffect(() => {
    if (networkStatus < 7) {
      setLoading((curr) => ({
        ...curr,
        [id]: true,
      }));
    }
  }, [id, networkStatus, setLoading]);

  useEffect(() => {
    return () => {
      setRefDashboard((curr) => {
        const result = { ...curr };
        delete result[id];
        return result;
      });
      setLoading((curr) => ({
        ...curr,
        [id]: false,
      }));
      setLastUpdated(Date.now());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
}

export function ComparativeDashboardChartFetcher({
  comparativeId,
  dashboards,
  type = IntelligenceDashboardType.Idle,
}: {
  comparativeId: string;
  dashboards: FetcherInputOverrideProps[];
  type?: IntelligenceDashboardType;
}) {
  return (
    <>
      {dashboards.map(
        ({
          id,
          daysOfWeek,
          startTime,
          endTime,
          entityCount,
          thresholdSeconds,
          timezone,
        }) => {
          const baseProps = {
            id,
            daysOfWeek,
            startTime,
            endTime,
            entityCount,
            thresholdSeconds,
            comparativeId,
            timezone,
          };
          if (type === IntelligenceDashboardType.Presence) {
            return (
              <ComparativeDashboardChartPresenceFetcher
                key={id}
                {...baseProps}
              />
            );
          }
          return (
            <ComparativeDashboardChartContentFetcher
              key={id}
              type={type}
              {...baseProps}
            />
          );
        }
      )}
    </>
  );
}
