import gql from "graphql-tag";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { isDemoUser, useMe } from "@/components/Auth";
import { CaseSmallIcon } from "@/components/Player/PlayerIcons";
import { ShareTrayItem } from "@/components/Player/ShareTrayItem";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";

import { useAddClipToCaseMutation } from "@/generated-models";
import { usePrefixOrgSlug } from "@/hooks/useOrgRouteBase";

import { AddToCaseDialog, CaseContentType } from "./AddToCaseDialog";

interface AddToCaseButtonProps {
  startTime: string;
  endTime: string;
  cameraId: number;
}

export const AddToCaseButton = React.memo(function AddToCaseButton({
  startTime,
  endTime,
  cameraId,
  labelClassName,
  label,
}: AddToCaseButtonProps & { labelClassName?: string; label?: string }) {
  const me = useMe();
  const prefixOrgSlug = usePrefixOrgSlug();
  const isDemo = !!me && isDemoUser(me); // only relevant for demo orgs
  const anchorEl = useRef(null as null | HTMLButtonElement);
  const { pushSnackbar } = useFeedback();
  const [dialogOpen, setDialogOpen] = useState(false);
  const navigate = useNavigate();
  const [addClipToCase] = useAddClipToCaseMutation({
    onError: () =>
      pushSnackbar(
        "We were unable to save this clip. Please try again later.",
        FeedbackType.Error
      ),
  });

  return (
    <>
      <ShareTrayItem
        ref={anchorEl}
        onClick={(e) => {
          e.stopPropagation();
          setDialogOpen(true);
        }}
        aria-label="add to case"
        disabled={isDemo}
        icon={<CaseSmallIcon />}
        labelClassName={labelClassName}
        label={label ?? "Save to Case"}
      />

      <AddToCaseDialog
        type={CaseContentType.clip}
        open={dialogOpen}
        setOpen={setDialogOpen}
        onSave={async (caseId: number, redirectToCase: boolean) => {
          await addClipToCase({
            variables: { caseId, value: { cameraId, startTime, endTime } },
            onCompleted: (data) => {
              if (redirectToCase && data.addClipToCase) {
                navigate(
                  prefixOrgSlug(
                    `/cases/${caseId}?clip=${data.addClipToCase.id}`
                  )
                );
              } else {
                setDialogOpen(false);
                pushSnackbar(
                  "Successfully saved clip to case",
                  FeedbackType.Success
                );
              }
            },
          });
        }}
      />
    </>
  );
});

gql`
  mutation addClipToCase($caseId: Int!, $value: CaseClipInput!) {
    addClipToCase(caseId: $caseId, value: $value) {
      id
    }
  }
`;
