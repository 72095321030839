import ArrowDropdownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Box,
  Button,
  capitalize,
  FormControl,
  FormControlProps,
  Grid,
  Hidden,
  InputLabel,
  MenuItem,
  MenuList,
  OutlinedInput,
  ownerDocument,
  Popover,
  Select,
  SelectProps,
  TextField,
  TextFieldProps,
  useControlled,
  useForkRef,
  useFormControl,
} from "@mui/material";
// @ts-ignore
import formControlState from "@mui/material/FormControl/formControlState";
// @ts-ignore
import nativeSelectClasses from "@mui/material/NativeSelect/nativeSelectClasses";
import { SelectInputProps } from "@mui/material/Select/SelectInput";
import { StaticDatePicker } from "@mui/x-date-pickers";
import clsx from "clsx";
import { addDays, addMonths, format, isBefore, startOfDay } from "date-fns/fp";
import React from "react";
import { makeStyles } from "tss-react/mui";

const today = startOfDay(new Date());
// const tomorrow = addDays(1, today);
export const presets = [
  { value: addDays(1, today).getTime(), label: "In one day" },
  { value: addDays(7, today).getTime(), label: "In one week" },
  { value: addMonths(1, today).getTime(), label: "In one month" },
  { value: -1, label: "Never expires" },
];

const useStyles = makeStyles()((theme) => ({
  presetList: {
    borderRight: "1px solid rgba(0, 0, 0, .2)",
    padding: 0,
  },
  preset: {
    borderBottom: "1px solid rgba(163, 163, 163, .2)",
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  },
  presetSelected: {
    fontWeight: "bold",
  },
}));

interface RelativeDatePickerProps {
  label: string;
  value: Date | null;
  onChange: (value: Date | null) => void;
  minDate?: Date;
  maxDate?: Date;
  FormControlProps?: FormControlProps;
  disableImmediately?: () => void;
  style?: React.CSSProperties;
}
export function RelativeDatePicker({
  label,
  value,
  onChange,
  minDate,
  maxDate,
  FormControlProps,
  disableImmediately,
  style,
}: RelativeDatePickerProps) {
  const { classes } = useStyles();
  const startOfDayOfValue = value && startOfDay(value);
  return (
    <FormControl fullWidth variant="outlined" {...FormControlProps}>
      <InputLabel id="sharing-expiry-label">{label}</InputLabel>
      <CustomSelect
        classes={nativeSelectClasses}
        value={startOfDayOfValue?.getTime() ?? -1}
        label={label}
        labelId="sharing-expiry-label"
        style={style}
        onChange={(e) => {
          const v = e.target.value as number;
          if (v === -2) return; // disable
          onChange(v === -1 ? null : new Date(v));
        }}
        renderValue={(value) => {
          const preset = presets.find((p) => p.value === value);
          const neverExpiresOptionSelected = value === -1;
          const selectedDate = neverExpiresOptionSelected
            ? null
            : new Date(value as number);
          const formattedDate = selectedDate && format("PP", selectedDate);
          const inPast = selectedDate && isBefore(new Date(), selectedDate);
          return (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <strong>
                {preset
                  ? preset.label
                  : inPast
                  ? "Expired - not active"
                  : formattedDate}
              </strong>
              {!neverExpiresOptionSelected && (preset || inPast) && (
                <div style={{ fontSize: ".8em", opacity: 0.5 }}>
                  {formattedDate}
                </div>
              )}
            </Box>
          );
        }}
        renderPopperContents={(handleChange, value) => (
          <Grid container>
            <Hidden mdDown>
              <MenuList className={classes.presetList}>
                {presets.map(({ value: presetValue, label }, i) => (
                  <MenuItem
                    key={i}
                    onClick={(event) => {
                      handleChange(presetValue, event);
                    }}
                    className={clsx(classes.preset, {
                      [classes.presetSelected]: presetValue === value,
                    })}
                  >
                    {label}
                  </MenuItem>
                ))}
                {disableImmediately &&
                  (value === -1 || !isBefore(new Date(), new Date(value))) && (
                    <MenuItem
                      className={classes.preset}
                      onClick={(event) => {
                        disableImmediately();
                        handleChange(-2, event);
                      }}
                    >
                      Expire Now
                    </MenuItem>
                  )}
              </MenuList>
            </Hidden>

            <Box>
              <StaticDatePicker
                showToolbar={false}
                displayStaticWrapperAs="desktop"
                minDate={
                  minDate &&
                  new Date(
                    Math.min(
                      minDate.getTime(),
                      startOfDayOfValue?.getTime() ?? Number.POSITIVE_INFINITY
                    )
                  )
                }
                maxDate={maxDate}
                inputFormat="eee, LLL do Y"
                disableMaskedInput
                renderInput={(inputProps) => (
                  <TextField
                    {...(inputProps as TextFieldProps)}
                    variant="standard"
                    size="small"
                  />
                )}
                value={value === -1 ? null : new Date(value)}
                onChange={(date) => {
                  if (!date) return;

                  handleChange(date.getTime(), {});
                }}
              />
              {disableImmediately && (
                <Hidden smUp>
                  <Button
                    onClick={disableImmediately}
                    style={{
                      marginBottom: 8,
                      marginLeft: 18,
                    }}
                  >
                    Expire Now
                  </Button>
                </Hidden>
              )}
            </Box>
          </Grid>
        )}
      />
    </FormControl>
  );
}

type CustomSelectProps<T> = Omit<SelectProps, "value" | "defaultValue"> & {
  value: T;
  defaultValue?: T;
  renderPopperContents: RenderPopperContents<T>;
};
function CustomSelect<T>(props: CustomSelectProps<T>) {
  const {
    autoWidth = false,
    children,
    classes,
    displayEmpty = false,
    // IconComponent = ArrowDropDownIcon,
    id,
    input,
    inputProps,
    label,
    labelId,
    labelWidth,
    MenuProps,
    multiple = false,
    native = false,
    onClose,
    onOpen,
    open,
    renderValue,
    renderPopperContents,
    SelectDisplayProps,
    variant: variantProps = "standard",
    ...other
  } = props as any;

  const muiFormControl = useFormControl();
  const fcs = formControlState({
    props,
    muiFormControl,
    states: ["variant"],
  });
  const variant = fcs.variant || variantProps;

  return (
    <OutlinedInput
      label={label}
      inputComponent={CustomSelectInput as any}
      inputProps={{
        children,
        variant,
        type: undefined, // We render a select. We can ignore the type provided by the `Input`.
        multiple,
        ...(native
          ? { id }
          : {
              autoWidth,
              displayEmpty,
              labelId,
              MenuProps,
              onClose,
              onOpen,
              open,
              renderValue,
              renderPopperContents,
              SelectDisplayProps: { id, ...SelectDisplayProps },
            }),
        ...inputProps,
        classes: inputProps
          ? clsx({
              baseClasses: classes,
              newClasses: inputProps.classes,
              Component: Select,
            })
          : classes,
        ...(input ? input.props.inputProps : {}),
      }}
      // onChange={onChange as any}
      {...other}
    />
  );
}

type RenderPopperContents<T> = (
  handleChange: (value: T, event: any) => void,
  value: T
) => React.ReactNode;
const CustomSelectInput = React.forwardRef(function CustomSelectInput<
  T extends React.ReactNode
>(
  props: React.PropsWithChildren<SelectInputProps & React.AriaAttributes> & {
    value: T;
    defaultValue?: T;
    labelId: string;
    renderPopperContents: RenderPopperContents<T>;
  },
  ref: any
) {
  const {
    "aria-describedby": ariaDescribedby,
    "aria-label": ariaLabel,
    autoFocus,
    autoWidth,
    children,
    // @ts-ignore
    classes,
    // @ts-ignore
    className,
    defaultValue,
    disabled,
    // @ts-ignore
    displayEmpty,
    IconComponent,
    inputRef: inputRefProp,
    labelId,
    MenuProps,
    multiple,
    name,
    onBlur,
    onChange,
    onClose,
    onFocus,
    onOpen,
    open: openProp,
    readOnly,
    renderValue,
    renderPopperContents,
    SelectDisplayProps = {},
    tabIndex: tabIndexProp,
    // catching `type` from Input which makes no sense for SelectInput
    // @ts-ignore
    type,
    value: valueProp,
    variant = "standard",
    ...other
  } = props;

  const [value] = useControlled({
    controlled: valueProp,
    default: defaultValue,
    name: "Select",
  });

  const inputRef = React.useRef(null);
  const displayRef = React.useRef<HTMLDivElement>(null);
  const [displayNode, setDisplayNode] = React.useState<HTMLDivElement | null>(
    null
  );
  const [openState, setOpenState] = React.useState(false);
  const handleRef = useForkRef(ref, inputRefProp!);

  const handleDisplayRef = React.useCallback((node: HTMLDivElement | null) => {
    (displayRef.current as any) = node;

    if (node) {
      setDisplayNode(node);
    }
  }, []);

  React.useImperativeHandle(
    handleRef,
    () => ({
      focus: () => {
        displayRef.current!.focus();
      },
      node: inputRef.current!,
      value,
    }),
    [value]
  );

  React.useEffect(() => {
    const label = ownerDocument(displayRef.current!).getElementById(labelId);
    if (label) {
      const handler = () => {
        if (getSelection()?.isCollapsed) {
          displayRef.current!.focus();
        }
      };
      label.addEventListener("click", handler);
      return () => {
        label.removeEventListener("click", handler);
      };
    }
    return undefined;
  }, [labelId]);

  const update = (open: boolean, event: any) => {
    if (open) {
      if (onOpen) {
        onOpen(event);
      }
    } else if (onClose) {
      onClose(event);
    }

    setOpenState(open);
  };

  function handleMouseDown(event: React.MouseEvent) {
    // Ignore everything but left-click
    if (event.button !== 0) {
      return;
    }
    // Hijack the default focus behavior.
    event.preventDefault();
    displayRef.current!.focus();

    update(true, event);
  }

  function handleKeyDown(event: React.KeyboardEvent) {
    const validKeys = [
      " ",
      "ArrowUp",
      "ArrowDown",
      // The native select doesn't respond to enter on MacOS, but it's recommended by
      // https://www.w3.org/TR/wai-aria-practices/examples/listbox/listbox-collapsible.html
      "Enter",
    ];

    if (validKeys.includes(event.key)) {
      event.preventDefault();
      update(true, event);
    }
  }

  const open = displayNode !== null && openState;

  let tabIndex = disabled ? undefined : 0;

  const buttonId = name ? `mui-component-select-${name}` : undefined;

  function handleChange(value: T, event: any) {
    if (onChange) {
      // Redefine target to allow name and value to be read.
      // This allows seamless integration with the most popular form libraries.
      // https://github.com/mui-org/material-ui/issues/13485#issuecomment-676048492
      // Clone the event to not override `target` of the original event.
      const nativeEvent = event.nativeEvent || event;
      // @ts-ignore
      const clonedEvent = new nativeEvent.constructor(
        nativeEvent.type,
        nativeEvent
      );

      Object.defineProperty(clonedEvent, "target", {
        writable: true,
        value: { value, name },
      });
      onChange(clonedEvent, event.target);
      setOpenState(false);
    }
  }

  return (
    <>
      <div
        ref={handleDisplayRef}
        tabIndex={tabIndex}
        role="button"
        aria-disabled={disabled ? "true" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="listbox"
        aria-labelledby={buttonId}
        onMouseDown={disabled ? undefined : handleMouseDown}
        onKeyDown={disabled ? undefined : handleKeyDown}
        onBlur={(event) => {
          // if open event.stopImmediatePropagation
          if (!open && onBlur) {
            event.persist();
            onBlur(event);
          }
        }}
        onFocus={onFocus}
        // The id is required for proper a11y
        id={buttonId}
        className={clsx(
          classes.root,
          classes.select,
          classes.selectMenu,
          classes[variant],
          {
            [classes.disabled]: disabled,
          },
          className,
          SelectDisplayProps.className
        )}
      >
        {renderValue ? renderValue(valueProp) : valueProp}
      </div>
      <input
        value={String(valueProp)}
        readOnly
        name={name}
        ref={inputRef}
        aria-hidden
        tabIndex={-1}
        disabled={disabled}
        className={clsx(classes.nativeInput, "hidden")}
        {...other}
      />
      <ArrowDropdownIcon
        className={clsx(classes.icon, classes[`icon${capitalize(variant)}`], {
          [classes.iconOpen]: open,
          [classes.disabled]: disabled,
        })}
      />
      <Popover
        open={open}
        anchorEl={displayNode}
        onClose={(event) => update(false, event)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {renderPopperContents(handleChange, value)}
      </Popover>
    </>
  );
});
