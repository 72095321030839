import gql from "graphql-tag";
import { maxBy, chunk } from "lodash/fp";

import { usePrecenseQuery } from "@/generated-models";

import { Point } from "../Zones/getSectorsForPolygon";

export function usePrecenseAnalytics(
  camId: number,
  startTime: Date,
  endTime: Date,
  objectTypes?: string[],
  searchArea?: Point[],
  minimumObjCount: number = 1
) {
  const { data: precenseData, loading, error } = usePrecenseQuery({
    variables: {
      camId: camId,
      start: startTime.toISOString(),
      end: endTime.toISOString(),
      objectTypes: objectTypes!,
      searchArea: searchArea,
      precenseObjectMinimum: minimumObjCount,
      precensePaddingDuration: 5,
    },
    skip: !objectTypes?.length,
    fetchPolicy: "cache-first", // Used for first execution
    nextFetchPolicy: "cache-only", // Used for subsequent executions
  });

  const precenseDates = precenseData?.camera.previews.map(({ start, end }) => {
    const mapStart = new Date(start);
    const mapEnd = new Date(end);
    return {
      start: mapStart < startTime ? startTime : mapStart,
      end: mapEnd > endTime ? endTime : mapEnd,
    };
  });
  const presentTime = precenseDates
    ? precenseDates.reduce(
        (ms, { start, end }) => ms + (end.getTime() - start.getTime()),
        0
      )
    : undefined;
  const maxPrecence = maxBy(
    ({ start, end }) => end.getTime() - start.getTime(),
    precenseDates
  );
  const maxAbsenceArr = precenseDates
    ? maxBy(
        (segment: Date[]) => segment[1].getTime() - segment[0].getTime(),
        chunk(2, [
          startTime,
          ...precenseDates.flatMap(({ start, end }) => [start, end]),
          endTime,
        ])
      )
    : undefined;
  const maxAbsence =
    maxAbsenceArr?.length === 2
      ? { start: maxAbsenceArr[0], end: maxAbsenceArr[1] }
      : undefined;

  return {
    data: {
      presentTime,
      maxPrecence,
      maxAbsence,
      precenseIntervals: precenseDates,
    },
    loading,
    error,
  };
}

gql`
  query precense(
    $camId: Int!
    $start: String!
    $end: String!
    $searchArea: [SearchPoint!]
    $objectTypes: [String!]!
    $precensePaddingDuration: Int!
    $precenseObjectMinimum: Int!
  ) {
    camera(id: $camId) {
      previews(
        startTime: $start
        endTime: $end
        duration: $precensePaddingDuration
        search: {
          searchSubjects: $objectTypes
          searchArea: $searchArea
          objectCount: $precenseObjectMinimum
        }
      ) {
        start
        end
      }
    }
  }
`;
