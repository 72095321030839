import { InfoOutlined, ReportProblemOutlined } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton, Typography } from "@mui/material";
import clsx from "clsx";
import gql from "graphql-tag";
import { uniq } from "lodash/fp";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";

import { useLocalStorage } from "@/util/useLocalStorage";

import { useSetPlayerSetting } from "@/components/Player/playerControlsMachine";
import {
  PlayerMachineEvent,
  usePlayerService,
  useStillOverlayShown,
} from "@/components/Player/playerMachine";
import { ZendeskArticle, ZendeskLink } from "@/components/Zendesk/ZendeskLink";

import {
  useLatestCameraStillQuery,
  useLatestKioskCameraStillQuery,
} from "@/generated-models";
import { usePermissions } from "@/hooks/usePermissions";

const useStyles = makeStyles()((theme) => ({
  connectivityPopupHeading: {
    color: "white",
    fontSize: 14,
  },
  connectivityPopupText: {
    color: "white",
    opacity: "0.5",
    fontSize: 14,
  },
  stillImageHolder: {
    width: "100%",
    objectFit: "contain",
  },
  stillImageHolderDefault: {
    height: "auto",
  },
}));

export function LatestCameraStillOverlay({
  cameraId,
  still,
  kiosk,
  blur,
  stillOverlayShownStates,
}: {
  cameraId: number;
  /** Override still loading */
  still?: string;
  kiosk?: boolean;
  blur?: boolean;
  stillOverlayShownStates?: string[];
}) {
  const { send } = usePlayerService();
  const stillOverlayShown = useStillOverlayShown(stillOverlayShownStates);

  const onFetchStill = useCallback(
    (cameraStillLink: string) => {
      // Hacky way of getting the timestamp of the latest still; parsing
      // it from the filename. A better way would be to expose the
      // timestamp on the GQL schema, though that timestamp is actually
      // parsed from the filename as well. So maybe this hack isn't as
      // bad after all.
      const filename = cameraStillLink.split("/").pop()!;
      const matches = /(?<timestamp>[^.]+)\./.exec(filename)?.groups;
      const stillTimestamp =
        matches?.timestamp &&
        new Date(
          matches.timestamp.replace(/T(\d{2})-(\d{2})-(\d{2})/, "T$1:$2:$3")
        );
      if (!stillTimestamp || isNaN(stillTimestamp.getTime())) return;

      send({
        type: PlayerMachineEvent.STILL_RECEIVED,
        stillTimestamp,
      });
    },
    [send]
  );
  useEffect(() => {
    if (!still) return;
    onFetchStill(still);
  }, [onFetchStill, still]);
  let { data: stillData } = useLatestCameraStillQuery({
    variables: { id: cameraId },
    fetchPolicy: "cache-and-network",
    skip: !stillOverlayShown || kiosk || Boolean(still),
    onCompleted: (data) => {
      // Data should not be undefined according to types, but I've seen it happen in production.
      // Possibly a race condition when multiple queries are fetching this still at the same time.
      if (data) onFetchStill(data.camera.still);
    },
  });

  const { data: kioskStillData } = useLatestKioskCameraStillQuery({
    variables: { ids: [cameraId] },
    fetchPolicy: "cache-and-network",
    skip: !stillOverlayShown || !kiosk || Boolean(still),
    onCompleted: (data) => {
      if (data && data.kioskCameras.length > 0) {
        const camera = data.kioskCameras[0];
        onFetchStill(camera.still);
      }
    },
  });

  let stillSrc = still ?? stillData?.camera.still;
  if (kioskStillData && kioskStillData.kioskCameras.length > 0) {
    stillSrc = kioskStillData.kioskCameras[0].still;
  }
  if (!stillSrc) return null;

  return (
    <StillOverlay
      src={stillSrc}
      blur={blur}
      stillOverlayShownStates={stillOverlayShownStates}
    />
  );
}

const defaultImageURL = "/logo.png";
export function StillOverlay({
  src,
  blur = true,
  stillOverlayShownStates,
}: {
  src: string;
  blur?: boolean;
  stillOverlayShownStates?: string[];
}) {
  const stillOverlayShown = useStillOverlayShown(stillOverlayShownStates);

  if (!stillOverlayShown) return null;
  return (
    <div className="absolute w-full h-full">
      <div className="h-full w-full flex-center overflow-hidden bg-black">
        <ImageWithFallback
          src={src}
          fallback={defaultImageURL}
          classes={{
            root: clsx("w-full object-contain", { "filter blur-[9px]": blur }),
            fallback: "height-auto",
          }}
        />
      </div>
    </div>
  );
}

export function ImageWithFallback({
  src,
  alt,
  fallback,
  classes,
}: {
  src?: string | null;
  alt?: string;
  fallback: string;
  classes: { root: string; fallback: string };
}) {
  const [showingFallback, setShowingFallback] = useState(false);
  return (
    <img
      className={clsx(classes.root, { [classes.fallback]: showingFallback })}
      alt={alt ?? ""}
      src={showingFallback || !src ? fallback : src}
      onError={(e: any) => {
        setShowingFallback(true);
      }}
      onLoad={(e: any) => {
        setShowingFallback(false);
      }}
    />
  );
}

export function ConnectivityDisplayBase({
  title,
  actionNode,
  icon,
  close,
  mode = "absolute",
}: {
  title: ReactNode;
  actionNode?: ReactNode;
  icon?: ReactNode;
  close?: () => void;
  mode?: "inline" | "absolute";
}) {
  const { classes: baseClasses } = useStyles();
  // const { send } = usePlayerService();

  return (
    <div
      className={clsx("p-0", {
        "absolute max-w-[95%] w-max left-1/2 -translate-x-1/2 top-6  m-auto rounded-md shadow-lg":
          mode === "absolute",
      })}
    >
      <div
        className={clsx("bg-[#383838] bg-opacity-90", {
          rounded: mode === "absolute",
        })}
      >
        <div
          className={clsx(
            "flex gap-2 items-center bg-black bg-opacity-20 p-2",
            { "rounded-t": mode === "absolute" }
          )}
        >
          {icon}
          <Typography className={clsx(baseClasses.connectivityPopupHeading)}>
            {title}
          </Typography>
          <ZendeskLink
            color="error"
            article={ZendeskArticle.VIDEO_TROUBLESHOOT}
          />
          <div className="grow" />
          <Typography className={clsx(baseClasses.connectivityPopupHeading)}>
            {actionNode}
          </Typography>
          {close && (
            <>
              <IconButton
                className="text-white p-0"
                onClick={close}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

/**
 *
 * @param props.cameraId required to persist supressions per camera
 */
export function ConnectivityDisplay({
  state,
  overrideRole,
  mode,
  cameraId,
}: {
  state: ConnectivityState;
  cameraId: number;
  overrideRole?: "user" | "admin";
  mode?: "inline" | "absolute";
}) {
  const hasPermission = usePermissions();
  const [
    connectivityDisplaySupressions,
    setConnectivityDisplaySupressions,
  ] = useLocalStorage(
    "connectivityDisplaySupressions",
    {} as Record<number, ConnectivityState[]>
  );
  const close = useCallback(() => {
    setConnectivityDisplaySupressions((current) => ({
      ...current,
      [cameraId]: uniq([...(current[cameraId] ?? []), state]),
    }));
  }, [setConnectivityDisplaySupressions, cameraId, state]);
  const closed = Boolean(
    connectivityDisplaySupressions[cameraId]?.includes(state)
  );

  const connectivityState = connectivityStates[state];

  let role: "admin" | "user" = "user";
  if (hasPermission("devices_manage")) role = "admin";
  if (overrideRole) role = overrideRole;
  const action = connectivityState.actions[role];
  const connectivityAction = useConnectivityAction(
    action &&
      (!(typeof action === "string") && "action" in action
        ? action.action
        : null)
  );

  if (closed) return null;

  const buttonBaseProps = {
    color: "primary",
    variant: "text",
    size: "small",
    className: "whitespace-nowrap",
  } as const;
  let actionNode: ReactNode = null;
  if (!action || typeof action === "string") {
    actionNode = null;
  } else if (action && connectivityAction) {
    actionNode = (
      <Button {...buttonBaseProps} onClick={connectivityAction}>
        {action.label}
      </Button>
    );
  }

  const description: string =
    typeof connectivityState.description === "string"
      ? connectivityState.description
      : connectivityState.description[role];

  return (
    <ConnectivityDisplayBase
      title={description}
      icon={connectivityState.icon}
      actionNode={actionNode}
      close={close}
      mode={mode}
    />
  );
}

/**
 * Fake retry message
 * @param props.interval in seconds
 */
/*function RetryMessage({ interval = 5 }: { interval?: number }) {
  const [countdown, setCountdown] = useState(interval);
  useEffect(() => {
    function tick() {
      setCountdown((current) => {
        window.clearTimeout(timeout);
        if (current === 1) {
          // simulate retrying
          timeout = window.setTimeout(
            tick,
            1000 + Math.floor(Math.random() * 1500)
          );
          return 0;
        }

        // count down normally
        timeout = window.setTimeout(
          tick,
          1000 + Math.floor(Math.random() * 1500)
        );
        return current === 0 ? interval : current - 1;
      });
    }
    let timeout = window.setTimeout(tick, 1000);
    return () => window.clearTimeout(timeout);
  }, [setCountdown, interval]);

  return (
    <>
      <CircularProgress color="inherit" size={12} className="mr-2 opacity-30" />
      <Typography className="text-xs text-white text-opacity-30">
        {countdown === 0
          ? "Retrying now..."
          : `Trying again in ${countdown} seconds`}
      </Typography>
    </>
  );
}*/

function useConnectivityAction(
  action: keyof typeof connectivityActions | null
) {
  const setPlayerSetting = useSetPlayerSetting();
  const connectivityActions = {
    "switch-to-auto": () => {
      // eslint-disable-next-line
      setPlayerSetting("quality", "auto");
    },
  } as const;
  return action && connectivityActions[action];
}

export type ConnectivityState = keyof typeof connectivityStates;
// https://spotaico.atlassian.net/browse/PROD-147
const connectivityStates = {
  cameraUnreachable: {
    title: "Camera Unreachable",
    description: {
      admin:
        "Camera is unreachable, please check connectivity or reboot device.",
      user:
        "Camera is unreachable, please reach out to your admin or IT to investigate",
    },
    icon: <InfoOutlined color="error" />,
    retryMessage: true,
    actions: {
      admin: null,
      user: null,
    },
  },
  applianceUnreachable: {
    title: "Appliance Unreachable",
    description: {
      admin: "Appliance unreachable, please check connectivity.",
      user:
        "Appliance unreachable, please reach out to your admin or IT to investigate.",
    },
    icon: <InfoOutlined color="error" />,
    retryMessage: true,
    actions: {
      admin: null,
      user: null,
    },
  },
  playbackError: {
    title: "Error Streaming",
    icon: <InfoOutlined color="error" />,
    description: "We are experiencing an error trying to stream.",
    retryMessage: true,
    actions: {
      admin: null,
      user: "Please reach out to your admin or IT to investigate.",
    },
  },
  bufferingVideoOnAuto: {
    title: "Video Buffering",
    icon: <ReportProblemOutlined className="text-warning" />,
    description: {
      admin: "Video is taking longer than expected to load.",
      user:
        "Video is taking longer than expected to load, please contact your admin or IT if this issue persists.",
    },
    retryMessage: false,
    actions: {
      admin: null,
      user: null,
    },
  },
  bufferingStreamOnAuto: {
    title: "Stream Buffering",
    icon: <ReportProblemOutlined className="text-warning" />,
    description: {
      admin: "Stream is taking longer than expected to load.",
      user:
        "Stream is taking longer than expected to load, please contact your admin or IT if this issue persists.",
    },
    retryMessage: false,
    actions: {
      admin: null,
      user: null,
    },
  },
  bufferingVideoOnHighRes: {
    title: "Video Buffering",
    icon: <ReportProblemOutlined className="text-warning" />,
    description:
      "Video is taking longer than expected to load, please lower the quality or change to auto quality.",
    retryMessage: false,
    actions: {
      admin: {
        action: "switch-to-auto",
        label: "Switch to Auto",
      },
      user: {
        action: "switch-to-auto",
        label: "Switch to Auto",
      },
    },
  },
  bufferingStreamOnHighRes: {
    title: "Stream Buffering",
    icon: <ReportProblemOutlined className="text-warning" />,
    description:
      "Video is taking longer than expected to load, please lower the quality or change to auto quality.",
    retryMessage: false,
    actions: {
      admin: {
        action: "switch-to-auto",
        label: "Switch to Auto",
      },
      user: {
        action: "switch-to-auto",
        label: "Switch to Auto",
      },
    },
  },
} as const;

gql`
  query latestKioskCameraStill($ids: [Int!]!) {
    kioskCameras(camIds: $ids) {
      still
    }
  }
`;
