import { MenuItem } from "@mui/material";
import { Select, SelectProps } from "formik-mui";

// Useful list, but not a good source of truth: https://gist.github.com/nodesocket/3919205
// run 'new Intl.Locale("en-US").timeZones' for the full US list

export const timezoneLabelMap: Record<string, string> = {
  "Pacific/Honolulu": "Hawaii Time",
  "America/Juneau": "Alaska Time",
  "America/Los_Angeles": "Pacific Time (PT)",
  "America/Phoenix": "Arizona Time",
  "America/Denver": "Mountain Time (MT)",
  "America/Chicago": "Central Time (CT)",
  "America/New_York": "Eastern Time (ET)",
  "America/Halifax": "Atlantic Time",
  "America/Sao_Paulo": "Brazil, Buenos Aires, Georgetown",
  "Europe/London": "London, Lisbon, Casablanca",
  "Europe/Amsterdam": "Amsterdam, Berlin, Madrid, Paris",
  "Europe/Bucharest": "Bucharest, Kaliningrad, South Africa",
  "Asia/Kolkata": "New Delhi (IST)",
};

export function TimezonePicker({ ...props }: SelectProps) {
  return (
    <Select {...props}>
      {Object.entries(timezoneLabelMap).map(([timezoneIdentifier, label]) => (
        <MenuItem key={timezoneIdentifier} value={timezoneIdentifier}>
          {label}
        </MenuItem>
      ))}
    </Select>
  );
}
