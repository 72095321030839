import { Backdrop, Fade, Modal, Paper } from "@mui/material";

import { AlertType } from "@/generated-models";

import { AlertCreate } from "./AlertCreate";
import { AlertEdit } from "./AlertEdit";

export enum AlertOperation {
  create = "create",
  edit = "edit",
}

interface AlertModalProps {
  open: boolean;
  closeModal: () => void;
  onCompleted?: (alertId: number) => void;
  operation: AlertOperation;
  defaultAlertType?: AlertType;
  alertId?: number | null;
}

export function AlertModal({
  open,
  closeModal,
  onCompleted,
  operation,
  defaultAlertType,
  alertId,
}: AlertModalProps) {
  return (
    <Modal
      open={open}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 350 }}
      className="flex-center"
    >
      <Fade in={open}>
        <Paper className="max-w-3xl h-full max-h-[90vh] overflow-y-scroll sm:h-auto">
          {operation === AlertOperation.create && (
            <AlertCreate
              defaultAlertType={defaultAlertType}
              allowCameraSelection
              onCancel={closeModal}
              onCompleted={(alertId) => {
                closeModal();

                if (onCompleted) {
                  onCompleted(alertId);
                }
              }}
              contentWrapperClassName="!p-0"
            />
          )}
          {operation === AlertOperation.edit && (
            <AlertEdit
              alertId={alertId}
              onClose={closeModal}
              contentWrapperClassName="!pt-4 !py-0"
            />
          )}
        </Paper>
      </Fade>
    </Modal>
  );
}
