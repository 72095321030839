import clsx from "clsx";
import { useRef } from "react";

import { useBreakpoints } from "@/util/useBreakpoints";

import { ErrorMessage } from "@/components/ErrorMessage";

import { IntLoadingIndicator } from "../../IntLoadingIndicator";
import { LicensePlateSearchResultGrid } from "../LicensePlateSearchResultGrid";
import { useGroupedLprSearch } from "../hooks";
import { LicensePlateDetailsPlayer } from "./LicensePlateDetailsPlayer";

export function LicensePlateDetails() {
  const playerRef = useRef<HTMLDivElement>(null);
  const { fitsDesktop } = useBreakpoints();
  const { loading, error } = useGroupedLprSearch();

  if (error) {
    return (
      <ErrorMessage
        title="Oops!"
        description="Failed to load license plate results. Please try again."
      />
    );
  }

  if (loading) {
    return (
      <IntLoadingIndicator className="flex justify-center items-center py-20 w-full" />
    );
  }

  return (
    <div className="flex flex-col md:flex-row items-start gap-6 mx-0 md:mx-16 lg:mx-32">
      <div
        ref={playerRef}
        className={clsx("grow scroll-mt-4", {
          hidden: !fitsDesktop,
        })}
      >
        <LicensePlateDetailsPlayer />
      </div>
      <div
        className={clsx("overflow-y-scroll md:min-w-[240px]", {
          "w-full": !fitsDesktop,
        })}
      >
        <div className="h-screen w-full relative px-4 md:px-0 md:!pr-5">
          <LicensePlateSearchResultGrid />
        </div>
      </div>
    </div>
  );
}
