import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Paper,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import gql from "graphql-tag";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-use";
import { NumberParam, useQueryParam } from "use-query-params";

import { useBreakpoints } from "@/util/useBreakpoints";

import {
  AlertCameras,
  AlertCommunication,
  AlertTimeRange,
} from "@/pages/Alerts/AlertFormBlocks";
import { AlertNameType } from "@/pages/Alerts/AlertNameType";
import { aiAlertTypes } from "@/pages/Alerts/constants";
import { useAlertRoutes } from "@/pages/Alerts/utils";

import { ErrorMessage } from "@/components/ErrorMessage";
import { InterestListSelectFormItem } from "@/components/InterestList/InterestListSelectFormItem";
import { Loading } from "@/components/Loading";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { QueryParamLink } from "@/components/shared/QueryParamLink";

import {
  AlertCameraInput,
  AlertDetectionCountOperator,
  AlertType,
  GetAlertsDocument,
  GetAlertsQuery,
  useAlertQuery,
  useDeleteAlertMutation,
  useUpdateAlertMutation,
} from "@/generated-models";
import { BackButton } from "@/layout/BackButton";
import { ContentWrapper } from "@/layout/ContentWrapper";

import { integrationAlertTypes } from "./AlertCreate";
import { AlertIntegrationDevices } from "./AlertIntegrationDevices";
import { AlertIntegrationEvent } from "./AlertIntegrationEvent";

const headerStyles = "px-5 py-[11px] bg-[#f1f1f1]";

const blockStyles = `py-[24px] px-[22px] sm:py-[36px] sm:px-[44px]`;

interface AlertEditProps {
  alertId?: number | null;
  onClose?: () => void;
  contentWrapperClassName?: string;
}

export function AlertEdit({
  alertId,
  onClose,
  contentWrapperClassName,
}: AlertEditProps) {
  const { fitsTablet } = useBreakpoints();
  const feedback = useFeedback();
  const [deleteConfirm, showDeleteConfirm] = useState(false);
  const [orgUserId] = useQueryParam("ouid", NumberParam);
  const navigate = useNavigate();
  const location = useLocation();
  let { id } = useParams<{ id: string }>();
  id = alertId ? alertId.toString() : id;

  const { data, loading, error } = useAlertQuery({
    variables: {
      id: Number(id!),
      orgUserId: orgUserId ?? undefined,
    },
    fetchPolicy: "network-only",
  });
  const alertRoutes = useAlertRoutes();

  const [updateAlert] = useUpdateAlertMutation({
    onCompleted: () => {
      feedback.pushSnackbar("Alert updated successfully", FeedbackType.Success);

      if (onClose) {
        onClose();
      } else {
        navigate(alertRoutes.ALERTS_CONFIGURE + location.search);
      }
    },
    onError: () =>
      feedback.pushSnackbar(
        "Updating alert failed, please try again",
        FeedbackType.Error
      ),
  });
  const [deleteAlert] = useDeleteAlertMutation({
    onCompleted: () => {
      feedback.pushSnackbar("Alert deleted successfully", FeedbackType.Success);

      if (onClose) {
        onClose();
      } else {
        navigate(alertRoutes.ALERTS_CONFIGURE + location.search);
      }
    },
    onError: () =>
      feedback.pushSnackbar(
        "Deleting alert failed, please try again",
        FeedbackType.Error
      ),
  });

  if (error) return <ErrorMessage title="Error" description={error.message} />;
  if (loading || !data?.alert) return <Loading />;
  const { alert } = data;

  const closeDialog = () => showDeleteConfirm(false);

  const integrationSource = alert.integrationSources?.[0];
  const isIntegrationAlert = integrationAlertTypes.includes(alert.type);

  return (
    <ContentWrapper className={contentWrapperClassName}>
      <Container maxWidth="md" className="p-0">
        <Paper
          elevation={fitsTablet ? 4 : 0}
          style={fitsTablet ? {} : { borderRadius: 0 }}
        >
          <Formik
            initialValues={{
              name: alert.name,
              type: alert.type,
              thresholdSeconds: alert.thresholdSeconds,
              confidence: alert.confidence,
              cameras: alert.cameras.map(
                (c) => ({ id: c.id, zoneIds: c.zoneIds } as AlertCameraInput)
              ),
              daysOfWeek: alert.daysOfWeek.map(String),
              startTime: convertTimeToDate(alert.startTime),
              endTime: convertTimeToDate(alert.endTime),
              users: alert.users,
              isDigest: alert.isDigest,
              isImmediate: alert.isImmediate,
              interestLists: alert.interestLists?.map((i) => i.id),
              operator: alert.operator ?? AlertDetectionCountOperator.Any,
              detectionCount: alert.detectionCount,
              customText: alert.customText,
              integrationId:
                integrationSource && String(integrationSource.integrationId),
              integrationEventTypeId:
                integrationSource &&
                String(integrationSource.integrationEventTypeId),
              integrationSourceIds:
                alert.integrationSources?.map((s) => s.id) ?? [],
            }}
            onSubmit={(values) => {
              const {
                integrationId,
                integrationEventTypeId,
                integrationSourceIds,
                ...input
              } = values;
              return updateAlert({
                variables: {
                  id: alert.id,
                  updates: {
                    ...input,
                    daysOfWeek: values.daysOfWeek.map(Number),
                    thresholdSeconds: Number(values.thresholdSeconds),
                    startTime: convertDateToTime(values.startTime),
                    endTime: convertDateToTime(values.endTime),
                    detectionCount:
                      values.operator !== AlertDetectionCountOperator.Any &&
                      values.detectionCount
                        ? Number(values.detectionCount)
                        : null,
                    users: values.users.map(
                      ({
                        orgUserId,
                        receiveNotifications,
                        receiveEmails,
                        receiveTexts,
                        receivePush,
                        receiveDigestEmails,
                      }) => {
                        return {
                          orgUserId,
                          receiveNotifications,
                          receiveEmails,
                          receiveTexts,
                          receivePush,
                          receiveDigestEmails,
                        };
                      }
                    ),
                    customText:
                      values.customText !== alert.customText
                        ? values.customText
                        : undefined,
                    integration:
                      integrationId && integrationEventTypeId
                        ? {
                            integrationId: Number(integrationId),
                            integrationEventTypeId: Number(
                              integrationEventTypeId
                            ),
                            integrationSourceIds: integrationSourceIds,
                          }
                        : null,
                  },
                },
              });
            }}
          >
            {({ isValid, isSubmitting, values, errors }) => (
              <Form>
                <div
                  className={`flex justify-start items-center ${headerStyles}`}
                >
                  <BackButton
                    className="md:hidden"
                    withQueryParams
                    to={alertRoutes.ALERTS_BASE}
                  />
                  <Typography variant="h4" component="h2">
                    Name and Alert Type
                  </Typography>
                </div>
                <div className={blockStyles}>
                  <AlertNameType initialName={alert.name} disableType />
                </div>
                {alert.type === AlertType.VehicleInterestList && (
                  <>
                    <div className={headerStyles}>
                      <Typography variant="h4" component="h2">
                        License Plate Lists Of Interest
                      </Typography>
                    </div>
                    <div className={blockStyles}>
                      <InterestListSelectFormItem hideLabel loading={loading} />
                    </div>
                  </>
                )}
                {isIntegrationAlert ? (
                  <>
                    <div className={headerStyles}>
                      <Typography variant="h4" component="h2">
                        Integration and event type
                      </Typography>
                    </div>
                    <div className={blockStyles}>
                      <AlertIntegrationEvent integrationType={values.type} />
                    </div>
                    <div className={headerStyles}>
                      <Typography variant="h4" component="h2">
                        Devices
                      </Typography>
                    </div>
                    <div className={blockStyles}>
                      <AlertIntegrationDevices />
                    </div>
                  </>
                ) : (
                  <>
                    <div className={headerStyles}>
                      <Typography variant="h4" component="h2">
                        Cameras
                      </Typography>
                    </div>
                    <div className={blockStyles}>
                      <AlertCameras
                        proMode={
                          aiAlertTypes.has(values.type) &&
                          values.operator !== AlertDetectionCountOperator.Any
                        }
                      />
                    </div>
                  </>
                )}
                {alert.type !== AlertType.CameraOffline && (
                  <>
                    <div className={headerStyles}>
                      <Typography variant="h4" component="h2">
                        Time Range
                      </Typography>
                    </div>
                    <div className={blockStyles}>
                      <AlertTimeRange />
                    </div>
                  </>
                )}
                <div className={headerStyles}>
                  <Typography variant="h4" component="h2">
                    Communication Preferences
                  </Typography>
                </div>
                <div className={blockStyles}>
                  <AlertCommunication />
                </div>
                <div
                  className={"py-[12px] px-[44px] text-right text-[#ff5400]"}
                >
                  {Object.values(errors)
                    .filter(function (error: any): error is string {
                      return typeof error === "string";
                    })
                    .map((error) => (
                      <div key={String(error)}>{error}</div>
                    ))}
                </div>
                <div className="flex px-[44px] pb-[32px]">
                  <Button
                    color="primary"
                    startIcon={<DeleteIcon />}
                    disabled={isSubmitting}
                    onClick={() => showDeleteConfirm(true)}
                  >
                    Delete Alert
                  </Button>
                  {onClose ? (
                    <Button
                      disabled={isSubmitting}
                      onClick={onClose}
                      style={{ marginLeft: "auto", marginRight: 8 }}
                    >
                      CANCEL
                    </Button>
                  ) : (
                    <Button
                      disabled={isSubmitting}
                      to=".."
                      component={QueryParamLink}
                      style={{ marginLeft: "auto", marginRight: 8 }}
                    >
                      CANCEL
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting || !isValid}
                  >
                    SAVE
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
          <Dialog open={deleteConfirm} onClose={closeDialog}>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete this Alert? This cannot be
                undone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog} color="primary">
                Cancel
              </Button>
              <Button
                onClick={() => {
                  deleteAlert({
                    variables: { id: alert.id },
                    optimisticResponse: {
                      __typename: "Mutation",
                      deleteAlert: {
                        __typename: "DefaultPayload",
                      },
                    },
                    update: (store, { data }) => {
                      if (!data) throw new Error("Unable to delete Alert");
                      const alertsData = store.readQuery<GetAlertsQuery>({
                        query: GetAlertsDocument,
                      });
                      if (alertsData) {
                        store.writeQuery({
                          query: GetAlertsDocument,
                          data: {
                            alerts: alertsData.alerts.filter(
                              (a) => a.id !== alert.id
                            ),
                          },
                        });
                      }
                    },
                  });
                }}
                variant="contained"
                color="secondary"
                autoFocus
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </Container>
    </ContentWrapper>
  );
}

export function convertTimeToDate(time: string) {
  if (!time) return new Date(2020, 0, 1);
  const [hours, minutes] = time.split(":").map(Number);
  return new Date(2020, 0, 1, hours, minutes);
}

export function convertDateToTime(date: Date) {
  return `${date.getHours()}:${date.getMinutes()}:00Z`;
}

const ALERT_FRAGMENT = gql`
  fragment AlertBase on Alert {
    id
    name
    active
    type
    thresholdSeconds
    confidence
    cameras {
      id
      zoneIds
    }
    integrationSources {
      id
      integrationId
      integrationEventTypeId
    }
    startDate
    endDate
    daysOfWeek
    startTime
    endTime
    isDigest
    isImmediate
    operator
    detectionCount
    interestLists {
      id
      type
      name
      items {
        name
        source
        notes
      }
    }
    users {
      orgUserId
      name
      isOwner
      receiveNotifications
      receiveEmails
      receiveTexts
      receivePush
      receiveDigestEmails
      hasPhoneNumber
      hasMobileApp
    }
    customText
  }
`;

gql`
    query alert($id: Int!, $orgUserId: Int) {
        alert(id: $id, orgUserId: $orgUserId) {
            ...AlertBase
        }
        ${ALERT_FRAGMENT}
    }
`;

gql`
    mutation updateAlert($id: Int!, $updates: UpdateAlertInput!) {
        updateAlert(id: $id, updates: $updates) {
            ...AlertBase
        }
        ${ALERT_FRAGMENT}
    }
`;

gql`
  mutation deleteAlert($id: Int!) {
    deleteAlert(id: $id) {
      __typename
    }
  }
`;
