import { IconButton, Modal, Paper } from "@mui/material";
import clsx from "clsx";
import { ReactNode } from "react";

import { CloseIcon } from "@/components/Player/PlayerIcons";

interface BaseModalProps {
  children?: ReactNode;
  classes?: {
    root?: string;
  };
  header?: React.ReactNode;
  statusBars?: React.ReactNode;
  onClose: () => void;
}

export default function BaseModal({
  classes,
  header,
  statusBars,
  onClose,
  children,
}: BaseModalProps) {
  return (
    <Modal className="flex-center" disablePortal={false} open onClose={onClose}>
      <Paper
        elevation={9}
        className="rounded outline-none min-h-[200px] min-w-[400px]"
      >
        <div className={clsx("h-full", classes?.root ?? "max-w-2xl")}>
          <div className="h-full flex flex-col">
            {header && (
              <div className="flex items-center justify-between w-full p-4 border-b border-gray-300 border-solid bg-[#F0F0F0] rounded-t">
                <div className="flex flex-col gap-1">{header}</div>
                <IconButton
                  onClick={() => onClose()}
                  size="large"
                  className="text-text"
                >
                  <CloseIcon />
                </IconButton>
              </div>
            )}
            {statusBars}
            {children}
          </div>
        </div>
      </Paper>
    </Modal>
  );
}
