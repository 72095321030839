import { Button } from "@mui/material";
import { useFormikContext } from "formik";
import { useAtom, useAtomValue, useSetAtom } from "jotai";

import { InterestListFormMode } from "../constants";
import {
  bulkItemAtom,
  currentListItemAtom,
  defaultCloseAtom,
  listModeAtom,
  selectedListItemsAtom,
} from "../hooks";

const primaryBtnProps = {
  variant: "contained" as "contained",
  color: "primary" as "primary",
  className: "shadow-none text-xs leading-[22px] font-normal rounded-md",
};

interface InterestListActionsProps {
  primaryActionLabel: string;
  onPrimaryAction: () => void;
  onClose: () => void;
}

const CUSTOM_ACTION_MODES = [
  InterestListFormMode.view,
  InterestListFormMode.insert,
];

export function InterestListActions({
  primaryActionLabel,
  onPrimaryAction,
  onClose,
}: InterestListActionsProps) {
  const { isValid, dirty, resetForm, isSubmitting } = useFormikContext();
  const [mode, setMode] = useAtom(listModeAtom);
  const bulkItem = useAtomValue(bulkItemAtom);
  const defaultClose = useAtomValue(defaultCloseAtom);
  const selectedItems = useAtomValue(selectedListItemsAtom);
  const setCurrentListItem = useSetAtom(currentListItemAtom);

  return (
    <div className="flex justify-end items-center gap-2 px-0 pt-4">
      <Button
        variant="contained"
        color="primary"
        className="shadow-none text-xs leading-[22px] font-normal rounded-md bg-[#DAEEFF] text-primary"
        onClick={() => {
          if (defaultClose) {
            if (
              defaultClose &&
              mode === InterestListFormMode.edit &&
              !!bulkItem
            ) {
              setMode(InterestListFormMode.insert);
            } else {
              onClose();
            }
          } else if (mode !== InterestListFormMode.view) {
            setMode(InterestListFormMode.view);
            setCurrentListItem(null);
            resetForm();
          } else {
            onClose();
          }
        }}
        disabled={isSubmitting}
      >
        Cancel
      </Button>
      {mode === InterestListFormMode.view && (
        <Button
          type="button"
          disabled={selectedItems?.length === 0}
          onClick={() => {
            onPrimaryAction();
          }}
          {...primaryBtnProps}
        >
          {primaryActionLabel}
        </Button>
      )}
      {!CUSTOM_ACTION_MODES.includes(mode) && (
        <Button
          type="submit"
          disabled={isSubmitting || !isValid || !dirty}
          {...primaryBtnProps}
        >
          Save List
        </Button>
      )}
      {mode === InterestListFormMode.insert && (
        <Button
          type="submit"
          disabled={isSubmitting || !bulkItem?.name}
          {...primaryBtnProps}
        >
          {primaryActionLabel}
        </Button>
      )}
    </div>
  );
}
