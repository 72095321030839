import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { useCallback } from "react";
import { StringParam, UrlUpdateType, useQueryParam } from "use-query-params";

import { Clip } from "@/pages/Search/searchHooks";

import { useCameraByIdQuery } from "@/generated-models";

import {
  ContextualPlayerBase,
  ContextualPlayerFooter,
  Indicators,
  LocationNameTitle,
  VodContextualPlayerDetails,
} from "../VideoWall/ContextualVideoPlayer";

interface EmbeddedVodPlayerProps {
  cameraId: number;
  subjects?: string[] | null;
}

export function EmbeddedVodPlayer({
  cameraId,
  subjects,
}: EmbeddedVodPlayerProps) {
  const { data } = useCameraByIdQuery({ variables: { cameraId } });
  const { vod, setVod } = useEmbeddedVodParam();

  if (!vod || !data) return null;
  const { camera } = data;

  return (
    <ContextualPlayerBase
      className="rounded-2xl"
      playerProps={{
        camera,
        startTime: vod.start,
        endTime: vod.end,
        subjects,
      }}
      header={
        <>
          <div className="flex py-3 px-[14px] justify-between items-center w-full">
            <LocationNameTitle
              location={camera.location.name}
              name={camera.name}
              className="hidden md:flex"
            />
            <div className="flex flex-row md:flex-col items-end justify-between gap-4 w-full">
              <IconButton
                className="w-3 h-3"
                aria-label="close"
                onClick={() => setVod(null)}
              >
                <CloseIcon className="text-white" />
              </IconButton>
              <Indicators />
            </div>
          </div>
        </>
      }
      footer={
        <>
          <span className="md:hidden">
            <VodContextualPlayerDetails
              name={camera.name}
              location={camera.location.name}
              timezone={camera.location.timezone}
              camId={camera.id}
              subjects={subjects}
              startTime={vod.start}
              endTime={vod.end}
            />
          </span>
          <span className="hidden md:block">
            <ContextualPlayerFooter
              camId={camera.id}
              timezone={camera.location.timezone}
              startTime={vod.start}
              endTime={vod.end}
              subjects={subjects}
            />
          </span>
        </>
      }
    />
  );
}

export function useEmbeddedVodParam() {
  const [param, setParam] = useQueryParam("clip", StringParam);
  const setVod = useCallback(
    (value: Clip | null, updateType?: UrlUpdateType) => {
      if (value == null) {
        setParam(undefined, updateType);
        return;
      }
      setParam(`${value.start}|${value.end}`, updateType);
    },
    [setParam]
  );

  // Parse start and end from clip param
  const [start, end] = param ? param.split("|") : [null, null];
  return { vod: start && end ? { start, end } : null, setVod };
}
