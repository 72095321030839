import { Avatar, Divider } from "@mui/material";
import clsx from "clsx";
import { useMemo } from "react";

import { AIBase } from "@/icons/AIBase";
import { PawIcon } from "@/icons/Paw";

import { useMe } from "../Auth";
import { AssistantEditorReadOnlyInput } from "./AssistantEditorReadOnlyInput";

interface AssistantMessageProps {
  sender?: boolean;
  content: string;
}

export function AssistantMessage({ sender, content }: AssistantMessageProps) {
  const me = useMe();

  const initials = useMemo(() => {
    const matches = me?.name.match(/\b(\w)/g);
    return matches?.join("") || [];
  }, [me?.name]);

  return (
    <div className="flex items-center w-full animate-fade-in-bottom">
      <div className="relative">
        <Avatar
          className={clsx(
            "bg-[#0F1316] border border-solid text-primary font-bold text-sm",
            {
              "border-[#344450]": sender,
              "border-[#698092]": !sender,
            }
          )}
        >
          {sender ? initials : <PawIcon className="w-6 h-6" white />}
        </Avatar>
        {!sender && (
          <AIBase primary className="absolute h-4 left-[-10px] top-[30px]" />
        )}
      </div>
      <Divider
        className={clsx("w-3", {
          "border-[#344450]": sender,
          "border-[#698092]": !sender,
        })}
      />

      <div
        className={clsx(
          "rounded-[23px] border-l-[9px] border-solid px-10 py-6 flex-1",
          {
            "bg-[#2D3942] border-[#344450] text-[#A6C8E1]": sender,
            "bg-[#252E35] border-[#698092] text-white": !sender,
          }
        )}
      >
        <AssistantEditorReadOnlyInput value={content} isSender={sender} />
      </div>
    </div>
  );
}
