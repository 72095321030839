import type { Mark } from "@mui/base/useSlider";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import {
  Slider as MuiSlider,
  SliderProps as MuiSliderProps,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { FieldProps } from "formik";
import { debounce } from "lodash/fp";
import React, { useEffect, useMemo, useState } from "react";

const marks = [
  {
    label: "Lowest",
    tooltip: (
      <>
        Provides the <strong>most alerts</strong> but could include{" "}
        <strong>more false detections</strong>.
      </>
    ),
  },
  {
    label: "Low",
    tooltip: (
      <>
        Provides <strong>more alerts</strong> but could include{" "}
        <strong>some false detections</strong>.
      </>
    ),
  },
  {
    label: (
      <>
        <div>High</div>
        <Typography variant="caption" className="opacity-60">
          (Recommended)
        </Typography>
      </>
    ),
    tooltip: (
      <>
        Best balance between{" "}
        <strong>alert quantity and minimizing false alerts.</strong>
      </>
    ),
  },
  {
    label: "Highest",
    tooltip: (
      <>
        <strong>Avoids false alerts, but might miss alerts</strong> where
        objects are obscured or are farther away.
      </>
    ),
  },
];

export function ConfidenceSlider({ ...props }: MuiSliderProps) {
  const sliderMarks: Mark[] = marks.map((mark, index) => ({
    value: index,
    label: (
      <div
        className={clsx(
          "text-center",
          props.value === index ? "font-bold" : undefined
        )}
      >
        <div style={{ height: "29px" }}>{mark.label}</div>
        <div
          className={clsx(
            props.value === index ? "visibility: visible" : "visibility: hidden"
          )}
        >
          <ArrowDropUpIcon style={{ color: "#E3E3E3" }} fontSize="large" />
        </div>
      </div>
    ),
  }));
  return (
    <div>
      <div className="px-12 mt-4">
        <MuiSlider
          aria-label="Confidence Level"
          valueLabelDisplay="off"
          step={1}
          marks={sliderMarks}
          color="primary"
          min={0}
          max={3}
          classes={{
            rail: "bg-[#e0e0e0]",
            track: "bg-[#81C5FF] border-0",
            thumb:
              "border-4 bg-white border-[#007CE4] shadow-[0_4px_4px_rgba(0,0,0,0.25)]",
            mark: "bg-[#e0e0e0] border-0 opacity-100 w-2 h-2 rounded-[50%]",
            markActive: "bg-[#81C5FF]",
            markLabel: "mt-1",
          }}
          {...props}
        />
      </div>
      <div style={{ marginTop: "27px" }}>
        <hr />
        <div className="px-4 sm:px-24 text-center min-h-[60px] text-[#757575] flex items-center">
          <span>{marks[props.value as number].tooltip}</span>
        </div>
      </div>
    </div>
  );
}

export interface FormikSliderProps
  extends FieldProps,
    Omit<MuiSliderProps, "name" | "onChange" | "value" | "defaultValue"> {}

export const fieldToSlider = ({
  field,
  form: { isSubmitting },
  disabled = false,
  ...props
}: FormikSliderProps): MuiSliderProps => {
  return {
    disabled: isSubmitting || disabled,
    ...props,
    ...field,
  };
};

export function FormikConfidenceSlider(props: FormikSliderProps) {
  const [value, setValue] = useState(props.field.value);
  // Debounce committing the field value to ensure the slider transitions smoothly
  const commitValue = useMemo(() => debounce(400, props.form.setFieldValue), [
    props.form.setFieldValue,
  ]);
  useEffect(() => {
    setValue(props.field.value);
  }, [props.field.value, setValue]);
  return (
    <ConfidenceSlider
      {...fieldToSlider(props)}
      value={value}
      onChange={(e, value) => setValue(value)}
      onChangeCommitted={(e, value) => commitValue(props.field.name, value)}
    />
  );
}
