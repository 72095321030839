import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { Button, IconButton } from "@mui/material";
import { format } from "date-fns";
import { capitalize } from "lodash/fp";
import { ReactNode } from "react";

import { ReactComponent as MinimizeHeatmap } from "@/icons/icon-min.svg";

import { formatIsoDate } from "@/util/date";
import { formatDurationSecs } from "@/util/formatDurationSecs";

import { IntelligenceDashboardType, SearchPoint } from "@/generated-models";

import { IntLoadingIndicator } from "../IntLoadingIndicator";
import { IntelligenceFullScreenView } from "../IntelligenceFullScreenView";
import { HeatmapColorValues } from "../constants";
import { IntelligenceHeatmap } from "./IntelligenceHeatmap";
import { useHeatmap } from "./useHeatmap";

interface HeatmapDashboardInt {
  setExpandHeatmap?: (b: boolean) => void;
  cameraId: number;
  intDashId: number;
  timezone: string;
  startDate: Date;
  endDate: Date;
  date?: Date;
  zone?: SearchPoint[];
  heatmapType: IntelligenceDashboardType;
  entityType: string;
}

function getTooltipText(entityType: string) {
  return {
    report: "Report",
    count: "Counting",
    presence: `${capitalize(entityType)} presence`,
    idle: `${capitalize(entityType)} idle`,
    interestList: "Interest List",
    compound: "Interest List",
  };
}

function getHeatmapLegendText(
  heatmapType: IntelligenceDashboardType,
  entityType: string
) {
  let type = "idle";

  if (heatmapType === IntelligenceDashboardType.Presence) {
    type = "present";
  }

  return {
    legendHeader: `Understand where ${entityType} were most frequently ${type}`,
    legendLabel: `Total Amount of Time ${capitalize(type)}`,
  };
}

export const MobileHeatmapDashboard = ({
  datePicker,
  setExpandHeatmap,
  cameraId,
  intDashId,
  timezone,
  startDate,
  endDate,
  date,
  zone,
  heatmapType,
  entityType,
}: HeatmapDashboardInt & {
  datePicker?: ReactNode;
}) => {
  const { maxHeat, minHeat, loading } = useHeatmap({
    intDashId: intDashId || 0,
    input: {
      startDate: formatIsoDate(startDate),
      endDate: formatIsoDate(endDate),
      gridSize: 50,
    },
  });

  const legendValues = Math.floor((maxHeat - minHeat) / 6 / 5) * 5;
  const legendColors = heatmapType
    ? HeatmapColorValues[heatmapType].legend
    : "from-[#FF30F8] via-[#B47AFB] to-[#4498FF]";

  return (
    <IntelligenceFullScreenView>
      <div className="flex items-center justify-between py-4 px-4">
        <div className="text-lg leading-[20px] font-bold">Activity Map</div>
        {datePicker}
        <Button
          className="border border-white text-white bg-black/50 z-10"
          variant="outlined"
          size="small"
          startIcon={<MinimizeHeatmap />}
          onClick={() => setExpandHeatmap?.(false)}
        >
          Minimize
        </Button>
      </div>
      {loading && <IntLoadingIndicator className="flex h-full" />}
      {!loading && (
        <div className="landscape:hidden portrait:block">
          <HeatmapDashboard
            cameraId={cameraId}
            intDashId={intDashId}
            timezone={timezone}
            startDate={startDate}
            setExpandHeatmap={setExpandHeatmap}
            endDate={endDate}
            date={date}
            zone={zone}
            expanded
            heatmapType={heatmapType}
            entityType={entityType}
          />
        </div>
      )}
      <div
        className={`portrait:hidden block px-4 ${
          loading ? "hidden" : "flex flex-row"
        }`}
      >
        <div className="relative min-h-[227px] md:min-h-[556px] w-full bg-black">
          <div className="relative z-1 h-full flex flex-row items-center">
            <IntelligenceHeatmap
              cameraId={cameraId}
              intDashId={intDashId}
              timezone={timezone}
              startDate={startDate}
              endDate={endDate}
              showTooltip={true}
              zone={zone}
              date={date && format(date, "MMMM dd")}
              colorValues={
                heatmapType ? HeatmapColorValues[heatmapType] : undefined
              }
              tooltipText={
                heatmapType
                  ? getTooltipText(entityType)[heatmapType]
                  : undefined
              }
            />
          </div>
        </div>
        <div className="pl-5 max-w-[194px] flex flex-col">
          <div className="bg-gray-fb border border-[#eeeeee] p-3 rounded mdflex flex-col grow items-center">
            <p className="text-base font-bold text-center leading-5 flex-none mb-3">
              {getHeatmapLegendText(heatmapType, entityType).legendLabel}
            </p>
            <div className="grow flex justify-center">
              <div
                className={`w-[34px] h-full min-h-[225px] bg-gradient-to-b ${legendColors} rounded`}
              ></div>
              <div className="legend mt-0 ml-3 flex flex-col-reverse justify-between">
                <div className="legend-item font-medium text-sm text-gray-75">
                  0
                </div>
                {Array.from({ length: 5 }, (v, i) => i + 1).map(
                  (legendValue) => (
                    <div
                      key={`legend-item-${legendValue}`}
                      className="legend-item font-medium text-sm text-gray-75"
                    >
                      {
                        formatDurationSecs(
                          Math.round((legendValue * legendValues) / 1000),
                          {
                            hideZeroes: true,
                          }
                        ).split(" ")[0]
                      }
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </IntelligenceFullScreenView>
  );
};

export const HeatmapDashboard = ({
  setExpandHeatmap,
  expanded,
  cameraId,
  intDashId,
  timezone,
  startDate,
  endDate,
  date,
  zone,
  heatmapType,
  entityType,
}: HeatmapDashboardInt & { expanded?: boolean }) => {
  const { maxHeat, loading } = useHeatmap({
    intDashId: intDashId || 0,
    input: {
      startDate: formatIsoDate(startDate),
      endDate: formatIsoDate(endDate),
      gridSize: 50,
    },
  });

  const incrementValue = maxHeat / 5;
  const legendColors = heatmapType
    ? HeatmapColorValues[heatmapType].legend
    : "from-[#FF30F8] via-[#B47AFB] to-[#4498FF]";

  return (
    <div
      className={`shadow-paper rounded overflow-hidden ${
        loading ? "hidden" : "flex md:flex-row flex-col"
      }`}
    >
      <div className="relative min-h-[227px] md:min-h-[556px] w-full bg-black">
        <Button
          className="hidden md:flex absolute top-2 right-2 border border-white text-white bg-black/50 z-10"
          variant="outlined"
          startIcon={<MinimizeHeatmap />}
          onClick={() => setExpandHeatmap?.(false)}
        >
          Minimize
        </Button>
        {!expanded && (
          <IconButton
            className="md:hidden flex absolute top-2 right-2 z-50 text-white"
            onClick={() => setExpandHeatmap?.(true)}
          >
            <FullscreenIcon />
          </IconButton>
        )}
        <div className="relative z-1 h-full flex flex-row items-center">
          <IntelligenceHeatmap
            cameraId={cameraId}
            intDashId={intDashId}
            timezone={timezone}
            startDate={startDate}
            endDate={endDate}
            showTooltip={true}
            zone={zone}
            date={date && format(date, "MMMM dd")}
            colorValues={
              heatmapType ? HeatmapColorValues[heatmapType] : undefined
            }
            tooltipText={
              heatmapType ? getTooltipText(entityType)[heatmapType] : undefined
            }
          />
        </div>
      </div>
      <div className="p-6 md:max-w-[194px] md:flex flex-col">
        <header className="mb-4 flex-none md:block hidden">
          <span className="font-bold text-lg">Activity Map</span>
          <p className="text-base leading-5">
            {getHeatmapLegendText(heatmapType, entityType).legendHeader}
          </p>
        </header>
        <div className="bg-gray-fb border border-[#eeeeee] p-3 rounded md:flex flex-col grow items-center">
          <p className="text-base font-bold text-center leading-5 flex-none mb-3">
            {getHeatmapLegendText(heatmapType, entityType).legendLabel}
          </p>
          <div className="grow md:flex">
            <div
              className={`w-full md:w-[34px] h-[20px] md:h-full bg-gradient-to-l md:bg-gradient-to-b ${legendColors} rounded`}
            ></div>
            <div className="legend mt-3 md:mt-0 md:ml-3 flex md:flex-col-reverse justify-between">
              <div className="legend-item font-medium text-sm text-gray-75">
                0
              </div>
              {Array.from({ length: 5 }, (v, i) => i + 1).map((legendValue) => (
                <div
                  key={`legend-item-${legendValue}`}
                  className="legend-item font-medium text-sm text-gray-75"
                >
                  {formatDurationSecs(
                    Math.round(
                      (legendValue * incrementValue) /
                        1000 /
                        calculateRoundingFactor(maxHeat / 1000)
                    ) * calculateRoundingFactor(maxHeat / 1000),
                    {
                      hideZeroes: true,
                    }
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        <p className="text-base leading-5 px-5 pt-3 text-center block md:hidden">
          {getHeatmapLegendText(heatmapType, entityType).legendHeader}
        </p>
      </div>
    </div>
  );
};

// Values in seconds
const stepDurations = [
  // 1, // 1s
  5, // 5s
  10, // 10s
  // 60, // 1min
  300, // 5min
  600, // 10min
  // 3600, // 1hr
  18000, // 5hr
  36000, // 10hr
  // 86400, // 1d
  432000, // 5d
  864000, // 10d
];

function calculateRoundingFactor(max: number) {
  const el = Math.max(stepDurations.findIndex((x) => x > max) - 2, 0);
  return stepDurations[el];
}
