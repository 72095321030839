import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { atom, useAtom } from "jotai";
import { sortBy, uniq } from "lodash/fp";
import React from "react";

import { ReactComponent as GroupIcon } from "@/icons/icon_group.svg";
import { ReactComponent as LocationGroupIcon } from "@/icons/icon_group_location.svg";

import { useGroupsQuery } from "@/generated-models";

interface CamDrawerGroupSelectorProps {
  activeGroupId?: number | undefined;
  setGroupId?: React.Dispatch<number | undefined>;
}

export const groupIdAtom = atom(undefined as number | undefined);

export function CamDrawerGroupSelector({
  activeGroupId,
  setGroupId,
}: CamDrawerGroupSelectorProps) {
  const { data } = useGroupsQuery();
  const groups = data?.groups;

  const [groupIdAtomValue, setGroupIdAtomValue] = useAtom(groupIdAtom);

  if (!groups) {
    return <>loading...</>; // TODO: improve this placeholder
  }

  return (
    <FormControl variant="outlined" size="small" fullWidth>
      <InputLabel htmlFor="group-select">Groups & Locations</InputLabel>
      <Select
        value={activeGroupId ?? groupIdAtomValue ?? "__ALL__"}
        onChange={(e) => {
          const newValue = e.target.value;
          const normalizedValue =
            newValue === "__ALL__" ? undefined : (newValue as number);
          setGroupId?.(normalizedValue);
          setGroupIdAtomValue(normalizedValue);
        }}
        variant="outlined"
        label="Groups & Locations"
        inputProps={{ name: "tag", id: "group-select" }}
        className="font-medium"
      >
        <MenuItem value="__ALL__">All Groups & Locations</MenuItem>
        {sortBy((group) => group.name.toLowerCase(), uniq(groups)).map(
          (group) => (
            <MenuItem value={group.id} key={group.id}>
              <div className="flex items-center">
                {group.isLocationGroup ? (
                  <LocationGroupIcon fontSize="small" className="shrink-0" />
                ) : (
                  <GroupIcon fontSize="small" className="shrink-0" />
                )}
                <div className="ml-2 truncate">{group.name}</div>
              </div>
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  );
}
