import { ReactComponent as AlertIcon } from "@/icons/alert.svg";

export function AudibleAlert({ alertMessage }: { alertMessage: string }) {
  return (
    <div className="flex items-center gap-[10px]">
      <AlertIcon className="h-6" />
      <div className="color-[rgba(212, 212, 212, 1)] w-fit md:w-[435px]">
        {alertMessage}
      </div>
    </div>
  );
}
