import { HighlightOff } from "@mui/icons-material";
import { IconButton, Paper, Typography } from "@mui/material";
import clsx from "clsx";
import { ReactNode } from "react";

import { useBreakpoints } from "@/util/useBreakpoints";

import { MobileHeader } from "@/layout/MobileHeader";
import { useCustomScrollbarStyles } from "@/layout/theme";

interface BasicDrawerProps {
  title?: string;
  onClose?: () => void;
  closeButton?: ReactNode;
  children?: ReactNode;
}

export function BasicDrawer({
  children,
  title,
  onClose,
  closeButton,
}: BasicDrawerProps) {
  const { classes: scrollbarClasses } = useCustomScrollbarStyles();
  return (
    <Paper
      elevation={4}
      square
      className={clsx(
        "flex flex-col bg-blue-light w-[267px] flex-shrink-0 px-2 pt-5 pb-2 overflow-auto",
        scrollbarClasses.scrollbarContainer
      )}
    >
      {title || onClose || closeButton ? (
        <div className="flex items-center justify-between">
          {title && (
            <Typography variant="h4" className="ml-2.5">
              {title}
            </Typography>
          )}
          {(onClose || closeButton) && (
            <>
              <div className="flex-grow" />
              {closeButton ?? (
                <IconButton
                  onClick={onClose}
                  // className="text-blue-light hover:text-blue-dark"
                >
                  <HighlightOff />
                </IconButton>
              )}
            </>
          )}
        </div>
      ) : null}
      {children}
    </Paper>
  );
}

export function ResponsiveBasicDrawer(props: BasicDrawerProps) {
  const { fitsDesktop } = useBreakpoints();

  if (fitsDesktop) {
    return <BasicDrawer {...props} />;
  } else {
    return (
      <div className="fixed bg-white inset-0 overflow-auto">
        <MobileHeader
          label={props.title ?? "Details"}
          showBackButton
          to="./.."
        />
        <div className="p-4">{props.children}</div>
        {/* {props.children} */}
      </div>
    );
  }
}
