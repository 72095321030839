import { Field, useField } from "formik";
import React, { ChangeEvent, useMemo } from "react";

import { CamDrawer } from "@/components/CamDrawer/CamDrawerBase";
import { CamWrapperProps } from "@/components/CamDrawer/CamDrawerList";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";

import { useCamDrawerBaseQuery, CamDrawerBaseQuery } from "@/generated-models";

interface IntegrationLinkCamerasDrawer extends CamDrawerBaseQuery {
  cameras: Array<
    CamDrawerBaseQuery["cameras"][number] & {
      selected: boolean;
      disabled: boolean;
    }
  >;
}

export function IntegrationsLinkCamerasDrawer({
  open,
  onOpen,
  onClose,
}: {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
}) {
  const [, { value: cameraIds }, { setValue: setSelectedCameras }] = useField<
    number[]
  >("cameraIds");
  const { data, loading } = useCamDrawerBaseQuery();

  const cameras = useMemo(() => {
    return (data?.cameras || []).reduce((result, camera) => {
      /*
        The onvifBackchannelEnabled indicates the camera uses
        two-way audio already via bidi.
      */
      if (!camera.settings.onvifBackchannelEnabled) {
        const selected = cameraIds.includes(camera.id);
        const disabled = cameraIds.length === 4 && !selected;
        result.push({ ...camera, selected, disabled });
      }
      return result;
    }, [] as IntegrationLinkCamerasDrawer["cameras"]);
  }, [data, cameraIds]);

  function CamWrapper({ cam, children }: CamWrapperProps) {
    return (
      <label>
        <Field
          type="radio"
          name="cameraId"
          value={cam.id}
          className="absolute opacity-0 w-0 h-0"
          disabled={
            cam.disabled ||
            (cameraIds.length === 4 && !cameraIds.includes(cam.id))
          }
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (!cam.selected) {
              setSelectedCameras([...cameraIds, cam.id]);
            } else {
              setSelectedCameras(cameraIds.filter((id) => id !== cam.id));
            }
          }}
        />
        {children}
      </label>
    );
  }

  let camDrawerList: React.ReactNode;
  if (loading) {
    camDrawerList = <Loading>Fetching Cams</Loading>;
  } else if (cameras) {
    camDrawerList = (
      <CamDrawer.List cameras={cameras} CamWrapper={CamWrapper} />
    );
  } else {
    camDrawerList = (
      <ErrorMessage
        title="Oops..."
        description="Something went wrong loading cameras. Please try again later."
      />
    );
  }

  return (
    <>
      <CamDrawer open={open} onOpen={onOpen} onClose={onClose}>
        <CamDrawer.Filters className="!pt-6">
          <CamDrawer.GroupSelector />
          <CamDrawer.SearchBox placeholder="Search Cameras" fullWidth />
          <div className="bg-[#353D48] text-white text-center py-[7px] rounded">
            {cameraIds.length === 4
              ? "Max cameras added"
              : "You can add up to four cameras."}
          </div>
        </CamDrawer.Filters>
        {camDrawerList}
      </CamDrawer>
    </>
  );
}
