import LeftIcon from "@mui/icons-material/ChevronLeft";
import RightIcon from "@mui/icons-material/ChevronRight";
import { IconButton } from "@mui/material";
import clsx from "clsx";
import {
  addMonths,
  addYears,
  format,
  isSameMonth,
  subMonths,
} from "date-fns/fp";
import React, { useState } from "react";

const formatMonth = format("MMM");
const formatYear = format("y");

interface StaticMonthPickerProps {
  minDate: Date;
  maxDate: Date;
  value: Date;
  onChange: (value: Date) => void;
}

export function StaticMonthPicker({
  minDate,
  maxDate,
  value,
  onChange,
}: StaticMonthPickerProps) {
  const [date, setDate] = useState(value);
  const firstMonth = subMonths(date.getMonth(), date);

  return (
    <div className="flex flex-col text-sm text-text select-none">
      <div className="grid grid-cols-3 gap-2">
        <div>
          <IconButton
            size="small"
            className="text-text disabled:opacity-40"
            disabled={date.getFullYear() <= minDate.getFullYear()}
            onClick={() => setDate((d) => addYears(-1, d))}
          >
            <LeftIcon />
          </IconButton>
        </div>
        <div className="flex-center font-medium">{formatYear(date)}</div>
        <div className="flex justify-end">
          <IconButton
            size="small"
            className="text-text disabled:opacity-40"
            disabled={date.getFullYear() >= maxDate.getFullYear()}
            onClick={() => setDate((d) => addYears(1, d))}
          >
            <RightIcon />
          </IconButton>
        </div>
        {Array.from({ length: 12 }).map((_, i) => {
          const monthDate = addMonths(i, firstMonth);
          const disabled =
            (monthDate < minDate || monthDate > maxDate) &&
            !isSameMonth(monthDate, maxDate);
          const selected = isSameMonth(monthDate, value);

          return (
            <button
              key={i}
              className={clsx(
                "flex-center w-20 h-12 border rounded-lg",
                selected
                  ? "font-bold text-white bg-primary border-primary"
                  : "font-normal bg-inherit border-[#E0E0E0]",
                { "opacity-40": disabled }
              )}
              disabled={disabled}
              onClick={() => onChange(monthDate)}
            >
              {formatMonth(monthDate)}
            </button>
          );
        })}
      </div>
    </div>
  );
}
