import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Button, ButtonBase, Collapse, Typography } from "@mui/material";
import naturalSort from "javascript-natural-sort";
import { memo, useMemo } from "react";

import { Circle } from "@/icons/Circle";

import {
  DeviceStatusV2,
  getDeviceStatusV2,
} from "@/pages/Settings/LocationSettings/Camera/CameraSetupTable";

import {
  ConnectionValidation as Validation,
  DeviceDetailsQuery,
  LifecycleStates,
} from "@/generated-models";

import FisheyeChannelForm from "./FisheyeChannelForm";

interface FisheyeChannelsListProps {
  device: DeviceDetailsQuery["deviceScan"];
  expanded: boolean;
  onSetExpanded?: (expanded: boolean) => void;
  onOpenNativeProxy?: () => void;
}

function FisheyeChannelsList({
  expanded,
  onSetExpanded = () => {},
  device,
  onOpenNativeProxy = () => {},
}: FisheyeChannelsListProps) {
  const status = getDeviceStatusV2(device);
  // If device validation failed, only show channels with activated camera
  const channels = useMemo(
    () =>
      device.status === Validation.Ok
        ? device.channels
        : device.channels.filter((c) => c.streams.some((s) => !!s.camera)),
    [device]
  );

  const activeChannels = useMemo(
    () =>
      channels.filter((c) =>
        c.streams.some(
          (s) => s.camera?.lifecycleState === LifecycleStates.Enabled
        )
      ),
    [channels]
  );

  return (
    <div className="flex flex-col">
      <ButtonBase
        className="p-1 rounded-[5px] flex items-start hover:bg-black/5 transition-all duration-200 ease-in-out mb-2"
        onClick={() => onSetExpanded(!expanded)}
      >
        <div className="mr-[6px] -mt-[2px]">
          {status !== DeviceStatusV2.NvrNoActivatedCameras ? (
            <CheckCircleIcon style={{ fontSize: 18, color: "#62b309" }} />
          ) : (
            <Circle style={{ fontSize: 18, opacity: 0.1 }} />
          )}
        </div>
        <div className="flex flex-1">
          <div className="flex flex-col items-start justify-start">
            <Typography variant="h6" style={{ lineHeight: 1, marginTop: 1 }}>
              Fisheye Camera Configuration
            </Typography>
            <Typography className="text-sm tracking-[-0.1px] my-2 text-start max-w-[585px]">
              We found {activeChannels?.length} active and {channels?.length}{" "}
              total camera streams. Select the streams you would like to enable
              and view. Each stream is viewed as a separate camera feed for IVR
              purposes.
            </Typography>
          </div>
        </div>

        <Button
          component="div"
          size="small"
          color="primary"
          style={{ opacity: expanded ? 0 : 1 }}
          variant="outlined"
          onClick={() => onSetExpanded(!expanded)}
        >
          Edit
        </Button>
      </ButtonBase>
      <Collapse in={expanded}>
        <div className="flex flex-col max-h-[60vh] overflow-y-scroll gap-2">
          {channels
            ?.slice() // Need to copy array for sort
            .sort((a, b) => {
              const aHasCam = a.streams.some((s) => s.camera);
              const bHasCam = b.streams.some((s) => s.camera);
              if (aHasCam !== bHasCam) {
                // one of them has a cam, make sure the one without a cam is sorted on top
                return aHasCam ? 1 : -1;
              }
              // They either both have a cam, or neither has a cam. Apply a natural sort
              // on their paths
              return naturalSort(a.streams[0]?.path, b.streams[0]?.path);
            })
            .map((channel) => (
              <div key={channel.id} className="flex items-center gap-1">
                <div className="border border-solid border-[#E8E8E8] rounded-[10px] flex-1">
                  <FisheyeChannelForm
                    onOpenNativeProxy={onOpenNativeProxy}
                    key={`channel-${channel.id}`}
                    deviceId={device.id}
                    defaultName={`${
                      device.isFisheye ? "Fisheye" : "NVR"
                    } Channel ${channel.channelId + 1}`}
                    channel={channel}
                    vendor={device.vendor}
                    disableAddingFisheyeCameras={activeChannels?.length > 3}
                  />
                </div>
              </div>
            ))}
        </div>
      </Collapse>
    </div>
  );
}

export default memo(FisheyeChannelsList);
