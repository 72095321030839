import { Add, Close, Remove } from "@mui/icons-material";
import {
  Button,
  ClickAwayListener,
  IconButton,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { PropsWithChildren, useState, memo } from "react";

import { PawIcon } from "@/icons/Paw";

import { DPad, DPadActions } from "@/components/Player/PTZ/DPad";
import {
  CameraControls,
  useCameraControls,
} from "@/components/Player/PTZ/useCameraControls";
import { PlayerTooltip } from "@/components/Player/PlayerTooltip";

const sensitivty = {
  move: 0.25,
  zoom: 0.1,
};

interface PtzButtonProps {
  cameraId: number;
  deviceId: number;
  url: string;
  popupClassName?: string;
  disabled?: boolean;
  disabledTooltip?: string;
}

function PTZButtonComponent({
  cameraId,
  deviceId,
  url, // signaling server url
  popupClassName,
  disabled = false,
  disabledTooltip,
}: PtzButtonProps) {
  const [popupOpen, setPopupOpen] = useState(false);
  const { supported, controls } = useCameraControls({
    cameraId,
    deviceId,
    url,
  });

  if (!supported) {
    return null;
  }

  const isDisabled = !controls || disabled;

  return (
    <ClickAwayListener
      onClickAway={() => {
        setPopupOpen(false);
      }}
    >
      <div className="relative">
        <PlayerTooltip
          title={disabled ? disabledTooltip : "Pan/Tilt/Zoom Controls"}
        >
          <div
            className={clsx(
              "relative rounded-md bg-black bg-opacity-0 transition-colors"
            )}
          >
            <Button
              className={clsx("text-inherit", { "opacity-50": isDisabled })}
              disabled={isDisabled}
              onClick={(e) => {
                e.stopPropagation();
                setPopupOpen(!popupOpen);
              }}
              aria-label="Pan/Tilt/Zoom Controls"
            >
              <Typography
                className="text-sm font-bold"
                color={popupOpen ? "primary" : "inherit"}
              >
                PTZ
              </Typography>
            </Button>
          </div>
        </PlayerTooltip>
        {popupOpen && (
          <PTZPopup
            className={popupClassName}
            controls={controls}
            onClose={() => setPopupOpen(false)}
          />
        )}
      </div>
    </ClickAwayListener>
  );
}

function PTZPopup({
  className,
  controls,
  onClose,
}: {
  className?: string;
  controls: CameraControls | null;
  onClose: () => void;
}) {
  const move = async (x: number, y: number) => {
    await controls?.ptz
      ?.startMove(x, y)
      .catch((reason) => console.error(reason));
  };

  const stopMove = async () => {
    await controls?.ptz?.stop().catch((reason) => console.error(reason));
  };

  const zoom = async (direction: number) => {
    await controls?.ptz
      ?.startZoom(direction)
      .catch((reason) => console.error(reason));
  };

  const focus = async (direction: number) => {
    await controls?.focus
      ?.startFocus(direction)
      .catch((reason) => console.error(reason));
  };

  const stopFocus = async () => {
    await controls?.focus?.stop().catch((reason) => console.error(reason));
  };

  const dpadActions: DPadActions = {
    upLeft: {
      onMouseDown: async () => move(-sensitivty.move / 2, sensitivty.move / 2),
      onMouseUp: stopMove,
    },
    up: {
      onMouseDown: async () => move(0, sensitivty.move),
      onMouseUp: stopMove,
    },
    upRight: {
      onMouseDown: async () => move(sensitivty.move / 2, sensitivty.move / 2),
      onMouseUp: stopMove,
    },
    left: {
      onMouseDown: async () => move(-sensitivty.move, 0),
      onMouseUp: stopMove,
    },
    center: {
      onMouseDown: async () =>
        controls?.ptz?.gotoHome().catch((reason) => console.error(reason)),
      onMouseUp: async () => {},
    },
    right: {
      onMouseDown: async () => move(sensitivty.move, 0),
      onMouseUp: stopMove,
    },
    downLeft: {
      onMouseDown: async () => move(-sensitivty.move / 2, -sensitivty.move / 2),
      onMouseUp: stopMove,
    },
    down: {
      onMouseDown: async () => move(0, -sensitivty.move),
      onMouseUp: stopMove,
    },
    downRight: {
      onMouseDown: async () => move(sensitivty.move / 2, -sensitivty.move / 2),
      onMouseUp: stopMove,
    },
  };

  return (
    <div
      className={clsx("absolute bottom-10 right-[-130px] w-[250px]", className)}
    >
      <div className="flex items-center bg-[#292929] rounded-t-md">
        <PawIcon white={true} className="ml-2 h-5 w-5" />
        <Typography className="pl-3 py-1">PTZ Controls</Typography>
        <div className="grow" />
        <IconButton
          className="pr-2 text-[#bdbdbd]"
          size="small"
          onClick={onClose}
        >
          <Close />
        </IconButton>
      </div>
      <div className="px-4 pt-2 pb-4 grid grid-cols-2 bg-[#323232] bg-opacity-90 rounded-b-md">
        <div>
          <div className="col-span-3 text-[#bdbdbd]">Pan &amp; Tilt</div>
          <div className="-ml-2">
            <DPad disabled={!controls?.ptz} actions={dpadActions} />
          </div>
        </div>
        <div className="mb-2 pl-4 flex flex-col">
          <Move
            name="Zoom"
            disabled={!controls?.ptz}
            moveMinus={async () => {
              zoom(-sensitivty.zoom);
            }}
            movePlus={async () => {
              zoom(sensitivty.zoom);
            }}
            stop={stopMove}
          />
          <div className="grow" />
          <Move
            name="Focus"
            disabled={!controls?.focus}
            moveMinus={async () => {
              focus(-sensitivty.zoom);
            }}
            movePlus={async () => {
              focus(sensitivty.zoom);
            }}
            stop={stopFocus}
          />
        </div>
      </div>
    </div>
  );
}

function Move({
  name,
  disabled,
  moveMinus,
  movePlus,
  stop,
}: {
  name: string;
  disabled: boolean;
  moveMinus: () => Promise<void>;
  movePlus: () => Promise<void>;
  stop: () => Promise<void>;
}) {
  return (
    <div>
      <div className="pb-1 text-[#bdbdbd]">{name}</div>
      <div className="grid grid-cols-2 gap-2">
        <MoveButton
          disabled={disabled}
          onMouseDown={moveMinus}
          onMouseUp={stop}
        >
          <Remove />
        </MoveButton>
        <MoveButton disabled={disabled} onMouseDown={movePlus} onMouseUp={stop}>
          <Add />
        </MoveButton>
      </div>
    </div>
  );
}

function MoveButton({
  children,
  disabled,
  onMouseDown,
  onMouseUp,
}: PropsWithChildren<{
  disabled: boolean;
  onMouseDown: () => Promise<void>;
  onMouseUp: () => Promise<void>;
}>) {
  return (
    <IconButton
      disabled={disabled}
      className={clsx("text-white bg-[#292929] rounded-md", {
        "opacity-50": disabled,
      })}
      size="small"
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
    >
      {children}
    </IconButton>
  );
}

export const PTZButton = memo(PTZButtonComponent);
