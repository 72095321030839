import DownloadIcon from "@mui/icons-material/GetApp";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Step,
  StepConnector,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { CheckboxWithLabel, Select } from "formik-mui";
import gql from "graphql-tag";
import { useFlags } from "launchdarkly-react-client-sdk";
import { keyBy } from "lodash/fp";
import React, { useState } from "react";
import { makeStyles } from "tss-react/mui";

import { downloadExternalFile } from "@/util/file";
import { filterFalsy } from "@/util/filterFalsy";

import { FormikGroupSelect } from "@/pages/Settings/UserManagement/GroupSelect";

import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  ComplianceReportDownloadQueryVariables,
  ReportsInput,
  useComplianceReportDownloadQuery,
  useGroupsQuery,
} from "@/generated-models";

const useStyles = makeStyles<void, "completed">()(
  (_theme, _props, classes) => ({
    completed: {
      [`&.${classes.completed}, + div`]: { color: "rgba(0, 0, 0, 0.54)" },
    },
  })
);

const steps = [
  {
    label: "Report duration",
    content: "Pick the required duration for your report.",
  },
  {
    label: "Camera groups and locations",
    content:
      "Choose the camera groups and locations that you would like to be included in your report.",
  },
  {
    label: "Report content",
    content:
      "Click next to get the full report, or select only the documents you need.",
  },
  {
    label: "Download compliance report",
    content: (
      <>
        <strong>Success!</strong> Your compliance report has been created. Click
        to download and finish.
      </>
    ),
  },
];

const reportNames = {
  cameras: "Camera Properties",
  storage: "Proof of Storage",
  auditLogs: "Audit Logs",
  footageFailure: "Footage Record Failure",
};

const cbProps = {
  component: CheckboxWithLabel,
  color: "primary",
  size: "small",
  type: "checkbox",
};

export function ComplianceCreate() {
  const { footageRecordFailureReport } = useFlags();
  let durations = [
    { value: 30, label: "Past 30 Days" },
    { value: 60, label: "Past 60 Days" },
    { value: 90, label: "Past 90 Days" },
    { value: 180, label: "Past 180 Days" },
  ];
  if (footageRecordFailureReport) {
    durations = [
      { value: 0.5, label: "Last 12 hours" },
      // TODO: Discuss the 12 hour requirements. Devices can have
      // different timezones, so the last 12 hours option with
      // noon-to-midnight and midnight to noon variations will probably
      // not work.
      // { value: 0.5, label: "Last 12 hours (noon to midnight)" },
      // { value: 0.5, label: "Last 12 hours (midnight to noon)" },
      // Altrenative time option to discuss
      // { value: 1, label: "Last 24 hours" },
      ...durations,
    ];
  }
  const durationsMap = keyBy("value", durations);

  const { classes } = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const { pushSnackbar } = useFeedback();
  const { data: groupData } = useGroupsQuery(refetchOnMountPolicy);
  const groupsMap = groupData && keyBy("id", groupData.groups);
  const [pdfQuery, setPdfQuery] = useState<
    ComplianceReportDownloadQueryVariables["input"] | null
  >(null);
  const { data: pdfData } = useComplianceReportDownloadQuery({
    skip: !pdfQuery,
    variables: { input: pdfQuery! },
    pollInterval: 3000,
    notifyOnNetworkStatusChange: true, // Hack to get onCompleted to fire on every poll
    onCompleted: (data) => {
      if (data?.complianceReportDownload) {
        downloadExternalFile(data.complianceReportDownload);
        setPdfQuery(null);
      }
    },
    onError: () => {
      pushSnackbar(
        "Something went wrong.. Please try again later or contact support.",
        FeedbackType.Error
      );
      setPdfQuery(null);
    },
  });
  const pdfLoading = Boolean(!pdfData?.complianceReportDownload && pdfQuery);
  const timezone =
    Intl?.DateTimeFormat?.()?.resolvedOptions()?.timeZone ?? "UTC";

  const handleNext = () =>
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  const handleBack = () =>
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  const isFinalStep = activeStep === steps.length - 1;

  return (
    <Formik
      initialValues={{
        durationDays: 30,
        groups: [] as string[],
        reports: {
          cameras: {
            resolution: true,
            fps: true,
            uptime: true,
            alerts: true,
          },
          storage: true,
          auditLogs: true,
          footageFailure: false,
        },
      }}
      onSubmit={(values) => {
        console.log(values);
      }}
    >
      {({ values, setFieldValue }) => {
        const reportsValues = Object.keys(
          values.reports
        ) as (keyof ReportsInput)[];
        const camerasValues = Object.values(values.reports.cameras);
        const selectedReports = reportsValues
          .map((key) => {
            const selected =
              key === "cameras"
                ? camerasValues.some((x) => x)
                : values.reports[key];
            return selected ? reportNames[key] : undefined;
          })
          .filter(filterFalsy);
        const getSublabel = (step: number) => {
          if (step === 0) return durationsMap[values.durationDays].label;
          if (step === 1 && groupsMap) {
            return values.groups.map((id) => groupsMap[id].name).join(", ");
          }
          if (step === 2) return selectedReports.join(", ");
          return "";
        };
        return (
          <Form>
            <Stepper
              activeStep={activeStep}
              orientation="vertical"
              connector={<StepConnector style={{ marginLeft: 14 }} />}
            >
              {steps.map(({ label, content }, index) => (
                <Step key={label}>
                  <StepLabel
                    StepIconProps={{
                      classes: { root: "text-3xl", text: "font-bold" },
                    }}
                    classes={{
                      iconContainer: "pr-4",
                      label: "text-xl",
                      completed: classes.completed,
                    }}
                    optional={
                      index < activeStep ? (
                        <div>{getSublabel(index)}</div>
                      ) : undefined
                    }
                  >
                    {label}
                  </StepLabel>

                  <StepContent
                    classes={{ root: "pl-8" }}
                    style={{ marginLeft: 14 }}
                  >
                    <Typography>{content}</Typography>
                    <Box m={2} />
                    {activeStep === 0 && (
                      <FormControl variant="outlined">
                        <Field
                          component={Select}
                          name="durationDays"
                          labelId="duration-select-label"
                          label="Duration"
                          className="w-48"
                          formControl={{ variant: "standard" }}
                        >
                          {durations.map(({ value, label }) => (
                            <MenuItem key={value} value={value}>
                              {label}
                            </MenuItem>
                          ))}
                        </Field>
                      </FormControl>
                    )}
                    {activeStep === 1 && groupData && (
                      <FormikGroupSelect
                        name="groups"
                        options={groupData.groups}
                        variant="outlined"
                      />
                    )}
                    {activeStep === 2 && (
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={camerasValues.some((x) => x)}
                              indeterminate={
                                camerasValues.some((x) => x) &&
                                camerasValues.some((x) => !x)
                              }
                              onChange={(e) => {
                                const checked = e.target.checked;
                                setFieldValue("reports.cameras", {
                                  resolution: checked,
                                  fps: checked,
                                  uptime: checked,
                                  alerts: checked,
                                });
                              }}
                              color="primary"
                              size="small"
                            />
                          }
                          label={
                            <span className="font-medium">
                              Camera Properties
                            </span>
                          }
                        />
                        <FormGroup className="pl-12 pb-1">
                          <Field
                            {...cbProps}
                            name="reports.cameras.resolution"
                            Label={{ label: "Resolution" }}
                          />
                          <Field
                            {...cbProps}
                            name="reports.cameras.fps"
                            Label={{ label: "Frame rate" }}
                          />
                          <Field
                            {...cbProps}
                            name="reports.cameras.uptime"
                            Label={{ label: "Health (% uptime)" }}
                          />
                          <Field
                            {...cbProps}
                            name="reports.cameras.alerts"
                            Label={{ label: "Alerts" }}
                          />
                        </FormGroup>
                        <Field
                          {...cbProps}
                          name="reports.storage"
                          Label={{
                            label: (
                              <span className="font-medium">
                                Proof of Storage
                              </span>
                            ),
                          }}
                        />
                        <Field
                          {...cbProps}
                          name="reports.auditLogs"
                          Label={{
                            label: (
                              <span className="font-medium">Audit Logs</span>
                            ),
                          }}
                        />
                        {/* We only show this field for one day max */}
                        {footageRecordFailureReport &&
                          values.durationDays <= 1 && (
                            <Field
                              {...cbProps}
                              name="reports.footageFailure"
                              Label={{
                                label: (
                                  <span className="font-medium">
                                    Footage Record Failure
                                  </span>
                                ),
                              }}
                            />
                          )}
                      </FormGroup>
                    )}
                    {activeStep === 3 && (
                      <>
                        {values.durationDays >= 180 && (
                          <Typography className="-mt-4 text-xs">
                            Note: Downloading may take a few minutes
                          </Typography>
                        )}
                      </>
                    )}
                    <div className="mt-4">
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className="mr-2"
                      >
                        BACK
                      </Button>
                      {isFinalStep ? (
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={<DownloadIcon />}
                          endIcon={
                            pdfLoading ? (
                              <CircularProgress size="14px" />
                            ) : undefined
                          }
                          onClick={() =>
                            setPdfQuery({
                              ...values,
                              durationDays: Number(values.durationDays),
                              groups: values.groups.map(Number),
                              timezone,
                            })
                          }
                          disabled={pdfLoading}
                        >
                          DOWNLOAD REPORT
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleNext}
                          disabled={
                            (activeStep === 1 && values.groups.length === 0) ||
                            (activeStep === 2 && selectedReports.length === 0)
                          }
                        >
                          NEXT
                        </Button>
                      )}
                    </div>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </Form>
        );
      }}
    </Formik>
  );
}

gql`
  query complianceReportDownload($input: ComplianceReportDownloadInput!) {
    complianceReportDownload(input: $input)
  }
`;
