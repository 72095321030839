import { IconButton, IconButtonProps } from "@mui/material";
import React from "react";

import { useMultiPlayerControls } from "@/components/Player/PlayerBase";
import { Forward10Icon } from "@/components/Player/PlayerIcons";

import { PlayerTooltip } from "./PlayerTooltip";

export function Forward10Button({
  fontSize = 24,
  ...props
}: IconButtonProps & { fontSize?: number }) {
  const { seek } = useMultiPlayerControls();
  return (
    <PlayerTooltip
      title={
        props.disabled ? "Not available in live mode" : "Jump 10 sec ahead"
      }
    >
      <span>
        <IconButton
          onClick={() => seek((p) => p + 10)}
          classes={{ disabled: "opacity-50" }}
          style={{ color: "inherit", fontSize }}
          {...props}
          size="large"
        >
          <Forward10Icon fontSize="inherit" />
        </IconButton>
      </span>
    </PlayerTooltip>
  );
}
