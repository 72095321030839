import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Typography } from "@mui/material";
import clsx from "clsx";

import { COMPARATIVE_COLORS } from "../constant";

interface ComparativeLocationIndicatorProps {
  location: string;
  position: number;
  className?: string;
  textClassName?: string;
}

export function ComparativeLocationIndicator({
  location,
  position,
  className,
  textClassName,
}: ComparativeLocationIndicatorProps) {
  return (
    <div
      className={clsx(
        "md:w-[unset] w-full flex items-center justify-start gap-1 p-3 md:px-[10px] md:py-1 border border-[#E6E6E6] bg-white rounded-lg md:rounded",
        className
      )}
    >
      <div
        className="w-2 h-2 rounded-full block md:hidden"
        style={{ background: COMPARATIVE_COLORS[position][1] }}
      />
      <LocationOnIcon
        className="md:block hidden"
        style={{ color: COMPARATIVE_COLORS[position][1] }}
      />
      <Typography
        className={clsx(
          "text-xs leading-[14px] md:text-sm md:leading-4",
          textClassName
        )}
      >
        {location}
      </Typography>
    </div>
  );
}
