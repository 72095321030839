import mapboxgl from "mapbox-gl";
import React, { useCallback } from "react";
import ReactDOM from "react-dom";

import { useBreakpoints } from "@/util/useBreakpoints";

import { AddedCameraIcon } from "@/components/MappingTool/AddedCameraIcon";
import { HoveredCam, MODE } from "@/components/MappingTool/Mapping";
import { NavigationTab } from "@/components/View/sharedViewHooks";

interface MarkerProps {
  cam: any;
  map: mapboxgl.Map | null;
  setEditCam: React.Dispatch<number | null>;
  setCam: React.Dispatch<number | undefined>;
  setHoveredCam: React.Dispatch<HoveredCam | null>;
  setFullWidth: React.Dispatch<boolean>;
  mode: MODE;
  setMobileViewMode?: React.Dispatch<NavigationTab>;
}

export function AddedCameraMarker({
  cam,
  map,
  setEditCam,
  setCam,
  setFullWidth,
  setHoveredCam,
  mode,
  setMobileViewMode,
}: MarkerProps) {
  const { fitsDesktop } = useBreakpoints();
  const refCb = useCallback(
    (node: HTMLDivElement | null) => {
      if (!node || !map) return;
      new mapboxgl.Marker({
        element: node,
        draggable: false,
      })
        .setLngLat([cam.position.x, cam.position.y])
        .addTo(map);
    },
    [cam, map]
  );

  return ReactDOM.createPortal(
    <div
      ref={refCb}
      style={{
        display: "flex",
        position: "absolute",
        alignItems: "center",
        justifyContent: "center",
        height: "0px",
        width: "0px",
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <AddedCameraIcon
        angle={cam.angle}
        online={cam.camera.health.cameraOnline}
        showStatus={mode === "VIEW"}
        onMainCamHovered={
          fitsDesktop
            ? (element) => mode === "VIEW" && setHoveredCam({ element, cam })
            : undefined
        }
        onMainCamLeft={
          fitsDesktop ? () => mode === "VIEW" && setHoveredCam(null) : undefined
        }
        onMainCamClicked={() => {
          if (mode === "VIEW") {
            setCam(cam.camera.id);
            setFullWidth(false);
            if (setMobileViewMode) {
              setMobileViewMode(NavigationTab.Cameras);
            }
          } else {
            setEditCam(cam.id);
          }
        }}
      />
    </div>,
    map!.getCanvasContainer()
  );
}
