import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CloseIcon from "@mui/icons-material/Close";
import CheckBoxIndeterminateIcon from "@mui/icons-material/IndeterminateCheckBox";
import {
  Checkbox,
  Chip,
  TextField,
  Typography,
  Autocomplete,
} from "@mui/material";
import { keyBy } from "lodash/fp";
import React from "react";
import { makeStyles } from "tss-react/mui";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const indeterminateIcon = <CheckBoxIndeterminateIcon fontSize="small" />;

const useStyles = makeStyles()(() => ({
  chipRoot: { borderRadius: 6, backgroundColor: "#fff" },
  deleteIcon: { width: 14, height: 14, margin: "0 5px 0 -2px" },
  popupIndicatorOpen: { transform: "none" },
  popupOption: {
    "&[aria-selected='true']": { backgroundColor: "initial" },
    borderBottom: "1px solid rgba(0, 0, 0, 0.04)",
  },
  checkboxLabel: { fontWeight: "bold" },
  // endAdornment: { top: "calc(50% - 20px)" },
}));

interface AuditLogSelectProps<
  IdType extends number | string,
  OptionType extends Option<IdType>
> {
  value: IdType[];
  onChange: (ids: IdType[]) => void;
  label?: string;
  options?: Option<IdType>[];
  allSelectedLabel?: string;
  manySelectedLabel?: string;
  renderOption?: (option: OptionType) => React.ReactNode;
}

interface Option<IdType extends number | string> {
  id: IdType;
  name: string;
  disabled?: boolean | null;
}

function groupSelectedOption(name: string) {
  return {
    id: 0,
    name,
  };
}

const selectAllOption = {
  id: -42,
  name: "Select/Deselect All",
};

export function AuditLogGroupSelect<OptionType extends Option<number>>(
  props: AuditLogSelectProps<number, OptionType>
) {
  return (
    <AuditLogAutoComplete
      allSelectedLabel="All groups selected"
      manySelectedLabel="Many groups selected"
      {...props}
    />
  );
}
export function AuditLogCameraSelect<OptionType extends Option<number>>(
  props: AuditLogSelectProps<number, OptionType>
) {
  return (
    <AuditLogAutoComplete
      allSelectedLabel="All cameras selected"
      manySelectedLabel="Many cameras selected"
      {...props}
      options={[selectAllOption, ...(props.options ?? [])]}
    />
  );
}
export function AuditLogUserSelect<OptionType extends Option<number>>(
  props: AuditLogSelectProps<number, OptionType>
) {
  return (
    <AuditLogAutoComplete
      allSelectedLabel="All users selected"
      manySelectedLabel="Many users selected"
      {...props}
      options={[selectAllOption, ...(props.options ?? [])]}
    />
  );
}
export function AuditLogActivitySelect<OptionType extends Option<string>>(
  props: AuditLogSelectProps<string, OptionType>
) {
  return (
    <AuditLogAutoComplete
      allSelectedLabel="All activities selected"
      manySelectedLabel="Many activities selected"
      {...props}
      options={[selectAllOption as any, ...(props.options ?? [])]}
    />
  );
}

export function AuditLogAutoComplete<
  IdType extends number | string,
  OptionType extends Option<IdType> = Option<IdType>
>({
  value: ids,
  onChange,
  label,
  options = [],
  allSelectedLabel = "All selected",
  manySelectedLabel = "Many selected",
  renderOption,
}: AuditLogSelectProps<IdType, OptionType>) {
  const { classes } = useStyles();

  const optionsMap = keyBy("id", options);
  // Defaults to showing "All selected" if _none_ are actually selected.
  // This makes sense in the context of filters.
  const value: Option<number | string>[] =
    ids.length === 0
      ? [groupSelectedOption(allSelectedLabel)]
      : ids.map((id) => optionsMap[id]);

  const filteredOptionCount = options.filter((o) => o.id !== selectAllOption.id)
    .length;
  const allSelected = ids.length === filteredOptionCount || ids.length === 0;

  return (
    <Autocomplete
      multiple
      value={value as Option<IdType>[]}
      onChange={(_, newValue, __, details) => {
        if (details?.option.id === selectAllOption.id) {
          // Select/Deselect all
          if (ids.length === 0) {
            onChange(
              options.map((o) => o.id).filter((id) => id !== selectAllOption.id)
            );
          } else {
            onChange([]);
          }
        } else {
          onChange(
            newValue
              // Filter out "All" and "Many" options (see groupSelectedOption())
              .filter((v) => typeof v.id !== "number" || v.id > 0)
              .map((v) => v.id)
          );
        }
      }}
      limitTags={1}
      options={options}
      disableCloseOnSelect
      disableClearable={ids.length === 0}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option, { selected, ...rest }) => {
        let checked: boolean | undefined = selected;
        let indeterminate: boolean | undefined = undefined;
        if (option.id === selectAllOption.id) {
          if (ids.length === 0) {
            checked = false;
          } else if (ids.length === filteredOptionCount) {
            checked = true;
          } else {
            indeterminate = true;
            checked = true;
          }
        }
        return (
          <li {...props}>
            <Checkbox
              color="primary"
              icon={icon}
              checkedIcon={checkedIcon}
              indeterminateIcon={indeterminateIcon}
              style={{ marginRight: 8 }}
              checked={checked}
              indeterminate={indeterminate}
            />
            {option.id !== selectAllOption.id && renderOption
              ? renderOption(option as OptionType)
              : option.name}
          </li>
        );
      }}
      ListboxProps={{ style: { padding: 0 } }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          InputLabelProps={{ ...params.InputLabelProps, shrink: true }}
          InputProps={{
            ...params.InputProps,
            style: { padding: 2, paddingRight: 52 },
          }}
        />
      )}
      renderTags={(value, getTagProps) => {
        const manySelected = value.length > 20;
        if (allSelected) {
          return (
            <Typography style={{ paddingLeft: 14 }}>
              {allSelectedLabel}
            </Typography>
          );
        } else if (manySelected) {
          return (
            <Typography style={{ paddingLeft: 14 }}>
              {manySelectedLabel}
            </Typography>
          );
        }
        return value.map((option, index) => {
          const tagProps = getTagProps({ index });
          return (
            <Chip
              label={option.name}
              {...tagProps}
              variant="outlined"
              deleteIcon={<CloseIcon />}
              classes={{
                root: classes.chipRoot,
                deleteIcon: classes.deleteIcon,
              }}
              onDelete={
                allSelected || manySelected || option.disabled
                  ? undefined
                  : tagProps.onDelete
              }
            />
          );
        });
      }}
      classes={{
        popupIndicatorOpen: classes.popupIndicatorOpen,
        option: classes.popupOption,
        // endAdornment: classes.endAdornment,
      }}
    />
  );
}
