// This is a temporary fix to allow `e.preventDefault()` on "wheel" events from React in Chrome.
// https://github.com/facebook/react/issues/14856#issuecomment-482796664

const PASSIVE_EVENTS = [
  "touchstart",
  "touchmove",
  "touchend",
  "touchcancel",
  "wheel",
];

const checkType = (type: any, options: any) => {
  if (!PASSIVE_EVENTS.includes(type)) return null;

  const modOptions: any = {
    boolean: {
      capture: options,
      passive: false,
    },
    object: {
      ...options,
      passive: false,
    },
  };

  return modOptions[typeof options];
};

const addEventListener = (document.addEventListener as any).bind();
const removeEventListener = (document.removeEventListener as any).bind();
export function patchPassiveEvents() {
  (document as any).addEventListener = (
    type: any,
    listener: any,
    options: any,
    wantsUntrusted: any
  ) =>
    addEventListener(
      type,
      listener,
      checkType(type, options) || options,
      wantsUntrusted
    );
  document.removeEventListener = (type: any, listener: any, options: any) =>
    removeEventListener(type, listener, checkType(type, options) || options);
}
