import { CircularProgress } from "@mui/material";
import React from "react";

import { useIsBuffering } from "@/components/Player/PlayerBase";
import { usePlaylistReachable } from "@/components/Player/playerMachine";

export function BufferingIndicator({ playerId }: { playerId?: string }) {
  const isBuffering = useIsBuffering(playerId);
  const playlistReachable = usePlaylistReachable();
  if (!isBuffering || !playlistReachable) return null;
  return (
    <div className="absolute h-full w-full flex items-center justify-center pointer-events-none">
      <CircularProgress className="text-white" size={80} />
    </div>
  );
}
