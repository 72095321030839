import { BugReport } from "@mui/icons-material";
import IntelligenceIcon from "@mui/icons-material/AutoGraph";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import VideoIcon from "@mui/icons-material/OndemandVideo";
import UsersIcon from "@mui/icons-material/PeopleOutline";
import RedeemIcon from "@mui/icons-material/Redeem";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
} from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import { ReactElement, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import semverGte from "semver/functions/gte";

import { LocationIcon, MaintainIcon, NotificationsIcon } from "@/icons/Icons";
import { ReactComponent as IntegrationsLogo } from "@/icons/integrations.svg";

import { filterFalsy } from "@/util/filterFalsy";
import { useBeamer } from "@/util/useBeamer";

import { useMe } from "@/components/Auth";
import {
  isMobileApp,
  mobileEnvironment,
  mobileFeatureFlags,
} from "@/components/Mobile/mobileEnvironment";
import { ShareIcon } from "@/components/Player/PlayerIcons";
import { UserContextDisplay } from "@/components/User/UserContextDisplay";
import { Logo } from "@/components/shared/Logo";

import { Role, useHasAiLicenseQuery } from "@/generated-models";
import { usePermissions } from "@/hooks/usePermissions";

import { usePrefixOrgSlug } from "../hooks/useOrgRouteBase";

function MobileMenuItem({
  as = Link,
  icon,
  label,
  last,
  divider = true,
  ...rest
}: {
  as?: any;
  icon: ReactElement;
  label: string;
  onClick?: () => void;
  to?: string;
  last?: boolean;
  divider?: boolean;
}) {
  const Component = as;
  return (
    <>
      <Component
        className="flex items-center p-5 font-medium bg-transparent"
        {...rest}
      >
        {icon}
        <div className="ml-4">{label}</div>
      </Component>
      {!last && divider && <Divider className="mx-5 border-[#E9E9E9]" />}
    </>
  );
}

export function MobileMenu() {
  const { navigationUpdates, intercomSupport, integrations } = useFlags();
  const [expandHelp, setExpandHelp] = useState(false);
  const { data: licenseQuery } = useHasAiLicenseQuery();
  const navigate = useNavigate();
  const me = useMe();
  const hasPermission = usePermissions();
  const prefixOrgSlug = usePrefixOrgSlug();
  const { show } = useIntercom();

  const mobileVersion =
    mobileEnvironment !== null && semverGte(mobileEnvironment.version, "1.1.0");

  useBeamer();

  const featuredNavLinks = [
    licenseQuery?.hasAiLicense &&
      hasPermission("intelligence_access") && {
        to: "/intelligence",
        label: "Intelligence",
        icon: <IntelligenceIcon />,
      },
    hasPermission("video_share") && {
      to: "/shares",
      label: "Shares",
      icon: <ShareIcon />,
    },
    hasPermission("alerts_access") && {
      to: "/alerts",
      label: "Alerts",
      icon: <NotificationsNoneIcon />,
    },
    hasPermission("devices_access") && {
      to: "/maintain",
      label: "Maintain",
      icon: <MaintainIcon />,
      ...(!navigationUpdates
        ? [
            {
              to: "/configure/tutorials",
              label: "Tutorials",
              icon: <VideoIcon />,
            },
            {
              as: "button",
              onClick: () => {
                const zendesk = window.zE;
                zendesk?.activate();
                navigate(-1);
              },
              label: "Support",
              icon: <HelpOutlineIcon />,
            },
          ]
        : []),
    },
  ].filter(filterFalsy);

  const navLinks = [
    { to: "/configure/settings", label: "Settings", icon: <SettingsIcon /> },
    hasPermission("users_access") && {
      to: "/configure/user-management",
      label: "Users",
      icon: <UsersIcon />,
    },
    hasPermission("devices_access") && {
      to: "/configure/locations",
      label: "Locations",
      icon: <LocationIcon />,
    },
    integrations &&
      hasPermission("integrations_access") && {
        to: "/integrations",
        label: "Integrations",
        icon: <IntegrationsLogo className="w-6" />,
      },
    {
      to: "/configure/notifications",
      label: "Mobile Notifications",
      icon: <NotificationsIcon />,
    },
    (!isMobileApp || mobileFeatureFlags.externalLinks) && {
      as: "button",
      onClick: () => {
        const beamer = (window as any).Beamer;
        if (beamer) beamer.show();
      },
      label: "What's New",
      icon: <RedeemIcon />,
    },
    me &&
      me.role >= Role.Support && {
        to: "/debug",
        label: "Debug",
        icon: <BugReport />,
      },
  ].filter(filterFalsy);

  return (
    <main>
      <div className="pl-4 pb-3 pt-5 shadow bg-white">
        {me?.organization.logo ? (
          <img
            src={me?.organization.logo}
            alt="Logo"
            className="h-9 max-w-[9rem] object-contain"
          />
        ) : (
          <Logo className="h-9" />
        )}
      </div>
      <nav className="flex flex-col text-text font-medium">
        {featuredNavLinks.map((menuItemProps, index) => (
          <span className="flex flex-col bg-white text-[22px] leading-[25.78px]">
            <MobileMenuItem
              {...menuItemProps}
              to={
                menuItemProps.to ? prefixOrgSlug(menuItemProps.to) : undefined
              }
              last={featuredNavLinks.length - 1 === index}
              key={index}
            />
          </span>
        ))}
        {navigationUpdates && (mobileEnvironment === null || mobileVersion) && (
          <>
            <Divider className="mx-5 border-[#E9E9E9]" />
            <span className="flex flex-col bg-white text-[22px] leading-[25.78px]">
              <Accordion
                elevation={0}
                expanded={expandHelp}
                onChange={() => setExpandHelp((el) => !el)}
              >
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className="flex items-center px-1 py-2 font-medium bg-transparent">
                    <HelpOutlineIcon />
                    <div className="ml-4">Help</div>
                    {expandHelp ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="px-1">
                    <a
                      className="text-base block"
                      href={
                        intercomSupport
                          ? "https://helpcenter.spot.ai/en"
                          : "https://spotai.zendesk.com/hc/en-us"
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <strong className="text-primary">Knowledge Base</strong>
                      <br />
                      Help articles & how-to guides
                    </a>
                    <Link
                      className="text-base block mt-4"
                      to={prefixOrgSlug("/configure/tutorials")}
                    >
                      <strong className="text-primary">Video Tutorials</strong>
                      <br />
                      Video walkthroughs of features
                    </Link>
                    <Button
                      className="text-base block mt-4 text-left hover:bg-transparent p-0"
                      onClick={() => {
                        if (intercomSupport) {
                          show();
                        } else {
                          const zendesk = window.zE;
                          zendesk?.activate();
                          navigate(-1);
                        }
                      }}
                    >
                      <strong className="text-primary">Contact Support</strong>
                      <br />
                      Leave a message for the Spot Support Team
                    </Button>
                  </div>
                </AccordionDetails>
              </Accordion>
            </span>
          </>
        )}
        <div className="bg-[#EFEFEF]">
          {navLinks.map((menuItemProps, index) => (
            // <span className="flex flex-col  text-[16px] leading-[18.75px]">
            <MobileMenuItem
              {...menuItemProps}
              to={
                menuItemProps.to ? prefixOrgSlug(menuItemProps.to) : undefined
              }
              last={navLinks.length - 1 === index}
              divider={false}
              key={index}
            />
            // </span>
          ))}
          <UserContextDisplay className="m-4" />
        </div>
      </nav>
    </main>
  );
}
