import { useUpdateLocationMutation } from "@/generated-models";

export function useCompleteWalkthrough(
  locationId: number,
  onCompleted?: () => void
) {
  const [updateLocation] = useUpdateLocationMutation({
    onCompleted,
  });

  return () =>
    updateLocation({
      variables: {
        input: {
          id: locationId,
          isSetup: true,
        },
      },
    });
}
