import clsx from "clsx";
import React, { PropsWithChildren } from "react";

import { usePlayerControlsShown } from "@/components/Player/playerControlsMachine";

export function PlayerControls({
  children,
  disabled,
  className = "absolute bottom-0 left-0 right-0 z-1",
}: PropsWithChildren<{ disabled?: boolean; className?: string }>) {
  const shown = usePlayerControlsShown();
  return (
    <div
      className={clsx(
        "flex flex-col items-center text-white transition-opacity",
        className,
        {
          "opacity-70": disabled,
          "pointer-events-none": disabled,
          "opacity-0": !shown,
        }
      )}
    >
      {children}
    </div>
  );
}
