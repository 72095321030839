import { useMemo } from "react";

import { useBreakpoints } from "@/util/useBreakpoints";

import { useFullScreenHandle } from "@/components/FullScreen";
import { usePlayerControls } from "@/components/Player/PlayerBase";

import { useGetStillsSampleQuery } from "@/generated-models";

export function usePlayerMobileMode() {
  const { fitsTablet } = useBreakpoints();
  // const isLandscape = useMediaQuery("(orientation: landscape)");
  // return !fitsTablet || isLandscape;
  return !fitsTablet;
}

/**
 * Wrapper that falls back to using the video player API to go into
 * fullscreen mode, since iOS devices don't support putting an
 * HTML element into fullscreen mode.
 */
export function usePlayerFullscreen(playerId?: string) {
  const { enter, exit, browserSupportsFullscreen } = useFullScreenHandle();
  const { setFullscreen } = usePlayerControls(playerId);

  return useMemo(
    () =>
      browserSupportsFullscreen
        ? { enter, exit }
        : {
            enter: () => Promise.resolve(setFullscreen(true)),
            exit: () => Promise.resolve(setFullscreen(false)),
          },
    [browserSupportsFullscreen, enter, exit, setFullscreen]
  );
}

export function useVodStills(
  cameraId: number,
  startTime: string,
  endTime: string,
  skip?: boolean
) {
  const { data: stillsSampleData } = useGetStillsSampleQuery({
    variables: {
      cameraId,
      startTime: new Date(startTime).toISOString(),
      endTime: new Date(endTime).toISOString(),
      sampleCount: 50,
    },
    skip,
  });

  return useMemo(() => {
    const startTimestamp = new Date(startTime).getTime();
    return stillsSampleData?.stillsSample.map((s) => ({
      timestamp: (new Date(s.timestamp).getTime() - startTimestamp) / 1000,
      src: s.src,
    }));
  }, [stillsSampleData, startTime]);
}
