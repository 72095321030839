import { Container, Paper, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import clsx from "clsx";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useMemo } from "react";
import {
  Link,
  Navigate,
  Route,
  Routes,
  useLocation,
  useMatch,
} from "react-router-dom";

import { filterFalsy } from "@/util/filterFalsy";
import { useBreakpoints } from "@/util/useBreakpoints";
import { useDocumentTitle } from "@/util/useDocumentTitle";

import { ComplianceReports } from "@/pages/Settings/ComplianceReports/ComplianceReports";
import { LocationSettings } from "@/pages/Settings/LocationSettings/LocationSettings";
import { NotificationSettings } from "@/pages/Settings/NotificationSettings";
import { OrganizationSettings } from "@/pages/Settings/OrganizationSettings";
import { GroupSettings } from "@/pages/Settings/TagsSettings/TagsSettings";
import { Webhooks } from "@/pages/Settings/Webhooks/Webhooks";

import { NoAccess } from "@/components/NoAccess/NoAccess";
import {
  SecondaryNavTab,
  SecondaryNavTabs,
} from "@/components/SecondaryNavTabs";
import { Tutorials } from "@/components/Tutorials/Tutorials";

import { usePermissions } from "@/hooks/usePermissions";
import { ContentWrapper } from "@/layout/ContentWrapper";
import { useCustomScrollbarStyles } from "@/layout/theme";

import { AppleTVSettings } from "./AppleTV";
import { AuditLogRouter } from "./AuditLogs/AuditLogRouter";
import { DeveloperApiSettings } from "./DeveloperApi/DeveloperApiSettings";
import { useSettingsRoutes } from "./SettingsRoutes";
import { RoleDetails } from "./UserManagement/RoleDetails";
import { UserDetails } from "./UserManagement/UserDetails";
import { UserManagement } from "./UserManagement/UserManagement";

export function Settings() {
  useDocumentTitle("Configure");
  const hasPermission = usePermissions();
  const location = useLocation();
  const theme = useTheme();
  const { fitsDesktop } = useBreakpoints();
  const { atv, devApiGen, webhooks } = useFlags();
  const settingsRoutes = useSettingsRoutes();
  const { classes: scrollbarClasses } = useCustomScrollbarStyles();

  const tabs: {
    tab: string;
    path?: string;
    label: string;
    Component: React.ComponentType;
    disabled?: boolean;
  }[] = [
    {
      tab: "user-management",
      path: "user-management/*",
      label: "User Management",
      Component: UserManagement,
    },
    {
      tab: "locations",
      path: "locations/*",
      label: "Locations",
      Component: LocationSettings,
      disabled: !hasPermission("devices_access"),
    },
    {
      tab: "groups",
      path: "groups/*",
      label: "Camera Groups",
      Component: GroupSettings,
    },
    {
      tab: "notifications",
      label: "Notifications",
      Component: NotificationSettings,
    },
    webhooks && {
      tab: "webhooks",
      label: "Webhooks",
      Component: Webhooks,
      disabled: !hasPermission("organization_api"),
    },
    {
      tab: "audit-log",
      label: "Audit Log",
      Component: AuditLogRouter,
      disabled: !hasPermission("organization_audit_log"),
    },
    {
      tab: "compliance",
      path: "compliance/*",
      label: "Compliance",
      Component: ComplianceReports,
      disabled: !hasPermission("organization_compliance"),
    },
    atv && {
      tab: "apple-tv",
      path: "apple-tv/*",
      label: "Apple TV",
      Component: AppleTVSettings,
    },
    {
      tab: "settings",
      label: "Settings",
      Component: OrganizationSettings,
      disabled: !hasPermission("organization_settings"),
    },
    devApiGen && {
      tab: "developer-api",
      path: "developer-api/*",
      label: "API",
      Component: DeveloperApiSettings,
      disabled: !hasPermission("organization_api"),
    },
    {
      tab: "tutorials",
      path: "tutorials/*",
      label: "Tutorials",
      Component: Tutorials,
    },
  ].filter(filterFalsy);

  const tabLocation = tabs.find((candidate) =>
    location.pathname.includes(candidate.tab)
  )?.tab;
  const locationMatch = useMatch(`${settingsRoutes.locations}/*`);
  const configuringLocation = Boolean(locationMatch?.params["*"]);
  const configuringGroups = Boolean(useMatch(`${settingsRoutes.groups}/:id/*`));

  const initialPath = useMemo(() => {
    return tabs.find((t) => !!t.path)?.path?.split("/")[0] || "tutorials";
  }, [tabs]);

  return (
    <div
      className={clsx("flex", {
        "grow h-[calc(100%-70px)]": !locationMatch,
      })}
    >
      <ContentWrapper className="flex justify-center grow !pb-0">
        <Container
          maxWidth={configuringLocation ? false : "md"}
          className={clsx("flex items-start px-0 md:px-3 m-0 overflow-hidden", {
            "max-w-[1050px]": configuringLocation,
          })}
        >
          {fitsDesktop && !configuringLocation && !configuringGroups && (
            <div className="relative mr-8">
              <div className="w-px h-full absolute top-0 right-px bg-[#cecece]" />
              <SecondaryNavTabs value={tabLocation} orientation="vertical">
                {tabs
                  .filter((t) => !t.disabled)
                  .map(({ tab, label }) => (
                    <SecondaryNavTab
                      wrapped
                      data-cy="nav-item"
                      key={tab}
                      label={label}
                      component={Link}
                      to={tab}
                      value={tab}
                      color={theme.palette.text.secondary}
                    />
                  ))}
              </SecondaryNavTabs>
            </div>
          )}

          <div className="relative grow h-full">
            <Paper
              elevation={fitsDesktop ? 4 : 0}
              className={clsx(
                "overflow-auto grow max-h-full",
                scrollbarClasses.scrollbarContainer
              )}
            >
              <Routes>
                <Route index element={<Navigate to={initialPath} replace />} />
                {tabs.map(({ tab, path, Component, disabled }) => (
                  <Route
                    key={tab}
                    path={path ?? tab}
                    element={disabled ? <NoAccess /> : <Component />}
                  />
                ))}
              </Routes>
            </Paper>
            {configuringLocation && (
              <Typography className="w-full mt-4 text-center text-sm">
                Need help? Contact our friendly support team at{" "}
                <a href="mailto:help@spotai.co">help@spotai.co</a>
              </Typography>
            )}
          </div>
        </Container>
        <Routes>
          {/* Drawer on the right */}
          <Route path="user-management/users/:id" element={<UserDetails />} />
          <Route path="user-management/roles/:id" element={<RoleDetails />} />
        </Routes>
      </ContentWrapper>
    </div>
  );
}
