import { Backdrop } from "@mui/material";
import { useField } from "formik";
import { useMemo } from "react";

import { CamDrawer } from "@/components/CamDrawer/CamDrawerBase";
import { CamWrapperProps } from "@/components/CamDrawer/CamDrawerList";

import { CamDrawerBaseQuery } from "@/generated-models";

export function GroupsCamDrawer({
  cameras,
  open,
  onOpen,
  onClose,
}: {
  cameras: CamDrawerBaseQuery["cameras"];
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
}) {
  const [, { value: cameraIds }, { setValue }] = useField<number[]>(
    "cameraIds"
  );

  const statefulCameras = useMemo(() => {
    return cameras.map((c) => {
      const selected = cameraIds.includes(c.id);
      return { ...c, selected };
    });
  }, [cameras, cameraIds]);

  return (
    <>
      <Backdrop open={open} onClick={onClose} className="z-1" />
      <CamDrawer open={open} onOpen={onOpen} onClose={onClose}>
        <CamDrawer.Filters>
          <CamDrawer.GroupSelector />
          <CamDrawer.SearchBox placeholder="Search Cameras" fullWidth />
          <CamDrawer.ListControls
            cameras={statefulCameras}
            selectIds={(ids) => setValue([...cameraIds, ...ids])}
            deselectIds={(ids) =>
              setValue(cameraIds.filter((id) => !ids.includes(id)))
            }
          />
          <CamDrawer.SelectedOnlyFilter />
        </CamDrawer.Filters>
        <CamDrawer.List cameras={statefulCameras} CamWrapper={CamWrapper} />
      </CamDrawer>
    </>
  );
}

function CamWrapper({ cam, children }: CamWrapperProps) {
  const [, { value: cameraIds }, { setValue }] = useField<number[]>(
    "cameraIds"
  );

  return (
    <label>
      <input
        type="checkbox"
        data-testid={`cam-${cam.id}`}
        checked={cameraIds.some((id) => id === cam.id)}
        onChange={(event) => {
          if (event.target.checked) {
            setValue([...cameraIds, cam.id]);
          } else {
            setValue(cameraIds.filter((id) => id !== cam.id));
          }
        }}
        className="absolute opacity-0 w-0 h-0"
      />
      {children}
    </label>
  );
}
