import { Button, Grid } from "@mui/material";
import CropperJS from "cropperjs";
import "cropperjs/dist/cropper.css";
import React, { useCallback, useState } from "react";
import Cropper from "react-cropper";
import { makeStyles } from "tss-react/mui";

import { theme } from "@/layout/theme";

const useStyles = makeStyles()(() => ({
  cropperHolder: {
    position: "relative",
    display: "flex",
  },
  spotCropper: {
    maxWidth: "50vh",
    display: "flex",
  },
}));

export interface CropData {
  top: number;
  left: number;
  width: number;
  height: number;
}

export interface ImageCropperProps {
  image: string;
  onCropChange: (
    img: {
      data: string;
      file: File;
      cropData: CropData;
    } | null
  ) => void;
  fileName: string;
  cancel: () => void;
  initialCropData?: CropData;
}
export function ImageCropper({
  image,
  onCropChange,
  fileName,
  cancel,
  initialCropData,
}: ImageCropperProps) {
  const { classes } = useStyles();
  const [cropperImage, setCroppedImage] = useState<{
    cropData: CropData;
    data: string;
    file: File;
  } | null>(null);

  const [cropper, setCropper] = useState<Cropper>();

  const wrapper = useCallback(
    (element: HTMLDivElement | null) => {
      if (element !== null) {
        element.addEventListener("cropend", (event: any) => {
          const cropper: CropperJS = event.target.cropper;
          const cropData = cropper.getData(true);
          if (
            cropData.width === cropperImage?.cropData.width &&
            cropData.height === cropperImage?.cropData.height
          ) {
            setCroppedImage(null);
          } else {
            const img = cropper.getCroppedCanvas();
            img.toBlob((blob) => {
              if (!blob) return;

              const file = new File([blob], fileName);
              setCroppedImage({
                cropData: {
                  top: cropData.y,
                  left: cropData.x,
                  height: img.height,
                  width: img.width,
                },
                data: img.toDataURL("image/png"),
                file: file,
              });
            });
          }
        });
        element.addEventListener("ready", (event: any) => {
          const cropper: CropperJS = event.target.cropper;
          const { height, naturalHeight } = cropper.getImageData();
          const ratio = height / naturalHeight;
          if (initialCropData)
            cropper.setCropBoxData({
              top: initialCropData.top * ratio,
              left: initialCropData.left * ratio,
              height: initialCropData.height * ratio,
              width: initialCropData.width * ratio,
            });
        });
      }
    },
    // eslint-disable-next-line
    [onCropChange]
  );

  const handleCropConfirm = () => {
    if (!cropperImage) return;
    onCropChange(cropperImage);
  };

  const handleCancelCrop = () => {
    if (!cropper) return;
    cropper.reset();
    cancel();
  };

  return (
    <>
      <Grid container className={classes.cropperHolder} ref={wrapper}>
        <Grid
          container
          item
          xs={12}
          justifyContent={"flex-end"}
          alignContent={"flex-start"}
          style={{ marginBottom: theme.spacing(1) }}
        >
          <Button
            size={"small"}
            color={"primary"}
            variant="outlined"
            onClick={handleCancelCrop}
          >
            {" "}
            Cancel crop
          </Button>
          <Button
            size={"small"}
            color={"primary"}
            variant="contained"
            style={{
              marginLeft: "8px",
            }}
            onClick={handleCropConfirm}
            disabled={!cropperImage}
          >
            {" "}
            Crop
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Cropper
            className={classes.spotCropper}
            src={image}
            viewMode={2}
            guides={true}
            background={false}
            //responsive={true}
            zoomable={false}
            scalable={false}
            autoCrop={true}
            autoCropArea={1}
            checkOrientation={true}
            onInitialized={(instance) => {
              setCropper(instance);
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
