import {
  Button,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { keyBy, sortBy } from "lodash/fp";
import { useEffect, useRef, useState } from "react";
import { makeStyles, withStyles } from "tss-react/mui";

import { useBreakpoints } from "@/util/useBreakpoints";
import { ConnectedTableSortLabel, useTableSort } from "@/util/useTableSort";

import {
  BACKG_GREY,
  DeviceRow,
  LockRow,
} from "@/pages/Settings/LocationSettings/Camera/DeviceRow";

import { ErrorMessage } from "@/components/ErrorMessage";
import { AutoActivateStreamAction } from "@/components/Genius/AutoActivateStreamAction";
import { GeniusPopup } from "@/components/Genius/GeniusPopup";
import { Loading } from "@/components/Loading";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  CameraStatus,
  ConnectionValidation as Validation,
  DeviceListQuery,
  LifecycleStates,
  useDeviceListQuery,
} from "@/generated-models";

export enum DeviceStatusV2 {
  NotACamera = "01-Not A Camera",
  Deleting = "02-Deleting",
  DeviceUnauthenticated = "03-Not Authenticated",
  DeviceNeedsStream = "04-Needs Stream Selection",

  NvrNoActivatedCameras = "05-Nvr No Activated Cameras",
  CameraDeactivated = "06-Camera Deactivated",
  CameraOnline = "07-Camera Online",
  Nvr = "08-Nvr Online",
  CameraOffline = "09-Camera Offline",
  NvrSomeOffline = "10-Nvr Offline",
}

export function useDeviceStatusAction(device: MappedDevice, lockRow: LockRow) {
  switch (device.statusv2) {
    case DeviceStatusV2.NotACamera:
      return ["Camera not detected", undefined];
    case DeviceStatusV2.DeviceUnauthenticated:
      return ["Not Authenticated", "Enter Credentials"];
    case DeviceStatusV2.DeviceNeedsStream:
      return [
        <strong>Authenticated</strong>,
        <AutoActivateStreamAction
          deviceId={device.id}
          name={device.name}
          lockRow={lockRow}
        />,
      ];
    case DeviceStatusV2.CameraDeactivated:
      return [
        "Deactivated",
        <Typography
          style={{
            fontWeight: "bold",
            color: "RGBA(0, 124, 228, 1.00)",
            fontSize: 14,
          }}
        >
          Reactivate Camera
        </Typography>,
      ];
    case DeviceStatusV2.CameraOnline:
      return [
        <Chip
          style={{ background: "#7DD679" }}
          label={
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: 14,
                color: "white",
              }}
            >
              Online
            </Typography>
          }
        />,
        undefined,
      ];
    case DeviceStatusV2.CameraOffline:
      return [
        <Chip
          style={{ background: "#DE6969" }}
          label={
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: 14,
                color: "white",
              }}
            >
              Offline
            </Typography>
          }
        />,
        "Check Camera Connectivity",
      ];
    case DeviceStatusV2.Nvr:
      return [
        <Tooltip title="All configured channels are online">
          <Chip
            style={{ background: "#7DD679" }}
            label={
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: 14,
                  color: "white",
                }}
              >
                {`Healthy`}
              </Typography>
            }
          />
        </Tooltip>,
        undefined,
      ];
    case DeviceStatusV2.NvrSomeOffline:
      return [
        <Tooltip title="One or more configured channels are offline">
          <Chip
            style={{ background: "#DE6969" }}
            label={
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: 14,
                  color: "white",
                }}
              >
                {`Unhealthy`}
              </Typography>
            }
          />
        </Tooltip>,
        "Review Unhealthy Channels",
      ];
    case DeviceStatusV2.NvrNoActivatedCameras:
      return [
        <strong>Authenticated</strong>,
        `Review ${device.isFisheye ? "Fisheye" : "NVR"} Channels`,
      ];
    case DeviceStatusV2.Deleting:
      return ["Deleting...", ""];
  }
}

export function getDeviceStatusV2(
  device: Pick<DeviceFromListQuery, "isNvr" | "isCameraVendor" | "status"> & {
    cameras: Pick<
      DeviceFromListQuery["cameras"][number],
      "lifecycleState" | "status"
    >[];
  }
) {
  if (!device.isCameraVendor) {
    return DeviceStatusV2.NotACamera;
  }

  if (device.isNvr) {
    const activeNvrDevices = device.cameras.filter(
      (cam) => cam.lifecycleState === LifecycleStates.Enabled
    ).length;
    const onlineNvrDevices = device.cameras.filter(
      (cam) =>
        cam.lifecycleState === LifecycleStates.Enabled &&
        cam.status === CameraStatus.Online
    ).length;
    if (activeNvrDevices === 0) {
      return DeviceStatusV2.NvrNoActivatedCameras;
    }
    return activeNvrDevices === onlineNvrDevices
      ? DeviceStatusV2.Nvr
      : DeviceStatusV2.NvrSomeOffline;
  }

  const cam =
    device.cameras.find(
      (cam) => cam.lifecycleState !== LifecycleStates.Deleted
    ) ?? device.cameras[0];
  if (cam) {
    switch (cam.lifecycleState) {
      case LifecycleStates.Enabled:
        return cam.status === CameraStatus.Offline
          ? DeviceStatusV2.CameraOffline
          : DeviceStatusV2.CameraOnline;
      case LifecycleStates.Deleting:
        return DeviceStatusV2.Deleting;
      default:
        return DeviceStatusV2.CameraDeactivated;
    }
  }
  return device.status === Validation.Ok
    ? DeviceStatusV2.DeviceNeedsStream
    : DeviceStatusV2.DeviceUnauthenticated;
}

const BORDER_BLUE = "#a4cdf0";
const BACKG_BLUE = "#ecf7ff";
const useStyles = makeStyles()((theme) => ({
  activeRow: {
    opacity: 1,
    "& > td": {
      backgroundColor: `${BACKG_BLUE} !important`,
      borderColor: BORDER_BLUE,
    },
  },
}));

type DeviceFromListQuery = NonNullable<
  DeviceListQuery["location"]
>["devices"][number];
export type MappedDevice = ReturnType<typeof mapDevice>;
function mapDevice({ __typename, cameras, ...device }: DeviceFromListQuery) {
  // const cameras = (data?.location?.cameras ?? [])
  //   // Find cameras connected to this device
  //   .filter((cam) => cam.deviceId === device.id)
  //   // Filter deleted cameras
  //   .filter((cam) =>
  //     [LifecycleStates.Disabled, LifecycleStates.Enabled].includes(
  //       cam.lifecycleState
  //     )
  //   );

  const type = device.isNvr ? "Nvr" : cameras.length > 0 ? "Camera" : "Device";
  const cam = type === "Camera" ? cameras[0] : null;
  const d = {
    ...device,
    type: type as "Nvr" | "Camera" | "Device" | "Manual",
    cameras,
    isCameraVendor: cameras.length > 0 || device.isCameraVendor,
    vendor: cam ? cam.vendor : device.vendor,
    // path: cam ? cam.path : device.path,
    still:
      cam?.still ??
      device.channels.find((channel) => Boolean(channel.still))?.still,
    name: getDeviceName({
      isNvr: device.isNvr,
      isFisheye: device.isFisheye,
      isCameraVendor: device.isCameraVendor,
      camName: cameras[0]?.name,
    }),
    active: cam
      ? cam.lifecycleState === LifecycleStates.Enabled
      : !!cameras.length,
    firstSegmentTime: cam?.firstSegmentTime ?? null,
  } as const;
  const statusv2 = getDeviceStatusV2(d);
  return { ...d, statusv2 };
}

interface CameraSetupTableProps {
  locationId: number;
  searchInput: string | null;
  startScan: () => Promise<unknown>;
}
export function CameraSetupTable({
  locationId,
  searchInput,
  startScan,
}: CameraSetupTableProps) {
  const { classes } = useStyles();
  const { fitsTablet } = useBreakpoints();
  const [showOthers, setShowOthers] = useState(false);
  const [popupDeviceId, setPopupDeviceId] = useState<number | null>(null);
  const itemsRef = useRef<(HTMLTableRowElement | null)[]>([]);
  // you can access the elements with itemsRef.current[n]

  // This state can be used to lock a row to a specific index in the table. Used for transitions betweens types.
  const [lockedMacs, lockMacs] = useState<
    { mac: string; index: number; dontExpand?: boolean }[]
  >([]);

  const [initialScan, setInitialScan] = useState(false);

  // force refresh from appliance before grabbing the device list
  useEffect(() => {
    (async () => {
      await startScan();
      setInitialScan(true);
    })();
  }, [startScan]);

  // Fetch deviceScans and added cameras
  const { data, error } = useDeviceListQuery({
    variables: { locationId },
    skip: !initialScan,
    ...refetchOnMountPolicy,
    // pollInterval: 10000,
  });

  // Map device scans, cameras and NVRs to match types
  const mappedDevices = data?.location?.devices?.map(mapDevice) ?? [];
  const sortedDevices = mappedDevices
    .filter((d) => d.type === "Nvr")
    .concat(mappedDevices.filter((d) => d.type === "Camera"))
    .concat(mappedDevices.filter((d) => d.type === "Device"));

  // Filter other devices if needed
  const cameraDevices = sortedDevices.filter((d) => d.isCameraVendor);
  const devices = showOthers ? sortedDevices : cameraDevices;

  // Show other devices if table is otherwise empty
  const onlyOtherRows =
    cameraDevices.length === 0 && Number(data?.location?.devices.length) > 0;
  useEffect(() => {
    if (onlyOtherRows) setShowOthers(true);
  }, [onlyOtherRows]);

  // Sort scan results
  const { sortedRows, ...labelParams } = useTableSort(devices, "statusv2", -1);
  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(
      0,
      data?.location?.devices.length || 20
    );
  }, [data?.location?.devices.length]);
  // Error
  if (error) {
    return (
      <ErrorMessage
        title="Oops"
        description="Something went wrong while trying to load the cameras, please try again later."
      />
    );
  }

  // Loading
  if (!data?.location || !sortedRows) {
    return (
      <div style={{ padding: "48px 0", margin: "0 auto" }}>
        <Loading />
      </div>
    );
  }

  // Filter camera rows based on search query
  const searchQuery = searchInput?.trim().toLowerCase();
  const filteredRows = !searchQuery
    ? sortedRows
    : sortedRows.filter((r) =>
        [
          r.name,
          r.vendor,
          r.ip,
          r.mac,
          // r.path,
          // r.username,
          // r.password,
          // r.port.toString(),
          r.statusv2,
        ].some((field) => field.toLowerCase().includes(searchQuery))
      );

  // Show empty state and no matching search results message
  if (filteredRows.length === 0) {
    return (
      <ErrorMessage
        title={
          searchQuery ? "No matching results" : "No cameras found or added yet"
        }
        description={
          searchQuery
            ? "Could not find any cameras matching your search query."
            : "Use the buttons above to discover cameras or add them manually."
        }
      />
    );
  }

  const camRows = filteredRows;
  const lockedRows = [] as typeof camRows;
  // Extract locked rows sorted by locked index
  for (const { mac } of sortBy("index", lockedMacs)) {
    const currentIndex = camRows.findIndex((row) => row.mac === mac);
    if (currentIndex !== -1) {
      const [lockedDevice] = camRows.splice(currentIndex, 1);
      lockedRows.push(lockedDevice);
    }
  }

  // Insert locked rows back into the list at the locked index
  const lockedMacsMap = keyBy("mac", lockedMacs);
  for (const row of lockedRows) {
    const { index } = lockedMacsMap[row.mac];
    camRows.splice(index, 0, row);
  }

  const otherDevicesCount = data.location.devices.filter(
    (s) => !s.isCameraVendor
  ).length;

  const targetIndex =
    (!!popupDeviceId && camRows.findIndex((c) => c.id === popupDeviceId)) || 0;
  return (
    <>
      <StyledTable>
        <TableHead>
          <TableRow>
            <StyledCell>
              <div style={{ marginLeft: 93 }}>
                <ConnectedTableSortLabel name="name" {...labelParams}>
                  Name
                </ConnectedTableSortLabel>
              </div>
            </StyledCell>
            {fitsTablet && (
              <>
                <StyledCell>
                  <ConnectedTableSortLabel name="mac" {...labelParams}>
                    MAC
                  </ConnectedTableSortLabel>
                </StyledCell>
                <StyledCell>
                  <ConnectedTableSortLabel name="ip" {...labelParams}>
                    IP Address
                  </ConnectedTableSortLabel>
                </StyledCell>
                <StyledCell>
                  <ConnectedTableSortLabel name="vendor" {...labelParams}>
                    Vendor
                  </ConnectedTableSortLabel>
                </StyledCell>
                <StyledCell>
                  <ConnectedTableSortLabel name="statusv2" {...labelParams}>
                    Status
                  </ConnectedTableSortLabel>
                </StyledCell>
                <StyledCell>
                  <ConnectedTableSortLabel name="statusv2" {...labelParams}>
                    Action
                  </ConnectedTableSortLabel>
                </StyledCell>
              </>
            )}
            <StyledCell />
          </TableRow>
          <TableRow />
        </TableHead>
        <TableBody>
          {camRows.map((device, index) => (
            <DeviceRow
              rowRef={(el) => {
                itemsRef.current[index] = el;
              }}
              key={`${device.type}-${device.id}`}
              device={device}
              locationId={locationId}
              classNames={
                !!popupDeviceId && device.id === popupDeviceId
                  ? classes.activeRow
                  : undefined
              }
              lockRow={(mac: string) => {
                if (mac.toLowerCase() !== "unknown") {
                  lockMacs((cur) => [
                    ...cur.filter((row) => row.mac !== mac),
                    { mac, index },
                  ]);
                }
              }}
              onClick={(element) => setPopupDeviceId(device.id)}
            />
          ))}
        </TableBody>
      </StyledTable>
      {otherDevicesCount > 0 && !searchQuery && !onlyOtherRows && (
        <Button
          color="primary"
          onClick={() => setShowOthers(!showOthers)}
          style={{ fontWeight: "bold" }}
        >
          {showOthers
            ? "Hide Other Devices"
            : `Show ${otherDevicesCount} Other Devices Found`}
        </Button>
      )}
      {!!popupDeviceId && targetIndex !== null && targetIndex > -1 && (
        <GeniusPopup
          onClose={() => setPopupDeviceId(null)}
          deviceId={popupDeviceId}
          lockRow={(mac: string) => {
            if (!!popupDeviceId) {
              if (mac.toLowerCase() !== "unknown" && targetIndex !== null) {
                lockMacs((cur) => [
                  ...cur.filter((row) => row.mac !== mac),
                  { mac, index: targetIndex },
                ]);
              }
            }
          }}
        />
      )}
    </>
  );
}

function getDeviceName({
  isFisheye,
  isNvr,
  isCameraVendor,
  camName,
}: {
  isNvr: boolean;
  camName: string | undefined;
  isCameraVendor: boolean;
  isFisheye: boolean;
}) {
  if (isFisheye) return "Fisheye";
  if (isNvr) return "NVR";
  if (camName) return camName;
  if (isCameraVendor) return "Camera device";
  return "Other device";
}

const StyledTable = withStyles(Table, (theme) => ({
  root: {
    borderCollapse: "separate",
    borderSpacing: "0 2px",
    "& td": {
      transition: "background-color,border-color 0.1s",
    },
  },
}));

export const StyledCell = withStyles(TableCell, (theme) => ({
  root: {
    backgroundColor: "transparent",
    fontWeight: 400,
    border: `solid 1px ${BACKG_GREY}`,
    padding: "0 12px 0 0",
    "&:not(:first-of-type)": {
      borderLeft: 0,
    },
    "&:not(:last-of-type)": {
      borderRight: 0,
    },
    "&:first-of-type": {
      borderRadius: "4px 0 0 4px",
    },
    "&:last-of-type": {
      borderRadius: "0 4px 4px 0",
    },
  },
  head: {
    padding: "2px 0",
    borderColor: "#e8e8e8",
    backgroundColor: "initial",
  },
}));
