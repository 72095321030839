import { NumberParam, withDefault } from "use-query-params";

import { InterestListType } from "@/generated-models";

export enum InterestListFormMode {
  create,
  edit,
  view,
  insert,
}

export const InterestListMode = withDefault(
  NumberParam,
  InterestListFormMode.view
);

const prompts = {
  lprAddToList:
    "Add this license plate to an existing list or create a list with this license plate.",
  lprCreate:
    "Create a title for this list, add license plates manually or upload a CSV file of license plates, and add notes for each license plate if needed.",
  lprNote:
    "Add a custom note to this license plate. This can be used to add details about the owner of this vehicle or any other relevant information.",
  lprSelect:
    "Select or create a list of license plates of interest. These lists can be used to create a license plate of interest insight and/or alerts.",
};

export type InterestListConfig = {
  addToListPrompt: string;
  createPrompt: string;
  featureName: string;
  notePrompt: string;
  resourceName: string;
  resourceShortName: string;
  selectPrompt: string;
  tableResourceName: string;
};

export const interestListConfig: Record<
  InterestListType,
  InterestListConfig
> = {
  lpr: {
    addToListPrompt: prompts.lprAddToList,
    createPrompt: prompts.lprCreate,
    featureName: "License Plates of Interest",
    notePrompt: prompts.lprNote,
    resourceName: "license plate",
    resourceShortName: "plate",
    selectPrompt: prompts.lprSelect,
    tableResourceName: "Plate Number",
  },
};
