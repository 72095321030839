import CheckIcon from "@mui/icons-material/Check";
import {
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  TextField,
} from "@mui/material";
import clsx from "clsx";
import { keyBy } from "lodash/fp";
import { useEffect, useMemo } from "react";
import { Controller, useController } from "react-hook-form";

import { BASE_CONNECT_INPUT_PROPS, PLACEHOLDER_ID } from "../../constant";
import { useIntegrationTypes } from "../../hooks/integrationHooks";

function IntegrationTypeField({
  className,
  disabled,
}: {
  className?: string;
  disabled?: boolean;
}) {
  const { data, loading } = useIntegrationTypes();
  const {
    field: { value, onChange },
  } = useController({
    name: "type",
  });

  const optionsWithoutPOS = useMemo(() => {
    return data.filter((d) => d.name !== "pos");
  }, [data]);

  const options = useMemo(() => {
    return optionsWithoutPOS.map((d) => ({
      label: d.label,
      value: d.id,
    }));
  }, [optionsWithoutPOS]);

  const optionsLabel = keyBy("id", optionsWithoutPOS);

  useEffect(() => {
    if ((!value || value < 0) && options.length > 1) {
      onChange(options[0].value);
    }
  }, [onChange, options, value]);

  return (
    <FormControl
      size="small"
      fullWidth
      className={clsx("w-full flex-1", className)}
    >
      <InputLabel variant="outlined" className="-my-3 -mx-3" shrink>
        Integration Type
      </InputLabel>

      <Controller
        name="type"
        render={({ field }) => (
          <>
            {loading && (
              <Skeleton
                variant="rectangular"
                className="w-[230px] h-[45px] rounded-lg"
              />
            )}
            {!loading && (
              <Select
                variant="outlined"
                className="rounded-lg"
                disabled={disabled}
                input={
                  <OutlinedInput
                    notched={false}
                    className="bg-white h-[45px] w-[230px]"
                    label="string"
                  />
                }
                value={field.value || 1}
                renderValue={(v) => {
                  return optionsLabel[v]?.label || v;
                }}
                onBlur={field.onBlur}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
              >
                <MenuItem value={PLACEHOLDER_ID} />;
                {options.map((option: any) => {
                  const selected = field.value === option.value;
                  return (
                    <MenuItem
                      className={clsx("py-2", {
                        "text-white bg-primary": selected,
                      })}
                      value={option.value}
                      key={option.value}
                    >
                      <ListItemIcon
                        className={clsx({
                          invisible: !selected,
                        })}
                      >
                        <CheckIcon className="text-white" />
                      </ListItemIcon>
                      <ListItemText primary={option.label} />
                    </MenuItem>
                  );
                })}
              </Select>
            )}
          </>
        )}
      />
    </FormControl>
  );
}

function IntegrationNameField() {
  return (
    <FormControl>
      <Controller
        name="name"
        render={({ field }) => (
          <TextField
            label="Name"
            classes={{
              root: "mt-4 max-w-[230px]",
            }}
            className="grow"
            placeholder="Name"
            {...BASE_CONNECT_INPUT_PROPS}
            {...field}
          />
        )}
      />
    </FormControl>
  );
}

export function IntegrationDetailsFormItem({ edit }: { edit?: boolean }) {
  return (
    <div className="flex flex-col sm:flex-row items-start gap-4">
      <IntegrationNameField />
      <IntegrationTypeField className="mt-4" disabled={edit} />
    </div>
  );
}
