import { Monaco } from "@monaco-editor/react";
import { IRange, editor } from "monaco-editor";

import {
  ASSISTANT_COMMANDS,
  ASSISTANT_RESOURCE,
  PATTERN_VARIABLE,
} from "@/components/Assistant/utils/editor/constant";

import { Page_AssistantQuery } from "@/generated-models";

function getUrl(id: number, childPath: string) {
  const url = window.location.href;
  const path = url.substring(0, url.lastIndexOf("/"));
  return `${path}${childPath}${id}`;
}

function getCameraHoverMessage(
  resource: Page_AssistantQuery["cameras"][number]
) {
  return [
    {
      value: `**${resource.name}** (${resource?.status})`,
    },
    { value: resource?.location?.name },
    {
      value: `[View Live](${getUrl(resource.id, "/live?cams=")})`,
    },
    {
      value: `![${resource?.name}](${resource?.still})`,
    },
  ];
}

function getApplianceHoverMessage(
  resource: Page_AssistantQuery["appliances"][number]
) {
  const status = resource?.health?.online ? "online" : "offline";
  return [
    {
      value: `**${resource.serialNumber}** (${resource.version}) (${status})`,
    },
    { value: resource?.location?.name },
  ];
}

function getLocationHoverMessage(
  resource: Page_AssistantQuery["cameras"][number]["location"]
) {
  return [
    {
      value: `**${resource.name}**`,
    },
    {
      value: `[View Location](${getUrl(resource.id, "/configure/locations/")})`,
    },
  ];
}

function getHoverMessage(type: ASSISTANT_RESOURCE) {
  switch (type) {
    case ASSISTANT_RESOURCE.camera:
      return getCameraHoverMessage;
    case ASSISTANT_RESOURCE.location:
      return getLocationHoverMessage;
    default:
      return getApplianceHoverMessage;
  }
}

export function registerInjectResourceCommand(
  editor: editor.IStandaloneCodeEditor,
  monaco: Monaco
) {
  monaco.editor.registerCommand(
    ASSISTANT_COMMANDS.INJECT_RESOURCE,
    (_, ...args) => {
      // Arguments are injected differently based on how the command is called.
      const [type, text, resource, range] = Array.isArray(args[0])
        ? args[0]
        : args;

      const computedRange = {
        ...range,
        endColumn: range.endColumn + text.length,
      };

      editor.createDecorationsCollection([
        {
          range: computedRange,
          options: {
            inlineClassName:
              "text-primary bg-primary bg-opacity-10 font-mono before:content-['hello']",
            hoverMessage: getHoverMessage(type)(resource),
            stickiness: 1,
          },
        },
      ]);
    }
  );
}

export function onEditorContextMenu(
  editor: editor.IStandaloneCodeEditor,
  onMatchedVariable: (
    type: ASSISTANT_RESOURCE,
    element: Element,
    range: IRange
  ) => void
) {
  editor.onContextMenu((e) => {
    const { target } = e;

    e.event.stopPropagation();
    e.event.preventDefault();

    if (target.position && target.element) {
      const wordAtPosition = editor
        ?.getModel()
        ?.getWordAtPosition(target.position);

      const match = wordAtPosition?.word?.match(PATTERN_VARIABLE);

      // If we match a variable
      if (match && wordAtPosition) {
        onMatchedVariable(
          match[1].toLowerCase() as ASSISTANT_RESOURCE,
          target.element,
          {
            ...target.range!,
            endColumn: wordAtPosition.endColumn,
            startColumn: wordAtPosition.startColumn,
          }
        );
      }
    }
  });
}
