import React from "react";

import { useCameraMotionZone } from "@/pages/Search/searchHooks";

interface MotionZoneOverlayProps {
  cameraId: number;
}

export function MotionZoneOverlay({ cameraId }: MotionZoneOverlayProps) {
  const { activeShape } = useCameraMotionZone(cameraId);

  if (!activeShape) return null;

  return (
    <svg
      className="absolute w-full h-full"
      viewBox="0 0 100 100"
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polygon
        points={activeShape.map((p) => `${p.x}, ${p.y}`).join(" ")}
        stroke="#ef00b6"
        strokeWidth={2}
        vectorEffect="non-scaling-stroke"
        fill="#ef00b6"
        fillOpacity={0}
      />
    </svg>
  );
}
