import VolumeUp from "@mui/icons-material/VolumeUp";
import { IconButton } from "@mui/material";
import Divider from "@mui/material/Divider";

import { useAlertRoutes } from "@/pages/Alerts/utils";

import { useMe } from "@/components/Auth";
import { CloseIcon } from "@/components/Player/PlayerIcons";
import { QueryParamLink } from "@/components/shared/QueryParamLink";

import { useSetFlagsMutation } from "@/generated-models";

export function AlertSnackbarActions({
  handleClose,
}: {
  handleClose: () => void;
}) {
  const me = useMe();
  const [setFlags, { loading: settingFlags }] = useSetFlagsMutation();
  const alertRoutes = useAlertRoutes();
  const showAudioAlerts = me?.profile?.flags?.audioAlerts;

  return (
    <div className="w-full md:w-[114px] gap-[10px] flex items-center justify-end leading-none border-t border-[#3A5170] md:border-t-0 pt-2">
      <button
        className="bg-transparent"
        disabled={settingFlags}
        onClick={() => {
          setFlags({
            variables: {
              flags: {
                audioAlerts: !showAudioAlerts,
              },
            },
          });
        }}
      >
        <VolumeUp
          className={`hover:cursor-pointer h-4 ${
            showAudioAlerts && "text-[#5DA5E0]"
          }`}
        />
      </button>

      <Divider
        orientation="vertical"
        flexItem
        sx={{
          bgcolor: "#5DA5E0",
        }}
      />
      <QueryParamLink className="text-[#5DA5E0]" to={alertRoutes.ALERTS_BASE}>
        View
      </QueryParamLink>
      <Divider
        orientation="vertical"
        flexItem
        sx={{
          bgcolor: "#5DA5E0",
        }}
      />
      <div className="flex items-center">
        <IconButton onClick={handleClose} color="inherit" size="small">
          <CloseIcon
            fontSize="small"
            sx={{
              color: "#5DA5E0",
              fontSize: "15px",
            }}
          />
        </IconButton>
      </div>
    </div>
  );
}
