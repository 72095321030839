import ArrowDownIcon from "@mui/icons-material/ArrowDownward";
import clsx from "clsx";
import { Fragment } from "react";
import { useSearchParams } from "react-router-dom";

import { SearchParamLink } from "@/components/shared/QueryParamLink";

interface SortBarProps {
  param: string;
  options: { label: string; prefix: string; defaultOrder: "asc" | "desc" }[];
  defaultValue: string;
}

export function SortBar({ param, options, defaultValue }: SortBarProps) {
  const [search] = useSearchParams();
  const value = search.get(param) || defaultValue;
  const [sort, rawOrder] = value.split("_");
  const order = rawOrder === "desc" ? "desc" : "asc";

  return (
    <div className="flex items-center gap-2 text-sm">
      <div className="text-xs opacity-50">Sort by:</div>
      {options.map(({ label, prefix, defaultOrder }, index) => {
        const active = sort === prefix;
        return (
          <Fragment key={prefix}>
            <SearchParamLink
              params={{
                [param]: `${prefix}_${
                  active && order === defaultOrder
                    ? getOppositeOrder(order)
                    : defaultOrder
                }`,
              }}
              replace
              className={clsx(
                "flex items-center text-primary text-sm leading-none",
                { "font-bold border-b border-primary": active }
              )}
            >
              {label}
              {active && (
                <ArrowDownIcon
                  className={clsx("h-3 w-3 ml-px", {
                    "rotate-180": order === "asc",
                  })}
                />
              )}
            </SearchParamLink>
            {options.length > index + 1 && (
              <hr className="h-2 w-px bg-[#DADADA]" />
            )}
          </Fragment>
        );
      })}
    </div>
  );
}

function getOppositeOrder(order: "asc" | "desc") {
  return order === "asc" ? "desc" : "asc";
}
