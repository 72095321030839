import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Drawer, Tooltip } from "@mui/material";
import clsx from "clsx";
import gql from "graphql-tag";
import { Provider as JotaiProvider } from "jotai";
import React, { PropsWithChildren, useMemo, useState } from "react";

import { useBreakpoints } from "@/util/useBreakpoints";

import { CamDrawerGroupSelector } from "@/components/CamDrawer/CamDrawerGroupSelector";
import { CamDrawerList } from "@/components/CamDrawer/CamDrawerList";
import { CamDrawerListControls } from "@/components/CamDrawer/CamDrawerListControls";
import { CamDrawerSearchBox } from "@/components/CamDrawer/CamDrawerSearchBox";
import { CamDrawerSelectedOnlyFilter } from "@/components/CamDrawer/CamDrawerSelectedOnlyFilter";

import { CamDrawerBaseQuery } from "@/generated-models";

export interface CamDrawerBaseProps {
  open?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  children: React.ReactNode;
}

interface CamDrawerButtonProps {
  isOpen?: boolean;
  handleOpen?: () => void;
  handleClose?: () => void;
}

function DesktopCamDrawerButton({
  isOpen,
  handleOpen,
  handleClose,
}: CamDrawerButtonProps) {
  return (
    <button
      aria-label={isOpen ? "Close" : "Open"}
      type="button"
      style={{
        height: "100%",
        backgroundImage:
          "linear-gradient(rgb(0, 124, 228) 35.85%, rgb(128, 0, 228) 100%)",
      }}
      className="p-0 border-none outline-none flex-shrink-0 flex-center w-[57px] text-white"
      onClick={() => (isOpen ? handleClose?.() : handleOpen?.())}
    >
      {isOpen ? (
        <ChevronRight className="text-[44px]" />
      ) : (
        <ChevronLeft className="text-[44px]" />
      )}
    </button>
  );
}

function MobileCamDrawerButton({
  isOpen,
  handleOpen,
  handleClose,
}: CamDrawerButtonProps) {
  return (
    <button
      aria-label={isOpen ? "Close" : "Open"}
      type="button"
      className={clsx(
        "p-0 border-none outline-none flex-shrink-0 flex-center",
        "w-full h-[42px] bg-primary text-white rounded-t-xl mt-2 font-medium",
        "text-lg leading-[21px]"
      )}
      style={{
        boxShadow: "0px -4px 9px rgba(0, 0, 0, 0.25)",
      }}
      onClick={() => (isOpen ? handleClose?.() : handleOpen?.())}
    >
      <div className="flex items-center justify-center w-full">
        <div className="flex-1">Select a Camera</div>
        <CloseIcon className="text-white w-5 h-5 ml-auto mr-3" />
      </div>
    </button>
  );
}

function CamDrawerBase({
  open = false,
  onOpen,
  onClose,
  children,
}: CamDrawerBaseProps) {
  const { fitsDesktop } = useBreakpoints();
  const [internalIsOpen, setInternalIsOpen] = useState(open);

  // "Mixed" component - one that can be both controlled and uncontrolled
  const isOpen = open === undefined ? internalIsOpen : open;
  const handleOpen = useMemo(() => {
    if (open === undefined) return () => setInternalIsOpen(true);
    return onOpen;
  }, [open, onOpen, setInternalIsOpen]);
  const handleClose = useMemo(() => {
    if (open === undefined) return () => setInternalIsOpen(false);
    return onClose;
  }, [open, onClose, setInternalIsOpen]);

  const drawerClasses = {
    "w-[400px] max-w-[100%] duration-[225ms]": open,
    "w-0 md:w-[57px] duration-[195ms]": !open,
    "transition-[width] flex-row": true,
  };

  const mobileDrawerClasses = {
    "h-full duration-[225ms]": open,
    "h-0 duration-[195ms]": !open,
    "transition-[height] flex-col": true,
  };

  const camDrawerProps = {
    isOpen,
    handleClose,
    handleOpen,
  };

  return (
    <JotaiProvider>
      <Drawer
        variant="permanent"
        anchor={fitsDesktop ? "right" : "bottom"}
        // todo: boxShadow: "0 0 10px 0 rgba(0,0,0,0.4)",
        className="flex flex-row flex-shrink-0 border-l-[initial] shadow overflow-hidden absolute"
        classes={{
          paper: clsx(
            "border-none flex overflow-hidden b-0 t-0",
            fitsDesktop ? drawerClasses : mobileDrawerClasses
          ),
        }}
      >
        <Tooltip title={isOpen ? "Close" : "Open"} placement="left">
          <span>
            {fitsDesktop ? (
              <DesktopCamDrawerButton {...camDrawerProps} />
            ) : (
              <MobileCamDrawerButton {...camDrawerProps} />
            )}
          </span>
        </Tooltip>
        <section
          className="flex flex-col grow h-full overflow-hidden"
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              event.stopPropagation();
              return false;
            }
          }}
        >
          {children}
        </section>
      </Drawer>
    </JotaiProvider>
  );
}

export const CamDrawer = Object.assign(CamDrawerBase, {
  Filters: CamDrawerFilters,
  List: CamDrawerList,
  GroupSelector: CamDrawerGroupSelector,
  SearchBox: CamDrawerSearchBox,
  SelectedOnlyFilter: CamDrawerSelectedOnlyFilter,
  ListControls: CamDrawerListControls,
});

function CamDrawerFilters({
  className,
  children,
}: PropsWithChildren<{ className?: string }>) {
  return (
    <div
      className={clsx(
        "flex flex-col gap-2.5 pt-6 pb-3 px-4 md:pt-[88px] bg-white",
        className
      )}
    >
      {children}
    </div>
  );
}

export type DrawerCam = CamDrawerBaseQuery["cameras"][number];

type StatefulDrawerCamWithoutSettings = Omit<DrawerCam, "settings">;

export interface StatefulDrawerCam extends StatefulDrawerCamWithoutSettings {
  selected?: boolean;
  disabled?: boolean;
  settings?: DrawerCam["settings"] | undefined;
}

gql`
  query camDrawerBase {
    cameras {
      id
      name
      status
      location {
        id
        name
      }
      tags {
        id
        name
      }
      settings {
        onvifBackchannelEnabled
      }
    }
  }
`;
