import {
  CloseOutlined,
  ErrorOutlineOutlined,
  OpenInNewOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  LinearProgress,
  Link,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import gql from "graphql-tag";
import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";

import { useDeviceProxyLink } from "@/util/useDeviceProxyLink";
import { useLocalStorage } from "@/util/useLocalStorage";

import DeviceCredentials from "@/pages/Onboarding/ApplianceProxy/DeviceCredentials";
import { DeviceSettingsInfo } from "@/pages/Onboarding/ApplianceProxy/DeviceSettingsInfo";

import { useFeedback } from "@/components/SnackbarProvider";
import { Logo } from "@/components/shared/Logo";

import { useApplianceTokenQuery } from "@/generated-models";

const useStyles = makeStyles()((theme) => ({
  iframe: {
    flexGrow: 1,
    border: 0,
    zIndex: 2,
  },
  background: {},
  separator: {
    fontSize: 14,
    paddingLeft: 16,
    paddingRight: 16,
    color: "#E0E0E0",
  },
}));

function authorizeAppliance(serialNumber: string, token: string) {
  return fetch(`https://${serialNumber}.s1.spotai.co/apitoken`, {
    method: "post",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },

    body: JSON.stringify({
      token,
    }),
  });
}

interface ProxyProps {
  applianceSerial: string;
  proxyEndpoint?: string;
  deviceId?: number;
}
export default function Proxy({
  applianceSerial,
  proxyEndpoint,
  deviceId,
}: ProxyProps) {
  const { classes } = useStyles();
  const feedback = useFeedback();
  const [fallbackOriginalProxy, setFallbackOriginalProxy] = useLocalStorage(
    "fallbackProxy",
    false
  ); //defaults true if cloudproxy
  const useCloudproxy = !fallbackOriginalProxy;
  const [loaded, setLoaded] = useState(useCloudproxy); //defaults true if cloudproxy
  const [authError, setAuthError] = useState<string | undefined>(undefined);
  const targetSplit = proxyEndpoint?.split(":");
  const [iframeLoading, setIframeLoading] = useState(true); //defaults true if cloudproxy

  const mode = proxyEndpoint && proxyEndpoint !== "" ? "camera" : "appliance";
  const proxyLink = useDeviceProxyLink(applianceSerial, proxyEndpoint);

  useApplianceTokenQuery({
    variables: {
      serialNumber: applianceSerial,
      authInput: {
        proxyEndpoint,
      },
    },
    onCompleted: async ({
      applianceFromSerial: {
        authToken: { token },
      },
    }) => {
      try {
        await authorizeAppliance(applianceSerial, token);
      } catch (error) {
        setAuthError(
          `Could not authorize appliance, please check that appliance ${applianceSerial} is online.`
        );
      }
      setLoaded(true);
    },
    onError: (error) => {
      setAuthError(
        `Could not authorize appliance, please check that appliance ${applianceSerial} is online.`
      );
      setLoaded(true);
    },
    skip: useCloudproxy,
  });

  useEffect(() => {
    if (useCloudproxy) return;
    if (authError) {
      console.log(authError);
    }
  }, [useCloudproxy, authError, feedback]);

  return (
    <div className="flex flex-col w-full h-full">
      {mode === "camera" && (
        <Box
          width="100%"
          height="50px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{
            boxSizing: "border-box",
            background: "#FFECEC",
            boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2)",
            zIndex: 6,
          }}
        >
          <ErrorOutlineOutlined style={{ color: "#9C1818" }} />
          <Typography className="pl-[10px] pr-5 text-[#9C1818] font-normal text-base mt-0.5">
            Some native camera features may not be remotely available based on
            the camera's system and capabilities.
          </Typography>
          <Button
            classes={{
              outlined:
                "border-[#930000]/[.51] bg-[#930000]/[.11] text-[#930000] hover:bg-[#930000]/[.25]",
            }}
            size="small"
            variant="outlined"
            href={`http://${proxyEndpoint}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Access via LAN
          </Button>
        </Box>
      )}
      <div
        className={clsx(
          "flex items-center w-full py-1 z-10 bg-white box-border",
          {
            "shadow-[0_4px_8px_0_rgba(0,0,0,0.2)]": mode === "appliance",
          }
        )}
      >
        <Logo className="px-8 h-7" />
        <Box display="flex" flexDirection="column" className="mr-4">
          <Typography variant="h1">
            {mode === "camera" ? "Native Camera" : "Appliance"} Configuration
          </Typography>
          <Typography className="text-sm leading-[16px]">
            {mode === "appliance" ? (
              <>
                Accessing network tools for <u>{applianceSerial}</u>
              </>
            ) : (
              <>
                Proxying <strong>{proxyEndpoint}</strong> through{" "}
                <strong>{applianceSerial}</strong>
              </>
            )}
          </Typography>
        </Box>
        {mode === "camera" && deviceId && targetSplit && (
          <DeviceCredentials
            deviceId={deviceId}
            targetIp={targetSplit[0]}
            className="px-[53px]"
          />
        )}
        {mode === "camera" && (
          <DeviceSettingsInfo
            deviceId={deviceId}
            applianceSerial={applianceSerial}
          />
        )}

        <IconButton
          size="small"
          style={{ marginBottom: 15, marginLeft: 10, marginRight: 20 }}
          onClick={() => window.close()}
        >
          <CloseOutlined style={{ fontSize: 14 }} />
        </IconButton>
      </div>
      {localStorage.internalTools === "true" && (
        <div className="flex justify-end pr-2 bg-white py-2">
          <Button
            size="small"
            color="secondary"
            variant="contained"
            style={{ marginLeft: 15 }}
            onClick={() => {
              setFallbackOriginalProxy(!fallbackOriginalProxy);
              window.location.reload();
            }}
          >
            (internal){" "}
            {fallbackOriginalProxy
              ? "Switch to WebRTC proxy"
              : "Switch to legacy proxy"}
          </Button>
        </div>
      )}
      {mode === "camera" && (
        <div className="flex items-center justify-center w-full h-[35px] box-border bg-[#f4f4f4] z-10">
          <Typography className="text-sm" variant="body1">
            Is the page below not working correctly?
          </Typography>
          <OpenInNewOutlined className="text-lg ml-6" color="primary" />
          <Link
            className="text-sm ml-2 font-medium"
            color="primary"
            href={proxyLink}
            target="_blank"
            rel="noopener noreferrer"
            underline="hover"
          >
            Expand page in new tab
          </Link>
        </div>
      )}
      {iframeLoading && <LinearProgress />}
      {authError ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          flexGrow={1}
        >
          <Typography variant="body1">{authError}</Typography>
        </Box>
      ) : loaded ? (
        <iframe
          onLoadStart={() => setIframeLoading(true)}
          onLoad={() => setIframeLoading(false)}
          sandbox="allow-modals allow-forms allow-popups allow-scripts allow-downloads allow-same-origin"
          title="Camera Proxy"
          name={`${Date.now()}`}
          className={classes.iframe}
          src={proxyLink}
        />
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          flexGrow={1}
        >
          <CircularProgress />
          <Box m={1} />
          <Typography variant="body1">
            Authenticating with your appliance
          </Typography>
        </Box>
      )}
    </div>
  );
}

gql`
  query applianceToken($serialNumber: String!, $authInput: AuthTokenInput!) {
    applianceFromSerial(serialNumber: $serialNumber) {
      authToken(input: $authInput) {
        token
      }
    }
  }
`;

gql`
  query getDeviceCredentials($deviceId: Int!) {
    deviceScan(id: $deviceId) {
      ip
      username
      password
    }
  }
`;
