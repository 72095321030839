import { Typography } from "@mui/material";
import clsx from "clsx";
import { format as tzFormat, utcToZonedTime } from "date-fns-tz";
import { ReactNode } from "react";

import { useBreakpoints } from "@/util/useBreakpoints";

import { useWallclockTime } from "@/components/Player/PlayerBase";

export function PlayerMetadataLayout({
  wallClock,
  clipNavigation,
  liveButtonOrIndicator,
  zoneSwitch,
}: {
  wallClock: ReactNode;
  clipNavigation?: ReactNode;
  liveButtonOrIndicator: ReactNode;
  zoneSwitch?: ReactNode;
}) {
  const { fitsTablet } = useBreakpoints();
  return fitsTablet ? (
    <>
      {wallClock}
      {clipNavigation}
      {zoneSwitch}
      {liveButtonOrIndicator}
    </>
  ) : (
    <div className="w-full grid grid-cols-2 gap-x-2 gap-y-4">
      {wallClock}
      {liveButtonOrIndicator}
      {clipNavigation}
      {zoneSwitch}
    </div>
  );
}

export function WallClock({
  className,
  classes = {},
  hideDate,
  timezone = "UTC",
  dateFormat = "PP",
}: {
  className?: string;
  classes?: {
    date?: string;
    time?: string;
    ampm?: string;
    timeContainer?: string;
  };
  hideDate?: boolean;
  timezone: string;
  dateFormat?: string;
}) {
  const wallclockTime = useWallclockTime() ?? new Date();
  const zonedTime = utcToZonedTime(wallclockTime, timezone);
  return (
    <div
      className={clsx(
        "flex flex-col-reverse sm:flex-col items-start gap-x-2 text-[#bbbbbb]",
        className
      )}
    >
      {!hideDate && (
        <Typography className={clsx("text-base", classes?.date)}>
          {tzFormat(zonedTime, dateFormat, { timeZone: timezone })}
        </Typography>
      )}
      <div className={clsx("flex gap-x-2 items-end", classes?.timeContainer)}>
        <Typography
          className={clsx(
            "font-bold text-lg leading-3 text-[#f9f9f9]",
            classes?.time
          )}
        >
          {tzFormat(zonedTime, "h:mm:ss a", { timeZone: timezone })}
        </Typography>
        <Typography className={clsx("text-base leading-3", classes?.ampm)}>
          {tzFormat(zonedTime, "z", { timeZone: timezone })}
        </Typography>
      </div>
    </div>
  );
}
