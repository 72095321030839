import ClearIcon from "@mui/icons-material/HighlightOff";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import clsx from "clsx";
import { debounce } from "lodash/fp";
import React, { useCallback, useState } from "react";
import { StringParam, useQueryParam } from "use-query-params";

export function MobileFilterSearchBar({
  autoFocus,
  className,
  onClick = () => {},
  onSubmit = () => {},
  onHide = () => {},
}: {
  autoFocus?: boolean;
  className?: string;
  onClick?: () => void;
  onHide?: () => void;
  onSubmit?: () => void;
}) {
  const [searchFilter, setSearchFilter] = useQueryParam("search", StringParam);
  const [value, setValue] = useState(searchFilter);
  const setInput = (result: string) => {
    setSearchFilter(result);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const persistInput = useCallback(debounce(500, setInput), []);

  return (
    <div
      className={clsx(
        "ml-auto w-full pl-4 pr-3 py-3 border-y border-solid border-[#007CE4/[20]] bg-white",
        className
      )}
    >
      <TextField
        autoFocus={autoFocus}
        onClick={onClick}
        onChange={(e) => {
          setValue(e.target.value);
          persistInput(e.target.value);
        }}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            e.stopPropagation();
            e.preventDefault();
            onSubmit();
          }
        }}
        onBlur={() => {
          if (!value) {
            onHide();
          } else {
            onSubmit();
          }
        }}
        value={value}
        inputProps={{
          enterKeyHint: "search",
        }}
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start">
              <div className="bg-[#DDEFFF] rounded-full p-2 mr-1">
                <SearchIcon color="primary" />
              </div>
            </InputAdornment>
          ),
          endAdornment: value && (
            <InputAdornment position="end">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setValue("");
                  setInput("");
                  onHide();
                }}
                aria-label="clear search"
                size="small"
              >
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        fullWidth
      />
    </div>
  );
}
