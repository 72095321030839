import {
  Button,
  Hidden,
  Link as MaterialLink,
  Paper,
  PaperProps,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { lazy, Suspense, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import {
  isDocumentInFullScreenMode,
  useGlobalFullscreenState,
} from "@/util/fullscreen";
import { useBreakpoints } from "@/util/useBreakpoints";

import { isDemoUser, useMe } from "@/components/Auth";
import { DemoHeaderBar } from "@/components/Demo/DemoHeaderBar";
import { TermsBanner } from "@/components/TermsBanner";
import { Logo } from "@/components/shared/Logo";

import { Role } from "@/generated-models";
import { usePrefixOrgSlug } from "@/hooks/useOrgRouteBase";
import { DesktopMenu } from "@/layout/DesktopMenu";

const SupportDrawerButton = lazy(
  () => import("../components/InternalTools/SupportDrawer/SupportDrawer")
);

const useStyles = makeStyles()((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

function HeaderPaper(props: PaperProps) {
  const { classes } = useStyles();
  return (
    <Paper
      component="header"
      elevation={4}
      square
      {...props}
      className={clsx(
        "relative flex items-center bg-white shrink-0 grow-0 h-[70px]",
        classes.root,
        props.className
      )}
    />
  );
}

type HeaderBaseProps = React.PropsWithChildren<{ customLogoSrc?: string }>;
export function HeaderBase({ customLogoSrc, children }: HeaderBaseProps) {
  const { navigationUpdates } = useFlags();
  const [isFullscreen, setIsFullscreen] = useGlobalFullscreenState(
    "isFullscreen"
  );
  useEffect(() => {
    function handler() {
      setIsFullscreen(isDocumentInFullScreenMode());
    }
    document.addEventListener("fullscreenchange", handler);
    return () => document.removeEventListener("fullscreenchange", handler);
  }, [setIsFullscreen]);

  const { fitsLarge, fitsTablet } = useBreakpoints();
  const prefixOrgSlug = usePrefixOrgSlug();

  return (
    <HeaderPaper className={clsx({ hidden: isFullscreen })}>
      <Link
        to={prefixOrgSlug("/")}
        className="ml-5 h-full flex items-center py-3"
      >
        {customLogoSrc ? (
          <img
            src={customLogoSrc}
            alt="Logo"
            className="h-full max-w-[9rem] object-contain"
          />
        ) : (
          <Logo
            className={clsx(
              "h-full",
              navigationUpdates && !fitsLarge ? "max-w-[6rem]" : "max-w-[8rem]"
            )}
          />
        )}
      </Link>
      {children}
      {customLogoSrc && fitsTablet && (
        <div className="flex-center flex-col self-stretch px-4 bg-[#f4f4f4] text-gray-400 text-2xs">
          <Typography variant="caption">powered by</Typography>
          <Logo className="h-6 max-w-none" />
        </div>
      )}
    </HeaderPaper>
  );
}

export function AuthenticatedHeader() {
  const { fitsSmallTablet } = useBreakpoints();
  const me = useMe();
  const isDemo = !!me && isDemoUser(me);

  return (
    <>
      {me &&
        me.role >= Role.Success &&
        localStorage.internalTools === "true" &&
        fitsSmallTablet && (
          <Suspense fallback={null}>
            <SupportDrawerButton />
          </Suspense>
        )}
      {isDemo && <DemoHeaderBar />}
      {fitsSmallTablet && (
        <HeaderBase customLogoSrc={me?.organization.logo ?? undefined}>
          <DesktopMenu />
          <TermsBanner />
        </HeaderBase>
      )}
    </>
  );
}

export function GuestHeader(props: HeaderBaseProps) {
  return (
    <HeaderBase {...props}>
      <Hidden mdDown>
        <div className="grow" />
        <div className="flex-center">
          <Button
            component={MaterialLink}
            href="https://spot.ai"
            target="_blank"
            style={{ fontWeight: "bold", fontSize: 12 }}
          >
            Learn More
          </Button>
          <div className="h-3 p-1">
            {/* <Divider orientation="vertical" /> */}
          </div>
        </div>
      </Hidden>
    </HeaderBase>
  );
}
