import {
  GetLprReportDetailsQuery,
  GetLprSearchQuery,
  IntelligenceDashboardQuery,
  IntelligenceDashboardType,
} from "@/generated-models";

export function isInterestListDashboard(
  dashboard: IntelligenceDashboardQuery["intelligenceDashboard"]
) {
  return dashboard?.type === IntelligenceDashboardType.InterestList;
}

export function getUniqueLprId(
  data:
    | GetLprSearchQuery["lprSearch"][number]
    | GetLprReportDetailsQuery["lprReportDetails"][number]
) {
  const startTime = new Date(data.startTime).getTime();
  const endTime = new Date(data.endTime).getTime();
  return `${data.camera.id}-${data.vehicleId}-${startTime}-${endTime}`;
}
