import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { MenuItem, Select, Tab, Tabs } from "@mui/material";
import clsx from "clsx";
import { atom, useAtom } from "jotai";
import { capitalize } from "lodash";
import React, { useEffect, useMemo } from "react";

import { formatDurationSecs } from "@/util/formatDurationSecs";
import { useBreakpoints } from "@/util/useBreakpoints";

import { IntelligenceDashboardType } from "@/generated-models";

import { getObjectLabel } from "../../utils";
import { ComparativeTabType } from "../constant";

const tabClassName = "text-lg leading-[21px] px-0 pb-1";

function useContentTabs(
  type: IntelligenceDashboardType,
  thresholdSeconds: number,
  objectTypes?: string[] | null | undefined
) {
  if (!type || type === IntelligenceDashboardType.Presence) return null;

  const label = capitalize(getObjectLabel(objectTypes, type));
  const labelMulti = capitalize(getObjectLabel(objectTypes, type, true));

  return [
    { value: ComparativeTabType.PRESENCE, label: "", hidden: true },
    {
      value: ComparativeTabType.COUNT_IN_OUT,
      label: `${label} Count In & Out`,
      hidden: type !== IntelligenceDashboardType.Count,
    },
    {
      value: ComparativeTabType.COUNT_IN,
      label: `${label} Count In`,
      hidden: type !== IntelligenceDashboardType.Count,
    },
    {
      value: ComparativeTabType.COUNT_OUT,
      label: `${label} Count Out`,
      hidden: type !== IntelligenceDashboardType.Count,
    },
    {
      value: ComparativeTabType.IDLE_PERCENTAGE,
      label: `Percentage of ${labelMulti} Idle > ${formatDurationSecs(
        thresholdSeconds,
        {
          hideZeroes: true,
          long: true,
        }
      )}`,
      hidden: type !== IntelligenceDashboardType.Idle,
    },
    {
      value: ComparativeTabType.IDLE_COUNT,
      label: `Total & Idle ${label} Count`,
      hidden: type !== IntelligenceDashboardType.Idle,
    },
  ];
}

export const compContentTabAtom = atom<ComparativeTabType>(
  ComparativeTabType.IDLE_PERCENTAGE
);

export function ComparativeDashboardContentTabs({
  type,
  thresholdSeconds,
  objectTypes,
}: {
  type: IntelligenceDashboardType;
  thresholdSeconds: number;
  objectTypes?: string[] | null | undefined;
}) {
  const { fitsTablet } = useBreakpoints();
  const [tab, setTab] = useAtom(compContentTabAtom);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const tabs = useContentTabs(type, thresholdSeconds, objectTypes);
  const visibleTabs = useMemo(() => tabs?.filter((t) => !t.hidden) || [], [
    tabs,
  ]);

  useEffect(() => {
    if (visibleTabs[0] && !visibleTabs?.find((t) => t.value === tab)) {
      setTab(visibleTabs[0].value);
    }
  }, [setTab, tab, visibleTabs]);

  if (!tabs) return <></>;

  if (!fitsTablet) {
    return (
      <div className="w-full">
        <label
          className="block font-medium mb-2 text-[#666666]"
          htmlFor="Graph Data"
        >
          Graph Data
        </label>
        <Select
          value={tab}
          onChange={(event) => {
            setTab(event.target.value as ComparativeTabType);
          }}
          variant="outlined"
          className="w-full"
          inputProps={{
            className:
              "px-4 py-3 bg-white text-lg leading-5 font-bold border border-[#AAAAAA] rounded-lg h-auto min-h-0",
          }}
          IconComponent={KeyboardArrowDownIcon}
        >
          {tabs.map((t) => (
            <MenuItem
              className={clsx(t.hidden && "hidden")}
              key={t.value}
              value={t.value}
            >
              {t.label}
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  }

  return (
    <Tabs
      value={tab}
      onChange={handleChange}
      aria-label="basic tabs example"
      classes={{ flexContainer: "gap-4 " }}
    >
      {tabs.map((t) => (
        <Tab
          key={t.label}
          className={clsx(tabClassName, t.hidden && "hidden")}
          {...t}
        />
      ))}
    </Tabs>
  );
}
