import clsx from "clsx";
import { useMatch } from "react-router-dom";

import { CameraIcon } from "@/icons/Icons";

import { useBreakpoints } from "@/util/useBreakpoints";

import { useMe } from "@/components/Auth";
import { SearchParamLink } from "@/components/shared/QueryParamLink";

import { usePrefixOrgSlug } from "@/hooks/useOrgRouteBase";
import { usePermissions } from "@/hooks/usePermissions";

import { ReactComponent as PeopleIcon } from "./icons/people.svg";

// import { ReactComponent as VehicleIcon } from "./icons/vehicle.svg";

export function SearchTabs({
  children,
  fallback,
}: {
  children?: React.ReactNode;
  fallback: React.ReactNode;
}) {
  const me = useMe();
  const hasPermission = usePermissions();
  const { fitsDesktop } = useBreakpoints();
  const prefixOrgSlug = usePrefixOrgSlug();

  return (
    <div className="flex-center flex-wrap md:justify-start gap-y-1 gap-x-4 md:gap-x-6 my-5 px-2 md:px-0 w-full">
      {me?.organization.flags.aiFaceRecognition &&
      hasPermission("people_search_access") &&
      fitsDesktop ? (
        <div className="flex gap-x-2 p-2 bg-blue-medium border border-primary border-opacity-10 rounded-full shrink-0">
          <Tab
            path={prefixOrgSlug("/search")}
            label="Cameras"
            icon={<CameraIcon />}
          />
          <Tab
            path={prefixOrgSlug("/search/people")}
            label="People"
            icon={<PeopleIcon className="-mr-0.5 -ml-[5px]" />}
          />
        </div>
      ) : (
        fallback
      )}
      {children}
    </div>
  );
}

interface TabProps {
  path: string;
  label: string;
  icon: React.ReactNode;
}

function Tab({ path, label, icon }: TabProps) {
  const isActive = Boolean(useMatch(path));

  return isActive ? (
    <div className="flex items-center gap-x-1 h-10 px-4 text-2xl text-primary bg-white rounded-full shadow-toggleButton">
      {icon}
      <SearchHeader label={label} />
    </div>
  ) : (
    <SearchParamLink
      className="flex items-center gap-x-1 h-10 px-4 text-2xl font-bold text-primary rounded-full hover:bg-white transition-colors"
      to={path}
      keepParams={["g"]}
    >
      {icon}
      <span>
        <span className="font-extralight">Search</span> {label}
      </span>
    </SearchParamLink>
  );
}

interface SearchHeaderProps {
  label: string;
  className?: string;
}

export function SearchHeader({ label, className }: SearchHeaderProps) {
  return (
    <h1
      className={clsx(
        "font-bold text-transparent bg-clip-text spot-gradient",
        className
      )}
    >
      <span className="font-extralight">Search</span> {label}
    </h1>
  );
}
