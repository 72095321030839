import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { capitalize } from "lodash/fp";
import { ArrayParam, useQueryParam } from "use-query-params";

export function IntelligenceFilter({
  displayName,
  filterName,
  options,
}: {
  displayName: string;
  filterName: string;
  options: { label: string; value: string }[];
}) {
  const [filterVal, setFilterVal] = useQueryParam(filterName, ArrayParam);
  const filteredParsed = filterVal ?? [];

  return (
    <FormControl
      size="small"
      style={{ minWidth: 200, backgroundColor: "white" }}
      fullWidth
      className="w-full md:w-[unset]"
    >
      <InputLabel>{capitalize(displayName)}</InputLabel>

      <Select
        multiple
        variant="outlined"
        value={filteredParsed}
        input={<OutlinedInput label={displayName} />}
        onChange={(e) => {
          setFilterVal(e.target.value as string[]);
        }}
        inputProps={{ name: "type", id: "type" }}
        renderValue={(selected) => {
          const values = options
            .filter((o) => selected.includes(o.value))
            .map((r) => r.label)
            .join(",");
          return values;
        }}
      >
        {options.map((metric) => (
          <MenuItem value={metric.value} key={metric.label}>
            <ListItemText primary={metric.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
