import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { capitalize } from "lodash/fp";

import { MetricsRange } from "@/generated-models";

interface MaintainMetricsChartRangeSelectorProps {
  value: MetricsRange;
  onValueChange: (newValue: MetricsRange) => void;
}

export function MaintainMetricsChartRangeSelector({
  value,
  onValueChange,
}: MaintainMetricsChartRangeSelectorProps) {
  return (
    <ToggleButtonGroup
      className="bg-[#DDEFFF] rounded-[50px] gap-1 px-1 py-[3px]"
      color="primary"
      value={value}
      exclusive
      size="small"
      aria-label="Category"
      onClick={(e) => {
        e.stopPropagation();
      }}
      onChange={(_, value) => {
        onValueChange(value);
      }}
    >
      {[MetricsRange.Daily, MetricsRange.Weekly, MetricsRange.Monthly].map(
        (c) => {
          return (
            <ToggleButton
              key={c}
              value={c}
              className="border-none rounded-[50px] text-sm leading-4 transition-all font-normal h-[22.4px]"
              classes={{
                root: "text-primary px-4 py-1",
                selected: "bg-white !font-bold",
              }}
            >
              {capitalize(c)}
            </ToggleButton>
          );
        }
      )}
    </ToggleButtonGroup>
  );
}
