// import clsx from "clsx";
import { useState } from "react";

import CameraSupportDetails from "@/components/CameraSupportDetails";
import EditableText from "@/components/EditableText";
import { useUpdateCamera } from "@/components/Genius/Forms/Camera/cameraHooks";
import { DeviceDetailChannel } from "@/components/Genius/GeniusPopup";
import { StreamSelectionDropdown } from "@/components/StreamSelectionDropdown/StreamSelectionDropdown";

import { Camera, CameraStatus, LifecycleStates } from "@/generated-models";

import { LocationAi, useLocationCapacity } from "../../LocationCapacityGuard";

interface FisheyeChannelFormProps {
  channel: DeviceDetailChannel;
  defaultName: string;
  vendor: string;
  deviceId: number;
  cameraStatus?: CameraStatus;
  onOpenNativeProxy?: () => void;
  disableAddingFisheyeCameras: boolean;
}

export default function FisheyeChannelForm({
  defaultName,
  channel,
  deviceId,
  vendor,
  onOpenNativeProxy = () => {},
  disableAddingFisheyeCameras,
}: FisheyeChannelFormProps) {
  const connectedStream = channel.streams.find((stream) => stream.camera);
  const camera = connectedStream?.camera;
  const [nameValue, setNameValue] = useState(camera?.name ?? defaultName);

  const { locationAi, maxAICapacityReached } = useLocationCapacity();
  // initial state is value of existing camera, or true if non-togglable, or false if non AI or max AI already reached
  const aiEnabled =
    camera?.aiEnabled ??
    (locationAi !== LocationAi.NonAiOnly && !maxAICapacityReached);
  const {
    update: updateCamera,
    loading: updateCameraLoading,
  } = useUpdateCamera();
  const isSubmitting = updateCameraLoading;

  return (
    <div className="w-full h-full flex p-[10px]">
      <img
        src={camera?.still ?? "/no-still.svg"}
        alt="Camera still"
        style={{ width: "300px", objectFit: "cover", overflow: "hidden" }}
      />
      <div className="flex flex-col justify-center-center flex-1 pl-[10px]">
        <div className="flex mb-2">
          <div className="flex flex-col w-full">
            <div className="flex items-center gap-3">
              <EditableText
                TypographyProps={{
                  style: { fontSize: 16, fontWeight: "bold" },
                }}
                initialValue={camera?.name ?? defaultName}
                onSubmit={(value) => {
                  if (camera) {
                    updateCamera(camera.id, undefined, value);
                  } else {
                    // Set name value to be used by `addCamera` later on
                    setNameValue(value);
                  }
                }}
              />
            </div>
            <CameraSupportDetails camera={camera as Camera} />
          </div>
        </div>

        <StreamSelectionDropdown
          channelId={channel.channelId}
          deviceId={deviceId}
          deviceName={nameValue}
          deviceVendor={vendor}
          aiEnabled={aiEnabled}
          onOpenNativeProxy={onOpenNativeProxy}
          disabled={
            isSubmitting ||
            (!!camera?.lifecycleState &&
              camera.lifecycleState !== LifecycleStates.Enabled) ||
            disableAddingFisheyeCameras
          }
        />
      </div>
    </div>
  );
}
