import React from "react";

interface ErrorBoundaryProps {
  renderError: (error: any) => React.ReactNode;
  children: React.ReactNode;
}
export class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  state = { error: null as any };

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  componentDidCatch(error: any) {
    // You can also log the error to an error reporting service
    console.error(error);
  }

  render() {
    if (this.state.error) {
      // You can render any custom fallback UI
      return this.props.renderError(this.state.error);
    }

    return this.props.children;
  }
}
