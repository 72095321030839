import gql from "graphql-tag";
import { intersection } from "lodash";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { ArrayParam, StringParam, useQueryParam } from "use-query-params";

import { useSearchRangeParams } from "@/pages/Search/searchHooks";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  GetLprSearchQuery,
  useGetLprReportDetailsQuery,
  useGetLprReportQuery,
  useGetlprReportTimeseriesQuery,
} from "@/generated-models";

import { getUniqueLprId } from "../utils";

export interface GroupedPlateMatch {
  plate: string;
  visits: number;
  firstSeen: string;
  lastSeen: string;
  make: string;
  model: string;
  colorId: number;
  typeId: number;
  cameras?: GetLprSearchQuery["lprSearch"][number]["camera"][];
}

export const DEFAULT_TIME_RANGE = 14;

export function useLprReportTimeseries(dashboardId?: number) {
  const { id } = useParams();
  const { rangeStart, rangeEnd } = useSearchRangeParams(DEFAULT_TIME_RANGE);
  const [searchFilter] = useQueryParam("search", StringParam);
  const [locationsFilter] = useQueryParam("locations", ArrayParam);
  const [camerasFilter] = useQueryParam("cameras", ArrayParam);

  const resolvedId = dashboardId || id;

  const query = useGetlprReportTimeseriesQuery({
    variables: {
      input: {
        dashboardId: Number(resolvedId),
        startTime: rangeStart?.toISOString(),
        endTime: rangeEnd?.toISOString(),
      },
      inputFilter: {
        search: searchFilter,
        locationIds:
          locationsFilter?.map((l) => parseInt(l as string)) || undefined,
        cameraIds:
          camerasFilter?.map((l) => parseInt(l as string)) || undefined,
      },
    },
  });

  return query;
}

export function useLprReportGrouped(
  dashboardId?: number,
  filter?: boolean,
  skip?: boolean
) {
  const { id } = useParams();
  const { rangeStart, rangeEnd } = useSearchRangeParams(DEFAULT_TIME_RANGE);
  const [searchFilter] = useQueryParam("search", StringParam);
  const [locationsFilter] = useQueryParam("locations", ArrayParam);
  const [camerasFilter] = useQueryParam("cameras", ArrayParam);
  const [makeFilter] = useQueryParam("make", ArrayParam);
  const [modelFilter] = useQueryParam("model", ArrayParam);
  const [colorFilter] = useQueryParam("color", ArrayParam);
  const [typeFilter] = useQueryParam("type", ArrayParam);

  const resolvedId = dashboardId || id;

  const query = useGetLprReportQuery({
    variables: {
      input: {
        dashboardId: Number(resolvedId),
        startTime: rangeStart?.toISOString(),
        endTime: rangeEnd?.toISOString(),
      },
    },
    skip,
  });

  const data = useMemo(() => {
    const collection =
      query.data?.lprReport?.map((d) => ({
        ...d,
      })) || [];

    if (filter) {
      return collection
        .filter((d) => {
          return (
            !searchFilter ||
            d.plate?.toLowerCase()?.includes(searchFilter.toLowerCase())
          );
        })
        .filter(
          (d) =>
            !locationsFilter ||
            intersection(
              locationsFilter,
              d.cameras.map((c) => String(c.location.id))
            ).length > 0
        )
        .filter(
          (d) =>
            !camerasFilter ||
            intersection(
              camerasFilter,
              d.cameras.map((c) => String(c.id))
            ).length > 0
        )
        .filter((d) => !makeFilter || makeFilter.includes(d.make))
        .filter((d) => !modelFilter || modelFilter.includes(d.model))
        .filter((d) => !colorFilter || colorFilter.includes(`${d.colorId}`))
        .filter((d) => !typeFilter || typeFilter.includes(`${d.typeId}`));
    }
    return collection;
  }, [
    camerasFilter,
    colorFilter,
    filter,
    locationsFilter,
    makeFilter,
    modelFilter,
    query.data?.lprReport,
    searchFilter,
    typeFilter,
  ]);

  return { ...query, data: { lprReport: data } };
}

export function useLprReportDetails(plate?: string, filter?: boolean) {
  const { id } = useParams();
  const { rangeStart, rangeEnd } = useSearchRangeParams(DEFAULT_TIME_RANGE);

  const query = useGetLprReportDetailsQuery({
    ...refetchOnMountPolicy,
    variables: {
      input: {
        dashboardId: Number(id),
        plates: plate ? [plate] : [""],
        startTime: rangeStart?.toISOString(),
        endTime: rangeEnd?.toISOString(),
      },
    },
    skip: !id || !plate,
  });

  const data = useMemo(() => {
    return (
      query.data?.lprReportDetails?.map((d) => ({
        id: getUniqueLprId(d),
        ...d,
      })) || []
    ).sort((a, b) => a.startTime.localeCompare(b.startTime) * -1);
  }, [query.data?.lprReportDetails]);

  return { ...query, data };
}

export function useLprResolvedVisits(dashboardId?: number) {
  const { data } = useLprReportGrouped(dashboardId);

  return useMemo(() => {
    const result: Record<string, number> = {};
    (data?.lprReport || []).forEach(({ plate, visits }) => {
      const resolvedPlate = plate.includes("/") ? plate.split("/")[0] : plate;
      if (result[resolvedPlate]) {
        result[resolvedPlate] += visits;
      } else {
        result[resolvedPlate] = visits;
      }
    });
    return result;
  }, [data?.lprReport]);
}

export const GET_LPR_REPORT_TIMESERIES = gql`
  query getlprReportTimeseries(
    $input: LprReportInput!
    $inputFilter: LprFiltersInput
  ) {
    lprReportTimeseries(input: $input, inputFilter: $inputFilter) {
      date
      visits
    }
  }
`;

export const GET_LPR_REPORT = gql`
  query getLprReport($input: LprReportInput!) {
    lprReport(input: $input) {
      make
      model
      colorId
      typeId
      firstSeen
      lastSeen
      visits
      plate
      cameras {
        id
        name
        location {
          id
          name
          timezone
        }
      }
    }
  }
`;

export const GET_LPR_REPORT_DETAILS = gql`
  query getLprReportDetails($input: LprReportDetailsInput!) {
    lprReportDetails(input: $input) {
      vehicleId
      startTime
      endTime
      vehicleAttributes {
        plate
        make
        model
        colorId
        typeId
      }
      camera {
        id
        name
        firstSegmentTime
        location {
          id
          name
          timezone
        }
      }
    }
  }
`;
