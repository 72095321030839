import { useAtom, useSetAtom } from "jotai";
import { useEffect } from "react";

import { InterestListType } from "@/generated-models";

import { DefaultDialog, useDialog } from "../shared/Dialog";
import { InterestListMainForm } from "./Form/InterestListMainForm";
import { interestListConfig, InterestListFormMode } from "./constants";
import {
  defaultCloseAtom,
  listModeAtom,
  selectedListItemsAtom,
  useResetInterestListState,
} from "./hooks";

interface InterestListDialogProps {
  defaultMode?: InterestListFormMode;
  initialSelection?: number[];
  initialValue?: string;
  primaryActionLabel: string;
  type: InterestListType;
  onApplyLists: (lists: number[]) => void;
  onClose: () => void;
  open?: boolean;
}

function getTitle(type: InterestListType, mode: InterestListFormMode) {
  const config = interestListConfig[type];

  switch (mode) {
    case InterestListFormMode.insert:
      return `Add to ${config.featureName} List`;
    case InterestListFormMode.view:
      return `${config.featureName} Lists`;
    case InterestListFormMode.edit:
      return "Edit List";
    default:
      return "Create List";
  }
}

export function InterestListDialog({
  defaultMode,
  initialSelection,
  initialValue,
  primaryActionLabel,
  onApplyLists,
  onClose,
  open,
  type,
}: InterestListDialogProps) {
  const { open: openDialog, cancel, ...interestListDialogProps } = useDialog();

  const [mode, setMode] = useAtom(listModeAtom);
  const [selectedItems, setSelectedItems] = useAtom(selectedListItemsAtom);
  const setDefaultClose = useSetAtom(defaultCloseAtom);

  const resetInterestListState = useResetInterestListState();

  useEffect(() => {
    if (open && defaultMode) {
      setMode(defaultMode);
      setDefaultClose(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (open) {
      openDialog();

      if (initialSelection) {
        setSelectedItems(initialSelection);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  function onCloseDialog() {
    if (mode !== InterestListFormMode.view && !defaultMode) {
      setMode(InterestListFormMode.view);
    } else {
      cancel();
      onClose();
      resetInterestListState();
    }
  }

  return (
    <DefaultDialog
      classes={{ paper: "max-w-[unset] w-[unset]" }}
      title={getTitle(type, mode)}
      cancel={onCloseDialog}
      hideDefaultActionButtons
      content={
        <InterestListMainForm
          initialValue={initialValue}
          type={type}
          primaryActionLabel={primaryActionLabel}
          onPrimaryAction={() => {
            onApplyLists((selectedItems || []) as number[]);
            onCloseDialog();
          }}
          onClose={onCloseDialog}
        />
      }
      cancelText="Cancel"
      confirmText="Create List"
      {...interestListDialogProps}
    />
  );
}
