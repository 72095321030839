import { Box, useTheme, Alert } from "@mui/material";
import { useState } from "react";

import CameraSupportDetails from "@/components/CameraSupportDetails";
import EditableText from "@/components/EditableText";
import { useUpdateCamera } from "@/components/Genius/Forms/Camera/cameraHooks";
import { DeviceDetailChannel } from "@/components/Genius/GeniusPopup";
import { StreamSelectionDropdown } from "@/components/StreamSelectionDropdown/StreamSelectionDropdown";

import { Camera, CameraStatus, LifecycleStates } from "@/generated-models";

import { LocationAi, useLocationCapacity } from "../../LocationCapacityGuard";

interface NvrChannelFormProps {
  channel: DeviceDetailChannel;
  defaultName: string;
  vendor: string;
  deviceId: number;
  cameraStatus?: CameraStatus;
  onOpenNativeProxy?: () => void;
}

export default function NvrChannelForm({
  defaultName,
  channel,
  deviceId,
  vendor,
  onOpenNativeProxy = () => {},
}: NvrChannelFormProps) {
  const theme = useTheme();

  const connectedStream = channel.streams.find((stream) => stream.camera);
  const camera = connectedStream?.camera;
  const [nameValue, setNameValue] = useState(camera?.name ?? defaultName);

  const { locationAi, maxAICapacityReached } = useLocationCapacity();
  // initial state is value of existing camera, or true if non-togglable, or false if non AI or max AI already reached
  const aiEnabled =
    camera?.aiEnabled ??
    (locationAi !== LocationAi.NonAiOnly && !maxAICapacityReached);
  const {
    update: updateCamera,
    loading: updateCameraLoading,
  } = useUpdateCamera();
  const isSubmitting = updateCameraLoading;

  return (
    <Box width="100%" height="100%" display="flex" flexDirection="row">
      <img
        src={channel.still ?? "/no-still.svg"}
        alt="Camera still"
        style={{ width: "300px", objectFit: "cover", overflow: "hidden" }}
      />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        flexGrow={1}
        mx={2}
        py={1}
      >
        <Box display="flex" flexDirection="row" mb={1}>
          <div className="flex flex-col w-full">
            <div className="flex items-center gap-3">
              <EditableText
                TypographyProps={{
                  style: { fontSize: 22, fontWeight: "bold" },
                }}
                initialValue={camera?.name ?? defaultName}
                onSubmit={(value) => {
                  if (camera) {
                    updateCamera(camera.id, undefined, value);
                  } else {
                    // Set name value to be used by `addCamera` later on
                    setNameValue(value);
                  }
                }}
              />
            </div>
            <CameraSupportDetails camera={camera as Camera} />
          </div>
        </Box>
        {camera?.status === CameraStatus.Offline && (
          <Alert style={{ margin: theme.spacing(0, 0, 2) }} severity="error">
            Channel appears to be offline.
          </Alert>
        )}

        <StreamSelectionDropdown
          channelId={channel.channelId}
          deviceId={deviceId}
          deviceName={nameValue}
          deviceVendor={vendor}
          aiEnabled={aiEnabled}
          onOpenNativeProxy={onOpenNativeProxy}
          disabled={
            isSubmitting ||
            (!!camera?.lifecycleState &&
              camera.lifecycleState !== LifecycleStates.Enabled)
          }
        />
      </Box>
    </Box>
  );
}
