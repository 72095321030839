import { AddCircle, ChevronRight } from "@mui/icons-material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Divider, Skeleton, Typography } from "@mui/material";
import clsx from "clsx";
import { LayoutGroup, motion } from "framer-motion";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useMemo } from "react";

import { pluralize } from "@/util/pluralize";

import { PromptListType, PromptListsQuery } from "@/generated-models";

import { CopilotFeedbackModal } from "../../../CopilotOverlay/CopilotLabelButton/CopilotFeedbackModal";
import { usePromptLists } from "../../../copilotQueryHooks";
import { CopilotPopoverHeader } from "../Core/CopilotPopoverHeader";
import {
  LibraryMode,
  useCloseLibraryPopover,
  useLibraryParams,
} from "../copilotLibraryHooks";

interface CopilotLibraryButtonProps {
  list?: PromptListsQuery["promptLists"][number];
  loading?: boolean;
}

function CopilotLibraryButton({ list, loading }: CopilotLibraryButtonProps) {
  const [, setLibraryParams] = useLibraryParams();
  const label =
    list?.type === PromptListType.Organization ? "Custom" : list?.name;
  const count = list?.prompts?.length || 0;

  if (list?.prompts.length === 0) return <></>;

  return (
    <>
      <motion.button
        type="button"
        whileHover="hover"
        className="bg-transparent flex items-center justify-between"
        disabled={loading || !list}
        key={`library-btn-${list?.id}`}
        initial={{ opacity: 0, height: 0 }}
        animate={{
          opacity: 1,
          height: 24,
          transition: { type: "spring", stiffness: 100, mass: 0.75 },
        }}
        exit={{
          opacity: 0,
          height: 0,
          zIndex: 0,
          transition: { type: "spring", stiffness: 50 },
        }}
        onClick={() => {
          setLibraryParams({ details: list?.id, mode: LibraryMode.details });
        }}
      >
        {!loading && (
          <motion.div
            variants={{
              hover: { marginLeft: 4 },
            }}
          >
            <Typography className="text-[13px] leading-normal text-[#B8B8B8]/[.99]">
              <strong>{count}</strong> {label}{" "}
              {pluralize(
                {
                  multi: "Objects",
                  1: "Object",
                },
                count
              )}
            </Typography>
          </motion.div>
        )}
        {loading && (
          <Skeleton
            className="h-[18px] w-[120px] bg-white/20"
            variant="rounded"
          />
        )}
        <ChevronRight className={clsx("text-white", loading && "opacity-50")} />
      </motion.button>
      <Divider className="border-[#2F2F2F]" />
    </>
  );
}

function CopilotLibraryFooter() {
  const { copilotNewObjectFlow } = useFlags();
  // eslint--next-line @typescript-eslint/no-unused-vars
  const [, setLibraryParams] = useLibraryParams();
  return (
    <div className="flex flex-col items-center justify-center gap-[10px]">
      {copilotNewObjectFlow && (
        <button
          type="button"
          className="bg-[#131313] border border-solid border-[#4C076C] rounded-[65px] py-[3px] pl-[6px] pr-[13px] w-full text-[13px] leading-normal text-white flex items-center justify-start gap-2"
          onClick={() => {
            setLibraryParams({ mode: LibraryMode.adding });
          }}
        >
          <AddCircle className="text-primary w-4 h-4" />
          Coach a Custom Object
        </button>
      )}
      {!copilotNewObjectFlow && (
        <CopilotFeedbackModal
          buttonClassName="bg-transparent font-normal hover:font-bold font-barlow text-left text-[#E6B0FF] text-xs leading-normal tracking-[0.36px] py-[6px] px-1 bg-[#0C0C0C] rounded-[65px] text-center border border-solid border-[#4C076C] h-[35px] w-full"
          placeholder="I want AI Copilot to understand..."
          prefix="I want AI Copilot to understand..."
        >
          I want AI Copilot to understand...
        </CopilotFeedbackModal>
      )}
      <button
        type="button"
        className="text-sm leading-normal inline-block text-transparent bg-clip-text"
        onClick={() => {
          setLibraryParams({ mode: LibraryMode.help });
        }}
        style={{
          backgroundImage:
            "linear-gradient(90deg, #007CE4 5.61%, #A900E4 102.51%)",
        }}
      >
        How does this work?
      </button>
    </div>
  );
}

export function CopilotLibraryListContent() {
  const { copilotNewObjectFlow } = useFlags();
  const { data, loading } = usePromptLists();
  const handleClose = useCloseLibraryPopover();

  const items = useMemo(() => {
    return data.filter((d) => {
      if (copilotNewObjectFlow) return true;
      return d.type !== PromptListType.Organization;
    });
  }, [copilotNewObjectFlow, data]);

  return (
    <>
      <CopilotPopoverHeader
        icon={<DragIndicatorIcon className="text-[#878787]" />}
        title="Object Library"
        subtitle="AI Copilot uses these objects to help guide your search experience."
        onClose={handleClose}
      />
      {loading && (
        <LayoutGroup>
          <CopilotLibraryButton loading />
          <CopilotLibraryButton loading />
          <CopilotLibraryButton loading />
        </LayoutGroup>
      )}
      {!loading &&
        items.map((l, idx) => (
          <CopilotLibraryButton key={`${l.id}-${idx}`} list={l} />
        ))}
      <CopilotLibraryFooter />
    </>
  );
}
