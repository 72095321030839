import BackIcon from "@mui/icons-material/ChevronLeft";
import { Dialog, IconButton, Typography } from "@mui/material";
import { ReactNode } from "react";

import { pluralize } from "@/util/pluralize";

import { MobileFilterSearchBar } from "./MobileFilterSearchBar";

interface MobileFilterModalProps {
  open: boolean;
  subject: string;
  children?: ReactNode;
  total: number;
  onBack: () => void;
}

export function MobileSearchModal({
  open,
  subject,
  children,
  total,
  onBack,
}: MobileFilterModalProps) {
  return (
    <Dialog fullScreen open={open}>
      <div className="top-0 sticky z-50">
        <div className="flex justify-between items-center gap-2 px-4 py-2 bg-white">
          <IconButton
            onClick={() => onBack()}
            size="small"
            edge="end"
            className="p-0"
          >
            <BackIcon fontSize="large" color="primary" />
          </IconButton>
          <Typography className="font-bold text-[17px] leading-[22px] flex items-center gap-x-2">
            {pluralize(
              {
                multi: (
                  <>
                    {total} {subject}s
                  </>
                ),
                1: (
                  <>
                    {total} {subject}
                  </>
                ),
                0: <>No {subject}s</>,
              },
              total
            )}
          </Typography>
          <div />
        </div>
        <MobileFilterSearchBar
          autoFocus
          className="bg-white"
          onSubmit={() => onBack()}
          onHide={() => onBack()}
        />
        <div
          className="h-2.5"
          style={{
            background:
              "linear-gradient(180deg, #C4C4C4 0%, rgba(196, 196, 196, 0) 100%)",
          }}
        ></div>
      </div>
      <div className="-mt-[10px]">{children}</div>
    </Dialog>
  );
}
