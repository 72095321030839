import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { ReactNode, useMemo } from "react";
import Draggable from "react-draggable";

import {
  TutorialType,
  useCopilotNavigate,
} from "../CopilotPlayerSection/copilotPlayerSectionHooks";
import { COPILOT_COLORS } from "../constant";
import { useUniversalSearchByImage } from "../copilotQueryHooks";

function TutorialBookIcon() {
  return (
    <svg
      width="19"
      height="13"
      viewBox="0 0 19 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1804 0.00385321C16.4332 -0.0308624 16.6582 0.173431 16.6582 0.437981V10.1215C16.6582 10.3332 16.5122 10.5146 16.3116 10.553C13.6007 11.0718 11.9028 11.4058 10.6072 12.0828C10.3175 12.2342 9.94666 12.029 9.94666 11.6798V2.12177C9.94666 1.97209 10.0202 1.8313 10.1446 1.75237C11.5846 0.838926 13.0228 0.437398 16.1804 0.00385321Z"
        fill="#9A62FF"
      />
      <path
        d="M17.4494 2.50293H17.9494C18.1618 2.50293 18.334 2.6815 18.334 2.90178V12.4343C18.334 12.6546 18.1618 12.8331 17.9494 12.8331H12.7571C12.5446 12.8331 12.3724 12.6546 12.3724 12.4343V12.3681C13.4719 11.9982 14.8789 11.7226 16.851 11.3452C17.1972 11.2789 17.4494 10.9658 17.4494 10.5999V2.50293Z"
        fill="#5F14BE"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.9876 0.00385321C2.73476 -0.0308624 2.50977 0.173431 2.50977 0.437981V10.1215C2.50977 10.3332 2.65573 10.5146 2.85633 10.553C5.56731 11.0718 7.26515 11.4058 8.56077 12.0828C8.85049 12.2342 9.22131 12.029 9.22131 11.6798V2.12177C9.22131 1.97209 9.14775 1.8313 9.02333 1.75237C7.58336 0.838926 6.14516 0.437398 2.9876 0.00385321Z"
        fill="#9A62FF"
      />
      <path
        d="M1.7186 2.50293H1.2186C1.00618 2.50293 0.833984 2.6815 0.833984 2.90178V12.4343C0.833984 12.6546 1.00618 12.8331 1.2186 12.8331H6.41091C6.62332 12.8331 6.79552 12.6546 6.79552 12.4343V12.3681C5.69604 11.9982 4.28911 11.7226 2.31701 11.3452C1.97077 11.2789 1.7186 10.9658 1.7186 10.5999V2.50293Z"
        fill="#5F14BE"
      />
    </svg>
  );
}

function TutorialContent({
  children,
  type,
}: {
  children: ReactNode;
  type: TutorialType;
}) {
  const [navigate] = useCopilotNavigate();

  if (navigate.tutorialSubject !== type) return <></>;

  return (
    <motion.div
      className="flex"
      initial="initial"
      animate="animate"
      variants={{
        initial: {
          opacity: 0,
        },
        animate: {
          opacity: 1,
        },
      }}
      transition={{ duration: 0.5 }}
      key={type}
    >
      {children}
    </motion.div>
  );
}

export function CopilotWorkflowInfoTray() {
  const [navigate, setNavigate] = useCopilotNavigate();
  const { data } = useUniversalSearchByImage();

  const idx = useMemo(() => {
    return data.findIndex((d) =>
      d.title.toLowerCase().includes(navigate.tutorialSubject || "")
    );
  }, [data, navigate.tutorialSubject]);

  const color = idx >= 0 ? COPILOT_COLORS[idx % COPILOT_COLORS.length] : null;
  const isDefault = navigate.tutorialSubject === TutorialType.default;

  return (
    <AnimatePresence>
      {navigate.tutorialSubject !== TutorialType.hidden && (
        <div className="absolute w-full h-full flex items-end justify-center">
          <Draggable bounds="parent">
            <motion.button
              type="button"
              className={clsx(
                "relative py-[6px] pl-[15px] pr-[10px] gap-[10px] rounded-[41px] border border-solid border-[#353535]",
                "flex items-center bg-black/80 shadow-[0_4_14px_0_rgba(0,0,0,0.69)] pointer-events-auto z-20",
                {
                  "-top-2": isDefault,
                  "-top-3": !isDefault,
                }
              )}
              transition={{
                transition: {
                  ease: "easeInOut",
                  duration: 0.5,
                },
              }}
              animate={{
                scale: isDefault ? 1 : 1.28,
              }}
            >
              <motion.div className="text-white font-barlow h-[13px] text-[13px] leading-[13px] whitespace-nowrap flex">
                <AnimatePresence>
                  <TutorialContent
                    key="tutorial-default"
                    type={TutorialType.default}
                  >
                    Click on any <LabelIndicator fill="#FFFFFF" /> to use AI
                    Copilot
                  </TutorialContent>
                  <TutorialContent
                    key="tutorial-hidden"
                    type={TutorialType.hidden}
                  >
                    Click on any <LabelIndicator fill="#FFFFFF" /> to use AI
                    Copilot
                  </TutorialContent>
                  <TutorialContent
                    key="tutorial-object"
                    type={TutorialType.object}
                  >
                    Coach your AI Copilot: click&nbsp;
                    <TutorialBookIcon />
                    &nbsp;icon
                  </TutorialContent>
                  <TutorialContent
                    key="tutorial-person"
                    type={TutorialType.person}
                  >
                    Click on any{" "}
                    <LabelIndicator fill={color || COPILOT_COLORS[9]} /> to
                    search for&nbsp;
                    <strong>people</strong>
                  </TutorialContent>
                  <TutorialContent
                    key="tutorial-vehicle"
                    type={TutorialType.vehicle}
                  >
                    Click on any{" "}
                    <LabelIndicator fill={color || COPILOT_COLORS[6]} /> to
                    create&nbsp;
                    <strong>vehicle</strong>&nbsp;insights
                  </TutorialContent>
                </AnimatePresence>
              </motion.div>
              <motion.button
                type="button"
                className="bg-transparent ml-auto"
                whileHover={{
                  opacity: 1,
                }}
                initial={{
                  opacity: 0,
                  scale: 0.6,
                }}
                animate={{
                  opacity: 0.5,
                  scale: 1,
                }}
                onClick={() => {
                  setNavigate({
                    id: undefined,
                    tutorialSubject: TutorialType.hidden,
                  });
                }}
              >
                <CloseRoundedIcon className="text-white w-5 h-5" />
              </motion.button>
            </motion.button>
          </Draggable>
        </div>
      )}
    </AnimatePresence>
  );
}

export function LabelIndicator({ fill }: { fill: string }) {
  return (
    <svg
      width="63"
      height="16"
      viewBox="0 0 63 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="mx-1 -mt-[1px]"
    >
      <rect
        x="1"
        y="0.5"
        width="61"
        height="15"
        rx="7.5"
        fill="black"
        fillOpacity="0.7"
      />
      <path
        d="M8.2 11.5V4.52H9.04V10.77H12.35V11.5H8.2ZM18.7061 11.5L18.1161 9.51H15.6661L15.0761 11.5H14.2061L16.3461 4.52H17.4561L19.5961 11.5H18.7061ZM16.9361 5.41H16.8461L15.8661 8.78H17.9161L16.9361 5.41ZM21.6722 4.52H24.0422C24.6422 4.52 25.1122 4.68333 25.4522 5.01C25.7922 5.33667 25.9622 5.77333 25.9622 6.32C25.9622 6.74 25.8589 7.07 25.6522 7.31C25.4522 7.55 25.1922 7.72 24.8722 7.82V7.85C25.2655 7.94333 25.5889 8.12 25.8422 8.38C26.0955 8.63333 26.2222 9.00333 26.2222 9.49C26.2222 9.77 26.1755 10.0333 26.0822 10.28C25.9889 10.5267 25.8555 10.74 25.6822 10.92C25.5155 11.1 25.3155 11.2433 25.0822 11.35C24.8555 11.45 24.6089 11.5 24.3422 11.5H21.6722V4.52ZM24.0922 10.77C24.4789 10.77 24.7822 10.6833 25.0022 10.51C25.2222 10.3367 25.3322 10.07 25.3322 9.71V9.3C25.3322 8.94667 25.2222 8.68333 25.0022 8.51C24.7822 8.33 24.4789 8.24 24.0922 8.24H22.5122V10.77H24.0922ZM23.9522 7.55C24.3055 7.55 24.5822 7.47333 24.7822 7.32C24.9822 7.16 25.0822 6.92 25.0822 6.6V6.21C25.0822 5.89 24.9822 5.65 24.7822 5.49C24.5822 5.33 24.3055 5.25 23.9522 5.25H22.5122V7.55H23.9522ZM28.5883 11.5V4.52H32.8883V5.25H29.4283V7.6H32.7683V8.33H29.4283V10.77H32.8883V11.5H28.5883ZM35.7844 11.5V4.52H36.6244V10.77H39.9344V11.5H35.7844Z"
        fill={fill}
      />
      <path
        d="M48.1852 11.0886L51.0477 8.41113L48.1852 5.73363L49.0664 4.91113L52.8164 8.41113L49.0664 11.9111L48.1852 11.0886Z"
        fill="white"
      />
      <rect
        x="0.74537"
        y="0.24537"
        width="61.5093"
        height="15.5093"
        rx="7.75463"
        stroke="#737373"
        strokeOpacity="0.64"
        strokeWidth="0.509259"
      />
    </svg>
  );
}
