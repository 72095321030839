import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CopyIcon from "@mui/icons-material/FileCopyOutlined";
import { Typography, IconButton, Button, ButtonGroup } from "@mui/material";
import * as clipboard from "clipboard-polyfill/build/clipboard-polyfill.promise";
import { Formik, Form, FieldArray } from "formik";
import { ChangeEvent, useState } from "react";
import * as yup from "yup";

import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { StyledInputWithLabel } from "@/components/Styled/StyledInputWithLabel";

import { useSetupIntegrationMutation } from "@/generated-models";

import { CFG_FAILED_MSG, CFG_SUCCESS_MSG } from "../constant";
import { INTEGRATION_SETUP_STATE_QUERY } from "../hooks";

interface IntegrationsVendorFormProps {
  id: number;
  schema?: any;
  setupState?: any;
  className?: string;
  onConnectionResult?: (success: boolean) => void;
  editMode?: boolean;
  initialState?: any;
  callback?: () => void;
  onBack?: () => void;
}

interface DeviceProps {
  deviceName: string;
  macAddress: string;
}

interface DevicesValues {
  devices: DeviceProps[];
}

const macAddressRegex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;

const validationSchema = yup.array().of(
  yup.object().shape({
    deviceName: yup.string().required("Required"),
    macAddress: yup
      .string()
      .required("Required")
      .matches(macAddressRegex, "Invalid MAC address"),
  })
);

const AddedDevices = ({
  deviceName,
  macAddress,
  remove,
  disabled,
}: {
  deviceName: string;
  macAddress: string;
  remove: () => void;
  disabled: boolean;
}) => (
  <div className="rounded-md border border-[#EAEAEA] p-2 bg-[#FBFBFB]">
    <div className="text-base font-bold">
      <CheckCircleIcon className="text-[#18D710] mr-1" />
      {deviceName}
    </div>
    <div className="flex justify-between items-center">
      <div>
        <span className="text-[#BDBDBD]">MAC: </span>
        <span className="text-gray-75">{macAddress}</span>
      </div>
      <IconButton size="small" disabled={disabled}>
        <DeleteForeverIcon
          className="text-sm text-[#BDBDBD]"
          onClick={() => remove()}
        />
      </IconButton>
    </div>
  </div>
);

export function IntegrationsHaloVendorForm({
  id,
  setupState,
  className,
  onConnectionResult = () => {},
  initialState,
  callback,
  onBack,
  editMode,
}: IntegrationsVendorFormProps) {
  const [connected, setConnected] = useState(false);
  const [deviceData, setDeviceData] = useState({
    deviceName: "",
    macAddress: "",
  });
  const { pushSnackbar } = useFeedback();

  const [setupIntegration, { loading }] = useSetupIntegrationMutation({
    refetchQueries: [
      { query: INTEGRATION_SETUP_STATE_QUERY, variables: { input: { id } } },
      "integration",
    ],
    onCompleted: () => {
      pushSnackbar(CFG_SUCCESS_MSG, FeedbackType.Success);
      setConnected(true);
      onConnectionResult(true);
      if (callback) {
        callback();
      }
    },
    onError: (e) => {
      pushSnackbar(e.message || CFG_FAILED_MSG, FeedbackType.Error);
      setConnected(false);
      onConnectionResult(false);
    },
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDeviceData({ ...deviceData, [event.target.name]: event.target.value });
  };

  const handleSubmit = (values: any) => {
    setupIntegration({
      variables: {
        input: {
          integrationId: id,
          setupState: {
            devices: values.devices.map((value: any) => ({
              name: value.deviceName,
              "mac address": value.macAddress,
            })),
          },
        },
      },
    });
  };

  const initialValues: DevicesValues = {
    devices: setupState?.devices
      ? setupState?.devices.map(
          (device: { name: string; ["mac address"]: string }) => ({
            deviceName: device.name,
            macAddress: device["mac address"],
          })
        )
      : [],
  };

  return (
    <div className={className}>
      <div className="max-w-xl">
        <div className="border border-gray-[#E0E0E0] bg-gray-fb p-3 rounded-lg text-sm flex flex-col gap-4">
          {initialState?.map((msg: any) => (
            <div key={msg.title}>
              <div className="text-gray-75 mb-1">{msg.title}</div>
              <div className="flex gap-[14px] items-center">
                <span className="whitespace-nowrap overflow-x-hidden">
                  {msg.path}
                </span>
                <IconButton
                  size="small"
                  onClick={async () => await clipboard.writeText(msg.path)}
                >
                  <CopyIcon style={{ fontSize: 14 }} />
                </IconButton>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Formik
        onSubmit={(values) => handleSubmit(values)}
        validationSchema={validationSchema}
        initialValues={initialValues}
      >
        {({ values }) => (
          <Form>
            <FieldArray
              name="devices"
              render={({ remove, unshift }) => {
                return (
                  <div className="haloIntegrationSetup">
                    <div className="max-w-xl">
                      <div className="flex flex-col gap-3 mt-7 items-start">
                        <div className="w-full">
                          <StyledInputWithLabel
                            id="deviceName"
                            title="Device Name"
                            fullWidth
                            value={deviceData.deviceName}
                            onChange={(event) => handleChange(event)}
                          />
                        </div>
                        <div className="w-full">
                          <StyledInputWithLabel
                            id="macAddress"
                            title="MAC Address"
                            fullWidth
                            value={deviceData.macAddress}
                            onChange={(event) => handleChange(event)}
                          />
                        </div>
                        <Button
                          className="shadow-none font-normal text-base rounded-lg h-11 px-[70px] w-auto"
                          variant="contained"
                          color="primary"
                          disabled={loading || !deviceData.macAddress}
                          onClick={() => {
                            // validate macAddress
                            if (!macAddressRegex.test(deviceData.macAddress)) {
                              return pushSnackbar(
                                `Invalid Mac Address. Must match pattern "^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$"`,
                                FeedbackType.Error
                              );
                            }
                            unshift({
                              deviceName: deviceData.deviceName,
                              macAddress: deviceData.macAddress,
                            });
                            setDeviceData({
                              deviceName: "",
                              macAddress: "",
                            });
                          }}
                        >
                          Add Device
                        </Button>
                      </div>
                    </div>
                    {values.devices.length > 0 && (
                      <div className="max-w-[800px] mt-7">
                        <Typography fontSize={18} fontWeight={700}>
                          Devices Added
                        </Typography>
                        <div className="grid grid-cols-3 gap-3 mt-2">
                          {values.devices.map(
                            ({ deviceName, macAddress }, index) => {
                              return (
                                <AddedDevices
                                  key={`${deviceName}-${macAddress}-${index}`}
                                  deviceName={deviceName}
                                  macAddress={macAddress}
                                  remove={() => remove(index)}
                                  disabled={loading}
                                />
                              );
                            }
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                );
              }}
            />
            <ButtonGroup className="mt-7 flex gap-2">
              {!editMode && (
                <Button
                  variant="contained"
                  className="shadow-none bg-[#DAEEFF] text-[#007CE4] font-normal rounded-[6px]"
                  onClick={onBack}
                >
                  Back
                </Button>
              )}
              <Button
                type="submit"
                className="shadow-none font-normal text-base rounded-lg h-11 px-10 w-auto"
                variant="contained"
                color="primary"
                disabled={
                  values.devices.length === 0 ||
                  loading ||
                  (editMode &&
                    setupState?.devices.length === values.devices.length)
                }
              >
                {editMode ? "Save" : "Next"}
              </Button>
            </ButtonGroup>
          </Form>
        )}
      </Formik>
      {connected && (
        <Typography className="text-[#18D710] flex items-center justify-start gap-[6px] mt-[18px] text-lg leading-[22px]">
          <CheckCircleIcon />
          Connection Successful
        </Typography>
      )}
    </div>
  );
}
