import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";

import { AlertTriangleIcon } from "@/icons/AlertTriangle";

import { DarkTheme } from "@/layout/theme";

import { CloseIcon } from "../Player/PlayerIcons";

export default function ZoneInUseDialog({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  return (
    <DarkTheme enabled={false}>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle
          variant="h3"
          className="flex justify-between items-center py-2 pr-3 bg-[#F4F4F4]"
        >
          <span className="flex items-center gap-2">
            <AlertTriangleIcon />
            Zone In Use
          </span>
          <IconButton onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            component="div"
            className="pt-2 text-text font-medium"
          >
            Cannot delete zone because it is in use by other feature(s)
          </DialogContentText>
        </DialogContent>
        <DialogActions className="px-5 pb-4">
          <Button
            variant="outlined"
            color="primary"
            autoFocus
            onClick={onClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </DarkTheme>
  );
}
