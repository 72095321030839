import { IconProps } from "./IconProps";

export function SpotConnectIcon({ style, className, onClick }: IconProps) {
  return (
    <svg
      style={style}
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
    >
      <circle cx="18" cy="18" r="15.75" fill="#DDEFFF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.469 17.2195L12.0712 17.6173C10.5335 19.1549 10.5335 21.648 12.0712 23.1857C13.6089 24.7234 16.102 24.7234 17.6397 23.1857L18.0374 22.788L12.469 17.2195ZM10.878 24.379C13.0747 26.5757 16.6362 26.5757 18.8329 24.379L19.6559 23.556C20.0801 23.1319 20.0801 22.4441 19.6559 22.0199L13.237 15.6011C12.8129 15.1769 12.1251 15.1769 11.7009 15.6011L10.878 16.424C8.68129 18.6207 8.68129 22.1823 10.878 24.379Z"
        fill="#007CE4"
      />
      <path
        d="M8.92337 27.5298C8.59301 27.8601 8.05738 27.8601 7.72702 27.5298C7.39666 27.1994 7.39666 26.6638 7.72702 26.3334L11.1045 22.9559L12.3008 24.1523L8.92337 27.5298Z"
        fill="#007CE4"
      />
      <path
        d="M15.3505 15.9273C15.7356 15.5422 16.3601 15.5422 16.7452 15.9273C17.1303 16.3125 17.1303 16.9369 16.7452 17.3221L15.0561 19.0112L13.6614 17.6165L15.3505 15.9273Z"
        fill="#007CE4"
      />
      <path
        d="M17.9428 18.5152C18.3266 18.1314 18.9488 18.1314 19.3326 18.5152C19.7164 18.899 19.7164 19.5212 19.3326 19.905L17.641 21.5966L16.2512 20.2068L17.9428 18.5152Z"
        fill="#007CE4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.8015 18.0363L23.1993 17.6386C24.737 16.1009 24.737 13.6078 23.1993 12.0701C21.6616 10.5324 19.1685 10.5324 17.6308 12.0701L17.2331 12.4679L22.8015 18.0363ZM24.3925 10.8769C22.1958 8.68019 18.6343 8.68019 16.4376 10.8769L15.6146 11.6998C15.1904 12.124 15.1904 12.8118 15.6146 13.2359L22.0335 19.6548C22.4577 20.079 23.1454 20.079 23.5696 19.6548L24.3925 18.8318C26.5892 16.6351 26.5892 13.0736 24.3925 10.8769Z"
        fill="#007CE4"
      />
      <path
        d="M26.3462 7.72757C26.6765 7.39721 27.2121 7.39721 27.5425 7.72757C27.8729 8.05793 27.8729 8.59356 27.5425 8.92392L24.1667 12.2997L22.9703 11.1034L26.3462 7.72757Z"
        fill="#007CE4"
      />
    </svg>
  );
}
