import Close from "@mui/icons-material/Close";
import { Chip as MuiChip, ChipProps } from "@mui/material";
import clsx from "clsx";

/**
 * Prestyled Chip
 */
export function Chip({ classes, ...props }: ChipProps) {
  return (
    <MuiChip
      variant="outlined"
      color="primary"
      deleteIcon={<Close />}
      classes={{
        ...classes,
        root: clsx(
          "min-w-0 bg-[#F6FBFF] border-[#B6D8F5] h-[22px] cursor-default",
          classes?.root
        ),
        label: clsx(
          "font-normal text-md leading-[normal] px-1.5",
          classes?.label
        ),
        deleteIcon: clsx("w-3 h-3 mr-2.5", classes?.deleteIcon),
      }}
      {...props}
    />
  );
}
