import gql from "graphql-tag";
import { atom, useSetAtom } from "jotai";
import { groupBy } from "lodash/fp";
import { useMemo } from "react";

import { InterestList, InterestListItemInput } from "@/generated-models";

import { InterestListFormMode } from "./constants";

export const listModeAtom = atom<InterestListFormMode>(
  InterestListFormMode.view
);

export const bulkItemAtom = atom<InterestListItemInput | null>(null);
export const currentNoteNameAtom = atom<string>("");
export const currentListItemAtom = atom<number | null>(null);
export const selectedListItemsAtom = atom<number[]>([]);
export const defaultCloseAtom = atom<boolean>(false);

export function useResetInterestListState() {
  const setBulkItem = useSetAtom(bulkItemAtom);
  const setListMode = useSetAtom(listModeAtom);
  const setCurrentListItem = useSetAtom(currentListItemAtom);
  const setSelectedListItems = useSetAtom(selectedListItemsAtom);
  const setCurrentNoteName = useSetAtom(currentNoteNameAtom);
  const setDefaultClose = useSetAtom(defaultCloseAtom);

  return () => {
    setBulkItem(null);
    setListMode(InterestListFormMode.view);
    setCurrentListItem(null);
    setSelectedListItems([]);
    setCurrentNoteName("");
    setDefaultClose(false);
  };
}

export function useInterestListNotes(
  lists?: Pick<InterestList, "id" | "name" | "items">[]
) {
  return useMemo(() => {
    if (!lists) return {};

    return groupBy(
      "name",
      lists.flatMap((l) =>
        l.items.map((i) => ({ ...i, listId: l.id, listName: l.name }))
      )
    );
  }, [lists]);
}

gql`
  fragment InterestListFragment on InterestList {
    id
    name
    type
    items {
      name
      notes
      source
    }
  }
`;

gql`
  query page_interestLists {
    interestLists {
      ...InterestListFragment
    }
  }
`;

gql`
  mutation createInterestList($input: InterestListInput!) {
    createInterestList(input: $input) {
      ...InterestListFragment
    }
  }
`;

gql`
  mutation updateInterestList($id: Int!, $input: InterestListInput!) {
    updateInterestList(id: $id, input: $input) {
      ...InterestListFragment
    }
  }
`;

gql`
  mutation addToInterestLists($ids: [Int!]!, $input: InterestListItemInput!) {
    addToInterestLists(ids: $ids, input: $input) {
      ...InterestListFragment
    }
  }
`;
