import { useMemo } from "react";

import { usePrefixOrgSlug } from "@/hooks/useOrgRouteBase";

export const CONFIGURE_PATH = "/configure";
export const USERS_PATH = `${CONFIGURE_PATH}/user-management/users`;
export const ROLES_PATH = `${CONFIGURE_PATH}/user-management/roles`;
export const LOCATIONS_PATH = `${CONFIGURE_PATH}/locations`;
export const GROUPS_PATH = `${CONFIGURE_PATH}/groups`;

export function useSettingsRoutes() {
  const prefixOrgSlug = usePrefixOrgSlug();
  return useMemo(
    () => ({
      configure: prefixOrgSlug(`${CONFIGURE_PATH}`),
      users: prefixOrgSlug(`${USERS_PATH}`),
      roles: prefixOrgSlug(`${ROLES_PATH}`),
      locations: prefixOrgSlug(`${LOCATIONS_PATH}`),
      groups: prefixOrgSlug(`${GROUPS_PATH}`),
    }),
    [prefixOrgSlug]
  );
}
