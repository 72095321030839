import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
  Autocomplete,
  DialogActions,
  IconButton,
  Chip,
} from "@mui/material";
import { createFilterOptions } from "@mui/material/useAutocomplete";
import clsx from "clsx";
import { isEqual, uniq } from "lodash/fp";
import React, { Dispatch, PropsWithChildren, useMemo, useState } from "react";

import { ReactComponent as EditOutlineIcon } from "@/icons/edit-outline.svg";

import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  useIntegrationSourcesQuery,
  useUpdateIntegrationSourceMutation,
} from "@/generated-models";

import { chipProps } from "../constant";
import { useIntegrationTypeIcon } from "../hooks";

interface TagsButtonProps {
  integrationId: number;
  currentTags: string[];
  siteName: string;
  disableIconButton?: boolean;
  className?: string;
}

export function IntegrationsDeviceTagsButton({
  className,
  disableIconButton,
  ...props
}: TagsButtonProps) {
  const [opened, setOpened] = useState(false);
  const { currentTags } = props;
  const hasTags = currentTags.length > 0;

  const EllipsisText = ({ children }: PropsWithChildren<{}>) => (
    <div
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: 55,
      }}
    >
      {children}
    </div>
  );

  return (
    <>
      {hasTags && (
        <div className="flex flex-wrap gap-2 items-center">
          {currentTags.length > 3 ? (
            <Chip label={`${currentTags.length} Tags`} {...chipProps} />
          ) : (
            currentTags
              .slice()
              .sort()
              .map((tag, i) => (
                <Chip
                  key={i}
                  label={<EllipsisText>{tag}</EllipsisText>}
                  {...chipProps}
                />
              ))
          )}
          {disableIconButton ? (
            <Button
              color="primary"
              className={clsx("font-normal", className)}
              onClick={() => setOpened(true)}
            >
              + Add a tag
            </Button>
          ) : (
            <IconButton onClick={() => setOpened(true)}>
              <EditOutlineIcon />
            </IconButton>
          )}
        </div>
      )}
      {!hasTags && (
        <Button
          color="primary"
          className={clsx("font-normal", className)}
          onClick={() => setOpened(true)}
        >
          + Add a tag
        </Button>
      )}
      {opened && (
        <IntegrationsDeviceTagModal {...props} close={() => setOpened(false)} />
      )}
    </>
  );
}

function IntegrationsDeviceTagModal({
  integrationId,
  currentTags,
  siteName,
  close,
}: TagsButtonProps & {
  close: () => void;
}) {
  const tags = useSourceTags(integrationId);

  const { pushSnackbar } = useFeedback();

  const [updateTags] = useUpdateIntegrationSourceMutation({
    refetchQueries: ["integrationSources"],
    onCompleted: () => {
      close();
      pushSnackbar("Device tags successfully updated.", FeedbackType.Success);
    },
    onError: () =>
      pushSnackbar(
        "Failed to update the device tags, please try again",
        FeedbackType.Error
      ),
  });
  const TypeIcon = useIntegrationTypeIcon();
  const [selectedTags, setSelectedTags] = useState(currentTags);

  const dirty = useMemo(() => !isEqual(currentTags, selectedTags), [
    currentTags,
    selectedTags,
  ]);

  return (
    <Dialog
      classes={{
        paper: "rounded-lg max-w-xl",
      }}
      open={true}
      onClose={close}
    >
      <DialogTitle className="flex justify-between items-center py-4 bg-[#F4F4F4]">
        <Typography variant="h3">Add Device Tags</Typography>
      </DialogTitle>
      <DialogContent className="p-[10px]">
        <DialogContentText className="text-text mb-3">
          Tags help you organize your cases into the categories that make sense
          to you.
        </DialogContentText>
        <Typography className="text-lg leading-[21px] font-bold flex items-center justify-start gap-1 mb-8">
          <TypeIcon /> {siteName}
        </Typography>
        <DeviceTagSelect
          label="Tags"
          size="small"
          tags={tags}
          selectedTags={selectedTags}
          setSelectedTags={(tags) => {
            setSelectedTags(tags);
          }}
          freeSolo
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          className="shadow-none bg-[#DAEEFF] text-[#007CE4] font-normal rounded-[6px]"
          onClick={close}
        >
          Cancel
        </Button>
        <Button
          disabled={!dirty}
          variant="contained"
          color="primary"
          className="font-normal rounded-[6px] shadow-none"
          onClick={async () => {
            await updateTags({
              variables: { input: { id: integrationId, tags: selectedTags } },
            });
            close();
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

type AutocompleteItem = {
  label: React.ReactNode;
  value: string;
};
const filterAutocomplete = createFilterOptions<AutocompleteItem>();
function mapTagValues(tags: string[]): AutocompleteItem[] {
  return tags.map((t) => ({ label: t, value: t }));
}

export function DeviceTagSelect({
  tags,
  selectedTags,
  setSelectedTags,
  label,
  placeholder = "Search tags",
  freeSolo = false,
  limitTags = -1,
  size,
  className,
}: {
  tags: string[];
  selectedTags: string[];
  setSelectedTags: Dispatch<string[]>;
  label?: string;
  placeholder?: string;
  freeSolo?: boolean;
  limitTags?: number;
  size?: "small" | "medium";
  className?: string;
}) {
  return (
    <Autocomplete
      multiple
      forcePopupIcon
      freeSolo={freeSolo}
      fullWidth
      size={size}
      className={className}
      options={mapTagValues(uniq([...tags, ...selectedTags]).sort())}
      value={mapTagValues(selectedTags)}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      onChange={(_, value) =>
        setSelectedTags(
          uniq(value.map((v) => (typeof v === "string" ? v : v.value).trim()))
        )
      }
      limitTags={limitTags}
      getLimitTagsText={
        limitTags === 0 ? (more) => `${more} Selected` : undefined
      }
      noOptionsText="No tags found"
      ChipProps={{
        variant: "outlined",
        color: "primary",
        deleteIcon: <CloseIcon />,
        classes: { root: "font-medium bg-[#F6FBFF]", deleteIcon: "w-5 h-5" },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            notched: false,
            ...params.InputProps,
            className: clsx("rounded-lg", params.InputProps.className),
          }}
          InputLabelProps={{
            className: "-top-3 -left-[14px]",
            shrink: true,
            ...params.InputLabelProps,
          }}
          label="Device Tags"
          variant="outlined"
          placeholder={placeholder}
        />
      )}
      getOptionLabel={(option) => (option as { value: string }).value}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            checked={selected}
            color="primary"
            className="mr-2"
            icon={option.label !== option.value ? <AddIcon /> : undefined}
          />
          {option.label}
        </li>
      )}
      filterOptions={(options, params) => {
        const filtered = filterAutocomplete(options, params);

        // Suggest the creation of a new value
        const value = params.inputValue;
        if (freeSolo && value && filtered.every((v) => v.value !== value)) {
          filtered.unshift({
            value,
            label: (
              <>
                {value}
                <Button
                  variant="contained"
                  color="primary"
                  className="text-sm ml-auto"
                >
                  Create Tag
                </Button>
              </>
            ),
          });
        }

        return filtered;
      }}
    />
  );
}

function useSourceTags(id: number) {
  const { data } = useIntegrationSourcesQuery({
    ...refetchOnMountPolicy,
  });

  return data?.integrationSources?.flatMap((c) => c.tags || []) || [];
}
