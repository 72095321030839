import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Button } from "@mui/material";
import clsx from "clsx";
import { FunctionComponent, ReactNode, useState } from "react";

import { useBreakpoints } from "@/util/useBreakpoints";

import { FilterDropdownField } from "./Fields/FilterDropdownField";
import { FilterFieldProps } from "./constant";

export function FilterBarControls<T>({
  components = {},
  label = "Filters",
  hideExpand,
  children,
  className,
  filters,
}: {
  components?: Record<string, FunctionComponent<FilterFieldProps<T>>>;
  className?: string;
  hideExpand?: boolean;
  label?: string;
  children?: ReactNode;
  filters: FilterFieldProps<T>[];
}) {
  const [expandFilter, setExpandFilter] = useState(false);
  const { fitsDesktop } = useBreakpoints();
  return (
    <div
      className={clsx(
        "flex md:flex-row flex-col gap-2 gap-y-4 items-center w-full flex-1",
        {
          "pb-3": expandFilter && !fitsDesktop,
        },
        className
      )}
    >
      {!hideExpand && (
        <div className="flex items-center justify-center gap-1">
          <Button
            disabled={fitsDesktop}
            disableRipple
            size="small"
            className="text-sm font-normal leading-[14px] text-[#353D48] md:mt-0 mt-3 md:text-[#959595] hover:bg-transparent"
            onClick={() => {
              setExpandFilter((value) => !value);
            }}
          >
            {label}
            {!fitsDesktop && (
              <>
                {expandFilter ? (
                  <ArrowDropUpIcon fontSize="small" />
                ) : (
                  <ArrowDropDownIcon fontSize="small" />
                )}
              </>
            )}
          </Button>
        </div>
      )}
      {(fitsDesktop || (!fitsDesktop && (expandFilter || hideExpand))) &&
        filters.map((filter) => {
          const Component =
            components[filter.filterName] || FilterDropdownField;
          return <Component key={filter.filterName} {...filter} />;
        })}
      {children}
    </div>
  );
}
