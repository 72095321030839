import { FormProps } from "@rjsf/core";
import Form from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";

import { schemaFormTemplates } from "./SchemaFormTemplates";
import { schemaFormWidgets } from "./SchemaFormWidgets";

export default function SchemaForm({
  widgets = {},
  templates = {},
  ...props
}: Omit<FormProps, "validator">) {
  return (
    <Form
      widgets={{ ...widgets, ...schemaFormWidgets }}
      templates={{ ...templates, ...schemaFormTemplates }}
      {...props}
      validator={validator}
    />
  );
}
