import { Typography } from "@mui/material";
import React from "react";

import { formatVideoTime } from "@/util/date";

import { useMultiCamVodTime } from "@/components/Player/PlayerBase";

export function ProgressDisplay() {
  const time = useMultiCamVodTime();
  if (
    !time ||
    !Number.isFinite(time.position) ||
    !Number.isFinite(time.duration)
  )
    return null;

  return (
    <Typography variant="body2">
      <strong>{formatVideoTime(Math.floor(time.position * 1000))}</strong>
      {time.duration > 0 && (
        <>
          &nbsp;/&nbsp;
          {formatVideoTime(Math.floor(time.duration * 1000))}
        </>
      )}
    </Typography>
  );
}
