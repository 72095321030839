import ClearIcon from "@mui/icons-material/Clear";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Button, Chip, IconButton } from "@mui/material";
import clsx from "clsx";
import { utcToZonedTime } from "date-fns-tz";
import { format } from "date-fns/fp";
import { ReactNode, useMemo } from "react";
import {
  ArrayParam,
  StringParam,
  useQueryParam,
  useQueryParams,
} from "use-query-params";

import { useBreakpoints } from "@/util/useBreakpoints";

import { useSearchRangeParams } from "@/pages/Search/searchHooks";

import { MobileFilterCategoryProps } from "./MobileFilterModal";

function buildQueryParamResult(categories: MobileFilterCategoryProps[]) {
  return categories.reduce<
    Record<string, typeof ArrayParam | typeof StringParam>
  >(function (result, category) {
    result[category.filterName] = category.type ?? ArrayParam;
    return result;
  }, {});
}

export function MobileFilterDateTimeCategoryChip({
  timezone,
}: {
  timezone: string;
}) {
  const { query, rangeStart, rangeEnd, setRangeParam } = useSearchRangeParams();
  const label = useMemo(() => {
    return (
      [
        format("MMM d, yyyy p")(utcToZonedTime(rangeStart, timezone)),
        format("MMM d, yyyy p")(utcToZonedTime(rangeEnd, timezone)),
      ].join(" - ") || ""
    );
  }, [rangeEnd, rangeStart, timezone]);

  return (
    <>
      {!!query && (
        <Chip
          classes={{
            outlinedPrimary: "font-medium",
            deleteIconOutlinedColorPrimary: "w-4 h-4",
          }}
          deleteIcon={<ClearIcon />}
          variant="outlined"
          color="primary"
          label={label}
          onDelete={() => {
            setRangeParam(null);
          }}
        />
      )}
    </>
  );
}

function MobileFilterActionCategoryChips({
  filterName,
  options,
}: MobileFilterCategoryProps) {
  const [filterVal, setFilterVal] = useQueryParam(filterName, ArrayParam);
  const filteredParsed = useMemo(() => {
    if (filterVal) {
      return options.filter((o) => filterVal.includes(`${o.value}`));
    }

    return [];
  }, [filterVal, options]);
  return (
    <>
      {filteredParsed.map(({ label, value }) => (
        <Chip
          classes={{
            outlinedPrimary: "font-medium",
            deleteIconOutlinedColorPrimary: "w-4 h-4",
          }}
          deleteIcon={<ClearIcon />}
          variant="outlined"
          color="primary"
          key={label}
          label={label}
          onDelete={() => {
            const filters = [...(filterVal || [])];
            filters.splice(filters.indexOf(`${value}`), 1);
            setFilterVal(filters);
          }}
        />
      ))}
    </>
  );
}

export function MobileFilterActionBar({
  className,
  dynamic,
  onFilterClick,
  chips,
  categories,
}: {
  className?: string;
  dynamic?: boolean;
  chips?: ReactNode;
  categories: MobileFilterCategoryProps[];
  onFilterClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}) {
  const { fitsDesktop } = useBreakpoints();
  const params = useMemo(() => {
    return buildQueryParamResult(categories);
  }, [categories]);

  const [query, setQuery] = useQueryParams(params);
  const isEmptyQuery = Object.values(query).every((el) => !el);

  if ((dynamic && isEmptyQuery) || fitsDesktop) {
    return <></>;
  }

  return (
    <div
      className={clsx(
        "bg-[#F6FBFF] flex items-center justify-between py-[14px] px-4",
        {
          "border border-solid border-[#007CE4/[20]] !py-2": dynamic,
        },
        className
      )}
    >
      <div className="flex items-center gap-x-3">
        {onFilterClick && (
          <IconButton
            className="bg-[#DDEFFF]"
            color="primary"
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              onFilterClick(event);
            }}
          >
            <FilterListIcon />
          </IconButton>
        )}
        <div className="grid grid-cols-1 gap-1">
          {chips}
          {categories.map((c) => (
            <MobileFilterActionCategoryChips key={c.filterName} {...c} />
          ))}
        </div>
      </div>
      <Button
        size="small"
        color="primary"
        onClick={() => {
          const clearedQueries = categories.reduce<Record<string, undefined>>(
            function (result, category) {
              result[category.filterName] = undefined;
              return result;
            },
            {}
          );
          setQuery(clearedQueries);
        }}
      >
        Clear all filters
      </Button>
    </div>
  );
}
