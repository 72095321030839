import { utcToZonedTime } from "date-fns-tz";
import uniq from "lodash/uniq";

import { dateTimeFormat } from "@/util/date";

import { GetLprReportQuery } from "@/generated-models";

import { Color, VehicleType } from "../../LicensePlate/constants";

export type ReportExportContent = GetLprReportQuery["lprReport"];

export function reportCsvFormatter(
  data: NonNullable<ReportExportContent>,
  mmct?: boolean
) {
  const total = data.reduce((n, { visits }) => n + visits, 0);
  const makes = uniq(data.map((d) => d.make));
  const models = uniq(data.map((d) => d.model));
  const colors = uniq(data.map((d) => d.colorId));
  const types = uniq(data.map((d) => d.typeId));

  const groupedVists: Record<string, number> = {};
  (data || []).forEach(({ plate, visits }) => {
    const resolvedPlate = plate.includes("/") ? plate.split("/")[0] : plate;
    if (groupedVists[resolvedPlate]) {
      groupedVists[resolvedPlate] += visits;
    } else {
      groupedVists[resolvedPlate] = visits;
    }
  });

  const maxVisits = Math.max(...Object.values(groupedVists));

  const timezone = data?.[0]?.cameras?.[0]?.location?.timezone || "";
  const visitRows: string[] =
    data
      ?.sort((a, b) => b.lastSeen.localeCompare(a.lastSeen))
      ?.map(
        ({
          plate,
          visits,
          firstSeen,
          lastSeen,
          make,
          model,
          colorId,
          typeId,
        }) => {
          const firstSeenFormatted = firstSeen
            ? `"${dateTimeFormat.format(utcToZonedTime(firstSeen, timezone))}"`
            : "";

          const lastSeenFormatted = lastSeen
            ? `"${dateTimeFormat.format(utcToZonedTime(lastSeen, timezone))}"`
            : "";

          const formattedVisits = groupedVists[plate] ?? visits ?? 0;

          return [
            plate,
            lastSeenFormatted,
            firstSeenFormatted,
            formattedVisits,
            ...(mmct
              ? [make, model, Color[colorId || 0], VehicleType[typeId || 0]]
              : []),
          ].join(",");
        }
      ) || [];

  return [
    `Unique License Plates,${Object.keys(groupedVists).length}`,
    `Most visits by a single plate,${maxVisits}`,
    `Number of vehicles total,${total}`,
    ...(mmct
      ? [
          `Make,${makes.length}`,
          `Model,${models.length}`,
          `Color,${colors.length}`,
          `Type,${types.length}`,
        ]
      : []),
    "",
    "Summary:",
    `License Plate Number,Last Seen,First Seen,Visits${
      mmct ? ",Make,Model,Color,Type" : ""
    }`,
    ...visitRows,
  ];
}
