import { Tooltip, TooltipProps } from "@mui/material";
import clsx from "clsx";

/**
 * Shows a tooltip when disabled. Also wraps the children in a `<span>`, because a toolip
 * cannot render around a disabled interactive element (e.g. <button disabled>);
 */
export function DisabledTooltip({
  disabled,
  children,
  classes,
  ...props
}: TooltipProps & { disabled?: boolean; classes?: { wrapper?: string } }) {
  if (!disabled) return children;
  return (
    <Tooltip {...props}>
      <span
        className={clsx(classes?.wrapper, "pointer-events-auto")}
        onClick={(e) => e.preventDefault()}
      >
        {children}
      </span>
    </Tooltip>
  );
}
