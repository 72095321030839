import CheckIcon from "@mui/icons-material/Check";
import { Box, Button } from "@mui/material";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { TabSpec } from "@/pages/Settings/LocationSettings/SetupFlow";
import { useSettingsRoutes } from "@/pages/Settings/SettingsRoutes";

const animationDuration = 0.3;
const useStyles = makeStyles()((theme) => ({
  numberContainer: {
    borderRadius: "50%",
    background: "white",
    width: 25,
    height: 25,
    justifyContent: "center",
    alignItems: "center",
    transition: `all ${animationDuration}s ease-in-out`,
  },
  number: {
    color: "#007CE4",
  },
  substep: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: "flex",
    placeItems: "center",
    color: "white",
    justifyContent: "center",
    transition: `all ${animationDuration}s ease-in-out`,
    borderRadius: 0,
  },
  done: {
    background: "#08C500",
    "&:hover": {
      backgroundColor: "rgba(8, 197, 0, 0.8)",
    },
  },
  upcoming: {
    background: "#1391fb",
    "&:hover": {
      backgroundColor: "rgba(19, 145, 251, 0.5)",
    },
  },
  awaiting: {
    opacity: 0.4,
    pointerEvents: "none",
  },
  helpButton: {
    alignSelf: "center",
    color: "white",
    borderRadius: 6,
    marginRight: theme.spacing(2),
    transition: `all ${animationDuration}s ease-in-out`,
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
  },
  unfinishedButton: {
    background: "RGBA(255,255,255,0.1)",
    border: "1px solid RGBA(255,255,255,0.35)",
  },
}));

interface WalkthroughBarProps {
  steps: TabSpec[];
  locationId: number;
}

export default function WalkthroughBar({
  steps,
  locationId,
}: WalkthroughBarProps) {
  const [currentStep, setCurrentStep] = useState(0);
  useEffect(() => {
    for (let [idx, step] of steps.entries()) {
      if (!step.complete) {
        setCurrentStep(idx);
        return;
      }
    }
  }, [steps]);
  const { classes } = useStyles();
  const navigate = useNavigate();
  const settingsRoutes = useSettingsRoutes();
  return (
    <Box>
      <Box
        display="flex"
        alignItems="stretch"
        width="100%"
        height={45}
        style={{ background: "#007CE4" }}
      >
        <Box
          className={clsx(classes.substep)}
          style={{
            minWidth: 60,
            fontWeight: "bold",
            background: "#08C500",
          }}
        >
          Genius Setup
        </Box>
        {steps.map(({ name, tab }, idx) => (
          <Step
            complete={idx < currentStep}
            upcoming={idx === currentStep}
            num={idx + 1}
            title={name}
            key={idx + 1}
            onClick={() => {
              navigate(`${settingsRoutes.locations}/${locationId}/${tab}`);
            }}
            disabled={idx > currentStep}
          />
        ))}
        <Box flexGrow="1" />
        <Button
          style={{
            transition: "all 0.2s ease-in-out",
            margin: 0,
            marginRight: 4,
          }}
          target="_blank"
          rel="noopener noreferrer"
          href={`mailto:help@spotai.co?subject=Spotai Location Onboarding Issue`}
          className={classes.helpButton}
        >
          Need Help?
        </Button>
      </Box>
    </Box>
  );
}

interface StepProps {
  complete?: boolean;
  upcoming?: boolean;
  num: number;
  title: string;
  onClick: () => void;
  disabled: boolean;
}

function Step({ upcoming, complete, num, title, onClick }: StepProps) {
  const { classes } = useStyles();
  return (
    <Button
      onClick={onClick}
      className={clsx(
        classes.substep,
        { [classes.awaiting]: !upcoming && !complete },
        { [classes.upcoming]: upcoming },
        { [classes.done]: complete }
      )}
    >
      <Box className={classes.numberContainer} display="flex">
        {complete ? (
          <CheckIcon
            style={{
              height: "100%",
              width: "100%",
              fontSize: 30,
              color: "#08C500",
            }}
          />
        ) : (
          <p className={classes.number}>{num}</p>
        )}
      </Box>
      <Box m={1} />
      <strong>{title}</strong>
    </Button>
  );
}
