import { LPRIcon } from "@/icons/LPRIcon";

import { pluralize } from "@/util/pluralize";

import { intelligentFiltersConfig } from "@/pages/Search/intelligence/intelligence";

export const debugThroughputPaneConfig = {
  vehicleLpr: {
    id: "vehicleLpr" as const,
    title: "Vehicle License Plate",
    pluralize: (count: number) =>
      pluralize(
        {
          1: `vehicle license plate`,
          multi: `vehicle license plates`,
        },
        count
      ),
    icon: <LPRIcon className="text-primary" />,
    color: (opacity: number = 1) => `rgba(0, 214, 74, ${opacity})`,
    objectIds: ["vehiclePlate"],
    objectConfidenceThreshold: [0],
  },
  ...intelligentFiltersConfig,
};

export const idToFilterDebugConfigMap = Object.fromEntries(
  Object.entries(debugThroughputPaneConfig).flatMap(([_, config]) =>
    config.objectIds.map((id) => [id, config])
  )
);
