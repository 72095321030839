import { IconProps } from "@/icons/IconProps";

export function AI({ style, className, onClick }: IconProps) {
  return (
    <svg
      style={style}
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <circle
        r="0.984925"
        transform="matrix(0.700972 -0.713189 0.700972 0.713189 2.38081 10.0491)"
        fill="#8C9CB0"
      />
      <circle
        r="0.984925"
        transform="matrix(0.700972 -0.713189 0.700972 0.713189 4.97163 12.7083)"
        fill="#8C9CB0"
      />
      <circle
        r="0.984925"
        transform="matrix(0.70097 -0.713191 0.70097 0.713191 7.56928 15.3684)"
        fill="#8C9CB0"
      />
      <circle
        r="0.984925"
        transform="matrix(0.70097 -0.713191 0.70097 0.713191 9.84174 18.0277)"
        fill="#8C9CB0"
      />
      <circle
        r="0.984925"
        transform="matrix(0.700972 -0.713189 0.700972 0.713189 4.97163 7.72205)"
        fill="#8C9CB0"
      />
      <circle
        r="0.984925"
        transform="matrix(0.700972 -0.713189 0.700972 0.713189 7.24116 10.0491)"
        fill="#353D48"
      />
      <circle
        r="0.984925"
        transform="matrix(0.70097 -0.713191 0.70097 0.713191 9.84174 12.7083)"
        fill="#353D48"
      />
      <circle
        r="0.984925"
        transform="matrix(0.700972 -0.713189 0.700972 0.713189 12.4326 15.3684)"
        fill="#8C9CB0"
      />
      <circle
        r="0.984925"
        transform="matrix(0.700972 -0.713189 0.700972 0.713189 7.56929 5.06287)"
        fill="#8C9CB0"
      />
      <circle
        r="0.984925"
        transform="matrix(0.700972 -0.713189 0.700972 0.713189 9.84175 7.39014)"
        fill="#353D48"
      />
      <circle
        r="1.64154"
        transform="matrix(0.700972 -0.713189 0.700972 0.713189 12.3805 10.0491)"
        fill="#007CE4"
      />
      <circle
        r="0.984925"
        transform="matrix(0.70097 -0.713191 0.70097 0.713191 15.0273 12.7083)"
        fill="#8C9CB0"
      />
      <circle
        r="0.984925"
        transform="matrix(0.700972 -0.713189 0.700972 0.713189 9.84175 2.40491)"
        fill="#8C9CB0"
      />
      <circle
        r="0.984925"
        transform="matrix(0.700972 -0.713189 0.700972 0.713189 12.4326 5.06287)"
        fill="#8C9CB0"
      />
      <circle
        r="0.984925"
        transform="matrix(0.700972 -0.713189 0.700972 0.713189 15.0273 7.72205)"
        fill="#8C9CB0"
      />
      <circle
        r="0.984925"
        transform="matrix(0.700972 -0.713189 0.700972 0.713189 17.6191 10.0491)"
        fill="#8C9CB0"
      />
    </svg>
  );
}
