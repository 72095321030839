import React from "react";

import { createIconComponent } from "@/icons/createIconComponent";

import { ReactComponent as ZoneIcon_ } from "./zone-icon.svg";

export const ZoneIcon = createIconComponent(ZoneIcon_);

export function DrawExample() {
  return (
    <svg width="133" height="75" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 52.566 22.415 2.44 95.44 6.6 131 55.438" stroke="#EF00B6" />
      <circle cx="2.914" cy="55.151" r="2.914" fill="#EF00B6" />
      <circle cx="23.117" cy="2.914" r="2.914" fill="#EF00B6" />
      <circle cx="95.805" cy="7.33" r="2.914" fill="#EF00B6" />
      <path
        opacity=".5"
        d="M129.407 55.788 43.158 72.013"
        stroke="#EF00B6"
        strokeWidth="2"
        strokeDasharray="2 2"
      />
      <circle cx="130.086" cy="55.151" r="2.914" fill="#EF00B6" />
      <circle cx="42.63" cy="71.878" r="2.562" fill="#fff" />
      <path
        d="M56.28 61.39v.008c-.002.018.006.07.082.17.072.096.174.196.291.31l.007.006.067.07c.145.15.3.31.418.47.137.185.257.41.246.668-.01.254-.144.483-.291.674-.15.194-.344.386-.523.561l-2.938 2.945-.177.177-.177-.177-1.01-1.01-.177-.177.177-.177 2.847-2.84-.33-.33-.834.828-.176.175-.176-.176-2.669-2.669-.177-.177.177-.176 2.733-2.726.176.177a.709.709 0 0 1 1.004 0l1.43 3.396Zm0 0 .001-.008c.001-.04.024-.105.103-.21.078-.102.184-.208.308-.333.371-.371.38-.994-.002-1.359l-1.661-1.66 1.252 3.57Zm-12.545 6.362-.073.073V70.847H46.684l.074-.073 6.803-6.81.176-.177-.176-.177-2.669-2.669-.177-.177-.177.177-6.803 6.81Z"
        fill="#000"
        stroke="#fff"
        strokeWidth=".5"
      />
    </svg>
  );
}
