import {
  FormControl,
  FormControlLabel,
  FormGroup,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
} from "@mui/material";
import gql from "graphql-tag";
import { orderBy } from "lodash/fp";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { NumberParam, useQueryParam } from "use-query-params";

import { useMe } from "@/components/Auth";

import {
  GetOrgUsersHasNotificationsQuery,
  Role,
  useGetOrgUsersHasNotificationsQuery,
} from "@/generated-models";

export function InternalAlertUserSelect() {
  const me = useMe();
  const [orgUserId, setOrgUserId] = useQueryParam("ouid", NumberParam);
  const [showUserSelect, setShowUserSelect] = useState(Boolean(orgUserId));
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: orgUsers } = useGetOrgUsersHasNotificationsQuery({
    skip: !me || me.role < Role.Sales,
  });

  const sortedOrgUsers = orderBy(
    (u) => u.name.toLowerCase(),
    "asc",
    orgUsers?.users ?? ([] as GetOrgUsersHasNotificationsQuery["users"])
  );

  if (!me || me.role < Role.Sales) return null;

  const toggleAdminAlerts = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.checked) {
      searchParams.delete("userId");
      setSearchParams(searchParams);
    }
    setShowUserSelect(event.target.checked);
  };

  const handleSelectedUserChange = (event: SelectChangeEvent) => {
    setOrgUserId(parseInt(event.target.value, 10));
  };

  return (
    <div className="bg-[#DDEFFF] shadow-divider py-3 px-6 flex justify-between">
      <div className="">
        <div className="font-bold">Alerts Displayed</div>
        <FormGroup className="block">
          <FormControlLabel
            className="mx-0 text-sm"
            control={
              <Switch
                checked={showUserSelect}
                defaultChecked
                size="small"
                onChange={toggleAdminAlerts}
              />
            }
            label="Only Customer Alerts"
            labelPlacement="start"
          />
        </FormGroup>
      </div>
      {showUserSelect && (
        <div className="w-60">
          {Boolean(sortedOrgUsers.length) ? (
            <FormControl variant="outlined" fullWidth sx={{ minWidth: 120 }}>
              <Select
                labelId="demo-simple-select-label"
                className="h-10 bg-white"
                id="demo-simple-select"
                value={orgUserId?.toString() ?? undefined}
                displayEmpty
                variant="outlined"
                onChange={handleSelectedUserChange}
              >
                <MenuItem value="">Select User</MenuItem>
                <ListSubheader>Users with notifications</ListSubheader>
                {sortedOrgUsers
                  .filter((user) => user.hasNotifications)
                  .map((orgUser) => (
                    <MenuItem key={orgUser.id} value={orgUser.orgUserId}>
                      {orgUser.name}
                    </MenuItem>
                  ))}
                <ListSubheader>Users without notifications</ListSubheader>
                {sortedOrgUsers
                  .filter((user) => !user.hasNotifications)
                  .map((orgUser) => (
                    <MenuItem key={orgUser.id} value={orgUser.orgUserId}>
                      {orgUser.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          ) : (
            <div>No users in organization</div>
          )}
        </div>
      )}
    </div>
  );
}

gql`
  query getOrgUsersHasNotifications {
    users {
      id
      orgUserId
      name
      hasNotifications
    }
  }
`;
