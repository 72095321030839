import HelpIcon from "@mui/icons-material/Help";
import { motion } from "framer-motion";

import { useExitIfFullscreen } from "@/components/FullScreen";

import { useCopilotWelcomeModalOpen } from "../CopilotWelcomeModal/CopilotWelcomeModal";
import { useLaunchCopilot } from "./copilotPlayerSectionHooks";

export function CopilotHelpButton() {
  const { open, setOpen } = useCopilotWelcomeModalOpen();
  const launchCopilot = useLaunchCopilot();
  const exitIfFullscreen = useExitIfFullscreen();

  function toggleModal() {
    if (!open) {
      exitIfFullscreen();
      launchCopilot();
    }
    setOpen((o) => !o);
  }

  return (
    <motion.button
      type="button"
      className="pointer-events-auto bg-transparent -mt-[2px]"
      onClick={(e) => {
        e.stopPropagation();
        toggleModal();
      }}
      animate="animate"
      whileHover={{ color: "#ffffff", transition: { duration: 0.2 } }}
      transition={{
        duration: 0.8,
      }}
      style={{
        color: "#925EFF",
      }}
    >
      <HelpIcon className="w-5 h-5 text-current" />
    </motion.button>
  );
}
