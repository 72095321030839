import { ReactComponent as ForkliftAbsentIcon_ } from "@/icons/forklift_absent.svg";
import { ReactComponent as ForkliftPresentIcon_ } from "@/icons/forklift_present.svg";
import { ReactComponent as IdleTimeIcon_ } from "@/icons/idle_time.svg";
import { ReactComponent as LPRIcon_ } from "@/icons/lpr.svg";
import { ReactComponent as MotionIcon_ } from "@/icons/motion.svg";
import { ReactComponent as PeopleAbsentIcon_ } from "@/icons/people_absent.svg";
import { ReactComponent as PeoplePresentIcon_ } from "@/icons/people_present.svg";
import { ReactComponent as CarIcon_ } from "@/icons/vehicle.svg";

import { createIconComponent } from "./createIconComponent";

export const CarIcon = createIconComponent(CarIcon_);
export const ForkliftAbsentIcon = createIconComponent(ForkliftAbsentIcon_);
export const ForkliftPresentIcon = createIconComponent(ForkliftPresentIcon_);
export const IdleTimeIcon = createIconComponent(IdleTimeIcon_);
export const MotionIcon = createIconComponent(MotionIcon_);
export const PeopleAbsentIcon = createIconComponent(PeopleAbsentIcon_);
export const PeoplePresentIcon = createIconComponent(PeoplePresentIcon_);
export const LPRIcon = createIconComponent(LPRIcon_);
