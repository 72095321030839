import gql from "graphql-tag";

export const CASE_EVENT_FRAGMENT = gql`
  fragment CaseEventBase on CaseEvent {
    id
    triggeredBy {
      id
      name
    }
    createdAt
    eventType
    metadata
  }
`;

export const SHARED_CASE_EVENT_FRAGMENT = gql`
  fragment SharedCaseEventBase on SharedCaseEvent {
    id
    triggeredBy {
      name
    }
    createdAt
    eventType
    metadata
  }
`;

const SHARED_CASE_ATTACHMENT_FRAGMENT = gql`
  fragment SharedCaseAttachmentBase on SharedCaseAttachment {
    id
    type
    label
    url
  }
`;

const CASE_ATTACHMENT_FRAGMENT = gql`
  fragment CaseAttachmentBase on CaseAttachment {
    id
    type
    label
    url
  }
`;

export const CASE_COLLABORATOR_FRAGMENT = gql`
  fragment CaseCollaboratorBase on CaseCollaborator {
    id
    orgUserId
    name
    email
    caseRole
  }
`;

export const CASE_PERMISSIONS_FRAGMENT = gql`
  fragment CasePermissionsBase on CasePermissions {
    access
    comment
    manage_files
    manage_media
    manage_collaborators
    change_status
    edit_info
    share
    download
    delete
  }
`;

export const SHARED_CASE_PERMISSIONS_FRAGMENT = gql`
  fragment SharedCasePermissionsBase on SharedCasePermissions {
    access
    comment
    manage_files
    manage_media
    manage_collaborators
    change_status
    edit_info
    share
    download
    delete
  }
`;

export const CASE_FRAGMENT = gql`
  fragment CaseBase on Case {
    id
    createdBy {
      id
      name
    }
    caseCollaborators {
      ...CaseCollaboratorBase
    }
    createdAt
    title
    description
    resolutionStatus
    incidentDate
    tags

    clips {
      id

      camera {
        id

        location {
          id
          name
        }
      }
    }

    screenshots {
      id

      camera {
        id

        location {
          id
          name
        }
      }
    }

    annotations {
      id
      label
      content
      mentions
      time

      clip {
        id
      }

      caseScreenshotId
    }

    events {
      ...CaseEventBase
    }

    attachments {
      ...CaseAttachmentBase
    }

    permissions {
      ...CasePermissionsBase
    }
  }
  ${CASE_EVENT_FRAGMENT}
  ${CASE_ATTACHMENT_FRAGMENT}
  ${CASE_COLLABORATOR_FRAGMENT}
  ${CASE_PERMISSIONS_FRAGMENT}
`;

export const SHARED_CASE_FRAGMENT = gql`
  fragment SharedCaseBase on SharedCase {
    id
    createdBy {
      name
    }

    createdAt
    title
    description
    resolutionStatus
    incidentDate
    tags

    clips {
      id

      camera {
        id

        location {
          id
          name
        }
      }
    }

    screenshots {
      id

      camera {
        id

        location {
          id
          name
        }
      }
    }

    annotations {
      id
      label
      content
      time

      clip {
        id
      }

      caseScreenshotId
    }

    events {
      ...SharedCaseEventBase
    }

    attachments {
      ...SharedCaseAttachmentBase
    }

    permissions {
      ...SharedCasePermissionsBase
    }
  }
  ${SHARED_CASE_EVENT_FRAGMENT}
  ${SHARED_CASE_ATTACHMENT_FRAGMENT}
  ${SHARED_CASE_PERMISSIONS_FRAGMENT}
`;

export const CASE_COMMENT_FRAGMENT = gql`
  fragment CaseCommentBase on CaseComment {
    id
    user {
      id
      name
      email
    }
    createdAt
    content
    mentions
    clip {
      id
      startTime
      endTime
    }
    caseScreenshotId
  }
`;

export const SHARED_CASE_COMMENT_FRAGMENT = gql`
  fragment SharedCaseCommentBase on SharedCaseComment {
    id
    user {
      name
    }
    createdAt
    content
    clip {
      id
      startTime
      endTime
    }
    caseScreenshotId
  }
`;

export const CASE_COMMENT_WITH_REPLIES_FRAGMENT = gql`
  fragment CaseCommentWithReplies on CaseComment {
    ...CaseCommentBase
    replies {
      ...CaseCommentBase
    }
  }
  ${CASE_COMMENT_FRAGMENT}
`;

export const SHARED_CASE_COMMENT_WITH_REPLIES_FRAGMENT = gql`
  fragment SharedCaseCommentWithReplies on SharedCaseComment {
    ...SharedCaseCommentBase
    replies {
      ...SharedCaseCommentBase
    }
  }
  ${SHARED_CASE_COMMENT_FRAGMENT}
`;

export const CASE_CLIP_ANNOTATION_BASE_FRAGMENT = gql`
  fragment CaseClipAnnotationBase on CaseClipAnnotation {
    id
    user {
      id
      name
      email
    }
    createdAt
    label
    content
    replies {
      ...CaseCommentBase
    }
    clip {
      id
      startTime
      endTime
    }
    caseScreenshotId
    time
    position {
      x
      y
    }
    mentions
  }
  ${CASE_COMMENT_FRAGMENT}
`;

export const SHARED_CASE_CLIP_ANNOTATION_BASE_FRAGMENT = gql`
  fragment SharedCaseClipAnnotationBase on SharedCaseClipAnnotation {
    id
    user {
      name
    }
    createdAt
    label
    content
    replies {
      ...SharedCaseCommentBase
    }
    clip {
      id
      startTime
      endTime
    }
    caseScreenshotId
    time
    position {
      x
      y
    }
  }
  ${SHARED_CASE_COMMENT_FRAGMENT}
`;

export const CASE_WITH_MEDIA_FRAGMENT = gql`
  fragment CaseWithMedia on Case {
    id

    clips {
      id
      camera {
        id
        name

        location {
          id
          name
          timezone
        }
      }
      createdAt
      startTime
      endTime

      annotations {
        id
        label
        content
        mentions
        time
      }

      syncedToCloud
      stills {
        timestamp
        src
      }
    }
    screenshots {
      id
      camera {
        id
        name

        location {
          id
          name
          timezone
        }
      }
      timestamp
      source

      annotations {
        id
        label
        content
        mentions
        time
      }
    }

    permissions {
      ...CasePermissionsBase
    }
  }
  ${CASE_PERMISSIONS_FRAGMENT}
`;

export const SHARED_CASE_WITH_MEDIA_FRAGMENT = gql`
  fragment SharedCaseWithMedia on SharedCase {
    id

    clips {
      id
      camera {
        id
        name

        location {
          id
          name
          timezone
        }
      }
      createdAt
      startTime
      endTime

      annotations {
        id
        label
        content
        time
      }

      stills {
        timestamp
        src
      }
    }
    screenshots {
      id
      camera {
        id
        name

        location {
          id
          name
          timezone
        }
      }
      timestamp
      source

      annotations {
        id
        label
        content
        time
      }
    }

    permissions {
      ...SharedCasePermissionsBase
    }
  }
  ${SHARED_CASE_PERMISSIONS_FRAGMENT}
`;

export const CASE_METADATA_FRAGMENT = gql`
  fragment CaseMetadata on Case {
    id
    comments {
      ...CaseCommentWithReplies
    }

    annotations {
      ...CaseClipAnnotationBase
    }

    clips {
      id
      createdAt
      createdBy {
        id
        name
      }
    }

    screenshots {
      id
      createdAt
      createdBy {
        id
        name
      }
    }

    events {
      ...CaseEventBase
    }
    permissions {
      ...CasePermissionsBase
    }
  }

  ${CASE_COMMENT_WITH_REPLIES_FRAGMENT}
  ${CASE_CLIP_ANNOTATION_BASE_FRAGMENT}
  ${CASE_EVENT_FRAGMENT}
  ${CASE_PERMISSIONS_FRAGMENT}
`;

export const CASE_CLIP_FRAGMENT = gql`
  fragment CaseClip on CaseClip {
    id
    startTime
    endTime
    stills {
      timestamp
      src
    }
    camera {
      id
      name
      location {
        id
        timezone
        name
      }
      audioControlEnabled
    }
    feeds {
      tunnel
      local
    }
    annotations {
      ...CaseClipAnnotationBase
    }
    case {
      id
    }
  }
  ${CASE_CLIP_ANNOTATION_BASE_FRAGMENT}
`;

export const SHARED_CASE_METADATA_FRAGMENT = gql`
  fragment SharedCaseMetadata on SharedCase {
    id
    comments {
      ...SharedCaseCommentWithReplies
    }

    annotations {
      ...SharedCaseClipAnnotationBase
    }

    clips {
      id
      createdAt
      createdBy {
        name
      }
    }

    screenshots {
      id
      createdAt
      createdBy {
        name
      }
    }

    events {
      ...SharedCaseEventBase
    }
    permissions {
      ...SharedCasePermissionsBase
    }
  }

  ${SHARED_CASE_COMMENT_WITH_REPLIES_FRAGMENT}
  ${SHARED_CASE_CLIP_ANNOTATION_BASE_FRAGMENT}
  ${SHARED_CASE_EVENT_FRAGMENT}
  ${SHARED_CASE_PERMISSIONS_FRAGMENT}
`;

export const SHARED_CASE_CLIP_FRAGMENT = gql`
  fragment SharedCaseClip on SharedCaseClip {
    id
    startTime
    endTime
    stills {
      timestamp
      src
    }
    camera {
      id
      name
      location {
        id
        timezone
        name
      }
      audioControlEnabled
    }
    feeds {
      tunnel
      local
    }
    annotations {
      ...SharedCaseClipAnnotationBase
    }
    case {
      id
    }
  }
  ${SHARED_CASE_CLIP_ANNOTATION_BASE_FRAGMENT}
`;
