import HelpIcon from "@mui/icons-material/Help";
import { Typography, Paper, Popover, IconButton } from "@mui/material";
import React, { useState, useRef } from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  paper: {
    maxWidth: 500,
    padding: 13,
  },
  title: {
    marginRight: 17,
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: "bold",
    lineHeight: 1.14,
    letterSpacing: -0.34,
    color: "#353d48",
    marginBottom: 5,
  },
  description: {
    marginRight: 17,
    fontFamily: "Roboto",
    fontSize: 14,
    lineHeight: "normal",
    letterSpacing: -0.29,
    color: "#353d48",
  },
  newFeature: {
    fontFamily: "Roboto",
    fontSize: 10,
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "italic",
    lineHeight: 2,
    letterSpacing: -0.34,
    color: "#007ce4",
  },
  helpIcon: {
    fontSize: 15,
    color: "#c4c4c4",
    backgroundColor: "black",
    borderRadius: "50%",
    opacity: 0.4,
    borderColor: "transparent",
  },
}));

export function HelpPopover({
  newFeature = false,
  title,
  description,
}: {
  newFeature?: boolean;
  title: string;
  description: string;
}) {
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);

  const helpButtonRef = useRef(null as null | HTMLButtonElement);

  return (
    <>
      <IconButton
        size={"small"}
        ref={helpButtonRef}
        aria-label="Zone based motion help"
        onClick={() => setOpen(true)}
      >
        <HelpIcon className={classes.helpIcon} />
      </IconButton>
      <Popover
        style={{ opacity: 0.95 }}
        open={open}
        anchorEl={helpButtonRef.current}
        onClose={() => {
          setOpen(false);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Paper className={classes.paper}>
          <Typography className={classes.title}>
            {title}
            {newFeature && (
              <span className={classes.newFeature}>
                {" "}
                &nbsp; &nbsp; &nbsp;New Feature!
              </span>
            )}
          </Typography>
          <Typography className={classes.description}>{description}</Typography>
        </Paper>
      </Popover>
    </>
  );
}
