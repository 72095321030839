import { InputBase, styled, alpha, InputLabel } from "@mui/material";

export const StyledInput = styled(InputBase)(({ theme }) => ({
  width: "100%",

  "& .MuiInputBase-input": {
    borderRadius: 8,
    position: "relative",
    backgroundColor: "white",
    border: "1px solid rgba(53, 61, 72, 0.36)",
    fontSize: 14,
    width: "100%",
    padding: "12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

export interface StyledInputProps {
  id: string;
  title: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  fullWidth?: boolean;
  type?: string;
}

export const StyledInputWithLabel = ({
  id,
  title,
  value,
  onChange,
  disabled,
  fullWidth,
  type,
}: StyledInputProps) => (
  <>
    <InputLabel shrink htmlFor={id}>
      {title}
    </InputLabel>
    <StyledInput
      id={id}
      name={id}
      type={type}
      value={value}
      onChange={onChange}
      disabled={disabled}
      fullWidth={fullWidth}
    />
  </>
);
