/**
 * Works like a regular `debounce`, but accumulates all args that have
 * been passed during the debouncing timeframe, and passes them as a list
 * to the original function. Assumes the callback to take a single argument.
 * @param func
 * @param wait
 */
export function debounceAccumulate<T>(
  func: (args: T[]) => void,
  wait: number,
  immediateCallback?: (arg: T) => void
) {
  let timerId: any;
  const accumulatedArgs: T[] = [];

  return (arg: T) => {
    if (immediateCallback) immediateCallback(arg);
    if (timerId) clearTimeout(timerId);

    accumulatedArgs.push(arg);

    timerId = setTimeout(() => {
      func(accumulatedArgs);

      // Reset debounce
      accumulatedArgs.length = 0;
      timerId = null;
    }, wait);
  };
}
