import { useApolloClient } from "@apollo/client";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  useTheme,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { debounce } from "lodash/fp";
import React, { useCallback, useState } from "react";
import { makeStyles } from "tss-react/mui";

import { DeviceIcon } from "@/icons/Device";

import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";

import {
  ApplianceStatus,
  CheckApplianceStatusDocument,
  CheckApplianceStatusQueryResult,
  CheckApplianceStatusQueryVariables,
  useAddAppliancesMutation,
} from "@/generated-models";

const useStyles = makeStyles()((theme) => ({
  appliance: {
    padding: "24px 16px",
    marginBottom: 4,
    backgroundColor: "white",
  },
}));
interface ApplianceFormProps {
  locationId: number;
  onSubmit?: (serialNumber: string) => void;
  onDelete?: (serialNumber: string) => void;
}

export default function ApplianceForm({
  locationId,
  onSubmit,
  onDelete,
}: ApplianceFormProps) {
  const { classes } = useStyles();
  const theme = useTheme();
  const client = useApolloClient();
  const feedback = useFeedback();
  const [addAppliances] = useAddAppliancesMutation({
    awaitRefetchQueries: true,
    refetchQueries: ["locationWithAppliances"],
    onError: () =>
      feedback.pushSnackbar(
        "Adding appliance failed, please try again",
        FeedbackType.Error
      ),
  });
  const [validating, setValidating] = useState(false);
  // eslint-disable-next-line
  const validate = useCallback(
    debounce(
      1000,
      async (value: string, callback: (error: string | undefined) => void) => {
        if (!value) {
          return callback("Please fill out the serial number");
        }

        setValidating(true);
        const {
          data,
          error,
        }: CheckApplianceStatusQueryResult = (await client.query<
          any,
          CheckApplianceStatusQueryVariables
        >({
          query: CheckApplianceStatusDocument,
          variables: { serialNumber: value.toLowerCase() },
          fetchPolicy: "network-only",
        })) as any;
        setValidating(false);

        if (error) return callback("Something went wrong");

        if (data && data.applianceStatus) {
          if (data.applianceStatus === ApplianceStatus.NotFound)
            return callback("Appliance not found");
          if (data.applianceStatus === ApplianceStatus.AlreadySetUp)
            return callback("Appliance has already been set up");
        }

        callback(undefined);
      }
    ),
    []
  );
  return (
    <Formik
      validateOnChange={true}
      validateOnBlur={false}
      initialValues={{ serialNumber: "" }}
      initialErrors={{ serialNumber: "" }}
      initialStatus={{ validating: false }}
      onSubmit={async ({ serialNumber }, { resetForm }) => {
        if (serialNumber) {
          await addAppliances({
            variables: { locationId, serialNumbers: [serialNumber] },
          });
        }
        resetForm();
        if (onSubmit) onSubmit(serialNumber);
      }}
    >
      {({ isSubmitting, touched, values, isValid, setFieldTouched }) => (
        <Form>
          <Grid
            container
            alignItems="center"
            wrap="nowrap"
            className={classes.appliance}
          >
            <Grid container item xs={11} alignItems="center" wrap="nowrap">
              <DeviceIcon style={{ opacity: 0.4 }} />
              <Field
                name="serialNumber"
                label="Serial Number"
                disabled={isSubmitting}
                style={{ marginLeft: 8, width: 200 }}
                placeholder="sn1-abc123"
                component={TextField}
                helperText="Printed on a sticker on the device"
                InputLabelProps={{ shrink: true }}
                inputProps={{ style: { padding: "0 0 2px 0" } }}
                InputProps={{
                  style: { fontSize: 24, fontWeight: "bold" },
                  endAdornment: validating ? (
                    <InputAdornment position="end">
                      <CircularProgress size="14px" />
                    </InputAdornment>
                  ) : (
                    values.serialNumber &&
                    touched.serialNumber &&
                    isValid && (
                      <InputAdornment position="end">
                        <CheckIcon
                          style={{
                            color: theme.palette.success.main,
                          }}
                        />
                      </InputAdornment>
                    )
                  ),
                }}
                validate={async (value: string) => {
                  return new Promise((res) => {
                    validate(value, (error) => {
                      setFieldTouched("serialNumber");
                      res(error);
                    });
                  });
                }}
              />
              <Box m={1} />
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={isSubmitting || !isValid || !values.serialNumber}
              >
                Register Appliance
                {isSubmitting && (
                  <>
                    <Box m={1} />
                    <CircularProgress size="14px" />
                  </>
                )}
              </Button>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                color="primary"
                onClick={() => {
                  if (onDelete) onDelete(values.serialNumber);
                }}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
