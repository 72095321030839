import { ApolloError, useApolloClient } from "@apollo/client";
import { useUpdateEffect } from "@react-hookz/web";
import * as turf from "@turf/turf";
import gql from "graphql-tag";
import html2canvas from "html2canvas";
import { useAtom, useSetAtom } from "jotai";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useCallback } from "react";
import { linearRegressionLine, linearRegression } from "simple-statistics";
import { BooleanParam, useQueryParam, withDefault } from "use-query-params";

import { concat } from "@/util/apolloCache";
import { hasAdvancedAiLicense } from "@/util/license";
import { useBreakpoints } from "@/util/useBreakpoints";
import { useLocalStorage } from "@/util/useLocalStorage";

import {
  playingIntentState,
  useIsBuffering,
  usePlayerControls,
  usePlayingIntent,
  useWallclockTime,
  useZoomState,
} from "@/components/Player/PlayerBase";
import { captureFrame } from "@/components/Player/SnapshotButton";
import { useStillOverlayShown } from "@/components/Player/playerMachine";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import {
  useActiveCamIds,
  useFirstActiveCamId,
} from "@/components/View/sharedViewHooks";

import { refetchOnMountPolicy } from "@/apolloClient";
import { isProductionEnv } from "@/environment";
import {
  PointInput,
  PromptListType,
  UniversalSearchInput,
  useAddFocusMutation,
  useAddPromptListItemMutation,
  useCameraLicenseByIdQuery,
  useDeleteFocusMutation,
  useDeletePromptListItemMutation,
  usePromptListsQuery,
  useSendUniversalSearchFeedbackMutation,
  useUniversalSearchByImageQuery,
} from "@/generated-models";

import { useLibraryParams } from "./CopilotPlayerSection/CopilotLibraryPopover/copilotLibraryHooks";
import { SubjectType } from "./constant";
import { useCopilotContext } from "./useCopilotContext";
import { getBoundingBoxFromVolume } from "./utils";

export function useSendCopilotFeedback() {
  const { pushSnackbar } = useFeedback();
  const [sendFeedback, { loading }] = useSendUniversalSearchFeedbackMutation();
  const wallclockTime = useWallclockTime();
  const cameraId = useFirstActiveCamId();
  const time = wallclockTime?.toISOString() || "N/A";

  return {
    send: async (message: string) => {
      let image;
      const container = document.getElementById("root");

      if (container) {
        const canvas = await html2canvas(container as HTMLElement);
        const dataURL = canvas.toDataURL("image/jpeg");
        const blob = await fetch(dataURL).then((res) => res.blob());
        image = new File([blob], "screenshot.jpeg", { type: "image/jpeg" });
      }

      await sendFeedback({
        variables: {
          input: {
            image,
            message,
            url: window.location.href,
            cameraId,
            time,
          },
        },
        onCompleted: () => {
          pushSnackbar(
            "Feedback received. AI Copilot will learn from your examples",
            FeedbackType.Success
          );
        },
      });
    },
    loading,
  };
}

export function useIsCopilotCompatible() {
  const { fitsDesktop } = useBreakpoints();
  const { universalSearch } = useFlags();
  const activeCamIds = useActiveCamIds();
  return activeCamIds.length === 1 && universalSearch && fitsDesktop;
}

const CopilotParams = withDefault(BooleanParam, true);

export function useCopilotEnabled() {
  const wallClock = useWallclockTime();
  const { fitsDesktop } = useBreakpoints();
  const { universalSearch } = useFlags();
  const [playing] = useAtom(playingIntentState);
  const cameraId = useFirstActiveCamId();
  const playerId = cameraId?.toString();
  const isBuffering = useIsBuffering(playerId);
  const [copilotEnabled, setCopilotEnabled] = useQueryParam(
    "copilot",
    CopilotParams
  );
  const activeCamIds = useActiveCamIds();

  return {
    copilotEnabled:
      activeCamIds.length === 1 &&
      copilotEnabled &&
      fitsDesktop &&
      !!wallClock &&
      !playing &&
      !isBuffering &&
      universalSearch,
    setCopilotEnabled,
  };
}

export function useIsCopilotEnabled() {
  const { fitsDesktop } = useBreakpoints();
  const [copilotEnabled] = useQueryParam("copilot", BooleanParam);
  const activeCamIds = useActiveCamIds();
  return activeCamIds.length === 1 && copilotEnabled && fitsDesktop;
}

export function useCreateAutoZone(cameraId: number) {
  const { pushSnackbar } = useFeedback();
  const [addFocus, { loading }] = useAddFocusMutation({
    update: (cache, { data }) => {
      if (!data) return;
      cache.modify({
        id: `Camera:${cameraId}`,
        fields: {
          focusZones: concat({
            __ref: `Focus:${data.addFocus.id}`,
          }),
        },
      });
    },
    onError: (e) => {
      console.error(e);
      pushSnackbar(
        "Unable to create Zone, please try again later",
        FeedbackType.Error
      );
    },
  });

  return {
    loading,
    create: (
      name: string,
      shape: PointInput[],
      type: SubjectType,
      approximateLine?: boolean
    ) => {
      // 1. If approximate, generate a line zone.
      // 2. Approximate bounding box for certain subject types.
      // 3. Fallback to default shape if neither previous case applies.
      const formattedShape = approximateLine
        ? approximateLineFromPolygon(shape)
        : type === SubjectType.person || type === SubjectType.vehicle
        ? getBoundingBoxFromVolume(shape).volume
        : shape;

      return addFocus({
        variables: {
          cameraId,
          input: { name: name, shape: formattedShape },
        },
      });
    },
  };
}

// Generates an approximate line given a polygon.
// 1. Generate a best fit line
// 2. Generate a convex hull to smooth out the polygon
// 3. Fine the intersecting line between the best fit and convex hull.
function approximateLineFromPolygon(shape: PointInput[]) {
  const points = shape.map((s) => [s.x, s.y]);
  const lr = linearRegressionLine(linearRegression(points));
  const bestFit = [
    [0, lr(0)],
    [100, lr(100)],
  ];

  const cvxHyll = turf.convex(
    turf.featureCollection(points.map((p) => turf.point(p)))
  );

  const intersects = turf.lineIntersect(turf.lineString(bestFit), cvxHyll!);

  if (intersects.features.length === 0) return shape;

  return intersects.features.map((f) => ({
    x: f.geometry.coordinates[0],
    y: f.geometry.coordinates[1],
  })) as PointInput[];
}

export function useDeleteAutoZone() {
  const [deleteFocus, { loading }] = useDeleteFocusMutation();
  return {
    loading,
    deleteAutoZone: async (id: number) => {
      deleteFocus({
        variables: { focusId: id },
        update(cache, { data }) {
          if (!data) return;
          cache.evict({ id: `Focus:${data.deleteFocus}` });
        },
      });
    },
  };
}

// Returns an image from the current video feed.
function useGetFeedImage() {
  const wallclockTime = useWallclockTime()?.toISOString();
  const cameraId = useFirstActiveCamId();
  const playerId = cameraId.toString();
  const zoomState = useZoomState(playerId);
  const { getPlayerElement } = usePlayerControls(playerId);
  const [debugCopilotStill] = useLocalStorage("debugCopilotStill", false);

  return async () => {
    const playerElement = getPlayerElement();

    if (!playerElement) return { image: null, imageDataURL: null };

    const resolvedTime = `${wallclockTime}-${Date.now()}`;
    const filename = `${cameraId}-${resolvedTime}.jpg`;

    const dataURL = captureFrame(
      playerElement,
      filename,
      zoomState,
      undefined,
      !debugCopilotStill
    );
    const blob = await fetch(dataURL).then((res) => res.blob());
    return {
      image: new File([blob], filename, { type: "image/jpeg" }),
      imageDataURL: dataURL,
    };
  };
}

export function useGetUniveralSearchInput() {
  const [libraryParams] = useLibraryParams();
  const cameraId = useFirstActiveCamId();
  const wallclockTime = useWallclockTime()?.toISOString() || "";
  const { image } = useCopilotContext();

  return (input?: Partial<UniversalSearchInput>) => {
    return {
      cameraId,
      time: wallclockTime,
      image,
      promptOverride: libraryParams.object,
      ...(input || {}),
    };
  };
}

export function useRefetchUniversalSearch() {
  const client = useApolloClient();
  const { setImage, setFetchQueryState } = useCopilotContext();
  const getImage = useGetFeedImage();
  const getInput = useGetUniveralSearchInput();
  const { refetch, updateQuery } = useUniversalSearchByImage();

  return useCallback(
    async (input?: Partial<UniversalSearchInput>) => {
      setFetchQueryState({
        fetchQueryLoading: true,
        fetchQueryError: null,
      });

      const imageData = await getImage();

      setImage(imageData);

      try {
        const { data } = await refetch({
          input: getInput({
            image: imageData.image,
            ...input,
          }),
        });

        client.refetchQueries({
          updateCache(cache) {
            cache.evict({ fieldName: "aiAnnotations" });
          },
        });

        updateQuery(() => ({
          __typename: "Query",
          universalSearchByImage: data?.universalSearchByImage || [],
        }));
        setFetchQueryState({
          fetchQueryLoading: false,
          fetchQueryError: null,
        });

        return data;
      } catch (error) {
        setFetchQueryState({
          fetchQueryLoading: false,
          fetchQueryError: error as ApolloError,
        });
        return null;
      }
    },
    [
      client,
      getImage,
      getInput,
      refetch,
      setFetchQueryState,
      setImage,
      updateQuery,
    ]
  );
}

// Handles coordinating when copilot should be re-queried.
export function useUniversalSearchLaunchHandler() {
  const cameraId = useFirstActiveCamId();
  const playerId = cameraId.toString();
  const { copilotEnabled } = useCopilotEnabled();
  const { overrideImage, setOverrideImage } = useCopilotContext();
  const isPlaying = usePlayingIntent();
  const wallclockTime = useWallclockTime()?.toISOString().replace(/\.\d+/, "");
  const setPlaying = useSetAtom(playingIntentState);
  const isBuffering = useIsBuffering(playerId);
  const stillOverlayShown = useStillOverlayShown();
  const refetch = useRefetchUniversalSearch();

  const disabled =
    isPlaying ||
    !wallclockTime ||
    !copilotEnabled ||
    isBuffering ||
    stillOverlayShown;

  // Pause the player if it's ready and copilot is enabled.
  useUpdateEffect(() => {
    if (isPlaying && copilotEnabled && !isBuffering && !stillOverlayShown) {
      setPlaying(false);
    }
  }, [copilotEnabled, isBuffering, isPlaying, setPlaying, stillOverlayShown]);

  // Update ts to indicate that we should refetch copilot data.
  useUpdateEffect(() => {
    if (!disabled) {
      refetch();
    }
    if (overrideImage) {
      setOverrideImage(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled, wallclockTime]);
}

export function useUniversalSearchByImage() {
  const { fetchQueryError, fetchQueryLoading } = useCopilotContext();
  const getInput = useGetUniveralSearchInput();

  const query = useUniversalSearchByImageQuery({
    variables: {
      input: getInput(),
    },
    fetchPolicy: "cache-only",
    notifyOnNetworkStatusChange: true,
  });

  const result = query.data?.universalSearchByImage;

  return {
    ...query,
    id: result?.id,
    data: result?.items || [],
    error: fetchQueryError,
    loading: fetchQueryLoading,
  };
}

const listOrder = [
  PromptListType.Base,
  PromptListType.Global,
  PromptListType.Organization,
];
export function usePromptLists() {
  const query = usePromptListsQuery({ ...refetchOnMountPolicy });
  const promptLists = [...(query.data?.promptLists || [])];

  const data = promptLists
    .sort((a, b) => {
      return listOrder.indexOf(a.type) - listOrder.indexOf(b.type);
    })
    .map((l) => ({
      ...l,
      prompts: [...l.prompts].sort((a, b) => a.label.localeCompare(b.label)),
    }));

  return {
    ...query,
    data,
  };
}

export function useAddItemToOrgPromptList() {
  const { pushSnackbar } = useFeedback();
  const { data } = usePromptLists();
  const refetch = useRefetchUniversalSearch();

  function onError() {
    pushSnackbar(
      "We were unable to update the custom object list. Please try again later.",
      FeedbackType.Error
    );
  }

  const [updatePromptList, { loading }] = useAddPromptListItemMutation({
    onError,
  });

  return {
    loading,
    addItem: async (item?: string | null) => {
      const normalizedItem = item?.toLowerCase();
      const orgList = data.find((d) => d.type === PromptListType.Organization);

      if (!orgList || !normalizedItem) {
        onError();
        return Promise.resolve();
      }

      // Item is already in list, skip operation
      if (!!orgList.prompts.find((p) => p.label === normalizedItem)) {
        return Promise.resolve();
      }

      const result = await updatePromptList({
        variables: {
          input: {
            id: orgList.id,
            label: normalizedItem,
          },
        },
      });

      if (result.errors) {
        onError();
        return Promise.resolve();
      }

      // Refetch new data.
      await refetch({ promptOverride: undefined });

      return result;
    },
  };
}

export function useRemoveItemFromOrgPromptList() {
  const { pushSnackbar } = useFeedback();
  const { data } = usePromptLists();

  function onError() {
    pushSnackbar(
      "We were unable to update the custom object list. Please try again later.",
      FeedbackType.Error
    );
  }

  const [deleteItem, { loading }] = useDeletePromptListItemMutation({
    refetchQueries: ["promptLists"],
  });

  return {
    loading,
    removeItem: async (item?: string | null) => {
      const normalizedItem = item?.toLowerCase();
      const orgList = data.find((d) => d.type === PromptListType.Organization);

      if (!orgList || !normalizedItem) {
        onError();
        return Promise.resolve();
      }

      // Item is already removed from the list, skip operation
      if (!orgList.prompts.find((p) => p.label === normalizedItem)) {
        return Promise.resolve();
      }

      await deleteItem({
        variables: {
          input: {
            id: orgList.id,
            label: normalizedItem,
          },
        },
      });
    },
  };
}

export function useCombinedIgnoreList() {
  const { data } = usePromptLists();
  return data.flatMap((d) => d.ignoreList);
}

export function useHasAdvancedAi(cameraId: number) {
  const { copilotDisableProReq } = useFlags();
  const { data } = useCameraLicenseByIdQuery({ variables: { cameraId } });
  const hasAdvancedAi = hasAdvancedAiLicense(data?.camera?.appliance?.license);

  return hasAdvancedAi || copilotDisableProReq || !isProductionEnv;
}

gql`
  fragment PromptListFragment on PromptList {
    id
    name
    type
    organizationId
    ignoreList
    prompts {
      label
    }
  }
`;

gql`
  query promptLists {
    promptLists {
      ...PromptListFragment
    }
  }
`;

gql`
  query universalSearchByImage($input: UniversalSearchInput!) {
    universalSearchByImage(input: $input) {
      id
      items {
        ...AnnotationLabelItemFragment
      }
    }
  }
`;

gql`
  mutation sendUniversalSearchFeedback($input: UniversalSearchFeedbackInput!) {
    sendUniversalSearchFeedback(input: $input) {
      message
    }
  }
`;

gql`
  mutation createPromptList($input: CreatePromptListInput!) {
    createPromptList(input: $input) {
      ...PromptListFragment
    }
  }
`;

gql`
  mutation createOrgPromptList {
    createOrgPromptList {
      ...PromptListFragment
    }
  }
`;

gql`
  mutation updatePromptList($input: UpdatePromptListInput!) {
    updatePromptList(input: $input) {
      ...PromptListFragment
    }
  }
`;

gql`
  mutation addPromptListItem($input: AddPromptItemInput!) {
    addPromptListItem(input: $input) {
      ...PromptListFragment
    }
  }
`;

gql`
  mutation deletePromptListItem($input: DeletePromptItemInput!) {
    deletePromptListItem(input: $input) {
      message
    }
  }
`;
