import { Button, CircularProgress, Typography } from "@mui/material";
import clsx from "clsx";
import { utcToZonedTime } from "date-fns-tz";
import { format, startOfHour } from "date-fns/fp";
import gql from "graphql-tag";
import { useFlags } from "launchdarkly-react-client-sdk";
import { groupBy } from "lodash/fp";
import React, { useEffect, useState } from "react";

import { trackDownloadedStills } from "@/util/analytics";
import { downloadExternalFile } from "@/util/file";
import { useBreakpoints } from "@/util/useBreakpoints";
import { usePagination } from "@/util/usePagination";

import {
  useCameraPreviews,
  useRangeParam,
  useVodParam,
} from "@/pages/Search/searchHooks";

import { useMe } from "@/components/Auth";
import { ExpandingSnippetPreview } from "@/components/Camera/ExpandingSnippetPreview";
import { Loading } from "@/components/Loading";
import { useWallclockTime } from "@/components/Player/PlayerBase";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { useFocusedCam } from "@/components/View/sharedViewHooks";
import { ZendeskArticle, ZendeskLink } from "@/components/Zendesk/ZendeskLink";
import {
  QueryParamLink,
  SearchParamLink,
} from "@/components/shared/QueryParamLink";

import { Camera, useDownloadStillsZipLazyQuery } from "@/generated-models";
import { usePermissions } from "@/hooks/usePermissions";

import { ActiveAttributeFilters } from "./SubjectSearch/components/ActiveFiltersBar";

interface ClipSegmentsSectionProps
  extends Omit<ClipSegmentsListProps, "focusedCam"> {
  activeCams: ClipSegmentsListProps["focusedCam"][];
}

export function ClipSegmentsSection({
  activeCams,
  ...props
}: ClipSegmentsSectionProps) {
  const me = useMe();
  const hasPermission = usePermissions();
  const focusedCam = useFocusedCam(activeCams);
  const multiCam = activeCams.length > 1;

  return (
    <>
      <div className={clsx("px-2 md:px-4", { "bg-[#e2e2e2]": multiCam })}>
        <div className="flex gap-2 px-2 py-3 items-center">
          <Typography variant="h4">
            Clip Segments
            <ZendeskLink article={ZendeskArticle.SEGMENT_SCRUB} />
          </Typography>
          <DownloadStillsButton cameraId={focusedCam.id} />
          {me?.organization.flags.aiFaceRecognition &&
            hasPermission("people_search_access") && (
              <SearchParamLink
                className="relative px-6 py-1 ml-auto text-sm text-white spot-gradient rounded shadow-[0_4px_4px_rgba(0,0,0,0.25)]"
                to="people"
              >
                <span className="absolute -left-3.5 -top-1.5 px-1 text-2xs text-white bg-spotGreen italic font-bold rounded pointer-events-none">
                  NEW!
                </span>
                Search People
              </SearchParamLink>
            )}
        </div>
        <ActiveAttributeFilters />
        {multiCam && (
          <div className="flex gap-2 md:gap-5">
            {activeCams.map((cam, index) => {
              const focused = cam.id === focusedCam.id;
              return (
                <Button
                  key={cam.id}
                  component={QueryParamLink}
                  params={index > 0 ? { f: cam.id } : undefined}
                  removalParams={index === 0 ? ["f"] : undefined}
                  replace
                  disabled={focused}
                  variant={focused ? "contained" : "text"}
                  className={clsx("px-3 rounded-b-none", {
                    "bg-white text-text": focused,
                    "text-[#757575]": !focused,
                  })}
                  disableRipple
                  disableElevation
                >
                  <span className="truncate">{cam.name}</span>
                </Button>
              );
            })}
          </div>
        )}
      </div>
      <div id="search-result-list" className="px-4 md:px-6 pt-3">
        <ClipSegmentsList focusedCam={focusedCam} {...props} />
      </div>
    </>
  );
}

interface ClipSegmentsListProps {
  focusedCam: Pick<Camera, "id" | "name"> & { location: { timezone: string } };
  selectVod: (start: string, end: string, progress?: number) => void;
}

export function ClipSegmentsList({
  focusedCam,
  selectVod,
}: ClipSegmentsListProps) {
  const { pageCount, reset, visibilitySensor } = usePagination();
  const { vodStart, vodEnd } = useVodParam();
  const wallclockTime = useWallclockTime();
  const previews = useCameraPreviews(focusedCam.id);
  const { fitsDesktop } = useBreakpoints();
  const [activeClip, setActiveClip] = useState("");
  const { mobileScrubbingThumbnails } = useFlags();
  useEffect(() => reset(), [previews, reset]);

  if (!previews) {
    return (
      <div className="flex-center h-96 w-full">
        <Loading>Fetching clips</Loading>
      </div>
    );
  }

  const sortedPreviews = previews
    .slice(0)
    .sort((a, b) => (a.start > b.start ? 1 : -1));

  const paginatedPreviewGroups = Object.values(
    // Group by hour
    groupBy(
      (preview) => startOfHour(new Date(preview.start)).toISOString(),
      sortedPreviews
    )
  )
    // Apply pagination
    .slice(0, pageCount);

  // Bounding the wallclock time to the current VOD time selection, because the HLS video might
  // not perfectly align with the wallclock time due to video segments misaligning a few seconds.
  // Bounding within the VOD timeframe ensures the selected vod tile will we a tile that falls
  // within the actual VOD bounds.
  let boundedWallclockTime = wallclockTime;
  if (vodStart && vodEnd && wallclockTime) {
    boundedWallclockTime = new Date(
      Math.max(
        vodStart.getTime(),
        Math.min(vodEnd.getTime(), wallclockTime.getTime())
      )
    );
  }
  const currentTimeString = boundedWallclockTime?.toISOString();
  const { timezone } = focusedCam.location;

  if (previews.length === 0) {
    return (
      <Typography className="py-4 text-center">
        <strong>No events found.</strong> Try expanding the time range to search
        more video or increase your focus zone.
      </Typography>
    );
  }

  return (
    <>
      {paginatedPreviewGroups.map((group, index) => {
        return (
          <React.Fragment key={[focusedCam.id, group[0].start].join()}>
            <TimeBlockSeparator
              time={startOfHour(utcToZonedTime(group[0].start, timezone))}
              style={index === 0 ? { marginTop: 0 } : undefined}
            />
            <div
              className="grid gap-2 w-full"
              style={{
                gridTemplateColumns: "repeat(auto-fill, minmax(160px, 1fr))",
              }}
            >
              {group.map((preview) => {
                const start = new Date(preview.start);
                const end = new Date(preview.end);
                const selected = Boolean(
                  currentTimeString &&
                    currentTimeString >= preview.start &&
                    currentTimeString < preview.end
                );
                return (
                  <ExpandingSnippetPreview
                    key={preview.start}
                    {...preview}
                    selected={selected}
                    progress={
                      selected
                        ? (boundedWallclockTime!.getTime() - start.getTime()) /
                          (end.getTime() - start.getTime())
                        : undefined
                    }
                    timezone={timezone}
                    cameraId={focusedCam.id}
                    onClick={selectVod}
                    setActiveClip={setActiveClip}
                    activeClip={activeClip}
                  />
                );
              })}
            </div>
            {activeClip !== "" && mobileScrubbingThumbnails && !fitsDesktop && (
              <div
                className="fixed top-0 left-0 bg-black/60 w-screen h-screen z-50"
                onClick={() => setActiveClip("")}
              />
            )}
          </React.Fragment>
        );
      })}
      {visibilitySensor}
    </>
  );
}

function DownloadStillsButton({ cameraId }: { cameraId: number }) {
  const { rangeStart, rangeEnd } = useRangeParam();
  const feedback = useFeedback();
  const [
    downloadStillsZipFn,
    {
      data: downloadStillsZip,
      loading: downloadStillsZipLoading,
      stopPolling,
      refetch,
    },
  ] = useDownloadStillsZipLazyQuery({
    variables: {
      id: cameraId,
      start: rangeStart.toISOString(),
      end: rangeEnd.toISOString(),
    },
    pollInterval: 4000,
    onCompleted: (data) => {
      if (data && data.downloadStillsZip && data.downloadStillsZip.link) {
        feedback.pushSnackbar(
          "Successfully zipped stills",
          FeedbackType.Success
        );
        if (stopPolling) {
          stopPolling();
        }
        downloadExternalFile(data.downloadStillsZip.link);
        trackDownloadedStills();
      }
    },
    onError: (error) => {
      feedback.pushSnackbar(error.message, FeedbackType.Error);
      if (stopPolling) {
        stopPolling();
      }
    },
    notifyOnNetworkStatusChange: true,
  });

  return (
    <Button
      color="primary"
      className="text-primary text-xs"
      onClick={() => {
        if (downloadStillsZip?.downloadStillsZip.link && refetch) {
          refetch();
        } else {
          downloadStillsZipFn();
        }
      }}
    >
      {downloadStillsZipLoading ||
      downloadStillsZip?.downloadStillsZip.link === null ? (
        <CircularProgress size={15} />
      ) : (
        "Download Stills"
      )}
    </Button>
  );
}

// const useClipDurationStyles = makeStyles()({
//   label: {
//     fontSize: "inherit",
//     fontWeight: "inherit",
//     color: "inherit",
//     display: "inline",
//   },
//   select: {
//     marginLeft: 8,
//     fontSize: "inherit",
//     fontWeight: "bold",
//   },
// });
// function ClipDurationSelector() {
//   const { classes } = useClipDurationStyles();
//   const { durationParam, setDurationParam, durations } = useDuration();
//   return (
//     <div>
//       <InputLabel className={classes.label} id="clipDurationLabel">
//         Clip Duration
//       </InputLabel>
//       <Select
//         labelId="clipDurationLabel"
//         value={durationParam}
//         className={classes.select}
//         onChange={(e) => {
//           setDurationParam(e.target.value as any);
//         }}
//         disableUnderline={true}
//       >
//         {durations.map((d) => (
//           <MenuItem key={d} value={d}>
//             {d} mins
//           </MenuItem>
//         ))}
//       </Select>
//     </div>
//   );
// }

function TimeBlockSeparator({
  time,
  style,
}: {
  time: Date;
  style?: React.CSSProperties;
}) {
  return (
    <div className="mt-4 mb-2" style={style}>
      {format("p", time)} - {format("iiii, MMMM d", time)}
    </div>
  );
}

gql`
  query downloadStillsZip(
    $id: Int!
    $start: String!
    $end: String!
    $limit: Int
  ) {
    downloadStillsZip(
      id: $id
      startTime: $start
      endTime: $end
      limit: $limit
    ) {
      link
    }
  }
`;
