import ListIcon from "@mui/icons-material/List";
import { Button } from "@mui/material";
import { useSetAtom } from "jotai";
import { useState } from "react";

import { InterestList, InterestListType } from "@/generated-models";

import { InterestListDialog } from "./InterestListDialog";
import { InterestListFormMode } from "./constants";
import {
  currentListItemAtom,
  listModeAtom,
  useResetInterestListState,
} from "./hooks";

export function InterestListDetailsButton({
  type,
  list,
}: {
  type: InterestListType;
  list: Pick<InterestList, "id" | "name" | "items">;
}) {
  const [open, setOpen] = useState(false);

  const setMode = useSetAtom(listModeAtom);
  const setCurrentListItem = useSetAtom(currentListItemAtom);

  const resetInterestListState = useResetInterestListState();

  return (
    <div>
      <Button
        onClick={() => {
          setCurrentListItem(list.id);
          setMode(InterestListFormMode.edit);
          setOpen(true);
        }}
        startIcon={<ListIcon />}
        color="primary"
        size="small"
      >
        <span className="font-bold">{list.name}</span>
        <span className="text-[#757575] ml-1">
          {" "}
          ({list.items.length} license plates)
        </span>
      </Button>
      <InterestListDialog
        open={open}
        defaultMode={InterestListFormMode.edit}
        primaryActionLabel={"Add to Insight"}
        type={type}
        onClose={() => {
          setOpen(false);
          resetInterestListState();
        }}
        onApplyLists={() => {
          setOpen(false);
          resetInterestListState();
        }}
      />
    </div>
  );
}
