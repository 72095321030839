type IosMessage =
  | "logout"
  | "log"
  | "saveScreenshot"
  | "lockLandscape"
  | "unlockLandscape";

function sendMessage(name: IosMessage, data?: Record<string, any>) {
  const handler = (window as any).webkit?.messageHandlers?.[name];
  if (!handler) return false;

  handler.postMessage(data);
  return true;
}

export function iosLockLandscape() {
  return sendMessage("lockLandscape");
}

export function iosUnlockLandscape() {
  return sendMessage("unlockLandscape");
}

export function iosLogout() {
  return sendMessage("logout");
}

export function iosLog(...args: any[]) {
  return sendMessage("log", { args });
}

export function iosError(...args: any[]) {
  return sendMessage("log", { error: args });
}

export function iosSaveScreenshot(dataURL: string) {
  return sendMessage("saveScreenshot", { dataURL });
}
