import { Button, IconButton } from "@mui/material";
import { startOfDay } from "date-fns/fp";
import gql from "graphql-tag";
import React from "react";

import { generateExternalLink, generateVodShareUrl } from "@/util/camera";

import { isDemoUser, useMe } from "@/components/Auth";
import { CloseIcon, ShareIcon } from "@/components/Player/PlayerIcons";
import { ShareTrayItem } from "@/components/Player/ShareTrayItem";
import {
  determineLinkSharingActive,
  SharingLinkDialog,
} from "@/components/Player/SharingLinkDialog";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { useDialog } from "@/components/shared/Dialog";

import {
  useCreateSharedClipMutation,
  useSendSharedClipEmailMutation,
  useSendSharedClipTextMutation,
  useSharedClipInfoQuery,
  useUpdateSharedClipMutation,
} from "@/generated-models";
import { usePrefixOrgSlug } from "@/hooks/useOrgRouteBase";
import { usePermissions } from "@/hooks/usePermissions";
import { CERULEAN_BLUE } from "@/layout/theme";

interface ShareClipButtonProps {
  startTime: string;
  endTime: string;
  cameraId: number;
  label?: string;
  button?: (props: { shared: boolean; onClick: () => void }) => React.ReactNode;
}

export const ShareClipButton = React.memo(function ShareClipButton({
  cameraId,
  startTime,
  endTime,
  button,
  label,
}: ShareClipButtonProps) {
  const prefixOrgSlug = usePrefixOrgSlug();
  const me = useMe();
  const hasPermission = usePermissions();
  const isDemo = !!me && isDemoUser(me); // only relevant for demo orgs
  const hasSharingPermission = !isDemo && hasPermission("video_share");
  // const [popperOpen, setPopperOpen] = useState(false);
  const { data, updateQuery } = useSharedClipInfoQuery({
    variables: { cameraId, startTime, endTime },
    // Background info on nextFetchPolicy (buried in an issue):
    // https://github.com/apollographql/apollo-client/issues/6760#issuecomment-668188727
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const { pushSnackbar } = useFeedback();
  const getError = (share?: boolean) => () =>
    pushSnackbar(
      `Failed to ${
        share ? "" : "un"
      }share this clip. If this issue persists, please contact support.`,
      FeedbackType.Error
    );
  const [
    createSharedClip,
    { loading: creatingSharedClip },
  ] = useCreateSharedClipMutation({
    onError: getError(
      !determineLinkSharingActive(data?.sharedClipInfo?.expiry)
    ),
    refetchQueries: ["sharedClipInfo"],
  });
  const [
    updateSharedClip,
    { loading: updatingSharedClip },
  ] = useUpdateSharedClipMutation({
    onError: getError(
      !determineLinkSharingActive(data?.sharedClipInfo?.expiry)
    ),
  });
  const [sendSharedClipEmail] = useSendSharedClipEmailMutation();
  const [sendSharedClipText] = useSendSharedClipTextMutation();

  const shared =
    creatingSharedClip ||
    determineLinkSharingActive(data?.sharedClipInfo?.expiry);
  const shareToken = data?.sharedClipInfo?.shareToken;

  function disable() {
    if (!data?.sharedClipInfo) return;
    const startOfToday = startOfDay(new Date()).toISOString();

    updateSharedClip({
      variables: {
        id: data.sharedClipInfo.id,
        update: {
          expiry: startOfToday,
        },
      },
      optimisticResponse: {
        __typename: "Mutation",
        updateSharedClip: {
          __typename: "SharedClip",
          id: 0,
          shareToken: shareToken ?? "",
          description: data?.sharedClipInfo?.description ?? "",
          expiry: startOfToday,
        },
      },
      update: (_, { data }) =>
        updateQuery(() => ({
          __typename: "Query",
          sharedClipInfo: data?.updateSharedClip || null,
        })),
    });
  }

  const dialogProps = useDialog();

  async function openDialog() {
    if (!shared) {
      // Create a new shared clip
      createSharedClip({
        variables: { cameraId, startTime, endTime },
        optimisticResponse: {
          __typename: "Mutation",
          createSharedClip: {
            __typename: "SharedClip",
            id: 0,
            shareToken: shareToken ?? "",
            description: data?.sharedClipInfo?.description ?? "",
            expiry: data?.sharedClipInfo?.expiry,
          },
        },
        update: (_, { data }) =>
          updateQuery(() => ({
            __typename: "Query",
            sharedClipInfo: data?.createSharedClip || null,
          })),
      });
    }

    const active = await dialogProps.open();
    if (active) {
      pushSnackbar("Clip Sharing Activated", undefined, (handleClose) => (
        <>
          <Button
            size="small"
            color="primary"
            onClick={() => {
              disable();
              handleClose();
            }}
          >
            UNDO
          </Button>
          <IconButton onClick={handleClose} color="inherit" size="large">
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      ));
    }
  }

  return (
    <>
      {!button ? (
        <ShareTrayItem
          style={{
            color: determineLinkSharingActive(data?.sharedClipInfo?.expiry)
              ? CERULEAN_BLUE
              : undefined,
          }}
          onClick={openDialog}
          aria-label="share"
          disabled={!hasSharingPermission}
          icon={<ShareIcon className="text-inherit" />}
          label={label ?? "Share"}
        />
      ) : (
        button({
          shared: determineLinkSharingActive(data?.sharedClipInfo?.expiry),
          onClick: openDialog,
        })
      )}

      {dialogProps.opened && (
        <SharingLinkDialog
          {...dialogProps}
          submit={async (description: string, expiry: Date | null) => {
            if (!data?.sharedClipInfo) return;
            await updateSharedClip({
              variables: {
                id: data.sharedClipInfo.id,
                update: {
                  description,
                  expiry: expiry?.toISOString(),
                },
              },
            });
          }}
          submitting={updatingSharedClip}
          sharedLink={data?.sharedClipInfo}
          link={
            data?.sharedClipInfo?.shareToken
              ? generateExternalLink(
                  prefixOrgSlug(
                    generateVodShareUrl(data.sharedClipInfo.shareToken)
                  )
                )
              : null
          }
          title="Share Video Clip"
          expirationLabel="Clip Expiration"
          linkLabel="Clip Link"
          disableImmediately={disable}
          sendEmail={async (email) => {
            await sendSharedClipEmail({
              variables: {
                id: data!.sharedClipInfo!.id,
                email,
              },
            });
          }}
          sendText={async (phoneNumber) => {
            await sendSharedClipText({
              variables: {
                id: data!.sharedClipInfo!.id,
                phoneNumber,
              },
            });
          }}
        />
      )}
    </>
  );
});

export const SHARED_CLIP_FRAGMENT = gql`
  fragment SharedClipBase on SharedClip {
    id
    shareToken
    description
    expiry
  }
`;

export const SHARED_CLIP_INFO = gql`
  query sharedClipInfo(
    $cameraId: Int!
    $startTime: DateTime!
    $endTime: DateTime!
  ) {
    sharedClipInfo(
      cameraId: $cameraId
      startTime: $startTime
      endTime: $endTime
    ) {
      ...SharedClipBase
    }
  }

  ${SHARED_CLIP_FRAGMENT}
`;

export const CREATE_SHARED_CLIP = gql`
  mutation createSharedClip(
    $cameraId: Int!
    $startTime: DateTime!
    $endTime: DateTime!
  ) {
    createSharedClip(
      cameraId: $cameraId
      startTime: $startTime
      endTime: $endTime
    ) {
      ...SharedClipBase
    }
  }
  ${SHARED_CLIP_FRAGMENT}
`;

export const UPDATE_SHARED_CLIP = gql`
  mutation updateSharedClip($id: Int!, $update: SharedClipUpdate!) {
    updateSharedClip(id: $id, update: $update) {
      ...SharedClipBase
    }
  }

  ${SHARED_CLIP_FRAGMENT}
`;

export const SEND_SHARED_CLIP_EMAIL = gql`
  mutation sendSharedClipEmail($id: Int!, $email: String!) {
    sendSharedClipEmail(id: $id, email: $email)
  }
`;

export const SEND_SHARED_CLIP_TEXT = gql`
  mutation sendSharedClipText($id: Int!, $phoneNumber: String!) {
    sendSharedClipText(id: $id, phoneNumber: $phoneNumber)
  }
`;
