import { uniq } from "lodash/fp";
import { useCallback, useEffect, useMemo } from "react";
import {
  BooleanParam,
  DelimitedArrayParam,
  DelimitedNumericArrayParam,
  NumberParam,
  StringParam,
  useQueryParam,
} from "use-query-params";

import { filterNullish } from "@/util/filterFalsy";

import { CameraStatus } from "@/generated-models";

function useActiveGroupNames() {
  return useQueryParam("filterGroups", DelimitedArrayParam)[0];
}

export function useActiveGroupId() {
  const [groupId, _setActiveGroup] = useQueryParam("g", NumberParam);

  const setActiveGroup = useCallback(
    (id?: number) => {
      _setActiveGroup(id, "replaceIn");
      if (id != null) {
        localStorage.setItem("activeGroupId", String(id));
      } else {
        localStorage.removeItem("activeGroupId");
      }
    },
    [_setActiveGroup]
  );
  // Initialize group id from local storage if one is available
  useEffect(() => {
    const localStorageGroupId = Number(localStorage.activeGroupId);
    if (localStorageGroupId && !groupId) {
      _setActiveGroup(localStorageGroupId, "replaceIn");
    }
  }, [groupId, _setActiveGroup]);

  return [groupId ?? undefined, setActiveGroup] as const;
}

export function useActiveGroupAndCams<G, C>(
  groups?: (G & { id: number; name: string })[],
  cameras?: (C & {
    id: number;
    status: CameraStatus;
    tags: { id: number }[];
  })[]
): { activeGroup?: G; groupCams?: C[]; setActiveGroup: (id?: number) => void } {
  const [activeGroupId, setActiveGroup] = useActiveGroupId();
  const groupNames = useActiveGroupNames();

  const results = useMemo(() => {
    if (!groups) return { activeGroup: undefined, groupCams: undefined };

    const activeGroup = activeGroupId
      ? groups.find((g) => g.id === activeGroupId)
      : undefined;

    const filterGroupIds = (groupNames && !activeGroup
      ? groups.filter((g) => groupNames.includes(g.name))
      : groups.filter((g) => g.id === activeGroupId)
    )
      .map((g) => g.id)
      .filter(filterNullish);

    function camMatcher(tags: number[]) {
      return filterGroupIds.every((g) => tags.some((id) => id === g));
    }

    // Cameras in active group
    const groupCams =
      cameras && filterGroupIds.length > 0
        ? cameras.filter((c) => camMatcher(c.tags.map((t) => t.id)))
        : undefined;

    return { activeGroup, groupCams };
  }, [groups, cameras, activeGroupId, groupNames]);

  // Code below is related to experimental group mode feature
  const [cams, setCamsParam] = useQueryParam(
    "cams",
    DelimitedNumericArrayParam
  );
  const [groupViewMode] = useQueryParam("groupMode", BooleanParam);

  // If group view mode is enabled, set cams param to first 4 cameras in active group
  useEffect(() => {
    if (cams?.length || !groupViewMode || !results.groupCams) return;
    setCamsParam(
      results.groupCams
        .filter((c) => c.status === CameraStatus.Online)
        .slice(0, 4)
        .map((c) => c.id)
    );
  }, [results, cams, setCamsParam, groupViewMode]);

  // If group names are set, clear active group param
  useEffect(() => {
    if (groupNames) setActiveGroup(undefined);
  }, [groupNames, setActiveGroup]);

  return { ...results, setActiveGroup };
}

export enum NavigationTab {
  Cameras = "cameras",
  Maps = "maps",
  Walls = "walls",
  Ai = "ai",
  Insights = "insights",
}

const stringToNavTab: Record<string, NavigationTab> = {
  maps: NavigationTab.Maps,
  walls: NavigationTab.Walls,
  ai: NavigationTab.Ai,
  insights: NavigationTab.Insights,
};

export function useNavigationTabs() {
  const [tab, setTab] = useQueryParam("t", StringParam);
  const navTab = (tab && stringToNavTab[tab]) || NavigationTab.Cameras;
  const setNavTab = useCallback(
    (value: NavigationTab) => {
      setTab(value === NavigationTab.Cameras ? undefined : value, "replaceIn");
    },
    [setTab]
  );

  return { navTab, setNavTab };
}

export function useFocusedCam<T>(activeCams: (T & { id: number })[]): T {
  const [focusedCamId, setFocusedCamId] = useQueryParam("f", NumberParam);

  // This hook may not be used with an empty array
  if (activeCams.length === 0) {
    throw new Error("useFocusedCam hook received an empty activeCams array");
  }

  // Find focused camera or fallback to first camera
  const focusedCam = focusedCamId
    ? activeCams.find(({ id }) => id === focusedCamId)
    : undefined;

  // Remove param when focused cam is removed
  const clearParam = !!focusedCamId && !focusedCam;
  useEffect(() => {
    if (clearParam) setFocusedCamId(undefined, "replaceIn");
  }, [clearParam, setFocusedCamId]);

  return focusedCam ?? activeCams[0];
}

export function useSetFocusedCam() {
  return useQueryParam("f", NumberParam)[1];
}

export function useActiveCamIds() {
  const [param] = useQueryParam("cams", DelimitedNumericArrayParam);
  return useMemo(() => param?.filter(filterNullish) ?? [], [param]);
}

export function useFirstActiveCamId() {
  const activeCamIds = useActiveCamIds();
  return activeCamIds[0];
}

type NewValueType<D> = D | ((latestValue: D) => D);

export function useSetActiveCamIds() {
  const [, setParam] = useQueryParam("cams", DelimitedNumericArrayParam);
  return useCallback(
    (value: NewValueType<number[] | undefined>) => {
      setParam((prev) => {
        const newValue =
          typeof value === "function"
            ? value(prev?.filter(filterNullish))
            : value;
        // Make sure newValue array is not empty, unique and sorted
        return newValue?.length ? uniq(newValue.sort()) : undefined;
      }, "replaceIn");
    },
    [setParam]
  );
}
