import { Button, Container, Typography } from "@mui/material";
import { useState } from "react";

import { useBreakpoints } from "@/util/useBreakpoints";

import { CreateVideoWallPopper } from "@/components/VideoWall/CreateVideoWallPopper";
import { WallSelector } from "@/components/VideoWall/WallSelector";
import { ZendeskArticle, ZendeskLink } from "@/components/Zendesk/ZendeskLink";

import { usePage_VideoWallsQuery } from "@/generated-models";
import { useOrgSlugNavigate } from "@/hooks/useOrgRouteBase";

import { useCanEditVideoWalls } from "./hooks";
import { useIsDemoUser } from "./useIsDemoUser";
import { useVideoWallContext } from "./useVideoWallContext";

export function VideoWallWelcomeScreen() {
  const { data } = usePage_VideoWallsQuery();
  const { fitsDesktop } = useBreakpoints();
  const canEdit = useCanEditVideoWalls();
  const navigate = useOrgSlugNavigate();
  const [createAnchor, setCreateAnchor] = useState<null | HTMLElement>(null);
  const {
    editingState: [, setEditing],
  } = useVideoWallContext();
  const isDemo = useIsDemoUser();

  // error and loading state should be handled in parent <VideoWall />
  if (!data) return null;

  return (
    <>
      <Container
        maxWidth="lg"
        className="flex flex-col items-center pt-24 md:pt-32"
      >
        <img src="/wall-hero.svg" alt="" className="max-w-full" />
        <div className="flex flex-col items-center max-w-[270px] md:max-w-none text-center">
          <Typography
            variant="h1"
            className="text-primary text-3xl md:text-5xl font-light max-w-[250px] md:max-w-none md:-mt-16"
          >
            Welcome to <span className="text-5xl">Video Walls</span>
          </Typography>
          <Typography className="max-w-[700px] my-8">
            Video Walls allow you to monitor multiple live cameras at the same
            time.{" "}
            {data.videoWalls.length > 0 &&
              (fitsDesktop ? (
                <>
                  You can create layouts that include cameras from different
                  locations, all in one place. Select an existing video wall or
                  click the “+” button to create a new wall.
                  <ZendeskLink article={ZendeskArticle.SPOTCAST} />
                </>
              ) : (
                <>
                  Select a video wall below or create a new wall on our desktop
                  site.
                </>
              ))}
          </Typography>
          {data.videoWalls.length === 0 ? (
            fitsDesktop ? (
              <Button
                variant="contained"
                color="primary"
                disabled={isDemo || !canEdit}
                onClick={(e) => {
                  e.stopPropagation();
                  setCreateAnchor((createAnchor) =>
                    createAnchor ? null : e.currentTarget
                  );
                }}
              >
                Create a Wall
              </Button>
            ) : (
              <Typography className="italic" variant="caption">
                Please open this page on desktop to create a wall
              </Typography>
            )
          ) : (
            <div className="self-stretch md:self-auto">
              <WallSelector {...data} />
            </div>
          )}
        </div>
        {fitsDesktop && (
          <CreateVideoWallPopper
            anchor={createAnchor}
            setAnchor={setCreateAnchor}
            editWall={(id: number, rotating: boolean) => {
              navigate(`/wall/${rotating ? "r/" : ""}${id}`, { replace: true });
              setEditing(true);
            }}
          />
        )}
      </Container>
    </>
  );
}
