import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from "@mui/material";
import clsx from "clsx";
import { debounce } from "lodash/fp";
import { CSSProperties, ReactNode, useCallback, useRef, useState } from "react";

export interface SearchBoxProps {
  input: string;
  disabled?: boolean;
  setInput: (value: string) => void;
  placeholder?: string;
  fullWidth?: boolean;
  className?: string;
  classes?: TextFieldProps["classes"];
  startAdornment?: ReactNode;
  style?: CSSProperties;
  InputProps?: TextFieldProps["InputProps"];
}

export function SearchBox({
  input,
  disabled,
  setInput,
  placeholder = "Search",
  fullWidth = false,
  InputProps,
  className,
  classes,
  style = {},
}: SearchBoxProps) {
  const [value, setValue] = useState(input);
  // eslint-disable-next-line
  const persistInput = useCallback(debounce(500, setInput), []);

  return (
    <TextField
      placeholder={placeholder}
      disabled={disabled}
      onChange={(e) => {
        setValue(e.target.value);
        persistInput(e.target.value);
      }}
      value={value}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: value && (
          <InputAdornment position="end">
            <IconButton
              onClick={() => {
                setValue("");
                setInput("");
              }}
              aria-label="clear search"
              size="small"
            >
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
        ...InputProps,
      }}
      fullWidth={fullWidth}
      style={style}
      className={className}
      classes={classes}
    />
  );
}

interface GrowingSearchBoxProps {
  input: string | null;
  setInput: (value: string | null) => void;
  placeholder?: string;
  classes?: { root?: string; iconButton?: string; iconButtonActive?: string };
}

export function GrowingSearchBox({
  input,
  setInput,
  placeholder = "Search",
  classes,
}: GrowingSearchBoxProps) {
  const [value, setValue] = useState(input);
  // eslint-disable-next-line
  const persistInput = useCallback(debounce(500, setInput), []);
  const inputRef = useRef<HTMLInputElement>();

  const active = value !== null;

  return (
    <TextField
      className={clsx("transition-size duration-300", classes?.root)}
      style={{ width: !active ? 24 : 300 }}
      placeholder={placeholder}
      onChange={(e) => {
        setValue(e.target.value);
        persistInput(e.target.value);
      }}
      value={value || ""}
      inputRef={inputRef}
      InputProps={{
        disableUnderline: !active,
        startAdornment: (
          <InputAdornment position="start">
            <IconButton
              onClick={() => {
                setValue("");
                setInput("");
                inputRef.current?.focus();
              }}
              disabled={active}
              aria-label="open search"
              size="small"
              className={clsx(classes?.iconButton, {
                [classes?.iconButtonActive ?? ""]: active,
              })}
            >
              <SearchIcon className="text-primary" />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: active && (
          <InputAdornment position="end">
            <IconButton
              onClick={() => {
                setValue(null);
                setInput(null);
              }}
              aria-label="clear search"
              size="small"
            >
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
