import { useFlags } from "launchdarkly-react-client-sdk";
import {
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";

import { AnnotationLabelState, PointInput } from "@/generated-models";

interface CopilotLabelProviderProps {
  id: number;
  label: string;
  labelState: AnnotationLabelState;
  color: string;
  shape: PointInput[];
  children: ReactNode;
}

export enum CopilotLabelMode {
  default,
  rename,
  adjust,
  remove,
}

const CopilotLabelContext = createContext({
  id: 0,
  label: "",
  color: "",
  labelState: AnnotationLabelState.Suggested,
  shape: [] as PointInput[],

  mode: CopilotLabelMode.default,
  setMode: (() => {}) as React.Dispatch<SetStateAction<CopilotLabelMode>>,

  createdAutoZone: null as number | null,
  setCreatedAutoZone: (() => {}) as React.Dispatch<
    SetStateAction<number | null>
  >,
});

export const CopilotLabelProvider = ({
  id,
  label,
  labelState,
  color,
  shape,
  children,
}: CopilotLabelProviderProps) => {
  const { copilotPinning } = useFlags();
  const [mode, setMode] = useState<CopilotLabelMode>(CopilotLabelMode.default);
  const [createdAutoZone, setCreatedAutoZone] = useState<number | null>(null);

  return (
    <CopilotLabelContext.Provider
      value={{
        id,
        label,
        labelState: copilotPinning
          ? labelState
          : AnnotationLabelState.Confirmed,
        color,
        shape,
        mode,
        setMode,
        createdAutoZone,
        setCreatedAutoZone,
      }}
    >
      {children}
    </CopilotLabelContext.Provider>
  );
};

export const useCopilotLabelContext = () => {
  const context = useContext(CopilotLabelContext);

  if (!context) {
    throw new Error(
      "useCopilotLabelContext must be used within a CopilotLabelProvider"
    );
  }

  return context;
};
