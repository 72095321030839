import { Typography } from "@mui/material";
import React from "react";

import {
  mobileEnvironment,
  detectMobileWrapper,
} from "@/components/Mobile/mobileEnvironment";

import { apiHost, deployEnv } from "@/environment";

export function Debug() {
  return (
    <article className="p-2 flex flex-col gap-8">
      <Typography variant="h1">Debug</Typography>
      <section>
        <Typography variant="h2">Commit hash</Typography>
        <code>{process.env.REACT_APP_VERSION}</code>
      </section>
      <section>
        <Typography variant="h2">React version</Typography>
        <code>{React.version}</code>
      </section>
      <section>
        <Typography variant="h2">User Agent</Typography>
        <code>{navigator.userAgent}</code>
      </section>
      <section>
        <Typography variant="h2">Mobile Environment</Typography>
        <pre>{JSON.stringify(mobileEnvironment, null, 2)}</pre>
      </section>
      <section>
        <Typography variant="h2">
          detectMobileWrapper(){" "}
          <span className="text-sm opacity-60 font-normal">deprecated</span>
        </Typography>
        <pre>{JSON.stringify(detectMobileWrapper())}</pre>
      </section>
      <section>
        <Typography variant="h2">Deploy Env</Typography>
        <code>{deployEnv}</code>
      </section>
      <section>
        <Typography variant="h2">Host</Typography>
        <code>{window.location.host}</code>
      </section>
      <section>
        <Typography variant="h2">API host</Typography>
        <code>{apiHost}</code>
      </section>
      <section>
        <details>
          <summary className="cursor-pointer">
            <Typography variant="h2" className="inline ml-1">
              Environment variables
            </Typography>
          </summary>
          <pre className="ml-4 px-2 py-1 bg-gray-100 text-xs md:text-sm overflow-x-auto">
            <div>DEPLOY_ENV: {process.env.REACT_APP_DEPLOY_ENV}</div>
            <div>API_HOST: {process.env.REACT_APP_API_HOST}</div>
            <div>DEBUG_GA: {process.env.REACT_APP_DEBUG_GA}</div>
            <div>
              LAUNCH_DARKLY_DEV: {process.env.REACT_APP_LAUNCH_DARKLY_DEV}
            </div>
            <div>MUX_KEY: {process.env.REACT_APP_MUX_KEY}</div>
            <div>BRIVO_CLIENT_ID: {process.env.REACT_APP_BRIVO_CLIENT_ID}</div>
            <div>INTERCOM: {process.env.REACT_APP_INTERCOM}</div>
          </pre>
        </details>
      </section>
    </article>
  );
}
