import { Typography } from "@mui/material";
import gql from "graphql-tag";
import React, { useContext, useMemo, useState } from "react";

import { DefaultDialog, useDialog } from "@/components/shared/Dialog";

import {
  ApplianceAiMode,
  LifecycleStates,
  useLocationCapacityQuery,
} from "@/generated-models";

export enum LocationAi {
  AiOnly,
  NonAiOnly,
  Mixed,
}

export function useLocationCapacity() {
  return useContext(LocationCapacityContext);
}

const LocationCapacityContext = React.createContext({
  locationId: 0,
  locationAi: LocationAi.NonAiOnly,
  totalCapacity: 0,
  totalAICapacity: 0,
  usedCapacity: 0,
  usedAICapacity: 0,
  maxCapacityReached: true,
  maxAICapacityReached: true,
  /**
   * @returns whether the max capacity has been reached and a modal is shown.
   * If ai is true, AI capacity is also checked.
   */
  guardCapacityAndShowModal: (_: boolean) => true as boolean,
});

export function LocationCapacityGuard({
  locationId,
  children,
}: React.PropsWithChildren<{ locationId: number }>) {
  const { data } = useLocationCapacityQuery({
    variables: { locationId },
    fetchPolicy: "cache-and-network",
  });

  const { open, ...capacityDialogProps } = useDialog();

  const aiAppliances = data?.location?.appliances.filter(
    (a) => a.deviceAiMode === ApplianceAiMode.Nvidia
  );
  const locationAi =
    aiAppliances?.length === 0
      ? LocationAi.NonAiOnly
      : aiAppliances?.length === data?.location?.appliances.length
      ? LocationAi.AiOnly
      : LocationAi.Mixed;

  const totalCapacity =
    data?.location?.appliances.reduce(
      (result, appliance) => result + appliance.capacity,
      0
    ) ?? 0;

  const totalAICapacity =
    data?.location?.appliances.reduce((result, appliance) => {
      const hasAI = appliance.deviceAiMode === ApplianceAiMode.Nvidia;
      const capacity = hasAI ? appliance.capacity : 0;
      return result + capacity;
    }, 0) ?? 0;

  const usedCapacity =
    data?.location?.cameras.filter(
      (c) => c.lifecycleState === LifecycleStates.Enabled
    ).length ?? 0;

  const usedAICapacity =
    data?.location?.cameras.filter((c) => {
      return c.lifecycleState === LifecycleStates.Enabled && c.aiEnabled;
    }).length ?? 0;

  const [camCount, setCamCount] = useState(`${totalCapacity} cameras`);

  const capacity = useMemo(() => {
    const maxCapacityReached = usedCapacity >= totalCapacity;
    const maxAICapacityReached = usedAICapacity >= totalAICapacity;
    return {
      locationId,
      locationAi,
      totalAICapacity,
      totalCapacity,
      usedCapacity,
      usedAICapacity,
      maxCapacityReached,
      maxAICapacityReached,
      guardCapacityAndShowModal: (checkAi: boolean) => {
        if (
          !!sessionStorage.bypassLocationCapacity &&
          !!JSON.parse(sessionStorage.bypassLocationCapacity)
        ) {
          return false;
        }

        const capacityReached =
          maxCapacityReached || (checkAi && maxAICapacityReached);
        if (capacityReached) {
          setCamCount(
            checkAi && !maxCapacityReached
              ? `${totalAICapacity} AI-enabled cameras`
              : `${totalCapacity} cameras`
          );
          open().then((confirmed) => {
            // Capture the confirm button and make it act like a `mailto` link.
            if (!confirmed) return;
            window.open("mailto:sales@spotai.co", "_blank");
          });
        }
        return capacityReached;
      },
    };
  }, [
    usedCapacity,
    totalCapacity,
    locationId,
    locationAi,
    totalAICapacity,
    usedAICapacity,
    open,
  ]);

  return (
    <LocationCapacityContext.Provider value={capacity}>
      {children}
      <DefaultDialog
        title="Location Camera Capacity Full"
        content={
          <>
            <Typography>
              Oops! This location has a maximum capacity of {camCount} based on
              the appliance
              {data?.location?.appliances.length === 1 ? "" : "s"} you have.
            </Typography>
            <div className="h-4" />
            <Typography>
              To add more cameras, you will need a new appliance, please contact
              your account executive or{" "}
              <a href="mailto:sales@spotai.co" target="_blank" rel="noreferrer">
                sales@spotai.co
              </a>
              .
            </Typography>
          </>
        }
        cancelText="Close"
        confirmText="Contact Sales"
        {...capacityDialogProps}
      />
    </LocationCapacityContext.Provider>
  );
}

gql`
  query locationCapacity($locationId: Int!) {
    location(id: $locationId) {
      id
      cameras {
        id
        lifecycleState
        aiEnabled
      }
      appliances {
        id
        capacity
        deviceAiMode
      }
    }
  }
`;
