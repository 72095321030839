import CloseIcon from "@mui/icons-material/Close";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { Dialog, IconButton } from "@mui/material";
import gql from "graphql-tag";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { SearchParamLink } from "@/components/shared/QueryParamLink";

import {
  DetectedTrack,
  useConfirmDetectedTrackMutation,
  useReassignDetectedTrackMutation,
  useRemoveDetectedTrackMutation,
} from "@/generated-models";
import { usePrefixOrgSlug } from "@/hooks/useOrgRouteBase";

import { ReassignTrackModal } from "./SubjectMergeModals";

interface SubjectFeedbackButtonsProps {
  subjectId: number;
  track: Pick<DetectedTrack, "id" | "confirmed" | "imageSrc">;
}

enum DialogState {
  Closed,
  ChooseOption,
  ReassignTrack,
}

export function SubjectFeedbackButtons({
  subjectId,
  track,
}: SubjectFeedbackButtonsProps) {
  const navigate = useNavigate();
  const prefixOrgSlug = usePrefixOrgSlug();
  const [dialogState, setDialogState] = useState(DialogState.Closed);
  const closeDialog = () => setDialogState(DialogState.Closed);
  const { pushSnackbar } = useFeedback();
  const [
    confirmTrack,
    { loading: confirming },
  ] = useConfirmDetectedTrackMutation({
    variables: { id: track.id },
    update: (cache, { data }) => {
      if (!data) return;
      cache.modify({
        id: `DetectedTrack:${track.id}`,
        fields: {
          confirmed: () => true,
        },
      });
    },
    onError: () =>
      pushSnackbar("Submitting feedback failed", FeedbackType.Error),
  });
  const [removeTrack, { loading: removing }] = useRemoveDetectedTrackMutation({
    variables: { id: track.id },
    update: (cache, { data }) => {
      if (!data) return;
      cache.evict({ id: `DetectedTrack:${track.id}` });
    },
    onCompleted: () => setDialogState(DialogState.Closed),
    onError: () =>
      pushSnackbar("Removing detection failed", FeedbackType.Error),
  });
  const [
    reassignTrack,
    { loading: reassigning },
  ] = useReassignDetectedTrackMutation({
    update: (cache, { data }) => {
      if (!data) return;
      cache.evict({ id: `DetectedTrack:${data.reassignDetectedTrack.id}` });
      setDialogState(DialogState.Closed);
    },
    onError: () =>
      pushSnackbar("Reassigning detection failed", FeedbackType.Error),
  });

  return (
    <div
      className="absolute bottom-1 left-1/2 -translate-x-1/2 flex items-center bg-white rounded opacity-0 group-hover:opacity-100 transition-opacity cursor-default"
      onClick={(e) => e.stopPropagation()}
    >
      <button
        className="bg-transparent p-1 hover:text-primary disabled:text-primary transition-colors"
        disabled={track.confirmed || confirming}
        onClick={() => confirmTrack()}
      >
        <ThumbUpIcon fontSize="small" />
      </button>
      <hr className="h-3 w-px mx-1 bg-black bg-opacity-20" />
      <button
        className="bg-transparent p-1 hover:text-primary transition-colors"
        onClick={() => setDialogState(DialogState.ChooseOption)}
      >
        <ThumbDownIcon fontSize="small" />
      </button>
      <Dialog
        onClose={closeDialog}
        open={dialogState !== DialogState.Closed}
        PaperProps={{ className: "p-5" }}
        maxWidth={dialogState === DialogState.ChooseOption ? "xs" : "lg"}
      >
        {dialogState === DialogState.ChooseOption && (
          <>
            <div className="flex justify-between items-start">
              <div>
                <div className="text-lg font-bold">Manage Detection</div>
                <div className="text-sm">
                  Select from the following options to manage this detection.
                </div>
              </div>
              <IconButton onClick={closeDialog} size="small" edge="end">
                <CloseIcon />
              </IconButton>
            </div>
            <div className="flex gap-x-4 mt-5">
              <img
                src={track.imageSrc || ""}
                alt="Subject"
                className="w-36 h-36 rounded-md object-cover object-center"
              />
              <div className="flex flex-col gap-y-3 grow">
                <button
                  className="flex-center w-full h-10 bg-[#DAEEFF] hover:bg-opacity-50 text-primary text-sm rounded-lg"
                  disabled={removing || reassigning}
                  onClick={() =>
                    reassignTrack({
                      variables: { id: track.id },
                      optimisticResponse: {
                        __typename: "Mutation",
                        reassignDetectedTrack: {
                          __typename: "MoveDetectedTrackResult",
                          id: track.id,
                          subjectId: -1,
                          previousSubjectRemoved: false,
                        },
                      },
                      onCompleted: (data) => {
                        const newSubjectUrl = prefixOrgSlug(
                          `/search/people/${data.reassignDetectedTrack.subjectId}`
                        );
                        if (data.reassignDetectedTrack.previousSubjectRemoved) {
                          // If previous subject was removed, we navigate to the new subject
                          navigate(newSubjectUrl, { replace: true });
                        }
                        pushSnackbar(
                          <>
                            New person created with detection!
                            {data.reassignDetectedTrack
                              .previousSubjectRemoved ? (
                              ""
                            ) : (
                              <SearchParamLink
                                to={newSubjectUrl}
                                className="ml-2 underline"
                              >
                                View Person
                              </SearchParamLink>
                            )}
                          </>,
                          FeedbackType.Info
                        );
                      },
                    })
                  }
                >
                  Create a New Person
                </button>
                <button
                  className="flex-center w-full h-10 bg-[#DAEEFF] hover:bg-opacity-50 text-primary text-sm rounded-lg"
                  disabled={removing || reassigning}
                  onClick={() => setDialogState(DialogState.ReassignTrack)}
                >
                  Reassign to Existing Person
                </button>
                <button
                  className="flex-center w-full h-10 bg-[#DAEEFF] hover:bg-opacity-50 text-primary text-sm rounded-lg"
                  disabled={removing || reassigning}
                  onClick={() => removeTrack()}
                >
                  Not a Person
                </button>
              </div>
            </div>
          </>
        )}
        {dialogState === DialogState.ReassignTrack && (
          <ReassignTrackModal
            imageSrc={track.imageSrc}
            currentSubjectId={subjectId}
            cancel={() => setDialogState(DialogState.ChooseOption)}
            onSelect={(targetSubjectId) =>
              reassignTrack({
                variables: { id: track.id, targetSubjectId },
                optimisticResponse: {
                  __typename: "Mutation",
                  reassignDetectedTrack: {
                    __typename: "MoveDetectedTrackResult",
                    id: track.id,
                    subjectId: targetSubjectId,
                    previousSubjectRemoved: false,
                  },
                },
                onCompleted: (data) => {
                  const newSubjectUrl = prefixOrgSlug(
                    `/search/people/${data.reassignDetectedTrack.subjectId}`
                  );
                  if (data.reassignDetectedTrack.previousSubjectRemoved) {
                    // If previous subject was removed, we navigate to the new subject
                    navigate(newSubjectUrl, { replace: true });
                  }
                  pushSnackbar(
                    <>
                      Detection reassigned!
                      {data.reassignDetectedTrack.previousSubjectRemoved ? (
                        ""
                      ) : (
                        <SearchParamLink
                          to={newSubjectUrl}
                          className="ml-2 underline"
                        >
                          View Person
                        </SearchParamLink>
                      )}
                    </>,
                    FeedbackType.Info
                  );
                },
              })
            }
          />
        )}
      </Dialog>
    </div>
  );
}

gql`
  mutation confirmDetectedTrack($id: Int!) {
    confirmDetectedTrack(id: $id) {
      message
    }
  }
`;

gql`
  mutation removeDetectedTrack($id: Int!) {
    removeDetectedTrack(id: $id) {
      message
    }
  }
`;

gql`
  mutation reassignDetectedTrack($id: Int!, $targetSubjectId: Int) {
    reassignDetectedTrack(id: $id, targetSubjectId: $targetSubjectId) {
      id
      subjectId
      previousSubjectRemoved
    }
  }
`;
