import { memo } from "react";

import { VideoWallPlayer } from "@/components/VideoWall/VideoWallPlayer";

import { Page_VideoWallsQuery } from "@/generated-models";

import { EmptyCell } from "./EmptyCell";
import { NotAllowedCell } from "./NotAllowedCell";

function VideoWallCamTileComponent({
  cam,
  maxAutoLevel,
  videoTileRef,
  videoTileMeasurement,
  spanSize,
  rotating,
  kiosk,
  isWebRtc,
  blur = false,
  ...props
}: {
  cam: Page_VideoWallsQuery["videoWalls"][number]["cameras"][number];
  videoTileRef?: any;
  videoTileMeasurement?: DOMRectReadOnly;
  spanSize?: number;
  maxAutoLevel?: string;
  rotating?: boolean;
  kiosk?: boolean;
  isWebRtc: boolean;
  blur?: boolean;
}) {
  return (
    <div
      className="relative rounded overflow-hidden"
      {...props}
      style={{
        gridColumn: `span ${spanSize}`,
        gridRow: `span ${spanSize}`,
      }}
    >
      <div className="min-h-full aspect-video">
        {cam ? (
          cam.__typename === "Camera" && cam.feeds ? (
            <VideoWallPlayer
              sources={cam.feeds}
              name={cam.name}
              timezone={cam.location.timezone}
              camId={cam.id}
              applianceOnline={cam.health.applianceOnline}
              cameraOnline={cam.health.cameraOnline}
              maxAutoLevel={maxAutoLevel}
              location={cam.location.name}
              rotating={rotating}
              kiosk={kiosk}
              isWebRtc={isWebRtc}
              audioControlEnabled={cam.settings.audioControlEnabled ?? false}
              blur={blur}
            />
          ) : (
            <NotAllowedCell />
          )
        ) : (
          <EmptyCell />
        )}
      </div>
    </div>
  );
}

export const VideoWallCamTile = memo(VideoWallCamTileComponent);
