import { Hidden, Paper, Popper, Typography } from "@mui/material";
import clsx from "clsx";
import { orderBy } from "lodash/fp";
import { useState } from "react";
import { makeStyles } from "tss-react/mui";

import { filterNullish } from "@/util/filterFalsy";
import { insertBreaklines } from "@/util/insertBreaklines";

import { AnnotationLabel } from "@/pages/Cases/AnnotationLabel";
import { getColorForLabel } from "@/pages/Cases/CaseComments";
import {
  applyCommentPartMapper,
  insertMentions,
} from "@/pages/Cases/commentDecorations";

import { useVodTime } from "@/components/Player/PlayerBase";

import { CaseClipQuery, GetSharedCaseClipQuery } from "@/generated-models";
import { AbsoluteOverlay } from "@/layout/AbsoluteOverlay";

import { useAnnotationContext } from "./CreateAnnotationContextProvider";
import { CreateAnnotationBox } from "./CreateAnnotationOverlay";

export type AnnotationType = ClipType["annotations"][number];

export function getMentions(annotation: AnnotationType) {
  if (annotation.__typename === "CaseClipAnnotation") {
    return annotation.mentions;
  }
  return undefined;
}

export function ClipAnnotationDisplayOverlay({
  annotations,
}: {
  annotations: AnnotationType[];
}) {
  const time = useVodTime();
  if (!time) return null;

  const displayingAnnotations = getDisplayingAnnotations(
    annotations,
    time.position
  );

  return (
    <AbsoluteOverlay>
      {Object.values(displayingAnnotations)
        .filter(Boolean)
        .map((a) => (
          <ClipAnnotationDisplay
            annotation={a!}
            key={a!.id}
            playerTime={time.position}
          />
        ))}
    </AbsoluteOverlay>
  );
}

export enum AnnotationDisplayMode {
  HIDDEN = "hidden",
  COMING_UP = "comingUp",
  // COLLAPSED = 'collapsed',
  EXPANDED = "expanded",
}

function ClipAnnotationDisplay({
  annotation,
  playerTime,
}: {
  annotation: AnnotationType;
  playerTime: number;
}) {
  const displayMode = getAnnotationDisplayMode(annotation.time, playerTime);

  return (
    <AnnotationDisplay annotation={annotation} displayMode={displayMode} />
  );
}

export function AnnotationDisplayOverlay({
  annotations,
}: {
  annotations: AnnotationType[];
}) {
  return (
    <AbsoluteOverlay>
      {annotations.map((a) => (
        <AnnotationDisplay annotation={a} key={a.id} />
      ))}
    </AbsoluteOverlay>
  );
}

function AnnotationDisplay({
  annotation: { label, position, content },
  annotation,
  displayMode = AnnotationDisplayMode.EXPANDED,
}: {
  annotation: AnnotationType;
  displayMode?: AnnotationDisplayMode;
}) {
  const [anchor, setAnchor] = useState<SVGElement | null>(null);

  let opacity = 0;
  switch (displayMode) {
    case AnnotationDisplayMode.COMING_UP:
      opacity = 0.25;
      break;
    case AnnotationDisplayMode.EXPANDED:
      opacity = 1;
      break;
  }

  return (
    <div
      style={{
        left: `${position.x * 100}%`,
        top: `${position.y * 100}%`,
        opacity,
      }}
      className="absolute transition-opacity"
    >
      <AnnotationLabel
        ref={(element) => {
          if (!element) return;
          setAnchor(element);
        }}
        variant="contained"
        color={getColorForLabel(label)}
        className="top-0 left-0 absolute -translate-x-1/2 -translate-y-full"
      >
        {label}
      </AnnotationLabel>
      {anchor && (
        <Hidden mdDown>
          <Popper
            disablePortal
            open={displayMode === AnnotationDisplayMode.EXPANDED}
            anchorEl={anchor}
            placement="right-start"
          >
            <Paper className="bg-[#404040] border border-solid border-transparent md:border-[#676767] text-white md:bg-black md:bg-opacity-60 md:w-[400px] md:mx-4 p-2 mx-4">
              <Typography color="inherit">
                {[content || ""]
                  .flatMap(applyCommentPartMapper(insertBreaklines))
                  .flatMap(
                    applyCommentPartMapper(
                      insertMentions(getMentions(annotation))
                    )
                  )}
              </Typography>
            </Paper>
          </Popper>
        </Hidden>
      )}
    </div>
  );
}

const useMobileAnnotationStyles = makeStyles()((theme) => ({
  root: {
    background: "#404040",
    boxSizing: "border-box",
    color: "white",
    padding: theme.spacing(2),
    height: 128,
    overflow: "auto",

    transition: "all 300ms",
  },
  collapsed: {
    height: 0,
    padding: 0,
  },

  mobileAnnotation: {
    marginBottom: theme.spacing(1),
  },

  annotationLabel: {
    // transform: "translateY(3px)",
    marginRight: 4,
    display: "inline-block",
  },
}));

export function MobileClipAnnotationDisplay({
  annotations,
}: {
  annotations: AnnotationType[];
}) {
  const time = useVodTime();
  if (!time) return null;

  if (annotations.length === 0) return null;

  const displayingAnnotation = getDisplayingAnnotations(
    annotations,
    time.position
  )[AnnotationDisplayMode.EXPANDED];

  return (
    <MobileAnnotationDisplay
      annotations={[displayingAnnotation].filter(filterNullish)}
      collapseIfEmpty
    />
  );
}

export function MobileAnnotationDisplay({
  annotations,
  collapseIfEmpty = false,
}: {
  annotations: AnnotationType[];
  collapseIfEmpty?: boolean;
}) {
  const { classes } = useMobileAnnotationStyles();
  if (!collapseIfEmpty && annotations.length === 0) return null;

  return (
    <div
      className={clsx(classes.root, {
        [classes.collapsed]: annotations.length === 0,
      })}
    >
      {annotations.map((annotation) => (
        <MobileAnnotation annotation={annotation} />
      ))}
    </div>
  );
}

function MobileAnnotation({
  annotation: { label, content },
  annotation,
}: {
  annotation: AnnotationType;
}) {
  const { classes } = useMobileAnnotationStyles();

  return (
    <div className={classes.mobileAnnotation}>
      <AnnotationLabel
        variant="contained"
        color={getColorForLabel(label)}
        width={14}
        className={classes.annotationLabel}
      >
        {label}
      </AnnotationLabel>{" "}
      {[content || ""]
        .flatMap(applyCommentPartMapper(insertBreaklines))
        .flatMap(
          applyCommentPartMapper(insertMentions(getMentions(annotation)))
        )}
    </div>
  );
}

const COMING_UP_INTERVAL = 5; // Seconds
const SHOWN_INTERVAL = 10; // Seconds
export function getAnnotationDisplayMode(
  annotationTime: number,
  playerTime: number
) {
  if (playerTime < annotationTime) {
    if (playerTime > annotationTime - COMING_UP_INTERVAL) {
      return AnnotationDisplayMode.COMING_UP;
    }
  } else if (playerTime - annotationTime < SHOWN_INTERVAL) {
    return AnnotationDisplayMode.EXPANDED;
  }
  return AnnotationDisplayMode.HIDDEN;
}

function getDisplayingAnnotations(
  annotations: AnnotationType[],
  playerTime: number
) {
  const ascending = orderBy("time", "asc", annotations);
  const descending = ascending.slice(0).reverse();
  const comingUp = ascending.find(
    (a) =>
      getAnnotationDisplayMode(a.time, playerTime) ===
      AnnotationDisplayMode.COMING_UP
  );
  const expanded = descending.find(
    (a) =>
      getAnnotationDisplayMode(a.time, playerTime) ===
      AnnotationDisplayMode.EXPANDED
  );
  return {
    [AnnotationDisplayMode.COMING_UP]: comingUp,
    [AnnotationDisplayMode.EXPANDED]: expanded,
  } as const;
}

type CaseClipType = CaseClipQuery | GetSharedCaseClipQuery;
export type ClipType = NonNullable<CaseClipType["caseClip"]>;

export function MobileAnnotationInput({
  caseId,
  clipId,
  screenshotId,
}: {
  caseId: number;
  clipId?: number;
  screenshotId?: number;
}) {
  const { placedAnnotationPosition } = useAnnotationContext();
  const time = useVodTime();
  if (!placedAnnotationPosition) return null;

  return (
    <CreateAnnotationBox
      time={time?.position}
      position={placedAnnotationPosition}
      clipId={clipId}
      screenshotId={screenshotId}
      caseId={caseId}
    />
  );
}
