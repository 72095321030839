import { CircularProgress } from "@mui/material";
import clsx from "clsx";
import { utcToZonedTime } from "date-fns-tz";
import gql from "graphql-tag";
import { capitalize } from "lodash";
import React, { useMemo } from "react";
import { Chart } from "react-charts";

import {
  primaryAxis,
  secondaryAxes,
  useReactChartStyles,
} from "@/pages/Intelligence/ChartConfig";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  IntelligenceDashboardType,
  useIntelligenceDashboardContentQuery,
} from "@/generated-models";

import {
  EntityColors,
  EntityLabel,
  IntelligenceFeatureType,
} from "../constants";
import { getObjectLabel } from "../utils";

export function IdleCardPreview({
  id,
  timezone,
  chartDate,
  objectTypes,
  feature,
}: {
  id: number;
  timezone: string;
  chartDate: string;
  objectTypes?: string[] | string | null;
  feature: IntelligenceFeatureType;
}) {
  const entity = EntityLabel[feature];
  const { classes } = useReactChartStyles();
  const { data, error, loading } = useIntelligenceDashboardContentQuery({
    ...refetchOnMountPolicy,
    variables: {
      id,
      startDate: chartDate,
      endDate: chartDate,
      bucketSizeSeconds: 3600,
    },
  });

  const objectLabelPlural = getObjectLabel(
    objectTypes,
    IntelligenceDashboardType.Idle,
    true
  );

  const buckets = data?.intelligenceDashboard?.metrics.results?.bucketCounts;
  const chartData = useMemo(() => {
    if (!buckets || buckets.length === 0) return [];
    const mapped = buckets.map((b) => ({
      date: utcToZonedTime(new Date(b.bucketMs), timezone),
      idle: b.idleCount,
      total: b.totalCount - b.idleCount,
    }));
    return [
      {
        label: `${capitalize(objectLabelPlural)} Idle`,
        data: mapped.map((b) => ({ primary: b.date, secondary: b.idle })),
      },
      {
        label: `Total ${capitalize(objectLabelPlural)}`,
        data: mapped.map((b) => ({ primary: b.date, secondary: b.total })),
      },
    ];
  }, [buckets, objectLabelPlural, timezone]);

  if (error) {
    return (
      <div className="h-40 md:h-52 flex-center">
        <div>No available data</div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="h-[140px] flex-center">
        <CircularProgress size={40} />
      </div>
    );
  }

  if (!chartData.length) {
    return (
      <div className="h-40 md:h-52 flex-center">
        <div>No available data</div>
      </div>
    );
  }

  return (
    <div className={clsx("h-[140px] px-2", classes.hideChartLines)}>
      <Chart
        options={{
          data: chartData,
          primaryAxis: primaryAxis(),
          secondaryAxes: customSecondaryAxes,
          primaryCursor: { showLabel: false },
          secondaryCursor: { showLabel: false },
          defaultColors: EntityColors[entity],
        }}
      />
    </div>
  );
}

const customSecondaryAxes = [{ ...secondaryAxes[0], showGrid: false }];

gql`
  query intelligenceDashboardBuckets(
    $id: Int!
    $startDate: Date!
    $endDate: Date!
    $bucketSizeSeconds: Int!
    $usePaths: Boolean
  ) {
    intelligenceDashboard(id: $id) {
      id
      metrics(
        startDate: $startDate
        endDate: $endDate
        input: { bucketSizeSeconds: $bucketSizeSeconds, usePaths: $usePaths }
      ) {
        results {
          bucketCounts {
            bucketMs
            totalCount
            idleCount
            leftCount
            rightCount
          }
        }
      }
    }
  }
`;
