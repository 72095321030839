import { Hidden } from "@mui/material";
import clsx from "clsx";
import React from "react";

import { IntelligenceDashboardQuery } from "@/generated-models";

import {
  DashboardDatePicker,
  RangeToggle,
  DashboardTypeIndicator,
} from "../../IntelligenceDashboardView";
import { useIntelligenceDateRange } from "../../hooks";
import { getDashboardFeatureConfig } from "../../utils";
import { getComparativeType, getComparativeCameras } from "../utils";

export function ComparativeDashboardContentToolbar({
  dashboard,
}: {
  dashboard: NonNullable<IntelligenceDashboardQuery["intelligenceDashboard"]>;
}) {
  const { range, setRange } = useIntelligenceDateRange();

  const type = getComparativeType(dashboard)!;
  const cameras = getComparativeCameras(dashboard);
  const config = getDashboardFeatureConfig(type, dashboard.objectTypes);

  return (
    <div className="flex flex-col -mt-[10px] bg-transparent relative z-10">
      <div
        className={clsx(
          `flex flex-col-reverse items-center gap-2
        sm:flex-row sm:justify-between md:grid grid-cols-3 px-6 py-3 border-b-2 md:rounded-t-xl
        shadow-[0_-20px_25px__-5px_rgba(0,0,0,0.1)]`,
          getDashboardFeatureConfig(type, dashboard.objectTypes).border
            .legacyPrimary,
          getDashboardFeatureConfig(type, dashboard.objectTypes).background
            .colorTertiary
        )}
      >
        <Hidden mdDown>
          <DashboardTypeIndicator
            objectTypes={dashboard.objectTypes}
            type={type}
          />
        </Hidden>
        <DashboardDatePicker cameraId={cameras[0]?.id} />
        <div className="flex justify-end items-center">
          <RangeToggle
            value={range}
            onChange={setRange}
            className={config.background.colorBase}
          />
        </div>
        <Hidden mdUp>
          <DashboardTypeIndicator
            objectTypes={dashboard.objectTypes}
            type={type}
          />
        </Hidden>
      </div>
    </div>
  );
}
