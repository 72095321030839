import clsx from "clsx";
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";

import { useZoomState } from "@/components/Player/PlayerBase";
import {
  PlayerControlsEvent,
  useMobileOverlayShown,
  usePlayerControlsService,
} from "@/components/Player/playerControlsMachine";

import { usePlayerMobileMode } from "./PlayerControls";

export function MobileOverlay({
  children,
  playerId,
}: PropsWithChildren<{ playerId?: string }>) {
  const shown = useMobileOverlayShown();
  const [pointerEventsEnabled, setPointerEventsEnabled] = useState(false);
  useEffect(() => {
    if (shown) {
      const timer = setTimeout(() => setPointerEventsEnabled(true), 500);
      return () => clearTimeout(timer);
    } else {
      setPointerEventsEnabled(false);
    }
  }, [shown]);
  const zoom = useZoomState(playerId);
  const { send } = usePlayerControlsService();
  const handleClick = useCallback(
    (e: React.TouchEvent<HTMLDivElement> | React.MouseEvent) => {
      if (e.target !== e.currentTarget) return;
      e.stopPropagation();
      send(PlayerControlsEvent.HIDE_CONTROLS);
    },
    [send]
  );
  const playerMobileMode = usePlayerMobileMode();
  if (!playerMobileMode || zoom) return null;

  return (
    <div
      // Need to add both listeners to we can stop propagation to the root player, which
      // is required because the root player will show on click/touchStart.
      onTouchStart={handleClick}
      onClick={handleClick}
      className={clsx(
        "absolute w-full h-full text-white flex-center transition-opacity bg-black bg-opacity-30",
        shown ? "" : "opacity-0",
        { "pointer-events-none": !pointerEventsEnabled }
      )}
    >
      {children}
    </div>
  );
}
