import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { BooleanParam, useQueryParam } from "use-query-params";

import { GeniusPopup } from "@/components/Genius/GeniusPopup";

import { CameraDetailByIdQuery } from "@/generated-models";
import { usePermissions } from "@/hooks/usePermissions";

export function MaintainDetailsConfigFisheye({
  camera,
}: {
  camera: CameraDetailByIdQuery["camera"];
}) {
  const [configure, setConfigure] = useQueryParam("configure", BooleanParam);
  const [popupDeviceId, setPopupDeviceId] = useState<number | null>(null);
  const hasPermission = usePermissions();
  const { device } = camera;
  const { id } = device;

  useEffect(() => {
    if (configure) {
      setPopupDeviceId(id);
    }
  }, [configure, id]);

  return (
    <>
      <Button
        fullWidth
        size="small"
        color="primary"
        variant="outlined"
        className="mt-8 mb-4"
        disabled={!hasPermission("devices_manage")}
        onClick={() => {
          setPopupDeviceId(id);
        }}
      >
        Configure Fisheye
      </Button>
      {popupDeviceId && (
        <GeniusPopup
          isFisheye
          onClose={() => {
            setPopupDeviceId(null);
            setConfigure(false);
          }}
          deviceId={popupDeviceId}
        />
      )}
    </>
  );
}
