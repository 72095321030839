import clsx from "clsx";
import { Fragment, ReactNode } from "react";

export function LicensePlateNumberIndicator({
  children,
  className,
  plate = "",
  primary = false,
  large = false,
}: {
  children?: ReactNode;
  className?: string;
  plate?: string | null;
  primary?: boolean;
  large?: boolean;
}) {
  const plates = plate?.split("/") || [];
  return (
    <div className="flex items-center gap-1">
      {plates.map((p, idx) => {
        return (
          <Fragment key={`${p}-${idx}`}>
            <div
              className={clsx(
                "text-xs md:text-sm tracking-[1.2px] md:tracking-[1.4px] px-1 font-bold font-mono uppercase border border-solid rounded",
                {
                  "text-white border-white": !primary,
                  "text-primary border-primary bg-white": primary,
                },
                className
              )}
            >
              {p}
            </div>
            {idx < plates.length - 1 && (
              <span
                className={clsx({
                  "text-white": !primary,
                  "text-primary": primary,
                })}
              >
                /
              </span>
            )}
          </Fragment>
        );
      })}
      {children}
    </div>
  );
}
