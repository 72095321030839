import { ReactComponent as AccessControlIcon_ } from "@/icons/accesscontrol.svg";
import { ReactComponent as AddMultipleIcon_ } from "@/icons/add-multiple.svg";
import { ReactComponent as CameraIcon_ } from "@/icons/camera.svg";
import { ReactComponent as CasesIcon_ } from "@/icons/case-small.svg";
import { ReactComponent as ClothingLowerIcon_ } from "@/icons/clothing-lower.svg";
import { ReactComponent as ClothingUpperIcon_ } from "@/icons/clothing-upper.svg";
import { createIconComponent } from "@/icons/createIconComponent";
import { ReactComponent as EnvironmentSensorIcon_ } from "@/icons/environmentsensor.svg";
import { ReactComponent as IntercomIcon_ } from "@/icons/intercom.svg";
import { ReactComponent as IOBoardIcon_ } from "@/icons/ioboard.svg";
import { ReactComponent as LiveIcon_ } from "@/icons/live.svg";
import { ReactComponent as LocationIcon_ } from "@/icons/location.svg";
import { ReactComponent as MaintainIcon_ } from "@/icons/maintain.svg";
import { ReactComponent as NotificationsIcon_ } from "@/icons/notifications.svg";
import { ReactComponent as SsoIcon_ } from "@/icons/sso.svg";
import { ReactComponent as WallIcon_ } from "@/icons/wall.svg";

export const AccessControlIcon = createIconComponent(AccessControlIcon_);
export const EnvironmentSensorIcon = createIconComponent(
  EnvironmentSensorIcon_
);

export const IOBoardIcon = createIconComponent(IOBoardIcon_);

export const CameraIcon = createIconComponent(CameraIcon_);
export const CasesIcon = createIconComponent(CasesIcon_);
export const ClothingLowerIcon = createIconComponent(ClothingLowerIcon_);
export const ClothingUpperIcon = createIconComponent(ClothingUpperIcon_);
export const LiveIcon = createIconComponent(LiveIcon_);
export const LocationIcon = createIconComponent(LocationIcon_);
export const MaintainIcon = createIconComponent(MaintainIcon_);
export const NotificationsIcon = createIconComponent(NotificationsIcon_);
export const WallIcon = createIconComponent(WallIcon_);
export const AddMultipleIcon = createIconComponent(AddMultipleIcon_, {
  inheritViewBox: true,
});
export const SsoIcon = createIconComponent(SsoIcon_, {
  inheritViewBox: true,
});
export const IntercomIcon = createIconComponent(IntercomIcon_, {
  inheritViewBox: true,
});
