import CheckIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { format, formatDistance } from "date-fns/fp";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { omit } from "lodash/fp";
import { Dispatch, useMemo, useReducer, useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Navigate, useParams } from "react-router-dom";
import { useInterval, useUpdateEffect } from "react-use";

import { debounceAccumulate } from "@/util/debounceAccumulate";
import { filterFalsy, filterNullish } from "@/util/filterFalsy";

import { FixedAspectRatio } from "@/components/FixedAspectRatio";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { UnmanagedSpotDrawer } from "@/components/SpotDrawer";
import { EmptyCell } from "@/components/VideoWall/EmptyCell";
import { NotAllowedCell } from "@/components/VideoWall/NotAllowedCell";
import { VideoWallConfigSelector } from "@/components/VideoWall/VideoWallConfigSelector";
import { VideoWallEditPanel } from "@/components/VideoWall/VideoWallEditPanel";
import VideoWallGrid, {
  pickWallConfiguration,
} from "@/components/VideoWall/VideoWallGrid";
import { VideoWallHeader } from "@/components/VideoWall/VideoWallHeader";
import { VideoWallLayout } from "@/components/VideoWall/VideoWallLayout";
import { DefaultDialog, useDialog } from "@/components/shared/Dialog";
import { LazyImage } from "@/components/shared/LazyImage";

import {
  Maybe,
  Page_VideoWallsDocument,
  Page_VideoWallsQuery,
  useDeleteWallMutation,
  usePage_VideoWallsQuery,
  useUpdateWallMutation,
} from "@/generated-models";
import { useOrgSlugNavigate, usePrefixOrgSlug } from "@/hooks/useOrgRouteBase";
import { GREEN } from "@/layout/theme";

import {
  VideoWallFromQuery,
  WallCamera,
  WallConfig,
  configs,
  useWallStyles,
  validateName,
} from "./constants";
import { useVideoWallContext } from "./useVideoWallContext";

type EditingEvent =
  | { type: "TOGGLE_CAMERA"; camera: WallCamera }
  | { type: "START_DRAGGING" }
  | { type: "STOP_DRAGGING" }
  | { type: "SET_CAMERA"; index: number; camera: WallCamera | null }
  | { type: "SET_CONFIG"; config: WallConfig };

interface EditingState {
  editing: boolean;
  config: WallConfig;
  cameras: Maybe<WallCamera>[];
  draggingCameras: Maybe<WallCamera>[] | null;
  currentWall: VideoWallFromQuery | null;
  nextConfig: WallConfig | null;
}

interface DragItem {
  type: "camera" | "cell";
  camera: WallCamera;
}

const initialEditingState: EditingState = {
  editing: false,
  config: configs[0],
  cameras: [],
  draggingCameras: null,
  currentWall: null,
  nextConfig: null,
};

export function SingleVideoWallEdit() {
  const { classes } = useWallStyles();
  const { largeWalls, videoWall32 } = useFlags();

  let maxWallSize: number;

  if (videoWall32) {
    maxWallSize = 36;
  } else if (largeWalls) {
    maxWallSize = 16;
  } else {
    maxWallSize = 9;
  }

  const params = useParams<{ id: string }>();
  const id = Number(params.id);

  const { data } = usePage_VideoWallsQuery();
  const wall = data?.videoWalls.find((wall) => wall.id === id);
  const [deleteWall, { loading: deletingWall }] = useDeleteWallMutation();
  const [updateWall, { loading: updatingWall }] = useUpdateWallMutation();

  const {
    fullscreenHandle,
    originalConfigurationState: [, setMatchesOriginalConfiguration],
    editingState: [, setEditing],
  } = useVideoWallContext();

  const save = useMemo(
    () =>
      debounceAccumulate(
        async function save(
          updates: {
            name?: string;
            cams?: Maybe<WallCamera>[];
            config?: WallConfig;
          }[]
        ) {
          setDebouncedUpdating(false);
          if (!wall) return;

          const { config, cams, ...restUpdate } = updates.reduceRight(
            (result, value) => {
              result.cams = result.cams ?? value.cams;
              result.config = result.config ?? value.config;
              result.name = result.name ?? value.name;
              return result;
            }
          );

          const update = {
            ...restUpdate,
            config: config as any,
            cameraIds: cams?.map((cam) => cam?.id ?? null),
          };

          setLastSaved(new Date());

          await updateWall({
            variables: {
              id: wall.id,
              update,
            },
            update: (store, { data }) => {
              if (!data) throw new Error("Failed to update wall");
              const res = store.readQuery<Page_VideoWallsQuery>({
                query: Page_VideoWallsDocument,
              })!;
              if (!res) return;
              const { videoWalls, rotatingVideoWalls } = res;
              store.writeQuery<Page_VideoWallsQuery>({
                query: Page_VideoWallsDocument,
                data: {
                  __typename: "Query",
                  videoWalls: videoWalls.map((w) => {
                    if (w.id === data.updateVideoWall.id)
                      return data.updateVideoWall;
                    return w;
                  }),
                  rotatingVideoWalls,
                },
              });
            },
          });
        },
        1000,
        () => setDebouncedUpdating(true)
      ),
    [wall, updateWall]
  );

  const [
    { config: editingWallConfig, cameras: editingCameras, draggingCameras },
    dispatch,
  ] = useReducer(editingReducer, {
    ...initialEditingState,
    currentWall: wall ?? null,
    config: omit(
      "__typename",
      wall?.config ?? pickWallConfiguration(wall?.cameras.length ?? 9)!
    ) as WallConfig,
    cameras: wall ? [...wall.cameras] : [],
  });

  const cameras = draggingCameras ?? editingCameras;
  const {
    dropCamera,
    removeCamera,
    toggleCamera,
    startDragging,
    stopDragging,
    setConfig,
  } = useEditingActions(dispatch);
  const [lastSaved, setLastSaved] = useState<Date | null>(null);
  const [debouncedUpdating, setDebouncedUpdating] = useState(false);

  function editingReducer(
    state: EditingState,
    event: EditingEvent
  ): EditingState {
    switch (event.type) {
      case "TOGGLE_CAMERA": {
        if (state.cameras.some((c) => c?.id === event.camera.id)) {
          // Remove
          return {
            ...state,
            cameras: state.cameras.map((c) =>
              c?.id === event.camera.id ? null : c
            ),
          };
        }

        // Add
        const emptySlotIndex = state.cameras.findIndex((c) => c == null);
        if (emptySlotIndex !== -1) {
          const newCameras = [...state.cameras];
          newCameras[emptySlotIndex] = event.camera;
          return { ...state, cameras: newCameras };
        } else {
          // Size up the grid to the next size
          const nextSizeConfiguration = pickWallConfiguration(
            state.cameras.length + 1,
            maxWallSize
          );

          // No more room to add camera -> noop
          if (!nextSizeConfiguration) return state;

          const newCameras = Array.from(
            {
              ...[...state.cameras, event.camera],
              length:
                nextSizeConfiguration.columns * nextSizeConfiguration.rows,
            },
            (c) => c ?? null
          );

          // Used to autosave new config if new row/column was added
          save({ config: nextSizeConfiguration });

          return {
            ...state,
            config: nextSizeConfiguration,
            cameras: newCameras,
          };
        }
      }
      case "START_DRAGGING": {
        // There are empty slots, no scale-up required
        if (state.cameras.some((c) => !c)) return state;

        // Size up the grid to the next size
        const nextSizeConfiguration = pickWallConfiguration(
          state.cameras.filter(filterFalsy).length + 1,
          maxWallSize
        );

        // No more room to add camera -> no scale-up possible
        if (!nextSizeConfiguration) return state;

        // We're in business, scaling up by setting draggingCameras
        const draggingCameras = Array.from(
          {
            ...state.cameras,
            length: nextSizeConfiguration.columns * nextSizeConfiguration.rows,
          },
          (c) => c ?? null
        );

        return { ...state, draggingCameras, nextConfig: nextSizeConfiguration };
      }
      case "STOP_DRAGGING": {
        const { draggingCameras, nextConfig, config, ...oldState } = state;

        return {
          ...oldState,
          draggingCameras: null,
          config: nextConfig ?? config,
          nextConfig: null,
        };
      }
      case "SET_CAMERA": {
        const newCameras = [...(state.draggingCameras ?? state.cameras)];
        newCameras[event.index] = event.camera;
        return { ...state, cameras: newCameras };
      }
      case "SET_CONFIG": {
        const count = event.config.sizes.length;
        // Rearrange cameras that fall "outside" of the newly selected config
        // to take up empty spots that would fall within it.
        const overflowingCameras =
          count < state.cameras.length
            ? state.cameras
                .slice(count - state.cameras.length)
                .filter(filterFalsy)
            : [];
        const newCameras = Array.from(
          { ...state.cameras, length: count },
          (c) => c ?? overflowingCameras.pop() ?? null
        );

        return {
          ...state,
          config: event.config,
          cameras: newCameras,
          nextConfig: null,
        };
      }
      default:
        return state;
    }
  }

  useUpdateEffect(
    () => {
      if (!editingCameras || !editingCameras?.length) return;
      save({ cams: editingCameras });
    },
    // Auto save when changing cameras. We need these useEffect "hacks" to ensure
    // it doesn't trigger a save on the initial render, and when the gql query response
    // comes in.
    [editingCameras.map((c) => c?.id).join(",")]
  );

  useUpdateEffect(() => {
    // Autosave when changes occur to config
    save({ config: editingWallConfig });
  }, [JSON.stringify(editingWallConfig)]);

  const { open: openDeleteWallDialog, ...deleteWallDialogProps } = useDialog();
  const { pushSnackbar } = useFeedback();
  const navigate = useOrgSlugNavigate();
  const prefixOrgSlug = usePrefixOrgSlug();

  // error and loading state should be handled in parent <VideoWallPage />
  if (!data) return null;

  if (!wall) {
    // Wall doesn't exist anymore
    localStorage.removeItem("lastViewedWallId");
    return <Navigate to={prefixOrgSlug("/wall")} replace />;
  }

  const wallNames = [...data.videoWalls, ...data.rotatingVideoWalls].map(
    (wall) => wall.name
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <VideoWallLayout
        header={
          <Formik
            initialValues={{
              name: wall.name,
            }}
            onSubmit={() => {}}
            render={() => (
              <VideoWallHeader component={Form}>
                <Box display="flex" alignItems="center">
                  <ButtonGroup color="primary">
                    <Field
                      name="name"
                      label="Video Wall Name"
                      variant="outlined"
                      size="small"
                      color="primary"
                      validate={validateName(
                        wallNames.filter((name) => name !== wall.name)
                      )}
                      onKeyUp={(e: any) => {
                        save({ name: e.target.value });
                      }}
                      classes={{
                        root: classes.wallNameInput,
                      }}
                      InputProps={{
                        classes: {
                          root: "rounded-r-none",
                          notchedOutline: classes.wallNameInputOutline,
                        },
                      }}
                      component={TextField}
                    />
                    <Button
                      className="border-l-0 rounded-l-none"
                      color="primary"
                      variant="outlined"
                      disabled={deletingWall}
                      onClick={async () => {
                        const deleteWallConfirmed = await openDeleteWallDialog();
                        if (!deleteWallConfirmed) return;

                        fullscreenHandle.exit();

                        await deleteWall({
                          variables: { id: wall.id },
                          update: (store, { data: deleteWallResponse }) => {
                            if (!deleteWallResponse)
                              throw new Error(
                                "Unable to delete wall #" + wall.id
                              );

                            setEditing(false);
                            localStorage.removeItem("lastViewedWallId");

                            const res = store.readQuery<Page_VideoWallsQuery>({
                              query: Page_VideoWallsDocument,
                            })!;
                            if (!res) return;
                            const { videoWalls } = res;
                            store.writeQuery({
                              query: Page_VideoWallsDocument,
                              data: {
                                videoWalls: videoWalls.filter(
                                  (w) => w.id !== wall.id
                                ),
                              },
                            });
                          },
                        });

                        navigate("/wall", { replace: true });

                        pushSnackbar(
                          "Video Wall deleted successfully",
                          FeedbackType.Success
                        );
                      }}
                    >
                      <DeleteForeverIcon />
                    </Button>
                  </ButtonGroup>
                  <DefaultDialog
                    title="Delete Wall"
                    content={`Are you sure you want to delete "${
                      wall!.name
                    }"? This action cannot be undone.`}
                    disablePortal
                    {...deleteWallDialogProps}
                  />
                  <Box m={0.5} />
                  <VideoWallConfigSelector
                    currentCameraCount={
                      cameras?.filter(filterFalsy).length ?? 0
                    }
                    emptySlotCount={
                      cameras?.filter((c) => c == null).length ?? 0
                    }
                    config={editingWallConfig}
                    setConfig={setConfig}
                  />
                </Box>

                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignSelf="center"
                  alignItems="center"
                >
                  {lastSaved && (
                    <>
                      <Tooltip title={format("PPP ppp", lastSaved)}>
                        <Box display="flex" alignItems="center">
                          {updatingWall || debouncedUpdating ? (
                            <CircularProgress size="20px" />
                          ) : (
                            <CheckIcon style={{ color: GREEN }} />
                          )}
                          <Box m={0.5} />
                          <Typography
                            variant="caption"
                            style={{ opacity: 0.4 }}
                          >
                            Saved <DistanceFromNow date={lastSaved} /> ago.
                          </Typography>
                        </Box>
                      </Tooltip>
                      <Box m={1} />
                    </>
                  )}
                  <Tooltip
                    title="Finish Editing"
                    style={{
                      flexShrink: 0,
                    }}
                  >
                    <Button
                      onClick={() => setEditing(false)}
                      variant="contained"
                      color="primary"
                      size="medium"
                    >
                      Done
                    </Button>
                  </Tooltip>
                </Box>
              </VideoWallHeader>
            )}
          />
        }
        body={
          <VideoWallGrid
            config={editingWallConfig}
            fullscreen={false}
            autoLayout={false}
            filterableCamIndexes={wall.cameras
              .map((cam, index) =>
                Boolean(cam && cam.__typename !== "NotAllowed") ? null : index
              )
              .filter(filterNullish)}
            onLayoutChange={({ matchesOriginalConfiguration }) =>
              setMatchesOriginalConfiguration(matchesOriginalConfiguration)
            }
          >
            {cameras.map((cam, i) => (
              <Box position="relative" key={`${cam?.id}-${i}`}>
                <FixedAspectRatio ratio="9 / 16" className="min-h-full">
                  <EditCamGridCell
                    cam={cam}
                    dropCamera={dropCamera}
                    removeCamera={removeCamera}
                    index={i}
                  />
                </FixedAspectRatio>
              </Box>
            ))}
          </VideoWallGrid>
        }
        tray={
          <UnmanagedSpotDrawer initialOpen>
            <VideoWallEditPanel
              // This `key` is deliberate, it ensures the Formik form gets reset
              // when switching walls while editing
              key={wall.id}
              editingCameraIds={editingCameras.map((cam) => cam?.id || null)}
              toggleCamera={toggleCamera}
              startDragging={startDragging}
              stopDragging={stopDragging}
            />
          </UnmanagedSpotDrawer>
        }
      />
    </DndProvider>
  );
}

function useEditingActions(dispatch: Dispatch<EditingEvent>) {
  return useMemo(() => {
    return {
      dropCamera: (index: number, camera: WallCamera) => {
        dispatch({ type: "SET_CAMERA", index, camera });
      },
      removeCamera: (index: number) => {
        dispatch({ type: "SET_CAMERA", index, camera: null });
      },
      toggleCamera: (camera: WallCamera) => {
        dispatch({ type: "TOGGLE_CAMERA", camera });
      },
      startDragging: () => {
        dispatch({ type: "START_DRAGGING" });
      },
      stopDragging: () => {
        dispatch({ type: "STOP_DRAGGING" });
      },
      setConfig: (config: WallConfig) => {
        dispatch({ type: "SET_CONFIG", config });
      },
    };
  }, [dispatch]);
}

function DistanceFromNow({ date }: { date: Date }) {
  const [now, setNow] = useState(new Date());

  useInterval(() => {
    setNow(new Date());
  }, 1000);

  return <>{formatDistance(now, date)}</>;
}

function EditCamGridCell({
  cam,
  index,
  dropCamera,
  removeCamera,
}: {
  cam: WallCamera | null;
  index: number;
  dropCamera: (index: number, camera: WallCamera) => void;
  removeCamera: (index: number) => void;
}) {
  const { classes } = useWallStyles();
  const isCam = cam?.__typename === "Camera";

  const [{ isOver, canDrop }, drop] = useDrop<
    DragItem,
    any,
    { isOver: boolean; item: DragItem; canDrop: boolean }
  >({
    accept: ["camera", "cell"],
    drop: ({ camera }) => {
      dropCamera(index, camera);
      return cam;
    },
    canDrop: (item) => (isCam || !cam) && item.camera.id !== cam?.id,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      item: monitor.getItem(),
      canDrop: monitor.canDrop(),
    }),
  });

  const [, drag] = useDrag({
    type: "cell",
    item: () => ({ camera: cam }),
    canDrag: isCam,
    end: (_, monitor) => {
      const dropResult = monitor.getDropResult() as any;
      if (!dropResult) return;
      if (dropResult.hasOwnProperty("id")) {
        // Swapping with cell containing a camera
        dropCamera(index, dropResult as WallCamera);
      } else {
        // Dropping in an empty cell
        removeCamera(index);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div ref={drop} style={{ height: "100%" }}>
      <Grid
        ref={drag}
        container
        alignItems="center"
        justifyContent="center"
        style={{
          height: "100%",
          overflow: "hidden",
          cursor: isCam ? "move" : "initial",
        }}
      >
        {isOver && canDrop && <div className={classes.dropHover} />}
        {cam?.__typename === "Camera" && (
          <LazyImage
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "center",
              display: "block",
              borderRadius: 2,
            }}
            cameraId={cam.id}
            alt=""
          />
        )}
        {cam?.__typename === "NotAllowed" && <NotAllowedCell />}
        {!cam && <EmptyCell />}
        {cam?.__typename === "Camera" && (
          <Grid
            container
            alignItems="center"
            wrap="nowrap"
            className={classes.streamDetails}
          >
            <div style={{ overflow: "hidden" }}>
              <div className={classes.slotText}>{cam.name}</div>
            </div>
            <Box flexGrow={1} />
            <Tooltip title="Remove from wall" style={{ flexShrink: 0 }}>
              <IconButton
                style={{ color: "white" }}
                size="small"
                onClick={() => removeCamera(index)}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
