import CheckIcon from "@mui/icons-material/CheckCircleOutline";
import { Box, Grid, Typography } from "@mui/material";
import clsx from "clsx";
import gql from "graphql-tag";
import { orderBy } from "lodash/fp";
import { useState } from "react";
import { useDrag } from "react-dnd";
import { makeStyles } from "tss-react/mui";

import { WallCamera } from "@/pages/VideoWall/constants";

import { Loading } from "@/components/Loading";
import { SearchBox } from "@/components/SearchBox";
import { LiveStatusIndicator } from "@/components/StatusIndicators";
import { DesktopGroupSelect } from "@/components/View/ViewNavigation";
import { useActiveGroupAndCams } from "@/components/View/sharedViewHooks";
import { LazyBackground } from "@/components/shared/LazyImage";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  useCamDrawerBaseQuery,
  useGroupsQuery,
  WallCamerasFragmentDoc,
} from "@/generated-models";
import { useCustomScrollbarStyles } from "@/layout/theme";

import { DrawerCam } from "../CamDrawer/CamDrawerBase";

const useStyles = makeStyles<void, "backgroundImage">()(
  (theme, _props, classes) => ({
    editPanel: {
      // padding: theme.spacing(2),
      // marginTop: 64,
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
    },
    cameraListHeaderContainer: {
      backgroundColor: "#F1F1F1",
      paddingTop: 0,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      [theme.breakpoints.up("md")]: {
        paddingTop: theme.spacing(2),
        // paddingTop: (props) =>
        // props.isFullscreen ? theme.spacing(2) : theme.spacing(11),
      },
    },
    cameraGrid: {
      // display: "flex",
      // flexGrow: 1,
      // flexDirection: "column",
      overflowY: "auto",
      height: "100%",
    },
    cameraBlock: {
      justifyContent: "start",
      cursor: "move",
      padding: theme.spacing(1),

      "&:hover": {
        backgroundColor: "#d1e8fa",
      },
      "&.added": {
        cursor: "pointer",
        backgroundColor: "#f1f1f1",

        [`& .${classes.backgroundImage}`]: {
          opacity: 0.2,
        },
      },
    },
    cameraThumbnail: {
      position: "relative",
      width: 65,
      height: 65,
      borderRadius: 2,
      border: "solid 1px #DDD",
      backgroundColor: "#979797",
      flexShrink: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    backgroundImage: {
      position: "absolute",
      backgroundSize: "cover",
      backgroundPosition: "center",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    },
    addedCheck: {
      color: "white",
      width: "26px",
      height: "26px",
      position: "relative",
      background: "#67C21B",
      borderRadius: "50%",
      boxShadow: "0 0 0 3px rgb(255 255 255 / 30%)",

      "& svg": {
        width: "calc(100% + 6px)",
        height: "auto",
        margin: -3,
      },
    },
    viewingLabel: {
      width: 65,
      fontSize: "0.65rem",
      position: "absolute",
      left: 0,
      bottom: 0,
      borderRadius: "0px 0px 5px 5px",
      color: "white",
      backgroundColor: "#007ce4",
      fontWeight: 500,
    },
    cameraInfo: {
      display: "flex",
      flexDirection: "column",
      height: 69,
      marginLeft: 10,
    },
  })
);

interface EditPanelProps extends DraggableCameraListeners {
  editingCameraIds: (number | null)[];
}

interface DraggableCameraListeners {
  toggleCamera?: (camera: WallCamera) => void;
  startDragging: () => void;
  stopDragging: () => void;
}

export function VideoWallEditPanel({
  editingCameraIds,
  ...draggableCameraListeners
}: EditPanelProps) {
  const { classes } = useStyles();
  const { classes: scrollbarClasses } = useCustomScrollbarStyles();
  const [searchInput, setSearchInput] = useState("");
  const { data: groupsData } = useGroupsQuery(refetchOnMountPolicy);
  const { data: camerasData } = useCamDrawerBaseQuery(refetchOnMountPolicy);
  const { activeGroup, setActiveGroup, groupCams } = useActiveGroupAndCams(
    groupsData?.groups,
    camerasData?.cameras
  );

  // TODO: this search should replaced by standard camera drawer search
  const splitSearchInput = searchInput.split(" ").map((x) => x.toLowerCase());
  function filterCamera(cam: {
    name: string;
    location: { name: string };
    tags: { name: string }[];
  }) {
    const searchText = [
      cam.name,
      cam.location.name,
      cam.tags.map((t) => t.name).join(" "),
    ].join(" ");
    const lowerCaseName = searchText.toLowerCase();
    return splitSearchInput.every((search) => lowerCaseName.includes(search));
  }

  return (
    <div className={classes.editPanel}>
      {!camerasData || !groupsData ? (
        <Loading />
      ) : (
        <>
          <Box padding={2} className={classes.cameraListHeaderContainer}>
            <DesktopGroupSelect
              groups={groupsData.groups}
              activeGroupId={activeGroup?.id}
              setGroupId={setActiveGroup}
            />
            <Box mt="10px" />
            <SearchBox
              input={searchInput}
              setInput={setSearchInput}
              placeholder="Search Cameras"
              fullWidth
            />
            <Box m={2} />
            <Typography
              variant="caption"
              style={{
                paddingLeft: 32,
                opacity: 0.5,
                fontStyle: "italic",
              }}
            >
              Click or drag and drop cameras to add to the wall.
            </Typography>
          </Box>
          <Box m={1} />
          <div
            className={clsx(
              classes.cameraGrid,
              scrollbarClasses.scrollbarContainer
            )}
          >
            {groupCams && groupCams.length === 0 ? (
              <Typography>
                No cameras have been added for this Group yet
              </Typography>
            ) : (
              <>
                {orderBy(
                  ["status", "name"],
                  ["desc", "asc"],
                  (groupCams ?? camerasData.cameras).filter(filterCamera)
                ).map((cam) => (
                  <DraggableCam
                    key={cam.id}
                    cam={cam}
                    added={editingCameraIds.includes(cam.id)}
                    {...draggableCameraListeners}
                  />
                ))}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export function DraggableCam({
  cam,
  added,
  toggleCamera,
  startDragging,
  stopDragging,
}: {
  cam: DrawerCam;
  added: boolean;
} & DraggableCameraListeners) {
  const { classes } = useStyles();
  const [, drag] = useDrag({
    type: "camera",
    item: () => {
      startDragging();
      return { camera: cam };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: stopDragging,
  });
  return (
    <Grid
      container
      wrap="nowrap"
      className={clsx(classes.cameraBlock, { added })}
      ref={added ? undefined : drag}
      onClick={() => toggleCamera?.(cam)}
    >
      <div className={classes.cameraThumbnail}>
        <LazyBackground className={classes.backgroundImage} cameraId={cam.id} />
        {added && (
          <div className={classes.addedCheck}>
            <CheckIcon />
          </div>
        )}
      </div>
      <div className={classes.cameraInfo}>
        <strong
          style={{
            fontWeight: "bold",
            overflow: "hidden",
            textOverflow: "ellipsis",
            wordWrap: "break-word",
            maxHeight: "2.8em",
            lineHeight: "1.4em",
          }}
        >
          {cam.name}
        </strong>
        <div style={{ fontSize: 10, opacity: 0.7 }}>{cam.location.name}</div>

        <Grid
          container
          alignItems="center"
          style={{
            fontSize: 12,
            marginTop: 4,
            textTransform: "capitalize",
          }}
        >
          <LiveStatusIndicator status={cam.status} />
          {cam.status}
        </Grid>
      </div>
    </Grid>
  );
}

gql`
  mutation updateWall($id: Int!, $update: VideoWallUpdate!) {
    updateVideoWall(id: $id, update: $update) {
      id
      name
      cameras {
        ... on Camera {
          ...WallCameras
        }
        ... on NotAllowed {
          id
          noAccess
        }
      }
      config {
        columns
        rows
        sizes
      }
    }
  }
  ${WallCamerasFragmentDoc}
`;
