import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Clear";
import { CircularProgress, MenuItem, Select } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import clsx from "clsx";
import { startOfDay } from "date-fns";
import { format } from "date-fns/fp";
import gql from "graphql-tag";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useMemo, useState } from "react";

import { useMe } from "@/components/Auth";
import { ErrorMessage } from "@/components/ErrorMessage";

import {
  LocationWithAppliancesQuery,
  Role,
  useApplianceLicenseQuery,
  useLicenseConfigurationsQuery,
  useSetApplianceLicenseMutation,
} from "@/generated-models";

const today = startOfDay(new Date());
const formatDate = format("MMM d, yyyy");

export function ApplianceLicenseForm({
  appliance,
}: {
  appliance: Required<
    NonNullable<Required<LocationWithAppliancesQuery>["location"]>
  >["appliances"][0];
}) {
  const [expirationOpen, setExpirationOpen] = useState(false);
  const me = useMe();
  const { licensingExpiration } = useFlags();
  const canEdit = me && me.role >= Role.Success;
  const { data, loading, error } = useApplianceLicenseQuery({
    variables: {
      serialNumber: appliance.serialNumber,
    },
  });

  const {
    data: licenseConfigs,
    loading: licenseConfigsLoading,
    error: licenseConfigsError,
  } = useLicenseConfigurationsQuery({
    variables: {
      serialNumber: appliance.serialNumber,
    },
  });

  const currentLicense = data?.applianceFromSerial?.license;
  const currentSku = currentLicense?.sku;
  const currentSkuAfterExpiration = currentLicense?.skuAfterExpiration || "";
  const expirationDate = currentLicense?.expiration
    ? formatDate(new Date(currentLicense.expiration))
    : "";
  const licenses = useMemo(() => {
    return licenseConfigs?.licenseConfigurationsFromSerial ?? [];
  }, [licenseConfigs]);

  const otherLicenses = useMemo(() => {
    return licenses.filter((l) => l.sku !== currentSku);
  }, [currentSku, licenses]);
  const [setApplianceLicense] = useSetApplianceLicenseMutation({
    refetchQueries: ["locationWithAppliances"],
  });

  if (error || licenseConfigsError) {
    return (
      <ErrorMessage
        title="Unable to fetch license data"
        description="Please try again later"
      />
    );
  }

  if (loading || licenseConfigsLoading) {
    return (
      <div className="w-full flex items-center justify-center mt-1">
        <CircularProgress size={20} />
      </div>
    );
  }

  function LicenseSelectMenu({
    value,
    items,
    onChange,
  }: {
    value?: string;
    items: { sku: string }[];
    onChange: (sku: string) => void;
  }) {
    return (
      <Select
        className="font-bold"
        classes={{
          select: "py-0",
        }}
        value={value || "__NONE__"}
        onChange={async (e) => {
          const sku = e.target.value;
          onChange(sku);
        }}
      >
        <MenuItem value="__NONE__" disabled>
          Select a license
        </MenuItem>
        {items.map(({ sku }) => (
          <MenuItem key={sku} value={sku}>
            {sku}
          </MenuItem>
        ))}
      </Select>
    );
  }

  return (
    <>
      {(canEdit || currentSku) && (
        <div className={clsx("flex items-center gap-x-3")}>
          <div>SKU License:</div>
          {canEdit ? (
            <LicenseSelectMenu
              value={currentSku}
              items={licenses}
              onChange={(sku) => {
                setApplianceLicense({
                  variables: { id: appliance.id, input: { sku } },
                });
              }}
            />
          ) : (
            <strong>{currentSku}</strong>
          )}
        </div>
      )}

      {!canEdit && currentLicense?.expiration && (
        <div className="flex items-center gap-x-3">
          <div>Expiration Date:</div>
          <div className="font-bold">{expirationDate}</div>
        </div>
      )}
      {canEdit && licensingExpiration && (
        <DatePicker
          open={expirationOpen}
          onClose={() => setExpirationOpen(false)}
          InputProps={{
            placeholder: "",
            className: "pointer-events-none",
          }}
          renderInput={({ inputRef }) => {
            return (
              <>
                {!currentLicense?.expiration && (
                  <button
                    ref={inputRef}
                    type="button"
                    className="bg-transparent flex gap-x-2 items-center text-[#007CE4] mt-1"
                    onClick={() => {
                      setExpirationOpen(true);
                    }}
                  >
                    <AddIcon fontSize="small" />
                    <div className="leading-5">Add license expiration date</div>
                  </button>
                )}
                {currentLicense?.expiration && currentSku && (
                  <div className="flex items-center gap-x-3">
                    <div>On</div>
                    <Select
                      ref={inputRef}
                      className="font-bold"
                      classes={{
                        select: "py-0",
                      }}
                      value="__DEFAULT__"
                      open={false}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setExpirationOpen(true);
                      }}
                    >
                      <MenuItem value="__DEFAULT__" disabled>
                        {expirationDate}
                      </MenuItem>
                    </Select>
                    <div>reverts to</div>
                    <LicenseSelectMenu
                      value={currentSkuAfterExpiration}
                      items={otherLicenses}
                      onChange={(sku) => {
                        setApplianceLicense({
                          variables: {
                            id: appliance.id,
                            input: { sku: currentSku, skuAfterExpiration: sku },
                          },
                        });
                      }}
                    />
                  </div>
                )}
                {currentLicense?.expiration && (
                  <button
                    ref={inputRef}
                    type="button"
                    className="bg-transparent flex gap-x-2 items-center text-[#007CE4]"
                    onClick={() => {
                      setApplianceLicense({
                        variables: {
                          id: appliance.id,
                          input: {
                            sku: currentSku!,
                            skuAfterExpiration: null,
                            expiration: null,
                          },
                        },
                      });
                    }}
                  >
                    <RemoveIcon fontSize="small" />
                    <div className="leading-5">Remove Expiration Date</div>
                  </button>
                )}
              </>
            );
          }}
          inputFormat="PP"
          value={currentLicense?.expiration}
          onChange={(date) => {
            setApplianceLicense({
              variables: {
                id: appliance.id,
                input: { sku: currentSku!, expiration: date?.toISOString() },
              },
            });
          }}
          minDate={today}
        />
      )}
    </>
  );
}

gql`
  mutation setApplianceLicense($id: Int!, $input: SetLicenseInput!) {
    setApplianceLicense(id: $id, input: $input) {
      id
      license {
        id
        configuration {
          sku
          features {
            id
            label
            description
            value
            type
          }
        }
        sku
        skuAfterExpiration
        seats
        isExpired
        expiration
      }
    }
  }
`;

gql`
  query licenseConfigurations($serialNumber: String!) {
    licenseConfigurationsFromSerial(serialNumber: $serialNumber) {
      sku
      features {
        label
        description
        value
        type
      }
    }
  }
`;

gql`
  query applianceLicense($serialNumber: String!) {
    applianceFromSerial(serialNumber: $serialNumber) {
      id
      license {
        id
        configuration {
          sku
          features {
            id
            label
            description
            value
            type
          }
        }
        sku
        skuAfterExpiration
        seats
        isExpired
        expiration
      }
    }
  }
`;

gql`
  query applianceLicenses {
    appliances {
      id
      serialNumber
      license {
        id
        configuration {
          sku
          features {
            id
            label
            description
            value
            type
          }
        }
        sku
        skuAfterExpiration
        seats
        isExpired
        expiration
      }
    }
  }
`;
