import gql from "graphql-tag";
import { max, min } from "lodash/fp";
import { useMemo } from "react";

import {
  IntelligenceHeatmapQueryVariables,
  useIntelligenceHeatmapQuery,
} from "@/generated-models";

export function useHeatmap({
  intDashId,
  input,
}: IntelligenceHeatmapQueryVariables) {
  const { startDate, endDate, gridSize } = input;
  const { data, loading, error } = useIntelligenceHeatmapQuery({
    variables: {
      intDashId,
      input: {
        startDate,
        endDate,
        gridSize,
      },
    },
  });

  const maxHeat = useMemo(() => {
    return (
      max(
        data?.intelligenceDashboard?.heatmap.results?.map(({ heat }) => heat)
      ) || 0.000001
    ); // epsilon
  }, [data]);

  const minHeat = useMemo(() => {
    return (
      min(
        data?.intelligenceDashboard?.heatmap.results?.map(({ heat }) => heat)
      ) || 0.000001
    ); // epsilon
  }, [data]);

  return {
    data,
    loading,
    error,
    maxHeat,
    minHeat,
  };
}

gql`
  query intelligenceHeatmap(
    $intDashId: Int!
    $input: IntelligenceHeatmapOptions!
  ) {
    intelligenceDashboard(id: $intDashId) {
      id
      heatmap(input: $input) {
        results {
          x
          y
          heat
          presenceSumMsec
          throughput
        }
      }
    }
  }
`;
