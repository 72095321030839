import { useMemo } from "react";

import { useMe } from "@/components/Auth";

import { AdditionalAiClass, useCameraSettingsQuery } from "@/generated-models";

export function useAIModelEnabled(
  model: AdditionalAiClass,
  cameraIds?: number[]
) {
  const me = useMe();
  const { data } = useCameraSettingsQuery();

  return useMemo(() => {
    if (model === AdditionalAiClass.Forklift) {
      return (
        me?.organization?.flags?.additionalAiFeature ===
          AdditionalAiClass.Forklift ||
        !!data?.cameras
          .filter((c) => !cameraIds || cameraIds.includes(c.id))
          .find((c) => c.settings.modelForkliftEnabled)
      );
    }
  }, [
    cameraIds,
    data?.cameras,
    me?.organization?.flags?.additionalAiFeature,
    model,
  ]);
}
