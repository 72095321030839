import CloseIcon from "@mui/icons-material/Close";
import CopyIcon from "@mui/icons-material/FileCopyOutlined";
import MailIcon from "@mui/icons-material/Mail";
import MessageIcon from "@mui/icons-material/Message";
import ShareIcon from "@mui/icons-material/Share";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Hidden,
  IconButton,
  Popover,
  PopoverProps,
  TextField,
  Typography,
} from "@mui/material";
import * as clipboard from "clipboard-polyfill/build/clipboard-polyfill.promise";
import { isBefore } from "date-fns/fp";
import { Field, FieldValidator, Form, Formik } from "formik";
import { TextField as FormikTextField } from "formik-mui";
import { debounce } from "lodash/fp";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { makeStyles } from "tss-react/mui";

import { validateEmail, validatePhoneNumber } from "@/util/form";
import { isMobileUserAgent } from "@/util/userAgent";

import { CopyableTextInput } from "@/components/CopyableTextInput";
import { RelativeDatePicker } from "@/components/DateTimePicker/RelativeDatePicker";
import { Loading } from "@/components/Loading";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";

import { Maybe } from "@/generated-models";

import { isMobileApp } from "../Mobile/mobileEnvironment";

const useSharingLinkDialogStyles = makeStyles()((theme) => ({
  root: {
    overflow: "initial",
    width: 400,

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0",
      minHeight: "100vh",
      "&": {
        /* mobile viewport bug fix */
        minHeight: "-webkit-fill-available",
      },
      maxHeight: "none",
      maxWidth: "none",
      borderRadius: "0",
    },
  },
  topBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
  content: {
    width: "100%",
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(0.5),
    flexGrow: 0,
  },
  actions: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: "grid",
    gap: theme.spacing(1),
    "& > *": {
      gridRow: 1,
      position: "relative",
    },
  },
  shareLinkField: {
    flexGrow: 1,
  },
}));
export function SharingLinkDialog({
  submit,
  submitting,
  sharedLink,
  link,
  title,
  cameraName,
  expirationLabel,
  linkLabel,
  sendEmail,
  sendText,
  disableImmediately,
  hideCopyButton,
  opened,
  cancel,
  confirm,
  ...dialogProps
}: {
  submit: (description: string, expiry: Date | null) => void;
  submitting: boolean;
  sharedLink?: Maybe<{
    description: string;
    expiry?: Maybe<string>;
  }>;
  link: string | null;
  title: React.ReactNode;
  cameraName?: string;
  expirationLabel: string;
  linkLabel: string;
  hideCopyButton?: boolean;
  sendEmail: (email: string) => Promise<void>;
  sendText: (phoneNumber: string) => Promise<void>;
  disableImmediately?: () => void;

  opened: boolean;
  cancel: () => void;
  confirm: () => void;
} & Omit<DialogProps, "open" | "onClose" | "title">) {
  const { classes } = useSharingLinkDialogStyles();
  const actionsRef = useRef<HTMLDivElement>(null);

  const [description, setDescription] = useState("");
  // expiry=null means it wasn't edited by the user
  const [expiry, setExpiry] = useState<null | Date>(null);

  useEffect(() => {
    if (!sharedLink) return;
    // Only update the state if it was empty - if we'd update all the time
    // it could lead to race conditions with gql mutations coming back
    // out-of-order.
    setDescription(
      (oldDescription) => oldDescription || sharedLink.description
    );
    setExpiry(sharedLink.expiry ? new Date(sharedLink.expiry) : null);
    // eslint-disable-next-line
  }, [sharedLink?.description, sharedLink?.expiry]);

  // eslint-disable-next-line
  const persistInput = useCallback(debounce(500, submit), [submit]);

  const copyPopper = usePopper(5000);
  const emailPopper = usePopper();
  const phonePopper = usePopper();

  const { pushSnackbar } = useFeedback();

  function close() {
    submit(description, expiry);
    if (determineLinkSharingActive(expiry?.toISOString())) {
      confirm();
    } else {
      cancel();
    }
  }

  return (
    <Dialog
      classes={{ paper: classes.root }}
      open={opened}
      onClose={close}
      {...dialogProps}
    >
      <Box className={classes.topBar}>
        <DialogTitle className={classes.title}>
          <Typography
            variant="h3"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {title}
          </Typography>
        </DialogTitle>
        <Box pt={1} pr={1}>
          <IconButton onClick={close} size="large">
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      {!sharedLink ? (
        <Box
          style={{
            minWidth: 400,
            minHeight: 200,
            display: "grid",
            alignItems: "center",
          }}
        >
          <Loading />
        </Box>
      ) : (
        <>
          <DialogContent className={classes.content}>
            <TextField
              multiline
              label="Description"
              rows="3"
              fullWidth
              value={description}
              onChange={(e) => {
                const newValue = e.target.value;
                setDescription(newValue);
                persistInput(newValue, expiry);
              }}
              variant="outlined"
              onKeyUp={(e) => {
                if (e.keyCode === 13 && (e.metaKey || e.ctrlKey)) {
                  e.preventDefault();
                  e.stopPropagation();
                  close();
                }
              }}
            />

            <Box m={2.5} />
            <RelativeDatePicker
              label={expirationLabel}
              value={expiry}
              onChange={(newValue) => {
                setExpiry(newValue);
                persistInput(description, newValue);
              }}
              disableImmediately={
                disableImmediately
                  ? () => {
                      disableImmediately();
                      cancel();
                    }
                  : undefined
              }
              style={{ minWidth: 250 }}
            />
            <Box m={1.5} />
            <CopyableTextInput
              label={linkLabel}
              disabled={!link}
              className={classes.shareLinkField}
              fullWidth
              value={link ?? ""}
            />
          </DialogContent>
          {!!navigator.share && (isMobileUserAgent() || isMobileApp) ? (
            <div className="flex-center p-4 pt-2">
              <Button
                fullWidth
                size="large"
                variant="contained"
                color="primary"
                startIcon={<ShareIcon />}
                onClick={() =>
                  link &&
                  navigator
                    .share({ title: cameraName, url: link })
                    .catch(console.error)
                }
              >
                Share Link
              </Button>
            </div>
          ) : (
            <div className={classes.actions} ref={actionsRef}>
              {!hideCopyButton && (
                <Button
                  onClick={() =>
                    link && clipboard.writeText(link).then(copyPopper.show)
                  }
                  variant="contained"
                  color="primary"
                  startIcon={<CopyIcon />}
                >
                  Copy
                </Button>
              )}
              <Button
                onClick={emailPopper.show}
                variant="contained"
                color="primary"
                startIcon={<MailIcon />}
              >
                Mail
              </Button>
              <Button
                onClick={phonePopper.show}
                variant="contained"
                color="primary"
                startIcon={<MessageIcon />}
              >
                Text<Hidden smDown> Message</Hidden>
              </Button>
              <CopiedAlert {...copyPopper} anchorEl={actionsRef.current} />
              <SendLinkPopper
                anchorEl={actionsRef.current}
                {...emailPopper}
                onSubmit={async (value) => {
                  await sendEmail(value)
                    .then(() => {
                      pushSnackbar(
                        `Link sent to ${value}`,
                        FeedbackType.Success
                      );
                    })
                    .catch(() => {
                      pushSnackbar(
                        `Unable to email sharing link to ${value}. Please check the email address.`,
                        FeedbackType.Error
                      );
                    });
                  emailPopper.onClose();
                }}
                validate={validateEmail}
                label="Email Address"
              />
              <SendLinkPopper
                anchorEl={actionsRef.current}
                {...phonePopper}
                onSubmit={async (value) => {
                  await sendText(value)
                    .then(() => {
                      pushSnackbar(
                        `Link sent to ${value}`,
                        FeedbackType.Success
                      );
                    })
                    .catch(() => {
                      pushSnackbar(
                        `Unable to text sharing link to ${value}. Please check the number.`,
                        FeedbackType.Error
                      );
                    });
                  phonePopper.onClose();
                }}
                validate={validatePhoneNumber}
                label="Phone Number"
              />
            </div>
          )}
        </>
      )}
    </Dialog>
  );
}

function usePopper(delay?: number) {
  const [open, setOpen] = useState(false);
  const timerRef = useRef<number>(0);
  const show = useCallback(() => {
    if (timerRef.current) {
      window.clearTimeout(timerRef.current);
    }
    setOpen(true);

    if (delay) {
      timerRef.current = window.setTimeout(() => {
        setOpen(false);
        timerRef.current = 0;
      }, delay);
    }
  }, [delay]);
  const onClose = useCallback(() => setOpen(false), []);
  return {
    open,
    show,
    onClose,
  };
}

const useAlertStyles = makeStyles()((theme) => ({
  paper: {
    padding: theme.spacing(1.5),
    width: 380,
    display: "flex",
    alignItems: "center",
  },
}));
function CopiedAlert({
  open,
  onClose,
  anchorEl,
}: Pick<PopoverProps, "open" | "onClose" | "anchorEl">) {
  const { classes } = useAlertStyles();
  return (
    <Popover
      classes={{
        paper: classes.paper,
      }}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
    >
      <CopyIcon fontSize="small" />
      <Box m={1} />
      Link Copied
      <Box flexGrow={1} />
      <IconButton
        onClick={() => onClose?.({}, "escapeKeyDown")}
        size="small"
        color="primary"
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Popover>
  );
}

const useSendLinkStyles = makeStyles()((theme) => ({
  paper: {
    padding: theme.spacing(1.5),
    width: 440,
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    gap: "8px",
  },
}));
function SendLinkPopper({
  open,
  onClose,
  anchorEl,
  onSubmit,
  validate,
  label,
}: Pick<PopoverProps, "open" | "onClose" | "anchorEl"> & {
  label: string;
  onSubmit: (value: string) => Promise<void>;
  validate: FieldValidator;
}) {
  const { classes } = useSendLinkStyles();

  return (
    <Popover
      classes={{
        paper: classes.paper,
      }}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
    >
      <Formik
        initialValues={{
          value: "",
        }}
        onSubmit={async ({ value }, { setSubmitting, resetForm }) => {
          await onSubmit(value);
          setSubmitting(false);
          resetForm();
        }}
      >
        {({ isSubmitting }) => (
          <Form className={classes.grid}>
            <Field
              component={FormikTextField}
              name="value"
              validate={validate}
              variant="outlined"
              label={label}
              size="small"
            />
            <Button
              disabled={isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
            >
              {isSubmitting ? (
                <>
                  Sending
                  <Box width={10} display="inline-block" />
                  <CircularProgress color="primary" size={12} />
                </>
              ) : (
                "Send"
              )}
            </Button>
          </Form>
        )}
      </Formik>
    </Popover>
  );
}

export function determineLinkSharingActive(expiry?: Maybe<string | Date>) {
  // Handling `undefined` as "loading", in which case we want to fall back to sharing=false
  return (
    expiry === null ||
    Boolean(expiry && !isBefore(new Date(), new Date(expiry)))
  );
}
