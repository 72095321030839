import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { Skeleton, Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import clsx from "clsx";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useMemo, memo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { AI } from "@/icons/AI";
import { LPRIcon } from "@/icons/LPRIcon";
import { ReactComponent as FisheyeIcon } from "@/icons/fisheye.svg";
import { ReactComponent as ForkliftIcon } from "@/icons/forklift.svg";

import { MaintainDatagrid } from "@/pages/Maintain/Datagrid/MaintainDatagrid";
import { MaintainToolbar } from "@/pages/Maintain/Datagrid/MaintainToolbar";
import { DeviceIcon } from "@/pages/Maintain/DeviceIcon";
import { MaintainStatusChip } from "@/pages/Maintain/MaintainStatusChip";
import {
  useMaintainDeviceStatistics,
  useMaintainFilteredDevices,
} from "@/pages/Maintain/hooks";

import { useMe } from "@/components/Auth";
import { useDataGridSelection } from "@/components/DataGrid/hooks";
import { ErrorMessage } from "@/components/ErrorMessage";
import { LazyImage } from "@/components/shared/LazyImage";

import { ApplianceAiMode, Role } from "@/generated-models";
import { ContentWrapper } from "@/layout/ContentWrapper";

import { ExpandedCameras } from "../utils";
import { useMaintainBulkConfig } from "./useMaintainBulkConfig";

export function MaintainCameraDatagridComponent() {
  const navigate = useNavigate();

  const { cameraBulkCfg } = useFlags();
  const { pathname, search } = useLocation();
  const {
    loading,
    streamLoading,
    cameras,
    filteredCameras,
    error,
  } = useMaintainFilteredDevices();
  const { locked } = useDataGridSelection();

  // Initially sort and prioritize AI & online cameras first.
  const sortedFilteredCameras = useMemo(
    () =>
      filteredCameras?.slice()?.sort((a, b) => {
        const statusDiff = b.status?.localeCompare(a.status);
        if (statusDiff) return statusDiff;
        return (
          Number(b.appliance.deviceAiMode !== ApplianceAiMode.Disabled) -
          Number(a.appliance.deviceAiMode !== ApplianceAiMode.Disabled)
        );
      }),
    [filteredCameras]
  );
  const {
    cameras: { online, offline, outOfSpec },
  } = useMaintainDeviceStatistics(true);

  const me = useMe();
  const isSuccess = me && me.role >= Role.Success;

  const rowSelectionProps = useMaintainBulkConfig();

  if (error) {
    return (
      <ContentWrapper>
        <ErrorMessage title="Error" description={error?.message} />
      </ContentWrapper>
    );
  }
  if (!filteredCameras && !loading) {
    return (
      <ErrorMessage
        title="Unable to fetch camera data"
        description="Please try again later"
      />
    );
  }

  const columns: GridColDef<ExpandedCameras[0]>[] = [
    {
      field: "id",
      headerName: "Preview",
      sortable: false,
      headerClassName: "invisible",
      cellClassName: "pl-0",
      renderCell: ({ row }) => {
        return <LazyImage cameraId={row.id} className="h-[57px] w-[100px]" />;
      },
    },
    {
      field: "name",
      headerName: "Camera Name",
      width: 278,
      renderCell: ({ value, row }) => {
        const isNvr = row.device.isNvr;
        const isFisheye = row.device.isFisheye;
        const aiEnabled =
          row.appliance.deviceAiMode !== ApplianceAiMode.Disabled;
        const lprEnabled = row.settings.lprEnabled;
        const forkliftEnabled = row.settings.modelForkliftEnabled;
        const audioControlEnabled = row.settings.audioControlEnabled;
        const Icon = isFisheye ? FisheyeIcon : DeviceIcon;

        return (
          <div>
            <div className="flex gap-x-2 items-center">
              <Icon className={clsx(!isNvr && "-mt-0.5")} isNvr={isNvr} />
              <div className="flex gap-x-1 items-center">
                <div className="truncate max-w-[150px]" title={value}>
                  {value}
                </div>
              </div>
              {aiEnabled && (
                <Tooltip title="Intelligence Enabled">
                  <span>
                    <AI />
                  </span>
                </Tooltip>
              )}
              {audioControlEnabled && (
                <Tooltip title="Audio Enabled">
                  <VolumeUpIcon className="text-primary" />
                </Tooltip>
              )}
              {lprEnabled && (
                <Tooltip title="License Plate Recognition Enabled">
                  <span>
                    <LPRIcon className="text-primary" />
                  </span>
                </Tooltip>
              )}
              {forkliftEnabled && (
                <ForkliftIcon className="mt-1 text-primary" />
              )}
            </div>
            {isFisheye && (
              <div className="text-text text-xs ml-[28px]">
                Stream {row.streamCounter}
              </div>
            )}
          </div>
        );
      },
    },
    {
      field: "location",
      headerName: "Location",
      flex: 2,
      valueGetter: ({ row }) => row.appliance.location.name,
    },
    { field: "vendor", headerName: "Vendor", width: 130 },
    {
      field: "ip",
      headerName: "IP Address",
      width: 150,
      valueGetter: ({ row }) => row.device.ip,
      sortComparator: (i1: string, i2: string) => {
        const convertIp = (ip: string) =>
          Number(
            ip
              .split(".")
              .map(function (x) {
                return x.padStart(3, "0");
              })
              .join("")
          );
        return convertIp(i1) - convertIp(i2);
      },
    },
    {
      field: "appliance",
      headerName: "Appliance",
      type: "string",
      width: 125,
      sortComparator: (c1: string, c2: string) => c2.localeCompare(c1),
      valueGetter: ({ row }) => row.appliance.serialNumber,
    },
    {
      field: "resolution",
      headerName: "Resolution",
      width: 90,
      valueGetter: ({ row }) => row.stream.metadata.height,
      renderCell: ({ value }) =>
        streamLoading ? (
          <Skeleton variant="text" className="grow" />
        ) : value > 0 ? (
          `${value}p`
        ) : (
          ""
        ),
    },
    {
      field: "fps",
      headerName: "FPS",
      width: 40,
      valueGetter: ({ row }) => row.stream.metadata.fps,
      renderCell: ({ value }) =>
        streamLoading ? (
          <Skeleton variant="text" className="grow" />
        ) : value > 0 ? (
          value
        ) : (
          ""
        ),
    },
    {
      field: "status",
      headerName: "Status",
      type: "string",
      width: 115,
      sortComparator: (c1: string, c2: string) => c2.localeCompare(c1),
      renderCell: ({ row }) => {
        return (
          <MaintainStatusChip
            title="Camera"
            camera={row}
            loading={streamLoading}
          />
        );
      },
    },
  ];

  if (isSuccess) {
    columns.push({
      field: "cid",
      headerName: "ID",
      type: "string",
      width: 75,
      valueGetter: ({ row }) => row.id,
    });
  }

  return (
    <div>
      <MaintainToolbar
        loading={loading}
        online={online}
        offline={offline}
        outOfSpec={outOfSpec}
      />
      <MaintainDatagrid
        rowSelectionProps={rowSelectionProps}
        columnVisibilityModel={{
          appliance: cameraBulkCfg || isSuccess,
        }}
        initialState={{
          pagination: { paginationModel: { pageSize: 32 } },
        }}
        loading={loading}
        columns={columns}
        baseRows={cameras || []}
        rows={sortedFilteredCameras || []}
        onRowClick={({ id }) => {
          if (locked) return;
          navigate(String(id), {
            state: { goBack: `${pathname}${search}` },
          });
        }}
      />
    </div>
  );
}

export const MaintainCameraDatagrid = memo(MaintainCameraDatagridComponent);
