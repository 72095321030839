import { useAtom } from "jotai";
import { orderBy } from "lodash/fp";
import { useMemo } from "react";

import { StatefulDrawerCam } from "@/components/CamDrawer/CamDrawerBase";
import { groupIdAtom } from "@/components/CamDrawer/CamDrawerGroupSelector";
import { searchInputAtom } from "@/components/CamDrawer/CamDrawerSearchBox";
import { selectedOnlyFilterAtom } from "@/components/CamDrawer/CamDrawerSelectedOnlyFilter";

export function useCamDrawerCams<T extends StatefulDrawerCam>(
  cameras: T[] | undefined
): {
  cams: T[];
  splitSearchInput: string[];
} {
  const [searchInput] = useAtom(searchInputAtom);
  const [groupId] = useAtom(groupIdAtom);
  const [selectedOnly] = useAtom(selectedOnlyFilterAtom);

  return useMemo(() => {
    const splitSearchInput = searchInput.split(" ").map((x) => x.toLowerCase());
    if (!cameras) return { cams: [], splitSearchInput };

    // Cameras in active group
    const groupCams = groupId
      ? cameras.filter((c) => c.tags.some((g) => g.id === groupId))
      : undefined;

    function filterCamera(cam: StatefulDrawerCam) {
      if (selectedOnly && !cam.selected) return false;
      const searchText = [
        cam.name,
        cam.location.name,
        cam.tags.map((t) => t.name).join(" "),
      ].join(" ");
      const lowerCaseName = searchText.toLowerCase();
      return splitSearchInput.every((search) => lowerCaseName.includes(search));
    }
    const filtered = (groupCams ?? cameras).filter(filterCamera);
    const cams = orderBy(
      ["disabled", "status", "name"],
      ["asc", "desc", "asc"],
      filtered
    );

    return { cams, splitSearchInput };
  }, [cameras, groupId, searchInput, selectedOnly]);
}
