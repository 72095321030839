import { useEffect, useState } from "react";

import { useScript } from "@/util/useScript";

import { useMe } from "@/components/Auth";
import {
  isMobileApp,
  mobileFeatureFlags,
} from "@/components/Mobile/mobileEnvironment";

interface BeamerConfig {
  product_id: string;
  selector?: string;
  button?: boolean;
  display?: string;
  top?: number;
  right?: number;
  language?: string;
  standalone?: boolean;
  filter?: string;
  lazy?: boolean;
  alert?: boolean;

  user_firstname?: string;
  user_lastname?: string;
  user_email?: string;
  user_id?: string | number;

  [key: string]: string | number | boolean | undefined;
}

declare global {
  interface Window {
    beamer_config: BeamerConfig;
    Beamer:
      | {
          init: () => {};
          show: () => {};
          hide: () => {};
          update: (parameters: BeamerConfig) => {};
          destroy: () => {};
        }
      | undefined;
  }
}

export function useBeamer() {
  const [beamerConfigSet, setBeamerConfigSet] = useState(false);
  const me = useMe();

  useEffect(() => {
    if (!me || (isMobileApp && !mobileFeatureFlags.externalLinks)) return;
    // Beamer docs: https://www.getbeamer.com/docs/#parameters
    window.beamer_config = {
      product_id: "bXDVwKbR24150",
      selector: ".beamerTrigger",
      button: false,
      display: "right",
      top: -10,
      right: -10,
      language: "EN",
      filter: "admin",
      standalone: true,
      lazy: true,
      alert: true,

      user_name: me.name,
      user_email: me.email,
      organization: me.organization.name,
    };
    setBeamerConfigSet(true);
  }, [me]);
  const [loaded] = useScript(
    "https://app.getbeamer.com/js/beamer-embed.js",
    !beamerConfigSet
  );

  useEffect(() => {
    if (!loaded) return;
    window.Beamer?.init();
  }, [loaded]);
}
