import { format } from "date-fns/fp";
import gql from "graphql-tag";
import { atom } from "jotai";
import { useMemo } from "react";
import { NumberParam, useQueryParam } from "use-query-params";

import {
  useApplianceConfigQuery,
  useApplianceConfigsMetadataQuery,
} from "@/generated-models";

import { useMaintainDevices } from "../../hooks";

export type MaintainApplianceConfig = {
  id: number;
  serialNumber: string;
  data: any;
  configType: string;
};

export const applianceConfigsAtom = atom<
  Record<string, MaintainApplianceConfig>
>({});

export function useSelectedAppliance() {
  const { appliances } = useMaintainDevices();
  const [selected, setSelected] = useQueryParam("selected", NumberParam);

  const selectedAppliance = useMemo(
    () => appliances?.find((a) => a.id === selected),
    [appliances, selected]
  );

  return { selected, setSelected, selectedAppliance };
}

export function useApplianceConfig(id?: number | null, skip?: boolean) {
  const { selected, selectedAppliance } = useSelectedAppliance();

  const serialNumber = selectedAppliance?.serialNumber;

  const { data: configQueryData, loading, error } = useApplianceConfigQuery({
    variables: {
      serialNumber: serialNumber || "",
      input: {
        type: "iotCore",
        id,
      },
    },
    fetchPolicy: "network-only",
    skip: !serialNumber || skip,
  });

  const data = useMemo(() => {
    const config = configQueryData?.applianceFromSerial?.config;
    let value;

    try {
      value = config ? JSON.stringify(config.data, null, 2) : null;
    } catch (e) {
      // noop
    }

    return {
      value: value || "{}",
      path: `${selected}`,
      config,
    };
  }, [configQueryData?.applianceFromSerial?.config, selected]);

  return { data, loading, error };
}

export function useApplianceDiff() {
  const [compareTo, setCompareTo] = useQueryParam("compare", NumberParam);

  const {
    data: { value: modified },
    loading: loadingModified,
  } = useApplianceConfig();

  const {
    data: { value: original },
    loading: loadingOriginal,
  } = useApplianceConfig(compareTo, !compareTo);

  return {
    enabled: !!compareTo,
    versionId: compareTo,
    setVersionId: setCompareTo,
    reset: () => setCompareTo(undefined, "replaceIn"),
    original,
    modified,
    loading: loadingOriginal || loadingModified,
  };
}

const dateFormat = "MMM, d, hh:mm:ss a";

export function useApplianceConfigHistory() {
  const { selectedAppliance } = useSelectedAppliance();
  const { data } = useApplianceConfigsMetadataQuery({
    variables: {
      serialNumber: selectedAppliance?.serialNumber || "",
    },
    skip: !selectedAppliance,
  });

  return useMemo(() => {
    const items = [...(data?.applianceConfigsMetadata || [])].sort((a, b) => {
      return (b.createdAt || "")?.localeCompare(a.createdAt || "");
    });

    return (
      items.map((i, idx) => {
        const date = i.createdAt
          ? format(dateFormat)(new Date(i.createdAt || ""))
          : "";
        const delivered = i.deliveredAt
          ? format(dateFormat)(new Date(i.deliveredAt || ""))
          : "";
        const version = items.length - idx;
        return {
          label: `Version ${version} - (${date})`,
          date,
          deliveryDate: delivered,
          version,
          value: i.id,
        };
      }) || []
    );
  }, [data?.applianceConfigsMetadata]);
}

gql`
  query applianceConfigSchema {
    applianceConfigSchema {
      schema
    }
  }
`;

gql`
  query applianceConfigsMetadata($serialNumber: String!, $type: String) {
    applianceConfigsMetadata(serialNumber: $serialNumber, type: $type) {
      id
      configType
      createdAt
      deliveredAt
    }
  }
`;

gql`
  query applianceConfig($serialNumber: String!, $input: ApplianceConfigInput!) {
    applianceFromSerial(serialNumber: $serialNumber) {
      id
      config(input: $input) {
        id
        configType
        createdAt
        data
      }
    }
  }
`;

gql`
  mutation updateApplianceConfiguration(
    $input: UpdateApplianceConfigurationInput!
  ) {
    updateApplianceConfiguration(input: $input) {
      message
    }
  }
`;
