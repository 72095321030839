import { Typography } from "@mui/material";
import clsx from "clsx";

export function WatchLiveHeader({ className }: { className?: string }) {
  return (
    <Typography
      variant="h1"
      className={clsx(
        "font-bold text-transparent text-center bg-clip-text spot-gradient",
        className
      )}
    >
      <span className="font-extralight">Watch</span> Live
    </Typography>
  );
}
