import { CircularProgress } from "@mui/material";
import clsx from "clsx";
import { useLocation, useNavigate } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";

import { useBreakpoints } from "@/util/useBreakpoints";

import { ErrorMessage } from "@/components/ErrorMessage";

import { IntegrationTypeKey } from "@/generated-models";

import { IntegrationsEventDatagrid } from "../../Core/IntegrationsEventDatagrid";
import IntegrationsNavigationHeader from "../../Core/IntegrationsNavigationHeader";
import {
  IntegrationEventData,
  useCurrentVendor,
  useCurrentSourceId,
  useIntegrationEvents,
  useSourceById,
  useCurrentTypeId,
} from "../../hooks";
import { IntegrationDetailsEventSummaryBar } from "../IntegrationDetailsEvent/IntegrationDetailsEventSummaryBar";
import { IntegrationsDetailsDeviceWeeklySummary } from "./IntegrationsDetailsDeviceWeeklySummary";
import { IntegrationsDetailsDeviceOverviewPlayer } from "./IntegrationsDeviceDetailsOverviewPlayer";

function DeviceOverviewEventDatagrid() {
  const { fitsDesktop } = useBreakpoints();
  const { data, loading } = useIntegrationEvents();
  const navigate = useNavigate();
  const currentSourceId = useCurrentSourceId();

  return (
    <div className="col-span-1 flex flex-col">
      <IntegrationDetailsEventSummaryBar
        className="md:px-0 px-4 py-2"
        condensed={fitsDesktop}
        loading={loading}
        events={data as IntegrationEventData[]}
      />
      <IntegrationsEventDatagrid
        onRowClick={(_, options) => {
          navigate({
            search: options.search,
          });
        }}
        sourceId={currentSourceId}
      />
    </div>
  );
}

export function IntegrationsDetailsDeviceOverview() {
  const { pathname } = useLocation();
  const vendor = useCurrentVendor();
  const { data, loading, error } = useSourceById();
  const { fitsDesktop } = useBreakpoints();
  const [params] = useQueryParam("tab", StringParam);
  const type = useCurrentTypeId();
  const backPath = pathname.split("/").slice(0, -2).join("/");

  if (loading) {
    return (
      <div className="flex items-center justify-center py-24 w-full">
        <CircularProgress size={30} />
      </div>
    );
  }

  if (error) {
    return <ErrorMessage title="Error" description={error.toString()} />;
  }

  return (
    <div>
      <div className="flex items-center justify-between md:bg-transparent bg-white pt-4 md:pt-0">
        <div className="flex flex-col gap-1 relative">
          <IntegrationsNavigationHeader
            to={Boolean(params) ? `${backPath}?tab=${params}` : backPath}
            title={data?.standardMeta?.name}
            className="!md:gap-3 !gap-1 sm:mt-2"
            textClassName={clsx({ "text-2xl leading-[28px]": !fitsDesktop })}
            subText={data?.standardMeta?.siteName}
          />
        </div>
        {fitsDesktop && vendor && (
          <img
            className="h-4 md:h-9 md:mr-0 mr-4"
            alt={vendor.name}
            src={vendor.logoSrc}
          />
        )}
      </div>
      <IntegrationsDetailsDeviceWeeklySummary />
      <div className="grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-[38px] md:pt-6">
        <IntegrationsDetailsDeviceOverviewPlayer
          lowLatency={type === IntegrationTypeKey.Intercom}
        />
        {type !== IntegrationTypeKey.Intercom && (
          <DeviceOverviewEventDatagrid />
        )}
      </div>
    </div>
  );
}
