import { Avatar, Box, Typography } from "@mui/material";
import clsx from "clsx";
import React from "react";
import { makeStyles } from "tss-react/mui";

import { Spotcast } from "@/icons/Spotcast";

const useStyles = makeStyles()((theme) => ({
  avatar: {
    background: "#007CE4",
    color: "white",
    fontWeight: "bold",
  },
  stepContainer: {
    borderRadius: "9999px",
    padding: 5,
    background: "rgba(196, 196, 196, 0.2)",
  },
  baseText: {
    color: "rgba(255,255,255,0.5)",
    fontSize: 18,
  },
  code: {
    flexGrow: 1,
    textAlign: "center",
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    color: "white",
    fontWeight: "bold",
    letterSpacing: "0.445em",
    fontSize: 18,
  },
  textMargin: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
  },
}));

interface KioskLoginWelcomeProps {
  userCode: string;
}

export default function KioskLoginWelcome({
  userCode,
}: KioskLoginWelcomeProps) {
  const { classes } = useStyles();

  return (
    <div className="w-full h-full flex-center flex-col">
      <Spotcast
        style={{ width: "clamp(400px, 30vw, 1000px)", minHeight: 100 }}
      />
      <Box m={2} mt={5}>
        <div
          className={clsx(classes.stepContainer, "w-full flex items-center")}
        >
          <Avatar className={classes.avatar}>1</Avatar>
          <Typography
            variant="body1"
            className={clsx(classes.baseText, classes.textMargin)}
          >
            Go to:{" "}
            <a
              className="text-white font-bold"
              href={`${window.location.origin}/spotcast`}
            >
              {window.location.origin}/spotcast
            </a>
          </Typography>
        </div>
        <div className="my-2" />
        <div
          className={clsx(classes.stepContainer, "w-full flex items-center")}
        >
          <Avatar className={classes.avatar}>2</Avatar>
          <Typography
            variant="body1"
            className={clsx(classes.baseText, classes.textMargin)}
          >
            Enter code:
          </Typography>
          <code className={classes.code}>{userCode}</code>
        </div>
      </Box>
    </div>
  );
}
