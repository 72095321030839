import { Divider } from "@mui/material";
import { useLocation } from "react-router-dom";

import IntegrationsCard from "@/pages/IntegrationsOld/Core/IntegrationsCard";
import IntegrationsNavigationHeader from "@/pages/IntegrationsOld/Core/IntegrationsNavigationHeader";

import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";

import {
  useCurrentIntegration,
  useCurrentIntegrationId,
} from "../hooks/integrationHooks";
import { IntegrationEditDetailsForm } from "./IntegrationEditDetailsForm";
import { IntegrationEditResourcesForm } from "./IntegrationEditResourcesForm";
import IntegrationsDeleteButton from "./IntegrationsDeleteButton";

export function IntegrationEditPage() {
  const { pathname } = useLocation();
  const id = useCurrentIntegrationId();
  const backPath = pathname.split("/").slice(0, -1).join("/");

  const { integration, error, loading } = useCurrentIntegration();

  if (error) {
    return <ErrorMessage title="Error" description={error.toString()} />;
  }

  if (!loading && !integration) {
    return (
      <ErrorMessage title="Not Found" description=" Integration not found." />
    );
  }

  return (
    <IntegrationsCard>
      <div className="flex items-center justify-between -mt-5 mb-3 sm:py-2">
        <IntegrationsNavigationHeader
          title="Integration Details"
          to={backPath}
        />
        <IntegrationsDeleteButton id={id} />
      </div>
      <div className="-mx-8">
        <div
          style={{
            background:
              "linear-gradient(180deg, rgba(0, 0, 0, 0.373989) 0%, rgba(255, 255, 255, 0.0001) 98.13%)",
          }}
          className="opacity-40 w-full h-2"
        />
        {loading && <Loading grow>Fetching Integration</Loading>}
        {!loading && integration && (
          <div className="p-8 flex flex-col gap-5">
            <IntegrationEditDetailsForm />
            <Divider orientation="horizontal" />
            <IntegrationEditResourcesForm />
          </div>
        )}
      </div>
    </IntegrationsCard>
  );
}
