import { atom, useAtomValue } from "jotai";
import { atomFamily } from "jotai/utils";
import { Participant } from "livekit-client";

export const livekitVoipParticipantState = atomFamily((url: string) => {
  const voipAtom = atom(null as Participant | null);
  voipAtom.debugLabel = `voipParticipantAtom:${url}`;
  return voipAtom;
});

export function useLivekitVoipParticipant(url: string) {
  return useAtomValue(livekitVoipParticipantState(url));
}
