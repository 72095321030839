import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { useState } from "react";

import {
  LPRSetupCameraAngle,
  LPRSetupCameraDistance,
} from "@/icons/LPRSetupIllustrations";

interface LPRSetupHelpModalProps {
  onCancel: () => void;
  onConfirm: () => void;
  open: boolean;
  loading?: boolean;
}

function LPRSetupStepper({
  loading,
  onConfirm,
}: {
  loading?: boolean;
  onConfirm: () => void;
}) {
  const [step, setStep] = useState(0);

  const steps = [
    {
      label: "Camera Resolution & Distance",
      children: (
        <div>
          <Typography className="text-sm leading-4 mb-2">
            Your camera must have <strong>a minimum resolution of 1080p</strong>{" "}
            for License Plate Recognition to work.
            <br />
            <br />
            Position the camera within the{" "}
            <strong>
              recommended distance of 15 feet away from vehicles.
            </strong>{" "}
            Place the camera as close as possible for optimal results.
          </Typography>
          <LPRSetupCameraDistance />
        </div>
      ),
    },
    {
      label: "Camera Angle",
      children: (
        <div>
          <Typography className="text-sm leading-4">
            Ensure that both the vertical and horizontal angles of the camera{" "}
            <strong>do not exceed 20 degrees</strong> for the best recognition
            results.
          </Typography>
          <br />
          <Typography className="text-sm leading-4 mb-2">
            For reference, at a distance of <strong>10 feet</strong> from the
            license plate, the camera should be no more than{" "}
            <strong>3 feet above, below, or to the right or left</strong> of the
            license plate to be within the 20 degree zone.
          </Typography>
          <LPRSetupCameraAngle />
        </div>
      ),
    },
    {
      label: "License Plate Visibility",
      children: (
        <Typography className="text-sm leading-4 mb-2">
          The maximum vehicle speed for license plates to be detected is{" "}
          <strong>5mph </strong>
          (parking lot speeds will work, road speeds will not).
          <br />
          <br />
          To test if your camera will be successful, look at license plates in
          your camera feed at 1080p in the Spot AI Dashboard. License plates
          <strong> must be</strong> visible and human readable for at least{" "}
          <strong>2 full seconds.</strong>
        </Typography>
      ),
    },
    {
      label: "Nighttime & Poor Weather",
      children: (
        <div>
          <Typography className="text-sm leading-4">
            For license plates to be detected at night or in poor weather, an
            infrared camera or external infrared device is required.
          </Typography>
          <Typography className="text-sm leading-4 font-bold mt-4 mb-1">
            Without Infrared
          </Typography>
          <img
            src="/lpr_no_infrared.png"
            alt="LPR No Infrared"
            className="h-[100pxl"
          />
          <Typography className="text-sm leading-4 font-bold mt-4 mb-1">
            With Infrared
          </Typography>
          <img
            src="/lpr_infrared.png"
            alt="LPR Infrared"
            className="h-[100pxl"
          />
        </div>
      ),
    },
    {
      label: "Disable Unnecessary Features",
      children: (
        <Typography className="text-sm leading-4 mb-2">
          Automatic gain control (AGC), digital noise reduction (DNR),
          autofocus, and back light compensation (BLC) all must be disabled for
          LPR to work.
        </Typography>
      ),
    },
  ];

  const lastStep = step === steps.length - 1;

  return (
    <Stepper className="px-0 pt-3" activeStep={step} orientation="vertical">
      {steps.map(({ label, children }, index) => (
        <Step key={index}>
          <StepLabel
            StepIconProps={{
              classes: {
                root: "text-[28px] ml-[-2px]",
              },
            }}
            classes={{
              label: "font-medium text-base leading-[18px] ml-4",
            }}
          >
            {label}
          </StepLabel>
          <StepContent className="max-w-[340px]">
            <div className="ml-4">{children}</div>
            {loading ? (
              <div className="flex justify-center items-center w-full mt-4 p-2">
                <CircularProgress size={20} />
              </div>
            ) : (
              <div className="flex items-center gap-2 mt-6 ml-4">
                <Button
                  disabled={step === 0}
                  variant="contained"
                  className="shadow-none bg-[#DAEEFF] text-[#007CE4] font-normal rounded-[6px] disabled:bg-[#EEEEEE] disabled:text-[#9C9C9C]"
                  onClick={() => {
                    setStep((step) => step - 1);
                  }}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className="font-normal rounded-[6px] shadow-none"
                  onClick={async () => {
                    if (lastStep) {
                      onConfirm();
                    } else {
                      setStep((step) => step + 1);
                    }
                  }}
                >
                  {lastStep ? "Complete" : "Confirm"}
                </Button>
              </div>
            )}
          </StepContent>
        </Step>
      ))}
    </Stepper>
  );
}

export function LPRSetupHelpModal({
  open,
  onCancel,
  onConfirm,
  loading,
}: LPRSetupHelpModalProps) {
  return (
    <Dialog
      classes={{
        paper: "rounded-lg max-w-[493px]",
      }}
      open={open}
      onClose={onCancel}
    >
      <DialogTitle className="flex justify-between items-center py-4 bg-[#F4F4F4]">
        <Typography variant="h3">Camera Set Up to Enable LPR</Typography>
        <Button
          onClick={onCancel}
          className="text-[#757575] text-base leading-5 font-normal"
        >
          Close
        </Button>
      </DialogTitle>
      <DialogContent className="p-6">
        <DialogContentText className="text-text text-sm leading-4">
          In order to enable License Plate Recognition, please follow the guided
          steps to verify that your camera set up meets all necessary
          requirements.{" "}
          <a
            className="text-primary"
            href="https://spotai.zendesk.com/hc/en-us/articles/10333832301837-Camera-Setup-for-License-Plate-Recognition"
            target="_blank"
            rel="noopener noreferrer"
          >
            More info and tips
          </a>
        </DialogContentText>
        <LPRSetupStepper loading={loading} onConfirm={onConfirm} />
      </DialogContent>
    </Dialog>
  );
}
