import { AppState, Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Divider,
  Hidden,
  Link,
  Link as MaterialLink,
  Typography,
} from "@mui/material";
import gql from "graphql-tag";
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useLocation, useMatch, useNavigate } from "react-router-dom";

import { iosLogout } from "@/util/iosMessages";

import {
  ErrorMessage,
  RefreshButtonErrorMessage,
} from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";

import { auth0Config } from "@/auth0";
import { apiHost } from "@/environment";
import {
  OrgType,
  Role,
  useMeAuthPollQuery,
  useMeQuery,
} from "@/generated-models";
import { HeaderBase } from "@/layout/Header";
import { Layout } from "@/layout/Layout";

import { isMobileApp } from "./Mobile/mobileEnvironment";
import { executeZendeskCmd } from "./Scripts/Zendesk";

function setUserTokenCookie(value: string, expires: Date) {
  // Set cookies for proxy requests (TODO: get rid of these as well)
  document.cookie = `userToken=${value};path=/cloudproxy;secure;samesite=strict;expires=${expires.toUTCString()}`;
  document.cookie = `userToken=${value};path=/socket;secure;samesite=strict;expires=${expires.toUTCString()}`;

  // Set httpOnly userToken cookie
  return fetch(`${apiHost}/userToken`, {
    method: "POST",
    credentials: "include",
    headers: { Authorization: `Bearer ${value}` },
  }).then(async (res) => {
    if (!res.ok) throw new Error(await res.text());
    return res;
  });
}

const UNAUTHENTICATED_ERR_CODE = "UNAUTHENTICATED";
const demoMode = process.env.REACT_APP_DEMO_LOGIN === "true";

export function AuthProvider({ children }: PropsWithChildren<{}>) {
  const navigate = useNavigate();
  const isDemo = demoMode || window.location.hostname.includes("demo.spot.ai");
  const onRedirectCallback = useCallback(
    (appState: AppState) => {
      if (appState.isDemo && appState.demoEmail) {
        localStorage.setItem("demoEmail", appState.demoEmail);
      } else {
        localStorage.removeItem("demoEmail");
      }

      // Use the router's navigate module to replace the url
      navigate(appState?.returnTo ?? "/", { replace: true });
    },
    [navigate]
  );

  return (
    <Auth0Provider
      domain={auth0Config.domain}
      clientId={auth0Config.dashboardCliendId}
      redirectUri={`${window.location.origin}/callback`}
      advancedOptions={{
        defaultScope: "openid email",
      }}
      onRedirectCallback={onRedirectCallback}
      demoLogin={isDemo}
    >
      <Auth0Wrapper>{children}</Auth0Wrapper>
    </Auth0Provider>
  );
}

function Auth0Wrapper({ children }: PropsWithChildren<{}>) {
  const { isLoading, error, user, getIdTokenClaims } = useAuth0();
  const navigate = useNavigate();
  const [idTokenLoading, setIdTokenLoading] = useState(true);

  // This useEffect is a bit of a hacky workaround, but we basically only want to
  // render the children here after we've made a "real" attempt at getting the idToken.
  // The initial render triggers a call to `getIdTokenClaims" which always returns
  // undefined. To work around this, we first wait for the auth0.isLoading to finish,
  // and only after that completes do we attempt to retrieve the idToken.
  useEffect(() => {
    if (isLoading) return;
    getIdTokenClaims()
      .then((token) => {
        if (token?.__raw) {
          return setUserTokenCookie(token.__raw, new Date(token!.exp! * 1000));
        }
      })
      .finally(() => setIdTokenLoading(false));
  }, [user, getIdTokenClaims, isLoading]);

  useEffect(() => {
    if (error) {
      if (error.message === "expired_user_password") {
        navigate("/password-expired");
      }

      console.error(error);
    }
  }, [error, navigate]);

  if (isLoading || idTokenLoading) {
    return <div>Authenticating...</div>;
  }

  return <>{children}</>;
}

gql`
  query me {
    me {
      id
      orgUserId
      profile {
        id
        email
        name
        phone
        flags {
          realTimeAlerts
          audioAlerts
          customerAlertsOnly
        }
      }
      # these profile fields are now deprecated, but used throughout dashboard
      # will take some time to migrate to the new profile fields
      email
      phone
      name

      role
      rolev2 {
        id
        name
      }
      termsAccepted
      termsPeopleSearchAccepted
      intercomHash
      revokeAccessAt
      canSwitchOrganization
      organization {
        id
        name
        slug
        cloudSyncEnabled
        audioControlEnabled
        geniusEnabled
        allowSupport
        promptListId
        industry
        logo
        type
        flags {
          webRTC
          ptz
          liveWebRTC
          additionalAiFeature
          aiAttributeSearch
          aiFaceRecognition
        }

        permissions {
          video_live_access
          video_vod_access
          video_share
          users_access
          users_manage
          devices_access
          devices_manage
          cases_access
          walls_access
          integrations_access
          integrations_manage
          audio_access
          audio_manage
          intelligence_access
          alerts_access
          organization_api
          organization_audit_log
          organization_compliance
          organization_settings
          people_search_access
          people_assignment_manage
          people_search_manage
          attribute_search_manage
        }
      }
      flags {
        salesContact {
          email
          phone
        }
        showAllCases
        newAuditLogs
      }
    }
  }
`;

gql`
  query meAuthPoll {
    me {
      id
      orgUserId

      profile {
        id
        name
        email
      }

      organization {
        id
        name
        slug
      }
    }
  }
`;

export function useLogout() {
  const { logout: auth0Logout } = useAuth0();

  return useCallback(async () => {
    // Remove userToken and appUserToken cookie
    await fetch(`${apiHost}/logout`, { credentials: "include" }).then(
      async (res) => {
        if (!res.ok) console.error("Logout failed:", await res.text());
        return res;
      }
    );

    if (isMobileApp) {
      // Android app
      try {
        const android = (window as any).Android;
        if (android) android.logout();
      } catch (err) {
        console.error("The Android native logout handler failed");
      }
      // iOS app
      try {
        iosLogout();
      } catch (err) {
        console.error("The iOS native logout handler failed");
      }
    } else {
      return auth0Logout({ returnTo: window.location.origin });
    }
  }, [auth0Logout]);
}

export function useMe() {
  const { data } = useMeQuery();
  return data?.me;
}

export function isDemoUser(user: {
  role: Role;
  organization: { type: OrgType };
}) {
  return user.organization.type === OrgType.Demo && user.role < Role.Spot;
}

export function AuthProtected({ children }: { children: React.ReactNode }) {
  const { user, logout } = useAuth0();
  const auth0User = user as { email: string } | undefined;

  const { loading, data, error, stopPolling } = useMeAuthPollQuery({
    skip: !auth0User,
    pollInterval: 60000,
  });

  // Stop polling me query if there is an error
  useEffect(() => {
    if (error) stopPolling();
  }, [error, stopPolling]);

  useEffect(() => {
    // Prefill Zendesk web widget fields
    const zendesk = window.zE;
    if (data?.me && zendesk?.identify) {
      zendesk.identify({
        name: data.me.profile.name,
        email: data.me.profile.email,
        organization: data.me.organization.name,
      });

      // Reset the Zendesk form after closing it.
      executeZendeskCmd("webWidget:on", "close", function () {
        executeZendeskCmd("webWidget", "reset");
      });
    }
  }, [data?.me]);

  const auth0Logout = () => logout({ returnTo: window.location.origin });

  const match = useMatch("/o/:orgSlug/*");
  // Display 404 error if org slug does not match the user organization
  // This probably means the user does not have access to this organization
  if (match && data && match.params.orgSlug !== data.me.organization.slug) {
    return (
      <Layout
        header={
          <NoOrgHeader email={data.me.profile.email} logout={auth0Logout} />
        }
      >
        <ErrorMessage
          title="404"
          description={
            <span>
              The requested page could not be found. Take me back to the{" "}
              <Link href="/" underline="hover">
                dashboard
              </Link>
              .
            </span>
          }
        />
      </Layout>
    );
  }

  if (error) {
    const graphqlError = error.graphQLErrors[0]?.extensions.code as string;
    if (graphqlError === UNAUTHENTICATED_ERR_CODE) {
      return (
        <Layout
          header={<NoOrgHeader email={auth0User?.email} logout={auth0Logout} />}
        >
          <NoAuthError />
        </Layout>
      );
    }

    return (
      <Layout
        header={<NoOrgHeader email={auth0User?.email} logout={auth0Logout} />}
      >
        <NoOrgError error={graphqlError} logout={auth0Logout} />
      </Layout>
    );
  }

  if (loading) return null;

  if (!auth0User) return <Login />;

  if (data?.me) return <>{children}</>;

  return <Login />;
}

export function MobileAppAuthProtected({
  children,
}: {
  children: React.ReactNode;
}) {
  const logout = useLogout();
  const { data, error } = useMeQuery({
    pollInterval: 60000,
  });

  if (data?.me) return <>{children}</>;

  if (error) {
    const graphqlError = error.graphQLErrors[0]?.extensions.code as string;
    if (graphqlError === UNAUTHENTICATED_ERR_CODE) {
      return (
        <Layout>
          <NoAuthError logout={logout} />
        </Layout>
      );
    }

    return (
      <Layout header={<NoOrgHeader logout={logout} />}>
        <NoOrgError error={graphqlError} logout={logout} />
      </Layout>
    );
  }

  return <Loading />;
}

// Show custom message if user is not yet connected to an organization
// NOTE: this might not be the best way to identify this error
function NoOrgError({ error, logout }: { error: string; logout: () => void }) {
  return error === "UNAUTHORIZED" ? (
    <ErrorMessage
      title="Whoops."
      description={
        <>
          <Typography>
            You are not attached to an organization yet. If you believe you
            encountered this page in error, please contact your organization
            administrator.
          </Typography>
          <div className="m-4" />
          <Button variant="contained" color="primary" onClick={logout}>
            Log out
          </Button>
        </>
      }
    />
  ) : (
    <RefreshButtonErrorMessage
      title={"Something went wrong"}
      description={"Please refresh the page to try again."}
    />
  );
}

function NoAuthError({ logout }: { logout?: () => void }) {
  const [login, setLogin] = useState(false);

  // Render login component so user can login again and retain the current URL
  if (login) return <Login />;

  return (
    <ErrorMessage
      title="Whoops."
      description={
        <>
          <Typography>
            You are no longer authenticated with the application. If you believe
            you encountered this page in error, please contact your organization
            administrator.
          </Typography>
          <div className="m-4" />
          <Button
            variant="contained"
            color="primary"
            onClick={() => logout ?? setLogin(true)}
          >
            Login
          </Button>
        </>
      }
    />
  );
}

function Login() {
  const location = useLocation();
  const searchParams = new URLSearchParams(window.location.search);
  const { loginWithRedirect } = useAuth0();
  const isDemo = demoMode || window.location.hostname.includes("demo.spot.ai");
  const demoEmail = searchParams.get("demoEmail");

  useEffect(() => {
    fetch(`${apiHost}/health`)
      .then((response) => response.json())
      .then((jsonResponse) => {
        if (!jsonResponse.notice) {
          // When using the Okta app, the browser is sent to /callback. We don't actually want to send the browser to
          // that page after login since it does not exist. Just send it to the homepage instead.
          const rt =
            location.pathname === "/callback"
              ? "/"
              : location.pathname + location.search;
          loginWithRedirect({
            appState: {
              returnTo: rt,
              demoEmail,
              isDemo,
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [
    loginWithRedirect,
    location.pathname,
    location.search,
    isDemo,
    demoEmail,
  ]);
  return (
    <div className="mt-20">
      <Loading>Redirecting to login page...</Loading>
    </div>
  );
}

function NoOrgHeader({
  email,
  logout,
}: {
  email?: string;
  logout: () => void;
}) {
  return (
    <HeaderBase>
      <div className="flex items-center gap-1 ml-auto mr-2">
        <Hidden mdDown>
          <Button
            component={MaterialLink}
            href="https://spot.ai"
            target="_blank"
            className="font-bold text-xs"
          >
            Learn More
          </Button>
          <Divider orientation="vertical" className="h-3" />
        </Hidden>
        {email && (
          <>
            <Typography className="text-xs mx-2">{email}</Typography>
            <Divider orientation="vertical" className="h-3" />
          </>
        )}
        <Button onClick={logout} className="font-normal text-xs">
          Log Out
        </Button>
      </div>
    </HeaderBase>
  );
}
