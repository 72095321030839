import SnapshotIcon from "@mui/icons-material/AddAPhoto";
import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton, Popover, Typography } from "@mui/material";
import gql from "graphql-tag";
import { useAtomCallback } from "jotai/utils";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { formatIsoDateWithTimezone } from "@/util/date";

import { downloadSupport } from "@/components/Downloads";
import {
  usePlayerControls,
  useZoomState,
  wallclockTimeState,
  ZoomState,
} from "@/components/Player/PlayerBase";
import { PlayerTooltip } from "@/components/Player/PlayerTooltip";
import { ShareTrayItem } from "@/components/Player/ShareTrayItem";
import { useClipCompleted } from "@/components/Player/playerMachine";

import {
  useAddScreenshotToCaseMutation,
  useAuditSnapshotTakenMutation,
} from "@/generated-models";

import { isDemoUser, useMe } from "../Auth";
import { mobileEnvironment } from "../Mobile/mobileEnvironment";
import { FeedbackType, useFeedback } from "../SnackbarProvider";
import { AddToCaseDialog, CaseContentType } from "./AddToCaseDialog";
import { HighestQualityOverlay } from "./HighestQualityOverlay";

// From testing we've found that iPhone8 has problems with screenshots larger that 1000px wide.
// It seems to be a memory limitation. The symptom is that the saved screenshot is an all-black
// image. It's the right dimensions, but all black.
// Newer iPhones don't seem to have this limitation.
let deviceSupportsSnapshot = true;
if (mobileEnvironment?.platform === "IOS") {
  // Disable screenshot button for iPhone 8 and older
  const modelParser = /iPhone(\d+),(\d+)/;
  const match = modelParser.exec(mobileEnvironment.model);
  if (match) {
    const [major, minor] = match.slice(1).map(Number);
    deviceSupportsSnapshot = (major === 10 && minor >= 6) || major > 10;
  }
}

interface SnapshotButtonProps {
  playerId?: string;
  disabled?: boolean;
  cameraId?: number;
  cameraName: string;
  timezone: string;
  trayItem?: boolean;
  size?: "small" | "medium";
  className?: string;
}

export function SnapshotButton({
  playerId,
  disabled: disabled_,
  cameraId,
  cameraName,
  timezone,
  trayItem = false,
  size,
  className,
}: SnapshotButtonProps) {
  const me = useMe();
  const isDemo = !!me && isDemoUser(me); // only relevant for demo orgs
  const [qualityLoading, setQualityLoading] = useState(false);
  const zoomState = useZoomState(playerId);
  const isDefaultZoomState = !zoomState || zoomState.zoomLevel === 1;
  const clipCompleted = useClipCompleted();
  const getWallclockTime = useAtomCallback(
    useCallback(
      (get) => {
        return get(wallclockTimeState(playerId));
      },
      [playerId]
    )
  );
  const { getPlayerElement } = usePlayerControls(playerId);

  const [file, setFile] = useState<{ file: File; timestamp: string } | null>(
    null
  );
  const closeCasePopover = () => setFile(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [audit] = useAuditSnapshotTakenMutation();

  if (!downloadSupport || !deviceSupportsSnapshot) return null;

  const disabled = disabled_ || clipCompleted;

  const onClick = async () => {
    const playerElement = getPlayerElement();
    if (!playerElement) return;
    const wallclockTime = await getWallclockTime();
    const timestamp = wallclockTime ?? new Date();
    const filename = `${cameraName} - ${formatIsoDateWithTimezone(
      timestamp,
      timezone
    )}.jpg`;
    const dataURL = captureFrame(playerElement, filename, zoomState);

    if (cameraId) {
      const blob = await fetch(dataURL).then((res) => res.blob());
      setFile({
        file: new File([blob], filename, { type: "image/jpeg" }),
        timestamp: timestamp.toISOString(),
      });

      audit({
        variables: { cameraId, timestamp: timestamp.toISOString() },
      }).catch(console.error);
    }
  };

  return (
    <>
      {qualityLoading && (
        <HighestQualityOverlay
          playerId={playerId}
          onFinish={() => {
            onClick();
            setQualityLoading(false);
          }}
        />
      )}
      {trayItem ? (
        <ShareTrayItem
          onClick={(e) => {
            e.stopPropagation();
            setAnchorEl(e.currentTarget);
            if (isDefaultZoomState && mobileEnvironment?.platform !== "IOS") {
              setQualityLoading(true);
            } else {
              onClick();
            }
          }}
          aria-label="create snapshot"
          disabled={disabled}
          icon={<SnapshotIcon />}
          label="Create Snapshot"
        />
      ) : (
        <PlayerTooltip title="Create snapshot">
          <div
            className={
              className ?? "md:bg-black rounded-md md:hover:bg-gray-900"
            }
          >
            {/**
             * Wrapper div is required to ensure the Tooltip works even
             * when the button is disabled, as disabled buttons don't emit events
             **/}
            <IconButton
              size={size}
              disabled={disabled}
              className="flex-center text-white"
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                if (
                  isDefaultZoomState &&
                  mobileEnvironment?.platform !== "IOS"
                ) {
                  setQualityLoading(true);
                } else {
                  onClick();
                }
              }}
            >
              <SnapshotIcon
                color="inherit"
                className={disabled ? "opacity-50" : undefined}
              />
            </IconButton>
          </div>
        </PlayerTooltip>
      )}
      {cameraId && anchorEl && file && !isDemo && (
        <AddToCasePopover
          anchorEl={anchorEl}
          cameraId={cameraId}
          {...file}
          onClose={closeCasePopover}
        />
      )}
    </>
  );
}

function AddToCasePopover({
  anchorEl,
  cameraId,
  timestamp,
  file,
  onClose,
}: {
  anchorEl: HTMLButtonElement;
  cameraId: number;
  file: File;
  timestamp: string;
  onClose: () => void;
}) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const navigate = useNavigate();
  const { pushSnackbar } = useFeedback();
  const [addScreenshotToCase] = useAddScreenshotToCaseMutation({
    onError: () =>
      pushSnackbar(
        "We were unable to save this screenshot. Please try again later.",
        FeedbackType.Error
      ),
  });

  return (
    <>
      <Popover
        open={!dialogOpen}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        classes={{
          paper: "bg-black mt-0.5 py-3 pl-6 pr-8 text-center",
        }}
      >
        <IconButton
          size="small"
          className="absolute top-0 right-0 text-[#bdbdbd]"
          onClick={onClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
        <Typography className="text-white font-medium leading-none mb-2">
          Screenshot downloaded!
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => setDialogOpen(true)}
        >
          Add Screenshot to Case
        </Button>
      </Popover>

      <AddToCaseDialog
        type={CaseContentType.screenshot}
        open={dialogOpen}
        setOpen={setDialogOpen}
        onSave={async (caseId: number, redirectToCase: boolean) => {
          await addScreenshotToCase({
            variables: { caseId, value: { cameraId, timestamp, file } },
            onCompleted: (data) => {
              if (redirectToCase && data.addScreenshotToCase) {
                navigate(
                  `/cases/${caseId}?screenshot=${data.addScreenshotToCase.id}`
                );
              } else {
                onClose();
                pushSnackbar(
                  "Successfully saved screenshot to case",
                  FeedbackType.Success
                );
              }
            },
          });
        }}
      />
    </>
  );
}

export function captureFrame(
  video: HTMLVideoElement,
  filename: string,
  zoomState: ZoomState | null,
  customDimension?: { width: number; height: number },
  skipDownload?: boolean
) {
  const canvas = document.createElement("canvas");
  if (!zoomState || zoomState.zoomLevel === 1) {
    // Normal snapshot when not zooming
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    if (customDimension) {
      canvas.width = customDimension.width;
      canvas.height = customDimension.height;
      canvas
        .getContext("2d")!
        .drawImage(video, 0, 0, customDimension.width, customDimension.height);
    } else {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas.getContext("2d")!.drawImage(video, 0, 0);
    }
  } else {
    // If digital zoom is active, snapshot the visible part
    const ratio = video.videoHeight / video.offsetHeight;
    // canvas aspect ratio needs to be the same as the video player
    canvas.width = Math.round(video.offsetWidth * ratio);
    canvas.height = video.videoHeight;
    const { zoomLevel, xPos, yPos } = zoomState;
    const zoomWidth = video.videoWidth * zoomLevel;
    const zoomHeight = video.videoHeight * zoomLevel;
    // Width padding is needed to draw the black bars on the side of the player
    const widthDiff = canvas.width - video.videoWidth;
    const zoomWidthDiff = widthDiff * zoomLevel;
    // Please don't ask me how this math works exactly xD
    // It basically calculates where to position the video inside the canvas, considering the widthDiff.
    // If (xPos === 0) the padding should be (zoomWidthDiff / 2)
    // If (xPos === 1) the padding should be -(zoomWidthDiff / 2) + widthDiff
    const widthPadding = Math.round(
      (1 - xPos) * zoomWidthDiff - zoomWidthDiff / 2 + xPos * widthDiff
    );
    const divider = zoomLevel / (zoomLevel - 1);
    canvas
      .getContext("2d")!
      .drawImage(
        video,
        (zoomWidth * xPos) / -divider + widthPadding,
        (zoomHeight * yPos) / -divider,
        zoomWidth,
        zoomHeight
      );
  }

  try {
    var dataURL = canvas.toDataURL("image/jpeg");
  } catch (error: any) {
    console.log("canvas.toDataURL error", error);
    console.error(error);
    throw error;
  }

  if (!skipDownload) {
    const link = document.createElement("a");
    link.download = filename;
    link.href = dataURL;
    link.target = "_blank";
    link.click();
  }

  return dataURL;
}

gql`
  mutation addScreenshotToCase($caseId: Int!, $value: CaseScreenshotInput!) {
    addScreenshotToCase(caseId: $caseId, value: $value) {
      id
    }
  }
`;

gql`
  mutation auditSnapshotTaken($cameraId: Int!, $timestamp: String!) {
    auditSnapshotTaken(cameraId: $cameraId, timestamp: $timestamp)
  }
`;
