import React, { useState } from "react";

interface AddedCameraProps {
  angle: number;
  online: boolean;
  showStatus?: boolean;
  onMainCamHovered?: (e: HTMLElement) => void;
  onMainCamLeft?: () => void;
  onMainCamClicked: () => void;
}
export function AddedCameraIcon({
  angle,
  online,
  showStatus = false,
  onMainCamHovered,
  onMainCamLeft,
  onMainCamClicked,
}: AddedCameraProps) {
  const color = online ? "#42FF00" : "#FF3526";
  const [parentNode, setParentNode] = useState<HTMLElement | null>(null);
  return (
    <svg
      width="144"
      height="144"
      viewBox="0 0 144 144"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        position: "absolute",
      }}
      ref={(node) => {
        if (!node) return;
        setParentNode(node.parentElement);
      }}
    >
      <g
        onMouseEnter={
          parentNode && onMainCamHovered
            ? () => onMainCamHovered(parentNode)
            : undefined
        }
        onMouseLeave={onMainCamLeft ? () => onMainCamLeft() : undefined}
      >
        <path
          transform={`rotate(${angle} 72 72)`}
          opacity="0.45"
          d="M116 20.1894L72 72L28 20.1894C28 20.1894 50.7216 1 70.7957 1C96.2116 1 112.509 16.6091 116 20.1894Z"
          fill="url(#paint0_radial)"
        />
        <g onClick={onMainCamClicked} style={{ zIndex: 1 }}>
          <g filter={`url(#filter0_d1_${online})`}>
            <circle cx="72" cy="72" r="19" fill="white" />
            <circle cx="72" cy="72" r="19" stroke="#DBDBDB" />
          </g>
          <g filter={`url(#filter1_d1_${online})`}>
            <circle cx="72" cy="72" r="16" fill="#007CE4" />
            {showStatus && <circle cx="72" cy="72" r="16" stroke={color} />}
          </g>
          {showStatus && (
            <g filter={`url(#filter2_d1_${online})`}>
              <circle cx="67" cy="72" r="2" fill={color} />
            </g>
          )}
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M65.1667 68.6667H73.0833C73.9578 68.6667 74.6667 69.4129 74.6667 70.3333V73.6667C74.6667 74.5871 73.9578 75.3333 73.0833 75.3333H65.1667C64.2922 75.3333 63.5833 74.5871 63.5833 73.6667V70.3333C63.5833 69.4129 64.2922 68.6667 65.1667 68.6667ZM62 70.3333C62 68.4924 63.4178 67 65.1667 67H73.0833C74.5147 67 75.7242 67.9996 76.1163 69.3718H76.131L80.7673 67.8702C81.0074 67.7925 81.2683 67.8393 81.4704 67.9964C81.6725 68.1535 81.7917 68.4023 81.7917 68.6667V75.3334C81.7917 75.5978 81.6725 75.8466 81.4704 76.0037C81.2683 76.1608 81.0074 76.2076 80.7673 76.1299L76.131 74.6283H76.1162C75.7242 76.0004 74.5146 77 73.0833 77H65.1667C63.4178 77 62 75.5076 62 73.6667V70.3333ZM76.25 72.9616H76.2501C76.329 72.9616 76.4075 72.974 76.4829 72.9984L80.2084 74.2051V69.795L76.4829 71.0017C76.4075 71.0261 76.329 71.0385 76.2501 71.0385H76.25V72.9616Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id={`filter0_d1_${online}`}
          x="48.5"
          y="52.5"
          width="47"
          height="47"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <filter
          id={`filter1_d1_${online}`}
          x="52.5"
          y="52.5"
          width="39"
          height="39"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          {showStatus && (
            <>
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="1.5" />
              {online ? (
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.258824 0 0 0 0 1 0 0 0 0 0 0 0 0 0.7 0"
                />
              ) : (
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 1 0 0 0 0 0.207843 0 0 0 0 0.14902 0 0 0 0.7 0"
                />
              )}
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow"
              />
            </>
          )}
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <filter
          id={`filter2_d1_${online}`}
          x="63"
          y="68"
          width="8"
          height="8"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1" />
          {online ? (
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.258824 0 0 0 0 1 0 0 0 0 0 0 0 0 1 0"
            />
          ) : (
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 1 0 0 0 0 0.207843 0 0 0 0 0.14902 0 0 0 1 0"
            />
          )}
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <radialGradient
          id="paint0_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(72.2635 72.5298) rotate(-90.211) scale(71.5303 88.6573)"
        >
          <stop offset="0.25" stopColor="#0057FF" />
          <stop offset="0.90625" stopColor="#007CE4" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
}
