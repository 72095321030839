import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Button, MenuItem, Select, Tab, Tabs } from "@mui/material";
import { useField } from "formik";
import { capitalize } from "lodash/fp";
import { useEffect } from "react";
import { ArrayParam, useQueryParam } from "use-query-params";

import { useBreakpoints } from "@/util/useBreakpoints";

import { IntelligenceDashboardType } from "@/generated-models";

import { IntelligenceFeatureType } from "../../constants";
import { getObjectLabel } from "../../utils";

type ValidTabTypes =
  | IntelligenceDashboardType.Idle
  | IntelligenceDashboardType.Count
  | IntelligenceDashboardType.Presence;

const filterMap: Record<
  string,
  Record<ValidTabTypes, IntelligenceFeatureType>
> = {
  people: {
    [IntelligenceDashboardType.Idle]: IntelligenceFeatureType.PeopleIdleTime,
    [IntelligenceDashboardType.Count]: IntelligenceFeatureType.PeopleCounting,
    [IntelligenceDashboardType.Presence]:
      IntelligenceFeatureType.PeoplePresence,
  },
  default: {
    [IntelligenceDashboardType.Idle]: IntelligenceFeatureType.VehicleIdleTime,
    [IntelligenceDashboardType.Count]: IntelligenceFeatureType.VehicleCounting,
    [IntelligenceDashboardType.Presence]:
      IntelligenceFeatureType.VehiclePresence,
  },
  vehicle: {
    [IntelligenceDashboardType.Idle]: IntelligenceFeatureType.VehicleIdleTime,
    [IntelligenceDashboardType.Count]: IntelligenceFeatureType.VehicleCounting,
    [IntelligenceDashboardType.Presence]:
      IntelligenceFeatureType.VehiclePresence,
  },
  forklift: {
    [IntelligenceDashboardType.Idle]: IntelligenceFeatureType.ForkliftIdleTime,
    [IntelligenceDashboardType.Count]: IntelligenceFeatureType.ForkliftCounting,
    [IntelligenceDashboardType.Presence]:
      IntelligenceFeatureType.ForkliftPresence,
  },
};

export function ComparativeToolbar() {
  const { fitsTablet } = useBreakpoints();

  const [, { value }, { setValue }] = useField<number[]>({
    name: "referenceDashIds",
  });

  const [, { value: objectTypes }] = useField<string>({
    name: "objectTypes",
  });

  const [, { value: subtype }, { setValue: setSubtype }] = useField<
    ValidTabTypes
  >({
    name: "subtype",
  });

  const handleChange = (_: React.SyntheticEvent, newValue: ValidTabTypes) => {
    setSubtype(newValue);
  };

  const [, setFilters] = useQueryParam("metrics", ArrayParam);

  const tabClassName = "text-lg leading-[21px] px-0 pb-1";

  const tabDisabled = value?.length > 0;

  const label = capitalize(
    getObjectLabel(objectTypes, IntelligenceDashboardType.Compound)
  );

  useEffect(() => {
    setFilters([filterMap[objectTypes][subtype]]);
  }, [objectTypes, setFilters, subtype]);

  const items = [
    {
      label: `${label} Idle Time`,
      disabled: tabDisabled && subtype !== IntelligenceDashboardType.Idle,
      value: IntelligenceDashboardType.Idle,
    },
    {
      label: `${label} Count`,
      disabled: tabDisabled && subtype !== IntelligenceDashboardType.Count,
      value: IntelligenceDashboardType.Count,
    },
    {
      label: `${label} Presence`,
      disabled: tabDisabled && subtype !== IntelligenceDashboardType.Presence,
      value: IntelligenceDashboardType.Presence,
    },
  ];

  if (!fitsTablet) {
    return (
      <div>
        <div className="w-full">
          <label
            className="block font-medium mb-2 text-[#666666]"
            htmlFor="Graph Data"
          >
            Insight Type
          </label>
          <Select
            value={subtype}
            onChange={(event) => {
              setSubtype(event.target.value as ValidTabTypes);
            }}
            variant="outlined"
            className="w-full"
            inputProps={{
              className:
                "px-4 py-3 bg-white text-lg leading-5 font-bold border border-[#AAAAAA] rounded-lg h-auto min-h-0",
            }}
            IconComponent={KeyboardArrowDownIcon}
          >
            {items.map((i) => (
              <MenuItem key={i.value} disabled={i.disabled} value={i.value}>
                {i.label}
              </MenuItem>
            ))}
          </Select>
        </div>
        <Button
          disabled={!value || value.length === 0}
          onClick={() => {
            setValue([], true);
          }}
          className="font-normal absolute mt-[26px] left-full -ml-[260px]"
          color="primary"
        >
          Clear Selections
        </Button>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-between">
      <Tabs
        value={subtype}
        onChange={handleChange}
        aria-label="basic tabs example"
        classes={{ flexContainer: "gap-4" }}
      >
        {items.map((i) => (
          <Tab key={i.value} className={tabClassName} {...i} />
        ))}
      </Tabs>
      <Button
        disabled={!value || value.length === 0}
        onClick={() => {
          setValue([], true);
        }}
        className="font-normal mt-2"
        color="primary"
      >
        Clear Selections
      </Button>
    </div>
  );
}
