import { AddCircle } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useController } from "react-hook-form";
import { useHotkeys } from "react-hotkeys-hook";

import { RoleMemberSelect } from "./RoleMemberSelect";

export function ManageRoleMembersButton({
  open,
  close,
  opened,
  className,
}: {
  open: () => void;
  close: () => void;
  opened: boolean;
  className?: string;
}) {
  const {
    field: { value: initialMemberIds, onChange: updateMemberIds, onBlur },
  } = useController({
    name: "memberIds",
  });

  const [memberIds, setMemberIds] = useState(initialMemberIds);

  useEffect(() => {
    setMemberIds(initialMemberIds);
  }, [initialMemberIds]);

  function onClose() {
    setMemberIds(initialMemberIds);
    close();
  }

  async function apply() {
    updateMemberIds(memberIds);
    close();
    onBlur();
  }

  useHotkeys(
    "ctrl+enter",
    () => {
      apply();
    },
    undefined,
    [memberIds, updateMemberIds]
  );

  return (
    <>
      <Button
        color="primary"
        className={clsx("text-md", className)}
        onClick={open}
        startIcon={<AddCircle />}
      >
        Add Member
      </Button>
      <Dialog open={opened} onClose={onClose}>
        <DialogTitle className="flex justify-between items-center py-6 bg-[#F4F4F4] border-b border-solid border-[#D5D5D5]">
          <Typography variant="h3">Add Members</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="h4" className="text-base my-4">
            Add Role Member
          </Typography>
          <Typography className="mb-4">
            Adding a member to this role will change their current role. A
            member can only ever be assigned one role at a time.
          </Typography>
          <RoleMemberSelect
            initialMemberIds={memberIds}
            setMemberIds={setMemberIds}
          />
        </DialogContent>
        <DialogActions className="p-6 pt-0">
          <Button
            onClick={() => {
              onClose();
            }}
            variant="outlined"
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={apply} variant="contained" color="primary">
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
