import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DownloadIcon from "@mui/icons-material/GetApp";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";

export interface DownloadWidgetProps {
  label: string;
  description?: string;
  details?: Record<string, React.ReactNode>;
  cancel: () => void;
  /**
   * Progress percentage, or...
   *   - null if pending
   *   - undefined if no progress should be rendered
   */
  progress?: number | null;
}
export const DownloadWidget = React.memo(function DownloadWidget({
  label,
  description = "Preparing download...",
  details,
  cancel,
  progress,
}: DownloadWidgetProps) {
  const [open, setOpen] = useState(false);
  return (
    <Accordion
      classes={{
        root: "bg-[#2196f3] text-white rounded",
      }}
      expanded={open}
      onChange={() => setOpen(!open)}
      elevation={4}
    >
      <AccordionSummary
        classes={{
          root:
            "text-white !px-2.5 !py-0 !min-h-[48px] shadow-[0_0_40px_rgba(0,0,0,0.15)] bg-[#2196f3]",
          content: "my-3 mx-0",
        }}
        expandIcon={<ExpandMoreIcon className="text-white" />}
        aria-controls="location-panel-bh-content"
        id="location-panel-bh-header"
      >
        <DownloadIcon fontSize="small" />
        <CircularProgress
          size={26}
          className="text-inherit absolute top-[11px] left-[7px]"
          variant={progress != null ? "determinate" : "indeterminate"}
          value={progress ?? undefined}
        />
        <strong className="mr-6 ml-2.5">{label}</strong>
      </AccordionSummary>
      <AccordionDetails classes={{ root: "p-0 flex-col bg-[#0d6cb7]" }}>
        <div className="py-4 grid grid-cols-[auto_auto] gap-1 gap-y-0.5 px-3 text-[11px]">
          {details &&
            Object.entries(details).map(([key, value]) => (
              <React.Fragment key={key}>
                <DetailsLabel>{key}</DetailsLabel>
                <div>{value}</div>
              </React.Fragment>
            ))}
          {progress !== undefined && (
            <>
              <DetailsLabel>Progress</DetailsLabel>
              <strong>{progress !== null ? Math.floor(progress) : 0}%</strong>
            </>
          )}
        </div>
        <div className="flex items-center justify-between py-2 px-2.5 text-xs font-bold bg-[#2196f3]">
          <Button
            size="small"
            className="text-[11px] text-white border-white border-opacity-40 normal-case"
            onClick={cancel}
            variant="outlined"
          >
            Cancel
          </Button>
          {description}
        </div>
      </AccordionDetails>
    </Accordion>
  );
});

function DetailsLabel({ children }: React.PropsWithChildren<{}>) {
  return <div className="opacity-60 text-right">{children}</div>;
}
