import CameraIcon from "@mui/icons-material/VideocamOutlined";
import { Grid, Tooltip } from "@mui/material";

export function EmptyCell() {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ height: "100%", backgroundColor: "#1b1b1b", borderRadius: 2 }}
    >
      <Tooltip title="Empty slot">
        <CameraIcon
          fontSize="large"
          style={{ color: "rgba(255, 255, 255, .11)" }}
        />
      </Tooltip>
    </Grid>
  );
}
