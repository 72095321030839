import { Button } from "@mui/material";
import clsx from "clsx";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useState } from "react";

import { InterestListType } from "@/generated-models";

import { InterestListDialog } from "./InterestListDialog";
import { InterestListFormMode } from "./constants";

interface AddToInterestListButtonProps {
  className?: string;
  buttonClassName?: string;
  name?: string | null;
  type: InterestListType;
}

export function AddToInterestListButton({
  className,
  buttonClassName,
  name,
  type,
}: AddToInterestListButtonProps) {
  const { lprInterests } = useFlags();
  const [open, setOpen] = useState(false);
  if (!name || !lprInterests) return <></>;

  return (
    <div className={className}>
      <Button
        size="small"
        color="primary"
        className={clsx("text-base leading-[18.75px]", buttonClassName)}
        onClick={() => {
          setOpen(true);
        }}
      >
        + Add to List
      </Button>
      <InterestListDialog
        open={open}
        initialValue={name}
        defaultMode={InterestListFormMode.insert}
        primaryActionLabel={"Add to List"}
        type={type}
        onClose={() => {
          setOpen(false);
        }}
        onApplyLists={() => {
          setOpen(false);
        }}
      />
    </div>
  );
}
