import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Popover, Typography } from "@mui/material";
import gql from "graphql-tag";
import { useMemo } from "react";

import { GeniusLogo } from "@/components/Genius/GeniusLogo";
import { Loading } from "@/components/Loading";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  DiscoveryStatusQuery,
  useDiscoveryStatusQuery,
} from "@/generated-models";

export function DiscoveryStatusPopup({
  locationId,
  anchorEl,
  onClose,
}: {
  locationId: number;
  anchorEl: HTMLButtonElement | null;
  onClose: () => void;
}) {
  const { data, loading } = useDiscoveryStatusQuery({
    variables: { locationId: locationId },
    ...refetchOnMountPolicy,
  });

  // Sort appliances so that we show appliances with any status information first.
  const sortedAppliances = useMemo(() => {
    return (
      data?.location?.appliances?.slice()?.sort((a, b) => {
        return (
          b?.interfaces?.filter((int) => !!int.discoveryStatus)?.length -
          a?.interfaces?.filter((int) => !!int.discoveryStatus)?.length
        );
      }) || []
    );
  }, [data]);

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open
      onClose={onClose}
    >
      <div className="p-4 min-w-[500px] max-w-[775px] text-sm">
        <div>
          <div className="flex items-center">
            <GeniusLogo className="mr-2 text-sm h-5" />
            <Typography variant="h1" component="h1" className="text-lg">
              Device Discovery
            </Typography>
          </div>
          {loading ? (
            <div className="py-10">
              <Loading />
            </div>
          ) : (
            <div className="grid grid-cols-2 gap-4 mt-4">
              {sortedAppliances.map((appliance) => (
                <ApplianceStatus {...appliance} key={appliance.serialNumber} />
              ))}
            </div>
          )}
        </div>
        <IconButton
          size="small"
          onClick={() => onClose()}
          className="absolute top-0 right-0 p-2"
        >
          <CloseIcon className="text-m" />
        </IconButton>
      </div>
    </Popover>
  );
}

type applianceQuery = NonNullable<
  DiscoveryStatusQuery["location"]
>["appliances"][number];

const ApplianceStatus = (appliance: applianceQuery) => {
  return (
    <div className="p-4 bg-gray-50 rounded-lg border border-gray-300 border-solid">
      <Typography variant="body2" component="h2" className="font-bold">
        {appliance.serialNumber}
      </Typography>
      <ul className="list-disc ml-5">
        {appliance.interfaces.map((intf) => {
          return intf.discoveryStatus ? (
            <InterfaceStatus {...intf} key={intf.name} />
          ) : null;
        })}
      </ul>
    </div>
  );
};

type interfaceQuery = NonNullable<
  DiscoveryStatusQuery["location"]
>["appliances"][number]["interfaces"][number];

const InterfaceStatus = ({ name, discoveryStatus, mac }: interfaceQuery) => {
  return (
    <li>
      <div className="flex gap-x-1 items-center">
        <Typography variant="body2" component="h3" className="font-bold">
          {name}
        </Typography>
        {mac && (
          <Typography className="text-sm font-light">{`(${mac})`}</Typography>
        )}
      </div>
      <ul className="list-disc ml-5">
        {discoveryStatus?.subnets.map((subnet) => (
          <SubnetStatus {...subnet} key={subnet.cidr} />
        ))}
      </ul>
    </li>
  );
};

type subnetQuery = NonNullable<
  NonNullable<
    DiscoveryStatusQuery["location"]
  >["appliances"][number]["interfaces"][number]["discoveryStatus"]
>["subnets"][number];

const SubnetStatus = ({ cidr, arp }: subnetQuery) => {
  let message = "";
  let msgColor: "error" | "primary" | "textPrimary" = "textPrimary";
  switch (arp.status) {
    case "success":
      message = "success";
      msgColor = "textPrimary";
      break;
    case "inprogress":
      message = "in-progress";
      msgColor = "primary";
      break;
    case "error":
      message = `error: ${arp.detail}`;
      msgColor = "error";
      break;
  }
  return (
    <li>
      <Typography variant="body2">{cidr}</Typography>
      <ul className="list-disc ml-5">
        <li>
          <Typography variant="body2">
            Last updated: {new Date(arp.lastUpdated).toLocaleString()}
          </Typography>
        </li>
        <li>
          <Typography variant="body2" color={msgColor}>
            {message}
          </Typography>
        </li>
      </ul>
    </li>
  );
};

gql`
  query discoveryStatus($locationId: Int!) {
    location(id: $locationId) {
      id
      appliances {
        id
        serialNumber
        interfaces {
          name
          mac
          discoveryStatus {
            subnets {
              cidr
              arp {
                lastUpdated
                status
                detail
              }
            }
          }
        }
      }
    }
  }
`;
