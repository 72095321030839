import * as p5 from "p5";
import { useEffect, useState } from "react";
import * as THREE from "three";
import CELLS from "vanta/dist/vanta.cells.min";
import HALO from "vanta/dist/vanta.halo.min";
import NET from "vanta/dist/vanta.net.min";
import TOPOLOGY from "vanta/dist/vanta.topology.min";
import TRUNK from "vanta/dist/vanta.trunk.min";
import WAVES from "vanta/dist/vanta.waves.min";

import { useLocalStorage } from "@/util/useLocalStorage";

export const backgrounds = [
  [
    WAVES,
    {
      THREE,
      mouseControls: false,
      touchControls: false,
      gyroControls: false,
      minHeight: 200.0,
      minWidth: 200.0,
      scale: 1.0,
      scaleMobile: 1.0,
      shininess: 150.0,
      zoom: 0.65,
      color2: 0x007ce4,
    },
  ],
  [
    HALO,
    {
      THREE,
      mouseControls: false,
      touchControls: false,
      gyroControls: false,
      minHeight: 200.0,
      minWidth: 200.0,
      baseColor: 0x007ce4,
      backgroundColor: 0x1a2126,
      shininess: 70.0,
      waveHeight: 30.0,
      waveSpeed: 0.4,
    },
  ],
  [
    TRUNK,
    {
      mouseControls: false,
      touchControls: false,
      gyroControls: false,
      minHeight: 200.0,
      minWidth: 200.0,
      scale: 1.0,
      scaleMobile: 1.0,
      color: 0x007ce4,
      spacing: 10.0,
      chaos: 10.0,
      p5,
    },
  ],
  [
    TOPOLOGY,
    {
      mouseControls: false,
      touchControls: false,
      gyroControls: false,
      minHeight: 200.0,
      minWidth: 200.0,
      scale: 1.0,
      scaleMobile: 1.0,
      color: 0x007ce4,
      p5,
    },
  ],
  [
    NET,
    {
      THREE,
      mouseControls: false,
      touchControls: false,
      gyroControls: false,
      minHeight: 200.0,
      minWidth: 200.0,
      scale: 1.0,
      scaleMobile: 1.0,
      color: 0x5e58e6,
      backgroundColor: 0x70607,
    },
  ],
  [
    CELLS,
    {
      THREE,
      mouseControls: false,
      touchControls: false,
      gyroControls: false,
      minHeight: 200.0,
      minWidth: 200.0,
      scale: 1.0,
      color1: 0x0,
      color2: 0x007ce4,
    },
  ],
  // No background
  [],
];

export function useBackground(sectionRef: React.LegacyRef<HTMLElement>) {
  const [vantaEffect, setVantaEffect] = useState(0);
  const [bg, setBg] = useLocalStorage("assistant-bg", 0);

  function destroyVanta() {
    if (vantaEffect) {
      ((vantaEffect as unknown) as { destroy: () => void }).destroy();
    }
  }

  useEffect(() => {
    const [type, config] = backgrounds[bg];

    if (!vantaEffect && type && config) {
      setVantaEffect(
        type({
          ...config,
          el: (sectionRef as any).current,
        })
      );
    }

    return () => {
      destroyVanta();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vantaEffect]);

  return (newBg: number) => {
    const [type, config] = backgrounds[newBg];

    setBg(newBg);
    destroyVanta();

    if (type && config) {
      setVantaEffect(
        type({
          ...config,
          el: (sectionRef as any).current,
        })
      );
    }
  };
}
