import { Participant } from "livekit-client";
import { sortBy } from "lodash/fp";

function getParticipant(participants: Participant[], identity: String) {
  // sorting and selecting is hack because when the webrtc server or participant(spotapp/camera)
  // crashes, it still keeps the old participants in cache for a while.
  // This creates 2 participants and we need the one that joined the last
  return sortBy("joinedAt", participants)
    .reverse()
    .find((p) => p.identity === identity);
}

export default getParticipant;
