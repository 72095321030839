import { Button, Typography } from "@mui/material";
import clsx from "clsx";
import { StringParam, useQueryParam } from "use-query-params";

import { useBreakpoints } from "@/util/useBreakpoints";

import { useMultiSelectionState } from "../LicensePlatePage";
import { useLprSearch } from "../hooks";
import { LicensePlateSummarySortButton } from "./LicensePlateSummarySortButton";

export function LicensePlateSummaryBarDetails() {
  const [viewing] = useQueryParam("viewing", StringParam);
  const { data } = useLprSearch(true);
  const [selectMultiple, setSelectMultiple] = useMultiSelectionState();

  const { fitsDesktop } = useBreakpoints();
  const total = data.length;

  return (
    <div className="flex flex-col md:flex-row items-center justify-between pb-2 gap-4">
      <div
        className={clsx(
          "flex justify-between md:justify-start items-center gap-4",
          {
            "w-full": !fitsDesktop,
          }
        )}
      >
        <Typography className="tracking-tight text-[28px] md:text-[28px] leading-[28.13px] md:leading-[32.81px] font-light">
          {total} Search Results
        </Typography>
        <Button
          disabled={!!selectMultiple ?? false}
          onClick={() => {
            setSelectMultiple([]);
          }}
          className="leading-[16.41px] hidden"
          color="primary"
        >
          Select Multiple Clips
        </Button>
      </div>
      <div
        className={clsx("flex sm:w-[unset] w-full items-center gap-3", {
          "md:pr-5": viewing,
        })}
      >
        <Typography className="text-sm leading-4 text-[#757575] mt-[3.5px]">
          Sort by:
        </Typography>
        <LicensePlateSummarySortButton label="time" />
        <LicensePlateSummarySortButton label="location" />
        <LicensePlateSummarySortButton label="camera" />
      </div>
    </div>
  );
}
