import { CircularProgress } from "@mui/material";
import clsx from "clsx";
import { utcToZonedTime } from "date-fns-tz";
import { capitalize } from "lodash";
import React, { useMemo } from "react";
import { Chart } from "react-charts";

import { filterNullish } from "@/util/filterFalsy";

import {
  primaryAxis,
  secondaryAxesNonStacked,
  useReactChartStyles,
} from "@/pages/Intelligence/ChartConfig";

import { useIntelligenceDashboardContentQuery } from "@/generated-models";

import {
  CountDirection,
  EntityColors,
  EntityLabel,
  IntelligenceFeatureType,
} from "../constants";

export function CountCardPreview({
  id,
  timezone,
  chartDate,
  subtype,
  feature,
}: {
  id: number;
  timezone: string;
  chartDate: string;
  subtype: CountDirection;
  feature: IntelligenceFeatureType;
}) {
  const { classes } = useReactChartStyles();
  const { data, error, loading } = useIntelligenceDashboardContentQuery({
    variables: {
      id,
      startDate: chartDate,
      endDate: chartDate,
      bucketSizeSeconds: 3600,
      usePaths: true,
    },
  });

  const entity = EntityLabel[feature];
  const buckets = data?.intelligenceDashboard?.metrics.results?.bucketCounts;
  const chartData = useMemo(() => {
    const mapped =
      buckets?.map((b) => ({
        date: utcToZonedTime(new Date(b.bucketMs), timezone),
        leftCount: b.leftCount || 0,
        rightCount: b.rightCount || 0,
      })) || [];
    return [
      subtype !== CountDirection.Out
        ? {
            label: `${capitalize(entity)} In`,
            data: mapped.map((b) => ({
              primary: b.date,
              secondary: b.leftCount,
            })),
          }
        : null,
      subtype !== CountDirection.In
        ? {
            label: `${capitalize(entity)} Out`,
            data: mapped.map((b) => ({
              primary: b.date,
              secondary: b.rightCount,
            })),
          }
        : null,
    ].filter(filterNullish);
  }, [buckets, entity, timezone, subtype]);

  if (error) {
    return (
      <div className="h-40 md:h-52 flex-center">
        <div>No available data</div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="h-[140px] flex-center">
        <CircularProgress size={40} />
      </div>
    );
  }

  if (!chartData.length) {
    return (
      <div className="h-40 md:h-52 flex-center">
        <div>No available data</div>
      </div>
    );
  }

  return (
    <div className={clsx("h-[140px] px-2", classes.hideChartLines)}>
      <Chart
        options={{
          data: chartData,
          primaryAxis: primaryAxis(undefined, { innerSeriesBandPadding: 0 }),
          secondaryAxes: customSecondaryAxes,
          primaryCursor: { showLabel: false },
          secondaryCursor: { showLabel: false },
          defaultColors: EntityColors[entity],
        }}
      />
    </div>
  );
}

const customSecondaryAxes = [
  { ...secondaryAxesNonStacked[0], showGrid: false },
];
