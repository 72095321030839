import { Container, Paper } from "@mui/material";
import { ReactNode } from "react";

interface ComparativeDashboardCardProps {
  children: ReactNode;
}

export function ComparativeDashboardCard({
  children,
}: ComparativeDashboardCardProps) {
  return (
    <Container maxWidth="lg" className="px-0 md:px-6 md:pt-8">
      <Paper
        elevation={0}
        className="rounded-2xl shadow-[0_0_15px_rgba(0,0,0,0.15)]"
      >
        {children}
      </Paper>
    </Container>
  );
}
