import { Button, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { BooleanParam, useQueryParam } from "use-query-params";

import { useBreakpoints } from "@/util/useBreakpoints";

import { MaintainConnectionAuthForm } from "@/pages/Maintain/Details/Config/MaintainDetailsConfigAuthForm";
import { MaintainDetailTile } from "@/pages/Maintain/Details/MaintainDetailsTile";

import CameraProxyPopover from "@/components/Camera/CameraProxyPopover";
import { LocationCapacityGuard } from "@/components/Genius/LocationCapacityGuard";
import { StreamSelectionDropdown } from "@/components/StreamSelectionDropdown/StreamSelectionDropdown";

import { CameraDetailByIdQuery } from "@/generated-models";
import { usePermissions } from "@/hooks/usePermissions";

export function ConnectionField({
  label,
  value,
}: {
  label: string;
  value?: string | number;
}) {
  return (
    <Typography className="text-sm">
      {label}: <span className="font-bold opacity-80">{value}</span>
    </Typography>
  );
}

export function MaintainDetailsConfigConnectionTile({
  camera,
}: {
  camera: CameraDetailByIdQuery["camera"];
}) {
  const { fitsDesktop } = useBreakpoints();
  const [configure, setConfigure] = useQueryParam("configure", BooleanParam);
  const proxyButtonRef = useRef<HTMLButtonElement | null>(null);
  const [proxyPopoverOpen, setProxyPopoverOpen] = useState<boolean>(false);
  const hasPermission = usePermissions();
  const { device, vendor, name, id } = camera;
  const { id: deviceId } = device;

  useEffect(() => {
    if (configure) {
      setProxyPopoverOpen(true);
      proxyButtonRef.current?.scrollIntoView();
    }
  }, [configure]);

  return (
    <MaintainDetailTile
      title="Camera Connection"
      action={
        fitsDesktop && (
          <div className="flex items-center">
            <Button
              ref={proxyButtonRef}
              color="primary"
              className="text-sm h-[36.5px] -mt-0.5"
              onClick={(e) => {
                setProxyPopoverOpen(true);
              }}
            >
              Native Configure
            </Button>
          </div>
        )
      }
    >
      <div className="grid grid-cols-1 sm:grid-cols-2 w-full gap-x-12 gap-y-4">
        <div>
          <Typography className="font-bold text-base mb-1">
            Connection
          </Typography>
          <div className="flex flex-col sm:flex-row gap- w-full">
            <ConnectionField label="IP" value={device.ip} />
            <span className="sm:block hidden border-l border-solid border-[#C4C4C4] mx-3"></span>
            <ConnectionField label="Mac" value={`${device.mac} (${vendor})`} />
          </div>
          <MaintainConnectionAuthForm cameraId={id} />
        </div>
        <LocationCapacityGuard locationId={camera.location.id}>
          <StreamSelectionDropdown
            deviceName={name}
            deviceVendor={vendor}
            deviceId={deviceId}
            channelId={camera.stream.channelId}
            aiEnabled={camera.aiEnabled}
            onOpenNativeProxy={() => setProxyPopoverOpen(true)}
            disabled={!hasPermission("devices_manage")}
          />
        </LocationCapacityGuard>
      </div>
      <CameraProxyPopover
        open={proxyPopoverOpen}
        anchorEl={proxyButtonRef.current}
        onClose={() => {
          setProxyPopoverOpen(false);
          setConfigure(false);
        }}
        deviceMac={device.mac}
        deviceId={device.id}
        localTarget={device.ip}
        homeAppliance={camera.appliance}
        reachableAppliances={device.reachableAppliances}
      />
    </MaintainDetailTile>
  );
}
