import EventRepeatOutlinedIcon from "@mui/icons-material/EventRepeatOutlined";
import { IconButton } from "@mui/material";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useQueryParam } from "use-query-params";

import { useBreakpoints } from "@/util/useBreakpoints";

import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { SettingsIcon } from "@/components/Player/PlayerIcons";

import { usePermissions } from "@/hooks/usePermissions";

import IntegrationsCard from "../../../IntegrationsOld/Core/IntegrationsCard";
import IntegrationsNavigationHeader from "../../../IntegrationsOld/Core/IntegrationsNavigationHeader";
import { IntegrationCSVEventImportButton } from "../Edit/IntegrationCSVEventImportButton";
import { IntegrationTypeIcon } from "../IntegrationTypeIcon";
import { useCurrentIntegration } from "../hooks/integrationHooks";
import { IntegrationDeviceTabContent } from "./Device/IntegrationDeviceTabContent";
import { IntegrationEventContent } from "./Event/IntegrationEventTabContent";
import {
  IntegrationDetailsTab,
  TabParam,
  TabType,
} from "./IntegrationDetailsTab";

function IntegrationDetailsPageHeader() {
  const { fitsDesktop } = useBreakpoints();
  const { integration } = useCurrentIntegration();
  const hasPermission = usePermissions();

  const hasManagePermission = hasPermission("integrations_manage");
  const isEditable = hasManagePermission && !!integration;

  return (
    <div className="flex flex-col gap-3 mt-0 md:mt-2 md:bg-transparent bg-white">
      <div className="relative md:mt-0 mt-8 flex flex-col sm:flex-row gap-y-2 sm:items-center justify-between">
        <div className="flex items-center justify-start gap-2">
          <IntegrationsNavigationHeader
            to=".."
            title={integration?.name || ""}
            className="!md:gap-3 !gap-1"
            textClassName={clsx({ "text-2xl leading-[28px]": !fitsDesktop })}
            subText={`Spot Connect: ${
              integration?.integrationType?.label || "Not Found"
            }`}
          />
          {isEditable && (
            <IconButton component={Link} to="./edit">
              <SettingsIcon className="text-[#353D48]" />
            </IconButton>
          )}
        </div>
        {isEditable && fitsDesktop && (
          <div className="flex justify-center items-center gap-2">
            <IntegrationCSVEventImportButton />
          </div>
        )}
      </div>

      <div className="flex items-center md:items-end justify-start gap-7 md:gap-2 md:pb-0 pb-2 md:px-0 px-5 sm:bg-transparent bg-white">
        <IntegrationDetailsTab
          tab={TabType.DEVICES}
          label="Devices"
          Icon={IntegrationTypeIcon}
        />
        <IntegrationDetailsTab
          tab={TabType.EVENTS}
          label="Events"
          Icon={EventRepeatOutlinedIcon}
        />
      </div>
      {!fitsDesktop && (
        <div
          className="h-2.5 opacity-[.10] relative w-full -my-5"
          style={{
            background:
              "linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%)",
          }}
        ></div>
      )}
    </div>
  );
}

export function IntegrationDetailsPage() {
  const { fitsDesktop } = useBreakpoints();
  const [activeTab] = useQueryParam("tab", TabParam);
  const { error, loading } = useCurrentIntegration();

  const content = (
    <>
      {activeTab === TabType.DEVICES && <IntegrationDeviceTabContent />}
      {activeTab === TabType.EVENTS && <IntegrationEventContent />}
    </>
  );

  if (error) {
    return (
      <ErrorMessage
        title="Unable to load integration"
        description="We're having trouble loading the integration, please try again later"
      />
    );
  }

  if (loading) {
    return <Loading grow>Fetching Integration</Loading>;
  }

  return (
    <>
      <IntegrationDetailsPageHeader />
      {fitsDesktop && (
        <IntegrationsCard className="md:rounded-tl-none md:rounded-2xl rounded-none">
          {content}
        </IntegrationsCard>
      )}
      {!fitsDesktop && <div className="px-4 py-2 mt-4">{content}</div>}
    </>
  );
}
