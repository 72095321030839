import { Alert } from "@mui/material";
import clsx from "clsx";
import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { Outlet, useLocation } from "react-router-dom";
import { useNetworkState, useUpdateEffect } from "react-use";

import { useMobileNav } from "@/pages/Live/LiveMobile";

import { AlertsManager } from "@/components/Alert/AlertsManager";
import { RefreshButtonErrorMessage } from "@/components/ErrorMessage";

import { AuthenticatedHeader } from "@/layout/Header";
import { MobileNav } from "@/layout/MobileNav";

export function Layout({
  children,
  header,
}: {
  children?: React.ReactNode;
  header?: React.ReactNode;
}) {
  const { online } = useNetworkState();
  const { infraMaintenanceBanner, audibleAlerts } = useFlags();
  const mobileNav = useMobileNav();

  return (
    <div className={clsx("h-full", { "pb-20 relative": mobileNav })}>
      <div className="flex flex-col h-full overflow-auto">
        {!online && (
          <Alert severity="error" variant="filled" className="rounded-t-none">
            You are currently offline. Check your internet connection.
          </Alert>
        )}
        {infraMaintenanceBanner && (
          <Alert
            icon={false}
            severity="info"
            variant="filled"
            className="text-xs rounded-none"
          >
            <strong>Scheduled Maintenance</strong> - Spot AI will be undergoing
            scheduled maintenance on 07/1/2023 at 1:30am PT for approximately 30
            minutes. Cameras will continue to record throughout but the Spot
            Dashboard will be unavailable during this time. We apologize for any
            inconvenience this will cause.
          </Alert>
        )}
        {header ?? <AuthenticatedHeader />}
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          {children ?? <Outlet />}
        </ErrorBoundary>
      </div>
      {mobileNav && <MobileNav />}
      {audibleAlerts && <AlertsManager />}
    </div>
  );
}

function ErrorFallback({ resetErrorBoundary }: FallbackProps) {
  const { pathname, search } = useLocation();
  useUpdateEffect(() => resetErrorBoundary(), [pathname, search]);

  return (
    <RefreshButtonErrorMessage
      title="Oops!"
      description="Something went wrong on this page, please refresh or navigate to a different page."
    />
  );
}
