import clsx from "clsx";
import { motion } from "framer-motion";

export function CopilotNavigationButton({
  onClick,
  className,
  disabled,
  flip,
  stroke = "stroke-[#3E3E3E]",
  fill = "fill-[white]",
}: {
  onClick: () => void;
  className?: string;
  disabled?: boolean;
  flip?: boolean;
  stroke?: string;
  fill?: string;
}) {
  return (
    <button
      onClick={onClick}
      type="button"
      className={clsx(
        "bg-transparent group transition duration-300 ease-in-out",
        {
          "opacity-30 pointer-events-none": disabled,
        },
        className
      )}
    >
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="16" cy="16" r="15" fill="black" fillOpacity="0.8" />
        <circle
          cx="16"
          cy="16"
          r="15.5"
          className={clsx(
            "group-hover:stroke-white transition duration-300 ease-in-out",
            stroke
          )}
          stroke="currentColor"
          strokeOpacity="0.6"
        />
        <motion.path
          fillRule="evenodd"
          clipRule="evenodd"
          className={clsx(
            "group-hover:fill-white transition duration-300 ease-in-out",
            fill
          )}
          d={
            flip
              ? "M21.7803 16.5308C22.0732 16.2379 22.0732 15.7631 21.7803 15.4702L17.0074 10.6972C16.7145 10.4043 16.2396 10.4043 15.9467 10.6972C15.6538 10.9901 15.6538 11.465 15.9467 11.7579L19.4393 15.2505H10.25C9.83579 15.2505 9.5 15.5863 9.5 16.0005C9.5 16.4147 9.83579 16.7505 10.25 16.7505H19.4393L15.9467 20.2432C15.6538 20.536 15.6538 21.0109 15.9467 21.3038C16.2396 21.5967 16.7145 21.5967 17.0074 21.3038L21.7803 16.5308Z"
              : "M10.2197 16.5308C9.92678 16.2379 9.92678 15.7631 10.2197 15.4702L14.9926 10.6972C15.2855 10.4043 15.7604 10.4043 16.0533 10.6972C16.3462 10.9901 16.3462 11.465 16.0533 11.7579L12.5607 15.2505H21.75C22.1642 15.2505 22.5 15.5863 22.5 16.0005C22.5 16.4147 22.1642 16.7505 21.75 16.7505H12.5607L16.0533 20.2432C16.3462 20.536 16.3462 21.0109 16.0533 21.3038C15.7604 21.5967 15.2855 21.5967 14.9926 21.3038L10.2197 16.5308Z"
          }
          fill="currentColor"
        />
      </svg>
    </button>
  );
}
