import { ListItem, ListItemIcon, ListItemProps } from "@mui/material";
import React, { ReactNode } from "react";

export function ShareTrayItem({
  icon,
  label,
  labelClassName,
  ...props
}: ListItemProps<"button"> & {
  icon: ReactNode;
  label: string;
  labelClassName?: string;
}) {
  return (
    <ListItem
      className="text-white text-left py-1 px-3 w-full focus:bg-white focus:bg-opacity-20 md:hover:bg-white md:hover:bg-opacity-20 h-10"
      {...(props as any)}
      button
    >
      <ListItemIcon className="text-inherit min-w-0 mr-2">{icon}</ListItemIcon>
      <strong className={labelClassName}>{label}</strong>
    </ListItem>
  );
}
