import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  ButtonGroup,
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
} from "@mui/material";
import { sortBy } from "lodash/fp";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useBreakpoints } from "@/util/useBreakpoints";

import { useWallStyles } from "@/pages/VideoWall/constants";
import { useCanEditVideoWalls } from "@/pages/VideoWall/hooks";
import { useIsDemoUser } from "@/pages/VideoWall/useIsDemoUser";
import { useVideoWallContext } from "@/pages/VideoWall/useVideoWallContext";

import { CreateVideoWallPopper } from "@/components/VideoWall/CreateVideoWallPopper";

import { Page_VideoWallsQuery } from "@/generated-models";

import { usePrefixOrgSlug } from "../../hooks/useOrgRouteBase";
import { WallPreview } from "./WallPreview";
import { useVideoWallParams } from "./useVideoWallParams";

export function WallSelector({
  videoWalls,
  rotatingVideoWalls,
}: {
  videoWalls: Page_VideoWallsQuery["videoWalls"];
  rotatingVideoWalls: Page_VideoWallsQuery["rotatingVideoWalls"];
}) {
  const {
    fullscreenHandle,
    editingState: [, setEditing],
    iosActiveState: [iosActive, setIosActive],
  } = useVideoWallContext();
  const isDemo = useIsDemoUser();
  const canEdit = useCanEditVideoWalls();
  const { fitsTablet } = useBreakpoints();
  const createWallButtonRef = useRef<HTMLButtonElement>(null);
  const { classes } = useWallStyles();
  const navigate = useNavigate();
  const [createAnchor, setCreateAnchor] = useState<null | HTMLElement>(null);
  const { rotatingWallId, singleWallId } = useVideoWallParams();
  const prefixOrgSlug = usePrefixOrgSlug();

  const walls = sortBy((w) => w.name.toLocaleLowerCase(), [
    ...videoWalls,
    ...rotatingVideoWalls,
  ]);

  return (
    <>
      <ButtonGroup color="primary" fullWidth>
        {fitsTablet && (
          <Tooltip title="Create Wall">
            <Button
              className="max-w-[52px] border-r-0 rounded-r-none"
              ref={createWallButtonRef}
              disabled={isDemo || !canEdit}
              onClick={(e) => {
                e.stopPropagation();
                fullscreenHandle.active && fullscreenHandle.exit();
                iosActive && setIosActive(false);
                setCreateAnchor((currentAnchor) =>
                  currentAnchor ? null : e.currentTarget
                );
              }}
            >
              <AddIcon fontSize="small" />
            </Button>
          </Tooltip>
        )}
        <Select
          className="rounded sm:rounded-l-none"
          variant="outlined"
          color="primary"
          classes={{ select: "py-3" }}
          fullWidth
          value={
            rotatingWallId
              ? `RotatingVideoWall:${rotatingWallId}`
              : singleWallId
              ? `VideoWall:${singleWallId}`
              : ""
          }
          onChange={(e) => {
            const value = e.target.value as string;
            if (value === "__NEW__") {
              e.stopPropagation();
              setCreateAnchor((currentAnchor) =>
                currentAnchor ? null : createWallButtonRef.current
              );
            } else {
              const [typename, id] = value.split(":");
              if (!id) return;

              if (typename === "RotatingVideoWall") {
                localStorage.lastViewedWallId = `r/${id}`;
                navigate(prefixOrgSlug(`/wall/r/${id}`), { replace: true });
              } else if (typename === "VideoWall") {
                localStorage.lastViewedWallId = id;
                navigate(prefixOrgSlug(`/wall/${id}`), { replace: true });
              }
            }
          }}
          displayEmpty
          renderValue={(value) => {
            if (!value) return "Select a Wall";

            return (
              <div className="flex gap-3">
                {rotatingWallId && <img src="/rotating-wall-icon.svg" alt="" />}
                {
                  [...videoWalls, ...rotatingVideoWalls].find(
                    (w) => `${w.__typename}:${w.id}` === value
                  )?.name
                }
              </div>
            );
          }}
          input={
            <OutlinedInput
              data-cy="wall-select"
              classes={{
                root: classes.wallSelectInput,
                notchedOutline: "opacity-50 border-primary",
              }}
            />
          }
          disabled={videoWalls.length === 0}
          MenuProps={{
            // Portal doesn't work in fullscreen mode
            // since the portal will fall outside of the
            // DOM subtree that is in full screen
            disablePortal: true,
          }}
        >
          {fitsTablet && (
            <MenuItem
              value="__NEW__"
              disabled={isDemo || !canEdit}
              className="text-primary justify-end"
            >
              + Create a New Wall
            </MenuItem>
          )}
          {walls.map((wall) => (
            <MenuItem
              key={`${wall.__typename}:${wall.id}`}
              value={`${wall.__typename}:${wall.id}`}
            >
              <WallPreview
                ids={
                  wall.__typename === "RotatingVideoWall"
                    ? wall.videoWalls.map((w) => w.id)
                    : [wall.id]
                }
              />
              <Box m={1} />
              {wall.__typename === "RotatingVideoWall" && (
                <>
                  <img src="/rotating-wall-icon.svg" alt="" />
                  <Box m={1} />
                </>
              )}
              {wall.name}
            </MenuItem>
          ))}
        </Select>
      </ButtonGroup>

      <CreateVideoWallPopper
        anchor={createAnchor}
        setAnchor={setCreateAnchor}
        editWall={(id: number, rotating: boolean) => {
          navigate(prefixOrgSlug(`/wall/${rotating ? "r/" : ""}${id}`), {
            replace: true,
          });
          setEditing(true);
        }}
      />
    </>
  );
}
