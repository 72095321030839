import clsx from "clsx";
import { ReactNode } from "react";

interface IntegrationsCardProps {
  dense?: boolean;
  className?: string;
  children: ReactNode;
}

export default function IntegrationsCard({
  dense,
  className,
  children,
}: IntegrationsCardProps) {
  return (
    <div
      className={clsx(
        "bg-white rounded-2xl shadow-[0_0px_15px_rgba(0,0,0,0.15)]",
        dense ? "p-4" : "p-8",
        className
      )}
    >
      {children}
    </div>
  );
}
