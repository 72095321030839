import { useEffect, useState } from "react";

import {
  getSignalingToken,
  webRTCLogger,
} from "@/components/Player/WebRTCPlayer/LivekitUtils";

function getValidDuration(expiration: number) {
  if (!expiration) return 0;

  const expDate = new Date(0);
  expDate.setUTCSeconds(expiration);

  // Add a grace period so that we can refresh the token while it's still valid.
  return ((expDate.getTime() - new Date().getTime()) * 2) / 3;
}

const useSignalingToken = ({
  signalingURL,
  rtcId,
}: {
  signalingURL: string;
  rtcId: string;
}) => {
  const [tick, setTick] = useState(0);
  const [signalingToken, setSignalingToken] = useState<string>();
  useEffect(() => {
    getSignalingToken(signalingURL, rtcId)
      .then(({ token, expiration }) => {
        const duration = getValidDuration(expiration);

        setSignalingToken(token);

        // Schedule a token refresh if we have a valid duration.
        if (duration > 0) {
          setTimeout(() => {
            setTick((v) => v + 1);
          }, duration);
        }
      })
      .catch((e) => {
        webRTCLogger("signaling token failed: ", e);

        // Retry after 5 seconds.
        setTimeout(() => {
          setTick((v) => v + 1);
        }, 5000);
      });
  }, [signalingURL, rtcId, tick]);

  return signalingToken;
};

export default useSignalingToken;
