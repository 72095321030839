import { useCallback } from "react";
import {
  Link,
  NavigateOptions,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Overwrite } from "utility-types";

export function useOrgRouteBase() {
  const { orgSlug } = useParams<{ orgSlug: string }>();

  return orgSlug && `/o/${orgSlug}`;
}

export function usePrefixOrgSlug() {
  const orgRouteBase = useOrgRouteBase();

  return useCallback(
    (path: string) => {
      if (!orgRouteBase) return path;
      return `${orgRouteBase}${path}`;
    },
    [orgRouteBase]
  );
}

export function useOrgSlugNavigate() {
  const prefixOrgSlug = usePrefixOrgSlug();
  const navigate = useNavigate();

  return useCallback(
    (to: string, options?: NavigateOptions) => {
      navigate(prefixOrgSlug(to), options);
    },
    [prefixOrgSlug, navigate]
  );
}

export function OrgSlugLink({
  to,
  ...rest
}: Overwrite<React.ComponentProps<typeof Link>, { to: string }>) {
  const prefixOrgSlug = usePrefixOrgSlug();
  return <Link to={prefixOrgSlug(to)} {...rest} />;
}
