import { Skeleton } from "@mui/material";
import { format } from "date-fns/fp";
import { useAtomValue } from "jotai";
import { Fragment, useMemo } from "react";

import { useBreakpoints } from "@/util/useBreakpoints";

import {
  IntervalGraph,
  IntervalTickProps,
  IntervalTicks,
} from "@/pages/Intelligence/IntervalGraph";

import { ReactECharts } from "@/components/Chart";

import { Camera, Location } from "@/generated-models";

import { Range } from "../../../IntelligenceDashboardView";
import { useIntelligenceDateRange } from "../../../hooks";
import { COMPARATIVE_COLORS, ComparativeTabType } from "../../constant";
import { compContentTabAtom } from "../ComparativeDashboardContentTabs";
import {
  refContentDashboardLastUpdated,
  refDashboardsLoadingAtom,
  refPresenceDashboardsAtom,
} from "./ComparativeDashboardChartFetcher";
import { useComparativeChartData } from "./hooks";

export type ComparativeDashboardDataProps = {
  referenceDashboards: {
    id: number;
    cameras: (Pick<Camera, "id" | "name"> & {
      location: Pick<Location, "name" | "timezone">;
    })[];
  }[];
};

export function ComparativeDashboardChart({
  referenceDashboards,
}: ComparativeDashboardDataProps) {
  const { fitsTablet } = useBreakpoints();
  const loadingCharts = useAtomValue(refDashboardsLoadingAtom);
  const data = useComparativeChartData(referenceDashboards);
  const tab = useAtomValue(compContentTabAtom);
  const { range } = useIntelligenceDateRange();

  const loading = Object.values(loadingCharts).some((c) => !!c);

  if (data.length === 0) return <></>;

  return (
    <div>
      <div className="h-40 sm:h-80">
        <ReactECharts
          loading={loading}
          style={{ height: fitsTablet ? 340 : 160 }}
          option={{
            responsive: true,
            grid: {
              show: true,
              top: "4%",
              left: "2%",
              right: 24,
              bottom: fitsTablet ? 60 : 0,
              containLabel: true,
            },
            dataZoom: !fitsTablet
              ? []
              : [
                  {
                    type: "slider",
                    xAxisIndex: 0,
                    filterMode: "none",
                    start: 0,
                    end: 100,
                  },
                  {
                    type: "inside",
                    xAxisIndex: 0,
                    filterMode: "none",
                  },
                ],
            legend: undefined,
            tooltip: {
              trigger: "axis",
              axisPointer: {
                label: {
                  formatter: ({ value }: { value: any }) => {
                    return format(
                      range === Range.Custom || range === Range.Day
                        ? "MMM, d hh:mm"
                        : "MMM, d"
                    )(new Date(value));
                  },
                },
              },
              valueFormatter:
                tab === ComparativeTabType.IDLE_PERCENTAGE
                  ? (value: unknown) => `${(value as number).toFixed(2)}%`
                  : undefined,
            },
            xAxis: {
              type: "time",
              boundaryGap: ["2%", "2%"],
              splitLine: {
                show: true,
              },
              minorSplitLine: {
                show: true,
              },
              minorTick: {
                splitNumber: 4,
              },
            },
            yAxis: {
              type: "value",
              max: tab === ComparativeTabType.IDLE_PERCENTAGE ? 100 : undefined,
              min: 0,
              boundaryGap: ["20%", "20%"],
              axisLabel: {},
            },
            series: data,
          }}
        />
      </div>
    </div>
  );
}

export function ComparativePresenceChart({
  referenceDashboards,
}: ComparativeDashboardDataProps) {
  const { range } = useIntelligenceDateRange();
  const lastUpdated = useAtomValue(refContentDashboardLastUpdated);
  const refPresence = useAtomValue(refPresenceDashboardsAtom);

  let tickConfig: IntervalTickProps | null = null;

  const data = useMemo(() => {
    return (
      referenceDashboards?.map((rd) => {
        const camera = rd.cameras[0];
        const results =
          refPresence[rd.id]?.data?.intelligenceDashboard?.presence?.results;
        const buckets = results?.presenceBuckets || [];
        return {
          id: rd.id,
          buckets,
          camera,
        };
      }) || []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastUpdated]);

  if (range !== Range.Day) {
    return (
      <ComparativeDashboardChart referenceDashboards={referenceDashboards} />
    );
  }

  return (
    <div>
      <div className="rounded-xl border border-[#D6D6D6] px-3 py-2">
        <div className="flex flex-col gap-1">
          {data.map(({ id, camera, buckets }, idx) => {
            if (!buckets[0])
              return (
                <Skeleton
                  variant="rectangular"
                  className="w-full h-3 rounded"
                  key={id}
                />
              );

            tickConfig = {
              graphStartMs: buckets[0].bucketMs,
              graphEndMs: buckets[0].bucketEndMs,
              variant: "xs",
              timezone: camera?.location?.timezone,
            };

            return (
              <IntervalGraph
                key={id}
                intervalBarStyle={{ background: COMPARATIVE_COLORS[idx][1] }}
                cameraId={camera?.id}
                intervals={buckets[0].intervals}
                graphStartMs={buckets[0].bucketMs}
                graphEndMs={buckets[0].bucketEndMs}
                timezone={camera?.location?.timezone}
                variant="xs"
                interactive
                hideLabels
              />
            );
          })}
        </div>
      </div>
      <div className="flex justify-between px-3 pt-[6px]">
        {tickConfig && <IntervalTicks {...(tickConfig as IntervalTickProps)} />}
      </div>
    </div>
  );
}
