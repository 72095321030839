import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Typography, Box, Popover } from "@mui/material";
import React, { PropsWithChildren, useState, useRef } from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  icon: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 4px 0 6px",
    fontSize: 8,
    fontWeight: "bold",
    lineHeight: 0.6,
    "& svg": { fontSize: 15 },
  },
  formControl: {
    width: "100%",
  },
  select: {
    background: "RGBA(243, 250, 255, 1.00)",
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
  },
  separator: {
    background: "rgba(0,0,0,0.3)",
    width: 1,
    height: 13,
    margin: theme.spacing(0, 1),
  },
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
    width: 350,
    pointerEvents: "auto",
  },
}));

interface HelpPopoverProps {
  title: string;
  stayOn?: boolean;
}

export default function HelpPopover({
  children,
  title,
  stayOn,
}: PropsWithChildren<HelpPopoverProps>) {
  const { classes } = useStyles();
  const [helpOpen, setHelpOpen] = useState(false);
  const helpRef = useRef<SVGSVGElement | null>(null);
  return (
    <>
      <HelpOutlineIcon
        ref={helpRef}
        onMouseEnter={() => setHelpOpen(true)}
        onMouseLeave={() => setHelpOpen(false)}
        style={{ fontSize: 18 }}
      />
      <Popover
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={helpOpen}
        anchorEl={helpRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={() => setHelpOpen(false)}
        disableRestoreFocus
        PaperProps={
          stayOn
            ? {
                onMouseEnter: () => setHelpOpen(true),
                onMouseLeave: () => setHelpOpen(false),
              }
            : undefined
        }
      >
        <Box display="flex" flexDirection="row" alignItems="center" mb={1}>
          <HelpOutlineIcon
            style={{ fontSize: 17, marginRight: 6, marginBottom: 2 }}
          />
          <Typography variant="h4">{title}</Typography>
        </Box>

        <div>{children}</div>
      </Popover>
    </>
  );
}
