import BackIcon from "@mui/icons-material/ChevronLeft";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  Divider,
  IconButton,
  Link,
  TextField as MaterialTextField,
  Typography,
} from "@mui/material";
import { createFilterOptions } from "@mui/material/useAutocomplete";
import { Field, Form, Formik, useField, useFormikContext } from "formik";
import { CheckboxWithLabel, TextField } from "formik-mui";
import { DatePicker as FormikDatePicker } from "formik-mui-x-date-pickers";
import gql from "graphql-tag";
import React, { useMemo } from "react";
import { NumberParam, useQueryParam } from "use-query-params";

import { formatIsoDate } from "@/util/date";

import { CaseCollaborator } from "@/pages/Cases/CaseCollaboratorChip";
import {
  CaseCollaboratorSelectField,
  CaseTagsSelectField,
} from "@/pages/Cases/Cases";

import { Loading } from "@/components/Loading";
import { CloseIcon } from "@/components/Player/PlayerIcons";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  CreateCaseMutation,
  useCreateCaseMutation,
  useGetCasesListQuery,
} from "@/generated-models";

import { ErrorMessage } from "../ErrorMessage";

export enum CaseContentType {
  clip,
  bulk_clips,
  screenshot,
}

export function AddToCaseDialog({
  type,
  open,
  setOpen,
  onSave,
}: {
  type: CaseContentType;
  open: boolean;
  setOpen: React.Dispatch<boolean>;
  onSave: (caseId: number, redirectToCase: boolean) => Promise<void>;
}) {
  const { pushSnackbar } = useFeedback();
  const { data, error } = useGetCasesListQuery({
    ...refetchOnMountPolicy,
  });
  const selectableCases = useMemo(() => {
    return data?.cases.filter((c: any) => c.permissions.manage_media) ?? [];
  }, [data?.cases]);

  const [createCase] = useCreateCaseMutation({
    onError: () =>
      pushSnackbar(
        "We were unable to create the case. Please try again later.",
        FeedbackType.Error
      ),
  });
  const [investigatingCaseId] = useQueryParam("case", NumberParam);

  let title =
    type === CaseContentType.clip
      ? "Save Clip"
      : type === CaseContentType.bulk_clips
      ? "Save Clips"
      : "Save Screenshot";

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      classes={{ paper: "max-w-md p-4 my-2 " }}
    >
      {error && (
        <ErrorMessage
          title="Oops!"
          description="Something went wrong, please try again.."
        />
      )}
      {!error && !data && <Loading />}
      {!error && data && (
        <Formik
          initialValues={{
            case: (investigatingCaseId
              ? data.cases.find((c) => c.id === investigatingCaseId)
              : null) as AutocompleteItem | null,
            createCaseTitle: "",
            createCaseDescription: "",
            collaborators: [] as CaseCollaborator[],
            tags: [] as string[],
            incidentDate: null as Date | null,
            viewCaseAfterSaving: false,
          }}
          onSubmit={async (values, { setSubmitting }) => {
            if (!values.case) return;

            let newCase: CreateCaseMutation | null = null;
            if (values.case.id === -1) {
              newCase = await createCase({
                variables: {
                  value: {
                    title: values.createCaseTitle,
                    description: values.createCaseDescription,
                    tags: values.tags,
                    collaborators: values.collaborators.map((c) => ({
                      orgUserId: c.orgUserId,
                      caseRole: c.caseRole,
                    })),
                    incidentDate: values.incidentDate
                      ? formatIsoDate(values.incidentDate)
                      : null,
                  },
                },
                refetchQueries: ["getCases"],
              }).then((result) => result.data!);
              if (!newCase) {
                setSubmitting(false);
                return;
              }
            }
            setSubmitting(false);
            setOpen(false);
            await onSave(
              newCase ? newCase.createCase.id : Number(values.case.id),
              values.viewCaseAfterSaving
            );
          }}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form>
              {/* Modal header */}
              <div className="flex justify-between items-center">
                {values.case?.id === -1 ? (
                  <Button
                    onClick={() => setFieldValue("case", null)}
                    startIcon={<BackIcon />}
                  >
                    <Typography variant="h3">Create new Case</Typography>
                  </Button>
                ) : (
                  <Typography variant="h3">{title}</Typography>
                )}
                <IconButton size="small" onClick={() => setOpen(false)}>
                  <CloseIcon />
                </IconButton>
              </div>
              <Divider className="my-2" />
              {/* Modal content */}
              {values.case?.id === -1 ? (
                <div className="flex flex-col gap-4">
                  <Field
                    name="createCaseTitle"
                    component={TextField}
                    label="Title"
                    fullWidth
                  />
                  <Field
                    name="createCaseDescription"
                    component={TextField}
                    label="Description"
                    multiline
                    rows={5}
                    className="min-w-[400px]"
                  />
                  <CaseTagsSelectField
                    name="tags"
                    tags={data.cases.flatMap((c) => c.tags)}
                  />
                  <CaseCollaboratorSelectField name="collaborators" />
                  <Field
                    name="incidentDate"
                    inputFormat="MM/dd/yyyy"
                    disableFuture
                    textField={{
                      label: "Incident Date",
                      variant: "outlined",
                      fullWidth: true,
                    }}
                    component={FormikDatePicker}
                  />
                </div>
              ) : (
                <>
                  <Typography>
                    Creating a case will allow you to investigate, annotate and
                    group related clips and screenshots.{" "}
                    <Link
                      href="https://app.getbeamer.com/spotai3395/en/introducing-cases-video-collaboration-made-easy"
                      target="_blank"
                      underline="hover"
                    >
                      Learn More
                    </Link>
                  </Typography>
                  <div className="my-4" />
                  <CaseSelector name="case" cases={selectableCases} />
                </>
              )}
              <Divider className="my-4" />
              <div className="flex justify-between">
                <Field
                  component={CheckboxWithLabel}
                  type="checkbox"
                  name="viewCaseAfterSaving"
                  Label={{ label: "View case after saving." }}
                />
                <Button
                  disabled={isSubmitting || !values.case}
                  type="submit"
                  variant="contained"
                  color="primary"
                  endIcon={
                    isSubmitting ? <CircularProgress size={20} /> : undefined
                  }
                >
                  {title}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </Dialog>
  );
}

type AutocompleteItem = {
  id: number;
  title: string;
  inputValue?: string;
};
const filterAutocomplete = createFilterOptions<AutocompleteItem>();

function CaseSelector(props: { name: string; cases: AutocompleteItem[] }) {
  const [{ value }, , helpers] = useField<AutocompleteItem>(props);
  const { setFieldValue } = useFormikContext<{ createCaseTitle: string }>();

  return (
    <Autocomplete
      value={value}
      classes={{
        option: "first-of-type:text-white first-of-type:bg-primary",
      }}
      autoHighlight
      onChange={(_, value) => {
        if (!value) return;
        helpers.setValue(value);
        if (value.id === -1) {
          setFieldValue("createCaseTitle", value.inputValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filterAutocomplete(options, params);

        // Suggest the creation of a new value
        filtered.unshift({
          inputValue: params.inputValue,
          title: `+ Add ${
            params.inputValue ? `"${params.inputValue}"` : "new Case"
          }`,
          id: -1,
        });

        return filtered;
      }}
      selectOnFocus
      handleHomeEndKeys
      options={props.cases}
      getOptionLabel={(option) => option.title}
      renderInput={(params) => (
        <MaterialTextField
          {...params}
          label="Select Case or Create new Case"
          variant="outlined"
        />
      )}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.title}
        </li>
      )}
    />
  );
}

gql`
  query getCasesList {
    cases {
      id
      title
      tags
      permissions {
        manage_media
      }
    }
  }
`;
