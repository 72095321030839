import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogClasses,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Input,
  Typography,
} from "@mui/material";
import { ReactNode, useCallback, useEffect, useReducer, useState } from "react";
import { makeStyles } from "tss-react/mui";

type DialogResolver = (result: boolean) => void;

function dialogReducer<ContextType = unknown>(
  state: {
    resolve: DialogResolver | null;
    context: ContextType | null;
  },
  action:
    | { type: "open"; resolve: DialogResolver; context?: ContextType }
    | { type: "close" }
) {
  switch (action.type) {
    case "open":
      return {
        resolve: (value: boolean) => {
          // Chaining the resolve to handle multiple calls to `open()` before
          // calling `close()`. Chaining like this ensures that we construct a
          // chain of resolve() calls that will unwind as soon as confirm() or
          // cancel() is called
          action.resolve(value);
          state.resolve?.(value);
        },
        context: action.context,
      };
    case "close":
      return { resolve: null, context: null };
  }
}

export function useDialog<ContextType = undefined>() {
  const [{ resolve, context }, dispatch] = useReducer(dialogReducer, {
    resolve: null,
    context: null,
  });
  const opened = Boolean(resolve);

  const open = useCallback(
    function open(...args: ContextType extends undefined ? [] : [ContextType]) {
      return new Promise<boolean>((res) => {
        dispatch({ type: "open", resolve: res, context: args[0] });
      });
    },
    [dispatch]
  );

  function confirm() {
    if (resolve) {
      resolve(true);
      dispatch({ type: "close" });
    }
  }
  function cancel() {
    if (resolve) {
      resolve(false);
      dispatch({ type: "close" });
    }
  }

  return { open, opened, confirm, cancel, context: context as ContextType };
}

const useStyles = makeStyles()((theme) => ({
  topBar: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#F0F0F0",
    padding: "8px 8px 8px 0",
    marginBottom: 8,
  },
  actions: {
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}));

export function DefaultDialog({
  title,
  content,
  cancelText = "Cancel",
  alternateText = "Alternate",
  confirmText = "Confirm",
  confirmColor = "primary",
  confirmationDisabled = false,
  additionalActions,
  opened,
  cancel,
  alternate,
  confirm,
  hideDefaultActionButtons = false,
  verifyText,

  ...dialogProps
}: {
  title: ReactNode;
  content: ReactNode;
  cancelText?: ReactNode;
  alternateText?: ReactNode;
  confirmText?: ReactNode;
  dialogClasses?: Partial<DialogClasses>;
  confirmColor?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning"
    | undefined;
  confirmationDisabled?: boolean;
  additionalActions?: ReactNode;

  opened: boolean;

  hideDefaultActionButtons?: boolean;
  verifyText?: string;
  cancel: () => void;
  alternate?: () => void;
  confirm: () => void;
} & Omit<DialogProps, "open" | "onClose" | "content">) {
  const { classes } = useStyles();
  const [verified, setVerified] = useState(!verifyText);

  // reset verified if the dialog is reopened or reused
  useEffect(() => {
    setVerified(!verifyText);
  }, [opened, verifyText]);

  return (
    <Dialog
      open={opened}
      onClose={cancel}
      onClick={(e) => {
        e.stopPropagation();
      }}
      {...dialogProps}
    >
      <Box className={classes.topBar}>
        {title && (
          <DialogTitle className="text-xl leading-6">{title}</DialogTitle>
        )}
        <Box flexGrow={1} />
        <IconButton onClick={cancel} size="large">
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent>
        {typeof content === "string" ? (
          <Typography>{content}</Typography>
        ) : (
          content
        )}
        {verifyText && (
          <VerificationInput
            verifyText={verifyText}
            onChange={(verified) => {
              setVerified(verified);
            }}
          />
        )}
      </DialogContent>
      {!hideDefaultActionButtons && (
        <DialogActions className={classes.actions}>
          {additionalActions && (
            <>
              {additionalActions}
              <Box flexGrow={1} />
            </>
          )}
          <Button onClick={cancel} color="primary" variant="outlined">
            {cancelText}
          </Button>
          {alternate && (
            <Button
              onClick={() => {
                cancel();
                alternate();
              }}
              variant="contained"
              color="primary"
            >
              {alternateText}
            </Button>
          )}
          <Button
            onClick={confirm}
            disabled={confirmationDisabled || !verified}
            variant="contained"
            color={confirmColor}
            autoFocus
          >
            {confirmText}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

const VerificationInput = ({
  verifyText,
  onChange,
}: {
  verifyText: string;
  onChange: (verified: boolean) => void;
}) => {
  return (
    <>
      <Typography className="mt-4">
        Type "{verifyText}" below to confirm this action.
      </Typography>
      <Input
        spellCheck={false}
        placeholder={verifyText}
        onChange={(e) => onChange(e.target.value.trim() === verifyText)}
        className="border border-solid border-[#DFDFDF] rounded-md outline-none w-full font-[Courier] mt-2 pl-2"
        classes={{ underline: "before:hidden after:hidden" }}
      />
    </>
  );
};
