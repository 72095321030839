import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { DateTimePicker, DateTimePickerProps } from "@mui/x-date-pickers";
import React, { useState } from "react";

export function DateTimePickerUncontrolled({
  initialDate,
  inputProps,
  onChange,
  ...props
}: Omit<DateTimePickerProps<Date, Date>, "value" | "renderInput"> & {
  initialDate: Date;
  inputProps: TextFieldProps;
}) {
  const [current, setCurrent] = useState<Date | null>(initialDate);
  const [open, setOpen] = useState(false);

  return (
    <DateTimePicker
      {...props}
      showToolbar
      value={current}
      onChange={(value) => {
        setCurrent(value);
      }}
      open={open}
      onAccept={(result: Date | null) => {
        onChange(result);
        setOpen(false);
      }}
      OpenPickerButtonProps={{
        className: "hidden",
      }}
      renderInput={(renderInputProps) => (
        <TextField
          {...(renderInputProps as TextFieldProps)}
          {...(inputProps as TextFieldProps)}
          onClick={(e) => {
            e.preventDefault();
            setOpen(!open);
          }}
          InputProps={{
            className:
              "cursor-pointer text-sm font-normal tracking-tighter normal-case",
            classes: {
              input: "p-0 text-sm mt-1",
            },
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end">
                <ArrowDropDownIcon className="w-6 mt-1" />
              </InputAdornment>
            ),
          }}
          variant="standard"
          size="small"
        />
      )}
    />
  );
}
