export async function getSignalingToken(
  signalingURL: string,
  roomUserId: string
) {
  const updatedUrl = `${signalingURL}&identity=${roomUserId}`;
  const res = await fetch(updatedUrl);
  const token = await res.text();
  let expiration;

  try {
    expiration = JSON.parse(atob(token.split(".")[1]))?.exp as number;
  } catch (e) {
    expiration = 0;
  }

  return { token, expiration };
}

export function getWebRTCUrl(serialNumber: string) {
  return `wss://${serialNumber}-webrtc.s2.spotai.co`;
}

export const webRTCLogger = (function () {
  const context = "SPOT WEBRTC:";
  return Function.prototype.bind.call(console.log, console, context);
})();
