import { Monaco } from "@monaco-editor/react";

import { SPOT_ASSIST_LANG } from "./constant";

export function registerAssistantLanguage(monaco: Monaco) {
  monaco.languages.register({ id: SPOT_ASSIST_LANG });

  return monaco.languages.setLanguageConfiguration(SPOT_ASSIST_LANG, {
    // Allow square brackets to be part of a word.
    // eslint-disable-next-line no-useless-escape
    wordPattern: /(-?\d*\.\d\w*)|([^\`\~\!\#\%\^\&\*\(\)\-\=\+\{\}\\\|\;\:\'\"\,\.\<\>\/\?\s]+)/g,
  });
}
