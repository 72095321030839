import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { capitalize } from "lodash/fp";

import { MaintainDashboardType } from "@/generated-models";

import { useMaintainMetricsDashboardType } from "../hooks";

export function MaintainMetricsTypeToggle() {
  const [type, setType] = useMaintainMetricsDashboardType();

  return (
    <ToggleButtonGroup
      className="p-1 bg-[#F6FBFF] rounded-lg border border-solid border-[#DDEFFF] gap-1"
      color="primary"
      value={type}
      exclusive
      size="small"
      aria-label="Category"
      onChange={(_, value) => {
        setType(value);
      }}
    >
      {Object.values([
        MaintainDashboardType.Appliance,
        MaintainDashboardType.Location,
      ]).map((c) => {
        return (
          <ToggleButton
            key={c}
            value={c}
            className="border-none rounded-md text-sm sm:text-lg leading-[21px] transition-all font-normal"
            classes={{
              root: "bg-[#ddefff]/50 text-primary px-6",
              selected: "bg-white font-bold !bg-primary text-white",
            }}
          >{`${capitalize(c)}s`}</ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
}
