import { nanoid } from "nanoid";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import socketIOClient from "socket.io-client";

export interface ConnectionInfo {
  connectionId: string;
  iceServers: RTCIceServer[];
}

interface RemoteCandidateEventData {
  candidate: RTCIceCandidateInit;
  peerId: string;
}

const SocketContext = React.createContext<{
  uuid: string | null;
  addListener: (id: string, listener: RTCPeerConnection) => void;
  removeListener: (id: string) => void;
  iceServers: RTCIceServer[] | null;
}>({
  uuid: null,
  addListener: () => {},
  removeListener: () => {},
  iceServers: [],
});

export function useSocket() {
  return useContext(SocketContext);
}

export function socketClient() {
  const uuid = nanoid();
  let iceServers: RTCIceServer[] | null = null;
  const socket = socketIOClient("https://signaling.spotai.co/", {
    query: {
      uuid,
    },
  });

  const listeners = new Map<string, RTCPeerConnection>();
  const iceServersListeners: ((iceServers: RTCIceServer[]) => void)[] = [];

  socket.on("connect", () => {
    socket.emit("ready");
  });

  // socket.on("disconnect", (reason: string) => {
  //   console.log(`socket disconnected, ${reason}`);
  // });

  socket.on("readyACK", async (connectionData: ConnectionInfo) => {
    iceServers = connectionData.iceServers;
    iceServersListeners.forEach((listener) => {
      listener(connectionData.iceServers);
    });
  });

  socket.on("NEW_CANDIDATE", async (data: RemoteCandidateEventData) => {
    const { candidate, peerId } = data;
    //console.log(`candidate received for ${peerId}`);
    const listener = listeners.get(peerId);
    if (listener) {
      await listener.addIceCandidate(candidate);
    }
  });

  function addListener(id: string, listener: RTCPeerConnection) {
    // console.log(`Socket: Added a new listener: ${id}`);
    return listeners.set(id, listener);
  }

  function removeListener(id: string) {
    // console.log(`Socket: Removed listener: ${id}`);
    return listeners.delete(id);
  }

  function addOnIceServersChange(
    onICEServersChange: (iceServers: RTCIceServer[]) => void
  ) {
    iceServersListeners.push(onICEServersChange);
    return iceServers;
  }

  // TODO: Add a removeOnIceServersChange for listeners to deregister

  return {
    addListener,
    removeListener,
    uuid,
    addOnIceServersChange,
  };
}

const socket = socketClient();

export function SignalingServer({ children }: { children: React.ReactNode }) {
  const [iceServers, setIceServers] = useState<RTCIceServer[] | null>(null);

  useEffect(() => {
    const iceServers = socket.addOnIceServersChange((servers) => {
      // console.log("ice servers received on signaling server");
      setIceServers(servers);
    });

    setIceServers(iceServers);
  }, []);

  //TODO: Implement a cleanup function that deregisters the listener from the socket

  return (
    <>
      <SocketContext.Provider
        value={{
          uuid: socket.uuid,
          addListener: socket.addListener,
          removeListener: socket.removeListener,
          iceServers,
        }}
      >
        {children}
      </SocketContext.Provider>
    </>
  );
}
