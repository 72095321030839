import { FeatureLicenseSeat, FeatureLicenseType } from "@/generated-models";

export function getLicenseResourceName(seat: FeatureLicenseSeat) {
  switch (seat.type) {
    case FeatureLicenseType.Atv:
      return seat.data.deviceId;
    default:
      return "";
  }
}
