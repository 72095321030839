import BackIcon from "@mui/icons-material/ChevronLeft";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Button, Dialog, Divider, IconButton, Typography } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import { useMemo, ReactNode } from "react";
import { ArrayParam, StringParam, useQueryParams } from "use-query-params";

import { MobileFilterActionBar } from "./MobileFilterActionBar";
import { MobileFilterCategory } from "./MobileFilterCategory";

export interface FilterOption {
  label: string;
  value: string | number;
}

export interface MobileFilterCategoryProps {
  name: string;
  filterName: string;
  type?: typeof StringParam;
  hidden?: boolean;
  options: FilterOption[];
}

interface MobileFilterModalProps {
  count: number;
  open: boolean;
  subject: string;
  children?: ReactNode;
  chips?: ReactNode;
  categories: MobileFilterCategoryProps[];
  onBack: () => void;
}

function useHasFilterApplied(categories: MobileFilterCategoryProps[]) {
  const paramConfig = useMemo(() => {
    const result: Record<string, typeof ArrayParam | typeof StringParam> = {};
    categories.forEach((c) => {
      result[c.filterName] = c.type ?? ArrayParam;
    });

    return result;
  }, [categories]);

  const [params] = useQueryParams(paramConfig);
  const hasFilterApplied = useMemo(() => {
    return Object.values(params).some((p) => !isEmpty(p));
  }, [params]);

  return hasFilterApplied;
}

export function MobileFilterModal({
  count,
  open,
  subject,
  categories,
  children,
  chips,
  onBack,
}: MobileFilterModalProps) {
  const hasFilterApplied = useHasFilterApplied(categories);

  return (
    <Dialog fullScreen open={open}>
      <div className="top-0 sticky z-50">
        <div className="flex justify-between items-center gap-2 px-4 py-2 bg-white">
          <IconButton
            onClick={() => onBack()}
            size="small"
            edge="end"
            className="p-0"
          >
            <BackIcon fontSize="large" color="primary" />
          </IconButton>
          <Typography className="font-bold text-[17px] leading-[22px] flex items-center gap-x-2">
            <FilterListIcon />
            Filter {subject}
          </Typography>
          <div />
        </div>
        <div
          className="h-2.5"
          style={{
            background:
              "linear-gradient(180deg, #C4C4C4 0%, rgba(196, 196, 196, 0) 100%)",
          }}
        ></div>
      </div>
      <div className="-mt-[10px]">
        <MobileFilterActionBar chips={chips} categories={categories} />
        {children}
        {categories
          .filter((c) => !c.hidden)
          .map((category) => (
            <MobileFilterCategory key={category.filterName} {...category} />
          ))}
        <Divider />
      </div>
      {hasFilterApplied && (
        <div className="flex h-full items-end px-2 py-2 w-full">
          <Button
            className="w-full"
            color="primary"
            variant="contained"
            onClick={() => onBack()}
          >{`Show ${count} Result${count > 1 ? "s" : ""}`}</Button>
        </div>
      )}
    </Dialog>
  );
}
