import { atom, useAtomValue } from "jotai";
import { atomFamily } from "jotai/utils";

export interface DCMessageSender {
  connected: boolean;
  send: (msg: string) => Promise<MessageEvent<string>>;
}
export const livekitDataChannelState = atomFamily((url: string) => {
  const dataChannelAtom = atom(null as DCMessageSender | null);
  dataChannelAtom.debugLabel = `dataChannelAtom:${url}`;
  return dataChannelAtom;
});

export function useLivekitDataChannel(url: string) {
  return useAtomValue(livekitDataChannelState(url));
}
