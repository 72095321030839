import {
  createTheme,
  StyledEngineProvider,
  Theme,
  ThemeOptions,
  ThemeProvider,
  useTheme,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";

const TEXT_PRIMARY = "#353d48";
export const CERULEAN_BLUE = "#007ce4";
export const PINK = "#ff4fe2";
export const GREEN = "#08c500";
export const SPOT_GREEN = "#18D710";
// const LIVE_GREEN = "#5cc900";
// const LIVE_GREEN_TEXT = "#67C21B";
// const SEARCH_PURPLE = "#8c00ce";
// const TEXT_GRADIENT_PURPLE = "#a900e4";
export const ERROR = "#db6262";

const mainThemeOptions: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: CERULEAN_BLUE,
      contrastText: "#ffffff",
      light: "#449fec",
    },
    secondary: {
      main: GREEN,
      contrastText: "#ffffff",
    },
    success: {
      main: "#67c21b",
    },
    error: {
      main: ERROR,
    },
    text: {
      primary: TEXT_PRIMARY,
    },
    background: {
      default: "#ffffff",
    },
  },
  typography: {
    fontFamily:
      "'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    h1: {
      fontSize: 24,
      fontWeight: "bold",
    },
    h2: {
      fontSize: 21,
      fontWeight: "bold",
    },
    h3: {
      fontSize: 20,
      fontWeight: "bold",
    },
    h4: {
      fontSize: 18,
      fontWeight: "bold",
    },
    h5: {
      fontSize: 18,
      fontWeight: "normal",
    },
    h6: {
      fontSize: 16,
      fontWeight: "bold",
    },
    body1: {
      fontSize: 16,
      fontWeight: "normal",
    },
    button: {
      textTransform: "none",
      color: CERULEAN_BLUE,
    },
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: ({ theme }) => ({
          width: "100%",
          [theme.breakpoints.down("sm")]: {
            margin: 0,
          },
        }),
      },
    },
    MuiBackdrop: {
      defaultProps: {
        className: "z-[-1]",
      },
    },
    MuiButton: {
      defaultProps: {
        color: "inherit",
      },
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: "standard",
      },
    },
    MuiStepper: {
      defaultProps: {
        className: "p-6",
      },
    },
    MuiTabs: {
      defaultProps: {
        textColor: "inherit",
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "standard",
      },
    },
  },
};

export const theme = createTheme(mainThemeOptions);

export const darkFocusThemeOptions: ThemeOptions = {
  ...mainThemeOptions,
  palette: {
    ...mainThemeOptions.palette,
    mode: "dark",
    text: {
      primary: "#fff",
    },
    background: {
      default: "#000",
    },
  },
  typography: {
    ...mainThemeOptions.typography,
    allVariants: {
      color: "#fff",
    },
  },
};

export const darkFocusTheme = createTheme(darkFocusThemeOptions);

export const useCustomScrollbarStyles = makeStyles()(() => ({
  scrollbarContainer: {
    // This media query should make sure to only apply scroll bar styles
    // on desktop
    "@media (hover: hover)": {
      "&::-webkit-scrollbar": {
        background: "transparent",
        height: 8,
        width: 8,
      },
      "&::-webkit-scrollbar-thumb": {
        border: "none",
        boxShadow: "none",
        background: "rgba(0, 0, 0, .3)",
        borderRadius: 8,
        minHeight: 40,
      },
    },
  },
  darkScrollbarContainer: {
    // This media query should make sure to only apply scroll bar styles
    // on desktop
    "@media (hover: hover)": {
      "&::-webkit-scrollbar": {
        background: "transparent",
        height: 8,
        width: 8,
      },
      "&::-webkit-scrollbar-thumb": {
        border: "none",
        boxShadow: "none",
        background: "rgba(255, 255, 255, .3)",
        borderRadius: 8,
        minHeight: 40,
      },
    },
  },
}));

export function DarkTheme({
  enabled = true,
  theme: themeOverride,
  children,
}: React.PropsWithChildren<{ enabled?: boolean; theme?: Theme }>) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={enabled ? themeOverride ?? darkFocusTheme : theme}>
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export function useIsDarkTheme() {
  const theme = useTheme();
  return theme.palette.mode === "dark";
}
