import clsx from "clsx";
import React from "react";
import { LinkProps, Link } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  root: {
    fontSize: "inherit",
    color: "inherit",
    textDecoration: "inherit",

    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

export function LinkWithInheritedStyles({ className, ...props }: LinkProps) {
  const { classes } = useStyles();
  return <Link className={clsx(classes.root, className)} {...props} />;
}
