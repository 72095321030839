import React from "react";

export function DeviceIcon({ style }: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="93"
      height="72"
      fill="none"
      viewBox="0 0 93 72"
      {...style}
    >
      <path
        fill="#fff"
        stroke="#353D48"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M2.396 23.296c-.564.312-.865.95-.749 1.583l4.374 23.75c.099.536.48.977.998 1.15l60.419 20.293c.697.234 1.462-.073 1.805-.724L88.802 32.14c.094-.18.15-.375.167-.577l1.649-20.349c.063-.787-.494-1.489-1.275-1.605L40.759 2.393c-.327-.049-.66.012-.948.171L2.396 23.296z"
      />
      <path stroke="#353D48" d="M89.123 11.093L67.88 40.553 3.123 24.608" />
      <path stroke="#353D48" d="M4.742 33.88l63.081 17.498L90 17" />
      <path
        fillRule="evenodd"
        stroke="#353D48"
        strokeLinejoin="round"
        d="M69.608 44.427L67.679 47.1 51.995 43l.123 2.01 15.627 4.24 1.68-2.556.183-2.267z"
        clipRule="evenodd"
      />
      <path stroke="#353D48" d="M67.88 40.553l-.197 28.078" />
    </svg>
  );
}
export function P340DeviceIcon({ style }: any) {
  return (
    <svg
      width="58"
      height="106"
      viewBox="0 0 58 106"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3596 89.6308C13.3648 90.0567 13.0495 90.194 12.643 90.0998C12.3658 90.0355 12.0578 89.8608 11.8115 89.6048L12.0072 89.3365C12.2199 89.5503 12.4501 89.6878 12.6496 89.734C12.8418 89.7786 12.9516 89.7199 12.95 89.5856L12.9499 89.5779C12.948 89.4206 12.7404 89.3189 12.5104 89.1929C12.2211 89.0417 11.8981 88.8444 11.8934 88.4569L11.8933 88.4492C11.8886 88.0655 12.1925 87.9026 12.5805 87.9925C12.8244 88.0491 13.0916 88.199 13.3003 88.3889L13.1269 88.6778C12.937 88.5151 12.7365 88.396 12.5739 88.3583C12.4002 88.318 12.3015 88.3793 12.3029 88.4983L12.303 88.5059C12.3048 88.6517 12.5162 88.7619 12.7462 88.8918C13.0319 89.0498 13.3551 89.2586 13.3595 89.6231L13.3596 89.6308ZM15.6187 89.7412C15.6269 90.4165 15.188 90.6935 14.7224 90.5856C14.4009 90.5111 14.1956 90.2951 14.0566 90.0755L14.0679 91.0039L13.6207 90.9003L13.5886 88.2606L14.0357 88.3643L14.0399 88.7019C14.1814 88.5243 14.3826 88.4026 14.6967 88.4754C15.1587 88.5825 15.6105 89.066 15.6187 89.7412ZM15.1641 89.6359C15.1593 89.2369 14.9012 88.9245 14.5982 88.8543C14.2951 88.784 14.0322 88.9794 14.037 89.3746C14.0418 89.7698 14.311 90.0885 14.614 90.1588C14.9208 90.2299 15.169 90.0387 15.1641 89.6359ZM17.8942 90.2687C17.9013 90.848 17.4636 91.2248 16.8613 91.0852C16.2663 90.9473 15.8208 90.3734 15.8137 89.7941C15.8066 89.2109 16.2442 88.8341 16.8429 88.9729C17.4416 89.1117 17.8871 89.6855 17.8942 90.2687ZM17.4471 90.1727C17.4428 89.8121 17.1916 89.4592 16.8405 89.3779C16.482 89.2948 16.2564 89.5333 16.2608 89.8901C16.2652 90.2507 16.5164 90.6035 16.8637 90.6841C17.2259 90.768 17.4515 90.5295 17.4471 90.1727ZM18.7247 89.8491L18.7368 90.8428C18.739 91.0231 18.8286 91.1165 18.9801 91.1517C19.0799 91.1748 19.1683 91.1723 19.2564 91.1468L19.261 91.5267C19.151 91.5662 19.0258 91.5754 18.8595 91.5369C18.5306 91.4607 18.2959 91.2571 18.2905 90.812L18.2775 89.7454L18.0299 89.688L18.0251 89.289L18.2727 89.3464L18.2659 88.7901L18.7131 88.8937L18.7199 89.45L19.2446 89.5717L19.2495 89.9707L18.7247 89.8491Z"
        fill="black"
      />
      <path
        d="M20.5806 91.2467C20.8545 91.2122 20.9973 90.9347 20.8994 90.6268C20.8015 90.319 20.5001 90.0974 20.2261 90.1319C19.9522 90.1664 19.8094 90.4439 19.9073 90.7517C20.0052 91.0596 20.3066 91.2811 20.5806 91.2467Z"
        fill="black"
      />
      <path
        d="M21.5774 92.442C21.9158 92.3994 22.0922 92.0566 21.9713 91.6763C21.8504 91.2961 21.478 91.0223 21.1396 91.0649C20.8012 91.1075 20.6249 91.4503 20.7458 91.8306C20.8667 92.2109 21.239 92.4846 21.5774 92.442Z"
        fill="black"
      />
      <path
        d="M21.857 91.0263C21.9859 91.0101 22.0531 90.8794 22.007 90.7346C21.961 90.5897 21.8191 90.4854 21.6902 90.5016C21.5613 90.5179 21.4941 90.6485 21.5402 90.7934C21.5862 90.9382 21.7281 91.0425 21.857 91.0263Z"
        fill="black"
      />
      <path
        d="M21.3012 90.551C21.5268 90.5226 21.6444 90.2941 21.5638 90.0406C21.4832 89.7871 21.2349 89.6046 21.0093 89.633C20.7837 89.6614 20.6662 89.89 20.7468 90.1435C20.8274 90.397 21.0756 90.5794 21.3012 90.551Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.637 1.41162C37.7679 1.36497 37.9067 1.34499 38.0454 1.35286L55.0434 2.31759C55.6098 2.34974 56.0471 2.82993 56.0283 3.39919L53.8467 69.6791C53.8391 69.9106 53.7553 70.1331 53.6084 70.3118L26.6832 103.073C26.4263 103.386 26.0107 103.528 25.6095 103.419C19.4022 101.721 9.09976 98.0867 7.16499 97.3996C6.81379 97.2749 6.50787 96.9773 6.47639 96.5206C6.1724 92.1112 2.66239 41.2827 0.61449 15.4222C0.576045 14.9367 0.873356 14.5156 1.30708 14.361L37.637 1.41162ZM54.9844 3.36455L37.9864 2.39982L1.65652 15.3492C1.65651 15.3492 1.6565 15.3492 1.65648 15.3492C3.69954 41.1503 7.19581 91.7699 7.51586 96.4119C9.4652 97.1041 19.7173 100.72 25.8781 102.405L52.8028 69.6444L54.9844 3.36455Z"
        fill="black"
        stroke="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.439 3.67765C55.0968 3.4096 55.5016 3.247 55.5711 3.22516L55.6751 3.55875C55.6237 3.57492 55.2403 3.72834 54.5699 4.00153C53.9061 4.27204 52.9722 4.65519 51.8319 5.12463C49.5512 6.0635 46.4451 7.34728 43.0229 8.76488C36.1784 11.6001 28.0697 14.9704 22.7707 17.1868L22.6368 16.8642C27.9364 14.6475 36.0455 11.277 42.8901 8.44176C46.3124 7.02411 49.4187 5.74022 51.6998 4.80122C52.8403 4.33173 53.7746 3.94839 54.439 3.67765Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6233 17.4798L18.9712 17.4669L20.6607 63.4574L23.5803 64.2134L23.4933 64.5519L20.3224 63.7307L18.6233 17.4798Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.9299 16.8106L1.40345 15.4186L1.42692 15.0698L21.9534 16.4618C22.6684 16.5103 23.23 17.0956 23.2515 17.8148L25.8036 102.918L25.4556 102.928L22.9035 17.8253C22.8874 17.2859 22.4662 16.847 21.9299 16.8106Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.9095 14.6482L5.10969 13.5339L5.11854 13.3594L26.9183 14.4736C27.6001 14.5085 28.14 15.0648 28.1571 15.7499L30.2159 98.306L30.0419 98.3103L27.9831 15.7543C27.9683 15.1605 27.5004 14.6784 26.9095 14.6482Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.1509 17.6851L1.37383 16.4241L1.41245 15.8136L21.1895 17.0746C21.9292 17.1218 22.5117 17.7264 22.534 18.4702L25.0494 102.368L24.4404 102.386L21.925 18.4886C21.9121 18.0603 21.5768 17.7122 21.1509 17.6851Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.6345 45.776L22.295 46.0274L22.4096 50.6194L20.7478 50.3193L20.6345 45.776ZM20.8137 45.9799L20.9183 50.1725L22.2302 50.4095L22.1247 46.1783L20.8137 45.9799Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.6589 30.9942L21.8822 39.9485L20.49 39.9834L20.2667 31.0292L21.6589 30.9942ZM20.4451 31.1995L20.6597 39.8044L21.7038 39.7781L21.4893 31.1733L20.4451 31.1995Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7705 51.2329L22.4322 51.5331L22.5466 56.12L20.8861 55.8687L20.7705 51.2329ZM20.9499 51.4429L21.0565 55.7177L22.3674 55.9161L22.2618 51.6799L20.9499 51.4429Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.312 58.8812L20.4221 58.2109L20.4417 58.1257L23.3315 58.7961L23.312 58.8812Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.79869 94.2229L4.49677 16.6129L4.67044 16.6009L9.97236 94.2109C10.0044 94.6806 10.3218 95.0818 10.7702 95.2195L24.4379 99.4173L24.3869 99.5844L10.7193 95.3867C10.2019 95.2277 9.83572 94.7649 9.79869 94.2229Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.97314 96.628L10.149 94.7998L10.2356 94.9515L7.05972 96.7796L6.97314 96.628Z"
        fill="black"
      />
      <path
        d="M11.1281 90.6088C11.125 91.0531 10.7926 91.1902 10.3693 91.0839C10.0807 91.0114 9.76201 90.8232 9.50946 90.5512L9.71953 90.2751C9.93768 90.5024 10.1757 90.6503 10.3835 90.7025C10.5836 90.7527 10.6996 90.6937 10.7006 90.5536L10.7006 90.5456C10.7018 90.3814 10.4867 90.2713 10.2486 90.1353C9.94905 89.9719 9.61514 89.7597 9.61793 89.3554L9.61798 89.3474C9.62074 88.9471 9.94182 88.7831 10.3459 88.8846C10.5998 88.9483 10.8763 89.11 11.0908 89.3122L10.9037 89.6101C10.7083 89.4367 10.501 89.3085 10.3317 89.2659C10.1508 89.2205 10.0463 89.2825 10.0455 89.4066L10.0454 89.4146C10.0444 89.5667 10.2633 89.6859 10.5013 89.8258C10.7969 89.9963 11.1308 90.2205 11.1281 90.6008L11.1281 90.6088ZM13.4887 90.7684C13.4838 91.4729 13.0193 91.7533 12.5344 91.6316C12.1996 91.5475 11.9892 91.3182 11.8482 91.0863L11.8415 92.0551L11.3759 91.9382L11.3949 89.184L11.8605 89.3009L11.8581 89.6532C12.0097 89.4707 12.2225 89.3477 12.5496 89.4299C13.0306 89.5506 13.4935 90.0638 13.4887 90.7684ZM13.0153 90.6495C13.0182 90.2332 12.7545 89.9024 12.439 89.8231C12.1234 89.7439 11.8445 89.9425 11.8417 90.3549C11.8388 90.7672 12.114 91.1049 12.4296 91.1842C12.749 91.2644 13.0124 91.0699 13.0153 90.6495ZM15.8581 91.3633C15.854 91.9678 15.3887 92.3522 14.7615 92.1947C14.142 92.0392 13.6874 91.4318 13.6916 90.8274C13.6958 90.2189 14.161 89.8345 14.7844 89.991C15.4078 90.1475 15.8623 90.7549 15.8581 91.3633ZM15.3925 91.2544C15.395 90.8781 15.1393 90.5052 14.7738 90.4134C14.4005 90.3196 14.1598 90.564 14.1573 90.9363C14.1547 91.3126 14.4104 91.6855 14.7721 91.7764C15.1492 91.871 15.3899 91.6267 15.3925 91.2544ZM16.7351 90.9419L16.728 91.9787C16.7267 92.1669 16.8185 92.2661 16.9763 92.3057C17.0802 92.3318 17.1727 92.331 17.2654 92.3061L17.2626 92.7024C17.1467 92.7415 17.0156 92.7487 16.8424 92.7052C16.5 92.6192 16.2586 92.4022 16.2618 91.9379L16.2695 90.825L16.0116 90.7603L16.0145 90.344L16.2723 90.4087L16.2763 89.8282L16.742 89.9452L16.738 90.5256L17.2844 90.6628L17.2815 91.0791L16.7351 90.9419Z"
        fill="white"
      />
      <path
        d="M18.6487 92.4329C18.9359 92.4021 19.0906 92.1158 18.9943 91.7936C18.898 91.4714 18.5872 91.2351 18.3 91.266C18.0128 91.2968 17.8581 91.5831 17.9544 91.9053C18.0507 92.2275 18.3616 92.4637 18.6487 92.4329Z"
        fill="white"
      />
      <path
        d="M19.6677 93.6979C20.0225 93.6598 20.2136 93.3062 20.0946 92.9082C19.9757 92.5101 19.5916 92.2183 19.2369 92.2564C18.8822 92.2945 18.691 92.6481 18.81 93.0462C18.929 93.4442 19.313 93.736 19.6677 93.6979Z"
        fill="white"
      />
      <path
        d="M19.988 92.2292C20.1232 92.2147 20.196 92.08 20.1507 91.9283C20.1053 91.7767 19.959 91.6655 19.8239 91.6801C19.6888 91.6946 19.616 91.8293 19.6613 91.9809C19.7066 92.1325 19.8529 92.2437 19.988 92.2292Z"
        fill="white"
      />
      <path
        d="M19.4165 91.7228C19.653 91.6974 19.7804 91.4616 19.7011 91.1963C19.6218 90.9309 19.3657 90.7364 19.1293 90.7618C18.8928 90.7872 18.7653 91.0229 18.8447 91.2883C18.924 91.5536 19.18 91.7482 19.4165 91.7228Z"
        fill="white"
      />
    </svg>
  );
}
