export enum DeployEnv {
  Production = "production",
  Staging = "staging",
  Local = "local",
}

function inferDeployEnv(envVar: string) {
  if (envVar === DeployEnv.Production) {
    return DeployEnv.Production;
  }
  if (envVar === DeployEnv.Staging) {
    return DeployEnv.Staging;
  }
  return DeployEnv.Local;
}

const hostname =
  typeof window !== "undefined"
    ? window.location.hostname
    : "_WINDOW_NOT_AVAILABLE";

const productionHostnames = new Set([
  "dashboard.spotai.co",
  "dashboard.spot.ai",
]);

const stagingMatchers = [
  // netlify preview URLs
  /^(?:[^/]*--)?dashboard-spotai\.netlify\.app$/,
  // branch subdomains
  /^[^/]+\.dashboard\.spotai\.co$/,
  /^[^/]+\.dashboard\.spot\.ai$/,
];

function inferDeployEnvFromHostname() {
  if (productionHostnames.has(hostname)) {
    return DeployEnv.Production;
  }
  if (stagingMatchers.some((matcher) => matcher.test(hostname))) {
    return DeployEnv.Staging;
  }
  // If we're not in production or staging, we're probably in development.
  return DeployEnv.Local;
}

/**
 * The deploy env is inferred from the hostname by default, but can be overridden
 * by specifying the REACT_APP_DEPLOY_ENV environment variable.
 */
export const deployEnv = process.env.REACT_APP_DEPLOY_ENV
  ? inferDeployEnv(process.env.REACT_APP_DEPLOY_ENV)
  : inferDeployEnvFromHostname();

// Convenience booleans for checking the deploy env.
export const isProductionEnv = deployEnv === DeployEnv.Production;
export const isStagingEnv = deployEnv === DeployEnv.Staging;
export const isLocalEnv = deployEnv === DeployEnv.Local;

const isAndroidDev = hostname.includes("10.0.2.2");
const supportedApiDomains = new Set(["spot.ai", "spotai.co"]);

function inferApiHostFromDeployEnvAndHostname() {
  const domain = hostname.split(".").slice(-2).join(".");

  // Use the current domain for the API host if it's supported, if not
  // use the default API host (spot.ai).
  const apiDomain = supportedApiDomains.has(domain) ? domain : "spot.ai";

  if (isProductionEnv) return `https://api.${apiDomain}`;
  if (isStagingEnv) return `https://api-staging.${apiDomain}`;
  if (isAndroidDev) return `http://10.0.2.2:4000`; // Android emulator webview
  return "http://localhost:4000";
}

/**
 * The API host is inferred from the deploy env by default, but can be overridden
 * by specifying the REACT_APP_API_HOST environment variable.
 */
export const apiHost =
  process.env.REACT_APP_API_HOST || inferApiHostFromDeployEnvAndHostname();

/**
 * Debug mode is enabled by default in development and staging, but can be
 * overridden by specifying the REACT_APP_DEBUG_GA environment variable.
 */
export const debugMode = process.env.REACT_APP_DEBUG_GA
  ? process.env.REACT_APP_DEBUG_GA === "true"
  : !isProductionEnv;
