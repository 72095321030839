import { CircularProgress } from "@mui/material";
import { init, getInstanceByDom, EChartOption } from "echarts";
import type { EChartsOption, ECharts, SetOptionOpts } from "echarts";
import React, { useRef, useEffect } from "react";
import type { CSSProperties } from "react";

export interface ReactEChartsProps {
  option: EChartsOption;
  style?: CSSProperties;
  settings?: SetOptionOpts;
  loading?: boolean;
}

export function ReactECharts({
  option,
  style,
  settings,
  loading,
}: ReactEChartsProps): JSX.Element {
  const chartRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    let chart: ECharts | undefined;
    if (chartRef.current) {
      chart = init(chartRef.current);
    }

    function resizeChart() {
      chart?.resize();
    }
    window.addEventListener("resize", resizeChart);

    return () => {
      chart?.dispose();
      window.removeEventListener("resize", resizeChart);
    };
  }, []);

  useEffect(() => {
    if (chartRef.current) {
      const chart = getInstanceByDom(chartRef.current);
      chart.clear();
      chart.setOption(option as EChartOption, settings);
    }
  }, [option, settings]);

  return (
    <div className="w-full h-full relative">
      {loading && (
        <div className="w-full h-full flex items-center justify-center absolute z-10 bg-transparent">
          <CircularProgress />
        </div>
      )}
      <div
        ref={chartRef}
        style={{
          width: "100%",
          height: "100px",
          opacity: loading ? 0 : 1,
          ...style,
        }}
      />
    </div>
  );
}
