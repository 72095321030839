import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import React, { useState } from "react";

import {
  DeviceStreamSettings,
  useIsInSpecStream,
} from "@/util/validation/stream";

import MaintainDeviceStatusPopover from "@/pages/Maintain/MaintainDeviceStatusPopover";

import { CameraSettingsRecommender } from "@/components/Camera/CameraSettingsRecommender";
import { StatusChip } from "@/components/StatusChip";

import {
  Camera,
  CameraStatus,
  DefaultCameraSettings,
} from "@/generated-models";

import { ApplianceUnreachable } from "./Datagrid/MaintainApplianceDatagrid";
import { MaintainCamera } from "./hooks";

interface MaintainStatusChipProps {
  camera: Pick<Camera, "id" | "status"> & {
    stream: DeviceStreamSettings;
    appliance: {
      serialNumber: string;
      defaultCameraSettings: DefaultCameraSettings;
      health: { online: boolean };
    };
  };
  title?: string;
  loading?: boolean;
}

export function MaintainStatusChip({
  camera,
  title,
  loading,
}: MaintainStatusChipProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [delayHandler, setDelayHandler] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);

  const { status, stream, appliance } = camera;

  const validation = useIsInSpecStream(
    stream,
    appliance.defaultCameraSettings,
    camera as MaintainCamera
  );

  const isOffline = status === CameraStatus.Offline;
  const isOutOfSpec = validation.errors.length > 0;

  const onMouseEnter = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (status === CameraStatus.Offline || isOutOfSpec) {
      setDelayHandler(
        setTimeout(function () {
          setAnchorEl(event.target as HTMLDivElement);
        }, 400)
      );
    }
  };

  const onMouseLeave = () => {
    if (delayHandler) {
      clearTimeout(delayHandler);
    }
  };

  return (
    <div className="flex gap-x-1 items-center">
      <StatusChip
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        status={status}
      />
      <>
        {isOutOfSpec && appliance.health.online && !loading && (
          <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <ReportProblemOutlinedIcon className="text-[#FF9800]" />
          </div>
        )}
      </>
      <MaintainDeviceStatusPopover
        type={title || "Device"}
        open={!!anchorEl}
        anchorEl={anchorEl}
        offline={isOffline}
        onClose={() => setAnchorEl(null)}
      >
        {appliance.health.online ? (
          <CameraSettingsRecommender camera={camera} />
        ) : (
          <ApplianceUnreachable
            selectedSerial={appliance.serialNumber}
            onClick={() => setAnchorEl(null)}
          />
        )}
      </MaintainDeviceStatusPopover>
    </div>
  );
}
