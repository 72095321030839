import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RemoveIcon from "@mui/icons-material/Remove";
import ShareIcon from "@mui/icons-material/Share";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { capitalize, omit, orderBy, sortBy, uniq } from "lodash/fp";
import { parse, stringify } from "query-string";
import React, { useMemo, useState } from "react";
import Highlighter from "react-highlight-words";
import { useLocation, useNavigate } from "react-router-dom";
import { useUpdateEffect } from "react-use";

import { ReactComponent as GroupIcon } from "@/icons/icon_group.svg";
import { ReactComponent as LocationGroupIcon } from "@/icons/icon_group_location.svg";

import { useBreakpoints } from "@/util/useBreakpoints";
import { useLocalStorage } from "@/util/useLocalStorage";
import { usePagination } from "@/util/usePagination";
import { useSlugMatch } from "@/util/useSlugMatch";

import { FixedAspectRatio } from "@/components/FixedAspectRatio";
import { PlayerTooltip } from "@/components/Player/PlayerTooltip";
import { determineLinkSharingActive } from "@/components/Player/SharingLinkDialog";
import { SearchBox } from "@/components/SearchBox";
import { LiveStatusIndicator } from "@/components/StatusIndicators";
import { DefaultDialog, useDialog } from "@/components/shared/Dialog";
import { LazyImage } from "@/components/shared/LazyImage";
import { QueryParamLink } from "@/components/shared/QueryParamLink";

import { isProductionEnv } from "@/environment";
import {
  Camera,
  CameraSharing,
  CameraStatus,
  Location,
  Maybe,
  Tag,
} from "@/generated-models";
import { useCustomScrollbarStyles } from "@/layout/theme";

export interface SelectorGroup {
  id: number;
  name: string;
  isLocationGroup: boolean;
}

interface DesktopGroupSelectProps {
  groups: SelectorGroup[];
  activeGroupId: number | undefined;
  setGroupId: React.Dispatch<number | undefined>;
  className?: string;
}
export function DesktopGroupSelect({
  groups,
  activeGroupId,
  setGroupId,
  className,
}: DesktopGroupSelectProps) {
  if (!groups) return null;
  return (
    <FormControl
      variant="outlined"
      size="small"
      fullWidth
      className={className}
    >
      <InputLabel htmlFor="group-select">Groups & Locations</InputLabel>
      <Select
        value={activeGroupId ?? "__ALL__"}
        onChange={(e) => {
          const newValue = e.target.value;
          setGroupId(newValue === "__ALL__" ? undefined : (newValue as number));
        }}
        variant="outlined"
        label="Groups & Locations"
        inputProps={{ name: "tag", id: "group-select" }}
        className="font-medium"
      >
        <MenuItem value="__ALL__">All Groups & Locations</MenuItem>
        {sortBy([(l) => l.name.toLowerCase()], uniq(groups)).map((t) => (
          <MenuItem value={t.id} key={t.id}>
            <div className="flex items-center">
              {t.isLocationGroup ? (
                <LocationGroupIcon fontSize="small" className="shrink-0" />
              ) : (
                <GroupIcon fontSize="small" className="shrink-0" />
              )}
              <div className="ml-2 truncate">{t.name}</div>
            </div>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

type ListingCamera = Pick<Camera, "id" | "name" | "status"> & {
  location: Pick<Location, "id" | "name">;
  tags: Pick<Tag, "id" | "name">[];
  sharing?: Maybe<Pick<CameraSharing, "expiry">>;
};

export interface CameraNavigationProps {
  groupCams?: ListingCamera[];
  cameras: ListingCamera[];
  activeCamIds: number[];
  onSelect?: () => void;
  children?: React.ReactNode;
}

export function CameraNavigation({
  groupCams,
  cameras,
  activeCamIds,
  onSelect,
  children,
}: CameraNavigationProps) {
  const { classes: scrollbarClasses } = useCustomScrollbarStyles();
  const { fitsDesktop } = useBreakpoints();
  const [searchInput, setSearchInput] = useState("");
  const splitSearchInput = searchInput.split(" ").map((x) => x.toLowerCase());
  const { open, ...dialogProps } = useDialog();
  const location = useLocation();
  const navigate = useNavigate();
  // Used for multi cam feature flag
  const searchRouteMatch = useSlugMatch("search");
  const [favorites] = useLocalStorage<{ locations: number[] }>("favorites", {
    locations: [],
  });

  const orderedCameras = useMemo(() => {
    const result = orderBy(
      ["status", "name"],
      ["desc", "asc"],
      (groupCams ?? cameras).filter((cam) => {
        const searchText = [
          cam.name,
          cam.location.name,
          cam.tags.map((t) => t.name).join(" "),
        ].join(" ");
        const lowerCaseName = searchText.toLowerCase();
        return splitSearchInput.every((search) =>
          lowerCaseName.includes(search)
        );
      })
    );

    if (!isProductionEnv) {
      const fLocations = favorites.locations || [];
      result.sort((a: ListingCamera, b: ListingCamera) => {
        const indexA = fLocations.indexOf(a.location.id);
        const indexB = fLocations.indexOf(b.location.id);
        if (indexA === indexB) return 0;
        return indexB - indexA;
      });
    }

    return result;
  }, [cameras, favorites, groupCams, splitSearchInput]);

  // Client side pagination
  const { pageCount, reset, visibilitySensor } = usePagination(1);
  useUpdateEffect(() => reset(), [orderedCameras.map((c) => c.id).join("")]);

  const cameraLimitReached = activeCamIds.length === 4;

  return (
    <div className="flex flex-col grow h-full">
      <div className="pb-3 px-4 pt-7">
        {children}
        <SearchBox
          input={searchInput}
          setInput={setSearchInput}
          placeholder="Search Cameras"
          fullWidth
        />
      </div>
      <div
        className={clsx("overflow-y-auto", scrollbarClasses.scrollbarContainer)}
      >
        {groupCams?.length === 0 ? (
          <Typography className="mx-4">
            No cameras have been added to this Group yet
          </Typography>
        ) : (
          orderedCameras.slice(0, pageCount * 20).map((cam) => {
            const isActive = activeCamIds.includes(cam.id);
            return (
              <React.Fragment key={cam.id}>
                <Divider className="opacity-60" />
                <div
                  data-cy="camera-nav-item"
                  className={clsx(
                    "flex items-center md:hover:bg-primary md:hover:bg-opacity-5",
                    { "bg-primary bg-opacity-10": isActive }
                  )}
                >
                  <QueryParamLink
                    params={{ cams: cam.id }}
                    removalParams={["f", "m", "zones", "subjects"]}
                    className="flex grow px-4 py-3"
                    onClick={async (e) => {
                      onSelect?.();
                      if (activeCamIds.length > 1) {
                        e.preventDefault();
                        const confirmed = await open();
                        if (confirmed) {
                          // TODO: Would love to do this differently, but don't see any better way right now.
                          const searchParams = parse(location.search);
                          const newSearch = stringify({
                            ...omit(["f", "m", "zones"], searchParams),
                            cams: cam.id,
                          });
                          navigate({ search: newSearch });
                        }
                      }
                    }}
                  >
                    <div
                      className={clsx(
                        "box-content relative w-24 h-14 shrink-0 rounded border border-solid border-gray-400 overflow-hidden",
                        { "border-primary": isActive }
                      )}
                    >
                      <LazyImage
                        className="absolute w-full h-full object-cover object-center left-0 top-0"
                        cameraId={cam.id}
                        alt=""
                      />

                      {isActive ? (
                        <div className="absolute bottom-0 left-0 w-full flex items-center bg-primary">
                          <CheckIcon className="text-white ml-1.5 text-sm" />
                          <Typography
                            variant="caption"
                            className="text-white my-0.5 ml-1 tracking-normal text-2xs"
                          >
                            Selected
                          </Typography>
                        </div>
                      ) : (
                        determineLinkSharingActive(cam.sharing?.expiry) && (
                          <div
                            className="mr-1 absolute bottom-1 left-1 bg-black bg-opacity-50 flex-center w-6 h-6 overflow-hidden"
                            style={{ borderRadius: "50%" }}
                          >
                            <ShareIcon
                              color="secondary"
                              className="text-base"
                            />
                          </div>
                        )
                      )}
                    </div>
                    <div className="ml-3 text-text">
                      <Highlighter
                        highlightClassName="text-primary bg-transparent"
                        searchWords={splitSearchInput}
                        autoEscape={true}
                        textToHighlight={cam.name}
                      />
                      <div className="my-0.5 text-gray-400 text-xs">
                        <Highlighter
                          highlightClassName="text-primary bg-transparent"
                          searchWords={splitSearchInput}
                          autoEscape={true}
                          textToHighlight={cam.location.name}
                        />
                      </div>
                      <div className="flex items-center text-xs">
                        <LiveStatusIndicator status={cam.status} />
                        <span
                          style={
                            cam.status === CameraStatus.Online
                              ? {
                                  color: "#67c21b",
                                }
                              : undefined
                          }
                        >
                          {capitalize(cam.status)}
                        </span>
                      </div>
                    </div>
                  </QueryParamLink>
                  {!!searchRouteMatch &&
                    fitsDesktop &&
                    (isActive ? (
                      <RemoveCameraButton
                        cameraId={cam.id}
                        activeCamIds={activeCamIds}
                        className="bg-primary text-white mx-3 md:hover:bg-opacity-30"
                      >
                        <RemoveIcon />
                      </RemoveCameraButton>
                    ) : (
                      <Tooltip
                        title={
                          cameraLimitReached
                            ? "Camera limit reached"
                            : "Add camera"
                        }
                      >
                        <span>
                          <IconButton
                            component={QueryParamLink}
                            params={{
                              cams: [...activeCamIds, cam.id].join("_"),
                            }}
                            className="bg-gray-200 text-white mx-3 md:hover:bg-primary md:hover:bg-opacity-30"
                            size="small"
                            disabled={cameraLimitReached}
                            classes={{ disabled: "opacity-50" }}
                          >
                            <AddIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                    ))}
                </div>
              </React.Fragment>
            );
          })
        )}
        {visibilitySensor}
      </div>
      <DefaultDialog
        title="Exit Multi-Camera Mode?"
        content={`All currently selected cameras will be removed from the player. If you want to add this camera, click the "Add camera" button with the plus icon.`}
        confirmText="Exit Multi-Camera Mode"
        confirmColor="primary"
        {...dialogProps}
      />
    </div>
  );
}

export function RemoveCameraButton({
  cameraId,
  activeCamIds,
  className,
  children,
}: React.PropsWithChildren<{
  cameraId: number;
  activeCamIds: number[];
  className?: string;
}>) {
  return (
    <PlayerTooltip title="Remove camera">
      <IconButton
        component={QueryParamLink}
        params={
          activeCamIds.length > 1
            ? { cams: activeCamIds.filter((id) => id !== cameraId).join("_") }
            : undefined
        }
        removalParams={
          activeCamIds.length === 1
            ? ["cams", "f", "m", "zones", "subjects"]
            : activeCamIds[0] === cameraId || activeCamIds.length === 2
            ? ["f"]
            : undefined
        }
        className={className}
        size="small"
      >
        {children}
      </IconButton>
    </PlayerTooltip>
  );
}

export interface MobileCameraNavigationProps {
  cameras: ListingCamera[];
  groups: Pick<Tag, "id" | "name">[];
  activeGroup?: Pick<Tag, "id" | "name">;
  activeCamIds: number[];
  onSelect?: () => void;
}

export function MobileCameraNavigation({
  cameras,
  groups,
  activeGroup,
  activeCamIds,
  onSelect,
}: MobileCameraNavigationProps) {
  return (
    <>
      {(activeGroup ? [activeGroup] : orderBy("name", "asc", groups)).map(
        (group) => {
          const cams = orderBy(
            ["status", "name"],
            ["desc", "asc"],
            cameras.filter((c) => c.tags.some((g) => g.id === group.id))
          );
          if (cams.length === 0) return null;

          return (
            <Accordion
              key={group.id}
              square
              elevation={0}
              defaultExpanded
              className={clsx("before:hidden m-0", { "pt-2": !!activeGroup })}
            >
              {!activeGroup && (
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className="text-text" />}
                  className="min-h-[48px]"
                  classes={{ content: "my-3 overflow-hidden" }}
                >
                  <Typography className="font-medium truncate">
                    {group.name}
                  </Typography>
                </AccordionSummary>
              )}
              <AccordionDetails className="grid grid-cols-3 sm:grid-cols-4 gap-x-1.5 gap-y-3 pt-0">
                {cams.map((cam) => {
                  const isActive = activeCamIds.includes(cam.id);
                  return (
                    <QueryParamLink
                      key={cam.id}
                      params={{ cams: cam.id, g: group.id }}
                      removalParams={["f", "m", "zones", "subjects"]}
                      onClick={() => onSelect?.()}
                    >
                      <FixedAspectRatio
                        ratio="9 / 16"
                        className={clsx(
                          "rounded overflow-hidden border",
                          isActive
                            ? "border-primary drop-shadow-primary"
                            : "border-transparent"
                        )}
                      >
                        <LazyImage
                          className="absolute w-full h-full object-cover object-center"
                          cameraId={cam.id}
                          alt=""
                        />
                        {cam.status === CameraStatus.Offline && (
                          <Typography className="absolute top-0.5 right-0.5 px-1.5 py-px bg-[#D70000] bg-opacity-50 text-white text-[8px] font-medium tracking-normal rounded-2xl">
                            Offline
                          </Typography>
                        )}
                        {isActive && (
                          <div className="flex items-center absolute bottom-0 left-0 right-0 h-4 pl-1 gap-1 bg-primary text-white">
                            <CheckIcon className="text-sm" />
                            <Typography className="tracking-normal text-2xs font-medium">
                              Selected
                            </Typography>
                          </div>
                        )}
                      </FixedAspectRatio>
                      <Typography
                        className={clsx(
                          "mt-1 text-xs truncate",
                          isActive ? "text-primary font-medium" : "text-text"
                        )}
                      >
                        {cam.name}
                      </Typography>
                    </QueryParamLink>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          );
        }
      )}
    </>
  );
}
