import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { useState, ChangeEvent } from "react";
import { useLocation } from "react-router-dom";

import { ErrorMessage } from "@/components/ErrorMessage";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";

import {
  IntegrationTypeKey,
  IntegrationVendor,
  IntegrationVendorKey,
  useIntegrationMetadataQuery,
  useIntegrationQuery,
  useUpdateIntegrationMutation,
} from "@/generated-models";

import { IntegrationBrivoVendorForm } from "../Core/IntegrationBrivoVendorForm";
import { IntegrationIntercomVendorForm } from "../Core/IntegrationIntercomVendorForm";
import { IntegrationsAvigilonVendorForm } from "../Core/IntegrationsAvigilonVendorForm";
import IntegrationsCard from "../Core/IntegrationsCard";
import IntegrationsDeleteButton from "../Core/IntegrationsDeleteButton";
import IntegrationsDeviceDatagrid from "../Core/IntegrationsDeviceDatagrid";
import { IntegrationsHaloVendorForm } from "../Core/IntegrationsHaloVendorForm";
import IntegrationsNavigationHeader from "../Core/IntegrationsNavigationHeader";
import { IntegrationsSpotAIVendorInputsForm } from "../Core/IntegrationsSpotAIVendorInputsForm";
import { IntegrationsVendorForm } from "../Core/IntegrationsVendorForm";
import {
  useCurrentIntegrationId,
  useCurrentTypeId,
  useIntegrationSetupState,
} from "../hooks";

function IntegrationsDetailsPagePrompt({
  vendor,
}: {
  vendor: IntegrationVendor;
}) {
  const { name, logoSrc, key } = vendor;
  return (
    <div className="sm:flex items-start justify-between mb-4">
      <img
        className="order-2 mb-2 sm:mb-0 sm:mt-1 max-h-10"
        alt={name}
        src={logoSrc}
      />
      {key !== IntegrationVendorKey.Brivo ? (
        <div className="order-1 flex flex-col gap-2">
          <Typography className="text-lg leading-[21px] font-bold">
            Connection
          </Typography>
          <Typography className="text-sm leading-[16.41px] max-w-lg">
            <span>
              Use your {name} credentials to connect. Make sure you have super
              admin access to {name} integrations.
            </span>
          </Typography>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
}

function IntegrationDetailsDevicesSection() {
  return (
    <div>
      <Typography className="text-lg leading-[21px] font-bold">
        Devices & Linked Cameras
      </Typography>
      <IntegrationsDeviceDatagrid mode="edit" />
    </div>
  );
}

function IntegrationsDetailsPageLoading() {
  return (
    <div className="flex items-center justify-center w-full h-full py-12">
      <CircularProgress size={30} />
    </div>
  );
}

function IntegrationDetailsConnectionSection({
  vendor,
}: {
  vendor: IntegrationVendor;
}) {
  const id = useCurrentIntegrationId();

  const { data, loading: initialStateLoading } = useIntegrationMetadataQuery({
    variables: { input: { id } },
  });

  const { setupState, loading, error } = useIntegrationSetupState(id);

  if (error) {
    return <ErrorMessage title="Error" description={error.toString()} />;
  }

  const isLoading = loading || initialStateLoading;

  if (isLoading) {
    return <IntegrationsDetailsPageLoading />;
  }

  const integrationProps = {
    id: id,
    schema: vendor.setupSchema,
    setupState: setupState,
    initialState: data?.integration?.initialState,
    editMode: true,
  };

  switch (vendor.key) {
    case IntegrationVendorKey.Halo:
      return <IntegrationsHaloVendorForm {...integrationProps} />;

    case IntegrationVendorKey.Brivo:
      return (
        <IntegrationBrivoVendorForm
          callback={() => {}}
          authorizationUrl=""
          editMode
        />
      );

    case IntegrationVendorKey.AvigilonAlta:
      return <IntegrationsAvigilonVendorForm {...integrationProps} />;

    case IntegrationVendorKey.Spot:
      return <IntegrationsSpotAIVendorInputsForm {...integrationProps} />;

    case IntegrationVendorKey.SpotIntercom:
      return <IntegrationIntercomVendorForm {...integrationProps} />;

    default:
      return (
        <IntegrationsVendorForm
          className="mt-2 mb-[30px]"
          {...integrationProps}
        />
      );
  }
}

function IntegrationDetailsNameSection({
  id,
  name = "",
}: {
  id: number;
  name: string;
}) {
  const { pushSnackbar } = useFeedback();
  const [value, setValue] = useState(name);
  const dirty = value !== name;

  const [updateIntegration, { loading }] = useUpdateIntegrationMutation({
    refetchQueries: ["integrationSources"],
    onCompleted: () => {
      pushSnackbar("Integration successfully updated.", FeedbackType.Success);
    },
    onError: () =>
      pushSnackbar(
        "Failed to update the integration, please try again",
        FeedbackType.Error
      ),
  });

  return (
    <div className="flex gap-2 items-center">
      <FormControl className="mt-6 mb-3">
        <InputLabel
          classes={{
            shrink: "-ml-[14px] -mt-1",
          }}
          shrink
          className="text-[#757575] text-xs leading-[14px] capitalize"
          htmlFor="component-simple"
        >
          Name
        </InputLabel>
        <OutlinedInput
          disabled={loading}
          size="small"
          label="Name"
          notched={false}
          className="rounded-lg"
          value={value}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setValue(e.target.value);
          }}
        />
      </FormControl>
      {dirty && (
        <Button
          onClick={() => {
            updateIntegration({
              variables: {
                input: {
                  id,
                  name: value,
                },
              },
            });
          }}
          disabled={loading}
          variant="contained"
          color="primary"
          size="large"
          className="mt-3 shadow-none text-xs leading-[22px] font-normal rounded-md"
        >
          Save
        </Button>
      )}
    </div>
  );
}

export function IntegrationsEditForm() {
  const { pathname } = useLocation();
  const id = useCurrentIntegrationId();
  const typeId = useCurrentTypeId();
  const backPath = pathname.split("/").slice(0, -1).join("/");
  const { data, error, loading } = useIntegrationQuery({
    variables: {
      input: { id },
    },
    skip: !id,
  });

  const integration = data?.integration;

  if (error) {
    return <ErrorMessage title="Error" description={error.toString()} />;
  }

  return (
    <IntegrationsCard>
      <div className="flex items-center justify-between -mt-5 mb-3">
        <IntegrationsNavigationHeader
          title="Integration Details"
          to={backPath}
        />
        <IntegrationsDeleteButton id={id} />
      </div>
      <div className="-mx-8">
        <div
          style={{
            background:
              "linear-gradient(180deg, rgba(0, 0, 0, 0.373989) 0%, rgba(255, 255, 255, 0.0001) 98.13%)",
          }}
          className="opacity-40 w-full h-2"
        />
        {loading && <IntegrationsDetailsPageLoading />}
        {!loading && integration && (
          <div className="p-8">
            <>
              <IntegrationsDetailsPagePrompt vendor={integration.vendor} />
              <IntegrationDetailsConnectionSection
                vendor={integration.vendor}
              />
              <Divider className="my-3" />
            </>
            {integration.name && (
              <>
                <IntegrationDetailsNameSection
                  id={id}
                  name={integration.name}
                />
                <Divider className="my-3" />
              </>
            )}
            {typeId !== IntegrationTypeKey.IoBoard &&
              typeId !== IntegrationTypeKey.Intercom && (
                <IntegrationDetailsDevicesSection />
              )}
          </div>
        )}
      </div>
    </IntegrationsCard>
  );
}
