import { OutlinedInput, Typography } from "@mui/material";
import { useState } from "react";

import { useEditAnnotationLabel } from "../../../copilotAnnotationQueryHooks";
import {
  CopilotLabelMode,
  useCopilotLabelContext,
} from "../useCopilotLabelContext";
import { CopilotFormButton } from "./CopilotFormButton";

export function RenameLabelForm() {
  const { id, label, color, setMode } = useCopilotLabelContext();
  const [value, setValue] = useState(label);
  const { loading, edit } = useEditAnnotationLabel();

  return (
    <div className="flex flex-col gap-[10px]">
      <div className="flex items-center justify-start gap-1">
        <Typography className="text-[13px] font-bold leading-normal font-barlow text-left">
          Rename Label:{" "}
          <span className="uppercase" style={{ color }}>
            {label}
          </span>
        </Typography>
      </div>
      <OutlinedInput
        size="small"
        disabled={loading}
        className="bg-black border border-solid border-primary shadow-[0_0_9px_0_#007CE4] rounded-[26.91px] h-[26px]"
        classes={{
          input: "text-white font-barlow text-sm tracking-[1.26px] uppercase",
        }}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onKeyUp={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      />
      <div className="flex items-center gap-1 w-full">
        <CopilotFormButton
          className="flex-1"
          onClick={() => {
            setMode(CopilotLabelMode.default);
          }}
        >
          Cancel
        </CopilotFormButton>
        <CopilotFormButton
          variant="primary"
          className="flex-1"
          disabled={!value || label === value || loading}
          onMouseDown={() => {
            edit(id, { title: value });
          }}
        >
          Rename
        </CopilotFormButton>
      </div>
    </div>
  );
}
