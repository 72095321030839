const BOM = "\uFEFF";

export function downloadCSV(content: string, filename: string) {
  const link = document.createElement("a");

  const blob = new Blob([BOM + content], { type: "text/csv;charset=utf-8" });
  const url = window.URL.createObjectURL(blob);

  link.setAttribute("href", url);
  link.setAttribute("download", `${filename}.csv`);
  document.body.appendChild(link);

  link.click();
}

/**
 * Uses the basic "download" functionality on anchor tags to trigger a download for an external file.
 * The reason we're using this over FileSaver, is that FileSaver will buffer the entire file in memory.
 * That is a big problem when downloading really large file, e.g. a Case with many long clips. It's better
 * to just let the browser take care of that download. It also allows the user to close the tab once the
 * download has been triggered and the browser will just continue the download.
 */
export function downloadExternalFile(url: string, filename?: string) {
  const a = document.createElement("a");
  a.href = url;
  const download = filename ?? url.split("/").pop();
  if (download) a.download = download;

  document.body.appendChild(a);
  a.click();

  // Remove element on next tick, appearantly firefox like this better
  // https://www.stefanjudis.com/snippets/how-trigger-file-downloads-with-javascript/
  setTimeout(() => {
    a.parentNode?.removeChild(a);
  }, 0);
}
