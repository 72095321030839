import { utcToZonedTime } from "date-fns-tz";
import {
  differenceInMilliseconds,
  format,
  startOfDay,
  subDays,
} from "date-fns/fp";
import { groupBy, memoize, padCharsEnd, clamp } from "lodash/fp";
import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import ResizeObserver from "resize-observer-polyfill";
import { makeStyles } from "tss-react/mui";

import { bestMatch } from "@/util/bestMatch";
import { formatIsoDateWithTimezone } from "@/util/date";
import { filterFalsy } from "@/util/filterFalsy";

import { intelligentFiltersConfig } from "@/pages/Search/intelligence/intelligence";

import { BlockLevel, levels } from "@/components/View/timelineLevels";

interface Marker {
  date: number;
  renderLabel: boolean;
  markerStyle: "small" | "marker" | "block";
}
interface MacroMarker {
  lower: number;
  upper: number;
  label: string;
}

export interface MetaEventGroup {
  id?: keyof typeof intelligentFiltersConfig;
  config?: {
    icon: React.ReactElement;
    color: (opacity?: number) => string;
  };
  label: string;
  groups: EventGroup[];
}

interface EventGroup {
  color: number;
  opacity?: number;
  events: { start: number; end: number }[];
}

const markerStyles = ["small", "marker", "block"] as const;

const barStartTopOffsetPx = 60;
const barSignalHeightPx = 10;
const barPaddingPx = 22;
const barBackgroundHeightPx = 4;
const markerBottomOffsetPx = 54;
// const activeWindowBannerHeight = 24;
const activeWindowHandleSizes = {
  width: 16,
  padding: 10,
};

const useStyles = makeStyles<
  {
    timelineHeight: number;
    activeWindowBannerHeight: number;
  },
  "activeWindowHandle"
>()((theme, { timelineHeight, activeWindowBannerHeight }, classes) => ({
  container: {
    position: "relative",
    height: timelineHeight + activeWindowBannerHeight,
    userSelect: "none",

    cursor: "grab",

    "&:active": {
      cursor: "grabbing",
    },

    "& canvas": {
      // boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.37)",
    },
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: activeWindowBannerHeight,
    left: `50%`,
    width: "100%",
    fontFamily: theme.typography.fontFamily,
    fontSize: 10,
  },
  label: {
    bottom: 2,
    position: "absolute",
    pointerEvents: "none",
  },
  macroLabel: {
    top: 4,
    position: "absolute",
    whiteSpace: "nowrap",
    pointerEvents: "none",
  },
  hoverTimestamp: {
    position: "absolute",
    bottom: 2,
    padding: "0 8px",
    backgroundColor: "white",
    color: theme.palette.primary.main,
    whiteSpace: "nowrap",
    transform: "translateX(-50%)",
    pointerEvents: "none",
    zIndex: 30,
    fontWeight: "bold",

    "&::before": {
      position: "absolute",
      content: '""',
      display: "block",
      width: 1,
      height: timelineHeight,
      background: "rgba(0, 0, 0, .2)",
      bottom: -2,
      left: "50%",
    },
  },

  // activeWindowBanner: {
  //   cursor: "initial",
  //   position: "absolute",
  //   left: 0,
  //   right: 0,
  //   bottom: -activeWindowBannerHeight,
  //   height: activeWindowBannerHeight,
  //   background: "#0f497a",
  //   color: "white",
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "space-between",
  //   paddingLeft: 6,
  //   whiteSpace: "nowrap",
  //   minWidth: 280,
  //   fontSize: 12,
  //   borderRadius: "0 0 6px 6px",
  // },

  activeWindow: {
    [`&:hover .${classes.activeWindowHandle}`]: {
      opacity: 1,
      transitionDelay: "0s",
    },
  },

  activeWindowHandle: {
    opacity: 0,
    transition: "opacity 200ms",
    transitionDelay: "250ms",
    // background: 'rgba(255, 255, 0, .4)', // to debug interactive area
    position: "absolute",
    padding: `0 ${activeWindowHandleSizes.padding}px`,
    height: "100%",
    cursor: "col-resize",
    pointerEvents: "auto",

    "&>div": {
      boxSizing: "border-box",
      backgroundColor: theme.palette.secondary.main,
      border: `1px solid rgba(255, 255, 255, 0.46)`,
      width: activeWindowHandleSizes.width,
      height: "100%",
      transition: "transform 200ms",
      boxShadow: `0 0 4px 0 rgba(0, 0, 0, 0.25)`,
      display: "flex",
      alignItems: "center",

      "&>svg": {
        fill: "white",
      },
    },
  },
}));

function normalizeMarkerSizes({
  blockSize: b,
  markerSize: m,
  smallMarkerSize: s,
}: BlockLevel) {
  return [s, m, b].map((v) => v / s);
}

export interface Bounds {
  lower: number;
  upper: number;
}

export interface CanvasTimelineProps {
  eventGroups?: MetaEventGroup[];
  playhead?: number | null; // Timestamp at which to display the playhead
  debug?: boolean;
  timezone: string;
  bounds: Bounds;
  activeWindow?: Bounds;
  activeWindowToolbar?: React.ReactElement;
  thumbnails?: { timestamp: number; src: string }[];
  className?: string;
  onNavigate?: (viewport: {
    centerTime: number;
    zoomLevel: number;
    bounds: Bounds;
  }) => void;
  onSeek?: (time: number) => void;
  onChangeActiveWindow?: (range: Bounds) => void;
  style?: React.CSSProperties;
}

export function CanvasTimeline(props: CanvasTimelineProps) {
  const timelineHeight =
    (((props.eventGroups?.length || 0) + 2) *
      (barSignalHeightPx + barPaddingPx) +
      barStartTopOffsetPx) /
    2;
  const activeWindowBannerHeight = 0;
  const { classes } = useStyles({ timelineHeight, activeWindowBannerHeight });
  const canvas = useRef<HTMLCanvasElement>(null);
  const debugContainer = useRef<HTMLElement>(null);
  const overlay = useRef<HTMLDivElement>(null);
  const thumbnailsContainer = useRef<HTMLDivElement>(null);

  const timeline = useRef<ReturnType<typeof setupWebGLTimeline>>();

  useEffect(() => {
    timeline.current = setupWebGLTimeline({
      canvas: canvas.current!,
      debugContainer: debugContainer.current,
      overlay: overlay.current!,
      thumbnailsContainer: thumbnailsContainer.current!,
      classNames: classes,
      ...props,
      playhead: props.playhead ?? null, // ensure null is used instead of undefined
      timelineHeight,
    });

    return timeline.current.cleanup;
    // eslint-disable-next-line
  }, []);
  // }, [canvas, debugContainer, overlay]);

  useEffect(() => {
    timeline.current?.setPlayhead(props.playhead ?? null);
  }, [props.playhead]);

  useEffect(() => {
    timeline.current?.setTimezone(props.timezone);
  }, [props.timezone]);

  useEffect(() => {
    timeline.current?.setBounds(props.bounds);
    // eslint-disable-next-line
  }, [props.bounds.lower, props.bounds.upper]);

  useEffect(() => {
    timeline.current?.setActiveWindow(props.activeWindow);
  }, [props.activeWindow]);

  useEffect(() => {
    timeline.current?.setActiveWindowToolbar(props.activeWindowToolbar);
  }, [props.activeWindowToolbar]);

  const replacer = (k: string, v: unknown) => {
    if (k === "icon") {
      return undefined;
    }

    return v;
  };

  useEffect(() => {
    timeline.current?.setEventGroups(props.eventGroups);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(props.eventGroups, replacer)]);

  useEffect(() => {
    timeline.current?.setOnNavigate(props.onNavigate);
  }, [props.onNavigate]);
  useEffect(() => {
    timeline.current?.setOnSeek(props.onSeek);
  }, [props.onSeek]);
  useEffect(() => {
    timeline.current?.setOnChangeActiveWindow(props.onChangeActiveWindow);
  }, [props.onChangeActiveWindow]);
  useEffect(() => {
    timeline.current?.setThumbnails(props.thumbnails);
  }, [props.thumbnails]);
  useEffect(() => {
    timeline.current?.setTimelineHeight(timelineHeight);
  }, [timelineHeight]);

  return (
    <div className={classes.container} style={props.style}>
      <div ref={thumbnailsContainer} className="absolute w-full left-1/2" />
      <div
        className={props.className}
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          overflow: "hidden",

          // boxShadow: "0 2px 7px 0 rgba(0, 0, 0, 0.09)",
          // background: "white",
        }}
      >
        <canvas
          ref={canvas}
          style={{
            width: "100%",
            height: timelineHeight,
          }}
        ></canvas>
        <div ref={overlay} className={classes.overlay}></div>
      </div>
      {props.debug && (
        <code
          style={{ position: "absolute", top: "100%" }}
          ref={debugContainer}
        ></code>
      )}
    </div>
  );
}

const blockWidth = 200; // px

interface WebGLTimelineProps extends CanvasTimelineProps {
  // Internal
  canvas: HTMLCanvasElement;
  debugContainer: HTMLElement | null;
  overlay: HTMLDivElement;
  thumbnailsContainer: HTMLDivElement;
  classNames: ReturnType<typeof useStyles>["classes"];
  timelineHeight: number;

  // Overrides
  playhead: number | null;
}

function setupWebGLTimeline({
  canvas,
  debugContainer: debug,
  overlay,
  thumbnailsContainer,
  classNames,

  // Initial props
  timezone,
  eventGroups = [],
  playhead,
  bounds,
  activeWindow,
  activeWindowToolbar,
  thumbnails,

  // Listeners
  onNavigate,
  onSeek,
  onChangeActiveWindow,

  timelineHeight,
}: WebGLTimelineProps) {
  const container = canvas.parentElement!;
  /** Init GL */
  const gl = initGl(canvas);
  const program = createShaders(gl);
  const uniformNames = ["offset", "yOffset", "zoomFactor", "color"] as const;
  const getUniformLocation = memoize((name: string) =>
    gl.getUniformLocation(program, name)
  );
  function setUniform(
    name: typeof uniformNames[number],
    value: number | number[]
  ) {
    const location = getUniformLocation(name);
    if (typeof value === "number") {
      gl.uniform1f(location, value);
    } else {
      const methodName = `uniform${value.length}fv`;
      (gl as any)[methodName](location, value);
    }
  }
  // const offsetLocation = gl.getUniformLocation(program, "offset");
  // const yOffsetLocation = gl.getUniformLocation(program, "yOffset");
  // const xOffsetPxLocation = gl.getUniformLocation(program, "xOffsetPx");
  // const zoomFactorLocation = gl.getUniformLocation(program, "zoomFactor");
  // const markerColorLocation = gl.getUniformLocation(program, "color");
  // Bookkeeping for webgl drawArrays calls later on
  const markerTypeCounters = {
    small: 0,
    marker: 0,
    block: 0,
  };
  let utcOffset = calculateUtcOffset(timezone);

  /** ============ Public API ============ */
  function setPlayhead(value: number | null) {
    const bounded =
      value && activeWindow
        ? Math.min(activeWindow.upper, Math.max(activeWindow.lower, value))
        : value;
    const delta = bounded && playhead && bounded - playhead;
    playhead = bounded;

    // Scroll viewport along if...
    if (
      playhead &&
      // ...the user isn't currently dragging
      dragStartX === -1 &&
      // ...the playhead "jumps" less than 1 minute
      delta &&
      delta < 60e3
    ) {
      const viewport = getViewport();
      const playheadRight =
        1 -
        (playhead - viewport.bounds.lower) /
          (viewport.bounds.upper - viewport.bounds.lower);
      // ...the playhead plays near the viewport bounds
      if (
        playheadRight < NAV_BOUNDS_PADDING &&
        playheadRight > NAV_BOUNDS_PADDING * 0.5
      ) {
        setCenterTime(playhead - pxToTime(width * (0.5 - NAV_BOUNDS_PADDING)));
      }
    }

    renderDebug();
    updatePlayhead();
    invalidate();
  }
  function setEventGroups(value: MetaEventGroup[] = []) {
    eventGroups = value;
    updateMarkers();
    updateLineLabels();
  }
  function setTimezone(value: string) {
    timezone = value;
    utcOffset = calculateUtcOffset(value);
    updateMarkers();
  }
  function setBounds(value: Bounds) {
    bounds = value;
    // applyNavBounds();
    setViewport(bounds.lower, bounds.upper);
    emitNavigate();
    setVertices();
    invalidate();
  }
  function setActiveWindow(value?: Bounds) {
    activeWindow = value;
    updateActiveWindow();
    // updateActiveWindowBannerText();

    const viewport = getViewport();
    if (value) {
      if (
        value.lower > viewport.bounds.upper ||
        value.upper < viewport.bounds.lower
      ) {
        setCenterTime((value.lower + value.upper) / 2);
      }
    } else if (
      playhead &&
      (playhead > viewport.bounds.upper || playhead < viewport.bounds.lower)
    ) {
      setCenterTime(playhead);
    }
  }
  function setActiveWindowToolbar(value?: React.ReactElement) {
    activeWindowToolbar = value;
    // updateActiveWindowBannerText();
  }
  function setCenterTime(value: number) {
    resetOriginTime(value);
    originOffsetPx = 0;
    applyNavBounds();
    updateMarkers();
  }
  function setTimelineHeight(value: number) {
    timelineHeight = value;
    updateMarkers();
  }
  function cleanup() {
    // Remove listeners
    container.removeEventListener("mousedown", handleMouseDown);
    window.removeEventListener("mousemove", handleDrag);
    window.removeEventListener("mouseup", finishDrag);
    container.removeEventListener("mousemove", handleMouseMove);
    container.removeEventListener("mouseout", handleMouseOut);
    container.removeEventListener("click", handleClick);
    container.removeEventListener("wheel", handleWheel);
    container.removeEventListener("touchstart", handleTouchStart);
    window.removeEventListener("touchend", handleTouchEnd);
    window.removeEventListener("touchmove", handleTouchMove);
    resizeObserver.disconnect();

    // Cancel stepper
    animationFrameId && cancelAnimationFrame(animationFrameId);

    // Clear manually managed DOM elements - should only be relevant for fast refresh
    debug && (debug.innerHTML = "");
    overlay.innerHTML = "";
  }
  function emitNavigate() {
    if (!onNavigate) return;

    onNavigate(getViewport());
  }
  function emitSeek(time: number) {
    if (!onSeek) return;
    if (
      bounds &&
      (time > Math.min(Date.now(), bounds.upper) || time < bounds.lower)
    )
      return;

    onSeek(time);
  }

  // This allows for updating the listeners, which is especially relevant
  // in a React context, where listeners might be defined inline.
  // Not allowing for updating the listeners could result in stale
  // listener bugs.
  // I'm not 100% happy with this implementation, is there a better
  // way?
  function setOnNavigate(listener: typeof onNavigate) {
    onNavigate = listener;
  }
  function setOnSeek(listener: typeof onSeek) {
    onSeek = listener;
  }
  function setOnChangeActiveWindow(listener: typeof onChangeActiveWindow) {
    onChangeActiveWindow = listener;
  }

  const api = {
    cleanup,
    setPlayhead,
    setEventGroups,
    setTimezone,
    setBounds,
    setActiveWindow,
    setActiveWindowToolbar,

    setOnNavigate,
    setOnSeek,
    setOnChangeActiveWindow,
    setThumbnails,
    setTimelineHeight,
  };

  let animationFrameId: number | null = null;
  /** Flag to indicate that we need to redraw on the next tick */
  let invalidated = true;
  function invalidate() {
    invalidated = true;

    if (!animationFrameId) {
      animationFrameId = requestAnimationFrame(step);
    }
  }

  /** ============ Timeline business logic parameters ============ */

  let originTime = playhead || subDays(15, startOfDay(new Date())).getTime();

  /** X offset from the originTime in px */
  let originOffsetPx = 0;

  /**
   * Width of the canvas in pixels
   */
  let width = canvas.offsetWidth;
  let zoomLevel = 4;
  let markers: Marker[] = [];
  let macroMarkers: MacroMarker[] = [];
  let zoomFactorValue: number = getZoomFactor();
  let zoomFactorTarget: number = zoomFactorValue;
  let mouseX: number | null = null;
  let mouseY: number | null = null;
  let mouseTime: number | null = null;

  // Emit an initial navigate
  emitNavigate();

  function resetOriginTime(value: number) {
    originOffsetPx += timeToPx(value - originTime);
    originTime = value;
  }
  /** Get center offset in ms */
  function getCenterOffset() {
    return -originOffsetPx * getSecPerPx() * 1000;
  }
  /** Get center timestamp */
  function getCenterTime() {
    return originTime + getCenterOffset();
  }
  function getViewport() {
    const centerTime = getCenterTime();
    const range = pxToTime(width);
    const halfViewportTimeRange = Math.round(range * 0.5);
    return {
      centerTime,
      zoomLevel,
      range,
      bounds: {
        lower: centerTime - halfViewportTimeRange,
        upper: centerTime + halfViewportTimeRange,
      },
    };
  }
  /**
   * Get current viewport bounds, based on the zoomLevelValue (as opposed to zoomLevel).
   * This makes sure the current tweening state is taken into account
   */
  function getTweenedBounds() {
    const centerTime = getCenterTime();
    const range = 2 / zoomFactorValue;
    const halfViewportTimeRange = Math.round(range * 0.5);
    return {
      lower: centerTime - halfViewportTimeRange,
      upper: centerTime + halfViewportTimeRange,
    };
  }

  const NAV_BOUNDS_PADDING = 0.05; // 5%
  function getNavBounds() {
    const padding = Math.round(pxToTime(activeWindowHandleSizes.width));
    return [bounds.lower - padding, bounds.upper + padding] as const;
  }
  function getSecPerPx() {
    return interpolateBlockSize(zoomLevel) / blockWidth;
  }
  function getMsPerPx() {
    return getSecPerPx() * 1000;
  }
  function timeToPx(ms: number) {
    return ms / getMsPerPx();
  }
  function pxToTime(px: number) {
    return getMsPerPx() * px;
  }
  function xToTime(x: number) {
    return getCenterTime() + pxToTime(x - width * 0.5);
  }
  function getZoomFactor() {
    return 2 / (width * getMsPerPx());
  }
  /**
   * Get the offset value in WebGL coordinates
   */
  function getOffsetValue() {
    return (2 * originOffsetPx) / width;
  }
  function interpolateBlockSize(zoomLevel: number) {
    // Zoom interpolation
    const baseZoomLevel = Math.round(zoomLevel);
    const level = levels[baseZoomLevel];
    const zoomInterpolationFactor = zoomLevel - baseZoomLevel;
    const interpolationTargetZoom =
      baseZoomLevel +
      (zoomInterpolationFactor && (zoomInterpolationFactor > 0 ? 1 : -1));
    const interpolatedBlockSize =
      level.blockSize +
      Math.abs(zoomInterpolationFactor) *
        (levels[interpolationTargetZoom].blockSize - level.blockSize);
    return interpolatedBlockSize;
  }

  function getMaxZoomLevel() {
    return calculateZoomLevelForTimeframe(bounds.lower, bounds.upper);
  }

  function boundedZoomLevel(zoomLevel: number) {
    return Math.min(
      getMaxZoomLevel() || levels.length - 1,
      Math.max(0, zoomLevel)
    );
  }

  function calculateZoomLevelForTimeframe(lower: number, upper: number) {
    const time = upper - lower;
    const widthWithoutPadding = width - activeWindowHandleSizes.width * 2;
    const blockSize = (time * blockWidth) / widthWithoutPadding / 1000;
    const closestLevel = levels.reduce((closest, candidate) => {
      if (
        Math.abs(closest.blockSize - blockSize) <
        Math.abs(candidate.blockSize - blockSize)
      ) {
        return closest;
      } else {
        return candidate;
      }
    });
    const closestZoom = levels.indexOf(closestLevel);
    const interpolationZoom =
      blockSize > closestLevel.blockSize ? closestZoom + 1 : closestZoom - 1;
    if (interpolationZoom > levels.length - 1) return levels.length - 1;
    if (interpolationZoom < 0) return 0;

    const interpolationLevel = levels[interpolationZoom];
    return (
      closestZoom +
      (blockSize - closestLevel.blockSize) /
        Math.abs(closestLevel.blockSize - interpolationLevel.blockSize)
    );
  }

  /**
   * @param lower timestamp in ms
   * @param upper timestamp in ms
   */
  function setViewport(lower: number, upper: number) {
    const time = upper - lower;
    const zoom = calculateZoomLevelForTimeframe(lower, upper);
    originTime = lower + time * 0.5;
    originOffsetPx = 0;
    zoomLevel = boundedZoomLevel(zoom);
    zoomFactorValue = zoomFactorTarget = getZoomFactor();
    updateMarkers();
    updateLabels();
  }

  // setTimeout(() => setViewport(new Date('2020-05-20').getTime(), new Date('2020-05-25').getTime()), 1000);

  /**
   * Normalizes the given timestamp, while defining `originTime` as the reference,
   * i.e. if ts == origin, this function will return 0.
   */
  function normalizeTime(time: number) {
    return time - originTime;
  }

  function colorToVec(color: number, opacity = 1) {
    return [
      (color >> 16) / 0xff,
      ((color >> 8) & 0xff) / 0xff,
      (color & 0xff) / 0xff,
      opacity, // opacity, doesn't seem to work to set this to < 1, not sure why
    ];
  }

  /**
   * Determine markers to render based on the current zoom and offset
   */
  function updateMarkers() {
    const baseZoomLevel = Math.round(zoomLevel);
    const level = levels[baseZoomLevel];
    const interpolatedBlockSize = interpolateBlockSize(zoomLevel);

    const centerTime = getCenterTime();
    const numberOfBlocks = width / blockWidth;
    const visibleSeconds = interpolatedBlockSize * numberOfBlocks;
    const start = centerTime - (visibleSeconds / 2) * 1000;
    // const end = centerTime + (visibleSeconds / 2) * 1000;
    const minMarkerSize = level.smallMarkerSize;
    const blockSizeMs = level.blockSize * 1000;
    const firstBlockStart =
      Math.floor((start + utcOffset) / blockSizeMs) * blockSizeMs - utcOffset;

    const [, m, b] = normalizeMarkerSizes(level);
    markers = Array.from({
      length: Math.ceil((visibleSeconds / minMarkerSize) * 1.5) + 1,
    }).map((_, i) => {
      const date = firstBlockStart + i * level.smallMarkerSize * 1000;
      let renderLabel = false;
      let markerStyle: "small" | "marker" | "block";
      if (i % b === 0) {
        renderLabel = true;
        markerStyle = "block";
      } else if (i % m === 0) {
        markerStyle = "marker";
      } else {
        markerStyle = "small";
      }
      return { date, renderLabel, markerStyle };
    });

    updateMacroMarkers();

    setVertices();
  }

  function roundTimestamp(value: number, rounding: number, offset = 0) {
    return Math.floor((value + offset) / rounding) * rounding - offset;
  }

  function updateMacroMarkers() {
    const {
      bounds: { lower, upper },
    } = getViewport();
    const baseZoomLevel = Math.round(zoomLevel);
    const level = levels[baseZoomLevel];
    if (!level.macroBlockSize || !level.macroBlockLabelFormat) {
      macroMarkers.length = 0;
      return;
    }

    const macroBlockSizeMs = level.macroBlockSize * 1000;
    const firstMacroBlockStart = roundTimestamp(
      lower,
      level.macroBlockSize * 1000,
      utcOffset
    );
    const numberOfMacroBlocks = Math.ceil(
      (upper - firstMacroBlockStart) / macroBlockSizeMs
    );

    macroMarkers = Array.from({ length: numberOfMacroBlocks }).map(
      (_, index) => {
        const time = firstMacroBlockStart + macroBlockSizeMs * index;
        return {
          lower: time,
          upper: time + macroBlockSizeMs,
          label: format(
            level.macroBlockLabelFormat,
            utcToZonedTime(time, timezone)
          ),
        };
      }
    );
  }

  /** ============ Overlay Elements ============ */
  function htmlToElement(html: string) {
    var template = document.createElement("template");
    html = html.trim(); // Never return a text node of whitespace as the result
    template.innerHTML = html;
    return template.content.firstChild as HTMLElement;
  }

  /** ==== Labels ==== */
  const labels = new Map<number, HTMLElement>();
  function updateLabels() {
    const markersWithLabels = markers.filter(
      (m) => m.renderLabel && m.date >= bounds.lower && m.date <= bounds.upper
    );
    // Add labels at the extremes
    // if (markersWithLabels[0].date !== bounds.lower) {
    //   markersWithLabels.unshift({
    //     date: bounds.lower,
    //     markerStyle: "block",
    //     renderLabel: true,
    //   });
    //   const pxDiffFirstLabels = timeToPx(
    //     markersWithLabels[1].date - markersWithLabels[0].date
    //   );
    //   if (pxDiffFirstLabels < 80) {
    //     // If the first and second labels are too close together, remove the second label
    //     markersWithLabels.splice(1, 1);
    //   }
    // }
    // if (markersWithLabels[markersWithLabels.length - 1].date !== bounds.upper) {
    //   markersWithLabels.push({
    //     date: bounds.upper,
    //     markerStyle: "block",
    //     renderLabel: true,
    //   });
    //   const lastIndex = markersWithLabels.length - 1;
    //   const pxDiffLastLabels = timeToPx(
    //     markersWithLabels[lastIndex].date -
    //       markersWithLabels[lastIndex - 1].date
    //   );
    //   if (pxDiffLastLabels < 80) {
    //     // If the last and second to last labels are too close together, remove the second to last
    //     // label
    //     markersWithLabels.splice(lastIndex - 1, 1);
    //   }
    // }

    // 1. removal of obsolete labels
    const blockDates = new Set<number>(markersWithLabels.map((m) => m.date));

    labels.forEach(function deleteOldLabel(labelElement, date) {
      if (!blockDates.has(date)) {
        overlay.removeChild(labelElement);
        labels.delete(date);
      }
    });

    // 2. upsert labels
    markersWithLabels.forEach(function upsertLabel(marker) {
      let element = labels.get(marker.date);
      if (!element) {
        // 2a. Create label element if none exists for the current marker
        element = htmlToElement(`<div class="${classNames.label}"/>`);
        overlay.appendChild(element);
        labels.set(marker.date, element);
      }
      // 2b. Update the label position
      element.style.left = timeToOffsetPercentage(marker.date);

      // 3. Set the label text - the text might update when switching
      // to a different zoom level.
      element.innerHTML = format(
        levels[Math.round(zoomLevel)].blockLabelFormat,
        utcToZonedTime(marker.date, timezone)
      );
    });

    updateMacroLabels();
  }

  const macroLabels = new Map<number, HTMLElement>();
  function updateMacroLabels() {
    const { lower, upper } = getTweenedBounds();
    // 1. removal of obsolete labels
    const blockDates = new Set<number>(macroMarkers.map((m) => m.lower));

    macroLabels.forEach(function deleteOldLabel(labelElement, date) {
      if (!blockDates.has(date)) {
        overlay.removeChild(labelElement);
        macroLabels.delete(date);
      }
    });

    const minWidth = 50; // px
    const minWidthTime = pxToTime(minWidth);

    // 2. upsert labels
    macroMarkers.forEach(function upsertLabel(marker) {
      let element = macroLabels.get(marker.lower);
      if (!element) {
        // 2a. Create label element if none exists for the current marker
        element = htmlToElement(`<div class="${classNames.macroLabel}"/>`);
        overlay.appendChild(element);
        macroLabels.set(marker.lower, element);
      }

      const leftTime = Math.max(
        marker.lower,
        lower + pxToTime(activeWindowHandleSizes.width)
      );
      const rightTime = Math.min(marker.upper, upper + minWidthTime);
      // 2b. Update the label position
      element.style.left =
        marker.lower > lower
          ? timeToOffsetPercentage(leftTime)
          : `calc(-50% + ${activeWindowHandleSizes.width}px)`;
      element.style.right =
        marker.upper < upper + minWidthTime
          ? `${(1 - timeToOffset(rightTime)) * 100}%`
          : "50%";

      // 3. Set the label text - the text might update when switching
      // to a different zoom level.
      element.innerHTML = marker.label;
    });
  }

  /** ==== Playhead ==== */
  let playheadElement = htmlToElement(
    `<div class="absolute w-0.5 bg-secondary h-full z-10 transform -translate-x-1/2 pointer-events-none" />`
  );
  overlay.appendChild(playheadElement);
  function updatePlayhead() {
    if (playhead === null) {
      playheadElement.style.display = "none";
      return;
    }

    playheadElement.style.display = "block";
    playheadElement.style.left = timeToOffsetPercentage(playhead);
  }

  /** ==== Hover timestamp ==== */
  let hoverTimestampElement = htmlToElement(
    `<div class="${classNames.hoverTimestamp}" style="display: none"/>`
  );
  overlay.appendChild(hoverTimestampElement);
  function updateHoverTimestamp() {
    if (mouseTime === null) {
      hoverTimestampElement.style.display = "none";
      return;
    }

    hoverTimestampElement.style.display = "block";
    hoverTimestampElement.style.left = timeToOffsetPercentage(mouseTime);
    hoverTimestampElement.innerHTML = format(
      "MMM dd, hh:mm a",
      utcToZonedTime(mouseTime, timezone)
    );
  }

  let lineLabelContainer = htmlToElement(
    `<div class="bg-white absolute flex flex-col px-1" style="left: calc(-50% + 16px);top: 22px;" />`
  );
  overlay.appendChild(lineLabelContainer);
  function updateLineLabels() {
    if (!lineLabelContainer) {
      return;
    }

    lineLabelContainer.innerHTML = "";
    eventGroups.forEach((group) => {
      const configId = group.id;
      const config = configId
        ? intelligentFiltersConfig[configId]
        : group.config;

      if (config) {
        const iconWrapper = htmlToElement(
          `<div class="flex-center h-[16.5px] py-[2.5px]"/>`
        );
        iconWrapper.style.color = config.color(1);
        ReactDOM.render(config.icon, iconWrapper);
        lineLabelContainer.appendChild(iconWrapper);
      }
    });
  }

  /** ==== Hover thumbnails ==== */
  let hoverThumbnailElement = htmlToElement(
    `<div class="absolute bottom-0 transform -translate-x-1/2" style="display: none"></div>`
  );
  thumbnailsContainer.appendChild(hoverThumbnailElement);
  function updateHoverThumbnail() {
    if (
      mouseTime === null ||
      mouseTime < bounds.lower ||
      mouseTime > Math.min(Date.now(), bounds.upper) ||
      loadedThumbnails.size === 0
    ) {
      hoverThumbnailElement.style.display = "none";
      if (mouseTime != null) loadNextThumbnail();
      return;
    }
    showClosestThumbnail();

    hoverThumbnailElement.style.display = "block";
    hoverThumbnailElement.style.left = timeToOffsetPercentage(mouseTime);

    loadNextThumbnail();
  }
  function showClosestThumbnail() {
    loadedThumbnails.forEach((i) => (i.img.style.display = "none"));
    if (mouseTime === null || loadedThumbnails.size === 0) return;

    const bestImageMatch = bestMatch(
      loadedThumbnails,
      (a, b) =>
        Math.abs(a.timestamp - mouseTime!) - Math.abs(b.timestamp - mouseTime!)
    )!;
    bestImageMatch.img.style.display = "block";
  }
  let thumbnailLoadingQueue = new Set<NonNullable<typeof thumbnails>[number]>();
  let loadedThumbnails = new Set<{
    timestamp: number;
    img: HTMLImageElement;
  }>();
  let thumbnailLoadingIndicators = new Set<HTMLElement>();
  function setThumbnails(value: typeof thumbnails) {
    thumbnails = value;
    // Clear old thumbnails
    hoverThumbnailElement.innerHTML = "";

    thumbnailLoadingQueue = new Set(value);
    loadedThumbnails.clear();
    for (const l of thumbnailLoadingIndicators) {
      thumbnailsContainer.removeChild(l);
    }
    thumbnailLoadingIndicators.clear();
  }
  let loadingNextThumbnailCount = 0;
  function loadNextThumbnail() {
    if (
      !mouseTime ||
      thumbnailLoadingQueue.size === 0 ||
      loadingNextThumbnailCount > 5
    )
      return;
    loadingNextThumbnailCount++;

    const thumbnail = bestMatch(
      thumbnailLoadingQueue,
      (a, b) =>
        Math.abs(a.timestamp - mouseTime!) - Math.abs(b.timestamp - mouseTime!)
    )!;
    thumbnailLoadingQueue.delete(thumbnail);

    const loadingIndicator = htmlToElement(
      '<div class="absolute rounded w-2 h-2 bottom-1 transform -translate-x-1/2" />'
    );
    if (localStorage.timelineStillDebug === "true") {
      loadingIndicator.style.left = timeToOffsetPercentage(thumbnail.timestamp);
      loadingIndicator.style.backgroundColor = "grey";
      thumbnailsContainer.appendChild(loadingIndicator);
      thumbnailLoadingIndicators.add(loadingIndicator);
    }

    const image = document.createElement("img");
    hoverThumbnailElement.appendChild(image);
    image.style.display = "none";
    image.className = "rounded border border-white border-solid shadow-xl";
    image.src = thumbnail.src;
    image.onload = function () {
      hoverThumbnailElement.appendChild(image);
      loadedThumbnails.add({ timestamp: thumbnail.timestamp, img: image });
      showClosestThumbnail();
      loadingIndicator.style.backgroundColor = "green";
      loadingNextThumbnailCount--;
      loadNextThumbnail();
    };
    image.onerror = function () {
      // For now, assume this image is broken and will never be loaded;
      //   1. it's already deleted from the queue
      //   2. never add it to the loadedThumbnails set
      //   3. move on to the next image
      // This will be a problem when we have a short network hiccup. A more robust
      // solution will suspend images that produce errors, and retry them later on.
      loadingIndicator.style.backgroundColor = "red";
      loadingNextThumbnailCount--;
      loadNextThumbnail();
    };
  }

  /** ==== Active Window ==== */
  const activeWindowElement = htmlToElement(
    `<div class="${classNames.activeWindow} bg-secondary bg-opacity-20 absolute h-full" />`
  );

  let activeWindowDragStartX = 0;
  overlay.appendChild(activeWindowElement);
  let activeWindowDragStart: Bounds | undefined;
  enableDragging({
    determineShouldAbort: (event) => event.target !== activeWindowElement,
    dragStartTarget: activeWindowElement,
    startDrag: (pageX: number) => {
      activeWindowDragStartX = pageX;
      activeWindowDragStart = activeWindow && { ...activeWindow };

      blockTimelineDrag = true;
    },
    handleDrag: (pageX: number) => {
      if (!activeWindowDragStart || !activeWindow) return;
      const draggingDistance = getDraggingDistance(
        pageX,
        activeWindowDragStartX
      );
      function mapTime(time: number) {
        return Math.round((time + pxToTime(draggingDistance)) / 60e3) * 60e3;
      }
      activeWindow.lower = clamp(
        bounds.lower,
        activeWindow.upper - minActiveWindowSize,
        mapTime(activeWindowDragStart.lower)
      );
      activeWindow.upper = clamp(
        activeWindow.lower + minActiveWindowSize,
        bounds.upper,
        mapTime(activeWindowDragStart.upper)
      );

      invalidate();
      // updateActiveWindowBannerText();
    },
    finishDrag: (pageX: number) => {
      blockTimelineDrag = false;

      const draggingDistance = getDraggingDistance(
        pageX,
        activeWindowDragStartX
      );

      if (Math.abs(draggingDistance) > 10) {
        emitCurrentActiveWindow();
      }
    },
  });

  function updateActiveWindow() {
    if (!activeWindow) {
      activeWindowElement.style.display = "none";
      return;
    }

    activeWindowElement.style.display = "block";
    activeWindowElement.style.left = timeToOffsetPercentage(activeWindow.lower);
    activeWindowElement.style.width = `${
      (timeToOffset(activeWindow.upper) - timeToOffset(activeWindow.lower)) *
      100
    }%`;

    // This block makes sure the active window banner does not disappear
    // "off the page" when it nears the far right of the page.
    // Setting style.left to 'initial' will align the banner to the right
    // of the active window, and overflow towards the left.
    // const activeWindowWidth = timeToPx(activeWindow.upper - activeWindow.lower);
    // if (activeWindowWidth < 220 && timeToOffset(activeWindow.lower) > 0.25) {
    //   activeWindowBannerElement.style.left = "initial";
    // } else {
    //   activeWindowBannerElement.style.removeProperty("left");
    // }
  }

  // const activeWindowBannerElement = htmlToElement(`
  //   <div class="${classNames.activeWindowBanner}">
  //     <span class="activeWindowBannerText"></span>
  //     <div class="activeWindowBannerToolbar"></div>
  //   </div>`);
  // function updateActiveWindowBannerText() {
  //   if (!activeWindow) return;

  //   const formattedTime = formatVideoTime(
  //     activeWindow.upper - activeWindow.lower
  //   );
  //   activeWindowBannerElement.querySelector(
  //     ".activeWindowBannerText"
  //   )!.innerHTML = `${formattedTime} Clip`;

  //   const activeWindowBannerToolbarElement = activeWindowElement.querySelector(
  //     ".activeWindowBannerToolbar"
  //   )!;
  //   if (activeWindowToolbar) {
  //     ReactDOM.render(activeWindowToolbar, activeWindowBannerToolbarElement);
  //   } else {
  //     ReactDOM.unmountComponentAtNode(activeWindowBannerToolbarElement);
  //   }
  // }
  // activeWindowElement.appendChild(activeWindowBannerElement);
  function addActiveWindowHandle({
    left,
    onDrag,
    onFinishDrag,
    getCurrentTime,
    isUpperBoundary = false,
  }: {
    left: string;
    onDrag: (time: number) => void;
    onFinishDrag: (time: number) => void;
    getCurrentTime: () => number | undefined;
    isUpperBoundary?: boolean;
  }) {
    const handleElement = htmlToElement(
      `<div class="${
        classNames.activeWindowHandle
      }" style="left: ${left}; margin-left: -${
        activeWindowHandleSizes.padding +
        (isUpperBoundary ? 0 : activeWindowHandleSizes.width)
      }px"/>`
    );
    activeWindowElement.appendChild(handleElement);
    const backgroundElement = htmlToElement(`<div>
      <svg height="16" width="16" style="transform: scaleX(${
        isUpperBoundary ? -1 : 1
      }) scale(.7)">
          <polygon points="2,0 2,16 14,8" class="triangle" />
      </svg>
    </div>`);
    handleElement.appendChild(backgroundElement);

    let dragStartX = 0;
    let startTime: number | undefined = 0;

    enableDragging({
      dragStartTarget: handleElement,
      startDrag: (pageX: number) => {
        dragStartX = pageX;
        startTime = getCurrentTime();

        blockTimelineDrag = true;
      },
      handleDrag: (pageX: number) => {
        if (!startTime) return;
        const draggingDistance = getDraggingDistance(pageX, dragStartX);
        onDrag(
          Math.round((startTime + pxToTime(draggingDistance)) / 1000 / 60) *
            1000 *
            60
        );
        invalidate();
      },
      finishDrag: (pageX: number) => {
        blockTimelineDrag = false;

        if (!startTime) return;
        const draggingDistance = getDraggingDistance(pageX, dragStartX);
        onFinishDrag(startTime + pxToTime(draggingDistance));
      },
    });
  }
  function emitCurrentActiveWindow() {
    if (activeWindow && onChangeActiveWindow) {
      onChangeActiveWindow(activeWindow);
    }
  }
  const minActiveWindowSize = 60e3; // 1 minute
  addActiveWindowHandle({
    left: "0",
    onDrag: (time) => {
      if (!activeWindow) return;

      activeWindow.lower = clamp(
        bounds.lower,
        activeWindow.upper - minActiveWindowSize,
        time
      );
      // updateActiveWindowBannerText();
    },
    onFinishDrag: emitCurrentActiveWindow,
    getCurrentTime: () => activeWindow?.lower,
  });
  addActiveWindowHandle({
    left: "100%",
    onDrag: (time) => {
      if (!activeWindow) return;

      activeWindow.upper = clamp(
        activeWindow.lower + minActiveWindowSize,
        bounds.upper,
        time
      );
      // updateActiveWindowBannerText();
    },
    onFinishDrag: emitCurrentActiveWindow,
    getCurrentTime: () => activeWindow?.upper,
    isUpperBoundary: true,
  });

  /**
   * Translates a given time to a fracture:
   *  -0.5 represents the left of the timeline
   * 0 the center
   * 0.5 the right
   **/
  function timeToOffset(time: number) {
    return (zoomFactorValue * normalizeTime(time) + getOffsetValue()) / 2;
  }

  /**
   * Translates a given time to a left percentage, 0% corresponding to
   * the center of the timeline.
   * @param time The time to translate
   */
  function timeToOffsetPercentage(time: number) {
    return timeToOffset(time) * 100 + "%";
  }

  const pad = padCharsEnd("\xa0", 20);
  function debugTime(time: number | null | undefined) {
    return time ? formatIsoDateWithTimezone(new Date(time), timezone) : "";
  }
  function renderDebug() {
    if (!debug) return;
    const navBounds = getNavBounds();
    const viewport = getViewport();
    const playheadRight =
      playhead &&
      1 -
        (playhead - viewport.bounds.lower) /
          (viewport.bounds.upper - viewport.bounds.lower);

    debug.innerHTML = [
      `${pad("Zoom level")}:    ${zoomLevel.toFixed(5) || ""}`,
      `${pad("Max zoom level")}:    ${getMaxZoomLevel()?.toFixed(5) || ""}`,
      `${pad("Mouse X")}:       ${mouseX?.toFixed(5) || ""}`,
      `${pad("Mouse Y")}:       ${mouseY?.toFixed(5) || ""}`,
      `${pad("Mouse Time")}:    ${debugTime(mouseTime)}`,
      `${pad("Center Time")}:   ${debugTime(getCenterTime())}`,
      `${pad("Playhead Time")}: ${debugTime(playhead)}`,
      `${pad("Playhead Right")}: ${playheadRight ? playheadRight * 100 : ""}%`,
      `${pad("Origin Time")}:   ${debugTime(originTime)}`,
      `${pad("Offset")}:        ${originOffsetPx}`,
      `${pad("Viewport")}:      ${debugTime(viewport.bounds?.lower)}`,
      `${pad("")}\xa0           ${debugTime(viewport.bounds?.upper)}`,
      `${pad("Bounds")}:        ${debugTime(bounds?.lower)}`,
      `${pad("")}\xa0           ${debugTime(bounds?.upper)}`,
      `${pad("Nav bounds")}:    ${debugTime(navBounds?.[0])}`,
      `${pad("")}\xa0           ${debugTime(navBounds?.[1])}`,
    ].join("<br />");
  }

  /**
   * @param mouseX Absolute X position of the mouse in px
   */
  function setMouseTime(mouseX: number | null) {
    mouseTime = mouseX && xToTime(mouseX);

    updateHoverTimestamp();
    updateHoverThumbnail();
  }

  /** ============ MOUSE INTERACTION ============ */
  const DRAGGING_THRESHOLD = 10;
  let dragStartX = -1;
  let startOriginOffset = 0;
  let blockTimelineDrag = false; // Set by active window handles
  let mouseDownTime: number | null = null;

  container.addEventListener("mousedown", handleMouseDown);
  function handleMouseDown(event: MouseEvent) {
    // Prevent drag start by using the right mouse button
    if (event.which === 3 || event.button === 2) return;

    dragStartX = event.pageX;
    startOriginOffset = originOffsetPx;
    mouseDownTime = Date.now();

    window.addEventListener("mousemove", handleDrag);
  }

  window.addEventListener("mouseup", finishDrag);
  function finishDrag(event: MouseEvent) {
    window.removeEventListener("mousemove", handleDrag);
    emitNavigate();
  }
  function getDraggingDistance(x: number, dragStartX: number) {
    // Apply dragging threshold, helps with detecting clicks with minimal
    // mouse movement between the `mousedown` and `mouseup` events
    const diff = x - dragStartX;
    return Math.abs(diff) < DRAGGING_THRESHOLD ? 0 : diff;
  }
  function handleDrag(event: MouseEvent) {
    if (blockTimelineDrag) return;
    const draggingDistance = getDraggingDistance(event.pageX, dragStartX);
    originOffsetPx = startOriginOffset + draggingDistance;
    applyNavBounds();
    updateMarkers();
  }

  container.addEventListener("mousemove", handleMouseMove);
  function handleMouseMove(event: MouseEvent) {
    const x =
      event.clientX - (event.currentTarget as any).getBoundingClientRect().left; //x position within the element.
    const y =
      event.clientY - (event.currentTarget as any).getBoundingClientRect().top; //x position within the element.
    // Between 0 and 1
    mouseX = x / width;
    mouseY = y / timelineHeight;
    setMouseTime(x);
    renderDebug();
  }

  container.addEventListener("mouseout", handleMouseOut);
  function handleMouseOut(event: MouseEvent) {
    mouseX = null;
    mouseY = null;
    setMouseTime(null);
    renderDebug();
  }

  function getRelativeX(target: HTMLElement, clientX: number): number {
    return clientX - target.getBoundingClientRect().left;
  }

  function getRelativeY(target: HTMLElement, clientY: number): number {
    return clientY - target.getBoundingClientRect().top;
  }

  // This click listener has been disabled, because with the current timeline
  // design (summer 2020) seeking by clicking is not possible anyway. And this
  // listener prevents propagation when one of the active window toolbar buttons
  // is clicked.
  container.addEventListener("click", handleClick);
  function handleClick(event: MouseEvent) {
    const originDragStartX = dragStartX;

    dragStartX = -1; // Signify that we've stopped dragging

    if (
      getDraggingDistance(event.pageX, originDragStartX) !== 0 ||
      (mouseDownTime && Date.now() - mouseDownTime > 500)
    ) {
      return;
    }

    event.stopPropagation();
    // Ensure we have a mouseTime
    handleMouseMove(event);

    emitSeek(mouseTime!);
  }
  container.addEventListener("wheel", handleWheel);
  function handleWheel(event: WheelEvent) {
    if (!(event.ctrlKey || event.metaKey || event.deltaX)) return;
    event.preventDefault();
    const x = getRelativeX(canvas, event.clientX); //x position within the element.
    const y = getRelativeY(canvas, event.clientY); //x position within the element.
    mouseX = x / width;
    mouseY = y / timelineHeight;
    setMouseTime(x);

    // 1. Reset origin time, so we zoom at the mouse position
    resetOriginTime(mouseTime!);

    // 2a. Set zoomLevel
    zoomLevel = boundedZoomLevel(zoomLevel + event.deltaY / 300);

    // 2b. Pan by horizontally scrolling
    originOffsetPx -= event.deltaX;

    applyNavBounds();

    // 3. Recalculate zoom factor
    //    (set zoomFactorTarget to tween the value)
    zoomFactorTarget = getZoomFactor();

    updateMarkers();
    emitNavigate();
  }

  function applyNavBounds() {
    const navBounds = getNavBounds();
    if (!navBounds) return;
    const viewport = getViewport();

    const lowerShift = Math.max(0, navBounds[0] - viewport.bounds.lower);
    const upperShift = Math.min(0, navBounds[1] - viewport.bounds.upper);

    if (upperShift || lowerShift) {
      if (
        viewport.bounds.upper - viewport.bounds.lower <
        navBounds[1] - navBounds[0]
      ) {
        resetOriginTime(lowerShift ? navBounds[0] : navBounds[1]);
        const shiftMs = upperShift + lowerShift;
        const shiftPx = timeToPx(shiftMs);
        originOffsetPx -= shiftPx;
      } else {
        zoomLevel = getMaxZoomLevel()!;

        zoomFactorTarget = getZoomFactor();
        resetOriginTime(Math.round((navBounds[0] + navBounds[1]) * 0.5));
        originOffsetPx = 0;
      }
    }
  }

  /** ============ TOUCH INTERACTION ============ */
  let startTouches = new Map<number, Touch>();
  let moveTouches = new Map<number, Touch>();
  let startZoomLevel = 0;

  container.addEventListener("touchstart", handleTouchStart);
  function handleTouchStart(event: TouchEvent) {
    event.preventDefault();
    for (let touch of event.targetTouches) {
      startTouches.set(touch.identifier, touch);
      moveTouches.set(touch.identifier, touch);
    }
    startZoomLevel = zoomLevel;

    if (startTouches.size === 1) {
      window.addEventListener("touchmove", handleTouchMove);
      window.addEventListener("touchend", handleTouchEnd);
    } else if (startTouches.size === 2) {
      const [touch1, touch2] = startTouches.values();
      // Set center of zooming to the point between the two touches
      const touch1Time = xToTime(getRelativeX(canvas, touch1.clientX));
      const touch2Time = xToTime(getRelativeX(canvas, touch2.clientX));
      const touchCenterTime = (touch2Time + touch1Time) * 0.5;
      resetOriginTime(touchCenterTime);
      updateMarkers();
    }
    startOriginOffset = originOffsetPx;
  }
  function handleTouchEnd(event: TouchEvent) {
    if (startTouches.size === 0) {
      window.removeEventListener("touchmove", handleTouchMove);
      window.removeEventListener("touchend", handleTouchEnd);
      return;
    }
    // Single finger - touch drag
    event.preventDefault();
    if (startTouches.size - event.changedTouches.length === 0) {
      // Calculate dragging distances for each ended touch
      const draggingDistances = [...event.changedTouches]
        .map((changedTouch) => {
          const startTouch = startTouches.get(changedTouch.identifier);
          return (
            startTouch &&
            getDraggingDistance(event.changedTouches[0].pageX, startTouch.pageX)
          );
        })
        .filter(filterFalsy);
      if (draggingDistances.length && Math.max(...draggingDistances) !== 0) {
        emitNavigate();
      } else {
        emitSeek(
          xToTime(getRelativeX(canvas, event.changedTouches[0].clientX))
        );
      }
    }
    for (let touch of event.changedTouches) {
      startTouches.delete(touch.identifier);
      moveTouches.delete(touch.identifier);
    }
    if (startTouches.size === 1) {
      // When user was touching with 2 fingers and let go of 1, reset the
      // start parameters to the remaining touch point
      startZoomLevel = zoomLevel;
      startOriginOffset = originOffsetPx;
      const moveTouch = moveTouches.values().next().value;
      startTouches.set(moveTouch.identifier, moveTouch);
    }
  }
  function handleTouchMove(event: TouchEvent) {
    event.preventDefault();
    if (blockTimelineDrag) return;

    for (let touch of event.changedTouches) {
      moveTouches.set(touch.identifier, touch);
    }

    if (moveTouches.size === 1) {
      // Single finger - touch drag
      const draggingDistance = getDraggingDistance(
        moveTouches.values().next().value.pageX,
        startTouches.values().next().value.pageX
      );
      if (draggingDistance !== 0) {
        originOffsetPx = startOriginOffset + draggingDistance;
        applyNavBounds();
        updateMarkers();
      }
    } else if (moveTouches.size === 2) {
      const [touch1, touch2] = moveTouches.values();
      // Two fingers - pinch zoom + drag
      const matchedTouches = [touch1, touch2]
        .map((touch) => startTouches.get(touch.identifier))
        .filter(filterFalsy);
      // Check if the two target touches are the same ones that started
      // the 2-touch
      if (matchedTouches.length !== 2) {
        console.warn("Mismatch between touches and target touches", {
          touches: startTouches,
          targetTouches: [touch1, touch2],
        });
        return;
      }

      // Zooming
      const currentDiff = touch1.pageX - touch2.pageX;
      const startDiff = matchedTouches[0].pageX - matchedTouches[1].pageX;
      const pinchFactor = Math.abs(currentDiff / startDiff);
      zoomLevel = boundedZoomLevel(startZoomLevel - Math.log2(pinchFactor));

      // Dragging
      const startCenterX =
        (matchedTouches[0].pageX + matchedTouches[1].pageX) * 0.5;
      const currentCenterX = (touch1.pageX + touch2.pageX) * 0.5;
      originOffsetPx = startOriginOffset + currentCenterX - startCenterX;

      applyNavBounds();

      zoomFactorTarget = zoomFactorValue = getZoomFactor();

      updateMarkers();
    }
  }

  function resizeCanvas() {
    // set the size of the drawingBuffer
    // var devicePixelRatio = 1;
    var devicePixelRatio = window.devicePixelRatio || 1;
    canvas.width = canvas.offsetWidth * devicePixelRatio;
    canvas.height = canvas.offsetHeight * devicePixelRatio;

    gl.viewport(0, 0, canvas.width, canvas.height);

    // Make sure the width we calculate width is not multiplied by the devicePixelRatio
    width = canvas.offsetWidth;

    zoomFactorValue = zoomFactorTarget = getZoomFactor();
    setViewport(bounds.lower, bounds.upper);
    updateMarkers();
    invalidate();
  }
  resizeCanvas();

  const resizeObserver = new ResizeObserver(resizeCanvas);
  resizeObserver.observe(canvas);

  function tween(current: number, target: number, speed = 0.5) {
    if (current === target) return current;
    invalidate();

    const tweenSize = (target - current) * speed;
    if (Math.abs(tweenSize) < 1e-23) {
      return target;
    } else {
      return current + tweenSize;
    }
  }

  function step() {
    zoomFactorValue = tween(zoomFactorValue, zoomFactorTarget);

    if (invalidated) {
      gl.clear(gl.COLOR_BUFFER_BIT);
      draw(getOffsetValue(), zoomFactorValue);

      updateLabels();
      updatePlayhead();
      updateActiveWindow();

      renderDebug();

      animationFrameId = requestAnimationFrame(step);
    } else {
      animationFrameId = null;
    }
    invalidated = false;
  }

  function setVertices() {
    const vertices: number[] = [];

    // 1. Inactive areas
    setInactiveAreaVertices(vertices);

    // 2. Markers
    setMarkerVertices(vertices);

    // 3. Event bars
    setBarVertices(vertices);

    // 4. Flush vertices to WebGL context
    if (vertices.length > 0) {
      gl.bufferData(
        gl.ARRAY_BUFFER,
        new Float32Array(vertices),
        gl.STATIC_DRAW
      );
    }

    invalidate();
  }

  function setInactiveAreaVertices(vertices: number[]) {
    const { bounds: vpBounds, range } = getViewport();
    if (!bounds) return [];
    // Left area
    setRectCoordinates(
      vertices,
      1,
      normalizeTime(bounds.lower),
      -1,
      normalizeTime(vpBounds.lower - range)
    );

    // Right area
    setRectCoordinates(
      vertices,
      1,
      normalizeTime(vpBounds.upper + range),
      -1,
      normalizeTime(Math.min(Date.now(), bounds.upper))
    );
  }

  /**
   * Adds coordinates for two triangles to `vertices` that form a rectangle described
   * by the `top`, `right`, `bottom`, `left` coordinates.
   */
  function setRectCoordinates(
    vertices: number[],
    top: number,
    right: number,
    bottom: number,
    left: number
  ) {
    vertices.push(
      left,
      bottom,
      left,
      top,
      right,
      top,

      left,
      bottom,
      right,
      top,
      right,
      bottom
    );
  }

  function setMarkerVertices(vertices: number[]) {
    const groups = groupBy("markerStyle", markers);
    // Set marker group counts
    markerStyles.forEach((key) => {
      markerTypeCounters[key] = groups[key]?.length || 0;
    });
    markerStyles
      .map((key) => groups[key])
      .filter(Boolean)
      .flat()
      .forEach(({ date, markerStyle }) => {
        const x = normalizeTime(date);
        const y =
          markerStyle === "small" ? 4 / timelineHeight : 10 / timelineHeight;
        const thickness = markerStyle === "block" ? 2 : 1;
        const halfThicknessMs = pxToTime(thickness * 0.5);
        setRectCoordinates(
          vertices,
          y,
          x + halfThicknessMs,
          -y,
          x - halfThicknessMs
        );
      });
  }

  // Event Bars
  function getBarRelativeHeights() {
    const barBackgroundHeight = barBackgroundHeightPx / timelineHeight; // Using this ensures we're rendering entire pixels
    const barHeight = barSignalHeightPx / timelineHeight; // Using this ensures we're rendering entire pixels
    const barPadding = barPaddingPx / timelineHeight; // Using this ensures we're rendering entire pixels
    return {
      barHeight,
      barBackgroundHeight,
      barPadding,
    };
  }

  function setBarVertices(vertices: number[]) {
    const { barHeight, barBackgroundHeight } = getBarRelativeHeights();

    eventGroups.forEach(({ groups }) => {
      const topBackground = barBackgroundHeight / 2;
      // Background
      setRectCoordinates(vertices, topBackground, 1, -topBackground, -1);

      const topSignal = barHeight / 2;
      // Events
      groups.forEach(({ events }) => {
        events.forEach((event) => {
          setRectCoordinates(
            vertices,
            topSignal,
            normalizeTime(event.end),
            -topSignal,
            normalizeTime(event.start)
          );
        });
      });
    });
  }

  /**
   * Execute WebGL draw calls
   **/
  function draw(offsetValue: number, zoomFactorValue: number) {
    const { barHeight, barPadding } = getBarRelativeHeights();
    // eslint-disable-next-line
    let bufferIterator = 0;

    // Inactive areas
    // if (bounds) {
    setUniform("offset", offsetValue);
    setUniform("yOffset", 0);
    setUniform("zoomFactor", zoomFactorTarget);
    bufferIterator = drawRects(2, colorToVec(0, 0.1), bufferIterator);
    // }

    // Top row of markers, make sure not to update the bufferIteratorStart
    // so we can draw the second row of markers
    // drawMarkers(offsetValue, zoomFactorValue, 0.5, bufferIterator);
    // Bottom row of markers
    bufferIterator = drawMarkers(
      offsetValue,
      zoomFactorValue,
      -1 + markerBottomOffsetPx / timelineHeight,
      bufferIterator
    );

    // const barCount = eventGroups.length;
    // const totalBarsHeight = (barHeight + barPadding) * (barCount - 1);
    // To ensure bars aren't offset by subpixels
    // const offset = 1 / timelineHeight;
    eventGroups.forEach((eventGroup, i) => {
      const barOffset = -barStartTopOffsetPx / timelineHeight + 1;
      const barSeparation = i * (barHeight + barPadding);
      const barY = barOffset - barSeparation;
      setUniform("yOffset", barY);
      // Bar background
      setUniform("offset", 0);
      setUniform("zoomFactor", 1);
      bufferIterator = drawRects(1, colorToVec(0, 0.1), bufferIterator);

      // Events
      setUniform("offset", offsetValue);
      setUniform("zoomFactor", zoomFactorValue);
      eventGroup.groups.forEach(({ color, opacity, events }) => {
        bufferIterator = drawRects(
          events.length,
          colorToVec(color, opacity || 1),
          bufferIterator
        );
      });
    });
  }

  /**
   * @param count Number of rects to draw from the gl buffer
   * @param color The fill color of the rects
   * @param bufferIteratorStart The start offset of the gl buffer
   * @returns The buffer iterator after drawing the rects
   */
  function drawRects(
    count: number,
    color: number[],
    bufferIteratorStart: number
  ) {
    let bufferIterator = bufferIteratorStart;
    setUniform("color", color);
    for (let i = 0; i < count; i++) {
      gl.drawArrays(gl.TRIANGLES, bufferIterator, 3 * 2);
      bufferIterator += 3 * 2;
    }
    return bufferIterator;
  }

  function drawMarkers(
    offsetValue: number,
    zoomFactorValue: number,
    yOffsetValue: number,
    bufferIteratorStart = 0
  ) {
    let bufferIterator = bufferIteratorStart;
    setUniform("offset", offsetValue);
    setUniform("yOffset", yOffsetValue);
    setUniform("zoomFactor", zoomFactorValue);

    // Small markers
    if (markerTypeCounters.small) {
      bufferIterator = drawRects(
        markerTypeCounters.small,
        colorToVec(0x979797),
        bufferIterator
      );
    }

    // Normal markers
    if (markerTypeCounters.marker) {
      bufferIterator = drawRects(
        markerTypeCounters.marker,
        colorToVec(0x979797),
        bufferIterator
      );
    }

    // Block markers
    if (markerTypeCounters.block) {
      bufferIterator = drawRects(
        markerTypeCounters.block,
        colorToVec(0x5f5f5f),
        bufferIterator
      );
    }

    // Return state of the buffer iterator, so `draw()` knows where to continue
    return bufferIterator;
  }

  // Kick off rendering
  invalidate();

  return api;
}

function initGl(canvas: HTMLCanvasElement) {
  const gl = canvas.getContext("webgl", {
    premultipliedAlpha: false,
  })!;
  gl.enable(gl.BLEND);
  gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
  gl.clearColor(1, 1, 1, 1);

  const vertexBuffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, vertexBuffer);

  return gl;
}

function createShaders(gl: WebGLRenderingContext) {
  // Set up vertex and fragment shaders
  const vertexShaderSource = `
    attribute vec2 coordinates;
    uniform float zoomFactor;
    uniform float offset;
    uniform float yOffset;

    void main() {
      gl_Position = vec4(zoomFactor * coordinates.x + offset, coordinates.y + yOffset, 0.0, 1.0);
    }
  `;
  const vertexShader = gl.createShader(gl.VERTEX_SHADER)!;
  gl.shaderSource(vertexShader, vertexShaderSource);
  gl.compileShader(vertexShader);

  const fragmentShaderSource = `
    precision mediump float;
    uniform vec4 color;

    void main() {
      gl_FragColor = vec4(color);
    }
  `;
  const fragmentShader = gl.createShader(gl.FRAGMENT_SHADER)!;
  gl.shaderSource(fragmentShader, fragmentShaderSource);
  gl.compileShader(fragmentShader);

  const shaderProgram = gl.createProgram()!;
  gl.attachShader(shaderProgram, vertexShader);
  gl.attachShader(shaderProgram, fragmentShader);
  gl.linkProgram(shaderProgram);
  gl.useProgram(shaderProgram);

  // Wire "coordinates" attribute - this is the flat array of coordinates for the markers
  const coordinates = gl.getAttribLocation(shaderProgram, "coordinates");
  gl.enableVertexAttribArray(coordinates);
  gl.vertexAttribPointer(
    coordinates,
    2,
    gl.FLOAT,
    false,
    2 * Float32Array.BYTES_PER_ELEMENT,
    0
  );
  return shaderProgram;
}

function calculateUtcOffset(timezone: string) {
  const now = new Date();
  return differenceInMilliseconds(
    utcToZonedTime(now, "UTC"),
    utcToZonedTime(now, timezone)
  );
}

function enableDragging({
  dragStartTarget,
  startDrag,
  handleDrag,
  finishDrag,
  determineShouldAbort = () => false,
}: {
  dragStartTarget: HTMLElement;
  startDrag: (pageX: number) => void;
  finishDrag: (pageX: number) => void;
  handleDrag: (pageX: number) => void;
  determineShouldAbort?: (event: MouseEvent | TouchEvent) => boolean;
}) {
  /**
   * ==============
   * Mouse events
   * ==============
   **/
  dragStartTarget.addEventListener("mousedown", handleMouseDown);

  function handleMouseDown(event: MouseEvent) {
    if (determineShouldAbort(event)) return;

    // Prevent drag start by using the right mouse button
    if (event.which === 3 || event.button === 2) return;

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
    startDrag(event.pageX);
  }
  function handleMouseUp(event: MouseEvent) {
    window.removeEventListener("mousemove", handleMouseMove);
    window.removeEventListener("mouseup", handleMouseUp);

    finishDrag(event.pageX);
  }

  function handleMouseMove(event: MouseEvent) {
    handleDrag(event.pageX);
  }

  /**
   * ==============
   * Touch events
   * ==============
   **/
  let touchIdentifier = -1;
  dragStartTarget.addEventListener("touchstart", handleTouchStart);
  function handleTouchStart(event: TouchEvent) {
    if (determineShouldAbort(event)) return;

    event.stopPropagation();
    event.preventDefault();
    touchIdentifier = event.targetTouches[0].identifier;
    startDrag(event.targetTouches[0].pageX);

    window.addEventListener("touchend", handleTouchEnd);
    window.addEventListener("touchmove", handleTouchMove);
  }
  function handleTouchEnd(event: TouchEvent) {
    const touch = [...event.changedTouches].find(
      (t) => t.identifier === touchIdentifier
    );
    if (!touch) return;
    finishDrag(touch.pageX);
    window.removeEventListener("touchend", handleTouchEnd);
    window.removeEventListener("touchmove", handleTouchMove);
  }
  function handleTouchMove(event: TouchEvent) {
    const touch = [...event.changedTouches].find(
      (t) => t.identifier === touchIdentifier
    );
    if (!touch) return;
    handleDrag(touch.pageX);
  }
}
