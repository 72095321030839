import { throttle } from "lodash";
import { useMemo } from "react";

export function useThrottle<T extends (...args: any) => any>(
  callback: T,
  wait: number
) {
  return useMemo(
    () => throttle(callback, wait, { leading: true, trailing: false }),
    [wait, callback]
  );
}
