import { GlobalStyles } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import { ReactNode, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  IntercomProvider as ReactIntercomProvider,
  useIntercom,
} from "react-use-intercom";

import { useBreakpoints } from "@/util/useBreakpoints";

import { useMe } from "../Auth";

const intercomAppId = process.env.REACT_APP_INTERCOM ?? "vsszk7z3";

function IntercomListener({ children }: { children: ReactNode }) {
  const { update } = useIntercom();
  const location = useLocation();

  useEffect(() => {
    update({
      lastRequestAt: new Date().getTime() / 1000,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return <>{children}</>;
}

export function IntercomProvider({ children }: { children: ReactNode }) {
  const [open, setOpen] = useState(false);
  const { fitsDesktop } = useBreakpoints();
  const { intercomSupport } = useFlags();
  const me = useMe();

  return (
    <>
      <GlobalStyles
        styles={{
          ".intercom-launcher": {
            display: `${fitsDesktop ? "block" : "none"} !important`,
          },
          ".intercom-namespace": {
            display: `${!fitsDesktop && !open ? "none" : "block"} !important`,
          },
        }}
      />
      <ReactIntercomProvider
        appId={intercomAppId}
        shouldInitialize={!!me && !!intercomAppId && !!intercomSupport}
        onShow={() => {
          setOpen(true);
        }}
        onHide={() => {
          setOpen(false);
        }}
        autoBoot
        autoBootProps={{
          email: me?.email,
          userId: `${me?.id}`,
          userHash: me?.intercomHash,
          name: me?.name,
          phone: me?.phone,
          actionColor: "#007ce4",
        }}
      >
        <IntercomListener>{children}</IntercomListener>
      </ReactIntercomProvider>
    </>
  );
}
