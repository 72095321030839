import { Button, Typography } from "@mui/material";
import clsx from "clsx";
import { Link } from "react-router-dom";

import { SpotConnect } from "@/icons/SpotConnect";

import { useBreakpoints } from "@/util/useBreakpoints";

import { usePrefixOrgSlug } from "@/hooks/useOrgRouteBase";
import { MobileHeader } from "@/layout/MobileHeader";

import IntegrationsCard from "../../../IntegrationsOld/Core/IntegrationsCard";
import { IntegrationCreateForm } from "./IntegrationCreateForm";

function IntegrationsCreatePageHeader() {
  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center justify-start gap-3 -ml-2">
        <SpotConnect className="h-7 w-7" />

        <Typography className="text-2xl leading-[28px]">
          <strong>Spot Connect:</strong> Create Custom Integration
        </Typography>
      </div>
      <Button
        className="leading-[16px] text-[#757575]"
        component={Link}
        to=".."
      >
        Cancel
      </Button>
    </div>
  );
}

export function IntegrationCreatePage() {
  const { fitsDesktop } = useBreakpoints();
  const prefixOrgSlug = usePrefixOrgSlug();

  return (
    <>
      <MobileHeader
        to={prefixOrgSlug("/integrations")}
        label="Spot Connect: Create Custom Integration"
      />
      <IntegrationsCard
        className={clsx({
          "-my-8": !fitsDesktop,
        })}
      >
        {fitsDesktop && <IntegrationsCreatePageHeader />}
        <div className="-mx-8">
          {fitsDesktop && (
            <div
              style={{
                background:
                  "linear-gradient(180deg, rgba(0, 0, 0, 0.373989) 0%, rgba(255, 255, 255, 0.0001) 98.13%)",
              }}
              className="opacity-40 w-full h-2 mt-6"
            />
          )}
          <div className="px-9 py-8">
            <div className="flex flex-col gap-2">
              <Typography className="font-bold text-lg leading-[21px]">
                Welcome to Spot Connect.
              </Typography>
              <Typography className="text-base leading-[18.75px] w-[540px]">
                Use Spot Connect to create a custom integration and connect Spot
                AI to your system. Name and select the type of integration,
                define events, and sync with your existing system.
              </Typography>
              <IntegrationCreateForm />
            </div>
          </div>
        </div>
      </IntegrationsCard>
    </>
  );
}
