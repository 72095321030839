import { Add, Search, SvgIconComponent } from "@mui/icons-material";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import PeopleIcon from "@mui/icons-material/People";
import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";
import { useLocalStorage } from "react-use";

import { LPRIcon } from "@/icons/LPRIcon";
import { ReactComponent as CompareIcon } from "@/icons/compare.svg";
import { ReactComponent as Forklift } from "@/icons/forklift.svg";

import { useAIModelEnabled } from "@/util/featureToggles";

import { AdditionalAiClass } from "@/generated-models";

import { useLprEnabled } from "./hooks";

export enum CountDirection {
  In = "in",
  Out = "out",
  InAndOut = "in_out",
}

export enum IntelligenceInsightType {
  People = "people",
  Vehicle = "vehicle",
  Forklift = "forklift",
  LPR = "lpr",
  Comparative = "comparative",
}

export const CountDirectionLabel: Record<string, string> = {
  in: "In",
  out: "Out",
  in_out: "In & Out",
};

export enum IntelligenceFeatureType {
  ComparativeInsights = "ComparativeInsights",
  LicensePlateInterestLists = "LicensePlateInterestLists",
  LicensePlateReports = "LicensePlateReports",
  LicensePlateSearch = "LicensePlateSearch",
  PeopleCounting = "PeopleCounting",
  PeopleIdleTime = "PeopleIdleTime",
  PeoplePresence = "PeoplePresence",
  VehicleCounting = "VehicleCounting",
  VehicleIdleTime = "VehicleIdleTime",
  VehiclePresence = "VehiclePresence",
  ForkliftCounting = "ForkliftCounting",
  ForkliftIdleTime = "ForkliftIdleTime",
  ForkliftPresence = "ForkliftPresence",
}

export const CountingFeatureTypes = [
  IntelligenceFeatureType.PeopleCounting,
  IntelligenceFeatureType.VehicleCounting,
  IntelligenceFeatureType.ForkliftCounting,
];

export type EntityType = "vehicle" | "people" | "forklift";

export const EntityLabel = {
  ComparativeInsights: "vehicle" as EntityType,
  LicensePlateInterestLists: "vehicle" as EntityType,
  LicensePlateReports: "vehicle" as EntityType,
  LicensePlateSearch: "vehicle" as EntityType,
  PeopleCounting: "people" as EntityType,
  PeoplePresence: "people" as EntityType,
  PeopleIdleTime: "people" as EntityType,
  VehicleCounting: "vehicle" as EntityType,
  VehicleIdleTime: "vehicle" as EntityType,
  VehiclePresence: "vehicle" as EntityType,
  ForkliftCounting: "forklift" as EntityType,
  ForkliftIdleTime: "forklift" as EntityType,
  ForkliftPresence: "forklift" as EntityType,
};

export const EntityColors = {
  people: ["#9947FF", "#4A00A9"],
  vehicle: ["#37D3F5", "#00AFD6"],
  forklift: ["#FFB55F", "#e07901"],
};

export interface IntelligenceInsightConfig {
  colorAccent: string;
  colorBase: string;
  colorPrompt: string;
  hidden?: boolean;
  icon: React.ReactNode;
  illustrationPrompt: string;
  label: string;
  labelCreate: string;
  labelEmpty: string;
  labelPrompt: string;
  features: IntelligenceFeatureType[];
}

// Used for rendering overview controls & prompts.
export const useIntelligenceInsightConfig: () => Record<
  IntelligenceInsightType,
  IntelligenceInsightConfig
> = () => {
  const forkliftEnabled = useAIModelEnabled(AdditionalAiClass.Forklift);
  const lprUi = useLprEnabled();
  const { lprInterests, comparativeInt } = useFlags();
  const [intro] = useLocalStorage("comparativeIntro", false);

  return {
    people: {
      colorAccent: "#F8F2FF",
      colorBase: "#6500E4",
      colorPrompt: "#FAF7FF",
      icon: <PeopleIcon style={{ display: "inline" }} width={28} height={28} />,
      illustrationPrompt: "/intelligence/people-insights-illustration.svg",
      label: "People",
      labelCreate: "Create People Insight",
      labelEmpty: "People Insights",
      labelPrompt:
        "Gain insights into your business operations by knowing when, where, and how many people are present.",
      features: [
        IntelligenceFeatureType.PeopleCounting,
        IntelligenceFeatureType.PeopleIdleTime,
        IntelligenceFeatureType.PeoplePresence,
      ],
    },
    vehicle: {
      colorAccent: "#F1FDFF",
      colorBase: "#00AFD6",
      colorPrompt: "#EAF9FF",
      icon: (
        <DirectionsCarIcon
          style={{ display: "inline" }}
          width={28}
          height={28}
        />
      ),
      illustrationPrompt: "/intelligence/vehicle-insights-illustration.svg",
      label: "Vehicles",
      labelCreate: "Create Vehicle Insight",
      labelEmpty: "Vehicle Insights",
      labelPrompt:
        "Improve operations by knowing when, where and how many vehicles are present and how long they are stationary.",
      features: [
        IntelligenceFeatureType.VehicleCounting,
        IntelligenceFeatureType.VehicleIdleTime,
        IntelligenceFeatureType.VehiclePresence,
      ],
    },
    forklift: {
      colorAccent: "#FFFAF4",
      colorBase: "#E07901",
      colorPrompt: "#FFF2E3",
      hidden: !forkliftEnabled,
      icon: <Forklift style={{ display: "inline" }} width={28} height={28} />,
      illustrationPrompt: "/intelligence/forklift-insights-illustration.svg",
      label: "Forklifts",
      labelCreate: "Create Forklift Insight",
      labelEmpty: "Forklift Insights",
      labelPrompt:
        "Increase efficiency by knowing when, where and how many forklifts are present and how long they are stationary.",
      features: [
        IntelligenceFeatureType.ForkliftCounting,
        IntelligenceFeatureType.ForkliftIdleTime,
        IntelligenceFeatureType.ForkliftPresence,
      ],
    },
    lpr: {
      colorAccent: "#F0FFFE",
      colorBase: "#00A69C",
      colorPrompt: "#F5FFFB",
      hidden: !lprUi,
      icon: <LPRIcon />,
      illustrationPrompt: "/intelligence/lpr-illustration.svg",
      label: "License Plates",
      labelCreate: "Create License Plate Insight",
      labelEmpty: "License Plate Recognition",
      labelPrompt:
        "Search for vehicles by license plate to determine when incidents occur, and browse and track license plates with custom reports.",
      features: [
        IntelligenceFeatureType.LicensePlateReports,
        ...(lprInterests
          ? [IntelligenceFeatureType.LicensePlateInterestLists]
          : []),
        IntelligenceFeatureType.LicensePlateSearch,
      ],
    },
    comparative: {
      colorAccent: "#DDEFFF",
      colorBase: "#007CE4",
      colorPrompt: "#F5FFFB",
      hidden: !comparativeInt || (comparativeInt && !intro),
      icon: <CompareIcon />,
      illustrationPrompt: "",
      label: "Compare Insights",
      labelCreate: "",
      labelEmpty: "",
      labelPrompt: "",
      features: [],
    },
  };
};

export interface IntelligenceFeatureConfigData {
  action: {
    icon: SvgIconComponent;
    label: string;
    linkNew: string;
    legacyLabelMobile: string;
  };
  background: {
    colorPrimary: string;
    colorSecondary: string;
    colorTertiary: string;
    colorBase: string;
  };
  border: {
    // Deprecated
    legacyPrimary: string;
    legacySecondary: string;
  };
  foreground: {
    colorPrimary: string;
  };

  illustration: {
    form?: string;
    default: string;
    copilot?: string;
    // Deprecated, new designs don't use mists or legacy illustrations.
    legacy: string;
    legacyIcon: React.ReactNode;
    legacyMobile: string;
    legacyMist: string;
    legacyMobileMist: string;
  };
  label: {
    formDescription?: React.ReactNode;
    description: string;
    descriptionCondensed?: string;
    displayName: string;
    // Deprecated
    legacySecondaryDisplayName: string;
  };
  type: IntelligenceFeatureType;
}

export const IntelligenceFeatureConfig: Record<
  IntelligenceFeatureType,
  IntelligenceFeatureConfigData
> = {
  [IntelligenceFeatureType.LicensePlateInterestLists]: {
    action: {
      icon: Add,
      label: "Create New",
      linkNew: "interestList/new",
      legacyLabelMobile: "Create New",
    },
    background: {
      colorPrimary: "bg-[#00A69C]",
      colorSecondary: "bg-[#F5FFFB]",
      colorTertiary: "bg-[#F5FFFB]",
      colorBase: "bg-[#F7F0FD]",
    },
    border: {
      legacyPrimary: "border-b-[#F7F0FD]",
      legacySecondary: "border-[#6BD9C5] border-[0.3px]",
    },
    foreground: {
      colorPrimary: "text-[#00A69C]",
    },
    illustration: {
      form: "/intelligence/form/lpr-interest.svg",
      default: "/intelligence/lpr-interest-illustration.svg",
      legacy: "",
      legacyIcon: <></>,
      legacyMobile: "",
      legacyMist: "",
      legacyMobileMist: "",
    },
    label: {
      formDescription: (
        <p>
          To create a <strong>License Plate of Interest List</strong>, first
          name the list, select cameras, add license plates, and if needed,
          adjust days and times tracked.
        </p>
      ),
      description:
        "Create lists of license plates of interest and follow their visits over time.",
      displayName: "License Plates of Interest",
      legacySecondaryDisplayName: "",
    },
    type: IntelligenceFeatureType.LicensePlateInterestLists,
  },
  [IntelligenceFeatureType.LicensePlateReports]: {
    action: {
      icon: Add,
      label: "Create New",
      linkNew: "report/new",
      legacyLabelMobile: "Create New",
    },
    background: {
      colorPrimary: "bg-[#00A69C]",
      colorSecondary: "bg-[#F5FFFB]",
      colorTertiary: "bg-[#F5FFFB]",
      colorBase: "bg-[#F7F0FD]",
    },
    border: {
      legacyPrimary: "border-b-[#F7F0FD]",
      legacySecondary: "border-[#6BD9C5] border-[0.3px]",
    },
    foreground: {
      colorPrimary: "text-[#00A69C]",
    },
    illustration: {
      form: "/intelligence/form/lpr-report.svg",
      default: "/intelligence/license-plate-reports-illustration.svg",
      legacy: "",
      legacyIcon: <></>,
      legacyMobile: "",
      legacyMist: "",
      legacyMobileMist: "",
    },
    label: {
      formDescription: (
        <p>
          To create a <strong>License Plate Recognition Dashboard</strong>,
          first name the dashboard, select cameras, and if needed, adjust days
          and times tracked.
        </p>
      ),
      description:
        "Track vehicles over time by license plate number and determine when incidents occur.",
      displayName: "LPR Report",
      legacySecondaryDisplayName: "",
    },
    type: IntelligenceFeatureType.LicensePlateReports,
  },
  [IntelligenceFeatureType.LicensePlateSearch]: {
    action: {
      icon: Search,
      label: "Search",
      linkNew: "license-plate",
      legacyLabelMobile: "Search Plates",
    },
    background: {
      colorPrimary: "bg-[#00A69C]",
      colorSecondary: "bg-[#F5FFFB]",
      colorTertiary: "bg-[#FCF9FF]",
      colorBase: "bg-[#F7F0FD]",
    },
    border: {
      legacyPrimary: "border-b-[#F7F0FD]",
      legacySecondary: "border-[#6BD9C5] border-[0.3px]",
    },
    foreground: {
      colorPrimary: "text-[#00A69C]",
    },
    illustration: {
      default: "/intelligence/license-plate-search-illustration.svg",
      legacy: "/lprIllustration.svg",
      legacyIcon: <></>,
      legacyMobile: "/lprMobileIllustration.svg",
      legacyMist: "/lprBackMist.svg",
      legacyMobileMist: "lprBackMobileMist.svg",
    },
    label: {
      description: "Search vehicles by license plate number.",
      displayName: "License Plate Search",
      legacySecondaryDisplayName: "License Plates",
    },
    type: IntelligenceFeatureType.LicensePlateSearch,
  },
  [IntelligenceFeatureType.PeopleCounting]: {
    action: {
      icon: Add,
      label: "Create New",
      linkNew: "count/new?type=people",
      legacyLabelMobile: "Create New",
    },
    background: {
      colorPrimary: "bg-[#6500E4]",
      colorSecondary: "bg-[#F8F3FF]",
      colorTertiary: "bg-[#FCF9FF]",
      colorBase: "bg-[#F7F0FD]",
    },
    border: {
      legacyPrimary: "border-b-[#F7F0FD]",
      legacySecondary: "border-[#DABCFF] border-[0.3px]",
    },
    foreground: {
      colorPrimary: "text-[#6500E4]",
    },
    illustration: {
      form: "/intelligence/form/people-count.svg",
      default: "/intelligence/people-counting-illustration.svg",
      copilot: "/intelligence/copilot/people-counting-illustration.svg",
      legacy: "",
      legacyIcon: <PeopleIcon />,
      legacyMobile: "",
      legacyMist: "",
      legacyMobileMist: "",
    },
    label: {
      formDescription: (
        <p>
          To create a <strong>People Count Dashboard</strong>, first name the
          dashboard, select a camera, then draw a line that people cross to
          count.
        </p>
      ),
      description:
        "Count people entering your building, leaving your business or walking past a certain location.",
      descriptionCondensed: "Count people using this selected zone",
      displayName: "People Counting",
      legacySecondaryDisplayName: "",
    },
    type: IntelligenceFeatureType.PeopleCounting,
  },
  [IntelligenceFeatureType.PeoplePresence]: {
    action: {
      icon: Add,
      label: "Create New",
      linkNew: "presence/new?type=people",
      legacyLabelMobile: "Create New",
    },
    background: {
      colorPrimary: "bg-[#6500E4]",
      colorSecondary: "bg-[#F8F3FF]",
      colorTertiary: "bg-[#FCF9FF]",
      colorBase: "bg-[#F7F0FD]",
    },
    border: {
      legacyPrimary: "border-b-[#F7F0FD]",
      legacySecondary: "border-[#DABCFF] border-[0.3px]",
    },
    foreground: {
      colorPrimary: "text-[#6500E4]",
    },
    illustration: {
      form: "/intelligence/form/people-presence.svg",
      copilot: "/intelligence//copilot/people-presence-illustration.svg",
      default: "/intelligence/people-presence-illustration.svg",
      legacy: "/PresenceIllustration.svg",
      legacyIcon: <PeopleIcon />,
      legacyMobile: "/PresenceMobileIllustration.svg",
      legacyMist: "/presenceBackMist.svg",
      legacyMobileMist: "presenceBackMobileMist.svg",
    },
    label: {
      formDescription: (
        <p>
          To create a <strong>People Presence Dashboard</strong>, first name the
          dashboard, select a camera, then select the minimum threshold for
          tracking.
        </p>
      ),
      description:
        "Understand how often your customers or employees are in a designated place and for how long.",
      descriptionCondensed:
        "How often people are in a designated place, and for how long.",
      displayName: "People Presence",
      legacySecondaryDisplayName: "People Presence",
    },
    type: IntelligenceFeatureType.PeoplePresence,
  },
  [IntelligenceFeatureType.PeopleIdleTime]: {
    action: {
      icon: Add,
      label: "Create New",
      linkNew: "idle/new?type=people",
      legacyLabelMobile: "Create New",
    },
    background: {
      colorPrimary: "bg-[#6500E4]",
      colorSecondary: "bg-[#F8F3FF]",
      colorTertiary: "bg-[#FCF9FF]",
      colorBase: "bg-[#F7F0FD]",
    },
    border: {
      legacyPrimary: "border-b-[#F7F0FD]",
      legacySecondary: "border-[#DABCFF] border-[0.3px]",
    },
    foreground: {
      colorPrimary: "text-[#6500E4]",
    },
    illustration: {
      default: "/intelligence/people-idle-time-illustration.svg",
      copilot: "/intelligence//copilot/people-idle-time-illustration.svg",
      form: "/intelligence/form/people-presence.svg",
      legacy: "/PresenceIllustration.svg",
      legacyIcon: <PeopleIcon />,
      legacyMobile: "/PresenceMobileIllustration.svg",
      legacyMist: "/presenceBackMist.svg",
      legacyMobileMist: "presenceBackMobileMist.svg",
    },
    label: {
      formDescription: (
        <p>
          To create a <strong>People Idle Time Dashboard</strong>, first name
          the dashboard, select a camera, then set the idle time threshold.
        </p>
      ),
      description:
        "Gain insights into how many people are stationary and for how long.",
      descriptionCondensed: "How many people are stationary, and for how long.",
      displayName: "People Idle Time",
      legacySecondaryDisplayName: "People Idle Time",
    },
    type: IntelligenceFeatureType.PeopleIdleTime,
  },
  [IntelligenceFeatureType.VehicleCounting]: {
    action: {
      icon: Add,
      label: "Create New",
      linkNew: "count/new?type=vehicle",
      legacyLabelMobile: "Create New",
    },
    background: {
      colorPrimary: "bg-[#00AFD6]",
      colorSecondary: "bg-[#EAF9FF]",
      colorTertiary: "bg-[#F5FDFF]",
      colorBase: "bg-[#E6F5F9]",
    },
    border: {
      legacyPrimary: "border-b-[#E6F5F9]",
      legacySecondary: "border-[#84E9FF] border-[0.3px]",
    },
    foreground: {
      colorPrimary: "text-[#00AFD6]",
    },
    illustration: {
      form: "/intelligence/form/vehicle-count.svg",
      default: "/intelligence/vehicle-counting-illustration.svg",
      copilot: "/intelligence/copilot/vehicle-counting-illustration.svg",
      legacy: "",
      legacyIcon: <DirectionsCarIcon />,
      legacyMobile: "",
      legacyMist: "",
      legacyMobileMist: "",
    },
    label: {
      formDescription: (
        <p>
          To create a <strong>Vehicle Count Dashboard</strong>, first name the
          dashboard, select a camera, then draw a line that vehicles cross to
          count.
        </p>
      ),
      description:
        "Count vehicles entering your facility, leaving your parking lot or driving down the street.",
      descriptionCondensed: "Count vehicles using this selected zone",
      displayName: "Vehicle Counting",
      legacySecondaryDisplayName: "",
    },
    type: IntelligenceFeatureType.VehicleCounting,
  },
  [IntelligenceFeatureType.VehicleIdleTime]: {
    action: {
      icon: Add,
      label: "Create New",
      linkNew: "idle/new",
      legacyLabelMobile: "Create New",
    },
    background: {
      colorPrimary: "bg-[#00AFD6]",
      colorSecondary: "bg-[#EAF9FF]",
      colorTertiary: "bg-[#F5FDFF]",
      colorBase: "bg-[#E6F5F9]",
    },
    border: {
      legacyPrimary: "border-b-[#E6F5F9]",
      legacySecondary: "border-[#84E9FF] border-[0.3px]",
    },
    foreground: {
      colorPrimary: "text-[#00AFD6]",
    },
    illustration: {
      form: "/intelligence/form/vehicle-idle-time.svg",
      default: "/intelligence/vehicle-idle-time-illustration.svg",
      copilot: "/intelligence/copilot/vehicle-idle-time-illustration.svg",
      legacy: "/IdleTimeIllustration.svg",
      legacyIcon: <DirectionsCarIcon />,
      legacyMobile: "/IdleTimeMobileIllustration.svg",
      legacyMist: "/idleBackMist.svg",
      legacyMobileMist: "idleBackMobileMist.svg",
    },
    label: {
      formDescription: (
        <p>
          To create a <strong>Vehicle Idle Time Dashboard</strong>, first name
          the dashboard, select a camera, then set the idle time threshold.
        </p>
      ),
      description:
        "Understand how many of your vehicles are stationary and for how long.",
      descriptionCondensed:
        "How many vehicles are stationary, and for how long.",
      displayName: "Vehicle Idle Time",
      legacySecondaryDisplayName: "Vehicle Idle Time",
    },
    type: IntelligenceFeatureType.VehicleIdleTime,
  },
  [IntelligenceFeatureType.VehiclePresence]: {
    action: {
      icon: Add,
      label: "Create New",
      linkNew: "presence/new?type=vehicle",
      legacyLabelMobile: "Create New",
    },
    background: {
      colorPrimary: "bg-[#00AFD6]",
      colorSecondary: "bg-[#EAF9FF]",
      colorTertiary: "bg-[#F5FDFF]",
      colorBase: "bg-[#E6F5F9]",
    },
    border: {
      legacyPrimary: "border-b-[#E6F5F9]",
      legacySecondary: "border-[#84E9FF] border-[0.3px]",
    },
    foreground: {
      colorPrimary: "text-[#00AFD6]",
    },
    illustration: {
      form: "/intelligence/form/vehicle-idle-time.svg",
      default: "/intelligence/vehicle-presence-illustration.svg",
      copilot: "/intelligence/copilot/vehicle-presence-illustration.svg",
      legacy: "",
      legacyIcon: <DirectionsCarIcon />,
      legacyMobile: "",
      legacyMist: "",
      legacyMobileMist: "",
    },
    label: {
      formDescription: (
        <p>
          To create a <strong>Vehicle Presence Dashboard</strong>, first name
          the dashboard, select a camera, then select the minimum threshold for
          tracking.
        </p>
      ),
      description:
        "Gain insights into your business operations by knowing when vehicles are present and absent.",
      descriptionCondensed:
        "How often vehicles are in a designated place, and for how long.",
      displayName: "Vehicle Presence",
      legacySecondaryDisplayName: "Vehicle Presence",
    },
    type: IntelligenceFeatureType.VehiclePresence,
  },
  [IntelligenceFeatureType.ForkliftCounting]: {
    action: {
      icon: Add,
      label: "Create New",
      linkNew: "count/new?type=forklift",
      legacyLabelMobile: "Create New",
    },
    background: {
      colorPrimary: "bg-[#e07901]",
      colorSecondary: "bg-[#fff8f0]",
      colorTertiary: "bg-[#fff8f0]",
      colorBase: "bg-[#ffead1]",
    },
    border: {
      legacyPrimary: "border-b-[#ffead1]",
      legacySecondary: "border-[#e07901] border-[0.3px]",
    },
    foreground: {
      colorPrimary: "text-[#e07901]",
    },
    illustration: {
      default: "/intelligence/forklift-counting-illustration.svg",
      copilot: "/intelligence/copilot/forklift-counting-illustration.svg",
      form: "/intelligence/form/forklift-insights-illustration.svg",
      legacy: "",
      legacyIcon: (
        <Forklift style={{ display: "inline" }} width={28} height={28} />
      ),
      legacyMobile: "",
      legacyMist: "",
      legacyMobileMist: "",
    },
    label: {
      formDescription: (
        <p>
          To create a <strong>Forklift Count Dashboard</strong>, first name the
          dashboard, select a camera, then draw a line that forklifts cross to
          count.
        </p>
      ),
      description: "Count forklifts entering or exiting your facility.",
      descriptionCondensed: "Count forklifts using this selected zone",
      displayName: "Forklift Counting",
      legacySecondaryDisplayName: "",
    },
    type: IntelligenceFeatureType.ForkliftCounting,
  },
  [IntelligenceFeatureType.ForkliftIdleTime]: {
    action: {
      icon: Add,
      label: "Create New",
      linkNew: "idle/new?type=forklift",
      legacyLabelMobile: "Create New",
    },
    background: {
      colorPrimary: "bg-[#e07901]",
      colorSecondary: "bg-[#fff8f0]",
      colorTertiary: "bg-[#fff8f0]",
      colorBase: "bg-[#ffead1]",
    },
    border: {
      legacyPrimary: "border-b-[#ffead1]",
      legacySecondary: "border-[#e07901] border-[0.3px]",
    },
    foreground: {
      colorPrimary: "text-[#e07901]",
    },
    illustration: {
      default: "/intelligence/forklift-idle-time-illustration.svg",
      copilot: "/intelligence/copilot/forklift-idle-time-illustration.svg",
      form: "/intelligence/form/forklift-insights-illustration.svg",
      legacy: "/IdleTimeIllustration.svg",
      legacyIcon: (
        <Forklift style={{ display: "inline" }} width={28} height={28} />
      ),
      legacyMobile: "/IdleTimeMobileIllustration.svg",
      legacyMist: "/idleBackMist.svg",
      legacyMobileMist: "idleBackMobileMist.svg",
    },
    label: {
      formDescription: (
        <p>
          To create a <strong>Forklift Idle Time Dashboard</strong>, first name
          the dashboard, select a camera, then set the idle time threshold.
        </p>
      ),
      description:
        "Understand how many of your forklifts are stationary and for how long.",
      descriptionCondensed:
        "How many forklifts are stationary, and for how long.",
      displayName: "Forklift Idle Time",
      legacySecondaryDisplayName: "Forklift Idle Time",
    },
    type: IntelligenceFeatureType.ForkliftIdleTime,
  },
  [IntelligenceFeatureType.ForkliftPresence]: {
    action: {
      icon: Add,
      label: "Create New",
      linkNew: "presence/new?type=forklift",
      legacyLabelMobile: "Create New",
    },
    background: {
      colorPrimary: "bg-[#e07901]",
      colorSecondary: "bg-[#fff8f0]",
      colorTertiary: "bg-[#fff8f0]",
      colorBase: "bg-[#ffead1]",
    },
    border: {
      legacyPrimary: "border-b-[#ffead1]",
      legacySecondary: "border-[#e07901] border-[0.3px]",
    },
    foreground: {
      colorPrimary: "text-[#e07901]",
    },
    illustration: {
      default: "/intelligence/forklift-presence-illustration.svg",
      form: "/intelligence/form/forklift-insights-illustration.svg",
      copilot: "/intelligence/copilot/forklift-presence-illustration.svg",
      legacy: "",
      legacyIcon: (
        <Forklift style={{ display: "inline" }} width={28} height={28} />
      ),
      legacyMobile: "",
      legacyMist: "",
      legacyMobileMist: "",
    },
    label: {
      formDescription: (
        <p>
          To create a <strong>Forklift Presence Dashboard</strong>, first name
          the dashboard, select a camera, then select the minimum threshold for
          tracking.
        </p>
      ),
      description:
        "Gain insights into your business operations by knowing when forklifts are present and absent.",
      descriptionCondensed:
        "How often forklifts are in a designated place, and for how long.",
      displayName: "Forklift Presence",
      legacySecondaryDisplayName: "Forklift Presence",
    },
    type: IntelligenceFeatureType.ForkliftPresence,
  },
  [IntelligenceFeatureType.ComparativeInsights]: {
    action: {
      icon: Add,
      label: "Create New",
      linkNew: "compound/new",
      legacyLabelMobile: "Create New",
    },
    background: {
      colorPrimary: "bg-[#007ce4]",
      colorSecondary: "bg-[#f6fbff]",
      colorTertiary: "bg-[#f6fbff]",
      colorBase: "bg-[#ffead1]",
    },
    border: {
      legacyPrimary: "border-b-[#ffead1]",
      legacySecondary: "border-[#007ce4] border-[0.3px]",
    },
    foreground: {
      colorPrimary: "text-[#007ce4]",
    },
    illustration: {
      default: "",
      form: "",
      legacy: "",
      legacyIcon: <></>,
      legacyMobile: "",
      legacyMist: "",
      legacyMobileMist: "",
    },
    label: {
      formDescription: <></>,
      description: "",
      displayName: "Comparative Insight",
      descriptionCondensed:
        "How often forklifts are in a designated place, and for how long.",
      legacySecondaryDisplayName: "Comparative Insight",
    },
    type: IntelligenceFeatureType.ComparativeInsights,
  },
};

export const HeatmapColorValues = {
  compound: {
    min: 213,
    max: 302,
    legend: "from-[#FF30F8] via-[#B47AFB] to-[#4498FF]",
  },
  interestList: {
    min: 213,
    max: 302,
    legend: "from-[#FF30F8] via-[#B47AFB] to-[#4498FF]",
  },
  report: {
    min: 213,
    max: 302,
    legend: "from-[#FF30F8] via-[#B47AFB] to-[#4498FF]",
  },
  count: {
    min: 213,
    max: 302,
    legend: "from-[#FF30F8] via-[#B47AFB] to-[#4498FF]",
  },
  idle: {
    min: 192,
    max: 66,
    legend: "from-[#E5FF00] via-[#8EF253] to-[#04CCFE]",
  },
  presence: {
    min: 213,
    max: 302,
    legend: "from-[#FF30F8] via-[#B47AFB] to-[#4498FF]",
  },
};
