import { CircularProgress, Typography } from "@mui/material";
import clsx from "clsx";
import { useFlags } from "launchdarkly-react-client-sdk";
import uniq from "lodash/uniq";

import { useLprReportGrouped, useLprResolvedVisits } from "./hooks";

function LicensePlateReportStatistic({
  className,
  label,
  value,
}: {
  className?: string;
  label: string;
  value: number;
}) {
  return (
    <Typography
      className={clsx("text-base leading-[18.75px] text-[#00A69C]", className)}
    >
      <strong>{value}</strong> {label}
    </Typography>
  );
}

export function LicensePlateReportCardPreview({
  dashboardId,
}: {
  dashboardId: number;
}) {
  const { lprMmct } = useFlags();
  const { data, error, loading } = useLprReportGrouped(dashboardId);
  const plates = data?.lprReport || [];
  const total = plates.reduce((n, { visits }) => n + visits, 0);
  const makes = uniq(plates.map((d) => d.make));
  const models = uniq(plates.map((d) => d.model));
  const colors = uniq(plates.map((d) => d.colorId));
  const types = uniq(plates.map((d) => d.typeId));
  const resolvedVisits = useLprResolvedVisits(dashboardId);

  const maxVisits = Math.max(...Object.values(resolvedVisits));

  if (error) {
    return (
      <div className="h-40 md:h-52 flex-center">
        <div>No available data</div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="h-[140px] flex-center">
        <CircularProgress size={40} />
      </div>
    );
  }

  return (
    <div className="flex flex-col justify-center items-center gap-2 h-full">
      <LicensePlateReportStatistic
        label="Unique license plates"
        value={Object.keys(resolvedVisits).length}
      />
      <LicensePlateReportStatistic
        label="Most visits by a single plate"
        value={maxVisits >= 0 ? maxVisits : 0}
      />
      <LicensePlateReportStatistic
        label="Number of vehicles total"
        value={total}
      />
      {lprMmct ? (
        <div className="flex flex-row justify-start divide-x divide-[#91D8D4] first:pl-3 text-[#00A69C]">
          <LicensePlateReportStatistic
            className="pr-3"
            label={makes.length > 1 ? "Makes" : "Make"}
            value={makes.length}
          />
          <LicensePlateReportStatistic
            className="px-3"
            label={models.length > 1 ? "Models" : "Model"}
            value={models.length}
          />
          <LicensePlateReportStatistic
            className="px-3"
            label={colors.length > 1 ? "Colors" : "Color"}
            value={colors.length}
          />
          <LicensePlateReportStatistic
            className="px-3"
            label={types.length > 1 ? "Types" : "Type"}
            value={types.length}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
