import { Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { BooleanParam, useQueryParam } from "use-query-params";

import { MaintainDetailTile } from "@/pages/Maintain/Details/MaintainDetailsTile";

import { GeniusPopup } from "@/components/Genius/GeniusPopup";

import { CameraDetailByIdQuery } from "@/generated-models";
import { usePermissions } from "@/hooks/usePermissions";

function NVRField({
  label,
  value,
}: {
  label: string;
  value?: string | number;
}) {
  return (
    <Typography className="text-sm">
      {label}: <span className="font-bold">{value}</span>
    </Typography>
  );
}

export function MaintainDetailsConfigNvrTile({
  camera,
}: {
  camera: CameraDetailByIdQuery["camera"];
}) {
  const [configure, setConfigure] = useQueryParam("configure", BooleanParam);
  const [popupDeviceId, setPopupDeviceId] = useState<number | null>(null);
  const hasPermission = usePermissions();
  const { appliance, device, vendor } = camera;
  const { ip, mac, id } = device;

  const cameraCount = appliance?.cameras.length || 0;

  useEffect(() => {
    if (configure) {
      setPopupDeviceId(id);
    }
  }, [configure, id]);

  return (
    <MaintainDetailTile title="NVR Connection">
      <div className="flex flex-col sm:flex-row gap-6 w-full justify-between mt-4">
        <NVRField label="IP" value={ip} />
        <span className="sm:block hidden border-l border-solid border-[#C4C4C4] px-1"></span>
        <NVRField label="MAC" value={`${mac} (${vendor})`} />
        <span className="sm:block hidden border-l border-solid border-[#C4C4C4] px-1"></span>
        <NVRField label="Cameras" value={cameraCount} />
      </div>
      <Button
        fullWidth
        size="small"
        color="primary"
        variant="outlined"
        className="mt-8 mb-4"
        disabled={!hasPermission("devices_manage")}
        onClick={() => {
          setPopupDeviceId(id);
        }}
      >
        Configure NVR
      </Button>
      {popupDeviceId && (
        <GeniusPopup
          onClose={() => {
            setPopupDeviceId(null);
            setConfigure(false);
          }}
          deviceId={popupDeviceId}
        />
      )}
    </MaintainDetailTile>
  );
}
