import { Monaco } from "@monaco-editor/react";
import { uniq } from "lodash/fp";

import { Page_AssistantQuery } from "@/generated-models";

import {
  ASSISTANT_COMMANDS,
  ASSISTANT_RESOURCE,
  SPOT_ASSIST_LANG,
} from "./constant";
import { getPromptSuggestions } from "./suggestions/prompts";

export function registerAssistantCompletionProvider(
  monaco: Monaco,
  cameras: Page_AssistantQuery["cameras"],
  appliances: Page_AssistantQuery["appliances"],
  getLocationId: () => number | null
) {
  return monaco.languages.registerCompletionItemProvider(SPOT_ASSIST_LANG, {
    provideCompletionItems: async (model, position) => {
      const locationId = await getLocationId();

      const word = model.getWordUntilPosition(position);
      const range = {
        startLineNumber: position.lineNumber,
        endLineNumber: position.lineNumber,
        startColumn: word.startColumn,
        endColumn: word.endColumn,
      };

      const locations = uniq(
        cameras.map((cam) => cam.location).flat()
      ).sort((a, b) => a.name.localeCompare(b.name));

      const promptSuggestions = getPromptSuggestions(monaco, range);

      const locationSuggestions = locations.map((l) => ({
        label: `[Location] (${l.name})`,
        kind: monaco.languages.CompletionItemKind.Variable,
        insertText: l.name,
        insertTextRules:
          monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        documentation: `Location (${l.name})`,
        detail: `Location (${l.name})`,
        command: {
          id: ASSISTANT_COMMANDS.INJECT_RESOURCE,
          arguments: [ASSISTANT_RESOURCE.location, l.name, l, range],
        },
        range,
      }));

      const cameraSuggestions = cameras
        .filter((c) => c.location.id === locationId)
        .map((c) => ({
          label: `[Camera] (${c.name}) (${c.location.name})`,
          kind: monaco.languages.CompletionItemKind.Variable,
          insertText: c.name,
          insertTextRules:
            monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: `Camera (${c.name}) (${c.location.name})`,
          detail: `Camera (${c.name}) (${c.location.name})`,
          command: {
            id: ASSISTANT_COMMANDS.INJECT_RESOURCE,
            arguments: [ASSISTANT_RESOURCE.camera, c.name, c, range],
          },
          range,
        }));

      const applianceSuggestions = appliances
        .filter((c) => c.location.id === locationId)
        .map((a) => ({
          label: `[Appliance] (${a.serialNumber}) (${a.location.name})`,
          kind: monaco.languages.CompletionItemKind.Variable,
          insertText: a.serialNumber,
          insertTextRules:
            monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: `Appliance (${a.serialNumber}) (${a.location.name})`,
          detail: `Appliance(${a.serialNumber}) (${a.location.name})`,
          command: {
            id: ASSISTANT_COMMANDS.INJECT_RESOURCE,
            arguments: [ASSISTANT_RESOURCE.appliance, a.serialNumber, a, range],
          },
          range,
        }));

      const suggestionType = new URLSearchParams(window.location.search).get(
        "suggestion-type"
      );

      let suggestions = [];

      switch (suggestionType) {
        case ASSISTANT_RESOURCE.prompt:
          suggestions = promptSuggestions;
          break;
        case ASSISTANT_RESOURCE.appliance:
          suggestions = applianceSuggestions;
          break;
        case ASSISTANT_RESOURCE.camera:
          suggestions = cameraSuggestions;
          break;
        case ASSISTANT_RESOURCE.location:
          suggestions = locationSuggestions;
          break;
        default:
          suggestions = [
            ...promptSuggestions,
            ...applianceSuggestions,
            ...cameraSuggestions,
            ...locationSuggestions,
          ];
      }

      return { suggestions: suggestions };
    },
  });
}
