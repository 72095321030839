import CircleIcon from "@mui/icons-material/Circle";
import { Chip, IconButton, Typography } from "@mui/material";
import clsx from "clsx";
import { useState } from "react";
import { Link } from "react-router-dom";

import { StillImage } from "@/components/Still/StillImage";

import { IntegrationDevice } from "@/generated-models";

import { chipProps } from "../../constant";

interface IntegrationDeviceCardProps {
  device: IntegrationDevice;
}

function IntegrationDetailsDeviceHeader({
  device,
}: IntegrationDeviceCardProps) {
  const [currentCameraIdx, setCurrentCameraIdx] = useState(0);
  const { cameras = [] } = device;

  const currentCamera = cameras[currentCameraIdx];
  const currentStill = currentCamera?.still;

  return (
    <div className="flex flex-col items-start justify-center gap-2">
      <StillImage
        className={clsx("rounded aspect-video h-full w-full", {
          "object-cover": !currentStill,
        })}
        src={currentStill || "/no-still.svg"}
        alt={currentCamera?.name}
      />
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        className="flex items-center justify-center gap-2 w-full min-h-[10px] mb-4"
      >
        {cameras.map((c, idx) => (
          <IconButton
            onClick={() => {
              setCurrentCameraIdx(idx);
            }}
            className="p-0"
            size="small"
            key={c.id}
          >
            <CircleIcon
              className={clsx("w-[10px] h-[10px] text-[#353D48]", {
                "opacity-30": idx !== currentCameraIdx,
              })}
            />
          </IconButton>
        ))}
      </div>
    </div>
  );
}

function IntegrationDetailsDeviceCardFooter({
  device,
}: IntegrationDeviceCardProps) {
  const { name, tags = [], cameras = [] } = device;
  const locationName = cameras[0]?.location?.name;

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col items-start">
        <Typography
          className={clsx("text-primary text-sm leading-4", {
            invisible: !locationName,
          })}
        >
          {locationName}
        </Typography>
        <Typography className="text-primary text-lg leading-[21px] font-bold">
          {name}
        </Typography>
      </div>
      <div className="flex flex-col sm:flex-row gap-2 items-start min-h-[27px]">
        {tags.map((tag: string) => (
          <Chip
            key={tag}
            label={tag}
            {...chipProps}
            classes={{
              ...chipProps.classes,
              label: "text-sm leading-4",
            }}
          />
        ))}
      </div>
    </div>
  );
}

export function IntegrationDeviceCard({ device }: IntegrationDeviceCardProps) {
  const { id } = device;

  return (
    <Link to={`./device/${id}`}>
      <div className="rounded-lg bg-white border border-solid border-[#DCDCDC] p-3">
        <IntegrationDetailsDeviceHeader device={device} />
        <IntegrationDetailsDeviceCardFooter device={device} />
      </div>
    </Link>
  );
}
