import {
  Box,
  Button,
  CircularProgress,
  Hidden,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import { clamp } from "lodash/fp";
import React, { useCallback, useEffect, useState } from "react";

import { concat } from "@/util/apolloCache";
import { formatVideoTime } from "@/util/date";
import {
  addPointerListener,
  addPosition,
  getRelativeMousePosition,
  Position,
  subtractPosition,
} from "@/util/mouseEvents";
import { useBreakpoints } from "@/util/useBreakpoints";

import { AnnotationLabel } from "@/pages/Cases/AnnotationLabel";
import { getColorForLabel } from "@/pages/Cases/CaseComments";
import {
  MentionSuggestions,
  SpotMentionInput,
} from "@/pages/Cases/SpotMentionInput";

import { useVodTime } from "@/components/Player/PlayerBase";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";

import {
  useCreateCaseClipAnnotationMutation,
  useSubsequentAnnotationLabelQuery,
} from "@/generated-models";

import { useAnnotationContext } from "./CreateAnnotationContextProvider";

export function CreateAnnotationOverlay({
  caseId,
  clipId,
  screenshotId,
}: {
  caseId: number;
  clipId?: number;
  screenshotId?: number;
}) {
  const {
    placedAnnotationPosition,
    setPlacedAnnotationPosition,
  } = useAnnotationContext();
  const completeDrag = useCallback(
    (drag: Position | null) => {
      setPlacedAnnotationPosition(
        (placedAnnotationPosition) =>
          placedAnnotationPosition &&
          addPosition(placedAnnotationPosition, drag)
      );
    },
    [setPlacedAnnotationPosition]
  );
  const { boundaryElementCallback, handleElementCallback, drag } = useDragging(
    completeDrag
  );

  const [anchorElement, setAnchorElement] = useState<SVGElement | null>(null);
  const [mousePosition, setMousePosition] = useState<Position | null>(null);
  const {
    data: subsequentAnnotationLabelData,
  } = useSubsequentAnnotationLabelQuery({
    variables: {
      caseId,
    },
  });
  const handleMove = useCallback(
    (e: React.MouseEvent) => {
      setMousePosition(getRelativeMousePosition(e));
    },
    [setMousePosition]
  );
  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      if (placedAnnotationPosition) return;
      setPlacedAnnotationPosition(getRelativeMousePosition(e));
    },
    [placedAnnotationPosition, setPlacedAnnotationPosition]
  );
  const handleMouseLeave = useCallback(() => setMousePosition(null), []);

  // const { draggingDistance: Vector, dragging: boolean } = useDragging(boundaryElement: HTMLElement | Ref<HTMLElement>, handleRef, handleDragComplete);

  const time = useVodTime();
  if (clipId && !time) return null;

  if (!subsequentAnnotationLabelData?.subsequentAnnotationLabel) return null;

  const annotationPosition = placedAnnotationPosition ?? mousePosition;
  const draggedPosition =
    annotationPosition && addPosition(annotationPosition, drag);

  const label = subsequentAnnotationLabelData.subsequentAnnotationLabel;
  return (
    <div
      ref={boundaryElementCallback}
      className="top-0 right-0 bottom-0 left-0 absolute cursor-pointer"
      style={{
        pointerEvents: "initial",
      }}
      onMouseMove={handleMove}
      onClick={handleClick}
      onMouseLeave={handleMouseLeave}
    >
      {draggedPosition && (
        <Box
          // @ts-ignore
          ref={handleElementCallback}
          style={{
            left: `${draggedPosition.x * 100}%`,
            top: `${draggedPosition.y * 100}%`,
          }}
          className="absolute transition-opacity"
        >
          <AnnotationLabel
            ref={setAnchorElement}
            className="top-0 left-0 absolute -translate-x-1/2 -translate-y-full"
            variant="contained"
            color={getColorForLabel(label)}
          >
            {label}
          </AnnotationLabel>
          {placedAnnotationPosition && anchorElement && (
            <Hidden mdDown>
              <Popper
                open
                anchorEl={anchorElement}
                placement="right-start"
                style={{
                  zIndex: 1,
                }}
              >
                <CreateAnnotationBox
                  time={time?.position}
                  position={draggedPosition}
                  clipId={clipId}
                  screenshotId={screenshotId}
                  caseId={caseId}
                />
              </Popper>
            </Hidden>
          )}
        </Box>
      )}
    </div>
  );
}

export function CreateAnnotationBox({
  time,
  position,
  caseId,
  clipId,
  screenshotId,
}: {
  time?: number;
  position: Position;
  caseId: number;
  clipId?: number;
  screenshotId?: number;
}) {
  const { pushSnackbar } = useFeedback();
  const [
    createCaseClipAnnotation,
    { loading: isSubmitting },
  ] = useCreateCaseClipAnnotationMutation({
    refetchQueries: ["subsequentAnnotationLabel"],
  });
  const { fitsTablet } = useBreakpoints();
  const commentState = useState("");
  const mentionsState = useState<MentionSuggestions>({});

  const { setPlacingAnnotation } = useAnnotationContext();
  const finishPlacingAnnotation = useCallback(
    () => setPlacingAnnotation(false),
    [setPlacingAnnotation]
  );

  async function submit(comment: string, mentions: MentionSuggestions) {
    await createCaseClipAnnotation({
      variables: {
        clipId,
        screenshotId,
        value: {
          content: comment,
          position,
          time,
        },
      },
      update: (cache, { data }) => {
        if (!data) return;
        if (clipId) {
          cache.modify({
            id: `CaseClip:${clipId}`,
            fields: {
              annotations: concat(data.createCaseClipAnnotation.annotation),
            },
          });
        }
        if (screenshotId) {
          cache.modify({
            id: `CaseScreenshot:${screenshotId}`,
            fields: {
              annotations: concat(data.createCaseClipAnnotation.annotation),
            },
          });
        }
        cache.modify({
          id: `Case:${caseId}`,
          fields: {
            annotations: concat(data.createCaseClipAnnotation.annotation),
            collaborators: concat(
              data!.createCaseClipAnnotation.addedCollaborators
            ),
          },
        });
      },
    })
      .catch((error) => {
        pushSnackbar(
          "Unable to create annotation, please try again later",
          FeedbackType.Error
        );
        console.error(error);
      })
      .then(finishPlacingAnnotation);
  }

  return (
    <Paper
      className="bg-[#404040] border border-solid border-transparent md:border-[#676767] text-white md:bg-black md:bg-opacity-60 md:w-[400px] md:mx-4"
      square={!fitsTablet}
    >
      <div className="p-2">
        <SpotMentionInput
          classes={{
            mentions: "text-white",
            suggestion: "bg-black bg-opacity-60",
          }}
          commentState={commentState}
          mentionsState={mentionsState}
          onSubmitComment={submit}
          onCancel={finishPlacingAnnotation}
          autoFocus
          style={
            !fitsTablet
              ? {
                  input: {
                    borderColor: "transparent",
                  },
                }
              : undefined
          }
        />
      </div>
      <div className="m-2" />
      <div className="flex items-center text-white p-2 bg-[#151515] bg-opacity-50 lg:bg-opacity-0 md:items-end">
        {time && (
          <Typography variant="body2" style={{ color: "#ccc" }}>
            {formatVideoTime(Math.floor(time * 1000))}
          </Typography>
        )}
        <div className="grow" />
        {isSubmitting ? (
          <div className="flex items-center min-h-[36px]">
            Saving
            <div className="m-4" />
            <CircularProgress size={30} />
          </div>
        ) : (
          <>
            <Button color="inherit" onClick={finishPlacingAnnotation}>
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => submit(commentState[0], mentionsState[0])}
            >
              Save
            </Button>
          </>
        )}
      </div>
    </Paper>
  );
}

function useDragging(completeDrag: (drag: Position | null) => void) {
  const [drag, setDrag] = useState<Position | null>(null);
  const [boundaryElement, setBoundaryElement] = useState<HTMLElement | null>(
    null
  );
  const [handleElement, setHandleElement] = useState<HTMLElement | null>(null);
  useEffect(() => {
    let cleanups: (() => void)[] | null = null;
    if (!handleElement) return;
    let handleCleanup = () => {};

    function addDragStartListener() {
      handleCleanup = addPointerListener(
        "mousedown",
        "touchstart",
        handleElement!,
        handleDragStart
      );
    }
    addDragStartListener();

    function handleDragStart(position: Position, button?: number) {
      if (!boundaryElement) return;
      cleanups = [];
      let dragStart = getRelativeMousePosition(position, boundaryElement);
      let drag = { x: 0, y: 0 };

      function handleDrag(position: Position) {
        // todo: check if within boundary, if not set drag = null
        drag = subtractPosition(
          getRelativeMousePosition(position, boundaryElement!),
          dragStart
        );
        setDrag(clampDraggingPosition(dragStart, drag));
      }
      cleanups.push(
        addPointerListener("mousemove", "touchmove", window, handleDrag)
      );

      // todo: cancel dragging on mouseout of boundary
      function handleMouseUp(_: Position, upButton?: number) {
        if (button !== upButton) return;
        completeDrag(drag);

        setDrag(null);
        cleanups?.forEach((x) => x());
        addDragStartListener();
      }
      cleanups.push(
        addPointerListener("mouseup", "touchend", window, handleMouseUp)
      );
      handleCleanup();
    }

    return () => {
      handleCleanup();
      cleanups?.forEach((x) => x());
    };
  }, [boundaryElement, handleElement, completeDrag]);

  return {
    boundaryElementCallback: setBoundaryElement,
    handleElementCallback: setHandleElement,
    drag,
  };
}

function clampDraggingPosition(dragStart: Position, drag: Position) {
  return {
    x: clamp(-dragStart.x, 1 - dragStart.x, drag.x),
    y: clamp(-dragStart.y, 1 - dragStart.y, drag.y),
  };
}
