import { Box, Tooltip } from "@mui/material";
import clsx from "clsx";
import { format, utcToZonedTime } from "date-fns-tz";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect, useState, memo } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { filterFalsy } from "@/util/filterFalsy";
import { isWebRTCSupported } from "@/util/isWebRTCSupported";
import { useBreakpoints } from "@/util/useBreakpoints";

import { FullScreen } from "@/components/FullScreen";
import { LiveViewTracker } from "@/components/Player/ActivityTracker";
import { PlayerAspect, PlayerBase } from "@/components/Player/PlayerBase";
import { LocalIndicator } from "@/components/Player/PlayerMetadata";
import { LatestCameraStillOverlay } from "@/components/Player/Status/ConnectivityDisplay";
import { VideoPlayer } from "@/components/Player/VideoPlayer";
import {
  useMatchesPlayingState,
  useStillOverlayShown,
  useStillTimestamp,
} from "@/components/Player/playerMachine";

import { CameraFeeds } from "@/generated-models";

import { usePrefixOrgSlug } from "../../hooks/useOrgRouteBase";
import LivekitPlayer from "../Player/WebRTCPlayer/LivekitPlayer";
import { ContextualVideoPlayer } from "./ContextualVideoPlayer";

interface VideoWallPlayerProps {
  sources: CameraFeeds;
  name: string;
  timezone: string;
  camId: number;
  applianceOnline: boolean;
  cameraOnline: boolean;
  kiosk?: boolean;
  maxAutoLevel?: string;
  location?: string;
  rotating?: boolean;
  isWebRtc: boolean;
  audioControlEnabled: boolean;
  blur?: boolean;
}

function VideoWallPlayerComponent({
  sources,
  name,
  timezone,
  camId,
  applianceOnline,
  cameraOnline,
  kiosk,
  maxAutoLevel,
  location,
  rotating,
  isWebRtc,
  audioControlEnabled,
  blur = false,
}: VideoWallPlayerProps) {
  const { contextualPlayer } = useFlags();
  const { fitsDesktop } = useBreakpoints();

  const prefixOrgSlug = usePrefixOrgSlug();

  const muxConfig = rotating
    ? {
        cameraId: camId,
        playerName: "Rotating Wall",
        sampling: 0.01, // 1% of traffic
        kiosk: kiosk,
      }
    : {
        cameraId: camId,
        playerName: "Wall",
        sampling: 0.1, // 10% of traffic
        kiosk: kiosk,
      };

  /*
    Blur prop enforces camera to be blured regardless of status.
    If it's true, we don't show the actual player.
  */
  if (blur) {
    return (
      <PlayerBase className="bg-black">
        <FullScreen>
          <LatestCameraStillOverlay cameraId={camId} kiosk={kiosk} blur />
        </FullScreen>
      </PlayerBase>
    );
  }

  return (
    <PlayerBase className="bg-black">
      <FullScreen>
        {!kiosk && <LiveViewTracker cameraId={camId} />}
        {contextualPlayer && !fitsDesktop && (
          <div className="block md:hidden">
            <ContextualVideoPlayer
              camId={camId}
              name={name}
              myWebRTC={Boolean(isWebRtc)}
              sources={sources}
              maxAutoLevel={maxAutoLevel}
              timezone={timezone}
              location={location}
              audioControlEnabled={!!audioControlEnabled}
            >
              {isWebRtc && sources.webRTC && isWebRTCSupported() ? (
                <PlayerAspect className="overflow-hidden">
                  <LivekitPlayer
                    forceMuted
                    url={sources?.webRTC ?? ""}
                    playerId={camId.toString()}
                  />
                </PlayerAspect>
              ) : (
                <VideoPlayer
                  forceMuted
                  sources={sources}
                  showingLivestream
                  forceAutoQuality
                  maxAutoLevel={maxAutoLevel}
                  muxConfig={muxConfig}
                />
              )}
            </ContextualVideoPlayer>
          </div>
        )}
        <Link
          to={prefixOrgSlug(`/live?cams=${camId}`)}
          className={clsx("overflow-hidden", {
            "hidden md:block": contextualPlayer,
          })}
        >
          {isWebRtc && sources.webRTC && isWebRTCSupported() ? (
            <PlayerAspect className="overflow-hidden">
              <LivekitPlayer
                forceMuted
                url={sources?.webRTC ?? ""}
                playerId={camId.toString()}
              />
            </PlayerAspect>
          ) : (
            <VideoPlayer
              forceMuted
              sources={sources}
              showingLivestream
              forceAutoQuality
              maxAutoLevel={maxAutoLevel}
              muxConfig={muxConfig}
            />
          )}
        </Link>
        <LatestCameraStillOverlay
          cameraId={camId}
          kiosk={kiosk}
          blur={false}
          stillOverlayShownStates={[
            "playback.playingState.stalled",
            "selectingStream.genericError",
          ]}
        />
        <StatusIndicators
          disconnected={!applianceOnline || !cameraOnline}
          timezone={timezone}
          stillOverlayShownStates={[
            "playback.playingState.stalled",
            "selectingStream.genericError",
          ]}
        />
        <div className="absolute bottom-0 left-0 z-10 w-full p-1">
          <VideoWallBar name={name} timezone={timezone} />
        </div>
      </FullScreen>
    </PlayerBase>
  );
}

const statusIndicatorIcons = {
  networkNone: "/network_none.svg",
  networkPoor: "/network_poor.svg",
  networkStill: "/network_still.svg",
  networkStillOld: "/network_still_old.svg",
};

const useStatusIndicatorStyles = makeStyles()((theme) => ({
  tooltip: {
    background: "rgba(0, 0, 0, .5)",
    width: 180,
  },
}));

function StatusIndicators({
  disconnected,
  timezone,
  stillOverlayShownStates,
}: {
  disconnected: boolean;
  timezone: string;
  stillOverlayShownStates: string[];
}) {
  const { classes } = useStatusIndicatorStyles();
  const stillOverlayShown = useStillOverlayShown(stillOverlayShownStates);
  const stalled = useMatchesPlayingState("stalled");
  const stillTimestamp = useStillTimestamp();

  if (!stillOverlayShown) return null;

  const networkIndicator = disconnected
    ? statusIndicatorIcons.networkNone
    : stillOverlayShown
    ? statusIndicatorIcons.networkPoor
    : null;

  const oldStill = Boolean(
    stillTimestamp && Date.now() - stillTimestamp.getTime() > 120e3
  );
  const stillIndicator = stillOverlayShown
    ? oldStill
      ? statusIndicatorIcons.networkStillOld
      : statusIndicatorIcons.networkStill
    : null;

  const zonedTime = stillTimestamp && utcToZonedTime(stillTimestamp, timezone);
  const tooltipText = [
    disconnected
      ? "Still displayed due to disconnection"
      : stalled
      ? "Connecting stream - showing still"
      : "Still displayed due to poor network",
    oldStill && format(zonedTime!, "Pp z"),
  ]
    .filter(filterFalsy)
    .join(". ");

  return (
    <Box position="absolute" top={8} left={8} zIndex={1}>
      <Tooltip
        title={tooltipText}
        placement="right"
        PopperProps={{
          disablePortal: true,
        }}
        classes={classes}
      >
        <Box>
          {networkIndicator && (disconnected || !stalled) && (
            <img src={networkIndicator} alt="poor network" />
          )}
          <Box width={2} display="inline-block" />
          {stillIndicator && <img src={stillIndicator} alt="showing still" />}
        </Box>
      </Tooltip>
    </Box>
  );
}

function VideoWallBar({ name, timezone }: { name: string; timezone: string }) {
  const { fitsDesktop } = useBreakpoints();

  return (
    <div className="flex gap-1 w-full items-center justify-between">
      <div className="p-1 bg-black bg-opacity-50 text-white z-10 rounded overflow-hidden flex gap-2 items-center">
        {fitsDesktop && <LocalIndicator variant="outline" />}
        <div className="text-xs md:text-sm truncate">{name}</div>
      </div>
      {fitsDesktop && (
        <div className="ml-auto p-1 bg-black bg-opacity-50 text-white z-10 rounded overflow-hidden flex gap-2  items-center">
          <Timestamp timezone={timezone} />
        </div>
      )}
    </div>
  );
}

function Timestamp({ timezone }: { timezone: string }) {
  const [time, setTime] = useState(Date.now());
  const stillOverlayShown = useStillOverlayShown();
  const stillTimestamp = useStillTimestamp();

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 1000);
    return () => clearInterval(interval);
  }, []);

  const zonedTime = utcToZonedTime(
    stillOverlayShown && stillTimestamp ? stillTimestamp : time,
    timezone
  );
  const timeFormat = stillOverlayShown ? "h:mm:ss" : "h:mm";

  return (
    <div style={{ fontSize: 14, marginRight: 10, flexShrink: 0 }}>
      <strong>{format(zonedTime, timeFormat, { timeZone: timezone })}</strong>{" "}
      <span style={{ opacity: 0.5 }}>
        {format(zonedTime, "a z", { timeZone: timezone })}
      </span>
    </div>
  );
}

export const VideoWallPlayer = memo(VideoWallPlayerComponent);
