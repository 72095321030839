import Check from "@mui/icons-material/Check";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CloseIcon from "@mui/icons-material/Close";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import {
  Autocomplete,
  Button,
  Divider,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { sortBy } from "lodash/fp";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "react-use";

import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { StatusChip } from "@/components/StatusChip";

import { Appliance } from "@/generated-models";
import { DarkTheme } from "@/layout/theme";

import { useMaintainDevices } from "../../hooks";
import { MaintainConfigDiffViewer } from "./MaintainConfigDiffViewer";
import { MaintainConfigEditorInput } from "./MaintainConfigEditorInput";
import {
  useApplianceConfigHistory,
  useApplianceDiff,
  useSelectedAppliance,
} from "./hooks";

type ApplianceData = Pick<
  Appliance,
  "id" | "serialNumber" | "version" | "health"
>[];

function ApplianceConfigHistoryButton() {
  const { enabled, setVersionId } = useApplianceDiff();
  const items = useApplianceConfigHistory();

  if (items.length <= 1) return <></>;
  return (
    <Button
      variant="contained"
      className="shadow-none bg-[#DAEEFF] text-[#007CE4] font-normal rounded-[6px] disabled:bg-[#EEEEEE] disabled:text-[#9C9C9C]"
      onClick={() => {
        setVersionId(enabled ? undefined : items[1].value, "replaceIn");
      }}
    >
      {enabled ? "Exit" : "Diff"}
    </Button>
  );
}

function ApplianceThemeButton({
  onToggleTheme,
}: {
  onToggleTheme: () => void;
}) {
  const [dark] = useLocalStorage("maintainEditDark", false);

  return (
    <Button
      className={clsx("min-w-[44px] px-0", {
        "text-[#A6C8E1]": dark,
      })}
      variant="outlined"
      onClick={() => {
        onToggleTheme();
      }}
    >
      {!dark ? <DarkModeIcon /> : <LightModeIcon />}
    </Button>
  );
}

function ApplianceConfigEditorHeader({
  appliances,
  onToggleTheme,
}: {
  appliances?: ApplianceData;
  onToggleTheme: () => void;
}) {
  const navigate = useNavigate();
  return (
    <div className="flex items-center justify-between gap-2">
      <div className="flex items-center justify-start gap-2">
        <IconButton onClick={() => navigate(-1)} className="text-primary">
          <ChevronLeftIcon fontSize="large" />
        </IconButton>
        <Typography className="font-bold text-lg leading-[21px]">
          Edit Configuration
        </Typography>
      </div>
      <div className="flex gap-2 ml-auto">
        <ApplianceSelector appliances={appliances} />
        <ApplianceConfigHistoryButton />
        <ApplianceThemeButton onToggleTheme={onToggleTheme} />
      </div>
    </div>
  );
}

function ApplianceSelector({ appliances }: { appliances?: ApplianceData }) {
  const { reset } = useApplianceDiff();
  const { selected, setSelected } = useSelectedAppliance();
  const options = sortBy(
    "label",
    appliances?.map((a) => ({
      label: a.serialNumber,
      status: (a.health.online ? "online" : "offline") as "online" | "offline",
      version: a.version,
      value: a.id,
    })) || []
  );

  const value = options.find((o) => o.value === selected);

  return (
    <Autocomplete
      fullWidth
      size="small"
      data-cy="appliance-selector"
      className="min-w-[300px]"
      options={options}
      value={value}
      disableClearable
      limitTags={3}
      onChange={(event, newValue) => {
        setSelected(newValue?.value, "replaceIn");
        reset();
      }}
      noOptionsText="No locations found"
      ChipProps={{
        variant: "outlined",
        color: "primary",
        deleteIcon: <CloseIcon />,
        classes: { root: "font-medium bg-[#F6FBFF]", deleteIcon: "w-5 h-5" },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder="Select an Appliance"
          InputProps={{
            notched: false,
            ...params.InputProps,
            className: clsx("rounded-lg", params.InputProps.className),
          }}
          InputLabelProps={{
            className: "-top-3 -left-[14px]",
            shrink: true,
            ...params.InputLabelProps,
          }}
        />
      )}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option, { selected }) => (
        <li
          {...props}
          className={clsx(props.className, "p-1", {
            "bg-primary text-white font-bold": selected,
          })}
        >
          <Check
            className={clsx({ invisible: !selected }, "text-[16px] mx-1")}
          />
          <div className="flex flex-col">
            <Typography className="text-[10px] leading-[10px] opacity-60">
              {option.version}
            </Typography>
            <Typography>{option.label}</Typography>
          </div>
          <StatusChip className="ml-auto mr-2" status={option.status} />
        </li>
      )}
    />
  );
}

export function MaintainApplianceConfigEditor() {
  const [dark, setDark] = useLocalStorage("maintainEditDark", false);
  const { appliances, loading, error } = useMaintainDevices();
  const { selected } = useSelectedAppliance();
  const { enabled: diff } = useApplianceDiff();

  if (loading) {
    return <Loading>Fetching Appliances</Loading>;
  }

  if (error) {
    return <ErrorMessage title="Oops!" description={error.message} />;
  }

  const Provider = dark ? DarkTheme : Fragment;

  return (
    <Provider>
      <div
        className={clsx(
          "flex flex-col rounded-xl  shadow-[0_0_15px_rgba(0,0,0,0.15)] h-[calc(100vh-150px)] px-5 py-4",
          {
            "bg-white": !dark,
            "bg-[#1e1e1e]": dark,
          }
        )}
      >
        <ApplianceConfigEditorHeader
          appliances={appliances}
          onToggleTheme={() => {
            setDark(!dark);
          }}
        />
        <Divider className="-mx-[20px] my-2" />
        {!!selected ? (
          <>
            {!diff && <MaintainConfigEditorInput />}
            {diff && <MaintainConfigDiffViewer />}
          </>
        ) : (
          <div className="flex w-full h-full justify-center items-center">
            <Typography className="italic font-light text-xl">
              Select an appliance to get started
            </Typography>
          </div>
        )}
      </div>
    </Provider>
  );
}
