import clsx from "clsx";
import React from "react";
import { useMatch } from "react-router-dom";

import { usePermissions } from "@/hooks/usePermissions";

export function StillImage({
  className,
  alt = "",
  ...props
}: React.ImgHTMLAttributes<HTMLImageElement>) {
  const match = useMatch("/s/case/:token");
  const matchSnippet = useMatch("/s/snippet/:token");
  const hasPermission = usePermissions();

  return (
    <img
      className={clsx(
        {
          blur:
            !(
              hasPermission("video_vod_access") ||
              hasPermission("video_live_access")
            ) &&
            !match &&
            !matchSnippet,
        },
        className
      )}
      alt={alt}
      {...props}
    />
  );
}
