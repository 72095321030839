import DeleteForever from "@mui/icons-material/DeleteForever";
import Typography from "@mui/material/Typography";

import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";

import {
  AnnotationLabelState,
  useDeleteAiAnnotationLabelMutation,
} from "@/generated-models";

import {
  CopilotLabelMode,
  useCopilotLabelContext,
} from "../useCopilotLabelContext";
import { CopilotFormButton } from "./CopilotFormButton";

export function DeleteLabelForm() {
  const { id, label, setMode } = useCopilotLabelContext();
  const { deleteLabel, loading } = useDeleteAnnotationLabel(id);

  return (
    <div className="flex flex-col gap-[10px]">
      <div className="flex items-center justify-start gap-1">
        <DeleteForever />
        <Typography className="text-[13px] font-bold leading-normal font-barlow text-left">
          Delete Label:{" "}
          <span className="text-[#C22F00] uppercase">{label}</span>
        </Typography>
      </div>
      <Typography className="text-[11px] leading-normal text-[#6C6C6C] font-barlow italic max-w-[218px] text-left">
        Hiding a label will remove it from the scene. You can reset the label
        visibility in the AI Copilot bar.
      </Typography>
      <div className="flex items-center gap-1 w-full">
        <CopilotFormButton
          className="flex-1"
          onClick={() => {
            setMode(CopilotLabelMode.default);
          }}
        >
          Cancel
        </CopilotFormButton>
        <CopilotFormButton
          variant="destructive"
          className="flex-1"
          disabled={loading}
          onMouseDown={deleteLabel}
        >
          Delete
        </CopilotFormButton>
      </div>
    </div>
  );
}

function useDeleteAnnotationLabel(id: number) {
  const { pushSnackbar } = useFeedback();
  const [del, { loading }] = useDeleteAiAnnotationLabelMutation({
    update: (cache, { data }) => {
      if (!data) return;
      cache.modify({
        id: `AnnotationLabelItem:${id}`,
        fields: {
          labelState: () => AnnotationLabelState.Suppressed,
        },
      });
    },
    onError: (e) => {
      console.error(e);
      pushSnackbar(
        "Unable to delete the specified label, please try again later",
        FeedbackType.Error
      );
    },
  });

  return {
    loading,
    deleteLabel: () => {
      return del({
        variables: {
          id,
        },
      });
    },
  };
}
