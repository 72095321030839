import PrevIcon from "@mui/icons-material/SkipPrevious";
import { IconButton, IconButtonProps } from "@mui/material";
import React from "react";

import { PlayerTooltip } from "./PlayerTooltip";

export function PreviousClipButton({
  fontSize = 24,
  arrowColor = "#fff",
  ...buttonProps
}: {
  fontSize?: number;
  arrowColor?: string;
} & IconButtonProps) {
  const disabled = !buttonProps.onClick;
  return (
    <PlayerTooltip title="Previous clip">
      <span>
        <IconButton
          disabled={disabled}
          style={{
            color: arrowColor,
            opacity: disabled ? 0.2 : "initial",
            fontSize,
          }}
          {...buttonProps}
          size="large"
        >
          <PrevIcon fontSize="inherit" />
        </IconButton>
      </span>
    </PlayerTooltip>
  );
}
