import { Button } from "@mui/material";
import { useAtomValue } from "jotai";
import { useFlags } from "launchdarkly-react-client-sdk";

import { formatIsoDate, formatIsoDateTime } from "@/util/date";
import { downloadCSV } from "@/util/file";
import { filterNullish } from "@/util/filterFalsy";
import { formatDurationSecs } from "@/util/formatDurationSecs";
import { useBreakpoints } from "@/util/useBreakpoints";

import { useMe } from "@/components/Auth";
import { DownloadIcon } from "@/components/Player/PlayerIcons";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";

import {
  IntelligenceDashboardQuery,
  IntelligenceDashboardType,
} from "@/generated-models";

import {
  refContentDashboardsAtom,
  refDashboardsLoadingAtom,
  refPresenceDashboardsAtom,
} from "../ComparativeDashboard/Content/Chart/ComparativeDashboardChartFetcher";
import { useIntelligenceDateRange } from "../hooks";
import { countCsvFormatter } from "./formatters/count";
import { idleCsvFormatter, IdleExportContent } from "./formatters/idle";
import {
  presenceCsvFormatter,
  PresenceExportContent,
} from "./formatters/presence";
import { reportCsvFormatter, ReportExportContent } from "./formatters/report";
import { useIntelligenceDashboardData } from "./hooks";

type Dashboard = NonNullable<
  IntelligenceDashboardQuery["intelligenceDashboard"]
>;

interface IntelligenceDashboardViewExportButtonProps {
  variant?: "text" | "outlined" | "contained";
  disabled?: boolean;
  dashboard: Dashboard;
  startDate: Date;
  endDate: Date;
  range?: string;
}

function useBuildExportData() {
  const { lprMmct } = useFlags();

  return (
    dashboard: Dashboard,
    content: unknown,
    startDate: Date,
    endDate: Date,
    range?: string,
    organization?: string
  ) => {
    const { cameras, name, thresholdSeconds, type } = dashboard;
    const timezone = cameras[0].location.timezone;
    const thresholdString = `>${formatDurationSecs(thresholdSeconds, {
      hideZeroes: true,
    })}`;
    const prefix = [
      "",
      `${name}`,
      `${formatIsoDateTime(startDate)} - ${formatIsoDateTime(endDate)}`,
      "",
      `Camera,${cameras[0].name}`,
      `Organization,${organization}`,
      `Location,${cameras[0].location.name}`,
      `Type,${type}`,
      "",
    ];

    let result: string[];
    switch (type) {
      case IntelligenceDashboardType.Idle:
        result = idleCsvFormatter(
          content as NonNullable<IdleExportContent>,
          cameras,
          timezone,
          thresholdString,
          dashboard.objectTypes
        );
        break;
      case IntelligenceDashboardType.Count:
        result = countCsvFormatter(
          content as NonNullable<IdleExportContent>,
          cameras,
          timezone,
          range,
          dashboard.subtype
        );
        break;
      case IntelligenceDashboardType.InterestList:
      case IntelligenceDashboardType.Report:
        result = reportCsvFormatter(
          content as NonNullable<ReportExportContent>,
          lprMmct
        );
        break;
      default:
        result = presenceCsvFormatter(
          content as NonNullable<PresenceExportContent>,
          range
        );
    }

    return [...prefix, ...(result || [])].join("\n");
  };
}

export function IntelligenceDashboardComparativeViewExportButton({
  variant,
  dashboard,
}: Omit<
  IntelligenceDashboardViewExportButtonProps,
  "startDate" | "endDate" | "range" | "disabled"
>) {
  const me = useMe();
  const { pushSnackbar } = useFeedback();
  const { fitsDesktop } = useBreakpoints();
  const buildExportData = useBuildExportData();
  const { range, startDate, endDate } = useIntelligenceDateRange();

  const loadingDashboards = useAtomValue(refDashboardsLoadingAtom);
  const loading = Object.values(loadingDashboards).some((c) => !!c);
  const refContent = useAtomValue(refContentDashboardsAtom);
  const refPresence = useAtomValue(refPresenceDashboardsAtom);

  return (
    <Button
      color="primary"
      variant={variant ?? fitsDesktop ? "outlined" : undefined}
      endIcon={fitsDesktop && <DownloadIcon />}
      className="bg-white flex-shrink-0"
      disabled={loading}
      onClick={() => {
        const reports = (dashboard?.referenceDashboards || [])
          .map((rd) => {
            const details =
              dashboard.subtype === IntelligenceDashboardType.Presence
                ? refPresence[rd.id]
                : refContent[rd.id];

            if (!details) return null;

            return buildExportData(
              rd as Dashboard,
              details.data?.intelligenceDashboard,
              startDate,
              endDate,
              range,
              me?.organization?.name
            );
          })
          .filter(filterNullish);

        downloadCSV(
          reports.join("\n") || "",
          `intelligence-dashboards-${dashboard?.name}-${formatIsoDate(
            new Date()
          )}`
        );

        pushSnackbar("Dashboard Successfully Exported", FeedbackType.Success);
      }}
    >
      Export
    </Button>
  );
}

export function IntelligenceDashboardViewExportButton({
  variant,
  disabled,
  dashboard,
  startDate,
  endDate,
  range,
}: IntelligenceDashboardViewExportButtonProps) {
  const me = useMe();
  const { pushSnackbar } = useFeedback();
  const { fitsDesktop } = useBreakpoints();
  const buildExportData = useBuildExportData();
  const details = useIntelligenceDashboardData(
    dashboard,
    startDate,
    endDate,
    range
  );

  return (
    <Button
      color="primary"
      variant={variant ?? fitsDesktop ? "outlined" : undefined}
      endIcon={fitsDesktop && <DownloadIcon />}
      className="bg-white flex-shrink-0"
      disabled={disabled || !details}
      onClick={() => {
        if (details) {
          const report = buildExportData(
            dashboard,
            details,
            startDate,
            endDate,
            range,
            me?.organization?.name
          );

          downloadCSV(
            report || "",
            `intelligence-dashboards-${dashboard?.name}-${formatIsoDate(
              new Date()
            )}`
          );

          pushSnackbar("Dashboard Successfully Exported", FeedbackType.Success);
        }
      }}
    >
      Export
    </Button>
  );
}
