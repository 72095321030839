import ArrowDownIcon from "@mui/icons-material/ArrowDropDown";
import CheckIcon from "@mui/icons-material/Check";
import { Button, Popover } from "@mui/material";
import clsx from "clsx";
import { addHours, addMinutes, addSeconds } from "date-fns/fp";
import React, { useEffect, useRef, useState } from "react";

import { formatShortDuration } from "@/util/date";
import { useBreakpoints } from "@/util/useBreakpoints";

import { useVodParam } from "@/pages/Search/searchHooks";

import { CustomDurationInput } from "@/components/DurationSelect/CustomDurationInput";
import { NextClipButton } from "@/components/Player/NextClipButton";
import { PreviousClipButton } from "@/components/Player/PreviousClipButton";

const durations = [1, 2, 5, 10, 30];
export function ClipNavigation({
  className,
  condensed,
  playNextClip,
  playPreviousClip,
  lightMode = false,
  mobileDesign = false,
}: {
  className?: string;
  condensed?: boolean;
  playNextClip?: () => void;
  playPreviousClip?: () => void;
  lightMode?: boolean;
  mobileDesign?: boolean;
}) {
  const [durationDropdownOpened, setDurationDropdownOpened] = useState(false);
  const [customDurationActive, setCustomDurationActive] = useState(false);
  const { vodDuration, setVod, vodStart, vodEnd } = useVodParam();
  const { fitsDesktop, fitsTablet } = useBreakpoints();

  const durationMinutes = vodDuration ? Math.floor(vodDuration / 1000 / 60) : 5;
  const clipNavigationBorderStyle = lightMode
    ? "border-gray-e0 border-solid"
    : "border-white border-opacity-30 border-solid";

  useEffect(() => {
    setCustomDurationActive(false);
  }, [durationDropdownOpened]);

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  return (
    <div
      ref={wrapperRef}
      className={clsx(clipNavigationBorderStyle, [
        "inline-flex items-center rounded relative",
        {
          "border bg-white bg-opacity-10": !lightMode,
          "bg-gray-f4 border border-gray-e0": lightMode,
          "w-full": mobileDesign && !fitsDesktop,
          "h-[23px]": condensed,
          className,
        },
      ])}
    >
      <PreviousClipButton
        onClick={playPreviousClip}
        fontSize={condensed ? 14 : 20}
        arrowColor={lightMode ? "#353D48" : "#fff"}
        className={clsx("p-1.5", {
          "px-6": mobileDesign && !fitsDesktop,
        })}
      />
      <Button
        color="inherit"
        data-cy="clip-duration"
        size={condensed ? "small" : "medium"}
        classes={{ disabled: "text-white text-opacity-20" }}
        className={clsx(
          clipNavigationBorderStyle,
          "border-r border-l border-t-0 border-b-0 self-stretch flex-center rounded-none text-sm font-normal",
          {
            "gap-3 px-3": fitsTablet,
            "gap-1 px-2": !fitsTablet,
            "text-[13px] leading-[15px] py-1": condensed,
            grow: mobileDesign,
          }
        )}
        onClick={(e) => {
          e.stopPropagation();
          setDurationDropdownOpened((o) => !o);
        }}
        disabled={!vodDuration}
      >
        {Boolean(vodDuration) ? (
          <span>
            {formatShortDuration(vodStart, vodEnd, {
              days: !fitsTablet ? "d" : "day",
              hours: !fitsTablet ? "h" : "hour",
              minutes: !fitsTablet ? "m" : "min",
              seconds: !fitsTablet ? "s" : "sec",
            })}
            {fitsDesktop && " clip"}
          </span>
        ) : (
          <>No Clip</>
        )}
        <ArrowDownIcon />
      </Button>
      <NextClipButton
        onClick={playNextClip}
        fontSize={20}
        arrowColor={lightMode ? "#353D48" : "#fff"}
        className={clsx("p-1.5", {
          "px-6": mobileDesign && !fitsDesktop,
        })}
      />

      <Popover
        anchorEl={wrapperRef.current}
        open={durationDropdownOpened}
        disablePortal
        classes={{
          paper: "bg-none overflow-hidden",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={() => {
          if (customDurationActive) return;
          setDurationDropdownOpened(false);
        }}
      >
        <div
          className={clsx(
            clipNavigationBorderStyle,
            "border w-full top-full bg-gray-44 rounded z-20 flex flex-col text-white p-4"
          )}
        >
          {durations.map((d) => (
            <Button
              key={d}
              className={clsx("text-inherit justify-start px-6 font-normal", {
                "font-bold": d === durationMinutes && !customDurationActive,
                "pl-0": d === durationMinutes && !customDurationActive,
              })}
              startIcon={
                d === durationMinutes && !customDurationActive ? (
                  <CheckIcon color="primary" />
                ) : undefined
              }
              onClick={() => {
                if (vodStart) {
                  setVod({
                    start: vodStart.toISOString(),
                    end: addMinutes(d, vodStart).toISOString(),
                  });
                }
                setDurationDropdownOpened(false);
              }}
            >
              {d} min
            </Button>
          ))}
          {customDurationActive ? (
            <>
              <div className="flex my-2 flex-wrap">
                <CheckIcon
                  color="primary"
                  style={{ fontSize: 20 }}
                  className="mr-2 -ml-1"
                />
                <CustomDurationInput
                  initial={
                    vodDuration ? { start: vodStart, end: vodEnd } : undefined
                  }
                  onChange={({ sec, min, hour }) => {
                    if (sec + min + hour === 0) {
                      setCustomDurationActive(false);
                      return;
                    }
                    if (vodStart) {
                      setVod({
                        start: vodStart.toISOString(),
                        end: addHours(
                          hour,
                          addMinutes(min, addSeconds(sec, vodStart))
                        ).toISOString(),
                      });
                    }
                    setDurationDropdownOpened(false);
                  }}
                />
              </div>
              <div className="flex-center w-full mt-2">
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  className="p-0.5"
                >
                  Save
                </Button>
              </div>
            </>
          ) : (
            <Button
              className="text-inherit justify-start px-6 font-normal"
              onClick={() => setCustomDurationActive(true)}
            >
              Custom
            </Button>
          )}
        </div>
      </Popover>
    </div>
  );
}
