import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, IconButton, Paper, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { NumberParam, useQueryParam } from "use-query-params";

import { ReactComponent as CaseIcon } from "@/icons/case.svg";

import { useBreakpoints } from "@/util/useBreakpoints";

import { QueryParamLink } from "@/components/shared/QueryParamLink";

import { useGetCaseQuery } from "@/generated-models";

import { usePrefixOrgSlug } from "../../hooks/useOrgRouteBase";

export function CaseInvestigationBanner() {
  const [caseId] = useQueryParam("case", NumberParam);
  const { fitsTablet } = useBreakpoints();
  const prefixOrgSlug = usePrefixOrgSlug();
  const { data } = useGetCaseQuery({
    variables: {
      id: caseId || -1,
    },
    skip: !caseId,
    // Background info on nextFetchPolicy (buried in an issue):
    // https://github.com/apollographql/apollo-client/issues/6760#issuecomment-668188727
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  if (!data?.case) return null;

  return (
    <Paper
      className="flex items-center relative py-2 px-4 text-white bg-primary"
      square
      elevation={4}
    >
      {fitsTablet && (
        <>
          <CaseIcon width="32px" height="100%" />
          <Box m={2} />
        </>
      )}
      <Typography variant="h3" className="font-normal">
        <span className="opacity-50">Case:</span>{" "}
        <span className="font-bold">{data.case.title}</span>
      </Typography>
      <Box flexGrow={1} />
      <Button
        component={Link}
        className="text-white border-white"
        to={prefixOrgSlug(`/cases/${caseId}`)}
        variant="outlined"
      >
        Back to Case
      </Button>
      <Box m={1} />
      <IconButton
        className="text-white"
        component={QueryParamLink}
        removalParams={["case"]}
        size={fitsTablet ? undefined : "small"}
      >
        <CloseIcon fontSize={fitsTablet ? undefined : "small"} />
      </IconButton>
    </Paper>
  );
}
