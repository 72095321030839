import {
  Avatar,
  Button,
  CircularProgress,
  Hidden,
  Tooltip,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { addDays, min } from "date-fns";
import { format, utcToZonedTime } from "date-fns-tz";
import { endOfMonth, endOfWeek, startOfMonth, startOfWeek } from "date-fns/fp";
import gql from "graphql-tag";
import { capitalize, lowerCase } from "lodash/fp";
import { useMemo } from "react";
import { Chart } from "react-charts";
import { BooleanParam, useQueryParam } from "use-query-params";

import { ReactComponent as ExpandIcon } from "@/icons/icon-expand.svg";
import { ReactComponent as MinimizeIcon } from "@/icons/icon-min.svg";

import { formatIsoDate, timeFormat } from "@/util/date";
import { formatDurationSecs } from "@/util/formatDurationSecs";
import { useBreakpoints } from "@/util/useBreakpoints";

import { ErrorMessage } from "@/components/ErrorMessage";
import { EmbeddedVodPlayer } from "@/components/Player/EmbeddedVodPlayer";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  IntelligenceDashboardType,
  JobStatus,
  PresenceMetrics,
  useIntelligencePresenceDashboardContentQuery,
} from "@/generated-models";

import {
  primaryAxis,
  secondaryAxesPercentage,
  useReactChartStyles,
} from "../ChartConfig";
import { IntLoadingIndicator } from "../IntLoadingIndicator";
import { DashboardDatePicker, Range } from "../IntelligenceDashboardView";
import { IntelligenceFullScreenView } from "../IntelligenceFullScreenView";
import { IntervalGraph } from "../IntervalGraph";
import { PresenceDatagrid } from "../Presence/PresenceDatagrid";
import { TimelineDivider } from "../TimelineDivider";
import {
  EntityColors,
  EntityLabel,
  EntityType,
  IntelligenceFeatureType,
} from "../constants";
import { useIntelligenceDateRange } from "../hooks";
import {
  getDashboardFeatureConfig,
  getDashboardFeatureType,
  getIntObjectFilterConfig,
  getObjectLabel,
  getObjectTypeIcon,
} from "../utils";

export function MobilePresenceDashboard({
  objectTypes,
  cameraId,
  metrics,
  timezone,
  feature,
}: {
  objectTypes?: string[] | string | null;
  cameraId: number;
  metrics?: PresenceMetrics;
  timezone: string;
  feature: IntelligenceFeatureType;
}) {
  const [, setFullscreen] = useQueryParam("fullscreen", BooleanParam);

  const { range } = useIntelligenceDateRange();

  const entity = EntityLabel[feature];

  const typeString = getObjectLabel(
    objectTypes,
    IntelligenceDashboardType.Presence
  );

  return (
    <IntelligenceFullScreenView>
      <div className="flex items-center justify-between mt-4 mx-4">
        <div className="text-lg leading-[20px] font-bold">
          {capitalize(typeString)} Idle Counts
        </div>
        <DashboardDatePicker cameraId={cameraId} />
        <Button
          startIcon={<MinimizeIcon className="fill-current text-primary" />}
          color="primary"
          onClick={() => setFullscreen(false)}
        >
          Minimize
        </Button>
      </div>
      {!metrics && <IntLoadingIndicator className="py-10 md:py-20" />}
      {metrics && (
        <div className="px-5 py-6">
          <div className="p-2 md:px-4 bg-[#FBFBFB] rounded-md mt-5 border border-[#EEEEEE]">
            <div className="flex items-center mt-1 mb-2">
              <Typography className="text-sm font-medium">
                {range === Range.Day || range === Range.Custom
                  ? `Moments when Spot AI Intelligence detected ${typeString} in the camera frame:`
                  : `${capitalize(typeString)} Presence Percentage`}
              </Typography>
            </div>
            {range === Range.Day ? (
              metrics.presenceBuckets?.length ? (
                <div className="mb-6 mt-6">
                  <IntervalGraph
                    intervalBarStyle={{ background: EntityColors[entity][1] }}
                    cameraId={cameraId}
                    intervals={metrics.presenceBuckets[0].intervals}
                    graphStartMs={metrics.presenceBuckets[0].bucketMs}
                    graphEndMs={metrics.presenceBuckets[0].bucketEndMs}
                    timezone={timezone}
                    variant="regular"
                  />
                </div>
              ) : (
                <div className="w-full h-44 text-center flex items-center justify-center">
                  No data avaliable
                </div>
              )
            ) : (
              <PresenceDashboardChart
                entity={entity}
                buckets={metrics.presenceBuckets}
                timezone={timezone}
                primaryTicks={range === Range.Week ? 7 : undefined}
                typeString={typeString}
              />
            )}
          </div>
          <div className="flex-center pt-3">
            <div className="flex items-center sm:ml-auto">
              <div
                style={{
                  background: EntityColors[entity][0],
                }}
                className="md:h-6 md:w-6 h-[14px] w-[14px] rounded mr-2"
              />
              <Typography className="text-xs md:text-sm">
                {capitalize(typeString)} Absent
              </Typography>
            </div>
            <div className="flex items-center ml-8">
              <div
                style={{
                  background: EntityColors[entity][1],
                }}
                className="md:h-6 md:w-6 h-[14px] w-[14px] rounded mr-2"
              />
              <Typography className="text-xs md:text-sm">
                {capitalize(typeString)} Present
              </Typography>
            </div>
          </div>
        </div>
      )}
    </IntelligenceFullScreenView>
  );
}

export function PresenceDashboard({
  objectTypes,
  entityCount,
  metrics,
  timezone,
  cameraId,
  startDate,
  endDate,
  range,
  feature,
}: {
  objectTypes?: string[] | string | null;
  entityCount: number;
  cameraId: number;
  metrics: PresenceMetrics;
  timezone: string;
  startDate: Date;
  endDate: Date;
  range: string;
  feature: IntelligenceFeatureType;
}) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setFullscreen] = useQueryParam("fullscreen", BooleanParam);
  const { fitsDesktop } = useBreakpoints();

  const entity = EntityLabel[feature];
  const config = getDashboardFeatureConfig(
    IntelligenceDashboardType.Presence,
    objectTypes
  );
  const typeString = getObjectLabel(
    objectTypes,
    IntelligenceDashboardType.Presence
  );
  const TypeIcon = getObjectTypeIcon(
    objectTypes,
    IntelligenceDashboardType.Presence
  );

  const legend = (
    <>
      <div className="flex items-center sm:ml-auto">
        <div
          style={{
            background: EntityColors[entity][0],
          }}
          className="md:h-6 md:w-6 h-[14px] w-[14px] rounded mr-2"
        />
        <Typography className="text-xs md:text-sm">
          {capitalize(typeString)} Absent
        </Typography>
      </div>
      <div className="flex items-center ml-8">
        <div
          style={{
            background: EntityColors[entity][1],
          }}
          className="md:h-6 md:w-6 h-[14px] w-[14px] rounded mr-2"
        />
        <Typography className="text-xs md:text-sm">
          {capitalize(typeString)} Present
        </Typography>
      </div>
    </>
  );

  return (
    <>
      <div className="flex items-stretch flex-wrap gap-2 md:gap-4 mt-4 md:mt-6">
        <div
          className={clsx(
            "rounded-lg px-2 py-3 md:p-4 md:pr-8 h-full shadow-paper flex flex-row w-full sm:w-auto"
          )}
        >
          <PresencePercentChart
            objectTypes={objectTypes}
            label={typeString}
            feature={feature}
            entityCount={entityCount}
            totalMsec={metrics.totalMsec}
            totalMsecPresent={metrics.totalMsecPresent}
            variant={!fitsDesktop ? "small" : undefined}
            TypeIcon={TypeIcon}
          />
        </div>
        {metrics.totalMsecPresent != null && (
          <PresenceMetricBlock
            metricClassName={config.foreground.colorPrimary}
            tooltipTitle={`Total amount of time ${entityCount} or more ${typeString} were present`}
            metric={formatDurationSecs(
              Math.round(metrics.totalMsecPresent / 1000),
              {
                hideSeconds: !fitsDesktop,
              }
            )}
            label={
              <>
                <strong>Total Time</strong>
                <br />
                Present
              </>
            }
            icon={
              <TypeIcon className="h-5 text-[#353D48] md:text-[#C6C6C6] md:p-0 p-0.5" />
            }
          />
        )}

        <PresenceMetricBlock
          metricClassName={config.foreground.colorPrimary}
          timerange={
            metrics.longestPresence
              ? {
                  start: new Date(metrics.longestPresence.startMs),
                  end: new Date(metrics.longestPresence.endMs),
                  timezone,
                }
              : undefined
          }
          tooltipTitle={`Longest continuous amount of time where ${entityCount} or more ${typeString} were present`}
          metric={
            <>
              {metrics.longestPresence ? (
                formatDurationSecs(
                  Math.round(
                    (new Date(metrics.longestPresence.endMs).getTime() -
                      new Date(metrics.longestPresence.startMs).getTime()) /
                      1000
                  ),
                  {
                    hideSeconds: !fitsDesktop,
                  }
                )
              ) : (
                <>Never</>
              )}
            </>
          }
          label={
            <>
              Longest
              <br />
              <strong>Presence</strong>
            </>
          }
          icon={
            <TypeIcon className="h-5 text-[#353D48] md:text-[#C6C6C6] md:p-0 p-0.5" />
          }
          showDate={range !== Range.Day && range !== Range.Custom}
        />
        <PresenceMetricBlock
          timerange={
            metrics.longestAbsence
              ? {
                  start: new Date(metrics.longestAbsence.startMs),
                  end: new Date(metrics.longestAbsence.endMs),
                  timezone,
                }
              : undefined
          }
          tooltipTitle={`Longest continuous amount of time where there were zero ${typeString} present`}
          metric={
            <>
              {metrics.longestAbsence &&
                formatDurationSecs(
                  Math.round(
                    (new Date(metrics.longestAbsence.endMs).getTime() -
                      new Date(metrics.longestAbsence.startMs).getTime()) /
                      1000
                  ),
                  {
                    hideSeconds: !fitsDesktop,
                  }
                )}
            </>
          }
          label={
            <>
              Longest
              <br />
              <strong>Absence</strong>
            </>
          }
          icon={
            <>
              <TypeIcon className="h-5 text-[#353D48] md:text-[#C6C6C6] md:p-0 p-0.5" />
              <div className="absolute bg-gray-200 w-full h-1 -rotate-45" />
            </>
          }
          showDate={range !== Range.Day && range !== Range.Custom}
          metricClassName="text-[#BDBDBD]"
        />
      </div>
      {range !== Range.Day && range !== Range.Custom && (
        <div className="mt-2 md:mt-5">
          <EmbeddedVodPlayer
            cameraId={cameraId}
            subjects={
              getIntObjectFilterConfig(
                objectTypes,
                IntelligenceDashboardType.Presence
              ).objectIds
            }
          />
        </div>
      )}
      <div className="p-2 md:px-4 bg-[#FBFBFB] rounded-md mt-5 border border-[#EEEEEE]">
        <div className="flex items-center mt-1 mb-2">
          <Typography className="text-sm font-medium">
            {range === Range.Day || range === Range.Custom
              ? `Moments when Spot AI Intelligence detected ${typeString} in the camera frame:`
              : `${capitalize(typeString)} Presence Percentage`}
          </Typography>
          <Hidden smDown>
            {range === Range.Day || range === Range.Custom ? (
              <Typography className="text-sm ml-auto">
                Click timeline to view a clip from that time
              </Typography>
            ) : (
              legend
            )}
          </Hidden>
          <Hidden mdUp>
            <div className="grow"></div>

            <Button
              startIcon={<ExpandIcon className="fill-current text-primary" />}
              color="primary"
              onClick={() => setFullscreen(true)}
            >
              Expand
            </Button>
          </Hidden>
        </div>
        {range === Range.Day ? (
          metrics.presenceBuckets?.length ? (
            <div className="mb-6 mt-6">
              <IntervalGraph
                intervalBarStyle={{ background: EntityColors[entity][1] }}
                cameraId={cameraId}
                intervals={metrics.presenceBuckets[0].intervals}
                graphStartMs={metrics.presenceBuckets[0].bucketMs}
                graphEndMs={metrics.presenceBuckets[0].bucketEndMs}
                timezone={timezone}
                variant={fitsDesktop ? "regular" : "small"}
                interactive
              />
            </div>
          ) : (
            <div className="w-full h-44 text-center flex items-center justify-center">
              No data avaliable
            </div>
          )
        ) : (
          <PresenceDashboardChart
            entity={entity}
            buckets={metrics.presenceBuckets}
            timezone={timezone}
            primaryTicks={range === Range.Week ? 7 : undefined}
            typeString={typeString}
          />
        )}
      </div>

      <Hidden smUp>
        <div className="flex-center pt-3">{legend}</div>
      </Hidden>

      <div className="mt-8">
        <PresenceDatagrid
          objectIds={
            getIntObjectFilterConfig(
              objectTypes,
              IntelligenceDashboardType.Presence
            ).objectIds
          }
          buckets={metrics.presenceBuckets}
          cameraId={cameraId}
          timezone={timezone}
        />
      </div>
    </>
  );
}

export function PresencePercentChart({
  objectTypes,
  entityCount,
  label,
  totalMsecPresent,
  totalMsec,
  variant,
  feature,
  TypeIcon,
}: {
  objectTypes?: string[] | string | null;
  entityCount: number;
  label: string;
  totalMsecPresent: number;
  totalMsec: number;
  variant?: "small";
  feature: IntelligenceFeatureType;
  TypeIcon: React.ElementType;
}) {
  const config = getDashboardFeatureConfig(
    IntelligenceDashboardType.Presence,
    objectTypes
  );
  const entity = EntityLabel[feature];
  const color = EntityColors[entity][1];

  return (
    <Tooltip
      title={`Percentage of time that ${entityCount} or more ${lowerCase(
        label
      )} were present during the selected time period`}
    >
      <div
        className={clsx(
          "flex-grow flex flex-row items-center justify-center",
          variant === "small" ? "gap-4" : "gap-4 md:gap-8"
        )}
      >
        <div
          className={clsx(
            "relative inline-flex rounded-full border-white",
            variant === "small" ? "border-4" : "border-8"
          )}
          style={{ boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.1)" }}
        >
          <CircularProgress
            thickness={8}
            variant="determinate"
            size={variant === "small" ? "3rem" : "9rem"}
            sx={{
              color: (theme) => theme.palette.grey[200],
            }}
            value={100}
          />
          <CircularProgress
            thickness={8}
            className="absolute left-0"
            size={variant === "small" ? "3rem" : "9rem"}
            variant="determinate"
            value={(totalMsecPresent / totalMsec) * 100}
            sx={{
              color: () => color,
            }}
          />
          <div className="top-0 left-0 bottom-0 right-0 absolute flex items-center justify-center">
            <Avatar
              className={clsx(
                "border-solid rounded-full border-gray-200 bg-transparent text-gray-400 flex justify-center items-center",
                variant === "small" ? "h-5 w-5 border-2" : "w-14 h-14 border-4"
              )}
            >
              <TypeIcon
                className={clsx(
                  variant === "small" ? "h-3 w-3" : "mb-[2px] h-14 w-8"
                )}
              />
            </Avatar>
          </div>
        </div>
        <div
          className={clsx(
            "flex md:h-full justify-center self-center flex-wrap gap-2",
            variant === "small" ? "flex-row items-center" : "flex-col"
          )}
        >
          <div
            className={clsx(
              "leading-none font-bold",
              variant === "small"
                ? "text-[32px]"
                : "text-[38px] md:text-[56px]",
              config.foreground.colorPrimary
            )}
          >
            {((totalMsecPresent / totalMsec) * 100).toFixed(2)}%
          </div>
          <div
            className={clsx(
              "leading-4 md:leading-5 md:ml0 ml-2",
              variant === "small" ? "text-base" : "text-sm md:text-xl"
            )}
          >
            <strong>Percent of Time</strong>
            <br />
            {capitalize(label)} Present
          </div>
        </div>
      </div>
    </Tooltip>
  );
}

export function PresenceMetricBlock({
  metric,
  label,
  icon,
  tooltipTitle = "",
  className,
  timerange,
  showDate,
  metricClassName = "text-primary",
}: {
  metric: React.ReactNode;
  label: React.ReactNode;
  icon?: React.ReactNode;
  tooltipTitle?: string;
  className?: string;
  timerange?: {
    start: Date;
    end: Date;
    timezone: string;
  };
  showDate?: boolean;
  metricClassName?: string;
}) {
  return (
    <Tooltip title={tooltipTitle}>
      <div
        className={clsx(
          "rounded-lg px-2 py-3 md:p-4 shadow-paper flex-grow flex flex-col justify-between",
          className
        )}
      >
        <div className="flex flex-row justify-bewteen mb-3 md:mb-6">
          <div className="text-sm md:text-xl flex-grow leading-[0.875rem] md:leading-none">
            {label}
          </div>
          {icon && (
            <Avatar className="md:w-8 md:h-8 w-5 h-5 border-solid rounded-full border-gray-200 border md:border-4 bg-transparent text-gray-400 flex justify-center items-center">
              {icon}
            </Avatar>
          )}
        </div>
        <div
          className={clsx(
            "text-2xl md:text-[40px] leading-none font-bold mb-2 flex-grow",
            metricClassName
          )}
        >
          {metric}
        </div>
        {!!timerange && (
          <>
            {/* <Divider variant="middle" /> */}
            <div className="my-1">
              <TimelineDivider />
            </div>
            <div className="flex flex-row justify-between mt-1">
              <Typography className="md:text-base text-[8px] leading-[9px]">
                {timeFormat.format(
                  utcToZonedTime(timerange.start, timerange.timezone)
                )}
              </Typography>
              {showDate && (
                <Typography className="font-bold mx-4">
                  {format(
                    utcToZonedTime(timerange.start, timerange.timezone),
                    "E M/d",
                    { timeZone: timerange.timezone }
                  )}
                </Typography>
              )}
              <Typography className="md:text-base text-[8px] leading-[9px]">
                {timeFormat.format(
                  utcToZonedTime(timerange.end, timerange.timezone)
                )}
              </Typography>
            </div>
          </>
        )}
      </div>
    </Tooltip>
  );
}

const currentDate = new Date();
export function PresenceDashboardDataLoader({
  id,
  date,
  range,
  timezone,
  cameraId,
}: {
  cameraId: number;
  id: number;
  date: Date;
  range: string;
  timezone: string;
}) {
  const { fitsDesktop } = useBreakpoints();
  const [fullscreen] = useQueryParam("fullscreen", BooleanParam);
  const startDate =
    range === Range.Month
      ? startOfMonth(date)
      : range === Range.Week
      ? startOfWeek(date)
      : date;
  const endDate =
    range === Range.Month
      ? min([endOfMonth(date), currentDate])
      : range === Range.Week
      ? min([endOfWeek(date), currentDate])
      : date;
  const {
    data,
    error,
    stopPolling,
  } = useIntelligencePresenceDashboardContentQuery({
    ...refetchOnMountPolicy,
    variables: {
      id,
      startDate: formatIsoDate(startDate),
      endDate: formatIsoDate(endDate),
      bucketSizeSeconds: 86400,
      polling: true,
    },
    pollInterval: 1000,
    onCompleted(returnedData) {
      if (
        returnedData?.intelligenceDashboard?.presence.job.status &&
        returnedData?.intelligenceDashboard?.presence.job.status ===
          JobStatus.Done
      ) {
        stopPolling();
      }
    },
    onError() {
      stopPolling();
    },
  });

  if (error) {
    return (
      <ErrorMessage
        title="Oops!"
        description="Failed to load precense intelligence dashboard. Please try again."
      />
    );
  }

  if (!data?.intelligenceDashboard?.presence.results) {
    return <IntLoadingIndicator className="py-10 md:py-20" />;
  }

  if (!fitsDesktop && fullscreen) {
    return (
      <MobilePresenceDashboard
        cameraId={cameraId}
        objectTypes={data.intelligenceDashboard?.objectTypes}
        metrics={data.intelligenceDashboard.presence.results}
        timezone={timezone}
        feature={getDashboardFeatureType(
          data.intelligenceDashboard!.type,
          data.intelligenceDashboard?.objectTypes
        )}
      />
    );
  }

  return (
    <PresenceDashboard
      objectTypes={data.intelligenceDashboard?.objectTypes}
      entityCount={data.intelligenceDashboard.entityCount ?? 0}
      cameraId={cameraId}
      metrics={data.intelligenceDashboard.presence.results}
      timezone={timezone}
      startDate={startDate}
      endDate={range === Range.Day ? addDays(endDate, 1) : endDate}
      range={range}
      feature={getDashboardFeatureType(
        data.intelligenceDashboard!.type,
        data.intelligenceDashboard?.objectTypes
      )}
    />
  );
}

function PresenceDashboardChart({
  entity,
  buckets,
  timezone,
  primaryTicks,
  typeString,
}: {
  entity: EntityType;
  buckets: PresenceMetrics["presenceBuckets"];
  timezone: string;
  primaryTicks?: number;
  typeString: string;
}) {
  const { classes } = useReactChartStyles();
  const chartData = useMemo(() => {
    const mapped = buckets.map((b) => ({
      date: utcToZonedTime(new Date(b.bucketMs), timezone),
      presencePerc: Math.round(
        (b.presenceSum / (b.bucketEndMs - b.bucketMs)) * 100
      ),
    }));
    return [
      {
        label: `${capitalize(typeString)} Present`,
        data: mapped.map((b) => ({
          primary: b.date,
          secondary: b.presencePerc,
        })),
      },
      {
        label: `${capitalize(typeString)} Absent`,
        data: mapped.map((b) => ({
          primary: b.date,
          secondary: 100 - b.presencePerc,
        })),
      },
    ];
  }, [buckets, timezone, typeString]);

  return (
    <div className={clsx("h-40 sm:h-60 md:h-80", classes.hideChartLines)}>
      <Chart
        options={{
          data: chartData,
          primaryAxis: {
            ...primaryAxis(primaryTicks),
            formatters: {
              tooltip: (value: any) => {
                const date = value as Date;
                return `${date ? format(date, "eeee") : ""}`;
              },
            },
          },
          secondaryAxes: secondaryAxesPercentage,
          primaryCursor: { showLabel: false },
          secondaryCursor: { showLabel: false },
          defaultColors: [EntityColors[entity][1], EntityColors[entity][0]],
        }}
      />
    </div>
  );
}

gql`
  query intelligencePresenceDashboardContent(
    $id: Int!
    $startDate: Date!
    $endDate: Date!
    $bucketSizeSeconds: Int
    $polling: Boolean
    $entityCount: Int
    $daysOfWeek: [Int!]
    $startTime: String
    $endTime: String
    $compoundId: String
  ) {
    intelligenceDashboard(id: $id) {
      id
      thresholdSeconds
      type
      objectTypes
      entityCount
      presence(
        input: {
          bucketSizeSeconds: $bucketSizeSeconds
          startDate: $startDate
          endDate: $endDate
          entityCount: $entityCount
          daysOfWeek: $daysOfWeek
          startTime: $startTime
          endTime: $endTime
          compoundId: $compoundId
        }
        polling: $polling
      ) {
        job {
          id
          status
        }
        results {
          totalMsecPresent
          totalMsec
          longestPresence {
            startMs
            endMs
          }
          longestAbsence {
            startMs
            endMs
          }
          presenceBuckets {
            bucketMs
            bucketEndMs
            presenceSum
            intervals {
              startMs
              endMs
            }
          }
        }
      }
    }
  }
`;

gql`
  query precenseHeatmap(
    $cameraId: Int!
    $startTime: String!
    $endTime: String!
  ) {
    vod(id: $cameraId, startTime: $startTime, endTime: $endTime) {
      feeds {
        tunnel
        local
      }
    }
  }
`;
