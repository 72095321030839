import { CopyAll } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/AddCircle";
import CheckIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import * as clipboard from "clipboard-polyfill/build/clipboard-polyfill.promise";
import FileSaver from "file-saver";
import { Field, FieldArray, Form, Formik } from "formik";
import { Select, TextField } from "formik-mui";
import gql from "graphql-tag";
import { useFlags } from "launchdarkly-react-client-sdk";
import { compact } from "lodash/fp";
import Papa from "papaparse";
import React, { useState } from "react";
import { makeStyles } from "tss-react/mui";

import { pluralize } from "@/util/pluralize";
import { useBreakpoints } from "@/util/useBreakpoints";

import {
  getEmailLabelByEmail,
  required,
  validateEmail,
} from "@/pages/Settings/UserManagement/UserSettingsUtils";

import { Chip } from "@/components/Chip";
import { DownloadIcon } from "@/components/Player/PlayerIcons";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";

import {
  useAddBulkUsersMutation,
  useGetSsoConnectionsQuery,
  UserForOrgFragmentDoc,
  useRolesQuery,
} from "@/generated-models";

const useStyles = makeStyles()((theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 16,
    backgroundColor: "#F0F0F0",
    borderRadius: "4px 4px 0 0",
  },
  textfield: {
    [theme.breakpoints.up("sm")]: {
      paddingRight: 16,
      flexGrow: 1,
    },
    [theme.breakpoints.down("sm")]: { paddingBottom: 8 },
  },
  roleField: {
    minWidth: 90,
    flexShrink: 0,
    [theme.breakpoints.down("sm")]: { paddingBottom: 8 },
  },
  removeButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: 50,
    flexShrink: 0,
  },
  actionBar: {
    display: "flex",
    alignItems: "center",
    padding: "12px 32px",
    borderTop: "1px solid #e0e0e0",
  },
  actionButton: { fontWeight: "bold", letterSpacing: 2 },
  progress: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  uploadButton: {
    display: "flex",
    alignItems: "center",
    margin: "1em 0",
    "& > svg": { marginLeft: 12, color: theme.palette.success.main },
  },
}));

interface BulkUserRow {
  name: string;
  email: string;
  roleId: number;
}
const initialBulkUser: BulkUserRow = {
  name: "",
  email: "",
  roleId: 1,
};
const initialBulkValues = {
  users: [initialBulkUser, initialBulkUser, initialBulkUser],
};

export function AddBulkModal({ close }: { close: () => void }) {
  const { classes } = useStyles();
  const { fitsTablet } = useBreakpoints();
  const [showCsvUpload, setShowCsvUpload] = useState(false);
  const { pushSnackbar } = useFeedback();
  const [addUsers] = useAddBulkUsersMutation({
    onCompleted: () =>
      pushSnackbar("Successfully added users", FeedbackType.Success),
    onError: () => pushSnackbar("Failed to add users", FeedbackType.Error),
  });
  const { data: ssoData } = useGetSsoConnectionsQuery();
  const { customRoles } = useFlags();
  const { data: roleData } = useRolesQuery({
    skip: !customRoles,
  });

  return (
    <>
      <div className={classes.header}>
        <Typography variant="h2">Add Multiple Users</Typography>
        {fitsTablet && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => setShowCsvUpload(true)}
            disabled={showCsvUpload}
          >
            Upload CSV List of Users
          </Button>
        )}
      </div>
      <Formik
        initialValues={initialBulkValues}
        onSubmit={async ({ users }) => {
          const res = await addUsers({
            variables: {
              input: users,
            },
            update(cache, { data }) {
              if (data) {
                cache.modify({
                  id: "ROOT_QUERY",
                  fields: {
                    users(existing = []) {
                      const newUsers = data.addBulkUsers.map((user) =>
                        cache.writeFragment({
                          data: user,
                          fragment: UserForOrgFragmentDoc,
                          fragmentName: "UserForOrg",
                        })
                      );
                      return [...existing, ...newUsers];
                    },
                  },
                });
              }
            },
          });
          if (res.data?.addBulkUsers) close();
        }}
      >
        {({ values, isSubmitting }) => (
          <>
            <Form>
              {showCsvUpload ? (
                <CsvUpload
                  values={values.users}
                  close={() => setShowCsvUpload(false)}
                />
              ) : (
                <FieldArray name="users">
                  {({ push, remove }) => (
                    <>
                      <div className="px-8 pt-6 pb-4 max-h-[70vh] overflow-y-auto relative grid grid-cols-[repeat(3,minmax(0,1fr))_auto] gap-x-2 gap-y-8">
                        {values.users.map(({ email }, index) => (
                          <React.Fragment key={index}>
                            <Field
                              component={TextField}
                              name={`users.${index}.name`}
                              fullWidth
                              placeholder="Jane Doe"
                              InputLabelProps={{ shrink: true }}
                              label="Name"
                              validate={required("Please provide a name")}
                            />
                            <Field
                              component={TextField}
                              name={`users.${index}.email`}
                              type="email"
                              fullWidth
                              validate={validateEmail([])}
                              placeholder="example@email.com"
                              label={getEmailLabelByEmail(
                                email,
                                ssoData?.ssoConnections
                              )}
                              InputLabelProps={{ shrink: true }}
                            />
                            <FormControl fullWidth>
                              <Field
                                label="Role"
                                component={Select}
                                disabled={!roleData}
                                name={`users.${index}.roleId`}
                                id={`${index}-roleId`}
                                formControl={{ variant: "standard" }}
                              >
                                {roleData?.roles.map((role) => (
                                  <MenuItem key={role.id} value={role.id}>
                                    {role.name}
                                  </MenuItem>
                                ))}
                              </Field>
                            </FormControl>
                            <div className="flex items-end">
                              <IconButton
                                size="small"
                                disabled={
                                  values.users.length === 1 || isSubmitting
                                }
                                onClick={() => remove(index)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </div>
                          </React.Fragment>
                        ))}
                      </div>
                      <div className="flex items-center px-8 pb-4">
                        <Button
                          color="primary"
                          onClick={() => push(initialBulkUser)}
                          startIcon={<AddIcon />}
                          disabled={isSubmitting}
                        >
                          Add User
                        </Button>
                        {isSubmitting && (
                          <div className={classes.progress}>
                            <CircularProgress size={50} />
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </FieldArray>
              )}

              {!showCsvUpload && (
                <div className={classes.actionBar}>
                  <Box flexGrow={1} />
                  <Button
                    color="primary"
                    size="small"
                    className={classes.actionButton}
                    style={{ fontWeight: 400 }}
                    onClick={close}
                    disabled={isSubmitting}
                  >
                    CANCEL
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    size="small"
                    className={classes.actionButton}
                    disabled={isSubmitting}
                  >
                    SAVE
                  </Button>
                </div>
              )}
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}

function CsvUpload({
  values,
  close,
}: {
  values: BulkUserRow[];
  close: () => void;
}) {
  const { classes } = useStyles();
  const [csvError, setCsvError] = useState("");
  const [userRows, setUserRows] = useState<BulkUserRow[]>([]);
  const { customRoles } = useFlags();
  const { data: rolesData } = useRolesQuery({
    skip: !customRoles,
  });

  return (
    <FieldArray name="users">
      {({ push, remove }) => (
        <>
          <div className="flex flex-col gap-1 p-8">
            <div className="p-2.5 bg-blue-light border border-blue-medium rounded">
              <Typography variant="h3" className="font-normal text-lg">
                <strong>Step 1.</strong> Download CSV template file
              </Typography>
              <Button
                className="my-2"
                color="primary"
                variant="contained"
                startIcon={<DownloadIcon />}
                onClick={() => {
                  const csvTemplate = Papa.unparse([
                    {
                      name: "User name",
                      email: "username@example.com",
                      role: rolesData?.roles[0].name ?? "user",
                    },
                  ]);
                  FileSaver.saveAs(
                    new Blob([csvTemplate], { type: "text/csv" }),
                    "spot-users.csv"
                  );
                }}
              >
                Download CSV Template
              </Button>
            </div>
            <div className="p-2.5 bg-blue-light border border-blue-medium rounded">
              <Typography variant="h3" className="font-normal text-lg">
                <strong>Step 2.</strong> Add user info in template
              </Typography>
              <p>
                For every row in the CSV there should be a <strong>name</strong>
                , <strong>email</strong> and <strong>role</strong>.
              </p>
              {customRoles && rolesData?.roles ? (
                <>
                  <p className="my-2">
                    The role has to be one of the existing roles:
                  </p>
                  <div className="flex flex-wrap gap-2">
                    {rolesData.roles.map((role) => (
                      <RoleNameChip name={role.name} key={role.id} />
                    ))}
                  </div>
                </>
              ) : (
                <p>Role can be either "admin" or "user".</p>
              )}
            </div>
            <div className="p-2.5 bg-blue-light border border-blue-medium rounded">
              <Typography variant="h3" className="font-normal text-lg">
                <strong>Step 3.</strong> Upload CSV file
              </Typography>
              <div className={classes.uploadButton}>
                <input
                  style={{ display: "none" }}
                  id="file-input"
                  type="file"
                  accept=".csv"
                  onChange={async (e) => {
                    const inputEl = e.target;
                    const files = inputEl.files;

                    if (files && files[0]) {
                      const text = await files?.[0].text();
                      const textNormalized = text
                        .replace(/(?:\r\n|\r|\n)/g, "\r\n")
                        .trim();
                      Papa.parse<{
                        name?: string;
                        email?: string;
                        role?: string;
                      }>(textNormalized, {
                        header: true,
                        complete: (results) => {
                          // Reset input value so onChange triggers again on the same file
                          inputEl.value = "";

                          // If there were any parsing error, return and display error
                          if (results.errors.length > 0) {
                            const error = results.errors[0];
                            setCsvError(
                              `${error.code} on row ${error.row + 1}: ${
                                error.message
                              }`
                            );
                            return;
                          } else {
                          }

                          // Reset any displayed error
                          setCsvError("");

                          try {
                            const mappedRows = results.data.map((row) => {
                              // Trim and extract values
                              const name = row.name?.trim();
                              const email = row.email?.trim();
                              const role = row.role?.trim() ?? "user";

                              // Make sure name and email are not empty
                              if (!name || !email) return null;

                              if (!rolesData) {
                                throw new Error("Could not load roles");
                              }
                              let customRoleId:
                                | number
                                | undefined = rolesData.roles.find(
                                (r) => r.name === role
                              )?.id;

                              if (!customRoleId) {
                                throw new Error(
                                  `Could not find role with name "${role}" for user with email "${email}"`
                                );
                              }

                              return {
                                name,
                                email,
                                // Validate role and use fallback
                                roleId: customRoleId,
                              };
                            });
                            const newRows = compact(mappedRows);
                            if (newRows.length > 0) setUserRows(newRows);
                            else throw new Error("No rows parsed from CSV");
                          } catch (error: any) {
                            console.error(error);
                            setCsvError(error.message ?? "Failed to parse CSV");
                          }
                        },
                      });
                    }
                  }}
                />
                <label htmlFor="file-input">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    startIcon={<DownloadIcon className="-scale-y-100" />}
                  >
                    Upload CSV File
                  </Button>
                </label>
                {userRows.length > 0 && (
                  <>
                    <CheckIcon className="mr-2" />
                    {pluralize(
                      {
                        1: "1 user found",
                        multi: userRows.length + " users found",
                      },
                      userRows.length
                    )}
                  </>
                )}
              </div>
              {csvError && <Alert severity="error">{csvError}</Alert>}
            </div>
          </div>

          <div className={classes.actionBar}>
            <Box flexGrow={1} />
            <Button
              color="primary"
              size="small"
              className={classes.actionButton}
              style={{ fontWeight: 400 }}
              onClick={close}
            >
              BACK
            </Button>
            <Button
              color="primary"
              size="small"
              className={classes.actionButton}
              disabled={userRows.length === 0}
              onClick={() => {
                // Remove empty rows from the list
                const removeIndexes = values
                  .reduce<number[]>(
                    (acc, row, index) =>
                      row.name || row.email ? acc : [...acc, index],
                    []
                  )
                  // Sort and reverse to make sure we remove higher indexes first
                  .sort()
                  .reverse();
                for (const index of removeIndexes) remove(index);

                // Push rows captured from the CSV
                for (const row of userRows) push(row);
                close();
              }}
            >
              ADD {userRows.length > 0 ? userRows.length : ""} USERS TO LIST
            </Button>
          </div>
        </>
      )}
    </FieldArray>
  );
}

function RoleNameChip({ name }: { name: string }) {
  const [copied, setCopied] = useState(false);
  return (
    <Chip
      label={name}
      color="primary"
      deleteIcon={
        <Tooltip
          title={copied ? "Copied!" : "Copy to clipboard"}
          onClose={() => setCopied(false)}
          classes={{ popper: "pointer-events-none" }}
        >
          <CopyAll className="ml-1" />
        </Tooltip>
      }
      onDelete={async () => {
        await clipboard.writeText(name);
        setCopied(true);
      }}
    />
  );
}

export const ADD_BULK_USERS = gql`
  mutation addBulkUsers($input: [BulkUserInput!]!) {
    addBulkUsers(input: $input) {
      ...UserForOrg
    }
  }
  ${UserForOrgFragmentDoc}
`;
