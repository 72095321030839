export function buildVodUrlParams(
  cameraId: number,
  startMs?: number,
  endMs?: number,
  objectIds?: string[] | null
) {
  const searchParams = new URLSearchParams({ cams: String(cameraId) });

  if (startMs && endMs) {
    searchParams.append(
      "range",
      `${new Date(startMs).toISOString()}|${new Date(endMs).toISOString()}`
    );
  }
  if (objectIds?.length) {
    searchParams.append("subjects", objectIds.join("_"));
  }
  return searchParams;
}

export function buildVodUrl(
  cameraId: number,
  startMs?: number,
  endMs?: number,
  objectIds?: string[] | null
) {
  const params = buildVodUrlParams(cameraId, startMs, endMs, objectIds);
  return `/search?${params.toString()}`;
}
