import { ApolloError } from "@apollo/client";
import {
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";

import { Point } from "@/components/Zones/getSectorsForPolygon";

interface CopilotProviderProps {
  children: ReactNode;
}

export enum CopilotMode {
  default,
  addobject,
  coachinghistory,
  editobjectboundary,
}

type ImageData = { image: File | null; imageDataURL: string | null } | null;

type CopilotFetchState = {
  fetchQueryError?: ApolloError | null;
  fetchQueryLoading: boolean;
};

const CopilotContext = createContext({
  fetchQueryError: null as CopilotFetchState["fetchQueryError"],
  fetchQueryLoading: false,
  setFetchQueryState: (() => {}) as React.Dispatch<
    SetStateAction<CopilotFetchState>
  >,

  image: null as File | null,
  imageDataURL: null as string | null,
  setImage: (() => {}) as React.Dispatch<SetStateAction<ImageData>>,

  overrideImage: null as File | null,
  overrideImageDataURL: null as string | null,
  setOverrideImage: (() => {}) as React.Dispatch<SetStateAction<ImageData>>,

  mode: CopilotMode.default,
  setMode: (() => {}) as React.Dispatch<SetStateAction<CopilotMode>>,

  editingLabelId: null as number | null,
  setEditingLabelId: (() => {}) as React.Dispatch<
    SetStateAction<number | null>
  >,

  editingShape: [] as Point[],
  setEditingShape: (() => {}) as React.Dispatch<SetStateAction<Point[]>>,
});

export const CopilotProvider = ({ children }: CopilotProviderProps) => {
  const [fetchQueryState, setFetchQueryState] = useState<CopilotFetchState>({
    fetchQueryError: null,
    fetchQueryLoading: false,
  });
  const [imageData, setImage] = useState<ImageData>(null);
  const [overrideImageData, setOverrideImage] = useState<ImageData>(null);
  const [mode, setMode] = useState<CopilotMode>(CopilotMode.default);
  const [editingShape, setEditingShape] = useState<Point[]>([]);
  const [editingLabelId, setEditingLabelId] = useState<number | null>(null);

  return (
    <CopilotContext.Provider
      value={{
        fetchQueryError: fetchQueryState.fetchQueryError,
        fetchQueryLoading: fetchQueryState.fetchQueryLoading,
        setFetchQueryState,

        image: imageData?.image || null,
        imageDataURL: imageData?.imageDataURL || null,
        setImage,

        overrideImage: overrideImageData?.image || null,
        overrideImageDataURL: overrideImageData?.imageDataURL || null,
        setOverrideImage,

        mode,
        setMode,

        editingLabelId,
        setEditingLabelId,

        editingShape,
        setEditingShape,
      }}
    >
      {children}
    </CopilotContext.Provider>
  );
};

export const useCopilotContext = () => {
  const context = useContext(CopilotContext);

  if (!context) {
    throw new Error("useCopilotContext must be used within a CopilotProvider");
  }

  return context;
};
