// These colors will rotate
export const colors = [
  "#FA00FF",
  "#FFE600",
  "#FFA800",
  "#FF7A00",
  "#FF3D00",
  "#FF007A",
  "#CC00FF",
  "#9E00FF",
  "#0075FF",
  "#00B2FF",
  "#00D1FF",
  "#00F0FF",
  "#00FFD1",
  "#00FF94",
  "#83F85A",
  "#DBFF00",
];

export function getColor(idx: number) {
  return colors[idx % colors.length];
}
