import { CheckCircle } from "@mui/icons-material";
import MoveIcon from "@mui/icons-material/ArrowForwardRounded";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Switch,
  Tooltip,
  Typography,
  useTheme,
  Select,
  FormLabel,
  CircularProgress,
  Chip,
} from "@mui/material";
import { isBefore, subMonths, format } from "date-fns/fp";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useRef, useState } from "react";
import ReactJson from "react-json-view";
import { Link } from "react-router-dom";
import semverGte from "semver/functions/gte";
import semverLt from "semver/functions/lt";
import semverSatisfies from "semver/functions/satisfies";
import semverValid from "semver/functions/valid";
import { makeStyles } from "tss-react/mui";

import { P340DeviceIcon } from "@/icons/Device";

import { useLocalStorage } from "@/util/useLocalStorage";

import TroubleshootPopover from "@/pages/Settings/LocationSettings/TroubleshootPopover";

import { useMe } from "@/components/Auth";
import BaseModal from "@/components/Modal/BaseModal";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { ConnectionStatus } from "@/components/StatusIndicators";
import { ZendeskArticle, ZendeskLink } from "@/components/Zendesk/ZendeskLink";
import { DefaultDialog, useDialog } from "@/components/shared/Dialog";

import { isProductionEnv } from "@/environment";
import {
  ApplianceAiMode,
  LocationNamesQuery,
  LocationWithAppliancesDocument,
  LocationWithAppliancesQuery,
  Role,
  useForceDeleteCamerasMutation,
  useLocationNamesQuery,
  useMoveApplianceMutation,
  useNatejrDebugQuery,
  useRemoveApplianceMutation,
  useUpdateApplianceConfigMutation,
} from "@/generated-models";
import { usePrefixOrgSlug } from "@/hooks/useOrgRouteBase";
import { usePermissions } from "@/hooks/usePermissions";

import { ApplianceLicenseForm } from "./ApplianceLicenseForm";

const identityCertIsExpired = isBefore(subMonths(6, new Date()));

const useStyles = makeStyles()((theme) => ({
  appliance: {
    padding: "24px 16px",
    marginBottom: 4,
    backgroundColor: "white",
  },
  status: {
    display: "flex",
    alignItems: "flex-start",
    "& > span": {
      marginLeft: 8,
    },
    "&:first-of-type": {
      marginBottom: 4,
    },
  },
  serialNumber: { fontSize: 24, fontWeight: "bold", lineHeight: 1 },
  popoverPaper: {
    display: "flex",
    flexDirection: "column",
    width: 400,
    borderRadius: 12,
  },
  modal: { display: "flex", alignItems: "center", justifyContent: "center" },
}));

export default function AddedAppliance({
  locationId,
  appliance,
}: {
  locationId: number;
  appliance: Required<
    NonNullable<Required<LocationWithAppliancesQuery>["location"]>
  >["appliances"][0];
}) {
  const me = useMe();
  const { classes } = useStyles();
  const prefixOrgSlug = usePrefixOrgSlug();
  const theme = useTheme();
  const feedback = useFeedback();
  const hasPermission = usePermissions();
  const canEdit = hasPermission("devices_manage");
  const { cameraBulkCfg, iotCoreDisabled } = useFlags();
  const supportsLicensing = appliance.licensesSupported;
  const supportsLpr =
    appliance.licensesSupported ||
    appliance.serialNumber.includes("sn4t-") ||
    appliance.serialNumber.includes("sn4u-");

  const { natejrConfigPageMetricsReport } = useFlags();

  const [overrideAiFeatureflagCheck] = useLocalStorage(
    "overrideAiFeatureflagCheck",
    false
  );
  const [overrideNvpCheck] = useLocalStorage("overrideNvpCheck", false);

  const [
    updateApplianceConfig,
    { loading: updatingConfig },
  ] = useUpdateApplianceConfigMutation({
    onError: () =>
      feedback.pushSnackbar(
        "Updating health metrics flag failed",
        FeedbackType.Error
      ),
  });

  const buttonRef = useRef<HTMLElement>(null);
  const [localPopoverOpen, setLocalPopoverOpen] = useState(false);

  const applianceVersion = semverValid(appliance.version);
  const aiAllowed = appliance.aiSupported;

  return (
    <Grid
      container
      alignItems="center"
      wrap="nowrap"
      className={classes.appliance}
    >
      <Grid container item xs={4} alignItems="center">
        <P340DeviceIcon />
        <div style={{ marginLeft: theme.spacing(2) }}>
          <div style={{ fontSize: 11, opacity: 0.6 }}>Serial Number</div>
          <div className={classes.serialNumber}>{appliance.serialNumber}</div>
          <div className="text=[#757575] text-sm mt-1">
            {appliance.capacity} camera max capacity
          </div>
          {canEdit && (
            <>
              <MoveApplianceButton
                locationId={locationId}
                appliance={appliance}
              />
              <DeleteApplianceButton appliance={appliance} />
              <ForceDeleteCamerasButton appliance={appliance} />
            </>
          )}
        </div>
      </Grid>
      <Grid
        item
        xs={3}
        style={{
          padding: "16px 0px",
          borderRight: "1px solid rgba(0,0,0,0.1)",
        }}
      >
        <div className={classes.status}>
          <CheckCircle style={{ fill: theme.palette.success.main }} />
          <span>Registered</span>
        </div>
        <div className={classes.status}>
          <ConnectionStatus
            status={appliance.health.online ? "online" : "inactive"}
          />
          <Box display="flex" flexDirection="column" ml={"8px"} mt={"1px"}>
            {appliance.health.online ? "Connected" : "Not connected"}
            {canEdit && (
              <span
                ref={buttonRef}
                style={{
                  cursor: "pointer",
                  fontWeight: 500,
                  color: "#007ce4",
                  fontSize: 14,
                }}
                onClick={() => {
                  setLocalPopoverOpen(true);
                }}
              >
                Troubleshoot
              </span>
            )}
          </Box>
          {!appliance.health.online && (
            <ZendeskLink article={ZendeskArticle.OFFLINE_APPLIANCE} />
          )}
        </div>
      </Grid>
      <Grid item xs={5}>
        <Divider orientation="vertical" />
        <div className="ml-4 flex-row space-y-1">
          <div>
            <strong>{appliance.cameras.length || "No"}</strong> cameras
            connected
          </div>
          {appliance.version && (
            <div>
              Spot version <strong>{appliance.version}</strong>
            </div>
          )}
          {!supportsLicensing && (
            <div>
              <EditDaysOfStorage
                applianceId={appliance.id}
                storageRotationDays={appliance.storageRotationDays}
              />
            </div>
          )}
          {me &&
            me.role >= Role.Success &&
            appliance.lastIdentityRotation &&
            identityCertIsExpired(new Date(appliance.lastIdentityRotation)) && (
              <Tooltip title="Contact Engineering to provision and push new identity certificates">
                <div className="my-3">
                  <strong>Identity Cert Expired!</strong>
                  <br />
                  Last rotation:{" "}
                  {format("PPP", new Date(appliance.lastIdentityRotation))}
                </div>
              </Tooltip>
            )}

          {supportsLicensing && <ApplianceLicenseForm appliance={appliance} />}
          {me &&
            me.role >= Role.Success &&
            !supportsLicensing &&
            overrideAiFeatureflagCheck && (
              <Box className="flex flex-row items-center">
                AI Mode:{" "}
                <Tooltip
                  title={
                    !aiAllowed && !overrideAiFeatureflagCheck
                      ? "AI disabled becuase appliance does not support AI."
                      : ""
                  }
                >
                  <FormControl
                    disabled={!aiAllowed && !overrideAiFeatureflagCheck}
                    size="small"
                    className="grow ml-2 max-w-[200px]"
                  >
                    <Select
                      value={appliance.deviceAiMode}
                      disabled={updatingConfig}
                      onChange={async (e) => {
                        await updateApplianceConfig({
                          variables: {
                            id: appliance.id,
                            input: {
                              deviceAiMode: e.target.value as ApplianceAiMode,
                            },
                          },
                          optimisticResponse: {
                            __typename: "Mutation",
                            updateAppliance: {
                              ...appliance,
                              deviceAiMode: e.target.value as ApplianceAiMode,
                            },
                          },
                        });
                      }}
                    >
                      <MenuItem value={"disabled"}>AI Disabled</MenuItem>
                      <MenuItem value={"nvidia"}>
                        <strong>AI Nvidia only</strong>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Tooltip>
              </Box>
            )}

          {me &&
            me.role >= Role.Success &&
            ((applianceVersion !== null &&
              // NVP is only supported on 2022.8.0-alpha.0 and above,
              // which includes 3.0.0 and above (but below 2022.0.0).
              semverGte(applianceVersion, "3.0.0-alpha.0") &&
              semverLt(applianceVersion, "4.0.0-alpha.0") &&
              !semverSatisfies(
                applianceVersion,
                "2022.0.0 - 2022.8.0-alpha.0"
              )) ||
              overrideNvpCheck) && (
              <Chip
                className="mr-2 text-white"
                size="small"
                label="NVP"
                color="success"
              />
            )}
          {me && me.role >= Role.Success && supportsLpr && !cameraBulkCfg && (
            <FormControlLabel
              control={
                <Switch
                  checked={appliance.lprEnabled || false}
                  disabled={updatingConfig}
                  onChange={async (e) => {
                    await updateApplianceConfig({
                      variables: {
                        id: appliance.id,
                        input: {
                          lprEnabled: e.target.checked,
                        },
                      },
                      optimisticResponse: {
                        __typename: "Mutation",
                        updateAppliance: {
                          ...appliance,
                          lprEnabled: e.target.checked,
                        },
                      },
                    });
                  }}
                />
              }
              label="Enable LPR"
            />
          )}
          {natejrConfigPageMetricsReport && (
            <NateJrReportModal serialNumber={appliance.serialNumber} />
          )}

          <div className="flex">
            {me && me.role >= Role.Success && (
              <>
                <Button
                  color="primary"
                  style={{ fontWeight: "bold" }}
                  size="small"
                  href={`https://monitoring.spotai.co/location/${locationId}${
                    isProductionEnv ? "" : "?staging=1"
                  }`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Monitoring
                </Button>
                <Button
                  color="primary"
                  style={{ fontWeight: "bold" }}
                  size="small"
                  href={`https://app.datadoghq.com/logs?query=host%3A${appliance.serialNumber}&index=&live=true`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Datadog
                </Button>
                {iotCoreDisabled && (
                  <Button
                    color="primary"
                    component={Link}
                    style={{ fontWeight: "bold" }}
                    size="small"
                    to={prefixOrgSlug(
                      `/maintain/appliances/editor?selected=${appliance.id}`
                    )}
                  >
                    Edit CFG
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      </Grid>
      <TroubleshootPopover
        open={localPopoverOpen}
        anchorEl={buttonRef.current}
        onClose={() => setLocalPopoverOpen(false)}
        appliance={appliance}
      />
    </Grid>
  );
}

interface EditDaysOfStorageProps {
  applianceId: number;
  storageRotationDays: number;
}

function EditDaysOfStorage({
  applianceId,
  storageRotationDays,
}: EditDaysOfStorageProps) {
  const me = useMe();
  const [editing, setEditing] = useState(false);
  const feedback = useFeedback();
  const [updateConfig] = useUpdateApplianceConfigMutation({
    onError: () =>
      feedback.pushSnackbar(
        "Updating days of storage failed, please try again",
        FeedbackType.Error
      ),
  });

  return editing ? (
    <Formik
      initialValues={{ storageRotationDays }}
      onSubmit={async (input) => {
        if (input.storageRotationDays !== storageRotationDays) {
          await updateConfig({ variables: { id: applianceId, input } });
        }
        setEditing(false);
      }}
      render={() => (
        <Form className="inline-flex items-center m-0 p-0 ml-2">
          <Field
            name="storageRotationDays"
            component={TextField}
            size="small"
            type="number"
          />
          <Tooltip title="Save">
            <IconButton type="submit" size="small">
              <CheckIcon />
            </IconButton>
          </Tooltip>
        </Form>
      )}
    />
  ) : (
    <>
      <strong>{storageRotationDays}</strong> days of storage
      {me && me.role >= Role.Success && (
        <Tooltip title="Edit">
          <IconButton onClick={() => setEditing(true)} size="small">
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
}

function MoveApplianceButton({
  locationId,
  appliance,
}: {
  locationId: number;
  appliance: Required<
    NonNullable<Required<LocationWithAppliancesQuery>["location"]>
  >["appliances"][0];
}) {
  const me = useMe();
  const isSupported = !!me && me.role >= Role.Support;
  const [modalOpen, setModalOpen] = useState(false);
  const { data } = useLocationNamesQuery({
    skip: !isSupported,
  });

  if (!isSupported || !data || data.locations.length < 2) {
    return null;
  }

  return (
    <Tooltip title={"Move appliance to a new location"}>
      <span className="pr-1 border-r-2">
        <Button
          className="font-normal -ml-3 py-2"
          disabled={modalOpen}
          color="primary"
          onClick={async () => {
            setModalOpen(true);
          }}
          size="large"
        >
          <MoveIcon className="mr-1" /> Move
        </Button>
        {modalOpen && (
          <MoveApplianceModal
            applianceId={appliance.id}
            currentLocationId={locationId}
            locations={data.locations}
            onClose={() => {
              setModalOpen(false);
            }}
          />
        )}
      </span>
    </Tooltip>
  );
}

function MoveApplianceModal({
  applianceId,
  currentLocationId,
  locations,
  onClose,
}: {
  applianceId: number;
  currentLocationId: number;
  locations: LocationNamesQuery["locations"];
  onClose: () => void;
}) {
  const feedback = useFeedback();
  const [moveAppliance] = useMoveApplianceMutation();
  const [newLocation, setNewLocation] = useState("");
  const { open, ...dialogProps } = useDialog();
  const newLocations = locations
    .filter((l) => l.id !== currentLocationId)
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <BaseModal
      onClose={onClose}
      header={
        <div className="font-bold text-2xl bg-[#F0F0F0]">Move Appliance</div>
      }
    >
      <Formik
        initialValues={{
          newLocation: { id: newLocations[0].id, name: newLocations[0].name },
        }}
        onSubmit={async (values) => {
          setNewLocation(values.newLocation.name);
          const confirmed = await open();
          if (!confirmed) return;

          await moveAppliance({
            variables: {
              id: applianceId,
              locationId: values.newLocation.id,
            },
            refetchQueries: [
              {
                query: LocationWithAppliancesDocument,
                variables: { id: currentLocationId },
              },
            ],
            awaitRefetchQueries: true,
            onError: () =>
              feedback.pushSnackbar(
                "Failed to move appliance, please try again",
                FeedbackType.Error
              ),
          });
          onClose();
        }}
      >
        {({ isSubmitting, values, setValues, handleChange }) => {
          return (
            <Form className="p-4">
              <div className="flex flex-col justify-start">
                <FormLabel htmlFor="location" className="text-text">
                  Select new location:
                </FormLabel>
                <Select
                  value={values.newLocation.id}
                  onChange={(e) => {
                    setValues({
                      newLocation: {
                        id: Number(e.target.value),
                        name:
                          newLocations.find(
                            (l) => l.id === Number(e.target.value)
                          )?.name || "",
                      },
                    });
                    handleChange(e);
                  }}
                  name="location"
                  className="my-4"
                >
                  {newLocations.map((location) => (
                    <MenuItem key={location.id} value={location.id}>
                      <Typography className="font-bold">
                        {location.name}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className="flex justify-end space-x-2">
                <Button
                  color="primary"
                  variant="outlined"
                  disabled={isSubmitting}
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={
                    isSubmitting ||
                    !values.newLocation.id ||
                    !values.newLocation.name
                  }
                >
                  Move
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
      <DefaultDialog
        title={`Move Appliance`}
        confirmText="Move"
        content={`Move appliance to ${newLocation}?`}
        {...dialogProps}
      />
    </BaseModal>
  );
}

function DeleteApplianceButton({
  appliance,
}: {
  appliance: Required<
    NonNullable<Required<LocationWithAppliancesQuery>["location"]>
  >["appliances"][0];
}) {
  const feedback = useFeedback();
  const { open, ...dialogProps } = useDialog();
  const [remove, { loading: removing }] = useRemoveApplianceMutation({
    variables: { id: appliance.id },
    refetchQueries: ["locationWithAppliances"],
    awaitRefetchQueries: true,
    onError: () =>
      feedback.pushSnackbar(
        "Deleting appliance failed, please try again",
        FeedbackType.Error
      ),
  });

  const hasCameras = Boolean(appliance.cameras.length);

  return (
    <Tooltip
      title={
        hasCameras ? "Appliance has connected cameras" : "Delete appliance"
      }
    >
      <span className="ml-4">
        <Button
          className="font-normal -ml-3 py-2"
          disabled={hasCameras || removing}
          color="primary"
          onClick={async () => {
            const confirmed = await open();
            if (!confirmed) return;
            remove();
          }}
          size="large"
        >
          <DeleteIcon className="mr-1" /> Delete
        </Button>
        <DefaultDialog
          title={`Are you sure you want to delete ${appliance.serialNumber}?`}
          confirmText="Delete Appliance"
          content="This action cannot be undone."
          verifyText="DELETE"
          {...dialogProps}
        />
      </span>
    </Tooltip>
  );
}

function ForceDeleteCamerasButton({
  appliance,
}: {
  appliance: Required<
    NonNullable<Required<LocationWithAppliancesQuery>["location"]>
  >["appliances"][0];
}) {
  const feedback = useFeedback();
  const me = useMe();
  const { open, ...dialogProps } = useDialog();

  const [forceDelete, { loading: deleting }] = useForceDeleteCamerasMutation({
    variables: { applianceId: appliance.id },
    onError: () =>
      feedback.pushSnackbar(
        "Deleting cameras failed, please try again",
        FeedbackType.Error
      ),
  });

  if (
    appliance.health.online ||
    appliance.cameras.length > 0 ||
    !me ||
    me.role < Role.Support
  ) {
    return null;
  }

  return (
    <Tooltip
      title={
        "For use when appliance is no longer online. Force deletes cameras from DB."
      }
    >
      <div>
        <Button
          className="font-normal -ml-3 py-2"
          disabled={deleting}
          color="primary"
          onClick={async () => {
            const confirmed = await open();
            if (!confirmed) return;
            forceDelete();
          }}
          size="large"
        >
          <DeleteIcon className="mr-1" /> Force Delete Cameras
        </Button>
        <DefaultDialog
          title={`Force delete the cameras on ${appliance.serialNumber}?`}
          confirmText="Force Delete"
          content="This action cannot be undone."
          verifyText="DELETE"
          {...dialogProps}
        />
      </div>
    </Tooltip>
  );
}

function NateJrReportModal({ serialNumber }: { serialNumber: string }) {
  const [open, setOpen] = useState(false);
  const { data, loading, error } = useNatejrDebugQuery({
    variables: { serialNumber },
    skip: !open,
  });
  return (
    <div>
      Appliance is healthy.{" "}
      <Button onClick={() => setOpen(!open)}>Open NateJr Report</Button>
      <Modal open={open} onClose={() => setOpen(false)}>
        <div className="w-full h-full flex justify-center items-center">
          <div className="w-9/12 h-[90vh] rounded-md bg-white flex flex-col">
            <div className="flex flex-row justify-between items-center shadow p-4">
              <div className="font-bold text-2xl">NateJr Report</div>
              <IconButton onClick={() => setOpen(false)}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className="flex-grow overflow-scroll p-4">
              {loading ? (
                <CircularProgress />
              ) : error ? (
                error.message
              ) : data?.applianceFromSerial.internalNatejrSampleReport !=
                null ? (
                <ReactJson
                  style={{ height: "100%", width: "100%" }}
                  src={JSON.parse(
                    data?.applianceFromSerial.internalNatejrSampleReport
                  )}
                />
              ) : (
                <>Report Empty</>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
