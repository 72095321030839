import clsx from "clsx";
import { ReactNode, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { DefaultDialog, useDialog } from "@/components/shared/Dialog";

import {
  useCreateIntegrationEventTypesMutation,
  useDeleteIntegrationEventTypeMutation,
  useUpdateIntegrationEventTypeMutation,
} from "@/generated-models";

import {
  EditFormActions,
  EventFormCard,
} from "../Form/Event/CustomEventFormItem";
import {
  generateFormSchema,
  generateJsonSchema,
} from "../Form/Event/schemaUtils";
import { IntegrationFormEventField } from "../Form/Event/utils";
import { SchemaActionButton } from "../SchemaActionButton";
import { DEFAULT_CONNECT_EVENT, FormMode } from "../constant";
import { useCurrentIntegrationEventTypes } from "../hooks/eventHooks";
import { useCurrentIntegrationId } from "../hooks/integrationHooks";

interface EditFormCardProps {
  id: number;
  event: IntegrationFormEventField;
  mode: FormMode;
  children?: ReactNode;
  onCancel: () => void;
}

function EditFormCard({
  id,
  event,
  mode,
  children,
  onCancel,
}: EditFormCardProps) {
  const integrationId = useCurrentIntegrationId();
  const { pushSnackbar } = useFeedback();
  const { open: openDeleteDialog, ...deleteDialogProps } = useDialog();

  const [createEvents] = useCreateIntegrationEventTypesMutation({
    onError: (error) =>
      pushSnackbar(
        "Unable to create the integration event type, please try again.",
        FeedbackType.Error
      ),
  });

  const [editEventType] = useUpdateIntegrationEventTypeMutation({
    refetchQueries: ["integrationEventTypesV2"],
    onError: () =>
      pushSnackbar(
        "Failed to update the integration event type, please try again",
        FeedbackType.Error
      ),
  });

  const [deleteEventType] = useDeleteIntegrationEventTypeMutation({
    onError: () =>
      pushSnackbar(
        "Failed to delete the integration event type, please try again",
        FeedbackType.Error
      ),
  });

  const form = useForm<{ events: IntegrationFormEventField[] }>({
    defaultValues: {
      events: [event],
    },
    mode: "onChange",
  });

  return (
    <FormProvider {...form}>
      <DefaultDialog
        title="Delete Event Type"
        confirmText="Delete"
        content="Are you sure you want to delete this event type? This will delete all associated events."
        {...deleteDialogProps}
      />
      <form
        className="flex flex-col items-start justify-center gap-2 w-full"
        onSubmit={form.handleSubmit(async ({ events }) => {
          if (mode === FormMode.Edit) {
            await editEventType({
              variables: {
                input: {
                  integrationId,
                  integrationEventTypeId: id,
                  name: events[0].name,
                  buffer: events[0].buffer,
                  duration: events[0].duration,
                },
              },
            });
            form.resetField("events.0.name", { defaultValue: events[0].name });
            form.resetField("events.0.buffer", {
              defaultValue: events[0].buffer,
            });
            form.resetField("events.0.duration", {
              defaultValue: events[0].duration,
            });
          } else {
            await createEvents({
              refetchQueries: ["integrationEventTypesV2"],
              variables: {
                input: {
                  integrationId,
                  integrationEventTypes: events.map((e) => ({
                    name: e.name,
                    schema: generateJsonSchema(e.properties),
                    buffer: e.buffer,
                    duration: e.duration,
                  })),
                },
              },
            });
            onCancel();
          }
        })}
      >
        <EventFormCard
          mode={mode}
          className="w-full"
          idx={0}
          onDelete={async () => {
            if (mode === FormMode.Create) {
              onCancel();
            }

            const deleteConfirmed = await openDeleteDialog();
            if (!deleteConfirmed) return;

            deleteEventType({
              variables: {
                input: {
                  integrationEventTypeId: id,
                  integrationId,
                },
              },
              update(cache) {
                cache.evict({
                  id: `IntegrationEventTypeV2:${id}`,
                });
              },
            });
          }}
        />
        {children}
      </form>
    </FormProvider>
  );
}

export function IntegrationEditEventsForm() {
  const [adding, setAdding] = useState(false);
  const { types } = useCurrentIntegrationEventTypes();

  function toggleAdding() {
    setAdding(!adding);
  }

  return (
    <div className="flex flex-col gap-6">
      {[...types]
        .sort((a, b) => a.id - b.id)
        .map((t) => (
          <EditFormCard
            key={t.id}
            mode={FormMode.Edit}
            id={t.id}
            onCancel={() => {}}
            event={{
              name: t.name,
              duration: t.duration,
              buffer: t.buffer,
              properties: generateFormSchema(t.schema),
            }}
          />
        ))}
      {adding && (
        <>
          <EditFormCard
            mode={FormMode.Create}
            id={-1}
            onCancel={() => {
              setAdding(false);
            }}
            event={{
              ...DEFAULT_CONNECT_EVENT,
            }}
          >
            <div className="flex items-center justify-end w-full">
              <EditFormActions
                onCancel={() => {
                  setAdding(false);
                }}
              />
            </div>
          </EditFormCard>
        </>
      )}
      <div className={clsx(adding && "invisible")}>
        <SchemaActionButton large onClick={toggleAdding}>
          + Add Event
        </SchemaActionButton>
      </div>
    </div>
  );
}
