import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ExpandIcon from "@mui/icons-material/ChevronRight";
import LockIcon from "@mui/icons-material/Lock";
import {
  Box,
  Collapse,
  IconButton,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import gql from "graphql-tag";
import { ReactNode, useEffect, useState } from "react";
import {
  Link,
  Navigate,
  Route,
  Routes,
  useLocation,
  useMatch,
  useNavigate,
} from "react-router-dom";
import { makeStyles, withStyles } from "tss-react/mui";

import { ScrollToTopOnMount } from "@/util/ScrollToTop";

import { ApplianceSetup } from "@/pages/Settings/LocationSettings/Appliance/ApplianceSetup";
import { CameraSetup } from "@/pages/Settings/LocationSettings/Camera/CameraSetup";
import { LocationCreateEdit } from "@/pages/Settings/LocationSettings/General/LocationCreateEdit";
import { InstallationNotes } from "@/pages/Settings/LocationSettings/InstallationNotes";
import WalkthroughBar from "@/pages/Settings/LocationSettings/Walkthrough/WalkthroughBar";
import WalkthroughHeader from "@/pages/Settings/LocationSettings/Walkthrough/WalkthroughHeader";

import { PHIWarning } from "@/components/PHIWarning";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  useGetLocationAppliancesCamerasQuery,
  useGetLocationQuery,
} from "@/generated-models";

import { useSettingsRoutes } from "../SettingsRoutes";

const useStyles = makeStyles()((theme) => ({
  expandButton: {
    transition: "0.25s transform",
    backgroundColor: "#fff",
    color: "#353D48",
    border: "solid 1px #c8c8c8",
    marginLeft: 8,
    "& svg": { fontSize: 18 },
  },
  headerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    padding: "0 8px",
  },
  header: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    minHeight: theme.spacing(7),
  },
  divider: {
    position: "absolute",
    width: "100%",
    height: 6.8,
    opacity: 0.4,
    backgroundImage:
      "linear-gradient(to bottom, rgba(0, 0, 0, 0.37), rgba(255, 255, 255, 0))",
  },
  navigation: { background: "transparent" },
}));

export interface TabSpec {
  tab: string;
  Component: ({
    locationId,
    setup,
  }: {
    setup?: boolean;
    locationId: number;
  }) => JSX.Element;
  name: string;
  description: ReactNode;
  complete?: boolean;
  unlocked?: boolean;
}

const TAB_GENERAL: TabSpec = {
  tab: "location",
  Component: LocationCreateEdit,
  name: "Location",
  description: "Enter the name and location details below.",
};
const TAB_APPLIANCE: TabSpec = {
  tab: "appliances",
  Component: ApplianceSetup,
  name: "Appliances",
  description: (
    <>
      Connect your appliance(s) to your <strong>camera network switch</strong>{" "}
      and power it on. After, register your appliances here.
    </>
  ),
};
const TAB_CAMERAS: TabSpec = {
  tab: "cameras",
  Component: CameraSetup,
  name: "Cameras",
  description:
    "We automatically scan your network for cameras. Once found, you will need to authenticate and configure the camera’s settings.",
};

function useTabsState(locationId?: number): [TabSpec[], boolean] {
  const { data, loading } = useGetLocationAppliancesCamerasQuery({
    variables: {
      locationId: locationId || -1,
    },
    skip: !locationId,
  });
  const locationComplete = !!locationId;
  const applianceComplete =
    locationComplete && !!data?.location?.appliances.length;
  const cameraComplete = applianceComplete && !!data?.location?.cameras.length;
  return [
    [
      { ...TAB_GENERAL, complete: locationComplete, unlocked: true },
      {
        ...TAB_APPLIANCE,
        complete: applianceComplete,
        unlocked: locationComplete,
      },
      { ...TAB_CAMERAS, complete: cameraComplete, unlocked: applianceComplete },
    ],
    loading,
  ];
}

export function SetupFlow() {
  const { classes } = useStyles();
  const theme = useTheme();
  const [notesOpen, setNotesOpen] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const settingsRoutes = useSettingsRoutes();
  const editMatch = useMatch(`${settingsRoutes.locations}/:locationId/*`);
  const locationId = Number(editMatch?.params.locationId);
  const [tabs, loading] = useTabsState(locationId);

  // Only redirect to setup tab after the tab load states are
  // calculated and if the path name is not create
  useEffect(() => {
    if (locationId && !loading) {
      const uncomplete = tabs.find((t) => !t.complete);
      if (uncomplete) {
        navigate(
          `${settingsRoutes.locations}/${locationId}/${uncomplete.tab}`,
          {
            replace: true,
          }
        );
      }
    }
    // eslint-disable-next-line
  }, [locationId, loading]);

  const { data } = useGetLocationQuery({
    variables: { id: locationId },
    skip: !locationId,
    ...refetchOnMountPolicy,
  });
  const setup =
    (data?.location?.isSetup !== undefined && !data?.location?.isSetup) ||
    location.pathname.includes("create");

  const tabIndex = tabs.findIndex((candidate) =>
    location.pathname.endsWith(candidate.tab)
  );
  const tabLocation = tabIndex > -1 ? tabs[tabIndex].tab : tabs[0].tab;

  return (
    <>
      <ScrollToTopOnMount />
      {setup && (
        <div className="rounded-t overflow-hidden">
          <WalkthroughBar steps={tabs} locationId={locationId} />
        </div>
      )}
      <div className={classes.headerContainer}>
        <div className={classes.header}>
          <IconButton component={Link} to=".." size="large">
            <ChevronLeftIcon
              fontSize="large"
              style={{ color: theme.palette.text.primary }}
            />
          </IconButton>
          <Typography
            variant="h4"
            component="h1"
            style={{ display: "inline-block" }}
          >
            {locationId ? data?.location?.name : "Create Location"}
          </Typography>
          <Box flexGrow={1} />
          {!!locationId && (
            <>
              <PHIWarning
                style={{
                  marginLeft: "auto",
                  marginRight: theme.spacing(1),
                  transition: "all 0.1s",
                  opacity: notesOpen ? 1 : 0,
                }}
              />
              <Typography
                variant="subtitle2"
                style={{ display: "inline-block", opacity: 0.5 }}
              >
                Notes
              </Typography>
              <IconButton
                size="small"
                className={classes.expandButton}
                style={{
                  transform: `rotate(${notesOpen ? 90 : -90}deg)`,
                  padding: 0,
                }}
                onClick={() => setNotesOpen(!notesOpen)}
              >
                <ExpandIcon />
              </IconButton>
              <Box m={1} />
            </>
          )}
        </div>
        {!!locationId && (
          <Collapse style={{ width: "100%" }} in={notesOpen}>
            <Box width="100%" px={2} pb={2}>
              <InstallationNotes locationId={locationId} />
            </Box>
          </Collapse>
        )}
      </div>
      <div className={classes.divider} />
      {(!!locationId || setup) && (
        <Box
          style={{ background: "RGBA(235, 235, 235, 1.00)" }}
          pt={1}
          px={0.5}
          mb={1}
        >
          <SetupNavTabs value={tabLocation} className={classes.navigation}>
            {tabs.map(({ tab, name, unlocked }) => (
              <SetupNavTab
                key={tab}
                label={
                  <>
                    {!unlocked && (
                      <LockIcon
                        style={{ marginRight: 4, fontSize: 14 }}
                        fontSize="small"
                      />
                    )}
                    {name}
                  </>
                }
                component={Link}
                to={`${locationId}/${tab}`}
                value={tab}
                disabled={!unlocked}
              />
            ))}
          </SetupNavTabs>
        </Box>
      )}

      <Routes>
        <Route
          path="create"
          element={
            <>
              {setup && (
                <WalkthroughHeader
                  title={`1. ${tabs[0].name}`}
                  description={tabs[0].description}
                />
              )}
              <LocationCreateEdit />
            </>
          }
        />
        <Route path=":locationId/*">
          <Route index element={<Navigate to="cameras" replace />} />
          {tabs.map(({ tab, Component, name, description }, idx) => (
            <Route
              key={tab}
              path={tab}
              element={
                <>
                  {setup && (
                    <WalkthroughHeader
                      title={`${idx + 1}. ${name}`}
                      description={description}
                    />
                  )}
                  <Component setup={setup} locationId={locationId} />
                </>
              }
            />
          ))}
        </Route>
      </Routes>
    </>
  );
}

const SetupNavTabs = withStyles(Tabs, (theme) => ({
  root: {
    minHeight: 1,
  },
  indicator: {
    background: "white",
    height: "100%",
    zIndex: 2,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
}));

const SetupNavTab = withStyles(Tab, (theme) => ({
  root: {
    minHeight: 1,
    padding: "6px 15px",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    minWidth: 100,
    fontWeight: "normal",
    zIndex: 3,
  },
  selected: {
    fontWeight: "bold",
  },
  wrapper: {
    flexDirection: "row",
    justifyContent: "center",
  },
}));

export const GET_LOCATION_APPLIANCES_CAMERAS = gql`
  query getLocationAppliancesCameras($locationId: Int!) {
    location(id: $locationId) {
      id
      cameras {
        id
      }
      appliances {
        id
      }
    }
  }
`;
