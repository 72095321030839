import AddBoxIcon from "@mui/icons-material/AddBox";
import Close from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import ErrorIcon from "@mui/icons-material/Error";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ExpandIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Button,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { Field, FieldArray, useField } from "formik";
import { Checkbox } from "formik-mui";
import gql from "graphql-tag";
import { orderBy } from "lodash/fp";
import { useState } from "react";
import { makeStyles } from "tss-react/mui";

import { useBreakpoints } from "@/util/useBreakpoints";

import { AlertsCamDrawer } from "@/pages/Alerts/AlertsCamDrawer";

import { TimePickerFormik } from "@/components/DateTimePicker/DateTimePicker";
import { ErrorMessage } from "@/components/ErrorMessage";
import { InterestListSelectFormItem } from "@/components/InterestList/InterestListSelectFormItem";
import { Loading } from "@/components/Loading";
import DrawZone from "@/components/Zones/DrawZone";
import { useActiveZones, ZoneSelect } from "@/components/Zones/ZoneSelect";
import { Point } from "@/components/Zones/getSectorsForPolygon";
import { CameraImage } from "@/components/shared/LazyImage";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  AlertCameraInput,
  AlertQuery,
  AlertType,
  useCamZonesQuery,
  useUserNamesQuery,
} from "@/generated-models";

import { AlertCustomInformation } from "./AlertCustomInformation";
import { formattedAlertTypes } from "./constants";

export const useStyles = makeStyles<void, "dayCheckbox">()(
  (theme, _props, classes) => ({
    block: {
      display: "flex",
      flexDirection: "column",
      alignItems: "baseline",
      "& > div:first-of-type": {
        marginTop: 0,
      },
      "& > div:last-child": {
        marginBottom: 0,
      },
    },
    formItem: {
      minWidth: 220,
      margin: "10px 0",

      [theme.breakpoints.down("sm")]: {
        minWidth: "initial",
        width: "100%",
      },
    },
    subHeader: {
      fontSize: 16,
      fontWeight: "bold",
      marginTop: 10,
    },
    daySelect: {
      "& input": {
        position: "absolute",
        opacity: 0,
        width: 0,
        height: 0,
      },
      [`& input:checked + .${classes.dayCheckbox}`]: {
        backgroundColor: theme.palette.primary.main,
      },
    },
    dayCheckbox: {
      marginRight: 3,
      padding: "1px 12px",
      color: "#fff",
      backgroundColor: "#d6d6d6",
      borderRadius: 2,
      fontWeight: "bold",
      cursor: "pointer",

      [theme.breakpoints.down("sm")]: {
        padding: "1px 6px",
      },
    },
    autoComplete: {
      background: "#FFFFFF",
      border: "1px solid #64B6FB",
      boxSizing: "border-box",
      borderRadius: "6px",
    },
    pinCommunicationToTop: {
      verticalAlign: "initial",
    },
  })
);

/*function AiCheck() {
  const [validationPromise] = useState(() => {
    let resolve: (value: string | void) => void = () => {};
    let reject: (error: Error) => void = () => {};

    const promise = new Promise<string | void>((res, rej) => {
      resolve = res;
      reject = rej;
    });

    return { promise, resolve, reject };
  });
  const [, meta, helpers] = useField<null | boolean>({
    name: "aiCheck",
    validate: () => validationPromise.promise,
  });

  useEffect(() => {
    // Resets the error if this <AiCheck> component is unmounted. It's a way of clearing the validation
    // when a non-AI alert type is selected.
    return () => {
      helpers.setError(undefined);
      helpers.setTouched(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useApplianceAiEnabledQuery({
    onCompleted(data) {
      const hasAiEnabledCamera = data.appliances.some(
        (a) =>
          a.deviceAiMode === ApplianceAiMode.Nvidia &&
          a.cameras.some((c) => c.lifecycleState === LifecycleStates.Enabled)
      );

      validationPromise.resolve(
        hasAiEnabledCamera
          ? void 0
          : "No AI Enabled cameras found. Please contact your customer support rep."
      );

      helpers.setValue(hasAiEnabledCamera);
    },
  });

  return meta.error ? (
    <Typography className="text-red-800 font-medium">{meta.error}</Typography>
  ) : null;
}*/

export function AlertCameras({
  proMode = false,
  allowCameraSelection = true,
}: {
  proMode?: boolean;
  allowCameraSelection?: boolean;
}) {
  const { fitsTablet } = useBreakpoints();
  const [, { value: alertType }] = useField<AlertType>("type");
  const [, { value: cameraIds }] = useField<AlertCameraInput[]>("cameras");
  const { classes } = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <div className={classes.block}>
      <div className={classes.subHeader}>Cameras</div>
      {allowCameraSelection && (
        <>
          <div className="max-w-sm">
            {fitsTablet ? (
              <>
                Select cameras for this alert from the drawer on the right, then
                select zones from the dropdown
              </>
            ) : (
              <>
                Select cameras for this alert from the button below, then select
                zones from the dropdown. New zones need to be created on
                desktop.
              </>
            )}
          </div>
          <div className="flex mt-4 items-center">
            <Button
              variant="contained"
              color="primary"
              style={{ marginRight: 12 }}
              onClick={() => setDrawerOpen(true)}
            >
              CAMERA SELECT
            </Button>
            <div>{cameraIds.length} Cameras selected</div>
          </div>
        </>
      )}

      {alertType !== AlertType.CameraOffline && <ZoneSelection />}
      {allowCameraSelection && (
        <AlertsCamDrawer
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          onOpen={() => setDrawerOpen(true)}
        />
      )}
    </div>
  );
}

const cappedCameraListCount = 5;

function ZoneSelection() {
  const { fitsTablet } = useBreakpoints();
  // TODO: potentially narrow this query down to only the cams that have been selected.
  const { data, error } = useCamZonesQuery(refetchOnMountPolicy);
  const [, { value: selectedCameras }, helpers] = useField<AlertCameraInput[]>(
    "cameras"
  );
  const [capped, setCapped] = useState(true);

  if (!data?.cameras) {
    return selectedCameras.length > 0 ? (
      <Loading className="m-8">Fetching Cameras</Loading>
    ) : null;
  }
  if (error) {
    return selectedCameras.length > 0 ? (
      <ErrorMessage title="Error" description={error.message} />
    ) : null;
  }
  const cams = data.cameras.filter((c) =>
    selectedCameras.some((selected) => selected.id === c.id)
  );
  const camsCapped = cams && cams.length > cappedCameraListCount && capped;
  const cappedCams = cams.slice(
    0,
    camsCapped ? cappedCameraListCount : undefined
  );
  // const zones = flatten(data.cameras.map((c) => c.focusZones)).filter((z) =>
  //   zoneIds.includes(z.id)
  // );

  return (
    <>
      <div className="flex flex-col gap-4 my-4 w-full">
        {cappedCams.map((cam) => {
          const selectedCam = selectedCameras.find((c) => c.id === cam.id);
          if (!selectedCam) return null; // this shouldn't happen
          const zoneSelect = (
            <ZoneSelect
              cameraId={cam.id}
              snapshotSource={cam.still}
              feeds={cam.feeds ?? undefined}
              multi
              zones={cam.focusZones}
              zoneIds={selectedCam.zoneIds}
              setZoneIds={(ids) => {
                helpers.setValue(
                  selectedCameras.map((c) =>
                    c === selectedCam
                      ? {
                          ...c,
                          zoneIds: ids,
                        }
                      : c
                  )
                );
              }}
            />
          );

          return (
            <div key={cam.id} className="flex flex-col gap-4">
              <div className="flex gap-4 overflow-hidden">
                <div className="relative">
                  <CameraImage
                    alt={cam.name}
                    className="self-center w-[140px] md:w-[200px] rounded-[1px]"
                    cameraId={cam.id}
                  />
                  <ActiveZoneOverlay
                    zones={cam.focusZones}
                    zoneIds={selectedCam.zoneIds}
                  />
                </div>
                <div className="flex flex-col flex-grow overflow-hidden">
                  <div className="flex items-start justify-between gap-2">
                    <Typography className="font-bold">{cam.name}</Typography>
                    <IconButton
                      size="small"
                      className="-mt-1"
                      onClick={() => {
                        helpers.setValue(
                          selectedCameras.filter((c) => c.id !== cam.id)
                        );
                      }}
                    >
                      <Close fontSize="small" />
                    </IconButton>
                  </div>
                  <div className="flex-grow" />
                  <Typography className="text-sm opacity-50 truncate">
                    {cam.location.name}
                  </Typography>
                  {fitsTablet && (
                    <>
                      <div className="h-2" />
                      {zoneSelect}
                    </>
                  )}
                </div>
              </div>
              {!fitsTablet && zoneSelect}
            </div>
          );
        })}
        {camsCapped && (
          <Button
            color="primary"
            endIcon={<ExpandIcon />}
            onClick={() => setCapped(false)}
          >
            View All ({cams.length - cappedCameraListCount} more)
          </Button>
        )}
      </div>
    </>
  );
}

function ActiveZoneOverlay({
  zoneIds,
  zones,
}: {
  zoneIds: number[];
  zones: { id: number; shape: Point[] }[];
}) {
  const activeZones = useActiveZones(zones, zoneIds);
  return (
    <>
      {activeZones.map((activeZone) => (
        <DrawZone
          key={activeZone.id}
          shape={activeZone.shape}
          setShape={() => {}}
          editing={false}
          setEditing={() => {}}
          enableEdit={false}
        />
      ))}
    </>
  );
}

const weekDays = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

export function AlertInterestList() {
  return (
    <div>
      <Typography className="font-bold text-base leading-[18.75px]">
        License Plate Lists
      </Typography>
      <Typography className="text-sm leading-[16.41px] mb-2">
        Create a list of license plates of interest that you wanted to be
        alerted about when they are detected.
      </Typography>

      <InterestListSelectFormItem hideLabel />
    </div>
  );
}

export function AlertTimeRange() {
  const { classes } = useStyles();
  const [, { value: alertType }] = useField<AlertType>("type");
  const [, { value: startTime }] = useField<AlertType>("startTime");
  const [, { value: endTime }] = useField<AlertType>("endTime");

  return (
    <div className={classes.block}>
      {/* <div className={classes.subHeader}>Calendar</div>
    <div>Select the start and end date for this alert.</div> */}
      <div className={classes.subHeader}>Days of the Week</div>
      <div>
        Watch for {formattedAlertTypes[alertType].toLowerCase()} events during
        these days of the week.
      </div>
      <div className="flex flex-wrap gap-1 my-2 relative">
        {weekDays.map((day, i) => (
          <label key={day}>
            <Field
              type="checkbox"
              name="daysOfWeek"
              value={String(i)}
              className="peer absolute opacity-0"
            />
            <div className="px-3.5 py-1.5 border border-gray-300 text-[#757575] rounded-sm cursor-pointer peer-checked:font-medium peer-checked:border-primary peer-checked:bg-[#F6FBFF] peer-checked:text-primary outline-1 outline-primary peer-focus-visible:outline-dashed select-none">
              {day}
            </div>
          </label>
        ))}
      </div>
      <div className={classes.subHeader}>Time of Day</div>
      <div>Select the active alert time of day.</div>
      <div className="flex flex-wrap gap-2 mt-2">
        <div>
          <Typography variant="body2" className="font-medium">
            Start time
          </Typography>
          <Field
            component={TimePickerFormik}
            name="startTime"
            variant="outlined"
          />
        </div>
        <div>
          <Typography variant="body2" className="font-medium">
            End time
          </Typography>
          <Field
            component={TimePickerFormik}
            name="endTime"
            variant="outlined"
          />
        </div>
      </div>
      {startTime > endTime && (
        <Typography variant="body2" className="text-primary mt-2 italic">
          *This will be an overnight time range for each day selected
        </Typography>
      )}
    </div>
  );
}

export const alertUserTemplate = {
  isOwner: false,
  receiveNotifications: true,
  receiveEmails: false,
  receiveTexts: false,
  receivePush: false,
  receiveDigestEmails: false,
};

type AlertFormUser = Pick<
  AlertQuery["alert"]["users"][number],
  | "name"
  | "orgUserId"
  | "isOwner"
  | "hasPhoneNumber"
  | "hasMobileApp"
  | "receivePush"
  | "receiveEmails"
  | "receiveTexts"
  | "receiveDigestEmails"
>;

export function AlertCommunication({ isDemo = false }: { isDemo?: boolean }) {
  const [{ value: values }] = useField<AlertFormUser[]>("users");
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { data: userNamesData } = useUserNamesQuery({
    // Background info on nextFetchPolicy (buried in an issue):
    // https://github.com/apollographql/apollo-client/issues/6760#issuecomment-668188727
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const handleClose = () => setAnchorEl(null);
  const orgUserIds = values.map((v) => v.orgUserId);
  const users = orderBy(
    (u) => u.name.toLowerCase(),
    "asc",
    userNamesData?.users.filter((u) => !orgUserIds.includes(u.orgUserId))
  );

  return (
    <div className={classes.block}>
      <div className={classes.subHeader}>Alert Communication</div>
      <div>
        Get a daily summary of all alerts and/or get instant alerts as events
        happen. Selecting only the daily summary option avoids your inbox
        filling up with individual alerts.
      </div>
      <FieldArray
        name="users"
        render={(helpers) => (
          <>
            <Table style={{ marginTop: 16 }}>
              <TableHead>
                <TableRow>
                  <TableCell>User</TableCell>
                  <TableCell align="center">Daily Summary</TableCell>
                  <TableCell align="left">Instant Alerts</TableCell>
                  <TableCell /> {/* For delete icon*/}
                </TableRow>
              </TableHead>
              <TableBody>
                {values
                  .filter((user) =>
                    userNamesData?.users.some(
                      (u) => u.orgUserId === user.orgUserId
                    )
                  )
                  .map(
                    (
                      {
                        name,
                        orgUserId,
                        isOwner,
                        hasPhoneNumber,
                        hasMobileApp,
                        receivePush,
                      },
                      index
                    ) => (
                      <TableRow
                        key={orgUserId}
                        className={classes.pinCommunicationToTop}
                      >
                        <TableCell align="left" padding="checkbox">
                          {name}
                          {isOwner ? " (Owner)" : ""}
                        </TableCell>
                        <TableCell align="center" padding="checkbox">
                          <FormControlLabel
                            control={
                              <Field
                                name={`users[${index}].receiveDigestEmails`}
                                component={Checkbox}
                                type="checkbox"
                                color="primary"
                                size="small"
                              />
                            }
                            label="Email"
                          />
                        </TableCell>
                        <TableCell align="left" padding="checkbox">
                          <FormControlLabel
                            control={
                              <Field
                                name={`users[${index}].receiveEmails`}
                                component={Checkbox}
                                type="checkbox"
                                color="primary"
                                size="small"
                              />
                            }
                            label="Email"
                          />
                          <br />
                          <Tooltip
                            title={
                              hasPhoneNumber
                                ? ""
                                : "Phone number not added for user"
                            }
                          >
                            <FormControlLabel
                              control={
                                <Field
                                  name={`users[${index}].receiveTexts`}
                                  component={Checkbox}
                                  type="checkbox"
                                  color="primary"
                                  size="small"
                                />
                              }
                              label="SMS"
                              disabled={!hasPhoneNumber}
                            />
                          </Tooltip>
                          <br />
                          <div>
                            <FormControlLabel
                              // classes={{ label: clsx(isDigest && "font-bold") }}
                              control={
                                <Field
                                  name={`users[${index}].receivePush`}
                                  component={Checkbox}
                                  type="checkbox"
                                  color="primary"
                                  size="small"
                                />
                              }
                              label="Mobile App"
                            />
                            <Tooltip
                              title={
                                hasMobileApp
                                  ? "To receive mobile app notifications, please make sure to have the Spot AI mobile app installed and notifications enabled."
                                  : "This user doesn't seem to have the mobile app installed. They will not be able to receive Mobile App Notifications."
                              }
                            >
                              {hasMobileApp || !receivePush ? (
                                <InfoOutlinedIcon fontSize="medium" />
                              ) : (
                                <ErrorIcon
                                  fontSize="medium"
                                  style={{ color: "#E30000" }}
                                />
                              )}
                            </Tooltip>
                          </div>
                        </TableCell>
                        <TableCell align="center" padding="checkbox">
                          {!isOwner && (
                            <Tooltip title="Remove user">
                              <IconButton
                                onClick={() => helpers.remove(index)}
                                size="large"
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        </TableCell>
                      </TableRow>
                    )
                  )}
              </TableBody>
            </Table>
            <Button
              color="primary"
              startIcon={<AddBoxIcon />}
              style={{ marginTop: 8 }}
              aria-controls="user-menu"
              aria-haspopup="true"
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              Add user
            </Button>
            <Menu
              id="user-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {users && users.length > 1 && (
                <MenuItem
                  onClick={() => {
                    users.forEach(({ orgUserId, name, email, phone }) =>
                      helpers.push({
                        ...alertUserTemplate,
                        name: name || email,
                        orgUserId,
                        hasPhoneNumber: !!phone,
                      })
                    );
                    handleClose();
                  }}
                >
                  Add all users
                </MenuItem>
              )}
              {users?.map(({ orgUserId, name, email, phone }) => (
                <MenuItem
                  key={orgUserId}
                  onClick={() => {
                    helpers.push({
                      ...alertUserTemplate,
                      name: name || email,
                      orgUserId,
                      hasPhoneNumber: !!phone,
                    });
                    handleClose();
                  }}
                >
                  {name || email}
                </MenuItem>
              ))}
              {!users && (
                <MenuItem onClick={handleClose}>
                  Failed loading users...
                </MenuItem>
              )}
              {users?.length === 0 && (
                <MenuItem onClick={handleClose}>
                  All users have been added
                </MenuItem>
              )}
            </Menu>
          </>
        )}
      />
      <AlertCustomInformation className="pt-4" />
      {isDemo && (
        <MuiAlert
          elevation={2}
          variant="filled"
          severity="warning"
          style={{ marginTop: 16 }}
        >
          Alerts cannot be created in demo environment
        </MuiAlert>
      )}
    </div>
  );
}

export function required(message: string) {
  return (input: string) => (!input ? message : undefined);
}

export const GET_USER_NAMES = gql`
  query userNames {
    users {
      id
      orgUserId
      name
      email
      phone
    }
  }
`;

gql`
  query applianceAiEnabled {
    appliances {
      id
      serialNumber
      cameras {
        id
        lifecycleState
        aiEnabled
      }
    }
  }
`;

gql`
  query camZones {
    cameras {
      id
      name
      still
      feeds {
        tunnel
        local
      }
      location {
        id
        name
      }
      focusZones {
        id
        name
        shape {
          x
          y
        }
      }
    }
  }
`;
