import React from "react";

import { IconProps } from "@/icons/IconProps";

export function AlertTriangleIcon({ style, className, onClick }: IconProps) {
  return (
    <svg
      style={style}
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="19"
      viewBox="0 0 22 19"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FFF"
          stroke="#FFF"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M0 15.82L9.18 0 18.36 15.82z"
          transform="translate(1.758 1.5)"
        />
        <path
          fill="#E51C1C"
          fillRule="nonzero"
          d="M18.36 15.82L9.18 0 0 15.82h18.36zM10 10H8.36V6.64H10V10zm0 3.32H8.36v-1.68H10v1.68z"
          transform="translate(1.758 1.5)"
        />
      </g>
    </svg>
  );
}
