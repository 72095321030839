import { atom } from "jotai";

import { useCopilotEnabled } from "../copilotQueryHooks";
import { useCopilotContext } from "../useCopilotContext";

const aiCopilotGenerativeLoading = atom(false);
aiCopilotGenerativeLoading.debugLabel = "aiCopilotGenerativeLoading";

export const aiCopilotGenerativeImage = atom("");
aiCopilotGenerativeImage.debugLabel = "aiCopilotGenerativeImage";

export function CopilotOverrideOverlay() {
  const { overrideImageDataURL } = useCopilotContext();
  const { copilotEnabled } = useCopilotEnabled();

  if (!copilotEnabled) return <></>;

  if (overrideImageDataURL) {
    return (
      <img
        className="absolute w-full h-full z-0 pointer-events-none"
        alt="Copilot"
        src={overrideImageDataURL}
      />
    );
  }

  return <></>;
}
