import { LDProvider } from "launchdarkly-react-client-sdk";
import { ReactNode } from "react";

import { isProductionEnv, isStagingEnv } from "@/environment";
import { Role, useMeQuery } from "@/generated-models";

const clientSideID = isProductionEnv
  ? "6286d3b70f5d0714a27ebce5"
  : isStagingEnv
  ? "6286d3b70f5d0714a27ebce4"
  : process.env.REACT_APP_LAUNCH_DARKLY_DEV;

export function LaunchDarklyProvider({
  locationIsKiosk,
  children,
}: {
  locationIsKiosk?: boolean;
  children: ReactNode;
}) {
  const { loading, data } = useMeQuery({ skip: locationIsKiosk });

  if (!clientSideID) return <>{children}</>;

  if (loading) return <>Resolving features...</>;

  const {
    id,
    name,
    email,
    organization,
    role,
    canSwitchOrganization,
  } = data?.me ?? {
    id: -1,
    name: "Anonymous User",
    email: "anon@email.com",
    organization: { id: -1 },
    role: Role.User,
    canSwitchOrganization: false,
  };

  return (
    <LDProvider
      clientSideID={clientSideID}
      key={id} // Ensure we always re-render when the user changes
      context={{
        kind: "user",
        key: `${id}`,
        name: name,
        email: email,
        org: organization.id,
        loginId: id,
        loginFullName: name,
        loginEmail: email,
        loginRole: role,
        canSwitchOrganization,
      }}
      // Bootstrap the values for LD using localStorage which stores the values from last change
      // https://docs.launchdarkly.com/sdk/features/bootstrapping#react-web
      options={{ bootstrap: "localStorage" }}
    >
      {children}
    </LDProvider>
  );
}
