import clsx from "clsx";
import gql from "graphql-tag";
import { useFlags } from "launchdarkly-react-client-sdk";

import { useBreakpoints } from "@/util/useBreakpoints";

import { LazyImage } from "@/components/shared/LazyImage";
import { QueryParamLink } from "@/components/shared/QueryParamLink";

import {
  Camera,
  CameraStatus,
  Tag,
  useRecentCamerasQuery,
  useRecentlyViewedLiveStreamsQuery,
} from "@/generated-models";

function filterOnline(cam: Pick<Camera, "status">) {
  return cam.status === CameraStatus.Online;
}

export function RecentCameras() {
  const { auditLogs } = useFlags();
  return auditLogs ? <NewRecentCameras /> : <OldRecentCameras />;
}

function OldRecentCameras() {
  const { data, error } = useRecentlyViewedLiveStreamsQuery({
    fetchPolicy: "cache-and-network",
  });
  if (error || !data?.recentlyViewedLiveStreams.length) return null;

  const recentCams = data.recentlyViewedLiveStreams
    .map((s) => s.camera)
    .filter(filterOnline);
  return <RecentCamerasInternal cams={recentCams} />;
}

function NewRecentCameras() {
  const { data, error } = useRecentCamerasQuery({
    fetchPolicy: "cache-and-network",
  });
  if (error || !data?.recentCameras.length) return null;

  const recentCams = data.recentCameras.filter(filterOnline);
  return <RecentCamerasInternal cams={recentCams} />;
}

function RecentCamerasInternal({
  cams,
}: {
  cams: (Pick<Camera, "id" | "name"> & {
    tags: Pick<Tag, "id" | "isLocationGroup">[];
  })[];
}) {
  const { fitsTablet } = useBreakpoints();

  const largeItem = fitsTablet
    ? { gridColumn: "span 2", gridRow: "span 2" }
    : undefined;

  return (
    <div className="mt-7 mb-3 px-2 md:px-0.5 w-full">
      <h2 className="text-lg font-bold leading-none">Recent Live Cameras</h2>
      <hr className="border-text border-opacity-30 my-2 md:my-3" />
      <div className="grid grid-cols-1 sm:grid-cols-5 gap-2 md:gap-y-3 md:gap-x-4">
        {cams.slice(0, 7).map((cam, index) => (
          <QueryParamLink
            params={{
              cams: cam.id,
              g: cam.tags.find((t) => t.isLocationGroup)?.id,
            }}
            key={cam.id}
            style={index === 0 ? largeItem : undefined}
            className={clsx("relative rounded overflow-hidden flex h-auto", {
              "aspect-video": index !== 0,
            })}
          >
            <LazyImage
              className="w-full h-full object-cover rounded overflow-hidden"
              containerClassName="w-full"
              cameraId={cam.id}
              alt=""
            />
            <div className="absolute left-1 top-1 right-1 flex overflow-hidden rounded">
              <div className="p-1 rounded bg-gray-900 bg-opacity-70 text-white font-bold text-2xs sm:text-xs leading-tight whitespace-nowrap">
                {cam.name}
              </div>
            </div>
          </QueryParamLink>
        ))}
      </div>
    </div>
  );
}

gql`
  query recentlyViewedLiveStreams {
    recentlyViewedLiveStreams {
      id

      camera {
        id
        name
        status

        tags {
          id
          name
          isLocationGroup
        }
      }
    }
  }
`;

gql`
  query recentCameras {
    recentCameras {
      id
      name
      status

      tags {
        id
        name
        isLocationGroup
      }
    }
  }
`;
