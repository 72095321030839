// https://gist.github.com/didacus/ae19f2e3fb6c5f0de61e3dc7314ad24f
import _debounce from "lodash/debounce";
import { useState, useEffect, useMemo } from "react";

function getSize() {
  return {
    height: window.innerHeight,
    width: window.innerWidth,
  };
}

const useDebouncedWindowResize = ({ debounce = 0 }) => {
  const [windowSize, setSize] = useState(getSize);
  const getWindowSize = () => {
    setSize(getSize());
  };

  const listener = useMemo(
    () => (debounce ? _debounce(getWindowSize, debounce) : getWindowSize),
    [debounce]
  );

  useEffect(() => {
    window.addEventListener("resize", listener);

    return () => {
      window.removeEventListener("resize", listener);
    };
  }, [listener]);

  return windowSize;
};

export default useDebouncedWindowResize;
