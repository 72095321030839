import { atom, useAtom } from "jotai";
import React from "react";

import { SearchBox, SearchBoxProps } from "@/components/SearchBox";

export const searchInputAtom = atom("");

export function CamDrawerSearchBox(
  props: Omit<SearchBoxProps, "input" | "setInput">
) {
  const [input, setInput] = useAtom(searchInputAtom);
  return <SearchBox input={input} setInput={setInput} {...props} />;
}
