import { useAtomValue } from "jotai";
import { Fragment } from "react";

import { IntelligenceDashboardType } from "@/generated-models";

import { ComparativeLocationChip } from "../Core/ComparativeLocationChip";
import { ComparativeTabType } from "../constant";
import { useComparativeDescriptions } from "../hooks";
import { ComparativeDashboardDataProps } from "./Chart/ComparativeDashboardChart";
import { refDashboardsLoadingAtom } from "./Chart/ComparativeDashboardChartFetcher";
import { compContentTabAtom } from "./ComparativeDashboardContentTabs";

export function ComparativeDashboardContentChips({
  referenceDashboards,
  type,
  thresholdSeconds,
  objectTypes,
}: {
  referenceDashboards: ComparativeDashboardDataProps["referenceDashboards"];
  type: IntelligenceDashboardType;
  thresholdSeconds: number;
  objectTypes?: string[] | null | undefined;
}) {
  const descriptions = useComparativeDescriptions(
    type,
    thresholdSeconds,
    objectTypes
  );
  const tab = useAtomValue(compContentTabAtom);
  const loading = useAtomValue(refDashboardsLoadingAtom);

  return (
    <div className="grid gap-3 grid-cols-[repeat(auto-fit,minmax(140px,_1fr))]">
      {referenceDashboards.map((rd, idx) => {
        const c = rd.cameras[0];
        if (!c) return <Fragment key={rd.id}></Fragment>;
        return (
          <ComparativeLocationChip
            key={rd.id}
            location={c.location.name}
            camera={c.name}
            position={idx}
            descriptions={descriptions}
            className="flex-1"
            loading={loading[rd.id]}
            light={tab === ComparativeTabType.IDLE_COUNT}
          />
        );
      })}
    </div>
  );
}
