import { Box } from "@mui/material";
import React, { PropsWithChildren } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { useMe } from "@/components/Auth";

import { useSharingOrganizationQuery } from "@/generated-models";
import { AuthenticatedHeader, GuestHeader } from "@/layout/Header";
import { Layout } from "@/layout/Layout";

import { GuestFooter } from "./GuestLayout";

const useStyles = makeStyles()(() => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    width: "100%",
    background: "#f8f8f8",
    minHeight: "100vh",
  },
}));

export function GuestCaseLayout({
  anonymousCase,
}: {
  anonymousCase: React.ReactNode;
}) {
  const { classes } = useStyles();
  const { token } = useParams();
  const { data } = useSharingOrganizationQuery({
    variables: { shareToken: token ?? "" },
    skip: !token,
  });

  const me = useMe();
  const HeaderComponent = me ? AuthenticatedHeader : GuestHeader;

  return (
    <Layout
      header={
        <HeaderComponent
          customLogoSrc={data?.sharingOrganization.logo ?? undefined}
        />
      }
    >
      <article className={classes.wrapper}>
        <div className="grow flex flex-col w-full mb-10">
          {anonymousCase}

          <GuestFooter />
        </div>
      </article>
    </Layout>
  );
}

export function GuestErrorLayout({ children }: PropsWithChildren<{}>) {
  const me = useMe();
  const HeaderComponent = me ? AuthenticatedHeader : GuestHeader;
  return (
    <Layout header={<HeaderComponent />}>
      <Box>{children}</Box>
      <GuestFooter />
    </Layout>
  );
}
