import { Button, Skeleton, Typography } from "@mui/material";
import { uniq } from "lodash/fp";
import { useMemo } from "react";
import { Link } from "react-router-dom";

import { SpotConnectIcon } from "@/icons/SpotConnectIcon";

import { pluralize } from "@/util/pluralize";

import { ErrorMessage } from "@/components/ErrorMessage";

import { Page_IntegrationsV2Query } from "@/generated-models";
import { usePrefixOrgSlug } from "@/hooks/useOrgRouteBase";

import { useCurrentIntegrationDevices } from "./hooks/deviceHooks";

interface IntegrationCustomActiveVendorCardProps {
  integration: Page_IntegrationsV2Query["integrationsV2"][number];
}

function IntegrationConfiguredTypeContentSummary({
  tagsCount,
  devicesCount,
  cameraCount,
}: {
  tagsCount: number;
  devicesCount: number;
  cameraCount: number;
}) {
  const tagsLabel = pluralize(
    {
      1: "Tag",
      multi: "Tags",
    },
    tagsCount
  );

  const devicesLabel = pluralize(
    {
      1: "Device",
      multi: "Devices",
    },
    devicesCount
  );

  const camerasLabel = pluralize(
    {
      1: "Camera",
      multi: "Cameras",
    },
    cameraCount
  );

  return (
    <>
      <strong>{tagsCount} </strong>
      {tagsLabel}
      {", "}
      <strong>{devicesCount}</strong> {devicesLabel}
      {", "}
      <strong>{cameraCount}</strong> {camerasLabel}
    </>
  );
}

function IntegrationConfiguredTypeContent({
  integration,
}: IntegrationCustomActiveVendorCardProps) {
  const { id } = integration;
  const prefixOrgSlug = usePrefixOrgSlug();

  const { devices, count, loading, error } = useCurrentIntegrationDevices(id);

  const tags = useMemo(() => uniq(devices.flatMap((s) => s.tags)), [devices]);

  const cameraCount = useMemo(() => {
    return devices.flatMap((s) => s.cameraIds).length;
  }, [devices]);

  if (error) {
    return <ErrorMessage title="Error" description={error.toString()} />;
  }

  return (
    <>
      <>
        <Typography className="text-base leading-[19px] pb-7">
          {loading ? (
            <Skeleton variant="text" className="w-[250px] lg:w-[350px]" />
          ) : (
            <IntegrationConfiguredTypeContentSummary
              tagsCount={tags.length}
              devicesCount={count}
              cameraCount={cameraCount}
            />
          )}
        </Typography>
      </>
      <div className="flex items-center justify-between">
        <Button
          variant="contained"
          color="primary"
          className="shadow-none rounded w-[87px]"
          component={Link}
          to={prefixOrgSlug(`/integrations/connect/spot/${id}`)}
        >
          View
        </Button>
      </div>
    </>
  );
}

export function IntegrationCustomActiveVendorCard({
  integration,
}: IntegrationCustomActiveVendorCardProps) {
  return (
    <div className="rounded-2xl bg-white flex flex-col items-center shadow-[0_19px_22px_rgba(0,42,82,0.1)] p-8">
      <div className="flex flex-col gap-3 w-full">
        <div className="flex items-center justify-between">
          <Typography className="text-primary text-lg leading-[21px] font-bold">
            {integration.name}
          </Typography>
          <div>
            <SpotConnectIcon />
          </div>
        </div>
        <div>
          <IntegrationConfiguredTypeContent integration={integration} />
        </div>
      </div>
    </div>
  );
}
