import { Form, Formik } from "formik";
import { useAtom, useAtomValue } from "jotai";
import { omit } from "lodash/fp";
import { useMemo } from "react";

import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";

import {
  InterestListType,
  Page_InterestListsDocument,
  useAddToInterestListsMutation,
  useCreateInterestListMutation,
  usePage_InterestListsQuery,
  useUpdateInterestListMutation,
} from "@/generated-models";

import { InterestListFormMode } from "../constants";
import {
  currentListItemAtom,
  defaultCloseAtom,
  listModeAtom,
  selectedListItemsAtom,
} from "../hooks";
import { InterestListActions } from "./InterestListActions";
import { InterestListAddItemForm } from "./InterestListAddItemForm";
import { InterestListDetailsForm } from "./InterestListDetailsForm";
import { InterestListSelectionMenu } from "./InterestListSelectionMenu";

interface InterestListMainFormProps {
  initialValue?: string;
  type: InterestListType;
  primaryActionLabel: string;
  onPrimaryAction: () => void;
  onClose: () => void;
}

function MainFormContent({
  type,
  primaryActionLabel,
  onPrimaryAction,
  onClose,
}: InterestListMainFormProps) {
  const mode = useAtomValue(listModeAtom);

  let form;

  switch (mode) {
    case InterestListFormMode.view:
      form = <InterestListSelectionMenu type={type} />;
      break;
    case InterestListFormMode.insert:
      form = <InterestListAddItemForm type={type} />;
      break;
    default:
      form = <InterestListDetailsForm type={type} />;
  }

  return (
    <>
      {form}
      <InterestListActions
        primaryActionLabel={primaryActionLabel}
        onPrimaryAction={onPrimaryAction}
        onClose={onClose}
      />
    </>
  );
}

export function InterestListMainForm({
  initialValue,
  type,
  primaryActionLabel,
  onPrimaryAction,
  onClose,
}: InterestListMainFormProps) {
  const { pushSnackbar } = useFeedback();
  const { data } = usePage_InterestListsQuery();
  const [mode, setMode] = useAtom(listModeAtom);
  const defaultClose = useAtomValue(defaultCloseAtom);
  const selectedListItems = useAtomValue(selectedListItemsAtom);

  const [currentListItem, setCurrentListItem] = useAtom(currentListItemAtom);

  const initialValues = useMemo(() => {
    if (!currentListItem) {
      if (initialValue) {
        return { items: [{ name: initialValue }] };
      } else {
        return null;
      }
    }
    const res = data?.interestLists.find((list) => list.id === currentListItem);
    return res
      ? {
          type: res.type,
          name: res.name,
          items: res.items.map((i) => omit("__typename", i)),
        }
      : null;
  }, [data?.interestLists, currentListItem, initialValue]);

  const [createInterestList] = useCreateInterestListMutation({
    refetchQueries: [{ query: Page_InterestListsDocument }],
    onError: () =>
      pushSnackbar(
        "We were unable to create the list of interest. Please try again later.",
        FeedbackType.Error
      ),
  });

  const [updateInterestList] = useUpdateInterestListMutation({
    refetchQueries: [{ query: Page_InterestListsDocument }],
    onError: (e) => {
      pushSnackbar(
        "We were unable to update the list of interest. Please try again later.",
        FeedbackType.Error
      );
    },
  });

  const [addToInterestLists] = useAddToInterestListsMutation({
    refetchQueries: [{ query: Page_InterestListsDocument }],
    onError: (e) => {
      pushSnackbar(
        "We were unable to update the lists of interest. Please try again later.",
        FeedbackType.Error
      );
    },
  });

  return (
    <Formik
      initialValues={{
        type,
        name: initialValues?.name ?? "",
        items: initialValues?.items ?? [],
      }}
      enableReinitialize
      onSubmit={async (values) => {
        if (mode === InterestListFormMode.insert) {
          await addToInterestLists({
            variables: { ids: selectedListItems, input: values.items[0] },
          });
        } else if (currentListItem) {
          await updateInterestList({
            variables: { id: currentListItem, input: { ...values } },
          });
        } else {
          await createInterestList({
            variables: { input: { ...values } },
          });
        }

        if (defaultClose) {
          onClose();
        } else {
          setMode(InterestListFormMode.view);
          setCurrentListItem(null);
        }
      }}
    >
      <Form>
        <MainFormContent
          type={type}
          primaryActionLabel={primaryActionLabel}
          onPrimaryAction={onPrimaryAction}
          onClose={onClose}
        />
      </Form>
    </Formik>
  );
}
