import ChatIcon from "@mui/icons-material/Chat";
import CloseIcon from "@mui/icons-material/Close";
import MicIcon from "@mui/icons-material/Mic";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { CircularProgress, IconButton, Popover, Tooltip } from "@mui/material";
import clsx from "clsx";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect, useRef, useState } from "react";
import { useMatch } from "react-router-dom";
import io from "socket.io-client";
import { z } from "zod";

import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";

import { TextAudioControl } from "./TextToAudio";

const Sdp = z.object({
  type: z.enum(["answer", "offer", "pranswer", "rollback"]),
  sdp: z.string(),
});

const Ice = z.object({
  sdpMLineIndex: z.number(),
  candidate: z.string(),
});

export const IceServers = z.array(
  z.object({
    username: z
      .string()
      .nullish()
      .transform((x) => x || undefined),
    urls: z.array(z.string()),
    credential: z
      .string()
      .nullish()
      .transform((x) => x || undefined),
  })
);
interface RealtimeAudioProps {
  cameraId: number;
}
export function RealTimeAudioButton(props: RealtimeAudioProps) {
  const { biAudioTts } = useFlags();
  const feedback = useFeedback();
  const [activated, setActivated] = useState<"text" | "webrtc" | undefined>(
    undefined
  );
  const targetRef = useRef<HTMLDivElement>(null);
  return (
    <div className="flex flex-row gap-2 self-stretch items-center">
      {activated && (
        <Tooltip title="Close audio channel">
          <div
            className="cursor-pointer"
            onClick={() => setActivated(undefined)}
          >
            <CloseIcon />
          </div>
        </Tooltip>
      )}
      {!activated && (
        <Tooltip title="Connect to two way audio">
          <div
            className="cursor-pointer"
            onClick={() => setActivated("webrtc")}
          >
            <MicIcon />
          </div>
        </Tooltip>
      )}
      {activated !== "webrtc" && biAudioTts && (
        <Tooltip title="Connect to text to speech">
          <div
            ref={targetRef}
            className="cursor-pointer"
            onClick={() => setActivated("text")}
          >
            <ChatIcon />
          </div>
        </Tooltip>
      )}
      {activated === "webrtc" && (
        <RealTimeAudioControl
          cameraId={props.cameraId}
          onDisconnect={() => {
            feedback.pushSnackbar(
              "Two way audio disconnected",
              FeedbackType.Warning
            );
            setActivated(undefined);
          }}
        />
      )}
      <Popover
        open={!!targetRef.current && activated === "text"}
        anchorEl={targetRef.current}
        onClose={() => setActivated(undefined)}
      >
        <div className="flex flex-col items-stretch">
          <div className="h-8 flex flex-row items-center justify-between px-6 py-8 bg-[#F0F0F0]">
            <div className="text-xl">Text-to-Speech</div>
            <IconButton onClick={() => setActivated(undefined)}>
              <CloseIcon />
            </IconButton>
          </div>
          <TextAudioControl
            cameraId={props.cameraId}
            onDisconnected={() => {
              feedback.pushSnackbar(
                "Text to speech disconnected",
                FeedbackType.Warning
              );
              setActivated(undefined);
            }}
          />
        </div>
      </Popover>
    </div>
  );
}

function RealTimeAudioControl(props: {
  cameraId: number;
  onDisconnect: () => void;
}) {
  const { streams, micStreamRef } = useRealtimeAudioStream(props);
  const [recording, setRecording] = useState(false);

  useEffect(() => {
    if (micStreamRef.current) {
      micStreamRef.current.getAudioTracks()[0].enabled = recording;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recording]);

  return !!streams?.length ? (
    <div
      className={clsx(
        "transition-all text-white no-underline rounded text-md self-stretch flex flex-row items-center justify-center gap-2 cursor-pointer",
        recording
          ? "bg-error hover:bg-error shadow-[0_0px_41px_#FF490F] w-48 font-bold"
          : "bg-primary/60 hover:bg-primary w-48"
      )}
      onMouseDown={() => {
        setRecording(true);
      }}
      onMouseUp={() => {
        setRecording(false);
      }}
    >
      {recording ? (
        <>
          <RadioButtonCheckedIcon /> Relase to Stop
        </>
      ) : (
        <>
          <MicIcon /> Click & Hold to Talk
        </>
      )}
      <audio
        ref={(audio) => {
          if (audio && streams?.length) {
            audio.srcObject = streams[0];
          }
        }}
        controls={false}
        autoPlay
      />
    </div>
  ) : (
    <div className="flex items-center justify-center">
      <CircularProgress size="1.5rem" />
    </div>
  );
}

function useRealtimeAudioStream({
  cameraId,
  onDisconnect,
}: {
  cameraId: number;
  onDisconnect: () => void;
}) {
  const [streams, setStreams] = useState<readonly MediaStream[] | undefined>(
    undefined
  );
  const micStreamRef = useRef<MediaStream>();
  const peerRef = useRef<RTCPeerConnection>();
  const match = useMatch("/o/:orgSlug/*");
  useEffectOnce(() => {
    const socket = io("/rtsp_signaler", {
      path: "/socket",
      transports: ["polling"],
      transportOptions: {
        polling: {
          extraHeaders: {
            "x-spot-camera-id": `${cameraId}`,
            "x-spot-org-slug": match?.params.orgSlug,
            "x-spot-audio-input": "webrtc",
          },
        },
      },
    });
    socket.on("disconnect", () => {
      onDisconnect();
    });
    socket.on("error", (msg: string) => {
      console.error(msg);
    });

    socket.on("message", async (msg: string) => {
      const data = JSON.parse(msg);
      if (peerRef.current != null) {
        // Only start exchanging if ice servers are recieved and a peer conn is avaliable
        const sdp = Sdp.safeParse(data);
        if (sdp.success) {
          peerRef.current.setRemoteDescription(sdp.data);
          return;
        }
        const ice = Ice.safeParse(data);
        if (ice.success) {
          peerRef.current.addIceCandidate(ice.data);
          return;
        }
      } else {
        // there is no peer conn, await ice servers from server and use that to create a peer conn
        const twilioIce = IceServers.safeParse(data);
        if (twilioIce.success) {
          const pc = new RTCPeerConnection({
            iceServers: twilioIce.data,
            bundlePolicy: "max-bundle",
          });
          console.log("Created local peer connection object pc");
          pc.addEventListener("icecandidate", ({ candidate }) => {
            if (candidate) {
              const { sdpMLineIndex, candidate: iceCandidate } = candidate;
              socket.emit(
                "message",
                JSON.stringify({ sdpMLineIndex, candidate: iceCandidate })
              );
            }
          });

          pc.addEventListener("iceconnectionstatechange", (e) => {
            console.log(`ICE state: ${pc.iceConnectionState}`);
            console.log("ICE state change event: ", e);
          });

          pc.addEventListener("track", (e) => {
            setStreams(e.streams);
          });

          micStreamRef.current = await navigator.mediaDevices.getUserMedia({
            audio: {
              echoCancellation: true,
              autoGainControl: true,
              noiseSuppression: true,
              channelCount: 1,
            },
            video: false,
          });
          micStreamRef.current.getAudioTracks()[0].enabled = false;
          micStreamRef.current
            .getTracks()
            .forEach((track) => pc.addTrack(track));
          const offer = await pc.createOffer({
            offerToReceiveAudio: true,
            offerToReceiveVideo: false,
          });
          console.log("creating offer and publishing SDP");
          await pc.setLocalDescription(offer);

          peerRef.current = pc;
          socket.emit("message", JSON.stringify(offer));
          return;
        }
      }

      console.log(`fail, ${msg}`);
    });

    return () => {
      socket.close();
      if (peerRef.current) peerRef.current.close();
      if (streams) {
        streams.forEach((s) => s.getTracks().forEach((t) => t.stop()));
      }
    };
  });

  return { streams, micStreamRef };
}

export const useEffectOnce = (effect: () => void | (() => void)) => {
  const destroyFunc = useRef<void | (() => void)>();
  const effectCalled = useRef(false);
  const renderAfterCalled = useRef(false);
  const [, setVal] = useState<number>(0);

  if (effectCalled.current) {
    renderAfterCalled.current = true;
  }

  useEffect(() => {
    // only execute the effect first time around
    if (!effectCalled.current) {
      destroyFunc.current = effect();
      effectCalled.current = true;
    }

    // this forces one render after the effect is run
    setVal((val) => val + 1);

    return () => {
      // if the comp didn't render since the useEffect was called,
      // we know it's the dummy React cycle
      if (!renderAfterCalled.current) {
        return;
      }
      if (destroyFunc.current) {
        destroyFunc.current();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Do not put anything into this array.
};
