import DeleteIcon from "@mui/icons-material/Delete";
import DevicesIcon from "@mui/icons-material/Devices";
import CamIcon from "@mui/icons-material/VideocamOutlined";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  Alert,
} from "@mui/material";
import { useRef, useState, useEffect, memo } from "react";
import { makeStyles } from "tss-react/mui";
import { BooleanParam, useQueryParam } from "use-query-params";

import { Fisheye } from "@/icons/Fisheye";

import { pluralize } from "@/util/pluralize";
import { useBreakpoints } from "@/util/useBreakpoints";

import { LockRow } from "@/pages/Settings/LocationSettings/Camera/DeviceRow";

import CameraProxyPopover from "@/components/Camera/CameraProxyPopover";
import CameraSupportDetails from "@/components/CameraSupportDetails";
import EditableText from "@/components/EditableText";
import { ErrorMessage } from "@/components/ErrorMessage";
import {
  DeviceAuthenticationForm,
  ManualDeviceAuthenticationForm,
} from "@/components/Genius/Forms/AuthenticationForm";
import StreamSelectForm from "@/components/Genius/Forms/Camera/CameraStreamSelectForm";
import { useUpdateCamera } from "@/components/Genius/Forms/Camera/cameraHooks";
import NvrChannelsList from "@/components/Genius/Forms/Nvr/NvrChannelsList";
import { Loading } from "@/components/Loading";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { UpdatableImage } from "@/components/UpdatableImage";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  Camera,
  CameraStatus,
  ConnectionValidation as Validation,
  DeviceDetailsQuery,
  LifecycleStates,
  useDeleteDeviceScanMutation,
  useDeviceDetailsQuery,
} from "@/generated-models";

import BaseModal from "../Modal/BaseModal";
import { ZendeskArticle, ZendeskLink } from "../Zendesk/ZendeskLink";
import { FisheyeModal } from "./FisheyeModal";
import { LocationAi, useLocationCapacity } from "./LocationCapacityGuard";

const useStyles = makeStyles()((theme) => ({
  paper: {
    borderRadius: 4,
    minHeight: 200,
    minWidth: 400,
  },
  infoBox: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    borderRadius: 4,
    // border: "1px solid rgba(0,0,0,0.3)",
    padding: theme.spacing(1),
    margin: theme.spacing(2, 1),
    background: "#E6F2FC",
  },
  stillImg: {
    color: "#fff",
    fontWeight: "bold",
    border: "4px solid #FFFFFF",
    filter: "drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.16))",
    borderRadius: 4,
    width: "min(400px, 100%)",
    boxSizing: "border-box",
    transition: "all 0.2s ease-in-out",
  },
  stillFallback: {
    width: "100%",
    height: 250,
    borderRadius: 4,
    background: `url(/no-still.svg)`,
    border: "4px solid #FFFFFF",
    filter: "drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.16))",
  },
  icon: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 8,
    fontWeight: "bold",
    lineHeight: 0.6,
    "& svg": { fontSize: 20 },
  },
}));

export type DeviceDetailChannel = NonNullable<
  DeviceDetailsQuery["deviceScan"]
>["channels"][number];
export type DeviceDetailStream = DeviceDetailChannel["streams"][number];

export enum SecondaryFormState {
  Expanded = "expanded",
  Contracted = "contracted",
  Hidden = "hidden",
}

interface GeniusBodyProps {
  deviceId: number;
  onClose?: () => void;
  lockRow?: LockRow;
  isFisheye?: boolean;
}

export function GeniusPopupComponent({
  deviceId,
  onClose = () => {},
  lockRow,
  isFisheye,
}: GeniusBodyProps) {
  const { data, error } = useDeviceDetailsQuery({
    ...refetchOnMountPolicy,
    variables: { deviceId },
  });
  const device = data?.deviceScan;

  if (error) {
    return (
      <BaseModal onClose={onClose}>
        <div className="flex-center w-full h-full p-10">
          <ErrorMessage
            title="Unable to fetch device"
            description="Please try again later"
          />
        </div>
      </BaseModal>
    );
  }

  if (!device) {
    return (
      <BaseModal onClose={onClose}>
        <div className="flex-center w-full h-full p-10">
          <Loading>Fetching device details</Loading>
        </div>
      </BaseModal>
    );
  }

  if (isFisheye) {
    return <FisheyeModal device={device} onClose={onClose} lockRow={lockRow} />;
  }

  if (!device.isNvr) {
    return (
      <CameraPopupBody device={device} onClose={onClose} lockRow={lockRow} />
    );
  }

  return <NvrPopupBody device={device} onClose={onClose} lockRow={lockRow} />;
}

function NvrPopupBody({
  device,
  onClose,
  lockRow,
}: {
  device: DeviceDetailsQuery["deviceScan"];
  onClose: () => void;
  lockRow?: LockRow;
}) {
  const { fitsDesktop } = useBreakpoints();
  const [configure, setConfigure] = useQueryParam("configure", BooleanParam);
  const deviceIsAuthenticated = device.status === Validation.Ok;
  const deviceHasCameras = !!device.cameras.length;

  const [expandAuthForm, setExpandAuthForm] = useState(!deviceIsAuthenticated);
  const [secondaryFormState, setSecondaryFormState] = useState(
    deviceIsAuthenticated || deviceHasCameras
      ? SecondaryFormState.Expanded
      : SecondaryFormState.Hidden
  );

  const isFisheye = device.isFisheye;

  // Need to un-hide the secondary form if we were just recently authenticated for this device.
  useEffect(() => {
    if (
      secondaryFormState === SecondaryFormState.Hidden &&
      deviceIsAuthenticated
    ) {
      secondaryOnSetExpanded(true);
    }
  }, [deviceIsAuthenticated, secondaryFormState]);

  function secondaryOnSetExpanded(expanded: boolean) {
    if (expanded) {
      setExpandAuthForm(false);
      setSecondaryFormState(SecondaryFormState.Expanded);
    } else {
      setSecondaryFormState(SecondaryFormState.Contracted);
    }
  }

  const proxyButtonRef = useRef<HTMLButtonElement | null>(null);
  const [proxyPopoverOpen, setProxyPopoverOpen] = useState<boolean>(false);

  const deviceTypeMisconfigurationAdvisoryOpen =
    secondaryFormState === SecondaryFormState.Expanded;

  useEffect(() => {
    if (configure) {
      setTimeout(() => {
        setProxyPopoverOpen(true);
      }, 1);
    }
  }, [configure]);
  const deviceName = isFisheye ? "Fisheye" : "NVR";

  return (
    <BaseModal
      classes={{
        root: "max-w-4xl",
      }}
      onClose={onClose}
      header={
        <>
          <div className="flex items-center gap-2">
            <div className="flex-center flex-col">
              {isFisheye ? (
                <Fisheye dark className="text-[20px]" />
              ) : (
                <CamIcon className="text-[20px]" />
              )}
              <div className="font-bold leading-[0.6]" style={{ fontSize: 8 }}>
                {deviceName}
              </div>
            </div>
            <EditableText
              TypographyProps={{
                style: { fontSize: 18, fontWeight: "bold", lineHeight: 1 },
              }}
              initialValue={deviceName}
              disabled
              onSubmit={() => {}}
            />
            {!deviceHasCameras && device.isManual && (
              <DeleteDeviceButton device={device} close={onClose} />
            )}
          </div>
          <div className="flex items-center gap-3">
            <Typography variant="body2">
              IP: <strong style={{ opacity: 0.8 }}>{device.ip}</strong>
            </Typography>
            <div className="w-[1px] h-4 bg-black bg-opacity-10" />
            <Typography variant="body2">
              Mac: <strong style={{ opacity: 0.8 }}>{device.mac}</strong>{" "}
              {device.vendor.toLowerCase() !== "unknown" &&
                `(${device.vendor.split(" ")[0]})`}
            </Typography>
            {fitsDesktop && (
              <Button
                ref={proxyButtonRef}
                size="small"
                color="primary"
                className="h-5"
                onClick={(e) => {
                  setProxyPopoverOpen(true);
                }}
              >
                Native {deviceName} Config
              </Button>
            )}
            <CameraProxyPopover
              open={proxyPopoverOpen}
              anchorEl={proxyButtonRef.current}
              onClose={() => {
                setProxyPopoverOpen(false);
                setConfigure(false);
              }}
              deviceId={device.id}
              deviceMac={device.mac}
              localTarget={device.ip}
              homeAppliance={device.cameras[0]?.appliance}
              reachableAppliances={device.reachableAppliances}
            />
          </div>
        </>
      }
      statusBars={
        deviceTypeMisconfigurationAdvisoryOpen && (
          <Alert
            className="flex items-center"
            severity="info"
            classes={{
              message: "p-0",
            }}
          >
            {device.cameras.length > 0
              ? `NVR needs more than ${pluralize(
                  {
                    1: "1 channel",
                    multi: `${device.channels.length} channels`,
                  },
                  device.channels.length
                )}?`
              : `Device not a ${deviceName}?`}
            <Button
              color="primary"
              size="small"
              className="ml-2"
              onClick={() => setExpandAuthForm(true)}
            >
              Change Connection Settings
            </Button>
          </Alert>
        )
      }
    >
      <div className="flex">
        <div className="p-4 overflow-hidden">
          <DeviceAuthenticationForm
            lockRow={lockRow}
            expanded={expandAuthForm}
            device={device}
            expansionLocked={secondaryFormState === SecondaryFormState.Hidden}
            onSetExpanded={(expanded: boolean) => {
              setExpandAuthForm(expanded);
            }}
            onClose={onClose}
          />
          <Box m={1} />
          {secondaryFormState !== SecondaryFormState.Hidden && (
            <NvrChannelsList
              onSetExpanded={secondaryOnSetExpanded}
              expanded={secondaryFormState === SecondaryFormState.Expanded}
              device={device}
              onOpenNativeProxy={() => setProxyPopoverOpen(true)}
            />
          )}
        </div>
      </div>
    </BaseModal>
  );
}

function CameraPopupBody({
  device,
  onClose,
  lockRow,
}: {
  device: DeviceDetailsQuery["deviceScan"];
  onClose: () => void;
  lockRow?: LockRow;
}) {
  const { fitsDesktop } = useBreakpoints();
  const { classes } = useStyles();
  const deviceIsAuthenticated = device?.status === Validation.Ok;

  const [expandAuthForm, setExpandAuthForm] = useState(!deviceIsAuthenticated);
  const [secondaryFormState, setSecondaryFormState] = useState(
    deviceIsAuthenticated
      ? SecondaryFormState.Expanded
      : SecondaryFormState.Hidden
  );

  // Need to un-hide the secondary form if we were just recently authenticated for this device.
  useEffect(() => {
    if (
      secondaryFormState === SecondaryFormState.Hidden &&
      deviceIsAuthenticated
    ) {
      secondaryOnSetExpanded(true);
    }
  }, [deviceIsAuthenticated, secondaryFormState]);

  function secondaryOnSetExpanded(expanded: boolean) {
    if (expanded) {
      setExpandAuthForm(false);
      setSecondaryFormState(SecondaryFormState.Expanded);
    } else {
      setSecondaryFormState(SecondaryFormState.Contracted);
    }
  }

  const proxyButtonRef = useRef<HTMLButtonElement | null>(null);
  const [proxyPopoverOpen, setProxyPopoverOpen] = useState<boolean>(false);

  const { update: updateCamera } = useUpdateCamera();
  const camera = device.cameras[0];
  const channel = device.channels[0];
  const { locationAi, maxAICapacityReached } = useLocationCapacity();
  // initial state is value of existing camera, or true if non-togglable, or false if non AI or max AI already reached
  const aiEnabled =
    camera?.aiEnabled ??
    (locationAi !== LocationAi.NonAiOnly && !maxAICapacityReached);

  const still = camera?.still ?? channel?.still;
  const [nameValue, setNameValue] = useState("Camera Device");
  const deviceTypeMisconfigurationAdvisoryOpen =
    secondaryFormState === SecondaryFormState.Expanded && !camera;

  return (
    <BaseModal
      onClose={onClose}
      header={
        <>
          <div className="flex gap-2 items-center">
            <div className={classes.icon}>
              {device.isCameraVendor ? <CamIcon /> : <DevicesIcon />}
            </div>
            <EditableText
              TypographyProps={{
                style: { fontSize: 18, fontWeight: "bold", lineHeight: 1 },
              }}
              initialValue={camera?.name ?? nameValue}
              onSubmit={(value) => {
                if (camera) {
                  updateCamera(camera.id, undefined, value);
                } else {
                  // Set local nameValue state to use when creating new cam
                  setNameValue(value);
                }
              }}
            />
            {!camera && device.isManual && (
              <DeleteDeviceButton device={device} close={onClose} />
            )}
          </div>
          <div className="flex items-center gap-3">
            <Typography variant="body2">
              IP: <strong style={{ opacity: 0.8 }}>{device.ip}</strong>
            </Typography>
            <div className="w-[1px] h-4 bg-black bg-opacity-10" />
            <Typography variant="body2">
              Mac: <strong style={{ opacity: 0.8 }}>{device.mac}</strong>{" "}
              {device.vendor.toLowerCase() !== "unknown" &&
                `(${device.vendor.split(" ")[0]})`}
            </Typography>
            <div>
              {fitsDesktop && (
                <Button
                  ref={proxyButtonRef}
                  size="small"
                  color="primary"
                  className="h-5"
                  onClick={(e) => {
                    setProxyPopoverOpen(true);
                  }}
                >
                  Native Camera Config
                </Button>
              )}
              <ZendeskLink
                color="primary"
                article={ZendeskArticle.CAMERA_SETTINGS}
              />
            </div>
            <CameraProxyPopover
              open={proxyPopoverOpen}
              anchorEl={proxyButtonRef.current}
              onClose={() => setProxyPopoverOpen(false)}
              deviceId={device.id}
              deviceMac={device.mac}
              localTarget={device.ip}
              homeAppliance={device.cameras[0]?.appliance}
              reachableAppliances={device.reachableAppliances}
            />
          </div>
          <CameraSupportDetails camera={camera as Camera} />
        </>
      }
      statusBars={
        camera?.lifecycleState !== LifecycleStates.Deleting && (
          <>
            {camera?.status === CameraStatus.Offline &&
              camera?.lifecycleState === LifecycleStates.Enabled && (
                <div>
                  <Alert severity="error" style={{ borderRadius: 0 }}>
                    Camera appears to be offline. Check network and camera
                    configuration.
                  </Alert>
                </div>
              )}
            {deviceTypeMisconfigurationAdvisoryOpen && (
              <Alert
                className="flex items-center"
                severity="info"
                classes={{
                  message: "p-0",
                }}
              >
                Device not a camera?
                <Button
                  color="primary"
                  size="small"
                  className="ml-2"
                  onClick={() => setExpandAuthForm(true)}
                >
                  Change Connection Settings
                </Button>
              </Alert>
            )}
          </>
        )
      }
    >
      <div className="h-full flex flex-col">
        {camera?.lifecycleState === LifecycleStates.Deleting ? (
          <div className="flex-center py-4 pb-4">
            <CircularProgress /> Deletion in progress
          </div>
        ) : (
          <>
            <div className="flex">
              {
                // Still on the left side of the popup for non-nvr device
                <div className="w-[240px] shrink-0 p-4 pr-0">
                  <UpdatableImage
                    src={still || "/no-still.svg"}
                    className={classes.stillImg}
                  />
                </div>
              }
              <div className="p-4 overflow-hidden">
                <DeviceAuthenticationForm
                  lockRow={lockRow}
                  expanded={expandAuthForm}
                  device={device}
                  expansionLocked={
                    secondaryFormState === SecondaryFormState.Hidden
                  }
                  onSetExpanded={(expanded: boolean) => {
                    setExpandAuthForm(expanded);
                  }}
                  onClose={onClose}
                />
                <Box m={1} />
                {secondaryFormState !== SecondaryFormState.Hidden && (
                  <StreamSelectForm
                    channel={channel}
                    onClose={onClose}
                    deviceName={nameValue}
                    deviceId={device.id}
                    deviceVendor={device.vendor}
                    aiEnabled={aiEnabled}
                    expanded={
                      secondaryFormState === SecondaryFormState.Expanded
                    }
                    onSetExpanded={secondaryOnSetExpanded}
                    onOpenNativeProxy={() => setProxyPopoverOpen(true)}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </BaseModal>
  );
}

export function ManualDevicePopup({
  onClose,
  locationId,
}: {
  onClose: () => void;
  locationId: number;
}) {
  return (
    <BaseModal
      onClose={onClose}
      header={
        <div className="flex items-center gap-2">
          <div className="flex-center flex-col">
            <CamIcon />
          </div>
          <EditableText
            TypographyProps={{
              style: { fontSize: 18, fontWeight: "bold", lineHeight: 1 },
            }}
            initialValue="Add a Device"
            disabled
            onSubmit={() => {}}
          />
        </div>
      }
    >
      <div className="flex">
        <div className="p-4 overflow-hidden">
          <ManualDeviceAuthenticationForm
            locationId={locationId}
            onClose={onClose}
          />
        </div>
      </div>
    </BaseModal>
  );
}

export function DeleteDeviceButton({
  device,
  close,
}: {
  device: DeviceDetailsQuery["deviceScan"];
  close: () => void;
}) {
  const { pushSnackbar } = useFeedback();
  const [deleteDevice, { loading }] = useDeleteDeviceScanMutation({
    variables: { id: device.id },
    update(cache) {
      close();
      const deletingId = cache.identify(device);
      if (deletingId) {
        cache.evict({ id: deletingId });
        cache.gc();
      }
    },
    onError: () =>
      pushSnackbar(
        "Failed deleting the device, try again later..",
        FeedbackType.Error
      ),
  });

  return (
    <Button
      color="primary"
      startIcon={<DeleteIcon />}
      disabled={loading}
      onClick={() => deleteDevice()}
    >
      Delete Device
    </Button>
  );
}

export const GeniusPopup = memo(GeniusPopupComponent);
