import {
  Box,
  Button,
  Grid,
  Input,
  OutlinedInput,
  Tooltip,
  Typography,
} from "@mui/material";
import gql from "graphql-tag";
import { debounce } from "lodash/fp";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Dropzone, { DropzoneRef, FileRejection } from "react-dropzone";
import { makeStyles } from "tss-react/mui";

import { ReactComponent as BWIcon } from "@/icons/back_and_white.svg";
import { ReactComponent as CropIcon } from "@/icons/crop.svg";
import { ReactComponent as RotateAntiClockIcon } from "@/icons/rotateAntiClock.svg";
import { ReactComponent as RotateClockIcon } from "@/icons/rotateClock.svg";

import { useBreakpoints } from "@/util/useBreakpoints";

import { Loading } from "@/components/Loading";
import { CropData, ImageCropper } from "@/components/MappingTool/ImageCropper";
import MapboxMap from "@/components/MappingTool/MapboxMap";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { SelectorGroup } from "@/components/View/ViewNavigation";
import { DefaultDialog, useDialog } from "@/components/shared/Dialog";

import {
  AddLocationLayoutInput,
  LocationLayoutType,
  useAddLocationLayoutMutation,
  useGroupLocationIdQuery,
} from "@/generated-models";
import { theme } from "@/layout/theme";

import { ZendeskArticle, ZendeskLink } from "../Zendesk/ZendeskLink";

const useStyles = makeStyles()((theme) => ({
  addImageHeading: {
    fontSize: "22px",
    lineHeight: "26px",
    fontWeight: 700,
    textAlign: "center",
  },
  addImageMessage: {
    fontSize: "16px",
    lineHeight: "19px",
    textAlign: "center",
  },
  uploadImageContainer: {
    border: "2px solid #007CE4",
    boxSizing: "border-box",
    borderRadius: "10px",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  orText: {
    alignSelf: "center",
    textAlign: "center",
    fontWeight: 700,
    fontSize: "18px",
  },
  dragBox: {
    border: "1px dashed rgba(0, 0, 0, 0.26)",
    boxSizing: "border-box",
    borderRadius: "4px",
    width: "100%",
  },
  customInputLabel: {
    "& legend": {
      visibility: "visible",
      color: "#969696",
      lineHeight: "0.9em",
    },
  },
  customInput: {
    padding: "9px 7px",
  },
  editToolIcon: {
    border: "1px solid #969696",
  },
  editToolIconSelected: {
    border: "1px solid #007CE4",
    "& path": {
      fill: "#007CE4",
    },
  },
  cropperContainer: {
    width: "100%",
    height: "60vh",
    padding: "10px",
    border: "1px solid #E5E5E5",
    borderRadius: "8px",
    overflow: "overlay",
  },
  selectedImage: {
    alignSelf: "center",
    display: "flex",
    height: "50vh",
    maxWidth: "50vh",
    objectFit: "contain",
  },
}));

export interface ImageTransformation {
  grayscale: boolean;
  rotationAngle: number;
}

interface AddFloorImageProps {
  tag: SelectorGroup;
  showIntro: boolean;
  setLayoutParam: React.Dispatch<number | undefined>;
  setFullWidth?: React.Dispatch<boolean>;
  isFullWidth?: boolean;
}

export function AddFloorImage({
  tag,
  showIntro,
  setLayoutParam,
  setFullWidth,
  isFullWidth = false,
}: AddFloorImageProps) {
  const { classes } = useStyles();
  const { fitsDesktop } = useBreakpoints();
  const defaultTransformation = {
    grayscale: false,
    rotationAngle: 0,
  };
  const [intro, setIntro] = useState(showIntro);

  const {
    open: openCreateLayoutModal,
    confirm: confirmCreateLayout,
    ...dialogProps
  } = useDialog();

  const { pushSnackbar } = useFeedback();
  const [viewName, setViewName] = useState<string>("");
  const dropzoneRef = useRef<DropzoneRef | null>(null);
  const [selectedFile, setSelectedFile] = useState<{
    file: File;
    preview: string;
  } | null>(null);
  const [transformations, setTransformations] = useState<ImageTransformation>({
    grayscale: false,
    rotationAngle: 0,
  });
  const [cropping, setCropping] = useState(false);
  const [croppedImage, setCroppedImage] = useState<{
    data: string;
    file: File;
    cropData: { top: number; left: number; width: number; height: number };
  } | null>(null);
  const [layoutType, setLayoutType] = useState<LocationLayoutType>(
    LocationLayoutType.Image
  );

  const [address, setAddress] = useState<string>("");
  const [coordinates, setCoordinates] = useState<{
    x: number;
    y: number;
  } | null>(null);

  // temporary state
  const [searchAddress, setSearchAddress] = useState<string>("");
  // eslint-disable-next-line
  const persistInput = useCallback(debounce(1000, setSearchAddress), [
    setSearchAddress,
  ]);

  //APIs
  const [
    addLocationLayout,
    { loading: submitting },
  ] = useAddLocationLayoutMutation();

  const { data: groupData } = useGroupLocationIdQuery({
    variables: { id: tag.id },
  });

  useEffect(() => {
    if (!isFullWidth) {
      setIntro(true);
    }
  }, [isFullWidth]);

  const onCropChange = (
    img: { data: string; file: File; cropData: CropData } | null
  ) => {
    if (!img) return;
    setCroppedImage(img);
    setCropping(false);
  };

  function onDrop<T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[]
  ) {
    if (acceptedFiles.length === 0 && fileRejections[0]) {
      pushSnackbar(fileRejections[0].errors[0].message, FeedbackType.Error);
      return;
    }
    setSelectedFile({
      file: acceptedFiles[0],
      preview: URL.createObjectURL(acceptedFiles[0]),
    });
    setCroppedImage(null);
    setTransformations(defaultTransformation);
  }

  if (!groupData) return <Loading />;

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        {intro ? (
          <>
            <Grid item>
              <img src="/map_view.svg" alt="Map" />
            </Grid>
            <Box m={0.5} />
            <Grid item>
              <Typography variant="h3">
                {fitsDesktop ? "Welcome to Map View" : "Select a Layout"}
                <ZendeskLink article={ZendeskArticle.MAPS} />
              </Typography>
            </Grid>
            <Box m={0.5} />
            <Grid
              item
              xs={isFullWidth ? 4 : 12}
              style={{ flexBasis: "auto", padding: theme.spacing(1) }}
            >
              <Typography
                style={{
                  fontSize: "16px",
                  textAlign: "center",
                }}
              >
                {fitsDesktop
                  ? "Upload a floor plan/site plan or connect with Google Maps  to start placing your cameras on their physical location"
                  : "Select a Layout to view a map. Use Spot on a desktop to create or edit your maps."}
              </Typography>
            </Grid>
            <Box m={1} />

            {fitsDesktop && (
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={() => {
                    setIntro(false);
                    setFullWidth && setFullWidth(true);
                  }}
                >
                  Set Up Location
                </Button>
              </Grid>
            )}
          </>
        ) : (
          <Box m={3}>
            <Grid item>
              <Typography className={classes.addImageHeading}>
                Create a new view for this location.
              </Typography>
            </Grid>
            <Box m={2} />
            <Grid container item justifyContent="center">
              <Grid item xs={6}>
                <Typography className={classes.addImageMessage}>
                  Start by uploading a document like a floor plan, site map, or
                  blue print, or automatically generate a Google Map view from
                  your location address.
                  <br />
                  <br />
                  After making your first selection, you can mix and match views
                  by combining Google Map views with file upload of multiple
                  floor plans or site maps.
                </Typography>
              </Grid>
            </Grid>
            <Box m={4} />
            <Grid container item>
              <Grid
                item
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                xs={5}
                className={classes.uploadImageContainer}
              >
                <Grid item>
                  <img src="/upload-file.svg" alt="Upload" />
                </Grid>
                <Box m={1} />
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    onClick={() => {
                      setLayoutType(LocationLayoutType.Image);
                      openCreateLayoutModal();
                    }}
                  >
                    Upload File
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={2} className={classes.orText}>
                OR{" "}
              </Grid>
              <Grid
                item
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                xs={5}
                className={classes.uploadImageContainer}
              >
                <Grid item>
                  <img src="/google-maps.svg" alt="Generate google map" />
                </Grid>
                <Box m={1} />
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    onClick={() => {
                      setLayoutType(LocationLayoutType.Address);
                      openCreateLayoutModal();
                    }}
                  >
                    Generate Google Map
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
      </Grid>

      <DefaultDialog
        {...dialogProps}
        title={
          layoutType === LocationLayoutType.Image
            ? "Upload your floor plan, site map, or other file"
            : "Generate Google Map"
        }
        confirm={async () => {
          if (!viewName) {
            pushSnackbar("Please add a name for the view", FeedbackType.Error);
            return;
          }
          if (
            layoutType === LocationLayoutType.Image &&
            (!selectedFile || !selectedFile.file)
          ) {
            pushSnackbar("Please add a file to the view", FeedbackType.Error);
            return;
          }

          let input: AddLocationLayoutInput = {
            locationId: groupData.group.locations[0].id,
            name: viewName,
            type: layoutType,
            angle: transformations?.rotationAngle,
            grayscale: transformations?.grayscale,
          };

          if (layoutType === LocationLayoutType.Image) {
            input = {
              ...input,
              file: croppedImage ? croppedImage.file : selectedFile!.file,
            };
          } else if (layoutType === LocationLayoutType.Address) {
            input = {
              ...input,
              address,
              coordinates,
              zoom: 17,
            };
          }

          const { errors } = await addLocationLayout({
            variables: {
              input,
            },
            optimisticResponse: {
              __typename: "Mutation",
              addLocationLayout: {
                __typename: "LocationLayout",
                id: -1,
                ...input,
                zoom: 1,
                address: address ?? null,
                coordinates: {
                  __typename: "Point",
                  ...(input.coordinates ?? { x: 0, y: 0 }),
                },
              },
            },
            update(cache, { data }) {
              setLayoutParam(data?.addLocationLayout.id);
              cache.modify({
                id: `Tag:${tag.id}`,
                fields: {
                  layouts(exisingLayouts) {
                    return [
                      ...exisingLayouts,
                      { __ref: `LocationLayout:${data?.addLocationLayout.id}` },
                    ];
                  },
                },
              });
            },
          }).catch((e) => ({ errors: [e] }));

          if (errors?.length) {
            pushSnackbar(
              "Failed to add Layout, Please try again.",
              FeedbackType.Error
            );
          } else {
            pushSnackbar(`Layout Added Successfully!`, FeedbackType.Success);
            dialogProps.cancel();
            setSelectedFile(null);
            setCroppedImage(null);
            setViewName("");
          }
        }}
        fullWidth={!!selectedFile}
        maxWidth={layoutType === LocationLayoutType.Image ? "md" : "xs"}
        confirmText={submitting ? "Adding Layout" : "Add Layout"}
        confirmationDisabled={
          submitting || !viewName || (!selectedFile && !coordinates)
        }
        content={
          layoutType === LocationLayoutType.Image ? (
            <Dropzone
              ref={dropzoneRef}
              onDrop={onDrop}
              accept={{ "image/*": [] }}
              noClick={Boolean(selectedFile)}
              multiple={false}
            >
              {({
                getRootProps,
                getInputProps,
                isDragActive,
                isDragAccept,
                open: openFileSelect,
              }) => (
                <Grid container direction="column" alignItems="center">
                  <Box m={1} />
                  <Grid
                    container
                    item
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid
                      item
                      xs={selectedFile ? 6 : 12}
                      style={{ width: "100%" }}
                    >
                      <OutlinedInput
                        fullWidth
                        label="Layout Name"
                        required
                        classes={{
                          notchedOutline: classes.customInputLabel,
                          input: classes.customInput,
                        }}
                        onChange={(event: any) => {
                          const { value } = event.target;
                          setViewName(value);
                        }}
                        notched
                        placeholder={"Add Layout name"}
                        value={viewName}
                      />
                    </Grid>
                    {selectedFile && (
                      <Grid item>
                        <Grid container direction="row" spacing={1}>
                          <Grid item {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Button
                              onClick={() => {
                                openFileSelect();
                              }}
                              variant="outlined"
                              color="primary"
                            >
                              Change File
                            </Button>
                          </Grid>

                          <Grid item>
                            <Tooltip title={"Black and White"}>
                              <Button
                                color="primary"
                                onClick={() => {
                                  setTransformations((current) => {
                                    return {
                                      ...current,
                                      grayscale: !current?.grayscale,
                                    };
                                  });
                                }}
                                disabled={cropping}
                                className={
                                  transformations?.grayscale
                                    ? classes.editToolIconSelected
                                    : classes.editToolIcon
                                }
                              >
                                <BWIcon />
                              </Button>
                            </Tooltip>
                          </Grid>

                          <Grid item>
                            <Tooltip title={"Rotate Left"}>
                              <Button
                                color="primary"
                                onClick={() => {
                                  setCropping(false);
                                  setTransformations((current) => {
                                    return {
                                      ...current,
                                      rotationAngle:
                                        Number(current?.rotationAngle) - 90,
                                    };
                                  });
                                }}
                                disabled={cropping}
                                className={classes.editToolIcon}
                              >
                                <RotateAntiClockIcon />
                              </Button>
                            </Tooltip>
                          </Grid>

                          <Grid item>
                            <Tooltip title={"Rotate Right"}>
                              <Button
                                color="primary"
                                onClick={() => {
                                  setCropping(false);
                                  setTransformations((current) => {
                                    return {
                                      ...current,
                                      rotationAngle:
                                        Number(current?.rotationAngle) + 90,
                                    };
                                  });
                                }}
                                disabled={cropping}
                                className={classes.editToolIcon}
                              >
                                <RotateClockIcon />
                              </Button>
                            </Tooltip>
                          </Grid>

                          <Grid item>
                            <Tooltip title={"Crop"}>
                              <Button
                                color="primary"
                                onClick={() => {
                                  setCropping(true);
                                }}
                                className={
                                  cropping
                                    ? classes.editToolIconSelected
                                    : classes.editToolIcon
                                }
                              >
                                <CropIcon />
                              </Button>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  <Box m={1} />
                  {!selectedFile ? (
                    <div
                      className={classes.dragBox}
                      {...getRootProps()}
                      style={{
                        transition: "background 400ms",
                        background: isDragActive
                          ? isDragAccept
                            ? "rgb(8 197 0 / 0.1)"
                            : "rgb(219 98 98 / 0.1)"
                          : undefined,
                      }}
                    >
                      <input {...getInputProps()} />
                      <Box style={{ pointerEvents: "none", padding: "16px" }}>
                        <Grid container direction="column" alignItems="center">
                          <Box m={2} />
                          <img
                            src="/upload-file.svg"
                            alt="Upload"
                            width="42px"
                          />
                          <Box m={1} />
                          <Typography variant="h3" component="h6">
                            Drag and drop file here
                          </Typography>
                          <Typography>or</Typography>
                          <Typography
                            variant="h3"
                            component="h6"
                            color="primary"
                          >
                            Browse Files
                          </Typography>
                        </Grid>
                      </Box>
                    </div>
                  ) : (
                    <Grid
                      container
                      justifyContent={"center"}
                      className={classes.cropperContainer}
                    >
                      {cropping ? (
                        <ImageCropper
                          image={selectedFile.preview}
                          onCropChange={onCropChange}
                          fileName={selectedFile.file.name}
                          cancel={() => setCropping(false)}
                          initialCropData={croppedImage?.cropData}
                        />
                      ) : (
                        <img
                          alt={"Layout"}
                          className={classes.selectedImage}
                          style={{
                            filter: `grayscale(${Number(
                              transformations?.grayscale
                            )})`,
                            transform: `rotate(${transformations?.rotationAngle}deg)`,
                          }}
                          src={
                            croppedImage
                              ? croppedImage.data
                              : selectedFile.preview
                          }
                        />
                      )}
                    </Grid>
                  )}
                </Grid>
              )}
            </Dropzone>
          ) : (
            <Grid
              container
              item
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs={12} style={{ width: "100%" }}>
                <OutlinedInput
                  fullWidth
                  label="Layout Name"
                  required
                  classes={{
                    notchedOutline: classes.customInputLabel,
                    input: classes.customInput,
                  }}
                  onChange={(event: any) => {
                    const { value } = event.target;
                    setViewName(value);
                  }}
                  notched
                  placeholder={"Add Layout name"}
                  value={viewName}
                />

                <Box mt={1} />
                <Input
                  fullWidth
                  required
                  classes={{
                    input: classes.customInput,
                  }}
                  placeholder={"Address"}
                  onChange={(event: any) => {
                    const { value } = event.target;
                    setAddress(value);
                    persistInput(value);
                  }}
                  value={address}
                />
                <Box m={1} />
                <MapboxMap
                  address={searchAddress}
                  setCoordinates={setCoordinates}
                />
              </Grid>
            </Grid>
          )
        }
      />
    </>
  );
}

// This seems a bit weird. It would be better if `AddLocationLayoutMutation` would accept a `groupId` argument.
gql`
  query groupLocationId($id: Int!) {
    group: tag(id: $id) {
      id
      locations {
        id
      }
    }
  }
`;
