import { QueryResult } from "@apollo/client";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import ImageIcon from "@mui/icons-material/ImageOutlined";
import LocationIcon from "@mui/icons-material/PinDrop";
import VideoIcon from "@mui/icons-material/PlayCircleOutlined";
import CameraIcon from "@mui/icons-material/Videocam";
import {
  Button,
  Container,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { format, utcToZonedTime } from "date-fns-tz";
import gql from "graphql-tag";
import { PropsWithChildren, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import { NumberParam, useQueryParam } from "use-query-params";

import { pluralize } from "@/util/pluralize";

import { AnnotationButton } from "@/pages/Cases/AnnotationButton";

import { SnippetPreview } from "@/components/Camera/SnippetPreview";
import { ErrorMessage } from "@/components/ErrorMessage";
import { FixedAspectRatio } from "@/components/FixedAspectRatio";
import { Loading } from "@/components/Loading";
import { CaseContentType } from "@/components/Player/AddToCaseDialog";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { DefaultDialog, useDialog } from "@/components/shared/Dialog";
import { QueryParamLink } from "@/components/shared/QueryParamLink";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  CaseWithMediaQuery,
  SharedCaseWithMediaQuery,
  useCaseWithMediaQuery,
  useRemoveClipFromCaseMutation,
  useRemoveScreenshotFromCaseMutation,
  useSharedCaseWithMediaQuery,
} from "@/generated-models";
import { usePermissions } from "@/hooks/usePermissions";

import { usePrefixOrgSlug } from "../../hooks/useOrgRouteBase";
import {
  CASE_WITH_MEDIA_FRAGMENT,
  SHARED_CASE_WITH_MEDIA_FRAGMENT,
} from "./caseFragments";

type CaseWithMediaType = CaseWithMediaQuery | SharedCaseWithMediaQuery;

export type ClipType = NonNullable<CaseWithMediaType["case"]>["clips"][number];
type ScreenshotType = NonNullable<
  CaseWithMediaType["case"]
>["screenshots"][number];

type CaseMediaType = ClipType | ScreenshotType;

export function CaseMedia({
  id,
  className,
  anonymous,
}: {
  id: number;
  className?: string;
  anonymous: boolean;
}) {
  return anonymous ? (
    <SharedCaseMediaData className={className} />
  ) : (
    <CaseMediaData id={id} className={className} />
  );
}

function CaseMediaData({ id, className }: { id: number; className?: string }) {
  const caseMediaQuery = useCaseWithMediaQuery({
    variables: { id },
    ...refetchOnMountPolicy,
  });

  return (
    <CaseMediaInner
      className={className}
      caseMediaQuery={caseMediaQuery}
      anonymous={false}
    />
  );
}

function SharedCaseMediaData({ className }: { className?: string }) {
  const { token } = useParams();
  const caseMediaQuery = useSharedCaseWithMediaQuery({
    variables: { token: token ?? "" },
    skip: !token,
    ...refetchOnMountPolicy,
  });

  return (
    <CaseMediaInner
      className={className}
      caseMediaQuery={caseMediaQuery}
      anonymous={true}
    />
  );
}

function CaseMediaInner({
  caseMediaQuery,
  className,
  anonymous,
}: {
  caseMediaQuery:
    | QueryResult<CaseWithMediaQuery, any>
    | QueryResult<SharedCaseWithMediaQuery, any>;
  className?: string;
  anonymous: boolean;
}) {
  const hasPermission = usePermissions();
  const [clipId, setClipId] = useQueryParam("clip", NumberParam);
  const [, setStartTime] = useQueryParam("startTime", NumberParam);
  const [screenshotId, setScreenshotId] = useQueryParam(
    "screenshot",
    NumberParam
  );
  const { pushSnackbar } = useFeedback();
  const [removeClipFromCase] = useRemoveClipFromCaseMutation({
    onCompleted: () =>
      pushSnackbar("Successfully deleted this clip.", FeedbackType.Success),
    onError: () =>
      pushSnackbar(
        "Failed to delete this clip. Please try again later.",
        FeedbackType.Error
      ),
  });
  const [removeScreenshotFromCase] = useRemoveScreenshotFromCaseMutation({
    onCompleted: () =>
      pushSnackbar(
        "Successfully deleted this screenshot.",
        FeedbackType.Success
      ),
    onError: () =>
      pushSnackbar(
        "Failed to delete this screenshot. Please try again later.",
        FeedbackType.Error
      ),
  });

  const { open: openCaseDialog, ...caseDialogProps } = useDialog();
  const { open: openScreenshotDialog, ...screenshotDialogProps } = useDialog();
  const prefixOrgSlug = usePrefixOrgSlug();

  const { data, error } = caseMediaQuery;

  if (error) {
    return (
      <ErrorMessage
        title="Problem fetching clips and screenshots"
        description={error.message}
      />
    );
  }
  if (!data?.case) return <Loading grow className="m-24" />;
  const caseId = data.case.id;

  const caseItems: CaseMediaType[] = [
    ...data.case.clips,
    ...data.case.screenshots,
  ].sort((a, b) => {
    const timestampA =
      a.__typename === "CaseClip" || a.__typename === "SharedCaseClip"
        ? a.startTime
        : a.timestamp;
    const timestampB =
      b.__typename === "CaseClip" || b.__typename === "SharedCaseClip"
        ? b.startTime
        : b.timestamp;
    return timestampA < timestampB ? -1 : 1;
  });

  return (
    <Container maxWidth="lg" className={className}>
      <div className="flex items-center justify-between mb-2 mt-2 sm:mt-6">
        <Typography className="font-bold">
          {pluralize(
            { 1: "1 Clip", multi: `${data.case.clips.length} Clips` },
            data.case.clips.length
          )}
          {data.case.screenshots.length > 0 &&
            " | " +
              pluralize(
                {
                  1: "1 Screenshot",
                  multi: `${data.case.screenshots.length} Screenshots`,
                },
                data.case.screenshots.length
              )}
        </Typography>
        {hasPermission("video_vod_access") &&
          data.case.permissions.manage_media && (
            <Button
              component={Link}
              to={prefixOrgSlug(`/search?case=${data.case.id}`)}
              color="primary"
              className="font-bold"
            >
              + Add a Clip
            </Button>
          )}
      </div>
      <CaseItemGrid>
        {caseItems.map((item) => (
          <CaseItem
            key={`${item.__typename}${item.id}`}
            id={item.id}
            timestamp={
              item.__typename === "CaseClip" ||
              item.__typename === "SharedCaseClip"
                ? item.startTime
                : item.timestamp
            }
            durationSeconds={
              item.__typename === "CaseClip" ||
              item.__typename === "SharedCaseClip"
                ? (new Date(item.endTime).getTime() -
                    new Date(item.startTime).getTime()) /
                  1000
                : undefined
            }
            camera={item.camera}
            annotations={item.annotations}
            source={
              item.__typename === "CaseScreenshot" ||
              item.__typename === "SharedCaseScreenshot"
                ? item.source
                : undefined
            }
            stills={
              item.__typename === "CaseClip" ||
              item.__typename === "SharedCaseClip"
                ? item.stills
                : undefined
            }
            viewing={
              item.__typename === "CaseClip" ||
              item.__typename === "SharedCaseClip"
                ? item.id === clipId
                : item.id === screenshotId
            }
            onDelete={
              data.case?.permissions.manage_media
                ? item.__typename === "CaseClip"
                  ? async () => {
                      const confirmed = await openCaseDialog();
                      if (!confirmed) return;
                      await removeClipFromCase({
                        variables: { id: item.id },
                        optimisticResponse: {
                          __typename: "Mutation",
                          removeClipFromCase: {
                            __typename: "DeletionResponse",
                            id: item.id,
                            deleted: true,
                          },
                        },
                        update: (cache) =>
                          cache.evict({ id: cache.identify(item) }),
                      });
                      if (item.id === clipId) {
                        setClipId(undefined);
                        setStartTime(undefined);
                      }
                    }
                  : async () => {
                      const confirmed = await openScreenshotDialog();
                      if (!confirmed) return;
                      await removeScreenshotFromCase({
                        variables: { caseId, screenshotId: item.id },
                        optimisticResponse: {
                          __typename: "Mutation",
                          removeScreenshotFromCase: {
                            __typename: "DeletionResponse",
                            id: item.id,
                            deleted: true,
                          },
                        },
                        update: (cache) =>
                          cache.evict({ id: cache.identify(item) }),
                      });
                      if (item.id === screenshotId) setScreenshotId(null);
                    }
                : undefined
            }
          />
        ))}
      </CaseItemGrid>
      <DefaultDialog
        {...caseDialogProps}
        confirmColor="primary"
        title="Delete Clip"
        content="Are you sure you want to delete this clip? This cannot be undone."
      />
      <DefaultDialog
        {...screenshotDialogProps}
        confirmColor="primary"
        title="Delete Screenshot"
        content="Are you sure you want to delete this screenshot? This cannot be undone."
      />
    </Container>
  );
}

const useScrollbarStyles = makeStyles()(() => ({
  scrollbar: {
    "&::-webkit-scrollbar": {
      background: "transparent",
      height: 4,
      width: 8,
    },
    "&::-webkit-scrollbar-thumb": {
      border: "none",
      boxShadow: "none",
      background: "rgba(0, 0, 0, .3)",
      borderRadius: 8,
      minHeight: 40,
    },
  },
}));

export function CaseItemGrid({ children }: PropsWithChildren<{}>) {
  return (
    <section className="grid grid-cols-[repeat(auto-fill,minmax(280px,1fr))] gap-3">
      {children}
    </section>
  );
}

export function CaseItem({
  id,
  timestamp,
  durationSeconds,
  camera,
  annotations,
  source,
  stills,
  viewing,
  onDelete,
}: {
  id: number;
  timestamp: string;
  durationSeconds?: number;
  camera: ClipType["camera"];
  annotations: ClipType["annotations"];
  source?: string;
  stills?: ClipType["stills"];
  viewing: boolean;
  onDelete?: () => void;
}) {
  const { classes } = useScrollbarStyles();
  const isScreenshot = !!source;

  return (
    <Paper elevation={4} className="flex flex-col rounded-lg">
      <div className="relative rounded-t-lg overflow-hidden">
        <CaseItemImage
          id={id}
          camera={camera}
          source={source}
          stills={stills}
          durationSeconds={durationSeconds}
        />
        <div className="absolute top-1 left-1 flex items-center gap-1 p-0.5 pr-2 text-white font-bold text-xs bg-[#1b1b1b] bg-opacity-80 rounded-md pointer-events-none">
          {isScreenshot ? (
            <>
              <ImageIcon />
              Screenshot
            </>
          ) : (
            <>
              <VideoIcon />
              Video Clip
            </>
          )}
        </div>
        {viewing && (
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 h-8 w-24 flex-center text-white font-bold text-base bg-primary bg-opacity-60 rounded-full pointer-events-none">
            Viewing
          </div>
        )}
      </div>

      <div
        className={clsx(
          "flex overflow-auto gap-0.5 bg-[#fbfbfb]",
          classes.scrollbar
        )}
      >
        {annotations.map((annotation) => (
          <AnnotationButton
            key={annotation.id}
            itemId={id}
            type={
              isScreenshot ? CaseContentType.screenshot : CaseContentType.clip
            }
            annotation={annotation}
            classes={{ annotation: "border-none bg-[#f4f4f4] rounded-none" }}
          />
        ))}
      </div>

      <div className="px-3 pt-2 pb-3">
        <div className="flex justify-between items-center gap-1">
          <Typography className="text-sm font-bold">
            {format(
              utcToZonedTime(new Date(timestamp), camera.location.timezone),
              "eee, PP h:mm aaa",
              { timeZone: camera.location.timezone }
            )}
          </Typography>
          {onDelete && (
            <Tooltip title={`Delete ${isScreenshot ? "Screenshot" : "Clip"}`}>
              <IconButton
                aria-label={`delete ${isScreenshot ? "screenshot" : "clip"}`}
                size="small"
                edge="end"
                className="text-black text-opacity-20"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onDelete();
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </div>
        <div className="flex items-center">
          <CameraIcon fontSize="small" className="opacity-40 mr-2" />
          <Typography className="text-sm">{camera.name}</Typography>
        </div>
        <div className="flex items-center mt-1.5">
          <LocationIcon fontSize="small" className="opacity-40 mr-2" />
          <Typography className="text-sm">{camera.location.name}</Typography>
        </div>
      </div>
    </Paper>
  );
}

function CaseItemImage({
  id,
  camera,
  source,
  stills,
  durationSeconds,
}: {
  id: number;
  camera: ClipType["camera"];
  source?: string;
  stills?: ClipType["stills"];
  durationSeconds?: number;
}) {
  const [hoverProgress, setHoverProgress] = useState(0);

  return (
    <QueryParamLink
      params={
        !!source
          ? { screenshot: id }
          : {
              clip: id,
              startTime:
                durationSeconds && hoverProgress > 0.05
                  ? Math.floor(durationSeconds * hoverProgress)
                  : undefined,
            }
      }
      removalParams={["startTime", !!source ? "clip" : "screenshot"]}
    >
      {source && (
        <FixedAspectRatio ratio="9 / 16">
          <img
            alt={camera.name}
            src={source}
            className="w-full h-full object-cover"
          />
        </FixedAspectRatio>
      )}
      {stills && (
        <SnippetPreview
          timezone={camera.location.timezone}
          stills={stills}
          hoverProgress={hoverProgress}
          setHoverProgress={setHoverProgress}
          className="rounded overflow-hidden"
        />
      )}
    </QueryParamLink>
  );
}

gql`
  query caseWithMedia($id: Int!) {
    case(id: $id) {
      ...CaseWithMedia
    }
  }
  ${CASE_WITH_MEDIA_FRAGMENT}
`;

gql`
  query sharedCaseWithMedia($token: String!) {
    case: sharedCase(token: $token) {
      ...SharedCaseWithMedia
    }
  }
  ${SHARED_CASE_WITH_MEDIA_FRAGMENT}
`;

gql`
  mutation removeClipFromCase($id: Int!) {
    removeClipFromCase(id: $id) {
      id
      deleted
    }
  }
`;

gql`
  mutation removeScreenshotFromCase($caseId: Int!, $screenshotId: Int!) {
    removeScreenshotFromCase(caseId: $caseId, screenshotId: $screenshotId) {
      id
      deleted
    }
  }
`;
