import CircleIcon from "@mui/icons-material/Circle";
import { Chip, IconButton, Skeleton, Typography } from "@mui/material";
import clsx from "clsx";
import { useState } from "react";
import { Link } from "react-router-dom";

import { StillImage } from "@/components/Still/StillImage";

import {
  IntegrationSourcesQuery,
  IntegrationTypeKey,
} from "@/generated-models";

import { chipProps } from "../../constant";
import { useCurrentTypeId, useIntegrationSetupState } from "../../hooks";

interface IntegrationDetailsDeviceCardProps {
  source: IntegrationSourcesQuery["integrationSources"][number];
}

function IntegrationDetailsDeviceStatus({
  source,
}: IntegrationDetailsDeviceCardProps) {
  const { data, loading } = useIntegrationSetupState(source.integrationId);
  const typeId = useCurrentTypeId();

  const { standardMeta } = source;
  const { slotId, onLabel, offLabel, normallyClosed } = standardMeta;

  if (typeId !== IntegrationTypeKey.IoBoard) return <></>;

  const on = data?.integration?.setupState?.boardStatus[slotId];
  const resolvedOn = normallyClosed ? !on : on;
  const label = resolvedOn ? onLabel ?? "On" : offLabel ?? "Off";

  if (loading) {
    return <Skeleton variant="rounded" className="h-6 w-full" />;
  }

  return (
    <div
      className={clsx("rounded px-2 py-1 w-full text-sm leading-4", {
        "bg-[#EBFFEA] text-[#2CB626]": resolvedOn,
        "bg-[#FFF0EC] text-[#FF490F]": !resolvedOn,
      })}
    >
      Status: <strong>{label}</strong>
    </div>
  );
}

function IntegrationDetailsDeviceHeader({
  source,
}: IntegrationDetailsDeviceCardProps) {
  const [currentCameraIdx, setCurrentCameraIdx] = useState(0);
  const { cameras = [] } = source;

  const currentCamera = cameras[currentCameraIdx];
  const currentStill = currentCamera?.still;

  return (
    <div className="flex flex-col items-start justify-center gap-2">
      <IntegrationDetailsDeviceStatus source={source} />
      <StillImage
        className={clsx("rounded aspect-video h-full w-full", {
          "object-cover": !currentStill,
        })}
        src={currentStill || "/no-still.svg"}
        alt={currentCamera?.name}
      />
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        className="flex items-center justify-center gap-2 w-full min-h-[10px] mb-4"
      >
        {cameras.map((c, idx) => (
          <IconButton
            onClick={() => {
              setCurrentCameraIdx(idx);
            }}
            className="p-0"
            size="small"
            key={c.id}
          >
            <CircleIcon
              className={clsx("w-[10px] h-[10px] text-[#353D48]", {
                "opacity-30": idx !== currentCameraIdx,
              })}
            />
          </IconButton>
        ))}
      </div>
    </div>
  );
}

function IntegrationDetailsDeviceCardFooter({
  source,
}: IntegrationDetailsDeviceCardProps) {
  const { standardMeta, tags = [] } = source;
  const { name, siteName } = standardMeta;

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col items-start">
        <Typography className="text-primary text-sm leading-4">
          {siteName}
        </Typography>
        <Typography className="text-primary text-lg leading-[21px] font-bold">
          {name}
        </Typography>
      </div>
      <div className="flex flex-col sm:flex-row gap-2 items-start">
        {tags.map((tag: string) => (
          <Chip
            key={tag}
            label={tag}
            {...chipProps}
            classes={{
              ...chipProps.classes,
              label: "text-sm leading-4",
            }}
          />
        ))}
      </div>
    </div>
  );
}

export function IntegrationDetailsDeviceCard({
  source,
}: IntegrationDetailsDeviceCardProps) {
  const { id } = source;

  return (
    <Link to={`./device/${id}`}>
      <div className="rounded-lg bg-white border border-solid border-[#DCDCDC] p-3">
        <IntegrationDetailsDeviceHeader source={source} />
        <IntegrationDetailsDeviceCardFooter source={source} />
      </div>
    </Link>
  );
}
