import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";

interface CameraSettingsFeedbackDialogProps {
  open: boolean;
  onClose: () => void;
  messages: string[];
}

export function CameraSettingsFeedbackDialog({
  open,
  onClose,
  messages,
}: CameraSettingsFeedbackDialogProps) {
  return (
    <Dialog
      classes={{
        paper: "rounded-lg max-w-[493px]",
      }}
      open={open}
      onClose={onClose}
    >
      <DialogTitle className="flex justify-between items-center py-4 bg-[#F4F4F4]">
        <Typography variant="h3">Unable to Update Cameras</Typography>
      </DialogTitle>
      <DialogContent className="p-6">
        <DialogContentText className="text-text text-sm leading-4">
          <Typography className="text-sm leading-4 mb-2">
            There was an error when attempting to update the cameras:
          </Typography>
          <Typography className="text-sm leading-4 mb-2">
            <ul className="list-disc px-[14px]">
              {messages.map((m) => (
                <li key={m}>{m}</li>
              ))}
            </ul>
          </Typography>
          <Typography className="text-sm leading-4 mt-6">
            Please contact support if the issue persists.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          className="shadow-none bg-[#DAEEFF] text-[#007CE4] font-normal rounded-[6px] disabled:bg-[#EEEEEE] disabled:text-[#9C9C9C]"
          onClick={onClose}
        >
          Close
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="font-normal rounded-[6px] shadow-none"
          onClick={() => {
            window.open("https://spotai.zendesk.com/hc/en-us/requests/new");
            onClose();
          }}
        >
          Contact Support
        </Button>
      </DialogActions>
    </Dialog>
  );
}
