import { useLimitedStills } from "@/components/Camera/ExpandingSnippetPreview";
import { StillImage } from "@/components/Still/StillImage";

export function DatagridStill({
  cameraId,
  start,
  end,
}: {
  cameraId: number;
  start: Date;
  end: Date;
}) {
  const stills = useLimitedStills({
    start: start.toISOString(),
    end: end.toISOString(),
    cameraId,
    limited: true,
  });

  return stills.length > 0 ? (
    <StillImage
      className="h-full w-full object-cover"
      src={stills[0].src}
      alt=""
    />
  ) : null;
}
