import { memo } from "react";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";

import { StillImage } from "@/components/Still/StillImage";

import { useLatestCameraStillQuery } from "@/generated-models";
import { usePrefixOrgSlug } from "@/hooks/useOrgRouteBase";

export const LazyImage = memo(
  ({
    cameraId,
    scrollContainer,
    containerClassName,
    ...props
  }: React.ImgHTMLAttributes<HTMLImageElement> & {
    containerClassName?: string;
    cameraId: number;
    scrollContainer?: HTMLElement | string;
  }) => {
    const { ref, inView } = useInView({
      triggerOnce: true,
    });
    return (
      <div ref={ref} className={containerClassName}>
        {inView && <CameraImage cameraId={cameraId} {...props} />}
      </div>
    );
  }
);

export function CameraImage({
  cameraId,
  alt = "",
  ...props
}: React.ImgHTMLAttributes<HTMLImageElement> & {
  cameraId: number;
}) {
  const navigate = useNavigate();
  const prefixOrgSlug = usePrefixOrgSlug();
  const { data } = useLatestCameraStillQuery({ variables: { id: cameraId } });
  if (!data?.camera.still) return null;
  return (
    <StillImage
      onDoubleClick={() => {
        navigate(prefixOrgSlug(`/live?cams=${cameraId}`));
      }}
      data-cy={`camera-image-${cameraId}`}
      alt={alt}
      {...props}
      src={props.src || data.camera.still}
    />
  );
}

export const LazyBackground = memo(
  ({
    cameraId,
    ...props
  }: React.HTMLAttributes<HTMLSpanElement> & {
    cameraId: number;
  }) => {
    const { ref, inView } = useInView({
      triggerOnce: true,
    });
    return (
      <div ref={ref}>
        {inView && <CameraBackgroundImage cameraId={cameraId} {...props} />}
      </div>
    );
  }
);

function CameraBackgroundImage({
  cameraId,
  ...props
}: React.HTMLAttributes<HTMLSpanElement> & {
  cameraId: number;
}) {
  // const navigate = useNavigate();
  const { data } = useLatestCameraStillQuery({ variables: { id: cameraId } });
  if (!data?.camera.still) return null;
  return (
    <span
      data-cy={`camera-bg-image-${cameraId}`}
      // Removing this for now, as it's causing weird behavior in a lot of camera drawers.
      // onDoubleClick={() => {
      //   navigate(`/live?cams=${cameraId}`);
      // }}
      style={{ backgroundImage: `url(${data.camera.still})` }}
      {...props}
    />
  );
}
