import CircleIcon from "@mui/icons-material/Circle";
import { Button, IconButton, Typography } from "@mui/material";
import clsx from "clsx";
import gql from "graphql-tag";
import { useFlags } from "launchdarkly-react-client-sdk";
import { ReactNode, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { useBreakpoints } from "@/util/useBreakpoints";

import { useRangeParam, useVodParam } from "@/pages/Search/searchHooks";

import { FullScreen } from "@/components/FullScreen";
import {
  AddToCaseDialog,
  CaseContentType,
} from "@/components/Player/AddToCaseDialog";
import {
  LivePlayer,
  VodPlayer,
} from "@/components/Player/MainPlayer/MainPlayer";
import { PlayerIdsProvider } from "@/components/Player/PlayerBase";
import { CaseSmallIcon } from "@/components/Player/PlayerIcons";
import { IntercomAudio } from "@/components/Player/WebRTCPlayer/IntercomAudio";
import { PlayerMachineProvider } from "@/components/Player/playerMachine";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { StillImage } from "@/components/Still/StillImage";
import { WallClock } from "@/components/View/View";
import { WiredCanvasTimeline } from "@/components/View/WiredCanvasTimeline";

import {
  IntegrationTypeKey,
  useAddClipsToCaseMutation,
} from "@/generated-models";
import { usePermissions } from "@/hooks/usePermissions";

import IntegrationsCard from "../../Core/IntegrationsCard";
import {
  useActiveCameras,
  useCurrentTypeId,
  useCurrentVendor,
  useIntegrationEvents,
  useIntegrationTypeIcon,
  useSourceById,
} from "../../hooks";

interface DeviceOverviewPlayerHeaderProps {
  title: string;
}

function getResolvedValue(standardMeta: {
  value: boolean;
  normallyClosed: boolean;
}) {
  return standardMeta.normallyClosed ? !standardMeta.value : standardMeta.value;
}

function useCanvasEventGroups() {
  const { rangeEnd } = useRangeParam();
  const { data } = useIntegrationEvents();
  const type = useCurrentTypeId();
  const Icon = useIntegrationTypeIcon();
  const vendor = useCurrentVendor();

  return useMemo(() => {
    switch (type) {
      case IntegrationTypeKey.IoBoard:
        const items = data.sort((a, b) => (a.ts || 0) - (b.ts || 0));
        const lastItem = items[data.length - 1];
        const onLabel = lastItem?.standardMeta?.onLabel || "On";
        const offLabel = lastItem?.standardMeta?.offLabel || "Off";
        const onEvents: { start: number; end: number }[] = [];
        const offEvents: { start: number; end: number }[] = [];

        items.forEach((d) => {
          const value = getResolvedValue(d.standardMeta);
          const ts = d.ts || 0;

          const nextStateEvent = items.find(
            (n) =>
              getResolvedValue(n.standardMeta) !== value && (n.ts || 0) > ts
          );

          if (value) {
            onEvents.push({
              start: ts,
              end: nextStateEvent?.ts || rangeEnd.getTime(),
            });
          } else {
            offEvents.push({
              start: ts,
              end: nextStateEvent?.ts || rangeEnd.getTime(),
            });
          }
        });

        return [
          {
            label: offLabel,
            config: {
              color: (opacity: number = 1) => `rgba(152, 0, 223, ${opacity})`,
              icon: <span className="text-[#ff490f]">{offLabel}</span>,
            },
            groups: [
              {
                color: 0xff490f,
                events: offEvents,
              },
            ],
          },
          {
            label: onLabel,
            config: {
              color: (opacity: number = 1) => `rgba(152, 0, 223, ${opacity})`,
              icon: <span className="text-[#2cb626]">{onLabel}</span>,
            },
            groups: [
              {
                color: 0x2cb626,
                events: onEvents,
              },
            ],
          },
        ];
      default:
        return [
          {
            label: vendor?.name || "",
            config: {
              color: (opacity: number = 1) => `rgba(152, 0, 223, ${opacity})`,
              icon: <Icon />,
            },
            groups: [
              {
                color: 0x979797,
                events: data.map((d) => {
                  const ts = d.ts || 0;
                  return {
                    start: ts,
                    end: ts + 60000,
                  };
                }),
              },
            ],
          },
        ];
    }
  }, [Icon, data, rangeEnd, type, vendor?.name]);
}

function NoCameraConfiguredTile() {
  return (
    <div className="min-h-[200px] md:min-h-[400px] text-white">
      <StillImage
        className="aspect-video h-full w-full object-cover"
        src="/no-still.svg"
        alt="No cameras configured"
      />
    </div>
  );
}

function IntegrationsDetailsDeviceLivePlayer({
  lowLatency = false,
}: {
  lowLatency?: boolean;
}) {
  const { activeCams, loading } = useActiveCameras();

  if (loading) {
    return <></>;
  }

  if (activeCams.length === 0) {
    return <NoCameraConfiguredTile />;
  }

  return (
    <LivePlayer
      cameras={activeCams}
      overrideMachineProvider={false}
      lowLatency={lowLatency}
    />
  );
}

function IntegrationsDetailsDeviceVodPlayer() {
  const { activeCams, loading } = useActiveCameras();
  const { vodStart, vodEnd } = useVodParam();

  if (loading) {
    return <></>;
  }

  if (activeCams.length === 0) {
    return <NoCameraConfiguredTile />;
  }

  return (
    <VodPlayer
      cameras={activeCams}
      startTime={vodStart.toISOString()}
      endTime={vodEnd.toISOString()}
      overrideMachineProvider={false}
    />
  );
}

function LiveIndicator() {
  return (
    <div className="bg-[#23282f] rounded px-[18px] py-[10px] text-[#18D710] flex items-center gap-2">
      <CircleIcon className="text-[10px]" />
      <Typography className="font-bold text-sm leading-[16.41px]">
        LIVE
      </Typography>
    </div>
  );
}

// TODO: Enable share and case buttons when ready.
function DeviceOverviewPlayerHeader({
  title,
}: DeviceOverviewPlayerHeaderProps) {
  const Icon = useIntegrationTypeIcon();
  return (
    <div className="flex items-center justify-between pb-4">
      <Typography className="font-bold text-2xl leading-[28px] flex items-center gap-2">
        <Icon className="text-[28px]" />
        {title}
      </Typography>
      {/* <div className="flex items-center gap-4">
        <IconButton disableRipple className="p-0" color="primary">
          <ShareIcon />
        </IconButton>
        <IconButton disableRipple className="p-0" color="primary">
          <CasesIcon />
        </IconButton>
      </div> */}
    </div>
  );
}

function DeviceOverviewPlayerFooter() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { activeCams } = useActiveCameras();
  const { pushSnackbar } = useFeedback();
  const { activeCamIds } = useActiveCameras();
  const { vodStart, vodEnd } = useVodParam();
  const [dialogOpen, setDialogOpen] = useState(false);
  const { intercomIntegration } = useFlags();
  const type = useCurrentTypeId();

  const [addClipsToCase] = useAddClipsToCaseMutation({
    onError: () =>
      pushSnackbar(
        "We were unable to save this clip. Please try again later.",
        FeedbackType.Error
      ),
  });

  const live = !searchParams.get("vod");
  return (
    <div className="bg-[#191919] rounded-b-2xl p-4">
      <div className="flex items-center justify-between">
        <WallClock
          dateFormat="PPPP"
          timezone={activeCams?.[0]?.location?.timezone}
        />

        {intercomIntegration &&
          type === IntegrationTypeKey.Intercom &&
          activeCams[0]?.intercom?.id && (
            <>
              <div className="grow" />
              <IntercomAudio
                cameraId={activeCams[0].id}
                url={activeCams[0].feeds?.webRTC || ""}
              />
              <div className="mr-5" />
            </>
          )}

        {live && <LiveIndicator />}
        {!live && (
          <>
            <div className="flex items-center justify-between gap-3">
              <div className="flex gap-3">
                <IconButton
                  className="text-white"
                  onClick={() => {
                    setDialogOpen(true);
                  }}
                  aria-label="Add to case"
                  size="small"
                >
                  <CaseSmallIcon />
                </IconButton>
              </div>
              <Button
                variant="contained"
                color="primary"
                className="shadow-none font-bold"
                onClick={() => {
                  searchParams.delete("vod");
                  searchParams.delete("id");
                  searchParams.delete("hideMultiCamRemove");
                  searchParams.delete("hideMultiCamZone");
                  setSearchParams(searchParams);
                }}
              >
                Return to Live
              </Button>
            </div>
            <AddToCaseDialog
              type={CaseContentType.bulk_clips}
              open={dialogOpen}
              setOpen={setDialogOpen}
              onSave={async (caseId: number, redirectToCase: boolean) => {
                await addClipsToCase({
                  variables: {
                    caseId,
                    value: {
                      cameraIds: activeCamIds,
                      startTime: vodStart.toISOString(),
                      endTime: vodEnd.toISOString(),
                    },
                  },
                });
              }}
            />
          </>
        )}
      </div>
    </div>
  );
}

function IntegrationsVideoProviders({ children }: { children: ReactNode }) {
  const { activeCamIds } = useActiveCameras();

  return (
    <PlayerIdsProvider cameraIds={activeCamIds}>
      <PlayerMachineProvider>
        <FullScreen className="flex grow flex-col dark">{children}</FullScreen>
      </PlayerMachineProvider>
    </PlayerIdsProvider>
  );
}

function CanvasTimeline() {
  const { activeCams } = useActiveCameras();
  const { rangeStart, rangeEnd } = useRangeParam();
  const { vodStart, vodEnd } = useVodParam();
  const eventGroups = useCanvasEventGroups();
  const [showMissingFootage] = useState(
    localStorage.enableMissingFootage === "true"
  );

  if (activeCams.length === 0) {
    return <></>;
  }

  return (
    <WiredCanvasTimeline
      eventGroups={eventGroups}
      className="rounded-lg"
      showMissingFootage={showMissingFootage}
      timezone={activeCams[0]?.location?.timezone}
      focusedCameraId={activeCams[0]?.id}
      activeWindow={
        vodStart && vodEnd
          ? { lower: vodStart.getTime(), upper: vodEnd.getTime() }
          : undefined
      }
      bounds={{
        lower: rangeStart.getTime(),
        upper: rangeEnd.getTime(),
      }}
      style={{ borderRadius: 8, boxShadow: "0 0 15px rgba(0, 0, 0, 0.15)" }}
    />
  );
}

export function IntegrationsDetailsDeviceOverviewPlayer({
  lowLatency = false,
}: {
  lowLatency?: boolean;
}) {
  const { fitsDesktop } = useBreakpoints();
  const [searchParams] = useSearchParams();
  const hasPermission = usePermissions();
  const { data } = useSourceById();

  const title = data?.standardMeta?.name;
  const vod = searchParams.get("vod");

  if (
    (vod && !hasPermission("video_vod_access")) ||
    (!vod && !hasPermission("video_live_access"))
  ) {
    return <div className="col-span-1 md:col-span-2" />;
  }

  return (
    <div className="col-span-1 md:col-span-2">
      <IntegrationsVideoProviders>
        <IntegrationsCard
          className={clsx("rounded-b-none rounded-t-none md:rounded-2xl pb-0", {
            "!p-0": !fitsDesktop,
          })}
          dense
        >
          {fitsDesktop && <DeviceOverviewPlayerHeader title={title} />}
          <div className="bg-black min-h-[200px] md:-mx-4">
            {vod ? (
              <IntegrationsDetailsDeviceVodPlayer />
            ) : (
              <IntegrationsDetailsDeviceLivePlayer lowLatency={lowLatency} />
            )}
          </div>
        </IntegrationsCard>
        <DeviceOverviewPlayerFooter />
      </IntegrationsVideoProviders>
      <div className="md:block hidden mt-2">
        <CanvasTimeline />
      </div>
    </div>
  );
}

gql`
  mutation addClipsToCase($caseId: Int!, $value: CaseClipsInput!) {
    addClipsToCase(caseId: $caseId, value: $value) {
      id
    }
  }
`;
