import {
  Button,
  ButtonGroup,
  CircularProgress,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { useFlags } from "launchdarkly-react-client-sdk";
import { uniq } from "lodash/fp";
import { useMemo } from "react";
import {
  BooleanParam,
  StringParam,
  useQueryParam,
  useQueryParams,
} from "use-query-params";

import { CAMERA_STATUS_OUT_OF_SPEC } from "@/pages/Maintain/constants";
import { useMaintainDevices } from "@/pages/Maintain/hooks";

import { useMe } from "@/components/Auth";
import { useDataGridSelection } from "@/components/DataGrid/hooks";
import { FilterBar } from "@/components/Filter/FilterBar";
import { FilterBarControls } from "@/components/Filter/FilterBarControls";
import { FilterFieldOption } from "@/components/Filter/constant";
import { SearchBox } from "@/components/SearchBox";

import { getAiConfigSettings } from "./useMaintainBulkConfig";

interface MaintainToolbarProps {
  loading?: boolean;
  online: number;
  offline: number;
  outOfSpec?: number;
}
interface FilterButtonProps {
  className?: string;
  loading?: boolean;
  count: number;
  label: string;
  query: string;
  filtered?: boolean | null;
  onClick: () => void;
}

function FilterButton({
  className,
  loading,
  count,
  label,
  query,
  filtered,
  onClick,
}: FilterButtonProps) {
  return (
    <Button
      data-cy={`filter-btn-${query}`}
      disabled={loading}
      className={clsx(
        "h-[40px] sm:w-[unset] w-full md:text-sm text-xs md:px-2 px-1",
        className
      )}
      variant={filtered ? "contained" : "outlined"}
      color="primary"
      onClick={onClick}
    >
      <div className="flex items-center gap-x-1">
        {loading && <CircularProgress size={14} color="inherit" />}
        <span>{!loading && count}</span>
        <span>{label}</span>
      </div>
    </Button>
  );
}

export function MaintainToolbar({
  online,
  offline,
  outOfSpec = -1,
  loading,
}: MaintainToolbarProps) {
  const me = useMe();
  const { cameraBulkCfg } = useFlags();
  const { appliances = [] } = useMaintainDevices();
  const { editing } = useDataGridSelection();
  const [params, setParams] = useQueryParams({
    online: BooleanParam,
    offline: BooleanParam,
    [CAMERA_STATUS_OUT_OF_SPEC]: BooleanParam,
  });
  const [searchInputParam, setSearchInputParam] = useQueryParam(
    "search",
    StringParam
  );

  const sortedTags = useMemo(() => {
    return uniq(
      appliances.map((appliance) => appliance.location.name).flat()
    ).sort();
  }, [appliances]);

  const aiFeatureFilterOptions: FilterFieldOption[] = getAiConfigSettings(
    me?.organization.flags,
    me?.organization.permissions
  ).flatMap((s) => [
    {
      category: true,
      label: s.label,
      value: "",
    },
    {
      label: "Enabled",
      secondaryLabel: s.label,
      value: `${s.field}--enabled`,
    },
    {
      label: "Disabled",
      secondaryLabel: s.label,
      value: `${s.field}--disabled`,
    },
  ]);

  const audioFilterOptions = [
    {
      label: "Enabled",
      value: `audioControlEnabled--enabled`,
    },
    {
      label: "Disabled",
      value: `audioControlEnabled--disabled`,
    },
  ];
  const filters = [
    {
      placeholder: "Groups & Locations",
      label: "Groups & Locations",
      disabled: !!editing,
      filterName: "group",
      options:
        sortedTags?.map((d) => ({
          label: d,
          value: d,
        })) || [],
    },
    ...(cameraBulkCfg
      ? [
          {
            placeholder: "AI Features",
            label: "AI Features",
            disabled: !!editing,
            getValue: (value: string | null) => {
              const option = aiFeatureFilterOptions.find(
                (f) => f.value === value
              );
              return option
                ? `${option.secondaryLabel} ${option.label}`
                : value || "";
            },
            filterName: "aiFeatures",
            selectedLabel: "Features",
            options: aiFeatureFilterOptions,
          },
          {
            placeholder: "Audio",
            label: "Audio",
            disabled: !!editing,
            getValue: (value: string | null) => {
              const option = audioFilterOptions.find((f) => f.value === value);
              return option ? `Audio ${option.label}` : value || "";
            },
            filterName: "cameraFeatures",
            selectedLabel: "Audio Filter",
            options: audioFilterOptions,
          },
        ]
      : []),
  ];

  return (
    <div className="flex flex-col bg-white">
      <div className="flex flex-col md:flex-row gap-x-3 gap-y-3 items-center px-6 py-3">
        <Typography
          color="textSecondary"
          variant="body2"
          className="font-medium text-sm lg:w-[80px] ml-1"
        >
          Filter by:
        </Typography>
        <ButtonGroup
          className="lg:w-[unset] w-full justify-center md:justify-start"
          disabled={!!editing}
          disableElevation
        >
          <FilterButton
            loading={loading}
            count={online}
            label="Online"
            query="online"
            filtered={params.online}
            onClick={() =>
              setParams((value) => ({
                online: value.online ? undefined : true,
                offline: undefined,
                [CAMERA_STATUS_OUT_OF_SPEC]: undefined,
              }))
            }
          />
          <FilterButton
            loading={loading}
            count={offline}
            label="Offline"
            query="offline"
            filtered={params.offline}
            onClick={() =>
              setParams((value) => ({
                online: undefined,
                offline: value.offline ? undefined : true,
                [CAMERA_STATUS_OUT_OF_SPEC]: undefined,
              }))
            }
          />
          {outOfSpec >= 0 && (
            <FilterButton
              className="min-w-[115px] !text-xs"
              loading={loading}
              count={outOfSpec}
              label="Out of Spec"
              query={CAMERA_STATUS_OUT_OF_SPEC}
              filtered={params[CAMERA_STATUS_OUT_OF_SPEC]}
              onClick={() =>
                setParams((value) => ({
                  online: undefined,
                  offline: undefined,
                  [CAMERA_STATUS_OUT_OF_SPEC]: value[CAMERA_STATUS_OUT_OF_SPEC]
                    ? undefined
                    : true,
                }))
              }
            />
          )}
        </ButtonGroup>
        <div className="flex flex-col md:flex-row items-center justify-center gap-2 w-full">
          <FilterBarControls
            className="!w-[unset] flex-initial"
            hideExpand
            filters={filters}
          />
          <div className="ml-auto w-full sm:w-[unset]">
            <SearchBox
              disabled={!!editing}
              className={clsx("w-full", {
                "md:min-w-[200px]": !editing,
              })}
              input={searchInputParam || ""}
              setInput={setSearchInputParam}
            />
          </div>
        </div>
      </div>
      <FilterBar className="pb-1" filters={filters} />
    </div>
  );
}
