import { Backdrop, Tooltip } from "@mui/material";
import { Field } from "formik";
import gql from "graphql-tag";
import { ChangeEvent } from "react";

import { CamDrawer } from "@/components/CamDrawer/CamDrawerBase";
import { CamWrapperProps } from "@/components/CamDrawer/CamDrawerList";
import { CamDrawerListDescription } from "@/components/CamDrawer/CamDrawerListDescription";

import { IntDashCam } from "./IntelligenceDashboardForm";

const setDisabledforUnlicensedCameras = (camera: IntDashCam) => {
  const licensing = camera.appliance.licensesSupported;
  const disabled = !licensing && !camera.aiEnabled;
  return { ...camera, disabled };
};
const setSelectedByIds = (cameraIds: number[]) => (camera: IntDashCam) => ({
  ...camera,
  selected: cameraIds.includes(camera.id),
});

export function IntelligenceDashboardCamDrawer({
  open,
  onOpen,
  onClose,
  cameras,
  selectedCameraIds,
  setSelectedCameraIds,
  multiple,
}: {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
  cameras: IntDashCam[];
  setSelectedCameraIds: (newValue: number[]) => void;
  selectedCameraIds: number[];
  multiple: boolean;
}) {
  const transformedCameras = cameras
    .map(setDisabledforUnlicensedCameras)
    .map(setSelectedByIds(selectedCameraIds));

  function CamWrapper({ cam, children }: CamWrapperProps) {
    return (
      <Tooltip
        title={
          cam.disabled
            ? "This camera cannot be selected, because it is not AI-enabled"
            : ""
        }
      >
        <label>
          <Field
            type="radio"
            name="cameraId"
            value={cam.id}
            className="absolute opacity-0 w-0 h-0"
            disabled={cam.disabled}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              if (!cam.selected) {
                if (multiple) {
                  setSelectedCameraIds([...selectedCameraIds, cam.id]);
                } else {
                  setSelectedCameraIds([cam.id]);
                }
              } else {
                setSelectedCameraIds(
                  selectedCameraIds.filter((id) => id !== cam.id)
                );
              }
              if (!multiple || setSelectedCameraIds.length === 1) {
                onClose();
              }
            }}
          />
          {children}
        </label>
      </Tooltip>
    );
  }

  return (
    <>
      <Backdrop open={open} onClick={onClose} className="z-1" />
      <CamDrawer open={open} onOpen={onOpen} onClose={onClose}>
        <CamDrawer.Filters>
          <CamDrawer.GroupSelector />
          <CamDrawer.SearchBox placeholder="Search Cameras" fullWidth />
        </CamDrawer.Filters>
        <CamDrawerListDescription description="Cameras that are grayed out are not AI-enabled and cannot be selected" />
        <CamDrawer.List cameras={transformedCameras} CamWrapper={CamWrapper} />
      </CamDrawer>
    </>
  );
}

gql`
  query intDashCams {
    cameras {
      id
      name
      status
      aiEnabled
      feeds {
        tunnel
        local
      }

      settings {
        lprEnabled
        modelForkliftEnabled
      }

      focusZones {
        id
        name
        shape {
          x
          y
        }
      }

      location {
        id
        name
      }

      appliance {
        id
        licensesSupported
      }

      tags {
        id
        name
      }
    }
  }
`;
