import { Typography } from "@mui/material";
import clsx from "clsx";

import { BackButton } from "@/layout/BackButton";

interface IntegrationsNavigationHeaderProps {
  title: string;
  to?: string;
  className?: string;
  textClassName?: string;
  subText?: string;
}

export default function IntegrationsNavigationHeader({
  title,
  to,
  className,
  textClassName,
  subText,
}: IntegrationsNavigationHeaderProps) {
  return (
    <div className={clsx("flex items-center justify-start gap-3", className)}>
      <BackButton to={to} className="mr-3" />
      <div>
        {subText && (
          <Typography className="text-sm md:text-base">{subText}</Typography>
        )}
        <Typography
          className={clsx(
            "text-[24px] sm:text-[32px] leading-5 sm:leading-[37.5px] font-bold w-full",
            textClassName
          )}
        >
          {title}
        </Typography>
      </div>
    </div>
  );
}
