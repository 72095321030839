import clsx from "clsx";
import React from "react";

/**
 * @deprecated use CSS aspect ratio instead (https://tailwindcss.com/docs/aspect-ratio)
 */
export function FixedAspectRatio({
  children,
  ratio,
  className,
  classNameInner,
  style,
}: {
  children: React.ReactNode;
  ratio: number | string;
  className?: string;
  classNameInner?: string;
  style?: React.CSSProperties;
}) {
  return (
    <div style={style} className={clsx("relative", className)}>
      <div
        style={{
          paddingTop: `calc(100% * ${ratio} / 2)`,
          paddingBottom: `calc(100% * ${ratio} / 2)`,
        }}
      />
      <div className={clsx("absolute w-full h-full top-0", classNameInner)}>
        {children}
      </div>
    </div>
  );
}
