import { GridColDef, GridSortModel } from "@mui/x-data-grid";
import clsx from "clsx";
import { format } from "date-fns-tz";
import gql from "graphql-tag";
import { useAtom } from "jotai";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ArrayParam, StringParam, useQueryParam } from "use-query-params";

import { filterNullish } from "@/util/filterFalsy";

import { useSearchRangeParams } from "@/pages/Search/searchHooks";
import { auditLogActionsAtom } from "@/pages/Settings/AuditLogs/FilterAccordion";

import { DataGrid } from "@/components/DataGrid/DataGrid";

import { Sort, useAuditLogsQuery } from "@/generated-models";

import { getDescription } from "./AuditDetails";
import { AuditLogDetailsDrawer } from "./AuditDetailsDrawer";

const DEFAULT_PAGE_SIZE = 100;

export const AuditLogsDatagrid = () => {
  const { rangeStart, rangeEnd } = useSearchRangeParams();
  const [categoriesFilter] = useQueryParam("categories", ArrayParam);
  const [usersFilter] = useQueryParam("users", ArrayParam);
  const [sortMode, setSortMode] = useQueryParam("sort", StringParam);
  const [auditLogActions] = useAtom(auditLogActionsAtom);

  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [selectedEvent, setSelectedEvent] = useState<
    AuditLogEvent | undefined
  >();

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const { data, loading } = useAuditLogsQuery({
    variables: {
      offset: paginationModel.page * paginationModel.pageSize,
      limit: paginationModel.pageSize,
      filters: {
        start: rangeStart.toISOString(),
        end: rangeEnd.toISOString(),
        categories: categoriesFilter?.filter(filterNullish),
        actions: auditLogActions?.filter(filterNullish),
        users: usersFilter?.map((u) => Number(u) || null).filter(filterNullish),
      },
      sort: sortMode === Sort.Asc ? Sort.Asc : undefined,
    },
  });

  const totalCount = data?.auditLogs.paginationInfo.totalCount;
  const [rowCountState, setRowCountState] = useState(totalCount || 0);
  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      totalCount !== undefined ? totalCount : prevRowCountState
    );
  }, [totalCount, setRowCountState]);

  const entries = data?.auditLogs.results;
  const users = data?.users;

  const rows: AuditLogEvent[] = useMemo(() => {
    return (
      entries?.map((e, idx) => {
        const user = users?.find((u) => u.id === e.profileId);
        return {
          id: idx,
          timestamp: new Date(e.timestamp),
          profileId: e.profileId,
          userEmail: user?.profile.email ?? "",
          userName: user?.profile.name ?? "<unknown user>",
          category: e.category,
          action: e.action,
          details: e.details,
        };
      }) ?? []
    );
  }, [entries, users]);

  const onSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      setSortMode(sortModel[0].sort === Sort.Asc ? Sort.Asc : undefined);
    },
    [setSortMode]
  );

  return (
    <>
      <DataGrid
        loading={loading}
        rowHeight={50}
        rows={rows}
        rowCount={rowCountState}
        classes={{
          cell: "outline-none border-none",
          columnHeaders: "bg-white",
        }}
        className="md:rounded-none border-x-0 border-b-0"
        initialState={{
          pagination: { paginationModel },
          sorting: {
            sortModel: [
              {
                field: "timestamp",
                sort: sortMode === Sort.Asc ? Sort.Asc : Sort.Desc,
              },
            ],
          },
        }}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        sortingMode="server"
        onSortModelChange={onSortModelChange}
        columns={
          [
            {
              field: "timestamp",
              headerName: "Timestamp",
              width: 130,
              renderCell: ({ row, value }) => {
                const time = format(value, "h:mm a");
                const date = format(row.timestamp, "MMM d, yyyy");
                return (
                  <div>
                    <div>{date}</div>
                    <div>
                      <strong>{time}</strong>
                    </div>
                  </div>
                );
              },
            },
            {
              headerName: "User",
              sortable: false,
              renderCell: ({ row }) => {
                return (
                  <div>
                    <div>{row.userName}</div>
                  </div>
                );
              },
              flex: 1,
            },
            {
              field: "category",
              headerName: "Category",
              sortable: false,
              flex: 1,
            },
            {
              field: "action",
              headerName: "Action",
              sortable: false,
              flex: 1,
              valueFormatter: ({ value }) => getDescription(value),
            },
          ] as GridColDef<AuditLogEvent>[]
        }
        getRowClassName={(params) => {
          if (params.id === selectedId) {
            return "bg-blue-medium";
          }
          return clsx(
            "cursor-pointer",
            params.indexRelativeToCurrentPage % 2 === 0
              ? "bg-blue-medium/[.2]"
              : "bg-white"
          );
        }}
        onRowClick={(params) => {
          setSelectedId(Number(params.id));
          setSelectedEvent(params.row);
        }}
      />
      <AuditLogDetailsDrawer
        event={selectedEvent}
        closeDrawer={() => setSelectedEvent(undefined)}
      />
    </>
  );
};

export type AuditLogEvent = {
  timestamp: Date;
  profileId: number;
  userEmail: string;
  userName: string;
  category: string;
  action: string;
  details?: object;
};

gql`
  query auditLogs(
    $offset: Int!
    $limit: Int!
    $filters: AuditLogFilters!
    $sort: Sort
  ) {
    users {
      id
      profile {
        id
        email
        name
      }
    }
    auditLogs(offset: $offset, limit: $limit, filters: $filters, sort: $sort) {
      results {
        timestamp
        profileId
        category
        action
        details
      }
      paginationInfo {
        totalCount
      }
    }
  }
`;
