import { Typography } from "@mui/material";
import clsx from "clsx";
import { useAtom, useAtomValue, useSetAtom } from "jotai";

import { CopilotLassoIcon } from "@/icons/CopilotIcon";

import DrawZone from "@/components/Zones/DrawZone";

import {
  useAddItemToOrgPromptList,
  useRefetchUniversalSearch,
  useRemoveItemFromOrgPromptList,
  useUniversalSearchByImage,
} from "../../../../copilotQueryHooks";
import { formatSubjectLabel } from "../../../../utils";
import { CopilotLibraryInput } from "../../Core/CopilotLibraryInput";
import {
  LibraryAddStep,
  LibraryMode,
  aiCopilotCustomObjectImage,
  aiCopilotCustomObjectShape,
  aiCopilotCustomShape,
  aiCopilotCustomShapeEditing,
  useGenerateVisualObject,
  useLibraryParams,
} from "../../copilotLibraryHooks";
import {
  CopilotLibraryAddedSuccessIndicator,
  CopilotLibraryObjectStateIndicator,
  ObjectSearchStatus,
} from "./CopilotLibraryObjectStateIndicator";

function useGetObjectSearchStatus() {
  const [libraryParams] = useLibraryParams();
  const { addStep, object } = libraryParams;
  const found = addStep === LibraryAddStep.found;

  return {
    status: found ? ObjectSearchStatus.found : ObjectSearchStatus.missing,
    subtitle: found
      ? "We found a few examples in this scene"
      : `We didn’t find any examples of "${object}" in this scene. You can still add the object to the custom object library.`,
  };
}

export function useLibraryActionCallbacks(
  object: string,
  setObject: (v: string) => void
) {
  const [libraryParams, setLibraryParams] = useLibraryParams();
  const { addItem, loading: loadingAdd } = useAddItemToOrgPromptList();
  const { removeItem, loading: loadingRem } = useRemoveItemFromOrgPromptList();
  const { loading } = useUniversalSearchByImage();
  const setShape = useSetAtom(aiCopilotCustomShape);
  const [editing, setEditing] = useAtom(aiCopilotCustomShapeEditing);
  const step = libraryParams.addStep;
  const refetch = useRefetchUniversalSearch();

  const setStep = (addStep: LibraryAddStep) => {
    setLibraryParams({ addStep });
  };

  const generateObjectData = useGenerateVisualObject();

  function resetLibraryAddState() {
    setStep(LibraryAddStep.input);
    setObject("");
  }

  switch (step) {
    case LibraryAddStep.found:
      return {
        loading: loading || loadingAdd,
        onCancel: async () => {
          setStep(LibraryAddStep.input);
          setLibraryParams({ retry: true, object: undefined });
        },
        onConfirm: async () => {
          const data = await addItem(libraryParams.object);
          if (data) setStep(LibraryAddStep.success);
        },
      };
    case LibraryAddStep.inputvisual:
      return {
        confirmDisabled: editing,
        onCancel: () => {
          setStep(LibraryAddStep.found);
          setEditing(true);
          setShape([]);
        },
        onConfirm: async () => {
          await generateObjectData();
          setStep(LibraryAddStep.ivconfirmation);
        },
      };
    case LibraryAddStep.ivconfirmation:
      return {
        confirmDisabled: editing,
        onCancel: () => {
          setStep(LibraryAddStep.inputvisual);
        },
        onConfirm: async () => {
          // TODO API call
        },
      };
    case LibraryAddStep.missing:
      return {
        loading: loading || loadingAdd,
        onCancel: () => {
          setStep(LibraryAddStep.input);
          setLibraryParams({ retry: true });
        },
        onConfirm: async () => {
          await addItem(libraryParams.object);
          setStep(LibraryAddStep.success);
        },
      };
    case LibraryAddStep.success:
      return {
        loading: loading || loadingRem,
        onCancel: async () => {
          await removeItem(libraryParams.object);
          await refetch({ promptOverride: undefined });
          resetLibraryAddState();
        },
        onConfirm: async () => {
          await refetch({ promptOverride: undefined });
          resetLibraryAddState();
        },
      };
    case LibraryAddStep.input:
      return {
        loading,
        onCancel: () => {
          setLibraryParams({ mode: LibraryMode.default, retry: undefined });
        },
        onConfirm: async () => {
          setLibraryParams({ object });

          const result = await refetch({ promptOverride: object });

          const found = (result?.universalSearchByImage?.items || []).filter(
            (r) => formatSubjectLabel(r.title) === object.toLowerCase()
          );

          setLibraryParams({
            addStep:
              found.length > 0 ? LibraryAddStep.found : LibraryAddStep.missing,
            retry: undefined,
          });
        },
      };
    default:
      return {
        onCancel: () => {
          setLibraryParams({ mode: LibraryMode.default });
        },
        onConfirm: () => {
          setLibraryParams({ object });
        },
      };
  }
}

export function useLibraryActionProps() {
  const [libraryParams] = useLibraryParams();
  const step = libraryParams.addStep;

  switch (step) {
    case LibraryAddStep.found:
      return {
        message: "Is this correct?",
        reverse: true,
        cancelGhost: false,
        confirmLabel: "Yes",
        cancelLabel: "No",
      };
    case LibraryAddStep.inputvisual:
      return {
        confirmLabel: "Continue",
        cancelLabel: "Cancel",
      };
    case LibraryAddStep.missing:
      return {
        confirmLabel: "Coach Object",
        cancelLabel: "Try Again",
      };
    case LibraryAddStep.success:
      return {
        confirmLabel: "Coach Another Object",
        cancelLabel: "Undo",
      };
    default:
      return {
        cancelGhost: true,
        confirmLabel: "Coach Object",
      };
  }
}

function InputVisualContent() {
  const [libraryParams] = useLibraryParams();
  const [editing] = useAtom(aiCopilotCustomShapeEditing);
  const customObjectImage = useAtomValue(aiCopilotCustomObjectImage);
  const customObjectShape = useAtomValue(aiCopilotCustomObjectShape);

  const confirmation = libraryParams.addStep === LibraryAddStep.ivconfirmation;

  return (
    <div className="flex flex-col items-center justify-center gap-[10px]">
      <CopilotLibraryObjectStateIndicator
        label={libraryParams.object || ""}
        status={ObjectSearchStatus.default}
        subtitle="Help us learn by circling it in the scene."
        dividerClassName="!pt-0"
      >
        <CopilotLassoIcon />
        <Typography
          className={clsx(
            "text-[13px] italic leading-normal opacity-50 text-[#B8B8B8]",
            {
              "w-full text-left": !editing,
            }
          )}
        >
          {confirmation
            ? "Selection Preview"
            : "Use the lasso tool to select the object"}
        </Typography>
        {confirmation && (
          <div className="relative">
            <DrawZone
              shape={customObjectShape}
              setShape={() => {}}
              editing={false}
              setEditing={() => {}}
              animated
              style={{
                color: "#fff",
                fillOpacity: 0.3,
              }}
              shapeProps={{
                strokeWidth: "2",
                strokeDasharray: "10",
                strokeDashoffset: "1",
              }}
            />
            <img alt="preview" src={customObjectImage} />
          </div>
        )}
      </CopilotLibraryObjectStateIndicator>
    </div>
  );
}

export function CopilotLibraryAddStepContent({
  value,
  onChange,
  onConfirm,
}: {
  value: string;
  onChange: (v: string) => void;
  onConfirm: () => void;
}) {
  const [libraryParams] = useLibraryParams();
  const step = libraryParams.addStep || LibraryAddStep.input;
  const statusProps = useGetObjectSearchStatus();
  return (
    <>
      {step === LibraryAddStep.input && (
        <>
          {libraryParams.retry && (
            <Typography className="text-[13px] text-[#B8B8B8]/80 italic leading-normal font-bold mb-1">
              Didn't find your object? Try again with a synonym.
            </Typography>
          )}
          <CopilotLibraryInput
            value={value}
            onChange={onChange}
            onEnter={onConfirm}
          />
        </>
      )}
      {(step === LibraryAddStep.found || step === LibraryAddStep.missing) && (
        <CopilotLibraryObjectStateIndicator
          label={libraryParams.object || ""}
          {...statusProps}
        />
      )}
      {step === LibraryAddStep.success && (
        <CopilotLibraryAddedSuccessIndicator />
      )}
      {step === LibraryAddStep.inputvisual && <InputVisualContent />}
      {step === LibraryAddStep.ivconfirmation && <InputVisualContent />}
    </>
  );
}
