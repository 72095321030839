import { AddBox, HighlightOff } from "@mui/icons-material";
import { Button, IconButton, InputAdornment, InputLabel } from "@mui/material";
import clsx from "clsx";
import { FastField, useField } from "formik";
import { TextField } from "formik-mui";
import { useState } from "react";

const MAX_LENGTH = 250;

export const AlertCustomInformation = ({
  className,
}: {
  className?: string;
}) => {
  const [{ value }, , { setValue }] = useField("customText");
  const [hideTextArea, setHideTextArea] = useState(!value);
  return (
    <div className={clsx("w-2/3", className)}>
      <div className="font-bold">Custom Additional Message (optional)</div>
      <div>
        Add a custom message to be included when an alert is received. This can
        be used to give instructions or provide any other important information.
      </div>
      <div className="pt-5">
        <Button
          className={clsx("p-0", { hidden: !hideTextArea })}
          color="primary"
          aria-label="open custom message text area"
          startIcon={<AddBox />}
          onClick={() => setHideTextArea(false)}
        >
          Add custom message
        </Button>
        <div className={clsx({ hidden: hideTextArea })}>
          <InputLabel htmlFor="customText" className="text-xs">
            Custom Message
          </InputLabel>
          <FastField
            component={TextField}
            id="customText"
            name="customText"
            multiline
            fullWidth
            variant="outlined"
            placeholder="Enter your message here."
            rows={4}
            className="mt-1"
            sx={{ "& .MuiOutlinedInput-root": { alignItems: "flex-start" } }}
            inputProps={{ maxLength: MAX_LENGTH }}
            InputProps={{
              className: "text-sm p-2",
              endAdornment: (
                <InputAdornment position="end" className="m-0 pt-2">
                  <IconButton
                    className="p-0"
                    aria-label="close custom message text area"
                    onClick={() => {
                      setValue("");
                      setHideTextArea(true);
                    }}
                  >
                    <HighlightOff className="h-4 w-4 text-text" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <div className="italic text-xs inline-flex w-full justify-end">
            {value?.length ?? 0}/{MAX_LENGTH} characters
          </div>
        </div>
      </div>
    </div>
  );
};
