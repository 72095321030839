import { Logger } from "@datadog/browser-logs";
import { LiveKitRoom, useTracks } from "@livekit/components-react";
import { differenceInSeconds } from "date-fns/fp";
import { useAtom, useSetAtom } from "jotai";
import { ConnectionQuality, Participant, Track } from "livekit-client";
import { nanoid } from "nanoid";
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useInterval } from "react-use";

import {
  defaultPlayerId,
  playerControlsState,
  playingIntentState,
  qualityLevelsState,
  readyState,
  useMultiPlayerBuffering,
  wallclockTimeState,
} from "@/components/Player/PlayerBase";
import ApplianceParticipantVideo from "@/components/Player/WebRTCPlayer/ApplianceParticipantVideo";
import {
  getWebRTCUrl,
  webRTCLogger,
} from "@/components/Player/WebRTCPlayer/LivekitUtils";
import { useLivekitConnection } from "@/components/Player/WebRTCPlayer/hooks/useLivekitConnection";
import useSignalingToken from "@/components/Player/WebRTCPlayer/hooks/useSignalingToken";
import {
  PlayerMachineEvent,
  usePlayerService,
  usePlaylistReachable,
} from "@/components/Player/playerMachine";
import { useSocket } from "@/components/Socket";

export interface WebRTCPlayerProps {
  url: string;
  playerId: string;
  autoPlay?: boolean;
  forceMuted?: boolean;
}

interface WebRTCStats {
  bitrate?: string;
  RTCQuality?: ConnectionQuality;
  lastPublishRestart?: Date | undefined;
}

const WebRTCDebugContext = React.createContext<boolean>(false);

export function useWebrtcDebugState() {
  return useContext(WebRTCDebugContext);
}

export default memo(function LivekitPlayer({
  url,
  playerId,
  autoPlay,
  forceMuted,
}: WebRTCPlayerProps) {
  const [rtcId] = useState(nanoid());
  const [reconnectCount, setReconnectCount] = useState(1);
  const [, applianceSerial] = /cam=([\w-]+)--([^&]+)/.exec(url) ?? [];
  const signalingServer = getWebRTCUrl(applianceSerial);
  const signalingToken = useSignalingToken({ signalingURL: url, rtcId });
  const { iceServers } = useSocket();
  const { send } = usePlayerService();

  return (
    <LiveKitRoom
      serverUrl={signalingServer}
      token={signalingToken}
      connectOptions={{
        rtcConfig: { iceServers: iceServers || undefined },
        autoSubscribe: true,
      }}
      connect
      onConnected={() => {
        console.log(`LiveKit connected signalingServer=${signalingServer}`);
      }}
      onDisconnected={() => {
        console.log(
          `Disconnected from LiveKit signalingServer=${signalingServer}`
        );
        setReconnectCount((c) => c + 1);
      }}
      onError={(e) => {
        webRTCLogger("could not establish connection", e);
        send(PlayerMachineEvent.ALL_SOURCES_FAILED);
        setReconnectCount((c) => c + 1);
      }}
    >
      <LiveKitPlayerInner
        url={url}
        playerId={playerId}
        key={reconnectCount}
        autoPlay={autoPlay}
        forceMuted={forceMuted}
        applianceSerial={applianceSerial}
        rtcId={rtcId}
      />
    </LiveKitRoom>
  );
});

function ParticipantRenderer({
  participant,
  metricsCollector,
  playerId,
  url,
  autoPlay = true,
  forceMuted = false,
}: {
  participant: Participant;
  metricsCollector?: Logger;
} & WebRTCPlayerProps) {
  const { send } = usePlayerService();
  const subscribedTracks = useTracks(
    [Track.Source.Camera, Track.Source.Microphone],
    {
      onlySubscribed: true,
    }
  );

  const [videoTrack, audioTrack] = useMemo<
    [Track | undefined, Track | undefined]
  >(() => {
    if (!subscribedTracks) {
      return [undefined, undefined];
    }
    const videoTrackRef = subscribedTracks.find(
      (t) => t.source === Track.Source.Camera
    );
    const audioTrackRef = subscribedTracks.find(
      (t) => t.source === Track.Source.Microphone
    );
    return [
      videoTrackRef?.publication?.track,
      !forceMuted ? audioTrackRef?.publication?.track : undefined,
    ];
  }, [subscribedTracks, forceMuted]);

  const [stats, setStats] = useState<WebRTCStats>();
  const debug = useWebrtcDebugState();

  const [connectionStartTime, setConnectionStartTime] = useState<number | null>(
    Date.now()
  );

  const setPlayerControls = useSetAtom(
    playerControlsState(playerId ?? defaultPlayerId)
  );

  const [clockStartTime] = useState(Date.now());

  const setWallclockTime = useSetAtom(wallclockTimeState(playerId));

  const playlistReachable = usePlaylistReachable();

  const [playerReadyState, setReadyState] = useAtom(readyState(playerId));

  const setQualityLevels = useSetAtom(qualityLevelsState);

  const multiPlayerBuffering = useMultiPlayerBuffering();

  const [playingIntent, setPlayingIntent] = useAtom(playingIntentState);

  const [playerElement, setPlayerElement] = useState<HTMLVideoElement | null>(
    null
  );
  const playerRef = useCallback(
    (node: HTMLVideoElement | null) => {
      setPlayerElement(node);
    },
    [setPlayerElement]
  );

  useEffect(() => {
    setPlayingIntent(autoPlay);
  }, [autoPlay, setPlayingIntent, url]);

  useEffect(() => {
    if (!playerElement) return;
    if (multiPlayerBuffering || !playingIntent) {
      if (!playerElement.paused) {
        if (playerReadyState >= 3) {
          playerElement.pause();
        }
      }
    } else {
      playerElement.play().catch((e) => console.log);
    }
  }, [
    multiPlayerBuffering,
    playerElement,
    playerReadyState,
    playingIntent,
    setPlayingIntent,
  ]);

  useEffect(() => {
    if (!playerElement) return;
    const player = playerElement;

    setPlayerControls((current) => ({
      ...current,
      getPlayerElement: () => playerElement!,
      setFullscreen: (fullscreen: boolean) => {
        fullscreen && player.requestFullscreen();
      },
      setVolume: (v) => {
        player.muted = v === 0;
        player.volume = v / 500;
      },
      forceQuality: (quality, callback) => callback(),
    }));

    function handlePlaying() {
      send(PlayerMachineEvent.PLAYING);
      if (connectionStartTime) {
        metricsCollector?.log("connectionTime", {
          duration: Date.now() - connectionStartTime,
        });

        setConnectionStartTime(null);
      }
    }

    player.addEventListener("playing", handlePlaying);

    function handlePause() {
      send(PlayerMachineEvent.PAUSED);
    }

    player.addEventListener("pause", handlePause);

    function handleError(e: unknown) {
      console.log(`Player error: ${e}`);
    }

    player.addEventListener("error", handleError);

    function handleLoadedMetadata() {
      setReadyState(player.readyState);
      if (player.videoHeight) {
        send({
          type: PlayerMachineEvent.SET_VISUAL_QUALITY,
          value: player.videoHeight,
        });
      }
    }

    player.addEventListener("loadedmetadata", handleLoadedMetadata);

    function updateReadyState() {
      setReadyState(player.readyState);
      // Is this redundant?
      if (player.readyState < 3) send(PlayerMachineEvent.BUFFERING);
    }

    function updateCanPlayState() {
      updateReadyState();
      send({
        type: PlayerMachineEvent.SET_VIDEO_DIMENSIONS,
        dimensions: { width: player.videoWidth, height: player.videoHeight },
      });
    }

    player.addEventListener("waiting", updateReadyState);
    player.addEventListener("progress", updateReadyState);
    player.addEventListener("canplay", updateCanPlayState);
    player.addEventListener("canplaythrough", updateReadyState);
    player.addEventListener("loadstart", updateReadyState);

    function handleTimeUpdate(this: HTMLVideoElement) {
      const playerElement = this;

      setWallclockTime(
        new Date(clockStartTime + Math.round(playerElement.currentTime * 1000))
      );
    }

    player.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      player.removeEventListener("playing", handlePlaying);
      player.removeEventListener("pause", handlePause);
      player.removeEventListener("error", handleError);
      player.removeEventListener("loadedmetadata", handleLoadedMetadata);
      player.removeEventListener("waiting", updateReadyState);
      player.removeEventListener("progress", updateReadyState);
      player.removeEventListener("canplay", updateCanPlayState);
      player.removeEventListener("canplaythrough", updateReadyState);
      player.removeEventListener("loadstart", updateReadyState);
      player.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, [
    send,
    setPlayerControls,
    playlistReachable,
    playerElement,
    setQualityLevels,
    setReadyState,
    setWallclockTime,
    url,
    clockStartTime,
    connectionStartTime,
    metricsCollector,
  ]);

  // Attach the stream to the player
  useEffect(() => {
    if (!playerElement) {
      return;
    }

    if (videoTrack) videoTrack.attach(playerElement);
    if (audioTrack) audioTrack.attach(playerElement);

    return () => {
      videoTrack?.detach(playerElement);
      audioTrack?.detach(playerElement);
    };
  }, [videoTrack, audioTrack, playerElement]);

  useInterval(async () => {
    if (localStorage.webrtcDetails === "true") {
      let totalBitRate = 0;
      participant.tracks.forEach((pub) => {
        totalBitRate += pub.track?.currentBitrate || 0;
      });
      setStats({
        bitrate: (totalBitRate / 1000).toFixed(2),
      });
    }
  }, 3000);

  return (
    <>
      <video
        ref={playerRef}
        muted={true}
        style={{
          width: "100%",
          objectFit: "cover",
        }}
      />

      {localStorage.webrtcDetails === "true" && debug && (
        <div className="absolute top-0 left-0 bg-black bg-opacity-40 text-white z-10 px-2 py-1">
          bitrate: {stats?.bitrate} kbps
          <br />
          quality: {participant?.connectionQuality}
          <br />
          publishing since:{" "}
          {differenceInSeconds(participant.joinedAt)(new Date())} sec
          <br />
          dimensions: {videoTrack?.mediaStreamTrack.getSettings().width}X
          {videoTrack?.mediaStreamTrack.getSettings().height}
          <br />
          fps:{" "}
          {videoTrack?.mediaStreamTrack.getSettings().frameRate?.toFixed(2)}
          <br />
          videoTrack: {videoTrack?.mediaStreamTrack.id}
          <br />
          audioTrack: {audioTrack?.mediaStreamTrack.id}
        </div>
      )}
    </>
  );
}

function LiveKitPlayerInner({
  url,
  playerId,
  autoPlay,
  forceMuted,
  applianceSerial,
  rtcId,
}: {
  applianceSerial: string;
  rtcId: string;
} & WebRTCPlayerProps) {
  const [showDebug, setShowDebug] = useState(false);
  const { metricsLogger } = useLivekitConnection(
    applianceSerial,
    url,
    playerId,
    rtcId
  );
  return (
    <WebRTCDebugContext.Provider value={showDebug}>
      <div
        onMouseEnter={() => setShowDebug(true)}
        onMouseLeave={() => setShowDebug(false)}
      >
        <ApplianceParticipantVideo>
          {(applianceParticipant) => (
            <ParticipantRenderer
              url={url}
              playerId={playerId}
              participant={applianceParticipant}
              metricsCollector={metricsLogger}
              autoPlay={autoPlay}
              forceMuted={forceMuted}
            />
          )}
        </ApplianceParticipantVideo>
      </div>
    </WebRTCDebugContext.Provider>
  );
}
