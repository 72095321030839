export const generateLiveShareUrl = (token: string) => {
  return `/s/camera/${token}`;
};

export const generateVodShareUrl = (token: string) => {
  return `/s/vod/${token}`;
};

export const generateCaseShareUrl = (token: string) => {
  return `/s/case/${token}`;
};

export const generateExternalLink = (path: string) => {
  return `${window.location.protocol}//${window.location.host}${path}`;
};
