export const ptzVendors = [
  "AMPAK", //Foscam
  "Axis",
  "Dahua",
  "FLIR Systems",
  "Hanwha",
  "Hikvision",
  "Hui Zhou Gaoshengda", //Reolink
  "Lorex",
  "Reolink",
  "Shenzhen Century Xinyang", //Foscam
];

export const isPTZSupported = (vendor?: string | null) => {
  if (!vendor) {
    return false;
  }

  const lowerVendor = vendor.toLowerCase();
  return ptzVendors.some((v) => lowerVendor.includes(v.toLowerCase()));
};
