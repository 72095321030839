import { SVGProps } from "react";

export function GeniusLogo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="95"
      height="26"
      viewBox="0 0 95 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.42344 14.9338C9.71464 14.1653 10.9259 11.7157 10.1289 9.46244C9.33193 7.20917 6.82845 6.00548 4.53725 6.77394C2.24605 7.54239 1.03477 9.99198 1.83177 12.2453C2.62877 14.4985 5.13225 15.7022 7.42344 14.9338Z"
        fill="#353D48"
      />
      <path
        d="M15.6597 22.5409C18.49 21.5916 19.9863 18.5656 19.0018 15.7822C18.0173 12.9987 14.9247 11.5118 12.0944 12.4611C9.26414 13.4103 7.76785 16.4363 8.75238 19.2198C9.7369 22.0032 12.8294 23.4901 15.6597 22.5409Z"
        fill="#007CE4"
      />
      <path
        d="M18.1357 10.8573C19.2139 10.4957 19.7839 9.34294 19.4088 8.28258C19.0338 7.22221 17.8557 6.65577 16.7775 7.0174C15.6993 7.37902 15.1292 8.53177 15.5043 9.59214C15.8794 10.6525 17.0575 11.2189 18.1357 10.8573Z"
        fill="#353D48"
      />
      <path
        d="M13.5326 8.12758C15.4195 7.49473 16.417 5.47742 15.7606 3.62178C15.1043 1.76615 13.0426 0.774878 11.1557 1.40772C9.26887 2.04057 8.27134 4.05788 8.92769 5.91352C9.58404 7.76915 11.6457 8.76042 13.5326 8.12758Z"
        fill="#353D48"
      />
      <path
        d="M37.249 18.46C36.6496 19.1606 35.8024 19.7058 34.7073 20.0958C33.6122 20.4786 32.3987 20.67 31.0669 20.67C29.6684 20.67 28.4401 20.3739 27.382 19.7817C26.3313 19.1822 25.5174 18.3156 24.9403 17.1817C24.3705 16.0478 24.0783 14.7153 24.0635 13.1842V12.1117C24.0635 10.5373 24.3335 9.17588 24.8737 8.02755C25.4212 6.872 26.2056 5.99089 27.2267 5.38422C28.2552 4.77034 29.4575 4.46339 30.8338 4.46339C32.7502 4.46339 34.2486 4.91117 35.3288 5.80672C36.4091 6.69505 37.0492 7.99144 37.249 9.69587H34.0081C33.8601 8.7931 33.5308 8.13227 33.0203 7.71338C32.5171 7.29449 31.8216 7.08505 30.9337 7.08505C29.8016 7.08505 28.9396 7.50033 28.3476 8.33088C27.7557 9.16143 27.456 10.3964 27.4486 12.0359V13.0434C27.4486 14.6972 27.7705 15.9467 28.4142 16.7917C29.058 17.6367 30.0014 18.0592 31.2445 18.0592C32.4949 18.0592 33.3865 17.7992 33.9193 17.2792V14.56H30.8893V12.1659H37.249V18.46Z"
        fill="#007CE4"
      />
      <path
        d="M45.2734 20.67C43.5124 20.67 42.077 20.1428 40.9671 19.0883C39.8646 18.0339 39.3133 16.6292 39.3133 14.8742V14.5709C39.3133 13.3936 39.5464 12.3428 40.0126 11.4184C40.4787 10.4867 41.1373 9.77171 41.9882 9.27338C42.8465 8.76782 43.8232 8.51505 44.9183 8.51505C46.5609 8.51505 47.8521 9.0206 48.7918 10.0317C49.7389 11.0428 50.2125 12.4764 50.2125 14.3325V15.6109H42.5653C42.6689 16.3764 42.9797 16.9903 43.4976 17.4525C44.023 17.9147 44.6852 18.1458 45.4843 18.1458C46.72 18.1458 47.6856 17.7089 48.3811 16.835L49.9572 18.5575C49.4762 19.2219 48.8251 19.7419 48.0038 20.1175C47.1825 20.4858 46.2723 20.67 45.2734 20.67ZM44.9072 11.05C44.2708 11.05 43.7529 11.2595 43.3533 11.6784C42.9612 12.0973 42.7096 12.6967 42.5986 13.4767H47.0604V13.2275C47.0456 12.5342 46.8532 11.9998 46.4832 11.6242C46.1133 11.2414 45.5879 11.05 44.9072 11.05Z"
        fill="#007CE4"
      />
      <path
        d="M54.9961 8.73171L55.096 10.0859C55.9543 9.03865 57.1049 8.51505 58.5477 8.51505C59.8204 8.51505 60.7675 8.87977 61.389 9.60921C62.0106 10.3386 62.3287 11.4292 62.3435 12.8809V20.4533H59.136V12.9567C59.136 12.2923 58.988 11.812 58.692 11.5159C58.396 11.2125 57.904 11.0609 57.2159 11.0609C56.3131 11.0609 55.6361 11.4364 55.1848 12.1875V20.4533H51.9772V8.73171H54.9961Z"
        fill="#007CE4"
      />
      <path
        d="M68.1705 20.4533H64.9518V8.73171H68.1705V20.4533ZM64.7631 5.69839C64.7631 5.22895 64.9222 4.84256 65.2403 4.53923C65.5659 4.23589 66.0062 4.08423 66.5611 4.08423C67.1087 4.08423 67.5452 4.23589 67.8708 4.53923C68.1964 4.84256 68.3591 5.22895 68.3591 5.69839C68.3591 6.17505 68.1926 6.56505 67.8597 6.86838C67.5341 7.17172 67.1013 7.32338 66.5611 7.32338C66.021 7.32338 65.5844 7.17172 65.2514 6.86838C64.9259 6.56505 64.7631 6.17505 64.7631 5.69839Z"
        fill="#007CE4"
      />
      <path
        d="M78.0152 19.2617C77.2234 20.2006 76.1284 20.67 74.7299 20.67C73.4424 20.67 72.4583 20.3089 71.7776 19.5867C71.1043 18.8644 70.7602 17.8064 70.7454 16.4125V8.73171H73.953V16.3042C73.953 17.5247 74.5227 18.135 75.6622 18.135C76.7499 18.135 77.4972 17.7667 77.9042 17.03V8.73171H81.1229V20.4533H78.104L78.0152 19.2617Z"
        fill="#007CE4"
      />
      <path
        d="M90.0464 17.2142C90.0464 16.8314 89.8503 16.5317 89.4581 16.315C89.0734 16.0911 88.4518 15.8925 87.5935 15.7192C84.7374 15.1342 83.3093 13.9497 83.3093 12.1659C83.3093 11.1259 83.7496 10.2592 84.6301 9.56587C85.518 8.86532 86.676 8.51505 88.104 8.51505C89.6283 8.51505 90.8455 8.86532 91.7556 9.56587C92.6731 10.2664 93.1319 11.1764 93.1319 12.2959H89.9243C89.9243 11.8481 89.7763 11.4798 89.4803 11.1909C89.1843 10.8948 88.7219 10.7467 88.093 10.7467C87.5528 10.7467 87.1348 10.8659 86.8388 11.1042C86.5428 11.3425 86.3948 11.6459 86.3948 12.0142C86.3948 12.3609 86.5613 12.6425 86.8943 12.8592C87.2346 13.0686 87.8044 13.2528 88.6035 13.4117C89.4026 13.5634 90.076 13.7367 90.6235 13.9317C92.3179 14.5384 93.1651 15.5892 93.1651 17.0842C93.1651 18.1531 92.6953 19.0197 91.7556 19.6842C90.8159 20.3414 89.6024 20.67 88.1152 20.67C87.1089 20.67 86.2135 20.4967 85.4292 20.15C84.6523 19.7961 84.0419 19.3158 83.5979 18.7092C83.1539 18.0953 82.932 17.4345 82.932 16.7267H85.9731C86.0027 17.2828 86.2135 17.7089 86.6057 18.005C86.9979 18.3011 87.5232 18.4492 88.1817 18.4492C88.7959 18.4492 89.2583 18.3372 89.5691 18.1133C89.8873 17.8822 90.0464 17.5825 90.0464 17.2142Z"
        fill="#007CE4"
      />
    </svg>
  );
}
