import {
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";

interface IntegrationProviderProps {
  children: ReactNode;
}

const IntegrationContext = createContext({
  eventCount: 0,
  setEventCount: (() => {}) as React.Dispatch<SetStateAction<number>>,
});

export const IntegrationProvider = ({ children }: IntegrationProviderProps) => {
  const [eventCount, setEventCount] = useState<number>(0);

  return (
    <IntegrationContext.Provider value={{ eventCount, setEventCount }}>
      {children}
    </IntegrationContext.Provider>
  );
};

export const useIntegrationContext = () => {
  const context = useContext(IntegrationContext);

  if (!context) {
    throw new Error(
      "useIntegrationContext must be used within a IntegrationProvider"
    );
  }

  return context;
};
