import clsx from "clsx";
import React, { PropsWithChildren, useEffect, useState } from "react";

import { useCompletedState } from "@/components/Player/PlayerBase";

export function CompletedOverlay({ children }: PropsWithChildren<{}>) {
  const shown = useCompletedState();
  const [pointerEventsEnabled, setPointerEventsEnabled] = useState(false);
  useEffect(() => {
    if (shown) {
      const timer = setTimeout(() => setPointerEventsEnabled(true), 500);
      return () => clearTimeout(timer);
    } else {
      setPointerEventsEnabled(false);
    }
  }, [shown]);
  return (
    <div
      // Need to add both listeners to we can stop propagation to the root player, which
      // is required because the root player will show on click/touchStart.
      className={clsx(
        "absolute w-full h-full text-white flex-center transition-opacity bg-white bg-opacity-10",
        shown ? "" : "opacity-0",
        { "pointer-events-none": !pointerEventsEnabled }
      )}
    >
      {children}
    </div>
  );
}
