import clsx from "clsx";
import { ReactNode } from "react";

export function Statistic({
  className,
  flag,
  inverted,
  error,
  children,
}: {
  className?: string;
  flag?: boolean;
  inverted?: boolean;
  error?: boolean;
  children?: ReactNode;
}) {
  const colors = {
    "bg-[#F44336]": error,
    "bg-[#18D710]": !error,
  };

  const flagElement = (
    <div className="w-3 overflow-hidden inline-block">
      <div
        className={clsx("h-2 w-2 transform", {
          ...colors,
          "rotate-45 origin-bottom-left": !inverted,
          "-rotate-45 origin-top-left": inverted,
        })}
      ></div>
    </div>
  );

  return (
    <div
      className={clsx(
        "flex flex-col items-center animate-scale-up-center",
        className
      )}
    >
      {flag && !inverted && flagElement}
      <div
        className={clsx(
          colors,
          "rounded text-white text-sm leading-[14px] px-2 py-1 font-bold"
        )}
      >
        {children}
      </div>
      {flag && inverted && flagElement}
    </div>
  );
}
