import { Tooltip } from "@mui/material";
import clsx from "clsx";
import { motion } from "framer-motion";
import { CSSProperties, useState } from "react";
import { useLocalStorage } from "react-use";

import { idToFilterDebugConfigMap } from "@/components/Ai/debugConfig";

import {
  idToFilterConfigMap,
  intelligentFiltersConfig,
} from "./intelligence/intelligence";

const TOP_BOX_PX_SIZE = 14;
const BOUNDING_BOX_BORDER_WIDTH_PX = 3;

interface ActivityBoundingBoxProps {
  id: string;
  xmin: number;
  ymin: number;
  xmax: number;
  ymax: number;
  localObjId?: string | null;
  vx?: number | null;
  vy?: number | null;
  score?: number | null;
  objTypeId: string;
  description: string | null;
  onClick?: () => void;
  additionalStyles?: CSSProperties;
  additionalClasses?: string;
  selected?: boolean;
}

interface BoundingBoxEdgeProps {
  className?: string;
  path: string;
}

function getTransition(duration: number) {
  return {
    transition: {
      ease: "easeInOut",
      duration: duration,
    },
  };
}

function BoundingBoxEdge({ className, path }: BoundingBoxEdgeProps) {
  return (
    <motion.svg
      className={className}
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d={path}
        fill="currentColor"
      />
    </motion.svg>
  );
}

const unit = "12px";
const calcMax = `calc(100% - ${unit})`;
const bbClipPath = `polygon(0% ${unit}, ${unit} ${unit}, ${unit} 0%, ${calcMax} 0%, ${calcMax} ${unit}, 100% ${unit}, 100% ${calcMax}, ${calcMax} ${calcMax}, ${calcMax} 100%, ${unit} 100%, ${unit} ${calcMax}, 0% ${calcMax})`;

const BB_COLOR = "#FF00FF";
const getColor = (opacity = 1) => `rgba(255, 0, 255, ${opacity})`;

export function ActivityBoundingBox({
  id,
  xmin,
  ymin,
  xmax,
  ymax,
  localObjId,
  vx,
  vy,
  score,
  objTypeId,
  description,
  onClick = () => {},
  additionalStyles,
  additionalClasses,
  selected,
}: ActivityBoundingBoxProps) {
  const [hidden, setHidden] = useState(false);
  const [showAiDebug] = useLocalStorage("showAiDebug", false);

  const { objectConfidenceThreshold, objectIds } = showAiDebug
    ? idToFilterDebugConfigMap[objTypeId]
    : idToFilterConfigMap[objTypeId];

  const sharedVariants = selected
    ? ["animate", "move", "selected"]
    : ["animate", "move"];

  if (hidden) return <></>;

  return (
    <motion.div
      key={`bb-${id}`}
      onClick={onClick}
      className={clsx(
        "absolute group flex flex-col items-center justify-center rounded-[10px] transition duration-200 ease-in-out",
        additionalClasses,
        {
          "opacity-0":
            objectConfidenceThreshold[objectIds.indexOf(objTypeId)] >
            (score || 1),
        }
      )}
      initial={["initial", "move"]}
      exit={["exit", "move"]}
      animate={sharedVariants}
      whileHover={["hover", ...sharedVariants]}
      variants={{
        initial: {
          scale: 1.05,
          opacity: 0,
          background:
            "radial-gradient(circle at 50% 50%, rgba(255, 255, 0, 0) 0%, rgba(255, 0, 255, 0.15) 25%)",
        },
        animate: {
          scale: 1,
          opacity: 1,
          background:
            "radial-gradient(circle at 50% 50%, rgba(255, 255, 0, 0) 100%, rgba(255, 0, 255, 0.15) 100%)",
          ...getTransition(0.15),
        },
        exit: {
          opacity: 0,
          ...getTransition(0.8),
        },
        hover: {
          outline: `8px solid ${getColor(0.33)}`,
          ...getTransition(0.1),
        },
        selected: {
          outline: `8px solid ${getColor(0.33)}`,
          ...getTransition(0.1),
        },
        move: {
          top: `${(ymin * 100).toFixed(2)}%`,
          bottom: `${((1 - ymax) * 100).toFixed(2)}%`,
          left: `${(xmin * 100).toFixed(2)}%`,
          right: `${((1 - xmax) * 100).toFixed(2)}%`,
          transition: {
            ease: "linear",
            duration: 0.3,
          },
        },
      }}
      style={{
        fontSize: 10,
        color: "transparent",
        lineHeight: 1,
        ...additionalStyles,
      }}
    >
      <motion.div
        key={`container-${id}`}
        variants={{
          initial: {
            borderColor: BB_COLOR,
          },
          selected: {
            borderWidth: 3,
            ...getTransition(0.1),
          },
        }}
        {...getTransition(0.25)}
        className="absolute w-full h-full text-transparent rounded-[10px] border-solid opacity-95 group"
        style={{
          borderWidth: 1,
          clipPath: bbClipPath,
          ...additionalStyles,
        }}
      />
      {
        <RemoveBoundingBoxButton
          onClick={() => {
            setHidden(true);
          }}
        />
      }
      <motion.div
        className="absolute flex items-center justify-between w-full h-full opacity-95"
        variants={{
          initial: {
            color: BB_COLOR,
          },
          hover: {
            color: BB_COLOR,
          },
        }}
      >
        <div className="absolute flex flex-col items-start justify-between w-full h-full">
          <BoundingBoxEdge path="M3 10V9C3 5.68629 5.68629 3 9 3H10V0H9C4.02944 0 0 4.02943 0 9V10H3Z" />

          <BoundingBoxEdge path="M3 0V1C3 4.31371 5.68629 7 9 7H10V10H9C4.02944 10 0 5.97057 0 1V0H3Z" />
        </div>

        <div className="absolute flex flex-col items-end justify-between w-full h-full">
          <BoundingBoxEdge path="M-1.31134e-07 3L1 3C4.31371 3 7 5.68629 7 9.00001L7 10.0009L10 10.0009L10 9.00001C10 4.02944 5.97057 2.60982e-07 1 4.37114e-08L0 0L-1.31134e-07 3Z" />

          <BoundingBoxEdge path="M-1.31134e-07 7L1 7C4.31371 7 7 4.31371 7 0.999994L7 0.000151328L10 0.000151197L10 0.999994C10 5.97056 5.97057 10 1 10L0 10L-1.31134e-07 7Z" />
        </div>
      </motion.div>

      {showAiDebug && (
        <div
          className="absolute text-white flex items-center justify-center font-mono rounded-lg px-1 border-solid border"
          style={{
            left: `-${
              ymin < 0.02 ? TOP_BOX_PX_SIZE : BOUNDING_BOX_BORDER_WIDTH_PX
            }px`,
            top: `-${
              ymin < 0.02 ? BOUNDING_BOX_BORDER_WIDTH_PX : TOP_BOX_PX_SIZE + 2
            }px`,
            height: TOP_BOX_PX_SIZE,
            borderColor: getColor(),
            background: getColor(0.8),
          }}
        >
          {!description && score && <>{(score * 100)?.toFixed(2)}%</>}{" "}
          {description && <> {description}</>}{" "}
          {(Math.abs(vx || 0) > 0.001 || Math.abs(vy || 0) > 0.001) && (
            <>
              <div className="w-1" />
              {intelligentFiltersConfig["motion"].icon}
            </>
          )}
        </div>
      )}
    </motion.div>
  );
}

function RemoveBoundingBoxButton({ onClick }: { onClick: () => void }) {
  return (
    <Tooltip title="Hide bounding box">
      <button
        type="button"
        className="bg-transparent absolute top-0 ml-[calc(100%-16px)] z-1 opacity-0 group-hover:opacity-100 transition duration-200 ease-in-out hover:scale-125"
        onClick={onClick}
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="8" cy="8" r="8" fill="#CF2AD9" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.00059 13.0669C10.7988 13.0669 13.0673 10.7985 13.0673 8.00028C13.0673 5.20204 10.7988 2.93361 8.00059 2.93361C5.20234 2.93361 2.93392 5.20204 2.93392 8.00028C2.93392 10.7985 5.20234 13.0669 8.00059 13.0669ZM8.00059 14.4003C11.5352 14.4003 14.4006 11.5349 14.4006 8.00028C14.4006 4.46566 11.5352 1.60028 8.00059 1.60028C4.46596 1.60028 1.60059 4.46566 1.60059 8.00028C1.60059 11.5349 4.46596 14.4003 8.00059 14.4003Z"
            fill="white"
          />
          <rect
            x="5.40625"
            y="6.35065"
            width="1.33333"
            height="5.99959"
            transform="rotate(-45 5.40625 6.35065)"
            fill="white"
          />
          <rect
            x="5.40723"
            y="9.6496"
            width="5.99837"
            height="1.33333"
            transform="rotate(-45 5.40723 9.6496)"
            fill="white"
          />
        </svg>
      </button>
    </Tooltip>
  );
}
