import { IntegrationEventTypeV2 } from "@/generated-models";

import {
  IntegrationFormEventChildren,
  IntegrationFormEventPropertyField,
  IntegrationSchemaType,
  NestedIntegrationSchemaTypes,
  getSchemaPropertyNestedField,
} from "./utils";

type JsonSchema = any;

function getResolvedSchemaType(type: IntegrationSchemaType) {
  switch (type) {
    case IntegrationSchemaType.datetime:
      return {
        type: IntegrationSchemaType.string,
        format: IntegrationSchemaType.datetime,
      };
    default:
      return {
        type,
      };
  }
}

function getInvertedSchemaType(type: IntegrationSchemaType, format: string) {
  if (
    type === IntegrationSchemaType.string &&
    format === IntegrationSchemaType.datetime
  )
    return IntegrationSchemaType.datetime;
  return type as IntegrationSchemaType;
}

function generateFormSchema(schema: IntegrationEventTypeV2["schema"]) {
  const result: (IntegrationFormEventPropertyField &
    IntegrationFormEventChildren)[] = [];

  Object.entries(schema?.items?.properties || schema?.properties || []).forEach(
    ([name, config]) => {
      const { type, format, description } = config as {
        type: IntegrationSchemaType;
        format: string;
        description: string;
      };

      const item = {
        name,
        type: getInvertedSchemaType(type, format),
        required: schema?.required?.includes(name),
        description,
        children: NestedIntegrationSchemaTypes.includes(type)
          ? generateFormSchema(config)
          : [],
      };

      result.push(item);
    }
  );
  return result;
}

function generateJsonSchema(
  properties: (IntegrationFormEventPropertyField &
    IntegrationFormEventChildren)[],
  nested: boolean = false
) {
  const required: string[] = [];

  const schemaProperties = properties.reduce((acc, property) => {
    const { name, type, required: req, children, description } = property;

    if (req) {
      required.push(name);
    }

    const result = {
      ...acc,
      [name]: {
        ...getResolvedSchemaType(type),
        description,
      },
    } as JsonSchema;

    if (children && children?.length > 0) {
      const nestedSchema = generateJsonSchema(children, true);
      if (type === IntegrationSchemaType.object) {
        result[name][getSchemaPropertyNestedField(type)] =
          nestedSchema.properties;
        result[name].required = nestedSchema.required;
      }

      if (type === IntegrationSchemaType.array_of_objects) {
        result[name][getSchemaPropertyNestedField(type)] = {
          type: IntegrationSchemaType.object,
          properties: nestedSchema.properties,
          required: nestedSchema.required,
        };
      }

      result[name].description = description;
      result[name].additionalProperties = false;
    }

    return result;
  }, {}) as JsonSchema;

  if (nested) {
    return {
      properties: schemaProperties,
      required,
    };
  }

  return {
    $schema: "http://json-schema.org/draft-07/schema#",
    type: "object",
    additionalProperties: false,
    properties: schemaProperties,
    required,
  };
}

export { generateJsonSchema, generateFormSchema };
