import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Button, Fade, Link } from "@mui/material";
import clsx from "clsx";
import { motion } from "framer-motion";
import { ReactNode, useState } from "react";
import { BooleanParam, useQueryParam } from "use-query-params";

import { CopilotLogo } from "@/icons/CopilotIcon";

import { useLocalStorage } from "@/util/useLocalStorage";

import { CopilotNavigationButton } from "../CopilotNavigationButton";
import { useCopilotEnabled } from "../copilotQueryHooks";
import { CustomObjectIllustration } from "./CustomObjectIllustration";
import { ObjectIllustration } from "./ObjectIllustration";
import { WorkflowsIllustration } from "./WorkflowsIllustration";

const baseAnimation = {
  initial: { opacity: 0, translateX: -10 },
  animate: { opacity: 1, translateX: 0 },
  transition: {
    opacity: { ease: "easeInOut" },
    layout: { duration: 0.2 },
  },
};

export function useCopilotWelcomeModalOpen() {
  const [open, setPersistedOpen] = useLocalStorage("copilotWelcomeModal", true);
  const [openParam, setOpen] = useQueryParam(
    "copilotWelcomeModal",
    BooleanParam
  );

  return { open: open || openParam, setPersistedOpen, setOpen };
}

export function CopilotWelcomeModal() {
  const { copilotEnabled } = useCopilotEnabled();
  const { open, setOpen, setPersistedOpen } = useCopilotWelcomeModalOpen();
  const [step, setStep] = useState(0);

  function closeModal() {
    setPersistedOpen(false);
    setOpen(undefined);
    setStep(0);
  }

  const STEPS: Record<number, ReactNode> = {
    0: <StepOne />,
    1: <StepTwo />,
    2: <StepThree />,
    3: <StepFour onStart={closeModal} />,
  };

  const totalSteps = Object.keys(STEPS).length;
  const isLastStep = step + 1 >= totalSteps;

  if (!open || !copilotEnabled) return <></>;

  return (
    <div className="absolute w-full h-full bg-black/50 z-50 pointer-events-auto">
      <Fade in={open}>
        <div
          className="flex flex-col justify-center items-center w-full h-full"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="p-5 rounded-3xl bg-black bg-opacity-[.56] shadow-[0_30px_29px_0_rgba(0,0,0,0.63)] flex items-center gap-2 w-[584px] max-h-[560px]">
            <div
              className="rounded-2xl border border-solid border-[#242424] bg-[#131313] p-4 h-full w-full bg-cover"
              style={{
                backgroundImage: "url(/intelligence/copilot/welcome-bg.svg)",
              }}
            >
              <div className="flex flex-col h-full">
                <div className="flex items-center justify-between">
                  <motion.div
                    initial={{
                      opacity: 0,
                    }}
                    animate={{
                      opacity: step === 0 ? 0 : 1,
                    }}
                    transition={{ duration: 0.5 }}
                  >
                    <CopilotLogo
                      style={{
                        filter:
                          "drop-shadow(0px 0px 7.475409507751465px rgba(255, 255, 255, 0.69))",
                      }}
                    />
                  </motion.div>
                  <motion.button
                    type="button"
                    className="bg-transparent"
                    whileHover={{
                      opacity: 1,
                    }}
                    initial={{
                      opacity: 0,
                      scale: 0.6,
                    }}
                    animate={{
                      opacity: 0.5,
                      scale: 1,
                    }}
                    onClick={closeModal}
                  >
                    <CloseRoundedIcon className="text-white w-5 h-5" />
                  </motion.button>
                </div>
                <div className="flex items-start justify-between w-full h-full">
                  <div className="flex items-center justify-center h-full">
                    <CopilotNavigationButton
                      stroke="stroke-[#500556]"
                      fill="fill-[#CE46D9]"
                      className={clsx({ "!opacity-0": step === 0 })}
                      disabled={step === 0}
                      onClick={() => {
                        setStep((s) => s - 1);
                      }}
                    />
                  </div>
                  <div className="px-[15px] max-w-[440px]">
                    <div>{STEPS[step]}</div>
                  </div>
                  <div className="flex items-center justify-center h-full">
                    <CopilotNavigationButton
                      flip
                      stroke="stroke-[#500556]"
                      fill="fill-[#CE46D9]"
                      className={clsx({ "!opacity-0": isLastStep })}
                      disabled={isLastStep}
                      onClick={() => {
                        setStep((s) => s + 1);
                      }}
                    />
                  </div>
                </div>
              </div>
              {!isLastStep && (
                <div className="flex items-center justify-center gap-[6px] py-1">
                  {Array(totalSteps)
                    .fill(null)
                    .map((_, idx) => (
                      <CopilotWelcomeStepButton
                        key={idx}
                        idx={idx}
                        step={step}
                        onChange={() => {
                          setStep(idx);
                        }}
                      />
                    ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
}

function CopilotWelcomeStepButton({
  idx,
  step,
  onChange,
}: {
  idx: number;
  step: number;
  onChange: () => void;
}) {
  return (
    <button
      type="button"
      onClick={onChange}
      className={clsx("bg-transparent", {
        "opacity-100 pointer-events-none": step === idx,
        "opacity-20": step !== idx,
      })}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="6"
        height="6"
        viewBox="0 0 6 6"
        fill="none"
      >
        <circle cx="3" cy="3" r="3" fill="white" />
      </svg>
    </button>
  );
}

function KBLink({ article }: { article: string }) {
  return (
    <Link
      href={`https://help.spot.ai/hc/en-us/articles/${article}`}
      target="_blank"
      rel="noopener noreferrer"
      underline="hover"
      className="text-[12px] leadning-normal font-barlow font-normal"
    >
      Learn more
      <OpenInNewIcon className="w-3 h-3 ml-1" />
    </Link>
  );
}

function StepOne() {
  return (
    <>
      <div className="-mt-6 w-full flex flex-col items-center justify-center">
        <motion.div
          initial={{
            opacity: 0,
            scale: 0.5,
          }}
          animate={{
            opacity: 1,
            scale: 1,
          }}
          transition={{ duration: 0.5 }}
        >
          <CopilotLogo
            style={{
              width: 286,
              height: 78,
              filter:
                "drop-shadow(0px 0px 7.475409507751465px rgba(255, 255, 255, 0.69))",
            }}
          />
        </motion.div>
      </div>
      <div className="flex flex-col gap-4 mt-3">
        <CopilotWelcomeHeader>Welcome to your own AI</CopilotWelcomeHeader>
        <CopilotWelcomeText>
          AI Copilot is your AI assistant for cameras, working with you to
          revamp the security, safety, and efficiency of your business
        </CopilotWelcomeText>
        <CopilotWelcomeText>
          AI Copilot immerses itself in your physical environment. It actively
          indexes it, getting a holistic understanding of your space and
          enabling workflows directly on your video.
        </CopilotWelcomeText>
        <CopilotWelcomeText>
          The more you use AI Copilot, the smarter it gets.
        </CopilotWelcomeText>
      </div>
    </>
  );
}

function StepTwo() {
  return (
    <>
      <div className="flex flex-col gap-4 mt-5">
        <CopilotWelcomeHeader>
          Understand everything
          <KBLink article="20853166445965" />
        </CopilotWelcomeHeader>
        <CopilotWelcomeText>
          AI Copilot understands entire scenes, enabling new use cases tailored
          to your business. It will show you everything it understands: a map of
          all objects in the scene.
        </CopilotWelcomeText>
        <ObjectIllustration />
        <CopilotWelcomeCaption>
          Hover over objects to explore the scene.
        </CopilotWelcomeCaption>
      </div>
    </>
  );
}

function StepThree() {
  return (
    <>
      <div className="flex flex-col gap-4 mt-5">
        <CopilotWelcomeHeader>
          Point and click workflows <KBLink article="20853188562189" />
        </CopilotWelcomeHeader>
        <CopilotWelcomeText>
          AI Copilot empowers anyone in your organization to seamlessly initiate
          actions or derive business insights with a simple click.
        </CopilotWelcomeText>
        <div className="relative pb-12">
          <WorkflowsIllustration />
          <CopilotWelcomeCaption className="absolute -mt-4 text-sm">
            Click on labels to reveal the action menu where you can search,
            create alerts, view and create intelligence insights and more.
          </CopilotWelcomeCaption>
        </div>
      </div>
    </>
  );
}

function StepFour({ onStart }: { onStart: () => void }) {
  return (
    <>
      <div className="flex flex-col gap-4 mt-5">
        <CopilotWelcomeHeader>
          Coach your AI
          <KBLink article="20971804447373" />
        </CopilotWelcomeHeader>
        <CopilotWelcomeText>
          AI Copilot will evolve with every piece of feedback you provide,
          refining itself to better cater to your distinct business challenges.
        </CopilotWelcomeText>
        <div className="relative pb-8">
          <div className="flex items-center justify-center">
            <CustomObjectIllustration />
          </div>
          <CopilotWelcomeCaption className="absolute -mt-[94px] text-sm">
            The more you use AI Copilot, the smarter it gets. Click the object
            library to see what AI Copilot understands, and show/hide objects.
            Click on labels to provide AI Copilot feedback on results.
          </CopilotWelcomeCaption>
          <div className="py-[10px]" />
          <Button
            onClick={onStart}
            variant="outlined"
            className="rounded-lg h-[54px] border-2 border-solid border-white/[.12] font-barlow font-bold text-white w-full px-6 py-4 -mt-[24px] absolute hover:bg-opacity-80"
            style={{
              background:
                "linear-gradient(135deg, #A900E4 -3.16%, #007CE4 117.76%)",
            }}
          >
            Get Started
          </Button>
        </div>
      </div>
    </>
  );
}

function CopilotWelcomeHeader({ children }: { children: ReactNode }) {
  return (
    <motion.div
      className="text-white font-bold font-barlow text-2xl leading-normal flex items-center justify-between"
      {...baseAnimation}
    >
      {children}
    </motion.div>
  );
}

function CopilotWelcomeText({ children }: { children: ReactNode }) {
  return (
    <motion.div
      className="text-white font-barlow text-sm leading-normal"
      {...baseAnimation}
    >
      {children}
    </motion.div>
  );
}

function CopilotWelcomeCaption({
  className,
  children,
}: {
  className?: string;
  children: ReactNode;
}) {
  return (
    <motion.div
      className={clsx(
        "w-full rounded-lg bg-black/80 px-[10px] pt-2 pb-[10px] flex items-center justify-center text-[#E6B0FF] font-barlow text-base leading-normal -mt-2 shadow-[0_4px_10px_0_rgba(0,0,0,0.41)]",
        className
      )}
      {...baseAnimation}
    >
      {children}
    </motion.div>
  );
}
