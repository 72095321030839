import { useEffect, useState } from "react";

export type ImgProps = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>;
/**
 * Allows "smoothly" updating the `src` prop of an `<img>`. When updating the `src`, it will
 * load the new image in the background and swap to the new `src` once loading has completed.
 *
 * This prevents flashes when resetting an image src which is particularly useful when images
 * are tagged with a GCS token which will updated when issuing a new GQL query.
 */
export function UpdatableImage({
  src,
  ...imgProps
}: { src?: string } & ImgProps) {
  const [currentSrc, setCurrentSrc] = useState(null as string | null);
  useEffect(() => {
    if (!src) {
      setCurrentSrc(null);
      return;
    }
    const img = new Image();
    img.onload = function handleImageLoaded() {
      setCurrentSrc(src);
    };
    img.onerror = function handleImageError() {
      setCurrentSrc(null);
    };
    img.src = src;
  }, [src, setCurrentSrc]);

  // eslint-disable-next-line
  return <img {...imgProps} src={currentSrc ?? "/no-still.svg"} />;
}
