import EventRepeatOutlinedIcon from "@mui/icons-material/EventRepeatOutlined";
import { IconButton, SvgIconProps, Typography } from "@mui/material";
import clsx from "clsx";
import startCase from "lodash/startCase";
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { StringParam, useQueryParam, withDefault } from "use-query-params";

import { useBreakpoints } from "@/util/useBreakpoints";

import { SettingsIcon } from "@/components/Player/PlayerIcons";

import { IntegrationTypeKey, useIntegrationQuery } from "@/generated-models";
import { usePermissions } from "@/hooks/usePermissions";

import IntegrationsCard from "../Core/IntegrationsCard";
import IntegrationsNavigationHeader from "../Core/IntegrationsNavigationHeader";
import {
  useCurrentIntegrationId,
  useCurrentVendor,
  useCurrentTypeId,
  useIntegrationTypeIcon,
} from "../hooks";
import { getLabels } from "../labels";
import { IntegrationDetailsDevicesPage } from "./IntegrationDetailsDevices/IntegrationDetailsDevicesPage";
import { IntegrationDetailsEventPage } from "./IntegrationDetailsEvent/IntegrationDetailsEventPage";

enum TabType {
  DEVICES = "devices",
  EVENTS = "events",
}

const TabParam = withDefault(StringParam, TabType.DEVICES);

interface IntegrationDetailsPageTabProps {
  tab: string;
  label: string;
  Icon: FunctionComponent<SvgIconProps<"svg", {}>>;
}

function IntegrationDetailsPageTab({
  tab,
  label,
  Icon,
}: IntegrationDetailsPageTabProps) {
  const { fitsDesktop } = useBreakpoints();
  const [activeTab, setActiveTab] = useQueryParam("tab", TabParam);

  const active = tab === activeTab;
  return (
    <button
      disabled={active}
      onClick={() => {
        setActiveTab(tab);
      }}
      type="button"
      className={clsx(
        "md:rounded-t-2xl bg-transparent md:bg-white flex items-center justify-start border-b-2 border-solid md:border-b-0",
        {
          "md:py-4 md:px-7 gap-[7px] md:gap-[10px] border-primary": active,
          "md:py-4 md:px-5 text-primary md:bg-opacity-50 gap-[7px] border-transparent": !active,
        }
      )}
    >
      <Icon style={{ fontSize: fitsDesktop ? (active ? 28 : 19) : 24 }} />
      <Typography
        className={clsx({
          "text-lg leading-[21px] md:text-2xl md:leading-[28px] font-bold": active,
          "text-lg leading-[21px] md:text-lg md:leading-[21px]": !active,
        })}
      >
        {label}
      </Typography>
    </button>
  );
}

function IntegrationDetailsPageHeader() {
  const hasPermission = usePermissions();
  const hasManagePermission = hasPermission("integrations_manage");

  const { fitsDesktop } = useBreakpoints();

  const type = useCurrentTypeId();
  const vendor = useCurrentVendor();
  const TypeIcon = useIntegrationTypeIcon();
  const typeId = useCurrentTypeId();
  const id = useCurrentIntegrationId();
  const { data } = useIntegrationQuery({
    variables: {
      input: { id },
    },
    skip: !id,
  });

  const integration = data?.integration;
  const labels = getLabels(typeId);

  return (
    <div className="flex flex-col gap-3 mt-0 md:mt-2 md:bg-transparent bg-white">
      <div className="relative md:mt-0 mt-8">
        <div className="flex items-center justify-start gap-2">
          <IntegrationsNavigationHeader
            to=".."
            title={integration?.name || "Loading..."}
            className="!md:gap-3 !gap-1"
            textClassName={clsx({ "text-2xl leading-[28px]": !fitsDesktop })}
            subText={
              type === IntegrationTypeKey.IoBoard
                ? "I/O Board"
                : startCase(type)
            }
          />
          {hasManagePermission && (
            <IconButton component={Link} to="./edit">
              <SettingsIcon className="text-[#353D48]" />
            </IconButton>
          )}
          {fitsDesktop && vendor && (
            <div className="ml-auto">
              <img
                className="h-4 md:h-9 md:mr-0 mr-4"
                alt={vendor.name}
                src={vendor.logoSrc}
              />
            </div>
          )}
        </div>
      </div>

      <div className="flex items-center md:items-end justify-start gap-7 md:gap-2 md:pb-0 pb-2 md:px-0 px-5 sm:bg-transparent bg-white">
        <IntegrationDetailsPageTab
          tab={TabType.DEVICES}
          label={`${labels.prefix}${labels.prefix ? " " : ""}${labels.devices}`}
          Icon={TypeIcon}
        />
        {type !== IntegrationTypeKey.Intercom && (
          <IntegrationDetailsPageTab
            tab={TabType.EVENTS}
            label={`${labels.prefix}${labels.prefix ? " " : ""}${
              labels.events
            }`}
            Icon={EventRepeatOutlinedIcon}
          />
        )}
      </div>

      {!fitsDesktop && (
        <div
          className="h-2.5 opacity-[.10] relative w-full -my-5"
          style={{
            background:
              "linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%)",
          }}
        ></div>
      )}
    </div>
  );
}

export function IntegrationsDetailsPage() {
  const type = useCurrentTypeId();
  const { fitsDesktop } = useBreakpoints();
  let [activeTab] = useQueryParam("tab", TabParam);
  if (type === IntegrationTypeKey.Intercom) {
    activeTab = TabType.DEVICES;
  }

  const content = (
    <>
      {activeTab === TabType.DEVICES && <IntegrationDetailsDevicesPage />}
      {activeTab === TabType.EVENTS && <IntegrationDetailsEventPage />}
    </>
  );

  return (
    <>
      <IntegrationDetailsPageHeader />
      {fitsDesktop ? (
        <IntegrationsCard className="md:rounded-tl-none md:rounded-2xl rounded-none">
          {content}
        </IntegrationsCard>
      ) : (
        <div className="px-4 py-2 mt-4">{content}</div>
      )}
    </>
  );
}
