import { Divider, Grid, Popover, Tooltip } from "@mui/material";
import {
  addSeconds,
  differenceInDays,
  differenceInMilliseconds,
  differenceInSeconds,
  format,
  formatDistanceStrict,
  fromUnixTime,
} from "date-fns/fp";
import { Fragment, useEffect, useState } from "react";

import { useBreakpoints } from "@/util/useBreakpoints";

import { useTimeRangeParams } from "@/pages/Maintain/hooks";

import { ActivityTypes } from "@/generated-models";

interface Bound {
  left: number;
  right: number;
  timelineUnitDist: number;
}

const timelineElementClass = "z-1 bg-[#efefef] p-0 absolute h-[5px]";

export function MaintainDetailsTimeline({
  now,
  connectivityData,
  cameraActivity,
}: {
  now: Date;
  connectivityData: any;
  cameraActivity: any;
}) {
  const [popoverContent, setPopoverContent] = useState("");
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    content: string
  ) => {
    setAnchorEl(event.currentTarget);
    setPopoverContent(content);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const { fromQuery: from, toQuery: to, setDatePicker } = useTimeRangeParams(
    now
  );
  const { fitsTablet } = useBreakpoints();
  function getBound(): Bound {
    const boundLeft = from.getTime();
    const boundRight = to.getTime();

    return {
      left: boundLeft,
      right: boundRight,
      timelineUnitDist: (boundRight - boundLeft) / 100,
    };
  }
  function getTimelineLabels(): Array<string> {
    const lalbelCount = differenceInDays(from)(to) > 1 ? 7 : 5;
    // Interestingly, Array.map doesn't work on an array of undefined pointers
    // https://stackoverflow.com/questions/5501581/javascript-new-arrayn-and-array-prototype-map-weirdness
    const labels = new Array<string>(lalbelCount).fill("label");
    const labelDateDisplayFormat =
      differenceInDays(from)(to) > 1 ? "E - LLL d" : "h:mmaaaa - LLL d";
    const step = Math.floor(differenceInSeconds(from)(to) / labels.length);
    return labels.map((_, i) => {
      return format(labelDateDisplayFormat)(
        addSeconds(i * step)(fromUnixTime(from.getTime() / 1000))
      );
    });
  }
  function calculateTotalDisconnectionTime(): number {
    const missingSegmentLength = connectivityData.camera.missingSegments.reduce(
      (
        acc: number,
        currentSegment: { start: string; end: string; duration: number }
      ) => {
        if (
          +currentSegment.start + currentSegment.duration > from.getTime() &&
          +currentSegment.start < to.getTime()
        ) {
          acc +=
            Math.min(
              +currentSegment.start + currentSegment.duration,
              to.getTime()
            ) -
            from.getTime() -
            Math.max(+currentSegment.start - from.getTime(), 0);
        }
        return acc;
      },
      0
    );

    const missingBoundLength =
      Math.max(
        to.getTime() -
          new Date(connectivityData.camera.footageBounds.end).getTime(),
        0
      ) +
      Math.max(
        new Date(connectivityData.camera.footageBounds.start).getTime() -
          from.getTime(),
        0
      );

    return missingSegmentLength + missingBoundLength;
  }
  function calculateUserActivityTime(
    activityEvents: {
      activity: ActivityTypes;
      startTime: number;
      duration: number;
    }[]
  ): {
    events: number;
    duration: number;
  } {
    return activityEvents.reduce(
      (acc, activity) => {
        if (
          new Date(activity.startTime) > from &&
          new Date(activity.startTime) < to
        ) {
          return {
            events: acc.events + 1,
            duration: acc.duration + activity.duration,
          };
        }
        return acc;
      },
      { events: 0, duration: 0 }
    );
  }

  function drawLabels(timelineLabels: string[]) {
    return (
      <Grid
        container
        alignItems="center"
        justifyContent={fitsTablet ? "flex-end" : "flex-start"}
      >
        <Grid
          item
          xs={12}
          style={{
            padding: "0px",
            position: "relative",
            display: "flex",
          }}
        >
          {timelineLabels.map((timelineLabel, i) => {
            return (
              <Grid item xs={3} style={{ display: "flex" }} key={`label-${i}`}>
                <Divider
                  orientation="vertical"
                  flexItem
                  className="w-[1px] h-[27px] opacity-30 border border-solid border-[#979797]"
                />
                <Grid
                  container
                  alignItems="baseline"
                  direction="column"
                  style={{ marginLeft: "2px" }}
                >
                  <Grid item className="h-[14px] text-xs text-[#353d48]">{`${
                    timelineLabel.split("-")[0]
                  }`}</Grid>
                  <Grid
                    item
                    className="opacity-50 h-[11px] text-[10px] text-[#252d48]"
                  >{`${timelineLabel.split("-")[1]}`}</Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    );
  }
  function getTimestampFromCoordinate(leftOffset: number): number {
    return (
      from.getTime() +
      (leftOffset - timelineOffset) *
        ((to.getTime() - from.getTime()) / timelineWidth)
    );
  }

  const [dragStart, setDragStart] = useState<number | null>(null);
  const [dragging, setDragging] = useState<number | null>(null);
  const [timelineWidth, setTimelineWidth] = useState<number>(0);
  const [timelineOffset, setTimelineOffset] = useState<number>(0);

  useEffect(() => {
    if (!dragStart) return;

    function mouseMoveListener(e: MouseEvent) {
      if (Math.abs(e.pageX - dragStart!) >= 10) {
        setDragging(e.pageX);
      }
    }

    function mouseUpListener(e: MouseEvent) {
      setDragStart(null);
      setDragging(null);

      if (dragging) {
        e.pageX > dragStart!
          ? setDatePicker(
              new Date(getTimestampFromCoordinate(dragStart!)),
              new Date(getTimestampFromCoordinate(e.pageX))
            )
          : setDatePicker(
              new Date(getTimestampFromCoordinate(e.pageX)),
              new Date(getTimestampFromCoordinate(dragStart!))
            );
      }
    }

    window.addEventListener("mouseup", mouseUpListener);
    window.addEventListener("mousemove", mouseMoveListener);

    return function cleanup() {
      window.removeEventListener("mousemove", mouseMoveListener);
      window.removeEventListener("mouseup", mouseUpListener);
    };
    // eslint-disable-next-line
  }, [dragStart, dragging]);

  const activity = cameraActivity.map(
    (act: { activity: ActivityTypes; startTime: string; endTime: string }) => {
      return {
        activity: act.activity,
        startTime: new Date(act.startTime).getTime(),
        duration: differenceInMilliseconds(new Date(act.startTime))(
          new Date(act.endTime)
        ),
      };
    }
  );

  let bound: Bound = getBound();
  let timelineLabels: Array<string> = getTimelineLabels();

  const disconnectPercent = Math.max(
    Math.round(
      (1 - calculateTotalDisconnectionTime() / (bound.right - bound.left)) * 100
    ),
    0
  );
  const userActivityDetails = calculateUserActivityTime(activity);
  let currentConnectivityTimestamp =
    bound.left > new Date(connectivityData.camera.footageBounds.start).getTime()
      ? bound.left
      : new Date(connectivityData.camera.footageBounds.start).getTime();

  return (
    <>
      <Grid
        container
        style={{
          margin: "8px 0 0 0",
          width: "initial",
          position: "relative",
          userSelect: "none",
        }}
        onMouseDown={(e) => {
          setTimelineWidth(e.currentTarget.offsetWidth);
          setTimelineOffset(e.currentTarget.offsetLeft);
          setDragStart(e.pageX);
        }}
      >
        {/* Missing Segment tooltip */}
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          disableRestoreFocus
          className="pointer-events-none"
          classes={{
            root: "opacity-60",
            paper: "-mt-1 px-2 py-1 text-white bg-black opacity-60 text-2xs",
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          {popoverContent}
        </Popover>
        {/* Zoom timeline */}
        {dragStart && timelineOffset && dragging && (
          <div
            className="opacity-20 z-[2] bg-[#0040ff] absolute h-[70%] top-[15%]"
            draggable={false}
            style={{
              left: `${
                dragging > dragStart
                  ? dragStart - timelineOffset
                  : Math.max(dragging - timelineOffset, 0)
              }px`,
              width: `${Math.abs(dragging - dragStart)}px`,
              maxWidth: `${
                dragging > dragStart
                  ? timelineWidth - (dragStart - timelineOffset)
                  : dragStart - timelineOffset
              }px`,
              userSelect: "none",
            }}
          />
        )}

        {/* Connectivity Timeline */}
        <Grid container className="mb-4">
          <Grid
            container
            item
            justifyContent="space-between"
            style={{ display: "flex" }}
          >
            <Grid
              item
              className="text-[#353d48] h-[14px] text-xs font-bold mb-2"
            >
              Connectivity
            </Grid>
            <Grid item className="h-3 opacity-50 text-[10px] text-[#252d48]">
              {`${disconnectPercent}% Average`}
            </Grid>
          </Grid>
          <Grid
            xs={12}
            item
            className="bg-[#08c500] p-0 relative h-[5px] mt-2 z-0"
          >
            <>
              {/* Missing link from -> footageBound.start for the time when
                camera wasn't connected
                */}
              {new Date(connectivityData.camera.footageBounds.start).getTime() >
                bound.left && (
                <Tooltip
                  placement="top"
                  title={`Disconnected for
                    ${formatDistanceStrict(new Date(bound.left))(
                      new Date(connectivityData.camera.footageBounds.start)
                    )} at ${format("h:mm a, do LLL")(new Date(bound.left))}`}
                >
                  <div
                    className={timelineElementClass}
                    style={{
                      left: `0px`,
                      width: `${
                        (new Date(
                          connectivityData.camera.footageBounds.start
                        ).getTime() -
                          bound.left) /
                        bound.timelineUnitDist
                      }%`,
                      maxWidth: `100%`,
                    }}
                  />
                </Tooltip>
              )}
              {/* All missing segments */}
              {connectivityData.camera.missingSegments.map(
                (
                  missingSegment: { start: string; duration: number },
                  idx: number
                ) => {
                  let timestamp: number = 0;
                  if (
                    +missingSegment.start + missingSegment.duration >=
                      bound.left &&
                    +missingSegment.start < bound.right
                  ) {
                    const prevSegment =
                      connectivityData.camera.missingSegments[idx - 1];
                    timestamp = timestamp
                      ? +prevSegment.start + prevSegment.duration
                      : currentConnectivityTimestamp;
                    const startLeft = Math.max(
                      +missingSegment.start - bound.left,
                      0
                    );
                    const duration =
                      missingSegment.duration -
                      Math.max(bound.left - +missingSegment.start, 0) -
                      Math.max(
                        +missingSegment.start +
                          missingSegment.duration -
                          bound.right,
                        0
                      );
                    const draw = (
                      <Fragment key={`segment-${idx}`}>
                        <div
                          className={timelineElementClass}
                          key={`conn-${missingSegment.start}`}
                          onMouseLeave={handlePopoverClose}
                          onMouseEnter={(e) => {
                            handlePopoverOpen(
                              e,
                              `Connected for ${formatDistanceStrict(
                                new Date(timestamp)
                              )(new Date(+missingSegment.start))} at ${format(
                                "h:mm a, do LLL"
                              )(new Date(timestamp))}`
                            );
                          }}
                          style={{
                            left: `${
                              (timestamp - bound.left) / bound.timelineUnitDist
                            }%`,
                            width: `${
                              (+missingSegment.start - timestamp) /
                              bound.timelineUnitDist
                            }%`,
                            background: "#08c500",
                            maxWidth: `100%`,
                          }}
                        />
                        <div
                          className={timelineElementClass}
                          key={`miss-${missingSegment.start}`}
                          onMouseLeave={handlePopoverClose}
                          onMouseEnter={(e) => {
                            handlePopoverOpen(
                              e,
                              `Disconnected for ${formatDistanceStrict(
                                new Date(startLeft)
                              )(new Date(startLeft + duration))} at ${format(
                                "h:mm a, do LLL"
                              )(new Date(+missingSegment.start))}`
                            );
                          }}
                          style={{
                            left: `${startLeft / bound.timelineUnitDist}%`,
                            width: `${duration / bound.timelineUnitDist}%`,
                          }}
                        />
                      </Fragment>
                    );
                    currentConnectivityTimestamp =
                      +missingSegment.start + missingSegment.duration;
                    return draw;
                  }
                  return null;
                }
              )}

              {/* Connected timefarme between last missing segment and before the footage bound ends*/}
              {currentConnectivityTimestamp <
                Math.min(
                  new Date(connectivityData.camera.footageBounds.end).getTime(),
                  bound.right
                ) && (
                <Tooltip
                  title={`Connected for ${formatDistanceStrict(
                    new Date(currentConnectivityTimestamp)
                  )(
                    new Date(
                      Math.min(
                        new Date(
                          connectivityData.camera.footageBounds.end
                        ).getTime(),
                        bound.right
                      )
                    )
                  )} at ${format("h:mm a, do LLL")(
                    new Date(currentConnectivityTimestamp)
                  )}`}
                >
                  <div
                    className={timelineElementClass}
                    style={{
                      left: `${
                        (currentConnectivityTimestamp - bound.left) /
                        bound.timelineUnitDist
                      }%`,
                      width: `${
                        (Math.min(
                          new Date(
                            connectivityData.camera.footageBounds.end
                          ).getTime(),
                          bound.right
                        ) -
                          currentConnectivityTimestamp) /
                        bound.timelineUnitDist
                      }%`,
                      background: "#08c500",
                    }}
                  />
                </Tooltip>
              )}
              {/* Missing link footageBound.end -> to for the time when camera wasn't connected */}
              {new Date(connectivityData.camera.footageBounds.end).getTime() <
                bound.right && (
                <Tooltip
                  placement="top"
                  title={`Disconnected since ${format("h:mm a, do LLL")(
                    new Date(connectivityData.camera.footageBounds.end)
                  )}`}
                >
                  <div
                    className={timelineElementClass}
                    style={{
                      right: `0px`,
                      width: `${
                        (bound.right -
                          new Date(
                            connectivityData.camera.footageBounds.end
                          ).getTime()) /
                        bound.timelineUnitDist
                      }%`,
                      maxWidth: `100%`,
                    }}
                  />
                </Tooltip>
              )}
            </>
          </Grid>
        </Grid>

        {/* Activity Timeline */}
        <Grid
          container
          alignItems="center"
          justifyContent="flex-start"
          className="mb-4"
        >
          <Grid
            container
            item
            justifyContent="space-between"
            style={{ display: "flex" }}
          >
            <Grid
              item
              className="text-[#353d48] h-[14px] text-xs font-bold mb-2"
            >
              Activity
            </Grid>
            <Grid item className="h-3 opacity-50 text-[10px] text-[#252d48]">
              {`${userActivityDetails.events} Events. ${Math.ceil(
                (userActivityDetails.duration / (bound.right - bound.left)) *
                  100
              )}% Active`}
            </Grid>
          </Grid>
          <Grid
            xs={12}
            item
            className="bg-[#efefef] p-0 relative h-[5px] mt-2 z-0"
          >
            {activity && (
              <>
                {activity.map(
                  (act: {
                    activity: ActivityTypes;
                    startTime: number;
                    duration: number;
                  }) => {
                    return (
                      act.startTime > bound.left &&
                      act.startTime + act.duration <= bound.right && (
                        <Tooltip
                          title={`${getActivityText(act.activity)} at ${format(
                            "h:mm a, do LLL"
                          )(new Date(act.startTime))}`}
                          key={`t-${act.activity}-${act.startTime}`}
                        >
                          <div
                            className={timelineElementClass}
                            onMouseOver={(e: any) =>
                              (e.target.style.zIndex = 1)
                            }
                            onMouseOut={(e: any) => (e.target.style.zIndex = 0)}
                            key={`act-${act.activity}-${act.startTime}`}
                            style={{
                              left: `${
                                (act.startTime - bound.left) /
                                bound.timelineUnitDist
                              }%`,
                              width: `${
                                act.duration / bound.timelineUnitDist
                              }%`,
                              minWidth: `0.5%`,
                              background: getActivityColor(act.activity),
                            }}
                          />
                        </Tooltip>
                      )
                    );
                  }
                )}
              </>
            )}
          </Grid>
        </Grid>

        {/* Timeline Labels */}
        {timelineLabels && drawLabels(timelineLabels)}
      </Grid>
    </>
  );
}

function getActivityColor(activity: ActivityTypes): string {
  switch (activity) {
    case ActivityTypes.LiveView:
    case ActivityTypes.VodView: {
      return "#67c21b";
    }
    case ActivityTypes.DownloadClip: {
      return "#ffac00";
    }
    case ActivityTypes.SaveClip: {
      return "#027ce4";
    }
    case ActivityTypes.DisableCameraSharing: {
      return "#ff0000";
    }
    case ActivityTypes.EnableCameraSharing: {
      return "#ff0000";
    }
  }
  return "#fffff";
}
function getActivityText(activity: ActivityTypes): string {
  switch (activity) {
    case ActivityTypes.LiveView: {
      return "LIVE video viewed";
    }
    case ActivityTypes.VodView: {
      return "Clip viewed";
    }
    case ActivityTypes.DownloadClip: {
      return "Clip downloaded";
    }
    case ActivityTypes.SaveClip: {
      return "Clip saved";
    }
    case ActivityTypes.DisableCameraSharing: {
      return "Disabled LIVE camera sharing";
    }
    case ActivityTypes.EnableCameraSharing: {
      return "Enabled LIVE camera sharing";
    }
  }
  return "Camera event occurred";
}
