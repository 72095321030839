import { DateRange, LocationOn, Timer, Videocam } from "@mui/icons-material";
import { Checkbox, Divider, Paper, Typography } from "@mui/material";
import clsx from "clsx";
import { format } from "date-fns/fp";
import { useField } from "formik";
import { capitalize } from "lodash";
import { Fragment } from "react";
import { Link } from "react-router-dom";

import {
  formatIsoDate,
  formatTimeString,
  getShorthandDayRange,
} from "@/util/date";
import { required } from "@/util/form";
import { formatDurationSecs } from "@/util/formatDurationSecs";
import { useBreakpoints } from "@/util/useBreakpoints";

import {
  CountDirection,
  CountDirectionLabel,
} from "@/pages/Intelligence/constants";

import {
  IntelligenceDashboardType,
  Page_IntelligenceQuery,
} from "@/generated-models";

import { ComparativeCardPreview } from "./ComparativeDashboard/ComparativeCardPreview";
import { CountCardPreview } from "./Count/CountCardPreview";
import { IdleCardPreview } from "./Idle/IdleCardPreview";
import { LicensePlateInterestListCardPreview } from "./LicensePlate/LicensePlateReport/LicensePlateInterestListCardPreview";
import { LicensePlateReportCardPreview } from "./LicensePlate/LicensePlateReport/LicensePlateReportCardPreview";
import { PresenceCardPreview } from "./Presence/PresenceCardPreview";
import {
  getDashboardFeatureConfig,
  getDashboardFeatureType,
  getObjectLabel,
  getObjectTypeIcon,
} from "./utils";

const today = new Date();
const todayIso = formatIsoDate(today);
const todayFormatted = format("PPP", today);

type QueriedDash = Page_IntelligenceQuery["intelligenceDashboards"][number];

const MAX_SELECTION_COUNT = 6;

function IntelligenceDashboardCardCheckbox({
  dashboard,
}: {
  dashboard: QueriedDash;
}) {
  const [, { value }, { setValue }] = useField<number[]>({
    name: "referenceDashIds",
    validate: (value: any) => required(value),
  });

  const enabled = value?.includes(dashboard.id) || false;

  return (
    <Checkbox
      color="primary"
      disabled={!enabled && value?.length >= MAX_SELECTION_COUNT}
      checked={enabled}
      onChange={(_, checked) => {
        const newValue = value || [];
        if (checked) {
          setValue([...newValue, dashboard.id]);
        } else {
          setValue(newValue.filter((v) => v !== dashboard.id));
        }
      }}
    />
  );
}

export function IntelligenceDashboardCard({
  minimal,
  selectable,
  dashboard,
}: {
  minimal?: boolean;
  selectable?: boolean;
  dashboard: QueriedDash;
}) {
  const { fitsTablet } = useBreakpoints();
  const feature = getDashboardFeatureType(
    dashboard.type,
    dashboard.objectTypes
  );

  const TypeIcon = getObjectTypeIcon(dashboard.objectTypes, dashboard.type);

  if (dashboard.type === IntelligenceDashboardType.Compound) {
    return <ComparativeCardPreview id={dashboard.id} />;
  }

  return (
    <Paper
      elevation={2}
      className="flex flex-col gap-2 flex-grow p-4 rounded-lg bg-white shadow-[0_0px_15px_rgba(0,0,0,0.15)]"
      component={selectable ? "div" : Link}
      to={String(dashboard.id)}
    >
      <div className="flex justify-between items-center flex-wrap">
        <div className="flex items-center gap-2 w-full flex-wrap">
          {selectable && (
            <IntelligenceDashboardCardCheckbox dashboard={dashboard} />
          )}
          <Typography className="font-medium truncate text-lg flex-1">
            {dashboard.name}
          </Typography>
          {!selectable && (
            <div
              className={clsx(
                "rounded-lg",
                getDashboardFeatureConfig(dashboard.type, dashboard.objectTypes)
                  .background.colorSecondary
              )}
            >
              <Typography
                className={`text-center font-medium p-2 text-sm shrink-0 ${
                  getDashboardFeatureConfig(
                    dashboard.type,
                    dashboard.objectTypes
                  ).foreground.colorPrimary
                }`}
              >
                {
                  getDashboardFeatureConfig(
                    dashboard.type,
                    dashboard.objectTypes
                  ).label.displayName
                }
              </Typography>
            </div>
          )}
        </div>
      </div>
      {!minimal && (
        <Typography
          className={clsx("font-bold text-gray-71 text-center text-base", {
            hidden: selectable && !fitsTablet,
          })}
        >
          {todayFormatted}
        </Typography>
      )}

      {!minimal && (
        <div
          className={clsx("h-32", {
            hidden: selectable && !fitsTablet,
          })}
        >
          {dashboard.type === IntelligenceDashboardType.Idle && (
            <IdleCardPreview
              id={dashboard.id}
              objectTypes={dashboard.objectTypes}
              timezone={dashboard.cameras[0]?.location?.timezone}
              chartDate={todayIso}
              feature={feature}
            />
          )}
          {dashboard.type === IntelligenceDashboardType.Count && (
            <CountCardPreview
              id={dashboard.id}
              timezone={dashboard.cameras[0]?.location?.timezone}
              chartDate={todayIso}
              subtype={dashboard.subtype as CountDirection}
              feature={feature}
            />
          )}
          {dashboard.type === IntelligenceDashboardType.Presence && (
            <PresenceCardPreview
              id={dashboard.id}
              objectTypes={dashboard.objectTypes}
              entityCount={dashboard.entityCount || 0}
              timezone={dashboard.cameras[0]?.location?.timezone}
              chartDate={todayIso}
              feature={feature}
            />
          )}
          {dashboard.type === IntelligenceDashboardType.Report && (
            <LicensePlateReportCardPreview dashboardId={dashboard.id} />
          )}
          {dashboard.type === IntelligenceDashboardType.InterestList && (
            <LicensePlateInterestListCardPreview dashboardId={dashboard.id} />
          )}
        </div>
      )}

      <Divider
        className={clsx("my-2", {
          hidden: selectable && !fitsTablet,
        })}
      />
      <div className="grid grid-cols-2 gap-3">
        <div className="flex items-center">
          <Videocam className="mr-2 opacity-30" fontSize="small" />
          <Typography className="text-sm truncate">
            {dashboard.cameras.map(({ name }) => name).join(",")}
          </Typography>
        </div>
        <div className="flex items-center">
          {dashboard.type === IntelligenceDashboardType.Idle && (
            <>
              <Timer className="mr-2 opacity-30" fontSize="small" />
              <Typography className="text-sm truncate">
                Idle &gt;
                {formatDurationSecs(dashboard.thresholdSeconds, {
                  long: true,
                  hideZeroes: true,
                })}
              </Typography>
            </>
          )}

          {dashboard.type === IntelligenceDashboardType.Presence && (
            <>
              <TypeIcon className="mr-2 opacity-30" fontSize="small" />
              <Typography className="text-sm truncate">
                {dashboard.entityCount}+{" "}
                {capitalize(
                  getObjectLabel(dashboard.objectTypes, dashboard.type, true)
                )}{" "}
                Present
              </Typography>
            </>
          )}

          {dashboard.type === IntelligenceDashboardType.Count && (
            <>
              <TypeIcon className="mr-2 opacity-30" fontSize="small" />
              <Typography className="text-sm truncate">
                {dashboard.subtype
                  ? CountDirectionLabel[dashboard.subtype]
                  : ""}
              </Typography>
            </>
          )}
        </div>
        <div className="flex items-center">
          <LocationOn className="mr-2 opacity-30" fontSize="small" />
          <Typography className="text-sm truncate">
            {dashboard.cameras.map(({ location }) => location.name).join(",")}
          </Typography>
        </div>
        <div className="flex items-center">
          <DateRange className="mr-2 opacity-30" fontSize="small" />
          <Typography className="text-sm truncate">{`${getShorthandDayRange(
            dashboard.daysOfWeek
          )}, ${formatTimeString(
            dashboard.startTime,
            "haaa"
          )} - ${formatTimeString(dashboard.endTime, "haaa")}`}</Typography>
        </div>
      </div>
    </Paper>
  );
}
