import { useApolloClient } from "@apollo/client";
import { useTheme } from "@mui/material";
import {
  addMinutes,
  roundToNearestMinutesWithOptions,
  subMinutes,
} from "date-fns/fp";
import gql from "graphql-tag";
import React, { useEffect, useMemo, useState } from "react";
import tinycolor from "tinycolor2";

import { filterFalsy, filterNullish } from "@/util/filterFalsy";

import {
  ageBucketMap,
  useGenderParam,
} from "@/pages/Search/SubjectSearch/components/AttributeFilters";
import {
  IntelligentFilterConfiguration,
  intelligentFiltersConfig,
} from "@/pages/Search/intelligence/intelligence";
import {
  useArraySearchFilter,
  useCameraMotionZone,
  useDuration,
  useSearchSubjects,
  useVodParam,
} from "@/pages/Search/searchHooks";

import {
  useMultiPlayerControls,
  usePlayerIds,
  useWallclockTime,
} from "@/components/Player/PlayerBase";
import {
  Bounds,
  CanvasTimeline,
  CanvasTimelineProps,
  MetaEventGroup,
} from "@/components/View/CanvasTimeline";
import { levels } from "@/components/View/timelineLevels";
import { Point } from "@/components/Zones/getSectorsForPolygon";

import {
  CameraVodPreviewsDocument,
  CameraVodPreviewsQuery,
  CameraVodPreviewsQueryVariables,
  ClothingColor,
  Gender,
  Segment,
  useFootageMetadataQuery,
  useGetStillsSampleQuery,
  VehicleColor,
  VehicleMake,
  VehicleType,
} from "@/generated-models";

import { useCopilotEnabled } from "../Ai/Copilot/copilotQueryHooks";

const roundToNearestFive = roundToNearestMinutesWithOptions({ nearestTo: 5 });
const now = new Date();
const thirtyDaysAgo = new Date();
thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

export function WiredCanvasTimeline({
  focusedCameraId,
  showMissingFootage,
  ...props
}: Omit<CanvasTimelineProps, "playhead" | "onSeek" | "onChangeActiveWindow"> & {
  focusedCameraId: number;
  showMissingFootage: boolean;
  style?: React.CSSProperties;
}) {
  const theme = useTheme();
  const wallclockTime = useWallclockTime();
  const { copilotEnabled, setCopilotEnabled } = useCopilotEnabled();
  const { setVod, vodDuration } = useVodParam();
  const { durationParam } = useDuration();
  const [motionSegmentInterval, setMotionSegmentInterval] = useState<
    number | null
  >(60);
  const [viewportBounds, setViewportBounds] = useState<Bounds | null>(null);

  // Disabled debouncing as we've disabled pan/zoom
  const debouncedInterval = motionSegmentInterval;
  // const debouncedBounds = viewportBounds;
  // const debouncedInterval = useDebounce(motionSegmentInterval, 500);
  const debouncedBounds = useDebounce(viewportBounds, 500);

  function padded(bounds: Bounds) {
    const diff = Math.round((bounds.upper - bounds.lower) * 0.5);
    return { lower: bounds.lower - diff, upper: bounds.upper + diff };
  }
  const paddedBounds = debouncedBounds && padded(debouncedBounds);

  // Missing segments
  const { data: footageMetadata } = useFootageMetadataQuery({
    variables: {
      cameraId: focusedCameraId,
      startTime: thirtyDaysAgo.toISOString(),
      endTime: now.toISOString(),
    },
    skip: !showMissingFootage,
  });
  const startTime = new Date(paddedBounds?.lower || 0).toISOString();
  const endTime = new Date(paddedBounds?.upper || 0).toISOString();

  // Motion intervals, Deprecation incoming.
  const { activeShape } = useCameraMotionZone(focusedCameraId);

  function mapSegment(segment: Segment) {
    const start = Number(segment.start);
    return {
      start,
      end: start + segment.duration,
    };
  }
  const missingSegments = footageMetadata?.camera.missingSegments;
  const missingSegmentEvents = missingSegments?.map(mapSegment);

  const segments = useIntelligenceIntervals(
    focusedCameraId,
    { startTime, endTime, interval: debouncedInterval },
    !paddedBounds,
    activeShape
  );

  const eventGroups: MetaEventGroup[] = [
    ...(props.eventGroups || []),
    missingSegmentEvents &&
      showMissingFootage && {
        label: "Missing Footage",
        groups: [
          {
            color: parseInt(theme.palette.error.main.replace("#", ""), 16),
            events: missingSegmentEvents,
          },
        ],
      },
    ...segments.map(([searchSubject, { mainSegments, backgroundSegments }]) => {
      return {
        id: searchSubject?.id,
        label: searchSubject?.title || "unknown",
        groups: [
          {
            color: parseInt(
              (tinycolor(searchSubject?.color()).toHex() || "#FFFF00").replace(
                "#",
                ""
              ),
              16
            ),
            events: mainSegments.map(({ start, end }) => ({
              start: new Date(start).getTime(),
              end: new Date(end).getTime(),
            })),
          },
          {
            color: parseInt(
              tinycolor(searchSubject?.color() || "#FFFF00")
                .lighten(40)
                .toHex()
                .replace("#", ""),
              16
            ),
            events: backgroundSegments.map(({ start, end }) => ({
              start: new Date(start).getTime(),
              end: new Date(end).getTime(),
            })),
          },
        ],
      };
    }),
  ].filter(filterFalsy);

  const { seek, setContinuation } = useMultiPlayerControls();

  const { data: stillsSampleData } = useGetStillsSampleQuery({
    variables: {
      cameraId: focusedCameraId,
      startTime: roundToNearestFive(
        subMinutes(5, new Date((debouncedBounds ?? props.bounds).lower))
      ).toISOString(),
      endTime: roundToNearestFive(
        addMinutes(
          5,
          // Make sure to keep fetching stills if now is in between bounds
          new Date(
            Math.min((debouncedBounds ?? props.bounds).upper, Date.now())
          )
        )
      ).toISOString(),
      sampleCount: 50,
    },
  });
  const stillsSample = useMemo(
    () =>
      stillsSampleData?.stillsSample.map(({ timestamp, src }) => ({
        timestamp: new Date(timestamp).getTime(),
        src,
      })),
    [stillsSampleData]
  );

  return (
    <CanvasTimeline
      {...props}
      onNavigate={({ zoomLevel, bounds }) => {
        setViewportBounds(bounds);
        const intervals = [null, null, null, 1, 5, 10, 30, 60];
        const interval =
          intervals[Math.floor((zoomLevel / levels.length) * intervals.length)];
        setMotionSegmentInterval(interval);
      }}
      eventGroups={
        eventGroups.length === 0
          ? [{ label: "", groups: [{ color: 0, events: [] }] }]
          : eventGroups
      }
      onSeek={(time) => {
        if (
          props.activeWindow &&
          time > props.activeWindow.lower &&
          time < props.activeWindow.upper
        ) {
          seek((time - props.activeWindow.lower) / 1000, true);
          return;
        }

        const start = new Date(time);
        const end = new Date(time);
        end.setSeconds(
          end.getSeconds() +
            (vodDuration ? vodDuration / 1000 : durationParam * 60)
        );

        if (copilotEnabled) {
          setCopilotEnabled(false);
        }

        setVod({ start: start.toISOString(), end: end.toISOString() });
      }}
      bounds={props.bounds}
      onChangeActiveWindow={({ lower, upper }) => {
        const time = wallclockTime?.getTime();
        if (time && time > lower && time < upper) {
          setContinuation((time - lower) / 1000);
        }

        if (copilotEnabled) {
          setCopilotEnabled(false);
        }

        setVod({
          start: new Date(lower).toISOString(),
          end: new Date(upper).toISOString(),
        });
      }}
      // activeWindowToolbar={
      //   props.activeWindow && (
      //     <ActiveWindowToolbar
      //       cameraId={cameraId}
      //       startTime={new Date(props.activeWindow.lower).toISOString()}
      //       endTime={new Date(props.activeWindow.upper).toISOString()}
      //     />
      //   )
      // }
      playhead={wallclockTime?.getTime()}
      thumbnails={stillsSample}
    />
  );
}

type EventSegments = { start: string; end: string }[];
type MainBackgroundSegmentsPair = {
  mainSegments: EventSegments;
  backgroundSegments: EventSegments;
};
type SearchSegments = [
  IntelligentFilterConfiguration | null,
  MainBackgroundSegmentsPair
][];
function useIntelligenceIntervals(
  focusedCameraId: number,
  input: { startTime: string; endTime: string; interval: number | null },
  skip?: boolean,
  activeMotionZone?: Point[]
) {
  const playerIds = usePlayerIds();
  const subjects = useSearchSubjects();
  const clothingUpper = useArraySearchFilter("clothingUpper");
  const clothingLower = useArraySearchFilter("clothingLower");
  const [gender] = useGenderParam();
  const age = useArraySearchFilter("age");
  const vehicleType = useArraySearchFilter("vehicleType");
  const vehicleMake = useArraySearchFilter("vehicleMake");
  const vehicleColor = useArraySearchFilter("vehicleColor");
  const [segments, setSegments] = useState<SearchSegments>([]);
  const client = useApolloClient();

  useEffect(() => {
    if (!skip) {
      let cancelled = false;
      const fetchSegments = async () => {
        const {
          motionSubjects,
          vehicleSubjects,
          forkliftSubjects,
          otherSubjects,
          peopleSubjects,
        } = {
          motionSubjects: subjects?.filter((x) =>
            ["m", "legacyMotion"].includes(x)
          ),
          peopleSubjects: subjects?.filter((x) => ["0"].includes(x)),
          vehicleSubjects: subjects?.filter((x) => ["2", "5", "7"].includes(x)),
          forkliftSubjects: subjects?.filter((x) => ["1"].includes(x)),
          otherSubjects: subjects?.filter(
            (x) => !["m", "legacyMotion", "1", "2", "5", "7", "0"].includes(x)
          ),
        };

        const regroupedSubjects = [
          ...(motionSubjects
            ? [{ id: "motion" as const, subjects: motionSubjects }]
            : []),
          ...(peopleSubjects
            ? [{ id: "people" as const, subjects: peopleSubjects }]
            : []),
          ...(vehicleSubjects
            ? [{ id: "vehicle" as const, subjects: vehicleSubjects }]
            : []),
          ...(forkliftSubjects
            ? [{ id: "forklift" as const, subjects: forkliftSubjects }]
            : []),
          ...(otherSubjects || []).map((subject) => ({
            id: undefined,
            subjects: [subject],
          })),
        ].filter((sub) => sub && sub.subjects && sub.subjects.length > 0);

        const results = await Promise.all(
          regroupedSubjects.map(async (searchGroup) => {
            const cameraResults = await Promise.all(
              playerIds
                .filter((id) => !!id)
                .map(async (id) => {
                  const cameraId = Number(id);
                  const { data } = await client.query<
                    CameraVodPreviewsQuery,
                    CameraVodPreviewsQueryVariables
                  >({
                    query: CameraVodPreviewsDocument,
                    variables: {
                      id: cameraId,
                      start: input.startTime,
                      end: input.endTime,
                      duration: (input.interval || 0.5) * 60,
                      search: {
                        searchSubjects: searchGroup.subjects,
                        searchArea: activeMotionZone,
                        attributes: {
                          clothingUpper: clothingUpper as
                            | ClothingColor[]
                            | undefined,
                          clothingLower: clothingLower as
                            | ClothingColor[]
                            | undefined,
                          gender: gender as Gender | null | undefined,
                          age: age?.map((x) => ageBucketMap[x]),
                          vehicleType: vehicleType as VehicleType[],
                          vehicleMake: vehicleMake as VehicleMake[],
                          vehicleColor: vehicleColor as VehicleColor[],
                        },
                      },
                    },
                  });

                  const previewSegments = data?.camera.previews;

                  if (!previewSegments) {
                    return undefined;
                  }
                  return { previewSegments, cameraId };
                })
            );
            return [
              searchGroup.id ? intelligentFiltersConfig[searchGroup.id] : null,
              cameraResults.filter(filterNullish).reduce(
                (prev, curr) => {
                  if (curr.cameraId === focusedCameraId) {
                    return {
                      mainSegments: prev.mainSegments.concat(
                        curr.previewSegments
                      ),
                      backgroundSegments: prev.backgroundSegments,
                    };
                  }
                  return {
                    mainSegments: prev.mainSegments,
                    backgroundSegments: prev.backgroundSegments.concat(
                      curr.previewSegments
                    ),
                  };
                },
                {
                  mainSegments: [] as EventSegments,
                  backgroundSegments: [] as EventSegments,
                }
              ),
            ] as [
              IntelligentFilterConfiguration | null,
              MainBackgroundSegmentsPair
            ];
          })
        );
        if (!cancelled) {
          setSegments(results.filter(filterFalsy));
        }
      };
      fetchSegments();
      return () => {
        cancelled = true;
      };
    }
  }, [
    input.startTime,
    input.endTime,
    input.interval,
    skip,
    focusedCameraId,
    activeMotionZone,
    subjects,
    clothingUpper,
    clothingLower,
    gender,
    age,
    vehicleType,
    vehicleMake,
    vehicleColor,
    playerIds,
    client,
  ]);
  return segments;
}

// const useActiveWindowToolbarStyles = makeStyles()((theme) => ({
//   button: {
//     fontFamily: "inherit",
//     fontSize: "inherit",
//     fontWeight: "normal",
//     background: "none",
//     border: "none",
//     cursor: "pointer",
//     minWidth: "initial",
//     lineHeight: "inherit",
//     padding: `0 8px`,
//   },
//   divider: {
//     background: "white",
//     opacity: 0.4,
//   },
//   activeWindowToolbar: {
//     fontSize: 12,
//   },
// }));

// const ActiveWindowToolbar = React.memo(function ActiveWindowToolbar(
//   props: ActiveWindowToolbarButtonProps
// ) {
//   return (
//     <FlattenProviders
//       contextProviders={[
//         // forceRefresh to allow history.pushState from within this separate react root
//         <BrowserRouter forceRefresh />,
//         <ThemeProvider theme={theme} children={{} as any} />,
//         <ApolloProvider children={{} as any} />,
//         <SnackbarProvider children={{} as any} />,
//         <ClipDownload children={{} as any} />,
//         <QueryParamProvider children={{} as any} />,
//       ]}
//     >
//       <ActiveWindowToolbarButtons {...props} />
//     </FlattenProviders>
//   );
// });

// interface ActiveWindowToolbarButtonProps {
//   cameraId: number;
//   startTime: string;
//   endTime: string;
// }
// function ActiveWindowToolbarButtons(props: ActiveWindowToolbarButtonProps) {
//   const { classes } = useActiveWindowToolbarStyles();
//   const { downloadClip } = useClipDownload();
//   return (
//     <Grid container className={classes.activeWindowToolbar}>
//       <ShareClipButton
//         {...props}
//         button={({ shared, onClick }) => (
//           <Button
//             color={shared ? "secondary" : "inherit"}
//             onClick={onClick}
//             className={classes.button}
//           >
//             {shared ? "Shared" : "Share"}
//           </Button>
//         )}
//       />
//       <Divider className={classes.divider} orientation="vertical" flexItem />
//       <AddToCaseButton
//         {...props}
//         button={({ onClick, anchorEl }) => (
//           <Button
//             ref={anchorEl}
//             color="inherit"
//             onClick={onClick}
//             className={classes.button}
//           >
//             Add to Case
//           </Button>
//         )}
//       />
//       {downloadSupport && (
//         <>
//           <Divider
//             className={classes.divider}
//             orientation="vertical"
//             flexItem
//           />
//           <Button
//             color="inherit"
//             className={classes.button}
//             onClick={() => {
//               trackClipDownload();
//               downloadClip(props);
//             }}
//           >
//             Download
//           </Button>
//         </>
//       )}
//     </Grid>
//   );
// }

function useDebounce<T>(value: T, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}

gql`
  query footageMetadata(
    $cameraId: Int!
    $startTime: String!
    $endTime: String!
  ) {
    camera(id: $cameraId) {
      id
      footageBounds {
        start
        end
      }
      missingSegments(startTime: $startTime, endTime: $endTime) {
        start
        duration
      }
    }
  }
`;

gql`
  query getStillsSample(
    $cameraId: Int!
    $startTime: String!
    $endTime: String!
    $sampleCount: Int!
  ) {
    stillsSample(
      id: $cameraId
      startTime: $startTime
      endTime: $endTime
      sampleCount: $sampleCount
    ) {
      timestamp
      src
    }
  }
`;

gql`
  query cameraVodPreviews(
    $id: Int!
    $start: String!
    $end: String!
    $duration: Int!
    $search: SearchOptions
  ) {
    camera(id: $id) {
      id
      name

      previews(
        startTime: $start
        endTime: $end
        duration: $duration
        search: $search
      ) {
        start
        end
      }
    }
  }
`;
