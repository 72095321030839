import { DeleteForever } from "@mui/icons-material";
import { Button, TextField, Typography } from "@mui/material";
import clsx from "clsx";
import { ReactNode } from "react";
import {
  Controller,
  useController,
  useFieldArray,
  useFormContext,
} from "react-hook-form";

import { useBreakpoints } from "@/util/useBreakpoints";

import { useDialog } from "@/components/shared/Dialog";

import { IntegrationCreateFormFields } from "../../Create/IntegrationCreateForm";
import { SchemaActionButton } from "../../SchemaActionButton";
import {
  BASE_CONNECT_INPUT_PROPS,
  DEFAULT_CONNECT_DEVICE,
  FormMode,
} from "../../constant";
import ConnectCustomDeviceCamerasDialog from "./CustomDeviceCamerasDialog";
import { CustomDeviceTagsButton } from "./CustomDeviceTagsButton";

interface IntegrationDeviceFormCardProps {
  idx: number;
  id?: number;
  mode?: FormMode;
  path: string;
  disabled?: boolean;
  onDelete: () => void;
  children?: ReactNode;
}

function DeviceFormCardItem({ children }: { children: ReactNode }) {
  return <div className="flex justify-center mt-5">{children}</div>;
}

export function IntegrationDeviceFormCard({
  idx,
  path,
  mode = FormMode.Edit,
  onDelete,
  disabled,
  children,
}: IntegrationDeviceFormCardProps) {
  const { open, opened, cancel } = useDialog();
  const { fitsDesktop } = useBreakpoints();

  const {
    field: { value: name },
  } = useController({
    name: `${path}.name`,
  });

  const {
    field: { value: tags, onChange: updateTags },
  } = useController({
    name: `${path}.tags`,
  });

  const {
    field: { value: cameraIds },
    fieldState: { error },
  } = useController({
    name: `${path}.cameraIds`,
    rules: {
      validate: {
        required: (v) => v.length > 0 || "Must be linked to a camera",
      },
    },
  });
  const cameraTitle = fitsDesktop ? "Cameras" : "Cams";

  return (
    <div className="bg-[#FBFBFB] max-w-[750px] p-4 rounded-lg border border-solid border-[#E0E0E0]">
      <ConnectCustomDeviceCamerasDialog
        name={name}
        path={path}
        open={opened}
        onClose={cancel}
      />
      <>
        <div className="flex flex-col sm:flex-row items-start gap-4 justify-between">
          <Controller
            name={`${path}.name`}
            rules={{
              required: {
                value: true,
                message: "Please provide a name",
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                label="Device Name"
                error={!!error}
                helperText={error?.message}
                className="mt-4 w-full sm:w-2/4 md:w-96"
                placeholder="Device Name"
                {...BASE_CONNECT_INPUT_PROPS}
                {...field}
              />
            )}
          />
          <div className="flex items-end w-full justify-between sm:justify-start sm:w-auto">
            <DeviceFormCardItem>
              <div className="flex flex-col items-center justify-start gap-2">
                <Button color="primary" className="font-bold" onClick={open}>
                  {cameraIds.length > 0
                    ? `${cameraIds.length} Linked ${cameraTitle}`
                    : `Link ${cameraTitle}`}
                </Button>
                {error && (
                  <Typography className="text-xs text-error text-center">
                    {error.message}
                  </Typography>
                )}
              </div>
            </DeviceFormCardItem>
            <DeviceFormCardItem>
              <CustomDeviceTagsButton
                className={clsx("pl-2", tags.length > 0 && "w-full")}
                currentTags={tags}
                deviceName={name || `Device ${idx + 1}`}
                onSave={(result) => {
                  updateTags(result);
                }}
              />
            </DeviceFormCardItem>

            {mode === FormMode.Edit && (
              <Button
                disabled={disabled}
                onClick={onDelete}
                className="text-[#757575] font-normal"
              >
                <DeleteForever className="pr-1" />
                <span className="sr-only">Delete Device</span>
              </Button>
            )}
          </div>
        </div>
        {!!children && (
          <div className="flex items-center justify-end w-full">{children}</div>
        )}
      </>
    </div>
  );
}

export function CustomDeviceFormItem() {
  const { control } = useFormContext<IntegrationCreateFormFields>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "devices",
  });

  return (
    <div>
      <div className="flex flex-col gap-3">
        {fields.map((f, idx) => (
          <IntegrationDeviceFormCard
            idx={idx}
            key={f.id}
            path={`devices.${idx}`}
            mode={fields.length === 1 ? FormMode.Create : FormMode.Edit}
            onDelete={() => {
              remove(idx);
            }}
          />
        ))}
      </div>
      <div className="mt-6">
        <SchemaActionButton
          large
          onClick={() => {
            append({ ...DEFAULT_CONNECT_DEVICE });
          }}
        >
          + Add Device
        </SchemaActionButton>
      </div>
    </div>
  );
}
