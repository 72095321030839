import { useLocalStorageValue } from "@react-hookz/web";
import { utcToZonedTime } from "date-fns-tz";
import { format } from "date-fns/fp";
import gql from "graphql-tag";
import { isEqual, uniq, uniqBy } from "lodash/fp";
import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { formatShortDuration } from "@/util/date";
import { downloadExternalFile } from "@/util/file";
import { filterNullish } from "@/util/filterFalsy";

import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";

import {
  DistributedTaskStatus,
  useCaseDownloadQuery,
  useClipDownloadQuery,
  useGetCameraNameTimezoneQuery,
} from "@/generated-models";

import { DownloadWidget } from "./Download/DownloadWidget";
import {
  detectMobileWrapper,
  mobileEnvironment,
} from "./Mobile/mobileEnvironment";
import { DefaultDialog, useDialog } from "./shared/Dialog";

export type CaseQueryVariables = { caseId: number } | { shareToken: string };

const DownloadsContext = React.createContext<{
  downloadClip: (clip: Clip) => void;
  downloadCase: (query: CaseQueryVariables) => void;
}>({ downloadClip: () => {}, downloadCase: () => {} });

export function useDownloads() {
  return useContext(DownloadsContext);
}

interface Clip {
  cameraId: number;
  startTime: string;
  endTime: string;
}

const fmt = format("yyyy-MM-dd p");

// Download is not supported in iOS Chrome
export const downloadSupport =
  !navigator.userAgent.includes("CriOS") &&
  // Old native apps
  !(detectMobileWrapper() && !mobileEnvironment);

export function DownloadsProvider({ children }: { children: React.ReactNode }) {
  const [clips, setClips] = useLocalStorageValue<Clip[]>(
    "activeClipDownloads",
    []
  );
  const [cases, setCases] = useLocalStorageValue<CaseQueryVariables[]>(
    "activeCaseDownloads",
    []
  );
  const downloadClip = useCallback(
    (newClip: Clip) => {
      setClips((clips) =>
        uniqBy((clip) => [clip.cameraId, clip.startTime, clip.endTime].join(), [
          ...clips,
          newClip,
        ])
      );
    },
    [setClips]
  );
  const finishClip = useCallback(
    (clip: Clip) => {
      setClips((clips) => clips.filter((c) => c !== clip));
    },
    [setClips]
  );

  const downloadCase = useCallback(
    (variables: CaseQueryVariables) => {
      setCases((cases) => uniq([...cases, variables]));
    },
    [setCases]
  );
  const finishCase = useCallback(
    (variables: CaseQueryVariables) => {
      const tester = isEqual(variables);
      setCases((cases) => cases.filter((c) => !tester(c)));
    },
    [setCases]
  );

  return (
    <>
      {clips.length + cases.length > 0 && (
        <div className="fixed top-2 md:top-16 sm:left-5 right-16 sm:right-[initial] z-[1202] flex flex-col gap-4 min-w-[240px]">
          {clips.map((clip) => (
            <ClipDownload
              key={[clip.cameraId, clip.startTime, clip.endTime].join("-")}
              clip={clip}
              onFinish={finishClip.bind(undefined, clip)}
            />
          ))}
          {cases.map((variables) => (
            <CaseDownload
              key={
                "caseId" in variables ? variables.caseId : variables.shareToken
              }
              variables={variables}
              onFinish={finishCase.bind(undefined, variables)}
            />
          ))}
        </div>
      )}
      <DownloadsContext.Provider value={{ downloadClip, downloadCase }}>
        {children}
      </DownloadsContext.Provider>
    </>
  );
}

function ClipDownload({
  clip,
  onFinish,
}: {
  clip: Clip;
  onFinish: () => void;
}) {
  const feedback = useFeedback();
  const { data: cameraData } = useGetCameraNameTimezoneQuery({
    variables: { cameraId: clip.cameraId },
  });
  const {
    startPolling,
    stopPolling,
    data: clipDownloadData,
  } = useClipDownloadQuery({
    fetchPolicy: "network-only",
    variables: clip!,
    skip: !clip,
    onError: () => {
      onFinish();
      feedback.pushSnackbar(
        "Preparing clip for download failed, please try again",
        FeedbackType.Error
      );
    },
    notifyOnNetworkStatusChange: true, // Hack to get onCompleted to fire on every poll
    onCompleted: (data: any) => {
      if (data?.clipDownload.link) {
        downloadExternalFile(data.clipDownload.link);
        onFinish();
      }
    },
  });

  useEffect(() => {
    if (!clip) stopPolling();
    else startPolling(3000);
  }, [clip, stopPolling, startPolling]);

  const details = useMemo(() => {
    if (!clip || !cameraData?.camera.location.timezone) return null;
    const startDate = new Date(clip.startTime);
    const endDate = new Date(clip.endTime);
    const timezone = cameraData.camera.location.timezone;
    return {
      Name: cameraData.camera.name,
      "Start time": fmt(utcToZonedTime(startDate, timezone)),
      "End time": fmt(utcToZonedTime(endDate, timezone)),
      Duration: formatShortDuration(startDate, endDate),
    };
  }, [clip, cameraData]);

  return (
    details && (
      <DownloadWidget
        label="Downloading Clip"
        progress={clipDownloadData?.clipDownload?.progress ?? null}
        details={details}
        cancel={onFinish}
      />
    )
  );
}

function CaseDownload({
  variables,
  onFinish,
}: {
  variables: CaseQueryVariables;
  onFinish: () => void;
}) {
  const feedback = useFeedback();

  const [supressFailedClips, setSupressFailedClips] = useState(false);

  const onError = useCallback(() => {
    onFinish();
    feedback.pushSnackbar(
      "Preparing case for download failed, please try again",
      FeedbackType.Error
    );
  }, [feedback, onFinish]);
  const {
    startPolling,
    stopPolling,
    data: caseDownloadData,
  } = useCaseDownloadQuery({
    fetchPolicy: "network-only",
    variables: {
      ...variables,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      allowFailedTasks: supressFailedClips,
    },
    onError,
    notifyOnNetworkStatusChange: true, // Hack to get onCompleted to fire on every poll
    onCompleted: (data) => {
      if (data?.caseDownload.taskStages) {
        const hardFailingStages = data.caseDownload.taskStages
          .flat()
          .filter(
            (task) =>
              task.type !== "clip-sync" &&
              task.status === DistributedTaskStatus.Failed
          );
        if (hardFailingStages.length > 0) {
          onError();
        }
      }
      if (data?.caseDownload.link && data?.caseDownload.title) {
        downloadExternalFile(
          data.caseDownload.link,
          `Case - ${data.caseDownload.title}.zip`
        );
        onFinish();
      }
    },
  });
  const { open, ...partialDownloadDialog } = useDialog();

  useEffect(() => {
    startPolling(3000);
    return stopPolling;
  }, [variables, stopPolling, startPolling]);

  const details = useMemo(() => {
    let result: Record<string, ReactNode> = {};
    if (caseDownloadData?.caseDownload.title) {
      result.Name = caseDownloadData?.caseDownload.title;
    }
    let clipIndex = 0;
    caseDownloadData?.caseDownload.taskStages?.flat()?.forEach((task) => {
      const name =
        task.type === "clip-sync" ? `Clip ${++clipIndex}` : task.name;
      if (task.status === DistributedTaskStatus.Failed) {
        result[name] = <strong className="text-error">Failed</strong>;
      } else {
        result[name] = Math.floor(task.progress) + "%";
      }
    });

    return result;
  }, [
    caseDownloadData?.caseDownload.title,
    caseDownloadData?.caseDownload.taskStages,
  ]);

  const failedClips = useMemo(() => {
    if (!caseDownloadData?.caseDownload.taskStages) return;

    const clipTasks = caseDownloadData.caseDownload.taskStages
      ?.flat()
      ?.filter((task) => task.type === "clip-sync");

    if (
      clipTasks.some((task) => task.status === DistributedTaskStatus.Pending)
    ) {
      // Wait for all clip tasks to start
      return;
    }

    return clipTasks
      .map((task, i) =>
        task.status === DistributedTaskStatus.Failed ? `Clip ${i + 1}` : null
      )
      .filter(filterNullish);
  }, [caseDownloadData?.caseDownload.taskStages]);

  useEffect(() => {
    async function run() {
      if (failedClips?.length && !supressFailedClips) {
        const confirmed = await open();
        if (!confirmed) {
          onFinish();
        } else {
          setSupressFailedClips(true);
        }
      }
    }
    run();
  }, [failedClips, onFinish, open, supressFailedClips]);

  const progress = caseDownloadData?.caseDownload.taskStages
    ?.flat()
    .reduce(
      (progress, task, _i, tasks) => progress + task.progress / tasks.length,
      0
    );

  return (
    details && (
      <>
        <DownloadWidget
          label="Downloading Case"
          progress={progress ?? null}
          details={details}
          cancel={onFinish}
        />
        <DefaultDialog
          title="Case Download Incomplete"
          content={
            <>
              We're having some trouble downloading this case, the following
              clips couldn't be downloaded:
              <ul>
                {failedClips?.map((failedClipName, i) => (
                  <li key={i}>{failedClipName}</li>
                ))}
              </ul>
            </>
          }
          confirmText="Download anyway"
          {...partialDownloadDialog}
        />
      </>
    )
  );
}

gql`
  query clipDownload($cameraId: Int!, $startTime: String!, $endTime: String!) {
    clipDownload(id: $cameraId, startTime: $startTime, endTime: $endTime) {
      link
      progress
    }
  }
`;

gql`
  query getCameraNameTimezone($cameraId: Int!) {
    camera(id: $cameraId) {
      id
      name
      location {
        id
        timezone
      }
    }
  }
`;

gql`
  query caseDownload(
    $caseId: Int
    $shareToken: String
    $allowFailedTasks: Boolean
    $timezone: String
  ) {
    caseDownload(
      caseId: $caseId
      sharedCaseToken: $shareToken
      allowFailedTasks: $allowFailedTasks
      timezone: $timezone
    ) {
      id
      title
      link
      taskStages {
        id
        name
        type
        status
        progress
      }
    }
  }
`;
