import { formatIsoDate } from "@/util/date";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  IntelligenceDashboardQuery,
  IntelligenceDashboardType,
  useIntelligenceDashboardContentQuery,
  useIntelligencePresenceDashboardContentQuery,
} from "@/generated-models";

import { Range } from "../IntelligenceDashboardView";
import { useLprReportGrouped } from "../LicensePlate/LicensePlateReport/hooks";

type Dashboard = NonNullable<
  IntelligenceDashboardQuery["intelligenceDashboard"]
>;

export function useIntelligenceDashboardData(
  dashboard: Dashboard,
  startDate: Date,
  endDate: Date,
  range?: string
) {
  const isReport =
    dashboard.type === IntelligenceDashboardType.Report ||
    dashboard.type === IntelligenceDashboardType.InterestList;

  const lprQuery = useLprReportGrouped(dashboard.id, false, !isReport);

  if (isReport) return lprQuery.data?.lprReport;

  const query =
    dashboard.type === IntelligenceDashboardType.Presence
      ? useIntelligencePresenceDashboardContentQuery
      : useIntelligenceDashboardContentQuery;

  return query({
    ...refetchOnMountPolicy,
    variables: {
      id: dashboard.id,
      startDate: formatIsoDate(startDate),
      endDate: formatIsoDate(endDate),
      bucketSizeSeconds:
        dashboard.type === IntelligenceDashboardType.Idle && range === Range.Day
          ? 3600
          : 86400,
      usePaths: dashboard.type === IntelligenceDashboardType.Count,
    },
  }).data?.intelligenceDashboard;
}
