import clsx from "clsx";
import React, { PropsWithChildren } from "react";
import { useMeasure } from "react-use";

import { useZoomState } from "@/components/Player/PlayerBase";
import { calculateZoomTransform } from "@/components/Player/ZoomButton";
import { useVideoDimensions } from "@/components/Player/playerMachine";

/**
 * An overlay that exactly matches the dimensions of the video content, given the
 * video's aspect ratio, and player dimensions.
 *
 * Assumes it is rendered within a container that matches the dimensions of the
 * jw player element, and has a position other than `static`.
 */
export function VideoOverlay({
  children,
  playerId,
  className,
  enablePointerEvents = false,
}: PropsWithChildren<{
  playerId?: string;
  className?: string;
  enablePointerEvents?: boolean;
}>) {
  const [ref, rect] = useMeasure();

  const zoom = useZoomState(playerId);
  const dimensions = useVideoDimensions();
  if (!dimensions) return null;
  const { width, height } = dimensions;
  const videoAspect = width / height;

  return (
    <div
      // Disabling pointer events is required to interact with player element,
      // For example, with zoom features. However, this conflicts with other interactions,
      // Such as the ability to select bounding boxes on the ActivityOverlay.
      //
      // pointer-events-none was originally used to allow for interactions with JWPlayer, but
      // now that we have a player in house, pointer-events-none is no longer required.
      className={clsx(
        "absolute w-full h-full grid place-items-center overflow-hidden",
        { "pointer-events-none": !enablePointerEvents }
      )}
      ref={ref as any}
    >
      {rect && (
        <div
          className={clsx("h-full relative", className, {
            "transition-transform": zoom?.dragging,
          })}
          style={{
            width:
              rect.width / rect.height > videoAspect
                ? videoAspect * rect.height
                : "100%",
            transform: zoom
              ? calculateZoomTransform(zoom.zoomLevel, zoom.xPos, zoom.yPos)
              : undefined,
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
}
