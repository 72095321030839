import {
  Typography,
  InputLabel,
  Button,
  ButtonGroup,
  Divider,
  Card,
} from "@mui/material";
import { Formik, Form, FormikHelpers } from "formik";
import { ChangeEvent, memo } from "react";
import * as yup from "yup";

import { useBreakpoints } from "@/util/useBreakpoints";

import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { StyledInputWithLabel } from "@/components/Styled/StyledInputWithLabel";
import { useDialog } from "@/components/shared/Dialog";

import { useSetupIntegrationMutation } from "@/generated-models";

import IntegrationsDeviceDatagrid from "../Core/IntegrationsDeviceDatagrid";
import { CFG_FAILED_MSG, CFG_SUCCESS_MSG } from "../constant";
import { INTEGRATION_SETUP_STATE_QUERY } from "../hooks";
import IntegrationsLinkCamerasDialog, {
  IntegrationsLinkCamerasList,
} from "./IntegrationsIntercomLinkCamerasDialog";
import { IntegrationsDeviceTagsButton } from "./IntegrationsIntercomTagsButton";

interface IntegrationsIntercomVendorInputsFormProps {
  id: number;
  schema?: any;
  setupState?: any;
  className?: string;
  onConnectionResult?: (success: boolean) => void;
  editMode?: boolean;
  initialState?: any;
  callback?: () => void;
  onBack?: () => void;
}

const validationSchema = yup
  .object()
  .shape({
    name: yup.string().min(5).required("Name is required"),
    ip: yup.string().min(5).required("IP is required"),
    port: yup
      .number()
      .max(65535, "Port must be at most 65535")
      .required("Port is required"),
    userId: yup.string().required("Sip user id is required"),
    cameraIds: yup
      .array()
      .of(yup.number())
      .min(1, "At least one camera is required")
      .required("At least one camera is required"),
    tags: yup.array().of(yup.string()),
  })
  .transform((originalValue, transformedValue) => {
    const { port } = transformedValue;
    if (typeof port === "string") {
      transformedValue.port = parseInt(port, 10);
    }
    return transformedValue;
  });

export interface IntegrationData {
  ip?: string;
  name?: string;
  userId?: string;
  port?: string;
  cameraIds?: Array<number>;
  tags?: Array<string>;
}

interface IntegrationDataErrors {
  ip?: string;
  name?: string;
  userId?: string;
  port?: string;
  cameraIds?: string;
  tags?: string;
}

interface AddDeviceProps {
  integrationData: IntegrationData;
  setIntegrationData: (integrationData: IntegrationData) => void;
  errors: IntegrationDataErrors;
}

function AddDevice({
  integrationData,
  setIntegrationData,
  errors,
}: AddDeviceProps) {
  const { open, opened, cancel } = useDialog();
  const cameraLinkCount = integrationData.cameraIds?.length || 0;
  const { fitsDesktop } = useBreakpoints();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIntegrationData({ [event.target.name]: event.target.value });
  };

  return (
    <>
      <IntegrationsLinkCamerasDialog
        open={opened}
        onClose={cancel}
        integrationData={integrationData}
        setIntegrationData={setIntegrationData}
      />

      <Card
        classes={{
          root:
            "px-4 py-4 bg-[#FBFBFB] border-t border-solid border-[#E0E0E0] mb-4",
        }}
      >
        <div className="flex sm:flex-row flex-col gap-5 w-full">
          <div className="flex-1 flex flex-col gap-4">
            <div className="w-full">
              <StyledInputWithLabel
                id="name"
                title="Device Name"
                fullWidth
                value={integrationData.name}
                onChange={(event) => handleChange(event)}
              />
            </div>
            <div className="w-full">
              <StyledInputWithLabel
                id="ip"
                title="IP Address"
                fullWidth
                value={integrationData.ip}
                onChange={(event) => handleChange(event)}
              />
            </div>
            <div className="w-full">
              <StyledInputWithLabel
                id="port"
                title="Port"
                fullWidth
                value={integrationData.port}
                onChange={(event) => handleChange(event)}
              />
            </div>
            <div className="w-full">
              <StyledInputWithLabel
                id="userId"
                title="SIP User ID"
                fullWidth
                value={integrationData.userId}
                onChange={(event) => handleChange(event)}
              />
            </div>
            {Object.keys(errors).length > 0 && (
              <div className="font-bold">{Object.values(errors)[0]}</div>
            )}
          </div>
          {fitsDesktop && (
            <Divider className="h-[400px] w-2" orientation="vertical" />
          )}
          <div className="flex-1 flex flex-col gap-2">
            <Typography className="text-xs leading-[14px] opacity-70">
              Linked Cameras
            </Typography>
            <Divider orientation="horizontal" />
            {cameraLinkCount === 0 && (
              <Typography className="text-sm leading-[16.41px]">
                Add cameras to this device to use the intercom while viewing
                live camera feeds.
              </Typography>
            )}
            <IntegrationsLinkCamerasList
              integrationData={integrationData}
              setIntegrationData={setIntegrationData}
            />
            <div>
              <Button
                color="primary"
                className="font-bold py-0 -ml-2"
                onClick={open}
              >
                + Link Cameras
              </Button>
            </div>
            <Divider className="w-full h-2" orientation="horizontal" />
            <div>
              <InputLabel shrink htmlFor="Tags">
                Tags
              </InputLabel>
              <div className="-mt-2">
                <IntegrationsDeviceTagsButton
                  className="h-8"
                  integrationData={integrationData}
                  setIntegrationData={setIntegrationData}
                  disableIconButton
                />
              </div>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
}

function IntegrationIntercomVendorFormComponent({
  id,
  setupState,
  className,
  onBack,
  editMode,
}: IntegrationsIntercomVendorInputsFormProps) {
  const initialValues: IntegrationData = {
    ip: setupState?.ip ?? "",
    name: setupState?.name ?? "",
    port: setupState?.port ?? "",
    userId: setupState?.userId ?? "",
    cameraIds: setupState?.cameraIds ?? [],
    tags: setupState?.tags ?? [],
  };

  const { pushSnackbar } = useFeedback();

  const [setupIntegration, { loading }] = useSetupIntegrationMutation({
    refetchQueries: [
      { query: INTEGRATION_SETUP_STATE_QUERY, variables: { input: { id } } },
      "integration",
      "integrationSources",
    ],
  });

  const handleSubmit = async (
    values: IntegrationData,
    { setSubmitting, resetForm }: FormikHelpers<IntegrationData>
  ) => {
    try {
      await setupIntegration({
        variables: {
          input: {
            integrationId: id,
            setupState: {
              ...values,
              port: +(values.port || 0),
            },
          },
        },
      });
      pushSnackbar(CFG_SUCCESS_MSG, FeedbackType.Success);
      resetForm({ values: initialValues });
    } catch (error: any) {
      pushSnackbar(error?.message || CFG_FAILED_MSG, FeedbackType.Error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className={className}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnMount={false}
      >
        {({ values, setFieldValue, isSubmitting, isValid, dirty, errors }) => {
          const setIntegrationData = (updateValues: IntegrationData) => {
            (Object.keys(updateValues) as Array<keyof IntegrationData>).forEach(
              (key) => {
                setFieldValue(key, updateValues[key]);
              }
            );
          };

          return (
            <Form>
              <div className="spotSlotForm">
                <div className="flex flex-col gap-4">
                  <AddDevice
                    setIntegrationData={setIntegrationData}
                    integrationData={values}
                    errors={errors}
                  />
                </div>
              </div>
              <ButtonGroup className="mt-7 flex gap-2">
                {!editMode && (
                  <Button
                    onClick={onBack}
                    variant="contained"
                    className="shadow-none bg-[#DAEEFF] text-[#007CE4] text-sm leading-6 font-normal h-9 rounded-[6px]"
                  >
                    Back
                  </Button>
                )}
                <Button
                  type="submit"
                  className="shadow-none font-normal text-sm leading-6 rounded-lg h-9 px-10 w-auto"
                  variant="contained"
                  color="primary"
                  disabled={!isValid || !dirty || isSubmitting || loading}
                >
                  Add device
                </Button>
              </ButtonGroup>
              <div className="mt-8">
                <IntegrationsDeviceDatagrid mode="create" hideWhenEmpty />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export const IntegrationIntercomVendorForm = memo(
  IntegrationIntercomVendorFormComponent
);
