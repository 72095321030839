import gql from "graphql-tag";
import { useParams } from "react-router-dom";

import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";

import { refetchOnMountPolicy } from "@/apolloClient";
import { useGetSharedCaseQuery } from "@/generated-models";
import { GuestCaseLayout, GuestErrorLayout } from "@/layout/GuestCaseLayout";

import { CaseInner } from "./Case";
import { SHARED_CASE_FRAGMENT } from "./caseFragments";

export function GuestCaseView() {
  const { token } = useParams();

  const caseQuery = useGetSharedCaseQuery({
    variables: { token: token ?? "" },
    skip: !token,
    ...refetchOnMountPolicy,
  });

  const { data, loading, error } = caseQuery;

  if (error) {
    return (
      <GuestErrorLayout>
        <ErrorMessage
          title="Oops"
          description="We could not find the shared case. Possibly this sharing link has been revoked."
        />
      </GuestErrorLayout>
    );
  }
  if (loading || !data) {
    return (
      <Loading grow className="h-full">
        Loading case...
      </Loading>
    );
  }

  return (
    <GuestCaseLayout
      anonymousCase={
        <div style={{ gridArea: "sharedCase" }} className="min-w-0 pb-4 grow">
          <CaseInner caseQuery={caseQuery} anonymous={true} />
        </div>
      }
    />
  );
}

gql`
  query getSharedCase($token: String!) {
    case: sharedCase(token: $token) {
      ...SharedCaseBase
    }
  }
  ${SHARED_CASE_FRAGMENT}
`;
