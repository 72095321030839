import { Typography } from "@mui/material";
import { Field } from "formik";

import { required } from "@/util/form";

import { InterestListType } from "@/generated-models";

import {
  InterestListTextInput,
  InterestListTextInputLabel,
  InterestListUploadCSVInput,
} from ".";
import { interestListConfig } from "../constants";
import { InterestListDetailsSummaryTable } from "./InterestListDetailsSummaryTable";
import { InterestListDialogSingleItemInput } from "./InterestListDialogSingleItemInput";

function InterestListBaseForm({ type }: { type: InterestListType }) {
  const config = interestListConfig[type];

  return (
    <div className="flex flex-col gap-6">
      <Typography className="text-sm leading-[16.41px]">
        {config.createPrompt}
      </Typography>
      <div>
        <InterestListTextInputLabel label="List Title" htmlFor="name" />
        <Field
          component={InterestListTextInput}
          name="name"
          placeholder="Enter list title"
          validate={required}
        />
      </div>
      <InterestListDialogSingleItemInput type={type} />
      <InterestListUploadCSVInput />
    </div>
  );
}

export function InterestListDetailsForm({ type }: { type: InterestListType }) {
  return (
    <div className="flex flex-col sm:flex-row items-center justify-between gap-10">
      <InterestListBaseForm type={type} />
      <InterestListDetailsSummaryTable type={type} />
    </div>
  );
}
