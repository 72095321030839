import AddCircleIcon from "@mui/icons-material/AddCircle";
import FilterListIcon from "@mui/icons-material/FilterList";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  Container,
  Divider,
  Hidden,
  IconButton,
  Link as MaterialLink,
  MenuItem,
  Paper,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import gql from "graphql-tag";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useCallback, useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import {
  ArrayParam,
  NumberParam,
  StringParam,
  useQueryParam,
} from "use-query-params";

import { useBreakpoints } from "@/util/useBreakpoints";
import { useDocumentTitle } from "@/util/useDocumentTitle";

import { InternalAlertUserSelect } from "@/pages/Alerts/InternalAlertUserSelect";
import { alertTypesConfig } from "@/pages/Alerts/constants";

import { useMe } from "@/components/Auth";
import { ErrorMessage } from "@/components/ErrorMessage";
import { MobileFilterActionBar } from "@/components/Filter/Mobile/MobileFilterActionBar";
import { MobileFilterModal } from "@/components/Filter/Mobile/MobileFilterModal";
import { MobileFilterSearchBar } from "@/components/Filter/Mobile/MobileFilterSearchBar";
import { MobileSearchModal } from "@/components/Filter/Mobile/MobileSearchModal";
import { Loading } from "@/components/Loading";
import { SearchBox } from "@/components/SearchBox";
import {
  DataGridContextualPlayer,
  useDataGridContextPlayer,
} from "@/components/VideoWall/ContextualVideoPlayer";
import { DefaultDialog, useDialog } from "@/components/shared/Dialog";
import { QueryParamLink } from "@/components/shared/QueryParamLink";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  AlertType,
  GetNewNotificationsCountDocument,
  Page_AlertsDocument,
  Page_AlertsQuery,
  Role,
  useDeleteAllNotificationsMutation,
  useGetAlertsQuery,
  useGetNewNotificationsCountQuery,
  useGetNotificationsFiltersQuery,
  usePage_AlertsLazyQuery,
  usePage_AlertsQuery,
} from "@/generated-models";
import { ContentWrapper } from "@/layout/ContentWrapper";
import { MobileHeader } from "@/layout/MobileHeader";

import { AlertNotification } from "./AlertNotification";
import { AlertsSettingsEdit } from "./AlertsSettingsEdit";
import { useAlertRoutes, useFilterEnabledAlerts } from "./utils";

const useStyles = makeStyles()((theme) => ({
  notification: {
    display: "flex",
    padding: 10,
    marginBottom: 8,
  },
  infoItem: {
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    lineHeight: 1,
    marginTop: 7,
    [theme.breakpoints.up("sm")]: {
      marginLeft: 22,
    },
    "& svg": { color: "#c4c4c4", fontSize: 16, marginRight: 8 },
  },
  emptyState: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 48,
  },
}));

interface FilterOptions {
  name: string;
  filterName: string;
  options: {
    label: string;
    value: string;
  }[];
}

export function AlertNotifications() {
  useDocumentTitle("Alerts");
  const alertRoutes = useAlertRoutes();
  const { classes } = useStyles();
  const { fitsTablet, fitsDesktop } = useBreakpoints();
  const [selectedAlertType, setSelectedAlertType] = useState("all");
  const [mobileModal, setMobileModal] = useState(false);
  const [searchFilter, setSearchFilter] = useQueryParam("search", StringParam);
  const [alertIds] = useQueryParam("alertIds", ArrayParam);
  const [alertType, setAlertType] = useQueryParam("alertType", ArrayParam);
  const [cameras] = useQueryParam("cameras", ArrayParam);
  const [locations] = useQueryParam("locations", ArrayParam);
  const [orgUserId] = useQueryParam("ouid", NumberParam);
  const { contextualPlayer } = useFlags();
  // query based notifications
  // supports AlertId, startTime, EndTime
  const [alertId] = useQueryParam("alertId", NumberParam);
  const [startTime] = useQueryParam("startTime", StringParam);
  const [endTime] = useQueryParam("endTime", StringParam);
  const { open, ...clearAlertsDialogProps } = useDialog();
  const [filterEl, setFilterEl] = useState<null | HTMLElement>(null);
  const filterIsOpen = Boolean(filterEl);
  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFilterEl(event.currentTarget);
  };
  const handleClose = () => setFilterEl(null);

  const variables = {
    alertId,
    startTime,
    endTime,
    limit: 20,
    search: searchFilter || undefined, // filter empty string
    types: (alertType as AlertType[]) || undefined,
    alertIds: (alertIds as string[]) || undefined,
    cameras: (cameras as string[]) || undefined,
    locations: (locations as string[]) || undefined,
    orgUserId: orgUserId ?? undefined,
  };

  const {
    data: newNotifications,
    refetch: refetchCountBadge,
  } = useGetNewNotificationsCountQuery();
  const { data: alertsData } = useGetAlertsQuery(refetchOnMountPolicy);

  const { data, loading, error, fetchMore } = usePage_AlertsQuery({
    ...refetchOnMountPolicy,
    errorPolicy: "all",
    skip: !!searchFilter,
    variables,
    onCompleted: () => refetchCountBadge(),
  });

  const [
    getSearchResults,
    { data: fetchedSearchResults },
  ] = usePage_AlertsLazyQuery({
    errorPolicy: "all",
  });

  const newSearchResults = useCallback(() => {
    getSearchResults({
      variables: {
        alertId,
        startTime,
        endTime,
        limit: 20,
        search: searchFilter || undefined, // filter empty string
        types: (alertType as AlertType[]) || undefined,
        alertIds: (alertIds as string[]) || undefined,
        cameras: (cameras as string[]) || undefined,
        locations: (locations as string[]) || undefined,
        orgUserId: orgUserId ?? undefined,
      },
    });
  }, [
    getSearchResults,
    alertId,
    startTime,
    endTime,
    searchFilter,
    alertType,
    alertIds,
    cameras,
    locations,
    orgUserId,
  ]);

  useEffect(() => {
    newSearchResults();
  }, [searchFilter, newSearchResults]);

  const me = useMe();
  const { data: notificationFilters } = useGetNotificationsFiltersQuery();
  const alertsList = alertsData?.alerts;
  const newNotificationsCount = newNotifications?.notificationsCount ?? 0;
  const notificationsQuery = { query: Page_AlertsDocument, variables };
  const [deleteAll] = useDeleteAllNotificationsMutation({
    refetchQueries: [{ query: GetNewNotificationsCountDocument }],
  });

  const {
    open: contextualPlayerOpen,
    handleOpen: handleContextualPlayerOpen,
    handleClose: handleContextualPlayerClose,
    playerProps,
  } = useDataGridContextPlayer();

  // Only show empty state for non sales and lower users.
  const showEmptyState = !me || (me && me.role < Role.Sales);

  if (error && !data)
    return <ErrorMessage title="Error" description={error.message} />;
  if (alertsList?.length === 0 && showEmptyState) return <NoConfiguredAlerts />;

  let content: React.ReactNode = <Loading grow>Fetching Alerts</Loading>;

  const clearAlertsButton = (
    <>
      <Button
        color="primary"
        className={clsx({
          "font-normal": !fitsTablet,
        })}
        onClick={() => open()}
      >
        Clear All Alerts
      </Button>
      <DefaultDialog
        {...clearAlertsDialogProps}
        title="Clear All Alerts?"
        content={
          <>
            <Typography>
              Are you sure you want to clear all of your alerts? This cannot be
              undone
            </Typography>
          </>
        }
        cancelText="Cancel"
        confirmText="Clear All Alerts"
        confirm={() => {
          deleteAll({
            optimisticResponse: {
              __typename: "Mutation",
              deleteAllNotifications: {
                __typename: "DefaultPayload",
                message: "Done!",
              },
            },
            update: (store) => {
              store.writeQuery({
                ...notificationsQuery,
                data: { notificationsCount: 0, notifications: [] },
              });
            },
          });
          clearAlertsDialogProps.confirm();
        }}
      />
    </>
  );

  const changeAlertType = ({
    selectedAlertType,
  }: {
    selectedAlertType: AlertType | string;
  }) => {
    setSelectedAlertType(selectedAlertType);
    setAlertType(selectedAlertType === "all" ? undefined : [selectedAlertType]);
  };

  const AlertControls = () => {
    const filterEnabledAlerts = useFilterEnabledAlerts();
    return (
      <div className="flex justify-between items-center gap-2 pb-3 pt-3 md:pt-0 px-2 sm:px-0">
        {newNotificationsCount > 0 && (
          <Typography variant="h4">
            {`${newNotificationsCount} New Alerts`}
          </Typography>
        )}
        {newNotificationsCount === 0 &&
          alertNotifications &&
          alertNotifications?.notificationsCount > 0 && (
            <Typography variant="h4">
              {`${alertNotifications.notificationsCount} Alerts`}
            </Typography>
          )}

        <SearchBox
          input={searchFilter ?? ""}
          setInput={(value) => setSearchFilter(value || undefined)}
          className="ml-auto min-w-[240px]"
        />
        <Hidden smDown>
          <Select
            labelId="select-label"
            value={selectedAlertType}
            variant="outlined"
            size="small"
            onChange={(e) =>
              changeAlertType({ selectedAlertType: e.target.value ?? "all" })
            }
          >
            <MenuItem value="all">All Alert Types</MenuItem>
            {Object.entries(alertTypesConfig)
              .filter(([key]) => filterEnabledAlerts(key as AlertType))
              .map(([key, { typeName }]) => (
                <MenuItem key={key} value={key}>
                  {typeName}
                </MenuItem>
              ))}
            <MenuItem value={AlertType.Motion}>Motion Event</MenuItem>
          </Select>
        </Hidden>
        {fitsTablet && clearAlertsButton}
      </div>
    );
  };

  let notifications = [];
  const alertNotifications =
    (contextualPlayer && searchFilter) || orgUserId
      ? fetchedSearchResults
      : data;
  const filterCategories: FilterOptions[] | null = notificationFilters
    ? [
        {
          name: "Alert Type",
          filterName: "alertType",
          options:
            notificationFilters.alertFilters.alertType.map((alertTypeItem) => ({
              label: `${
                alertTypesConfig[alertTypeItem.type as AlertType].typeName
              }`,
              value: alertTypeItem.type,
            })) ?? [],
        },
        {
          name: "Alert Name",
          filterName: "alertIds",
          options:
            notificationFilters.alertFilters.alerts.map((alertTypeItem) => ({
              label: `${alertTypeItem.name}`,
              value: alertTypeItem.id.toString(),
            })) ?? [],
        },
        {
          name: "Locations",
          filterName: "locations",
          options:
            notificationFilters.alertFilters.locations.map((alertTypeItem) => ({
              label: `${alertTypeItem.name}`,
              value: alertTypeItem.id.toString(),
            })) ?? [],
        },
        {
          name: "Cameras",
          filterName: "cameras",
          options:
            notificationFilters.alertFilters.cameras.map((alertTypeItem) => ({
              label: `${alertTypeItem.name}`,
              value: alertTypeItem.id.toString(),
            })) ?? [],
        },
      ]
    : null;

  if (alertNotifications?.notifications) {
    notifications = alertNotifications.notifications;
    content = (
      <>
        {alertNotifications.notifications.length === 0 && (
          <div className={classes.emptyState}>
            <NotificationsIcon style={{ fontSize: 48 }} />
            <Typography variant="h3">No Alerts</Typography>
            <Typography className="w-[295px] text-center mt-3" variant="body2">
              Your alerts will show up here.{" "}
              <MaterialLink
                component={QueryParamLink}
                to={alertRoutes.ALERTS_CONFIGURE}
                underline="hover"
              >
                Edit Alerts
              </MaterialLink>{" "}
              to edit your alerts or create a new alert.
            </Typography>
          </div>
        )}

        {notifications.map((noti) => (
          <AlertNotification
            noti={noti}
            variables={variables}
            key={noti.id}
            handleContextualPlayerOpen={handleContextualPlayerOpen}
          />
        ))}

        {alertNotifications.notifications.length <
          alertNotifications.notificationsCount && (
          <div className="flex justify-center mt-6">
            <Button
              variant="contained"
              color="primary"
              size="large"
              disabled={loading}
              onClick={() =>
                fetchMore({
                  variables: {
                    cursor: Math.min(
                      ...alertNotifications.notifications.map((n) => n.id)
                    ),
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;
                    return {
                      ...prev,
                      notificationsCount: fetchMoreResult.notificationsCount,
                      notifications: [
                        ...prev.notifications,
                        ...fetchMoreResult.notifications,
                      ],
                    };
                  },
                })
              }
            >
              Show more
            </Button>
          </div>
        )}

        {!fitsDesktop && contextualPlayer && (
          <MobileSearchModal
            open={mobileModal}
            subject="Alert"
            total={alertNotifications?.notificationsCount}
            onBack={() => {
              setMobileModal(false);
            }}
          >
            <div className="grid md:grid-cols-1 gap-6 p-4">
              {notifications?.map((noti) => (
                <AlertNotification
                  noti={noti}
                  variables={variables}
                  key={noti.id}
                  handleContextualPlayerOpen={handleContextualPlayerOpen}
                />
              ))}
            </div>
          </MobileSearchModal>
        )}
      </>
    );
  }

  const MobileAlertControls = ({
    notificationsCount,
  }: {
    notificationsCount: number;
  }) => {
    return (
      <div className="pt-4">
        <div className="flex justify-center gap-3 px-4">
          <Button
            className="px-4 w-1/2"
            variant="outlined"
            color="primary"
            component={QueryParamLink}
            to={alertRoutes.ALERTS_CONFIGURE}
          >
            Edit Alerts
          </Button>
          <Button
            variant="outlined"
            className="px-4 w-1/2"
            color="primary"
            startIcon={<AddCircleIcon />}
            component={QueryParamLink}
            to={alertRoutes.ALERTS_CREATE}
          >
            Create Alert
          </Button>
          <AlertsSettingsEdit />
        </div>
        <div className="flex justify-between items-center mt-4 px-4">
          {notificationsCount > 0 && (
            <div className="flex gap-1">
              <div>{notificationsCount} Alerts</div>
              {newNotificationsCount > 0 && (
                <>
                  <Divider
                    className="border-r-2"
                    orientation="vertical"
                    flexItem
                  />
                  <strong>{newNotificationsCount} New</strong>
                </>
              )}
            </div>
          )}
          <div className="grow" />
          <div className="flex gap-4 items-center">
            {!searchFilter && (
              <Tooltip title="Search">
                <IconButton
                  className="bg-[#ddefff]"
                  size="small"
                  color="primary"
                  onClick={() => {
                    setMobileModal(true);
                  }}
                >
                  <SearchIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Filter">
              <IconButton
                className="bg-[#ddefff]"
                size="small"
                color="primary"
                onClick={handleFilterClick}
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        {searchFilter && (
          <MobileFilterSearchBar
            onClick={() => setMobileModal(true)}
            className="mt-4"
          />
        )}
        <MobileFilterActionBar
          className="mt-2"
          dynamic
          categories={filterCategories ?? []}
          onFilterClick={(e) => {
            handleFilterClick(e);
          }}
        />
      </div>
    );
  };

  return (
    <ContentWrapper>
      <Container maxWidth="md" className="p-0">
        {fitsTablet ? (
          <Paper elevation={4}>
            <div className="flex justify-between items-center p-4">
              <Typography variant="h1">Alerts</Typography>
              <div className="flex gap-2">
                <Button
                  variant="outlined"
                  color="primary"
                  component={QueryParamLink}
                  to={alertRoutes.ALERTS_CONFIGURE}
                >
                  Edit Alerts
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<AddCircleIcon />}
                  component={QueryParamLink}
                  to={alertRoutes.ALERTS_CREATE}
                >
                  Create Alert
                </Button>
                <AlertsSettingsEdit />
              </div>
            </div>

            <InternalAlertUserSelect />

            <div className="p-4">
              <AlertControls />
              {content}
            </div>
          </Paper>
        ) : (
          <>
            <MobileHeader
              label="Alerts"
              action={clearAlertsButton}
              show
              showBackButton={false}
            />
            {contextualPlayer ? (
              <MobileAlertControls
                notificationsCount={
                  searchFilter
                    ? notifications.length
                    : alertNotifications?.notificationsCount ?? 0
                }
              />
            ) : (
              <AlertControls />
            )}
            <div className="p-4">{content}</div>
            <MobileFilterModal
              count={notifications?.length || 0}
              open={filterIsOpen}
              subject="Alerts"
              onBack={() => {
                handleClose();
              }}
              categories={filterCategories ?? []}
            />
          </>
        )}
        <DataGridContextualPlayer
          open={contextualPlayerOpen}
          handleClose={handleContextualPlayerClose}
          playerProps={playerProps}
        />
      </Container>
    </ContentWrapper>
  );
}

export type NotificationType = Page_AlertsQuery["notifications"][number];

function NoConfiguredAlerts() {
  const alertRoutes = useAlertRoutes();
  return (
    <div className="flex flex-col gap-y-3 items-center justify-center p-12 flex-1">
      <NotificationsIcon className="text-primary text-[66px]" />
      <Typography
        className="text-primary text-[44px] leading-[50x] font-light"
        variant="h1"
      >
        Welcome to Alerts
      </Typography>
      <Typography
        className="w-[345px] text-center text-text leading-4"
        variant="body2"
      >
        We will watch your cameras while you are away for motion events and
        cameras going down. Create an alert to start getting notifications.
      </Typography>
      <Button
        className="mt-5"
        color="primary"
        variant="outlined"
        component={QueryParamLink}
        to={alertRoutes.ALERTS_CREATE}
      >
        Create a New Alert
      </Button>
    </div>
  );
}

gql`
  fragment NotificationCamera on Camera {
    id
    name
    status
    firstSegmentTime
    location {
      id
      name
      timezone
    }
    health {
      cameraOnline
      applianceOnline
    }
    settings {
      audioControlEnabled
    }
  }
`;

gql`
  query page_alerts(
    $alertId: Int
    $startTime: String
    $endTime: String
    $cursor: Int
    $limit: Int
    $type: AlertType
    $search: String
    $types: [AlertType!]
    $alertIds: [String!]
    $cameras: [String!]
    $locations: [String!]
    $orgUserId: Int
  ) {
    notificationsCount(
      alertId: $alertId
      startTime: $startTime
      endTime: $endTime
      search: $search
      type: $type
      types: $types
      alertIds: $alertIds
      cameras: $cameras
      locations: $locations
      orgUserId: $orgUserId
    )
    notifications(
      alertId: $alertId
      startTime: $startTime
      endTime: $endTime
      cursor: $cursor
      limit: $limit
      search: $search
      type: $type
      types: $types
      alertIds: $alertIds
      cameras: $cameras
      locations: $locations
      orgUserId: $orgUserId
    ) {
      id
      timestamp
      still
      isSeen
      message
      vod {
        startTime
        endTime
        feeds {
          tunnel
        }
      }
      alert {
        id
        name
        type
        customText
      }
      camera {
        ...NotificationCamera
      }
      integration {
        integrationEventType {
          name
          integrationType {
            key
          }
        }
        integrationVendor {
          key
        }
        integrationSource {
          id
          integrationId
          standardMeta
          externalId
          cameras {
            ...NotificationCamera
          }
        }
      }
    }
  }
`;

gql`
  query getNotificationsFilters {
    alertFilters {
      alertType {
        type
        count
      }
      alerts {
        id
        name
        count
      }
      cameras {
        id
        name
        count
      }
      locations {
        id
        name
        count
      }
    }
  }
`;

gql`
  mutation seenNotification($id: Int!) {
    seenNotification(id: $id) {
      message
    }
  }
`;

gql`
  mutation deleteNotification($id: Int!) {
    deleteNotification(id: $id) {
      message
    }
  }
`;

gql`
  mutation deleteAllNotifications {
    deleteAllNotifications {
      message
    }
  }
`;
