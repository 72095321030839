import gql from "graphql-tag";
import { capitalize } from "lodash/fp";
import { useEffect } from "react";

import { formattedAlertTypes } from "@/pages/Alerts/constants";

import { useMe } from "@/components/Auth";
import { useFeedback } from "@/components/SnackbarProvider";

import {
  useNew_AlertsQuery,
  AlertSource,
  New_AlertsQuery,
  useSeenNotificationMutation,
} from "@/generated-models";

import { AlertSnackbarActions } from "./AlertSnackbarActions";
import { AudibleAlert } from "./AudibleAlert";

type Notification = New_AlertsQuery["notifications"][0];

async function playAudio() {
  try {
    await new Audio("/alert-audio.mp3").play();
  } catch (e) {
    console.log("Failed to play alert sound", e);
  }
}

export function AlertsManager() {
  const { pushSnackbar } = useFeedback();
  const me = useMe();
  const [setSeenNotification] = useSeenNotificationMutation();

  const flags = me?.profile?.flags;
  // TODO: Ava suggested adding only camera disconnects as an option.
  // TODO: We only want to have those values set as true on for GDF.
  // Make them off by default once the GDF has them set in the database.
  const realTimeAlerts = Boolean(flags?.realTimeAlerts) ?? true;
  const audioAlerts = Boolean(flags?.audioAlerts) ?? true;

  const { data } = useNew_AlertsQuery({
    pollInterval: 10000,
    variables: {
      limit: 1,
      isSeen: false,
      orgUserId: me?.orgUserId,
    },
    onCompleted: (res) => {
      const seenNotificationId = res?.notifications[0]?.id;
      if (seenNotificationId) {
        setSeenNotification({
          variables: {
            id: seenNotificationId,
          },
        });
      }
    },
  });

  const notificationsData = data?.notifications?.[0] || ({} as Notification);
  const { id } = notificationsData;

  useEffect(() => {
    if (id) {
      const alertMessage = constructAlertMessage(notificationsData);

      pushSnackbar(
        <AudibleAlert alertMessage={alertMessage} />,
        undefined,
        (handleClose) => <AlertSnackbarActions handleClose={handleClose} />,
        10000
      );

      if (audioAlerts) {
        playAudio();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, realTimeAlerts, playAudio]);

  return null;
}

function constructAlertMessage(notification: Notification): string {
  const { alert, camera, integration, timestamp } = notification;
  const formattedTimestamp = new Date(timestamp)?.toTimeString();
  const formattedAlertType = formattedAlertTypes[alert?.type]?.toLowerCase();

  switch (alert.source) {
    case AlertSource.Camera:
      return getCameraAlertMessage(
        camera,
        formattedTimestamp,
        formattedAlertType
      );
    case AlertSource.Integration:
      return getIntegrationAlertMessage(
        integration,
        formattedTimestamp,
        formattedAlertType
      );
  }
}

function getCameraAlertMessage(
  camera: Notification["camera"],
  formattedTimeStamp: string,
  formattedAlertType: string
): string {
  const { name, location } = camera || {};
  const { name: locationName } = location || {};

  return (
    `${capitalize(formattedAlertType)} detected on ` +
    `${name} at ${locationName} at ${formattedTimeStamp}.`
  );
}

function getIntegrationAlertMessage(
  integration: Notification["integration"],
  formattedTimeStamp: string,
  formattedAlertType: string
): string {
  const { integrationSource, integrationEventType } = integration || {};
  const { cameras, standardMeta } = integrationSource || {};
  const { name, siteName } = standardMeta || {};
  const { name: eventName = "" } = integrationEventType || {};
  const { location: cameraLocation } = cameras?.[0] || {};
  const location = siteName ?? cameraLocation?.name ?? "Unknown";

  return (
    `${capitalize(eventName)} event detected ` +
    `on ${formattedAlertType} device, ${name} at ${location} ` +
    `at ${formattedTimeStamp}.`
  );
}

gql`
  query new_alerts(
    $isSeen: Boolean
    $limit: Int
    $profileId: Int
    $orgUserId: Int
  ) {
    notifications(
      isSeen: $isSeen
      limit: $limit
      profileId: $profileId
      orgUserId: $orgUserId
    ) {
      id
      timestamp
      alert {
        name
        source
        type
      }
      camera {
        name
        location {
          name
        }
      }
      integration {
        integrationSource {
          cameras {
            name
            location {
              name
            }
          }
          standardMeta
        }
        integrationEventType {
          name
        }
      }
    }
  }
`;
