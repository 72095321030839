import CloseIcon from "@mui/icons-material/Close";
import ShareIcon from "@mui/icons-material/Share";
import { Box, Button, IconButton } from "@mui/material";
import { addDays, startOfDay } from "date-fns/fp";
import gql from "graphql-tag";
import React from "react";

import { generateExternalLink, generateLiveShareUrl } from "@/util/camera";

import { isDemoUser, useMe } from "@/components/Auth";
import { DisabledShareTrayItem } from "@/components/Player/DisabledShareTrayItem";
import { ShareTrayItem } from "@/components/Player/ShareTrayItem";
import {
  determineLinkSharingActive,
  SharingLinkDialog,
} from "@/components/Player/SharingLinkDialog";
import { useFeedback } from "@/components/SnackbarProvider";
import { LiveStatusIndicator } from "@/components/StatusIndicators";
import { useDialog } from "@/components/shared/Dialog";

import {
  CameraStatus,
  useCamShareInfoQuery,
  useSendSharedCameraEmailMutation,
  useSendSharedCameraTextMutation,
  useUpdateCameraSharingInfoMutation,
} from "@/generated-models";
import { usePrefixOrgSlug } from "@/hooks/useOrgRouteBase";
import { usePermissions } from "@/hooks/usePermissions";

interface ShareCameraButtonProps {
  cameraId: number;
  iconButton?: boolean;
  whiteIcon?: boolean;
}

const today = startOfDay(new Date());
const nextWeek = addDays(7, today);

export const CAM_SHARE_INFO = gql`
  query camShareInfo($cameraId: Int!) {
    camera(id: $cameraId) {
      id
      name
      location {
        id
        name
        timezone
      }
      sharing {
        id
        shareToken
        expiry
        description
      }
    }
  }
`;

export const ShareCameraButton = React.memo(function ShareCameraButton({
  cameraId,
  iconButton = false,
  whiteIcon = false,
}: ShareCameraButtonProps) {
  const prefixOrgSlug = usePrefixOrgSlug();
  const me = useMe();
  const hasPermission = usePermissions();
  const isDemo = !!me && isDemoUser(me); // only relevant for demo orgs
  const hasSharingPermission = !isDemo && hasPermission("video_share");
  const [
    updateSharingInfo,
    { loading: updatingSharingInfo },
  ] = useUpdateCameraSharingInfoMutation();

  const { data } = useCamShareInfoQuery({
    variables: { cameraId },
  });

  const dialogProps = useDialog();
  const { pushSnackbar } = useFeedback();

  const [sendSharedCameraEmail] = useSendSharedCameraEmailMutation();
  const [sendSharedCameraText] = useSendSharedCameraTextMutation();

  function disable() {
    const camera = data?.camera;
    if (!camera) return;
    updateSharingInfo({
      variables: {
        id: cameraId,
        updates: {
          expiry: today.toISOString(),
        },
      },
      optimisticResponse: {
        __typename: "Mutation",
        updateCameraSharingInfo: {
          ...camera,
          sharing: {
            __typename: "CameraSharing",
            id: cameraId,
            description: "",
            expiry: today.toISOString(),
          },
        },
      },
    });
  }

  async function openDialog() {
    if (!determineLinkSharingActive(data?.camera.sharing?.expiry)) {
      const updates = {
        expiry: nextWeek.toISOString(),
        // deliberately: clear the description when activating sharing
        description: "",
      };
      updateSharingInfo({
        variables: {
          id: cameraId,
          updates,
        },
      });
    }
    const active = await dialogProps.open();

    if (active) {
      pushSnackbar(
        "Live Camera Sharing Activated",
        undefined,
        (handleClose) => (
          <>
            <Button
              size="small"
              color="primary"
              onClick={() => {
                disable();
                handleClose();
              }}
            >
              UNDO
            </Button>
            <IconButton onClick={handleClose} color="inherit" size="large">
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        )
      );
    }
  }

  if (!hasSharingPermission) {
    if (iconButton) return null;
    return (
      <DisabledShareTrayItem
        icon={<ShareIcon className="text-inherit" />}
        label="Share"
        tooltipTitle="Ask your administrator for sharing permission"
      />
    );
  }

  const linkActive = determineLinkSharingActive(data?.camera.sharing?.expiry);

  return (
    <>
      {iconButton ? (
        <IconButton
          className={linkActive && !whiteIcon ? "text-primary" : "text-white"}
          onClick={openDialog}
          aria-label="share"
          size="large"
        >
          <ShareIcon />
        </IconButton>
      ) : (
        <ShareTrayItem
          className={linkActive && !whiteIcon ? "text-primary" : "text-white"}
          onClick={openDialog}
          aria-label="share"
          icon={<ShareIcon className="text-inherit" />}
          label="Share"
        />
      )}

      {dialogProps.opened && (
        <SharingLinkDialog
          {...dialogProps}
          submit={async (description: string, expiry: Date | null) => {
            await updateSharingInfo({
              variables: {
                id: cameraId,
                updates: {
                  description,
                  expiry: expiry?.toISOString(),
                },
              },
            });
          }}
          submitting={updatingSharingInfo}
          sharedLink={
            data?.camera.sharing && {
              expiry: data.camera.sharing.expiry,
              description: data.camera.sharing.description ?? "",
            }
          }
          link={
            data?.camera?.sharing?.shareToken
              ? generateExternalLink(
                  prefixOrgSlug(
                    generateLiveShareUrl(data.camera.sharing.shareToken)
                  )
                )
              : null
          }
          title={
            <>
              <LiveStatusIndicator status={CameraStatus.Online} />
              <Box width="4px" />
              Share Live Camera
            </>
          }
          cameraName={data?.camera.name}
          expirationLabel="Link Expiration"
          linkLabel="Live Camera Link"
          disableImmediately={disable}
          sendEmail={async (email) => {
            await sendSharedCameraEmail({
              variables: {
                id: data!.camera!.id,
                email,
              },
            });
          }}
          sendText={async (phoneNumber) => {
            await sendSharedCameraText({
              variables: {
                id: data!.camera!.id,
                phoneNumber,
              },
            });
          }}
        />
      )}
    </>
  );
});

export const UPDATE_CAMERA_SHARING_INFO = gql`
  mutation updateCameraSharingInfo($id: Int!, $updates: CameraSharingInput!) {
    updateCameraSharingInfo(id: $id, updates: $updates) {
      id
      sharing {
        id
        description
        expiry
      }
    }
  }
`;

export const SEND_SHARED_CAMERA_EMAIL = gql`
  mutation sendSharedCameraEmail($id: Int!, $email: String!) {
    sendSharedCameraEmail(id: $id, email: $email)
  }
`;

export const SEND_SHARED_CAMERA_TEXT = gql`
  mutation sendSharedCameraText($id: Int!, $phoneNumber: String!) {
    sendSharedCameraText(id: $id, phoneNumber: $phoneNumber)
  }
`;
