import { DateRange, Edit, LocationOn, Videocam } from "@mui/icons-material";
import { Button, Divider } from "@mui/material";
import clsx from "clsx";
import { utcToZonedTime } from "date-fns-tz";
import { format } from "date-fns/fp";
import { useFlags } from "launchdarkly-react-client-sdk";
import { uniqBy, uniq } from "lodash/fp";
import { ReactNode, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { formatTimeString, getShorthandDayRange } from "@/util/date";
import { filterNullish } from "@/util/filterFalsy";
import { useBreakpoints } from "@/util/useBreakpoints";

import { useSearchRangeParams } from "@/pages/Search/searchHooks";

import { FilterDateField } from "@/components/Filter/Fields/FilterDateField";
import { FilterBar } from "@/components/Filter/FilterBar";
import { FilterBarControls } from "@/components/Filter/FilterBarControls";
import { useTransparentFilterFieldStyles } from "@/components/Filter/constant";
import { useActiveFilter } from "@/components/Filter/utils";

import { IntelligenceDashboardQuery } from "@/generated-models";

import { IntelligenceDashboardViewExportButton } from "../../Export/IntelligenceDashboardViewExportButton";
import { Color, VehicleType } from "../constants";
import { DEFAULT_TIME_RANGE, useLprReportGrouped } from "./hooks";

interface LicensePlateReportFilterBarProps {
  dashboard: IntelligenceDashboardQuery["intelligenceDashboard"];
}

function LicensePlateSettingInfoItem({
  Icon,
  value,
}: {
  Icon: ReactNode;
  value: string;
}) {
  return (
    <span className="text-[#757575] flex items-center gap-1">
      {Icon}
      <span>{value}</span>
    </span>
  );
}

function LicensePlateReportFilterBarSettings({
  className,
  dashboard,
}: LicensePlateReportFilterBarProps & { className?: string }) {
  const navigate = useNavigate();
  const { rangeStart, rangeEnd } = useSearchRangeParams();
  const { id, cameras, startTime, endTime, daysOfWeek } = dashboard!;
  const { fitsDesktop } = useBreakpoints();
  const { loading } = useLprReportGrouped();

  return (
    <div className={clsx("flex items-center justify-between", className)}>
      <div className="flex sm:flex-row flex-col items-start sm:items-center gap-2 sm:gap-5">
        <LicensePlateSettingInfoItem
          Icon={<LocationOn />}
          value={cameras.map((cam) => cam.location.name).join(",")}
        />
        <LicensePlateSettingInfoItem
          Icon={<Videocam />}
          value={cameras.map((cam) => cam.name).join(",")}
        />
        <LicensePlateSettingInfoItem
          Icon={<DateRange />}
          value={`${getShorthandDayRange(daysOfWeek)}, ${formatTimeString(
            startTime
          )} - ${formatTimeString(endTime)}`}
        />
        <div className="-ml-2 hidden sm:flex items-center gap-2">
          <Divider orientation="vertical" className="h-3" />
          <Button
            size="small"
            startIcon={<Edit />}
            color="primary"
            onClick={() => {
              navigate(`../edit/${id}`);
            }}
          >
            Edit
          </Button>
        </div>
      </div>
      {!fitsDesktop && (
        <div className="flex flex-col justify-between gap-3">
          <Button
            className="bg-white"
            variant="outlined"
            color="primary"
            onClick={() => {
              navigate(`../edit/${id}`);
            }}
          >
            Edit
          </Button>
          <IntelligenceDashboardViewExportButton
            variant="outlined"
            dashboard={dashboard!}
            disabled={loading}
            startDate={rangeStart}
            endDate={rangeEnd}
          />
        </div>
      )}
    </div>
  );
}

export function LicensePlateReportFilterBar({
  dashboard,
}: LicensePlateReportFilterBarProps) {
  const { classes } = useTransparentFilterFieldStyles();
  const { lprMmct } = useFlags();
  const { fitsDesktop } = useBreakpoints();
  const { data: groupedReport, loading } = useLprReportGrouped();

  const lprCameras = useMemo(
    () =>
      (groupedReport?.lprReport || [])
        .flatMap((d) => d.cameras)
        .filter(filterNullish),
    [groupedReport]
  );

  const sortedLocations = useMemo(() => {
    return uniq(lprCameras.map((cam) => cam.location).flat()).sort((a, b) =>
      a.name.localeCompare(b.name)
    );
  }, [lprCameras]);

  const filters = useMemo(() => {
    const timezone = lprCameras[0]?.location?.timezone || "";
    return [
      {
        placeholder: "Dates & Times",
        filterName: "datetime",
        label: "Date & Time",
        classes,
        timezone,
        style: {},
        getValue: (value: string | null) => {
          return (
            value
              ?.split("|")
              .map((vi) =>
                format("MMM d, yyyy p")(utcToZonedTime(new Date(vi), timezone))
              )
              .join(" - ") || ""
          );
        },
        options: [],
        date: true,
        defaultStartRange: DEFAULT_TIME_RANGE,
      },
      {
        placeholder: "Locations & Groups",
        label: "Locations & Groups",
        classes,
        style: {},
        filterName: "locations",
        options: uniqBy(
          "value",
          sortedLocations?.map((d) => ({
            label: d.name,
            value: String(d.id),
          })) || []
        ),
      },
      {
        placeholder: "Cameras",
        filterName: "cameras",
        label: "Cameras",
        classes,
        style: {},
        options: uniqBy(
          "value",
          lprCameras?.map((d) => ({
            label: d.name,
            value: String(d.id),
            secondaryLabel: d.location.name,
          })) || []
        ),
      },
      ...(lprMmct
        ? [
            {
              placeholder: "Makes",
              filterName: "make",
              label: "Makes",
              classes,
              style: {},
              options: uniqBy(
                "value",
                groupedReport.lprReport?.map((d) => ({
                  label: d.make,
                  value: d.make,
                })) || []
              ),
            },
            {
              placeholder: "Models",
              filterName: "model",
              label: "Models",
              classes,
              style: {},
              options: uniqBy(
                "value",
                groupedReport.lprReport?.map((d) => ({
                  label: d.model,
                  value: d.model,
                })) || []
              ),
            },
            {
              placeholder: "Colors",
              filterName: "color",
              label: "Colors",
              classes,
              style: {},
              options: uniqBy(
                "value",
                groupedReport.lprReport?.map((d) => ({
                  label: `${Color[d.colorId || 0]}`,
                  value: d.colorId,
                })) || []
              ),
            },
            {
              placeholder: "Type",
              filterName: "type",
              label: "Type",
              style: {},
              classes,
              options: uniqBy(
                "value",
                groupedReport.lprReport?.map((d) => ({
                  label: `${VehicleType[d.typeId || 0]}`,
                  value: d.typeId,
                })) || []
              ),
            },
          ]
        : []),
    ];
  }, [classes, groupedReport.lprReport, lprCameras, lprMmct, sortedLocations]);

  const { hasActiveFilter } = useActiveFilter(filters);

  return (
    <div
      className={clsx(
        "flex flex-col justify-center-center pt-4 bg-[#F2F2F2] gap-3",
        {
          "pb-4": !hasActiveFilter || !fitsDesktop,
        }
      )}
    >
      <LicensePlateReportFilterBarSettings
        className="px-4 sm:px-6"
        dashboard={dashboard}
      />
      <Divider className="sm:block hidden mx-4 sm:mx-6" />
      <div className="sm:block hidden">
        <FilterBarControls
          className="pl-4 pr-6"
          filters={filters}
          components={{
            datetime: FilterDateField,
          }}
        />
        {!loading && <FilterBar filters={filters} />}
      </div>
    </div>
  );
}
