import { Tab, Tabs } from "@mui/material";
import { useState } from "react";

import { IntegrationEditDevicesForm } from "./IntegrationEditDevicesForm";
import { IntegrationEditEventsForm } from "./IntegrationEditEventsForm";

enum ResourceType {
  Events = 0,
  Devices = 1,
}

const tabProps = {
  disableRipple: true,
  className: "text-xl text-[#353D48] opacity-100 p-0 top-[6px] min-w-[unset]",
  classes: {
    selected: "text-primary font-bold",
  },
};

export function IntegrationEditResourcesForm() {
  const [value, setValue] = useState(ResourceType.Events);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Tabs className="-mt-4" value={value} onChange={handleChange}>
        <Tab {...tabProps} label="Events" value={ResourceType.Events} />
        <div className="px-4" />
        <Tab {...tabProps} label="Devices" value={ResourceType.Devices} />
      </Tabs>
      {value === ResourceType.Events && <IntegrationEditEventsForm />}
      {value === ResourceType.Devices && <IntegrationEditDevicesForm />}
    </>
  );
}
