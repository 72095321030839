import { Monaco } from "@monaco-editor/react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import { Button, Typography } from "@mui/material";
import clsx from "clsx";
import { atom, useAtom, useAtomValue } from "jotai";
import { IRange, editor } from "monaco-editor";
import { useEffect, useRef, useState } from "react";
import { StringParam, useQueryParam } from "use-query-params";

import { AssistantEditorInput } from "@/components/Assistant/AssistantEditorInput";
import {
  ASSISTANT_COMMANDS,
  ASSISTANT_RESOURCE,
} from "@/components/Assistant/utils/editor/constant";

import {
  conversationLoadingAtom,
  conversationLocationAtom,
  useAskSpot,
} from "../hooks";
import { AssistantLocationSelector } from "./AssistantLocationSelector";
import { AssistantPromptInputFooter } from "./AssistantPromptInputFooter";
import { AssistantPromptInputPopover } from "./AssistantPromptInputPopover";
import { onEditorContextMenu, registerInjectResourceCommand } from "./utils";

export const questionAtom = atom<string>("");

interface AssistantPromptInputProps {
  condensed?: boolean;
}

export function AssistantPromptInput({ condensed }: AssistantPromptInputProps) {
  const editorRef = useRef<editor.IStandaloneCodeEditor>();
  const monacoRef = useRef<Monaco>();
  const anchorEl = useRef<Element | null>();

  const [suggestionType, setSuggestionType] = useQueryParam(
    "suggestion-type",
    StringParam
  );

  const locationId = useAtomValue(conversationLocationAtom);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const { askSpot } = useAskSpot();
  const [shouldAskSpot, setShouldAskSpot] = useState(false);
  const [type, setType] = useState("");
  const [range, setRange] = useState<IRange | null>();
  const conversationLoading = useAtomValue(conversationLoadingAtom);
  const [question, setQuestion] = useAtom(questionAtom);

  const editorClass = clsx({
    "h-[200px]": !condensed,
    "h-[100px]": condensed,
  });

  useEffect(() => {
    if (shouldAskSpot) {
      askSpot();
      setShouldAskSpot(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldAskSpot]);

  return (
    <div
      className="w-full rounded-2xl bg-[#252E35]/90 p-[6px] pt-3"
      style={{
        filter:
          "drop-shadow(0px 14px 49px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 22px rgba(0, 0, 0, 0.25))",
      }}
    >
      <div className="flex flex-col items-center justify-between gap-2 w-full h-full">
        <div className="w-full h-full px-4">
          <div className="flex items-center justify-between w-full pb-2">
            <Typography
              className={clsx("text-white font-bold", {
                "text-[24px] leading-[27px]": !condensed,
                "text-lg": condensed,
              })}
            >
              Ask Me Anything.
            </Typography>
            <AssistantLocationSelector />
          </div>
          <div className="bg-[#1A2126] p-5 pb-2 rounded-lg">
            {!!locationId && (
              <AssistantEditorInput
                value={question}
                onMount={(editor, monaco) => {
                  editorRef.current = editor;
                  monacoRef.current = monaco;

                  registerInjectResourceCommand(
                    editorRef.current,
                    monacoRef.current
                  );

                  editorRef.current.onDidBlurEditorWidget(() => {
                    if (suggestionType !== ASSISTANT_RESOURCE.prompt) {
                      setSuggestionType(ASSISTANT_RESOURCE.prompt);
                    }
                  });

                  editorRef.current.onKeyDown(async ({ code, shiftKey }) => {
                    if (code.toLowerCase() === "enter" && !shiftKey) {
                      setShouldAskSpot(true);
                    }
                  });

                  onEditorContextMenu(
                    editorRef.current,
                    (newType, newElement, newRange) => {
                      anchorEl.current = newElement;
                      setType(newType);
                      setRange(newRange);

                      setPopoverOpen(true);
                    }
                  );
                }}
                containerClassName={editorClass}
                className={editorClass}
                onChange={(value) => {
                  setQuestion(value || "");
                }}
              />
            )}
            <div className="flex gap-2 items-center justify-end -mr-3">
              {question.length > 0 && (
                <button
                  type="button"
                  className="bg-transparent cursor-pointer z-10"
                  onClick={() => {
                    setQuestion("");
                  }}
                >
                  <HighlightOffIcon className="w-5 h-5" />
                </button>
              )}
              <Button
                size="small"
                variant="contained"
                className="bg-[#394854] text-white font-bold disabled:opacity-40"
                startIcon={<SendRoundedIcon className="-mt-0.5 -mr-1" />}
                disabled={question.length === 0 || conversationLoading}
                onClick={askSpot}
              >
                Submit
              </Button>
            </div>
          </div>
          <AssistantPromptInputPopover
            open={popoverOpen}
            anchorEl={anchorEl.current}
            type={type}
            onSave={(data, value) => {
              editorRef?.current?.executeEdits("", [
                { range: range!, text: value },
              ]);
              editorRef.current?.trigger(
                "",
                ASSISTANT_COMMANDS.INJECT_RESOURCE,
                [type, value, data, range]
              );
              setPopoverOpen(false);
            }}
            handleClose={() => {
              setPopoverOpen(false);
            }}
          />
        </div>
        {!!locationId && (
          <div className="flex items-center justify-between w-full">
            <AssistantPromptInputFooter
              condensed={condensed}
              onTriggerSuggestion={() => {
                editorRef.current?.trigger(
                  "",
                  "editor.action.triggerSuggest",
                  {}
                );
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
