import { Snackbar, SnackbarContent } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";

export enum FeedbackType {
  Success = "success",
  Info = "info",
  Warning = "warning",
  Error = "error",
}

const SnackbarContext = React.createContext<{
  pushSnackbar: (
    message: React.ReactNode,
    type?: FeedbackType,
    action?: (handleClose: () => void) => React.ReactNode,
    duration?: number
  ) => void;
}>({ pushSnackbar: () => {} });

export function useFeedback() {
  return useContext(SnackbarContext);
}

interface SnackbarMessage {
  message: React.ReactNode;
  action?: (handleClose: () => void) => React.ReactNode;
  type?: FeedbackType;
  key: number;
  duration: number;
}

const useStyles = makeStyles()((theme) => ({
  snackbarContent: {
    "& .MuiSnackbarContent-action": {
      width: "100%",
      paddingLeft: "10px",
      marginRight: "0px",
      [theme.breakpoints.up("md")]: {
        width: "auto",
      },
    },
  },
}));

export function SnackbarProvider({ children }: { children: React.ReactNode }) {
  const { classes } = useStyles();
  const [snackPack, setSnackPack] = useState<SnackbarMessage[]>([]);
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState<SnackbarMessage | undefined>(
    undefined
  );

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  const pushSnackbar = (
    message: React.ReactNode,
    type?: FeedbackType,
    action?: (handleClose: () => void) => React.ReactNode,
    duration: number = 5000
  ) => {
    setSnackPack((prev) => [
      ...prev,
      { message, key: new Date().getTime(), type, action, duration },
    ]);
  };

  const handleClose = (_?: any, reason?: string) => {
    if (reason === "clickaway") return;
    setOpen(false);
  };

  const handleExited = () => setMessageInfo(undefined);

  return (
    <>
      <SnackbarContext.Provider value={{ pushSnackbar }}>
        {children}
      </SnackbarContext.Provider>
      <Snackbar
        key={messageInfo?.key}
        open={open}
        autoHideDuration={messageInfo?.duration}
        onClose={handleClose}
        TransitionProps={{ onExited: handleExited }}
        message={messageInfo?.message}
        action={messageInfo?.action?.(handleClose)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <div>
          {messageInfo?.type ? (
            <Alert
              onClose={handleClose}
              severity={messageInfo.type}
              classes={{
                message: "flex items-center",
                icon: "flex items-center",
              }}
            >
              {messageInfo.message}
            </Alert>
          ) : (
            messageInfo?.message &&
            messageInfo?.action && (
              <SnackbarContent
                className={`w-fit max-w-[605px] px-[10px] ${classes.snackbarContent}`}
                message={messageInfo?.message}
                action={messageInfo.action(handleClose)}
              />
            )
          )}
        </div>
      </Snackbar>
    </>
  );
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
