import {
  addHours,
  addMinutes,
  endOfHour,
  isAfter,
  isBefore,
  parseISO,
  startOfHour,
  subDays,
  subHours,
} from "date-fns/fp";
import gql from "graphql-tag";
import { isEmpty, omit, uniq } from "lodash/fp";
import { useCallback, useEffect, useMemo } from "react";
import { useUpdateEffect } from "react-use";
import {
  BooleanParam,
  DelimitedArrayParam,
  NumberParam,
  ObjectParam,
  StringParam,
  UrlUpdateType,
  useQueryParam,
  useQueryParams,
} from "use-query-params";

import { filterNullish } from "@/util/filterFalsy";

import { intelligentFiltersConfig } from "@/pages/Search/intelligence/intelligence";

import { Point } from "@/components/Zones/getSectorsForPolygon";

import {
  ClothingColor,
  Gender,
  useCameraFocusZonesQuery,
  useCameraVodAndMotionPreviewsQuery,
  VehicleColor,
  VehicleMake,
  VehicleType,
} from "@/generated-models";

import {
  ageBucketMap,
  useGenderParam,
} from "./SubjectSearch/components/AttributeFilters";

interface StartEndPair {
  start: Date;
  end: Date;
}

const startOfCurrentHour = startOfHour(new Date());
const endOfCurrentHour = endOfHour(new Date());
const defaultRangeStart = subHours(1, startOfCurrentHour);
const defaultRangeEnd = addHours(2, defaultRangeStart);

const defaultSearchRangeEnd = endOfCurrentHour;

export function useSearchRangeParams(defaultStartRange?: number) {
  const defaulSearchRangeStart = subDays(
    defaultStartRange ?? 90,
    startOfCurrentHour
  );
  const [params, setParams] = useQueryParam("datetime", StringParam);

  const setRangeParam = useCallback(
    (value: StartEndPair | null) => {
      const rangeStart = value?.start ?? defaulSearchRangeStart;
      const rangeEnd = value?.end ?? defaultSearchRangeEnd;

      setParams(
        value
          ? `${rangeStart.toISOString()}|${rangeEnd.toISOString()}`
          : undefined
      );
    },
    [defaulSearchRangeStart, setParams]
  );

  const [start, end] = params ? params.split("|") : ["", ""];

  return {
    query: params,
    /** Parsed start param with default in case none was explicitly selected */
    rangeStart: start ? new Date(start) : defaulSearchRangeStart,
    /** Parsed end param with default in case none was explicitly selected */
    rangeEnd: end ? new Date(end) : defaultSearchRangeEnd,
    setRangeParam,
  };
}

export function useRangeParam() {
  const [params, setParams] = useQueryParams({
    range: StringParam,
    vod: StringParam,
  });
  const { range, vod } = params;

  const setRangeParam = useCallback(
    (value: StartEndPair | null, updateType?: UrlUpdateType) => {
      // Determine new range start and end
      const rangeStart = value?.start ?? defaultRangeStart;
      const rangeEnd = value?.end ?? defaultRangeEnd;

      // Update query params
      setParams((current) => {
        const newRange = value
          ? `${rangeStart.toISOString()}|${rangeEnd.toISOString()}`
          : undefined;

        let newVod = current.vod;

        // Ensure the active clip always falls between the start- and end times
        // Spec: https://www.notion.so/spotai/Timeline-V2-d12b4e8960be4b5cbd9ce8911d28cc92#005e136f90114c7284dd41d160d84f8e
        if (current.vod) {
          // Parse vod param
          const [start, end] = current.vod.split("|");
          const vodStart = start ? new Date(start) : rangeStart;
          const vodEnd = end ? new Date(end) : rangeEnd;

          const isBeforeStart = isBefore(rangeStart);
          const isAfterEnd = isAfter(rangeEnd);
          if (isAfterEnd(vodStart) || isBeforeStart(vodEnd)) {
            // Case 1: vod completely outside of time range
            newVod = undefined;
          } else if (isAfterEnd(vodEnd)) {
            // Case 2: vod end time > time range end
            newVod = `${start}|${rangeEnd.toISOString()}`;
          } else if (isBeforeStart(vodStart)) {
            // Case 3: vod start time < time range start
            newVod = `${rangeStart.toISOString()}|${end}`;
          }
          // Else: vod still within time range
        }

        return { range: newRange, vod: newVod };
      }, updateType);
    },
    [setParams]
  );

  // If VOD is outside of default time range, set range to include VOD
  useEffect(() => {
    if (!range && vod) {
      // Parse vod param
      const [start, end] = vod.split("|");
      const vodStart = start ? new Date(start) : defaultRangeStart;
      const vodEnd = end ? new Date(end) : defaultRangeEnd;

      if (
        isBefore(defaultRangeStart, vodStart) ||
        isAfter(defaultRangeEnd, vodEnd)
      ) {
        const rangeStart = subHours(1, startOfHour(vodStart));
        const rangeEnd = addHours(1, startOfHour(vodEnd));
        setRangeParam({ start: rangeStart, end: rangeEnd }, "replaceIn");
      }
    }
  }, [range, vod, setRangeParam]);

  return useMemo(() => {
    const [start, end] = range ? range.split("|") : ["", ""];
    return {
      /** Parsed start param with default in case none was explicitly selected */
      rangeStart: start ? new Date(start) : defaultRangeStart,
      /** Parsed end param with default in case none was explicitly selected */
      rangeEnd: end ? new Date(end) : defaultRangeEnd,
      setRangeParam,
    };
  }, [range, setRangeParam]);
}

export function useRangeParamWithoutDefaults() {
  const [param] = useQueryParam("range", StringParam);

  return useMemo(() => {
    const [start, end] = param ? param.split("|") : [null, null];
    return {
      rangeStart: start ? new Date(start) : null,
      rangeEnd: end ? new Date(end) : null,
    };
  }, [param]);
}

const durations = [5, 10, 15, 30];
export function useDuration() {
  const [param, setParam] = useQueryParam("duration", NumberParam);
  const setDurationParam = useCallback(
    (value: NewValueType<number | null | undefined>) => {
      if (!value || value === durations[0]) setParam(undefined, "replaceIn");
      else setParam(value, "replaceIn");
    },
    [setParam]
  );
  return {
    durationParam: param || durations[0],
    setDurationParam,
    durations,
  };
}

export interface Clip {
  start: string;
  end: string;
}
interface ClipWithPosition extends Clip {
  position?: number;
}
type NewValueType<D> = D | ((latestValue: D) => D);

export function useVodParam() {
  const [param, setParam] = useQueryParam("vod", StringParam);
  const [rangeParam] = useQueryParam("range", StringParam);
  const setVod = useCallback(
    (
      value: NewValueType<ClipWithPosition | null>,
      updateType?: UrlUpdateType
    ) => {
      if (value == null) {
        setParam(undefined, updateType);
        return;
      }

      if (typeof value === "function") {
        setParam((v) => {
          let newValue = null;
          if (v == null) {
            newValue = value(null);
          } else {
            const [start, end, position] = v.split("|");
            newValue = value({
              start,
              end,
              position: position ? Number(position) : undefined,
            });
          }

          return newValue
            ? formatVodParam(newValue.start, newValue.end, newValue.position)
            : undefined;
        }, updateType);
        return;
      }

      setParam(
        formatVodParam(value.start, value.end, value.position),
        updateType
      );
    },
    [setParam]
  );

  return useMemo(() => {
    // Parse start, end and position from vod param
    const [start, end, position] = param
      ? param.split("|")
      : [null, null, null];

    // Get time range start from range param
    const rangeStart = rangeParam?.split("|")[0];
    const defaultStart = rangeStart ? new Date(rangeStart) : defaultRangeStart;

    // In case of empty vod param, fallback to default based on time range
    const vodStart = start ? parseISO(start) : defaultStart;
    const vodEnd = end ? parseISO(end) : addMinutes(durations[0], defaultStart);
    const vodPosition = position ? Number(position) : undefined;
    const vodParam =
      param ||
      formatVodParam(vodStart.toISOString(), vodEnd.toISOString(), vodPosition);
    const isDefaultVod = !param;
    const vodDuration = vodEnd.getTime() - vodStart.getTime();
    return {
      vodParam,
      vodStart,
      vodEnd,
      vodPosition,
      vodDuration,
      setVod,
      isDefaultVod,
    };
  }, [param, setVod, rangeParam]);
}

function formatVodParam(start: string, end: string, position?: number) {
  return [start, end, position].filter(filterNullish).join("|");
}

export function useArraySearchFilter(name: string) {
  const [param] = useQueryParam(name, DelimitedArrayParam);
  return useMemo(() => param?.filter(filterNullish), [param]);
}

export function useSetArraySearchFilter(name: string) {
  const [, setParam] = useQueryParam(name, DelimitedArrayParam);
  return useCallback(
    (value: NewValueType<string[] | undefined>) => {
      setParam((prev) => {
        const newValue =
          typeof value === "function"
            ? value(prev?.filter(filterNullish))
            : value;
        // Make sure newValue array is not empty, unique and sorted
        return newValue?.length ? uniq(newValue.sort()) : undefined;
      }, "replaceIn");
    },
    [setParam]
  );
}

export function useHideBoundingBoxes() {
  return useQueryParam("hideBB", BooleanParam);
}

export function useSearchSubjects() {
  return useArraySearchFilter("subjects");
}

export function useSetSearchSubjects() {
  return useSetArraySearchFilter("subjects");
}

const motionSubjects = intelligentFiltersConfig.motion.objectIds;
type ShapeOrId = number | Point[];
export function useCameraMotionZone(cameraId: number) {
  // Get selected zone state from URL
  const [param, setParam] = useQueryParam("zones", ObjectParam);
  const camParam = param?.[cameraId];

  // Fetch zones for this camera
  const { data } = useCameraFocusZonesQuery({ variables: { id: cameraId } });

  // Turn on motion filter when ZBM gets enabled
  const subjects = useSearchSubjects();
  const setSubjects = useSetSearchSubjects();
  useEffect(() => {
    if (param) {
      if (!subjects?.length) {
        setSubjects((prev) => (prev ?? []).concat(motionSubjects));
      }
    }
  }, [param, setSubjects, subjects?.length]);

  // Turn off ZBM when motion filter gets disabled
  useUpdateEffect(() => {
    if (!subjects?.length) {
      setParam(undefined, "replaceIn");
    }
  }, [subjects, setParam]);

  return useMemo(() => {
    // Parse URL param
    let zoneId: number | undefined = undefined;
    let tempZone: Point[] | undefined = undefined;
    if (camParam) {
      try {
        const shapeOrId = JSON.parse(camParam) as ShapeOrId;
        if (typeof shapeOrId === "number") zoneId = shapeOrId;
        else tempZone = shapeOrId;
      } catch (error) {
        console.error("Failed to parse motion zone param");
        console.error(error);
      }
    }
    // Match zoneId with saved camera zones
    const activeZone =
      zoneId && data
        ? data.camera.focusZones.find((z) => z.id === zoneId)
        : undefined;
    return {
      activeZone,
      // Map activeZone.shape to get rid of __typename
      activeShape: tempZone ?? activeZone?.shape.map(({ x, y }) => ({ x, y })),
      zones: data?.camera.focusZones ?? [],
    };
  }, [camParam, data]);
}

export function useSetCameraMotionZone() {
  const [, setParam] = useQueryParam("zones", ObjectParam);
  return useCallback(
    (cameraId: number, shapeOrId: ShapeOrId | undefined) => {
      setParam((value) => {
        const newValue = shapeOrId
          ? { ...value, [cameraId]: JSON.stringify(shapeOrId) }
          : omit(cameraId, value);
        return isEmpty(newValue) ? undefined : newValue;
      }, "replaceIn");
    },
    [setParam]
  );
}

export function useCameraPreviews(id: number): Clip[] | undefined {
  const { rangeStart, rangeEnd } = useRangeParam();
  const { durationParam } = useDuration();
  const { vodDuration } = useVodParam();
  const subjects = useSearchSubjects();
  const { activeShape } = useCameraMotionZone(id);
  const clothingUpper = useArraySearchFilter("clothingUpper");
  const clothingLower = useArraySearchFilter("clothingLower");
  const [gender] = useGenderParam();
  const age = useArraySearchFilter("age");
  const vehicleType = useArraySearchFilter("vehicleType");
  const vehicleMake = useArraySearchFilter("vehicleMake");
  const vehicleColor = useArraySearchFilter("vehicleColor");

  const start = rangeStart.toISOString();
  const end = rangeEnd.toISOString();
  // Poll the previews so the latest tile can update as new stills are coming in.
  // NOTE: We might want to do this with a "subscription" some day
  const pollInterval = isAfter(new Date(), rangeEnd)
    ? 60000 // 1 minute
    : undefined;

  const { data: cameraWithPreviews } = useCameraVodAndMotionPreviewsQuery({
    variables: {
      id,
      start,
      end,
      duration: !!subjects // will be null if beta is not enabled
        ? 60 // One minute
        : vodDuration
        ? Math.round(vodDuration / 1000)
        : durationParam * 60,
      search: !!subjects // will be null if beta is not enabled
        ? {
            searchSubjects: subjects,
            searchArea: activeShape,
            attributes: {
              clothingUpper: clothingUpper as ClothingColor[] | undefined,
              clothingLower: clothingLower as ClothingColor[] | undefined,
              gender: gender as Gender | null | undefined,
              age: age?.map((x) => ageBucketMap[x]),
              vehicleType: vehicleType as VehicleType[],
              vehicleMake: vehicleMake as VehicleMake[],
              vehicleColor: vehicleColor as VehicleColor[],
            },
          }
        : undefined,
    },
    pollInterval,
  });

  const camera = cameraWithPreviews?.camera;
  return camera?.previews;
}

gql`
  query cameraVodAndMotionPreviews(
    $id: Int!
    $start: String!
    $end: String!
    $duration: Int!
    $search: SearchOptions
  ) {
    camera(id: $id) {
      id
      name

      previews(
        startTime: $start
        endTime: $end
        duration: $duration
        search: $search
      ) {
        start
        end
      }
    }
  }
`;

gql`
  query cameraFocusZones($id: Int!) {
    camera(id: $id) {
      id
      focusZones {
        id
        name
        shape {
          x
          y
        }
      }
    }
  }
`;
