import InfoOutlined from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Typography,
  Chip,
  LinearProgress,
  IconButton,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";

import { PawIcon } from "@/icons/Paw";

import { DiscoveryStatusPopup } from "@/components/Genius/DiscoveryStatusPopup";
import { GeniusLogo } from "@/components/Genius/GeniusLogo";
import { useGenius } from "@/components/Genius/GeniusProvider";

import {
  CameraStatus,
  GetLocationSetupInfoQuery,
  LifecycleStates,
} from "@/generated-models";
import { theme } from "@/layout/theme";

import { ZendeskArticle, ZendeskLink } from "../Zendesk/ZendeskLink";

export default function GeniusBanner({
  locationId,
  locationResults,
}: {
  locationId: number;
  locationResults?: GetLocationSetupInfoQuery;
}) {
  const [geniusScan, { loading, statusComponent }] = useGenius();
  useEffect(() => {
    geniusScan(); // Intentionally only execute once on load
    // eslint-disable-next-line
  }, []);
  const discoStatusButtonRef = useRef<HTMLButtonElement | null>(null);
  const [discoStatusPopupOpen, setDiscoStatusPopupOpen] = useState(false);
  const onlineCamCount = locationResults?.location?.cameras.filter(
    ({ status, lifecycleState }) =>
      status === CameraStatus.Online &&
      lifecycleState === LifecycleStates.Enabled
  ).length;
  const offlineCamCount = locationResults?.location?.cameras.filter(
    ({ status, lifecycleState }) =>
      status === CameraStatus.Offline &&
      lifecycleState === LifecycleStates.Enabled
  ).length;
  const geniusAuthConfiguredCount = locationResults?.location?.devices.filter(
    ({ geniusAuthConfigured }) => geniusAuthConfigured
  ).length;
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      style={{
        background: "RGBA(229, 242, 252, 1.00)",
        position: "sticky",
        top: 0,
        zIndex: 1201,
        borderBottom: "0.5px solid rgba(0,0,0,0.03)",
        filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1))",
      }}
      mt={2}
      mb={2}
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        width="100%"
        style={{
          padding: theme.spacing(1, 4),
        }}
      >
        <GeniusLogo style={{ marginTop: -2 }} />
        <IconButton
          ref={discoStatusButtonRef}
          size="small"
          onClick={() => setDiscoStatusPopupOpen(true)}
        >
          <InfoOutlined
            color="primary"
            style={{
              margin: theme.spacing(0.5, 0.5),
              fontSize: 16,
            }}
          />
        </IconButton>
        <Typography variant="body1" style={{ fontSize: 15 }}>
          {statusComponent}
        </Typography>
        {discoStatusPopupOpen && (
          <DiscoveryStatusPopup
            locationId={locationId}
            anchorEl={discoStatusButtonRef.current}
            onClose={() => setDiscoStatusPopupOpen(false)}
          />
        )}
        <ZendeskLink color="primary" article={ZendeskArticle.ADDING_CAMERA} />
        <Box flexGrow={1} m={1} />
        {!!geniusAuthConfiguredCount && (
          <Chip
            style={{
              margin: theme.spacing(0.5),
            }}
            label={
              <Box display="flex" flexDirection="row" alignItems="center">
                <strong>{geniusAuthConfiguredCount} Authenticated by</strong>{" "}
                <PawIcon style={{ height: 16, width: 22 }} />
              </Box>
            }
            color="primary"
            variant="outlined"
          />
        )}
        {!!onlineCamCount && (
          <Chip
            style={{
              background: "RGBA(94, 216, 114, 1.00)",
              fontWeight: "bold",
              color: "white",
              margin: theme.spacing(0.5),
            }}
            label={`${onlineCamCount} ${
              onlineCamCount === 1 ? "Cam" : "Cams"
            } Online`}
          />
        )}

        {!!offlineCamCount && (
          <>
            <Chip
              style={{
                background: "RGBA(237, 97, 103, 1.00)",
                fontWeight: "bold",
                color: "white",
                marginLeft: theme.spacing(0.5),
              }}
              label={`${offlineCamCount} ${
                onlineCamCount === 1 ? "Cam" : "Cams"
              } Offline`}
            />
            <ZendeskLink
              color="primary"
              article={ZendeskArticle.OFFLINE_CAMERA}
            />
          </>
        )}
      </Box>
      {loading && <LinearProgress />}
    </Box>
  );
}
