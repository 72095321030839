import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { IconButton, Paper, Typography } from "@mui/material";
import gql from "graphql-tag";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import { MaintainDetailsAnalytics } from "@/pages/Maintain/Details/Analytics/MaintainDetailsAnalytics";
import { MaintainDetailsConfig } from "@/pages/Maintain/Details/Config/MaintainDetailsConfig";
import { MaintainDetailsHeader } from "@/pages/Maintain/Details/MaintainDetailsHeader";
import { MaintainDetailsStatusBanner } from "@/pages/Maintain/Details/MaintainDetailsStatusBanner";

import { ErrorMessage } from "@/components/ErrorMessage";
import {
  DEFAULT_CAMERA_SETTINGS_FRAGMENT,
  DEVICE_STREAM_METADATA_FRAGMENT,
  DEVICE_STREAM_SETTINGS_FRAGMENT,
} from "@/components/Genius/fragments";
import { Loading } from "@/components/Loading";

import { useCameraDetailByIdQuery } from "@/generated-models";
import { ContentWrapper } from "@/layout/ContentWrapper";

export function MaintainDetailsPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation() as { state: { goBack?: string } };
  const { loading, data, error } = useCameraDetailByIdQuery({
    variables: { cameraId: Number(id) },
  });

  const camera = data?.camera;

  if (error) {
    return (
      <ContentWrapper>
        <ErrorMessage title="Error" description={error?.message} />
      </ContentWrapper>
    );
  }

  if (!camera) {
    if (!loading) {
      return (
        <ErrorMessage
          title="Unable to fetch health stats"
          description="Please try again later"
        />
      );
    }
    return <Loading grow>Fetching camera data</Loading>;
  }

  return (
    <Paper square={false} elevation={3}>
      <div className="px-4 py-4 flex items-center">
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            const backUrl = location?.state?.goBack;
            if (backUrl) {
              navigate(backUrl);
            } else {
              navigate("..");
            }
          }}
          size="large"
        >
          <ChevronLeftIcon fontSize="large" color="primary" />
        </IconButton>
        <Typography variant="h1">Camera Details</Typography>
      </div>
      <MaintainDetailsHeader camera={camera} />
      <div className="px-9 pb-9 pt-6 gap-y-6 flex flex-col">
        <MaintainDetailsAnalytics camera={camera} />
        <MaintainDetailsStatusBanner camera={camera} />
        <MaintainDetailsConfig camera={camera} />
      </div>
    </Paper>
  );
}

gql`
  query cameraDetailById($cameraId: Int!) {
    camera(id: $cameraId) {
      id
      name
      status
      vendor
      firstSegmentTime
      location {
        id
        name
        timezone
      }
      details
      lifecycleState
      aiEnabled
      health {
        cameraOnline
      }
      settings {
        lprEnabled
      }
      stream {
        id
        channelId
        settings {
          ...DeviceStreamSettings
        }
        metadata {
          ...DeviceStreamMetadata
        }
      }
      device {
        id
        ip
        port
        path
        mac
        isNvr
        status
        isFisheye
        reachableAppliances {
          id
          serialNumber
          health {
            online
          }
        }
      }
      appliance {
        id
        serialNumber
        health {
          online
        }
        defaultCameraSettings {
          ...DefaultCameraSettings
        }
        cameras {
          id
        }
      }
    }
  }
  ${DEFAULT_CAMERA_SETTINGS_FRAGMENT}
  ${DEVICE_STREAM_METADATA_FRAGMENT}
  ${DEVICE_STREAM_SETTINGS_FRAGMENT}
`;
