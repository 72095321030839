import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import HelpIcon from "@mui/icons-material/Help";
import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import clsx from "clsx";
import { ReactNode, useState } from "react";

interface AssistantPromptHelpModalProps {
  className?: string;
  condensed?: boolean;
  iconClassName?: string;
}

interface AssistantInfoCardProps {
  children: ReactNode;
  description: string;
}

function SearchIcon() {
  return (
    <svg
      width="44"
      height="45"
      viewBox="0 0 44 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.4527 26.4314L27.2944 25.2218V25.2243C29.1146 23.1069 29.6462 21.0635 29.6462 18.0731C29.6462 11.405 24.2412 6 17.5731 6C10.905 6 5.5 11.405 5.5 18.0731C5.5 24.7412 10.905 30.1462 17.5731 30.1462C20.5635 30.1462 22.6069 29.6146 24.7243 27.7944H24.7218L25.9314 28.9567L34.0847 37.0983C34.7816 37.7942 35.9106 37.7938 36.607 37.0974C37.3042 36.4003 37.3038 35.2699 36.6061 34.5732L28.4527 26.4314ZM17.528 27.3832C22.6947 27.3832 26.8832 23.1947 26.8832 18.028C26.8832 12.8613 22.6947 8.6729 17.528 8.6729C12.3613 8.6729 8.1729 12.8613 8.1729 18.028C8.1729 23.1947 12.3613 27.3832 17.528 27.3832Z"
        fill="#B3D2E9"
      />
    </svg>
  );
}

function GuideIcon() {
  return (
    <svg
      width="51"
      height="31"
      viewBox="0 0 51 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.6438 30.5599H24.3674C21.996 28.3501 18.9024 27.1328 15.6571 27.1328H6.04438L5.1084 26.1968V1.12349L6.04438 0.1875H15.6571C19.0878 0.1875 22.3754 1.37833 25.0056 3.55693C27.6362 1.37833 30.9233 0.1875 34.3541 0.1875H43.9668L44.9028 1.12349V26.1968L43.9668 27.1328H34.3541C31.1088 27.1328 28.0151 28.3501 25.6438 30.5599ZM6.98046 25.2609H15.6566C19.0925 25.2609 22.3804 26.4513 25.0051 28.6288C27.6299 26.4513 30.9177 25.2609 34.3536 25.2609H43.0298L43.0305 2.05909H34.354C31.1149 2.05909 28.0213 3.27671 25.6425 5.48728H24.3679C21.9892 3.27671 18.8956 2.05909 15.6565 2.05909H6.98035L6.98046 25.2609Z"
        fill="#A6C8E1"
      />
      <path
        d="M49.0739 30.8104H0.935985L0 29.8744V4.89692L0.935985 3.96094H6.04372V5.83291H1.87197V28.9388H48.1373V5.83291H43.9656V3.96094H49.0733L50.0093 4.89692V29.8744L49.0739 30.8104Z"
        fill="#A6C8E1"
      />
      <path
        d="M24.0693 4.80078H25.9413V29.8741H24.0693V4.80078Z"
        fill="#A6C8E1"
      />
    </svg>
  );
}

function GraphIcon() {
  return (
    <svg
      width="44"
      height="45"
      viewBox="0 0 44 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.3999 4.89844H6.5999V37.8984H39.5999V40.0984H4.3999V4.89844ZM36.9973 11.747C37.1092 11.8386 37.2019 11.9513 37.2702 12.0787C37.3385 12.2061 37.381 12.3457 37.3953 12.4895C37.4096 12.6334 37.3954 12.7786 37.3536 12.917C37.3117 13.0553 37.243 13.1841 37.1513 13.2958L27.2513 25.3958C27.154 25.5146 27.0329 25.6117 26.8958 25.6808C26.7587 25.75 26.6087 25.7897 26.4553 25.7973C26.302 25.805 26.1487 25.7805 26.0054 25.7254C25.8621 25.6702 25.732 25.5857 25.6233 25.4772L19.9319 19.7858L11.8887 30.8452C11.7128 31.069 11.4569 31.2158 11.175 31.2549C10.893 31.2939 10.6069 31.2222 10.3768 31.0547C10.1467 30.8872 9.99039 30.637 9.94085 30.3567C9.8913 30.0764 9.95233 29.7879 10.1111 29.5516L18.9111 17.4516C19.0045 17.323 19.1247 17.2161 19.2634 17.1383C19.4021 17.0606 19.556 17.0138 19.7145 17.0013C19.873 16.9887 20.0323 17.0107 20.1815 17.0657C20.3307 17.1206 20.4662 17.2073 20.5787 17.3196L26.3185 23.0616L35.4485 11.901C35.54 11.7892 35.6527 11.6964 35.7801 11.6281C35.9075 11.5599 36.0471 11.5173 36.191 11.503C36.3348 11.4887 36.4801 11.5029 36.6184 11.5448C36.7568 11.5867 36.8855 11.6554 36.9973 11.747Z"
        fill="#B3D2E9"
      />
    </svg>
  );
}

function AssistantInfoCard({ children, description }: AssistantInfoCardProps) {
  return (
    <div className="bg-[#3B4955] border border-solid border-[#536572] rounded-xl px-8 py-5 h-[97px] flex items-center justify-between gap-8">
      <div className="w-11">{children}</div>
      <Typography className="text-base leading-[19px]">
        {description}
      </Typography>
    </div>
  );
}

export function AssistantPromptHelpModal({
  condensed,
}: AssistantPromptHelpModalProps) {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <button
        type="button"
        className="bg-transparent text-[#A6C8E1] text-sm leading-[27px]"
        onClick={() => {
          setOpen(true);
        }}
      >
        <HelpIcon className={clsx("-mt-0.5 mr-1 h-4 w-4")} />
        How does this work?
      </button>
      <Dialog
        PaperProps={{
          className: "bg-[#232C33]/90 rounded-[20px] text-[#A6C8E1] max-w-2xl",
        }}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle className="mt-2 mx-5">
          <div className="flex items-center justify-between">
            <Typography className="text-white font-bold text-[32px] leading-[38px] tracking-[0.015em]">
              How it Works
            </Typography>
            <button
              type="button"
              className="bg-transparent text-[#A6C8E1] opacity-40 -mt-8 -mr-8"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CancelOutlinedIcon
                className={clsx({
                  "h-4 w-4": condensed,
                })}
              />
            </button>
          </div>
        </DialogTitle>
        <DialogContent className="mx-5 mb-4">
          <div className="flex flex-col gap-10">
            <Typography className="text-base leading-[19px]">
              SpotFetch, a sophisticated AI-powered assistant, elevates the
              SpotAI video intelligence platform experience. Its intuitive
              interface enables users to effortlessly locate footage, access
              how-to guides, perform analytics on your footage, and more.
            </Typography>
            <AssistantInfoCard
              description={`Effortlessly locate footage of people, vehicles, license plates, and beyond. For instance, instruct SpotFetch to "find footage of license plate BJG3914."`}
            >
              <SearchIcon />
            </AssistantInfoCard>

            <AssistantInfoCard
              description={`How to Guides: Easily pull how-to guides by requesting SpotFetch to "search support center for lpr camera setup."`}
            >
              <GuideIcon />
            </AssistantInfoCard>

            <AssistantInfoCard
              description={`Generate immediate analytics on your footage simply by asking SpotFetch "what was the longest idle time yesterday in Parking Lot Cam" for subjects like vehicles, people, and forklifts.`}
            >
              <GraphIcon />
            </AssistantInfoCard>

            <Typography className="text-base leading-[19px]">
              SpotFetch's advanced AI revolutionizes video data management,
              transforming the way you explore, analyze, and collaborate with
              your footage.
            </Typography>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
