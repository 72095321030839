import {
  FormControlLabel,
  FormGroup,
  Paper,
  Switch,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import gql from "graphql-tag";
import { useFlags } from "launchdarkly-react-client-sdk";
import { lazy, Suspense, useEffect, useMemo } from "react";

import { AnalyticsViewType, trackView } from "@/util/analytics";
import { useBreakpoints } from "@/util/useBreakpoints";
import { useDocumentTitle } from "@/util/useDocumentTitle";

import { CopilotPlayerSection } from "@/components/Ai/Copilot/CopilotPlayerSection/CopilotPlayerSection";
import {
  useCopilotEnabled,
  useIsCopilotCompatible,
} from "@/components/Ai/Copilot/copilotQueryHooks";
import { CopilotProvider } from "@/components/Ai/Copilot/useCopilotContext";
import { useMe } from "@/components/Auth";
import { ErrorMessage } from "@/components/ErrorMessage";
import { FullScreen, IfFullscreen } from "@/components/FullScreen";
import { RecentCameras } from "@/components/Live/RecentCameras";
import { WatchLiveHeader } from "@/components/Live/WatchLiveHeader";
import { Loading } from "@/components/Loading";
import { LivePlayer } from "@/components/Player/MainPlayer/MainPlayer";
import {
  HdIndicator,
  LocalIndicator,
} from "@/components/Player/PlayerMetadata";
import { IntercomAudio } from "@/components/Player/WebRTCPlayer/IntercomAudio";
import { TwoWayAudio } from "@/components/Player/WebRTCPlayer/TwoWayAudio";
import { PlayerMachineProvider } from "@/components/Player/playerMachine";
import QuickEditCameraName from "@/components/QuickEditCameraName";
import { PlayerMetadataLayout, WallClock } from "@/components/View/View";
import { SearchParamLink } from "@/components/shared/QueryParamLink";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  GetCameraOrgUserSettingsDocument,
  useGetCameraOrgUserSettingsLazyQuery,
  usePage_LiveQuery,
  useUpsertCameraOrgUserSettingsMutation,
} from "@/generated-models";
import { usePrefixOrgSlug } from "@/hooks/useOrgRouteBase";
import { usePermissions } from "@/hooks/usePermissions";

import { RealTimeAudioButton } from "../RealTimeAudioButton";
import { ZoneSwitch } from "../Search/ZoneMotion/ZoneSwitch";

const Insights = lazy(() => import("../../components/Insights/Insights"));

interface LiveProps {
  activeCamIds: number[];
}

export function Live({ activeCamIds }: LiveProps) {
  useDocumentTitle("Live");
  const prefixOrgSlug = usePrefixOrgSlug();
  const me = useMe();
  const hasPermission = usePermissions();
  const hasViewLivePermission = hasPermission("video_live_access");
  const { fitsDesktop } = useBreakpoints();
  const { copilotEnabled } = useCopilotEnabled();
  const copilotCompatible = useIsCopilotCompatible();
  const {
    bidirectionalAudio,
    liveWebRtc: liveWebRTCAvialability,
    onvifBackchannel,
    intercomIntegration,
  } = useFlags();

  const [
    getCameraOrgUserSettings,
    { data: getCameraOrgUserSettingsData },
  ] = useGetCameraOrgUserSettingsLazyQuery({
    fetchPolicy: "network-only",
  });

  const [upsertCameraOrgUserSettings] = useUpsertCameraOrgUserSettingsMutation({
    refetchQueries: [GetCameraOrgUserSettingsDocument],
  });

  const noCamerasSelected = activeCamIds.length === 0;
  const { data, error } = usePage_LiveQuery({
    variables: { ids: activeCamIds },
    ...refetchOnMountPolicy,
    skip: noCamerasSelected || !hasViewLivePermission,
  });

  const activeCams = useMemo(() => data?.cameras ?? [], [data]);
  const cameraIds = useMemo(() => activeCams.map((c) => c.id), [activeCams]);
  const cameraIdsJoin = useMemo(() => cameraIds.join(""), [cameraIds]);

  useEffect(() => {
    if (cameraIdsJoin) trackView(AnalyticsViewType.live);
  }, [cameraIdsJoin]);

  useEffect(() => {
    if (liveWebRTCAvialability && cameraIds.length === 1 && me?.orgUserId) {
      getCameraOrgUserSettings({
        variables: {
          input: { cameraId: cameraIds[0], orgUserId: me.orgUserId },
        },
      });
    }
  }, [
    cameraIds,
    me?.orgUserId,
    getCameraOrgUserSettings,
    liveWebRTCAvialability,
  ]);

  const cameraLiveWebRTC =
    getCameraOrgUserSettingsData?.cameraOrgUserSettings?.liveWebRTC;
  const organizationLiveWebRTC = me?.organization?.flags?.liveWebRTC;

  const isLowLatency = useMemo(() => {
    return (
      liveWebRTCAvialability &&
      (cameraLiveWebRTC ?? organizationLiveWebRTC ?? true)
    );
  }, [liveWebRTCAvialability, cameraLiveWebRTC, organizationLiveWebRTC]);

  // User does not have permission to view this page
  if (!hasViewLivePermission) {
    return (
      <ErrorMessage
        title="You do not have access to live video streams"
        description="Your system admin has restricted your ability to search video feeds, please contact them for additional access permissions."
      />
    );
  }

  if (error) {
    return (
      <ErrorMessage
        title="Error"
        description="Failed loading the camera(s). Please try again later or contact support."
      />
    );
  }

  // Page query is loading
  if (!noCamerasSelected && (!data || !me)) {
    return <Loading className="my-40" />;
  }

  if (activeCamIds.length > 0 && activeCams.length === 0) {
    // User is trying to access a camera without having access to it
    return (
      <ErrorMessage
        title="You do not have access to this camera"
        description="Your system admin has restricted your ability to access this camera, please contact them for additional access permissions."
      />
    );
  }

  if (activeCams.length === 0) {
    return (
      <div className="flex flex-wrap justify-center md:justify-start gap-x-4 md:gap-x-6 mt-5 px-2 md:px-0">
        <WatchLiveHeader className="text-4xl shrink-0" />
        <Typography className="max-w-xs sm:max-w-sm md:max-w-md text-sm md:text-base leading-5 md:leading-5">
          <strong>Watch any of your cameras live.</strong> Choose a recent
          camera or select a camera from the list or map in the
          {fitsDesktop ? " sidebar on the right" : " tray below"}.
        </Typography>
        <RecentCameras />
      </div>
    );
  }

  return (
    <PlayerMachineProvider>
      <CopilotProvider>
        <div className="flex items-center justify-between h-16 md:h-20 px-4 md:p-0">
          <WatchLiveHeader className="text-3xl" />

          <SearchParamLink
            className="text-primary font-medium"
            to="."
            keepParams={["g"]}
          >
            Recent Livestreams
          </SearchParamLink>
        </div>
        <Paper
          elevation={6}
          className={clsx("mb-6", {
            "rounded-b-2xl": copilotCompatible && fitsDesktop,
          })}
        >
          <div className="flex items-center gap-1 p-3 justify-between">
            {activeCams.length === 1 ? (
              <>
                <QuickEditCameraName
                  cameraId={activeCams[0].id}
                  camName={activeCams[0].name}
                />
                {activeCams[0].location.tag ? (
                  <SearchParamLink
                    params={{ g: activeCams[0].location.tag.id }}
                    className="truncate text-primary"
                  >
                    {activeCams[0].location.name}
                  </SearchParamLink>
                ) : (
                  activeCams[0].location.name
                )}
                {fitsDesktop && (
                  <>
                    <div className="grow" />
                    {liveWebRTCAvialability && (
                      <FormGroup className="mr-2">
                        <FormControlLabel
                          label="Low Latency"
                          labelPlacement="start"
                          control={
                            <Switch
                              size="small"
                              onChange={({ target }) => {
                                if (!me) return;

                                upsertCameraOrgUserSettings({
                                  variables: {
                                    input: {
                                      cameraId: cameraIds[0],
                                      orgUserId: me.orgUserId,
                                      liveWebRTC: target.checked,
                                    },
                                  },
                                });
                              }}
                              checked={isLowLatency}
                            />
                          }
                        />
                      </FormGroup>
                    )}
                    <HdIndicator />
                    <LocalIndicator />
                  </>
                )}
              </>
            ) : (
              <>
                <Typography component="h2" className="font-bold md:text-xl">
                  Multi-Camera Live
                </Typography>
              </>
            )}
          </div>

          <FullScreen className="flex grow flex-col dark">
            <LivePlayer
              cameras={activeCams}
              overrideMachineProvider={false}
              lowLatency={isLowLatency}
            />

            <div
              className={clsx(
                "text-white py-3 px-4 flex items-center gap-5 bg-black bg-opacity-90",
                {
                  "rounded-b": !(copilotCompatible && fitsDesktop),
                }
              )}
            >
              {fitsDesktop && activeCams.length === 1 && (
                <IfFullscreen>
                  <Typography component="h2" variant="h4">
                    {activeCams[0].name}
                  </Typography>

                  <div className="flex-center gap-1">
                    <HdIndicator />
                    <LocalIndicator />
                  </div>
                </IfFullscreen>
              )}

              <PlayerMetadataLayout
                wallClock={
                  <WallClock
                    timezone={activeCams[0].location.timezone}
                    className="sm:!flex-row-reverse sm:items-center !gap-x-4"
                    classes={{
                      date: "!text-[13px] opacity-50 font-normal",
                      time: "text-[#BFBFBF] !text-base",
                      timeContainer: "items-center !gap-x-1",
                      ampm: "text-white opacity-30 !text-[9px]",
                    }}
                  />
                }
                zoneSwitch={
                  activeCams.length === 1 &&
                  copilotEnabled && (
                    <ZoneSwitch cameraId={activeCams[0].id} showHelp />
                  )
                }
                liveButtonOrIndicator={
                  <>
                    <div className="grow" />
                    {bidirectionalAudio &&
                      activeCams.length === 1 &&
                      // TODO: Hotfix for ExtraSpace, remove when we can determine if a camera supports bidrectional audio
                      (activeCams[0].vendor === "IC Realtime LLC" ||
                        activeCams[0].vendor === "Visionhitech Co., Ltd.") &&
                      // don't show if the camera is already using backchannel
                      !(
                        onvifBackchannel &&
                        activeCams[0].settings.onvifBackchannelEnabled
                      ) && <RealTimeAudioButton cameraId={activeCams[0].id} />}
                    {onvifBackchannel &&
                      activeCams.length === 1 &&
                      !!activeCams[0].settings.onvifBackchannelEnabled && (
                        <TwoWayAudio
                          cameraId={activeCams[0].id}
                          url={
                            isLowLatency
                              ? activeCams[0].feeds?.webRTC ?? ""
                              : ""
                          }
                        />
                      )}
                    {intercomIntegration &&
                      activeCams.length === 1 &&
                      activeCams[0]?.intercom?.id && (
                        <IntercomAudio
                          cameraId={activeCams[0].id}
                          url={
                            isLowLatency
                              ? activeCams[0].feeds?.webRTC ?? ""
                              : ""
                          }
                        />
                      )}
                    {hasPermission("video_vod_access") && (
                      <SearchParamLink
                        to={prefixOrgSlug(`/search`)}
                        className="bg-primary text-white no-underline rounded text-[13px] leading-[15px] px-[14px] py-1"
                      >
                        Search This Camera
                      </SearchParamLink>
                    )}
                  </>
                }
              />
            </div>
            {fitsDesktop && copilotCompatible && <CopilotPlayerSection />}
          </FullScreen>
        </Paper>

        {activeCams[0]?.aiEnabled && fitsDesktop && (
          <Suspense fallback={null}>
            <div className="px-6">
              <Insights
                cameraId={activeCams[0].id}
                timezone={activeCams[0].location.timezone}
              />
            </div>
          </Suspense>
        )}

        <div className="h-14" />
      </CopilotProvider>
    </PlayerMachineProvider>
  );
}

gql`
  query page_live($ids: [Int!]!) {
    cameras(ids: $ids) {
      id
      name
      still
      aiEnabled
      vendor
      intercom {
        id
      }
      feeds {
        tunnel
        local
        webRTC
      }
      health {
        cameraOnline
        applianceOnline
      }
      location {
        id
        name
        timezone
        tag {
          id
        }
      }
      device {
        id
        onvifSupported
      }
      settings {
        audioControlEnabled
        onvifBackchannelEnabled
      }
    }
  }
`;

gql`
  query getCameraOrgUserSettings($input: GetCameraOrgUserSettingsInput!) {
    cameraOrgUserSettings(input: $input) {
      id
      cameraId
      orgUserId
      liveWebRTC
    }
  }
`;

gql`
  mutation upsertCameraOrgUserSettings(
    $input: UpsertCameraOrgUserSettingsInput!
  ) {
    upsertCameraOrgUserSettings(input: $input) {
      cameraId
      orgUserId
      liveWebRTC
    }
  }
`;
