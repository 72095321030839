import { Tabs, Tab } from "@mui/material";
import React from "react";
import { withStyles } from "tss-react/mui";

export function createStyledTabs(styles?: {
  tab?: {
    root?: React.CSSProperties;
    selected?: React.CSSProperties;
  };
}) {
  const NavTabs = withStyles(
    (props: any) => (
      <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />
    ),
    (theme) => ({
      root: {
        alignSelf: "stretch",
        minHeight: "initial",
        // marginBottom: 8,
        // marginTop: -4,
        overflow: "initial",
      },
      scroller: {
        overflow: "initial !important",
      },
      flexContainer: {
        height: "100%",
      },
      indicator: {
        background: "none",
        height: 3,
        "& > div": {
          backgroundColor: "currentColor",
          width: "100%",
          // maxWidth: 18,
          borderRadius: 1.5,
          height: "100%",
          margin: "auto",
        },
      },
    })
  );

  const NavTab = withStyles(
    (props: any) => (
      <Tab
        {...props}
        aria-label={props.icon ? props.label : undefined}
        label={props.icon ? undefined : props.label}
      />
    ),
    (theme) => ({
      root: {
        fontSize: 18,
        minWidth: 10,
        minHeight: "initial",
        fontWeight: "normal",
        lineHeight: "1.6",
        overflow: "initial",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        ...styles?.tab?.root,
      },
      selected: {
        ...styles?.tab?.selected,
      },
    })
  );

  return { NavTabs, NavTab };
}

export function createMobileTabs(styles?: React.CSSProperties) {
  const { fontSize = 18 } = styles ?? {};
  const MobileTabs = withStyles(
    (props: any) => <Tabs {...props} />,
    (theme) => ({
      root: {
        alignSelf: "stretch",
        minHeight: "initial",
        // marginBottom: 8,
        // marginTop: -4,
        overflow: "initial",
      },
      scroller: {
        overflow: "initial !important",
      },
      flexContainer: {
        height: "100%",
        gap: 2,
      },
      indicator: {
        display: "none",
      },
    })
  );

  const MobileTab = withStyles(
    (props: any) => (
      <Tab
        {...props}
        aria-label={props.icon ? props.label : undefined}
        label={props.icon ? undefined : props.label}
      />
    ),
    (theme) => ({
      root: {
        fontSize,
        minWidth: 10,
        minHeight: "initial",
        fontWeight: "normal",
        lineHeight: "1.6",
        overflow: "initial",
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingBottom: theme.spacing(0.5),
        paddingTop: theme.spacing(1),
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        background: "linear-gradient(180deg, #f1f1f1 23%, #d0d0d0 100%)",
        color: theme.palette.text.primary,
      },
      selected: {
        fontWeight: "bold",
        background: "white",
        color: theme.palette.primary.main,
      },
    })
  );

  return { MobileTabs, MobileTab };
}
