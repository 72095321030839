import { Button } from "@mui/material";
import gql from "graphql-tag";
import React from "react";

import { useMe } from "@/components/Auth";

import { Role, useSyncCamerasToApplianceMutation } from "@/generated-models";

export function SyncCamerasButton({ locationId }: { locationId: number }) {
  const me = useMe();
  const [sync, { loading, data }] = useSyncCamerasToApplianceMutation();

  // Only visible for SpotAI employees
  if (!me || me.role < Role.Success) return null;

  return (
    <Button
      color="primary"
      disabled={loading}
      onClick={() => sync({ variables: { locationId } })}
      style={{ marginRight: "auto" }}
    >
      Sync Cams
      {data ? ` (${data.syncCamerasToAppliance})` : ""}
    </Button>
  );
}

export const SYNC_CAMERAS_TO_APPLIANCE = gql`
  mutation syncCamerasToAppliance($locationId: Int!) {
    syncCamerasToAppliance(locationId: $locationId)
  }
`;
