import { Button, CircularProgress } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";

import { IntegrationDetailsFormItem } from "../Form/Details/IntegrationDetailsFormItem";
import { PLACEHOLDER_ID } from "../constant";
import {
  useCurrentIntegration,
  useEditIntegration,
} from "../hooks/integrationHooks";

type IntegrationEditDetailsPayload = {
  name: string;
  type: number;
};

export function IntegrationEditDetailsForm() {
  const { integration } = useCurrentIntegration();
  const { edit, loading } = useEditIntegration();

  const form = useForm<IntegrationEditDetailsPayload>({
    defaultValues: {
      name: integration?.name || "",
      type: integration?.integrationType?.id || PLACEHOLDER_ID,
    },
    mode: "onChange",
  });

  const name = form.watch("name");

  return (
    <FormProvider {...form}>
      <form
        className="flex items-center justify-start gap-2"
        onSubmit={form.handleSubmit(async ({ name }) => {
          await edit(name, () => {
            form.reset({ name });
          });
        })}
      >
        <IntegrationDetailsFormItem edit />
        {form.formState.isDirty && (
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="shadow-none text-xs leading-[22px] font-normal px-8 sm:mt-4"
            disabled={loading || !name}
          >
            Save
          </Button>
        )}
        {loading && <CircularProgress className="ml-3 sm:mt-4" size={16} />}
      </form>
    </FormProvider>
  );
}
