import { useAtom } from "jotai";
import { StringParam, useQueryParams, withDefault } from "use-query-params";

import { IntelligenceFeatureType } from "@/pages/Intelligence/constants";

import { useMe } from "@/components/Auth";
import { playingIntentState } from "@/components/Player/PlayerBase";

import { OrganizationIndustry } from "@/generated-models";

import { SubjectType } from "../constant";
import { useCopilotEnabled } from "../copilotQueryHooks";

export type NavigateConfig = {
  searchFeature?: SubjectType;
  insightFeature?: IntelligenceFeatureType;
  fallback?: {
    searchFeature?: SubjectType;
    insightFeature?: IntelligenceFeatureType;
  };
};

export enum TutorialType {
  person = "person",
  vehicle = "vehicle",
  default = "default",
  object = "object",
  hidden = "hidden",
}

const CALLOUT_PHY_SPACE = "Reduce incidents on your physical space";
const CALLOUT_ROI = "Increase ROI with people insights";

function buildCallout(
  navigateType: SubjectType | null,
  navigate: NavigateConfig,
  callout: string,
  description?: string | null
) {
  return {
    callout,
    description,
    onClick: () => {
      return { navigate, navigateType };
    },
  };
}

const tutorialParam = withDefault(StringParam, TutorialType.default);

export function useCopilotNavigate() {
  return useQueryParams({
    id: StringParam,
    hoveredId: StringParam,
    tutorialSubject: tutorialParam,
    insightFeature: StringParam,
    searchFeature: StringParam,
  });
}

export function getDefaultCopilotNavigateParams() {
  return {
    id: undefined,
    hoveredId: undefined,
    tutorialSubject: undefined,
    insightFeature: undefined,
    searchFeature: undefined,
  };
}

export function useCopilotCallouts() {
  const me = useMe();

  if (!me) return [];

  switch (me.organization.industry) {
    case OrganizationIndustry.CarWash:
      return [
        buildCallout(
          SubjectType.vehicle,
          {
            searchFeature: SubjectType.vehicle,
          },
          "Investigate damage claims",
          CALLOUT_PHY_SPACE
        ),
        buildCallout(
          SubjectType.person,
          {
            insightFeature: IntelligenceFeatureType.PeoplePresence,
          },
          "Increase throughput",
          CALLOUT_ROI
        ),
      ];
    case OrganizationIndustry.Industrial:
      return [
        buildCallout(
          SubjectType.forklift,
          {
            insightFeature: IntelligenceFeatureType.ForkliftPresence,
            fallback: {
              searchFeature: SubjectType.person,
            },
          },
          "Ensure safe work",
          CALLOUT_PHY_SPACE
        ),
        buildCallout(
          SubjectType.vehicle,
          {
            insightFeature: IntelligenceFeatureType.VehicleIdleTime,
            fallback: {
              insightFeature: IntelligenceFeatureType.VehiclePresence,
            },
          },
          "Reduce truck loading time",
          CALLOUT_ROI
        ),
      ];
    case OrganizationIndustry.Retail:
      return [
        buildCallout(
          SubjectType.person,
          {
            insightFeature: IntelligenceFeatureType.PeoplePresence,
            fallback: {
              searchFeature: SubjectType.person,
            },
          },
          "Reduce shrinkage",
          CALLOUT_PHY_SPACE
        ),
        buildCallout(
          SubjectType.person,
          {
            insightFeature: IntelligenceFeatureType.PeoplePresence,
          },
          "Analyze foot traffic",
          CALLOUT_ROI
        ),
      ];
    case OrganizationIndustry.Sled:
      return [
        buildCallout(
          SubjectType.person,
          {
            searchFeature: SubjectType.person,
          },
          "Investigate persons of interest",
          CALLOUT_PHY_SPACE
        ),
        buildCallout(
          SubjectType.person,
          {
            insightFeature: IntelligenceFeatureType.PeoplePresence,
          },
          "Analyze foot traffic",
          CALLOUT_ROI
        ),
      ];
    default:
      return [
        buildCallout(
          null,
          {
            searchFeature: SubjectType.person,
          },
          CALLOUT_PHY_SPACE
        ),
        buildCallout(
          SubjectType.person,
          {
            insightFeature: IntelligenceFeatureType.PeoplePresence,
          },
          CALLOUT_ROI
        ),
      ];
  }
}

export function useLaunchCopilot() {
  const [playing, setPlaying] = useAtom(playingIntentState);
  const { copilotEnabled, setCopilotEnabled } = useCopilotEnabled();

  return () => {
    if (playing) {
      setPlaying(false);
    }
    if (!copilotEnabled) {
      setCopilotEnabled(true);
    }
  };
}
