import { addHours, startOfHour, subDays } from "date-fns/fp";
import gql from "graphql-tag";
import { useEffect } from "react";

import { MaintainDetialsConnectionSummary as MaintainDetailsConnectionSummary } from "@/pages/Maintain/Details/Analytics/MaintainDetailsConnectionSummary";
import { MaintainDetailsTimeRangePicker } from "@/pages/Maintain/Details/Analytics/MaintainDetailsTimeRangePicker";
import { MaintainDetailsTimeline } from "@/pages/Maintain/Details/Analytics/MaintainDetailsTimeline";
import { useTimeRangeParams } from "@/pages/Maintain/hooks";

import { Loading } from "@/components/Loading";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";

import {
  CameraDetailByIdQuery,
  useCameraActivityQuery,
  useCamfootageMetadataQuery,
} from "@/generated-models";

const now = new Date();
const thirtyDaysAgo = startOfHour(addHours(1)(subDays(30)(now)));
const to = startOfHour(now);

export function MaintainDetailsAnalytics({
  camera,
}: {
  camera: CameraDetailByIdQuery["camera"];
}) {
  const { pushSnackbar } = useFeedback();
  const { fromQuery, toQuery, setTimeRangeParams } = useTimeRangeParams(now);

  // Ensure that the time range is not set before the
  // deployment of the camera.
  useEffect(() => {
    const firstSegment = camera.firstSegmentTime
      ? new Date(camera.firstSegmentTime)
      : null;
    if (firstSegment && firstSegment.getTime() > fromQuery.getTime()) {
      setTimeRangeParams(
        {
          start: camera.firstSegmentTime,
          end: toQuery.toISOString(),
        },
        "replaceIn"
      );
      // Reset range if start range is later than the end range or if they are the same
    } else if (
      fromQuery.getTime() > toQuery.getTime() ||
      fromQuery.getTime() === toQuery.getTime()
    ) {
      setTimeRangeParams(
        {
          start: thirtyDaysAgo.toISOString(),
          end: now.toISOString(),
        },
        "replaceIn"
      );
    }
  }, [camera.firstSegmentTime, toQuery, fromQuery, setTimeRangeParams]);

  const {
    loading: cameraFootageMetadataLoading,
    data: footageMetadata,
    error: cameraFootageMetadataError,
  } = useCamfootageMetadataQuery({
    variables: {
      cameraId: camera.id,
      startTime: thirtyDaysAgo.toISOString(),
      endTime: to.toISOString(),
    },
  });

  const {
    loading: activityLoading,
    data: cameraActivity,
    error: cameraActivityError,
  } = useCameraActivityQuery({
    variables: {
      cameraId: camera.id,
      from: thirtyDaysAgo.toISOString(),
      to: addHours(1)(to).toISOString(), // add 1 hour make sure latest activity is fetched
    },
  });

  useEffect(() => {
    if (cameraFootageMetadataError) {
      pushSnackbar(cameraFootageMetadataError.message, FeedbackType.Error);
    }

    // eslint-disable-next-line
  }, [cameraFootageMetadataError]);

  useEffect(() => {
    if (cameraActivityError) {
      pushSnackbar(cameraActivityError.message, FeedbackType.Error);
    }
    // eslint-disable-next-line
  }, [cameraActivityError]);

  return (
    <div>
      <MaintainDetailsTimeRangePicker
        camera={camera}
        now={now}
        timezone={camera.location.timezone}
      />
      {(cameraFootageMetadataLoading || activityLoading) && <Loading />}
      {footageMetadata && cameraActivity && (
        <>
          <MaintainDetailsTimeline
            connectivityData={footageMetadata}
            cameraActivity={cameraActivity?.cameraActivity}
            now={now}
          />
          <MaintainDetailsConnectionSummary
            footageMetadata={footageMetadata}
            now={now}
          />
        </>
      )}
    </div>
  );
}

// Query to get missing segments by cameraId
gql`
  query CamfootageMetadata(
    $cameraId: Int!
    $startTime: String!
    $endTime: String!
  ) {
    camera(id: $cameraId) {
      id
      footageBounds {
        start
        end
      }
      missingSegments(startTime: $startTime, endTime: $endTime) {
        start
        duration
      }
    }
  }
`;

gql`
  query CameraActivity($cameraId: Int!, $from: String!, $to: String!) {
    cameraActivity(cameraId: $cameraId, from: $from, to: $to) {
      activity
      startTime
      endTime
    }
  }
`;
