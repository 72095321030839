import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import React, { useMemo, useState } from "react";

import { concat } from "@/util/apolloCache";

import { useSetCameraMotionZone } from "@/pages/Search/searchHooks";

import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { ZendeskArticle, ZendeskLink } from "@/components/Zendesk/ZendeskLink";
import ZoneInUseDialog from "@/components/Zones/ZoneInUseDialog";
import { ZoneIcon } from "@/components/Zones/ZoneSvgs";
import { Point } from "@/components/Zones/getSectorsForPolygon";

import {
  useAddFocusMutation,
  useDeleteFocusMutation,
} from "@/generated-models";

interface TempZonePopoverProps {
  cameraId: number;
  shape: Point[];
  editZone: () => void;
  close: () => void;
  compact: boolean;
}

export function TempZonePopover({
  cameraId,
  shape,
  editZone,
  close,
  compact,
}: TempZonePopoverProps) {
  const [newZoneName, setZoneName] = useState("");
  const { pushSnackbar } = useFeedback();
  const setZone = useSetCameraMotionZone();
  const [addFocus, { loading: adding }] = useAddFocusMutation({
    onCompleted: (data) => {
      close();
      setZone(cameraId, data.addFocus.id);
      pushSnackbar("Successfully created zone", FeedbackType.Success);
    },
    onError: (e) => {
      console.error(e);
      pushSnackbar(
        "Unable to create Zone, please try again later",
        FeedbackType.Error
      );
    },
  });

  return (
    <>
      <div className="flex items-center gap-2 max-w-lg">
        {!compact && <ZoneIcon />}
        <div className={compact ? "" : "text-base"}>
          <strong className={compact ? "font-bold" : "text-lg"}>
            Temporary zone created!
          </strong>{" "}
          Name and save this zone to use for future searches and/or create
          zone-based motion alerts.
        </div>
      </div>

      <div className="flex gap-2 items-end mt-1">
        <TextField
          label="Zone Name"
          size="small"
          InputProps={{ className: "text-white before:border-white" }}
          InputLabelProps={{ className: "text-gray-400" }}
          value={newZoneName}
          onChange={(event) => setZoneName(event.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          disabled={adding}
          onClick={() => {
            addFocus({
              variables: {
                cameraId,
                input: { name: newZoneName || "Saved Zone", shape },
              },
              update: (cache, { data }) => {
                if (!data) return;
                cache.modify({
                  id: `Camera:${cameraId}`,
                  fields: {
                    focusZones: concat({
                      __ref: `Focus:${data.addFocus.id}`,
                    }),
                  },
                });
              },
            });
          }}
        >
          Save Zone
        </Button>
        <div className="grow" />
        <Button
          variant="outlined"
          className="text-white border-white "
          onClick={() => {
            setZone(cameraId, undefined);
            close();
          }}
        >
          Clear
        </Button>
        <Button
          variant="outlined"
          className="text-white border-white "
          startIcon={<EditIcon fontSize="small" />}
          onClick={() => {
            editZone();
            close();
          }}
        >
          Edit Zone
        </Button>
      </div>
    </>
  );
}

interface SavedZonePopoverProps {
  cameraId: number;
  activeZone: { id: number; shape: Point[] };
  zones: { id: number; name: string; shape: { x: number; y: number }[] }[];
  editZone: () => void;
  createNew: () => void;
  createAlert: () => void;
  close: () => void;
  compact: boolean;
}

export function SavedZonePopover({
  cameraId,
  activeZone,
  zones,
  editZone,
  createNew,
  createAlert,
  close,
  compact,
}: SavedZonePopoverProps) {
  const { pushSnackbar } = useFeedback();
  const [zoneInUseDialogOpen, setZoneInUseDialogOpen] = useState(false);
  const setZone = useSetCameraMotionZone();
  const [deleteFocus, { loading: deleting }] = useDeleteFocusMutation({
    onCompleted: (data) => {
      const otherZones = zones.filter(({ id }) => id !== data.deleteFocus);
      if (otherZones.length > 0) setZone(cameraId, otherZones[0].id);
      else {
        setZone(cameraId, undefined);
        close();
      }
      pushSnackbar("Successfully deleted zone", FeedbackType.Success);
    },
    onError: (e) => {
      console.error(e);
      if (e.message === "Used by resource") {
        setZoneInUseDialogOpen(true);
      } else {
        pushSnackbar(
          "Something went wrong while deleting the zone, please try again",
          FeedbackType.Error
        );
      }
    },
  });

  const deleteIcon = deleting ? (
    <CircularProgress size={20} />
  ) : (
    <DeleteIcon fontSize="small" />
  );

  const filteredZones = useMemo(() => zones.filter((z) => z.shape.length > 2), [
    zones,
  ]);

  return (
    <>
      <div className="flex gap-3 items-center">
        {!compact && (
          <>
            <ZoneIcon fontSize="large" />
            <div className="mr-2 text-lg font-medium leading-tight">
              <div>Zone Based</div>
              <div>
                Motion Filter
                <ZendeskLink
                  color="inherit"
                  className="!mt-0"
                  article={ZendeskArticle.ZONE_BASED_MOTION_FILTER}
                />
              </div>
            </div>
          </>
        )}
        <FormControl variant="outlined" size="small">
          <InputLabel htmlFor="zone-input-label" className="text-white">
            Zone
          </InputLabel>
          <Select
            labelId="zone-input-label"
            value={activeZone.id}
            onChange={(e) => {
              const newValue = e.target.value;
              if (typeof newValue === "number") {
                setZone(cameraId, newValue);
              } else if (newValue === "_NEW_") {
                createNew();
                close();
              } else {
                console.error("unexpected format for focus id:", newValue);
              }
            }}
            input={
              <OutlinedInput
                label="Zone"
                classes={{ notchedOutline: "border-white" }}
              />
            }
            renderValue={(selected) => {
              if (typeof selected === "number") {
                const zone = zones.find((z) => z.id === selected);
                return zone ? zone.name : "Unknown";
              }
              return "Invalid value";
            }}
            className="min-w-[200px] text-white font-medium"
            classes={{ icon: "text-white" }}
            MenuProps={{ classes: { list: "p-0" } }}
          >
            {filteredZones.map((zone) => {
              const selected = activeZone.id === zone.id;
              return (
                <MenuItem
                  value={zone.id}
                  key={zone.id}
                  classes={{
                    root: clsx("p-2 text-white md:hover:bg-opacity-90", {
                      "pl-10 bg-[#444545]": !selected,
                    }),
                    selected: "bg-primary font-bold",
                  }}
                  disableGutters
                >
                  {selected && <CheckIcon className="mr-2" />}
                  {zone.name}
                </MenuItem>
              );
            })}
            <MenuItem
              value="_NEW_"
              classes={{
                root:
                  "p-2 text-white bg-[#444545] md:hover:bg-opacity-90 font-medium",
              }}
              disableGutters
            >
              <AddIcon className="mr-2" /> Create New Zone
            </MenuItem>
          </Select>
        </FormControl>
        <Tooltip title={compact ? "Edit Zone" : ""}>
          <Button
            variant="outlined"
            className={clsx("text-white border-white", {
              "p-[9px] min-w-0": compact,
            })}
            startIcon={compact ? undefined : <EditIcon fontSize="small" />}
            onClick={() => {
              editZone();
              close();
            }}
          >
            {compact ? <EditIcon fontSize="small" /> : "Edit Zone"}
          </Button>
        </Tooltip>
        <Tooltip title={compact ? "Delete Zone" : ""}>
          <Button
            variant="outlined"
            className={clsx("text-white border-white", {
              "p-[9px] min-w-0": compact,
            })}
            startIcon={compact ? undefined : deleteIcon}
            disabled={deleting}
            onClick={() => {
              deleteFocus({
                variables: { focusId: activeZone.id },
                update(cache, { data }) {
                  if (!data) return;
                  cache.evict({ id: `Focus:${data.deleteFocus}` });
                },
              });
            }}
          >
            {compact ? deleteIcon : "Delete Zone"}
          </Button>
        </Tooltip>
        <Button
          variant="contained"
          color="primary"
          startIcon={<NotificationsIcon />}
          onClick={() => {
            createAlert();
            close();
          }}
          className="ml-4"
        >
          Create Alert
        </Button>
      </div>
      <ZoneInUseDialog
        open={zoneInUseDialogOpen}
        onClose={() => setZoneInUseDialogOpen(false)}
      />
    </>
  );
}
interface MobileSavedZonePopoverProps {
  cameraId: number;
  activeZone: { id: number; shape: Point[] };
  zones: { id: number; name: string; shape: Point[] }[];
  editZone: () => void;
  createNew: () => void;
  createAlert: () => void;
  close: () => void;
}

export function MobileSavedZonePopover({
  cameraId,
  activeZone,
  zones,
  editZone,
  createNew,
  createAlert,
  close,
}: MobileSavedZonePopoverProps) {
  const { pushSnackbar } = useFeedback();
  const [zoneInUseDialogOpen, setZoneInUseDialogOpen] = useState(false);
  const setZone = useSetCameraMotionZone();
  const [deleteFocus, { loading: deleting }] = useDeleteFocusMutation({
    onCompleted: (data) => {
      const otherZones = zones.filter(({ id }) => id !== data.deleteFocus);
      if (otherZones.length > 0) setZone(cameraId, otherZones[0].id);
      else {
        setZone(cameraId, undefined);
        close();
      }
      pushSnackbar("Successfully deleted zone", FeedbackType.Success);
    },
    onError: (e) => {
      console.error(e);
      if (e.message === "Used by resource") {
        setZoneInUseDialogOpen(true);
      } else {
        pushSnackbar(
          "Something went wrong while deleting the zone, please try again",
          FeedbackType.Error
        );
      }
    },
  });

  const filteredZones = useMemo(() => zones.filter((z) => z.shape.length > 2), [
    zones,
  ]);

  return (
    <>
      <div className="w-full grid grid-cols-2 gap-3 text-text">
        <div className="col-span-2 font-medium text-base">
          <ZoneIcon fontSize="medium" /> Zone Based Motion Filter
        </div>
        <FormControl size="small" fullWidth className="col-span-2">
          <InputLabel id="zone-select-label">Zone</InputLabel>
          <Select
            labelId="zone-select-label"
            id="zone-select"
            variant="outlined"
            label="Zone"
            size="small"
            MenuProps={{ classes: { list: "p-0" } }}
            value={activeZone.id}
            renderValue={(selected) => {
              if (typeof selected === "number") {
                const zone = zones.find((z) => z.id === selected);
                return zone ? zone.name : "Unknown";
              }
              return "Invalid value";
            }}
            onChange={(e) => {
              const newValue = e.target.value;
              if (typeof newValue === "number") {
                setZone(cameraId, newValue);
              } else {
                console.error("unexpected format for focus id:", newValue);
              }
            }}
          >
            {filteredZones.map((zone) => {
              const selected = activeZone.id === zone.id;
              return (
                <MenuItem
                  value={zone.id}
                  key={zone.id}
                  classes={{
                    root: clsx("grid grid-cols-[32px_1fr_auto_auto]"),
                    selected: "bg-primary font-bold text-white",
                  }}
                >
                  <span>{selected && <CheckIcon className="mr-2" />}</span>
                  <span>{zone.name}</span>
                  <IconButton
                    className="text-inherit mr-2"
                    size="small"
                    disabled={deleting}
                    onClick={() => {
                      editZone();
                      close();
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    className="text-inherit ml-2"
                    size="small"
                    disabled={deleting}
                    onClick={() => {
                      deleteFocus({
                        variables: { focusId: activeZone.id },
                        update(cache, { data }) {
                          if (!data) return;
                          cache.evict({ id: `Focus:${data.deleteFocus}` });
                        },
                      });
                    }}
                  >
                    {deleting ? (
                      <CircularProgress size={20} />
                    ) : (
                      <DeleteIcon fontSize="small" />
                    )}
                  </IconButton>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <Button
          variant="contained"
          className="bg-[#DBEDFB] text-primary w-full shadow-none"
          startIcon={<AddIcon />}
          onClick={() => {
            createNew();
            close();
          }}
        >
          New Zone
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<NotificationsIcon />}
          onClick={() => {
            createAlert();
            close();
          }}
        >
          Create Alert
        </Button>
      </div>
      <ZoneInUseDialog
        open={zoneInUseDialogOpen}
        onClose={() => setZoneInUseDialogOpen(false)}
      />
    </>
  );
}

interface MobileTempZonePopoverProps {
  cameraId: number;
  shape: Point[];
  editZone: () => void;
  close: () => void;
}

export function MobileTempZonePopover({
  cameraId,
  shape,
  editZone,
  close,
}: MobileTempZonePopoverProps) {
  const [newZoneName, setZoneName] = useState("");
  const { pushSnackbar } = useFeedback();
  const setZone = useSetCameraMotionZone();
  const [addFocus, { loading: adding }] = useAddFocusMutation({
    onCompleted: (data) => {
      close();
      setZone(cameraId, data.addFocus.id);
      pushSnackbar("Successfully created zone", FeedbackType.Success);
    },
    onError: (e) => {
      console.error(e);
      pushSnackbar(
        "Unable to create Zone, please try again later",
        FeedbackType.Error
      );
    },
  });

  return (
    <div className="w-full grid grid-cols-8 gap-3 text-text">
      <div className="col-span-8 font-medium text-base">
        <ZoneIcon fontSize="medium" /> Temporary zone created!!
      </div>
      <Typography
        variant="body2"
        className="col-span-8 font-sm leading-4 text-gray-75"
      >
        Name and save this zone to use for future searches and/or create
        zone-based motion alerts.
      </Typography>
      <TextField
        className="col-span-5"
        id="outlined-required"
        size="small"
        variant="standard"
        label="Zone Name"
        value={newZoneName}
        onChange={(event) => setZoneName(event.target.value)}
      />
      <Button
        variant="contained"
        color="primary"
        className="col-span-3"
        disabled={adding}
        onClick={() => {
          addFocus({
            variables: {
              cameraId,
              input: { name: newZoneName || "Saved Zone", shape },
            },
            update: (cache, { data }) => {
              if (!data) return;
              cache.modify({
                id: `Camera:${cameraId}`,
                fields: {
                  focusZones: concat({
                    __ref: `Focus:${data.addFocus.id}`,
                  }),
                },
              });
            },
          });
        }}
      >
        Save Zone
      </Button>
      <Button
        variant="outlined"
        color="primary"
        className="col-span-4"
        onClick={() => {
          setZone(cameraId, undefined);
          close();
        }}
      >
        Clear
      </Button>
      <Button
        variant="contained"
        className="col-span-4 bg-[#DBEDFB] text-primary w-full shadow-none"
        startIcon={<EditIcon />}
        onClick={() => {
          editZone();
          close();
        }}
      >
        Edit Zone
      </Button>
    </div>
  );
}
