import CloseIcon from "@mui/icons-material/Close";
import FilterIcon from "@mui/icons-material/FilterList";
import CommentIcon from "@mui/icons-material/ModeComment";
import ClipIcon from "@mui/icons-material/Movie";
import LocationIcon from "@mui/icons-material/Room";
import {
  Badge,
  Button,
  Chip,
  Container,
  Dialog,
  Divider,
  FormControl,
  Grid,
  Hidden,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Slide,
  SlideProps,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import clsx from "clsx";
import { endOfDay, format, isAfter, isBefore, startOfDay } from "date-fns/fp";
import { FastField, Field, Form, Formik, useField } from "formik";
import { TextField as FormikTextField } from "formik-mui";
import { DatePicker as FormikDatePicker } from "formik-mui-x-date-pickers";
import gql from "graphql-tag";
import { capitalize, keyBy, orderBy, uniqBy } from "lodash/fp";
import React, { ReactNode, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { ReactComponent as CasesIcon } from "@/icons/CasesIcon_filled.svg";
import { ReactComponent as CaseIcon } from "@/icons/case.svg";

import { formatIsoDate } from "@/util/date";
import { filterFalsy, filterNullish } from "@/util/filterFalsy";
import { pluralize } from "@/util/pluralize";
import { useBreakpoints } from "@/util/useBreakpoints";
import { useDocumentTitle } from "@/util/useDocumentTitle";
import { usePagination } from "@/util/usePagination";
import { useSlugMatch } from "@/util/useSlugMatch";

import { CaseTagSelect } from "@/pages/Cases/AddCaseTagButton";
import { AnnotationLabel } from "@/pages/Cases/AnnotationLabel";
import { chipProps } from "@/pages/Cases/Case";
import { CasesEmptyState } from "@/pages/Cases/CasesEmptyState";

import { isDemoUser, useMe } from "@/components/Auth";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { LocationsSelect } from "@/components/LocationsSelect";
import { SearchBox } from "@/components/SearchBox";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { ZendeskArticle, ZendeskLink } from "@/components/Zendesk/ZendeskLink";
import { DefaultDialog, useDialog } from "@/components/shared/Dialog";
import { LinkWithInheritedStyles } from "@/components/shared/LinkWithInheritedStyles";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  ResolutionStatus,
  useCreateCaseMutation,
  usePage_CasesQuery,
} from "@/generated-models";

import { CaseCollaborator } from "./CaseCollaboratorChip";
import { CaseCollaboratorSelect } from "./CaseCollaboratorSelect";
import { CaseDescription } from "./CaseDescription";

const headerContent: string[] = [
  "Save, share, and download clips that matter to you",
  "Annotate video clips to mark events and guide the discussion",
  "Share and comment with your team members and external users",
  "Track the progress of the case by resolution status",
];
const useStyles = makeStyles()(() => ({
  description: {
    // https://css-tricks.com/line-clampin/#weird-webkit-flexbox-way
    // Didn't work when I tried to put this in a style attribute
    display: "-webkit-box",
    lineClamp: 3 /* number of lines to show */,
    boxOrient: "vertical",
  },
}));

const pageSize = 6;
export function Cases() {
  useDocumentTitle("Cases");
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { fitsTablet } = useBreakpoints();
  const [searchInput, setSearchInput] = useState("");
  const [tagsInput, setTagsInput] = useState<string[]>([]);
  const [locationsInput, setLocationsInput] = useState<number[]>([]);
  const [fromDateInput, setFromDateInput] = useState<Date | null>(null);
  const [toDateInput, setToDateInput] = useState<Date | null>(null);
  const routeMatch = useSlugMatch("cases/:tab");
  const { data, error } = usePage_CasesQuery(refetchOnMountPolicy);
  const { visibilitySensor, pageCount } = usePagination(3);
  const locations = useMemo(() => {
    if (data?.cases) {
      const list = uniqBy(
        "id",
        data.cases.flatMap((c) => c.locations)
      ).map((l) => ({ id: l.id, label: l.name }));
      const map = keyBy("id", list);
      return {
        list,
        selected: locationsInput.map((id) => map[id]).filter(filterNullish),
      };
    }
  }, [data, locationsInput]);

  if (error) {
    return (
      <ErrorMessage title="Unable to fetch cases" description={error.message} />
    );
  }
  if (!data?.cases || !locations) return <Loading grow>Fetching Cases</Loading>;
  const { cases } = data;

  const normalizedSearchInput = searchInput.toLowerCase().trim();
  const filteredCases = cases
    .filter(
      (c) =>
        !routeMatch ||
        routeMatch.params.tab === "all" ||
        routeMatch.params.tab === c.resolutionStatus
    )
    .filter(
      (c) =>
        c.title.toLowerCase().includes(normalizedSearchInput) ||
        c.description.toLowerCase().includes(normalizedSearchInput)
    )
    .filter(
      (c) =>
        tagsInput.length === 0 || tagsInput.some((tag) => c.tags.includes(tag))
    )
    .filter(
      (c) =>
        locationsInput.length === 0 ||
        c.locations.some((l) => locationsInput.includes(l.id))
    )
    .filter(
      (c) =>
        !fromDateInput ||
        isAfter(fromDateInput, new Date(c.incidentDate ?? c.createdAt))
    )
    .filter(
      (c) =>
        !toDateInput ||
        isBefore(endOfDay(toDateInput), new Date(c.incidentDate ?? c.createdAt))
    );

  const CaseListControls = fitsTablet
    ? DesktopCaseListControls
    : MobileCaseListControls;

  const caseCounts = {
    all: cases.length,
    open: cases.filter((x) => x.resolutionStatus === ResolutionStatus.Open)
      .length,
  };

  const activeFilters = [
    ...locations.selected.map((loc) => (
      <Chip
        key={loc.id}
        label={loc.label}
        onDelete={() =>
          setLocationsInput((ids) => ids.filter((id) => id !== loc.id))
        }
        {...chipProps}
      />
    )),
    ...tagsInput.map((tag) => (
      <Chip
        key={tag}
        label={tag}
        onDelete={() => setTagsInput((tags) => tags.filter((t) => t !== tag))}
        {...chipProps}
      />
    )),
    fromDateInput && (
      <Chip
        key="fromDate"
        label={`From: ${format("PP", fromDateInput)}`}
        onDelete={() => setFromDateInput(null)}
        {...chipProps}
      />
    ),
    toDateInput && (
      <Chip
        key="toDate"
        label={`To: ${format("PP", toDateInput)}`}
        onDelete={() => setToDateInput(null)}
        {...chipProps}
      />
    ),
    routeMatch?.params.tab && routeMatch.params.tab !== "all" && (
      <Chip
        key="status"
        label={`Status: ${capitalize(routeMatch.params.tab)}`}
        onDelete={() => navigate(".", { replace: true })}
        {...chipProps}
      />
    ),
  ].filter(filterFalsy);

  return (
    <>
      {cases.length === 0 ? (
        <div
          data-cy="case-item"
          className="h-full w-full sm:bg-casesGradient md:overflow-hidden md:bg-none relative"
        >
          <Container
            maxWidth="lg"
            className="pt-8 md:mt-16 lg:mt-32 relative z-10 pb-8 md:pb-0"
          >
            <CasesEmptyState
              createCaseButton={
                <CreateCaseButton tags={cases.flatMap((c) => c.tags)} />
              }
              headerContent={headerContent}
            />
          </Container>
          <div className="bg-whiteToTransparent h-full w-full absolute top-0 left-0 z-1" />
        </div>
      ) : (
        <>
          <CasesHeader />
          {/* Filters and actions bar */}
          <Container maxWidth="lg">
            <CaseListControls
              countIndicator={
                <Typography className="font-bold text-lg">
                  {pluralize(
                    { 1: "1 Case", multi: `${filteredCases.length} Cases` },
                    filteredCases.length
                  )}
                </Typography>
              }
              search={
                <SearchBox
                  input={searchInput}
                  setInput={setSearchInput}
                  placeholder="Search"
                  className="max-w-[199px]"
                />
              }
              filters={[
                <div key="locations" className="relative sm:w-48 sm:h-10 z-1">
                  <LocationsSelect
                    options={locations.list}
                    value={locations.selected}
                    onChange={setLocationsInput}
                    placeholder=""
                    limitTags={0}
                    className="sm:absolute sm:bg-gray-fb"
                  />
                </div>,
                <div key="tags" className="relative sm:w-48 sm:h-10 z-1">
                  <CaseTagSelect
                    tags={cases.flatMap((c) => c.tags)}
                    selectedTags={tagsInput}
                    setSelectedTags={setTagsInput}
                    label="Tags"
                    placeholder=""
                    limitTags={0}
                    className="sm:absolute sm:bg-gray-fb"
                    size="small"
                  />
                </div>,
                <DateFieldSelector
                  key="from"
                  label="From Date"
                  value={fromDateInput}
                  onChange={setFromDateInput}
                />,
                <DateFieldSelector
                  key="to"
                  label="To Date"
                  value={toDateInput}
                  onChange={setToDateInput}
                />,
                <FormControl
                  key="status"
                  variant="outlined"
                  size="small"
                  className="min-w-[120px]"
                >
                  <InputLabel>Status</InputLabel>
                  <Select
                    variant="outlined"
                    label="Status"
                    value={routeMatch?.params.tab ?? ""}
                    onChange={(event) =>
                      navigate(event.target.value || ".", { replace: true })
                    }
                  >
                    <MenuItem value="">
                      <em>{labelWithCount("All", caseCounts.all)}</em>
                    </MenuItem>
                    <MenuItem value={ResolutionStatus.Open}>
                      {labelWithCount("Open", caseCounts.open)}
                    </MenuItem>
                    <MenuItem value={ResolutionStatus.Closed}>
                      {labelWithCount(
                        "Closed",
                        caseCounts.all - caseCounts.open
                      )}
                    </MenuItem>
                  </Select>
                </FormControl>,
              ]}
              createCaseButton={
                <CreateCaseButton tags={cases.flatMap((c) => c.tags)} />
              }
            />
          </Container>
          {/* Active filters bar */}
          {!!activeFilters.length && (
            <div className="mt-3 py-3 bg-[#F6FBFF]">
              <Container maxWidth="lg" className="flex items-center">
                <Hidden smDown>
                  <Typography className="font-medium shrink-0 mr-6">
                    Active filters
                  </Typography>
                </Hidden>
                <div className="flex flex-wrap items-center gap-1 min-w-0">
                  {activeFilters}
                  <Button
                    color="primary"
                    onClick={() => {
                      if (locationsInput.length) setLocationsInput([]);
                      if (tagsInput.length) setTagsInput([]);
                      if (routeMatch?.params.tab) {
                        navigate(".", { replace: true });
                      }
                      if (fromDateInput) setFromDateInput(null);
                      if (toDateInput) setToDateInput(null);
                    }}
                  >
                    Clear all filters
                  </Button>
                </div>
              </Container>
            </div>
          )}
          {/* Cases List */}
          <Container maxWidth="lg" className="pt-8">
            {filteredCases.length === 0 && (
              <Typography className="mt-20 text-center">
                No Cases Found...
              </Typography>
            )}
            <Grid container spacing={2}>
              {orderBy("createdAt", "desc", filteredCases)
                .slice(0, pageCount * pageSize)
                .map((c) => (
                  <Grid key={c.id} item xs={12} sm={6} md={4}>
                    <Paper
                      className="flex flex-col h-full rounded-md overflow-hidden"
                      elevation={4}
                    >
                      <div className="p-3 grow bg-[#F1F1F1]">
                        <div className="flex items-center">
                          <CaseIcon
                            width="28"
                            height="100%"
                            className="shrink-0"
                          />
                          <div className="ml-4">
                            <Typography variant="h3">
                              <LinkWithInheritedStyles to={String(c.id)}>
                                {c.title}
                              </LinkWithInheritedStyles>
                            </Typography>
                          </div>
                          {c.isNew && (
                            <div className="px-1.5 py-px ml-4 rounded-lg text-white bg-primary text-xs">
                              new
                            </div>
                          )}
                        </div>
                        {c.locations.length > 0 &&
                          c.locations.slice(0, 3).map((l, i, list) => (
                            <div
                              key={l.id}
                              className="flex items-center ml-12 min-w-0"
                            >
                              <LocationIcon className="mr-1 text-base" />
                              <Typography className="text-sm overflow-hidden whitespace-nowrap text-ellipsis">
                                {i === list.length - 1 &&
                                c.locations.length > list.length
                                  ? `+ ${
                                      c.locations.length - list.length + 1
                                    } more`
                                  : l.name}
                              </Typography>
                            </div>
                          ))}
                        <Divider className="my-2" />
                        <Typography variant="h6">Description</Typography>
                        <CaseDescription
                          className={clsx(
                            "overflow-hidden text-ellipsis",
                            classes.description
                          )}
                          description={c.description}
                        />
                        {c.tags.length > 0 && (
                          <>
                            <Typography variant="h6" className="mt-2">
                              Tags
                            </Typography>
                            <div className="flex flex-wrap gap-1">
                              {c.tags
                                .slice()
                                .sort()
                                .slice(0, 10)
                                .map((tag, i, list) => (
                                  <Chip
                                    key={i}
                                    label={
                                      i === list.length - 1 &&
                                      c.tags.length > list.length
                                        ? `+ ${
                                            c.tags.length - list.length + 1
                                          } more`
                                        : tag
                                    }
                                    {...chipProps}
                                  />
                                ))}
                            </div>
                          </>
                        )}
                      </div>
                      <div className="px-4">
                        <InteractionCountItem
                          singular="Clip"
                          plural="Clips"
                          count={c.counts.clips}
                          notificationCount={c.notificationCounts.clips}
                          icon={<ClipIcon />}
                        />
                        <InteractionCountItem
                          singular="Annotation"
                          plural="Annotations"
                          count={c.counts.annotations}
                          notificationCount={c.notificationCounts.annotations}
                          icon={<AnnotationLabel>{""}</AnnotationLabel>}
                        />
                        <InteractionCountItem
                          singular="Comment"
                          plural="Comments"
                          count={c.counts.comments}
                          notificationCount={c.notificationCounts.comments}
                          icon={<CommentIcon />}
                        />
                      </div>
                      <div className="flex justify-between items-center p-4 pt-2">
                        <div>
                          <Typography className="opacity-50 leading-none">
                            Resolution Status
                          </Typography>
                          <Typography
                            className={clsx(
                              "leading-none capitalize",
                              c.resolutionStatus === ResolutionStatus.Closed
                                ? "text-secondary"
                                : "text-inherit"
                            )}
                          >
                            <strong>{c.resolutionStatus}</strong>
                          </Typography>
                        </div>
                        <Button
                          component={Link}
                          to={String(c.id)}
                          variant="contained"
                          color="primary"
                          className="min-w-[100px] text-base font-bold"
                          size="small"
                        >
                          View
                        </Button>
                      </div>
                    </Paper>
                  </Grid>
                ))}
            </Grid>
            {visibilitySensor}
          </Container>
        </>
      )}
    </>
  );
}

function CasesHeader() {
  return (
    <header data-cy="case-item" className="relative sm:bg-casesGradient">
      <Container
        className="py-0 sm:py-4 flex justify-start md:justify-between items-center px-0 relative z-10"
        maxWidth="lg"
      >
        <img
          className="w-[35%] h-auto md:h-[210px] md:w-auto"
          src="/CasesHeaderIllustration.svg"
          alt=""
        />
        <div className="md:shrink-0 ml-3 md:ml-12">
          <h1 className="text-[22px] sm:text-4xl md:text-[44px] text-text font-bold flex items-center">
            <CasesIcon className="w-6 md:w-auto" />
            <span className="leading-4 ml-2">
              Cases
              <ZendeskLink article={ZendeskArticle.CASES} />
            </span>
          </h1>
          <h2 className="text-sm sm:text-2xl md:text-[32px] text-primary font-light">
            Investigate. Collaborate. <strong>Resolve.</strong>
          </h2>
        </div>
        <Divider orientation="vertical" className="h-16 mx-8 hidden md:block" />
        <ul className="list-disc list-inside hidden md:block">
          {headerContent.map((listText, index) => (
            <li key={`listItem-${index}`} className="text-primary">
              <span className="text-text">{listText}</span>
            </li>
          ))}
        </ul>
      </Container>
      <div className="bg-whiteToTransparent h-full w-full absolute top-0 left-0 z-1" />
    </header>
  );
}

function CreateCaseButton({ tags }: { tags: string[] }) {
  const me = useMe();
  const isDemo = !!me && isDemoUser(me); // only relevant for demo orgs
  const { pushSnackbar } = useFeedback();
  const navigate = useNavigate();
  const [createCase] = useCreateCaseMutation();
  const {
    open: openCreateCaseDialog,
    confirm: confirmCaseCreation,
    ...dialogProps
  } = useDialog();
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        className="grow sm:grow-0 shrink-0"
        onClick={openCreateCaseDialog}
        disabled={isDemo}
      >
        Create Case
      </Button>
      <Formik
        initialValues={{
          title: "",
          description: "",
          collaborators: [] as CaseCollaborator[],
          tags: [] as string[],
          incidentDate: null as Date | null,
        }}
        onSubmit={async ({ collaborators, ...values }, { setSubmitting }) => {
          try {
            const { data, errors } = await createCase({
              variables: {
                value: {
                  ...values,
                  collaborators: collaborators.map((c) => ({
                    orgUserId: c.orgUserId,
                    caseRole: c.caseRole,
                  })),
                  incidentDate: values.incidentDate
                    ? formatIsoDate(values.incidentDate)
                    : null,
                },
              },
            });
            if (errors && errors.length > 0) {
              throw errors[0];
            }

            if (data?.createCase) {
              navigate(String(data.createCase.id));
            }
          } catch (error: any) {
            const spotErrorCode =
              error.extensions?.exception?.code ??
              error.graphQLErrors?.[0].extensions?.exception?.code;
            console.log("hello?", { error, spotErrorCode });
            pushSnackbar(
              spotErrorCode === "NOT_IN_ORG"
                ? error.message
                : "Something went wrong while creating the case, please try again later.",
              FeedbackType.Error
            );
          }
          setSubmitting(false);
        }}
      >
        {({ submitForm, errors, isSubmitting }) => (
          <DefaultDialog
            {...dialogProps}
            title="Create Case"
            confirm={() => {
              submitForm();
            }}
            confirmationDisabled={
              isSubmitting || Object.values(errors).some(Boolean)
            }
            content={
              <Form>
                <Typography>
                  Please provide some initial information on the case. Don't
                  worry, you can always change this later on.
                </Typography>
                <div className="flex flex-col my-4 gap-4">
                  <FastField
                    component={FormikTextField}
                    name="title"
                    label="Title"
                    validate={required("Please provide a title")}
                    required
                    fullWidth
                    variant="outlined"
                    autoFocus
                  />
                  <FastField
                    component={FormikTextField}
                    name="description"
                    label="Description"
                    validate={required("Please provide a description")}
                    required
                    fullWidth
                    variant="outlined"
                    multiline
                    rows={5}
                  />
                  <CaseTagsSelectField name="tags" tags={tags} />
                  <CaseCollaboratorSelectField name="collaborators" />
                  <Field
                    name="incidentDate"
                    inputFormat="MM/dd/yyyy"
                    disableFuture
                    textField={{
                      label: "Incident Date",
                      variant: "outlined",
                      fullWidth: true,
                    }}
                    component={FormikDatePicker}
                  />
                </div>
              </Form>
            }
          />
        )}
      </Formik>
    </>
  );
}

function DateFieldSelector({
  value,
  onChange,
  label,
}: {
  value: Date | null;
  onChange: (value: Date | null) => void;
  label: string;
}) {
  const [open, setOpen] = useState(false);

  return (
    <DatePicker
      open={open}
      onClose={() => setOpen(false)}
      InputProps={{
        placeholder: "",
      }}
      OpenPickerButtonProps={{
        className: "hidden",
      }}
      inputFormat="PP"
      disableMaskedInput
      label={label}
      value={value}
      onChange={(date) => onChange(startOfDay(date) ?? null)}
      disableFuture
      renderInput={({ inputProps, ...props }) => (
        <div>
          <TextField
            placeholder={label}
            {...(props as TextFieldProps)}
            variant="outlined"
            size="small"
            inputProps={{
              ...inputProps,
              placeholder: props.value ? undefined : label,
            }}
            InputProps={{
              className: "pointer-events-none",
            }}
            className="w-full sm:w-[120px]"
            classes={{ root: "pr-0" }}
            onClick={(e) => {
              e.preventDefault();
              setOpen(!open);
            }}
          />
        </div>
      )}
    />
  );
}

function labelWithCount(label: string, count?: number) {
  if (count) return `${label} (${count})`;
  return label;
}

interface CaseListControlsProps {
  countIndicator: ReactNode;
  search: ReactNode;
  filters: ReactNode[];
  createCaseButton: ReactNode;
}

function DesktopCaseListControls({
  countIndicator,
  search,
  filters,
  createCaseButton,
}: CaseListControlsProps) {
  return (
    <div className="flex flex-wrap gap-x-2 gap-y-3">
      <div className="flex justify-between items-end gap-2 grow">
        {countIndicator}
        {search}
      </div>
      <div className="flex flex-wrap gap-2 ml-auto">
        {filters}
        <Divider orientation="vertical" className="h-7 my-auto mx-1" />
        {createCaseButton}
      </div>
    </div>
  );
}
function MobileCaseListControls({
  countIndicator,
  search,
  filters,
  createCaseButton,
}: CaseListControlsProps) {
  const [filtersOpen, setFiltersOpen] = useState(false);
  const openFilters = () => setFiltersOpen(true);
  const closeFilters = () => setFiltersOpen(false);

  return (
    <div className="flex flex-col gap-3">
      <div className="flex justify-between items-end gap-3">
        {countIndicator}
        {search}
      </div>
      <div className="flex gap-3">
        <Button
          variant="outlined"
          color="primary"
          startIcon={<FilterIcon />}
          onClick={openFilters}
          className="grow"
        >
          Filter Cases
        </Button>
        {createCaseButton}
      </div>
      <Dialog
        fullScreen
        open={filtersOpen}
        onClose={closeFilters}
        TransitionComponent={Transition}
        PaperProps={{ className: "p-4" }}
      >
        <div className="flex justify-between items-center px-2 py-4">
          <div className="flex items-center text-primary gap-2">
            <FilterIcon />
            <Typography variant="h2" className="text-2xl font-medium">
              Filter Cases
            </Typography>
          </div>
          <IconButton
            edge="end"
            onClick={closeFilters}
            aria-label="close"
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className="flex flex-col gap-3">{filters}</div>
      </Dialog>
    </div>
  );
}

const Transition = React.forwardRef(function Transition(
  props: SlideProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function InteractionCountItem({
  singular,
  plural,
  count,
  notificationCount,
  icon,
}: {
  singular: string;
  plural: string;
  count: number;
  notificationCount: number;
  icon: ReactNode;
}) {
  return (
    <>
      <div className="flex items-center py-2">
        <div className="flex justify-center w-5 mr-2 opacity-50">
          <Badge
            variant="dot"
            color="secondary"
            invisible={notificationCount === 0}
            style={{ display: "initial" }} // Safari bugfix
          >
            {icon}
          </Badge>
        </div>
        <strong>{count}</strong>
        &nbsp;
        {pluralize(
          {
            1: singular,
            multi: plural,
          },
          count
        )}
      </div>
      <Divider />
    </>
  );
}

export function CaseCollaboratorSelectField({
  filterSelf = true,
  ...props
}: {
  name: string;
  filterSelf?: boolean;
}) {
  const [{ value }, , helpers] = useField<CaseCollaborator[]>(props);
  const me = useMe();
  return (
    <CaseCollaboratorSelect
      label="Add Collaborators"
      selectedCollaborators={value}
      setSelectedCollaborators={(selectedUsers) => {
        helpers.setValue(selectedUsers);
      }}
      filterOptions={
        // Filter out "me" from collaborator options
        filterSelf ? (option) => option.orgUserId !== me?.orgUserId : undefined
      }
    />
  );
}

export function CaseTagsSelectField({
  name,
  tags,
}: {
  name: string;
  tags: string[];
}) {
  const [{ value }, , helpers] = useField<string[]>({ name });
  return (
    <CaseTagSelect
      label="Add Tags"
      tags={tags}
      selectedTags={value}
      setSelectedTags={(newValue) => helpers.setValue(newValue)}
      freeSolo
    />
  );
}

function required(message: string) {
  return (input: string) => (!input ? message : undefined);
}

gql`
  query page_cases {
    cases {
      id
      createdBy {
        id
        name
      }
      createdAt
      incidentDate
      title
      description
      resolutionStatus
      tags
      locations {
        id
        name
      }
      counts {
        clips
        comments
        annotations
      }
      notificationCounts {
        clips
        comments
        annotations
      }
      isNew
    }
  }
`;

gql`
  mutation createCase($value: CaseInput!) {
    createCase(value: $value) {
      id
    }
  }
`;
