import { Divider, Typography } from "@mui/material";

import { MobileHeader } from "@/layout/MobileHeader";

import { DeveloperApiDocumentation } from "./DeveloperApiDocumentation";
import { DeveloperApiKeys } from "./DeveloperApiKeys";

export function DeveloperApiSettings() {
  return (
    <div>
      <MobileHeader label="API" />
      <div className="hidden md:flex justify-between items-center flex-wrap gap-y-1 gap-x-8 py-5 px-7 bg-white">
        <Typography variant="h1">API</Typography>
      </div>

      <div className="flex items-center justify-center p-5 sm:p-9 shadow-divider">
        <div className="flex flex-col items-start gap-[26px] w-full">
          <DeveloperApiDocumentation />
          <Divider className="w-full" orientation="horizontal" />
          <DeveloperApiKeys />
        </div>
      </div>
    </div>
  );
}
