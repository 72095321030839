import { CircularProgress } from "@mui/material";
import { groupBy, intersection } from "lodash/fp";
import { useMemo } from "react";
import { ArrayParam, StringParam, useQueryParam } from "use-query-params";

import { ErrorMessage } from "@/components/ErrorMessage";

import { useCurrentIntegrationDevices } from "../../hooks/deviceHooks";
import { IntegrationDeviceAccordion } from "./IntegrationDeviceAccordion";
import { IntegrationDeviceTabSummaryBar } from "./IntegrationDeviceTabSummaryBar";

export function IntegrationDeviceTabContent() {
  const [tagsFilter] = useQueryParam("tags", ArrayParam);
  const [locationsFilter] = useQueryParam("locations", ArrayParam);
  const [searchInputParam] = useQueryParam("search", StringParam);

  const { devices, loading, error } = useCurrentIntegrationDevices();

  const filteredDevices = useMemo(() => {
    const searchQuery = searchInputParam?.toLowerCase();
    let result = devices || [];

    if (searchQuery) {
      result = result.filter((r) => {
        return (
          r.name.toLowerCase().includes(searchQuery) ||
          r.cameras[0]?.location?.name.toLowerCase().includes(searchQuery) ||
          r.cameras[0]?.name.toLowerCase().includes(searchQuery) ||
          r.tags.join("_").toLowerCase().includes(searchQuery)
        );
      });
    }

    return result
      .filter(
        (s) =>
          !locationsFilter ||
          locationsFilter.includes(String(s.cameras[0]?.location?.id))
      )
      .filter(
        (s) => !tagsFilter || intersection(tagsFilter, s.tags).length > 0
      );
  }, [devices, locationsFilter, searchInputParam, tagsFilter]);

  const groupedSources = useMemo(() => {
    return groupBy((device) => {
      return device.cameras[0]?.location.name ?? "Unknown";
    }, filteredDevices);
  }, [filteredDevices]);

  if (error) {
    return <ErrorMessage title="Error" description={error.toString()} />;
  }

  return (
    <div className="flex flex-col gap-3 md:gap-6">
      <IntegrationDeviceTabSummaryBar filterCount={filteredDevices.length} />
      {loading && (
        <div className="w-full flex items-center justify-center p-8">
          <CircularProgress size={30} />
        </div>
      )}
      {!loading &&
        Object.entries(groupedSources).map(([location, devices]) => {
          return (
            <IntegrationDeviceAccordion
              key={location}
              location={location}
              devices={devices as any}
            />
          );
        })}
    </div>
  );
}
