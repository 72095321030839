import AddIcon from "@mui/icons-material/AddCircle";
import Edit from "@mui/icons-material/Edit";
import Stars from "@mui/icons-material/Stars";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import clsx from "clsx";
import gql from "graphql-tag";
import { useParams } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import { StringParam, useQueryParam } from "use-query-params";

import { pluralize } from "@/util/pluralize";
import { useBreakpoints } from "@/util/useBreakpoints";
import { ConnectedTableSortLabel, useTableSort } from "@/util/useTableSort";

import { useMe } from "@/components/Auth";
import { Loading } from "@/components/Loading";
import { QueryParamLink } from "@/components/shared/QueryParamLink";

import { refetchOnMountPolicy } from "@/apolloClient";
import { useRolesQuery } from "@/generated-models";
import { useOrgSlugNavigate } from "@/hooks/useOrgRouteBase";

import { UserManagementMobileSearchBox } from "./UserManagementMobileSearchBox";

export function RoleSettings() {
  const me = useMe();
  const permissions = me?.organization.permissions;
  return (
    <>
      <UserManagementMobileSearchBox />

      {permissions?.users_manage && (
        <div className="bg-[#DDEFFF] py-1 flex items-center justify-end gap-1 md:shadow-inner">
          <Button
            startIcon={<AddIcon className="text-[18px]" />}
            color="primary"
            className="text-xs font-normal"
            classes={{ startIcon: "mr-1 " }}
            component={QueryParamLink}
            to="./new"
          >
            New Role
          </Button>
        </div>
      )}

      <RoleList />
    </>
  );
}

const useStyles = makeStyles()({
  tableHead: {
    "& > th": {
      padding: "12px 16px",
      borderBottom: "1px solid rgba(200, 200, 200, 1)",
    },
  },
  tableRow: {
    "& > td": { border: "initial", padding: 16 },
    "&:nth-of-type(odd)": { backgroundColor: "#F6FBFF" },
  },

  tableRowAdmin: {
    cursor: "pointer",
    "&:hover": { backgroundColor: "#DDEFFF" },
  },
});

function RoleList() {
  const { classes } = useStyles();
  const { fitsTablet } = useBreakpoints();

  const me = useMe();
  const permissions = me?.organization.permissions;
  const { data } = useRolesQuery({
    ...refetchOnMountPolicy,
  });

  const navigate = useOrgSlugNavigate();
  const { "*": splat } = useParams();
  const roleId = Number(splat?.split("/")[0]) || null; // coerce empty string to null

  const [searchInputParam] = useQueryParam("search", StringParam);
  const searchQuery = searchInputParam?.toLowerCase();
  const filteredRolesBySearch = !searchQuery
    ? data?.roles
    : data?.roles.filter((r) =>
        [r.name].some((field) => field.toLowerCase().includes(searchQuery))
      );
  const { sortedRows: sortedRoles, ...sortLabelParams } = useTableSort(
    filteredRolesBySearch,
    "name"
  );

  if (!sortedRoles) {
    return (
      <div style={{ padding: "48px 0" }}>
        <Loading>Loading roles...</Loading>
      </div>
    );
  }
  return (
    <Table className="shadow-inner md:shadow-none">
      <TableHead>
        <TableRow className={classes.tableHead}>
          <TableCell>
            {!fitsTablet && <span className="font-normal">Sort by: </span>}
            <ConnectedTableSortLabel
              name="name"
              {...sortLabelParams}
              classes={{ active: "text-primary" }}
            >
              Role
            </ConnectedTableSortLabel>
          </TableCell>
          {fitsTablet && (
            <TableCell>
              <ConnectedTableSortLabel name="builtIn" {...sortLabelParams}>
                Type
              </ConnectedTableSortLabel>
            </TableCell>
          )}
          <TableCell>
            <ConnectedTableSortLabel name="members" {...sortLabelParams}>
              Members
            </ConnectedTableSortLabel>
          </TableCell>
          {fitsTablet && permissions?.users_manage && (
            <TableCell className="font-normal">Actions</TableCell>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedRoles?.map((role) => {
          const selected = roleId === role.id;
          return (
            <TableRow
              key={role.id}
              className={clsx(
                "transition-colors transation-transform border-b border-solid border-[#ececec]",
                classes.tableRow,
                {
                  [classes.tableRowAdmin]: permissions?.users_manage,
                  "bg-blue-medium": selected,
                }
              )}
              onClick={
                permissions?.users_manage
                  ? () =>
                      navigate(
                        selected // toggle detail panel
                          ? `/configure/user-management/roles`
                          : `/configure/user-management/roles/${role.id}`

                        // {
                        //   replace: userId !== null && userId !== user.id,
                        // }
                      )
                  : undefined
              }
            >
              <TableCell className="font-bold leading-[normal]">
                <div className="flex">
                  {role.name}
                  {role.builtIn && (
                    <Tooltip title="Built-in role - cannot be changed">
                      <Stars className="text-[14px] ml-1" />
                    </Tooltip>
                  )}
                </div>
                {!fitsTablet && (
                  <div className="text-xs opacity-50 font-normal">
                    {role.builtIn ? "Built-in" : "Custom"}
                  </div>
                )}
              </TableCell>
              {fitsTablet && (
                <TableCell>{role.builtIn ? "Built-in" : "Custom"}</TableCell>
              )}
              <TableCell>
                {role.members.length}{" "}
                {pluralize(
                  {
                    1: "member",
                    multi: "members",
                  },
                  role.members.length
                )}
              </TableCell>
              {fitsTablet && permissions?.users_manage && (
                <TableCell>
                  <Button startIcon={<Edit />} color="primary">
                    Edit
                  </Button>
                </TableCell>
              )}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

gql`
  query roles {
    roles {
      id
      name
      description
      builtIn
      members {
        id
        name
      }
    }
  }
`;
