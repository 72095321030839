export const COPILOT_COLORS = [
  "#00A3FF",
  "#0047FF",
  "#8000FF",
  "#CC00FF",
  "#FF00C7",
  "#FF004D",
  "#FF7A00",
  "#FFC700",
  "#8FFF00",
  "#00FFF0",
];

export enum SubjectType {
  person = "person",
  vehicle = "vehicle",
  forklift = "forklift",
  other = "other",
}
