import { useLocalStorageValue } from "@react-hookz/web";
import gql from "graphql-tag";
import { get, keyBy, uniq } from "lodash/fp";
import { useMemo } from "react";

import { filterNullish } from "@/util/filterFalsy";

import {
  useIntegrationEventTypeSchemaAttributesQuery,
  useIntegrationEventTypesV2Query,
  useIntegrationEventsV2Query,
} from "@/generated-models";

import { useFilterQueryParams } from ".";
import { generateAttributeName } from "../Details/Event/IntegrationEventCustomColumnPopover";
import { useGetResolvedEventData } from "../Form/Event/hooks";
import { IntegrationNestedChild } from "../IntegrationNestedChild";
import { useCurrentIntegrationDevices } from "./deviceHooks";
import { useCurrentIntegrationId } from "./integrationHooks";

export function useCurrentIntegrationEvents() {
  const integrationId = useCurrentIntegrationId();
  const { types, loading: typesLoading } = useCurrentIntegrationEventTypes();
  const { devices, loading: devicesLoading } = useCurrentIntegrationDevices();
  const getResolvedEventData = useGetResolvedEventData();
  const [params] = useFilterQueryParams();

  const dependenciesLoading = typesLoading || devicesLoading;

  const [start, end] = useMemo(() => {
    if (!params.datetime) return [null, null];
    const [start, end] = params.datetime.split("|");
    return [new Date(start).toISOString(), new Date(end).toISOString()];
  }, [params.datetime]);

  const integrationEventTypeIds = useMemo(
    () =>
      uniq(
        ((params.integrationEventTypeIds ||
          types.map((t) => t.id)) as unknown) as string[]
      )
        .filter(filterNullish)
        .map((c) => parseInt(c)),
    [params.integrationEventTypeIds, types]
  );

  const integrationDeviceIds = useMemo(
    () =>
      uniq(
        ((params.integrationDeviceIds ||
          devices.map((d) => d.id)) as unknown) as string[]
      )
        .filter(filterNullish)
        .map((c) => parseInt(c)),
    [devices, params.integrationDeviceIds]
  );

  const cameraIds = useMemo(
    () =>
      uniq(
        (params.cameraIds || devices.flatMap((d) => d.cameraIds)) as string[]
      )
        .filter(filterNullish)
        .map((c) => parseInt(c)),
    [devices, params.cameraIds]
  );

  const query = useIntegrationEventsV2Query({
    variables: {
      input: {
        integrationId,
        integrationEventTypeIds,
        integrationDeviceIds,
        cameraIds,
        start,
        end,
      },
    },
    skip: dependenciesLoading,
  });

  const events = useMemo(() => {
    return [...(query.data?.integrationEventsV2 || [])].filter((e) => {
      const search = params.search?.toLowerCase();

      if (search) {
        const { device, eventType } = getResolvedEventData(e);

        return (
          device?.name.toLowerCase().includes(search) ||
          eventType?.name.toLowerCase().includes(search)
        );
      }

      return true;
    });
  }, [getResolvedEventData, params.search, query.data?.integrationEventsV2]);

  return {
    ...query,
    events,
    eventsCount: events.length,
    loading: query.loading || dependenciesLoading,
  };
}

export function useCurrentIntegrationEventTypes() {
  const integrationId = useCurrentIntegrationId();
  const {
    data: attrib,
    loading: attribLoading,
    error: attribError,
  } = useCurrentSchemaTypeAttributes();
  const { data, loading, error, ...rest } = useIntegrationEventTypesV2Query({
    variables: {
      integrationId,
    },
    skip: !integrationId,
  });

  const types = data?.integrationEventTypesV2 || [];
  const typesMap = keyBy("id", types);

  const resolvedLoading = loading || attribLoading;
  const resolvedError = error || attribError;

  // Remove parent fields as they're not needed.
  const { attributeFields, attributeFieldsMap } = useMemo(() => {
    const attributes = attrib?.integrationEventTypeSchemaAttributes || [];
    const attributeFieldsMap: Record<string, string[]> = {};

    const attributeFields = attributes.filter(({ key, description }) => {
      const parent = key.split(".")[0];
      const isNested = key.includes(".") || key.includes("[]");
      const hasNestedValue = attributes.some(
        ({ key: nestedKey }) =>
          nestedKey.includes(`${key}.`) || nestedKey.includes(`${key}[]`)
      );

      if (!isNested) {
        attributeFieldsMap[key] = [];
      } else {
        attributeFieldsMap[parent] = [
          ...attributeFieldsMap[parent],
          description ? description : key,
        ];
      }

      return isNested || (!isNested && !hasNestedValue);
    });

    return { attributeFields, attributeFieldsMap };
  }, [attrib?.integrationEventTypeSchemaAttributes]);

  return {
    types,
    typesMap,
    attributeFields,
    attributeFieldsMap,
    loading: resolvedLoading,
    error: resolvedError,
    ...rest,
  };
}

export function useCurrentIntegrationEventColumns() {
  const {
    attributeFieldsMap,
    attributeFields,
  } = useCurrentIntegrationEventTypes();
  const [hidden] = useHiddenSchemaAttributes();

  return useMemo(() => {
    return Object.entries(attributeFieldsMap)
      .sort(([a, children], [b, children2]) =>
        children2.length > children.length ? -1 : 1
      )
      .map(([path, children]) => {
        const currentField = attributeFields.find(({ key }) => key === path);
        const description = attributeFields.find(({ key }) => key === path)
          ?.description;

        return {
          field: path,
          headerName: Boolean(description)
            ? description
            : generateAttributeName({ key: path }),
          hidden: hidden.includes(path),
          valueGetter: ({ row }: { row: { attributes: unknown } }) => {
            return children.length === 0
              ? get(`attributes.${path}`, row)
              : null;
          },
          renderCell: ({ row }: { row: { attributes: unknown } }) => {
            return children.length > 0 ? (
              <IntegrationNestedChild
                row={get(`attributes.${path}`, row)}
                path={path}
              />
            ) : (
              <span>
                {get(`attributes.${path}`, row) !== undefined
                  ? String(get(`attributes.${path}`, row))
                  : ""}
              </span>
            );
          },
          flex: 2,
          minWidth: 120,
          sortable: !(
            currentField?.type === "array" ||
            currentField?.type === "object" ||
            children.length > 0
          ),
        };
      })
      .filter((c) => !c?.hidden)
      .filter(filterNullish);
  }, [attributeFieldsMap, attributeFields, hidden]);
}

export function useCurrentSchemaTypeAttributes() {
  const integrationId = useCurrentIntegrationId();
  return useIntegrationEventTypeSchemaAttributesQuery({
    variables: {
      integrationId,
    },
  });
}

export function useHiddenSchemaAttributes() {
  const integrationId = useCurrentIntegrationId();
  return useLocalStorageValue<string[]>(
    `integrationHiddenAttributes-${integrationId}`,
    []
  );
}

gql`
  fragment IntegrationEventTypeV2Fragment on IntegrationEventTypeV2 {
    id
    organizationId
    integrationId
    name
    isDeleted
    schema
    created
    updated
    duration
    buffer
  }
`;

gql`
  fragment IntegrationEventV2Fragment on IntegrationEventV2 {
    id
    timestamp
    integrationId
    integrationEventTypeId
    integrationDeviceId
    duration
    buffer
    attributes
  }
`;

gql`
  mutation createIntegrationEventTypes(
    $input: CreateIntegrationEventTypesInput!
  ) {
    createIntegrationEventTypes(input: $input) {
      ...IntegrationEventTypeV2Fragment
    }
  }
`;

gql`
  mutation updateIntegrationEventType(
    $input: UpdateIntegrationEventTypeInput!
  ) {
    updateIntegrationEventType(input: $input) {
      ...IntegrationEventTypeV2Fragment
    }
  }
`;

gql`
  mutation deleteIntegrationEventType(
    $input: DeleteIntegrationEventTypeInput!
  ) {
    deleteIntegrationEventType(input: $input) {
      message
    }
  }
`;

gql`
  query integrationEventsV2($input: IntegrationEventsV2Input!) {
    integrationEventsV2(input: $input) {
      ...IntegrationEventV2Fragment
    }
  }
`;

gql`
  query integrationEventTypesV2($integrationId: Int!) {
    integrationEventTypesV2(integrationId: $integrationId) {
      ...IntegrationEventTypeV2Fragment
    }
  }
`;

gql`
  query integrationEventTypeSchemaAttributes($integrationId: Int!) {
    integrationEventTypeSchemaAttributes(integrationId: $integrationId) {
      key
      description
      type
    }
  }
`;

gql`
  mutation importIntegrationEvents($input: ImportIntegrationEventsInput!) {
    importIntegrationEvents(input: $input) {
      message
    }
  }
`;
