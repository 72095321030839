import { Autocomplete, createFilterOptions, TextField } from "@mui/material";
import { orderBy } from "lodash/fp";
import { Dispatch } from "react";

import { useMe } from "@/components/Auth";

import { CaseRole, useUsersForCaseQuery } from "@/generated-models";

import { CaseCollaborator, CaseCollaboratorChip } from "./CaseCollaboratorChip";

export function CaseCollaboratorSelect({
  setSelectedCollaborators,
  selectedCollaborators,
  label,
  filterOptions = () => true,
}: {
  filterOptions?: (option: CaseCollaborator) => boolean;
  setSelectedCollaborators: Dispatch<CaseCollaborator[]>;
  selectedCollaborators: CaseCollaborator[];
  label?: string;
}) {
  const me = useMe();
  const { data } = useUsersForCaseQuery();

  const selectedOrgUserIds = new Set(
    selectedCollaborators?.map((u) => u.orgUserId)
  );
  const options = (
    orderBy(
      (collab) => collab.name.toLowerCase(),
      "asc",
      // Filter out selected users from the options
      data?.users.filter((option) => !selectedOrgUserIds.has(option.orgUserId))
    ).map(({ __typename, ...u }) => ({
      ...u,
      // Default to commenter role
      caseRole: CaseRole.Commenter,
    })) ?? []
  ).filter(filterOptions);

  return (
    <Autocomplete
      multiple
      id="tags-outlined"
      options={options}
      fullWidth
      getOptionLabel={(option) => option.name}
      filterOptions={filterCollaboratorOptions}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.name}
          <span className="inline-block ml-2 text-xs opacity-50">
            {option.email}
          </span>
        </li>
      )}
      renderTags={(values, getTagProps) => {
        return values.map((value, index) => {
          const props = getTagProps({ index });

          return (
            <CaseCollaboratorChip
              roleArrowOnHover={false}
              collaborator={value}
              collaboratorManagamentDisabled={value.orgUserId === me?.orgUserId}
              {...props}
              onChangeRole={(caseRole) => {
                const updatedCollaborators = selectedCollaborators.map((c) =>
                  c.orgUserId === value.orgUserId
                    ? // update the caseRole of the current collaborator
                      {
                        ...c,
                        caseRole,
                      }
                    : c
                );
                setSelectedCollaborators(updatedCollaborators);
              }}
              onDelete={() => {
                console.log("deleting", value);
                props.onDelete({ source: "chip" });
              }}
            />
          );
        });
      }}
      classes={{
        inputRoot: "gap-2",
      }}
      disableClearable
      value={selectedCollaborators}
      filterSelectedOptions
      onChange={(
        event: React.SyntheticEvent<Element, Event> | { source: string },
        value,
        reason
      ) => {
        if (
          // prevent removing options by backspacing the search input
          reason !== "removeOption" ||
          // ...but allow deletion of option through the role select
          ("source" in event && event.source === "chip")
        ) {
          setSelectedCollaborators(value);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          placeholder="Search by name"
        />
      )}
    />
  );
}

const filterCollaboratorOptions = createFilterOptions<CaseCollaborator>({
  stringify(option) {
    return [option.name, option.email].join(" ");
  },
});
