import clsx from "clsx";

export function StreamInfoMetadataMetric({
  label,
  value,
  error,
  className,
  valueClassName,
}: {
  label: string;
  value?: string;
  error?: boolean;
  className?: string;
  valueClassName?: string;
}) {
  return (
    <div className="flex flex-col items-start gap-y-0.5">
      <div className={clsx("text-xs leading-3", className)}>{label}</div>
      <div
        className={clsx(
          "text-base leading-4 font-bold",
          error && "text-[#F44336]"
        )}
      >
        <span className={valueClassName}>{value}</span>
      </div>
    </div>
  );
}
