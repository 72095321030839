import { QueryResult } from "@apollo/client";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import OpenStatusIcon from "@mui/icons-material/Error";
import ImageIcon from "@mui/icons-material/ImageOutlined";
import AddedClipIcon from "@mui/icons-material/VideoCall";
import { Box, Button, Divider, IconButton, Typography } from "@mui/material";
import clsx from "clsx";
import {
  differenceInHours,
  differenceInSeconds,
  format,
  formatDistanceStrict,
} from "date-fns/fp";
import gql from "graphql-tag";
import { Fragment, ReactNode, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import { NumberParam, useQueryParam } from "use-query-params";

import { concat } from "@/util/apolloCache";
import { insertBreaklines } from "@/util/insertBreaklines";
import { useBreakpoints } from "@/util/useBreakpoints";

import { AnnotationLabel } from "@/pages/Cases/AnnotationLabel";
import { CreateCaseCommentBox } from "@/pages/Cases/CreateCaseCommentBox";
import {
  CASE_COMMENT_WITH_REPLIES_FRAGMENT,
  CASE_METADATA_FRAGMENT,
  SHARED_CASE_METADATA_FRAGMENT,
} from "@/pages/Cases/caseFragments";
import {
  applyCommentPartMapper,
  insertMentions,
  insertTimestampLinks,
} from "@/pages/Cases/commentDecorations";

import { useMe } from "@/components/Auth";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { PHIWarning } from "@/components/PHIWarning";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { Avatar } from "@/components/User/Avatar";
import { DefaultDialog, useDialog } from "@/components/shared/Dialog";
import { QueryParamLink } from "@/components/shared/QueryParamLink";

import {
  CaseEventTypes,
  CaseMetadataQuery,
  MetadataForSharedCaseQuery,
  ResolutionStatus,
  useCaseMetadataQuery,
  useCreateCaseCommentMutation,
  useDeleteCaseClipAnnotationMutation,
  useDeleteCaseCommentMutation,
  useMetadataForSharedCaseQuery,
} from "@/generated-models";
import { CERULEAN_BLUE, GREEN } from "@/layout/theme";

type MetadataQueryTye = CaseMetadataQuery | MetadataForSharedCaseQuery;
type CaseType = NonNullable<MetadataQueryTye["case"]>;
type CommentType = CaseType["comments"][number];
type AnnotationType = CaseType["annotations"][number];
type ClipType = CaseType["clips"][number];
type ScreenshotType = CaseType["screenshots"][number];
type EventType = CaseType["events"][number];
type TimeLineItem =
  | CommentType
  | AnnotationType
  | ClipType
  | EventType
  | ScreenshotType;

type CommentOrAnnotation = CommentType | AnnotationType;

export function CaseComments({
  caseId,
  viewOnly,
  anonymous,
}: {
  caseId: number;
  viewOnly: boolean;
  anonymous: boolean;
}) {
  return anonymous ? (
    <AnonymousCaseCommentsData viewOnly={viewOnly} />
  ) : (
    <CaseCommentsData caseId={caseId} viewOnly={viewOnly} />
  );
}

function AnonymousCaseCommentsData({ viewOnly }: { viewOnly: boolean }) {
  const { token } = useParams();
  const caseQuery = useMetadataForSharedCaseQuery({
    variables: { token: token ?? "" },
    skip: !token,
    pollInterval: 15000,
    fetchPolicy: "cache-and-network",
  });

  return (
    <CaseCommentsInner
      caseQuery={caseQuery}
      viewOnly={viewOnly}
      // anonymous={true}
    />
  );
}

function CaseCommentsData({
  caseId,
  viewOnly,
}: {
  caseId: number;
  viewOnly: boolean;
}) {
  const caseQuery = useCaseMetadataQuery({
    variables: { id: caseId },
    pollInterval: 15000,
    fetchPolicy: "cache-and-network",
  });

  return (
    <CaseCommentsInner
      caseQuery={caseQuery}
      viewOnly={viewOnly}
      // anonymous={false}
    />
  );
}

function CaseCommentsInner({
  caseQuery,
  viewOnly,
}: // anonymous,
{
  caseQuery:
    | QueryResult<CaseMetadataQuery, any>
    | QueryResult<MetadataForSharedCaseQuery, any>;
  viewOnly: boolean;
  // anonymous: boolean;
}) {
  const { fitsTablet } = useBreakpoints();

  const { open: openDeleteCommentDialog, ...dialogProps } = useDialog();
  const [clipId] = useQueryParam("clip", NumberParam);
  const [screenshotId] = useQueryParam("screenshot", NumberParam);

  const { data, error } = caseQuery;

  let content: ReactNode | null = null;
  if (error) {
    return (
      <ErrorMessage
        title="Unable to load comments"
        description={error.message}
      />
    );
  }
  if (!data?.case) {
    content = <Loading grow>Fetching comments</Loading>;
  } else {
    const timelineItems: TimeLineItem[] = [
      ...data.case.comments,
      ...data.case.annotations,
      ...data.case.clips,
      ...data.case.screenshots,
      ...data.case.events,
    ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));

    content = (
      <>
        {data.case.permissions.comment && (
          <>
            <CommentBox caseId={data.case.id} viewOnly={viewOnly} />
            <Divider className="m-3" />
          </>
        )}
        {
          // eslint-disable-next-line
          timelineItems.map((item) => {
            switch (item.__typename) {
              case "CaseComment":
              case "CaseClipAnnotation":
              case "SharedCaseComment":
              case "SharedCaseClipAnnotation":
                return (
                  <CaseComment
                    key={`${item.__typename}:${item.id}`}
                    commentOrAnnotation={item}
                    caseId={data.case!.id}
                    openDeleteCommentDialog={openDeleteCommentDialog}
                    viewOnly={viewOnly}
                    // anonymous={anonymous}
                    caseCommentPermission={Boolean(
                      data.case?.permissions.comment
                    )}
                  />
                );
              case "CaseClip":
              case "CaseScreenshot":
              case "SharedCaseClip":
              case "SharedCaseScreenshot":
                return (
                  <AddedCaseItem
                    key={`${item.__typename}:${item.id}`}
                    item={item}
                    viewing={
                      item.__typename === "CaseClip" ||
                      item.__typename === "SharedCaseClip"
                        ? clipId === item.id
                        : screenshotId === item.id
                    }
                  />
                );
              case "CaseEvent":
              case "SharedCaseEvent":
                return (
                  <CaseEvent
                    event={item}
                    key={`${item.__typename}:${item.id}`}
                  />
                );
            }
          })
        }
        <DefaultDialog
          {...dialogProps}
          confirmColor="primary"
          title="Delete Message"
          content="Are you sure you want to delete this message? This cannot be undone."
        />
      </>
    );
  }

  return (
    <>
      {fitsTablet && (
        <div className="p-4">
          <Typography variant="h4">Annotations &amp; Comments</Typography>
        </div>
      )}
      <div className="flex flex-col py-2 px-4">{content}</div>
    </>
  );
}

function CommentBox({
  caseId,
  viewOnly,
}: {
  caseId: number;
  viewOnly: boolean;
}) {
  const me = useMe();
  const [
    createCaseComment,
    { loading: createCommentInFlight },
  ] = useCreateCaseCommentMutation();
  const { pushSnackbar } = useFeedback();
  const [clipId] = useQueryParam("clip", NumberParam);

  return (
    <>
      {" "}
      <PHIWarning style={{ marginBottom: 8 }} />
      <CreateCaseCommentBox
        disabled={createCommentInFlight || viewOnly}
        onSubmitComment={async (comment, mentions, reset) => {
          const value = {
            content: comment,
            caseClipId: clipId,
          };
          await createCaseComment({
            variables: {
              caseId,
              value,
            },
            optimisticResponse: {
              __typename: "Mutation",
              createCaseComment: {
                __typename: "CaseCommentCreationResponse",
                comment: {
                  __typename: "CaseComment",
                  id: -1,
                  createdAt: new Date().toISOString(),
                  mentions,
                  user: me!,
                  replies: [],
                  clip: null,
                  ...value,
                },
                addedCollaborators: Object.entries(mentions).map(
                  ([id, name]) => ({
                    __typename: "User",
                    id: Number(id),
                    name,
                    email: "n/a",
                  })
                ),
              },
            },
            update: (cache, { data }) => {
              const newCommentRef = cache.writeFragment({
                data: {
                  ...data!.createCaseComment.comment,
                },
                fragment: CASE_COMMENT_WITH_REPLIES_FRAGMENT,
                fragmentName: "CaseCommentWithReplies",
              });
              cache.modify({
                id: `Case:${caseId}`,
                fields: {
                  collaborators: concat(
                    data!.createCaseComment.addedCollaborators
                  ),
                  comments: concat(newCommentRef),
                },
              });
            },
          }).catch((e) => {
            pushSnackbar(
              `Problem posting comment: ${e.message}. Please try again later`,
              FeedbackType.Error
            );
          });

          reset();
        }}
      />
    </>
  );
}

// These colors will rotate
const colors = [
  "#FFE600", // A
  "#FFA800", // B
  "#FF7A00", // C
  "#FF3D00", // D
  "#FF007A", // E
  "#FA00FF", // F
  "#CC00FF", // G
  "#9E00FF", // H
  "#0075FF", // I
  "#00B2FF", // J
  "#00D1FF", // K
  "#00F0FF", // L
  "#00FFD1", // M
  "#00FF94", // N
  "#83F85A", // O
  "#DBFF00", // P
];

const BASE_CHAR_CODE = "A".charCodeAt(0);
/**
 * Assumes labels start at A and rotate past Z: A, B, C, ..., Y, Z, AA, AB, AC, ...etc
 */
export function getColorForLabel(label: string) {
  // Generate a number for the label where:
  // A => 1
  // B => 2
  // ...
  // Z => 26
  // AA => 27
  // AB => 28
  // ...etc
  const value = label
    .split("")
    .map((char, i) => {
      const normalizedCharValue = char.charCodeAt(0) - BASE_CHAR_CODE + 1;
      const charPosition = label.length - 1 - i;
      return normalizedCharValue * 26 ** charPosition;
    })
    .reduce((sum, value) => sum + value, 0);

  return colors[value % colors.length];
}

const useCommentStyles = makeStyles<
  void,
  "actionButton" | "actionButtonInReply"
>()((theme, _props, classes) => ({
  comment: {
    [`&:hover .${classes.actionButton}:not(.${classes.actionButtonInReply})`]: {
      visibility: "visible",
    },
    padding: `${theme.spacing(0.5)} 0 ${theme.spacing(0.5)} ${theme.spacing(
      0.5
    )}`,
  },
  content: {
    paddingRight: theme.spacing(2),
  },
  highlighted: {
    animation: "$highlightFade 1s linear 1s alternate 3",
  },
  "@keyframes highlightFade": {
    "0%": {
      backgroundColor: `${CERULEAN_BLUE}00`,
    },
    "50%": {
      backgroundColor: `${CERULEAN_BLUE}22`,
    },
    "100%": {
      backgroundColor: `${CERULEAN_BLUE}00`,
    },
  },
  reply: {
    backgroundColor: "#FFFFFFCC",
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    borderLeft: "1px solid hsl(0 0% 0% / .1)",

    [`&:hover .${classes.actionButton}`]: {
      visibility: "visible",
    },
  },
  actionButton: {
    fontWeight: "normal",
    background: "none !important",
    minWidth: "initial",
    padding: 0,
    opacity: 0.7,

    visibility: "hidden",
  },
  actionButtonInReply: {
    visibility: "hidden",
  },
}));

function getMentions(commentOrAnnotation: CommentOrAnnotation) {
  if (
    commentOrAnnotation.__typename === "CaseClipAnnotation" ||
    commentOrAnnotation.__typename === "CaseComment"
  ) {
    return commentOrAnnotation.mentions;
  }
  return undefined;
}

function CaseComment({
  commentOrAnnotation,
  caseId,
  parent,
  openDeleteCommentDialog,
  viewOnly,
  // anonymous,
  caseCommentPermission,
}: {
  commentOrAnnotation: CommentOrAnnotation;
  caseId: number;
  parent?: CommentOrAnnotation;
  openDeleteCommentDialog: () => Promise<boolean | undefined>;
  viewOnly: boolean;
  // anonymous: boolean;
  caseCommentPermission: boolean;
}) {
  const me = useMe();
  const { classes } = useCommentStyles();
  const postTime = usePostTime(commentOrAnnotation.createdAt);
  const [
    createCaseComment,
    { loading: createCommentInFlight },
  ] = useCreateCaseCommentMutation();
  const [deleteCaseComment] = useDeleteCaseCommentMutation();
  const [deleteCaseClipAnnotation] = useDeleteCaseClipAnnotationMutation();
  const { pushSnackbar } = useFeedback();
  const [highlightedCommentId] = useQueryParam("comment", NumberParam);
  const isReply = Boolean(parent);
  const [clipId] = useQueryParam("clip", NumberParam);
  const [screenshotId] = useQueryParam("screenshot", NumberParam);

  const { ref } = useInitialCommentScroll(
    commentOrAnnotation.__typename === "CaseComment" ||
      commentOrAnnotation.__typename === "SharedCaseComment"
      ? "comment"
      : "annotation",
    commentOrAnnotation.id
  );

  const [creatingReply, setCreatingReply] = useState(false);

  const hasReplies =
    commentOrAnnotation.replies && commentOrAnnotation.replies.length > 0;
  return (
    <>
      <div
        className={clsx("flex", classes.comment, {
          [classes.reply]: isReply,
          [classes.highlighted]:
            highlightedCommentId === commentOrAnnotation.id,
        })}
        ref={ref}
      >
        <div className="flex flex-col items-center mr-2 w-10">
          {commentOrAnnotation.user ? (
            <Avatar user={commentOrAnnotation.user} />
          ) : (
            <DeleteIcon />
          )}
          {!isReply && (
            <>
              <Box m={0.5} />
              <Divider orientation="vertical" className="grow w-px h-auto" />
            </>
          )}
        </div>
        <div className="grow min-w-0">
          <div className="flex justify-between items-center gap-2 mb-4">
            <Typography className="font-bold truncate">
              {commentOrAnnotation.content === null
                ? commentOrAnnotation.__typename === "CaseClipAnnotation" ||
                  commentOrAnnotation.__typename === "SharedCaseClipAnnotation"
                  ? "This annotation has been deleted"
                  : "This comment has been deleted"
                : commentOrAnnotation.user
                ? commentOrAnnotation.user.name
                : "[deleted user]"}
            </Typography>
            <Typography variant="caption" className="opacity-60 shrink-0">
              {postTime}
            </Typography>
          </div>

          <div className="flex items-center">
            {(commentOrAnnotation.__typename === "CaseClipAnnotation" ||
              commentOrAnnotation.__typename === "SharedCaseClipAnnotation") &&
              // Don't show the annotation label if the annotation
              // was soft-deleted
              commentOrAnnotation.user && (
                <div className="shrink-0">
                  <WrappedAnnotationLabel annotation={commentOrAnnotation} />
                </div>
              )}
            <Typography className={classes.content}>
              {[commentOrAnnotation.content || ""]
                .flatMap(applyCommentPartMapper(insertBreaklines))
                .flatMap(
                  applyCommentPartMapper(
                    insertTimestampLinks(
                      commentOrAnnotation.clip && {
                        id: commentOrAnnotation.clip.id,
                        duration: getClipDuration(commentOrAnnotation.clip),
                      }
                    )
                  )
                )
                .flatMap(
                  applyCommentPartMapper(
                    insertMentions(getMentions(commentOrAnnotation))
                  )
                )
                .map((content, i) => (
                  <Fragment key={i}>{content}</Fragment>
                ))}
              {(commentOrAnnotation.__typename === "CaseClipAnnotation" ||
                commentOrAnnotation.__typename ===
                  "SharedCaseClipAnnotation") &&
                !commentOrAnnotation.clip &&
                !commentOrAnnotation.caseScreenshotId && (
                  <div style={{ opacity: 0.5, fontSize: ".8em" }}>
                    corresponding clip has been deleted
                  </div>
                )}
            </Typography>
          </div>

          {hasReplies && (
            <Box pt={2} pl={1.5}>
              {commentOrAnnotation
                .replies!.slice(0)
                .sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1))
                .map((reply) => (
                  <CaseComment
                    key={`${commentOrAnnotation.id}/${reply.id}`}
                    commentOrAnnotation={reply}
                    caseId={caseId}
                    parent={commentOrAnnotation}
                    openDeleteCommentDialog={openDeleteCommentDialog}
                    viewOnly={viewOnly}
                    // anonymous={anonymous}
                    caseCommentPermission={caseCommentPermission}
                  />
                ))}
            </Box>
          )}

          {/* Action buttons */}
          {(commentOrAnnotation.__typename === "CaseComment" ||
            commentOrAnnotation.__typename === "CaseClipAnnotation") &&
            // !anonymous &&
            !viewOnly &&
            caseCommentPermission &&
            !creatingReply && (
              <Box ml={hasReplies ? 1.5 : 0} display="flex">
                {!isReply && (
                  <>
                    <Button
                      onClick={() => setCreatingReply(true)}
                      className={clsx(classes.actionButton, {
                        [classes.actionButtonInReply]: isReply,
                      })}
                      color="primary"
                    >
                      reply
                    </Button>

                    <Box m={1} />
                  </>
                )}
                {commentOrAnnotation.user?.id === me?.id && (
                  <>
                    <Button
                      onClick={async () => {
                        const deletionConfirmed = await openDeleteCommentDialog();
                        if (!deletionConfirmed) return;

                        if (
                          commentOrAnnotation.__typename ===
                          "CaseClipAnnotation"
                        ) {
                          deleteCaseClipAnnotation({
                            variables: {
                              id: commentOrAnnotation.id,
                            },
                            refetchQueries: ["subsequentAnnotationLabel"],
                            optimisticResponse: {
                              __typename: "Mutation",
                              deleteCaseClipAnnotation: {
                                __typename: "DeletionResponse",
                                id: commentOrAnnotation.id,
                                deleted: true,
                              },
                            },
                            update: (cache, { data }) => {
                              const deletingId = cache.identify(
                                commentOrAnnotation
                              );
                              if (
                                !commentOrAnnotation.replies ||
                                commentOrAnnotation.replies.length === 0
                              ) {
                                // Case 1: no replies to this annotation, just delete it
                                cache.evict({ id: deletingId });
                              } else {
                                // Case 2: there are replies, set user and content to null
                                cache.modify({
                                  id: deletingId,
                                  fields: {
                                    user: () => null,
                                    content: () => null,
                                  },
                                });
                              }
                            },
                          });
                        } else {
                          deleteCaseComment({
                            variables: {
                              id: commentOrAnnotation.id,
                            },
                            optimisticResponse: {
                              __typename: "Mutation",
                              deleteCaseComment: {
                                __typename: "DeletionResponse",
                                id: commentOrAnnotation.id,
                                deleted: true,
                              },
                            },
                            update: (cache, { data }) => {
                              const deletingId = cache.identify(
                                commentOrAnnotation
                              );
                              if (
                                !commentOrAnnotation.replies ||
                                commentOrAnnotation.replies.length === 0
                              ) {
                                // Case 1: no replies to this comment, just delete it
                                cache.evict({ id: deletingId });
                              } else {
                                // Case 2: there are replies, set user and content to null
                                cache.modify({
                                  id: deletingId,
                                  fields: {
                                    user: () => null,
                                    content: () => null,
                                  },
                                });
                              }
                              if (
                                parent &&
                                parent.user === null &&
                                parent.replies?.length === 1 &&
                                parent.replies?.[0] === commentOrAnnotation
                              ) {
                                // Case 3: this is a reply to a deleted comment, delete the parent
                                // if this is the only comment left
                                cache.evict({ id: cache.identify(parent) });
                              }
                            },
                          });
                        }
                      }}
                      className={clsx(classes.actionButton, {
                        [classes.actionButtonInReply]: isReply,
                      })}
                      color="primary"
                    >
                      delete
                    </Button>

                    <Box m={1} />
                  </>
                )}

                {!isReply && commentOrAnnotation.clip && (
                  <>
                    {commentOrAnnotation.__typename === "CaseClipAnnotation" ? (
                      <Button
                        component={QueryParamLink}
                        params={{
                          clip: commentOrAnnotation.clip.id,
                          startTime: commentOrAnnotation.time,
                        }}
                        removalParams={["screenshot"]}
                        className={classes.actionButton}
                        color="primary"
                      >
                        view annotation
                      </Button>
                    ) : (
                      <Button
                        component={QueryParamLink}
                        params={{ clip: commentOrAnnotation.clip.id }}
                        removalParams={["startTime", "screenshot"]}
                        disabled={clipId === commentOrAnnotation.clip.id}
                        className={classes.actionButton}
                        color="primary"
                      >
                        {clipId === commentOrAnnotation.clip.id
                          ? "(currently viewing)"
                          : "view clip"}
                      </Button>
                    )}

                    <Box m={1} />
                  </>
                )}
                {!isReply && commentOrAnnotation.caseScreenshotId && (
                  <>
                    {commentOrAnnotation.__typename === "CaseClipAnnotation" ? (
                      <Button
                        component={QueryParamLink}
                        params={{
                          screenshot: commentOrAnnotation.caseScreenshotId,
                        }}
                        removalParams={["startTime", "clip"]}
                        className={classes.actionButton}
                        color="primary"
                      >
                        view annotation
                      </Button>
                    ) : (
                      <Button
                        component={QueryParamLink}
                        params={{
                          screenshot: commentOrAnnotation.caseScreenshotId,
                        }}
                        removalParams={["startTime", "clip"]}
                        disabled={
                          screenshotId === commentOrAnnotation.caseScreenshotId
                        }
                        className={classes.actionButton}
                        color="primary"
                      >
                        {screenshotId === commentOrAnnotation.caseScreenshotId
                          ? "(currently viewing)"
                          : "view screenshot"}
                      </Button>
                    )}

                    <Box m={1} />
                  </>
                )}
              </Box>
            )}

          {creatingReply && (
            <Box m={2} ml={0}>
              <CreateCaseCommentBox
                disabled={createCommentInFlight}
                label="Reply"
                autoFocus
                onSubmitComment={(comment, mentions) => {
                  const parentIdSpread =
                    commentOrAnnotation.__typename === "CaseClipAnnotation"
                      ? { parentAnnotationId: commentOrAnnotation.id }
                      : { parentId: commentOrAnnotation.id };

                  setCreatingReply(false);

                  createCaseComment({
                    variables: {
                      caseId,
                      value: {
                        content: comment,
                        caseClipId: clipId,
                        ...parentIdSpread,
                      },
                    },
                    optimisticResponse: {
                      __typename: "Mutation",
                      createCaseComment: {
                        __typename: "CaseCommentCreationResponse",
                        comment: {
                          __typename: "CaseComment",
                          id: -1,
                          content: comment,
                          createdAt: new Date().toISOString(),
                          mentions: {},
                          user: me!,
                          clip: null,
                        },
                        addedCollaborators: Object.entries(mentions).map(
                          ([id, name]) => ({
                            __typename: "User",
                            id: Number(id),
                            name,
                            email: "n/a",
                          })
                        ),
                      },
                    },
                    update: (cache, { data }) => {
                      cache.modify({
                        id: cache.identify(commentOrAnnotation),
                        fields: {
                          replies: concat({
                            __ref: cache.identify(
                              data!.createCaseComment.comment
                            ),
                          }),
                        },
                      });

                      cache.modify({
                        id: `Case:${caseId}`,
                        fields: {
                          collaborators: concat(
                            data!.createCaseComment.addedCollaborators
                          ),
                        },
                      });
                    },
                  }).catch((e) => {
                    pushSnackbar(
                      `Problem posting reply: ${e.message}. Please try again later.`,
                      FeedbackType.Error
                    );
                  });
                }}
                onCancel={() => setCreatingReply(false)}
              />
            </Box>
          )}
        </div>
      </div>
    </>
  );
}

function WrappedAnnotationLabel({
  annotation,
}: {
  annotation: AnnotationType;
}) {
  const label = (
    <AnnotationLabel
      variant="contained"
      color={
        // Starts at "yellow" for A, moves towards red for B, C ...etc
        getColorForLabel(annotation.label)
      }
      width={24}
    >
      {annotation.label}
    </AnnotationLabel>
  );
  return annotation.clip ? (
    <IconButton
      component={QueryParamLink}
      className="w-12 h-12"
      params={{
        clip: annotation.clip.id,
        startTime: annotation.time,
      }}
      state={{ time: Date.now() }}
      size="large"
    >
      {label}
    </IconButton>
  ) : (
    <div className="m-2">{label}</div>
  );
}

function getClipDuration({
  startTime,
  endTime,
}: {
  startTime: string;
  endTime: string;
}) {
  return differenceInSeconds(new Date(startTime), new Date(endTime));
}

function AddedCaseItem({
  item,
  viewing,
}: {
  item: ClipType | ScreenshotType;
  viewing: boolean;
}) {
  const postTime = usePostTime(item.createdAt);
  const isClip =
    item.__typename === "CaseClip" || item.__typename === "SharedCaseClip";

  if (!item.createdBy) return null;

  return (
    <div className="flex items-center mb-2">
      <div className="flex-center flex-col mr-2">
        <div className="flex-center flex-col w-10 h-10">
          {isClip ? <AddedClipIcon /> : <ImageIcon />}
        </div>
        <Divider
          orientation="vertical"
          className="flex-grow w-px min-h-[20px]"
        />
      </div>
      <div className="flex-grow">
        <div className="flex items-center flex-wrap">
          <Typography className="font-bold">
            {item.createdBy.name} added a{" "}
            <QueryParamLink
              params={isClip ? { clip: item.id } : { screenshot: item.id }}
              removalParams={["startTime", isClip ? "screenshot" : "clip"]}
            >
              {isClip ? "New Clip" : "New Screenshot"}
            </QueryParamLink>
          </Typography>
          {viewing && (
            <Typography variant="caption" className="opacity-60 ml-2">
              (currently viewing)
            </Typography>
          )}
        </div>
        <Typography variant="caption" className="opacity-60">
          {postTime}
        </Typography>
      </div>
    </div>
  );
}

function useInitialCommentScroll(
  paramName: "comment" | "annotation",
  commentOrAnnotationId: number
) {
  const [commentOrAnnotationIdParam] = useQueryParam(paramName, NumberParam);
  const [scrolled, setScrolled] = useState(false);
  const refCallback = useCallback(
    (node: HTMLDivElement | null) => {
      if (
        !node ||
        scrolled ||
        commentOrAnnotationId !== commentOrAnnotationIdParam
      )
        return;

      node.scrollIntoView({ behavior: "smooth", block: "center" });
      setScrolled(true);
    },
    [commentOrAnnotationIdParam, commentOrAnnotationId, scrolled]
  );

  return {
    ref: refCallback,
  };
}

function CaseEvent({ event }: { event: EventType }) {
  if (event.eventType === CaseEventTypes.ResolutionStatusUpdate) {
    return (
      <ResolutionStatusEventComponent event={event as ResolutionStatusEvent} />
    );
  }
  return null;
}

const resolutionStatusIconMap = {
  [ResolutionStatus.Open]: {
    Icon: OpenStatusIcon,
    iconColor: CERULEAN_BLUE,
  },
  [ResolutionStatus.Closed]: {
    Icon: CheckCircleIcon,
    iconColor: GREEN,
  },
};

type ResolutionStatusEvent = Omit<EventType, "eventType" | "metadata"> & {
  eventType: CaseEventTypes.ResolutionStatusUpdate;
  metadata: {
    newValue: ResolutionStatus;
  };
};
function ResolutionStatusEventComponent({
  event,
}: {
  event: ResolutionStatusEvent;
}) {
  const postTime = usePostTime(event.createdAt);

  const { Icon, iconColor } = resolutionStatusIconMap[event.metadata.newValue];
  return (
    <div className="flex items-center mb-2">
      <div className="flex-center flex-col mr-2">
        <div className="flex-center flex-col w-10 h-10">
          <Icon style={{ color: iconColor }} />
        </div>
        <Divider
          orientation="vertical"
          className="flex-grow w-px min-h-[20px]"
        />
      </div>
      <div className="flex-grow">
        <div className="flex items-center flex-wrap">
          <Typography className="font-bold">
            {event.triggeredBy.name} marked this case{" "}
            <strong className="capitalize" style={{ color: iconColor }}>
              {event.metadata.newValue}
            </strong>
          </Typography>
        </div>
        <Typography variant="caption" className="opacity-60">
          {postTime}
        </Typography>
      </div>
    </div>
  );
}

function usePostTime(createdAt: string) {
  const now = new Date();
  const createdAtDate = new Date(createdAt);
  const ageInHours = differenceInHours(createdAtDate, now);
  return ageInHours > 5
    ? `${formatDistanceStrict(now, createdAtDate)} ago`
    : format("p", createdAtDate);
}

gql`
  query caseMetadata($id: Int!) {
    case(id: $id) {
      ...CaseMetadata
    }
  }

  ${CASE_METADATA_FRAGMENT}
`;

gql`
  query metadataForSharedCase($token: String!) {
    case: sharedCase(token: $token) {
      ...SharedCaseMetadata
    }
  }

  ${SHARED_CASE_METADATA_FRAGMENT}
`;

gql`
  mutation createCaseComment($caseId: Int!, $value: CaseCommentInput!) {
    createCaseComment(caseId: $caseId, value: $value) {
      comment {
        ...CaseCommentWithReplies
      }
      addedCollaborators {
        id
        name
        email
      }
    }
  }
  ${CASE_COMMENT_WITH_REPLIES_FRAGMENT}
`;

gql`
  mutation deleteCaseComment($id: Int!) {
    deleteCaseComment(id: $id) {
      id
      deleted
    }
  }
`;

gql`
  mutation deleteCaseClipAnnotation($id: Int!) {
    deleteCaseClipAnnotation(id: $id) {
      id
      deleted
    }
  }
`;
