import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Divider } from "@mui/material";
import Alert from "@mui/material/Alert";
import { sortBy } from "lodash/fp";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import AddedAppliance from "@/pages/Settings/LocationSettings/Appliance/AddedAppliance";
import ApplianceForm from "@/pages/Settings/LocationSettings/Appliance/ApplianceForm";

import { Loading } from "@/components/Loading";
import { ZendeskArticle, ZendeskLink } from "@/components/Zendesk/ZendeskLink";
import { DefaultDialog, useDialog } from "@/components/shared/Dialog";

import { useLocationWithAppliancesQuery } from "@/generated-models";
import { usePermissions } from "@/hooks/usePermissions";

export function ApplianceSetup({
  locationId,
  setup,
}: {
  locationId: number;
  setup?: boolean;
}) {
  const navigate = useNavigate();
  const hasPermission = usePermissions();
  const canEdit = hasPermission("devices_manage");
  const [showInput, setShowInput] = useState(false);
  const { open: openApplianceOfflineDialog, ...dialogProps } = useDialog();
  const { data } = useLocationWithAppliancesQuery({
    variables: { id: locationId },
    pollInterval: 10000,
    // Background info on nextFetchPolicy (buried in an issue):
    // https://github.com/apollographql/apollo-client/issues/6760#issuecomment-668188727
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const appliances = data?.location?.appliances;

  // Show add appliance form when list is empty
  const appliancesCount = appliances?.length;

  useEffect(() => {
    if (appliancesCount === 0) {
      setShowInput(true);
    }
  }, [appliancesCount]);

  if (!appliances) {
    return (
      <div style={{ padding: "48px 0", margin: "0 auto" }}>
        <Loading />
      </div>
    );
  }

  const appliancesOffline = appliances.filter((a) => !a.health.online).length;
  const navigateToCamera = () => {
    navigate(`../cameras`);
  };

  return (
    <Box>
      <Box ml={1}>
        {sortBy("id", appliances).map((appliance) => (
          <AddedAppliance
            key={appliance.id}
            locationId={locationId}
            appliance={appliance}
          />
        ))}
        {showInput && (
          <ApplianceForm
            onSubmit={() => setShowInput(false)}
            onDelete={() => setShowInput(false)}
            locationId={locationId}
          />
        )}
      </Box>
      {appliancesOffline > 0 && (
        <Alert severity="warning" style={{ marginTop: 4 }}>
          You won't be able to add cameras to devices that are not connected.
        </Alert>
      )}
      <Divider style={{ margin: "0px 20px" }} />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{ padding: 10 }}
      >
        {canEdit && (
          <div className="flex items-center gap-x-1">
            <Button
              color="primary"
              variant="outlined"
              disabled={showInput}
              onClick={() => setShowInput(true)}
              style={{
                alignSelf: "stretch",
                marginLeft: 10,
                marginRight: 2,
                boxSizing: "border-box",
              }}
            >
              <AddIcon />
              Add Appliance
            </Button>
            <ZendeskLink
              color="primary"
              article={ZendeskArticle.INSTALL_APPLIANCE}
            />
          </div>
        )}
        {setup && (
          <Button
            color="primary"
            variant="contained"
            component={Link}
            to="../cameras"
            onClick={(event: React.MouseEvent) => {
              if (appliancesOffline > 0 && setup) {
                openApplianceOfflineDialog();
                event.preventDefault();
              }
            }}
            style={{
              marginLeft: 10,
              marginRight: 10,
              boxSizing: "border-box",
            }}
          >
            Continue
          </Button>
        )}
      </Box>
      <DefaultDialog
        {...dialogProps}
        confirm={navigateToCamera}
        confirmColor="primary"
        title="Appliances are Offline"
        content={`${appliancesOffline} appliance${
          appliancesOffline > 1 && "s"
        } are offline. Are you sure you want to continue?`}
      />
    </Box>
  );
}
