import * as yup from "yup";

export function required(value: any) {
  return !value ? "Required" : undefined;
}
const emailSchema = yup
  .string()
  .email('":email" is not a valid email address')
  .required();
export async function validateEmail(rawInput?: string) {
  if (!rawInput) return "Please provide an email address";
  const invalidEmail = await emailSchema
    .validate(rawInput)
    .then(() => false)
    .catch(() => true);

  if (invalidEmail) return `"${rawInput}" is not a valid email address`;
}

// Source: https://regexr.com/3c53v
const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;

export async function validatePhoneNumber(rawInput?: string) {
  if (!rawInput) return "Please provide a phone number";

  if (!phoneRegExp.test(rawInput))
    return `"${rawInput}" is not a valid phone number`;
}
