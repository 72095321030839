import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, Typography } from "@mui/material";
import clsx from "clsx";
import React, { Suspense, lazy, ReactNode } from "react";
import { useNavigate } from "react-router-dom";

import { useBreakpoints } from "@/util/useBreakpoints";

import { ErrorBoundary } from "@/components/ErrorBoundary";

const ErrorDots = lazy(() => import("./Animation/ErrorDots"));

interface ErrorPageProps {
  title: string;
  description: ReactNode;
  dense?: boolean;
  narrow?: boolean;
  dark?: boolean;
}

export function ErrorMessage({
  title,
  description,
  dense,
  narrow,
}: ErrorPageProps) {
  const { fitsDesktop } = useBreakpoints();
  const navigate = useNavigate();
  const message = (
    <div className="text-text sm:max-w-sm">
      <Typography
        variant="h2"
        className={clsx("mb-2 text-center md:text-start", {
          "text-4xl": !dense,
          "text-2xl": dense,
        })}
      >
        {title}
      </Typography>

      {typeof description === "string" ? (
        <>
          <Typography className="text-center md:text-start">
            {description}
          </Typography>
          {!fitsDesktop && (
            <div className="pt-4 flex items-center justify-center">
              <Button
                startIcon={<ArrowBackIcon />}
                variant="contained"
                color="primary"
                onClick={() => navigate(-1)}
              >
                Go Back
              </Button>
            </div>
          )}
        </>
      ) : (
        description
      )}
    </div>
  );

  return (
    <div
      role="alert"
      className={clsx("flex-center flex-col  p-10", {
        "h-[60vh]": !dense,
        "h-[20vh]": dense,
        "md:flex-row": !narrow,
      })}
    >
      <ErrorBoundary renderError={() => message}>
        <Suspense fallback={null}>
          <div className="mr-14 mb-4 sm:mr-2 sm:mb-0 sm:-ml-14">
            <ErrorDots />
          </div>
          {message}
        </Suspense>
      </ErrorBoundary>
    </div>
  );
}

export function RefreshButtonErrorMessage({
  title,
  description,
}: ErrorPageProps) {
  return (
    <ErrorMessage
      title={title}
      description={
        <>
          <Typography>{description}</Typography>
          <Button
            variant="contained"
            color="primary"
            className="mt-2 bg-primary"
            onClick={() => window.location.reload()}
          >
            Refresh
          </Button>
        </>
      }
    />
  );
}
