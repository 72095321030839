import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  DialogTitle,
} from "@mui/material";

interface AudioEnableAcknowledgmentModalProps {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export function AudioEnableAcknowledgmentModal({
  open,
  onCancel,
  onConfirm,
}: AudioEnableAcknowledgmentModalProps) {
  return (
    <Dialog
      classes={{
        paper: "rounded-lg max-w-[493px]",
      }}
      open={open}
      onClose={onCancel}
    >
      <DialogTitle className="flex justify-between items-center py-4 bg-[#F4F4F4]">
        <Typography variant="h3">Audio Recording Enablement</Typography>
      </DialogTitle>
      <DialogContent className="p-6">
        <Typography variant="body1" className="mb-4">
          The laws regarding audio recording without consent vary by state
          within the United States. Please consult with a local attorney to
          ensure compliance with applicable state and federal laws prior to
          enabling audio controls.
        </Typography>
        <Typography variant="body1" className="mb-4">
          By clicking "Enable," you confirm that you have read and acknowledged
          this notice.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          className="shadow-none text-xs leading-[22px] font-normal rounded-md bg-[#DAEEFF] text-primary hover:brightness-75"
          variant="contained"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          className="shadow-none text-xs leading-[22px] font-normal rounded-md"
          color="primary"
          variant="contained"
          onClick={onConfirm}
        >
          Enable
        </Button>
      </DialogActions>
    </Dialog>
  );
}
