import { Popover, Skeleton } from "@mui/material";
import { GridEventListener } from "@mui/x-data-grid";
import { GridApiCommunity } from "@mui/x-data-grid/internals";
import clsx from "clsx";
import { useEffect, useState } from "react";

import { useClosestStillQuery } from "@/generated-models";

import { GridIntegrationEvent } from "../hooks";

export function IntegrationsEventPopover({
  apiRef,
}: {
  apiRef: React.MutableRefObject<GridApiCommunity>;
}) {
  const [activeRow, setActiveRow] = useState<GridIntegrationEvent | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const handleEvent: GridEventListener<"rowMouseEnter"> = (params, event) => {
      setAnchorEl(event.currentTarget);
      setActiveRow(params.row);
    };
    return apiRef.current.subscribeEvent("rowMouseEnter", handleEvent);
  }, [apiRef, setAnchorEl]);

  useEffect(() => {
    const handleEvent = () => setAnchorEl(null);
    return apiRef.current.subscribeEvent("rowMouseLeave", handleEvent);
  }, [apiRef, setAnchorEl]);

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const eventTimestamp = activeRow?.ts
    ? new Date(activeRow.ts).toISOString()
    : undefined;

  return (
    <Popover
      id="mouse-over-popover"
      sx={{ pointerEvents: "none" }}
      open={Boolean(anchorEl) && Boolean(activeRow) && Boolean(eventTimestamp)}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      transformOrigin={{ vertical: "bottom", horizontal: "center" }}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      {activeRow && eventTimestamp && (
        <CameraStillGrid
          cameras={activeRow.cameras}
          timestamp={eventTimestamp}
        />
      )}
    </Popover>
  );
}

export function CameraStillGrid({
  cameras,
  timestamp,
  fullWidth = false,
}: {
  cameras: { id: number }[];
  timestamp: string;
  fullWidth?: boolean;
}) {
  return (
    <div
      className={clsx("grid bg-black gap-0.5", {
        "p-0.5": !fullWidth || cameras.length > 1,
        "grid-cols-2": cameras.length > 1,
      })}
    >
      {cameras.map(({ id }) => (
        <div
          className={clsx(
            "h-full aspect-video flex-center text-white",
            fullWidth ? "w-full" : "w-44"
          )}
        >
          <EventStill key={id} camId={id} targetTimestamp={timestamp} />
        </div>
      ))}
    </div>
  );
}

function EventStill({
  camId,
  targetTimestamp,
}: {
  camId: number;
  targetTimestamp: string;
}) {
  const { data, loading } = useClosestStillQuery({
    variables: { camId, targetTimestamp },
  });

  if (loading) {
    return (
      <Skeleton variant="rectangular" className="bg-white/20 h-full w-full" />
    );
  }

  if (!data?.closestStill) return <>Still not found</>;

  return (
    <img src={data.closestStill.src} alt="event still" className="w-full" />
  );
}
