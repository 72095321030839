import { ReactNode } from "react";

type VariantKeys = number | "multi";
export type PluralizeVariants<T extends ReactNode> = Record<VariantKeys, T>;

export function pluralize<T extends ReactNode>(
  variants: PluralizeVariants<T>,
  value: number
) {
  return variants[value] || variants.multi;
}
