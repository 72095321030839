import BubbleChartIcon from "@mui/icons-material/BubbleChart";
import ServerIcon from "@mui/icons-material/DnsRounded";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CameraIcon from "@mui/icons-material/Videocam";
import { Button } from "@mui/material";
import clsx from "clsx";
import { ReactNode } from "react";
import { StringParam, useQueryParam } from "use-query-params";

import { ASSISTANT_RESOURCE } from "@/components/Assistant/utils/editor/constant";

import { AssistantPromptHelpModal } from "./AssistantPromptHelpModal";

interface AssistantPromptFooterActionProps {
  className?: string;
  condensed?: boolean;
  inverted?: boolean;
  disabled?: boolean;
  onClick: () => void;
  children: ReactNode;
}

function AssistantPromptFooterAction({
  className,
  condensed,
  inverted,
  disabled,
  onClick,
  children,
}: AssistantPromptFooterActionProps) {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      variant="contained"
      size="small"
      className={clsx(
        "rounded-[44px] text-[#A6C8E1] hover:text-white shadow-none disabled:opacity-50 h-6",
        {
          "bg-[#394854]": !inverted,
          "bg-[#414C56]": inverted,
          "text-sm leading-4 py-1 px-[10px]": !condensed,
          "text-xs leading-3 py-0.5 px-2": condensed,
        },
        className
      )}
    >
      {children}
    </Button>
  );
}

interface AssistantPromptInputFooterProps {
  condensed?: boolean;
  onTriggerSuggestion: () => void;
}

export function AssistantPromptInputFooter({
  condensed,
  onTriggerSuggestion,
}: AssistantPromptInputFooterProps) {
  const [, setSuggestionType] = useQueryParam("suggestion-type", StringParam);

  const iconClassName = clsx("-ml-1 h-4 opacity-50", condensed && "hidden");

  const onTriggerResourceSuggestion = (resource: ASSISTANT_RESOURCE) => {
    setSuggestionType(resource);
    onTriggerSuggestion();
  };

  return (
    <div
      className={clsx("flex items-center justify-start w-full px-4 mb-1", {
        "gap-1": !condensed,
        "gap-0.5": condensed,
      })}
    >
      <AssistantPromptFooterAction
        condensed={condensed}
        onClick={() => {
          onTriggerResourceSuggestion(ASSISTANT_RESOURCE.prompt);
        }}
      >
        <BubbleChartIcon
          className={clsx(iconClassName, "transform -scale-x-100")}
        />
        Examples
      </AssistantPromptFooterAction>
      <AssistantPromptFooterAction
        className="hidden"
        condensed={condensed}
        onClick={() => {
          onTriggerResourceSuggestion(ASSISTANT_RESOURCE.location);
        }}
      >
        <LocationOnIcon className={iconClassName} />
        Locations
      </AssistantPromptFooterAction>
      <AssistantPromptFooterAction
        condensed={condensed}
        onClick={() => {
          onTriggerResourceSuggestion(ASSISTANT_RESOURCE.appliance);
        }}
      >
        <ServerIcon className={iconClassName} />
        Appliances
      </AssistantPromptFooterAction>
      <AssistantPromptFooterAction
        condensed={condensed}
        onClick={() => {
          onTriggerResourceSuggestion(ASSISTANT_RESOURCE.camera);
        }}
      >
        <CameraIcon className={iconClassName} />
        Cameras
      </AssistantPromptFooterAction>
      <div className="ml-auto z-10">
        <AssistantPromptHelpModal condensed={condensed} />
      </div>
    </div>
  );
}
