import { useEffect } from "react";

import { usePlayerFullscreen } from "./PlayerControls";

/**
 * When rendered within a <PlayerBase />, will automatically switch the player into
 * full screen mode when the device is turned around into landscape mode. Will also
 * automatically exit full screen mode when turned to portait.
 */

export function MobileLandscapeFullscreen() {
  const { enter, exit } = usePlayerFullscreen();
  useEffect(() => {
    if (!window.screen?.orientation) return; // check for browser support

    function onOrientationChange(this: ScreenOrientation): any {
      if (this.type.startsWith("landscape")) {
        enter().catch(console.error);
      } else if (this.type.startsWith("portrait")) {
        exit();
      }
    }
    window.screen.orientation.addEventListener("change", onOrientationChange);
    return () =>
      window.screen.orientation.removeEventListener(
        "change",
        onOrientationChange
      );
  }, [enter, exit]);

  return null;
}
