import { editor } from "monaco-editor";

export const SPOT_ASSIST_LANG = "spot-assistant-lang";
export const SPOT_ASSIST_THEME = "spot-assistant-theme";

export const PATTERN_PROMPT_INPUT = /\(\((.*)\)\)/;
export const PATTERN_VARIABLE = /\[\[(.*)\]\]/;

export enum ASSISTANT_RESOURCE {
  appliance = "appliance",
  camera = "camera",
  location = "location",
  prompt = "prompt",
}

export const ASSISTANT_COMMANDS = {
  INJECT_RESOURCE: "spot.inject_resource",
};

export const EDITOR_BASE_OPTIONS: editor.IStandaloneEditorConstructionOptions = {
  acceptSuggestionOnEnter: "off",
  contextmenu: false,
  folding: false,
  fontFamily: "Roboto",
  fontSize: 18,
  glyphMargin: false,
  hideCursorInOverviewRuler: true,
  lineDecorationsWidth: 0,
  lineNumbersMinChars: 0,
  lineNumbers: "off",
  minimap: {
    enabled: false,
  },
  overviewRulerLanes: 0,
  overviewRulerBorder: false,
  scrollbar: {
    horizontalScrollbarSize: 4,
    useShadows: false,
    verticalScrollbarSize: 4,
  },
  scrollBeyondLastLine: false,
};
