import { makeStyles } from "tss-react/mui";

export interface FilterFieldOption {
  label: string;
  category?: boolean;
  secondaryLabel?: string;
  value: string | number;
}

export interface FilterFieldProps<T> {
  placeholder: string;
  filterName: string;
  disabled?: boolean;
  options: FilterFieldOption[];
  label: string;
  getValue?: (value: T | null) => string;
  classes?: Partial<{ root: string; notchedOutline: string }>;
  className?: string;
  style?: React.CSSProperties;
  selectedLabel?: string;
}

export const useTransparentFilterFieldStyles = makeStyles()(() => ({
  root: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#AAD8FF",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#AAD8FF",
    },
  },
  notchedOutline: {
    border: "1px solid transparent",
  },
}));
