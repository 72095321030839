import { ErrorOutline } from "@mui/icons-material";
import { SvgIconProps } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import { ComponentType } from "react";

import {
  CarIcon,
  ForkliftAbsentIcon,
  ForkliftPresentIcon,
  IdleTimeIcon,
  LPRIcon,
  MotionIcon,
  PeopleAbsentIcon,
  PeoplePresentIcon,
} from "@/icons/AlertIcons";
import {
  AccessControlIcon,
  EnvironmentSensorIcon,
  IOBoardIcon,
} from "@/icons/Icons";

import {
  AlertDetectionCountOperator,
  AlertType,
  Page_AlertsQuery,
} from "@/generated-models";

import { intelligentFiltersConfig } from "../Search/intelligence/intelligence";

export type Notification = Page_AlertsQuery["notifications"][number];

export const alertTypesConfig: Record<
  AlertType,
  {
    typeName: string;
    threshold?: {
      title: string;
      description: string;
    };
    icon: ComponentType<SvgIconProps>;
    color?: string;
  }
> = {
  [AlertType.PeopleIdle]: {
    typeName: "People Idle Time",
    threshold: {
      title: "People Idle Threshold",
      description:
        "How long a person should be idle before we generate an alert.",
    },
    icon: IdleTimeIcon,
    color: intelligentFiltersConfig.vehicle.color(),
  },
  [AlertType.Motion]: {
    typeName: "Motion Event",
    threshold: {
      title: "Motion Threshold",
      description:
        "How long motion should be detected before we generate an alert.",
    },
    icon: MotionIcon,
    color: intelligentFiltersConfig.motion.color(),
  },
  [AlertType.CameraOffline]: {
    typeName: "Camera Disconnect",
    threshold: {
      title: "Disconnect Threshold",
      description:
        "How long a camera should be disconnected before we generate an alert.",
    },
    icon: ErrorOutline,
    color: "#E30000",
  },
  [AlertType.People]: {
    typeName: "People",
    threshold: {
      title: "Presence Threshold",
      description:
        "How long people should be present before we generate an alert.",
    },
    icon: PeoplePresentIcon,
    color: intelligentFiltersConfig.people.color(),
  },
  [AlertType.PeopleAbsent]: {
    typeName: "People Absent",
    threshold: {
      title: "Presence Threshold",
      description:
        "How long people should be absent before we generate an alert.",
    },
    icon: PeopleAbsentIcon,
    color: intelligentFiltersConfig.people.color(),
  },
  [AlertType.Forklift]: {
    typeName: "Forklifts",
    threshold: {
      title: "Presence Threshold",
      description:
        "How long should forklifts be present before we generate an alert.",
    },
    icon: ForkliftPresentIcon,
    color: intelligentFiltersConfig.forklift.color(),
  },
  [AlertType.ForkliftAbsent]: {
    typeName: "Forklifts Absent",
    threshold: {
      title: "Presence Threshold",
      description:
        "How long forklifts should be absent before we generate an alert.",
    },
    icon: ForkliftAbsentIcon,
    color: intelligentFiltersConfig.forklift.color(),
  },
  [AlertType.ForkliftIdle]: {
    typeName: "Forklift Idle Time",
    threshold: {
      title: "Forklift Idle Threshold",
      description:
        "How long a forklift should be idle before we generate an alert.",
    },
    icon: IdleTimeIcon,
    color: intelligentFiltersConfig.forklift.color(),
  },
  [AlertType.Vehicle]: {
    typeName: "Vehicles",
    threshold: {
      title: "Presence Threshold",
      description:
        "How long vehicles should be present before we generate an alert.",
    },
    icon: CarIcon,
    color: intelligentFiltersConfig.vehicle.color(),
  },
  [AlertType.VehicleIdle]: {
    typeName: "Vehicle Idle Time",
    threshold: {
      title: "Vehicle Idle Threshold",
      description:
        "How long a vehicle should be idle before we generate an alert.",
    },
    icon: IdleTimeIcon,
    color: intelligentFiltersConfig.vehicle.color(),
  },
  [AlertType.VehicleInterestList]: {
    typeName: "License Plates of Interest",
    threshold: {
      title: "License Plates of Interest Threshold",
      description:
        "How long a vehicle should be idle before we generate an alert.",
    },
    icon: LPRIcon,
    color: intelligentFiltersConfig.vehicle.color(),
  },
  [AlertType.AccessControl]: {
    typeName: "Access Control",
    threshold: {
      title: "Access Control Threshold",
      description: "",
    },
    icon: AccessControlIcon,
  },
  [AlertType.EnvironmentSensor]: {
    typeName: "Environment Sensor",
    threshold: {
      title: "Environment Sensor Threshold",
      description: "",
    },
    icon: EnvironmentSensorIcon,
  },
  [AlertType.IoBoard]: {
    typeName: "IO Board",
    threshold: {
      title: "IO Board Threshold",
      description: "",
    },
    icon: IOBoardIcon,
  },
};

export const alertDetectionCountOperator = {
  [AlertDetectionCountOperator.Any]: "Any number",
  [AlertDetectionCountOperator.EqualTo]: "Equal to",
  [AlertDetectionCountOperator.GreaterThan]: "More than",
  [AlertDetectionCountOperator.LessThan]: "Fewer than",
};

export const aiAlertTypes = new Set([
  AlertType.People,
  AlertType.PeopleAbsent,
  AlertType.Vehicle,
  AlertType.VehicleIdle,
]);

const aiThresholdOptions = {
  options: [0, 30, 60, 120, 300, 600],
  custom: true,
};

export const useAlertThresholdOptions = (): Record<
  AlertType,
  { options: number[]; custom: boolean } | null
> => {
  const { alertCamOneMin } = useFlags();

  return {
    [AlertType.CameraOffline]: {
      options: [...(alertCamOneMin ? [60] : []), 600, 1800, 3600, 7200, 43200],
      custom: false,
    },
    [AlertType.Motion]: aiThresholdOptions,
    [AlertType.People]: aiThresholdOptions,
    [AlertType.PeopleIdle]: aiThresholdOptions,
    [AlertType.PeopleAbsent]: aiThresholdOptions,
    [AlertType.Forklift]: aiThresholdOptions,
    [AlertType.ForkliftAbsent]: aiThresholdOptions,
    [AlertType.ForkliftIdle]: aiThresholdOptions,
    [AlertType.Vehicle]: aiThresholdOptions,
    [AlertType.VehicleIdle]: aiThresholdOptions,
    [AlertType.VehicleInterestList]: aiThresholdOptions,
    [AlertType.AccessControl]: aiThresholdOptions,
    [AlertType.EnvironmentSensor]: aiThresholdOptions,
    [AlertType.IoBoard]: aiThresholdOptions,
  };
};

export const alertTypeObjectIds: Record<AlertType, string[]> = {
  [AlertType.CameraOffline]: [],
  [AlertType.Motion]: intelligentFiltersConfig.motion.objectIds,
  [AlertType.People]: intelligentFiltersConfig.people.objectIds,
  [AlertType.PeopleIdle]: intelligentFiltersConfig.people.objectIds,
  [AlertType.PeopleAbsent]: intelligentFiltersConfig.people.objectIds,
  [AlertType.Forklift]: intelligentFiltersConfig.forklift.objectIds,
  [AlertType.ForkliftAbsent]: intelligentFiltersConfig.forklift.objectIds,
  [AlertType.ForkliftIdle]: intelligentFiltersConfig.forklift.objectIds,
  [AlertType.Vehicle]: intelligentFiltersConfig.vehicle.objectIds,
  [AlertType.VehicleIdle]: intelligentFiltersConfig.vehicle.objectIds,
  [AlertType.VehicleInterestList]: intelligentFiltersConfig.vehicle.objectIds,
  [AlertType.AccessControl]: [],
  [AlertType.EnvironmentSensor]: [],
  [AlertType.IoBoard]: [],
};

export const formattedAlertTypes: Record<AlertType, string> = {
  [AlertType.CameraOffline]: "Camera Offline",
  [AlertType.PeopleIdle]: "People Idle Time",
  [AlertType.Motion]: "Motion",
  [AlertType.People]: "Person",
  [AlertType.PeopleAbsent]: "People Absent",
  [AlertType.Forklift]: "Forklift",
  [AlertType.ForkliftAbsent]: "Forklift Absent",
  [AlertType.ForkliftIdle]: "Forklift Idle Time",
  [AlertType.Vehicle]: "Vehicle",
  [AlertType.VehicleIdle]: "Vehicle Idle Time",
  [AlertType.VehicleInterestList]: "License Plates of Interest",
  [AlertType.AccessControl]: "Access Control",
  [AlertType.EnvironmentSensor]: "Environment Sensor",
  [AlertType.IoBoard]: "IO Board",
};
