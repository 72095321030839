import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import * as clipboard from "clipboard-polyfill/build/clipboard-polyfill.promise";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import gql from "graphql-tag";
import { useCallback, useState } from "react";

import { generateCaseShareUrl, generateExternalLink } from "@/util/camera";
import { validateEmail } from "@/util/form";

import { ShareSaveIcon } from "@/components/Player/PlayerIcons";

import {
  useCreateSharedCaseMetadataMutation,
  useDeleteSharedCaseMetadataMutation,
  useSendSharedCaseEmailMutation,
  useSharedCaseMetadataByCaseIdQuery,
} from "@/generated-models";
import { usePrefixOrgSlug } from "@/hooks/useOrgRouteBase";

export function ShareCaseButton({
  caseId,
  open: openProp,
  close,
  openCollaborators,
  opened,
}: {
  caseId: number;
  open: () => void;
  close: () => void;
  openCollaborators: () => void;
  opened: boolean;
}) {
  const prefixOrgSlug = usePrefixOrgSlug();
  const { data } = useSharedCaseMetadataByCaseIdQuery({
    variables: { caseId },
  });
  const [
    createPublicSharedCaseMetadataMutation,
    publicSharedCaseMetadata,
  ] = useCreateSharedCaseMetadataMutation({
    variables: {
      caseId,
    },
  });
  const [sendSharedCaseEmail] = useSendSharedCaseEmailMutation({
    refetchQueries: [
      { query: SHARED_CASE_METADATA_BY_CASE_ID, variables: { caseId } },
    ],
  });
  const [deleteSharedCaseMetadata] = useDeleteSharedCaseMetadataMutation({
    refetchQueries: [
      { query: SHARED_CASE_METADATA_BY_CASE_ID, variables: { caseId } },
    ],
  });
  const open = useCallback(() => {
    createPublicSharedCaseMetadataMutation();
    openProp();
  }, [createPublicSharedCaseMetadataMutation, openProp]);
  const [copied, setCopied] = useState(false);

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        startIcon={<ShareSaveIcon />}
        onClick={open}
      >
        Share
      </Button>
      <Dialog open={opened} onClose={close}>
        <DialogTitle
          variant="h4"
          className="flex justify-between items-center py-2 bg-[#F4F4F4]"
        >
          Share Case
          <IconButton onClick={close} size="large">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText component="div" className="py-2 text-text">
            <Typography variant="body2">
              Provide view only access to this case securely by requiring email
              authentication, or by copying the link which can be accessed by
              anyone with the link.
            </Typography>
            <Box m={1} />
            <Typography variant="body2">
              To allow users to comment or add files,{" "}
              <span
                className="text-primary cursor-pointer"
                onClick={openCollaborators}
              >
                add them as a collaborator.
              </span>
            </Typography>
          </DialogContentText>
          <Typography variant="body2" className="mt-4 mb-2 font-bold">
            Authenticated share
          </Typography>
          <Formik
            initialValues={{
              email: "",
              caseId,
            }}
            onSubmit={async (values) => {
              sendSharedCaseEmail({ variables: values });
            }}
          >
            <Form className="grid grid-cols-[minmax(0,_1fr)_min-content] gap-y-4 gap-x-2">
              <Field
                variant="outlined"
                name="email"
                component={TextField}
                className="col-span-1"
                hiddenLabel
                placeholder="Email address"
                validate={validateEmail}
              />
              <Button
                color="primary"
                variant="contained"
                type="submit"
                className="h-[56px]"
              >
                Add
              </Button>
              {data?.sharedCaseMetadataByCaseId
                .filter((x) => x.email?.length)
                .map((item) => (
                  <>
                    <div className="col-span-1 ml-4">{item.email}</div>
                    <div className="flex justify-center">
                      <IconButton
                        onClick={() =>
                          deleteSharedCaseMetadata({
                            variables: { id: item.id },
                          })
                        }
                        size="medium"
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </>
                ))}
            </Form>
          </Formik>
          <Typography variant="body2" className="mt-4 font-bold">
            Copy link
          </Typography>
          <div className="grid grid-cols-[minmax(0,_1fr)_auto] gap-4 items-center">
            <div className="col-span-1">
              <strong>Anyone</strong> on the internet can use this link to{" "}
              <strong>view</strong> the case.
            </div>
            <Tooltip
              title={copied ? "Copied!" : "Copy to clipboard"}
              onClose={() => setCopied(false)}
              classes={{ popper: "pointer-events-none" }}
            >
              <span>
                <Button
                  color="primary"
                  disabled={copied || publicSharedCaseMetadata.loading}
                  onClick={async () => {
                    const shareLink = generateExternalLink(
                      prefixOrgSlug(
                        generateCaseShareUrl(
                          publicSharedCaseMetadata?.data
                            ?.createSharedCaseMetadata.shareToken ?? ""
                        )
                      )
                    );
                    await clipboard.writeText(shareLink);
                    setCopied(true);
                  }}
                >
                  Copy Link
                </Button>
              </span>
            </Tooltip>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

gql`
  mutation createSharedCaseMetadata($caseId: Int!) {
    createSharedCaseMetadata(caseId: $caseId) {
      id
      shareToken
    }
  }
`;

gql`
  mutation sendSharedCaseEmail($caseId: Int!, $email: String!) {
    sendSharedCaseEmail(caseId: $caseId, email: $email)
  }
`;

const SHARED_CASE_METADATA_BY_CASE_ID = gql`
  query sharedCaseMetadataByCaseId($caseId: Int!) {
    sharedCaseMetadataByCaseId(caseId: $caseId) {
      id
      expiry
      email
    }
  }
`;

gql`
  mutation deleteSharedCaseMetadata($id: Int!) {
    deleteSharedCaseMetadata(id: $id)
  }
`;
