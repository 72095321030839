import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Typography } from "@mui/material";
import clsx from "clsx";
import { ErrorMessage, FieldHookConfig, useField } from "formik";
import { Select, SelectProps, TextField, TextFieldProps } from "formik-mui";
import { PropsWithChildren, ReactNode } from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  label: {
    color: theme.palette.mode === "dark" ? "#EEE" : "#666666",
  },
  input: {
    background: theme.palette.mode === "dark" ? "transparent" : "white",
  },
}));

export function FormErrorMessage({ name }: { name: string }) {
  return (
    <ErrorMessage
      name={name}
      render={(message) => (
        <Typography
          className="text-[#db6262] text-xs"
          classes={{ root: "mt-1" }}
        >
          {message}
        </Typography>
      )}
    />
  );
}

export function TextInput({ label, ...props }: TextFieldProps) {
  const { classes } = useStyles();

  return (
    <div className="w-full">
      {label && (
        <label
          className={clsx("block font-medium mb-2", classes.label)}
          htmlFor={props.id ?? props.field.name}
        >
          {label}
        </label>
      )}
      <TextField
        hiddenLabel
        variant="outlined"
        InputProps={{
          classes: {
            input: clsx(
              "px-4 py-3 text-lg leading-5 font-bold border border-[#AAAAAA] rounded-lg h-auto",
              classes.input
            ),
            notchedOutline: "rounded-lg",
          },
        }}
        className="w-full"
        {...props}
        id={props.id ?? props.field.name}
      />
    </div>
  );
}

export function TextAreaInput(props: TextFieldProps) {
  const { classes } = useStyles();

  return (
    <TextInput
      {...props}
      multiline
      rows={4}
      InputProps={{
        classes: {
          root: clsx("px-4 py-3", classes.input),
          input: "text-lg leading-5 border border-[#AAAAAA] rounded-lg h-auto",
          notchedOutline: "rounded-lg",
        },
      }}
    />
  );
}

export function SelectInput({
  label,
  children,
  inputClassName,
  ...props
}: SelectProps & {
  unSerialValueFn: (value: string) => any;
  inputClassName?: string;
}) {
  const { classes } = useStyles();

  return (
    <div className="w-full">
      {label && (
        <label
          className={clsx("block font-medium mb-2", classes.label)}
          htmlFor={props.id ?? props.field.name}
        >
          {label}
        </label>
      )}
      <Select
        variant="outlined"
        inputProps={{
          className: clsx(
            "px-4 py-3 text-lg leading-5 font-bold border border-[#AAAAAA] rounded-lg h-auto min-h-0",
            classes.input,
            inputClassName
          ),
        }}
        formControl={{ className: "w-full" }}
        IconComponent={KeyboardArrowDownIcon}
        {...props}
        id={props.id ?? props.field.name}
      >
        {children}
      </Select>
    </div>
  );
}

export function SelfCheckboxBase({
  children,
  className,
  ...field
}: React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>) {
  return (
    <label htmlFor={field.id ?? `${field.name}-${field.value}`}>
      <input
        type="checkbox"
        className="peer hidden"
        {...field}
        id={field.id ?? `${field.name}-${field.value}`}
      />
      <div
        className={clsx(
          "py-2 text-center text-sm leading-4 md:text-base border border-gray-300 text-[#757575] rounded-sm cursor-pointer peer-checked:font-medium peer-checked:border-primary peer-checked:bg-[#F6FBFF] peer-checked:text-primary outline-1 outline-primary peer-focus-visible:outline-dashed select-none",
          className
        )}
      >
        {children ?? field.value}
      </div>
    </label>
  );
}

type SelfCheckboxProps = PropsWithChildren<
  Pick<
    FieldHookConfig<string>,
    "name" | "value" | "className" | "id" | "validate"
  >
>;

export function SelfCheckbox({
  children,
  className,
  ...fieldProps
}: SelfCheckboxProps) {
  const [field] = useField<string>({ ...fieldProps, type: "checkbox" });
  return (
    <SelfCheckboxBase className={className} {...field}>
      {children}
    </SelfCheckboxBase>
  );
}

type SelfCheckboxSetProps = {
  name: string;
  label: ReactNode;
  labelClassName?: string;
  options: { value: string; label?: string; id?: string }[];
};

export function SelfCheckboxSet({
  name,
  label,
  labelClassName,
  options,
}: SelfCheckboxSetProps) {
  const { classes } = useStyles();

  return (
    <fieldset className="w-full">
      <legend
        className={clsx(
          "block font-medium mb-2",
          classes.label,
          labelClassName
        )}
      >
        {label}
      </legend>
      <div
        className="grid gap-1 items-center w-full"
        style={{
          gridTemplateColumns: `repeat(${options.length}, 1fr)`,
        }}
      >
        {options.map((option) => (
          <SelfCheckbox key={option.value} name={name} value={option.value}>
            {option.label}
          </SelfCheckbox>
        ))}
      </div>
    </fieldset>
  );
}
