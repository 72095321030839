import { SpotConnect } from "@/icons/SpotConnect";

export function useIntegrationTypeIcon() {
  return SpotConnect;
}

export function IntegrationTypeIcon() {
  const Icon = useIntegrationTypeIcon();

  return <Icon className="w-8 h-8" />;
}
