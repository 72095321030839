import { datadogLogs } from "@datadog/browser-logs";
import {
  useConnectionState,
  useRemoteParticipants,
  useRoomContext,
} from "@livekit/components-react";
import { useAtom, useSetAtom } from "jotai";
import {
  ConnectionState,
  DataPacket_Kind,
  LocalParticipant,
  Participant,
  Room,
} from "livekit-client";
import { pick } from "lodash/fp";
import { useEffect } from "react";
import { useInterval } from "react-use";

import { livekitApplianceParticipantState } from "@/components/Player/WebRTCPlayer/hooks/useLivekitApplianceParticipant";
import { livekitDataChannelState } from "@/components/Player/WebRTCPlayer/hooks/useLivekitDataChannel";
import { livekitRoomState } from "@/components/Player/WebRTCPlayer/hooks/useLivekitRoom";
import useSourceStatus from "@/components/Player/WebRTCPlayer/hooks/useSourceStatus";
import {
  PlayerMachineEvent,
  usePlayerService,
} from "@/components/Player/playerMachine";

import getParticipant from "../utils/getParticipant";
import { livekitVoipParticipantState } from "./useLivekitVoipParticipant";

export function useLivekitConnection(
  applianceSerial: string,
  signalingURL: string,
  camId: string,
  rtcId: string
) {
  const { send } = usePlayerService();

  const setDataChannel = useSetAtom(
    livekitDataChannelState(signalingURL.split("&")[0])
  );

  const setRoom = useSetAtom(livekitRoomState(signalingURL.split("&")[0]));
  const [applianceParticipant, setApplianceParticipant] = useAtom(
    livekitApplianceParticipantState(signalingURL.split("&")[0])
  );
  const [, setVoipParticipant] = useAtom(
    livekitVoipParticipantState(signalingURL.split("&")[0])
  );

  const metricsLogger = datadogLogs.createLogger("webRTC Logger", {
    level: "info",
    context: {
      connectionId: rtcId,
      host: applianceSerial,
      camId: `${applianceSerial}--${camId}`,
      scope: "webRTC Connection",
      startTime: Date.now(),
    },
  });

  const participants = useRemoteParticipants();
  const room = useRoomContext();
  const connectionState = useConnectionState(room);

  useSourceStatus(metricsLogger);

  useEffect(() => {
    setRoom(room ?? null);
    if (!room) return;

    room.on("connected", () => {
      console.log(`connected to ${room.sid} as ${room.localParticipant.sid}`);
    });
  }, [room, setRoom]);

  useEffect(() => {
    if (!room) {
      setApplianceParticipant(null);
      setDataChannel(null);
      return;
    }

    const applianceParticipant = getApplianceParticipant(participants);
    if (!applianceParticipant) {
      return;
    }

    setApplianceParticipant(applianceParticipant);
    setDataChannel(
      createLivekitDataChannelSender(
        room.localParticipant,
        room,
        applianceParticipant.sid
      )
    );
  }, [room, participants, setDataChannel, setApplianceParticipant]);

  useEffect(() => {
    if (!room) {
      setVoipParticipant(null);
      return;
    }

    const voipParticipant = getVoipParticipant(participants);
    if (!voipParticipant) {
      return;
    }

    setVoipParticipant(voipParticipant);
  }, [room, participants, setVoipParticipant]);

  useEffect(() => {
    send({
      type: PlayerMachineEvent.SOURCES_CHANGED,
      sources: {
        webRTC: signalingURL,
        tunnel: "tunnel",
        local: "local",
      },
    });
  }, [camId, rtcId, signalingURL, send]);

  useEffect(() => {
    return () => {
      if (!room || room.state !== "connected") return;
      room?.disconnect();
    };
  }, [room]);

  useInterval(async () => {
    if (
      !room ||
      connectionState !== ConnectionState.Connected ||
      !applianceParticipant
    )
      return;

    const stats = await room.engine.subscriber?.pc.getStats();
    let allReports: any[] = [];

    // Store all reports in an array
    stats?.forEach((report: any) => {
      allReports.push(report);
    });

    // Variables for candidate pair info
    let localCandidateId: string | undefined;
    let remoteCandidateId: string | undefined;
    let currentRoundTripTime: number | undefined;
    let localConnectionType: string | undefined;
    let remoteConnectionType: string | undefined;

    // Access the candidate-pair report with 'succeeded' state
    for (const report of allReports) {
      if (report.type === "candidate-pair" && report.state === "succeeded") {
        localCandidateId= report.localCandidateId;
        remoteCandidateId = report.remoteCandidateId;
        currentRoundTripTime = report.currentRoundTripTime; // Store the round trip time
        break; // Stop the loop once the candidate-pair is found
      }
    }

    // Access the respective local and remote candidate reports
    if (localCandidateId && remoteCandidateId) {
      const localCandidateReport = allReports.find(r => r.type === "local-candidate" && r.id === localCandidateId);
      const remoteCandidateReport = allReports.find(r => r.type === "remote-candidate" && r.id === remoteCandidateId);

      // Get the connection types
      localConnectionType = localCandidateReport?.candidateType;
      remoteConnectionType = remoteCandidateReport?.candidateType;
    }

    let inboundRTPMetrics = {}
    for (const report of allReports) {
      if (report.type === "inbound-rtp" && report.mediaType === "video") {
        inboundRTPMetrics =  {
          ...pick(
            [
              "framesReceived",
              "framesDecoded",
              "nackCount",
              "pliCount",
              "packetsLost",
              "packetsReceived",
              "timestamp",
              "jitterBufferDelay",
              "jitter",
              "fractionLost",
              "bytesReceived",
              "jitterBufferEmittedCount",
              "framesPerSecond",
              "frameWidth",
              "frameHeight",
            ],
            report
          ),
        }
      }
    }
    //Call the metrics logger here
    metricsLogger.log("stats", {
      "localCandidateId" : localCandidateId,
      "remoteCandidateId" : remoteCandidateId,
      "localConnectionType" : localConnectionType,
      "remoteConnectionType" : remoteConnectionType,
      "currentRoundTripTime" : currentRoundTripTime,
      ...inboundRTPMetrics
    })
  }, 10000);

  return {
    metricsLogger,
  };
}

const getApplianceParticipant = (participants: Participant[]) => {
  return getParticipant(participants, "SPOT_SFU");
};

const getVoipParticipant = (participants: Participant[]) => {
  return getParticipant(participants, "VOIP");
};

const createLivekitDataChannelSender = (
  participant: LocalParticipant,
  room: Room,
  destination: string
) => {
  const encoder = new TextEncoder();
  const decoder = new TextDecoder();

  const outstanding: {
    resolve: (
      value: MessageEvent<string> | PromiseLike<MessageEvent<string>>
    ) => void;
    reject: (reason?: any) => void;
  }[] = [];

  room.on("dataReceived", (payload, sender) => {
    if (sender?.sid !== destination) {
      console.warn(`unexpected data from ${sender?.sid}`);
      return;
    }
    const received = decoder.decode(payload);
    const first = outstanding.shift();
    const messageEvent = new MessageEvent<string>("message", {
      data: received,
    });
    first?.resolve(messageEvent);
  });

  return {
    connected: participant.dataChannelsInfo().length > 0, // make sure the channels exist
    send: async (msg: string) => {
      const req = new Promise<MessageEvent<string>>((resolve, reject) => {
        outstanding.push({ resolve, reject });
        participant.publishData(encoder.encode(msg), DataPacket_Kind.RELIABLE, {
          destination: [destination],
        });
      });
      const timeout = 5 * 1000;
      let timer: NodeJS.Timer;
      return Promise.race([
        req,
        new Promise<MessageEvent<string>>((_, reject) => {
          timer = setTimeout(() => {
            // dc.close(); // close since we're now possibly out-of-sync with the peer
            reject("datachannel timeout");
          }, timeout);
        }),
      ]).finally(() => clearTimeout(timer));
    },
  };
};
