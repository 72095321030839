import { Container } from "@mui/material";
import clsx from "clsx";
import { Navigate, Route, Routes } from "react-router-dom";

import { useBreakpoints } from "@/util/useBreakpoints";

import { MaintainDetailsPage } from "@/pages/Maintain/Details/MaintainDetailsPage";
import { MaintainDevices } from "@/pages/Maintain/MaintainDevices";

import { useDataGridSelection } from "@/components/DataGrid/hooks";

import { ContentWrapper } from "@/layout/ContentWrapper";
import { MobileHeader } from "@/layout/MobileHeader";

export function MaintainPage() {
  const { fitsDesktop } = useBreakpoints();
  const { editing } = useDataGridSelection();

  return (
    <ContentWrapper>
      <Container
        className={clsx({ "pr-[300px]": editing })}
        maxWidth="xl"
        style={fitsDesktop ? undefined : { padding: 0 }}
      >
        <MobileHeader label="Maintain" />
        <Routes>
          <Route index element={<Navigate to="cameras" replace />} />
          <Route path="cameras/:id" element={<MaintainDetailsPage />} />
          <Route path="*" element={<MaintainDevices />} />
        </Routes>
      </Container>
    </ContentWrapper>
  );
}
