import { ApolloError, gql } from "@apollo/client";
import { addHours, startOfHour, subDays } from "date-fns/fp";
import { keyBy } from "lodash/fp";
import { useMemo } from "react";
import {
  ArrayParam,
  BooleanParam,
  StringParam,
  useQueryParam,
  useQueryParams,
} from "use-query-params";

import { getCameraSpecs } from "@/util/validation/camera";
import {
  getStreamMetadataSpecs,
  getStreamSettings,
} from "@/util/validation/stream";

import { CAMERA_STATUS_OUT_OF_SPEC } from "@/pages/Maintain/constants";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  CameraSettings,
  CameraStatus,
  DefaultCameraSettingsFragmentDoc,
  DeviceStreamMetadataFragmentDoc,
  DeviceStreamSettingsFragmentDoc,
  useGetMaintainCamerasStreamSettingsQuery,
  usePage_MaintainQuery,
} from "@/generated-models";

import { expandCamerasForStreams, ExpandedCameras } from "./utils";

export function useTimeRangeParams(now: Date) {
  const [timeRangeParams, setTimeRangeParams] = useQueryParams({
    start: StringParam,
    end: StringParam,
  });

  function setDatePicker(from: Date, to: Date) {
    setTimeRangeParams({
      start: from.toISOString(),
      end: to.getTime() > from.getTime() ? to.toISOString() : now.toISOString(),
    });
  }

  const thirtyDaysAgo = startOfHour(addHours(1)(subDays(30)(now)));
  const to = startOfHour(now);

  const { start: startParam, end: endParam } = timeRangeParams;
  const fromQuery = startParam ? new Date(startParam) : thirtyDaysAgo;
  const toQuery = endParam ? new Date(endParam) : to;

  return {
    fromQuery,
    toQuery,
    timeRangeParams,
    setTimeRangeParams,
    setDatePicker,
  };
}

// Return cameras and appliances needed to render page
export function useMaintainDevices() {
  const { data, loading, error } = usePage_MaintainQuery(refetchOnMountPolicy);
  // Load camera stream settings in parallel to avoid initial page loading delay.
  const {
    data: streamData,
    loading: streamLoading,
  } = useGetMaintainCamerasStreamSettingsQuery(refetchOnMountPolicy);

  const { cameras, appliances } = useMemo(() => {
    const appliances = data?.appliances;
    const cameras = data?.cameras;
    const appliancesMap = keyBy("id", appliances);
    const cameraStreamsMap = keyBy("id", streamData?.cameras);

    return {
      cameras: cameras?.map((cam) => {
        const settings = getStreamSettings({
          metadata: cam.stream.metadata,
          settings: cameraStreamsMap[cam.id]?.stream.settings,
        });
        return {
          ...cam,
          appliance: appliancesMap[cam.applianceId],
          stream: {
            ...cam.stream,
            metadata: { ...cam.stream.metadata, ...settings },
          },
        };
      }),
      appliances,
    };
  }, [data, streamData]);

  return {
    loading,
    streamLoading,
    error,
    cameras,
    appliances,
  };
}

type MaintainDevicesType = ReturnType<typeof useMaintainDevices>;
export type MaintainCamera = NonNullable<
  MaintainDevicesType["cameras"]
>[number];
export type MaintainAppliance = NonNullable<
  MaintainDevicesType["appliances"]
>[number];

export function useClearMaintainFilters() {
  const [, setOnlineFiltered] = useQueryParam(
    CameraStatus.Online,
    BooleanParam
  );
  const [, setOfflineFiltered] = useQueryParam(
    CameraStatus.Offline,
    BooleanParam
  );
  const [, setOutOfSpecFiltered] = useQueryParam(
    CAMERA_STATUS_OUT_OF_SPEC,
    BooleanParam
  );
  const [, setSearchInputParam] = useQueryParam("search", StringParam);
  const [, setGroup] = useQueryParam("group", ArrayParam);

  return () => {
    setOnlineFiltered(undefined);
    setOfflineFiltered(undefined);
    setOutOfSpecFiltered(undefined);
    setSearchInputParam(undefined);
    setGroup(undefined);
  };
}

// Return a filtered set of devices based on the current filter/search queries.
export function useMaintainFilteredDevices() {
  const query = useMaintainDevices();
  const [onlineFiltered] = useQueryParam(CameraStatus.Online, BooleanParam);
  const [offlineFiltered] = useQueryParam(CameraStatus.Offline, BooleanParam);

  const [aiFeaturesFilter] = useQueryParam("aiFeatures", ArrayParam);
  const [cameraFeatures] = useQueryParam("cameraFeatures", ArrayParam);

  const featuresFilter = useMemo(() => {
    const tempArray = [];
    if (aiFeaturesFilter && aiFeaturesFilter.length > 0) {
      tempArray.push(...aiFeaturesFilter);
    }
    if (cameraFeatures && cameraFeatures.length > 0) {
      tempArray.push(...cameraFeatures);
    }
    return tempArray;
  }, [aiFeaturesFilter, cameraFeatures]);

  const disabledFeaturesFilter = featuresFilter
    ?.filter((ff) => ff?.includes("--disabled"))
    .map((ff) => ff?.split("--")[0]);

  const enabledFeaturesFilter = featuresFilter
    ?.filter((ff) => ff?.includes("--enabled"))
    .map((ff) => ff?.split("--")[0]);

  const [outOfSpecFiltered] = useQueryParam(
    CAMERA_STATUS_OUT_OF_SPEC,
    BooleanParam
  );
  const [searchInputParam] = useQueryParam("search", StringParam);
  const [groupFilter] = useQueryParam("group", ArrayParam);
  const searchQuery = searchInputParam?.toLowerCase();

  let { cameras, appliances } = query;

  const filteredCameras = useMemo(() => {
    const expandedCameras = expandCamerasForStreams(cameras as ExpandedCameras);
    const filteredCameras = expandedCameras?.filter((cam) => {
      if (groupFilter && !groupFilter.includes(cam.appliance.location.name)) {
        return false;
      }
      // If we have feature filters enabled, check to see if we are filtering for any
      // feature and state.
      if (
        (!!enabledFeaturesFilter && enabledFeaturesFilter.length > 0) ||
        (!!disabledFeaturesFilter && disabledFeaturesFilter.length > 0)
      ) {
        const hasFeature = enabledFeaturesFilter?.some((f) => {
          if (f && !!cam.settings[f as keyof CameraSettings]) {
            return true;
          }

          return false;
        });

        const hasDisabledFeature = disabledFeaturesFilter?.some((f) => {
          if (f && !cam.settings[f as keyof CameraSettings]) {
            return true;
          }

          return false;
        });

        if (!hasFeature && !hasDisabledFeature) {
          return false;
        }
      }
      if (onlineFiltered && cam.status !== CameraStatus.Online) {
        return false;
      }
      if (offlineFiltered && cam.status !== CameraStatus.Offline) {
        return false;
      }
      if (outOfSpecFiltered && !isCameraOutOfSpec(cam)) {
        return false;
      }
      return !searchQuery || cam.name.toLowerCase().includes(searchQuery);
    });

    // Return filtered cameras along with fisheye camera streams
    return filteredCameras;
  }, [
    cameras,
    groupFilter,
    enabledFeaturesFilter,
    disabledFeaturesFilter,
    onlineFiltered,
    offlineFiltered,
    outOfSpecFiltered,
    searchQuery,
  ]);

  const filteredAppliances = useMemo(() => {
    return appliances?.filter((app) => {
      if (groupFilter && !groupFilter.includes(app.location.name)) {
        return false;
      }
      if (onlineFiltered && !app?.health?.online) {
        return false;
      }
      if (offlineFiltered && app?.health?.online === true) {
        return false;
      }
      return (
        !searchQuery || app.serialNumber.toLowerCase().includes(searchQuery)
      );
    });
  }, [appliances, offlineFiltered, onlineFiltered, searchQuery, groupFilter]);

  return { ...query, filteredCameras, filteredAppliances };
}

export type DeviceStatistics = {
  total: number;
  online: number;
  offline: number;
  outOfSpec?: number;
};

// Return a summarized collection of device statistics.
export function useMaintainDeviceStatistics(
  filterGroup = false
): {
  loading: boolean;
  error?: ApolloError;
  cameras: DeviceStatistics;
  appliances: DeviceStatistics;
} {
  const [groupFilter] = useQueryParam("group", ArrayParam);
  const {
    cameras = [],
    appliances = [],
    loading,
    streamLoading,
    error,
  } = useMaintainDevices();

  const outOfSpecCameras = useMemo(() => {
    return cameras.filter(
      (dev) =>
        isCameraOutOfSpec(dev) &&
        deviceIsInGroup(dev.appliance.location, groupFilter, filterGroup)
    ).length;
  }, [cameras, filterGroup, groupFilter]);

  const onlineCameras = useMemo(() => {
    return cameras.filter(
      (dev) =>
        dev.status === CameraStatus.Online &&
        deviceIsInGroup(dev.appliance.location, groupFilter, filterGroup)
    ).length;
  }, [cameras, filterGroup, groupFilter]);

  const offlineCameras = useMemo(() => {
    return cameras.filter(
      (dev) =>
        dev.status === CameraStatus.Offline &&
        deviceIsInGroup(dev.appliance.location, groupFilter, filterGroup)
    ).length;
  }, [cameras, filterGroup, groupFilter]);

  const onlineAppliances = useMemo(() => {
    return appliances.filter(
      (dev) =>
        dev.health.online &&
        deviceIsInGroup(dev.location, groupFilter, filterGroup)
    ).length;
  }, [appliances, filterGroup, groupFilter]);

  const offlineAppliances = useMemo(() => {
    return appliances.filter(
      (dev) =>
        !dev.health.online &&
        deviceIsInGroup(dev.location, groupFilter, filterGroup)
    ).length;
  }, [appliances, filterGroup, groupFilter]);

  return {
    loading,
    error,
    cameras: {
      total: cameras.length,
      online: onlineCameras,
      offline: offlineCameras,
      // Only show out of spec if the stream data is loaded.
      outOfSpec: streamLoading ? undefined : outOfSpecCameras,
    },
    appliances: {
      total: appliances.length,
      online: onlineAppliances,
      offline: offlineAppliances,
    },
  };
}

function isCameraOutOfSpec(cam: MaintainCamera) {
  const { stream, appliance } = cam;
  const { defaultCameraSettings } = appliance;

  const streamErrors = getStreamMetadataSpecs(
    stream,
    defaultCameraSettings
  ).filter((s) => s.error);
  const cameraErrors = getCameraSpecs(cam).filter((s) => s.error);

  return [...streamErrors, ...cameraErrors].length > 0;
}

// Determines if the device is in the specified group (or if group filtering is not applicable).
function deviceIsInGroup(
  location: { name: string },
  groupFilter?: (string | null)[] | null,
  applyFilter = true
) {
  return (
    !applyFilter ||
    !groupFilter ||
    (groupFilter && applyFilter && groupFilter.includes(location.name))
  );
}

gql`
  query page_maintain {
    appliances {
      id
      serialNumber
      version
      deviceAiMode
      health {
        online
      }
      license {
        sku
      }
      location {
        id
        name
      }
      defaultCameraSettings {
        ...DefaultCameraSettings
      }
    }
    cameras {
      id
      name
      status
      vendor
      applianceId
      device {
        id
        ip
        isNvr
        mac
        isFisheye
        channels(filterChannelsWithCamera: false) {
          streams {
            id
          }
        }
      }
      settings {
        lprEnabled
        modelForkliftEnabled
        audioControlEnabled
        onvifBackchannelEnabled
        attributesEnabled
        faceRecognitionEnabled
      }
      stream {
        id
        metadata {
          ...DeviceStreamMetadata
        }
      }
      metrics {
        bitrate
      }
    }
  }
  ${DefaultCameraSettingsFragmentDoc}
  ${DeviceStreamMetadataFragmentDoc}
`;

gql`
  query getMaintainCamerasStreamSettings {
    cameras {
      id
      stream {
        id
        settings {
          ...DeviceStreamSettings
        }
      }
    }
  }
  ${DeviceStreamSettingsFragmentDoc}
`;
