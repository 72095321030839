import { Typography } from "@mui/material";
import clsx from "clsx";
import { Fragment } from "react";
import { StringParam, useQueryParam } from "use-query-params";

import { pluralize } from "@/util/pluralize";
import { useBreakpoints } from "@/util/useBreakpoints";

import { ErrorMessage } from "@/components/ErrorMessage";

import { IntLoadingIndicator } from "../IntLoadingIndicator";
import { LicensePlateTile } from "./LicensePlateTile";
import { useGroupedLprSearch } from "./hooks";

export function LicensePlateSearchResultGrid() {
  const { fitsDesktop } = useBreakpoints();
  const [viewing] = useQueryParam("viewing", StringParam);
  const { data, loading, error } = useGroupedLprSearch();

  if (error) {
    return (
      <ErrorMessage
        title="Oops!"
        description="Failed to load license plate results. Please try again."
      />
    );
  }

  if (loading) {
    return (
      <IntLoadingIndicator
        className={clsx("flex justify-center items-center py-20 w-full", {
          "text-center": viewing && fitsDesktop,
        })}
      />
    );
  }

  return (
    <div>
      <div className="flex flex-col gap-4 justify-center">
        <div className="grid gap-3 md:gap-4 grid-cols-[repeat(auto-fit,minmax(150px,_1fr))] md:grid-cols-[repeat(auto-fit,minmax(220px,_1fr))]">
          {data?.map((item) => (
            <Fragment key={item.label}>
              <Typography
                variant="h1"
                className="text-lg leading-[21px] col-[1_/_-1]"
              >
                <strong>{item.label}</strong> - {item.results.length}{" "}
                {pluralize(
                  { 1: "Result", multi: "Results" },
                  item.results.length
                )}
              </Typography>
              {item.results.map((result) => (
                <LicensePlateTile key={result.id} data={result} />
              ))}
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}
