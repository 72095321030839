import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import gql from "graphql-tag";

import { validateName } from "@/pages/VideoWall/constants";

import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { ZendeskArticle, ZendeskLink } from "@/components/Zendesk/ZendeskLink";

import {
  Page_VideoWallsDocument,
  Page_VideoWallsQuery,
  useCreateRotatingWallMutation,
  useCreateWallMutation,
  usePage_VideoWallsQuery,
} from "@/generated-models";

interface CreateVideoWallPopperProps {
  anchor: HTMLElement | null;
  setAnchor: (anchor: HTMLElement | null) => void;
  editWall: (id: number, rotating: boolean) => void;
}

export function CreateVideoWallPopper({
  anchor,
  setAnchor,
  editWall,
}: CreateVideoWallPopperProps) {
  const [createWall, { loading: creatingVideoWall }] = useCreateWallMutation();
  const [
    createRotatingWall,
    { loading: creatignRotatingWall },
  ] = useCreateRotatingWallMutation();
  const feedback = useFeedback();
  const close = () => setAnchor(null);
  const { data } = usePage_VideoWallsQuery();
  const names = data?.videoWalls.map((v) => v.name) ?? [];

  return (
    <Dialog
      open={!!anchor}
      onClose={() => setAnchor(null)}
      classes={{ paper: "w-auto" }}
    >
      <Paper elevation={5} style={{ width: 400, padding: "14px 16px 20px" }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <div style={{ fontSize: 18, fontWeight: "bold" }}>
            Create a New Wall
          </div>
          <IconButton aria-label="close" size="small" onClick={close}>
            <CloseIcon style={{ color: "#353d48" }} />
          </IconButton>
        </Grid>
        <Formik
          initialValues={{
            name: "",
            rotating: false,
          }}
          onSubmit={async ({ name, rotating }, { setSubmitting }) => {
            if (rotating) {
              const { data } = await createRotatingWall({
                variables: { name },
                update: (store, { data }) => {
                  if (!data) throw new Error("Failed to create wall");
                  const res = store.readQuery<Page_VideoWallsQuery>({
                    query: Page_VideoWallsDocument,
                  });
                  if (!res) return;
                  const { videoWalls, rotatingVideoWalls } = res;
                  store.writeQuery<Page_VideoWallsQuery>({
                    query: Page_VideoWallsDocument,
                    data: {
                      __typename: "Query",
                      videoWalls,
                      rotatingVideoWalls: [
                        ...rotatingVideoWalls,
                        data.createRotatingVideoWall,
                      ] as any,
                    },
                  });
                },
              });
              if (data) {
                editWall(data.createRotatingVideoWall.id, true);
              }
            } else {
              const { data } = await createWall({
                variables: { name },
                update: (store, { data }) => {
                  if (!data) throw new Error("Failed to create wall");
                  const res = store.readQuery<Page_VideoWallsQuery>({
                    query: Page_VideoWallsDocument,
                  });
                  if (!res) return;
                  const { videoWalls, rotatingVideoWalls } = res;
                  store.writeQuery<Page_VideoWallsQuery>({
                    query: Page_VideoWallsDocument,
                    data: {
                      __typename: "Query",
                      videoWalls: [...videoWalls, data.createVideoWall] as any,
                      rotatingVideoWalls,
                    },
                  });
                },
              });
              if (data) {
                editWall(data.createVideoWall.id, false);
              }
            }
            setSubmitting(false);
            close();
            feedback.pushSnackbar(
              "New Video Wall created",
              FeedbackType.Success
            );
          }}
          validateOnBlur={false}
          validateOnChange={false}
          render={({ submitForm, values, setFieldValue }) => (
            <Form>
              <Box m={1} />
              <ButtonGroup variant="outlined" color="primary" fullWidth>
                <Button
                  variant={values.rotating ? undefined : "contained"}
                  onClick={() => setFieldValue("rotating", false)}
                >
                  Video Wall
                </Button>
                <Button
                  variant={values.rotating ? "contained" : undefined}
                  onClick={() => setFieldValue("rotating", true)}
                >
                  Rotating Video Wall Set
                </Button>
              </ButtonGroup>
              <Box mx={3} my={2}>
                <Typography style={{ textAlign: "center" }}>
                  {values.rotating
                    ? "Create a rotating video wall set to view and cycle through multiple video walls."
                    : "Create a video wall to watch up to nine cameras at once."}
                  <ZendeskLink
                    article={
                      values.rotating
                        ? ZendeskArticle.ROTATING_VIDEO_WALL
                        : ZendeskArticle.VIDEO_WALL_CUSTOMIZE
                    }
                  />
                </Typography>
              </Box>
              <Field
                name="name"
                label="Wall Name"
                fullWidth
                validate={validateName(names)}
                component={TextField}
                autoFocus
              />
              <Box m={2} />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                disabled={creatingVideoWall || creatignRotatingWall}
                onClick={submitForm}
              >
                {values.rotating ? "Create Rotating Wall" : "Create Wall"}
              </Button>
            </Form>
          )}
        />
      </Paper>
    </Dialog>
  );
}

gql`
  mutation createWall($name: String!) {
    createVideoWall(name: $name) {
      id
      name
      cameras {
        ... on Camera {
          id
        }
        ... on NotAllowed {
          id
        }
      }
      config {
        columns
        rows
        sizes
      }
    }
  }
`;

gql`
  mutation createRotatingWall($name: String!) {
    createRotatingVideoWall(name: $name) {
      id
      name
      pageDuration
      videoWalls {
        id
      }
    }
  }
`;
