import { useTheme } from "@mui/material";
import React from "react";

export function Logo(props: {
  className?: string;
  style?: React.CSSProperties;
}) {
  const { mode } = useTheme().palette;
  return (
    <img
      src={mode === "light" ? "/logo.svg" : "/logo-light.svg"}
      alt="Spot AI"
      {...props}
    />
  );
}
