import {
  Button,
  CircularProgress,
  Divider,
  OutlinedInput,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { useFlags } from "launchdarkly-react-client-sdk";
import groupBy from "lodash/groupBy";
import startCase from "lodash/startCase";
import { Fragment, useMemo, useState } from "react";

import { SpotConnect } from "@/icons/SpotConnect";
import { ReactComponent as IntegrationsLogo } from "@/icons/integrations.svg";
import { ReactComponent as IllustrationsIcon } from "@/icons/integrations_illustration.svg";

import { useBreakpoints } from "@/util/useBreakpoints";

import { ErrorMessage } from "@/components/ErrorMessage";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  IntegrationTypeKey,
  IntegrationVendorKey,
  IntegrationVendorsQuery,
  Page_IntegrationsQuery,
  Page_IntegrationsV2Query,
  useIntegrationVendorsQuery,
  usePage_IntegrationsQuery,
  usePage_IntegrationsV2Query,
  useSuggestIntegrationMutation,
} from "@/generated-models";

import { IntegrationTypeIcon } from "../../IntegrationsOld/hooks";
import { IntegrationCustomActiveVendorCard } from "../Connect/IntegrationCustomActiveVendorCard";
import { IntegrationCustomVendorCard } from "../Connect/IntegrationCustomVendorCard";
import { IntegrationActiveVendorCard } from "./IntegrationActiveVendorCard";
import { IntegrationVendorCard } from "./IntegrationVendorCard";

function IntegrationsOverviewHeader() {
  const { fitsDesktop } = useBreakpoints();
  return (
    <div className="flex flex-col md:flex-row items-center justify-start ml-4 md:mb-0 mb-6">
      <IllustrationsIcon />
      <div className="integration-overview-header my-6 flex flex-col gap-2 md:-ml-6 md:mt-0 -mt-6">
        <Typography className="text-[28px] md:text-[54px] leading-[30.8px] md:leading-[59px] font-bold flex items-center justify-start gap-2">
          <IntegrationsLogo height={fitsDesktop ? 20 : undefined} />
          Integrations
        </Typography>
        <Typography className="text-[32px] leading-[35px] font-light text-primary md:max-w-[unset] max-w-[310px]">
          Expand Spot AI by integrating into your existing systems.
        </Typography>
      </div>
    </div>
  );
}

function IntegrationOverviewSubheader({ label }: { label: string }) {
  return (
    <div className="flex flex-col md:px-0 px-3">
      <Typography className="text-2xl leading-[22px] font-light">
        <strong className="font-bold">{label}</strong> Integrations
      </Typography>
      <Divider className="my-3 md:my-5 border-[#AED1F0]" />
    </div>
  );
}

function IntegrationsOverviewFooter() {
  const { pushSnackbar } = useFeedback();
  const [suggestion, setSuggestion] = useState("");

  const [sendSuggestion] = useSuggestIntegrationMutation({
    onCompleted: () => {
      setSuggestion("");
      pushSnackbar("Suggestion sent to AI Copilot.", FeedbackType.Success);
    },
  });

  return (
    <div className="py-4 md:py-10 flex md:flex-row flex-col justify-between items-center gap-5 md:gap-10 bg-[#F3F9FF] rounded-2xl border border-solid border-[#DDEFFF] px-3 md:px-11 md:mb-0 mb-6 mx-3 md:mx-0">
      <Typography className="text-primary font-light text-[26px] leading-[28.6px] md:text-start text-center">
        We are rapidly adding more integrations.
      </Typography>
      <div className="flex md:flex-row flex-col items-center justify-start flex-1 md:gap-0 gap-[9px] w-full md:w-[unset]">
        <OutlinedInput
          inputProps={{ className: "py-[7px]" }}
          className="flex-1 rounded-l-lg md:rounded-r-none rounded-r-lg w-full"
          size="small"
          placeholder="Enter Suggestion"
          value={suggestion}
          onChange={(e) => {
            setSuggestion(e.target.value);
          }}
        />
        <Button
          variant="contained"
          color="primary"
          className="rounded-r-lg shadow-none md:rounded-l-none rounded-l-lg w-full md:w-[unset]"
          disabled={!suggestion}
          onClick={() => {
            sendSuggestion({
              variables: {
                input: {
                  suggestion,
                },
              },
            });
          }}
        >
          Suggest Integration
        </Button>
      </div>
    </div>
  );
}

function IntegrationsOverviewAvailableSection() {
  const { data, loading, error } = useIntegrationVendorsQuery();
  const {
    brivoIntegration,
    ioBoard,
    intercomIntegration,
    spotConnect,
  } = useFlags();

  const grouped = useMemo(() => {
    const filteredKeys: IntegrationVendorKey[] = [];
    if (!brivoIntegration) filteredKeys.push(IntegrationVendorKey.Brivo);
    if (!ioBoard) filteredKeys.push(IntegrationVendorKey.Spot);
    if (!intercomIntegration) {
      filteredKeys.push(IntegrationVendorKey.SpotIntercom);
    }

    const IntegrationVendors = data?.integrationVendors.filter(
      (inte) => !filteredKeys.includes(inte.key)
    );

    return groupBy(IntegrationVendors || [], (vendors) => vendors.type.key);
  }, [
    brivoIntegration,
    data?.integrationVendors,
    ioBoard,
    intercomIntegration,
  ]);

  if (error) {
    return <ErrorMessage title="Error" description={error.toString()} />;
  }

  return (
    <div>
      <IntegrationOverviewSubheader label="Available" />
      {loading && (
        <div className="flex items-center justify-center w-full py-10">
          <CircularProgress size="2.5rem" />
        </div>
      )}
      {!loading && (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10 md:px-0 px-3">
          {Object.entries(grouped)
            .filter((a) => a[0] !== IntegrationTypeKey.IoBoard)
            .sort((a, b) => a[0].localeCompare(b[0]))
            .map(([typeKey, configs]) => (
              <IntegrationsOverviewSectionItem
                key={typeKey}
                typeKey={typeKey as IntegrationTypeKey}
                configs={configs}
              />
            ))}
          {spotConnect && (
            <>
              <div className="md:col-span-3 sm:col-span-2 col-span-1 flex items-center justify-start gap-1 -mb-5">
                <SpotConnect className="w-8 h-8 " />
                <Typography className="font-light text-lg leading-[16px] md:text-xl md:leading-[20px]">
                  Spot Connect
                </Typography>
              </div>
              <IntegrationCustomVendorCard />
            </>
          )}
          {grouped[IntegrationTypeKey.IoBoard] && (
            <IntegrationsOverviewSectionItem
              key={IntegrationTypeKey.IoBoard}
              typeKey={IntegrationTypeKey.IoBoard}
              configs={grouped[IntegrationTypeKey.IoBoard]}
            />
          )}
        </div>
      )}
    </div>
  );
}

function IntegrationsOverviewSectionItem({
  typeKey,
  configs,
}: {
  typeKey: IntegrationTypeKey;
  configs: IntegrationVendorsQuery["integrationVendors"];
}) {
  return (
    <>
      <div className="md:col-span-3 sm:col-span-2 col-span-1 flex items-center justify-start gap-1 -mb-5">
        <IntegrationTypeIcon typeId={typeKey as IntegrationTypeKey} />
        <Typography className="font-light text-lg leading-[16px] md:text-xl md:leading-[20px]">
          {configs[0]?.type?.name || startCase(typeKey)}
        </Typography>
      </div>
      {configs.map((vendor) => (
        <IntegrationVendorCard key={vendor.name} vendor={vendor} />
      ))}
    </>
  );
}

function IntegrationsOverviewActiveSection() {
  const {
    brivoIntegration,
    ioBoard,
    spotConnect,
    intercomIntegration,
  } = useFlags();
  const { data, error } = usePage_IntegrationsQuery(refetchOnMountPolicy);
  const { data: dataV2, error: errorV2 } = usePage_IntegrationsV2Query(
    refetchOnMountPolicy
  );

  const resolvedError = error || errorV2;

  const grouped = useMemo(() => {
    const filteredKeys: IntegrationVendorKey[] = [];
    if (!brivoIntegration) filteredKeys.push(IntegrationVendorKey.Brivo);
    if (!ioBoard) filteredKeys.push(IntegrationVendorKey.Spot);
    if (!intercomIntegration) {
      filteredKeys.push(IntegrationVendorKey.SpotIntercom);
    }

    const integrations = data?.integrations.filter(
      (inte) => !filteredKeys.includes(inte.vendorKey)
    );

    return groupBy(
      integrations ?? [],
      (integration) => integration.vendor.type.key
    );
  }, [brivoIntegration, ioBoard, intercomIntegration, data?.integrations]);

  if (resolvedError) {
    return (
      <ErrorMessage title="Error" description={resolvedError.toString()} />
    );
  }

  if (!data || !dataV2) {
    return (
      <div className="flex items-center justify-center w-full py-10">
        <CircularProgress size="2.5rem" />
      </div>
    );
  }

  if (Object.keys(grouped).length === 0 && dataV2.integrationsV2.length === 0) {
    return <></>;
  }

  return (
    <div className="-mt-16">
      <IntegrationOverviewSubheader label="Active" />
      {Object.keys(grouped).length > 0 && (
        <>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10 md:px-0 px-3">
            {Object.entries(grouped)
              .filter((a) => a[0] !== IntegrationTypeKey.IoBoard)
              .sort((a, b) => a[0].localeCompare(b[0]))
              .map(([typeKey, integrations]) => (
                <IntegrationActiveSectionItem
                  key={typeKey}
                  typeKey={typeKey as IntegrationTypeKey}
                  integrations={integrations}
                />
              ))}
            {grouped[IntegrationTypeKey.IoBoard] && (
              <IntegrationActiveSectionItem
                key={IntegrationTypeKey.IoBoard}
                typeKey={IntegrationTypeKey.IoBoard}
                integrations={grouped[IntegrationTypeKey.IoBoard]}
              />
            )}
          </div>
        </>
      )}

      {spotConnect && dataV2.integrationsV2.length > 0 && (
        <div
          className={clsx(
            "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10 md:px-0 px-3",
            {
              "mt-10": Object.keys(grouped).length > 0,
            }
          )}
        >
          <IntegrationCustomActiveSectionItem
            integrations={dataV2.integrationsV2}
          />
        </div>
      )}
    </div>
  );
}

function IntegrationActiveSectionItem({
  typeKey,
  integrations,
}: {
  typeKey: IntegrationTypeKey;
  integrations: Page_IntegrationsQuery["integrations"];
}) {
  return (
    <>
      <div className="md:col-span-3 sm:col-span-2 col-span-1 flex items-center justify-start gap-1 -mb-5">
        <IntegrationTypeIcon typeId={typeKey as IntegrationTypeKey} />
        <Typography className="font-light text-lg leading-[16px] md:text-xl md:leading-[20px]">
          {integrations[0]?.vendor?.type?.name || startCase(typeKey)}
        </Typography>
      </div>
      {integrations.map((integration) => (
        <IntegrationActiveVendorCard
          key={integration.id}
          integration={integration}
        />
      ))}
    </>
  );
}

function IntegrationCustomActiveSectionItem({
  integrations,
}: {
  integrations: Page_IntegrationsV2Query["integrationsV2"];
}) {
  return (
    <>
      <div className="md:col-span-3 sm:col-span-2 col-span-1 flex items-center justify-start gap-1 -mb-5">
        <SpotConnect className="w-8 h-8 " />
        <Typography className="font-light text-lg leading-[16px] md:text-xl md:leading-[20px]">
          Spot Connect
        </Typography>
      </div>
      {integrations.map((integration) => (
        <IntegrationCustomActiveVendorCard
          key={integration.id}
          integration={integration}
        />
      ))}
    </>
  );
}

export function IntegrationsOverviewPage() {
  return (
    <div className="flex flex-col gap-14 -mt-8">
      <IntegrationsOverviewHeader />
      <IntegrationsOverviewActiveSection />
      <IntegrationsOverviewAvailableSection />
      <IntegrationsOverviewFooter />
    </div>
  );
}
