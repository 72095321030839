import { DiffEditor } from "@monaco-editor/react";
import { CircularProgress, Typography } from "@mui/material";
import { useLocalStorage } from "react-use";

import { MaintainApplianceConfigHistorySelect } from "./MaintainApplianceConfigHistory";
import { useApplianceConfigHistory, useApplianceDiff } from "./hooks";

export function MaintainConfigDiffViewer() {
  const items = useApplianceConfigHistory();
  const [dark] = useLocalStorage("maintainEditDark", false);
  const { loading, original, modified } = useApplianceDiff();
  const currentItem = items[0];

  return (
    <div className="w-full h-[calc(100%-120px)]">
      {loading && (
        <div className="flex items-center justify-center w-full h-full">
          <CircularProgress className="text-white" />
        </div>
      )}
      {!loading && (
        <div className="w-full h-full">
          <div className="grid grid-cols-2 items-center gap-4 pb-2">
            <MaintainApplianceConfigHistorySelect />
            <div className="flex flex-col">
              <Typography className="font-mono text-center text-sm font-bold">
                Current
              </Typography>
              <Typography className="font-mono text-center text-[10px]">
                {currentItem.label}
              </Typography>
            </div>
          </div>
          <DiffEditor
            width="100%"
            height="100%"
            language="json"
            theme={dark ? "vs-dark" : "light"}
            original={original}
            modified={modified}
            options={{
              readOnly: true,
            }}
          />
        </div>
      )}
    </div>
  );
}
