import { Search } from "@mui/icons-material";
import { Hidden, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import { StringParam, useQueryParam } from "use-query-params";

import { SearchBox } from "@/components/SearchBox";

import { IntelligenceDashboardQuery } from "@/generated-models";

import { LicensePlateReportDetailsMobileBar } from "./LicensePlateReportDetailsMobileBar";
import { useLprReportGrouped } from "./hooks";

export function LicensePlateInterestListReportDetailsBar({
  dashboard,
}: {
  dashboard: IntelligenceDashboardQuery["intelligenceDashboard"];
}) {
  const [showSearch, setShowSearch] = useState(false);
  const [searchFilter, setSearchFilter] = useQueryParam("search", StringParam);
  const { data } = useLprReportGrouped();
  const plates = data?.lprReport || [];

  return (
    <div className="flex flex-col gap-3 justify-center px-0 sm:px-6 pt-2 sm:pt-6 pb-3 sm:pb-5">
      <Hidden smUp>
        <LicensePlateReportDetailsMobileBar dashboard={dashboard} />
      </Hidden>

      <div className="flex items-center justify-between">
        <Typography className="text-2xl leading-[28px] font-bold hidden sm:block">
          {plates.length} Vehicles
        </Typography>
        <Hidden smDown>
          <div className="flex justify-between items-center px-4 sm:px-0">
            {showSearch ? (
              <SearchBox
                input={searchFilter || ""}
                setInput={setSearchFilter}
                placeholder="Search Results"
              />
            ) : (
              <IconButton
                onClick={() => setShowSearch(true)}
                size="small"
                className="text-text"
              >
                <Search />
              </IconButton>
            )}
          </div>
        </Hidden>
      </div>
    </div>
  );
}
