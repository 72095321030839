import { Typography } from "@mui/material";

import { useMaintainMetricsDashboardType } from "../hooks";
import { MaintainMetricsLegendToolbar } from "./MaintainMetricsLegendToolbar";
import { MaintainMetricsSelectMenu } from "./MaintainMetricsSelectMenu";
import { MaintainMetricsTypeToggle } from "./MaintainMetricsTypeToggle";

export function MaintainMetricsToolbar() {
  const [type] = useMaintainMetricsDashboardType();

  return (
    <div className="flex flex-col gap-[18px] justify-center">
      <div className="flex flex-col sm:flex-row sm:gap-0 gap-1 items-center justify-between">
        <div className="flex flex-col sm:flex-row items-center justify-start gap-1">
          <MaintainMetricsTypeToggle />
          <MaintainMetricsSelectMenu />
        </div>
      </div>
      <Typography className="text-sm leading-[16.41px] max-w-2xl">
        <strong>
          Select {type}s to view their download and upload bandwidth
          consumption.
        </strong>
        You can use this view to help identify bandwidth issues that in turn
        affect Spot AI. Learn more.
      </Typography>
      <MaintainMetricsLegendToolbar />
    </div>
  );
}
