import { Container, Divider, GlobalStyles } from "@mui/material";
import { useAtomValue } from "jotai";
import { useRef } from "react";

import { useLocalStorage } from "@/util/useLocalStorage";

import { AssistantConversationHistory } from "./AssistantConversationHistory";
import { AssistantPageFooter } from "./AssistantPageFooter";
import { AssistantPageHeader } from "./AssistantPageHeader";
import { AssistantPromptInput } from "./AssistantPrompt/AssistantPromptInput";
import { AssistantPromptRegenerateButton } from "./AssistantPrompt/AssistantPromptRegenerateButton";
import { useBackground } from "./background";
import { conversationAtom } from "./hooks";

export function AssistantPage() {
  const sectionRef = useRef(null);
  const conversationHistory = useAtomValue(conversationAtom);
  const [bg, setBg] = useLocalStorage("assistant-bg", 0);

  const onBgChange = useBackground(sectionRef);

  return (
    <main
      ref={sectionRef}
      className="assistant-container w-full flex-1 flex md:px-0 px-2"
    >
      <GlobalStyles
        styles={{
          body: {
            background: "#1A2126 !important",
          },
        }}
      />
      <Container maxWidth="md" style={{ padding: 0 }}>
        <div className="flex flex-col items-center justify-center gap-8 py-32">
          <AssistantPageHeader />
          {conversationHistory.length > 0 && (
            <>
              <AssistantConversationHistory />
              <Divider className="border-[#252E35]/90 w-3/4" />
            </>
          )}
          <AssistantPromptInput />
          <AssistantPromptRegenerateButton />
          <AssistantPageFooter
            className="-mt-[30px]"
            selectedBackground={bg}
            onBackgroundChange={(newValue) => {
              onBgChange(newValue);
              setBg(newValue);
            }}
          />
        </div>
      </Container>
    </main>
  );
}
