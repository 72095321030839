import { Close, Mic, RadioButtonChecked } from "@mui/icons-material";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import clsx from "clsx";
import gql from "graphql-tag";
import { LocalTrack, Participant } from "livekit-client";
import { useEffect, useState } from "react";

import { useLivekitApplianceParticipant } from "@/components/Player/WebRTCPlayer/hooks/useLivekitApplianceParticipant";
import { useLivekitRoom } from "@/components/Player/WebRTCPlayer/hooks/useLivekitRoom";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";

import { useRecordBidiAudioActivityMutation } from "@/generated-models";

type Metadata = {
  backchannel: boolean;
  backchannelDetails?: {
    status: string;
    sid?: string;
  };
};

enum State {
  Idle,
  Connecting,
  Connected,
}

export function TwoWayAudio({
  cameraId,
  url,
}: {
  cameraId: number;
  url: string;
}) {
  const room = useLivekitRoom(url.split("&")[0]);
  const applianceParticipant = useLivekitApplianceParticipant(
    url.split("&")[0]
  );
  const feedback = useFeedback();
  const [recordActivity] = useRecordBidiAudioActivityMutation();

  const [activatedAt, setActivatedAt] = useState<Date | null>(null);
  const [micEnabled, setMicEnabled] = useState(false);
  const [connected, setConnected] = useState(State.Idle);
  const [micTrack, setMicTrack] = useState<LocalTrack | null>(null);
  const [connectedSid, setConnectedSid] = useState<string | null>(null);

  const [metadata] = useMetadata(applianceParticipant);
  const [backchannelAvailable] = useBackchannelAvailable(metadata);

  useEffect(() => {
    log(
      "connection changed",
      room?.localParticipant?.sid,
      applianceParticipant?.sid
    );
    setActivatedAt(null);
  }, [room, applianceParticipant]);

  useEffect(() => {
    if (!activatedAt || !room) {
      return;
    }

    if (micEnabled && connected === State.Idle) {
      log("connecting");
      setConnected(State.Connecting);
    }

    room.localParticipant
      .setMicrophoneEnabled(micEnabled, undefined, {
        stopMicTrackOnMute: true,
      })
      .then((t) => {
        setMicTrack(t?.track || null);
        log("mic", micEnabled ? "enabled" : "disabled", t?.trackSid);

        if (micEnabled) {
          recordActivity({
            variables: { id: cameraId, start: activatedAt.toISOString() },
          });
        }
      })
      .catch((e) => {
        console.error("failed to enable mic", e);
        feedback.pushSnackbar("Microphone error", FeedbackType.Error);
        setActivatedAt(null);
      });
  }, [
    room,
    activatedAt,
    micEnabled,
    feedback,
    connected,
    cameraId,
    recordActivity,
  ]);

  useEffect(() => {
    if (
      metadata?.backchannelDetails?.status === "connected" &&
      !!metadata.backchannelDetails.sid
    ) {
      log("connected to", metadata.backchannelDetails.sid);
      setConnectedSid(metadata.backchannelDetails.sid);
    } else {
      setConnectedSid(null);
    }
  }, [metadata?.backchannelDetails]);

  useEffect(() => {
    if (connected === State.Connecting && micTrack?.sid === connectedSid) {
      log("connected!");
      setConnected(State.Connected);
    }

    if (connected === State.Connected && micTrack?.sid !== connectedSid) {
      log("sid mismatch when connected");
      setActivatedAt(null);
    }
  }, [connected, micTrack, connectedSid]);

  useEffect(() => {
    if (activatedAt) {
      return;
    }

    setConnected(State.Idle);
    setMicEnabled(false);
    setMicTrack(null);

    if (!!micTrack) {
      room?.localParticipant
        .unpublishTrack(micTrack, true)
        .then((t) => {
          log("unpublished", t?.trackSid);
        })
        .catch((error) => {
          console.error("failed to unpublish", error);
        });
    }
  }, [room, activatedAt, micTrack]);

  const errMsg = !url
    ? "Two-way audio requires low latency enabled"
    : !backchannelAvailable || !room || !applianceParticipant
    ? "Two-way audio unavailable"
    : !!metadata?.backchannelDetails?.sid &&
      metadata.backchannelDetails.sid !== micTrack?.sid
    ? "Two-way audio already in use"
    : "";

  return (
    <div className="flex flex-row gap-2 self-stretch items-center">
      {activatedAt && (
        <Tooltip title="Disable two-way audio">
          <IconButton
            className="text-white"
            onClick={() => {
              setActivatedAt(null);
              feedback.pushSnackbar(
                "Two-way audio disabled",
                FeedbackType.Warning
              );
            }}
          >
            <Close />
          </IconButton>
        </Tooltip>
      )}
      {!activatedAt && (
        <Tooltip title={errMsg || "Enable two-way audio"}>
          <span>
            <IconButton
              className={!errMsg ? "text-white" : "text-[#bbbbbb]"}
              onClick={() => setActivatedAt(new Date())}
              disabled={!!errMsg}
            >
              <Mic />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {activatedAt && !errMsg && (
        <div
          className={clsx(
            "transition-all text-white no-underline rounded text-md self-stretch flex flex-row items-center justify-center gap-2 cursor-pointer",
            micEnabled
              ? "bg-error hover:bg-error shadow-[0_0px_41px_#FF490F] w-48 font-bold"
              : "bg-primary/60 hover:bg-primary w-48"
          )}
          onMouseDown={() => {
            setMicEnabled(true);
          }}
          onMouseUp={() => {
            setMicEnabled(false);
          }}
        >
          {micEnabled ? (
            connected === State.Connected ? (
              <>
                <RadioButtonChecked /> Release to Stop
              </>
            ) : (
              <CircularProgress size="1.5rem" />
            )
          ) : (
            <>
              <Mic /> Click & Hold to Talk
            </>
          )}
        </div>
      )}
    </div>
  );
}

const useMetadata = (applianceParticipant: Participant | null) => {
  const [metadata, setMetadata] = useState<Metadata | null>(null);

  useEffect(() => {
    if (!applianceParticipant) {
      log("no appliance");
      setMetadata(null);
      return;
    }

    log("initial metadata", applianceParticipant.metadata);
    setMetadata(
      applianceParticipant.metadata
        ? JSON.parse(applianceParticipant.metadata)
        : null
    );

    applianceParticipant.on(
      "participantMetadataChanged",
      (prevMetadata: string | undefined, participant?: any) => {
        log("metadata changed", applianceParticipant.metadata);
        setMetadata(
          applianceParticipant.metadata
            ? JSON.parse(applianceParticipant.metadata)
            : null
        );
      }
    );
  }, [applianceParticipant]);

  return [metadata];
};

const useBackchannelAvailable = (metadata: Metadata | null) => {
  const [backchannelAvailable, setBackchannelAvailable] = useState(false);
  useEffect(() => {
    log("backchannel", metadata?.backchannel);
    setBackchannelAvailable(!!metadata?.backchannel);
  }, [metadata?.backchannel]);
  return [backchannelAvailable];
};

const log = (...data: any) => {
  if (localStorage.getItem("backchannelDebug") === "true")
    console.log("backchannel:", ...data);
};

gql`
  mutation recordBidiAudioActivity($id: Int!, $start: DateTime!) {
    recordBidiAudioActivity(id: $id, start: $start)
  }
`;
