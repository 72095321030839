import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { Link, StyledEngineProvider, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Provider as JotaiProvider } from "jotai";
import { useFlags } from "launchdarkly-react-client-sdk";
import { parse, stringify } from "query-string";
import { lazy, Suspense, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useMatch,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

import { Analytics, AnonymousAnalytics } from "@/util/analytics";
import "@/util/datadog";
import { filterNullish } from "@/util/filterFalsy";

import { AlertsPage } from "@/pages/Alerts/AlertsPage";
import { Case } from "@/pages/Cases/Case";
import { Cases } from "@/pages/Cases/Cases";
import { IntegrationsPage } from "@/pages/Integrations/IntegrationsPage";
import { Intelligence } from "@/pages/Intelligence/Intelligence";
import { IntelligenceDashboardForm } from "@/pages/Intelligence/IntelligenceDashboardForm";
import { IntelligenceDashboardView } from "@/pages/Intelligence/IntelligenceDashboardView";
import { MaintainPage } from "@/pages/Maintain/MaintainPage";
import ProxyPage from "@/pages/Onboarding/ApplianceProxy/ProxyPage";
import { WelcomePage } from "@/pages/Onboarding/WelcomePage";
import { Settings } from "@/pages/Settings/Settings";
import { GuestCameraView } from "@/pages/Shares/GuestCamera";
import { GuestVodView } from "@/pages/Shares/GuestVod";
import { SharesList } from "@/pages/Shares/SharesList";
import Kiosk from "@/pages/VideoWall/Kiosk/Kiosk";
import KioskAuth from "@/pages/VideoWall/Kiosk/KioskAuth";
import KioskLayout from "@/pages/VideoWall/Kiosk/KioskLayout";

import {
  AuthProtected,
  AuthProvider,
  MobileAppAuthProtected,
  useMe,
} from "@/components/Auth";
import { DownloadsProvider } from "@/components/Downloads";
import {
  ErrorMessage,
  RefreshButtonErrorMessage,
} from "@/components/ErrorMessage";
import { Gainsight } from "@/components/Gainsight";
import { LaunchDarklyProvider } from "@/components/LaunchDarkly";
import { MaintenanceProvider } from "@/components/MaintenanceProvider";
import { PasswordExpired } from "@/components/PasswordExpired";
import { Zendesk } from "@/components/Scripts/Zendesk";
import { SnackbarProvider } from "@/components/SnackbarProvider";
import { SignalingServer } from "@/components/Socket";
import { ViewBase } from "@/components/View/ViewBase";
import { FlattenProviders } from "@/components/shared/FlattenProviders";

import { ApolloProvider } from "@/apolloClient";
import { Layout } from "@/layout/Layout";
import { MobileMenu } from "@/layout/MobileMenu";
import { theme } from "@/layout/theme";

import { IntercomProvider } from "./components/Intercom/IntercomProvider";
import { RequiredUpdateSplash } from "./components/Mobile/RequiredUpdateSplash";
import { isMobileApp } from "./components/Mobile/mobileEnvironment";
import { OrgPermissionIds, usePermissions } from "./hooks/usePermissions";
import { AssistantPage } from "./pages/Assistant/AssistantPage";
import { GuestCaseView } from "./pages/Cases/GuestCase";
import { Debug } from "./pages/Debug/Debug";
import { LicensePlatePage } from "./pages/Intelligence/LicensePlate/LicensePlatePage";
import { LoginPage } from "./pages/Login";
import { Search } from "./pages/Search/Search";
import { windowProxy } from "./pages/Settings/UserManagement/NavigationPrompt";
import { GuestSnippetView } from "./pages/Shares/GuestSnippet";
import { VideoWallPage } from "./pages/VideoWall/VideoWall";

const InternalTools = lazy(
  () => import("@/components/InternalTools/InternalTools")
);

export const muiCache = createCache({
  key: "mui",
  prepend: true,
});

export default function App() {
  return (
    <ErrorBoundary
      fallback={
        <RefreshButtonErrorMessage
          title="Oops!"
          description="Something went wrong, please refresh the page."
        />
      }
    >
      <BrowserRouter window={windowProxy}>
        <Providers />
      </BrowserRouter>
    </ErrorBoundary>
  );
}

function Providers() {
  const matchKiosk = useMatch("/kiosk");
  const locationIsKiosk = Boolean(matchKiosk);

  return (
    <FlattenProviders
      contextProviders={[
        <CacheProvider value={muiCache} />,
        <StyledEngineProvider injectFirst />,
        <ThemeProvider theme={theme} children={<></>} />,
        <JotaiProvider children={<></>} />,
        <ApolloProvider children={<></>} />,
        <SnackbarProvider children={<></>} />,
        isMobileApp || locationIsKiosk ? null : (
          <MaintenanceProvider children={<></>} />
        ),
        locationIsKiosk ? null : isMobileApp ? (
          <RequiredUpdateSplash children={<></>} />
        ) : (
          <AuthProvider children={<></>} />
        ),
        <LaunchDarklyProvider
          locationIsKiosk={locationIsKiosk}
          children={<></>}
        />,
        locationIsKiosk ? null : <DownloadsProvider children={<></>} />,
        <QueryParamProvider
          adapter={ReactRouter6Adapter}
          options={{
            searchStringToObject: parse,
            objectToSearchString: stringify,
          }}
          children={{} as any}
        />,
        <LocalizationProvider dateAdapter={AdapterDateFns} />,
        <SignalingServer children={<></>} />,
        locationIsKiosk ? null : <Gainsight children={<></>} />,
        locationIsKiosk ? null : <Zendesk children={<></>} />,
        locationIsKiosk ? null : <IntercomProvider children={<></>} />,
      ].filter(filterNullish)}
    >
      <ResolvedRoutes />
    </FlattenProviders>
  );
}

/**
 * Redirects URLs without organization slugs to the default orgization for this user.
 * This is meant for backwards compatibility of the new URL format, which includes the org slug.
 */
function OrgRedirect() {
  const { "*": splat } = useParams();
  const [search] = useSearchParams();
  const searchSuffix = search.toString();
  const me = useMe();
  if (!me) return null;

  return (
    <Navigate
      to={
        ["o", me.organization.slug, splat ?? "live"].join("/") +
        (searchSuffix ? `?${searchSuffix}` : "")
      }
      replace
    />
  );
}

function ResolvedRoutes() {
  const AuthWrapper = isMobileApp ? MobileAppAuthProtected : AuthProtected;
  const { spotAssist, integrations, dashboardRedirect } = useFlags();
  const hasPermission = usePermissions();

  useEffect(() => {
    const url = new URL(window.location.href);

    if (
      !isMobileApp &&
      dashboardRedirect &&
      url.hostname === "dashboard.spotai.co"
    ) {
      window.location.replace(
        `https://dashboard.spot.ai${url.pathname + url.search + url.hash}`
      );
    }
  }, [dashboardRedirect]);

  const defaultPageCandidates: {
    to: string;
    permission: OrgPermissionIds;
  }[] = [
    { to: "live", permission: "video_live_access" },
    { to: "search", permission: "video_vod_access" },
    { to: "walls", permission: "walls_access" },
    { to: "cases", permission: "cases_access" },
    { to: "shares", permission: "video_share" },
    { to: "intelligence", permission: "intelligence_access" },
    { to: "integrations", permission: "integrations_access" },
    { to: "maintain", permission: "devices_access" },
  ];
  const defaultPage = defaultPageCandidates.find(({ permission }) =>
    hasPermission(permission)
  )?.to;

  const mainRoutes = (
    <>
      {defaultPage && (
        <Route index element={<Navigate to={defaultPage} replace />} />
      )}
      <Route path="welcome" element={<WelcomePage />} />
      <Route path="login" element={<LoginPage />} />
      <Route
        path="live/*"
        element={hasPermission("video_live_access", <ViewBase />)}
      />
      <Route
        path="search/*"
        element={hasPermission("video_vod_access", <Search />)}
      />
      <Route
        path="wall/*"
        element={hasPermission("walls_access", <VideoWallPage />)}
      />

      <Route
        path="cases/:id/*"
        element={hasPermission("cases_access", <Case />)}
      />
      <Route path="cases" element={hasPermission("cases_access", <Cases />)}>
        <Route path="all" />
        <Route path="closed" />
        <Route path="open" />
      </Route>
      <Route
        path="shares/*"
        element={hasPermission("video_share", <SharesList />)}
      />
      <Route path="intelligence">
        <Route
          index
          element={hasPermission("intelligence_access", <Intelligence />)}
        />
        <Route
          path="license-plate"
          element={hasPermission("intelligence_access", <LicensePlatePage />)}
        />
        <Route
          path=":type/new"
          element={hasPermission(
            "intelligence_access",
            <IntelligenceDashboardForm />
          )}
        />
        <Route
          path="edit/:id"
          element={hasPermission(
            "intelligence_access",
            <IntelligenceDashboardForm />
          )}
        />
        <Route
          path=":id"
          element={hasPermission(
            "intelligence_access",
            <IntelligenceDashboardView />
          )}
        />
      </Route>
      {integrations && (
        <Route
          path="integrations/*"
          element={hasPermission("integrations_access", <IntegrationsPage />)}
        />
      )}
      {spotAssist && <Route path="assistant/*" element={<AssistantPage />} />}
      <Route path="configure/*" element={<Settings />} />
      {
        <Route
          path="maintain/*"
          element={hasPermission("devices_access", <MaintainPage />)}
        />
      }
      <Route
        path="internal/*"
        element={
          <Suspense>
            <InternalTools />
          </Suspense>
        }
      />
      <Route path="alerts/*" element={<AlertsPage />} />
      <Route path="menu" element={<MobileMenu />} />
      <Route path="debug" element={<Debug />} />
      <Route
        path="*"
        element={
          <ErrorMessage
            title="404"
            description={
              <span>
                That's an error. The requested URL could not be found. Take me
                back to the{" "}
                <Link href="/" underline="hover">
                  dashboard
                </Link>
                .
              </span>
            }
          />
        }
      />
    </>
  );

  const orgMainRoutes = (
    <>
      <Route index element={<OrgRedirect />} />
      <Route path="*" element={<OrgRedirect />} />
      <Route path="o/:orgSlug" element={<Layout />}>
        {mainRoutes}
      </Route>
    </>
  );

  const sharedOrgRoutes = ["o/:orgSlug/s", "/s"].map((path) => (
    <Route key={path} path={path} element={<AnonymousAnalytics />}>
      <Route path="camera/:token" element={<GuestCameraView />} />
      <Route path="vod/:token" element={<GuestVodView />} />
      <Route path="snippet/:token" element={<GuestSnippetView />} />
      <Route path="case/:token/*" element={<GuestCaseView />} />
    </Route>
  ));

  const localProxyRoutes = ["o/:orgSlug/localproxy/", "/localproxy/"].map(
    (path) => (
      <Route key={path} path={path}>
        <Route
          path="appliance/:applianceSerial/:proxyEndpoint"
          element={<ProxyPage />}
        />
        <Route path="appliance/:applianceSerial" element={<ProxyPage />} />
      </Route>
    )
  );

  return (
    <Routes>
      <Route path="/password-expired" element={<PasswordExpired />} />
      {localProxyRoutes}
      {sharedOrgRoutes}
      <Route path="/user-login-page" element={<LoginPage />} />
      <Route
        path="/spotcast"
        element={<Navigate to="/wall?registerSpotcast=1" replace />}
      />
      <Route
        path="/kiosk"
        element={
          <KioskLayout>
            <KioskAuth>
              <Kiosk />
            </KioskAuth>
          </KioskLayout>
        }
      />
      <Route
        path="/"
        element={
          <>
            <AuthWrapper>
              <Outlet />
            </AuthWrapper>
            <Analytics />
          </>
        }
      >
        {orgMainRoutes}
      </Route>
    </Routes>
  );
}
