import CloseIcon from "@mui/icons-material/Close";
import PlayButtonIcon from "@mui/icons-material/PlayCircleFilledWhiteTwoTone";
import { Box, IconButton, Modal, Tooltip, Typography } from "@mui/material";
import gql from "graphql-tag";
import { padCharsStart } from "lodash/fp";
import { Link, useMatch, useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { useBreakpoints } from "@/util/useBreakpoints";
import { useDocumentTitle } from "@/util/useDocumentTitle";

import { Loading } from "@/components/Loading";

import { useTutorialVideosQuery } from "@/generated-models";
import { usePrefixOrgSlug } from "@/hooks/useOrgRouteBase";
import { MobileHeader } from "@/layout/MobileHeader";

// import { BasicPlayer } from "../BasicPlayer";

const useStyles = makeStyles()((theme) => ({
  videoTile: {
    // textDecoration: "inherit",
    // color: "inherit",
    "&:not(:hover) svg": {
      display: "none",
    },
  },
  playButton: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: 70,
    height: 70,
  },

  [theme.breakpoints.up("sm")]: {
    player: {
      maxWidth: "70vw",
      maxHeight: "70vh",
    },
  },
  player: {
    maxWidth: "100vw",
  },
}));

export function Tutorials() {
  useDocumentTitle("Tutorials");
  const { fitsDesktop } = useBreakpoints();
  const { classes } = useStyles();
  const { data } = useTutorialVideosQuery();
  const navigate = useNavigate();
  const prefixOrgSlug = usePrefixOrgSlug();
  const videoRouteMatch = useMatch(prefixOrgSlug("/configure/tutorials/:id"));
  const openedVideoId = videoRouteMatch && Number(videoRouteMatch.params.id);
  const openedVideo =
    data &&
    videoRouteMatch &&
    data.tutorialVideos.find((vid) => vid.id === openedVideoId);

  return (
    <>
      <MobileHeader label="Tutorials" />
      {fitsDesktop && (
        <div className="flex justify-between items-center flex-wrap p-4">
          <Typography variant="h1">Tutorials</Typography>
          <Typography variant="body2">
            Learn how to use Spot with these quick and easy video tutorials.
          </Typography>
        </div>
      )}

      <article className="shadow-divider">
        <div className="flex justify-between px-6 pt-8 pb-4">
          <Typography variant="h2">Introduction to Spot</Typography>
          {data && (
            <Typography variant="body2">
              {data.tutorialVideos.length} videos
            </Typography>
          )}
        </div>
        {!data && <Loading>Loading Tutorials</Loading>}
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-3 mt-0 px-4 py-3">
          {data?.tutorialVideos.map((vid) => (
            <div
              key={vid.id}
              className="shadow-[0_6px_13px_rgba(0,0,0,0.18)] rounded-md"
              style={{
                background:
                  "linear-gradient(180deg, #D6D6D6 0%, #FFFFFF 100%), linear-gradient(180deg, #EDEDED 0%, #EDEDED 100%)",
              }}
            >
              <Link to={String(vid.id)} className={classes.videoTile} replace>
                <div className="flex flex-col gap-2">
                  <div className="bg-[#DADADA] rounded-t-md relative">
                    <img src={vid.thumbnail} alt="" className="w-full" />
                    <PlayButtonIcon
                      color="primary"
                      className={classes.playButton}
                    />
                  </div>

                  <div className="p-2 flex flex-col">
                    <Typography
                      variant="h6"
                      component="h2"
                      className="no-underline"
                    >
                      {vid.title}
                    </Typography>
                    <Typography variant="body2">{vid.description}</Typography>
                    <Typography
                      variant="body2"
                      style={{
                        maxWidth: 200,
                      }}
                      className="mt-2"
                    >
                      {formatTutorialDuration(vid.duration)}
                    </Typography>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </article>
      {openedVideo && (
        <Modal
          open
          onClose={() => navigate(".", { replace: true })}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
            className="max-w-[50%]"
            style={{
              flexBasis: "fit-content",
            }}
          >
            <Tooltip title="close">
              <Link replace to=".">
                <IconButton size="small">
                  <CloseIcon style={{ color: "white" }} />
                </IconButton>
              </Link>
            </Tooltip>
            <div style={{ background: "white" }}>
              {/* <BasicPlayer source={openedVideo.url} autostart mute={false} /> */}
              <video
                // className={classes.player}
                className="w-full"
                src={openedVideo.url}
                autoPlay
                controls
              />
              <Box p={3}>
                <Typography variant="h6" component="h2">
                  {openedVideo.title}
                </Typography>
                <Typography variant="caption">
                  {formatTutorialDuration(openedVideo.duration)}
                </Typography>
                {/* <Typography variant="body2">{openedVideo.description}</Typography> */}
              </Box>
            </div>
          </Box>
        </Modal>
      )}
    </>
  );
}

const padSec = padCharsStart("0", 2);

function formatTutorialDuration(durationSec: number) {
  const durationMin = Math.floor(durationSec / 60);
  const durationSecRest = durationSec % 60;
  return `${durationMin}:${padSec(String(durationSecRest))}`;
}

export const GET_TUTORIAL_VIDEOS = gql`
  query tutorialVideos {
    tutorialVideos {
      id
      title
      description
      duration
      thumbnail
      url
    }
  }
`;
