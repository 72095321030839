import React, { Suspense, useEffect, useState } from "react";

import { apiHost } from "@/environment";

const Maintenance = React.lazy(() => import("./Maintenance"));

export function MaintenanceProvider({ children }: React.PropsWithChildren<{}>) {
  const [maintenanceNotice, setStatus] = useState();

  useEffect(() => {
    fetch(`${apiHost}/health`)
      .then((response) => response.json())
      .then((jsonResponse) => {
        setStatus(jsonResponse.notice);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return maintenanceNotice ? (
    <Suspense fallback={<div>Loading...</div>}>
      <Maintenance notice={maintenanceNotice} />
    </Suspense>
  ) : (
    <>{children}</>
  );
}
