import WarningIcon from "@mui/icons-material/ErrorOutline";
import { Button, CircularProgress, Tooltip, Typography } from "@mui/material";

import { LockRow } from "@/pages/Settings/LocationSettings/Camera/DeviceRow";

import {
  LocationAi,
  useLocationCapacity,
} from "@/components/Genius/LocationCapacityGuard";
import { determineDefaultStream } from "@/components/StreamSelectionDropdown/StreamSelectionDropdownMenu";

import { useDeviceDetailsQuery } from "@/generated-models";

import { DefaultDialog, useDialog } from "../shared/Dialog";
import { useAddCamera } from "./Forms/Camera/cameraHooks";

export function AutoActivateStreamAction({
  deviceId,
  name,
  lockRow,
}: {
  deviceId: number;
  name: string;
  lockRow: LockRow;
}) {
  const {
    locationId,
    locationAi,
    maxCapacityReached,
    guardCapacityAndShowModal,
  } = useLocationCapacity();
  const { data } = useDeviceDetailsQuery({ variables: { deviceId } });

  const { addNewCamera: addCamera, loading: addCameraLoading } = useAddCamera(
    locationId
  );

  const { open, ...capacityDialogProps } = useDialog();

  if (!data?.deviceScan) {
    return null;
  }
  const device = data.deviceScan;

  const connectedStream = device.channels[0]?.streams.find(
    (stream) => stream.camera
  );
  const recommendedCameraSettings =
    connectedStream?.camera?.appliance?.defaultCameraSettings;

  const autoSelectedStream =
    device.channels[0] &&
    determineDefaultStream(
      device.channels[0].streams,
      recommendedCameraSettings
    );

  if (!autoSelectedStream) {
    return (
      <Typography className="font-bold text-[#F44336] text-sm">
        Manually Activate Stream
      </Typography>
    );
  }
  return (
    <>
      <Tooltip
        title={
          maxCapacityReached
            ? "Max Capacity Reached"
            : `Activate stream (${autoSelectedStream.metadata!.width}x${
                autoSelectedStream.metadata!.height
              })`
        }
      >
        <Button
          color="primary"
          variant="outlined"
          style={{ background: "white" }}
          disabled={addCameraLoading}
          onMouseOver={(e) => e.stopPropagation()}
          onClick={async (e) => {
            e.stopPropagation();

            // check total capacity first
            if (guardCapacityAndShowModal(false)) return;

            let ai = true;
            if (locationAi === LocationAi.Mixed) {
              ai = await open();
              if (ai && guardCapacityAndShowModal(true)) return;
            } else if (locationAi === LocationAi.NonAiOnly) {
              ai = false;
            }

            lockRow(device.mac, true);
            addCamera(autoSelectedStream.id, name, device.vendor, ai);
          }}
        >
          Auto-Activate
          <div className="w-1" />
          {maxCapacityReached ? (
            <WarningIcon className="text-base" />
          ) : (
            addCameraLoading && <CircularProgress size="small" />
          )}
        </Button>
      </Tooltip>
      {locationAi === LocationAi.Mixed && (
        <DefaultDialog
          title="Enable AI?"
          content={
            <>
              <Typography>Enable AI for this camera?</Typography>
            </>
          }
          cancelText="No"
          confirmText="Yes"
          {...capacityDialogProps}
        />
      )}
    </>
  );
}
