import { omit } from "lodash/fp";
import { parse, stringify } from "query-string";
import { forwardRef } from "react";
import {
  Link,
  LinkProps,
  Pathname,
  useLocation,
  useSearchParams,
} from "react-router-dom";

export interface QueryParamLinkProps extends Omit<LinkProps, "to"> {
  params?: {
    [paramName: string]: string | number | null | undefined;
  };
  removalParams?: string[];
  mergeCurrentParams?: boolean;
  /** defaults to the current pathname */
  to?: string;
}

export const QueryParamLink = forwardRef(function QueryParamLink(
  {
    params = {},
    removalParams = [],
    mergeCurrentParams = true,
    to,
    className = "text-primary",
    ...rest
  }: QueryParamLinkProps,
  ref: any
) {
  const { search } = useLocation();
  const parsed = mergeCurrentParams
    ? // We never want to `omit` params that were passed - only those that were already in the URL
      omit(removalParams, parse(search))
    : undefined;
  const newSearch = stringify({ ...parsed, ...params });

  return (
    <Link
      ref={ref}
      className={className}
      to={{ pathname: to, search: `?${newSearch}` }}
      {...rest}
    />
  );
});

export interface SearchParamLinkProps extends Omit<LinkProps, "to"> {
  params?: Record<string, string | number>;
  removeParams?: string[];
  keepParams?: string[];
  mergeCurrentParams?: boolean;
  /** defaults to the current pathname */
  to?: Pathname;
}

// Similar to the above, but uses the new useSearchParams hook and has a different api.
// I didn't want to change the above because it's used in a lot of places.
export function SearchParamLink({
  params = {},
  removeParams = [],
  keepParams = [],
  mergeCurrentParams = true,
  to,
  className = "text-primary",
  ...rest
}: SearchParamLinkProps) {
  let [searchParams] = useSearchParams();

  if (mergeCurrentParams) {
    // Collect the params we want to delete
    const deleteKeys = new Set(removeParams);
    if (keepParams.length) {
      // If we're keeping some params, delete the rest
      for (const key of searchParams.keys()) {
        if (!keepParams.includes(key)) deleteKeys.add(key);
      }
    }

    // Delete the params we don't want
    for (const key of deleteKeys) {
      searchParams.delete(key);
    }
  } else {
    // Clear the params
    searchParams = new URLSearchParams();
  }

  // Merge in the new params
  Object.entries(params).forEach(([key, value]) => {
    searchParams.set(key, String(value));
  });

  // Sort the params
  searchParams.sort();

  return (
    <Link
      className={className}
      to={{ pathname: to, search: `?${searchParams.toString()}` }}
      {...rest}
    />
  );
}
