import clsx from "clsx";
import { StringParam, useQueryParam } from "use-query-params";

import { useLprSearch } from "../hooks";
import { LicensePlateSummaryActionBar } from "./LicensePlateSummaryActionBar";
import { LicensePlateSummaryBarDetails } from "./LicensePlateSummaryBarDetails";
import { LicensePlateSummaryChart } from "./LicensePlateSummaryChart";

export function LicensePlateSummaryBar({ className }: { className?: string }) {
  const [viewing] = useQueryParam("viewing", StringParam);
  const { loading, error, disabled } = useLprSearch();

  if (loading || error || disabled) return <></>;

  return (
    <div className={className}>
      <LicensePlateSummaryBarDetails />
      <LicensePlateSummaryActionBar />
      <div className={clsx(viewing && "hidden")}>
        <LicensePlateSummaryChart />
      </div>
    </div>
  );
}
