import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import gql from "graphql-tag";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-use";
import { NumberParam, StringParam, useQueryParam } from "use-query-params";

import { filterNullish } from "@/util/filterFalsy";
import { useBreakpoints } from "@/util/useBreakpoints";

import { convertDateToTime, convertTimeToDate } from "@/pages/Alerts/AlertEdit";
import {
  AlertCameras,
  AlertCommunication,
  AlertInterestList,
  AlertTimeRange,
  alertUserTemplate,
} from "@/pages/Alerts/AlertFormBlocks";
import { AlertNameType } from "@/pages/Alerts/AlertNameType";
import {
  aiAlertTypes,
  useAlertThresholdOptions,
} from "@/pages/Alerts/constants";
import { useAlertRoutes } from "@/pages/Alerts/utils";

import { CopilotBanner } from "@/components/Ai/Copilot/CopilotOverlay/CopilotLabelButton/CopilotBanner";
import { isDemoUser, useMe } from "@/components/Auth";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { ZendeskArticle, ZendeskLink } from "@/components/Zendesk/ZendeskLink";
import { QueryParamLink } from "@/components/shared/QueryParamLink";

import {
  AlertCameraInput,
  AlertDetectionCountOperator,
  AlertType,
  GetAlertsDocument,
  Role,
  useCreateAlertMutation,
  useUserQuery,
} from "@/generated-models";
import { BackButton } from "@/layout/BackButton";
import { ContentWrapper } from "@/layout/ContentWrapper";

import { AlertIntegrationDevices } from "./AlertIntegrationDevices";
import { AlertIntegrationEvent } from "./AlertIntegrationEvent";

interface AlertCreateProps {
  defaultAlertType?: AlertType;
  defaultCameras?: AlertCameraInput[];
  allowCameraSelection?: boolean;
  onCompleted?: (id: number) => void;
  onCancel?: () => void;
  contentWrapperClassName?: string;
}

// NOTE: make sure these values match with the IntegrationTypeKey enum.
export const integrationAlertTypes = [
  AlertType.AccessControl,
  AlertType.EnvironmentSensor,
  AlertType.IoBoard,
];

export function AlertCreate(props: AlertCreateProps) {
  const {
    defaultAlertType,
    defaultCameras = [],
    allowCameraSelection,
    onCompleted,
    onCancel,
    contentWrapperClassName,
  } = props;
  const { fitsTablet } = useBreakpoints();
  const [orgUserId] = useQueryParam("ouid", NumberParam);
  const [cameraId] = useQueryParam("cams", NumberParam);
  const [defaultAlertTypeParam] = useQueryParam("type", StringParam);
  const [zoneId] = useQueryParam("zoneId", NumberParam);
  const resolvedDefaultCameras = cameraId
    ? [{ id: cameraId, zoneIds: zoneId ? [zoneId] : [] }]
    : defaultCameras;
  const resolvedDefaultAlertType = (defaultAlertTypeParam ??
    defaultAlertType) as AlertType;

  const user = useMe();
  const { data } = useUserQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    errorPolicy: "all",
    skip: !orgUserId || !user || user.role < Role.Sales,
    variables: {
      orgUserId: orgUserId ?? -1,
    },
  });
  const isDemo = !!user && isDemoUser(user); // only relevant for demo orgs
  const { alertCamOneMin } = useFlags();
  const feedback = useFeedback();
  const location = useLocation();
  const navigate = useNavigate();
  const alertRoutes = useAlertRoutes();
  const [createAlert] = useCreateAlertMutation({
    refetchQueries: [{ query: GetAlertsDocument }],
    awaitRefetchQueries: true,
    onCompleted: (result) => {
      feedback.pushSnackbar("Alert created successfully", FeedbackType.Success);
      onCompleted
        ? onCompleted(result.createAlert.id)
        : navigate(alertRoutes.ALERTS_CONFIGURE + `${location.search}`);
    },
    onError: () =>
      feedback.pushSnackbar(
        "Creating alert failed, please try again",
        FeedbackType.Error
      ),
  });
  const [step, setStep] = useState(0);
  const actingUser = data?.user ?? user;
  const alertThresholdOptions = useAlertThresholdOptions();
  const defaultThresholdIdx =
    (resolvedDefaultAlertType ?? AlertType.CameraOffline) ===
      AlertType.CameraOffline && alertCamOneMin
      ? 1
      : 0;
  if (!user || !actingUser) return null;

  return (
    <ContentWrapper className={contentWrapperClassName}>
      <Container maxWidth="md" className="p-0">
        <Paper
          elevation={fitsTablet ? 4 : 0}
          style={fitsTablet ? {} : { borderRadius: 0 }}
        >
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ padding: 18 }}
          >
            <Typography variant="h1">
              <BackButton
                className="md:hidden"
                withQueryParams
                to={alertRoutes.ALERTS_BASE}
              />
              Create an Alert
              <ZendeskLink
                className="pl-1"
                article={ZendeskArticle.CREATE_ALERT}
              />
            </Typography>
            <Button
              style={{ opacity: 0.7 }}
              {...(onCancel
                ? { onClick: onCancel }
                : { to: alertRoutes.ALERTS_BASE, component: QueryParamLink })}
            >
              Cancel
            </Button>
          </Grid>
          <Formik
            initialValues={{
              name: "",
              type: resolvedDefaultAlertType ?? AlertType.CameraOffline,
              thresholdSeconds:
                alertThresholdOptions[
                  resolvedDefaultAlertType ?? AlertType.CameraOffline
                ]?.options[defaultThresholdIdx] ?? 0,
              confidence: 2,
              cameras: resolvedDefaultCameras,
              daysOfWeek: ["0", "1", "2", "3", "4", "5", "6"],
              startTime: convertTimeToDate("00:00"),
              endTime: convertTimeToDate("23:59"),
              users: [
                {
                  ...alertUserTemplate,
                  orgUserId: actingUser.orgUserId,
                  name: actingUser.name || actingUser.email,
                  isOwner: true,
                  hasPhoneNumber: !!actingUser.phone,
                  hasMobileApp: true,
                },
              ],
              isDigest: true,
              isImmediate: false,
              operator: AlertDetectionCountOperator.Any,
              detectionCount: null as null | number,
              customText: "",
              integrationId: "",
              integrationEventTypeId: "",
              integrationSourceIds: [] as number[],
            }}
            onSubmit={(values) => {
              const {
                integrationId,
                integrationEventTypeId,
                integrationSourceIds,
                ...input
              } = values;
              return createAlert({
                variables: {
                  input: {
                    ...input,
                    detectionCount:
                      values.operator !== AlertDetectionCountOperator.Any &&
                      values.detectionCount
                        ? Number(values.detectionCount)
                        : null,
                    thresholdSeconds: Number(values.thresholdSeconds),
                    daysOfWeek: values.daysOfWeek.map(Number),
                    startTime: convertDateToTime(values.startTime),
                    endTime: convertDateToTime(values.endTime),
                    users: values.users.map(
                      ({
                        orgUserId,
                        receiveNotifications,
                        receiveEmails,
                        receiveTexts,
                        receiveDigestEmails,
                        receivePush,
                      }) => {
                        return {
                          orgUserId,
                          receiveNotifications,
                          receiveEmails,
                          receiveTexts,
                          receiveDigestEmails,
                          receivePush,
                        };
                      }
                    ),
                    integration:
                      integrationId && integrationEventTypeId
                        ? {
                            integrationId: Number(integrationId),
                            integrationEventTypeId: Number(
                              integrationEventTypeId
                            ),
                            integrationSourceIds: integrationSourceIds,
                          }
                        : null,
                  },
                },
              });
            }}
          >
            {({
              isValid,
              isSubmitting,
              values,
              validateForm,
              isValidating,
              submitForm,
              setFieldTouched,
            }) => {
              const skipTimeFields = values.type === AlertType.CameraOffline;
              const skipListField =
                values.type !== AlertType.VehicleInterestList;

              const integrationSteps = [
                {
                  label: "Select integration and event type",
                  children: (
                    <AlertIntegrationEvent integrationType={values.type} />
                  ),
                },
                {
                  label: "Select devices",
                  children: <AlertIntegrationDevices />,
                },
              ];

              const sharedSteps = [
                skipListField
                  ? null
                  : {
                      label: "Select List",
                      children: <AlertInterestList />,
                    },
                {
                  label: "Select your cameras",
                  children: (
                    <AlertCameras
                      proMode={
                        aiAlertTypes.has(values.type) &&
                        values.operator !== AlertDetectionCountOperator.Any
                      }
                      allowCameraSelection={allowCameraSelection ?? true}
                    />
                  ),
                },
              ];

              const steps = [
                {
                  label: "Name and alert type",
                  children: <AlertNameType disableType={!!defaultAlertType} />,
                },
                ...(integrationAlertTypes.includes(values.type)
                  ? integrationSteps
                  : sharedSteps),
                skipTimeFields
                  ? null
                  : {
                      label: "Select alert time range",
                      children: <AlertTimeRange />,
                    },
                {
                  label: "Enter your alert communication preferences",
                  children: <AlertCommunication isDemo={isDemo} />,
                },
              ].filter(filterNullish);
              const lastStep = step === steps.length - 1;

              return (
                <Form>
                  <Stepper
                    activeStep={step}
                    orientation="vertical"
                    style={{
                      boxShadow: "inset 0px 10px 9px -10px rgba(0,0,0,0.4)",
                    }}
                  >
                    {steps.map(({ label, children }, index) => (
                      <Step key={index}>
                        {index === 0 && (
                          <CopilotBanner className="bg-#F2F2F2 mb-4" />
                        )}
                        <StepLabel
                          StepIconProps={{
                            classes: {
                              root: "text-[28px] ml-[-2px]",
                            },
                          }}
                          classes={{
                            label: "font-medium text-base leading-[18px] ml-4",
                          }}
                        >
                          {label}
                        </StepLabel>
                        <StepContent>
                          <div
                            style={{
                              padding: fitsTablet ? "18px" : "12px 18px",
                            }}
                          >
                            {children}
                          </div>
                          <div className="flex items-center gap-2 mt-2 ml-6">
                            <Button
                              disabled={step === 0}
                              onClick={() => setStep((step) => step - 1)}
                              style={{ marginRight: 8 }}
                            >
                              BACK
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={async () => {
                                const errors = await validateForm();
                                const keys = Object.keys(errors);
                                keys.forEach((key) => setFieldTouched(key));
                                if (keys.length === 0) {
                                  lastStep
                                    ? submitForm()
                                    : setStep((step) => step + 1);
                                }
                              }}
                              disabled={
                                isSubmitting ||
                                !isValid ||
                                (lastStep && isDemo) ||
                                isValidating
                              }
                            >
                              <>{lastStep ? "CREATE ALERT" : "NEXT"}</>
                            </Button>
                            {step === 0 && isValidating && (
                              <>
                                <div className="w-4" />
                                <CircularProgress size={16} /> Checking AI
                                Availability
                              </>
                            )}
                          </div>
                        </StepContent>
                      </Step>
                    ))}
                  </Stepper>
                </Form>
              );
            }}
          </Formik>
        </Paper>
      </Container>
    </ContentWrapper>
  );
}

gql`
  mutation createAlert($input: CreateAlertInput!) {
    createAlert(input: $input) {
      id
    }
  }
`;

gql`
  query eligibleAlertFeatures {
    eligibleAlertFeatures {
      type
      features
    }
  }
`;

gql`
  query user($orgUserId: Int!) {
    user(orgUserId: $orgUserId) {
      id
      orgUserId
      name
      email
      phone
      role
    }
  }
`;
