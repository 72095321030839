import React, { PropsWithChildren, useContext, useRef, useState } from "react";

import {
  useGeniusRegenerateMissingStillsMutation,
  useGeniusScanMutation,
} from "@/generated-models";

const GeniusContext = React.createContext<
  [
    (
      loadingMessage?: React.ReactNode,
      successMessage?: React.ReactNode
    ) => void,
    { loading: boolean; statusComponent: React.ReactNode }
  ]
>([
  () => {},
  {
    loading: false,
    statusComponent:
      "Genius is an assistant to keep your cameras happy. Configure a camera below to get started!",
  },
]);

export function useGenius() {
  return useContext(GeniusContext);
}

export default function GeniusProvider(
  props: PropsWithChildren<{
    locationId: number;
    startScan: () => Promise<any>;
  }>
) {
  const [regenerateMissingStills] = useGeniusRegenerateMissingStillsMutation({
    variables: { input: { locationId: props.locationId } },
  });
  const [startGeniusScan, { loading }] = useGeniusScanMutation({
    variables: { input: { locationId: props.locationId } },
  });

  const [statusComponent, setStatusComponent] = useState<
    React.ReactNode | undefined
  >(
    "Genius is an assistant to keep your cameras happy. Configure a camera below to get started!"
  );
  const isRunningRef = useRef(false);
  async function geniusScan(
    loadingMessage?: React.ReactNode,
    successMessage?: React.ReactNode
  ) {
    setStatusComponent(
      loadingMessage || "Genius is refreshing cameras on your network..."
    );
    try {
      if (!isRunningRef.current) {
        isRunningRef.current = true;
        // get latest devices, genius scan new ones, update stream stills, get updated devices
        await props.startScan();
        await startGeniusScan();
        await regenerateMissingStills();
        await props.startScan();

        setStatusComponent(
          successMessage || "Configure another camera below to continue."
        );
        isRunningRef.current = false;
      }
    } catch (e) {
      console.error(e);
      setStatusComponent("Configure another camera below to continue.");
    }
  }

  return (
    <GeniusContext.Provider value={[geniusScan, { loading, statusComponent }]}>
      {props.children}
    </GeniusContext.Provider>
  );
}
