import { IconProps } from "@/icons/IconProps";

export function PersonAbsentIcon({ style, className, onClick }: IconProps) {
  return (
    <svg
      style={style}
      className={className}
      onClick={onClick}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.7">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.8921 6.35983L10.1124 6.2023C10.1393 6.06896 10.1536 5.93053 10.1536 5.78804C10.1536 5.64556 10.1393 5.50713 10.1124 5.37379L10.8921 5.21626C10.9295 5.40114 10.949 5.59234 10.949 5.78804C10.949 5.98375 10.9295 6.17495 10.8921 6.35983ZM10.4467 4.16332L9.78999 4.61225C9.63251 4.38191 9.42965 4.18289 9.1936 4.02813L9.62974 3.3629C9.95227 3.57436 10.2302 3.84671 10.4467 4.16332ZM8.57007 2.93258L8.41879 3.71351C8.28331 3.68727 8.14268 3.67338 7.99796 3.67338C7.85324 3.67338 7.71261 3.68727 7.57714 3.71351L7.42585 2.93258C7.61094 2.89672 7.80222 2.87793 7.99796 2.87793C8.1937 2.87793 8.38499 2.89672 8.57007 2.93258ZM6.36618 3.3629L6.80232 4.02813C6.56627 4.18289 6.36341 4.38191 6.20593 4.61225L5.54927 4.16332C5.76572 3.84671 6.04365 3.57436 6.36618 3.3629ZM5.10382 5.21626C5.06647 5.40114 5.04688 5.59234 5.04688 5.78804C5.04688 5.98375 5.06647 6.17495 5.10382 6.35983L5.88352 6.2023C5.85658 6.06896 5.84233 5.93053 5.84233 5.78804C5.84233 5.64556 5.85658 5.50713 5.88352 5.37379L5.10382 5.21626ZM5.54927 7.41277L6.20593 6.96383C6.36341 7.19418 6.56627 7.3932 6.80232 7.54795L6.36618 8.21318C6.04365 8.00173 5.76572 7.72937 5.54927 7.41277ZM7.42585 8.64351L7.57714 7.86257C7.71261 7.88882 7.85324 7.9027 7.99796 7.9027C8.14268 7.9027 8.28331 7.88882 8.41879 7.86257L8.57007 8.64351C8.38499 8.67936 8.1937 8.69816 7.99796 8.69816C7.80222 8.69816 7.61094 8.67936 7.42585 8.64351ZM9.62974 8.21319L9.1936 7.54795C9.42965 7.3932 9.63251 7.19418 9.78999 6.96383L10.4467 7.41277C10.2302 7.72937 9.95227 8.00173 9.62974 8.21319Z"
          fill="#353D48"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.97656 13.1218H2.77202C2.77202 12.9664 2.79147 12.8135 2.82943 12.6633L2.57858 12.5999V12.3264H2.0982C2.08359 12.3734 2.07026 12.4208 2.05822 12.4684C2.03691 12.5528 2.01965 12.638 2.00658 12.7241C1.98673 12.8549 1.97656 12.9876 1.97656 13.1218ZM2.65096 11.2999L3.27509 11.7931C3.47923 11.5347 3.74565 11.2863 4.07148 11.0592L3.61665 10.4066C3.23538 10.6724 2.90945 10.9728 2.65096 11.2999ZM4.77479 9.77799L5.08114 10.5121C5.42787 10.3674 5.80543 10.2467 6.20786 10.1554L6.03185 9.37967C5.5869 9.48062 5.16558 9.61491 4.77479 9.77799ZM7.33699 9.18756L7.39385 9.98098C7.59158 9.96681 7.79276 9.95952 7.99678 9.95952C8.2008 9.95952 8.40198 9.96681 8.59971 9.98098L8.65657 9.18756C8.43988 9.17203 8.21975 9.16406 7.99678 9.16406C7.77381 9.16406 7.55368 9.17203 7.33699 9.18756ZM9.96171 9.37967L9.7857 10.1554C10.1881 10.2467 10.5657 10.3674 10.9124 10.5121L11.2188 9.77799C10.828 9.61491 10.4067 9.48062 9.96171 9.37967ZM12.3769 10.4067L11.9221 11.0592C12.2479 11.2863 12.5143 11.5347 12.7185 11.7931L13.3426 11.2999C13.0841 10.9728 12.7582 10.6724 12.3769 10.4067ZM13.8954 12.3264H13.415V12.5999L13.1641 12.6633C13.2021 12.8135 13.2215 12.9664 13.2215 13.1218H14.017C14.017 12.9876 14.0068 12.8549 13.987 12.7241C13.9739 12.638 13.9567 12.5528 13.9353 12.4684C13.9233 12.4208 13.91 12.3734 13.8954 12.3264ZM12.2109 13.1218V12.3264H11.0069V13.1218H12.2109ZM9.80284 13.1218V12.3264H8.5988V13.1218H9.80284ZM7.39476 13.1218V12.3264H6.19071V13.1218H7.39476ZM4.98667 13.1218V12.3264H3.78263V13.1218H4.98667Z"
          fill="#353D48"
        />
      </g>
    </svg>
  );
}
