import clsx from "clsx";
import { memo } from "react";
import { makeStyles } from "tss-react/mui";

import {
  DataGrid,
  BaseDatagridProps,
  useQueryParamPagination,
} from "@/components/DataGrid/DataGrid";

const useStyles = makeStyles()(() => ({
  root: {
    "& .MuiDataGrid-window": {
      top: "40px !important",
    },
    "& .MuiDataGrid-columnsContainer": {
      background: "#f4f4f4",
      height: 40,
      minHeight: "40px !important",
    },
    "& .MuiDataGrid-columnHeader": {
      height: 40,
      "& .MuiDataGrid-columnHeaderDraggableContainer": {
        height: 40,
      },
      "& .MuiDataGrid-columnSeparator": {
        minHeight: "40px !important",
        height: 40,
      },
    },
    "& .MuiDataGrid-footerContainer": {
      justifyContent: "center",
    },
  },
}));

type MaintainGridProps = Omit<BaseDatagridProps, "pagination" | "signature">;

export function MaintainDatagridComponent(props: MaintainGridProps) {
  const { classes } = useStyles();
  const queryParamPagination = useQueryParamPagination();

  return (
    <div className="w-full" data-cy="maintain-datagrid">
      <DataGrid
        classes={{
          root: clsx(classes.root, "!rounded-t-none"),
          cell: "h-[58px]",
        }}
        getRowId={({ id }) => id}
        rowHeight={58}
        pageSizeOptions={[16, 32, 64, 100]}
        autoHeight
        {...props}
        {...queryParamPagination}
      />
    </div>
  );
}

export const MaintainDatagrid = memo(MaintainDatagridComponent);
