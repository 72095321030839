import DeleteIcon from "@mui/icons-material/Delete";
import OpenInNewTabIcon from "@mui/icons-material/OpenInNew";
import ShareIcon from "@mui/icons-material/Share";
import SquareIcon from "@mui/icons-material/Square";
import AlertIcon from "@mui/icons-material/Warning";
import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  IconButton,
  Link as MaterialLink,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { format } from "date-fns/fp";
import gql from "graphql-tag";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useUpdateEffect } from "react-use";
import { makeStyles } from "tss-react/mui";
import { StringParam, useQueryParam } from "use-query-params";

import {
  generateExternalLink,
  generateLiveShareUrl,
  generateVodShareUrl,
} from "@/util/camera";
import { formatShortDuration } from "@/util/date";
import { pluralize } from "@/util/pluralize";
import { useBreakpoints } from "@/util/useBreakpoints";
import { usePagination } from "@/util/usePagination";
import { useSlugMatch } from "@/util/useSlugMatch";

import { CopyTextButton } from "@/components/CopyTextButton";
import { RelativeDatePicker } from "@/components/DateTimePicker/RelativeDatePicker";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { determineLinkSharingActive } from "@/components/Player/SharingLinkDialog";
import { GrowingSearchBox, SearchBox } from "@/components/SearchBox";
import { LiveStatusIndicator } from "@/components/StatusIndicators";
import { createStyledTabs } from "@/components/StyledTabs/StyledTabs";
// import { SelectInlineLabel } from "../shared/SelectInlineLabel";
import { DefaultDialog, useDialog } from "@/components/shared/Dialog";
import { NoStillsThumb } from "@/components/shared/NoStillsThumb";
import { SyncedToCloudIndicator } from "@/components/shared/SyncedToCloudIndicator";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  Maybe,
  Page_SharesDocument,
  Page_SharesQuery,
  useDeleteCameraSharingMutation,
  useDeleteSharedClipsMutation,
  useDisableCameraSharingMutation,
  useExpireSharedClipsMutation,
  usePage_SharesQuery,
  useSharedClipWithStillsQuery,
  useUpdateCameraSharingInfoMutation,
  useUpdateSharedClipMutation,
} from "@/generated-models";
import { usePrefixOrgSlug } from "@/hooks/useOrgRouteBase";
import { usePermissions } from "@/hooks/usePermissions";
import { ContentWrapper } from "@/layout/ContentWrapper";
import { MobileHeader } from "@/layout/MobileHeader";

import { useDocumentTitle } from "../../util/useDocumentTitle";

const { NavTabs, NavTab } = createStyledTabs({
  tab: { root: { fontSize: 14 } },
});

const useStyles = makeStyles<void, "openInNewTabIcon" | "syncedToCloudIcon">()(
  (theme, _props, classes) => ({
    container: {
      [theme.breakpoints.down("md")]: {
        padding: 0,
      },
    },
    bulkControls: {
      background: "#F6FBFF",
      borderRadius: 4,
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
      marginBottom: theme.spacing(1.5),
      marginTop: theme.spacing(1.5),
    },
    sharesCard: {
      display: "grid",
      gridTemplateColumns: "minmax(auto, 45%) 1fr",
      gap: `9px`,

      padding: 10,

      boxShadow: "0px 2px 11px rgba(152, 152, 152, 0.27)",

      "div > span": {
        position: "absolute",
        top: -2,
        left: -2,
      },
    },
    previewContainer: {
      position: "relative",
    },
    preview: {
      width: "100%",
      display: "block",
      "&>*": {
        borderRadius: 4,
        overflow: "hidden",
      },
    },
    checkbox: {
      padding: 4,
      color: "#b4b4b4",

      "& .MuiIconButton-label": {
        position: "relative",

        "&:before": {
          content: '""',
          display: "block",
          position: "absolute",
          top: 4,
          bottom: 4,
          left: 4,
          right: 4,
          background: "white",
        },

        "& svg": {
          position: "relative",
        },
      },
    },
    sharesCardColumn: {
      display: "flex",
      flexDirection: "column",
      minWidth: 0,

      "&:last-child": {
        gridColumn: "1 / 3",
      },
    },
    link: {
      color: "inherit",
      display: "flex",
      flexWrap: "nowrap",
      "&:hover, &:focus": {
        color: theme.palette.primary.main,
        [`& .${classes.openInNewTabIcon}`]: {
          display: "block",
        },
        [`& .${classes.syncedToCloudIcon}`]: {
          display: "none",
        },
      },

      [`& .${classes.openInNewTabIcon}`]: {
        display: "none",
      },
    },
    openInNewTabIcon: {},
    syncedToCloudIcon: {},
    linkPreview: {
      minWidth: 0,

      "& span": {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        width: "100%",
        display: "block",
      },
    },
    squareIconButton: {
      boxShadow: "none",
      padding: 0,
      minWidth: 44,
      borderRadius: 4,
    },
    dialogRoot: {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        margin: "0",
        minHeight: "100vh",
        "&": {
          /* mobile viewport bug fix */
          minHeight: "-webkit-fill-available",
        },
        maxHeight: "none",
        maxWidth: "none",
        borderRadius: "0",

        "& .MuiDialogContent-root": {
          flexGrow: 0,
        },
      },
    },
    deleteWarning: {
      background: "rgba(255,202,202,0.36)",
      color: "#9C1818",

      display: "grid",
      placeItems: "center",
      gap: "16px",
      padding: theme.spacing(2),
      gridTemplateColumns: "auto 1fr",

      "& svg": {
        color: "#EF0000",
      },
    },
  })
);

export function SharesList() {
  useDocumentTitle("Shares");
  const { classes } = useStyles();
  const prefixOrgSlug = usePrefixOrgSlug();
  const hasPermission = usePermissions();
  const { contextualPlayer } = useFlags();
  const { fitsTablet, fitsDesktop } = useBreakpoints();
  const navigate = useNavigate();
  const location = useLocation();
  const routeMatch = useSlugMatch("shares/:tab");
  const tab = routeMatch?.params.tab as "active" | "expired" | undefined;
  if (tab && tab !== "expired") {
    navigate(".", { replace: true });
  }
  const [searchInputParam, setSearchInputParam] = useQueryParam(
    "search",
    StringParam
  );
  const setSearchParam = (value?: string | null) =>
    setSearchInputParam(value || undefined);

  const { pageCount, reset, visibilitySensor } = usePagination(1);
  useUpdateEffect(() => reset(), [tab, reset]);
  const { data, loading, error } = usePage_SharesQuery(refetchOnMountPolicy);
  const [updateSharedClip] = useUpdateSharedClipMutation();
  const [deleteSharedClips] = useDeleteSharedClipsMutation();
  const [expireSharedClips] = useExpireSharedClipsMutation();
  const [disableCameraSharing] = useDisableCameraSharingMutation();
  const [deleteCameraSharing] = useDeleteCameraSharingMutation();
  const [updateSharingInfo] = useUpdateCameraSharingInfoMutation();

  const normalized = useMemo(
    () =>
      data && [
        ...(hasPermission("video_vod_access")
          ? data?.sharedClips.map((clip) => ({
              typename: clip.__typename,
              id: clip.id,
              cameraName: clip.camera.name,
              locationName: clip.camera.location.name,
              timezone: clip.camera.location.timezone,
              createdAt: clip.createdAt,
              createdBy: clip.createdBy,
              shareToken: clip.shareToken,
              expiry: clip.expiry,

              // shared clip only
              viewCount: clip.viewCount,
              duration: formatShortDuration(
                new Date(clip.startTime),
                new Date(clip.endTime)
              ),
              syncedToCloud: clip.syncedToCloud,

              // camera only
              status: null,
              still: null,
            }))
          : []),
        ...(hasPermission("video_live_access")
          ? data?.sharedCameras.map((camera) => ({
              typename: camera.__typename,
              id: camera.id,
              cameraName: camera.name,
              locationName: camera.location.name,
              timezone: camera.location.timezone,
              createdAt: camera.sharing!.createdAt,
              createdBy: camera.sharing!.createdBy,
              shareToken: camera.sharing!.shareToken,
              expiry: camera.sharing!.expiry,

              // shared clip only
              viewCount: null,
              duration: null,
              syncedToCloud: null,

              // camera only
              status: camera.status,
              still: camera.still,
            }))
          : []),
      ],
    [data, hasPermission]
  );
  const active = normalized?.filter((s) =>
    determineLinkSharingActive(s.expiry)
  );
  const inactive = normalized?.filter(
    (s) => !determineLinkSharingActive(s.expiry)
  );
  const listForTab = tab === "expired" ? inactive : active;

  const filteredBySearch = !searchInputParam
    ? listForTab
    : listForTab?.filter((s) =>
        [s.cameraName, s.locationName, s.createdBy].some((field) =>
          field?.toLowerCase().includes(searchInputParam.toLowerCase())
        )
      );

  const {
    selection,
    setSelection,
    selectAll,
    selectNone,
    selectedAll,
    toggle: toggleSelection,
    selectionIdentities,
    identify: identifyShare,
  } = useBulkSelection(filteredBySearch, [tab], (s) => `${s.typename}:${s.id}`);

  let selectedClipCount = 0;
  let selectedCameraCount = 0;
  selection.forEach((s) => {
    if (s.typename === "Camera") {
      selectedCameraCount++;
    } else {
      selectedClipCount++;
    }
  });

  const renderedShares = filteredBySearch
    ?.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
    .slice(0, pageCount * 30);
  type SharedItem = NonNullable<typeof normalized>[number];
  const { open: openClipDeletion, ...clipDialogProps } = useDialog<
    SharedItem
  >();
  const { open: openLiveFeedDeletion, ...liveFeedDialogProps } = useDialog<
    SharedItem
  >();
  const { open: openBulkDeletion, ...bulkDeleteDialogProps } = useDialog();

  useEffect(() => {
    if (selection.size === 0) {
      bulkDeleteDialogProps.cancel();
    }
    // eslint-disable-next-line
  }, [selection.size]);

  const {
    expireSelectedClips,
    deleteSelectedClips,
    expireSelectedCameras,
    deleteSelectedCameras,
  } = useBulkActions(selection);

  return (
    <ContentWrapper>
      <Container maxWidth="lg" className={classes.container}>
        <Paper square elevation={3}>
          {fitsDesktop && (
            <div className="flex items-center p-4 justify-between">
              <Typography variant="h1">Shares</Typography>
              <Box m={1} flexGrow={1} />
              {filteredBySearch && searchInputParam && (
                <>
                  <Typography style={{ opacity: 0.5 }} variant="body2">
                    {filteredBySearch.length} matching{" "}
                    {pluralize(
                      { 1: "result", multi: "results" },
                      filteredBySearch.length
                    )}
                  </Typography>
                  <Box m={1} />
                </>
              )}
              <SearchBox
                input={searchInputParam ?? ""}
                setInput={setSearchParam}
              />
            </div>
          )}
          <MobileHeader
            label="Shares"
            action={
              <div className="mr-3">
                <GrowingSearchBox
                  input={searchInputParam ?? null}
                  setInput={setSearchParam}
                />
              </div>
            }
          />
          <article className="p-3 md:shadow-divider">
            <NavTabs
              value={routeMatch?.params.tab || "active"}
              variant={fitsTablet ? undefined : "fullWidth"}
            >
              <NavTab
                value="active"
                label={labelWithCount("Active", active?.length ?? 0)}
                component={Link}
                to={`.${location.search}`}
              />
              <NavTab
                value="expired"
                label={labelWithCount("Expired", inactive?.length ?? 0)}
                component={Link}
                to={`./expired${location.search}`}
              />
            </NavTabs>
            {selection.size > 0 && (
              <Box className={classes.bulkControls}>
                <Tooltip title={selectedAll ? "Select None" : "Select All"}>
                  <Checkbox
                    color="primary"
                    indeterminate={!selectedAll}
                    checked
                    onChange={(e) => {
                      if (!selectedAll) {
                        selectAll();
                      } else {
                        selectNone();
                      }
                    }}
                    classes={{ root: classes.checkbox }}
                  />
                </Tooltip>
                <Box m={1} />
                <Typography>
                  <strong>{selection.size}</strong> Selected{" "}
                  {pluralize({ 1: "Share", multi: "Shares" }, selection.size)}
                </Typography>
                <Box m={3} />
                <div className="flex flex-nowrap items-center">
                  <Button color="primary" onClick={selectAll}>
                    Select All
                  </Button>
                  <Divider
                    orientation="vertical"
                    style={{ height: 12, margin: "0 8px" }}
                  />
                  <Button color="primary" onClick={selectNone}>
                    Select None
                  </Button>
                </div>
                <Box flexGrow={1} />
                <div className="flex flex-nowrap items-center">
                  <Button color="primary" onClick={openBulkDeletion}>
                    <DeleteIcon style={{ opacity: 0.4 }} />
                    <Box m={0.5} />
                    Delete
                  </Button>
                </div>
              </Box>
            )}
            {error ? (
              <ErrorMessage
                title="Unable to load shares"
                description="Please try again later"
              />
            ) : loading ? (
              <Loading>Fetching Shares</Loading>
            ) : !renderedShares?.length ? (
              searchInputParam && listForTab?.length ? (
                <NoResultsState searchQuery={searchInputParam} />
              ) : (
                <EmptyState />
              )
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {renderedShares?.map((s) => {
                  const isCamera = s.typename === "Camera";
                  const url = prefixOrgSlug(
                    (isCamera ? generateLiveShareUrl : generateVodShareUrl)(
                      s.shareToken!
                    )
                  );

                  const shareUrl = generateExternalLink(
                    prefixOrgSlug(
                      (isCamera ? generateLiveShareUrl : generateVodShareUrl)(
                        s.shareToken!
                      )
                    )
                  );

                  const ShareThumb = () =>
                    s.still ? (
                      <img src={s.still} alt="" style={{ width: "100%" }} />
                    ) : (
                      <SharedClipSnippetPreview id={s.id} />
                    );

                  const ShareText = () => (
                    <>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          textTransform: "capitalize",
                        }}
                        variant="body2"
                      >
                        {s.duration ? (
                          <>{s.duration} Video Segment</>
                        ) : (
                          <>
                            <LiveStatusIndicator status={s.status!} />
                            <strong>Live</strong> Camera Feed
                          </>
                        )}
                      </Typography>
                      <Box flexGrow={1} m={1} />
                      <Tooltip
                        title="Open in new Tab"
                        className={classes.openInNewTabIcon}
                      >
                        <OpenInNewTabIcon fontSize="small" />
                      </Tooltip>
                      {s.syncedToCloud != null && (
                        <SyncedToCloudIndicator
                          className={classes.syncedToCloudIcon}
                          syncedToCloud={s.syncedToCloud}
                          IconProps={{
                            style: {
                              fontSize: 16,
                              marginBottom: -3,
                              marginLeft: 6,
                            },
                          }}
                        />
                      )}
                    </>
                  );

                  return (
                    <Paper
                      data-cy="shared-clip"
                      key={`${s.typename}:${s.id}`}
                      elevation={3}
                      className={classes.sharesCard}
                    >
                      <Box className={classes.previewContainer}>
                        {contextualPlayer && !fitsDesktop ? (
                          <MaterialLink
                            className={classes.preview}
                            component={Link}
                            to={url}
                            underline="hover"
                          >
                            <ShareThumb />
                          </MaterialLink>
                        ) : (
                          <MaterialLink
                            className={classes.preview}
                            href={url}
                            target="_blank"
                            underline="hover"
                          >
                            <ShareThumb />
                          </MaterialLink>
                        )}
                        <Checkbox
                          icon={
                            <SquareIcon className="bg-gray-400/50 text-white" />
                          }
                          color="primary"
                          checked={selectionIdentities.has(identifyShare(s))}
                          onChange={(e) => toggleSelection(e, s)}
                          classes={{ root: classes.checkbox }}
                        />
                      </Box>
                      <Box className={classes.sharesCardColumn}>
                        {contextualPlayer && !fitsDesktop ? (
                          <MaterialLink
                            to={url}
                            component={Link}
                            className={classes.link}
                            style={{
                              opacity: 0.7,
                            }}
                            underline="hover"
                          >
                            <ShareText />
                          </MaterialLink>
                        ) : (
                          <MaterialLink
                            href={url}
                            target="_blank"
                            className={classes.link}
                            style={{
                              opacity: 0.7,
                            }}
                            underline="hover"
                          >
                            <ShareText />
                          </MaterialLink>
                        )}
                        <Typography
                          variant="body2"
                          style={{ fontSize: 12, opacity: 0.7 }}
                        >
                          Shared on {formatCreatedAt(new Date(s.createdAt))}
                        </Typography>
                        {s.createdBy && (
                          <Typography
                            variant="body2"
                            style={{ fontSize: 12, opacity: 0.7 }}
                          >
                            by {s.createdBy}
                          </Typography>
                        )}
                        {s.viewCount != null && (
                          <>
                            <Box m={0.25} />
                            <Typography
                              variant="body2"
                              style={{ fontSize: 12, opacity: 0.7 }}
                            >
                              {pluralize(
                                {
                                  multi: (
                                    <>
                                      <strong>{s.viewCount}</strong> views
                                    </>
                                  ),
                                  1: (
                                    <>
                                      <strong>1</strong> view
                                    </>
                                  ),
                                  0: <>no views</>,
                                },
                                s.viewCount
                              )}
                            </Typography>
                          </>
                        )}
                      </Box>
                      <Box className={classes.sharesCardColumn}>
                        <Box display="flex" alignItems="center">
                          <Typography style={{ fontWeight: "bold" }}>
                            {s.cameraName}
                          </Typography>
                        </Box>
                        <Typography variant="body2">
                          {s.locationName}
                        </Typography>

                        <Box m={1} />
                        <Box display="flex">
                          <CopyTextButton
                            className={classes.squareIconButton}
                            fontSize={16}
                            text={shareUrl}
                          />
                          <Box m={0.5} />
                          <RelativeDatePicker
                            FormControlProps={{ size: "small" }}
                            label="Clip Expiration"
                            value={s.expiry ? new Date(s.expiry) : null}
                            onChange={(expiry) => {
                              if (s.typename === "SharedClip") {
                                updateSharedClip({
                                  variables: {
                                    id: s.id,
                                    update: {
                                      // Please don't convert this to optional chaining
                                      // because that will coerce null to undefined, which
                                      // means something else in the context of this mutation
                                      expiry: expiry && expiry.toISOString(),
                                    },
                                  },
                                });
                              } else {
                                updateSharingInfo({
                                  variables: {
                                    id: s.id,
                                    updates: {
                                      // Please don't convert this to optional chaining
                                      // because that will coerce null to undefined, which
                                      // means something else in the context of this mutation
                                      expiry: expiry && expiry.toISOString(),
                                    },
                                  },
                                });
                              }
                            }}
                            disableImmediately={() => {
                              if (s.typename === "SharedClip") {
                                expireSharedClips({
                                  variables: { ids: [s.id] },
                                });
                              } else {
                                disableCameraSharing({
                                  variables: { ids: [s.id] },
                                });
                              }
                            }}
                          />
                          <Box m={0.5} />
                          <Tooltip title="Delete">
                            <IconButton
                              className={classes.squareIconButton}
                              onClick={async () => {
                                if (s.typename === "Camera") {
                                  const confirmed = await openLiveFeedDeletion(
                                    s
                                  );
                                  if (!confirmed) return;

                                  await deleteCameraSharing({
                                    variables: {
                                      ids: [s.id],
                                    },
                                    update: (store) => {
                                      const {
                                        sharedCameras,
                                        sharedClips,
                                      } = store.readQuery<Page_SharesQuery>({
                                        query: Page_SharesDocument,
                                      })!;

                                      store.writeQuery<Page_SharesQuery>({
                                        query: Page_SharesDocument,
                                        data: {
                                          __typename: "Query",
                                          sharedClips,
                                          sharedCameras: sharedCameras.filter(
                                            (camera) => s.id !== camera.id
                                          ),
                                        },
                                      });
                                    },
                                  });
                                } else {
                                  const confirmed = await openClipDeletion(s);
                                  if (!confirmed) return;

                                  await deleteSharedClips({
                                    variables: { ids: [s.id] },
                                    update: (store) => {
                                      store.evict({
                                        id: store.identify({
                                          __typename: s.typename,
                                          id: s.id,
                                        }),
                                      });
                                    },
                                  });
                                }
                              }}
                              size="large"
                            >
                              <DeleteIcon
                                fontSize="small"
                                style={{ opacity: 0.5 }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Box>
                    </Paper>
                  );
                })}
              </div>
            )}

            <DefaultDialog
              classes={{
                paper: classes.dialogRoot,
              }}
              {...clipDialogProps}
              title="Delete Shared Clip"
              content={
                <>
                  <Box className={classes.deleteWarning}>
                    <AlertIcon fontSize="large" />
                    <Typography>
                      This shared clip will be{" "}
                      <strong>permanently deleted</strong> after the data
                      retention period expires.
                    </Typography>
                  </Box>
                  {tab !== "expired" && (
                    <>
                      {" "}
                      <Box m={2} />
                      If you want to keep the clip, expire the clip instead
                      which hides it from public viewing but keeps the clip in
                      your shared clips list.
                    </>
                  )}
                </>
              }
              additionalActions={
                tab !== "expired" && (
                  <Button
                    onClick={() => {
                      expireSharedClips({
                        variables: { ids: [clipDialogProps.context.id] },
                      });
                      clipDialogProps.cancel();
                    }}
                    variant="outlined"
                    color="primary"
                  >
                    Expire
                  </Button>
                )
              }
              confirmText="Permanently Delete"
            />
            <DefaultDialog
              classes={{
                paper: classes.dialogRoot,
              }}
              {...liveFeedDialogProps}
              title="Delete Shared Live Feed"
              content={
                <>
                  <Box className={classes.deleteWarning}>
                    <AlertIcon fontSize="large" />
                    <Typography>
                      This shared live feed will be{" "}
                      <strong>permanently deleted</strong> and any links that
                      you've shared previously will stop working.
                    </Typography>
                  </Box>
                  <Box m={2} />
                  This will delete the live camera feed sharing preventing
                  public viewing of the camera feed.
                  {tab !== "expired" && (
                    <>
                      {" "}
                      If you want to retain the existing links, you can expire
                      the shared live feeds instead.
                    </>
                  )}
                </>
              }
              additionalActions={
                tab !== "expired" && (
                  <Button
                    onClick={() => {
                      disableCameraSharing({
                        variables: { ids: [liveFeedDialogProps.context.id] },
                      });
                      liveFeedDialogProps.cancel();
                    }}
                    variant="outlined"
                    color="primary"
                  >
                    Expire
                  </Button>
                )
              }
              confirmText="Delete Live Feed"
            />
            <DefaultDialog
              classes={{
                paper: classes.dialogRoot,
              }}
              {...bulkDeleteDialogProps}
              title="Delete Multiple Items"
              content={
                <>
                  {selectedClipCount > 0 && (
                    <>
                      <Typography variant="h3">
                        {selectedClipCount} Shared{" "}
                        {pluralize(
                          { 1: "Clip", multi: "Clips" },
                          selectedClipCount
                        )}
                      </Typography>
                      <Box m={2} />
                      <Box className={classes.deleteWarning}>
                        <AlertIcon fontSize="large" />
                        <Typography>
                          <strong>
                            {selectedClipCount} Shared{" "}
                            {pluralize(
                              { 1: "Clip", multi: "Clips" },
                              selectedClipCount
                            )}
                          </strong>{" "}
                          will be <strong>permanently deleted</strong> after the
                          data retention period expires.
                        </Typography>
                      </Box>
                      <Box m={2} />

                      {tab !== "expired" && (
                        <Typography>
                          If you want to keep the clip, expire the clip instead
                          which hides it from public viewing but keeps the clip
                          in your shared clips list.
                        </Typography>
                      )}
                      <Box m={2} />
                      <Box display="flex">
                        {tab !== "expired" && (
                          <Button
                            onClick={expireSelectedClips}
                            variant="outlined"
                            color="primary"
                          >
                            Expire
                          </Button>
                        )}
                        <Box flexGrow={1} />
                        <Button
                          onClick={() => {
                            // On cancel, deselect all shared clips
                            setSelection(
                              (currentSelection) =>
                                new Set(
                                  [...currentSelection].filter(
                                    (s) => s.typename === "Camera"
                                  )
                                )
                            );
                          }}
                          variant="outlined"
                          color="primary"
                        >
                          Cancel
                        </Button>
                        <Box m={0.5} />
                        <Button
                          onClick={deleteSelectedClips}
                          variant="contained"
                          color="primary"
                        >
                          Permanently Delete
                        </Button>
                      </Box>
                    </>
                  )}

                  {selectedCameraCount > 0 && selectedClipCount > 0 && (
                    <Divider style={{ margin: "20px 0" }} />
                  )}
                  {selectedCameraCount > 0 && (
                    <>
                      <Typography variant="h3">
                        Delete {selectedCameraCount} Live{" "}
                        {pluralize(
                          { 1: "Feed", multi: "Feeds" },
                          selectedCameraCount
                        )}
                      </Typography>
                      <Box m={2} />
                      <Box className={classes.deleteWarning}>
                        <AlertIcon fontSize="large" />
                        <Typography>
                          <strong>
                            {selectedCameraCount} Live{" "}
                            {pluralize(
                              { 1: "Feed", multi: "Feeds" },
                              selectedCameraCount
                            )}
                          </strong>{" "}
                          will be <strong>permanently deleted</strong> and any
                          links that you've shared previously will stop working.
                        </Typography>
                      </Box>
                      <Box m={2} />
                      <Typography>
                        This will delete the live camera feed sharing preventing
                        public viewing of the camera feed.
                        {tab !== "expired" && (
                          <>
                            {" "}
                            If you want to retain the existing links, you can
                            expire the shared live feeds instead.
                          </>
                        )}
                      </Typography>
                      <Box m={2} />
                      <Box display="flex">
                        {tab !== "expired" && (
                          <Button
                            onClick={expireSelectedCameras}
                            variant="outlined"
                            color="primary"
                          >
                            Expire
                          </Button>
                        )}
                        <Box flexGrow={1} />
                        <Button
                          onClick={() => {
                            // On cancel, deselect all shared clips
                            setSelection(
                              (currentSelection) =>
                                new Set(
                                  [...currentSelection].filter(
                                    (s) => s.typename === "SharedClip"
                                  )
                                )
                            );
                          }}
                          variant="outlined"
                          color="primary"
                        >
                          Cancel
                        </Button>
                        <Box m={0.5} />
                        <Button
                          onClick={deleteSelectedCameras}
                          variant="contained"
                          color="primary"
                        >
                          Delete Live{" "}
                          {pluralize(
                            { 1: "Feed", multi: "Feeds" },
                            selectedCameraCount
                          )}
                        </Button>
                      </Box>
                    </>
                  )}
                  <Box m={2} />
                </>
              }
              hideDefaultActionButtons
            />

            {visibilitySensor}
          </article>
        </Paper>
      </Container>
    </ContentWrapper>
  );
}

const useEmptyStateStyles = makeStyles()((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    minHeight: 360,
  },
  headline: {
    fontWeight: 300,
    fontSize: 44,
    color: theme.palette.primary.main,
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
  },
}));

function EmptyState() {
  const { classes } = useEmptyStateStyles();
  return (
    <Container className={classes.container} maxWidth="md">
      <Typography variant="h1" className={classes.headline}>
        Your shares will appear here.
      </Typography>
      <Box m={2} />
      <Typography style={{ fontSize: 18, maxWidth: 650 }}>
        Share clips and live streams at any time by clicking the{" "}
        <ShareIcon
          fontSize="small"
          color="primary"
          style={{ margin: "0 6px -4px 3px" }}
        />
        share button while viewing video.{" "}
        <Link to="../cases" className={classes.link}>
          Cases
        </Link>{" "}
        also allow you to share multiple clips with even more context,
        annotation, and discussion.
      </Typography>
    </Container>
  );
}

function NoResultsState({ searchQuery }: { searchQuery: string }) {
  const { classes } = useEmptyStateStyles();
  const location = useLocation();
  return (
    <Container className={classes.container} maxWidth="md">
      <Typography variant="h1" className={classes.headline}>
        No results found
      </Typography>
      <Box m={2} />
      <Typography style={{ fontSize: 18, maxWidth: 650 }}>
        No results were found for the search term "<em>{searchQuery}</em>".{" "}
        <Link className={classes.link} to={location.pathname} replace>
          Clear the search
        </Link>{" "}
        to view all shares.
      </Typography>
    </Container>
  );
}

function useBulkSelection<T>(
  values: Maybe<T[]> | undefined,
  resetDependencies: any[],
  identify: (v: T) => string
) {
  const [selection, setSelection] = useState(new Set<T>());
  const recomputeDependencies = [values?.map(identify).join(",")];

  useEffect(() => {
    setSelection(new Set());
    // eslint-disable-next-line
  }, resetDependencies);

  useEffect(() => {
    setSelection(
      (selection) =>
        new Set(
          [...selection].filter((v) =>
            values?.some((candidate) => identify(candidate) === identify(v))
          )
        )
    );
    // eslint-disable-next-line
  }, recomputeDependencies);
  const selectedAll = selection.size === values?.length;
  const selectAll = useCallback(() => {
    if (!values) return;
    setSelection(new Set(values));
    // eslint-disable-next-line
  }, recomputeDependencies);
  const selectNone = useCallback(() => {
    setSelection(new Set());
    // eslint-disable-next-line
  }, []);
  const toggle = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, share: T) => {
      const checked = event.target.checked;
      setSelection((oldSelection) => {
        const clone = new Set(oldSelection);
        if (checked) clone.add(share);
        else clone.delete(share);

        return clone;
      });
    },
    [setSelection]
  );

  return {
    selection,
    selectionIdentities: new Set([...selection].map(identify)),
    setSelection,
    toggle,
    identify,
    selectAll,
    selectedAll,
    selectNone,
  };
}

function useBulkActions<
  T extends { typename: "SharedClip" | "Camera"; id: number }
>(selection: Set<T>) {
  const [deleteSharedClips] = useDeleteSharedClipsMutation();
  const [expireSharedClips] = useExpireSharedClipsMutation();
  const [disableCameraSharing] = useDisableCameraSharingMutation();
  const [deleteCameraSharing] = useDeleteCameraSharingMutation();

  const selectionArray = [...selection.values()];
  const sharedClipIds = selectionArray
    .filter((s) => s.typename === "SharedClip")
    .map((s) => s.id);
  const cameraIds = selectionArray
    .filter((s) => s.typename === "Camera")
    .map((s) => s.id);

  function expireSelectedClips() {
    return expireSharedClips({
      variables: { ids: sharedClipIds },
    });
  }

  function deleteSelectedClips() {
    deleteSharedClips({
      variables: { ids: sharedClipIds },
      update: (store) => {
        sharedClipIds.forEach((id) =>
          store.evict({
            id: store.identify({
              id,
              __typename: "SharedClip",
            }),
          })
        );
      },
    });
  }

  function expireSelectedCameras() {
    return disableCameraSharing({
      variables: { ids: cameraIds },
    });
  }

  function deleteSelectedCameras() {
    return deleteCameraSharing({
      variables: { ids: cameraIds },
      update: (store) => {
        const { sharedCameras, sharedClips } = store.readQuery<
          Page_SharesQuery
        >({
          query: Page_SharesDocument,
        })!;

        store.writeQuery<Page_SharesQuery>({
          query: Page_SharesDocument,
          data: {
            __typename: "Query",
            sharedClips,
            sharedCameras: sharedCameras.filter(
              (s) => !cameraIds.includes(s.id)
            ),
          },
        });
      },
    });
  }

  return {
    expireSelectedClips,
    deleteSelectedClips,
    expireSelectedCameras,
    deleteSelectedCameras,
  };
}

const formatCreatedAt = format("MMM d, yyyy");

function labelWithCount(label: string, count?: number) {
  if (count) return `${label} (${count})`;
  return label;
}

function SharedClipSnippetPreview({ id }: { id: number }) {
  const [limit] = useState<number | null>(1);
  const { data } = useSharedClipWithStillsQuery({
    variables: { id, limit },
  });
  const stills = useCachedCoalesce(data?.sharedClipById.stills ?? null);

  if (stills?.length === 0) {
    return <NoStillsThumb />;
  }

  return <img src={stills?.[0]?.src ?? ""} alt="" style={{ width: "100%" }} />;
}

function useCachedCoalesce<T>(value: T) {
  const [cache, setCache] = useState(value);
  useEffect(() => {
    if (value) setCache(value);
  }, [value, setCache]);

  return cache;
}

gql`
  query page_shares {
    sharedClips {
      id
      shareToken
      description
      expiry
      viewCount
      createdBy
      createdAt
      startTime
      endTime
      syncedToCloud
      camera {
        name
        location {
          name
          timezone
        }
      }
    }
    sharedCameras {
      id
      name
      status
      still
      sharing {
        id
        shareToken
        description
        expiry
        createdBy
        createdAt
      }

      location {
        id
        name
        timezone
      }
    }
  }
`;

gql`
  query sharedClipWithStills($id: Int!, $limit: Int) {
    sharedClipById(id: $id) {
      id
      stills(limit: $limit) {
        src
        timestamp
      }
    }
  }
`;

gql`
  mutation deleteSharedClips($ids: [Int!]!) {
    deleteSharedClips(ids: $ids)
  }
`;

gql`
  mutation expireSharedClips($ids: [Int!]!) {
    expireSharedClips(ids: $ids) {
      id
      expiry
    }
  }
`;

gql`
  mutation disableCameraSharing($ids: [Int!]!) {
    disableCameraSharing(ids: $ids) {
      id
      sharing {
        id
        expiry
      }
    }
  }
`;

gql`
  mutation deleteCameraSharing($ids: [Int!]!) {
    deleteCameraSharing(ids: $ids)
  }
`;
