import clsx from "clsx";
import { makeStyles } from "tss-react/mui";

import { useVideoWallContext } from "@/pages/VideoWall/useVideoWallContext";

import { GridContainer, GridContainerProps } from "@/components/shared/Grid";

const useHeaderStyles = makeStyles()((theme) => ({
  wallHeader: {
    backgroundColor: theme.palette.background.default,
    gridTemplateColumns: "1fr auto",
    "&.fullscreen": {
      gridTemplateColumns: "repeat(3, 1fr)",

      "& :not(:first-of-type):not(:last-child)": {
        justifySelf: "center",
      },
    },
  },
}));

export function VideoWallHeader({ className, ...props }: GridContainerProps) {
  const { classes } = useHeaderStyles();
  const { fullscreenActive } = useVideoWallContext();

  return (
    <GridContainer
      {...props}
      className={clsx(
        "p-2 sm:p-4 items-center gap-1",
        classes.wallHeader,
        { fullscreen: fullscreenActive },
        className
      )}
    />
  );
}
