import { Typography } from "@mui/material";
import { lazy, ReactNode } from "react";

const ErrorDots = lazy(() => import("../Animation/ErrorDots"));

interface MobilePlayerErrorProps {
  title: string;
  description: ReactNode;
}

const MobilePlayerError = ({ title, description }: MobilePlayerErrorProps) => {
  return (
    <div role="alert" className="flex-center flex-col h-full">
      <div className="mr-11">
        <ErrorDots width={100} height={80} />
      </div>
      <Typography variant="h2" className="mb-2 text-center text-xl">
        {title}
      </Typography>

      {typeof description === "string" ? (
        <Typography className="w-full text-center overflow-hidden text-ellipsis whitespace-nowrap">
          {description}
        </Typography>
      ) : (
        description
      )}
    </div>
  );
};

export default MobilePlayerError;
