import {
  endOfMonth,
  endOfWeek,
  format,
  startOfMonth,
  startOfWeek,
  addDays,
} from "date-fns/fp";
import gql from "graphql-tag";
import { SetStateAction, useCallback, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  DateParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

import { useLocalStorage } from "@/util/useLocalStorage";

import { useApplianceLprEnabledQuery } from "@/generated-models";

import { Range } from "./IntelligenceDashboardView";

const today = new Date();
const tomorrow = addDays(1, today);

export function useIntelligenceDateRange() {
  const { id } = useParams();
  const [localConfig, setLocalConfig] = useLocalStorage(
    `intelligence-date-config-${id || "default"}`,
    {
      start: today,
      end: tomorrow,
      range: Range.Day,
    }
  );

  const DateStartQueryParam = withDefault(
    DateParam,
    localConfig ? new Date(localConfig.start) : today
  );

  const DateEndQueryParam = withDefault(
    DateParam,
    localConfig ? new Date(localConfig.end) : today
  );

  const DateRangeQueryParam = withDefault(
    StringParam,
    localConfig?.range ?? Range.Day
  );

  const [dateConfig, setDateConfig] = useQueryParams({
    date: DateStartQueryParam,
    "date-end": DateEndQueryParam,
    range: DateRangeQueryParam,
  });

  const date = useMemo(() => dateConfig?.date ?? today, [dateConfig]);
  const dateEnd = useMemo(() => dateConfig?.["date-end"] ?? today, [
    dateConfig,
  ]);
  const range = dateConfig?.range ?? Range.Day;

  useEffect(() => {
    if (localConfig) {
      setDateConfig(
        {
          date: new Date(localConfig.start),
          "date-end": new Date(localConfig.end),
          range: localConfig?.range,
        },
        "replaceIn"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDate = useCallback(
    (value: SetStateAction<Date | null | undefined>) => {
      setLocalConfig((cfg) => ({
        ...cfg,
        start: value as Date,
      }));
      return setDateConfig(
        {
          date: value as Date,
        },
        "replaceIn"
      );
    },
    [setDateConfig, setLocalConfig]
  );

  const setDateEnd = useCallback(
    (value: SetStateAction<Date | null | undefined>) => {
      setLocalConfig((cfg) => ({
        ...cfg,
        end: value as Date,
      }));
      return setDateConfig(
        {
          "date-end": value as Date,
        },
        "replaceIn"
      );
    },
    [setDateConfig, setLocalConfig]
  );

  let startDate: Date;
  let endDate: Date;

  switch (range) {
    case Range.Month:
      startDate = startOfMonth(date);
      endDate = endOfMonth(date);
      break;
    case Range.Week:
      startDate = startOfWeek(date);
      endDate = endOfWeek(date);
      break;
    case Range.Custom:
      startDate = date;
      endDate = dateEnd ?? date;
      break;
    default:
      startDate = date;
      endDate = date;
  }

  const setRange = (value: Range | undefined) => {
    const result = value === Range.Day ? undefined : value;
    setLocalConfig((cfg) => ({
      ...cfg,
      range: result ?? Range.Day,
    }));

    if (value === Range.Custom) {
      return setDateConfig(
        {
          date: startDate,
          "date-end": endDate,
          range: result,
        },
        "replaceIn"
      );
    }
    return setDateConfig(
      {
        range: result,
      },
      "replaceIn"
    );
  };

  return {
    range,
    date,
    dateEnd,
    startDate,
    endDate,
    setRange,
    setDate,
    setDateEnd,
  };
}

export function useIntelligenceTimeframeText() {
  const { range, date, dateEnd } = useIntelligenceDateRange();

  switch (range) {
    case Range.Custom:
      return `${format("MMM d", date)} - ${format("MMM d, y", dateEnd)}`;
    case Range.Day:
      return format("PP", date);
    case Range.Week:
      return `${format("MMM d", startOfWeek(date))} - ${format(
        "MMM d, y",
        endOfWeek(date)
      )}`;
    case Range.Month:
      return format("MMM", date);
    default:
      return "";
  }
}

// Determines if the current org supports LPR based on:
// - Any appliance in the org has LPR enabled on it.
export function useLprEnabled() {
  const { data } = useApplianceLprEnabledQuery();

  return useMemo(() => Boolean(data?.appliances?.some((d) => d.lprEnabled)), [
    data,
  ]);
}

gql`
  query applianceLprEnabled {
    appliances {
      id
      lprEnabled
    }
  }
`;
