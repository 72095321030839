import clsx from "clsx";
import { uniq } from "lodash/fp";
import React, { CSSProperties, useEffect } from "react";

import { bestMatch } from "@/util/bestMatch";
import { useBreakpoints } from "@/util/useBreakpoints";

import { GridContainer } from "@/components/shared/Grid";

import { configs, WallConfig } from "../../pages/VideoWall/constants";
import useDebouncedResizeObserver from "./useDebouncedResizeObserver";
import useDebouncedWindowResize from "./useDebouncedWindowResize";

// const wallConfigurationMap = {
//   4: [2, 2],
//   6: [3, 2],
//   // 8: [2, 4],
//   9: [3, 3],
// } as const;

export const wallSizes = uniq(configs.map((c) => c.sizes.length));
export type WallSize = typeof wallSizes[number];
export function pickWallConfiguration(count: number, maxCount?: number) {
  const smallestFittingWallSize = wallSizes
    .sort((a, b) => a - b)
    .find((s) => s >= count && (!maxCount || s <= maxCount));

  return (
    smallestFittingWallSize &&
    configs.find((c) => c.sizes.length === smallestFittingWallSize)
  );
}

function calculateConfigurationArea(
  wrapperDimensions: { width: number; height: number },
  config: { columns: number; rows: number }
) {
  // Implementation taken from
  // https://stackoverflow.com/a/6565988/971091
  const { width: ws, height: hs } = wrapperDimensions;
  const wi = config.columns * tileWidthBase;
  const hi = config.rows * tileHeightBase;
  const ri = wi / hi;
  const rs = ws / hs;
  const [scaledWidth, scaledHeight] =
    rs > ri ? [(wi * hs) / hi, hs] : [ws, (hi * ws) / wi];

  return scaledWidth * scaledHeight;
}
const tileWidthBase = 16;
const tileHeightBase = 9;
const tileAspectRatio = tileWidthBase / tileHeightBase;
export function useWallGridLayout(
  configuration: WallConfig,
  filterableCount: number,
  wrapperDimensions: { width: number; height: number },
  autoLayout: boolean
) {
  const configurationRatio =
    (configuration.columns / configuration.rows) * tileAspectRatio;
  const wrapperRatio = wrapperDimensions.width / wrapperDimensions.height;

  function augmentOriginalLayout() {
    return {
      width: Math.min(1, configurationRatio / wrapperRatio),
      columns: configuration.columns,
      rows: configuration.rows,
      sizes: configuration.sizes,
      matchesOriginalConfiguration: true,
    };
  }

  if (!autoLayout) {
    return augmentOriginalLayout();
  }

  const tileCount = configuration.sizes.length;
  const filteredTileCount = Math.max(tileCount - filterableCount, 0) || 9; // fall back to 3x3 grid if no cams have been added yet
  const ratios = Array.from({ length: filteredTileCount }).map((_, i) => {
    const columns = i + 1;
    const rows = Math.ceil(filteredTileCount / columns);
    const ratio = (columns / rows) * tileAspectRatio;
    const width = Math.min(1, ratio / wrapperRatio);

    return {
      columns,
      rows,
      ratio,
      width,
      tileWidth: (width / columns) * wrapperDimensions.width,
    };
  });
  // Finds the auto layout that best matches the container aspect ratio
  const bestRatioMatch = bestMatch(
    ratios,
    (a, b) =>
      calculateConfigurationArea(wrapperDimensions, b) -
      calculateConfigurationArea(wrapperDimensions, a)
  )!;
  // Now pick the candidate that best distributes the tiles over the number of
  // rows and columns available.
  // e.g for a wide screen:
  //   tile count: 9
  //   best ratio match: 6 x 2 => this would leave the bottom row with 3 open slots
  //   best distribution: 5 x 2 => leaves the bottom row with only 1 open slot, looks better
  const candidates = ratios.filter((r) => r.rows === bestRatioMatch.rows);
  const autoConfig = bestMatch(
    candidates,
    (a, b) => Math.abs(a.columns - a.rows) - Math.abs(b.columns - b.rows)
  )!;

  // Final comparison of auto config vs original
  if (
    calculateConfigurationArea(wrapperDimensions, configuration) >
    calculateConfigurationArea(wrapperDimensions, autoConfig)
  ) {
    return augmentOriginalLayout();
  }

  return {
    width: autoConfig.width,
    columns: autoConfig.columns,
    rows: autoConfig.rows,
    sizes: Array.from(
      { length: autoConfig.rows * autoConfig.columns },
      () => 1
    ),
    matchesOriginalConfiguration:
      autoConfig.columns === configuration.columns &&
      autoConfig.rows === configuration.rows,
  };
}

const scrollingGridCols = `grid-cols-[repeat(auto-fit,minmax(var(--tile-min-width),_1fr))]`;
const tileMinWidth = 300;

export default function VideoWallGrid({
  config,
  children,
  autoLayout,
  filterableCamIndexes = [],
  fullscreen = false,
  onLayoutChange = () => {},
  preload = false,
}: React.PropsWithChildren<{
  config: WallConfig;
  autoLayout: boolean;
  filterableCamIndexes?: number[];
  fullscreen?: boolean;
  onLayoutChange?: (
    config: Pick<
      ReturnType<typeof useWallGridLayout>,
      "matchesOriginalConfiguration" | "columns" | "rows"
    >
  ) => void;
  preload?: boolean;
}>) {
  const { ref, width = 1, height = 1 } = useDebouncedResizeObserver(500);
  const { fitsDesktop } = useBreakpoints();
  const childrenArray = Array.isArray(children) ? children : [children];
  const {
    width: gridWidth,
    columns,
    rows,
    sizes,
    matchesOriginalConfiguration,
  } = useWallGridLayout(
    config,
    filterableCamIndexes.length,
    {
      width,
      height,
    },
    autoLayout
  );

  const size = useDebouncedWindowResize({ debounce: 250 });

  useEffect(() => {
    if (!onLayoutChange) return;
    onLayoutChange({
      matchesOriginalConfiguration,
      columns,
      rows,
    });
  }, [matchesOriginalConfiguration, columns, rows, onLayoutChange]);

  const availableWidth = size.width;
  const isContainedMode = (gridWidth * availableWidth) / columns > tileMinWidth;

  return (
    <>
      <div
        ref={ref}
        className={clsx(
          "dark grid place-items-center p-2",
          { "overflow-hidden content-center": isContainedMode },
          { "overflow-y-auto": !isContainedMode }
        )}
        style={
          preload
            ? {
                position: "absolute",
                opacity: 0,
                pointerEvents: "none",

                // Below for debugging
                // top: 0,
                // left: 0,
                // width: 200,
                // height: 200 / 16 * 9,
                // zIndex: 1,
              }
            : {
                background: fullscreen ? "#000" : "#fff",
              }
        }
      >
        <GridContainer
          className={clsx("grid", !isContainedMode && scrollingGridCols, {
            "gap-[1px]": fullscreen,
            "gap-2": !fullscreen,
          })}
          style={
            {
              width: isContainedMode ? `calc(${gridWidth} * 100%)` : `100%`,
              "--tile-min-width": `${tileMinWidth}px`,
              gridTemplateColumns: isContainedMode && `repeat(${columns}, 1fr)`,
              gridTemplateRows: isContainedMode && `repeat(${rows}, 1fr)`,
            } as CSSProperties
          }
        >
          {childrenArray
            .map(
              (c, i) =>
                c &&
                React.cloneElement(c as any, {
                  spanSize:
                    isContainedMode || (!autoLayout && fitsDesktop)
                      ? sizes[i]
                      : 1,
                  tileMinWidth: tileMinWidth,
                })
            )
            .filter(
              (_, i) =>
                matchesOriginalConfiguration ||
                !filterableCamIndexes.includes(i)
            )}
        </GridContainer>
      </div>
    </>
  );
}
