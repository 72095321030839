import { atom, useAtomValue } from "jotai";
import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

const kioskKeepAliveInterval = 30000;
const kioskKeepAliveURL = "https://127.0.0.1/kioskKeepAlive";

export const keepAliveAtom = atom(true);

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export function useKeepAlive() {
  const queryParams = useQuery();
  const sendKeepAlive = useAtomValue(keepAliveAtom);

  useEffect(() => {
    if (!sendKeepAlive || !queryParams.has("kioskKeepAlive")) {
      return;
    }

    let isUnmounted = false;
    let interval: NodeJS.Timeout | undefined;

    const ping = () => {
      fetch(kioskKeepAliveURL).catch((error) => {
        console.log("kioskKeepAlive Error: " + error);
      });
      interval = setTimeout(() => {
        if (!isUnmounted) {
          ping();
        }
      }, kioskKeepAliveInterval);
    };
    ping();

    return () => {
      isUnmounted = true;
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [queryParams, sendKeepAlive]);
}
