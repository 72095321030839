import { IconButton } from "@mui/material";
import React from "react";

import { useMultiPlayerControls } from "@/components/Player/PlayerBase";
import { ReplayIcon } from "@/components/Player/PlayerIcons";

import { PlayerTooltip } from "./PlayerTooltip";

export function ReplayButton({ fontSize = 24 }: { fontSize?: number }) {
  const { play } = useMultiPlayerControls();
  return (
    <PlayerTooltip title="Replay">
      <IconButton
        color="inherit"
        style={{ fontSize }}
        onClick={play}
        size="large"
      >
        <ReplayIcon fontSize="inherit" />
      </IconButton>
    </PlayerTooltip>
  );
}
