import {
  default as ErrorOutlineIcon,
  default as WarningIcon,
  default as CheckCircleIcon,
} from "@mui/icons-material/ErrorOutline";
import { Button, CircularProgress } from "@mui/material";
import clsx from "clsx";

import { useIsValidStream } from "@/util/validation/stream";

import { CamLifecycleButtons } from "@/pages/Settings/LocationSettings/Camera/CamLifecycleButtons";

import {
  useAddCamera,
  useUpdateCamera,
} from "@/components/Genius/Forms/Camera/cameraHooks";
import { useLocationCapacity } from "@/components/Genius/LocationCapacityGuard";

import {
  CameraStatus,
  DeviceScanByIdQuery,
  DeviceStream,
  LifecycleStates,
} from "@/generated-models";

import { MaxCapacityTooltip } from "../Genius/MaxCapacityTooltip";

interface StreamActivationActionsProps {
  deviceName: string;
  deviceVendor: string;
  aiEnabled: boolean;
  selectedStreamId?: number;
  channel?: NonNullable<DeviceScanByIdQuery["deviceScan"]>["channels"][number];
  onChange?: (newStreamId?: number) => void;
  onClose?: () => void;
  disabledActivateCamera: boolean;
}

export function StreamActivationActions({
  deviceName,
  deviceVendor,
  aiEnabled,
  selectedStreamId,
  channel,
  onChange = () => {},
  onClose = () => {},
  disabledActivateCamera,
}: StreamActivationActionsProps) {
  const connectedStream = channel?.streams.find((stream) => stream.camera);
  const camera = connectedStream?.camera;

  const {
    locationId,
    maxCapacityReached,
    maxAICapacityReached,
    guardCapacityAndShowModal,
  } = useLocationCapacity();
  const selectedStream = channel?.streams.find(
    (s) => s.id === selectedStreamId
  );

  const pathError = useIsValidStream(selectedStream as DeviceStream);

  const {
    update: updateCamera,
    loading: updateCameraLoading,
  } = useUpdateCamera();
  const { addNewCamera: addCamera, loading: addCameraLoading } = useAddCamera(
    locationId
  );
  const isSubmitting = addCameraLoading || updateCameraLoading;

  return (
    <div className="flex items-center justify-end mt-3 mr-0 mb-0">
      {camera?.lifecycleState && camera.id && (
        <CamLifecycleButtons
          {...camera}
          resetForm={() => {
            onChange(channel?.streams[0]?.id);
          }}
          onClose={onClose}
        />
      )}
      {/* If the selectedStreamId isn't already the selected stream, allow activation */}
      {connectedStream?.id !== selectedStreamId && (
        <MaxCapacityTooltip
          maxCapacityReached={maxCapacityReached}
          maxAICapacityReached={aiEnabled ? maxAICapacityReached : undefined}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={async () => {
              if (selectedStreamId == null) return;
              if (camera) {
                await updateCamera(camera.id, selectedStreamId);
                onChange(selectedStreamId);
              } else {
                if (guardCapacityAndShowModal(aiEnabled)) return;

                await addCamera(
                  selectedStreamId,
                  deviceName,
                  deviceVendor,
                  aiEnabled
                );

                onClose();
              }
            }}
            disabled={
              isSubmitting ||
              Boolean(pathError) ||
              selectedStreamId == null ||
              disabledActivateCamera
            }
            style={{ fontSize: 12 }}
          >
            {camera ? "Update" : "Activate"} Camera
            {maxCapacityReached && !camera ? (
              <>
                <div className="w-1" />
                <WarningIcon className="text-base" />
              </>
            ) : (
              isSubmitting && <CircularProgress className="mx-1" size="20px" />
            )}
          </Button>
        </MaxCapacityTooltip>
      )}
      {camera?.lifecycleState === LifecycleStates.Enabled && (
        <div
          className={clsx("flex items-center ml-auto text-sm font-bold", {
            "text-[#DE6969]": camera.status !== CameraStatus.Online,
            "text-[#62b309]": camera.status === CameraStatus.Online,
          })}
        >
          {camera.status === CameraStatus.Online ? (
            <>
              <CheckCircleIcon className="my-0 mr-0.5 ml-2 text-lg" />
              <div>Online</div>
            </>
          ) : (
            <>
              <ErrorOutlineIcon className="my-0 mr-0.5 ml-2 text-lg" />
              <div>Offline</div>
            </>
          )}
        </div>
      )}
    </div>
  );
}
