import Add from "@mui/icons-material/Add";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import {
  Button,
  Container,
  Divider,
  Hidden,
  IconButton,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Link, useNavigate } from "react-router-dom";
import { useLocalStorage } from "react-use";
import { StringParam, useQueryParam } from "use-query-params";

import { useBreakpoints } from "@/util/useBreakpoints";

import { usePrefixOrgSlug } from "@/hooks/useOrgRouteBase";

import {
  IntelligenceInsightConfig,
  IntelligenceInsightType,
  useIntelligenceInsightConfig,
} from "../constants";

function IntelligenceFeatureHeaderItem({
  className,
  onClick,
  type,
  config,
  hideAddIcon = false,
}: {
  className?: string;
  onClick?: () => void;
  hideAddIcon?: boolean;
  type: IntelligenceInsightType;
  config: IntelligenceInsightConfig;
}) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setPromptCreate] = useQueryParam("promptCreate", StringParam);

  return (
    <button
      type="button"
      className={clsx(
        "rounded-md p-3 border border-solid flex items-center justify-start gap-1 h-[48px]",
        className
      )}
      style={{
        background: config.colorAccent,
        color: config.colorBase,
        borderColor: config.colorBase,
      }}
      onClick={() => {
        if (onClick) {
          onClick();
        } else {
          setPromptCreate(type);
        }
      }}
    >
      {!hideAddIcon && <Add />}
      <Typography className="text-base sm:text-lg leading-[19px] sm:leading-[21px] text-start">
        {config.label}
      </Typography>
      <div className="ml-auto sm:block hidden">{config.icon}</div>
    </button>
  );
}

function ComparativeDashboardCallout() {
  const { comparativeInt } = useFlags();
  const prefixOrgSlug = usePrefixOrgSlug();
  const navigate = useNavigate();
  const [intro, setIntro] = useLocalStorage("comparativeIntro", false);

  if (!comparativeInt || intro) return <></>;

  return (
    <>
      <div className="rounded-lg border border-solid border-white bg-[#DDEFFF] py-4 px-5 flex lg:flex-row flex-col items-center justify-between mt-5 shadow-[0_6px_14px_rgba(0,42,82,0.1)] self-stretch gap-4">
        <div className="flex items-center justify-start gap-5">
          <img
            className="sm:block hidden w-[72px] h-[72px]"
            alt="Comparative Insights"
            src="/intelligence/comparative-illustration.svg"
          />
          <div className="flex flex-col lg:max-w-[800px] gap-y-3 sm:gap-y-1">
            <Typography className="font-bold sm:font-light text-lg sm:text-[28px] leading-5 sm:leading-8 sm:text-primary">
              Introducing Comparative Insights!
            </Typography>
            <Typography className="text-sm leading-4 sm:text-base sm:leading-[18.75px]">
              <strong>Compare Insights across locations and cameras.</strong>{" "}
              Combine up to six Insights to compare metrics across cameras or
              locations. Make Comparative Insights from your existing Insights,
              or create new Insights and then combine away.
            </Typography>
          </div>
        </div>
        <Button
          color="primary"
          variant="contained"
          className="rounded-lg shadow-none font-base w-full lg:w-[unset] h-[42px]"
          onClick={() => {
            setIntro(true);
            navigate(prefixOrgSlug("/intelligence/compound/new"));
          }}
        >
          Compare Insights
        </Button>
      </div>
      <Divider className="bg-black opacity-[.12] mt-5" />
    </>
  );
}

export function IntelligenceFeatureHeader() {
  const navigate = useNavigate();
  const prefixOrgSlug = usePrefixOrgSlug();
  const { fitsTablet } = useBreakpoints();
  const config = useIntelligenceInsightConfig();
  const configItems = Object.entries(config).filter(
    ([_, config]) => !config.hidden
  );

  const symmetrical = configItems.length % 2 === 0;

  const tiles = configItems.map(([type, config], idx) => {
    const position = idx + 1;
    const trailingTile = position % 2 !== 0 && !configItems[position];
    const resolvedType = type as IntelligenceInsightType;
    const containerClassName = clsx(
      "md:w-full md:max-w-[220px] col-span-2 sm:col-span-1",
      {
        "col-start-0": !trailingTile,
        "col-start-2": trailingTile,
      }
    );
    const baseProps = {
      key: config.label,
      type: resolvedType,
      config: config,
    };

    if (type === IntelligenceInsightType.Comparative) {
      return (
        <div
          key="comparative-item"
          className={clsx(containerClassName, "flex items-center gap-2")}
        >
          <Divider
            orientation="vertical"
            className="h-6 ml-1 sm:block hidden"
          />
          <IntelligenceFeatureHeaderItem
            {...baseProps}
            className="gap-3"
            hideAddIcon
            onClick={() => {
              navigate(prefixOrgSlug("/intelligence/compound/new"));
            }}
          />
        </div>
      );
    }

    return (
      <IntelligenceFeatureHeaderItem
        className={containerClassName}
        {...baseProps}
      />
    );
  });

  return (
    <Container maxWidth="xl">
      {!fitsTablet && (
        <IconButton
          edge="start"
          component={Link}
          to="menu"
          className="-ml-3 p-0 text-primary absolute"
          size="large"
        >
          <ChevronLeft className="text-[44px]" />
        </IconButton>
      )}
      <div className="flex items-center justify-center gap-5 sm:mt-0 mt-6">
        <img
          src="/intelligence/intelligence-illustration.svg"
          className="h-[80px] sm:h-[100px] block sm:ml-0 ml-5"
          alt="Intelligence Logo"
        />
        <div className="flex flex-col items-start gap-0.5 sm:w-[unset] w-[165px]">
          <img
            src="/intelligenceLogoText.svg"
            className="h-6 sm:h-11"
            alt="Intelligence Logo"
          />
          <Typography className="font-light text-primary text-lg leading-[21px] sm:text-[21px] sm:leading-[25px] -ml-0.5">
            Turn video into meaningful insights.
          </Typography>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row items-center justify-center w-full gap-1 mt-2">
        <div>
          <Typography className="text-start font-bold text-base leading-[19px] sm:text-[20px] sm:leading-[23px] mr-4 sm:w-[110px]">
            Create a{fitsTablet && <br />} New Insight
          </Typography>
        </div>
        <Hidden mdDown>{tiles}</Hidden>
        <Hidden mdUp>
          <div
            className={clsx(
              "w-full grid gap-1 grid-cols-4 justify-center mt-1 sm:mt-0",
              {
                "sm:grid-cols-[repeat(auto-fit,_minmax(160px,_1fr))]": !symmetrical,
                "sm:grid-cols-[repeat(auto-fit,_minmax(160px,220px))]": symmetrical,
              }
            )}
          >
            {tiles}
          </div>
        </Hidden>
      </div>
      <Divider className="bg-black opacity-[.12] mt-5" />
      <ComparativeDashboardCallout />
    </Container>
  );
}
