import gql from "graphql-tag";

import { useCameraMuxMetadatalByIdQuery } from "@/generated-models";

export interface MuxConfiguration {
  playerName?: string;
  sampling?: number;
  cameraName?: string;
  cameraId?: number;
  locationName?: string;
  vodStart?: string;
  vodEnd?: string;
  experimentName?: string;
  kiosk?: boolean;
}

export interface MuxMetadata {
  playerName: string;
  sampling: number;
  cameraId?: number;
  cameraName?: string;
  locationId?: number;
  locationName?: string;
  applianceSerial?: string | null;
  applianceVersion?: string | null;
  codec?: string | null;
  width?: number | null;
  height?: number | null;
  vodStart?: string;
  vodEnd?: string;
  experimentName: string;
  loading?: boolean;
}

export function useMuxMetadata(config?: MuxConfiguration): MuxMetadata {
  const {
    playerName = "",
    sampling = 0.1, // Default to 10% sampling
    cameraName,
    cameraId,
    locationName,
    vodStart,
    vodEnd,
    experimentName = "",
    kiosk,
  } = config || {};

  const { data, loading } = useCameraMuxMetadatalByIdQuery({
    variables: {
      cameraId: cameraId || -1,
    },
    skip: !cameraId || kiosk,
  });

  const camera = data?.camera;
  const stream = camera?.stream;

  const result = {
    playerName,
    sampling,
    cameraName,
    locationName,
    vodStart,
    vodEnd,
    experimentName,
    loading,
  };

  return camera
    ? {
        ...result,
        cameraId,
        cameraName: camera.name,
        locationId: camera.location.id,
        locationName: camera.location.name,
        applianceSerial: camera.appliance.serialNumber,
        applianceVersion: camera.appliance.version,
        codec: stream?.settings?.video?.codec || stream?.metadata?.codec,
        width: stream?.settings?.video?.width || stream?.metadata?.width,
        height: stream?.settings?.video?.height || stream?.metadata?.height,
      }
    : result;
}

gql`
  query cameraMuxMetadatalById($cameraId: Int!) {
    camera(id: $cameraId) {
      id
      name
      location {
        id
        name
      }
      stream {
        id
        settings {
          video {
            codec
            height
            width
            fps
          }
        }
        metadata {
          id
          width
          height
          codec
          fps
        }
      }
      appliance {
        id
        serialNumber
        version
      }
    }
  }
`;
