import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import PeopleIcon from "@mui/icons-material/People";
import { MenuItem, Typography } from "@mui/material";
import clsx from "clsx";
import { Field, useField } from "formik";

import { ReactComponent as Forklift } from "@/icons/forklift.svg";

import { useAIModelEnabled } from "@/util/featureToggles";
import { required } from "@/util/form";

import { SelectInput, TextInput } from "@/pages/Intelligence/FormInputs";

import { AdditionalAiClass } from "@/generated-models";

import { Intelligence } from "../../Intelligence";

export function SelectComparisonsField() {
  const forkliftEnabled = useAIModelEnabled(AdditionalAiClass.Forklift);

  const [, { value }] = useField<number[]>({
    name: "referenceDashIds",
  });

  const subjectDisabled = value?.length > 0;

  return (
    <div className="flex flex-col gap-6">
      <Typography className="max-w-2xl text-base leading-[18px] text-[#757575]">
        Create a name for your Comparative Insight and select two to six
        Insights that you would like to compare. The Insights must be the same
        subject and metric.
      </Typography>
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
        <Field
          component={TextInput}
          name="name"
          label="Comparative Insight Name"
          validate={required}
        />
        <Field
          className="rounded-lg"
          inputClassName="py-[10px]"
          component={SelectInput}
          name="objectTypes"
          validate={required("Please provide a type")}
          label="Insight Subject"
          disabled={subjectDisabled}
        >
          <MenuItem value={"people"}>
            <div className="flex items-center gap-1">
              <PeopleIcon
                className={clsx("text-[#6500E4]", {
                  "text-[#757575]/60": subjectDisabled,
                })}
              />
              <Typography>People</Typography>
            </div>
          </MenuItem>
          <MenuItem className="flex items-center gap-1" value={"default"}>
            <div className="flex items-center gap-1">
              <DirectionsCarIcon
                className={clsx("text-[#00AFD6]", {
                  "text-[#757575]/60": subjectDisabled,
                })}
              />
              <Typography>Vehicles</Typography>
            </div>
          </MenuItem>
          {forkliftEnabled && (
            <MenuItem className="flex items-center gap-1" value={"forklift"}>
              <div className="flex items-center gap-1">
                <Forklift
                  className={clsx("text-[#e07901]", {
                    "text-[#757575]/60": subjectDisabled,
                  })}
                  width={24}
                  height={24}
                />
                <Typography>Forklift</Typography>
              </div>
            </MenuItem>
          )}
        </Field>
      </div>
      <Intelligence asForm />
    </div>
  );
}
