import { format } from "date-fns-tz";

import { Details } from "./AuditDetailsDrawer";
import { AuditLogEvent } from "./AuditLogsDatagrid";

export const getDetails = (event: AuditLogEvent): Details[] => {
  return detailsProviders[event.action]?.getDetails(event) || [];
};

export const getDescription = (action: string): string => {
  return detailsProviders[action]?.getDescription() || "";
};

const LiveViewProvider: DetailsProvider = {
  getDescription: () => "live view viewed",
  getDetails: (event: AuditLogEvent): Details[] => {
    return [...getCameraDetails(event), ...getDurationDetails(event)];
  },
};

const BidiAudioUsedProvider: DetailsProvider = {
  getDescription: () => "two-way audio used",
  getDetails: (event: AuditLogEvent): Details[] => {
    return [...getCameraDetails(event), ...getDurationDetails(event)];
  },
};

const IntercomAudioUsedProvider: DetailsProvider = {
  getDescription: () => "intercom audio used",
  getDetails: (event: AuditLogEvent): Details[] => {
    return [...getCameraDetails(event), ...getDurationDetails(event)];
  },
};

const VodViewProvider: DetailsProvider = {
  getDescription: () => "vod view viewed",
  getDetails: (event: AuditLogEvent): Details[] => {
    return [...getCameraDetails(event), ...getDurationDetails(event)];
  },
};

const getAuth0Details = (event: AuditLogEvent): Details[] => {
  return [
    { label: "IP", value: (event.details as any).ip },
    { label: "User Agent", value: (event.details as any).userAgent },
  ];
};

const LoginSuccessProvider: DetailsProvider = {
  getDescription: () => "login successful",
  getDetails: getAuth0Details,
};

const LoginFailureProvider: DetailsProvider = {
  getDescription: () => "login failed",
  getDetails: getAuth0Details,
};

const LogoutSuccessProvider: DetailsProvider = {
  getDescription: () => "logout successful",
  getDetails: getAuth0Details,
};

const UserAddedProvider: DetailsProvider = {
  getDescription: () => "user added",
  getDetails: (event: AuditLogEvent): Details[] => {
    return [...getUserAddedDetails(event)];
  },
};

const UserUpdatedProvider: DetailsProvider = {
  getDescription: () => "user updated",
  getDetails: (event: AuditLogEvent): Details[] => {
    return [...getUserUpdatedDetails(event)];
  },
};

const UserDeletedProvider: DetailsProvider = {
  getDescription: () => "user deleted",
  getDetails: (event: AuditLogEvent): Details[] => {
    return [...getUserDetails(event)];
  },
};

const UserSelfUpdatedProvider: DetailsProvider = {
  getDescription: () => "user updated self",
  getDetails: (event: AuditLogEvent): Details[] => {
    return [...getSelfUpdatedDetails(event)];
  },
};

const CameraSettingsUpdatedProvider: DetailsProvider = {
  getDescription: () => "camera settings updated",
  getDetails: (event: AuditLogEvent): Details[] => {
    return [
      ...getCameraDetails(event),
      ...getCameraSettingsUpdatedDetails(event),
    ];
  },
};

const ChangedPasswordRequestedProvider: DetailsProvider = {
  getDescription: () => "password change requested",
  getDetails: (_: AuditLogEvent): Details[] => {
    return [];
  },
};

const PasswordChangeSuccessProvider: DetailsProvider = {
  getDescription: () => "password change successful",
  getDetails: (_: AuditLogEvent): Details[] => {
    return [];
  },
};

const PasswordChangeFailedProvider: DetailsProvider = {
  getDescription: () => "password change failed",
  getDetails: (_: AuditLogEvent): Details[] => {
    return [];
  },
};

const CameraShareCreatedProvider: DetailsProvider = {
  getDescription: () => "live video share created",
  getDetails: (event: AuditLogEvent): Details[] => {
    return [
      ...getCameraDetails(event),
      ...getExpiryDetails(event),
      getShareUrl(event),
    ];
  },
};

const CameraShareUpdatedProvider: DetailsProvider = {
  getDescription: () => "live video share updated",
  getDetails: (event: AuditLogEvent): Details[] => {
    return [...getCameraDetails(event), ...getShareUpdatedDetails(event)];
  },
};

const CameraShareDeletedProvider: DetailsProvider = {
  getDescription: () => "live video share deleted",
  getDetails: (event: AuditLogEvent): Details[] => {
    return [...getCameraDetails(event)];
  },
};

const CameraShareSharedProvider: DetailsProvider = {
  getDescription: () => "live video shared",
  getDetails: (event: AuditLogEvent): Details[] => {
    return [...getCameraDetails(event), ...getShareDetails(event)];
  },
};

const SnapshotTakenProvider: DetailsProvider = {
  getDescription: () => "video snapshot taken",
  getDetails: (event: AuditLogEvent): Details[] => {
    return [...getCameraDetails(event), ...getTimestampDetails(event)];
  },
};

const VodShareCreatedProvider: DetailsProvider = {
  getDescription: () => "vod share created",
  getDetails: (event: AuditLogEvent): Details[] => {
    return [
      ...getCameraDetails(event),
      ...getDurationDetails(event),
      ...getExpiryDetails(event),
      getShareUrl(event),
    ];
  },
};

const VodShareUpdatedProvider: DetailsProvider = {
  getDescription: () => "vod share updated",
  getDetails: (event: AuditLogEvent): Details[] => {
    return [
      ...getCameraDetails(event),
      ...getDurationDetails(event),
      ...getShareUpdatedDetails(event),
    ];
  },
};

const VodShareDeletedProvider: DetailsProvider = {
  getDescription: () => "vod share deleted",
  getDetails: (event: AuditLogEvent): Details[] => {
    return [...getCameraDetails(event), ...getDurationDetails(event)];
  },
};

const VodShareSharedProvider: DetailsProvider = {
  getDescription: () => "vod shared",
  getDetails: (event: AuditLogEvent): Details[] => {
    return [
      ...getCameraDetails(event),
      ...getDurationDetails(event),
      ...getShareDetails(event),
    ];
  },
};

const VodDownloadedProvider: DetailsProvider = {
  getDescription: () => "vod downloaded",
  getDetails: (event: AuditLogEvent): Details[] => {
    return [...getCameraDetails(event), ...getDurationDetails(event)];
  },
};

const WallViewedProvider: DetailsProvider = {
  getDescription: () => "wall viewed",
  getDetails: (event: AuditLogEvent): Details[] => {
    return [
      { label: "Wall name", value: (event.details as any).name },
      ...getDurationDetails(event),
    ];
  },
};

type DetailsProvider = {
  getDescription: () => string;
  getDetails: (event: AuditLogEvent) => Details[];
};

const detailsProviders: Record<string, DetailsProvider> = {
  //session
  lis: LoginSuccessProvider,
  lif: LoginFailureProvider,
  los: LogoutSuccessProvider,
  // user
  ua: UserAddedProvider,
  uu: UserUpdatedProvider,
  ud: UserDeletedProvider,
  usu: UserSelfUpdatedProvider,
  cpr: ChangedPasswordRequestedProvider,
  cps: PasswordChangeSuccessProvider,
  cpf: PasswordChangeFailedProvider,
  // device
  casu: CameraSettingsUpdatedProvider,
  // video
  lv: LiveViewProvider,
  st: SnapshotTakenProvider,
  vv: VodViewProvider,
  vd: VodDownloadedProvider,
  wv: WallViewedProvider,
  bau: BidiAudioUsedProvider,
  iau: IntercomAudioUsedProvider,
  // share
  csc: CameraShareCreatedProvider,
  csu: CameraShareUpdatedProvider,
  csd: CameraShareDeletedProvider,
  css: CameraShareSharedProvider,
  vsc: VodShareCreatedProvider,
  vsu: VodShareUpdatedProvider,
  vsd: VodShareDeletedProvider,
  vss: VodShareSharedProvider,
};

type Change<T> =
  | {
      // added
      field: string; // can be a child, e.g. "permissions.editLocation"
      newValue: T;
    }
  | {
      // removed
      field: string;
      previousValue: T;
    }
  | {
      // updated
      field: string;
      previousValue: T;
      newValue: T;
    };

type ChangeDetails<T> = {
  change: Change<T>;
};

type CameraDetails = {
  locationId: number;
  locationName: string;
  cameraId: number;
  cameraName: string;
};

const getCameraDetails = (event: AuditLogEvent): Details[] => {
  const details = event.details as CameraDetails;
  return [
    {
      label: "Camera Name",
      value: details.cameraName || "<unknown>",
    },
    { label: "Location", value: details.locationName || "<unknown>" },
  ];
};

type TimestampDetails = { timestamp: string };

const getTimestampDetails = (event: AuditLogEvent): Details[] => {
  const details = event.details as TimestampDetails;
  return [
    {
      label: "Timestamp",
      value:
        format(new Date(details.timestamp), "MMM d, yyyy h:mm a z") ||
        "<unknown>",
    },
  ];
};

type DurationDetails = {
  start: string;
  end: string;
};

const getDurationDetails = (event: AuditLogEvent): Details[] => {
  const details = event.details as DurationDetails;
  return [
    {
      label: "Duration",
      value: `${format(
        new Date(details.start),
        "MMM d, yyyy h:mm:ss a"
      )} - ${format(new Date(details.end), "MMM d, yyyy h:mm:ss a")}`,
    },
  ];
};

type ExpiryDetails = { expiration: string | null };

const getExpiryDetails = (event: AuditLogEvent): Details[] => {
  const details = event.details as ExpiryDetails;
  return [
    {
      label: "Expiration",
      value: details.expiration
        ? format(new Date(details.expiration), "MMM d, yyyy h:mm a")
        : "never",
    },
  ];
};

type ShareDetails = { email: string } | { text: string };

const getShareDetails = (event: AuditLogEvent): Details[] => {
  const details = event.details as ShareDetails;
  return "email" in details
    ? [
        {
          label: "Shared By Email",
          value: details.email,
        },
      ]
    : "text" in details
    ? [
        {
          label: "Shared By Text Message",
          value: details.text,
        },
      ]
    : [];
};

const getShareUpdatedDetails = (event: AuditLogEvent): Details[] => {
  const details = event.details as ChangeDetails<string>;

  if ("previousValue" in details.change && "newValue" in details.change) {
    return [
      {
        label: `${details.change.field} Updated`,
        value: `${details.change.previousValue} to ${details.change.newValue}`,
      },
    ];
  }
  return [];
};

const getShareUrl = (event: AuditLogEvent): Details => {
  const details = event.details as { url: string };
  return {
    label: "URL",
    value: details.url,
  };
};

type UserDetails = {
  profileId: number;
  email: string;
  name: string;
};

type UserAddedDetails = UserDetails & {
  phone: string;
  role: string;
  permissions?: {
    editLocation: boolean;
    editUser: boolean;
    sharing: boolean;
    viewLive: boolean;
    viewVod: boolean;
  };
  tags: { id: number; name: string }[];
};

const getUserAddedDetails = (event: AuditLogEvent): Details[] => {
  const details = event.details as UserAddedDetails;
  return [
    {
      label: "Name",
      value: details.name || "<unknown>",
    },
    {
      label: "Email",
      value: details.email || "<unknown>",
    },
    {
      label: "Role",
      value: details.role || "<unknown>",
    },
    {
      label: "Live Video Access",
      value: String(details.permissions?.viewLive) || "<unknown>",
    },
    {
      label: "Video History Access",
      value: String(details.permissions?.viewVod) || "<unknown>",
    },
    {
      label: "Add/Edit Locations",
      value: String(details.permissions?.editLocation) || "<unknown>",
    },
    {
      label: "Add/Edit Users",
      value: String(details.permissions?.editUser) || "<unknown>",
    },
  ];
};

type UserUpdatedDetails<T> = UserDetails & ChangeDetails<T>;

const getUserUpdatedDetails = (event: AuditLogEvent): Details[] => {
  const details = event.details as
    | UserUpdatedDetails<string>
    | UserUpdatedDetails<boolean>
    | UserUpdatedDetails<{ name: string }>;
  const results = [
    {
      label: "Name",
      value: details.name || "<unknown>",
    },
    {
      label: "Email",
      value: details.email || "<unknown>",
    },
  ];

  if ("previousValue" in details.change) {
    if ("newValue" in details.change) {
      results.push({
        label: `${details.change.field} Updated`,
        value: `${details.change.previousValue} to ${details.change.newValue}`,
      });
    } else {
      results.push({
        label: `${details.change.field} Removed`,
        value:
          typeof details.change.previousValue === "object"
            ? details.change.previousValue.name
            : String(details.change.previousValue),
      });
    }
  } else {
    results.push({
      label: `${details.change.field} Added`,
      value:
        typeof details.change.newValue === "object"
          ? details.change.newValue.name
          : String(details.change.newValue),
    });
  }

  return results;
};

type SelfUpdatedDetails<T> = ChangeDetails<T>;

const getSelfUpdatedDetails = (event: AuditLogEvent): Details[] => {
  const details = event.details as SelfUpdatedDetails<string>;

  const results = [
    {
      label: "Name",
      value: event.userName || "<unknown>",
    },
    {
      label: "Email",
      value: event.userEmail || "<unknown>",
    },
  ];

  if ("previousValue" in details.change && "newValue" in details.change) {
    results.push({
      label: `${details.change.field} Updated`,
      value: `${details.change.previousValue} to ${details.change.newValue}`,
    });
  }

  return results;
};

const getUserDetails = (event: AuditLogEvent): Details[] => {
  const details = event.details as UserAddedDetails;
  return [
    {
      label: "Email",
      value: details.email || "<unknown>",
    },
  ];
};

const getCameraSettingsUpdatedDetails = (event: AuditLogEvent): Details[] => {
  const details = event.details as ChangeDetails<string>;

  if (
    !!details.change &&
    "previousValue" in details.change &&
    "newValue" in details.change
  ) {
    return [
      {
        label: `${details.change.field} Updated`,
        value: `${details.change.previousValue} to ${details.change.newValue}`,
      },
    ];
  }
  return [];
};
