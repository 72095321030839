import { IconButton } from "@mui/material";
import { useAtom } from "jotai";
import React, { ReactNode, useCallback } from "react";

import {
  playingIntentState,
  useCompletedState,
  useMultiPlayerControls,
} from "@/components/Player/PlayerBase";
import {
  PauseIcon,
  PlayIcon,
  ReplayIcon,
} from "@/components/Player/PlayerIcons";

import { PlayerTooltip } from "./PlayerTooltip";

export function PlayPauseReplayButton({
  fontSize = 24,
}: {
  fontSize?: number;
}) {
  const [playing, setPlaying] = useAtom(playingIntentState);
  const togglePlaying = useCallback(() => setPlaying((value) => !value), [
    setPlaying,
  ]);
  const { play } = useMultiPlayerControls();
  const completed = useCompletedState();
  let action: "Play" | "Pause" | "Replay" = "Play";
  let icon: ReactNode;
  if (completed) {
    action = "Replay";
    icon = <ReplayIcon fontSize="inherit" />;
  } else if (playing) {
    action = "Pause";
    icon = <PauseIcon fontSize="inherit" />;
  } else {
    action = "Play";
    icon = <PlayIcon fontSize="inherit" />;
  }

  return (
    <PlayerTooltip title={action}>
      <IconButton
        color="inherit"
        onClick={completed ? play : togglePlaying}
        style={{ fontSize }}
        size="large"
      >
        {icon}
      </IconButton>
    </PlayerTooltip>
  );
}
