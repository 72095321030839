import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Button, ButtonBase, Collapse, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { Circle } from "@/icons/Circle";

import { StreamSelectionDropdown } from "@/components/StreamSelectionDropdown/StreamSelectionDropdown";

import { DeviceDetailsQuery, LifecycleStates } from "@/generated-models";

const useStyles = makeStyles()((theme) => ({
  headerButton: {
    padding: theme.spacing(0.5),
    borderRadius: 5,
    display: "flex",
    alignItems: "flex-start",
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      background: "rgba(0,0,0,0.05)",
    },
  },
}));

interface CameraStreamSelectFormProps {
  expanded: boolean;
  onSetExpanded?: (expanded: boolean) => void;
  channel: NonNullable<DeviceDetailsQuery["deviceScan"]>["channels"][number];
  deviceId: number;
  deviceName: string;
  deviceVendor: string;
  aiEnabled: boolean;
  onClose: () => void;
  onOpenNativeProxy?: () => void;
}
export default function CameraStreamSelectForm({
  channel,
  expanded,
  onSetExpanded = () => {},
  deviceId,
  deviceName,
  deviceVendor,
  aiEnabled,
  onClose,
  onOpenNativeProxy = () => {},
}: CameraStreamSelectFormProps) {
  const { classes } = useStyles();

  const connectedStream = channel?.streams.find((stream) => stream.camera);
  const camera = connectedStream?.camera;

  return (
    <Box style={{ marginTop: 16 }}>
      <Box display="flex" flexDirection="column" width="100%">
        <ButtonBase
          className={classes.headerButton}
          onClick={() => onSetExpanded(!expanded)}
        >
          <Box mr={0.75}>
            {camera?.lifecycleState === LifecycleStates.Enabled ? (
              <CheckCircleIcon style={{ fontSize: 18, color: "#62b309" }} />
            ) : (
              <Circle style={{ fontSize: 18, opacity: 0.1 }} />
            )}
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
            flexGrow={1}
          >
            <Typography variant="h6" style={{ lineHeight: 1, marginTop: 1 }}>
              Stream Selection
            </Typography>
            {expanded && (
              <Typography variant="caption" style={{ lineHeight: 1.4 }}>
                Select the optimal stream for your camera
              </Typography>
            )}
          </Box>
          <Button
            component="div"
            size="small"
            color="primary"
            style={{ opacity: expanded ? 0 : 1 }}
            variant="outlined"
            onClick={() => onSetExpanded(!expanded)}
          >
            Edit
          </Button>
        </ButtonBase>

        <Collapse in={expanded}>
          <Box m={2} />
          <StreamSelectionDropdown
            channelId={channel?.channelId}
            deviceId={deviceId}
            deviceName={deviceName}
            deviceVendor={deviceVendor}
            onOpenNativeProxy={onOpenNativeProxy}
            onClose={onClose}
            aiEnabled={aiEnabled}
            disabled={
              !!camera?.lifecycleState &&
              camera.lifecycleState !== LifecycleStates.Enabled
            }
          />
        </Collapse>
      </Box>
    </Box>
  );
}
