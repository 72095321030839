import { CircularProgress, Typography } from "@mui/material";
import clsx from "clsx";
import { format } from "date-fns/fp";

import { ReactECharts } from "@/components/Chart";
import { lineTimeseriesConfig } from "@/components/Chart/utils";
import { ErrorMessage } from "@/components/ErrorMessage";

import { useLprReportTimeseries } from "./hooks";

export function LicensePlateInterestListVisitChart({
  title,
  className,
  dashboardId,
  dense,
}: {
  title?: string;
  className?: string;
  dashboardId?: number;
  dense?: boolean;
}) {
  const { data, error } = useLprReportTimeseries(dashboardId);

  if (error) {
    return <ErrorMessage title="Error" description={error.toString()} />;
  }

  if (!data) {
    return (
      <div className="flex items-center justify-center w-full py-10">
        <CircularProgress size="2.5rem" />
      </div>
    );
  }

  return (
    <div
      className={clsx(
        !dense &&
          "p-2 sm:p-5 border border-solid border-[#E0E0E0] rounded-lg m-2 sm:m-6",
        className
      )}
    >
      {title && (
        <Typography className="font-bold text-lg leading-[21.09px] pb-4">
          {title}
        </Typography>
      )}
      <ReactECharts
        style={{ height: dense ? 170 : 200 }}
        option={{
          responsive: true,
          grid: {
            show: true,
            top: "4%",
            left: "2%",
            right: 24,
            bottom: "28%",
            containLabel: true,
          },
          legend: undefined,
          tooltip: {
            trigger: "axis",
            axisPointer: {
              label: {
                formatter: ({ value }: { value: any }) => {
                  return format("MMM, d")(new Date(value));
                },
              },
            },
          },
          xAxis: {
            type: "time",
            boundaryGap: ["5%", "5%"],
            minorSplitLine: {
              show: true,
            },
          },
          dataZoom: dense
            ? []
            : [
                {
                  type: "slider",
                  xAxisIndex: 0,
                  filterMode: "none",
                  start: 0,
                  end: 100,
                },
                {
                  type: "inside",
                  xAxisIndex: 0,
                  filterMode: "none",
                },
              ],
          yAxis: {
            type: "value",
            boundaryGap: ["20%", "20%"],
            axisLabel: {},
          },
          series: [
            {
              ...lineTimeseriesConfig,
              name: "Visits",
              data: data?.lprReportTimeseries.map((x) => [
                new Date(x.date),
                x.visits,
              ]),
              type: "line",
              symbolSize: 5,
              dimensions: ["timestamp", "value"],
              lineStyle: { color: "#00A69C" },
              itemStyle: {
                color: "#00A69C",
              },
            },
          ],
        }}
      />
    </div>
  );
}
