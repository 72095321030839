import { useFlags } from "launchdarkly-react-client-sdk";

import { useMe } from "@/components/Auth";

export function useCanEditVideoWalls() {
  const me = useMe();
  const { userReadOnlyWalls } = useFlags();

  return !userReadOnlyWalls || me?.rolev2?.id === 1;
}
