import gql from "graphql-tag";
import { useEffect, useState } from "react";

import { auth0Config } from "@/auth0";
import { apiHost } from "@/environment";
import {
  useKioskInfoQuery,
  useRegisterKioskMutation,
} from "@/generated-models";

const DEFAULT_POLL_INTERVAL = 5000; // milliseconds

interface Auth0InitialDeviceResponse {
  // device_code is the unique code for the device. When the user goes to the verification_uri in their browser-based device, this code will be bound to their session.
  device_code: string;
  // user_code contains the code that should be input at the verification_uri to authorize the device.
  user_code: string;
  // verification_uri contains the URL the user should visit to authorize the device.
  verification_uri: string;
  // The above, with the user code
  verification_uri_complete: string;
  // expires_in indicates the lifetime (in seconds) of the device_code and user_code.
  expires_in: number;
  // interval indicates the interval (in seconds) at which the app should poll the token URL to request a token.
  interval: number;
}

// I fuckin hate auth0, the /oauth/device/code endpoint literally doesn't respect cors.
// Workaround to proxy through netlify and/or setupProxy.js
async function requestDeviceCode(abortSignal: AbortSignal) {
  const res = await fetch(`/auth0/oauth/device/code`, {
    method: "post",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      client_id: auth0Config.kioskClientId,
      scope: "openid email",
      audience: auth0Config.spotApiId,
    }),
    signal: abortSignal,
  });
  return [
    res.status,
    (await res.json()) as Auth0InitialDeviceResponse,
  ] as const;
}

enum Auth0DeviceTokenStatus {
  PENDING = "authorization_pending",
  EXPIRED = "expired_token",
  DENIED = "access_denied",
  SLOW_DOWN = "slow_down",
}

async function attemptDeviceAuthentication(deviceCode: string) {
  const [status, response] = await fetchDeviceToken(deviceCode);
  if (status === 200) {
    return response as Auth0DeviceToken;
  } else if (status >= 400 && status < 500) {
    const {
      error,
      error_description: description,
    } = response as Auth0DeviceTokenFailResponse;
    switch (error) {
      case Auth0DeviceTokenStatus.PENDING:
        return undefined;
      case Auth0DeviceTokenStatus.EXPIRED:
        throw new Error(`Code expired, reload: ${description}`);
      case Auth0DeviceTokenStatus.DENIED:
        throw new Error(`Access Denied ${description}`);
      case Auth0DeviceTokenStatus.SLOW_DOWN:
        await sleep(2000);
        return undefined;
      default:
        throw new Error(
          `Unexpected authentication error, but got 400 error: ${error} - ${description}`
        );
    }
  } else {
    // unclear what happened
    throw new Error("Unexpected authentication error");
  }
}

interface Auth0DeviceToken {
  access_token: string;
  refresh_token: string;
  id_token: string;
  token_type: string;
  expires_in: number;
}

interface Auth0DeviceTokenFailResponse {
  error: Auth0DeviceTokenStatus;
  error_description: string;
}

async function fetchDeviceToken(
  deviceCode: string
): Promise<[number, Auth0DeviceToken | Auth0DeviceTokenFailResponse]> {
  const res = await fetch(`/auth0/oauth/token`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },

    body: JSON.stringify({
      grant_type: "urn:ietf:params:oauth:grant-type:device_code",
      device_code: deviceCode,
      client_id: auth0Config.kioskClientId,
    }),
  });
  return [res.status, await res.json()];
}

export function useKioskAuth() {
  const [deviceCodeInfo, setDeviceCodeInfo] = useState<
    Auth0InitialDeviceResponse | undefined
  >(undefined);
  const { data, refetch: refetchKioskInfo } = useKioskInfoQuery({
    pollInterval: 15000,
  });
  const currentKiosk = data?.kioskInfo;

  // This useeffect manages the deviceCodeInfo only
  useEffect(() => {
    if (currentKiosk) {
      setDeviceCodeInfo(undefined);
      return;
    }

    if (!deviceCodeInfo) {
      let isUnmounted = false;
      let abortFetch: (() => void) | undefined;
      const refetch = async () => {
        try {
          const abort = new AbortController();
          abortFetch = () => {
            abort.abort();
          };
          const [status, resp] = await requestDeviceCode(abort.signal);
          abortFetch = undefined;
          if (isUnmounted) return;

          if (status !== 200) {
            throw new Error("failed to get device code");
          }
          setDeviceCodeInfo(resp);
        } catch (e) {
          if (isUnmounted) return;

          setDeviceCodeInfo(undefined);
          await sleep(5000);
          if (isUnmounted) return;
          refetch();
        }
      };
      refetch();
      return () => {
        isUnmounted = true;
        if (abortFetch) {
          abortFetch();
        }
      };
    }
  }, [currentKiosk, deviceCodeInfo]);

  const [registerKiosk] = useRegisterKioskMutation();

  // This useeffect manages the effect after a deviceCode is provided
  useEffect(() => {
    if (deviceCodeInfo === undefined || currentKiosk) {
      return;
    }

    const {
      device_code: deviceCode,
      expires_in: expiry,
      interval: pollInterval = DEFAULT_POLL_INTERVAL / 1000,
      user_code: userCode,
    } = deviceCodeInfo;
    const pollIntervalMs = pollInterval * 1000 || DEFAULT_POLL_INTERVAL;
    const expiration = Date.now() + expiry * 1000 * 0.9; // 10% buffer because auth0 seems to expire these much earlier sometimes

    const checkKiosk = async (): Promise<boolean> => {
      const authResult = await attemptDeviceAuthentication(deviceCode);
      if (authResult === undefined) return false;

      // Temporarily set the user ID token we get to register a kiosk
      await fetch(`${apiHost}/kioskUserToken`, {
        method: "POST",
        credentials: "include",
        headers: { Authorization: `Bearer ${authResult.id_token}` },
      }).then(async (res) => {
        if (!res.ok) throw new Error(await res.text());
        return res;
      });

      const resp = await registerKiosk({
        variables: {
          input: {
            name: userCode,
            code: userCode,
          },
        },
      });
      const kioskToken = resp.data?.registerKiosk.token;
      if (!kioskToken) {
        throw new Error(
          `Could not authenticate properly: ${resp.errors?.[0].message}`
        );
      }

      // TODO: invalidate kioskUserToken cookie?

      // Eventually we need to figure out a way to expire this particular token
      // Set httpOnly kioskToken cookie
      await fetch(`${apiHost}/kioskToken`, {
        method: "POST",
        credentials: "include",
        headers: { Authorization: `Bearer ${kioskToken}` },
      }).then(async (res) => {
        if (!res.ok) throw new Error(await res.text());
        return res;
      });

      refetchKioskInfo();
      return true;
    };

    let timeout: NodeJS.Timeout | undefined;
    const poll = () => {
      timeout = setTimeout(async () => {
        if (Date.now() >= expiration) {
          console.log("token expired");
          setDeviceCodeInfo(undefined);
          return;
        }
        try {
          const success = await checkKiosk();
          if (!success) poll();
        } catch (e) {
          setDeviceCodeInfo(undefined);
          console.log("Refetch issue", e);
          return;
        }
      }, pollIntervalMs || DEFAULT_POLL_INTERVAL);
    };
    poll();

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [currentKiosk, deviceCodeInfo, refetchKioskInfo, registerKiosk]);

  return { userCode: deviceCodeInfo?.user_code, currentKiosk };
}

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

gql`
  mutation registerKiosk($input: RegisterKioskInput!) {
    registerKiosk(input: $input) {
      token
      kiosk {
        name
      }
    }
  }
`;
