import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import gql from "graphql-tag";
import { capitalize } from "lodash/fp";
import { useState } from "react";
import {
  Link,
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { useBreakpoints } from "@/util/useBreakpoints";

import { TagCameras } from "@/pages/Settings/TagsSettings/TagCameras";
import { TagUsers } from "@/pages/Settings/TagsSettings/TagUsers";

import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { NoAccess } from "@/components/NoAccess/NoAccess";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { createStyledTabs } from "@/components/StyledTabs/StyledTabs";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  useGroupDetailsQuery,
  useUpdateGroupMutation,
} from "@/generated-models";
import { usePermissions } from "@/hooks/usePermissions";
import { theme } from "@/layout/theme";

const Placeholder = <>Placeholder</>;

const useStyles = makeStyles()((theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
  },
  divider: {
    position: "absolute",
    width: "100%",
    height: 6.8,
    opacity: 0.4,
    backgroundImage:
      "linear-gradient(to bottom, rgba(0, 0, 0, 0.37), rgba(255, 255, 255, 0))",
  },
  headerContent: {
    marginRight: theme.spacing(2),
  },
  navigation: { marginLeft: "auto", flexShrink: 0 },

  descriptionExpandButton: {
    transition: "0.25s transform",
    backgroundColor: "#fff",
    color: "#353d48",
    border: "solid 1px #c8c8c8",
    marginLeft: 12,
    "& svg": { fontSize: 18 },
  },
  descriptionNotesContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "10px 20px",
    backgroundColor: "#f8f8f8",
    border: "solid 1px #e4e4e4",
    borderRadius: 6,
    marginTop: 8,
  },
}));

export function Group() {
  const { classes } = useStyles();
  const theme = useTheme();
  const location = useLocation();
  const hasPermission = usePermissions();
  const { id } = useParams();
  const { fitsDesktop } = useBreakpoints();
  const { data, error } = useGroupDetailsQuery({
    variables: { id: Number(id) },
    ...refetchOnMountPolicy,
  });

  const tabs = [
    {
      tab: "cameras",
      Component: Placeholder,
      disabled: !hasPermission("devices_access"),
    },
    {
      tab: "users",
      Component: Placeholder,
      disabled: !hasPermission("users_access"),
    },
  ];

  if (error) {
    return (
      <ErrorMessage
        title="Failed to load group"
        description="Please try again later or contact support."
      />
    );
  }

  if (!data) {
    return (
      <div style={{ padding: "48px 0", margin: "0 auto" }}>
        <Loading>Fetching Group details...</Loading>
      </div>
    );
  }

  const tabLocation = tabs.find(
    (candidate) =>
      location.pathname.includes(candidate.tab) && !candidate.disabled
  )?.tab;

  if (!tabLocation) {
    return <NoAccess />;
  }

  return (
    <>
      <Grid container alignItems="center" wrap="nowrap">
        <Grid item>
          <IconButton component={Link} to=".." size="large">
            <ChevronLeftIcon
              fontSize="large"
              style={{ color: theme.palette.text.primary }}
            />
          </IconButton>
        </Grid>
        <Grid container item justifyContent="space-between">
          <Grid item>
            <Typography component="h4" variant="h1">
              <QuickEditTagName
                name={data.group.name}
                tagId={data.group.id}
                isLocation={data.group.locations.length > 0}
              />
            </Typography>
          </Grid>

          {fitsDesktop && (
            <Grid item>
              <div className={classes.headerContent}>
                Customize your group by adding elements below
              </div>
            </Grid>
          )}
        </Grid>
      </Grid>
      <div className={classes.divider} />
      <Grid container direction="column">
        <Box p={2}>
          <EditDescription
            description={data.group.description}
            tagId={data.group.id}
          />
        </Box>
        <Box px={1}>
          <NavTabs value={tabLocation}>
            {tabs.map(({ tab, disabled }) => (
              <NavTab
                key={tab}
                value={tab}
                component={Link}
                label={capitalize(tab)}
                disabled={disabled}
                to={tab}
              />
            ))}
          </NavTabs>
        </Box>
      </Grid>
      <Routes>
        <Route index element={<Navigate to="cameras" replace />} />
        <Route path="cameras" element={<TagCameras group={data.group} />} />
        <Route path="users" element={<TagUsers group={data.group} />} />
      </Routes>
    </>
  );
}

const { NavTabs, NavTab } = createStyledTabs({
  tab: { root: { fontSize: 14 } },
});

function EditDescription({
  description,
  tagId,
}: {
  description: string;
  tagId: number;
}) {
  const { fitsDesktop } = useBreakpoints();
  const hasPermission = usePermissions();
  const { classes } = useStyles();
  const { pushSnackbar } = useFeedback();
  const [updateGroup] = useUpdateGroupMutation({
    onCompleted: () =>
      pushSnackbar(`Group updated successfully`, FeedbackType.Success),
    onError: () =>
      pushSnackbar(
        "Failed to update Group, please try again later",
        FeedbackType.Error
      ),
  });

  return (
    <Formik
      initialValues={{ description }}
      onSubmit={(values) => {
        return updateGroup({
          variables: {
            id: tagId,
            value: { description: values.description },
          },
        });
      }}
    >
      {({ isSubmitting, dirty }) => (
        <div>
          {hasPermission("devices_manage") ? (
            <Form className={classes.descriptionNotesContainer}>
              <Field
                name="description"
                placeholder="Add or update description here."
                component={TextField}
                multiline
                InputProps={{ disableUnderline: true }}
                disabled={isSubmitting}
              />
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting || !dirty}
                style={{ marginLeft: "auto" }}
              >
                {fitsDesktop ? "Save description" : "Save"}
              </Button>
            </Form>
          ) : (
            <Typography>{description}</Typography>
          )}
        </div>
      )}
    </Formik>
  );
}

function QuickEditTagName({
  name,
  tagId,
  isLocation,
}: {
  name: string;
  tagId: number;
  isLocation: boolean;
}) {
  const [editing, setEditing] = useState(false);
  const feedback = useFeedback();
  const hasPermission = usePermissions();
  const [updateGroup] = useUpdateGroupMutation({
    onCompleted: () => {
      setEditing(false);
      feedback.pushSnackbar(
        "Group name updated successfully",
        FeedbackType.Success
      );
    },
    onError: () =>
      feedback.pushSnackbar(
        "Failed to update Group, please try again later",
        FeedbackType.Error
      ),
  });

  return editing ? (
    <Formik
      initialValues={{ name }}
      onSubmit={(values) => {
        return updateGroup({
          variables: { id: tagId, value: { name: values.name } },
        });
      }}
    >
      <Form>
        <Field name="name" component={TextField} size="small" />
        <Tooltip title="Save">
          <IconButton
            type="submit"
            style={{ opacity: 0.5, padding: theme.spacing(0.5) }}
            size="large"
          >
            <CheckIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Cancel">
          <IconButton
            type="button"
            onClick={() => setEditing(false)}
            style={{ opacity: 0.5, padding: theme.spacing(0.5) }}
            size="large"
          >
            <CancelIcon />
          </IconButton>
        </Tooltip>
      </Form>
    </Formik>
  ) : (
    <>
      <span>{name}</span>
      <Box display="inline-block" />
      {hasPermission("devices_manage") && !isLocation && (
        <Tooltip title="Edit Group name">
          <IconButton
            onClick={() => setEditing(true)}
            style={{ opacity: 0.5, padding: theme.spacing(0.5) }}
            size="large"
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
}

gql`
  mutation updateGroup($id: Int!, $value: UpdateTagInput!) {
    updateTag(id: $id, value: $value) {
      id
      name
      description
    }
  }
`;

gql`
  query groupDetails($id: Int!) {
    group: tag(id: $id) {
      id
      name
      description
      cameras {
        id
      }
      users {
        id
        name
        role
      }
      locations {
        id
        name
        cameras {
          id
          name
          status
        }
      }
    }
  }
`;
