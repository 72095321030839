import { ErrorMessage } from "../ErrorMessage";

export function NoAccess() {
  return (
    <ErrorMessage
      title="No access"
      description="Please contact your admin, as you have insufficient permissions to view this page."
    />
  );
}
