import gql from "graphql-tag";
import { parse as parseQuerystring } from "query-string";
import { useEffect, useState } from "react";
import { useFullScreenHandle } from "react-full-screen";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { CSSInterpolation, GlobalStyles } from "tss-react";
import { BooleanParam, useQueryParam } from "use-query-params";

import { useDocumentTitle } from "@/util/useDocumentTitle";

import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";

import {
  usePage_VideoWallsQuery,
  WallCamerasFragmentDoc,
} from "@/generated-models";
import { usePrefixOrgSlug } from "@/hooks/useOrgRouteBase";
import { usePermissions } from "@/hooks/usePermissions";
import { ContentWrapper } from "@/layout/ContentWrapper";

import { RotatingVideoWallView } from "./RotatingVideoWallView";
import { SingleVideoWallEdit } from "./SingleVideoWallEdit";
import { SingleVideoWallView } from "./SingleVideoWallView";
import { VideoWallWelcomeScreen } from "./VideoWallWelcomeScreen";
import { useWallStyles } from "./constants";
import { useCanEditVideoWalls } from "./hooks";
import { useIsDemoUser } from "./useIsDemoUser";
import { VideoWallContext } from "./useVideoWallContext";

export function VideoWallPage() {
  useDocumentTitle("Video Wall");
  // VideoWallContext
  const { theme } = useWallStyles();
  const prefixOrgSlug = usePrefixOrgSlug();
  const hasPermission = usePermissions();
  const hasViewPermission = hasPermission("video_live_access");
  const fullscreenHandle = useFullScreenHandle();
  const autoLayoutState = useState(true);
  const originalConfigurationState = useState(false);
  const editingState = useState(false);
  const canEdit = useCanEditVideoWalls();

  const iosActiveState = useState(false);
  const [editing] = editingState;
  const [iosActive] = iosActiveState;

  const { loading, data, error } = usePage_VideoWallsQuery({
    skip: !hasViewPermission,
  });

  const location = useLocation();
  const navigate = useNavigate();
  const isDemo = useIsDemoUser();

  // This query param is somewhat a bandaid solution but its the simplest way to resolve
  // the lack of a correct redirect that doesn't really matter that much in the grand scheme of things
  const [spotcastRegistration] = useQueryParam(
    "registerSpotcast",
    BooleanParam
  );

  useEffect(() => {
    if (location.pathname !== prefixOrgSlug("/wall")) return;

    // Apply legacy redirect: /wall?id=110   =>    /wall/110
    const legacyWallId = Number(parseQuerystring(location.search)?.id);

    if (legacyWallId) {
      navigate(
        `/wall/${legacyWallId}${
          spotcastRegistration ? "?registerSpotcast=1" : ""
        }`,
        { replace: true }
      );
      return;
    }

    // Apply last viewed wall id from local storage when landing on /wall
    // Possible formats of localStorage.lastViewedWallId:
    //  - wall: ":id"
    //  - rotating: "r/:id"
    if (/(r\/)?(\d+)/.test(localStorage.lastViewedWallId)) {
      navigate(
        prefixOrgSlug(
          `/wall/${localStorage.lastViewedWallId}${
            spotcastRegistration ? "?registerSpotcast=1" : ""
          }`
        ),
        { replace: true }
      );
      return;
    }

    // eslint-disable-next-line
  }, []);

  if (!hasViewPermission) {
    return (
      <ErrorMessage
        title="You do not have access to Live videos."
        description="Your system admin has restricted your ability to see Live video feeds, please contact them for additional access permissions."
      />
    );
  }

  if (error) {
    return (
      <ContentWrapper>
        <ErrorMessage title="Error" description={error.message} />
      </ContentWrapper>
    );
  }

  if (loading || !data?.videoWalls) {
    return <Loading grow>Fetching Video Walls</Loading>;
  }

  return (
    <VideoWallContext.Provider
      value={{
        fullscreenHandle,
        autoLayoutState,
        originalConfigurationState,
        editingState,
        iosActiveState,
        fullscreenActive: fullscreenHandle.active || iosActive,
        debugMode: false,
      }}
    >
      <GlobalStyles
        styles={{
          header: ({
            [theme.breakpoints.up("sm")]: {
              position: "sticky !important", // TODO: Extremely hacky, need to have a better solution for spacing with the drawer than just setting header static
              top: 0,
            },
          } as unknown) as CSSInterpolation,

          ".fullscreen": {
            display: "grid",
            boxSizing: "border-box",
            height: "100%",
            gridTemplateColumns: "1fr",
            gridTemplateRows: "auto 1fr",
          },
        }}
      />
      <Routes>
        <Route index element={<VideoWallWelcomeScreen />} />
        <Route path="r/:id" element={<RotatingVideoWallView />} />
        <Route
          path=":id"
          element={
            !isDemo && editing && canEdit ? (
              <SingleVideoWallEdit />
            ) : (
              <SingleVideoWallView />
            )
          }
        />
      </Routes>
    </VideoWallContext.Provider>
  );
}

gql`
  mutation deleteWall($id: Int!) {
    deleteVideoWall(id: $id) {
      message
    }
  }
`;

gql`
  mutation updateRotatingWall($id: Int!, $update: RotatingVideoWallUpdate!) {
    updateRotatingVideoWall(id: $id, update: $update) {
      id
      name
      pageDuration
      videoWalls {
        ...VideoWallFragment
      }
    }
  }
  ${WallCamerasFragmentDoc}
`;

gql`
  mutation deleteRotatingWall($id: Int!) {
    deleteRotatingVideoWall(id: $id) {
      message
    }
  }
`;
