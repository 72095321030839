import { Monaco } from "@monaco-editor/react";

export function registerApplianceConfigExtensions(
  monaco: Monaco,
  schema: unknown
) {
  monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
    validate: true,
    schemas: [
      {
        uri: "spot/appliance/config",
        fileMatch: ["*"],
        schema: schema,
      },
    ],
  });
}
