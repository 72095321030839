import { MaintainCamera } from "@/pages/Maintain/hooks";

const heroSpeedMac = "2c:6f:51";

export function getCameraSpecs(camera: MaintainCamera) {
  const isHeroSpeedCamera = camera.device.mac
    .toLowerCase()
    .includes(heroSpeedMac);

  const bitrate = camera.metrics?.bitrate || 0;

  const isMedianBitrateAboveSpec = bitrate > 2048;

  return [
    {
      label: "Bitrate",
      value: `${bitrate}`,
      error: isHeroSpeedCamera && isMedianBitrateAboveSpec,
      recommended: "2048",
    },
  ];
}
