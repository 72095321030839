import { Button, Slider, Typography } from "@mui/material";
import clsx from "clsx";
import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { formatTimeMinutes } from "@/util/date";
import { useUuid } from "@/util/useUuid";

import { useIsDarkTheme } from "@/layout/theme";

const ThumbHoverContext = React.createContext({
  hovering: new Map<string, boolean>(),
  setHovering: (() => {}) as React.Dispatch<
    React.SetStateAction<Map<string, boolean>>
  >,
});

const marks: { value: number; label: string }[] = [
  {
    value: 0,
    label: "12am",
  },
  {
    value: 12 * 60, // minutes
    label: "12pm",
  },
  {
    value: 24 * 60, // minutes
    label: "12am",
  },
];
const min = marks[0].value;
const max = marks[marks.length - 1].value;
export function TimeRangeSelector({
  btnClassName,
  labelClassName,
  initialValue,
  onChange,
}: {
  btnClassName?: string;
  labelClassName?: string;
  initialValue: [from: number, to: number];
  onChange: (value: [from: number, to: number]) => void;
}) {
  const [value, setValue] = useState(initialValue);
  const [from, to] = value;
  const dark = useIsDarkTheme();
  const reset = useCallback(() => {
    setValue([min, max]);
    onChange([min, max]);
  }, [setValue, onChange]);
  const [thumbHovering, setThumbHovering] = useState(
    new Map<string, boolean>()
  );

  return (
    <ThumbHoverContext.Provider
      value={{ hovering: thumbHovering, setHovering: setThumbHovering }}
    >
      <div className="min-w-[240px] text-center">
        <div className="flex justify-between items-center">
          <Typography
            variant="body2"
            className={clsx("font-medium", {
              "text-[#eee]": dark,
              "text-[#666666]": !dark,
            })}
          >
            Time Range
          </Typography>
          <Button
            color="primary"
            className={clsx("-mx-3", btnClassName, {
              invisible: from === min && to === max,
            })}
            onClick={reset}
          >
            Clear
          </Button>
        </div>
        <div className="px-1.5 h-6">
          <Slider
            value={value as number[]}
            onChange={(_, newValue) => {
              setValue(newValue as [number, number]);
            }}
            step={15}
            min={min}
            max={max}
            onChangeCommitted={(__, newValue) =>
              onChange(newValue as [number, number])
            }
            components={{
              ValueLabel: ValueLabel,
            }}
            componentsProps={{
              valueLabel: {
                valueLabelDisplay: "auto",
              },
            }}
            classes={{
              rail: "h-1 bg-gray-e0 opacity-100",
              track: "h-1",
              mark: "h-1",
              thumb:
                "shadow-none w-4 h-4 hover:shadow-[0_2px_4px_rgba(21,67,105,0.35)] transition-[box-shadow]",
            }}
          />
        </div>
        <Typography
          className={clsx("font-medium text-primary", labelClassName)}
        >
          {to - from === max
            ? "Entire day"
            : `${formatTimeMinutes(from)} - ${formatTimeMinutes(to)}`}
        </Typography>
      </div>
    </ThumbHoverContext.Provider>
  );
}
function ValueLabel({
  children,
  open,
  value,
}: {
  children: ReactElement;
  open: boolean;
  value: number;
}) {
  const uuid = useUuid();
  const { hovering, setHovering } = useContext(ThumbHoverContext);
  useEffect(() => {
    setHovering((current) => {
      const next = new Map(current);
      next.set(uuid, open);
      return next;
    });
  }, [open, setHovering, uuid]);
  const oneHovering = [...hovering.values()].some((x) => x);
  return (
    <>
      {React.Children.map(children, (child) => {
        const { style, ...props } = child.props;
        return (
          <div style={style} className="absolute w-4 h-4">
            <div
              className={clsx(
                "absolute -top-9 bg-primary text-white font-medium whitespace-nowrap text-xs px-1 py-0.5 rounded-sm transition-opacity -translate-x-1/2",
                {
                  "opacity-0": !(open || oneHovering),
                }
              )}
            >
              {value}

              <span
                className={clsx(
                  "h-[0.71em] w-[1em] bottom-0 mb-[-0.71em] absolute overflow-hidden box-border left-1/2 -translate-x-1/2"
                )}
              >
                <div
                  className="w-full h-full m-auto rotate-45 bg-primary"
                  style={{
                    transformOrigin: "100% 0",
                  }}
                />
              </span>
            </div>
            {React.cloneElement(child, {
              ...props,
              style: {}, // reset left
            })}
          </div>
        );
      })}
    </>
  );
}
