import { Skeleton, Typography } from "@mui/material";
import clsx from "clsx";

import { COMPARATIVE_COLORS } from "../constant";

interface ComparativeLocationChipProps {
  location: string;
  camera: string;
  position: number;
  descriptions: [string] | [string, string];
  light?: boolean;
  loading?: boolean;
  className?: string;
}

function DescriptionItem({
  description,
  position,
  idx,
}: {
  description: string;
  position: number;
  idx: number;
}) {
  return (
    <div className="flex gap-1 items-center justify-start">
      <div
        className="w-[30px] h-2 rounded-[50px]"
        style={{
          background: COMPARATIVE_COLORS[position][idx],
        }}
      />
      <Typography className="text-[10px] leading-[11px]">
        {description}
      </Typography>
    </div>
  );
}

export function ComparativeLocationChip({
  location,
  camera,
  position,
  descriptions,
  className,
  loading,
  light,
}: ComparativeLocationChipProps) {
  return (
    <div
      className={clsx(
        "px-3 py-2 flex flex-col gap-[6px] rounded-lg bg-[#F8F8F8] border border-[#E4E4E4]",
        className
      )}
    >
      <div className="flex flex-col w-full">
        <Typography className="font-bold text-xs leading-[14px] truncate">
          {location}
        </Typography>
        <Typography className="text-xs leading-[14px] truncate">
          {camera}
        </Typography>
      </div>
      {loading && (
        <Skeleton
          className="h-2 mt-0.5 mb-[1px] max-w-[140px]"
          variant="rounded"
        />
      )}
      {!loading && (
        <div className="flex flex-col">
          {descriptions.map((d, idx) => (
            <DescriptionItem
              key={d}
              description={d}
              position={position}
              idx={light ? idx : idx + 1}
            />
          ))}
        </div>
      )}
    </div>
  );
}
