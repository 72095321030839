import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { IconButton } from "@mui/material";
import clsx from "clsx";
import { useIntercom } from "react-use-intercom";

export const IntercomArticle: {
  [key: string]: number;
} = {
  ADDING_CAMERA: 7960681,
  ADDING_NVR: 7960680,
  ADDING_USERS: 7960650,
  AUDIT_LOGS: 7960644,
  CAMERA_GROUPS: 7960647,
  CAMERA_SETTINGS: 7960692,
  CASES: 7960733,
  CONFIGURE_STATIC_IP: 7960670,
  CREATE_ALERT: 7960734,
  INSTALL_APPLIANCE: 7960668,
  MAPS: 7960719,
  OFFLINE_APPLIANCE: 7960673,
  OFFLINE_CAMERA: 7960699,
  PTZ_SUPPORT: 7960694,
  ROTATING_VIDEO_WALL: 7960730,
  SEGMENT_SCRUB: 7960724,
  SHARE_VIDEO: 7960727,
  SPOTCAST: 7960729,
  TIMELAPSE: 7960721,
  VIDEO_TROUBLESHOOT: 7960720,
  VIDEO_WALL_CUSTOMIZE: 7960732,
  ZONE_BASED_MOTION_FILTER: 7960723,
};

interface ZendeskLinkProps {
  color?:
    | "inherit"
    | "default"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning"
    | undefined;
  className?: string;
  article: number;
}

export function IntercomLink({ color, className, article }: ZendeskLinkProps) {
  const { showArticle } = useIntercom();

  return (
    <IconButton
      className={clsx(className, "-mt-0.5", {
        "opacity-50 hover:opacity-100": !!color,
      })}
      color={color}
      size="small"
      onClick={() => {
        if (article) {
          showArticle(article);
        }
      }}
    >
      <HelpOutlineIcon fontSize="small" />
    </IconButton>
  );
}
