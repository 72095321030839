import { Error } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { ReactNode, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { useBreakpoints } from "@/util/useBreakpoints";

import { CreateIntegrationDeviceInput } from "@/generated-models";

import { IntegrationDetailsFormItem } from "../Form/Details/IntegrationDetailsFormItem";
import { CustomDeviceFormItem } from "../Form/Device/CustomDeviceFormItem";
import { CustomEventFormItem } from "../Form/Event/CustomEventFormItem";
import { IntegrationFormEventField } from "../Form/Event/utils";
import {
  DEFAULT_CONNECT_DEVICE,
  DEFAULT_CONNECT_EVENT,
  PLACEHOLDER_ID,
} from "../constant";
import { useCreateIntegration } from "../hooks/integrationHooks";

interface ConnectStepLabelProps {
  label: string;
  subtitle: string;
  index: number;
  step: number;
  error?: boolean;
}

function StepContainer({
  hidden,
  children,
}: {
  hidden?: boolean;
  children: ReactNode;
}) {
  return <div className={clsx(hidden && "hidden")}>{children}</div>;
}

function ConnectStepLabel({
  label,
  subtitle,
  index,
  step,
  error,
}: ConnectStepLabelProps) {
  return (
    <StepLabel
      StepIconProps={{
        classes: {
          root: "text-[28px] ml-[-2px]",
        },
      }}
      classes={{
        label: "font-medium text-base leading-[18px] ml-4",
      }}
    >
      {label}
      {error && <Error className="text-error text-lg ml-1 -mt-0.5" />}
      {subtitle && index === step && (
        <Typography className="text-sm leading-[16.41px] text-[#666666] max-w-sm mt-1">
          {subtitle}
        </Typography>
      )}
    </StepLabel>
  );
}

export interface IntegrationCreateFormFields {
  name: string;
  type: number;
  events: IntegrationFormEventField[];
  devices: Omit<CreateIntegrationDeviceInput, "integrationId">[];
  description?: string;
  duration?: number;
  buffer?: number;
}

export function IntegrationCreateForm() {
  const { fitsTablet } = useBreakpoints();
  const [step, setStep] = useState(0);

  const { loading, create } = useCreateIntegration();

  const form = useForm<IntegrationCreateFormFields>({
    defaultValues: {
      name: "",
      type: PLACEHOLDER_ID,
      events: [{ ...DEFAULT_CONNECT_EVENT }],
      devices: [{ ...DEFAULT_CONNECT_DEVICE }],
      description: "",
      duration: 600000,
      buffer: 120000,
    },
    mode: "onChange",
  });

  const {
    handleSubmit,
    watch,
    trigger,
    formState: { errors },
  } = form;

  const integrationName = watch("name");
  const hasErrors = Object.keys(errors).length > 0;

  const steps = [
    {
      label: "Integration Name",
      subtitle: "Give this integration a name",
    },
    {
      label: "Create Events",
      subtitle:
        "Name events, add their relevant schema, and define each schema type.",
      error: !!errors["events"],
    },
    {
      label: "Add Devices & Link Cameras",
      subtitle: "Name devices, link cameras, and add tags.",
      error: !!errors["devices"],
    },
  ];

  const onSubmit = handleSubmit(async ({ name, type, events, devices }) => {
    if (!(await trigger())) return;

    create({
      devices,
      events,
      integrationInput: { name, integrationTypeId: type },
    });
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit}>
        <Stepper className="py-6" activeStep={step} orientation="vertical">
          {steps.map(({ label, subtitle, error }, index) => {
            const lastStep = step === steps.length - 1;
            return (
              <Step key={index}>
                <ConnectStepLabel
                  label={label}
                  subtitle={subtitle}
                  index={index}
                  step={step}
                  error={error}
                />
                <StepContent>
                  <div
                    style={{
                      padding: fitsTablet ? "18px" : "12px 18px",
                    }}
                  >
                    <StepContainer hidden={index !== 0}>
                      <IntegrationDetailsFormItem />
                    </StepContainer>
                    <StepContainer hidden={index !== 1}>
                      <CustomEventFormItem />
                    </StepContainer>
                    <StepContainer hidden={index !== 2}>
                      <CustomDeviceFormItem />
                    </StepContainer>
                  </div>
                  <div className="flex items-center gap-1 ml-5">
                    {index > 0 && (
                      <Button
                        variant="contained"
                        className="shadow-none text-xs leading-[22px] font-normal px-8 bg-[#ddefff] text-[#007CE4] w-[70px]"
                        disabled={step === 0}
                        onClick={() => setStep((step) => step - 1)}
                      >
                        Back
                      </Button>
                    )}
                    {!lastStep && (
                      <Button
                        variant="contained"
                        color="primary"
                        className="shadow-none text-xs leading-[22px] font-normal px-8"
                        disabled={(step === 0 && !integrationName) || loading}
                        type="button"
                        onClick={async () => {
                          setStep((step) => step + 1);
                        }}
                      >
                        Next
                      </Button>
                    )}
                    {lastStep && (
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className="shadow-none text-xs leading-[22px] font-normal px-8"
                        disabled={loading}
                      >
                        Finish
                      </Button>
                    )}
                    {loading && <CircularProgress className="ml-3" size={16} />}
                  </div>
                </StepContent>
              </Step>
            );
          })}
        </Stepper>
        {hasErrors && (
          <div className="text-error font-bold text-sm pt-2">
            Please address the specified errors before continuing.
          </div>
        )}
      </form>
    </FormProvider>
  );
}
