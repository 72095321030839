import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton, IconButtonProps, Tooltip } from "@mui/material";
import { Dispatch, SetStateAction } from "react";

interface PasswordToggleButtonProps extends IconButtonProps {
  showPassword: boolean;
  setShowPassword: Dispatch<SetStateAction<boolean>>;
  fontSize: number;
}

export const PasswordToggleButton = ({
  showPassword,
  setShowPassword,
  fontSize,
  ...buttonProps
}: PasswordToggleButtonProps) => (
  <Tooltip title={showPassword ? "Hide Password" : "Show Password"}>
    <IconButton
      size="small"
      aria-label="toggle password visibility"
      {...buttonProps}
      onClick={() => setShowPassword((currentState) => !currentState)}
    >
      {showPassword ? (
        <Visibility style={{ fontSize }} />
      ) : (
        <VisibilityOff style={{ fontSize }} />
      )}
    </IconButton>
  </Tooltip>
);
