export function bestMatch<T>(
  list: Iterable<T>,
  comparator: (a: T, b: T) => number
) {
  const firstIterResult = list[Symbol.iterator]().next();
  if (firstIterResult.done) return undefined;
  let bestMatch = firstIterResult.value;
  for (const candidate of list) {
    bestMatch = comparator(candidate, bestMatch) < 0 ? candidate : bestMatch;
  }
  return bestMatch;
}
