import ClearIcon from "@mui/icons-material/Clear";
import { Button, Chip, Hidden } from "@mui/material";
import clsx from "clsx";
import { useAtom } from "jotai";
import { Fragment, useMemo } from "react";
import { ArrayParam, useQueryParams } from "use-query-params";

import { useBreakpoints } from "@/util/useBreakpoints";

import { auditLogActionsAtom } from "@/pages/Settings/AuditLogs/FilterAccordion";

import { FilterFieldProps } from "../../../components/Filter/constant";
import {
  useActiveFilter,
  buildQueryParamResult,
} from "../../../components/Filter/utils";

function FilterChipGroup<T>({ filters }: { filters: FilterFieldProps<T>[] }) {
  const { fitsDesktop } = useBreakpoints();
  const params = useMemo(() => {
    return buildQueryParamResult(filters, ArrayParam);
  }, [filters]);
  const [query, setQuery] = useQueryParams(params);
  const [, setAuditLogActions] = useAtom(auditLogActionsAtom);

  const chips = Object.entries(query).map(([key, value]) => {
    const filter = filters.find((f) => f.filterName === key);

    if (!value || !filter) return <Fragment key={key} />;

    const items = value.map((v) => {
      const option = filter.options.find((o) => `${o.value}` === v);

      return (
        <Chip
          classes={{
            outlinedPrimary: "font-medium my-1 truncate",
            deleteIconOutlinedColorPrimary: "w-4 h-4",
          }}
          size={fitsDesktop ? "medium" : "small"}
          deleteIcon={<ClearIcon />}
          variant="outlined"
          color="primary"
          key={`${key}-${v}`}
          label={
            filter.getValue
              ? filter.getValue((v as unknown) as T)
              : option?.label ?? v
          }
          onDelete={() => {
            const filters = [...(value || [])];
            setAuditLogActions((val) => {
              if (!val) return null;

              const index = val.indexOf(v);

              if (index > -1) {
                val.splice(index, 1);
              }

              return val;
            });
            filters.splice(filters.indexOf(v), 1);
            setQuery({ ...query, [key]: filters });
          }}
        />
      );
    });

    return items;
  });

  return <div className="capitalize flex items-center gap-2">{chips}</div>;
}

export function AuditLogFilterBar<T>({
  filters,
  className,
}: {
  className?: string;
  filters: FilterFieldProps<T>[];
}) {
  const { clearFilters, hasActiveFilter } = useActiveFilter(filters);
  const [, setAuditLogActions] = useAtom(auditLogActionsAtom);

  return (
    <>
      {hasActiveFilter && (
        <div
          className={clsx(
            className,
            "bg-white px-5 border-t border-[#007CE42B]"
          )}
        >
          <div className="flex items-center gap-x-4">
            <div className="flex items-center gap-x-4 flex-wrap">
              <Hidden mdDown>
                <div className="flex flex-wrap items-start gap-x-1">
                  <FilterChipGroup filters={filters} />
                </div>
              </Hidden>
            </div>
            <Button
              onClick={() => clearFilters(() => setAuditLogActions(null))}
              size="small"
              color="primary"
              className={clsx("leading-4 font-normal ml-auto text-xs")}
            >
              Clear All
            </Button>
          </div>
          <Hidden mdUp>
            <div className="flex flex-wrap items-center gap-x-1">
              <FilterChipGroup filters={filters} />
            </div>
          </Hidden>
        </div>
      )}
    </>
  );
}
