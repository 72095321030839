import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import { Button, Divider, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import clsx from "clsx";
import { useField } from "formik";
import { useAtomValue } from "jotai";
import { startCase } from "lodash";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { pluralize } from "@/util/pluralize";
import { useBreakpoints } from "@/util/useBreakpoints";

import { InterestListItemInput, InterestListType } from "@/generated-models";

import { InterestListNoteInput } from ".";
import { InterestListConfig, interestListConfig } from "../constants";
import { currentNoteNameAtom } from "../hooks";

const headerCellClass = "py-2 bg-[#F4F4F4]";

function InterestListDetailsSummaryTableHeader() {
  return (
    <TableHead>
      <TableRow>
        <TableCell className={headerCellClass}>Plate Number</TableCell>
        <TableCell
          className={clsx(headerCellClass, "text-[#353D48]/70")}
          align="left"
        >
          Source
        </TableCell>
        <TableCell
          className={clsx(headerCellClass, "text-[#353D48]/70")}
          align="left"
        >
          Notes
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

function InterestListDetailsSummaryTableNoteForm({
  row,
  noteContent,
  setNoteContent,
  config,
  onCloseEdit,
}: {
  row: InterestListItemInput;
  noteContent: string;
  setNoteContent: Dispatch<SetStateAction<string>>;
  config: InterestListConfig;
  onCloseEdit: () => void;
}) {
  const [, { value }, { setValue }] = useField<InterestListItemInput[]>(
    "items"
  );

  const itemIdx = value.findIndex((i) => i.name === row.name);

  return (
    <TableRow>
      <TableCell colSpan={3}>
        <div className="flex flex-col gap-3">
          <Typography className="font-bold font-mono text-sm tracking-[1.4px] uppercase">
            {row.name}
          </Typography>
          <Typography className="text-sm leading-[16.41px] text-[#757575]">
            {config.notePrompt}
          </Typography>

          <InterestListNoteInput
            notes={noteContent}
            onClear={onCloseEdit}
            onChange={(e) => {
              setNoteContent(e);
            }}
          />

          <div className="flex items-center gap-2 ml-auto">
            <Button
              variant="contained"
              color="primary"
              className="shadow-none text-sm leading-[22px] font-normal rounded-md bg-[#DAEEFF] text-primary py-1 px-[10px]"
              onClick={onCloseEdit}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="shadow-none text-sm leading-[22px] font-normal rounded-md py-1 px-[10px]"
              onClick={() => {
                const newValue = [...value];
                newValue.splice(itemIdx, 1, {
                  ...newValue[itemIdx],
                  notes: noteContent,
                });
                setValue(newValue);
                onCloseEdit();
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
}

export function InterestListDetailsSummaryTable({
  type,
}: {
  type: InterestListType;
}) {
  const currentNoteName = useAtomValue(currentNoteNameAtom);

  const [addingNote, setAddingNote] = useState<string | null>(
    currentNoteName || ""
  );
  const [noteContent, setNoteContent] = useState("");
  const { fitsTablet } = useBreakpoints();
  const config = interestListConfig[type];

  const [, { value }, { setValue }] = useField<InterestListItemInput[]>(
    "items"
  );

  useEffect(() => {
    if (currentNoteName) {
      const note = value.find((v) => v.name === currentNoteName);
      setNoteContent(note?.notes || "");
    }
  }, [currentNoteName, value]);

  if (value.length === 0) return <></>;
  const label = pluralize(
    {
      1: `${config.resourceName}`,
      multi: `${config.resourceName}s`,
    },
    value.length
  );

  const onCloseEdit = () => {
    setAddingNote(null);
    setNoteContent("");
  };

  return (
    <>
      <Divider orientation={fitsTablet ? "vertical" : "horizontal"} flexItem />
      <div className="w-[500px] flex flex-col self-stretch gap-4">
        <Typography className="font-bold text-lg leading-[21.09px]">{`${
          value.length
        } ${startCase(label)} Added`}</Typography>

        <TableContainer
          classes={{
            root: "rounded-lg shadow-[0_0_15px_rgba(0,0,0,0.15)] max-h-[500px]",
          }}
          component={Paper}
        >
          <Table stickyHeader classes={{ root: "min-w-[500px]" }}>
            <InterestListDetailsSummaryTableHeader />
            <TableBody>
              {value
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((row) =>
                  addingNote === row.name ? (
                    <InterestListDetailsSummaryTableNoteForm
                      row={row}
                      config={config}
                      noteContent={noteContent}
                      setNoteContent={setNoteContent}
                      onCloseEdit={onCloseEdit}
                    />
                  ) : (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        <div className="flex items-center justify-start gap-1">
                          <Typography className="font-bold font-mono text-sm tracking-[1.4px] uppercase">
                            {row.name}
                          </Typography>
                          <button
                            onClick={() => {
                              setValue(
                                [...value].filter((v) => v.name !== row.name)
                              );
                            }}
                            type="button"
                            className="bg-transparent -mt-0.5"
                          >
                            <DeleteForeverIcon className="text-[#BDBDBD] w-4 h-4" />
                          </button>
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        {row.source ? "CSV File" : "Manual"}
                      </TableCell>
                      <TableCell align="left">
                        <button
                          onClick={() => {
                            setAddingNote(row.name);
                            setNoteContent(row.notes || "");
                          }}
                          type="button"
                          className="bg-transparent text-primary text-sm leading-[16.41px]"
                        >
                          {row.notes && (
                            <StickyNote2OutlinedIcon className="mr-0.5 w-4 h-4 -mt-0.5" />
                          )}
                          {row.notes ? "View/Edit" : "+ Add Note"}
                        </button>
                      </TableCell>
                    </TableRow>
                  )
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}
