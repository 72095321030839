import HelpIcon from "@mui/icons-material/Help";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Typography } from "@mui/material";
import { useMemo } from "react";

import { useMe } from "@/components/Auth";
import { DataGrid } from "@/components/DataGrid/DataGrid";
import { getLicenseResourceName } from "@/components/InternalTools/Licenses/utils";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";

import {
  FeatureLicenseSeat,
  useUpdateFeatureLicenseSeatMutation,
} from "@/generated-models";

const appleTVHelpCenterLink =
  "https://help.spot.ai/hc/en-us/articles/19468254331405-Spot-Display-32-Camera-Video-Wall-on-Apple-TV";

export function AppleTVLicenseTable({ data }: { data: FeatureLicenseSeat[] }) {
  const me = useMe();
  const { pushSnackbar } = useFeedback();
  const rows = useMemo(() => {
    return [...data]
      .sort((a, b) => a.createdAt.localeCompare(b.createdAt))
      .filter((a) => a.organizationId === me?.organization.id)
      .map((r, idx) => ({
        ...r,
        idx,
      }));
  }, [data, me?.organization.id]);

  const [deactivateLicense, { loading }] = useUpdateFeatureLicenseSeatMutation({
    refetchQueries: ["featureLicenseSeats"],
    onCompleted: () => {
      pushSnackbar(`License deactivated`, FeedbackType.Success);
    },
    onError: () =>
      pushSnackbar(
        "Failed to deactivate license, please try again later",
        FeedbackType.Error
      ),
  });

  return (
    <div className="flex flex-col items-end gap-4">
      <DataGrid
        getRowId={({ id }) => id}
        className="min-h-[300px] min-w-[370px]"
        rowHeight={58}
        columnHeaderHeight={40}
        rows={rows}
        classes={{
          columnHeader: "bg-white",
        }}
        initialState={{
          pagination: { paginationModel: { pageSize: 5 } },
          sorting: { sortModel: [{ field: "idx", sort: "asc" }] },
        }}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "" : "bg-[#F6FBFF]"
        }
        columns={[
          {
            field: "idx",
            headerName: "Licenses",
            width: 105,
            renderCell: ({ value }) => {
              return `License ${value + 1}`;
            },
          },
          {
            field: "deviceId",
            headerName: "Apple TV Device ID",
            flex: 1,
            valueGetter: ({ row }) => {
              return getLicenseResourceName(row);
            },
            renderCell: ({ row, value }) => {
              if (value)
                return (
                  <div className="flex items-center gap-0.5">
                    <Typography className="font-bold uppercase text-xs">
                      {value}
                    </Typography>
                    <button
                      type="button"
                      className="bg-transparent text-primary disabled:opacity-40 -mt-0.5"
                      disabled={loading}
                      onClick={() => {
                        deactivateLicense({
                          variables: {
                            id: row.id,
                            input: {
                              activated: false,
                              data: {
                                mac: "",
                              },
                            },
                          },
                        });
                      }}
                    >
                      <HighlightOffIcon className="text-lg -mt-0.5" />
                    </button>
                  </div>
                );
              return (
                <span className="italic text-sm text-[#18D710]">Available</span>
              );
            },
          },
        ]}
      />
      <a
        className="flex items-center"
        href={appleTVHelpCenterLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        <HelpIcon className="fill-[#007de4] mr-1" />{" "}
        <Typography className="text-[#007de4] text-sm">Setup Guide</Typography>
      </a>
    </div>
  );
}
