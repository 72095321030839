import { Button, Grid, Hidden, Typography } from "@mui/material";
import React from "react";
import { Link, Route, Routes } from "react-router-dom";

import { useDocumentTitle } from "@/util/useDocumentTitle";

import { ComplianceCreate } from "@/pages/Settings/ComplianceReports/ComplianceCreate";

export function ComplianceReports() {
  useDocumentTitle("Compliance");

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className="p-4"
      >
        <Typography variant="h1">1-Click Compliance</Typography>
      </Grid>
      <div className="shadow-divider p-4">
        <Routes>
          <Route index element={<ComplianceOverview />} />
          <Route path="report" element={<ComplianceCreate />} />
        </Routes>
      </div>
    </>
  );
}

function ComplianceOverview() {
  return (
    <Grid container className="p-4">
      <Hidden mdDown>
        <Grid item xs={5} className="flex-center">
          <img
            src="/one-click-compliance.svg"
            alt="illustration"
            className="w-full"
          />
        </Grid>
      </Hidden>
      <Grid item xs={12} md={7}>
        <Typography variant="h2" className="text-5xl font-light text-primary">
          Get in compliance
        </Typography>
        <Typography variant="body1" className="mt-4 text-sm">
          1- Click Compliance allows you to easily generate reports to show that
          your surveillance capabilities are compliant with government
          legislation.
        </Typography>
        <Typography variant="body1" className="mt-4 text-sm">
          You can generate reports with all your state’s requirements, choose
          specific documents for spot-check audits, and save report templates to
          generate at a moment’s notice.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          className="text-base font-bold mt-8 w-52"
          component={Link}
          to="report"
        >
          Create a New Report
        </Button>
      </Grid>
    </Grid>
  );
}
