import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

interface FeedbackDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  description: string;
  messages?: string[];
}

export function FeedbackDialog({
  open,
  onClose,
  title,
  description,
  messages,
}: FeedbackDialogProps) {
  return (
    <Dialog
      classes={{ paper: "rounded-lg max-w-[493px]" }}
      open={open}
      onClose={onClose}
    >
      <DialogTitle className="flex justify-between items-center py-4 bg-[#F4F4F4]">
        <h3 className="font-bold text-xl">{title}</h3>
      </DialogTitle>
      <DialogContent className="p-6">
        <DialogContentText className="text-text text-sm leading-4">
          <p className="text-sm leading-4 mb-2">{description}</p>
          {messages && (
            <p className="text-sm leading-4 mb-2">
              <ul className="list-disc px-[14px]">
                {messages.map((m) => (
                  <li key={m}>{m}</li>
                ))}
              </ul>
            </p>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
