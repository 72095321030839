import gql from "graphql-tag";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { AnalyticsViewType, trackView } from "@/util/analytics";

import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { GuestLivePlayer } from "@/components/Player/GuestPlayer";
import { LiveStatusIndicator } from "@/components/StatusIndicators";

import {
  CameraSharingInput,
  CameraStatus,
  useDisableCameraSharingMutation,
  useGetSharedCameraQuery,
  useUpdateCameraSharingInfoMutation,
} from "@/generated-models";
import {
  GuestErrorLayout,
  GuestLayout,
  SharedLinkError,
} from "@/layout/GuestLayout";

const useStyles = makeStyles()((theme) => ({
  player: {
    gridArea: "player",
  },
}));

export const GET_SHARED_CAMERA = gql`
  query getSharedCamera($token: String!) {
    sharedCamera(cameraToken: $token) {
      ... on SharedCamera {
        id
        name
        feed
        sharing {
          id
          expiry
          description
          createdAt
          createdBy
        }
        sharingPermissions
        audioControlEnabled
        location {
          name
          timezone
        }
      }
      ... on ErrorResult {
        code
        message
      }
      ... on SharedCameraExpired {
        createdBy
      }
    }
  }
`;

export function GuestCameraView() {
  const { classes } = useStyles();
  const { token } = useParams();

  const { loading, data, error } = useGetSharedCameraQuery({
    variables: { token: token ?? "" },
  });

  useEffect(() => {
    if (loading || !data?.sharedCamera || error) return;
    trackView(AnalyticsViewType.live, true);
  }, [loading, data, error]);
  const [disableCameraSharing] = useDisableCameraSharingMutation();
  const [updateSharingInfo] = useUpdateCameraSharingInfoMutation();

  if (error) {
    return (
      <ErrorMessage
        title="Oops"
        description="We could not find the shared camera. Possibly this sharing link has been revoked."
      />
    );
  }
  if (loading || !data?.sharedCamera) return <Loading>Loading...</Loading>;

  const camera = data.sharedCamera;

  if (camera.__typename !== "SharedCamera") {
    const error = camera;
    const description =
      error.__typename === "SharedCameraExpired" && error.createdBy ? (
        <>
          Ask the creator,{" "}
          <strong style={{ color: "white" }}>{error.createdBy}</strong> to
          extend the clip's expiration date
        </>
      ) : (
        <>
          This might mean the clip has been deleted or you have a wrong link.
          Please contact the owner of the Spot account to request a new link.
        </>
      );
    return (
      <GuestErrorLayout>
        <SharedLinkError title={error.message} description={description} />
      </GuestErrorLayout>
    );
  }

  function update(updates: CameraSharingInput) {
    if (data?.sharedCamera?.__typename !== "SharedCamera") return;

    updateSharingInfo({
      variables: {
        id: data.sharedCamera!.id,
        updates,
      },
    });
  }

  return (
    <GuestLayout
      player={
        <GuestLivePlayer
          className={classes.player}
          cameraId={camera.id}
          cameraName={camera.name}
          locationName={camera.location.name}
          sources={{
            tunnel: camera.feed,
            local: "",
            webRTC: null,
          }}
          timezone={camera.location.timezone}
          audioControlEnabled={camera.audioControlEnabled}
        />
      }
      title={
        <>
          <LiveStatusIndicator status={CameraStatus.Online} /> Shared Camera
          Feed (live)
        </>
      }
      expiry={camera.sharing.expiry ?? null}
      onExpiryChange={
        camera.sharingPermissions
          ? (expiry) =>
              update({
                // Please don't convert this to optional chaining
                // because that will coerce null to undefined, which
                // means something else in the context of this mutation
                expiry: expiry && expiry.toISOString(),
              })
          : undefined
      }
      onDisable={
        camera.sharingPermissions
          ? () =>
              disableCameraSharing({
                variables: { ids: [camera!.id] },
              })
          : undefined
      }
      description={camera.sharing.description}
      onDescriptionChange={
        camera.sharingPermissions
          ? (description) =>
              update({
                description,
              })
          : undefined
      }
      locationName={camera.location.name}
      createdAt={camera.sharing.createdAt}
      createdBy={camera.sharing.createdBy ?? undefined}
    />
  );
}
