import { GraphQLErrors } from "@apollo/client/errors";
import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import {
  Button,
  IconButton,
  Typography,
  Dialog,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { GridColDef } from "@mui/x-data-grid";
import { formatISO } from "date-fns";
import Papa, { ParseError } from "papaparse";
import { useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";

import { useBreakpoints } from "@/util/useBreakpoints";

import { DataGrid } from "@/components/DataGrid/DataGrid";
import FileUploader from "@/components/FileUploader/FileUploader";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { useDialog } from "@/components/shared/Dialog";

import {
  useImportIntegrationEventsMutation,
  IntegrationEventImportInput,
} from "@/generated-models";

import { generateFormSchema } from "../Form/Event/schemaUtils";
import {
  IntegrationFormEventChildren,
  IntegrationFormEventPropertyField,
} from "../Form/Event/utils";
import {
  IntegrationNestedChild,
  renderCellValue,
} from "../IntegrationNestedChild";
import { useCurrentIntegrationDevices } from "../hooks/deviceHooks";
import {
  useCurrentIntegrationEventTypes,
  useCurrentIntegrationEvents,
} from "../hooks/eventHooks";
import { useCurrentIntegrationId } from "../hooks/integrationHooks";

const newCols: GridColDef[] = [
  { field: "integrationDeviceId", headerName: "Device Id", flex: 1 },
  {
    field: "integrationEventTypeId",
    headerName: "Integration Event Type Id",
    flex: 1,
  },
  { field: "timestamp", headerName: "Timestamp", flex: 1 },
  { field: "buffer", headerName: "Buffer", flex: 1 },
  { field: "duration", headerName: "Duration", flex: 1 },
];

interface FilesToUploadType {
  name: string;
  integrationEvents: IntegrationEventImportInput[];
}

interface InternalValidationErrorDetails {
  field: string;
  message: string;
  value?: any;
}

interface InternalvalidationException {
  details: InternalValidationErrorDetails[];
}

interface ImportErrorMessageProps {
  graphQLErrors?: GraphQLErrors;
  messages?: ParseError[];
}

const ImportErrorMessage = ({
  messages,
  graphQLErrors,
}: ImportErrorMessageProps) => {
  if (graphQLErrors) {
    return (
      <div className="flex flex-col">
        <p>
          There was an error processing your CSV. Please find fix the following
          issues:
        </p>
        {graphQLErrors.map((errorObj, index) => {
          return (
            <div className="mt-2" key={`${errorObj.message}-${index}`}>
              <p>{errorObj.message}</p>
              <ul>
                {(errorObj.extensions
                  ?.exception as InternalvalidationException)?.details?.map(
                  (error: any) => {
                    return (
                      <li key={`${error.message}-${index}`}>
                        - {error.message}
                      </li>
                    );
                  }
                )}
              </ul>
            </div>
          );
        })}
      </div>
    );
  }

  if (messages) {
    return (
      <div className="flex flex-col">
        <p>
          There was an error processing your CSV. Please find fix the following
          issues:
        </p>
        <ul>
          {messages.map((message, index) => (
            <li key={`${message.code}-${index}`}>
              - <strong>{message.code}</strong>: {message.message}
            </li>
          ))}
        </ul>
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <p>
        There was an error processing your CSV. Please fix your CSV format and
        try again.
      </p>
    </div>
  );
};

const DetailHeader = ({ description }: { description: string }) => (
  <header className="relative">
    <div className="flex items-center justify-between">
      <p>{description}</p>
    </div>
  </header>
);

export function IntegrationCSVEventImportButton() {
  const integrationId = useCurrentIntegrationId();
  const {
    startPolling,
    stopPolling,
    eventsCount,
  } = useCurrentIntegrationEvents();
  const [showSchema, setShowSchema] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [importCount, setImportCount] = useState(0);
  const [tabValue, setTabValue] = useState(0);
  const [currentPreview, setCurrentPreview] = useState<
    FilesToUploadType | undefined
  >(undefined);
  const { pushSnackbar } = useFeedback();
  const { open, cancel, ...dialogProps } = useDialog();
  const {
    open: openPreview,
    cancel: cancelPreview,
    ...previewDialogProps
  } = useDialog();
  const [filesToUpload, setFilesToUpload] = useState<FilesToUploadType[]>([]);
  const [
    importEvents,
    { loading: eventsImporting },
  ] = useImportIntegrationEventsMutation({
    refetchQueries: ["integrationEventsV2", "integrationEventTypesV2"],
    onCompleted: () => {
      pushSnackbar("Successfully imported events", FeedbackType.Success);
      handleClose();
      pushSnackbar("Processing imported events...", FeedbackType.Info);
      startPolling(1000);
    },
    onError: (errors) => {
      pushSnackbar(
        <ImportErrorMessage
          graphQLErrors={
            errors.graphQLErrors.length > 0 ? errors.graphQLErrors : undefined
          }
        />,
        FeedbackType.Error
      );
    },
  });

  useEffect(() => {
    if (importCount > 0 && importCount === eventsCount) {
      stopPolling();
      setImportCount(0);
      pushSnackbar("Events imported successfully", FeedbackType.Success);
    }
  }, [importCount, stopPolling, eventsCount, pushSnackbar]);

  function deleteFile(index: number) {
    setFilesToUpload((prev) => {
      const copy = [...prev];
      copy.splice(index, 1);
      return copy;
    });
  }

  function handleClose() {
    setFilesToUpload([]);
    setCurrentPreview(undefined);
    cancel();
  }

  function handleConfirm() {
    for (const file of filesToUpload) {
      importEvents({
        variables: {
          input: {
            integrationId,
            integrationEvents: file.integrationEvents,
          },
        },
      });
    }
  }

  function openPreviewDialog(file: FilesToUploadType) {
    openPreview();
    setCurrentPreview(file);
  }

  const { previewColumns, previewRows } = useMemo(() => {
    const previewColumns: GridColDef[] = [];
    const previewRows: any[] = [];

    if (!currentPreview) return { previewColumns, previewRows };

    for (const previewEvent of currentPreview.integrationEvents) {
      const { attributes } = previewEvent;
      const attributeDetails: Record<string, string> = {};

      for (const [key, value] of Object.entries(attributes)) {
        const isNested = typeof value === "object" || Array.isArray(value);
        if (!previewColumns.find((c) => c.field === key)) {
          previewColumns.push({
            field: key,
            headerName: key,
            flex: 1,
            renderCell: ({ value }) =>
              isNested ? (
                IntegrationNestedChild({
                  row: value,
                  path: key,
                })
              ) : (
                <span>{renderCellValue(value)}</span>
              ),
          });
        }

        attributeDetails[key] = value as string;
      }

      previewRows.push({
        id: crypto.randomUUID(),
        ...previewEvent,
        ...attributeDetails,
      });
    }

    return {
      previewColumns,
      previewRows,
    };
  }, [currentPreview]);

  const ImportPreview = ({ name }: { name: string }) => {
    const { fitsDesktop } = useBreakpoints();

    return (
      <Dialog
        onClose={cancelPreview}
        open={previewDialogProps.opened}
        onClick={(e) => e.stopPropagation()}
        maxWidth="lg"
      >
        <div className="flex flex-col p-5 gap-4">
          <>
            <header className="flex items-center justify-between">
              <p className="font-bold text-xl">File Preview: {name}</p>
              <IconButton size="small" onClick={cancelPreview}>
                <CloseIcon />
              </IconButton>
            </header>
            <div>
              <DataGrid
                rows={previewRows}
                columns={[...newCols, ...previewColumns]}
                rowHeight={fitsDesktop ? 48 : 110}
                classes={{
                  cell: "outline-none",
                }}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                pageSizeOptions={[5]}
              />
            </div>
          </>
        </div>
      </Dialog>
    );
  };

  const ImportBlock = () => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      disabled: uploading,
      accept: {
        "text/csv": [".csv"],
      },
      maxSize: 10e6,
      onDropAccepted: async (files) => {
        setUploading(true);
        for (const file of files) {
          Papa.parse(file, {
            complete: function (results) {
              if (results.errors.length > 0) {
                pushSnackbar(
                  <ImportErrorMessage messages={results.errors} />,
                  FeedbackType.Error
                );
                return;
              }

              try {
                const integrationEvents = csvToEventArray(
                  results.data as string[][]
                );

                setImportCount(integrationEvents.length + eventsCount);
                setFilesToUpload((prev) => [
                  ...prev,
                  {
                    name: file.name,
                    integrationEvents,
                  },
                ]);
              } catch (error: any) {
                pushSnackbar(error.message, FeedbackType.Error);
              }
            },
            error: function (err: ParseError) {
              pushSnackbar(err.message, FeedbackType.Error);
            },
          });
        }
        setUploading(false);
      },
      onDropRejected: (rejections) => {
        if (rejections[0]) {
          pushSnackbar(rejections[0].errors[0].message, FeedbackType.Error);
        }
      },
    });

    return (
      <>
        <header>
          <p className="font-bold text-xl">Import Events</p>
          <p>
            Import events based on your{" "}
            <button
              onClick={() => {
                setShowSchema(true);
              }}
              type="button"
              className="bg-transparent text-primary"
            >
              event types and schema
            </button>
          </p>
        </header>
        <div className="rounded-lg border border-solid border-[#F0F0F0] p-3 bg-gray-fb">
          <p className="text-base">
            <strong>API Import</strong> (Automatic)
          </p>
          <p className="text-sm">
            To set up automatic imports, consult{" "}
            <a
              className="text-primary"
              href="https://developers.spot.ai/reference/createintegrationevents"
              target="_blank"
              rel="noopener noreferrer"
            >
              our API documentation
            </a>
          </p>
        </div>
        <div className="rounded-lg border border-solid border-[#F0F0F0] p-3 bg-gray-fb flex gap-4 flex-col">
          <div>
            <p className="text-base">
              <strong>CSV Import</strong> (Manual)
            </p>
            <p className="text-sm">
              Manually import events by uploading CSV files
            </p>
          </div>
          <FileUploader
            loading={uploading}
            state={{
              getRootProps,
              getInputProps,
              isDragActive,
            }}
          />
          {filesToUpload && filesToUpload.length > 0 && (
            <div>
              <p className="text-base font-bold">Files to Upload</p>
              <ul>
                {filesToUpload.map((file, index) => (
                  <li
                    key={`${file.name}-${index}`}
                    className="flex items-center justify-between"
                  >
                    <div className=" flex items-center gap-1">
                      <FilePresentIcon className="text-primary h-5 w-5" />
                      <button
                        className="font-bold text-base text-primary bg-transparent"
                        onClick={() => openPreviewDialog(file)}
                      >
                        {file.name}
                      </button>
                    </div>
                    <IconButton
                      size="small"
                      onClick={() => {
                        deleteFile(index);
                      }}
                    >
                      <DeleteForeverIcon
                        fontSize="small"
                        className="text-gray-75"
                      />
                    </IconButton>
                  </li>
                ))}
              </ul>
            </div>
          )}
          <DialogActions>
            <Button
              className="bg-[#DAEEFF] text-[#007CE4] border-[#DAEEFF]"
              onClick={handleClose}
              color="primary"
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              className="bg-primary text-white"
              onClick={handleConfirm}
              variant="contained"
              autoFocus
            >
              Upload
            </Button>
          </DialogActions>
        </div>
      </>
    );
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Button
        variant="contained"
        className="rounded-md text-primary bg-[#DAEEFF] hover:bg-primary/20 font-normal text-sm shadow-none h-9"
        onClick={open}
      >
        Import Events
      </Button>
      <Dialog onClose={handleClose} open={dialogProps.opened} maxWidth="xs">
        <div className="flex flex-col p-5 gap-4 relative">
          {showSchema && (
            <>
              <Tabs value={tabValue} onChange={handleTabChange}>
                <Tab className="text-xl" label="Events" />
                <Tab className="text-xl" label="Devices" />
              </Tabs>
              {tabValue === 0 && (
                <>
                  <DetailHeader description="Event names and schema for this integration" />
                  <EventDetails />
                </>
              )}
              {tabValue === 1 && (
                <>
                  <DetailHeader description="Devices for this integration" />
                  <DeviceDetails />
                </>
              )}
              <IconButton
                className="absolute top-2 right-2 text-[#bdbdbd]"
                size="small"
                onClick={() => {
                  setShowSchema(false);
                  setTabValue(0);
                }}
              >
                <CloseIcon />
              </IconButton>
            </>
          )}
          {!showSchema && <ImportBlock />}
        </div>
        {eventsImporting && (
          <div className="absolute inset-0 w-full h-full bg-black/20 flex items-center justify-center">
            <CircularProgress />
          </div>
        )}
      </Dialog>
      {currentPreview && <ImportPreview name={currentPreview.name} />}
    </>
  );
}

interface EventDetailsRowProps {
  integrationEventTypeId: any;
  integrationDeviceId: any;
  timestamp: any;
  attributes: any;
}

function EventDetailsRow({
  item,
}: {
  item: IntegrationFormEventPropertyField & IntegrationFormEventChildren;
}) {
  const children = item.children || [];
  return (
    <>
      <Typography className="text-sm">
        {item.name} -{" "}
        <span className="text-[#757575]">
          {item.type}
          {item.required ? " (required)" : ""}
        </span>
      </Typography>
      {children.length > 0 && (
        <div className="px-[18px]">
          {children.map((c) => (
            <EventDetailsRow key={c.name} item={c} />
          ))}
        </div>
      )}
    </>
  );
}

function EventDetails() {
  const { types } = useCurrentIntegrationEventTypes();

  return (
    <div className="flex flex-col gap-4">
      {types.map((t) => {
        const properties = generateFormSchema(t.schema);
        return (
          <div
            className="p-2 rounded bg-[#FBFBFB] flex flex-col gap1"
            key={t.id}
          >
            <Typography className="text-base">
              <span className="font-bold">{t.name}</span> - ID: {t.id}
            </Typography>
            {properties.map((p) => (
              <EventDetailsRow key={p.name} item={p} />
            ))}
          </div>
        );
      })}
    </div>
  );
}

function DeviceDetails() {
  const { devices } = useCurrentIntegrationDevices();

  return (
    <div className="flex flex-col gap-4">
      {devices.map((d) => {
        return (
          <div
            className="p-2 rounded bg-[#FBFBFB] flex flex-col gap1"
            key={d.id}
          >
            <Typography className="text-base">
              <span className="font-bold">{d.name}</span> - ID: {d.id}
            </Typography>
            <Typography className="text-sm">
              {d.cameras.length} {d.cameras.length === 1 ? "Camera" : "Cameras"}{" "}
              Linked
            </Typography>
            {d.tags.length > 0 && (
              <div className="mt-2 flex gap-1">
                {d.tags.map((tag, index) => (
                  <Chip
                    key={`tag-${tag}-${index}`}
                    color="primary"
                    size="small"
                    variant="outlined"
                    label={tag}
                  />
                ))}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

function csvToEventArray(rows: string[][]) {
  const defaultHeaders = [
    "integrationEventTypeId",
    "integrationDeviceId",
    "timestamp",
    "attributes",
  ];

  const headers = rows.shift();
  if (!headers) throw new Error("No headers found");

  if (rows[rows.length - 1].length === 1) {
    rows.pop();
  }

  // Ensure each default header is present
  for (const header of headers) {
    if (!defaultHeaders.includes(header)) {
      throw new Error(`Invalid header: ${header}`);
    }
  }

  const arr = rows.map((row) => {
    const el = headers.reduce<EventDetailsRowProps>(
      (object, header: any, index) => {
        return {
          ...object,
          [header]: parseData(row[index], header),
        };
      },
      {} as EventDetailsRowProps
    );
    return el;
  });

  return arr;
}

function parseData(data: string, key: string) {
  switch (key) {
    case "attributes":
      return JSON.parse(data);
    case "integrationEventTypeId":
    case "integrationDeviceId":
      return Number(data);
    case "timestamp":
      const today = formatISO(new Date());
      if (today < data) {
        throw new Error(
          "Timestamp cannot be in the future. Please remove the row or fix the timestamp."
        );
      }
      return data;
    default:
      return data;
  }
}
