import {
  DateRange,
  LocationOn,
  SvgIconComponent,
  Timer,
  Videocam,
} from "@mui/icons-material";
import DropDownIcon from "@mui/icons-material/ArrowDropDown";
import CheckIcon from "@mui/icons-material/Check";
import LeftIcon from "@mui/icons-material/ChevronLeft";
import RightIcon from "@mui/icons-material/ChevronRight";
import {
  Button,
  Container,
  Divider,
  Hidden,
  IconButton,
  Link as MaterialLink,
  MenuItem,
  MenuList,
  OutlinedInput,
  Paper,
  Popover,
  Select,
  styled,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  PickersDay,
  PickersDayProps,
  StaticDatePicker,
} from "@mui/x-date-pickers";
import clsx from "clsx";
import {
  addDays,
  addMonths,
  addWeeks,
  differenceInDays,
  differenceInMinutes,
  endOfWeek,
  isSameDay,
  isWithinInterval,
  startOfWeek,
  subDays,
  subHours,
  subMonths,
} from "date-fns/fp";
import gql from "graphql-tag";
import { capitalize } from "lodash";
import React, { ReactElement, useMemo, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { Link, useLocation, useParams } from "react-router-dom";
import { useUpdateEffect } from "react-use";
import { BooleanParam, useQueryParam } from "use-query-params";

import { ReactComponent as ExpandHeatmap } from "@/icons/icon-expand.svg";

import {
  formatIsoDate,
  formatTimeString,
  getShorthandDayRange,
} from "@/util/date";
import { formatDurationSecs } from "@/util/formatDurationSecs";
import { useBreakpoints } from "@/util/useBreakpoints";
import { useDocumentTitle } from "@/util/useDocumentTitle";

import { PresenceDashboardDataLoader } from "@/pages/Intelligence/Presence/PresenceDashboardDataLoader";
import {
  CountDirection,
  CountDirectionLabel,
  HeatmapColorValues,
  IntelligenceFeatureType,
} from "@/pages/Intelligence/constants";

import { DateTimePicker } from "@/components/DateTimePicker/DateTimePicker";
import {
  DatePickerType,
  MobileDateTimePicker,
} from "@/components/DateTimePicker/DateTimeRangePicker";
import { StaticMonthPicker } from "@/components/DateTimePicker/MonthPicker";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { StillImage } from "@/components/Still/StillImage";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  IntelligenceDashboardType,
  JobStatus,
  useCameraByIdQuery,
  useIntelligenceDashboardContentQuery,
  useIntelligenceDashboardQuery,
} from "@/generated-models";

import { intelligentFiltersConfig } from "../Search/intelligence/intelligence";
import { ComparativeDashboard } from "./ComparativeDashboard/ComparativeDashboard";
import { CountDashboard, MobileCountDashboard } from "./Count/CountDashboard";
import { IntelligenceDashboardViewExportButton } from "./Export/IntelligenceDashboardViewExportButton";
import {
  HeatmapDashboard,
  MobileHeatmapDashboard,
} from "./Heatmap/HeatmapDashboard";
import { IntelligenceHeatmap } from "./Heatmap/IntelligenceHeatmap";
import { useHeatmap } from "./Heatmap/useHeatmap";
import { IdleDashboard, MobileIdleDashboard } from "./Idle/IdleDashboard";
import { IntLoadingIndicator } from "./IntLoadingIndicator";
import { LicensePlateInterestListReportPage } from "./LicensePlate/LicensePlateReport/LicensePlateInterestListReportPage";
import { LicensePlateReportPage } from "./LicensePlate/LicensePlateReport/LicensePlateReportPage";
import {
  useIntelligenceDateRange,
  useIntelligenceTimeframeText,
} from "./hooks";
import {
  getDashboardFeatureConfig,
  getDashboardFeatureType,
  getObjectLabel,
  getObjectTypeIcon,
} from "./utils";

export enum Range {
  Custom = "custom",
  Day = "day",
  Week = "week",
  Month = "month",
}

export function DashboardTypeIndicator({
  objectTypes,
  type,
  subtype,
}: {
  objectTypes: string[] | null | undefined;
  type: IntelligenceDashboardType;
  subtype?: string | null;
}) {
  const { fitsDesktop } = useBreakpoints();
  const indicatorFontSize = fitsDesktop ? "large" : "small";
  const config = getDashboardFeatureConfig(type, objectTypes);

  return (
    <div className="flex items-center">
      <Typography
        variant="h2"
        className={clsx(
          `text-base md:text-xl font-bold flex flex-row items-center`,
          getDashboardFeatureConfig(type, objectTypes).foreground.colorPrimary
        )}
      >
        {React.cloneElement(config.illustration.legacyIcon as ReactElement, {
          fontSize: indicatorFontSize,
          className: "mr-2 -mt-0.5 md:mt-0",
        })}
        <span className="align-middle">
          {config.label.displayName}
          {subtype && ` - ${CountDirectionLabel[subtype]}`}
        </span>
      </Typography>
    </div>
  );
}

function IntelligenceDashboardInfoField({
  Icon,
  label,
  value,
}: {
  Icon: SvgIconComponent;
  label: string;
  value: string;
}) {
  return (
    <div className="flex items-center">
      <Icon className="mr-1 md:mr-3 text-[#757575]" fontSize="small" />
      <Typography className="text-sm leading-[14px] md:text-base truncate text-[#757575] font-bold md:block flex flex-col gap-x-[10px]">
        <span className={"text-[#9A9A9A] font-medium"}>{label}: &nbsp;</span>
        {value}
      </Typography>
    </div>
  );
}

export function IntelligenceDashboardView() {
  useDocumentTitle("Intelligence Dashboard");
  const [expandHeatmap, setExpandHeatmap] = useState(false);
  const location = useLocation();
  const { id } = useParams();
  const { data, error } = useIntelligenceDashboardQuery({
    ...refetchOnMountPolicy,
    variables: { id: Number(id) },
  });

  const intelligenceHeatmaps =
    data?.intelligenceDashboard?.type !== IntelligenceDashboardType.Count &&
    data?.intelligenceDashboard?.type !== IntelligenceDashboardType.Compound;

  const {
    date,
    range,
    startDate,
    endDate,
    setRange,
  } = useIntelligenceDateRange();

  const { data: heatmapData } = useHeatmap({
    intDashId: data?.intelligenceDashboard?.id || 0,
    input: {
      startDate: formatIsoDate(startDate),
      endDate: formatIsoDate(endDate),
      gridSize: 50,
    },
  });

  if (error) {
    return (
      <ErrorMessage
        title="Oops!"
        description="Failed to load intelligence dashboard. Please try again."
      />
    );
  }
  if (!data) return <Loading className="py-20" />;
  if (!data.intelligenceDashboard) {
    return (
      <ErrorMessage
        title="404"
        description={
          <span>
            The requested dashboard could not be found. Take me back to the{" "}
            <MaterialLink component={Link} to="intelligence">
              dashboard listing
            </MaterialLink>
            .
          </span>
        }
      />
    );
  }

  const dashboard = data.intelligenceDashboard;

  const config = getDashboardFeatureConfig(
    dashboard.type,
    dashboard.objectTypes
  );

  const TypeIcon = getObjectTypeIcon(dashboard.objectTypes, dashboard.type);

  if (dashboard.type === IntelligenceDashboardType.Compound) {
    return <ComparativeDashboard dashboard={dashboard} />;
  }

  if (dashboard.type === IntelligenceDashboardType.InterestList) {
    return <LicensePlateInterestListReportPage dashboard={dashboard} />;
  }

  if (dashboard.type === IntelligenceDashboardType.Report) {
    return <LicensePlateReportPage dashboard={dashboard} />;
  }

  const titleSection = (
    <>
      <IconButton
        component={Link}
        to={location?.state?.from || ".."}
        size="small"
        className="-ml-2 md:-ml-12 text-text"
      >
        <LeftIcon fontSize="large" />
      </IconButton>
      <div className="truncate">
        <Typography variant="h1" className="text-xl md:text-2xl truncate">
          {dashboard.name}
        </Typography>
      </div>
      <>
        <div className="ml-auto mr-2 block md:hidden">
          <IntelligenceDashboardViewExportButton
            dashboard={data.intelligenceDashboard}
            startDate={startDate}
            endDate={endDate}
            range={range}
          />
        </div>
        <Link
          to={`../edit/${dashboard.id}`}
          className="no-underline mr-2 block md:invisible text-primary"
        >
          Edit
        </Link>
      </>
    </>
  );

  const dashboardPreview = (
    <>
      {intelligenceHeatmaps &&
      heatmapData?.intelligenceDashboard?.heatmap.results &&
      heatmapData?.intelligenceDashboard?.heatmap.results.length > 0 ? (
        <div
          className={`relative md:h-48 aspect-video ${
            !expandHeatmap ? "block" : "hidden"
          }`}
        >
          <Button
            className="hidden md:flex absolute top-2 right-2 border border-white text-white bg-black/50 z-50"
            variant="outlined"
            startIcon={<ExpandHeatmap />}
            onClick={() => setExpandHeatmap(true)}
          >
            Expand Activity Map
          </Button>
          <IntelligenceHeatmap
            cameraId={dashboard.cameras[0].id}
            intDashId={dashboard.id}
            timezone={dashboard.cameras[0].location.timezone}
            startDate={startDate}
            endDate={endDate}
            showTooltip={false}
            colorValues={HeatmapColorValues[data.intelligenceDashboard.type]}
          />
        </div>
      ) : (
        <StillImage
          alt="Camera snapshot"
          src={dashboard.cameras[0].still}
          className="md:h-48 md:rounded-tl-lg"
        />
      )}
    </>
  );

  return (
    <Container maxWidth="lg" className="px-0 md:px-6 md:pt-8">
      <Paper elevation={0} className="md:rounded-lg md:shadow-paper">
        <Hidden mdUp>
          {expandHeatmap && (
            <MobileHeatmapDashboard
              setExpandHeatmap={setExpandHeatmap}
              cameraId={dashboard.cameras[0].id}
              intDashId={dashboard.id}
              timezone={dashboard.cameras[0].location.timezone}
              startDate={startDate}
              endDate={endDate}
              date={date}
              zone={dashboard.zone?.shape.map(
                ({ __typename, ...point }) => point
              )}
              entityType={getObjectLabel(dashboard.objectTypes, dashboard.type)}
              heatmapType={dashboard.type}
              datePicker={
                <DashboardDatePicker cameraId={dashboard.cameras[0].id} />
              }
            />
          )}
          <div className="top-0 sticky z-50">
            <div className="flex items-center justify-between sm:p-0 md:p-2 bg-white">
              {titleSection}
            </div>
            <div
              className="h-2.5 opacity-[.10]"
              style={{
                background:
                  "linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%)",
              }}
            ></div>
          </div>
        </Hidden>
        <div className="flex flex-col md:flex-row bg-[#f2f2f2] md:gap-12 md:mt-0 -mt-[10px]">
          <Hidden mdDown>{dashboardPreview}</Hidden>
          <div className="flex flex-col justify-center flex-grow gap-4 px-6 md:px-12 py-5 min-w-0">
            <Hidden mdDown>
              <div className="flex items-center gap-1">
                {titleSection}
                <div className="flex-grow" />
                <Button
                  color="primary"
                  variant="outlined"
                  to={`../edit/${dashboard.id}`}
                  component={Link}
                  className="bg-white flex-shrink-0"
                >
                  Edit
                </Button>
                <IntelligenceDashboardViewExportButton
                  dashboard={data.intelligenceDashboard}
                  startDate={startDate}
                  endDate={endDate}
                  range={range}
                />
              </div>
              <Divider />
            </Hidden>

            <div className="grid grid-cols-2 gap-3 gap-x-4 md:gap-x-8 self-start w-full md:pr-36 mb-4">
              <IntelligenceDashboardInfoField
                Icon={Videocam}
                label="Camera"
                value={dashboard.cameras[0].name}
              />

              {data.intelligenceDashboard.type ===
                IntelligenceDashboardType.Count && (
                <IntelligenceDashboardInfoField
                  Icon={TypeIcon}
                  label="Direction"
                  value={
                    dashboard.subtype
                      ? CountDirectionLabel[dashboard.subtype]
                      : ""
                  }
                />
              )}

              {data.intelligenceDashboard.type ===
                IntelligenceDashboardType.Idle && (
                <IntelligenceDashboardInfoField
                  Icon={Timer}
                  label={`${
                    dashboard.objectTypes
                      ? capitalize(
                          getObjectLabel(dashboard.objectTypes, dashboard.type)
                        )
                      : "Vehicle"
                  } Idle`}
                  value={formatDurationSecs(dashboard.thresholdSeconds, {
                    hideZeroes: true,
                    long: true,
                  })}
                />
              )}

              {data.intelligenceDashboard.type ===
                IntelligenceDashboardType.Presence && (
                <IntelligenceDashboardInfoField
                  Icon={TypeIcon}
                  label={`${capitalize(
                    getObjectLabel(dashboard.objectTypes, dashboard.type)
                  )} Presence`}
                  value={`${
                    data.intelligenceDashboard.entityCount
                  }+ ${capitalize(
                    getObjectLabel(
                      dashboard.objectTypes,
                      dashboard.type,
                      data.intelligenceDashboard.entityCount > 1
                    )
                  )}`}
                />
              )}

              <IntelligenceDashboardInfoField
                Icon={LocationOn}
                label="Location"
                value={dashboard.cameras[0].location.name}
              />

              <IntelligenceDashboardInfoField
                Icon={DateRange}
                label="Tracking"
                value={`${getShorthandDayRange(
                  dashboard.daysOfWeek
                )}, ${formatTimeString(
                  dashboard.startTime
                )} - ${formatTimeString(dashboard.endTime)}`}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col pb-6 md:pb-11 -mt-[10px] bg-transparent relative z-10">
          <div
            className={clsx(
              `flex flex-col-reverse items-center gap-2
              sm:flex-row sm:justify-between md:grid grid-cols-3 px-6 py-3 border-b-2 md:rounded-t-xl
              shadow-[0_-20px_25px__-5px_rgba(0,0,0,0.1)]`,
              getDashboardFeatureConfig(
                data.intelligenceDashboard.type,
                data.intelligenceDashboard.objectTypes
              ).border.legacyPrimary,
              getDashboardFeatureConfig(
                data.intelligenceDashboard.type,
                data.intelligenceDashboard.objectTypes
              ).background.colorTertiary
            )}
          >
            <Hidden mdDown>
              <DashboardTypeIndicator
                type={data.intelligenceDashboard.type}
                objectTypes={data.intelligenceDashboard.objectTypes}
                subtype={data.intelligenceDashboard.subtype}
              />
            </Hidden>
            <DashboardDatePicker
              cameraId={data.intelligenceDashboard.cameras[0]?.id}
            />
            <div className="flex justify-end items-center">
              <RangeToggle
                value={range}
                onChange={setRange}
                className={config.background.colorBase}
              />
            </div>
            <Hidden mdUp>
              <DashboardTypeIndicator
                type={data.intelligenceDashboard.type}
                objectTypes={data.intelligenceDashboard.objectTypes}
                subtype={data.intelligenceDashboard.subtype}
              />
            </Hidden>
          </div>
          {intelligenceHeatmaps &&
            heatmapData?.intelligenceDashboard?.heatmap.results &&
            heatmapData?.intelligenceDashboard?.heatmap.results.length > 0 && (
              <div
                className={`md:px-6 md:mt-6 ${
                  expandHeatmap ? "block" : "md:hidden"
                }`}
              >
                <HeatmapDashboard
                  setExpandHeatmap={setExpandHeatmap}
                  cameraId={dashboard.cameras[0].id}
                  intDashId={dashboard.id}
                  timezone={dashboard.cameras[0].location.timezone}
                  startDate={startDate}
                  endDate={endDate}
                  date={date}
                  entityType={getObjectLabel(
                    dashboard.objectTypes,
                    dashboard.type
                  )}
                  zone={dashboard.zone?.shape.map(
                    ({ __typename, ...point }) => point
                  )}
                  heatmapType={dashboard.type}
                />
              </div>
            )}
          <Hidden mdUp>
            {data.intelligenceDashboard.type !==
              IntelligenceDashboardType.Presence &&
              !(
                intelligenceHeatmaps &&
                heatmapData?.intelligenceDashboard?.heatmap.results &&
                heatmapData?.intelligenceDashboard?.heatmap.results.length > 0
              ) &&
              dashboardPreview}
          </Hidden>
          <div className="px-3 md:px-6">
            {dashboard.type === IntelligenceDashboardType.Presence && (
              <PresenceDashboardDataLoader
                id={dashboard.id}
                date={date}
                range={range}
                timezone={dashboard.cameras[0].location.timezone}
                cameraId={dashboard.cameras[0].id}
              />
            )}
            {dashboard.type === IntelligenceDashboardType.Idle && (
              <IdleDashboardDataLoader
                id={dashboard.id}
                objectTypes={dashboard.objectTypes}
                startDate={startDate}
                endDate={endDate}
                range={range}
                timezone={dashboard.cameras[0].location.timezone}
                cameraId={dashboard.cameras[0].id}
                zoneId={dashboard.zone?.id}
              />
            )}
            {dashboard.type === IntelligenceDashboardType.Count && (
              <CountDashboardDataLoader
                id={dashboard.id}
                objectTypes={dashboard.objectTypes}
                startDate={startDate}
                endDate={endDate}
                range={range}
                timezone={dashboard.cameras[0].location.timezone}
                cameraId={dashboard.cameras[0].id}
                zoneId={dashboard.zone?.id}
                subtype={dashboard.subtype as CountDirection}
                feature={getDashboardFeatureType(
                  dashboard.type,
                  dashboard.objectTypes
                )}
              />
            )}
          </div>
        </div>
      </Paper>
    </Container>
  );
}

const maxDate = new Date();
const MAX_CUSTOM_RANGE = 31;

const dateFuncByRange: Record<Range, (a: number, b: Date) => Date> = {
  [Range.Custom]: addDays,
  [Range.Day]: addDays,
  [Range.Week]: addWeeks,
  [Range.Month]: addMonths,
};

// const tomorrow = addDays(1, today);
export const presets = [
  { value: subHours(1, maxDate).getTime(), label: "Last Hour" },
  { value: subDays(1, maxDate).getTime(), label: "Last 24 Hours" },
  { value: subDays(7, maxDate).getTime(), label: "Last Week" },
  { value: subMonths(1, maxDate).getTime(), label: "Last Month" },
  { value: -1, label: "Custom" },
];

export function DashboardDatePicker({ cameraId }: { cameraId: number }) {
  const { fitsDesktop } = useBreakpoints();
  const timeframeText = useIntelligenceTimeframeText();

  const {
    date: activeDate,
    endDate: activeEndDate,
    range: activeRange,
    setRange: onRangeChange,
    setDate: onDateChange,
    setDateEnd: onDateEndChange,
  } = useIntelligenceDateRange();

  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [openedPicker, openPicker] = useState<DatePickerType | null>(null);
  const [invalid, setInvalid] = useState(false);
  const [value, setValue] = useState(activeDate);
  const [endValue, setEndValue] = useState(activeEndDate);
  const [range, setRange] = useState<Range>(activeRange as Range);
  const [pickerEl, setPickerEl] = useState<HTMLButtonElement | null>(null);
  const pickerOpen = Boolean(pickerEl);
  const { loading, data } = useCameraByIdQuery({
    variables: { cameraId },
  });
  const closePicker = () => {
    setPickerEl(null);
    setValue(activeDate);
    setEndValue(activeEndDate);
    setRange(range);
    setInvalid(false);
  };

  const isCustom = range === Range.Custom;
  const minDate = new Date(data?.camera?.createdAt || "2021-01-01T00:00:00");
  const difference = differenceInDays(value)(endValue);

  useUpdateEffect(() => {
    if (range === Range.Custom && !pickerOpen) {
      setValue(activeDate);
      setEndValue(activeEndDate);
      setPickerEl(buttonRef.current);
    }
  }, [range]);

  useUpdateEffect(() => {
    if (invalid) setInvalid(false);
  }, [setInvalid, value, endValue]);

  useUpdateEffect(() => setValue(activeDate), [activeDate]);
  useUpdateEffect(() => {
    if (isCustom) {
      setEndValue(activeEndDate);
    }
  }, [activeEndDate?.getTime()]);
  useUpdateEffect(() => setRange(activeRange as Range), [activeRange]);

  const sameValue = value.getTime() === activeDate.getTime();
  const sameEndValue = endValue.getTime() === activeEndDate.getTime();

  // Previous/next button stuff
  const dateFunc = dateFuncByRange[range] ?? addDays;
  const previousDate = dateFunc(-1, activeDate);
  const nextDate = dateFunc(1, activeDate);
  const previousDisabled =
    range === Range.Custom ||
    (range === Range.Week ? endOfWeek(previousDate) : previousDate) < minDate;
  const nextDisabled =
    range === Range.Custom ||
    (range === Range.Week ? startOfWeek(nextDate) : nextDate) > maxDate;

  const isCustomPreset = useMemo(() => {
    return !presets.some(
      (p) => differenceInMinutes(value, new Date(p.value)) === 0
    );
  }, [value]);

  // Keyboard shortcuts
  useHotkeys(
    "left",
    () => {
      if (!previousDisabled) {
        onDateChange((prev) => dateFunc(-1, prev ?? new Date()));
      }
    },
    undefined,
    [onDateChange, dateFunc, previousDisabled]
  );
  useHotkeys(
    "right",
    () => {
      if (!nextDisabled) {
        onDateChange((prev) => dateFunc(1, prev ?? new Date()));
      }
    },
    undefined,
    [onDateChange, dateFunc, nextDisabled]
  );

  const selectedCustomRange = useMemo(() => {
    return presets.find(({ value: presetValue }) => {
      return (
        (differenceInMinutes(value, new Date(presetValue)) === 0 &&
          endValue === maxDate) ||
        (presetValue === -1 && isCustomPreset)
      );
    });
  }, [endValue, isCustomPreset, value]);

  const customMenuItems = useMemo(() => {
    return presets.map(({ value: presetValue, label }, i) => {
      const selected =
        selectedCustomRange && selectedCustomRange.value === presetValue;

      return (
        <MenuItem
          className={clsx(
            "px-8 py-3 text-sm leading-[20px] tracking-[0.107px]",
            {
              "bg-[#353D48] text-white font-bold": selected,
            }
          )}
          key={i}
          onClick={() => {
            if (presetValue === -1) {
              setValue(new Date(subHours(8, maxDate)));
            } else {
              setValue(new Date(presetValue));
            }
            setEndValue(maxDate);
          }}
        >
          <CheckIcon
            className={clsx("pr-1", {
              invisible: !selected,
            })}
          />
          {label}
        </MenuItem>
      );
    });
  }, [selectedCustomRange]);

  if (loading) {
    return <></>;
  }

  return (
    <div className="flex-center gap-2">
      <Tooltip title={`Previous ${activeRange}`}>
        <IconButton
          size="small"
          disabled={previousDisabled}
          onClick={() => onDateChange(previousDate)}
        >
          <LeftIcon fontSize="large" />
        </IconButton>
      </Tooltip>
      <Button
        ref={buttonRef}
        className="text-lg md:text-xl font-bold"
        endIcon={
          <DropDownIcon
            className={clsx("text-2xl", { "rotate-180": pickerOpen })}
          />
        }
        onClick={(event) => setPickerEl(event.currentTarget)}
      >
        {timeframeText}
      </Button>
      <Popover
        open={pickerOpen}
        anchorEl={pickerEl}
        onClose={closePicker}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className="flex sm:flex-row flex-col">
          {range === Range.Custom && (
            <div className="bg-[#F4F4F4] flex items-center md:p-0 p-4">
              {fitsDesktop ? (
                <MenuList>{customMenuItems}</MenuList>
              ) : (
                <Select
                  className="w-full rounded-lg"
                  variant="outlined"
                  size="small"
                  input={<OutlinedInput />}
                  value={selectedCustomRange}
                  renderValue={(value) => {
                    return value?.label || "Custom";
                  }}
                >
                  {customMenuItems}
                </Select>
              )}
            </div>
          )}
          <div className="flex flex-col items-center pt-5 px-3">
            <RangeToggle value={range} onChange={setRange} />
            {range !== Range.Month && range !== Range.Custom && (
              <StaticDatePicker
                value={value}
                onChange={(value) => value && setValue(value)}
                renderDay={(date, _, pickersDayProps) => {
                  if (range === Range.Day) {
                    return <PickersDay {...pickersDayProps} />;
                  }

                  const start = startOfWeek(value);
                  const end = endOfWeek(value);

                  const dayIsBetween = isWithinInterval({ start, end }, date);
                  const isFirstDay = isSameDay(date, start);
                  const isLastDay = isSameDay(date, end);

                  return (
                    <CustomPickersDay
                      {...pickersDayProps}
                      selected={dayIsBetween}
                      disableMargin
                      dayIsBetween={dayIsBetween}
                      isFirstDay={isFirstDay}
                      isLastDay={isLastDay}
                    />
                  );
                }}
                renderInput={(params) => <TextField {...params} />}
                minDate={minDate}
                disableFuture
                showToolbar={false}
                displayStaticWrapperAs="desktop"
              />
            )}
            {range === Range.Custom && (
              <div>
                {fitsDesktop && (
                  <div className="flex md:flex-row flex-col md:px-8 md:py-5">
                    <DateTimePicker
                      value={value}
                      minDate={minDate}
                      maxDate={maxDate}
                      invalid={invalid}
                      onChange={(value) => {
                        if (value) setValue(value);
                      }}
                    />
                    <div className="font-bold mx-8 my-auto hidden md:block">
                      TO
                    </div>
                    <DateTimePicker
                      value={endValue}
                      minDate={minDate}
                      maxDate={maxDate}
                      invalid={invalid}
                      onChange={(value) => {
                        if (value) setEndValue(value);
                      }}
                    />
                  </div>
                )}
                {!fitsDesktop && (
                  <div className="min-h-[600px]">
                    <MobileDateTimePicker
                      label="Start Time"
                      value={value}
                      onChange={(value) => {
                        if (value) setValue(value);
                      }}
                      minDate={minDate}
                      maxDate={maxDate}
                      invalid={invalid}
                      opened={openedPicker === DatePickerType.Start}
                      toggle={() =>
                        openPicker(
                          openedPicker === DatePickerType.Start
                            ? null
                            : DatePickerType.Start
                        )
                      }
                    />
                    <Divider />
                    <MobileDateTimePicker
                      label="End Time"
                      value={endValue}
                      onChange={(value) => {
                        if (value) setEndValue(value);
                      }}
                      minDate={minDate}
                      maxDate={maxDate}
                      invalid={invalid}
                      opened={openedPicker === DatePickerType.End}
                      toggle={() =>
                        openPicker(
                          openedPicker === DatePickerType.End
                            ? null
                            : DatePickerType.End
                        )
                      }
                    />
                  </div>
                )}
              </div>
            )}
            {range === Range.Month && (
              <div className="pt-3 pb-5 px-8">
                <StaticMonthPicker
                  value={value}
                  onChange={setValue}
                  minDate={minDate}
                  maxDate={maxDate}
                />
              </div>
            )}
          </div>
        </div>
        <Divider />
        <div className="flex justify-between px-4 py-2">
          <Button
            color="primary"
            onClick={() => {
              setValue(activeDate);
              setEndValue(activeEndDate);
            }}
            className="tracking-button font-normal"
            disabled={
              (isCustom && sameValue && sameEndValue) ||
              (!isCustom && sameValue && range === activeRange)
            }
          >
            RESET
          </Button>
          <div className="flex items-center gap-2">
            <Button
              color="primary"
              onClick={closePicker}
              className="tracking-button font-normal"
            >
              CANCEL
            </Button>
            <Button
              color="primary"
              onClick={() => {
                if (isCustom) {
                  if (endValue <= value || difference > MAX_CUSTOM_RANGE) {
                    setInvalid(true);
                    return;
                  }
                }

                onRangeChange(range);
                onDateChange(value);

                if (isCustom) {
                  onDateEndChange(endValue);
                }

                closePicker();
              }}
              disabled={
                (isCustom && sameValue && sameEndValue) ||
                (isCustom && difference > MAX_CUSTOM_RANGE) ||
                (!isCustom && sameValue && range === activeRange)
              }
              className="tracking-button font-bold"
            >
              {isCustom && difference > MAX_CUSTOM_RANGE
                ? "Max 30 Days"
                : "APPLY"}
            </Button>
          </div>
        </div>
      </Popover>
      <Tooltip title={`Next ${activeRange}`}>
        <IconButton
          size="small"
          disabled={nextDisabled}
          onClick={() => onDateChange(nextDate)}
        >
          <RightIcon fontSize="large" />
        </IconButton>
      </Tooltip>
    </div>
  );
}

type CustomPickerDayProps = PickersDayProps<Date> & {
  dayIsBetween: boolean;
  isFirstDay: boolean;
  isLastDay: boolean;
};

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== "dayIsBetween" && prop !== "isFirstDay" && prop !== "isLastDay",
})<CustomPickerDayProps>(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  width: 40,
  ...(dayIsBetween && {
    borderRadius: 0,
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  }),
  ...(isLastDay && {
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  }),
})) as React.ComponentType<CustomPickerDayProps>;

const ranges = [
  { label: "Daily", value: Range.Day },
  { label: "Weekly", value: Range.Week },
  { label: "Monthly", value: Range.Month },
  { label: "Custom", value: Range.Custom },
];

export function RangeToggle({
  value,
  omit,
  onChange,
  className,
}: {
  value: string;
  onChange: (value: Range) => void;
  omit?: Range[];
  className?: string;
}) {
  const items = omit ? ranges.filter((r) => !omit.includes(r.value)) : ranges;

  return (
    <ToggleButtonGroup
      size="small"
      className={clsx("text-[#757575] rounded-full", className)}
      value={value}
      exclusive
      onChange={(_, value) => onChange(value)}
    >
      {items.map(({ label, value: v }) => (
        <ToggleButton
          key={v}
          value={v}
          disabled={value === v}
          className="border-none rounded-full m-1 w-20 text-[#353D48] text-sm transition-all"
          classes={{
            selected: "bg-white font-bold shadow-toggleButton",
          }}
        >
          {label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}

function IdleDashboardDataLoader({
  id,
  objectTypes,
  startDate,
  endDate,
  range,
  timezone,
  cameraId,
  zoneId,
}: {
  objectTypes?: string[] | string | null;
  cameraId: number;
  id: number;
  startDate: Date;
  endDate: Date;
  range: string;
  timezone: string;
  zoneId?: number;
}) {
  const { fitsDesktop } = useBreakpoints();
  const feature = getDashboardFeatureType(
    IntelligenceDashboardType.Idle,
    objectTypes
  );
  const [fullscreen] = useQueryParam("fullscreen", BooleanParam);
  const { data, error, stopPolling } = useIntelligenceDashboardContentQuery({
    ...refetchOnMountPolicy,
    variables: {
      id,
      startDate: formatIsoDate(startDate),
      endDate: formatIsoDate(endDate),
      bucketSizeSeconds: range === Range.Day ? 3600 : 86400,
      polling: true,
    },
    pollInterval: 1000,
    onCompleted(returnedData) {
      if (
        returnedData?.intelligenceDashboard?.metrics.job.status &&
        returnedData?.intelligenceDashboard?.metrics.job.status ===
          JobStatus.Done
      ) {
        stopPolling();
      }
    },
    onError() {
      stopPolling();
    },
  });

  if (error) {
    return (
      <ErrorMessage
        title="Oops!"
        description="Failed to load intelligence dashboard. Please try again."
      />
    );
  }

  if (!data?.intelligenceDashboard?.metrics.results) {
    return (
      <IntLoadingIndicator className="flex justify-center items-center py-20" />
    );
  }

  if (!fitsDesktop && fullscreen) {
    return (
      <MobileIdleDashboard
        feature={feature}
        objectTypes={objectTypes}
        cameraId={cameraId}
        dashboard={data?.intelligenceDashboard}
        timezone={timezone}
      />
    );
  }

  return (
    <IdleDashboard
      feature={feature}
      objectTypes={objectTypes ?? intelligentFiltersConfig.vehicle.objectIds}
      cameraId={cameraId}
      metrics={data.intelligenceDashboard.metrics.results}
      thresholdSeconds={data.intelligenceDashboard.thresholdSeconds}
      timezone={timezone}
      zoneId={zoneId}
    />
  );
}

function CountDashboardDataLoader({
  id,
  objectTypes,
  startDate,
  endDate,
  range,
  timezone,
  cameraId,
  zoneId,
  subtype,
  feature,
}: {
  cameraId: number;
  id: number;
  objectTypes?: string[] | string | null;
  startDate: Date;
  endDate: Date;
  range: string;
  timezone: string;
  zoneId?: number;
  subtype: CountDirection;
  feature: IntelligenceFeatureType;
}) {
  const { fitsDesktop } = useBreakpoints();
  const [fullscreen] = useQueryParam("fullscreen", BooleanParam);
  const { data, error, stopPolling } = useIntelligenceDashboardContentQuery({
    ...refetchOnMountPolicy,
    variables: {
      id,
      startDate: formatIsoDate(startDate),
      endDate: formatIsoDate(endDate),
      bucketSizeSeconds: range === Range.Day ? 3600 : 86400,
      usePaths: true,
      polling: true,
    },
    pollInterval: 1000,
    onCompleted(returnedData) {
      if (
        returnedData?.intelligenceDashboard?.metrics.job.status &&
        returnedData?.intelligenceDashboard?.metrics.job.status ===
          JobStatus.Done
      ) {
        stopPolling();
      }
    },
    onError() {
      stopPolling();
    },
  });

  if (error) {
    return (
      <ErrorMessage
        title="Oops!"
        description="Failed to load intelligence dashboard. Please try again."
      />
    );
  }

  if (!data?.intelligenceDashboard?.metrics.results) {
    return (
      <IntLoadingIndicator className="flex justify-center items-center py-20" />
    );
  }

  if (!fitsDesktop && fullscreen) {
    return (
      <MobileCountDashboard
        cameraId={cameraId}
        dashboard={data?.intelligenceDashboard}
        timezone={timezone}
        feature={feature}
        subtype={subtype}
      />
    );
  }

  return (
    <CountDashboard
      id={data.intelligenceDashboard.id}
      cameraId={cameraId}
      metrics={data.intelligenceDashboard.metrics.results}
      timezone={timezone}
      subtype={subtype}
      feature={feature}
      objectTypes={objectTypes}
    />
  );
}

gql`
  query intelligenceDashboardContent(
    $id: Int!
    $startDate: Date!
    $endDate: Date!
    $bucketSizeSeconds: Int!
    $usePaths: Boolean
    $polling: Boolean
    $thresholdSeconds: Int
    $daysOfWeek: [Int!]
    $startTime: String
    $endTime: String
    $compoundId: String
  ) {
    intelligenceDashboard(id: $id) {
      id
      thresholdSeconds
      metrics(
        startDate: $startDate
        endDate: $endDate
        input: {
          bucketSizeSeconds: $bucketSizeSeconds
          usePaths: $usePaths
          thresholdSeconds: $thresholdSeconds
          daysOfWeek: $daysOfWeek
          startTime: $startTime
          endTime: $endTime
          compoundId: $compoundId
        }
        polling: $polling
      ) {
        job {
          id
          status
        }
        results {
          bqJobId
          totalCount
          idleCount
          averageIdleTimeSeconds
          averageNonzeroIdleTimeSeconds
          maxIdleTimeSeconds
          entities {
            objectId
            firstMs
            lastMs
            idleSum
            queueNum
            crossedLeft
            crossedRight
          }
          leftCount
          rightCount
          bucketCounts {
            bucketMs
            totalCount
            idleCount
            leftCount
            rightCount
          }
        }
      }
    }
  }
`;
