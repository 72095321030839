import clsx from "clsx";
import { atom, useAtom } from "jotai";

export const selectedOnlyFilterAtom = atom(false);

export function CamDrawerSelectedOnlyFilter() {
  const [selectedOnly, setSelectedOnly] = useAtom(selectedOnlyFilterAtom);

  return (
    <div className="flex justify-start gap-4">
      <button
        type="button"
        className={clsx("text-sm leading-[16.41px] bg-transparent", {
          "text-primary font-bold": !selectedOnly,
          "italic text-[#757575]": selectedOnly,
        })}
        onClick={() => {
          setSelectedOnly(!selectedOnly);
        }}
      >
        {selectedOnly
          ? "Viewing only selected cameras"
          : "Show only selected cameras"}
      </button>
      {selectedOnly && (
        <button
          className="text-primary font-bold text-sm leading-[16.41px] bg-transparent"
          onClick={() => {
            setSelectedOnly(false);
          }}
        >
          Show all
        </button>
      )}
    </div>
  );
}
