import { SearchOutlined } from "@mui/icons-material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { IconButton, Typography } from "@mui/material";
import clsx from "clsx";
import { useFlags } from "launchdarkly-react-client-sdk";
import { uniq, uniqBy } from "lodash/fp";
import { useMemo, useState } from "react";
import { ArrayParam, StringParam, useQueryParam } from "use-query-params";

import { filterNullish } from "@/util/filterFalsy";

import {
  MobileFilterActionBar,
  MobileFilterDateTimeCategoryChip,
} from "@/components/Filter/Mobile/MobileFilterActionBar";
import { MobileFilterDateTimeCategory } from "@/components/Filter/Mobile/MobileFilterCategory";
import { MobileFilterModal } from "@/components/Filter/Mobile/MobileFilterModal";
import { MobileFilterSearchBar } from "@/components/Filter/Mobile/MobileFilterSearchBar";
import { MobileSearchModal } from "@/components/Filter/Mobile/MobileSearchModal";
import { useTransparentFilterFieldStyles } from "@/components/Filter/constant";

import { IntelligenceDashboardQuery } from "@/generated-models";

import { Color, VehicleType } from "../constants";
import { LicensePlateReportDatagrid } from "./LicensePlateReportDatagrid";
import { useLprReportGrouped } from "./hooks";

export function LicensePlateReportDetailsMobileBar({
  dashboard,
}: {
  dashboard: IntelligenceDashboardQuery["intelligenceDashboard"];
}) {
  const { lprMmct } = useFlags();
  const { classes } = useTransparentFilterFieldStyles();
  const [searchFilter] = useQueryParam("search", StringParam);
  const [mobileModal, setMobileModal] = useState(false);
  const [mobileSearch, setMobileSearch] = useState(false);
  const [mobileFilter, setMobileFilter] = useState(false);
  const [locationsFilter] = useQueryParam("locations", ArrayParam);
  const [camerasFilter] = useQueryParam("cameras", ArrayParam);
  const emptyFilters = !locationsFilter && !camerasFilter;

  const { data: groupedReport } = useLprReportGrouped();
  const data = groupedReport?.lprReport || [];

  const lprCameras = useMemo(
    () =>
      (groupedReport?.lprReport || [])
        .flatMap((d) => d.cameras)
        .filter(filterNullish),
    [groupedReport]
  );

  const timezone = lprCameras[0].location?.timezone;

  const sortedLocations = useMemo(() => {
    return uniq(lprCameras.map((cam) => cam?.location).flat()).sort((a, b) =>
      a.name.localeCompare(b.name)
    );
  }, [lprCameras]);

  const filterCategories = useMemo(
    () => [
      {
        name: "Dates & Times",
        filterName: "datetime",
        hidden: true,
        classes,
        options: [],
        type: StringParam,
      },
      {
        name: "Locations",
        filterName: "locations",
        classes,
        options: uniqBy(
          "value",
          sortedLocations.map((d) => ({
            label: d.name,
            value: String(d.id),
          })) || []
        ),
      },
      {
        name: "Cameras",
        filterName: "cameras",
        classes,
        options: uniqBy(
          "value",
          lprCameras.map((d) => ({
            label: d.name,
            value: String(d.id),
          })) || []
        ),
      },
      ...(lprMmct
        ? [
            {
              name: "Makes",
              filterName: "make",
              classes,
              options: uniqBy(
                "value",
                groupedReport.lprReport?.map((d) => ({
                  label: d.make,
                  value: d.make,
                })) || []
              ),
            },
            {
              name: "Models",
              filterName: "model",
              classes,
              options: uniqBy(
                "value",
                groupedReport.lprReport?.map((d) => ({
                  label: d.model,
                  value: d.model,
                })) || []
              ),
            },
            {
              name: "Colors",
              filterName: "color",
              classes,
              label: "Colors",
              options: uniqBy(
                "value",
                groupedReport.lprReport?.map((d) => ({
                  label: `${Color[d.colorId || 0]}`,
                  value: d.colorId,
                })) || []
              ),
            },
            {
              name: "Type",
              filterName: "type",
              classes,
              label: "Type",
              options: uniqBy(
                "value",
                groupedReport.lprReport?.map((d) => ({
                  label: `${VehicleType[d.typeId || 0]}`,
                  value: d.typeId,
                })) || []
              ),
            },
          ]
        : []),
    ],
    [classes, groupedReport.lprReport, lprCameras, lprMmct, sortedLocations]
  );

  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-center px-4 mb-2">
        <Typography className="font-bold sm:text-2xl sm:file:leading-7 text-lg leading-[21.09px]">
          {data.length || 0} Vehicles
        </Typography>
        <MobileSearchModal
          open={mobileModal}
          subject="Result"
          total={data.length ?? 0}
          onBack={() => {
            setMobileModal(false);
          }}
        >
          <LicensePlateReportDatagrid dashboard={dashboard} />
        </MobileSearchModal>
        <MobileFilterModal
          count={data?.length || 0}
          open={mobileFilter}
          subject="Result"
          chips={<MobileFilterDateTimeCategoryChip timezone={timezone} />}
          onBack={() => {
            setMobileFilter(false);
          }}
          categories={filterCategories}
        >
          <MobileFilterDateTimeCategory timezone={timezone} />
        </MobileFilterModal>
        <div className="flex items-center gap-x-4">
          <IconButton
            className={clsx("bg-[#DDEFFF]", {
              invisible: !!mobileSearch || !!searchFilter,
            })}
            color="primary"
            onClick={() => {
              setMobileModal(true);
            }}
          >
            <SearchOutlined />
          </IconButton>
          {emptyFilters && (
            <IconButton
              className="bg-[#DDEFFF]"
              color="primary"
              onClick={() => {
                setMobileFilter(true);
              }}
            >
              <FilterListIcon />
            </IconButton>
          )}
        </div>
      </div>
      {!mobileModal && searchFilter && (
        <MobileFilterSearchBar
          onClick={() => setMobileModal(true)}
          onHide={() => setMobileSearch(false)}
        />
      )}
      <MobileFilterActionBar
        dynamic
        categories={filterCategories}
        chips={<MobileFilterDateTimeCategoryChip timezone={timezone} />}
        onFilterClick={() => {
          setMobileFilter(true);
        }}
      />
    </div>
  );
}
