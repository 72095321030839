import { isProductionEnv } from "./environment";

/**
 * If you want to run your local API against the production Auth0 tenant, you
 * need to run: REACT_APP_API_HOST=localhost:4000 npm run start-production
 *
 * We could simplify this flow by returning the entire Auth0 config from the server,
 * that would insure that the client and server configs are always in sync.
 * We're not introducing this now because:
 * 1. It will introduce an extra network request on app load.
 * 2. We are likely offloading auth to the server in the near future (Next.js).
 */
export const auth0Config = {
  domain: isProductionEnv ? "spotai.auth0.com" : "spotai-staging.us.auth0.com",
  dashboardCliendId: isProductionEnv
    ? "TFAkF5pdHH7J6p5xjjZj7Xs9ADZMW2Y0"
    : "FTMo6hB9qHSEI4QDeO3b98gkHpnuoCPW",
  kioskClientId: isProductionEnv
    ? "Ee2Sg1EH1HtaO2nxqSTZm1UPW2t2H4lU"
    : "EXGXVY0OXRwaqxoiVo5fLgIwE9okAYBQ",
  spotApiId: isProductionEnv
    ? "https://api.spotai.co"
    : "https://api-staging.spotai.co",
};
