import { Link } from "@mui/material";
import { identity } from "lodash/fp";
import React from "react";

import { QueryParamLink } from "@/components/shared/QueryParamLink";

export function insertMentions(mentions: Record<number, string> | undefined) {
  return (text: string) => {
    // https://github.com/facebook/react/issues/3386#issuecomment-78605760
    const parts = text.split(/(@\[[^\]]+\]\(\d+\))/g); // entire placeholder match
    return parts.map((part, i) => {
      if (i % 2 === 0) return part;

      const [, name, id] = /@\[([^\]]+)\]\((\d+)\)/.exec(part) ?? [];
      const mentionedUser = mentions?.[Number(id)] ?? false;

      // Try to find user in `mentions`
      if (mentionedUser)
        return (
          <Link key={i} underline="hover">
            @{mentionedUser}
          </Link>
        );

      // fallback - user with id does not exist
      return `@${name}`;
    });
  };
}

/**
 * Maps written out timestamps (like 2:44) to links that will start playing
 * the corresponding clip at that timestamp.
 */
export function insertTimestampLinks(
  clip:
    | {
        id: number;
        duration: number;
      }
    | undefined
    | null
) {
  if (!clip) return identity;

  return (text: string) => {
    const parts = text.split(/(\d+:\d+)/g);

    return parts.map((part, i) => {
      if (i % 2 === 0) return part;

      const [minutes, seconds] = part.split(":").map(Number);
      if (seconds > 60) return part; // Not a timestamp

      const timestamp = minutes * 60 + seconds;

      if (timestamp > clip.duration) return part; // Outside of video bounds

      return (
        <QueryParamLink
          params={{
            clip: clip.id,
            startTime: timestamp,
          }}
        >
          {part}
        </QueryParamLink>
      );
    });
  };
}

export function applyCommentPartMapper(
  mapper: (content: string) => string | (string | JSX.Element)[]
  // idPrefix: number | string
) {
  return (part: string | JSX.Element, i: number) =>
    typeof part === "string" ? mapper(part) : part;
}
