import { IconButton, IconButtonProps } from "@mui/material";
import React from "react";

import { useMultiPlayerControls } from "@/components/Player/PlayerBase";
import { Back10Icon } from "@/components/Player/PlayerIcons";

import { PlayerTooltip } from "./PlayerTooltip";

export function Back10Button({
  fontSize = 24,
  ...props
}: IconButtonProps & { fontSize?: number }) {
  const { seek } = useMultiPlayerControls();
  return (
    <PlayerTooltip
      title={
        props.disabled ? "Not available in live mode" : "Replay last 10 sec"
      }
    >
      <span>
        <IconButton
          onClick={() => seek((p) => p - 10)}
          color="inherit"
          classes={{ disabled: "opacity-50" }}
          style={{ color: "inherit", fontSize }}
          {...props}
          size="large"
        >
          <Back10Icon fontSize="inherit" />
        </IconButton>
      </span>
    </PlayerTooltip>
  );
}
