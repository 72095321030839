import { Monaco } from "@monaco-editor/react";

import { SPOT_ASSIST_THEME } from "./constant";

export const THEME_RULE_TOKEN = {
  DATE: "custom-date",
  HYPERLINK: "custom-hyperlink",
  PROMPT_INPUT: "custom-prompt-input",
  VARIABLE: "custom-variable",
};

export function registerAssistantTheme(monaco: Monaco) {
  monaco.editor.defineTheme(SPOT_ASSIST_THEME, {
    base: "vs-dark",
    inherit: false,
    rules: [
      { token: "comment", foreground: "#608b4e" },
      {
        token: THEME_RULE_TOKEN.PROMPT_INPUT,
        foreground: "#e49b0f",
        fontStyle: "italic bold",
      },
      {
        token: THEME_RULE_TOKEN.HYPERLINK,
        foreground: "#007ce4",
        fontStyle: "italic underline bold",
      },
      {
        token: THEME_RULE_TOKEN.VARIABLE,
        foreground: "#da70d6",
        fontStyle: "italic bold",
      },
    ],
    colors: {
      "editor.foreground": "#A6C8E1",
      "editor.background": "#00000000",
      "editor.lineHighlightBorder": "#00000000",
      "editorHoverWidget.background": "#3B4954",
      "editorHoverWidget.border": "#293137",
      "editorSuggestWidget.background": "#3B4954",
      "editorSuggestWidget.border": "#293137",
      "editorSuggestWidget.foreground": "#FFFFFF",
      "editorSuggestWidget.selectedBackground": "#44637B",
    },
  });
}
