import React from "react";

import { IconProps } from "@/icons/IconProps";

export function PawIcon({
  style,
  className,
  onClick,
  white = false,
}: IconProps & { white?: boolean }) {
  const color = white ? "#FFFFFF" : "#3F3F41";
  return (
    <svg
      style={style}
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="52"
      viewBox="0 0 42 52"
    >
      <g fill="none" fillRule="evenodd" transform="translate(-2 -2)">
        <circle
          cx="12.645"
          cy="24.224"
          r="10.114"
          fill={color}
          transform="rotate(-16 12.645 24.224)"
        />
        <circle
          cx="29.959"
          cy="40.771"
          r="12.494"
          fill="#007CE4"
          transform="rotate(-16 29.96 40.771)"
        />
        <circle
          cx="39.23"
          cy="21.108"
          r="4.76"
          fill={color}
          transform="rotate(-16 39.23 21.108)"
        />
        <circle
          cx="28.005"
          cy="10.709"
          r="8.329"
          fill={color}
          transform="rotate(-16 28.005 10.709)"
        />
      </g>
    </svg>
  );
}
