import { Brightness1 } from "@mui/icons-material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { List, ListItem, Typography } from "@mui/material";
import clsx from "clsx";
import { Link } from "react-router-dom";

import { useBreakpoints } from "@/util/useBreakpoints";

import { IntelligenceFeatureConfig } from "../constants";

interface LicensePlateHeaderProps {
  className?: string;
  condensed?: boolean;
}

function BackButton() {
  return (
    <div className="flex items-center w-full justify-start">
      <Link to=".." className="-ml-4 text-primary flex gap-0.5 items-center">
        <ChevronLeftIcon fontSize="large" />
        <Typography className="text-sm leading-[16.41px] md:text-lg md:leading-[21px] text-primary font-medium">
          Back to Intelligence
        </Typography>
      </Link>
    </div>
  );
}
function LicensePlateDescriptionList() {
  return (
    <List className="p-2 md:px-0">
      {[
        "Quickly and easily search and resolve vehicle related incidents",
        "Drive operations by understanding trends about specific vehicles",
        "Understand customer behavior to provide tailored experiences",
      ].map((text, idx) => {
        return (
          <ListItem key={idx} className="pl-0 py-1 leading-4">
            <Brightness1
              className={`text-[8px] ${IntelligenceFeatureConfig.LicensePlateSearch.foreground.colorPrimary} mr-2`}
            />
            {text}
          </ListItem>
        );
      })}
    </List>
  );
}

export function LicensePlateHeader({
  className,
  condensed,
}: LicensePlateHeaderProps) {
  const { fitsTablet, fitsDesktop } = useBreakpoints();

  return (
    <div
      className={clsx(
        "flex sm:flex-row gap-2 items-center justify-center sm:px-12",
        className,
        {
          "flex-col-reverse sm:mt-20": !condensed,
          "flex-row": condensed,
          "!justify-between": condensed && !fitsTablet,
        }
      )}
    >
      <div
        className={clsx("flex flex-col h-full", {
          "gap-7": !condensed && fitsDesktop,
          "gap-3": condensed && fitsDesktop,
        })}
      >
        {(fitsTablet || (!fitsTablet && condensed)) && <BackButton />}
        <Typography
          className={clsx("text-[#00A69C]", {
            "text-[24px] leading-[21.6px] sm:text-[32px] sm:leading-[28.8px] md:text-[64px] md:leading-[90%]": !condensed,
            "text-[22px] leading-[19.8px] md:text-[34px] md:leading-[30.6px]": condensed,
            "text-left ml-[10px] -mt-4": !fitsTablet && !condensed,
            "text-left pl-4": !fitsTablet && condensed,
          })}
        >
          License Plate
          {((!condensed && fitsTablet) || (condensed && !fitsTablet)) && (
            <br />
          )}{" "}
          Recognition
        </Typography>
        {((!condensed && !fitsTablet) || fitsTablet) && (
          <div className="sm:px-0 px-3">
            <Typography className="text-sm tracking-[0%] leading-[16.41px] md:text-base md:leading-[18.75px] md:mt-0 mt-4 max-w-[378px]">
              Search for vehicles by license plate to track vehicles over time
              and determine when incidents occur.
            </Typography>
            {!condensed && <LicensePlateDescriptionList />}
          </div>
        )}
      </div>
      <div
        className={clsx(
          "max-w-full overflow-hidden relative flex items-center justify-center",
          {
            "h-[80px] mt-4": condensed && !fitsTablet,
            "h-[150px] mt-4": condensed && fitsTablet,
            "h-[300px]": !condensed,
          }
        )}
      >
        <img
          className="z-1 h-full"
          src="/lprIllustration.svg"
          alt={`License Plate Recognition Illustration`}
        />
        <img
          className="absolute"
          src="/lprIllustrationGradient.svg"
          alt={`License Plate Recognition Illustration`}
        />
      </div>

      {!fitsTablet && !condensed && <BackButton />}
    </div>
  );
}
