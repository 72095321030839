import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Button, ButtonGroup, InputLabel, Typography } from "@mui/material";
import { Form, Formik, useField } from "formik";
import { useState } from "react";
import * as yup from "yup";

import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { StyledInput } from "@/components/Styled/StyledInputWithLabel";

import { useSetupIntegrationMutation } from "@/generated-models";

import { CFG_FAILED_MSG, CFG_SUCCESS_MSG } from "../constant";
import { INTEGRATION_SETUP_STATE_QUERY } from "../hooks";

interface IntegrationsVendorFormProps {
  id: number;
  schema?: any;
  setupState?: any;
  className?: string;
  onConnectionResult?: (success: boolean) => void;

  editMode?: boolean;
  initialState?: any;
  callback?: () => void;
  onBack?: () => void;
}

interface AvigilonFormProps {
  email: string;
  password: string;
  twoFactorOneTimePassword?: string;
}

const validationSchema = yup.object().shape({
  email: yup.string().required("Required"),
  password: yup.string().required("Required"),
  twoFactorOneTimePassword: yup.string(),
});

const InputField = ({
  title,
  ...props
}: {
  id: string;
  title: string;
  disabled?: boolean;
  type?: string;
}) => {
  const [field] = useField(props.id);

  return (
    <div>
      <InputLabel shrink htmlFor={props.id}>
        {title}
      </InputLabel>
      <StyledInput {...props} {...field} />
    </div>
  );
};

export function IntegrationsAvigilonVendorForm({
  id,
  schema = {},
  setupState,
  className,
  onConnectionResult = () => {},
  callback,
  editMode,
  onBack,
}: IntegrationsVendorFormProps) {
  const [connected, setConnected] = useState(false);
  const [formData, setFormData] = useState<AvigilonFormProps>({
    email: "",
    password: "",
    twoFactorOneTimePassword: "",
  });
  const [is2FAEnabled, setIs2FAEnabled] = useState(false);
  const { pushSnackbar } = useFeedback();

  const initialValues: AvigilonFormProps = {
    email: formData.email || "",
    password: formData.password || "",
    twoFactorOneTimePassword: formData.twoFactorOneTimePassword || "",
  };

  const [setupIntegration, { loading }] = useSetupIntegrationMutation({
    refetchQueries: [
      { query: INTEGRATION_SETUP_STATE_QUERY, variables: { input: { id } } },
      "integration",
    ],
    onCompleted: () => {
      pushSnackbar(CFG_SUCCESS_MSG, FeedbackType.Success);
      setConnected(true);
      onConnectionResult(true);
      if (callback) {
        callback();
      }
    },
    onError: (e) => {
      pushSnackbar(e.message || CFG_FAILED_MSG, FeedbackType.Error);
      setConnected(false);
      onConnectionResult(false);
    },
  });

  function handleSubmit(values: AvigilonFormProps) {
    setupIntegration({
      variables: {
        input: {
          integrationId: id,
          setupState: values,
        },
      },
    });

    setFormData(values);
  }

  return (
    <div className={className}>
      <div className="max-w-xl">
        <Formik
          onSubmit={(values) => handleSubmit(values)}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          <Form>
            <div className="flex flex-col gap-4">
              <div className="w-full">
                <InputField type="email" id="email" title="Email" />
              </div>
              <div className="w-full">
                <InputField type="password" id="password" title="Password" />
              </div>
              <div className="flex justify-between items-center">
                <div className="w-full">
                  <input
                    type="checkbox"
                    name="2faEnabled"
                    id="2faEnabled"
                    className="mr-2"
                    checked={is2FAEnabled}
                    onChange={() => setIs2FAEnabled(!is2FAEnabled)}
                  />
                  <label htmlFor="2faEnabled">Is 2FA Enabled?</label>
                </div>
                {is2FAEnabled && (
                  <div className="w-3/5">
                    <InputField
                      id="twoFactorOneTimePassword"
                      title="2FA Code (Optional)"
                    />
                  </div>
                )}
              </div>
            </div>
            <ButtonGroup className="mt-7 flex gap-2">
              {!editMode && (
                <Button
                  variant="contained"
                  className="shadow-none bg-[#DAEEFF] text-[#007CE4] font-normal rounded-[6px]"
                  onClick={onBack}
                >
                  Back
                </Button>
              )}
              <Button
                type="submit"
                className="shadow-none font-normal text-base rounded-lg h-11 px-10 w-auto"
                variant="contained"
                color="primary"
                disabled={loading}
              >
                {editMode ? "Save" : "Next"}
              </Button>
            </ButtonGroup>
          </Form>
        </Formik>
      </div>
      {connected && (
        <Typography className="text-[#18D710] flex items-center justify-start gap-[6px] mt-[18px] text-lg leading-[22px]">
          <CheckCircleIcon />
          Connection Successful
        </Typography>
      )}
    </div>
  );
}
