import { IntegrationEventV2 } from "@/generated-models";

import { useCurrentIntegrationDevices } from "../../hooks/deviceHooks";
import { useCurrentIntegrationEventTypes } from "../../hooks/eventHooks";

function useGetResolvedEventData() {
  const { devicesMap } = useCurrentIntegrationDevices();
  const { typesMap } = useCurrentIntegrationEventTypes();

  return (event: IntegrationEventV2) => {
    const device = devicesMap[event.integrationDeviceId];
    const timezone = device?.cameras?.[0]?.location?.timezone || "";
    return {
      device,
      eventType: typesMap[event.integrationEventTypeId],
      timezone,
    };
  };
}

export { useGetResolvedEventData };
