import { Position, editor } from "monaco-editor";

import "@/components/VideoWall/ContextualVideoPlayer";

import { Page_AssistantQuery } from "@/generated-models";

type EditorVodParams = {
  range?: string;
  vod?: string;
  cams?: string;
  subjects?: string;
};

const getPathRegex = (path: string) => `[^\\s].*/o/.*/${path}([^\\s"]*)`;

export function getEditorVodMatch(
  editor: editor.IStandaloneCodeEditor,
  position: Position | null,
  cameras?: Page_AssistantQuery["cameras"]
) {
  const result = editor
    .getModel()
    ?.findMatches(getPathRegex("search"), false, true, true, null, true);

  const searchQueryParams = result?.find(
    (matchItem) => position && matchItem.range.containsPosition(position)
  )?.matches?.[1];

  if (searchQueryParams) {
    const data: EditorVodParams = {};
    const params = new URLSearchParams(searchQueryParams);

    for (const [key, val] of params) {
      data[key as keyof EditorVodParams] = val;
    }

    const { cams = "", range, vod = "", subjects } = data;

    const camera = cameras?.find((c) => c.id === parseInt(cams));
    const [startTime, endTime] = decodeURIComponent(range || vod).split("|");

    if (camera) {
      return {
        startTime,
        endTime,
        camera,
        subjects: subjects?.split(",") || [],
      };
    }
  }

  return null;
}
