export function concat<T>(value: T | T[]) {
  return (cached: T[]) => cached.concat(value);
}

export function removeById<
  Id extends number | string,
  ItemWithId extends { id: Id },
  ItemWithRef extends { __ref: string }
>(id: Id) {
  return (cached: (ItemWithId | ItemWithRef)[]) =>
    cached.filter((item) => {
      if ("id" in item) {
        return item.id !== id;
      } else {
        return item.__ref.split(":")[1] !== id.toString();
      }
    });
}
