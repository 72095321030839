import { Typography, Divider } from "@mui/material";
import clsx from "clsx";
import { useState } from "react";

import { CopyTextButton } from "@/components/CopyTextButton";
import { PasswordToggleButton } from "@/components/PasswordToggleButton";

import { useGetDeviceCredentialsQuery } from "@/generated-models";

interface DeviceCredentialsProps {
  deviceId: number;
  targetIp: string;
  className?: string;
}

export default function DeviceCredentials({
  deviceId,
  targetIp,
  className,
}: DeviceCredentialsProps) {
  const [showPassword, setShowPassword] = useState(false);
  const [deviceCredentials, setDeviceCredentials] = useState<
    { username: string; password: string } | undefined
  >(undefined);

  useGetDeviceCredentialsQuery({
    variables: {
      deviceId: deviceId || 0, // 0 is skipped
    },
    onCompleted: async ({ deviceScan: { ip, username, password } }) => {
      // verify device ID is for IP being accessed
      if (ip === targetIp) {
        setDeviceCredentials({ username, password });
      }
    },
  });

  if (!deviceCredentials || !deviceCredentials.username) {
    return null;
  }

  return (
    <div className={clsx("flex flex-col", className)}>
      <div className="flex items-center justify-between">
        <Typography variant="body2" className="text-sm">
          User:
          <strong className="ml-1">{deviceCredentials?.username}</strong>
        </Typography>
        <CopyTextButton
          style={{ float: "right", marginLeft: 19, color: "#353D48" }}
          fontSize={14}
          text={deviceCredentials?.username || ""}
        />
      </div>
      <Divider className="mt-1" />
      <div className="flex items-center justify-between mt-4">
        <Typography variant="body2" className="text-sm">
          Password:
          <strong className="ml-1">
            {showPassword ? deviceCredentials?.password : "************"}
          </strong>
        </Typography>
        <PasswordToggleButton
          showPassword={showPassword}
          setShowPassword={setShowPassword}
          fontSize={14}
          className="text-[#353D48]"
        />
        <CopyTextButton
          fontSize={14}
          style={{ color: "#353D48" }}
          text={deviceCredentials?.password || ""}
        />
      </div>
      <Divider className="mt-1" />
    </div>
  );
}
