import gql from "graphql-tag";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Navigate, useParams } from "react-router-dom";

import { filterNullish } from "@/util/filterFalsy";
import { useViewActivityTimer } from "@/util/useViewActivityTimer";

import KioskCastButton from "@/pages/VideoWall/Kiosk/KioskCastButton";

import { useMe } from "@/components/Auth";
import { VideoWallCamTile } from "@/components/VideoWall/VideoWallCamTile";
import VideoWallGrid from "@/components/VideoWall/VideoWallGrid";
import { VideoWallLayout } from "@/components/VideoWall/VideoWallLayout";
import { VideoWallViewHeader } from "@/components/VideoWall/VideoWallViewHeader";

import {
  usePage_VideoWallsQuery,
  useRecordVideoWallActivityMutation,
} from "@/generated-models";

import { usePrefixOrgSlug } from "../../hooks/useOrgRouteBase";
import { VideoWallConnectionNotSupportedOverlay } from "./VideoWallConnectionNotSupportedOverlay";
import { forcedQualityByCameraCount, useWallStyles } from "./constants";
import { useVideoWallContext } from "./useVideoWallContext";

export function SingleVideoWallView() {
  useWallStyles();

  const params = useParams<{ id: string }>();
  /*
     Using useSearchParams and/or searchParams could result in hard to find bugs. We
     have similar window.location based logic elsewhere and for empty query strings values
     search.includes is true and other methods can be falsy.
  */
  const { search } = window.location;
  // This means the users is connects to a wall via a cable
  const kioskKeepAlive = search.includes("kioskKeepAlive");
  const id = Number(params.id);

  const { data } = usePage_VideoWallsQuery();

  const me = useMe();
  const webRtcIsEnabled = me?.organization?.flags.webRTC ?? false;
  const { auditLogData, sixteenPlusCamerasWarning } = useFlags();

  const {
    fullscreenHandle,
    autoLayoutState: [autoLayout],
    originalConfigurationState: [, setMatchesOriginalConfiguration],
    editingState: [, setEditing],
    fullscreenActive,
  } = useVideoWallContext();
  const prefixOrgSlug = usePrefixOrgSlug();

  // error and loading state should be handled in parent <VideoWall />
  if (!data) return null;

  const wall = data.videoWalls.find((wall) => wall.id === id);

  if (!wall) {
    // Wall doesn't exist anymore
    localStorage.removeItem("lastViewedWallId");
    return <Navigate to={prefixOrgSlug("/wall")} replace />;
  }

  const maxAutoLevel = forcedQualityByCameraCount(
    wall.cameras.filter(filterNullish).length
  );

  const { cameras } = wall;
  // This means the users is connects to a wall via a cable with more cameras
  // than the cable can support
  const showConnectionNotSupportedWarning =
    sixteenPlusCamerasWarning && cameras?.length > 15 && kioskKeepAlive;

  return (
    <VideoWallLayout
      header={
        <div>
          <VideoWallViewHeader
            fullscreenHandle={fullscreenHandle}
            wallName={wall.name}
            castButton={<KioskCastButton wallId={id} />}
            data={data}
            edit={() => setEditing(true)}
          />
        </div>
      }
      body={
        <>
          {showConnectionNotSupportedWarning && (
            <VideoWallConnectionNotSupportedOverlay />
          )}
          {auditLogData && <ViewRecorder wallId={id} />}
          <VideoWallGrid
            config={wall.config as any}
            fullscreen={fullscreenActive}
            autoLayout={autoLayout}
            filterableCamIndexes={cameras
              .map((cam, index) =>
                Boolean(cam && cam.__typename !== "NotAllowed") ? null : index
              )
              .filter(filterNullish)}
            onLayoutChange={({ matchesOriginalConfiguration }) =>
              setMatchesOriginalConfiguration(matchesOriginalConfiguration)
            }
          >
            {cameras.map((cam, i) => (
              <VideoWallCamTile
                cam={cam}
                key={`${cam?.id}-${i}`}
                maxAutoLevel={maxAutoLevel}
                isWebRtc={webRtcIsEnabled}
                blur={showConnectionNotSupportedWarning}
              />
            ))}
          </VideoWallGrid>
        </>
      }
    />
  );
}

function ViewRecorder({ wallId }: { wallId: number }) {
  const [recordActivity] = useRecordVideoWallActivityMutation();
  useViewActivityTimer(true, (start: Date) =>
    recordActivity({
      variables: { id: wallId, start: start.toISOString() },
    })
  );
  return null;
}

gql`
  mutation recordVideoWallActivity($id: Int!, $start: DateTime!) {
    recordVideoWallActivity(id: $id, start: $start)
  }
`;
