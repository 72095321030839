import { gql } from "@apollo/client";
import React, { useEffect } from "react";

import { useScript } from "@/util/useScript";

import { useMe } from "@/components/Auth";

import { debugMode } from "@/environment";
import { Role, useOrgSummaryQuery } from "@/generated-models";

declare global {
  interface Window {
    aptrinsic?: (...args: any[]) => void;
  }
}

const SCRIPTS_PATH = "https://storage.googleapis.com/spot-scripts/";
export function Gainsight({ children }: React.PropsWithChildren<{}>) {
  const me = useMe();
  const { data: orgSummary } = useOrgSummaryQuery({ skip: !me });
  const script = debugMode ? "gainsight-stage.js" : "gainsight.js";
  const [loaded] = useScript(SCRIPTS_PATH + script);

  useEffect(() => {
    if (loaded && me && orgSummary && window.aptrinsic) {
      const gsUserData = {
        id: me.id,
        email: me.email,
        firstName: me.name.split(" ")[0],
        lastName: me.name.split(" ")[1],
        //orgName: me.organization.name,
        status: "current",
        role: Role[me.role],
      };

      window.aptrinsic("identify", gsUserData, {
        id: me.organization.id,
        name: me.organization.name,
        type: me.organization.type,
        ...(me.role >= Role.Admin && orgSummary.summary),
      });
    }
  }, [loaded, me, orgSummary]);

  return <>{children}</>;
}

gql`
  query orgSummary {
    summary {
      locationCount
      applianceCount
      cameraCount
      userCount
    }
  }
`;
