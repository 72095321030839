import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Button } from "@mui/material";
import clsx from "clsx";
import { capitalize } from "lodash/fp";
import { BooleanParam, useQueryParam } from "use-query-params";

import { useMaintainMetricsDashboardType } from "../../hooks";

interface MetricAddResourceButtonProps {
  primary?: boolean;
  disabled?: boolean;
  className?: string;
}

export function MetricAddResourceButton({
  primary,
  disabled,
  className,
}: MetricAddResourceButtonProps) {
  const [, setModalOpen] = useQueryParam("add-resource-modal", BooleanParam);
  const [type] = useMaintainMetricsDashboardType();

  return (
    <Button
      disabled={disabled}
      variant="outlined"
      color="primary"
      onClick={() => {
        setModalOpen(true);
      }}
      className={clsx("font-normal", className, {
        "bg-[#F6FBFF] border-[#DDEFFF]": primary,
        "shadow-[0_8px_13px_rgba(0,0,0,0.25)] border-transparent bg-white": !primary,
      })}
    >
      <AddCircleIcon className="w-4 h-4 mr-2" />
      {`Add ${capitalize(type)}`}
    </Button>
  );
}
