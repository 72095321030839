import {
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
  GridValidRowModel,
} from "@mui/x-data-grid";
import { ReactNode } from "react";

export function MobileColumnsRenderer<T extends GridValidRowModel>({
  params,
  colDefs,
  children,
}: {
  params: GridRenderCellParams;
  colDefs: GridColDef<T>[];
  children: ReactNode;
}) {
  const { row, ...rest } = params;

  return (
    <div className="flex flex-col">
      <div>{children}</div>
      {colDefs.map((def) => {
        const value = def.valueGetter
          ? def.valueGetter({ row, ...rest, value: "" })
          : null;

        return (
          <div className="flex items-center pl-4" key={def.field}>
            {def.renderHeader
              ? def.renderHeader({} as GridColumnHeaderParams<T, any, any>)
              : def.headerName}
            <span className="mr-1">:</span>
            {def.renderCell ? (
              <>{def.renderCell({ row, ...rest, value })}</>
            ) : (
              <></>
            )}
          </div>
        );
      })}
    </div>
  );
}
