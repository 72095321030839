import { Button, Tooltip } from "@mui/material";
import clsx from "clsx";
import { useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";

import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { DefaultDialog, useDialog } from "@/components/shared/Dialog";

import {
  IntegrationDevice,
  useDeleteIntegrationDeviceMutation,
} from "@/generated-models";

import { IntegrationDeviceFormCard } from "../Form/Device/CustomDeviceFormItem";
import { SchemaActionButton } from "../SchemaActionButton";
import { FormMode, PLACEHOLDER_ID } from "../constant";
import {
  useCurrentIntegrationDevices,
  useUpsertIntegrationDevice,
} from "../hooks/deviceHooks";
import { useCurrentIntegrationId } from "../hooks/integrationHooks";

type FormCardProps = {
  onCancel?: () => void;
  mode?: FormMode;
};

function EditFormActions({
  loading,
  onCancel,
  mode = FormMode.Edit,
}: FormCardProps & { loading?: boolean }) {
  const { watch, formState, reset } = useFormContext<{
    device: IntegrationDevice;
  }>();
  const { isDirty } = formState;
  const isEdit = mode === FormMode.Edit;
  const cameraIds = watch("device.cameraIds");
  const noCamerasLinked = cameraIds.length === 0;

  if (!isDirty && isEdit) return <></>;

  return (
    <div className="flex items-center gap-2 mt-2">
      <Button
        size="small"
        variant="contained"
        className="shadow-none text-xs leading-[22px] font-normal bg-[#ddefff] text-[#007CE4] w-[70px]"
        disabled={loading}
        onClick={() => {
          if (onCancel) {
            onCancel();
          } else {
            reset();
          }
        }}
      >
        Cancel
      </Button>
      <Tooltip
        title={noCamerasLinked ? "At least one camera must be linked." : ""}
      >
        <span>
          <Button
            size="small"
            type="submit"
            variant="contained"
            color="primary"
            className="shadow-none text-xs leading-[22px] font-normal"
            disabled={!isDirty || loading || noCamerasLinked}
          >
            {isEdit ? "Save" : "Add"}
          </Button>
        </span>
      </Tooltip>
    </div>
  );
}

type DeviceFormSchema = Pick<
  IntegrationDevice,
  "id" | "name" | "cameraIds" | "tags"
>;

function EditFormCard({
  device,
  mode,
  onComplete,
  onCancel,
}: {
  onComplete?: () => void;
  device: DeviceFormSchema;
} & FormCardProps) {
  const { pushSnackbar } = useFeedback();
  const { open: openDeleteDialog, ...deleteDialogProps } = useDialog();
  const integrationId = useCurrentIntegrationId();
  const form = useForm<{ device: DeviceFormSchema }>({
    defaultValues: {
      device,
    },
    mode: "onChange",
  });

  const { upsert, loading: loadingUpsert } = useUpsertIntegrationDevice();
  const deviceId = form.watch("device.id");

  const [
    deleteDevice,
    { loading: deleting },
  ] = useDeleteIntegrationDeviceMutation({
    onError: () =>
      pushSnackbar(
        "Failed to delete the integration device, please try again",
        FeedbackType.Error
      ),
  });

  return (
    <FormProvider {...form}>
      <DefaultDialog
        title="Delete Device"
        confirmText="Delete"
        content="Are you sure you want to delete this device? This will delete all associated events."
        {...deleteDialogProps}
      />
      <form
        className="flex items-center justify-start gap-2"
        onSubmit={form.handleSubmit(async ({ device }) => {
          const { name, tags, cameraIds } = device;
          await upsert(
            {
              name,
              tags,
              cameraIds,
            },
            deviceId,
            (result) => {
              onComplete?.();
              form.reset({ device: result });
            }
          );
        })}
      >
        <IntegrationDeviceFormCard
          idx={0}
          path="device"
          key={device.id}
          disabled={deleting}
          mode={mode}
          onDelete={async () => {
            const deleteConfirmed = await openDeleteDialog();
            if (!deleteConfirmed) return;

            deleteDevice({
              variables: {
                input: {
                  integrationId,
                  integrationDeviceId: device.id,
                },
              },
              update(cache) {
                cache.evict({
                  id: `IntegrationDevice:${device.id}`,
                });
              },
            });
          }}
        >
          <EditFormActions
            mode={mode}
            onCancel={onCancel}
            loading={loadingUpsert}
          />
        </IntegrationDeviceFormCard>
      </form>
    </FormProvider>
  );
}

export function IntegrationEditDevicesForm() {
  const { devices, loading, error } = useCurrentIntegrationDevices();
  const [adding, setAdding] = useState(false);

  function toggleAdding() {
    setAdding(!adding);
  }

  if (error) {
    return <ErrorMessage title="Error" description={error.toString()} />;
  }

  if (loading) {
    return <Loading grow>Fetching Devices</Loading>;
  }

  return (
    <>
      <div className="flex flex-col gap-3">
        {devices.map((f) => (
          <EditFormCard
            key={f.id}
            device={{
              id: f.id,
              name: f.name,
              tags: f.tags,
              cameraIds: f.cameraIds,
            }}
          />
        ))}
        {adding && (
          <EditFormCard
            mode={FormMode.Create}
            onCancel={toggleAdding}
            onComplete={toggleAdding}
            device={{
              id: PLACEHOLDER_ID,
              name: "",
              tags: [],
              cameraIds: [],
            }}
          />
        )}
      </div>
      <div className={clsx("mt-6", adding && "invisible")}>
        <SchemaActionButton large onClick={toggleAdding}>
          + Add Device
        </SchemaActionButton>
      </div>
    </>
  );
}
