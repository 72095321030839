import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { Button, CircularProgress, TextField } from "@mui/material";
import { useRef, useState } from "react";
import { useMatch } from "react-router-dom";
import { useEffectOnce } from "react-use";
import io from "socket.io-client";

import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";

const CHAR_LIMIT = 100;
export function TextAudioControl(props: {
  cameraId: number;
  onDisconnected: () => void;
}) {
  const feedback = useFeedback();
  const { socket, connected } = useTextAudioStream({
    cameraId: props.cameraId,
    onDisconnected: props.onDisconnected,
  });
  const [textValue, setTextValue] = useState("Hello there!");

  return connected ? (
    <div className="flex flex-col px-6 py-3 gap-3 mb-3 max-w-lg">
      <div className="text-lg">
        Type a message that will then be converted into speech and broadcast by
        this camera.
      </div>
      <TextField
        className="text-lg"
        multiline
        variant="outlined"
        rows={2}
        value={textValue}
        onChange={(e) => {
          if (e.target.value.length > CHAR_LIMIT) {
            feedback.pushSnackbar(
              `Text must be under ${CHAR_LIMIT} characters`,
              FeedbackType.Info
            );
          } else {
            setTextValue(e.target.value);
          }
        }}
      />
      <div className="flex flex-row justify-end">
        <Button
          className="align-end text-lg"
          startIcon={<VolumeUpIcon />}
          color="primary"
          variant="contained"
          onClick={() => {
            socket.current?.emit("text", textValue);
            feedback.pushSnackbar(`Sent text, ${textValue}`, FeedbackType.Info);
          }}
        >
          Send
        </Button>
      </div>
    </div>
  ) : (
    <div className="flex items-center justify-center">
      <CircularProgress size="1.5rem" />
    </div>
  );
}

export function useTextAudioStream({
  cameraId,
  onDisconnected,
}: {
  cameraId: number;
  onDisconnected: () => void;
}) {
  const match = useMatch("/o/:orgSlug/*");
  const socket = useRef<SocketIOClient.Socket>();
  const [connected, setConnected] = useState<boolean>(false);
  useEffectOnce(() => {
    socket.current = io("/rtsp_signaler", {
      path: "/socket",
      transports: ["polling"],
      transportOptions: {
        polling: {
          extraHeaders: {
            "x-spot-camera-id": `${cameraId}`,
            "x-spot-org-slug": match?.params.orgSlug,
            "x-spot-audio-input": "text",
          },
        },
      },
    });
    socket.current.on("disconnect", () => {
      onDisconnected();
      setConnected(false);
    });
    socket.current.on("error", (msg: string) => {
      console.error(msg);
    });
    socket.current.on("connect", () => {
      console.log("connected");
      setConnected(true);
    });
    socket.current.on("textAudio", async (msg: ArrayBuffer) => {
      console.log(msg);
      const audio = new Audio(URL.createObjectURL(new Blob([msg])));
      audio.play();
    });

    return () => {
      if (socket.current) socket.current.close();
    };
  });

  return { socket, connected };
}
