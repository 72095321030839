import { Skeleton } from "@mui/material";
import clsx from "clsx";
import { useState } from "react";

import { useMe } from "@/components/Auth";

import { apiHost } from "@/environment";

export function WallPreview({ ids }: { ids: number[] }) {
  const me = useMe();
  const limitedIds = ids.slice(0, 3); // max 3 stacked previews
  const lastIndex = limitedIds.length - 1;
  const [showSkeleton, setShowSkeleton] = useState(true);
  return (
    <div
      className="w-24 relative flex items-end"
      style={{ paddingTop: -(lastIndex * (lastIndex - 8)) }}
    >
      <div className={clsx("max-w-full relative", { "w-full": showSkeleton })}>
        {limitedIds
          .map((id, index) => (
            <img
              key={id}
              className="block relative pointer-events-none rounded-sm shadow-md"
              style={{
                maxWidth: "inherit",
                maxHeight: "inherit",
                ...(index > 0
                  ? {
                      position: "absolute",
                      left: 5 * index + "%",
                      top: index * (index - 8), // magic formula that "looks good"
                      opacity: 1 / (index + 1),
                      width: 100 - index * 10 + "%",
                      height: "50%", // we're only seeing part of the top for the "behind" tiles anyway
                      objectFit: "cover",
                      objectPosition: "top center",
                    }
                  : undefined),
              }}
              onLoad={() => {
                setShowSkeleton(false);
              }}
              src={`${apiHost}/downloads/${me?.organization.id}/videowall/${id}/preview.jpg`}
              alt=""
            />
          ))
          .reverse()}
        {showSkeleton && <Skeleton width="100%" height="80px" />}
      </div>
    </div>
  );
}
