import clsx from "clsx";
import { atom as jAtom, useAtomValue } from "jotai";
import { atomFamily as jAtomFamily, useAtomCallback } from "jotai/utils";
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";

import { filterNullish } from "@/util/filterFalsy";
import { useThrottle } from "@/util/useThrottle";

import {
  PlayerControlsEvent,
  PlayerControlsProvider,
  PlayerControlsProviderProps,
  usePlayerControlsService,
} from "@/components/Player/playerControlsMachine";
import {
  PlayerMachineProvider,
  PlayerMachineProviderProps,
} from "@/components/Player/playerMachine";

export const defaultPlayerId = "_no_id_";
const emptySlotPlayerId = "";
const PlayerIdsContext = React.createContext<string[]>([defaultPlayerId]);

export function usePlayerIds() {
  return useContext(PlayerIdsContext);
}

export function PlayerIdsProvider({
  children,
  cameraIds,
}: PropsWithChildren<{
  cameraIds: number[];
}>) {
  /*
  Make sure the player ids provider always has a length of 9,
  to ensure that hooks are called the same number of times per render cycle.
 */
  const playerIds = useMemo(
    () =>
      Array.from({ length: 9 }).map(
        (_, i) => cameraIds[i]?.toString() ?? emptySlotPlayerId
      ),
    // eslint-disable-next-line
    [cameraIds.join()]
  );
  return (
    <PlayerIdsContext.Provider value={playerIds}>
      {children}
    </PlayerIdsContext.Provider>
  );
}

export const wallclockPtsState = jAtomFamily((id: string | undefined) => {
  const atom = jAtom(null as number | null);
  atom.debugLabel = `wallclockPtsState:${id}`;
  return atom;
});

export function useWallclockPts() {
  const [id] = usePlayerIds();
  return useAtomValue(wallclockPtsState(id));
}

export const wallclockTimeState = jAtomFamily((id: string | undefined) => {
  const atom = jAtom(null as Date | null);
  atom.debugLabel = `wallClockTimeState:${id}`;
  return atom;
});

/**
 * This will only show the correct time for the first player even if multiple playerIds are used in the provider
 */
export function useWallclockTime() {
  const [id] = usePlayerIds();
  return useAtomValue(wallclockTimeState(id));
}

export const qualityLevelsState = jAtom([
  { label: "0", width: -1, height: -1, bitrate: -1 },
]);
qualityLevelsState.debugLabel = `qualityLevelsState`;

export const vodTimeState = jAtomFamily((id: string | undefined) => {
  const atom = jAtom(
    null as {
      position: number;
      duration: number;
      bufferPercent: number;
    } | null
  );
  atom.debugLabel = `vodTimeState:${id}`;
  return atom;
});

export function useVodTime(playerId = defaultPlayerId) {
  return useAtomValue(vodTimeState(playerId));
}

const completedState = jAtomFamily((ids: string[]) => {
  const atom = jAtom((get) => {
    const times = ids.map((id) => get(vodTimeState(id))).filter(filterNullish);
    if (times.length === 0) return false;
    return times.every((t) => t.position === t.duration);
  });
  atom.debugLabel = `completedState:${ids.join(",")}`;
  return atom;
});

export function useCompletedState() {
  const ids = usePlayerIds();
  return useAtomValue(completedState(ids));
}

export function useMultiCamVodTime() {
  const ids = usePlayerIds();
  const times = ids.map(useVodTime).filter(filterNullish);
  if (times.length === 0) return null;
  return {
    position: times[0].position,
    // position: meanBy((x) => x.position, times),
    duration: Math.max(...times.map((x) => x.duration)),
    bufferPercent: Math.min(...times.map((x) => x.bufferPercent)),
    completed: times.every((t) => t.position === t.duration),
  };
}

export function useMultiCamVodTimeCallback() {
  const ids = usePlayerIds();
  return useAtomCallback(
    useCallback(
      (get) => {
        return () => {
          const times = ids
            .map((id) => get(vodTimeState(id)))
            .filter(filterNullish);
          if (times.length === 0) return null;
          return {
            position: times[0].position,
            duration: Math.max(...times.map((x) => x.duration)),
            bufferPercent: Math.min(...times.map((x) => x.bufferPercent)),
          };
        };
      },
      // eslint-disable-next-line
      [ids.join()]
    )
  );
}

export const zoomState = jAtomFamily((id: string | undefined) => {
  const atom = jAtom(null as (ZoomState & { dragging?: boolean }) | null);
  atom.debugLabel = `zoomState:${id}`;
  return atom;
});

export function useZoomState(playerId = defaultPlayerId) {
  return useAtomValue(zoomState(playerId));
}

export const playerControlsState = jAtomFamily((id: string) => {
  const atom = jAtom({
    getPlayerElement: (() => {}) as () => HTMLVideoElement | undefined,
    seek: (() => {}) as (
      time: number | ((prevTime: number) => number),
      addStartTimeOffset?: boolean
    ) => void,
    setContinuation: (time: number) => {},
    setFullscreen: (fullscreen: boolean) => {},
    setVolume: (volume: number) => {},
    forceQuality: (selectedQuality: string, callback: () => void) => {},
    play: () => {},
    pause: () => {},
  });
  atom.debugLabel = `playerControlsState:${id}`;
  return atom;
});

export function usePlayerControls(playerId = defaultPlayerId) {
  return useAtomValue(playerControlsState(playerId));
}

export function useMultiPlayerControls() {
  const ids = usePlayerIds();
  const controls = ids.map(usePlayerControls);
  const playFunctions = controls.map((c) => c.play);
  const play = useCallback(() => {
    playFunctions.forEach((f) => f());
    // eslint-disable-next-line
  }, playFunctions);

  const pauseFunctions = controls.map((c) => c.pause);
  const pause = useCallback(() => {
    pauseFunctions.forEach((f) => f());
    // eslint-disable-next-line
  }, pauseFunctions);

  const seekFunctions = controls.map((c) => c.seek);
  const seek = useCallback(
    (
      time: number | ((prevTime: number) => number),
      addStartTimeOffset?: boolean
    ) => {
      seekFunctions.forEach((f) => {
        f(time, addStartTimeOffset);
      });
    },
    // eslint-disable-next-line
    seekFunctions
  );

  const setContinuationFunctions = controls.map((c) => c.setContinuation);
  const setContinuation = useCallback(
    (time: number) => {
      setContinuationFunctions.forEach((f) => f(time));
    },
    // eslint-disable-next-line
    setContinuationFunctions
  );

  return { play, pause, seek, setContinuation };
}

export const readyState = jAtomFamily((id: string | undefined) => {
  const atom = jAtom(0);
  atom.debugLabel = `readyState:${id}`;
  return atom;
});

export function useReadyState(playerId = defaultPlayerId) {
  return useAtomValue(readyState(playerId));
}

export function useIsBuffering(playerId = defaultPlayerId) {
  const ready = useReadyState(playerId);
  if (playerId === emptySlotPlayerId) return false;
  return ready < 3;
}

export function useMultiPlayerBuffering() {
  const ids = usePlayerIds();
  const bufferingStates = ids.map(useIsBuffering);
  if (bufferingStates.length < 2) return false;
  return bufferingStates.some(Boolean);
}

export const playingIntentState = jAtom(true);
playingIntentState.debugLabel = "playingIntentState";

export function usePlayingIntent() {
  return useAtomValue(playingIntentState);
}

export const playbackRateState = jAtom(1);
playbackRateState.debugLabel = "playbackRateState";

export function usePlaybackRate() {
  return useAtomValue(playbackRateState);
}

interface PlayerBaseProps {
  timezone?: string;
  className?: string;
  PlayerMachineProps?: PlayerMachineProviderProps;
  PlayerControlsProps?: PlayerControlsProviderProps;
  enableControlsEvents?: boolean;
}

export function PlayerBase(props: PropsWithChildren<PlayerBaseProps>) {
  return (
    <>
      <PlayerMachineProvider {...props.PlayerMachineProps}>
        <PlayerControlsProvider {...props.PlayerControlsProps}>
          <PlayerControlsContainer {...props} />
        </PlayerControlsProvider>
      </PlayerMachineProvider>
    </>
  );
}

export function PlayerControlsContainer({
  children,
  className,
  enableControlsEvents = true,
}: PropsWithChildren<PlayerBaseProps>) {
  const { send } = usePlayerControlsService();

  const showControls = useCallback(() => {
    send(PlayerControlsEvent.SHOW_CONTROLS);
  }, [send]);
  const hideControls = useCallback(() => {
    send(PlayerControlsEvent.HIDE_CONTROLS);
  }, [send]);
  const throttledShow = useThrottle(showControls, 2000);

  return (
    <div
      className={clsx("flex flex-col w-full h-full relative", className)}
      // todo: pointer events for showing/hiding controls
      // Throttling mouse move to not send events to the machine on each move event
      onMouseMove={enableControlsEvents ? throttledShow : undefined}
      onTouchStart={enableControlsEvents ? showControls : undefined}
      onMouseLeave={enableControlsEvents ? hideControls : undefined}
      onMouseEnter={enableControlsEvents ? showControls : undefined}
      onClick={enableControlsEvents ? throttledShow : undefined}
      data-cy="player-base"
    >
      {children}
    </div>
  );
}

export interface ZoomState {
  zoomLevel: number;
  xPos: number;
  yPos: number;
}

export function PlayerAspect({
  children,
  maxHeight,
  minHeight,
  className,
  onClick,
  disable,
}: PropsWithChildren<{
  maxHeight?: number | string;
  minHeight?: number | string;
  className?: string;
  onClick?: () => void;
  disable?: boolean;
}>) {
  // Force 16:9 aspect ratio for the player.
  const dimensions = { width: 16, height: 9 };
  const ratio = dimensions.height / dimensions.width;
  if (disable) return <>{children}</>;
  return (
    <div className={clsx("relative w-full", className)}>
      <div style={{ minHeight, maxHeight }}>
        <div
          style={{
            paddingTop: `calc(100% * ${ratio})`,
          }}
        ></div>
      </div>
      <div className="absolute w-full h-full top-0" onClick={onClick}>
        {children}
      </div>
    </div>
  );
}

export function PlayerSyncer({ id }: { id: string }) {
  const { seek } = usePlayerControls(id);
  const playerTime = useVodTime(id);
  const aggregatedTime = useMultiCamVodTime();
  const throttledSeek = useThrottle(seek, 1000);
  const position = playerTime?.position;
  const aggregatedPosition = aggregatedTime?.position;
  useEffect(() => {
    if (!position || !aggregatedPosition) return;
    if (Math.abs(position - aggregatedPosition) > 2) {
      throttledSeek(aggregatedPosition);
    }
  }, [position, aggregatedPosition, throttledSeek]);
  return null;
}
