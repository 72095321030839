import MenuIcon from "@mui/icons-material/MoreVert";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import clsx from "clsx";
import gql from "graphql-tag";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useState, memo } from "react";
import { useSearchParams } from "react-router-dom";
import { BooleanParam, useQueryParams } from "use-query-params";

import { useGlobalFullscreenState } from "@/util/fullscreen";
import { isPTZSupported as isPTZSupportedByCamera } from "@/util/isPTZSupported";
import { isWebRTCSupported } from "@/util/isWebRTCSupported";
import { useLocalStorage } from "@/util/useLocalStorage";
import { useViewActivityTimer } from "@/util/useViewActivityTimer";

import {
  ActivityOverlay,
  LicensePlateRecognitionActivityOverlay,
} from "@/pages/Search/ActivityOverlay";
import HeatmapOverlay from "@/pages/Search/HeatmapOverlay";
import { MotionZoneOverlay } from "@/pages/Search/ZoneMotion/MotionZoneOverlay";
import { ZoneSwitch } from "@/pages/Search/ZoneMotion/ZoneSwitch";

import { CopilotOverlay } from "@/components/Ai/Copilot/CopilotOverlay/CopilotOverlay";
import { CopilotVideoPlayerTray } from "@/components/Ai/Copilot/CopilotPlayerSection/CopilotPlayerSection";
import {
  useCopilotEnabled,
  useIsCopilotCompatible,
} from "@/components/Ai/Copilot/copilotQueryHooks";
import { useMe } from "@/components/Auth";
import { ErrorMessage } from "@/components/ErrorMessage";
import { FixedAspectRatio } from "@/components/FixedAspectRatio";
import { AddToCaseButton } from "@/components/Player/AddToCaseButton";
import { Back10Button } from "@/components/Player/Back10Button";
import { BufferingIndicator } from "@/components/Player/BufferingIndicator";
import { CompletedOverlay } from "@/components/Player/CompletedOverlay";
import { DisabledShareTrayItem } from "@/components/Player/DisabledShareTrayItem";
import { DownloadButton } from "@/components/Player/DownloadButton";
import { Forward10Button } from "@/components/Player/Forward10Button";
import { FullscreenButton } from "@/components/Player/FullscreenButton";
import { HealthButton } from "@/components/Player/HealthButton";
import { KeyboardShortcuts } from "@/components/Player/KeyboardShortcuts";
import {
  LiveConnectivityDisplay,
  VodConnectivityDisplay,
} from "@/components/Player/MainPlayer/MainConnectivityDisplays";
import { MobileOverlay } from "@/components/Player/MobileOverlay";
import { NextClipButton } from "@/components/Player/NextClipButton";
import { PTZButton } from "@/components/Player/PTZ/PTZButton";
import { PlayPauseButton } from "@/components/Player/PlayPauseButton";
import { PlayPauseReplayButton } from "@/components/Player/PlayPauseReplayButton";
import { PlaybackRateDisplay } from "@/components/Player/PlaybackRateDisplay";
import {
  PlayerAspect,
  PlayerBase,
  PlayerControlsContainer,
  useZoomState,
} from "@/components/Player/PlayerBase";
import {
  usePlayerMobileMode,
  useVodStills,
} from "@/components/Player/PlayerControls";
import { PlayerControls } from "@/components/Player/PlayerControls.1";
import {
  CaseSmallIcon,
  DownloadIcon,
  ExpandIcon,
  MinimizeIcon,
  RemoveIcon,
} from "@/components/Player/PlayerIcons";
import {
  HdIndicator,
  LocalIndicator,
} from "@/components/Player/PlayerMetadata";
import {
  PlaybackRateSetting,
  PlayerSettingsButton,
  QualitySetting,
} from "@/components/Player/PlayerSettingsButton";
import { PreviousClipButton } from "@/components/Player/PreviousClipButton";
import { ProgressBar } from "@/components/Player/ProgressBar";
import { ProgressDisplay } from "@/components/Player/ProgressDisplay";
import { ReplayButton } from "@/components/Player/ReplayButton";
import { ShareCameraButton } from "@/components/Player/ShareCameraButton";
import { ShareClipButton } from "@/components/Player/ShareClipButton";
import { ShareTrayButton } from "@/components/Player/ShareTrayButton";
import { SnapshotButton } from "@/components/Player/SnapshotButton";
import {
  LatestCameraStillOverlay,
  StillOverlay,
} from "@/components/Player/Status/ConnectivityDisplay";
import { TopRightTray } from "@/components/Player/TopRightTray";
import { VideoOverlay } from "@/components/Player/VideoOverlay";
import { VideoPlayer } from "@/components/Player/VideoPlayer";
import { VolumeButton } from "@/components/Player/VolumeButton";
import { DigitalPtzButton } from "@/components/Player/ZoomButton";
import { PlayerControlsProvider } from "@/components/Player/playerControlsMachine";
import { RemoveCameraButton } from "@/components/View/ViewNavigation";
import {
  useFocusedCam,
  useSetFocusedCam,
} from "@/components/View/sharedViewHooks";

import {
  Camera,
  CameraHealth,
  CameraSettings,
  DeviceScan,
  Location,
  Role,
  useRecordLiveViewActivityMutation,
  useVodStreamQuery,
} from "@/generated-models";
import { DarkTheme } from "@/layout/theme";

import { LiveViewTracker } from "../ActivityTracker";
import MobilePlayerError from "../MobilePlayerError";
import LivekitPlayer from "../WebRTCPlayer/LivekitPlayer";
import { PlayerMachineProvider, usePlayingState } from "../playerMachine";

// Required to ensure TW doesn't purge these classes during prod builds
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const gridColumnClasses = [
  "grid-cols-1",
  "grid-cols-2",
  "grid-cols-3",
  "grid-cols-4",
  "grid-cols-5",
];

interface LivePlayerProps {
  cameras: (Pick<
    Camera,
    "id" | "name" | "still" | "feeds" | "health" | "vendor"
  > & {
    location: Pick<Location, "id" | "timezone">;
    device: Pick<DeviceScan, "id" | "onvifSupported">;
    settings: Pick<CameraSettings, "audioControlEnabled">;
  })[];
  overrideMachineProvider?: boolean;
  lowLatency?: boolean;
}

function LivePlayerComponent({
  cameras,
  overrideMachineProvider = true,
  lowLatency = false,
}: LivePlayerProps) {
  const [experimentalShaka] = useLocalStorage("experimentalShaka", false);
  const [isFullscreen] = useGlobalFullscreenState("isFullscreen");
  const playerMobileMode = usePlayerMobileMode();
  const focusedCam = useFocusedCam(cameras);
  const copilotCompatible = useIsCopilotCompatible();
  const { copilotEnabled } = useCopilotEnabled();
  const setFocusedCam = useSetFocusedCam();
  const multiCamMode = cameras.length > 1;
  const me = useMe();
  const { auditLogData } = useFlags();
  const showVolume = !multiCamMode && !!cameras[0];
  const playerId = focusedCam.id.toString();
  const zoom = useZoomState(playerId);
  const isPTZSupported =
    isPTZSupportedByCamera(cameras[0].vendor) &&
    isWebRTCSupported() &&
    me?.organization.flags.ptz &&
    cameras[0].device.onvifSupported;
  const audioControlEnabled =
    !multiCamMode && (cameras[0]?.settings?.audioControlEnabled ?? false);

  const digitalPtzProps = {
    key: `${focusedCam.id}-digital-ptz`,
    playerId,
    disabled: multiCamMode ? "Focus a camera to zoom" : undefined,
  };

  return (
    <PlayerControlsProvider>
      <PlayerMachineProvider override={overrideMachineProvider}>
        <PlayerControlsContainer>
          {playerMobileMode && cameras.length === 1 && (
            <LiveConnectivityDisplay
              health={cameras[0].health}
              cameraId={cameras[0].id}
              mode="inline"
            />
          )}
          <PlayerAspect
            maxHeight={isFullscreen ? `calc(100vh - 62px)` : undefined}
            className="rounded-t m-auto"
          >
            <div
              id="player-container"
              className={`h-full w-full bg-black ${
                multiCamMode
                  ? `p-1 gap-1 grid grid-cols-${Math.ceil(
                      Math.sqrt(cameras.length)
                    )}`
                  : ""
              }`}
            >
              {cameras.map((cam) => {
                const playerId = cam.id.toString();

                const player = (
                  <VideoPlayer
                    playerId={playerId}
                    sources={cam.feeds}
                    showingLivestream
                    forceAutoQuality={multiCamMode}
                    // Feature flag for BLISS Car Wash - Bliss Palmdale location
                    // We could put this feature flag in Split if we want, but
                    // I think the long term solution should be appliance side,
                    // so this is just a short term solution.
                    forceHighestQuality={
                      cam.location.id === 616 || cam.location.id === 613
                    }
                    muxConfig={{
                      cameraId: cam.id,
                      playerName: "Main Live",
                      sampling: 1, // No sampling on basic live players
                    }}
                  />
                );

                if (experimentalShaka) {
                  return player;
                }

                const focused = focusedCam.id === cam.id;

                const canBeFocused = multiCamMode && !focused;

                return (
                  <PlayerBase
                    key={cam.id}
                    PlayerMachineProps={{ override: multiCamMode }}
                    PlayerControlsProps={{ override: multiCamMode }}
                    enableControlsEvents={multiCamMode}
                    className={clsx({
                      // "col-span-3 row-span-3": expanded && multiCamMode,
                      "ring-2 ring-white": focused && multiCamMode,
                    })}
                  >
                    {auditLogData ? (
                      <ViewRecorder cameraId={cam.id} />
                    ) : (
                      <LiveViewTracker cameraId={cam.id} />
                    )}
                    <PlayerAspect
                      className="overflow-hidden"
                      onClick={
                        canBeFocused
                          ? () => setFocusedCam(cam.id, "replaceIn")
                          : undefined
                      }
                    >
                      {lowLatency ? (
                        <LivekitPlayer
                          url={cam.feeds?.webRTC ?? ""}
                          playerId={playerId}
                        />
                      ) : (
                        player
                      )}
                    </PlayerAspect>

                    {copilotCompatible && playerId && !zoom && (
                      <div
                        className={clsx({
                          "absolute w-full h-full": copilotEnabled,
                        })}
                      >
                        {copilotEnabled && (
                          <MotionZoneOverlay cameraId={cam.id} />
                        )}
                        <CopilotOverlay />
                      </div>
                    )}

                    <LatestCameraStillOverlay
                      cameraId={cam.id}
                      still={cam.still}
                    />
                    <BufferingIndicator playerId={playerId} />
                    {!playerMobileMode && (
                      <LiveConnectivityDisplay
                        health={cam.health}
                        cameraId={cam.id}
                      />
                    )}
                    {multiCamMode && (
                      <PlayerControls>
                        <div className="w-full bg-black bg-opacity-70 flex items-center gap-3 px-3 py-1 text-white">
                          <div className="font-medium text-base">
                            {cam.name}
                          </div>
                          <HdIndicator />
                          <LocalIndicator />
                          <div className="grow" />
                          <VolumeButton playerId={playerId} enabled={false} />
                        </div>
                      </PlayerControls>
                    )}

                    {!playerMobileMode && copilotEnabled && (
                      <PlayerControls>
                        <CopilotVideoPlayerTray
                          focusedPlayerId={playerId}
                          digitalPtzProps={digitalPtzProps}
                        />
                      </PlayerControls>
                    )}
                    {!multiCamMode && (
                      <TopRightTray>
                        {playerMobileMode ? (
                          <ShareCameraButton cameraId={cam.id} iconButton />
                        ) : (
                          <ShareTrayButton>
                            <ShareCameraButton cameraId={cam.id} />
                            <Divider className="bg-gray-800" />
                            <DisabledShareTrayItem
                              icon={<CaseSmallIcon />}
                              label="Save to Case"
                              tooltipTitle="Not available for live streams"
                            />
                            <Divider className="bg-gray-800" />
                            <DisabledShareTrayItem
                              icon={<DownloadIcon />}
                              label="Download"
                              tooltipTitle="Not available for live streams"
                            />
                          </ShareTrayButton>
                        )}
                        <SnapshotButton
                          playerId={playerId}
                          cameraId={cam.id}
                          cameraName={cam.name}
                          timezone={cam.location.timezone}
                        />
                        {me && me.role >= Role.Success && (
                          <HealthButton cameraId={cam.id} />
                        )}
                      </TopRightTray>
                    )}
                    <MobileOverlay>
                      <PlayPauseButton fontSize={60} />
                    </MobileOverlay>
                  </PlayerBase>
                );
              })}
            </div>
          </PlayerAspect>
          <KeyboardShortcuts />

          {/* Can this stay? */}
          {/* <MobileLandscapeFullscreen /> */}

          {!copilotEnabled && (
            <PlayerControls disabled={false}>
              <div
                style={{
                  background: "linear-gradient(transparent 0%, black 140%)",
                }}
                className="flex-center w-full py-0.5"
              >
                {!playerMobileMode && (
                  <>
                    <Back10Button disabled />
                    <PlayPauseButton />
                    <Forward10Button disabled />
                  </>
                )}

                <div className="flex grow items-center">
                  <Box flexGrow={1} />
                  {isPTZSupported &&
                  !playerMobileMode &&
                  cameras[0]?.feeds?.webRTC ? (
                    <PTZButton
                      popupClassName={clsx(!showVolume && "!right-[-82px]")}
                      cameraId={cameras[0].id}
                      deviceId={cameras[0].device.id}
                      url={cameras[0].feeds.webRTC}
                      disabled={!lowLatency}
                      disabledTooltip="PTZ controls require low latency enabled"
                    />
                  ) : (
                    <DigitalPtzButton {...digitalPtzProps} />
                  )}
                  {showVolume && (
                    <VolumeButton
                      playerId={cameras[0].id.toString()}
                      enabled={audioControlEnabled}
                    />
                  )}
                  <PlayerSettingsButton>
                    {!multiCamMode && <QualitySetting />}
                  </PlayerSettingsButton>
                  <FullscreenButton
                    playerId={
                      cameras.length === 1
                        ? cameras[0].id.toString()
                        : undefined
                    }
                  />
                </div>
              </div>
            </PlayerControls>
          )}
        </PlayerControlsContainer>
      </PlayerMachineProvider>
    </PlayerControlsProvider>
  );
}

interface VodPlayerProps extends Omit<LivePlayerProps, "cameras"> {
  cameras: (Pick<Camera, "id" | "name"> & {
    location: Pick<Location, "id" | "timezone">;
    settings: Pick<CameraSettings, "audioControlEnabled">;
    health: Pick<CameraHealth, "cameraOnline" | "applianceOnline">;
  })[];
  playPreviousClip?: () => void;
  playNextClip?: () => void;
  startTime: string;
  endTime: string;
  startPosition?: number;
  subjects?: string[] | null;
}

function VodPlayerComponent({
  cameras,
  startTime,
  endTime,
  startPosition,
  playPreviousClip,
  playNextClip,
  overrideMachineProvider = true,
  subjects,
}: VodPlayerProps) {
  const { scrubbingMobileVodPlayer } = useFlags();
  const { copilotEnabled } = useCopilotEnabled();
  const [isFullscreen] = useGlobalFullscreenState("isFullscreen");
  const playerMobileMode = usePlayerMobileMode();
  const [expandedCamId, setExpandedCamId] = useState<number | null>(null);
  const expandedCam = expandedCamId
    ? cameras.find((cam) => cam.id === expandedCamId)
    : undefined;
  const sortedCams = expandedCam
    ? [expandedCam, ...cameras.filter((cam) => cam.id !== expandedCamId)]
    : cameras;
  const focusedCam = useFocusedCam(cameras);
  const focusedCamId = focusedCam.id;
  const focusedPlayerId = focusedCamId.toString();
  const multiCamMode = cameras.length > 1;
  const activeCamIds = cameras.map((c) => c.id);
  const audioControlEnabled =
    !multiCamMode && (cameras[0]?.settings?.audioControlEnabled ?? false);
  let [searchParams] = useSearchParams();

  // Skip fetching vod still in multi cam mode
  const stills = useVodStills(
    focusedCamId,
    startTime,
    endTime,
    multiCamMode || (playerMobileMode && !scrubbingMobileVodPlayer)
  );

  const digitalPtzProps = {
    key: `${focusedCamId}${startTime}${endTime}`,
    playerId: focusedPlayerId,
    disabled: multiCamMode ? "Focus a camera to zoom" : undefined,
  };

  return (
    <PlayerControlsProvider>
      {/* This PlayerMachineProvider will be overriden (not used) in case of multicam */}
      <PlayerMachineProvider override={overrideMachineProvider}>
        <PlayerControlsContainer>
          {playerMobileMode && cameras.length === 1 && (
            <VodConnectivityDisplay
              health={cameras[0].health}
              cameraId={cameras[0].id}
              mode="inline"
            />
          )}
          <FixedAspectRatio
            ratio="9 / 16"
            className={clsx(
              "bg-black my-auto",
              { "py-2": multiCamMode },
              {
                "border-[6px] border-primary relative": searchParams.get(
                  "case"
                ),
              }
            )}
            style={
              isFullscreen ? { maxHeight: "calc(100vh - 62px)" } : undefined
            }
            classNameInner={clsx({
              [`px-2 gap-2 grid content-center grid-cols-${Math.ceil(
                Math.sqrt(cameras.length + (!!expandedCamId ? 8 : 0))
              )}`]: multiCamMode,
            })}
          >
            {sortedCams.map((cam) => (
              <VodVideoPlayer
                key={cam.id}
                camera={cam}
                activeCamIds={activeCamIds}
                startTime={startTime}
                endTime={endTime}
                startPosition={startPosition}
                multiCamMode={multiCamMode}
                expanded={expandedCamId ? expandedCamId === cam.id : null}
                toggleExpand={() =>
                  setExpandedCamId((prev) => (prev === cam.id ? null : cam.id))
                }
                focused={focusedCamId === cam.id}
                subjects={subjects}
              />
            ))}
          </FixedAspectRatio>
          <KeyboardShortcuts />

          <MobileOverlay>
            <Back10Button fontSize={36} />
            <PlayPauseReplayButton fontSize={60} />
            <Forward10Button fontSize={36} />
          </MobileOverlay>

          {!multiCamMode && !copilotEnabled && (
            <CompletedOverlay>
              <PreviousClipButton fontSize={36} onClick={playPreviousClip} />
              <ReplayButton fontSize={60} />
              <NextClipButton fontSize={36} onClick={playNextClip} />
            </CompletedOverlay>
          )}

          {/* <MobileLandscapeFullscreen /> */}

          <PlayerControls
            className={
              searchParams.get("case")
                ? "absolute z-1 left-[6px] right-[6px] bottom-[6px]"
                : "absolute bottom-0 left-0 right-0 z-1"
            }
            disabled={false}
          >
            {playerMobileMode && (
              <div className="flex w-full justify-end z-10 relative">
                <DigitalPtzButton {...digitalPtzProps} />
                <VolumeButton
                  enabled={audioControlEnabled && !multiCamMode}
                  playerId={focusedPlayerId}
                />
                <PlayerSettingsButton>
                  <PlaybackRateSetting />
                  {!multiCamMode && <QualitySetting />}
                </PlayerSettingsButton>
                <FullscreenButton playerId={focusedPlayerId} />
              </div>
            )}
            <ProgressBar stills={stills} />

            {!playerMobileMode && copilotEnabled && (
              <CopilotVideoPlayerTray
                focusedPlayerId={focusedPlayerId}
                digitalPtzProps={digitalPtzProps}
              />
            )}

            {!playerMobileMode && !copilotEnabled && (
              <div
                style={{
                  background:
                    "linear-gradient(180deg, rgba(0, 0, 0, 0.68) 0%, #000000 90%)",
                }}
                className="flex-center py-0.5 w-full"
              >
                <Back10Button />
                <PlayPauseReplayButton />
                <Forward10Button />

                <Box mx={1}>
                  <ProgressDisplay />
                </Box>
                <PlaybackRateDisplay />

                <div className="flex grow items-center">
                  <Box flexGrow={1} />

                  <DigitalPtzButton {...digitalPtzProps} />
                  <VolumeButton
                    enabled={audioControlEnabled && !multiCamMode}
                    playerId={focusedPlayerId}
                  />
                  <PlayerSettingsButton>
                    <PlaybackRateSetting />
                    {!multiCamMode && <QualitySetting />}
                  </PlayerSettingsButton>
                  <FullscreenButton playerId={focusedPlayerId} />
                </div>
              </div>
            )}
          </PlayerControls>
        </PlayerControlsContainer>
      </PlayerMachineProvider>
    </PlayerControlsProvider>
  );
}

export const VodVideoPlayer = memo(VodVideoPlayerComponent);

function VodVideoPlayerComponent({
  camera,
  activeCamIds,
  startTime,
  endTime,
  startPosition,
  multiCamMode = false,
  toggleExpand,
  expanded,
  focused,
  subjects,
}: {
  camera: VodPlayerProps["cameras"][number];
  activeCamIds: number[];
  startTime: string;
  endTime: string;
  startPosition?: number;
  multiCamMode?: boolean;
  toggleExpand: () => void;
  expanded: boolean | null; // null means no cam is expanded
  focused: boolean;
  subjects?: string[] | null;
}) {
  const { vodV2Viewing } = useFlags();
  const copilotCompatible = useIsCopilotCompatible();
  const { copilotEnabled } = useCopilotEnabled();
  const setFocusedCam = useSetFocusedCam();
  const playerMobileMode = usePlayerMobileMode();
  const cameraId = camera.id;
  const playerId = cameraId.toString();
  const zoom = useZoomState(playerId);
  const [capabilitiesParams] = useQueryParams({
    hideMultiCamRemove: BooleanParam,
    hideMultiCamZone: BooleanParam,
  });
  const { data, error } = useVodStreamQuery({
    variables: { id: cameraId, startTime, endTime },
  });
  const [showAiDebug] = useLocalStorage("showAiDebug", false);

  if (error) {
    const description = error.toString() || (
      <Typography
        className={clsx({
          "text-center text-sm overflow-hidden text-ellipsis": playerMobileMode,
        })}
      >
        Something went wrong while trying to load a VOD...
        <br />
        Please try again later.
      </Typography>
    );
    const title = "Error";

    return (
      <DarkTheme>
        {playerMobileMode ? (
          <MobilePlayerError title={title} description={description} />
        ) : (
          <ErrorMessage
            dense={multiCamMode}
            title={title}
            description={description}
          />
        )}
      </DarkTheme>
    );
  }

  const commonButtonProps = { cameraId, startTime, endTime };
  const snapshotProps = {
    playerId,
    cameraId,
    cameraName: camera.name,
    timezone: camera.location.timezone,
  };
  const shareTrayItems = (
    <>
      <ShareClipButton {...commonButtonProps} />
      <Divider className="bg-gray-800" />
      <AddToCaseButton {...commonButtonProps} />
      <Divider className="bg-gray-800" />
      <DownloadButton {...commonButtonProps} />
    </>
  );
  const canBeFocused = multiCamMode && !focused;

  return (
    <PlayerBase
      PlayerMachineProps={{ override: multiCamMode }}
      PlayerControlsProps={{ override: multiCamMode }}
      enableControlsEvents={multiCamMode}
      className={clsx({
        "col-span-3 row-span-3": expanded && multiCamMode,
        "ring-2 ring-white": focused && multiCamMode,
      })}
    >
      <PlayerAspect
        className={clsx("overflow-hidden", { "cursor-pointer": canBeFocused })}
        onClick={
          canBeFocused ? () => setFocusedCam(cameraId, "replaceIn") : undefined
        }
      >
        <VideoPlayer
          playerId={playerId}
          sources={data?.vod?.feeds}
          forceAutoQuality={multiCamMode}
          forceHighestQuality={
            camera.location.id === 616 || camera.location.id === 613
          }
          currentTime={startPosition}
          muxConfig={{
            cameraId,
            playerName: "Main VOD",
            sampling: 1, // 100% sampling for main VOD
            vodStart: startTime,
            vodEnd: endTime,
            ...(vodV2Viewing && { experimentName: "vod_v2_viewing" }),
          }}
        />
      </PlayerAspect>
      {data?.vod?.still && <StillOverlay src={data.vod.still} />}
      <VolumeButton playerId={playerId} enabled={false} />
      {copilotCompatible && playerId && !zoom && (
        <div
          className={clsx({
            "absolute w-full h-full": copilotEnabled,
          })}
        >
          <CopilotOverlay />
        </div>
      )}
      <VideoOverlay
        enablePointerEvents={
          !zoom || zoom.zoomLevel === 1 || (!zoom && !!copilotEnabled)
        }
        playerId={playerId}
      >
        <MotionZoneOverlay cameraId={cameraId} />
        {!copilotEnabled && (
          <ActivityOverlay
            timezone={camera.location.timezone}
            subjects={subjects}
            {...commonButtonProps}
          />
        )}
        {showAiDebug && (
          <LicensePlateRecognitionActivityOverlay
            cameraId={cameraId}
            startTime={startTime}
            endTime={endTime}
          />
        )}
        <HeatmapOverlay
          camId={cameraId}
          startTime={startTime}
          endTime={endTime}
        />
      </VideoOverlay>
      {data?.vod && <BufferingIndicator playerId={playerId} />}
      {!playerMobileMode && !multiCamMode && (
        <VodConnectivityDisplay health={camera.health} cameraId={cameraId} />
      )}
      {/* {!multiCamMode && <ConnectivityPopup cameraHealth={camera.health} />} */}
      <>
        {multiCamMode ? (
          <PlayerControls className="absolute top-0 left-0 right-0">
            <div className="w-full bg-black bg-opacity-70 flex items-center gap-2 px-3 py-1 text-white">
              <div className="font-medium text-sm truncate">{camera.name}</div>
              <div className="mr-auto">
                {!capabilitiesParams.hideMultiCamRemove && (
                  <RemoveCameraButton
                    cameraId={cameraId}
                    activeCamIds={activeCamIds}
                    className="text-white"
                  >
                    <RemoveIcon />
                  </RemoveCameraButton>
                )}
              </div>
              {expanded && (
                <>
                  {!capabilitiesParams.hideMultiCamZone && (
                    <ZoneSwitch cameraId={cameraId} multiCamMode />
                  )}
                  <DigitalPtzButton playerId={playerId} size="small" />
                  <SnapshotButton
                    {...snapshotProps}
                    size="small"
                    className=""
                  />
                  <ShareTrayButton size="small" className="">
                    {shareTrayItems}
                  </ShareTrayButton>
                </>
              )}
              {expanded === null && (
                <>
                  {!capabilitiesParams.hideMultiCamZone && (
                    <ZoneSwitch cameraId={cameraId} multiCamMode />
                  )}
                  <ShareTrayButton
                    icon={<MenuIcon />}
                    size="small"
                    className=""
                  >
                    {shareTrayItems}
                    <Divider className="bg-gray-800" />
                    <SnapshotButton
                      {...snapshotProps}
                      size="small"
                      className=""
                      trayItem
                    />
                  </ShareTrayButton>
                </>
              )}
              <IconButton
                size="small"
                onClick={() => toggleExpand()}
                className="text-white"
              >
                {expanded ? (
                  <MinimizeIcon />
                ) : (
                  <ExpandIcon viewBox="0 0 20 20" />
                )}
              </IconButton>
            </div>
          </PlayerControls>
        ) : (
          <TopRightTray>
            <ShareTrayButton>{shareTrayItems}</ShareTrayButton>
            <SnapshotButton {...snapshotProps} />
          </TopRightTray>
        )}
      </>
    </PlayerBase>
  );
}

function ViewRecorder({ cameraId }: { cameraId: number }) {
  const playingState = usePlayingState();

  const [recordActivity] = useRecordLiveViewActivityMutation();
  useViewActivityTimer(playingState === "playing", (start: Date) =>
    recordActivity({
      variables: { id: cameraId, start: start.toISOString() },
    })
  );

  return null;
}

gql`
  mutation recordLiveViewActivity($id: Int!, $start: DateTime!) {
    recordLiveViewActivity(id: $id, start: $start)
  }
`;

gql`
  query vodStream($id: Int!, $startTime: String!, $endTime: String!) {
    vod(id: $id, startTime: $startTime, endTime: $endTime) {
      id
      feeds {
        tunnel
        local
      }
      still
    }
  }
`;

export const LivePlayer = memo(LivePlayerComponent);

export const VodPlayer = memo(VodPlayerComponent);
