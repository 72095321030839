import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Search,
} from "@mui/icons-material";
import { Divider, IconButton, Skeleton, Typography } from "@mui/material";
import clsx from "clsx";
import gql from "graphql-tag";
import { orderBy, uniqBy } from "lodash/fp";
import {
  lazy,
  PropsWithChildren,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { Lazy } from "swiper";
// Import Swiper styles
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper/types";
import {
  DelimitedNumericArrayParam,
  NumberParam,
  useQueryParam,
} from "use-query-params";

import { useBreakpoints } from "@/util/useBreakpoints";
import { useSlugMatch } from "@/util/useSlugMatch";

import { ErrorMessage } from "@/components/ErrorMessage";
import { FullScreen } from "@/components/FullScreen";
import { RecentCameras } from "@/components/Live/RecentCameras";
import { WallClock } from "@/components/Live/WallClock";
import { WatchLiveHeader } from "@/components/Live/WatchLiveHeader";
import { LiveIndicator } from "@/components/Player/LiveIndicator";
import { LivePlayer } from "@/components/Player/MainPlayer/MainPlayer";
import { PlayerIdsProvider } from "@/components/Player/PlayerBase";
import { PlayerMachineProvider } from "@/components/Player/playerMachine";
import { WiredMobileCameraDrawer } from "@/components/View/MobileCameraDrawer";
import { useActiveCamIds } from "@/components/View/sharedViewHooks";
import { QueryParamLink } from "@/components/shared/QueryParamLink";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  CameraStatus,
  LifecycleStates,
  useLiveAllCamsQuery,
  useLiveGroupQuery,
  usePage_LiveQuery,
} from "@/generated-models";
import { usePrefixOrgSlug } from "@/hooks/useOrgRouteBase";
import { useCustomScrollbarStyles } from "@/layout/theme";

import { MainPlayerMobileHeader } from "../../components/Player/MainPlayer/MainPlayerMobileHeader";

const Insights = lazy(() => import("../../components/Insights/Insights"));

/**
 * Helper hook as long as this new mobile view is feature flagged. Once this rolls
 * out to GA, it will be the default component and hopefully we won't have to check
 * for this flag anymore.
 */
export function useLiveMobile() {
  const isLive = useSlugMatch("live");
  const { fitsDesktop } = useBreakpoints();
  return Boolean(isLive && !fitsDesktop);
}

export function useMobileNav() {
  const liveMobile = useLiveMobile();
  const activeCamIds = useActiveCamIds();
  const { fitsSmallTablet } = useBreakpoints();
  const rotatingWall = useSlugMatch("wall/r/:id");
  const singleWall = useSlugMatch("wall/:id");
  const isVidWall = !!(rotatingWall || singleWall);
  return (
    (!liveMobile || activeCamIds.length === 0) && !fitsSmallTablet && !isVidWall
  );
}

function useActiveGroupCameras() {
  // Not using localStorage here because this view scopes the current cam to the
  // current group.
  const [activeGroupId] = useQueryParam("g", NumberParam);
  const { data: allCamsData } = useLiveAllCamsQuery({
    skip: activeGroupId !== undefined,
  });
  const { data: groupData } = useLiveGroupQuery({
    variables: { id: activeGroupId ?? -1 },
    skip: activeGroupId === undefined,
  });

  let result:
    | {
        id: number;
        name: string;
        still: string;
        lifecycleState: LifecycleStates;
        status: CameraStatus;
      }[]
    | null = null;
  if (allCamsData) result = allCamsData.cameras;

  if (groupData) {
    const { cameras, locations } = groupData.group;
    result = uniqBy("id", cameras.concat(locations.flatMap((l) => l.cameras)));
  }

  if (result) {
    result = orderBy(["status", "name"], ["desc", "asc"], result);
  }

  return result;
}

function useActiveCam(groupCameras: { id: number }[] | null) {
  const activeCamIds = useActiveCamIds();
  const activeCamId = activeCamIds[0] as number | undefined;
  const { data, error } = usePage_LiveQuery({
    variables: { ids: activeCamIds },
    ...refetchOnMountPolicy,
    skip: !activeCamId,
  });
  const [, setCams] = useQueryParam("cams", DelimitedNumericArrayParam);
  const setIndex = useCallback(
    (index: number) => {
      if (!groupCameras) return;
      setCams([groupCameras[index].id]);
    },
    [setCams, groupCameras]
  );
  const handleNext = useCallback(() => {
    setCams((current) => {
      if (!current || !groupCameras) return current;
      const currentIndex = groupCameras.findIndex(
        ({ id }) => id === current[0]
      );
      return [groupCameras[(currentIndex + 1) % groupCameras.length].id];
    });
  }, [setCams, groupCameras]);
  const handleBack = useCallback(() => {
    setCams((current) => {
      if (!current || !groupCameras) return current;
      const currentIndex = groupCameras.findIndex(
        ({ id }) => id === current[0]
      );
      return [
        groupCameras[
          (currentIndex + groupCameras.length - 1) % groupCameras.length
        ].id,
      ];
    });
  }, [setCams, groupCameras]);

  // Ensure only a single cam is active at a time for this view
  useEffect(() => {
    // Multi cam is not supported yet.
    if (activeCamIds.length > 1) {
      setCams([activeCamIds[0]]);
    }
  }, [activeCamIds, setCams]);

  // When selecting a group that doesn't contain the currently active camera,
  // select the first camera in the newly selected group
  useEffect(() => {
    if (!groupCameras?.length || activeCamId === undefined) return;
    if (!groupCameras.some((cam) => cam.id === activeCamId)) {
      setCams([groupCameras[0].id]);
    }
  }, [activeCamId, groupCameras, setCams]);

  const activeCam = data?.cameras[0];
  // Memo so LivePlayer can rely on referential equality across renders
  const [activeCamArray, activeCamIdArray] = useMemo(() => {
    if (!activeCam) return [[], []];

    return [[activeCam], [activeCam.id]];
  }, [activeCam]);

  return {
    activeCam,
    activeCamId,
    activeCamArray,
    activeCamIdArray,
    error,
    handleNext,
    handleBack,
    setIndex,
  };
}

export function LiveMobile() {
  const activeCamIds = useActiveCamIds();
  const { fitsSmallTablet } = useBreakpoints();
  const { classes: scrollbarClasses } = useCustomScrollbarStyles();
  const mainContainerRef = useRef<HTMLDivElement>(null);
  if (activeCamIds.length === 0) {
    // Live home - recent cams
    return (
      <div className="flex flex-col h-full relative">
        <div
          className={clsx(
            "w-full overflow-y-auto overflow-x-hidden flex flex-col items-center mt-8 md:mt-16",
            scrollbarClasses.scrollbarContainer
          )}
          ref={mainContainerRef}
        >
          <WatchLiveHeader className="text-5xl md:text-7xl" />
          <Typography className="max-w-xs sm:max-w-sm md:max-w-xl text-center mt-1 px-10 sm:px-0 md:mt-6 mb-2 md:mb-12 text-sm leading-[18.75px] md:text-base">
            <strong>Watch any of your cameras live.</strong> Choose a recent
            camera or select a camera from the list or map in the
            {fitsSmallTablet ? " sidebar on the right" : " tray below"}.
          </Typography>
          <RecentCameras />
        </div>
        <WiredMobileCameraDrawer />
      </div>
    );
  }
  return <LiveCam />;
}

function LiveCam() {
  const prefixOrgSlug = usePrefixOrgSlug();
  const activeGroupCameras = useActiveGroupCameras();
  const {
    activeCam,
    activeCamId,
    activeCamArray,
    activeCamIdArray,
    error: activeCamError,
    handleNext,
    handleBack,
    setIndex,
  } = useActiveCam(activeGroupCameras);
  const activeStep = activeGroupCameras?.findIndex(
    ({ id }) => activeCamId === id
  );

  const swiperRef = useRef<SwiperType | null>(null);

  // When the active cam changes through a URL param update (which happens when
  // the user selects a different camera from the cam tray), update the swiper's
  // active index.
  useEffect(() => {
    if (!activeGroupCameras || !swiperRef.current) return;
    const index = activeGroupCameras.findIndex(
      (candidate) => candidate.id === activeCamId
    );
    if (
      index !== undefined &&
      index !== -1 &&
      index !== swiperRef.current?.activeIndex
    ) {
      swiperRef.current.slideTo(index, 0);
    }
  }, [activeCamId, activeGroupCameras]);

  if (activeCamError) {
    return (
      <ErrorMessage
        title="Sorry about that!"
        description="We were unable to load the camera"
      />
    );
  }

  return (
    <PlayerIdsProvider cameraIds={activeCamIdArray}>
      <PlayerMachineProvider>
        <MainPlayerMobileHeader additionalIndicators={<LiveIndicator />} />
        <section>
          <div className="flex grow flex-col">
            {activeGroupCameras ? (
              <Swiper
                className="bg-black"
                lazy={{
                  loadPrevNext: true,
                }}
                autoHeight
                modules={[Lazy]}
                onSwiper={(swiper) => (swiperRef.current = swiper)}
                initialSlide={activeStep}
                onSlideChangeTransitionEnd={(swiper) => {
                  setIndex(swiper.activeIndex);
                }}
              >
                {activeGroupCameras.map((camera) => (
                  <SwiperSlide key={camera.id} className="bg-black">
                    {/* <span className="absolute bottom-0 left-0 z-10 text-white text-xs">
                    {i + 1}/{activeGroupCameras.length} - {camera.name}
                  </span> */}
                    {camera.id === activeCamId &&
                    activeCam &&
                    activeCamArray ? (
                      <FullScreen className="w-full h-full">
                        <LivePlayer
                          cameras={activeCamArray}
                          overrideMachineProvider={false}
                        />
                      </FullScreen>
                    ) : (
                      <img
                        className="swiper-lazy blur-xl"
                        data-src={camera.still}
                        alt={camera.name}
                      />
                    )}
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              <Skeleton
                variant="rectangular"
                className="w-full h-auto aspect-video"
              />
            )}

            <HybridStepper
              activeStep={activeStep ?? 0}
              stepCount={activeGroupCameras?.length ?? 0}
              handleBack={() => {
                handleBack();
                swiperRef.current?.slidePrev();
              }}
              handleNext={() => {
                handleNext();
                swiperRef.current?.slideNext();
              }}
            >
              <div className="font-bold text-xl text-center truncate">
                {activeGroupCameras?.find((c) => c.id === activeCamId)
                  ?.name ?? <>&nbsp;</>}
              </div>
            </HybridStepper>

            <Divider className="mx-4" />

            <div className="py-3 px-4 flex flex-col gap-5">
              <div className="flex items-center">
                {activeCam ? (
                  <WallClock timezone={activeCam.location.timezone} />
                ) : (
                  <div>&nbsp;</div>
                )}
                <div className="grow" />
                <QueryParamLink
                  to={prefixOrgSlug(`/search`)}
                  className="flex gap-1 items-center bg-primary bg-opacity-[0.14] text-primary no-underline py-2 pr-3 pl-2 rounded text-md font-normal whitespace-nowrap border-[#007CE414]"
                >
                  <Search />
                  Camera History
                </QueryParamLink>
              </div>
            </div>
            {/* shadow */}
            <div className="w-full h-[19px] -mb-2 bg-gradient-to-t from-white to-black opacity-10" />
          </div>

          {activeCam?.aiEnabled ? (
            <Suspense fallback={null}>
              <Insights
                cameraId={activeCam.id}
                timezone={activeCam.location.timezone}
              />
            </Suspense>
          ) : (
            <img
              className="w-full px-20 py-10"
              src="/paw-placeholder.svg"
              alt=""
            />
          )}
          <div className="h-20" />
        </section>
        <WiredMobileCameraDrawer />
      </PlayerMachineProvider>
    </PlayerIdsProvider>
  );
}

function HybridStepper({
  activeStep,
  stepCount,
  handleBack,
  handleNext,
  children,
}: PropsWithChildren<{
  activeStep: number;
  stepCount: number;
  handleNext: () => void;
  handleBack: () => void;
}>) {
  return (
    <div className="flex items-center min-w-0 py-4">
      <IconButton
        color="primary"
        size="small"
        onClick={handleBack}
        disabled={activeStep === 0}
      >
        <KeyboardArrowLeft fontSize="large" />
      </IconButton>
      <div className="grow min-w-0">
        {children}
        {children && <div className="h-1" />}
        {stepCount <= 16 ? (
          <div className="flex justify-center gap-1.5">
            {Array.from({ length: stepCount }).map((_, i) => (
              <div
                key={i}
                className={clsx("w-1.5 h-1.5 bg-primary rounded-full", {
                  "bg-opacity-20": i !== activeStep,
                })}
              />
            ))}
          </div>
        ) : (
          <div className="text-primary text-center text-xs">
            <span className="font-bold">{activeStep + 1}</span>{" "}
            <span className="text-[#B3D8F7]">of</span> {stepCount}
          </div>
        )}
      </div>
      <IconButton
        color="primary"
        size="small"
        onClick={handleNext}
        disabled={activeStep === stepCount - 1}
      >
        <KeyboardArrowRight fontSize="large" />
      </IconButton>
    </div>
  );
}

gql`
  query liveGroup($id: Int!) {
    group: tag(id: $id) {
      id
      name
      cameras(lifecycleStates: [enabled]) {
        id
        name
        still
        lifecycleState
        status
      }
      locations {
        id
        cameras(lifecycleStates: [enabled]) {
          id
          name
          still
          lifecycleState
          status
        }
      }
    }
  }
`;

gql`
  query liveAllCams {
    cameras {
      id
      name
      still
      lifecycleState
      status
    }
  }
`;
