import { GridColDef, useGridApiRef } from "@mui/x-data-grid";
import { addSeconds, subSeconds } from "date-fns/fp";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";

import { useBreakpoints } from "@/util/useBreakpoints";

import { DataGrid } from "@/components/DataGrid/DataGrid";

import {
  IntegrationDevice,
  IntegrationEventsV2Query,
} from "@/generated-models";

import { useGetResolvedEventData } from "../../Form/Event/hooks";
import { getEventDateTime } from "../../Form/Event/utils";
import { useCurrentDeviceId } from "../../hooks/deviceHooks";
import {
  useCurrentIntegrationEventColumns,
  useCurrentIntegrationEvents,
} from "../../hooks/eventHooks";
import IntegrationEventCustomColumnPopover from "./IntegrationEventCustomColumnPopover";
import { IntegrationsEventPopover } from "./IntegrationEventPopover";

export type IntegrationEventData = IntegrationEventsV2Query["integrationEventsV2"][number];

interface IntegrationEventDatagridProps {
  onRowClick?: (
    device: IntegrationDevice,
    redirectData: { pathname: string; search: string }
  ) => void;
}

export function IntegrationEventDatagrid({
  onRowClick,
}: IntegrationEventDatagridProps) {
  const deviceId = useCurrentDeviceId();
  const [params] = useSearchParams();
  const { events, loading } = useCurrentIntegrationEvents();
  const { fitsDesktop } = useBreakpoints();
  const getResolvedEventData = useGetResolvedEventData();
  const [datetimeInputParam] = useQueryParam("datetime", StringParam);

  const attributeColumns = useCurrentIntegrationEventColumns();
  const apiRef = useGridApiRef();
  const currentSelectedId = params.get("id");

  const resolvedEvents = useMemo(() => {
    return events.filter((e) => {
      if (!!deviceId && e.integrationDeviceId !== deviceId) {
        return false;
      }
      return true;
    });
  }, [deviceId, events]);

  return (
    <>
      <DataGrid
        apiRef={apiRef}
        getRowId={(d: { id: string }) => d.id}
        rowHeight={fitsDesktop ? 48 : 110}
        rows={resolvedEvents}
        loading={loading}
        columnHeaderHeight={40}
        classes={{
          cell: "outline-none",
        }}
        initialState={{
          pagination: { paginationModel: { pageSize: 100 } },
          sorting: { sortModel: [{ field: "timestamp", sort: "desc" }] },
        }}
        getRowClassName={(params) => {
          if (params.id === currentSelectedId) {
            return "bg-primary text-white";
          }
          return "cursor-pointer";
        }}
        onRowClick={({ row }) => {
          const eventTimestamp = new Date(row.timestamp);
          const { start, end } = getClipDateRange(eventTimestamp);

          if (onRowClick && row.id !== currentSelectedId) {
            onRowClick(row, {
              pathname: `./device/${row.integrationDeviceId}`,
              search: `vod=${start.toISOString()}|${end.toISOString()}&id=${
                row.id
              }&hideMultiCamRemove=1&hideMultiCamZone=1${
                datetimeInputParam ? `&datetime=${datetimeInputParam}` : ""
              }`,
            });
          }
        }}
        columns={
          [
            {
              field: "timestamp",
              headerName: "Time",
              width: 165,
              renderCell: ({ row, value }) => {
                const { timezone } = getResolvedEventData(row);
                const { date, time } = getEventDateTime(value, timezone);

                return (
                  <span>
                    {date} <strong>{time}</strong>
                  </span>
                );
              },
            },
            {
              field: "integrationDeviceId",
              headerName: "Device",
              minWidth: 150,
              flex: 2,
              renderCell: ({ row }) => {
                const { device } = getResolvedEventData(row);

                return device.name || "Unknown";
              },
            },
            {
              field: "integrationEventTypeId",
              headerName: "Event Type",
              minWidth: 150,
              flex: 2,
              renderCell: ({ row }) => {
                const { eventType } = getResolvedEventData(row);

                return eventType.name || "Unknown";
              },
            },
            ...attributeColumns,
            {
              field: "internal_customize",
              sortable: false,
              renderCell: () => <></>,
              renderHeader: () => {
                return <IntegrationEventCustomColumnPopover />;
              },
              width: 20,
            },
          ] as GridColDef<IntegrationEventData>[]
        }
        disableMobileHeader={false}
      />
      {fitsDesktop && !deviceId && <IntegrationsEventPopover apiRef={apiRef} />}
    </>
  );
}

export function getClipDateRange(
  eventTimestamp: Date
): { start: Date; end: Date } {
  const start = subSeconds(60, eventTimestamp);
  const end = addSeconds(60, eventTimestamp);
  return { start, end };
}
