import { CheckCircleOutline } from "@mui/icons-material";
import clsx from "clsx";
import React from "react";
import Highlighter from "react-highlight-words";
import { useUpdateEffect } from "react-use";

import { usePagination } from "@/util/usePagination";

import { StatefulDrawerCam } from "@/components/CamDrawer/CamDrawerBase";
import { useCamDrawerCams } from "@/components/CamDrawer/useCamDrawerCams";
import { LazyBackground } from "@/components/shared/LazyImage";

import { CameraStatus } from "@/generated-models";
import { useCustomScrollbarStyles } from "@/layout/theme";

export interface CamWrapperProps {
  cam: StatefulDrawerCam;
  children: React.ReactNode;
}
type CamWrapperType = React.ComponentType<CamWrapperProps>;

function NoopCamWrapper({ children }: CamWrapperProps) {
  return <>{children}</>;
}

export interface CamStatusProps {
  cam: StatefulDrawerCam;
  camStatus: CameraStatus;
}
type CamStatusType = React.ComponentType<CamStatusProps>;

export function DefaultCamStatus({ camStatus }: CamStatusProps) {
  return (
    <div className="flex items-center capitalize text-xs">
      <span
        className="w-2 h-2 mx-1 rounded-[50%]"
        style={{
          backgroundColor: camStatus === "online" ? "#67c21b" : "#b0b0b0",
        }}
      />
      {camStatus}
    </div>
  );
}

/**
 * Use the CamWrapper to handle clicking/dragging a cam. It's a little counterintuitive, but
 * this component doesn't do anything by default when clicking a cam, so selection needs
 * to be handled by the parent component.
 */
export function CamDrawerList({
  CamWrapper = NoopCamWrapper,
  CamStatus = DefaultCamStatus,
  cameras,
}: {
  CamWrapper?: CamWrapperType;
  CamStatus?: CamStatusType;
  cameras: StatefulDrawerCam[];
}) {
  const scrollbarClasses = useCustomScrollbarStyles();
  const { cams, splitSearchInput } = useCamDrawerCams(cameras);
  const { pageCount, reset, visibilitySensor } = usePagination(1);
  useUpdateEffect(() => reset(), [cams.map((c) => c.id).join("")]);

  return (
    <>
      <div
        className={clsx(
          // `relative` is required here due to a funky firefox bug (feature?)
          // When clicking a label, the browser will try to focus the underlying
          // input element. If we don't make the label `relative` but the checkbox
          // is `absolute`, then the element will be positioned at the top of the
          // parent layer. Now if we click a cam, firefox will scroll the parent
          // container, which it shouldn't, because the parent is set to `overflow: hidden`.
          // Scrolling that parent will break the UI.
          // https://stackoverflow.com/a/17974351
          "flex flex-col gap-3 overflow-auto p-2.5 relative",
          scrollbarClasses.classes.scrollbarContainer
        )}
      >
        {cams?.slice(0, pageCount * 20).map((cam) => (
          <CamWrapper key={cam.id} cam={cam}>
            <div
              className={clsx("flex", {
                "cursor-pointer": !cam.disabled,
                "cursor-not-allowed": cam.disabled,
              })}
            >
              <div className="text-xs mb-1">
                <div
                  className={clsx("flex-center relative w-[103px] h-[58px]", {
                    "opacity-25": cam.disabled,
                  })}
                >
                  <LazyBackground
                    className={clsx(
                      "h-full absolute w-full top-0 left-0 bg-cover bg-center",
                      {
                        "opacity-20": cam.selected,
                      }
                    )}
                    cameraId={cam.id}
                  />
                  <div
                    className={clsx(
                      "text-white w-[26px] h-[26px] relative bg-[#67C21B] rounded-[50%]",
                      {
                        hidden: !cam.selected,
                      }
                    )}
                  >
                    <CheckCircleOutline
                      className="h-auto m-[-3px]"
                      style={{
                        width: "calc(100% + 6px)",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                className={clsx("ml-2.5 text-sm", {
                  "opacity-25": cam.disabled,
                })}
              >
                <Highlighter
                  highlightClassName="text-primary bg-transparent"
                  searchWords={splitSearchInput}
                  autoEscape={true}
                  textToHighlight={cam.name}
                />
                <div className="my-[3px] opacity-70">
                  <Highlighter
                    highlightClassName="text-primary bg-transparent"
                    searchWords={splitSearchInput}
                    autoEscape={true}
                    textToHighlight={cam.location.name}
                  />
                </div>
              </div>
            </div>
            <CamStatus cam={cam} camStatus={cam.status} />
          </CamWrapper>
        ))}
        {visibilitySensor}
      </div>
    </>
  );
}
