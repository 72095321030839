import CircleIcon from "@mui/icons-material/Circle";
import { Button, IconButton, Typography } from "@mui/material";
import clsx from "clsx";
import { ReactNode, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { SpotConnect } from "@/icons/SpotConnect";
import { SpotConnectIcon } from "@/icons/SpotConnectIcon";

import { useBreakpoints } from "@/util/useBreakpoints";

import IntegrationsCard from "@/pages/IntegrationsOld/Core/IntegrationsCard";
import { useRangeParam, useVodParam } from "@/pages/Search/searchHooks";

import { FullScreen } from "@/components/FullScreen";
import {
  AddToCaseDialog,
  CaseContentType,
} from "@/components/Player/AddToCaseDialog";
import {
  LivePlayer,
  VodPlayer,
} from "@/components/Player/MainPlayer/MainPlayer";
import { PlayerIdsProvider } from "@/components/Player/PlayerBase";
import { CaseSmallIcon } from "@/components/Player/PlayerIcons";
import { PlayerMachineProvider } from "@/components/Player/playerMachine";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { StillImage } from "@/components/Still/StillImage";
import { WallClock } from "@/components/View/View";
import { WiredCanvasTimeline } from "@/components/View/WiredCanvasTimeline";

import { useAddClipsToCaseMutation } from "@/generated-models";
import { usePermissions } from "@/hooks/usePermissions";

import {
  useActiveCameras,
  useIntegrationDeviceById,
} from "../../hooks/deviceHooks";
import { useCurrentIntegrationEvents } from "../../hooks/eventHooks";

interface DeviceOverviewPlayerHeaderProps {
  title: string;
}

function useCanvasEventGroups() {
  const { events } = useCurrentIntegrationEvents();

  return useMemo(() => {
    return [
      {
        label: "Event",
        config: {
          color: (opacity: number = 1) => `rgba(152, 0, 223, ${opacity})`,
          icon: <SpotConnect className="text-[14px]" />,
        },
        groups: [
          {
            color: 0x979797,
            events: events.map((e) => {
              const ts = new Date(e.timestamp).getTime();
              return {
                start: ts,
                end: ts + 60000,
              };
            }),
          },
        ],
      },
    ];
  }, [events]);
}

function NoCameraConfiguredTile() {
  return (
    <div className="min-h-[200px] md:min-h-[400px] text-white">
      <StillImage
        className="aspect-video h-full w-full object-cover"
        src="/no-still.svg"
        alt="No cameras configured"
      />
    </div>
  );
}

function IntegrationsDetailsDeviceLivePlayer() {
  const { activeCams, loading } = useActiveCameras();

  if (loading) {
    return <></>;
  }

  if (activeCams.length === 0) {
    return <NoCameraConfiguredTile />;
  }

  return <LivePlayer cameras={activeCams} overrideMachineProvider={false} />;
}

function IntegrationsDetailsDeviceVodPlayer() {
  const { activeCams, loading } = useActiveCameras();
  const { vodStart, vodEnd } = useVodParam();

  if (loading) {
    return <></>;
  }

  if (activeCams.length === 0) {
    return <NoCameraConfiguredTile />;
  }

  return (
    <VodPlayer
      cameras={activeCams}
      startTime={vodStart.toISOString()}
      endTime={vodEnd.toISOString()}
      overrideMachineProvider={false}
    />
  );
}

function LiveIndicator() {
  return (
    <div className="bg-[#23282f] rounded px-[18px] py-[10px] text-[#18D710] flex items-center gap-2">
      <CircleIcon className="text-[10px]" />
      <Typography className="font-bold text-sm leading-[16.41px]">
        LIVE
      </Typography>
    </div>
  );
}

// TODO: Enable share and case buttons when ready.
function DeviceOverviewPlayerHeader({
  title,
}: DeviceOverviewPlayerHeaderProps) {
  return (
    <div className="flex items-center justify-between pb-4">
      <Typography className="font-bold text-2xl leading-[28px] flex items-center gap-2">
        <SpotConnectIcon className="text-[28px]" />
        {title}
      </Typography>
      {/* <div className="flex items-center gap-4">
        <IconButton disableRipple className="p-0" color="primary">
          <ShareIcon />
        </IconButton>
        <IconButton disableRipple className="p-0" color="primary">
          <CasesIcon />
        </IconButton>
      </div> */}
    </div>
  );
}

function DeviceOverviewPlayerFooter() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { activeCams } = useActiveCameras();
  const { pushSnackbar } = useFeedback();
  const { activeCamIds } = useActiveCameras();
  const { vodStart, vodEnd } = useVodParam();
  const [dialogOpen, setDialogOpen] = useState(false);

  const [addClipsToCase] = useAddClipsToCaseMutation({
    onError: () =>
      pushSnackbar(
        "We were unable to save this clip. Please try again later.",
        FeedbackType.Error
      ),
  });

  const live = !searchParams.get("vod");
  return (
    <div className="bg-[#191919] rounded-b-2xl p-4">
      <div className="flex items-center justify-between">
        <WallClock
          dateFormat="PPPP"
          timezone={activeCams?.[0]?.location?.timezone}
        />

        {live && <LiveIndicator />}
        {!live && (
          <>
            <div className="flex items-center justify-between gap-3">
              <div className="flex gap-3">
                <IconButton
                  className="text-white"
                  onClick={() => {
                    setDialogOpen(true);
                  }}
                  aria-label="Add to case"
                  size="small"
                >
                  <CaseSmallIcon />
                </IconButton>
              </div>
              <Button
                variant="contained"
                color="primary"
                className="shadow-none font-bold"
                onClick={() => {
                  searchParams.delete("vod");
                  searchParams.delete("id");
                  searchParams.delete("hideMultiCamRemove");
                  searchParams.delete("hideMultiCamZone");
                  setSearchParams(searchParams);
                }}
              >
                Return to Live
              </Button>
            </div>
            <AddToCaseDialog
              type={CaseContentType.bulk_clips}
              open={dialogOpen}
              setOpen={setDialogOpen}
              onSave={async (caseId: number, redirectToCase: boolean) => {
                await addClipsToCase({
                  variables: {
                    caseId,
                    value: {
                      cameraIds: activeCamIds,
                      startTime: vodStart.toISOString(),
                      endTime: vodEnd.toISOString(),
                    },
                  },
                });
              }}
            />
          </>
        )}
      </div>
    </div>
  );
}

function IntegrationsVideoProviders({ children }: { children: ReactNode }) {
  const { activeCamIds } = useActiveCameras();

  return (
    <PlayerIdsProvider cameraIds={activeCamIds}>
      <PlayerMachineProvider>
        <FullScreen className="flex grow flex-col dark">{children}</FullScreen>
      </PlayerMachineProvider>
    </PlayerIdsProvider>
  );
}

function CanvasTimeline() {
  const { activeCams } = useActiveCameras();
  const { rangeStart, rangeEnd } = useRangeParam();
  const { vodStart, vodEnd } = useVodParam();
  const [showMissingFootage] = useState(
    localStorage.enableMissingFootage === "true"
  );

  const eventGroups = useCanvasEventGroups();

  return (
    <WiredCanvasTimeline
      eventGroups={eventGroups}
      className="rounded-lg"
      showMissingFootage={showMissingFootage}
      timezone={activeCams[0]?.location?.timezone}
      focusedCameraId={activeCams[0]?.id}
      activeWindow={
        vodStart && vodEnd
          ? { lower: vodStart.getTime(), upper: vodEnd.getTime() }
          : undefined
      }
      bounds={{
        lower: rangeStart.getTime(),
        upper: rangeEnd.getTime(),
      }}
      style={{ borderRadius: 8, boxShadow: "0 0 15px rgba(0, 0, 0, 0.15)" }}
    />
  );
}

export function IntegrationDetailsDeviceEventPlayer() {
  const { fitsDesktop } = useBreakpoints();
  const [searchParams] = useSearchParams();
  const hasPermission = usePermissions();
  const { data } = useIntegrationDeviceById();

  const title = data?.name || "";
  const vod = searchParams.get("vod");

  if (
    (vod && !hasPermission("video_vod_access")) ||
    (!vod && !hasPermission("video_live_access"))
  ) {
    return <div className="col-span-1 md:col-span-3" />;
  }

  return (
    <div className="col-span-1 md:col-span-3">
      <IntegrationsVideoProviders>
        <IntegrationsCard
          className={clsx("rounded-b-none rounded-t-none md:rounded-2xl pb-0", {
            "!p-0": !fitsDesktop,
          })}
          dense
        >
          {fitsDesktop && <DeviceOverviewPlayerHeader title={title} />}
          <div className="bg-black min-h-[200px] md:-mx-4">
            {vod ? (
              <IntegrationsDetailsDeviceVodPlayer />
            ) : (
              <IntegrationsDetailsDeviceLivePlayer />
            )}
          </div>
        </IntegrationsCard>
        <DeviceOverviewPlayerFooter />
      </IntegrationsVideoProviders>
      <div className="md:block hidden mt-2">
        <CanvasTimeline />
      </div>
    </div>
  );
}
