import LeftIcon from "@mui/icons-material/ChevronLeft";
import { IconButton } from "@mui/material";
import clsx from "clsx";
import { Link, useNavigate } from "react-router-dom";

import {
  QueryParamLink,
  QueryParamLinkProps,
} from "@/components/shared/QueryParamLink";

interface MobileBackButtonProps {
  to?: string;
  withQueryParams?: boolean;
  queryParamsProps?: QueryParamLinkProps;
  className?: string;
}

export function BackButton({
  to,
  className,
  withQueryParams = false,
  queryParamsProps,
}: MobileBackButtonProps) {
  const navigate = useNavigate();
  // The withQueryParams prop does nothing in this variant so far. If it's needed, we can update the logic.
  if (!to) {
    return (
      <IconButton
        size="small"
        className={clsx("p-0 text-primary", className)}
        onClick={() => navigate(-1)}
      >
        <LeftIcon fontSize="large" />
      </IconButton>
    );
  }

  return (
    <IconButton size="small" className={clsx("p-0 text-primary", className)}>
      {withQueryParams ? (
        <QueryParamLink to={to} {...queryParamsProps}>
          <LeftIcon fontSize="large" />
        </QueryParamLink>
      ) : (
        <Link to={to}>
          <LeftIcon fontSize="large" />
        </Link>
      )}
    </IconButton>
  );
}
