import { filterFalsy } from "@/util/filterFalsy";

enum TimeUnit {
  hour,
  minute,
  second,
}

const shortUnitSuffix: Record<TimeUnit, string> = {
  [TimeUnit.hour]: "h",
  [TimeUnit.minute]: "m",
  [TimeUnit.second]: "s",
};

const longUnitSuffix: Record<TimeUnit, string> = {
  [TimeUnit.hour]: "hour",
  [TimeUnit.minute]: "minute",
  [TimeUnit.second]: "second",
};

function formatShort(value: number, unit: TimeUnit) {
  return `${value}${shortUnitSuffix[unit]}`;
}

function formatLong(value: number, unit: TimeUnit) {
  const pluralSuffix = value === 1 ? "" : "s";
  return `${value} ${longUnitSuffix[unit]}${pluralSuffix}`;
}

/** Formats a duration in seconds into readable hours, minutes, seconds */
export function formatDurationSecs(
  durationSeconds: number,
  {
    delimiter = " ",
    hideZeroes = false,
    long = false,
    hideSeconds = false,
  } = {}
) {
  // Determine long or short format
  const formatUnit = long ? formatLong : formatShort;

  // Early exit zero duration
  if (durationSeconds === 0) return formatUnit(0, TimeUnit.second);

  // Calculate unit values
  const hours = Math.floor(durationSeconds / 3600);
  const minutes = Math.floor((durationSeconds % 3600) / 60);
  const seconds = durationSeconds % 60;

  // Format unit values and and join with delimiter
  return [
    hours > 0 && formatUnit(hours, TimeUnit.hour),
    ((!hideZeroes && hours > 0) || minutes > 0) &&
      formatUnit(minutes, TimeUnit.minute),
    (!hideZeroes || seconds > 0) &&
      !hideSeconds &&
      formatUnit(seconds, TimeUnit.second),
  ]
    .filter(filterFalsy)
    .join(delimiter);
}
