import { FormControl, MenuItem, Typography } from "@mui/material";
import { Field, useField, useFormikContext } from "formik";
import { Select } from "formik-mui";
import gql from "graphql-tag";

import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  AlertType,
  useIntegrationEventTypesQuery,
  useIntegrationsListQuery,
} from "@/generated-models";

import { AlertFormBlock } from "./AlertFormBlock";
import { required } from "./AlertFormBlocks";
import { alertFormBlockStyles } from "./alertStyles";

export function AlertIntegrationEvent({
  integrationType,
}: {
  integrationType: AlertType;
}) {
  const { setFieldValue } = useFormikContext();
  // Fetch integrations and filter by integration type
  const { data: integrationsData, error } = useIntegrationsListQuery(
    refetchOnMountPolicy
  );
  // NOTE: integrationType is an AlertType, so those values will need to match
  // with an IntegrationTypeKey for this to work.
  const integrations = integrationsData?.integrations.filter(
    (integration) => integration.typeKey === integrationType.toString()
  );

  // Fetch event types for the integration type
  const typeId = integrations?.[0]?.typeId;
  const { data: eventTypesData } = useIntegrationEventTypesQuery({
    variables: { input: { integrationTypeId: typeId ?? 0 } },
    skip: !typeId,
  });

  // Define field to prevent user skipping this step while in loading or error state
  useField({
    name: "integrationId",
    validate: required("Please provide an integration"),
  });

  if (error) {
    return (
      <ErrorMessage
        title="Failed to load integrations."
        description={error?.message}
        dense
      />
    );
  }

  if (!integrationsData) {
    return <Loading />;
  }

  if (!integrationsData.integrations.length) {
    return (
      <ErrorMessage
        title="No integrations found."
        description="To create an integration alert you must first create an integration."
        dense
      />
    );
  }

  return (
    <div>
      <Typography className="font-bold text-base leading-[18.75px]">
        Integration & Event Trigger
      </Typography>
      <Typography className="text-sm leading-[16.41px] mb-2">
        Choose the integration and type of event you would like to receive an
        alert for.
      </Typography>
      <AlertFormBlock>
        <FormControl className={alertFormBlockStyles.formItem}>
          <label className="text-xs text-text/70">Integration</label>
          <Field
            component={Select}
            name="integrationId"
            validate={required("Please provide an integration")}
            onChange={() => {
              // Reset integrationSourceIds when integrationId changes, otherwise
              // it could still contain values from the previous integration.
              setFieldValue("integrationSourceIds", []);
            }}
            formControl={{ variant: "standard" }}
            variant="outlined"
            size="small"
          >
            {integrations?.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Field>
        </FormControl>
        <FormControl className={alertFormBlockStyles.formItem}>
          <label className="text-xs text-text/70">Event Trigger</label>
          <Field
            component={Select}
            name="integrationEventTypeId"
            validate={required("Please provide an event trigger")}
            formControl={{ variant: "standard" }}
            variant="outlined"
            size="small"
          >
            {eventTypesData?.integrationEventTypes.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Field>
        </FormControl>
      </AlertFormBlock>
    </div>
  );
}

gql`
  query integrationsList {
    integrations {
      id
      typeId
      typeKey
      name
    }
  }
`;

gql`
  query integrationEventTypes($input: IntegrationEventTypesInput!) {
    integrationEventTypes(input: $input) {
      id
      name
    }
  }
`;
