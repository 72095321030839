// import { SearchOutlined } from "@mui/icons-material";
// import { Divider, IconButton } from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Divider, IconButton } from "@mui/material";
import { uniqBy } from "lodash/fp";
import { useMemo, useState } from "react";
import { ArrayParam, StringParam, useQueryParam } from "use-query-params";

import { useBreakpoints } from "@/util/useBreakpoints";

import { IntegrationsDetailsSummaryBar } from "@/pages/IntegrationsOld/IntegrationsDetails/IntegrationsDetailsSummaryBar";

// import { StringParam, useQueryParam } from "use-query-params";
// import { useBreakpoints } from "@/util/useBreakpoints";
import { FilterBar } from "@/components/Filter/FilterBar";
import { FilterBarControls } from "@/components/Filter/FilterBarControls";
import { MobileFilterActionBar } from "@/components/Filter/Mobile/MobileFilterActionBar";
import { MobileFilterModal } from "@/components/Filter/Mobile/MobileFilterModal";
import { SearchBox } from "@/components/SearchBox";

import { refetchOnMountPolicy } from "@/apolloClient";
import { useIntegrationDeviceTagsQuery } from "@/generated-models";

import { useCurrentIntegrationDevices } from "../../hooks/deviceHooks";
import { useCurrentIntegrationId } from "../../hooks/integrationHooks";

export function IntegrationDeviceTabSummaryBar({
  filterCount,
}: {
  filterCount: number;
}) {
  const { devices, count, loading } = useCurrentIntegrationDevices();
  const integrationId = useCurrentIntegrationId();

  const { fitsDesktop } = useBreakpoints();
  const [showSearch, setShowSearch] = useState(false);
  const [mobileFilter, setMobileFilter] = useState(false);
  const [tagsFilter] = useQueryParam("tags", ArrayParam);
  const [locationsFilter] = useQueryParam("locations", ArrayParam);

  const emptyFilters = !tagsFilter && !locationsFilter;

  const [searchInputParam, setSearchInputParam] = useQueryParam(
    "search",
    StringParam
  );

  const { data } = useIntegrationDeviceTagsQuery({
    variables: { integrationId },
    ...refetchOnMountPolicy,
  });

  const filters = useMemo(
    () => [
      {
        placeholder: "Locations",
        filterName: "locations",
        label: "Locations",
        name: "Locations",
        options: uniqBy(
          "value",
          devices?.flatMap((d) =>
            d.cameras.flatMap((c) => ({
              label: c.location.name,
              value: `${c.location.id}`,
            }))
          ) || []
        ),
      },
      {
        placeholder: "Tags",
        filterName: "tags",
        name: "Tags",
        label: "Tags",
        options: (data?.integrationDeviceTags || []).map((t) => ({
          label: t,
          value: t,
        })),
      },
    ],
    [devices, data]
  );

  return (
    <div className="flex flex-col justify-center">
      <IntegrationsDetailsSummaryBar
        loading={loading}
        count={count}
        label="Device"
      >
        {/* Hiding filters on integrations */}
        <div className="flex items-center gap-3">
          {fitsDesktop && (
            <FilterBarControls label="Filter:" filters={filters} />
          )}
          <Divider
            className="h-[25px] hidden md:block"
            orientation="vertical"
          />
          {emptyFilters && (
            <IconButton
              className="bg-[#DDEFFF] flex md:hidden"
              color="primary"
              onClick={() => {
                setMobileFilter(true);
              }}
            >
              <FilterListIcon />
            </IconButton>
          )}
          {showSearch ? (
            <SearchBox
              className="min-w-[250px] w-full py-1"
              input={searchInputParam || ""}
              setInput={setSearchInputParam}
            />
          ) : (
            <IconButton
              onClick={() => {
                setShowSearch(true);
              }}
              className="bg-[#DDEFFF]"
              color="primary"
            >
              <SearchOutlined />
            </IconButton>
          )}
        </div>
      </IntegrationsDetailsSummaryBar>
      <MobileFilterActionBar
        className="mt-2"
        dynamic
        categories={filters ?? []}
        onFilterClick={(e) => {
          setMobileFilter(true);
        }}
      />
      <MobileFilterModal
        count={filterCount || 0}
        open={mobileFilter}
        subject="Dashboards"
        onBack={() => {
          setMobileFilter(false);
        }}
        categories={filters}
      />
      {!loading && (
        <FilterBar
          className="md:block hidden rounded-lg mt-5"
          filters={filters}
        />
      )}
    </div>
  );
}
