import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { addMinutes, subDays } from "date-fns/fp";
import { useMemo, useState } from "react";
import { ArrayParam, useQueryParam } from "use-query-params";

import { useSearchRangeParams } from "@/pages/Search/searchHooks";

import { MobileDateTimeRangePopper } from "@/components/DateTimePicker/DateTimeRangePicker";
import { SearchBox } from "@/components/SearchBox";

import { MobileFilterCategoryProps } from "./MobileFilterModal";

export function MobileFilterDateTimeCategory({
  timezone,
}: {
  timezone: string;
}) {
  const [expanded, setExpanded] = useState(false);
  const { query, rangeStart, rangeEnd, setRangeParam } = useSearchRangeParams();

  const startValue = utcToZonedTime(rangeStart, timezone);
  const endValue = utcToZonedTime(rangeEnd, timezone);

  const timeRangePopperProps = {
    startValue,
    endValue,
    onChange: (start: Date, end: Date) => {
      setExpanded(false);
      setRangeParam({
        start: zonedTimeToUtc(start, timezone),
        end: zonedTimeToUtc(end, timezone),
      });
    },
    close: () => {
      setExpanded(false);
    },
    reset: () => {
      setExpanded(false);
      setRangeParam(null);
    },
    minDate: utcToZonedTime(subDays(90, new Date()), timezone),
    maxDate: utcToZonedTime(addMinutes(60, new Date()), timezone),
  };

  return (
    <Accordion
      elevation={0}
      disableGutters
      expanded={expanded}
      onChange={(_, expanded) => {
        setExpanded(expanded);
      }}
      classes={{
        expanded: "min-h-auto",
        root: "px-0",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        classes={{
          expanded: "border-t border-solid border-[#e5e7eb]",
          content: "leading-6 font-bold my-5 !border-0",
          expandIconWrapper: "text-medium text-lg leading-6 !border-0",
        }}
      >
        <div>Dates & Times{!!query ? " (Selected)" : ""}</div>
      </AccordionSummary>
      <AccordionDetails>
        <MobileDateTimeRangePopper {...timeRangePopperProps} />
      </AccordionDetails>
    </Accordion>
  );
}

export function MobileFilterCategory({
  name,
  filterName,
  options,
}: MobileFilterCategoryProps) {
  const [searchInput, setSearchInput] = useState("");
  const [filterVal, setFilterVal] = useQueryParam(filterName, ArrayParam);
  const filteredParsed = filterVal ?? [];
  const filteredOptions = useMemo(() => {
    if (searchInput) {
      const normalizedSearchInput = searchInput.toLowerCase().trim();
      return options.filter(
        ({ label, value }) =>
          label.toLowerCase().includes(normalizedSearchInput) ||
          `${value}`.toLowerCase().includes(normalizedSearchInput)
      );
    }
    return options;
  }, [options, searchInput]);
  return (
    <Accordion
      elevation={0}
      disableGutters
      classes={{
        expanded: "min-h-auto",
        root: "px-0",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        classes={{
          expanded: "border-t border-solid border-[#e5e7eb]",
          content: "leading-6 font-bold my-5 !border-0",
          expandIconWrapper: "text-medium text-lg leading-6 !border-0",
        }}
      >
        <div>
          {name}
          {filteredParsed.length > 0 && (
            <span className="text-sm pl-1">
              ({filteredParsed.length} Selected)
            </span>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <SearchBox
          input={searchInput}
          setInput={setSearchInput}
          placeholder="Search"
          className="w-full pr-4 mb-2"
        />
        <FormGroup className="divide-y">
          {filteredOptions.map(({ label, value }) => (
            <FormControlLabel
              key={`${filterName}-option-${value}`}
              className="py-[6px]"
              control={<Checkbox className="mr-1" />}
              label={label}
              checked={filteredParsed.includes(`${value}`)}
              onChange={(event, checked) => {
                if (checked) {
                  setFilterVal([...filteredParsed, `${value}`]);
                } else {
                  const filters = [...filteredParsed];
                  filters.splice(filters.indexOf(`${value}`), 1);
                  setFilterVal(filters);
                }
              }}
            />
          ))}
        </FormGroup>
      </AccordionDetails>
    </Accordion>
  );
}
