export function getDevice() {
  const UA = navigator.userAgent;
  if (/\b(iPhone|iPod)\b/i.test(UA)) {
    return "iPhone";
  } else if (/Android/i.test(UA)) {
    return "Android";
  } else {
    return "Others";
  }
}

export function isMobileUserAgent() {
  const userAgent = navigator.userAgent;
  return /Android/.test(userAgent) || /\b(iPhone|iPod|iPad)\b/.test(userAgent);
}
