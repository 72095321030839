import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";
import clsx from "clsx";
import { useField } from "formik";
import { useState } from "react";

import { useBreakpoints } from "@/util/useBreakpoints";

import { TimeRangeSelector } from "@/components/TimeRange/TimeRangeSelector";

import { IntelligenceDashboardType } from "@/generated-models";

import { SelfCheckboxSet } from "..";
import {
  EntityCountSelect,
  ThresholdSecondsSelect,
} from "../../IntelligenceDashboardForm";
import { ComparativeSettingsFieldSummary } from "./ComparativeSettingsField";
import { useComparativeSettings, useReferenceTypeInfo } from "./utils";

const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

interface ComparativeCustomSettingsFormProps {
  enabled?: boolean;
  onEnableToggle: (value: string | null) => void;
}

const cardClass =
  "rounded-lg shadow-[0_6px_14px_rgba(0,42,82,0.1)] border-0 w-full sm:w-[480px] justify-start p-4 bg-white text-start";

export function ComparativeCustomSettingsForm({
  enabled,
  onEnableToggle,
}: ComparativeCustomSettingsFormProps) {
  const [editing, setEditing] = useState(false);
  const { fitsDesktop } = useBreakpoints();
  const info = useReferenceTypeInfo();
  const [, { value: timeRange }, { setValue: setTimeRange }] = useField<
    [number, number]
  >({
    name: "timeRange",
  });

  const { data } = useComparativeSettings();

  if (!enabled) {
    return (
      <button
        type="button"
        className={clsx(cardClass, "pl-[10px]")}
        onClick={() => {
          onEnableToggle("<<custom>>");
          setEditing(true);
        }}
      >
        <div className="flex items-center gap-1">
          <RadioButtonUnchecked color="primary" />
          <Typography className="text-primary text-lg leading-[21px]">
            Custom Settings
          </Typography>
        </div>
      </button>
    );
  }

  return (
    <div
      className={clsx(cardClass, "inline-block pl-2", {
        "!bg-[#F6FBFF]": enabled || editing,
      })}
    >
      {!editing && (
        <ComparativeSettingsFieldSummary
          selected
          label="Custom Settings"
          dashboard={data}
        >
          <button
            className="text-primary bg-transparent text-sm leading-4"
            onClick={() => {
              setEditing(true);
            }}
          >
            Edit
          </button>
        </ComparativeSettingsFieldSummary>
      )}
      {editing && (
        <div className="flex flex-col gap-4 pl-2">
          <div className="flex items-center justify-between -ml-2">
            <div className="flex items-center gap-1">
              <RadioButtonChecked color="primary" />
              <Typography
                className={clsx("text-primary text-lg leading-[21px]", {
                  "font-bold": enabled,
                })}
              >
                Custom Settings
              </Typography>
            </div>
            <button
              type="button"
              className={clsx("bg-transparent opacity-50 text-text", {
                hidden: !editing,
              })}
              onClick={() => {
                onEnableToggle(null);
                setEditing(false);
              }}
            >
              <CloseIcon className="w-5 h-5" />
            </button>
          </div>
          {info?.type === IntelligenceDashboardType.Presence && (
            <EntityCountSelect />
          )}
          {info?.type === IntelligenceDashboardType.Idle && (
            <ThresholdSecondsSelect />
          )}
          <SelfCheckboxSet
            labelClassName="text-sm leading-4"
            name="daysOfWeek"
            label="Days Tracked"
            options={weekDays.map((day, i) => ({
              value: String(i),
              label: day,
            }))}
          />
          <TimeRangeSelector
            btnClassName={clsx({ "p-0": !fitsDesktop })}
            labelClassName={clsx({
              "pt-2": !fitsDesktop,
            })}
            initialValue={timeRange}
            onChange={(value) => setTimeRange(value)}
          />
        </div>
      )}
    </div>
  );
}
