import { Divider, Switch, Typography } from "@mui/material";
import gql from "graphql-tag";
import React from "react";

import { useBreakpoints } from "@/util/useBreakpoints";
import { useDocumentTitle } from "@/util/useDocumentTitle";

import { AlertRow } from "@/pages/Alerts/AlertList";

import { useMe } from "@/components/Auth";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  useGetUserAlertsQuery,
  useUpdateAlertUserMutation,
} from "@/generated-models";
import { MobileHeader } from "@/layout/MobileHeader";

export function NotificationSettings() {
  const { fitsDesktop } = useBreakpoints();
  useDocumentTitle("Notification Settings");

  return (
    <div>
      <MobileHeader label="Mobile Notifications" />

      {fitsDesktop && (
        <div className="flex justify-between items-center flex-wrap gap-y-1 gap-x-8 p-4 bg-white ">
          <Typography variant="h1">Mobile Notifications</Typography>
          <div>Receive mobile push notifications for your Alerts</div>
        </div>
      )}
      <div className="flex flex-col p-4 shadow-divider">
        <UserAlertsList />
      </div>
    </div>
  );
}

function UserAlertsList() {
  const me = useMe();
  const { pushSnackbar } = useFeedback();
  const { data, error } = useGetUserAlertsQuery(refetchOnMountPolicy);
  const [updateUser] = useUpdateAlertUserMutation({
    onError: () =>
      pushSnackbar(
        "Updating notification setting failed...",
        FeedbackType.Error
      ),
  });

  if (error) {
    return (
      <ErrorMessage
        title="Error"
        description="Something went wrong, please try again."
      />
    );
  }
  if (!data?.alerts) return <Loading className="my-16" />;

  return (
    <>
      {data.alerts.map((alert, index) => {
        const user = alert.users.find((u) => u.userId === me?.id);
        if (!user) return null;
        const { receivePush } = user;

        return (
          <React.Fragment key={alert.id}>
            <div className="flex">
              <div className="flex items-center w-16">
                <Switch
                  checked={receivePush}
                  onChange={() => {
                    const updates = { receivePush: !receivePush };
                    updateUser({
                      variables: { id: user.id, updates },
                      optimisticResponse: {
                        __typename: "Mutation",
                        updateAlertUser: {
                          __typename: "AlertUser",
                          id: user.id,
                          ...updates,
                        },
                      },
                    });
                  }}
                  color="primary"
                  size="small"
                />
                {receivePush ? (
                  <span className="text-primary text-xs font-bold">ON</span>
                ) : (
                  <span className="text-[#a0a0a0] text-xs">OFF</span>
                )}
              </div>
              <div className="ml-6">
                <AlertRow alert={alert} />
              </div>
            </div>
            {data.alerts.length - 1 > index && <Divider className="my-4" />}
          </React.Fragment>
        );
      })}
    </>
  );
}

gql`
  query getUserAlerts {
    alerts {
      id
      name
      type
      source
      cameras {
        id
      }
      integrationSources {
        id
      }
      startTime
      endTime
      daysOfWeek
      users {
        id
        userId
        receivePush
      }
      customText
    }
  }
`;

gql`
  mutation updateAlertUser($id: Int!, $updates: AlertUserUpdateInput!) {
    updateAlertUser(id: $id, updates: $updates) {
      id
      receivePush
    }
  }
`;
