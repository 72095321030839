import AddIcon from "@mui/icons-material/Add";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import UndoIcon from "@mui/icons-material/Undo";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Modal,
  Paper,
  Popover,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import { formatDistance } from "date-fns";
import gql from "graphql-tag";
import isCidr from "is-cidr";
import lodash from "lodash";
import isIpPrivate from "private-ip";
import React, { useState } from "react";
import { makeStyles, withStyles } from "tss-react/mui";

import { useLocalStorage } from "@/util/useLocalStorage";

import { Loading } from "@/components/Loading";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";

import {
  DhcpUpdate,
  useApplianceInterfacesQuery,
  useUpdateApplianceNetworkConfigurationMutation,
} from "@/generated-models";

interface InterfacesModalProps {
  locationId: number;
  opened: boolean;
  close: () => void;
}

const BACKG_GREY = "#f5f5f5";

const StyledTable = withStyles(Table, () => ({
  root: {
    borderCollapse: "separate",
    borderSpacing: "0 2px",
    "& td": {
      transition: "background-color,border-color 0.1s",
    },
  },
}));

const StyledCell = withStyles(TableCell, () => ({
  root: {
    backgroundColor: BACKG_GREY,
    border: `solid 1px ${BACKG_GREY}`,
    padding: "0 12px 0 0",
    "&:not(:first-child)": {
      borderLeft: 0,
    },
    "&:not(:last-child)": {
      borderRight: 0,
    },
    "&:first-child": {
      borderRadius: "4px 0 0 4px",
    },
    "&:last-child": {
      borderRadius: "0 4px 4px 0",
    },
  },
  head: {
    padding: "2px 0",
    borderColor: "#e8e8e8",
    backgroundColor: "initial",
  },
}));

const useStyles = makeStyles()((theme) => ({
  paper: {
    outline: "none",
    padding: theme.spacing(2, 3),
    width: "clamp(300px, 80%, 1500px)",
    borderRadius: 5,
    zIndex: 1,
  },
  leftHeader: {
    fontSize: 25,
    fontWeight: "bold",
    flexGrow: 1,
  },
  buttonsContainer: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    right: 40,
  },
  divider: { height: 8, margin: "0 4px" },
  tableRow: {
    height: 50,
  },
  couldNotFetch: {
    width: "100%",
    textAlign: "center",
    margin: 10,
    fontFamily: "Roboto",
  },
}));

interface InterfaceUpdate {
  appliance: string;
  interfaceName: string;
  cidr: string;
  isDelete: boolean;
}

interface DHCPUpdate {
  appliance: string;
  interfaceName: string;
  dhcpEnabled: boolean;
}

//......................................................................
//.................OOOOOO.........................CCC...................
//.NNNN....NNN....OOOOOOOOO..OTTTTTTTTTT.III....CCCCCCCC...EEEEEEEEEEE..
//.NNNNN...NNN...OOOOOOOOOO..OTTTTTTTTTT.III...CCCCCCCCCC..EEEEEEEEEEE..
//.NNNNN...NNN..OOOOOOOOOOOO.OTTTTTTTTTT.III..CCCCCCCCCCC..EEEEEEEEEEE..
//.NNNNNN..NNN..OOOO....OOOO.....TTTT....III..CCCC...CCCCC.EEEE.........
//.NNNNNNN.NNN..OOOO....OOOOO....TTTT....III.ICCCC....CCCC.EEEEEEEEEE...
//.NNNNNNN.NNN.NOOO......OOOO....TTTT....III.ICCC..........EEEEEEEEEE...
//.NNN.NNNNNNN.NOOO......OOOO....TTTT....III.ICCC..........EEEEEEEEEE...
//.NNN.NNNNNNN.NOOO......OOOO....TTTT....III.ICCC..........EEEEEEEEEE...
//.NNN..NNNNNN..OOOO....OOOOO....TTTT....III.ICCCC....CCCC.EEEE.........
//.NNN..NNNNNN..OOOO....OOOO.....TTTT....III..CCCC...CCCCC.EEEE.........
//.NNN...NNNNN..OOOOOOOOOOOO.....TTTT....III..CCCCCCCCCCC..EEEEEEEEEEE..
//.NNN...NNNNN...OOOOOOOOOO......TTTT....III...CCCCCCCCCC..EEEEEEEEEEE..
//.NNN....NNNN....OOOOOOOOO......TTTT....III....CCCCCCCC...EEEEEEEEEEE..
//.................OOOOOO........................CCCCC..................
//......................................................................
// TODO: This file has a lot of stuff that specifically supports DHCP updates.
// We are trying to remove this once we establish that none of this configuration
// is necessary. Until then, we will hide this behind a feature flag
// and when we deprecate all of this, we can get rid of this complexity.

function renderNewInterfaceTable(
  ifaceUpdates: InterfaceUpdate[],
  dhcpUpdates: DHCPUpdate[],
  ifaces: {
    serialNumber: string;
    name: string;
    type: string;
    dhcp?: boolean | null | undefined;
    configuredAddrs: string[];
    remainingAddrs: string[];
  }[]
) {
  let ifaceMap: Record<
    string,
    { serialNumber: string; name: string; dhcp?: boolean; addrs: string[] }
  > = {};
  ifaces.forEach((iface) => {
    ifaceMap[`${iface.serialNumber}#${iface.name}`] = {
      serialNumber: iface.serialNumber,
      name: iface.name,
      dhcp: iface.dhcp || false,
      addrs: [...iface.configuredAddrs, ...iface.remainingAddrs],
    };
  });

  ifaceUpdates.forEach((update) => {
    const key = `${update.appliance}#${update.interfaceName}`;
    let obj = ifaceMap[key];
    if (update.isDelete) {
      obj.addrs = obj.addrs.filter((addr) => addr !== update.cidr);
    } else {
      obj.addrs = [...obj.addrs, update.cidr];
    }
    ifaceMap[key] = obj;
  });

  dhcpUpdates.forEach((update) => {
    const key = `${update.appliance}#${update.interfaceName}`;
    ifaceMap[key].dhcp = update.dhcpEnabled;
  });
  return ifaceMap;
}
// Recreate new map and verify that configuration is correct.
function validateUpdates(
  ifaceUpdates: InterfaceUpdate[],
  dhcpUpdates: DHCPUpdate[],
  ifaces: {
    serialNumber: string;
    name: string;
    type: string;
    dhcp?: boolean | null | undefined;
    configuredAddrs: string[];
    remainingAddrs: string[];
  }[]
) {
  let ifaceMap = renderNewInterfaceTable(ifaceUpdates, dhcpUpdates, ifaces);

  let invalidConfigs: { serialNumber: string; name: string }[] = [];
  Object.keys(ifaceMap).forEach((key) => {
    const { addrs, dhcp, name, serialNumber } = ifaceMap[key];
    if (addrs.length === 0 && !dhcp) {
      invalidConfigs.push({ serialNumber, name });
    }
  });

  return invalidConfigs;
}

export function InterfacesModal({
  locationId,
  opened,
  close,
}: InterfacesModalProps) {
  const { classes } = useStyles();
  const [lastUpdateTime, setLastUpdateTime] = useState<Date>();
  const [value, setValue] = useState("");
  const [showNonEthernet] = useLocalStorage<boolean>(
    "showNonEthernetInterfaces",
    false
  );
  const { data } = useApplianceInterfacesQuery({
    variables: { locationId },
    pollInterval: 7000,
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      setLastUpdateTime(new Date());
    },
    fetchPolicy: "no-cache",
    skip: !opened,
  });
  const feedback = useFeedback();

  const [anchorEl, setAnchorEl] = React.useState<
    [HTMLButtonElement, { appliance: string; interfaceName: string }] | null
  >(null);
  const [interfaceUpdates, setInterfaceUpdates] = useState<InterfaceUpdate[]>(
    []
  );
  const [dhcpUpdates, setDhcpUpdates] = useState<DHCPUpdate[]>([]);
  const [dhcpWarning, setDhcpWarning] = useState<DHCPUpdate | null>(null);
  const { pushSnackbar } = useFeedback();
  const eraseAllConfigChanges = () => {
    setInterfaceUpdates([]);
    setDhcpUpdates([]);
  };
  const [
    updateConfigurations,
    { loading },
  ] = useUpdateApplianceNetworkConfigurationMutation({
    refetchQueries: ["applianceInterfaces"],
    awaitRefetchQueries: true,
    onCompleted: () => {
      eraseAllConfigChanges();
      pushSnackbar(
        `Successfully submitted network updates to appliance, will take up to 1 minute to take effect`,
        FeedbackType.Success
      );
    },
    onError: (error) => {
      eraseAllConfigChanges();
      pushSnackbar(
        `Could not update all appliance network configurations: ${error}`,
        FeedbackType.Error
      );
    },
    fetchPolicy: "no-cache",
  });

  const appliances = data?.location?.appliances;
  const interfaces = appliances
    ?.flatMap(({ interfaces, serialNumber }) =>
      interfaces.map((iface) => ({
        ...iface,
        serialNumber,
      }))
    )
    .filter(({ name }) => !name.includes("docker"));
  const ethernetOnlyInterfaces = interfaces?.filter(
    ({ type }) => type === "ethernet"
  );
  const changedInterfacesRender = renderNewInterfaceTable(
    interfaceUpdates,
    dhcpUpdates,
    interfaces || []
  );

  const addInterfaceUpdate = (updateObject: InterfaceUpdate) => {
    if (!interfaceUpdates.includes(updateObject)) {
      setInterfaceUpdates([...interfaceUpdates, updateObject]);
    }
  };

  const removeInterfaceUpdate = (updateObject: InterfaceUpdate) => {
    setInterfaceUpdates(
      interfaceUpdates.filter((update) => !lodash.isEqual(update, updateObject))
    );
  };

  const addDhcpUpdate = (updateObject: DhcpUpdate) => {
    if (!dhcpUpdates.includes(updateObject)) {
      setDhcpUpdates([...dhcpUpdates, updateObject]);
    }
  };
  const removeDhcpUpdate = (updateObject: DhcpUpdate) => {
    setDhcpUpdates(
      dhcpUpdates.filter((update) => !lodash.isEqual(update, updateObject))
    );
  };

  return (
    <>
      <Modal
        open={opened}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          outline: "none",
        }}
        onClose={close}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={opened}>
          <Paper className={classes.paper}>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                padding: "4px 0 16px",
              }}
            >
              <div className={classes.leftHeader}>
                Network Interfaces
                <Tooltip
                  title={`Last updated: ${
                    lastUpdateTime
                      ? formatDistance(lastUpdateTime, new Date(), {
                          includeSeconds: true,
                        })
                      : "never"
                  }`}
                >
                  <CircularProgress style={{ marginLeft: 10 }} size={"1.5ch"} />
                </Tooltip>
                <Typography variant={"body2"}>
                  Your appliance has smartly configured its network settings to
                  fit with your camera network configuration.
                </Typography>
              </div>
            </div>

            {!interfaces || !ethernetOnlyInterfaces ? (
              <>
                <Loading />
                <Box m={1} />
              </>
            ) : (
              <>
                <StyledTable>
                  <TableHead>
                    <TableRow>
                      <StyledCell>
                        <div style={{ marginLeft: 15 }}>Appliance</div>
                      </StyledCell>
                      <StyledCell>Interface</StyledCell>
                      <StyledCell>Type</StyledCell>
                      <StyledCell>DHCP / Assigned Addresses</StyledCell>
                      <StyledCell>Static Addresses</StyledCell>
                    </TableRow>
                    <TableRow />
                  </TableHead>

                  <TableBody>
                    {(showNonEthernet
                      ? interfaces
                      : ethernetOnlyInterfaces
                    ).map((iface) => {
                      const ipAddressAssigned =
                        iface.dhcp !== undefined &&
                        iface.dhcp !== null &&
                        !!iface.remainingAddrs.length;
                      const filterPredicate = ({
                        appliance,
                        interfaceName,
                      }: {
                        appliance: string;
                        interfaceName: string;
                      }) =>
                        interfaceName === iface.name &&
                        appliance === iface.serialNumber;

                      const dhcpUpdate = dhcpUpdates.filter(filterPredicate);

                      const renderedInterface =
                        changedInterfacesRender[
                          `${iface.serialNumber}#${iface.name}`
                        ];

                      const relevantUpdates = interfaceUpdates
                        .filter(filterPredicate)
                        .map(({ isDelete, cidr }) => ({ isDelete, cidr }));

                      const canEditStaticAddresses = !(
                        renderedInterface.dhcp && !ipAddressAssigned
                      );

                      return (
                        <TableRow className={classes.tableRow}>
                          <StyledCell>
                            <div style={{ marginLeft: 15 }}>
                              {iface.serialNumber}
                            </div>
                          </StyledCell>
                          <StyledCell>{iface.name}</StyledCell>
                          <StyledCell>{iface.type}</StyledCell>
                          <StyledCell>
                            <Box
                              display="flex"
                              flexWrap="wrap"
                              alignItems="center"
                              justifyContent="flex-start"
                            >
                              {!ipAddressAssigned && (
                                <Tooltip
                                  title={`${
                                    renderedInterface.dhcp
                                      ? "Disable"
                                      : "Enable"
                                  } DHCP mode`}
                                >
                                  <Switch
                                    color="primary"
                                    size="small"
                                    checked={renderedInterface.dhcp}
                                    onChange={() => {
                                      const dhcpUpdateObject = {
                                        interfaceName: iface.name,
                                        appliance: iface.serialNumber,
                                        dhcpEnabled: !renderedInterface.dhcp,
                                      };
                                      if (!!dhcpUpdate.length) {
                                        removeDhcpUpdate({
                                          interfaceName: iface.name,
                                          appliance: iface.serialNumber,
                                          dhcpEnabled: !iface.dhcp,
                                        });
                                      } else if (renderedInterface.dhcp) {
                                        // switch off
                                        setDhcpWarning(dhcpUpdateObject);
                                      } else {
                                        // switch on
                                        setDhcpWarning(dhcpUpdateObject);
                                      }
                                    }}
                                  />
                                </Tooltip>
                              )}
                              {!!dhcpUpdate.length ? (
                                <strong
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginLeft: 6,
                                  }}
                                >
                                  Unsaved Changes
                                  <Tooltip
                                    title={
                                      "Assigned IP addresses won't be updated until you save this configuration."
                                    }
                                  >
                                    <InfoOutlinedIcon
                                      style={{
                                        marginLeft: 2,
                                        fontSize: 15,
                                        color: "RGBA(20, 127, 225, 1.00)",
                                      }}
                                      fontSize={"small"}
                                    />
                                  </Tooltip>
                                </strong>
                              ) : iface.dhcp && !ipAddressAssigned ? (
                                <strong>
                                  Awaiting Assignment{" "}
                                  <CircularProgress size={"small"} />
                                </strong>
                              ) : (
                                ipAddressAssigned &&
                                iface.remainingAddrs.map((addr) => (
                                  <Box m={0.5}>
                                    <Chip
                                      size="small"
                                      label={addr}
                                      variant="outlined"
                                    />
                                  </Box>
                                ))
                              )}
                            </Box>
                          </StyledCell>
                          <StyledCell>
                            <Box
                              display="flex"
                              flexWrap="wrap"
                              alignItems="center"
                            >
                              {iface.configuredAddrs.map((cidr) => {
                                const toBeDeleted = relevantUpdates.some(
                                  ({ isDelete, cidr: cidrUpdate }) =>
                                    cidrUpdate === cidr && isDelete
                                );

                                const deleteObject = {
                                  appliance: iface.serialNumber,
                                  interfaceName: iface.name,
                                  cidr,
                                  isDelete: true,
                                };

                                return (
                                  <Box m={0.5}>
                                    {toBeDeleted ? (
                                      <Chip
                                        size="small"
                                        label={cidr}
                                        onDelete={
                                          canEditStaticAddresses
                                            ? () =>
                                                removeInterfaceUpdate(
                                                  deleteObject
                                                )
                                            : undefined
                                        }
                                        deleteIcon={
                                          canEditStaticAddresses ? (
                                            <UndoIcon
                                              style={{ color: "black" }}
                                            />
                                          ) : undefined
                                        }
                                        style={{
                                          backgroundColor: "red",
                                        }}
                                      />
                                    ) : (
                                      <Chip
                                        size="small"
                                        label={cidr}
                                        onDelete={() =>
                                          addInterfaceUpdate(deleteObject)
                                        }
                                      />
                                    )}
                                  </Box>
                                );
                              })}
                              {relevantUpdates
                                .filter(({ isDelete }) => !isDelete)
                                .map(({ cidr }) => (
                                  <Box m={0.5}>
                                    <Chip
                                      color="primary"
                                      size="small"
                                      label={cidr}
                                      onDelete={() =>
                                        removeInterfaceUpdate({
                                          appliance: iface.serialNumber,
                                          interfaceName: iface.name,
                                          cidr,
                                          isDelete: false,
                                        })
                                      }
                                    />
                                  </Box>
                                ))}
                              <Tooltip title="Add Static Address">
                                <IconButton
                                  size={"small"}
                                  style={{
                                    opacity: canEditStaticAddresses ? 1 : 0.3,
                                  }}
                                  onClick={(e) => {
                                    if (canEditStaticAddresses) {
                                      setAnchorEl([
                                        e.currentTarget,
                                        {
                                          appliance: iface.serialNumber,
                                          interfaceName: iface.name,
                                        },
                                      ]);
                                    } else {
                                      feedback.pushSnackbar(
                                        "Cannot edit static addresses before ip is auto assigned. Disable DHCP mode first.",
                                        FeedbackType.Error
                                      );
                                    }
                                  }}
                                >
                                  <AddIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </StyledCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </StyledTable>
                {interfaces.length <= 0 && (
                  <Typography className={classes.couldNotFetch}>
                    Could not fetch interfaces.
                  </Typography>
                )}
              </>
            )}
            <Box
              mt={2}
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
            >
              <Button
                variant="contained"
                style={{
                  fontSize: 12,
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                  transition: "all ease-in-out 0.2s",
                  opacity:
                    !!interfaceUpdates.length || !!dhcpUpdates.length ? 1 : 0.4,
                }}
                onClick={() => {
                  eraseAllConfigChanges();
                }}
              >
                Undo
              </Button>
              <Box m={1} />
              <Button
                color="primary"
                variant="contained"
                style={{
                  fontSize: 12,
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                  transition: "all ease-in-out 0.2s",
                  opacity:
                    !!interfaceUpdates.length || !!dhcpUpdates.length ? 1 : 0.4,
                }}
                disabled={!interfaceUpdates.length && !dhcpUpdates.length}
                onClick={() => {
                  if (interfaces) {
                    const invalidObjects = validateUpdates(
                      interfaceUpdates,
                      dhcpUpdates,
                      interfaces
                    );

                    if (invalidObjects.length !== 0) {
                      feedback.pushSnackbar(
                        `Please enable DHCP or add a static IP address for ${invalidObjects[0].name}`,
                        FeedbackType.Error
                      );
                      return;
                    }

                    updateConfigurations({
                      variables: {
                        input: {
                          dhcpUpdates,
                          interfaceUpdates,
                        },
                      },
                    });
                  }
                }}
              >
                Save Configuration
              </Button>
            </Box>
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl && anchorEl[0]}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              onClose={() => {
                setAnchorEl(null);
              }}
            >
              <Paper
                style={{
                  padding: "10px 15px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box m={0} fontWeight={"bold"}>
                  Enter static CIDR address for{" "}
                  {anchorEl && anchorEl[1].appliance} on{" "}
                  {anchorEl && anchorEl[1].interfaceName}
                </Box>
                <Box m={0.5} />
                <TextField
                  size={"small"}
                  variant="outlined"
                  fullWidth
                  value={value}
                  placeholder="192.168.1.0/24"
                  onChange={(event: any) => setValue(event.target.value)}
                />
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  style={{ marginTop: 12, alignSelf: "flex-end" }}
                  onClick={() => {
                    if (anchorEl) {
                      const updateObject = {
                        appliance: anchorEl[1].appliance,
                        interfaceName: anchorEl[1].interfaceName,
                        cidr: value,
                      };

                      if (!isCidr(value)) {
                        feedback.pushSnackbar(
                          "Input is an invalid CIDR",
                          FeedbackType.Error
                        );
                        return;
                      }

                      if (!isIpPrivate(value.split("/")[0])) {
                        feedback.pushSnackbar(
                          "Input is not an private IP address",
                          FeedbackType.Error
                        );
                        return;
                      }

                      const existingInterfaces = interfaces?.flatMap((iface) =>
                        iface.configuredAddrs
                          .concat(iface.remainingAddrs)
                          .map((cidr) => ({
                            appliance: iface.serialNumber,
                            interfaceName: iface.name,
                            cidr,
                          }))
                      );

                      if (
                        interfaceUpdates
                          .map(({ appliance, interfaceName, cidr }) => ({
                            appliance,
                            interfaceName,
                            cidr,
                          }))
                          .concat(existingInterfaces || [])
                          .some((ifaceUpdate) => {
                            if (lodash.isEqual(ifaceUpdate, updateObject)) {
                              return true;
                            }

                            if (
                              ifaceUpdate.cidr.split("/")[0] ===
                              updateObject.cidr.split("/")[0]
                            ) {
                              return true;
                            }
                            return false;
                          })
                      ) {
                        feedback.pushSnackbar(
                          "CIDR or IP already exists on configuration",
                          FeedbackType.Error
                        );
                      } else {
                        addInterfaceUpdate({
                          ...updateObject,
                          isDelete: false,
                        });

                        setAnchorEl(null);
                      }
                    }
                  }}
                  disabled={loading}
                >
                  Add CIDR
                </Button>
              </Paper>
            </Popover>
            <Dialog disableEscapeKeyDown maxWidth="xs" open={!!dhcpWarning}>
              <DialogTitle id="">Changing DHCP</DialogTitle>
              <DialogContent>
                {dhcpWarning &&
                  (!dhcpWarning.dhcpEnabled ? (
                    <>
                      Changing DHCP Settings can be dangerous and{" "}
                      <strong>
                        cause your appliance to lose all network connectivity.
                      </strong>{" "}
                      Are you sure you want to change this DHCP setting?
                    </>
                  ) : (
                    <>
                      Turning on DHCP will erase all static addresses on this
                      interface. Are you sure?
                    </>
                  ))}
              </DialogContent>
              <DialogActions>
                <Button
                  autoFocus
                  onClick={() => setDhcpWarning(null)}
                  color="primary"
                  variant="contained"
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    if (dhcpWarning) {
                      if (dhcpWarning.dhcpEnabled && interfaces) {
                        for (let i = 0; i < interfaces.length; i++) {
                          const iface = interfaces[i];
                          if (
                            iface.name === dhcpWarning.interfaceName &&
                            iface.serialNumber === dhcpWarning.appliance
                          ) {
                            const deletionObjects = iface.configuredAddrs.map(
                              (cidr) => {
                                return {
                                  appliance: iface.serialNumber,
                                  interfaceName: iface.name,
                                  cidr: cidr,
                                  isDelete: true,
                                };
                              }
                            );
                            setInterfaceUpdates(
                              interfaceUpdates
                                .filter(
                                  ({ appliance, interfaceName }) =>
                                    appliance !== dhcpWarning.appliance ||
                                    interfaceName !== dhcpWarning.interfaceName
                                )
                                .concat(deletionObjects)
                            );
                            break;
                          }
                        }
                      }
                      addDhcpUpdate(dhcpWarning);
                    }
                    setDhcpWarning(null);
                  }}
                >
                  I am sure
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
        </Fade>
      </Modal>
    </>
  );
}

export const FETCH_INTERFACES = gql`
  query applianceInterfaces($locationId: Int!) {
    location(id: $locationId) {
      appliances {
        interfaces {
          name
          type
          dhcp
          configuredAddrs
          remainingAddrs
        }
        serialNumber
        id
      }
    }
  }
`;

export const UPDATE_APPLIANCE_NETWORK = gql`
  mutation updateApplianceNetworkConfiguration(
    $input: UpdateApplianceNetworkConfigurationInput!
  ) {
    updateApplianceNetworkConfiguration(input: $input) {
      message
    }
  }
`;
