import CloseIcon from "@mui/icons-material/Close";
import { Divider, IconButton, Typography } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { ReactNode } from "react";

export function CopilotPopoverHeader({
  icon,
  title,
  subtitle,
  onClose,
  hideSubtitleDivider,
}: {
  icon?: ReactNode;
  title: string;
  subtitle?: string;
  onClose?: () => void;
  hideSubtitleDivider?: boolean;
}) {
  return (
    <>
      <motion.div
        key="lib-content-header"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 0.5,
        }}
        className="flex items-center justify-between"
      >
        <div className="flex items-center gap-2">
          {icon}
          <Typography className="text-white leading-normal text-base font-bold">
            {title}
          </Typography>
        </div>
        {!!onClose && (
          <IconButton size="small" className="p-0" onClick={onClose}>
            <CloseIcon fontSize="small" className="text-white/50" />
          </IconButton>
        )}
      </motion.div>
      <Divider className="border-[#2F2F2F]" />
      <AnimatePresence>
        {" "}
        {subtitle && (
          <>
            <motion.div
              key="lib-content-header-subtitle"
              className="text-[13px] text-[#B8B8B8]/50 italic leading-normal"
              initial={{ opacity: 0, translateX: -10 }}
              animate={{ opacity: 1, translateX: 0 }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.2 }}
            >
              {subtitle}
            </motion.div>
            {!hideSubtitleDivider && <Divider className="border-[#2F2F2F]" />}
          </>
        )}
      </AnimatePresence>
    </>
  );
}
