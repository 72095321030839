import { PlayCircleOutline } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import NotificationsIcon from "@mui/icons-material/Notifications";
import VideoIcon from "@mui/icons-material/PlayCircleOutlined";
import LocationIcon from "@mui/icons-material/Room";
import VideocamIcon from "@mui/icons-material/VideocamOutlined";
import ClockIcon from "@mui/icons-material/WatchLaterOutlined";
import {
  Button,
  Divider,
  IconButton,
  Link as MaterialLink,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { format, utcToZonedTime } from "date-fns-tz";
import { isBefore } from "date-fns/fp";
import gql from "graphql-tag";
import { useFlags } from "launchdarkly-react-client-sdk";
import { ReactNode, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { useBreakpoints } from "@/util/useBreakpoints";
import { getDevice } from "@/util/userAgent";

import { alertTypeObjectIds, alertTypesConfig } from "@/pages/Alerts/constants";
import { useAlertRoutes } from "@/pages/Alerts/utils";

import { PlayVideoButton } from "@/components/PlayVideoButton";
import { BasicPlayer } from "@/components/Player/BasicPlayer";
import { ImageWithFallback } from "@/components/Player/Status/ConnectivityDisplay";
import { IVodContextualPlayer } from "@/components/VideoWall/ContextualVideoPlayer";
import { DefaultDialog, useDialog } from "@/components/shared/Dialog";
import { NoStillsThumb } from "@/components/shared/NoStillsThumb";

import {
  AlertType,
  Camera,
  GetNewNotificationsCountDocument,
  Page_AlertsDocument,
  Page_AlertsQuery,
  useDeleteNotificationMutation,
} from "@/generated-models";
import { usePrefixOrgSlug } from "@/hooks/useOrgRouteBase";
import { usePermissions } from "@/hooks/usePermissions";

import {
  getClipDateRange,
  getEventDatagridId,
} from "../IntegrationsOld/Core/IntegrationsEventDatagrid";
import { CameraStillGrid } from "../IntegrationsOld/Core/IntegrationsEventStills";
import { NotificationType } from "./AlertNotifications";
import { createClipLink } from "./common";

function footageIsAvailable(
  footageStartDate: string | number,
  camera?: Pick<Camera, "firstSegmentTime">
) {
  const firstSegmentTime = camera?.firstSegmentTime;
  return (
    !!firstSegmentTime &&
    !isBefore(new Date(firstSegmentTime), new Date(footageStartDate))
  );
}

function AlertNotificationMessageItem({
  type,
  message,
}: {
  type: AlertType;
  message?: string | null;
}) {
  return (
    <Typography
      className={clsx("text-xs leading-4 font-bold", {
        "font-mono tracking-[1.4px] border border-solid border-[#353D48] rounded py-[1px] px-[6px] uppercase":
          type === AlertType.VehicleInterestList,
      })}
    >
      {message}
    </Typography>
  );
}

function AlertNotificationMessage({ noti }: { noti: NotificationType }) {
  if (noti.alert.type === AlertType.VehicleInterestList && noti.message) {
    return (
      <div className="flex items-center gap-1">
        {noti.message.split(",").map((m) => (
          <AlertNotificationMessageItem
            key={m}
            type={noti.alert.type}
            message={m}
          />
        ))}
      </div>
    );
  }

  return (
    <AlertNotificationMessageItem
      type={noti.alert.type}
      message={noti.message}
    />
  );
}

export function AlertNotification({
  noti,
  variables,
  handleContextualPlayerOpen,
}: {
  noti: NotificationType;
  variables: {
    alertId: number | null | undefined;
    startTime: string | null | undefined;
    endTime: string | null | undefined;
    limit: number;
  };
  handleContextualPlayerOpen: (newPlayerProps: IVodContextualPlayer) => void;
}) {
  const alertRoutes = useAlertRoutes();
  const hasPermission = usePermissions();
  const { search } = useLocation();
  const { fitsTablet, fitsDesktop } = useBreakpoints();
  const { contextualPlayer } = useFlags();
  const [deleteNotification] = useDeleteNotificationMutation({
    refetchQueries: [{ query: GetNewNotificationsCountDocument }],
  });
  const { open, ...deleteAlertsDialogProps } = useDialog();
  const { icon: Icon, color } = alertTypesConfig[noti.alert.type];
  const [playing, setPlaying] = useState(false);

  const header = (
    <div>
      <div className="flex items-center mb-0.5">
        <Icon className="mr-1.5 text-2xl" style={{ color }} />
        <Typography
          variant="h4"
          component="h2"
          className="text-base leading-[18.75px]"
        >
          {alertTypesConfig[noti.alert.type].typeName}
          {noti.integration
            ? ` - ${noti.integration.integrationEventType.name}`
            : ""}
        </Typography>
        <div className="grow" />
        <Tooltip title="Delete">
          <IconButton size="small" onClick={() => open()}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </div>
      <Divider className="self-stretch" />
      <DefaultDialog
        {...deleteAlertsDialogProps}
        title="Delete This Alert?"
        content={
          <>
            <Typography>
              Are you sure you want to delete this report? This cannot be
              undone.
            </Typography>
          </>
        }
        cancelText="Cancel"
        confirmText="Delete Alert"
        confirm={() => {
          deleteNotification({
            variables: { id: noti.id },
            optimisticResponse: {
              __typename: "Mutation",
              deleteNotification: {
                __typename: "DefaultPayload",
                message: "Done!",
              },
            },
            update: (store) => {
              const allNotificationsData = store.readQuery<Page_AlertsQuery>({
                query: Page_AlertsDocument,
                variables,
              });
              if (allNotificationsData) {
                const newNotifications = allNotificationsData.notifications.filter(
                  (n) => n.id !== noti.id
                );
                store.writeQuery({
                  query: Page_AlertsDocument,
                  variables,
                  data: {
                    notificationsCount:
                      allNotificationsData.notificationsCount - 1,
                    notifications: newNotifications,
                  },
                });
              }
            },
          });
          deleteAlertsDialogProps.confirm();
        }}
      />
    </div>
  );

  const timezone =
    noti.camera?.location.timezone ||
    noti.integration?.integrationSource.cameras[0]?.location.timezone ||
    "UTC";
  const time = (
    <span className="font-bold">
      {format(utcToZonedTime(new Date(noti.timestamp), timezone), "p z", {
        timeZone: timezone,
      })}
    </span>
  );

  const customText = noti.alert.customText;

  const handleOpen = () => {
    if (
      contextualPlayer &&
      noti.vod &&
      noti.camera &&
      hasPermission("video_vod_access")
    ) {
      handleContextualPlayerOpen({
        camera: noti.camera,
        startTime: noti.vod.startTime as string,
        endTime: noti.vod.endTime as string,
        alertType: alertTypesConfig[noti.alert.type].typeName,
        alertName: noti.alert.name,
        subjects: alertTypeObjectIds[noti.alert.type],
      });
    }
  };

  const actionButton =
    contextualPlayer &&
    noti.vod &&
    noti.camera &&
    noti.alert.type !== AlertType.CameraOffline ? (
      <>
        {hasPermission("video_vod_access") && (
          <PlayVideoButton
            available={footageIsAvailable(noti.timestamp, noti.camera)}
            label="View Clip"
            notAvailableLabel="Video Not Available"
            onClick={handleOpen}
          />
        )}
      </>
    ) : (
      <ViewNotificationDetailsButton notification={noti} />
    );

  return (
    <Paper
      data-cy="alert-notification"
      elevation={fitsTablet ? 3 : 0}
      className="flex flex-col gap-2 p-3 sm:p-4 mb-2 border border-gray-e0 sm:border-none rounded-lg sm:rounded"
      key={noti.id}
    >
      {!fitsTablet && header}
      <div className="flex flex-col gap-1 justify-between">
        <div className="flex gap-3">
          <div className="relative aspect-video !h-[83px] sm:!h-[200px]">
            {noti.vod &&
            noti.camera &&
            getDevice() !== "iPhone" &&
            hasPermission("video_vod_access") &&
            fitsDesktop ? (
              <>
                {!playing ? (
                  <>
                    <div className="absolute w-full h-full">
                      <div className="h-full w-full flex-center overflow-hidden bg-black">
                        <ImageWithFallback
                          src={noti.still}
                          fallback="/logo.png"
                          classes={{
                            root: clsx("w-full object-contain"),
                            fallback: "height-auto",
                          }}
                        />
                      </div>
                    </div>
                    <div className="absolute w-full h-full flex-center items-center text-white text-md bg-[#1b1b1b] bg-opacity-30">
                      <IconButton
                        className="w-full h-full"
                        color="inherit"
                        onClick={() => setPlaying(true)}
                        size="large"
                      >
                        <VideoIcon style={{ fontSize: 48 }} />
                      </IconButton>
                    </div>
                  </>
                ) : (
                  <BasicPlayer
                    className="rounded overflow-hidden sm:rounded-none"
                    poster={noti.still || undefined}
                    sources={{ tunnel: noti.vod.feeds.tunnel, local: "" }}
                    muxConfig={{
                      cameraId: noti.camera.id,
                      playerName: "Alert Notification",
                    }}
                    forceMuted
                    autoPlay={true}
                  />
                )}
              </>
            ) : noti.still ? (
              <img
                className="bg-[#444] object-cover object-center rounded overflow-hidden sm:rounded-none w-full"
                src={noti.still}
                alt="notification still"
                onClick={handleOpen}
              />
            ) : noti.integration?.integrationSource.cameras.length ? (
              <CameraStillGrid
                cameras={noti.integration.integrationSource.cameras}
                timestamp={noti.timestamp}
                fullWidth
              />
            ) : (
              <NoStillsThumb />
            )}
          </div>
          <div className="flex flex-col gap-0.5 sm:gap-2 align-baseline grow">
            {fitsTablet && header}
            <InfoItem>
              {fitsTablet && (
                <NotificationsIcon className="text-[#c4c4c4] text-base mr-2" />
              )}
              <div className="flex gap-2 items-center">
                <MaterialLink
                  className="no-underline sm:underline"
                  component={Link}
                  to={
                    `${alertRoutes.ALERTS_CONFIGURE}/${noti.alert.id}` + search
                  }
                >
                  {noti.alert.name}
                </MaterialLink>
                {noti.message && (
                  <>
                    <Divider className="h-3" orientation="vertical" />
                    <AlertNotificationMessage noti={noti} />
                  </>
                )}
              </div>
            </InfoItem>
            <InfoItem>
              {fitsTablet && (
                <LocationIcon className="text-[#c4c4c4] text-base mr-2" />
              )}
              {noti.camera?.location.name ??
                noti.integration?.integrationSource?.standardMeta?.siteName ??
                noti.integration?.integrationSource?.cameras[0]?.location
                  .name ??
                "Unknown"}
            </InfoItem>
            {noti.integration?.integrationSource?.standardMeta?.name && (
              <InfoItem>
                {fitsTablet && (
                  <Icon className="text-[#c4c4c4] text-base mr-2" />
                )}
                {noti.integration?.integrationSource?.standardMeta?.name}
              </InfoItem>
            )}
            {noti.camera && (
              <InfoItem>
                {fitsTablet && (
                  <VideocamIcon className="text-[#c4c4c4] text-base mr-2" />
                )}
                {noti.camera.name}
              </InfoItem>
            )}
            <InfoItem>
              {fitsTablet && (
                <ClockIcon className="text-[#c4c4c4] text-base mr-2" />
              )}
              <p>
                {format(
                  utcToZonedTime(new Date(noti.timestamp), timezone),
                  "LLLL d, yyyy",
                  { timeZone: timezone }
                )}
                {fitsTablet && (
                  <>
                    <span> </span>
                    {time}
                  </>
                )}
              </p>
            </InfoItem>
            {!fitsTablet && <InfoItem>{time}</InfoItem>}
            {fitsDesktop && customText && (
              <InfoItem>
                <InfoIcon className="text-[#c4c4c4] text-base mr-2 self-start" />
                <ViewMore text={customText} />
              </InfoItem>
            )}

            <div className="grow" />
            {fitsDesktop && actionButton}
          </div>
        </div>
        {!fitsDesktop && (
          <div className="pt-3.5">
            {customText && (
              <div className="pb-3.5">
                <ViewMore text={customText} />
              </div>
            )}
            {actionButton}
          </div>
        )}
      </div>
    </Paper>
  );
}

const ViewMore = ({ text }: { text: string }) => {
  const maxLines = 4;
  const { fitsTablet, fitsDesktop } = useBreakpoints();
  const maxLength = fitsTablet ? (fitsDesktop ? 70 : 104) : 49;
  const lines = text.split("\n");
  const vOverflow = lines.length > maxLines;
  const hOverflow = lines.slice(0, maxLines).some((l) => l.length > maxLength);
  const hasOverflow = vOverflow || hOverflow;
  const [clamp, setClamp] = useState(hasOverflow);

  return (
    <div>
      <p
        style={
          clamp && vOverflow
            ? {
                WebkitLineClamp: maxLines,
                WebkitBoxOrient: "vertical",
                display: "-webkit-box",
              }
            : undefined
        }
        className={clsx(
          "italic leading-4 overflow-hidden pr-1 max-w-xs sm:max-w-2xl md:max-w-md",
          clamp && hOverflow
            ? "whitespace-pre text-ellipsis"
            : "whitespace-pre-wrap"
        )}
      >
        {text}
      </p>
      {hasOverflow && (
        <Button
          onClick={() => {
            setClamp(!clamp);
          }}
          color="primary"
          className="p-0 font-normal"
        >
          View {clamp ? "More" : "Less"}
        </Button>
      )}
    </div>
  );
};

function ViewNotificationDetailsButton({
  notification: { timestamp, alert, camera, integration, vod },
}: {
  notification: NotificationType;
}) {
  const prefixOrgSlug = usePrefixOrgSlug();
  const hasPermission = usePermissions();

  let props: {
    children: ReactNode;
    to: string;
    startIcon?: ReactNode;
  } | null = null;

  if (camera) {
    if (alert.type === AlertType.CameraOffline) {
      props = {
        children: "View Camera Health",
        to: `/maintain/cameras?search=${encodeURIComponent(camera.name)}`,
      };
    } else if (camera && vod && hasPermission("video_vod_access")) {
      props = {
        children: "View Clip",
        to: createClipLink(camera.id, vod, alert.type),
        startIcon: <PlayCircleOutline />,
      };
    }
  } else if (integration) {
    const integrationType = integration.integrationEventType.integrationType.key.toLowerCase();
    const vendor = integration.integrationVendor.key;
    const source = integration.integrationSource;
    const { start, end } = getClipDateRange(vendor, new Date(timestamp));
    const search = new URLSearchParams({
      id: getEventDatagridId({
        id: source.id,
        ts: new Date(timestamp).getTime(),
        externalId: source.externalId,
      }),
      vod: `${start.toISOString()}|${end.toISOString()}`,
    }).toString();

    props = {
      children: "View Event",
      to: `/integrations/${integrationType}/${vendor}/${source.integrationId}/device/${source.id}?${search}`,
    };
  }

  if (!props) return null;

  return (
    <Button
      color="primary"
      className="ml-[-5px] mb-[-5px] py-0 normal-case self-start text-base"
      component={Link}
      {...props}
      to={prefixOrgSlug(props.to)}
    />
  );
}

function InfoItem({ children }: { children: React.ReactNode }) {
  return (
    <div className="flex items-center text-sm leading-none">{children}</div>
  );
}

gql`
  query CameraById($cameraId: Int!) {
    camera(id: $cameraId) {
      id
      name
      status
      vendor
      createdAt
      firstSegmentTime
      location {
        id
        name
        timezone
      }
      health {
        cameraOnline
        applianceOnline
      }
      settings {
        audioControlEnabled
      }
    }
  }
`;
