import { Avatar } from "@mui/material";
import clsx from "clsx";
import { AnimatePresence } from "framer-motion";
import { useFlags } from "launchdarkly-react-client-sdk";
import { omit } from "lodash";
import { findLastIndex, isEqual } from "lodash/fp";

import { useLocalStorage } from "@/util/useLocalStorage";

import {
  idToFilterConfigMap,
  intelligentFiltersConfig,
} from "@/pages/Search/intelligence/intelligence";
import {
  useHideBoundingBoxes,
  useSearchSubjects,
} from "@/pages/Search/searchHooks";

import {
  FocusedBoxInfo,
  useAiLprQuery,
  useAiVodQuery,
  useFrameQuery,
  useFocusBox,
  ObjectFrame,
} from "@/components/Ai/AiState";
import { idToFilterDebugConfigMap } from "@/components/Ai/debugConfig";
import { useWallclockPts } from "@/components/Player/PlayerBase";

import { ActivityBoundingBox } from "./ActivityBoundingBox";

const previousFrameCount = 3 * 10; // 30 seconds
interface ActivityOverlayProps {
  cameraId: number;
  startTime: string;
  endTime: string;
  timezone: string;
  subjects?: string[] | null;
}

export function ActivityOverlay({
  cameraId,
  startTime,
  endTime,
  subjects,
}: ActivityOverlayProps) {
  const [hiddenBB] = useHideBoundingBoxes();
  const subjectsFromUrl = useSearchSubjects();

  // bbox toggle ON, any combo of Vehicle and People Search buttons ON/OFF -> all bboxes ON
  // bbox toggle OFF, Vehicle and People Search buttons both OFF -> all bboxes OFF
  // bbox toggle OFF, Vehicle Search OFF, People Search ON -> vehicle bboxes OFF, people bboxes ON
  const resolvedSubjects =
    subjects ?? (hiddenBB ? subjectsFromUrl : ["0", "1", "2", "5", "7"]);

  const boundingBoxData = useAiVodQuery(
    cameraId,
    startTime,
    endTime,
    resolvedSubjects
  );
  if (boundingBoxData == null) {
    return null;
  }

  const { objectFrames, displayingFrames } = boundingBoxData;
  return (
    <>
      {!!objectFrames.length ? (
        <BoundingBoxOverlay
          objectFrames={displayingFrames}
          cameraId={cameraId}
        />
      ) : (
        <BorderActivityOverlay objectFrames={objectFrames} />
      )}
    </>
  );
}

interface LicensePlateRecognitionActivityOverlayProps {
  cameraId: number;
  startTime: string;
  endTime: string;
}

export function LicensePlateRecognitionActivityOverlay({
  cameraId,
  startTime,
  endTime,
}: LicensePlateRecognitionActivityOverlayProps) {
  const objectFrames = useAiLprQuery(cameraId, startTime, endTime);

  return (
    <>
      {objectFrames && objectFrames.length ? (
        <BoundingBoxOverlay objectFrames={objectFrames} cameraId={cameraId} />
      ) : null}
    </>
  );
}

function BorderActivityOverlay({
  objectFrames,
}: {
  objectFrames: ObjectFrame[];
}) {
  const subjects = useSearchSubjects();
  const wallclockPtsNullable = useWallclockPts();
  const ptsTime = !!wallclockPtsNullable ? wallclockPtsNullable : 0;

  const latestFrameIdx = findLastIndex(
    ({ pts }) =>
      ptsTime - 90 * 1000 <= pts && // 90 second grace
      pts <= ptsTime,
    objectFrames
  );
  const filteredConfigs = Object.values(
    intelligentFiltersConfig
  ).filter(({ objectIds }) => objectIds.every((x) => subjects?.includes(x)));
  const lastNFramesFiltered =
    latestFrameIdx !== -1
      ? objectFrames
          .slice(
            Math.max(0, latestFrameIdx - previousFrameCount), // if theres motion, filter out too many frames from being considered
            latestFrameIdx
          )
          .map((frame) => ({
            ...frame,
            objects: frame.objects.filter(({ type: { id } }) =>
              filteredConfigs.some(({ objectIds }) => objectIds.includes(id))
            ),
          }))
      : [];

  const anythingHappened = lastNFramesFiltered.some(
    ({ objects }) => objects.length !== 0
  );

  return (
    <>
      <div
        className={clsx(
          "absolute w-full h-full z-1 transition-colors border-3 border-transparent",
          {
            "border-[#30A0FF]": anythingHappened,
          }
        )}
      />
      <div className="absolute ml-2 flex flex-col justify-center gap-1 h-full">
        {filteredConfigs
          .filter(({ objectIds }) =>
            lastNFramesFiltered.some(({ objects }) =>
              objects.some(({ type: { id } }) => objectIds.includes(id))
            )
          )
          .map(({ icon, color }, idx) => (
            // <Tooltip title={`${title} detected`} key={idx}>
            <Avatar
              key={idx}
              className="h-6 w-6"
              style={{ backgroundColor: color() }}
            >
              {icon}
            </Avatar>
            // </Tooltip>
          ))}
      </div>
    </>
  );
}

function BoundingBoxOverlay({
  objectFrames,
  cameraId,
}: {
  objectFrames: ObjectFrame[];
  cameraId: number;
}) {
  const { hideBb } = useFlags();
  const [showMotionBoxes] = useLocalStorage("showMotionBoxes", false);
  const [focusedBoundingBox, setFocusedBoundingBox] = useFocusBox();

  // Add in .3s offset to account for animation.
  const closestFrame = useFrameQuery(objectFrames, 300 * 90);
  const [showAiDebug] = useLocalStorage("showAiDebug", false);

  return (
    <div className="absolute w-full h-full">
      <AnimatePresence>
        {focusedBoundingBox?.objectPath && (
          <div className="absolute h-full w-full">
            <svg
              preserveAspectRatio="none"
              className="absolute h-full w-full"
              viewBox="0 0 1000 1000"
            >
              <path
                d={`${focusedBoundingBox.objectPath.map(
                  ({ x, y }, idx) =>
                    `${idx === 0 ? "M" : "L"} ${(x * 1000).toFixed(0)} ${(
                      y * 1000
                    ).toFixed(0)}`
                )}`}
                stroke="green"
                strokeWidth="3"
                fillOpacity="0"
              />
            </svg>
          </div>
        )}
        {!hideBb &&
          closestFrame &&
          closestFrame.objects
            .filter(({ type: { id: objTypeId } }) => {
              const { id } = showAiDebug
                ? idToFilterDebugConfigMap[objTypeId]
                : idToFilterConfigMap[objTypeId];
              return showMotionBoxes || id !== "motion";
            })
            .map((detectedObject) => {
              const objTypeId = detectedObject.type.id;

              const boxFocusIdentifier: FocusedBoxInfo = {
                localObjId: detectedObject.localObjId || "NO_ID_AVALIABLE",
                objectType: objTypeId,
                cameraId,
              };
              const focusedStatus =
                !!focusedBoundingBox &&
                isEqual(
                  boxFocusIdentifier,
                  omit(focusedBoundingBox, ["objectPath"])
                );
              const key = `${cameraId}|${objTypeId}|${detectedObject.localObjId}`;
              return (
                <ActivityBoundingBox
                  additionalClasses={clsx(
                    focusedBoundingBox === null || focusedStatus
                      ? "opacity-100"
                      : "opacity-30 hover:opacity-100",
                    { "cursor-pointer": !!setFocusedBoundingBox }
                  )}
                  key={key}
                  id={key}
                  objTypeId={objTypeId}
                  description={detectedObject.description || null}
                  selected={focusedStatus}
                  onClick={
                    !!setFocusedBoundingBox
                      ? () => {
                          setFocusedBoundingBox(
                            isEqual(boxFocusIdentifier, focusedBoundingBox)
                              ? null
                              : boxFocusIdentifier
                          );
                        }
                      : undefined
                  }
                  {...detectedObject}
                />
              );
            })}
      </AnimatePresence>
    </div>
  );
}
