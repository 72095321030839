import { format } from "date-fns-tz";
import { ArrayParam, useQueryParam } from "use-query-params";

import { filterNullish } from "@/util/filterFalsy";

import { FilterFieldOption } from "@/components/Filter/constant";

import { useAuditLogFiltersQuery } from "@/generated-models";

import { getDescription } from "./AuditDetails";

export function useAuditLogFilters() {
  const { data } = useAuditLogFiltersQuery();
  const [categoriesFilter] = useQueryParam("categories", ArrayParam);
  const categories = data?.auditLogCategories.map((c) => c.name) || [];
  const currentCatFilters =
    categoriesFilter?.filter(filterNullish) ?? categories;
  const actions: Record<string, string[]> =
    data?.auditLogCategories.reduce<Record<string, string[]>>((p, v) => {
      p[v.name] = v.actions;
      return p;
    }, {}) ?? {};

  const categoryOptions: FilterFieldOption[] =
    categories
      ?.map((c) => ({ label: c, value: c }))
      .sort((a, b) => a.label.localeCompare(b.label)) ?? [];
  const actionOptions: FilterFieldOption[] =
    Object.entries(actions)
      .flatMap(([k, v]) =>
        currentCatFilters.includes(k)
          ? v.map((a) => ({ label: getDescription(a), value: a }))
          : null
      )
      .filter(filterNullish)
      .sort((a, b) => a.label.localeCompare(b.label)) ?? [];

  const userOptions: FilterFieldOption[] =
    data?.users
      ?.map((u) => ({ label: u.profile.name, value: u.id }))
      .sort((a, b) => a.label.localeCompare(b.label)) ?? [];

  const filters = [
    {
      placeholder: "Dates & Times",
      filterName: "datetime",
      label: "Date & Time",
      getValue: (value: string | null) => {
        return (
          value
            ?.split("|")
            .map((vi) => format(new Date(vi), "MMM d, yyyy p"))
            .join(" - ") || ""
        );
      },
      options: [],
      minDate: new Date("2020-07-01"),
    },
    {
      placeholder: "Category",
      filterName: "categories",
      label: "Categories",
      options: categoryOptions,
    },
    {
      placeholder: "Actions",
      filterName: "actions",
      label: "Actions",
      options: actionOptions,
    },
    {
      placeholder: "Users",
      filterName: "users",
      label: "Users",
      options: userOptions,
    },
  ];

  const categorizedFilters = data?.auditLogCategories.map((c) => ({
    placeholder: c.name,
    filterName: c.name,
    id: c.name,
    label: c.name,
    options: c.actions.map((a) => ({
      label: getDescription(a),
      value: a,
    })),
  }));

  const newFilters = [
    ...(categorizedFilters && categorizedFilters.length > 0
      ? categorizedFilters
      : []),
  ];

  const userFilter = {
    placeholder: "Users",
    filterName: "users",
    label: "Users",
    options: userOptions,
  };

  return {
    newFilters,
    filters,
    userFilter,
  };
}
