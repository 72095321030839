import LeftIcon from "@mui/icons-material/ChevronLeft";
import { IconButton, Typography } from "@mui/material";
import clsx from "clsx";
import { ReactNode } from "react";

import { useBreakpoints } from "@/util/useBreakpoints";

import { usePrefixOrgSlug } from "../hooks/useOrgRouteBase";
import { BackButton } from "./BackButton";

interface MobileHeaderProps {
  transparent?: boolean;
  action?: ReactNode;
  label: string;
  to?: string;
  /** Allows overriding the default of hiding on `fitsDesktop` */
  show?: boolean;
  showBackButton?: boolean;
  onBack?: (() => void) | null;
  classes?: {
    root?: string;
  };
  disableShadow?: boolean;
  sticky?: boolean;
}

export function MobileHeader({
  action,
  label,
  to,
  show,
  showBackButton = true,
  onBack,
  classes,
  disableShadow = false,
  sticky = true,
}: MobileHeaderProps) {
  const { fitsDesktop } = useBreakpoints();
  const prefixOrgSlug = usePrefixOrgSlug();
  if (show === false || (show === undefined && fitsDesktop)) return null;

  return (
    <div className={sticky ? "sticky top-0 z-50" : ""}>
      <div
        className={clsx(
          "flex items-center justify-between py-[5px] sm:p-0 md:p-2 bg-white",
          classes?.root
        )}
      >
        {showBackButton ? (
          <>
            {!onBack && (
              <BackButton
                to={to ?? prefixOrgSlug(`/menu`)}
                className="text-primary ml-3 md:hidden"
              />
            )}
            {!!onBack && (
              <IconButton
                onClick={onBack}
                size="small"
                className="text-primary ml-3"
              >
                <LeftIcon fontSize="large" />
              </IconButton>
            )}
          </>
        ) : (
          <div />
        )}
        <Typography
          variant="h1"
          className={clsx("text-[17px] leading-[22px] sm:text-xl truncate", {
            "-ml-[45px]": !action,
          })}
        >
          {label}
        </Typography>
        {action ?? <div />}
      </div>
      {!disableShadow && (
        <div
          className="h-2.5 opacity-[.10] absolute w-full"
          style={{
            background:
              "linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%)",
          }}
        />
      )}
    </div>
  );
}
