import { IconButton, List, Popover } from "@mui/material";
import clsx from "clsx";
import React, { PropsWithChildren, useCallback, useState } from "react";

import { ShareSaveIcon } from "@/components/Player/PlayerIcons";
import {
  PlayerControlsEvent,
  usePlayerControlsService,
  useSaveShareTrayOpen,
} from "@/components/Player/playerControlsMachine";

import { PlayerTooltip } from "./PlayerTooltip";

export function ShareTrayButton({
  size = "medium",
  icon = <ShareSaveIcon />,
  className,
  children,
}: PropsWithChildren<{
  size?: "small" | "medium";
  icon?: React.ReactNode;
  className?: string;
}>) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLDivElement>(null);
  const { send } = usePlayerControlsService();
  const open = useSaveShareTrayOpen();
  const toggle = useCallback(
    () => send(PlayerControlsEvent.TOGGLE_SAVE_SHARE_TRAY),
    [send]
  );
  return (
    <>
      <PlayerTooltip
        title="Share, Save, Download"
        classes={{ tooltip: "bg-black bg-opacity-90" }}
      >
        <div
          className={className ?? "md:bg-black rounded-md md:hover:bg-gray-900"}
          ref={(el) => el && setAnchorEl(el)}
        >
          {/**
           * Wrapper span is required to ensure the Tooltip works even
           * when the button is disabled, as disabled buttons don't emit events
           **/}
          <IconButton
            size={size}
            className={clsx(open ? "text-primary" : "text-white")}
            onClick={toggle}
          >
            {icon}
          </IconButton>
        </div>
      </PlayerTooltip>
      {anchorEl && (
        <Popover
          open={open}
          disablePortal
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={toggle}
          classes={{ paper: "bg-black rounded-md my-1" }}
        >
          <List className="bg-black p-0 w-30 rounded-md">{children}</List>
        </Popover>
      )}
    </>
  );
}
