import { gql } from "@apollo/client";
import { Box, Button, Grid, Link, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { isEqual } from "lodash/fp";
import { Dispatch, SetStateAction, useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import { BooleanParam, useQueryParam } from "use-query-params";

import { usePagination } from "@/util/usePagination";

import { useSettingsRoutes } from "@/pages/Settings/SettingsRoutes";

import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { LazyBackground } from "@/components/shared/LazyImage";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  Camera,
  GroupDetailsDocument,
  GroupDetailsQuery,
  useCamDrawerBaseQuery,
  useUpdateTagCamerasMutation,
} from "@/generated-models";
import { usePermissions } from "@/hooks/usePermissions";

import { GroupsCamDrawer } from "./GroupsCamDrawer";

const useStyles = makeStyles()(() => ({
  camera: {
    display: "flex",
    padding: "6px 10px",
    marginBottom: 1,
  },
  cameraThumbnail: {
    position: "relative",
    width: 258,
    height: 145,
  },
  imageSrc: {
    height: "100%",
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  online: {
    textTransform: "capitalize",
    marginTop: 4,
    "& > span": {
      width: 8,
      height: 8,
      margin: "0 4px",
      borderRadius: "50%",
    },
  },
}));

export function TagCameras({ group }: { group: GroupDetailsQuery["group"] }) {
  const settingsRoutes = useSettingsRoutes();
  const navigate = useNavigate();
  const [newGroupParam] = useQueryParam("new", BooleanParam);
  const [drawerOpen, setDrawerOpen] = useState(newGroupParam || false);
  const { pushSnackbar } = useFeedback();
  const hasPermission = usePermissions();

  const isAdmin = hasPermission("devices_manage");
  const [updateGroupCameras] = useUpdateTagCamerasMutation({
    refetchQueries: [
      { query: GroupDetailsDocument, variables: { id: group.id } },
    ],
    awaitRefetchQueries: true,
    onCompleted: () => {
      pushSnackbar(
        "Cameras successfully added to the group",
        FeedbackType.Success
      );
      if (newGroupParam) navigate("../users");
    },
    onError: () =>
      pushSnackbar(
        "Something went wrong while adding cameras to the group",
        FeedbackType.Error
      ),
  });

  // If this is a location group
  const location = group.locations[0]
    ? { id: group.locations[0].id, name: group.locations[0].name }
    : null;

  return (
    <>
      <Box px={3}>
        <Grid container direction="column">
          <Grid container item justifyContent="space-between">
            {group.locations.length > 0 ? (
              <Typography style={{ alignSelf: "flex-end" }}>
                This Group was auto generated from a location.{" "}
                {isAdmin && (
                  <>
                    You can add or remove cameras from{" "}
                    <Link
                      href={`${settingsRoutes.locations}/${group.locations[0].id}/cameras`}
                      target="_blank"
                      underline="hover"
                    >
                      location settings
                    </Link>
                  </>
                )}
              </Typography>
            ) : (
              <>
                {isAdmin && (
                  <>
                    <Typography style={{ alignSelf: "flex-end" }}>
                      Select the cameras you want to add to this Group.
                    </Typography>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={drawerOpen}
                        onClick={async (e) => {
                          setDrawerOpen(true);
                        }}
                      >
                        Select Cameras
                      </Button>
                    </Grid>
                  </>
                )}
              </>
            )}
          </Grid>

          {location ? (
            <Box p={2}>
              <Grid container item md={12}>
                <CameraGrid
                  cams={group.locations[0].cameras.map((cam) => ({
                    ...cam,
                    location,
                  }))}
                />
              </Grid>
            </Box>
          ) : (
            <Formik
              initialValues={{
                cameraIds: group.cameras.map((c) => c.id),
              }}
              onSubmit={(values) => {
                if (
                  isEqual(values.cameraIds.sort())(
                    group.cameras.map((c) => c.id).sort()
                  )
                ) {
                  return;
                }
                return updateGroupCameras({
                  variables: {
                    id: group.id,
                    value: { cameras: values.cameraIds },
                  },
                });
              }}
            >
              {({ submitForm, values }) => (
                <Form>
                  <Grid item>
                    <TagCamerasSelect
                      camIds={values.cameraIds}
                      submitFn={submitForm}
                      drawerOpen={drawerOpen}
                      setDrawerOpen={setDrawerOpen}
                    />
                  </Grid>
                </Form>
              )}
            </Formik>
          )}
        </Grid>
      </Box>
    </>
  );
}

function TagCamerasSelect({
  camIds,
  submitFn,
  drawerOpen,
  setDrawerOpen,
}: {
  camIds: number[];
  submitFn: () => void;
  drawerOpen: boolean;
  setDrawerOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const hasPermission = usePermissions();
  const { data, error } = useCamDrawerBaseQuery(refetchOnMountPolicy);

  if (error) {
    return (
      <ErrorMessage
        title="Failed to load cameras"
        description="Please try again later or contact support."
      />
    );
  }

  if (!data) {
    return (
      <div style={{ padding: "32px 0", margin: "0 auto" }}>
        <Loading />
      </div>
    );
  }

  const cams = data.cameras.filter((cam) => camIds.includes(cam.id));

  return (
    <>
      <Grid container item className="p-4">
        <CameraGrid cams={cams} />
      </Grid>
      {hasPermission("devices_manage") && (
        <GroupsCamDrawer
          cameras={data.cameras}
          open={drawerOpen}
          onOpen={() => setDrawerOpen(true)}
          onClose={() => {
            setDrawerOpen(false);
            submitFn();
          }}
        />
      )}
    </>
  );
}

const pageSize = 20;

function CameraGrid({
  cams,
}: {
  cams: (Pick<Camera, "id" | "status" | "name"> & {
    location: { name: string };
  })[];
}) {
  const { classes } = useStyles();
  const { pageCount, visibilitySensor } = usePagination(1);

  return (
    <>
      {cams.slice(0, pageSize * pageCount).map((cam) => (
        <Grid key={cam.id} item xs={12} md={4}>
          <div className={classes.camera}>
            <div style={{ fontSize: 12, position: "relative" }}>
              <div className={classes.cameraThumbnail}>
                <LazyBackground
                  className={classes.imageSrc}
                  cameraId={cam.id}
                />
              </div>
              <Grid
                container
                alignItems="center"
                className={classes.online}
                style={{}}
              >
                <span
                  style={{
                    backgroundColor:
                      cam.status === "online" ? "#67c21b" : "#b0b0b0",
                  }}
                />
                {cam.status}
              </Grid>
              <div style={{ fontSize: 14 }}>
                <div>
                  <strong>{cam.location.name}</strong>
                </div>
              </div>
              <div style={{ fontSize: 12 }}>
                <div>{cam.name}</div>
              </div>
            </div>
          </div>
        </Grid>
      ))}
      {cams.length > pageSize && visibilitySensor}
    </>
  );
}

gql`
  mutation updateTagCameras($id: Int!, $value: updateTagCamerasInput!) {
    updateTagCameras(id: $id, value: $value) {
      id
    }
  }
`;
