import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Divider, Typography } from "@mui/material";
import clsx from "clsx";
import { motion } from "framer-motion";
import { camelCase, startCase } from "lodash";
import { ReactNode } from "react";

import { useLibraryParams } from "../../copilotLibraryHooks";

export enum ObjectSearchStatus {
  default = "default",
  found = "found",
  missing = "missing",
}

function IndicatorIcon({ children }: { children: ReactNode }) {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0462 18.2699L19.2301 17.4177V17.4194C20.5126 15.9276 20.8871 14.4879 20.8871 12.3811C20.8871 7.6831 17.079 3.875 12.3811 3.875C7.6831 3.875 3.875 7.6831 3.875 12.3811C3.875 17.079 7.6831 20.8871 12.3811 20.8871C14.4879 20.8871 15.9276 20.5126 17.4194 19.2301H17.4177L18.2699 20.049L24.0142 25.7852C24.5052 26.2755 25.3007 26.2752 25.7913 25.7845C26.2825 25.2934 26.2822 24.497 25.7907 24.0062L20.0462 18.2699ZM12.3493 18.9404C15.9895 18.9404 18.9404 15.9895 18.9404 12.3493C18.9404 8.70912 15.9895 5.75818 12.3493 5.75818C8.70912 5.75818 5.75818 8.70912 5.75818 12.3493C5.75818 15.9895 8.70912 18.9404 12.3493 18.9404Z"
        fill="currentColor"
      />
      {children}
    </svg>
  );
}

function getIndicatorConfig(status: ObjectSearchStatus) {
  switch (status) {
    case ObjectSearchStatus.found:
      return {
        color: "text-[#3BB16A]",
        icon: (
          <IndicatorIcon>
            <path
              d="M11.4615 13.897L16.0238 9L17 10.0552L11.4615 16L8 12.2845L8.97615 11.2367L11.4615 13.897Z"
              fill="currentColor"
            />
          </IndicatorIcon>
        ),
      };
    case ObjectSearchStatus.missing:
      return {
        color: "text-[#F04E38]",
        icon: (
          <IndicatorIcon>
            <path
              d="M9.33987 10.1406L11.7035 12.5042L9.3634 14.8444L9.18662 15.0211L9.3634 15.1979L9.7868 15.6213L9.96357 15.7981L10.1404 15.6213L12.4805 13.2812L14.8206 15.6213L14.9974 15.7981L15.1741 15.6213L15.5975 15.1979L15.7743 15.0211L15.5975 14.8444L13.2574 12.5042L15.6211 10.1406L15.7978 9.96382L15.6211 9.78704L15.1977 9.36364L15.0209 9.18686L14.8441 9.36364L12.4805 11.7273L10.1168 9.36364L9.94005 9.18686L9.76328 9.36364L9.33987 9.78704L9.1631 9.96382L9.33987 10.1406Z"
              fill="currentColor"
              stroke="currentColor"
              strokeWidth="0.5"
            />
          </IndicatorIcon>
        ),
      };
    default:
      return {
        color: "text-white",

        icon: (
          <IndicatorIcon>
            <path
              d="M13.0966 14.7359H11.6815C11.6852 14.373 11.7111 14.0605 11.7592 13.7984C11.811 13.5323 11.8979 13.2923 12.02 13.0786C12.1458 12.8649 12.3122 12.6532 12.5194 12.4435C12.6933 12.2742 12.845 12.1129 12.9745 11.9597C13.104 11.8065 13.2057 11.6492 13.2797 11.4879C13.3537 11.3226 13.3907 11.1391 13.3907 10.9375C13.3907 10.7036 13.3574 10.5101 13.2908 10.3569C13.2279 10.1996 13.1317 10.0806 13.0022 10C12.8764 9.91935 12.7174 9.87903 12.525 9.87903C12.3659 9.87903 12.2179 9.91734 12.081 9.99395C11.9441 10.0665 11.8313 10.1794 11.7425 10.3327C11.6574 10.4859 11.613 10.6875 11.6093 10.9375H10C10.0111 10.3851 10.1276 9.92944 10.3496 9.57056C10.5753 9.20766 10.8768 8.93952 11.2542 8.76613C11.6315 8.58871 12.0551 8.5 12.525 8.5C13.0429 8.5 13.4869 8.59274 13.8568 8.77823C14.2268 8.95968 14.5098 9.22782 14.7059 9.58266C14.902 9.93347 15 10.3609 15 10.8649C15 11.2157 14.9371 11.5282 14.8113 11.8024C14.6855 12.0726 14.5209 12.3246 14.3174 12.5585C14.1139 12.7923 13.8901 13.0343 13.6459 13.2843C13.4351 13.4899 13.2908 13.7056 13.2131 13.9315C13.1391 14.1573 13.1003 14.4254 13.0966 14.7359ZM11.515 16.6048C11.515 16.3468 11.5964 16.1331 11.7592 15.9637C11.9219 15.7903 12.1402 15.7036 12.414 15.7036C12.6841 15.7036 12.9005 15.7903 13.0633 15.9637C13.2297 16.1331 13.313 16.3468 13.313 16.6048C13.313 16.8548 13.2297 17.0665 13.0633 17.2399C12.9005 17.4133 12.6841 17.5 12.414 17.5C12.1402 17.5 11.9219 17.4133 11.7592 17.2399C11.5964 17.0665 11.515 16.8548 11.515 16.6048Z"
              fill="currentColor"
            />
          </IndicatorIcon>
        ),
      };
  }
}

export function CopilotLibraryObjectStateIndicator({
  status,
  label,
  subtitle,
  dividerClassName,
  children,
}: {
  status: ObjectSearchStatus;
  label: string;
  subtitle?: string;
  dividerClassName?: string;
  children?: ReactNode;
}) {
  const { color, icon } = getIndicatorConfig(status);
  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      exit={{
        opacity: 0,
      }}
      transition={{
        duration: 0.5,
      }}
      className="flex flex-col items-center justify-between w-full gap-2 py-2"
    >
      <div className={clsx("flex items-center gap-1", color)}>
        {icon}
        <Typography className="font-bold text-2xl leading-normal">
          {label}
        </Typography>
      </div>
      <Typography className="text-white text-base leading-normal">
        {subtitle}
      </Typography>
      {children}
      <Divider
        className={clsx("border-[#2F2F2F] w-full pt-3", dividerClassName)}
      />
    </motion.div>
  );
}

export function CopilotLibraryAddedSuccessIndicator() {
  const [libraryParams] = useLibraryParams();
  const formattedLabel = startCase(camelCase(libraryParams.object || ""));

  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      exit={{
        opacity: 0,
      }}
      transition={{
        duration: 0.5,
      }}
      className="flex flex-col items-center justify-between w-full gap-2 py-2"
    >
      <div className={"flex items-center gap-1 text-[#3BB16A]"}>
        <CheckCircleOutlineIcon className="w-11 h-11" />
      </div>
      <Typography className="text-white text-base text-center leading-normal">
        {`${formattedLabel} has been added to your Custom Object Library.`}
      </Typography>
      <Divider className="border-[#2F2F2F] w-full pt-3" />
    </motion.div>
  );
}
