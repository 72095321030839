import gql from "graphql-tag";
import { keyBy } from "lodash/fp";
import { useParams } from "react-router-dom";

import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";

import {
  Camera,
  IntegrationDevicesQuery,
  useCreateIntegrationDeviceMutation,
  useIntegrationDeviceQuery,
  useIntegrationDevicesQuery,
  useUpdateIntegrationDeviceMutation,
} from "@/generated-models";

import { PLACEHOLDER_ID } from "../constant";
import { useCurrentIntegrationId } from "./integrationHooks";

export type IntegrationDevices = IntegrationDevicesQuery["integrationDevices"];
export type IntegrationDevice = IntegrationDevices[number];

export function useCurrentDeviceId() {
  const { deviceId: tempIntId } = useParams();
  return Number(tempIntId || null);
}

export function useCurrentIntegrationDevices(id?: number) {
  const integrationId = useCurrentIntegrationId();
  const resolvedId = id ?? integrationId;
  const { data, ...rest } = useIntegrationDevicesQuery({
    variables: {
      input: {
        integrationId: resolvedId,
      },
    },
    skip: !resolvedId,
  });

  const devices = data?.integrationDevices || [];
  const devicesMap = keyBy("id", devices);

  return {
    devices,
    devicesMap,
    count: devices.length || 0,
    ...rest,
  };
}

export function useIntegrationDeviceById() {
  const currentDeviceId = useCurrentDeviceId();
  const integrationId = useCurrentIntegrationId();
  const query = useIntegrationDeviceQuery({
    variables: {
      input: {
        integrationDeviceId: currentDeviceId,
        integrationId,
      },
    },
    skip: !currentDeviceId,
  });

  return {
    ...query,
    data: query.data?.integrationDevice,
  };
}

export function useActiveCameras() {
  const { data, loading } = useIntegrationDeviceById();
  const activeCams = (data?.cameras || []) as Camera[];
  const activeCamIds = activeCams.map((c) => c.id);

  return { activeCamIds, activeCams, loading };
}

export function useUpsertIntegrationDevice() {
  const integrationId = useCurrentIntegrationId();
  const { pushSnackbar } = useFeedback();

  const [
    createDevice,
    { loading: loadingCreateDevice },
  ] = useCreateIntegrationDeviceMutation({
    refetchQueries: ["integrationDevices"],
    onError: () =>
      pushSnackbar(
        "Unable to add the integration device, please try again.",
        FeedbackType.Error
      ),
  });

  const [
    updateDevice,
    { loading: loadingUpdateDevice },
  ] = useUpdateIntegrationDeviceMutation({
    refetchQueries: ["integrationDevices"],
    onError: () =>
      pushSnackbar(
        "Unable to update the integration device, please try again.",
        FeedbackType.Error
      ),
  });

  const loading = loadingCreateDevice || loadingUpdateDevice;

  return {
    loading,
    upsert: async (
      payload: Pick<IntegrationDevice, "name" | "cameraIds" | "tags">,
      integrationDeviceId?: number | null,
      onComplete?: (
        device?: Pick<IntegrationDevice, "id" | "name" | "cameraIds" | "tags">
      ) => void
    ) => {
      const input = {
        ...payload,
      };

      if (payload.cameraIds.length === 0) {
        pushSnackbar("Please link a camera to the device", FeedbackType.Error);
        throw new Error("Please link a camera to the device");
      }

      if (integrationDeviceId && integrationDeviceId > PLACEHOLDER_ID) {
        const { data, errors } = await updateDevice({
          refetchQueries: ["integrationDevices"],
          variables: {
            input: { ...input, integrationId, integrationDeviceId },
          },
        });

        if (errors) {
          throw new Error("Failed to update the integration");
        }

        onComplete?.(data?.updateIntegrationDevice);
      } else {
        const { data, errors } = await createDevice({
          variables: {
            input: { ...input, integrationId },
          },
        });

        if (errors) {
          throw new Error("Failed to update the integration");
        }

        onComplete?.(data?.createIntegrationDevice);
      }
    },
  };
}

gql`
  fragment IntegrationDeviceFragment on IntegrationDevice {
    id
    integrationId
    name
    isDeleted
    tags
    created
    updated

    cameraIds

    cameras {
      id
      name
      status
      still
      vendor
      feeds {
        tunnel
        local
        webRTC
      }
      health {
        cameraOnline
        applianceOnline
      }
      location {
        id
        name
        timezone
      }
      device {
        id
        onvifSupported
      }
    }
  }
`;

gql`
  mutation deleteIntegrationV2($id: Int!) {
    deleteIntegrationV2(id: $id) {
      message
    }
  }
`;

gql`
  mutation createIntegrationDevice($input: CreateIntegrationDeviceInput!) {
    createIntegrationDevice(input: $input) {
      ...IntegrationDeviceFragment
    }
  }
`;

gql`
  mutation createIntegrationDevices($input: CreateIntegrationDevicesInput!) {
    createIntegrationDevices(input: $input) {
      ...IntegrationDeviceFragment
    }
  }
`;

gql`
  mutation updateIntegrationDevice($input: UpdateIntegrationDeviceInput!) {
    updateIntegrationDevice(input: $input) {
      ...IntegrationDeviceFragment
    }
  }
`;

gql`
  mutation deleteIntegrationDevice($input: DeleteIntegrationDeviceInput!) {
    deleteIntegrationDevice(input: $input) {
      message
    }
  }
`;

gql`
  query integrationDevices($input: IntegrationDevicesInput!) {
    integrationDevices(input: $input) {
      ...IntegrationDeviceFragment
    }
  }
`;

gql`
  query integrationDevice($input: GetIntegrationDeviceByIdInput!) {
    integrationDevice(input: $input) {
      ...IntegrationDeviceFragment
    }
  }
`;

gql`
  query integrationDeviceTags($integrationId: Int) {
    integrationDeviceTags(integrationId: $integrationId)
  }
`;
