import HelpIcon from "@mui/icons-material/Help";
import { IconButton, Popover } from "@mui/material";
import React, { useState } from "react";

interface HelpPopoverProps {
  children: React.ReactNode;
  className?: string;
}

export function HelpPopover({ children, className }: HelpPopoverProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  return (
    <>
      <IconButton
        className={className}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <HelpIcon />
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <div className="p-2">{children}</div>
      </Popover>
    </>
  );
}
