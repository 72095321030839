import React, { ChangeEvent, useMemo } from "react";
import { useController, useFormContext } from "react-hook-form";

import { CamDrawer } from "@/components/CamDrawer/CamDrawerBase";
import { CamWrapperProps } from "@/components/CamDrawer/CamDrawerList";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";

import { useCamDrawerBaseQuery } from "@/generated-models";

export function CustomDeviceCamerasDrawer({
  open,
  path,
  onOpen,
  onClose,
}: {
  open: boolean;
  path: string;
  onOpen: () => void;
  onClose: () => void;
}) {
  const { trigger } = useFormContext();
  const {
    field: { value: cameraIds, onChange: setSelectedCameras },
  } = useController({
    name: `${path}.cameraIds`,
  });

  const { data, loading } = useCamDrawerBaseQuery();

  const cameras = useMemo(() => {
    return data?.cameras.map((c) => {
      const selected = cameraIds.includes(c.id);
      const disabled = cameraIds.length === 4 && !cameraIds.includes(c.id);
      return { ...c, selected, disabled };
    });
  }, [data, cameraIds]);

  const selectedLocation = useMemo(() => {
    const firstSelected = cameras?.find((c) => c.selected);
    return firstSelected?.location?.id;
  }, [cameras]);

  const filteredCameras = useMemo(() => {
    if (!selectedLocation) return cameras;
    return cameras?.filter((c) => c.location.id === selectedLocation);
  }, [cameras, selectedLocation]);

  function CamWrapper({ cam, children }: CamWrapperProps) {
    return (
      <label>
        <input
          type="radio"
          id="css"
          name="cameraId"
          value={cam.id}
          className="absolute opacity-0 w-0 h-0"
          disabled={
            cam.disabled ||
            (!!selectedLocation && selectedLocation !== cam.location.id) ||
            (cameraIds.length === 4 && !cameraIds.includes(cam.id))
          }
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (!cam.selected) {
              setSelectedCameras([...cameraIds, cam.id]);
            } else {
              setSelectedCameras(
                cameraIds.filter((id: number) => id !== cam.id)
              );
            }
            trigger();
          }}
        />
        {children}
      </label>
    );
  }

  let camDrawerList: React.ReactNode;
  if (loading) {
    camDrawerList = <Loading>Fetching Cams</Loading>;
  } else if (filteredCameras) {
    camDrawerList = (
      <CamDrawer.List cameras={filteredCameras} CamWrapper={CamWrapper} />
    );
  } else {
    camDrawerList = (
      <ErrorMessage
        title="Oops..."
        description="Something went wrong loading cameras. Please try again later."
      />
    );
  }

  return (
    <>
      <CamDrawer open={open} onOpen={onOpen} onClose={onClose}>
        <CamDrawer.Filters className="!pt-6">
          <CamDrawer.GroupSelector />
          <CamDrawer.SearchBox placeholder="Search Cameras" fullWidth />
          <div className="bg-[#353D48] text-white text-center py-[7px] rounded">
            {cameraIds.length === 4
              ? "Max cameras added"
              : "You can add up to four cameras from the same location."}
          </div>
        </CamDrawer.Filters>
        {camDrawerList}
      </CamDrawer>
    </>
  );
}
