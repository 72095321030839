import { Container, Typography } from "@mui/material";
import { StringParam, useQueryParam } from "use-query-params";

import { IntelligenceHeader } from "./Header/IntelligenceHeader";
import {
  IntelligenceInsightConfig,
  IntelligenceInsightType,
  useIntelligenceInsightConfig,
} from "./constants";

function IntelligenceInsightCard({
  type,
  config,
}: {
  type: IntelligenceInsightType;
  config: IntelligenceInsightConfig;
}) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setPromptCreate] = useQueryParam("promptCreate", StringParam);

  return (
    <button
      type="button"
      className="bg-white rounded-lg p-4 shadow-[0_6px_14px_rgba(0,42,82,0.1)] flex flex-col gap-4 justify-center items-center"
      onClick={() => {
        setPromptCreate(type);
      }}
    >
      <div
        style={{ background: config.colorPrompt }}
        className="h-[172px] w-full flex items-center justify-center"
      >
        <img
          className="block"
          alt={`${config.label} illustration`}
          src={config.illustrationPrompt}
        />
      </div>
      <div className="flex flex-col gap-1">
        <Typography
          style={{ color: config.colorBase }}
          className="font-bold text-xl leading-[23px] text-center"
        >
          {config.labelEmpty}
        </Typography>
        <Typography className="text-sm leading-4 text-center h-16 max-w-[215px]">
          {config.labelPrompt}
        </Typography>
      </div>
      <div
        className="w-full rounded text-white text-sm leading-4 px-2 py-3 font-bold mt-2"
        style={{ background: config.colorBase }}
        onClick={() => {
          setPromptCreate(type);
        }}
      >
        Get Started
      </div>
    </button>
  );
}

export function IntelligenceEmptyState() {
  const config = useIntelligenceInsightConfig();

  return (
    <Container maxWidth="xl" className="flex flex-col gap-4 p-0">
      <IntelligenceHeader
        className="!lg:justify-center !justify-center lg:ml-0 ml-[-54px]"
        subtitleClassName="-ml-1 !text-[21px] !leading-[25px]"
      />
      <div className="grid gap-5 sm:gap-4 grid-cols-1 sm:grid-cols-[repeat(auto-fit,_minmax(180px,290px))] justify-center px-8 sm:px-12">
        {Object.entries(config)
          .filter(
            ([type, config]) =>
              !config.hidden &&
              (type as IntelligenceInsightType) !==
                IntelligenceInsightType.Comparative
          )
          .map(([type, config]) => (
            <IntelligenceInsightCard
              key={config.label}
              type={type as IntelligenceInsightType}
              config={config}
            />
          ))}
      </div>
    </Container>
  );
}
