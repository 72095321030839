import clsx from "clsx";
import { ReactNode } from "react";

interface SchemaActionButtonProps {
  large?: boolean;
  onClick?: () => void;
  children: ReactNode;
}

export function SchemaActionButton({
  large,
  onClick,
  children,
}: SchemaActionButtonProps) {
  return (
    <button
      onClick={onClick}
      type="button"
      className={clsx("bg-transparent text-primary", {
        "text-lg": large,
        "text-base": !large,
      })}
    >
      {children}
    </button>
  );
}
