import {
  ChevronLeft,
  ChevronRight,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import CircleIcon from "@mui/icons-material/Circle";
import { Divider, IconButton, Typography } from "@mui/material";
import { utcToZonedTime } from "date-fns-tz";
import {
  startOfWeek,
  endOfWeek,
  format,
  addDays,
  subWeeks,
  subDays,
} from "date-fns/fp";
import { useMemo, useState } from "react";

import { formatDurationSecs } from "@/util/formatDurationSecs";
import { useBreakpoints } from "@/util/useBreakpoints";

import { useSearchRangeParams } from "@/pages/Search/searchHooks";

import { IntegrationEvent, IntegrationTypeKey } from "@/generated-models";

import {
  useCurrentVendor,
  useIntegrationEvents,
  useSourceById,
} from "../../hooks";

const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export function IntegrationsDetailsDeviceWeeklySummary() {
  const vendor = useCurrentVendor();

  switch (vendor?.type?.key) {
    case IntegrationTypeKey.IoBoard:
      return <IOBoardWeeklySummary />;
    default:
      return <></>;
  }
}

// Determines total online and offline time based on event data.
function useGetEventsBreakdown(
  events: any[],
  startDay: Date,
  endDay: Date,
  slotIdx?: number
) {
  return useMemo(() => {
    let totalOffCount = 0;
    let totalOffDuration = 0;
    const dayEvents: Record<number, IntegrationEvent[]> = {};
    const result: Record<
      number,
      {
        on: number;
        off: number;
        onDuration: number;
        offDuration: number;
      }
    > = {};

    const sortedEvents = events.sort((a, b) => a.ts - b.ts);
    const backEnabled = sortedEvents[0]?.ts <= subWeeks(1, startDay).getTime();
    const nextEnabled =
      sortedEvents[sortedEvents.length - 1]?.ts >= subDays(1, endDay).getTime();

    sortedEvents
      .filter(
        (a) =>
          a.ts >= startDay.getTime() &&
          a.ts <= endDay.getTime() &&
          a.standardMeta.slotIdx === slotIdx
      )
      .forEach((e) => {
        const date = new Date(e.ts);
        const { standardMeta } = e;
        const { value, normallyClosed } = standardMeta;

        const day = date.getDay();

        const dayEventList = dayEvents[day] || [];

        const on = normallyClosed ? !value : value;
        const resultDuration =
          e.ts - (dayEventList[dayEventList.length - 1]?.ts ?? 0);
        const currentResult = result[day] ?? {
          on: 0,
          off: 0,
          onDuration: 0,
          offDuration: 0,
        };

        if (dayEvents[day]) {
          dayEvents[day].push(e);

          if (on) {
            currentResult.offDuration += resultDuration;
            totalOffDuration += resultDuration;
          } else {
            currentResult.onDuration += resultDuration;
          }
        } else {
          dayEvents[day] = [e];
        }

        if (on) {
          currentResult.on++;
        } else {
          currentResult.off++;
          totalOffCount++;
        }

        result[day] = currentResult;
      });

    return {
      dayEvents,
      breakdown: result,
      totalOffCount,
      totalOffDuration,
      backEnabled,
      nextEnabled,
    };
  }, [endDay, events, slotIdx, startDay]);
}

const today = new Date();
function IOBoardWeeklySummary() {
  const [offset, setOffset] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const { data, loading } = useSourceById();
  const { rangeStart, rangeEnd } = useSearchRangeParams();
  const { fitsDesktop } = useBreakpoints();

  const timezone = data?.cameras[0]?.location?.timezone || "";

  const startDay = utcToZonedTime(
    startOfWeek(subWeeks(offset, today)),
    timezone
  );
  const endDay = utcToZonedTime(endOfWeek(subWeeks(offset, today)), timezone);

  const { data: events, loading: eventsLoading } = useIntegrationEvents(
    undefined,
    {
      startTime: rangeStart.toISOString(),
      endTime: rangeEnd.toISOString(),
      query: {},
    },
    loading
  );

  const {
    breakdown,
    totalOffCount,
    totalOffDuration,
    backEnabled,
    nextEnabled,
  } = useGetEventsBreakdown(
    events,
    startDay,
    endDay,
    data?.standardMeta?.slotIdx
  );

  if (loading || !fitsDesktop) return <></>;

  return (
    <div className="flex flex-col gap-6">
      <div className="flex justify-between items-center px-4 pt-5 w-full">
        <div className="flex items-center justify-start gap-2">
          <Typography className="font-bold text-2xl leading-[28px]">
            Weekly Summary - {format("MMM d", startDay)} -{" "}
            {format("MMM d", endDay)}
          </Typography>
          <IconButton
            className="p-0"
            color="primary"
            onClick={() => {
              setExpanded((v) => !v);
            }}
          >
            {expanded ? (
              <ExpandLess fontSize="large" />
            ) : (
              <ExpandMore fontSize="large" />
            )}
          </IconButton>
        </div>
        {!eventsLoading && (
          <Typography className="text-lg leading-[21px]">
            Conveyor Stopped for{" "}
            <strong>
              {formatDurationSecs(Math.round(totalOffDuration / 1000))}
            </strong>{" "}
            from <strong>{totalOffCount}</strong> Events{" "}
          </Typography>
        )}
      </div>
      {expanded && (
        <div className="flex items-center w-full justify-between">
          <IconButton
            className="p-0 mt-[34px]"
            color="primary"
            disabled={!backEnabled}
            onClick={() => {
              setOffset((o) => o + 1);
            }}
          >
            <ChevronLeft fontSize="large" />
          </IconButton>
          <div className="relative w-full">
            <div className="flex items-center justify-around flex-1">
              {weekDays.map((day, idx) => {
                const date = addDays(idx, startDay);
                const info = breakdown[idx];
                return (
                  <div className="flex flex-col items-center" key={day}>
                    <Typography className="text-primary">
                      <strong className="text-base leading-[18.75px]">
                        {info?.off || 0}
                      </strong>{" "}
                      <span className="text-sm leading-4">Events</span>
                    </Typography>
                    <Typography className="text-[#FF490F]">
                      <strong className="text-base leading-[18.75px]">
                        {formatDurationSecs(
                          Math.round((info?.offDuration || 0) / 1000)
                        )}
                      </strong>{" "}
                      <span className="text-sm leading-4">Off</span>
                    </Typography>
                    <div className="flex flex-col gap-[9px] items-center">
                      <div />
                      <CircleIcon className="text-primary w-[5px] h-[5px]" />
                      <Typography className="text-xs leading-[14px]">
                        {format("eee, MMM d", date)}
                      </Typography>
                    </div>
                  </div>
                );
              })}
            </div>
            <Divider
              className="w-full absolute top-[59px]"
              orientation="horizontal"
            />
          </div>
          <IconButton
            className="p-0 mt-[34px]"
            color="primary"
            disabled={!nextEnabled}
            onClick={() => {
              setOffset((o) => o - 1);
            }}
          >
            <ChevronRight fontSize="large" />
          </IconButton>
        </div>
      )}
    </div>
  );
}
