import clsx from "clsx";
import gql from "graphql-tag";
import { omit } from "lodash/fp";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

import {
  AccessControlIcon,
  EnvironmentSensorIcon,
  IOBoardIcon,
  IntercomIcon,
} from "@/icons/Icons";

import {
  Camera,
  IntegrationEvent,
  IntegrationEventsInput,
  IntegrationSource,
  IntegrationTypeKey,
  useIntegrationEventsQuery,
  useIntegrationSetupStateQuery,
  useIntegrationSourcesQuery,
  useIntegrationVendorsQuery,
} from "@/generated-models";

import { IOBoardStatus } from "./constant";

export type IntegrationEventData = IntegrationEvent &
  Omit<IntegrationSource, "events" | "standardMeta" | "__typename"> & {
    sourceStandardMeta?: IntegrationSource["standardMeta"];
  };

export function IntegrationTypeIcon({
  typeId,
}: {
  typeId: IntegrationTypeKey;
}) {
  const Icon = useIntegrationTypeIcon(typeId);

  return (
    <Icon
      className={clsx({
        "w-10 h-10":
          typeId === IntegrationTypeKey.AccessControl ||
          typeId === IntegrationTypeKey.Intercom,
        "w-10 h-7":
          typeId === IntegrationTypeKey.IoBoard ||
          typeId === IntegrationTypeKey.EnvironmentSensor,
      })}
    />
  );
}

export function useIntegrationTypeIcon(typeId?: IntegrationTypeKey) {
  const currentTypeId = useCurrentTypeId();
  const resolvedTypeId = typeId ?? currentTypeId;

  switch (resolvedTypeId) {
    case IntegrationTypeKey.EnvironmentSensor:
      return EnvironmentSensorIcon;
    case IntegrationTypeKey.IoBoard:
      return IOBoardIcon;
    case IntegrationTypeKey.Intercom:
      return IntercomIcon;
    default:
      return AccessControlIcon;
  }
}

export function useCurrentIntegrationId() {
  const { integrationId: tempIntId } = useParams();
  return Number(tempIntId || null);
}

export function useCurrentSourceId() {
  const { sourceId: tempIntId } = useParams();
  return Number(tempIntId || null);
}

export type GridIntegrationEvent = ReturnType<
  typeof useIntegrationEvents
>["data"][number];

export function useIntegrationEvents(
  sourceId?: number,
  eventInput?: IntegrationEventsInput,
  skip?: boolean
) {
  const id = useCurrentIntegrationId();
  const { data, loading, error } = useIntegrationEventsQuery({
    variables: { input: { id }, eventInput },
    skip,
  });

  return useMemo(() => {
    const sources = data?.integration?.sources ?? [];

    const filteredSources = sourceId
      ? sources.filter((s) => s.id === sourceId)
      : sources;

    return {
      data: filteredSources.flatMap((s) => {
        return s.events.map((e) => ({
          ...omit(["events", "standardMeta"], s),
          sourceStandardMeta: { ...s.standardMeta },
          ...e,
        }));
      }),
      loading,
      error,
    };
  }, [data, loading, error, sourceId]);
}

export function useIntegrationSetupState(id: number, skip?: boolean) {
  const query = useIntegrationSetupStateQuery({
    errorPolicy: "all",
    variables: {
      input: {
        id,
      },
    },
    skip,
  });

  const setupState = query.data?.integration?.setupState;
  const boardStatus = setupState?.boardStatus?.status;

  return {
    ...query,
    setupState,
    online: !!boardStatus
      ? boardStatus === IOBoardStatus.Connected
      : !!setupState,
  };
}

export function useCurrentTypeId() {
  const { typeId } = useParams<{ typeId: IntegrationTypeKey }>();

  return useMemo(() => {
    return (
      Object.values(IntegrationTypeKey).find(
        (currTypeId) => currTypeId.toLowerCase() === typeId?.toLowerCase()
      ) || IntegrationTypeKey.AccessControl
    );
  }, [typeId]);
}

export function useCurrentVendor() {
  const { vendorKey } = useParams();
  const { data } = useIntegrationVendorsQuery();

  return useMemo(
    () =>
      data?.integrationVendors.find((m) => m.key.toLowerCase() === vendorKey),
    [data?.integrationVendors, vendorKey]
  );
}

export function useSourceById(sourceId?: number) {
  const { integrationId } = useParams();
  const currentSourceId = useCurrentSourceId();

  const resolvedId = sourceId || currentSourceId;

  const query = useIntegrationSourcesQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        id: Number(integrationId),
      },
    },
    skip: !integrationId,
  });

  const data = useMemo(
    () => query.data?.integrationSources?.find((s) => s.id === resolvedId),
    [query.data?.integrationSources, resolvedId]
  );

  return { ...query, data };
}

export function useActiveCameras() {
  const { data, loading } = useSourceById();
  const activeCams = (data?.cameras || []) as Camera[];
  const activeCamIds = activeCams.map((c) => c.id);

  return { activeCamIds, activeCams, loading };
}

gql`
  fragment IntegrationVendorMetadataFragment on IntegrationVendor {
    id
    key
    name
    type {
      id
      name
      key
    }
    description
    logoSrc
  }
`;

gql`
  fragment IntegrationVendorFragment on IntegrationVendor {
    id
    key
    name
    type {
      id
      name
      key
    }
    instructions
    description
    logoSrc
    setupSchema
    sourceMetaSchema
    eventMetaSchema
    authInfo {
      authType
      authorizationUrl
    }
  }
`;

gql`
  fragment IntegrationFragment on Integration {
    id
    vendorId
    vendorKey
    name
    profileId
  }
`;

gql`
  fragment IntegrationSourceFragment on IntegrationSource {
    id
    integrationId
    externalId
    standardMeta

    tags

    cameras {
      id
      name
      status
      still
      vendor
      feeds {
        tunnel
        local
        webRTC
      }
      health {
        cameraOnline
        applianceOnline
      }
      location {
        id
        name
        timezone
      }
      device {
        id
        onvifSupported
      }
      intercom {
        id
      }
    }
  }
`;

gql`
  query integrationVendors {
    integrationVendors {
      ...IntegrationVendorFragment
    }
  }
`;

gql`
  query page_integrations {
    integrations {
      ...IntegrationFragment

      vendor {
        ...IntegrationVendorMetadataFragment
      }
    }
  }
`;

gql`
  query integrationMetadata($input: IntegrationsInput!) {
    integration(input: $input) {
      ...IntegrationFragment

      initialState
    }
  }
`;

export const INTEGRATION_SETUP_STATE_QUERY = gql`
  query integrationSetupState($input: IntegrationsInput!) {
    integration(input: $input) {
      id
      setupState
      initialState
    }
  }
`;

gql`
  query integration($input: IntegrationsInput!) {
    integration(input: $input) {
      ...IntegrationFragment

      sources {
        ...IntegrationSourceFragment
      }

      vendor {
        ...IntegrationVendorFragment
      }
    }
  }
`;

gql`
  query integrationSources($input: IntegrationSourcesInput) {
    integrationSources(input: $input) {
      ...IntegrationSourceFragment
    }
  }
`;

gql`
  mutation addIntegration($input: AddIntegrationInput!) {
    addIntegration(input: $input) {
      ...IntegrationFragment
    }
  }
`;

gql`
  mutation setupIntegration($input: SetupIntegrationInput!) {
    setupIntegration(input: $input) {
      success
    }
  }
`;

gql`
  mutation updateIntegrationSource($input: UpdateIntegrationSourceInput!) {
    updateIntegrationSource(input: $input) {
      ...IntegrationSourceFragment
    }
  }
`;

gql`
  mutation deleteIntegration($id: Int!) {
    deleteIntegration(id: $id) {
      message
    }
  }
`;

gql`
  mutation updateIntegration($input: UpdateIntegrationInput!) {
    updateIntegration(input: $input) {
      ...IntegrationFragment
    }
  }
`;

gql`
  mutation suggestIntegration($input: IntegrationSuggestionInput!) {
    suggestIntegration(input: $input) {
      message
    }
  }
`;

gql`
  query integrationEvents(
    $input: IntegrationsInput!
    $eventInput: IntegrationEventsInput
  ) {
    integration(input: $input) {
      ...IntegrationFragment

      sources {
        ...IntegrationSourceFragment
        events(input: $eventInput) {
          ts
          standardMeta
        }
      }

      vendor {
        ...IntegrationVendorFragment
      }
    }
  }
`;
