import { useEffect, useRef, useState } from "react";

const CHARS = "!@#$%^&*():{};|,.<>/?";

export function DecodedText({
  label,
  duration = 10,
  delay = 0,
  cycles = 4,
}: {
  label: string;
  duration?: number;
  delay?: number;
  cycles?: number;
}) {
  const intervalRef = useRef<any | null>(null);
  const [value, setValue] = useState(label);

  const target = label;

  const scramble = () => {
    let position = 0;

    intervalRef.current = setInterval(() => {
      const scrambled = target
        .split("")
        .map((char, index) => {
          if (position / cycles > index) {
            return char;
          }

          const randomCharIndex = Math.floor(Math.random() * CHARS.length);
          const randomChar = CHARS[randomCharIndex];

          return randomChar;
        })
        .join("");

      setValue(scrambled);
      position++;

      if (position >= target.length * cycles) {
        stopScramble();
      }
    }, duration);
  };

  const stopScramble = () => {
    clearInterval(intervalRef.current || undefined);

    setValue(target);
  };

  useEffect(() => {
    clearInterval(intervalRef.current || undefined);
    setTimeout(() => {
      scramble();
    }, delay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [label, delay]);

  return <>{value}</>;
}
