import CastIcon from "@mui/icons-material/Cast";
import CastConnectedIcon from "@mui/icons-material/CastConnected";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import EditIcon from "@mui/icons-material/Edit";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Popover,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import gql from "graphql-tag";
import { useEffect, useRef, useState } from "react";
import TimeAgo from "react-timeago";
import { makeStyles } from "tss-react/mui";
import { BooleanParam, useQueryParam } from "use-query-params";

import { SpotcastLight } from "@/icons/Spotcast";

import KioskCodeInput from "@/pages/VideoWall/Kiosk/KioskCodeInput";

import HelpPopover from "@/components/HelpPopover";
import { Loading } from "@/components/Loading";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";

import {
  KiosksQuery,
  RotatingVideoWall,
  useCastWallToKioskMutation,
  useKiosksQuery,
  useRemoveKioskMutation,
  useRenameKioskMutation,
  VideoWall,
} from "@/generated-models";
import { CERULEAN_BLUE } from "@/layout/theme";

const useStyles = makeStyles()((theme) => ({
  popover: {
    minWidth: 370,
    borderRadius: 12,
  },
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    borderRadius: 12,
  },
  baseText: {
    opacity: 0.67,
    color: "#353D48",
    fontWeight: "bold",
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
    transition: "all 0.2s ease-in-out",
  },
  casted: {
    backgroundColor: theme.palette.primary.main,
    opacity: 1.0,
  },
  bold: {
    color: theme.palette.primary.main,
    opacity: 1.0,
  },
  transitionAll: {
    transition: "all 0.2s ease-in-out",
  },
  listItem: {
    borderTop: "0.5px solid rgba(0,0,0,0.1)",

    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  listItemIcon: { minWidth: "inherit", marginRight: 10 },
  listItemSecondaryText: {
    marginTop: -4, //Nudge it up
    fontSize: 12,
    opacity: 0.5,
    color: "#000000",
  },
  directions: {
    width: "100%",
    textAlign: "center",
    color: CERULEAN_BLUE,
    fontWeight: "bold",
  },
  secondaryCasted: {
    opacity: 1,
    color: "#353D48",
  },
  input: {
    fontSize: 17,
    marginBottom: 7,
  },
  titleText: {
    fontSize: 17,
    color: theme.palette.primary.main,
    transition: "all 0.1s ease-in-out",
    opacity: 0,
    marginLeft: 5,
    marginBottom: 3, // Nudge it up
  },
  visible: {
    opacity: 1,
  },
}));

interface KioskCastButtonProps {
  wallId?: number;
  rotatingWallId?: number;
  className?: string;
}
export default function KioskCastButton({
  wallId,
  rotatingWallId,
  className,
}: KioskCastButtonProps) {
  const { classes } = useStyles();
  const theme = useTheme();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [spotcastRegistration, setSpotcastRegistration] = useQueryParam(
    "registerSpotcast",
    BooleanParam
  );
  const [open, setOpen] = useState(!!spotcastRegistration);
  const [codeDialogOpen, setCodeDialogOpen] = useState(!!spotcastRegistration);
  const feedback = useFeedback();
  const [castKiosk] = useCastWallToKioskMutation({
    refetchQueries: ["kiosks"],
    awaitRefetchQueries: true,
  });
  const [removeKiosk] = useRemoveKioskMutation({
    onCompleted: () => {
      feedback.pushSnackbar("Removed kiosk successfully", FeedbackType.Success);
    },
    onError: () => {
      feedback.pushSnackbar(
        "Unknown error when removing kiosk",
        FeedbackType.Error
      );
    },
    refetchQueries: ["kiosks"],
    awaitRefetchQueries: true,
  });
  const [renameKiosk] = useRenameKioskMutation({
    onCompleted: () => {
      feedback.pushSnackbar("Renamed kiosk successfully", FeedbackType.Success);
    },
    onError: () => {
      feedback.pushSnackbar(
        "Unknown error when renaming kiosk",
        FeedbackType.Error
      );
    },
    refetchQueries: ["kiosks"],
    awaitRefetchQueries: true,
  });
  useEffect(() => {
    setSpotcastRegistration(undefined);
  }, [setSpotcastRegistration]);

  const { data, loading } = useKiosksQuery({
    pollInterval: 10000,
    fetchPolicy: "cache-and-network",
    skip: !open,
  });

  return (
    <>
      <Button
        size="large"
        className={className}
        ref={buttonRef}
        onClick={() => setOpen(true)}
      >
        <SpotcastLight width="80" />
      </Button>
      <Popover
        open={open}
        anchorEl={buttonRef.current}
        onClose={() => {
          setOpen(false);
          setCodeDialogOpen(false);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{ paper: classes.popover }}
      >
        {data ? (
          <List className={classes.root} subheader={<li />}>
            <li className={classes.listSection}>
              <AnimatePresence>
                <ul className={classes.ul}>
                  {(data.kiosks.length === 0 || codeDialogOpen) && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        p={theme.spacing(2, 2)}
                      >
                        <Button
                          onClick={() => setCodeDialogOpen(false)}
                          style={{
                            display:
                              data.kiosks.length === 0 ? "none" : undefined,
                            position: "absolute",
                            top: 16,
                            left: 12,
                            opacity: 0.7,
                          }}
                          startIcon={<ChevronLeftIcon />}
                        >
                          Back
                        </Button>
                        <KioskCodeInput
                          onSubmitCode={() => setCodeDialogOpen(false)}
                        />
                      </Box>
                    </motion.div>
                  )}
                  {!(data.kiosks.length === 0 || codeDialogOpen) && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    >
                      <div>
                        <ListSubheader>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            my={2}
                            px={1.5}
                          >
                            <SpotcastLight width="120" />
                            <Box m={0.5} />
                            <HelpPopover title="Spotcast Help">
                              <Typography variant="body2">
                                Spotcast helps turn any screen into a video
                                wall. Press "Add Kiosk" on the right to setup a
                                new kiosk.
                              </Typography>
                            </HelpPopover>
                            <Box flexGrow={1} />
                            <Button
                              size="small"
                              color="primary"
                              onClick={() => setCodeDialogOpen(true)}
                              style={{ fontWeight: 400 }}
                            >
                              + Add Kiosk
                            </Button>
                          </Box>
                        </ListSubheader>
                        <ListItem
                          className={classes.listItem}
                          style={{ background: "#E6F2FC" }}
                        >
                          <Typography
                            variant="body2"
                            className={classes.directions}
                          >
                            {wallId === undefined ? (
                              <strong>Open a wall to spotcast</strong>
                            ) : (
                              <strong>Select a kiosk to spotcast</strong>
                            )}
                          </Typography>
                        </ListItem>
                        {data.kiosks.map(
                          ({ videoWall, name, lastSeen, id }, idx) => {
                            const castAction = async () => {
                              await castKiosk({
                                variables: {
                                  input: {
                                    kioskId: id,
                                    wallId:
                                      videoWall?.__typename === "VideoWall" &&
                                      videoWall.id === wallId
                                        ? undefined
                                        : wallId,
                                    rotatingWallId:
                                      videoWall?.__typename ===
                                        "RotatingVideoWall" &&
                                      videoWall.id === rotatingWallId
                                        ? undefined
                                        : rotatingWallId,
                                  },
                                },
                                // refetchQueries: ["kiosks"],
                              }).then(
                                () =>
                                  feedback.pushSnackbar(
                                    determineIsActive(
                                      videoWall,
                                      wallId,
                                      rotatingWallId
                                    )
                                      ? "Removed spotcast successfully"
                                      : "Spotcasted successfully",
                                    FeedbackType.Success
                                  ),
                                () =>
                                  feedback.pushSnackbar(
                                    "Unknown error when spotcasting kiosk",
                                    FeedbackType.Error
                                  )
                              );
                            };

                            return (
                              <KioskListItem
                                key={idx}
                                wall={videoWall}
                                onClickCast={castAction}
                                name={name}
                                wallId={wallId}
                                rotatingWallId={rotatingWallId}
                                lastSeen={lastSeen}
                                loading={loading}
                                onChangeName={(newName) => {
                                  if (newName !== name) {
                                    renameKiosk({
                                      variables: {
                                        input: { newName, kioskId: id },
                                      },
                                      refetchQueries: ["kiosks"],
                                      awaitRefetchQueries: true,
                                    });
                                  }
                                }}
                                onRemove={() => {
                                  removeKiosk({
                                    variables: {
                                      id,
                                    },
                                    refetchQueries: ["kiosks"],
                                    awaitRefetchQueries: true,
                                  });
                                }}
                              />
                            );
                          }
                        )}
                      </div>
                    </motion.div>
                  )}
                </ul>
              </AnimatePresence>
            </li>
          </List>
        ) : (
          <Loading className="m-4" />
        )}
      </Popover>
    </>
  );
}

function determineIsActive(
  wall: KiosksQuery["kiosks"][number]["videoWall"],
  wallId?: number,
  rotatingWallId?: number
) {
  return Boolean(
    wall &&
      wall.id === (wall.__typename === "VideoWall" ? wallId : rotatingWallId)
  );
}

function KioskListItem({
  wall,
  wallId,
  rotatingWallId,
  name,
  onClickCast,
  lastSeen,
  onRemove,
  onChangeName,
  loading,
}: {
  wall?: Pick<
    VideoWall | RotatingVideoWall,
    "__typename" | "id" | "name"
  > | null;
  // | Pick<RotatingVideoWall, "__typename" | "id" | "name">
  name?: string | null;
  onClickCast: () => void;
  onRemove: () => void;
  onChangeName: (newName: string) => void;
  wallId?: number;
  rotatingWallId?: number;
  lastSeen: string;
  loading?: boolean;
}) {
  const { classes } = useStyles();
  const [hovered, setHovered] = useState(false);
  const [renameOn, setRenameOn] = useState(false);
  const [renameValue, setRenameValue] = useState(name || "");

  useEffect(() => {
    setHovered(false);
  }, [renameOn]);

  useEffect(() => {
    setRenameOn(false);
  }, [name, setRenameOn]);

  return (
    <Tooltip
      title={
        !hovered && !renameOn
          ? `${
              !wall
                ? "Cast to"
                : wall?.id !== wallId
                ? "Switch cast to"
                : "Uncast"
            } "${name?.trim()}"`
          : ""
      }
      arrow
    >
      <ListItem
        style={{
          opacity: wall?.id !== wallId ? 0.9 : 1,
        }}
        button
        onClick={() => {
          if (!renameOn) {
            onClickCast();
          }
        }}
        disableRipple={renameOn}
        className={classes.listItem}
        disabled={!wallId && !rotatingWallId}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <Avatar
            className={clsx(classes.transitionAll, classes.baseText, {
              [classes.casted]: determineIsActive(wall, wallId, rotatingWallId),
            })}
          >
            {wall?.id === wallId ? (
              <CastConnectedIcon style={{ color: "white" }} />
            ) : (
              <CastIcon style={{ color: "white" }} />
            )}
          </Avatar>
        </ListItemIcon>
        <ListItemText
          classes={{
            primary: clsx(classes.transitionAll, classes.baseText, {
              [classes.bold]: !!wall,
            }),
            secondary: clsx(classes.listItemSecondaryText, {
              [classes.secondaryCasted]: !!wall,
            }),
          }}
          primary={
            <Box display="flex" alignItems="center">
              {renameOn ? (
                <TextField
                  className={classes.input}
                  size="small"
                  autoFocus
                  value={renameValue}
                  onChange={(e) => setRenameValue(e.target.value)}
                  onBlur={() => {
                    onChangeName(renameValue);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      onChangeName(renameValue);
                    }
                  }}
                />
              ) : (
                <Box
                  style={{ cursor: "text" }}
                  onMouseOver={() => setHovered(true)}
                  onMouseLeave={() => setHovered(false)}
                  onClick={(e) => {
                    e.stopPropagation();
                    setRenameValue(name || "Untitled Kiosk");
                    setRenameOn(true);
                  }}
                  onMouseEnter={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Tooltip title="Rename" arrow>
                    <Box display="flex" alignItems="center">
                      {name}{" "}
                      <EditIcon
                        className={classes.titleText}
                        style={{
                          opacity: hovered ? 1 : 0,
                        }}
                      />
                    </Box>
                  </Tooltip>
                </Box>
              )}
            </Box>
          }
          secondary={
            wall?.id === wallId || !wall ? (
              <>
                Last online <TimeAgo date={lastSeen} />
              </>
            ) : (
              <>Casting wall: {wall.name}</>
            )
          }
        />

        <ListItemSecondaryAction>
          <Tooltip title="Remove kiosk">
            <IconButton onClick={() => onRemove()} size="large">
              <RemoveCircleOutlineIcon
                style={{ opacity: 0.3, color: "black" }}
                fontSize={"small"}
              />
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      </ListItem>
    </Tooltip>
  );
}

export const GET_KIOSKS = gql`
  query kiosks {
    kiosks {
      id
      name
      lastSeen
      videoWall {
        ... on BaseWall {
          id
          name
        }
      }
    }
  }
`;

gql`
  mutation castWallToKiosk($input: CastWallToKioskInput!) {
    castWallToKiosk(input: $input) {
      id
      videoWall {
        ... on BaseWall {
          id
        }
      }
    }
  }
`;

export const REMOVE_KIOSK = gql`
  mutation removeKiosk($id: Int!) {
    removeKiosk(id: $id) {
      message
    }
  }
`;

export const RENAME_KIOSK = gql`
  mutation renameKiosk($input: RenameKioskInput!) {
    renameKiosk(input: $input) {
      name
    }
  }
`;
