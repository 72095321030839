import { IconProps } from "@mui/material";

export function Fisheye({
  style,
  className,
  dark,
}: IconProps & { dark?: boolean }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      style={style}
      className={className}
    >
      <path
        opacity="0.3"
        d="M20 10C20 15.5125 15.5402 20 10 20C4.45983 20 0 15.5125 0 10C0 4.48753 4.45983 0 10 0C15.5402 0 20 4.48753 20 10ZM10 1.96676C5.56787 1.96676 1.96676 5.56787 1.96676 10C1.96676 14.4321 5.56787 18.0332 10 18.0332C14.4321 18.0332 18.0332 14.4321 18.0332 10C18.0332 5.56787 14.4321 1.96676 10 1.96676Z"
        fill={dark ? "#353D48" : "white"}
      />
      <path
        d="M17.064 8.94766C17.6457 12.8535 14.9587 16.4823 11.0529 17.064C7.1471 17.6457 3.51829 14.9587 2.93657 11.0529C2.35486 7.1471 5.04184 3.51829 8.94766 2.93657C12.8535 2.35486 16.4823 5.04184 17.064 8.94766ZM6.53768 6.37148C5.84516 6.48228 5.37425 7.1194 5.48505 7.78422C5.59586 8.47674 6.23297 8.94765 6.89779 8.83685C7.59031 8.72605 8.06123 8.08893 7.95043 7.42411C7.86732 6.73159 7.2302 6.26067 6.53768 6.37148ZM15.4019 8.75375C15.208 8.00583 14.8756 7.34101 14.4324 6.75929C13.9892 6.17757 13.4629 5.73436 12.8258 5.37425C13.5183 6.03907 14.0169 6.81469 14.2939 7.72882C14.5709 8.64295 14.5986 9.58477 14.377 10.4989C14.1554 11.4407 13.6845 12.2441 13.0197 12.9089C11.8839 14.0169 10.4989 14.5432 8.89225 14.4047C7.31331 14.2662 6.03906 13.5183 5.09724 12.2441C5.09724 12.2718 5.09724 12.3272 5.12494 12.3549C6.14987 14.6817 8.72605 16.0114 11.2191 15.4851C13.5183 14.9864 15.2357 13.1859 15.5405 10.859C15.6236 9.88948 15.4019 8.80915 15.4019 8.75375Z"
        fill={dark ? "#353D48" : "white"}
      />
    </svg>
  );
}
