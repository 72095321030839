import DevicesIcon from "@mui/icons-material/Devices";
import PersonIcon from "@mui/icons-material/Person";
import CamIcon from "@mui/icons-material/VideocamOutlined";
import { Box, TableRow, Tooltip, Typography, Skeleton } from "@mui/material";
import clsx from "clsx";
import { differenceInDays } from "date-fns/fp";
import { makeStyles } from "tss-react/mui";

import { AI } from "@/icons/AI";
import { Fisheye } from "@/icons/Fisheye";
import { LPRIcon } from "@/icons/LPRIcon";
import { PawIcon } from "@/icons/Paw";

import { useBreakpoints } from "@/util/useBreakpoints";

import {
  DeviceStatusV2,
  MappedDevice,
  StyledCell,
  useDeviceStatusAction,
} from "@/pages/Settings/LocationSettings/Camera/CameraSetupTable";

import { useMe } from "@/components/Auth";
import CameraSupportDetails from "@/components/CameraSupportDetails";
import { UpdatableImage } from "@/components/UpdatableImage";

import {
  Camera,
  ConnectionValidation as Validation,
  Role,
} from "@/generated-models";
import { usePermissions } from "@/hooks/usePermissions";

const BORDER_BLUE = "#a4cdf0";
const BACKG_BLUE = "#ecf7ff";
export const BACKG_GREY = "#f5f5f5";
// const BACKG_RED = "#f0acac";

const useStyles = makeStyles()((theme) => ({
  nameCell: { display: "flex", alignItems: "center", padding: 6 },
  nvrChannels: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: 42,
    width: 79,
    fontSize: 12,
  },
  rowButtonColumn: { textAlign: "left" },
  expandButton: {
    transition: "0.25s transform",
    backgroundColor: "#fff",
    color: theme.palette.primary.main,
    border: `solid 1px ${BACKG_GREY}`,
    marginLeft: 16,
  },
  tableRow: {
    opacity: 0.6,
    cursor: "pointer",
    backgroundColor: BACKG_GREY,
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      opacity: 1,
      backgroundColor: "#e8e8e8",
    },
  },
  rowIsUnactivated: {
    opacity: 1,
    backgroundColor: "RGBA(245, 245, 245, 1.00)",
    "&:hover": {
      backgroundColor: "RGBA(235, 235, 235, 1.00)",
    },
  },
  rowIsActivated: {
    opacity: 1,
    backgroundColor: "RGBA(244, 250, 253, 1.00)",
    "&:hover": {
      backgroundColor: "#E2EDF5",
    },
  },
  activeRow: {
    opacity: 1,
    "& > td": {
      backgroundColor: `${BACKG_BLUE} !important`,
      borderColor: BORDER_BLUE,
    },
  },
  formRow: { border: 0, padding: 0 },
  forms: {
    padding: 10,
    backgroundColor: BACKG_GREY,
    borderRadius: "0 0 4px 4px",
    border: `solid 1px ${BACKG_GREY}`,
    borderTop: 0,
    transition: "background-color,border-color 0.1s",
  },
  formTitle: {
    display: "flex",
    justifyContent: "space-between",
    lineHeight: 2.1,
    paddingLeft: 4,
  },
  formContainer: {
    padding: 12,
    backgroundColor: "#fff",
    borderRadius: 4,
    border: `solid 1px ${BORDER_BLUE}`,
  },
  actionButtons: {
    display: "flex",
    alignItems: "center",
    margin: "auto 0 0 12px",
  },
  formValid: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
    color: "#62b309",
    fontSize: 14,
    fontWeight: "bold",
    "& > svg": { margin: "0 2px 0 8px", fontSize: 18 },
  },
  stillContainer: {
    position: "relative",
    color: "#fff",
    fontWeight: "bold",
    minHeight: 200,
    marginRight: 12,
  },
  stillFallback: {
    backgroundColor: "#000",
    width: "100%",
    height: 250,
  },
  stillContainerLabel: {
    position: "absolute",
    left: 0,
    bottom: 5,
    padding: "6px 16px",
    backgroundColor: "rgba(0, 0, 0, 0.65)",
    fontSize: 16,
    "& > span": { fontSize: 9, marginLeft: 6 },
  },
  inactiveOverlay: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 100,
    paddingLeft: 16,
    fontSize: 32,
    letterSpacing: 16,
    lineHeight: 2,
    backgroundColor: "rgba(0, 0, 0, 0.65)",
    textAlign: "center",
  },
  icon: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 4px 0 6px",
    fontSize: 8,
    fontWeight: "bold",
    lineHeight: 0.6,
    "& svg": { fontSize: 15 },
  },
  streamOptionInfo: {
    margin: "-5px 0 4px 27px",
    fontSize: 12,
    "& span": { fontWeight: "bold" },
  },
  fullWidthLabel: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    fontSize: 14,
    fontWeight: "bold",
  },
}));

export type LockRow = (mac: string, dontExpand?: boolean) => void;

export function DeviceRow({
  device,
  locationId,
  closeManual,
  lockRow,
  onClick = () => {},
  classNames,
  rowRef,
}: {
  device: MappedDevice;
  locationId: number;
  closeManual?: () => void;
  lockRow: LockRow;
  onClick?: (e: HTMLElement) => void;
  classNames?: string;
  rowRef?: (instance: HTMLTableRowElement | null) => void;
}) {
  const { type, statusv2, name, vendor, ip, isNvr, isFisheye } = device;
  const { classes } = useStyles();
  const { fitsDesktop } = useBreakpoints();
  const me = useMe();
  const isSupport = me && me.role >= Role.Success;

  const hasPermission = usePermissions();
  const canEdit = hasPermission("devices_manage");

  const valid = device.status === Validation.Ok;
  const channelsCount = isNvr ? device.channels.length : 0;
  const camera = type === "Camera" ? device.cameras[0] : null;

  const correctRowStyle =
    statusv2 >= DeviceStatusV2.CameraDeactivated
      ? classes.rowIsActivated
      : statusv2 >= DeviceStatusV2.DeviceUnauthenticated
      ? classes.rowIsUnactivated
      : undefined;

  const [StatusComponent, ActionComponent] = useDeviceStatusAction(
    device,
    lockRow
  );

  return (
    <>
      <TableRow
        ref={rowRef}
        className={clsx([classes.tableRow, correctRowStyle, classNames], {
          "cursor-default pointer-events-none": !canEdit,
        })}
        onClick={(e) => {
          if (!canEdit) return true;
          onClick(e.currentTarget);
        }}
      >
        <StyledCell>
          <div className={classes.nameCell}>
            {isNvr ? (
              <div className={classes.nvrChannels}>
                <span>
                  <strong>{channelsCount} channels</strong>
                </span>
                {!!device.cameras.length && (
                  <span>{device.cameras.length} configured</span>
                )}
              </div>
            ) : (
              <div
                className="text-center w-20 h-11 shrink-0"
                style={{ fontSize: 10 }}
              >
                {device.still ? (
                  <UpdatableImage
                    src={device.still}
                    className="w-full h-full object-cover object-center rounded"
                  />
                ) : valid ? (
                  <Tooltip title="Thumbnail loading...">
                    <Skeleton
                      variant="rectangular"
                      className="h-full w-full opacity-10 rounded"
                    />
                  </Tooltip>
                ) : (
                  <div
                    // style={{ border: "1px solid #E8E8E8", borderRadius: 4 }}
                    className="flex-center w-full h-full rounded border border-gray-300 border-solid"
                  >
                    Authenticate to see preview
                  </div>
                )}
              </div>
            )}
            <div className={classes.icon}>
              {isFisheye ? (
                <Fisheye dark className="h-[15px]" />
              ) : device.isCameraVendor ? (
                <CamIcon />
              ) : (
                <DevicesIcon />
              )}
              {!isFisheye && isNvr && <div>NVR</div>}
            </div>
            <div>
              <strong>{name}</strong>
            </div>
            {camera?.aiEnabled && (
              <div className="pl-0.5 grow flex justify-end">
                <AI />
              </div>
            )}
            {camera?.settings?.lprEnabled && (
              <div className="pl-0.5 grow flex justify-end">
                <LPRIcon className="text-primary" />
              </div>
            )}
          </div>
        </StyledCell>
        {fitsDesktop && (
          <>
            <StyledCell style={{ lineHeight: 1.1 }}>
              <div>{device.mac}</div>
              <CameraSupportDetails camera={camera as Camera} />
            </StyledCell>
            <StyledCell style={{ lineHeight: 1.1 }}>
              <div>{ip}</div>
              {device.isManual && (
                <Tooltip title='This network device was added using "Add a Device"'>
                  <Typography
                    className="text-2xs text-gray-71"
                    variant="caption"
                  >
                    Manual
                  </Typography>
                </Tooltip>
              )}
            </StyledCell>
            <StyledCell
              style={{
                textTransform: "capitalize",
                minWidth: 140,
              }}
            >
              {vendor.trim().split(/,| /)[0]}
            </StyledCell>
          </>
        )}
        <StyledCell
          className={classes.rowButtonColumn}
          style={{ minWidth: 180 }}
        >
          <Tooltip
            title={
              statusv2 === DeviceStatusV2.DeviceNeedsStream ||
              statusv2 === DeviceStatusV2.NvrNoActivatedCameras
                ? device.geniusAuthConfigured
                  ? "Automatically authenticated by Spot Genius"
                  : "Manually authenticated"
                : ""
            }
          >
            <span
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Box
                mr={0.5}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                {(statusv2 === DeviceStatusV2.DeviceNeedsStream ||
                  statusv2 === DeviceStatusV2.NvrNoActivatedCameras) &&
                  (device.geniusAuthConfigured ? (
                    <PawIcon style={{ height: 18, width: 18 }} />
                  ) : (
                    <PersonIcon
                      style={{ height: 18, width: 18, opacity: 0.6 }}
                    />
                  ))}
              </Box>
              {StatusComponent}
            </span>
          </Tooltip>
          {isSupport && device.firstSegmentTime && (
            <Tooltip title={device.firstSegmentTime}>
              <div className="text-2xs text-gray-71">
                {differenceInDays(
                  new Date(device.firstSegmentTime),
                  new Date()
                ) + 1}{" "}
                days of storage
              </div>
            </Tooltip>
          )}
        </StyledCell>
        <StyledCell
          className={clsx(classes.rowButtonColumn, !canEdit && "opacity-20")}
          style={{ minWidth: 180 }}
        >
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {ActionComponent}
          </span>
        </StyledCell>
      </TableRow>
    </>
  );
}
