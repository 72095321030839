import { Divider, Skeleton } from "@mui/material";
import { useState } from "react";

import { Appliance, Camera } from "@/generated-models";

import { MetricAddResourceButton } from "../Core/MetricAddResource/MetricAddResourceButton";
import { getColor } from "../constants";
import {
  useGetDashboardResourceData,
  useMaintainDashboard,
  useUpdateMaintainDashboard,
} from "../hooks";
import { MetricsLegendButton } from "./MetricsLegendButton";

function LegendSkeleton() {
  return <Skeleton variant="rectangular" className="w-[134px] h-9 rounded" />;
}

export function MaintainMetricsLegendToolbar() {
  const { data, loading } = useMaintainDashboard();
  const [updating, setUpdating] = useState(false);
  const resources = useGetDashboardResourceData();
  const update = useUpdateMaintainDashboard();

  const resourceIds = data?.maintainDashboard?.resourceIds || [];
  const hidden = data?.maintainDashboard?.settings?.hiddenResourceIds || [];

  return (
    <div className="flex items-center justify-start flex-wrap gap-1">
      {loading && (
        <>
          <LegendSkeleton />
          <LegendSkeleton />
          <LegendSkeleton />
          <LegendSkeleton />
        </>
      )}
      {!loading && (
        <>
          {resources.map((d, idx) => {
            const visible = !hidden.includes(d.id);
            return (
              <MetricsLegendButton
                key={d.id}
                disabled={updating}
                color={getColor(idx)}
                visible={!hidden.includes(d.id)}
                label={(d as Camera).name || (d as Appliance).serialNumber}
                onToggleVisibility={async () => {
                  setUpdating(true);
                  await update(null, {
                    hiddenResourceIds: visible
                      ? [...hidden, d.id]
                      : hidden.filter((h) => h !== d.id),
                  });
                  setUpdating(false);
                }}
                onRemoveResource={async () => {
                  setUpdating(true);
                  await update(resourceIds.filter((r) => r !== d.id));
                  setUpdating(false);
                }}
              />
            );
          })}
          {resources.length > 0 && (
            <Divider orientation="vertical" className="h-5" />
          )}
          <MetricAddResourceButton primary disabled={updating} />
        </>
      )}
    </div>
  );
}
