import * as turf from "@turf/turf";

import { ZoomState } from "@/components/Player/PlayerBase";
import { Point } from "@/components/Zones/getSectorsForPolygon";

import { PointInput } from "@/generated-models";

const IGNORED_LABELS = ["stuff", "merged", "other"];

const boundingPadding = 5;

export function simplifyBoundingBox(shape: Point[]) {
  const simplified = turf.simplify(
    turf.polygon([
      [
        ...shape.map((s) => {
          return [s.x, s.y];
        }),
        [shape[0].x, shape[0].y],
      ],
    ]),
    { tolerance: 0.5, highQuality: true }
  );

  return simplified.geometry.coordinates[0].map(([x, y]) => ({
    x,
    y,
  }));
}

export function formatSubjectLabel(label: string) {
  return label
    .split("-")
    .filter((s) => !IGNORED_LABELS.includes(s))
    .join("-")
    .toLowerCase();
}

export function getBoundingBoxFromVolume(shape: PointInput[]) {
  let minX = 100,
    maxX = 0,
    minY = 100,
    maxY = 0;

  shape.forEach(({ x, y }) => {
    if (x < minX) minX = x;
    if (y < minY) minY = y;

    if (x > maxX) maxX = x;
    if (y > maxY) maxY = y;
  });

  // Pad the bounding box.
  minX -= boundingPadding;
  minY -= boundingPadding;
  maxX += boundingPadding;
  maxY += boundingPadding;

  minX = Math.min(Math.max(minX, 0), 100);
  minY = Math.min(Math.max(minY, 0), 100);

  return {
    volume: [
      { x: minX, y: minY },
      { x: minX, y: maxY },
      { x: maxX, y: maxY },
      { x: maxX, y: minY },
    ],
    minX,
    minY,
    maxX,
    maxY,
  };
}

export function captureCustomObjectFrame(
  video: HTMLVideoElement,
  filename: string,
  zoomState: ZoomState | null,
  skipDownload?: boolean
) {
  const canvas = document.createElement("canvas");

  if (!zoomState || zoomState.zoomLevel === 1) {
    // Normal snapshot when not zooming
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    canvas.getContext("2d")!.drawImage(video, 0, 0);
  } else {
    // If digital zoom is active, snapshot the visible part
    const ratio = video.videoHeight / video.offsetHeight;
    // canvas aspect ratio needs to be the same as the video player
    canvas.width = Math.round(video.offsetWidth * ratio);
    canvas.height = video.videoHeight;
    const { zoomLevel, xPos, yPos } = zoomState;
    const zoomWidth = video.videoWidth * zoomLevel;
    const zoomHeight = video.videoHeight * zoomLevel;
    // Width padding is needed to draw the black bars on the side of the player
    const widthDiff = canvas.width - video.videoWidth;
    const zoomWidthDiff = widthDiff * zoomLevel;
    // Please don't ask me how this math works exactly xD
    // It basically calculates where to position the video inside the canvas, considering the widthDiff.
    // If (xPos === 0) the padding should be (zoomWidthDiff / 2)
    // If (xPos === 1) the padding should be -(zoomWidthDiff / 2) + widthDiff
    const widthPadding = Math.round(
      (1 - xPos) * zoomWidthDiff - zoomWidthDiff / 2 + xPos * widthDiff
    );
    const divider = zoomLevel / (zoomLevel - 1);
    canvas
      .getContext("2d")!
      .drawImage(
        video,
        (zoomWidth * xPos) / -divider + widthPadding,
        (zoomHeight * yPos) / -divider,
        zoomWidth,
        zoomHeight
      );
  }

  try {
    var dataURL = canvas.toDataURL("image/jpeg");
  } catch (error: any) {
    console.log("canvas.toDataURL error", error);
    console.error(error);
    throw error;
  }

  if (!skipDownload) {
    const link = document.createElement("a");
    link.download = filename;
    link.href = dataURL;
    link.target = "_blank";
    link.click();
  }

  return dataURL;
}
