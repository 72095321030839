import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/ErrorOutline";
import {
  Button,
  CircularProgress,
  Collapse,
  Divider,
  Fade,
  Grid,
  IconButton,
  Link as MaterialLink,
  AlertTitle,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import gql from "graphql-tag";
import { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { usePrevious } from "react-use";
import { makeStyles } from "tss-react/mui";

import { AI } from "@/icons/AI";

import { CameraSetupTable } from "@/pages/Settings/LocationSettings/Camera/CameraSetupTable";
import { InterfacesModal } from "@/pages/Settings/LocationSettings/Camera/InterfacesModal";
import { RebalanceCamerasModal } from "@/pages/Settings/LocationSettings/Camera/RebalanceCamerasModal";
import { SyncCamerasButton } from "@/pages/Settings/LocationSettings/Camera/SyncCamerasButton";
import { useCompleteWalkthrough } from "@/pages/Settings/LocationSettings/Walkthrough/UseCompleteWalkthrough";

import { useMe } from "@/components/Auth";
import { ErrorMessage } from "@/components/ErrorMessage";
import GeniusBanner from "@/components/Genius/GeniusBanner";
import { ManualDevicePopup } from "@/components/Genius/GeniusPopup";
import GeniusProvider from "@/components/Genius/GeniusProvider";
import {
  LocationCapacityGuard,
  useLocationCapacity,
} from "@/components/Genius/LocationCapacityGuard";
import { MaxCapacityTooltip } from "@/components/Genius/MaxCapacityTooltip";
import { Loading } from "@/components/Loading";
import { GrowingSearchBox } from "@/components/SearchBox";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { ZendeskArticle, ZendeskLink } from "@/components/Zendesk/ZendeskLink";

import {
  DeviceListDocument,
  LifecycleStates,
  Role,
  useGetLocationSetupInfoQuery,
  useLocationWithAppliancesQuery,
  useStartScanMutation,
} from "@/generated-models";
import { usePermissions } from "@/hooks/usePermissions";

import BulkImportModal from "./BulkImportModal";

const useStyles = makeStyles()((theme) => ({
  root: { padding: "0 16px 12px" },
  header: { padding: "4px 0 16px", fontSize: 18, fontWeight: "bold" },
  buttonsContainer: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    right: 40,
  },
  divider: { height: 8, margin: "0 4px" },
}));

export function CameraSetup(props: CameraSetupProps) {
  return (
    <LocationCapacityGuard locationId={props.locationId}>
      <CameraSetupInner {...props} />
    </LocationCapacityGuard>
  );
}

interface CameraSetupProps {
  locationId: number;
  setup?: boolean;
}

function CameraSetupInner({ locationId, setup }: CameraSetupProps) {
  const { classes: cls } = useStyles();
  const [showWarning, setShowWarning] = useState(true);
  const [interfacesModalOpened, setInterfacesModalOpened] = useState(false);
  const [rebalanceModalOpened, setRebalanceModalOpened] = useState(false);
  const hasPermission = usePermissions();
  const [bulkImportModalOpen, setBulkImportModalOpen] = useState(false);
  const [manualPopupOpen, setManualPopupOpen] = useState(false);
  const manualButtonRef = useRef<HTMLButtonElement | null>(null);
  const [searchInput, setSearchInput] = useState<string | null>(null);
  const { pushSnackbar } = useFeedback();
  const me = useMe();

  const [startScan, { loading: scanning }] = useStartScanMutation({
    variables: { input: { locationId } },
    refetchQueries: () => [
      { query: DeviceListDocument, variables: { locationId: locationId } },
    ],
    awaitRefetchQueries: true,
    onError: (error) =>
      pushSnackbar(
        `Scanning for cameras failed, ${
          error.message.includes("No online appliances")
            ? "because all appliances are offline"
            : "please try again..."
        }`,
        FeedbackType.Error
      ),
  });

  const locationResults = useGetLocationSetupInfoQuery({
    variables: { locationId },
    fetchPolicy: "cache-only",
  });

  const {
    guardCapacityAndShowModal,
    maxCapacityReached,
  } = useLocationCapacity();

  const applianceResults = useLocationWithAppliancesQuery({
    variables: { id: locationId },
    // Background info on nextFetchPolicy (buried in an issue):
    // https://github.com/apollographql/apollo-client/issues/6760#issuecomment-668188727
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const camCount = locationResults.data?.location?.cameras.length;
  const nonAiCamCount = useMemo(() => {
    return (
      locationResults.data?.location?.cameras?.filter(
        (c) => !c.aiEnabled && c.lifecycleState === LifecycleStates.Enabled
      )?.length || 0
    );
  }, [locationResults]);
  const aiCamCount = useMemo(() => {
    return (
      locationResults.data?.location?.cameras?.filter(
        (c) => c.aiEnabled && c.lifecycleState === LifecycleStates.Enabled
      )?.length || 0
    );
  }, [locationResults]);

  const [showCongrats, setShowCongrats] = useState(false);
  const previousCamCount = usePrevious(camCount);
  const completeWalkthrough = useCompleteWalkthrough(locationId);
  useEffect(() => {
    if (
      setup &&
      previousCamCount !== undefined &&
      camCount !== undefined &&
      previousCamCount < camCount
    ) {
      completeWalkthrough().then(() => {
        setShowCongrats(true);
        window.scrollTo({ top: 0, behavior: "smooth" });
      });
    }
    // eslint-disable-next-line
  }, [camCount]);

  // Loading appliance info
  if (!applianceResults.data?.location) {
    return (
      <div style={{ padding: "48px 0", margin: "0 auto" }}>
        <Loading />
      </div>
    );
  }

  // Show error if no appliance were added
  const { appliances } = applianceResults.data.location;
  if (appliances.length === 0) {
    return (
      <ErrorMessage
        title="No appliance connected"
        description={
          <span>
            An appliance needs to be connected before being able to setup
            cameras, go back to{" "}
            <MaterialLink to="appliances" component={Link} underline="hover">
              the previous step
            </MaterialLink>
            .
          </span>
        }
      />
    );
  }

  const isSupport = me && me.role >= Role.Support;
  const isMaster = me && me.role >= Role.Master;
  const canEdit = hasPermission("devices_manage");

  const appliancesOffline = appliances.filter((a) => !a.health.online).length;
  const allAppliancesOffline = appliances.length === appliancesOffline;
  const showRebalanceModal = isSupport && appliances.length > 1;

  return (
    <GeniusProvider locationId={locationId} startScan={startScan}>
      <div>
        <GeniusBanner
          locationId={locationId}
          locationResults={locationResults.data}
        />
        <div className={cls.root}>
          <Collapse in={showCongrats}>
            <Alert
              style={{ marginTop: 10, marginBottom: 10 }}
              severity="success"
              onClose={() => setShowCongrats(false)}
            >
              <AlertTitle>Setup Complete!</AlertTitle>
              You can come back to location settings any time to add more cams -{" "}
              <a href="https://dashboard.spot.ai">See Dashboard</a>
            </Alert>
          </Collapse>
          <Grid
            container
            alignItems="center"
            justifyContent="flex-end"
            className={cls.header}
          >
            {!!camCount && (
              <div className="mr-auto flex gap-x-6 items-center">
                <div>{camCount} Cameras</div>
                <div className="font-normal text-sm">
                  <div className="flex-row gap-y-1">
                    <div className="flex gap-x-1 items-center">
                      <AI /> <span className="opacity-70 font-medium">AI:</span>
                      <div className="ml-auto">
                        <strong>{aiCamCount}</strong> Connected
                      </div>
                    </div>
                    <div className="flex gap-x-1 items-center">
                      <span className="opacity-70 font-medium">Non AI:</span>
                      <div className="ml-auto">
                        <strong>{nonAiCamCount}</strong> Connected
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {canEdit && (
              <Fade in={searchInput === null}>
                <div className={cls.buttonsContainer}>
                  <Button
                    color="primary"
                    disabled={scanning}
                    onClick={() => startScan()}
                    endIcon={
                      scanning ? <CircularProgress size="14px" /> : undefined
                    }
                  >
                    {scanning ? "Scanning for Cameras" : "Scan for Cameras"}
                  </Button>
                  <Divider orientation="vertical" className={cls.divider} />
                  <Button
                    color="primary"
                    onClick={() => setInterfacesModalOpened(true)}
                  >
                    Network Interfaces
                  </Button>
                  {(isMaster || localStorage.enableBulkImport === "true") && (
                    <>
                      <Divider orientation="vertical" className={cls.divider} />
                      <MaxCapacityTooltip
                        maxCapacityReached={maxCapacityReached}
                      >
                        <Button
                          color="primary"
                          style={{ fontWeight: 400 }}
                          onClick={() => {
                            // dev only; ignoring AI capacity
                            if (guardCapacityAndShowModal(false)) return;
                            setBulkImportModalOpen(true);
                          }}
                        >
                          Bulk Import
                          {maxCapacityReached && (
                            <>
                              <div className="w-1" />
                              <WarningIcon className="text-base" />
                            </>
                          )}
                        </Button>
                      </MaxCapacityTooltip>
                    </>
                  )}
                  <Divider orientation="vertical" className={cls.divider} />
                  <MaxCapacityTooltip maxCapacityReached={maxCapacityReached}>
                    <Button
                      ref={manualButtonRef}
                      color="primary"
                      style={{ fontWeight: 400 }}
                      onClick={() => {
                        // the AI check will be done prior to activation
                        if (guardCapacityAndShowModal(false)) return;
                        setManualPopupOpen(true);
                      }}
                    >
                      Add a Device
                      {maxCapacityReached && (
                        <>
                          <div className="w-1" />
                          <WarningIcon className="text-base" />
                        </>
                      )}
                    </Button>
                  </MaxCapacityTooltip>

                  {manualPopupOpen && (
                    <ManualDevicePopup
                      onClose={() => setManualPopupOpen(false)}
                      locationId={locationId}
                    />
                  )}
                  {bulkImportModalOpen && (
                    <BulkImportModal
                      onClose={() => setBulkImportModalOpen(false)}
                      locationId={locationId}
                    />
                  )}
                  <Divider orientation="vertical" className={cls.divider} />
                  <SyncCamerasButton locationId={locationId} />
                  {showRebalanceModal && (
                    <>
                      <Divider orientation="vertical" className={cls.divider} />
                      <Button
                        color="primary"
                        onClick={() => setRebalanceModalOpened(true)}
                      >
                        Rebalance
                      </Button>
                    </>
                  )}
                  <ZendeskLink article={ZendeskArticle.ADDING_NVR} />
                </div>
              </Fade>
            )}
            <GrowingSearchBox
              input={searchInput}
              setInput={setSearchInput}
              placeholder="Search Cameras"
            />
          </Grid>

          <Collapse in={showWarning && appliancesOffline > 0}>
            <Alert
              severity={allAppliancesOffline ? "error" : "warning"}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => setShowWarning(false)}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              style={{ marginBottom: 8 }}
            >
              {allAppliancesOffline ? "All " : "Some "}appliances seem to be
              offline. This could cause degraded functionality.
            </Alert>
          </Collapse>

          <CameraSetupTable
            locationId={locationId}
            searchInput={searchInput}
            startScan={startScan}
          />

          <InterfacesModal
            locationId={locationId}
            opened={interfacesModalOpened}
            close={() => setInterfacesModalOpened(false)}
          />
          {showRebalanceModal && (
            <RebalanceCamerasModal
              locationId={locationId}
              opened={rebalanceModalOpened}
              close={() => setRebalanceModalOpened(false)}
            />
          )}
        </div>
      </div>
    </GeniusProvider>
  );
}

export const START_SCAN = gql`
  mutation startScan($input: StartScanInput!) {
    startScan(input: $input) {
      message
    }
  }
`;

export const GENIUS_SCAN = gql`
  mutation geniusScan($input: GeniusScanInput!) {
    geniusScan(input: $input) {
      message
    }
  }
`;

export const GENIUS_REGENERATE_MISSING_STILLS = gql`
  mutation geniusRegenerateMissingStills(
    $input: GeniusRegenerateMissingStillsInput!
  ) {
    geniusRegenerateMissingStills(input: $input) {
      message
    }
  }
`;

export const GET_LOCATION_SETUP_INFO = gql`
  query getLocationSetupInfo($locationId: Int!) {
    location(id: $locationId) {
      id
      devices {
        geniusAuthConfigured
      }
      cameras {
        id
        status
        lifecycleState
        aiEnabled
        appliance {
          id
        }
      }
      appliances {
        id
        version
      }
    }
  }
`;
