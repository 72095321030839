import { MenuItem, Select, SelectChangeEvent, Skeleton } from "@mui/material";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { uniq } from "lodash/fp";

import { usePage_AssistantQuery } from "@/generated-models";
import { DarkTheme } from "@/layout/theme";

import {
  conversationAtom,
  conversationLoadingAtom,
  conversationLocationAtom,
} from "../hooks";
import { questionAtom } from "./AssistantPromptInput";

export function AssistantLocationSelector() {
  const [location, setLocation] = useAtom(conversationLocationAtom);
  const setConversation = useSetAtom(conversationAtom);
  const conversationLoading = useAtomValue(conversationLoadingAtom);
  const setQuestion = useSetAtom(questionAtom);

  const { data, loading } = usePage_AssistantQuery();

  const locations = uniq(
    data?.cameras.map((cam) => cam.location).flat()
  ).sort((a, b) => a.name.localeCompare(b.name));

  const handleChange = (event: SelectChangeEvent) => {
    setLocation((event.target.value as unknown) as number);
    setConversation([]);
    setQuestion("");
  };

  return (
    <DarkTheme>
      <div className="flex gap-1 items-center justify-center">
        <span className="text-[#A6C8E1] text-sm leading-4">Location: </span>
        {loading && (
          <Skeleton
            className="rounded-lg"
            variant="rectangular"
            width={142}
            height={37}
          />
        )}
        {!loading && (
          <Select
            className="rounded-lg text-sm"
            classes={{
              outlined: "py-[7px]",
            }}
            sx={{
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "#CCCCCC",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#CCCCCC",
              },
            }}
            value={((location as unknown) as string) || "__NONE__"}
            variant="outlined"
            size="small"
            disabled={conversationLoading || loading}
            onChange={handleChange}
          >
            <MenuItem value="__NONE__" disabled>
              Select a location
            </MenuItem>
            {locations.map((l) => (
              <MenuItem value={l.id}>{l.name}</MenuItem>
            ))}
          </Select>
        )}
      </div>
    </DarkTheme>
  );
}
