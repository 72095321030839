import CloseIcon from "@mui/icons-material/Close";
import {
  Collapse,
  Dialog,
  Hidden,
  IconButton,
  Link,
  Pagination,
  Tooltip,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { format } from "date-fns/fp";
import gql from "graphql-tag";
import { orderBy, sortBy } from "lodash/fp";
import { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useUpdateEffect } from "react-use";
import { usePagination } from "react-use-pagination";

import { pluralize } from "@/util/pluralize";

import { IntLoadingIndicator } from "@/pages/Intelligence/IntLoadingIndicator";

import { ErrorMessage } from "@/components/ErrorMessage";
import {
  useActiveCamIds,
  useActiveGroupId,
} from "@/components/View/sharedViewHooks";
import { SearchParamLink } from "@/components/shared/QueryParamLink";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  ClothingColor,
  Gender,
  GetDetectedSubjectsQuery,
  useGetDetectedSubjectsQuery,
} from "@/generated-models";
import { usePermissions } from "@/hooks/usePermissions";

import { SearchHeader, SearchTabs } from "../SearchTabs";
import { ReactComponent as MergeSubjectsSvg } from "../icons/merge-subjects.svg";
import {
  useArraySearchFilter,
  useRangeParamWithoutDefaults,
} from "../searchHooks";
import { ActivePeopleFiltersBar } from "./components/ActiveFiltersBar";
import { ageBucketMap, useGenderParam } from "./components/AttributeFilters";
import { EmptyState } from "./components/EmptyState";
import { HelpPopover } from "./components/HelpPopover";
import {
  LayoutSelect,
  LayoutSize,
  useLayoutParam,
} from "./components/LayoutSelect";
import { PeopleFilterBar } from "./components/PeopleFilterBar";
import { SortBar } from "./components/SortBar";
import { MergeSubjectsModal } from "./components/SubjectMergeModals";

export function PeopleSearch() {
  return (
    <div className="px-3 md:px-5 pb-5">
      <SearchTabs
        fallback={<SearchHeader label="People" className="text-4xl shrink-0" />}
      >
        <Typography className="flex-auto basis-80 max-w-xl text-sm md:text-base leading-5 md:leading-5">
          <strong>Search for people based on appearance.</strong> Discover all
          instances of a specific person or filter by attributes such as
          clothing color, gender, or age.
        </Typography>
        <HelpPopover className="text-primary">
          <Typography>
            People search predictively sorts observed faces into individual
            identities. Use this to search for people seen across your
            organization.
          </Typography>
          <Typography>
            To get the best results on search, an unobstructed, well lit, high
            quality capture of faces are required.{" "}
            <Link
              href="https://help.spot.ai/hc/en-us/articles/19121608742413-Attribute-Search-and-People-Search-with-Faces"
              target="_blank"
              rel="noopener noreferrer"
              underline="hover"
            >
              For more guidance, please see the KB article
            </Link>
            .
          </Typography>
        </HelpPopover>
      </SearchTabs>
      <div className="flex items-start gap-x-7 md:gap-x-9">
        <PeopleFilterBar />
        <div className="flex flex-col grow">
          <PeopleResultsList />
        </div>
      </div>
    </div>
  );
}

const sortParamName = "sort";
const sortOptions = [
  { label: "A-Z", prefix: "az", defaultOrder: "asc" as const },
  { label: "Last seen", prefix: "last", defaultOrder: "desc" as const },
  { label: "Earliest seen", prefix: "first", defaultOrder: "asc" as const },
  { label: "Detections", prefix: "det", defaultOrder: "desc" as const },
];
const defaultSort = `${sortOptions[0].prefix}_${sortOptions[0].defaultOrder}`;

const formatDateTime = format("MMM d, h:mmaaa");

function PeopleResultsList() {
  const hasPermission = usePermissions();
  const managePermission = hasPermission("people_assignment_manage");
  const [layoutParam] = useLayoutParam();
  const { rangeStart, rangeEnd } = useRangeParamWithoutDefaults();
  const [activeGroupId] = useActiveGroupId();
  const activeCamIds = useActiveCamIds();
  const clothingUpper = useArraySearchFilter("clothingUpper");
  const clothingLower = useArraySearchFilter("clothingLower");
  const [gender] = useGenderParam();
  const age = useArraySearchFilter("age");

  const { data, error } = useGetDetectedSubjectsQuery({
    ...refetchOnMountPolicy,
    variables: {
      input: {
        objectType: "0",
        startTime: rangeStart?.toISOString(),
        endTime: rangeEnd?.toISOString(),
        groupIds: activeGroupId ? [activeGroupId] : undefined,
        cameraIds: activeCamIds.length ? activeCamIds : undefined,
        attributes: {
          clothingUpper: clothingUpper as ClothingColor[] | undefined,
          clothingLower: clothingLower as ClothingColor[] | undefined,
          gender: gender as Gender | null | undefined,
          age: age?.map((x) => ageBucketMap[x]),
        },
      },
    },
  });

  // Sort subjects
  const [search] = useSearchParams();
  const sortParam = search.get(sortParamName) || defaultSort;
  const subjects = useMemo(() => {
    if (!data) return null;
    const [sort, rawOrder] = sortParam.split("_");
    const sortedById = sortBy((s) => s.id, data.detectedSubjects);
    return orderBy(
      (s) => {
        switch (sort) {
          case "last":
            return s.stats?.lastSeen;
          case "first":
            return s.stats?.firstSeen;
          case "det":
            return s.stats?.trackCount;
          default:
            return s.label ?? `zzz-${s.id}`;
        }
      },
      rawOrder === "desc" ? "desc" : "asc",
      sortedById
    );
  }, [data, sortParam]);

  // Merge dialog state
  const [mergeActive, setMergeActive] = useState(false);
  const [mergeSubject, setMergeSubject] = useState<
    GetDetectedSubjectsQuery["detectedSubjects"][number] | null
  >(null);
  const closeDialog = () => setMergeSubject(null);

  // Pagination
  const {
    currentPage,
    totalPages,
    setPage,
    startIndex,
    endIndex,
  } = usePagination({
    totalItems: subjects?.length ?? 0,
    initialPageSize: 48,
  });
  useUpdateEffect(() => setPage(0), [sortParam, subjects?.length]);

  if (error) {
    return (
      <ErrorMessage
        title="Failed to load results"
        description={error.message}
      />
    );
  }

  return (
    <>
      <div className="flex items-center gap-3 flex-wrap">
        <div className="text-2xl font-bold mr-auto">
          {subjects
            ? `${subjects.length} ${pluralize(
                { 1: " Result", multi: " Results" },
                subjects.length
              )}`
            : "Results"}
        </div>
        <div className="flex items-center gap-3 flex-wrap ml-auto">
          {managePermission && (
            <>
              <button
                className={clsx(
                  "px-3 py-1.5 rounded-md transition-colors",
                  mergeActive
                    ? "bg-primary text-white font-bold"
                    : "bg-transparent text-primary font-normal"
                )}
                onClick={() => setMergeActive((value) => !value)}
              >
                Combine & Improve
              </button>
              <hr className="h-3 w-px bg-black bg-opacity-20" />
            </>
          )}
          <LayoutSelect />
          <hr className="h-3 w-px bg-black bg-opacity-20" />
          <SortBar
            param={sortParamName}
            options={sortOptions}
            defaultValue={defaultSort}
          />
        </div>
      </div>
      {managePermission && (
        <Collapse in={mergeActive}>
          <div className="flex items-center py-2 px-5 bg-primary text-white rounded-t-lg mt-2">
            <Hidden lgDown>
              <MergeSubjectsSvg className="mr-3" />
            </Hidden>
            <div className="text-lg font-bold mr-5">
              Select a Target Person to combine
            </div>
            <div className="flex-auto basis-80 max-w-xs text-sm italic">
              Improve accuracy and make future people searches easier by
              consolidating duplicate results.
            </div>
            <IconButton
              className="text-white ml-auto"
              onClick={() => setMergeActive(false)}
            >
              <CloseIcon fontSize="large" />
            </IconButton>
          </div>
        </Collapse>
      )}
      <ActivePeopleFiltersBar />
      {subjects ? (
        subjects.length ? (
          <>
            <div
              className={clsx("grid mt-3", {
                "grid-cols-3 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-8 xl:grid-cols-10 gap-2 md:gap-x-4 md:gap-y-3":
                  layoutParam === LayoutSize.Small,
                "grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-7 gap-x-4 gap-y-3 md:gap-x-6 md:gap-y-5": !layoutParam,
                "grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-x-4 gap-y-3 md:gap-x-6 md:gap-y-5":
                  layoutParam === LayoutSize.Large,
              })}
            >
              {subjects.slice(startIndex, endIndex + 1).map((subject) => (
                <SearchParamLink
                  key={subject.id}
                  className="paper flex flex-col w-full p-1 text-xs md:text-sm bg-white hover:scale-105 hover:shadow-[0_3px_14px_rgba(0,0,0,0.45)] transition-[transform,box-shadow] relative"
                  to={String(subject.id)}
                  onClick={(e) => {
                    if (mergeActive) {
                      e.preventDefault();
                      setMergeSubject(subject);
                    }
                  }}
                >
                  <img
                    src={subject.bestTrack?.imageSrc ?? ""}
                    alt={subject.label ?? "Unknown subject"}
                    className="w-full aspect-[9/10] rounded-t object-cover object-center"
                  />
                  <div className="mt-1 truncate text-center">
                    {subject.label ?? `Person ${subject.id}`}
                  </div>
                  <div className="truncate text-center text-2xs leading-tight text-opacity-70">
                    Last Seen: {formatDateTime(subject.stats?.lastSeen)}
                  </div>
                  {subject.stats?.trackCount && (
                    <Tooltip
                      title={`${subject.stats.trackCount} ${pluralize(
                        { 1: "detection", multi: "detections" },
                        subject.stats.trackCount
                      )}`}
                    >
                      <div className="absolute top-2 left-2 flex-center px-1.5 bg-text bg-opacity-50 rounded-sm text-white text-2xs leading-[1.125rem] font-bold">
                        {subject.stats.trackCount}
                      </div>
                    </Tooltip>
                  )}
                </SearchParamLink>
              ))}
            </div>
            {totalPages > 1 && (
              <div className="mt-5 self-end">
                <Pagination
                  count={totalPages}
                  page={currentPage + 1}
                  onChange={(_, value) => setPage(value - 1)}
                />
              </div>
            )}
          </>
        ) : (
          <EmptyState description="Change your search parameters to search for new people" />
        )
      ) : (
        <IntLoadingIndicator className="my-40 mx-auto" />
      )}
      {managePermission && (
        <Dialog
          onClose={closeDialog}
          open={Boolean(mergeSubject)}
          PaperProps={{ className: "p-5" }}
          maxWidth="lg"
        >
          {mergeSubject && (
            <MergeSubjectsModal
              imageSrc={mergeSubject.bestTrack?.imageSrc}
              currentSubjectId={mergeSubject.id}
              label={mergeSubject.label}
              cancel={closeDialog}
            />
          )}
        </Dialog>
      )}
    </>
  );
}

gql`
  query getDetectedSubjects($input: DetectedSubjectsInput!) {
    detectedSubjects(input: $input) {
      id
      label
      stats {
        firstSeen
        lastSeen
        trackCount
      }
      bestTrack {
        imageSrc
      }
    }
  }
`;
