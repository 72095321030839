import { Typography } from "@mui/material";

import { SpotFetchLogo } from "@/icons/SpotFetchLogo";

export function AssistantPageHeader() {
  return (
    <div className="flex flex-col items-center justify-center gap-[38px]">
      <SpotFetchLogo />
      <Typography className="text-[21px] leading-[27px] text-[#A6C8E1] font-light">
        Optimize your operations by simply asking questions.
      </Typography>
    </div>
  );
}
