import gql from "graphql-tag";
import { useMemo } from "react";
import { StringParam, useQueryParam, withDefault } from "use-query-params";

import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  Appliance,
  Camera,
  Location,
  MaintainDashboardSettingsInput,
  MaintainDashboardType,
  MaintainMetricTimeSeriesInput,
  Page_Maintain_MetricsQuery,
  useCreateMaintainDashboardMutation,
  usePage_Maintain_DashboardQuery,
  usePage_Maintain_Time_SeriesQuery,
  useUpdateMaintainDashboardMutation,
} from "@/generated-models";

import { getColor } from "./constants";

export type MaintainMetricsCams = Page_Maintain_MetricsQuery["cameras"][number];

const MaintainMetricsTypeQueryparam = withDefault(
  StringParam,
  MaintainDashboardType.Appliance
);

export function useMetricResourceChartMetadata() {
  const [type] = useMaintainMetricsDashboardType();
  const resources = useGetDashboardResourceData();

  return useMemo(() => {
    const result: Record<string, string> = {};
    const displayNames: Record<string, string> = {};

    resources.forEach((r, idx) => {
      switch (type) {
        case MaintainDashboardType.Appliance:
          result[(r as Appliance).serialNumber] = getColor(idx);
          break;
        case MaintainDashboardType.Location:
          result[(r as Location).name] = getColor(idx);
          break;
        default:
          displayNames[(r as Camera).id] = (r as Camera).name;
          result[(r as Camera).id] = getColor(idx);
          break;
      }
    });

    return { colors: result, displayNames };
  }, [resources, type]);
}

export function useMaintainMetricsDashboardType() {
  return useQueryParam("metrics-dashboard-type", MaintainMetricsTypeQueryparam);
}

export function useGetDashboardResourceData() {
  const [type] = useMaintainMetricsDashboardType();
  const { data } = useMaintainDashboard();

  const dashboardData = data?.maintainDashboard;

  switch (type) {
    case MaintainDashboardType.Appliance:
      return dashboardData?.appliances || [];
    case MaintainDashboardType.Location:
      return dashboardData?.locations || [];
    default:
      return dashboardData?.cameras || [];
  }
}

export function useMaintainDashboard(type?: MaintainDashboardType) {
  const [fallbackType] = useMaintainMetricsDashboardType();

  const resolvedType = type || (fallbackType as MaintainDashboardType);

  return usePage_Maintain_DashboardQuery({
    variables: {
      input: {
        type: resolvedType!,
      },
    },
    errorPolicy: "all",
    skip: !resolvedType,
  });
}

export function useMaintainTimeSeriesData(
  metricInput: MaintainMetricTimeSeriesInput
) {
  const [type] = useMaintainMetricsDashboardType();

  return usePage_Maintain_Time_SeriesQuery({
    ...refetchOnMountPolicy,
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        type: type as MaintainDashboardType,
      },
      metricInput,
    },
    skip: !type,
  });
}

export function useCreateDashboardIfNeeded() {
  const { data, loading } = useMaintainDashboard();

  const [type] = useMaintainMetricsDashboardType();
  const [createMaintainDashboard] = useCreateMaintainDashboardMutation();

  return async () => {
    if (!loading && !data?.maintainDashboard?.id) {
      return await createMaintainDashboard({
        variables: {
          type: type as MaintainDashboardType,
          input: {},
        },
        refetchQueries: ["page_maintain_dashboard"],
      });
    }
  };
}

export function useRemoveMaintainDashboardMetric() {
  const { data } = useMaintainDashboard();
  const update = useUpdateMaintainDashboard(["page_maintain_dashboard"]);

  return async (metric: string) => {
    await update(null, {
      metrics: data?.maintainDashboard?.settings?.metrics.filter(
        (h) => h !== metric
      ),
    });
  };
}

export function useUpdateMaintainDashboard(refetchQueries?: string[]) {
  const { data } = useMaintainDashboard();
  const { pushSnackbar } = useFeedback();

  const [updateMaintainDashboard] = useUpdateMaintainDashboardMutation();

  return (
    resourceIds?: number[] | null,
    settings?: MaintainDashboardSettingsInput | null,
    dashboardId?: number | null
  ) => {
    const dashId = dashboardId ?? data?.maintainDashboard?.id;
    if (!dashId) {
      pushSnackbar(
        "Could not update dashboard data, please contact support if this persists.",
        FeedbackType.Error
      );
      return null;
    } else {
      return updateMaintainDashboard({
        notifyOnNetworkStatusChange: true,
        variables: {
          id: dashId,
          input: {
            resourceIds,
            settings,
          },
        },
        refetchQueries: refetchQueries ?? [
          "page_maintain_dashboard",
          "page_maintain_time_series",
        ],
        onError: (e) => {
          pushSnackbar(e.message, FeedbackType.Error);
        },
      });
    }
  };
}

gql`
  fragment MaintainDashboardFragment on MaintainDashboard {
    id
    type
    resourceIds

    metrics {
      label
      field
      query
      description
      type
      unit
    }

    cameras {
      id
      name
    }
    appliances {
      id
      serialNumber
    }
    locations {
      id
      name
    }
    settings {
      hiddenResourceIds
      metrics
    }
  }
`;

gql`
  query page_maintain_dashboard($input: GetMaintainDashboardInput!) {
    maintainDashboard(input: $input) {
      ...MaintainDashboardFragment
    }
  }
`;

gql`
  query page_maintain_time_series(
    $input: GetMaintainDashboardInput!
    $metricInput: MaintainMetricTimeSeriesInput!
  ) {
    maintainMetricTimeSeries(input: $input, metricInput: $metricInput) {
      series
    }
  }
`;

gql`
  query page_maintain_metrics {
    appliances {
      id
      serialNumber
      health {
        online
      }
      location {
        id
        name
      }
    }
    cameras {
      id
      name
      status
      vendor

      location {
        id
        name
      }

      appliance {
        id
        serialNumber
      }

      tags {
        id
        name
      }
    }
  }
`;

gql`
  mutation createMaintainDashboard(
    $type: MaintainDashboardType!
    $input: MaintainDashboardInput!
  ) {
    createMaintainDashboard(type: $type, input: $input) {
      ...MaintainDashboardFragment
    }
  }
`;

gql`
  mutation updateMaintainDashboard($id: Int!, $input: MaintainDashboardInput!) {
    updateMaintainDashboard(id: $id, input: $input) {
      ...MaintainDashboardFragment
    }
  }
`;
