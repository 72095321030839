import mapboxgl from "mapbox-gl";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

import { AddCamera } from "@/components/MappingTool/AddCamera";
import { Layout } from "@/components/MappingTool/Mapping";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";

import {
  useDeleteLayoutCamMutation,
  useUpdateLayoutCameraMutation,
} from "@/generated-models";

export function EditCameraMarker({
  map,
  cam,
  layout,
}: {
  map: mapboxgl.Map;
  cam: any;
  layout: Layout;
}) {
  const { pushSnackbar } = useFeedback();
  const [updateLayoutCamera] = useUpdateLayoutCameraMutation();
  const [deleteLayoutCamera] = useDeleteLayoutCamMutation();
  const [marker, setMarker] = useState<mapboxgl.Marker | null>(null);
  const [element, setElement] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!element) return;

    const newMarker = new mapboxgl.Marker({
      element,
      draggable: true,
    })
      .setLngLat([cam.position.x, cam.position.y])
      .addTo(map);

    setMarker(newMarker);
    // eslint-disable-next-line
  }, [element]);

  useEffect(() => {
    return () => {
      map.dragPan.enable();
      map.scrollZoom.enable();
    };
    // eslint-disable-next-line
  }, []);

  return ReactDOM.createPortal(
    <div
      style={{ zIndex: 1 }}
      ref={(node) => {
        if (!map || !node) return;
        setElement(node);
        map.dragPan.disable();
        map.scrollZoom.disable();
      }}
    >
      {marker && (
        <AddCamera
          key={cam.id}
          locationId={layout.locationId}
          layoutId={layout.id}
          initialAngle={cam.angle}
          camera={cam.camera}
          offset={{
            top: 0,
            left: 0,
          }}
          mapMarker={marker}
          onUpdate={async (updateCallback) => {
            console.log("update called for edit cam", cam.camera.name);

            const args = updateCallback(cam);
            const [x, y] = marker.getLngLat().toArray();

            const camPosition = { x, y };
            const { errors } = await updateLayoutCamera({
              variables: {
                id: cam.id,
                input: {
                  layoutId: args.layoutId,
                  cameraId: args.cameraId,
                  angle: args.angle,
                  position: camPosition,
                },
              },
              optimisticResponse: {
                __typename: "Mutation",
                updateLayoutCamera: {
                  angle: args.angle,
                  cameraId: args.cameraId,
                  id: cam.id,
                  layoutId: args.layoutId,
                  position: {
                    ...camPosition,
                    __typename: "Point",
                  },
                  camera: cam.camera,
                  __typename: "LayoutCamera",
                },
              },
            }).catch((e) => ({ errors: [e] }));

            if (errors?.length) {
              pushSnackbar(
                "Something went wrong. Please try again",
                FeedbackType.Error
              );
            }
          }}
          onDelete={async () => {
            await deleteLayoutCamera({
              variables: { id: cam.id },
              optimisticResponse: {
                __typename: "Mutation",
                deleteLayoutCamera: cam.id,
              },
              update: (cache) => {
                cache.evict({
                  id: `LayoutCamera:${cam.id}`,
                });
                try {
                  cache.gc();
                } catch (e) {
                  console.log(e);
                }
              },
            }).catch((e) => ({ errors: [e] }));
          }}
          disableDrag={true}
        />
      )}
    </div>,
    map!.getCanvasContainer()
  );
}
