import { QueryResult } from "@apollo/client";
import { PlayCircleOutlineOutlined } from "@mui/icons-material";
import { Divider, Hidden } from "@mui/material";
import gql from "graphql-tag";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import {
  CASE_CLIP_ANNOTATION_BASE_FRAGMENT,
  CASE_CLIP_FRAGMENT,
  SHARED_CASE_CLIP_FRAGMENT,
} from "@/pages/Cases/caseFragments";

import { FullScreen } from "@/components/FullScreen";
import { Back10Button } from "@/components/Player/Back10Button";
import { BufferingIndicator } from "@/components/Player/BufferingIndicator";
import { CompletedOverlay } from "@/components/Player/CompletedOverlay";
import { DownloadButton } from "@/components/Player/DownloadButton";
import { Forward10Button } from "@/components/Player/Forward10Button";
import { FullscreenButton } from "@/components/Player/FullscreenButton";
import { KeyboardShortcuts } from "@/components/Player/KeyboardShortcuts";
import { MobileOverlay } from "@/components/Player/MobileOverlay";
import { PlayPauseReplayButton } from "@/components/Player/PlayPauseReplayButton";
import { PlaybackRateDisplay } from "@/components/Player/PlaybackRateDisplay";
import { PlayerAspect, PlayerBase } from "@/components/Player/PlayerBase";
import { usePlayerMobileMode } from "@/components/Player/PlayerControls";
import { PlayerControls } from "@/components/Player/PlayerControls.1";
import { PlayerMetadata } from "@/components/Player/PlayerMetadata";
import {
  PlaybackRateSetting,
  PlayerSettingsButton,
  QualitySetting,
} from "@/components/Player/PlayerSettingsButton";
import { ProgressBar } from "@/components/Player/ProgressBar";
import { ProgressDisplay } from "@/components/Player/ProgressDisplay";
import { ReplayButton } from "@/components/Player/ReplayButton";
import { ShareClipButton } from "@/components/Player/ShareClipButton";
import { ShareTrayButton } from "@/components/Player/ShareTrayButton";
import { SnapshotButton } from "@/components/Player/SnapshotButton";
import { TopRightTray } from "@/components/Player/TopRightTray";
import { VideoOverlay } from "@/components/Player/VideoOverlay";
import { VideoPlayer } from "@/components/Player/VideoPlayer";
import { VolumeButton } from "@/components/Player/VolumeButton";
import { DigitalPtzButton } from "@/components/Player/ZoomButton";
import { WallclockTimer } from "@/components/View/WallclockTimer";

import {
  CaseClipQuery,
  GetSharedCaseClipQuery,
  useCaseClipQuery,
  useGetSharedCaseClipQuery,
} from "@/generated-models";

import {
  ClipAnnotationDisplayOverlay,
  MobileAnnotationInput,
  MobileClipAnnotationDisplay,
} from "./AnnotationDisplay";
import { useAnnotationContext } from "./CreateAnnotationContextProvider";
import { CreateAnnotationOverlay } from "./CreateAnnotationOverlay";

export function CaseClipPlayer({
  clipId,
  caseCommentPermission,
  anonymous,
}: {
  clipId: number;
  caseCommentPermission: boolean;
  anonymous: boolean;
}) {
  const Component = anonymous
    ? AnonymousCaseClipPlayerData
    : CaseClipPlayerData;

  return (
    <Component clipId={clipId} caseCommentPermission={caseCommentPermission} />
  );
}

function CaseClipPlayerData({
  clipId,
  caseCommentPermission,
}: {
  clipId: number;
  caseCommentPermission: boolean;
}) {
  const caseClipQuery = useCaseClipQuery({
    variables: { id: clipId },
  });

  return (
    <CaseClipPlayerInner
      caseClipQuery={caseClipQuery}
      caseCommentPermission={caseCommentPermission}
    />
  );
}

function AnonymousCaseClipPlayerData({ clipId }: { clipId: number }) {
  const { token } = useParams();
  const caseClipQuery = useGetSharedCaseClipQuery({
    variables: { id: clipId, token: token ?? "" },
    skip: !token,
  });

  return (
    <CaseClipPlayerInner
      caseClipQuery={caseClipQuery}
      caseCommentPermission={true}
    />
  );
}

function CaseClipPlayerInner({
  caseClipQuery,
  caseCommentPermission,
}: {
  caseClipQuery:
    | QueryResult<CaseClipQuery, any>
    | QueryResult<GetSharedCaseClipQuery, any>;
  caseCommentPermission: boolean;
}) {
  const playerMobileMode = usePlayerMobileMode();

  const { placingAnnotation } = useAnnotationContext();

  const annotationsShownState = useState(true);
  const [annotationsShown] = annotationsShownState;

  const { data } = caseClipQuery;

  const mappedStills = React.useMemo(() => {
    if (!data?.caseClip) return;
    const startTimestamp = new Date(data.caseClip.startTime).getTime();
    return data.caseClip.stills.map((s) => ({
      timestamp: (new Date(s.timestamp).getTime() - startTimestamp) / 1000,
      src: s.src,
    }));
  }, [data?.caseClip]);

  if (!data?.caseClip) {
    return (
      <PlayerBase>
        <PlayerAspect maxHeight="calc(100vh - 250px)">
          <div className="bg-black w-full h-full">&nbsp;</div>
        </PlayerAspect>
      </PlayerBase>
    );
  }

  const { feeds, camera } = data.caseClip;
  const commonButtonProps = {
    cameraId: camera.id,
    startTime: data.caseClip.startTime,
    endTime: data.caseClip.endTime,
  };

  const overlayActive = Boolean(
    placingAnnotation || (annotationsShown && data.caseClip.annotations.length)
  );
  return (
    <PlayerBase>
      <FullScreen className="flex grow flex-col">
        <div className="flex relative w-full grow">
          <PlayerAspect maxHeight="calc(100vh - 250px)">
            <VideoPlayer
              sources={feeds && { ...feeds, webRTC: null }}
              wallclockStartTime={new Date(data.caseClip.startTime)}
              muxConfig={{
                cameraId: camera.id,
                playerName: "Cases",
                vodStart: data.caseClip.startTime,
                vodEnd: data.caseClip.endTime,
              }}
            />
          </PlayerAspect>
          <KeyboardShortcuts />

          {!placingAnnotation && (
            <>
              <CompletedOverlay>
                <ReplayButton fontSize={60} />
              </CompletedOverlay>
              <MobileOverlay>
                <Back10Button fontSize={36} />
                <PlayPauseReplayButton fontSize={60} />
                <Forward10Button fontSize={36} />
              </MobileOverlay>
            </>
          )}

          {overlayActive && (
            <VideoOverlay>
              {placingAnnotation ? (
                <CreateAnnotationOverlay
                  caseId={data.caseClip.case.id}
                  clipId={data.caseClip.id}
                />
              ) : (
                <ClipAnnotationDisplayOverlay
                  annotations={data.caseClip.annotations}
                />
              )}
              <BufferingIndicator />
            </VideoOverlay>
          )}

          {caseCommentPermission && !placingAnnotation && (
            <TopRightTray>
              <ShareTrayButton>
                <ShareClipButton {...commonButtonProps} />
                <Divider className="bg-gray-800" />
                <DownloadButton {...commonButtonProps} />
              </ShareTrayButton>
              <SnapshotButton
                cameraId={camera.id}
                cameraName={camera.name}
                timezone={camera.location.timezone}
              />
            </TopRightTray>
          )}

          <PlayerControls
            disabled={placingAnnotation}
            className={placingAnnotation ? "hidden" : undefined}
          >
            {playerMobileMode && (
              <div className="flex w-full justify-end z-10 relative">
                <DigitalPtzButton key={feeds?.tunnel} />
                <VolumeButton enabled={camera.audioControlEnabled ?? false} />
                <PlayerSettingsButton>
                  <PlaybackRateSetting />
                  <QualitySetting />
                </PlayerSettingsButton>
                <FullscreenButton />
              </div>
            )}
            <ProgressBar stills={mappedStills} />

            {!playerMobileMode && (
              <div
                style={{
                  background:
                    "linear-gradient(180deg, rgba(0, 0, 0, 0.68) 0%, #000000 90%)",
                }}
                className="py-0.5 flex-center w-full"
              >
                <Back10Button />
                <PlayPauseReplayButton />
                <Forward10Button />

                <div className="mx-2">
                  <ProgressDisplay />
                </div>
                <PlaybackRateDisplay />

                <div className="flex grow items-center justify-end">
                  <DigitalPtzButton key={feeds?.tunnel} />
                  <VolumeButton enabled={camera.audioControlEnabled ?? false} />
                  <PlayerSettingsButton>
                    <PlaybackRateSetting />
                    <QualitySetting />
                  </PlayerSettingsButton>
                  <FullscreenButton />
                </div>
              </div>
            )}
          </PlayerControls>
        </div>
        <PlayerMetadata
          className="dark bg-[#1b1b1b] gap-1 py-2"
          disabled={placingAnnotation}
        >
          <PlayCircleOutlineOutlined />
          <Hidden smDown>
            <div className="opacity-50">Camera:</div>
          </Hidden>
          <div className="text-base font-bold truncate">{camera.name}</div>
          <div className="grow" />
          <WallclockTimer timezone={camera.location.timezone} />
        </PlayerMetadata>
      </FullScreen>
      <Hidden mdUp>
        {placingAnnotation ? (
          <MobileAnnotationInput
            clipId={data.caseClip.id}
            caseId={data.caseClip.case.id}
          />
        ) : (
          <MobileClipAnnotationDisplay
            annotations={data.caseClip.annotations}
          />
        )}
      </Hidden>
    </PlayerBase>
  );
}

gql`
  query caseClip($id: Int!) {
    caseClip(id: $id) {
      ...CaseClip
    }
  }
  ${CASE_CLIP_FRAGMENT}
`;

gql`
  query getSharedCaseClip($id: Int!, $token: String!) {
    caseClip: sharedCaseClip(id: $id, token: $token) {
      ...SharedCaseClip
    }
  }
  ${SHARED_CASE_CLIP_FRAGMENT}
`;

gql`
  mutation createCaseClipAnnotation(
    $clipId: Int
    $screenshotId: Int
    $value: CaseClipAnnotationInput!
  ) {
    createCaseClipAnnotation(
      caseClipId: $clipId
      screenshotId: $screenshotId
      value: $value
    ) {
      annotation {
        ...CaseClipAnnotationBase
      }
      addedCollaborators {
        id
        name
        email
      }
    }
  }
  ${CASE_CLIP_ANNOTATION_BASE_FRAGMENT}
`;

gql`
  query subsequentAnnotationLabel($caseId: Int!) {
    subsequentAnnotationLabel(caseId: $caseId)
  }
`;
