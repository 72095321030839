import { Provider } from "jotai";
import React from "react";

import { MuxConfiguration } from "@/util/useMuxMetadata";

import { BufferingIndicator } from "@/components/Player/BufferingIndicator";
import { MobileCenterButtons } from "@/components/Player/MobileCenterButtons";
import { PlayPauseReplayButton } from "@/components/Player/PlayPauseReplayButton";
import { PlayerBase } from "@/components/Player/PlayerBase";
import { ProgressBar } from "@/components/Player/ProgressBar";
import { VideoPlayer, VideoPlayerProps } from "@/components/Player/VideoPlayer";

import { CameraFeeds } from "@/generated-models";

export function BasicPlayer({
  className,
  ...props
}: {
  sources?: Pick<CameraFeeds, "tunnel" | "local"> | null;
  poster?: string;
  muxConfig?: MuxConfiguration;
  className?: string;
  forceMuted?: boolean;
  autoPlay?: boolean;
}) {
  return (
    <PlayerBase className={className}>
      <Provider>
        <VideoPlayer {...props} forceAutoQuality />

        <MobileCenterButtons>
          <PlayPauseReplayButton />
        </MobileCenterButtons>

        <BufferingIndicator />

        <div className="absolute bottom-0 left-0 right-0">
          <ProgressBar />
        </div>
      </Provider>
    </PlayerBase>
  );
}

export function NonInteractivePlayer(props: VideoPlayerProps) {
  return (
    <PlayerBase>
      <Provider>
        <VideoPlayer {...props} />
      </Provider>
    </PlayerBase>
  );
}
