import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import { Button, IconButton, Link, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import { MaintainDatagrid } from "@/pages/Maintain/Datagrid/MaintainDatagrid";
import { MaintainToolbar } from "@/pages/Maintain/Datagrid/MaintainToolbar";
import MaintainDeviceStatusPopover from "@/pages/Maintain/MaintainDeviceStatusPopover";
import {
  MaintainAppliance,
  useMaintainDeviceStatistics,
  useMaintainFilteredDevices,
} from "@/pages/Maintain/hooks";

import { useMe } from "@/components/Auth";
import { ErrorMessage } from "@/components/ErrorMessage";
import { StatusChip } from "@/components/StatusChip";

import { CameraStatus, Role } from "@/generated-models";
import { usePrefixOrgSlug } from "@/hooks/useOrgRouteBase";
import { usePermissions } from "@/hooks/usePermissions";
import { ContentWrapper } from "@/layout/ContentWrapper";

interface CameraCount {
  [key: string]: number;
}

let timeout: ReturnType<typeof setTimeout> | null = null;

export function MaintainApplianceDatagrid() {
  const hasPermissions = usePermissions();
  const prefixOrgSlug = usePrefixOrgSlug();
  const { iotCoreDisabled } = useFlags();
  const [selectedSerial, setSelectedSerial] = useState("");
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const me = useMe();
  const isSupport = me && me.role >= Role.Support;

  const {
    loading,
    filteredAppliances,
    cameras,
    error,
  } = useMaintainFilteredDevices();
  const {
    appliances: { online, offline },
  } = useMaintainDeviceStatistics(true);

  const counts = useMemo(() => {
    const result: CameraCount = {};
    cameras?.forEach((c) => {
      const serial = c.appliance.serialNumber;
      result[serial] = (result[serial] || 0) + 1;
    });

    return result;
  }, [cameras]);

  if (error) {
    return (
      <ContentWrapper>
        <ErrorMessage title="Error" description={error?.message} />
      </ContentWrapper>
    );
  }
  if (!filteredAppliances && !loading) {
    return (
      <ErrorMessage
        title="Unable to fetch appliance data"
        description="Please try again later"
      />
    );
  }

  const columns: GridColDef<MaintainAppliance>[] = [
    {
      field: "serialNumber",
      headerName: "Serial Number",
      width: 175,
      renderCell: ({ value }) => {
        if (!hasPermissions("devices_manage")) {
          return value;
        }
        return (
          <Link
            className="cursor-pointer"
            onClick={() => onOpenConfigureAppliance(value, prefixOrgSlug)}
          >
            {value}
          </Link>
        );
      },
    },
    {
      field: "location",
      headerName: "Location",
      flex: 2,
      valueGetter: ({ row }) => row.location.name,
    },
    {
      field: "license",
      headerName: "License SKU",
      width: 150,
      valueGetter: ({ row }) => row.license?.sku,
    },
    {
      field: "version",
      headerName: "Version",
      width: 125,
    },
    // {
    //   field: "id",
    //   headerName: "Storage Purchased",
    //   width: 200,
    //   valueGetter: ({ value }) => `TODO Days`,
    // },
    {
      field: "cameras",
      headerName: "Cameras Connected",
      width: 230,
      valueGetter: ({ row }) => `${counts[row.serialNumber] ?? 0} Cameras`,
    },
    {
      field: "health",
      headerName: "Status",
      width: 145,
      valueGetter: ({ row }) => row.health.online,
      renderCell: ({ row, value }) => {
        const status = value ? CameraStatus.Online : CameraStatus.Offline;
        return (
          <StatusChip
            onMouseEnter={(event) => {
              if (status === CameraStatus.Offline) {
                if (timeout) clearTimeout(timeout);
                timeout = setTimeout(() => {
                  setSelectedSerial(row.serialNumber);
                  setAnchorEl(event.target as HTMLDivElement);
                }, 400);
              }
            }}
            onMouseLeave={() => {
              if (timeout) {
                clearTimeout(timeout);
                timeout = null;
              }
            }}
            status={status}
          />
        );
      },
    },
  ];

  if (isSupport && iotCoreDisabled) {
    columns.push({
      field: "config",
      headerName: "Config",
      headerAlign: "center",
      cellClassName: "flex items-center justify-center",
      sortable: false,
      disableReorder: true,
      type: "string",
      renderCell: ({ row }) => {
        return (
          <IconButton
            color="primary"
            component={RouterLink}
            size="small"
            to={prefixOrgSlug(`/maintain/appliances/editor?selected=${row.id}`)}
          >
            <EditNoteRoundedIcon />
          </IconButton>
        );
      },
    });
  }

  return (
    <>
      <MaintainToolbar loading={loading} online={online} offline={offline} />
      <MaintainDatagrid
        initialState={{
          pagination: { paginationModel: { pageSize: 32 } },
          sorting: {
            sortModel: [{ field: "health", sort: "asc" }],
          },
        }}
        loading={loading}
        columns={columns}
        rows={filteredAppliances || []}
      />
      <MaintainDeviceStatusPopover
        type="Appliance"
        open={!!anchorEl}
        anchorEl={anchorEl}
        offline
        onClose={() => setAnchorEl(null)}
      >
        <ApplianceUnreachable
          selectedSerial={selectedSerial}
          onClick={() => setAnchorEl(null)}
        />
      </MaintainDeviceStatusPopover>
    </>
  );
}

export function ApplianceUnreachable({
  selectedSerial,
  onClick,
}: {
  selectedSerial: string;
  onClick?: () => void;
}) {
  const hasPermission = usePermissions();
  const prefixOrgSlug = usePrefixOrgSlug();

  return (
    <>
      <Typography variant="body1" className="text-sm">
        Appliance unreachable, check connectivity or reboot device.
      </Typography>
      {hasPermission("devices_manage") && (
        <div className="flex justify-end mt-3">
          <Button
            size="small"
            variant="outlined"
            color="primary"
            classes={{ outlined: "px-6" }}
            onClick={() => {
              onOpenConfigureAppliance(selectedSerial, prefixOrgSlug);
              onClick?.();
            }}
          >
            Configure Appliance
          </Button>
        </div>
      )}
    </>
  );
}

function onOpenConfigureAppliance(
  serial: string,
  addPrefix?: (path: string) => string
) {
  window.open(
    addPrefix
      ? addPrefix(`/localproxy/appliance/${serial}`)
      : `/localproxy/appliance/${serial}`,
    serial,
    "directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes"
  );
}
