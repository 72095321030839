import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useMemo } from "react";

import { OrganizationIndustry } from "@/generated-models";

const label = "Industry";

export const OrganizationIndustryLabels = {
  [OrganizationIndustry.CarWash]: "Car Wash",
  [OrganizationIndustry.Industrial]: "Industrial",
  [OrganizationIndustry.Retail]: "Retail",
  [OrganizationIndustry.Sled]: "SLED",
};

export function OrganizationIndustryDropdown({
  className,
  value,
  disabled,
  onChange,
}: {
  className?: string;
  value?: OrganizationIndustry | null;
  disabled?: boolean;
  onChange?: (value: OrganizationIndustry) => void;
}) {
  const items = useMemo(() => {
    return Object.entries(OrganizationIndustry).map(([label, value]) => {
      return {
        id: value,
        name: label,
      };
    });
  }, []);

  return (
    <FormControl className={className}>
      <InputLabel shrink>{label}</InputLabel>
      <Select
        size="small"
        className="rounded"
        value={value}
        disabled={disabled}
        onChange={(e) => {
          const newValue = e.target.value as OrganizationIndustry;
          onChange?.(newValue);
        }}
        notched
        variant="outlined"
        label={label}
      >
        {items.map((i) => (
          <MenuItem value={i.id} key={i.id}>
            <div className="flex items-center">
              <div className="ml-2 truncate">
                {OrganizationIndustryLabels[i.id] || i.name}
              </div>
            </div>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
