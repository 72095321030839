import { Hidden, Tab, Tabs, Typography } from "@mui/material";
import clsx from "clsx";
import { utcToZonedTime } from "date-fns-tz";
import { format } from "date-fns/fp";
import { useFlags } from "launchdarkly-react-client-sdk";
import { sortBy } from "lodash/fp";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { dateTimeFormat, timeFormat } from "@/util/date";
import { formatDurationSecs } from "@/util/formatDurationSecs";
import { useBreakpoints } from "@/util/useBreakpoints";
import { buildVodUrl } from "@/util/vod";

import { DataGrid } from "@/components/DataGrid/DataGrid";
import { PlayVideoButton } from "@/components/PlayVideoButton";
import {
  DataGridContextualPlayer,
  useDataGridContextPlayer,
} from "@/components/VideoWall/ContextualVideoPlayer";

import { PresenceMetrics, useCameraByIdQuery } from "@/generated-models";

import { DatagridStill } from "../Datagrid";
import { useIntelligenceTimeframeText } from "../hooks";
import { footageIsAvailable } from "../utils";

const formatDate = format("MMM d, y");

type Segment = { startMs: number; endMs: number };

enum CurrentTab {
  PRESENCE,
  ABSENCE,
}

export function PresenceDatagrid({
  objectIds,
  cameraId,
  timezone,
  buckets,
}: {
  objectIds?: string[] | null;
  cameraId: number;
  timezone: string;
  buckets: PresenceMetrics["presenceBuckets"];
}) {
  const navigate = useNavigate();
  const timeframeText = useIntelligenceTimeframeText();
  const [tab, setTab] = useState<CurrentTab>(CurrentTab.PRESENCE);
  const { fitsDesktop } = useBreakpoints();
  const { contextualPlayer } = useFlags();
  const { data, error, loading } = useCameraByIdQuery({
    variables: { cameraId },
  });
  const {
    open,
    handleOpen,
    handleClose,
    playerProps,
  } = useDataGridContextPlayer();

  const rows = useMemo(() => {
    let segments: Segment[] = sortBy(
      "startMs",
      buckets.flatMap((b) => b.intervals)
    );

    if (tab === CurrentTab.ABSENCE) {
      // Convert to absence segments
      const bucketsStart = buckets[0].bucketMs;
      const bucketsEnd = Math.min(
        buckets[buckets.length - 1].bucketEndMs,
        Date.now()
      );
      const absenceSegments: Segment[] = [];

      let absenceStartMs = bucketsStart;
      for (let i = 0; i < segments.length; i++) {
        // Create absence segment when gap is found between presence segments
        if (segments[i].startMs > absenceStartMs) {
          absenceSegments.push({
            startMs: absenceStartMs,
            endMs: segments[i].startMs,
          });
        }
        // Update absenceStartMs if current segment has higher endMs than previous segments
        if (segments[i].endMs > absenceStartMs) {
          absenceStartMs = segments[i].endMs;
        }
      }

      // Add final absence segment to complete the time range
      if (absenceStartMs < bucketsEnd) {
        absenceSegments.push({ startMs: absenceStartMs, endMs: bucketsEnd });
      }

      segments = absenceSegments;
    }

    return segments.map(({ startMs, endMs }, index) => ({
      startMs,
      endMs,
      id: index,
      date: startMs,
      duration: Math.round((endMs - startMs) / 1000),
    }));
  }, [buckets, tab]);

  return (
    <div className="w-full">
      <div className="flex items-center justify-between mb-3">
        <div className="flex gap-5 items-center">
          <Tabs
            value={tab}
            onChange={(_, newTab: CurrentTab) => setTab(newTab)}
          >
            <Tab
              className={clsx(
                "hover:bg-blue-light text-lg",
                tab !== CurrentTab.PRESENCE
                  ? "text-primary opacity-100 font-normal"
                  : "font-bold"
              )}
              value={CurrentTab.PRESENCE}
              label="Presence"
            />
            <Tab
              className={clsx(
                "hover:bg-blue-light text-lg",
                tab !== CurrentTab.ABSENCE
                  ? "text-primary opacity-100 font-normal"
                  : "font-bold"
              )}
              value={CurrentTab.ABSENCE}
              label="Absence"
            />
          </Tabs>
        </div>
        <Hidden mdDown>
          <Typography className="font-medium text-base leading-5 text-text">
            {rows.length} results on {timeframeText}
          </Typography>
          {/* Spacer to center middle item */}
          <div className="w-40" />
        </Hidden>
      </div>
      <DataGrid
        columnVisibilityModel={{
          id: fitsDesktop,
          date: fitsDesktop,
          startMs: fitsDesktop,
          endMs: fitsDesktop,
          video: fitsDesktop,
        }}
        classes={{ row: "cursor-pointer" }}
        getRowId={({ id }) => id}
        rowHeight={fitsDesktop ? 58 : 115}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
          sorting: { sortModel: [{ field: "duration", sort: "desc" }] },
        }}
        columns={[
          {
            field: "id",
            sortable: false,
            headerClassName: "invisible",
            cellClassName: "pl-0",
            renderCell: ({ row: { startMs, endMs } }) => (
              <DatagridStill
                cameraId={cameraId}
                start={new Date(startMs)}
                end={new Date(endMs)}
              />
            ),
          },
          {
            field: "duration",
            flex: 2,
            renderHeader: () => {
              const headerName = `Time ${
                tab === CurrentTab.PRESENCE ? "Present" : "Absent"
              }`;
              return fitsDesktop ? (
                headerName
              ) : (
                <span>
                  Sort By:{" "}
                  <strong className="text-primary">{headerName}</strong>
                </span>
              );
            },
            renderCell: ({ row: { startMs, endMs, duration } }) => {
              const length = formatDurationSecs(duration);
              if (fitsDesktop) return length;

              // Mobile
              return (
                <div className="flex items-center justify-between gap-x-4">
                  <div className="w-[155px] border border-blue-500">
                    <DatagridStill
                      cameraId={cameraId}
                      start={new Date(startMs)}
                      end={new Date(endMs)}
                    />
                  </div>
                  <div className="flex flex-col gap-y-2">
                    <strong>{length}</strong>
                    <div className="text-sm leading-4">
                      {dateTimeFormat.format(utcToZonedTime(startMs, timezone))}
                    </div>
                    <div className="text-sm leading-4">
                      {dateTimeFormat.format(utcToZonedTime(endMs, timezone))}
                    </div>
                    <PlayVideoButton
                      available={footageIsAvailable(startMs, data?.camera)}
                    />
                  </div>
                </div>
              );
            },
          },
          {
            field: "date",
            flex: 2,
            headerName: "Date",
            renderCell: ({ row: { date } }) =>
              formatDate(utcToZonedTime(date, timezone)),
          },
          {
            field: "startMs",
            flex: 2,
            headerName: "Start Time",
            renderCell: ({ row: { startMs } }) =>
              timeFormat.format(utcToZonedTime(startMs, timezone)),
          },
          {
            field: "endMs",
            flex: 2,
            headerName: "End Time",
            renderCell: ({ row: { endMs } }) =>
              timeFormat.format(utcToZonedTime(endMs, timezone)),
          },
          {
            field: "video",
            sortable: false,
            flex: 2,
            headerName: "Video",
            renderCell: ({ row: { startMs } }) => {
              return (
                <PlayVideoButton
                  available={footageIsAvailable(startMs, data?.camera)}
                />
              );
            },
          },
        ]}
        rows={rows}
        onRowClick={({ row: { startMs, endMs, duration } }) => {
          if (!footageIsAvailable(startMs, data?.camera)) {
            return;
          }

          if (contextualPlayer && data && !error && !loading) {
            const length = formatDurationSecs(duration);

            handleOpen({
              startTime: new Date(startMs).toISOString(),
              endTime: new Date(endMs).toISOString(),
              camera: data.camera,
              length: length,
              subjects: objectIds,
            });
          } else {
            navigate(buildVodUrl(cameraId, startMs, endMs, objectIds));
          }
        }}
      />
      <DataGridContextualPlayer
        open={open}
        handleClose={handleClose}
        playerProps={playerProps}
      />
    </div>
  );
}
