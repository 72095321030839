import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Drawer, IconButton, Typography } from "@mui/material";

import { getDetails } from "./AuditDetails";
import { AuditLogEvent } from "./AuditLogsDatagrid";

export type Details = {
  label: string;
  value: string;
};

export const AuditLogDetailsDrawer = ({
  event,
  closeDrawer,
}: {
  event?: AuditLogEvent;
  closeDrawer: () => void;
}) => {
  if (!event) return null;

  const details = getDetails(event);

  return (
    <Drawer
      classes={{
        paper:
          "bg-blue-light w-[285px] md:pt-[80px] border-0 shadow-[rgba(0,0,0,0.25)_0px_4px_15px]",
      }}
      hideBackdrop
      anchor="right"
      open={true}
      onClose={closeDrawer}
    >
      <div className="p-2 h-full flex flex-col gap-5">
        <div className="flex justify-between items-center">
          <Typography className="pl-2 font-bold text-primary text-lg leading-[23px]">
            Detail
          </Typography>
          <IconButton
            className="text-primary"
            size="small"
            onClick={() => {
              closeDrawer();
            }}
          >
            <HighlightOffIcon />
          </IconButton>
        </div>
        <div className="flex flex-col">
          <div className="grid grid-cols-1 bg-white rounded-lg border border-solid border-blue-medium px-2 py-4 divide-y">
            {details.map((d) => (
              <div className="py-2" key={d.label}>
                <Typography className="text-[#757575] font-light text-sm leading-4">
                  {d.label}
                </Typography>
                <Typography>{d.value}</Typography>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Drawer>
  );
};
