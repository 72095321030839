import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  Popover,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";

import EditableText from "@/components/EditableText";

import { Appliance } from "@/generated-models";
import { usePrefixOrgSlug } from "@/hooks/useOrgRouteBase";

interface CameraProxyPopoverProps {
  open: boolean;
  onClose: () => void;
  anchorEl?: null | Element | ((element: Element) => Element);
  deviceId: number;
  deviceMac: string;
  homeAppliance?: Pick<Appliance, "id" | "serialNumber" | "health">;
  reachableAppliances: Pick<Appliance, "id" | "serialNumber" | "health">[];
  localTarget: string;
}

enum ConnectionType {
  LOCAL = "local",
  REMOTE = "remote",
}

const SPOT_UNIVEW_PREFIXES = ["0ccc47b"];

export default function CameraProxyPopover({
  open,
  onClose,
  anchorEl,
  deviceMac,
  deviceId,
  localTarget,
  homeAppliance,
  reachableAppliances,
}: CameraProxyPopoverProps) {
  const [isRemote, setIsRemote] = useState(false);
  const [target, setTarget] = useState(`${localTarget}:80`);
  const native = SPOT_UNIVEW_PREFIXES.includes(
    deviceMac.replace(/\W/g, "").slice(0, 7).toLowerCase()
  );
  const prefixOrgSlug = usePrefixOrgSlug();

  // Prioritize which appliance to use:
  // 1. Online Home Appliance
  // 2. Any other reachable online appliance
  // 3. Offline Home Appliance
  // 4. Any other reachable offline appliance
  const appliance = useMemo(() => {
    if (homeAppliance?.health?.online) {
      return homeAppliance;
    }

    return (
      reachableAppliances.find((appliance) => appliance.health.online) ||
      homeAppliance ||
      reachableAppliances[0]
    );
  }, [reachableAppliances, homeAppliance]);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onClose={onClose}
      classes={{ paper: "flex flex-col rounded-xl py-4 px-4 w-[350px]" }}
    >
      <Box
        style={{ boxSizing: "border-box" }}
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h6" className="m-0">
          Camera Configuration
        </Typography>
        <div className="grow" />
        <IconButton size="small" onClick={onClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>

      <div className="h-3" />
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="connection-type-group-label"
          value={isRemote ? ConnectionType.REMOTE : ConnectionType.LOCAL}
          name="connection-type-group"
          onChange={(e) => {
            setIsRemote(e.target.value === ConnectionType.REMOTE);
          }}
        >
          <FormControlLabel
            classes={{ label: !isRemote ? "font-bold" : "" }}
            label="Local"
            value={"local"}
            control={<Radio color="primary" />}
          />
          <FormControlLabel
            classes={{ label: isRemote ? "font-bold" : "" }}
            label="Remote"
            value={ConnectionType.REMOTE}
            control={<Radio color="primary" />}
          />
        </RadioGroup>
      </FormControl>

      <div className="flex gap-2 mt-2 w-full items-center">
        <div className="w-1/2">
          <EditableText
            TextFieldProps={{
              label: "Endpoint",
              size: "small",
              placeholder: `${localTarget}:80`,
            }}
            initialValue={target}
            onSubmit={(value) => {
              setTarget(value);
            }}
          />
        </div>

        <Button
          className="w-1/2"
          color="primary"
          variant="contained"
          onClick={() => {
            window.open(
              isRemote
                ? prefixOrgSlug(
                    `/localproxy/appliance/${appliance?.serialNumber}/${target}?d=${deviceId}&native=${native}`
                  )
                : `http://${target}`,
              "Scanned Device Configuration",
              "directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes"
            );
          }}
        >
          {isRemote ? "Navigate Remotely" : "Navigate"}
        </Button>
      </div>

      <Typography variant={"body1"} className="mt-4 text-[0.625rem]">
        {isRemote ? (
          "*A remote connection may not always be possible in network environments. Use a local connection if remote access fails."
        ) : (
          <>
            *For best results, start with these camera setttings.
            <br />
            Resolution:
            <strong>1080p</strong>, framerate: <strong>15 FPS</strong>, bitrate:{" "}
            <strong>less than 1 mbps</strong>
          </>
        )}
      </Typography>
    </Popover>
  );
}
