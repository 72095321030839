import { Backdrop } from "@mui/material";
import { useField } from "formik";
import gql from "graphql-tag";
import React, { useEffect, useMemo } from "react";

import { filterFalsy } from "@/util/filterFalsy";

import { aiAlertTypes } from "@/pages/Alerts/constants";

import { CamDrawer } from "@/components/CamDrawer/CamDrawerBase";
import { CamWrapperProps } from "@/components/CamDrawer/CamDrawerList";
import { CamDrawerListDescription } from "@/components/CamDrawer/CamDrawerListDescription";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { DisabledTooltip } from "@/components/shared/CustomTooltips";

import {
  AlertCameraInput,
  AlertDetectionCountOperator,
  AlertFeature,
  AlertType,
  useAlertsCameraListQuery,
} from "@/generated-models";
import { useFieldValue } from "@/hooks/useFieldValue";

function useAlertTypeAndFeatures() {
  const alertType = useFieldValue<AlertType>("type");
  const countOperator = useFieldValue<AlertDetectionCountOperator>("operator");
  const thresholdSeconds = useFieldValue<number | null>("thresholdSeconds");
  const isAdvancedAiAlert = useMemo(() => {
    return (
      aiAlertTypes.has(alertType) &&
      (countOperator !== AlertDetectionCountOperator.Any ||
        Boolean(thresholdSeconds))
    );
  }, [alertType, countOperator, thresholdSeconds]);
  return { alertType, countOperator, thresholdSeconds, isAdvancedAiAlert };
}

export function AlertsCamDrawer({
  open,
  onOpen,
  onClose,
}: {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
}) {
  const [, { value: selectedCameras }, { setValue }] = useField<
    AlertCameraInput[]
  >("cameras");
  const {
    alertType,
    isAdvancedAiAlert,
    countOperator,
    thresholdSeconds,
  } = useAlertTypeAndFeatures();
  const { data, loading } = useAlertsCameraListQuery({
    variables: {
      alertConfig: {
        type: alertType,
        features: [
          countOperator !== AlertDetectionCountOperator.Any &&
            AlertFeature.Counting,
          Boolean(thresholdSeconds) && AlertFeature.Threshold,
        ].filter(filterFalsy),
      },
    },
  });
  const selectedCameraIdsKey = selectedCameras.map((c) => c.id).join();

  const cameras = useMemo(() => {
    return data?.cameras.map((c) => {
      const selected = selectedCameras.some(
        (selectedCam) => selectedCam.id === c.id
      );
      return {
        ...c,
        selected,
        disabled: !c.alertEligibility,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, selectedCameraIdsKey, alertType]);

  useEffect(() => {
    // Remove selected cams that are disabled.
    // Note: the `decoratedData` memo above will set cam.selected=false
    // if the cam.disabled===true. This effect serves the purpose of syncing
    // that selected state back into the Formik state.
    const selectedCamIds = cameras?.filter((c) => c.selected).map((c) => c.id);
    if (selectedCamIds && selectedCamIds.length !== selectedCameras.length) {
      setValue(selectedCameras.filter((c) => selectedCamIds.includes(c.id)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cameras, selectedCameraIdsKey, setValue]);

  let camDrawerList: React.ReactNode;
  if (loading) {
    camDrawerList = <Loading>Fetching Cams</Loading>;
  } else if (cameras) {
    camDrawerList = (
      <CamDrawer.List
        cameras={cameras}
        CamWrapper={CamWrapper}
        // CamStatus={CamStatus}
      />
    );
  } /* if (error) */ else {
    camDrawerList = (
      <ErrorMessage
        title="Oops..."
        description="Something went wrong loading cameras. Please try again later."
      />
    );
  }

  return (
    <>
      <Backdrop open={open} onClick={onClose} className="z-1" />
      <CamDrawer open={open} onOpen={onOpen} onClose={onClose}>
        <CamDrawer.Filters>
          <CamDrawer.GroupSelector />
          <CamDrawer.SearchBox placeholder="Search Cameras" fullWidth />
          <CamDrawer.ListControls
            cameras={cameras ?? []}
            selectIds={(ids) =>
              setValue([
                ...selectedCameras,
                ...ids.map((id) => ({ id, zoneIds: [] })),
              ])
            }
            deselectIds={(ids) =>
              setValue(selectedCameras.filter((c) => !ids.includes(c.id)))
            }
          />
          <CamDrawer.SelectedOnlyFilter />
        </CamDrawer.Filters>
        {aiAlertTypes.has(alertType) && (
          <CamDrawerListDescription
            description={
              isAdvancedAiAlert
                ? "Only cameras with AI licenses and capabilities are shown below."
                : "Only cameras with AI capabilities are shown below"
            }
          />
        )}
        {camDrawerList}
      </CamDrawer>
    </>
  );
}

// function CamStatus(props: CamStatusProps) {
//   if (props.cam.disabled) {
//     return (
//       <div className="flex items-center gap-1 capitalize font-medium text-xs">
//         <ErrorIcon className="text-[16px] opacity-50 text-warning" />
//         {props.cam.disabled}
//       </div>
//     );
//   }
//   return <DefaultCamStatus {...props} />;
// }

function CamWrapper({ cam, children }: CamWrapperProps) {
  const [
    ,
    { value: selectedCameras },
    { setValue: setSelectedCameras },
  ] = useField<AlertCameraInput[]>("cameras");
  // Hide all disabled cams
  // The disabled state + tooltip code here is technically redundant. It's still
  // in here because Edo hopes that at some point we'll decide to show disabled
  // states + reasons why a certain camera is disabled.
  // UI + Product have decided to hide disabled cams for now.
  return cam.disabled ? null : (
    <DisabledTooltip
      disabled={Boolean(cam.disabled)}
      title="Contact your customer support rep to enable AI for this camera"
      classes={{
        popper: "pointer-events-none",
      }}
    >
      <label>
        <input
          type="checkbox"
          data-testid={`cam-${cam.id}`}
          checked={selectedCameras.some((s) => s.id === cam.id)}
          onChange={(event) => {
            if (event.target.checked) {
              setSelectedCameras([
                ...selectedCameras,
                {
                  id: cam.id,
                  zoneIds: [],
                },
              ]);
            } else {
              setSelectedCameras(
                selectedCameras.filter((s) => s.id !== cam.id)
              );
            }
          }}
          disabled={Boolean(cam.disabled)}
          className="absolute opacity-0 w-0 h-0"
        />
        {children}
      </label>
    </DisabledTooltip>
  );
}

gql`
  query alertsCameraList($alertConfig: AlertConfiguration!) {
    cameras {
      id
      name
      status
      location {
        id
        name
      }

      alertEligibility(alertConfig: $alertConfig)

      tags {
        id
        name
      }
    }
  }
`;
