import CheckIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { Button, Divider, Tooltip, Typography } from "@mui/material";
import clsx from "clsx";
import { isIOS } from "react-device-detect";
import { FullScreenHandle } from "react-full-screen";

import { ReactComponent as RotatingWallIcon } from "@/icons/RotateWall.svg";

import { useBreakpoints } from "@/util/useBreakpoints";

import { useCanEditVideoWalls } from "@/pages/VideoWall/hooks";
import { useIsDemoUser } from "@/pages/VideoWall/useIsDemoUser";
import { useVideoWallContext } from "@/pages/VideoWall/useVideoWallContext";

import { SpotSwitch } from "@/components/Styled/SpotSwitch";
import { Logo } from "@/components/shared/Logo";

import { Page_VideoWallsQuery } from "@/generated-models";
import { usePrefixOrgSlug } from "@/hooks/useOrgRouteBase";
import { BackButton } from "@/layout/BackButton";

import { VideoWallHeader } from "./VideoWallHeader";
import { WallSelector } from "./WallSelector";
import { useVideoWallParams } from "./useVideoWallParams";

export function VideoWallViewHeader({
  fullscreenHandle,
  wallName,
  castButton,
  data,
  edit,
}: {
  fullscreenHandle: FullScreenHandle;
  wallName: string;
  castButton: React.ReactNode;
  data: Page_VideoWallsQuery;
  edit: () => void;
}) {
  const prefixOrgSlug = usePrefixOrgSlug();
  const { fitsDesktop } = useBreakpoints();
  const isDemo = useIsDemoUser();
  const canEdit = useCanEditVideoWalls();
  const { rotatingWallId } = useVideoWallParams();

  const { fullscreenActive } = useVideoWallContext();

  return fitsDesktop ? (
    <VideoWallHeader>
      {fullscreenActive ? (
        <>
          <Logo className="h-6" />
          <Typography variant="h4" component="h1" className="font-bold">
            {wallName}
          </Typography>
        </>
      ) : (
        <div className="flex items-center gap-2">
          <div className="grow max-w-[400px]">
            <WallSelector {...data} />
          </div>
          <Divider orientation="vertical" className="h-5" />
          <Button
            variant="outlined"
            color="primary"
            startIcon={<EditIcon />}
            onClick={edit}
            disabled={isDemo || !canEdit}
          >
            Edit
          </Button>
          {castButton}
        </div>
      )}
      <div className="flex justify-end items-center gap-2">
        <OptimizeLayoutSwitch />
        <Divider orientation="vertical" className="h-5 mr-1" />
        <FullScreenButton handle={fullscreenHandle} variant="outlined" />
      </div>
    </VideoWallHeader>
  ) : (
    <>
      {fullscreenActive ? (
        <div className="p-3 flex justify-between items-center shadow-topNav border-b border-b-text bg-black">
          <Typography
            variant="h1"
            className="text-lg font-bold leading-6 flex items-center"
          >
            {rotatingWallId && <RotatingWallIcon className="mr-2" />} {wallName}
          </Typography>
          <div className="">
            <FullScreenButton handle={fullscreenHandle} variant="outlined" />
          </div>
        </div>
      ) : (
        <>
          <div className="relative p-3 gap-3 shadow-topNav">
            <BackButton
              to={prefixOrgSlug("/wall/")}
              className="absolute top-1/2 left-3 -translate-y-1/2 md:hidden"
            />
            <Typography
              variant="h1"
              className="text-lg font-medium leading-6 text-center w-full"
            >
              Video Walls
            </Typography>
            <div className="absolute  top-1/2 -translate-y-1/2 right-3">
              <FullScreenButton handle={fullscreenHandle} variant="text" />
            </div>
          </div>
          <div className="grow sm:max-w-[400px] p-3">
            <WallSelector {...data} />
          </div>
        </>
      )}
    </>
  );
}

function OptimizeLayoutSwitch() {
  const {
    autoLayoutState: [autoLayout, setAutoLayout],
    originalConfigurationState: [matchesOriginalConfiguration],
  } = useVideoWallContext();
  const { fitsTablet } = useBreakpoints();
  return (
    <div className="flex items-center">
      <Tooltip title="Automatically optimizes wall layout for screen size and aspect ratio">
        <span>
          <SpotSwitch
            checked={autoLayout}
            onChange={() => setAutoLayout((current) => !current)}
            label={fitsTablet ? "Optimize Layout" : "Optimize"}
          />
        </span>
      </Tooltip>
      <Tooltip
        title={
          matchesOriginalConfiguration
            ? "Your wall layout is already optimized."
            : "Wall layout optimized for screen’s aspect ratio"
        }
      >
        <CheckIcon
          className={clsx(
            "-ml-1 text-base transition-opacity",
            matchesOriginalConfiguration ? "text-[#d8d8d8]" : "text-secondary",
            { "opacity-0 pointer-events-none": !autoLayout }
          )}
        />
      </Tooltip>
    </div>
  );
}

function FullScreenButton({
  handle,
  variant,
}: {
  handle: FullScreenHandle;
  variant: "text" | "outlined" | "contained" | undefined;
}) {
  const {
    iosActiveState: [iosActive, setIosActive],
  } = useVideoWallContext();

  const { title, onClick, icon } =
    handle.active || iosActive
      ? {
          title: "Exit Full Screen",
          onClick: handleExitFullscreen,
          icon: <FullscreenExitIcon />,
        }
      : {
          title: "Full Screen",
          onClick: handleEnterFullscreen,
          icon: <FullscreenIcon />,
        };

  function handleEnterFullscreen() {
    if (isIOS) {
      setIosActive(true);
    } else {
      handle.enter();
    }
  }

  function handleExitFullscreen() {
    if (isIOS) {
      setIosActive(false);
    } else {
      handle.exit();
    }
  }
  return (
    <Button
      onClick={onClick}
      color="primary"
      variant={variant}
      startIcon={icon}
      style={{
        textTransform: "none",
      }}
    >
      {title}
    </Button>
  );
}
