import { Download } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";

import { downloadExternalFile } from "@/util/file";
import { useDocumentTitle } from "@/util/useDocumentTitle";

import { useSearchRangeParams } from "@/pages/Search/searchHooks";

import { useMe } from "@/components/Auth";
import { ZendeskArticle, ZendeskLink } from "@/components/Zendesk/ZendeskLink";

import { apiHost } from "@/environment";

import { AuditLogsDatagrid } from "./AuditLogsDatagrid";
import { AuditLogsSummaryBar } from "./AuditLogsSummaryBar";

export function AuditLogsPage() {
  useDocumentTitle("Audit Log");
  const { rangeStart, rangeEnd } = useSearchRangeParams();
  const me = useMe();

  return (
    <div>
      <div className="flex justify-between px-7 py-5 shadow-[rgba(0,0,0,0.25)_0px_3px_5px]">
        <Typography variant="h1">
          Audit Log
          <ZendeskLink article={ZendeskArticle.AUDIT_LOGS} />
        </Typography>
        <Button
          color="primary"
          startIcon={<Download />}
          className="bg-blue-medium px-3.5 py-1.5 font-normal"
          onClick={() =>
            downloadExternalFile(
              `${apiHost}/downloads/${
                me?.organization.id
              }/audit-log-v2.csv?start=${rangeStart.toISOString()}&end=${rangeEnd.toISOString()}`
            )
          }
        >
          Export
        </Button>
      </div>
      <div className="flex flex-col">
        <AuditLogsSummaryBar />
        <AuditLogsDatagrid />
      </div>
    </div>
  );
}
