import { AxisOptions, AxisOptionsBase } from "react-charts";
import { makeStyles } from "tss-react/mui";

type DataPoint = { primary: Date; secondary: number };

export function primaryAxis(
  tickCount?: number,
  options?: AxisOptionsBase
): AxisOptions<DataPoint> {
  return {
    getValue: (datum) => datum.primary,
    scaleType: "localTime",
    innerBandPadding: 0.2,
    showGrid: false,
    ...(!!tickCount && { tickCount }),
    ...options,
  };
}

export const secondaryAxes: AxisOptions<DataPoint>[] = [
  {
    getValue: (datum) => datum.secondary,
    stacked: true,
    elementType: "bar",
    showGrid: true,
    formatters: {
      scale: (value: number) => (Number.isInteger(value) ? String(value) : ""),
    },
  },
];

export const secondaryAxesNonStacked: AxisOptions<DataPoint>[] = [
  {
    getValue: (datum) => {
      return datum.secondary;
    },
    elementType: "bar",
    showGrid: true,
    min: 0,
    formatters: {
      scale: (value: number) => (Number.isInteger(value) ? String(value) : ""),
    },
  },
];

export const secondaryAxesPercentage: AxisOptions<DataPoint>[] = [
  {
    getValue: (datum) => datum.secondary,
    stacked: true,
    elementType: "bar",
    showGrid: true,
    formatters: {
      scale: (value: number) =>
        Number.isInteger(value) ? String(value) + "%" : "",
    },
  },
];

export const secondaryAxesPercentageNonStacked: AxisOptions<DataPoint>[] = [
  {
    getValue: (datum) => datum.secondary,
    elementType: "bar",
    showGrid: true,
    formatters: {
      scale: (value: number) =>
        Number.isInteger(value) ? String(value) + "%" : "",
    },
  },
];

export const useReactChartStyles = makeStyles()(() => ({
  hideChartLines: {
    "& .ReactChart .domainAndTicks line": {
      strokeOpacity: 0,
    },
  },
}));
