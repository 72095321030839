import { Tabs, Tab } from "@mui/material";
import { withStyles } from "tss-react/mui";

export const SecondaryNavTabs = withStyles(Tabs, (theme) => ({
  root: {
    color: "#353d48",
  },
  indicator: {
    backgroundColor: "#353d48",
    width: 3,
  },
}));

export const SecondaryNavTab = withStyles(Tab, (theme) => ({
  root: {
    fontSize: 14,
    paddingRight: 32,
    paddingLeft: 0,
    minWidth: 100,
  },
  selected: {
    fontWeight: "bold",
  },
  wrapped: {
    alignItems: "flex-end",
    whiteSpace: "nowrap" as const,
  },
}));
