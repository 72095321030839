import CheckIcon from "@mui/icons-material/Check";
import { Button, ButtonProps } from "@mui/material";
import clsx from "clsx";
import { motion } from "framer-motion";
import { ReactNode } from "react";

import { ReactComponent as Pinning } from "@/icons/pinning.svg";

const CopilotFormButtonVariant = {
  default: "default",
  destructive: "destructive",
  primary: "primary",
};

export function CopilotFormButton({
  className,
  children,
  onClick,
  variant = "default",
  ...props
}: Omit<ButtonProps, "variant"> & {
  variant?: typeof CopilotFormButtonVariant[keyof typeof CopilotFormButtonVariant];
}) {
  return (
    <Button
      className={clsx(
        className,
        "py-2 px-3 h-[26px] rounded font-barlow text-[13px] leading-[26px] font-normal",
        {
          "text-white/40 bg-[#252525]":
            variant === CopilotFormButtonVariant.default || props.disabled,
          "text-white bg-[#C22F00]":
            variant === CopilotFormButtonVariant.destructive,
          "text-white": variant === CopilotFormButtonVariant.primary,
          "opacity-80": props.disabled,
        }
      )}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick?.(e);
      }}
      style={{
        background:
          variant === CopilotFormButtonVariant.primary && !props.disabled
            ? "linear-gradient(135deg, #A900E4 -3.16%, #007CE4 117.76%)"
            : undefined,
      }}
      {...props}
    >
      {children}
    </Button>
  );
}

export function CopilotMenuButton({
  className,
  disabled,
  onClick,
  onMouseOver,
  onMouseLeave,
  children,
}: {
  className?: string;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
  children: ReactNode;
}) {
  return (
    <motion.div
      className={clsx(
        "group border border-solid py-[5px] pl-[10px] pr-3 flex items-center justify-center gap-2 rounded-[6px] text-[13px] font-barlow leading-normal text-[#B8B8B8]",
        {
          "pointer-events-none opacity-80": disabled,
        },
        className
      )}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      animate="animate"
      whileHover="hover"
      variants={{
        animate: {
          borderColor: "#2E2E2E",
          background: "#1B1B1B",
        },
        hover: {
          borderColor: "#FFFFFF",
          background: "#000000",
        },
      }}
      transition={{
        ease: "easeInOut",
        duration: 0.2,
      }}
    >
      {children}
    </motion.div>
  );
}

export function CopilotPinButton({
  pinned,
  className,
  disabled,
  onClick,
}: {
  pinned?: boolean;
  className?: string;
  disabled?: boolean;
  onClick: () => void;
}) {
  return (
    <CopilotMenuButton
      disabled={disabled}
      className={clsx("h-6 cursor-pointer", className)}
      onClick={onClick}
    >
      <Pinning
        className={clsx(
          "group-hover:scale-125 duration-200 transition ease-in-out",
          {
            "scale-125": pinned,
          }
        )}
      />
      {pinned ? "Object Pinned" : "Pin this Object"}
      {pinned && <CheckIcon className="text-[#3BB16A]" />}
    </CopilotMenuButton>
  );
}
