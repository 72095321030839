import clsx from "clsx";
import { Mention, MentionsInput } from "react-mentions";
import { makeStyles } from "tss-react/mui";

import { useUsersForCaseQuery } from "@/generated-models";
import { ReactState } from "@/types/reactState";

const useStyles = makeStyles()((theme) => ({
  mentions: {
    fontSize: 14,
    "&__input": {
      padding: 8,
      borderRadius: 4,
      border: "1px solid #ccc",
      color: "inherit",
      margin: "0 !important", // Handle iOS highlighter misalignment bug

      "&:focus": {
        outlineStyle: "solid",
        outlineWidth: 2,
        outlineColor: theme.palette.primary.main,
      },

      "&:disabled": {
        backgroundColor: "#eee !important",
      },
    },
    "&__suggestions": {
      backgroundColor: "transparent !important",
    },
    "&__highlighter": {
      padding: 8,
      overflow: "hidden",
    },
    "&__suggestions__list": {
      boxShadow:
        "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);",
      borderRadius: 4,
    },
  },
  mention: {
    position: "relative",
    zIndex: 1,
    color: theme.palette.primary.main,
    textDecoration: "underline",
    pointerEvents: "none",
  },
  suggestion: {
    padding: theme.spacing(1),
    "&.focused": {
      background: `${theme.palette.primary.main} !important`,
      color: "white",
    },
  },
}));

function useMentionSuggestions() {
  const { data } = useUsersForCaseQuery();
  if (!data) return undefined;

  return data.users.reduce((result, user) => {
    result[user.id] = user.name;
    return result;
  }, {} as Record<number, string>);
}

export type MentionSuggestions = Record<number, string>;

export interface SpotMentionInputProps {
  onSubmitComment: (comment: string, mentions: MentionSuggestions) => void;
  commentState: ReactState<string>;
  mentionsState: ReactState<MentionSuggestions>;
  onCancel?: () => void;
  label?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  classes?: Partial<ReturnType<typeof useStyles>["classes"]>;
  style?: any;
}
export function SpotMentionInput({
  onSubmitComment,
  label = "Enter a comment here",
  onCancel,
  disabled = false,
  autoFocus,
  commentState: [comment, setComment],
  mentionsState: [mentions, setMentions],
  classes,
  style,
}: SpotMentionInputProps) {
  const mentionSuggestions = useMentionSuggestions() ?? {};
  const { classes: baseClasses } = useStyles();
  return (
    <MentionsInput
      placeholder={label}
      value={comment}
      onChange={(e) => {
        setComment(e.target.value);
      }}
      autoFocus={autoFocus}
      disabled={disabled}
      style={style}
      className={clsx(baseClasses.mentions, classes?.mentions, "grow")}
      onKeyDown={(e) => {
        if (e.key === "Enter" && !e.shiftKey) {
          e.preventDefault();
          e.stopPropagation();
          onSubmitComment(comment, mentions);
          setComment("");
          setMentions({});
        }
        if (onCancel && e.key === "Escape") {
          e.preventDefault();
          e.stopPropagation();
          onCancel();
        }
      }}
    >
      <Mention
        onAdd={(id, display) => {
          setMentions((oldMentions) => ({
            ...oldMentions,
            [id as number]: display,
          }));
        }}
        trigger="@"
        displayTransform={(id) => `@${mentionSuggestions[Number(id)]}`}
        data={Object.entries(mentionSuggestions).map(([k, v]) => ({
          id: k,
          display: v,
        }))}
        className={clsx(baseClasses.mention, classes?.mention)}
        renderSuggestion={(
          suggestion,
          search,
          highlightedDisplay,
          index,
          focused
        ) => (
          <div
            className={clsx(baseClasses.suggestion, classes?.suggestion, {
              focused,
            })}
          >
            {highlightedDisplay}
          </div>
        )}
      />
    </MentionsInput>
  );
}
