import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { AnalyticsViewType, trackView } from "@/util/analytics";

import { ExpandingSnippetPreview } from "@/components/Camera/ExpandingSnippetPreview";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { useWallclockTime } from "@/components/Player/PlayerBase";
import { executeZendeskCmd } from "@/components/Scripts/Zendesk";

import { AnonymousSharedClip, useGetSharedClipQuery } from "@/generated-models";
import { GuestErrorLayout } from "@/layout/GuestLayout";

export function GuestSnippetView() {
  const [selected, setSelected] = useState(false);
  const { token } = useParams();

  const { loading, data, error } = useGetSharedClipQuery({
    variables: { token: token ?? "" },
  });

  const sharedClip = data?.sharedClip as AnonymousSharedClip;
  const wallclockTime = useWallclockTime();
  let boundedWallclockTime = wallclockTime;

  useEffect(() => {
    if (loading || !data?.sharedClip || error) return;
    trackView(AnalyticsViewType.vod, true);
  }, [loading, data, error]);

  useEffect(() => {
    setTimeout(() => {
      executeZendeskCmd("webWidget", "hide");
    }, 100);
    return () => {
      executeZendeskCmd("webWidget", "show");
    };
  }, []);

  if (error) {
    return (
      <GuestErrorLayout>
        <ErrorMessage
          title="Oops"
          description="We could not find the shared clip. Possibly this sharing link has been revoked."
        />
      </GuestErrorLayout>
    );
  }
  if (loading || !sharedClip) return <Loading>Loading clip...</Loading>;
  const startTime = new Date(sharedClip?.startTime).getTime();
  const endTime = new Date(sharedClip?.endTime).getTime();
  if (sharedClip?.startTime && sharedClip?.endTime && wallclockTime) {
    boundedWallclockTime = new Date(
      Math.max(startTime, Math.min(endTime, wallclockTime.getTime()))
    );
  }

  return (
    <div
      className="bg-black h-screen"
      onMouseEnter={() => setSelected(true)}
      onMouseLeave={() => setSelected(false)}
    >
      <ExpandingSnippetPreview
        start={sharedClip.startTime}
        end={sharedClip.endTime}
        selected={selected}
        progress={
          boundedWallclockTime
            ? (boundedWallclockTime!.getTime() - startTime) /
              (endTime - startTime)
            : undefined
        }
        sharedClipToken={token}
        timezone={sharedClip.timezone}
        onClick={() => {}}
      />
    </div>
  );
}
