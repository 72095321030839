import { ChevronRight, ThumbUp, ThumbDown } from "@mui/icons-material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { ClickAwayListener, Tooltip, Typography } from "@mui/material";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { useFlags } from "launchdarkly-react-client-sdk";
import { delay } from "lodash";
import { useCallback, useState } from "react";
import Draggable from "react-draggable";

import { ReactComponent as Pinning } from "@/icons/pinning.svg";

import { AnnotationLabelState } from "@/generated-models";

import {
  useOrgPromptList,
  useUpdatePromptLabel,
} from "../../CopilotPlayerSection/CopilotLibraryPopover/copilotLibraryHooks";
import { DecodedText } from "../../DecodedText";
import { CONFIRMED_LABEL_STATES } from "../../copilotAnnotationQueryHooks";
import { CopilotFeedbackModal } from "./CopilotFeedbackModal";
import { AdjustBoundaryForm } from "./Forms/AdjustBoundaryForm";
import { DeleteLabelForm } from "./Forms/DeleteLabelForm";
import { RenameLabelForm } from "./Forms/RenameLabelForm";
import {
  BaseLabelMenuItems,
  LabelMenuItem,
} from "./MenuItems/BaseLabelMenuItems";
import { CoachMenuItems } from "./MenuItems/CoachMenuItems/CoachMenuItems";
import { useCopilotInsightQueryParam } from "./copilotLabelButtonHooks";
import {
  CopilotLabelMode,
  useCopilotLabelContext,
} from "./useCopilotLabelContext";
import { calculatePosition } from "./utils";

const MIN_POSITION = 7;
const MAX_POSITION = 93;

interface CopilotSubjectButtonProps {
  middleX: number;
  middleY: number;
  animationDelay?: number;
  onRevealZone: () => void;
  onHideZone: () => void;
}

function StateIcon({ state }: { state: AnnotationLabelState }) {
  const { copilotPinning } = useFlags();

  if (copilotPinning && state === AnnotationLabelState.Pinned) {
    return <Pinning className="ml-[7px] mr-[6px]" />;
  }

  return <div className="w-1.5 h-1.5 mr-[1px]" />;
}

function FeedbackMenuItem({ label }: { label: string }) {
  const { copilotPinning } = useFlags();
  const [insightMenu] = useCopilotInsightQueryParam();
  const { data } = useOrgPromptList();
  const { loading, toggle } = useUpdatePromptLabel();

  return (
    <>
      {!insightMenu && !copilotPinning && (
        <motion.div
          className="py-[13px] text-left text-sm leading-normal text-white/[0.5] hover:font-bold font-barlow"
          whileHover={{
            color: "rgba(255, 255, 255, 0.75)",
            transition: { duration: 0.2 },
          }}
        >
          <div className="flex items-center justify-start gap-[10px]">
            <div>Good Result?</div>
            <div className="flex items-center gap-[10px]">
              <CopilotFeedbackModal
                buttonClassName="bg-transparent hover:trans"
                prefix={`Good Result Reported for ${label}`}
                placeholder="Add additional comments if necessary"
              >
                <ThumbUp className="w-5 h-5 hover:text-white transition duration-150 ease-in-out hover:-translate-y-1" />
              </CopilotFeedbackModal>

              <CopilotFeedbackModal
                buttonClassName="bg-transparent hover:trans"
                prefix={`Bad Result Reported for ${label}`}
                placeholder="Add additional comments if necessary"
              >
                <ThumbDown className="w-5 h-5 -scale-x-100 hover:text-white transition duration-150 ease-in-out hover:translate-y-1" />
              </CopilotFeedbackModal>
            </div>
          </div>
        </motion.div>
      )}
      {!copilotPinning && (
        <LabelMenuItem
          className="!pl-0"
          disabled={loading}
          onClick={(e) => {
            if (data) {
              toggle(data?.id, label, data?.ignoreList);
            }
          }}
        >
          Hide all labels of "{label}"
        </LabelMenuItem>
      )}

      <motion.div
        className="py-3 text-white/[0.5]"
        whileHover={{
          color: "rgba(255, 255, 255, 0.75)",
          transition: { duration: 0.2 },
        }}
      >
        <div className="flex items-center justify-start gap-[10px]">
          <CopilotFeedbackModal
            buttonClassName="text-sm leading-normal bg-transparent hover:font-bold font-barlow text-left"
            placeholder="I wish AI Copilot would..."
          >
            I wish AI Copilot would...
          </CopilotFeedbackModal>
        </div>
      </motion.div>
    </>
  );
}

export function CopilotLabelButton({
  middleX,
  middleY,
  animationDelay,
  onRevealZone,
  onHideZone,
}: CopilotSubjectButtonProps) {
  const { copilotPinning } = useFlags();
  const [, setInsightMenu] = useCopilotInsightQueryParam();
  const { color, label, labelState, mode, setMode } = useCopilotLabelContext();
  const [dragging, setDragging] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [baseHovered, setHovered] = useState(false);
  const persistDragging = useCallback((value: boolean, duration = 200) => {
    return delay(() => {
      setDragging(value);
      onHover();
    }, duration);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const confirmed = CONFIRMED_LABEL_STATES.includes(labelState);
  const hovered = baseHovered || dragging;

  const baseAnimate = { animate: hovered ? "active" : "inactive" };
  const baseTransition = { transition: { type: "spring", stiffness: 100 } };

  const actionVariants = {
    expanded: {
      display: "flex",
      width: 125,
      opacity: 1,
      transition: {
        duration: 0.25,
      },
    },
    active: {
      display: "flex",
      width: 50,
      opacity: 1,
      transition: {
        duration: 0.25,
      },
    },
    inactive: {
      display: "flex",
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.25,
      },
    },
  };

  const btnVariants = {
    expanded: {
      background: "rgba(0, 0, 0, 0.50)",
      borderRadius: 18,
      color: "#FFFFFF",
      transition: {
        duration: 0.2,
      },
    },
    active: {
      background: "rgba(0, 0, 0, 0.50)",
      borderRadius: 44,
      color: "#FFFFFF",
      transition: {
        duration: 0.2,
      },
    },
    inactive: {
      background: "rgba(0, 0, 0, 0)",
      borderRadius: 44,
      color: confirmed ? color : "#7f7d7d",
      transition: {
        duration: 0.2,
      },
    },
  };

  function getPillVariant() {
    return {
      initial: {
        opacity: 0,
      },
      active: {
        opacity: 1,
        top: 0,
        border: confirmed
          ? "1px solid rgba(87, 87, 87, 0.64)"
          : "1px dashed rgba(255, 255, 255, 1)",
      },
      inactive: {
        opacity: 1,
        top: 0,
        border: confirmed
          ? "1px solid rgba(87, 87, 87, 0)"
          : "1px dashed rgba(255, 255, 255, 0.61)",
        paddingLeft: 0,
      },
    };
  }

  function onHover() {
    setHovered(true);
    onRevealZone?.();
  }

  function onClose() {
    setMode(CopilotLabelMode.default);
    setHovered(false);
    setExpanded(false);
    setInsightMenu(undefined);
  }

  function subMenuProps(className?: string) {
    return {
      className: clsx(
        "bg-[#1B1B1B] rounded-lg flex flex-col divide-y divide-[#2C2C2C] w-full mt-2 px-4",
        !expanded && "hidden",
        className
      ),
      animate: hovered && expanded ? "expanded" : "initial",
      variants: {
        initial: {
          opacity: 0,
          display: "none",
          transition: {
            duration: 0.25,
          },
        },
        expanded: {
          opacity: 1,
          display: "inline-block",
          transition: {
            duration: 0.1,
          },
        },
      },
    };
  }

  const paddedMiddleX = Math.min(Math.max(middleX, MIN_POSITION), MAX_POSITION);
  const paddedMiddleY = Math.min(Math.max(middleY, MIN_POSITION), MAX_POSITION);

  if (hidden) return <></>;

  return (
    <div className="absolute w-full h-full">
      <ClickAwayListener onClickAway={onClose}>
        <Draggable
          disabled={expanded}
          onDrag={() => {
            persistDragging(true);
          }}
          onStop={(e) => {
            persistDragging(false);
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <motion.button
            type="button"
            onClick={(e) => {
              if (dragging) return true;
              setExpanded((v) => !v);
              e.stopPropagation();
            }}
            onMouseOver={onHover}
            onMouseLeave={() => {
              onClose();
              onHideZone?.();
            }}
            className={clsx(
              "relative flex flex-col items-center p-[7px] -translate-x-2.5 -translate-[200px] pointer-events-auto",
              {
                "z-1": !expanded,
                "z-20": expanded,
                "pointer-events-none": dragging,
              }
            )}
            {...baseTransition}
            animate={expanded ? "expanded" : baseAnimate.animate}
            variants={btnVariants}
            style={{
              ...calculatePosition(label, paddedMiddleX, paddedMiddleY),
            }}
          >
            <AnimatePresence>
              <motion.div
                key="subject-button-pill"
                className={clsx(
                  "w-full flex items-center h-[28px] pr-[7px] text-[14px] font-normal leading-normal tracking-[1.615px] uppercase bg-black bg-opacity-70 font-barlow rounded-3xl",
                  {
                    hidden: mode !== CopilotLabelMode.default,
                  }
                )}
                {...baseAnimate}
                {...baseTransition}
                initial="initial"
                variants={getPillVariant()}
              >
                <StateIcon state={labelState} />
                <span className="h-[20px] leading-[20px]">
                  <DecodedText
                    label={label}
                    duration={60}
                    delay={animationDelay}
                  />
                </span>

                <motion.div
                  className="flex justify-end items-center gap-3 flex-1"
                  {...baseTransition}
                  animate={expanded ? "expanded" : baseAnimate.animate}
                  variants={actionVariants}
                >
                  <motion.div
                    className="flex items-center"
                    animate={expanded ? "expanded" : "default"}
                    transition={{ type: "spring", bounce: 0.75 }}
                    variants={{
                      default: {},
                      expanded: { rotate: 90 },
                    }}
                  >
                    <ChevronRight className="text-white" />
                  </motion.div>

                  <Tooltip title="Temporarily hide this label">
                    <motion.button
                      type="button"
                      onClick={(e) => {
                        setHidden(true);
                        onHideZone();
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                      className="text-white/40 hover:text-white/100 bg-transparent w-4 h-4 -mr-1"
                      {...baseTransition}
                      animate={expanded || hovered ? "expanded" : "hidden"}
                      variants={{
                        hidden: {
                          display: "none",
                        },
                        expanded: {
                          display: "flex",
                        },
                      }}
                    >
                      <HighlightOffIcon className="w-4 h-4" />
                    </motion.button>
                  </Tooltip>
                </motion.div>
              </motion.div>
              {mode === CopilotLabelMode.remove && (
                <motion.div
                  key="workflow-adjust-boundary"
                  {...subMenuProps("bg-black py-4")}
                >
                  <DeleteLabelForm />
                </motion.div>
              )}
              {mode === CopilotLabelMode.rename && (
                <motion.div
                  key="workflow-adjust-boundary"
                  {...subMenuProps("bg-black py-4")}
                >
                  <RenameLabelForm />
                </motion.div>
              )}
              {mode === CopilotLabelMode.adjust && (
                <motion.div
                  key="workflow-adjust-boundary"
                  {...subMenuProps("py-4")}
                >
                  <AdjustBoundaryForm />
                </motion.div>
              )}
              {mode === CopilotLabelMode.default && (
                <>
                  {copilotPinning && (
                    <motion.div
                      key="coach-menu"
                      {...subMenuProps("bg-transparent")}
                    >
                      <CoachMenuItems />
                    </motion.div>
                  )}
                  <motion.div
                    key="workflow-header-menu"
                    {...subMenuProps("bg-transparent mb-1")}
                  >
                    <Typography className="text-white opacity-50 font-barlow text-[11px]">
                      Workflows
                    </Typography>
                  </motion.div>
                  <motion.div key="subject-menu" {...subMenuProps()}>
                    <BaseLabelMenuItems />
                  </motion.div>
                  <motion.div key="subject-menu-feedback" {...subMenuProps()}>
                    <FeedbackMenuItem label={label} />
                  </motion.div>
                </>
              )}
            </AnimatePresence>
          </motion.button>
        </Draggable>
      </ClickAwayListener>
    </div>
  );
}
