import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import { PropsWithChildren } from "react";
import { ErrorBoundary } from "react-error-boundary";
import Helmet from "react-helmet";
import { GlobalStyles } from "tss-react";

import { darkFocusTheme } from "@/layout/theme";

import { KioskError } from "./KioskError";
import { useKeepAlive } from "./useKeepAlive";

export default function KioskLayout({ children }: PropsWithChildren<{}>) {
  useKeepAlive();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={darkFocusTheme}>
        <GlobalStyles
          styles={{
            "html, body, #root": {
              height: "100%",
              background: "#000",
            },
          }}
        />
        <Helmet>
          <meta http-equiv="refresh" content="43200" />
        </Helmet>
        <div className="h-full w-full">
          <ErrorBoundary
            fallback={
              <KioskError description="Something went wrong, please refresh the page." />
            }
          >
            {children}
          </ErrorBoundary>
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
