import { Check, Error as ErrorIcon } from "@mui/icons-material";
import clsx from "clsx";
import { useEffect } from "react";

export type SavedFeedbackStatus =
  | {
      success: true;
      error?: never;
    }
  | {
      success: false;
      error: Error;
    };
export function SavedFeedback({
  status,
  onClose,
  hideTimeout = 5000,
  confirmationText,
}: {
  status: SavedFeedbackStatus | null;
  onClose: () => void;
  hideTimeout?: number;
  confirmationText?: string;
}) {
  useEffect(() => {
    if (status) {
      const timer = setTimeout(() => {
        onClose();
      }, hideTimeout);
      return () => clearTimeout(timer);
    }
  }, [status, onClose, hideTimeout]);
  if (!status) return null;
  return (
    <div
      className={clsx(
        "sticky -bottom-2 -m-2 flex items-center gap-2 text-white p-4",
        {
          "bg-primary": status.success,
          "bg-error": !status.success,
        }
      )}
    >
      {status.success ? (
        <>
          <Check fontSize="small" className="relative" />
          <span className="relative">
            {confirmationText ?? "Changes saved."}
          </span>
        </>
      ) : (
        <>
          <ErrorIcon fontSize="small" className="relative" />
          <span className="relative">
            {status.error?.message ?? "Failed to save changes."}
          </span>
        </>
      )}
    </div>
  );
}
