import { DateRange, Timer } from "@mui/icons-material";
import { CircularProgress, Typography } from "@mui/material";
import { capitalize } from "lodash";
import { Link } from "react-router-dom";

import { formatTimeString, getShorthandDayRange } from "@/util/date";
import { formatDurationSecs } from "@/util/formatDurationSecs";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  IntelligenceDashboardType,
  useIntelligenceDashboardQuery,
} from "@/generated-models";

import { IntelligenceFeatureConfig } from "../constants";
import {
  getDashboardFeatureType,
  getObjectLabel,
  getObjectTypeIcon,
} from "../utils";
import { ComparativeDashboardSummaryStat } from "./ComparativeDashboardHeader";
import { ComparativeLocationIndicator } from "./Core/ComparativeLocationIndicator";
import { getComparativeCameras, getComparativeType } from "./utils";

export function ComparativeCardPreview({ id }: { id: number }) {
  const { data, loading, error } = useIntelligenceDashboardQuery({
    ...refetchOnMountPolicy,
    variables: { id: Number(id) },
  });

  const dashboard = data?.intelligenceDashboard;
  const cameras = getComparativeCameras(dashboard);

  const type =
    getComparativeType(dashboard) || IntelligenceDashboardType.Presence;
  const featureType = getDashboardFeatureType(type, dashboard?.objectTypes);

  const config = IntelligenceFeatureConfig[featureType];
  const TypeIcon = getObjectTypeIcon(dashboard?.objectTypes, type);

  return (
    <Link to={String(id)}>
      <div className="bg-white rounded-lg shadow-[0_6px_14px_rgba(0,42,82,0.1)]">
        <div className="px-3 py-4 flex flex-col items-center gap-2 w-full min-h-[318px]">
          <Typography className="font-bold text-lg leading-[21px]">
            <strong>Comparison</strong> Dashboard
          </Typography>
          {loading && (
            <div className="w-full h-[234px] flex items-center justify-center">
              <CircularProgress size={40} />
            </div>
          )}
          {error && (
            <div className="w-full h-[234px] flex items-center justify-center">
              <div>No available data</div>
            </div>
          )}
          {!loading && dashboard && !error && (
            <>
              <div className="flex flex-col gap-0.5 pt-4 items-center">
                <Typography className="font-bold text-xl leading-[23px]">
                  {config?.label?.displayName} Across Locations
                </Typography>
                <Typography className="text-sm leading-4 flex gap-2 items-center">
                  <span className="opacity-50">
                    {data?.intelligenceDashboard?.referenceDashboards?.length ||
                      0}{" "}
                    Dashboard Comparison
                  </span>
                </Typography>
              </div>
              <div className="grid gap-1 grid-cols-1 md:grid-cols-2">
                {cameras?.map((c, idx) => (
                  <ComparativeLocationIndicator
                    className="min-h-[40px]"
                    textClassName="max-w-[223px] truncate"
                    key={`${c.id}-${idx}`}
                    location={c.location.name}
                    position={idx}
                  />
                ))}
              </div>
              <div className="flex gap-[10px] items-center my-[18px] mt-auto">
                {type === IntelligenceDashboardType.Idle && (
                  <ComparativeDashboardSummaryStat
                    Icon={Timer}
                    iconClass="text-[#BDBDBD]"
                    value={`${
                      dashboard?.objectTypes
                        ? capitalize(
                            getObjectLabel(dashboard.objectTypes, type)
                          )
                        : "Vehicle"
                    } Idle >${formatDurationSecs(dashboard.thresholdSeconds, {
                      hideZeroes: true,
                      long: true,
                    })}`}
                  />
                )}
                {type === IntelligenceDashboardType.Presence && (
                  <ComparativeDashboardSummaryStat
                    Icon={TypeIcon}
                    iconClass="text-[#BDBDBD]"
                    value={`Presence ${dashboard.entityCount}+ ${capitalize(
                      getObjectLabel(
                        dashboard.objectTypes,
                        type,
                        dashboard.entityCount > 1
                      )
                    )}`}
                  />
                )}
                <ComparativeDashboardSummaryStat
                  Icon={DateRange}
                  iconClass="text-[#BDBDBD]"
                  value={`${getShorthandDayRange(
                    dashboard.daysOfWeek
                  )}, ${formatTimeString(
                    dashboard.startTime
                  )} - ${formatTimeString(dashboard.endTime)}`}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </Link>
  );
}
