import clsx from "clsx";
import React from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  container: {
    display: "grid",
  },
  item: {},
}));

export type GridContainerProps = React.PropsWithChildren<{
  component?: React.ElementType;
  className?: string;
  style?: React.CSSProperties;
}>;

export function GridContainer({
  component: Component = "div",
  className,
  ...rest
}: GridContainerProps) {
  const { classes } = useStyles();

  return <Component {...rest} className={clsx(classes.container, className)} />;
}
