import { Checkbox, Pagination } from "@mui/material";
import gql from "graphql-tag";
import { sortBy } from "lodash/fp";
import { useState } from "react";
import { usePagination } from "react-use-pagination";

import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { SearchParamLink } from "@/components/shared/QueryParamLink";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  useAllDetectedSubjectsQuery,
  useMergeDetectedSubjectsMutation,
} from "@/generated-models";
import { usePrefixOrgSlug } from "@/hooks/useOrgRouteBase";

import { ReactComponent as MergeSubjectsSvg } from "../../icons/merge-subjects.svg";

interface MergeSubjectsProps {
  imageSrc?: string | null;
  currentSubjectId: number;
  label?: string | null;
  cancel: () => void;
}

export function MergeSubjectsModal({
  imageSrc,
  currentSubjectId,
  label,
  cancel,
}: MergeSubjectsProps) {
  const prefixOrgSlug = usePrefixOrgSlug();
  const { data, error } = useAllDetectedSubjectsQuery({
    variables: { objectType: "0" },
    ...refetchOnMountPolicy,
  });

  // Remove the current subject from the list of subjects and sort
  const allSubjects = data
    ? sortBy(
        (s) => s.label,
        data.allDetectedSubjects.filter(
          (subject) => subject.id !== currentSubjectId && !!subject.bestTrack
        )
      )
    : undefined;

  const { pushSnackbar } = useFeedback();
  const [
    mergeSubjects,
    { loading: merging },
  ] = useMergeDetectedSubjectsMutation();
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const {
    currentPage,
    totalPages,
    setPage,
    startIndex,
    endIndex,
  } = usePagination({
    totalItems: allSubjects?.length ?? 0,
    initialPageSize: 24,
  });

  const subjects = allSubjects?.slice(startIndex, endIndex + 1);

  if (error) {
    return (
      <ErrorMessage
        title="Something went wrong..."
        description={error?.message}
      />
    );
  }

  return (
    <div className="flex gap-x-6">
      <div className="w-52 shrink-0 p-4 bg-blue-light border border-primary border-opacity-10 rounded-lg">
        <MergeSubjectsSvg className="mx-auto" />
        <div className="text-lg font-bold mt-0.5">Combine & Improve</div>
        <div className="text-base leading-tight mb-6">
          Choose the duplicates for the following person and combine them to
          help improve future searches.
        </div>
        <PersonCard imageSrc={imageSrc} label={label} />
        <button
          className="flex-center w-full h-10 bg-primary hover:bg-opacity-50 disabled:bg-[#E0E0E0] text-white disabled:text-[#959595] border-[#D8D8D8] disabled:border text-base rounded-lg mt-7 mb-3"
          onClick={() =>
            mergeSubjects({
              variables: {
                ids: selectedIds,
                targetSubjectId: currentSubjectId,
              },
              update: (cache, { data }) => {
                if (!data) return;
                for (const id of selectedIds) {
                  cache.evict({ id: `DetectedSubject:${id}` });
                }
              },
              onCompleted: () => {
                cancel();
                pushSnackbar(
                  <>
                    {selectedIds.length + 1} people combined!
                    <SearchParamLink
                      to={prefixOrgSlug(`/search/people/${currentSubjectId}`)}
                      className="ml-2 underline"
                    >
                      View Person
                    </SearchParamLink>
                  </>,
                  FeedbackType.Info
                );
              },
              onError: () =>
                pushSnackbar("Combining people failed...", FeedbackType.Error),
            })
          }
          disabled={selectedIds.length === 0 || merging}
        >
          Combine {selectedIds.length > 0 ? selectedIds.length + 1 : ""} People
        </button>
        <CancelButton onClick={cancel} />
      </div>
      {subjects ? (
        <SubjectGridLayout
          pagination={
            <Pagination
              count={totalPages}
              page={currentPage + 1}
              onChange={(_, value) => setPage(value - 1)}
            />
          }
        >
          {subjects.map((subject) => (
            <button
              key={subject.id}
              className="paper relative flex flex-col items-center w-full p-1 text-xs md:text-sm bg-white hover:bg-blue-medium transition-colors"
              disabled={merging}
              onClick={() =>
                setSelectedIds((ids) =>
                  ids.includes(subject.id)
                    ? ids.filter((id) => id !== subject.id)
                    : [...ids, subject.id]
                )
              }
            >
              <img
                src={subject.bestTrack?.imageSrc ?? ""}
                alt={subject.label ?? "Unknown subject"}
                className="w-full aspect-square rounded-t object-cover object-center"
              />
              <div className="w-full mt-1 truncate">
                {subject.label ?? `Person ${subject.id}`}
              </div>
              <div className="absolute top-1 left-1">
                {/* White checkbox background */}
                <div className="absolute inset-1 bg-white" />
                <Checkbox
                  color="primary"
                  checked={selectedIds.includes(subject.id)}
                  sx={{ padding: 0, color: "#CCCCCC" }}
                />
              </div>
            </button>
          ))}
        </SubjectGridLayout>
      ) : (
        <Loading className="my-40 mx-auto" />
      )}
    </div>
  );
}

interface ReassignTrackModalProps {
  imageSrc?: string | null;
  currentSubjectId: number;
  cancel: () => void;
  onSelect: (targetSubjectId: number) => void;
}

export function ReassignTrackModal({
  imageSrc,
  currentSubjectId,
  cancel,
  onSelect,
}: ReassignTrackModalProps) {
  const { data, error } = useAllDetectedSubjectsQuery({
    variables: { objectType: "0" },
    ...refetchOnMountPolicy,
  });

  // Remove the current subject from the list of subjects and sort
  const allSubjects = data
    ? sortBy(
        (s) => s.label,
        data.allDetectedSubjects.filter(
          (subject) => subject.id !== currentSubjectId && !!subject.bestTrack
        )
      )
    : undefined;

  const {
    currentPage,
    totalPages,
    setPage,
    startIndex,
    endIndex,
  } = usePagination({
    totalItems: allSubjects?.length ?? 0,
    initialPageSize: 24,
  });

  const subjects = allSubjects?.slice(startIndex, endIndex + 1);

  if (error) {
    return (
      <ErrorMessage
        title="Something went wrong..."
        description={error?.message}
      />
    );
  }

  return (
    <div className="flex gap-x-6">
      <div className="w-52 shrink-0 p-4 bg-blue-light border border-primary border-opacity-10 rounded-lg">
        <PersonCard imageSrc={imageSrc} />
        <div className="mt-4 text-lg font-bold">Reassign Detection</div>
        <div className="text-base leading-tight mb-7">
          Select the person who you want to reassign this detection to.
        </div>
        <CancelButton onClick={cancel} />
      </div>
      {subjects ? (
        <SubjectGridLayout
          pagination={
            <Pagination
              count={totalPages}
              page={currentPage + 1}
              onChange={(_, value) => setPage(value - 1)}
            />
          }
        >
          {subjects.map((subject) => (
            <button
              key={subject.id}
              className="paper flex flex-col items-center w-full p-1 text-xs md:text-sm bg-white hover:bg-blue-medium transition-colors"
              onClick={() => onSelect(subject.id)}
            >
              <img
                src={subject.bestTrack?.imageSrc ?? ""}
                alt={subject.label ?? "Unknown subject"}
                className="w-full aspect-square rounded-t object-cover object-center"
              />
              <div className="w-full mt-1 truncate">
                {subject.label ?? `Person ${subject.id}`}
              </div>
            </button>
          ))}
        </SubjectGridLayout>
      ) : (
        <Loading className="my-40 mx-auto" />
      )}
    </div>
  );
}

function PersonCard({
  imageSrc,
  label,
}: {
  imageSrc?: string | null;
  label?: string | null;
}) {
  return (
    <div className="paper p-1 bg-white">
      <img
        src={imageSrc ?? ""}
        alt="Subject"
        className="w-full h-44 rounded object-cover object-center"
      />
      {label && <div className="text-sm text-center mt-1">{label}</div>}
    </div>
  );
}

function CancelButton({ onClick }: { onClick: () => void }) {
  return (
    <button
      className="flex-center w-full h-10 bg-[#DAEEFF] hover:bg-opacity-50 text-primary text-base rounded-lg"
      onClick={onClick}
    >
      Cancel
    </button>
  );
}

function SubjectGridLayout({
  children,
  pagination,
}: {
  children: React.ReactNode;
  pagination: React.ReactNode;
}) {
  return (
    <div className="flex flex-col gap-5 items-end grow">
      <div className="grid w-full gap-x-4 gap-y-3 md:gap-x-6 md:gap-y-5 grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6">
        {children}
      </div>
      {pagination}
    </div>
  );
}

gql`
  mutation mergeDetectedSubjects($ids: [Int!]!, $targetSubjectId: Int!) {
    mergeDetectedSubjects(ids: $ids, targetSubjectId: $targetSubjectId) {
      message
    }
  }
`;

gql`
  query allDetectedSubjects($objectType: String!) {
    allDetectedSubjects(objectType: $objectType) {
      id
      label
      bestTrack {
        imageSrc
      }
    }
  }
`;
