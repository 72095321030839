import CopyIcon from "@mui/icons-material/FileCopyOutlined";
import { IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import { TextFieldProps } from "@mui/material/TextField";
import * as clipboard from "clipboard-polyfill/build/clipboard-polyfill.promise";
import React, { useState } from "react";

export function CopyableTextInput({
  className,
  value,
  ...textFieldProps
}: {
  className?: string;
  value: string;
} & Pick<TextFieldProps, "label" | "helperText" | "disabled" | "fullWidth">) {
  const [copied, setCopied] = useState(false);
  return (
    <TextField
      className={className}
      onFocus={(e: any) => e.target.select()}
      value={value}
      variant="outlined"
      margin="dense"
      {...textFieldProps}
      InputProps={
        textFieldProps.disabled
          ? undefined
          : {
              endAdornment: (
                <Tooltip
                  title={copied ? "Copied!" : "Copy to clipboard"}
                  classes={{ popper: "pointer-events-none" }}
                >
                  <InputAdornment position="end">
                    <IconButton
                      onMouseEnter={() => setCopied(false)}
                      size="small"
                      onClick={async () => {
                        await clipboard.writeText(value);
                        setCopied(true);
                      }}
                    >
                      <CopyIcon />
                    </IconButton>
                  </InputAdornment>
                </Tooltip>
              ),
              readOnly: true,
            }
      }
    />
  );
}
