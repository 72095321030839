export function CamDrawerListDescription({
  description,
}: {
  description: string;
}) {
  return (
    <div className="py-3 px-4 bg-[#F4F4F4] text-[#656565] italic leading-4">
      {description}
    </div>
  );
}
