import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import {
  ClickAwayListener,
  Fade,
  IconButton,
  Paper,
  Popper,
  Slider,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useUpdateEffect } from "react-use";
import { makeStyles } from "tss-react/mui";

import { trackAudioSet } from "@/util/analytics";

import { usePlayerControls } from "@/components/Player/PlayerBase";
import { PlayerTooltip } from "@/components/Player/PlayerTooltip";

import { usePermissions } from "@/hooks/usePermissions";

const useStyles = makeStyles()((theme) => ({
  popover: {
    padding: theme.spacing(2),
    margin: 0,
    maxWidth: 100,
    borderRadius: 0,
    backgroundColor: "black",
    height: 150,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  volumeSlider: {
    flexGrow: 1,
  },
}));

interface VolumeButtonProps {
  enabled?: boolean;
  playerId?: string;
}

export function VolumeButton({ enabled = true, playerId }: VolumeButtonProps) {
  const { classes } = useStyles();
  const anchorEl = useRef(null as null | HTMLButtonElement);
  const [popperOpen, setPopperOpen] = useState(false);
  const [volume, setVolume] = useState(0);
  const { setVolume: setPlayerVolume } = usePlayerControls(playerId);
  const hasPermission = usePermissions();

  const handleVolumeChange = useCallback(
    (event: any, newValue: number | number[]) => {
      const newVolume = Array.isArray(newValue) ? newValue[0] : newValue;
      setVolume(newVolume);
    },
    [setVolume]
  );

  useEffect(() => {
    setPlayerVolume(volume);
  }, [volume, setPlayerVolume]);

  useUpdateEffect(() => {
    trackAudioSet(volume);
  }, [volume, setPlayerVolume]);

  if (!enabled || !hasPermission("audio_access")) return null;

  return (
    <>
      <PlayerTooltip title="Volume">
        <IconButton
          ref={anchorEl}
          className={"text-white rounded-b-md rounded-t-none transition-colors"}
          style={{
            backgroundColor: popperOpen ? "black" : "transparent",
          }}
          onMouseEnter={() => setPopperOpen(true)}
          onMouseLeave={() => setPopperOpen(false)}
          onClick={(event) => {
            event.stopPropagation();
            setVolume((volume) => (volume === 0 ? 100 : 0));
            setPopperOpen(true);
          }}
          aria-label="audio"
          size="large"
        >
          {volume === 0 ? <VolumeOffIcon /> : <VolumeUpIcon />}
        </IconButton>
      </PlayerTooltip>
      <Popper
        id="mouse-over-popover"
        open={popperOpen}
        anchorEl={anchorEl.current}
        placement="top"
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={() => setPopperOpen(false)}>
            <Fade {...TransitionProps}>
              <Paper
                onMouseEnter={() => setPopperOpen(true)}
                onMouseLeave={() => setPopperOpen(false)}
                className={classes.popover}
                elevation={0}
                style={{
                  width:
                    anchorEl && anchorEl.current
                      ? anchorEl.current.offsetWidth
                      : 48,
                }}
              >
                <Slider
                  className={classes.volumeSlider}
                  orientation="vertical"
                  value={volume}
                  aria-labelledby="vertical-slider"
                  onChange={handleVolumeChange}
                  onChangeCommitted={handleVolumeChange}
                  max={500}
                  // classes={{
                  //   rail: classes.rail,
                  //   track: classes.track,
                  // }}
                />
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
}
