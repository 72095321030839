import {
  format as formatTz,
  utcToZonedTime,
  zonedTimeToUtc,
} from "date-fns-tz";
import {
  format,
  formatDuration,
  intervalToDuration,
  parse,
  startOfDay,
} from "date-fns/fp";
import enUS from "date-fns/locale/en-US";
import { padCharsStart } from "lodash/fp";

// data-fns-tz needs this
(enUS as any).code = "en-US";

const dayIdx: Record<number, string> = {
  0: "Su",
  1: "M",
  2: "Tu",
  3: "W",
  4: "Th",
  5: "F",
  6: "Sa",
};

// Return a shorthand day range for the given array of days of the week.
const weekendIndexes = [0, 6];
export const getShorthandDayRange = (dayRange: number[] = []) => {
  if (dayRange.length === 0) return "";
  if (dayRange.length === 1) return dayIdx[dayRange[0]];
  if (dayRange.length === 7) return "Daily";
  if (
    dayRange.length === 2 &&
    dayRange.every((value) => weekendIndexes.includes(value))
  ) {
    return "Weekends";
  }
  if (
    dayRange.length === 5 &&
    dayRange.every((value) => !weekendIndexes.includes(value))
  ) {
    return "Weekdays";
  }

  const consecutive = dayRange.every(
    (value, i) => i === 0 || +value === +dayRange[i - 1] + 1
  );

  return consecutive
    ? `${dayIdx[dayRange[0]]}-${dayIdx[dayRange[dayRange.length - 1]]}`
    : dayRange.map((item) => dayIdx[item]).join(", ");
};

// WARNING: timezone behavior can be confusing, I suggest using `Date.prototype.toISOString()`
const isoFormat = `yyyy-MM-dd'T'HH:mm:ss`;
export const formatIsoDateTime = format(isoFormat);
export function formatIsoDateWithTimezone(date: Date, timeZone: string) {
  const zonedTime = utcToZonedTime(date, timeZone);
  return formatTz(zonedTime, isoFormat + " (z)", { timeZone });
}

export const parseIsoDate = parse(new Date(), "yyyy-MM-dd");
export const formatIsoDate = format("yyyy-MM-dd");

/**
 * Parses an iso time string (hh:mm:ss) and formats to time string.
 */
const parseIsoTime = parse(new Date(), "HH:mm:ss");
export function formatTimeString(
  value: string | number | Date,
  outputFormat = "hh:mmaaa"
) {
  const date =
    typeof value === "string"
      ? parseIsoTime(value === "24:00:00" ? "00:00:00" : value)
      : value;
  return format(outputFormat, date);
}

// Formats date in localized format with timezone
export function formatWithTimezone(date: Date, timeZone: string) {
  return formatTz(date, "Pp (z)", { timeZone, locale: enUS });

  // FP version import is broken (https://github.com/marnusw/date-fns-tz/issues/20)
  // return formatWithOptions(
  //   { timeZone, locale: enUS },
  //   dateWithTimezonePattern,
  //   date
  // );
}

export const dateTimeFormatSecs = new Intl.DateTimeFormat("en-US", {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
});

export const dateTimeFormat = new Intl.DateTimeFormat("en-US", {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
});

export const dateFormat = new Intl.DateTimeFormat("en-US", {
  year: "numeric",
  month: "numeric",
  day: "numeric",
});

export const timeFormat = new Intl.DateTimeFormat("en-US", {
  hour: "numeric",
  minute: "numeric",
});

export const timeFormatSecs = new Intl.DateTimeFormat("en-US", {
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
});

export function breakDownDuration() {}

type DurationLabelReplacements = {
  [k in keyof Duration]?: string;
};
export function formatShortDuration(
  start: Date,
  end: Date,
  replacements?: DurationLabelReplacements,
  removeSpaceBetweenValueAndLabel?: boolean
) {
  const duration = intervalToDuration({ start, end });
  const formatted = formatDuration(duration);
  if (!replacements) return formatted;

  return Object.entries(replacements).reduce(
    (result, [original, replacement]) => {
      const o = removeSpaceBetweenValueAndLabel ? ` ${original}` : original;
      return result.replace(o, replacement ?? o);
    },
    formatted
  );
}

const padSec = padCharsStart("0", 2);
export function formatVideoTime(time: number) {
  const totalSeconds = Math.floor(time / 1000);
  const totalMinutes = Math.floor(totalSeconds / 60);
  const totalHours = Math.floor(totalMinutes / 60);
  const parts = (totalHours > 0 ? [String(totalHours)] : []).concat(
    [totalMinutes % 60, totalSeconds % 60].map((x) => padSec(String(x)))
  );
  return parts.join(":");
}

export function formatTimeMinutes(totalMinutes: number) {
  const totalHours = Math.floor(totalMinutes / 60);
  let displayHours = totalHours % 12;
  if (displayHours === 0) displayHours = 12;
  const parts = [displayHours, totalMinutes % 60].map((x) => padSec(String(x)));
  const suffix = Math.floor(totalHours / 12) === 1 ? "PM" : "AM";
  return parts.join(":") + " " + suffix;
}

/**
 * Formats minutes to an iso time (hh:mm:ss).
 * Examples:
 *  - 861 => 14:21:00
 *  - 4 => 00:04:00
 *  - 1440 => 24:00:00
 */
export function formatIsoTimeMinutes(totalMinutes: number) {
  if (totalMinutes > 1440) throw new Error("Minutes out of bound");
  const totalHours = Math.floor(totalMinutes / 60);
  const parts = [totalHours, totalMinutes % 60, 0].map((x) =>
    padSec(String(x))
  );
  return parts.join(":");
}

/**
 * Parses an iso time (hh:mm) to minutes.
 * Examples:
 *  - 14:21 => 861
 *  - 00:04 => 4
 *  - 24:00 => 1440
 */
export function parseIsoTimeMinutes(value: string) {
  if (!/([0-9]+):([0-9]{2})/.test(value)) {
    throw new Error("Invalid time format: " + value);
  }
  const [hoursRaw, minutesRaw] = value.split(":");
  return Number(hoursRaw) * 60 + Number(minutesRaw);
}

// Inspired by https://github.com/marnusw/date-fns-tz/issues/67#issuecomment-927900990
export function startOfDayZoned(input: Date, timezone: string) {
  const inputZoned = utcToZonedTime(input, timezone);

  const dayStartZoned = startOfDay(inputZoned);

  return zonedTimeToUtc(dayStartZoned, timezone);
}
