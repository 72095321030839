import CloudIcon from "@mui/icons-material/CloudDone";
import CloudOffIcon from "@mui/icons-material/CloudOff";
import { SvgIconTypeMap, Tooltip, TooltipProps } from "@mui/material";
import { DefaultComponentProps } from "@mui/material/OverridableComponent";
import clsx from "clsx";
import React from "react";

export function SyncedToCloudIndicator({
  syncedToCloud,
  IconProps,
  TooltipProps,
  className,
}: {
  syncedToCloud?: boolean | null;
  IconProps?: DefaultComponentProps<SvgIconTypeMap>;
  TooltipProps?: Partial<TooltipProps>;
  className?: string;
}) {
  const Icon = syncedToCloud ? CloudIcon : CloudOffIcon;
  const title = syncedToCloud ? "Synced to cloud" : "Not synced to cloud";
  return (
    <Tooltip
      title={title}
      {...TooltipProps}
      className={clsx(className, TooltipProps?.className)}
    >
      <Icon
        fontSize="small"
        style={{ color: "rgba(0, 0, 0, .54)" }}
        {...IconProps}
      />
    </Tooltip>
  );
}
