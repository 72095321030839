import { CircularProgress } from "@mui/material";
import { motion } from "framer-motion";
import { useState } from "react";

import { CopilotBookAddIcon } from "@/icons/CopilotIcon";

import { CopilotLibraryActions } from "../../Core/CopilotLibraryActions";
import { CopilotPopoverHeader } from "../../Core/CopilotPopoverHeader";
import {
  LibraryAddStep,
  useCloseLibraryPopover,
  useLibraryParams,
} from "../../copilotLibraryHooks";
import {
  CopilotLibraryAddStepContent,
  useLibraryActionCallbacks,
  useLibraryActionProps,
} from "./CopilotLibraryAddStepContent";

export function CopilotLibraryAddContent() {
  const [libraryParams] = useLibraryParams();
  const [object, setObject] = useState("");
  const actionProps = useLibraryActionProps();
  const callbackProps = useLibraryActionCallbacks(object, setObject);
  const handleClose = useCloseLibraryPopover();

  const resolvedLoading = callbackProps.loading;
  const step = libraryParams.addStep;

  return (
    <>
      <CopilotPopoverHeader
        icon={<CopilotBookAddIcon className="mt-[3px]" />}
        title="Coach Custom Object"
        hideSubtitleDivider
        subtitle={
          step === LibraryAddStep.input
            ? "Coach an object you want AI Copilot to search for. If it’s new, AI Copilot will add it to the Object Library and will search for it in future searches."
            : ""
        }
        onClose={handleClose}
      />
      <div className="flex flex-col gap-[10px]">
        <motion.div
          className="w-full"
          key="lib-details-add-content-input"
          initial={{ opacity: 0, translateX: -10 }}
          animate={{ opacity: 1, translateX: 0 }}
          transition={{
            opacity: { ease: "easeInOut" },
            layout: { duration: 0.2 },
          }}
        >
          {resolvedLoading && (
            <div className="flex items-center justify-center py-8 px-4">
              <CircularProgress />
            </div>
          )}
          {!resolvedLoading && (
            <CopilotLibraryAddStepContent
              value={object}
              onConfirm={callbackProps.onConfirm}
              onChange={(v) => {
                setObject(v);
              }}
            />
          )}
        </motion.div>
        <CopilotLibraryActions
          cancelGhost
          expanded={!!object}
          disabled={callbackProps.loading}
          {...callbackProps}
          {...actionProps}
        />
      </div>
    </>
  );
}
