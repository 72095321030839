import gql from "graphql-tag";
import { useEffect, useState } from "react";
import { Element, ElementCompact, Options, xml2js } from "xml-js";

import {
  DCMessageSender,
  useLivekitDataChannel,
} from "@/components/Player/WebRTCPlayer/hooks/useLivekitDataChannel";

import { useGenerateOnvifCredentialsMutation } from "@/generated-models";

export interface PTZControls {
  startMove: (x: number, y: number) => Promise<void>; // x, y are -1<->1
  startZoom: (direction: number) => Promise<void>; // direction is -1<->1
  stop: () => Promise<void>;
  gotoHome: () => Promise<void>;
}

export interface FocusControls {
  startFocus: (direction: number) => Promise<void>; // direction is -1<->1
  stop: () => Promise<void>;
}

export interface CameraControls {
  ptz?: PTZControls;
  focus?: FocusControls;
}

interface UsePTZProps {
  cameraId: number;
  deviceId: number;
  url: string;
}

export function useCameraControls({ cameraId, deviceId, url }: UsePTZProps) {
  const dc = useLivekitDataChannel(url.split("&")[0]);

  const [state, setState] = useState<{
    supported: boolean;
    controls: CameraControls | null;
  }>({ supported: false, controls: null });

  const [
    generateOnvifCredentialsMutation,
  ] = useGenerateOnvifCredentialsMutation();

  useEffect(() => {
    (async () => {
      if (!dc || !dc.connected) {
        // supported but (yet?) not connected
        setState({ supported: true, controls: null });
        return;
      }

      const connectionInfo = {
        camId: cameraId,
        deviceService: "/onvif/device_service",
      };

      // give the datachannel a chance to fully connect
      await new Promise((resolve) => setTimeout(resolve, 500));

      try {
        const clockSkew = await getClockSkew(dc, connectionInfo);

        const getHash = async () => {
          const now = Date.now();
          const creds = await generateOnvifCredentialsMutation({
            variables: {
              input: {
                id: deviceId,
                created: new Date(now + clockSkew).toISOString(),
              },
            },
          });
          return creds.data?.generateOnvifCredentials ?? "";
        };

        const endpoints = await getServices(
          dc,
          connectionInfo,
          await getHash()
        );
        const mediaService = endpoints.get(
          "http://www.onvif.org/ver10/media/wsdl"
        );
        const media2Service = endpoints.get(
          "http://www.onvif.org/ver20/media/wsdl"
        );
        const ptzService = endpoints.get("http://www.onvif.org/ver20/ptz/wsdl");
        const imagingService = endpoints.get(
          "http://www.onvif.org/ver20/imaging/wsdl"
        );

        if (
          (!media2Service && !mediaService) ||
          (!ptzService && !imagingService)
        ) {
          console.warn("missing required service:", {
            mediaService,
            media2Service,
            ptzService,
            imagingService,
            endpoints,
          });
          setState({ supported: true, controls: null });
          return;
        }

        const fullConnInfo = {
          ...connectionInfo,
          media2Service,
          mediaService,
          ptzService,
          imagingService,
        };

        const ptzControls = await getPTZControls(
          dc,
          fullConnInfo,
          getHash
        ).catch((e) => {
          console.error("failed to get PTZ controls", e);
          return undefined;
        });
        const focusControls = await getFocusControls(
          dc,
          fullConnInfo,
          getHash
        ).catch((e) => {
          console.error("failed to get focus controls", e);
          return undefined;
        });
        if (!ptzControls && !focusControls) {
          console.warn("no controls");
          setState({ supported: true, controls: null });
          return;
        }

        setState({
          supported: true,
          controls: {
            ptz: ptzControls,
            focus: focusControls,
          },
        });
      } catch (e) {
        console.error("PTZ error", e);
        // something is wrong, assume we don't support it
        setState({ supported: false, controls: null });
      }
    })();
  }, [cameraId, deviceId, generateOnvifCredentialsMutation, dc, dc?.connected]);

  return state;
}

const getPTZControls = async (
  dc: DCMessageSender,
  connInfo: ConnectionInfo,
  getHash: () => Promise<string>
) => {
  // TODO: check Configurations to determine if it supports pan/tilt or just zoom
  const profile = connInfo.ptzService
    ? await getProfile(dc, connInfo, await getHash())
    : null;

  return profile
    ? {
        startMove: async (x: number, y: number) => {
          await startMove(dc, connInfo, await getHash(), profile, { x, y });
        },
        startZoom: async (direction: number) => {
          await startZoom(dc, connInfo, await getHash(), profile, direction);
        },
        stop: async () => {
          await stop(dc, connInfo, await getHash(), profile);
        },
        gotoHome: async () => {
          await gotoHome(dc, connInfo, await getHash(), profile);
        },
      }
    : undefined;
};

const getFocusControls = async (
  dc: DCMessageSender,
  connInfo: ConnectionInfo,
  getHash: () => Promise<string>
) => {
  const videoSource = connInfo.imagingService
    ? await getVideoSource(dc, connInfo, await getHash())
    : null;

  return videoSource
    ? {
        startFocus: async (direction: number) => {
          await startFocus(
            dc,
            connInfo,
            await getHash(),
            videoSource,
            direction
          );
        },
        stop: async () => {
          await stopFocus(dc, connInfo, await getHash(), videoSource);
        },
      }
    : undefined;
};

interface ConnectionInfo {
  camId: number;
  deviceService: string;
  mediaService?: string;
  media2Service?: string;
  ptzService?: string;
  imagingService?: string;
}

interface MessageResult {
  code: number;
  body: string;
}

interface Response {
  error?: string;
  response?: MessageResult;
}

const sendCamMessage = async (dc: DCMessageSender, message: string) => {
  if (localStorage.getItem("ptzDebug") === "true") console.log(message);
  return dc.send(message).then((ev) => {
    const r = JSON.parse(ev.data) as Response;
    if (r.error) {
      throw new Error(`request failed: ${r.error}`);
    } else if (!r.response) {
      throw new Error("response empty");
    }

    if (r.response.code !== 200) {
      throw new Error(
        `request failed with status: ${r.response.code}, ${r.response.body}`
      );
    }
    return r.response;
  });
};

const createMessage = (request: string, headers: string = "") => {
  return `<?xml version="1.0" encoding="UTF-8"?><Envelope xmlns="http://www.w3.org/2003/05/soap-envelope"><Header>${headers}</Header><Body>${request}</Body></Envelope>`;
};

const createWebRTCMessage = (camId: number, path: string, body: string) => {
  return JSON.stringify({
    camId,
    path,
    contentType: "application/soap+xml; charset=utf-8",
    body,
  });
};

const getSystemDateAndTime = () => {
  const body = `<GetSystemDateAndTime xmlns="http://www.onvif.org/ver10/device/wsdl" />`;
  return createMessage(body);
};

const getClockSkew = async (dc: DCMessageSender, connInfo: ConnectionInfo) => {
  return new Promise<number>(async (resolve, reject) => {
    if (!connInfo.deviceService) {
      reject("no device service");
      return;
    }

    try {
      const response = await sendCamMessage(
        dc,
        createWebRTCMessage(
          connInfo.camId,
          connInfo.deviceService,
          getSystemDateAndTime()
        )
      );

      const localTime = new Date();

      const nsMap = new Map<string, string>();
      const opts: Options.XML2JS = {
        compact: true,
        attributeValueFn: nsCollector(nsMap),
      };
      const xml = xml2js(response.body, opts) as ElementCompact;

      const soapPrefix = nsMap.get("http://www.w3.org/2003/05/soap-envelope");
      const tdsPrefix = nsMap.get("http://www.onvif.org/ver10/device/wsdl");
      const ttPrefix = nsMap.get("http://www.onvif.org/ver10/schema");

      const env = xml[soapPrefix + "Envelope"] as ElementCompact;
      const body = env[soapPrefix + "Body"] as ElementCompact;

      const fault = env[soapPrefix + "Fault"] as ElementCompact;
      if (fault) {
        reject(`getClockSkew failed with fault: ${fault}`);
      }

      const utcTime = body[tdsPrefix + "GetSystemDateAndTimeResponse"][
        tdsPrefix + "SystemDateAndTime"
      ][ttPrefix + "UTCDateTime"] as ElementCompact;

      const time = utcTime[ttPrefix + "Time"] as ElementCompact;
      const hour = (time[ttPrefix + "Hour"] as ElementCompact)._text as number;
      const min = (time[ttPrefix + "Minute"] as ElementCompact)._text as number;
      const sec = (time[ttPrefix + "Second"] as ElementCompact)._text as number;

      const date = utcTime[ttPrefix + "Date"] as ElementCompact;
      const year = (date[ttPrefix + "Year"] as ElementCompact)._text as number;
      const month = (date[ttPrefix + "Month"] as ElementCompact)
        ._text as number;
      const day = (date[ttPrefix + "Day"] as ElementCompact)._text as number;

      const camTime = new Date(Date.UTC(year, month - 1, day, hour, min, sec));

      resolve(camTime.getTime() - localTime.getTime());
    } catch (e) {
      reject(e);
    }
  });
};

const getServices = async (
  dc: DCMessageSender,
  connInfo: ConnectionInfo,
  headers: string
) => {
  return new Promise<Map<string, string>>(async (resolve, reject) => {
    if (!connInfo.deviceService) {
      reject("no device service");
      return;
    }

    try {
      const msgBody =
        '<GetServices xmlns="http://www.onvif.org/ver10/device/wsdl"><IncludeCapability>true</IncludeCapability></GetServices>';
      const msg = createMessage(msgBody, headers);

      const response = await sendCamMessage(
        dc,
        createWebRTCMessage(connInfo.camId, connInfo.deviceService, msg)
      );

      const nsMap = new Map<string, string>();
      const opts: Options.XML2JS = {
        compact: true,
        attributeValueFn: nsCollector(nsMap),
      };
      const xml = xml2js(response.body, opts) as ElementCompact;

      const soapPrefix = nsMap.get("http://www.w3.org/2003/05/soap-envelope");
      const tdsPrefix = nsMap.get("http://www.onvif.org/ver10/device/wsdl");

      const env = xml[soapPrefix + "Envelope"] as ElementCompact;
      const body = env[soapPrefix + "Body"] as ElementCompact;
      const fault = env[soapPrefix + "Fault"] as ElementCompact;
      if (fault) {
        reject(`getServices failed with fault: ${fault}`);
      }

      const resp = body[tdsPrefix + "GetServicesResponse"] as ElementCompact;
      const services = resp[tdsPrefix + "Service"] as ElementCompact[];

      const endpoints = new Map<string, string>();
      services.forEach((elem) => {
        const ns = (elem[tdsPrefix + "Namespace"] as ElementCompact)
          ._text as string;
        const xaddr = (elem[tdsPrefix + "XAddr"] as ElementCompact)
          ._text as string;
        endpoints.set(ns, new URL(xaddr).pathname);
      });

      resolve(endpoints);
    } catch (e) {
      reject(e);
    }
  });
};

const getProfile = async (
  dc: DCMessageSender,
  connInfo: ConnectionInfo,
  headers: string,
  attempt: number = 1
): Promise<string> => {
  const ms = getMediaService(connInfo);
  if (!ms) {
    throw Error("no media service");
  }

  try {
    const msgBody = `<GetProfiles xmlns="http://www.onvif.org/ver${ms.version}/media/wsdl" />`;
    const msg = createMessage(msgBody, headers);

    const response = await sendCamMessage(
      dc,
      createWebRTCMessage(connInfo.camId, ms.path, msg)
    );

    const nsMap = new Map<string, string>();
    const opts: Options.XML2JS = {
      compact: false, // we need profiles in the correct order
      attributeValueFn: nsCollector(nsMap),
    };
    const xml = xml2js(response.body, opts) as Element;

    const env = xml.elements![0];
    // there might be a header!
    const body = env.elements![env.elements!.length > 1 ? 1 : 0];

    const resp = body.elements![0];
    const profilesElem1 = resp.elements![0];
    const token = profilesElem1.attributes!["token"] as string;
    if (!token) {
      throw Error(
        `token not found for profile: ${JSON.stringify(profilesElem1)}`
      );
    }

    return token;
  } catch (e) {
    console.error(e);
    const MAX_ATTEMPTS = 10;
    if (attempt <= MAX_ATTEMPTS) {
      await new Promise((resolve) => setTimeout(resolve, 1500));
      console.log(`Retrying getProfile attempt=${attempt + 1}`);
      return getProfile(dc, connInfo, headers, attempt + 1);
    }
    console.error(
      `CRITICAL PTZ ERROR: getProfile failed after ${MAX_ATTEMPTS} attempts`
    );
    throw e;
  }
};

const startMove = async (
  dc: DCMessageSender,
  connInfo: ConnectionInfo,
  headers: string,
  profile: string,
  direction: { x: number; y: number }
) => {
  return new Promise<void>(async (resolve, reject) => {
    if (!connInfo.ptzService) {
      reject("no PTZ service");
      return;
    }

    try {
      await sendCamMessage(
        dc,
        createWebRTCMessage(
          connInfo.camId,
          connInfo.ptzService,
          createContinuousMove(headers, profile, {
            coordinates: direction,
          })
        )
      );

      resolve();
    } catch (e) {
      reject(e);
    }
  });
};

const startZoom = async (
  dc: DCMessageSender,
  connInfo: ConnectionInfo,
  headers: string,
  profile: string,
  direction: number
) => {
  return new Promise<void>(async (resolve, reject) => {
    if (!connInfo.ptzService) {
      reject("no PTZ service");
      return;
    }

    try {
      await sendCamMessage(
        dc,
        createWebRTCMessage(
          connInfo.camId,
          connInfo.ptzService,
          createContinuousMove(headers, profile, {
            zoom: direction,
          })
        )
      );

      resolve();
    } catch (e) {
      reject(e);
    }
  });
};

const stop = async (
  dc: DCMessageSender,
  connInfo: ConnectionInfo,
  headers: string,
  profile: string
) => {
  return new Promise<void>(async (resolve, reject) => {
    if (!connInfo.ptzService) {
      reject("no PTZ service");
      return;
    }

    try {
      await sendCamMessage(
        dc,
        createWebRTCMessage(
          connInfo.camId,
          connInfo.ptzService,
          createStop(headers, profile)
        )
      );

      resolve();
    } catch (e) {
      reject(e);
    }
  });
};

const gotoHome = async (
  dc: DCMessageSender,
  connInfo: ConnectionInfo,
  headers: string,
  profile: string
) => {
  return new Promise<void>(async (resolve, reject) => {
    if (!connInfo.ptzService) {
      reject("no PTZ service");
      return;
    }

    try {
      await sendCamMessage(
        dc,
        createWebRTCMessage(
          connInfo.camId,
          connInfo.ptzService,
          createGotoHome(headers, profile)
        )
      );

      resolve();
    } catch (e) {
      reject(e);
    }
  });
};

const getVideoSource = async (
  dc: DCMessageSender,
  connInfo: ConnectionInfo,
  headers: string
) => {
  return new Promise<string>(async (resolve, reject) => {
    const ms = getMediaService(connInfo);
    if (!ms) {
      reject("no media service");
      return;
    }

    try {
      const msgBody = `<GetVideoSourceConfigurations xmlns="http://www.onvif.org/ver${ms.version}/media/wsdl"/>`;
      const msg = createMessage(msgBody, headers);

      const response = await sendCamMessage(
        dc,
        createWebRTCMessage(connInfo.camId, ms.path, msg)
      );

      const nsMap = new Map<string, string>();
      const opts: Options.XML2JS = {
        compact: false, // we need profiles in the correct order
        attributeValueFn: nsCollector(nsMap),
      };
      const xml = xml2js(response.body, opts) as Element;

      const ttPrefix = nsMap.get("http://www.onvif.org/ver10/schema");

      const env = xml.elements![0];
      // there might be a header!
      const body = env.elements![env.elements!.length > 1 ? 1 : 0];

      const resp = body.elements![0];
      const configElem = resp.elements![0];
      const tokenElem = configElem.elements?.find(
        (e) => e.name === ttPrefix + "SourceToken"
      );
      const token = tokenElem?.elements![0].text as string;
      if (!token) {
        reject(
          `sourceToken not found for video source: ${JSON.stringify(
            configElem
          )}`
        );
      }

      resolve(token);
    } catch (e) {
      reject(e);
    }
  });
};

const startFocus = async (
  dc: DCMessageSender,
  connInfo: ConnectionInfo,
  headers: string,
  source: string,
  direction: number
) => {
  return new Promise<void>(async (resolve, reject) => {
    if (!connInfo.imagingService) {
      reject("no imaging service");
      return;
    }

    try {
      await sendCamMessage(
        dc,
        createWebRTCMessage(
          connInfo.camId,
          connInfo.imagingService,
          createContinuousFocus(headers, source, direction)
        )
      );

      resolve();
    } catch (e) {
      reject(e);
    }
  });
};

const stopFocus = async (
  dc: DCMessageSender,
  connInfo: ConnectionInfo,
  headers: string,
  source: string
) => {
  return new Promise<void>(async (resolve, reject) => {
    if (!connInfo.imagingService) {
      reject("no imaging service");
      return;
    }

    try {
      await sendCamMessage(
        dc,
        createWebRTCMessage(
          connInfo.camId,
          connInfo.imagingService,
          createStopFocus(headers, source)
        )
      );

      resolve();
    } catch (e) {
      reject(e);
    }
  });
};

interface Vector {
  coordinates?: {
    x: number;
    y: number;
  } /* -1 to 1 for move, 0 to 1 for speed */;
  zoom?: number /* 0 to 1 */;
}

const createContinuousMove = (headers: string, profile: string, v: Vector) => {
  const body =
    `<ContinuousMove xmlns="http://www.onvif.org/ver20/ptz/wsdl" xmlns:tt="http://www.onvif.org/ver10/schema"><ProfileToken>${profile}</ProfileToken><Velocity>` +
    (v && v.coordinates
      ? `<tt:PanTilt x="${v.coordinates.x}" y="${v.coordinates.y}" />`
      : "") +
    (v && v.zoom !== undefined ? `<tt:Zoom x="${v.zoom}" />` : "") +
    "</Velocity></ContinuousMove>";
  return createMessage(body, headers);
};

const createStop = (headers: string, profile: string) => {
  const body = `<Stop xmlns="http://www.onvif.org/ver20/ptz/wsdl"><ProfileToken>${profile}</ProfileToken><PanTilt>true</PanTilt><Zoom>true</Zoom></Stop>`;
  return createMessage(body, headers);
};

const createGotoHome = (headers: string, profile: string) => {
  const body = `<GotoHomePosition xmlns="http://www.onvif.org/ver20/ptz/wsdl" xmlns:tt="http://www.onvif.org/ver10/schema"><ProfileToken>${profile}</ProfileToken></GotoHomePosition>`;
  return createMessage(body, headers);
};

const createContinuousFocus = (
  headers: string,
  source: string,
  direction: number
) => {
  const body = `<Move xmlns="http://www.onvif.org/ver20/imaging/wsdl" xmlns:tt="http://www.onvif.org/ver10/schema"><VideoSourceToken>${source}</VideoSourceToken><Focus><tt:Continuous><tt:Speed>${direction}</tt:Speed></tt:Continuous></Focus></Move>`;
  return createMessage(body, headers);
};

const createStopFocus = (headers: string, source: string) => {
  const body = `<Stop xmlns="http://www.onvif.org/ver20/imaging/wsdl"><VideoSourceToken>${source}</VideoSourceToken></Stop>`;
  return createMessage(body, headers);
};

// this is a poor man's way of gathering the namespace prefixes.
// It won't work if the prefix definitions change, or the XML uses
// default namespaces, but most cameras don't do that.
const nsCollector = (nsMap: Map<string, string>) => (
  value: string,
  name: string
) => {
  if (name.startsWith("xmlns:")) {
    nsMap.set(value, name.slice(6) + ":");
  }

  return value;
};

const getMediaService = (connInfo: ConnectionInfo) => {
  let path = "";
  let version = "";
  if (connInfo.media2Service) {
    path = connInfo.media2Service;
    version = "20";
  } else if (connInfo.mediaService) {
    path = connInfo.mediaService;
    version = "10";
  } else {
    return null;
  }

  return { path, version };
};

gql`
  mutation generateOnvifCredentials($input: OnvifCredentialsInput!) {
    generateOnvifCredentials(input: $input)
  }
`;
