import { Checkbox, FormGroup, FormControlLabel } from "@mui/material";
import { schemaRequiresTrueValue, WidgetProps } from "@rjsf/utils";

export const SchemaCheckboxWidget = function (props: WidgetProps) {
  const {
    schema,
    id,
    value,
    disabled,
    readonly,
    autofocus,
    onChange,
    onBlur,
    onFocus,
  } = props;

  const required = schemaRequiresTrueValue(schema);
  const { description } = schema;

  const _onChange = (_: any, checked: boolean) => onChange(checked);
  const _onBlur = ({
    target: { value },
  }: React.FocusEvent<HTMLButtonElement>) => onBlur(id, value);
  const _onFocus = ({
    target: { value },
  }: React.FocusEvent<HTMLButtonElement>) => onFocus(id, value);

  return (
    <FormGroup>
      <FormControlLabel
        sx={{
          padding: "0",
          marginTop: "-22px",
        }}
        control={
          <Checkbox
            id={id}
            name={id}
            disabled={disabled || readonly}
            required={required}
            checked={Boolean(value)}
            autoFocus={autofocus}
            onChange={_onChange}
            onBlur={_onBlur}
            onFocus={_onFocus}
          />
        }
        label={description}
      />
    </FormGroup>
  );
};
