import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import {
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { debounce } from "lodash/fp";
import React, { useCallback, useState } from "react";
import { StringParam, useQueryParam } from "use-query-params";

import { useBreakpoints } from "@/util/useBreakpoints";

import { MINIMUM_QUERY_LENGTH } from "../constants";

export function LicensePlateSearchInput() {
  const { fitsDesktop, fitsLarge } = useBreakpoints();
  const [searchFilter, setSearchFilter] = useQueryParam("search", StringParam);
  const [viewing, setViewing] = useQueryParam("viewing", StringParam);
  const [value, setValue] = useState(searchFilter);
  const showHelp =
    searchFilter &&
    searchFilter.length < MINIMUM_QUERY_LENGTH &&
    searchFilter.length > 0;
  const setInput = (result: string) => {
    // Clear current view if applicable.
    if (viewing) {
      setViewing("");
    }
    setSearchFilter(result);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const persistInput = useCallback(debounce(500, setInput), []);
  return (
    <div
      className={clsx("flex-1", {
        "w-full": !fitsLarge,
        "w-[433px]": fitsLarge,
      })}
    >
      <TextField
        size={fitsDesktop ? "medium" : "small"}
        className="w-full"
        onChange={(e) => {
          setValue(e.target.value);
          persistInput(e.target.value);
        }}
        placeholder="Enter Plate Number"
        variant="outlined"
        value={value}
        inputProps={{
          spellCheck: false,
        }}
        InputProps={{
          classes: {
            root: "rounded-[75px] bg-white lg:min-w-[433px]",
            input: clsx(
              "font-mono h-[18px] truncate tracking-[2px] md:tracking-[2.8px]",
              {
                "text-[20px] leading-[26px] md:text-[28px] md:leading-[37px] font-medium uppercase": value,
                "text-base leading-[18px] font-normal": !value,
              }
            ),
            notchedOutline:
              "border-[1.26761px] border-solid border-[#AAD8FF] focus:border-primary",
          },
          startAdornment: (
            <InputAdornment position="start">
              <div className="flex items-center gap-x-2">
                <SearchIcon
                  fontSize={fitsDesktop ? "medium" : "small"}
                  color="primary"
                />

                <div className="font-bold text-primary text-sm leading-[18px] md:text-lg md:leading-6 font-mono">
                  License Plate:
                </div>
              </div>
            </InputAdornment>
          ),
          endAdornment: value && (
            <InputAdornment position="end">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setValue("");
                  setInput("");
                }}
                aria-label="clear search"
                size="small"
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {showHelp && (
        <Typography className="font-medium text-primary text-sm md:text-base leading-4 text-center mt-2 md:mt-0 md:text-start">
          Enter at least 3 characters
        </Typography>
      )}
    </div>
  );
}
