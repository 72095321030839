import DropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Backdrop, Button, Fade, Modal, Paper } from "@mui/material";
import clsx from "clsx";
import { useState } from "react";

import { filterFalsy, filterNullish } from "@/util/filterFalsy";
import { useBreakpoints } from "@/util/useBreakpoints";

import {
  CreateSavedZone,
  EditZone,
  MobileCreateEditZone,
  SelectZones,
  ZoneModalMode,
} from "@/pages/Search/ZoneMotion/ZoneModals";

import { CameraFeeds, Focus } from "@/generated-models";
import { DarkTheme } from "@/layout/theme";

export type ZoneSelectProps = SingleOrMultiSelectProps & {
  className?: string;
  cameraId: number;
  zones: Focus[];
  snapshotSource: string;
  feeds?: Pick<CameraFeeds, "tunnel" | "local">;
};

type SingleOrMultiSelectProps = SingleZoneSelectProps | MultiSelectProps;

interface SingleZoneSelectProps {
  zoneId: number | null;
  setZoneId: (value: number | null) => void;
  multi?: false;
}

interface MultiSelectProps {
  zoneIds: number[];
  setZoneIds: (value: number[]) => void;
  multi: true;
}

export function useActiveZones<T extends { id: number }>(
  zones: T[],
  zoneIds: number[]
) {
  // Match zone select value with camera zones
  return zoneIds
    .map((zoneId) => zones.find((z) => z.id === zoneId))
    .filter(filterFalsy);
}

export function ZoneSelect({
  className,
  cameraId,
  zones,
  snapshotSource,
  feeds,
  ...singleOrMultiProps
}: ZoneSelectProps) {
  const { fitsDesktop } = useBreakpoints();

  const [zoneIds, setZoneIds] = singleOrMultiProps.multi
    ? [singleOrMultiProps.zoneIds, singleOrMultiProps.setZoneIds]
    : [
        [singleOrMultiProps.zoneId].filter(filterNullish),
        (ids: number[]) => singleOrMultiProps.setZoneId(ids[0]),
      ];
  const [modalMode, setModalMode] = useState<ZoneModalMode | null>(null);
  const closeModal = () => setModalMode(null);
  const modalOpen = modalMode !== null;

  const activeZones = useActiveZones(zones, zoneIds);
  const hasActiveZones = activeZones.length > 0;

  const muxConfig = { cameraId, playerName: "Zone Select Live Player" };

  return (
    <>
      <div className="flex items-center shrink-0 text-xs">
        <Button
          disabled={!hasActiveZones}
          className={clsx(
            "h-10 min-w-[100px] px-3 border border-solid rounded-r-none rounded-l transition-none shrink-0 text-sm",
            {
              "text-[#757575] font-normal border-[#BDBDBD] border-r-0": hasActiveZones,
            },
            {
              "bg-primary text-white font-bold border-[#2D95EC]": !hasActiveZones,
            },
            className
          )}
          onClick={() => setZoneIds([])}
        >
          Entire Cam
        </Button>
        <Button
          data-cy="select-zone-button"
          className={clsx(
            "h-10 min-w-[72px] px-3 border border-solid rounded-l-none rounded-r transition-none shrink-0 text-sm",
            {
              "text-[#757575] font-normal border-[#BDBDBD] border-l-0": !hasActiveZones,
            },
            {
              "bg-primary text-white font-bold border-[#2D95EC]": hasActiveZones,
            },
            className
          )}
          classes={{ endIcon: hasActiveZones ? "ml-0.5 -mr-2.5" : undefined }}
          endIcon={hasActiveZones && <DropDownIcon />}
          onClick={() => {
            if (!hasActiveZones && zones.length > 0) {
              // Set initial state to selecting the first saved zone
              setZoneIds([zones[0].id]);
              setModalMode(ZoneModalMode.Select);
            } else {
              // Create a new zone or edit selected zone
              setModalMode(
                hasActiveZones ? ZoneModalMode.Select : ZoneModalMode.Create
              );
            }
          }}
        >
          <div className="max-w-[100px] truncate">
            {hasActiveZones
              ? activeZones.map((z) => z.name).join(", ")
              : "Zone"}
          </div>
        </Button>
      </div>
      <DarkTheme enabled={fitsDesktop}>
        <Modal
          open={modalOpen}
          onClose={closeModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 350 }}
          className="flex-center"
          disablePortal={"Cypress" in global} // portals broken in Cypress tests because they're rendered outside of id=app container
        >
          <Fade in={modalOpen}>
            <Paper className="w-full md:h-[unset] h-full md:w-[800px]">
              {modalMode === ZoneModalMode.Select && hasActiveZones && (
                <SelectZones
                  snapshotSource={snapshotSource}
                  feeds={feeds}
                  muxConfig={muxConfig}
                  zones={zones}
                  activeZones={activeZones}
                  setZoneIds={setZoneIds}
                  editZone={() => setModalMode(ZoneModalMode.Edit)}
                  createNew={() => setModalMode(ZoneModalMode.Create)}
                  close={closeModal}
                  multi={singleOrMultiProps.multi}
                />
              )}
              {modalMode === ZoneModalMode.Edit && activeZones.length === 1 && (
                <>
                  <span className="hidden md:block">
                    <EditZone
                      zone={activeZones[0]}
                      snapshotSource={snapshotSource}
                      feeds={feeds}
                      muxConfig={muxConfig}
                      close={closeModal}
                    />
                  </span>
                  <span className="md:hidden">
                    <MobileCreateEditZone
                      mode="edit"
                      zone={activeZones[0]}
                      snapshotSource={snapshotSource}
                      close={closeModal}
                      feeds={feeds}
                      muxConfig={muxConfig}
                    />
                  </span>
                </>
              )}
              {modalMode === ZoneModalMode.Create && (
                <CreateSavedZone
                  cameraId={cameraId}
                  snapshotSource={snapshotSource}
                  feeds={feeds}
                  muxConfig={muxConfig}
                  setZoneId={(id: number) => setZoneIds([id])}
                  close={closeModal}
                />
              )}
            </Paper>
          </Fade>
        </Modal>
      </DarkTheme>
    </>
  );
}
