import { Button } from "@mui/material";
import { useCallback, useState } from "react";

import {
  MentionSuggestions,
  SpotMentionInput,
  SpotMentionInputProps,
} from "@/pages/Cases/SpotMentionInput";

export function CreateCaseCommentBox({
  onSubmitComment,
  ...props
}: Omit<
  SpotMentionInputProps,
  "commentState" | "mentionsState" | "onSubmitComment"
> & {
  onSubmitComment: (
    comment: string,
    mentions: MentionSuggestions,
    reset: () => void
  ) => void;
}) {
  const commentState = useState("");
  const mentionsState = useState<MentionSuggestions>({});
  const reset = useCallback(() => {
    commentState[1]("");
    mentionsState[1]({});
    // eslint-disable-next-line
  }, []);

  return (
    <div className="relative flex gap-2 flex-row">
      <SpotMentionInput
        {...props}
        onSubmitComment={(comment, mentionsState) =>
          onSubmitComment(comment, mentionsState, reset)
        }
        classes={{ suggestion: "bg-white" }}
        commentState={commentState}
        mentionsState={mentionsState}
      />
      <Button
        variant="contained"
        color="primary"
        disabled={props.disabled || !commentState[0]}
        onClick={() =>
          onSubmitComment(commentState[0], mentionsState[0], reset)
        }
        className="self-end flex-shrink-0 h-[40px]"
      >
        Comment
      </Button>
    </div>
  );
}
