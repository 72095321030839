import { Container, Paper } from "@mui/material";

import { ErrorMessage } from "@/components/ErrorMessage";

import { IntelligenceDashboardQuery } from "@/generated-models";

import { IntLoadingIndicator } from "../../IntLoadingIndicator";
import { LicensePlateInterestListReportDetailsBar } from "./LicensePlateInterestListReportDetailsBar";
import { LicensePlateInterestListReportFilterBar } from "./LicensePlateInterestListReportFilterBar";
import { LicensePlateInterestListVisitChart } from "./LicensePlateInterestListVisitChart";
import { LicensePlateReportDatagrid } from "./LicensePlateReportDatagrid";
import { LicensePlateReportPageHeader } from "./LicensePlateReportPageHeader";
import { useLprReportGrouped } from "./hooks";

interface LicensePlateReportPageProps {
  dashboard: IntelligenceDashboardQuery["intelligenceDashboard"];
}

export function LicensePlateInterestListReportPage({
  dashboard,
}: LicensePlateReportPageProps) {
  const { loading, error } = useLprReportGrouped();

  if (error) {
    return (
      <>
        <LicensePlateReportPageHeader dashboard={dashboard} />
        <LicensePlateInterestListReportFilterBar dashboard={dashboard} />
        <ErrorMessage
          title="Oops!"
          description={
            error.message ||
            "Failed to load license plate report results. Please try again."
          }
        />
      </>
    );
  }

  return (
    <Container maxWidth="lg" className="px-0 md:px-6 md:pt-8">
      <Paper elevation={0} className="md:rounded-lg md:shadow-paper">
        <LicensePlateReportPageHeader dashboard={dashboard} />
        {loading ? (
          <IntLoadingIndicator className="flex justify-center items-center py-20 w-full" />
        ) : (
          <div className="mb-6">
            <LicensePlateInterestListReportFilterBar dashboard={dashboard} />
            <LicensePlateInterestListVisitChart title="License Plates of Interest" />
            <LicensePlateInterestListReportDetailsBar dashboard={dashboard} />
            <LicensePlateReportDatagrid dashboard={dashboard} />
          </div>
        )}
      </Paper>
    </Container>
  );
}
