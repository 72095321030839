import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import { groupBy } from "lodash";
import { findIndex } from "lodash/fp";
import React from "react";

import { useVodParam } from "@/pages/Search/searchHooks";

import {
  useAiUiState,
  useAiVodQuery,
  useFrameQuery,
} from "@/components/Ai/AiState";
import {
  useMultiPlayerControls,
  useWallclockPts,
  useWallclockTime,
} from "@/components/Player/PlayerBase";
import { useFocusedCam } from "@/components/View/sharedViewHooks";

import { Appliance, Camera, Location } from "@/generated-models";

type ListingCamera = Pick<Camera, "id" | "name" | "status"> & {
  location: Pick<Location, "id" | "timezone">;
} & {
  appliance: Pick<Appliance, "serialNumber">;
};

export default function AiDebugTools({
  activeCameras,
}: {
  activeCameras: ListingCamera[];
}) {
  const { vodStart: startTime, vodEnd: endTime } = useVodParam();
  const wallclockTime = useWallclockTime()?.getTime();
  const wallclockPtsNullable = useWallclockPts();
  const wallclockPts = !!wallclockPtsNullable ? wallclockPtsNullable : 0;
  const focusedCam = useFocusedCam(activeCameras);
  const vodObjectsData = useAiVodQuery(
    focusedCam.id,
    startTime.toISOString(),
    endTime.toISOString(),
    ["0", "1", "2", "5", "7"]
  );
  const closestFrame = useFrameQuery(vodObjectsData?.objectFrames || []);

  return (
    <div className="w-full h-full flex-col flex px-2 py-3 gap-2 overflow-y-auto">
      <Typography variant="h1">AI Tools</Typography>
      <Typography variant="caption">
        ({focusedCam.id} on {focusedCam.appliance.serialNumber})
      </Typography>

      {!!vodObjectsData ? (
        <>
          <JumpForwardButton
            wallclockTime={wallclockTime}
            wallclockPts={wallclockPts}
            closestFrame={closestFrame}
            objectFrames={vodObjectsData.displayingFrames}
          />
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                <strong>Vod Statistics</strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <VodStatistics
                wallclockTime={wallclockTime}
                wallclockPts={wallclockPts}
                startTime={startTime.toISOString()}
                endTime={endTime.toISOString()}
                {...vodObjectsData}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                <strong>Frame Statistics</strong> - {wallclockTime}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FrameStatistics
                wallclockTime={wallclockTime}
                wallclockPts={wallclockPts}
                closestFrame={closestFrame}
              />
            </AccordionDetails>
          </Accordion>
        </>
      ) : (
        <div>Select a VOD to get started</div>
      )}
    </div>
  );
}

function FrameStatistics({
  closestFrame,
  wallclockTime,
  wallclockPts,
}: {
  closestFrame: ReturnType<typeof useFrameQuery>;
  wallclockTime?: number;
  wallclockPts?: number;
}) {
  const [{ syncAlg }, setAiUi] = useAiUiState();
  return (
    <div className="w-full">
      {closestFrame && (
        <>
          <label className="pointer-events-auto cursor-pointer">
            Timestamp
            <Switch
              checked={syncAlg}
              onChange={(_, checked) => setAiUi({ syncAlg: checked })}
            />{" "}
            PTS{" "}
          </label>
          <br />
          Wallclock Time: {wallclockTime}
          <br />
          Wallclock PTS: {wallclockPts}
          <br />
          ========= Synced PTS: {closestFrame.pts}
          <br />
          =========
          <br />
          {Object.entries(
            groupBy(
              closestFrame.objects.map(({ type: { id }, score }) => ({
                id,
                score,
              })),
              ({ id }) => id
            )
          ).map(([id, objs]) => (
            <React.Fragment key={id}>
              {`${id}: ${objs.length} detected`}
              <br />
              <strong>confidence scores</strong>
              <br />
              {objs
                .map(({ score }) => score)
                .sort()
                .reverse()
                .map((score, id) => (
                  <React.Fragment key={id}>
                    {score}
                    <br />
                  </React.Fragment>
                ))}
              <br />
              <br />
            </React.Fragment>
          ))}
        </>
      )}
    </div>
  );
}

interface VodStatsProps extends NonNullable<ReturnType<typeof useAiVodQuery>> {
  startTime: string;
  endTime: string;
  wallclockTime?: number;
  wallclockPts?: number;
}

function VodStatistics(props: VodStatsProps) {
  const { objectFrames, filteredScores, startTime, endTime } = props;
  const [{ bboxView }, setAiUi] = useAiUiState();

  return (
    <div className="w-full">
      <label className="pointer-events-auto cursor-pointer text-orange bg-white">
        <Select
          value={bboxView}
          label="Bounding box type"
          onChange={(e) => setAiUi({ bboxView: e.target.value as string })}
          MenuProps={{ style: { zIndex: 999999 } }}
        >
          <MenuItem value={"normal"}>normal</MenuItem>
          <MenuItem value={"raw"}>raw</MenuItem>
        </Select>
      </label>
      <br />
      Total frames (w/ 1.5 min buf): {objectFrames.length}
      <br />
      Apparent det fps:{" "}
      {(
        objectFrames.length /
        ((new Date(endTime).getTime() - new Date(startTime).getTime()) / 1000 +
          60 * 2)
      ).toFixed(2)}
      <br />
      Frames w/ objects:{" "}
      {objectFrames.filter(({ objects }) => objects.length !== 0).length}
      <br />
      Lowest confidence: {Math.min(...filteredScores)}
      <br />
      Highest confidence: {Math.max(...filteredScores)}
      <br />
      Median confidence: {median(filteredScores)}
    </div>
  );
}

function JumpForwardButton({
  wallclockTime,
  wallclockPts,
  closestFrame,
  objectFrames,
}: {
  closestFrame: ReturnType<typeof useFrameQuery>;
  wallclockTime?: number;
  wallclockPts?: number;
  objectFrames: NonNullable<
    ReturnType<typeof useAiVodQuery>
  >["displayingFrames"];
}) {
  const { seek } = useMultiPlayerControls();
  const [{ syncAlg }] = useAiUiState();
  let jumpForwardTime = 0;
  let currentTimeOrPts = -1;
  if (syncAlg && !!wallclockPts) {
    currentTimeOrPts = wallclockPts;
  } else if (!syncAlg && !!wallclockTime) {
    currentTimeOrPts = wallclockTime;
  }
  if (currentTimeOrPts !== -1) {
    let closestFrameObjectCount = 0;
    if (closestFrame?.objects) {
      closestFrameObjectCount = closestFrame.objects.length;
    }
    let nextChangedFrameIdx = findIndex(
      ({ objects, pts }) =>
        !!pts &&
        pts > currentTimeOrPts &&
        objects.length !== closestFrameObjectCount,
      objectFrames
    );

    // If the object count of the nextChangedFrame is 0, we want to skip forward
    // again to the next object detection
    if (
      nextChangedFrameIdx !== -1 &&
      objectFrames[nextChangedFrameIdx].objects.length === 0
    ) {
      const newFrameTimeOrPts = objectFrames[nextChangedFrameIdx].pts;

      nextChangedFrameIdx = findIndex(
        ({ objects, pts }) =>
          !!pts &&
          !!newFrameTimeOrPts &&
          pts > newFrameTimeOrPts &&
          objects.length > 0,
        objectFrames
      );
    }

    let nextTimeOrPts = currentTimeOrPts;
    if (nextChangedFrameIdx !== -1) {
      if (objectFrames[nextChangedFrameIdx]?.pts) {
        nextTimeOrPts = objectFrames[nextChangedFrameIdx].pts;
      }
    }

    jumpForwardTime = (nextTimeOrPts - currentTimeOrPts) / 1000;
  }
  return (
    <Button
      color="primary"
      variant="contained"
      onClick={() => seek((p) => p + jumpForwardTime)}
    >
      Jump To Next Detection Change
    </Button>
  );
}

const median = (array: number[]) => {
  array.sort((a, b) => b - a);
  const length = array.length;
  if (length % 2 === 0) {
    return (array[length / 2] + array[length / 2 - 1]) / 2;
  } else {
    return array[Math.floor(length / 2)];
  }
};
