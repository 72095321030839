import DeleteIcon from "@mui/icons-material/HighlightOff";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { SeriesOption } from "echarts";
import { groupBy } from "lodash/fp";
import { useMemo, useState } from "react";

import { ReactECharts } from "@/components/Chart";
import { lineTimeseriesConfig } from "@/components/Chart/utils";
import { ErrorMessage } from "@/components/ErrorMessage";

import {
  MaintainMetricsConfig,
  MaintainMetricsConfigType,
  MetricsRange,
} from "@/generated-models";

import {
  useMaintainTimeSeriesData,
  useMetricResourceChartMetadata,
  useRemoveMaintainDashboardMetric,
} from "../hooks";
import { MaintainMetricsChartRangeSelector } from "./MaintainMetricsChartRangeSelector";
import { getBooleanChartOptions, getChartOptions } from "./utils";

function getSeriesOptions(label: string, color: string) {
  return {
    ...lineTimeseriesConfig,
    name: label,
    type: "line" as "line",
    symbolSize: 2,
    dimensions: ["timestamp", "value"],
    lineStyle: { color },
    itemStyle: {
      color,
    },
  };
}

interface MaintainMetricsChartProps {
  metric: MaintainMetricsConfig;
}

export function MaintainMetricsChart({ metric }: MaintainMetricsChartProps) {
  const removeMetric = useRemoveMaintainDashboardMetric();
  const { label, field, unit, description } = metric;
  const [range, setRange] = useState<MetricsRange>(MetricsRange.Monthly);
  const { data, loading, error } = useMaintainTimeSeriesData({
    field,
    range,
  });
  const { colors, displayNames } = useMetricResourceChartMetadata();

  const isBoolean = metric.type === MaintainMetricsConfigType.Boolean;

  const seriesConfig = useMemo(() => {
    const seriesOptions = Object.entries(
      groupBy("label", data?.maintainMetricTimeSeries?.series || [])
    ).map(([label, data]) => {
      const color = colors[label];
      const displayLabel = displayNames[label] || label;
      return {
        ...getSeriesOptions(displayLabel, color),
        data: data
          .sort((a, b) => b.timestamp - a.timestamp)
          .map((x) => {
            return [new Date(x.timestamp), x[field] || 0];
          }),
      };
    }) as SeriesOption[];

    Object.entries(colors).forEach(([h, color]) => {
      if (!seriesOptions.find((o) => o.name === h)) {
        const displayLabel = displayNames[h] || h;
        seriesOptions.push({
          ...getSeriesOptions(displayLabel, color),
          data: [],
        });
      }
    });

    return seriesOptions;
  }, [colors, data?.maintainMetricTimeSeries?.series, displayNames, field]);

  return (
    <div className="flex flex-col gap-5 p-4 h-full">
      <div className="flex items-center justify-between">
        <div className="flex gap-1">
          <Tooltip title={description}>
            <Typography className="font-bold text-lg leading-[21.09px]">
              {label}
            </Typography>
          </Tooltip>
          <IconButton
            className="p-0"
            size="small"
            onClick={() => {
              removeMetric(field);
            }}
          >
            <DeleteIcon className="text-base" />
          </IconButton>
        </div>
        <div />
        <MaintainMetricsChartRangeSelector
          value={range}
          onValueChange={(newRange) => {
            setRange(newRange);
          }}
        />
      </div>
      {error && (
        <div className="py-6">
          <ErrorMessage
            dense
            title="Error"
            description={<span>{error.toString()}</span>}
          />
        </div>
      )}
      {!error && (
        <div
          className="w-full h-full relative"
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
        >
          {unit && !isBoolean && (
            <Typography className="font-mono text-[10px] absolute -rotate-90 top-1/3 -left-[18px] text-[#757575] opacity-80">
              {unit}
            </Typography>
          )}
          <ReactECharts
            style={{ height: "100%", minHeight: 275 }}
            loading={loading}
            option={{
              ...((isBoolean
                ? getBooleanChartOptions(label, unit?.[0], unit?.[1])
                : getChartOptions(label, unit?.[0])) as any),
              series: seriesConfig,
            }}
          />
        </div>
      )}
    </div>
  );
}
