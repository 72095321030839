import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PersonIcon from "@mui/icons-material/Person";
import {
  Button,
  Checkbox,
  IconButton,
  MenuItem,
  Popover,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { GridFilterListIcon } from "@mui/x-data-grid";
import clsx from "clsx";
import { format } from "date-fns-tz";
import gql from "graphql-tag";
import { xor, filter } from "lodash";
import { useEffect, useRef, useState } from "react";
import { ArrayParam, useQueryParam } from "use-query-params";

import { filterNullish } from "@/util/filterFalsy";

import { DatePicker } from "@/components/Filter/Fields/FilterAccordionDateField";

import { useAuditLogFiltersQuery } from "@/generated-models";

import { AuditLogFilterBar } from "./AuditLogFilterBar";
import { FilterAccordion } from "./FilterAccordion";
import { useAuditLogFilters } from "./hooks";

type PopperType = HTMLButtonElement | null;

export function AuditLogsSummaryBar() {
  const { data } = useAuditLogFiltersQuery();
  const [categoriesFilter] = useQueryParam("categories", ArrayParam);
  const [, setActionsFilter] = useQueryParam("actions", ArrayParam);
  const [anchorEl, setAnchorEl] = useState<PopperType>(null);
  const [calPopperEl, setCalPopperEl] = useState<PopperType>(null);
  const [userPopperEl, setUserPopperEl] = useState<PopperType>(null);
  const { newFilters, userFilter } = useAuditLogFilters();
  const [expandedFilter, setExpandedFilter] = useState<string | null>(null);

  const categories = data?.auditLogCategories.map((c) => c.name) || [];
  const currentCatFilters =
    categoriesFilter?.filter(filterNullish) ?? categories;
  const prevCatFilters = useRef<string[]>([]);
  const [filterVal, setFilterVal] = useQueryParam("users", ArrayParam);
  const filteredParsed = filterVal ?? [];

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const handlePopperClose = () => {
  //   setCalPopperEl(null);
  // };

  const open = Boolean(anchorEl);
  const CalPopperOpen = Boolean(calPopperEl);
  const UserPopperOpen = Boolean(userPopperEl);
  const id = open ? "simple-popover" : undefined;
  const UserPopperId = open ? "user-popover" : undefined;

  // TODO: this causes a query with new cat but old actions;
  // also switch from lis/los to los can result in the wrong row due to caching?

  // reset actions when the category selection changes
  useEffect(() => {
    if (xor(prevCatFilters.current, currentCatFilters).length > 0) {
      setActionsFilter(undefined);
    }
    prevCatFilters.current = currentCatFilters;
  }, [setActionsFilter, currentCatFilters]);

  return (
    <>
      <div className="bg-blue-medium/[.2] py-4 px-2">
        <div className="flex justify-end gap-3">
          <Tooltip title="Users filter" placement="top">
            <IconButton
              className="bg-[#ddefff] p-2"
              size="small"
              color="primary"
              onClick={(event) =>
                setUserPopperEl(userPopperEl ? null : event.currentTarget)
              }
            >
              <PersonIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Date filter" placement="top">
            <IconButton
              className="bg-[#ddefff] p-2 w-10"
              size="small"
              color="primary"
              onClick={(event) =>
                setCalPopperEl(calPopperEl ? null : event.currentTarget)
              }
            >
              <CalendarTodayIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Action filters" placement="top">
            <Button
              className={clsx(
                "px-3.5 rounded-full",
                open ? "bg-primary text-white" : "bg-[#ddefff]"
              )}
              size="small"
              color="primary"
              startIcon={<GridFilterListIcon />}
              onClick={handleClick}
              aria-describedby={id}
            >
              Filters
            </Button>
          </Tooltip>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            aria-labelledby="log-filters"
            aria-describedby="log-filters"
            PaperProps={{
              className: "bg-[#F4FAFF]",
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <div className="top-full rounded z-20 flex flex-col p-2.5 w-[298px] gap-1">
              {newFilters?.map((filterDetails) => {
                return (
                  <FilterAccordion
                    key={filterDetails.filterName}
                    expandedFilter={expandedFilter}
                    setExpandedFilter={setExpandedFilter}
                    {...filterDetails}
                  />
                );
              })}
            </div>
          </Popover>
          <Popover
            id={UserPopperId}
            open={UserPopperOpen}
            anchorEl={userPopperEl}
            onClose={() => setUserPopperEl(null)}
            aria-labelledby="user-filter"
            aria-describedby="user-filter"
            PaperProps={{
              className: "bg-[#F4FAFF]",
            }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <div className="top-full rounded z-20 flex flex-col p-2.5 w-[298px] gap-1 h-80">
              {userFilter?.options.map((user) => (
                <MenuItem
                  key={`${user.label}-${user.secondaryLabel}-${user.value}`}
                  value={`${user.value}`}
                  classes={{
                    root: "whitespace-normal",
                  }}
                  onClick={() => {
                    if (filteredParsed.includes(`${user.value}`)) {
                      setFilterVal((val) =>
                        filter(val, (v) => v !== `${user.value}`)
                      );
                    } else {
                      setFilterVal((val) => [...(val ?? []), `${user.value}`]);
                    }
                  }}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={filteredParsed.includes(`${user.value}`)}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText
                    className="capitalize"
                    data-cy="filter-dd-item-users"
                    primary={user.label}
                    secondary={user.secondaryLabel}
                  />
                </MenuItem>
              ))}
            </div>
          </Popover>
        </div>
      </div>
      <AuditLogFilterBar
        className="mt-5"
        filters={[
          ...newFilters,
          userFilter,
          {
            placeholder: "Dates & Times",
            filterName: "datetime",
            label: "Date & Time",
            getValue: (value: string | null) => {
              return (
                value
                  ?.split("|")
                  .map((vi) => format(new Date(vi), "MMM d, yyyy p"))
                  .join(" - ") || ""
              );
            },
            options: [],
          },
        ]}
      />
      <DatePicker
        open={CalPopperOpen}
        anchorEl={calPopperEl}
        setAnchorEl={setCalPopperEl}
        minDate={new Date("2020-07-01")}
      />
    </>
  );
}

gql`
  query auditLogFilters {
    users {
      id
      profile {
        id
        name
      }
    }
    auditLogCategories {
      name
      actions
    }
  }
`;
