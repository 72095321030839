import { Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  label: {
    fontSize: 12,
    opacity: 0.54,
    color: "black",
  },
}));

/**
 * Designed to mimic the layout of the material-ui TextField, but without it
 * being an input field.
 */
export function TextView({
  label,
  children,
  className,
}: {
  label: string;
  children: React.ReactNode;
  className?: string;
}) {
  const { classes } = useStyles();
  return (
    <div className={className}>
      <Typography className={classes.label} variant="body2" component="label">
        {label}
      </Typography>
      <Typography>{children}</Typography>
    </div>
  );
}
