import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  ClickAwayListener,
  FormControl,
  InputLabel,
  Modal,
  Popper,
  Slide,
  OutlinedInput,
} from "@mui/material";
import clsx from "clsx";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { addMinutes, subDays } from "date-fns/fp";
import { useRef, useState } from "react";
import { ArrayParam, useQueryParam } from "use-query-params";

import { useBreakpoints } from "@/util/useBreakpoints";

import { useSearchRangeParams } from "@/pages/Search/searchHooks";

import {
  DateTimeRangePopper,
  MobileDateTimeRangePopper,
} from "@/components/DateTimePicker/DateTimeRangePicker";

import { FilterFieldProps } from "../constant";

export function FilterDateField<T>({
  placeholder,
  filterName,
  classes = { root: "" },
  style,
  timezone,
  defaultStartRange,
  minDate,
  maxDate,
}: FilterFieldProps<T> & {
  defaultStartRange?: number;
  timezone?: string;
  minDate?: Date;
  maxDate?: Date;
}) {
  const { fitsDesktop } = useBreakpoints();
  const [filterVal] = useQueryParam(filterName, ArrayParam);
  const [popperOpen, setPopperOpen] = useState(false);
  const { rangeStart, rangeEnd, setRangeParam } = useSearchRangeParams(
    defaultStartRange
  );
  const mainContainerRef = useRef<HTMLDivElement>(null);
  const filteredParsed = filterVal ?? [];
  const filterSelected = filteredParsed.length > 0;
  const close = () => setPopperOpen(false);

  const tz = timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
  const startValue = utcToZonedTime(rangeStart, tz);
  const endValue = utcToZonedTime(rangeEnd, tz);

  const timeRangePopperProps = {
    startValue,
    endValue,
    onChange: (start: Date, end: Date) => {
      close();
      setRangeParam({
        start: zonedTimeToUtc(start, tz),
        end: zonedTimeToUtc(end, tz),
      });
    },
    close,
    reset: () => setRangeParam(null),
    minDate: minDate ?? utcToZonedTime(subDays(90, new Date()), tz),
    maxDate: maxDate ?? utcToZonedTime(addMinutes(60, new Date()), tz),
  };

  return (
    <FormControl
      size="small"
      style={style ?? { minWidth: 200 }}
      fullWidth
      className="w-full flex-1"
    >
      {!filterSelected && (
        <InputLabel variant="outlined" shrink={false}>
          {placeholder}
        </InputLabel>
      )}

      <ClickAwayListener onClickAway={close}>
        <div className="w-full">
          <OutlinedInput
            ref={mainContainerRef}
            notched={false}
            endAdornment={
              <ArrowDropDownIcon
                onClick={() => {
                  setPopperOpen(true);
                }}
                className="-mr-2 text-[#808080] cursor-pointer"
              />
            }
            classes={{
              root: clsx(
                "rounded-lg w-full",
                filterSelected && "text-[#353D48]",
                classes.root
              ),
              input: clsx(filterSelected && "font-bold", "cursor-pointer"),
              notchedOutline: clsx(
                classes.notchedOutline,
                filterSelected && "border-[#AAD8FF]"
              ),
            }}
            value={filterSelected ? "Date & Time Selected" : ""}
            inputProps={{
              readOnly: true,
            }}
            onClick={() => {
              setPopperOpen(true);
            }}
            className="bg-white"
            label={filterName}
          />
          {fitsDesktop && (
            <Popper
              open={popperOpen}
              anchorEl={mainContainerRef.current}
              className="z-[1201]"
            >
              <DateTimeRangePopper {...timeRangePopperProps} />
            </Popper>
          )}
          {!fitsDesktop && (
            <Modal open={popperOpen} onClose={close}>
              <Slide direction="up" in={popperOpen} mountOnEnter unmountOnExit>
                <div className="absolute bottom-0 w-full">
                  <MobileDateTimeRangePopper {...timeRangePopperProps} />
                </div>
              </Slide>
            </Modal>
          )}
        </div>
      </ClickAwayListener>
    </FormControl>
  );
}
