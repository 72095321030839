import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Hidden,
  IconButton,
  Typography,
  Grid,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import React, { useEffect, useState } from "react";
import { useInterval } from "react-use";
import { makeStyles } from "tss-react/mui";

import { useScript } from "@/util/useScript";

import { Logo } from "@/components/shared/Logo";

import { SalesContact } from "@/generated-models";

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    color: "#fff",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column-reverse",
    },
  },
  demoBar: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    flexWrap: "wrap",
    height: 40,
    backgroundColor: theme.palette.text.primary,
  },
  demoIcon: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    padding: "0 12px",
    backgroundColor: "#000",
    fontWeight: "bold",
    fontSize: 16,
  },
  viewOnly: {
    padding: "3px 9px",
    backgroundColor: "#4CD23F",
    borderRadius: 2,
    fontWeight: "bold",
  },
  countDown: {
    margin: "0 16px 0 auto",
    opacity: 0.5,
  },
  contactBar: {
    display: "flex",
    alignItems: "center",
    flexShrink: 0,
    height: 40,
    padding: "0 12px",
    backgroundColor: theme.palette.primary.main,
  },
}));

const INTITIAL_INTERVAL = 30000;
const FINAL_INTERVAL = 180000;

export function DemoHeaderBar({
  salesContact,
}: {
  salesContact?: Pick<SalesContact, "email" | "phone"> | null;
}) {
  const { classes } = useStyles();
  const [meetingPopupOpen, setMeetingPopupShown] = useState(false);
  const [popupInterval, setPopupInterval] = useState(INTITIAL_INTERVAL);
  const [meetingScheduled, setMeetingScheduled] = useState(false);
  useChiliPiper({
    updateScheduleStatus: setMeetingScheduled,
    updateModalState: setMeetingPopupShown,
  });

  useInterval(async () => {
    if (popupInterval === INTITIAL_INTERVAL) {
      setPopupInterval(FINAL_INTERVAL);
    }
    if (!meetingPopupOpen && !meetingScheduled) {
      setMeetingPopupShown(true);
    }
  }, popupInterval);

  const salesEmail = salesContact?.email || "sales@spotai.co";
  const salesPhone = salesContact?.phone || "(650) 487-0980";

  return (
    <div className={classes.root}>
      <div className={classes.demoBar}>
        <div
          className={classes.demoIcon}
          //style={expired ? { backgroundColor: "#D71E1E" } : {}}
        >
          <span>DEMO</span>
        </div>
        <Box m={0.5} />
        <div className={classes.viewOnly}>View Only</div>
        <Box m={1} />
        <Hidden lgDown>
          <div>For Demo, only viewing functionality enabled.</div>
        </Hidden>
      </div>
      <div className={classes.contactBar}>
        <div style={{ fontWeight: "bold" }}>Get Spot today</div>
        <Box m={1} />
        <div style={{ marginLeft: "auto" }}>
          <span>{salesEmail}</span>
          <span style={{ opacity: 0.4, margin: "0 5px" }}>or</span>
          <span>{salesPhone}</span>
        </div>
      </div>
      <DemoExpiredModal
        salesEmail={salesEmail}
        salesPhone={salesPhone}
        open={meetingPopupOpen}
        updateModalState={setMeetingPopupShown}
      />
    </div>
  );
}

const useModalStyles = makeStyles()((theme) => ({
  topBar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(1),
  },
  title: {
    paddingTop: theme.spacing(1),
    paddingBottom: 0,
  },
  divider: {
    background: "linear-gradient(90deg, #007CE4 0%, #004E8F 100%)",
    height: 74,
    width: "100%",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  checklist: {
    padding: theme.spacing(0),
  },
  contactInfo: {
    background: "#ACACAC33",
  },
  contactContent: {
    marginTop: "-10px",
    textAlign: "center",
  },
  schedulingPopup: {
    maxWidth: "315px",
  },
  formGrid: {
    padding: theme.spacing(2),
  },
  closeButton: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
  },
  message: {
    lineHeight: "16px",
  },
  salesInfo: {
    fontSize: "13px",
  },
}));

export function DemoExpiredModal({
  salesEmail,
  salesPhone,
  open,
  updateModalState,
}: {
  salesEmail: String;
  salesPhone: String;
  open: boolean;
  updateModalState: (state: boolean) => void;
}) {
  const { classes } = useModalStyles();

  return (
    <Dialog open={open} maxWidth="xs">
      <Box className={classes.schedulingPopup}>
        <Box className={classes.closeButton}>
          <IconButton
            onClick={() => updateModalState(false)}
            size="small"
            edge="end"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className={classes.topBar}>
          <DialogTitle className={classes.title}>
            <Box display="flex" alignItems="center">
              <Logo className="h-10" />
            </Box>
          </DialogTitle>
        </Box>
        <DialogContent>
          <Typography className={classes.message}>
            Want to see how Spot works with your cameras? Book a demo and get a
            $50 gift card!
          </Typography>
        </DialogContent>
        <Box m={1} />
        <Box>
          <Formik
            initialValues={{
              LastName: "",
              Email: localStorage.getItem("demoEmail") || "",
              Phone: "",
            }}
            onSubmit={(values) => console.log(values)}
            validate={(values) => {
              let errors: any = {};
              const emailErr = validateEmail(values.Email);
              if (emailErr) {
                errors.Email = emailErr;
              }
              if (!values.Phone) {
                errors.Phone = "Phone is required";
              }
              if (!values.LastName) {
                errors.LastName = "Full Name is required";
              }
              return errors;
            }}
          >
            {({ isValid }) => (
              <Form name="salesMeeting">
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  className={classes.formGrid}
                >
                  <Field
                    component={TextField}
                    name="LastName"
                    label="Full Name"
                    required
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    size="small"
                  />
                  <Box m={1} />
                  <Field
                    component={TextField}
                    name="Email"
                    label="Work Email"
                    required
                    variant="outlined"
                    type="email"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    size="small"
                  />
                  <Box m={1} />
                  <Field
                    component={TextField}
                    name="Phone"
                    label="Phone"
                    required
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    size="small"
                    inputProps={{
                      minLength: 8,
                    }}
                  />
                  <Box m={1} />

                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth
                    disabled={!isValid}
                  >
                    SCHEDULE A MEETING
                  </Button>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
        <Box m={1} />
        <Box className={classes.contactInfo}>
          <DialogContent>
            <Box className={classes.contactContent}>
              <Typography className={classes.salesInfo}>
                Contact us:{"  "}
                <span style={{ fontWeight: "bold" }}>{salesEmail}</span>
                {"  "} | {"  "}
                <span style={{ fontWeight: "bold" }}>{salesPhone}</span>{" "}
              </Typography>
            </Box>
          </DialogContent>
        </Box>
      </Box>
    </Dialog>
  );
}

declare global {
  interface Window {
    ChiliPiper: {
      scheduling: (subdomain: string, router: string, options: any) => {};
      debug: boolean;
      onSuccess: () => void;
      onClose: () => void;
      query: string;
    };
  }
}
function useChiliPiper({
  updateScheduleStatus,
  updateModalState,
}: {
  updateScheduleStatus: (state: boolean) => void;
  updateModalState: (state: boolean) => void;
}) {
  const [loaded] = useScript("https://js.chilipiper.com/marketing.js");
  useEffect(() => {
    if (!loaded) return;
    window.ChiliPiper?.scheduling("spotai", "demos-from-dashbo", {
      title: "Thanks! What time works best for a quick call?",
      query: "form[name*='salesMeeting']",
      debug: false,
      onSuccess: () => {
        updateScheduleStatus(true);
      },
      onClose: () => {
        updateModalState(false);
      },
    });
  }, [loaded, updateScheduleStatus, updateModalState]);
}

function validateEmail(value: string | undefined): string | undefined {
  const freeMailList = [
    "gmail.",
    "yahoo.",
    "hotmail.",
    "aol.",
    "msn.",
    "comcast.",
  ];
  if (!value) {
    return "Email is required";
  }
  return freeMailList
    .map(function (item) {
      if (value.toLowerCase().includes(item)) {
        return "Please use a work email";
      }
      return "";
    })
    .filter(function (item) {
      return item;
    })[0];
}
