import { Box, IconButton, Snackbar } from "@mui/material";
import clsx from "clsx";
import { useAtomValue, useSetAtom } from "jotai";
import { clamp } from "lodash/fp";
import React, { useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { makeStyles, withStyles } from "tss-react/mui";

import {
  addPointerListener,
  getRelativeMousePosition,
  Position,
  subtractPosition,
} from "@/util/mouseEvents";
import { useBreakpoints } from "@/util/useBreakpoints";

import {
  defaultPlayerId,
  qualityLevelsState,
  useIsBuffering,
  usePlayerControls,
  zoomState,
} from "@/components/Player/PlayerBase";
import {
  CloseIcon,
  ZoomInIcon,
  ZoomOutIcon,
} from "@/components/Player/PlayerIcons";
import { PlayerTooltip } from "@/components/Player/PlayerTooltip";
import { useSetPlayerSetting } from "@/components/Player/playerControlsMachine";
import { useVisualQuality } from "@/components/Player/playerMachine";

import { BufferingInfo } from "./BufferingInfo";

const useStyles = makeStyles<void, "zoomMode">()((theme, _props, classes) => ({
  zoomMode: {
    outline: `2px solid ${theme.palette.primary.main}`,
    border: `2px solid ${theme.palette.primary.main}`,
    borderBottom: 0,
    overflow: "hidden",
  },
  zoomTransition: { cursor: "grab" },
  zoomDragging: { cursor: "grabbing" },
}));

export function DigitalPtzButton({
  disabled,
  playerId = defaultPlayerId,
  size = "medium",
}: {
  disabled?: string;
  playerId?: string;
  size?: "medium" | "small";
}) {
  const { fitsTablet } = useBreakpoints();
  const [videoEl, setVideoEl] = useState<HTMLVideoElement | null>(null);
  const { getPlayerElement } = usePlayerControls(playerId);
  const setZoomAtom = useSetAtom(zoomState(playerId));

  useEffect(() => {
    if (disabled) {
      setVideoEl(null);
      setZoomAtom(null);
    }
  }, [disabled, setVideoEl, setZoomAtom]);

  if (!fitsTablet) return null;

  return (
    <div className="relative pt-px shrink-0">
      {videoEl ? (
        <ZoomMode
          playerId={playerId}
          videoEl={videoEl}
          cancel={() => {
            setZoomAtom(null);
            setVideoEl(null);
          }}
        />
      ) : (
        <PlayerTooltip title={disabled ?? "Zoom mode"}>
          <span>
            <IconButton
              size={size}
              className={clsx("text-white", { "text-primary": !!videoEl })}
              disabled={!!disabled}
              classes={{ disabled: "opacity-50" }}
              onClick={() => {
                const element = getPlayerElement();
                if (element) setVideoEl(element);
                else {
                  console.error(
                    "Failed to activate zoom mode, couldn't find video tag"
                  );
                }
              }}
              aria-label="zoom mode"
            >
              <ZoomInIcon />
            </IconButton>
          </span>
        </PlayerTooltip>
      )}
    </div>
  );
}

function ZoomMode({
  playerId,
  videoEl,
  cancel,
}: {
  playerId: string;
  videoEl: HTMLElement;
  cancel: () => void;
}) {
  const { classes: styles } = useStyles();
  const [zoomLevel, setZoomLevel] = useState(2);
  const [position, setPosition] = useState<Position>({ x: 0.5, y: 0.5 });
  // qualityLoaded is used to delay activating zoom mode when higher quality needs to be loaded
  const [qualityLoaded, setQualityLoaded] = useState(false);
  const buffering = useIsBuffering(playerId);
  const [qualityWarning, setQualityWarning] = useState<string | null>(null);
  const closeWarning = useCallback(() => setQualityWarning(null), [
    setQualityWarning,
  ]);
  const setZoomAtom = useSetAtom(zoomState(playerId));

  useEffect(() => {
    if (qualityLoaded) {
      const parentEl = videoEl.parentElement;
      parentEl?.classList.add(styles.zoomMode);
      videoEl.classList.add(styles.zoomTransition, "transition-transform");
      return () => {
        // Cleanup
        videoEl.style.removeProperty("transform");
        parentEl?.classList.remove(styles.zoomMode);
        videoEl.classList.remove(styles.zoomTransition, "transition-transform");
      };
    }
  }, [videoEl, styles.zoomMode, styles.zoomTransition, qualityLoaded]);

  useEffect(() => {
    if (qualityLoaded) {
      applyZoomTransform(videoEl, zoomLevel, position);
      setZoomAtom({ zoomLevel, xPos: position.x, yPos: position.y });
    }
  }, [videoEl, position, zoomLevel, qualityLoaded, setZoomAtom]);

  useEffect(() => {
    let cleanups: (() => void)[] = [];

    function handleDragStart(startPos: Position, button?: number) {
      cleanup();
      const parentEl = videoEl.parentElement;
      if (!parentEl) return;
      // Remove transition while dragging
      videoEl.classList.remove(styles.zoomTransition, "transition-transform");
      // Add drag cursor
      videoEl.classList.add(styles.zoomDragging);

      let dragStart = getRelativeMousePosition(startPos, parentEl);
      let newPos = position;

      function handleDrag(dragPos: Position) {
        if (!parentEl) return;
        // Get relative mouse position within the video player
        const relPos = getRelativeMousePosition(dragPos, parentEl);
        // Get the difference between start and current mouse position
        const diff = subtractPosition(relPos, dragStart);
        // Calculate the new video position
        newPos = clampPosition(calcPosition(position, diff, zoomLevel));
        applyZoomTransform(videoEl, zoomLevel, newPos);
        setZoomAtom({
          zoomLevel,
          xPos: newPos.x,
          yPos: newPos.y,
          dragging: true,
        });
      }
      cleanups.push(
        addPointerListener("mousemove", "touchmove", window, handleDrag)
      );

      function handleMouseUp(_: Position, upButton?: number) {
        if (button !== upButton) return;

        setPosition({ ...newPos });
        videoEl.classList.add(styles.zoomTransition, "transition-transform");
        videoEl.classList.remove(styles.zoomDragging);
      }
      cleanups.push(
        addPointerListener("mouseup", "touchend", window, handleMouseUp)
      );
    }
    let cleanupDragStart = addPointerListener(
      "mousedown",
      "touchstart",
      videoEl,
      handleDragStart
    );

    function cleanup() {
      cleanupDragStart();
      cleanups.forEach((x) => x());
      cleanups.length = 0;
    }

    return cleanup;
  }, [
    videoEl,
    position,
    zoomLevel,
    styles.zoomTransition,
    styles.zoomDragging,
    setZoomAtom,
  ]);

  const visualQuality = useVisualQuality();
  const qualityLevels = useAtomValue(qualityLevelsState);
  const setPlayerSetting = useSetPlayerSetting();
  const { forceQuality, getPlayerElement } = usePlayerControls(playerId);

  // Side effects on player
  useEffect(() => {
    // Set highest quality level
    const highestQualityLevel = getHighestQualityLevel(qualityLevels);
    if (!highestQualityLevel) {
      setQualityLoaded(true);
      if (visualQuality < 720) setQualityWarning(`${visualQuality}p`);
      return;
    }
    setPlayerSetting("quality", `${highestQualityLevel.height}p`);

    if (highestQualityLevel.height < 720) {
      setQualityWarning(`${highestQualityLevel.height}p`);
    }

    // Make sure the player will zoom with highest resolution
    if (!visualQuality || visualQuality !== highestQualityLevel.height) {
      forceQuality(`${highestQualityLevel.height}p`, () => {
        setQualityLoaded(true);
      });
    } else setQualityLoaded(true);

    // toggle the currentTime to force the new resolution
    const ve = videoEl as HTMLVideoElement;
    const old = ve.currentTime;
    const adjust = old >= 1 ? 1 : -1;
    ve.currentTime = old - adjust;
    ve.currentTime = old;

    return () => {
      setPlayerSetting("quality", "auto");
    };
    // eslint-disable-next-line
  }, []);

  const zoomIn = () => {
    if (zoomLevel < 16) {
      setPosition(calcZoomInPosition(position, zoomLevel * 2));
      setZoomLevel(zoomLevel * 2);
    }
  };
  const zoomOut = () => {
    if (zoomLevel > 1) {
      setPosition(
        zoomLevel === 2
          ? { x: 0.5, y: 0.5 }
          : clampPosition(calcZoomOutPosition(position, zoomLevel / 2))
      );
      setZoomLevel(zoomLevel / 2);
    }
  };

  const containerEl = getPlayerElement()?.parentElement;

  return (
    <>
      {containerEl && (
        <ZoomBar
          cancel={cancel}
          zoomLevel={zoomLevel}
          zoomIn={zoomIn}
          zoomOut={zoomOut}
        />
      )}
      {buffering && containerEl && (
        <BufferingInfo
          title="Buffering highest resolution for zoom"
          element={containerEl}
          cancel={cancel}
        />
      )}
      {containerEl && qualityLoaded && qualityWarning && (
        <QualityWarning
          element={containerEl}
          qualityWarning={qualityWarning}
          close={closeWarning}
        />
      )}
    </>
  );
}

function ZoomBar({
  zoomLevel,
  zoomIn,
  zoomOut,
  cancel,
}: {
  zoomLevel: number;
  zoomIn: () => void;
  zoomOut: () => void;
  cancel: () => void;
}) {
  return (
    <div className="flex-center p-1 mx-auto rounded bg-black bg-opacity-70 text-white text-xs transition-opacity opacity-70 hover:opacity-100 border border-gray-800 border-solid">
      <PlayerTooltip title="Close zoom">
        <IconButton className="text-white" size="small" onClick={cancel}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </PlayerTooltip>
      <Box mx={1}>
        <strong>{zoomLevel}x</strong> zoom
      </Box>
      <div>
        <ZoomButton size="small" onClick={zoomOut} disabled={zoomLevel <= 1}>
          <ZoomOutIcon />
        </ZoomButton>
        <ZoomButton size="small" onClick={zoomIn} disabled={zoomLevel >= 16}>
          <ZoomInIcon />
        </ZoomButton>
      </div>
    </div>
  );
}

const ZoomButton = withStyles(IconButton, (theme, _props, classes) => ({
  root: {
    marginRight: 3,
    borderRadius: 4,
    color: "#fff",
    transition: "backgroundColor 200ms ease-in-out",
    backgroundColor: "rgba(0, 0, 0, 0.65)",
    "&:hover": { backgroundColor: "rgba(0, 0, 0, 1)" },
  },
  disabled: {
    [`&.${classes.disabled}`]: {
      color: "rgba(255, 255, 255, 0.4)",
      backgroundColor: "rgba(0, 0, 0, 0.65)",
    },
  },
}));

const QualityWarning = React.memo(function QualityWarning({
  element,
  qualityWarning,
  close,
}: {
  element: HTMLElement;
  qualityWarning: string;
  close: () => void;
}) {
  return createPortal(
    <div className="absolute mx-auto left-0 right-0 top-2">
      <Snackbar
        open
        autoHideDuration={10000}
        onClose={(_, reason) => {
          if (reason !== "clickaway") close();
        }}
        message={
          <>
            This camera only supports{" "}
            <span style={{ color: "#f44337", fontWeight: "bold" }}>
              {qualityWarning} SD
            </span>{" "}
            so the zoom quality might be poor
          </>
        }
        action={
          <IconButton size="small" color="inherit" onClick={close}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
        ContentProps={{
          style: { flexWrap: "nowrap" },
          classes: { root: "bg-black bg-opacity-90" },
        }}
        style={{ position: "initial", transform: "none" }}
      />
    </div>,
    element
  );
});

function applyZoomTransform(
  element: HTMLElement,
  zoomLevel: number,
  position: Position
) {
  element.style.transform = calculateZoomTransform(
    zoomLevel,
    position.x,
    position.y
  );
}

export function calculateZoomTransform(
  zoomLevel: number,
  xPos: number,
  yPos: number
) {
  const x = (((xPos - 0.5) * (zoomLevel - 1)) / zoomLevel) * -100;
  const y = (((yPos - 0.5) * (zoomLevel - 1)) / zoomLevel) * -100;
  return `scale(${zoomLevel}) translate(${x}%, ${y}%)`;
}

/** Subtract the diff, but account for the current zoom level */
function calcPosition(base: Position, diff: Position, zoomLevel: number) {
  return {
    x: base.x - diff.x / (zoomLevel - 1),
    y: base.y - diff.y / (zoomLevel - 1),
  };
}

function clampPosition(pos: Position) {
  return {
    x: clamp(0, 1, pos.x),
    y: clamp(0, 1, pos.y),
  };
}

/** Calculate the new position when zooming in */
function calcZoomInPosition(pos: Position, zoomLevel: number) {
  const divider = zoomLevel - 1;
  return {
    x: pos.x + (0.5 - pos.x) / divider,
    y: pos.y + (0.5 - pos.y) / divider,
  };
}

/** Calculate the new position when zooming out */
function calcZoomOutPosition(pos: Position, zoomLevel: number) {
  const divider = zoomLevel * 2 - 2;
  return {
    x: pos.x - (0.5 - pos.x) / divider,
    y: pos.y - (0.5 - pos.y) / divider,
  };
}

/** Returns index of JW player quality level with highest bitrate */
function getHighestQualityLevel(
  levels: { bitrate?: number; label: string; width: number; height: number }[]
) {
  let result: typeof levels[number] | undefined;
  levels.forEach((l) => {
    if (!l.bitrate) return;
    if (result && result.bitrate! >= l.bitrate) return;
    result = l;
  });
  return result;
}
