import Check from "@mui/icons-material/Check";
import Search from "@mui/icons-material/Search";
import { Autocomplete, TextField } from "@mui/material";
import clsx from "clsx";
import { useState } from "react";

export interface OrgSelectorOption {
  label: string;
  value: number;
}
interface OrgSelectorProps {
  options: OrgSelectorOption[];
  value: OrgSelectorOption;
  onChange: (newValue: OrgSelectorOption) => void;
}
export function OrgSelector({ options, value, onChange }: OrgSelectorProps) {
  const [open, setOpen] = useState(false);
  return (
    <Autocomplete
      data-cy="org-selector"
      options={options}
      value={value}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      autoHighlight
      selectOnFocus
      disableClearable
      openOnFocus
      handleHomeEndKeys
      onChange={(event, newValue, reason) => {
        if (
          event.type === "keydown" &&
          (event as React.KeyboardEvent).key === "Backspace" &&
          reason === "removeOption"
        ) {
          return;
        }
        onChange(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            startAdornment: open && <Search className="text-primary" />,
          }}
        />
      )}
      disablePortal
      renderOption={(props, option, { selected }) => (
        <li
          {...props}
          className={clsx(props.className, "p-1", {
            "bg-primary text-white font-bold": selected,
          })}
        >
          <Check
            className={clsx({ invisible: !selected }, "text-[16px] mx-1")}
          />
          {option.label}
        </li>
      )}
      size="small"
      classes={{
        popupIndicator: "text-primary",
        root: "min-w-[240px]",
      }}
    />
  );
}
