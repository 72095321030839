import { DeleteForever } from "@mui/icons-material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import HighlightOff from "@mui/icons-material/HighlightOff";
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  AccordionSummaryProps,
  Checkbox,
  FormControlLabel,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import gql from "graphql-tag";
import { PropsWithChildren, useState } from "react";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

import { ResponsiveBasicDrawer } from "@/components/Drawer/BasicDrawer";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { DefaultDialog, useDialog } from "@/components/shared/Dialog";
import { QueryParamLink } from "@/components/shared/QueryParamLink";

import {
  useAllPermissionsQuery,
  useCreateRoleMutation,
  useDeleteRoleMutation,
  useRoleDetailsQuery,
  useUpdateRoleMutation,
  useUsersForCaseQuery,
} from "@/generated-models";
import { usePermissions } from "@/hooks/usePermissions";

import { ManageRoleMembersButton } from "./ManageRoleMembersButton";
import { CustomPrompt } from "./NavigationPrompt";
import { PermissionsDisplay } from "./Permissions";
import { SavedFeedback, SavedFeedbackStatus } from "./SavedFeedback";
import { TruncateList } from "./TruncateList";
import { UserManagementField } from "./UserManagementField";
import { UserManagementFormButtons } from "./UserManagementFormButtons";
import { useDetailsRouteInfo } from "./useDetailsRouteInfo";

export function RoleDetails() {
  const { id, formMode } = useDetailsRouteInfo();
  if (!id && formMode !== "create") {
    throw new Error("No role ID provided");
  }

  const { data: roleData, error: roleError } = useRoleDetailsQuery({
    variables: { id: Number(id) },
    skip: formMode === "create",
  });
  const {
    data: allPermissionsData,
    error: allPermissionsError,
  } = useAllPermissionsQuery({
    skip: formMode !== "create",
  });

  const [dialogState, setDialogState] = useState<"addMember" | null>(null);

  const hasPermission = usePermissions();
  const hasUserManagePermission = hasPermission("users_manage");

  const editable = Boolean(
    ((roleData?.role && !roleData.role.builtIn) || formMode === "create") &&
      hasUserManagePermission
  );

  let content = (
    <Loading>Loading {formMode === "create" ? "Permissions" : "Role"}</Loading>
  );

  if (roleError) {
    content = (
      <ErrorMessage
        narrow
        title="Unable to load role"
        description={roleError.message}
      />
    );
  } else if (allPermissionsError) {
    content = (
      <ErrorMessage
        narrow
        title="Unable to load permissions"
        description={allPermissionsError.message}
      />
    );
  } else if (
    // create mode
    (formMode === "create" && allPermissionsData) ||
    // edit mode
    roleData
  ) {
    content = (
      <RoleForm
        key={id}
        builtInRole={roleData?.role.builtIn ?? false}
        defaultValues={
          formMode === "create"
            ? {
                name: "",
                allLocations: false,
                permissionIds: [],
                memberIds: [],
              }
            : {
                name: roleData!.role.name,
                allLocations: roleData!.role.allLocations,
                permissionIds: roleData!.role.permissions
                  .filter((p) => p.enabled)
                  .map((p) => p.id),
                memberIds: roleData!.role.members.map((m) => m.orgUserId),
              }
        }
      >
        <div
          // key={data.role.id}
          className="bg-white border border-solid border-blue-medium rounded-lg px-[9px] py-[16px]"
        >
          <UserManagementField
            label="Role name"
            value={<Typography variant="h1">{roleData?.role.name}</Typography>}
            editValue={
              editable && (
                <div className="flex">
                  <Controller
                    name="name"
                    rules={{
                      required: {
                        value: true,
                        message: "Please provide a name",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        error={!!error}
                        autoComplete="off"
                        autoFocus
                        className="grow mb-4"
                        helperText={error?.message}
                        {...field}
                      />
                    )}
                  />
                  {roleData?.role.members.length === 0 && <DeleteRoleButton />}
                </div>
              )
            }
          />
          {roleData?.role && !editable && (
            <Typography
              variant="caption"
              className="mt-2 mb-4 block opacity-50"
            >
              This is a built-in role and cannot be edited.
            </Typography>
          )}

          <UserManagementField
            label="Groups & Locations"
            value={
              <FormControlLabel
                control={
                  <Checkbox
                    checked={roleData?.role.allLocations}
                    disabled
                    size="small"
                  />
                }
                label="All locations"
              />
            }
            editValue={
              editable && (
                <div className="flex">
                  <Controller
                    name="allLocations"
                    render={({ field }) => (
                      <FormControlLabel
                        control={<Checkbox {...field} checked={field.value} />}
                        label="All locations"
                      />
                    )}
                  />
                </div>
              )
            }
          />

          <Accordion {...accordionBaseProps}>
            <AccordionSummary {...accordionSummaryBaseProps}>
              <Typography className="font-bold">
                Members <MemberCount />
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="flex flex-col gap-[14px]">
              <MemberList />

              {hasUserManagePermission && (
                <ManageRoleMembersButton
                  open={() => setDialogState("addMember")}
                  close={() => setDialogState(null)}
                  opened={dialogState === "addMember"}
                />
              )}
            </AccordionDetails>
          </Accordion>

          <Accordion {...accordionBaseProps}>
            <AccordionSummary {...accordionSummaryBaseProps}>
              <Typography className="font-bold">Permissions</Typography>
            </AccordionSummary>
            <AccordionDetails className="p-0">
              <PermissionsDisplay
                key={id}
                permissions={
                  formMode === "create"
                    ? allPermissionsData!.permissions
                    : roleData!.role.permissions
                }
                editable={editable}
              />
            </AccordionDetails>
          </Accordion>
        </div>
      </RoleForm>
    );
  }

  return (
    <ResponsiveBasicDrawer
      title="Role Details"
      closeButton={
        <IconButton className="-mr-2" component={QueryParamLink} to="./..">
          <HighlightOff />
        </IconButton>
      }
    >
      {content}
    </ResponsiveBasicDrawer>
  );
}

const accordionBaseProps: Omit<AccordionProps, "children"> = {
  disableGutters: true,
  elevation: 0,
  sx: {
    "&:before": {
      display: "none",
    },
  },
};

const accordionSummaryBaseProps: Omit<AccordionSummaryProps, "children"> = {
  expandIcon: <ExpandMore />,
  classes: {
    root:
      "my-2 px-3 rounded-lg border border-solid border-blue-medium bg-blue-light",
    content: "my-0",
  },
};

function useFormMembers() {
  const memberIds = useWatch<RoleFormFields>({
    name: "memberIds" as const,
  }) as number[];
  const { data } = useUsersForCaseQuery();
  if (!data?.users || !memberIds) {
    return null;
  }
  return data.users
    .filter((u) => memberIds.includes(u.orgUserId))
    .sort((a, b) => a.name.localeCompare(b.name));
}

function MemberCount() {
  const members = useFormMembers();
  if (!members) {
    return null;
  }
  return <>({members.length})</>;
}

function MemberList() {
  const members = useFormMembers();
  if (!members) {
    return null;
  }
  return (
    <TruncateList
      classes={{
        root: "flex-col",
      }}
    >
      {members.map((member) => (
        <Typography
          component={Link}
          key={member.orgUserId}
          to={`./../../users/${member.orgUserId}`}
          variant="body2"
          className="text-primary leading-[normal]"
        >
          {member.name}
        </Typography>
      ))}
    </TruncateList>
  );
}

function DeleteRoleButton() {
  const { id } = useDetailsRouteInfo();
  const { pushSnackbar } = useFeedback();
  const [deleteRole] = useDeleteRoleMutation({
    variables: { id: Number(id) },
    optimisticResponse: {
      __typename: "Mutation",
      deleteRole: true,
    },
    update: (cache, { data }) => {
      if (data?.deleteRole) {
        cache.evict({
          id: `RoleV2:${id}`,
        });
      }
    },
  });
  const { open, ...dialogProps } = useDialog();
  const navigate = useNavigate();
  return (
    <>
      <IconButton
        color="primary"
        size="small"
        className="shrink-0"
        onClick={async () => {
          const confirmed = await open();
          if (!confirmed) return;
          const { errors } = await deleteRole();
          if (errors?.length) {
            pushSnackbar(
              "Something went wrong. Please try again",
              FeedbackType.Error
            );
          } else {
            pushSnackbar("Role deleted successfully", FeedbackType.Success);
            navigate("./..", { replace: true });
          }
        }}
      >
        <DeleteForever />
      </IconButton>
      <DefaultDialog
        title="Delete role"
        content="Are you sure you want to delete this role? This action is irreversible."
        confirmColor="primary"
        {...dialogProps}
      />
    </>
  );
}

export interface RoleFormFields {
  name: string;
  allLocations: boolean;
  permissionIds: string[];
  memberIds: number[];
}
function RoleForm({
  children,
  defaultValues,
  builtInRole,
}: PropsWithChildren<{
  defaultValues: RoleFormFields;
  builtInRole: boolean;
}>) {
  const [updateRole] = useUpdateRoleMutation();
  const [createRole] = useCreateRoleMutation();
  const { id, formMode } = useDetailsRouteInfo();
  const methods = useForm({
    defaultValues,
    mode: "onChange",
  });
  const {
    formState: { isDirty, isSubmitting },
  } = methods;
  const navigate = useNavigate();
  const [
    savedFeedbackStatus,
    setSavedFeedbackStatus,
  ] = useState<SavedFeedbackStatus | null>(null);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(async (values) => {
          try {
            if (formMode === "create") {
              const { data, errors } = await createRole({
                variables: {
                  input: {
                    name: values.name,
                    allLocations: values.allLocations,
                    description: "", // not implemented yet
                    permissions: values.permissionIds,
                    members: values.memberIds.map(Number),
                  },
                },
                refetchQueries: ["roles"],
              });
              if (errors?.length) {
                throw errors[0];
              } else if (data) {
                navigate(`./../${data.createRole.id}`);
              }
            } else {
              await updateRole({
                variables: {
                  id: Number(id),
                  update: {
                    // Members are the only allowed field to update
                    // on built-in roles
                    members: values.memberIds,
                    ...(!builtInRole && {
                      name: values.name,
                      allLocations: values.allLocations,
                      permissions: values.permissionIds,
                    }),
                  },
                },
              });
              methods.reset(values);
              setSavedFeedbackStatus({ success: true });
            }
          } catch (error: any) {
            setSavedFeedbackStatus({ success: false, error });
          }
        })}
      >
        {children}
        {isDirty && (
          <>
            <UserManagementFormButtons
              confirmText={formMode === "create" ? "Create Role" : "Save"}
            />
            <CustomPrompt
              when={isDirty && !isSubmitting}
              message="You'll lose your changes, you sure?"
            />
          </>
        )}
        {!isDirty && (
          <SavedFeedback
            confirmationText={
              formMode === "create" ? "Role Created." : "Changes Saved."
            }
            status={savedFeedbackStatus}
            onClose={() => setSavedFeedbackStatus(null)}
          />
        )}
        <div className="h-[50px]" />
      </form>
    </FormProvider>
  );
}

const roleDetailsFields = gql`
  fragment RoleDetailsFields on RoleV2 {
    id
    name
    builtIn
    allLocations

    permissions {
      id
      name
      description
      enabled
    }

    members {
      id
      orgUserId
      name
    }
  }
`;

gql`
  query allPermissions {
    permissions {
      id
      name
      description
      enabled
    }
  }
`;

gql`
  query roleDetails($id: Int!) {
    role(id: $id) {
      ...RoleDetailsFields
    }
    ${roleDetailsFields}
  }
`;

gql`
  mutation updateRole($id: Int!, $update: UpdateRoleInput!) {
    updateRole(id: $id, update: $update) {
      ...RoleDetailsFields
    }
    ${roleDetailsFields}
  }
`;

gql`
  mutation createRole($input: CreateRoleInput!) {
    createRole(input: $input) {
      ...RoleDetailsFields
    }
    ${roleDetailsFields}
  }
`;

gql`
  mutation deleteRole($id: Int!) {
    deleteRole(id: $id)
  }
`;
