import { SVGProps } from "react";

export function GeniusDiscoveredLogo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 32 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25.6784 14.6365C28.6341 13.615 30.2062 10.3853 29.1899 7.42281C28.1736 4.4603 24.9537 2.88679 21.9981 3.90828C19.0425 4.92976 17.4704 8.15943 18.4867 11.1219C19.503 14.0845 22.7228 15.658 25.6784 14.6365Z"
        fill="#08C500"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.2946 7.75717L22.5436 12.2581L20.4121 9.86012L20.9415 9.38958L22.5873 11.2411L26.8075 7.24301L27.2946 7.75717Z"
        fill="white"
      />
      <path
        d="M5.5391 11.1462C7.24615 10.5736 8.14862 8.74856 7.55482 7.06976C6.96101 5.39096 5.0958 4.49416 3.38875 5.0667C1.68169 5.63923 0.779225 7.4643 1.37303 9.1431C1.96683 10.8219 3.83205 11.7187 5.5391 11.1462Z"
        fill="#353D48"
      />
      <path
        d="M11.6747 16.8137C13.7834 16.1065 14.8982 13.852 14.1647 11.7782C13.4312 9.70437 11.1271 8.59655 9.01836 9.3038C6.90965 10.011 5.79483 12.2655 6.52836 14.3394C7.26188 16.4132 9.56597 17.521 11.6747 16.8137Z"
        fill="#007CE4"
      />
      <path
        d="M13.5202 8.10902C14.3235 7.83959 14.7482 6.98073 14.4688 6.19071C14.1893 5.40069 13.3116 4.97866 12.5082 5.24809C11.7049 5.51752 11.2802 6.37637 11.5597 7.1664C11.8391 7.95642 12.7169 8.37844 13.5202 8.10902Z"
        fill="#353D48"
      />
      <path
        d="M10.0907 6.07518C11.4965 5.60368 12.2398 4.10069 11.7507 2.71814C11.2617 1.3356 9.72566 0.597061 8.31986 1.06856C6.91405 1.54006 6.17084 3.04306 6.65985 4.4256C7.14887 5.80814 8.68493 6.54668 10.0907 6.07518Z"
        fill="#353D48"
      />
    </svg>
  );
}
