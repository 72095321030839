import { CircularProgress } from "@mui/material";
import clsx from "clsx";
import gql from "graphql-tag";
import { max, min } from "lodash/fp";
import { useMemo } from "react";
import { BooleanParam, useQueryParam } from "use-query-params";

import { useHeatmapQuery } from "@/generated-models";

import { useCameraMotionZone, useSearchSubjects } from "./searchHooks";

export default function HeatmapOverlay({
  camId,
  startTime,
  endTime,
}: {
  camId: number;
  startTime: string;
  endTime: string;
}) {
  const [showHeatmaps] = useQueryParam("heatmap", BooleanParam);
  const objectTypes = useSearchSubjects();
  const { activeShape } = useCameraMotionZone(camId);
  const gridSize = 100;
  const { data, loading, error } = useHeatmapQuery({
    variables: {
      camId,
      input: {
        startTime,
        endTime,
        gridSize,
        objectTypes: objectTypes!,
        searchArea: activeShape,
      },
    },
    skip: !objectTypes || !showHeatmaps,
  });
  const maxHeat = useMemo(() => {
    return max(data?.camera.heatmap.map(({ heat }) => heat)) || 0.000001; // epsilon
  }, [data]);
  const minHeat = useMemo(() => {
    return min(data?.camera.heatmap.map(({ heat }) => heat)) || 0.000001; // epsilon
  }, [data]);

  return (
    <div
      className={clsx(
        "transition-all absolute h-full w-full pointer-events-none blur-md",
        !!data ? "opacity-70" : "opacity-0"
      )}
      style={{ background: !!data ? heatMapColorforValue(0) : undefined }}
    >
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <>{error.toString()}</>
      ) : data ? (
        data.camera.heatmap.map(({ x, y, heat }) => (
          <div
            key={`${x}-${y}`}
            className={clsx("absolute")}
            style={{
              width: `${((1 / gridSize) * 100).toFixed(4)}%`,
              height: `${((1 / gridSize) * 100).toFixed(4)}%`,
              left: `${((x / gridSize) * 100).toFixed(4)}%`,
              top: `${((y / gridSize) * 100).toFixed(4)}%`,
              background: heatMapColorforValue(
                (heat - minHeat) / (maxHeat - minHeat)
              ),
            }}
          />
        ))
      ) : (
        <></>
      )}{" "}
    </div>
  );
}

gql`
  query heatmap($camId: Int!, $input: HeatmapOptions!) {
    camera(id: $camId) {
      id
      heatmap(input: $input) {
        x
        y
        heat
      }
    }
  }
`;

function heatMapColorforValue(value: number) {
  var h = (1.0 - value) * 240;
  return "hsl(" + h + ", 100%, 50%)";
}
