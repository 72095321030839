import { Button, Typography } from "@mui/material";

const API_DOCS_LINK = "https://developers.spot.ai/";

export function DeveloperApiDocumentation() {
  return (
    <div className="flex flex-col gap-4">
      <Typography className="text-2xl leading-[28px] font-bold">
        Welcome to Spot AI's API
      </Typography>
      <Typography className="text-sm leading-4">
        The Spot API allows developers to integrate with their Spot system. With
        Spot's RESTful API, developers can easily access and export their video
        intelligence data.
      </Typography>
      <div>
        <Button
          className="sm:w-[unset] w-full"
          variant="outlined"
          color="primary"
          component="a"
          href={API_DOCS_LINK}
          target="_blank"
          rel="noreferrer noopener"
        >
          View documentation & usage examples
        </Button>
      </div>
    </div>
  );
}
