import { useEffect, useState } from "react";

export function useVideoWallRotation(
  count: number,
  pageDuration: number,
  isWebRtc: boolean
) {
  const preloadTime = isWebRtc ? 8 : 3; // sec
  const [{ page, preloadingPage }, setPage] = useState({
    page: 0,
    preloadingPage: null as number | null,
  });

  useEffect(() => {
    const timer = setInterval(() => {
      setPage((current) => ({
        page: (current.page + 1) % count,
        preloadingPage: null,
      }));
    }, pageDuration * 1000);

    return () => clearInterval(timer);
  }, [setPage, count, pageDuration]);

  // Set nextPage after <preloadTime> seconds after switching page
  useEffect(() => {
    if (count < 2 || pageDuration < preloadTime) return;

    let nextPageTimer = setTimeout(() => {
      setPage((current) => ({
        ...current,
        preloadingPage: (current.page + 1) % count,
      }));
    }, (pageDuration - preloadTime) * 1000);

    return () => nextPageTimer && clearTimeout(nextPageTimer);
  }, [page, count, pageDuration, preloadTime]);

  return {
    page,
    setPage: (page: number) => setPage((current) => ({ ...current, page })),
    preloadingPage,
  };
}
