import { AddCircle, HighlightOff } from "@mui/icons-material";
import { IconButton, OutlinedInput } from "@mui/material";

interface CopilotLibraryProps {
  value: string;
  onChange: (v: string) => void;
  onEnter?: () => void;
}

export function CopilotLibraryInput({
  value,
  onChange,
  onEnter,
}: CopilotLibraryProps) {
  return (
    <OutlinedInput
      size="small"
      classes={{ root: "pl-2 w-full", input: "pl-[5px]" }}
      placeholder="Enter Object"
      className="bg-[#2D2D2D] text-white font-bold"
      startAdornment={<AddCircle className="text-primary" />}
      onKeyDown={async (e) => {
        if (e.key === "Enter") {
          await onEnter?.();
        }
      }}
      sx={{
        input: {
          caretColor: "#007ce4",
          "&::placeholder": {
            fontWeight: 400,
          },
        },
      }}
      endAdornment={
        <IconButton
          className="text-white disabled:opacity-20"
          edge="end"
          disabled={!value}
          onClick={() => {
            onChange("");
          }}
        >
          <HighlightOff />
        </IconButton>
      }
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
    />
  );
}
