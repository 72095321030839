import { IconButton } from "@mui/material";
import clsx from "clsx";
import React from "react";

import { useFullScreenHandle } from "@/components/FullScreen";
import {
  CloseFullscreenIcon,
  FullscreenIcon,
} from "@/components/Player/PlayerIcons";

import { usePlayerFullscreen } from "./PlayerControls";
import { PlayerTooltip } from "./PlayerTooltip";

export function FullscreenButton({
  playerId,
  className,
}: {
  playerId?: string;
  className?: string;
}) {
  const { active } = useFullScreenHandle();
  const { enter, exit } = usePlayerFullscreen(playerId);

  return (
    <PlayerTooltip title={active ? "Exit Fullscreen" : "Fullscreen"}>
      <IconButton
        className={clsx("text-white", className)}
        onClick={() => {
          if (active) {
            exit();
          } else {
            enter();
          }
        }}
        size="large"
      >
        {active ? <CloseFullscreenIcon color="primary" /> : <FullscreenIcon />}
      </IconButton>
    </PlayerTooltip>
  );
}
