import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import clsx from "clsx";
import { capitalize } from "lodash/fp";
import { ArrayParam, useQueryParam } from "use-query-params";

import { FilterFieldProps } from "../constant";

export function FilterDropdownField<T>({
  placeholder,
  filterName,
  label,
  selectedLabel,
  disabled,
  classes = { root: "" },
  options,
  className,
  style,
}: FilterFieldProps<T>) {
  const [filterVal, setFilterVal] = useQueryParam(filterName, ArrayParam);
  const filteredParsed = filterVal ?? [];
  const filterSelected = filteredParsed.length > 0;

  return (
    <FormControl
      size="small"
      style={style ?? { minWidth: 220 }}
      fullWidth
      className={clsx("w-full flex-1", className)}
    >
      {!filterSelected && (
        <InputLabel variant="outlined" shrink={false}>
          {placeholder}
        </InputLabel>
      )}

      <Select
        data-cy={`filter-dd-${filterName}`}
        multiple
        variant="outlined"
        value={filteredParsed}
        disabled={disabled}
        input={
          <OutlinedInput
            notched={false}
            classes={{
              root: classes.root,
              input: clsx(filterSelected && "font-bold"),
              notchedOutline: clsx(
                classes.notchedOutline,
                filterSelected && "border-[#AAD8FF]"
              ),
            }}
            className="bg-white"
            label={filterName}
          />
        }
        onChange={(e) => {
          setFilterVal(e.target.value as string[]);
        }}
        className="rounded-lg"
        renderValue={(selected) => {
          return selected.length > 0
            ? `${selectedLabel ?? capitalize(label)} Selected`
            : [];
        }}
      >
        {options.map((metric) => {
          if (metric.category) {
            return (
              <ListSubheader
                key={`category-${metric.label}`}
                className="bg-[#F2F2F2] leading-[32px] border-y border-solid border-[#E0E0E0] text-[#353D48]"
              >
                {metric.label}
              </ListSubheader>
            );
          }
          return (
            <MenuItem
              value={`${metric.value}`}
              key={`${metric.label}-${metric.secondaryLabel}-${metric.value}`}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={filteredParsed.includes(`${metric.value}`)}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText
                data-cy={`filter-dd-item-${filterName}`}
                primary={metric.label}
                secondary={metric.secondaryLabel}
              />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
