import ExpandIcon from "@mui/icons-material/ExpandMore";
import { Button, Collapse, Divider, Paper } from "@mui/material";
import { format } from "date-fns/fp";
import React, { useEffect, useState } from "react";

import {
  DateTimePicker,
  DateTimePickerProps,
} from "@/components/DateTimePicker/DateTimePicker";

interface DateTimeRangePopperProps {
  startValue: Date;
  endValue: Date;
  onChange: (start: Date, end: Date) => void;
  reset: () => void;
  close: () => void;
  minDate: Date;
  maxDate: Date;
}

export function DateTimeRangePopper({
  startValue,
  endValue,
  onChange,
  minDate,
  maxDate,
  close,
  reset,
}: DateTimeRangePopperProps) {
  const [startDate, setStartDate] = useState(startValue);
  const [endDate, setEndDate] = useState(endValue);
  const [invalid, setInvalid] = useState(false);
  useEffect(() => {
    if (invalid) setInvalid(false);
    // eslint-disable-next-line
  }, [setInvalid, startDate, endDate]);

  const touched =
    startValue.getTime() !== startDate.getTime() ||
    endValue.getTime() !== endDate.getTime();

  return (
    <Paper elevation={4}>
      <div className="flex px-8 py-5">
        <DateTimePicker
          value={startDate}
          onChange={(value) => {
            if (value) setStartDate(value);
          }}
          minDate={minDate}
          maxDate={maxDate}
          invalid={invalid}
        />
        <div className="font-bold mx-8 my-auto">TO</div>
        <DateTimePicker
          value={endDate}
          onChange={(value) => {
            if (value) setEndDate(value);
          }}
          minDate={minDate}
          maxDate={maxDate}
          invalid={invalid}
        />
      </div>
      <Divider />
      <div className="flex items-center px-5 py-4">
        <Button
          color="primary"
          onClick={() => {
            close();
            reset();
          }}
          className="tracking-button font-normal"
        >
          RESET
        </Button>
        {invalid && (
          <div className="font-bold text-red-600 ml-auto">
            End time needs to be after start time
          </div>
        )}
        <Button
          color="primary"
          onClick={close}
          className="tracking-button font-normal ml-auto mr-2"
        >
          CANCEL
        </Button>
        <Button
          color="primary"
          onClick={() => {
            // Validate
            if (endDate <= startDate) {
              setInvalid(true);
              return;
            }
            onChange(startDate, endDate);
          }}
          disabled={!touched || invalid}
          className="tracking-button font-bold"
        >
          APPLY
        </Button>
      </div>
    </Paper>
  );
}

export enum DatePickerType {
  Start,
  End,
}

export function MobileDateTimeRangePopper({
  startValue,
  endValue,
  onChange,
  minDate,
  maxDate,
  close,
  reset,
}: DateTimeRangePopperProps) {
  const [startDate, setStartDate] = useState(startValue);
  const [endDate, setEndDate] = useState(endValue);
  const [openedPicker, openPicker] = useState<DatePickerType | null>(null);
  const [invalid, setInvalid] = useState(false);
  useEffect(() => {
    if (invalid) setInvalid(false);
    // eslint-disable-next-line
  }, [setInvalid, startDate, endDate]);

  const touched =
    startValue.getTime() !== startDate.getTime() ||
    endValue.getTime() !== endDate.getTime();

  return (
    <div className="bg-white rounded-t">
      <MobileDateTimePicker
        label="Start Time"
        value={startDate}
        onChange={(value: Date) => setStartDate(value)}
        minDate={minDate}
        maxDate={maxDate}
        invalid={invalid}
        opened={openedPicker === DatePickerType.Start}
        toggle={() =>
          openPicker(
            openedPicker === DatePickerType.Start ? null : DatePickerType.Start
          )
        }
      />
      <Divider />
      <MobileDateTimePicker
        label="End Time"
        value={endDate}
        onChange={(value: Date) => setEndDate(value)}
        minDate={minDate}
        maxDate={maxDate}
        invalid={invalid}
        opened={openedPicker === DatePickerType.End}
        toggle={() =>
          openPicker(
            openedPicker === DatePickerType.End ? null : DatePickerType.End
          )
        }
      />
      <Divider />
      <div className="flex items-center px-3 py-2">
        <Button
          color="primary"
          onClick={() => {
            close();
            reset();
          }}
          className="tracking-button font-normal"
        >
          RESET
        </Button>
        {invalid && (
          <div className="font-bold text-red-600 ml-auto">
            End time needs to be after start time
          </div>
        )}
        <Button
          color="primary"
          onClick={close}
          className="tracking-button font-normal ml-auto mr-2"
        >
          CANCEL
        </Button>
        <Button
          color="primary"
          onClick={() => {
            // Validate
            if (endDate <= startDate) {
              setInvalid(true);
              return;
            }
            onChange(startDate, endDate);
          }}
          disabled={!touched || invalid}
          className="tracking-button font-bold"
        >
          APPLY
        </Button>
      </div>
    </div>
  );
}

interface MobileDateTimePickerProps extends DateTimePickerProps {
  label: string;
  opened: boolean;
  toggle: () => void;
}

export function MobileDateTimePicker({
  label,
  minDate,
  maxDate,
  value,
  onChange,
  invalid,
  opened,
  toggle,
}: MobileDateTimePickerProps) {
  const formatDate = format("E MMM d, y");
  const formatTime = format("h:mm");
  const formatAmPm = format("a");
  return (
    <>
      <div className="px-4 pt-3 pb-2 cursor-pointer" onClick={toggle}>
        <div className="text-xs opacity-80">{label}</div>
        <div className="flex text-lg font-bold text-primary">
          <div>{formatDate(value)}</div>
          <div className="ml-auto">
            {formatTime(value)}
            <span className="text-2xs">{formatAmPm(value)}</span>
          </div>
          <ExpandIcon
            className={`text-text ml-4 ${opened ? "transform rotate-180" : ""}`}
          />
        </div>
      </div>
      <Collapse in={opened}>
        <div className="pb-3">
          <DateTimePicker
            value={value}
            onChange={onChange}
            minDate={minDate}
            maxDate={maxDate}
            invalid={invalid}
          />
        </div>
      </Collapse>
    </>
  );
}
