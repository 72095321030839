import gql from "graphql-tag";

import { useCameraLicenseByIdQuery } from "@/generated-models";

export function hasAdvancedAiLicense(
  license?: {
    configuration: { features: { id: string }[] };
  } | null
) {
  return license?.configuration.features.some((f) => f.id === "ai") ?? false;
}

export function useHasAdvancedAi(cameraId: number) {
  const { data } = useCameraLicenseByIdQuery({ variables: { cameraId } });
  const hasAdvancedAi = hasAdvancedAiLicense(data?.camera?.appliance?.license);
  return hasAdvancedAi;
}

gql`
  query cameraLicenseById($cameraId: Int!) {
    camera(id: $cameraId) {
      id
      appliance {
        id
        licensesSupported
        license {
          configuration {
            features {
              id
            }
          }
        }
      }
    }
  }
`;
