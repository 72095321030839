import {
  Switch,
  SwitchProps,
  Typography,
  Box,
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
  FormControlLabel,
} from "@mui/material";
import { memo } from "react";
import { makeStyles } from "tss-react/mui";

import { useLocalStorage } from "@/util/useLocalStorage";

type SpotSwitchProps = Omit<SwitchProps, "color"> & {
  color?: string;
  label: string;
};

const useStyles = makeStyles()((theme) => ({
  switch: {
    width: 26,
    height: 16,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 1,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(10px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          // Spot green
          backgroundColor: "#18D710",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#18D710",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: "#BFBFBF",
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.7,
      },
    },
    "& .MuiSwitch-thumb": {
      margin: "auto",
      boxSizing: "border-box",
      width: 14,
      height: 14,
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      backgroundColor: "#D9D9D9",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  },
}));

export const IOSSwitch = memo(function (props: SpotSwitchProps) {
  const { checked, color, label, ...switchProps } = props;
  const theme = createTheme({
    palette: {
      primary: {
        main: color || "#18D710",
      },
    },
  });
  const { classes } = useStyles();

  return (
    <FormControlLabel
      className="m-auto"
      control={
        <Box marginRight={1} display="flex" alignItems="center">
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <Switch
                focusVisibleClassName=".Mui-focusVisible"
                {...switchProps}
                checked={checked}
                className={`${classes.switch} ${switchProps.className}`}
              />
            </ThemeProvider>
          </StyledEngineProvider>
          <Typography
            variant="caption"
            style={{
              fontSize: 11,
              width: 16,
              color: checked ? "#18D710" : "#BFBFBF",
              margin: 4,
            }}
          >
            {checked ? "On" : "Off"}
          </Typography>
        </Box>
      }
      label={
        <Typography variant="h6" className="relative font-bold mx-1">
          {label}
          {color && (
            <div
              className="absolute -bottom-0.5 h-1 w-10"
              style={{ backgroundColor: color }}
            />
          )}
        </Typography>
      }
    />
  );
});

export function WiredIOSSwitchComponent({
  localStorageKey,
  initialChecked = false,
  ...props
}: {
  localStorageKey: string;
  initialChecked?: boolean;
} & SpotSwitchProps) {
  const [checked, setChecked] = useLocalStorage(
    localStorageKey,
    initialChecked
  );
  return (
    <IOSSwitch
      {...props}
      checked={checked}
      onChange={(e) => setChecked(e.target.checked)}
    />
  );
}
