export const MINIMUM_QUERY_LENGTH = 3;

export enum Color {
  Unknown,
  Black,
  Blue,
  Brown,
  Green,
  Red,
  Silver,
  White,
  Yellow,
}

export enum VehicleType {
  Unknown,
  Bigtruck,
  Bus,
  Motorcycle,
  Pickuptruck,
  Sedan,
  Suv,
  Van,
}
