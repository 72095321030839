import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import gql from "graphql-tag";
import { useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";

import { useUpdateCaseCollaboratorsMutation } from "@/generated-models";

import { CaseCollaborator } from "./CaseCollaboratorChip";
import { CaseCollaboratorSelect } from "./CaseCollaboratorSelect";
import { CASE_COLLABORATOR_FRAGMENT } from "./caseFragments";

export function ManageCaseCollaboratorsButton({
  caseId,
  initialCollaborators,
  open,
  close,
  opened,
  className,
}: {
  caseId: number;
  initialCollaborators: CaseCollaborator[];
  open: () => void;
  close: () => void;
  opened: boolean;
  className?: string;
}) {
  const [updateCaseCollaborators] = useUpdateCaseCollaboratorsMutation();
  const [collaborators, setCollaborators] = useState(initialCollaborators);

  useEffect(() => {
    setCollaborators(initialCollaborators);
  }, [initialCollaborators]);

  function onClose() {
    setCollaborators(initialCollaborators);
    close();
  }

  async function apply() {
    await updateCaseCollaborators({
      variables: {
        caseId,
        collaborators: collaborators.map((c) => ({
          orgUserId: c.orgUserId,
          caseRole: c.caseRole,
        })),
      },
      refetchQueries: ["getCase"],
    });

    onClose();
  }

  useHotkeys(
    "ctrl+enter",
    () => {
      apply();
    },
    undefined,
    [collaborators, updateCaseCollaborators, caseId]
  );

  return (
    <>
      <Button
        color="primary"
        className={clsx("text-md font-normal", className)}
        onClick={open}
      >
        + Add a collaborator
      </Button>
      <Dialog open={opened} onClose={onClose}>
        <DialogTitle className="flex justify-between items-center py-6 bg-[#F4F4F4] border-b border-solid border-[#D5D5D5]">
          <Typography variant="h3">Manage Collaborators</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText component="div" className="py-4 text-text">
            <Typography>
              Cases are better with others! Select an existing Spot user. They
              will receive an email notification inviting them to the case.
            </Typography>
            <Box m={1} />
            <Typography>
              Want to share a view only version of the case?
            </Typography>
          </DialogContentText>

          <Typography variant="h4" className="text-base">
            Select a Spot AI user or team
          </Typography>
          <CaseCollaboratorSelect
            selectedCollaborators={collaborators}
            setSelectedCollaborators={setCollaborators}
          />
        </DialogContent>
        <DialogActions className="p-6 pt-0">
          <Button
            onClick={() => {
              onClose();
            }}
            variant="contained"
          >
            Cancel
          </Button>
          <Button onClick={apply} variant="contained" color="primary">
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

gql`
  mutation updateCaseCollaborators(
    $caseId: Int!
    $collaborators: [CaseCollaboratorInput!]!
  ) {
    updateCaseCollaborators(caseId: $caseId, collaborators: $collaborators) {
      ...CaseCollaboratorBase
    }
  }
  ${CASE_COLLABORATOR_FRAGMENT}
`;
