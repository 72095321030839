import { ChipProps, TextFieldProps } from "@mui/material";

import { IntegrationSchemaType } from "./Form/Event/utils";

export const PLACEHOLDER_ID = 1;

export enum FormMode {
  Create,
  Edit,
}

// TODO rip this out dude
export enum SchemaPropertyType {
  ArrayOfObjects = "ArrayOfObjects",
  Object = "Object",
  String = "String",
}

export const chipProps: ChipProps = {
  variant: "outlined",
  color: "primary",
  classes: {
    root: "min-w-0 bg-[#F6FBFF] border-[#B6D8F5] h-[27px] cursor-default",
    label: "font-normal text-md",
    deleteIcon: "w-3 h-3 mr-2.5",
  },
} as const;

export const CONNECT_NESTED_PROPERTY_TYPES = [
  SchemaPropertyType.ArrayOfObjects,
  SchemaPropertyType.Object,
];

export const DEFAULT_CONNECT_EVENT_ROW = {
  name: "",
  type: IntegrationSchemaType.string,
  required: false,
  children: [],
};

export const DEFAULT_CONNECT_EVENT = {
  name: "",
  properties: [{ ...DEFAULT_CONNECT_EVENT_ROW }],
  duration: 300000,
  buffer: 15000,
};

export const DEFAULT_CONNECT_DEVICE = {
  name: "",
  cameraIds: [],
  tags: [],
};

export const BASE_CONNECT_INPUT_PROPS = {
  variant: "outlined",
  size: "small",
  InputProps: {
    notched: false,
    className: "rounded-lg text-sm py-1 bg-white",
  },
  InputLabelProps: {
    className: "-top-3 -left-[14px]",
    shrink: true,
  },
} as TextFieldProps;
