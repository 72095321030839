import EventIcon from "@mui/icons-material/Event";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Divider } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import { Form, Formik } from "formik";
import { range } from "lodash/fp";
import { useState } from "react";
import { BooleanParam, useQueryParam } from "use-query-params";

import {
  formatIsoTimeMinutes,
  formatTimeString,
  getShorthandDayRange,
  parseIsoTimeMinutes,
} from "@/util/date";
import { useBreakpoints } from "@/util/useBreakpoints";

import { SelfCheckboxSet } from "@/pages/Intelligence/FormInputs";

import { TimeRangeSelector } from "@/components/TimeRange/TimeRangeSelector";

import { IntegrationSourcesQuery } from "@/generated-models";

export type Uptime = {
  startTime: string;
  endTime: string;
  daysOfWeek: number[];
};

interface IntegrationsDeviceUptimeDialogProps {
  title: string;
  source: IntegrationSourcesQuery["integrationSources"][number];
  uptime?: Uptime;
  onSubmit: (uptime: {
    startTime: string;
    endTime: string;
    daysOfWeek: number[];
  }) => void;
}

const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export default function IntegrationsDeviceUptimeDialog({
  title,
  uptime,
  onSubmit,
}: IntegrationsDeviceUptimeDialogProps) {
  const [finished, setFinished] = useQueryParam("finished", BooleanParam);
  const { fitsDesktop } = useBreakpoints();

  const [open, setOpen] = useState(false);

  function onDialogClose() {
    if (fitsDesktop) {
      setFinished(false);
    }
    setOpen(false);
  }

  const initialValues = {
    timeRange: (uptime
      ? [uptime.startTime, uptime.endTime].map(parseIsoTimeMinutes)
      : [0, 1440]) as [number, number],
    daysOfWeek: uptime?.daysOfWeek
      ? uptime?.daysOfWeek.map(String)
      : range(0, 7).map(String),
  };

  return (
    <>
      <Button
        className="flex justify-between items-center w-full border border-solid border-[#A6AEB9] rounded-lg px-3 py-[7px]"
        onClick={() => {
          setOpen((v) => !v);
        }}
      >
        <div className="flex items-center justify-start gap-2">
          <EventIcon className="text-[#353D48]" />
          <Typography className="opacity-40 text-sm leading-4">
            {`${getShorthandDayRange(
              uptime?.daysOfWeek || range(0, 7).map(Number)
            )}, ${formatTimeString(
              uptime?.startTime || "00:00:00"
            )} - ${formatTimeString(uptime?.endTime || "24:00:00")}`}
          </Typography>
        </div>
        <KeyboardArrowDownIcon className="text-[#757575]" />
      </Button>
      <Dialog
        classes={{ paper: "max-w-lg rounded-xl" }}
        onClose={onDialogClose}
        hideBackdrop
        disableEnforceFocus
        open={open}
      >
        <Formik
          validateOnBlur
          enableReinitialize
          initialValues={initialValues}
          onSubmit={({ timeRange, daysOfWeek }) => {
            const startTime = formatIsoTimeMinutes(timeRange[0]);
            const endTime = formatIsoTimeMinutes(timeRange[1]);

            onSubmit({
              startTime,
              endTime,
              daysOfWeek: daysOfWeek.map((d) => Number(d)),
            });
            onDialogClose();
          }}
        >
          {({
            isSubmitting,
            isValid,
            dirty,
            setFieldValue,
            touched,
            resetForm,
            values,
            submitForm,
          }) => (
            <Form className="max-w-lg p-5 flex flex-col gap-2">
              <Typography className="text-lg leading-[21px] font-bold">
                {title}
              </Typography>
              <Typography className="text-base leading-[18.75px] mb-[6px] -mt-1">
                Which days and times should be tracked as uptime
              </Typography>
              <Divider />
              <SelfCheckboxSet
                name="daysOfWeek"
                label="Days"
                options={weekDays.map((day, i) => ({
                  value: String(i),
                  label: day,
                }))}
              />
              <TimeRangeSelector
                btnClassName={clsx({ "p-0": !fitsDesktop })}
                labelClassName={clsx({
                  "pt-2": !fitsDesktop,
                })}
                initialValue={values.timeRange}
                onChange={(value) => setFieldValue("timeRange", value)}
              />
              <Divider className="mb-2" />
              <div className="flex gap-[10px] items-center justify-end">
                <Button
                  variant="contained"
                  color={finished ? "primary" : undefined}
                  className={clsx(
                    "shadow-none text-xs leading-[22px] font-normal rounded-md",
                    {
                      "bg-[#DAEEFF] text-primary": !finished,
                    }
                  )}
                  onClick={() => {
                    resetForm();
                    onDialogClose();
                  }}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="shadow-none text-xs leading-[22px] font-normal rounded-md"
                  disabled={isSubmitting || !isValid || !dirty}
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}
