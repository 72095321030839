import { CircularProgress, Typography } from "@mui/material";
import clsx from "clsx";
import React from "react";

interface LoadingProps {
  children?: React.ReactNode;
  grow?: boolean;
  className?: string;
}

export function Loading({ children, grow, className }: LoadingProps) {
  return (
    <div className={clsx("flex-center flex-col", { grow: grow }, className)}>
      <CircularProgress size={50} />
      {children && (
        <Typography component="p" className="text-3xl text-center mt-4 mb-6">
          {children}
        </Typography>
      )}
    </div>
  );
}
