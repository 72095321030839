import {
  useUpdateCameraSettings,
  useValidateUpdateCameraSettings,
} from "@/components/Camera/hooks";

import {
  Camera,
  CameraSettingsInput,
  CameraSettingsType,
  Page_MaintainDocument,
} from "@/generated-models";

type CameraSettingsUpdate = Partial<Camera["settings"]>;

const CAMERA_SETTINGS_MAPPING: Record<string, CameraSettingsType> = {
  lprEnabled: CameraSettingsType.AiLpr,
  modelForkliftEnabled: CameraSettingsType.AiModelForklift,
  audioControlEnabled: CameraSettingsType.Audio,
  onvifBackchannelEnabled: CameraSettingsType.OnvifBackchannel,
  attributesEnabled: CameraSettingsType.AiAttributes,
  faceRecognitionEnabled: CameraSettingsType.AiFaceRecognition,
};

function getSettings(settings: CameraSettingsUpdate) {
  const result: CameraSettingsInput[] = [];

  Object.entries(settings).forEach(([key, value]) => {
    if (CAMERA_SETTINGS_MAPPING[key] && value !== null && value !== undefined) {
      result.push({
        type: CAMERA_SETTINGS_MAPPING[key],
        enabled: !!value,
      });
    }
  });

  return result;
}

export function useBulkEditCameras() {
  const {
    FeedbackDialog,
    mutation: [updateCameraSettings, { loading }],
  } = useUpdateCameraSettings({
    refetchQueries: [{ query: Page_MaintainDocument }],
  });

  return {
    BulkEditFeedbackDialog: FeedbackDialog,
    bulkEditLoading: loading,
    bulkEdit: async (
      ids: number[],
      { settings }: { settings: Partial<Camera["settings"]> }
    ) => {
      return await updateCameraSettings({
        variables: {
          ids,
          input: {
            settings: getSettings(settings),
          },
        },
      });
    },
  };
}

export function useBulkValidateEditCameras() {
  const {
    FeedbackDialog,
    mutation: [validateUpdateCameraSettings, { loading }],
  } = useValidateUpdateCameraSettings();

  return {
    BulkValidateEditFeedbackDialog: FeedbackDialog,
    bulkValidateEditLoading: loading,
    bulkValidateEdit: async (
      ids: number[],
      { settings }: { settings: Partial<Camera["settings"]> }
    ) => {
      return await validateUpdateCameraSettings({
        variables: {
          ids,
          input: {
            settings: getSettings(settings),
          },
        },
      });
    },
  };
}
