import gql from "graphql-tag";

export const DEFAULT_CAMERA_SETTINGS_FRAGMENT = gql`
  fragment DefaultCameraSettings on DefaultCameraSettings {
    fps
    resolution
    bitrate
    encoding
    rateControl
  }
`;

export const DEVICE_STREAM_METADATA_FRAGMENT = gql`
  fragment DeviceStreamMetadata on StreamMetadata {
    id
    width
    height
    codec
    isValid
    fps
  }
`;

export const DEVICE_STREAM_SETTINGS_FRAGMENT = gql`
  fragment DeviceStreamSettings on StreamSettings {
    video {
      codec
      height
      width
      fps
    }
    audio {
      codec
    }
  }
`;
