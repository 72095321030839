import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { IconButton, Typography } from "@mui/material";
import { addHours, differenceInHours, format, subHours } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { startOfHour, endOfHour } from "date-fns/fp";
import { capitalize } from "lodash";
import { useMemo } from "react";
import { Chart } from "react-charts";
import { StringParam, useQueryParam, withDefault } from "use-query-params";

import { formatIsoDate } from "@/util/date";
import { filterNullish } from "@/util/filterFalsy";

import { intelligentFiltersConfig } from "@/pages/Search/intelligence/intelligence";

import { DataGridContextualPlayer } from "@/components/VideoWall/ContextualVideoPlayer";

import {
  useCameraByIdQuery,
  useIntelligenceDashboardBucketsQuery,
} from "@/generated-models";

import { primaryAxis, secondaryAxesNonStacked } from "../ChartConfig";
import { CountDirection, EntityType } from "../constants";
import { CountDashboardLegend } from "./CountDashboard";

export const ContextualStartTimeParam = withDefault(
  StringParam,
  new Date().toISOString()
);

export function CountDashboardChartContextualView({
  id,
  cameraId,
  timezone,
  primaryTicks,
  entity,
  subtype,
  defaultColors,
  open,
  onToggle,
}: {
  id: number;
  cameraId: number;
  timezone: string;
  primaryTicks?: number;
  entity: EntityType;
  subtype: CountDirection;
  defaultColors: string[];
  open?: boolean;
  onToggle: () => void;
}) {
  const [startTime, setStartTime] = useQueryParam(
    "contextualStartTime",
    ContextualStartTimeParam
  );

  const currentDate = utcToZonedTime(new Date(startTime), timezone);
  const startOfCurrentHour = startOfHour(currentDate);
  const startOfNextHour = addHours(startOfCurrentHour, 1);
  const endOfCurrentHour = endOfHour(currentDate);

  function onStartTimeChange(negative?: boolean) {
    const newDate = negative
      ? subHours(new Date(startTime), 1)
      : addHours(new Date(startTime), 1);
    setStartTime(newDate.toISOString());
  }

  const { data } = useIntelligenceDashboardBucketsQuery({
    variables: {
      id,
      startDate: formatIsoDate(startOfCurrentHour),
      endDate: formatIsoDate(endOfCurrentHour),
      bucketSizeSeconds: 300,
      usePaths: true,
    },
  });

  const { data: cameraData } = useCameraByIdQuery({
    variables: { cameraId: Number(cameraId) },
  });

  const filteredBuckets = useMemo(() => {
    const buckets =
      data?.intelligenceDashboard?.metrics.results?.bucketCounts || [];
    return buckets.filter(
      (b) =>
        differenceInHours(
          startOfHour(utcToZonedTime(new Date(b.bucketMs), timezone)),
          startOfCurrentHour
        ) === 0
    );
  }, [
    data?.intelligenceDashboard?.metrics.results?.bucketCounts,
    startOfCurrentHour,
    timezone,
  ]);

  const chartData = useMemo(() => {
    const resultingBuckets =
      filteredBuckets.length > 0
        ? filteredBuckets
        : [
            {
              bucketMs: startOfCurrentHour.getTime(),
              leftCount: 0,
              rightCount: 0,
            },
          ];
    const mapped = resultingBuckets.map((b) => ({
      date: utcToZonedTime(new Date(b.bucketMs), timezone),
      leftCount: b.leftCount || 0,
      rightCount: b.rightCount || 0,
    }));
    return [
      subtype !== CountDirection.Out
        ? {
            label: `${capitalize(entity)} In`,
            data: mapped.map((b) => ({
              primary: b.date,
              secondary: b.leftCount,
            })),
          }
        : null,
      subtype !== CountDirection.In
        ? {
            label: `${capitalize(entity)} Out`,
            data: mapped.map((b) => ({
              primary: b.date,
              secondary: b.rightCount,
            })),
          }
        : null,
    ].filter(filterNullish);
  }, [filteredBuckets, startOfCurrentHour, subtype, entity, timezone]);

  if (!cameraData) {
    return <></>;
  }

  return (
    <DataGridContextualPlayer
      open={!!open}
      handleClose={onToggle}
      playerBaseClassName="w-full md:w-3/4 !max-w-4xl"
      playerProps={{
        camera: cameraData.camera,
        subjects: intelligentFiltersConfig[entity]?.objectIds,
        startTime: new Date(startTime).toISOString(),
        endTime: endOfHour(new Date(startTime)).toISOString(),
      }}
    >
      <div className="bg-[#1C1C1C] rounded-b-lg">
        <div className="flex justify-between items-center px-4 sm:px-[30px] py-2 sm:py-4">
          <div className="font-medium text-xs sm:text-lg sm:leading-[21px] text-white">
            Hourly {capitalize(entity)} Counting
          </div>
          <div className="flex items-center justify-between gap-2 sm:gap-4">
            <IconButton
              className="bg-[#595959] rounded text-white text-xs sm:text-lg"
              aria-label="previous"
              size="small"
              onClick={() => {
                onStartTimeChange(true);
              }}
            >
              <ChevronLeftIcon fontSize="inherit" />
            </IconButton>
            <Typography className="font-medium text-xs sm:text-lg sm:leading-[21.09px] text-white">
              {format(startOfCurrentHour, "h aaa")} -{" "}
              {format(startOfNextHour, "h aaa")}
            </Typography>
            <IconButton
              className="bg-[#595959] rounded text-white text-xs sm:text-lg"
              onClick={() => {
                onStartTimeChange();
              }}
              aria-label="next"
              size="small"
            >
              <ChevronRightIcon fontSize="inherit" />
            </IconButton>
          </div>
          <CountDashboardLegend
            className="!my-0"
            labelClassName="text-white font-bold"
            entity={entity}
            subtype={subtype}
          />
        </div>
        <div className="relative h-[220px] rounded-b-lg">
          <Chart
            options={{
              onClickDatum: (datum) => {
                if (datum?.primaryValue) {
                  setStartTime(
                    zonedTimeToUtc(datum.primaryValue, timezone).toISOString()
                  );
                }
              },
              dark: true,
              data: chartData,
              primaryAxis: primaryAxis(primaryTicks, {
                innerSeriesBandPadding: 0,
              }),
              secondaryAxes: secondaryAxesNonStacked,
              primaryCursor: { showLabel: false },
              secondaryCursor: { showLabel: false },
              defaultColors:
                subtype === CountDirection.Out
                  ? defaultColors.reverse()
                  : defaultColors,
            }}
          />
        </div>
      </div>
    </DataGridContextualPlayer>
  );
}
