import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {
  Button,
  ButtonProps,
  FormLabel,
  Skeleton,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { useField } from "formik";
import { FunctionComponent, ReactNode, SVGProps, useMemo } from "react";

import { pluralize } from "@/util/pluralize";

import { BaseDatagridProps } from "../DataGrid";
import { useDataGridSelectedRows, useDataGridSelection } from "../hooks";

interface DataGridEditFormSectionProps {
  label: string;
  children: ReactNode;
  className?: string;
}

function SelectionStateIcon({
  enabled,
  total,
}: {
  enabled: number;
  total: number;
}) {
  let Icon;

  const disabled = enabled === 0;

  if (enabled === total) Icon = CheckCircleIcon;
  else if (disabled) Icon = CancelIcon;
  else Icon = RemoveCircleIcon;

  return (
    <Icon
      className={clsx(
        "animate-scale-up-center text-base",
        enabled !== total && !disabled && "opacity-40",
        {
          "text-[#BDBDBD]": disabled,
          "text-[#18D710]": !disabled,
        }
      )}
    />
  );
}

export function DataGridEditFormSection({
  label,
  children,
  className,
}: DataGridEditFormSectionProps) {
  return (
    <div className="bg-[#FFFFFF] rounded-lg border border-solid border-[#DDEFFF] p-4">
      <FormLabel className="text-[#757575] font-light text-sm leading-4">
        {label}
      </FormLabel>
      <div className={clsx("grid grid-cols divide-y -mb-3", className)}>
        {children}
      </div>
    </div>
  );
}

interface DataGridEditFormToggleProps extends BaseDatagridProps {
  label: string;
  field: string;
  loading?: boolean;
  children?: ReactNode;
  Icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  onChange?: (value: boolean) => void;
}

function ToggleButton({
  onClick,
  disabled,
  label,
  loading,
}: {
  onClick: () => void;
  disabled: boolean;
  label: string;
  loading?: boolean;
}) {
  const btnProps = {
    className: "text-sm leading-4 font-normal disabled:text-[#BDBDBD]",
    size: "small",
    color: "primary",
  } as ButtonProps;

  if (loading) {
    return (
      <Skeleton variant="rectangular" className="w-16 h-4 rounded-lg my-1" />
    );
  }

  return (
    <Button onClick={onClick} disabled={disabled} {...btnProps}>
      {label}
    </Button>
  );
}

export function DataGridEditFormToggle({
  label,
  field,
  loading,
  rowSelectionProps,
  rows,
  children,
  Icon,
  getRowId,
  onChange,
}: DataGridEditFormToggleProps) {
  const { count, locked, setLocked } = useDataGridSelection();
  const [, { value }, { setValue }] = useField<boolean | undefined>(field);
  const enabledRows = useDataGridSelectedRows(rows, getRowId, field);

  const rowLabel = rowSelectionProps?.rowLabel;

  const displayLabel = pluralize(
    {
      1: rowLabel,
      multi: `${rowLabel}s`,
    },
    count
  );

  const enabledCount = useMemo(() => {
    if (value) {
      return count;
    }
    if (value === false) {
      return 0;
    }

    return enabledRows.length;
  }, [count, enabledRows.length, value]);

  const multiSelection = count > 1;
  const isAllEnabled = enabledCount === count;
  const isAllDisabled = enabledCount === 0;

  function onToggleChange(value: boolean) {
    if (!locked) {
      setLocked(true);
    }
    if (onChange) {
      onChange(value);
    } else {
      setValue(value);
    }
  }

  return (
    <div className="flex flex-col gap-3 py-3">
      <div className="flex gap-2 items-start justify-start">
        <div className="flex flex-col gap-1 mt-0.5">
          <div className="flex items-center justify-start gap-1">
            {Icon && <Icon className="text-primary opacity-50 w-6 h-6" />}
            <Typography className="font-bold text-base leading-[19px]">
              {label}
            </Typography>
          </div>
          <div className="flex items-center justify-start gap-1">
            <SelectionStateIcon enabled={enabledCount} total={count} />
            {multiSelection && (
              <Typography className="text-[#353D48] text-sm leading-[14px]">
                <strong>{enabledCount}</strong> of {count} {displayLabel}{" "}
                enabled
              </Typography>
            )}
            {!multiSelection && (
              <Typography className="text-[#353D48] text-sm leading-[14px]">
                {enabledCount === 0 ? "Disabled" : "Enabled"}
              </Typography>
            )}
          </div>
          {children}
          <div
            className={clsx("flex items-center justify-start gap-3", {
              "-ml-1": multiSelection,
              "-ml-2": !multiSelection,
            })}
          >
            <ToggleButton
              loading={loading}
              disabled={isAllDisabled}
              label={`Disable ${multiSelection ? "All" : ""}`}
              onClick={() => {
                onToggleChange(false);
              }}
            />
            <ToggleButton
              loading={loading}
              disabled={isAllEnabled}
              label={`Enable ${multiSelection ? "All" : ""}`}
              onClick={() => {
                onToggleChange(true);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
