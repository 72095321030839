import { Button } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import gql from "graphql-tag";
import React from "react";
import { makeStyles } from "tss-react/mui";

import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";

import {
  useGetInstallationNotesQuery,
  useUpdateLocationMutation,
} from "@/generated-models";
import { usePermissions } from "@/hooks/usePermissions";

const useStyles = makeStyles()((theme) => ({
  notesContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "10px 20px",
    backgroundColor: "#f8f8f8",
    border: "solid 1px #e4e4e4",
    borderRadius: 6,
  },
}));

export function InstallationNotes({ locationId }: { locationId: number }) {
  const { classes } = useStyles();
  const { pushSnackbar } = useFeedback();
  const hasPermission = usePermissions();
  const canEdit = hasPermission("devices_manage");
  const { data } = useGetInstallationNotesQuery({
    variables: { id: locationId },
  });
  const [updateLocation] = useUpdateLocationMutation({
    onError: () =>
      pushSnackbar("Failed to save installation notes...", FeedbackType.Error),
  });

  // Loading
  if (!data?.location) return null;

  return (
    <Formik
      initialValues={{ notes: data.location.notes }}
      onSubmit={async ({ notes }) =>
        updateLocation({ variables: { input: { id: locationId, notes } } })
      }
    >
      {({ isSubmitting, dirty }) => (
        <div>
          <Form className={classes.notesContainer}>
            <Field
              name="notes"
              placeholder="Note down camera subnets, network details, or other relevant information here."
              component={TextField}
              fullWidth
              multiline
              InputProps={{ disableUnderline: true, style: { fontSize: 14 } }}
              disabled={isSubmitting || !canEdit}
            />
            {!isSubmitting && dirty && (
              <Button
                variant="outlined"
                color="primary"
                type="submit"
                disabled={isSubmitting || !dirty}
                style={{
                  marginLeft: "auto",
                }}
              >
                Save Notes
              </Button>
            )}
          </Form>
        </div>
      )}
    </Formik>
  );
}

export const GET_INSTALLATION_NOTES = gql`
  query getInstallationNotes($id: Int!) {
    location(id: $id) {
      id
      notes
    }
  }
`;
