import { Button, Step, StepContent, StepLabel, Stepper } from "@mui/material";
import { useField } from "formik";
import { useState } from "react";

import { useBreakpoints } from "@/util/useBreakpoints";

import { refetchOnMountPolicy } from "@/apolloClient";
import { usePage_IntelligenceQuery } from "@/generated-models";

import { ComparativePreview } from "./ComparativePreview";
import { ComparativeSettingsField } from "./ComparativeSettingsField";
import { SelectComparisonsField } from "./SelectComparisonsField";
import { useComparativeSettings } from "./utils";

export function ComparativeForm() {
  const { fitsTablet } = useBreakpoints();
  const [step, setStep] = useState(0);
  const [preview, setPreview] = useState(false);

  const [, { value: name }] = useField<string[]>({
    name: "name",
  });

  const [, { value: referenceDashIds }] = useField<number[]>({
    name: "referenceDashIds",
  });

  const { data } = usePage_IntelligenceQuery({
    ...refetchOnMountPolicy,
  });

  const { update } = useComparativeSettings();

  const steps = [
    {
      label: "Name Insight & Select Comparisons",
      children: <SelectComparisonsField />,
    },
    {
      label: "Select Settings",
      children: <ComparativeSettingsField />,
    },
    {
      label: "Preview & Save",
      children: <ComparativePreview />,
    },
  ];

  const lastStep = step === steps.length - 1;

  if (!fitsTablet) {
    return (
      <div className="flex flex-col gap-1 p-4">
        <SelectComparisonsField />
        <ComparativeSettingsField />
        {preview && (
          <div className="mt-4">
            <ComparativePreview />
          </div>
        )}
        {!preview && (
          <Button
            disabled={
              !referenceDashIds || referenceDashIds?.length === 0 || !name
            }
            className="rounded-lg shadow-none font-bold mt-7"
            variant="contained"
            color="primary"
            onClick={() => {
              if (!preview) {
                setPreview(true);
              }
            }}
          >
            Preview
          </Button>
        )}
        {preview && (
          <Button
            className="rounded-lg shadow-none font-bold mt-7"
            variant="contained"
            color="primary"
            type="submit"
          >
            Save & View Insight
          </Button>
        )}
      </div>
    );
  }

  return (
    <Stepper
      activeStep={step}
      orientation="vertical"
      style={{
        boxShadow: "inset 0px 10px 9px -10px rgba(0,0,0,0.4)",
      }}
    >
      {steps.map(({ label, children }, index) => (
        <Step key={index}>
          <StepLabel
            StepIconProps={{
              classes: {
                root: "text-[28px] ml-[-2px]",
              },
            }}
            classes={{
              label: "text-base leading-[18px] ml-4",
              active: "font-bold",
            }}
          >
            {label}
          </StepLabel>
          <StepContent>
            <div
              style={{
                padding: "0px 18px 12px 18px",
              }}
            >
              {children}
            </div>
            <div className="flex items-center gap-2 mt-2 ml-6">
              <Button
                variant="contained"
                className="shadow-none text-xs leading-[22px] font-normal px-4 text-[#959595] rounded-lg"
                disabled={step === 0}
                onClick={() => setStep((step) => step - 1)}
              >
                Back
              </Button>
              {lastStep && (
                <Button
                  variant="contained"
                  color="primary"
                  className="shadow-none text-xs leading-[22px] font-normal px-4 rounded-lg"
                  type="submit"
                >
                  Save Insight
                </Button>
              )}
              {!lastStep && (
                <Button
                  variant="contained"
                  color="primary"
                  className="shadow-none text-xs leading-[22px] font-normal px-4 rounded-lg"
                  disabled={
                    step === 0 && (!name || referenceDashIds?.length <= 1)
                  }
                  onClick={async () => {
                    if (step === 0) {
                      const firstDash = data?.intelligenceDashboards.find(
                        (i) => i.id === referenceDashIds[0]
                      );

                      if (firstDash) {
                        update(
                          firstDash.daysOfWeek,
                          firstDash.startTime,
                          firstDash.endTime,
                          firstDash.thresholdSeconds,
                          firstDash.entityCount
                        );
                      }
                    }
                    setStep((step) => step + 1);
                  }}
                >
                  <>{lastStep ? "Save Insight" : "Next"}</>
                </Button>
              )}
            </div>
          </StepContent>
        </Step>
      ))}
    </Stepper>
  );
}
