import { Check } from "@mui/icons-material";
import { Autocomplete, TextField, Typography } from "@mui/material";
import clsx from "clsx";
import { useLocalStorage } from "react-use";

import { useApplianceConfigHistory, useApplianceDiff } from "./hooks";

export function MaintainApplianceConfigHistorySelect() {
  const [dark] = useLocalStorage("maintainEditDark", false);
  const options = useApplianceConfigHistory();
  const { versionId, setVersionId } = useApplianceDiff();

  const value = options.find((o) => o.value === versionId);

  return (
    <Autocomplete
      fullWidth
      size="small"
      data-cy="appliance-selector"
      className="min-w-[300px]"
      options={options}
      value={value}
      disableClearable
      limitTags={3}
      onChange={(event, newValue) => {
        setVersionId(newValue?.value, "replaceIn");
      }}
      classes={{
        paper: "border border-solid border-primary/[.20]",
      }}
      noOptionsText="No locations found"
      ChipProps={{
        variant: "outlined",
        color: "primary",
        classes: { root: "font-medium bg-[#F6FBFF]", deleteIcon: "w-5 h-5" },
      }}
      getOptionDisabled={(option) => option.version === options.length}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder="Select an Appliance"
          InputProps={{
            notched: false,
            ...params.InputProps,
            className: clsx(
              "rounded-lg font-mono text-sm",
              params.InputProps.className
            ),
          }}
          InputLabelProps={{
            className: "-top-3 -left-[14px]",
            shrink: true,
            ...params.InputLabelProps,
          }}
        />
      )}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option, { selected }) => (
        <li
          {...props}
          className={clsx(props.className, "p-1", {
            "bg-primary text-white font-bold": selected,
          })}
        >
          <Check
            className={clsx({ invisible: !selected }, "text-[16px] mx-1")}
          />
          <div
            className={clsx(
              "px-2 h-[38px] font-mono flex items-center justify-center mx-2 border",
              {
                "border-black": !dark,
                "border-[#A6C8E1] text-white": dark,
                "bg-[#101010] text-white": !dark && !!option.deliveryDate,
                "bg-[#A6C8E1] !text-black": dark && !!option.deliveryDate,
                "text-black": !option.deliveryDate,
              }
            )}
          >
            v{option.version}
          </div>
          <div className="flex flex-col">
            <Typography className="text-[10px] leading-[10px] opacity-60 font-mono">
              Delivered: {option.deliveryDate || "Undelivered"}
            </Typography>
            <Typography className="font-mono">{option.date}</Typography>
          </div>
        </li>
      )}
    />
  );
}
