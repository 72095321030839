import { ThumbUp, ThumbDown, DeleteForever } from "@mui/icons-material";
import { Typography } from "@mui/material";
import clsx from "clsx";
import { Variant, motion } from "framer-motion";
import { FunctionComponent, useState } from "react";

import {
  CONFIRMED_LABEL_STATES,
  useEditAnnotationLabel,
} from "@/components/Ai/Copilot/copilotAnnotationQueryHooks";

import { AnnotationLabelState } from "@/generated-models";

import {
  CopilotMenuButton,
  CopilotPinButton,
} from "../../Forms/CopilotFormButton";
import { useCopilotInsightQueryParam } from "../../copilotLabelButtonHooks";
import {
  CopilotLabelMode,
  useCopilotLabelContext,
} from "../../useCopilotLabelContext";

function CoachButton({
  Icon,
  selected,
  selectedVariant,
  selectedIconClassName,
  onClick,
}: {
  Icon: FunctionComponent<{ className?: string }>;
  selected: boolean;
  selectedVariant: Variant;
  selectedIconClassName: string;
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}) {
  return (
    <motion.div
      className="w-[30px] h-[30px] rounded-[65.7px] p-[6px] flex items-center justify-center group"
      animate={selected ? "selected" : "animate"}
      whileHover="hover"
      onClick={(e) => {
        e.stopPropagation();
        onClick(e);
      }}
      variants={{
        animate: {
          border: "1px solid #2F2E2E",
          background: "#2F2E2E",
        },
        selected: selectedVariant,
        hover: {
          border: "1px solid #FFFFFF",
        },
      }}
      transition={{
        ease: "easeInOut",
        duration: 0.2,
      }}
    >
      <Icon
        className={clsx(
          "opacity-60 group-hover:opacity-100 transition duration-200 ease-in-out w-4 h-4",
          selected && selectedIconClassName
        )}
      />
    </motion.div>
  );
}

function PinButton() {
  const { id, labelState } = useCopilotLabelContext();
  const pinned = labelState === AnnotationLabelState.Pinned;
  const { loading, edit } = useEditAnnotationLabel();

  return (
    <motion.div className="flex flex-col group">
      <CopilotPinButton
        pinned={pinned}
        disabled={loading}
        onClick={() => {
          edit(id, {
            labelState: pinned
              ? AnnotationLabelState.Confirmed
              : AnnotationLabelState.Pinned,
          });
        }}
      />
      <div className="text-white font-barlow text-xs leading-normal italic opacity-0 group-hover:opacity-100 transition duration-200 ease-in-out">
        Pin and save this object
      </div>
    </motion.div>
  );
}

function CorrectiveButtons() {
  const { setMode } = useCopilotLabelContext();
  const [hovered, setHovered] = useState<CopilotLabelMode | null>(null);

  function onLeave() {
    setHovered(null);
  }

  return (
    <motion.div className="flex flex-col group">
      <div className="flex items-center justify-center gap-2">
        <CopilotMenuButton
          className="h-6"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setMode(CopilotLabelMode.rename);
          }}
          onMouseOver={() => {
            setHovered(CopilotLabelMode.rename);
          }}
          onMouseLeave={onLeave}
        >
          <CoachRenameIcon />
        </CopilotMenuButton>
        <CopilotMenuButton
          className="h-6"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setMode(CopilotLabelMode.adjust);
          }}
          onMouseOver={() => {
            setHovered(CopilotLabelMode.adjust);
          }}
          onMouseLeave={onLeave}
        >
          <CoachAdjustIcon />
        </CopilotMenuButton>
        <CopilotMenuButton
          className="h-6"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setMode(CopilotLabelMode.remove);
          }}
          onMouseOver={() => {
            setHovered(CopilotLabelMode.remove);
          }}
          onMouseLeave={onLeave}
        >
          <DeleteForever className="w-4 h-4" />
        </CopilotMenuButton>
      </div>
      <div className="text-white font-barlow text-xs leading-normal italic opacity-0 group-hover:opacity-100 transition duration-200 ease-in-out h-[18px]">
        {hovered === CopilotLabelMode.rename && <span>Rename the label</span>}
        {hovered === CopilotLabelMode.adjust && (
          <span>Adjust the object boundary</span>
        )}
        {hovered === CopilotLabelMode.remove && (
          <span>Remove this label permanently</span>
        )}
      </div>
    </motion.div>
  );
}

export function CoachMenuItems() {
  const { id, labelState } = useCopilotLabelContext();
  const { edit } = useEditAnnotationLabel();
  const [insightMenu] = useCopilotInsightQueryParam();
  const [showBadDetectionState, setShowBadDetectionState] = useState(false);

  const confirmed = CONFIRMED_LABEL_STATES.includes(labelState);

  if (insightMenu) return <></>;

  return (
    <div className="flex flex-col gap-3 items-center justify-center">
      <Typography className="text-white opacity-50 font-barlow text-[11px]">
        Coach AI Copilot
      </Typography>
      <motion.div layout="position" className="flex items-center gap-[10px]">
        <CoachButton
          Icon={ThumbUp}
          selected={confirmed}
          selectedIconClassName="text-[#3BB16A]"
          selectedVariant={{
            border: "1px solid #022A12",
            background: "#022A12",
          }}
          onClick={() => {
            if (!confirmed) {
              edit(id, {
                labelState: AnnotationLabelState.Confirmed,
              });
            } else if (labelState === AnnotationLabelState.Confirmed) {
              edit(id, {
                labelState: AnnotationLabelState.Suggested,
              });
            }

            if (showBadDetectionState) {
              setShowBadDetectionState(false);
            }
          }}
        />
        <CoachButton
          Icon={ThumbDown}
          selected={showBadDetectionState}
          selectedIconClassName="text-[#EA3C31]"
          selectedVariant={{
            border: "1px solid #2D0404",
            background: "#2D0404",
          }}
          onClick={() => {
            if (!showBadDetectionState && confirmed) {
              edit(id, {
                labelState: AnnotationLabelState.Suggested,
              });
            }
            setShowBadDetectionState((s) => !s);
          }}
        />
      </motion.div>
      <motion.div layout="position" className="flex items-center gap-[10px]">
        {showBadDetectionState ? <CorrectiveButtons /> : <PinButton />}
      </motion.div>
    </div>
  );
}

function CoachRenameIcon() {
  return (
    <svg
      width="22"
      height="14"
      viewBox="0 0 22 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 1C14 0.723862 14.2239 0.5 14.5 0.5C14.7761 0.5 15 0.723854 15 1V13C15 13.2761 14.7761 13.5 14.5 13.5C14.2239 13.5 14 13.2761 14 13V1Z"
        fill="white"
      />
      <path
        d="M9.02344 5.76892C9.02344 5.74345 9.03613 5.73071 9.06152 5.73071H9.68848C10.0605 5.73071 10.2463 5.87084 10.2463 6.15113C10.2463 6.43141 10.0605 6.57156 9.68848 6.57156H9.06152C9.03613 6.57156 9.02344 6.55882 9.02344 6.53334V5.76892Z"
        fill="white"
      />
      <path
        d="M10.3 8.16917C10.3 8.46985 10.1191 8.62018 9.75732 8.62018H9.06152C9.03613 8.62018 9.02344 8.60744 9.02344 8.58195V7.77933C9.02344 7.76929 9.02539 7.76123 9.0293 7.75514C9.0354 7.74579 9.04614 7.7411 9.06152 7.7411H9.76489C10.1216 7.7411 10.3 7.8838 10.3 8.16917Z"
        fill="white"
      />
      <path
        d="M4.04932 7.97807C4.04419 8.00865 4.05444 8.02394 4.07983 8.02394H5.0354C5.04565 8.02394 5.05347 8.02225 5.05908 8.01886C5.07056 8.01205 5.07275 7.99847 5.06592 7.97807L4.57666 6.26579C4.57153 6.2505 4.56396 6.24286 4.55371 6.24286L4.54932 6.24342L4.5459 6.24458C4.53955 6.24763 4.53442 6.2547 4.53076 6.26579L4.04932 7.97807Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 2.5C0.671631 2.5 0 3.17157 0 4V10C0 10.8284 0.671631 11.5 1.5 11.5H13V2.5H1.5ZM11.0566 7.09167C11.0564 7.08392 11.0615 7.07617 11.072 7.06842C11.4592 6.84929 11.6528 6.49767 11.6528 6.01353C11.6528 5.46825 11.4873 5.08096 11.156 4.85163C10.8298 4.61721 10.3408 4.5 9.68848 4.5H7.70093C7.66528 4.5 7.63721 4.51019 7.61694 4.53058C7.59644 4.55096 7.58618 4.57899 7.58618 4.61466V9.73622C7.58618 9.75772 7.58984 9.77644 7.59741 9.79239C7.60229 9.8029 7.60889 9.81221 7.61694 9.82031C7.63721 9.8407 7.66528 9.85089 7.70093 9.85089H9.75732C10.3433 9.85089 10.8198 9.72858 11.1868 9.48396C11.5535 9.23425 11.7371 8.84695 11.7371 8.32207C11.7371 8.04178 11.6836 7.80226 11.5764 7.60352C11.4695 7.40477 11.3013 7.2417 11.072 7.11429C11.062 7.10675 11.0569 7.09921 11.0566 7.09167ZM5.70801 9.85089C5.63672 9.85089 5.59351 9.81776 5.57812 9.75151L5.40991 9.15527C5.39966 9.13489 5.38696 9.12469 5.37183 9.12469H3.75122C3.74512 9.12469 3.73926 9.12634 3.73389 9.12965C3.72607 9.13455 3.71899 9.1431 3.71289 9.15527L3.54468 9.75151C3.52954 9.81776 3.48608 9.85089 3.41479 9.85089H2.10767C2.01587 9.85089 1.98291 9.80757 2.0083 9.72093L3.62134 4.59173C3.62842 4.57011 3.63843 4.55231 3.65137 4.53833C3.65845 4.53062 3.6665 4.52408 3.67554 4.51869C3.69604 4.50623 3.72119 4.5 3.75122 4.5H5.37183C5.43799 4.5 5.4812 4.53058 5.50171 4.59173L7.1145 9.72093C7.11963 9.73112 7.12231 9.74641 7.12231 9.7668C7.12231 9.82286 7.08667 9.85089 7.01514 9.85089H5.70801Z"
        fill="white"
      />
      <path
        opacity="0.4"
        d="M16 2.5H20.5C21.3284 2.5 22 3.17157 22 4V10C22 10.8284 21.3284 11.5 20.5 11.5H16V2.5Z"
        fill="white"
      />
    </svg>
  );
}

function CoachAdjustIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="13"
      viewBox="0 0 17 13"
      fill="none"
    >
      <path
        d="M6.84847 0.688477H4.89995V1.90203L1.13622 4.85921H0V6.84945H1.18096L4.89995 10.9403V12.6885H6.84847V10.6982H5.23712L1.73818 6.84945H1.94852V4.85921H1.80593L4.89995 2.42823V2.67872H6.84847V2.40352L8.16659 3.15149V4.34701H10.1151V2.35677H8.16659V2.67437L6.84847 1.92641V0.688477Z"
        fill="white"
      />
      <path
        d="M8.16659 10.5452V8.66575L13.5935 3.12265L15.4335 5.00209L10.0066 10.5452H8.16659Z"
        fill="white"
      />
      <path
        d="M16.8565 2.84199C17.0478 3.03745 17.0478 3.3532 16.8565 3.54866L15.9585 4.46583L14.1185 2.58639L15.0164 1.66922C15.2078 1.47376 15.5169 1.47376 15.7083 1.66922L16.8565 2.84199Z"
        fill="white"
      />
    </svg>
  );
}
