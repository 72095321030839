import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  Modal,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { utcToZonedTime } from "date-fns-tz";
import { format } from "date-fns/fp";
import { Provider as JotaiProvider } from "jotai";
import { ReactNode, useMemo, useState } from "react";

import { ReactComponent as PeopleAlertIcon } from "@/icons/PeopleAlertIcon.svg";

import { isWebRTCSupported } from "@/util/isWebRTCSupported";
import { buildVodUrlParams } from "@/util/vod";

import { WallClock } from "@/components/Live/WallClock";
import { AddToCaseButton } from "@/components/Player/AddToCaseButton";
import { DownloadButton } from "@/components/Player/DownloadButton";
import { LiveIndicator } from "@/components/Player/LiveIndicator";
import { VodPlayer } from "@/components/Player/MainPlayer/MainPlayer";
import { MobileOverlay } from "@/components/Player/MobileOverlay";
import { PlayPauseButton } from "@/components/Player/PlayPauseButton";
import {
  PlayerAspect,
  PlayerControlsContainer,
  PlayerIdsProvider,
} from "@/components/Player/PlayerBase";
import { PlayerControls } from "@/components/Player/PlayerControls.1";
import {
  HdIndicator,
  LocalIndicator,
} from "@/components/Player/PlayerMetadata";
import {
  PlayerSettingsButton,
  QualitySetting,
} from "@/components/Player/PlayerSettingsButton";
import { ShareCameraButton } from "@/components/Player/ShareCameraButton";
import { ShareClipButton } from "@/components/Player/ShareClipButton";
import { TopRightTray } from "@/components/Player/TopRightTray";
import { VideoPlayer } from "@/components/Player/VideoPlayer";
import { VolumeButton } from "@/components/Player/VolumeButton";
import { PlayerControlsProvider } from "@/components/Player/playerControlsMachine";
import { QueryParamLink } from "@/components/shared/QueryParamLink";

import {
  Camera,
  CameraFeeds,
  CameraHealth,
  CameraSettings,
  Location,
} from "@/generated-models";

import { usePrefixOrgSlug } from "../../hooks/useOrgRouteBase";
import { FullScreen, IfNotFullscreen } from "../FullScreen";
import { FullscreenButton } from "../Player/FullscreenButton";
import { PlayPauseReplayButton } from "../Player/PlayPauseReplayButton";
import { usePlayerMobileMode } from "../Player/PlayerControls";
import LivekitPlayer from "../Player/WebRTCPlayer/LivekitPlayer";
import { PlayerMachineProvider } from "../Player/playerMachine";

interface IVodPlayer {
  camera: Pick<Camera, "id" | "name"> & {
    location: Pick<Location, "id" | "name" | "timezone">;
    settings: Pick<CameraSettings, "audioControlEnabled">;
    health: Pick<CameraHealth, "cameraOnline" | "applianceOnline">;
  };
  startTime: string;
  endTime: string;
  subjects?: string[] | null;
}
interface IContextualVideoPlayer {
  sources?: CameraFeeds;
  camId: number;
  maxAutoLevel?: string;
  timezone: string;
  name: string;
  location?: string;
  audioControlEnabled?: boolean;
}
interface ILiveContextualPlayer extends IContextualVideoPlayer {
  className?: string;
  children: ReactNode;
  sources: CameraFeeds;
  myWebRTC: boolean;
}
export interface IVodContextualPlayer extends IVodPlayer {
  length?: string;
  queueNum?: number;
  alertType?: string;
  alertName?: string;
  actions?: ReactNode;
}

const ContextualPlayerDetails = ({
  name,
  location,
  timezone,
  camId,
  startTime,
  endTime,
  subjects,
}: {
  camId: number;
  timezone: string;
  name: string;
  location?: string;
  startTime?: string;
  endTime?: string;
  subjects?: string[] | null;
}) => {
  const prefixOrgSlug = usePrefixOrgSlug();
  return (
    <div className="py-3 px-4 flex flex-col gap-3 text-white">
      <div className="text-center">
        <div className="display text-xl font-bold">{name}</div>
        <div className="display opacity-50">{location}</div>
      </div>
      <Divider className="bg-gray-e0/20" />
      <div className="flex flex-col gap-5">
        <div className="flex items-center">
          <WallClock timezone={timezone} />
          <div className="grow" />
          <Button
            className="flex gap-1 items-center bg-[#035499] bg-opacity-80 hover:bg-opacity-100 text-white no-underline py-2 pr-3 pl-2 rounded text-md font-normal whitespace-nowrap border border-primary/80"
            component={QueryParamLink}
            startIcon={<SearchIcon />}
            to={prefixOrgSlug("/search")}
            params={Object.fromEntries(
              buildVodUrlParams(
                camId,
                startTime ? new Date(startTime).getTime() : undefined,
                endTime ? new Date(endTime).getTime() : undefined,
                subjects
              )
            )}
            removalParams={["vod", "range", "zones", "m", "subjects", "clip"]}
          >
            Search Camera
          </Button>
        </div>
      </div>
    </div>
  );
};

export const VodContextualPlayerDetails = ({
  name,
  location,
  timezone,
  camId,
  startTime,
  endTime,
  subjects,
}: {
  camId: number;
  timezone: string;
  name: string;
  location?: string;
  startTime: string;
  endTime: string;
  subjects?: string[] | null;
}) => {
  return (
    <div className="py-3 px-4 flex flex-col gap-3 text-white">
      <div className="text-center">
        <div className="display text-xl font-bold">{name}</div>
        <div className="display opacity-50">{location}</div>
      </div>
      <Divider className="bg-gray-e0/20" />
      <div className="flex flex-col gap-5">
        <div className="flex items-center">
          <WallClock timezone={timezone} />
          <div className="grow" />
          <ContextualPlayerFooterButtons
            startTime={startTime}
            endTime={endTime}
            camId={camId}
            subjects={subjects}
          />
        </div>
      </div>
    </div>
  );
};

export const ContextualPlayerFooterButtons = ({
  startTime,
  endTime,
  camId,
  subjects,
}: {
  startTime: string;
  endTime: string;
  camId: number;
  subjects?: string[] | null;
}) => {
  const prefixOrgSlug = usePrefixOrgSlug();
  const commonButtonProps = {
    cameraId: camId,
    startTime,
    endTime,
    label: "",
  };
  return (
    <List dense className="flex flex-row p-0">
      <IconButton
        className="md:hover:bg-opacity-20 md:hover:bg-white rounded-none px-3 py-1"
        component={QueryParamLink}
        to={prefixOrgSlug("/search")}
        params={Object.fromEntries(
          buildVodUrlParams(
            camId,
            startTime ? new Date(startTime).getTime() : undefined,
            endTime ? new Date(endTime).getTime() : undefined,
            subjects
          )
        )}
        removalParams={["vod", "range", "zones", "m", "subjects", "clip"]}
      >
        <SearchIcon className="text-white mr-2" />
      </IconButton>
      <ShareClipButton {...commonButtonProps} />
      <AddToCaseButton {...commonButtonProps} />
      <DownloadButton {...commonButtonProps} />
    </List>
  );
};

export const ContextualPlayerFooterDateTime = ({
  timezone,
  startTime,
}: {
  timezone: string;
  startTime: string;
}) => {
  const [date, time] = useMemo(
    () => [
      format("MMM d, yyyy")(
        utcToZonedTime(new Date(startTime), timezone || "")
      ),
      format("p")(utcToZonedTime(new Date(startTime), timezone || "")),
    ],
    [timezone, startTime]
  );

  return (
    <Typography className="text-white text-base leading-[18.75px] md:text-xl md:leading-[23px]">
      {date} <strong>{time}</strong>
    </Typography>
  );
};

export const ContextualPlayerFooter = ({
  actions,
  timezone,
  startTime,
  endTime,
  camId,
  subjects,
}: {
  actions?: ReactNode;
  timezone: string;
  startTime: string;
  endTime: string;
  camId: number;
  subjects?: string[] | null;
}) => {
  return (
    <div className="flex items-center justify-between py-[5px] px-4 pr-0 md:px-5 rounded-b-lg">
      <ContextualPlayerFooterDateTime
        timezone={timezone}
        startTime={startTime}
      />
      {actions}
      <ContextualPlayerFooterButtons
        startTime={startTime}
        endTime={endTime}
        camId={camId}
        subjects={subjects}
      />
    </div>
  );
};

export const ContextualVideoPlayer = ({
  camId,
  myWebRTC,
  sources,
  timezone,
  name,
  location,
  audioControlEnabled,
  children,
  className,
}: ILiveContextualPlayer) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const playerMobileMode = usePlayerMobileMode();

  return (
    <>
      <div
        className={clsx(
          "overflow-hidden hover:cursor-pointer",
          (className !== null || className !== undefined) && className
        )}
        onClick={handleOpen}
      >
        {children}
      </div>

      {open && (
        <JotaiProvider>
          <Modal
            open
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <FullScreen>
              <Box className="bg-black absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-full rounded-2xl">
                <IfNotFullscreen>
                  <div className="py-3 px-[14px] flex justify-between items-center">
                    <IconButton
                      className="w-3 h-3"
                      aria-label="close"
                      onClick={handleClose}
                    >
                      <CloseIcon className="text-white" />
                    </IconButton>
                    <div className="flex justify-end gap-0.5">
                      <LocalIndicator darkIcon />
                      <HdIndicator darkIcon />
                      <LiveIndicator darkIcon />
                    </div>
                  </div>
                </IfNotFullscreen>
                <PlayerControlsProvider>
                  <PlayerControlsContainer>
                    <PlayerAspect className="overflow-hidden" maxHeight="100vh">
                      {myWebRTC &&
                      sources.webRTC &&
                      // applianceParticipant &&
                      isWebRTCSupported() ? (
                        <>
                          <LivekitPlayer
                            url={sources?.webRTC ?? ""}
                            playerId={camId.toString()}
                          />
                        </>
                      ) : (
                        <VideoPlayer
                          forceMuted
                          sources={sources}
                          showingLivestream
                          autoPlay
                          muxConfig={{
                            cameraId: camId,
                            playerName: "Contextual Player",
                          }}
                        />
                      )}
                    </PlayerAspect>
                    <IfNotFullscreen>
                      <TopRightTray>
                        <div className="text-white">
                          <ShareCameraButton
                            cameraId={camId}
                            iconButton
                            whiteIcon
                          />
                        </div>
                        {/* To be added after Snapshot on mobile is debugged
                <SnapshotButton
                  playerId={camId.toString()}
                  cameraId={camId}
                  cameraName={name}
                  timezone={timezone}
                /> */}
                      </TopRightTray>
                    </IfNotFullscreen>
                    <MobileOverlay>
                      <PlayPauseButton fontSize={60} />
                    </MobileOverlay>
                    <PlayerControls
                      className="absolute bottom-0 left-0 right-0 z-1"
                      disabled={false}
                    >
                      <div
                        style={{
                          background:
                            "linear-gradient(transparent 0%, black 140%)",
                        }}
                        className="flex-center w-full py-0.5"
                      >
                        <div className="flex grow items-center">
                          {!playerMobileMode && <PlayPauseReplayButton />}
                          <Box flexGrow={1} />
                          <VolumeButton
                            playerId={camId.toString()}
                            enabled={audioControlEnabled}
                          />
                          <PlayerSettingsButton>
                            <QualitySetting />
                          </PlayerSettingsButton>
                          <FullscreenButton playerId={camId.toString()} />
                        </div>
                      </div>
                    </PlayerControls>
                  </PlayerControlsContainer>
                </PlayerControlsProvider>

                <IfNotFullscreen>
                  <ContextualPlayerDetails
                    name={name}
                    location={location}
                    timezone={timezone}
                    camId={camId}
                  />
                </IfNotFullscreen>
              </Box>
            </FullScreen>
          </Modal>
        </JotaiProvider>
      )}
    </>
  );
};

export function useDataGridContextPlayer(
  defaultPlayerProps?: IVodContextualPlayer
) {
  const [open, setOpen] = useState(false);
  const [playerProps, setPlayerProps] = useState<IVodContextualPlayer | null>(
    defaultPlayerProps ?? null
  );
  const handleOpen = (newPlayerProps: IVodContextualPlayer) => {
    setPlayerProps(newPlayerProps);
    setOpen(true);
  };
  const handleClose = (evt?: React.SyntheticEvent) => {
    evt?.stopPropagation();
    setPlayerProps(null);
    setOpen(false);
  };

  const updatePlayer = (newPlayerProps: Partial<IVodContextualPlayer>) => {
    setPlayerProps(
      (prevProps) =>
        ({ ...prevProps, ...newPlayerProps } as IVodContextualPlayer)
    );
  };
  return { open, handleClose, handleOpen, playerProps, updatePlayer };
}

type Entity = {
  objectId: string;
};

export function ClipActions({
  items,
  item,
  onNewItem,
}: {
  items: Entity[];
  item: Entity;
  onNewItem: (newItem: Entity) => void;
}) {
  const currentIndex = items.findIndex((x) => x.objectId === item.objectId);

  return (
    <div className="flex gap-2 items-center">
      {currentIndex > 0 && (
        <Button
          variant="contained"
          className="p-1"
          onClick={() => onNewItem(items[currentIndex - 1])}
        >
          <ArrowLeft />
        </Button>
      )}
      <span>
        Clip {currentIndex + 1} of {items.length}
      </span>
      {currentIndex < items.length - 1 && (
        <Button
          variant="contained"
          className="p-1"
          onClick={() => onNewItem(items[currentIndex + 1])}
        >
          <ArrowRight />
        </Button>
      )}
    </div>
  );
}

export const Indicators = () => (
  <div className="flex justify-end gap-0.5">
    <LocalIndicator darkIcon />
    <HdIndicator darkIcon />
  </div>
);

export const LocationNameTitle = ({
  location,
  name,
  className,
}: {
  location?: string;
  name: string;
  className?: string;
}) => (
  <div className={clsx("flex flex-col items-start gap-2", className)}>
    {location && (
      <div className="text-base leading-[18.75px] sm:w-[340px] w-[200px] text-white truncate">
        {location}
      </div>
    )}
    <div className="text-xl leading-[23px] font-bold text-white">{name}</div>
  </div>
);

export function ContextualPlayerBase({
  header,
  footer,
  playerProps,
  className,
}: {
  playerProps: IVodPlayer;
  header: ReactNode;
  footer: ReactNode;
  className?: string;
}) {
  return (
    <PlayerIdsProvider cameraIds={[playerProps.camera.id]}>
      <PlayerMachineProvider>
        <JotaiProvider>
          <Box className={clsx("bg-black w-full", className)}>
            {header}
            <FullScreen>
              <VodPlayer
                cameras={[playerProps.camera]}
                startTime={playerProps.startTime}
                endTime={playerProps.endTime}
                overrideMachineProvider={false}
                subjects={playerProps?.subjects}
              />
            </FullScreen>
            {footer}
          </Box>
        </JotaiProvider>
      </PlayerMachineProvider>
    </PlayerIdsProvider>
  );
}

export const DataGridContextualPlayer = ({
  open,
  handleClose,
  playerProps,
  playerBaseClassName,
  children,
}: {
  open: boolean;
  handleClose: (event: React.SyntheticEvent) => void;
  playerProps?: IVodContextualPlayer | null;
  playerBaseClassName?: string;
  children?: ReactNode;
}) => {
  return (
    <Modal
      open={open && Boolean(playerProps)}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      classes={{
        root: "grid place-items-center",
      }}
    >
      {playerProps ? (
        <ContextualPlayerBase
          className={clsx("rounded-2xl max-w-5xl", playerBaseClassName)}
          playerProps={playerProps}
          header={
            <>
              <div className="flex py-3 px-[14px] justify-between items-center w-full">
                <LocationNameTitle
                  location={playerProps.camera.location.name}
                  name={playerProps.camera.name}
                  className="hidden md:flex"
                />
                <div className="flex flex-row md:flex-col items-end justify-between gap-4 w-full">
                  <IconButton
                    className="w-3 h-3"
                    aria-label="close"
                    onClick={handleClose}
                  >
                    <CloseIcon className="text-white" />
                  </IconButton>
                  <Indicators />
                </div>
              </div>
              {playerProps.alertType && (
                <div className="bg-[#3C3C3C] text-white flex items-center gap-1 px-3 py-1">
                  {playerProps.alertName && (
                    <>
                      <NotificationsIcon />
                      <div>{playerProps.alertName}</div>
                    </>
                  )}
                  <div className="grow" />
                  <PeopleAlertIcon />
                  <div>{playerProps.alertType} Alert</div>
                </div>
              )}
              {playerProps.length && playerProps.queueNum && (
                <div className="bg-[#3C3C3C] text-white flex items-center gap-1 px-3 py-1">
                  <div>Idle for {playerProps.length}</div>
                  <div className="grow" />
                  <div>Queue #{playerProps.queueNum}</div>
                </div>
              )}
            </>
          }
          footer={
            <>
              <span className="md:hidden">
                <VodContextualPlayerDetails
                  name={playerProps.camera.name}
                  location={playerProps.camera.location.name}
                  timezone={playerProps.camera.location.timezone}
                  camId={playerProps.camera.id}
                  subjects={playerProps?.subjects}
                  startTime={playerProps.startTime}
                  endTime={playerProps.endTime}
                />
              </span>
              <span className="hidden md:block">
                <ContextualPlayerFooter
                  camId={playerProps.camera.id}
                  actions={playerProps?.actions}
                  timezone={playerProps.camera.location.timezone}
                  startTime={playerProps.startTime}
                  endTime={playerProps.endTime}
                  subjects={playerProps.subjects}
                />
              </span>
              {children}
            </>
          }
        />
      ) : (
        <div> No player data </div>
      )}
    </Modal>
  );
};

export const LPRReportDataGridContextualPlayer = ({
  playerProps,
}: {
  playerProps: IVodContextualPlayer;
}) => {
  return (
    <ContextualPlayerBase
      className="rounded-none sm:rounded-lg"
      playerProps={playerProps}
      header={
        <div className="flex py-3 px-[14px] justify-between items-center w-full">
          <LocationNameTitle
            location={playerProps.camera.location.name}
            name={playerProps.camera.name}
            className="hidden md:flex"
          />
          <div className="flex flex-row md:flex-col items-end justify-between gap-4 w-full">
            <Indicators />
          </div>
        </div>
      }
      footer={
        <>
          <span className="md:hidden">
            <VodContextualPlayerDetails
              name={playerProps.camera.name}
              location={playerProps.camera.location.name}
              timezone={playerProps.camera.location.timezone}
              camId={playerProps.camera.id}
              subjects={playerProps?.subjects}
              startTime={playerProps.startTime}
              endTime={playerProps.endTime}
            />
          </span>
          <span className="hidden md:block">
            <ContextualPlayerFooter
              camId={playerProps.camera.id}
              actions={playerProps?.actions}
              timezone={playerProps.camera.location.timezone}
              startTime={playerProps.startTime}
              endTime={playerProps.endTime}
              subjects={playerProps.subjects}
            />
          </span>
        </>
      }
    />
  );
};
