import { Divider, Typography } from "@mui/material";
import clsx from "clsx";
import { utcToZonedTime } from "date-fns-tz";
import { format } from "date-fns/fp";
import { useFlags } from "launchdarkly-react-client-sdk";
import { ReactNode, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { timeFormat } from "@/util/date";
import { formatDurationSecs } from "@/util/formatDurationSecs";
import { useBreakpoints } from "@/util/useBreakpoints";
import { buildVodUrl } from "@/util/vod";

import { Range } from "@/pages/Intelligence/IntelligenceDashboardView";

import { DataGrid } from "@/components/DataGrid/DataGrid";
import { PlayVideoButton } from "@/components/PlayVideoButton";
import {
  DataGridContextualPlayer,
  useDataGridContextPlayer,
} from "@/components/VideoWall/ContextualVideoPlayer";

import { IdleMetrics, useCameraByIdQuery } from "@/generated-models";

import { DatagridStill } from "../Datagrid";
import { CountDirection } from "../constants";
import { useIntelligenceTimeframeText } from "../hooks";
import { footageIsAvailable } from "../utils";

const formatDate = format("MMM d, y");

export default function CountEntitiesDatagrid({
  objectIds,
  cameraId,
  timezone,
  entities,
  subtype,
  range,
}: {
  objectIds?: string[] | null;
  cameraId: number;
  timezone: string;
  entities: IdleMetrics["entities"];
  subtype: CountDirection;
  range: string;
}) {
  const [currentSubtype, setCurrentSubtype] = useState<CountDirection>(
    CountDirection.InAndOut
  );
  const navigate = useNavigate();
  const { fitsDesktop } = useBreakpoints();
  const { contextualPlayer } = useFlags();
  const { loading, data, error } = useCameraByIdQuery({
    variables: { cameraId: Number(cameraId) },
  });
  const {
    open,
    handleOpen,
    handleClose,
    playerProps,
  } = useDataGridContextPlayer();

  const timeframeText = useIntelligenceTimeframeText();

  const resultSummaryPreposition = (range: Range) => {
    if (range === Range.Day) return "on";
    if (range === Range.Week) return "during";
    if (range === Range.Month) return "in";
    return "in";
  };

  // Filter entities based on count direction filter
  const rows = useMemo(() => {
    return entities.filter((e) => {
      if (currentSubtype === CountDirection.In) return e.crossedLeft;
      if (currentSubtype === CountDirection.Out) return e.crossedRight;
      return true;
    });
  }, [currentSubtype, entities]);

  function FilterButton({
    children,
    type,
  }: {
    children: ReactNode;
    type: CountDirection;
  }) {
    return (
      <button
        onClick={() => {
          setCurrentSubtype(type);
        }}
        className={clsx(
          "text-lg leading-[21px] text-text bg-transparent border-b-2 border-solid",
          {
            "opacity-70 border-transparent": type !== currentSubtype,
            "font-bold border-primary": type === currentSubtype,
          }
        )}
      >
        {children}
      </button>
    );
  }

  return (
    <div className="w-full" data-cy="maintain-datagrid">
      <div className="flex items-center justify-between mb-4">
        <div>
          {subtype === CountDirection.InAndOut && (
            <div className="flex gap-[20px] items-center">
              <FilterButton type={CountDirection.InAndOut}>All</FilterButton>
              <FilterButton type={CountDirection.In}>In</FilterButton>
              <FilterButton type={CountDirection.Out}>Out</FilterButton>
            </div>
          )}
        </div>
        <Typography className="font-medium text-base leading-[19px] text-text">
          {entities.length} results {resultSummaryPreposition(range as Range)}{" "}
          {timeframeText}
        </Typography>
        <div />
      </div>
      <DataGrid
        classes={{ row: "cursor-pointer" }}
        columnVisibilityModel={{
          id: fitsDesktop,
          idleSum: fitsDesktop,
          firstMs: fitsDesktop,
          lastMs: fitsDesktop,
          video: fitsDesktop,
          crossedLeft: fitsDesktop,
        }}
        getRowId={({ objectId }) => objectId}
        rowHeight={fitsDesktop ? 58 : 115}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
          sorting: { sortModel: [{ field: "queueNum", sort: "desc" }] },
        }}
        columns={[
          {
            field: "id",
            headerName: "Preview",
            sortable: false,
            headerClassName: "invisible",
            cellClassName: "pl-0",

            renderCell: ({ row: { firstMs, lastMs } }) => {
              return (
                <DatagridStill
                  cameraId={cameraId}
                  start={new Date(firstMs)}
                  end={new Date(lastMs)}
                />
              );
            },
          },
          {
            field: "queueNum",
            headerName: "Queue #",
            flex: 1,
            renderHeader: () => {
              return fitsDesktop ? (
                "Queue #"
              ) : (
                <span>
                  Sort By: <strong className="text-primary">Queue #</strong>
                </span>
              );
            },
            renderCell: ({
              row: {
                idleSum,
                queueNum,
                firstMs,
                lastMs,
                crossedLeft,
                crossedRight,
              },
            }) => {
              const length = formatDurationSecs(idleSum as number);

              if (fitsDesktop) {
                return <div>{`#${queueNum}`}</div>;
              }

              return (
                <div className="flex items-center justify-between gap-x-4">
                  <div className="w-[155px] border border-blue-500">
                    <DatagridStill
                      cameraId={cameraId}
                      start={new Date(firstMs)}
                      end={new Date(lastMs)}
                    />
                  </div>
                  <div className="flex flex-col gap-y-2">
                    <div className="flex gap-x-2 items-center">
                      <strong>{length}</strong>
                      <Divider className="h-[15px]" orientation="vertical" />
                      <div>{`Queue #${queueNum}`}</div>
                      <Divider className="h-[15px]" orientation="vertical" />
                      <div>{getDirection(crossedLeft, crossedRight)}</div>
                    </div>
                    <div className="text-sm leading-4">
                      {timeFormat.format(utcToZonedTime(firstMs, timezone))}
                    </div>
                    <div className="text-sm leading-4">
                      {timeFormat.format(utcToZonedTime(lastMs, timezone))}
                    </div>

                    <PlayVideoButton
                      available={footageIsAvailable(firstMs, data?.camera)}
                    />
                  </div>
                </div>
              );
            },
          },
          {
            field: "idleSum",
            flex: 2,
            headerName: "Date",
            renderCell: ({ row: { firstMs } }) =>
              formatDate(utcToZonedTime(firstMs, timezone)),
          },
          {
            field: "firstMs",
            flex: 2,
            headerName: "First Seen",
            renderCell: ({ row: { firstMs } }) =>
              timeFormat.format(utcToZonedTime(firstMs, timezone)),
          },
          {
            field: "lastMs",
            flex: 2,
            headerName: "Last Seen",
            renderCell: ({ row: { lastMs } }) =>
              timeFormat.format(utcToZonedTime(lastMs, timezone)),
          },
          {
            field: "crossedLeft",
            flex: 2,
            headerName: "Direction",
            renderCell: ({ row: { crossedLeft, crossedRight } }) => {
              if (crossedLeft && crossedRight) {
                return "In & Out";
              }
              if (crossedLeft) {
                return "In";
              }
              return "Out";
            },
          },
          {
            field: "video",
            sortable: false,
            flex: 2,
            headerName: "Video",
            renderCell: ({ row: { firstMs } }) => {
              return (
                <PlayVideoButton
                  available={footageIsAvailable(firstMs, data?.camera)}
                />
              );
            },
          },
        ]}
        rows={rows || []}
        onRowClick={({ row: { firstMs, lastMs, duration, queueNum } }) => {
          if (!footageIsAvailable(firstMs, data?.camera)) {
            return;
          }

          if (contextualPlayer && data && !error && !loading) {
            const length = duration && formatDurationSecs(duration);
            handleOpen({
              camera: data.camera,
              subjects: objectIds,
              startTime: new Date(firstMs).toISOString(),
              endTime: new Date(lastMs).toISOString(),
              length,
              queueNum,
            });
          } else {
            navigate(buildVodUrl(cameraId, firstMs, lastMs, objectIds));
          }
        }}
      />
      <DataGridContextualPlayer
        open={open}
        handleClose={handleClose}
        playerProps={playerProps}
      />
    </div>
  );
}

export function getDirection(
  crossedLeft?: boolean | null,
  crossedRight?: boolean | null
) {
  if (crossedLeft && crossedRight) return "In & Out";
  if (crossedLeft) return "In";
  if (crossedRight) return "Out";
  return "N/A";
}
