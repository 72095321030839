import { CircularProgress, Button, MenuItem } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { Select, TextField } from "formik-mui";
import { GraphQLError } from "graphql";

import { MaintainDetailTile } from "@/pages/Maintain/Details/MaintainDetailsTile";

import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";

import {
  CameraDetailByIdDocument,
  CameraDetailByIdQuery,
  useUpdateCameraMutation,
} from "@/generated-models";
import { usePermissions } from "@/hooks/usePermissions";

const CAMERA_CONNECTION_TYPES = {
  Wireless: "Wireless",
  Wired: "Wired",
};

export function MaintainDetailsConfigDetailsTile({
  camera,
}: {
  camera: CameraDetailByIdQuery["camera"];
}) {
  const hasPermissions = usePermissions();
  const canEdit = hasPermissions("devices_manage");
  const { pushSnackbar } = useFeedback();

  const [updateCamera] = useUpdateCameraMutation({
    refetchQueries: [
      { query: CameraDetailByIdDocument, variables: { cameraId: camera.id } },
    ],
  });

  return (
    <MaintainDetailTile title="Camera Details">
      <Formik
        enableReinitialize
        initialValues={{
          ...camera.details,
          networkType: camera.details.networkType || "",
        }}
        onSubmit={async (values, { setSubmitting, resetForm, setErrors }) => {
          const { data, errors } = await updateCamera({
            variables: {
              id: camera.id,
              input: { details: { ...values } },
            },
          }).catch((e) => {
            return {
              data: undefined,
              errors: e.graphQLErrors as GraphQLError[],
            };
          });

          if (Boolean(errors?.length) || !data) {
            if (errors) {
              for (const error of errors) {
                pushSnackbar(error.message, FeedbackType.Error);
              }

              if (errors.some((e) => /credentials/i.test(e.message))) {
                setErrors({
                  username: "Credentials invalid",
                  password: "Credentials invalid",
                });
              }
              if (errors.some((e) => /rtsp/i.test(e.message))) {
                setErrors({
                  path: "Invalid RTSP Path",
                });
              }
            }
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, resetForm, dirty }) => (
          <Form>
            <div className="grid md:grid-cols-3 grid-cols-1 sm:grid-cols-2 gap-6">
              <Field
                name="model"
                fullWidth
                component={TextField}
                label="Camera Model"
                disabled={isSubmitting || !canEdit}
              />
              <Field
                name="year"
                fullWidth
                component={TextField}
                label="Camera Year"
                disabled={isSubmitting || !canEdit}
              />
              <Field
                name="cost"
                fullWidth
                component={TextField}
                label="Camera Cost"
                disabled={isSubmitting || !canEdit}
              />
              <Field
                name="installer"
                fullWidth
                component={TextField}
                label="Camera Installer"
                disabled={isSubmitting || !canEdit}
              />
              <Field
                name="installationDate"
                fullWidth
                component={TextField}
                label="Camera Installation Date"
                disabled={isSubmitting || !canEdit}
              />
              <Field
                name="networkType"
                fullWidth
                component={Select}
                label="Camera Network Type"
                disabled={isSubmitting || !canEdit}
                formControl={{ variant: "standard" }}
              >
                {Object.entries(CAMERA_CONNECTION_TYPES).map(([key, name]) => (
                  <MenuItem key={key} value={key}>
                    {name}
                  </MenuItem>
                ))}
              </Field>
            </div>

            <div className="flex justify-end mt-6">
              <div className="flex gap-x-4">
                <Button
                  color="primary"
                  size="small"
                  disabled={!dirty}
                  onClick={() => {
                    resetForm();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="min-w-[105px]"
                  color="primary"
                  variant="contained"
                  type="submit"
                  size="small"
                  disabled={isSubmitting || !dirty}
                >
                  Save
                  {isSubmitting && (
                    <CircularProgress className="mx-1" size="20px" />
                  )}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </MaintainDetailTile>
  );
}
