import { QueryResult } from "@apollo/client";
import ImageIcon from "@mui/icons-material/ImageOutlined";
import { Hidden } from "@mui/material";
import gql from "graphql-tag";
import { useParams } from "react-router-dom";

import { ErrorMessage } from "@/components/ErrorMessage";
import { PlayerAspect } from "@/components/Player/PlayerBase";
import { PlayerMetadata } from "@/components/Player/PlayerMetadata";
import { WallclockStatic } from "@/components/View/WallclockTimer";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  CaseScreenshotQuery,
  SharedCaseScreenshotQuery,
  useCaseScreenshotQuery,
  useSharedCaseScreenshotQuery,
} from "@/generated-models";

import {
  AnnotationDisplayOverlay,
  MobileAnnotationDisplay,
  MobileAnnotationInput,
} from "./AnnotationDisplay";
import { useAnnotationContext } from "./CreateAnnotationContextProvider";
import { CreateAnnotationOverlay } from "./CreateAnnotationOverlay";
import {
  CASE_CLIP_ANNOTATION_BASE_FRAGMENT,
  SHARED_CASE_CLIP_ANNOTATION_BASE_FRAGMENT,
} from "./caseFragments";

export function CaseScreenshotViewer({
  caseId,
  screenshotId,
  anonymous,
}: {
  caseId: number;
  screenshotId: number;
  anonymous: boolean;
}) {
  const Component = anonymous
    ? SharedCaseScreenshotViewerData
    : CaseScreenshotViewerData;
  return <Component caseId={caseId} screenshotId={screenshotId} />;
}

function CaseScreenshotViewerData({
  caseId,
  screenshotId,
}: {
  caseId: number;
  screenshotId: number;
}) {
  const screenshotQuery = useCaseScreenshotQuery({
    variables: { caseId, screenshotId: screenshotId },
    ...refetchOnMountPolicy,
  });

  return (
    <CaseScreenshotViewerInner
      screenshotQuery={screenshotQuery}
      caseId={caseId}
    />
  );
}

function SharedCaseScreenshotViewerData({
  screenshotId,
}: {
  screenshotId: number;
}) {
  const { token } = useParams();
  const screenshotQuery = useSharedCaseScreenshotQuery({
    variables: { screenshotId, token: token ?? "" },
    skip: !token,
    ...refetchOnMountPolicy,
  });

  return <CaseScreenshotViewerInner screenshotQuery={screenshotQuery} />;
}

export function CaseScreenshotViewerInner({
  screenshotQuery,
  caseId,
}: {
  screenshotQuery:
    | QueryResult<CaseScreenshotQuery, any>
    | QueryResult<SharedCaseScreenshotQuery, any>;
  caseId?: number;
}) {
  const { data, error } = screenshotQuery;
  const { placingAnnotation } = useAnnotationContext();

  if (error) {
    return (
      <ErrorMessage
        title="Fetching screenshot failed"
        description={error.message}
      />
    );
  }

  if (!data?.caseScreenshot) {
    return (
      <PlayerAspect maxHeight="calc(100vh - 250px)">
        <div className="bg-black w-full h-full">&nbsp;</div>
      </PlayerAspect>
    );
  }
  const { source, camera, timestamp } = data.caseScreenshot;

  return (
    <>
      <PlayerAspect maxHeight="calc(100vh - 250px)" className="bg-black">
        <img alt={camera.name} src={source} className="h-full mx-auto" />
        {caseId && placingAnnotation ? (
          <CreateAnnotationOverlay
            caseId={caseId}
            screenshotId={data.caseScreenshot.id}
          />
        ) : (
          <AnnotationDisplayOverlay
            annotations={data.caseScreenshot.annotations}
          />
        )}
      </PlayerAspect>
      <PlayerMetadata className="dark bg-[#1b1b1b] gap-1 py-2">
        <ImageIcon />
        <Hidden smDown>
          <div className="opacity-50">Camera:</div>
        </Hidden>
        <div className="text-base font-bold truncate">{camera.name}</div>
        <div className="grow" />
        <WallclockStatic
          date={new Date(timestamp)}
          timezone={camera.location.timezone}
        />
      </PlayerMetadata>

      {caseId && (
        <Hidden mdUp>
          {placingAnnotation ? (
            <MobileAnnotationInput
              screenshotId={data.caseScreenshot.id}
              caseId={caseId}
            />
          ) : (
            <MobileAnnotationDisplay
              annotations={data.caseScreenshot.annotations}
            />
          )}
        </Hidden>
      )}
    </>
  );
}

gql`
  query caseScreenshot($caseId: Int!, $screenshotId: Int!) {
    caseScreenshot(caseId: $caseId, screenshotId: $screenshotId) {
      id
      timestamp
      source
      camera {
        id
        name
        location {
          id
          timezone
          name
        }
      }
      annotations {
        ...CaseClipAnnotationBase
      }
    }
  }
  ${CASE_CLIP_ANNOTATION_BASE_FRAGMENT}
`;

gql`
  query sharedCaseScreenshot($screenshotId: Int!, $token: String!) {
    caseScreenshot: sharedCaseScreenshot(
      screenshotId: $screenshotId
      token: $token
    ) {
      id
      timestamp
      source
      camera {
        id
        name
        location {
          id
          timezone
          name
        }
      }
      annotations {
        ...SharedCaseClipAnnotationBase
      }
    }
  }
  ${SHARED_CASE_CLIP_ANNOTATION_BASE_FRAGMENT}
`;
