import { Button, Typography } from "@mui/material";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";

export interface CopilotLibraryActionsProps {
  expanded?: boolean;
  reverse?: boolean;
  message?: string;
  disabled?: boolean;
  confirmDisabled?: boolean;
  cancelGhost?: boolean;
  cancelLabel?: string;
  confirmLabel: string;
  onCancel?: () => void;
  onConfirm: () => void;
}

export function CopilotLibraryActions({
  expanded,
  reverse,
  message,
  disabled,
  confirmDisabled,
  cancelGhost,
  cancelLabel,
  confirmLabel,
  onCancel,
  onConfirm,
}: CopilotLibraryActionsProps) {
  return (
    <AnimatePresence>
      {expanded && (
        <motion.div
          key="lib-details-add-content-actions"
          initial={{ opacity: 0, height: 0 }}
          animate={{
            opacity: 1,
            height: 30,
            transition: { type: "spring", stiffness: 100 },
          }}
          exit={{
            opacity: 0,
            height: 0,
            zIndex: 0,
            transition: { type: "spring", stiffness: 50 },
          }}
          className={clsx(
            "flex items-center justify-between gap-[5px] h-[30px]",
            {}
          )}
        >
          {message ? (
            <Typography className="text-base leading-normal text-white">
              {message}
            </Typography>
          ) : (
            <div />
          )}
          <div
            className={clsx("flex items-center gap-[5px] h-[30px]", {
              "flex-row-reverse": reverse,
              "flex-row": !reverse,
            })}
          >
            <Button
              className={clsx(" text-sm leading-normal h-[30px] ", {
                "text-[#656565] font-normal": cancelGhost,
                "bg-[#0D5A9B] hover:bg-opacity-60": !cancelGhost,
              })}
              size="small"
              color={cancelGhost ? undefined : "primary"}
              variant={cancelGhost ? "outlined" : "contained"}
              onClick={onCancel}
              disabled={disabled}
            >
              {cancelLabel || "Cancel"}
            </Button>
            <Button
              classes={{ disabled: "text-white/40" }}
              className="text-sm leading-normal"
              size="small"
              variant="contained"
              color="primary"
              onClick={onConfirm}
              disabled={disabled || confirmDisabled}
            >
              {confirmLabel}
            </Button>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
