import { ChevronLeft } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { ReactNode } from "react";
import { NumberParam, useQueryParam } from "use-query-params";

import {
  HdIndicator,
  LocalIndicator,
} from "@/components/Player/PlayerMetadata";
import { DesktopGroupSelect } from "@/components/View/ViewNavigation";
import { SearchParamLink } from "@/components/shared/QueryParamLink";

import { useGroupsQuery } from "@/generated-models";

export function MainPlayerMobileHeader({
  additionalIndicators,
}: {
  additionalIndicators?: ReactNode;
}) {
  const { data } = useGroupsQuery();
  // Not using localStorage here because this view scopes the current cam to the
  // current group.
  const [activeGroupId, setActiveGroupId] = useQueryParam("g", NumberParam);

  return (
    <header className="flex flex-col">
      <div className="flex">
        <IconButton
          color="primary"
          component={SearchParamLink}
          to="."
          keepParams={["g"]}
        >
          <ChevronLeft fontSize="large" />
        </IconButton>
        {data && (
          <DesktopGroupSelect
            className="m-2 mt-3"
            groups={data.groups}
            activeGroupId={activeGroupId ?? undefined}
            setGroupId={setActiveGroupId}
          />
        )}
      </div>
      <div className="flex justify-end gap-0.5 p-2 pt-0">
        <LocalIndicator />
        <HdIndicator />
        {additionalIndicators}
      </div>
    </header>
  );
}
