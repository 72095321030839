import {
  Box,
  Chip,
  Dialog,
  Grid,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";
import clsx from "clsx";
import gql from "graphql-tag";
import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";
import { Navigate, useParams } from "react-router-dom";

import { filterNullish } from "@/util/filterFalsy";
import { useBreakpoints } from "@/util/useBreakpoints";
import { useViewActivityTimer } from "@/util/useViewActivityTimer";

import KioskCastButton from "@/pages/VideoWall/Kiosk/KioskCastButton";

import { useMe } from "@/components/Auth";
import { RotatingVideoWallEdit } from "@/components/VideoWall/RotatingVideoWallEdit";
import { VideoWallCamTile } from "@/components/VideoWall/VideoWallCamTile";
import VideoWallGrid from "@/components/VideoWall/VideoWallGrid";
import { VideoWallLayout } from "@/components/VideoWall/VideoWallLayout";
import { VideoWallRotationProgress } from "@/components/VideoWall/VideoWallRotationProgress";
import { VideoWallViewHeader } from "@/components/VideoWall/VideoWallViewHeader";

import {
  usePage_VideoWallsQuery,
  useRecordRotatingVideoWallActivityMutation,
} from "@/generated-models";
import { theme } from "@/layout/theme";

import { forcedQualityByCameraCount, useWallStyles } from "./constants";
import { useCanEditVideoWalls } from "./hooks";
import { useIsDemoUser } from "./useIsDemoUser";
import { useVideoWallContext } from "./useVideoWallContext";
import { useVideoWallRotation } from "./useVideoWallRotation";

export function RotatingVideoWallView() {
  const { classes } = useWallStyles();
  const { fitsDesktop } = useBreakpoints();

  const params = useParams<{ id: string }>();
  const id = Number(params.id);
  const { data } = usePage_VideoWallsQuery();

  const me = useMe();
  const webRtcIsEnabled = me?.organization?.flags.webRTC ?? false;
  const { auditLogData } = useFlags();
  const canEdit = useCanEditVideoWalls();

  const wall = data?.rotatingVideoWalls.find((wall) => wall.id === id);
  const { page, setPage, preloadingPage } = useVideoWallRotation(
    wall?.videoWalls.length ?? 0,
    wall?.pageDuration ?? 90,
    webRtcIsEnabled
  );
  const wallPage = wall?.videoWalls[Math.min(page, wall.videoWalls.length - 1)];
  const preloadingWallPage =
    wall && preloadingPage != null
      ? wall?.videoWalls[Math.min(preloadingPage, wall.videoWalls.length - 1)]
      : null;
  const {
    fullscreenHandle,
    autoLayoutState: [autoLayout],
    originalConfigurationState: [, setMatchesOriginalConfiguration],
    editingState: [editing, setEditing],
    fullscreenActive,
  } = useVideoWallContext();
  const isDemo = useIsDemoUser();

  // error and loading state should be handled in parent <VideoWall />
  if (!data) return null;

  if (!wall) {
    // Wall doesn't exist anymore
    localStorage.removeItem("lastViewedWallId");
    return <Navigate to=".." replace />;
  }

  // Override dark theme for the progress bar
  const progressIndicator = (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <VideoWallRotationProgress
          page={page}
          pageDuration={wall.pageDuration}
        />
      </ThemeProvider>
    </StyledEngineProvider>
  );

  const maxAutoLevel =
    wallPage &&
    forcedQualityByCameraCount(wallPage.cameras.filter(filterNullish).length);

  return (
    <VideoWallLayout
      header={
        <div>
          <VideoWallViewHeader
            fullscreenHandle={fullscreenHandle}
            wallName={wall.name}
            castButton={<KioskCastButton rotatingWallId={id} />}
            data={data}
            edit={() => setEditing(true)}
          />

          <div>
            <Grid
              container
              wrap="nowrap"
              style={{
                padding: "0 16px",
                justifyContent: fullscreenActive ? "center" : undefined,
                maxWidth: "100vw",
                overflow: "auto",
              }}
            >
              {wall.videoWalls.map((wall, index, list) => (
                <React.Fragment key={wall.id}>
                  {fullscreenActive ? (
                    <Chip
                      className={clsx(
                        classes.pageChip,
                        classes.fullScreenPageChip,
                        {
                          active: wallPage?.id === wall.id,
                        }
                      )}
                      onClick={() => setPage(index)}
                      label={wall.name}
                      color={wallPage?.id === wall.id ? "primary" : undefined}
                    />
                  ) : (
                    <Chip
                      className={clsx(classes.pageChip, {
                        active: wallPage?.id === wall.id,
                      })}
                      onClick={() => setPage(index)}
                      label={wall.name}
                      color={wallPage?.id === wall.id ? "primary" : undefined}
                    />
                  )}
                  {index < list.length - 1 && <Box m={0.5} />}
                </React.Fragment>
              ))}
            </Grid>
            <Box m={1} />
            {(!fullscreenActive || !fitsDesktop) && progressIndicator}
          </div>
        </div>
      }
      body={
        <>
          {auditLogData && <ViewRecorder wallId={id} />}
          {/* Quick and dirty implementation with copy/paste. Might want to clean up later */}
          {preloadingWallPage && (
            <VideoWallGrid
              config={preloadingWallPage.config as any}
              fullscreen={fullscreenActive}
              autoLayout={autoLayout}
              filterableCamIndexes={preloadingWallPage.cameras
                .map((cam, index) =>
                  Boolean(cam && cam.__typename !== "NotAllowed") ? null : index
                )
                .filter(filterNullish)}
              preload
              key={preloadingWallPage.id}
            >
              {preloadingWallPage.cameras.map((cam, i) => (
                <VideoWallCamTile
                  cam={cam}
                  key={`${cam?.id}-${i}`}
                  maxAutoLevel="360p" // Preload with lowest quality
                  rotating
                  isWebRtc={webRtcIsEnabled}
                />
              ))}
            </VideoWallGrid>
          )}
          {wallPage && (
            <VideoWallGrid
              key={wallPage.id}
              config={wallPage.config as any}
              fullscreen={fullscreenActive}
              autoLayout={autoLayout}
              filterableCamIndexes={wallPage.cameras
                .map((cam, index) =>
                  Boolean(cam && cam.__typename !== "NotAllowed") ? null : index
                )
                .filter(filterNullish)}
              onLayoutChange={({ matchesOriginalConfiguration }) =>
                setMatchesOriginalConfiguration(matchesOriginalConfiguration)
              }
            >
              {wallPage.cameras.map((cam, i) => (
                <VideoWallCamTile
                  cam={cam}
                  key={`${cam?.id}-${i}`}
                  maxAutoLevel={maxAutoLevel}
                  rotating
                  isWebRtc={webRtcIsEnabled}
                />
              ))}
            </VideoWallGrid>
          )}

          {fullscreenActive && fitsDesktop && progressIndicator}

          {editing && !isDemo && canEdit && (
            <Dialog open onClose={() => setEditing(false)}>
              <RotatingVideoWallEdit />
            </Dialog>
          )}
        </>
      }
    />
  );
}

function ViewRecorder({ wallId }: { wallId: number }) {
  const [recordActivity] = useRecordRotatingVideoWallActivityMutation();
  useViewActivityTimer(true, (start: Date) =>
    recordActivity({
      variables: { id: wallId, start: start.toISOString() },
    })
  );
  return null;
}

gql`
  mutation recordRotatingVideoWallActivity($id: Int!, $start: DateTime!) {
    recordRotatingVideoWallActivity(id: $id, start: $start)
  }
`;
