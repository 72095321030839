import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { CircularProgress, Typography } from "@mui/material";
import clsx from "clsx";
import { DropzoneState } from "react-dropzone";

interface FileUploaderProps {
  prompt?: string;
  state: Pick<DropzoneState, "getInputProps" | "getRootProps" | "isDragActive">;
  loading?: boolean;
}

export default function FileUploader({
  prompt = "Drag and drop file here",
  state,
  loading,
}: FileUploaderProps) {
  const { getInputProps, getRootProps, isDragActive } = state;
  return (
    <div
      {...getRootProps()}
      className={clsx(
        "flex items-center flex-col my-3 p-5 rounded border border-dashed border-gray-71 cursor-pointer",
        { "bg-[#F6FBFF]": isDragActive }
      )}
    >
      <input {...getInputProps()} />
      {loading ? (
        <CircularProgress size={44} />
      ) : (
        <CloudUploadIcon className="w-11 h-11 text-[#e5e5e5]" />
      )}
      <Typography className="text-base font-medium">{prompt}</Typography>
      <Typography className="text-base">or</Typography>
      <Typography className="text-base font-bold text-primary">
        Browse files
      </Typography>
    </div>
  );
}
