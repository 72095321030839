import gql from "graphql-tag";
import { atom as jAtom, useAtomValue, useSetAtom } from "jotai";
import { findLastIndex, sortBy, uniqBy } from "lodash/fp";
import React, { useMemo } from "react";

import { filterFalsy } from "@/util/filterFalsy";
import { useLocalStorage } from "@/util/useLocalStorage";

import { useWallclockPts } from "@/components/Player/PlayerBase";

import {
  useLprBoundingBoxResultsQuery,
  usePackedVodObjectFramesQuery,
} from "@/generated-models";

interface AiValuesState {
  bboxView: string;
  syncAlg: boolean;
}

export const aiUiState = jAtom<AiValuesState>({
  bboxView: "normal",
  syncAlg: true,
  ...(localStorage.getItem("aistate")
    ? JSON.parse(localStorage.getItem("aistate")!)
    : undefined),
} as AiValuesState);
aiUiState.debugLabel = "aiState";

export function useAiUiState(): [
  AiValuesState,
  (update: Partial<AiValuesState>) => void
] {
  const updateAiUiState = useSetAtom(aiUiState);
  return [
    useAtomValue(aiUiState),
    (newState: Partial<AiValuesState>) => {
      updateAiUiState((oldState) => {
        const finalState = { ...oldState, ...newState };
        localStorage.setItem("aistate", JSON.stringify(finalState));
        return finalState;
      });
    },
  ];
}

export interface ObjectFrame {
  timestampMs: number;
  pts: number;
  objects: {
    xmin: number;
    ymin: number;
    xmax: number;
    ymax: number;
    score?: number | null;
    localObjId?: string | null;
    vx?: number | null;
    vy?: number | null;
    type: {
      id: string;
    };
    description?: string | null;
  }[];
}

export function useAiLprQuery(
  cameraId: number,
  startTime: string,
  endTime: string
) {
  const { data } = useLprBoundingBoxResultsQuery({
    variables: {
      input: {
        id: cameraId,
        startTime: startTime,
        endTime: endTime,
      },
    },
    onError: (error) => console.error(error.message),
  });

  const objectFrames: ObjectFrame[] | undefined = useMemo(
    () =>
      data
        ? sortBy(
            "timestampMs",
            data.lprBoundingBoxResults.results.map(
              ({
                timestampMs,
                pts,
                xmin,
                ymin,
                xmax,
                ymax,
                plate,
                plate_score,
              }) => ({
                timestampMs: timestampMs,
                pts: pts,
                objects: [
                  {
                    xmin,
                    ymin,
                    xmax,
                    ymax,
                    score: plate_score,
                    localObjId: `${timestampMs}|${plate}`,
                    vx: 0,
                    vy: 0,
                    type: {
                      id: "vehiclePlate",
                    },
                    description: plate,
                  },
                ],
              })
            )
          )
        : [],
    [data]
  );

  if (!objectFrames) return null;
  return objectFrames;
}

export function useAiVodQuery(
  cameraId: number,
  startTime: string,
  endTime: string,
  subjects?: string[] | null
) {
  const { data: packedData } = usePackedVodObjectFramesQuery({
    variables: {
      id: cameraId,
      startTime,
      endTime,
    },
    onError: (error) => console.error(error.message),
    skip: !subjects,
  });

  const objectFrames: ObjectFrame[] | undefined = useMemo(() => {
    if (packedData) {
      const result: ObjectFrame[] = [];
      const items = sortBy(
        "timestampMs",
        packedData?.vod.objectFramesPacked.map(({ t, pts, o }) => ({
          timestampMs: t,
          pts: pts,
          objects: o.map((x) => {
            const [fullId, bxData] = x.split(":");
            const [typeId, timeSeed, localObjId] = fullId.split("|");
            const [moving, bxTxt] = bxData.split("#");
            const bx = BigInt(Number(bxTxt));
            return {
              xmin: Number((bx >> BigInt(30)) & BigInt(1023)) / 1023,
              ymin: Number((bx >> BigInt(20)) & BigInt(1023)) / 1023,
              xmax: Number((bx >> BigInt(10)) & BigInt(1023)) / 1023,
              ymax: Number((bx >> BigInt(0)) & BigInt(1023)) / 1023,
              score: Number((bx >> BigInt(40)) & BigInt(1023)) / 1023,
              localObjId: `${timeSeed}|${localObjId}`,
              vx: moving === "1" ? 0.1 : 0,
              vy: moving === "1" ? 0.1 : 0,
              type: {
                id: typeId,
              },
            };
          }),
        }))
      );

      // Interpolate the object frames for better granularity.
      items.forEach((i, idx) => {
        const nextItem = items[idx + 1];
        result.push(i);

        if (nextItem) {
          result.push({
            timestampMs: (i.timestampMs + nextItem.timestampMs) / 2,
            pts: (i.pts + nextItem.pts) / 2,
            objects: i.objects.map((x) => {
              const nextObj = nextItem.objects.find(
                (o) => o.localObjId === x.localObjId
              );

              if (nextObj) {
                return {
                  xmin: (x.xmin + nextObj.xmin) / 2,
                  ymin: (x.ymin + nextObj.ymin) / 2,
                  xmax: (x.xmax + nextObj.xmax) / 2,
                  ymax: (x.ymax + nextObj.ymax) / 2,
                  score: (x.score + nextObj.score) / 2,
                  localObjId: x.localObjId,
                  vx: x.vx,
                  vy: x.vy,
                  type: x.type,
                };
              }

              return x;
            }),
          });
        }
      });

      return items;
    }

    return undefined;
  }, [packedData]);

  if (!subjects || !objectFrames) return null;

  const filteredScores = objectFrames
    .flatMap(({ objects }) => objects.map(({ score }) => score))
    .filter(filterFalsy);

  const displayingFrames = objectFrames.map((frame) => ({
    ...frame,
    objects: frame.objects.filter((object) =>
      subjects.includes(object.type.id)
    ),
  }));

  return { objectFrames, filteredScores, displayingFrames };
}

export function useFrameQuery(objectFrames: ObjectFrame[], offset?: number) {
  const tempPtsTime = useWallclockPts();
  const ptsTime = (tempPtsTime || 0) + (offset || 0);

  const latestFrameIdx = findLastIndex(
    ({ pts }) => !!ptsTime && !!pts && pts <= ptsTime,
    objectFrames
  );
  let closestFrame = objectFrames[latestFrameIdx];

  if (closestFrame?.pts) {
    const nextFrame = objectFrames[latestFrameIdx + 1];

    // three seconds is a huge gap for our target 3fps (degraded, 1fps on p1000)
    if (
      !nextFrame ||
      !closestFrame ||
      nextFrame.pts - closestFrame.pts > 3000 * 90
    ) {
      // threshold the firstNext frame gap
      // Imagine you have
      //                      ...gap too long...
      // time = |----------|---------------x----------|---------|
      // Where the frame and the next frame before and after the X is large enough.
      // In this case, we dont want to use the closest frame approximation to do our
      // bounding box alignment, so we jettison it out.
      return undefined;
    }

    if (
      nextFrame?.pts &&
      ptsTime &&
      Math.abs(nextFrame.pts - ptsTime) < Math.abs(closestFrame.pts - ptsTime)
    ) {
      closestFrame = objectFrames[latestFrameIdx + 1];
    }
  }

  // TODO: Resolve duplicate entires that pop up here.
  if (!!closestFrame) {
    closestFrame = {
      ...closestFrame,
      objects: uniqBy(
        ({ type: { id }, localObjId }) => `${id}|${localObjId}`,
        closestFrame.objects
      ),
    };
  }
  return closestFrame;
}

export const focusedBox = jAtom<FocusedBoxInfo | null | undefined>(null);
focusedBox.debugLabel = "focusedBox";
export interface FocusedBoxInfo {
  localObjId: string;
  objectType: string;
  cameraId: number;
  objectPath?: { x: number; y: number }[];
}
export function useFocusBox(): [
  FocusedBoxInfo | null | undefined,
  React.Dispatch<React.SetStateAction<FocusedBoxInfo | null | undefined>> | null
] {
  const [showAiDebug] = useLocalStorage("showAiDebug", false);
  const focusedBoundingBox = useAtomValue(focusedBox);
  const setFocusedBoundingBox = useSetAtom(focusedBox);

  return [focusedBoundingBox, showAiDebug ? setFocusedBoundingBox : null];
}

gql`
  query vodObjectFrames(
    $id: Int!
    $startTime: String!
    $endTime: String!
    $rawBoundingBoxesOnly: Boolean
  ) {
    vod(id: $id, startTime: $startTime, endTime: $endTime) {
      id
      objectFrames(input: { showRaw: $rawBoundingBoxesOnly }) {
        timestampMs
        pts
        objects {
          type {
            id
            source
          }
          xmin
          ymin
          xmax
          ymax
          score
          localObjId
          vx
          vy
        }
      }
    }
  }
`;

gql`
  query packedVodObjectFrames(
    $id: Int!
    $startTime: String!
    $endTime: String!
  ) {
    vod(id: $id, startTime: $startTime, endTime: $endTime) {
      id
      objectFramesPacked {
        t
        pts
        o
      }
    }
  }
`;

gql`
  query lprBoundingBoxResults($input: LprBoundingBoxResultsInput!) {
    lprBoundingBoxResults(input: $input) {
      results(input: $input) {
        timestampMs
        pts

        xmin
        ymin
        xmax
        ymax

        plate
        plate_score
      }
    }
  }
`;
