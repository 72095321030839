import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {
  CircularProgress,
  ClickAwayListener,
  Portal,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { utcToZonedTime, format as tzFormat } from "date-fns-tz";
import { motion } from "framer-motion";
import { useEffect, useMemo, useRef, useState } from "react";
import Draggable from "react-draggable";
import { NumberParam, useQueryParam } from "use-query-params";

import {
  usePlayerControls,
  useWallclockTime,
} from "@/components/Player/PlayerBase";
import { useFirstActiveCamId } from "@/components/View/sharedViewHooks";

import { useGetCameraNameTimezoneQuery } from "@/generated-models";

import { CopilotNavigationButton } from "../../CopilotNavigationButton";
import { useAiAnnotations } from "../../copilotAnnotationQueryHooks";
import {
  useRefetchUniversalSearch,
  useUniversalSearchByImage,
} from "../../copilotQueryHooks";
import { CopilotMode, useCopilotContext } from "../../useCopilotContext";
import { CopilotPopoverHeader } from "../CopilotLibraryPopover/Core/CopilotPopoverHeader";
import { CopilotHistoryEmptyState } from "./CopilotHistoryEmptyState";

export function CopilotHistoryPopover() {
  const cameraId = useFirstActiveCamId();
  const anchorEl = useRef<HTMLButtonElement | null>(null);
  const playerId = useFirstActiveCamId().toString();
  const wallClock = useWallclockTime()?.toISOString();
  const [annotation, setAnnotation] = useQueryParam("annotation", NumberParam);
  const {
    mode,
    setMode,
    overrideImage,
    setOverrideImage,
  } = useCopilotContext();
  const { getPlayerElement } = usePlayerControls(playerId);
  const refetch = useRefetchUniversalSearch();
  const { loading: searchLoading } = useUniversalSearchByImage();
  const { data: cameraData } = useGetCameraNameTimezoneQuery({
    variables: { cameraId: cameraId },
  });
  const timezone = cameraData?.camera?.location?.timezone || "";
  const { data, loading } = useAiAnnotations();
  const [position, setPosition] = useState<{
    top: number | string;
    left: number | string;
  }>({ top: 0, left: 0 });

  function cleanUpAnnotation() {
    if (overrideImage) {
      setOverrideImage(null);
      refetch();
    }
    setAnnotation(undefined);
  }

  useEffect(() => {
    return () => {
      cleanUpAnnotation();
      setMode(CopilotMode.default);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getHistoryStill(url: string, time: string) {
    const data = await fetch(url || "", {
      method: "GET",
    });
    const blob = await data.blob();
    const image = new File([blob], `${annotation}.jpeg`, {
      type: "image/jpeg",
    });
    setOverrideImage({
      image,
      imageDataURL: url,
    });

    refetch({ image, time });
  }

  const { index, annotations } = useMemo(() => {
    const trimmedClock = wallClock?.replace(/.\d+Z$/g, "Z") || "";
    const items = [...(data?.aiAnnotations || [])];
    let index =
      items.findIndex((a) => {
        return a.timestamp.replace(/.\d+Z$/g, "Z") === trimmedClock;
      }) || 0;

    if (annotation) {
      index = items.findIndex((i) => i.id === annotation);
    }

    if (index >= 0) {
      return {
        index,
        annotations: items,
      };
    }

    let closestIndex =
      items.findIndex((a) => {
        return new Date(a.timestamp) > new Date(wallClock || "");
      }) || 0;

    if (closestIndex < 0) {
      closestIndex = items.length;
    }

    items.splice(closestIndex, 0, {
      id: -1,
      cameraId,
      timestamp: wallClock || "",
      url: "",
      __typename: "AiAnnotation",
    });

    return {
      index: closestIndex,
      annotations: items,
    };
  }, [annotation, cameraId, data?.aiAnnotations, wallClock]);

  const count = annotations.length;

  const historyItem = data?.aiAnnotations[index];
  const zonedTime = utcToZonedTime(
    historyItem?.timestamp
      ? new Date(historyItem?.timestamp)
      : new Date(wallClock || ""),
    timezone
  );

  const disabled = loading || searchLoading;
  const defaultState = (data?.aiAnnotations.length || 0) <= 1;
  const open = mode === CopilotMode.coachinghistory;

  const handleToggle = async () => {
    if (open) {
      cleanUpAnnotation();
      setMode(CopilotMode.default);
    } else {
      const boundingRect = getPlayerElement()?.getBoundingClientRect();

      if (boundingRect) {
        setPosition({
          top: (boundingRect.bottom / 3) * 2,
          left: boundingRect.right - 300,
        });
      } else {
        setPosition({
          top: "50%",
          left: "50%",
        });
      }

      setMode(CopilotMode.coachinghistory);
    }
  };

  function handleClose() {
    if (mode === CopilotMode.coachinghistory) {
      setMode(CopilotMode.default);
      cleanUpAnnotation();
    }
  }

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <motion.button
          disabled={loading || searchLoading}
          className={clsx("relative bg-transparent mt-[7px] ml-1", {
            "opacity-40": disabled,
          })}
          ref={anchorEl}
          onClick={handleToggle}
          animate="animate"
          whileHover="hover"
          variants={{
            hover: {
              color: "#ffffff",
              translateY: -4,
              transition: { duration: 0.2 },
            },
          }}
          transition={{
            duration: 0.8,
          }}
          style={{
            color: "#925EFF",
          }}
        >
          <svg
            width="19"
            height="20"
            viewBox="0 0 19 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_2849_32629)">
              <path
                d="M10.293 2.875C6.35839 2.875 3.16797 6.06542 3.16797 10H0.792969L3.87255 13.0796L3.92797 13.1904L7.1263 10H4.7513C4.7513 6.93625 7.22922 4.45833 10.293 4.45833C13.3567 4.45833 15.8346 6.93625 15.8346 10C15.8346 13.0638 13.3567 15.5417 10.293 15.5417C8.76505 15.5417 7.37964 14.9163 6.38214 13.9108L5.25797 15.035C6.54839 16.3254 8.32172 17.125 10.293 17.125C14.2276 17.125 17.418 13.9346 17.418 10C17.418 6.06542 14.2276 2.875 10.293 2.875ZM9.5013 6.83333V10.7917L12.8896 12.8025L13.4596 11.8446L10.6888 10.1979V6.83333H9.5013Z"
                fill="currentColor"
              />
            </g>
            <defs>
              <clipPath id="clip0_2849_32629">
                <rect
                  width="19"
                  height="19"
                  fill="white"
                  transform="translate(0 0.5)"
                />
              </clipPath>
            </defs>
          </svg>
          <motion.span
            className="absolute text-xs text-white w-[100px] -left-10 top-[20px] opacity-0 font-barlow"
            variants={{
              hover: {
                opacity: 1,
              },
            }}
          >
            Coaching History
          </motion.span>
        </motion.button>

        {open && (
          <Portal>
            <Draggable>
              <div
                className="bg-black/[.38] rounded-lg absolute z-20 cursor-move"
                style={{
                  ...position,
                  marginTop: 50,
                }}
              >
                <div
                  className={clsx(
                    "m-[10px] p-[10px] bg-[#181818] rounded flex flex-col gap-[13px] max-h-[500px] w-[240px]"
                  )}
                >
                  <CopilotPopoverHeader
                    icon={<DragIndicatorIcon className="text-[#878787]" />}
                    title="Coaching History"
                    onClose={handleClose}
                  />
                  {loading && (
                    <div className="flex items-center justify-center p-4">
                      <CircularProgress />
                    </div>
                  )}
                  {!loading && !defaultState && (
                    <>
                      <div className="flex items-center justify-between gap-2">
                        <CopilotNavigationButton
                          disabled={index - 1 < 0}
                          onClick={async () => {
                            const prevItem = annotations[index - 1];

                            await getHistoryStill(
                              prevItem.url || "",
                              prevItem.timestamp
                            );

                            setAnnotation(prevItem.id);
                          }}
                        />
                        <div className="flex flex-col items-center justify-center">
                          <Typography className="font-bold text-base text-white font-barlow">
                            {tzFormat(zonedTime, "h:mm:ss a", {
                              timeZone: timezone,
                            })}
                          </Typography>
                          <Typography className="text-sm sm:text-base leading-none text-[#B8B8B8] font-barlow">
                            {tzFormat(zonedTime, "PP", { timeZone: timezone })}
                          </Typography>
                        </div>
                        <CopilotNavigationButton
                          flip
                          disabled={index + 2 > count}
                          onClick={async () => {
                            const nextItem = annotations[index + 1];

                            await getHistoryStill(
                              nextItem.url || "",
                              nextItem.timestamp
                            );

                            setAnnotation(nextItem.id);
                          }}
                        />
                      </div>
                      <Typography className="text-center font-barlow font-normal text-[13px] text-[#929295]">
                        <strong>{index + 1}</strong> of {count} Annotation
                        Frames
                      </Typography>
                    </>
                  )}
                  {!loading && defaultState && <CopilotHistoryEmptyState />}
                  <Typography className="font-barlow text-[13px] text-center text-[#929295]">
                    Coaching history displays all labels you have coached, such
                    as through adding, renaming, or pinning
                  </Typography>
                </div>
              </div>
            </Draggable>
          </Portal>
        )}
      </div>
    </ClickAwayListener>
  );
}
