import { Box, CircularProgress, Typography } from "@mui/material";
import clsx from "clsx";
import { makeStyles } from "tss-react/mui";

import { useKioskInfoQuery } from "@/generated-models";

const useStyles = makeStyles()((theme) => ({
  baseText: {
    color: "rgba(255,255,255,0.5)",
    fontSize: 18,
  },
}));

export default function KioskInfo({ pageName }: { pageName?: string }) {
  const { data } = useKioskInfoQuery();
  const { classes } = useStyles();

  if (!data?.kioskInfo) {
    return <CircularProgress />;
  }
  const kiosk = data.kioskInfo;

  return (
    <Box>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Typography
          variant="h5"
          component="h5"
          style={{ color: "white", fontWeight: "bold" }}
          className={clsx(classes.baseText)}
        >
          {kiosk.videoWall
            ? kiosk.videoWall.name
            : kiosk.name && kiosk.name !== ""
            ? kiosk.name
            : kiosk.code}
          {pageName && (
            <>
              &nbsp;&middot;&nbsp;
              <span style={{ fontWeight: 300, opacity: 0.7 }}>{pageName}</span>
            </>
          )}
        </Typography>
        <Box
          mx={1}
          width={12}
          height={12}
          style={{
            background: "#67C21B",
            borderRadius: 6,
          }}
        />
      </Box>

      {(kiosk.videoWall || kiosk.name !== kiosk.code) && (
        <Typography
          variant="h5"
          component="h5"
          style={{
            color: "white",
            opacity: 0.5,
            fontSize: 12,
          }}
          className={clsx(classes.baseText)}
        >
          {kiosk.videoWall && kiosk.name ? kiosk.name : `ID: ${kiosk.code}`}
        </Typography>
      )}
    </Box>
  );
}
