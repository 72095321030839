import { Typography } from "@mui/material";
import clsx from "clsx";
import { ReactNode } from "react";

import { useMe } from "@/components/Auth";

import { useDetailsRouteInfo } from "./useDetailsRouteInfo";

export function UserManagementField({
  label,
  value,
  editValue,
  classes,
  editSelfField = false,
}: {
  label: ReactNode;
  value: ReactNode;
  editValue?: ReactNode;
  classes?: {
    label?: string;
    value?: string;
  };
  editSelfField?: boolean;
}) {
  const me = useMe();
  const { id } = useDetailsRouteInfo();
  const permissions = me?.organization.permissions;
  const canManageUsers = permissions?.users_manage ?? false;
  const editingSelf = id === me?.orgUserId;
  const canManageField = canManageUsers || (editSelfField && editingSelf);

  const valueWrapper: {
    Component: React.ElementType<any>;
    props: any;
  } =
    typeof value === "string"
      ? {
          Component: Typography,
          props: { variant: "body1" },
        }
      : { Component: "div", props: {} };

  return (
    <div className="flex flex-col">
      <Typography
        variant="caption"
        className={clsx(
          "font-light text-gray-75 leading-normal",
          classes?.label
        )}
      >
        {label}
      </Typography>
      {Boolean(editValue) && canManageField ? (
        editValue
      ) : (
        <valueWrapper.Component
          {...valueWrapper.props}
          className={clsx("leading-normal", classes?.value)}
        >
          {value}
        </valueWrapper.Component>
      )}
    </div>
  );
}
