import { useInterpret, useSelector } from "@xstate/react";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
} from "react";
import {
  assign,
  interpret,
  Machine,
  MachineConfig,
  Subscribable,
} from "xstate";

import { usePlayerSelector } from "@/components/Player/playerMachine";

type MachineContext = typeof initialContext;
type MachineEvent = SetSettingEvent | { type: PlayerControlsEvent };

let rehydratedPlayerSetings = {
  //   playbackRate: Number(localStorage.playbackRate) || 1, // rate, 1 = normal
  quality: "auto",
};
if (localStorage.playerSettings) {
  try {
    rehydratedPlayerSetings = JSON.parse(localStorage.playerSettings);
  } catch (e) {
    console.error("Unable to parse player settings");
  }
}

const initialContext = {
  settings: rehydratedPlayerSetings,
};
type PlayerSetting = keyof typeof initialContext["settings"];
export enum PlayerControlsEvent {
  SHOW_CONTROLS = "SHOW_CONTROLS",
  HIDE_CONTROLS = "HIDE_CONTROLS",

  TOGGLE_SAVE_SHARE_TRAY = "TOGGLE_SAVE_SHARE_TRAY",

  // Settings
  CLOSE_SETTINGS_MENU = "CLOSE_SETTINGS_MENU",
  TOGGLE_SETTINGS_MENU = "TOGGLE_SETTINGS_MENU",
  OPEN_PLAYBACK_RATE_SETTING = "OPEN_PLAYBACK_RATE_SETTING",
  OPEN_QUALITY_SETTING = "OPEN_QUALITY_SETTING",
  CLOSE_SETTINGS_ITEM = "CLOSE_SETTINGS_ITEM",
  SET_SETTING = "SET_SETTING",
}

interface SetSettingEvent<Setting extends PlayerSetting = PlayerSetting> {
  type: PlayerControlsEvent.SET_SETTING;
  setting: Setting;
  value: typeof initialContext["settings"][Setting];
}

interface PlayerSettingsSchema {
  states: {
    root: {};
    playbackRate: {};
    quality: {};
  };
}

const playerSettings: MachineConfig<
  MachineContext,
  PlayerSettingsSchema,
  MachineEvent
> = {
  on: {
    CLOSE_SETTINGS_MENU: "#playerControls.shown",
    TOGGLE_SETTINGS_MENU: "#playerControls.shown",
  },
  initial: "root",
  states: {
    root: {
      on: {
        OPEN_PLAYBACK_RATE_SETTING: "playbackRate",
        OPEN_QUALITY_SETTING: "quality",
      },
    },
    playbackRate: {
      on: {
        CLOSE_SETTINGS_ITEM: "root",
      },
    },
    quality: {
      on: {
        CLOSE_SETTINGS_ITEM: "root",
      },
    },
  },
};

export function usePlayerSettingsOpened() {
  return usePlayerControlsSelector((state) =>
    state.matches("shown.playerSettings")
  );
}

export function useSetPlayerSetting() {
  const { send } = usePlayerControlsService();
  return useCallback(
    <Setting extends PlayerSetting = any>(
      setting: Setting,
      value: typeof initialContext["settings"][Setting]
    ) =>
      send({
        type: PlayerControlsEvent.SET_SETTING,
        setting,
        value,
      }),
    [send]
  );
}

export function useMatchesPlayerSettingsList() {
  return usePlayerControlsSelector((state) =>
    ["shown.root", "shown.playerSettings.root"].some((m) => state.matches(m))
  );
}
export function useMatchesPlayerSettingsItem(
  setting: keyof PlayerSettingsSchema["states"]
) {
  return usePlayerControlsSelector((state) =>
    state.matches("shown.playerSettings." + setting)
  );
}
export function useQuality() {
  return usePlayerControlsSelector((state) => state.context.settings.quality);
}

export function useSaveShareTrayOpen() {
  return usePlayerControlsSelector((state) =>
    state.matches("shown.saveShareTray")
  );
}

interface PlayerControlsSchema {
  states: {
    hidden: {};
    shown: {
      states: {
        root: {};
        playerSettings: PlayerSettingsSchema;
        saveShareTray: {};
      };
    };
  };
}

const playerControls = Machine<
  MachineContext,
  PlayerControlsSchema,
  MachineEvent
>({
  id: "playerControls",
  initial: "hidden",
  context: initialContext,
  on: {
    SET_SETTING: {
      actions: [
        assign({
          settings: (context, event: SetSettingEvent) => ({
            ...context.settings,
            [event.setting]: event.value,
          }),
        }),
        (context) => {
          localStorage.playerSettings = JSON.stringify(context.settings);
        },
      ],
    },
  },
  states: {
    hidden: {
      on: {
        SHOW_CONTROLS: "shown",
      },
    },
    shown: {
      initial: "root",
      states: {
        root: {
          on: {
            SHOW_CONTROLS: "#playerControls.shown",
            HIDE_CONTROLS: "#playerControls.hidden",

            TOGGLE_SETTINGS_MENU: "playerSettings",
            TOGGLE_SAVE_SHARE_TRAY: "saveShareTray",

            OPEN_PLAYBACK_RATE_SETTING: "playerSettings.playbackRate",
            OPEN_QUALITY_SETTING: "playerSettings.quality",
          },
          after: {
            3000: { target: "#playerControls.hidden" },
          },
        },
        playerSettings,
        saveShareTray: {
          on: {
            TOGGLE_SAVE_SHARE_TRAY: "#playerControls.shown",
          },
        },
      },
    },
  },
});

// Should never be used, except for type inference and checking that a parent
// provider exists with a machine _other_ than this default one.
const defaultMachine = interpret(playerControls);
const PlayerControlsMachineContext = createContext(defaultMachine);

export type PlayerControlsProviderProps = PropsWithChildren<{
  override?: boolean;
}>;
export function PlayerControlsProvider({
  override,
  ...props
}: PlayerControlsProviderProps) {
  const ancestorStore = useContext(PlayerControlsMachineContext);
  if (override === false && ancestorStore !== defaultMachine) {
    return <>{props.children}</>;
  }
  return <PlayerControlsProviderInner {...props} />;
}

function PlayerControlsProviderInner({ children }: PropsWithChildren<{}>) {
  const service = useInterpret(playerControls, {
    devTools: localStorage.xstateDev === "true",
  });
  return (
    <PlayerControlsMachineContext.Provider value={service}>
      {children}
    </PlayerControlsMachineContext.Provider>
  );
}

export function usePlayerControlsService() {
  return useContext(PlayerControlsMachineContext);
}

export function usePlayerControlsSelector<
  T,
  TActor = typeof service,
  TEmitted = TActor extends Subscribable<infer Emitted> ? Emitted : never
>(selector: (emitted: TEmitted) => T) {
  const service = usePlayerControlsService();
  return useSelector(service, selector);
}

export function usePlayerControlsShown() {
  const controlsShown = usePlayerControlsSelector((state) =>
    state.matches("shown")
  );
  const playbackComplete = usePlayerSelector((state) =>
    state.matches("playback.playingState.complete")
  );
  return controlsShown || playbackComplete;
}

export function useMobileOverlayShown() {
  const controlsShown = usePlayerControlsSelector((state) =>
    state.matches("shown")
  );
  const playbackComplete = usePlayerSelector((state) =>
    state.matches("playback.playingState.complete")
  );
  return controlsShown && !playbackComplete;
}
