import { Check } from "@mui/icons-material";
import { Fade, OutlinedInput, Typography } from "@mui/material";
import { useState } from "react";

import DrawZone from "@/components/Zones/DrawZone";

import { AnnotationLabelState } from "@/generated-models";

import { useCreateAnnotationLabel } from "../../../copilotAnnotationQueryHooks";
import { useUniversalSearchByImage } from "../../../copilotQueryHooks";
import { CopilotMode, useCopilotContext } from "../../../useCopilotContext";
import { CopilotFormButton, CopilotPinButton } from "./CopilotFormButton";

export function CreateLabelForm() {
  const [value, setValue] = useState("");
  const [pinned, setPinned] = useState(false);
  const { id } = useUniversalSearchByImage();
  const { create, loading } = useCreateAnnotationLabel(id!);
  const {
    editingShape,
    setEditingShape,
    setMode,
    imageDataURL,
  } = useCopilotContext();

  const disabled = value.length === 0 || loading;

  function reset() {
    setMode(CopilotMode.default);
    setEditingShape([]);
    setValue("");
    setPinned(false);
  }

  return (
    <Fade in>
      <div className="flex items-center justify-center h-full w-full">
        <div
          className="p-[7px] bg-black/30 rounded-t-3xl rounded-b-[18px] z-[2] w-[275px]"
          style={{
            boxShadow:
              "0px 8px 17px 0px rgba(0, 0, 0, 0.29), 0px 31px 31px 0px rgba(0, 0, 0, 0.26), 0px 69px 42px 0px rgba(0, 0, 0, 0.15), 0px 123px 49px 0px rgba(0, 0, 0, 0.04), 0px 192px 54px 0px rgba(0, 0, 0, 0.01)",
          }}
        >
          <div className="rounded-t-[18px] rounded-b-xl flex flex-col gap-[7px] bg-black/[0.55] p-[7px]">
            <div className="p-3 bg-[#151515] rounded-lg flex flex-col gap-3">
              <div className="flex items-center justify-center gap-1">
                <Check className="text-[#3BB16A]" />
                <Typography className="text-white font-barlow text-sm leading-normal">
                  Object created
                </Typography>
              </div>

              <div className="relative z-[2]">
                <DrawZone
                  shape={editingShape}
                  setShape={() => {}}
                  editing={false}
                  enableEdit={false}
                  setEditing={() => {}}
                />
                <img
                  className="rounded-lg"
                  alt="preview"
                  src={imageDataURL || ""}
                />
              </div>
              <Typography className="text-left text-white opacity-50 font-barlow leading-normal">
                Name this object
              </Typography>
              <OutlinedInput
                size="small"
                className="bg-black border border-solid border-primary shadow-[0_0_9px_0_#007CE4] rounded-[26.91px] h-[26px]"
                classes={{
                  input:
                    "text-white font-barlow text-sm tracking-[1.26px] uppercase",
                }}
                placeholder="Object Name"
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onKeyUp={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
            </div>
            <div className="flex justify-center">
              <CopilotPinButton
                className="!bg-black"
                pinned={pinned}
                onClick={() => {
                  setPinned((p) => !p);
                }}
              />
            </div>
            <div className="flex w-full gap-2">
              <CopilotFormButton
                variant="primary"
                className="flex-1"
                disabled={disabled}
                onMouseDown={async () => {
                  try {
                    await create({
                      shape: editingShape,
                      title: value,
                      labelState: pinned
                        ? AnnotationLabelState.Pinned
                        : AnnotationLabelState.Confirmed,
                    });
                    reset();
                  } catch {
                    // no op
                  }
                }}
              >
                Continue
              </CopilotFormButton>
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
}
