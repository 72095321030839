import { HelpOutline, KeyboardArrowDown } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import BackIcon from "@mui/icons-material/ChevronLeft";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import {
  Button,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Popover,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useMeasure } from "@react-hookz/web";
import clsx from "clsx";
import { useField } from "formik";
import gql from "graphql-tag";
import { capitalize } from "lodash";
import { omit } from "lodash/fp";
import { useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { concat } from "@/util/apolloCache";
import { isTouchEnabled } from "@/util/isTouchEnabled";
import { useBreakpoints } from "@/util/useBreakpoints";
import { MuxConfiguration } from "@/util/useMuxMetadata";

import { CountDirection } from "@/pages/Intelligence/constants";

import { NonInteractivePlayer } from "@/components/Player/BasicPlayer";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import DrawZone, {
  DrawLine,
  MobileDrawZone,
} from "@/components/Zones/DrawZone";
import ZoneInUseDialog from "@/components/Zones/ZoneInUseDialog";
import { DrawExample, ZoneIcon } from "@/components/Zones/ZoneSvgs";
import {
  getNormalizedShape,
  getTransformedShape,
} from "@/components/Zones/drawingUtils";
import { Point } from "@/components/Zones/getSectorsForPolygon";

import {
  CameraFeeds,
  IntDashboardCamDocument,
  useAddFocusMutation,
  useDeleteFocusMutation,
  useUpdateFocusMutation,
} from "@/generated-models";

export enum ZoneModalMode {
  Select,
  Edit,
  Alert,
  Create,
}

interface CreateTempZoneProps {
  snapshotSource: string;
  setZone: (shape: Point[]) => void;
  initialShape?: Point[];
  onCreate: () => void;
  close: () => void;
}

export function CreateTempZone({
  snapshotSource,
  setZone,
  initialShape = [],
  onCreate,
  close,
}: CreateTempZoneProps) {
  const [shape, setShape] = useState(initialShape);
  const [editing, setEditing] = useState(!initialShape.length);

  return (
    <div className="bg-[#323232] text-white">
      <div className="flex items-center gap-2 px-4 py-2">
        <ZoneIcon />
        <Typography variant="h2" className="font-medium text-lg">
          Draw a New Zone
        </Typography>
        <IconButton
          onClick={() => close()}
          size="small"
          edge="end"
          className="text-[#d4d4d4] ml-auto"
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div className="flex bg-black">
        <div className="relative m-auto">
          <img
            src={snapshotSource}
            alt="Camera snapshot"
            className="max-h-[500px]"
          />
          <DrawZone
            shape={shape}
            setShape={setShape}
            editing={editing}
            setEditing={setEditing}
          />
        </div>
      </div>
      <div className="flex p-4">
        <div className="px-4 mr-4 shrink-0">
          <DrawExample />
        </div>
        <div>
          <div>
            <strong>
              Click anywhere on the video to start drawing your zone.
            </strong>{" "}
            Connect the dots into completed shapes. Click on an existing dot to
            remove it.
          </div>
          <div className="flex mt-2 gap-2">
            <Button
              variant="outlined"
              className="text-white border-white "
              onClick={() => {
                setShape([]);
                if (!editing) setEditing(true);
              }}
            >
              Clear
            </Button>
            <Button
              variant="outlined"
              className="ml-auto text-white border-white "
              onClick={close}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={clsx({ "bg-gray-44 text-gray-400": editing })}
              disabled={editing}
              onClick={() => {
                setZone(shape);
                onCreate();
                close();
              }}
            >
              Apply
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

interface CreateSavedZoneProps {
  cameraId: number;
  snapshotSource: string;
  feeds?: Pick<CameraFeeds, "tunnel" | "local">;
  muxConfig?: MuxConfiguration;
  setZoneId: (id: number) => void;
  close: () => void;
}

export function CreateSavedZone({
  cameraId,
  snapshotSource,
  feeds,
  muxConfig,
  setZoneId,
  close,
}: CreateSavedZoneProps) {
  const { fitsDesktop } = useBreakpoints();
  const [shape, setShape] = useState<Point[]>([]);
  const [editing, setEditing] = useState(true);
  const [name, setName] = useState("");
  const { pushSnackbar } = useFeedback();
  const [addFocus, { loading: saving }] = useAddFocusMutation({
    variables: { cameraId, input: { name, shape } },
    update: (cache, { data }) => {
      if (!data) return;
      cache.modify({
        id: `Camera:${cameraId}`,
        fields: {
          focusZones: concat({
            __ref: `Focus:${data.addFocus.id}`,
          }),
        },
      });
    },
    onCompleted: (data) => {
      close();
      setZoneId(data.addFocus.id);
      pushSnackbar("Successfully created zone", FeedbackType.Success);
    },
    onError: (e) => {
      console.error(e);
      pushSnackbar(
        "Unable to create Zone, please try again later",
        FeedbackType.Error
      );
    },
  });

  return (
    <div className="md:bg-[#323232] md:text-white">
      <div className="flex justify-between md:justify-start flex-row-reverse md:flex-row items-center gap-2 px-4 py-2">
        <ZoneIcon className="md:visible invisible" />
        <Typography variant="h2" className="font-medium text-lg">
          Create a New Zone
        </Typography>
        <IconButton
          onClick={() => close()}
          size="small"
          edge="end"
          className="text-[#d4d4d4] md:ml-auto p-0 md:p-[5px]"
        >
          {fitsDesktop ? (
            <CloseIcon />
          ) : (
            <BackIcon fontSize="large" color="primary" />
          )}
        </IconButton>
      </div>
      <div className="relative">
        {feeds ? (
          <div className="w-full">
            <NonInteractivePlayer
              showingLivestream
              sources={feeds}
              poster={snapshotSource}
              muxConfig={muxConfig}
              forceMuted
            />
          </div>
        ) : (
          <img src={snapshotSource} alt="Camera snapshot" className="w-full" />
        )}
        <DrawZone
          shape={shape}
          setShape={setShape}
          editing={editing}
          setEditing={setEditing}
        />
      </div>
      <div className="flex p-4">
        {fitsDesktop && (
          <div className="px-4 mr-4 shrink-0 md:block hidden">
            <DrawExample />
          </div>
        )}
        <div className="w-full md:w-[unset]">
          <div className="md:block hidden">
            <strong>
              Click anywhere on the video to start drawing your zone.
            </strong>{" "}
            Connect the dots into completed shapes. Click on an existing dot to
            remove it.
          </div>
          <div className="md:hidden block">
            Drag individual dots to adjust the shape of your zone, or tap and
            drag the whole shape to move it around.
          </div>
          <div className="flex md:flex-row flex-col items-end mt-2 gap-5 md:gap-2">
            {!fitsDesktop && (
              <TextField
                className="w-full"
                label="Zone Name"
                size="small"
                disabled={saving}
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            )}
            <Button
              color={fitsDesktop ? "inherit" : "primary"}
              variant="outlined"
              className="md:text-white md:border-white md:w-[unset] w-full"
              onClick={() => {
                setShape([]);
                if (!editing) setEditing(true);
              }}
            >
              Clear
            </Button>
            <Button
              color={fitsDesktop ? "inherit" : "primary"}
              variant="outlined"
              className="ml-auto md:text-white md:border-white md:w-[unset] w-full"
              onClick={close}
            >
              Cancel
            </Button>
            {fitsDesktop && <div className="grow" />}
            {fitsDesktop && (
              <TextField
                label="Zone Name"
                size="small"
                InputProps={{ className: "text-white before:border-white" }}
                InputLabelProps={{ className: "text-gray-400" }}
                disabled={saving}
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            )}
            <Tooltip
              title={
                !name
                  ? "Enter zone name to save"
                  : editing
                  ? "Complete the shape to save"
                  : ""
              }
            >
              <span className="w-full md:w-[unset]">
                <Button
                  variant="contained"
                  color="primary"
                  className={clsx({
                    "bg-gray-44 text-gray-400 ": !name || editing || saving,
                    "w-full": !fitsDesktop,
                  })}
                  disabled={!name || editing || saving}
                  onClick={() => addFocus()}
                >
                  Save Zone
                </Button>
              </span>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
}

interface SelectZoneProps {
  snapshotSource: string;
  feeds?: Pick<CameraFeeds, "tunnel" | "local">;
  muxConfig?: MuxConfiguration;
  activeZones: { id: number; name: string; shape: Point[] }[];
  setZoneIds: (id: number[]) => void;
  zones: { id: number; name: string; shape: { x: number; y: number }[] }[];
  editZone: () => void;
  createNew: () => void;
  close: () => void;
  multi?: boolean;
}

export function SelectZones({
  snapshotSource,
  feeds,
  muxConfig,
  activeZones,
  setZoneIds,
  zones,
  editZone,
  createNew,
  close,
  multi = false,
}: SelectZoneProps) {
  const { fitsDesktop } = useBreakpoints();
  const { pushSnackbar } = useFeedback();
  const [zoneInUseDialogOpen, setZoneInUseDialogOpen] = useState(false);

  const [deleteFocus, { loading: deleting }] = useDeleteFocusMutation({
    onCompleted: (data) => {
      const otherZones = zones.filter(({ id }) => id !== data.deleteFocus);
      if (otherZones.length > 0) {
        setZoneIds([otherZones[0].id]);
      } else {
        setZoneIds([]);
        close();
      }
      pushSnackbar("Successfully deleted zone", FeedbackType.Success);
    },
    onError: (e) => {
      console.error(e);
      if (e.message === "Used by resource") {
        setZoneInUseDialogOpen(true);
      } else {
        pushSnackbar(
          "Something went wrong while deleting the zone, please try again",
          FeedbackType.Error
        );
      }
    },
  });

  const filteredZones = useMemo(() => zones.filter((z) => z.shape.length > 2), [
    zones,
  ]);

  return (
    <div className="md:bg-[#323232] md:text-white">
      <div className="flex justify-between md:justify-start flex-row-reverse md:flex-row items-center gap-2 px-4 py-2">
        <ZoneIcon className="md:visible invisible" />
        <Typography variant="h2" className="font-medium text-lg">
          Select a Zone
        </Typography>
        <IconButton
          onClick={() => close()}
          size="small"
          edge="end"
          className="text-[#d4d4d4] md:ml-auto p-0 md:p-[5px]"
        >
          {fitsDesktop ? (
            <CloseIcon />
          ) : (
            <BackIcon fontSize="large" color="primary" />
          )}
        </IconButton>
      </div>
      <div className="relative">
        {feeds ? (
          <div className="w-full">
            <NonInteractivePlayer
              sources={feeds}
              poster={snapshotSource}
              muxConfig={muxConfig}
              forceMuted
            />
          </div>
        ) : (
          <img src={snapshotSource} alt="Camera snapshot" className="w-full" />
        )}
        {activeZones.map((activeZone) => (
          <DrawZone
            key={activeZone.id}
            shape={activeZone.shape}
            setShape={() => {}}
            editing={false}
            setEditing={() => {}}
            enableEdit={false}
          />
        ))}
      </div>

      <div className="flex md:flex-row flex-col gap-5 md:gap-3 items-center p-4">
        <FormControl
          variant="outlined"
          size="small"
          className="md:w-[unset] w-full"
        >
          <InputLabel id="zone-input-label" className="text-[#bdbdbd]">
            {multi ? "Zones" : "Zone"}
          </InputLabel>
          <Select
            labelId="zone-input-label"
            value={activeZones.map((z) => z.id)}
            size="small"
            multiple={multi}
            onChange={(e) => {
              const newValue = e.target.value;
              if (Array.isArray(newValue)) {
                setZoneIds(newValue);
              } else if (typeof newValue === "number") {
                setZoneIds([newValue]);
              } else {
                console.error("unexpected format for focus id:", newValue);
              }
            }}
            input={<OutlinedInput label="Select Zone" />}
            className="min-w-[200px] font-medium"
            renderValue={() =>
              activeZones.length > 0
                ? activeZones.map((z) => z.name).join(", ")
                : "Zone"
            }
          >
            {filteredZones.map((zone) => (
              <MenuItem value={zone.id} key={zone.id}>
                {multi && (
                  <Checkbox
                    checked={activeZones.some((z) => z.id === zone.id)}
                  />
                )}
                {zone.name}
                {!fitsDesktop && (
                  <div className="ml-auto">
                    <IconButton
                      className="text-[#bdbdbd] border-[#bdbdbd] p-[9px] min-w-0"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        editZone();
                      }}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      edge="end"
                      className="text-[#bdbdbd] border-[#bdbdbd] p-[9px] min-w-0"
                      disabled={deleting}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        deleteFocus({
                          variables: { focusId: activeZones[0].id },
                          update(cache, { data }) {
                            if (!data) return;
                            cache.evict({ id: `Focus:${data.deleteFocus}` });
                          },
                        });
                      }}
                    >
                      {deleting ? (
                        <CircularProgress size={20} />
                      ) : (
                        <DeleteIcon fontSize="small" />
                      )}
                    </IconButton>
                  </div>
                )}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {!fitsDesktop && (
          <>
            <Button
              className="w-full bg-[#DBEDFB] border-[#C9E4F9]"
              color="primary"
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => createNew()}
            >
              New Zone
            </Button>
            <Divider
              orientation="horizontal"
              className="bg-[#E0E0E0] h-[1px] w-full mx-2"
            />
          </>
        )}
        {activeZones.length === 1 && fitsDesktop && (
          <>
            <Tooltip title="Edit zone">
              <Button
                variant="outlined"
                className="text-[#bdbdbd] border-[#bdbdbd] p-[9px] min-w-0"
                onClick={() => editZone()}
              >
                <EditIcon fontSize="small" />
              </Button>
            </Tooltip>
            <Tooltip title="Delete zone">
              <Button
                variant="outlined"
                className="text-[#bdbdbd] border-[#bdbdbd] p-[9px] min-w-0"
                disabled={deleting}
                onClick={() => {
                  deleteFocus({
                    variables: { focusId: activeZones[0].id },
                    update(cache, { data }) {
                      if (!data) return;
                      cache.evict({ id: `Focus:${data.deleteFocus}` });
                    },
                  });
                }}
              >
                {deleting ? (
                  <CircularProgress size={20} />
                ) : (
                  <DeleteIcon fontSize="small" />
                )}
              </Button>
            </Tooltip>
          </>
        )}
        {fitsDesktop && (
          <Divider orientation="vertical" className="bg-[#bdbdbd] h-7 mx-2" />
        )}
        {fitsDesktop && (
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => createNew()}
          >
            New Zone
          </Button>
        )}
        {!fitsDesktop && (
          <Button
            variant="outlined"
            color="primary"
            className="ml-auto w-full md:w-[unset]"
            onClick={() => close()}
          >
            Cancel
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          className="ml-auto w-full md:w-[unset]"
          onClick={() => close()}
        >
          Confirm
        </Button>
      </div>
      <ZoneInUseDialog
        open={zoneInUseDialogOpen}
        onClose={() => setZoneInUseDialogOpen(false)}
      />
    </div>
  );
}

interface EditZoneProps {
  zone: { id: number; name: string; shape: Point[] };
  snapshotSource: string;
  feeds?: Pick<CameraFeeds, "tunnel" | "local">;
  muxConfig?: MuxConfiguration;
  close: () => void;
}

export function EditZone({
  zone,
  snapshotSource,
  feeds,
  muxConfig,
  close,
}: EditZoneProps) {
  const { fitsDesktop } = useBreakpoints();
  const [shape, setShape] = useState(
    zone.shape.map(omit("__typename")) as Point[]
  );
  const [editing, setEditing] = useState(false);
  const [name, setName] = useState(zone.name);
  const { pushSnackbar } = useFeedback();
  const [updateFocus, { loading: saving }] = useUpdateFocusMutation({
    onCompleted: () => {
      pushSnackbar("Successfully saved zone", FeedbackType.Success);
      close();
    },
    onError: () =>
      pushSnackbar("Saving zone failed, please try again", FeedbackType.Error),
  });

  return (
    <div className="md:bg-[#323232] md:text-white">
      <div className="flex justify-between md:justify-start flex-row-reverse md:flex-row items-center gap-2 px-4 py-2">
        <ZoneIcon className="md:visible invisible" />
        <Typography variant="h2" className="font-medium text-lg">
          Edit a Zone
        </Typography>
        <IconButton
          onClick={() => close()}
          size="small"
          edge="end"
          className="text-[#d4d4d4] md:ml-auto p-0 md:p-[5px]"
        >
          {fitsDesktop ? (
            <CloseIcon />
          ) : (
            <BackIcon fontSize="large" color="primary" />
          )}
        </IconButton>
      </div>
      <div className="relative">
        {feeds ? (
          <div className="w-full">
            <NonInteractivePlayer
              showingLivestream
              sources={feeds}
              poster={snapshotSource}
              muxConfig={muxConfig}
              forceMuted
            />
          </div>
        ) : (
          <img src={snapshotSource} alt="Camera snapshot" className="w-full" />
        )}
        <DrawZone
          shape={shape}
          setShape={setShape}
          editing={editing}
          setEditing={setEditing}
        />
      </div>

      <div className="flex p-4">
        {fitsDesktop && (
          <div className="px-4 mr-4 shrink-0 md:block hidden">
            <DrawExample />
          </div>
        )}
        <div>
          <div className="md:block hidden">
            <strong>
              Click anywhere on the video to start drawing your zone.
            </strong>{" "}
            Connect the dots into completed shapes. Click on an existing dot to
            remove it.
          </div>
          <div className="md:hidden block">
            Drag individual dots to adjust the shape of your zone, or tap and
            drag the whole shape to move it around.
          </div>
          <div className="flex md:flex-row flex-col mt-2 gap-5 md:gap-2 items-end">
            <Button
              color={fitsDesktop ? "inherit" : "primary"}
              variant="outlined"
              className="md:text-white md:border-white md:w-[unset] w-full"
              onClick={() => {
                setShape([]);
                if (!editing) setEditing(true);
              }}
            >
              Clear
            </Button>
            {fitsDesktop && <div className="grow" />}
            <TextField
              className="w-full md:w-[unset]"
              label="Zone Name"
              size="small"
              InputProps={{
                className: fitsDesktop ? "text-white before:border-white" : "",
              }}
              InputLabelProps={{
                className: fitsDesktop ? "text-gray-400" : "",
              }}
              disabled={saving}
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
            <Tooltip
              title={
                !name
                  ? "Enter zone name to save"
                  : editing
                  ? "Complete the shape to save"
                  : ""
              }
            >
              <span className="w-full md:w-[unset]">
                <Button
                  variant="contained"
                  color="primary"
                  className={clsx({
                    "bg-gray-44 text-gray-400 ": !name || editing || saving,
                    "w-full": !fitsDesktop,
                  })}
                  disabled={!name || editing || saving}
                  onClick={() => {
                    if (name && shape.length > 2) {
                      updateFocus({
                        variables: { focusId: zone.id, input: { name, shape } },
                      });
                    }
                  }}
                >
                  Save Zone
                </Button>
              </span>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
}

interface MobileCreateEditZoneProps {
  mode: "create" | "edit";
  zone?: { id: number; name: string; shape?: Point[] };
  snapshotSource: string;
  feeds?: Pick<CameraFeeds, "tunnel" | "local">;
  muxConfig?: MuxConfiguration;
  close: () => void;
  setTempZone?: (shape: Point[]) => void;
}

const DefaultShape = [
  { x: 25, y: 25 },
  { x: 25, y: 50 },
  { x: 25, y: 75 },
  { x: 50, y: 75 },
  { x: 75, y: 75 },
  { x: 75, y: 50 },
  { x: 75, y: 25 },
  { x: 50, y: 25 },
] as Point[];

export function MobileCreateEditZone({
  mode,
  zone,
  snapshotSource,
  feeds,
  muxConfig,
  setTempZone,
  close,
}: MobileCreateEditZoneProps) {
  // const helpButtonEl = useRef(null);
  const [helpOpen, setHelpOpen] = useState(false);
  const { fitsDesktop } = useBreakpoints();
  const [tempShape, setTempShape] = useState(
    (zone?.shape?.map(omit("__typename")) as Point[]) ?? DefaultShape
  );
  const { pushSnackbar } = useFeedback();
  const [updateFocus, { loading: saving }] = useUpdateFocusMutation({
    onCompleted: () => {
      pushSnackbar("Successfully saved zone", FeedbackType.Success);
      close();
    },
    onError: () =>
      pushSnackbar("Saving zone failed, please try again", FeedbackType.Error),
  });
  const [measurements, ref] = useMeasure<HTMLDivElement>();

  return (
    <div className="md:bg-[#323232] md:text-white w-screen max-w-3xl">
      <div className="flex justify-between md:justify-start items-center gap-2 ">
        <IconButton
          onClick={() => close()}
          size="small"
          edge="end"
          className="text-[#d4d4d4] m-0 p-1"
        >
          <BackIcon color="primary" fontSize="large" />
        </IconButton>
        <Typography variant="h2" className="font-medium text-lg ">
          {mode === "edit" && "Edit a Zone"}
          {mode === "create" && "Create Zone"}
        </Typography>
        <IconButton
          onClick={() => setHelpOpen(true)}
          size="small"
          edge="end"
          className={clsx("m-0 px-4 h-[42px] rounded-none", {
            "text-text bg-[#F4F4F4] border-l border-t border-solid border-[#E1E1E1] rounded-tl": helpOpen,
            "text-[#d4d4d4]": !helpOpen,
          })}
          aria-describedby="help-button"
        >
          <HelpOutline />
          {helpOpen && <KeyboardArrowDown />}
        </IconButton>
        <Popover
          id="help-button"
          open={helpOpen}
          onClose={() => setHelpOpen(false)}
          anchorReference="anchorPosition"
          anchorPosition={{ top: 42, left: 0 }}
          marginThreshold={0}
          classes={{
            paper:
              "max-w-none w-screen rounded-none bg-[#F4F4F4] p-4 flex flex-col gap-4 border-x border-b border-[#E1E1E1] text-text before:block before:absolute before:inset-0 before:border-t before:border-[#E1E1E1] before:w-[calc(100%-80px)] shadow-none",
          }}
          BackdropProps={{
            invisible: true,
            classes: {
              root:
                "after:block after:absolute after:h-[calc(100%-42px)] after:top-[42px] after:w-full after:bg-black/50",
            },
          }}
        >
          <img
            src="mobiledraw.gif"
            alt="mobile draw help gif"
            className="w-full"
          />
          <Typography variant="body2">
            Drag individual dots to adjust the shape of your zone, or tap and
            drag the whole shape to move it around. Add more shapes to create
            separate zone sections.
          </Typography>
        </Popover>
      </div>
      <div className="relative" ref={ref}>
        {feeds ? (
          <div className="w-full">
            <NonInteractivePlayer
              showingLivestream
              sources={feeds}
              poster={snapshotSource}
              muxConfig={muxConfig}
              forceMuted
            />
          </div>
        ) : (
          <img src={snapshotSource} alt="Camera snapshot" className="w-full" />
        )}
        <MobileDrawZone
          shape={tempShape}
          setShape={setTempShape}
          backgroundRatio={
            measurements ? measurements.height / measurements.width : 0
          }
        />
      </div>

      <div className="flex flex-col p-4 gap-5 items-end w-full">
        <div className="flex gap-3 w-full">
          <Button
            color="inherit"
            variant="contained"
            className="bg-[#DBEDFB] text-primary w-full shadow-none"
            disabled={saving}
            onClick={() => {
              setTempShape(
                (zone?.shape?.map(omit("__typename")) as Point[]) ??
                  DefaultShape
              );
            }}
          >
            Reset
          </Button>
        </div>
        <Divider className="border-[#E9E9E9] w-full" />
        <Button
          color="primary"
          variant="outlined"
          className="w-full"
          disabled={saving}
          onClick={() => {
            if (mode === "create" && zone?.shape) {
              setTempZone?.(tempShape);
            }
            close();
          }}
        >
          Cancel
        </Button>

        <span className="w-full md:w-[unset]">
          <Button
            variant="contained"
            color="primary"
            className={clsx({
              "w-full": !fitsDesktop,
            })}
            disabled={saving}
            onClick={() => {
              if (mode === "create") {
                setTempZone?.(tempShape);
              }
              if (mode === "edit") {
                updateFocus({
                  variables: {
                    focusId: zone!.id,
                    input: { name: zone!.name, shape: tempShape },
                  },
                });
              }
              close();
            }}
          >
            Apply
          </Button>
        </span>
      </div>
    </div>
  );
}

interface CreateEditLineProps {
  zone?: { id: number; name: string; shape?: Point[] };
  snapshotSource: string;
  feeds?: Pick<CameraFeeds, "tunnel" | "local">;
  muxConfig?: MuxConfiguration;
  close: () => void;
  setZoneId: (zoneId: number) => void;
  cameraId?: number;
}

const DefaultLine = [
  { x: 20, y: 50 },
  { x: 80, y: 50 },
] as Point[];

export function CreateEditLine({
  cameraId,
  zone,
  snapshotSource,
  feeds,
  muxConfig,
  setZoneId,
  close,
  entity,
}: CreateEditLineProps & { entity: string | null }) {
  const [, { value: direction }, { setValue: setDirection }] = useField<
    CountDirection
  >("subtype");
  const [measurements, ref] = useMeasure<HTMLDivElement>();
  const [templine, setTempLine] = useState(
    (zone?.shape?.map(omit("__typename")) as Point[]) ??
      (isTouchEnabled() ? DefaultLine : [])
  );
  const { pushSnackbar } = useFeedback();
  const [addFocus, addState] = useAddFocusMutation({
    refetchQueries: [
      { query: IntDashboardCamDocument, variables: { id: cameraId } },
    ],
    update: (cache, { data }) => {
      if (!data) return;
      cache.modify({
        id: `Camera:${cameraId}`,
        fields: {
          focusZones: concat({
            __ref: `Focus:${data.addFocus.id}`,
          }),
        },
      });
    },
    onCompleted: (data) => {
      setZoneId(data.addFocus.id);
      pushSnackbar("Successfully saved line", FeedbackType.Success);
      close();
    },
    onError: () =>
      pushSnackbar("Saving line failed, please try again", FeedbackType.Error),
  });
  const [updateFocus, updateState] = useUpdateFocusMutation({
    refetchQueries: [
      { query: IntDashboardCamDocument, variables: { id: cameraId } },
    ],
    onCompleted: (data) => {
      setZoneId(data.updateFocus.id);
      pushSnackbar("Successfully saved line", FeedbackType.Success);
      close();
    },
    onError: () =>
      pushSnackbar("Saving line failed, please try again", FeedbackType.Error),
  });

  function saveLine() {
    if (templine.length !== 2) return;
    if (zone) {
      updateFocus({
        variables: {
          focusId: zone!.id,
          input: { name: zone!.name, shape: templine },
        },
      });
    } else {
      if (cameraId) {
        addFocus({
          variables: {
            cameraId,
            input: { name: `line-${uuidv4()}`, shape: templine },
          },
        });
      }
    }
  }

  return (
    <div className="sm:bg-black sm:text-white sm:rounded">
      <div className="grid grid-cols-[43px_auto_43px] sm:grid-cols-2 justify-between items-center gap-2 px-4 py-2">
        <IconButton
          onClick={() => close()}
          size="small"
          edge="end"
          className="text-[#d4d4d4] sm:ml-auto p-0 sm:p-[5px] sm:col-start-2 sm:row-start-1"
        >
          <CloseIcon className="hidden sm:block" />
          <BackIcon fontSize="large" color="primary" className="sm:hidden" />
        </IconButton>
        <Typography
          variant="h2"
          className="font-medium text-lg sm:col-start-1 sm:row-start-1"
        >
          Draw Count Line
        </Typography>
      </div>
      <div className="relative" ref={ref}>
        {feeds ? (
          <div className="w-full">
            <NonInteractivePlayer
              showingLivestream
              sources={feeds}
              poster={snapshotSource}
              muxConfig={muxConfig}
              forceMuted
            />
          </div>
        ) : (
          <img src={snapshotSource} alt="Camera snapshot" className="w-full" />
        )}

        {measurements && (
          <span>
            <DrawLine
              line={getTransformedShape(templine, measurements)}
              setLine={(line) =>
                setTempLine(getNormalizedShape(line, measurements))
              }
              measurements={measurements}
              countDirection={direction}
            />
          </span>
        )}
      </div>

      <div className="grid grid-rows-[repeat(5,auto)] grid-cols-1 sm:grid-rows-1 sm:grid-cols-[177px_minmax(min-content,384px)_193px] sm:justify-between sm:items-center p-4 gap-5 items-end w-full">
        <Typography
          variant="body1"
          className="leading-[19px] sm:col-start-2 sm:row-start-1 sm:text-center"
        >
          <span className="sm:font-bold sm:block">
            Create a line to count {entity} that pass through it.
          </span>{" "}
          <span className="sm:hidden">
            Tap and drag the dots to position the line. Tap the line to change
            the direction.
          </span>
          <span className="hidden sm:block">
            Click and drag to draw the line. Click the direction indicators to
            toggle the direction.
          </span>
        </Typography>
        <Divider className="border-[#E9E9E9] w-full sm:hidden" />
        <div className="flex flex-col gap-2 w-full sm:col-start-1">
          <Typography variant="body2" className="font-medium">
            {capitalize(entity || "")} Count Direction
          </Typography>
          <ButtonGroup fullWidth>
            <Button
              className={clsx("sm:w-fit", {
                "bg-[#007ce4] text-white": direction === CountDirection.In,
                "bg-[#F6FBFF] sm:bg-[#052E4F]": direction !== CountDirection.In,
              })}
              onClick={() => {
                setDirection(CountDirection.In);
              }}
            >
              In
            </Button>
            <Button
              className={clsx("sm:w-fit", {
                "bg-[#007ce4] text-white": direction === CountDirection.Out,
                "bg-[#F6FBFF] sm:bg-[#052E4F]":
                  direction !== CountDirection.Out,
              })}
              onClick={() => {
                setDirection(CountDirection.Out);
              }}
            >
              Out
            </Button>
            <Button
              className={clsx("whitespace-nowrap sm:w-fit", {
                "bg-[#007ce4] text-white":
                  direction === CountDirection.InAndOut,
                "bg-[#F6FBFF] sm:bg-[#052E4F]":
                  direction !== CountDirection.InAndOut,
              })}
              onClick={() => {
                setDirection(CountDirection.InAndOut);
              }}
            >
              In & Out
            </Button>
          </ButtonGroup>
        </div>
        <Divider className="border-[#E9E9E9] w-full sm:hidden" />
        <Button
          color="primary"
          variant="outlined"
          className="w-full sm:hidden"
          disabled={addState.loading || updateState.loading}
          onClick={close}
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          color="primary"
          className={clsx("w-full sm:col-start-3 sm:row-start-1")}
          classes={{
            disabled: "bg-[#007ce4] opacity-60",
          }}
          disabled={
            templine.length !== 2 || addState.loading || updateState.loading
          }
          onClick={saveLine}
        >
          Save
        </Button>
      </div>
    </div>
  );
}

gql`
  mutation addFocus($cameraId: Int!, $input: AddFocusInput!) {
    addFocus(cameraId: $cameraId, input: $input) {
      id
      name
      shape {
        x
        y
      }
    }
  }
`;

gql`
  mutation updateFocus($focusId: Int!, $input: UpdateFocusZoneInput!) {
    updateFocus(focusId: $focusId, input: $input) {
      id
      name
      shape {
        x
        y
      }
    }
  }
`;

gql`
  mutation deleteFocus($focusId: Int!) {
    deleteFocus(focusId: $focusId)
  }
`;
