import ReportIcon from "@mui/icons-material/Report";
import {
  Avatar,
  Box,
  capitalize,
  Chip,
  CircularProgress,
  CircularProgressProps,
  Divider,
  LinearProgress,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { utcToZonedTime } from "date-fns-tz";
import gql from "graphql-tag";
import { floor, round } from "lodash";
import { sortBy } from "lodash/fp";
import { useRef } from "react";
import FlipMove from "react-flip-move";

import { ReactComponent as EcoIcon } from "@/icons/motionfilter.svg";

import { timeFormatSecs } from "@/util/date";

import { IntelligenceLogoIcon } from "@/pages/Intelligence/IntelligenceIllustrations";
import { idToFilterConfigMap } from "@/pages/Search/intelligence/intelligence";
import {
  useCameraMotionZone,
  useSearchSubjects,
  useVodParam,
} from "@/pages/Search/searchHooks";

import {
  Appliance,
  Camera,
  Location,
  useLprBoundingBoxResultsQuery,
  useVodObjectActivityQuery,
} from "@/generated-models";

import { useMultiPlayerControls, useWallclockTime } from "../Player/PlayerBase";
import { useFocusedCam } from "../View/sharedViewHooks";
import { useFocusBox } from "./AiState";
import { usePrecenseAnalytics } from "./precenseHooks";

type ListingCamera = Pick<Camera, "id" | "name" | "status"> & {
  location: Pick<Location, "id" | "timezone">;
} & {
  appliance: Pick<Appliance, "serialNumber">;
};

export default function ThroughputPane({
  activeCameras,
}: {
  activeCameras: ListingCamera[];
}) {
  const { seek } = useMultiPlayerControls();
  const targetScrollRef = useRef<null | HTMLDivElement>(null);
  const [, setFocusedBoundingBox] = useFocusBox();
  const focusedCam = useFocusedCam(activeCameras);
  const { vodStart: startTime, vodEnd: endTime } = useVodParam();
  const searchSubjects = useSearchSubjects();
  const { activeShape } = useCameraMotionZone(focusedCam.id);
  const wallclockTimeDate = useWallclockTime();
  const wallclockTime = wallclockTimeDate?.getTime();
  const timezone = focusedCam.location.timezone;
  const presenceProgressRef = useRef<HTMLDivElement | null>(null);
  const {
    data,
    loading: throughputLoading,
    error: throughputError,
  } = useVodObjectActivityQuery({
    variables: {
      camId: focusedCam.id,
      startDatetime: startTime.toISOString(),
      endDatetime: endTime.toISOString(),
      objectTypes: searchSubjects!,
      searchArea: activeShape,
    },
    skip: searchSubjects === undefined,
    fetchPolicy: "cache-first", // Used for first execution
    nextFetchPolicy: "cache-only", // Used for subsequent executions
  });
  const {
    data: { presentTime, maxAbsence, maxPrecence, precenseIntervals },
    loading: precenseLoading,
    error: precenseError,
  } = usePrecenseAnalytics(
    focusedCam.id,
    startTime,
    endTime,
    searchSubjects,
    activeShape
  );
  const { data: lprdata } = useLprBoundingBoxResultsQuery({
    variables: {
      input: {
        id: focusedCam.id,
        startTime: startTime.toString(),
        endTime: endTime.toString(),
      },
    },
    onError: (error) => console.error(error.message),
  });

  const loading = throughputLoading || precenseLoading;
  const error = throughputError || precenseError;

  if (error) {
    return <>{error.toString()}</>;
  }
  if (!wallclockTime) {
    return <CircularProgress />;
  }

  const objects = sortBy(
    "sortkey",
    data?.camera.insights?.objectActivity?.objects
      .map(({ entered, exited, ...rest }) => {
        const enteredDate = new Date(entered);
        const exitedDate = new Date(exited);
        const enterTimeMs = enteredDate.getTime();
        const exitTimeMs = exitedDate.getTime();
        const hasNotSeen = wallclockTime < enterTimeMs;
        const hasEntered =
          enterTimeMs <= wallclockTime && wallclockTime <= exitTimeMs;
        const hasExited = exitTimeMs < wallclockTime;
        const percentDone =
          ((wallclockTime - enterTimeMs) / (exitTimeMs - enterTimeMs)) * 100;

        return {
          hasNotSeen,
          hasEntered,
          hasExited,
          entered: enteredDate,
          exited: exitedDate,
          percentDone,
          sortkey: `${
            hasNotSeen
              ? `${2}|${enterTimeMs}`
              : hasEntered
              ? `${1}|${pad((100 - percentDone).toFixed(0), 3)}`
              : `${0}|${exitTimeMs}`
          }`,
          objKey: `${rest.type}|${rest.objId}`,
          ...rest,
        };
      })
      .filter(
        ({ entered, exited }) =>
          (startTime.getTime() - 2000 <= entered.getTime() &&
            entered.getTime() <= endTime.getTime() + 2000) ||
          (startTime.getTime() - 2000 <= exited.getTime() &&
            exited.getTime() <= endTime.getTime() + 2000) ||
          (entered.getTime() <= startTime.getTime() &&
            endTime.getTime() <= exited.getTime())
      )
  );
  const targetElementIdx = objects.findIndex(({ hasEntered }) => hasEntered);

  const fullTime = endTime.getTime() - startTime.getTime();

  const allLicensePlateResults: String[] | undefined = lprdata
    ? lprdata?.lprBoundingBoxResults.results.map(
        ({ timestampMs, pts, xmin, ymin, xmax, ymax, plate, plate_score }) =>
          plate
      )
    : [];

  return (
    <div className="w-full h-full flex-col flex bg-slate-100">
      <div className="drop-shadow-2xl bg-gray-100 rounded-b-lg">
        <div className="p-3 pt-5 drop-shadow-lg bg-white rounded-b-lg transition-all">
          <div className="flex flex-row items-center">
            <IntelligenceLogoIcon className="h-8" />
            <div className="ml-1 flex-grow">
              <Typography variant="h4" className="leading-4">
                Video Intelligence
              </Typography>
              <Typography variant="caption" className="leading-4">
                {minuteTimeFormathm(
                  (endTime.getTime() - startTime.getTime()) / 1000
                )}{" "}
                clip
              </Typography>
            </div>
            {presentTime && (
              <div className="flex flex-row items-center justify-center">
                <Typography
                  variant="caption"
                  className="leading-3 text-right mr-2"
                >
                  % of Time
                  <br />
                  Objs Present
                </Typography>
                <CircularProgressWithLabel
                  value={(presentTime / fullTime) * 100}
                />
              </div>
            )}
          </div>

          {precenseIntervals && presentTime != null && (
            <>
              <Divider className="my-2" variant="middle" />
              <div className="flex flex-col">
                <div className="grid grid-cols-3 my-2">
                  <div className="flex flex-col">
                    <Typography
                      variant="h4"
                      color="primary"
                      className="text-2xl"
                    >
                      {minuteTimeFormathm(presentTime / 1000)}
                    </Typography>
                    <Typography variant="body1" className="leading-4 text-sm">
                      <strong>Total Time</strong> Present
                    </Typography>
                  </div>
                  {maxPrecence && (
                    <div
                      className="flex flex-col rounded-lg cursor-pointer hover:bg-white hover:drop-shadow-2xl"
                      onClick={() =>
                        seek(
                          (p) =>
                            p +
                            (maxPrecence.start.getTime() - wallclockTime) / 1000
                        )
                      }
                    >
                      <Typography
                        variant="h4"
                        color="primary"
                        className={clsx("text-2xl transition-all", {
                          "animate-pulse text-3xl text-red-500":
                            wallclockTimeDate &&
                            maxPrecence.start <= wallclockTimeDate &&
                            wallclockTimeDate <= maxPrecence.end,
                        })}
                      >
                        {minuteTimeFormathm(
                          ((maxPrecence.end.getTime() || 0) -
                            (maxPrecence.start.getTime() || 0)) /
                            1000
                        )}
                      </Typography>

                      <Typography variant="body1" className="leading-4 text-sm">
                        Longest <strong>Presence</strong>
                      </Typography>

                      <Typography variant="caption" className="leading-none">
                        {timeFormatSecs.format(
                          utcToZonedTime(maxPrecence.start, timezone)
                        )}
                      </Typography>
                    </div>
                  )}
                  {maxAbsence && (
                    <div
                      className="flex flex-col rounded-lg cursor-pointer hover:bg-white hover:drop-shadow-2xl"
                      onClick={() =>
                        seek(
                          (p) =>
                            p +
                            (maxAbsence.start.getTime() - wallclockTime) / 1000
                        )
                      }
                    >
                      <Typography
                        variant="h4"
                        color="primary"
                        className={clsx("text-2xl transition-all", {
                          "animate-pulse text-3xl text-red-500":
                            wallclockTimeDate &&
                            maxAbsence.start <= wallclockTimeDate &&
                            wallclockTimeDate <= maxAbsence.end,
                        })}
                      >
                        {minuteTimeFormathm(
                          ((maxAbsence?.end.getTime() || 0) -
                            (maxAbsence?.start.getTime() || 0)) /
                            1000
                        )}
                      </Typography>
                      <Typography variant="body1" className="leading-4 text-sm">
                        Longest <strong>Absence</strong>
                      </Typography>
                      <Typography variant="caption" className="leading-none">
                        {timeFormatSecs.format(
                          utcToZonedTime(maxAbsence.start, timezone)
                        )}
                      </Typography>
                    </div>
                  )}
                </div>

                <div className="flex flex-col">
                  <div
                    className="mt-2 w-full h-12 relative flex flex-row items-center cursor-pointer"
                    ref={presenceProgressRef}
                    onClick={(e) => {
                      presenceProgressRef.current &&
                        seek(
                          (p) =>
                            p +
                            ((e.nativeEvent.offsetX /
                              presenceProgressRef.current!.offsetWidth) *
                              fullTime +
                              startTime.getTime() -
                              wallclockTime) /
                              1000
                        );
                    }}
                  >
                    <div className=" bg-gray-200 h-8 w-full relative rounded-md overflow-hidden pointer-events-none">
                      {precenseIntervals.map(({ start, end }, idx) => (
                        <div
                          key={`${idx}`}
                          className={clsx(
                            "absolute top-0 bottom-0 bg-primary rounded-lg"
                          )}
                          style={{
                            left: `${(
                              ((start.getTime() - startTime.getTime()) /
                                fullTime) *
                              100
                            ).toFixed(2)}%`,
                            right: `${(
                              (1 -
                                (end.getTime() - startTime.getTime()) /
                                  fullTime) *
                              100
                            ).toFixed(2)}%`,
                          }}
                        />
                      ))}
                    </div>
                    {wallclockTime && (
                      <div
                        className="absolute top-0 bottom-0 bg-black h-full w-[2px] margin-auto pointer-events-none"
                        style={{
                          left: `${(
                            ((wallclockTime - startTime.getTime()) / fullTime) *
                            100
                          ).toFixed(2)}%`,
                        }}
                      />
                    )}
                  </div>
                  <div className="mt-[-6px] flex flex-row items-center justify-between">
                    <Typography variant="caption">
                      {timeFormatSecs.format(
                        utcToZonedTime(startTime, timezone)
                      )}
                    </Typography>
                    <Typography variant="caption">
                      {timeFormatSecs.format(utcToZonedTime(endTime, timezone))}
                    </Typography>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="mx-3 my-1">
          <Typography variant="caption">{objects.length} results</Typography>
        </div>
      </div>
      <div className="flex-grow flex-col flex px-3 py-3 overflow-y-auto">
        {!wallclockTime || loading || !objects ? (
          <CircularProgress />
        ) : (
          <>
            <FlipMove>
              {objects.map(
                (
                  {
                    objId,
                    type,
                    movementIntervals,
                    visual,
                    entered,
                    exited,
                    hasNotSeen,
                    hasEntered,
                    hasExited,
                    percentDone,
                    objKey,
                    attributes,
                    objectPath,
                    crossed,
                    detectedSubject,
                  },
                  idx
                ) => {
                  const { pluralize, icon, color } = idToFilterConfigMap[type];
                  const enterTimeMs = entered.getTime();
                  const exitTimeMs = exited.getTime();

                  const enterSec = round((wallclockTime - enterTimeMs) / 1000);
                  const exitSec = round((exitTimeMs - wallclockTime) / 1000);

                  let idleSum = 0;
                  let movingSum = 0;
                  const movementDates = movementIntervals
                    .map(({ moving, start }) => ({
                      moving,
                      start: new Date(start).getTime(),
                    }))
                    .filter(({ start }) => start <= wallclockTime);
                  const currentMoving =
                    movementDates.length !== 0
                      ? movementDates[movementDates.length - 1].moving
                      : undefined;
                  for (let i = 0; i < movementDates.length; i++) {
                    const { start, moving } = movementDates[i];
                    if (i === movementDates.length - 1) {
                      if (moving) {
                        movingSum += wallclockTime - start;
                      } else {
                        idleSum += wallclockTime - start;
                      }
                    } else {
                      const { start: nextStart } = movementDates[i + 1];
                      if (moving) {
                        movingSum += nextStart - start;
                      } else {
                        idleSum += nextStart - start;
                      }
                    }
                  }
                  idleSum = floor(idleSum / 1000);
                  movingSum = floor(movingSum / 1000);
                  const objectAttributesDebug = attributes
                    ? Object.entries(attributes).filter(
                        ([key, val]) => val !== null && key !== "__typename"
                      )
                    : undefined;

                  return (
                    <div className="w-full" key={objKey}>
                      <div
                        ref={idx === targetElementIdx ? targetScrollRef : null}
                        className={clsx(
                          "transition-all w-full flex-col flex rounded-md opacity-70 p-2 cursor-pointer hover:bg-white hover:drop-shadow-2xl hover:opacity-100",
                          {
                            "bg-white drop-shadow-2xl pb-5 my-2": hasEntered,
                            "opacity-100": hasEntered || hasExited,
                          }
                        )}
                        onClick={() =>
                          seek((p) => p + (enterTimeMs - wallclockTime) / 1000)
                        }
                        onMouseEnter={() =>
                          setFocusedBoundingBox &&
                          setFocusedBoundingBox({
                            localObjId: objId,
                            objectType: type,
                            cameraId: focusedCam.id,
                            objectPath,
                          })
                        }
                        onMouseLeave={() =>
                          setFocusedBoundingBox && setFocusedBoundingBox(null)
                        }
                      >
                        <div className="flex-row flex items-center gap-2">
                          {
                            <Avatar
                              style={{ background: color() }}
                              src={
                                detectedSubject?.bestTrack?.imageSrc ||
                                undefined
                              }
                            >
                              {icon}
                            </Avatar>
                          }
                          <div className="flex flex-col justify-center flex-grow">
                            <Typography variant="h6" className="leading-1">
                              {capitalize(pluralize(1) as string)}
                              {detectedSubject?.id
                                ? ` (Subject ID: ${detectedSubject.id})`
                                : ""}
                            </Typography>
                            <Typography variant="caption" className="leading-1">
                              {hasNotSeen &&
                                `Entering at ${timeFormatSecs.format(
                                  utcToZonedTime(entered, timezone)
                                )}`}
                              {hasEntered && (
                                <div className="flex flex-row gap-1">
                                  <Chip
                                    icon={<EcoIcon />}
                                    variant={
                                      currentMoving === false
                                        ? "outlined"
                                        : "filled"
                                    }
                                    size="small"
                                    label={minuteTimeFormat(movingSum)}
                                    color="primary"
                                  />
                                  <Chip
                                    icon={<ReportIcon />}
                                    variant={
                                      currentMoving === true
                                        ? "outlined"
                                        : "filled"
                                    }
                                    size="small"
                                    label={minuteTimeFormat(idleSum)}
                                    color="error"
                                  />
                                </div>
                              )}
                              {hasExited &&
                                `Exited at ${timeFormatSecs.format(
                                  utcToZonedTime(exited, timezone)
                                )}`}
                            </Typography>
                          </div>
                          {visual && visual.still && (
                            <div
                              className={clsx(
                                "box-content relative w-20 h-12 shrink-0 rounded border border-solid border-gray-400 overflow-hidden"
                              )}
                            >
                              <img
                                className="absolute h-full w-full object-cover object-center"
                                src={visual.still}
                                alt=""
                              />
                            </div>
                          )}
                        </div>
                        <div className="flex-wrap flex flex-row gap-1 mt-2">
                          <Chip color="primary" size="small" label={objId} />
                          {!!crossed?.crossedLeft && (
                            <Chip variant="outlined" size="small" label="in" />
                          )}
                          {!!crossed?.crossedRight && (
                            <Chip variant="outlined" size="small" label="out" />
                          )}
                          {!!objectAttributesDebug &&
                          objectAttributesDebug.length > 0
                            ? objectAttributesDebug.map(([key, val]) => (
                                <Chip
                                  variant="outlined"
                                  size="small"
                                  key={key}
                                  label={`${key}:${val}`}
                                />
                              ))
                            : null}

                          {!!allLicensePlateResults &&
                          allLicensePlateResults.length > 0
                            ? allLicensePlateResults.map((plate: String) => (
                                <Chip
                                  variant="outlined"
                                  size="small"
                                  key={`${plate}`}
                                  label={`plateOCR:${plate}`}
                                />
                              ))
                            : null}
                        </div>
                        {hasEntered && (
                          <div className="absolute w-full bottom-0 left-0 rounded-md overflow-hidden">
                            <div className="w-full flex flex-row justify-between px-1">
                              <Typography
                                variant="caption"
                                className="text-[0.6rem]"
                              >
                                {minuteTimeFormat(enterSec)}
                              </Typography>
                              <Typography
                                variant="caption"
                                className="text-[0.6rem]"
                              >
                                -{minuteTimeFormat(exitSec)}
                              </Typography>
                            </div>
                            <LinearProgress
                              className="w-full"
                              value={percentDone}
                              variant="determinate"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  );
                }
              )}
            </FlipMove>
          </>
        )}
      </div>
    </div>
  );
}

gql`
  query vodObjectActivity(
    $camId: Int!
    $startDatetime: DateTime!
    $endDatetime: DateTime!
    $searchArea: [SearchPoint!]
    $objectTypes: [String!]!
  ) {
    camera(id: $camId) {
      insights {
        objectActivity(
          input: {
            start: $startDatetime
            end: $endDatetime
            searchArea: $searchArea
            objectTypes: $objectTypes
          }
        ) {
          objects {
            objId
            type
            entered
            exited
            objectPath {
              x
              y
            }
            crossed {
              crossedLeft
              crossedRight
            }
            visual {
              x1
              y1
              x2
              y2
              still
            }
            movementIntervals {
              start
              moving
            }
            attributes {
              plate
              plateBoxPacked
              regionCodeId
              vehicleTypeId
              make
              model
              colorId
              typeId
              orientationId
              plateScore

              cloudaiVehColor
              cloudaiVehColorWeight
              cloudaiVehMake
              cloudaiVehMakeWeight
              cloudaiVehType
              cloudaiVehTypeWeight
              cloudaiPersonUpperColor
              cloudaiPersonUpperColorWeight
              cloudaiPersonLowerColor
              cloudaiPersonLowerColorWeight
              cloudaiPersonGender
              cloudaiPersonAge
              cloudaiPersonGenderAgeWeight
            }
            detectedSubject {
              id
              bestTrack {
                imageSrc
              }
            }
          }
        }
      }
    }
  }
`;

function pad(num: string, size: number) {
  var s = "000000000" + num;
  return s.substr(s.length - size);
}

export function minuteTimeFormat(seconds: number) {
  return `${floor(seconds / 60)}:${
    seconds % 60 < 10 ? `0${seconds % 60}` : `${seconds % 60}`
  }`;
}

export function minuteTimeFormathm(seconds: number) {
  return `${floor(seconds / 60)}m ${(seconds % 60).toFixed(0)}s`;
}

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        size="3rem"
        sx={{
          color: (theme) => theme.palette.grey[200],
        }}
        {...props}
        value={100}
      />
      <CircularProgress
        className="absolute left-0"
        size="3rem"
        variant="determinate"
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h4"
          color="primary"
          component="div"
          className="leading-none text-base"
        >
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}
