import { IconProps } from "@/icons/IconProps";

export function AIBase({
  style,
  className,
  onClick,
  primary,
}: IconProps & { primary?: boolean }) {
  return (
    <svg
      style={style}
      className={className}
      onClick={onClick}
      width="32"
      height="24"
      viewBox="0 0 32 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="32"
        height="24"
        rx="12"
        fill={primary ? "#007CE4" : "#353D48"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.1814 18.1393V6.11093H15.5789V7.82827C14.6012 6.57866 13.1554 5.82471 11.4135 5.82471C8.06752 5.82471 5.5752 8.54035 5.5752 12.1216C5.5752 15.7029 8.06062 18.4186 11.4135 18.4186C13.1623 18.4186 14.6012 17.6646 15.5789 16.415V18.1393H18.1814ZM15.572 12.1286C15.572 10.0064 14.016 8.3798 11.923 8.3798C9.80932 8.3798 8.24648 10.0064 8.24648 12.1286C8.24648 14.2509 9.80244 15.8775 11.923 15.8775C14.016 15.8775 15.572 14.2509 15.572 12.1286Z"
        fill="white"
      />
      <path
        d="M20.8114 18.1393V6.11093H23.4138V18.1393H20.8114Z"
        fill="white"
      />{" "}
    </svg>
  );
}
