import { MutationFunctionOptions } from "@apollo/client";
import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { pluralize } from "@/util/pluralize";

import { isDemoUser, useMe } from "@/components/Auth";
import {
  AddToCaseDialog,
  CaseContentType,
} from "@/components/Player/AddToCaseDialog";
import { CaseSmallIcon } from "@/components/Player/PlayerIcons";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";

import {
  AddClipToCaseMutation,
  Exact,
  SharedClipInfoQueryVariables,
  useAddClipToCaseMutation,
} from "@/generated-models";
import { usePrefixOrgSlug } from "@/hooks/useOrgRouteBase";

import { useMultiSelectionState } from "../LicensePlatePage";
import { useLprSearch } from "../hooks";

function useLprSearchSelectedClips() {
  const [selectMultiple] = useMultiSelectionState();
  const { data } = useLprSearch();
  return useMemo(() => {
    return data?.filter((d) => selectMultiple?.includes(d.id));
  }, [data, selectMultiple]);
}

export function LicensePlateSummaryActionBar() {
  const me = useMe();
  const isDemo = !!me && isDemoUser(me); // only relevant for demo orgs
  const { pushSnackbar } = useFeedback();
  const [caseDialogOpen, setCaseDialogOpen] = useState(false);
  const selectedResults = useLprSearchSelectedClips();
  const { data } = useLprSearch(true);
  const navigate = useNavigate();
  const prefixOrgSlug = usePrefixOrgSlug();
  const [addClipToCase] = useAddClipToCaseMutation({
    onError: () =>
      pushSnackbar(
        "We were unable to save this clip. Please try again later.",
        FeedbackType.Error
      ),
  });
  const [selectMultiple, setSelectMultiple] = useMultiSelectionState();

  if (!selectMultiple) return <></>;

  const clipLength = selectMultiple.length;

  return (
    <div className="bg-primary flex items-center justify-between -mx-32 px-32 py-[10px]">
      <div className="flex items-center gap-4">
        <Typography className="text-white leading[18.75px]">
          {clipLength} {pluralize({ 1: "Clip", multi: "Clips" }, clipLength)}{" "}
          Selected
        </Typography>
        <button
          type="button"
          disabled={(selectMultiple?.length || -1) >= data.length}
          className="bg-transparent underline text-white text-sm leading-[16-41px] disabled:opacity-50 disabled:pointer-events-none disabled:no-underline"
          onClick={() => {
            setSelectMultiple(data.map((d) => d.id));
          }}
        >
          Select All
        </button>
      </div>
      <div className="flex items-center gap-10">
        <Button
          variant="text"
          startIcon={<CaseSmallIcon className="w-5 h-5" />}
          className="text-white text-sm leading-4"
          size="small"
          disabled={isDemo}
          onClick={() => {
            setCaseDialogOpen(true);
          }}
        >
          Save to Case
        </Button>
        <IconButton
          edge="end"
          className="p-0"
          size="small"
          onClick={() => {
            setSelectMultiple(null);
          }}
        >
          <CloseIcon className="text-white" />
        </IconButton>
      </div>
      <AddToCaseDialog
        type={CaseContentType.bulk_clips}
        open={caseDialogOpen}
        setOpen={setCaseDialogOpen}
        onSave={async (caseId: number, redirectToCase: boolean) => {
          const ops: MutationFunctionOptions<
            AddClipToCaseMutation,
            Exact<{
              caseId: number;
              value: SharedClipInfoQueryVariables;
            }>
          >[] = selectedResults.map((d, idx) => {
            const lastOp = data.length - 1 === idx;
            return {
              variables: {
                caseId,
                value: {
                  cameraId: d.camera.id,
                  startTime: d.startTime,
                  endTime: d.endTime,
                },
              },
              ...(lastOp && {
                onCompleted: (data) => {
                  if (redirectToCase && data.addClipToCase) {
                    navigate(
                      prefixOrgSlug(
                        `/cases/${caseId}?clip=${data.addClipToCase.id}`
                      )
                    );
                  } else {
                    setCaseDialogOpen(false);
                    pushSnackbar(
                      "Successfully saved clips to case",
                      FeedbackType.Success
                    );
                  }
                },
              }),
            };
          });

          for (const op of ops) {
            await addClipToCase(op);
          }
        }}
      />
    </div>
  );
}
