import { ChevronRight } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import { usePrevious } from "@react-hookz/web";
import clsx from "clsx";
import { Fragment, useMemo } from "react";
import { Link } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";

import { CloseIcon } from "@/components/Player/PlayerIcons";

import {
  IntelligenceFeatureConfig,
  IntelligenceFeatureType,
  IntelligenceInsightType,
  useIntelligenceInsightConfig,
} from "./constants";

interface IntelligenceFeatureCardProps {
  featureType: IntelligenceFeatureType;
}

function IntelligenceFeatureCardIllustration({
  featureType,
}: IntelligenceFeatureCardProps) {
  const config = IntelligenceFeatureConfig[featureType];
  return (
    <img
      alt={`${config.label.displayName} illustration`}
      className="object-cover rounded-[4px] w-[106px] h-[106px]"
      src={config.illustration.default}
    />
  );
}

function IntelligenceFeatureCardContent({
  featureType,
}: IntelligenceFeatureCardProps) {
  const config = IntelligenceFeatureConfig[featureType];

  return (
    <div className="flex items-center gap-3 sm:gap-4 w-full">
      <div className="flex flex-col items-start justify-center h-full gap-[3px] w-full">
        <Typography
          className={clsx(
            "sm:text-2xl text-xl leading-[23px] sm:leading-[28px] font-bold",
            config.foreground.colorPrimary
          )}
        >
          {config.label.displayName}
        </Typography>
        <Typography className="text-sm sm:text-base leading-4 sm:leading-[19px] font-normal min-h-[unset]">
          {config.label.description}
        </Typography>
      </div>
      <div>
        <ChevronRight className="text-primary font-bold leading-4 text-[34px] sm:text-[48px]" />
      </div>
    </div>
  );
}

export function IntelligenceFeaturePrompModalItem({
  featureType,
}: IntelligenceFeatureCardProps) {
  const config = IntelligenceFeatureConfig[featureType];

  return (
    <Link className="w-full" to={config.action.linkNew}>
      <div className="p-2 gap-3 sm:gap-4 flex justify-start items-center w-full ">
        <IntelligenceFeatureCardIllustration featureType={featureType} />
        <IntelligenceFeatureCardContent featureType={featureType} />
      </div>
    </Link>
  );
}

export function IntelligenceCreatePromptModal() {
  const config = useIntelligenceInsightConfig();

  const [promptCreate, setPromptCreate] = useQueryParam(
    "promptCreate",
    StringParam
  );

  const previousPrompt = usePrevious(promptCreate);

  const resolvedConfig = useMemo(() => {
    const resolvedType =
      (promptCreate as IntelligenceInsightType) ||
      previousPrompt ||
      IntelligenceInsightType.People;
    return config[resolvedType] || config.people;
  }, [promptCreate, previousPrompt, config]);

  return (
    <Dialog
      classes={{ paper: "rounded-lg" }}
      open={!!promptCreate}
      onClose={() => {
        setPromptCreate(undefined);
      }}
    >
      <div className="flex items-center bg-[#F0F0F0] p-2 pl-0">
        <DialogTitle className="text-2xl !leading-5 font-light py-0">
          {resolvedConfig.labelCreate}
        </DialogTitle>
        <div className="flex-1" />
        <IconButton
          onClick={() => {
            setPromptCreate(undefined);
          }}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </div>

      <DialogContent classes={{ root: "p-5" }}>
        {resolvedConfig.features.map((feature, idx) => (
          <Fragment key={feature}>
            <IntelligenceFeaturePrompModalItem featureType={feature} />
            {resolvedConfig.features.length > idx + 1 && (
              <Divider className="my-2 mx-2" />
            )}
          </Fragment>
        ))}
      </DialogContent>
    </Dialog>
  );
}
