import { Checkbox } from "@mui/material";
import { GridAlignment, GridRenderCellParams } from "@mui/x-data-grid";
import { atom, useAtom } from "jotai";
import { get } from "lodash";
import { useMemo } from "react";
import {
  BooleanParam,
  DelimitedNumericArrayParam,
  useQueryParam,
} from "use-query-params";

import { BaseDatagridProps } from "./DataGrid";

const ALL_SELECTED = -1;

export function useDataGridSelectedRows(
  rows: BaseDatagridProps["rows"],
  getRowId?: BaseDatagridProps["getRowId"],
  field?: string
) {
  const { selection, allSelected } = useDataGridSelection();

  return useMemo(() => {
    function resolveRowId(row: BaseDatagridProps["rows"][number]) {
      return getRowId ? getRowId(row) : row.id;
    }

    return rows.filter((r) => {
      return (
        (allSelected || selection?.includes(resolveRowId(r))) &&
        (!field || !!get(r, field))
      );
    });
  }, [allSelected, field, getRowId, rows, selection]);
}

const dataGridSelectionTotalAtom = atom(0);

export function useDataGridSelectedRowIds(
  rows: BaseDatagridProps["rows"],
  getRowId?: BaseDatagridProps["getRowId"]
) {
  const data = useDataGridSelectedRows(rows, getRowId);

  return data.map((d) => (getRowId ? getRowId(d) : d.id));
}

export function useDataGridSelection() {
  const [total, setTotal] = useAtom(dataGridSelectionTotalAtom);

  const [selection, setSelection] = useQueryParam(
    "row-selection",
    DelimitedNumericArrayParam
  );

  const [editing, setEditing] = useQueryParam(
    "row-selection-editing",
    BooleanParam
  );

  const [locked, setLocked] = useQueryParam(
    "row-selection-editing-locked",
    BooleanParam
  );

  const selectAll = () => {
    setSelection([ALL_SELECTED]);
  };

  const allSelected = selection?.[0] === ALL_SELECTED;
  const count = allSelected ? total || 0 : (selection || [])?.length;

  function reset() {
    setEditing(false);
    setLocked(false);
    setSelection([]);
  }

  return {
    editing,
    locked,
    setLocked,
    setEditing,
    total,
    setTotal,
    selection,
    setSelection,
    selectAll,
    deselectAll: reset,
    allSelected,
    count,
    reset,
  };
}

export function useDataGridSelectionCheckbox(
  rows: BaseDatagridProps["rows"],
  getRowId?: BaseDatagridProps["getRowId"],
  rowLabel?: string
) {
  const {
    locked,
    setEditing,
    selection,
    setSelection,
    allSelected,
  } = useDataGridSelection();

  function resolveRowId(row: BaseDatagridProps["rows"][number]) {
    return getRowId ? getRowId(row) : row.id;
  }

  return {
    field: "edit-row",
    sortable: false,
    align: "center" as GridAlignment,
    headerClassName: "invisible",
    cellClassName: "justify-center",
    width: 50,
    renderCell: (params: GridRenderCellParams) => {
      const rowId = resolveRowId(params.row);
      return (
        <Checkbox
          disabled={!!locked}
          checked={selection?.includes(rowId) || allSelected}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={() => {
            let newSelection = selection ?? [];

            if (allSelected) {
              newSelection = rows
                .map((r) => resolveRowId(r))
                .filter((r) => rowId !== r);
            } else {
              newSelection = selection?.includes(rowId)
                ? selection.filter((s) => s !== rowId)
                : newSelection.concat(rowId);
            }
            setSelection(newSelection);
            setEditing(newSelection.length > 0);
          }}
        />
      );
    },
  };
}
