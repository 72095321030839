import ArrowLeft from "@mui/icons-material/ArrowLeft";
import ArrowRight from "@mui/icons-material/ArrowRight";
import CloseIcon from "@mui/icons-material/Close";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Button, IconButton } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import clsx from "clsx";
import { utcToZonedTime } from "date-fns-tz";
import gql from "graphql-tag";
import { useFlags } from "launchdarkly-react-client-sdk";
import { clamp } from "lodash/fp";
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useInView } from "react-intersection-observer";
import { makeStyles } from "tss-react/mui";
import { BooleanParam, useQueryParams } from "use-query-params";

import { timeFormat } from "@/util/date";
import { useBreakpoints } from "@/util/useBreakpoints";

import { idToFilterConfigMap } from "@/pages/Search/intelligence/intelligence";
import { useSearchSubjects } from "@/pages/Search/searchHooks";

import { StillImage } from "@/components/Still/StillImage";

import {
  AnonymousSharedClip,
  Still,
  useGetSharedClipQuery,
  useSharedClipStillBoxesQuery,
  useStillBoxesQuery,
  useStillsQueryQuery,
} from "@/generated-models";

export const GET_STILLS = gql`
  query stillsQuery($id: Int!, $start: String!, $end: String!, $limit: Int) {
    stills(id: $id, startTime: $start, endTime: $end, limit: $limit) {
      timestamp
      src
    }
  }
`;

export const GET_STILLS_BOXES = gql`
  query stillBoxes($id: Int!, $ts: String!) {
    stillBoxes(id: $id, ts: $ts) {
      xmin
      ymin
      xmax
      ymax
      score
      type {
        id
      }
    }
  }
`;

export const GET_SHARED_STILLS_BOXES = gql`
  query sharedClipStillBoxes($token: String!, $ts: String!) {
    sharedClipStillBoxes(token: $token, ts: $ts) {
      xmin
      ymin
      xmax
      ymax
      score
      type {
        id
      }
    }
  }
`;

const useStyles = makeStyles()((theme) => ({
  snippet: {
    cursor: "pointer",

    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },

    "&:hover .popper": {
      opacity: 1,
    },
  },
  box: {
    borderRadius: 4,
    position: "relative",
    overflow: "hidden",
  },
  outline: {
    border: `2px solid ${theme.palette.primary.main}`,
  },
  viewing: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    top: 3,
    left: 3,
    padding: "2px 4px 2px 1px",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    borderRadius: 2,
    fontSize: 11,
    fontWeight: "bold",
    pointerEvents: "none",
  },
  timestamp: {
    backgroundColor: "#383838",
    opacity: 0.7,
    color: "white",
    borderRadius: 2,
    fontSize: 11,
    fontWeight: "bold",
    padding: "2px 7px",
    position: "absolute",
    top: 3,
    right: 3,
    pointerEvents: "none",
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },

  progress: {
    color: "white",
    position: "absolute",
    width: 1,
    height: "100%",
    top: 0,
    backgroundColor: "white",

    pointerEvents: "none",
  },
  arrow: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
  },
  left: {
    right: "100%",
  },
  right: {
    left: "100%",
  },
}));

const ScrubImagesInner = <
  T extends string | number,
  StillType extends { timestamp: T; src: string }
>({
  keySuffix,
  stills,
  hoveringStill,
  onLoadComplete = () => {},
  imgClassName,
}: {
  keySuffix?: string;
  stills: StillType[];
  hoveringStill?: StillType;
  onLoadComplete?: () => void;
  imgClassName?: string;
}) => {
  const [loadedStills, setLoadedStills] = useState(new Set<T>());
  const [loadedOnce, setLoadedOnce] = useState(false);

  useEffect(() => {
    if (!loadedOnce && loadedStills.size === stills.length) {
      setLoadedOnce(true);
      onLoadComplete();
    }
  }, [loadedStills, onLoadComplete, stills, loadedOnce]);

  // Handles img loading state
  const displayingStill =
    hoveringStill && loadedStills.has(hoveringStill.timestamp)
      ? hoveringStill
      : stills[0];

  return (
    <>
      {stills.map((still) => (
        <StillImage
          key={`${still.timestamp}_${keySuffix ?? "still-scrubber"}`}
          src={still.src}
          alt=""
          onLoad={() =>
            setLoadedStills(new Set([...loadedStills, still.timestamp]))
          }
          className={imgClassName}
          style={{
            display:
              loadedStills.has(still.timestamp) && still === displayingStill
                ? "block"
                : "none",
          }}
        />
      ))}
    </>
  );
};

export const ScrubImages = memo(ScrubImagesInner);

export function useLimitedStills({
  start,
  end,
  sharedClipToken,
  cameraId,
  limited,
}: {
  start: string;
  end: string;
  sharedClipToken?: string;
  cameraId?: number;
  limited: boolean;
}) {
  // The purpose of caching an older response is to prevent an `undefined` result
  // during the period that we're switching between `limit: 1` and `limit: null`.
  const cachedFallbackStills = useRef<Pick<Still, "timestamp" | "src">[]>();

  const { data } = useStillsQueryQuery({
    variables: {
      start,
      end,
      id: cameraId!,
      limit: limited ? 1 : null,
    },
    skip: !cameraId,
  });

  const { data: clipData } = useGetSharedClipQuery({
    variables: { token: sharedClipToken ?? "" },
    skip: !sharedClipToken,
  });

  const sharedClip = clipData?.sharedClip as AnonymousSharedClip;

  useEffect(() => {
    if (cameraId && !data?.stills.length) return;
    if (sharedClipToken && !sharedClip?.stills) return;
    if (cameraId && data) {
      cachedFallbackStills.current = data.stills;
    } else if (sharedClipToken && sharedClip?.stills) {
      cachedFallbackStills.current = sharedClip?.stills;
    }
  }, [data, clipData, cameraId, sharedClipToken, sharedClip?.stills]);

  return (
    (cameraId ? data?.stills : sharedClip?.stills) ??
    cachedFallbackStills.current ??
    ([] as Still[])
  );
}

interface SnippetPreviewProps {
  timezone: string;
  start: string;
  end: string;
  sharedClipToken?: string;
  cameraId?: number;
  onClick: (start: string, end: string, progress?: number) => void;
  selected?: boolean;
  progress?: number;
  setActiveClip?: React.Dispatch<React.SetStateAction<string>>;
  activeClip?: string;
}

function InnerSnippetPreview({
  timezone,
  start,
  end,
  sharedClipToken,
  cameraId,
  onClick,
  selected,
  progress,
  setActiveClip = () => {},
  activeClip,
}: SnippetPreviewProps) {
  const { classes } = useStyles();
  const { fitsDesktop } = useBreakpoints();
  const [limitStills, setLimitStills] = useState(true);
  const [mouseOver, setMouseOver] = useState(false);
  const [hoverProgress, setHoverProgress] = useState(0);
  const [imageLoadComplete, setImageLoadComplete] = useState(!fitsDesktop);
  const { mobileScrubbingThumbnails } = useFlags();
  const setImageLoadCompleted = useCallback(() => {
    setImageLoadComplete(true);
  }, []);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [layoutParams] = useQueryParams({
    bypassBreakpoints: BooleanParam,
  });
  const calculatedFitsDesktop = fitsDesktop || layoutParams.bypassBreakpoints;
  const stills = useLimitedStills({
    start,
    end,
    cameraId,
    sharedClipToken,
    limited: limitStills,
  });

  const hoverIndex = Math.round(hoverProgress * (stills.length - 1));
  const caretProgress = hoverProgress || (selected && progress);

  // Handles graphql loading state
  const hoveringStill = stills[hoverIndex < stills.length ? hoverIndex : 0] as
    | Still
    | undefined;

  const zonedDate = utcToZonedTime(
    new Date(hoveringStill ? hoveringStill.timestamp : start),
    timezone
  );

  const isActiveClip = useMemo(() => start === activeClip, [start, activeClip]);

  const subjects = useSearchSubjects();
  const mobileClick = () => {
    setActiveClip(start);
  };

  // Mobile Thumbnail Scrub
  if (!calculatedFitsDesktop && mobileScrubbingThumbnails) {
    return isActiveClip ? (
      <div
        className={clsx("relative", {
          "z-[51]": isActiveClip,
        })}
      >
        <div
          className={clsx("relative", classes.snippet)}
          onClick={() =>
            calculatedFitsDesktop && onClick(start, end, hoverProgress)
          }
          onTouchStart={() => {
            setMouseOver(true);
            if (limitStills) setLimitStills(false);
          }}
          onTouchEnd={() => {
            setHoverProgress(0);
            setMouseOver(false);
          }}
          onTouchMove={(e) => {
            const node = e.target as HTMLElement;
            const bcr = node.getBoundingClientRect();

            setHoverProgress(
              clamp(
                0,
                1,
                (e.nativeEvent.targetTouches[0].clientX - bcr.x) /
                  (e.nativeEvent.target as any).clientWidth
              )
            );
          }}
          ref={anchorRef}
        >
          <div
            className={clsx(classes.box, "aspect-video", {
              [classes.outline]: selected,
            })}
          >
            {!imageLoadComplete && (
              <Skeleton variant="rectangular" height="100%" />
            )}
            {stills.length > 0 && (
              <>
                {calculatedFitsDesktop &&
                  !!subjects?.length &&
                  hoveringStill &&
                  !!hoveringStill.objects?.length && (
                    <StillBoxOverlay
                      sharedClipToken={sharedClipToken}
                      cameraId={cameraId}
                      timestamp={hoveringStill.timestamp}
                      searchSubjects={subjects}
                    />
                  )}
                {calculatedFitsDesktop ? (
                  <ScrubImages
                    keySuffix={""}
                    stills={stills}
                    hoveringStill={hoveringStill}
                    onLoadComplete={setImageLoadCompleted}
                  />
                ) : (
                  <img src={stills[0].src} alt="" />
                )}
                {caretProgress ? (
                  <div
                    className={classes.progress}
                    style={{ left: `${caretProgress * 100}%` }}
                  >
                    <ArrowLeft className={clsx(classes.arrow, classes.left)} />
                    <ArrowRight
                      className={clsx(classes.arrow, classes.right)}
                    />
                  </div>
                ) : null}
                {selected && (
                  <div className={classes.viewing}>
                    <PlayArrowIcon style={{ fontSize: 14, marginRight: 1 }} />
                    Viewing
                  </div>
                )}
                <div className={classes.timestamp}>
                  {timeFormat.format(new Date(zonedDate))}
                </div>
              </>
            )}
          </div>
        </div>

        {stills.length > 0 && (
          <div
            className={clsx(
              "popper absolute bottom-full w-80 transition-opacity transform -translate-x-1/2 -translate-y-1 left-1/2 shadow-md z-[51]",
              isActiveClip ? "opacity-100" : " opacity-0"
            )}
          >
            <div className="rounded overflow-hidden">
              {!!subjects?.length && hoveringStill && (
                <StillBoxOverlay
                  sharedClipToken={sharedClipToken}
                  cameraId={cameraId}
                  timestamp={hoveringStill.timestamp}
                  searchSubjects={subjects}
                />
              )}
              <ScrubImages
                keySuffix={"popper"}
                stills={stills}
                hoveringStill={hoveringStill}
                onLoadComplete={() => {}}
                imgClassName="w-full h-full object-cover"
              />
            </div>
            <IconButton
              className="absolute top-1 right-1 w-5 h-5 bg-black/70 rounded-sm"
              size="small"
              onClick={() => setActiveClip("")}
            >
              <CloseIcon className="text-white" />
            </IconButton>
          </div>
        )}
        <Button
          className="bg-primary text-white mt-2 absolute bottom-[-44.5px] left-0 w-full"
          onClick={() => {
            setActiveClip("");
            onClick(start, end);
          }}
        >
          Play
        </Button>
      </div>
    ) : (
      <div onClick={mobileClick}>
        {stills.length > 0 && <img src={stills[0].src} alt="" />}
      </div>
    );
  }

  return (
    <div
      className={clsx(classes.snippet, "relative")}
      onClick={() => onClick(start, end, hoverProgress)}
      onMouseEnter={
        calculatedFitsDesktop
          ? () => {
              setMouseOver(true);
              if (limitStills) setLimitStills(false);
            }
          : undefined
      }
      onMouseLeave={
        calculatedFitsDesktop
          ? () => {
              setHoverProgress(0);
              setMouseOver(false);
            }
          : undefined
      }
      onMouseMove={
        calculatedFitsDesktop
          ? (e) => {
              setHoverProgress(
                clamp(
                  0,
                  1,
                  e.nativeEvent.offsetX /
                    (e.nativeEvent.target as any).clientWidth
                )
              );
            }
          : undefined
      }
      ref={anchorRef}
    >
      <div
        className={clsx(classes.box, "aspect-video", {
          [classes.outline]: selected,
        })}
      >
        {!imageLoadComplete && <Skeleton variant="rectangular" height="100%" />}
        {stills.length > 0 && (
          <>
            {calculatedFitsDesktop &&
              !!subjects?.length &&
              hoveringStill &&
              !!hoveringStill.objects?.length && (
                <StillBoxOverlay
                  sharedClipToken={sharedClipToken}
                  cameraId={cameraId}
                  timestamp={hoveringStill.timestamp}
                  searchSubjects={subjects}
                />
              )}
            {calculatedFitsDesktop ? (
              <ScrubImages
                keySuffix={""}
                stills={stills}
                hoveringStill={hoveringStill}
                onLoadComplete={setImageLoadCompleted}
              />
            ) : (
              <img src={stills[0].src} alt="" />
            )}
            {caretProgress ? (
              <div
                className={classes.progress}
                style={{ left: `${caretProgress * 100}%` }}
              >
                <ArrowLeft className={clsx(classes.arrow, classes.left)} />
                <ArrowRight className={clsx(classes.arrow, classes.right)} />
              </div>
            ) : null}
            {selected && (
              <div className={classes.viewing}>
                <PlayArrowIcon style={{ fontSize: 14, marginRight: 1 }} />
                Viewing
              </div>
            )}
            <div className={classes.timestamp}>
              {timeFormat.format(new Date(zonedDate))}
            </div>
          </>
        )}
      </div>

      {fitsDesktop && stills.length > 0 && (
        <div
          className={clsx(
            "popper absolute bottom-full w-80 pointer-events-none transition-opacity transform -translate-x-1/2 -translate-y-1 left-1/2 shadow-md z-10",
            mouseOver ? "opacity-100" : " opacity-0"
          )}
        >
          <div className="rounded overflow-hidden">
            {!!subjects?.length && hoveringStill && (
              <StillBoxOverlay
                sharedClipToken={sharedClipToken}
                cameraId={cameraId}
                timestamp={hoveringStill.timestamp}
                searchSubjects={subjects}
              />
            )}
            <ScrubImages
              keySuffix={"popper"}
              stills={stills}
              hoveringStill={hoveringStill}
              onLoadComplete={() => {}}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export const ExpandingSnippetPreview = memo(function ExpandingSnippetPreview({
  scrollContainer,
  ...props
}: SnippetPreviewProps & { scrollContainer?: HTMLElement | string }) {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  return (
    <div ref={ref}>
      {inView ? (
        <InnerSnippetPreview {...props} />
      ) : (
        <div className="aspect-video">
          <Skeleton variant="rectangular" height="100%" />
        </div>
      )}
    </div>
  );
});

function StillBoxOverlay({
  sharedClipToken,
  cameraId,
  timestamp,
  searchSubjects,
}: {
  sharedClipToken?: string;
  cameraId?: number;
  timestamp: string;
  searchSubjects: string[];
}) {
  const { data } = useStillBoxesQuery({
    variables: {
      id: cameraId!,
      ts: timestamp,
    },
    skip: searchSubjects.length === 0 || !cameraId,
  });

  const { data: sharedStills } = useSharedClipStillBoxesQuery({
    variables: {
      token: sharedClipToken!,
      ts: timestamp,
    },
    skip: searchSubjects.length === 0 || !sharedClipToken,
  });

  const objects = !!data
    ? data?.stillBoxes
    : !!sharedStills
    ? sharedStills?.sharedClipStillBoxes
    : [];

  if (!objects?.length) {
    return <></>;
  }

  return (
    <div className="absolute pointer-events-none w-full h-full">
      {objects
        .filter(({ type: { id } }) => searchSubjects.includes(id))
        .map(({ xmin, ymin, xmax, ymax, type: { id }, score }, idx) => {
          const { objectConfidenceThreshold, objectIds } = idToFilterConfigMap[
            id
          ];
          return (
            <div
              key={`${idx}`}
              style={{
                opacity:
                  objectConfidenceThreshold[objectIds.indexOf(id)] <=
                  (score || 1)
                    ? 1
                    : 0,
                pointerEvents: "none",
                position: "absolute",
                top: `${(ymin * 100).toFixed(2)}%`,
                bottom: `${((1 - ymax) * 100).toFixed(2)}%`,
                left: `${(xmin * 100).toFixed(2)}%`,
                right: `${((1 - xmax) * 100).toFixed(2)}%`,
                border: `2px solid ${
                  idToFilterConfigMap[id]?.color() || "transparent"
                }`,
              }}
            />
          );
        })}
    </div>
  );
}
