import DropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Backdrop,
  Button,
  ButtonGroup,
  Fade,
  Modal,
  Paper,
  Popover,
} from "@mui/material";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { useUpdateEffect } from "react-use";

import { useBreakpoints } from "@/util/useBreakpoints";

import { AlertCreate } from "@/pages/Alerts/AlertCreate";
import {
  CreateTempZone,
  EditZone,
  MobileCreateEditZone,
  ZoneModalMode,
} from "@/pages/Search/ZoneMotion/ZoneModals";
import {
  MobileSavedZonePopover,
  MobileTempZonePopover,
  SavedZonePopover,
  TempZonePopover,
} from "@/pages/Search/ZoneMotion/ZonePopovers";
import {
  useCameraMotionZone,
  useSetCameraMotionZone,
} from "@/pages/Search/searchHooks";

import { HelpPopover } from "@/components/Help/HelpPopover";
import {
  useIsBuffering,
  usePlayerControls,
} from "@/components/Player/PlayerBase";
import { Point } from "@/components/Zones/getSectorsForPolygon";

import { AlertType, useLatestCameraStillQuery } from "@/generated-models";

export function ZoneSwitch({
  cameraId,
  showHelp = false,
  multiCamMode = false,
  mobileDesign = false,
}: {
  cameraId: number;
  showHelp?: boolean;
  multiCamMode?: boolean;
  mobileDesign?: boolean;
}) {
  const { fitsDesktop } = useBreakpoints();
  const { data } = useLatestCameraStillQuery({ variables: { id: cameraId } });
  const { activeZone, activeShape, zones } = useCameraMotionZone(cameraId);
  const setZone = useSetCameraMotionZone();
  const playerId = cameraId.toString();
  const { getPlayerElement } = usePlayerControls(playerId);
  const isBuffering = useIsBuffering(playerId);
  const [playerSnapshot, setPlayerSnapshot] = useState<string | null>(null);
  const buttonEl = useRef(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const closePopover = () => setPopoverOpen(false);
  const [modalMode, setModalMode] = useState<ZoneModalMode | null>(null);
  const closeModal = () => setModalMode(null);
  const modalOpen = modalMode !== null;

  // Get player snapshot when modal opens
  useEffect(() => {
    const video = getPlayerElement();
    if (video && !isBuffering && modalOpen) {
      // Create canvas
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      // Draw video on canvas and save image data
      const canvasContext = canvas.getContext("2d");
      if (canvasContext) {
        canvasContext.drawImage(video, 0, 0, canvas.width, canvas.height);
        setPlayerSnapshot(canvas.toDataURL());
      }
    }
  }, [getPlayerElement, isBuffering, setPlayerSnapshot, modalOpen]);

  // Clear snapshot when cameraId changes
  useUpdateEffect(() => {
    setPlayerSnapshot(null);
  }, [cameraId, setPlayerSnapshot]);

  if (!data) return null;

  const hasActiveShape = !!activeShape;

  return (
    <>
      {mobileDesign ? (
        <div className="text-sm text-gray-75">
          {showHelp && (
            <div className="flex items-center h-[25px]">
              <div>Search Region:</div>
              <div className="mx-1">
                <HelpPopover
                  title="Motion Events & Focus Zones"
                  description="Enabling motion events analyzes the selected time range for motions events. You can further refine the results by creating focus areas to help focus on motion events only within those areas. "
                />
              </div>
            </div>
          )}
          <ButtonGroup className="w-full">
            <Button
              disabled={!hasActiveShape}
              className={clsx(
                "h-9 min-w-[80px] px-3 border border-solid rounded-r-none rounded-l-md transition-none shrink-0 text-sm",
                {
                  "bg-[#F4F4F4] text-gray-75 font-normal border-gray-e0 border-r-0": hasActiveShape,
                  "bg-primary text-white font-bold border-[#45AAFF]": !hasActiveShape,
                  "w-1/2": mobileDesign && !fitsDesktop,
                }
              )}
              onClick={() => setZone(cameraId, undefined)}
            >
              All
            </Button>
            <Button
              ref={buttonEl}
              className={clsx(
                "h-9 min-w-[80px] px-3 border border-solid rounded-l-none rounded-r-md transition-none shrink-0 text-sm",
                {
                  "bg-[#F4F4F4] text-gray-75 font-normal border-gray-e0 border-l-0": !hasActiveShape,
                  "bg-zonePink text-white font-bold border-[#E567C7]": hasActiveShape,
                  "w-1/2": mobileDesign && !fitsDesktop,
                }
              )}
              classes={{
                endIcon: !!activeShape ? "ml-0.5 -mr-2.5" : undefined,
              }}
              endIcon={
                !!activeShape && (
                  <DropDownIcon className={popoverOpen ? "rotate-180" : ""} />
                )
              }
              onClick={() => {
                if (!hasActiveShape && zones.length > 0) {
                  // Set initial ZBM state to viewing the first saved zone
                  setZone(cameraId, zones[0].id);
                } else if (hasActiveShape) {
                  setPopoverOpen(true);
                } else {
                  // Create a new zone or edit selected zone
                  setModalMode(
                    activeZone ? ZoneModalMode.Select : ZoneModalMode.Create
                  );
                }
              }}
            >
              {activeZone && "Saved "}
              {!activeZone && !!activeShape && "Temp "}Zone
            </Button>
          </ButtonGroup>
        </div>
      ) : (
        <div className="flex items-center shrink-0 text-xs">
          {showHelp && <div className="mr-2 opacity-40">Search Region</div>}
          <ButtonGroup>
            <Button
              disabled={!hasActiveShape}
              className={clsx(
                "h-[23px] min-w-[48px] px-3 border border-solid rounded-r-none rounded-l-md transition-none shrink-0 text-xs",
                {
                  "bg-[#3D3D3D] text-[#d8d8d8] font-normal border-[#525252] border-r-0": hasActiveShape,
                },
                {
                  "bg-primary text-white font-bold border-[#2D95EC]": !hasActiveShape,
                }
              )}
              onClick={() => setZone(cameraId, undefined)}
            >
              All
            </Button>
            <Button
              ref={buttonEl}
              className={clsx(
                "h-[23px] min-w-[48px] px-3 border border-solid rounded-l-none rounded-r-md transition-none shrink-0 text-xs",
                {
                  "bg-[#3D3D3D] text-[#d8d8d8] font-normal border-[#525252] border-l-0": !hasActiveShape,
                },
                {
                  "bg-zonePink text-white font-bold border-[#E567C7]": hasActiveShape,
                }
              )}
              classes={{
                endIcon: !!activeShape ? "ml-0.5 -mr-2.5" : undefined,
              }}
              endIcon={
                !!activeShape && (
                  <DropDownIcon className={popoverOpen ? "rotate-180" : ""} />
                )
              }
              onClick={() => {
                if (!hasActiveShape && zones.length > 0) {
                  // Set initial ZBM state to viewing the first saved zone
                  setZone(cameraId, zones[0].id);
                } else if (hasActiveShape) {
                  setPopoverOpen(true);
                } else {
                  // Create a new zone or edit selected zone
                  setModalMode(
                    activeZone ? ZoneModalMode.Select : ZoneModalMode.Create
                  );
                }
              }}
            >
              {activeZone && "Saved "}
              {!activeZone && !!activeShape && "Temp "}Zone
            </Button>
          </ButtonGroup>
          {showHelp && (
            <div className="mx-1 opacity-60">
              <HelpPopover
                title="Motion Events & Focus Zones"
                description="Enabling motion events analyzes the selected time range for motions events. You can further refine the results by creating focus areas to help focus on motion events only within those areas. "
              />
            </div>
          )}
        </div>
      )}
      {activeShape && (
        <Popover
          open={popoverOpen}
          anchorEl={buttonEl.current}
          onClose={closePopover}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          classes={{
            paper:
              "p-3 mt-2 bg-[#FBFBFB] sm:bg-[#323232] text-white border-2 border-[#E1E1E1] sm:border-[#5C5C5C] overflow-visible w-full sm:w-auto",
          }}
        >
          <div className="absolute top-[-7px] left-[85%] sm:left-1/2 -ml-1.5 h-3 w-3 rotate-45 bg-[#FBFBFB] sm:bg-[#323232] border-l-2 border-t-2 border-[#E1E1E1] sm:border-[#5C5C5C]" />
          {activeZone ? (
            <>
              <span className="hidden sm:block">
                <SavedZonePopover
                  cameraId={cameraId}
                  activeZone={activeZone}
                  zones={zones}
                  editZone={() => setModalMode(ZoneModalMode.Edit)}
                  createNew={() => setModalMode(ZoneModalMode.Create)}
                  createAlert={() => setModalMode(ZoneModalMode.Alert)}
                  close={closePopover}
                  compact={multiCamMode || !fitsDesktop}
                />
              </span>
              <span className="sm:hidden">
                <MobileSavedZonePopover
                  cameraId={cameraId}
                  activeZone={activeZone}
                  zones={zones}
                  editZone={() => setModalMode(ZoneModalMode.Edit)}
                  createNew={() => setModalMode(ZoneModalMode.Create)}
                  createAlert={() => setModalMode(ZoneModalMode.Alert)}
                  close={closePopover}
                />
              </span>
            </>
          ) : (
            <>
              <span className="hidden sm:block">
                <TempZonePopover
                  cameraId={cameraId}
                  shape={activeShape}
                  editZone={() => setModalMode(ZoneModalMode.Create)}
                  close={closePopover}
                  compact={multiCamMode || !fitsDesktop}
                />
              </span>
              <span className="sm:hidden">
                <MobileTempZonePopover
                  cameraId={cameraId}
                  shape={activeShape}
                  editZone={() => setModalMode(ZoneModalMode.Create)}
                  close={closePopover}
                />
              </span>
            </>
          )}
        </Popover>
      )}
      <Modal
        open={modalOpen}
        onClose={closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 350 }}
        className="flex-center"
      >
        <Fade in={modalOpen}>
          <Paper className="max-w-3xl h-full sm:h-auto z-1">
            {modalMode === ZoneModalMode.Edit && activeZone && (
              <>
                <span className="hidden md:block">
                  <EditZone
                    zone={activeZone}
                    snapshotSource={playerSnapshot ?? data.camera.still}
                    close={closeModal}
                  />
                </span>

                <span className="md:hidden">
                  <MobileCreateEditZone
                    mode="edit"
                    zone={activeZone}
                    snapshotSource={playerSnapshot ?? data.camera.still}
                    close={closeModal}
                  />
                </span>
              </>
            )}
            {modalMode === ZoneModalMode.Alert && activeZone && (
              <AlertCreate
                defaultAlertType={AlertType.Motion}
                defaultCameras={[{ id: cameraId, zoneIds: [] }]}
                allowCameraSelection={false}
                onCancel={closeModal}
                onCompleted={closeModal}
                contentWrapperClassName="!p-0"
              />
            )}
            {modalMode === ZoneModalMode.Create && (
              <>
                <span className="hidden md:block">
                  <CreateTempZone
                    snapshotSource={playerSnapshot ?? data.camera.still}
                    setZone={(shape: Point[]) => setZone(cameraId, shape)}
                    initialShape={activeZone ? undefined : activeShape}
                    onCreate={() => setPopoverOpen(true)}
                    close={closeModal}
                  />
                </span>

                <span className="md:hidden">
                  <MobileCreateEditZone
                    mode="create"
                    snapshotSource={playerSnapshot ?? data.camera.still}
                    close={closeModal}
                    // zone={
                    //   activeZone
                    //     ? undefined
                    //     : {
                    //         id: 1,
                    //         name: "temp",
                    //         shape: activeShape,
                    //       }
                    // }
                    setTempZone={(shape: Point[]) => {
                      setZone(cameraId, shape);
                      setPopoverOpen(true);
                    }}
                  />
                </span>
              </>
            )}
          </Paper>
        </Fade>
      </Modal>
    </>
  );
}
