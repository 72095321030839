import gql from "graphql-tag";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { AnalyticsViewType, trackView } from "@/util/analytics";
import { formatShortDuration } from "@/util/date";
import { downloadExternalFile } from "@/util/file";

import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { SharedDownloadButton } from "@/components/Player/DownloadButton";
import { GuestVodPlayer } from "@/components/Player/GuestPlayer";

import {
  SharedClipUpdate,
  useExpireSharedClipsMutation,
  useGetSharedClipDownloadLazyQuery,
  useGetSharedClipQuery,
  useIncrementSharedClipViewCountMutation,
  useUpdateSharedClipMutation,
} from "@/generated-models";
import {
  GuestErrorLayout,
  GuestLayout,
  SharedLinkError,
} from "@/layout/GuestLayout";

const useStyles = makeStyles()((theme) => ({
  player: {
    gridArea: "player",
  },
}));

gql`
  query getSharedClip($token: String!) {
    sharedClip(token: $token) {
      ... on AnonymousSharedClip {
        id
        feed
        startTime
        endTime
        audioControlEnabled
        createdAt
        description
        expiry
        createdBy
        sharingPermissions
        stills {
          timestamp
          src
        }
        camera {
          location {
            id
            name
            timezone
          }
        }
      }
      ... on ErrorResult {
        code
        message
      }
      ... on AnonymousSharedClipExpired {
        createdBy
      }
    }
  }
`;

gql`
  query getSharedClipDownload($token: String!) {
    sharedClip(token: $token) {
      ... on AnonymousSharedClip {
        id
        downloadLink
      }
      ... on ErrorResult {
        code
        message
      }
      ... on AnonymousSharedClipExpired {
        createdBy
      }
    }
  }
`;

gql`
  mutation incrementSharedClipViewCount($token: String!) {
    incrementSharedClipViewCount(token: $token)
  }
`;

export function GuestVodView() {
  // const [isFullscreen] = useGlobalFullscreenState("isFullscreen");
  const { classes } = useStyles();
  const { token } = useParams();

  const { loading, data, error } = useGetSharedClipQuery({
    variables: { token: token ?? "" },
  });

  useEffect(() => {
    if (loading || !data?.sharedClip || error) return;
    trackView(AnalyticsViewType.vod, true);
  }, [loading, data, error]);

  const [
    triggerDownload,
    { data: downloadData, stopPolling },
  ] = useGetSharedClipDownloadLazyQuery({
    variables: { token: token! },
    pollInterval: 5000,
    notifyOnNetworkStatusChange: true, // Hack to get onCompleted to fire on every poll
    onCompleted: ({ sharedClip }) => {
      if (
        sharedClip.__typename !== "AnonymousSharedClip" ||
        data?.sharedClip.__typename !== "AnonymousSharedClip"
      ) {
        console.error({ sharedClip, data });
        return;
      }

      if (sharedClip.downloadLink) {
        downloadExternalFile(sharedClip.downloadLink);
      }
    },
  });

  const [
    incrementSharedClipViewCount,
  ] = useIncrementSharedClipViewCountMutation();
  useEffect(() => {
    if (!token) return;
    incrementSharedClipViewCount({ variables: { token } });
  }, [token, incrementSharedClipViewCount]);

  const [expireSharedClips] = useExpireSharedClipsMutation();
  const [updateSharedClip] = useUpdateSharedClipMutation();

  const link =
    downloadData?.sharedClip.__typename === "AnonymousSharedClip" &&
    downloadData.sharedClip.downloadLink;

  useEffect(() => {
    if (link && stopPolling) stopPolling();
  }, [link, stopPolling]);

  if (error) {
    return (
      <GuestErrorLayout>
        <ErrorMessage
          title="Oops"
          description="We could not find the shared clip. Possibly this sharing link has been revoked."
        />
      </GuestErrorLayout>
    );
  }
  if (loading || !data) return <Loading>Loading clip...</Loading>;

  if (data.sharedClip.__typename !== "AnonymousSharedClip") {
    const error = data.sharedClip;
    const description =
      error.__typename === "AnonymousSharedClipExpired" && error.createdBy ? (
        <>
          Ask the creator,{" "}
          <strong style={{ color: "white" }}>{error.createdBy}</strong> to
          extend the clip's expiration date
        </>
      ) : (
        <>
          This might mean the clip has been deleted or you have a wrong link.
          Please contact the owner of the Spot account to request a new link.
        </>
      );
    return (
      <GuestErrorLayout>
        <SharedLinkError
          title={data.sharedClip.message}
          description={description}
        />
      </GuestErrorLayout>
    );
  }

  const duration =
    data?.sharedClip &&
    formatShortDuration(
      new Date(data.sharedClip.startTime),
      new Date(data.sharedClip.endTime)
    );

  const vod = data.sharedClip;
  const timezone = vod.camera.location.timezone;

  function update(update: SharedClipUpdate) {
    if (data?.sharedClip.__typename !== "AnonymousSharedClip") return;

    return updateSharedClip({
      variables: {
        id: data.sharedClip.id,
        update,
      },
      update: (cache, { data }) => {
        if (!data) return;
        cache.modify({
          id: cache.identify({
            id: data.updateSharedClip.id,
            __typename: "AnonymousSharedClip",
          }),
          fields: {
            expiry: () => data.updateSharedClip.expiry ?? null,
            description: () => data.updateSharedClip.description,
          },
        });
      },
    });
  }
  return (
    <GuestLayout
      player={
        <GuestVodPlayer
          className={classes.player}
          cameraName="Shared Clip"
          locationName={vod.camera.location.name}
          sources={{
            tunnel: vod.feed,
            local: "",
            webRTC: null,
          }}
          timezone={timezone}
          audioControlEnabled={vod.audioControlEnabled}
          downloadButton={
            <SharedDownloadButton
              downloadLink={link || undefined}
              startDownload={triggerDownload}
            />
          }
          muxConfig={{
            vodStart: vod.startTime,
            vodEnd: vod.endTime,
            playerName: "Guest Vod Player",
          }}
        />
      }
      title={<>Shared Video Clip{duration && ` (${duration})`}</>}
      expiry={data.sharedClip.expiry ?? null}
      onExpiryChange={
        data.sharedClip.sharingPermissions
          ? (expiry) =>
              update({
                // Please don't convert this to optional chaining
                // because that will coerce null to undefined, which
                // means something else in the context of this mutation
                expiry: expiry && expiry.toISOString(),
              })
          : undefined
      }
      onDisable={
        data.sharedClip.sharingPermissions
          ? () =>
              data.sharedClip.__typename === "AnonymousSharedClip" &&
              expireSharedClips({
                variables: { ids: [data.sharedClip!.id] },

                update: (cache, { data }) => {
                  if (!data) return;
                  cache.modify({
                    id: cache.identify({
                      id: data.expireSharedClips[0].id,
                      __typename: "AnonymousSharedClip",
                    }),
                    fields: {
                      expiry: () => null,
                    },
                  });
                },
              })
          : undefined
      }
      description={data.sharedClip.description}
      onDescriptionChange={
        data.sharedClip.sharingPermissions
          ? (description) =>
              update({
                description,
              })
          : undefined
      }
      locationName={data.sharedClip.camera.location.name}
      createdAt={data.sharedClip.createdAt}
      createdBy={data.sharedClip.createdBy ?? undefined}
    />
  );
}
