import BlockIcon from "@mui/icons-material/Block";
import { Checkbox, Chip, Tooltip, Typography } from "@mui/material";
import clsx from "clsx";
import { utcToZonedTime } from "date-fns-tz";
import { format } from "date-fns/fp";
import uniq from "lodash/uniq";
import { Fragment, useMemo } from "react";
import {
  DelimitedArrayParam,
  StringParam,
  useQueryParam,
} from "use-query-params";

import { useBreakpoints } from "@/util/useBreakpoints";

import { FixedAspectRatio } from "@/components/FixedAspectRatio";
import { StillImage } from "@/components/Still/StillImage";

import { GetLprSearchQuery, useStillsQueryQuery } from "@/generated-models";

import { footageIsAvailable } from "../utils";
import { LicensePlateDetailsPlayer } from "./LicensePlateDetails/LicensePlateDetailsPlayer";
import { LicensePlateNumberIndicator } from "./LicensePlateNumberIndicator";

type LprData = GetLprSearchQuery["lprSearch"][number] & { id: string };
interface LicensePlateTileProps {
  data: LprData;
}

function TileHeader({
  data,
  onToggleTile,
}: {
  data: LprData;
  onToggleTile: () => void;
}) {
  const [selectMultiple] = useQueryParam("multiple-clips", DelimitedArrayParam);
  const hasFootage = footageIsAvailable(data.startTime, data.camera);

  return (
    <div className="border border-b-0 border-solid border-[#DEDEDE] bg-[#F6FbFF] rounded-t-lg p-2">
      <LicensePlateNumberIndicator
        primary
        plate={data.vehicleAttributes?.plate}
      >
        {!hasFootage && (
          <Tooltip
            className="pointer-events-auto cursor-default"
            title="Footage unavailable, storage period exceeded"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="ml-auto text-[#c3c3c3]">
              <BlockIcon />
            </div>
          </Tooltip>
        )}
      </LicensePlateNumberIndicator>
      <div className="flex items-center gap-x-[10px]">
        {selectMultiple && (
          <Checkbox
            size="small"
            classes={{ root: "w-4 h-4 -mt-[1px] p-0" }}
            checked={selectMultiple.includes(data.id)}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => {
              e.stopPropagation();
              onToggleTile();
            }}
          />
        )}
      </div>
    </div>
  );
}

function TileFooter({ data }: { data: LprData }) {
  const startTimeFormatted = useMemo(
    () => [
      format("MMM d, yyyy")(
        utcToZonedTime(new Date(data.startTime), data.camera.location.timezone)
      ),
      format("p")(
        utcToZonedTime(new Date(data.startTime), data.camera.location.timezone)
      ),
    ],
    [data]
  );

  return (
    <div className="border border-t-0 border-solid border-[#DEDEDE] rounded-b-lg p-2">
      <Typography className="text-[14px] leading-[16.41px] md:text-base md:leading-[18.75px] text-[#353D48] font-medium">
        {startTimeFormatted[0]} <strong>{startTimeFormatted[1]}</strong>
      </Typography>
      <Typography className="text-sm leading-4 mb-0.5 truncate">
        {data.camera.name}
      </Typography>
      <Typography className="text-sm leading-4 opacity-[0.66] truncate">
        {data.camera.location.name}
      </Typography>
    </div>
  );
}

function TileStill({ data }: { data: LprData }) {
  const { data: result, loading } = useStillsQueryQuery({
    variables: {
      start: data.startTime,
      end: data.endTime,
      id: data.camera.id,
      limit: 1,
    },
    skip: !data.camera.id,
  });

  const still = result?.stills[0]?.src;

  return !loading ? (
    <FixedAspectRatio ratio="9 / 16">
      <StillImage
        className="h-full w-full object-cover"
        src={still || "/no-still.svg"}
        alt=""
      />
    </FixedAspectRatio>
  ) : (
    <div className="h-[135px] w-full" />
  );
}

function ViewingIndicator() {
  const { fitsDesktop } = useBreakpoints();
  return (
    <>
      <div className="absolute w-full h-full flex items-center justify-center">
        <Chip
          className={clsx("font-bold z-10", {
            "text-lg px-3": !fitsDesktop,
          })}
          label="Viewing"
          color="primary"
        />
      </div>
      <div className="absolute bg-[#001C34]/[0.4] w-full h-full rounded-lg" />
    </>
  );
}

export function LicensePlateTile({ data }: LicensePlateTileProps) {
  const [selectMultiple, setSelectMultiple] = useQueryParam(
    "multiple-clips",
    DelimitedArrayParam
  );
  const { fitsDesktop } = useBreakpoints();
  const [viewing, setViewing] = useQueryParam("viewing", StringParam);
  const isViewing = viewing === data.id;
  const hasFootage = footageIsAvailable(data.startTime, data.camera);

  function onToggleTile() {
    const selectedMultipleResolved = selectMultiple || [];
    const checked = selectedMultipleResolved.includes(data.id);
    if (!checked) {
      setSelectMultiple(uniq([...selectedMultipleResolved, data.id]));
    } else {
      const result = [...selectedMultipleResolved];
      result.splice(result.indexOf(data.id), 1);
      setSelectMultiple(result.length > 0 ? result : [""]);
    }
  }

  return (
    <>
      {isViewing && !fitsDesktop && (
        <div className="col-span-full -mx-4">
          <LicensePlateDetailsPlayer />
        </div>
      )}
      <div
        id={data.id}
        className={clsx("relative", {
          "pointer-events-none": (isViewing && !selectMultiple) || !hasFootage,
          "cursor-pointer": !isViewing || selectMultiple,
        })}
        onClick={() => {
          if (selectMultiple) {
            onToggleTile();
          } else {
            setViewing(data.id);
          }
        }}
      >
        {isViewing && <ViewingIndicator />}
        <div className="bg-white md:min-w-[220px]">
          <TileHeader data={data} onToggleTile={onToggleTile} />
          <div className="border-l border-r border-solid border-[#DEDEDE]">
            <TileStill data={data} />
          </div>
          <div>
            <TileFooter data={data} />
          </div>
        </div>
      </div>
    </>
  );
}
