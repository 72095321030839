import TimelapseIcon from "@mui/icons-material/Timelapse";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import {
  Avatar,
  Hidden,
  Typography,
  Button,
  Popover,
  Tooltip,
} from "@mui/material";
import clsx from "clsx";
import { utcToZonedTime } from "date-fns-tz";
import { useFlags } from "launchdarkly-react-client-sdk";
import { capitalize } from "lodash";
import React, { ReactNode, useMemo } from "react";
import { Chart } from "react-charts";
import { BooleanParam, StringParam, useQueryParam } from "use-query-params";

import { ReactComponent as ExpandIcon } from "@/icons/icon-expand.svg";
import { ReactComponent as MinimizeIcon } from "@/icons/icon-min.svg";

import { formatDurationSecs } from "@/util/formatDurationSecs";

import {
  DashboardDatePicker,
  Range,
} from "@/pages/Intelligence/IntelligenceDashboardView";
import { intelligentFiltersConfig } from "@/pages/Search/intelligence/intelligence";

import { CopyableTextInput } from "@/components/CopyableTextInput";

import {
  IdleBucket,
  IdleMetrics,
  IntelligenceDashboardContentQuery,
  IntelligenceDashboardType,
} from "@/generated-models";

import {
  primaryAxis,
  secondaryAxes,
  useReactChartStyles,
} from "../ChartConfig";
import { IntLoadingIndicator } from "../IntLoadingIndicator";
import { IntelligenceFullScreenView } from "../IntelligenceFullScreenView";
import {
  EntityColors,
  EntityLabel,
  EntityType,
  IntelligenceFeatureType,
} from "../constants";
import { useIntelligenceDateRange } from "../hooks";
import {
  getIntObjectFilterConfig,
  getObjectLabel,
  getObjectTypeIcon,
} from "../utils";
import IdleDashboardEntitiesDatagrid from "./IdleDashboardEntitiesDatagrid";

export type IdleBuckets = Pick<
  IdleBucket,
  "bucket" | "totalCount" | "idleCount"
>[];

export default function IntelligenceDashboardDebug({
  bqJobId,
}: {
  bqJobId: string | undefined;
}) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "intelligence-dashboard-debug" : undefined;

  return (
    <div className="flex justify-end mt-4 md:mt-6">
      <Button
        aria-describedby={id}
        variant="contained"
        color="primary"
        onClick={handleClick}
      >
        Debug Dashboard
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <CopyableTextInput
          className="w-96 m-2"
          label="BigQuery Job Id"
          disabled={!bqJobId}
          fullWidth
          value={bqJobId ?? ""}
        />
      </Popover>
    </div>
  );
}

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export type IdleDashboardMetrics = Optional<
  Pick<
    IdleMetrics,
    | "bqJobId"
    | "totalCount"
    | "idleCount"
    | "averageIdleTimeSeconds"
    | "averageNonzeroIdleTimeSeconds"
    | "maxIdleTimeSeconds"
    | "entities"
    | "bucketCounts"
  >,
  "bqJobId"
>;

export function MobileIdleDashboard({
  cameraId,
  feature,
  objectTypes,
  dashboard,
  timezone,
}: {
  feature: IntelligenceFeatureType;
  objectTypes?: string[] | string | null;
  cameraId: number;
  intDashId?: number;
  dashboard?: IntelligenceDashboardContentQuery["intelligenceDashboard"];
  timezone: string;
  startDate?: Date;
  endDate?: Date;
}) {
  const entity = EntityLabel[feature];
  const objectLabel = getObjectLabel(
    objectTypes,
    IntelligenceDashboardType.Idle
  );
  const objectLabelPlural = getObjectLabel(
    objectTypes,
    IntelligenceDashboardType.Idle,
    true
  );

  const { range } = useIntelligenceDateRange();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setFullscreen] = useQueryParam("fullscreen", BooleanParam);
  const thresholdString = `>${formatDurationSecs(
    dashboard?.thresholdSeconds || 0,
    {
      hideZeroes: true,
    }
  )}`;

  return (
    <IntelligenceFullScreenView>
      <div className="flex items-center justify-between mt-4 mx-4">
        <div className="text-lg leading-[20px] font-bold">
          {capitalize(objectLabel)} Idle Counts
        </div>
        <DashboardDatePicker cameraId={cameraId} />
        <Button
          startIcon={<MinimizeIcon className="fill-current text-primary" />}
          color="primary"
          onClick={() => setFullscreen(false)}
        >
          Minimize
        </Button>
      </div>
      {!dashboard && <IntLoadingIndicator className="py-10 md:py-20" />}
      {dashboard && (
        <div className="px-5 py-6">
          <IdleDashboardChart
            entity={entity}
            label={objectLabelPlural}
            buckets={dashboard?.metrics.results!.bucketCounts}
            timezone={timezone}
            primaryTicks={range === Range.Week ? 7 : undefined}
          />
          <div className="flex justify-center items-center md:px-0 px-3 mt-6 md:mt-10 mb-2 md:mb-3">
            <div className="flex items-center">
              <div
                className={clsx(
                  "md:h-6 md:w-6 h-[14px] w-[14px] md:rounded mr-2",
                  {
                    "bg-[#37D3F5]": entity === "vehicle",
                    "bg-[#FFB55F]": entity === "forklift",
                    "bg-[#9947FF]": entity === "people",
                  }
                )}
              />
              <Typography className="text-xs md:text-sm">
                Total {capitalize(objectLabelPlural)}
              </Typography>
            </div>
            <div className="flex items-center ml-8">
              <div
                className={clsx(
                  "md:h-6 md:w-6 h-[14px] w-[14px] md:rounded mr-2",
                  {
                    "bg-[#00AFD6]": entity === "vehicle",
                    "bg-[#e07901]": entity === "forklift",
                    "bg-[#6500E4]": entity === "people",
                  }
                )}
              />
              <Typography className="text-xs md:text-sm">
                {capitalize(objectLabelPlural)} Idle {thresholdString}
              </Typography>
            </div>
          </div>
        </div>
      )}
    </IntelligenceFullScreenView>
  );
}

export function IdleDashboard({
  feature,
  objectTypes,
  cameraId,
  metrics,
  thresholdSeconds,
  timezone,
  datePicker,
  zoneId,
}: {
  feature: IntelligenceFeatureType;
  objectTypes?: string[] | string | null;
  cameraId: number;
  metrics: IdleDashboardMetrics;
  thresholdSeconds: number;
  timezone: string;
  datePicker?: ReactNode;
  zoneId?: number | null;
}) {
  const [range] = useQueryParam("range", StringParam);
  const entity = EntityLabel[feature];
  const [fullscreen = false, setFullscreen] = useQueryParam(
    "fullscreen",
    BooleanParam
  );
  const thresholdString = `>${formatDurationSecs(thresholdSeconds, {
    hideZeroes: true,
  })}`;
  const renderStationaryIdleTime =
    metrics.averageNonzeroIdleTimeSeconds != null;
  let {
    intelligenceDashboardDebugViews,
    intelligenceThresholdedIdleTimeAverage,
  } = useFlags();

  const TypeIcon = getObjectTypeIcon(
    objectTypes || intelligentFiltersConfig.vehicle.objectIds,
    IntelligenceDashboardType.Idle
  );

  const objectLabelPlural = getObjectLabel(
    objectTypes,
    IntelligenceDashboardType.Idle,
    true
  );

  const legend = (
    <>
      <div className="flex items-center md:ml-auto">
        <div
          className={clsx("md:h-6 md:w-6 h-[14px] w-[14px] md:rounded mr-2", {
            "bg-[#00AFD6]": entity === "vehicle",
            "bg-[#e07901]": entity === "forklift",
            "bg-[#6500E4]": entity === "people",
          })}
        />
        <Typography className="text-xs md:text-sm">
          Total {objectLabelPlural}
        </Typography>
      </div>
      <div className="flex items-center ml-8">
        <div
          className={clsx("md:h-6 md:w-6 h-[14px] w-[14px] md:rounded mr-2", {
            "bg-[#37D3F5]": entity === "vehicle",
            "bg-[#FFB55F]": entity === "forklift",
            "bg-[#9947FF]": entity === "people",
          })}
        />
        <Typography className="text-xs md:text-sm">
          {capitalize(objectLabelPlural)} Idle {thresholdString}
        </Typography>
      </div>
    </>
  );

  return (
    <>
      {intelligenceDashboardDebugViews && (
        <IntelligenceDashboardDebug bqJobId={metrics.bqJobId} />
      )}
      <div
        className={clsx("gap-2 md:gap-4 mt-4 md:mt-6", {
          "grid grid-cols-2 sm:grid-cols-3": renderStationaryIdleTime,
          "flex flex-wrap": !renderStationaryIdleTime,
        })}
      >
        <MetricBlock
          entity={entity}
          metric={
            <>
              {metrics.totalCount}{" "}
              {<span className="text-base">{objectLabelPlural}</span>}
            </>
          }
          tooltipTitle={
            !!zoneId
              ? `Total number of ${objectLabelPlural} that passed through the selected zone`
              : `Total number of ${objectLabelPlural} detected`
          }
          label={
            <>
              <strong>
                Total
                <br />
                {capitalize(objectLabelPlural)}
              </strong>
            </>
          }
          icon={
            <TypeIcon className="text-[#353D48] md:text-[#C6C6C6] mb-[2px] h-5 md:p-0 p-0.5" />
          }
        />
        <MetricBlock
          entity={entity}
          metric={
            <>
              {metrics.idleCount}{" "}
              {<span className="text-base">{objectLabelPlural}</span>}
            </>
          }
          tooltipTitle={`Number of ${objectLabelPlural} idle for more than the threshold`}
          label={
            <>
              <strong>
                <>
                  {capitalize(objectLabelPlural)}
                  <br />
                </>
                Idle {thresholdString}
              </strong>
            </>
          }
          icon={
            <TypeIcon className="text-[#353D48] md:text-[#C6C6C6] mb-[2px] h-5 md:p-0 p-0.5" />
          }
        />
        <MetricBlock
          entity={entity}
          metric={`${
            metrics.totalCount &&
            Math.round((metrics.idleCount / metrics.totalCount) * 1000) / 10
          }%`}
          tooltipTitle={`Percentage of ${objectLabelPlural} that were idle for more than the threshold out of the total ${objectLabelPlural}`}
          label={
            <strong>
              Percent
              <br />
              Idle {thresholdString}
            </strong>
          }
          icon={
            <TimelapseIcon className="text-[#353D48] md:text-[#C6C6C6] h-5 md:p-0 p-0.5" />
          }
        />
        <MetricBlock
          entity={entity}
          metric={formatDurationSecs(metrics.averageIdleTimeSeconds)}
          tooltipTitle={`Average idle time of all ${objectLabelPlural} that were idle for more than the threshold`}
          label={
            <>
              <strong>Average</strong>
              <br />
              Idle Time
              {intelligenceThresholdedIdleTimeAverage && ` ${thresholdString}`}
            </>
          }
          icon={
            <WatchLaterIcon className="text-[#353D48] md:text-[#C6C6C6] h-5 md:p-0 p-0.5" />
          }
        />
        {renderStationaryIdleTime && (
          <MetricBlock
            entity={entity}
            tooltipTitle={`Average idle time of all ${objectLabelPlural} that have been stationary`}
            metric={formatDurationSecs(metrics.averageNonzeroIdleTimeSeconds!)}
            label={
              <>
                <strong>Average</strong>
                <br />
                Stationary Idle Time
              </>
            }
            icon={
              <WatchLaterIcon className="text-[#353D48] md:text-[#C6C6C6] h-5 md:p-0 p-0.5" />
            }
          />
        )}
        <MetricBlock
          entity={entity}
          metric={formatDurationSecs(metrics.maxIdleTimeSeconds)}
          tooltipTitle={`Longest amount of time a ${objectLabelPlural} was idle`}
          label={
            <>
              <strong>Longest</strong>
              <br />
              Idle Time
            </>
          }
          icon={
            <WatchLaterIcon className="text-[#353D48] md:text-[#C6C6C6] h-5 md:p-0 p-0.5" />
          }
        />
      </div>
      <div className="flex items-center md:px-0 px-3 mt-6 md:mt-10 mb-2 md:mb-3">
        <Typography className="text-sm font-medium">
          {capitalize(entity)} Idle Counts
        </Typography>
        <Hidden mdDown>{legend}</Hidden>
        <Hidden mdUp>
          <div className="grow"></div>
          {metrics.bucketCounts.length > 0 && (
            <Button
              startIcon={<ExpandIcon className="fill-current text-primary" />}
              color="primary"
              onClick={() => setFullscreen(true)}
            >
              Expand
            </Button>
          )}
        </Hidden>
      </div>
      {metrics.bucketCounts.length > 0 ? (
        <div className="md:px-0 px-3">
          <IdleDashboardChart
            entity={entity}
            label={objectLabelPlural}
            buckets={metrics.bucketCounts}
            timezone={timezone}
            primaryTicks={range === Range.Week ? 7 : undefined}
          />
        </div>
      ) : (
        <div className="h-40 md:h-52 flex-center">
          <div>No available data</div>
        </div>
      )}
      <Hidden mdUp>
        <div className="flex-center pt-3">{legend}</div>
      </Hidden>

      <div className="mt-8">
        <IdleDashboardEntitiesDatagrid
          objectIds={
            getIntObjectFilterConfig(
              objectTypes,
              IntelligenceDashboardType.Idle
            ).objectIds
          }
          entities={metrics.entities}
          cameraId={cameraId}
          timezone={timezone}
        />
      </div>
      <Hidden mdUp>
        {fullscreen && (
          <IntelligenceFullScreenView>
            <div className="flex items-center justify-between mt-4 mx-4">
              <div className="text-lg leading-[20px] font-bold">
                Activity Map
              </div>
              {datePicker}
              <Button
                startIcon={
                  <MinimizeIcon className="fill-current text-primary" />
                }
                color="primary"
                onClick={() => setFullscreen(false)}
              >
                Minimize
              </Button>
            </div>
            <div className="px-5 py-6">
              <IdleDashboardChart
                entity={entity}
                label={objectLabelPlural}
                buckets={metrics.bucketCounts}
                timezone={timezone}
                primaryTicks={range === Range.Week ? 7 : undefined}
              />
              <div className="flex justify-center items-center md:px-0 px-3 mt-6 md:mt-10 mb-2 md:mb-3">
                {legend}
              </div>
            </div>
          </IntelligenceFullScreenView>
        )}
      </Hidden>
    </>
  );
}

export function MetricBlock({
  entity,
  metric,
  label,
  icon,
  className,
  tooltipTitle = "",
}: {
  entity: EntityType;
  metric: React.ReactNode;
  label: React.ReactNode;
  icon?: React.ReactNode;
  className?: string;
  tooltipTitle?: string;
}) {
  return (
    <Tooltip title={tooltipTitle}>
      <div
        className={clsx(
          "rounded-lg px-2 py-3 md:p-4 min-h-[88px] md:min-h-[unset] h-full shadow-paper flex-grow flex flex-col justify-between",
          className
        )}
      >
        <div className="flex flex-row justify-bewteen mb-1 md:mb-6">
          <div className="text-sm md:text-xl flex-grow leading-[0.875rem] md:leading-none">
            {label}
          </div>
          {icon && (
            <Avatar className="md:w-8 md:h-8 w-5 h-5 border-solid rounded-full border-gray-200 border md:border-4 bg-transparent text-gray-400 flex justify-center items-center">
              {icon}
            </Avatar>
          )}
        </div>
        <div
          className={clsx("text-2xl md:text-[38px] leading-none font-bold", {
            "text-[#00AFD6]": entity === "vehicle",
            "text-[#e07901]": entity === "forklift",
            "text-[#6500E4]": entity === "people",
          })}
        >
          {metric}
        </div>
      </div>
    </Tooltip>
  );
}

function IdleDashboardChart({
  entity,
  label,
  buckets,
  timezone,
  primaryTicks,
}: {
  entity: EntityType;
  label: string;
  buckets: IdleMetrics["bucketCounts"];
  timezone: string;
  primaryTicks?: number;
}) {
  const { classes } = useReactChartStyles();
  const chartData = useMemo(() => {
    const mapped = buckets.map((b) => ({
      date: utcToZonedTime(new Date(b.bucketMs), timezone),
      idle: b.idleCount,
      total: b.totalCount - b.idleCount,
    }));
    return [
      {
        label: `${capitalize(label)} Idle`,
        data: mapped.map((b) => ({ primary: b.date, secondary: b.idle })),
      },
      {
        label: capitalize(label),
        data: mapped.map((b) => ({ primary: b.date, secondary: b.total })),
      },
    ];
  }, [buckets, timezone, label]);

  return (
    <div className={clsx("h-40 sm:h-60 md:h-80", classes.hideChartLines)}>
      <Chart
        options={{
          data: chartData,
          primaryAxis: primaryAxis(primaryTicks),
          secondaryAxes,
          primaryCursor: { showLabel: false },
          secondaryCursor: { showLabel: false },
          defaultColors: EntityColors[entity],
        }}
      />
    </div>
  );
}
