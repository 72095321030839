import clsx from "clsx";
import { utcToZonedTime } from "date-fns-tz";
import { format } from "date-fns/fp";
import gql from "graphql-tag";
import { uniqBy, uniq } from "lodash/fp";
import { useMemo } from "react";
import { useQueryParam, StringParam } from "use-query-params";

import { FilterDateField } from "@/components/Filter/Fields/FilterDateField";
import { FilterBar } from "@/components/Filter/FilterBar";
import { FilterBarControls } from "@/components/Filter/FilterBarControls";
import { useTransparentFilterFieldStyles } from "@/components/Filter/constant";

import { useCamerasLprQuery } from "@/generated-models";

import { MINIMUM_QUERY_LENGTH } from "../constants";
import { useLprSearch } from "../hooks";
import { LicensePlateSearchInput } from "./LicensePlateSearchInput";

export function LicensePlateSearchBar({ className }: { className?: string }) {
  const { classes } = useTransparentFilterFieldStyles();
  const { data, loading } = useLprSearch();
  const { data: camerasData, loading: camerasLoading } = useCamerasLprQuery();

  const lprCameras = useMemo(
    () =>
      camerasData?.cameras?.filter((camera) => camera.settings.lprEnabled) ||
      [],
    [camerasData]
  );

  const sortedLocations = useMemo(() => {
    return uniq(lprCameras.map((cam) => cam.location).flat()).sort((a, b) =>
      a.name.localeCompare(b.name)
    );
  }, [lprCameras]);

  const filters = useMemo(
    () => [
      {
        placeholder: "Dates & Times",
        filterName: "datetime",
        label: "Date & Time",
        classes,
        timezone: lprCameras[0]?.location?.timezone,
        getValue: (value: string | null) => {
          return (
            value
              ?.split("|")
              .map((vi) =>
                format("MMM d, yyyy p")(
                  utcToZonedTime(
                    new Date(vi),
                    data[0]?.camera?.location?.timezone
                  )
                )
              )
              .join(" - ") || ""
          );
        },
        options: [],
        date: true,
      },
      {
        placeholder: "Locations & Groups",
        label: "Locations & Groups",
        classes,
        filterName: "locations",
        options: uniqBy(
          "value",
          sortedLocations?.map((d) => ({
            label: d.name,
            value: String(d.id),
          })) || []
        ),
      },
      {
        placeholder: "Cameras",
        filterName: "cameras",
        label: "Cameras",
        classes,
        options: uniqBy(
          "value",
          lprCameras?.map((d) => ({
            label: d.name,
            value: String(d.id),
            secondaryLabel: d.location.name,
          })) || []
        ),
      },
    ],
    [classes, data, lprCameras, sortedLocations]
  );

  const [searchFilter] = useQueryParam("search", StringParam);
  const showHelp =
    searchFilter &&
    searchFilter.length < MINIMUM_QUERY_LENGTH &&
    searchFilter.length > 0;

  return (
    <div
      className={clsx(
        "bg-[#DDEFFF] shadow-[1px_8px_33px_rgba(0,0,0,0.08)] rounded-2xl pt-3 lg:pt-7",
        className
      )}
    >
      <div
        className={clsx(
          "flex lg:flex-row flex-col justify-between items-center gap-2 px-2 lg:px-7 lg:mb-7 mb-3 flex-wrap",
          {
            "gap-y-6": showHelp,
          }
        )}
      >
        <LicensePlateSearchInput />
        <FilterBarControls
          className={clsx(showHelp && "-mt-6")}
          filters={filters}
          components={{
            datetime: FilterDateField,
          }}
        />
      </div>
      {!camerasLoading && !loading && (
        <FilterBar className="rounded-b-2xl py-2" filters={filters} />
      )}
    </div>
  );
}

export const CAMERAS_LPR = gql`
  query camerasLpr {
    cameras {
      id
      name
      settings {
        lprEnabled
      }
      location {
        id
        name
        timezone
      }
    }
  }
`;
