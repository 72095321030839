// @ts-nocheck
import { Tooltip } from "@mui/material";
import clsx from "clsx";
import React, { useState } from "react";
import { makeStyles } from "tss-react/mui";

import { useBreakpoints } from "@/util/useBreakpoints";

import { CountDirection } from "@/pages/Intelligence/constants";

import {
  IN_OUT_CIRCLE_WIDTH,
  LINE_HOVER_POINT_WIDTH,
  LINE_POINT_WIDTH,
  LINE_WIDTH,
} from "./constants";
import {
  getCirclePoints,
  getCursorPoint,
  getTouchPoint,
  isLineOutOfBounds,
  swapPoints,
} from "./drawingUtils";
import { Point } from "./getSectorsForPolygon";

const useStyles = makeStyles()(() => ({
  drawBoard: {
    cursor: `url(/pen_pointer.svg) 0 18, auto`,
  },
  removePoint: {
    cursor: `url(/cross_pointer.svg) 8 8, auto`,
  },
  complete: {
    cursor: `url(/pen_pointer.svg) 0 18, auto`,
  },
}));

type DrawZoneStyle = {
  color?: string;
  fillOpacity?: number;
};

type DrawZoneShapeProps = {
  strokeWidth?: string;
  strokeDasharray?: string;
  strokeDashoffset?: string;
};

type DrawZoneProps = {
  shape: Point[];
  setShape: React.Dispatch<React.SetStateAction<Point[]>>;
  editing: boolean;
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
  enableEdit?: boolean;
  style?: DrawZoneStyle;
  shapeProps?: DrawZoneShapeProps;
  animated?: boolean;
};

function AnimatedStroke() {
  return (
    <animate
      attributeName="stroke-dashoffset"
      values="100;0"
      dur="3s"
      calcMode="linear"
      repeatCount="indefinite"
    />
  );
}

export default function DrawZone({
  shape,
  setShape,
  editing,
  setEditing,
  enableEdit = true,
  style,
  shapeProps,
  animated,
}: DrawZoneProps) {
  const { classes } = useStyles();
  const [cursorPosition, setCursorPosition] = useState<Point | null>(null);
  const resolvedColor = style?.color || "#ef00b6";
  const baseShapeProps = shapeProps || {};
  const resolvedChild = animated ? <AnimatedStroke /> : <></>;

  return (
    <svg
      className={clsx("absolute inset-0 z-1 w-full h-full", {
        [classes.drawBoard]: editing || enableEdit,
      })}
      viewBox="0 0 100 100"
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={(e) => {
        if (editing) {
          const pt = e.currentTarget.createSVGPoint();
          pt.x = e.clientX;
          pt.y = e.clientY;

          // get cursor point inside the SVG
          const cursorPoint = pt.matrixTransform(
            e.currentTarget.getScreenCTM()?.inverse()
          );

          setShape((value) => [
            ...value,
            {
              x: Number(cursorPoint.x.toFixed(1)),
              y: Number(cursorPoint.y.toFixed(1)),
            },
          ]);
        } else if (enableEdit) {
          setEditing(true);
        }
      }}
      onMouseMove={(e) => {
        if (editing) {
          const pt = e.currentTarget.createSVGPoint();
          pt.x = e.clientX;
          pt.y = e.clientY;
          const cursorPoint = pt.matrixTransform(
            e.currentTarget.getScreenCTM()?.inverse()
          );
          setCursorPosition({
            x: Number(cursorPoint.x.toFixed(2)),
            y: Number(cursorPoint.y.toFixed(2)),
          });
        }
      }}
    >
      {editing ? (
        <>
          {/*draw the lines first because the circles need to be clickable*/}
          {shape.slice(1).map((point, idx) => {
            return (
              <line
                key={idx}
                x1={shape[idx].x}
                y1={shape[idx].y}
                x2={point.x}
                y2={point.y}
                strokeWidth={2}
                {...baseShapeProps}
                vectorEffect="non-scaling-stroke"
                stroke={resolvedColor}
              >
                {resolvedChild}
              </line>
            );
          })}
          {/*draw the gray line*/}
          {shape.length > 0 && cursorPosition && (
            <line
              x1={shape[shape.length - 1].x}
              y1={shape[shape.length - 1].y}
              x2={cursorPosition.x}
              y2={cursorPosition.y}
              strokeWidth={2}
              {...baseShapeProps}
              vectorEffect="non-scaling-stroke"
              stroke="#C9C4C8"
            >
              {resolvedChild}
            </line>
          )}

          {/*draw the circles*/}
          {shape.map((point, idx) => {
            const isCompleteShape = idx === 0 && shape.length > 2;
            return (
              <Tooltip
                key={idx}
                classes={{ popper: "pointer-events-none" }}
                title={
                  isCompleteShape
                    ? "Click to complete the zone"
                    : "Click to remove dot"
                }
              >
                <line
                  x1={point.x}
                  y1={point.y}
                  x2={point.x}
                  y2={point.y}
                  stroke={resolvedColor}
                  strokeLinecap="round"
                  strokeWidth={8}
                  vectorEffect="non-scaling-stroke"
                  className={clsx(
                    "z-1 hover:stroke-[12px]",
                    isCompleteShape ? classes.complete : classes.removePoint
                  )}
                  onClick={(e) => {
                    e.stopPropagation();
                    // finish creating the zone when the first points
                    // is clicked again. Check that it's a polygon
                    if (isCompleteShape) {
                      setEditing(false);
                      return;
                    }

                    // If it's the last point, clicking the circle should delete the last line
                    // Remove points by clicking them
                    const newShape = [...shape];
                    newShape.splice(idx, 1);
                    setShape(newShape);
                  }}
                />
              </Tooltip>
            );
          })}
        </>
      ) : (
        <polygon
          points={shape.map((p) => `${p.x}, ${p.y}`).join(" ")}
          stroke={resolvedColor}
          strokeWidth={3}
          {...baseShapeProps}
          vectorEffect="non-scaling-stroke"
          fill={resolvedColor}
          fillOpacity={style?.fillOpacity ?? 0.2}
        >
          {resolvedChild}
        </polygon>
      )}
    </svg>
  );
}

type MobileDrawZoneProps = {
  shape: Point[];
  setShape: React.Dispatch<React.SetStateAction<Point[]>>;
  backgroundRatio: number;
};

function isOutOfBounds(shape: Point[]): Boolean {
  return shape.some((p) => p.x > 100 || p.y > 100 || p.x < 0 || p.y < 0);
}

const POINT_RADIUS = 6;
const HOVER_POINT_RADIUS = POINT_RADIUS + 4;

export function MobileDrawZone({
  shape,
  setShape,
  backgroundRatio,
}: MobileDrawZoneProps) {
  const { classes } = useStyles();
  const [selectedIdx, setSelectedIdx] = useState<number | null>(null);

  return (
    <svg
      className={clsx("absolute inset-0 w-full h-full", classes.drawBoard)}
      viewBox="0 0 100 100"
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
      onTouchMove={(e) => {
        const touch = e.touches[0];
        const pt = e.currentTarget.createSVGPoint();
        pt.x = touch.clientX;
        pt.y = touch.clientY;
        const cursorPoint = pt.matrixTransform(
          e.currentTarget.getScreenCTM()?.inverse()
        );
        if (selectedIdx !== null) {
          const newShape = shape.map((p, idx) => {
            if (idx === selectedIdx) {
              return { x: cursorPoint.x, y: cursorPoint.y };
            }
            return p;
          });
          if (!isOutOfBounds(newShape)) {
            setShape(newShape);
          }
        }
      }}
    >
      <polygon
        points={shape.map((p) => `${p.x}, ${p.y}`).join(" ")}
        stroke="#ef00b6"
        strokeWidth={3}
        vectorEffect="non-scaling-stroke"
        fill="#ef00b6"
        fillOpacity={0.2}
      />
      {shape.map((p, idx) => (
        <React.Fragment key={idx}>
          {selectedIdx === idx && (
            <ellipse
              cx={p.x}
              cy={p.y}
              rx={backgroundRatio * HOVER_POINT_RADIUS}
              ry={HOVER_POINT_RADIUS}
              fill="#ffffff"
              className="z-20 opacity-50"
            />
          )}
          <ellipse
            cx={p.x}
            cy={p.y}
            rx={backgroundRatio * POINT_RADIUS}
            ry={POINT_RADIUS}
            fill="#ef00b6"
            className="z-20"
            onTouchStart={() => {
              setSelectedIdx(idx);
            }}
            onTouchEnd={() => {
              setSelectedIdx(null);
            }}
          />
        </React.Fragment>
      ))}
    </svg>
  );
}

type DrawLineProps = {
  line: Point[];
  setLine: (line: Point[]) => void;
  measurements?: DOMRectReadOnly;
  countDirection: CountDirection;
};

export function DrawLine({
  line,
  setLine,
  measurements,
  countDirection,
}: DrawLineProps) {
  const { classes } = useStyles();
  const { fitsTablet } = useBreakpoints();
  const [selectedIdx, setSelectedIdx] = useState<number | null>(null);
  const [cursorPosition, setCursorPosition] = useState<Point | null>(null);

  const circlePoints = line.length === 2 ? getCirclePoints(line) : null;
  return (
    <svg
      className={clsx("absolute inset-0 w-full h-full", classes.drawBoard, {
        "cursor-default": line.length === 2,
      })}
      viewBox={`0 0 ${measurements?.width} ${measurements?.height}`}
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
      onTouchMove={(e) => {
        if (selectedIdx !== null) {
          const point = getTouchPoint(e);
          const newLine = line.map((currPT, idx) =>
            idx === selectedIdx ? point : currPT
          );
          if (!isLineOutOfBounds(line, measurements)) {
            setLine(newLine);
          }
        }
      }}
      onTouchEnd={(e) => e.preventDefault()}
      onClick={(e) => {
        if (line.length === 0) {
          const point = getCursorPoint(e);
          setLine([point]);
        }
        if (line.length === 1) {
          const point = getCursorPoint(e);
          setLine(line.concat(point));
          setCursorPosition(null);
        }
      }}
      onMouseMove={(e) => {
        if (line.length === 1) {
          const point = getCursorPoint(e);
          setCursorPosition(point);
        }
        if (selectedIdx !== null) {
          const point = getCursorPoint(e);
          const newLine = line.map((currPT, idx) =>
            idx === selectedIdx ? point : currPT
          );
          if (!isLineOutOfBounds(newLine, measurements)) {
            setLine(newLine);
          }
        }
      }}
    >
      <marker
        id="markerArrowOut"
        markerWidth="10"
        markerHeight="7"
        refX="0"
        refY="3.5"
        orient="auto"
        fill="#890068"
      >
        <polygon points="0 0, 5 3.5, 0 7" />
      </marker>
      <marker
        id="markerArrowIn"
        markerWidth="10"
        markerHeight="7"
        refX="0"
        refY="3.5"
        orient="auto"
        fill="#ef00b6"
      >
        <polygon points="0 0, 5 3.5, 0 7" />
      </marker>
      {line.length === 2 && (
        <line
          x1={line[0].x}
          y1={line[0].y}
          x2={line[1].x}
          y2={line[1].y}
          stroke="#ef00b6"
          strokeWidth={LINE_WIDTH}
        />
      )}
      {line.length === 1 && cursorPosition !== null && (
        <line
          x1={line[0].x}
          y1={line[0].y}
          x2={cursorPosition.x}
          y2={cursorPosition.y}
          strokeDasharray="5"
          stroke="#ef00b6"
          strokeWidth={LINE_WIDTH}
        />
      )}
      {line.map((p, idx) => (
        <React.Fragment key={idx}>
          {(selectedIdx === idx || !fitsTablet) && (
            <circle
              cx={p.x}
              cy={p.y}
              r={LINE_HOVER_POINT_WIDTH / 2}
              fill="#ffffff"
              className={clsx("z-20 opacity-50", {
                "cursor-move": line.length === 2,
              })}
              onTouchStart={() => {
                setSelectedIdx(idx);
              }}
              onTouchEnd={(e) => {
                e.preventDefault();
                setSelectedIdx(null);
              }}
            />
          )}
          <circle
            cx={p.x}
            cy={p.y}
            r={LINE_POINT_WIDTH / 2}
            fill="#FFFFFF"
            strokeWidth={LINE_WIDTH}
            stroke="#ef00b6"
            className={clsx("z-20", { "cursor-move": line.length === 2 })}
            onTouchStart={() => {
              setSelectedIdx(idx);
            }}
            onTouchEnd={(e) => {
              e.preventDefault();
              setSelectedIdx(null);
            }}
            onMouseDown={() => {
              setSelectedIdx(idx);
            }}
            onMouseUp={(e) => {
              setSelectedIdx(null);
            }}
          />
        </React.Fragment>
      ))}
      {circlePoints && (
        <>
          {countDirection !== CountDirection.Out && (
            <>
              <circle
                cx={circlePoints.in.x}
                cy={circlePoints.in.y}
                r={IN_OUT_CIRCLE_WIDTH / 2}
                fill="#ef00b6"
                className="z-20 cursor-pointer"
                onClick={() => setLine(swapPoints(line))}
              />
              <text
                x={circlePoints.in.x}
                y={circlePoints.in.y + 4}
                textAnchor="middle"
                className="text-[10px] fill-white cursor-pointer"
                onClick={() => setLine(swapPoints(line))}
              >
                In
              </text>
              <line
                x1={(line[0].x + line[1].x) / 2}
                y1={(line[0].y + line[1].y) / 2}
                x2={circlePoints.inarrow.x}
                y2={circlePoints.inarrow.y}
                style={{
                  strokeWidth: 3,
                  stroke: "#ef00b6",
                  markerHeight: 8,
                  markerWidth: 8,
                  markerEnd: "url(#markerArrowIn)",
                }}
              />
            </>
          )}

          {countDirection !== CountDirection.In && (
            <>
              <circle
                cx={circlePoints.out.x}
                cy={circlePoints.out.y}
                r={IN_OUT_CIRCLE_WIDTH / 2}
                fill="#890068"
                className="z-20 cursor-pointer"
                onClick={() => setLine(swapPoints(line))}
              />
              <text
                x={circlePoints.out.x}
                y={circlePoints.out.y + 4}
                textAnchor="middle"
                className="text-[10px] fill-white cursor-pointer"
                onClick={() => setLine(swapPoints(line))}
              >
                Out
              </text>
              <line
                x1={(line[0].x + line[1].x) / 2}
                y1={(line[0].y + line[1].y) / 2}
                x2={circlePoints.outarrow.x}
                y2={circlePoints.outarrow.y}
                style={{
                  strokeWidth: 3,
                  stroke: "#890068",
                  markerHeight: 8,
                  markerWidth: 8,
                  markerEnd: "url(#markerArrowOut)",
                }}
              />
            </>
          )}
        </>
      )}
    </svg>
  );
}
