import { useMediaQuery, useTheme } from "@mui/material";

export function useBreakpoints() {
  const theme = useTheme();
  const fitsTablet = useMediaQuery(theme.breakpoints.up("sm"));
  const fitsDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const fitsLarge = useMediaQuery(theme.breakpoints.up("lg"));
  const fitsExtraLarge = useMediaQuery(theme.breakpoints.up("xl"));
  const fitsSmallTablet = useMediaQuery("(min-width: 933px)");

  return {
    fitsTablet,
    fitsDesktop,
    fitsLarge,
    fitsExtraLarge,
    fitsSmallTablet,
  };
}
