import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Divider,
  Input,
  MenuItem,
  Popover,
  PopoverProps,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { useMemo, useState } from "react";

import { PawIcon } from "@/icons/Paw";

import { useBreakpoints } from "@/util/useBreakpoints";
import { ENCODINGS, getStreamMetadataSpecs } from "@/util/validation/stream";

import { StreamInfoMetadataMetric } from "@/components/StreamSelectionDropdown/StreamInfoMetadataMetric";
import { StreamInfoTile } from "@/components/StreamSelectionDropdown/StreamInfoTile";
import { StreamSelectionHelp } from "@/components/StreamSelectionDropdown/StreamSelectionHelp";

import {
  DefaultCameraSettings,
  DeviceDetailsDocument,
  DeviceScanByIdQuery,
  DeviceStream,
  DeviceStreamMetadataFragment,
  ErrorCode,
  useAddStreamToChannelMutation,
} from "@/generated-models";

import { CAMERA_STREAM_SETTINGS_MOBILE_TEXT } from "./constants";

const CODEC_PRIORITY = ["h264", "h265"];

export interface Stream {
  id: number;
  path: string;
  metadata?: DeviceStreamMetadataFragment | null;
}

type StreamSelectionDropdownProps = {
  channel?: NonNullable<DeviceScanByIdQuery["deviceScan"]>["channels"][number];
  deviceId: number;
  recommendedCameraSettings?: DefaultCameraSettings;
  disabled?: boolean;
  selectedStreamId?: number;
  onChange: (newStreamId: number) => void;
  onOpenNativeProxy?: () => void;
} & Pick<PopoverProps, "open" | "onClose" | "anchorEl">;

function StreamRecommendedSettings({
  recommendedCameraSettings: rec,
}: {
  recommendedCameraSettings: DefaultCameraSettings;
}) {
  const metricProps = {
    className: "text-[#95C7F3]",
    valueClassName: "text-white",
  };

  const encodings = useMemo(
    () => rec.encoding.map((e) => ENCODINGS[e] || e).join(", "),
    [rec]
  );

  const rateControl = useMemo(() => rec.rateControl.join(", "), [rec]);

  return (
    <div className="pt-3 pb-1 flex justify-around">
      <StreamInfoMetadataMetric
        {...metricProps}
        label="Resolution"
        value={`${rec.resolution}p`}
      />
      <Divider
        orientation="vertical"
        flexItem
        className="bg-white/10"
        classes={{ root: "border-white" }}
      />
      <StreamInfoMetadataMetric
        {...metricProps}
        label="Framerate"
        value={`${rec.fps} fps`}
      />
      <Divider orientation="vertical" flexItem className="bg-white/10" />
      <StreamInfoMetadataMetric
        {...metricProps}
        label="Bitrate"
        value={`< ${rec.bitrate / 1024} mbps`}
      />
      <Divider orientation="vertical" flexItem className="bg-white/10" />
      <StreamInfoMetadataMetric
        {...metricProps}
        label="Encoding"
        value={encodings}
      />
      <Divider orientation="vertical" flexItem className="bg-white/10" />
      <StreamInfoMetadataMetric
        {...metricProps}
        label="Rate Control"
        value={rateControl}
      />
    </div>
  );
}

// TODO: don't use metadata!
// TODO Enforce spec validation always when ready.
export function determineDefaultStream(
  streams: Stream[],
  recommendedCameraSettings?: DefaultCameraSettings
) {
  // Only consider streams that...
  //    ...have metadata (aka we could ffprobe them, credentials checked out)
  //    ...are "valid" (checks for the correct codec - h264)
  //    ...have a positive width (we were able to determine resolution, so it's
  //       very likely a legitimate video stream)
  const validStreamsWithMetadata = streams.filter((x) =>
    Boolean(
      x.metadata &&
        x.metadata.isValid &&
        x.metadata.width &&
        x.metadata.width > 0
    )
  );

  // Sort by width, we prefer higher res streams...
  let sortedStreams = validStreamsWithMetadata.sort(
    (a, b) => b.metadata!.width! - a.metadata!.width!
  );

  // Sort by codec, we prefer higher res streams...
  sortedStreams = validStreamsWithMetadata.sort(
    (a, b) =>
      CODEC_PRIORITY.indexOf(b?.metadata?.codec || "") -
      CODEC_PRIORITY.indexOf(a?.metadata?.codec || "")
  );

  // Do not include streams that have spec validation errors.
  if (recommendedCameraSettings) {
    sortedStreams = sortedStreams.filter(
      (s) =>
        getStreamMetadataSpecs(
          s as DeviceStream,
          recommendedCameraSettings
        ).filter((r) => r.error).length === 0
    );
  }

  // ...but first check if we have any that are below 2000px wide,
  //    we target 1080p, this leaves some wiggleroom.
  // If there are no streams under 2000px, take the first one.
  return (
    sortedStreams.find((stream) => stream.metadata!.width! < 2000) ??
    sortedStreams[0]
  );
}

export function StreamSelectionDropdownMenu({
  open,
  onClose = () => {},
  anchorEl,
  selectedStreamId,
  disabled,
  channel,
  recommendedCameraSettings,
  deviceId,
  onChange,
  onOpenNativeProxy = () => {},
}: StreamSelectionDropdownProps) {
  const { fitsDesktop } = useBreakpoints();

  const recommendedStream =
    channel &&
    determineDefaultStream(channel.streams, recommendedCameraSettings);
  const selectedStream = channel?.streams.find(
    (stream) => stream.id === selectedStreamId
  );

  const [checkError, setCheckError] = useState("");
  const [checkPath, setCheckPathValue] = useState(selectedStream?.path || "/");
  const [
    addStreamToChannel,
    { loading: addStreamLoading },
  ] = useAddStreamToChannelMutation({
    onCompleted: () => setCheckPathValue("/"),
  });

  const checkPathDifferentFromExistingStreams =
    !channel || channel.streams.every((stream) => stream.path !== checkPath);

  const disableFields = addStreamLoading || disabled;

  function onSubmit() {
    // Validate path is not one of alt paths
    if (channel?.streams.some((stream) => stream.path === checkPath)) {
      return setCheckError("Already one of the options");
    }
    return addStreamToChannel({
      variables: {
        id: channel?.id ?? `${deviceId}:0`,
        path: checkPath.trim() || "/",
      },
      refetchQueries: [
        { query: DeviceDetailsDocument, variables: { deviceId } },
      ],
    })
      .then(({ data }) => {
        if (!data) return;
        if (data.addStreamToChannel.__typename === "StreamValidationError") {
          const error = data.addStreamToChannel;
          if (error.code === ErrorCode.RtspPathInvalid) {
            setCheckError("Path invalid");
            return;
          }
          throw error;
        } else {
          setCheckError("");
        }
      })
      .catch((error: Error) => {
        console.error(error);
        setCheckError(error.message);
      })
      .finally(() => {
        return;
      });
  }

  return (
    <Popover
      transformOrigin={{ horizontal: 10, vertical: 350 }}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      PaperProps={{
        className: "rounded-xl",
      }}
    >
      <div>
        <div className="flex justify-between items-center bg-primary pl-6 pr-4 py-4 min-w-[450px]">
          <Typography className="text-white font-bold text-lg leading-[16.5px]">
            Select a Stream
          </Typography>
          <div className="text-white">
            <StreamSelectionHelp
              streams={channel?.streams || []}
              onMenuClose={() => {
                onClose({}, "escapeKeyDown");
              }}
              onOpenNativeProxy={onOpenNativeProxy}
            />
          </div>
        </div>
        {recommendedCameraSettings && (
          <div className="bg-[#004D8D] py-3 px-[18px]">
            <div className="flex gap-x-2 items-center">
              <PawIcon style={{ height: 16, width: 13 }} white />
              <Typography className="text-white font-bold text-xs leading-3">
                Recommended Optimal Settings:
              </Typography>
            </div>
            <StreamRecommendedSettings
              recommendedCameraSettings={recommendedCameraSettings}
            />
          </div>
        )}
        <div className="pt-4">
          {channel?.streams.map((stream) => {
            const { id, path } = stream;
            const selected = selectedStreamId === id;
            return (
              <MenuItem
                className="px-6"
                key={path}
                value={id}
                disableGutters
                onClick={() => {
                  onChange(stream.id);
                }}
              >
                <StreamInfoTile
                  className={clsx(
                    "hover:bg-[#F6FBFF] hover:border-[#317DDD] w-full",
                    {
                      "hover:border-opacity-40": !selected,
                    }
                  )}
                  recommendedCameraSettings={recommendedCameraSettings}
                  metadataClassName="min-w-[450px]"
                  showErrors={selected}
                  recommended={path === recommendedStream?.path}
                  selected={selected}
                  inactive={!selected}
                  stream={stream}
                  onOpenNativeProxy={onOpenNativeProxy}
                />
              </MenuItem>
            );
          })}
        </div>
        <Accordion
          TransitionProps={{}}
          classes={{ root: "before:hidden" }}
          className="mx-6 mt-1 mb-3 shadow-none border border-solid border-[#ECECEC] rounded-lg"
        >
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            classes={{
              root: "min-h-[39px]",
              content: "my-1",
              expandIconWrapper: "py-1 -mr-4",
            }}
          >
            <div>
              <Typography className="text-sm">
                Can't find the right stream?
              </Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: "px-[6px] pt-0",
            }}
          >
            <div className="w-full">
              <Typography
                className="text-[11px] leading-[11px] opacity-50"
                variant="body2"
              >
                Enter an alternate path
              </Typography>
              <div className="flex">
                <Typography className="font-[Courier] absolute pl-2 mt-[6px] text-sm z-10">
                  Path:
                </Typography>
                <Input
                  spellCheck={false}
                  value={checkPath}
                  placeholder={selectedStream?.path || "/"}
                  onChange={(e) =>
                    setCheckPathValue(e.target.value.trim() || "/")
                  }
                  onKeyDown={(e) => {
                    if (
                      e.key === "Enter" &&
                      !disableFields &&
                      checkPathDifferentFromExistingStreams
                    ) {
                      e.preventDefault();
                      e.stopPropagation();
                      onSubmit();
                    }
                  }}
                  disabled={disableFields}
                  className="bg-white border-y border-l border-solid border-[#DFDFDF] box-border rounded-l-md outline-none w-full mt-0.5 h-7 pl-[3.25rem] font-[Courier]"
                  classes={{ underline: "before:hidden after:hidden" }}
                />
                <Button
                  onClick={onSubmit}
                  className="shadow-none rounded-l-none h-7 mt-0.5 pl-6 pr-4 rounded-r-md"
                  size="small"
                  variant="contained"
                  color="primary"
                  disabled={
                    disableFields || !checkPathDifferentFromExistingStreams
                  }
                >
                  {addStreamLoading && checkPathDifferentFromExistingStreams ? (
                    <CircularProgress
                      className="-ml-2"
                      size="16px"
                      color="inherit"
                    />
                  ) : (
                    "Check"
                  )}
                </Button>
              </div>
              {checkError && (
                <Typography
                  className="mt-1 text-[11px] leading-[11px] text-[#F44336] max-w-[400px]"
                  variant="body2"
                >
                  {checkError}
                </Typography>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
        {!recommendedStream && (
          <>
            <div className="pb-7 px-6 flex flex-col gap-y-1">
              <Typography
                align="center"
                className="font-bold leading-[18.75px]"
              >
                Don’t see the recommended stream settings?
              </Typography>

              {fitsDesktop ? (
                <Typography
                  align="center"
                  className="text-sm leading-4 w-[300px] mx-auto"
                >
                  Customize a stream directly on the camera with our{" "}
                  <span
                    className="text-primary font-bold cursor-pointer"
                    onClick={() => {
                      onClose({}, "escapeKeyDown");
                      onOpenNativeProxy();
                    }}
                  >
                    Native Camera Config
                  </span>{" "}
                  tool.
                </Typography>
              ) : (
                <Typography
                  align="center"
                  className="text-sm leading-4 w-[300px] mx-auto"
                >
                  {CAMERA_STREAM_SETTINGS_MOBILE_TEXT}
                </Typography>
              )}
            </div>
          </>
        )}
      </div>
    </Popover>
  );
}
