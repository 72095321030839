import DeleteIcon from "@mui/icons-material/DeleteForever";
import { Button } from "@mui/material";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";

import { useBreakpoints } from "@/util/useBreakpoints";

import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { DefaultDialog, useDialog } from "@/components/shared/Dialog";

import { useDeleteIntegrationMutation } from "@/generated-models";
import { usePrefixOrgSlug } from "@/hooks/useOrgRouteBase";

export default function IntegrationsDeleteButton({
  id,
  className,
}: {
  id: number;
  className?: string;
}) {
  const { fitsTablet } = useBreakpoints();
  const navigate = useNavigate();
  const { pushSnackbar } = useFeedback();
  const prefixOrgSlug = usePrefixOrgSlug();

  const { open: openDeleteDialog, ...deleteDialogProps } = useDialog();

  const [
    deleteIntegration,
    { loading: deleting },
  ] = useDeleteIntegrationMutation({
    onCompleted: () =>
      navigate(prefixOrgSlug("/integrations"), { replace: true }),
    onError: () =>
      pushSnackbar(
        "Failed to delete integration, please try again",
        FeedbackType.Error
      ),
  });

  return (
    <>
      <Button
        className={clsx("text-[#757575] font-normal", className)}
        startIcon={<DeleteIcon className="text-[#BDBDBD]" />}
        disabled={deleting}
        onClick={async () => {
          const deleteConfirmed = await openDeleteDialog();
          // Make sure the user confirms deletion
          if (!deleteConfirmed) return;
          deleteIntegration({
            variables: { id },
            update(cache) {
              cache.evict({
                id: `Integration:${id}`,
              });
            },
          });
        }}
      >
        {fitsTablet && `Delete Integration`}
      </Button>
      <DefaultDialog
        title="Delete Integration"
        confirmText="Delete"
        content="Are you sure you want to delete this integration?"
        {...deleteDialogProps}
      />
    </>
  );
}
