import InfoIcon from "@mui/icons-material/InfoOutlined";
import { Button, Box, Paper, Typography } from "@mui/material";
import gql from "graphql-tag";
import React from "react";
import { makeStyles } from "tss-react/mui";

import { useMe } from "@/components/Auth";

import { useAcceptTermsMutation } from "@/generated-models";

const useStyles = makeStyles()((theme) => ({
  background: {
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1),

    "& a, & a:visited": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  },
  button: {
    padding: "4px 12px",
    minWidth: "initial",
  },
  wrapper: {
    position: "absolute",
    width: "100%",
    bottom: -60,
    display: "flex",
    justifyContent: "center",
  },
}));

export function TermsBanner() {
  const { classes } = useStyles();
  const me = useMe();
  const [acceptTerms] = useAcceptTermsMutation({
    optimisticResponse: {
      __typename: "Mutation",
      acceptTerms: {
        __typename: "User",
        id: Number(me?.id),
        termsAccepted: true,
      },
    },
  });

  if (!me || me.termsAccepted) return null;

  return (
    <div className={classes.wrapper}>
      <Paper className={classes.background}>
        <InfoIcon />
        <Box mx={1} />
        <Typography>
          By signing in you accept Spot's{" "}
          <a href="https://www.spot.ai/terms-of-use" target="__blank">
            Terms of Use
          </a>
        </Typography>
        <Box mx={4} />
        <Button
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={() => acceptTerms()}
        >
          Ok
        </Button>
      </Paper>
    </div>
  );
}

export const ACCEPT_TERMS = gql`
  mutation acceptTerms {
    acceptTerms {
      id
      termsAccepted
    }
  }
`;
