import {
  Button,
  FormControlLabel,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";
import { useUpdateEffect } from "@react-hookz/web";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { useAtom, useSetAtom } from "jotai";
import { useFlags } from "launchdarkly-react-client-sdk";
import { makeStyles } from "tss-react/mui";

import { CopilotLogo, CopilotVersionBadge } from "@/icons/CopilotIcon";

import { useFullScreenHandle } from "@/components/FullScreen";
import { FullscreenButton } from "@/components/Player/FullscreenButton";
import { playingIntentState } from "@/components/Player/PlayerBase";
import { PlayIcon } from "@/components/Player/PlayerIcons";
import { DigitalPtzButton } from "@/components/Player/ZoomButton";
import { useFirstActiveCamId } from "@/components/View/sharedViewHooks";

import {
  useCopilotEnabled,
  useHasAdvancedAi,
  useUniversalSearchByImage,
} from "../copilotQueryHooks";
import { CopilotAddLabelButton } from "./CopilotAddLabelButton";
import { CopilotHelpButton } from "./CopilotHelpButton";
import { CopilotHistoryPopover } from "./CopilotHistoryPopover/CopilotHistoryPopover";
import { CopilotLibraryPopover } from "./CopilotLibraryPopover";
import { CopilotSectionCalloutLayout } from "./CopilotSectionCallout";
import { useLaunchCopilot } from "./copilotPlayerSectionHooks";

const trayClass =
  "flex justify-end items-center gap-2 z-10 pr-2 absolute right-0 -top-11";

const useStyles = makeStyles()((theme) => ({
  switch: {
    width: 26,
    height: 12,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 1,
      marginLeft: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: "rgba(89,35,117, 1)",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 2,
        },

        "& .MuiSwitch-thumb": {
          background: "#CE46D9",
          border: "1px solid white",
        },
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        opacity: 0.6,
      },

      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 1,
      },
    },
    "& .MuiSwitch-thumb": {
      margin: "auto",
      background: "#2F133C",
      boxSizing: "border-box",
      border: "1px solid #CE46D9",
      width: 10,
      height: 10,
    },

    "& .MuiSwitch-track": {
      borderRadius: 12 / 2,
      backgroundColor: "rgba(89,35,117, 1)",
      opacity: 1,
    },
  },
}));

function CopilotVideoPlayerTrayLogoSection() {
  const [playing, setPlaying] = useAtom(playingIntentState);
  const { copilotEnabled, setCopilotEnabled } = useCopilotEnabled();
  const { loading } = useUniversalSearchByImage();
  const { classes } = useStyles();

  function toggleCopilot() {
    if (playing) {
      setPlaying(false);
    }
    setCopilotEnabled((r) => !r);
  }

  return (
    <div className="flex flex-col">
      <motion.button
        type="button"
        className="bg-transparent"
        disabled={loading}
        whileHover="hover"
        onClick={toggleCopilot}
        variants={{
          hover: {
            opacity: 0.6,
          },
        }}
      >
        <CopilotLogo />
      </motion.button>
      <div className="flex items-center justify-between">
        <CopilotVersionBadge />
        <FormControlLabel
          className="mr-0"
          classes={{
            label:
              "text-[9px] text-[#CE46D9] font-bold font-barlow leading-normal ml-0.5 w-[13px]",
          }}
          control={
            <Switch
              disabled={loading}
              checked={copilotEnabled}
              size="small"
              className={classes.switch}
              onChange={toggleCopilot}
            />
          }
          label={copilotEnabled ? "On" : "Off"}
        />
      </div>
    </div>
  );
}

export function CopilotVideoPlayerTray({
  focusedPlayerId,
  digitalPtzProps,
}: {
  focusedPlayerId: string;
  digitalPtzProps: {
    disabled?: string;
    playerId: string;
    size?: "medium" | "small";
  };
}) {
  const setPlaying = useSetAtom(playingIntentState);
  const { setCopilotEnabled } = useCopilotEnabled();

  return (
    <>
      <div className={clsx(trayClass, "right-[unset] left-[0] ml-2")}>
        <div className="bg-black rounded opacity-90">
          <IconButton
            onClick={() => {
              setPlaying(true);
              setCopilotEnabled(false);
            }}
          >
            <PlayIcon className="text-white w-6 h-6" />
          </IconButton>
        </div>
      </div>
      <div className={clsx(trayClass, "mr-12")}>
        <div className="bg-black rounded opacity-90">
          <DigitalPtzButton {...digitalPtzProps} />
        </div>
      </div>
      <div className={trayClass}>
        <div className="bg-black rounded opacity-90 flex items-center justify-center w-10 h-[41px]">
          <FullscreenButton className="p-0" playerId={focusedPlayerId} />
        </div>
      </div>
    </>
  );
}

export function CopilotPlayerSection() {
  const { copilotPinning } = useFlags();
  const [playing] = useAtom(playingIntentState);
  const { copilotEnabled, setCopilotEnabled } = useCopilotEnabled();
  const { loading } = useUniversalSearchByImage();
  const cameraId = useFirstActiveCamId();
  const hasAdvancedAi = useHasAdvancedAi(cameraId);
  const { active: isFullscreen } = useFullScreenHandle();

  useUpdateEffect(() => {
    if (!playing && !copilotEnabled) {
      setCopilotEnabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playing]);

  if (!hasAdvancedAi || isFullscreen) return <></>;

  return (
    <AnimatePresence>
      <motion.div
        key="ai-copilot-player-section"
        initial={{ opacity: 0, height: 0 }}
        animate={{
          opacity: 1,
          height: 60,
          transition: { type: "spring", stiffness: 100 },
        }}
        exit={{
          opacity: 0,
          height: 0,
          zIndex: 0,
          transition: { type: "spring", stiffness: 50 },
        }}
        style={{
          background:
            "linear-gradient(270deg, #111038 0%, #3C154D 64.42%, #2B1136 98.96%)",
        }}
        className={clsx(
          "flex items-center justify-between gap-[5px] rounded-b-2xl w-full border-t border-solid border-[#45156B] pl-6 p-[10px]",
          {}
        )}
      >
        <CopilotVideoPlayerTrayLogoSection />
        {copilotEnabled && !loading && <CopilotSectionCalloutLayout />}

        <div className="flex items-center gap-3 justify-between mr-2">
          {loading && (
            <motion.div
              key="copilot-logo"
              className={clsx(!loading && "invisible", "-mt-1")}
              animate={loading ? "loading" : undefined}
              variants={{
                loading: {
                  rotate: 360,
                  transition: {
                    ease: "linear",
                    duration: 2,
                    repeat: loading ? Infinity : 0,
                  },
                },
              }}
            >
              <img className="min-w-[34px]" alt="COPILOT" src="/copilot.png" />
            </motion.div>
          )}
          {loading && (
            <Typography className="uppercase text-sm leading-normal font-barlow tracking-[2.5px] text-[#925EFF] mr-1 -mt-1">
              Fetching Analysis
            </Typography>
          )}
          <AnalyzeSceneButton />
          {copilotEnabled && (
            <>
              {copilotPinning && <CopilotAddLabelButton />}
              {copilotPinning && <CopilotHistoryPopover />}
              <CopilotLibraryPopover />
            </>
          )}
          <CopilotHelpButton />
        </div>
      </motion.div>
    </AnimatePresence>
  );
}

function AnalyzeSceneButton() {
  const launchCopilot = useLaunchCopilot();
  const { copilotEnabled } = useCopilotEnabled();

  return (
    <Button
      size="small"
      variant="outlined"
      className={clsx(
        "w-[178px] px-[14px] pt-1 pb-[6px] font-barlow text-[#E6B0FF] text-[14px] leading-[14px] font-normal rounded-[56px] bg-black/50 border border-solid border-[#4C076C]/50 hover:font-bold hover:border-[#B029EF] hover:text-white hover:bg-[#291B46] hover:shadow-[0_0_0px_0_rgba(0,0,0,0)] tracking-[1.316px] uppercase",
        {
          hidden: copilotEnabled,
        }
      )}
      onClick={launchCopilot}
    >
      Enable AI Copilot
    </Button>
  );
}
