import clsx from "clsx";
import React from "react";

import { useStillOverlayShown } from "@/components/Player/playerMachine";

import { CameraHealth } from "@/generated-models";

export function LiveIndicator({
  cameraHealth,
  darkIcon,
}: {
  cameraHealth?: Pick<CameraHealth, "cameraOnline" | "applianceOnline">;
  darkIcon?: boolean;
}) {
  const stillView = useStillOverlayShown();

  return (
    <div
      className={clsx(
        "flex items-center gap-1 font-bold py-0.5 px-1 rounded-sm text-xs",
        {
          "dark:text-white bg-gray-e0 dark:bg-gray-44": !darkIcon,
          "text-white bg-gray-44": darkIcon,
        }
      )}
    >
      {stillView ? (
        <>
          <div className="w-2 h-2 rounded-[50%] bg-[#f00] shadow-[0_0_6px_2px_rgba(255,46,0,49%)]" />
          <span>
            {cameraHealth &&
            (!cameraHealth.applianceOnline || !cameraHealth.cameraOnline)
              ? "OFFLINE"
              : "LOADING"}
          </span>
        </>
      ) : (
        <>
          <div className="w-2 h-2 rounded-[50%] bg-[#67c21b] shadow-[0_0_6px_2px_rgba(103,194,27,0.49)]" />
          <span>LIVE</span>
        </>
      )}
    </div>
  );
}
