import BlockIcon from "@mui/icons-material/Block";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { Button, Tooltip } from "@mui/material";

export function PlayVideoButton({
  label = "Play Video",
  notAvailableLabel = "Not Available",
  available,
  onClick,
}: {
  label?: string;
  notAvailableLabel?: string;
  available?: boolean | null;
  onClick?: () => void;
}) {
  const Icon = available ? PlayCircleOutlineIcon : BlockIcon;
  return (
    <Tooltip
      title={available ? "" : "Footage unavailable, storage period exceeded"}
    >
      {/** Need to wrap in span for tooltip to be interactable with a disabled button. */}
      <span>
        <Button
          className="p-0 pl-1 justify-start text-sm leading-4"
          color="primary"
          size="large"
          disabled={!available}
          onClick={onClick}
          startIcon={<Icon />}
        >
          {available ? label : notAvailableLabel}
        </Button>
      </span>
    </Tooltip>
  );
}
