
      export type PossibleTypesResultData = {
  "possibleTypes": {
    "AddStreamToChannelResult": [
      "DeviceChannel",
      "StreamValidationError"
    ],
    "AnonymousSharedClipResult": [
      "AnonymousSharedClip",
      "AnonymousSharedClipExpired",
      "GenericError"
    ],
    "BaseWall": [
      "RotatingVideoWall",
      "VideoWall"
    ],
    "CameraSlot": [
      "Camera",
      "NotAllowed"
    ],
    "ErrorResult": [
      "AnonymousSharedClipExpired",
      "GenericError",
      "SharedCameraExpired",
      "StreamValidationError"
    ],
    "KioskWall": [
      "RotatingVideoWall",
      "VideoWall"
    ],
    "SharedCameraResult": [
      "GenericError",
      "SharedCamera",
      "SharedCameraExpired"
    ],
    "UpdateDeviceResult": [
      "DeviceScan",
      "StreamValidationError"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AddStreamToChannelResult": [
      "DeviceChannel",
      "StreamValidationError"
    ],
    "AnonymousSharedClipResult": [
      "AnonymousSharedClip",
      "AnonymousSharedClipExpired",
      "GenericError"
    ],
    "BaseWall": [
      "RotatingVideoWall",
      "VideoWall"
    ],
    "CameraSlot": [
      "Camera",
      "NotAllowed"
    ],
    "ErrorResult": [
      "AnonymousSharedClipExpired",
      "GenericError",
      "SharedCameraExpired",
      "StreamValidationError"
    ],
    "KioskWall": [
      "RotatingVideoWall",
      "VideoWall"
    ],
    "SharedCameraResult": [
      "GenericError",
      "SharedCamera",
      "SharedCameraExpired"
    ],
    "UpdateDeviceResult": [
      "DeviceScan",
      "StreamValidationError"
    ]
  }
};
      export default result;
    