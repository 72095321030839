import DeleteForever from "@mui/icons-material/DeleteForever";
import { Paper, Typography } from "@mui/material";
import { capitalize } from "lodash/fp";

import { removeById } from "@/util/apolloCache";

import {
  SpotMenuItem,
  SpotMenuItemWithCheck,
  SpotSelectDense,
} from "@/components/Styled/SpotSelect";
import { Avatar } from "@/components/User/Avatar";

import {
  CaseRole,
  GetCaseQuery,
  useRemoveCaseCollaboratorMutation,
  useUpdateCaseCollaboratorRoleMutation,
} from "@/generated-models";

export type CaseCollaborator = Omit<
  NonNullable<GetCaseQuery["case"]>["caseCollaborators"][number],
  "__typename"
>;

export function CaseCollaboratorChip({
  collaborator: c,
  collaboratorManagamentDisabled,
  caseId,
  onDelete,
  onChangeRole,
  roleArrowOnHover,
}: {
  collaborator: CaseCollaborator;
  collaboratorManagamentDisabled: boolean;
  caseId?: number;
  onDelete?: () => void;
  onChangeRole?: (role: CaseRole) => void;
  roleArrowOnHover?: boolean;
}) {
  const [removeCollaboratorMutation] = useRemoveCaseCollaboratorMutation({
    variables: {
      caseId: caseId ?? -1, // removal will be disabled if no caseId is provided
      orgUserId: c.orgUserId,
    },
    optimisticResponse: {
      __typename: "Mutation",
      removeCaseCollaborator: {
        __typename: "DeletionResponse",
        deleted: true,
        id: c.orgUserId,
      },
    },
    update: (cache) => {
      cache.modify({
        id: `Case:${caseId}`,
        fields: {
          caseCollaborators: removeById(c.id),
        },
      });
    },
  });
  const removeCollaborator: (() => void) | undefined =
    onDelete ?? (caseId ? removeCollaboratorMutation : undefined);
  const [updateRoleMutation] = useUpdateCaseCollaboratorRoleMutation();

  function updateRole(caseRole: CaseRole) {
    if (onChangeRole) {
      onChangeRole(caseRole);
    } else {
      updateRoleMutation({
        variables: {
          caseCollaboratorId: c.id,
          caseRole,
        },
      });
    }
  }

  return (
    <Paper
      className="flex items-center py-1 px-1.5 gap-2 rounded-full"
      variant="outlined"
    >
      <Avatar user={c} className="h-7 w-7 text-[11px]" />
      <Typography variant="body2" className="font-bold">
        {c.name}
      </Typography>
      <div className="flex-grow" />
      {
        <SpotSelectDense
          arrowOnHover={roleArrowOnHover}
          value={c.caseRole}
          onChange={(event) => {
            const roleOrAction = event.target.value as CaseRole | "delete";
            if (roleOrAction === "delete") {
              removeCollaborator?.();
            } else {
              updateRole(roleOrAction);
            }
          }}
          disabled={collaboratorManagamentDisabled}
          renderValue={capitalize}
        >
          <SpotMenuItemWithCheck value="viewer">Viewer</SpotMenuItemWithCheck>
          <SpotMenuItemWithCheck value="commenter">
            Commenter
          </SpotMenuItemWithCheck>
          <SpotMenuItemWithCheck value="editor">Editor</SpotMenuItemWithCheck>

          {removeCollaborator && (
            <SpotMenuItem
              value="delete"
              className="border-t border-solid border-1 border-gray-300"
            >
              <DeleteForever className="text-sm mr-1" />
              Delete
            </SpotMenuItem>
          )}
        </SpotSelectDense>
      }
    </Paper>
  );
}
