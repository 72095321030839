import { Check } from "@mui/icons-material";
import {
  Select,
  SelectProps,
  FormControl,
  MenuItem,
  MenuItemProps,
} from "@mui/material";
import clsx from "clsx";
import { merge } from "lodash/fp";
import { Children } from "react";

interface SpotSelectDenseProps<T> extends SelectProps<T> {
  arrowOnHover?: boolean;
}
export function SpotSelectDense<T>({
  className,
  classes,
  MenuProps,
  children,
  arrowOnHover = true,
  ...props
}: SpotSelectDenseProps<T>) {
  const { select, icon, ...originalClasses } = classes ?? {};
  const arrowInitiallyHidden = Boolean(arrowOnHover || props.disabled);
  return (
    <FormControl size="small" className="m-0" disabled={props.disabled}>
      <Select
        disableUnderline
        size="small"
        className={clsx(
          // Disables hover effects when select is disabled
          props.disabled ? "" : "group",
          className
        )}
        classes={{
          select: clsx(
            "text-xs pl-1 py-0.5 group-hover:bg-[#eee] rounded-sm",
            arrowInitiallyHidden
              ? "transition-spacing group-hover:pr-5 pr-1"
              : "pr-5",
            select
          ),
          icon: clsx(
            arrowInitiallyHidden
              ? "transition-opacity group-hover:opacity-100 opacity-0"
              : "",
            icon
          ),
          ...originalClasses,
        }}
        MenuProps={merge(
          {
            MenuListProps: {
              dense: true,
              classes: {
                root: "text-primary",
              },
            },
          },
          MenuProps
        )}
        {...props}
      >
        {Children.map(children, (child) => {
          if (
            child &&
            typeof child === "object" &&
            "props" in child &&
            typeof child.type === "function" &&
            (isSameReactElement(child, SpotMenuItemWithCheck) ||
              isSameReactElement(child, SpotMenuItem))
          ) {
            return (
              <MenuItem
                {...child.props}
                classes={merge(child.props.classes, {
                  root: clsx(
                    "text-xs text-primary outline-none",
                    child.props.className
                  ),
                  selected: "font-bold",
                })}
              >
                {isSameReactElement(child, SpotMenuItemWithCheck) && (
                  <Check
                    className={clsx(
                      {
                        invisible: props.value !== child.props.value,
                      },
                      "text-xs mr-1"
                    )}
                  />
                )}
                {child.props.children}
              </MenuItem>
            );
          }
          return child;
        })}
      </Select>
    </FormControl>
  );
}

function isSameReactElement(child: any, type: Function) {
  return child.type === type || child.type.name === type.name;
}

/**
 * Styled MenuItem
 */
export function SpotMenuItem(props: MenuItemProps) {
  return <MenuItem {...props} />;
}

/**
 * Styles MenuItem, which adds a check icon if the item is currently selected
 */
export function SpotMenuItemWithCheck(props: MenuItemProps) {
  return <MenuItem {...props} />;
}
