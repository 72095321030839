import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";
import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { capitalize } from "lodash";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import { formatTimeString, getShorthandDayRange } from "@/util/date";
import { formatDurationSecs } from "@/util/formatDurationSecs";

import {
  IntelligenceDashboardType,
  Page_IntelligenceQuery,
} from "@/generated-models";

import { getObjectLabel } from "../../utils";
import { ComparativeCustomSettingsForm } from "./ComparativeCustomSettingsForm";
import {
  getComparativeSettingsId,
  useCurrentSettingsId,
  useComparativeSettings,
  useReferenceDashboards,
  useReferenceTypeInfo,
} from "./utils";

type QueriedDash = Page_IntelligenceQuery["intelligenceDashboards"][number];

interface ComparativeSettingsFieldSummaryProps {
  label: string;
  selected?: boolean;
  children?: ReactNode;
  dashboard: Pick<
    QueriedDash,
    "thresholdSeconds" | "daysOfWeek" | "startTime" | "endTime" | "entityCount"
  >;
}

export function ComparativeSettingsFieldSummary({
  label,
  selected,
  children,
  dashboard,
}: ComparativeSettingsFieldSummaryProps) {
  const info = useReferenceTypeInfo();

  return (
    <div className="flex gap-1 items-start justify-start">
      {selected ? (
        <RadioButtonChecked color="primary" />
      ) : (
        <RadioButtonUnchecked color="primary" />
      )}
      <div className="flex flex-col items-start gap-0.5 ml-0.5 mt-0.5">
        <Typography className="font-bold text-base leading-[18px] text-text text-start max-w-[420px]">
          {label}
        </Typography>
        {info?.type === IntelligenceDashboardType.Idle && (
          <Typography className="text-sm leading-4 text-[#757575] text-left">
            Idle Time: {">"}
            <strong>
              {formatDurationSecs(dashboard.thresholdSeconds, {
                hideZeroes: true,
                long: true,
              })}
            </strong>
          </Typography>
        )}
        {info?.type === IntelligenceDashboardType.Presence && (
          <Typography className="text-sm leading-4 text-[#757575] text-left">
            Presence Threshold:{" "}
            <strong>
              {dashboard.entityCount}+{" "}
              {capitalize(getObjectLabel(info.objectTypes, info.type, true))}
            </strong>
          </Typography>
        )}
        <Typography className="text-sm leading-4 text-[#757575] text-left truncate">
          Tracking:{" "}
          <strong>
            {`${getShorthandDayRange(dashboard.daysOfWeek)}, ${formatTimeString(
              dashboard.startTime,
              "haaa"
            )} - ${formatTimeString(dashboard.endTime, "haaa")}`}
          </strong>
        </Typography>
        {children}
      </div>
    </div>
  );
}

export function ComparativeSettingsField() {
  const [selected, setSelected] = useState<string>();

  const currentId = useCurrentSettingsId();

  const selectedDashboards = useReferenceDashboards();

  const settingsMap = useMemo(() => {
    const result: Record<string, QueriedDash[]> = {};

    selectedDashboards?.forEach((d) => {
      const sId = getComparativeSettingsId(d);
      if (result[sId]) {
        result[sId].push(d);
      } else {
        result[sId] = [d];
      }
    });

    return result;
  }, [selectedDashboards]);

  const { update } = useComparativeSettings();
  const hasCustom = !!selected && !settingsMap[selected];
  const options = Object.keys(settingsMap);

  const setDefaultSelected = useCallback(
    (configId?: string) => {
      const resolvedId = configId || Object.keys(settingsMap)[0];
      const firstBoard = settingsMap[resolvedId][0];
      setSelected(configId);
      update(
        firstBoard.daysOfWeek,
        firstBoard.startTime,
        firstBoard.endTime,
        firstBoard.thresholdSeconds,
        firstBoard.entityCount
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [settingsMap]
  );

  useEffect(() => {
    if (!selected) {
      setSelected(currentId);
    }
  }, [currentId, selected, setSelected]);

  const handleChange = (_: React.MouseEvent<HTMLElement>, newValue: string) => {
    setDefaultSelected(newValue);
  };

  return (
    <div className="flex flex-col gap-6">
      <Typography className="max-w-2xl text-base leading-[18px] text-[#757575] pt-8 sm:pt-0">
        Choose which Insight settings you want to be applied to your Comparison
        Insight, or create new settings. Note: this will not affect the settings
        of your existing Insights.
      </Typography>
      {options.length > 0 && (
        <div>
          <ToggleButtonGroup
            orientation="vertical"
            value={selected}
            exclusive
            onChange={handleChange}
            className="gap-3 w-full"
          >
            {options.map((o) => {
              const boards = settingsMap[o];
              const firstBoard = settingsMap[o][0];
              return (
                <ToggleButton
                  key={o}
                  value={o}
                  classes={{
                    selected: "bg-[#F6FBFF]",
                  }}
                  className="p-2 rounded-lg shadow-[0_6px_14px_rgba(0,42,82,0.1)] border-0 w-full sm:w-[480px] justify-start"
                >
                  <ComparativeSettingsFieldSummary
                    selected={selected === o}
                    label={boards.map((b) => b.name).join(", ")}
                    dashboard={firstBoard}
                  />
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        </div>
      )}
      <div>
        <ComparativeCustomSettingsForm
          enabled={hasCustom}
          onEnableToggle={(value) => {
            if (value) {
              setSelected(value);
            } else {
              setDefaultSelected();
            }
          }}
        />
      </div>
    </div>
  );
}
