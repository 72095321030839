import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, Drawer, Typography, capitalize } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import clsx from "clsx";
import { Form, Formik, FormikHelpers, FormikProps, FormikValues } from "formik";
import { ReactNode } from "react";

import { pluralize } from "@/util/pluralize";

import { BaseDatagridProps } from "../DataGrid";
import { useDataGridSelectedRowIds, useDataGridSelection } from "../hooks";

interface DataGridEditDrawerProps extends BaseDatagridProps {
  onSubmit: (
    ids: number[],
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>
  ) => void | Promise<any>;
  rowLabel: string;
  initialValues: FormikValues;
  children: (formProps: FormikProps<FormikValues>) => ReactNode;
}

export function DataGridEditDrawer({
  onSubmit,
  rowLabel,
  rowSelectionProps,
  initialValues,
  children,
  rows,
  getRowId,
}: DataGridEditDrawerProps) {
  const {
    count,
    editing,
    setSelection,
    setEditing,
    setLocked,
  } = useDataGridSelection();
  const enabledRowIds = useDataGridSelectedRowIds(rows, getRowId);
  const selectionDetails = rowSelectionProps?.editDrawerSelectionDetails;

  const displayLabel = pluralize(
    {
      1: capitalize(rowLabel),
      multi: `${capitalize(rowLabel)}s`,
    },
    count
  );

  function onClose() {
    setSelection([]);
    setEditing(false);
    setLocked(false);
  }

  return (
    <Drawer
      classes={{
        root: "z-[1202]",
        paper: "bg-[#F6FBFF] w-[285px] md:pt-[80px]",
      }}
      hideBackdrop
      variant="persistent"
      anchor="right"
      open={!!editing}
      onClose={onClose}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={async (values, formikHelpers) => {
          await onSubmit(enabledRowIds, values, formikHelpers);
        }}
      >
        {(form) => (
          <Form className="p-5 h-full flex flex-col gap-5">
            <div className="flex flex-col gap-2">
              <Typography className="font-bold text-primary text-xl leading-[23px]">
                Manage {displayLabel}
              </Typography>
              <Accordion
                className={clsx(
                  "bg-transparent shadow-none",
                  !selectionDetails && "pointer-events-none"
                )}
              >
                <AccordionSummary
                  classes={{ contentGutters: "my-0" }}
                  className="px-0 min-h-[19px] text-[#757575] text-base leading-[19px]"
                  expandIcon={!!selectionDetails && <ExpandMoreIcon />}
                  aria-controls="edit-content-summary"
                  id="edit-content-summary"
                >
                  <Typography>
                    {count} {displayLabel} Selected
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="p-0 mt-4">
                  {selectionDetails}
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="flex-1 flex flex-col gap-5">{children(form)}</div>
            <div className="flex flex-col gap-5">
              <div className="flex items-center justify-end gap-2">
                <Button
                  className="text-[#757575] rounded-lg font-normal"
                  onClick={() => {
                    onClose();
                    form.resetForm();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={!form.dirty || form.isSubmitting}
                  type="submit"
                  className="shadow-none font-normal rounded-lg"
                  variant="contained"
                  color="primary"
                >
                  {form.isSubmitting ? "Saving" : "Apply"}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Drawer>
  );
}
