import { useLocalStorage } from "./useLocalStorage";

export function useDeviceProxyLink(
  applianceSerial: string,
  proxyEndpoint?: string,
  native?: boolean
) {
  let proxyLink;
  const [fallbackOriginalProxy] = useLocalStorage("fallbackProxy", false); //defaults true if cloudproxy
  const useCloudproxy = !fallbackOriginalProxy;
  const targetSplit = proxyEndpoint?.split(":");

  const mode = proxyEndpoint && proxyEndpoint !== "" ? "camera" : "appliance";

  if (!native && (!useCloudproxy || (!targetSplit && mode === "camera"))) {
    proxyLink = `https://${applianceSerial}.s1.spotai.co/${
      mode === "camera" ? "" : "www"
    }`;
  } else {
    proxyLink = `/cloudproxy/http?serial=${applianceSerial}`;
    if (targetSplit) {
      proxyLink += `&target=${targetSplit[0]}&port=${targetSplit[1] || "80"}`;
    }
  }
  return proxyLink;
}
