import CloseIcon from "@mui/icons-material/Close";
import { Checkbox, TextField, Autocomplete } from "@mui/material";
import { sortBy } from "lodash/fp";
import React, { Dispatch } from "react";

type AutocompleteItem = {
  id: number;
  label: string;
};

export function LocationsSelect({
  options,
  value,
  onChange,
  label = "Locations",
  placeholder = "Search locations",
  limitTags = -1,
  className,
}: {
  options: AutocompleteItem[];
  value: AutocompleteItem[];
  onChange: Dispatch<number[]>;
  label?: string;
  placeholder?: string;
  limitTags?: number;
  className?: string;
}) {
  const sortedOptions = sortBy("label", options);
  return (
    <Autocomplete
      multiple
      fullWidth
      size="small"
      className={className}
      options={sortedOptions}
      value={value}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(_, value) => onChange(value.map((v) => v.id))}
      disableCloseOnSelect
      limitTags={limitTags}
      getLimitTagsText={
        limitTags === 0 ? (more) => `${more} Selected` : undefined
      }
      noOptionsText="No locations found"
      ChipProps={{
        variant: "outlined",
        color: "primary",
        deleteIcon: <CloseIcon />,
        classes: { root: "font-medium bg-[#F6FBFF]", deleteIcon: "w-5 h-5" },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          placeholder={placeholder}
        />
      )}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox checked={selected} color="primary" className="mr-2" />
          {option.label}
        </li>
      )}
    />
  );
}
