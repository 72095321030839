import { Button, Typography } from "@mui/material";
import clsx from "clsx";
import { Link } from "react-router-dom";

import { IntegrationTypeKey, IntegrationVendor } from "@/generated-models";
import { usePermissions } from "@/hooks/usePermissions";

interface IntegrationVendorCardProps {
  vendor: IntegrationVendor;
}

interface IntegrationActionsProps {
  vendor: IntegrationVendor;
  type: IntegrationTypeKey;
  className?: string;
  label?: string;
}

function IntegrationActions({
  vendor,
  type,
  label,
  className,
}: IntegrationActionsProps) {
  const to = `../${type}/${vendor.key}/new`.toLowerCase();

  return (
    <Button
      variant="contained"
      color="primary"
      className={clsx(
        "shadow-none rounded-lg md:w-[unset] w-full md:py-[6px] py-[9px]",
        className
      )}
      component={Link}
      to={to}
    >
      {label || "Connect"}
    </Button>
  );
}

function IntegrationIOBoardCard({ vendor }: IntegrationVendorCardProps) {
  const { name, description, type } = vendor;
  const hasPermission = usePermissions();
  const hasManagePermission = hasPermission("integrations_manage");

  return (
    <div className="md:col-span-3 sm:col-span-2 col-span-1 rounded-2xl bg-white flex justify-start items-center shadow-[0_19px_22px_rgba(0,42,82,0.1)] border border-solid border-[#9AD1FF]">
      <div className="p-6 flex md:flex-row flex-col justify-between items-start md:items-center gap-4 md:gap-3 w-full h-full">
        <div className="flex flex-col md:flex-row justify-start gap-7">
          <div className="flex items-center justify-start gap-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
            >
              <circle cx="18" cy="18" r="15.75" fill="#DDEFFF" />
              <path
                d="M13.5462 10.5452H21.8191V13.2519C22.3015 13.6326 22.7302 14.0785 23.0918 14.5762V10.2725C23.0918 9.72018 22.6441 9.27246 22.0918 9.27246H13.2734C12.7212 9.27246 12.2734 9.72018 12.2734 10.2725V14.5762C12.6351 14.0785 13.0638 13.6326 13.5462 13.2519V10.5452Z"
                fill="#007CE4"
              />
              <path
                d="M13.5462 23.7479C13.0638 23.3672 12.6351 22.9213 12.2734 22.4236V26.7273C12.2734 27.2796 12.7212 27.7273 13.2734 27.7273H22.0918C22.6441 27.7273 23.0918 27.2796 23.0918 26.7273V22.4236C22.7302 22.9213 22.3015 23.3672 21.8191 23.7479V26.4546H13.5462V23.7479Z"
                fill="#007CE4"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.6819 23.9095C20.6693 23.9095 23.0911 21.4877 23.0911 18.5003C23.0911 15.5129 20.6693 13.0911 17.6819 13.0911C14.6945 13.0911 12.2727 15.5129 12.2727 18.5003C12.2727 21.4877 14.6945 23.9095 17.6819 23.9095ZM17.6819 25.1822C21.3723 25.1822 24.3639 22.1906 24.3639 18.5003C24.3639 14.81 21.3723 11.8184 17.6819 11.8184C13.9916 11.8184 11 14.81 11 18.5003C11 22.1906 13.9916 25.1822 17.6819 25.1822Z"
                fill="#007CE4"
              />
              <rect
                x="13.5459"
                y="8"
                width="4.45462"
                height="1.27275"
                fill="#007CE4"
              />
              <rect
                x="13.5459"
                y="27.7271"
                width="8.27287"
                height="1.27275"
                fill="#007CE4"
              />
            </svg>
            <Typography className="text-lg leading-[21px] font-bold min-w-[150px] text-primary">
              {name}
            </Typography>
          </div>
          <Typography className="text-sm leading-[16px] max-w-[680px]">
            {description}
          </Typography>
        </div>
        {hasManagePermission && (
          <div className="flex flex-col items-center justify-center h-full md:min-w-[75px]">
            <IntegrationActions
              vendor={vendor}
              type={type.key}
              label="Set Up"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export function IntegrationVendorCard({ vendor }: IntegrationVendorCardProps) {
  const hasPermission = usePermissions();
  const hasManagePermission = hasPermission("integrations_manage");

  const { name, logoSrc, description, type } = vendor;

  if (type.key === IntegrationTypeKey.IoBoard)
    return <IntegrationIOBoardCard vendor={vendor} />;

  return (
    <div className="rounded-2xl bg-white flex flex-col items-center shadow-[0_19px_22px_rgba(0,42,82,0.1)]">
      <div className="p-8 flex md:flex-row flex-col justify-between items-start gap-4 md:gap-3 w-full h-full">
        <div className="flex flex-col gap-3">
          <div className="flex items-center justify-start gap-3">
            {type.key !== IntegrationTypeKey.Intercom && (
              <img
                className="h-6"
                alt={name}
                src={logoSrc?.replace("integrations/", "integrations/icon-")}
              />
            )}
            <Typography className="text-lg leading-[21px] font-bold">
              {name}
            </Typography>
          </div>
          <Typography className="text-sm leading-[16px] md:max-w-[228px]">
            {description}
          </Typography>
        </div>
        {hasManagePermission && (
          <div className="flex flex-col items-center justify-center h-full">
            <IntegrationActions
              vendor={vendor}
              type={type.key}
              className="text-primary bg-[#DAEEFF] hover:bg-opacity-60"
            />
          </div>
        )}
      </div>
    </div>
  );
}
