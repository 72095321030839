import { Checkbox, SxProps, Theme, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import clsx from "clsx";
import { useFlags } from "launchdarkly-react-client-sdk";
import { keyBy } from "lodash/fp";
import { StringParam, useQueryParam } from "use-query-params";

import { ClothingLowerIcon, ClothingUpperIcon } from "@/icons/Icons";

import {
  useArraySearchFilter,
  useSearchSubjects,
  useSetArraySearchFilter,
} from "@/pages/Search/searchHooks";

import {
  AgeAttribute,
  ClothingColor,
  Gender,
  VehicleMake,
  VehicleType,
} from "@/generated-models";

import {
  VehicleColorIcon,
  VehicleMakeIcon,
  VehicleTypeIcon,
} from "../../icons/SearchIcons";
import { ReactComponent as AgeIcon } from "../../icons/age.svg";
import { ReactComponent as GenderIcon } from "../../icons/gender.svg";
import { HelpPopover } from "./HelpPopover";

interface ThemedColors {
  primary: string;
  background: string;
  border: string;
  borderActive: string;
}

const themedColors: Record<string, ThemedColors> = {
  people: {
    primary: "#6500E4",
    background: "#EEE1FF",
    border: "#E1CDFA",
    borderActive: "#B280F1",
  },
  vehicles: {
    primary: "#00AFD6",
    background: "#CCF6FF50",
    border: "#CCF6FF",
    borderActive: "#00AFD680",
  },
};

interface AttributeFilterProps {
  hasAdvancedAi: boolean;
}

export function PeopleAttributeFilters({
  hasAdvancedAi,
}: AttributeFilterProps) {
  const { genderAgeAttributeSearch } = useFlags();
  const subjects = useSearchSubjects();
  const upperColor = useArraySearchFilter("clothingUpper");
  const setUpperColor = useSetArraySearchFilter("clothingUpper");
  const lowerColor = useArraySearchFilter("clothingLower");
  const setLowerColor = useSetArraySearchFilter("clothingLower");

  if (!subjects || !subjects.includes("0")) return null;

  return (
    <div className="flex items-center flex-wrap bg-[#F5EFFD] px-4 md:px-6 py-2 md:py-3 text-[#6500E4]">
      <div className="text-base md:text-lg font-bold mr-4 md:mr-6">
        People Search Filters:
      </div>
      <div className="flex flex-wrap gap-x-1 sm:gap-x-2.5 gap-y-1">
        <ThemedMultipleColorSelect
          selected={upperColor}
          setSelected={setUpperColor}
          label="Upper Clothing Color"
          Icon={ClothingUpperIcon}
          colors={themedColors.people}
        />
        <ThemedMultipleColorSelect
          selected={lowerColor}
          setSelected={setLowerColor}
          label="Lower Clothing Color"
          Icon={ClothingLowerIcon}
          colors={themedColors.people}
        />
        {hasAdvancedAi && genderAgeAttributeSearch && (
          <>
            <ThemedGenderSelect />
            <ThemedAgeSelect />
          </>
        )}
      </div>
    </div>
  );
}

export function VehicleAttributeFilters({
  hasAdvancedAi,
}: AttributeFilterProps) {
  const subjects = useSearchSubjects();
  const vehicleColor = useArraySearchFilter("vehicleColor");
  const setVehicleColor = useSetArraySearchFilter("vehicleColor");

  if (!subjects || !subjects.includes("2")) return null;

  return (
    <div className="flex items-center flex-wrap bg-[#F6FDFF] px-4 md:px-6 py-2 md:py-3 text-[#00AFD6]">
      <div className="text-base md:text-lg font-bold mr-4 md:mr-6">
        Vehicle Search Filters:
      </div>
      <div className="flex items-end flex-wrap gap-x-1 sm:gap-x-2.5 gap-y-1">
        <ThemedMultipleColorSelect
          selected={vehicleColor}
          setSelected={setVehicleColor}
          label="Vehicle Color"
          Icon={VehicleColorIcon}
          colors={themedColors.vehicles}
        />
        {hasAdvancedAi && (
          <>
            <ThemedVehicleTypeSelect />
            <ThemedVehicleMakeSelect />
            <HelpPopover className="text-[#00AFD6]">
              <Typography>
                Make/Type filters filter vehicles by predicted vehicle make or
                type.
              </Typography>
              <Typography>
                An unobstructed, well lit, large views of vehicles are required
                for best accuracy.
                {/* Visit this KB article for more guidance. */}
              </Typography>
            </HelpPopover>
          </>
        )}
      </div>
    </div>
  );
}

function getLabelProps(colors: ThemedColors) {
  return {
    shrink: true,
    sx: {
      position: "static",
      color: colors.primary,
      transform: "none",
      opacity: 0.5,
      fontSize: "12px",
      zIndex: 0,
      "&.Mui-focused": { opacity: 1, color: "inherit" },
      "&.MuiFormLabel-filled": { opacity: 1 },
    },
  };
}

const sharedSelectProps = {
  displayEmpty: true,
  MenuProps: {
    PaperProps: { sx: { marginTop: "4px" } },
    MenuListProps: { sx: { padding: "0" } },
  },
};

function getMultiSelectProps({
  selected,
  setSelected,
  label,
  Icon,
  sx,
}: {
  selected: string[];
  setSelected: (value: string[]) => void;
  label: string;
  Icon?: React.ElementType;
  sx?: SxProps<Theme>;
}) {
  return {
    multiple: true,
    value: selected,
    onChange: ({ target: { value } }: SelectChangeEvent<string[]>) => {
      setSelected(
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value
      );
    },
    input: <OutlinedInput sx={sx} />,
    renderValue: (selected: string[]) => {
      return (
        <div className="flex items-center gap-1">
          {Icon && <Icon />}
          <span className="truncate">
            {selected.length > 0
              ? `${selected.length} ${label}${selected.length > 1 ? "s" : ""}`
              : label}
          </span>
        </div>
      );
    },
    ...sharedSelectProps,
  };
}

export function getSelectProps({
  value,
  setValue,
  label,
  activeLabel,
  Icon,
  sx,
}: {
  value: string;
  setValue: (value: string) => void;
  label: string;
  activeLabel: string;
  Icon?: React.ElementType;
  sx?: SxProps<Theme>;
}) {
  return {
    value,
    onChange: ({ target: { value } }: SelectChangeEvent<string>) => {
      setValue(value);
    },
    input: <OutlinedInput sx={sx} />,
    renderValue: (value: string) => (
      <div className="flex items-center gap-1">
        {Icon && <Icon />}
        <span className="truncate">{value ? activeLabel : label}</span>
      </div>
    ),
    ...sharedSelectProps,
  };
}

function getThemedSelectStyles({
  isActive,
  theme,
  colors,
}: {
  isActive: boolean;
  theme: Theme;
  colors: ThemedColors;
}) {
  return {
    fontSize: "14px",
    fontWeight: isActive ? 700 : 400,
    color: isActive ? theme.palette.text.primary : colors.primary,
    backgroundColor: isActive ? "white" : colors.background,
    "&.Mui-focused": { backgroundColor: "white" },
    ".MuiOutlinedInput-notchedOutline": {
      borderColor: isActive ? colors.borderActive : colors.border,
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: colors.borderActive,
      borderWidth: "1px",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: colors.borderActive,
    },
    ".MuiSelect-select": { padding: "8px" },
    ".MuiSelect-select:focus": { backgroundColor: "white" },
    ".MuiSelect-icon": { color: colors.primary },
  };
}

export function getPrimarySelectStyles({
  isActive,
  theme,
}: {
  isActive: boolean;
  theme: Theme;
}) {
  return {
    fontSize: "14px",
    fontWeight: isActive ? 700 : 400,
    backgroundColor: isActive ? "#DDEFFF" : "#E9F5FF",
    borderRadius: "6px",
    borderWidth: "1px",
    borderColor: isActive ? "#FEFFFF" : "#E9F5FF",
    boxShadow: isActive ? "0px 0px 4px 0px #007CE4" : undefined,
    ".MuiOutlinedInput-notchedOutline": { border: "none" },
    ".MuiSelect-select": { padding: "7px 8px" },
    ".MuiSelect-select:focus": { backgroundColor: "initial" },
    ".MuiSelect-icon": { color: theme.palette.text.primary },
  };
}

export function getMenuItemProps({
  value,
  selected,
}: {
  value: string;
  selected: boolean;
}) {
  return {
    key: value,
    value,
    sx: {
      backgroundColor: selected ? "#E0F1FF" : "initial",
      borderBottom: "1px solid #F6F6F6",
      padding: "6px 8px",
      fontSize: "14px",
      "&:hover": {
        backgroundColor: "#E0F1FF",
      },
    },
  };
}

const attributeColors = [
  { value: ClothingColor.White, name: "White", color: "#FFFFFF" },
  { value: ClothingColor.Black, name: "Black", color: "#000000" },
  { value: ClothingColor.SilverGrey, name: "Silver/grey", color: "#808080" },
  { value: ClothingColor.Red, name: "Orange/red", color: "#B22222" },
  { value: ClothingColor.Blue, name: "Blue", color: "#0000CD" },
  { value: ClothingColor.LightBlue, name: "Light blue", color: "#87CEEB" },
  { value: ClothingColor.Green, name: "Green", color: "#008000" },
  // { value: ClothingColor.Brown, name: "Brown", color: "#8B4513" },
  { value: ClothingColor.Yellow, name: "Yellow", color: "#FFFF00" },
];
export const attributeColorsMap = keyBy("value", attributeColors);

function getColorMenuItems({ selected }: { selected: string[] }) {
  return attributeColors.map(({ value, name, color }) => {
    const checked = selected.includes(value);
    return (
      <MenuItem {...getMenuItemProps({ value, selected: checked })}>
        <Checkbox
          checked={checked}
          disableRipple
          sx={{ padding: 0, color: "#CCCCCC" }}
        />
        <div
          className="w-[18px] h-[18px] border border-[#D6D6D6] rounded-full mx-3"
          style={{ backgroundColor: color }}
        />
        {name}
      </MenuItem>
    );
  });
}

function ThemedMultipleColorSelect({
  selected = [],
  setSelected,
  label,
  Icon,
  colors,
}: {
  selected?: string[];
  setSelected: (value: string[]) => void;
  label: string;
  Icon?: React.ElementType;
  colors: ThemedColors;
}) {
  const isActive = selected.length > 0;

  return (
    <FormControl className="w-[10.5rem] [@media(min-width:450px)]:w-48">
      <InputLabel {...getLabelProps(colors)}>{label}</InputLabel>
      <Select
        {...getMultiSelectProps({
          selected,
          setSelected,
          label,
          Icon,
          sx: (theme) => getThemedSelectStyles({ isActive, theme, colors }),
        })}
      >
        {getColorMenuItems({ selected })}
      </Select>
    </FormControl>
  );
}

export function PrimaryMultipleColorSelect({
  selected = [],
  setSelected,
  label,
  Icon,
}: {
  selected?: string[];
  setSelected: (value: string[]) => void;
  label: string;
  Icon?: React.ElementType;
}) {
  const isActive = selected.length > 0;

  return (
    <Select
      fullWidth
      {...getMultiSelectProps({
        selected,
        setSelected,
        label,
        Icon,
        sx: (theme) => getPrimarySelectStyles({ isActive, theme }),
      })}
    >
      {getColorMenuItems({ selected })}
    </Select>
  );
}

const attributeAges = [
  { value: "0", name: "18-30" },
  { value: "1", name: "31-60" },
  { value: "2", name: "61+" },
];
export const attributeAgesMap = keyBy("value", attributeAges);
export const ageBucketMap: Record<string, AgeAttribute> = {
  0: { min: 18, max: 30 },
  1: { min: 31, max: 60 },
  2: { min: 61, max: 100 },
};

function getAgeMenuItems({ selected }: { selected: string[] }) {
  return attributeAges.map(({ value, name }) => {
    const checked = selected.includes(value);
    return (
      <MenuItem {...getMenuItemProps({ value, selected: checked })}>
        <Checkbox
          checked={checked}
          disableRipple
          sx={{ padding: 0, color: "#CCCCCC", marginRight: "12px" }}
        />
        {name}
      </MenuItem>
    );
  });
}

function ThemedAgeSelect() {
  const selected = useArraySearchFilter("age") ?? [];
  const setSelected = useSetArraySearchFilter("age");
  const isActive = selected.length > 0;
  const colors = themedColors.people;

  return (
    <FormControl className="w-36">
      <InputLabel {...getLabelProps(colors)}>Age</InputLabel>
      <Select
        fullWidth
        {...getMultiSelectProps({
          selected,
          setSelected,
          label: "Age",
          Icon: AgeIcon,
          sx: (theme) => getThemedSelectStyles({ isActive, theme, colors }),
        })}
      >
        {getAgeMenuItems({ selected })}
      </Select>
    </FormControl>
  );
}

export function PrimaryAgeSelect() {
  const selected = useArraySearchFilter("age") ?? [];
  const setSelected = useSetArraySearchFilter("age");
  const isActive = selected.length > 0;

  return (
    <Select
      fullWidth
      {...getMultiSelectProps({
        selected,
        setSelected,
        label: "Age",
        Icon: AgeIcon,
        sx: (theme) => getPrimarySelectStyles({ isActive, theme }),
      })}
    >
      {getAgeMenuItems({ selected })}
    </Select>
  );
}

export function useGenderParam() {
  // TODO: type as Gender enum
  return useQueryParam("gender", StringParam);
}

function getGenderMenuItems({ gender }: { gender?: string | null }) {
  return ["", Gender.Female, Gender.Male].map((value) => (
    <MenuItem
      {...getMenuItemProps({ value, selected: value === (gender ?? "") })}
    >
      <div className="flex items-center">
        <GenderIcon
          className={clsx("mr-1", {
            "[&>path:first-child]:fill-opacity-20": value === Gender.Female,
            "[&>path:last-child]:fill-opacity-20": value === Gender.Male,
          })}
        />
        <span className="truncate">{value || "Gender"}</span>
      </div>
    </MenuItem>
  ));
}

function ThemedGenderSelect() {
  const [gender, setGender] = useGenderParam();
  const isActive = Boolean(gender);
  const colors = themedColors.people;

  return (
    <FormControl className="w-36">
      <InputLabel {...getLabelProps(colors)}>Gender Appearance</InputLabel>
      <Select
        fullWidth
        value={gender ?? ""}
        onChange={({ target: { value } }: SelectChangeEvent<string>) => {
          setGender(value || undefined, "replaceIn");
        }}
        input={
          <OutlinedInput
            sx={(theme) => getThemedSelectStyles({ isActive, theme, colors })}
          />
        }
        {...sharedSelectProps}
      >
        {getGenderMenuItems({ gender })}
      </Select>
    </FormControl>
  );
}

export function PrimaryGenderSelect() {
  const [gender, setGender] = useGenderParam();
  const isActive = Boolean(gender);

  return (
    <Select
      fullWidth
      value={gender ?? ""}
      onChange={({ target: { value } }: SelectChangeEvent<string>) => {
        setGender(value || undefined, "replaceIn");
      }}
      input={
        <OutlinedInput
          sx={(theme) => getPrimarySelectStyles({ isActive, theme })}
        />
      }
      {...sharedSelectProps}
    >
      {getGenderMenuItems({ gender })}
    </Select>
  );
}

const vehicleTypes = [
  { value: VehicleType.Coupe, name: "Coupe" },
  { value: VehicleType.LargeVehicle, name: "Large Vehicle" },
  { value: VehicleType.Sedan, name: "Sedan" },
  { value: VehicleType.Suv, name: "SUV" },
  { value: VehicleType.Truck, name: "Truck" },
  { value: VehicleType.Van, name: "Van" },
];
export const vehicleTypesMap = keyBy("value", vehicleTypes);

function ThemedVehicleTypeSelect() {
  const selected = useArraySearchFilter("vehicleType") ?? [];
  const setSelected = useSetArraySearchFilter("vehicleType");
  const isActive = selected.length > 0;
  const colors = themedColors.vehicles;
  const label = "Vehicle Type";

  return (
    <FormControl className="w-[10.5rem] [@media(min-width:450px)]:w-48">
      <InputLabel {...getLabelProps(colors)}>{label}</InputLabel>
      <Select
        fullWidth
        {...getMultiSelectProps({
          selected,
          setSelected,
          label,
          Icon: VehicleTypeIcon,
          sx: (theme) => getThemedSelectStyles({ isActive, theme, colors }),
        })}
      >
        {vehicleTypes.map(({ value, name }) => {
          const checked = selected.includes(value);
          return (
            <MenuItem {...getMenuItemProps({ value, selected: checked })}>
              <Checkbox
                checked={checked}
                disableRipple
                sx={{ padding: 0, color: "#CCCCCC", marginRight: "12px" }}
              />
              {name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

const vehicleMakes = [
  { value: VehicleMake.Acura, name: "Acura" },
  { value: VehicleMake.Audi, name: "Audi" },
  { value: VehicleMake.Bmw, name: "BMW" },
  { value: VehicleMake.Chevrolet, name: "Chevrolet" },
  { value: VehicleMake.Chrysler, name: "Chrysler" },
  { value: VehicleMake.Dodge, name: "Dodge" },
  { value: VehicleMake.Ford, name: "Ford" },
  { value: VehicleMake.Gmc, name: "GMC" },
  { value: VehicleMake.Honda, name: "Honda" },
  { value: VehicleMake.Hyundai, name: "Hyundai" },
  { value: VehicleMake.Infiniti, name: "Infiniti" },
  { value: VehicleMake.Jeep, name: "Jeep" },
  { value: VehicleMake.Kia, name: "Kia" },
  { value: VehicleMake.Lexus, name: "Lexus" },
  { value: VehicleMake.Mazda, name: "Mazda" },
  { value: VehicleMake.Mercedes, name: "Mercedes" },
  { value: VehicleMake.Nissan, name: "Nissan" },
  { value: VehicleMake.Subaru, name: "Subaru" },
  { value: VehicleMake.Toyota, name: "Toyota" },
  { value: VehicleMake.Volkswagen, name: "Volkswagen" },
];
export const vehicleMakesMap = keyBy("value", vehicleMakes);

function ThemedVehicleMakeSelect() {
  const selected = useArraySearchFilter("vehicleMake") ?? [];
  const setSelected = useSetArraySearchFilter("vehicleMake");
  const isActive = selected.length > 0;
  const colors = themedColors.vehicles;
  const label = "Vehicle Make";

  return (
    <FormControl className="w-[10.5rem] [@media(min-width:450px)]:w-48">
      <InputLabel {...getLabelProps(colors)}>{label}</InputLabel>
      <Select
        fullWidth
        {...getMultiSelectProps({
          selected,
          setSelected,
          label,
          Icon: VehicleMakeIcon,
          sx: (theme) => getThemedSelectStyles({ isActive, theme, colors }),
        })}
      >
        {vehicleMakes.map(({ value, name }) => {
          const checked = selected.includes(value);
          return (
            <MenuItem {...getMenuItemProps({ value, selected: checked })}>
              <Checkbox
                checked={checked}
                disableRipple
                sx={{ padding: 0, color: "#CCCCCC", marginRight: "12px" }}
              />
              {name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
