import { CircularProgress, Popover } from "@mui/material";
import clsx from "clsx";
import { useRef, useState } from "react";

import { formatDurationSecs } from "@/util/formatDurationSecs";

import { useHeatmap } from "./useHeatmap";

export interface colorValuesInt {
  min: number;
  max: number;
}

export default function IntelligenceHeatmapOverlay({
  intDashId,
  startDate,
  endDate,
  showTooltip,
  date,
  colorValues,
  tooltipText,
  timeframeText,
}: {
  intDashId: number;
  startDate: string;
  endDate: string;
  showTooltip: boolean;
  date?: string;
  colorValues?: colorValuesInt;
  tooltipText?: string;
  timeframeText?: string;
}) {
  const { data, loading, error, maxHeat, minHeat } = useHeatmap({
    intDashId,
    input: {
      startDate,
      endDate,
      gridSize: 50,
    },
  });

  return (
    <div
      className={clsx(
        "transition-all absolute h-full w-full blur-md pointer-events-auto",
        !!data ? "opacity-70" : "opacity-0"
      )}
      style={{ background: !!data ? "transparent" : undefined }}
    >
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <>{error.toString()}</>
      ) : data?.intelligenceDashboard?.heatmap.results ? (
        <>
          {data.intelligenceDashboard.heatmap.results.map((h) => (
            <HeatCell
              key={`${h.x}-${h.y}`}
              gridSize={50}
              maxHeat={maxHeat}
              minHeat={minHeat}
              showTooltip={showTooltip}
              date={date || undefined}
              tooltipText={tooltipText}
              timeframeText={timeframeText}
              colorValues={
                colorValues || {
                  min: 213,
                  max: 302,
                }
              }
              {...h}
            />
          ))}
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

function HeatCell({
  minHeat,
  maxHeat,
  x,
  y,
  heat,
  presenceSumMsec,
  throughput,
  gridSize,
  showTooltip,
  tooltipText,
  date,
  colorValues,
  timeframeText,
}: {
  gridSize: number;
  minHeat: number;
  maxHeat: number;
  x: number;
  y: number;
  heat: number;
  presenceSumMsec: number;
  throughput?: number | null;
  showTooltip: boolean;
  tooltipText?: string;
  date?: string;
  colorValues: colorValuesInt;
  timeframeText?: string;
}) {
  const anchor = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);
  return (
    <>
      <div
        key={`${x}-${y}`}
        ref={anchor}
        className="absolute opacity-70"
        style={{
          width: `${((1 / gridSize) * 100).toFixed(4)}%`,
          height: `${((1 / gridSize) * 100).toFixed(4)}%`,
          left: `${((x / gridSize) * 100).toFixed(4)}%`,
          top: `${((y / gridSize) * 100).toFixed(4)}%`,
          background:
            heat === 0
              ? "transparent"
              : heatMapColorforValue(
                  (heat - minHeat) / (maxHeat - minHeat),
                  colorValues.min,
                  colorValues.max
                ),
        }}
        onMouseEnter={() => showTooltip && setOpen(true)}
        onMouseLeave={() => showTooltip && setOpen(false)}
      />
      {showTooltip && (
        <Popover
          className="opacity-70"
          sx={{
            pointerEvents: "none",
          }}
          disableRestoreFocus={true}
          anchorEl={anchor.current}
          open={open}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
        >
          <div className="bg-[#262626]/95 flex flex-row gap-2.5 p-3 relative">
            <div className="text-center text-base text-white leading-[1.17rem] w-[166px]">
              <strong>
                {`${tooltipText || "Custom presence"} for`}
                <br />
                {formatDurationSecs(Math.round(presenceSumMsec / 1000))}
              </strong>
              {timeframeText && ` on ${timeframeText}`}
            </div>
          </div>
        </Popover>
      )}
    </>
  );
}

function heatMapColorforValue(value: number, minHue: number, maxHue: number) {
  const h = (maxHue - minHue) * value + minHue;
  // var h = (1.0 - (value - min)) * 240;
  return `hsl(${h}, 100%, 50%)`;
}
