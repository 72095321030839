import { Divider } from "@mui/material";
import { utcToZonedTime } from "date-fns-tz";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useNavigate } from "react-router-dom";

import { dateTimeFormatSecs } from "@/util/date";
import { formatDurationSecs } from "@/util/formatDurationSecs";
import { useBreakpoints } from "@/util/useBreakpoints";
import { buildVodUrl } from "@/util/vod";

import { DataGrid } from "@/components/DataGrid/DataGrid";
import { PlayVideoButton } from "@/components/PlayVideoButton";
import {
  DataGridContextualPlayer,
  useDataGridContextPlayer,
} from "@/components/VideoWall/ContextualVideoPlayer";

import { IdleMetrics, useCameraByIdQuery } from "@/generated-models";

import { DatagridStill } from "../Datagrid";
import { footageIsAvailable } from "../utils";

export default function IdleDashboardEntitiesDatagrid({
  objectIds,
  cameraId,
  timezone,
  entities,
}: {
  objectIds?: string[] | null;
  cameraId: number;
  timezone: string;
  entities: IdleMetrics["entities"];
}) {
  const navigate = useNavigate();
  const { fitsDesktop } = useBreakpoints();
  const { contextualPlayer } = useFlags();
  const { loading, data, error } = useCameraByIdQuery({
    variables: { cameraId: Number(cameraId) },
  });
  const {
    open,
    handleOpen,
    handleClose,
    playerProps,
  } = useDataGridContextPlayer();

  return (
    <div className="w-full" data-cy="maintain-datagrid">
      <DataGrid
        classes={{ row: "cursor-pointer" }}
        columnVisibilityModel={{
          id: fitsDesktop,
          queueNum: fitsDesktop,
          firstMs: fitsDesktop,
          lastMs: fitsDesktop,
          video: fitsDesktop,
        }}
        getRowId={({ objectId }) => objectId}
        rowHeight={fitsDesktop ? 58 : 115}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
          sorting: { sortModel: [{ field: "idleSum", sort: "desc" }] },
        }}
        columns={[
          {
            field: "id",
            headerName: "Preview",
            sortable: false,
            headerClassName: "invisible",
            cellClassName: "pl-0",

            renderCell: ({ row: { firstMs, lastMs } }) => {
              return (
                <DatagridStill
                  cameraId={cameraId}
                  start={new Date(firstMs)}
                  end={new Date(lastMs)}
                />
              );
            },
          },
          {
            field: "queueNum",
            headerName: "Queue #",
            flex: 1,
            renderCell: ({ row: { queueNum } }) => `#${queueNum}`,
          },
          {
            field: "idleSum",
            flex: 2,
            renderHeader: () => {
              return fitsDesktop ? (
                "Idle Time Length"
              ) : (
                <span>
                  Sort By:{" "}
                  <strong className="text-primary">Idle Time Length</strong>
                </span>
              );
            },
            headerName: "Idle Time Length",
            renderCell: ({ row: { idleSum, queueNum, firstMs, lastMs } }) => {
              const length = formatDurationSecs(idleSum as number);
              if (fitsDesktop) {
                return <strong>{length}</strong>;
              }

              return (
                <div className="flex items-center justify-between gap-x-4">
                  <div className="w-[155px] border border-blue-500">
                    <DatagridStill
                      cameraId={cameraId}
                      start={new Date(firstMs)}
                      end={new Date(lastMs)}
                    />
                  </div>

                  <div className="flex flex-col gap-y-2">
                    <div className="flex gap-x-2 items-center">
                      <strong>{length}</strong>
                      <Divider className="h-[15px]" orientation="vertical" />
                      <div>{`Queue #${queueNum}`}</div>
                    </div>
                    <div className="text-sm leading-4">
                      {dateTimeFormatSecs.format(
                        utcToZonedTime(firstMs, timezone)
                      )}
                    </div>
                    <div className="text-sm leading-4">
                      {dateTimeFormatSecs.format(
                        utcToZonedTime(lastMs, timezone)
                      )}
                    </div>
                    <PlayVideoButton
                      available={footageIsAvailable(firstMs, data?.camera)}
                    />
                  </div>
                </div>
              );
            },
          },
          {
            field: "firstMs",
            flex: 2,
            headerName: "Entered",
            renderCell: ({ row: { firstMs } }) =>
              dateTimeFormatSecs.format(utcToZonedTime(firstMs, timezone)),
          },
          {
            field: "lastMs",
            flex: 2,
            headerName: "Exited",
            renderCell: ({ row: { lastMs } }) =>
              dateTimeFormatSecs.format(utcToZonedTime(lastMs, timezone)),
          },
          {
            field: "video",
            sortable: false,
            flex: 2,
            headerName: "Video",
            renderCell: ({ row: { firstMs } }) => {
              return (
                <PlayVideoButton
                  available={footageIsAvailable(firstMs, data?.camera)}
                />
              );
            },
          },
        ]}
        rows={entities || []}
        onRowClick={({ row: { firstMs, lastMs, duration } }) => {
          if (!footageIsAvailable(firstMs, data?.camera)) {
            return;
          }

          if (contextualPlayer && data && !error && !loading) {
            const length = formatDurationSecs(duration);
            handleOpen({
              startTime: new Date(firstMs).toISOString(),
              endTime: new Date(lastMs).toISOString(),
              camera: data.camera,
              length: length,
              subjects: objectIds,
            });
          } else {
            navigate(buildVodUrl(cameraId, firstMs, lastMs, objectIds));
          }
        }}
      />
      <DataGridContextualPlayer
        open={open}
        handleClose={handleClose}
        playerProps={playerProps}
      />
    </div>
  );
}
