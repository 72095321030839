import ArrowDownIcon from "@mui/icons-material/ArrowDropDown";
import CheckIcon from "@mui/icons-material/Check";
import { Button, Popover } from "@mui/material";
import clsx from "clsx";
import { FieldProps } from "formik";
import React, { useEffect, useState } from "react";
import { useUpdateEffect } from "react-use";

import { formatDurationSecs } from "@/util/formatDurationSecs";

import { CustomDurationInput } from "./CustomDurationInput";

interface DurationSelectProps {
  classes?: { button?: string; popover?: string };
  value: number;
  onChange: (value: number) => void;
  options: number[];
  disabled?: boolean;
  allowCustom?: boolean;
  variant?: "outlined" | "underlined";
}
export function DurationSelect({
  classes,
  value,
  onChange,
  options,
  disabled,
  allowCustom = true,
  variant = "outlined",
}: DurationSelectProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const closePopover = () => setAnchorEl(null);
  const [customDurationActive, setCustomDurationActive] = useState(false);

  useEffect(() => {
    setCustomDurationActive(false);
  }, [anchorEl]);

  return (
    <>
      <Button
        variant={variant === "outlined" ? "outlined" : "text"}
        className={clsx("h-10 border-[#BDBDBD] font-normal", classes?.button, {
          "border-b border-gray-75 border-solid rounded-none px-0":
            variant === "underlined",
        })}
        onClick={(e) => {
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}
        disabled={disabled}
        endIcon={<ArrowDownIcon />}
      >
        {value === 0
          ? "Instant"
          : formatDurationSecs(value, { hideZeroes: true, long: true })}
      </Button>
      <Popover
        anchorEl={anchorEl}
        open={!!anchorEl}
        disablePortal
        classes={{ paper: "bg-none overflow-hidden" }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => {
          if (customDurationActive) return;
          closePopover();
        }}
      >
        <div
          className={clsx(
            "w-full top-full rounded z-20 flex flex-col p-4",
            classes?.popover
          )}
        >
          {options.map((option) => {
            const active = option === value && !customDurationActive;
            return (
              <Button
                key={option}
                className={clsx("text-inherit justify-start px-6 font-normal", {
                  "pl-0 font-bold": active,
                })}
                startIcon={active ? <CheckIcon color="primary" /> : undefined}
                onClick={() => {
                  onChange(option);
                  closePopover();
                }}
              >
                {option === 0
                  ? "Instant"
                  : formatDurationSecs(option, {
                      hideZeroes: true,
                      long: true,
                    })}
              </Button>
            );
          })}
          {customDurationActive ? (
            <>
              <div className="flex my-2 flex-wrap">
                <CheckIcon color="primary" className="mr-2 -ml-1 text-xl" />
                <CustomDurationInput
                  onChange={({ sec, min, hour }) => {
                    if (
                      sec + min + hour === 0 &&
                      options.every((o) => o !== 0)
                    ) {
                      setCustomDurationActive(false);
                      return;
                    }
                    onChange(sec + min * 60 + hour * 3600);
                    console.log("closing");
                    closePopover();
                  }}
                />
              </div>
              <div className="flex-center w-full mt-2">
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  className="p-0.5"
                >
                  Save
                </Button>
              </div>
            </>
          ) : (
            allowCustom && (
              <Button
                className="text-inherit justify-start px-6 font-normal"
                onClick={() => setCustomDurationActive(true)}
              >
                Custom
              </Button>
            )
          )}
        </div>
      </Popover>
    </>
  );
}

export function DurationSelectFormik({
  field,
  form,
  meta,
  ...rest
}: FieldProps<number> &
  Pick<
    DurationSelectProps,
    "options" | "classes" | "disabled" | "allowCustom" | "variant"
  >) {
  useUpdateEffect(() => {
    form.setFieldValue(field.name, rest.options[0]);
    // explicitly don't run this effect when the field value changes, that
    // would break custom durations.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest.options.join(",")]);
  return (
    <DurationSelect
      onChange={(value) => {
        form.setFieldValue(field.name, value);
      }}
      value={field.value}
      {...rest}
    />
  );
}
