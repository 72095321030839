import ArrowLeft from "@mui/icons-material/ArrowLeft";
import ArrowRight from "@mui/icons-material/ArrowRight";
import Skeleton from "@mui/material/Skeleton";
import clsx from "clsx";
import { utcToZonedTime } from "date-fns-tz";
import { clamp } from "lodash/fp";
import React, { useState } from "react";
import { makeStyles } from "tss-react/mui";

import { timeFormat } from "@/util/date";

import { FixedAspectRatio } from "@/components/FixedAspectRatio";
import { StillImage } from "@/components/Still/StillImage";

import { Still } from "@/generated-models";

const useStyles = makeStyles()((theme) => ({
  snippet: {
    cursor: "pointer",

    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
  box: {
    position: "relative",
    overflow: "hidden",
  },
  outline: {
    border: `2px solid ${theme.palette.primary.main}`,
  },
  viewing: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    top: 3,
    left: 3,
    padding: "2px 4px 2px 1px",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    borderRadius: 2,
    fontSize: 11,
    fontWeight: "bold",
    pointerEvents: "none",
  },
  timestamp: {
    backgroundColor: "#383838",
    opacity: 0.7,
    color: "white",
    borderRadius: 2,
    fontSize: 11,
    fontWeight: "bold",
    padding: "2px 7px",
    position: "absolute",
    top: 3,
    right: 3,
    pointerEvents: "none",
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },

  progress: {
    color: "white",
    position: "absolute",
    width: 1,
    height: "100%",
    top: 0,
    backgroundColor: "white",

    pointerEvents: "none",
  },
  arrow: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
  },
  left: {
    right: "100%",
  },
  right: {
    left: "100%",
  },
}));

interface SnippetPreviewProps {
  timezone: string;
  stills: Pick<Still, "src" | "timestamp">[] | null; // null when loading
  fetchMoreStills?: () => void;
  hoverProgress: number;
  setHoverProgress: React.Dispatch<React.SetStateAction<number>>;
  className?: string;
}

/**
 * Note to future reader:
 * This used to be the main snippet preview component for the <VodTiles>.
 * It has since been replaced by the <ExpandingSnippetPreview>, this component
 * wasn't being used anywhere.
 *
 * I (Edo) have now hijacked this one for Case Clip cards and have tweaked
 * the default styles to fit that component.
 */
export function SnippetPreview({
  timezone,
  stills,
  fetchMoreStills,
  hoverProgress,
  setHoverProgress,
  className,
}: SnippetPreviewProps) {
  const { classes } = useStyles();
  const [mouseOver, setMouseOver] = useState(false);
  const [loadedStills, setLoadedStills] = useState(new Set<string>());

  if (!stills?.length) {
    return (
      <FixedAspectRatio ratio="9 / 16">
        <div
          className="w-full h-full bg-contain bg-center"
          style={{ backgroundImage: "url(/no-still.svg)" }}
        />
      </FixedAspectRatio>
    );
  }

  const hoverIndex = Math.round(hoverProgress * (stills.length - 1));
  const caretProgress = hoverProgress;

  // Handles graphql loading state
  const hoveringStill = stills[hoverIndex < stills.length ? hoverIndex : 0] as
    | Still
    | undefined;

  // Handles img loading state
  const displayingStill =
    hoveringStill &&
    (loadedStills.has(hoveringStill.timestamp) ? hoveringStill : stills[0]);

  const zonedDate =
    hoveringStill &&
    utcToZonedTime(new Date(hoveringStill.timestamp), timezone);

  return (
    <div
      className={clsx(classes.snippet, className)}
      onMouseEnter={() => {
        setMouseOver(true);
        fetchMoreStills?.();
      }}
      onMouseLeave={() => setHoverProgress(0)}
      onMouseMove={(e) => {
        setHoverProgress(
          clamp(
            0,
            1,
            e.nativeEvent.offsetX / (e.nativeEvent.target as any).clientWidth
          )
        );
      }}
    >
      <FixedAspectRatio ratio="9 / 16" className={classes.box}>
        {(!displayingStill || !loadedStills.has(displayingStill.timestamp)) && (
          <Skeleton variant="rectangular" height="100%" />
        )}
        {stills.length > 0 && (
          <>
            {(mouseOver ? stills : [stills[hoverIndex]]).map((still) => (
              <StillImage
                key={still.timestamp}
                src={still.src}
                alt=""
                onLoad={() =>
                  setLoadedStills(new Set([...loadedStills, still.timestamp]))
                }
                style={{
                  display:
                    loadedStills.has(still.timestamp) &&
                    still === displayingStill
                      ? "block"
                      : "none",
                }}
              />
            ))}
            {caretProgress ? (
              <div
                className={classes.progress}
                style={{ left: `${(caretProgress || 0) * 100}%` }}
              >
                <ArrowLeft className={clsx(classes.arrow, classes.left)} />
                <ArrowRight className={clsx(classes.arrow, classes.right)} />
              </div>
            ) : null}
            {zonedDate && (
              <div className={classes.timestamp}>
                {timeFormat.format(zonedDate)}
              </div>
            )}
          </>
        )}
      </FixedAspectRatio>
    </div>
  );
}
