import { useSetAtom } from "jotai";
import { useCallback } from "react";
import { useHotkeys } from "react-hotkeys-hook";

import { useFullScreenHandle } from "@/components/FullScreen";
import {
  playbackRateState,
  playingIntentState,
  useMultiPlayerControls,
} from "@/components/Player/PlayerBase";
import { playbackRateOptions } from "@/components/Player/PlayerSettingsButton";
import {
  PlayerControlsEvent,
  usePlayerControlsService,
} from "@/components/Player/playerControlsMachine";

export function KeyboardShortcuts() {
  const { seek } = useMultiPlayerControls();
  const setPlaying = useSetAtom(playingIntentState);
  const togglePlaying = useCallback(() => setPlaying((value) => !value), [
    setPlaying,
  ]);
  const { send } = usePlayerControlsService();
  const { active, enter, exit } = useFullScreenHandle();
  const setPlaybackRate = useSetAtom(playbackRateState);
  useHotkeys(
    "right",
    () => {
      seek((p) => p + 10);
      send(PlayerControlsEvent.SHOW_CONTROLS);
    },
    undefined,
    [seek]
  );
  useHotkeys(
    "left",
    () => {
      seek((p) => p - 10);
      send(PlayerControlsEvent.SHOW_CONTROLS);
    },
    undefined,
    [seek]
  );
  useHotkeys(
    "up",
    (e) => {
      e.preventDefault();
      send(PlayerControlsEvent.SHOW_CONTROLS);

      setPlaybackRate((current) => {
        const currentRateIndex = playbackRateOptions.findIndex(
          (o) => o.value === current
        );
        if (
          currentRateIndex === -1 ||
          currentRateIndex >= playbackRateOptions.length - 1
        )
          return current;
        return playbackRateOptions[currentRateIndex + 1].value;
      });
    },
    undefined,
    [setPlaybackRate, send]
  );
  useHotkeys(
    "down",
    (e) => {
      e.preventDefault();
      send(PlayerControlsEvent.SHOW_CONTROLS);

      setPlaybackRate((current) => {
        const currentRateIndex = playbackRateOptions.findIndex(
          (o) => o.value === current
        );
        if (currentRateIndex <= 0) return current;
        return playbackRateOptions[currentRateIndex - 1].value;
      });
    },
    undefined,
    [setPlaybackRate, send]
  );
  useHotkeys(
    "space",
    (event) => {
      event.preventDefault();
      togglePlaying();
      send(PlayerControlsEvent.SHOW_CONTROLS);
    },
    undefined,
    [seek, togglePlaying]
  );
  useHotkeys(
    "f",
    () => {
      active ? exit() : enter();
    },
    undefined,
    [seek, active, enter, exit]
  );
  return null;
}
