import { Checkbox, Skeleton, Typography } from "@mui/material";
import { useAtom, useSetAtom } from "jotai";
import { startCase } from "lodash";

import { pluralize } from "@/util/pluralize";

import {
  InterestListType,
  usePage_InterestListsQuery,
} from "@/generated-models";

import { ErrorMessage } from "../../ErrorMessage";
import { interestListConfig, InterestListFormMode } from "../constants";
import {
  currentListItemAtom,
  listModeAtom,
  selectedListItemsAtom,
} from "../hooks";
import { InterestListTextInputLabel } from "./";

export function InterestListSelectionMenu({
  type,
  prompt,
}: {
  type: InterestListType;
  prompt?: string;
}) {
  const { data, error } = usePage_InterestListsQuery();
  const config = interestListConfig[type];

  const setMode = useSetAtom(listModeAtom);
  const setCurrentListItem = useSetAtom(currentListItemAtom);
  const [selectedItems, setSelectedItems] = useAtom(selectedListItemsAtom);

  if (error) {
    return (
      <ErrorMessage
        title="Unable to load lists"
        description="We're having trouble loading lists, please try again later"
      />
    );
  }

  if (!data?.interestLists) {
    return (
      <div className="flex flex-col gap-3">
        <Skeleton className="rounded-lg" variant="rectangular" height={44} />
        <Skeleton className="rounded-lg" variant="rectangular" height={44} />
        <Skeleton className="rounded-lg" variant="rectangular" height={44} />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-6">
      <Typography className="text-sm leading-[16.41px]">
        {prompt ?? config.selectPrompt}
      </Typography>
      <div>
        <InterestListTextInputLabel
          label={`${startCase(config.resourceName)} List`}
        />
        <div className="flex flex-col gap-3 max-h-[400px] overflow-y-scroll">
          {data.interestLists.map((list) => (
            <div className="flex items-center justify-start border border-solid border-[#AAAAAA] rounded-lg pr-4">
              <Checkbox
                checked={selectedItems?.includes(list.id)}
                onChange={(e) => {
                  setSelectedItems((curr) => {
                    if (e.target.checked) {
                      return [...(curr || []), list.id];
                    }
                    return curr?.filter((l) => l !== list.id);
                  });
                }}
              />
              <Typography className="text-base leading-[18.75px]">
                <strong>{list.name}</strong>{" "}
                {`(${list.items.length} ${pluralize(
                  {
                    1: config.resourceShortName,
                    multi: `${config.resourceShortName}s`,
                  },
                  list.items.length
                )})`}
              </Typography>
              <button
                type="button"
                className="text-primary bg-transparent text-base leading-[18.75px] ml-auto"
                onClick={() => {
                  setCurrentListItem(list.id);
                  setMode(InterestListFormMode.edit);
                }}
              >
                View/Edit
              </button>
            </div>
          ))}
        </div>
        <button
          type="button"
          className="text-primary bg-transparent text-base leading-[19px] font-bold mt-3"
          onClick={() => {
            setMode(InterestListFormMode.create);
          }}
        >
          + Create New List
        </button>
      </div>
    </div>
  );
}
