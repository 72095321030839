import { Box, CircularProgress, Typography } from "@mui/material";
import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { NumberParam, useQueryParam } from "use-query-params";

import { useDeviceProxyLink } from "@/util/useDeviceProxyLink";

import Proxy from "@/pages/Onboarding/ApplianceProxy/Proxy";

import { ErrorMessage } from "@/components/ErrorMessage";

export default function ProxyPage() {
  const [params] = useSearchParams();
  const native = params.get("native")?.toLowerCase() === "true";
  const { applianceSerial, proxyEndpoint } = useParams();

  const [deviceId] = useQueryParam("d", NumberParam);
  const proxyLink = useDeviceProxyLink(
    applianceSerial || "",
    proxyEndpoint,
    native
  );

  // Bypass rendering the configuration page for native configuration pages.
  useEffect(() => {
    if (native && applianceSerial) {
      window.location.replace(proxyLink);
    }
  }, [native, applianceSerial, proxyLink]);

  if (!applianceSerial) {
    return <ErrorMessage title="Oops" description="Incorrect URL" />;
  }

  if (native) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        flexGrow={1}
      >
        <CircularProgress />
        <Box m={1} />
        <Typography variant="body1">
          Securely authenticating with appliance
        </Typography>
      </Box>
    );
  }

  return (
    <Box width="100vw" height="100vh">
      <Proxy
        proxyEndpoint={proxyEndpoint}
        applianceSerial={applianceSerial}
        deviceId={deviceId || undefined}
      />
    </Box>
  );
}
