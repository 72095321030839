import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { Divider, Tooltip, Typography } from "@mui/material";
import clsx from "clsx";
import { Fragment, ReactNode, forwardRef, useMemo } from "react";

import { PawIcon } from "@/icons/Paw";

import { useBreakpoints } from "@/util/useBreakpoints";
import { useIsInSpecStream, useIsValidStream } from "@/util/validation/stream";

import { StreamInfoMetadataMetric } from "@/components/StreamSelectionDropdown/StreamInfoMetadataMetric";

import {
  DefaultCameraSettings,
  DeviceScanByIdQuery,
  DeviceStream,
} from "@/generated-models";

import { CAMERA_STREAM_SETTINGS_MOBILE_TEXT } from "./constants";

const PATH_MAX_LEN = 30;

function PathInfo({
  recommended,
  inactive,
  path = "/",
}: {
  recommended?: boolean;
  inactive?: boolean;
  path?: string;
}) {
  const overflow = path.length >= PATH_MAX_LEN;
  return (
    <div
      className={clsx(
        "font-['Courier'] leading-[14px] bg-white rounded-md box-border p-2 border border-solid",
        {
          "border-[#CEE9FF]": !inactive,
          "border-[#DFDFDF]": inactive,
        }
      )}
    >
      <div className="flex justify-between flex-col sm:flex-row gap-y-2">
        <span>
          Path:{" "}
          <Tooltip title={overflow ? path : ""}>
            <span className="font-bold">{`${path?.substring(0, PATH_MAX_LEN)}${
              overflow ? "..." : ""
            }`}</span>
          </Tooltip>
        </span>
        {recommended && (
          <div className="flex gap-x-2 font-sans text-xs font-bold text-primary items-center pr-2">
            Recommended <PawIcon style={{ height: 16, width: 13 }} />
          </div>
        )}
      </div>
    </div>
  );
}

function MetadataInfo({
  recommendedCameraSettings,
  className,
  showErrors,
  inactive,
  stream,
  onOpenNativeProxy,
}: {
  recommendedCameraSettings?: DefaultCameraSettings;
  className?: string;
  showErrors?: boolean;
  inactive?: boolean;
  stream?: NonNullable<
    DeviceScanByIdQuery["deviceScan"]
  >["channels"][number]["streams"][number];
  onOpenNativeProxy: () => void;
}) {
  const { fitsDesktop } = useBreakpoints();

  const { specs, errors, errorFields, errorRecs } = useIsInSpecStream(
    stream as DeviceStream,
    recommendedCameraSettings
  );

  const filteredSpecs = useMemo(
    () =>
      specs.filter((s) => s.label !== "Bitrate" && s.label !== "Rate Control"),
    [specs]
  );

  return (
    <div>
      <div
        className={clsx(
          className,
          "rounded-md px-1 py-2 border border-solid flex justify-around flex-col sm:flex-row gap-y-1",
          {
            "border-[#CEE9FF]": !inactive,
            "border-[#DFDFDF] bg-white opacity-50": inactive,
          }
        )}
      >
        {filteredSpecs.map((item, idx) => {
          return (
            <Fragment key={item.label}>
              <StreamInfoMetadataMetric key={item.label} {...item} />
              {idx < specs.length - 1 && (
                <Divider
                  key={`divider-${item.label}`}
                  orientation="vertical"
                  flexItem
                />
              )}
            </Fragment>
          );
        })}
      </div>
      {showErrors && errors.length > 0 && (
        <div>
          <div className="flex gap-x-2 mt-3 items-center max-w-[450px]">
            <ReportProblemOutlinedIcon className="text-[#FF9800] h-6" />
            <Typography variant="body2" className="whitespace-normal leading-4">
              <span className="font-bold text-[#F44336]">{errorFields}</span>{" "}
              <span>{errors.length > 1 ? "are" : "is"}</span> not recommended
              {fitsDesktop ? (
                <>
                  {", "}
                  <span>{errors.length > 1 ? "they" : "it"}</span> can impact
                  latency, buffering and reduce storage days of videos. Please
                  change <span>{errors.length > 1 ? "them" : "it"}</span>{" "}
                  {recommendedCameraSettings ? "to" : ""}{" "}
                  {recommendedCameraSettings && (
                    <span className="font-bold">{errorRecs}</span>
                  )}{" "}
                  through{" "}
                  <span
                    className="text-primary cursor-pointer font-bold"
                    onClick={onOpenNativeProxy}
                  >
                    Native Configure.
                  </span>
                </>
              ) : (
                `. ${CAMERA_STREAM_SETTINGS_MOBILE_TEXT}`
              )}
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
}

interface StreamInfoProps {
  className?: string;
  recommendedCameraSettings?: DefaultCameraSettings;
  metadataClassName?: string;
  recommended?: boolean;
  inactive?: boolean;
  selected?: boolean;
  showErrors?: boolean;
  stream?: NonNullable<
    DeviceScanByIdQuery["deviceScan"]
  >["channels"][number]["streams"][number];
  children?: ReactNode;
  onOpenNativeProxy: () => void;
}

export const StreamInfoTile = forwardRef<HTMLDivElement, StreamInfoProps>(
  function StreamInfoTile(
    {
      className,
      metadataClassName,
      recommended,
      inactive,
      selected,
      showErrors,
      stream,
      children,
      onOpenNativeProxy,
      recommendedCameraSettings,
    },
    ref
  ) {
    const metadata = stream?.metadata;
    const path = stream?.path;
    const pathError = useIsValidStream(stream as DeviceStream);
    const error = !!pathError || !metadata;

    return (
      <div
        ref={ref}
        className={clsx(
          "rounded-lg p-3 border-solid",
          {
            "border border-[#ECECEC] bg-[#F4F4F4]/50": inactive,
            "border-2 border-[#F44336] bg-[#FFD9D7]/50": error && showErrors,
            "border-2 border-[#317DDD] bg-[#F6FBFF]":
              !inactive && (!error || !showErrors),
            "border-opacity-40": !selected,
          },
          className
        )}
      >
        <div className="flex flex-col gap-y-1">
          {children}
          <PathInfo recommended={recommended} inactive={inactive} path={path} />
          {metadata && (
            <MetadataInfo
              className={metadataClassName}
              inactive={inactive}
              stream={stream}
              showErrors={showErrors}
              onOpenNativeProxy={onOpenNativeProxy}
              recommendedCameraSettings={recommendedCameraSettings}
            />
          )}
          {error && (
            <span className="text-center mt-2 text-[#F44336] font-bold">
              {pathError || "No streams found"}
            </span>
          )}
        </div>
      </div>
    );
  }
);
