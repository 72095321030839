import BlockIcon from "@mui/icons-material/Block";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import { Button, Hidden, IconButton, Tooltip, Typography } from "@mui/material";
import clsx from "clsx";
import { utcToZonedTime } from "date-fns-tz";
import { useFlags } from "launchdarkly-react-client-sdk";
import { uniqBy } from "lodash/fp";
import { ReactNode, useMemo, useState } from "react";

import { dateTimeFormat } from "@/util/date";
import { useBreakpoints } from "@/util/useBreakpoints";

import { useLimitedStills } from "@/components/Camera/ExpandingSnippetPreview";
import { DataGrid } from "@/components/DataGrid/DataGrid";
import { AddToInterestListButton } from "@/components/InterestList/AddToInterestListButton";
import { EditInterestListButton } from "@/components/InterestList/EditInterestListButton";
import { useInterestListNotes } from "@/components/InterestList/hooks";
import { CloseIcon } from "@/components/Player/PlayerIcons";
import { PlayerMachineProvider } from "@/components/Player/playerMachine";
import { StillImage } from "@/components/Still/StillImage";
import { NoStillsThumb } from "@/components/shared/NoStillsThumb";

import {
  IntelligenceDashboardQuery,
  InterestListItem,
  InterestListType,
  LprSearchResult,
} from "@/generated-models";

import { footageIsAvailable } from "../../utils";
import { Color, VehicleType } from "../constants";
import { isInterestListDashboard } from "../utils";
import { LicensePlateReportContextualChartPlayer } from "./LicensePlateReportContextualChartPlayer";
import {
  GroupedPlateMatch,
  useLprReportGrouped,
  useLprReportDetails,
  useLprResolvedVisits,
} from "./hooks";

type ResultType = LprSearchResult & { id: string };

interface LicensePlateContextualPlayerProps {
  dashboard: IntelligenceDashboardQuery["intelligenceDashboard"];
  interestList?: boolean;
  selectedResult: GroupedPlateMatch;
  open?: boolean;
  handleClose: () => void;
}

function LicensePlateConextualResultButton({
  item,
  selected,
  setSelected,
}: {
  item: ResultType;
  selected?: boolean;
  setSelected: (value: ResultType) => void;
}) {
  const hasFootage = footageIsAvailable(item.startTime, item.camera);
  const Icon = hasFootage ? PlayCircleOutlineIcon : BlockIcon;

  return (
    <button
      disabled={selected || !hasFootage}
      className={clsx(
        "text-text text-sm leading-4 px-4 flex items-center gap-1 bg-transparent",
        {
          "font-bold cursor-default": selected,
          "opacity-40": !hasFootage,
        }
      )}
      onClick={() => setSelected(item)}
    >
      <Tooltip
        title={hasFootage ? "" : "Footage unavailable, storage period exceeded"}
      >
        <Icon className="w-4 h-4" />
      </Tooltip>

      <div>
        {dateTimeFormat.format(
          utcToZonedTime(item.startTime, item.camera.location.timezone)
        )}
      </div>
    </button>
  );
}

function LicensePlateMobileDetailsCard({
  selectedResult,
  children,
}: {
  selectedResult: GroupedPlateMatch;
  children?: ReactNode;
}) {
  const { lprMmct } = useFlags();
  const resolvedVisits = useLprResolvedVisits();
  const {
    plate,
    firstSeen,
    lastSeen,
    cameras,
    visits,
    make,
    model,
    colorId,
    typeId,
  } = selectedResult;
  const camera = cameras?.[0];

  return (
    <div className="flex items-center gap-4">
      <div className="w-[120px]">
        <LicensePlateStill
          className="!w-[114px]"
          plate={plate}
          cameraId={cameras?.[0]?.id}
          start={firstSeen}
        />
      </div>
      <div className="flex flex-col gap-2 items-start">
        <Typography className="text-sm leading-[16.41px] font-bold">
          Last Seen:{" "}
          {lastSeen
            ? dateTimeFormat.format(
                utcToZonedTime(lastSeen, camera?.location?.timezone || "")
              )
            : ""}
        </Typography>
        <Typography className="text-sm leading-[16.41px]">
          First Seen:{" "}
          {firstSeen
            ? dateTimeFormat.format(
                utcToZonedTime(firstSeen, camera?.location?.timezone || "")
              )
            : ""}
        </Typography>
        <Typography className="text-sm leading-[16.41px]">
          Visits: {resolvedVisits[plate] ?? visits ?? 0}
        </Typography>
        {lprMmct && (
          <Typography className="text-sm leading-[16.41px]">
            {make} | {model} | {Color[colorId || 0]} |{" "}
            {VehicleType[typeId || 0]}
          </Typography>
        )}
        {children}
      </div>
    </div>
  );
}

function LicensePlateContextualPlayer({
  dashboard,
  interestList,
  selectedResult,
  open,
  handleClose,
}: LicensePlateContextualPlayerProps) {
  const lists = useInterestListNotes(dashboard?.interestLists);
  const { data: plateVists = [] } = useLprReportDetails(
    selectedResult.plate?.split("/")[0],
    true
  );
  const { lprMmct } = useFlags();
  const [selected, setSelected] = useState<ResultType>();
  const resolvedSelected = selected || plateVists[0];
  const plate = resolvedSelected?.vehicleAttributes?.plate;

  const [visits, firstVisit, lastVisit, count] = useMemo(() => {
    const result = [...plateVists].sort(
      (a, b) =>
        new Date(b.startTime).getTime() - new Date(a.startTime).getTime()
    );

    const count = result.length;

    if (count === 1) {
      return [result, null, null, count];
    }

    const firstVisit = result.splice(-1)[0];
    const lastVisit = result.splice(0, 1)[0];

    return [result, firstVisit, lastVisit, count];
  }, [plateVists]);

  const plateLists = useMemo(() => {
    const result = [] as (InterestListItem & {
      listId: number;
      listName: string;
    })[];
    const plateSegments = plate?.split("/") || [];

    Object.entries(lists).forEach(([listPlate, listInfo]) => {
      if (plateSegments[0] === listPlate || plateSegments[1] === listPlate) {
        result.push(...listInfo);
      }
    });

    return uniqBy("listId", result);
  }, [lists, plate]);

  return (
    <LicensePlateReportContextualChartPlayer
      cameraId={resolvedSelected?.camera?.id}
      startTime={resolvedSelected?.startTime || ""}
      endTime={resolvedSelected?.endTime || ""}
      open={open}
      handleClose={handleClose}
      header={
        <Hidden smUp>
          <div className="p-4 flex gap-4 bg-[#D9F5F4]">
            <LicensePlateMobileDetailsCard selectedResult={selectedResult} />
          </div>
        </Hidden>
      }
    >
      <div className="flex flex-col h-full">
        <div className="hidden sm:flex items-center justify-between px-4 mb-2">
          <div className="flex min-w-[200px]">
            <div className="font-mono text-[#00A69C] font-bold text-[28px] leading-[37px] tracking-[1.4px] px-3 py-2 rounded border border-solid border-[#00A69C] uppercase">
              {plate}
            </div>
          </div>
          <IconButton
            edge="end"
            className="w-3 h-3 ml-1 mb-auto"
            aria-label="close"
            onClick={handleClose}
          >
            <CloseIcon className="text-[#CCCCCC]" />
          </IconButton>
        </div>
        {lprMmct && !interestList && (
          <div className="hidden sm:flex flex-row justify-start divide-x divide-[#D9D9D9] px-4 mb-3">
            <Typography className="pr-2 text-base leading-[19px]">
              {selectedResult.make}
            </Typography>
            <Typography className="px-2 text-base leading-[19px]">
              {selectedResult.model}
            </Typography>
            <Typography className="px-2 text-base leading-[19px]">
              {Color[selectedResult.colorId || 0]}
            </Typography>
          </div>
        )}
        <AddToInterestListButton
          className="px-4 pb-2"
          type={InterestListType.Lpr}
          name={plate?.split("/")[0]}
        />
        {interestList && (
          <div className="px-4 pt-2 pb-4 flex flex-col gap-2">
            {plateLists.map(({ listId, listName, ...item }) => (
              <div>
                <Typography className="text-xs leading-4">
                  [{listName}]
                </Typography>
                <Typography className="italic text-sm leading-[16.41px]">
                  {item.notes}
                </Typography>
                <EditInterestListButton
                  noteName={item.name}
                  type={InterestListType.Lpr}
                  listId={listId}
                />
              </div>
            ))}
          </div>
        )}
        <div className="px-4 mb-3">
          <div className="w-full h-[1px] bg-[#00A69C] opacity-[0.18]" />
        </div>
        <Typography className="font-bold text-xl leading-[23.44px] px-4 mb-2">
          {count} Visits
        </Typography>
        <div>
          <div className="grid grid-cols-1">
            {lastVisit && (
              <div>
                <div
                  className={clsx("flex flex-col gap-1 rounded-r py-1", {
                    "bg-[#B2E4E1]": lastVisit.id === resolvedSelected?.id,
                  })}
                >
                  <Typography className="text-sm leading-4 font-bold pl-4">
                    Last Visit
                  </Typography>
                  <LicensePlateConextualResultButton
                    item={lastVisit as ResultType}
                    selected={lastVisit.id === resolvedSelected?.id}
                    setSelected={setSelected}
                  />
                </div>
                <div className="pl-4 my-2">
                  <div className="w-full h-[1px] bg-[#00A69C] opacity-[0.18]" />
                </div>
              </div>
            )}
            <div
              className={clsx("overflow-y-scroll", {
                "max-h-[400px] sm:max-h-[300px] md:max-h-[300px] lg:max-h-[350px] xl:max-h-[400px]": !interestList,
                "max-h-[200px] sm:max-h-[200px] md:max-h-[200px] lg:max-h-[300px] xl:max-h-[300px]": interestList,
              })}
            >
              {visits.map((v) => {
                return (
                  <div
                    key={v.id}
                    className={clsx("rounded-r py-2", {
                      "bg-[#B2E4E1]": v.id === resolvedSelected?.id,
                    })}
                  >
                    <LicensePlateConextualResultButton
                      key={v.id}
                      item={v as ResultType}
                      selected={v.id === resolvedSelected?.id}
                      setSelected={setSelected}
                    />
                  </div>
                );
              })}
            </div>
            <div className="pl-4 mt-2">
              <div className="w-full h-[1px] bg-[#00A69C] opacity-[0.18]" />
            </div>
            {firstVisit && (
              <div
                className={clsx("flex flex-col gap-1 rounded-r mt-4 py-1", {
                  "bg-[#B2E4E1]": firstVisit.id === resolvedSelected?.id,
                })}
              >
                <Typography className="text-sm leading-4 font-bold pl-4">
                  First Visit
                </Typography>
                <LicensePlateConextualResultButton
                  item={firstVisit as ResultType}
                  selected={firstVisit.id === resolvedSelected?.id}
                  setSelected={setSelected}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </LicensePlateReportContextualChartPlayer>
  );
}

function LicensePlateStill({
  className,
  plate,
  cameraId,
  start,
}: {
  className?: string;
  plate?: string | null;
  cameraId?: number;
  start?: string | null;
}) {
  const plates = plate?.split("/") || [];
  const startDate = start ? new Date(start) : new Date();

  const stills = useLimitedStills({
    start: startDate.toISOString(),
    end: startDate.toISOString(),
    cameraId,
    limited: true,
  });

  const resultingStill =
    stills.length > 0 ? (
      <StillImage
        className={clsx("h-full w-full object-cover", className)}
        src={stills[0].src}
        alt=""
      />
    ) : (
      <NoStillsThumb />
    );

  return (
    <div className="flex flex-col-reverse sm:flex-row items-center justify-between gap-2 sm:gap-4 w-full">
      <div className="flex-1">{resultingStill}</div>
      <div className="flex-1 flex flex-col gap-y-1 w-full px-0.5">
        <div className="flex-1 flex flex-col gap-y-1">
          {plates.map((p) => (
            <div
              key={p}
              className={clsx(
                "text-center border border-solid border-[#00A69C] rounded px-2 font-mono font-bold tracking-[1.4px] uppercase text-[#00A69C] bg-white",
                {
                  "text-base leading-[21px] py-1": plates.length === 1,
                  "text-sm leading-[18.46px] py-0.5": plates.length !== 1,
                }
              )}
            >
              {p}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export function LicensePlateReportDatagrid({
  dashboard,
}: {
  dashboard: IntelligenceDashboardQuery["intelligenceDashboard"];
}) {
  const { data: groupedReport, loading } = useLprReportGrouped(undefined, true);
  const { lprMmct } = useFlags();
  const { fitsDesktop } = useBreakpoints();

  const isInterestList = isInterestListDashboard(dashboard);

  const lists = useInterestListNotes(dashboard?.interestLists);

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState();

  const data = groupedReport?.lprReport || [];
  const resolvedVisits = useLprResolvedVisits();

  return (
    <div className="sm:mx-6 sm:pb-6">
      <PlayerMachineProvider>
        {selected && (
          <LicensePlateContextualPlayer
            open={open}
            dashboard={dashboard}
            interestList={isInterestList}
            selectedResult={selected}
            handleClose={() => {
              setOpen(false);
              setSelected(undefined);
            }}
          />
        )}
        <DataGrid
          classes={{ row: "cursor-pointer" }}
          columnHeaderHeight={40}
          columnVisibilityModel={{
            plate: fitsDesktop,
            firstSeen: fitsDesktop,
            visits: fitsDesktop,
            make: !isInterestList && lprMmct && fitsDesktop,
            model: !isInterestList && lprMmct && fitsDesktop,
            colorId: !isInterestList && lprMmct && fitsDesktop,
            typeId: !isInterestList && lprMmct && fitsDesktop,
            video: !isInterestList && fitsDesktop,
            note: isInterestList && fitsDesktop,
          }}
          loading={loading}
          getRowId={({ plate }) => plate}
          rowHeight={fitsDesktop ? 58 : 140}
          initialState={{
            sorting: { sortModel: [{ field: "lastSeen", sort: "desc" }] },
          }}
          columns={[
            {
              field: "plate",
              headerName: "License Plate Number",
              cellClassName: "pl-0",
              width: 262,

              renderCell: ({
                row: { firstSeen, lastSeen, plate, cameras },
              }) => {
                return (
                  <LicensePlateStill
                    className="cursor-pointer"
                    plate={plate}
                    cameraId={cameras[0]?.id}
                    start={firstSeen}
                  />
                );
              },
            },
            {
              field: "lastSeen",
              headerName: "Last Seen",
              flex: 2,
              renderCell: ({ row }) => {
                const { lastSeen, cameras } = row;
                if (fitsDesktop) {
                  return (
                    <div>
                      {lastSeen
                        ? dateTimeFormat.format(
                            utcToZonedTime(
                              lastSeen,
                              cameras[0]?.location?.timezone || ""
                            )
                          )
                        : ""}
                    </div>
                  );
                }

                return (
                  <LicensePlateMobileDetailsCard selectedResult={row as any}>
                    <Button
                      className="p-0 pl-1 justify-start text-sm leading-4"
                      color="primary"
                      size="large"
                      startIcon={<PlayCircleOutlineIcon />}
                      onClick={() => {
                        setOpen(true);
                        setSelected(row as any);
                      }}
                    >
                      View Visits
                    </Button>
                  </LicensePlateMobileDetailsCard>
                );
              },
              renderHeader: () => {
                return fitsDesktop ? (
                  "Last Seen"
                ) : (
                  <span>
                    Sort By: <strong className="text-primary">Last Seen</strong>
                  </span>
                );
              },
            },
            {
              field: "firstSeen",
              flex: 2,
              headerName: "First Seen",
              renderCell: ({ row: { cameras, firstSeen } }) =>
                firstSeen
                  ? dateTimeFormat.format(
                      utcToZonedTime(
                        firstSeen,
                        cameras[0]?.location?.timezone || ""
                      )
                    )
                  : "",
            },
            {
              field: "visits",
              headerName: "Visits",
              valueGetter: ({ row: { plate, visits } }) =>
                resolvedVisits[plate] ?? visits ?? 0,
            },
            ...(lprMmct
              ? [
                  {
                    field: "make",
                    headerName: "Make",
                  },
                  {
                    field: "model",
                    headerName: "Model",
                  },
                  {
                    field: "colorId",
                    headerName: "Color",
                    valueGetter: ({ value }: any) => Color[value || 0],
                  },
                  {
                    field: "typeId",
                    headerName: "Type",
                    valueGetter: ({ value }: any) => VehicleType[value || 0],
                  },
                ]
              : []),
            {
              field: "video",
              sortable: false,
              flex: 2,
              headerName: "Video",
              renderCell: ({ row }) => (
                <Button
                  className="p-0 pl-1 justify-start text-sm leading-4"
                  color="primary"
                  size="large"
                  startIcon={<PlayCircleOutlineIcon />}
                  onClick={() => {
                    setOpen(true);
                    setSelected(row as any);
                  }}
                >
                  View Visits
                </Button>
              ),
            },
            {
              field: "note",
              headerName: "Note",
              renderCell: ({ row: { plate, visits } }) => {
                const plates = plate.toLowerCase()?.split("/") || [];
                const list = lists[plates[0]] || lists[plates[1]];

                if (list) {
                  const result = [] as string[];

                  list.forEach((l, idx) => {
                    result.push(`[${l.listName}]`);
                    result.push(`${l.notes}`);

                    if (list[idx + 1]) {
                      result.push("");
                    }
                  });

                  return (
                    <Tooltip
                      title={
                        <Typography
                          variant="body2"
                          className="whitespace-pre-line"
                        >
                          {result.join("\n")}
                        </Typography>
                      }
                    >
                      <StickyNote2OutlinedIcon />
                    </Tooltip>
                  );
                }
                return <div className="w-6 h-[1px] bg-[#353D48]" />;
              },
            },
          ]}
          rows={data || []}
          onRowClick={({ row }) => {
            setOpen(true);
            setSelected(row as any);
          }}
        ></DataGrid>
      </PlayerMachineProvider>
    </div>
  );
}
