import {
  Button,
  ButtonGroup,
  ButtonProps,
  Popper,
  Tooltip,
} from "@mui/material";
import clsx from "clsx";
import { useFlags } from "launchdarkly-react-client-sdk";
import { isEqual, omit } from "lodash/fp";
import { forwardRef, useMemo, useRef, useState } from "react";
import { makeStyles, withStyles } from "tss-react/mui";

import { pluralize } from "@/util/pluralize";

import { configs, WallConfig } from "@/pages/VideoWall/constants";

import {
  pickWallConfiguration,
  WallSize,
  wallSizes,
} from "@/components/VideoWall/VideoWallGrid";
import { GridContainer } from "@/components/shared/Grid";

const useStyles = makeStyles()((theme) => ({
  root: {
    minHeight: 32,
  },
  popperButton: {
    minHeight: 32,
    borderRadius: 0,
    margin: 0,
  },
  configButtonBase: {
    borderRadius: 0,
  },
}));

interface VideoWallConfigSelectorProps {
  config: WallConfig;
  setConfig: (config: WallConfig) => void;
  currentCameraCount: number;
  emptySlotCount: number;
}

export function VideoWallConfigSelector(props: VideoWallConfigSelectorProps) {
  const { largeWalls, videoWall32 } = useFlags();

  const allowedWallSizes = useMemo(() => {
    if (videoWall32) {
      return wallSizes;
    }

    if (largeWalls) {
      return wallSizes.filter((s) => s <= 16);
    }

    return wallSizes.filter((s) => s <= 9);
  }, [largeWalls, videoWall32]);

  const { classes } = useStyles();
  return (
    <ButtonGroup variant="outlined" color="primary" className={classes.root}>
      {allowedWallSizes.map((count, index) => {
        return (
          <LayoutSizeGroup
            className={clsx(
              index === 0 && "rounded-l",
              index === wallSizes.length - 1 && "rounded-r",
              classes.configButtonBase,
              "shadow-none hover:shadow-none"
            )}
            key={index}
            count={count}
            {...props}
          />
        );
      })}
    </ButtonGroup>
  );
}

function LayoutSizeGroup({
  count,
  currentCameraCount,
  emptySlotCount,
  config,
  setConfig,
  ...buttonProps
}: {
  count: WallSize;
} & VideoWallConfigSelectorProps &
  ButtonProps) {
  const { classes } = useStyles();
  const camOverflow = currentCameraCount - count;
  const disabled = Boolean(camOverflow && camOverflow > 0);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const matchingConfigs = configs.filter((c) => c.sizes.length === count);
  const ButtonComponent = disabled ? ButtonWithTooltip : Button;
  const groupIsSelected = matchingConfigs.some(
    isEqual(omit("__typename", config))
  );

  const [lastSelectedConfig, setLastSelectedConfig] = useState(
    groupIsSelected ? config : matchingConfigs[0]
  );

  return (
    <>
      <ButtonComponent
        className="rounded-none"
        {...buttonProps}
        ref={buttonRef}
        tooltipText={
          disabled
            ? `Please remove ${pluralize(
                {
                  1: "1 camera",
                  multi: `${camOverflow} cameras`,
                },
                camOverflow ?? 0
              )}`
            : `${count} camera grid`
        }
        variant={
          count === currentCameraCount + emptySlotCount
            ? "contained"
            : "outlined"
        }
        disabled={disabled}
        key={count}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        onClick={() => !groupIsSelected && setConfig(lastSelectedConfig)}
      >
        <LayoutIcon
          count={count}
          config={groupIsSelected ? config : lastSelectedConfig}
        />

        {!disabled && (
          <Popper
            open={open}
            anchorEl={buttonRef.current}
            disablePortal
            style={{
              zIndex: 1,
            }}
          >
            <ButtonGroup
              variant="outlined"
              color="primary"
              orientation="vertical"
              style={{
                background: "white",
              }}
            >
              {matchingConfigs.map((config, index) => (
                <Button
                  key={index}
                  className={clsx(
                    classes.popperButton,
                    "ml-0 border-x-primary/50 hover:border-x-primary",
                    index === 0 && "rounded-t",
                    index === matchingConfigs.length - 1 && "rounded-b"
                  )}
                  onClick={(e) => {
                    e.stopPropagation();
                    setConfig(config);
                    setOpen(false);
                    setLastSelectedConfig(config);
                  }}
                >
                  <LayoutIcon count={count} config={config} />
                </Button>
              ))}
            </ButtonGroup>
          </Popper>
        )}
      </ButtonComponent>
    </>
  );
}

const useLayoutIconStyles = makeStyles()((theme) => ({
  grid: {
    gap: "2px",
  },
  tile: {
    background: "currentColor",
  },
}));

function LayoutIcon({
  count,
  config: { columns, rows, sizes },
}: {
  count: number;
  config: WallConfig;
}) {
  const { classes } = useLayoutIconStyles();
  const configuration = pickWallConfiguration(count);
  if (!configuration) return <>?</>;

  return (
    <GridContainer
      className={classes.grid}
      style={{
        gridTemplateColumns: `repeat(${columns}, 4px)`,
        gridTemplateRows: `repeat(${rows}, 4px)`,
      }}
    >
      {Array.from({ length: count }, (_, i) => (
        <div
          key={i}
          className={classes.tile}
          style={{
            gridColumn: `span ${sizes[i]}`,
            gridRow: `span ${sizes[i]}`,
          }}
        />
      ))}
    </GridContainer>
  );
}

const TooltipButton = withStyles(Button, {
  root: {
    "&.Mui-disabled": {
      pointerEvents: "auto",

      "&:hover": {
        borderColor: "rgba(0, 0, 0, .12)",
      },
    },
  },
});

type ButtonWithTooltipProps = ButtonProps & { tooltipText: string };
const ButtonWithTooltip = forwardRef<HTMLButtonElement, ButtonWithTooltipProps>(
  function ButtonWithTooltip(
    { tooltipText, onClick, ...other }: ButtonWithTooltipProps,
    ref
  ) {
    const { disabled } = other;
    const adjustedButtonProps = {
      component: disabled ? "div" : undefined,
      onClick: disabled ? undefined : onClick,
    };
    return (
      <Tooltip title={tooltipText}>
        <TooltipButton {...other} {...adjustedButtonProps} ref={ref} />
      </Tooltip>
    );
  }
);
