import { createGlobalState } from "react-hooks-global-state";

const initialState = { isFullscreen: false };
export const { useGlobalState: useGlobalFullscreenState } = createGlobalState(
  initialState
);

export const isDocumentInFullScreenMode = () => {
  return document.fullscreenElement !== null;
};
