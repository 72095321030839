import { utcToZonedTime } from "date-fns-tz";
import { format } from "date-fns/fp";

import { timeFormat } from "@/util/date";
import { filterNullish } from "@/util/filterFalsy";

import { intelligentFiltersConfig } from "@/pages/Search/intelligence/intelligence";

import { Camera } from "@/generated-models";

import { getDirection } from "../../Count/CountEntitiesDatagrid";
import { Range } from "../../IntelligenceDashboardView";
import { CountDirection } from "../../constants";
import { IdleExportContent } from "./idle";

export function countCsvFormatter(
  data: NonNullable<IdleExportContent>,
  cameras: Pick<Camera, "id">[],
  timezone: string,
  range?: string,
  subtype?: string | null
) {
  const { leftCount, rightCount, bucketCounts } = data.metrics.results!;

  // Filter out the counts we don't need
  const direction = subtype as CountDirection;
  const entities = data.metrics.results!.entities.filter((e) => {
    if (direction === CountDirection.In) return e.crossedLeft;
    if (direction === CountDirection.Out) return e.crossedRight;
    return true;
  });

  const first = entities.sort((a, b) => a.firstMs - b.firstMs)[0];
  const last = entities.sort((a, b) => b.lastMs - a.lastMs)[0];
  const leftBuckets = [...bucketCounts].sort(
    (a, b) => (b.leftCount ?? 0) - (a.leftCount ?? 0)
  );
  const mostIn = leftBuckets[0];
  const fewestIn = leftBuckets[leftBuckets.length - 1];
  const rightBuckets = [...bucketCounts].sort(
    (a, b) => (b.rightCount ?? 0) - (a.rightCount ?? 0)
  );
  const mostOut = rightBuckets[0];
  const fewestOut = rightBuckets[rightBuckets.length - 1];

  return [
    direction !== CountDirection.Out ? `In Count,${leftCount}` : null,
    direction !== CountDirection.In ? `Out Count,${rightCount}` : null,
    range === Range.Day
      ? `First,${
          first
            ? timeFormat.format(utcToZonedTime(first.firstMs, timezone))
            : "N/A"
        }`
      : direction !== CountDirection.Out
      ? `Most In,${
          mostIn
            ? `${mostIn.leftCount ?? 0} on ${format(
                "M/d",
                utcToZonedTime(mostIn.bucketMs, timezone)
              )}`
            : "N/A"
        }`
      : null,
    range === Range.Day
      ? `Last,${
          last
            ? timeFormat.format(utcToZonedTime(last.lastMs, timezone))
            : "N/A"
        }`
      : direction !== CountDirection.In
      ? `Most Out,${
          mostOut
            ? `${mostOut.rightCount ?? 0} on ${format(
                "M/d",
                utcToZonedTime(mostOut.bucketMs, timezone)
              )}`
            : "N/A"
        }`
      : null,
    direction !== CountDirection.InAndOut
      ? direction === CountDirection.In
        ? `Fewest In,${
            fewestIn
              ? `${fewestIn.leftCount ?? 0} on ${format(
                  "M/d",
                  utcToZonedTime(fewestIn.bucketMs, timezone)
                )}`
              : "N/A"
          }`
        : `Fewest Out,${
            fewestOut
              ? `${fewestOut.rightCount ?? 0} on ${format(
                  "M/d",
                  utcToZonedTime(fewestOut.bucketMs, timezone)
                )}`
              : "N/A"
          }`
      : null,
    "",
    "Queue,Date,First Seen,Last Seen,Direction,Video",
    ...entities
      .sort((a, b) => b.queueNum - a.queueNum)
      .map(({ queueNum, firstMs, lastMs, crossedLeft, crossedRight }) =>
        [
          queueNum,
          format(`"MMM d, y"`, utcToZonedTime(firstMs, timezone)),
          `"${timeFormat.format(utcToZonedTime(firstMs, timezone))}"`,
          `"${timeFormat.format(utcToZonedTime(lastMs, timezone))}"`,
          getDirection(crossedLeft, crossedRight),
          `${window.location.origin}/search?cams=${
            cameras[0].id
          }&vod=${new Date(firstMs).toISOString()}|${new Date(
            lastMs
          ).toISOString()}&subjects=${intelligentFiltersConfig.vehicle.objectIds.join(
            "_"
          )}`,
        ].join(",")
      ),
  ].filter(filterNullish);
}
