import ComputerIcon from "@mui/icons-material/Computer";
import SettingsInputHdmiIcon from "@mui/icons-material/SettingsInputHdmi";
import {
  Box,
  Button,
  Grid,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { makeStyles } from "tss-react/mui";

import { SpotcastLight } from "@/icons/Spotcast";

import { auth0Config } from "@/auth0";
import { CERULEAN_BLUE } from "@/layout/theme";

const CODE_REGEX = /^[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}$/;
const useStyles = makeStyles()((theme) => ({
  directionGridItem: {
    display: "flex",
    flexDirection: "row",
  },
  avatar: {
    marginRight: theme.spacing(1.5),
  },
  divider: {
    width: 50,
    height: 1,
    background: "rgba(0,0,0,0.2)",
    alignSelf: "center",
  },
  stepLabel: {
    fontSize: 16,
  },
}));
interface KioskHelpProps {
  onSubmitCode?: (code: string) => void;
}

export default function KioskCodeInput({
  onSubmitCode = () => {},
}: KioskHelpProps) {
  const { classes } = useStyles();
  const theme = useTheme();
  const [userCode, setUserCode] = useState("");
  return (
    <Box
      style={{ width: 400 }}
      display="flex"
      flexDirection="column"
      alignItems="center"
      mt={1}
    >
      <SpotcastLight width="120" />
      <Typography
        style={{
          textAlign: "center",
          margin: theme.spacing(1.5, 2, 0),
          fontWeight: 500,
          fontSize: 16,
          opacity: 0.8,
        }}
        variant="h4"
      >
        Spotcast turns any screen into a video wall
      </Typography>
      <Box m={1} />
      <Stepper
        orientation="vertical"
        style={{ padding: theme.spacing(0, 1, 0) }}
      >
        <Step active>
          <StepLabel classes={{ label: classes.stepLabel }}>
            Set up device
          </StepLabel>
          <StepContent>
            <Grid style={{ margin: theme.spacing(1, 0) }} container spacing={1}>
              <Grid className={classes.directionGridItem} item xs={12}>
                <SettingsInputHdmiIcon className={classes.avatar} />
                <Typography variant={"body1"} style={{ fontSize: 15 }}>
                  Connect a HDMI/DisplayPort cable to the back of your Spot
                  appliance.
                </Typography>
              </Grid>

              <Grid
                className={classes.directionGridItem}
                style={{ justifyContent: "center" }}
                item
                xs={12}
              >
                <Box className={classes.divider} />
                <Typography
                  variant="h6"
                  style={{
                    opacity: 0.6,
                    margin: theme.spacing(0, 1),
                  }}
                >
                  OR
                </Typography>
                <Box className={classes.divider} />
              </Grid>
              <Grid className={classes.directionGridItem} item xs={12}>
                <ComputerIcon className={classes.avatar} />
                <Typography variant={"body1"} style={{ fontSize: 15 }}>
                  Access{" "}
                  <code
                    style={{
                      color: CERULEAN_BLUE,
                    }}
                  >
                    dashboard.spot.ai/kiosk
                  </code>{" "}
                  on any web browser. Smart TVs, tablets, office monitors, or
                  old laptops make great kiosks!
                </Typography>
              </Grid>
            </Grid>
          </StepContent>
        </Step>
        <Step active>
          <StepLabel classes={{ label: classes.stepLabel }}>Register</StepLabel>

          <StepContent>
            <Typography variant={"body1"} style={{ fontSize: 15 }}>
              Enter the code displayed on the kiosk:
            </Typography>
            <Box
              display="flex"
              flexDirection="row"
              width="100%"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <TextField
                autoFocus
                margin="dense"
                label="Kiosk Code"
                value={userCode}
                onChange={(e) => {
                  const val = e.currentTarget.value;
                  if (val.includes("-") || val.length < 5) {
                    setUserCode(val);
                    return;
                  }
                  setUserCode(val.slice(0, 4) + "-" + val.slice(4));
                }}
                style={{ flexGrow: 1 }}
                inputProps={{ style: { textTransform: "uppercase" } }}
              />
              <Box m={1} />
              <Button
                style={{ marginBottom: 4 }}
                variant="contained"
                onClick={() => {
                  window.open(
                    `https://${auth0Config.domain}/activate?user_code=${userCode}`,
                    "Scanned Device Configuration",
                    "directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=400,height=500"
                  );
                  onSubmitCode(userCode);
                }}
                color="primary"
                disabled={!CODE_REGEX.test(userCode)}
              >
                Add Kiosk
              </Button>
            </Box>
          </StepContent>
        </Step>
      </Stepper>
    </Box>
  );
}
