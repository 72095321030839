import { SvgIcon, SvgIconProps } from "@mui/material";

export function createIconComponent(
  Icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >,
  defaultProps?: SvgIconProps
) {
  return function (props: SvgIconProps) {
    return <SvgIcon {...defaultProps} {...props} component={Icon} />;
  };
}
