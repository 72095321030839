import ShareIcon from "@mui/icons-material/Share";
import { Box } from "@mui/material";
import React from "react";

import { ShareTrayItem } from "@/components/Player/ShareTrayItem";
import { SharingLinkDialog } from "@/components/Player/SharingLinkDialog";
import { LiveStatusIndicator } from "@/components/StatusIndicators";
import { useDialog } from "@/components/shared/Dialog";

import { CameraStatus } from "@/generated-models";

const MARKETING_HOOK = "https://hooks.zapier.com/hooks/catch/10426869/beu09bi";

export const ShareMarketingButton = React.memo(function ShareCameraButton({
  title,
}: {
  title?: string;
}) {
  const dialogProps = useDialog();

  async function openDialog() {
    await dialogProps.open();
  }

  async function sendHook(data: { email?: string; phoneNumber?: string }) {
    await fetch(MARKETING_HOOK, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: JSON.stringify({ ...data, submitted: new Date().toISOString() }),
    });
  }

  return (
    <>
      <ShareTrayItem
        className="text-primary"
        onClick={openDialog}
        aria-label="share"
        icon={<ShareIcon className="text-inherit" />}
        label="Share"
      />
      {dialogProps.opened && (
        <SharingLinkDialog
          {...dialogProps}
          sharedLink={{
            expiry: "",
            description: "",
          }}
          submit={() => {}}
          submitting={false}
          link={null}
          title={
            <>
              <LiveStatusIndicator status={CameraStatus.Online} />
              <Box width="4px" />
              {title ?? "Share Live Camera"}
            </>
          }
          hideCopyButton
          expirationLabel="Link Expiration"
          linkLabel="Camera Link"
          sendEmail={async (email) => {
            await sendHook({ email });
          }}
          sendText={async (phoneNumber) => {
            await sendHook({ phoneNumber });
          }}
        />
      )}
    </>
  );
});
