import { gql } from "@apollo/client";
import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  Link,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Dispatch, useState } from "react";
import { makeStyles } from "tss-react/mui";

import { useSettingsRoutes } from "@/pages/Settings/SettingsRoutes";

import { useMe } from "@/components/Auth";
import { Loading } from "@/components/Loading";
import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";
import { Avatar } from "@/components/User/Avatar";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  GroupDetailsDocument,
  GroupDetailsQuery,
  Role,
  useAddUsersToTagMutation,
  useGetUsersListQuery,
  User,
  useRemoveUsersFromTagMutation,
} from "@/generated-models";
import { usePermissions } from "@/hooks/usePermissions";
import { theme } from "@/layout/theme";

const useStyles = makeStyles()((theme) => ({
  collaborator: {
    display: "flex",
    alignItems: "center",
    padding: "5px 6px",
    margin: `0 ${theme.spacing(1)} ${theme.spacing(1)} 0`,
  },
  collaboratorAvatar: {
    fontSize: 11,
    width: 24,
    height: 24,
  },
  autoCompleteContainer: {
    background: "#FFFFFF",
    border: "4px solid #E1E1E1",
    borderRadius: "9px",
    padding: "0px",
  },
  autoComplete: {
    background: "#FFFFFF",
    border: "1px solid #64B6FB",
    boxSizing: "border-box",
    borderRadius: "6px",
  },
  infoText: {
    color: "#353D48",
  },
  expandButton: {
    transition: "0.25s transform",
    backgroundColor: "#fff",
    color: "#353d48",
    border: "solid 1px #c8c8c8",
    marginLeft: 12,
    "& svg": { fontSize: 18 },
  },
  addUserButton: {
    display: "flex",
    marginLeft: theme.spacing(1),
  },
  userTypeGrid: {
    marginTop: theme.spacing(2),
    flexDirection: "column",
  },
  userTypeCount: {
    marginRight: theme.spacing(1),
    fontWeight: 500,
  },
  userTypeHeading: {
    fontSize: "16px",
    fontWeight: 700,
  },
}));

export function TagUsers({ group }: { group: GroupDetailsQuery["group"] }) {
  const me = useMe();
  const hasPermission = usePermissions();
  const { classes } = useStyles();
  const feedback = useFeedback();
  const { data: orgUsers } = useGetUsersListQuery(refetchOnMountPolicy);
  const [addUsersToTag, { loading: addUserLoading }] = useAddUsersToTagMutation(
    {
      refetchQueries: [
        { query: GroupDetailsDocument, variables: { id: group.id } },
      ],
      awaitRefetchQueries: true,
      onCompleted: () => {
        feedback.pushSnackbar(
          `Users successfully added to ${group.name}`,
          FeedbackType.Success
        );
      },
      onError: () =>
        feedback.pushSnackbar(
          "Unable to add users to the tag. Try again later",
          FeedbackType.Error
        ),
    }
  );
  const [removeUsersFromTag] = useRemoveUsersFromTagMutation({
    refetchQueries: [
      { query: GroupDetailsDocument, variables: { id: group.id } },
    ],
    awaitRefetchQueries: true,
    onCompleted: () => {
      feedback.pushSnackbar(
        `User removed from ${group.name}`,
        FeedbackType.Success
      );
    },
    onError: () =>
      feedback.pushSnackbar(
        "Unable to remove user from the group. Try again later",
        FeedbackType.Error
      ),
  });
  const [selectedUsers, setSelectedUsers] = useState<TagUsersType>([]);
  const settingsRoutes = useSettingsRoutes();

  if (!orgUsers) {
    return (
      <div style={{ padding: "32px 0", margin: "0 auto" }}>
        <Loading>Loading Users...</Loading>
      </div>
    );
  }

  const filteredTagUsers =
    me && me.role >= Role.Spot
      ? group.users
      : group.users.filter((user) => user.role < Role.Spot);

  return (
    <>
      <Box px={3}>
        <Grid container direction="column">
          {hasPermission("users_manage") && (
            <>
              <Grid item>
                <Typography className={classes.infoText}>
                  Search and add existing users or{" "}
                  <strong>
                    <Link
                      target="_blank"
                      href={settingsRoutes.users}
                      underline="hover"
                    >
                      create a new user
                    </Link>
                  </strong>{" "}
                  to allow them access to this group.
                </Typography>
              </Grid>
              <Box m={1} />
              <Grid item container>
                <Grid
                  item
                  xs={12}
                  md={6}
                  className={classes.autoCompleteContainer}
                >
                  <TagUsersSelect
                    filteredUsers={group.users}
                    setSelectedUsers={setSelectedUsers}
                    selectedUsers={selectedUsers}
                    orgUsers={orgUsers.users}
                  />
                </Grid>
                <Grid
                  className={classes.addUserButton}
                  item
                  xs={12}
                  md={3}
                  alignItems="center"
                >
                  <Button
                    onClick={async () => {
                      await addUsersToTag({
                        variables: {
                          id: group.id,
                          value: { users: selectedUsers.map((u) => u.id) },
                        },
                      });

                      setSelectedUsers([]);
                    }}
                    variant="contained"
                    color="primary"
                    disabled={addUserLoading || selectedUsers.length === 0}
                  >
                    Add to group
                  </Button>
                </Grid>
              </Grid>
              <Box m={1} />
            </>
          )}
          <Grid item container className={classes.userTypeGrid}>
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item style={{ display: "inline-flex" }} className="my-2">
                <Typography className={classes.userTypeHeading}>
                  Users
                </Typography>
              </Grid>
              <Grid item className={classes.userTypeCount}>
                {filteredTagUsers.length} Users
              </Grid>
            </Grid>
            <Grid container>
              {filteredTagUsers.length > 0
                ? filteredTagUsers.map((u) => (
                    <Grid key={u.id} item xs={6} md={3}>
                      <Paper
                        className={classes.collaborator}
                        variant="outlined"
                      >
                        <Avatar
                          user={u}
                          className={classes.collaboratorAvatar}
                        />
                        <Box m={0.5} />
                        <Typography variant="body2">{u.name}</Typography>
                        {hasPermission("users_manage") && (
                          <>
                            <Box
                              m={0.5}
                              style={{
                                marginLeft: "auto",
                                marginRight: theme.spacing(0.5),
                              }}
                            />
                            <Tooltip title="Remove from group">
                              <IconButton
                                size="small"
                                onClick={async () => {
                                  await removeUsersFromTag({
                                    variables: {
                                      id: group.id,
                                      value: { users: [u.id] },
                                    },
                                  });
                                }}
                              >
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </>
                        )}
                      </Paper>
                    </Grid>
                  ))
                : "No users have been added to this group yet."}
            </Grid>
          </Grid>
          <Box m={1} />
        </Grid>
      </Box>
    </>
  );
}

type TagUsersType = Pick<User, "id" | "name" | "role">[];

// Auto select for tag users
export function TagUsersSelect({
  filteredUsers = [], // users that need to be filtered from the selection
  setSelectedUsers,
  selectedUsers,
  orgUsers,
}: {
  filteredUsers?: { id: number }[];
  setSelectedUsers: Dispatch<TagUsersType>;
  selectedUsers?: TagUsersType;
  orgUsers: TagUsersType;
}) {
  const { classes } = useStyles();
  const existingTagUsers = new Set(filteredUsers.map((x) => x.id));
  const autoCompleteOptions = orgUsers.filter(
    (x) => !existingTagUsers.has(x.id)
  );
  return (
    <Autocomplete
      className={classes.autoComplete}
      id="tags-users"
      multiple
      options={autoCompleteOptions}
      fullWidth
      getOptionLabel={(option) => option.name}
      loadingText={"Fetching user list"}
      value={selectedUsers}
      filterSelectedOptions
      clearOnEscape={true}
      onChange={(_, value) => {
        setSelectedUsers(value);
      }}
      isOptionEqualToValue={(o, v) => o === v}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder="Search users to add to this group"
        />
      )}
    />
  );
}

gql`
  mutation addUsersToTag($id: Int!, $value: AddTagUserInput!) {
    addUsersToTag(id: $id, value: $value) {
      id
    }
  }
`;

gql`
  mutation removeUsersFromTag($id: Int!, $value: RemoveTagUserInput!) {
    removeUsersFromTag(id: $id, value: $value)
  }
`;
