import { Typography } from "@mui/material";
import clsx from "clsx";
import { format, utcToZonedTime } from "date-fns-tz";
import { addMinutes } from "date-fns/fp";
import { CSSProperties, useMemo, useState } from "react";

import { bestMatch } from "@/util/bestMatch";
import { getRelativeMousePosition } from "@/util/mouseEvents";
import { useBreakpoints } from "@/util/useBreakpoints";

import { ScrubImages } from "@/components/Camera/ExpandingSnippetPreview";
import { useEmbeddedVodParam } from "@/components/Player/EmbeddedVodPlayer";

import {
  useCameraByIdQuery,
  useGetStillsSampleQuery,
} from "@/generated-models";

import { footageIsAvailable } from "./utils";

export function IntervalGraph({
  cameraId,
  intervals,
  graphStartMs,
  graphEndMs,
  timezone,
  variant = "regular",
  interactive = false,
  intervalBarStyle = {},
  hideLabels,
}: {
  cameraId?: number;
  intervals: { startMs: number; endMs: number }[];
  graphStartMs: number;
  graphEndMs: number;
  timezone: string;
  variant?: "small" | "regular" | "xs";
  interactive?: boolean;
  intervalBarStyle?: CSSProperties;
  hideLabels?: boolean;
}) {
  const fullTime = graphEndMs - graphStartMs;

  return (
    <div
      className={clsx(
        "flex flex-col items-stretch justify-center ",
        variant === "regular" ? "gap-4" : "gap-2"
      )}
    >
      <div
        className={clsx("w-full border border-gray-300 relative", {
          "h-3 rounded-sm border-0": variant === "xs",
          "h-12 p-1 rounded-md": variant === "small",
          "h-16 p-2 rounded-xl": variant === "regular",
        })}
      >
        <div
          className={clsx(
            "bg-[#E6E6E6] h-full w-full relative overflow-hidden",
            {
              rounded: variant === "xs",
              "rounded-sm": variant !== "xs",
            }
          )}
        >
          {intervals.map(({ startMs, endMs }, idx) => {
            return (
              <div
                key={`${idx}`}
                className={clsx("absolute top-0 bottom-0", {
                  "rounded-sm": variant === "xs",
                })}
                style={{
                  ...intervalBarStyle,
                  left: `${(
                    ((startMs - graphStartMs) / fullTime) *
                    100
                  ).toFixed(2)}%`,
                  right: `${(((graphEndMs - endMs) / fullTime) * 100).toFixed(
                    2
                  )}%`,
                }}
              />
            );
          })}
        </div>
        {interactive && cameraId && (
          <div
            className={clsx(
              "absolute top-0 bottom-0",
              variant === "xs" && "z-10",
              variant === "regular" ? "left-2 right-2" : "left-1 right-1"
            )}
          >
            <HoverThumbnails
              cameraId={cameraId}
              startDate={new Date(graphStartMs)}
              endDate={new Date(graphEndMs)}
            />
          </div>
        )}
      </div>
      <div
        className={clsx("flex justify-between", {
          hidden: hideLabels,
        })}
      >
        <IntervalTicks
          variant={variant}
          timezone={timezone}
          graphStartMs={graphStartMs}
          graphEndMs={graphEndMs}
        />
      </div>
    </div>
  );
}

export interface IntervalTickProps {
  graphStartMs: number;
  graphEndMs: number;
  variant?: "small" | "regular" | "xs";
  timezone: string;
}

export function IntervalTicks({
  variant,
  timezone,
  graphStartMs,
  graphEndMs,
}: IntervalTickProps) {
  const fullTime = graphEndMs - graphStartMs;
  const ticks = Math.max(
    2, // Ensure at least 2 ticks are rendered.
    Math.round(fullTime / (60 * 60 * 1000) / (variant === "regular" ? 1 : 4))
  );

  return (
    <>
      {Array.apply(null, Array(ticks)).map((_, i) => (
        <Typography
          className={clsx(
            "text-gray-500",
            variant === "regular" ? "text-sm" : "text-xs"
          )}
          key={`${i}`}
        >
          {format(
            utcToZonedTime(
              roundToNearestHour(
                new Date(graphStartMs + (fullTime / (ticks - 1)) * i)
              ),
              timezone
            ),
            "haaa",
            { timeZone: timezone }
          )}
        </Typography>
      ))}
    </>
  );
}

function roundToNearestHour(date: Date) {
  date.setMinutes(date.getMinutes() + 30);
  date.setMinutes(0, 0, 0);
  return date;
}

function HoverThumbnails({
  cameraId,
  startDate,
  endDate,
}: {
  cameraId: number;
  startDate: Date;
  endDate: Date;
}) {
  const { fitsDesktop } = useBreakpoints();
  const [hoverTime, setHoverTime] = useState<number | null>(null);
  const { vod, setVod } = useEmbeddedVodParam();
  const { data: camera } = useCameraByIdQuery({
    variables: { cameraId },
  });
  const { data: stillsSampleData } = useGetStillsSampleQuery({
    variables: {
      cameraId,
      startTime: startDate.toISOString(),
      endTime: endDate.toISOString(),
      sampleCount: 100,
    },
  });

  const stills = useMemo(() => {
    const startTime = startDate.getTime();
    return stillsSampleData?.stillsSample.map((s) => ({
      timestamp: (new Date(s.timestamp).getTime() - startTime) / 1000,
      src: s.src,
    }));
  }, [stillsSampleData, startDate]);

  const { duration, vodStart, vodEnd } = useMemo(() => {
    const startTime = startDate.getTime();
    const durationMs = endDate.getTime() - startTime;
    const vodStart =
      vod && ((new Date(vod.start).getTime() - startTime) / durationMs) * 100;
    const vodEnd =
      vod && ((new Date(vod.end).getTime() - startTime) / durationMs) * 100;
    return { duration: durationMs / 1000, vodStart, vodEnd };
  }, [startDate, endDate, vod]);

  const bestStillMatch =
    stills && typeof hoverTime === "number"
      ? bestMatch(
          stills,
          (a, b) =>
            Math.abs(a.timestamp - hoverTime) -
            Math.abs(b.timestamp - hoverTime)
        )
      : undefined;

  return (
    <div
      className="h-full w-full cursor-pointer"
      onMouseMove={
        fitsDesktop
          ? (e) => {
              const mousePosition = getRelativeMousePosition(
                e,
                e.currentTarget as HTMLDivElement
              );

              setHoverTime(duration * mousePosition.x);
            }
          : undefined
      }
      onMouseLeave={fitsDesktop ? () => setHoverTime(null) : undefined}
      onPointerDown={(e) => {
        const mousePosition = getRelativeMousePosition(
          e,
          e.currentTarget as HTMLDivElement
        );
        const vodStart = new Date(
          startDate.getTime() + duration * mousePosition.x * 1000
        );

        if (!footageIsAvailable(vodStart.getTime(), camera?.camera)) {
          return;
        }
        setVod({
          start: vodStart.toISOString(),
          end: addMinutes(15, vodStart).toISOString(),
        });
      }}
    >
      {/* Active VOD selected */}
      {vodStart && vodEnd && (
        <div
          className="absolute h-full bg-[#18D710] bg-opacity-10 pointer-events-none"
          style={{
            left: `${vodStart}%`,
            right: `${Math.max(0, 100 - vodEnd)}%`,
          }}
        >
          <div className="h-full w-[3px] bg-[#18D710]" />
        </div>
      )}

      {/* On hover cursor and still */}
      {fitsDesktop && (
        <div
          className={clsx("absolute h-full pointer-events-none", {
            hidden: hoverTime == null,
          })}
          style={{ left: ((hoverTime ?? 0) / duration) * 100 + "%" }}
        >
          <div className="h-full w-0.5 bg-[#BDBDBD]" />
          {stills && (
            <div className="absolute transform -translate-x-1/2 bottom-full w-56">
              <ScrubImages
                stills={stills}
                hoveringStill={bestStillMatch}
                imgClassName="w-full rounded border border-white border-solid"
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
