import {
  Button,
  CircularProgress,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BooleanParam, useQueryParam } from "use-query-params";

import { useBreakpoints } from "@/util/useBreakpoints";

import { FeedbackType, useFeedback } from "@/components/SnackbarProvider";

import {
  IntegrationVendor,
  IntegrationVendorKey,
  useAddIntegrationMutation,
  useIntegrationMetadataQuery,
} from "@/generated-models";
import { usePrefixOrgSlug } from "@/hooks/useOrgRouteBase";

import { IntegrationBrivoVendorForm } from "../Core/IntegrationBrivoVendorForm";
import { IntegrationIntercomVendorForm } from "../Core/IntegrationIntercomVendorForm";
import { IntegrationsAvigilonVendorForm } from "../Core/IntegrationsAvigilonVendorForm";
import IntegrationsDeviceDatagrid from "../Core/IntegrationsDeviceDatagrid";
import { IntegrationsHaloVendorForm } from "../Core/IntegrationsHaloVendorForm";
import { IntegrationsLinkCamerasDialogSuccessContent } from "../Core/IntegrationsLinkCamerasDialog";
import { IntegrationsSpotAIVendorForm } from "../Core/IntegrationsSpotAIVendorForm";
import { IntegrationsSpotAIVendorInputsForm } from "../Core/IntegrationsSpotAIVendorInputsForm";
import { IntegrationsVendorForm } from "../Core/IntegrationsVendorForm";
import { useCurrentIntegrationId, useIntegrationSetupState } from "../hooks";
import IntegrationCreateFormNameStep from "./IntegrationCreateFormSteps.tsx/IntegrationCreateFormNameStep";

interface IntegrationsCreateFormProps {
  vendor: IntegrationVendor;
}

export default function IntegrationsCreateForm({
  vendor,
}: IntegrationsCreateFormProps) {
  const navigate = useNavigate();
  const { fitsTablet, fitsDesktop } = useBreakpoints();
  const { pushSnackbar } = useFeedback();
  const { typeId, vendorKey } = useParams();
  const prefixOrgSlug = usePrefixOrgSlug();
  const [discoveredDevices, setDiscoveredDevices] = useQueryParam(
    "discovered-devices",
    BooleanParam
  );
  const [integrationName, setIntegrationName] = useState("");
  const [step, setStep] = useState(0);
  const [connected, setConnected] = useState(false);
  const [finished] = useQueryParam("finished", BooleanParam);

  const { key, setupSchema } = vendor;
  const integrationId = useCurrentIntegrationId();
  const { data, loading: initialStateLoading } = useIntegrationMetadataQuery({
    variables: { input: { id: integrationId } },
  });
  const { setupState, loading: setupStateLoading } = useIntegrationSetupState(
    integrationId,
    !connected
  );

  const [
    addIntegration,
    { loading: isAddingIntegration },
  ] = useAddIntegrationMutation({
    onCompleted: (result) => {
      navigate(
        prefixOrgSlug(
          `/integrations/${typeId}/${vendorKey}/new/${result.addIntegration.id}`
        )
      );
    },
    onError: () =>
      pushSnackbar(
        "Failed to create the integration, please try again",
        FeedbackType.Error
      ),
  });

  const loading =
    isAddingIntegration || setupStateLoading || initialStateLoading;

  const isIOBoard = vendor.key === IntegrationVendorKey.Spot;
  const steps = [
    {
      label: isIOBoard ? "Name I/O Board" : "Integration Name",
      subtitle: isIOBoard ? (
        <span>Create a name for your I/O board</span>
      ) : (
        <span>Give this integration a name</span>
      ),
    },
    {
      label: getMainStepLabel(vendor),
      subtitle: getSubtitle(vendor),
    },
  ];

  if (vendor.key !== IntegrationVendorKey.SpotIntercom) {
    steps.push({
      label: getCameraStepLabel(vendor),
      subtitle: isIOBoard ? (
        <span>
          Name your input, and define the On and Off state event names. Next, if
          needed, define the I/O uptime. Finally, link the relevant cameras to
          the input. Repeat this process up to 6 times.
        </span>
      ) : (
        <span>Select a device from the list below and link cameras.</span>
      ),
    });
  }

  if (discoveredDevices) {
    return <IntegrationsDeviceDatagrid mode="create" />;
  }

  const integrationProps = {
    id: integrationId,
    schema: setupSchema,
    setupState: setupState,
    initialState: data?.integration?.initialState,
    onConnectionResult: (result: boolean) => {
      setConnected(result);
    },
    editMode: false,
    callback: () => setStep((step) => step + 1),
  };

  function formSwitcher() {
    switch (vendor.key) {
      case IntegrationVendorKey.Brivo:
        return (
          <IntegrationBrivoVendorForm
            callback={() => setStep((step) => step + 1)}
            authorizationUrl={vendor.authInfo.authorizationUrl ?? ""}
          />
        );

      case IntegrationVendorKey.AvigilonAlta:
        return (
          <IntegrationsAvigilonVendorForm
            {...integrationProps}
            onBack={() => setStep((step) => step - 1)}
          />
        );

      case IntegrationVendorKey.Halo:
        return (
          <IntegrationsHaloVendorForm
            {...integrationProps}
            onBack={() => setStep((step) => step - 1)}
          />
        );

      case IntegrationVendorKey.Spot:
        return (
          <IntegrationsSpotAIVendorForm
            id={integrationId}
            schema={setupSchema}
            setupState={setupState}
            initialState={data?.integration?.initialState}
            onConnectionResult={(result) => {
              setConnected(result);
            }}
            callback={() => setStep((step) => step + 1)}
            onBack={() => setStep((step) => step - 1)}
          />
        );

      case IntegrationVendorKey.SpotIntercom:
        return (
          <IntegrationIntercomVendorForm
            id={integrationId}
            schema={setupSchema}
            setupState={setupState}
            initialState={data?.integration?.initialState}
            onConnectionResult={(result) => {
              setConnected(result);
            }}
            callback={() => setStep((step) => step + 1)}
            onBack={() => setStep((step) => step - 1)}
          />
        );

      default:
        return <IntegrationsVendorForm {...integrationProps} />;
    }
  }

  async function addNameStep() {
    await addIntegration({
      variables: {
        input: {
          typeId: vendor.type.id,
          vendorId: vendor.id,
          name: integrationName,
        },
      },
    });

    setStep((step) => step + 1);
  }

  return (
    <Stepper className="p-6 pl-0" activeStep={step} orientation="vertical">
      {steps.map(({ label, subtitle }, index) => {
        const firstStep = step === 0;
        const lastStep = step === steps.length - 1;
        return (
          <Step key={index}>
            <StepLabel
              StepIconProps={{
                classes: {
                  root: "text-[28px] ml-[-2px]",
                },
              }}
              classes={{
                label: "font-medium text-base leading-[18px] ml-4",
              }}
            >
              {label}
              {subtitle && index === step && (
                <Typography className="text-sm leading-[16.41px] text-[#666666] max-w-sm mt-1">
                  {subtitle}
                </Typography>
              )}
              {index === 0 && step !== 0 && (
                <Typography className="text-sm leading-[16.41px] text-[#666666] max-w-sm mt-1">
                  {integrationName}
                </Typography>
              )}
            </StepLabel>
            <StepContent>
              <div
                style={{
                  padding: fitsTablet ? "18px" : "12px 18px",
                }}
              >
                {index === 0 && (
                  <IntegrationCreateFormNameStep
                    onChange={setIntegrationName}
                    nextStep={async () => addNameStep()}
                    disabled={integrationName.length < 1 || loading}
                    integrationName={integrationName}
                  />
                )}
                {index === 1 && formSwitcher()}
                {index === 2 && (
                  <div>
                    {vendor.key === IntegrationVendorKey.Spot ? (
                      <IntegrationsSpotAIVendorInputsForm
                        id={integrationId}
                        schema={setupSchema}
                        setupState={setupState}
                        initialState={data?.integration?.initialState}
                        onConnectionResult={(result) => {
                          setConnected(result);
                        }}
                        callback={() => {
                          navigate(
                            prefixOrgSlug(
                              `/integrations/${typeId}/${vendorKey}/${integrationId}`
                            )
                          );
                        }}
                        onBack={() => setStep((step) => step - 1)}
                      />
                    ) : fitsDesktop ? (
                      <IntegrationsDeviceDatagrid mode="create" />
                    ) : (
                      <div>
                        <Button
                          className="-mt-2 mb-6 shadow-none font-normal px-8"
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            setDiscoveredDevices(true);
                          }}
                        >
                          Link Devices & Cameras
                        </Button>
                        {finished && (
                          <IntegrationsLinkCamerasDialogSuccessContent className="mb-4" />
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
              {(key !== IntegrationVendorKey.Spot || index === 0) && (
                <div className="flex items-center gap-1 ml-5">
                  {index > 1 && (
                    <Button
                      variant="contained"
                      className="shadow-none text-xs leading-[22px] font-normal px-8 text-[#959595]"
                      disabled={step === 0}
                      onClick={() => setStep((step) => step - 1)}
                    >
                      Back
                    </Button>
                  )}
                  {index === 1 &&
                  (key === IntegrationVendorKey.Halo ||
                    key === IntegrationVendorKey.Brivo ||
                    key === IntegrationVendorKey.AvigilonAlta) ? null : (
                    <Button
                      variant="contained"
                      color="primary"
                      className="shadow-none text-xs leading-[22px] font-normal px-8"
                      onClick={async () => {
                        if (firstStep) {
                          await addNameStep();
                        } else if (index === 1 && !lastStep) {
                          setStep((step) => step + 1);
                        } else if (lastStep) {
                          navigate(
                            prefixOrgSlug(
                              `/integrations/${typeId}/${vendorKey}/${integrationId}`
                            )
                          );
                        } else {
                          setStep((step) => step + 1);
                        }
                      }}
                    >
                      <>{lastStep ? "Finish" : "Next"}</>
                    </Button>
                  )}
                  {loading && <CircularProgress className="ml-3" size={16} />}
                </div>
              )}
            </StepContent>
          </Step>
        );
      })}
    </Stepper>
  );
}

const getSubtitle = (vendor: IntegrationVendor) => {
  switch (vendor.key) {
    case IntegrationVendorKey.AvigilonAlta: {
      return (
        <span>
          Use your Avigilon Alta credentials to connect. Make sure you have
          super admin access to Avigilon Alta integrations. By clicking Validate
          Connection, you give Spot AI permission to create webhooks in your
          Avigilon Alta account.
        </span>
      );
    }
    case IntegrationVendorKey.Brivo:
      return (
        <span>
          You will need to use your Admin ID to set up the Brivo integration.
          Read our{" "}
          <a
            href="https://spotai.zendesk.com/hc/en-us/articles/15625701488653-Integration-Setup-Guide-Brivo"
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary"
          >
            setup guide
          </a>{" "}
          to learn how to find your Brivo Admin ID.
        </span>
      );
    case IntegrationVendorKey.Spot:
      return <span>Enter the MAC Address for your I/O board.</span>;
    case IntegrationVendorKey.Halo:
      return (
        <span>
          Follow our{" "}
          <a
            href="https://spotai.zendesk.com/hc/en-us/articles/14937995853197-Integration-Setup-Guide-HALO-Sensors"
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary"
          >
            setup guide
          </a>{" "}
          to learn how to configure your HALO sensor and set up the integration.
        </span>
      );
    case IntegrationVendorKey.SpotIntercom:
      return (
        <span>
          Follow our setup guide{" "}
          {/* TODO: Add a link once we have it prepared. */}
          {/* <a
            href="https://spotai.zendesk.com/hc/en-us/articles/14937995853197-Integration-Setup-Guide-HALO-Sensors"
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary"
          >
            setup guide
          </a>{" "} */}
          to connect each of your intercom devices to Spot AI.
        </span>
      );

    // Follow this set up guide to connect each of your intercom devices to Spot AI.
    default: {
      return (
        <span>
          Use your {vendor.name} credentials to connect. Make sure you have
          super admin access to {vendor.name} integrations.
        </span>
      );
    }
  }
};

function getMainStepLabel(vendor: IntegrationVendor) {
  const { name, key } = vendor;

  switch (key) {
    case IntegrationVendorKey.Halo:
      return "Add HALO Sensors";
    case IntegrationVendorKey.Spot:
      return "Connect & Validate";
    case IntegrationVendorKey.SpotIntercom:
      return "Add Intercom Devices";
    default:
      return `Connect and validate to ${name}`;
  }
}

function getCameraStepLabel(vendor: IntegrationVendor) {
  const { key } = vendor;

  switch (key) {
    case IntegrationVendorKey.Halo:
      return "View Sensors & Link Cameras";
    case IntegrationVendorKey.Spot:
      return "Add Inputs & Link Cameras";
    case IntegrationVendorKey.SpotIntercom:
      return "Connect Spot AI’s cameras to your intercom system. This powerful integration allows you to pair your cameras to intercom devices and use your intercom while viewing live camera feeds.";
    default:
      return `View Discovered Devices & Link Cameras`;
  }
}
