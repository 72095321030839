import { Button, Chip, Paper, Typography } from "@mui/material";
import clsx from "clsx";
import gql from "graphql-tag";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Link, useNavigate } from "react-router-dom";

import {
  Role,
  useOrganizationsQuery,
  useSwitchOrganizationMutation,
} from "@/generated-models";

import { useLogout, useMe } from "../Auth";
import { Loading } from "../Loading";
import { OrgSelector } from "../OrgSelector/OrgSelector";

export function UserContextDisplay({ className }: { className?: string }) {
  const me = useMe();
  const logout = useLogout();
  const { customRoles } = useFlags();

  const canSwitchOrg = me?.canSwitchOrganization;

  return (
    <Paper
      className={clsx("flex flex-col gap-4 min-w-[220px] p-4", className)}
      elevation={3}
    >
      {me ? (
        <>
          {me.organization.logo && (
            <img
              src={me.organization.logo}
              className="max-h-[40px] max-w-[160px] self-center m-4"
              alt="logo"
            />
          )}
          <div>
            <Typography className="opacity-40 leading-none" variant="caption">
              Organization
            </Typography>
            {canSwitchOrg ? (
              <WiredOrgSelector currentOrg={me.organization} />
            ) : (
              <Typography
                variant="subtitle2"
                style={{ fontSize: 18, fontWeight: "bold" }}
              >
                {me.organization.name}
              </Typography>
            )}
          </div>
          <div>
            <Typography className="opacity-40 leading-none" variant="caption">
              User
            </Typography>
            <div className="flex items-center justify-between">
              <div className="flex-center gap-2">
                <Typography variant="subtitle2">{me.email}</Typography>
                <Button
                  component={Link}
                  color="primary"
                  className="font-normal px-2 min-w-0"
                  size="small"
                  to="/configure/user-management/users/me"
                >
                  Edit
                </Button>
              </div>
              <Button
                color="primary"
                className="font-normal"
                onClick={() => logout()}
                size="small"
              >
                Sign Out
              </Button>
            </div>
          </div>
          <div>
            <Typography className="opacity-40 leading-none" variant="caption">
              Role
            </Typography>
            <div className="flex items-center gap-2 flex-wrap justify-between">
              <Chip
                label={
                  customRoles && me.rolev2 ? me.rolev2.name : Role[me.role]
                }
                variant="outlined"
                size="small"
              />
              {me.role >= Role.Spot && (
                <Chip label={Role[me.role]} variant="outlined" size="small" />
              )}
            </div>
          </div>
        </>
      ) : (
        <Loading />
      )}
    </Paper>
  );
}

function WiredOrgSelector({
  currentOrg,
}: {
  currentOrg: { id: number; name: string; slug: string };
}) {
  const { data } = useOrganizationsQuery();
  const [switchOrganization] = useSwitchOrganizationMutation();
  const navigate = useNavigate();
  const organizations = data?.organizations ?? [currentOrg];
  const sortedOrganizations = organizations
    .slice(0)
    .sort((a, b) => (a.name < b.name ? -1 : 1));
  const options = sortedOrganizations.map((o) => ({
    value: o.id,
    label: o.name,
  }));
  return (
    <OrgSelector
      onChange={async (selectedOption) => {
        const organizationId = selectedOption.value;
        const org = organizations.find((o) => o.id === organizationId);
        if (!org) {
          console.error("Organization not found", organizationId);
          return;
        }
        await switchOrganization({ variables: { organizationId } });

        // Remove localStorage.lastLocation - will not exist in a different
        // organization.
        localStorage.removeItem("lastLocation");

        // Refresh the page while purposely omitting the query params
        // since they will likely be meaningless in the context of
        // the org that is being switched to (e.g. cameraId).
        // window.location.href =
        //   window.location.origin + window.location.pathname;
        navigate(`/o/${org.slug}`);
      }}
      value={options.find((o) => o.value === currentOrg.id)!}
      options={options}
    />
  );
}

gql`
  mutation switchOrganization($organizationId: Int!) {
    switchOrganization(organizationId: $organizationId) {
      id
      organization {
        id
        name
        slug
      }
    }
  }
`;
