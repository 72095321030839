import { IfNotFullscreen } from "@/components/FullScreen";

import { CopilotWelcomeModal } from "../CopilotWelcomeModal/CopilotWelcomeModal";
import { CopilotAddVisualLabelOverlay } from "./CopilotAddVisualLabelOverlay";
import { CopilotBaseOverlay } from "./CopilotBaseOverlay";
import { CopilotOverrideOverlay } from "./CopilotOverrideOverlay";
import { CopilotUpsertObjectOverlay } from "./CopilotUpsertObjectOverlay";

export function CopilotOverlay() {
  return (
    <IfNotFullscreen>
      <CopilotWelcomeModal />
      <CopilotUpsertObjectOverlay />
      <CopilotAddVisualLabelOverlay />
      <CopilotOverrideOverlay />
      <CopilotBaseOverlay />
    </IfNotFullscreen>
  );
}
