import { CircularProgress } from "@mui/material";
import clsx from "clsx";
import { useLocation } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";

import { useBreakpoints } from "@/util/useBreakpoints";

import { ErrorMessage } from "@/components/ErrorMessage";

import IntegrationsNavigationHeader from "../../../../IntegrationsOld/Core/IntegrationsNavigationHeader";
import { useIntegrationDeviceById } from "../../hooks/deviceHooks";
import { IntegrationEventContent } from "../Event/IntegrationEventTabContent";
import { IntegrationDetailsDeviceEventPlayer } from "./IntegrationDetailsDeviceEventPlayer";

export function IntegrationDetailsDeviceEventPage() {
  const { pathname } = useLocation();

  const { data, loading, error } = useIntegrationDeviceById();
  const { fitsDesktop } = useBreakpoints();
  const [params] = useQueryParam("tab", StringParam);
  const backPath = pathname.split("/").slice(0, -2).join("/");

  if (loading) {
    return (
      <div className="flex items-center justify-center py-24 w-full">
        <CircularProgress size={30} />
      </div>
    );
  }

  if (error) {
    return <ErrorMessage title="Error" description={error.toString()} />;
  }

  return (
    <div>
      <div className="flex items-center justify-between md:bg-transparent bg-white pt-4 md:pt-0">
        <div className="flex flex-col gap-1 relative">
          <IntegrationsNavigationHeader
            to={Boolean(params) ? `${backPath}?tab=${params}` : backPath}
            title={data?.name || ""}
            className="!md:gap-3 !gap-1 sm:mt-2"
            textClassName={clsx({ "text-2xl leading-[28px]": !fitsDesktop })}
          />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-5 gap-2 md:gap-[38px] md:pt-6">
        <IntegrationDetailsDeviceEventPlayer />
        <div className="col-span-1 md:col-span-2">
          <IntegrationEventContent condensed />
        </div>
      </div>
    </div>
  );
}
