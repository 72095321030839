import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Button, Typography } from "@mui/material";
import clsx from "clsx";

export interface MetricsLegendButtonProps {
  label: string;
  color: string;
  visible?: boolean;
  disabled?: boolean;
  onToggleVisibility?: () => void;
  onRemoveResource?: () => void;
}

export function MetricsLegendButton({
  label,
  color,
  visible,
  disabled,
  onToggleVisibility,
  onRemoveResource,
}: MetricsLegendButtonProps) {
  const Icon = visible ? VisibilityIcon : VisibilityOffIcon;

  return (
    <Button
      disabled={disabled}
      variant="outlined"
      className="border-[#DBDBDB] px-2"
      onClick={onToggleVisibility}
    >
      <div className="flex items-center justify-center gap-1">
        <div style={{ background: color }} className="w-4 h-4" />
        <Icon className="w-4" />
        <Typography
          className={clsx("text-sm leading-4 font-normal", {
            "opacity-40": !visible,
          })}
        >
          {label}
        </Typography>
        <div
          className="bg-transparent -mt-[1px]"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onRemoveResource?.();
          }}
        >
          <HighlightOffIcon className="w-4 opacity-50" />
        </div>
      </div>
    </Button>
  );
}
