import { useAtomValue } from "jotai";
import { sum } from "lodash/fp";
import { useEffect, useRef, useState } from "react";

import { qualityLevelsState } from "@/components/Player/PlayerBase";
import { ConnectivityDisplay } from "@/components/Player/Status/ConnectivityDisplay";
import { useQuality } from "@/components/Player/playerControlsMachine";
import {
  PlayingStates,
  usePlayingState,
} from "@/components/Player/playerMachine";

import { CameraHealth } from "@/generated-models";

export function LiveConnectivityDisplay({
  health,
  ...rest
}: {
  health: Pick<CameraHealth, "cameraOnline" | "applianceOnline">;
  cameraId: number;
  mode?: "absolute" | "inline";
}) {
  const qualitySetting = useQuality();
  const bufferingProblem = useBufferingProblem(qualitySetting);
  const qualityLevels = useAtomValue(qualityLevelsState);

  if (!health.applianceOnline) {
    return <ConnectivityDisplay state="applianceUnreachable" {...rest} />;
  }
  if (!health.cameraOnline) {
    return <ConnectivityDisplay state="cameraUnreachable" {...rest} />;
  }

  if (bufferingProblem) {
    const levels = qualityLevels
      .map((level) => level.height)
      .sort((a, b) => a - b)
      .map((height) => `${height}p`);
    // Fallback to "auto" if quality setting is not actually available
    const quality = levels.includes(qualitySetting) ? qualitySetting : "auto";
    // Don't suggest switching to auto if already on auto or lowest quality
    const state = ["auto", levels[0]].includes(quality)
      ? "bufferingStreamOnAuto"
      : "bufferingStreamOnHighRes";

    return <ConnectivityDisplay state={state} {...rest} />;
  }

  return null;
}

export function VodConnectivityDisplay({
  health,
  ...rest
}: {
  health: Pick<CameraHealth, "cameraOnline" | "applianceOnline">;
  mode?: "absolute" | "inline";
  cameraId: number;
}) {
  // const playlistReachable = usePlaylistReachable();
  const qualitySetting = useQuality();
  const bufferingProblem = useBufferingProblem(qualitySetting);
  const qualityLevels = useAtomValue(qualityLevelsState);

  if (!health.applianceOnline) {
    return <ConnectivityDisplay state="applianceUnreachable" {...rest} />;
  }

  if (bufferingProblem) {
    const levels = qualityLevels
      .map((level) => level.height)
      .sort((a, b) => a - b)
      .map((height) => `${height}p`);
    // Fallback to "auto" if quality setting is not actually available
    const quality = levels.includes(qualitySetting) ? qualitySetting : "auto";
    // Don't suggest switching to auto if already on auto or lowest quality
    const state = ["auto", levels[0]].includes(quality)
      ? "bufferingVideoOnAuto"
      : "bufferingVideoOnHighRes";

    return <ConnectivityDisplay state={state} {...rest} />;
  }

  return null;
}

const bufferingStates = new Set(["idle", "buffering", "stalled"]);
const bufferingLookbackPeriod = 15e3; // seconds
function useBufferingProblem(quality: string) {
  const playingState = usePlayingState();
  const [bufferingTimeFraction, setBufferingTimeFraction] = useState(0);
  const bufferTrackerRef = useRef<ReturnType<typeof bufferTracker>>();
  // Reset buffer tracking when switching quality, so that the buffering
  // popup will hide while the player is loading the newly selected quality.
  useEffect(() => {
    setBufferingTimeFraction(0);
    const tracker = (bufferTrackerRef.current = bufferTracker(
      setBufferingTimeFraction
    ));
    return () => {
      tracker.destroy();
    };
  }, [quality]);

  useEffect(() => {
    if (!bufferTrackerRef.current) return;
    bufferTrackerRef.current.handlePlayingStateChange(playingState);
  }, [playingState]);

  useEffect(() => {}, []);

  return bufferingTimeFraction > 0.7;
}

/**
 * The buffering tracker keeps track of the past <bufferingLookbackPeriod> seconds, and
 * will calculate what fraction of that period the player was buffering.
 *
 * todo: add unit tests
 */
function bufferTracker(setBufferingTimeFraction: (value: number) => void) {
  let bufferingTimes = [] as { start: number; end?: number }[];
  let interval: number | null = null;
  function handlePlayingStateChange(playingState: PlayingStates) {
    if (interval) {
      window.clearTimeout(interval);
    }
    // console.log(playingState);
    let newBufferingTimes: typeof bufferingTimes | null = null;
    const last = bufferingTimes[bufferingTimes.length - 1];
    if (bufferingStates.has(playingState)) {
      // start buffering
      if (!last || last.end) {
        newBufferingTimes = [...bufferingTimes, { start: Date.now() }];
      }
    } else if (last) {
      // end buffering
      if (last && !last.end) {
        newBufferingTimes = [
          ...bufferingTimes.slice(0, -1),
          { ...last, end: Date.now() },
        ];
      }
    }

    if (newBufferingTimes) {
      bufferingTimes = newBufferingTimes;
    }

    function purgeBufferingTimes(value: typeof bufferingTimes) {
      return value
        .filter((b) => {
          if (b.end) {
            return Date.now() - b.end < bufferingLookbackPeriod;
          }
          return true;
        })
        .map(({ start, end }) => ({
          start: Math.max(start, Date.now() - bufferingLookbackPeriod),
          end,
        }));
    }
    interval = window.setInterval(() => {
      const purgedBufferingTimes = purgeBufferingTimes(bufferingTimes).map(
        ({ start, end }) => (end ?? Date.now()) - start
      );
      // console.log(purgedBufferingTimes, sum(purgedBufferingTimes));
      setBufferingTimeFraction(
        sum(purgedBufferingTimes) / bufferingLookbackPeriod
      );
    }, 1000);
  }
  return {
    handlePlayingStateChange,
    destroy: () => {
      if (interval) {
        window.clearTimeout(interval);
      }
    },
  };
}
