import {
  IntelligenceDashboardQuery,
  IntelligenceDashboardType,
} from "@/generated-models";

export function getComparativeCameras(
  dashboard: IntelligenceDashboardQuery["intelligenceDashboard"]
) {
  return dashboard?.referenceDashboards?.flatMap((rd) => rd.cameras) || [];
}

export function getComparativeType(
  dashboard: IntelligenceDashboardQuery["intelligenceDashboard"]
) {
  return dashboard?.subtype as IntelligenceDashboardType;
}
