import { useGenius } from "@/components/Genius/GeniusProvider";
import { useFeedback, FeedbackType } from "@/components/SnackbarProvider";

import {
  DeviceListDocument,
  LocationCapacityDocument,
  useAddCameraMutation,
  useUpdateCameraMutation,
} from "@/generated-models";

export function useUpdateCamera() {
  const [geniusScan] = useGenius();
  const { pushSnackbar } = useFeedback();
  const [updateCamera, { loading }] = useUpdateCameraMutation({});

  function update(cameraId: number, deviceStreamId?: number, name?: string) {
    // Update added camera
    const input = {
      deviceStreamId,
      name,
    };
    return updateCamera({
      variables: { id: cameraId, input },
    })
      .then((x) => {
        if (x.data) {
          pushSnackbar(
            `Successfully updated "${x.data.updateCamera.name}"`,
            FeedbackType.Success
          );
        }
        return x;
      })
      .catch((error: Error) => {
        pushSnackbar(
          `Updating the camera failed, ${
            error.message.includes("already exist")
              ? "because an identical camera already exists"
              : "please try again..."
          }`,
          FeedbackType.Error
        );
      })
      .then(
        async () =>
          !!deviceStreamId && // Only genius scan on path changes
          (await geniusScan(
            <>
              Genius is applying updated settings{" "}
              {input.name ? `from "${input.name}" ` : ""}to other cameras.
            </>,
            <>
              Automatically tested new settings{" "}
              {input.name ? `from "${input.name}" ` : ""}on other cameras.
            </>
          ))
      )
      .catch((e) => console.error(e));
  }

  return { update, loading };
}

export function useAddCamera(locationId: number) {
  const [geniusScan] = useGenius();
  const { pushSnackbar } = useFeedback();
  const [addCamera, { loading }] = useAddCameraMutation({
    onError: (error) =>
      pushSnackbar(
        `Activating the camera failed, ${
          error.message.includes("already exist")
            ? "because the camera already exists"
            : "please try again..."
        }`,
        FeedbackType.Error
      ),
  });

  function addNewCamera(
    deviceStreamId: number,
    name: string,
    vendor: string,
    ai: boolean
  ) {
    // Activate new camera
    const newCam = {
      deviceStreamId,
      name: name || "Untitled Camera",
      ai,
      bypass:
        !!sessionStorage.bypassLocationCapacity &&
        !!JSON.parse(sessionStorage.bypassLocationCapacity),
    };
    return addCamera({
      variables: { input: newCam },
      refetchQueries: [
        { query: LocationCapacityDocument, variables: { locationId } },
        { query: DeviceListDocument, variables: { locationId: locationId } },
      ],
      update(_, { data }) {
        if (!data) return;

        pushSnackbar(
          `Successfully activated "${data.addCamera.name}"`,
          FeedbackType.Success
        );
        geniusScan(
          <>
            Genius is applying settings from <strong>{newCam.name}</strong> to
            other cameras from {vendor}.
          </>,
          <>
            Successfully applied settings from <strong>{newCam.name}</strong> to
            other {vendor} cameras.
          </>
        );
      },
    });
  }
  return { addNewCamera, loading };
}
