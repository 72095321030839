import {
  Button,
  MenuItem,
  Popover,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { useAtomValue } from "jotai";
import { useState } from "react";

import { ASSISTANT_RESOURCE } from "@/components/Assistant/utils/editor/constant";

import { refetchOnMountPolicy } from "@/apolloClient";
import {
  Page_AssistantQuery,
  usePage_AssistantQuery,
} from "@/generated-models";
import { DarkTheme } from "@/layout/theme";

import { conversationLocationAtom } from "../hooks";

interface AssistantPromptInputPopoverProps {
  open: boolean;
  anchorEl?: Element | null;
  type?: string;
  handleClose: () => void;
  onSave: (
    data:
      | Page_AssistantQuery["cameras"][number]
      | Page_AssistantQuery["appliances"][number],
    value: string
  ) => void;
}

export function AssistantPromptInputPopover({
  open,
  anchorEl,
  type,
  handleClose,
  onSave,
}: AssistantPromptInputPopoverProps) {
  const { data } = usePage_AssistantQuery(refetchOnMountPolicy);

  const [selected, setSelected] = useState("");
  const locationId = useAtomValue(conversationLocationAtom);

  const handleChange = (event: SelectChangeEvent) => {
    setSelected(event.target.value);
  };

  const isCamera = type === ASSISTANT_RESOURCE.camera;

  return (
    <DarkTheme>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className="p-4 flex flex-col gap-2 min-w-[200px]">
          <Typography className="font-semibold text-xs">{type}</Typography>
          <Select
            value={selected}
            onChange={handleChange}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {isCamera
              ? data?.cameras
                  ?.filter((c) => c.location.id === locationId)
                  ?.map((c) => (
                    <MenuItem key={c.id} value={c.id}>
                      <div className="flex flex-col">
                        <div className="text-xs">{c.location.name}</div>
                        <div>{c.name}</div>
                      </div>
                    </MenuItem>
                  ))
              : data?.appliances
                  ?.filter((a) => a.location.id === locationId)
                  ?.map((a) => (
                    <MenuItem key={a.id} value={a.id}>
                      <div className="flex flex-col">
                        <div className="text-xs">{a.location.name}</div>
                        <div>{a.serialNumber}</div>
                      </div>
                    </MenuItem>
                  ))}
          </Select>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className="font-normal rounded-[6px] shadow-none mt-4"
            onClick={() => {
              if (isCamera) {
                const item = data?.cameras.find(
                  (c) => c.id === parseInt(selected)
                );

                if (item) {
                  onSave(item, item.name);
                }
              } else {
                const item = data?.appliances.find(
                  (c) => c.id === parseInt(selected)
                );

                if (item) {
                  onSave(item, item.serialNumber);
                }
              }
            }}
          >
            Save
          </Button>
        </div>
      </Popover>
    </DarkTheme>
  );
}
