import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { usePrevious } from "@react-hookz/web";
import { pick } from "lodash/fp";
import { useEffect, useRef } from "react";
import { StringParam, useQueryParam } from "use-query-params";

import { intelligentFiltersConfig } from "@/pages/Search/intelligence/intelligence";

import { AddToInterestListButton } from "@/components/InterestList/AddToInterestListButton";
import {
  ContextualPlayerBase,
  ContextualPlayerFooterButtons,
  ContextualPlayerFooterDateTime,
  Indicators,
  LocationNameTitle,
} from "@/components/VideoWall/ContextualVideoPlayer";

import { InterestListType } from "@/generated-models";

import { LicensePlateNumberIndicator } from "../LicensePlateNumberIndicator";
import { useLprSelected } from "../hooks";

export function LicensePlateDetailsPlayer() {
  const playerRef = useRef<HTMLDivElement>(null);
  const { data } = useLprSelected();
  const prevId = usePrevious(data?.id);
  const [, setViewing] = useQueryParam("viewing", StringParam);
  const camera = data?.camera;

  useEffect(() => {
    if (data && data.id !== prevId) {
      setTimeout(() => {
        playerRef?.current?.scrollIntoView();
      }, 0);
    }
  }, [camera, data, prevId]);

  if (!data || !camera) return <></>;

  return (
    <div ref={playerRef} id="lpr-details-player" className="scroll-mt-20">
      <ContextualPlayerBase
        className="rounded-2xl"
        playerProps={{
          ...pick(["startTime", "endTime"], data),
          camera,
        }}
        header={
          <>
            <div className="flex py-3 px-[14px] justify-between items-center w-full">
              <LocationNameTitle
                location={camera.location.name}
                name={camera.name}
                className="hidden md:flex"
              />
              <div className="flex flex-row md:flex-col items-end justify-between gap-4 w-full">
                <IconButton
                  className="w-3 h-3"
                  aria-label="close"
                  onClick={() => setViewing(null)}
                >
                  <CloseIcon className="text-white" />
                </IconButton>
                <Indicators />
              </div>
            </div>
          </>
        }
        footer={
          <div className="flex items-center justify-between py-[8px] px-4 pr-0 md:px-5 rounded-b-lg">
            <div className="flex flex-col-reverse md:flex-row md:gap-3 gap-1 md:items-center">
              <ContextualPlayerFooterDateTime
                timezone={camera.location.timezone}
                startTime={data.startTime}
              />
              <LicensePlateNumberIndicator
                plate={data.vehicleAttributes?.plate}
              />
              <AddToInterestListButton
                className="pt-1"
                buttonClassName="text-white underline"
                type={InterestListType.Lpr}
                name={data.vehicleAttributes?.plate?.split("/")[0]}
              />
            </div>
            <ContextualPlayerFooterButtons
              startTime={data.startTime}
              endTime={data.endTime}
              camId={camera.id}
              subjects={intelligentFiltersConfig.vehicle.objectIds}
            />
          </div>
        }
      />
    </div>
  );
}
