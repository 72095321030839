import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  IconButton,
  TextField,
  TextFieldProps,
  Tooltip,
  Typography,
  TypographyProps,
} from "@mui/material";
import React, { useState } from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  input: {
    fontSize: 17,
    marginBottom: 7,
  },
  titleText: {
    fontSize: 17,
    color: theme.palette.grey[600],
    marginLeft: 5,
    marginBottom: 3, // Nudge it up
  },
  visible: {
    opacity: 1,
  },
}));
interface EditableTextProps {
  initialValue?: string;
  onSubmit: (value: string) => void;
  TextFieldProps?: TextFieldProps;
  TypographyProps?: TypographyProps;
  disabled?: boolean;
}
export default function EditableText({
  initialValue = "",
  onSubmit,
  TextFieldProps,
  TypographyProps,
  disabled,
}: EditableTextProps) {
  const { classes } = useStyles();
  const [value, setValue] = useState(initialValue);
  const [submittedValue, setSubmittedValue] = useState(initialValue);
  const [editing, setEditing] = useState(false);
  function submit() {
    // Prevent submits when nothing changed
    if (value !== submittedValue) {
      onSubmit(value);
      setSubmittedValue(value);
    }
  }

  return (
    <Box display="flex" alignItems="center">
      {editing ? (
        <TextField
          className={classes.input}
          size="small"
          autoFocus
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onBlur={() => {
            setEditing(false);
            submit();
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              setEditing(false);
              submit();
            }
          }}
          {...TextFieldProps}
        />
      ) : (
        <div className="flex items-center gap-2">
          <Typography {...TypographyProps}>{value}</Typography>{" "}
          {!disabled && (
            <Tooltip title="Rename" arrow>
              <IconButton
                size="small"
                disabled={disabled}
                onClick={() => {
                  setEditing(true);
                }}
              >
                <EditIcon fontSize="small" className={classes.titleText} />
              </IconButton>
            </Tooltip>
          )}
        </div>
      )}
    </Box>
  );
}
