import CheckIcon from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import {
  Box,
  ButtonBase,
  ClickAwayListener,
  Dialog,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { useAtom, useAtomValue } from "jotai";
import { orderBy } from "lodash/fp";
import React, { PropsWithChildren } from "react";

import { useBreakpoints } from "@/util/useBreakpoints";

import {
  playbackRateState,
  qualityLevelsState,
} from "@/components/Player/PlayerBase";
import { SettingsIcon } from "@/components/Player/PlayerIcons";
import { PlayerTooltip } from "@/components/Player/PlayerTooltip";
import {
  PlayerControlsEvent,
  useMatchesPlayerSettingsItem,
  useMatchesPlayerSettingsList,
  usePlayerControlsService,
  usePlayerSettingsOpened,
  useQuality,
  useSetPlayerSetting,
} from "@/components/Player/playerControlsMachine";
import { useVisualQuality } from "@/components/Player/playerMachine";

// const useStyles = makeStyles()((theme) => ({
//   hideFirstDivider: {
//     "&:first-child": {
//       display: "none",
//     },
//   },
// }));

export function PlayerSettingsMenu({ children }: PropsWithChildren<{}>) {
  return <List className="bg-black p-0 w-72 rounded-md">{children}</List>;
}

function generateQualityOptions(resolutions: number[], autoRes?: number) {
  return [
    // Auto quality option
    { value: "auto", label: "Auto" + (autoRes ? ` (${autoRes}p)` : "") },
    // Options to force resolution. First is original source quality, the rest is ABR.
    ...orderBy((x) => x, "desc", resolutions).map((value, i) => ({
      value: `${value}p`,
      label: `${value}p` + (i === 0 ? " (original)" : ""),
      endAdornment: value >= 720 ? "HD" : "SD",
    })),
  ];
}
export function QualitySetting() {
  const selectedQuality = useQuality();
  const visualQuality = useVisualQuality();
  const setPlayerSetting = useSetPlayerSetting();
  const qualityLevels = useAtomValue(qualityLevelsState);
  const settingEnabled = qualityLevels.length > 1;
  const list = useMatchesPlayerSettingsList();
  const detail = useMatchesPlayerSettingsItem("quality") && settingEnabled;

  // TODO: get resolutions and visual quality from player context (recoil or player machine)
  const resolutions = qualityLevels.map((x) => x.height).filter(Boolean);

  const verifiedQuality = resolutions.some(
    (value) => selectedQuality === `${value}p`
  )
    ? selectedQuality
    : "auto";

  // Overwrite quality options based on max resolution
  const qualityOptions = generateQualityOptions(
    resolutions,
    verifiedQuality === "auto" ? visualQuality : undefined
  );
  const selectedItem = qualityOptions.find(
    (x) => x.value === verifiedQuality
  ) ?? { label: `${visualQuality}p`, value: `${visualQuality}p` };
  return (
    <>
      {list && (
        <PlayerSettingsItem
          label="Quality"
          onClickEvent={PlayerControlsEvent.OPEN_QUALITY_SETTING}
          selectedItem={selectedItem}
          disabled={!settingEnabled}
        />
      )}
      {detail && (
        <>
          <PlayerSettingsHeader label="Quality" />
          <Divider className="bg-gray-800" />
          {qualityOptions.map((item) => (
            <PlayerSettingsOption
              key={item.value}
              item={item}
              currentValue={verifiedQuality}
              select={() => setPlayerSetting("quality", item.value)}
            />
          ))}
        </>
      )}
    </>
  );
}
export const playbackRateOptions = [
  { value: 0.5, label: "0.5" },
  { value: 1, label: "Normal" },
  { value: 1.5, label: "1.5" },
  { value: 2, label: "2.0" },
  { value: 3, label: "3.0" },
  { value: 4, label: "4.0" },
  // { value: 8, label: "8.0" },
  // { value: 16, label: "16.0" },
];
export function PlaybackRateSetting({ disabled }: { disabled?: boolean }) {
  const list = useMatchesPlayerSettingsList();
  const detail = useMatchesPlayerSettingsItem("playbackRate");
  const [selectedPlaybackRate, setPlaybackRate] = useAtom(playbackRateState);
  return (
    <>
      {list && (
        <PlayerSettingsItem
          disabled={disabled}
          label="Playback Speed"
          onClickEvent={PlayerControlsEvent.OPEN_PLAYBACK_RATE_SETTING}
          selectedItem={playbackRateOptions.find(
            (x) => x.value === selectedPlaybackRate
          )}
        />
      )}
      {detail && (
        <>
          <PlayerSettingsHeader label="Playback Speed" />
          <Divider className="bg-gray-800" />
          {playbackRateOptions.map((item) => (
            <PlayerSettingsOption
              key={item.value}
              item={item}
              currentValue={selectedPlaybackRate}
              select={() => setPlaybackRate(item.value)}
            />
          ))}
        </>
      )}
    </>
  );
}
interface SettingItem<T extends string | number> {
  value: T;
  label: string;
  endAdornment?: string;
}

function PlayerSettingsItem<T extends string | number>({
  label,
  onClickEvent,
  selectedItem,
  disabled = false,
}: {
  label: string;
  onClickEvent: PlayerControlsEvent;
  selectedItem?: SettingItem<T>;
  disabled?: boolean;
}) {
  const { send } = usePlayerControlsService();
  return (
    <>
      <Divider className="bg-gray-800 first:hidden" />
      <ListItem
        onClick={() => send(onClickEvent)}
        component={ButtonBase}
        className="text-white text-left py-1 px-2.5 w-full hover:bg-white hover:bg-opacity-10"
        disabled={disabled}
      >
        <ListItemText
          primary={
            <Grid container>
              <Box m={1} />
              <strong>{label}</strong>
              <Box flexGrow={1} />
              <div>{selectedItem?.label || "Auto"}</div>
            </Grid>
          }
        />

        {disabled ? <Box m={1.5} /> : <ChevronRight />}
      </ListItem>
    </>
  );
}

const settingsClass = "text-white text-left px-2.5 py-1 w-full";

function PlayerSettingsHeader({ label }: { label: string }) {
  const { send } = usePlayerControlsService();
  return (
    <ListItem className={settingsClass}>
      <ListItemIcon
        classes={{
          root: "min-w-0 pr-2",
        }}
      >
        <IconButton
          onClick={() => send(PlayerControlsEvent.CLOSE_SETTINGS_ITEM)}
          style={{ padding: 0, color: "white" }}
          size="large"
        >
          <ChevronLeft />
        </IconButton>
      </ListItemIcon>
      <ListItemText>
        <strong>{label}</strong>
      </ListItemText>
    </ListItem>
  );
}

function PlayerSettingsOption<T extends number | string>({
  item,
  currentValue,
  select,
}: {
  item: SettingItem<T>;
  currentValue: T;
  select: () => void;
}) {
  const selected = currentValue === item.value;
  return (
    // Setting an item
    <ListItem
      onClick={select}
      component={ButtonBase}
      className={`${settingsClass} hover:bg-white hover:bg-opacity-10`}
    >
      {selected ? (
        <ListItemIcon
          classes={{
            root: "min-w-0 pr-2",
          }}
        >
          <CheckIcon color="primary" />
        </ListItemIcon>
      ) : (
        <Box width={32} />
      )}
      <ListItemText
        primary={
          <strong
            style={{
              opacity: selected ? 1 : 0.5,
            }}
          >
            {item.label}
          </strong>
        }
      />
      <Typography color="primary" variant="caption">
        {item.endAdornment}
      </Typography>
    </ListItem>
  );
}

// export const PlayerSettingsButton = React.memo(function ({
export function PlayerSettingsButton({
  children,
  initialOpenEvent,
}: PropsWithChildren<{
  initialOpenEvent?:
    | PlayerControlsEvent.OPEN_PLAYBACK_RATE_SETTING
    | PlayerControlsEvent.OPEN_QUALITY_SETTING;
}>) {
  const popupOpen = usePlayerSettingsOpened();
  const { send } = usePlayerControlsService();
  const { fitsTablet } = useBreakpoints();

  return (
    <ClickAwayListener
      onClickAway={() => {
        send(PlayerControlsEvent.CLOSE_SETTINGS_MENU);
      }}
    >
      <div className="relative">
        <PlayerTooltip title="Settings">
          <div
            className={clsx(
              "relative rounded-md bg-black bg-opacity-0 transition-colors",
              {
                "bg-opacity-100": popupOpen,
              }
            )}
          >
            <IconButton
              style={{ color: "inherit" }}
              onClick={(e) => {
                e.stopPropagation();

                send(
                  popupOpen || !initialOpenEvent
                    ? PlayerControlsEvent.TOGGLE_SETTINGS_MENU
                    : initialOpenEvent
                );
              }}
              aria-label="settings"
              size="large"
            >
              <SettingsIcon color={popupOpen ? "primary" : "inherit"} />
            </IconButton>
          </div>
        </PlayerTooltip>
        {popupOpen &&
          (fitsTablet ? (
            <Box className="absolute bottom-14 right-0 bg-black rounded-md">
              <PlayerSettingsMenu>{children}</PlayerSettingsMenu>
            </Box>
          ) : (
            <Dialog
              open
              onClose={() => {
                send(PlayerControlsEvent.CLOSE_SETTINGS_MENU);
              }}
              classes={{
                paper: "bg-black rounded-md",
              }}
            >
              <PlayerSettingsMenu>{children}</PlayerSettingsMenu>
            </Dialog>
          ))}
      </div>
    </ClickAwayListener>
  );
}
