import { IconButton } from "@mui/material";
import { useAtom } from "jotai";
import React, { ReactNode, useCallback } from "react";

import { playingIntentState } from "@/components/Player/PlayerBase";
import { PauseIcon, PlayIcon } from "@/components/Player/PlayerIcons";

import { PlayerTooltip } from "./PlayerTooltip";

export function PlayPauseButton({ fontSize = 24 }: { fontSize?: number }) {
  const [playing, setPlaying] = useAtom(playingIntentState);
  const togglePlaying = useCallback(() => setPlaying((value) => !value), [
    setPlaying,
  ]);
  let action: "Play" | "Pause" = "Play";
  let icon: ReactNode;
  if (playing) {
    action = "Pause";
    icon = <PauseIcon fontSize="inherit" />;
  } else {
    action = "Play";
    icon = <PlayIcon fontSize="inherit" />;
  }

  return (
    <PlayerTooltip title={action}>
      <IconButton
        color="inherit"
        onClick={togglePlaying}
        style={{ fontSize }}
        size="large"
      >
        {icon}
      </IconButton>
    </PlayerTooltip>
  );
}
