import { SeriesOption } from "echarts";
import { useAtomValue } from "jotai";
import { useMemo } from "react";

import { COMPARATIVE_COLORS, ComparativeTabType } from "../../constant";
import { compContentTabAtom } from "../ComparativeDashboardContentTabs";
import { ComparativeDashboardDataProps } from "./ComparativeDashboardChart";
import {
  refContentDashboardsAtom,
  refPresenceDashboardsAtom,
} from "./ComparativeDashboardChartFetcher";

function buildLineConfig(color: string | string[]) {
  return {
    type: "line",
    emphasis: {
      focus: "series",
    },
    dimensions: ["timestamp", "value"],
    animation: false,
    itemStyle: {
      color,
    },
  };
}

function truncateString(value: string, length: number) {
  if (value?.length <= length) {
    return value;
  }
  return value?.slice(0, length) + "...";
}

function getChartColors(tab: ComparativeTabType) {
  switch (tab) {
    case ComparativeTabType.IDLE_PERCENTAGE:
      return COMPARATIVE_COLORS.map((c) => c[1]);
    case ComparativeTabType.IDLE_COUNT:
      return COMPARATIVE_COLORS.map((c) => [c[0], c[1]]);
    case ComparativeTabType.COUNT_IN_OUT:
      return COMPARATIVE_COLORS.map((c) => [c[1], c[2]]);
    case ComparativeTabType.COUNT_IN:
      return COMPARATIVE_COLORS.map((c) => c[1]);
    case ComparativeTabType.COUNT_OUT:
      return COMPARATIVE_COLORS.map((c) => c[1]);
    default:
      return COMPARATIVE_COLORS.map((c) => [c[1], c[2]]);
  }
}

function useBuildPresenceData() {
  const tab = useAtomValue(compContentTabAtom);
  const refPresence = useAtomValue(refPresenceDashboardsAtom);
  const colors = getChartColors(tab);

  return (idx: number, id: number, label: string, timezone: string) => {
    const presMapped = refPresence[id]?.timeseries || [];

    return [
      {
        name: `${label} Present`,
        data: presMapped.map((b) => [b.date, b.presencePerc]),
        ...buildLineConfig(colors[idx][1]),
      },
      {
        name: `${label} Absent`,
        data: presMapped.map((b) => [b.date, 100 - b.presencePerc]),
        ...buildLineConfig(colors[idx][0]),
      },
    ];
  };
}

function useBuildContentData() {
  const tab = useAtomValue(compContentTabAtom);
  const refContent = useAtomValue(refContentDashboardsAtom);
  const colors = getChartColors(tab);

  return (idx: number, id: number, label: string) => {
    const buckets = refContent[id]?.timeseries || [];

    switch (tab) {
      case ComparativeTabType.IDLE_PERCENTAGE:
        return [
          {
            name: label,
            data: buckets.map((b) => [b.date, b.idlePercentage]),
            ...buildLineConfig(colors[idx]),
          },
        ];
      case ComparativeTabType.IDLE_COUNT:
        return [
          {
            name: `${label} Idle`,
            data: buckets.map((b) => [b.date, b.idle]),
            ...buildLineConfig(colors[idx][1]),
          },
          {
            name: `${label} Total`,
            data: buckets.map((b) => [b.date, b.total]),
            ...buildLineConfig(colors[idx][0]),
          },
        ];

      case ComparativeTabType.COUNT_IN_OUT:
      case ComparativeTabType.COUNT_IN:
      case ComparativeTabType.COUNT_OUT:
        return [
          ...(tab !== ComparativeTabType.COUNT_OUT
            ? [
                {
                  name: `${label} In`,
                  data: buckets.map((b) => [b.date, b.leftCount]),
                  ...buildLineConfig(colors[idx][0]),
                },
              ]
            : []),
          ...(tab !== ComparativeTabType.COUNT_IN
            ? [
                {
                  name: `${label} Out`,
                  data: buckets.map((b) => [b.date, b.rightCount]),
                  ...buildLineConfig(colors[idx]),
                },
              ]
            : []),
          {
            name: `${label} Out`,
            data: buckets.map((b) => [b.date, b.rightCount]),
            ...buildLineConfig(colors[idx][1]),
          },
        ];
      default:
        return [];
    }
  };
}

export function useComparativeChartData(
  dashboards: ComparativeDashboardDataProps["referenceDashboards"]
) {
  const tab = useAtomValue(compContentTabAtom);

  const buildContentData = useBuildContentData();
  const buildPresenceData = useBuildPresenceData();

  return useMemo(() => {
    const result: SeriesOption[] = [];

    dashboards.forEach(({ id, cameras }, idx) => {
      const camera = cameras[0];
      const timezone = camera?.location?.timezone;
      const label = `${truncateString(camera?.name, 15)} - (${truncateString(
        camera?.location?.name,
        15
      )})`;

      const fn =
        tab === ComparativeTabType.PRESENCE
          ? buildPresenceData
          : buildContentData;

      result.push(...(fn(idx, id, label, timezone) as SeriesOption[]));
    });
    return result;
  }, [buildContentData, buildPresenceData, dashboards, tab]);
}
