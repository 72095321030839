import { Box, Button, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import gql from "graphql-tag";
import React from "react";

import { useLogout } from "@/components/Auth";
import { Logo } from "@/components/shared/Logo";

import { useResetUserPasswordMutation } from "@/generated-models";

export function PasswordExpired() {
  const logout = useLogout();
  const [resetPassword, { data }] = useResetUserPasswordMutation();
  const success = !!data?.resetUserPassword.message;

  return (
    <div className="flex flex-col items-center py-10 px-3">
      <Logo className="h-16" />
      <Box m={1} />
      <Typography variant="h1">Your password expired</Typography>
      <Typography variant="subtitle1" className="text-center">
        {success
          ? "We've just sent you an email to reset your password."
          : "Please enter your email address. We will send you an email to reset your password."}
      </Typography>
      <Box m={1} />
      {!success && (
        <Formik
          validateOnBlur
          initialValues={{ email: "" }}
          onSubmit={({ email }, { setErrors }) => {
            return resetPassword({ variables: { email } }).catch(() =>
              setErrors({ email: "Something went wrong" })
            );
          }}
        >
          {({ isSubmitting, isValid, values }) => (
            <Form className="flex items-center">
              <Field
                name="email"
                placeholder="name@email.com"
                component={TextField}
                variant="outlined"
              />
              <Box m={1} />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting || !isValid || !values.email}
              >
                Reset password
              </Button>
            </Form>
          )}
        </Formik>
      )}
      <Box m={2} />
      <Button color="primary" onClick={() => logout()}>
        Switch to different account
      </Button>
    </div>
  );
}

export const RESET_USER_PASSWORD = gql`
  mutation resetUserPassword($email: String!) {
    resetUserPassword(email: $email) {
      message
    }
  }
`;
