import { Tooltip, TooltipProps } from "@mui/material";
import React from "react";

export function PlayerTooltip(props: TooltipProps) {
  return (
    <Tooltip
      PopperProps={{ disablePortal: true }}
      classes={{ tooltip: "bg-black bg-opacity-90 whitespace-nowrap" }}
      {...props}
    />
  );
}
