import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Skeleton,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { useField } from "formik";
import { useState } from "react";

import { FormErrorMessage } from "@/pages/Intelligence/FormInputs";

import { InterestListDialog } from "@/components/InterestList/InterestListDialog";

import {
  InterestListType,
  usePage_InterestListsQuery,
} from "@/generated-models";

export function InterestListSelectFormItem({
  loading,
  hideLabel,
}: {
  loading?: boolean;
  hideLabel?: boolean;
}) {
  const { data } = usePage_InterestListsQuery();
  const [, { value = [] }, { setValue }] = useField<number[]>("interestLists");
  const [open, setOpen] = useState(false);

  return (
    <div>
      {!hideLabel && (
        <Typography variant="body2" className="font-medium text-[#666666] mb-2">
          License Plates of Interest
        </Typography>
      )}

      <FormGroup className={clsx(value.length > 0 && "mb-2")}>
        {value.map((v) => {
          const list = data?.interestLists.find((l) => l.id === v);

          return (
            <FormControlLabel
              key={v}
              control={<Checkbox className="py-1" defaultChecked />}
              onClick={() => {
                setValue(value.filter((i) => i !== v));
              }}
              label={
                <span>
                  <strong className="text-primary">{list?.name}</strong> (
                  {list?.items?.length} Plates)
                </span>
              }
            />
          );
        })}
      </FormGroup>
      {!loading && (
        <Button
          className="whitespace-nowrap bg-[#DDEFFF] text-primary shadow-none rounded-lg font-normal"
          color="primary"
          variant="contained"
          onClick={() => {
            setOpen(true);
          }}
          size="large"
        >
          + Create or Select List
        </Button>
      )}
      {loading && (
        <Skeleton variant="rectangular" className="h-10 w-[190px] rounded-lg" />
      )}
      <FormErrorMessage name="interestLists" />
      <InterestListDialog
        open={open}
        initialSelection={value}
        primaryActionLabel={"Add to Insight"}
        type={InterestListType.Lpr}
        onClose={() => {
          setOpen(false);
        }}
        onApplyLists={(lists) => {
          setOpen(false);
          setValue(lists);
        }}
      />
    </div>
  );
}
