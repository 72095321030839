import FaceIcon from "@mui/icons-material/Face";
import InfoIcon from "@mui/icons-material/Info";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import PaletteIcon from "@mui/icons-material/Palette";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { useSessionStorageValue } from "@react-hookz/web";
import clsx from "clsx";
import { FormikProps, FormikValues } from "formik";
import { useFlags } from "launchdarkly-react-client-sdk";
import { FunctionComponent, SVGProps, useState } from "react";

import { LPRIcon } from "@/icons/LPRIcon";
import { ReactComponent as ForkliftIcon } from "@/icons/forklift.svg";

import { filterFalsy } from "@/util/filterFalsy";

import { LPRSetupHelpModal } from "@/pages/Maintain/Datagrid/LPRSetupHelpModal";
import { PeopleSearchEulaModal } from "@/pages/Search/PeopleSearchEulaModal";

import { useMe } from "@/components/Auth";
import { BaseDatagridRowSelectionProps } from "@/components/DataGrid/DataGrid";
import {
  DataGridEditFormSection,
  DataGridEditFormToggle,
} from "@/components/DataGrid/DataGridEditDrawer/DataGridEditForm";
import { useDataGridSelection } from "@/components/DataGrid/hooks";
import { useDialog } from "@/components/shared/Dialog";

import {
  CameraSettings,
  OrganizationFlags,
  OrganizationPermissions,
} from "@/generated-models";
import { usePermissions } from "@/hooks/usePermissions";

import { AudioEnableAcknowledgmentModal } from "./AudioEnableAcknowledgmentModal";
import { useBulkEditCameras, useBulkValidateEditCameras } from "./hooks";

export function getAiConfigSettings(
  orgFlags?: OrganizationFlags,
  permissions?: Partial<OrganizationPermissions>
): { field: string; label: string; Icon: FunctionComponent }[] {
  return [
    {
      field: "modelForkliftEnabled",
      label: "Forklift Detection",
      Icon: ForkliftIcon,
    },
    {
      field: "lprEnabled",
      label: "License Plate Recognition",
      Icon: LPRIcon,
    },
    orgFlags?.aiAttributeSearch &&
      permissions?.attribute_search_manage && {
        field: "attributesEnabled",
        label: "Attribute Search",
        Icon: PaletteIcon,
      },
    orgFlags?.aiFaceRecognition &&
      permissions?.people_search_manage && {
        field: "faceRecognitionEnabled",
        label: "People Search",
        Icon: FaceIcon,
      },
  ].filter(filterFalsy);
}

export function useMaintainBulkConfig() {
  const { cameraBulkCfg, onvifBackchannel } = useFlags();

  const [showLprHelp, setShowLprHelp] = useSessionStorageValue(
    "lpr-help",
    true
  );
  const me = useMe();
  const hasPermissions = usePermissions();
  const { open: peopleSearchOpen, ...peopleSearchDialogProps } = useDialog();
  const [lprHelpOpen, setLprHelpOpen] = useState(false);
  const { selection = [], reset } = useDataGridSelection();
  const [
    audioEnableAcknowledgmentOpen,
    setAudioEnableAcknowledgmentOpen,
  ] = useState(false);

  const {
    BulkEditFeedbackDialog,
    bulkEdit,
    bulkEditLoading,
  } = useBulkEditCameras();
  const {
    BulkValidateEditFeedbackDialog,
    bulkValidateEdit,
    bulkValidateEditLoading,
  } = useBulkValidateEditCameras();

  if (!cameraBulkCfg || !hasPermissions("devices_manage")) return null;

  async function onBulkToggleChange(
    allRowIds: number[],
    formikProps: FormikProps<FormikValues>,
    settings: Partial<CameraSettings>
  ) {
    const result = await bulkValidateEdit(
      selection?.includes(-1) ? allRowIds : (selection as number[]),
      {
        settings,
      }
    );

    if (result?.data?.validateUpdateCameraSettings?.success) {
      if (settings.lprEnabled) {
        if (showLprHelp) {
          setLprHelpOpen(true);
          setShowLprHelp(false);
        }
      }
      formikProps.setValues({ settings });
    }
  }

  const aiConfigSettings = getAiConfigSettings(
    me?.organization.flags,
    me?.organization.permissions
  );

  return {
    editDrawerInitialValues: {
      settings: {
        modelForkliftEnabled: undefined,
        lprEnabled: undefined,
        audioControlEnabled: undefined,
        onvifBackchannelEnabled: undefined,
        attributesEnabled: undefined,
        faceRecognitionEnabled: undefined,
      },
    },
    editDrawerOnSubmit: async (ids, { settings }, helpers) => {
      await bulkEdit(ids, {
        settings,
      });
      reset();
      helpers.resetForm();
    },
    rowLabel: "camera",
    renderEditDrawer: (props, formikProps) => (
      <>
        <BulkEditFeedbackDialog />
        <PeopleSearchEulaModal {...peopleSearchDialogProps} />
        <BulkValidateEditFeedbackDialog />
        <LPRSetupHelpModal
          loading={bulkEditLoading}
          open={lprHelpOpen}
          onCancel={() => {
            setLprHelpOpen(false);
          }}
          onConfirm={async () => {
            setLprHelpOpen(false);
          }}
        />

        <AudioEnableAcknowledgmentModal
          open={audioEnableAcknowledgmentOpen}
          onConfirm={() => {
            onBulkToggleChange(
              props.rows.map((row) => props?.getRowId?.(row) ?? row.id),
              formikProps,
              {
                audioControlEnabled: true,
              }
            );
            setAudioEnableAcknowledgmentOpen(false);
          }}
          onCancel={() => {
            setAudioEnableAcknowledgmentOpen(false);
          }}
        />
        <DataGridEditFormSection label="AI Features">
          {aiConfigSettings.map((s) => (
            <DataGridEditFormToggle
              {...props}
              key={s.field}
              loading={bulkValidateEditLoading}
              field={`settings.${s.field}`}
              label={s.label}
              Icon={s.Icon}
              onChange={async (newValue) => {
                if (
                  s.field === "faceRecognitionEnabled" &&
                  newValue &&
                  !me?.termsPeopleSearchAccepted
                ) {
                  const confirmed = await peopleSearchOpen();
                  if (!confirmed) return;
                }
                onBulkToggleChange(
                  props.rows.map((row) => props?.getRowId?.(row) ?? row.id),
                  formikProps,
                  {
                    [s.field]: newValue,
                    ...(s.field === "attributesEnabled"
                      ? {
                          // if value didnt change or value is enabled, pass face recognition option through. Else disable facial rec
                          faceRecognitionEnabled:
                            newValue == null || newValue
                              ? formikProps.values.settings
                                  .faceRecognitionEnabled
                              : false,
                        }
                      : undefined),
                    ...(s.field === "faceRecognitionEnabled"
                      ? {
                          // Forcibly enable attributes if face rec is enabled
                          attributesEnabled: newValue ? true : undefined,
                        }
                      : undefined),
                  }
                );
              }}
            >
              {s.field === "lprEnabled" && !showLprHelp && (
                <button
                  type="button"
                  className="mt-1 ml-0.5 text-xs leading-[14.06px] font-normal text-primary bg-transparent flex items-center gap-1"
                  onClick={() => {
                    setLprHelpOpen((v) => !v);
                  }}
                >
                  LPR Setup Tips
                  <InfoIcon className="text-sm" />
                </button>
              )}
            </DataGridEditFormToggle>
          ))}
        </DataGridEditFormSection>
        {hasPermissions("audio_manage") && (
          <DataGridEditFormSection label="Camera Settings">
            <DataGridEditFormToggle
              {...props}
              loading={bulkValidateEditLoading}
              field="settings.audioControlEnabled"
              label="Audio"
              Icon={VolumeUpIcon as FunctionComponent}
              onChange={(newValue) => {
                if (newValue) {
                  setAudioEnableAcknowledgmentOpen(true);
                } else {
                  onBulkToggleChange(
                    props.rows.map((row) => props?.getRowId?.(row) ?? row.id),
                    formikProps,
                    {
                      audioControlEnabled: newValue,
                    }
                  );
                }
              }}
            ></DataGridEditFormToggle>
            {onvifBackchannel && (
              <DataGridEditFormToggle
                {...props}
                loading={bulkValidateEditLoading}
                field="settings.onvifBackchannelEnabled"
                label="Two-Way Audio"
                Icon={TwoWayAudioIcon}
                onChange={(newValue) => {
                  onBulkToggleChange(
                    props.rows.map((row) => props?.getRowId?.(row) ?? row.id),
                    formikProps,
                    {
                      onvifBackchannelEnabled: newValue,
                    }
                  );
                }}
              ></DataGridEditFormToggle>
            )}
          </DataGridEditFormSection>
        )}
      </>
    ),
  } as BaseDatagridRowSelectionProps;
}

const TwoWayAudioIcon: FunctionComponent<SVGProps<SVGSVGElement>> = (
  props: SVGProps<SVGSVGElement>
) => {
  return <KeyboardVoiceIcon className={clsx(props.className, "ml-[-2px]")} />;
};
