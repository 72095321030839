import { CircularProgress, Dialog, DialogContent } from "@mui/material";
import { endOfHour, startOfHour } from "date-fns";
import { addHours } from "date-fns/fp";
import { ReactNode } from "react";
import { StringParam, withDefault } from "use-query-params";

import { useBreakpoints } from "@/util/useBreakpoints";

import { intelligentFiltersConfig } from "@/pages/Search/intelligence/intelligence";

import { ErrorMessage } from "@/components/ErrorMessage";
import { LPRReportDataGridContextualPlayer } from "@/components/VideoWall/ContextualVideoPlayer";

import { useCameraByIdQuery } from "@/generated-models";
import { MobileHeader } from "@/layout/MobileHeader";

import { footageIsAvailable } from "../../utils";

interface LicensePlateReportContextualChartPlayerProps {
  cameraId?: number;
  startTime: string;
  endTime: string;
  children: ReactNode;
  open?: boolean;
  handleClose: () => void;
  header?: ReactNode;
}

export const ContextualStartTimeParam = withDefault(
  StringParam,
  startOfHour(new Date()).toISOString()
);

export const ContextualEndTimeParam = withDefault(
  StringParam,
  endOfHour(addHours(1, new Date())).toISOString()
);

export function LicensePlateReportContextualChartPlayer({
  cameraId = 0,
  startTime,
  endTime,
  children,
  open,
  handleClose,
  header,
}: LicensePlateReportContextualChartPlayerProps) {
  const { fitsDesktop } = useBreakpoints();
  const { data } = useCameraByIdQuery({
    variables: { cameraId: cameraId || 0 },
    skip: !cameraId,
  });

  const camera = data?.camera;
  const hasFootage = footageIsAvailable(startTime, camera);

  if (!camera || !cameraId) {
    return (
      <Dialog fullScreen={!fitsDesktop} maxWidth="xs" open={open || false}>
        <DialogContent className="p-0 flex items-center w-full justify-center py-8">
          <CircularProgress />
        </DialogContent>
      </Dialog>
    );
  }

  const player = hasFootage ? (
    <LPRReportDataGridContextualPlayer
      playerProps={{
        camera: camera,
        subjects: intelligentFiltersConfig.vehicle.objectIds,
        startTime,
        endTime,
      }}
    />
  ) : (
    <div className="w-full h-full bg-black">
      <ErrorMessage title="Error" description="Error: VOD not found" />
    </div>
  );

  return (
    <Dialog
      fullScreen={!fitsDesktop}
      maxWidth="lg"
      open={open || false}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogContent className="p-0">
        <MobileHeader label="License Plate Details" onBack={handleClose} />
        {header}
        <div className="sm:p-3 flex sm:flex-row flex-col">
          <div className="flex-1 sm:rounded-lg bg-[#B2E4E1] sm:p-2 my-auto">
            {player}
          </div>
          <div>{children}</div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
